import * as React from 'react';
import { CheckboxFilter } from 'components/checkboxFilter';
import { SearchFilter } from 'components/searchFilter';
import { incidentSeverities, incidentStatuses, incidentTypes, incidentTimePeriods } from 'models/incident-enums';
import { IIncidentsFilters } from 'models/incidents-filters';
import { IKeyValue } from 'models/key-value';
import useTranslate from 'translations/translation-utils';

interface IProps {
    filters: IIncidentsFilters;
    onFilter: (key: string, element: string) => void;
    onAddFilterKeyword: (keyword: string) => void;
    onRemoveFilterKeyword: (keyword: string) => void;
    onToggleFilterExpanded?: (id: string) => void;
}

export const IncidentsFilters: React.FC<IProps> = (props) => {
    const commonProps = {
        expandedFilters: ['statuses', 'severities', 'types', 'periods', 'specialFilters'],
        onFilter: props.onFilter,
        onToggleFilterExpanded: props.onToggleFilterExpanded,
    };
    const translate = useTranslate();
    const incidentSpecialFilters: Array<IKeyValue<string>> = [
        { key: 'AssignedToCurrentUser', value: translate('CheckboxFilter.Labels.AssignedToMe') }
    ];

    return (
        <>
            <SearchFilter
                title={translate('ActionRegisterPage.Title.Filters')}
                name="filterKeywords"
                values={props.filters.filterKeywords}
                onAddFilterKeyword={props.onAddFilterKeyword}
                onRemoveFilterKeyword={props.onRemoveFilterKeyword}
            />
            <CheckboxFilter
                {...commonProps}
                title={translate('IncidentPage.Title.SpecialFilters')}
                name="specialFilters"
                selectedFilters={props.filters.specialFilters}
                unreservedFilters={incidentSpecialFilters}
            />
            <CheckboxFilter
                {...commonProps}
                title={translate('IncidentRiskActions.Label.Status')}
                name="statuses"
                selectedFilters={props.filters.statuses}
                unreservedFilters={incidentStatuses}
            />
            <CheckboxFilter
                {...commonProps}
                title={translate('IncidentDetails.Label.Severity')}
                name="severities"
                selectedFilters={props.filters.severities}
                unreservedFilters={incidentSeverities}
            />
            <CheckboxFilter
                {...commonProps}
                title={translate('IncidentDetails.Label.Type')}
                name="types"
                selectedFilters={props.filters.types}
                unreservedFilters={incidentTypes}
            />
            <CheckboxFilter
                {...commonProps}
                title={translate('IncidentDetails.Label.TimePeriod')}
                name="periods"
                selectedFilters={props.filters.periods}
                unreservedFilters={incidentTimePeriods}
            />
        </>
    );
}
