import classnames from 'classnames';
import * as React from 'react';

import './mobile-modal-toggle.scss';

export interface IProps {
    visible: boolean;
    onClose: () => void;
    className?: string;
}

const css = (props: IProps) => classnames(
    'MobileModalToggle',
    {
        ['MobileModalToggle--mobile-visible']: props.visible
    },
    props.className
);

export const MobileModalToggle: React.FC<React.PropsWithChildren<IProps>> = (props) => (
    <div className={css(props)}>
        <div className="MobileModalToggle__modal-background" />
        <div className="MobileModalToggle__modal-content">
            <div className="MobileModalToggle__content">
                {props.children}
            </div>
        </div>
        <button
            className="MobileModalToggle__modal-close"
            aria-label="close"
            onClick={props.onClose}
        />
    </div>
);
