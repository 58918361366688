export const clientRiskJa = `
<div>
<p>下のリンクをクリックしてご覧ください:</p>
<p><a href="https://confluence.cbre.com/x/u7zSC" target= "_blank">リスクフローのプロセス</a></p>
</div>
<br />
<div>
<p><a href="https://quantum.cbre.com/issues" target= "_blank">問題を報告する</a></p>
<p><a href="https://quantum.cbre.com/requests" target= "_blank">リクエストを出す</a></p>
</div>`;
