import { combineReducers } from 'redux';
import { ControlCentreActions, ControlCentreActionTypes } from '../../actions/control-centre-actions';
import { ISiteData, WidgetTypeName } from '../../models/control-centre';
import { IBaseState } from '../base-state';
import { NavActions } from 'actions/actions-v2/nav-actions-v2';
import { IAppAction } from 'actions/app-action';

export type WidgetType = IRisksRequiringAttentionWidget
    | IActionsRequiringAttentionWidget
    | ISiteSelectorWidget
    | IRiskRegisterHealthWidget
    | IWidgetState;

export type ViewByType = 'Services' | 'Pillars';

export interface IFilterWidget {
    filterName: string;
    value: string | string[] | number | boolean;
}

export interface IRootControlCentreState extends IBaseState {
    controlCentre: IControlCentreState;
}

export interface IBaseWidgetProps {
    widgetId: string;
}

export interface IBaseWidgetActionProps {
    onInitialiseWidget: (widgetType: string) => void;
}

export interface ISiteSelectorWidget extends IWidgetState {
    excludedSites: string[];
    expandedClients: string[];
    dcsChecked: boolean;
    emeaChecked: boolean;
    apacChecked: boolean;
    americasChecked: boolean;
    regionsChecked: string[];
}

export interface IActionsRequiringAttentionWidget extends IWidgetState {
    overdueActionsLowerLimit: number;
}

export interface IRisksRequiringAttentionWidget extends IWidgetState {
    passedReviewLowerLimit: number;
    overdueActionsLowerLimit: number;
}

export interface IRiskRegisterHealthWidget extends IWidgetState {
    minimumHealthyActivity: number;
    maximumAllowableLateActivity: number;
    shownSites: string[];
}

export interface ICermComplianceOverviewWidget extends IWidgetState {
    viewBy: ViewByType;
}

export interface ICermRegionOverviewWidget extends IWidgetState {
    dcsChecked: boolean;
    emeaChecked: boolean;
    apacChecked: boolean;
    americasChecked: boolean;
    regionsChecked: string[];
}

export interface IWidgetState {
    id: string;
    type: WidgetTypeName;
    isHidden: boolean;
    isSettingsShown: boolean;
    isCollapsed: boolean;
    isInitialised: boolean;
}

export interface IControlCentreState {
    sites: ISiteData[];
    widgets: WidgetType[];
    isInitialising: boolean;
    regions: string[];
    lineOfBusinesses: string[];
}

const INITIAL_STATE: IControlCentreState = {
    sites: [],
    widgets: [],
    isInitialising: true,
    regions: [],
    lineOfBusinesses: [],
};

const sites = (state: IControlCentreState['sites'] = INITIAL_STATE.sites, action: ControlCentreActionTypes) => {
    switch (action.type) {

        case ControlCentreActions.TOGGLE_SHOW_SITES:
            return state.map((item) => {
                if (action.payload.siteIds.includes(item.id)) {
                    return {
                        ...item,
                        isVisible: !item.isVisible
                    };
                }

                return item;
            });
        case ControlCentreActions.INITIALISE_WIDGET_STATE_FULFILLED: // replace data on each site
            if (action.payload.widgetType === 'risksRequiringAttention') {
                return state.map((site) => {
                    const newSiteData = action.payload.sites.find((newSite) => newSite.id === site.id);
                    if (newSiteData) {
                        return {
                            ...site,
                            risks: newSiteData.risks
                        };
                    }
                    return site;
                });
            }
            if (action.payload.widgetType === 'cermComplianceOverview') {
                return state.map((site) => {
                    const newSiteData = action.payload.sites.find((newSite) => newSite.id === site.id);
                    if (newSiteData) {
                        return {
                            ...site,
                            cermCompliance: newSiteData.cermCompliance
                        };
                    }
                    return site;
                });
            }
            if (action.payload.widgetType === 'riskRegisterHealth') {
                return state.map((site) => {
                    const newSiteData = action.payload.sites.find((newSite) => newSite.id === site.id);
                    if (newSiteData) {
                        return {
                            ...site,
                            riskRegisterHealthCharts: newSiteData.riskRegisterHealthCharts
                        };
                    }
                    return site;
                });
            }
            if (action.payload.widgetType === 'actionsRequiringAttention') {
                return state.map((site) => {
                    const newSiteData = action.payload.sites.find((newSite) => newSite.id === site.id);
                    if (newSiteData) {
                        return {
                            ...site,
                            actions: newSiteData.actions
                        };
                    }
                    return site;
                });
            }
            return state;
        case ControlCentreActions.INITIALISE_CONTROL_CENTRE_STATE_FULFILLED:
            return action.payload.controlCentre.sites;
        default:
            return state;
    }
};

// TODO: Need to rethink how to default those generic widgets in reducer
export const widgets = (state = INITIAL_STATE.widgets, action: ControlCentreActionTypes): IControlCentreState['widgets'] => {
    switch (action.type) {
        case ControlCentreActions.TOGGLE_SHOW_SETTINGS:
            return state.map((widget) => {
                if (widget.id === action.payload.widgetId) {
                    return {
                        ...widget,
                        isSettingsShown: !widget.isSettingsShown
                    };
                }

                return widget;
            });

        case ControlCentreActions.TOGGLE_COLLAPSE_EXPAND:
            return state.map((widget) => {
                if (widget.id === action.payload.widgetId) {
                    return {
                        ...widget,
                        isCollapsed: !widget.isCollapsed
                    };
                }

                return widget;
            });

        case ControlCentreActions.TOGGLE_SHOW_WIDGET:
            return state.map((widget) => {
                if (widget.id === action.payload.widgetId) {
                    return {
                        ...widget,
                        isHidden: !widget.isHidden
                    };
                }

                return widget;
            });

        case ControlCentreActions.TOGGLE_FILTER_CHECKED:
            return state.map((widget) => {
                if (widget.id === action.payload.widgetId) {
                    const checkValues = widget[action.payload.filterName];
                    if (Array.isArray(checkValues)) {
                        const arrayItems: string[] = checkValues;
                        if (arrayItems.find((item) => item === action.payload.value)) {
                            return {
                                ...widget,
                                [action.payload.filterName]:
                                    arrayItems.filter((value) => value !== action.payload.value)
                            };
                        } else {
                            return {
                                ...widget,
                                [action.payload.filterName]: [...arrayItems, action.payload.value]
                            };
                        }
                    }
                }

                return widget;
            });

        case ControlCentreActions.RESET_WIDGET:
            return state.map((widget) => {
                if (widget.id === action.payload.widgetId) {
                    // Loop around filters and update with default values
                    let updatedWidget = { ...widget };
                    action.payload.filters.forEach((filter) => {
                        updatedWidget = {
                            ...updatedWidget,
                            [filter.filterName]: filter.value
                        };
                    });

                    return updatedWidget;
                }

                return widget;
            });

        case ControlCentreActions.FILTER_WIDGET:
        case ControlCentreActions.FILTER_AND_UPDATE_WIDGET:
            return state.map((widget) => {
                if (widget.id === action.payload.widgetId) {
                    switch (widget.type) {
                        case 'risksRequiringAttention':
                        case 'actionsRequiringAttention':
                        case 'siteSelector':
                        case 'riskRegisterHealth':
                        case 'cermComplianceOverview':
                        case 'cermRegionOverview':
                            return {
                                ...widget,
                                [action.payload.filterName]: action.payload.value
                            };
                        default:
                            break;
                    }
                }

                return widget;
            });
        case ControlCentreActions.INITIALISE_CONTROL_CENTRE_STATE_FULFILLED:
            return action.payload.controlCentre.widgets;
        case ControlCentreActions.INITIALISE_WIDGET_STATE: // replace data on each site
            return state.map((widget) => {
                if (widget.type === action.payload.widgetType) {
                    return {
                        ...widget,
                        isInitialised: true
                    };
                }
                return widget;
            });
        default:
            return state;
    }
};

const isInitialising =
    (state: IControlCentreState['isInitialising'] = INITIAL_STATE.isInitialising, action: IAppAction) => {
        switch (action.type) {
            case NavActions.LOAD_APP_DATA_FULFILLED:
                return false;
            default:
                return state;
        }
    };

const regions =
    (state: IControlCentreState['regions'] = INITIAL_STATE.regions, action: ControlCentreActionTypes) => {
        switch (action.type) {
            default:
                return state;
        }
    };

const lineOfBusinesses =
    (state: IControlCentreState['lineOfBusinesses'] = INITIAL_STATE.lineOfBusinesses, action: ControlCentreActionTypes) => {
        switch (action.type) {
            default:
                return state;
        }
    };

export const controlCentreReducer = combineReducers<IControlCentreState>({
    sites,
    widgets,
    isInitialising,
    lineOfBusinesses,
    regions
});
