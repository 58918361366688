import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { connect } from 'react-redux';
import { CalendarPage } from './calendar-page';
import {
    onSiteChange,
    onLoadCalendar,
    onFilterCalendar,
    onCreateCalendarEvent,
    resetCalendarEvent,
    onSubscribeEvent,
} from 'actions/actions-v2/calendar-action-v2';
import { ICalendarItem } from 'models/calendar';
import { ICalendarState } from 'state/calendar-state';
import { getFilteredLookupSitePeopleWithAllowedRoles } from 'sharedSelectors';
import { loadPersonsLookup } from 'actions/actions-v2/person-action-v2';

const getPermissions = (state: IRootState) => state.app.permissions;
const getSiteId = (state: IRootState) => state.app.siteId;
const getLookups = createSelector(
    (state: IRootState) => state.controlCentre.sites,
    (state) =>
        state.map((site) => {
            return { key: site.id, value: site.clientAndSiteName };
        })
);

const getEvents = createSelector(
    (state: IRootState) => state.calendar,
    (state: ICalendarState) =>
        state.calendarItems.map<Partial<ICalendarItem>>((calendarItem) => ({
            title: calendarItem.title,
            start: new Date(calendarItem.start),
            end: new Date(calendarItem.end),
            calendarItemType: calendarItem.calendarItemType,
            className: calendarItem.className,
            uid: calendarItem.uid ? calendarItem.uid : calendarItem.id,
        }))
);

const getSelectedValues = (state: IRootState) => state.calendar.selectedValues;
const getCalendarEvent = (state: IRootState) => state.calendar.calendarEvent;
const getLoading = (state: IRootState) => state.calendar.isLoading;
const getSaving = (state: IRootState) => state.calendar.isSaving;
const getSiteLinkUrl = (state: IRootState) => state.calendar.siteLinkUrl;
const getOperatingPlatform = (state: IRootState) => state.siteState.site.operatingPlatform;

const mapStateToProps = createSelector(
    getSelectedValues,
    getSiteId,
    getPermissions,
    getLookups,
    getEvents,
    getFilteredLookupSitePeopleWithAllowedRoles,
    getCalendarEvent,
    getLoading,
    getSaving,
    getSiteLinkUrl,
    getOperatingPlatform,
    (
        selectedValues,
        siteId,
        permissions,
        lookupSites,
        events,
        lookupSitePeopleRoles,
        calendarEvent,
        isLoading,
        isSaving,
        siteLinkUrl,
        siteOperatinPlatform,
    ) => ({
        selectedValues,
        siteId,
        permissions,
        lookupSites,
        events,
        lookupSitePeopleRoles,
        calendarEvent,
        isLoading,
        isSaving,
        siteLinkUrl,
        siteOperatinPlatform
    })
);

const mapDispatchToProps = {
    onSiteChange,
    onLoadCalendar,
    onFilterCalendar,
    onCreateCalendarEvent,
    resetCalendarEvent,
    onSubscribeEvent,
    loadPersonsLookup,
};

export const CalendarContainer = connect(mapStateToProps, mapDispatchToProps)(CalendarPage);
