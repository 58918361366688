import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { NotificationActions } from 'actions/actions-v2/notification-action-v2';
import { IAppState } from 'reducers/app';

const controllerName = 'notification';

const getNotificationCount = {
    action: NotificationActions.LOAD_NOTIFICATION_COUNT,
    actionFulfilled: NotificationActions.LOAD_NOTIFICATION_COUNT_FULFILLED,
    actionRejected: NotificationActions.LOAD_NOTIFICATION_COUNT_REJECTED,
} as IActionType;

const markAllReadData = {
    action: NotificationActions.MARK_ALL_READ,
    actionFulfilled: NotificationActions.MARK_ALL_READ_FULFILLED,
    actionRejected: NotificationActions.MARK_ALL_READ_REJECTED,
} as IActionType;

const markAllReadEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, markAllReadData).post<IAppState>(`/api/${controllerName}/MarkAllRead`);
const loadNotificationEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getNotificationCount).get<IAppState>(`/api/${controllerName}/count`);

export const notificationEpicV2 = combineEpics(markAllReadEpic, loadNotificationEpic);
