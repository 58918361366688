import React from 'react';
import parse from 'html-react-parser';
/* import helpTextTranslation from '../../translations/language'; */
import { ModalCard, ModalCardBody, ModalCardFooter, ModalCardHeader } from 'components/modalCard';
import { ModuleType } from 'models/help-module';
import useTranslate from 'translations/translation-utils';
import { moduleSelect } from 'utils/language-utils'

interface Iprops {
    text?: string;
    isShown: boolean;
    onClose: () => void;
    title?: string;
    moduleType: ModuleType;
    language: string;
}

const HelpModule: React.FC<Iprops> = (props) => {
    const translate = useTranslate();
    return (
        <>
            <ModalCard modalId="confirmation" isShown={props.isShown}>
                <ModalCardHeader
                    modalId="confirmation"
                    title={props.title ? props.title : translate('HelpModulePage.Label.Help')}
                    onClose={props.onClose}
                />
                <ModalCardBody modalId="confirmation">
                    {parse(moduleSelect(props.moduleType, props.language))}
                </ModalCardBody>
                <ModalCardFooter />
            </ModalCard>
        </>
    );
};

export default HelpModule;
