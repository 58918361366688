import React, { FormEvent } from 'react';
import NumberFormat from 'react-number-format';
import { DateTime, Format } from 'components/dateTime';
import { Checkbox } from 'components/form/Checkbox';
import { enumEnergyBillPeriods } from 'models/building';
import { IEnergyBill } from 'models/energy-bill';
import { Button } from 'components/v2/components';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';

interface IProps {
    enabled: boolean;
    energyBills: IEnergyBill[];
    energyBillPeriods: enumEnergyBillPeriods;
    onNavigateToEnergyBillEdit: (energyBill: IEnergyBill) => void;
    onNavigateToEnergyBillDelete: (energyBill: IEnergyBill) => void;
    onNavigateToEnergyBill: () => void;
    intl: any;
}

export interface IState {
    isDisplayed: boolean;
}

class BillingTableClass extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            isDisplayed: true
        };
    }

    public render() {
        const { intl } = this.props;

        return (
            <>
                <div className="billing-table">
                    <Checkbox
                        id="displayTable"
                        name="displayTable"
                        label={translate(intl, 'UpsertSite.BuildingForm.BillingTable.ShowHideTable')}
                        checked={this.state.isDisplayed}
                        onChange={this.onToggleTable}
                    />
                    {this.state.isDisplayed &&
                        <>
                            <table id="billingTable" className="table is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>{translate(intl, 'UpsertSite.Views.BilingTable.From')}</th>
                                        <th>{translate(intl, 'UpsertSite.Views.BilingTable.TotalUsageMW')}</th>
                                        <th>{translate(intl, 'UpsertSite.Views.BilingTable.ITUsageMW')}</th>
                                        <th />
                                    </tr>
                                </thead>
                                {this.props.energyBills &&
                                    this.props.energyBills.map((item: IEnergyBill, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>
                                                    <DateTime
                                                        value={item.date.toString()}
                                                        format={Format.DateOnly}
                                                    />
                                                </td>
                                                <td>
                                                    <NumberFormat
                                                        value={item.electricityAmount}
                                                        displayType="text"
                                                        thousandSeparator={true}
                                                    />
                                                </td>
                                                <td>
                                                    <NumberFormat
                                                        value={item.itUsage}
                                                        displayType="text"
                                                        thousandSeparator={true}
                                                    />
                                                </td>
                                                <td>
                                                    <Button
                                                        onClick={this.props.onNavigateToEnergyBillEdit.bind(
                                                            this,
                                                            item
                                                        )}
                                                        type="button"
                                                        className="edit"
                                                    >
                                                        {translate(intl, 'ShiftHandoversAdminPage.label.Edit')}
                                                    </Button>
                                                    <Button
                                                        onClick={this.props.onNavigateToEnergyBillDelete.bind(
                                                            this,
                                                            item
                                                        )}
                                                        type="button"
                                                        className="remove"
                                                    >
                                                        {translate(intl, 'Attachments.AttachmentList.ButtonWithConfirmDialog.ButtonText')}
                                                    </Button>
                                                </td>
                                            </tr>
                                        );
                                    })}

                            </table>
                            <div className="action-groups">
                                {this.props.energyBillPeriods &&
                                    <Button
                                        onClick={this.props.onNavigateToEnergyBill}
                                    //  disabled={this.props.enabled}
                                    >
                                        {translate(intl,
                                            'UpsertSite.BuildingForm.BillingTable.AddData')}
                                    </Button>
                                }
                            </div>
                        </>
                    }
                </div>
            </>);
    }
    private onToggleTable = (event: FormEvent<HTMLInputElement>) => {
        this.setState({ isDisplayed: event.currentTarget.checked });
    }
}

export const BillingTable = injectIntl(BillingTableClass);
