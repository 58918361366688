import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';

const getCategory = (state: IRootState) => state.app.lookupCriticalSystemSpareCategories ?? [];
const getSubCategory = (state: IRootState) => state.app.lookupCriticalSystemSpareSubCategories ?? [];

export default createSelector(
    getCategory,
    getSubCategory,
    (categories, subCategories) => ({
        categories,
        subCategories
    })
);
