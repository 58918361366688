import { Dictionary } from 'lodash';
import { combineReducers } from 'redux';
import { ActionActions, ActionActionTypes } from '../../actions/action-actions';
import { IAction } from '../../models/action';
import { INITIAL_STATE as INITIAL_GRID_STATE } from '../grid';
import { genericSort } from 'utils/sort-utils';
import { ActionActions as ActionActionsV2 } from 'actions/actions-v2/action-action-v2';
import { IAppAction } from 'actions/app-action';

export interface IActionsGridState {
    expandedFilters: string[];
    expandedHistoryItems: Dictionary<boolean>;
    expandedAttachments: Dictionary<boolean>;
    items: IAction[];
    isLoading: boolean;
    expandedItems: string[];
}

export const INITIAL_STATE: IActionsGridState = {
    ...INITIAL_GRID_STATE,
    expandedFilters: ['filterStatus'],
    expandedHistoryItems: {},
    expandedAttachments: {}
};

const items = (state: IActionsGridState['items'] = INITIAL_STATE.items, action: IAppAction) => {
    switch (action.type) {
        case ActionActionsV2.LOAD_ACTIONS_REJECTED:
            return INITIAL_STATE.items;

        case ActionActionsV2.LOAD_ACTIONS_FULFILLED:
            return action.payload;
        case ActionActionsV2.CREATE_ACTION_FULFILLED:
            if (action.payload !== null) {
                return [action.payload, ...state];
            }
            return state;
        case ActionActionsV2.UPDATE_ACTION_FULFILLED:
            if (action.payload !== null) {
                const actionItem = state.find((n) => n.id === action.payload.id);
                if (actionItem) {
                    return state.map((item: IAction) => {
                        if (item.id === action.payload.id) {
                            return action.payload;
                        }
                        return item;
                    });
                }
                return [action.payload, ...state];
            }
            return state;
        case ActionActionsV2.SORT_ACTIONS:
            return genericSort(action.payload.sortColumn, [...state], action.payload.sortAscending);
        case ActionActions.LOAD_PARENT_FULFILLED:
            const newArray: IAction[] = [];
            state.map((item) => {
                if (item.id !== action.payload.id) {
                    newArray.push(item);
                } else {
                    const replacementWIthParent: IAction = {
                        ...item,
                        parent: action.payload.data
                    };
                    newArray.push(replacementWIthParent);
                }
            });
            return newArray;
        default:
            return state;
    }
};

const isLoading = (state: boolean = INITIAL_STATE.isLoading, action: IAppAction) => {
    switch (action.type) {
        case ActionActionsV2.LOAD_ACTIONS:
        case ActionActionsV2.LOAD_ACTION:
            return true;

        case ActionActionsV2.LOAD_ACTIONS_FULFILLED:
        case ActionActionsV2.LOAD_ACTIONS_REJECTED:
        case ActionActionsV2.LOAD_ACTION_FULFILLED:
        case ActionActionsV2.LOAD_ACTION_REJECTED:
            return false;

        default:
            return state;
    }
};

const expandedItems = (
    state: IActionsGridState['expandedItems'] = INITIAL_STATE.expandedItems,
    action: ActionActionTypes
) => {
    switch (action.type) {
        case ActionActions.TOGGLE_ITEM_EXPANDED:
            const id = action.payload.id;
            if (state.includes(id)) {
                return state.filter((c) => c !== id);
            }
            return [...state, id];

        default:
            return state;
    }
};

const expandedHistoryItems = (
    state: IActionsGridState['expandedHistoryItems'] = INITIAL_STATE.expandedHistoryItems,
    action: ActionActionTypes
) => {
    switch (action.type) {
        case ActionActions.SHOW_HISTORY:
            return {
                ...state,
                [action.payload.id]: true
            };

        case ActionActions.HIDE_HISTORY:
            const id = action.payload.id;
            const { [id]: _, ...newState } = state;
            return newState;

        default:
            return state;
    }
};

const expandedAttachments = (
    state: IActionsGridState['expandedAttachments'] = INITIAL_STATE.expandedAttachments,
    action: ActionActionTypes
) => {
    switch (action.type) {
        case ActionActions.SHOW_ATTACHMENTS:
            return {
                ...state,
                [action.payload.id]: true
            };

        case ActionActions.HIDE_ATTACHMENTS:
            const id = action.payload.id;
            const { [id]: _, ...newState } = state;
            return newState;

        default:
            return state;
    }
};

const expandedFilters = (
    state: IActionsGridState['expandedFilters'] = INITIAL_STATE.expandedFilters,
    action: ActionActionTypes
) => {
    switch (action.type) {
        case ActionActions.TOGGLE_FILTER_EXPANDED:
            const id = action.payload.id;

            if (state.includes(id)) {
                const newState = state.filter((item) => item !== id);
                return newState;
            } else {
                if (id) {
                    const newState = state.concat(id);
                    return newState;
                }
            }

            return state;

        default:
            return state;
    }
};

export const reducer = combineReducers<IActionsGridState>({
    items,
    isLoading,
    expandedItems,
    expandedFilters,
    expandedHistoryItems,
    expandedAttachments
});
