import { IRootState } from './../../../../routes/store';
import { createSelector } from 'reselect';

const getLineOfBusiness = (state: IRootState) => state.siteMetaDataState.allLineOfBusinesses;
const getDivisions = (state: IRootState) => state.siteMetaDataState.divisions;
const getBusinessUnits = (state: IRootState) => state.siteMetaDataState.businessUnit;
const getSelectedLineOfBusiness = (state: IRootState) => state.siteMetaDataState.hierarchy.selectedLineOfBusiness;
const getSelectedDivision = (state: IRootState) => state.siteMetaDataState.hierarchy.selectedDivision;
const getDeletedDivision = (state: IRootState) => state.siteMetaDataState.hierarchy.divisionsForLineOfBusiness;
export default createSelector(
    getLineOfBusiness,
    getDivisions,
    getBusinessUnits,
    getSelectedLineOfBusiness,
    getSelectedDivision,
    getDeletedDivision,
    (lineOfBusiness, divisions, businessUnit, selectLineOfBusinessValue, selectDivisionValue, divisionsForLineOfBusiness) => ({
        lineOfBusiness,
        divisions,
        businessUnit,
        selectLineOfBusinessValue,
        selectDivisionValue,
        divisionsForLineOfBusiness
    })
);