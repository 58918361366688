import React from 'react';
import { IShift } from 'models/shift';
import { NavLink } from 'react-router-dom';
import { /* Table, */ Panel } from '../v2/components';
import { IObsoleteSkillCategory } from 'models/engineer';
import _ from 'lodash';
import { ShiftGapsCard } from './shift-gaps-card';
import useTranslate from 'translations/translation-utils';
import { isMobile } from 'react-device-detect';

interface IProps {
    shift: IShift;
    skillCategories: IObsoleteSkillCategory[];
    isReadOnly: boolean;
}

const ShiftPanel: React.FC<IProps> = ({
    shift,
    isReadOnly
}) => {
    const [grid, setGrid] = React.useState(7);
    const translate = useTranslate();
    const [isMobileView, setIsMobileView] = React.useState(isMobile ?? false);

    const [windowWidth, setWindowWidth] = React.useState(0);
    let resizeWindow = () => {
        setWindowWidth(window.innerWidth);
    };

    React.useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, []);

    React.useEffect(() => {
        setIsMobileView(windowWidth < 780);
    }, [windowWidth])

    React.useEffect(() => {
        if (isMobileView) {
            setGrid(3)
        } else {
            setGrid(7)
        }
    }, [isMobileView])


    const getFilteredSkillCategories = (skillCategories) => {
        const arr = [];
        const skillCategory = _.cloneDeep(skillCategories)
        while (skillCategory && skillCategory.length) {
            arr.push(skillCategory.splice(0, grid))

        }
        return arr;
    }
    const getTitle = () => {
        return (
            <>
                <p className="shift-title">{shift.name}</p>
                <div className="action-controls">
                    {!isReadOnly && (
                        <NavLink className="button button-action" to={`/Shifts/Details/${shift.id}`}>
                            {translate('ShiftHandoversAdminPage.label.Edit')}
                        </NavLink>
                    )}
                    <mark className={`${shift.status === 'Green' ? 'not-comply' : 'comply'}`}>
                        {translate('RiskRegisterPage.RiskFilter.Title.Status')}
                    </mark>
                </div>
            </>
        );
    };
    return (
        <Panel title={getTitle()}>
            {
                getFilteredSkillCategories(shift.skillCategory).map((categories) => {
                    return (
                        <ShiftGapsCard
                            skillCategories={categories}
                            shift={shift}
                        />
                    )
                })
            }
        </Panel>
    );
};

export default (ShiftPanel)