import * as React from 'react';
import { NavLink } from 'react-router-dom';
import SiteName from '../sitename/sitename';
import { IKeyValue } from 'models';
import useTranslate from 'translations/translation-utils';

interface IProps {
    pageName?: string;
    isAdmin?: boolean;
    customLinks?: Array<IKeyValue<string>>;
}

export const Breadcrumb: React.FC<IProps> = ({ pageName, isAdmin, customLinks }) => {
    const translate = useTranslate();

    const getChildren = () => {
        if (customLinks) {
            return customLinks.map((link, i) => (
                <li key={i} className="breadcrumb-title">
                    <NavLink to={link.key}>{link.value}</NavLink>
                </li>
            ));
        }
    };

    return (
        <>
            <NavLink to="/" className="is-hidden-desktop is-hidden-tablet">
                <SiteName />
            </NavLink>

            <nav className="breadcrumb">
                <ul>
                    {isAdmin && (
                        <li className="breadcrumb-title">
                            <NavLink to="/">{translate('Breadcrumb.Links.Admin')}</NavLink>
                        </li>
                    )}

                    {getChildren()}

                    {pageName && <li className="breadcrumb-page-title">{pageName}</li>}
                </ul>
            </nav>
        </>
    );
};

export default Breadcrumb;
