import * as React from 'react';
import logo from 'assets/CBREQuantum-NewLogo_Green.svg'
import useTranslate from 'translations/translation-utils';

export const ClientDomainRequiredPage: React.FC = ({ }) => {
    const translate = useTranslate();
    return (
        <div className="access-denied">
            <div className="columns">
                <div className="column is-full">
                    <header>
                        <div className="logo">
                            <img src={logo} alt="CBRE Quantum" className="is-hidden-mobile" />
                            <img src={logo} alt="CBRE Quantum" className="is-hidden-tablet" />
                        </div>
                    </header>
                </div>
            </div>
            <div className="columns is-multiline is-centered">
                <div className="column is-half">
                    <ul className="details">
                        <div id="main_block">
                            <div id="msg_long_reason">
                                <div className="title" id="msg_title">
                                    <b>{translate('ClientDomain.Lable.1')}</b>
                                </div>
                                <li>
                                    <b>
                                        {translate('ClientDomain.Lable.2')}
                                        {translate('ClientDomain.Lable.3')}
                                    </b>
                                </li>
                            </div>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    );
};
