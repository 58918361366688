import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { CermAssessmentPage } from './cerm-assessment-page';
import { loadMasterProcessSteps } from 'actions/actions-v2/master-process-step-action-v2';
import { getPermissions } from 'sharedSelectors';
import {
    loadCermAssessmentResult,
    startCermExpertReview,
    finishCermExpertReview,
} from 'actions/actions-v2/cerm-assessment-action-v3';
import { loadSite, updateAssessmentSummary, resetSite } from 'actions/actions-v2/site-actions-v2';
import { uploadAttachmentSite } from 'actions/actions-v2/attachment-action-v2';
import { deleteAttachment } from 'actions/actions-v2/attachment-action-v2';

const getSiteId = (state: IRootState) => state.app.siteId;
const getIsLoading = (state: IRootState) =>
    state.siteState.isInitialising ||
    state.siteState.isSubmitting ||
    state.cermAssessmentResultState.isLoading;
const getShowHelpModule = (state: IRootState) => state.app.showHelpModule;
const getLookupSites = (state: IRootState) => state.app.lookupSites;
const getSite = (state: IRootState) => state.siteState.site;
const getSiteName = (state: IRootState) => {
    const siteResult = state.app.lookupSites.filter((f) => f.key === state.app.siteId);
    if (siteResult.length === 1) {
        return siteResult[0].value;
    }
    return 'site...';
};
const getMasterProcessSteps = (state: IRootState) =>
    state.masterProcessStepState.masterProcessSteps;
const getExpertReviewStatus = (state: IRootState) => {
    if (!state.cermAssessmentResultState.cermAssessmentResult) {
        return null;
    }
    return state.cermAssessmentResultState.cermAssessmentResult.expertReviewStatus;
};

const getOperatingPlatforms = (state: IRootState) => state.operatingPlatformState.operatingPlatforms;

const mapStateToProps = createSelector(
    getSiteId,
    getIsLoading,
    getMasterProcessSteps,
    getExpertReviewStatus,
    getPermissions,
    getSiteName,
    getShowHelpModule,
    getLookupSites,
    getSite,
    getOperatingPlatforms,
    (
        siteId,
        isLoading,
        masterProcessSteps,
        expertReviewStatus,
        permissions,
        siteName,
        showHelpModule,
        lookupSites,
        site,
        allOperatingPlatforms,
    ) => ({
        siteId,
        isLoading,
        masterProcessSteps,
        expertReviewStatus,
        permissions,
        siteName,
        showHelpModule,
        lookupSites,
        site,
        allOperatingPlatforms
    })
);

const mapDispatchToProps = {
    loadMasterProcessSteps,
    loadCermAssessmentResult,
    startCermExpertReview,
    finishCermExpertReview,
    loadSite,
    resetSite,
    updateAssessmentSummary,
    uploadAttachmentSite,
    deleteAttachment
};

export const CermAssessmentPageContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CermAssessmentPage)
);
