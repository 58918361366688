import * as React from 'react';
import { connect } from 'react-redux';
import selectors from './selectors';
import actions from './actions';
import { IOperatingPlatform } from 'models/operating-platform-model';
import { IBusinessUnitCreate, IDivisionsCreate, ILineOfBusinessCreate, ISelectedSiteMetaData } from 'models/site-metadata-model';
import { HierarchyLineofBusiness } from './hierarchy-lineofbusiness';
import { HierarchyDivisions } from './hierarchy-divisions';
import { HierachyBusinessUnits } from './hierarchy-businessUnits';
import { IDivisions } from 'models/divisions-model';
import HierarchyTrackerTab from './hierarchy-tracker';

interface IProps {
    isReadOnly: boolean;
    lineOfBusiness: ILineOfBusinessCreate[];
    selectOperatingPlatform: IOperatingPlatform;
    newOperatingPlatform: { id: string, label: string };
    saveLineOfBusinessSiteMetaData: (lineOfBusiness: ILineOfBusinessCreate[]) => void;
    saveDivisionsSiteMetaData: (divisions: IDivisionsCreate[]) => void;
    saveBusinessUnitsSiteMetaData: (businessUnits: IBusinessUnitCreate[]) => void;
    businessUnit: IBusinessUnitCreate[];
    divisions: IDivisions[];
    selectLineOfBusiness: (lineOfbusinessId: string, lineOfBusinessLabel: string, count: number, isActive: boolean) => void;
    selectDivision: (divisionId: string, divisionLabel: string, count: number, isActive: false) => void;
    selectLineOfBusinessValue: ISelectedSiteMetaData;
    selectDivisionValue: ISelectedSiteMetaData;
    getDivisionsForLineOfBusiness: (model: IDivisions[]) => void;
    divisionsForLineOfBusiness: IDivisions[]
}

const HierarchyTab: React.FC<IProps> = (props) => {
    return (
        <div className="tabs-container-table-only hierachy-tab-container">
            <div className="columns">
                <div className='column is-3'> <HierarchyLineofBusiness lineOfBusiness={props?.lineOfBusiness} selectOperatingPlatform={props.selectOperatingPlatform} newOperatingPlatform={props.newOperatingPlatform} saveLineOfBusinessSiteMetaData={props.saveLineOfBusinessSiteMetaData} selectLineOfBusiness={props.selectLineOfBusiness} selectLineOfBusinessValue={props?.selectLineOfBusinessValue} selectDivision={props.selectDivision} /></div>
                <div className='vertical-separator'></div>
                <div className='column is-3'> <HierarchyDivisions divisions={props.divisions} selectOperatingPlatform={props.selectOperatingPlatform} newOperatingPlatform={props.newOperatingPlatform} saveDivisionsSiteMetaData={props.saveDivisionsSiteMetaData} selectDivision={props.selectDivision} selectLineOfBusinessValue={props?.selectLineOfBusinessValue} selectLineOfBusiness={props.selectLineOfBusiness} selectDivisionValue={props.selectDivisionValue} getDivisionsForLineOfBusiness={props.getDivisionsForLineOfBusiness} /></div>
                <div className='vertical-separator'></div>
                <div className='column is-3'> <HierachyBusinessUnits businessUnit={props.businessUnit} selectOperatingPlatform={props.selectOperatingPlatform} newOperatingPlatform={props.newOperatingPlatform} saveBusinessUnitsSiteMetaData={props.saveBusinessUnitsSiteMetaData} selectDivisionValue={props.selectDivisionValue} selectLineOfBusinessValue={props?.selectLineOfBusinessValue} selectDivision={props.selectDivision} divisionsForLineOfBusiness={props.divisionsForLineOfBusiness} /></div>
                <div className='column is-3'><HierarchyTrackerTab lineOfBusiness={props.selectLineOfBusinessValue?.label} divisionSector={props.selectDivisionValue?.label} selectOperatingPlatform={props.selectOperatingPlatform ? props.selectOperatingPlatform.value : props.newOperatingPlatform.label} /> </div>
            </div>
        </div>
    );
};

export default connect(selectors, actions)(HierarchyTab);
