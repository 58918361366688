import { Action } from 'redux';
import { IQuestionSetsGridState } from 'reducers/questionSet/questionSets-grid';
import { IQuestionSet } from '../models/question-set';

export enum QuestionSetActions {
    LOAD_QUESTION_SETS = '@@questionSet/LOAD_QUESTION_SETS',
    LOAD_QUESTION_SETS_FULFILLED = '@@questionSet/LOAD_QUESTION_SETS_FULFILLED',
    LOAD_QUESTION_SETS_REJECTED = '@@questionSet/LOAD_QUESTION_SETS_REJECTED',
    LOAD_QUESTION_SETS_CANCELLED = '@@questionSet/LOAD_QUESTION_SETS_CANCELLED',
    CHANGE_ROLES = '@@questionSet/CHANGE_ROLES',
    CHANGE_ROLES_FULFILLED = '@@questionSet/CHANGE_ROLES_FULFILLED',
    CHANGE_ROLES_REJECTED = '@@questionSet/CHANGE_ROLES_REJECTED',
    TOGGLE_ITEM_EXPANDED = '@@questionSet/TOGGLE_ITEM_EXPANDED',
    TOGGLE_FILTER_EXPANDED = '@@questionSet/TOGGLE_FILTER_EXPANDED',
    ADD_FILTER_KEYWORD = '@@questionSet/ADD_FILTER_KEYWORD',
    REMOVE_FILTER_KEYWORD = '@@questionSet/REMOVE_FILTER_KEYWORD',
    CLEAR_FILTERS = '@@questionSet/CLEAR_FILTERS',
    CHANGE_SORT_ORDER_VALUE = '@@questionSet/CHANGE_SORT_ORDER_VALUE',
    CHANGE_FILTER_VALUE = '@@questionSet/CHANGE_FILTER_VALUE',
    SHOW_ACTIONS = '@@questionSet/SHOW_ACTIONS',
    HIDE_ACTIONS = '@@questionSet/HIDE_ACTIONS',
    EXPAND_ALL = '@@questionSet/EXPAND_ALL',
    COLLAPSE_ALL = '@@questionSet/COLLAPSE_ALL',

    DELETE_RISK_QUESTION = '@@questionSet/DELETE_RISK_QUESTION',
    DELETE_RISK_FULFILLED = '@@questionSet/DELETE_RISK_FULFILLED',
    DELETE_RISK_ERROR = '@@questionSet/DELETE_RISK_ERROR',

    DOWNLOAD_RISK_QUESTION = '@@questionSet/DOWNLOAD_RISK_QUESTION',
    DOWNLOAD_RISK_QUESTION_FULFILLED = '@@questionSet/DOWNLOAD_RISK_QUESTION_FULFILLED',
    DOWNLOAD_RISK_QUESTION_ERROR = '@@questionSet/DOWNLOAD_RISK_QUESTION_ERROR',
}

export interface IDeleteQuestionSet extends Action {
    type: QuestionSetActions.DELETE_RISK_QUESTION;
    payload: {
        id: string
    };
}

export interface IDeleteQuestionSetFulfilled extends Action {
    type: QuestionSetActions.DELETE_RISK_FULFILLED;
}

export interface IDeleteQuestionSetError extends Action {
    type: QuestionSetActions.DELETE_RISK_ERROR;
}

export interface IAddFilterKeyword extends Action {
    type: QuestionSetActions.ADD_FILTER_KEYWORD;
    payload: {
        keyword: string;
    };
}

export interface IRemoveFilterKeyword extends Action {
    type: QuestionSetActions.REMOVE_FILTER_KEYWORD;
    payload: {
        keyword: string;
    };
}

export interface IClearFilters extends Action {
    type: QuestionSetActions.CLEAR_FILTERS;
}

export interface ILoadQuestionSetsAction extends Action {
    type: QuestionSetActions.LOAD_QUESTION_SETS;
}

export interface IToggleFilterExpanded extends Action {
    type: QuestionSetActions.TOGGLE_FILTER_EXPANDED;
}

export interface ILoadQuestionSetsRejectedAction extends Action {
    type: QuestionSetActions.LOAD_QUESTION_SETS_REJECTED;
    error: true;
    payload: any;
}

export interface ILoadQuestionSetsCancelledAction extends Action {
    type: QuestionSetActions.LOAD_QUESTION_SETS_CANCELLED;
}

export interface ILoadQuestionSetsFulfilledAction extends Action {
    type: QuestionSetActions.LOAD_QUESTION_SETS_FULFILLED;
    payload: {
        questionSets: IQuestionSet[];
    };
}

export interface ISetSortOrderAction extends Action {
    type: QuestionSetActions.CHANGE_SORT_ORDER_VALUE;
    payload: {
        sortColumn?: string;
        sortAscending?: boolean;
    };
}

export interface ISetFilterValueAction extends Action {
    type: QuestionSetActions.CHANGE_FILTER_VALUE;
    payload: {
        field: string;
        value: string;
    };
}

export interface IToggleItemExpanded extends Action {
    type: QuestionSetActions.TOGGLE_ITEM_EXPANDED;
    payload: {
        id: string;
    };
}

export interface IHideActions extends Action {
    type: QuestionSetActions.HIDE_ACTIONS;
    payload: {
        id: string;
    };
}
export interface IExpandAllAction extends Action {
    type: QuestionSetActions.EXPAND_ALL;
    payload: {
        values: string[];
    };
}
export interface ICollapseAllAction extends Action {
    type: QuestionSetActions.COLLAPSE_ALL;
}

export interface IShowActions extends Action {
    type: QuestionSetActions.SHOW_ACTIONS;
    payload: {
        id: string;
    };
}

export interface IChangeRoleAction extends Action {
    type: QuestionSetActions.CHANGE_ROLES;
    payload: {
        value: any,
        action: any
    };
}

export interface IChangeRoleFulfilledAction extends Action {
    type: QuestionSetActions.CHANGE_ROLES_FULFILLED;
    payload: {
        value: IMultiSelectQuestionSetActionModel
    };
}

export interface IToggleFilterExpanded extends Action {
    type: QuestionSetActions.TOGGLE_FILTER_EXPANDED;
    payload: {
        id: Extract<keyof IQuestionSetsGridState, string>;
    };
}

export interface ISelectValue {
    value: string;
    label: string;
    parentId: string;
}

export interface ISelectOption {
    value: string;
    label: string;
    parentId: string;
}

export interface ISelectAction {
    action: string;
    removedValue?: ISelectOption;
    option?: ISelectOption;
}

export interface IMultiSelectQuestionSetActionModel {
    value: ISelectValue[];
    action: ISelectAction;
    parentId: string;
}

export interface IDownloadQuestionSet extends Action {
    type: QuestionSetActions.DOWNLOAD_RISK_QUESTION;
}

export type QuestionSetActionTypes =
IToggleFilterExpanded
| IDeleteQuestionSet
| IDeleteQuestionSetFulfilled
| IDeleteQuestionSetError
| IRemoveFilterKeyword
| IAddFilterKeyword
| IClearFilters
| IToggleFilterExpanded
| IChangeRoleFulfilledAction
| IChangeRoleAction
| ILoadQuestionSetsAction
| ILoadQuestionSetsCancelledAction
| ILoadQuestionSetsFulfilledAction
| ILoadQuestionSetsRejectedAction
| ISetFilterValueAction
| ISetSortOrderAction
| IToggleItemExpanded
| IHideActions
| ICollapseAllAction
| IExpandAllAction
| IShowActions
| IDownloadQuestionSet;
