import * as React from 'react';
import { IKeyValue } from '../../models/key-value';
import { ModalCard, ModalCardBody, ModalCardHeader } from '../modalCard';
import './singleSelectorDialog.scss';
import useTranslate from 'translations/translation-utils';

export interface ISingleSelectorDialogProps {
    fullItemsList: Array<IKeyValue<string>>;
    selectItemId: string;
    selectorType: string;
    subHeading?: string;
    infoMessage: string;
}

export interface ISingleSelectorActionProps {
    onSelectChange: (value: string) => void;
}

interface IAllProps extends ISingleSelectorDialogProps, ISingleSelectorActionProps { }

export const SingleSelectorDialog: React.FC<IAllProps> = ({
    selectorType,
    subHeading,
    fullItemsList,
    onSelectChange,
    selectItemId,
    infoMessage,
}) => {
    const [searchString, setSearchString] = React.useState('');
    const [isShown, setIsShown] = React.useState(false);
    const translate = useTranslate();

    React.useEffect(() => {
        if (selectItemId) {
            setIsShown(false);
        } else {
            setIsShown(true);
        }
    }, [selectItemId]);

    const filteredSites = fullItemsList.filter((item: IKeyValue<string>) => {
        return item.value.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1;
    });

    const updateSearch = (search: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(search.target.value);
    };

    const handleOnClick = (key: string) => {
        onSelectChange(key);
        setIsShown(false);
    };

    return (
        <ModalCard modalId={`${selectorType}-selector`} isShown={isShown}>
            <ModalCardHeader
                modalId={`${selectorType}-selector`}
                title={`${selectorType.toUpperCase()} ${translate('SelectorDialog.Label.1')}`}
                subTitle={subHeading}
                isInterstatial={true}
            />

            <ModalCardBody isInterstatial={true} modalId={`${selectorType}-selector`}>
                {fullItemsList.length ? (
                    <div className="SingleSelector-siteContainer">
                        <div className="SingleSelector-siteSearchContainer">
                            <input
                                className="input is-medium filter-keyword-text"
                                type="text"
                                placeholder={`${translate('SelectorDialog.Label.1')} ${selectorType}`}
                                value={searchString}
                                onChange={updateSearch}
                            />
                        </div>
                        <div className="SingleSelector-sitelist">
                            {filteredSites.map(({ key, value }) => {
                                const onClick = () => handleOnClick(key);
                                return (
                                    <p key={key} className="SingleSelector SingleSelectorItem">
                                        <a onClick={onClick}>{value}</a>
                                    </p>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    infoMessage
                )}
            </ModalCardBody>
        </ModalCard>
    );
};
