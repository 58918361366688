import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';

export const getSelectedSite = createSelector(
    (state: IRootState) => state.app,
    (appState) =>
        appState.lookupSites.length > 0 && appState.siteId && appState.siteId.length > 0
            ? appState.lookupSites.find((site) => site.key === appState.siteId)
            : null
);

const getPermissions = (state: IRootState) => state.app.permissions;
const getSite = (state: IRootState) => state.siteState.site;
const getOperatingPlatforms = (state: IRootState) => state.operatingPlatformState.operatingPlatforms;
const getPerson = createSelector(
    (state: any) => state.person,
    state => state.personProfile
);
const getAppSettings = (state: IRootState) => state.app.appSettings;

export default createSelector(
    getSelectedSite,
    getPermissions,
    getSite,
    getOperatingPlatforms,
    getPerson,
    getAppSettings,
    (selectedSite, permissions, site, allOperatingPlatforms, person, appSettings) => ({
        selectedSite,
        permissions,
        isTestSite: site.isTestSite,
        allOperatingPlatforms,
        person,
        appSettings,
    })
);
