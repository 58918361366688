import * as React from 'react';
import { IKeyedItem } from '../../models/keyed-item';
import { ConfirmDialog } from '../v2/components';
import useTranslate from 'translations/translation-utils';

interface IProps {
    confirmMessage: string;
    itemForConfirmation: IKeyedItem;
    backLinkUrl?: string;
    onConfirm: (item: IKeyedItem, backLinkUrl?: string) => void;
    onClose: (item: IKeyedItem) => void;
    isLoading?: boolean;
}

export const OldConfirmationDialog: React.FC<IProps> = (props) => {
    const translate = useTranslate();

    const onClose = () => {
        props.onClose(props.itemForConfirmation);
    };

    const onConfirm = () => {
        props.onConfirm(props.itemForConfirmation, props.backLinkUrl);
    };

    const showConfirmation = () => {
        if (props.itemForConfirmation) {
            return true;
        }

        return false;
    };

    return (
        <ConfirmDialog
            title={translate('ConfirmActionDialog.Button.Confirm')}
            message={props.confirmMessage}
            isVisible={showConfirmation()}
            onConfirm={onConfirm}
            onClose={onClose}
            isLoading={props.isLoading}
        />
    );
};
