import { Formik, FormikProps } from 'formik';
import * as React from 'react';
import { date, object, string } from 'yup';

import { BulmaColor } from '../../enums/BulmaColor';
import { BulmaSize } from '../../enums/BulmaSize';
import { IKeyValue } from '../../models/key-value';
import { IPersonLookup } from '../../models/person-lookup';
import { ISelectOption } from '../../models/select-option';
import { formatDate, getTimezoneDiffrence } from '../../utils/date-utils';
import { formikFieldCss, onPopoutSelectChange, orderDropdownByKey } from '../../utils/form-utils';
import { DatePicker } from '../datepicker/DatePicker';
import { Control, Field, Help } from '../form';
import { Modal } from '../modal';
import { PopoutSelect } from '../popoutSelect';
import { Select } from '../select';

import './action-upsert-modal.scss';
import { IAction } from 'models/action';
import { Input } from 'components/form';
import { Button } from '../v2/components';
import { Loader } from '../loader';
import useTranslate from 'translations/translation-utils';

export interface IProps {
    isShown: boolean;
    isLoading?: boolean;
    lookupActionCategories: Array<IKeyValue<string>>;
    lookupActionPriorities: Array<IKeyValue<string>>;
    lookupActionStatuses: Array<IKeyValue<string>>;
    lookupSitePeopleRoles: IPersonLookup[];
    lookupSites: Array<IKeyValue<string>>;
    upsertActionRequest?: Partial<IAction>;
    disableClosedAndCompleted?: boolean;
    disablePendingApproval?: boolean;
    archiveButtonEnabled?: boolean;
    canEditInExpertReview?: () => boolean;
    disableAutoGeneratedActionFields?: boolean;
    isActionEditable?: boolean;
}

export interface IActionProps {
    onClose: () => void;
    onSubmit: (request: IAction) => void;
    onArchive?: (action: Partial<IAction>) => void; // only pass if archiveButtonEnabled = true
}

export const ActionUpsertModal: React.FC<IProps & IActionProps> = ({ isLoading, ...props }) => {
    const translate = useTranslate();
    const onClose: React.MouseEventHandler<HTMLButtonElement> = () => {
        props.onClose();
    };

    const onSubmit = (upsertActionRequest: IAction) => {
        props.onSubmit(upsertActionRequest);
    };

    const upsertActionSchema = object().shape({
        siteId: string().required(translate('ValidationMessages.Action.SelectSite')),
        title: string().max(2048, 'Title can have maximum 2048 characters.').required(translate('ValidationMessages.Action.SelectTitle')),
        description: string().max(10000, 'Description can have maximum 10000 characters.').required(translate('ValidationMessages.Action.SelectDescription')),
        categoryId: string().required(translate('ValidationMessages.Action.SelectCategory')),
        priorityId: string().required(translate('ValidationMessages.Action.SelectPriority')),
        statusId: string().required(translate('ValidationMessages.Action.SelectStatus')),
        assigneeId: string().required(translate('ValidationMessages.Action.SelectAssignee')).nullable(true),
        reminderDate: date(),
        dueDate: date().when(
            'reminderDate',
            (reminderDateValue: Date, schema) =>
                reminderDateValue &&
                schema.min(reminderDateValue, translate('ValidationMessages.Action.SelectDueDate'))
        ),
    });

    const renderForm = (formikBag: FormikProps<IAction>) => {
        if (!formikBag.values.site && formikBag.initialValues.siteId) {
            const site = props.lookupSites.find((x) => x.key === formikBag.initialValues.siteId);
            if (site) {
                formikBag.setFieldValue('site', site.value);
            }
        }
        // Default site if you're in a site context, otherwise allow you to select one
        const withinSiteContext = Boolean(formikBag.initialValues.siteId);

        const getLookupSitePeople = (lookups: IPersonLookup[]) => {
            const typeaheadOptions: ISelectOption[] = lookups.map((lookup) => {
                const roles = lookup.roleNames.join(', ');
                if (roles) {
                    const label = `${lookup.label} (${roles})`;
                    return { label, value: lookup.id };
                } else {
                    return { label: lookup.label, value: lookup.id };
                }
            });

            return typeaheadOptions;
        };

        const handleDateChange = (e: Date, fieldName: string) => {
            formikBag.setFieldValue(fieldName, getTimezoneDiffrence(e));
        };

        const handleCategoryChange = (e: any) => {
            const value = e.target.value;
            const category = props.lookupActionCategories.find(x => x.key === value).label;
            formikBag.setFieldValue('category', category);
            formikBag.setFieldValue('categoryId', value);
        };
        const handlePriotityChange = (e: any) => {
            const value = e.target.value;
            const priority = props.lookupActionPriorities.find(x => x.key === value).label;
            formikBag.setFieldValue('priorityId', value);
            formikBag.setFieldValue('priority', priority);
        };
        const handleStatusChange = (e: any) => {
            const value = e.target.value;
            formikBag.setFieldValue('statusId', value);
            formikBag.setFieldValue('status', value);
        };

        // When creating a new ACTION we must filter out non-relevant 'Not Required' and 'Cancelled' status.
        const filterlookupActionStatus = (lookupActionStatus: Array<IKeyValue<string>>) => {
            let newlookUpActionStatues: Array<IKeyValue<string>> = lookupActionStatus;
            if (!formikBag.values.id) {
                newlookUpActionStatues = newlookUpActionStatues.filter(
                    (x) => x.key !== 'Cancelled' && x.key !== 'Not Required'
                );
            }

            if (props.disableClosedAndCompleted) {
                newlookUpActionStatues = newlookUpActionStatues.filter(
                    x => x.key !== 'Closed' && x.key !== 'Completed'
                );
            }

            if (props.disableClosedAndCompleted) {
                newlookUpActionStatues = newlookUpActionStatues.filter(
                    x => x.key !== 'Pending Approval'
                );
            }

            return newlookUpActionStatues;
        };

        const onArchive = (action: Partial<IAction>) => {
            props.onArchive(action);
        };
        const selectAssignee = () => {
            if (!getLookupSitePeople(props.lookupSitePeopleRoles).some((option) => option.value === formikBag.values.assigneeId) && formikBag.values.assignee && formikBag.values.assigneeId) {
                return { label: formikBag.values.assignee && formikBag.values.assignee.displayLabel, value: formikBag.values.assignee && formikBag.values.assignee.id }
            }
        }
        return (
            <Loader loading={isLoading}>
                <form className="action-upsert-modal" onSubmit={formikBag.handleSubmit}>
                    <div className="columns">
                        <div className="column is-9">
                            <Field
                                isHorizontal={true}
                                htmlFor="siteId"
                                label={translate('RiskActionTab.Label.Site')}
                                labelSize={BulmaSize.Medium}
                            >
                                <Field>
                                    <Control>
                                        <Select
                                            id="siteId"
                                            name="siteId"
                                            options={props.lookupSites}
                                            className={formikFieldCss(formikBag, 'siteId')}
                                            value={formikBag.values.siteId}
                                            disabled={withinSiteContext}
                                            onChange={formikBag.handleChange}
                                            onBlur={formikBag.handleBlur}
                                            emptyOptionsValue={translate(
                                                'RiskActionTab.Placeholder.emptyOptionsValue'
                                            )}
                                        />
                                    </Control>
                                    <Help
                                        isShown={formikBag.touched.siteId}
                                        bulmaColor={BulmaColor.Danger}
                                    >
                                        {formikBag.errors.siteId}
                                    </Help>
                                </Field>
                            </Field>

                            <Field
                                isHorizontal={true}
                                htmlFor="title"
                                label={`${translate('RiskActionTab.Label.Title')}*`}
                                labelSize={BulmaSize.Medium}
                            >
                                <Field>
                                    <Control>
                                        <Input
                                            className="is-medium"
                                            name="title"
                                            id="title"
                                            value={formikBag.values.title}
                                            onChange={formikBag.handleChange}
                                            onBlur={formikBag.handleBlur}
                                        />
                                    </Control>
                                    <Help
                                        isShown={formikBag.touched.title}
                                        bulmaColor={BulmaColor.Danger}
                                    >
                                        {formikBag.errors.title}
                                    </Help>
                                </Field>
                            </Field>

                            <Field
                                isHorizontal={true}
                                htmlFor="description"
                                label={`${translate('RiskActionTab.Label.Description')}*`}
                                labelSize={BulmaSize.Medium}
                            >
                                <Field>
                                    <Control>
                                        <textarea
                                            id="description"
                                            name="description"
                                            className={formikFieldCss(
                                                formikBag,
                                                'description',
                                                'textarea'
                                            )}
                                            placeholder={translate(
                                                'RiskActionTab.Placeholder.Description'
                                            )}
                                            value={formikBag.values.description}
                                            onChange={formikBag.handleChange}
                                            onBlur={formikBag.handleBlur}
                                        />
                                    </Control>
                                    <Help
                                        isShown={formikBag.touched.description}
                                        bulmaColor={BulmaColor.Danger}
                                    >
                                        {formikBag.errors.description}
                                    </Help>
                                </Field>
                            </Field>

                            <Field
                                isHorizontal={true}
                                htmlFor="reminderDate"
                                label={translate('RiskActionTab.Label.ReminderDate')}
                                labelSize={BulmaSize.Medium}
                            >
                                <Field>
                                    <Control>
                                        <DatePicker
                                            id="reminderDate"
                                            name="reminderDate"
                                            value={formatDate(formikBag.values.reminderDate)}
                                            onChange={(e) => {
                                                handleDateChange(e, 'reminderDate');
                                            }}
                                        />
                                    </Control>
                                    <Help
                                        isShown={formikBag.touched.reminderDate}
                                        bulmaColor={BulmaColor.Danger}
                                    >
                                        {formikBag.errors.reminderDate}
                                    </Help>
                                </Field>
                            </Field>

                            <Field
                                isHorizontal={true}
                                htmlFor="dueDate"
                                label={translate('RiskActionTab.Label.DueDate')}
                                labelSize={BulmaSize.Medium}
                            >
                                <Field>
                                    <Control>
                                        <DatePicker
                                            id="dueDate"
                                            name="dueDate"
                                            value={formatDate(formikBag.values.dueDate)}
                                            onChange={(e) => {
                                                handleDateChange(e, 'dueDate');
                                            }}
                                        />
                                    </Control>
                                    <Help
                                        isShown={formikBag.touched.dueDate}
                                        bulmaColor={BulmaColor.Danger}
                                    >
                                        {formikBag.errors.dueDate}
                                    </Help>
                                </Field>
                            </Field>

                            <Field
                                isHorizontal={true}
                                htmlFor="categoryId"
                                label={`${translate('RiskActionTab.Label.Category')}*`}
                                labelSize={BulmaSize.Medium}
                            >
                                <Field>
                                    <Control>
                                        <Select
                                            id="categoryId"
                                            name="categoryId"
                                            options={
                                                props.lookupActionCategories.map((c) => ({
                                                    key: c.key,
                                                    value: translate(
                                                        'RiskActionTab.Category.dropdown.'.concat(
                                                            c.value.replace(/\s/g, '')
                                                        )
                                                    ),
                                                }))
                                            }
                                            className={formikFieldCss(formikBag, 'categoryId')}
                                            value={formikBag.values.categoryId}
                                            onChange={handleCategoryChange}
                                            onBlur={formikBag.handleBlur}
                                            emptyOptionsValue={translate(
                                                'RiskActionTab.Placeholder.emptyOptionsValue'
                                            )}
                                            disabled={props.disableAutoGeneratedActionFields}
                                        />
                                    </Control>
                                    <Help
                                        isShown={formikBag.touched.categoryId}
                                        bulmaColor={BulmaColor.Danger}
                                    >
                                        {formikBag.errors.categoryId}
                                    </Help>
                                </Field>
                            </Field>

                            <Field
                                isHorizontal={true}
                                htmlFor="priorityId"
                                label={`${translate('RiskActionTab.Label.Priority')}*`}
                                labelSize={BulmaSize.Medium}
                            >
                                <Field>
                                    <Control>
                                        <Select
                                            id="priorityId"
                                            name="priorityId"
                                            options={orderDropdownByKey(
                                                props.lookupActionPriorities.map((c) => ({
                                                    key: c.key,
                                                    value: translate(
                                                        'RiskActionTab.Priority.dropdown.'.concat(
                                                            c.value.replace(/\s/g, '')
                                                        )
                                                    ),
                                                }))
                                            )}
                                            className={formikFieldCss(formikBag, 'priorityId')}
                                            value={formikBag.values.priorityId}
                                            onChange={handlePriotityChange}
                                            onBlur={formikBag.handleBlur}
                                            emptyOptionsValue={translate(
                                                'RiskActionTab.Placeholder.emptyOptionsValue'
                                            )}
                                            disabled={props.disableAutoGeneratedActionFields}
                                        />
                                    </Control>
                                    <Help
                                        isShown={formikBag.touched.priorityId}
                                        bulmaColor={BulmaColor.Danger}
                                    >
                                        {formikBag.errors.priorityId}
                                    </Help>
                                </Field>
                            </Field>

                            <Field
                                isHorizontal={true}
                                htmlFor="statusId"
                                label={`${translate('RiskActionTab.Label.Status')}*`}
                                labelSize={BulmaSize.Medium}
                            >
                                <Field>
                                    <Control>
                                        <Select
                                            id="statusId"
                                            name="statusId"
                                            options={orderDropdownByKey(
                                                filterlookupActionStatus(
                                                    props.lookupActionStatuses.map((c) => ({
                                                        key: c.key,
                                                        value: translate(
                                                            'RiskActionTab.ActionStatus.dropdown.'.concat(
                                                                c.value.replace(/\s/g, '')
                                                            )
                                                        ),
                                                    }))
                                                )
                                            )}
                                            className={formikFieldCss(formikBag, 'statusId')}
                                            value={formikBag.values.statusId}
                                            onChange={handleStatusChange}
                                            onBlur={formikBag.handleBlur}
                                            emptyOptionsValue={translate(
                                                'RiskActionTab.Placeholder.emptyOptionsValue'
                                            )}
                                        />
                                    </Control>
                                    <Help
                                        isShown={formikBag.touched.statusId}
                                        bulmaColor={BulmaColor.Danger}
                                    >
                                        {formikBag.errors.statusId}
                                    </Help>
                                </Field>
                            </Field>

                            <Field
                                isHorizontal={true}
                                htmlFor="assigneeId"
                                label={translate('RiskActionTab.Label.Assignee')}
                                labelSize={BulmaSize.Medium}
                            >
                                <Field>
                                    <Control>
                                        <PopoutSelect
                                            id="assigneeId"
                                            name="assigneeId"
                                            className={formikFieldCss(formikBag, 'assigneeId')}
                                            value={formikBag.values.assigneeId}
                                            onBlur={formikBag.handleBlur}
                                            onChange={formikBag.handleChange}
                                            secondaryValue={selectAssignee()}
                                            onSelectChange={onPopoutSelectChange.bind(
                                                this,
                                                formikBag,
                                                'assigneeId'
                                            )}
                                            options={getLookupSitePeople(
                                                props.lookupSitePeopleRoles
                                            )}
                                        />
                                    </Control>
                                    <Help
                                        isShown={formikBag.touched.assigneeId}
                                        bulmaColor={BulmaColor.Danger}
                                    >
                                        {formikBag.errors.assigneeId}
                                    </Help>
                                </Field>
                            </Field>
                            {selectAssignee() && <p className="has-text-warning margin-small">
                                {translate('Globals.Warning.InactiveUser')}
                            </p>}
                        </div>
                        <div className="column is-3">
                            {(props.canEditInExpertReview == null ||
                                (props.canEditInExpertReview && props.canEditInExpertReview())) && (
                                    <>
                                        <Button
                                            id="btnSaveAction"
                                            type="submit"
                                            isLoading={isLoading}
                                            disabled={
                                                (props.isActionEditable === false)
                                                ||
                                                (!formikBag.dirty ||
                                                    formikBag.isSubmitting)
                                            }
                                        >
                                            {translate('RiskActionTab.Button.Save')}
                                        </Button>
                                        <br />
                                        <br />
                                        {props.archiveButtonEnabled &&
                                            props.upsertActionRequest &&
                                            props.upsertActionRequest.id && (
                                                <>
                                                    <Button
                                                        id="btnArchiveAction"
                                                        buttonType="archive"
                                                        isLoading={isLoading}
                                                        onClick={() => onArchive(props.upsertActionRequest)}
                                                    >
                                                        {translate('RiskActionTab.Button.Archive')}
                                                    </Button>
                                                    <br />
                                                    <br />`
                                                </>
                                            )}
                                    </>
                                )}

                            <Button
                                id="btnCancelAction"
                                buttonType="cancel"
                                onClick={onClose}
                                isLoading={isLoading}
                            >
                                {translate('RiskActionTab.Button.Cancel')}
                            </Button>
                        </div>
                    </div>
                </form>
            </Loader>
        );
    };

    return (
        <Modal modalId="upsertActionModal" isShown={props.isShown}>
            <Formik<Partial<IAction>>
                initialValues={props.upsertActionRequest}
                validationSchema={upsertActionSchema}
                onSubmit={onSubmit}
                render={renderForm}
            />
        </Modal>
    );
};

ActionUpsertModal.defaultProps = {
    upsertActionRequest: {
        id: '',
        siteId: '',
        riskItemId: '',
        description: '',
        categoryId: '',
        priorityId: '0',
        statusId: '',
        reminderDate: '',
        assigneeId: null,
    },
};
