import { createSelector } from 'reselect';

import { ISiteRelationshipsRootState } from 'reducers/siteRelationships';

export default createSelector(
    (state: ISiteRelationshipsRootState) => state,
    (state) => ({
        selectedSite: state.siteRelationships.siteSelector.selectedSite,
        permissions: state.app.permissions,
    })
);
