import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { ShiftHandoverPage } from './shift-handover-page';
import { loadShiftHandovers, loadSettings } from 'actions/actions-v2/shift-handover-actions';
import { getShiftHandoverSettings, getPermissions } from 'sharedSelectors';

const getSiteId = (state: IRootState) => state.app.siteId;
const getIsLoading = (state: IRootState) => state.shiftHandoverState.isLoading;
const getShiftHandovers = (state: IRootState) => state.shiftHandoverState.shiftHandovers;

const mapStateToProps = createSelector(
    getSiteId,
    getIsLoading,
    getShiftHandoverSettings,
    getShiftHandovers,
    getPermissions,
    (
        siteId,
        isLoading,
        shiftHandoverSettings,
        shiftHandovers,
        permissions,
    ) => ({
        siteId,
        isLoading,
        shiftHandoverSettings,
        shiftHandovers,
        permissions,
    })
);

const mapDispatchToProps = {
    loadShiftHandovers,
    loadSettings,
};

export const ShiftHandoverPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ShiftHandoverPage);
