import { Action } from 'redux';
import { IActionAttachmentCollection } from '../models/action-attachments';
import { IActionAuditHistory } from '../models/action-audit-history';
import { IActionsState } from '../reducers/actions';
import { IAction } from 'models/action';
import { IIncident } from 'models';
import { IRisk } from 'models/risk';

export enum ActionActions {
    SHOW_UPSERT_ACTION_MODAL = '@@actions/SHOW_UPSERT_ACTION_MODAL',
    HIDE_UPSERT_ACTION_MODAL = '@@actions/HIDE_UPSERT_ACTION_MODAL',

    UPSERT_ACTION_TO_INCIDENT = '@@risks/UPSERT_ACTION_TO_INCIDENT',
    UPSERT_ACTION_TO_INCIDENT_FULFILLED = '@@risks/UPSERT_ACTION_TO_INCIDENT_FULFILLED',
    UPSERT_ACTION_TO_INCIDENT_REJECTED = '@@risks/UPSERT_ACTION_TO_INCIDENT_REJECTED',
    UPSERT_ACTION_TO_INCIDENT_CANCELLED = '@@risks/UPSERT_ACTION_TO_INCIDENT_CANCELLED',

    DELETE_ACTION = '@@actions/DELETE_ACTION',
    DELETE_ACTION_FULFILLED = '@@actions/DELETE_ACTION_FULFILLED',
    DELETE_ACTION_REJECTED = '@@actions/DELETE_ACTION_REJECTED',
    DELETE_ACTION_CANCELLED = '@@actions/DELETE_ACTION_CANCELLED',
    DELETE_ACTION_CONFIRM = '@@actions/DELETE_ACTION_CONFIRM',
    DELETE_ACTION_CANCEL = '@@actions/DELETE_ACTION_CANCEL',

    SHOW_HISTORY = '@@actions/SHOW_HISTORY',
    HIDE_HISTORY = '@@actions/HIDE_HISTORY',
    RELOAD_HISTORY = '@@actions/RELOAD_HISTORY',

    LOAD_HISTORY = '@@actions/LOAD_HISTORY',
    LOAD_HISTORY_FULFILLED = '@@actions/LOAD_HISTORY_FULFILLED',
    LOAD_HISTORY_REJECTED = '@@actions/LOAD_HISTORY_REJECTED',
    LOAD_HISTORY_CANCELLED = '@@actions/LOAD_HISTORY_CANCELLED',

    SHOW_ATTACHMENTS = '@@actions/SHOW_ATTACHMENTS',
    HIDE_ATTACHMENTS = '@@actions/HIDE_ATTACHMENTS',

    RELOAD_ATTACHMENTS = '@@actions/RELOAD_ATTACHMENTS',
    LOAD_ATTACHMENTS = '@@actions/LOAD_ATTACHMENTS',
    LOAD_ATTACHMENTS_FULFILLED = '@@actions/LOAD_ATTACHMENTS_FULFILLED',
    LOAD_ATTACHMENTS_REJECTED = '@@actions/LOAD_ATTACHMENTS_REJECTED',
    LOAD_ATTACHMENTS_CANCELLED = '@@actions/LOAD_ATTACHMENTS_CANCELLED',

    TOGGLE_FILTER_EXPANDED = '@@actions/TOGGLE_FILTER_EXPANDED',
    TOGGLE_ITEM_EXPANDED = '@@actions/TOGGLE_ITEM_EXPANDED',
    SHOW_ACTIONS = '@@actions/SHOW_ACTIONS',
    HIDE_ACTIONS = '@@actions/HIDE_ACTIONS',

    LOAD_PARENT = '@@actions/LOAD_PARENT',
    LOAD_PARENT_FULFILLED = '@@actions/LOAD_PARENT_FULFILLED',
    LOAD_PARENT_REJECTED = '@@actions/LOAD_PARENT_REJECTED',
    LOAD_PARENT_CANCELLED = '@@actions/LOAD_PARENT_CANCELLED',
}

export const hideModal = (): ActionActionTypes => ({
    type: ActionActions.HIDE_UPSERT_ACTION_MODAL,
});

export const onShowUpsertActionModal = (siteId: string, userId: string): ActionActionTypes => ({
    type: ActionActions.SHOW_UPSERT_ACTION_MODAL,
    payload: {
        item: {
            title: '',
            siteId: siteId ? siteId : '',
            riskItemId: null,
            assigneeId: userId ? '' : '',
            dueDate: new Date().toISOString(),
            reminderDate: new Date().toISOString(),
            categoryId: '',
            priorityId: '',
            description: '',
            status: 'Open',
            statusId: 'Open',
        },
    },
});

export const onShowEditActionModal = (upsertRequest: IAction): ActionActionTypes => ({
    type: ActionActions.SHOW_UPSERT_ACTION_MODAL,
    payload: {
        item: upsertRequest,
    },
});

export interface IToggleFilterExpanded extends Action {
    type: ActionActions.TOGGLE_FILTER_EXPANDED;
    payload: {
        id: Extract<keyof IActionsState, string>;
    };
}

export interface IToggleItemExpanded extends Action {
    type: ActionActions.TOGGLE_ITEM_EXPANDED;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface IHideActions extends Action {
    type: ActionActions.HIDE_ACTIONS;
    payload: {
        id: string;
    };
}

export interface IShowActions extends Action {
    type: ActionActions.SHOW_ACTIONS;
    payload: {
        id: string;
    };
}

export interface IDeleteAction extends Action {
    type: ActionActions.DELETE_ACTION;
    payload: {
        item: IAction;
    };
}

export interface IDeleteActionRejectedAction extends Action {
    type: ActionActions.DELETE_ACTION_REJECTED;
    error: true;
    payload: {
        errorMessage: string;
    };
}

export interface IDeleteActionCancelledAction extends Action {
    type: ActionActions.DELETE_ACTION_CANCELLED;
}

export interface IDeleteActionFulfilledAction extends Action {
    type: ActionActions.DELETE_ACTION_FULFILLED;
    payload: {};
}

export interface IDeleteActionConfirm extends Action {
    type: ActionActions.DELETE_ACTION_CONFIRM;
    payload: {
        item: IAction;
    };
}

export interface IDeleteActionCancel extends Action {
    type: ActionActions.DELETE_ACTION_CANCEL;
    payload: {
        item: IAction;
    };
}

export interface IShowHistory extends Action {
    type: ActionActions.SHOW_HISTORY;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface IHideHistory extends Action {
    type: ActionActions.HIDE_HISTORY;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface IReloadHistory extends Action {
    type: ActionActions.RELOAD_HISTORY;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface ILoadAuditHistory extends Action {
    type: ActionActions.LOAD_HISTORY;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface ILoadAuditHistoryCancelled extends Action {
    type: ActionActions.LOAD_HISTORY_CANCELLED;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface ILoadAuditHistoryRejectedAction extends Action {
    type: ActionActions.LOAD_HISTORY_REJECTED;
    error: true;
    payload: {
        id: string;
        siteId: string;
        errorMessage: string;
    };
}

export interface ILoadAuditHistoryFulfilledAction extends Action {
    type: ActionActions.LOAD_HISTORY_FULFILLED;
    payload: {
        id: string;
        siteId: string;
        data: IActionAuditHistory[];
    };
}

export interface IShowAttachments extends Action {
    type: ActionActions.SHOW_ATTACHMENTS;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface IHideAttachments extends Action {
    type: ActionActions.HIDE_ATTACHMENTS;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface IReloadAttachmentsAction extends Action {
    type: ActionActions.RELOAD_ATTACHMENTS;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface ILoadAttachmentsAction extends Action {
    type: ActionActions.LOAD_ATTACHMENTS;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface ILoadAttachmentsCancelledAction extends Action {
    type: ActionActions.LOAD_ATTACHMENTS_CANCELLED;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface ILoadAttachmentsRejectedAction extends Action {
    type: ActionActions.LOAD_ATTACHMENTS_REJECTED;
    error: true;
    payload: {
        id: string;
        siteId: string;
        errorMessage: string;
    };
}

export interface ILoadAttachmentsFulfilledAction extends Action {
    type: ActionActions.LOAD_ATTACHMENTS_FULFILLED;
    payload: {
        id: string;
        siteId: string;
        data: IActionAttachmentCollection[];
    };
}

export interface IShowUpsertActionModal extends Action {
    type: ActionActions.SHOW_UPSERT_ACTION_MODAL;
    payload: {
        item: Partial<IAction>;
    };
}

export interface IHideUpsertActionModal extends Action {
    type: ActionActions.HIDE_UPSERT_ACTION_MODAL;
}

export interface ILoadParent extends Action {
    type: ActionActions.LOAD_PARENT;
    payload: {
        id: string;
        riskItemId: string;
        siteId: string;
    };
}

export interface ILoadParentCancelled extends Action {
    type: ActionActions.LOAD_PARENT_CANCELLED;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface ILoadParentRejectedAction extends Action {
    type: ActionActions.LOAD_PARENT_REJECTED;
    error: true;
    payload: {
        id: string;
        siteId: string;
        errorMessage: string;
    };
}

export interface ILoadParentFulfilledAction extends Action {
    type: ActionActions.LOAD_PARENT_FULFILLED;
    payload: {
        id: string;
        siteId: string;
        data: IRisk;
    };
}

export const onToggleExpanded = (action: IAction): ActionActionTypes => ({
    type: ActionActions.TOGGLE_ITEM_EXPANDED,
    payload: {
        id: action.id,
        siteId: action.siteId,
    },
});

export const onSubmitActionOnIncident = (
    upsertActionRequest: IAction,
    incident: Partial<IIncident>
): ActionActionTypes => ({
    type: ActionActions.UPSERT_ACTION_TO_INCIDENT,
    payload: {
        incident,
        request: upsertActionRequest,
    },
});

export interface IUpsertActionToIncident extends Action {
    type: ActionActions.UPSERT_ACTION_TO_INCIDENT;
    payload: {
        incident: Partial<IIncident>;
        request: IAction;
    };
}

export interface IUpsertActionToIncidentRejectedAction extends Action {
    type: ActionActions.UPSERT_ACTION_TO_INCIDENT_REJECTED;
    error: true;
    payload: any;
}

export interface IUpsertActionToIncidentCancelledAction extends Action {
    type: ActionActions.UPSERT_ACTION_TO_INCIDENT_CANCELLED;
}

export interface IUpsertActionToIncidentFulfilledAction extends Action {
    type: ActionActions.UPSERT_ACTION_TO_INCIDENT_FULFILLED;
    payload: {};
}

export type ActionActionTypes =
    | IUpsertActionToIncident
    | IUpsertActionToIncidentRejectedAction
    | IUpsertActionToIncidentCancelledAction
    | IUpsertActionToIncidentFulfilledAction
    | IShowUpsertActionModal
    | IHideUpsertActionModal
    | IDeleteAction
    | IDeleteActionCancelledAction
    | IDeleteActionFulfilledAction
    | IDeleteActionRejectedAction
    | IDeleteActionCancel
    | IDeleteActionConfirm
    | IShowHistory
    | IHideHistory
    | IReloadHistory
    | ILoadAuditHistory
    | ILoadAuditHistoryCancelled
    | ILoadAuditHistoryFulfilledAction
    | ILoadAuditHistoryRejectedAction
    | IShowAttachments
    | IHideAttachments
    | IReloadAttachmentsAction
    | ILoadAttachmentsAction
    | ILoadAttachmentsCancelledAction
    | ILoadAttachmentsFulfilledAction
    | ILoadAttachmentsRejectedAction
    | IToggleFilterExpanded
    | IToggleItemExpanded
    | IHideActions
    | IShowActions
    | ILoadParent
    | ILoadParentCancelled
    | ILoadParentFulfilledAction
    | ILoadParentRejectedAction;
