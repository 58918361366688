import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { showFilterModal } from 'actions/incident-actions';
import { exportIncidents } from 'actions/actions-v2/incident-action-v2';
import { IncidentsGridToolbar } from './incidents-grid-toolbar';
import { IRootState } from 'routes/store';

const getSiteId = (state: IRootState) => state.app.siteId;
const getPermissions = (state: IRootState) => state.app.permissions;
    
const mapToState = createSelector(
    getSiteId,
    getPermissions,
    (siteId, permissions) => ({
        siteId,
        permissions,
    })
);

const mapDispatchToProps = {
    onShowFilterModal: showFilterModal,
    exportIncidents
};

export const IncidentsGridToolbarContainer = connect(
    mapToState,
    mapDispatchToProps
)(IncidentsGridToolbar);
