import { combineEpics } from 'redux-observable';

import { buildingEpics } from './building-epics';
import { clientEpics } from './client-epics';
import { personEpics } from './person-epics';
import { siteEpics } from './site-epics';

export const manageSiteRelationships = combineEpics(
    buildingEpics,
    clientEpics,
    personEpics,
    siteEpics,
);
