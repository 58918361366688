import * as React from 'react';
import { RelationshipType } from 'reducers/manageRoles/manageRoles-grid';
import { GridSortLink } from '../../../../components/gridSortLink';
import { Loader } from '../../../../components/loader';
import { SortDirection } from '../../../../enums/SortDirection';
import { RouteUrl } from '../../routes';
import { IManageRoleListStateProps } from '../manage-roles-page';
import { IPerson } from 'models/person';
import InfiniteScroll from 'react-infinite-scroller';
import { getLabelsWithSeparator } from 'utils/keyvalue-utils';
import { Button } from 'components/v2/components';
import './manage-role-list.scss';
import useTranslate from 'translations/translation-utils';
import Dotdotdot from 'react-dotdotdot';

export interface IManageRoleListDispatchProps {
    onRouteChange: (url: string) => void;
    onSort: (sortColumn: string, sortAscending: boolean) => void;
    onRoleChange: (value: any, action: any) => void;
    onToggleExpanded: (id: string) => void;
    onToggleRelationshipCheckedFilter: (id: string, type: RelationshipType, value: string) => void;
    onToggleAllRelationshipCheckedFilter: (
        id: string,
        type: RelationshipType,
        value: string[],
        allChecked: boolean
    ) => void;
    onToggleAllClientRelationshipCheckedFilter: (
        id: string,
        type: RelationshipType,
        value: string[],
        allChecked: boolean
    ) => void;
    onToggleExpandClient: (id: string, clientName: string) => void;
    loadMorePersons: () => void;
    sortColumn: string;
    sortAscending: boolean;
    hasMoreItems: boolean;
}

interface IActionListProps extends IManageRoleListStateProps, IManageRoleListDispatchProps { }

export const ManageRoleList: React.FC<IActionListProps> = (props) => {
    const translate = useTranslate();

    const getPersonRow = (person: IPerson, onChangeRoute: (url: string) => void) => {
        return (
            <div key={person.id} className="ManageRoleItem">
                <div className={`columns user-list-item${!person.isActive ? " inactive" : ""}`}>
                    <div className="column is-2">
                        {person.firstName} {person.lastName}
                    </div>
                    <div className="column is-3">
                        <Dotdotdot clamp={1}>{person.emailAddress1}</Dotdotdot>
                    </div>
                    <div className="column is-2">{person.businessTitle}</div>
                    <div className="column is-1">
                        {person.siteCount}
                    </div>
                    <div className="column is-2">{getLabelsWithSeparator(person.roles)}</div>
                    <div className="column is-2 is-narrow">
                        <Button
                            onClick={onChangeRoute.bind(this, `${RouteUrl.PersonUpsert}/${person.id}`)}
                        >
                            {translate('Globals.Label.Edit')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    const getGridHeader = (label: string, fieldName: string, props: IActionListProps) => {
        const onSort = (sortField: string, sortDirection: SortDirection) =>
            props.onSort(sortField, sortDirection === SortDirection.Ascending);

        return (
            <GridSortLink
                currentSortFieldName={props.sortColumn}
                currentSortDirection={
                    props.sortAscending ? SortDirection.Ascending : SortDirection.Descending
                }
                sortFieldName={fieldName}
                onSort={onSort}
            >
                {label}
            </GridSortLink>
        );
    };

    const loadMore = (): any => {
        if (!props.isLoading && props.hasMoreItems) {
            props.loadMorePersons();
        }
    };

    return (
        <>
            <div className={`grid-headers columns user-list-headers ${props.isLoading ? 'is-loading' : ''}`}>
                <div className="column is-2">{getGridHeader(translate('ManageRole.ManageRoleList.Name'), 'firstName', props)}</div>
                <div className="column is-3">{getGridHeader(translate('ManageRole.ManageRoleList.Email'), 'emailAddress1', props)}</div>
                <div className="column is-2">
                    {getGridHeader(translate('ManageRole.ManageRoleList.BusinessTitle'), 'businessTitle', props)}
                </div>
                <div className="column is-1">
                    {getGridHeader(translate('SideNavBar.Labels.Sites'), 'sitesResponsibilities', props)}
                </div>
                <div className="column is-2">
                    <div className="GridSortLink">{translate('GainAccess.Labels.Roles')}</div>
                </div>
                <div className="column is-2 is-narrow">
                    <div className="GridSortLink">{translate('ManageRole.ManageRoleList.Action')}</div>
                </div>
            </div>
            <InfiniteScroll
                loadMore={loadMore}
                hasMore={props.hasMoreItems}
                loader={<Loader loading={true} />}
            >
                {props.items &&
                    props.items.map((person) => getPersonRow(person, props.onRouteChange))}
            </InfiniteScroll>
        </>
    );
};
