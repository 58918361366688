import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { CriticalSystemSpareActions } from 'actions/actions-v2/critical-system-spares-actions';

const controllerName = 'CriticalSystemSpareSummary';
const apiPath = `/api/${controllerName}`;

const getActions = {
    action: CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARES_SUMMARY,
    actionFulfilled: CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARES_SUMMARY_FULFILLED,
    actionRejected: CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARES_SUMMARY_REJECTED
} as IActionType;

const loadEpic: Epic<IAppAction, IAppAction> = (action$, state$) => new BaseEpic(action$, state$, getActions).getById<string>(apiPath);

export const criticalSystemSpareSummaryEpicsV2 = combineEpics(
    loadEpic
);
