import { FormikErrors } from 'formik';
import { IKeyValue } from '../../../models/key-value';
import { IQuestionSet } from '../../../models/question-set';

export const yesNoOptions: Array<IKeyValue<string>> = [
    {
        key: 'Yes',
        value: JSON.stringify(true),
        label: 'Yes'
    },
    {
        key: 'No',
        value: JSON.stringify(false),
        label: 'No'
    },
];

export const dueDateUnitOptions: Array<IKeyValue<string>> = [
    {
        key: 'Days',
        value: 'day',
    },
    {
        key: 'Months',
        value: 'month',
    },
];

export const formikBagValidation = (formErrors: FormikErrors<Partial<IQuestionSet>>) => {
    for (const element in formErrors.questions) {
        if (element !== undefined) {
            return false;
        }
    }

    return true;
};
