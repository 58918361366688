import { isNil } from 'lodash';
import * as React from 'react';

export interface IProps extends React.HTMLAttributes<HTMLSpanElement> {
    value: number;
    maxDecimals?: number;
}

const getFormat = (maximumFractionDigits: number) => new Intl.NumberFormat(
    ['en-GB', 'en-US'],
    { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits }
);

function getNumberAsString(input: number, maxDecimals?: number) {
    if (isNil(maxDecimals)) {
        return `${input}`;
    }

    return getFormat(maxDecimals).format(input);
}

export const Percentage: React.FC<IProps> = ({ value, maxDecimals, ...otherProps }) => {
    if (value === null || value === undefined) {
        return <span>-</span>;
    }
    const v = getNumberAsString(value, maxDecimals);

    return (
        <span {...otherProps}>{v}%</span>
    );
};
