import * as React from 'react';
import { FormikProps } from 'formik';
import { RouteComponentProps } from 'react-router';
import { isNil } from 'lodash';
import { DatePicker } from 'components/datepicker';
import { Control, Field, Help, Input, Radio } from 'components/form';
import { BulmaColor } from 'enums/BulmaColor';
import { BulmaSize } from 'enums/BulmaSize';
import { IKeyValue } from 'models/key-value';
import { IEventTemplate } from 'models/question-set';
import { IOutcome, IProcessStep } from 'reducers/cermAssessments';
import { dueDateUnitOptions } from 'routes/create-question-set/views/common';
import { formatDate, getDateNow } from 'utils/date-utils';
import { formikFieldCssByState, getKeyed } from 'utils/form-utils';
import { ICermAssessmentRouteProps } from '../../../cerm-assessments-page';
import { Button } from 'components/v2/components';

export interface IProps extends RouteComponentProps<ICermAssessmentRouteProps> {
    lookupActionPriorities: Array<IKeyValue<string>>;
    formikBag: FormikProps<Partial<IProcessStep>>;
    index?: number;
}

export const EventForm: React.FC<IProps> = ({
    formikBag, match, index }) => {
    const indexParam = Number(match.params && match.params.outcomeId);
    const outcomeIndex = indexParam >= 0
        ? indexParam : index >= 0
            ? index
            : 0;

    const entityName: Extract<keyof IOutcome, string> = 'event';
    const prefix = `expectedOutcomeAnswers[${outcomeIndex}].${entityName}`;
    if (!formikBag.values.expectedOutcomeAnswers[outcomeIndex].event.startDate) {
        const defaultValues: IEventTemplate = {
            title: `CERM ${formikBag.values.processNumber} expects the following outcome ${formikBag.values.title}`,
            recurrenceUnit: 'day',
            startDate: getDateNow(),
            numberOfOccurrences: 1
        };

        switch (formikBag.values.priority) {
            case '1':
                defaultValues.recurrenceValue = 60;
                break;
            case '3':
                defaultValues.recurrenceValue = 30;
                break;
            case '9':
                defaultValues.recurrenceValue = 14;
                break;
            case '27':
                defaultValues.recurrenceValue = 7;
                break;
        }
        formikBag.setFieldValue(`${prefix}`, defaultValues);
        return <></>;
    }

    const handleDateChange = (date: Date) => {
        formikBag.setFieldValue(`${prefix}.startDate`, date);
    };
    const handleOverwriteClick = () => {
        const createEvents = `expectedOutcomeAnswers[${outcomeIndex}].createEvents`;
        formikBag.setFieldValue(`${createEvents}`, true);
    };

    const field = (name: Extract<keyof IEventTemplate, string>) => `${prefix}.${name}`;

    function value<T extends Extract<keyof IEventTemplate, string>>(name: T) {
        const val = getKeyed(formikBag, 'values', field(name));
        return !isNil(val) ? val : '';
    }

    const getErrors = (name: Extract<keyof IEventTemplate, string>) => getKeyed(formikBag, 'errors', field(name));
    const isTouched = (name: Extract<keyof IEventTemplate, string>) => getKeyed(formikBag, 'touched', field(name));

    const getFieldCss = (name: Extract<keyof IEventTemplate, string>, otherCss?: string) =>
        formikFieldCssByState(Boolean(getErrors(name)), isTouched(name), otherCss);

    const getHelpByKey = (name: Extract<keyof IEventTemplate, string>) => (
        <Help
            isShown={formikBag.submitCount > 0 || isTouched(name)}
            bulmaColor={BulmaColor.Danger}
        >
            {getErrors(name)}
        </Help>
    );

    const isFormDisable = !formikBag.values.expectedOutcomeAnswers[outcomeIndex].createEvents;

    return (
        <>
            <header>
                <h2 className="title is-5">Event Template</h2>
            </header>
            {isFormDisable ? <p>Events have been added to your CERM calendar for this expected outcome.
                Click Overwrite to clear and recreate these events.</p> : ''}
            {
                isFormDisable ?
                    <Button
                        id="overwriteButton"
                        value="Overwrite"
                        className="is-secondary"
                        onClick={handleOverwriteClick}
                    > Overwrite
                    </Button> : ''
            }
            {
                !isFormDisable &&
                <>
                    <Field
                        isHorizontal={true}
                        htmlFor={field('title')}
                        label="Event Title"
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <Input
                                    id={field('title')}
                                    name={field('title')}
                                    aria-required="true"
                                    placeholder="Title of CERM event"
                                    type="text"
                                    className={getFieldCss('title')}
                                    value={value('title')}
                                    onChange={formikBag.handleChange}
                                    disabled={isFormDisable}
                                    onBlur={(event) => formikBag.setFieldValue(event.target.name, event.target.value.trim())}
                                />
                            </Control>
                            {getHelpByKey('title')}
                        </Field>
                    </Field>
                    <Field
                        isHorizontal={true}
                        htmlFor="startDate"
                        label="Event Date"
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <DatePicker
                                    id="startDate"
                                    name="startDate"
                                    value={formatDate(formikBag.values.expectedOutcomeAnswers[outcomeIndex].event.startDate)}
                                    onChange={handleDateChange}
                                    disabled={isFormDisable}
                                />
                            </Control>
                        </Field>
                    </Field>
                    <Field
                        isHorizontal={true}
                        htmlFor={field('numberOfOccurrences')}
                        label="Number of Occurrences"
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <Input
                                    id={field('numberOfOccurrences')}
                                    name={field('numberOfOccurrences')}
                                    aria-required="true"
                                    placeholder="Number of Occurrences"
                                    type="number"
                                    size={3}
                                    className={getFieldCss('numberOfOccurrences')}
                                    value={value('numberOfOccurrences')}
                                    onChange={formikBag.handleChange}
                                    onBlur={(event) => formikBag.setFieldValue(event.target.name, event.target.value.trim())}
                                    disabled={isFormDisable}
                                />
                            </Control>
                            {getHelpByKey('numberOfOccurrences')}
                        </Field>
                    </Field>
                    <Field
                        isHorizontal={true}
                        htmlFor={field('recurrenceValue')}
                        label="Recurrence"
                        labelSize={BulmaSize.Medium}
                        className="is-vcentered actionDueDate"
                    >
                        <Field>
                            <Control>
                                <Input
                                    id={field('recurrenceValue')}
                                    name={field('recurrenceValue')}
                                    placeholder="0"
                                    type="number"
                                    min={0}
                                    className={getFieldCss('recurrenceValue')}
                                    value={value('recurrenceValue')}
                                    onChange={formikBag.handleChange}
                                    onBlur={(event) => formikBag.setFieldValue(event.target.name, event.target.value.trim())}
                                    disabled={isFormDisable}
                                />
                            </Control>
                        </Field>
                        <Field>
                            <Control>
                                <Radio
                                    id={field('recurrenceUnit')}
                                    name={field('recurrenceUnit')}
                                    options={dueDateUnitOptions}
                                    className={getFieldCss('recurrenceUnit')}
                                    value={value('recurrenceUnit')}
                                    onBlur={formikBag.handleBlur}
                                    onChange={formikBag.handleChange}
                                    disabled={isFormDisable}
                                />
                            </Control>
                        </Field>
                    </Field>
                </>}
        </>
    );
};
