import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';

const getFacilities = (state: IRootState) => state.facilityState.facilities;

const getIsValidatingMyDataFacility = (state: IRootState) =>
    state.manageRoles.grid.isValidatingMyDataFacility;

const getSiteMyDataFacilityAssignments = (state: IRootState) =>
    state.manageRoles.grid.siteMyDataFacilityAssignments?.siteMyDataFacilityAssignments ?? [];

export default createSelector(
    getFacilities,
    getIsValidatingMyDataFacility,
    getSiteMyDataFacilityAssignments,
    (facilities, isValidatingMyDataFacility, siteMyDataFacilityAssignments) => ({
        facilities,
        isValidatingMyDataFacility,
        siteMyDataFacilityAssignments,
    })
);
