export const criticalSpareJa = `<h1>とは Critical Spare</h1>
<div>

Lorem Ipsumは、印刷および植字業界の単なるダミーテキストです。
Lorem Ipsumは、以来、業界の標準的なダミーテキストです。
1500年代、未知のプリンターがタイプのギャレーを取り、それをスクランブルして
タイプ標本本を作る。アルダスのようなデスクトップパブリッシングソフトウェアで
LoremIpsumのバージョンを含むPageMaker
<p>それは5世紀だけでなく生き残った、</p>
<p>
    
だけでなく、本質的に残っている電子植字への飛躍
変更なし。
</p>
<p>
   
1960年代にLetrasetシートのリリースで普及しました
Lorem Ipsumパッセージを含み、最近では
</p>
<a>
一般的な信念に反して</a>
</div>`;
