import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { setMockDrillReportParticipation } from 'actions/actions-v2/mock-drill-report-actions';
import { MockDrillReportParticipantAlert } from './mock-drill-report-participant-alert';

const getSiteId = (state: IRootState) => state.app.siteId;
const getUserId = (state: IRootState) => state.app.userId;
const getIsLoading = (state: IRootState) => state.mockDrillReportState.isLoading;

const mapStateToProps = createSelector(
    getIsLoading,
    getSiteId,
    getUserId,
    (isLoading, siteId, userId) => ({
        isLoading,
        siteId,
        userId
    })
);

const mapDispatchToProps = {
    setMockDrillReportParticipation
};

export const MockDrillReportParticipantAlertContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MockDrillReportParticipantAlert);
