import React from 'react';

interface IProps {
    buttonText: string;
    onClick: () => void;
}

export const SchedulerButtonItem: React.FC<IProps> = ({ buttonText, onClick }) => {
    return (
        <div className="scheduler-button" onClick={onClick}>
            {buttonText}
        </div>
    );
};
