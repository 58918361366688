import { connect } from 'react-redux';
import actions from './actions';
import selector from './selector';

import { RiskAssessmentFilters as View } from './view/risk-assessment-filters';

export const RiskAssessmentFilters = connect(
    selector,
    actions
)(View);
