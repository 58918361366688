import { createSelector } from 'reselect';
import { IRootActionsState } from '../../../../reducers/actions';
import { IRootState } from 'routes/store';
import { getFilteredLookupSitePeopleWithAllowedRoles } from 'sharedSelectors';

const getIsUpsertModalShown = (state: IRootActionsState) =>
    state.actions.forms.showUpsertActionModal;
const getLookupActionCategories = (state: IRootState) => state.app.lookupActionCategories;
const getLookupActionPriorities = (state: IRootState) => state.app.lookupActionPriorities;
const getLookupActionStatuses = (state: IRootState) => state.app.lookupActionStatus;
const getLookupSites = (state: IRootState) => state.app.lookupSites;

const getLookups = createSelector(
    getLookupActionCategories,
    getLookupActionPriorities,
    getLookupActionStatuses,
    getLookupSites,
    getFilteredLookupSitePeopleWithAllowedRoles,
    (
        lookupActionCategories,
        lookupActionPriorities,
        lookupActionStatuses,
        lookupSites,
        lookupSitePeopleRoles
    ) => ({
        lookupActionCategories,
        lookupActionPriorities,
        lookupActionStatuses,
        lookupSites,
        lookupSitePeopleRoles,
    })
);

const getUpsertRequest = (state: IRootActionsState) => {
    return state.actions.forms.upsertItem;
};

export default createSelector(
    getIsUpsertModalShown,
    getLookups,
    getUpsertRequest,
    (isUpsertModalShown, lookups, upsertRequest) => ({
        isUpsertModalShown,
        lookups,
        upsertRequest,
    })
);
