import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { OpportunityQuestionsActions } from 'actions/actions-v2/opportunity-action';
import { IOpportunity } from 'models/opportunity-question-set';

const controllerName = 'opportunity';
const apiPath = `/api/${controllerName}`;
const redirectUrl = '/Opportunities';

const getAllActions = {
    action: OpportunityQuestionsActions.LOAD_OPPORTUNITY_QUESTIONS_LIST,
    actionFulfilled: OpportunityQuestionsActions.LOAD_OPPORTUNITY_QUESTIONS_LIST_FULFILLED,
    actionRejected: OpportunityQuestionsActions.LOAD_OPPORTUNITY_QUESTIONS_LIST_REJECTED,
} as IActionType;

const getActions = {
    action: OpportunityQuestionsActions.LOAD_OPPORTUNITY_QUESTIONS,
    actionFulfilled: OpportunityQuestionsActions.LOAD_OPPORTUNITY_QUESTIONS_FULFILLED,
    actionRejected: OpportunityQuestionsActions.LOAD_OPPORTUNITY_QUESTIONS_REJECTED,
} as IActionType;

const createActions = {
    action: OpportunityQuestionsActions.CREATE_OPPORTUNITY_QUESTIONS,
    actionFulfilled: OpportunityQuestionsActions.CREATE_OPPORTUNITY_QUESTIONS_FULFILLED,
    actionRejected: OpportunityQuestionsActions.CREATE_OPPORTUNITY_QUESTIONS_REJECTED,
} as IActionType;

const updateActions = {
    action: OpportunityQuestionsActions.UPDATE_OPPORTUNITY_QUESTIONS,
    actionFulfilled: OpportunityQuestionsActions.UPDATE_OPPORTUNITY_QUESTIONS_FULFILLED,
    actionRejected: OpportunityQuestionsActions.UPDATE_OPPORTUNITY_QUESTIONS_REJECTED,
} as IActionType;

const deleteActions = {
    action: OpportunityQuestionsActions.DELETE_OPPORTUNITY_QUESTIONS,
    actionFulfilled: OpportunityQuestionsActions.DELETE_OPPORTUNITY_QUESTIONS_FULFILLED,
    actionRejected: OpportunityQuestionsActions.DELETE_OPPORTUNITY_QUESTIONS_REJECTED,
} as IActionType;

const loadAllOpportunityQuestionEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getAllActions).get<IOpportunity>(apiPath);

const loadOpportunityQuestionEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getActions).getById<IOpportunity>(apiPath);

const createOpportunityQuestionEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createActions, redirectUrl).post<IOpportunity>(apiPath);

const updateOpportunityQuestionEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateActions, redirectUrl).put<IOpportunity>(apiPath);

const deleteOpportunityQuestionEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, deleteActions, redirectUrl).deleteById<string>(apiPath);

export const opportunityQuestionsEpic = combineEpics(
    loadAllOpportunityQuestionEpic,
    loadOpportunityQuestionEpic,
    createOpportunityQuestionEpic,
    updateOpportunityQuestionEpic,
    deleteOpportunityQuestionEpic
);
