import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { IFacilityActions } from 'actions/actions-v2/facility-action-v2';

const controllerName = 'MyData';
const apiPath = `/api/${controllerName}`;

const getFacilityActions = {
    action: IFacilityActions.LOAD_FACILITY,
    actionFulfilled: IFacilityActions.LOAD_FACILITY_FULFILLED,
    actionRejected: IFacilityActions.LOAD_FACILITY_REJECTED,
} as IActionType;

const loadFacilityEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getFacilityActions).get(`${apiPath}`);

export const IFacilityEpicsV2 = combineEpics(loadFacilityEpic);
