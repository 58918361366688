import { IAppState } from '../app';
import { IBaseState } from '../base-state';
import { INavState } from '../nav';
import {
    IClientsGridState,
    reducer as grid
} from './clients-grid';
import { IClientPage } from './upsert-client-page';

export interface IRootClientsState extends IBaseState {
    clients: IClientsGridState;
    clientPage: IClientPage;
    app: IAppState;
    nav: INavState;
}

export const clientsReducer = grid;
