import { combineReducers } from 'redux';
import { SiteFeatureActions } from 'actions/site-feature';
import { ISiteProfile } from 'models/site-profile';
import { ManageRoleActions } from 'actions/manage-role-actions';
import { SiteActions } from 'actions/actions-v2/site-actions-v2';
import { IAppAction } from 'actions/app-action';
import { genericSort } from 'utils/sort-utils';
import { orderBy } from 'lodash';
import { IBuilding } from 'models/building';

export interface ISiteFeatureState {
    sites: ISiteProfile[];
    isLoading: boolean;
    responseContinuation: string;
}

const INITIAL_STATE: ISiteFeatureState = {
    sites: [],
    isLoading: false,
    responseContinuation: null,
};

const isLoading = (state: boolean = INITIAL_STATE.isLoading, action: IAppAction) => {
    switch (action.type) {
        case SiteFeatureActions.LOAD_SITE_FEATURES_FULFILLED:
        case SiteFeatureActions.SAVE_SITE_FEATURES_REJECTED:
        case SiteFeatureActions.SAVE_SITE_FEATURES_FULFILLED:
            return false;
        case SiteFeatureActions.SAVE_SITE_FEATURES:
        case SiteFeatureActions.LOAD_SITE_FEATURES:
        case SiteActions.LOAD_SITES:
            return true;
        case SiteActions.LOAD_SITES_FULFILLED:
        case SiteActions.LOAD_SITES_REJECTED:

            return false;
        default:
            return state;
    }
};

const sites = (state: ISiteFeatureState['sites'] = INITIAL_STATE.sites, action: IAppAction) => {
    switch (action.type) {
        case ManageRoleActions.LOAD_SITES_FULFILLED:
        case SiteFeatureActions.LOAD_SITE_FEATURES_FULFILLED:
            return action.payload.sites;

        case SiteActions.LOAD_SITES_FULFILLED:
            if (action.payload) {
                if (action.isFromContinuationRequest) {
                    return [...state, ...action.payload];
                }

                return action.payload;
            }

            return state;

        case SiteActions.SORT_SITES:
            const sortAscending = action.payload.sortAscending;
            switch (action.payload.sortColumn) {
                case 'country':
                    return orderBy(
                        [...state],
                        [(c) => getMainBuildingCountry(c)],
                        [sortAscending ? 'asc' : 'desc']
                    );
                case 'totalMainsPowerCapacity':
                    return orderBy(
                        [...state],
                        [(c) => getSumValue('upsPowerCapacity_MW', c)],
                        [sortAscending ? 'asc' : 'desc']
                    );
            }
            return genericSort(action.payload.sortColumn, [...state], action.payload.sortAscending);
        default:
            return state;
    }
};

const responseContinuation = (
    state: string = INITIAL_STATE.responseContinuation,
    action: IAppAction
) => {
    switch (action.type) {
        case SiteActions.LOAD_SITES_FULFILLED:
            return action.responseContinuation;

        default:
            return state;
    }
};

const getMainBuildingCountry = (site: ISiteProfile) => {
    const mainBuildingAddress =
        site.buildings !== null ? site.buildings.find((b) => b.isMasterAddress) : null;
    if (mainBuildingAddress && mainBuildingAddress.country) {
        return mainBuildingAddress.country;
    }
    return null;
};

const getSumValue = (name: Extract<keyof IBuilding, string>, currentSite: ISiteProfile) => {
    const hasFacilities = currentSite && currentSite.buildings && currentSite.buildings.length > 0;
    return hasFacilities
        ? currentSite.buildings
            .filter((f) => f[name])
            .map((c) => c[name])
            .reduce((sum: number, r: number) => sum + r, 0)
        : 0;
};

export const siteFeaturesReducer = combineReducers<ISiteFeatureState>({
    isLoading,
    sites,
    responseContinuation,
});
