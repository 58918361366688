import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import HelpModule from './help-module';
import { createSelector } from 'reselect';

const getLanguage = (state: IRootState) => state.person.personProfile.language;

export const getUserLanguage = createSelector(getLanguage, (language) => language);

const mapStateToProps = (state: IRootState) => {
    return {
        language: getUserLanguage(state),
    };
};

export const HelpModuleContainer = connect(mapStateToProps)(HelpModule);
