import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import actions from './actions';
import selector from './selector';
import { CermAssessmentsReview as View } from './views/cerm-assessments-review';

export const CermAssessmentsReview = withRouter(connect(
    selector,
    actions
)(View));
