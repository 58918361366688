import * as React from 'react';
import { Icon, IconType } from 'components/icon';
import { BulmaSize } from 'enums/BulmaSize';
import { Button, ConfirmDialog } from 'components/v2/components';
import { Help, Input } from 'components/form';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconColor, MaterialIconType } from 'routes/material-icon/material-icon-type';
import { newGuid } from 'utils/id-utils';
import useTranslate from 'translations/translation-utils';
import { IOperatingPlatform } from 'models/operating-platform-model';
import { IBusinessUnitCreate, ISelectedSiteMetaData } from 'models/site-metadata-model';
import iconHierachyBlack from 'assets/icon-hierarchy-business-unit.svg';
import { BulmaColor } from 'enums/BulmaColor';
import { IDivisions } from 'models/divisions-model';

interface IProps {
    selectOperatingPlatform: IOperatingPlatform;
    newOperatingPlatform: { id: string, label: string };
    saveBusinessUnitsSiteMetaData: (businessUnits: IBusinessUnitCreate[]) => void;
    businessUnit: IBusinessUnitCreate[];
    selectDivisionValue: ISelectedSiteMetaData;
    selectLineOfBusinessValue: ISelectedSiteMetaData;
    selectDivision: (divisionId: string, divisionLabel: string, count: number, isActive: boolean) => void;
    divisionsForLineOfBusiness: IDivisions[];
}
export const HierachyBusinessUnits: React.FC<IProps> = ({
    businessUnit,
    selectOperatingPlatform,
    selectLineOfBusinessValue,
    newOperatingPlatform,
    saveBusinessUnitsSiteMetaData,
    selectDivisionValue,
    selectDivision,
    divisionsForLineOfBusiness
}) => {
    const [allBusinessUnits, setAllBusinessUnits] = React.useState(businessUnit)
    const translate = useTranslate();
    const [businessUnits, setBusinessUnits] = React.useState<IBusinessUnitCreate[]>([])
    const [isShown, setIsShown] = React.useState<boolean>(false);
    const [isEditHierarchySetOpen, setisEditHierarchySetOpen] = React.useState<boolean>(false);
    const [isEditHierarchySet, setisEditHierarchySet] = React.useState(null);
    const [newLobText, setNewLobText] = React.useState<string>('');
    const [updatedLobText, setUpdatedLobText] = React.useState<string>('');
    const [updatedBusinessUnits, setUpdatedBusinessUnits] = React.useState<IBusinessUnitCreate[]>([]);
    const [showDialogue, setShowDialogue] = React.useState(false);
    const [selectedBusinessUnit, setBusinessUnit] = React.useState('');
    const [siteCount, setSiteCount] = React.useState(false);
    const [isDuplicate, setIsDuplicate] = React.useState<boolean>(false);
    const operatingPlatform = selectOperatingPlatform ? selectOperatingPlatform : newOperatingPlatform;

    React.useEffect(() => {
        const filteredBusinessUnits = operatingPlatform?.id && selectLineOfBusinessValue?.id && selectDivisionValue?.id && allBusinessUnits.filter(
            x =>
                x.operatingPlatformId === operatingPlatform.id &&
                x.lineOfBusinessId === selectLineOfBusinessValue.id &&
                x.divisionId === selectDivisionValue.id
        );
        setBusinessUnits(filteredBusinessUnits)
    }, [selectDivisionValue, allBusinessUnits]);

    React.useEffect(() => {
        const businessUnitFiltered = divisionsForLineOfBusiness && divisionsForLineOfBusiness.length > 0 && allBusinessUnits.length > 0 && allBusinessUnits.filter((el) => {
            return divisionsForLineOfBusiness.some((f) => {
                return f.id === el.divisionId && f.operatingPlatformId === el.operatingPlatformId;
            });
        });
        setBusinessUnits(businessUnitFiltered);
    }, [divisionsForLineOfBusiness]);

    React.useEffect(() => {
        let allBusinessUnitsList = [...allBusinessUnits];
        if (selectDivisionValue?.isActive === false) {
            allBusinessUnitsList = allBusinessUnitsList && allBusinessUnitsList.length > 0 &&
                selectDivisionValue?.id &&
                selectLineOfBusinessValue?.id &&
                operatingPlatform?.id &&
                allBusinessUnitsList.filter
                    (obj => obj.divisionId !== selectDivisionValue?.id &&
                        obj.lineOfBusinessId === selectLineOfBusinessValue?.id &&
                        obj.operatingPlatformId === operatingPlatform.id
                    );
            setBusinessUnits(allBusinessUnitsList);
        }
    }, [selectDivisionValue?.isActive]);

    const handleOnClick = () => {
        if (isEditHierarchySetOpen) {
            setisEditHierarchySetOpen(false)
        }
        setIsShown(true);
    };

    const setSelectedBusinessUnit = (x) => {
        if (isShown) {
            setIsShown(false)
        }
        setisEditHierarchySetOpen(true)
        setisEditHierarchySet(x)
    }

    const onChangeEditText = (obj, updatedText) => {
        setUpdatedLobText(updatedText);
        const obj2 = Object.assign({}, obj);
        if (updatedText) {
            obj2.label = updatedText;
            obj2.value = updatedText;
        }
        setisEditHierarchySet(obj2)
    }

    const onConfirmDelete = () => {
        selectedBusinessUnit && deleteBusinessUnit(selectedBusinessUnit);
        setShowDialogue(false);
    }

    const deleteBusinessUnit = (deletedItem) => {
        let oldList = [];
        if (allBusinessUnits?.length > 0) {
            oldList = [...allBusinessUnits];
            oldList = oldList.filter(obj => obj.id !== deletedItem.id);
            setAllBusinessUnits(oldList);
            selectDivision(selectDivisionValue?.id, selectDivisionValue?.label, selectDivisionValue?.count - 1, selectDivisionValue?.isActive)
        }
        const payloadDeletedItem = {
            id: deletedItem?.id,
            key: deletedItem?.key,
            label: deletedItem?.label,
            lineOfBusiness: deletedItem?.lineOfBusiness,
            lineOfBusinessId: deletedItem?.lineOfBusinessId,
            division: deletedItem?.label,
            divisionId: deletedItem?.id,
            operatingPlatformId: deletedItem?.operatingPlatformId,
            operatingPlatform: deletedItem?.operatingPlatform,
            value: deletedItem?.value,
            isActive: false,
            count: deletedItem?.count,
        };
        let deletedList = [];
        if (updatedBusinessUnits?.length > 0) {
            deletedList = [...updatedBusinessUnits];
            const obj = deletedList.findIndex(x => x.id === deletedItem.id);
            if (obj > -1) {
                deletedList[obj] = payloadDeletedItem;
            }
            else {
                deletedList.push(payloadDeletedItem);
            }
        }
        else {
            deletedList.push(payloadDeletedItem);
        }
        setUpdatedBusinessUnits(deletedList);
        saveBusinessUnitsSiteMetaData(deletedList);
    }

    const getBody = () => {
        return (
            <>
                <br />
                <p>{(siteCount) ? translate('OperatingPlatform.Model.DeleteMessageWithSite') : translate('OperatingPlatform.Modal.BusinessUnit.DeleteMessageWithoutSite')}</p>
                {(!siteCount) ? <p><strong>{translate('OperatingPlatform.Hierarchy.Modal.DeleteFooterMessage')}</strong></p> : <p><strong>{translate('OperatingPlatform.Modal.withSitesDeleteFooterMessage')}</strong></p>}
            </>
        )
    };

    const deleteItem = (deletedItem) => {
        setShowDialogue(true);
        if (deletedItem.siteCount > 0) {
            setSiteCount(true)
        }
        if (deletedItem.siteCount === 0) {
            setBusinessUnit(deletedItem);
            setSiteCount(false);
        }
    }

    const onChangeText = (title) => {
        setNewLobText(title)
    }

    const addNewBusinessUnit = () => {
        let oldList = [];
        if (allBusinessUnits?.length > 0) {
            oldList = [...allBusinessUnits];
        }
        const operatingPlatformId = selectOperatingPlatform ? selectOperatingPlatform.id : newOperatingPlatform.id;
        const operatingPlatformValue = selectOperatingPlatform ? selectOperatingPlatform.value : newOperatingPlatform.label;
        const newLobId = newGuid();
        const payloadLobs = {
            id: newLobId,
            key: newLobId,
            label: newLobText,
            lineOfBusiness: selectLineOfBusinessValue?.label,
            lineOfBusinessId: selectLineOfBusinessValue?.id,
            division: selectDivisionValue?.label,
            divisionId: selectDivisionValue?.id,
            operatingPlatformId: operatingPlatformId,
            operatingPlatform: operatingPlatformValue,
            value: newLobText,
            isActive: true,
            count: 0,
            siteCount: 0
        };
        const payloadUpdatedLobs = {
            id: newLobId,
            key: newLobId,
            label: newLobText,
            lineOfBusiness: selectLineOfBusinessValue?.label,
            lineOfBusinessId: selectLineOfBusinessValue?.id,
            division: selectDivisionValue?.label,
            divisionId: selectDivisionValue?.id,
            operatingPlatformId: operatingPlatformId,
            operatingPlatform: operatingPlatformValue,
            value: newLobText,
            isActive: true
        };
        const found = businessUnits && businessUnits.length > 0 && businessUnits.some(el => el.label === newLobText);
        if (!found) {
            if (isDuplicate) {
                setIsDuplicate(false);
            }
            oldList.push(payloadLobs);
            const updatedlist1 = [...updatedBusinessUnits];
            updatedlist1.push(payloadUpdatedLobs)
            setUpdatedBusinessUnits(updatedlist1);
            saveBusinessUnitsSiteMetaData(updatedlist1);
            setAllBusinessUnits(oldList);
            selectDivision(selectDivisionValue?.id, selectDivisionValue?.label, selectDivisionValue?.count + 1, selectDivisionValue?.isActive)
            setIsShown(false);
            setNewLobText('');
        } else {
            setIsDuplicate(true);
        }
    }
    const updateBusinessUnits = (id) => {
        let oldList = [];
        const found = updatedLobText && businessUnits && businessUnits.length > 0 && businessUnits.some(el => el.label === updatedLobText);
        if (!found) {
            if (isDuplicate) {
                setIsDuplicate(false);
            }
            if (allBusinessUnits?.length > 0) {
                oldList = [...allBusinessUnits];
                const obj = oldList.findIndex(x => x.id === id);
                if (obj > -1) {
                    oldList[obj] = isEditHierarchySet;
                    setAllBusinessUnits(oldList);
                }
            }
            else {
                oldList.push(isEditHierarchySet);
                setAllBusinessUnits(oldList);
            }

            const payloadLobs = {
                id: isEditHierarchySet?.id,
                key: isEditHierarchySet?.key,
                label: isEditHierarchySet?.label,
                lineOfBusiness: isEditHierarchySet?.lineOfBusiness,
                lineOfBusinessId: isEditHierarchySet?.lineOfBusinessId,
                division: isEditHierarchySet?.division,
                divisionId: isEditHierarchySet?.divisionId,
                operatingPlatformId: isEditHierarchySet?.operatingPlatformId,
                operatingPlatform: isEditHierarchySet?.operatingPlatform,
                value: isEditHierarchySet?.value,
                isActive: true
            };
            const updatedList2 = [...updatedBusinessUnits];
            const obj1 = updatedList2.findIndex(x => x.id === id);
            if (obj1 > -1) {
                updatedList2[obj1] = isEditHierarchySet;
                setUpdatedBusinessUnits(updatedList2);
                saveBusinessUnitsSiteMetaData(updatedList2);
            }
            else {
                updatedList2.push(payloadLobs)
                setUpdatedBusinessUnits(updatedList2);
                saveBusinessUnitsSiteMetaData(updatedList2);
            }
            setUpdatedLobText('');
            setisEditHierarchySetOpen(false)
        }
        else {
            setIsDuplicate(true);
        }
    }
    const OnCancelIsEditHierarhySet = () => {
        setisEditHierarchySetOpen(false);
        if (isDuplicate) {
            setIsDuplicate(false);
        }
    }

    const OnCancelHierarhySet = () => {
        setIsShown(false);
        if (isDuplicate) {
            setIsDuplicate(false);
        }
    }
    return (
        <>
            <div className='columns'>
                <div className='column is-8'>
                    <span className='hierachy-title'>{translate('Globals.Label.BusinessUnit')} {`(${businessUnits ? businessUnits.length : 0})`}</span>
                </div>
                <div className='column is-2'>
                    <Button
                        id="btnDivisionSaveAction"
                        type="submit"
                        className="add-hierachy-item"
                        onClick={handleOnClick}
                        disabled={
                            !(selectDivisionValue?.id)
                        }
                    >
                        <Icon type={IconType.PlusCircle} size={BulmaSize.Small} />
                        <span className='hierarchy-add-button'>{translate('OperatingPlatform.ButtonLabel.Add')}</span>
                    </Button>
                </div>
            </div>
            <ConfirmDialog
                title={translate('OperatingPlatform.Hierarchy.Modal.DeleteTitle')}
                message={getBody()}
                isVisible={showDialogue}
                isLoading={false}
                showConfirmButton={!siteCount}
                onConfirm={() => onConfirmDelete()}
                onClose={() => setShowDialogue(false)}
                buttonConfirmText={translate('OperatingPlatform.Model.DeleteButton')}
                buttonCancelText={translate('Globals.Label.Cancel')}

            />
            {businessUnits?.length > 0 &&
                businessUnits.map((x) => (
                    <div key={x.key} className="columns selectedHierarchy">
                        <div
                            id={
                                'editBusinessUnit' + x.key
                            }
                            className="column is-9 is-clickable"
                        > <span className='icon'>
                                <img src={iconHierachyBlack} alt={"hierachy-item"} />
                            </span>
                            <span className='icon-text'>{x.value}</span>
                        </div>
                        <div className="column is-3">
                            <a
                                id={
                                    'editnBusinessUnit' + x.key
                                }

                                onClick={() =>
                                    setSelectedBusinessUnit(
                                        x
                                    )
                                }
                            >

                                < MaterialIcons type={MaterialIconType.Edit} color={MaterialIconColor.green} />
                            </a>
                            <a
                                id={
                                    'deleteBusinessUnit' + x.key
                                }
                                onClick={() =>
                                    deleteItem(
                                        x
                                    )
                                }
                            >
                                < MaterialIcons type={MaterialIconType.Delete} color={MaterialIconColor.green} />
                            </a>
                        </div>
                    </div>
                ))}
            {
                isShown &&
                <div className='edit-hirerachy-section'>
                    <div className="columns">
                        <div className="column is-1">
                        </div>
                        <div className="column is-10">
                            <Help
                                bulmaColor={BulmaColor.Danger}
                                isShown={isDuplicate}
                            >
                                {translate('OperatingPlatform.Hierarchy.Duplicate.BusinessUnits')}
                            </Help>
                            <Input
                                id={
                                    'newBusinessUnit'
                                }
                                placeholder={translate('OperatingPlatform.Hierarchy.BusinessUnits')}
                                onChange={(e: any) =>
                                    onChangeText(
                                        e.target.value
                                    )
                                }
                                value={newLobText}
                                className='hierarchy-new-item-input'
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className='column is-2'></div>
                        <div className="column is-10 is-pull-right hierarchy-buttons">
                            <Button
                                id={
                                    'newBusinessUnitAdd'
                                }
                                className='cancel-hirerachy-button'
                                onClick={() =>
                                    OnCancelHierarhySet(
                                    )
                                }
                            >
                                {translate('OperatingPlatform.Button.Cancel')}
                            </Button>
                            <Button
                                id={
                                    'newBusinessUnitAdd'

                                }
                                onClick={() =>
                                    addNewBusinessUnit(

                                    )
                                }
                                className='save-hirerachy-button'
                            >
                                {translate('OperatingPlatform.Button.Save')}
                            </Button>
                        </div>
                    </div>
                </div>
            }

            {
                isEditHierarchySetOpen && isEditHierarchySet &&
                <div className='edit-hirerachy-section'>
                    <div className="columns">
                        <div className="column is-1">
                        </div>
                        <div className="column is-10">
                            <Help
                                bulmaColor={BulmaColor.Danger}
                                isShown={isDuplicate}
                            >
                                {translate('OperatingPlatform.Hierarchy.Duplicate.BusinessUnits')}
                            </Help>
                            <Input
                                id={
                                    'editBusinessUnit' +
                                    isEditHierarchySet.key
                                }
                                placeholder={isEditHierarchySet.value}
                                onChange={(e: any) =>
                                    onChangeEditText(
                                        isEditHierarchySet,
                                        e.target.value
                                    )
                                }
                                value={
                                    updatedLobText ? updatedLobText : isEditHierarchySet.value
                                }

                                className='hierarchy-new-item-input'
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className='column is-2'></div>
                        <div className="column is-10 is-pull-right hierarchy-buttons">
                            <Button
                                id={
                                    'cancelHierarhySet' +
                                    isEditHierarchySet.key
                                }
                                className='cancel-hirerachy-button'
                                onClick={() =>
                                    OnCancelIsEditHierarhySet(
                                    )
                                }
                            >
                                {translate('OperatingPlatform.Button.Cancel')}
                            </Button>
                            <Button
                                id={
                                    'newBusinessUnitAdd' +
                                    isEditHierarchySet.key
                                }
                                onClick={() =>
                                    updateBusinessUnits(
                                        isEditHierarchySet.id
                                    )
                                }
                                className='save-hirerachy-button'
                            >
                                {translate('OperatingPlatform.Button.Save')}
                            </Button>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};