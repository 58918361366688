import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import * as React from 'react';
import './Icon.scss';
import { BulmaSize } from '../../enums/BulmaSize';
import { getIcon, IconType } from './type-map';

export { IconType };

export interface IProps {
    type: IconType;
    alt?: string;
    className?: string;
    size?: BulmaSize;
}

const getCss = (props: IProps) => classnames(
    'icon',
    props.size,
    props.className
);

const getSize = (props: IProps): SizeProp => {
    switch (props.size) {
        case BulmaSize.Medium:
            return 'lg';

        case BulmaSize.Large:
            return '2x';

        default:
            return undefined;
    }
};

export const Icon: React.FC<IProps> = (props) => (
    <span className={getCss(props)}>
        {props.type &&
            <FontAwesomeIcon
                icon={getIcon(props.type)}
                size={getSize(props)}
                title={props.alt}
            />
        }
    </span>
);

Icon.defaultProps = {
    size: BulmaSize.Default
};
