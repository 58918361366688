export interface IRelatedEntity {
    type: RelatedType;
    id: string;
}

export enum RelatedType {
    Risk = 'Risk',
    Cerm = 'Cerm',
    MockDrillReport = 'MockDrillReport'
}
