import { SiteActions } from 'actions/actions-v2/site-actions-v2';
import { IAppAction } from 'actions/app-action';

export default {
    onExportSites: (): IAppAction => ({
        type: SiteActions.EXPORT_SITES
    }),
    setInitialValues: (): IAppAction => ({
        type: SiteActions.LOAD_SITE_INITIAL_VALUES
    })
};
