import INavItem from 'models/nav-item';
import { IKeyValue } from 'models';
import { checkPermissions, checkSiteFeature, checkIfAnySiteFeature } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import { SiteFeatures } from 'actions/site-feature';

export const getDataCenterNavItems = (
    siteId: string,
    permissions: IKeyValue<string>[],
    currentSiteFeatures: string[]
) => {
    const navItems: INavItem[] = [
        {
            text: 'Dashboard',
            link: '/Dashboard',
            isVisible: true,
        },
        {
            text: 'CERM Assessment',
            link: '/CermAssessment',
            isVisible: true,
        },
        {
            text: 'CERM 100 Administration',
            link: '/',
            isVisible: true,
            children: [
                {
                    text: 'CERM 100 - Data Centre Client Contract Review',
                    link: checkPermissions([Privilege.IAuditorView], permissions)
                        ? '/ComplianceCheckList/100/documents'
                        : '/ComplianceCheckList/100/opportunityQuestionnaire',
                    isVisible: true,
                },
                {
                    text: 'CERM 101 - Data Centre Critical Environment Risk Communication',
                    link: '/ComplianceCheckList/101/documents',
                    isVisible: true,
                },

                {
                    text: 'CERM 101.1 - Lesson\'s learned',
                    link: '/MockDrills/LessonsLearned',
                    isVisible: true,
                },
                {
                    text: 'CERM 101.1 - Toolbox talks',
                    link: '/MockDrills/ToolboxTalks',
                    isVisible: true,
                },
            ],
        },
        {
            text: 'CERM 200 Training & Qualifications',
            link: '/',
            isVisible: true,
            children: [
                {
                    text: 'CERM 200.1 DNA Engineer Assessment',
                    link: '/Engineers',
                    isVisible: checkPermissions([Privilege.CDNAUpdate, Privilege.CDNAView], permissions),
                },
                {
                    text: 'CERM 200.1 Competency DNA Processes',
                    link: '/CompetencyDNAProcess/M&E',
                    isVisible:
                        checkPermissions([Privilege.CompetencyDNAProcess], permissions) &&
                        checkSiteFeature(SiteFeatures.CDNA, currentSiteFeatures),
                },
                {
                    text: 'CERM 200.2 Shifts and Gaps',
                    link: '/Shifts',
                    isVisible: checkPermissions([Privilege.CDNAUpdate, Privilege.CDNAView], permissions),
                },
                {
                    text: 'CERM 204 Mock Drills',
                    link: '/MockDrills/MockDrills',
                    isVisible:
                        checkPermissions(
                            [Privilege.MockDrillsRead, Privilege.MockDrillsReadWrite],
                            permissions
                        ) && checkSiteFeature(SiteFeatures.MockDrills, currentSiteFeatures),
                },
            ],
        },
        {
            text: 'CERM 300 Maintenance & Operations (M&E)',
            link: '/',
            isVisible: true,
            children: [
                {
                    text: 'CERM 301 - Data Centre Critical Plant - Area Labelling',
                    link: '/ComplianceCheckList/301/documents',
                    isVisible: true,
                },
                {
                    text: 'CERM 304 Critical Spares',
                    link: '/CriticalSystemSpares',
                    isVisible:
                        checkPermissions([Privilege.CriticalSystemsView], permissions) &&
                        checkSiteFeature(SiteFeatures.CriticalSpares, currentSiteFeatures),
                },
                {
                    text: 'CERM 310 - Data Centre Pre-Impairment Checklist',
                    link: '/ComplianceCheckList/310/documents',
                    isVisible: true,
                },
                {
                    text: 'CERM 311 Incidents and Near Misses',
                    link: '/Incidents',
                    isVisible: checkSiteFeature(SiteFeatures.Incidents, currentSiteFeatures),
                },
                {
                    text: 'CERM 312 Rounds And Readings',
                    link: '/ComplianceCheckList/312/documents',
                    isVisible: true,
                },
                {
                    text: 'CERM 313 Shift Handover',
                    link: '/ShiftHandovers',
                    isVisible:
                        checkPermissions([Privilege.ShiftHandover], permissions) &&
                        checkSiteFeature(SiteFeatures.CDNA, currentSiteFeatures),
                },
            ],
        },
        {
            text: 'CERM 350 Maintenance & Operations (IT)',
            link: '/',
            isVisible: true,
            children: [
                {
                    text: 'CERM 350 - Data Centre IT Energy Efficiency Management',
                    link: '/ComplianceCheckList/350/documents',
                    isVisible: true,
                },
                {
                    text: 'CERM 351 - Data Centre Energy Consumption Recording Process',
                    link: '/ComplianceCheckList/351/documents',
                    isVisible: true,
                },
                {
                    text: 'CERM 354 - Data Centre IT Equipment Installation Check List',
                    link: '/ComplianceCheckList/354/documents',
                    isVisible: true,
                },
                {
                    text: 'CERM 358 - Data Centre IT Equipment Receipt Form',
                    link: '/ComplianceCheckList/358/documents',
                    isVisible: true,
                },
                {
                    text: 'CERM 359 - Data Centre IT Equipment Release Form',
                    link: '/ComplianceCheckList/359/documents',
                    isVisible: true,
                },
                {
                    text: 'CERM 369 - Data Centre IT Project Acceptance Form',
                    link: '/ComplianceCheckList/369/documents',
                    isVisible: true,
                },
                {
                    text: 'CERM 372 - Data Centre IT Service Compliance Check List',
                    link: '/ComplianceCheckList/372/documents',
                    isVisible: true,
                },
            ],
        },
        {
            text: 'CERM 400 Planning, Analysis & Engineering',
            link: '/',
            isVisible: true,
            children: [
                {
                    text:
                        'CERM 400 - Data Center Critical Project Handover - Project Close Out Form',
                    link: '/ComplianceCheckList/400/documents',
                    isVisible: true,
                },
            ],
        },
        {
            text: 'CERM 500 Continuous Improvement',
            link: '/',
            isVisible: true,
            children: [
                {
                    text: 'Actions',
                    link: '/Actions',
                    isVisible: checkPermissions([Privilege.ActionView], permissions),
                },
                {
                    text: 'CERM 502 Risk Register',
                    link: '/Risks',
                    isVisible: checkPermissions([Privilege.RiskView], permissions),
                },
                {
                    text: 'CERM 502 Client Risk Register',
                    link: '/RiskRegisterClient',
                    isVisible: checkPermissions([Privilege.ClientRiskRegister], permissions),
                },
                {
                    text: 'CERM 502 Risk Profile Questionnaire',
                    link: '/RiskProfileQuestionnaire',
                    isNotSingleSiteRelated: false,
                    isVisible:
                        checkPermissions([Privilege.RiskProfileQuestionnaire], permissions) &&
                        checkSiteFeature(SiteFeatures.RiskProfiles, currentSiteFeatures),
                },
                {
                    text: 'CERM 502 Risk Questions',
                    link: '/RiskAssessment',
                    isVisible: true,
                },
                /*   {
                    text: 'CERM 508 Data Center Airflow Management',
                    link: '/ComplianceCheckList/400/documents',
                    isVisible: true,
                }, */
            ],
        },
        {
            text: 'Quantum Analytics',
            link: '/QuantumAnalytics',
            isNotSingleSiteRelated: true,
            isVisible: checkPermissions([Privilege.ReportTableau], permissions),
        },
        {
            text: 'Site',
            link: '/',
            isVisible: true,
            children: [
                {
                    text: 'Site Documents',
                    link: '/SiteDocuments',
                    isVisible: checkSiteFeature(SiteFeatures.SiteDocuments, currentSiteFeatures),
                },
                {
                    text: 'Site CERM Process Owners',
                    link: '/CermProcessOwners',
                    isVisible: true,
                },
                {
                    text: 'Site & Facilities',
                    link: `/Site/Upsert/${siteId}/Form/SiteDetails`,
                    isVisible:
                        siteId !== null && checkPermissions([Privilege.SiteView], permissions),
                },
            ],
        },
        {
            text: 'Romonet',
            link: '/',
            isVisible:
                checkIfAnySiteFeature(
                    [
                        SiteFeatures.RomonetHomePage,
                        SiteFeatures.RomonetExecutiveDashboard,
                        SiteFeatures.FacilityOperationsSnapshot,
                        SiteFeatures.ProjectTrackingDashboard,
                        SiteFeatures.EngineerReport,
                        SiteFeatures.DataReports,
                    ],
                    currentSiteFeatures
                ) && checkPermissions([Privilege.Romonet], permissions),
            children: [
                {
                    text: 'Romonet Home Page',
                    link: '/Romonet/HomePage',
                    isVisible: checkSiteFeature(SiteFeatures.RomonetHomePage, currentSiteFeatures),
                },
                {
                    text: 'Romonet Executive Dashboard',
                    link: '/Romonet/ExecutiveDashboard',
                    isVisible:
                        checkSiteFeature(
                            SiteFeatures.RomonetExecutiveDashboard,
                            currentSiteFeatures
                        ) && checkPermissions([Privilege.Romonet], permissions),
                },
                {
                    text: 'Facility Operations Snapshot',
                    link: '/Romonet/FacilityOperationsSnapshot',
                    isVisible:
                        checkSiteFeature(
                            SiteFeatures.FacilityOperationsSnapshot,
                            currentSiteFeatures
                        ) && checkPermissions([Privilege.Romonet], permissions),
                },
                {
                    text: 'CBRE Detailed Hall Report',
                    link: '/Romonet/DetailedHallReport',
                    isVisible:
                        checkSiteFeature(SiteFeatures.DetailedHallReport, currentSiteFeatures) &&
                        checkPermissions([Privilege.Romonet], permissions),
                },
                {
                    text: 'Project Tracking Dashboard',
                    link: '/Romonet/ProjectTrackingDashboard',
                    isVisible:
                        checkSiteFeature(
                            SiteFeatures.ProjectTrackingDashboard,
                            currentSiteFeatures
                        ) && checkPermissions([Privilege.Romonet], permissions),
                },
                {
                    text: 'Engineer Report',
                    link: '/Romonet/EngineerReport',
                    isVisible:
                        checkSiteFeature(SiteFeatures.EngineerReport, currentSiteFeatures) &&
                        checkPermissions([Privilege.Romonet], permissions),
                },
                {
                    text: 'Data Reports',
                    link: '/Romonet/DataReports',
                    isVisible:
                        checkSiteFeature(SiteFeatures.DataReports, currentSiteFeatures) &&
                        checkPermissions([Privilege.Romonet], permissions),
                },
            ],
        },
    ];

    return navItems;
};
