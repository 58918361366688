import { IAppAction } from './../app-action';
import { IClient } from 'models/client';
import { IClientFilters } from 'models/client-filters';

export enum ClientActions {
    LOAD_CLIENTS = '@@client/LOAD_CLIENTS',
    LOAD_CLIENTS_FULFILLED = '@@client/LOAD_CLIENTS_FULFILLED',
    LOAD_CLIENTS_REJECTED = '@@client/LOAD_CLIENTS_REJECTED',

    LOAD_CLIENT = '@@client/LOAD_CLIENT',
    LOAD_CLIENT_FULFILLED = '@@client/LOAD_CLIENT_FULFILLED',
    LOAD_CLIENT_REJECTED = '@@client/LOAD_CLIENT_REJECTED',

    CREATE_CLIENT = '@@client/CREATE_CLIENT',
    CREATE_CLIENT_FULFILLED = '@@client/CREATE_CLIENT_FULFILLED',
    CREATE_CLIENT_REJECTED = '@@client/CREATE_CLIENT_REJECTED',

    UPDATE_CLIENT = '@@client/UPDATE_CLIENT',
    UPDATE_CLIENT_FULFILLED = '@@client/UPDATE_CLIENT_FULFILLED',
    UPDATE_CLIENT_REJECTED = '@@client/UPDATE_CLIENT_REJECTED',

    DELETE_CLIENT = '@@client/DELETE_CLIENT',
    DELETE_CLIENT_FULFILLED = '@@client/DELETE_CLIENT_FULFILLED',
    DELETE_CLIENT_REJECTED = '@@client/DELETE_CLIENT_REJECTED',

    LOAD_IAUDITOR_ORGANIZATION_DETAILS = '@@client/LOAD_IAUDITOR_ORGANIZATION_DETAILS',
    LOAD_IAUDITOR_ORGANIZATION_DETAILS_FULFILLED = '@@client/LOAD_IAUDITOR_ORGANIZATION_DETAILS_FULFILLED',
    LOAD_IAUDITOR_ORGANIZATION_DETAILS_REJECTED = '@@client/LOAD_IAUDITOR_ORGANIZATION_DETAILS_REJECTED',

    SAVE_IAUDITOR_ACCESS_TOKEN = '@@client/SAVE_IAUDITOR_ACCESS_TOKEN',
    SAVE_IAUDITOR_ACCESS_TOKEN_FULFILLED = '@@client/SAVE_IAUDITOR_ACCESS_TOKEN_FULFILLED',
    SAVE_IAUDITOR_ACCESS_TOKEN_REJECTED = '@@client/SAVE_IAUDITOR_ACCESS_TOKEN_REJECTED',

    REVOKE_IAUDITOR_ACCESS_TOKEN = '@@client/REVOKE_IAUDITOR_ACCESS_TOKEN',
    REVOKE_IAUDITOR_ACCESS_TOKEN_FULFILLED = '@@client/REVOKE_IAUDITOR_ACCESS_TOKEN_FULFILLED',
    REVOKE_IAUDITOR_ACCESS_TOKEN_REJECTED = '@@client/REVOKE_IAUDITOR_ACCESS_TOKEN_REJECTED',

    SORT_CLIENTS = '@@client/SORT_CLIENTS',

    LOAD_MYDATA_CLIENTS = '@@client/LOAD_MYDATA_CLIENTS',
    LOAD_MYDATA_CLIENTS_FULFILLED = '@@client/LOAD_MYDATA_CLIENTS_FULFILLED',
    LOAD_MYDATA_CLIENTS_REJECTED = '@@client/LOAD_MYDATA_CLIENTS_REJECTED',

    RESET_CLIENT = '@@client/RESET_CLIENT',
}

export const getOrganizationDetails = (clientId: string): IAppAction => ({
    type: ClientActions.LOAD_IAUDITOR_ORGANIZATION_DETAILS,
    payload: clientId,
});

export const saveAccessToken = (token: string, clientId: string): IAppAction => ({
    type: ClientActions.SAVE_IAUDITOR_ACCESS_TOKEN,
    payload: {
        token,
        clientId,
    },
});

export const revokeAccessToken = (clientId: string): IAppAction => ({
    type: ClientActions.REVOKE_IAUDITOR_ACCESS_TOKEN,
    payload: clientId,
});

export const loadClients = (filters: IClientFilters): IAppAction => ({
    type: ClientActions.LOAD_CLIENTS,
    payload: filters,
});

export const loadClient = (id: string): IAppAction => ({
    type: ClientActions.LOAD_CLIENT,
    payload: id,
});

export const createClient = (client: IClient): IAppAction => ({
    type: ClientActions.CREATE_CLIENT,
    payload: client,
});

export const updateClient = (client: IClient): IAppAction => ({
    type: ClientActions.UPDATE_CLIENT,
    payload: client,
});

export const deleteClient = (id: string): IAppAction => ({
    type: ClientActions.DELETE_CLIENT,
    payload: id,
});

export const onSort = (key: Extract<keyof IClient, string>, sortAscending: boolean) => ({
    type: ClientActions.SORT_CLIENTS,
    payload: { key, sortAscending },
});

export const loadMyDataClients = (): IAppAction => ({
    type: ClientActions.LOAD_MYDATA_CLIENTS,
});

export const resetClient = () => ({
    type: ClientActions.RESET_CLIENT,
});
