import { createSelector } from 'reselect';
import { IConfirmDeleteProcessStep } from 'reducers/cerm-overview';

const getStepBeingArchived = (state: IConfirmDeleteProcessStep) => state.stepItemToDelete;

export default createSelector(
    [getStepBeingArchived],
    (itemForConfirmation) => ({
        itemForConfirmation,
    })
);
