import {
  EmeraldDatepickerSharedInputs,
  IEmeraldDatepickerSharedInputs,
} from '../datepicker/datepicker.inputs';
import { IEmeraldDisabledInput } from '../shared/disabled.input';
import { IEmeraldErrorTextInput } from '../shared/error.text.input';
import { IEmeraldHelperTextInput } from '../shared/helper.text.input';
import { IEmeraldIdInput } from '../shared/id.input';
import { IEmeraldLabelInput } from '../shared/label.input';
import { IEmeraldLeadingIconInput } from '../shared/leading-icon.input';
import { IEmeraldOutlinedInput } from '../shared/outlined.input';
import { IEmeraldPlaceholderInput } from '../shared/placeholder.input';
import { IEmeraldReadyOnlyInput } from '../shared/read-only.input';
import { IEmeraldRequiredInput } from '../shared/required.input';
import { IEmeraldTitleInput } from '../shared/title.input';
import { IEmeraldTrailingIconInput } from '../shared/trailing-icon.input';
import { IEmeraldValidInput } from '../shared/valid.input';
import { EmeraldInputType, EmeraldInputTypeType } from './input.type';

export class NativeInputCoreInputs {
  id?: string = `${Math.floor(Math.random() * 10000)}`;
  label?: string;
  placeholder?: string;
  value?: string | number;
  title?: string;
  disabled?: boolean;
  required?: boolean;
  readOnly?: boolean = false;
  rows?: number; // for text area
  cols?: number; // for text area
  max?: string | number; // for number type
  min?: string | number; // for number type
  maxLength?: number; // for text type
  minLength?: number; // for text type
}

export class EmeraldInputCoreInputs
  extends EmeraldDatepickerSharedInputs
  implements IEmeraldInputCoreInputs
{
  type?: EmeraldInputType | EmeraldInputTypeType = EmeraldInputType.Text;
  id?: string = `${Math.floor(Math.random() * 10000)}`;
  classes?: string;
  label?: string;
  placeholder?: string;
  value?: string | number | readonly string[];
  defaultValue?: string | number | readonly string[];
  title?: string;
  disabled?: boolean;
  required?: boolean;
  readOnly?: boolean = false;
  outlined?: boolean = false;
  trailingIcon?: string;
  leadingIcon?: string;
  trailingTemplate?: any;
  leadingTemplate?: any;
  helperText?: string;
  errorText?: string;
  isValid?: boolean;
  prefixText?: string;
  suffixText?: string;
  hasCharacterCounter?: boolean; // for textarea type
  useNativeValidation?: boolean = true;
  useNativeHTMLInput?: boolean;
  showClearIcon?: boolean = false; // for text, number, date & textarea type
  floatLabel?: boolean;
  rows?: number; // for text area
  cols?: number; // for text area
  max?: string | number; // for number type
  min?: string | number; // for number type
  step?: string | number; // for number type
  maxLength?: number; // for text type
  minLength?: number; // for text type
  isFocused?: boolean;
  debounceTime?: number;
}

export interface IEmeraldInputCoreInputs
  extends IEmeraldDatepickerSharedInputs,
    IEmeraldDisabledInput,
    IEmeraldLabelInput,
    IEmeraldIdInput,
    IEmeraldTitleInput,
    IEmeraldPlaceholderInput,
    IEmeraldOutlinedInput,
    IEmeraldRequiredInput,
    IEmeraldValidInput,
    IEmeraldLeadingIconInput,
    IEmeraldTrailingIconInput,
    IEmeraldErrorTextInput,
    IEmeraldHelperTextInput,
    IEmeraldReadyOnlyInput {
  type?: EmeraldInputType | EmeraldInputTypeType;
  classes?: string;
  value?: string | number | readonly string[];
  prefixText?: string;
  suffixText?: string;
  hasCharacterCounter?: boolean; // for textarea type
  useNativeValidation?: boolean;
  useNativeHTMLInput?: boolean;
  showClearIcon?: boolean; // for text, number, date & textarea type
  floatLabel?: boolean;
  rows?: number; // for text area
  cols?: number; // for text area
  max?: string | number; // for number type
  min?: string | number; // for number type
  step?: string | number; // for number type
  maxLength?: number; // for text type
  minLength?: number; // for text type
  isFocused?: boolean;
  debounceTime?: number;
}
