export interface IAssetModel {
    siteId: string;
    cmmsReference: string;
    name?: string;
    model?: string;
    serial: string;
    manufacturer?: string;
    age: string;
}

export interface IAssetManufacturer {
    manufacturer: string;
}

export interface IModelDetails {
    model: string;
}

export enum AssetFields {
    cmmsReference = 'cmmsReference',
    name = 'name',
    model = 'model',
    serial = 'serial',
    manufacturer = 'manufacturer',
    age = 'age',
}

