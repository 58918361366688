import React from 'react';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
interface Iprops {
    language?: string;
    contentKey: string;
    editorState?: any;
    wrapperClassName: string;
    editorClassName: string;
    content: string;
    index: number;
    fieldKey: string;
    type: string;
    parentIndex?: number;
    onEditorStateChange?: (editorContent: any, id: number, fieldKey: string, type: string, parentIndex: number) => void;

}
export const ContentEditor: React.FC<Iprops> = (props) => {
    const [editorState, setEditorState] = React.useState(() => {
        if (props.content) {
            const blocksFromHTML = convertFromHTML(props.content)
            const contentState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            )

            return EditorState.createWithContent(contentState)
        }
        return EditorState.createEmpty()
    }
    )
    const toolbar = {
        options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history', 'link'],
        inline: { inDropdown: false },
        list: { inDropdown: false },
        textAlign: { inDropdown: false },
        link: { inDropdown: false },
        history: { inDropdown: false },
    }

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
    }
    const onBlurEditorState = (editorState) => {
        const content = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        props.onEditorStateChange(content, props.index, props.fieldKey, props.type, props.parentIndex)
    }

    return (
        <Editor
            key={props.contentKey}
            toolbarOnFocus
            toolbar={toolbar}
            defaultEditorState={editorState}
            wrapperClassName={props.wrapperClassName}
            editorClassName={props.editorClassName}
            onEditorStateChange={onEditorStateChange}
            onBlur={() => onBlurEditorState(editorState)}
        />
    )
};
