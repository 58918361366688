import * as React from 'react';
import selectors from './selectors';
import actions from './actions';
import { connect } from 'react-redux';
import { IAction } from 'models/action';
import { IDifference } from 'models/difference';
import EntityHistory from 'components/history/entity-history';
import useTranslate from 'translations/translation-utils';

interface IProps {
    id: string;
    loadHistory: (id: string) => void;
    action: IAction;
    actionHistory: IDifference[][];
}

const ActionHistoryTab: React.FC<IProps> = (props) => {
    const translate = useTranslate();
    React.useEffect(() => {
        if (!props.id) {
            return;
        }
        props.loadHistory(props.id);
    }, [props.id]);
    return (
        <div className="tabs-container tab-content">
            <div className="section">
                <div className="column is-12">
                    <div className="title is-4 section-title">
                        {translate('ActionUpsertPage.Tabs.History')}
                    </div>
                    <EntityHistory entity={props.action} entityHistory={props.actionHistory} />
                </div>
            </div>
        </div>
    );
};
export default connect(
    selectors,
    actions
)(ActionHistoryTab);
