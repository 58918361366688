import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import {
    createActionForMockDrillReport,
    updateActionForMockDrillReport,
    archiveActionForMockDrillReport,
} from 'actions/actions-v2/action-action-v2';
import { onShowUpsertActionModal, onShowEditActionModal, hideModal } from 'actions/action-actions';
import { loadActions } from 'actions/actions-v2/mock-drill-report-actions';
import { MockDrillReportActionsTab } from './mock-drill-report-actions-tab';
import { IRootActionsState } from 'reducers/actions';
import { getFilteredLookupSitePeopleWithAllowedRoles } from 'sharedSelectors';
import { loadPersonsLookup } from 'actions/actions-v2/person-action-v2';

const getSiteId = (state: IRootState) => state.app.siteId;
const getMockDrillReportActions = (state: IRootState) => state.mockDrillReportState.actions;
const getLookupActionCategories = (state: IRootState) => state.app.lookupActionCategories;
const getLookupActionPriorities = (state: IRootState) => state.app.lookupActionPriorities;
const getLookupActionStatuses = (state: IRootState) => state.app.lookupActionStatus;
const getLookupSites = (state: IRootState) => state.app.lookupSites;
const getIsUpsertModalShown = (state: IRootActionsState) =>
    state.actions.forms.showUpsertActionModal;
const getUpsertRequest = (state: IRootActionsState) => state.actions.forms.upsertItem;
const getLookups = createSelector(
    getLookupActionCategories,
    getLookupActionPriorities,
    getLookupActionStatuses,
    getLookupSites,
    getFilteredLookupSitePeopleWithAllowedRoles,
    (
        lookupActionCategories,
        lookupActionPriorities,
        lookupActionStatuses,
        lookupSites,
        lookupSitePeopleRoles
    ) => ({
        lookupActionCategories,
        lookupActionPriorities,
        lookupActionStatuses,
        lookupSites,
        lookupSitePeopleRoles,
    })
);

const mapStateToProps = createSelector(
    getSiteId,
    getMockDrillReportActions,
    getLookups,
    getIsUpsertModalShown,
    getUpsertRequest,
    (siteId, actions, lookups, isUpsertModalShown, upsertRequest) => ({
        siteId,
        actions,
        lookups,
        isUpsertModalShown,
        upsertRequest,
    })
);

const mapDispatchToProps = {
    loadActions,
    onShowUpsertActionModal,
    onShowEditActionModal,
    hideModal,
    createAction: createActionForMockDrillReport,
    updateAction: updateActionForMockDrillReport,
    archiveAction: archiveActionForMockDrillReport,
    loadPersonsLookup,
};

export const MockDrillReportActionsTabContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MockDrillReportActionsTab);
