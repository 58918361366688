import { compact, isNil } from 'lodash';
import { createSelector } from 'reselect';

import { IBuilding } from 'models/building';
import { ISiteRelationshipsRootState } from 'reducers/siteRelationships';

const getBuildingText = (building: IBuilding) => isNil(building)
    ? null
    : compact([
        building.buildingName
    ]).join(' ');

export default createSelector(
    (state: ISiteRelationshipsRootState) => state.siteRelationships.deleteBuildingConfirmation,
    (state) => ({
        item: state.building,
        itemText: getBuildingText(state.building),
        site: state.site,
        visible: Boolean(state.building && state.site)
    })
);
