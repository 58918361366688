import * as React from 'react';
import selectors from './selectors';
import actions from './actions';
import { connect } from 'react-redux';
import EntityHistory from 'components/history/entity-history';
import { Loader } from 'components/loader';
import useTranslate from 'translations/translation-utils';
import InfiniteScroll from 'react-infinite-scroller';
import { isEmpty } from 'lodash';
import { IDifference } from 'models/difference';

interface IProps {
    id: string;
    loadHistory: (id: string, responseContinuationId: string) => void;
    history: IDifference[][];
    historyResponseContinuation: string;
    isLoadingHistory?: boolean;
}

const CermAssessmentHistoryTab: React.FC<IProps> = ({
    id,
    loadHistory,
    history,
    isLoadingHistory,
    historyResponseContinuation,
}) => {
    const translate = useTranslate();
    React.useEffect(() => {
        if (!id) {
            return;
        }
        loadHistory(id, null);
    }, [
        id,
        loadHistory,
    ]);

    const loadMore = (): void => {
        if (!isLoadingHistory) {
            loadHistory(id, historyResponseContinuation);
        }
    };

    return (
        <Loader loading={isLoadingHistory}>
            <div className="tabs-container tab-content">
                <div className="section">
                    <div className="column is-12">
                        <div className="title is-4 section-title">{translate('RiskRegisterUpsertPage.Tabs.History')}</div>
                        <InfiniteScroll
                            loadMore={loadMore}
                            hasMore={!isEmpty(historyResponseContinuation)}
                            loader={<Loader loading={true} />}
                        >
                            <EntityHistory entityHistory={history} />
                        </InfiniteScroll>

                    </div>
                </div>
            </div>
        </Loader>
    );
};
export default connect(
    selectors,
    actions
)(CermAssessmentHistoryTab);
