import { updateNextExpertReviewStatus } from 'actions/actions-v2/cerm-assessment-action-v3';
import { loadPersonsBySiteAndPrivilege } from 'actions/actions-v2/person-action-v2';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { CermSiteScore } from './CermSiteScore';

const getSiteId = (state: IRootState) => state.app.siteId;
const getPermissions = (state: IRootState) => state.app.permissions;
const getCermAssessmentResultState = (state: IRootState) => state.cermAssessmentResultState;
const getSitePersonsWithPrivilege = (state: IRootState) =>
    state.persons.grid.siteAndPrivilegePersons;

const mapStateToProps = createSelector(
    getSiteId,
    getPermissions,
    getCermAssessmentResultState,
    getSitePersonsWithPrivilege,
    (siteId, permissions, cermAssessmentResultState, siteAndPrivilegePersons) => ({
        siteId,
        permissions,
        score: cermAssessmentResultState.cermAssessmentResult
            ? cermAssessmentResultState.cermAssessmentResult.score
            : null,
        assessmentResult: cermAssessmentResultState.cermAssessmentResult
            ? cermAssessmentResultState.cermAssessmentResult
            : null,
        isLoading: cermAssessmentResultState.isLoading,
        siteAndPrivilegePersons,
    })
);

const mapDispatchToProps = {
    loadPersonsBySiteAndPrivilege,
    updateNextExpertReviewStatus,
};

export const CermSiteScoreContainer = connect(mapStateToProps, mapDispatchToProps)(CermSiteScore);
