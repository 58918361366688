import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { getCurrentSiteName } from 'routes/competency-dna-process/competency-dna-process-container';
import { getIsClient, getCurrentSiteFeatures } from 'sharedSelectors';

const getIsLoading = (state: IRootState) => state.criticalSystemSparesState.isLoading;
const getSiteId = (state: IRootState) => state.app.siteId;
const getCriticalSpares = (state: IRootState) =>
    state.criticalSystemSparesState.criticalSystemSpares;
const getIsExporting = (state: IRootState) => state.criticalSystemSparesState.isExporting;
const getCategories = (state: IRootState) => state.app.lookupCriticalSystemSpareCategories;
const getSubCategories = (state: IRootState) => state.app.lookupCriticalSystemSpareSubCategories;

export default createSelector(
    getIsLoading,
    getSiteId,
    getCriticalSpares,
    getIsExporting,
    getCategories,
    getSubCategories,
    getCurrentSiteName,
    getIsClient,
    getCurrentSiteFeatures,
    (
        isLoading,
        siteId,
        criticalSpares,
        isExporting,
        categories,
        subCategories,
        siteName,
        isClient,
        siteFeatures
    ) => ({
        isLoading,
        siteId,
        criticalSpares,
        isExporting,
        categories,
        subCategories,
        siteName,
        isClient,
        showCriticalSpares:
            siteFeatures.includes('Critical Spares') ||
            siteFeatures.includes('Client facing critical spares'),
    })
);
