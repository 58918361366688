export type EmeraldBadgeSizeType = 'large' | 'medium' | 'small';

export enum EmeraldBadgeSize {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}

export enum EmeraldBadgeVariant {
  DEFAULT = 'default',
  NUMBER_LARGE = 'number-large',
}
