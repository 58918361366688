import { IAuditableItem } from './auditable-item';
import { IEnergyBill } from './energy-bill';
import { IKeyedItem } from './keyed-item';

export interface IBuilding extends IKeyedItem, IAuditableItem {
    buildingCode: string;
    buildingName: string;
    siteCode: string;
    country: string;
    facility: string;
    otherFacility?: string;
    tier: string;
    city: string;
    county: string;
    latitude: number;
    region: string;
    clientFacilityId: string;
    clientCode: string;
    myDataPropertyId: string;
    longitude: number;
    isoCountryCode: string;
    addressLine1: string;
    addressLine2: string;
    isMasterAddress: boolean;
    areaCode: string;
    isActive: boolean;
    postCode: string;
    displayUnit: string;
    raisedFloorSpace_SqFt?: number;
    totalSpace_SqFt?: number;
    powerUsageEffectiveness: number;
    totalGeneratorPowerCapacity_MW: number;
    upsPowerCapacity_MW: number;
    itPowerCapacity_MW: number;
    whiteSpaceFeet: number;
    whitespaceMeters: number;
    questionnaireResult: number;
    energyBills: IEnergyBill[];
    energyBillPeriods: enumEnergyBillPeriods;
    weatherStationID: string;

    numberOfFloors: number;
    numberOfHalls: number;
    numberActiveItRacks: number;

    currentFacilityItLoadMw: string;
    doYouHaveGeneratorsOrDRups: string;
    whatVoltageAreYourGeneratorsOrDrups: string;
    doYouHaveStaticUps: string;
    doesYourDataCentreHaveARaisedFloor: string;
    hotOrColdIsleContainment: string;
    anyExistingEnvironmentalMonitoring: string;
    anyExistingEnvironmentalMonitoringOther: string;
    isEnvironmentalMonitoringWorking: string;
    areYouMonitoringTemperaturePerRack: string;
    anyTemperatureAndOrHumiditySla: string;
    totalSiteCoolingPowerMw: string;
    coolingType: string;
    doYouHaveCracOrCrahs: string;
    numberOfCracOrCrahUnits: number;
    approximateTotalCoolingCapacityOfTheCracsOrCrahsMW: number;
    numberOfCracOrCrahUnitsOnStandbyOrOffByFault: number;
    doTheCracOrCrahUnitsHaveVariableSpeedFans: string;
    approximateAgeOfCracsOrCrahsInstallationYear: number;
    approximateAgeOfCoolingSystemInstallationYear: number;
}

export enum enumEnergyBillPeriods {
    month = 'month',
    week = 'week',
}

export interface IExternalBuilding extends IKeyedItem, IAuditableItem {
    id: string;
    name: string;
}
