import { createSelector } from 'reselect';
import { getPermissions, getCurrentSelectedSiteId, getSitesWithFeatures } from 'sharedSelectors';
import { IRootState } from '../store';

const getIsClient = (state: IRootState) => state.app.isClient;

export default createSelector(
    getPermissions,
    getCurrentSelectedSiteId,
    getSitesWithFeatures,
    getIsClient,
    (permissions, siteId, lookupSitesFeatures, isClient) => ({
        permissions,
        siteId,
        lookupSitesFeatures,
        isClient
    })
);
