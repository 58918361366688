import { createSelector } from 'reselect';
import { IBaseState } from 'reducers/base-state';

const getPermissions = createSelector(
    (state: IBaseState) => state.app,
    (state) => state.permissions
);

export default createSelector(
    getPermissions,
    (permissions) => ({
        permissions
    })
);
