import * as React from 'react';
import { Button } from '../components';
import useTranslate from 'translations/translation-utils';

interface IProps {
    title: string;
    message?: string | JSX.Element;
    getMessage?: () => string | JSX.Element;
    isVisible: boolean;
    isLoading?: boolean;
    id?: string;
    buttonCancelText?: string;
    buttonCancelHide?: boolean;
    buttonOneText: string;
    buttonTwoText: string;
    onButtonOneClick: () => void;
    onButtonTwoClick: () => void;
    onClose?: () => void;
    onOutsideDialogClick?: () => void;
}

export const MultiButtonConfirmDialog: React.FC<IProps> = ({
    title,
    message: confirmMessage,
    getMessage,
    isVisible,
    isLoading,
    id = 'confirmation',
    buttonCancelText,
    buttonCancelHide = false,
    buttonOneText,
    buttonTwoText,
    onButtonOneClick,
    onButtonTwoClick,
    onClose,
    onOutsideDialogClick,
}) => {
    const translate = useTranslate();
    const getConfirmMessage = () => {
        if (getMessage) {
            return getMessage();
        }

        if (typeof confirmMessage === 'string') {
            return (
                <div>
                    <strong>{confirmMessage}</strong>
                </div>
            );
        }

        return confirmMessage;
    };

    buttonCancelText = buttonCancelText
        ? buttonCancelText
        : translate('ConfirmNavigation.Label.Cancel');

    return isVisible ? (
        <div id={id} className={`modal modal-quantum is-clipped is-active`}>
            <div className="modal-background" onClick={onOutsideDialogClick} />
            <div className="modal-content">
                <h2>{title}</h2>
                {getConfirmMessage()}
                <div className="modal-buttons">
                    {!buttonCancelHide && (
                        <Button buttonType="cancel" onClick={onClose} id="cancel-button">
                            {buttonCancelText}
                        </Button>
                    )}

                    <Button onClick={onButtonOneClick} isLoading={isLoading} id="confirm-button">
                        {buttonOneText}
                    </Button>

                    <Button onClick={onButtonTwoClick} isLoading={isLoading} id="confirm-button">
                        {buttonTwoText}
                    </Button>
                </div>
            </div>
        </div>
    ) : null;
};
