import { FormikProps, FormikErrors } from 'formik';
import { IIncident, IncidentImpact, IncidentStatus } from 'models';
import { Field } from 'components/form/Field';
import { BulmaSize } from 'enums/BulmaSize';
import React from 'react';
import { Checkbox } from 'components/form/Checkbox';
import { Control } from 'components/form/Control';
import { Input } from 'components/form/Input';
import useTranslate from 'translations/translation-utils';

interface IProps {
    formikBag: FormikProps<Partial<IIncident>>;
    getHelper: (formikErrors: FormikErrors<any> | any, isShown?: boolean) => void;
    handleFactorsChange: (item: IncidentImpact) => void;
}

export const IncidentImpactedCheckBoxes: React.FC<IProps> = ({
    formikBag,
    getHelper,
    handleFactorsChange,
}) => {
    const translate = useTranslate();
    return (
        <div>
            <Field
                isHorizontal={true}
                htmlFor="factors"
                label={translate('IncidentDetails.Label.IncidentImpact')}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control className="columns is-desktop">
                        <div className="column is-3">
                            <Checkbox
                                checked={formikBag.values.incidentImpact.includes(
                                    IncidentImpact.DroppedCriticalLoad
                                )}
                                id={IncidentImpact.DroppedCriticalLoad}
                                label={translate(
                                    'IncidentDetails.IncidentImpact.'.concat(
                                        IncidentImpact.DroppedCriticalLoad.toString().replace(/\s/g, '')
                                    )
                                )}
                                onChange={handleFactorsChange.bind(
                                    this,
                                    IncidentImpact.DroppedCriticalLoad
                                )}
                                disabled={formikBag.values.status === IncidentStatus.Closed}
                            />
                        </div>
                        <div className="column is-3">
                            <Checkbox
                                checked={formikBag.values.incidentImpact.includes(
                                    IncidentImpact.ReducedRedundancy
                                )}
                                id={IncidentImpact.ReducedRedundancy}
                                label={translate(
                                    'IncidentDetails.IncidentImpact.'.concat(
                                        IncidentImpact.ReducedRedundancy.toString().replace(/\s/g, '')
                                    )
                                )}
                                onChange={handleFactorsChange.bind(
                                    this,
                                    IncidentImpact.ReducedRedundancy
                                )}
                                disabled={formikBag.values.status === IncidentStatus.Closed}
                            />
                        </div>
                        <div className="column is-3">
                            <Checkbox
                                checked={formikBag.values.incidentImpact.includes(
                                    IncidentImpact.Evacuation
                                )}
                                id={IncidentImpact.Evacuation}
                                label={translate(
                                    'IncidentDetails.IncidentImpact.'.concat(
                                        IncidentImpact.Evacuation.toString().replace(/\s/g, '')
                                    )
                                )}
                                onChange={handleFactorsChange.bind(this, IncidentImpact.Evacuation)}
                                disabled={formikBag.values.status === IncidentStatus.Closed}
                            />
                        </div>
                        <div className="column is-3">
                            <Checkbox
                                checked={formikBag.values.incidentImpact.includes(
                                    IncidentImpact.PersonnelInjury
                                )}
                                id={IncidentImpact.PersonnelInjury}
                                label={translate(
                                    'IncidentDetails.IncidentImpact.'.concat(
                                        IncidentImpact.PersonnelInjury.toString().replace(/\s/g, '')
                                    )
                                )}
                                onChange={handleFactorsChange.bind(
                                    this,
                                    IncidentImpact.PersonnelInjury
                                )}
                                disabled={formikBag.values.status === IncidentStatus.Closed}
                            />
                        </div>
                    </Control>
                </Field>
            </Field>
            <Field isHorizontal={true} label=" ">
                <Field disabled={formikBag.values.status === IncidentStatus.Closed}>
                    <Control className="columns is-desktop">
                        <div className="column is-3">
                            <Checkbox
                                checked={formikBag.values.incidentImpact.includes(
                                    IncidentImpact.EnvironmentalImpact
                                )}
                                id={IncidentImpact.EnvironmentalImpact}
                                label={translate(
                                    'IncidentDetails.IncidentImpact.'.concat(
                                        IncidentImpact.EnvironmentalImpact.toString().replace(/\s/g, '')
                                    )
                                )}
                                onChange={handleFactorsChange.bind(
                                    this,
                                    IncidentImpact.EnvironmentalImpact
                                )}
                                disabled={formikBag.values.status === IncidentStatus.Closed}
                            />
                        </div>
                        <div className="column is-3">
                            <Checkbox
                                checked={formikBag.values.incidentImpact.includes(
                                    IncidentImpact.UtilityOutage
                                )}
                                id={IncidentImpact.UtilityOutage}
                                label={translate(
                                    'IncidentDetails.IncidentImpact.'.concat(
                                        IncidentImpact.UtilityOutage.toString().replace(/\s/g, '')
                                    )
                                )}
                                onChange={handleFactorsChange.bind(
                                    this,
                                    IncidentImpact.UtilityOutage
                                )}
                                disabled={formikBag.values.status === IncidentStatus.Closed}
                            />
                        </div>
                        <div className="column is-6">
                            <Checkbox
                                checked={formikBag.values.incidentImpact.includes(
                                    IncidentImpact.IncidentImpactOther
                                )}
                                id="IncidentImpactIncidentImpactOther"
                                label={translate(
                                    'IncidentDetails.IncidentImpact.'.concat(
                                        (IncidentImpact.IncidentImpactOther.toString().replace(/\s/g, '')).replace(/[,]/g, '')
                                    )
                                )}
                                onChange={handleFactorsChange.bind(
                                    this,
                                    IncidentImpact.IncidentImpactOther
                                )}
                                disabled={formikBag.values.status === IncidentStatus.Closed}
                            />
                        </div>
                    </Control>
                    <Control className="columns is-desktop">
                        <>
                            <div className="column is-8">
                                <Input
                                    id="incidentImpactOther"
                                    value={formikBag.values.incidentImpactOther}
                                    onChange={formikBag.handleChange}
                                    onBlur={formikBag.handleBlur}
                                    disabled={
                                        !formikBag.values.incidentImpact.includes(
                                            IncidentImpact.IncidentImpactOther
                                        ) || formikBag.values.status === IncidentStatus.Closed
                                    }
                                />
                            </div>
                            {
                                ((formikBag.values.incidentImpact.includes(IncidentImpact.IncidentImpactOther)) || (formikBag.values.incidentImpact.length === 0))
                                && getHelper(formikBag.errors.incidentImpactOther, formikBag.touched.incidentImpactOther)
                            }
                        </>
                    </Control>
                </Field>
            </Field>
        </div>
    );
};
