import * as React from 'react';
import { Loader } from 'components/loader';
import { ISiteAssessmentSummary, ISiteProfile } from 'models/site-profile';
import { Button, Toolbar, ToolbarGroupRight } from 'components/v2/components';
import { Formik, FormikProps } from 'formik';
import { IAttachmentQuery } from 'models/attachment/attachment-query';
import { Entity } from 'models/entity';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import { ICermAssessmentSnapshot } from 'models/cerm/cerm-assessment-snapshot';
import { easeInScrollToTop } from 'utils/animation-utils';
import { CermAssessmentSummaryTabForm } from './cerm-assessment-summary-tab-form'
import { CermAssessmentSummaryTabReadOnlyForm } from './cerm-assessment-summary-tab-readOnly'
import { ConfirmNavigateAwayDialog } from 'components/v2/dialog/ConfirmNavigateAwayDialog';
import { object, string } from 'yup';
import useTranslate from 'translations/translation-utils';
import { IAttachmentUpload } from 'models/attachment/attachment-upload';
import { IKeyValue } from 'models';

interface IProps {
    isLoading: boolean;
    isSaving: boolean;
    siteId: string;
    site: ISiteProfile;
    snapshots: ICermAssessmentSnapshot[];
    siteImage: IAttachmentModel[];
    loadAttachments: (query: IAttachmentQuery) => void;
    updateAssessmentSummary: (siteId: string, model: Partial<ISiteAssessmentSummary>) => void;
    uploadAttachmentSite: (attachment: IAttachmentUpload) => void;
    deleteAttachment: (attachment: IAttachmentQuery) => void;
    permissions: Array<IKeyValue<string>>;
    loadCermSnapshotsOverviewOnly: (siteId: string) => void;
}

export const cermValidationSchema = object<Partial<ISiteAssessmentSummary>>().shape({
    facilityDescription: string().trim().nullable(),
    assessmentSummary: string().trim().nullable(),
    yearConstructed: string().trim().nullable(),
    criticalLinesOfBusiness: string().trim().nullable(),
    space: string().trim().nullable(),
    staff: string().trim().nullable(),
    contactInfo: string().trim().nullable(),
});

export const CermAssessmentSummaryTab: React.FC<IProps> = ({
    isLoading,
    isSaving,
    siteId,
    site,
    snapshots,
    siteImage,
    loadAttachments,
    updateAssessmentSummary,
    uploadAttachmentSite,
    deleteAttachment,
    permissions,
    loadCermSnapshotsOverviewOnly
}): React.JSX.Element => {
    let formikBag: FormikProps<ISiteAssessmentSummary>;
    const [isFormDirty, setIsFormDirty] = React.useState(false);
    const [isReadOnly, setIsReadOnly] = React.useState(true);
    const translate = useTranslate();

    React.useEffect(() => {
        if (siteId) {
            loadAttachments({ entity: Entity.SiteImage, id: siteId, siteId });
        }
        loadCermSnapshotsOverviewOnly(siteId);
    }, [siteId]);

    const onSave = (): void => {
        formikBag.submitForm();
        easeInScrollToTop();
        setIsReadOnly(true);
    };

    const onEdit = (): void => {
        setIsReadOnly(false);
    };

    const onCancel = (): void => {
        formikBag.resetForm();
        setIsReadOnly(true);
    }

    const renderTab = (formik: FormikProps<ISiteAssessmentSummary>) => {
        formikBag = formik;
        setIsFormDirty(formik.dirty);
        return (
            <>
                {
                    isReadOnly ?
                        <CermAssessmentSummaryTabReadOnlyForm
                            formikBag={formikBag}
                            snapshots={snapshots}
                            siteImage={siteImage}
                            site={site}
                            isEdit={isReadOnly}
                        /> :
                        <CermAssessmentSummaryTabForm
                            formikBag={formikBag}
                            snapshots={snapshots}
                            siteImage={siteImage}
                            site={site}
                            uploadAttachmentSite={uploadAttachmentSite}
                            deleteAttachment={deleteAttachment}
                            isLoading={isLoading}
                            isSaving={isSaving}
                            permissions={permissions}
                            isEdit={isReadOnly}
                        />
                }
            </>
        );
    };

    const onSubmit = (value: Partial<ISiteAssessmentSummary>): void => {
        setIsFormDirty(false);
        updateAssessmentSummary(siteId, value);
    };

    return (
        <div className="cerm-assessment-summary">
            <div className="tabs-container tab-content cerm-assessment-summary-tab">
                <Loader loading={isLoading}>
                    {!isReadOnly ?
                        <Toolbar type="save">
                            <ToolbarGroupRight>
                                <Button
                                    id="btnCancel"
                                    type="button"
                                    buttonType="cancel"
                                    onClick={onCancel}
                                    isLoading={isLoading}
                                >
                                    {translate('Globals.Label.Cancel')}
                                </Button>
                                <Button
                                    id="btnSave"
                                    type="submit"
                                    buttonType="save"
                                    onClick={onSave}
                                    isLoading={isLoading}
                                    disabled={!isFormDirty}
                                >
                                    {translate('Globals.Label.Save')}
                                </Button>
                            </ToolbarGroupRight>
                        </Toolbar> :
                        <Toolbar type="save" className='edit-button-section'>
                            <ToolbarGroupRight>
                                <Button
                                    id="btnEdit"
                                    type="button"
                                    buttonType="action"
                                    onClick={onEdit}
                                    isLoading={isLoading}
                                >
                                    {translate('Globals.Label.Edit')}
                                </Button>
                            </ToolbarGroupRight>
                        </Toolbar>
                    }

                    <Formik<Partial<ISiteAssessmentSummary>>
                        initialValues={site.assessmentSummary}
                        validationSchema={cermValidationSchema}
                        onSubmit={onSubmit}
                        render={renderTab}
                        enableReinitialize={true}
                        isInitialValid={true}
                    />

                    <ConfirmNavigateAwayDialog when={isFormDirty} />
                </Loader>
            </div>
        </div>
    );
};
