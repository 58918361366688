import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { IIncident } from 'models';
import { IncidentActions } from 'actions/actions-v2/incident-action-v2';
import { IIncidentSettings } from 'models/incidents/incident-settings';

const controllerName = 'incident';

const createActions = {
    action: IncidentActions.CREATE_INCIDENT,
    actionFulfilled: IncidentActions.CREATE_INCIDENT_FULFILLED,
    actionRejected: IncidentActions.CREATE_INCIDENT_REJECTED,
} as IActionType;

const updateActions = {
    action: IncidentActions.UPDATE_INCIDENT,
    actionFulfilled: IncidentActions.UPDATE_INCIDENT_FULFILLED,
    actionRejected: IncidentActions.UPDATE_INCIDENT_REJECTED,
} as IActionType;

const setApprovalActions = {
    action: IncidentActions.SET_INCIDENT_APPROVAL,
    actionFulfilled: IncidentActions.SET_INCIDENT_APPROVAL_FULFILLED,
    actionRejected: IncidentActions.SET_INCIDENT_APPROVAL_REJECTED,
} as IActionType;

const getAllActions = {
    action: IncidentActions.LOAD_INCIDENTS,
    actionFulfilled: IncidentActions.LOAD_INCIDENTS_FULFILLED,
    actionRejected: IncidentActions.LOAD_INCIDENTS_REJECTED,
} as IActionType;

const getActions = {
    action: IncidentActions.LOAD_INCIDENT,
    actionFulfilled: IncidentActions.LOAD_INCIDENT_FULFILLED,
    actionRejected: IncidentActions.LOAD_INCIDENT_REJECTED,
} as IActionType;

const deleteActions = {
    action: IncidentActions.DELETE_INCIDENT,
    actionFulfilled: IncidentActions.DELETE_INCIDENT_FULFILLED,
    actionRejected: IncidentActions.DELETE_INCIDENT_REJECTED,
} as IActionType;

const exportIncidents = {
    action: IncidentActions.EXPORT_INCIDENTS,
    actionFulfilled: IncidentActions.EXPORT_INCIDENTS_FULFILLED,
    actionRejected: IncidentActions.EXPORT_INCIDENTS_REJECTED,
} as IActionType;

const loadHistory = {
    action: IncidentActions.LOAD_HISTORY,
    actionFulfilled: IncidentActions.LOAD_HISTORY_FULFILLED,
    actionRejected: IncidentActions.LOAD_HISTORY_REJECTED,
} as IActionType;

const loadSettings = {
    action: IncidentActions.LOAD_SETTINGS,
    actionFulfilled: IncidentActions.LOAD_SETTINGS_FULFILLED,
    actionRejected: IncidentActions.LOAD_SETTINGS_REJECTED,
} as IActionType;

const createIncidentEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createActions).post<IIncident>(`/api/${controllerName}`);

const updateIncidentEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateActions).put<IIncident>(`/api/${controllerName}`);

const setIncidentApprovalEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, setApprovalActions).put<IIncident>(`/api/${controllerName}/approval`);

const loadIncidentsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getAllActions).get<IIncident[]>(`/api/${controllerName}`);

const loadIncidentEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getActions).getByIdAndShard<IIncident>(`/api/${controllerName}`);

const deleteIncidentEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, deleteActions, '/incidents').deleteByIdAndSiteId<string>(
        `/api/${controllerName}`
    );

const exportIncidentsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, exportIncidents).exportByQueryModel<IIncident[]>(`/api/${controllerName}/export`);

const loadHistoryEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, loadHistory).getById<IIncident>(`/api/${controllerName}/history`);

const loadSettingsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, loadSettings).get<IIncidentSettings>(`/api/${controllerName}/settings`);

export const incidentEpicsV2 = combineEpics(
    createIncidentEpic,
    updateIncidentEpic,
    setIncidentApprovalEpic,
    loadIncidentsEpic,
    loadIncidentEpic,
    deleteIncidentEpic,
    exportIncidentsEpic,
    loadHistoryEpic,
    loadSettingsEpic
);
