import { history } from 'routes/App';
import { ClientActions, ClientActionTypes } from '../../../actions/client-actions';
import { loadClients } from 'actions/actions-v2/client-action-v2';

export default {
    onChangeRoute: (url: string) => history.push(url),
    onSort: (sortColumn: string, sortAscending: boolean): ClientActionTypes => ({
        type: ClientActions.SORT_CLIENT,
        payload: {
            key: sortColumn,
            sortAscending
        }
    }),
    loadClients
};
