import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { connect } from 'react-redux';
import { CalendarDetailsPage } from './calendar-details-page';
import {
    onSiteChange,
    onLoadCalendar,
    onFilterCalendar,
    onCreateCalendarEvent,
    onLoadCalendarEvent,
    onUpdateCalendarEvent,
    onDeleteCalendarEvent,
} from 'actions/actions-v2/calendar-action-v2';
import { getFilteredLookupSitePeopleWithAllowedRoles } from 'sharedSelectors';
import { loadPersonsLookup } from 'actions/actions-v2/person-action-v2';
import { withRouter } from 'react-router';

const getPermissions = (state: IRootState) => state.app.permissions;
const getSiteId = (state: IRootState) => state.app.siteId;
const getLookups = createSelector(
    (state: IRootState) => state.controlCentre.sites,
    (state) =>
        state.map((site) => {
            return { key: site.id, value: site.clientAndSiteName };
        })
);

const getCalendarEvent = (state: IRootState) => state.calendar.calendarEvent;
const getSelectedValues = (state: IRootState) => state.calendar.selectedValues;
const getLoading = (state: IRootState) => state.calendar.isLoading;

const mapStateToProps = createSelector(
    getSelectedValues,
    getSiteId,
    getPermissions,
    getLookups,
    getFilteredLookupSitePeopleWithAllowedRoles,
    getLoading,
    getCalendarEvent,
    (
        selectedValues,
        siteId,
        permissions,
        lookupSites,
        lookupSitePeopleRoles,
        isLoading,
        calenderEvent
    ) => ({
        selectedValues,
        siteId,
        permissions,
        lookupSites,
        lookupSitePeopleRoles,
        isLoading,
        calenderEvent,
    })
);

const mapDispatchToProps = {
    onSiteChange,
    onLoadCalendar,
    onFilterCalendar,
    onCreateCalendarEvent,
    onUpdateCalendarEvent,
    onDeleteCalendarEvent,
    onLoadCalendarEvent,
    loadPersonsLookup,
};

export const CalendarDetailsContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CalendarDetailsPage)
);
