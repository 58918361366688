import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { getPermissions, getRegionsLookUps } from 'sharedSelectors';

const getIsLoading = (state: IRootState) => state.cermGlobalOverviewState.isLoading;
const getCermList = (state: IRootState) => state.cermGlobalOverviewState.cermGlobalOverviewList;
const getClients = (state: IRootState) => state.app.lookupClients;
const getIsExporting = (state: IRootState) => state.cermGlobalOverviewState.isExporting;
const getOperatingPlatforms = (state: IRootState) =>
    state.operatingPlatformState.operatingPlatforms;
const getLineOfBusiness = (state: IRootState) => state.siteMetaDataState.allLineOfBusinesses;
const getAccessOpsList = (state: IRootState) => {
    return state.operatingPlatformState.operatingPlatforms && state.operatingPlatformState.operatingPlatforms.filter(item => state.person.personProfile.accessRights.operatingPlatforms.includes(item.key));
};

export default createSelector(
    getIsLoading,
    getCermList,
    getClients,
    getIsExporting,
    getPermissions,
    getLineOfBusiness,
    getRegionsLookUps,
    getOperatingPlatforms,
    getAccessOpsList,
    (isLoading, cermList, lookupClients, isExporting, permissions, linesOfBusiness, regions,operatingPlatforms, OpsList) => ({
        isLoading,
        cermList,
        lookupClients,
        isExporting,
        permissions,
        linesOfBusiness,
        regions,
        operatingPlatforms,
        OpsList
    })
);
