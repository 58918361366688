
import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';

const getIsUploading = (state: IRootState) => state.riskRegisterState.isLoading;
const getAttachments = (state: IRootState) => state.riskRegisterState.attachments;

export default createSelector(
    getIsUploading,
    getAttachments,
    (isUploading, attachments) => ({
        isUploading,
        attachments,
    })
);
