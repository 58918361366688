import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import {
    getSitesWithFeatures,
    getFilteredLookupSitePeopleWithAllowedRoles,
    getLookupRiskCategories,
} from 'sharedSelectors';
import { RiskDetailsForm } from './risk-details-form';
import actions from './actions';

const getPermissions = (state: IRootState) => state.app.permissions;
const getImpacts = (state: IRootState) => state.app.lookupRiskImpacts;
const getLikelihoods = (state: IRootState) => state.app.lookupRiskLikelihoods;
const getRiskStatuses = (state: IRootState) => state.app.lookupRiskStatus;
const getSite = (state: IRootState) => state.siteState.site;
const getRiskActions = (state: IRootState) => state.riskRegisterState.actions;
const getSitePersonsWithPrivilege = (state: IRootState) =>
    state.persons.grid.siteAndPrivilegePersons;
const getSitePersonsWithPrivilegeIsLoading = (state: IRootState) =>
    state.persons.grid.isLoading;

const mapStateToProps = createSelector(
    getLookupRiskCategories,
    getImpacts,
    getLikelihoods,
    getRiskStatuses,
    getFilteredLookupSitePeopleWithAllowedRoles,
    getSitePersonsWithPrivilege,
    getSitePersonsWithPrivilegeIsLoading,
    getSitesWithFeatures,
    getPermissions,
    getSite,
    getRiskActions,
    (
        categories,
        impacts,
        likelihoods,
        riskStatuses,
        lookupSitePeopleRoles,
        siteAndPrivilegePersons,
        siteAndPrivilegePersonsIsLoading,
        lookupSiteFeatures,
        permissions,
        site,
        riskActions,
    ) => ({
        categories,
        impacts,
        likelihoods,
        riskStatuses,
        lookupSitePeopleRoles,
        siteAndPrivilegePersons,
        siteAndPrivilegePersonsIsLoading,
        lookupSiteFeatures,
        permissions,
        site,
        riskActions,
    })
);

export const RiskDetailsFormContainer = connect(mapStateToProps, actions)(RiskDetailsForm);
