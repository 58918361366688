import { Help } from 'components/form';
import { BulmaColor } from 'enums/BulmaColor';
import moment, { Moment } from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';
import { formatDateString } from 'utils/date-utils';
import { useTranslate } from 'translations/translation-utils';

interface IProps {
    value: Date;
    disabled?: boolean;
    setValue: (value: Date) => void;
    locale: string;
    setTime: boolean;
    setTimeValid: (isValid: boolean) => void;
    showError: boolean;
    minDate?: Date;
}

export const CustomDateTime: React.FC<IProps> = ({
    value: calendarValue,
    disabled,
    setValue,
    locale,
    setTime,
    setTimeValid,
    showError,
    minDate,
}) => {
    const translate = useTranslate();
    const [dateFormat, setDateFormat] = React.useState('dd/MM/yyyy');
    const [startDate, setStartDate] = React.useState(new Date());
    const [minimumDate, setMinimumDate] = React.useState<Date>(null);
    const [isValid, setIsValid] = React.useState(false);
    const [val, setVal] = React.useState('');
    React.useEffect(() => {
        if (calendarValue) {
            const date = moment(calendarValue);
            setStartDate(date.toDate());
        }
    }, [calendarValue]);

    React.useEffect(() => {
        if (minDate) {
            const date = moment(minDate);
            setMinimumDate(date.toDate());
        }
    }, [minDate]);

    React.useEffect(() => {
        if (setTime) {
            if (calendarValue) {
                setVal(getValue(moment(calendarValue)));
            } else {
                setVal('');
            }
            if (!disabled) {
                setTimeValid(true);
                setIsValid(true);
            }
        }
    }, [setTime]);

    React.useEffect(() => {
        if (locale === 'en-US') {
            setDateFormat('MM/dd/yyyy');
        }
    }, [locale]);

    const getValue = (date: Moment) => {
        if (!setTime) {
            return '';
        }
        return `${date.get('hour') < 10 ? '0' : ''}${date.get('hour')}:${date.get('minute') < 10 ? '0' : ''
            }${date.get('minute')}`;
    };

    const setDate = (date: Date) => {
        setStartDate(date);
        setValue(date);
    };

    const setHour = (date: Date) => {
        if (date) {
            const time = moment(date);
            const value = moment(startDate).set({
                hour: time.get('hour'),
                minute: time.get('minute'),
            });

            setStartDate(value.toDate());
            setValue(value.toDate());
        }
    };

    const onChangeRow = (element: any) => {
        const time = element.target.value;
        if (validate(time)) {
            if (time.length < 6) {
                if (time.length === 3 && !time.includes(':')) {
                    const result = time.slice(0, 2) + ':' + time.slice(2);
                    setVal(result);
                } else {
                    setVal(time);
                }
                validateTime(time);
            }
        }
    };

    const validateTime = (time: string) => {
        if (time.length < 5) {
            setTimeValid(false);
            setIsValid(false);
        } else {
            const hourAndMin = time.split(':', 2);
            const hour = hourAndMin[0];
            const min = hourAndMin[1];

            if (Number(hour) > 23 || Number(min) > 59) {
                setTimeValid(false);
                setIsValid(false);
            } else {
                setTimeValid(true);
                setIsValid(true);
            }
        }
    };

    const validate = (time: string) => {
        if (!time) {
            return true;
        }
        const hourAndMin = time.split(':', 2);
        for (const item of hourAndMin) {
            if (isNaN(Number(item))) {
                return false;
            }
        }
        return true;
    };
    return (
        <div className="columns">
            <div className="column">
                <DatePicker
                    className="custom-date"
                    selected={startDate}
                    maxDate={new Date()}
                    minDate={minimumDate}
                    onChange={setDate}
                    disabled={disabled}
                    dateFormat={dateFormat}
                    value={formatDateString(calendarValue, 'DD MMM YYYY', '')}
                />
            </div>
            <div className="column" style={{ minWidth: '158px' }}>
                <span>{`${translate('IncidentDetails.Label.Time')} (${translate('IncidentDetails.Label.LocalTime')})`}: </span>
                <DatePicker
                    className="custom-time"
                    placeholderText={'24h-HH:MM'}
                    selected={startDate}
                    onChange={setHour}
                    showTimeSelectOnly={true}
                    timeCaption="Time"
                    timeIntervals={1}
                    dateFormat="HH:mm"
                    disabled={disabled}
                    onChangeRaw={onChangeRow}
                    value={val}
                />
                <Help bulmaColor={BulmaColor.Danger} isShown={showError && !isValid && !!val}>
                    {translate('IncidentDetails.Label.InvalidTime')}
                </Help>
                <Help bulmaColor={BulmaColor.Danger} isShown={showError && !isValid && !val}>
                    {translate('IncidentDetails.Label.TimeRequired')}
                </Help>
            </div>
        </div>
    );
};
