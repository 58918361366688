import { QuestionnaireActions, QuestionnaireActionTypes } from 'actions/questionnaire-actions';
import { IBuilding } from 'models/building';

export default {
    onSubmit: (siteId: string, building: Partial<IBuilding>): QuestionnaireActionTypes => ({
        type: QuestionnaireActions.SUBMIT_MODEL,
        payload: {
            siteId,
            building
        }
    })
};
