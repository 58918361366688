import { riskEnGB } from './risks/en-GB';
import { riskEs } from './risks/es';
import { riskJa } from './risks/ja';
import { incidentEnGB } from './incident/en-GB';
import { incidentJa } from './incident/ja';
import { incidentEs } from './incident/es';
import { clientRiskEnGB } from './client-risk/en-GB';
import { clientRiskEs } from './client-risk/es';
import { clientRiskJa } from './client-risk/ja';
import { actionEnGB } from './action/en-GB';
import { actionJa } from './action/ja';
import { actionEs } from './action/es';
import { mockDrillEnGB } from './mock-drill/en-GB';
import { mockDrillJa } from './mock-drill/ja';
import { mockDrillEs } from './mock-drill/es';
import { criticalSpareEnGB } from './critical-spare/en-GB';
import { criticalSpareJa } from './critical-spare/ja';
import { criticalSpareEs } from './critical-spare/es';
import { shiftHandoverEnGB } from './shift-handover/en-GB';
import { shiftHandoverEs } from './shift-handover/es';
import { shiftHandoverJa } from './shift-handover/ja';
import { cermAssesmentEnGB, hermAssesmentEnGB } from './cerm-assesment/en-GB';
import { cermAssesmentEs, hermAssesmentEs } from './cerm-assesment/es';
import { cermAssesmentJa, hermAssesmentJa } from './cerm-assesment/ja';
import { siteFacilitiesEnGB } from './site-facilities/en-GB';
import { siteFacilitiesEs } from './site-facilities/es';
import { siteFacilitiesJa } from './site-facilities/ja';
import { riskQuestionsOverviewEnGB } from './risk-questions-overview/en-GB';
import { riskQuestionsOverviewJa } from './risk-questions-overview/ja';
import { riskQuestionsOverviewEs } from './risk-questions-overview/es';

const helpTextTranslation = {
    risks: {
        'en-GB': riskEnGB,
        ja: riskJa,
        es: riskEs,
    },
    incident: {
        'en-GB': incidentEnGB,
        ja: incidentJa,
        es : incidentEs,
    },
    clientRisk: {
        'en-GB': clientRiskEnGB,
        ja: clientRiskJa,
        es: clientRiskEs,
    },
    action: {
        'en-GB': actionEnGB,
        ja: actionJa,
        es: actionEnGB,
    },
    mockDrill: {
        'en-GB': mockDrillEnGB,
        ja: mockDrillJa,
        es : mockDrillEs,
    },
    criticalSpare: {
        'en-GB': criticalSpareEnGB,
        ja: criticalSpareJa,
        es: criticalSpareEs,
    },
    shiftHandover: {
        'en-GB': shiftHandoverEnGB,
        ja: shiftHandoverJa,
        es: shiftHandoverEs,
    },
    assesment: {
        'en-GB': cermAssesmentEnGB,
        ja: cermAssesmentJa,
        es: cermAssesmentEs,
    },
    siteFacilities: {
        'en-GB': siteFacilitiesEnGB,
        ja: siteFacilitiesJa,
        es: siteFacilitiesEs,
    },
    riskQuestionsOverview: {
        'en-GB': riskQuestionsOverviewEnGB,
        ja: riskQuestionsOverviewJa,
        es: riskQuestionsOverviewEs,
    },
};

export default helpTextTranslation;
