import * as React from 'react';
import { CheckboxFilter } from 'components/checkboxFilter';
import { SearchFilter } from 'components/searchFilter';
import { IKeyValue } from 'models/key-value';
import { IEngineersFilters } from 'models/engineers-filters';
import { engineerSkillLevels } from 'models/engineer-enums';

interface IProps {
    filters: IEngineersFilters;
    onFilter: (key: string, element: string) => void;
    onAddFilterKeyword: (keyword: string) => void;
    onRemoveFilterKeyword: (keyword: string) => void;
}

interface IState {
    sites: Array<IKeyValue<string>>;
}
export class EngineersFilters extends React.PureComponent<IProps, IState> {
    onToggleFilterExpanded: any;

    constructor(props: IProps) {
        super(props);
        this.state = {
            sites: []
        };
    }

    public render() {
        const commonProps = {
            expandedFilters: ['levels'],
            onFilter: this.props.onFilter,
            onToggleFilterExpanded: this.onToggleFilterExpanded,
        };
        return (
            <>
                <SearchFilter
                    title="Filters"
                    name="filterKeywords"
                    values={this.props.filters.filterKeywords}
                    onAddFilterKeyword={this.props.onAddFilterKeyword}
                    onRemoveFilterKeyword={this.props.onRemoveFilterKeyword}
                />
                <CheckboxFilter
                    {...commonProps}
                    title="Skill Levels" // Skill Levels
                    name="levels"
                    selectedFilters={this.props.filters.levels}
                    unreservedFilters={engineerSkillLevels}
                />
            </>
        );
    }
}
