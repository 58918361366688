import * as React from 'react';
import { Help, Control, Field, Radio } from '..';
import { IKeyValue } from 'models';
import { BulmaColor } from 'enums/BulmaColor';

interface IProps {
    id: string;
    label: string;
    value: number | string;
    options: Array<IKeyValue<string>>;
    name?: string;
    error?: string;
    canEdit?: boolean;
    handleChange: (field: any) => void;
    handleBlur?: (e: any) => void;
}

export const RadioField: React.FC<IProps> = (props) => {
    return (
        <Field>
            <label className="label">{props.label}</label>
            <Control>
                <Radio
                    id={props.id}
                    name={props.name}
                    value={props.value}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    options={props.options}
                    disabled={props.canEdit}
                />
            </Control>
            <Help bulmaColor={BulmaColor.Danger} isShown={true}>
                {props.error}
            </Help>
        </Field>
    );
};
