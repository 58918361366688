/* eslint-disable @typescript-eslint/no-inferrable-types */
import { ColumnDefParams, IColumnDefParams } from './column-def.params';

import {
  IRowSelectionParams,
  TableViewMode,
  IPaginationParams,
  IActionButtonParams,
} from './common.params';

/**
 * Represents the inputs for the EmeraldDataTableCore component.
 * @template T - The type of data in each row of the table.
 */
export class EmeraldDataTableCoreInputs<T>
  implements IEmeraldDataTableCoreInputs<T>
{
  /**
   * The unique identifier for the data table.
   */
  id?: string = `emerald-data-table-${Math.floor(Math.random() * 10000)}`;

  /**
   * The title of the data table.
   */
  tableTitle?: string;

  /**
   * The capition of the data table.
   */
  caption?: string;

  /**
   * The column definitions of the data table.
   */
  columnDefs: ColumnDefParams<T>[] = [];

  /**
   * The unique field for the data table. This is used for row selection, action buttons, row editing & cell editing, etc.
   */
  uniqueField?: string;

  /**
   * The data rows of the data table.
   */
  rowData: T[] = [];

  /**
   * Additional CSS classes for the data table.
   */
  cssClasses?: string;

  /**
   * The pagination parameters for the data table.
   */
  paginationParams?: IPaginationParams;

  /**
   * The parameters for row selection in the data table.
   */
  rowSelectionParams?: IRowSelectionParams;

  /**
   * whether to enable columns resize
   */
  allowResizeColumns?: boolean = false;

  /**
   * The table view mode.
   */
  viewMode?: TableViewMode = TableViewMode.HorizontalLines;

  /**
   * The row height in rem.
   * Default is 3.25 rem.
   */
  rowHeightInRem?: number = 3.25;

  /**
   * The parameters for action buttons in the data table.
   */
  actionButtonParams?: IActionButtonParams;

  /**
   * whether to show global columns menu
   */
  showColumnsMenu?: boolean = false;

  /**
   * whether to show filters
   */
  showColumnFilters?: boolean;

  /**
   * whether to enable inline editing
   */
  editable?: boolean = false;

  /**
   * whether to enable expandable rows
   */
  enableExpandableRows?: boolean = false;

  /**
   * The minimum height of an expandable row in pixels.
   */
  expandableRowMinHeight?: number = 200;
}

/**
 * Represents the inputs for the EmeraldDataTableCore component.
 * @template T - The type of data in each row of the table.
 */
export interface IEmeraldDataTableCoreInputs<T> {
  /**
   * The unique identifier for the data table.
   */
  id?: string;

  /**
   * The title of the data table.
   */
  tableTitle?: string;

  /**
   * The capition of the data table.
   */
  caption?: string;

  /**
   * The column definitions of the data table.
   */
  columnDefs: IColumnDefParams<T>[];

  /**
   * The unique field for the data table. This is used for row selection, action buttons, row editing & cell editing, etc.
   */
  uniqueField?: string;

  /**
   * The data rows of the data table.
   */
  rowData: T[];

  /**
   * Additional CSS classes for the data table.
   */
  cssClasses?: string;

  /**
   * The pagination parameters for the data table.
   */
  paginationParams?: IPaginationParams;

  /**
   * The parameters for row selection in the data table.
   */
  rowSelectionParams?: IRowSelectionParams;

  /**
   * whether to enable columns resize
   */
  allowResizeColumns?: boolean;

  /**
   * The table view mode.
   */
  viewMode?: TableViewMode;

  /**
   * The row height in rem.
   */
  rowHeightInRem?: number;

  /**
   * The parameters for action buttons in the data table.
   */
  actionButtonParams?: IActionButtonParams;

  /**
   * whether to show global columns menu
   */
  showColumnsMenu?: boolean;

  /**
   * whether to show filters
   */
  showColumnFilters?: boolean;

  /**
   * whether to enable inline editing
   */
  editable?: boolean;
}
