import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { CalendarActionsV2 } from 'actions/actions-v2/calendar-action-v2';
import { ICalendarItem } from 'models/calendar';

const controllerName = 'calendar';
const apiPath = `/api/${controllerName}`;
const redirectUrl = '/calendar';

const getActions = {
    action: CalendarActionsV2.LOAD_CALENDAR_EVENT,
    actionFulfilled: CalendarActionsV2.LOAD_CALENDAR_EVENT_FULFILLED,
    actionRejected: CalendarActionsV2.LOAD_CALENDAR_EVENT_REJECTED,
} as IActionType;

const createActions = {
    action: CalendarActionsV2.CREATE_CALENDAR_EVENTS,
    actionFulfilled: CalendarActionsV2.CREATE_CALENDAR_EVENTS_FULFILLED,
    actionRejected: CalendarActionsV2.CREATE_CALENDAR_EVENTS_REJECTED,
} as IActionType;

const updateActions = {
    action: CalendarActionsV2.UPDATE_CALENDAR_EVENTS,
    actionFulfilled: CalendarActionsV2.UPDATE_CALENDAR_EVENTS_FULFILLED,
    actionRejected: CalendarActionsV2.UPDATE_CALENDAR_EVENTS_REJECTED,
} as IActionType;

const deleteActions = {
    action: CalendarActionsV2.DELETE_CALENDAR_EVENTS,
    actionFulfilled: CalendarActionsV2.DELETE_CALENDAR_EVENTS_FULFILLED,
    actionRejected: CalendarActionsV2.UPDATE_CALENDAR_EVENTS_REJECTED,
} as IActionType;

const subscribeActions = {
    action: CalendarActionsV2.SUBSCRIBE_CALENDAR_EVENT,
    actionFulfilled: CalendarActionsV2.SUBSCRIBE_CALENDAR_EVENT_FULFILLED,
    actionRejected: CalendarActionsV2.SUBSCRIBE_CALENDAR_EVENT_REJECTED,
} as IActionType;

const loadCalendarEventEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getActions).getByIdAndShard<ICalendarItem>(apiPath);

const createCalendarEventEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createActions, redirectUrl).post<ICalendarItem>(apiPath);

const updateCalendarEventEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateActions, redirectUrl).put<ICalendarItem>(apiPath);

const deleteCalendarEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, deleteActions, redirectUrl).deleteByIdAndShard<string>(apiPath);

const subscribeCalendarEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, subscribeActions, redirectUrl).get<string>(`${apiPath}/SubscribeUrl`);

export const calendarEpicv2 = combineEpics(
    loadCalendarEventEpic,
    createCalendarEventEpic,
    updateCalendarEventEpic,
    deleteCalendarEpic,
    subscribeCalendarEpic
);
