import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { OperatingPlatformList } from './operating-platform-list-page';
import {
    deleteOperatingPlatform,
    loadOperatingPlatform,
    resetOperatingPlatformMenus,
    resetUpdatedOperatingPlatformMenusFlag,
    resetMenusForOperatingPlatform,
    resetIsDeletedOperatingPlatformFlag
} from 'actions/actions-v2/operating-platform-action';
import { loadSiteMetaData } from 'actions/actions-v2/site-metadata-actions-v2'

const getOperatingPlatformMenus = (state: IRootState) => state.operatingPlatformState.operatingPlatformMenus;
const getOperatingPlatforms = (state: IRootState) => state.operatingPlatformState.operatingPlatforms;
const getIsDeletedOperatingPlatform = (state: IRootState) => state.operatingPlatformState.isDeletedOperatingPlatform;
const getMenus = (state: IRootState) => state.menuState.menus;
const getSiteOperatingPlatform = (state: IRootState) => state.siteState.site.operatingPlatform;
const getPermissions = (state: IRootState) => state.app.permissions;

const mapStateToProps = createSelector(
    getOperatingPlatforms,
    getMenus,
    getOperatingPlatformMenus,
    getSiteOperatingPlatform,
    getPermissions,
    getIsDeletedOperatingPlatform,
    (operatingPlatforms, menus, operatingPlatformMenus, siteOperatingPlatform, permissions, isDeletedOperatingPlatform) => ({
        operatingPlatforms,
        menus,
        siteOperatingPlatform,
        operatingPlatformMenus,
        permissions,
        isDeletedOperatingPlatform
    })
);

const mapDispatchToProps = {
    loadOperatingPlatform,
    deleteOperatingPlatform,
    loadSiteMetaData,
    resetOperatingPlatformMenus,
    resetUpdatedOperatingPlatformMenusFlag,
    resetMenusForOperatingPlatform,
    resetIsDeletedOperatingPlatformFlag
};

export const operatingPlatformListPage = connect(mapStateToProps, mapDispatchToProps)(OperatingPlatformList);
