import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { QuestionSetsPage as View } from './question-sets-page';
import actions from './actions';
import selectors from './selectors';

export const QuestionSetsPage = withRouter(connect(
    selectors,
    actions
)(View));
