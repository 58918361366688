import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import OperatingPlatformSelectorDialog from './operating-platform-selector-dialog';
import {
    showOperatingPlatformSelectorDialog,
    updatePreferredOperatingPlatform,
} from 'actions/actions-v2/user-profile-action-v2';
import { loadPerson } from 'actions/actions-v2/user-profile-action-v2';

const getPersonProfile = (state: IRootState) => state.person.personProfile;
const getShowOperatingPlatformSelectorDialog = (state: IRootState) => state.person.showOperatingPlatformSelectorDialog;
const getIsLoading = (state: IRootState) => state.person.isLoading;

const mapStateToProps = createSelector(
    getPersonProfile,
    getShowOperatingPlatformSelectorDialog,
    getIsLoading,
    (personProfile, showOperatingPlatformSelectorDialog, isLoading) => ({
        personProfile,
        isShowOperatingPlatformSelectorDialog: showOperatingPlatformSelectorDialog,
        isLoading,
    })
);

const mapDispatchToProps = {
    loadPerson,
    showOperatingPlatformSelectorDialog,
    updatePreferredOperatingPlatform,

};

export const OperatingPlatformSelectorDialogContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(OperatingPlatformSelectorDialog)
);
