import { AssetActions } from 'actions/asset-action';
import { IAppAction } from 'actions/app-action';
import { IAssetManufacturer, IModelDetails } from 'models/asset/asset-model';
import { combineReducers } from 'redux';

export interface IAssetState {
    assetManufacturers: IAssetManufacturer[],
    assetModels: IModelDetails[],
}

const INITIAL_STATE: IAssetState = {
    assetManufacturers: [],
    assetModels: [],
};

const assetManufacturers = (
    state: IAssetState['assetManufacturers'] = INITIAL_STATE.assetManufacturers,
    action: IAppAction
) => {
    switch (action.type) {
        case AssetActions.LOAD_ASSET_MANUFACTURER_FULFILLED:
            return action.payload;
        default:
            return state;
    }
};

const assetModels = (
    state: IAssetState['assetModels'] = INITIAL_STATE.assetModels,
    action: IAppAction
) => {
    switch (action.type) {
        case AssetActions.LOAD_ASSET_MODEL_FULFILLED:
            return action.payload;
        default:
            return state;
    }
};

export const assetReducer = combineReducers<IAssetState>({
    assetManufacturers,
    assetModels
});