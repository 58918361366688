import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { RomonetActions } from 'actions/actions-v2/romonet-actions';

const romonetControllerName = 'RomonetReport';
const apiPath = `/api/${romonetControllerName}`;

const DownloadReportsAction = {
    action: RomonetActions.DOWNLOAD_REPORTS,
    actionFulfilled: RomonetActions.DOWNLOAD_REPORTS_FULFILLED,
    actionRejected: RomonetActions.DOWNLOAD_REPORTS_REJECTED,
} as IActionType;

const DownloadPPTAction = {
    action: RomonetActions.DOWNLOAD_PPT,
    actionFulfilled: RomonetActions.DOWNLOAD_PPT_FULFILLED,
    actionRejected: RomonetActions.DOWNLOAD_PPT_REJECTED,
} as IActionType;

const LoadReportAction = {
    action: RomonetActions.LOAD_REPORT,
    actionFulfilled: RomonetActions.LOAD_REPORT_FULFILLED,
    actionRejected: RomonetActions.LOAD_REPORT_REJECTED,
} as IActionType;

const LoadDateFiltersAction = {
    action: RomonetActions.LOAD_DATE_FILTER,
    actionFulfilled: RomonetActions.LOAD_DATE_FILTER_FULFILLED,
    actionRejected: RomonetActions.LOAD_DATE_FILTER_REJECTED,
} as IActionType;

const LoadReportsNameAction = {
    action: RomonetActions.LOAD_DETAILS_HALL_NAME,
    actionFulfilled: RomonetActions.LOAD_DETAILS_HALL_NAME_FULFILLED,
    actionRejected: RomonetActions.LOAD_DETAILS_HALL_NAME_REJECTED,
} as IActionType;

const downloadReportsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, DownloadReportsAction).exportByQueryModel(`${apiPath}/Download`);

const loadReportEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, LoadReportAction).exportByQueryModel(`${apiPath}/GetReport`);

const loadDateFilterEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, LoadDateFiltersAction).get(`${apiPath}`);

const downloadPPTEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, DownloadPPTAction).exportByQueryModel(`${apiPath}/DownloadPPT`);

const loadReportsNameEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, LoadReportsNameAction).exportByQueryModel(`${apiPath}`);

export const romonetEpics = combineEpics(
    downloadReportsEpic,
    loadReportEpic,
    loadDateFilterEpic,
    downloadPPTEpic,
    loadReportsNameEpic
);
