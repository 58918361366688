import { SiteRelationshipsActions, SiteRelationshipsActionTypes } from 'actions/site-relationships-actions';
import { IClient } from 'models/client';
import { ISiteProfile } from 'models/site-profile';

export default {
    onClose: (site: ISiteProfile, client: IClient): SiteRelationshipsActionTypes => ({
        type: SiteRelationshipsActions.CHANGE_CLIENT_CANCELLED,
        payload: {
            site,
            client
        }
    }),
    onConfirm: (site: ISiteProfile, client: IClient): SiteRelationshipsActionTypes => ({
        type: SiteRelationshipsActions.CHANGE_CLIENT_CONFIRMED,
        payload: {
            site,
            client
        }
    })
};
