import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { loadHistory } from 'actions/actions-v2/mock-drill-report-actions';
import { MockDrillReportHistoryTab } from './mock-drill-report-history-tab';

const getIsLoading = (state: IRootState) => state.mockDrillReportState.isLoading;
const getHistory = (state: IRootState) => state.mockDrillReportState.history;
const getMockDrillReport = (state: IRootState) => state.mockDrillReportState.mockDrillReport;

const mapStateToProps = createSelector(
    getIsLoading,
    getMockDrillReport,
    getHistory,
    (isLoading, mockDrillReport, mockDrillReportHistory) => ({
        isLoading,
        mockDrillReport,
        mockDrillReportHistory
    })
);

const mapDispatchToProps = {
    loadHistory
};

export const MockDrillReportHistoryTabContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MockDrillReportHistoryTab);
