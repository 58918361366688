export const scenarioTypeTitle = (scenarioType: string) => {
    switch (scenarioType) {
        case 'ToolboxTalks':
            return 'Toolbox Talks';
        case 'LessonsLearned':
            return 'Lessons Learned';
        case 'MockDrill':
        default:
            return 'Mock Drill';
    }
};

export const scenarioTypeLabel = (scenarioType: string) => {
    switch (scenarioType) {
        case 'ToolboxTalks':
            return 'This Toolbox Talk session involves*';
        case 'LessonsLearned':
            return `This Lesson's Learned session involves*`;
        case 'MockDrill':
        default:
            return 'EOP/s this drill or session involves*';
    }
};

export const scenarioTypePlaceholder = (scenarioType: string) => {
    switch (scenarioType) {
        case 'ToolboxTalks':
            return `Enter the Toolbox Talk that this session involves`;
        case 'LessonsLearned':
            return `Enter the Lesson's learned that this session involves`;
        case 'MockDrill':
        default:
            return 'Enter the EOP titles such as; "EOP #123" and separate them with a command ","…';
    }
};
