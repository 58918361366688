import * as React from 'react';
import { IEngineerFilters, IKeyValue, ISkill, IObsoleteSkillCategory } from 'models';
import { MobileModalToggle } from 'components/mobileModalToggle';
import { Loader } from 'components/loader';
import { EngineerFilters } from 'components/engineer-filters/engineer-filters';
import _ from 'lodash';
import { MasterEngineerSkills } from 'components/engineer/master-engineer-skills';
import { MasterEngineerSkillsGridToolbarContainer } from 'components/master-engineer-skills-grid-toolbar/master-engineer-skills-grid-toolbar-container';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';

export interface IManageEngineerSkillsProps {
    engineerSkills: ISkill[];
    isLoading: boolean;
    lookupLevels: Array<IKeyValue<string>>;
    lookupCategories: Array<IKeyValue<string>>;
    lookupSkillStatuses: Array<IKeyValue<string>>;
    onCloseFilterModal(): void;
    loadEngineerSkills(): void;
    onSort: (key: string, sortAscending: boolean) => void;
    onToggleCategory: (id: string) => void;
    onChangeStatus: (skill: ISkill) => void;
    isExportingEngineerSkills: boolean;
    expandedCategories: string[];
    skillCategories: IObsoleteSkillCategory[];
    intl: any;
}

export interface IManageEngineerSkillsState {
    isFilterModalOpen: boolean;
    filters: IEngineerFilters;
    sortColumn: string;
    sortAscending: boolean;
    user: IKeyValue<string> | null;
}

class ManageEngineerSkillsClass extends React.Component<IManageEngineerSkillsProps, IManageEngineerSkillsState> {

    constructor(props: IManageEngineerSkillsProps) {
        super(props);
        this.state = {
            isFilterModalOpen: false,
            sortColumn: 'LastName',
            sortAscending: false,
            user: null, // {key: '', value: '', label: ''},
            filters: {
                levels: [],
                categories: [],
                statuses: [],
                filterKeywords: [],
            }
        };
    }

    public componentDidMount() {
        this.props.loadEngineerSkills();
    }

    public render() {
        const props = this.props;
        const { isFilterModalOpen } = this.state;
        const intl = props.intl;

        return (
            <div className="section">
                <div className="container">
                    <div className="columns">
                        <MobileModalToggle className="column is-3 filters-container" visible={isFilterModalOpen} onClose={props.onCloseFilterModal}>
                            <div className="columns is-mobile is-multiline">
                                <EngineerFilters
                                    onFilter={this.onFilter}
                                    filters={this.state.filters}
                                    onAddFilterKeyword={this.onAddFilterKeyword}
                                    onRemoveFilterKeyword={this.onRemoveFilterKeyword}
                                    showStatusFilter={false}
                                    skillCategories={this.props.skillCategories}
                                // setSiteFilter={this.setSiteFilters}
                                />
                            </div>
                        </MobileModalToggle>
                        <div className="column is-9">
                            <div className="columns">
                                <div className="column is-8"><h4 className="title is-uppercase">{translate(intl, 'ManageRole.Index.ManageEngineerSkills')}</h4></div>
                            </div>
                            <div className="columns is-multiline is-mobile">
                                <div className="column is-12">
                                    <MasterEngineerSkillsGridToolbarContainer
                                        onClearFilters={this.onClearFilters}
                                    // isExportingEngineers={this.props.isExportingEngineers}
                                    // items={this.props.engineer}
                                    />
                                </div>
                                {this.props.engineerSkills ?
                                    <div className="column is-12">
                                        <Loader loading={props.isLoading}>
                                            <MasterEngineerSkills
                                                engineerSkills={this.props.engineerSkills}
                                                isLoading={this.props.isLoading}
                                                expandedCategories={this.props.expandedCategories}
                                                onToggleCategory={this.props.onToggleCategory}
                                                filters={this.state.filters}
                                                save={this.save}
                                                skillCategories={this.props.skillCategories}
                                            // onChangeStatus={this.props.onChangeStatus}
                                            />
                                        </Loader>
                                    </div> : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private save = () => {
        this.setState({ sortAscending: true });
    }

    private onAddFilterKeyword = (keyword: string) => {
        const filters = { ...this.state.filters };
        const keywords = [keyword, ...filters.filterKeywords.slice(0, filters.filterKeywords.length)];
        filters.filterKeywords = keywords;
        this.setState({ filters });
        // this.props.loadEngineers(filters);
    }

    private onClearFilters = () => {
        const filters = {
            levels: [],
            categories: [],
            // statuses: [],
            filterKeywords: []
        } as IEngineerFilters;
        this.setState({ filters });
        // this.props.loadEngineers(filters);
    }

    private onRemoveFilterKeyword = (keyword: string) => {
        const filters = { ...this.state.filters };
        const index = filters.filterKeywords.indexOf(keyword);
        const keywords = [...filters.filterKeywords.slice(0, index), ...filters.filterKeywords.slice(index + 1)];
        filters.filterKeywords = keywords;
        this.setState({ filters });
        // this.props.loadEngineers(filters);
    }

    private onFilter = (key: Extract<keyof IEngineerFilters, string>, element: string) => {
        const filters = { ...this.state.filters };
        const index = filters[key].indexOf(element);
        if (index !== -1) {
            const items = [...filters[key].slice(0, index), ...filters[key].slice(index + 1)];
            filters[key] = items;
        } else {
            const items = [element, ...filters[key].slice(0, filters[key].length)];
            filters[key] = items;
        }
        this.setState({ filters });
        // this.props.loadEngineers(filters);
    }
}

export const ManageEngineerSkills = injectIntl(ManageEngineerSkillsClass);
