import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Loader } from 'components/loader';
import { formatDateString } from 'utils/date-utils';
import { IDashboardComponentProps } from 'models/dashboard/IDashboardComponentProps';
import { IDashboardCermOverviewComponent } from 'models/dashboard/IDashboardCermOverviewComponent';
import DashboardComponent, { loadComponentEffect } from '../DashboardComponent';
import selectors from './selectors';
import actions from './actions';
import moment from 'moment';
import { GetPercentage } from 'utils/decimal-utils';
import { checkPermission } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import useTranslate from 'translations/translation-utils';

const DashboardCermOverviewComponent: React.FC<
    IDashboardComponentProps<IDashboardCermOverviewComponent>
> = ({ siteId, isLoading, component, hasPermission, permissions, loadComponent, className,prefix }) => {
    const translate = useTranslate();

    const currentSiteId = React.useRef<string>(null);

    loadComponentEffect(hasPermission, currentSiteId, siteId, loadComponent);

    const getFooter = () => {
        if (!component || !component.lastUpdated || moment(component.lastUpdated).year() < 2) {
            return null;
        }

        return (
            <>
                <h4>{translate('Cerm.ComparisonPanel.Labels.LastUpdated')}</h4>
                <p>
                    {formatDateString(component.lastUpdated)}
                    {component.lastUpdatedByPersonName
                        ? ` ${translate('Cerm.SiteScore.Labels.By')} ${
                              component.lastUpdatedByPersonName
                          }`
                        : null}
                </p>
            </>
        );
    };

    const getCompletionPercentage = component
        ? `${GetPercentage(component.completionPercentage)} ${translate(
              'Dashboard.CermOverview.Completed'
          )}`
        : 'N/A';

    const getCompliantPercentage = component
        ? `${GetPercentage(component.compliancePercentage)} ${translate(
              'Dashboard.CermOverview.Compliant'
          )}`
        : 'N/A';

    const getScorePercentage = component
        ? `${GetPercentage(component.scorePercentage)} ${translate(
              'Dashboard.CermOverview.ScoreSmall'
          )}`
        : 'N/A';

    return (
        <DashboardComponent
            title={translate('Dashboard.CermOverview.Title').replace("CERM",prefix)}
            intro={translate('Dashboard.CalandarEvents.Intro')}
            className={`column-cerm ${className}`}
            footer={getFooter()}
            hasPermission={hasPermission}
            headerButton={
                <div className="has-text-centered">
                    <NavLink
                        to="/CermGlobalOverview"
                        className="button is-primary is-fullwidth is-medium"
                    >
                        {translate('Dashboard.CermOverview.ViewLink').replace("CERM",prefix)}
                    </NavLink>
                </div>
            }
        >
            <Loader loading={isLoading}>
                {!isLoading && (
                    <div className="component-content">
                        <h3>{translate('Dashboard.CermOverview.Score')}</h3>
                        <div className="counter">
                            <mark className="completed">{getCompletionPercentage}</mark>
                            <mark className="compliant">{getCompliantPercentage}</mark>
                            <mark className="score">{getScorePercentage}</mark>
                        </div>
                        {checkPermission(Privilege.GlobalOverview, permissions) && (
                            <>
                                <h3>{translate('Dashboard.CermOverview.Breakdown')}</h3>
                                <div>
                                    <NavLink to="/CermGlobalOverview" className="button">
                                        {translate('Dashboard.CermOverview.BreakdownButton1')}
                                    </NavLink>
                                    <NavLink to="/CermGlobalOverview" className="button">
                                        {translate('Dashboard.CermOverview.BreakdownButton2')}
                                    </NavLink>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </Loader>
        </DashboardComponent>
    );
};

export default connect(selectors, actions)(DashboardCermOverviewComponent);
