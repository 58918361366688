import {
    loadRisk,
    createRisk,
    createRiskMitigationPlan,
    acceptRisk,
    updateRisk,
    removeRisk,
    closeRisk,
    openClosedRisk,
    clearForm,
    cbreApprovalRisk,
    resetRisk,
} from 'actions/actions-v2/risk-action-v2';

import { loadPerson } from 'actions/actions-v2/user-profile-action-v2';
import {
    loadPersonsBySiteAndPrivilege,
    loadPersonsLookup,
} from 'actions/actions-v2/person-action-v2';
import { loadCermAssessmentExpertReview } from 'actions/actions-v2/cerm-assessment-action-v3';
import { loadAttachmentsRisks } from 'actions/actions-v2/attachment-action-v2';
import {
    createMacroRisk,
    loadMacroRisk,
    removeMacroRisk,
    updateMacroRisk,
} from 'actions/actions-v2/macro-risk-action-v2';

export default {
    loadRisk,
    createRisk,
    createRiskMitigationPlan,
    acceptRisk,
    updateRisk,
    removeRisk,
    closeRisk,
    openClosedRisk,
    clearForm,
    loadPersonsLookup,
    loadPersonProfile: loadPerson,
    loadPersonsBySiteAndPrivilege,
    loadCermAssessmentExpertReview,
    cbreApprovalRisk,
    loadAttachments: loadAttachmentsRisks,
    loadMacroRisk,
    createMacroRisk,
    updateMacroRisk,
    removeMacroRisk,
    resetRisk
};
