import { Control, Field, Radio } from 'components/form';
import { NumberField } from 'components/form/fields/number-field';
import { SelectField } from 'components/form/fields/select-field';
import { FormikProps } from 'formik';
import { IKeyValue } from 'models';
import { IRisk, IRiskMitigationPlan, RiskMitigationPlanStatus } from "models/risk"
import React, { useEffect, useState } from 'react';
import _, { isNil } from 'lodash';
import { yesNoOptions } from 'routes/create-question-set/views/common';
import { UiPreferences, usePageUIPreferences } from 'utils/page-ui-preferences-utils';
import { Panel } from 'components/v2/components';
import RiskMitigationDocuments from '../documents/risk-mitigation-documents-tab';
import * as RiskStatus from 'models/risk-statuses';
import RiskActions from '../actions/risk-actions-tab';
import ClientRiskMitigationDocuments from '../../../../clientView/client-risk-register/tabs/client-risk-mitigation-documents-tab';
import { getKeyed } from 'utils/form-utils';
import { formatDate } from 'utils/date-utils';
import { DatePickerField } from 'components/form/fields/date-picker-field';
import useTranslate from 'translations/translation-utils';

interface IProps {
    formikBag: FormikProps<IRisk>;
    mitigationPlan: IRiskMitigationPlan;
    isReadOnly: boolean;
    likelihoods: IKeyValue<string>[];
    impacts: IKeyValue<string>[];
    currencies: IKeyValue<string>[];
    isMitigationValid?: boolean;
    isActionEditable: boolean;
    isMacro: boolean;
    canEditInExpertReview?: () => boolean;
    setIsMitigationValid?: (value: boolean) => void;
    setIsActionEditable: (value: boolean) => void;
}

export const MitigationPlanForm: React.FC<IProps> = ({
    mitigationPlan,
    formikBag,
    isMitigationValid,
    isActionEditable,
    isMacro,
    setIsMitigationValid,
    setIsActionEditable,
    ...props
}) => {
    const mitigationIndex = formikBag.values.mitigationPlans.findIndex(
        x => x.id === mitigationPlan.id
    );
    const prefix = `mitigationPlans[${mitigationIndex}]`;
    const field = (name: Extract<keyof IRiskMitigationPlan, string>) => `${prefix}.${name}`;
    const riskField = (name: Extract<keyof IRisk, string>) => `${name}`;
    const [costErrorMsg, setCostErrorMsg] = useState('');
    const [mitigationPlanCount, setMitigationPlanCount] = useState(
        formikBag.values.mitigationPlans.length
    );

    useEffect(() => {
        setMitigationPlanCount(formikBag.values.mitigationPlans.length);
    }, [formikBag.values.mitigationPlans.length]);

    function value<T extends Extract<keyof IRiskMitigationPlan, string>>(name: T) {
        const val = getKeyed(formikBag, 'values', field(name));
        return !isNil(val) ? val : '';
    }

    function riskValue<T extends Extract<keyof IRisk, string>>(name: T) {
        const val = getKeyed(formikBag, 'values', riskField(name));
        return !isNil(val) ? val : '';
    }

    const [expandedItems, setExpandedItems] = usePageUIPreferences<string[]>(
        UiPreferences.CermAssessmentPageCollapsedPillars,
        []
    );

    const translate = useTranslate();

    const handleLikelihoodChange = (likelihood: { value: string; label: string }) => {
        formikBag.setFieldValue(field('riskLikelihoodId'), likelihood.value);
    };

    const handleImpactChange = (riskImpact: { value: string; label: string }) => {
        formikBag.setFieldValue(field('riskImpactId'), riskImpact.value);
    };

    React.useEffect(() => {
        if (!setIsMitigationValid) {
            return;
        }

        const cost = value('estimatedCost');
        const riskCost = riskValue('cost');

        validateCost(cost, riskCost);
    }, [formikBag.values.cost]);

    const handleCostChange = (event: any) => {
        if (!setIsMitigationValid) {
            return;
        }

        const cost = parseFloat(event.target.value);

        if (isNaN(cost)) {
            setIsMitigationValid(false);
            setCostErrorMsg(translate('RiskRegister.Upsert.Tabs.MitigationPlanForm'));
            return;
        }

        const riskCost = riskValue('cost');
        validateCost(cost, riskCost);

        formikBag.setFieldValue(field('estimatedCost'), cost);
    }

    const validateCost = (cost: any, riskCost: any) => {
        if (!setIsMitigationValid) {
            return;
        }

        const currency = riskCost.currencyIsoCode;

        if (cost) {
            if (currency) {
                if (cost > riskCost?.amount) {
                    setIsMitigationValid(false);
                    setCostErrorMsg(translate('RiskRegister.Upsert.Tabs.MitigationPlanForm.Message2'));
                } else {
                    setCostErrorMsg('');
                    setIsMitigationValid(true);
                }
            } else {
                setIsMitigationValid(false);
                setCostErrorMsg(translate('RiskRegister.Upsert.Tabs.MitigationPlanForm.Message3'));
            }
        } else {
            setCostErrorMsg('');
            setIsMitigationValid(true);
        }
    };

    const handleIsVisibleToClient = (event: any) => {
        const result = event.currentTarget.value === 'true';
        formikBag.setFieldValue(field('visibleToClient'), result);
    };

    const onPanelCollapsed = (item: string, collapsed: boolean) => {
        const items = !collapsed
            ? [...expandedItems, item]
            : expandedItems.filter((p) => p !== item);

        setExpandedItems(items);
    };

    const isRiskActiveAndNotClosed = (): boolean => {
        return formikBag.values?.isActive && formikBag.values?.riskStatusId !== RiskStatus.CLOSED;
    };

    const getTitle = (): string => {
        const result = translate('RiskRegister.Upsert.Tabs.MitigationPlanForm.MitigationPlan');
        const createdDate = value('createdDate');

        if (createdDate) {
            return result + ' - ' + createdDate?.toDateString();
        }

        return result;
    };

    const isReadOnly = () => {
        return value('status') === RiskMitigationPlanStatus.Completed || props.isReadOnly;
    };

    const getMitigationPlanRiskLikelihood = () => {
        const planLevel = props.likelihoods.find(
            option => option.key === value('riskLikelihoodId')
        );

        return planLevel
            ? {
                label: translate('RiskDetailsPage.Likelihood.dropdown.', planLevel.value),
                value: planLevel.value,
            }
            : {
                label: translate('RiskDetailsPage.Likelihood.dropdown.', riskValue('likelihoodId')),
                value: riskValue('likelihoodId'),
            };
    };

    const getMitigationPlanRiskImpact = () => {
        const planLevel = props.impacts.find(option => option.key === value('riskImpactId'));

        return planLevel
            ? {
                label: translate('RiskDetailsPage.Impact.dropdown.', planLevel.value),
                value: planLevel.value,
            }
            : {
                label: translate('RiskDetailsPage.Impact.dropdown.', riskValue('impactId')),
                value: riskValue('impactId'),
            };
    };

    const isInitCollapsed = (planCount: number): boolean => {
        return (
            planCount === formikBag.values.mitigationPlans.length &&
            value('status') === RiskMitigationPlanStatus.Completed
        );
    };

    return (
        <Panel
            title={getTitle()}
            collapsed={isInitCollapsed(mitigationPlanCount)}
            onCollapsed={collapsed => onPanelCollapsed(mitigationPlan.id, collapsed)}
            className="component-pillar"
        >
            <div className="section">
                <div className="columns">
                    <div className="column is-2">
                        <DatePickerField
                            id={'createdDate'}
                            label={translate('IAuditorComplianceChecklistPage.table.CreatedDate')}
                            isDisabled={true}
                            value={
                                !!mitigationPlan.createdDate
                                    ? formatDate(mitigationPlan.createdDate)
                                    : ''
                            }
                        />
                    </div>

                    <div className="column is-2">
                        <DatePickerField
                            id={'dueDate'}
                            label={translate('RiskActionTab.Label.DueDate')}
                            isDisabled={true}
                            value={
                                !!mitigationPlan.dueDate ? formatDate(mitigationPlan.dueDate) : ''
                            }
                        />
                    </div>

                    <div className="column is-2">
                        <SelectField
                            id={'riskLikelihoodId'}
                            label={translate('RiskDetailsPage.MitigationPlan.Label.Likelihood')}
                            error={null}
                            value={getMitigationPlanRiskLikelihood()}
                            options={props.likelihoods.map(c => ({
                                value: c.key,
                                label: translate('RiskDetailsPage.Likelihood.dropdown.', c.value),
                            }))}
                            handleChange={handleLikelihoodChange}
                            handleBlur={formikBag.handleBlur}
                            isDisabled={isReadOnly()}
                        />
                    </div>

                    <div className="column is-2">
                        <SelectField
                            id={'riskImpactId'}
                            label={translate('RiskDetailsPage.MitigationPlan.Label.Impact')}
                            error={null}
                            value={getMitigationPlanRiskImpact()}
                            options={props.impacts.map(c => ({
                                value: c.key,
                                label: translate('RiskDetailsPage.Impact.dropdown.', c.value),
                            }))}
                            handleChange={handleImpactChange}
                            handleBlur={formikBag.handleBlur}
                            isDisabled={isReadOnly()}
                        />
                    </div>

                    <div className="column is-2">
                        <NumberField
                            id="estimatedCost"
                            label={translate('RiskDetailsPage.MitigationPlan.Label.EstimatedTotalCostToRemedy')}
                            error={costErrorMsg}
                            value={value('estimatedCost')}
                            allowNegative={false}
                            thousandSeparator={false}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            handleChange={handleCostChange}
                            handleBlur={formikBag.handleBlur}
                            isDisabled={isReadOnly()}
                        />
                    </div>
                    <div className="column is-3">
                        {riskValue('sharedWithClient') === true && (
                            <Field>
                                <span style={{ padding: '14.125px' }}>
                                    {translate('RiskDetailsPage.MitigationPlan.Label.VisibleToClient')}
                                </span>

                                <Control className="columns">
                                    <div style={{ padding: '12.125px' }}>
                                        <Radio
                                            id="isVisibleToClient"
                                            name="isVisibleToClient"
                                            options={yesNoOptions}
                                            value={value('visibleToClient') ? 'true' : 'false'}
                                            onChange={handleIsVisibleToClient}
                                            onBlur={handleIsVisibleToClient}
                                            disabled={value('status') == 'Completed'}
                                        />
                                    </div>
                                </Control>
                            </Field>
                        )}
                    </div>
                </div>

                <div className="subtitle">{translate('RiskRegisterUpsertPage.Tabs.Actions')}</div>

                <RiskActions
                    riskId={formikBag.values?.id}
                    riskMitigationPlanId={value('id')}
                    canEditInExpertReview={props.canEditInExpertReview}
                    isReadOnly={
                        isReadOnly() ||
                        !isRiskActiveAndNotClosed() ||
                        (props.canEditInExpertReview && !props.canEditInExpertReview()) ||
                        value('status') === RiskMitigationPlanStatus.Completed
                    }
                    isActionEditable={isActionEditable}
                    setIsActionEditable={setIsActionEditable}
                    isRiskEdited={formikBag.dirty}
                    isMacro={isMacro}
                />

                {isRiskActiveAndNotClosed() && (
                    <>
                        <div className="subtitle">
                            {translate('RiskRegisterUpsertPage.Tabs.Documents')}
                        </div>

                        <RiskMitigationDocuments
                            id={value('id')}
                            isReadOnly={
                                isReadOnly() ||
                                !isRiskActiveAndNotClosed() ||
                                (props.canEditInExpertReview && !props.canEditInExpertReview()) ||
                                value('status') === RiskMitigationPlanStatus.Completed
                            }
                        />
                    </>
                )}

                {isRiskActiveAndNotClosed() === false && (
                    <ClientRiskMitigationDocuments id={value('id')} />
                )}
            </div>
        </Panel>
    );
};
