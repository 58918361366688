import * as React from 'react';
import Page from 'components/v2/page/Page';
import { RouteComponentProps } from 'react-router';
import { Formik, FormikProps } from 'formik';
import { history } from 'routes/App';
import {
    TabGroup,
    TabItem,
    ConfirmDialog,
    Button,
    Toolbar,
    ToolbarGroup,
    ToolbarGroupRight,
    Alert,
    ButtonWithConfirmDialog,
} from 'components/v2/components';
import { isEmpty } from 'lodash';
import { IKeyValue } from 'models';
import { scrollToFirstValidationError } from 'utils/form-utils';
import { onRouteChange } from 'actions/app-actions';
import {
    IShiftHandover,
    IShiftHandoverCategories,
    IShiftHandoverSettings,
    ShiftHandoverStatus,
} from 'models/shifts/shift-handover';
import { ShiftHandoverDetailsTabContainer } from './tabs/details/shift-handover-details-tab-container';
import { ShiftHandoverDocumentsTabContainer } from './tabs/documents/shift-handover-documents-tab-container';
import { ShiftHandoverHistoryTabContainer } from './tabs/history/shift-handover-history-tab-container';
import { shiftHandoverDetailsFormValidationSchema } from './tabs/details/shift-handover-details-tab';
import { IShiftChecklist } from 'models/shifts/master-shift-checklist';
import { buildNewShiftHandoverUrl } from '../shared/ShiftHandoverUtils';
import { ShiftHandoverDetailsReadOnlyTab } from './tabs/details/shift-handover-details-readonly-tab';
import { ShiftHandlerPageToolbar } from '../shared/shift-handler-page-toolbar';
import useTranslate from 'translations/translation-utils';
import { convertStringToDate } from 'utils/date-utils';
import { getUserId } from 'helpers/helpers';
import { checkPermissions } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import { IShiftEngineer } from 'models/shift';

interface IParams {
    id?: string;
    tab: string;
    siteId?: string;
    date?: string;
    facilityId?: string;
}

interface IProps extends RouteComponentProps<IParams> {
    siteId: string;
    isLoading: boolean;
    isExporting: boolean;
    isShiftHandoverCreated: boolean;
    shiftHandover: IShiftHandover;
    shiftChecklist: IShiftChecklist[];
    hasReadWritePermission: boolean;
    shiftHandoverSettings: IShiftHandoverSettings;
    permissions: Array<IKeyValue<string>>;
    shiftHandoverItemCategories?: IShiftHandoverCategories;
    loadShiftHandover: (id: string, siteId: string) => void;
    createShiftHandover: (model: Partial<IShiftHandover>) => void;
    updateShiftHandover: (model: Partial<IShiftHandover>) => void;
    deleteShiftHandover: (id: string, siteId: string) => void;
    loadShiftChecklistVersion: (siteId: string, shiftHandoverId: string) => void;
    clearForm: () => void;
    loadSettings: (siteId: string) => void;
    acknowledgeShiftHandover: (id: string, siteId: string) => void;
    loadLastSubmittedShiftHandover: (facilityId: string, siteId: string) => void;
    exportShiftHandovers: (id: string, siteId: string) => void;
    loadShiftHandoverCategory: (siteId) => void;
    shiftEngineers: IShiftEngineer[];
    loadEngineers: (siteId: string) => void;
    priorities: Array<IKeyValue<string>>;
}

enum Tab {
    details = 'details',
    documents = 'documents',
    history = 'history',
}

export const ShiftHandoverUpsertPage: React.FC<IProps> = ({
    match,
    siteId,
    isLoading,
    isExporting,
    shiftChecklist,
    permissions,
    isShiftHandoverCreated,
    shiftHandover,
    shiftHandoverSettings,
    shiftHandoverItemCategories,
    loadShiftHandover,
    createShiftHandover,
    updateShiftHandover,
    deleteShiftHandover,
    loadShiftChecklistVersion,
    loadSettings,
    acknowledgeShiftHandover,
    loadLastSubmittedShiftHandover,
    clearForm,
    exportShiftHandovers,
    loadShiftHandoverCategory,
    loadEngineers,
    shiftEngineers,
    priorities,
}) => {
    const translate = useTranslate();
    const currentTab = match.params.tab.toLowerCase();
    const [isValid, setIsValid] = React.useState(false);
    const [isFormDirty, setIsFormDirty] = React.useState(false);
    const [showTabDisabledDialog, setShowTabDisabledDialog] = React.useState(false);
    const [shouldBlockNavigation, setShouldBlockNavigation] = React.useState(false);
    const [selectedTab, setSelectedTab] = React.useState(currentTab);
    const shiftHandoverId = match.params.id;
    const dateShiftHandover = match.params.date;
    const facilityId = match.params.facilityId;
    const shiftHandoversPageUrl = '/ShiftHandovers';
    const customLinks: Array<IKeyValue<string>> = [
        { key: shiftHandoversPageUrl, value: translate('ShiftHandoversPage.Title') },
    ];
    let formikBag: FormikProps<IShiftHandover>;
    const hasReadWritePermission = checkPermissions([Privilege.ShiftHandover], permissions)
    const hasReadonlyPermission = checkPermissions([Privilege.ShiftHandoverRead], permissions)
    const isReadOnly =
        shiftHandover &&
        (shiftHandover.status === ShiftHandoverStatus.Awaiting ||
            shiftHandover.status === ShiftHandoverStatus.Acknowledged || (hasReadonlyPermission && !hasReadWritePermission))

    React.useEffect(() => {
        return () => {
            clearForm();
        };
    }, [match.params.id]);

    React.useEffect(() => {
        if (siteId && shiftHandover.id) {
            loadShiftChecklistVersion(siteId, shiftHandover.id);
        }
        if (siteId) {
            loadShiftHandoverCategory(siteId)
        }
    }, [siteId, shiftHandover.checkListItemsVersion]);

    React.useEffect(() => {
        if (!siteId) {
            return;
        }

        if (!shiftHandoverId) {
            loadLastSubmittedShiftHandover(facilityId, siteId);
            loadShiftChecklistVersion(siteId, shiftHandover.id);
        }

        if (isEmpty(shiftHandoverSettings.facilities) || shiftHandoverSettings.siteId !== siteId) {
            loadSettings(siteId);
        }
    }, [siteId, shiftHandoverId]);

    React.useEffect(() => {
        if (shiftHandoverId || !shiftHandoverSettings) {
            return;
        }

        if (dateShiftHandover) {
            const date = convertStringToDate(dateShiftHandover)
            formikBag.setFieldValue('dateOfShiftHandover', date);
        }

        if (facilityId) {
            formikBag.setFieldValue('facilityId', facilityId);
            if (!isEmpty(shiftHandoverSettings.facilities)) {
                const facility = shiftHandoverSettings.facilities.find((f) => f.id === facilityId);
                if (facility) {
                    formikBag.setFieldValue('facilityName', facility.name);
                }
            }
        }

        if (match.params.siteId) {
            formikBag.setFieldValue('siteId', match.params.siteId);
        }
    }, [match.params.date, facilityId, match.params.siteId, shiftHandover, shiftHandoverSettings]);

    React.useEffect(() => {
        if (siteId && shiftHandoverId) {
            loadShiftHandover(shiftHandoverId, siteId);
        }
    }, [siteId, shiftHandoverId]);

    const setNavLink = (tab: Tab) => {
        if (shiftHandoverId) {
            return match.path
                .replace(':id?', shiftHandoverId)
                .replace(':tab', tab)
                .replace(':siteId', siteId)
                .replace('/:date?/:facilityId?', '');
        }

        return match.path
            .replace(':id?/', '')
            .replace(':siteId', siteId)
            .replace(':tab', tab)
            .replace(':date?', dateShiftHandover)
            .replace(':facilityId?', facilityId);
    };

    const onSubmit = (value: Partial<IShiftHandover>) => {
        setShouldBlockNavigation(false);
        setIsFormDirty(false);
        if (shiftHandoverId) {
            const dataToUpdate = {
                ...value,
            };
            updateShiftHandover(dataToUpdate);
        } else {
            createShiftHandover({
                ...value,
                //  checkListItemsVersion: shiftChecklist[0].id,
                siteId,
            });
        }
    };

    const handleClick = (event: any, tab: Tab) => {
        event.preventDefault();
        if (!isShiftHandoverIdAvailable()) {
            setShowTabDisabledDialog(true);
            return;
        }

        formikBag.validateForm().then((errors) => {
            if (!Object.keys(errors).some((field) => field.length > 0)) {
                setSelectedTab(tab);
                history.push(setNavLink(tab));
            }
        });
    };

    const onSubmitButtonClick = () => {
        formikBag.setFieldValue('status', ShiftHandoverStatus.Awaiting);
        onSave();
    };

    const onSaveButtonClick = () => {
        onSave();
    };

    const onSave = () => {
        formikBag.validateForm().then(() => {
            if (isValid) {
                formikBag.submitForm();
            } else {
                scrollToFirstValidationError();
            }
        });
    };

    const onAcknowledgeButtonClick = () => {
        acknowledgeShiftHandover(shiftHandover.id, siteId);
    };

    const onCreateShiftHandoverClick = () => {
        const url = buildNewShiftHandoverUrl(
            siteId,
            null,
            shiftHandoverSettings.shiftHandoverType,
            shiftHandover.facilityId
        );
        clearForm();
        onRouteChange(url);
    };

    const renderTab = (formik: FormikProps<IShiftHandover>) => {
        formikBag = formik;
        setIsValid(formik.isValid);
        setIsFormDirty(formik.dirty);

        switch (currentTab) {
            case Tab.details:
                return isReadOnly ? (
                    <ShiftHandoverDetailsReadOnlyTab
                        shiftHandoverSettings={shiftHandoverSettings}
                        formikBag={formikBag}
                        isLoading={isLoading}
                        shiftChecklist={shiftChecklist}
                        shiftHandoverItemCategories={shiftHandoverItemCategories}
                        priorities={priorities}

                    />
                ) : (
                    <ShiftHandoverDetailsTabContainer
                        shiftHandoverSettings={shiftHandoverSettings}
                        formikBag={formikBag}
                        isLoading={isLoading}
                        shiftChecklist={shiftChecklist}
                        shiftHandoverItemCategories={shiftHandoverItemCategories}
                        loadEngineers={loadEngineers}
                        siteId={siteId}
                        shiftEngineers={shiftEngineers}
                        priorities={priorities}
                    />
                );

            case Tab.documents:
                return (
                    <ShiftHandoverDocumentsTabContainer
                        id={shiftHandoverId}
                        isReadOnly={isReadOnly}
                    />
                );

            case Tab.history:
                return <ShiftHandoverHistoryTabContainer id={shiftHandoverId} siteId={siteId} />;
        }
    };

    const isShiftHandoverIdAvailable = () => {
        return isEmpty(shiftHandoverId) ? false : true;
    };

    const isTabChanging = selectedTab !== currentTab;

    const isTabDisabled = !shiftHandoverId;

    const shouldShowShowConfirmNavigateAwayDialog =
        !isShiftHandoverCreated && !isTabChanging && (shouldBlockNavigation || isFormDirty);

    const displayArchiveButton: boolean = !!shiftHandoverId && !!shiftHandover;

    const onArchive = () => {
        if (formikBag.values.id) {
            deleteShiftHandover(formikBag.values.id, formikBag.values.siteId);
        }
    };

    const archiveButton = () => {
        if (!displayArchiveButton) {
            return null;
        }
        return (
            <ButtonWithConfirmDialog
                onConfirm={onArchive}
                buttonType={'archive'}
                buttonText={translate('ShiftHandoverUpsertPage.Button.Archive')}
                message={translate('ShiftHandoverUpsertPage.Archive.Message')}
                title={translate('ShiftHandoverUpsertPage.Archive.Title')}
                isButtonDisabled={!checkPermissions([Privilege.ShiftHandoverDelete], permissions)}
                isLoading={isLoading}
            />
        );
    };

    const isAcknowledgedByUser = (): boolean => {
        return shiftHandover &&
            shiftHandover.approvers !== undefined &&
            shiftHandover.approvers.some(x => x.approvedById === getUserId());
    }

    return (
        <Page
            title={translate('ShiftHandoversPage.Title')}
            breadcrumbCustomLinks={customLinks}
            className="shift-handover-upsert-page"
            redirectOnSiteChange={true}
            redirectOnSiteChangeUrl={shiftHandoversPageUrl}
            showConfirmNavigateAwayDialog={shouldShowShowConfirmNavigateAwayDialog}
        >
            {hasReadWritePermission
                && !isAcknowledgedByUser()
                && shiftHandover.status !== undefined
                && shiftHandover.status !== null
                && !(shiftHandoverSettings?.restrictAcknowledgement &&
                    shiftHandoverSettings.restrictAcknowledgement == true &&
                    getUserId() == shiftHandover.createdByPersonId)
                && (
                    <Alert
                        type="warning"
                        title={translate('ShiftHandoverUpsertPage.Alert.Awaiting.Title')}
                        showIcon={false}
                    >
                        <div className="columns">
                            <div className="column is-four-fifths">
                                <p>{translate('ShiftHandoverUpsertPage.Alert.Awaiting.Description')}</p>
                            </div>
                            <div className="column shift-acknowledge-submit">
                                <Button
                                    id="btnAcknowledge"
                                    buttonType="save"
                                    onClick={onAcknowledgeButtonClick}
                                    isLoading={isLoading}
                                >
                                    {translate('ShiftHandoverUpsertPage.Alert.Awaiting.Button.Text')}
                                </Button>
                            </div>
                        </div>
                    </Alert>
                )}

            {hasReadWritePermission
                && isAcknowledgedByUser()
                && shiftHandover.status !== undefined
                && shiftHandover.status !== null
                && (
                    <Alert
                        type="warning"
                        title={translate('ShiftHandoverUpsertPage.Alert.Acknowledged.Title')}
                        showIcon={false}
                    >
                        <div className="columns">
                            <div className="column is-four-fifths">
                                <p>
                                    {translate(
                                        'ShiftHandoverUpsertPage.Alert.Acknowledged.Description'
                                    )}
                                </p>
                            </div>
                            <div className="column shift-acknowledge-submit">
                                <Button
                                    id="btnCreateNew"
                                    buttonType="save"
                                    onClick={onCreateShiftHandoverClick}
                                    isLoading={isLoading}
                                >
                                    {translate(
                                        'ShiftHandoverUpsertPage.Alert.Acknowledged.Button.Text'
                                    )}
                                </Button>
                            </div>
                        </div>
                    </Alert>
                )}
            {shiftHandoverId && hasReadWritePermission && (
                <ShiftHandlerPageToolbar
                    onExport={() => exportShiftHandovers(shiftHandoverId, siteId)}
                    isExporting={isExporting}
                />
            )}
            <TabGroup isLoading={isLoading}>
                <TabItem
                    title={translate('ShiftHandoverUpsertPage.Tabs.Details')}
                    tab={Tab.details}
                    currentTab={currentTab}
                    onClick={handleClick}
                />
                <TabItem
                    title={translate('ShiftHandoverUpsertPage.Tabs.Documents')}
                    tab={Tab.documents}
                    currentTab={currentTab}
                    isDisabled={isTabDisabled}
                    onClick={handleClick}
                />
                <TabItem
                    title={translate('ShiftHandoverUpsertPage.Tabs.History')}
                    tab={Tab.history}
                    currentTab={currentTab}
                    isDisabled={isTabDisabled}
                    onClick={handleClick}
                />
            </TabGroup>
            <Formik<Partial<IShiftHandover>>
                initialValues={shiftHandover}
                onSubmit={onSubmit}
                validationSchema={shiftHandoverDetailsFormValidationSchema}
                render={renderTab}
                enableReinitialize={true}
                isInitialValid={shiftHandoverId ? true : false}
                validateOnBlur={true}
                validateOnChange={true}
            />
            <ConfirmDialog
                title={translate('ShiftHandoverUpsertPage.ConfirmDialog.Title')}
                message={translate('ShiftHandoverUpsertPage.ConfirmDialog.Message')}
                buttonCancelHide={true}
                buttonConfirmText={translate('ShiftHandoverUpsertPage.ConfirmDialog.Button')}
                isVisible={showTabDisabledDialog}
                onConfirm={() => setShowTabDisabledDialog(false)}
                onOutsideDialogClick={() => setShowTabDisabledDialog(false)}
            />
            <Toolbar type="save">
                <ToolbarGroup>
                    <Button
                        id="cancel"
                        buttonType="cancel"
                        isLoading={isLoading}
                        onClick={() => onRouteChange(shiftHandoversPageUrl)}
                    >
                        {translate('ShiftHandoverUpsertPage.Button.Cancel')}
                    </Button>
                    {archiveButton()}
                </ToolbarGroup>
                {hasReadWritePermission && !isReadOnly && (
                    <ToolbarGroupRight>
                        <Button
                            id="btnSubmit"
                            type="submit"
                            buttonType="save"
                            onClick={onSubmitButtonClick}
                            isLoading={isLoading}
                        >
                            {translate('ShiftHandoverUpsertPage.Button.Submit')}
                        </Button>
                        <Button
                            id="btnSave"
                            type="submit"
                            buttonType="save"
                            onClick={onSaveButtonClick}
                            isLoading={isLoading}
                        >
                            {translate('ShiftHandoverUpsertPage.Button.Save')}
                        </Button>
                    </ToolbarGroupRight>
                )}
            </Toolbar>
        </Page>
    );
};
