import { Form, Formik } from 'formik';
import React from 'react';
import { ArticlePanel } from '../panel/panel';
import FormikInput, { InputType } from './FormikInput';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';

interface IProps {
    title: string;
    subtitle: string;
    returnUrl?: string;
    initialValues: any;
    validate: (values: any) => void;
    onSubmit: any;
    intl: any;
}

class ContactFormClass extends React.PureComponent<IProps> {
    public render() {
        return (
            <ArticlePanel className="column is-half is-offset-one-quarter" style={{ marginTop: '20px' }}>
                <header>
                    <h1 className="title is-4">{this.props.title}</h1>
                    <small>{this.props.subtitle}</small>
                </header>
                <Formik
                    initialValues={this.props.initialValues}
                    validate={this.props.validate}
                    onSubmit={this.props.onSubmit}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <FormikInput lable="Subject" value="subject" type={InputType.input} />
                            <FormikInput lable="Message" value="message" type={InputType.textarea} />
                            <div className="field is-grouped">
                                <div className="control">
                                    <button
                                        type="submit"
                                        disabled={isSubmitting}
                                        className="button is-link"
                                    >{translate(this.props.intl, 'Globals.Label.Submit')}
                                    </button>
                                </div>
                                <div className="control">
                                    <button className="button is-text">{translate(this.props.intl, 'Globals.Label.Cancel')}</button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ArticlePanel>
        );
    }
}

export const ContactForm = injectIntl(ContactFormClass);