import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import {
    loadAttachmentsGlobalMockDrill,
    uploadAttachmentGlobalMockDrill,
} from 'actions/actions-v2/attachment-action-v2';
import { GlobalMockDrillAttachmentTab } from './global-mock-drill-attachments-tab';

const getIsUploading = (state: IRootState) => state.globalMockDrillsState.isLoading;
const getAttachments = (state: IRootState) => state.globalMockDrillsState.attachments;

const mapStateToProps = createSelector(
    getIsUploading,
    getAttachments,
    (isUploading, attachments) => ({
        isUploading,
        attachments,
    })
);

const mapDispatchToProps = {
    loadAttachments: loadAttachmentsGlobalMockDrill,
    uploadAttachment: uploadAttachmentGlobalMockDrill,
};

export const GlobalMockDrillAttachmentTabContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(GlobalMockDrillAttachmentTab);
