import { combineReducers } from 'redux';
import { QuestionSetActions as OldQuestionSetActions, QuestionSetActionTypes } from '../../actions/question-set-actions';
import { QuestionSetActions } from 'actions/actions-v2/question-set-actions';
import { IQuestionSet } from '../../models/question-set';
import { IGridViewState, INITIAL_STATE as INITIAL_GRID_STATE } from '../grid';
import { IAppAction } from 'actions/app-action';
import { s2ab } from 'utils/export-utils';
import { sortArrayBy } from 'utils/sort-utils';
import saveAs from 'file-saver';

export interface IQuestionSetsGridState extends IGridViewState<IQuestionSet> {
    questionSetsFilterStatuses: string[];
    questionSetsFilterCompletion: string[];
    expandedFilters: string[];
    filterKeywords: string[];
    isInitialising: boolean;
    isExporting: boolean;
}

export const INITIAL_STATE: IQuestionSetsGridState = {
    ...INITIAL_GRID_STATE,
    questionSetsFilterStatuses: [],
    questionSetsFilterCompletion: [],
    expandedFilters: ['questionSetsFilterRoles', 'questionSetsFilterCompletion'],
    filterKeywords: [],
    isInitialising: true,
    isExporting: true
};

const items = (state: IQuestionSetsGridState['items'] = INITIAL_STATE.items, action: IAppAction) => {
    switch (action.type) {
        case OldQuestionSetActions.LOAD_QUESTION_SETS_CANCELLED:
        case OldQuestionSetActions.LOAD_QUESTION_SETS_REJECTED:
            return INITIAL_STATE.items;

        case OldQuestionSetActions.LOAD_QUESTION_SETS_FULFILLED:
            return action.payload.questionSets;

        case QuestionSetActions.LOAD_LIST_FULFILLED:
            return action.payload ? action.payload : state;

        case QuestionSetActions.DELETE_FULFILLED:
            if (action.payload) {
                return state.filter((questionSet) => (questionSet.id !== action.payload));
            }
            return state;

        // case QuestionSetActions.LOAD_PAGE_FULFILLED:
        //     return action.payload.questionSets.items;

        case OldQuestionSetActions.CHANGE_ROLES_FULFILLED:
            return state.map((questionSet) => {
                if (questionSet.id === action.payload.value.parentId) {
                    return { ...questionSet, roles: action.payload.value.value };
                }
                return questionSet;
            });
        case OldQuestionSetActions.CHANGE_SORT_ORDER_VALUE:
            return sortArrayBy(action.payload.sortColumn, [...state], action.payload.sortAscending);
        default:
            return state;
    }
};

const isLoading = (state: boolean = INITIAL_STATE.isLoading, action: IAppAction) => {
    switch (action.type) {
        case QuestionSetActions.LOAD_LIST:
        case QuestionSetActions.DELETE:
        case OldQuestionSetActions.LOAD_QUESTION_SETS:
            return true;

        case QuestionSetActions.LOAD_LIST_FULFILLED:
        case QuestionSetActions.LOAD_LIST_REJECTED:
        case QuestionSetActions.DELETE_FULFILLED:
        case QuestionSetActions.DELETE_REJECTED:
        case OldQuestionSetActions.LOAD_QUESTION_SETS_CANCELLED:
        case OldQuestionSetActions.LOAD_QUESTION_SETS_FULFILLED:
        case OldQuestionSetActions.LOAD_QUESTION_SETS_REJECTED:
            return false;

        default:
            return state;
    }
};

const sortColumn = (
    state: IQuestionSetsGridState['sortColumn'] = INITIAL_STATE.sortColumn,
    action: QuestionSetActionTypes
) => {
    switch (action.type) {
        case OldQuestionSetActions.CHANGE_SORT_ORDER_VALUE:
            return action.payload.sortColumn;

        default:
            return state;
    }
};

const sortAscending = (
    state: IQuestionSetsGridState['sortAscending'] = INITIAL_STATE.sortAscending,
    action: QuestionSetActionTypes
) => {
    switch (action.type) {
        case OldQuestionSetActions.CHANGE_SORT_ORDER_VALUE:
            return action.payload.sortAscending;

        default:
            return state;
    }
};

const secondarySortColumn = (
    state: IQuestionSetsGridState['secondarySortColumn'] = INITIAL_STATE.secondarySortColumn,
    action: QuestionSetActionTypes
) => {
    switch (action.type) {
        default:
            return state;
    }
};

const secondarySortAscending = (
    state: IQuestionSetsGridState['secondarySortAscending'] = INITIAL_STATE.secondarySortAscending,
    action: QuestionSetActionTypes
) => {
    switch (action.type) {
        default:
            return state;
    }
};

const expandedItems = (
    state: IQuestionSetsGridState['expandedItems'] = INITIAL_STATE.expandedItems,
    action: QuestionSetActionTypes
) => {
    switch (action.type) {
        case OldQuestionSetActions.TOGGLE_ITEM_EXPANDED:
            const id = action.payload.id;
            if (state.includes(id)) {
                return state.filter((c) => c !== id);
            }
            return [...state, id];
        case OldQuestionSetActions.COLLAPSE_ALL:
            return [];
        case OldQuestionSetActions.EXPAND_ALL:
            return action.payload.values;
        default:
            return state;
    }
};

const questionSetsFilterStatuses = (
    state: IQuestionSetsGridState['questionSetsFilterStatuses'] = INITIAL_STATE.questionSetsFilterStatuses,
    action: QuestionSetActionTypes) => {
    switch (action.type) {
        case OldQuestionSetActions.CHANGE_FILTER_VALUE:
            if (action.payload.field !== 'questionSetsFilterRoles') {
                return state;
            }

            const filterValue = action.payload.value;
            if (state.includes(filterValue)) {
                const newState = state.filter((item) => item !== filterValue);
                return newState;
            } else {
                if (filterValue) {
                    const newState = state.concat(filterValue);
                    return newState;
                }
            }

        case OldQuestionSetActions.CLEAR_FILTERS:
            return [];

        default:
            return state;
    }
};

const getIsInitialising = (
    state: IQuestionSetsGridState['isInitialising'] = INITIAL_STATE.isInitialising,
    action: IAppAction) => {
    switch (action.type) {
        case QuestionSetActions.LOAD_LIST_FULFILLED:
        case OldQuestionSetActions.LOAD_QUESTION_SETS_FULFILLED:

            return false;
        default:
            return state;
    }
};

const questionSetsFilterCompletion = (
    state: IQuestionSetsGridState['questionSetsFilterCompletion'] = INITIAL_STATE.questionSetsFilterCompletion,
    action: QuestionSetActionTypes) => {
    switch (action.type) {
        case OldQuestionSetActions.CHANGE_FILTER_VALUE:
            if (action.payload.field !== 'questionSetsFilterCompletion') {
                return state;
            }

            const filterValue = action.payload.value;
            if (state.includes(filterValue)) {
                const newState = state.filter((item) => item !== filterValue);
                return newState;
            } else {
                if (filterValue) {
                    const newState = state.concat(filterValue);
                    return newState;
                }
            }

        case OldQuestionSetActions.CLEAR_FILTERS:
            return [];

        default:
            return state;
    }
};

const expandedFilters = (
    state: IQuestionSetsGridState['expandedFilters'] = INITIAL_STATE.expandedFilters,
    action: QuestionSetActionTypes
) => {
    switch (action.type) {
        case OldQuestionSetActions.TOGGLE_FILTER_EXPANDED:
            const id = action.payload.id;

            if (state.includes(id)) {
                const newState = state.filter((item) => item !== id);
                return newState;
            } else {
                if (id) {
                    const newState = state.concat(id);
                    return newState;
                }
            }

            return state;

        default:
            return state;
    }
};

const filterKeywords = (
    state: IQuestionSetsGridState['filterKeywords'] = INITIAL_STATE.filterKeywords,
    action: QuestionSetActionTypes
) => {
    switch (action.type) {
        case OldQuestionSetActions.ADD_FILTER_KEYWORD:
            const keywordToAdd = action.payload.keyword;

            if (!keywordToAdd || state.includes(keywordToAdd)) {
                // empty or we already have the filter, just return what we have
                return state;
            }

            return state.concat(keywordToAdd);

        case OldQuestionSetActions.REMOVE_FILTER_KEYWORD:
            const keywordToRemove = action.payload.keyword;

            if (!keywordToRemove || !state.includes(keywordToRemove)) {
                // empty or we don't have the filter, just return what we have
                return state;
            }

            const newState = state.filter((keyword) => keyword !== keywordToRemove);
            return newState;

        case OldQuestionSetActions.CLEAR_FILTERS:
            return [];
        default:
            return state;
    }
};

const isExporting = (
    state: IQuestionSetsGridState['isExporting'] = INITIAL_STATE.isExporting,
    appAction: IAppAction
) => {
    switch (appAction.type) {
        case QuestionSetActions.DOWNLOAD:
            return true;
        case QuestionSetActions.DOWNLOAD_FULFILLED:
            if (appAction?.payload?.fileContents) {
                const blob = new Blob([s2ab(atob(appAction.payload.fileContents))], {
                    type: 'application/excel',
                });
                saveAs(blob, appAction.payload.fileDownloadName);
            }
            return false;
        case QuestionSetActions.DOWNLOAD_REJECTED:
            return false;
        default:
            return state;
    }
};

const responseContinuation = (state: string = INITIAL_STATE.responseContinuation) => state;

export const reducer = combineReducers<IQuestionSetsGridState>({
    expandedFilters,
    expandedItems,
    filterKeywords,
    isLoading,
    isExporting,
    isInitialising: getIsInitialising,
    items,
    questionSetsFilterCompletion,
    questionSetsFilterStatuses,
    secondarySortAscending,
    secondarySortColumn,
    sortAscending,
    sortColumn,
    responseContinuation
});
