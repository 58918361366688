import { SiteRelationshipsActions, SiteRelationshipsActionTypes } from '../../actions/site-relationships-actions';
import { IPerson } from '../../models/person';
import { ISiteProfile } from '../../models/site-profile';

export interface IDeletePersonConfirmationState {
    person: IPerson;
    site: ISiteProfile;
}

export const INITIAL_STATE: IDeletePersonConfirmationState = {
    person: null,
    site: null
};

export const reducer = (
    state: IDeletePersonConfirmationState = INITIAL_STATE,
    action: SiteRelationshipsActionTypes
) => {
    switch (action.type) {
        case SiteRelationshipsActions.UNLINK_PERSON:
            return {
                ...action.payload
            };

        case SiteRelationshipsActions.UNLINK_PERSON_CONFIRMED:
        case SiteRelationshipsActions.UNLINK_PERSON_CANCELLED:
            return INITIAL_STATE;

        default:
            return state;
    }
};
