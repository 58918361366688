import { createSelector } from 'reselect';
import { IRootCermAssessmentState } from 'reducers/cermAssessments';

const getLookupActionCategories = (state: IRootCermAssessmentState) => {
    return state.app.lookupActionCategories;
};

const getLookupActionPriorities = (state: IRootCermAssessmentState) => {
    return state.app.lookupActionPriorities;
};

export default createSelector(
    getLookupActionPriorities,
    getLookupActionCategories,
    (lookupActionPriorities, lookupActionCategories) => ({
        lookupActionPriorities,
        lookupActionCategories,
    })
);
