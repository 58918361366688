import React, { useEffect, useState } from "react";
import { getTokenExpiry } from "./auth-helper";
import { getAuthenticationPlatform } from 'helpers/authentication';
export const RefreshToken: React.FC = () => {
    const [time, setTime] = useState<number>();
    const tokenRenewalTime = 5 * 60; // 5min 
    let timer: any;

    const updateRefreshTime = () => {
        const expiryTime = getTokenExpiry();
        const refreshTime = expiryTime - (new Date().getTime() / 1000);
        if (refreshTime <= tokenRenewalTime) {
            getAuthenticationPlatform().refreshToken(updateRefreshTime);
        } else {
            setTime((refreshTime - tokenRenewalTime) * 1000);
        }
    }

    useEffect(updateRefreshTime, []);
    useEffect(() => {
        if (time) {
            timer = setTimeout(() => {
                getAuthenticationPlatform().refreshToken(updateRefreshTime);
            }, time);
            return () => clearTimeout(timer);
        }
    }, [time]);
    return null;
}