import * as React from 'react';
import { Help, Control, Field } from '..';
import { BulmaColor } from 'enums/BulmaColor';
import NumberFormat from 'react-number-format';

interface IProps {
    id: string;
    label?: string;
    value: number | string;
    error?: string;
    prefix?: string;
    decimalSeparator?: boolean | string;
    thousandSeparator?: boolean;
    allowNegative?: boolean;
    decimalScale?: number;
    fixedDecimalScale?: boolean;
    min?: number;
    minLength?: number;
    max?: number;
    maxLength?: number;
    handleChange: (field: any) => void;
    handleBlur?: (e: any) => void;
    isHorizontal?: boolean;
    isDisabled?: boolean;
}

export const NumberField: React.FC<IProps> = (props) => {
    return (
        <Field isHorizontal={props.isHorizontal}>
            <label className="label">{props.label}</label>
            <Control>
                <NumberFormat
                    className="is-medium input"
                    id={props.id}
                    value={props.value}
                    onChange={props.handleChange}
                    prefix={props.prefix}
                    decimalSeparator={props.decimalSeparator as string}
                    thousandSeparator={props.thousandSeparator}
                    allowNegative={props.allowNegative}
                    onBlur={props.handleBlur}
                    decimalScale={props.decimalScale}
                    fixedDecimalScale={props.fixedDecimalScale}
                    max={props.max}
                    maxLength={props.maxLength}
                    min={props.min}
                    minLength={props.minLength}
                    disabled={props.isDisabled}
                />
            </Control>
            <Help bulmaColor={BulmaColor.Danger} isShown={true}>
                {props.error}
            </Help>
        </Field>
    );
};
