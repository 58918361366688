import { createSelector } from 'reselect';
import { IBaseState } from 'reducers/base-state';
import { IRootState } from 'routes/store';

const getLoadingStatus = createSelector(
    (state: IRootState) => state.dashboardComponentsState.isCalendarEventComponentLoading,
    (state) => state
);

const getSiteId = (state: IBaseState) => state.app.siteId;

const getComponent = createSelector(
    (state: IRootState) => state.dashboardComponentsState.calendarEventComponent,
    (state) => state
);

export default createSelector(
    getLoadingStatus,
    getSiteId,
    getComponent,
    (isLoading, siteId, component) => ({
        isLoading,
        siteId,
        component
    })
);
