import { AnyAction } from 'redux';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { ajax, AjaxError } from 'rxjs/ajax';
import { catchError, filter, map, mapTo, switchMap, takeUntil, debounceTime } from 'rxjs/operators';
import { AppActions } from './../actions/app-actions';
import { CalendarActionTypes, CalendarActions } from './../actions/calendar-actions';
import { IRootCalendarState } from 'state/calendar-state';
import { getErrorActions$ } from './epic-helpers';
import { getApiHeaders } from './shared-header';

import appConfig from 'helpers/config-helper';

const config = appConfig();

const apiUrl = config.REACT_APP_BASE_API;

const changeSiteEpic: Epic<AnyAction> = (action$) =>
    action$.pipe(
        ofType(AppActions.CHANGE_SITE),
        filter(() => window.location.pathname.toLowerCase() === '/calendar'),
        mapTo<CalendarActionTypes, CalendarActionTypes>({
            type: CalendarActions.LOAD_CALENDAR_EVENTS,
        })
    );

const loadCalendarEventsEpic: Epic<AnyAction, any, IRootCalendarState> = (action$, state$) =>
    action$.pipe(
        ofType(CalendarActions.LOAD_CALENDAR_EVENTS),
        debounceTime(1500),
        map(() => ({
            site: state$.value.app.siteId,
        })),
        map(() => `${apiUrl}/api/calendar/${state$.value.app.siteId}`),
        switchMap((url) =>
            ajax.getJSON(url, getApiHeaders(null, state$.value.app.siteId)).pipe(
                map<any, CalendarActionTypes>((response) => ({
                    type: CalendarActions.LOAD_CALENDAR_EVENTS_FULFILLED,
                    payload: {
                        calendarItems: response.value,
                    },
                })),
                takeUntil(
                    action$.pipe(
                        ofType(
                            CalendarActions.LOAD_CALENDAR_EVENTS,
                            CalendarActions.LOAD_CALENDAR_EVENTS_CANCELLED
                        )
                    )
                ),
                catchError<any, any>((error: AjaxError) =>
                    getErrorActions$('Calendar')(
                        CalendarActions.LOAD_CALENDAR_EVENTS_REJECTED,
                        error,
                        {
                            errorMessage: 'Unable to load calendar events',
                        }
                    )
                )
            )
        )
    );

export const calendarEpics = combineEpics(changeSiteEpic, loadCalendarEventsEpic);
