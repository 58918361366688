import { IEmeraldDisabledInput } from '../shared/disabled.input';
import { IEmeraldIdInput } from '../shared/id.input';
import { IEmeraldLabelInput } from '../shared/label.input';
import { IEmeraldTitleInput } from '../shared/title.input';

export enum SwitchLabelPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

export class EmeraldSwitchCoreInputs implements IEmeraldSwitchCoreInputs {
  id?: string = `Switch${Math.random().toString()}`;
  label?: string;
  labelPosition?: SwitchLabelPosition = SwitchLabelPosition.RIGHT;
  disabled?: boolean = false;
  title?: string;
  selected?: boolean = false;
  hasOnOffIcon?: boolean = false;
  iconWhenOn?: string = 'checkmark';
  iconWhenOff?: string = 'remove';
}

export interface IEmeraldSwitchCoreInputs
  extends IEmeraldDisabledInput,
    IEmeraldLabelInput,
    IEmeraldIdInput,
    IEmeraldTitleInput {
  labelPosition?: SwitchLabelPosition;
  selected?: boolean;
  hasOnOffIcon?: boolean;
  iconWhenOn?: string;
  iconWhenOff?: string;
}
