//No More used
import * as React from 'react';
import { isEmpty } from 'lodash';
import { BulmaSize } from '../../enums/BulmaSize';
import { IAction } from '../../models/action';
import { IKeyedItem } from '../../models/keyed-item';
import { DateTime, Format } from '../dateTime';
import { GridContentExpander } from '../gridContentExpander';
import { Icon, IconType } from '../icon';
import queryString from 'query-string';
import { IEmbeddedLink } from '../../models/embeddedLink';
import './linked-actions.scss';
import { IActionFilters } from 'models/action-filters';
import { Button } from '../v2/components';
import useTranslate from 'translations/translation-utils';

export interface ILinkedActionValue<T> {
    label: string;
    value: (input: T) => string;
}

export interface ILinkedActionsStateProps<T extends IAction = IAction> {
    item: IKeyedItem;
    title: string;
    data: T[];
    isExpanded: boolean;
    isLoading: boolean;
}

export interface ILinkedActionsActionProps {
    onOpen: (item: IKeyedItem) => void;
    onClose: (item: IKeyedItem) => void;
    onDelete?: (item: IKeyedItem) => void;
    loadRiskActions: (filters: IActionFilters) => void;
    onShowUpsertActionModal: (riskId: string, siteId: string) => void;
}

interface IProps extends ILinkedActionsStateProps, ILinkedActionsActionProps { }

export const LinkedActions: React.FC<IProps> = (props) => {
    const onOpen = () => {
        props.onOpen(props.item);
        props.loadRiskActions({
            siteId: [props.item.siteId],
            riskId: [props.item.id],
        });
    };
    const onClose = () => props.onClose(props.item);

    const onDelete: React.MouseEventHandler<HTMLButtonElement> = () => {
        props.onDelete(props.item);
    };

    const onShowUpsertActionModal = () => props.onShowUpsertActionModal(props.item.id, props.item.siteId);

    const getFullName = (item: IEmbeddedLink) => {
        if (item === null || item === undefined) {
            return '';
        }
        if (item.items === null || item.items === undefined) {
            return '';
        }
        if (item.items.fullName === null || item.items.fullName === undefined) {
            return '';
        }
        return item.items.fullName;
    };

    const translate = useTranslate();

    return (
        <GridContentExpander
            buttonText={props.title}
            isExpanded={props.isExpanded}
            isLoading={props.isLoading}
            className="linked-actions"
            onOpen={onOpen}
            onClose={onClose}
        >
            {isEmpty(props.data) && (
                <p>No linked actions.</p>
            )}

            {!isEmpty(props.data) && (
                <div className="grid-data">
                    <div className="columns horizontal-table grid-data__headers">
                        <div className="column is-5">
                            {translate('RiskRegisterPage.RiskRegisterTable.Title.Title')}
                        </div>
                        <div className="column">
                            {translate('RiskRegisterPage.RiskFilter.Title.Category')}
                        </div>
                        <div className="column">
                            {translate('RiskRegisterPage.RiskFilter.Title.Status')}
                        </div>
                        <div className="column">
                            {translate('RiskActionTab.TableHeader.Owner')}
                        </div>
                        <div className="column">
                            {translate('RiskActionTab.Label.DueDate')}
                        </div>
                        {props.onDelete && <div className="column is-1" />}
                    </div>

                    {props.data.map((item, i) => (
                        <div className="columns horizontal-table" key={i}>
                            <div className="column is-5">
                                <h2 className="mobile-header">{translate('RiskRegisterPage.RiskRegisterTable.Title.Title')}</h2>
                                <div>
                                    <a
                                        title={item.description}
                                        href={`/action?${queryString.stringify({ id: item.id, filterStatus: [item.statusId] })}`}
                                    >{item.description}
                                    </a>
                                </div>
                            </div>
                            <div className="column">
                                <h2 className="mobile-header">{translate('RiskRegisterPage.RiskFilter.Title.Category')}</h2>
                                <div>{item.category}</div>
                            </div>
                            <div className="column">
                                <h2 className="mobile-header">{translate('RiskRegisterPage.RiskFilter.Title.Status')}</h2>
                                <div>{item.status}</div>
                            </div>
                            <div className="column">
                                <h2 className="mobile-header">{translate('RiskActionTab.TableHeader.Owner')}</h2>
                                <div>{getFullName(item.owner)}</div>
                            </div>
                            <div className="column">
                                <h2 className="mobile-header">{translate('RiskActionTab.Label.DueDate')}</h2>
                                <div><DateTime value={item.dueDate} format={Format.DateOnly} /></div>
                            </div>

                            {props.onDelete && (<div className="column is-1">
                                <button
                                    className="button grid-view__option-action"
                                    onClick={onDelete}
                                >
                                    <Icon type={IconType.Trash} size={BulmaSize.Medium} />
                                    <span>{translate('Attachments.AttachmentList.ButtonWithConfirmDialog.ButtonText')}</span>
                                </button>
                            </div>)}
                        </div>
                    ))}
                </div>
            )}
            <div className="columns GridContentExpander__action-buttons">
                <div className="column">
                    <Button onClick={onShowUpsertActionModal}>
                        {translate('RiskActionTab.Button.Addaction')}
                    </Button>
                </div>
            </div>
        </GridContentExpander>
    );
};
