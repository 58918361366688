import { IRootState } from './../../../../routes/store';
import { createSelector } from 'reselect';

const getEngineer = (state: IRootState) => state.engineerState.engineer;
const getIsUploading = (state: IRootState) => state.engineerState.isUploading;
const getAttachments = (state: IRootState) => state.engineerState.attachments;
const getPermissions = (state: IRootState) => state.app.permissions;

export default createSelector(
    getEngineer,
    getIsUploading,
    getAttachments,
    getPermissions,
    (engineer, isUploading, attachments, permissions) => ({
        engineer,
        isUploading,
        attachments,
        permissions
    })
);
