import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { MockDrillsPage } from './mock-drills-page';
import { loadMockDrills, exportMockDrills } from 'actions/actions-v2/mock-drills-actions';
import { getPermissions } from 'sharedSelectors';
import { checkPermission } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';

const getSiteId = (state: IRootState) => state.app.siteId;
const getShowHelpModule = (state: IRootState) => state.app.showHelpModule;
const getMockDrills = (state: IRootState) => state.mockDrillsState.mockDrills;
const getIsLoading = (state: IRootState) => state.mockDrillsState.isLoading;
const getIsExporting = (state: IRootState) => state.mockDrillsState.isExporting;

const mapStateToProps = createSelector(
    getSiteId,
    getMockDrills,
    getIsLoading,
    getIsExporting,
    getPermissions,
    getShowHelpModule,
    (siteId, mockDrills, isLoading, isExporting, permissions, showHelpModule) => ({
        siteId,
        mockDrills,
        isLoading,
        isExporting,
        hasReadWritePermission: checkPermission(Privilege.MockDrillsReadWrite, permissions),
        showHelpModule,
    })
);

const mapDispatchToProps = {
    loadMockDrills,
    exportMockDrills,
};

export const MockDrillsPageContainer = connect(mapStateToProps, mapDispatchToProps)(MockDrillsPage);
