/* eslint-disable @typescript-eslint/no-inferrable-types */
export const EMPTY_STRING: string = '';
export const DEFAULT_TRUNCATED_TEXT_LENGTH: number = 20;
export const ELLIPSIS_TEXT: string = '...';
export const DEFAULT_FONT_SIZE_IN_REM = 1;

/* Emerald icons */
export const EMERALD_ICON_INPUT_CLEAR: string = 'input-clear-icon';
export const EMERALD_ICON_ARROW_DROP_DOWN: string = 'arrow_drop_down';
export const EMERALD_ICON_ARROW_RIGHT: string = 'arrow_right';
