import { saveAs } from 'file-saver';
import { Dictionary } from 'lodash';
import { combineReducers } from 'redux';
import { RiskActions, RiskActionTypes } from '../../actions/risk-actions';
import { IBuilding } from '../../models/building';
import { IRisk } from '../../models/risk';
import { IRiskLinkedActions } from '../../models/risk-linked-actions';
import { getKey } from '../../utils/id-utils';

import {
    IActionFormState,
    INITIAL_STATE as ACTIONS_FORM_INITIAL_STATE,
    reducer as actionsForm
} from '../actions/actions-form';

import { IPropertyChange } from '../../routes/risks/views/riskAuditHistory/selector';
import { IBaseState } from '../base-state';
import {
    INITIAL_STATE as RISKS_FORM_INITIAL_STATE,
    IRiskFormState,
    reducer as risksForm
} from '../risks/risks-form';
import {
    INITIAL_STATE as RISKS_ATTACHMENTS_INITIAL_STATE,
    IRisksAttachmentsState,
    reducer as attachments
} from './risks-attachments';
import {
    INITIAL_STATE as RISKS_GRID_INITIAL_STATE,
    IRisksGridState,
    reducer as grid
} from './risks-grid';
import { s2ab } from 'utils/export-utils';
import { ActionActions } from 'actions/actions-v2/action-action-v2';
import { IAppAction } from 'actions/app-action';
import { RiskActions as RiskActionsV2} from 'actions/actions-v2/risk-action-v2';

export interface IRootRisksState extends IBaseState {
    risks: IRisksState;
}

export interface IRisksState {
    isPublished: boolean;

    isInitialising: boolean;

    grid: IRisksGridState;
    attachments: IRisksAttachmentsState;
    actionsForm: IActionFormState;
    risksForm: IRiskFormState;

    deleteConfirmationItem: IRisk;

    linkedActions: Dictionary<{
        isLoading: boolean;
        data?: IRiskLinkedActions[]
    }>;

    linkedBuildings: Dictionary<{
        isLoading: boolean;
        data?: IBuilding[]
    }>;

    auditHistories: Dictionary<{
        continuationToken: string;
        pageSize: number;
        isLoading: boolean;
        data?: IPropertyChange[];
        errorMessage?: string;
    }>;

    isGeneratingReport: boolean;
}

const INITIAL_STATE: IRisksState = {

    isPublished: null,

    isInitialising: true,

    grid: RISKS_GRID_INITIAL_STATE,
    attachments: RISKS_ATTACHMENTS_INITIAL_STATE,
    actionsForm: ACTIONS_FORM_INITIAL_STATE,
    risksForm: RISKS_FORM_INITIAL_STATE,

    linkedActions: {},
    linkedBuildings: {},
    deleteConfirmationItem: null,

    auditHistories: {},
    isGeneratingReport: false
};

const deleteConfirmationItem = (
    state: IRisk = INITIAL_STATE.deleteConfirmationItem,
    action: RiskActionTypes) => {
    switch (action.type) {
        case RiskActions.DELETE_RISK:
        case RiskActions.DELETE_RISK_CONFIRM:
            return action.payload.item;
        case RiskActions.DELETE_RISK_CANCEL:
        case RiskActions.DELETE_RISK_FULFILLED:
        case RiskActions.DELETE_RISK_CANCELLED:
            return null;
        default:
            return state;
    }
};

const isPublished = (state: IRisksState['isPublished'] = INITIAL_STATE.isPublished, action: RiskActionTypes) => {
    switch (action.type) {
        case RiskActions.CHANGE_IS_PUBLISHED_VALUE:
            return action.payload.isPublished;

        default:
            return state;
    }
};

const isGeneratingReport =
(state: IRisksState['isGeneratingReport'] = INITIAL_STATE.isGeneratingReport, action: RiskActionTypes) => {
    switch (action.type) {
        case RiskActions.DOWNLOAD_RISKS:
            return true;
        case RiskActions.DOWNLOAD_RISKS_FULFILLED:
            const blob = new Blob([s2ab(atob(action.payload.response.fileContents))], {
                type: 'application/excel'
            });
            saveAs(blob, 'CERM 502.1 Data Center Risk Register Form Rev 0.3.xlsx');

            return false;
        case RiskActions.DOWNLOAD_RISKS_REJECTED:
            return false;
        default:
            return state;
    }
};

const linkedActions = (
    state: IRisksState['linkedActions'] = INITIAL_STATE.linkedActions,
    action: IAppAction
) => {
    switch (action.type) {
        // case ActionActions.LOAD_RISK_ACTIONS:
        //     return {
        //         ...state,
        //         [getKey(action.payload)]: {
        //             isLoading: true
        //         }
        //     };

        case ActionActions.LOAD_RISK_ACTIONS_REJECTED:
            return {
                ...state,
                [getKey(action.payload)]: {
                    ...state[getKey(action.payload)],
                    isLoading: false
                }
            };

        case  ActionActions.CREATE_ACTION_FULFILLED:
            if (action.payload && action.payload.riskItemId) {
                const key = getKey({ id: action.payload.riskItemId, siteId: action.payload.siteId });
                return {
                    ...state,
                    [key]: {
                        data: [...state[key].data,  action.payload],
                        isLoading: false
                    }
                };
            }
            return state;
        case ActionActions.LOAD_RISK_ACTIONS_FULFILLED:
            if (action.payload && action.payload.length > 0) {
                const siteId = action.payload[0].siteId;
                const riskId = action.payload[0].riskItemId;
                return {
                    ...state,
                    [getKey({ id: riskId, siteId})]: {
                        data: action.payload,
                        isLoading: false
                    }
                };
            }
            return state;
        default:
            return state;
    }
};

const linkedBuildings = (
    state: IRisksState['linkedBuildings'] = INITIAL_STATE.linkedBuildings,
    action: RiskActionTypes
) => {
    switch (action.type) {
        case RiskActions.LOAD_LINKED_BUILDINGS:
            return {
                ...state,
                [getKey(action.payload)]: {
                    isLoading: true
                }
            };

        case RiskActions.LOAD_LINKED_BUILDINGS_CANCELLED:
            return {
                ...state,
                [getKey(action.payload)]: {
                    ...state[getKey(action.payload)],
                    isLoading: false
                }
            };

        case RiskActions.LOAD_LINKED_BUILDINGS_FULFILLED:
            return {
                ...state,
                [getKey(action.payload)]: {
                    data: action.payload.data,
                    isLoading: false
                }
            };

        default:
            return state;
    }
};

const auditHistories = (
    state: IRisksState['auditHistories'] = INITIAL_STATE.auditHistories,
    action: RiskActionTypes
) => {
    switch (action.type) {
        case RiskActions.LOAD_HISTORY:
            return {
                ...state,
                [getKey(action.payload)]: {
                    isLoading: true,
                    continuationToken: null,
                    pageSize: 10,
                }
            };
        case RiskActions.LOAD_MORE_HISTORY:
            return {
                ...state,
                [getKey(action.payload)]: {
                    ...state[getKey(action.payload)],
                    isLoading: true,
                    continuationToken: action.payload.continuationToken,
                    pageSize: state[getKey(action.payload)].pageSize
                }
            };
        case RiskActions.LOAD_HISTORY_CANCELLED:
            return {
                ...state,
                [getKey(action.payload)]: {
                    ...state[getKey(action.payload)],
                    isLoading: false,
                    continuationToken: state[getKey(action.payload)].continuationToken,
                    pageSize: state[getKey(action.payload)].pageSize
                }
            };
        case RiskActions.LOAD_MORE_HISTORY_CANCELLED:
            return {
                ...state,
                [getKey(action.payload)]: {
                    ...state[getKey(action.payload)],
                    isLoading: false,
                    continuationToken: state[getKey(action.payload)].continuationToken,
                    pageSize: state[getKey(action.payload)].pageSize
                }
            };

        case RiskActions.LOAD_HISTORY_FULFILLED:
            return {
                ...state,
                [getKey(action.payload)]: {
                    data: action.payload.data,
                    isLoading: false,
                    continuationToken: action.payload.continuationToken,
                    pageSize: state[getKey(action.payload)].pageSize
                }
            };
        case RiskActions.LOAD_MORE_HISTORY_FULFILLED:
            return {
                ...state,
                [getKey(action.payload)]: {
                    data: state[getKey(action.payload)].data.concat(action.payload.data),
                    isLoading: false,
                    continuationToken: action.payload.continuationToken,
                    pageSize: state[getKey(action.payload)].pageSize
                }
            };
        default:
            return state;
    }
};

const isInitialising = (
    state: IRisksState['isInitialising'] = INITIAL_STATE.isInitialising,
    action: IAppAction
) => {
    switch (action.type) {
        case RiskActions.LOAD_RISKS_FULFILLED:
        case RiskActionsV2.LOAD_RISKS_FULFILLED:
            return false;
        default:
            return state;
    }
};

export const risksReducer = combineReducers<IRisksState>({
    grid,
    attachments,
    actionsForm,
    risksForm,
    isInitialising,
    deleteConfirmationItem,
    isPublished,
    linkedActions,
    linkedBuildings,
    auditHistories,
    isGeneratingReport
});
