/* eslint-disable @typescript-eslint/no-inferrable-types */
import {
  EmeraldToastXPosition,
  EmeraldToastXPositionType,
  EmeraldToastYPosition,
  EmeraldToastYPositionType,
} from './toast.position';
import {
  EmeraldToastVariance,
  EmeraldToastVarianceType,
} from './toast.variance';
import { IEmeraldTitleInput } from '../shared/title.input';
import { IEmeraldOpenedInput } from '../shared/opened.input';
import { IEmeraldIdInput } from '../shared/id.input';

export const DefaultContainerID: string = 'Main';
export class EmeraldToastInputs implements IEmeraldToastInputs {
  id: string = `${Math.floor(Math.random() * 10000)}`;
  opened?: boolean = false;
  title?: string = '';
  containerId: string = `${Math.floor(Math.random() * 10000)}`;
  variance: EmeraldToastVarianceType = EmeraldToastVariance.INFO;
  hasActionButton: boolean = false;
  primaryActionText: string = 'Ok';
  xPosition: EmeraldToastXPositionType = EmeraldToastXPosition.RIGHT;
  yPosition: EmeraldToastYPositionType = EmeraldToastYPosition.TOP;
  autoDismiss: boolean = false;
  autoDismissMilliseconds: number = 5000;
}

export interface IEmeraldToastInputs
  extends IEmeraldIdInput,
    IEmeraldTitleInput,
    IEmeraldOpenedInput {
  containerId: string;
  variance: EmeraldToastVarianceType;
  hasActionButton?: boolean;
  primaryActionText?: string;
  xPosition: EmeraldToastXPositionType;
  yPosition: EmeraldToastYPositionType;
  autoDismiss?: boolean;
  autoDismissMilliseconds?: number;
}

export class EmeraldToastData implements IEmeraldToastData {
  hasActionButton?: boolean = false;
  primaryActionText?: string = 'Ok';
  autoDismissMilliseconds?: number = 5000;
  toastId?: string = `${Math.floor(Math.random() * 10000)}`;
  constructor(
    public title: string,
    public message: string,
    public variance: EmeraldToastVarianceType = EmeraldToastVariance.INFO,
    public yPosition: EmeraldToastYPositionType = EmeraldToastYPosition.TOP,
    public xPosition: EmeraldToastXPositionType = EmeraldToastXPosition.RIGHT,
    public autoDismiss: boolean = false,
    public inlined: boolean = false,
    public containerId: string = DefaultContainerID
  ) {}
}

export interface IEmeraldToastData extends IEmeraldTitleInput {
  message: string;
  containerId: string;
  variance: EmeraldToastVarianceType;
  xPosition: EmeraldToastXPositionType;
  yPosition: EmeraldToastYPositionType;
  autoDismiss: boolean;
  inlined: boolean;
  hasActionButton?: boolean;
  primaryActionText?: string;
  autoDismissMilliseconds?: number;
  toastId?: string;
}
