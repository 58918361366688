import { IEmeraldTitleInput } from '../shared/title.input';
import { EmeraldAvatarSize, EmeraldAvatarSizeType } from './avatar.size';

export class EmeraldAvatarCoreInputs implements IEmeraldAvatarCoreInputs {
  title?: string;
  imageUrl?: string;
  initials?: string;
  iconColor?: string = 'white';
  icon?: string = 'person';
  size?: EmeraldAvatarSizeType = EmeraldAvatarSize.small;
}

export interface IEmeraldAvatarCoreInputs extends IEmeraldTitleInput {
  imageUrl?: string;
  initials?: string;
  iconColor?: string;
  icon?: string;
  size?: EmeraldAvatarSizeType;
}
