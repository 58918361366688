import React from 'react';
import Page from '../../components/v2/page/Page';
import { Form, Formik, FormikProps } from 'formik';
import { RouteComponentProps } from 'react-router';
import { ArticlePanel } from 'components/panel';
import {
    IOpportunity,
    OpportunityNav,
    IOpportunityQuestion,
} from 'models/opportunity-question-set';
import { Loader } from 'components/loader';
import OpportunityMasterForm from './opportunity-master-form';
import { Button, ButtonWithConfirmDialog, Alert } from 'components/v2/components';
import { object, string } from 'yup';
import OpportunityQuestionForm from './opportunity-question-form';
import OpportunityAdviceForm from './opportunity-advice-page';
import { OpportunityQuestionHierarchy } from './opportunity-question-hierarchy/opportunity-question-hierarchy';
import { getKeyed } from 'utils/form-utils';
import useTranslate from 'translations/translation-utils';

export interface IOppertunityParams {
    id: string;
}

export interface IProps extends RouteComponentProps<IOppertunityParams> {
    opportunity: IOpportunity;
    isInitialising: boolean;
    isLoading: boolean;
    load: (id: string) => void;
    create: (model: IOpportunity) => void;
    update: (model: IOpportunity) => void;
    delete: (id: string) => void;
}
const OpportunityPage: React.FC<IProps> = (props) => {
    const translate = useTranslate();
    const [shouldBlockNavigation, setShouldBlockNavigation] = React.useState(false);
    const [page, setPage] = React.useState(OpportunityNav.Master);
    const [path, setPath] = React.useState('master');
    const [objectPath, setObjectPath] = React.useState('');
    const [isValid, setIsValid] = React.useState(false);
    const [showError, setShowError] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    React.useEffect(() => {
        if (props.match.params.id) {
            props.load(props.match.params.id);
        }
    }, [props.match.params.id]);

    const submit = (opportunity: IOpportunity) => {
        setShouldBlockNavigation(false);
        if (opportunity.id) {
            props.update(opportunity);
        } else {
            props.create(opportunity);
        }
    };
    const value = (formikBag: FormikProps<Partial<IOpportunity>>, p: string) => {
        const val = getKeyed(formikBag, 'values', p);
        return val;
    };

    const checkOpportunityIsValid = (formik: FormikProps<Partial<IOpportunity>>) => {
        const opportunity = formik.values;
        if (opportunity.questions && opportunity.questions.length > 0) {
            if (checkAllQuestionsHasAdvice(opportunity.questions)) {
                formik.submitForm();
            }
        } else if (!opportunity.advices || opportunity.advices.length === 0) {
            setShowAlert(true);
        } else {
            formik.submitForm();
        }
    };

    const checkAllQuestionsHasAdvice = (questions: Partial<IOpportunityQuestion>[]) => {
        for (const element of questions) {
            if (
                (!element.questions || element.questions.length === 0) &&
                (!element.advices || element.advices.length === 0)
            ) {
                onNavigate(element.path);
                setShowAlert(true);
                return false;
            }
            if (element.questions) {
                return checkAllQuestionsHasAdvice(element.questions);
            }
        }
        return true;
    };

    const submitForm = (formik: FormikProps<Partial<IOpportunity>>, isDraft: boolean = true) => {
        formik.setFieldValue('isDraft', isDraft);
        if (!isDraft) {
            checkOpportunityIsValid(formik);
        } else {
            formik.submitForm();
        }
    };

    const onNavigate = (pathValue: string) => {
        if (isValid) {
            const array = pathValue.split('/');
            const last = array[array.length - 1];

            setPath(pathValue);

            if (last.includes('question')) {
                setPage(OpportunityNav.Question);
            } else if (last.includes('advice')) {
                setPage(OpportunityNav.Advice);
            } else {
                setPage(OpportunityNav.Master);
            }
        }
    };

    const onNavigateToQuestion = () => {
        if (isValid) {
            setShowAlert(false);
            setPath(path + `/question-`);
            setPage(OpportunityNav.Question);
        } else {
            setShowError(true);
        }
    };

    const onNavigateToAdvice = () => {
        if (isValid) {
            setShowAlert(false);
            setPath(path + `/advice-`);
            setPage(OpportunityNav.Advice);
        }
    };

    const onNavigateToList = () => {
        props.history.push('/Opportunities');
        // props.cleanUp();
    };

    const navigateBack = () => {
        const array = path.split('/');
        array.pop();
        const last = array[array.length - 1];

        setPath(array.join('/'));

        if (last.includes('question')) {
            setPage(OpportunityNav.Question);
        } else {
            setPage(OpportunityNav.Master);
        }
    };

    const onNavigateBack = () => {
        if (isValid) {
            navigateBack();
        }
    };
    const onArchive = () => {
        if (props.opportunity.id) {
            props.delete(props.opportunity.id);
        }
    };
    const onDelete = (formik: FormikProps<Partial<IOpportunity>>) => {
        const objsPath = objectPath.slice(0, -3);
        const i = objectPath.substr(objectPath.length - 3).charAt(1);
        const elements = value(formik, objsPath);
        elements.splice(i, 1);
        formik.setFieldValue(objsPath, elements);

        setIsValid(true);
        navigateBack();
    };

    const renderAlert = () => {
        return (
            <Alert type="warning" title={translate('OpportunityQ.QuestionsPage.Alert.Title')}>
                <p>{translate('OpportunityQ.QuestionsPage.Alert.Message')}</p>
            </Alert>
        );
    };
    const isNew = props.opportunity.id ? false : true;
    const isMaster = () => page === OpportunityNav.Master;
    const isQuestion = () => page === OpportunityNav.Question;
    const isAdvice = () => page === OpportunityNav.Advice;
    const entity = isQuestion() ? translate('RiskProfileQuestion.Set.Question') : translate('OpportunityQ.Title.Advice');

    const questionSetSchema = object<Partial<IOpportunity>>().shape({
        title: string().trim().required(translate('OpportunityQ.QuestionsPage.ErrorTitle')).nullable(true),
        category: string().required(translate('OpportunityQ.QuestionsPage.ErrorCategory')).nullable(true),
    });

    return (
        <Page
            isAdminPage={true}
            title={translate('OpportunityQ.QuestionsPage.Title')}
            className="opportunity-page"
            showConfirmNavigateAwayDialog={shouldBlockNavigation}
        >
            {!props.isInitialising && (
                <div className="CreateQuestionSetPage">
                    <div className="CreateQuestionSetForm">
                        <Loader loading={props.isLoading}>
                            <Formik<Partial<IOpportunity>>
                                initialValues={props.opportunity}
                                validationSchema={questionSetSchema}
                                onSubmit={submit}
                                enableReinitialize={true}
                                render={(formikBag) => (
                                    <div className="columns">
                                        <OpportunityQuestionHierarchy
                                            opportunity={formikBag.values}
                                            isDirty={formikBag.dirty}
                                            title={translate('OpportunityQ.QuestionsPage.Progress')}
                                            onNavigate={onNavigate}
                                            activePath={path}
                                            setShouldBlockNavigation={setShouldBlockNavigation}
                                        />
                                        <ArticlePanel className="column">
                                            <header>
                                                <small>{translate('OpportunityQ.QuestionsPage.Header')}</small>
                                            </header>
                                            <Form>
                                                {showAlert && renderAlert()}
                                                {isMaster() && (
                                                    <OpportunityMasterForm
                                                        formikBag={formikBag}
                                                        setIsValid={setIsValid}
                                                        showError={showError}
                                                    />
                                                )}
                                                {isQuestion() && (
                                                    <OpportunityQuestionForm
                                                        formikBag={formikBag}
                                                        setPath={setPath}
                                                        path={path}
                                                        setIsValid={setIsValid}
                                                        setObjectPath={setObjectPath}
                                                    />
                                                )}
                                                {isAdvice() && (
                                                    <OpportunityAdviceForm
                                                        formikBag={formikBag}
                                                        setPath={setPath}
                                                        path={path}
                                                        setIsValid={setIsValid}
                                                        setObjectPath={setObjectPath}
                                                    />
                                                )}
                                            </Form>
                                            <div className="QuestionSetForm__actions">
                                                {isMaster() && (
                                                    <Button
                                                        onClick={onNavigateToList}
                                                        className="button button-cancel"
                                                    >
                                                        {translate('OpportunityQ.QuestionsPage.ButtonText')}
                                                    </Button>
                                                )}
                                                {!isMaster() && (
                                                    <>
                                                        <Button
                                                            onClick={onNavigateBack}
                                                            className="button button-cancel"
                                                        >
                                                            {translate('Globals.Label.Back')}
                                                        </Button>
                                                        <ButtonWithConfirmDialog
                                                            onConfirm={() => onDelete(formikBag)}
                                                            buttonType={'archive'}
                                                            buttonText={`${translate('OpportunityQ.QuestionsPage.ConfirmDialog.ButtonText')} ${entity}`}
                                                            message={`${translate('OpportunityQ.QuestionsPage.ConfirmDialog.Message')} ${entity}`}
                                                            title={translate('OpportunityQ.QuestionsPage.ConfirmDialog.Title')}
                                                        />
                                                    </>
                                                )}
                                                {isMaster() && !isNew && (
                                                    <ButtonWithConfirmDialog
                                                        onConfirm={onArchive}
                                                        buttonType={'archive'}
                                                        buttonText={translate('Globals.Label.Archive')}
                                                        message={translate('OpportunityQ.QuestionsPage.ConfirmDialog2.Message')}
                                                        title={translate('OpportunityQ.QuestionsPage.ConfirmDialog.Title')}
                                                    />
                                                )}
                                                <div className="QuestionSetForm__actions">
                                                    {!isAdvice() && (
                                                        <>
                                                            <Button
                                                                disabled={!isValid}
                                                                onClick={onNavigateToQuestion}
                                                            >
                                                                {translate('QuestionForm.Lable.10')}
                                                            </Button>
                                                            <Button
                                                                disabled={!isValid}
                                                                onClick={onNavigateToAdvice}
                                                            >
                                                                {translate('OpportunityQ.QuestionsPage.AddAdvice')}
                                                            </Button>
                                                        </>
                                                    )}
                                                    <Button
                                                        disabled={!isValid}
                                                        onClick={() => submitForm(formikBag)}
                                                    >
                                                        {translate('Globals.Label.SaveAsDraft')}
                                                    </Button>
                                                    <Button
                                                        disabled={!isValid}
                                                        onClick={() => submitForm(formikBag, false)}
                                                    >
                                                        {translate('Globals.Label.Publish')}
                                                    </Button>
                                                </div>
                                            </div>
                                        </ArticlePanel>
                                    </div>
                                )}
                            />
                        </Loader>
                    </div>
                </div>
            )}
        </Page>
    );
};

export default OpportunityPage;
