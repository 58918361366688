import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { IRootActionsState } from 'reducers/actions';
import { getFilteredLookupSitePeopleWithAllowedRoles } from 'sharedSelectors';
import { checkPermission } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';

const getSiteId = (state: IRootState) => state.app.siteId;
const getIsLoading = (state: IRootState) => state.actions.grid.isLoading;
const getRiskActions = (state: IRootState) => state.riskRegisterState.actions;
const getMacroActions = (state: IRootState) => state.actionRegisterState.macroActions;
const getLookupActionCategories = (state: IRootState) => state.app.lookupActionCategories;
const getLookupActionPriorities = (state: IRootState) => state.app.lookupActionPriorities;
const getLookupActionStatuses = (state: IRootState) => state.app.lookupActionStatus;
const getLookupSites = (state: IRootState) => state.app.lookupSites;
const getActionAutoFieldUpdatePermission = (state: IRootState) =>
    checkPermission(Privilege.ActionAutoFieldUpdate, state.app.permissions);
const getIsUpsertModalShown = (state: IRootActionsState) =>
    state.actions.forms.showUpsertActionModal;
const getUpsertRequest = (state: IRootActionsState) => state.actions.forms.upsertItem;

const getLookups = createSelector(
    getLookupActionCategories,
    getLookupActionPriorities,
    getLookupActionStatuses,
    getLookupSites,
    getFilteredLookupSitePeopleWithAllowedRoles,
    (
        lookupActionCategories,
        lookupActionPriorities,
        lookupActionStatuses,
        lookupSites,
        lookupSitePeopleRoles
    ) => ({
        lookupActionCategories,
        lookupActionPriorities,
        lookupActionStatuses,
        lookupSites,
        lookupSitePeopleRoles,
    })
);

export default createSelector(
    getSiteId,
    getIsLoading,
    getRiskActions,
    getMacroActions,
    getLookups,
    getIsUpsertModalShown,
    getUpsertRequest,
    getActionAutoFieldUpdatePermission,
    (
        siteId,
        isLoading,
        riskActions,
        macroActions,
        lookups,
        isUpsertModalShown,
        upsertRequest,
        actionAutoFieldUpdatePermission
    ) => ({
        siteId,
        isLoading,
        riskActions,
        macroActions,
        lookups,
        isUpsertModalShown,
        upsertRequest,
        actionAutoFieldUpdatePermission,
    })
);
