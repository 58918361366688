import * as React from 'react';

import { FileUploadModal } from '../../../../components/fileUploadModal';

export interface IStateProps {
    isVisible: boolean;
    isModalVisible: boolean;
    isUploading: boolean;
}

export interface IDispatchProps {
    onConfirm: (riskId: string, siteId: string, files: File[]) => void;
    hideModal: (riskId: string, siteId: string) => void;
}

export interface IOwnProps {
    siteId: string;
    riskId: string;
}

interface IProps extends IStateProps, IDispatchProps, IOwnProps { }

export const RiskAddAttachmentModal: React.FC<IProps> = (props) => {
    if (!props.isVisible) {
        return null;
    }

    const hideModal = () => props.hideModal(props.riskId, props.siteId);

    const onConfirm = (files: File[]) => props.onConfirm(
        props.riskId,
        props.siteId,
        files);

    return (
        <>
            <FileUploadModal
                visible={props.isModalVisible}
                uploading={props.isUploading}
                onConfirm={onConfirm}
                onClose={hideModal}
            />
        </>
    );
};

RiskAddAttachmentModal.defaultProps = {
    isModalVisible: false
};
