import React from 'react';
/* import Page from 'components/v2/page/Page'; */
import {
    Panel,
    Table,
    SortOrder,
    Button,
    Toolbar,
    ToolbarGroup,
    ToolbarGroupRight,
    FloatingButton,
    ConfirmDialog,
    TableHeaders,
    ButtonWithConfirmDialog,
} from 'components/v2/components';
import {
    IGlobalMockDrill,
    IUpdateGlobalMockDrill,
    ICreateGlobalMockDrill,
    mockDrillScenarioType,
} from 'models/mock-drills';
import { FormikProps, Formik, FormikActions } from 'formik';
import { TextField } from 'components/form/fields/text-field';
import { TextAreaField } from 'components/form/fields/textarea-field';
import * as Yup from 'yup';
import { SelectField, IOption } from 'components/form/fields/select-field';
import { IKeyValue } from 'models';
import { Loader } from 'components/loader';
import { Checkbox } from 'components/form/Checkbox';
import { RouteComponentProps } from 'react-router-dom';
import { history } from 'routes/App';
import { UiPreferences } from 'utils/page-ui-preferences-utils';
import { sortArrayBy } from 'utils/sort-utils';
import { isEmpty, difference } from 'lodash';
import { getRegions } from 'enums/Regions';
import { ISiteInfo } from 'models/site-profile';
import { useTranslate } from 'translations/translation-utils';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import { IAttachmentQuery } from 'models/attachment/attachment-query';
import { IAttachmentUpload } from 'models/attachment/attachment-upload';
import { ICommonLookupModel } from 'models/common-lookup-model';
import { IPerson } from 'models/person';
import { getUserAccessOperatingPlatform } from 'helpers/helpers';

interface IRouteParams {
    id: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
    isLoading: boolean;
    isSaving: boolean;
    isLocked: boolean;
    clients: Array<IKeyValue<string>>;
    availableSites: ISiteInfo[];
    globalMockDrill: IGlobalMockDrill;
    attachments: IAttachmentModel[];
    userCanDeleteGlobalMockDrillsScenario: boolean;
    loadGlobalMockDrill: (id: string) => void;
    createGlobalMockDrill: (model: ICreateGlobalMockDrill) => void;
    updateGlobalMockDrill: (model: IUpdateGlobalMockDrill) => void;
    deleteGlobalMockDrill: (id: string, reloadUrl: string) => void;
    cleanupGlobalMockDrill: () => void;
    loadAttachments: (query: IAttachmentQuery) => void;
    uploadAttachment: (upload: IAttachmentUpload) => void;
    lineOfBusiness: ICommonLookupModel[];
    canEditGlobalMockDrillContent: boolean;
    personProfile: IPerson;
}

export const GlobalMockdrillDetailsTab: React.FC<IProps> = ({
    isLoading,
    isSaving,
    clients,
    availableSites,
    globalMockDrill,
    userCanDeleteGlobalMockDrillsScenario,
    createGlobalMockDrill,
    updateGlobalMockDrill,
    deleteGlobalMockDrill,
    lineOfBusiness,
    canEditGlobalMockDrillContent,
    personProfile
}) => {
    const globalMockDrillsPageUrl = '/GlobalMockDrills';
    // dropdown boxes
    const [selectedOperatingPlatform, setSelectedOperatingPlatform] = React.useState(null);
    const [selectedLineOfBusiness, setSelectedLineOfBusiness] = React.useState(null);
    const [selectedRegions, setSelectedRegions] = React.useState(null);
    const [selectedClient, setSelectedClient] = React.useState(null);

    const operatingPlatformSelected = selectedOperatingPlatform ? selectedOperatingPlatform.value : getUserAccessOperatingPlatform(personProfile);
    const [sortedSites, setSortedSites] = React.useState(availableSites);

    // checkboxes
    const [selectedSites, setSelectedSites] = React.useState<string[]>([]);
    const [selectAllSites, setSelectAllSites] = React.useState(false);

    const [shouldBlockNavigation, setShouldBlockNavigation] = React.useState(false);
    const [dialogIsVisible, setDialogIsVisible] = React.useState(false);
    const [submitPayload, setSubmitPayload] = React.useState(null);
    const regions = getRegions;

    const translate = useTranslate();

    React.useEffect(() => {
        if (!globalMockDrill?.sites) {
            setSelectedSites([]);
        } else {
            setSelectedSites(globalMockDrill.sites);
        }
    }, [globalMockDrill]);

    const validationSchema = Yup.object<Partial<IGlobalMockDrill>>().shape({
        name: Yup.string().trim().required(translate('Validations.ScenarioTitle')),
    });

    const renderForm = (formikBag: FormikProps<Partial<IGlobalMockDrill>>) => {
        const handleScenarioTypeChange = (field: { value: string; label: string }) => {
            formikBag.setFieldValue('scenarioType', field.value);
        };
        return formikBag.values ? (
            <form id="globalMockDrillForm" onSubmit={formikBag.handleSubmit}>
                <SelectField
                    id="scenarioType"
                    label={translate('MockDrills.GlobalUpsertPage.Labels.ScenarioType')}
                    error={formikBag.errors.scenarioType}
                    value={{
                        label: formikBag.values.scenarioType ?? mockDrillScenarioType[0].label,
                        value: formikBag.values.scenarioType ?? mockDrillScenarioType[0].value,
                    }}
                    options={mockDrillScenarioType.map((c) => ({
                        label: c.label,
                        value: translate(
                            'MockDrills.ScenarioType.'.concat(c.value.replace(/\s/g, ''))
                        ),
                    }))}
                    handleChange={handleScenarioTypeChange}
                    handleBlur={formikBag.handleBlur}
                    isDisabled={!canEditGlobalMockDrillContent}
                />
                <TextField
                    id="name"
                    label={`${translate('GlobalMockDrills.ScenarioTitle')} ${'*'}`}
                    value={formikBag.values.name ? formikBag.values.name : ''}
                    handleChange={formikBag.handleChange}
                    error={formikBag.errors.name}
                    setVal={formikBag.setFieldValue}
                    isDisabled={!canEditGlobalMockDrillContent}
                />
                <TextAreaField
                    id="description"
                    label={translate('GlobalMockDrills.ScenarioDes')}
                    value={formikBag.values.description ? formikBag.values.description : ''}
                    handleChange={formikBag.handleChange}
                    error={formikBag.errors.description}
                    setVal={formikBag.setFieldValue}
                    isDisabled={!canEditGlobalMockDrillContent}
                />
            </form>
        ) : null;
    };

    const onSubmit = (data: IGlobalMockDrill) => {
        const sitesToAdd = globalMockDrill
            ? difference(selectedSites, globalMockDrill.sites)
            : selectedSites;
        const sitesToRemove = globalMockDrill
            ? difference(globalMockDrill.sites, selectedSites)
            : [];

        setSubmitPayload({
            id: data.id,
            scenarioType: data.scenarioType ?? mockDrillScenarioType[0].value,
            name: data.name,
            description: data.description,
            sitesToAdd,
            sitesToRemove,
        });
        setDialogIsVisible(true);
    };

    const onConfirmButtonHandler = () => {
        if (!submitPayload) {
            return;
        }

        if (submitPayload.id) {
            UpdateGlobalMockDrill(submitPayload);
            return;
        }
        CreateGlobalMockDrill(submitPayload);
    };

    const CreateGlobalMockDrill = (data) => {
        const payload = {
            scenarioType: data.scenarioType ?? mockDrillScenarioType[0].value,
            name: data.name,
            description: data.description,
            sitesToAdd: data.sitesToAdd,
            sitesToRemove: [],
            reloadUrl: globalMockDrillsPageUrl,
        } as ICreateGlobalMockDrill;
        createGlobalMockDrill(payload);
    };

    const UpdateGlobalMockDrill = (data) => {
        const payload = {
            id: data.id,
            scenarioType: data.scenarioType,
            name: data.name,
            description: data.description,
            sitesToAdd: data.sitesToAdd,
            sitesToRemove: data.sitesToRemove,
            reloadUrl: globalMockDrillsPageUrl,
        } as IUpdateGlobalMockDrill;
        updateGlobalMockDrill(payload);
    };

    const onCancelButtonHandler = () => {
        history.push(globalMockDrillsPageUrl);
    };

    const handleOperatingPlatformChange = (value: IOption[]) => {
        setSelectedOperatingPlatform(value && value.length === 0 ? null : value);
    };

    const handleLineOfBusinessChange = (value: IOption[]) => {
        setSelectedLineOfBusiness(value && value.length === 0 ? null : value);
    };

    const handleRegionChange = (value: IOption[]) => {
        setSelectedRegions(value && value.length === 0 ? null : value);
    };

    const handleClientChange = (value: IOption[]) => {
        setSelectedClient(value && value.length === 0 ? null : value);
    };

    const onToggleHandler = (field: string, sortOrder: SortOrder) => {
        if (sortOrder === SortOrder.off) {
            setSortedSites(availableSites);
        }

        setSortedSites(sortArrayBy(field, availableSites, sortOrder === SortOrder.asc));
    };

    const onCheckboxClickHandler = (value) => {
        const id = value.target.id;
        if (selectedSites.includes(id)) {
            // remove from selected
            setSelectedSites(selectedSites.filter((x) => x !== id));
        } else {
            // add to selected
            setSelectedSites([...selectedSites, id]);
        }

        setShouldBlockNavigation(true);
    };

    const onSelectAllCheckboxClickHandler = () => {
        const filteredSites = getFilteredSitesArray(availableSites)
            .map((x) => x.key);
        if (selectAllSites) {
            // if previous state is true - reset the list
            setSelectedSites(selectedSites.filter(x => !filteredSites.includes(x)));
        } else {
            const sites = filteredSites.map(x => x);
            sites.push(...selectedSites);
            setSelectedSites(sites.filter((n, i) => sites.indexOf(n) === i));
        }
        setSelectAllSites(!selectAllSites);
        setShouldBlockNavigation(true);
    };
    const getFilteredSitesArray = (sitesArray: ISiteInfo[]) => {
        let filteredArray = [...sitesArray];
        if (selectedOperatingPlatform && selectedOperatingPlatform.value) {
            filteredArray = filteredArray.filter((x) =>
                selectedOperatingPlatform.value === x.operatingPlatform);
        }
        if (selectedLineOfBusiness) {
            filteredArray = filteredArray.filter((x) =>
                selectedLineOfBusiness.some((i) => i.value === x.lineOfBusiness)
            );
        }
        if (selectedRegions) {
            filteredArray = filteredArray.filter((x) =>
                selectedRegions.some((i) => i.value === x.region)
            );
        }

        if (selectedClient) {
            filteredArray = filteredArray.filter((x) =>
                selectedClient.some((i) => i.value === x.clientName)
            );
        }

        return filteredArray;
    };

    const buildSitesTable = () => {
        if (isEmpty(sortedSites)) {
            return (
                <div className="no-data">
                    <h2>{translate('SiteOverview.Lable.2')}</h2>
                </div>
            );
        }

        return (
            <Table>
                <TableHeaders
                    headers={[
                        {
                            title: translate('Cerm.SiteSelect.Cloumns.SiteName'),
                            sortField: 'value',
                        },
                        {
                            title: translate('UserSelector.Labels.OperatingPlatform'),
                            sortField: 'value',
                        },
                        {
                            title: 'LOB',
                            sortField: 'lineOfBusiness',
                        },
                        {
                            title: translate('GainAccess.Labels.Regions'),
                            sortField: 'region',
                        },
                        {
                            title: translate('LinkedBuildings.Country'),
                            sortField: 'country',
                        },
                        {
                            title: translate('Cerm.SiteSelect.Cloumns.Include'),
                        },
                    ]}
                    sortFieldUiPreference={UiPreferences.GlobalMockDrillPageSiteListSortField}
                    sortOrderUiPreference={UiPreferences.GlobalMockDrillPageSiteListSortOrder}
                    onToggle={onToggleHandler}
                />
                <tbody>
                    <tr>
                        <th colSpan={5} className="includeRow">
                            {translate('GlobalMockDrills.Include')}
                        </th>
                        <th className="includeRowCheckbox">
                            <Checkbox
                                id="selectAllCheckbox"
                                checked={selectAllSites}
                                onChange={onSelectAllCheckboxClickHandler}
                            />
                        </th>
                    </tr>
                    {getFilteredSitesArray(sortedSites).map((item: ISiteInfo) => (
                        <tr key={item.key}>
                            <td>{item.value}</td>
                            <td>{item.operatingPlatform}</td>
                            <td>{item.lineOfBusiness}</td>
                            <td>{item.region}</td>
                            <td>{item.country}</td>
                            <td>
                                <Checkbox
                                    id={item.key}
                                    checked={selectedSites && selectedSites.includes(item.key)}
                                    onChange={onCheckboxClickHandler}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    };

    const getDialogMessage = (
        <p>
            {translate('GlobalMockDrills.Msg1')}
            <br />
            {translate('GlobalMockDrills.Msg2')}
        </p>
    );

    const onArchive = () => {
        deleteGlobalMockDrill(globalMockDrill.id, globalMockDrillsPageUrl);
    };

    const archiveButton = () => {
        return globalMockDrill?.id && (
            <ButtonWithConfirmDialog
                onConfirm={onArchive}
                buttonType={'archive'}
                buttonText={translate('MockDrills.GlobalUpsertPage.Button.Archive')}
                message={translate('MockDrills.GlobalUpsertPage.Archive.Message')}
                title={translate('MockDrills.GlobalUpsertPage.Archive.Title')}
                isButtonDisabled={!userCanDeleteGlobalMockDrillsScenario}
                isLoading={isLoading}
            />
        );
    };

    const getLinesOfBusiness = () => {
        if (personProfile.accessRights && personProfile.accessRights.operatingPlatforms.length <= 1) {
            return lineOfBusiness.filter(x => x.operatingPlatform === personProfile.accessRights.operatingPlatforms[0]);
        }
        const selectedOperatingPlatformValue = selectedOperatingPlatform && lineOfBusiness.filter(x => x.operatingPlatform === selectedOperatingPlatform.value);
        return selectedOperatingPlatformValue;
    };

    return (
        <>
            <Loader loading={isLoading}>
                <Panel title={translate('GlobalMockDrills.ScenarioDetails')} bodyPadding={true}>
                    <Formik
                        initialValues={globalMockDrill}
                        validationSchema={validationSchema}
                        onSubmit={(
                            data: IGlobalMockDrill,
                            formikActions: FormikActions<IGlobalMockDrill>
                        ) => {
                            formikActions.setSubmitting(true);
                            onSubmit(data);
                            formikActions.setSubmitting(false);
                        }}
                        render={renderForm}
                        enableReinitialize={true}
                    />
                </Panel>
                <Panel
                    title={translate('GlobalMockDrills.Title.1')}
                    bodyPadding={true}
                    collapsed={false}
                >
                    <div className="columns">
                        {personProfile.accessRights.operatingPlatforms.length > 1 ?
                            <div className="column">
                                <SelectField
                                    isMulti={false}
                                    id="operatingPlatformId"
                                    label={translate('UserSelector.Labels.OperatingPlatform')}
                                    value={selectedOperatingPlatform}
                                    options={personProfile.accessRights &&
                                        personProfile.accessRights.operatingPlatforms &&
                                        personProfile.accessRights.operatingPlatforms.map((c) => ({
                                            value: c,
                                            label: c,
                                        })).sort((a, b) => a.label.localeCompare(b.label))}
                                    handleChange={handleOperatingPlatformChange}
                                />
                            </div> :
                            <div className="column">
                                <div className="field">
                                    <label className="label">{translate('UserSelector.Labels.OperatingPlatform')}</label>
                                    <div className="control">{operatingPlatformSelected}</div>
                                </div>
                            </div>}
                        <div className="column">
                            <SelectField
                                isMulti={true}
                                id="lineOfBusinessId"
                                label={translate('Cerm.GlobalOverviewFilters.Labels.LineOfBusiness')}
                                value={selectedLineOfBusiness}
                                options={getLinesOfBusiness() && getLinesOfBusiness().map((c) => ({
                                    value: c.value,
                                    label: c.value,
                                }))}
                                handleChange={handleLineOfBusinessChange}
                            />
                        </div>
                        <div className="column">
                            <SelectField
                                isMulti={true}
                                id="regionId"
                                label={translate('GainAccess.Labels.Regions')}
                                value={selectedRegions}
                                options={regions.map((c) => ({
                                    value: c.key,
                                    label: c.value,
                                }))}
                                handleChange={handleRegionChange}
                            />
                        </div>
                        <div className="column">
                            <SelectField
                                isMulti={true}
                                id="clientId"
                                label={translate('Globals.Category.dropdown.Clients')}
                                value={selectedClient}
                                options={clients.map((c) => ({
                                    value: c.key,
                                    label: c.value,
                                }))}
                                handleChange={handleClientChange}
                            />
                        </div>
                    </div>
                    {buildSitesTable()}
                </Panel>
            </Loader>
            <Toolbar type="save">
                <ToolbarGroup>
                    <Button buttonType="cancel" onClick={onCancelButtonHandler}>
                        {translate('Globals.Label.Cancel')}
                    </Button>
                    {archiveButton()}
                </ToolbarGroup>
                <ToolbarGroupRight>
                    <FloatingButton
                        type="submit"
                        form="globalMockDrillForm"
                        float={shouldBlockNavigation}
                        tooltip={translate('Globals.Label.PleaseClick')}
                        isLoading={isLoading || isSaving}
                        disabled={isSaving}
                    >
                        {translate('Globals.Label.Save')}
                    </FloatingButton>
                </ToolbarGroupRight>
            </Toolbar>
            <ConfirmDialog
                title="Information"
                message={getDialogMessage}
                onConfirm={onConfirmButtonHandler}
                onClose={() => setDialogIsVisible(false)}
                onOutsideDialogClick={() => setDialogIsVisible(false)}
                isVisible={dialogIsVisible}
                isLoading={isSaving}
            />
        </>
    );
};
