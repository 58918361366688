import { createSelector } from 'reselect';
import { IRootRisksState } from '../../../../reducers/risks';
import { IRootState } from 'routes/store';
import { getFilteredLookupSitePeopleWithAllowedRoles } from 'sharedSelectors';
import * as RiskStatus from 'models/risk-statuses';

const getIsShown = (state: IRootState) => {
    return state.risks.risksForm.showUpsertRiskModal;
};

const getLookupRiskCategories = (state: IRootState) => {
    return state.app.lookupRiskCategories;
};

const getLookupRiskLevels = (state: IRootState) => {
    return state.app.lookupRiskLevel;
};

const getUpsertRequest = (state: IRootState) => {
    return state.risks.risksForm.upsertItem;
};

// When creating a new RISK we must filter out non-relevant 'Closed'status
const getLookupRiskStatuses = createSelector(
    (state: IRootState) => state.app.lookupRiskStatus,
    getUpsertRequest,
    (lookupRiskStatus, upsertRiskRequest) => {
        if (!upsertRiskRequest || !upsertRiskRequest.id) {
            return lookupRiskStatus.filter((x) => x.key !== RiskStatus.CLOSED);
        }

        return lookupRiskStatus;
    }
);

const getLookupRiskLikelihoods = (state: IRootRisksState) => {
    return state.app.lookupRiskLikelihoods;
};

const getLookupRiskImpacts = (state: IRootRisksState) => {
    return state.app.lookupRiskImpacts;
};

const getLookupSites = (state: IRootRisksState) => {
    return state.app.lookupSites;
};

const getSite = (state: IRootState) => state.siteState.site;

export default createSelector(
    getIsShown,
    getLookupRiskCategories,
    getLookupRiskLevels,
    getLookupRiskStatuses,
    getLookupRiskLikelihoods,
    getLookupRiskImpacts,
    getFilteredLookupSitePeopleWithAllowedRoles,
    getLookupSites,
    getUpsertRequest,
    getSite,
    (
        isShown,
        lookupRiskCategories,
        lookupRiskLevels,
        lookupRiskStatus,
        lookupRiskLikelihoods,
        lookupRiskImpacts,
        lookupSitePeopleRoles,
        lookupSites,
        upsertRiskRequest,
        site
    ) => ({
        isShown,
        lookupRiskCategories,
        lookupRiskLevels,
        lookupRiskStatus,
        lookupRiskLikelihoods,
        lookupRiskImpacts,
        lookupSitePeopleRoles,
        lookupSites,
        upsertRiskRequest,
        site,
    })
);
