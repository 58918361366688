import * as React from 'react';
import { IconToolbar, IToolbarIconItem, ToolbarIconType } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';

export interface IActionProps {
    isFilterExists: boolean;
    onShowFilters: () => void;
    onClearFilters: () => void;
    isLoading: boolean;
}

export const CompetencyDnaProcessAdminToolbar: React.FC<IActionProps> = ({
    isFilterExists,
    onShowFilters,
    onClearFilters,
    isLoading,
}) => {
    const translate = useTranslate();
    const getToolbarItems = (): IToolbarIconItem[] => {
        return [
            {
                id: 'clear-all-button',
                title: translate('Globals.Label.ClearFilters'),
                type: isFilterExists ? ToolbarIconType.filterApplied : ToolbarIconType.clearFilter,
                isDisabled: isLoading,
                onClick: onClearFilters,
            },
            {
                id: 'show-filters-button',
                title: translate('Globals.Label.ShowFilters'),
                type: ToolbarIconType.filter,
                isDisabled: isLoading,
                onClick: onShowFilters,
            }
        ];
    };

    return <IconToolbar items={getToolbarItems()} />;
};
