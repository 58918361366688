import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { getSitesWithFeatures, getPermissions } from 'sharedSelectors';

const getSiteId = (state: IRootState) => state.app.siteId;
const getIsLoading = (state: IRootState) =>
    state.riskRegisterState.isLoading || state.lookups.isLoading;
const getRisk = (state: IRootState) => state.riskRegisterState.risk;
const getPersonLookups = (state: IRootState) => state.lookups.personLookups;
const getExpertReviewStatus = (state: IRootState) =>
    state.cermAssessmentResultState.expertReviewStatus;
const getAttachments = (state: IRootState) => state.riskRegisterState.attachments;
const getSubmitActionToRisk = (state: IRootState) => state.riskRegisterState.submitActionToRisk;

export default createSelector(
    getSiteId,
    getIsLoading,
    getRisk,
    getPersonLookups,
    getSitesWithFeatures,
    getExpertReviewStatus,
    getPermissions,
    getAttachments,
    getSubmitActionToRisk,
    (
        siteId,
        isLoading,
        risk,
        users,
        lookupSiteFeatures,
        expertReviewStatus,
        permissions,
        attachments,
        submitActionToRisk
    ) => ({
        siteId,
        isLoading,
        risk: { ...risk, siteId },
        users,
        lookupSiteFeatures,
        expertReviewStatus,
        permissions,
        attachments,
        submitActionToRisk,
    })
);
