import { createSelector } from 'reselect';

import { IAuditItemValue } from '../../../../components/auditHistory';
import { IAction } from '../../../../models/action';
import { IKeyedItem } from '../../../../models/keyed-item';
import { IRootActionsState } from '../../../../reducers/actions';
import { getKey } from '../../../../utils/id-utils';

const getIsExpanded = (state: IRootActionsState, props: { item: IKeyedItem }) =>
    state.actions.grid.expandedHistoryItems.hasOwnProperty(props.item.id);

const getIsLoading = (state: IRootActionsState, props: { item: IKeyedItem }) => {
    const match = state.actions.auditHistories[getKey(props.item)];
    return Boolean(match && match.isLoading);
};

const getErrorMessage = (state: IRootActionsState, props: { item: IKeyedItem }) => {
    const match = state.actions.auditHistories[getKey(props.item)];
    return (match && match.errorMessage);
};

const getAuditHistory = (state: IRootActionsState, props: { item: IKeyedItem }) => {
    const match = state.actions.auditHistories[getKey(props.item)];
    return (match && match.data) || [];
};

const getPage = (state: IRootActionsState, props: { item: IKeyedItem }) => {
    const match = state.actions.auditHistories[getKey(props.item)];
    return (match && match.page);
};

const getPageSize = (state: IRootActionsState, props: { item: IKeyedItem }) => {
    const match = state.actions.auditHistories[getKey(props.item)];
    return (match && match.pageSize);
};

const fields: Array<IAuditItemValue<IAction>> = [
    { label: 'Update By', value: (action) => action.lastUpdatedByPersonId },
    { label: 'Status', value: (action) => action.status }
];

export default () => createSelector(
    getIsExpanded,
    getIsLoading,
    getErrorMessage,
    getAuditHistory,
    getPage,
    getPageSize,
    (isExpanded, isLoading, errorMessage, data, page, pageSize) => ({
        isExpanded,
        isLoading,
        errorMessage,
        data,
        fields,
        page,
        pageSize
    })
);
