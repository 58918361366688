import { IAppAction } from 'actions/app-action';
import { combineReducers } from 'redux';
import { IFaqsState } from 'state/faqs-state';
import { IFaqsActions } from 'actions/actions-v2/faqs-action-v2';
import { IFaqs } from 'models/faqs-model';

const INITIAL_STATE: IFaqsState = {
    isLoading: false,
    faqsContent: {} as IFaqs,
};

const isLoading = (
    state: IFaqsState['isLoading'] = INITIAL_STATE.isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case IFaqsActions.LOAD_FAQS:
        case IFaqsActions.UPDATE_FAQS:
        case IFaqsActions.CREATE_FAQS:
            return true;
        case IFaqsActions.LOAD_FAQS_FULFILLED:
        case IFaqsActions.LOAD_FAQS_REJECTED:
        case IFaqsActions.UPDATE_FAQS_FULFILLED:
        case IFaqsActions.UPDATE_FAQS_REJECTED:
        case IFaqsActions.CREATE_FAQS_FULFILLED:
        case IFaqsActions.CREATE_FAQS_REJECTED:
            return false;

        default:
            return state;
    }
};

const faqsContent = (
    state: IFaqsState['faqsContent'] = INITIAL_STATE.faqsContent,
    action: IAppAction
) => {
    switch (action.type) {
        case IFaqsActions.LOAD_FAQS_FULFILLED:
        case IFaqsActions.UPDATE_FAQS_FULFILLED:
        case IFaqsActions.CREATE_FAQS_FULFILLED:
            if (action.payload) {
                return action.payload;
            }

        default:
            return state;
    }
};

export const IFaqsReducer = combineReducers<IFaqsState>({
    isLoading,
    faqsContent,
});
