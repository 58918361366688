/* eslint-disable @typescript-eslint/no-inferrable-types */
export const DEFAULT_CRUMB_DELIMETER = '/';
export const DEFAULT_OVERFLOW_TEXT = '...';
export const DEFAULT_MAX_INLINE_CRUMBS = 3;
export const DEFAULT_OVERFLOW_CRUMB_POSITION = 2;
export const OVERFLOW_ICON_COLOR = '#003f2d';

export type EmeraldBreadcrumbLinkType = 'Path' | 'Url' | 'None';

export enum EmeraldBreadcrumbLink {
  Path = 'Path',
  Url = 'Url',
  None = 'None',
}

export interface ICrumb {
  id?: string;
  label: string;
  icon?: string;
  title?: string;
  link?: string;
  linkType?: EmeraldBreadcrumbLink | EmeraldBreadcrumbLinkType;
  position?: number;
}

export class Crumb implements ICrumb {
  id?: string;
  label!: string;
  icon?: string;
  title?: string;
  link?: string;
  linkType?: EmeraldBreadcrumbLink | EmeraldBreadcrumbLinkType;
  position?: number;
}

export interface IEmeraldBreadcrumbCoreInputs {
  crumbs: Crumb[];
  delimeter?: string;
  delimeterIcon?: string;
  overflowText?: string;
  classes?: string;
  onClick?: any;
}

export class EmeraldBreadcrumbCoreInputs
  implements IEmeraldBreadcrumbCoreInputs
{
  crumbs: Crumb[] = [];
  delimeter?: string = DEFAULT_CRUMB_DELIMETER;
  delimeterIcon?: string = 'chevron_right';
  overflowText?: string;
  classes?: string;
  onClick?: any;
}
