import { IAppAction } from 'actions/app-action';
import { combineReducers } from 'redux';
import { s2ab } from 'utils/export-utils';
import { IMockDrillReportState } from 'state/mock-drills-state';
import { MockDrillReportActions } from 'actions/actions-v2/mock-drill-report-actions';
import { AttachmentActions } from 'actions/actions-v2/attachment-action-v2';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import { ActionActions } from 'actions/actions-v2';
import { IAction } from 'models/action';
import { IMockDrillReport, MockDrillReportStatus } from 'models/mock-drills';
import saveAs from 'file-saver';

const INITIAL_STATE: IMockDrillReportState = {
    isLoading: false,
    isExporting: false,
    isCreated: false,
    mockDrillReportsSummary: null,
    mockDrillReport: {
        dateOfDrill: new Date(),
        status: MockDrillReportStatus.Draft
    } as IMockDrillReport,
    attachments: [],
    history: [],
    actions: []
};

const isLoading = (
    state: IMockDrillReportState['isLoading'] = INITIAL_STATE.isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case MockDrillReportActions.LOAD:
        case MockDrillReportActions.LOAD_REPORTS_SUMMARY:
        case MockDrillReportActions.LOAD_ACTIONS:
        case ActionActions.CREATE_ACTION_FOR_MOCK_DRILL_REPORT:
        case ActionActions.UPDATE_ACTION_FOR_MOCK_DRILL_REPORT:
        case ActionActions.ARCHIVE_ACTION_FOR_MOCK_DRILL_REPORT:
        case MockDrillReportActions.CREATE:
        case MockDrillReportActions.UPDATE:
        case MockDrillReportActions.DELETE:
        case MockDrillReportActions.SET_PARTICIPATION:
            return true;

        case MockDrillReportActions.LOAD_FULFILLED:
        case MockDrillReportActions.LOAD_REJECTED:
        case MockDrillReportActions.LOAD_REPORTS_SUMMARY_FULFILLED:
        case MockDrillReportActions.LOAD_REPORTS_SUMMARY_REJECTED:
        case MockDrillReportActions.LOAD_ACTIONS_FULFILLED:
        case MockDrillReportActions.LOAD_ACTIONS_REJECTED:
        case ActionActions.CREATE_ACTION_FOR_MOCK_DRILL_REPORT_FULFILLED:
        case ActionActions.CREATE_ACTION_FOR_MOCK_DRILL_REPORT_REJECTED:
        case ActionActions.UPDATE_ACTION_FOR_MOCK_DRILL_REPORT_FULFILLED:
        case ActionActions.UPDATE_ACTION_FOR_MOCK_DRILL_REPORT_REJECTED:
        case ActionActions.ARCHIVE_ACTION_FOR_MOCK_DRILL_REPORT_FULFILLED:
        case ActionActions.ARCHIVE_ACTION_FOR_MOCK_DRILL_REPORT_REJECTED:
        case MockDrillReportActions.CREATE_FULFILLED:
        case MockDrillReportActions.CREATE_REJECTED:
        case MockDrillReportActions.UPDATE_FULFILLED:
        case MockDrillReportActions.UPDATE_REJECTED:
        case MockDrillReportActions.DELETE_FULFILLED:
        case MockDrillReportActions.DELETE_REJECTED:
        case MockDrillReportActions.SET_PARTICIPATION_FULFILLED:
        case MockDrillReportActions.SET_PARTICIPATION_REJECTED:
            return false;

        default:
            return state;
    }
};

const isExporting = (
    state: IMockDrillReportState['isExporting'] = INITIAL_STATE.isExporting,
    action: IAppAction
) => {
    switch (action.type) {
        case MockDrillReportActions.EXPORT:
            return true;

        case MockDrillReportActions.EXPORT_FULFILLED:
            const blob = new Blob([s2ab(atob(action.payload.fileContents))], {
                type: 'application/excel'
            });
            saveAs(blob, action.payload.fileDownloadName);
            return false;

        case MockDrillReportActions.EXPORT_REJECTED:
            return false;

        default:
            return state;
    }
};

const isCreated = (
    state: IMockDrillReportState['isCreated'] = INITIAL_STATE.isCreated,
    action: IAppAction
) => {
    switch (action.type) {
        case MockDrillReportActions.CREATE_FULFILLED:
            return true;

        case MockDrillReportActions.CLEANUP:
            return INITIAL_STATE.isCreated;

        default:
            return state;
    }
};

const mockDrillReportsSummary = (
    state: IMockDrillReportState['mockDrillReportsSummary'] = INITIAL_STATE.mockDrillReportsSummary,
    action: IAppAction
) => {
    switch (action.type) {
        case MockDrillReportActions.LOAD_REPORTS_SUMMARY_FULFILLED:
            if (action.payload) {
                return action.payload;
            }

        case MockDrillReportActions.CLEANUP_LIST:
            return INITIAL_STATE.mockDrillReportsSummary;

        default:
            return state;
    }
};

const mockDrillReport = (
    state: IMockDrillReportState['mockDrillReport'] = INITIAL_STATE.mockDrillReport,
    action: IAppAction
) => {
    switch (action.type) {
        case MockDrillReportActions.LOAD_FULFILLED:
        case MockDrillReportActions.CREATE_FULFILLED:
        case MockDrillReportActions.UPDATE_FULFILLED:
        case MockDrillReportActions.SET_PARTICIPATION_FULFILLED:
            if (action.payload) {
                return action.payload;
            }

            return state;

        case MockDrillReportActions.CLEANUP:
            return INITIAL_STATE.mockDrillReport;

        default:
            return state;
    }
};

const actions = (
    state: IMockDrillReportState['actions'] = INITIAL_STATE.actions,
    action: IAppAction
) => {
    switch (action.type) {
        case MockDrillReportActions.LOAD_ACTIONS_FULFILLED:
            return action.payload ? action.payload : state;

        case ActionActions.CREATE_ACTION_FOR_MOCK_DRILL_REPORT_FULFILLED:
            if (action.payload) {
                const newActions = [...state];
                newActions.push(action.payload);
                return newActions;
            }

            return state;

        case ActionActions.UPDATE_ACTION_FOR_MOCK_DRILL_REPORT_FULFILLED:
            if (action.payload) {
                const updatedItemIndex = state.findIndex((x) => x.id === action.payload.id);
                const updatedActions = [...state];
                updatedActions.splice(updatedItemIndex, 1, action.payload);
                return updatedActions;
            }

            return state;

        case ActionActions.ARCHIVE_ACTION_FOR_MOCK_DRILL_REPORT_FULFILLED:
            if (action.payload) {
                return state.filter((item: IAction) => item.id !== action.payload);
            }

            return state;

        case MockDrillReportActions.CLEANUP:
            return INITIAL_STATE.actions;

        default:
            return state;
    }
};

const attachments = (
    state: IMockDrillReportState['attachments'] = INITIAL_STATE.attachments,
    action: IAppAction
) => {
    switch (action.type) {
        case AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_MOCK_DRILL_REPORT:
            if (action.payload) {
                return action.payload;
            }
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_MOCK_DRILL_REPORT:
            if (action.payload) {
                return [...state, ...action.payload];
            }
            return state;
        case AttachmentActions.DELETE_ATTACHMENT_FULFILLED:
            if (action.payload) {
                return state.filter((item: IAttachmentModel) => item.fileName !== action.payload);
            }
            return state;

        case MockDrillReportActions.CLEANUP:
            return INITIAL_STATE.attachments;

        default:
            return state;
    }
};

const history = (
    state: IMockDrillReportState['history'] = INITIAL_STATE.history,
    action: IAppAction
) => {
    switch (action.type) {
        case MockDrillReportActions.LOAD_HISTORY_FULFILLED:
            return action.payload ? action.payload : state;

        case MockDrillReportActions.LOAD_HISTORY_REJECTED:
            return state;
        default:
            return state;
    }
};

export const mockDrillReportReducer = combineReducers<IMockDrillReportState>({
    isLoading,
    isExporting,
    isCreated,
    mockDrillReportsSummary,
    mockDrillReport,
    actions,
    attachments,
    history
});
