import { IEmeraldDisabledInput } from '../shared/disabled.input';
import { IEmeraldLabelInput } from '../shared/label.input';

export class EmeraldRadioButtonCoreInputs
  implements IEmeraldRadioButtonCoreInputs
{
  value?: string | number;
  checked?: boolean = false;
  id?: string;
  label?: string;
  disabled?: boolean = false;
  readOnly?: boolean;
  labelPosition?: string;
  name?: string;
}

export interface IEmeraldRadioButtonCoreInputs
  extends IEmeraldDisabledInput,
    IEmeraldLabelInput {
  name?: string;
  labelPosition?: string;
  value?: string | number;
  checked?: boolean;
  readOnly?: boolean;
  id?: string;
}
