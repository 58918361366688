import { combineReducers } from 'redux';
import { IAppAction } from 'actions/app-action';
import { IRiskProfileQuestionnaireState, getDefaultRiskProfileQuestionnaireContainerState } from 'state';
import { RiskProfileQuestionnaireActions } from 'actions/actions-v2';
import { s2ab } from 'utils/export-utils';
import saveAs from 'file-saver';

const riskProfileQuestionnaire = (
    state: IRiskProfileQuestionnaireState['riskProfileQuestionnaire'] = getDefaultRiskProfileQuestionnaireContainerState().riskProfileQuestionnaire,
    action: IAppAction
) => {
    switch (action.type) {
        case RiskProfileQuestionnaireActions.LOAD_RISK_PROFILE_QUESTIONNAIRE_FULFILLED:
            return action.payload;
        case RiskProfileQuestionnaireActions.CREATE_RISK_PROFILE_QUESTIONNAIRE_FULFILLED:
        case RiskProfileQuestionnaireActions.UPDATE_RISK_PROFILE_QUESTIONNAIRE_FULFILLED:
            return action.payload ? action.payload : state;
        case RiskProfileQuestionnaireActions.LOAD_RISK_PROFILE_QUESTIONNAIRE:
            return null;
        case RiskProfileQuestionnaireActions.LOAD_RISK_PROFILE_QUESTIONNAIRE_REJECTED:
            return state;
        default:
            return state;
    }
};

const riskProfileMasterQuestionnaire = (
    state: IRiskProfileQuestionnaireState['riskProfileMasterQuestionnaire'] = getDefaultRiskProfileQuestionnaireContainerState().riskProfileMasterQuestionnaire,
    action: IAppAction
) => {
    switch (action.type) {
        case RiskProfileQuestionnaireActions.LOAD_RISK_PROFILE_MASTER_QUESTIONNAIRE_FULFILLED:
            return action.payload;
        case RiskProfileQuestionnaireActions.CREATE_RISK_PROFILE_MASTER_QUESTIONNAIRE_FULFILLED:
        case RiskProfileQuestionnaireActions.UPDATE_RISK_PROFILE_MASTER_QUESTIONNAIRE_FULFILLED:
            return action.payload ? action.payload : state;
        case RiskProfileQuestionnaireActions.LOAD_RISK_PROFILE_MASTER_QUESTIONNAIRE:
            return null;
        case RiskProfileQuestionnaireActions.LOAD_RISK_PROFILE_MASTER_QUESTIONNAIRE_REJECTED:
            return state;
        default:
            return state;
    }
};

const isLoading = (
    state: IRiskProfileQuestionnaireState['isLoading'] = getDefaultRiskProfileQuestionnaireContainerState().isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case RiskProfileQuestionnaireActions.LOAD_RISK_PROFILE_QUESTIONNAIRE:
        case RiskProfileQuestionnaireActions.LOAD_RISK_PROFILE_MASTER_QUESTIONNAIRE:
        case RiskProfileQuestionnaireActions.CREATE_RISK_PROFILE_QUESTIONNAIRE:
        case RiskProfileQuestionnaireActions.UPDATE_RISK_PROFILE_QUESTIONNAIRE:
        case RiskProfileQuestionnaireActions.UPDATE_RISK_PROFILE_MASTER_QUESTIONNAIRE:
        case RiskProfileQuestionnaireActions.LOAD_RISK_PROFILE_MASTER_OVERVIEW:
        case RiskProfileQuestionnaireActions.CREATE_RISK_PROFILE_MASTER_QUESTIONNAIRE:
            return true;

        case RiskProfileQuestionnaireActions.LOAD_RISK_PROFILE_QUESTIONNAIRE_FULFILLED:
        case RiskProfileQuestionnaireActions.LOAD_RISK_PROFILE_QUESTIONNAIRE_REJECTED:
        case RiskProfileQuestionnaireActions.LOAD_RISK_PROFILE_MASTER_QUESTIONNAIRE_FULFILLED:
        case RiskProfileQuestionnaireActions.LOAD_RISK_PROFILE_MASTER_QUESTIONNAIRE_REJECTED:
        case RiskProfileQuestionnaireActions.CREATE_RISK_PROFILE_QUESTIONNAIRE_FULFILLED:
        case RiskProfileQuestionnaireActions.CREATE_RISK_PROFILE_QUESTIONNAIRE_REJECTED:
        case RiskProfileQuestionnaireActions.UPDATE_RISK_PROFILE_QUESTIONNAIRE_FULFILLED:
        case RiskProfileQuestionnaireActions.UPDATE_RISK_PROFILE_QUESTIONNAIRE_REJECTED:
        case RiskProfileQuestionnaireActions.UPDATE_RISK_PROFILE_MASTER_QUESTIONNAIRE_FULFILLED:
        case RiskProfileQuestionnaireActions.UPDATE_RISK_PROFILE_MASTER_QUESTIONNAIRE_REJECTED:
        case RiskProfileQuestionnaireActions.LOAD_RISK_PROFILE_MASTER_OVERVIEW_FULFILLED:
        case RiskProfileQuestionnaireActions.LOAD_RISK_PROFILE_MASTER_OVERVIEW_REJECTED:
        case RiskProfileQuestionnaireActions.CREATE_RISK_PROFILE_MASTER_QUESTIONNAIRE_FULFILLED:
        case RiskProfileQuestionnaireActions.CREATE_RISK_PROFILE_MASTER_QUESTIONNAIRE_REJECTED:
            return false;
        default:
            return state;
    }
};

const isExporting = (
    state: IRiskProfileQuestionnaireState['isExporting'] = getDefaultRiskProfileQuestionnaireContainerState().isExporting,
    action: IAppAction
) => {
    switch (action.type) {
        case RiskProfileQuestionnaireActions.EXPORT_RISK_PROFILE_QUESTIONNAIRE:
        case RiskProfileQuestionnaireActions.EXPORT_ALL_RISK_PROFILE_QUESTIONNAIRE:
            return true;
        case RiskProfileQuestionnaireActions.EXPORT_RISK_PROFILE_QUESTIONNAIRE_FULFILLED:
        case RiskProfileQuestionnaireActions.EXPORT_ALL_RISK_PROFILE_QUESTIONNAIRE_FULFILLED:
            const blob = new Blob([s2ab(atob(action.payload.fileContents))], {
                type: 'application/excel'
            });
            saveAs(blob, action.payload.fileDownloadName);
            return false;
        case RiskProfileQuestionnaireActions.EXPORT_RISK_PROFILE_QUESTIONNAIRE_REJECTED:
        case RiskProfileQuestionnaireActions.EXPORT_ALL_RISK_PROFILE_QUESTIONNAIRE_REJECTED:
            return false;
        default:
            return state;
    }
};

export const riskProfileQuestionsReducer = combineReducers<IRiskProfileQuestionnaireState>({
    riskProfileQuestionnaire,
    riskProfileMasterQuestionnaire,
    isLoading,
    isExporting
});
