import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { getPermissions } from 'sharedSelectors';
import { MacroRiskForm } from './macro-risk-form';
import { getSites } from 'components/sideNav/selectors';

const getDivisions = (state: IRootState) => {
    let result = [
        ...state.siteMetaDataState.divisions
            .filter(x => x.operatingPlatform === state.siteState.site.operatingPlatform).map(x => {
                return {
                    key: x.value,
                    value: x.value,
                    label: x.lineOfBusiness + ': ' + x.value,
                };
            })
    ];

    return result;
};

const getBusinessUnits = (state: IRootState) => {
    const opBusinessUnits = state.siteMetaDataState.businessUnit.filter(
        x => x.operatingPlatform === state.siteState.site.operatingPlatform
    );

    return [
        ...opBusinessUnits.map(x => {
            return {
                key: x.key,
                value: x.value,
                label: x.label,
            };
        }),
    ];
};
const getRegions = (state: IRootState) => state.app.lookupRegion;
const getCountries = (state: IRootState) => state.app.lookupCountry;
const getClients = (state: IRootState) => state.app.lookupClients;

const mapStateToProps = createSelector(
    getPermissions,
    getDivisions,
    getBusinessUnits,
    getRegions,
    getCountries,
    getClients,
    getSites,
    (
        permissions,
        lookupDivisions,
        lookupBusinessUnits,
        lookupRegions,
        lookupCountries,
        clients,
        sites
    ) => ({
        permissions,
        lookupDivisions,
        lookupBusinessUnits,
        lookupRegions,
        lookupCountries,
        clients,
        sites,
    })
);

export const MacroRiskFormContainer = connect(mapStateToProps)(MacroRiskForm);
