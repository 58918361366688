import { createSelector } from 'reselect';
import { IProcessStep, IRootCermAssessmentState } from '../../../../reducers/cermAssessments';

const getInitialValues = createSelector(
    (state: IRootCermAssessmentState) => state.cermAssessmentState,
    (state) => state.reviewProcessStep
);

export default createSelector(
    getInitialValues,
    (initialValues: Partial<IProcessStep>) => ({
        initialValues
    }),
);
