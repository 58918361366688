import React from 'react';
import { IProgressBarModel } from 'components/step-progress-bar/StepProgressBar';
import { Answer } from '../Context';
import { IStateResult, State } from '../State';
import { QuestionSypplyAirTempC } from './question-supply-air-temp-c';
import { QuestionSypplyAirTempD } from './question-supply-air-temp-d';

export class QuestionAdiabaticCoolingIae extends State {
    public result: IStateResult;
    private title: string = 'Adiabatic Cooling';
    private type: Answer;
    constructor() {
        super();
        this.handleClick = this.handleClick.bind(this);
    }
    public getResult(): IStateResult {
        return this.result;
    }

    public display(): JSX.Element {
        return (
            <div >
                <div className="is-size-3  has-text-centered">
                    {this.title}
                </div>
                <p className="has-text-centered">
                    Does your direct air system utilise adiabatic cooling (water evaporation)
                    to reduce outside air temperature?
                </p>
                <hr />
                <div className="formBody">
                    <div className="field">
                        <div className="control">
                            <input
                                className="radioButton"
                                type="radio"
                                name="answer"
                                value={Answer.Yes}
                                id={Answer.Yes}
                                onClick={this.handleClick}
                                checked={this.type === Answer.Yes}
                            />

                            <label htmlFor={Answer.Yes} className="radio is-size-5">
                                {Answer.Yes.toString()}
                            </label>
                            <p>
                                Your CRAC, CRAH, AHU have a water supply which is used to adiabatically cool the
                                outside air prior to supply to the IT equipment or the cooling coil
                                if there is mechanical cooling
                            </p>
                        </div>
                    </div>
                    <div className="field">
                        <div className="control">
                            <input
                                className="radioButton"
                                type="radio"
                                name="answer"
                                id={Answer.No}
                                value={Answer.No}
                                onClick={this.handleClick}
                                checked={this.type === Answer.No}
                            />
                            <label htmlFor={Answer.No} className="radio is-size-5">

                                {Answer.No.toString()}
                            </label>
                            <p>
                                Your CRAC, CRAH, AHU do not use adiabatic cooling, the air is
                                supplied directly to the IT area or to a mechanical coil
                            </p>
                        </div>
                    </div>
                </div>
                <hr />
                {this.context.footer(this.handle.bind(this))}
            </div>);
    }
    public handle() {

        this.result = {
            title: this.title,
            value: this.type.toString()
        };
        this.type === Answer.Yes ?
            this.context.transitionTo(new QuestionSypplyAirTempC()) :
            this.context.transitionTo(new QuestionSypplyAirTempD());
    }
    public getProgressBarModel(): IProgressBarModel[] {
        return [
            { isActive: true, name: 'Type of site' },
            { isActive: true, name: 'Question 1' },
            { isActive: false, name: 'Question 2' },
            { isActive: false, name: 'Question 3' },
            { isActive: false, name: 'Question 4' },
            { isActive: false, name: 'Summary' },
        ] as IProgressBarModel[];
    }
    private handleClick(event: any) {
        this.context.isDisable = false;
        this.type = event.currentTarget.value as Answer;
        this.context.handleRender();
    }
}
