import { onRouteChange } from 'actions/app-actions';
import {
    createClient,
    updateClient,
    deleteClient,
    loadClient,
    saveAccessToken,
    revokeAccessToken,
    getOrganizationDetails,
    loadMyDataClients,
    resetClient,
} from 'actions/actions-v2/client-action-v2';
import { loadAppSettings } from 'actions/actions-v2/nav-actions-v2';

export default {
    onRouteChange: (url: string) => onRouteChange(url),
    onCreate: createClient,
    onUpdate: updateClient,
    onDelete: deleteClient,
    loadClient,
    saveAccessToken,
    revokeAccessToken,
    getOrganizationDetails,
    loadMyDataClients,
    resetClient,
    loadAppSettings
};
