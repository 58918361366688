import {
    createActionForRisk,
    updateActionForRisk,
    archiveActionForRisk,
    createMacroAction,
    updateMacroActionForRisk,
} from 'actions/actions-v2/action-action-v2';
import { onShowUpsertActionModal, onShowEditActionModal, hideModal } from 'actions/action-actions';

export default {
    onShowUpsertActionModal,
    onShowEditActionModal,
    hideModal,
    createActionForRisk,
    updateActionForRisk,
    createMacroAction,
    updateMacroActionForRisk,
    archiveActionForRisk,
};
