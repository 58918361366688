import { connect } from 'react-redux';
import { createSelector } from 'reselect';
// import { Dispatch } from 'react';
// import { Action } from 'redux';
import { UsersSelector } from './user-selector';
import { IBaseState } from 'reducers/base-state';
import { IAppState } from 'reducers/app';
import {
    getLineOfBusinesses,
    getRegions,
    getOperatingPlatforms
} from 'routes/site-features/selectors';
import { onLoadSitesFeatures } from 'actions/site-feature';
import _ from 'lodash';
import { getOption } from 'models/site-profile';
import { IRootState } from 'routes/store';
import { loadSiteRoles } from 'actions/actions-v2/site-roles-actions-v2';
import { loadAllPersons, resetNotificationPerson } from 'actions/actions-v2/person-action-v2';

const getUsers = (state: IRootState) =>
    state.persons.grid.notificationPersons === null ? [] : state.persons.grid.notificationPersons;

const getRoles = createSelector(
    (state: IBaseState) => state.app,
    (state: IAppState) => {
        return state.lookupRoles
            ? state.lookupRoles.map(m => ({
                  value: m.value,
                  label: m.label,
              }))
            : [];
    }
);

const getClients = createSelector(
    (state: IBaseState) => state.app,
    (state: IAppState) => {
        return state.lookupRoles
            ? state.lookupClients
                  .filter(f => f.label !== null)
                  .map(m => ({
                      value: m.key,
                      label: m.label,
                  }))
            : [];
    }
);

const getSiteNames = createSelector(
    (state: IRootState) => state,
    state => {
        return state.siteFeatures.sites ? state.siteFeatures.sites.map(m => getOption(m)) : [];
    }
);

const getSiteRoles = (state: IRootState) => state.siteRolesState.siteRoles;
const getIsLoading = (state: IRootState) => state.persons.grid.isLoading;
const getLineOfBusiness = (state: IRootState) => state.siteMetaDataState.allLineOfBusinesses;

export const userSelectorSelector = createSelector(
    getUsers,
    getClients,
    getLineOfBusinesses,
    getRoles,
    getRegions,
    getSiteNames,
    getSiteRoles,
    getIsLoading,
    getLineOfBusiness,
    getOperatingPlatforms,
    (
        users,
        clients,
        lineOfBusinesses,
        roles,
        regions,
        sites,
        siteRoles,
        isLoading,
        allLineOfBusinesses,
        operatingPlatforms,

    ) => ({
        users,
        clients,
        lineOfBusinesses,
        roles,
        regions,
        sites,
        siteRoles,
        isLoading,
        allLineOfBusinesses,
        operatingPlatforms,
    })
);

const mapDispatchToProps = {
    loadAllPersons,
    onLoadSitesFeatures,
    loadSiteRoles,
    resetNotificationPerson,
};

export const UserSelectorContainer = connect(
    userSelectorSelector,
    mapDispatchToProps
)(UsersSelector);
