import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from './base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { GlobalSkillCategoryActions } from 'actions/global-skill-category-action';
import { IGlobalSkillCategory } from 'models/competency-dna/cdna';

const controllerName = 'GlobalSkillCategory';

const createActions = {
    action: GlobalSkillCategoryActions.CREATE_GLOBAL_SKILL_CATEGORY,
    actionFulfilled: GlobalSkillCategoryActions.CREATE_GLOBAL_SKILL_CATEGORY_FULFILLED,
    actionRejected: GlobalSkillCategoryActions.CREATE_GLOBAL_SKILL_CATEGORY_REJECTED,
} as IActionType;

const updateActions = {
    action: GlobalSkillCategoryActions.UPDATE_GLOBAL_SKILL_CATEGORY,
    actionFulfilled: GlobalSkillCategoryActions.UPDATE_GLOBAL_SKILL_CATEGORY_FULFILLED,
    actionRejected: GlobalSkillCategoryActions.UPDATE_GLOBAL_SKILL_CATEGORY_REJECTED,
} as IActionType;

const getAllActions = {
    action: GlobalSkillCategoryActions.LOAD_GLOBAL_SKILL_CATEGORIES,
    actionFulfilled: GlobalSkillCategoryActions.LOAD_GLOBAL_SKILL_CATEGORIES_FULFILLED,
    actionRejected: GlobalSkillCategoryActions.LOAD_GLOBAL_SKILL_CATEGORIES_REJECTED,
} as IActionType;

const deleteActions = {
    action: GlobalSkillCategoryActions.DELETE_GLOBAL_SKILL_CATEGORY,
    actionFulfilled: GlobalSkillCategoryActions.DELETE_GLOBAL_SKILL_CATEGORY_FULFILLED,
    actionRejected: GlobalSkillCategoryActions.DELETE_GLOBAL_SKILL_CATEGORY_REJECTED,
} as IActionType;

const loadGlobalSkillCategoriesEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getAllActions).get<IGlobalSkillCategory[]>(`/api/${controllerName}`);
const createGlobalSkillCategoryEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createActions).post<IGlobalSkillCategory>(
        `/api/${controllerName}`
    );
const updateGlobalSkillCategoryEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateActions).put<IGlobalSkillCategory>(
        `/api/${controllerName}`
    );
const deleteGlobalSkillCategoryEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, deleteActions).deleteByIdAndShard<string>(`/api/${controllerName}`);

export const globalSkillCategoryEpics = combineEpics(
    createGlobalSkillCategoryEpic,
    updateGlobalSkillCategoryEpic,
    loadGlobalSkillCategoriesEpic,
    deleteGlobalSkillCategoryEpic,
);
