import { loadMacroActions } from 'actions/actions-v2/action-action-v2';
import { loadAttachmentsRisks } from 'actions/actions-v2/attachment-action-v2';
import { loadPersonsLookup } from 'actions/actions-v2/person-action-v2';
import { loadRiskActions, loadHistory } from 'actions/actions-v2/risk-action-v2';

export default {
    loadRiskActions,
    loadAttachments: loadAttachmentsRisks,
    loadPersonsLookup,
    loadHistory,
    loadMacroActions,
};
