import { IAppAction } from '../app-action';
import { IRisk } from 'models/risk';
import { IRiskFilters } from 'models/IRiskFilters';

export enum MacroRiskActions {
    LOAD_MACRO_RISK = '@@risk/LOAD_MACRO_RISK',
    LOAD_MACRO_RISK_FULFILLED = '@@risk/LOAD_MACRO_RISK_FULFILLED',
    LOAD_MACRO_RISK_REJECTED = '@@risk/LOAD_MACRO_RISK_REJECTED',

    LOAD_MACRO_RISKS = '@@risk/LOAD_MACRO_RISKS',
    LOAD_MACRO_RISKS_FULFILLED = '@@risk/LOAD_MACRO_RISKS_FULFILLED',
    LOAD_MACRO_RISKS_REJECTED = '@@risk/LOAD_MACRO_RISKS_REJECTED',
    LOAD_MACRO_RISKS_CANCELLED = '@@risk/LOAD_MACRO_RISKS_CANCELLED',

    SORT_MACRO_RISKS = '@@risk/SORT_MACRO_RISKS',

    CREATE_MACRO_RISK = '@@risk/CREATE_MACRO_RISK',
    CREATE_MACRO_RISK_FULFILLED = '@@risk/CREATE_MACRO_RISK_FULFILLED',
    CREATE_MACRO_RISK_REJECTED = '@@risk/CREATE_MACRO_RISK_REJECTED',

    UPDATE_MACRO_RISK = '@@risk/UPDATE_MACRO_RISK',
    UPDATE_MACRO_RISK_FULFILLED = '@@risk/UPDATE_MACRO_RISK_FULFILLED',
    UPDATE_MACRO_RISK_REJECTED = '@@risk/UPDATE_MACRO_RISK_REJECTED',

    DELETE_MACRO_RISK = '@@risk/DELETE_MACRO_RISK',
    DELETE_MACRO_RISK_FULFILLED = '@@risk/DELETE_MACRO_RISK_FULFILLED',
    DELETE_MACRO_RISK_REJECTED = '@@risk/DELETE_MACRO_RISK_REJECTED',
}

export const createMacroRisk = (risk: Partial<IRisk>): IAppAction => ({
    type: MacroRiskActions.CREATE_MACRO_RISK,
    payload: risk,
});

export const updateMacroRisk = (risk: Partial<IRisk>): IAppAction => ({
    type: MacroRiskActions.UPDATE_MACRO_RISK,
    payload: risk,
});

export const removeMacroRisk = (id: string): IAppAction => ({
    type: MacroRiskActions.DELETE_MACRO_RISK,
    payload: id,
});

export const loadMacroRisks = (filters: IRiskFilters): IAppAction => ({
    type: MacroRiskActions.LOAD_MACRO_RISKS,
    payload: filters,
});

export const loadMacroRisk = (id: string): IAppAction => ({
    type: MacroRiskActions.LOAD_MACRO_RISK,
    payload: { id },
});
