import * as React from 'react';

import './MultiCellSelect.scss';

export interface IMultiSelectHeaderCell {
    colSpan: number,
    text: string
}

export interface IMultiCellSelect {
    isColumnClickable: boolean[];
    header: IMultiSelectHeaderCell[];
    subheader: IMultiSelectHeaderCell[];
    cellText: Array<Array<string>>;
}

export interface IStateProps {
    multiCellSelectData: IMultiCellSelect
    isEditable: boolean
    isMultiSelect: boolean
    cellSelected: boolean[][]
    updateCellSelected(newCellSelected: boolean[][]): void
}

export const MultiCellSelect: React.FC<IStateProps> = (props) => {

    const getSelectedRow = (data: boolean[][]): number => {
        return data?.findIndex(x => x[0] === true)
    }

    const onCellClick = (row: number, col: number): void => {
        let rowIndex: number = -1
        let cellSelected = props.cellSelected
        let selected = [...cellSelected]
        let selectedRow = getSelectedRow(cellSelected)
        switch (props.isMultiSelect) {
            case false:
                if (cellSelected[row][col] == false) {
                    for (let i = 0; i < cellSelected.length; i++) {
                        for (let j = 0; j < cellSelected[i].length; j++) {
                            if (i == row && j == col) {
                                selected[i][0] = true
                                selected[i][j] = true
                                rowIndex = i
                            } else {
                                selected[i][j] = false
                            }
                        }
                    }
                } else {
                    selected[row][0] = false
                    selected[row][col] = false
                    rowIndex = -1
                }
                break;
            case true:
                if (selectedRow != -1) {
                    selected[selectedRow][0] = false
                    rowIndex = -1
                }

                for (let i = 0; i < cellSelected.length; i++) {
                    for (let j = 0; j < cellSelected[i].length; j++) {
                        if (i == row && j == col) {
                            selected[i][j] = !cellSelected[i][j]
                        }
                        if (selected[i][j] === true) {
                            if (rowIndex == -1) {
                                selected[i][0] = true
                                rowIndex = i
                            }
                        }
                    }
                }
                break;
        }
        props.updateCellSelected(selected)
    }

    return (
        <table className='multiCellSelect'>
            <tbody>
                <tr>
                    {props.multiCellSelectData.header?.map((item, index) => {
                        return <th
                            key={'header_' + index}
                            colSpan={item.colSpan}
                            className="headerCell"
                        >{item.text}</th>
                    })}
                </tr>
                <tr>
                    {props.multiCellSelectData.subheader?.map((item, index) => {
                        return <th
                            key={'subheader_' + index}
                            colSpan={item.colSpan}
                            className="headerCell"
                        >{item.text}</th>
                    })}
                </tr>
                {props.multiCellSelectData.cellText?.map((row, rowNo) => {

                    return <tr key={'row_' + rowNo}>
                        {row?.map((item, colNo) => {
                            let className = props.multiCellSelectData.isColumnClickable[colNo] ? '' : colNo == 0 ? 'headerCell' : 'fixedCell'
                            return <td
                                key={'data' + rowNo + '_' + colNo}
                                className={className + ' ' + (props.cellSelected && props.cellSelected[rowNo][colNo] ? 'selectedCell' : '')}
                                onClick={() => {
                                    if (props.isEditable && props.multiCellSelectData.isColumnClickable[colNo]) {
                                        onCellClick(rowNo, colNo)
                                    }
                                }}>{item}</td>
                        })}
                    </tr>
                })}
            </tbody>
        </table>
    );
};
