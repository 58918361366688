import { LOCATION_CHANGE } from "redux-first-history";
import { combineReducers } from 'redux';

import { SiteRelationshipsActions, SiteRelationshipsActionTypes } from '../../actions/site-relationships-actions';
import { IPerson } from '../../models/person';

export interface IPersonSelectorState {
    selectedPerson: IPerson;
    searchValue: string;
    people: IPerson[];

    isLoading: boolean;
    isSubmitting: boolean;
}

export const INITIAL_STATE: IPersonSelectorState = {
    selectedPerson: null,
    searchValue: '',
    people: [],
    isLoading: false,
    isSubmitting: false
};

const selectedPerson = (
    state: IPersonSelectorState['selectedPerson'] = INITIAL_STATE.selectedPerson,
    action: SiteRelationshipsActionTypes
) => {
    switch (action.type) {
        case SiteRelationshipsActions.SELECT_PERSON:
            return action.payload.person;

        case LOCATION_CHANGE:
        case SiteRelationshipsActions.CHANGE_SITE_SEARCH_VALUE:
        case SiteRelationshipsActions.CHANGE_PERSON_SEARCH_VALUE:
            return null;

        default:
            return state;
    }
};

const searchValue = (
    state: IPersonSelectorState['searchValue'] = INITIAL_STATE.searchValue,
    action: SiteRelationshipsActionTypes
) => {
    switch (action.type) {
        case SiteRelationshipsActions.CHANGE_PERSON_SEARCH_VALUE:
            return action.payload.value;

        case SiteRelationshipsActions.SELECT_PERSON:
            return `${action.payload.person.firstName} ${action.payload.person.lastName}`;

        case LOCATION_CHANGE:
        case SiteRelationshipsActions.LINK_PERSON_FULFILLED:
            return '';

        default:
            return state;
    }
};

const people = (
    state: IPersonSelectorState['people'] = INITIAL_STATE.people,
    action: SiteRelationshipsActionTypes
) => {
    switch (action.type) {
        case LOCATION_CHANGE:
        case SiteRelationshipsActions.CHANGE_SITE_SEARCH_VALUE:
        case SiteRelationshipsActions.CHANGE_PERSON_SEARCH_VALUE:
        case SiteRelationshipsActions.LOAD_PERSON_SEARCH_RESULTS:
            return [];

        case SiteRelationshipsActions.LOAD_PERSON_SEARCH_RESULTS_FULFILLED:
            return action.payload.people;

        default:
            return state;
    }
};

const isLoading = (
    state: IPersonSelectorState['isLoading'] = INITIAL_STATE.isLoading,
    action: SiteRelationshipsActionTypes
) => {
    switch (action.type) {
        case SiteRelationshipsActions.LOAD_PERSON_SEARCH_RESULTS:
            return true;

        case LOCATION_CHANGE:
        case SiteRelationshipsActions.CHANGE_SITE_SEARCH_VALUE:
        case SiteRelationshipsActions.CHANGE_PERSON_SEARCH_VALUE:
        case SiteRelationshipsActions.LOAD_PERSON_SEARCH_RESULTS_FULFILLED:
        case SiteRelationshipsActions.LOAD_PERSON_SEARCH_RESULTS_CANCELLED:
        case SiteRelationshipsActions.LOAD_PERSON_SEARCH_RESULTS_REJECTED:
            return false;

        default:
            return state;
    }
};

const isSubmitting = (
    state: IPersonSelectorState['isSubmitting'] = INITIAL_STATE.isSubmitting,
    action: SiteRelationshipsActionTypes
) => {
    switch (action.type) {
        case SiteRelationshipsActions.LINK_PERSON:
            return true;

        case SiteRelationshipsActions.LINK_PERSON_CANCELLED:
        case SiteRelationshipsActions.LINK_PERSON_FULFILLED:
        case SiteRelationshipsActions.LINK_PERSON_REJECTED:
            return false;

        default:
            return state;
    }
};

export const reducer = combineReducers<IPersonSelectorState>({
    selectedPerson,
    searchValue,
    people,
    isLoading,
    isSubmitting
});
