
import { connect } from 'react-redux';
import actions from './actions';
import { FacilitySelectModal } from './facility-select-modal';
import selector from './selector';

export const FacilitySelectPage = connect(
    selector,
    actions
)(FacilitySelectModal);

