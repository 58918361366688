import * as React from 'react';
import { Loader } from 'components/loader';
import { IKeyValue } from 'models/key-value';
import { ISiteProfile } from 'models/site-profile';
import { SiteFeaturesEdit } from './site-features-edit';
import Page from 'components/v2/page/Page';
import { getAllSiteFeatures } from 'actions/site-feature';
import { ICommonLookupModel } from 'models/common-lookup-model';
import useTranslate from 'translations/translation-utils';
import { IOperatingPlatform } from 'models/operating-platform-model';

interface IProps {
    isLoading: boolean;
    sites: ISiteProfile[];
    clients: string[];
    lineOfBusinesses: ICommonLookupModel[];
    regions: string[];
    operatingPlatforms: IOperatingPlatform[];
}

interface IActionProps {
    saveSiteFeatures: (sites: Array<IKeyValue<string[]>>) => void;
    getSiteFeatures: () => void;
}

export const SiteFeatures: React.FC<IProps & IActionProps> = (props) => {
    const features = getAllSiteFeatures();
    const translate = useTranslate();
    React.useEffect(() => {
        props.getSiteFeatures();
    }, []);

    return (
        <Page title={translate('SiteFeatures.Page.Title.SiteFeatures')} isAdminPage={true}>
            <Loader className="ManageRoleList" loading={props.isLoading}>
                <SiteFeaturesEdit
                    sites={props.sites}
                    clients={props.clients}
                    lineOfBusinesses={props.lineOfBusinesses}
                    regions={props.regions}
                    features={features}
                    onSubmit={props.saveSiteFeatures}
                    operatingPlatforms={props.operatingPlatforms}
                />
            </Loader>
        </Page>
    );
};
