import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { IRiskProfileQuestionnaire } from 'models';
import { Loader } from 'components/loader';
import { RiskProfileQuestions } from 'components/risk-profile-questions/risk-profile-questions';
import { IRiskProfileCategory } from 'models/risk-profile-questions/risk-profile-lookups';
import { RiskProfileQuestionnaireToolbar } from './risk-profile-questionnaire-toolbar';
import Page from 'components/v2/page/Page';
import { IKeyValue } from '../../../src/models/key-value';
import useTranslate from 'translations/translation-utils';

interface IRouteParams {
    operatingPlatform?: string;
}
export interface IRiskProfileQuestionnaireProps extends RouteComponentProps<IRouteParams> {
    riskProfileQuestionnaire: IRiskProfileQuestionnaire | null;
    riskProfileCategories: IRiskProfileCategory[];
    siteId: string;
    siteName: string;
    isLoading: boolean;
    isExporting: boolean;
    loadQuestionnaire: (siteId: string, operatingPlatform: string) => void;
    saveQuestionnaire: (questionnaire: IRiskProfileQuestionnaire) => void;
    exportQuestionnaire: (siteId: string, operatingPlatform: string) => void;
    exportAllQuestionnaire: (operatingPlatform: string) => void;
    lookupSites: Array<IKeyValue<string>>;
}


export const RiskProfileQuestionnairePage: React.FC<IRiskProfileQuestionnaireProps> = (props) => {
    const getOperatingPlatform = (SelectedSiteId: string, lookupSites: any) => {
        let site = lookupSites.find(x => x.key === SelectedSiteId);
        return site ? site.operatingPlatform : '';
    }
    React.useEffect(() => {
        if (props.siteId) {
            props.loadQuestionnaire(props.siteId, getOperatingPlatform(props.siteId, props.lookupSites));
        }
    }, [props.siteId]);

    const exportQuestionnaire = () => {
        props.exportQuestionnaire(props.siteId, getOperatingPlatform(props.siteId, props.lookupSites));
    }

    const translate = useTranslate();
    const exportAllQuestionnaire = () => {
        props.exportAllQuestionnaire(getOperatingPlatform(props.siteId, props.lookupSites));
    }
    return (
        <Page title={translate('SideNavBar.Labels.RiskProfileQuestionnaire')}>
            <RiskProfileQuestionnaireToolbar
                isExporting={props.isExporting}
                onExportAllSites={exportAllQuestionnaire}
                onExportThisSite={exportQuestionnaire}
            />
            <Loader loading={props.isLoading}>
                {props.riskProfileQuestionnaire ? (
                    <RiskProfileQuestions
                        riskProfileQuestionnaire={props.riskProfileQuestionnaire}
                        siteId={props.siteId}
                        onSaveQuestionnaire={props.saveQuestionnaire}
                        categories={props.riskProfileCategories}
                        isLoading={props.isLoading}
                        operatingPlatform={getOperatingPlatform(props.siteId, props.lookupSites)}
                    />
                ) : null}
            </Loader>
        </Page>
    );



}

