import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { MockDrillReportsPage } from './mock-drill-reports-page';
import {
    loadMockDrillReportsSummary,
    cleanupMockDrillReportList,
} from 'actions/actions-v2/mock-drill-report-actions';
import { getPermissions } from 'sharedSelectors';
import { checkPermission } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import { deleteMockDrill } from 'actions/actions-v2/mock-drills-actions';
import { deleteGlobalMockDrill } from 'actions/actions-v2/global-mock-drills-actions';

const getSiteId = (state: IRootState) => state.app.siteId;
const getShowHelpModule = (state: IRootState) => state.app.showHelpModule;
const getIsLoading = (state: IRootState) => state.mockDrillReportState.isLoading;
const getMockDrill = (state: IRootState) => state.mockDrillsState.mockDrill;
const getMockDrillReportsSummary = (state: IRootState) =>
    state.mockDrillReportState.mockDrillReportsSummary;

const mapStateToProps = createSelector(
    getSiteId,
    getIsLoading,
    getMockDrill,
    getMockDrillReportsSummary,
    getPermissions,
    getShowHelpModule,
    (siteId, isLoading, mockDrill, mockDrillReportsSummary, permissions, showHelpModule) => ({
        siteId,
        isLoading,
        mockDrill,
        mockDrillReportsSummary,
        hasReadWritePermission: checkPermission(Privilege.MockDrillsReadWrite, permissions),
        showHelpModule,
        userCanDeleteLocalMockDrillsScenario: checkPermission(
            Privilege.MockDrillsLocalScenarioDelete,
            permissions),
        userCanDeleteGlobalMockDrills: checkPermission(
            Privilege.GlobalMockDrillsScenarioDelete,
            permissions),
    })
);

const mapDispatchToProps = {
    loadMockDrillReportsSummary,
    cleanupMockDrillReportList,
    deleteMockDrill,
    deleteGlobalMockDrill,
};

export const MockDrillReportsPageContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MockDrillReportsPage)
);
