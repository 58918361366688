import { Action } from 'redux';
// import { ICermOverviewPillar, ICermOverviewProcessStep } from '../models/cerm-overview';
import { ICermOverviewGridState } from '../reducers/cerm-overview';

export enum CermOverviewActions {
    // LOAD_DATA = '@@cermOverview/LOAD_DATA',
    // LOAD_DATA_FULFILLED = '@@cermOverview/LOAD_DATA_FULFILLED',
    // LOAD_DATA_CANCELLED = '@@cermOverview/LOAD_DATA_CANCELLED',
    // LOAD_DATA_REJECTED = '@@cermOverview/LOAD_DATA_REJECTED',
    // CHANGE_SORT_COLUMN = '@@cermOverview/CHANGE_SORT_COLUMN',
    // ADD_FILTER_KEYWORD = '@@cermOverview/ADD_FILTER_KEYWORD',
    // REMOVE_FILTER_KEYWORD = '@@cermOverview/REMOVE_FILTER_KEYWORD',
    // CHANGE_FILTER_VALUE = '@@cermOverview/CHANGE_FILTER_VALUE',
    // CLEAR_FILTERS = '@@cermOverview/CLEAR_FILTERS',
    COLLAPSE_ALL = '@@cermOverview/COLLAPSE_ALL',
    EXPAND_ALL = '@@cermOverview/EXPAND_ALL',
    SHOW_FILTER_MODAL = '@@cermOverview/SHOW_FILTER_MODAL',
    HIDE_FILTER_MODAL = '@@cermOverview/HIDE_FILTER_MODAL',
    TOGGLE_FILTER_EXPANDED = '@@cermOverview/TOGGLE_FILTER_EXPANDED',
    TOGGLE_PILLAR = '@@cermOverview/TOGGLE_PILLAR',
    TOGGLE_PROCESS_STEP_EXPANDED = '@@cermOverview/TOGGLE_PROCESS_STEP_EXPANDED',
    // LOAD_PAGE = '@@cermOverview/LOAD_PAGE',
    // LOAD_PAGE_FULFILLED = '@@cermOverview/LOAD_PAGE_FULFILLED',
    // LOAD_PAGE_REJECTED = '@@cermOverview/LOAD_PAGE_REJECTED',
    // LOAD_PAGE_CANCELLED = '@@cermOverview/LOAD_PAGE_CANCELLED',
    // CHANGE_FILTER_FULFILLED = '@@cermOverview/CHANGE_FILTER_FULFILLED',
    // CHANGE_FILTER_CANCELLED = '@@cermOverview/CHANGE_FILTER_CANCELLED',
    // CHANGE_FILTER_REJECTED = '@@cermOverview/CHANGE_FILTER_REJECTED',
}

// export interface ILoadPageAction extends Action {
//     type: CermOverviewActions.LOAD_PAGE;
// }

// export interface ILoadPageRejectedAction extends Action {
//     type: CermOverviewActions.LOAD_PAGE_REJECTED;
//     error: true;
//     payload: any;
// }

// export interface ILoadPageCancelledAction extends Action {
//     type: CermOverviewActions.LOAD_PAGE_CANCELLED;
// }

// export interface ILoadPageFulfilledAction extends Action {
//     type: CermOverviewActions.LOAD_PAGE_FULFILLED;
//     payload: any;
// }

// export interface IChangeFilterFulfilledAction extends Action {
//     type: CermOverviewActions.CHANGE_FILTER_FULFILLED;
//     payload: ICermOverviewPillar[];
// }

export interface ITogglePillarAction extends Action {
    type: CermOverviewActions.TOGGLE_PILLAR;
    payload: {
        id: string;
    };
}

export const togglePillar = (id: string): ITogglePillarAction => ({
    type: CermOverviewActions.TOGGLE_PILLAR,
    payload: {
        id
    }
});

export interface IToggleFilterExpandedAction extends Action {
    type: CermOverviewActions.TOGGLE_FILTER_EXPANDED;
    payload: {
        id: Extract<keyof ICermOverviewGridState, string>;
    };
}

export const toggleFilterExpanded = (
    id: Extract<keyof ICermOverviewGridState, string>
): IToggleFilterExpandedAction => ({
    type: CermOverviewActions.TOGGLE_FILTER_EXPANDED,
    payload: {
        id
    }
});

export interface IShowFilterModalAction extends Action {
    type: CermOverviewActions.SHOW_FILTER_MODAL;
}

export const showFilterModal = (): IShowFilterModalAction => ({
    type: CermOverviewActions.SHOW_FILTER_MODAL
});

export interface IHideFilterModalAction extends Action {
    type: CermOverviewActions.HIDE_FILTER_MODAL;
}

export const hideFilterModal = (): IHideFilterModalAction => ({
    type: CermOverviewActions.HIDE_FILTER_MODAL
});

// export interface IChangeFilterValueAction extends Action {
//     type: CermOverviewActions.CHANGE_FILTER_VALUE;
//     payload: {
//         field: keyof ICermOverviewGridState;
//         value: string;
//     };
// }

// export const changeFilterValue = (field: keyof ICermOverviewGridState, value: string): IChangeFilterValueAction => ({
//     type: CermOverviewActions.CHANGE_FILTER_VALUE,
//     payload: {
//         field,
//         value
//     }
// });

// export interface IAddFilterKeywordAction extends Action {
//     type: CermOverviewActions.ADD_FILTER_KEYWORD;
//     payload: {
//         keyword: string;
//     };
// }

// export const addFilterKeyword = (keyword: string): IAddFilterKeywordAction => ({
//     type: CermOverviewActions.ADD_FILTER_KEYWORD,
//     payload: {
//         keyword
//     }
// });

// export interface IRemoveFilterKeywordAction extends Action {
//     type: CermOverviewActions.REMOVE_FILTER_KEYWORD;
//     payload: {
//         keyword: string;
//     };
// }

// export const removeFilterKeyword = (keyword: string): IRemoveFilterKeywordAction => ({
//     type: CermOverviewActions.REMOVE_FILTER_KEYWORD,
//     payload: {
//         keyword
//     }
// });

export interface ICollapseAllAction extends Action {
    type: CermOverviewActions.COLLAPSE_ALL;
}

export const collapseAll = (): ICollapseAllAction => ({
    type: CermOverviewActions.COLLAPSE_ALL
});
export interface IExpandAllAction extends Action {
    type: CermOverviewActions.EXPAND_ALL;
    payload: {
        pillars: string[],
        steps: string[],
    };
}
// export interface IClearFiltersAction extends Action {
//     type: CermOverviewActions.CLEAR_FILTERS;
// }

// export const clearFilters = (): IClearFiltersAction => ({
//     type: CermOverviewActions.CLEAR_FILTERS
// });

export interface IToggleProcessStepExpandedAction extends Action {
    type: CermOverviewActions.TOGGLE_PROCESS_STEP_EXPANDED;
    payload: {
        id: string;
    };
}

export const toggleProcessStepExpanded = (id: string): IToggleProcessStepExpandedAction => ({
    type: CermOverviewActions.TOGGLE_PROCESS_STEP_EXPANDED,
    payload: {
        id
    }
});

// export interface ILoadDataAction extends Action {
//     type: CermOverviewActions.LOAD_DATA;
// }

// export const loadData = (): ILoadDataAction => ({
//     type: CermOverviewActions.LOAD_DATA
// });

// export interface ILoadDataFulfilledAction extends Action {
//     type: CermOverviewActions.LOAD_DATA_FULFILLED;
//     payload: {
//         data: ICermOverviewPillar[];
//     };
// }

// export const loadDataFulfilled = (data: ICermOverviewPillar[]): ILoadDataFulfilledAction => ({
//     type: CermOverviewActions.LOAD_DATA_FULFILLED,
//     payload: {
//         data
//     }
// });

// export interface ILoadDataRejectedAction extends Action {
//     type: CermOverviewActions.LOAD_DATA_REJECTED;
//     payload: {
//         error: any;
//     };
//     error: true;
// }

// export const loadDataRejected = (error: any): ILoadDataRejectedAction => ({
//     type: CermOverviewActions.LOAD_DATA_REJECTED,
//     payload: {
//         error
//     },
//     error: true
// });

// export interface ILoadDataCancelledAction extends Action {
//     type: CermOverviewActions.LOAD_DATA_CANCELLED;
// }

// export const loadDataCancelled = (): ILoadDataCancelledAction => ({
//     type: CermOverviewActions.LOAD_DATA_CANCELLED
// });

// export interface IChangeSortColumnAction extends Action {
//     type: CermOverviewActions.CHANGE_SORT_COLUMN;
//     payload: {
//         column: Extract<keyof ICermOverviewProcessStep, string>;
//         isAscending: boolean;
//     };
// }

// export const changeSortColumn = (
//     column: Extract<keyof ICermOverviewProcessStep, string>,
//     isAscending: boolean
// ): IChangeSortColumnAction => ({
//     type: CermOverviewActions.CHANGE_SORT_COLUMN,
//     payload: {
//         column,
//         isAscending
//     }
// });

export type CermOverviewActionTypes =
// | ILoadDataAction
// | ILoadDataCancelledAction
// | ILoadDataFulfilledAction
// | ILoadDataRejectedAction
// | IChangeSortColumnAction
| ITogglePillarAction
| IToggleFilterExpandedAction
| IHideFilterModalAction
| IShowFilterModalAction
// | IChangeFilterValueAction
| ICollapseAllAction
| IExpandAllAction
// | IAddFilterKeywordAction
// | IRemoveFilterKeywordAction
// | IClearFiltersAction
| IToggleProcessStepExpandedAction;
// | ILoadPageAction
// | ILoadPageCancelledAction
// | ILoadPageRejectedAction
// | ILoadPageFulfilledAction
// | IChangeFilterFulfilledAction;
