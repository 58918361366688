import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { CermAssessmentSummaryTab } from './cerm-assessment-summary-tab';
import { loadAttachmentsSite } from 'actions/actions-v2/attachment-action-v2';
import { IUpsertSiteRootState } from 'state/upsert-site-state';
import { loadCermSnapshotsOverviewOnly } from 'actions/actions-v2/cerm-assessment-action-v3';

const getSnapshots = (state: IRootState): typeof state.cermAssessmentResultState.snapshots => state.cermAssessmentResultState.snapshots;
const getSiteAttachments = (state: IRootState): typeof state.siteState.attachments => state.siteState.attachments;
const getAttachmentState = (state: IRootState): typeof state.attachmentState => state.attachmentState;
const getPermissions = (state: IUpsertSiteRootState): typeof state.app.permissions => state.app.permissions;

const mapStateToProps = createSelector(
    getSnapshots,
    getSiteAttachments,
    getAttachmentState,
    getPermissions,
    (snapshots, siteAttachements, attachmentState, permissions) => ({
        snapshots,
        siteImage: siteAttachements,
        isSaving: attachmentState.isSaving,
        permissions
    })
);

const mapDispatchToProps = {
    loadAttachments: loadAttachmentsSite,
    loadCermSnapshotsOverviewOnly,
};

export const CermAssessmentSummaryTabContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CermAssessmentSummaryTab);
