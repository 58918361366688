import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { FormikProps } from 'formik';
import { NavLink } from 'react-router-dom';
import { Control, Field, Help, Input } from '../../../../components/form';
import { Select } from '../../../../components/select';
import { ValueSlider } from '../../../../components/value-slider';
import { BulmaColor } from '../../../../enums/BulmaColor';
import { BulmaSize } from '../../../../enums/BulmaSize';
import { IKeyValue } from '../../../../models/key-value';
import { IQuestionSet } from '../../../../models/question-set';
import { formikFieldCss, getKeyed, scrollToFirstValidationError } from '../../../../utils/form-utils';
import { RouteUrlManageQuestionSets } from '../../routes';
import { Button } from 'components/v2/components';
import { isEmpty } from 'lodash';
import { onRouteChange } from 'actions/app-actions';
import { easeInScrollToTop } from 'utils/animation-utils';
import { emptyQuestion } from 'reducers/create-question-set';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';

interface IRouteParams {
    operatingPlatform?: string;
}

export interface IProps extends RouteComponentProps<IRouteParams> {
    formikBag: FormikProps<Partial<IQuestionSet>>;
    lookupCategories: Array<IKeyValue<string>>;
    lookupUrgencies: Array<IKeyValue<string>>;
    lookupPriorities: Array<IKeyValue<string>>;
    intl: any;
}

class QuestionSetFormClass extends React.PureComponent<IProps> {
    public state = {
        isValid: false,
    };

    public componentWillMount() {
        easeInScrollToTop();
    }

    public render() {
        const intl = this.props.intl;
        const formikBag = this.props.formikBag;
        const onPriorityChanged = (newValue: IKeyValue<string>) => {
            if (newValue !== undefined) {
                this.props.formikBag.setFieldValue('priority', newValue.value);
            }
        };
        const onNavigateToNext: React.MouseEventHandler<HTMLButtonElement> = () => {
            formikBag.validateForm().then((errors) => {
                if (!Object.keys(errors).some((field) => field.length > 0 && field !== 'questions')) {
                    if (isEmpty(this.props.formikBag.values.questions)) {
                        this.props.formikBag.setFieldValue('questions', [emptyQuestion]);
                    }

                    const url = `${RouteUrlManageQuestionSets(this.props.formikBag.values.id)}0/` + this.props.match.params.operatingPlatform;
                    onRouteChange(url);
                } else {
                    scrollToFirstValidationError();
                }
            });
        };

        const getPriorityValue = () => {
            const priorityValue = this.props.formikBag.values.priority;
            return priorityValue !== undefined || priorityValue !== '' ? priorityValue : '1';
        };
        const getErrors = (name: Extract<keyof IQuestionSet, string>) =>
            getKeyed(formikBag, 'errors', `${name}`);

        const getHelpByKey = (name: Extract<keyof IQuestionSet, string>) => (
            <Help
                isShown={formikBag.submitCount > 0 || getErrors(name)}
                bulmaColor={BulmaColor.Danger}
            >
                {getErrors(name)}
            </Help>
        );
        return (
            <>
                <Field
                    isHorizontal={true}
                    htmlFor="title"
                    label={translate(intl, 'ActionRegisterPage.ActionRegisterTable.Title.Title')}
                    labelSize={BulmaSize.Medium}
                >
                    <Field>
                        <Control>
                            <Input
                                id="title"
                                name="title"
                                aria-required="true"
                                placeholder={translate(intl, 'OpportunityQ.MasterForm.TitlePlaceholder')}
                                type="text"
                                className={formikFieldCss(formikBag, 'title')}
                                value={formikBag.values.title}
                                onChange={formikBag.handleChange}
                                onBlur={formikBag.handleBlur}
                            />
                        </Control>
                        {getHelpByKey('title')}
                    </Field>
                </Field>

                <Field
                    isHorizontal={true}
                    htmlFor="category"
                    label={translate(intl, 'RiskRegisterPage.RiskFilter.Title.Category')}
                    labelSize={BulmaSize.Medium}
                >
                    <Field>
                        <Control>
                            <Select
                                id="category"
                                name="category"
                                aria-required="true"
                                options={this.props.lookupCategories}
                                className={formikFieldCss(formikBag, 'category')}
                                value={formikBag.values.category}
                                onChange={formikBag.handleChange}
                                onBlur={formikBag.handleBlur}
                                emptyOptionsValue={translate(intl, 'OpportunityQ.MasterForm.EmptyOption')}
                            />
                        </Control>
                        {getHelpByKey('category')}
                    </Field>
                </Field>

                <Field
                    isHorizontal={true}
                    htmlFor="urgency"
                    label={translate(intl, 'RiskAssessmentFilters.CheckBoxFilters.Urgency')}
                    labelSize={BulmaSize.Medium}
                >
                    <Field>
                        <Control>
                            <Select
                                id="urgency"
                                name="urgency"
                                aria-required="true"
                                options={this.props.lookupUrgencies}
                                className={formikFieldCss(formikBag, 'urgency')}
                                value={formikBag.values.urgency}
                                onChange={formikBag.handleChange}
                                onBlur={formikBag.handleBlur}
                                emptyOptionsValue={translate(intl, 'OpportunityQ.MasterForm.EmptyOption')}
                            />
                        </Control>
                        {getHelpByKey('urgency')}
                    </Field>
                </Field>

                <Field
                    isHorizontal={true}
                    htmlFor="relevancyQuestion"
                    label={translate(intl, 'QuestionnSetList.Timeline.Item4')}
                    labelSize={BulmaSize.Medium}
                >
                    <Field>
                        <Control>
                            <textarea
                                id="relevancyQuestion"
                                name="relevancyQuestion"
                                aria-required="true"
                                className={formikFieldCss(
                                    formikBag,
                                    'relevancyQuestion',
                                    'textarea'
                                )}
                                placeholder={translate(intl, 'CreateQuestionSet.QuestionSetForm.Message')}
                                value={formikBag.values.relevancyQuestion}
                                onBlur={formikBag.handleBlur}
                                onChange={formikBag.handleChange}
                            />
                        </Control>
                        {getHelpByKey('relevancyQuestion')}
                    </Field>
                </Field>

                <Field
                    isHorizontal={true}
                    htmlFor="priority"
                    label={translate(intl, 'RiskActionTab.Label.Priority')}
                    labelSize={BulmaSize.Medium}
                >
                    <Field>
                        <Control>
                            {this.props.lookupPriorities.length > 0 && (
                                <ValueSlider
                                    name="priority"
                                    values={this.props.lookupPriorities}
                                    selectedValue={getPriorityValue()}
                                    onChange={onPriorityChanged}
                                />
                            )}
                        </Control>
                        {getHelpByKey('priority')}
                    </Field>
                </Field>

                <div className="QuestionSetForm__actions">
                    <NavLink to={`/QuestionSets/` + this.props.match.params.operatingPlatform} className="button button-cancel">
                        {translate(intl, 'Globals.Label.Cancel')}
                    </NavLink>
                    <Button onClick={onNavigateToNext}>Next</Button>
                </div>
            </>
        );
    }
}

export const QuestionSetForm = injectIntl(QuestionSetFormClass);
