import { ISkillCard } from 'models/competency-dna/skill-card';
import { IAppAction } from './app-action';
import { ISkillCardQuery } from 'models/competency-dna/skill-card-query';

export enum SkillCardActions {
    LOAD_SKILL_CARDS = '@@skillCard/LOAD_SKILL_CARDS',
    LOAD_SKILL_CARDS_FULFILLED = '@@skillCard/LOAD_SKILL_CARDS_FULFILLED',
    LOAD_SKILL_CARDS_REJECTED = '@@skillCard/LOAD_SKILL_CARDS_REJECTED',

    LOAD_SKILL_CARD = '@@skillCard/LOAD_SKILL_CARD',
    LOAD_SKILL_CARD_FULFILLED = '@@skillCard/LOAD_SKILL_CARD_FULFILLED',
    LOAD_SKILL_CARD_REJECTED = '@@skillCard/LOAD_SKILL_CARD_REJECTED',

    LOAD_SITE_SKILL_CARDS = '@@skillCard/LOAD_SITE_SKILL_CARDS',
    LOAD_SITE_SKILL_CARDS_FULFILLED = '@@skillCard/LOAD_SITE_SKILL_CARDS_FULFILLED',
    LOAD_SITE_SKILL_CARDS_REJECTED = '@@skillCard/LOAD_SITE_SKILL_CARDS_REJECTED',

    CREATE_SKILL_CARD = '@@skillCard/CREATE_SKILL_CARD',
    CREATE_SKILL_CARD_FULFILLED = '@@skillCard/CREATE_SKILL_CARD_FULFILLED',
    CREATE_SKILL_CARD_REJECTED = '@@skillCard/CREATE_SKILL_CARD_REJECTED',

    UPDATE_SKILL_CARD = '@@skillCard/UPDATE_SKILL_CARD',
    UPDATE_SKILL_CARD_FULFILLED = '@@skillCard/UPDATE_SKILL_CARD_FULFILLED',
    UPDATE_SKILL_CARD_REJECTED = '@@skillCard/UPDATE_SKILL_CARD_REJECTED',

    DELETE_SKILL_CARD = '@@skillCard/DELETE_SKILL_CARD',
    DELETE_SKILL_CARD_FULFILLED = '@@skillCard/DELETE_SKILL_CARD_FULFILLED',
    DELETE_SKILL_CARD_REJECTED = '@@skillCard/DELETE_SKILL_CARD_REJECTED'
}

export const loadSkillCards = (query: ISkillCardQuery): IAppAction => ({
    type: SkillCardActions.LOAD_SKILL_CARDS,
    payload: query
});

export const loadSkillCard = (id: string): IAppAction => ({
    type: SkillCardActions.LOAD_SKILL_CARD,
    payload: id
});

export const loadSiteSkillCards = (siteId: string): IAppAction => ({
    type: SkillCardActions.LOAD_SITE_SKILL_CARDS,
    payload: siteId
});

export const createSkillCard = (skillCard: ISkillCard): IAppAction => ({
    type: SkillCardActions.CREATE_SKILL_CARD,
    payload: skillCard
});

export const updateSkillCard = (skillCard: ISkillCard): IAppAction => ({
    type: SkillCardActions.UPDATE_SKILL_CARD,
    payload: skillCard
});

export const deleteSkillCard = (id: string): IAppAction => ({
    type: SkillCardActions.DELETE_SKILL_CARD,
    payload: id,
});