import { createSelector } from 'reselect';
import { getUpsertCermProcessStepState } from '../common';
import { IRootState } from 'routes/store';

const getOperatingPlatforms = (state: IRootState) =>
    state.operatingPlatformState.operatingPlatforms;

export default createSelector(
    getUpsertCermProcessStepState,
    getOperatingPlatforms,
    (state,operatingPlatforms) => ({
        isSubmitting: state.isSubmitting,
        operatingPlatforms
    })
);
