import React from 'react';
import { Table, Panel, TableHeader, SortOrder } from '../v2/components';
import { onRouteChange } from 'actions/app-actions';
import { DateTime, Format } from '../dateTime';
import { sortArrayBy } from 'utils/sort-utils';
import { IAction } from 'models/action';
import { getActionPriority } from './shared/action-register-utils';
import { usePageUIPreferences, UiPreferences } from 'utils/page-ui-preferences-utils';
import useTranslate from 'translations/translation-utils';
import { getLabelsWithSeparator } from 'utils/keyvalue-utils';
import { useCallback } from 'react';
import { IEmbeddedLink } from 'models/embeddedLink';
import { IRisk } from 'models/risk';
import './action-register-table.scss';
import * as ItemStatus from 'models/risk-statuses';
interface IProps {
    componentId?: string;
    title?: string;
    actions: IAction[];
    removeColumnHeaders?: boolean;
    removePanelHeader?: boolean;
    type?: 'info' | 'warning';
    collapsed?: boolean;
    isMacroActions?: boolean;
}

export const ActionRegisterTable: React.FC<IProps> = ({
    title,
    actions,
    removeColumnHeaders,
    removePanelHeader,
    type,
    collapsed,
    componentId = 'action-table',
    isMacroActions = false,
}) => {
    const translate = useTranslate();
    const [sortField, setSortField] = usePageUIPreferences(
        UiPreferences.ActionsPageSortField,
        'actionLevel'
    );
    const [sortOrder, setSortOrder] = usePageUIPreferences(
        UiPreferences.ActionsPageSortOrder,
        SortOrder.off
    );

    const onToggleHandler = (field: string) => {
        if (sortField !== field) {
            setSortField(field);
            setSortOrder(SortOrder.asc);
            return;
        }
        const sort = sortOrder === SortOrder.asc ? SortOrder.desc : SortOrder.asc;
        setSortOrder(sort);
    };

    const getSortState = (field: string): SortOrder => {
        if (field === sortField) {
            return sortOrder;
        }
        return SortOrder.off;
    };

    const getSortedList = (): IAction[] => {
        if (sortOrder === SortOrder.off) {
            return actions;
        }
        return sortOrder === SortOrder.asc
            ? sortArrayBy(sortField, actions, true)
            : sortArrayBy(sortField, actions, false);
    };

    const openDetails = (id: string, category: string): void => {
        if (isMacroActions) {
            onRouteChange(`/Action/${id}/macro/details/`);
        } else {
            onRouteChange(`/Action/${id}/${category}/details/`);
        }
    };

    const getSortableTableHeader = (
        key: Extract<keyof IAction, string>,
        displayName: string,
        className?: string
    ) => {
        return (
            <TableHeader
                sortState={getSortState(key)}
                onToggle={() => onToggleHandler(key)}
                className={className}
            >
                {displayName}
            </TableHeader>
        );
    };

    const buildTableInPanel = () => (
        <Panel
            title={title ?? translate('ActionRegisterPage.ActionRegisterTable.Title.TableTitle')}
            type={type}
            collapsed={collapsed}
            componentId={componentId}
        >
            {buildTable()}
        </Panel>
    );

    const truncateText = useCallback((text: string, length: number): string => {
        const truncatedText = text && text.length > length ? text.slice(0, length) + '...' : text;
        return truncatedText;
    }, []);

    const openRiskDetails = useCallback((id: string): void => {
        onRouteChange(`/RiskRegister/${id}/details/?macro=${isMacroActions}`);
    }, [isMacroActions]);

    const generateAssociatedIncidentsLink = useCallback((incidents: IEmbeddedLink[], parentRisk: IRisk): JSX.Element | string => {
        return (
            <>
                {incidents.map((incident, index) => {
                    const isLast = index === incidents.length - 1;
                    const incidentTitle = incident.uid && incident.displayLabel ? `${incident.uid} - ${incident.displayLabel}` : incident.displayLabel;
                    if (incident.status !== ItemStatus.CLOSED) {
                        return (
                            <span key={index}>
                                <a key={index} href="javascript:void(0);" className='anchor-link' onClick={() => onRouteChange(`/Incident/${incident.id}/details`)}>
                                    {(parentRisk === null && incidents.length === 1) ? <span className='truncate' title={incident.displayLabel}>{truncateText(incidentTitle, 40)}</span> : incident.uid}
                                    {!isLast && ' / '}</a>
                            </span>)
                    }
                    else {
                        return (
                            <span key={index}>
                                {(parentRisk === null && incidents.length === 1) ? <span className='truncate' title={incident.displayLabel}>{truncateText(incidentTitle, 40)}</span> : incident.uid}
                                {!isLast && ' / '}
                            </span>)
                    }
                })}
            </>
        );
    }, [truncateText]);

    const generateAssociatedRiskLink = useCallback((parentRisk: IRisk, incidents: IEmbeddedLink[]): JSX.Element | string => {
        const riskTitleWithUid = parentRisk.uid && parentRisk.title ? `${parentRisk.uid} - ${parentRisk.title}` : parentRisk.title;
        if (parentRisk !== null && parentRisk.status !== ItemStatus.CLOSED) {
            return (
                <a href="javascript:void(0);" className='anchor-link' onClick={() => openRiskDetails(parentRisk.id)}>
                    {incidents.length === 0 ?
                        <span className='truncate' title={parentRisk.title}>
                            {truncateText(riskTitleWithUid, 40)}</span>
                        : parentRisk.uid}
                </a>)
        }
        else {
            return incidents.length === 0 ?
                <span className='truncate' title={parentRisk.title}>
                    {truncateText(riskTitleWithUid, 40)}</span>
                : parentRisk.uid
        }
    }, [truncateText, openRiskDetails]);

    const showAssociatedItems = useCallback((actionItems: IAction): React.JSX.Element | "N/A" => {
        const incidents = actionItems.parents && actionItems.parents.filter((x) => x.bucket === 'Incident');
        const generatedAssociatedIncidentsLink = generateAssociatedIncidentsLink(incidents, actionItems?.parentRisk);

        if (actionItems && actionItems.parentRisk !== null && incidents && incidents.length > 0) {
            const riskItemUid = generateAssociatedRiskLink(actionItems.parentRisk, incidents);
            return <span>{riskItemUid}{(actionItems?.parentRisk?.uid && incidents.length > 0) ? ' / ' : ''}{generatedAssociatedIncidentsLink} </span>;
        }
        if (actionItems && actionItems.parentRisk !== null && incidents && incidents.length === 0) {
            const riskItemUid = generateAssociatedRiskLink(actionItems.parentRisk, incidents);
            return <span>{riskItemUid}</span>;
        }
        if ((actionItems && incidents && incidents.length > 0 && actionItems.parentRisk === null)) {
            return <span>{generatedAssociatedIncidentsLink}</span>;
        }
        if ((actionItems && incidents && incidents.length === 0 && actionItems.parentRisk === null)) {
            return 'N/A';
        }
    }, [generateAssociatedRiskLink, generateAssociatedIncidentsLink])

    const buildTable = () => (
        <Table>
            {!removeColumnHeaders && (
                <thead>
                    <tr>
                        {getSortableTableHeader(
                            'title',
                            translate('ActionRegisterPage.ActionRegisterTable.Title.Title'),
                            'action-title'
                        )}
                        {isMacroActions && (
                            <>
                                {getSortableTableHeader(
                                    'macroLevel',
                                    translate('ActionRegisterPage.ActionRegisterTable.Title.Level'),
                                    'narrow'
                                )}
                                {getSortableTableHeader(
                                    'macroLevelValues',
                                    translate('ActionRegisterPage.ActionRegisterTable.Title.LevelStatus'),
                                    'narrow'
                                )}
                            </>
                        )}
                        {getSortableTableHeader(
                            'status',
                            translate('ActionRegisterPage.ActionRegisterTable.Title.Status'),
                            'narrow'
                        )}
                        {getSortableTableHeader(
                            'priorityId',
                            translate('ActionRegisterPage.ActionRegisterTable.Title.Priority'),
                            'narrow'
                        )}
                        {getSortableTableHeader(
                            'category',
                            translate('ActionRegisterPage.ActionRegisterTable.Title.Category'),
                            'narrow'
                        )}
                        {getSortableTableHeader(
                            'riskItemId',
                            translate('ActionRegisterPage.ActionRegisterTable.Title.AssociatedItem'),
                            'fix-cell-width'
                        )}
                        {getSortableTableHeader(
                            'dueDate',
                            translate('ActionRegisterPage.ActionRegisterTable.Title.DueDate'),
                            'narrow'
                        )}
                    </tr>
                </thead>
            )}
            <tbody className="clickable">
                {getSortedList().map((item, index) => (
                    <tr
                        key={`action-row-${index}`}
                    >
                        <td onClick={() => openDetails(item.id, item.category)}>{item.title}</td>
                        {isMacroActions && (
                            <>
                                <td>{item.macroLevel}</td>
                                <td>{getLabelsWithSeparator(item.macroLevelValues, true)}</td>
                            </>
                        )}
                        <td onClick={() => openDetails(item.id, item.category)}>{translate('ActionDetailsPage.Status.dropdown.', item.status)}</td>
                        <td className="no-wrap" onClick={() => openDetails(item.id, item.category)}>{getActionPriority(item)}</td>
                        <td onClick={() => openDetails(item.id, item.category)}>{translate('ActionDetailsPage.Category.dropdown.', item.category)}</td>
                        <td className="fix-cell-width">{showAssociatedItems(item)}</td>
                        <td className="no-wrap" onClick={() => openDetails(item.id, item.category)}>
                            <DateTime value={item.dueDate} format={Format.DateOnly} />
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );

    return <>{removePanelHeader ? buildTable() : buildTableInPanel()}</>;
};
