import { ISiteFilters } from 'models/site-filters';
import { IAppAction } from './../app-action';
import { ISiteAssessmentSummary, ISiteProfile } from 'models/site-profile';

export enum SiteActions {
    LOAD_SITES = '@@sites/LOAD_SITES',
    LOAD_SITES_FULFILLED = '@@sites/LOAD_SITES_FULFILLED',
    LOAD_SITES_REJECTED = '@@sites/LOAD_SITES_REJECTED',

    LOAD_SITE = '@@sites/LOAD_SITE',
    LOAD_SITE_FULFILLED = '@@sites/LOAD_SITE_FULFILLED',
    LOAD_SITE_REJECTED = '@@sites/LOAD_SITE_REJECTED',
    LOAD_SITE_INITIAL_VALUES = '@@sites/LOAD_SITE_INITIAL_VALUES',

    CREATE_SITE = '@@sites/CREATE_SITE',
    CREATE_SITE_FULFILLED = '@@sites/CREATE_SITE_FULFILLED',
    CREATE_SITE_REJECTED = '@@sites/CREATE_SITE_REJECTED',

    UPDATE_SITE = '@@sites/UPDATE_SITE',
    UPDATE_SITE_FULFILLED = '@@sites/UPDATE_SITE_FULFILLED',
    UPDATE_SITE_REJECTED = '@@sites/UPDATE_SITE_REJECTED',

    UPDATE_SITE_NO_NAV = '@@sites/UPDATE_SITE_NO_NAV',
    UPDATE_SITE_FULFILLED_NO_NAV = '@@sites/UPDATE_SITE_FULFILLED_NO_NAV',
    UPDATE_SITE_REJECTED_NO_NAV = '@@sites/UPDATE_SITE_REJECTED_NO_NAV',

    UPDATE_SITE_ASSESSMENT_SUMMARY = '@@cerm/UPDATE_SITE_ASSESSMENT_SUMMARY',
    UPDATE_SITE_ASSESSMENT_SUMMARY_FULFILLED = '@@cerm/UPDATE_SITE_ASSESSMENT_SUMMARY_FULFILLED',
    UPDATE_SITE_ASSESSMENT_SUMMARY_REJECTED = '@@cerm/UPDATE_SITE_ASSESSMENT_SUMMARY_REJECTED',

    EXPORT_SITES = '@@sites/EXPORT_SITES',
    EXPORT_SITES_FULFILLED = '@@sites/EXPORT_SITES_FULFILLED',
    EXPORT_SITES_REJECTED = '@@sites/EXPORT_SITES_REJECTED',

    VALIDATE_MYDATA_FACILITY_SITES = '@@sites/VALIDATE_MYDATA_FACILITY_SITES',
    VALIDATE_MYDATA_FACILITY_SITES_FULFILLED = '@@sites/VALIDATE_MYDATA_FACILITY_SITES_FULFILLED',
    VALIDATE_MYDATA_FACILITY_SITES_REJECTED = '@@sites/VALIDATE_MYDATA_FACILITY_SITES_REJECTED',

    SORT_SITES = '@@sites/SORT_SITES',

    DELETE_SITE = '@@sites/DELETE_SITE',
    DELETE_SITE_FULFILLED = '@@sites/DELETE_SITE_FULFILLED',
    DELETE_SITE_REJECTED = '@@sites/DELETE_SITE_REJECTED',

    DELETE_BUILDING = '@@sites/DELETE_BUILDING',
    RESET_SITE = '@@sites/RESET_SITE',

    LOAD_RELATIONSHIPS = '@@sites/LOAD_RELATIONSHIPS',
    LOAD_RELATIONSHIPS_FULFILLED = '@@sites/LOAD_RELATIONSHIPS_FULFILLED',
    LOAD_RELATIONSHIPS_REJECTED = '@@sites/LOAD_RELATIONSHIPS_REJECTED',
}

export const createSite = (site: ISiteProfile): IAppAction => ({
    type: SiteActions.CREATE_SITE,
    payload: site,
});

export const updateSite = (site: ISiteProfile): IAppAction => ({
    type: SiteActions.UPDATE_SITE,
    payload: site,
});

export const updateSiteNoNav = (site: ISiteProfile): IAppAction => ({
    type: SiteActions.UPDATE_SITE_NO_NAV,
    payload: site,
});

export const updateAssessmentSummary = (
    id: string,
    assessmentSummary: ISiteAssessmentSummary
): IAppAction => ({
    type: SiteActions.UPDATE_SITE_ASSESSMENT_SUMMARY,
    payload: { id, ...assessmentSummary },
});

export const loadSite = (siteId: string): IAppAction => ({
    type: SiteActions.LOAD_SITE,
    payload: siteId,
});

export const loadSites = (filters: ISiteFilters): IAppAction => ({
    type: SiteActions.LOAD_SITES,
    payload: filters,
});

export const onSort = (
    sortColumn: Extract<keyof ISiteProfile, string>,
    sortAscending: boolean
) => ({
    type: SiteActions.SORT_SITES,
    payload: { sortColumn, sortAscending },
});

export const deleteSite = (id: string): IAppAction => ({
    type: SiteActions.DELETE_SITE,
    payload: id,
});

export const deleteBuilding = (id: string, backLinkUrl: string): IAppAction => ({
    type: SiteActions.DELETE_BUILDING,
    payload: { id, backLinkUrl },
});

export const exportSites = () => ({
    type: SiteActions.EXPORT_SITES,
});

export const validateMyDataFacilitySites = (siteId: string, propertyIds: string[]) => ({
    type: SiteActions.VALIDATE_MYDATA_FACILITY_SITES,
    payload: { siteId, propertyIds }
});

export const resetSite = (siteId: string): IAppAction => ({
    type: SiteActions.RESET_SITE,
    payload: siteId,
});

export const loadRelationships = (siteId: string): IAppAction => ({
    type: SiteActions.LOAD_RELATIONSHIPS,
    payload: siteId,
});