import classnames from 'classnames';
import * as React from 'react';
import './Tag.scss';
import { BulmaSize } from '../../enums/BulmaSize';

export interface IProps {
    className?: string;
    onDelete?: () => void;
    size?: BulmaSize;
}



const css = (props: IProps) => classnames(
    'Tag',
    props.size,
    props.className
);

const buttonCss = (props: IProps) => classnames(
    'delete',
    {
        [BulmaSize.Small]: !props.size || props.size === BulmaSize.Small || props.size === BulmaSize.Medium,
        [BulmaSize.Medium]: props.size === BulmaSize.Large
    }
);

export const Tag: React.FC<React.PropsWithChildren<IProps>> = (props) => (
    <span className={css(props)}>
        <span className="is-ellipsis">{props.children}</span>
        {props.onDelete && (<button
            className={buttonCss(props)}
            onClick={props.onDelete}
        />)}
    </span>
);
