import { IKeyValue } from 'models';

export enum CurrencyIsoCode {
    USD = 'USD',
    GBP = 'GBP',
    EUR = 'EUR',
    ARP = 'ARP',
    ARS = 'ARS',
    AUD = 'AUD',
    BGN = 'BGN',
    BRC = 'BRC',
    BRL = 'BRL',
    CAD = 'CAD',
    CHF = 'CHF',
    CLP = 'CLP',
    CNY = 'CNY',
    COP = 'COP',
    CRC = 'CRC',
    DKK = 'DKK',
    HKD = 'HKD',
    ILS = 'ILS',
    INR = 'INR',
    JPY = 'JPY',
    KES = 'KES',
    KRW = 'KRW',
    MXN = 'MXN',
    MYR = 'MYR',
    NOK = 'NOK',
    NZD = 'NZD',
    PEN = 'PEN',
    PLN = 'PLN',
    RON = 'RON',
    SAR = 'SAR',
    SEK = 'SEK',
    SGD = 'SGD',
    SKW = 'SKW',
    THB = 'THB',
    TRL = 'TRL',
    TRY = 'TRY',
    TWD = 'TWD',
    UYU = 'UYU',
    VEF = 'VEF',
    YEN = 'YEN',
    ZAR = 'ZAR'
}

export const getCurrencyIsoCodes = (): Array<IKeyValue<string>> =>
    Object.keys(CurrencyIsoCode).map((key) => ({ key, value: key }));
