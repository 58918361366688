import { IAppAction } from '../app-action';

export enum DashboardActions {
    LOAD_DASHBOARD_ACTION = '@@dashboard/LOAD_DASHBOARD_ACTION',
    LOAD_DASHBOARD_ACTION_FULFILLED = '@@dashboard/LOAD_DASHBOARD_ACTION_FULFILLED',
    LOAD_DASHBOARD_ACTION_REJECTED = '@@dashboard/LOAD_DASHBOARD_ACTION_REJECTED',

    LOAD_DASHBOARD_RISK_REGISTER = '@@dashboard/LOAD_DASHBOARD_RISK_REGISTER',
    LOAD_DASHBOARD_RISK_REGISTER_FULFILLED = '@@dashboard/LOAD_DASHBOARD_RISK_REGISTER_FULFILLED',
    LOAD_DASHBOARD_RISK_REGISTER_REJECTED = '@@dashboard/LOAD_DASHBOARD_RISK_REGISTER_REJECTED',

    LOAD_DASHBOARD_INCIDENT = '@@dashboard/LOAD_DASHBOARD_INCIDENT',
    LOAD_DASHBOARD_INCIDENT_FULFILLED = '@@dashboard/LOAD_DASHBOARD_INCIDENT_FULFILLED',
    LOAD_DASHBOARD_INCIDENT_REJECTED = '@@dashboard/LOAD_DASHBOARD_INCIDENT_REJECTED',

    LOAD_DASHBOARD_CALENDAR_EVENT = '@@dashboard/LOAD_DASHBOARD_CALENDAR_EVENT',
    LOAD_DASHBOARD_CALENDAR_EVENT_FULFILLED = '@@dashboard/LOAD_DASHBOARD_CALENDAR_EVENT_FULFILLED',
    LOAD_DASHBOARD_CALENDAR_EVENT_REJECTED = '@@dashboard/LOAD_DASHBOARD_CALENDAR_EVENT_REJECTED',

    LOAD_DASHBOARD_CERM_OVERVIEW = '@@dashboard/LOAD_DASHBOARD_CERM_OVERVIEW',
    LOAD_DASHBOARD_CERM_OVERVIEW_FULFILLED = '@@dashboard/LOAD_DASHBOARD_CERM_OVERVIEW_FULFILLED',
    LOAD_DASHBOARD_CERM_OVERVIEW_REJECTED = '@@dashboard/LOAD_DASHBOARD_CERM_OVERVIEW_REJECTED'
}

export const loadDashboardAction = (siteId: string): IAppAction => ({
    type: DashboardActions.LOAD_DASHBOARD_ACTION,
    payload: siteId
});

export const loadDashboardRiskRegister = (siteId: string): IAppAction => ({
    type: DashboardActions.LOAD_DASHBOARD_RISK_REGISTER,
    payload: siteId
});

export const loadDashboardCalendarEvent = (siteId: string): IAppAction => ({
    type: DashboardActions.LOAD_DASHBOARD_CALENDAR_EVENT,
    payload: siteId
});

export const loadDashboardIncident = (siteId: string): IAppAction => ({
    type: DashboardActions.LOAD_DASHBOARD_INCIDENT,
    payload: siteId
});

export const loadDashboardCermOverview = (siteId: string): IAppAction => ({
    type: DashboardActions.LOAD_DASHBOARD_CERM_OVERVIEW,
    payload: siteId
});
