import { IIncident, IPersonSummary, IIncidentRisksAndActions } from '../models';
import { FormikProps } from 'formik';
import { IPersonLookup } from '../models/person-lookup';
import { IMandatoryClaimCollections } from '../models/incidents/incident-settings';
import { IRisk } from '../models/risk';
import { IAction } from '../models/action';
import _ from 'lodash';

export function getRisks(allAvailableRisks: IRisk[], risksAndActions: IIncidentRisksAndActions) {
    if (!allAvailableRisks || !risksAndActions || !risksAndActions.risks) {
        return [];
    }
    const risks: IRisk[] = [];
    risksAndActions.risks.map(m => {
        const riskDetails = allAvailableRisks.find(f => f.id === m);
        if (riskDetails) {
            risks.push(riskDetails);
        }
    });
    return risks;
}

export function getActions(
    allAvailableActions: IAction[],
    risksAndActions: IIncidentRisksAndActions
) {
    if (!allAvailableActions || !risksAndActions || !risksAndActions.actions) {
        return [];
    }
    const actions: IAction[] = [];
    risksAndActions.actions.map(m => {
        const actionDetails = allAvailableActions.find(f => f.id === m);
        if (actionDetails) {
            actions.push(actionDetails);
        }
    });
    return actions;
}

export function resetFormikApproversOnSiteChange(
    formikBag: FormikProps<Partial<IIncident>>,
    users: IPersonLookup[]
) {
    const siteId = formikBag.values.siteId;
    const aloudUsers = users.filter(m => {
        if (m.siteIds.some(s => s === siteId)) {
            return m;
        }
    });

    formikBag.setFieldValue(
        'approvers',
        formikBag.values.approvers.filter(f => aloudUsers.some(s => s.id === f))
    );
}

export function getSiteUserIds(siteId: string, users: IPersonLookup[]) {
    return users.map(f => {
        if (f.siteIds.some(s => s === siteId)) {
            return f.id;
        }
    });
}

export function getMandatoryPeopleInfo(
    formikBag: Partial<IIncident>,
    users: IPersonLookup[],
    mandatoryClaimCollections: IMandatoryClaimCollections
) {
    const siteSpecificClaims =
        mandatoryClaimCollections &&
        mandatoryClaimCollections.siteSpecificClaims &&
        mandatoryClaimCollections.siteSpecificClaims.find(
            f => f.incidentSeverity === formikBag.severity
        );

    const granularClaims =
        mandatoryClaimCollections &&
        mandatoryClaimCollections.granularClaims &&
        mandatoryClaimCollections.granularClaims.find(
            f => f.incidentSeverity === formikBag.severity
        );

    let roles: string[] = [];

    if (granularClaims) {
        roles = granularClaims.roles;
    }

    const siteId = formikBag.siteId;
    const mandatoryUserIds = [] as string[];

    for (const user of users) {
        if (granularClaims) {
            if (roles) {
                if (user.roleNames.some(roleName => roles.some(role => role === roleName))) {
                    mandatoryUserIds.push(user.id);
                    continue;
                }
            }
        }

        if (siteSpecificClaims) {
            if (siteSpecificClaims.siteClaim.some((s: any) => s === 'SiteLead')) {
                if (user.siteLeads.some((s: any) => s === siteId)) {
                    mandatoryUserIds.push(user.id);
                    continue;
                }
            }
            if (siteSpecificClaims.siteClaim.some((s: any) => s === 'ContractSupport')) {
                if (user.contractSupports.some((s: any) => s === siteId)) {
                    mandatoryUserIds.push(user.id);
                    continue;
                }
            }
            if (siteSpecificClaims.siteClaim.some((s: any) => s === 'Approver')) {
                if (user.approvers.some((s: any) => s === siteId)) {
                    mandatoryUserIds.push(user.id);
                    continue;
                }
            }
        }
    }

    return getPersonInfo(siteId, mandatoryUserIds, users, false, false);
}

export function getPersonInfo(
    siteId: string,
    userIds: string[],
    allPeople: IPersonLookup[],
    includeCRO: boolean = true,
    includeClient: boolean = true
) {
    const people = [] as IPersonSummary[];
    if (_.isEmpty(userIds) || _.isEmpty(allPeople)) {
        return people;
    }

    for (const m of allPeople) {
        if (
            (m && m.siteIds.some((s: any) => s === siteId) && userIds.some(s => s === m.id)) ||
            ((includeCRO || userIds.some(s => m.id === s)) && m.roleNames.includes('CRO'))
        ) {
            const siteRoles = [] as string[];
            if (m && m.siteLeads.some((s: any) => s === siteId)) {
                siteRoles.push('Site Lead');
            }
            if (m && m.approvers.some((s: any) => s === siteId)) {
                siteRoles.push('L1 Approver');
            }
            if (m && m.contractSupports.some((s: any) => s === siteId)) {
                siteRoles.push('Contract Support');
            }
            if (m && m.clients.some((s: any) => s === siteId)) {
                if (!includeClient) {
                    continue;
                }
                siteRoles.push('Client');
            }
            if (m && m.clientProxy.some(s => s === siteId)) {
                siteRoles.push('Client Proxy');
            }
            if (m && m.roleNames && m.roleNames.includes('CRO')) {
                siteRoles.push('CRO');
            }
            people.push({
                id: m.id,
                fullName: `${m.firstName} ${m.lastName}`,
                firstName: m.firstName,
                lastName: m.lastName,
                email: m.email,
                siteRoles,
                siteIds: m.siteIds,
                roles: m.roleNames,
            });
        }
    }

    return people;
}
