import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { getPermissions } from 'sharedSelectors';

export const getPersonLookups = (state: IRootState) => {
    const siteWideAloudUserRoleList = [
        'Area Manager',
        'Contract Support',
        'Executive Leader',
        'Product Administrator',
        'Leader',
        'Regional Leader',
        'Site Manager',
        'Technician',
        'Client',
        'Global Super User',
    ];
    return state.lookups.personLookups.filter((c) =>
        c.roleNames.some((s) => siteWideAloudUserRoleList.indexOf(s) >= 0)
    );
};
export const getRelationships = (state: IRootState) => state.siteState.relationships;
export default createSelector(
    getPersonLookups,
    getPermissions,
    getRelationships,
    (personLookups, permissions, relationships) => ({
        users:
            personLookups && personLookups.length > 1
                ? personLookups.slice().sort((a, b) => (a.label > b.label ? 1 : -1))
                : personLookups,
        permissions,
        relationships,
    }));
