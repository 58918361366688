import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import {
    loadMockDrill,
    createMockDrill,
    updateMockDrill,
    cleanupMockDrill
} from 'actions/actions-v2/mock-drills-actions';
import { MockDrillUpsertPage } from './mock-drill-upsert-page';

const getMockDrill = (state: IRootState) => state.mockDrillsState.mockDrill;
const getIsLoading = (state: IRootState) => state.mockDrillsState.isLoading;
const getIsSaving = (state: IRootState) => state.mockDrillsState.isSaving;
const getSiteId = (state: IRootState) => state.app.siteId;
const getAvailableSites = (state: IRootState) => state.app.lookupSites;
const getClients = (state: IRootState) => state.app.lookupClients;
const getIsLocked = (state: IRootState) => {
    return state.mockDrillsState.mockDrill ? state.mockDrillsState.mockDrill.isLocked : false;
};

const getLineOfBusiness = (state: IRootState) => state.app.lookupLineOfBusiness;
const getPersonProfile = (state: IRootState) => state.person.personProfile;

const mapStateToProps = createSelector(
    getMockDrill,
    getIsLoading,
    getSiteId,
    getAvailableSites,
    getClients,
    getIsSaving,
    getIsLocked,
    getLineOfBusiness,
    getPersonProfile,
    (mockDrill, isLoading, siteId, availableSites, clients, isSaving, isLocked, lineOfBusiness, personProfile) => ({
        mockDrill,
        isLoading,
        siteId,
        availableSites,
        clients,
        isSaving,
        isLocked,
        lineOfBusiness,
        personProfile,
    })
);

const mapDispatchToProps = {
    loadMockDrill,
    createMockDrill,
    updateMockDrill,
    cleanupMockDrill
};

export const MockDrillUpsertPageContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MockDrillUpsertPage)
);
