import { IAppAction } from './../app-action';
import {IVerificationCode} from 'models/verification-code';

export enum ClientVerificationActions {
    SEND_VERIFICATION = '@@client-verification/SEND_VERIFICATION',
    SEND_VERIFICATION_FULFILLED = '@@client/SEND_VERIFICATION_FULFILLED',
    SEND_VERIFICATION_REJECTED = '@@client/SEND_VERIFICATION_REJECTED',

    VALIDATE_VERIFICATION_CODE = '@@client-verification/VALIDATE_VERIFICATION_CODE',
    VALIDATE_VERIFICATION_CODE_FULFILLED = '@@client/VALIDATE_VERIFICATION_CODE_FULFILLED',
    VALIDATE_VERIFICATION_CODE_REJECTED = '@@client/VALIDATE_VERIFICATION_CODE_REJECTED',

}

export const sendVerificationCode = (): IAppAction => ({
    type: ClientVerificationActions.SEND_VERIFICATION,
    payload: {},
});


export const validateVerificationCode = (model: IVerificationCode): IAppAction => ({
    type: ClientVerificationActions.VALIDATE_VERIFICATION_CODE,
    payload: model,
});