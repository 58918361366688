import { FormikProps } from 'formik';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { isNil } from 'lodash';
import { Control, Field, Help, Input, Radio } from 'components/form';
import { Select } from 'components/select';
import { ValueSlider } from 'components/value-slider';
import { BulmaColor } from 'enums/BulmaColor';
import { BulmaSize } from 'enums/BulmaSize';
import { IKeyValue } from 'models/key-value';
import { IActionTemplate } from 'models/question-set';
import { IOutcome, IProcessStep } from 'reducers/cermAssessments';
import { dueDateUnitOptions } from 'routes/create-question-set/views/common';
import { formikFieldCssByState, getKeyed } from 'utils/form-utils';
import { ICermAssessmentRouteProps } from '../../../cerm-assessments-page';
import useTranslate from 'translations/translation-utils';

export interface IProps extends RouteComponentProps<ICermAssessmentRouteProps> {
    lookupActionPriorities: Array<IKeyValue<string>>;
    lookupActionCategories: Array<IKeyValue<string>>;
    formikBag: FormikProps<Partial<IProcessStep>>;
}

export const ActionForm: React.FC<IProps> = ({
    lookupActionPriorities,
    lookupActionCategories,
    formikBag,
    match
}) => {
    const translate = useTranslate();
    const outcomeIndex = Number(match.params && match.params.outcomeId);
    const entityName: Extract<keyof IOutcome, string> = 'action';
    const prefix = `expectedOutcomeAnswers[${outcomeIndex}].${entityName}`;

    if (!formikBag.values.expectedOutcomeAnswers[outcomeIndex].action) {
        const defaultValues: IActionTemplate = {
            description: `CERM ${formikBag.values.processNumber} ${translate('ActionForm.Template.Description')} ${formikBag.values.title}`,
            categoryId: '9',
            priority: '1',
            dueDateUnit: 'day'
        };

        switch (formikBag.values.priority) {
            case '1':
                defaultValues.priority = '1';
                defaultValues.dueDateValue = 60;
                break;
            case '3':
                defaultValues.priority = '2';
                defaultValues.dueDateValue = 30;
                break;
            case '9':
                defaultValues.priority = '3';
                defaultValues.dueDateValue = 14;
                break;
            case '27':
                defaultValues.priority = '4';
                defaultValues.dueDateValue = 7;
                break;
        }
        formikBag.setFieldValue(`${prefix}`, defaultValues);
        return <></>;
    }

    const field = (name: Extract<keyof IActionTemplate, string>) => `${prefix}.${name}`;

    function value<T extends Extract<keyof IActionTemplate, string>>(name: T) {
        const val = getKeyed(formikBag, 'values', field(name));
        return !isNil(val) ? val : '';
    }

    const getErrors = (name: Extract<keyof IActionTemplate, string>) => getKeyed(formikBag, 'errors', field(name));
    const isTouched = (name: Extract<keyof IActionTemplate, string>) => getKeyed(formikBag, 'touched', field(name));

    const getFieldCss = (name: Extract<keyof IActionTemplate, string>, otherCss?: string) =>
        formikFieldCssByState(Boolean(getErrors(name)), isTouched(name), otherCss);

    const getHelpByKey = (name: Extract<keyof IActionTemplate, string>) => (
        <Help
            isShown={formikBag.submitCount > 0 || isTouched(name)}
            bulmaColor={BulmaColor.Danger}
        >
            {getErrors(name)}
        </Help>
    );

    const onPriorityChanged = (newValue: IKeyValue<string>) => {
        formikBag.setFieldValue(field('priority'), newValue.key);
    };

    const getPriorityValue = () => {
        const priorityValue = value('priority');
        return priorityValue !== undefined || priorityValue !== '' ? priorityValue : '1';
    };

    return (
        <>
            <header>
                <h2 className="title is-5">
                    {translate('ActionForm.Template.Title')}
                </h2>
            </header>

            <Field
                isHorizontal={true}
                htmlFor={field('description')}
                label={translate('ActionDetails.Lable.2')}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <textarea
                            id={field('description')}
                            name={field('description')}
                            className={getFieldCss('description', 'textarea')}
                            placeholder={translate('ActionDetails.Lable.3')}
                            value={value('description')}
                            onBlur={formikBag.handleBlur}
                            onChange={formikBag.handleChange}
                        />
                    </Control>
                    {getHelpByKey('description')}
                </Field>
            </Field>

            <Field
                isHorizontal={true}
                htmlFor={field('categoryId')}
                label={translate('ActionDetailsPage.label.Category')}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <Select
                            id={field('categoryId')}
                            name={field('categoryId')}
                            aria-required="true"
                            options={lookupActionCategories}
                            className={getFieldCss('categoryId')}
                            value={value('categoryId')}
                            onChange={formikBag.handleChange}
                            onBlur={formikBag.handleBlur}
                            emptyOptionsValue={translate('RiskActionTab.Placeholder.emptyOptionsValue')}
                        />
                    </Control>
                </Field>
            </Field>

            <Field
                isHorizontal={true}
                htmlFor={field('priority')}
                label={translate('ActionRegisterPage.ActionRegisterTable.Title.Priority')}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <ValueSlider
                            name={field('priority')}
                            values={lookupActionPriorities}
                            selectedValue={getPriorityValue()}
                            onChange={onPriorityChanged}
                        />
                    </Control>
                    {getHelpByKey('priority')}
                </Field>
            </Field>

            <Field
                isHorizontal={true}
                htmlFor={field('dueDateValue')}
                label={translate('ActionDetails.Lable.6')}
                labelSize={BulmaSize.Medium}
                className="is-vcentered actionDueDate"
            >
                <Field>
                    <Control>
                        <Input
                            id={field('dueDateValue')}
                            name={field('dueDateValue')}
                            placeholder="0"
                            type="number"
                            min={0}
                            size={2}
                            className={getFieldCss('dueDateValue')}
                            value={value('dueDateValue')}
                            onChange={formikBag.handleChange}
                            onBlur={formikBag.handleBlur}
                        />
                    </Control>
                </Field>
                <Field>
                    <Control>
                        <Radio
                            id={field('dueDateUnit')}
                            name={field('dueDateUnit')}
                            options={dueDateUnitOptions}
                            className={getFieldCss('dueDateUnit')}
                            value={value('dueDateUnit')}
                            onBlur={formikBag.handleBlur}
                            onChange={formikBag.handleChange}
                        />
                    </Control>
                </Field>
            </Field>
        </>
    );
};
