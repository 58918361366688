import * as React from 'react';
import './ModalCard.scss';

export const ModalCardFooter: React.FC<React.PropsWithChildren> = (props) => {
    return (
        <footer className="modal-card-foot">
            {props.children}
        </footer>
    );
};
