import { connect } from 'react-redux';

import actions from './actions';
import { ActionUpsert as view } from 'routes/actions/views/actionUpsert/ActionUpsert';
import selector from 'routes/actions/views/actionUpsert/selectors';

export const ActionUpsert = connect(
    selector,
    actions
)(view);
