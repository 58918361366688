import * as React from 'react';
import { connect } from 'react-redux';
import { history } from 'routes/App';
import { formatDateString } from 'utils/date-utils';
import { IKeyValue } from 'models';
import { IDashboardComponentProps } from 'models/dashboard/IDashboardComponentProps';
import { IDashboardActionComponent } from 'models/dashboard/IDashboardActionComponent';
import { Table, TableNoItem } from 'components/v2/components';
import { Loader } from 'components/loader';
import DashboardComponent, { loadComponentEffect } from '../DashboardComponent';
import selectors from './selectors';
import actions from './actions';
import { NavLink } from 'react-router-dom';
import useTranslate from 'translations/translation-utils';

const DashboardActionComponent: React.FC<IDashboardComponentProps<IDashboardActionComponent>> = ({
    siteId,
    isLoading,
    component,
    hasPermission,
    loadComponent,
    className,
}) => {

    const translate = useTranslate();
    const currentSiteId = React.useRef<string>(null);
    const legends: Array<IKeyValue<string>> = [
        { key: translate('Dashboard.Action.Overdue'), value: 'high' },
        { key: translate('Dashboard.Action.ComingUp'), value: 'medium' },
    ];

    loadComponentEffect(hasPermission, currentSiteId, siteId, loadComponent);

    const navigateToPage = (id: string) => {
        history.push(`Action/${id}/Details`);
    };

    const getClassNameByDueDateStatus = (dueDateStatus: string) => {
        switch (dueDateStatus) {
            case 'Overdue':
                return 'high';
            case 'ComingUp':
                return 'medium';
            default:
                return '';
        }
    };

    return (
        <DashboardComponent
            title={translate('Dashboard.Action.Title')}
            intro={translate('Dashboard.Action.Intro')}
            className={`column-action ${className}`}
            legends={legends}
            component={component}
            hasPermission={hasPermission}
            headerButton={
                <div className="has-text-centered">
                    <NavLink to="/Actions" className="button is-primary is-fullwidth is-medium">
                        {translate('Dashboard.Action.ViewActions')}
                    </NavLink>
                </div>
            }
        >
            <Loader loading={isLoading}>
                <Table>
                    <thead>
                        <tr>
                            <th className="narrow">{translate('RiskActionTab.Label.Priority')}</th>
                            <th className="narrow">{translate('RiskActionTab.TableHeader.Duedate')}</th>
                            <th>{translate('RiskRegisterPage.RiskRegisterTable.Title.Title')}</th>
                            <th>{translate('RiskRegisterPage.RiskFilter.Title.Category')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!component || !component.items || component.items.length < 1 ? (
                            <TableNoItem colSpan={4} />
                        ) : (
                            component.items.map((item, index) => {
                                return (
                                    <tr key={index} onClick={() => navigateToPage(item.id)}>
                                        <td>
                                            <mark className={item.priority.toLowerCase()}>
                                                {
                                                    item.priority === 'High'
                                                        ? translate('RiskRegisterPage.Overview.Status.High')
                                                        : item.priority === 'Medium'
                                                            ? translate('RiskRegisterPage.Overview.Status.Medium')
                                                            : translate('RiskRegisterPage.Overview.Status.Low')
                                                }
                                            </mark>
                                        </td>
                                        <td>
                                            <mark
                                                className={getClassNameByDueDateStatus(
                                                    item.dueDateStatus
                                                )}
                                            >
                                                {formatDateString(item.dueDate)}
                                            </mark>
                                        </td>
                                        <td>{item.title}</td>
                                        <td>{item.category}</td>
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                </Table>
            </Loader>
        </DashboardComponent>
    );
};

export default connect(selectors, actions)(DashboardActionComponent);
