
import classnames from 'classnames';
import { padStart } from 'lodash';
import React from 'react';

export const processNumber = (value: number) => (
    <span>{padStart(`${value}`, 3, '0')}</span>
);
export const getColumnCss = (name: Extract<keyof any, string>, ...others: string[]) => classnames(
    'column',
    `column--${name}`,
    ...others
);

export const getCss = (name: Extract<keyof any, string>) =>
    getColumnCss(name, 'grid__header');

export const getUniqueItems = (array: any[]) => {
    const uniqueTags = [];
    array.map(x => {
        if (uniqueTags.findIndex(f => f.value === x.value) === -1) {
            uniqueTags.push({ value: x.value, label: x.value })
        }
    });
    return uniqueTags;
};