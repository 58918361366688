import { IRiskMitigationPlan } from 'models/risk';
import React from 'react';
import useTranslate from 'translations/translation-utils';
import { UiPreferences, usePageUIPreferences } from 'utils/page-ui-preferences-utils';
import { Panel } from 'components/v2/components';
import { formatDate } from 'utils/date-utils';
import { ReadOnlyField } from 'components/form/fields/read-only-field';
import ClientRiskMitigationDocumentsTab from './client-risk-mitigation-documents-tab';
import ClientRiskActionsTab from './client-risk-actions-tab';
import { IKeyValue } from 'models';

interface IProps {
    mitigationPlan: IRiskMitigationPlan;
    riskId: string;
    likelihoods: IKeyValue<string>[];
    impacts: IKeyValue<string>[];
}

export const ClientMitigationPlan: React.FC<IProps> = ({
    mitigationPlan,
    riskId,
    likelihoods,
    impacts,
}) => {
    const [expandedItems, setExpandedItems] = usePageUIPreferences<string[]>(
        UiPreferences.CermAssessmentPageCollapsedPillars,
        []
    );

    const translate = useTranslate()

    const onPanelCollapsed = (item: string, collapsed: boolean) => {
        const items = !collapsed ? [...expandedItems, item] : expandedItems.filter(p => p !== item);

        setExpandedItems(items);
    };
    const getTitle = (): string => {
        let result = 'Mitigation Plan';
        const createdDate = mitigationPlan.createdDate;
        if (createdDate) {
            result = result + ' - ' + createdDate?.toDateString();
        }

        return result;
    };

    const getMitigationPlanRiskLikelihood = () => {
        const planLevel = likelihoods.find(
            option => option.key === mitigationPlan.riskLikelihoodId
        );

        return planLevel
            ? translate('RiskDetailsPage.Likelihood.dropdown.', planLevel.value)
            : translate('RiskDetailsPage.Likelihood.dropdown.', mitigationPlan.riskLikelihoodId);
    };

    const getMitigationPlanRiskImpact = () => {
        const planLevel = impacts.find(option => option.key === mitigationPlan.riskImpactId);

        return planLevel
            ? translate('RiskDetailsPage.Impact.dropdown.', planLevel.value)
            : translate('RiskDetailsPage.Impact.dropdown.', mitigationPlan.riskImpactId);
    };

    return (
        <>
            <Panel
                title={getTitle()}
                collapsed={false}
                onCollapsed={collapsed => onPanelCollapsed(mitigationPlan.id, collapsed)}
                className="component-pillar"
            >
                <div className="section">
                    <div className="columns">
                        <div className="column is-2">
                            <ReadOnlyField
                                label={translate('IAuditorComplianceChecklistPage.table.CreatedDate')}
                                value={formatDate(mitigationPlan.createdDate)}
                            />
                        </div>
                        <div className="column is-2">
                            <ReadOnlyField
                                label={translate('RiskActionTab.Label.DueDate')}
                                value={
                                    !!mitigationPlan.dueDate
                                        ? formatDate(mitigationPlan.dueDate)
                                        : ''
                                }
                            />
                        </div>
                        <div className="column is-2">
                            <ReadOnlyField
                                label={translate('RiskDetailsPage.MitigationPlan.Label.Likelihood')}
                                value={getMitigationPlanRiskLikelihood()}
                            />
                        </div>

                        <div className="column is-2">
                            <ReadOnlyField
                                label={translate('RiskDetailsPage.MitigationPlan.Label.Impact')}
                                value={getMitigationPlanRiskImpact()}
                            />
                        </div>
                        <div className="column is-2">
                            <ReadOnlyField
                                label={translate('RiskDetailsPage.label.Estimatedcosttoremedy')}
                                value={mitigationPlan.estimatedCost}
                            />
                        </div>
                    </div>
                    <div className="subtitle">
                        {translate('RiskRegisterUpsertPage.Tabs.Actions')}
                    </div>
                    <ClientRiskActionsTab
                        riskId={riskId}
                        riskMitigationPlanId={mitigationPlan.id}
                    />
                    <div className="subtitle">
                        {translate('RiskRegisterUpsertPage.Tabs.Documents')}
                    </div>
                    <ClientRiskMitigationDocumentsTab id={mitigationPlan.id} />
                </div>
            </Panel>
        </>
    );
};
