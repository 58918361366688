import { IEmeraldDisabledInput } from '../shared/disabled.input';
import { IEmeraldIdInput } from '../shared/id.input';
import { IEmeraldLabelInput } from '../shared/label.input';
import { IEmeraldTitleInput } from '../shared/title.input';
import { StepVariantType, EmeraldStepperVariantType } from './stepper.variant';

export class EmeraldStepperCoreInputs implements IEmeraldStepperCoreInputs {
  type?: EmeraldStepperVariantType;
  data?: IStepData[];
  stacked?: boolean;
  activeStepperId?: string;
}

export interface IEmeraldStepperCoreInputs {
  type?: EmeraldStepperVariantType;
  data?: IStepData[];
  stacked?: boolean;
  activeStepperId?: string;
}

export interface IStepData
  extends IEmeraldDisabledInput,
    IEmeraldLabelInput,
    IEmeraldIdInput,
    IEmeraldTitleInput {
  icon?: string;
  stepNumber?: number;
  stepVariant?: StepVariantType;
  subLabel?: string;
}
