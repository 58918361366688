import * as React from 'react';
import { IAction } from 'models/action';
import { FormikProps } from 'formik';
import { ActionDetailsFormContainer } from './actions-details-form-container';

interface IProps {
    formikBag: FormikProps<IAction>;
    isReadOnly?: boolean;
    commentSave?: boolean;
}

export const ActionDetailsTab: React.FC<IProps> = ({ formikBag, isReadOnly, commentSave }) => {
    return (
        <div className="tabs-container tab-content">
            <ActionDetailsFormContainer formikBag={formikBag} isReadOnly={isReadOnly} commentSave={commentSave} />
        </div>
    );
};
