export type EmeraldAvatarSizeType =
  | 'extra_large'
  | 'large'
  | 'medium'
  | 'small';

export enum EmeraldAvatarSize {
  extra_large = 'extra_large',
  large = 'large',
  medium = 'medium',
  small = 'small',
}
