import React from 'react';
import { IToolbarIconItem, ToolbarIconType, IconToolbar } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';

interface IProps {
    hasPermissionToDownload: boolean;
    disableDownload: boolean;
    onShowFilters: () => void;
    onClearFilters: () => void;
    onDownload: () => void;
}

export const CermSiteOverviewToolbar: React.FC<IProps> = ({
    hasPermissionToDownload,
    disableDownload,
    onShowFilters,
    onClearFilters,
    onDownload
}) => {
    const translate = useTranslate();
    const getToolbarItems = (): IToolbarIconItem[] => {
        return [
            hasPermissionToDownload && {
                id: 'export-button',
                title: translate('CermSiteOverview.Lable.1'),
                type: ToolbarIconType.download,
                isDisabled: disableDownload,
                isConfirmRequired: true,
                confirmationMessage:
                    translate('CermSiteOverview.Lable.2'),
                onClick: onDownload
            },
            {
                id: 'clear-all-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.ClearFilters'),
                type: ToolbarIconType.clearFilter,
                onClick: onClearFilters
            },
            {
                id: 'show-filters-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.ShowFilters'),
                type: ToolbarIconType.filter,
                onClick: onShowFilters
            }
        ];
    };

    return <IconToolbar items={getToolbarItems()} />;
};
