import React from 'react';
import { Formik, FormikProps } from 'formik';
import { RouteComponentProps } from 'react-router';
import Select from 'react-select';
import { array, object, string } from 'yup';
import { Control, Field, Help, Input } from 'components/form';
import { ArticlePanel } from 'components/panel';
import { BulmaColor } from 'enums/BulmaColor';
import { BulmaSize } from 'enums/BulmaSize';
import { IKeyValue } from 'models/key-value';
import { Checkbox } from 'components/form/Checkbox';
import { Loader } from 'components/loader';
import { IPerson, IPersonToSite, IAccessRights, IPersonActivate } from 'models/person';
import { RouteUrl } from 'routes/manageRoles/routes';
import { formikFieldCss, scrollToFirstValidationError } from 'utils/form-utils';
import { IRouterPersonId } from '../../manage-roles-page';
import { IPersonAssignedSite, ISiteProfile } from 'models/site-profile';
import { AssignedSites } from 'components/siteSelector/selected-sites';
import Page from 'components/v2/page/Page';
import { Alert, Button, ButtonWithConfirmDialog } from 'components/v2/components';
import './index.scss';
import { checkPermissions } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import { IPrivilege } from 'reducers/manageRoles/manageRoles-grid';
import { ICommonLookupModel } from 'models/common-lookup-model';
import { IBusinessUnitLookupModel } from 'models/businessUnit-lookup-model';
import { IOperatingPlatform } from 'models/operating-platform-model';
import { IDivisions } from 'models/divisions-model';

export interface IProps extends RouteComponentProps<IRouterPersonId> {
    isLoading: boolean;
    lookupAssignableRoles: Array<IKeyValue<string>>;
    upsertPersonRequest?: Partial<IPerson>;
    sites: ISiteProfile[];
    personAssignedSites: IPersonAssignedSite[];
    clients: string[];
    lineOfBusinesses: ICommonLookupModel[];
    divisions: IDivisions[];
    businessUnits: IBusinessUnitLookupModel[];
    permissions?: Array<IKeyValue<string>>;
    privileges: IPrivilege[];
    relationships: IPersonToSite[];
    onRouteChange: (url: string) => void;
    onSubmit: (request: IPerson) => void;
    onLoadPerson?: (personId: string) => void;
    resetPerson: () => void;
    onLoadSitesFeatures(): void;
    loadPersonAssignedSites(personId: string): void;
    loadPrivileges(privilegeNames: string[]): void;
    loadRelationships: (personId: string) => void;
    deleteUser(personId: string): void;
    activateUser(activatemodel: IPersonActivate): void;
    allOperatingPlatforms: IOperatingPlatform[];
}

const personValidationSchema = object<Partial<IPerson>>().shape({
    firstName: string().trim().required('Please enter a a first name'),
    lastName: string().trim().required('Please enter a a last name'),
    emailAddress1: string()
        .trim()
        .required('Please enter a email address')
        .email('Please enter a valid email'),
    personReference: string()
        .nullable(true)
        .matches(/^[Cc]?[0-9]+$/, "Employee Id must be numeric and can start with a 'C'"),
    notifyEmail: string().nullable(true),
    accessLevel: string().nullable().notRequired(),
    accessLevelValues: array()
        .nullable()
        .when('accessLevel', {
            is: (accessLevel: string) => !!accessLevel,
            then: array()
                .nullable()
                .required('Please select at least one access level')
                .min(1, 'Please select at least one access level'),
            otherwise: array().notRequired().nullable(),
        }),
    roles: array()
        .nullable()
        .required('Please select at least one role')
        .min(1, 'Please select at least one role'),
    accessRights: object().shape({
        operatingPlatforms: array()
            .required('Please select at least one operating platform')
            .min(1, 'Please select at least one operating platform')
            .nullable(),
    }),
});

const personValidationNotifySchema = object<Partial<IPerson>>().shape({
    notifyEmail: string()
        .required('Please select a email address')
        .email('Please enter a valid email')
        .nullable(true),
});

const personValidationEmployeeIDSchema = object<Partial<IPerson>>().shape({
    personReference: string()
        .trim()
        .matches(/^[Cc]?[0-9]+$/, "Employee Id must be numeric and can start with a 'C'")
        .required('Please enter Employee Id.')
        .nullable(true),
});

export const PersonUpsertForm: React.FC<IProps> = props => {
    let formikBag: FormikProps<IPerson>;

    const usersManagementPageUrl = '/ManageRole';
    const customLinks: Array<IKeyValue<string>> = [
        { key: usersManagementPageUrl, value: 'Users Management' },
    ];
    const [chosenValidationSchema, setChosenValidationSchema] = React.useState(
        personValidationSchema
    );
    const [isLinesOfBusinessDisabled, setIsLinesOfBusinessDisabled] = React.useState(true);
    const [isDivisionDisabled, setIsDivisionDisabled] = React.useState(true);
    const [isBusinessUnitDisabled, setIsBusinessUnitDisabled] = React.useState(true);
    const [isClientsDisabled, setIsClientsDisabled] = React.useState(true);
    const [isSitesDisabled, setIsSitesDisabled] = React.useState(true);

    React.useEffect(() => {
        props.onLoadSitesFeatures();
        props.resetPerson();
    }, []);

    React.useEffect(() => {
        if (props.match.params.personId) {
            props.onLoadPerson(props.match.params.personId);
            props.loadRelationships(props.match.params.personId);
            props.loadPersonAssignedSites(props.match.params.personId);
        }
    }, [props.match.params.personId]);

    React.useEffect(() => {
        setChosenValidationSchema(validValidationSchema(true, props.upsertPersonRequest));
        disableAccessRights(props.upsertPersonRequest.accessRights);
        if (formikBag) {
            formikBag.setFieldValue('notify', true);
            formikBag.setFieldValue('notifyEmail', props.upsertPersonRequest.emailAddress1);
        }
    }, [props.upsertPersonRequest]);

    const validValidationSchema = (notify: boolean, personRequest: Partial<IPerson>) => {
        let updatedValidationSchema = personValidationSchema;

        if (canViewEmployeeId() && personRequest.roles && personRequest.roles.length > 0) {
            if (
                (personRequest.roles && personRequest.roles.length > 1) ||
                (personRequest.roles.length === 1 &&
                    personRequest.roles.some(role => role.value !== 'Client'))
            ) {
                updatedValidationSchema = personValidationSchema.concat(
                    personValidationEmployeeIDSchema
                );
            }
        }

        if (notify) {
            updatedValidationSchema = updatedValidationSchema.concat(personValidationNotifySchema);
        }

        return updatedValidationSchema;
    };

    const canViewEmployeeId = () => {
        if (checkPermissions([Privilege.PeopleCBRECreate], props.permissions)) {
            return true;
        }

        return false;
    };

    const onRoleChange = (e: Array<any>) => {
        formikBag.setFieldValue('roles', e);
        AssignCorrectValidationSchemaBasedOnRole(e);
    };

    const AssignCorrectValidationSchemaBasedOnRole = (e: Array<any>) => {
        if (e && (e.length > 1 || (e.length === 1 && e.some(role => role.value !== 'Client')))) {
            setChosenValidationSchema(
                chosenValidationSchema.concat(personValidationEmployeeIDSchema)
            );
        } else {
            if (formikBag.values.notify) {
                setChosenValidationSchema(
                    personValidationSchema.concat(personValidationNotifySchema)
                );
            }
        }
    };

    const clearOutInvalidAccessRights = (accessRights: IAccessRights): void => {
        formikBag.setFieldValue('accessRights.sites', accessRights.sites
            .filter(s => props.sites
                .filter(c => formikBag.values.accessRights.operatingPlatforms.includes(c.operatingPlatform))
                .filter(x => {
                    if (accessRights.clients.length > 0) {
                        return accessRights.clients.includes(x.clientId);
                    } else {
                        return true;
                    }
                })
                .filter(x => {
                    if (accessRights.businessUnits.length > 0) {
                        return (
                            props.businessUnits
                                .filter(y => accessRights.businessUnits.includes(y.key))
                                .map(y => y.value)
                                .includes(x.businessUnit) &&
                            props.divisions
                                .filter(y => accessRights.divisions.includes(y.key))
                                .map(y => y.value)
                                .includes(x.division) &&
                            props.lineOfBusinesses
                                .filter(y => accessRights.linesOfBusiness.includes(y.key))
                                .map(y => y.value)
                                .includes(x.lineOfBusiness)
                        );
                    } else {
                        return true;
                    }
                })
                .map(x => x.id)
                .includes(s)
            )
        );

        formikBag.setFieldValue('accessRights.clients', accessRights.clients
            .filter(c => props.sites
                .filter(x => formikBag.values.accessRights.operatingPlatforms.includes(x.operatingPlatform))
                .filter(x => {
                    if (accessRights.businessUnits.length > 0) {
                        return (
                            props.businessUnits
                                .filter(y => accessRights.businessUnits.includes(y.key))
                                .map(y => y.value)
                                .includes(x.businessUnit) &&
                            props.divisions
                                .filter(y => accessRights.divisions.includes(y.key))
                                .map(y => y.value)
                                .includes(x.division) &&
                            props.lineOfBusinesses
                                .filter(y => accessRights.linesOfBusiness.includes(y.key))
                                .map(y => y.value)
                                .includes(x.lineOfBusiness)
                        );
                    } else {
                        return true;
                    }
                })
                .map(x => x.clientId)
                .includes(c)
            )
        );

        formikBag.setFieldValue('accessRights.businessUnits', accessRights.businessUnits
            .filter(x => props.businessUnits
                .filter(c => formikBag.values.accessRights.operatingPlatforms.includes(c.operatingPlatform) &&
                    formikBag.values.accessRights.linesOfBusiness.includes(c.lineOfBusinessId) &&
                    formikBag.values.accessRights.divisions.includes(c.divisionId))
                .map(x => x.key)
                .includes(x)
            )
        );

        formikBag.setFieldValue('accessRights.divisions', accessRights.divisions
            .filter(x => props.divisions
                .filter(c => formikBag.values.accessRights.operatingPlatforms.includes(c.operatingPlatform) &&
                    formikBag.values.accessRights.linesOfBusiness.includes(c.lineOfBusinessId))
                .map(x => x.key)
                .includes(x)
            )
        );

        formikBag.setFieldValue('accessRights.linesOfBusiness', accessRights.linesOfBusiness
            .filter(x => props.lineOfBusinesses
                .filter(c => accessRights.operatingPlatforms.includes(c.operatingPlatform))
                .map(c => c.key)
                .includes(x)
            )
        );

        setTimeout(() => resetAccessRights(formikBag.values.accessRights), 10);
    };
    const resetAccessRights = (accessRights: IAccessRights): void => {
        if (accessRights.operatingPlatforms.length === 0) {
            formikBag.setFieldValue('accessRights.linesOfBusiness', []);
            formikBag.setFieldValue('accessRights.divisions', []);
            formikBag.setFieldValue('accessRights.businessUnits', []);
            formikBag.setFieldValue('accessRights.clients', []);
            formikBag.setFieldValue('accessRights.sites', []);
        }

        if (accessRights.linesOfBusiness.length === 0 && accessRights.divisions.length > 0) {
            formikBag.setFieldValue('accessRights.divisions', []);
            formikBag.setFieldValue('accessRights.businessUnits', []);
            formikBag.setFieldValue('accessRights.clients', []);
            formikBag.setFieldValue('accessRights.sites', []);
        }

        if (accessRights.divisions.length === 0 && accessRights.businessUnits.length > 0) {
            formikBag.setFieldValue('accessRights.businessUnits', []);
            formikBag.setFieldValue('accessRights.clients', []);
            formikBag.setFieldValue('accessRights.sites', []);
        }

        if (accessRights.linesOfBusiness.length > 0 && accessRights.businessUnits.length === 0) {
            formikBag.setFieldValue('accessRights.clients', []);
            formikBag.setFieldValue('accessRights.sites', []);
        }

        if (accessRights.linesOfBusiness.length > 0 && accessRights.clients.length === 0) {
            formikBag.setFieldValue('accessRights.sites', []);
        }

        setTimeout(() => disableAccessRights(formikBag.values.accessRights), 10);
    };

    const disableAccessRights = (accessRights: IAccessRights) => {
        if (accessRights.operatingPlatforms.length > 0 &&
            accessRights.linesOfBusiness.length === 0 &&
            accessRights.divisions.length === 0 &&
            accessRights.businessUnits.length === 0 &&
            accessRights.clients.length === 0 &&
            accessRights.sites.length > 0
        ) {
            setIsLinesOfBusinessDisabled(true);
            setIsDivisionDisabled(true);
            setIsBusinessUnitDisabled(true);
            setIsClientsDisabled(true);
            setIsSitesDisabled(false);
        } else if (accessRights.operatingPlatforms.length > 0 &&
            accessRights.linesOfBusiness.length === 0 &&
            accessRights.divisions.length === 0 &&
            accessRights.businessUnits.length === 0 &&
            accessRights.sites.length === 0 &&
            accessRights.clients.length > 0
        ) {
            setIsLinesOfBusinessDisabled(true);
            setIsDivisionDisabled(true);
            setIsBusinessUnitDisabled(true);
            setIsSitesDisabled(true);
            setIsClientsDisabled(false);
        } else {
            setIsLinesOfBusinessDisabled(accessRights.operatingPlatforms.length === 0);
            setIsDivisionDisabled(accessRights.linesOfBusiness.length === 0);
            setIsBusinessUnitDisabled(accessRights.divisions.length === 0);
            setIsClientsDisabled(
                accessRights.operatingPlatforms.length === 0 ||
                accessRights.linesOfBusiness.length > 0
            );
            setIsSitesDisabled(
                accessRights.operatingPlatforms.length === 0 ||
                accessRights.linesOfBusiness.length > 0
            );
        }
    };

    const onOperatingPlatformChange = (e: Array<any>) => {
        formikBag.setFieldValue('accessRights.operatingPlatforms', e?.map(c => c.key) ?? []);
        setTimeout(() => clearOutInvalidAccessRights(formikBag.values.accessRights), 10);
    };

    const onLinesOfBusinessChange = (e: Array<any>) => {
        formikBag.setFieldValue('accessRights.linesOfBusiness', e?.map(c => c.key) ?? []);
        setTimeout(() => clearOutInvalidAccessRights(formikBag.values.accessRights), 10);
    };

    const onDivisionChange = (e: Array<any>) => {
        formikBag.setFieldValue('accessRights.divisions', e?.map(c => c.key) ?? []);
        setTimeout(() => clearOutInvalidAccessRights(formikBag.values.accessRights), 10);
    };

    const onBusinessUnitChange = (e: Array<any>) => {
        formikBag.setFieldValue('accessRights.businessUnits', e?.map(c => c.key) ?? []);
        setTimeout(() => clearOutInvalidAccessRights(formikBag.values.accessRights), 10);
    };

    const onClientsChange = (e: Array<any>) => {
        formikBag.setFieldValue('accessRights.clients', e?.map(c => c.key) ?? []);
        setTimeout(() => clearOutInvalidAccessRights(formikBag.values.accessRights), 10);
    };

    const onSitesChange = (e: Array<any>) => {
        formikBag.setFieldValue('accessRights.sites', e?.map(c => c.key) ?? []);
        setTimeout(() => clearOutInvalidAccessRights(formikBag.values.accessRights), 10);
    };

    const onNotifyEmailChange = (email: string) => {
        formikBag.setFieldValue('notifyEmail', email);
    };

    const onEmailChange = e => {
        formikBag.setFieldValue('notifyEmail', e.target.value);
        formikBag.setFieldValue('emailAddress1', e.target.value);
    };

    const onNotifyChange = () => {
        formikBag.setFieldValue('notify', !formikBag.values.notify);
    };

    const onDeleteUser = () => {
        props.deleteUser(props.match.params.personId);
    };

    const onActivateUser = () => {
        props.activateUser({ id: props.match.params.personId });
    };

    const onSave = () => {
        formikBag.validateForm().then(errors => {
            if (!Object.keys(errors).some(field => field.length > 0)) {
                formikBag.submitForm();
            } else {
                scrollToFirstValidationError();
            }
        });
    };

    const renderForm = (formik: FormikProps<IPerson>) => {
        formikBag = formik;

        return (
            <form id="personUpsertForm" onSubmit={formikBag.handleSubmit}>
                <div className="columns">
                    <div className="column is-12">
                        <div className="title is-4 section-title">Person Details</div>
                        <Field
                            isHorizontal={true}
                            htmlFor="title"
                            label="Name"
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Input
                                        id="firstName"
                                        name="firstName"
                                        aria-required="true"
                                        placeholder="First Name"
                                        type="text"
                                        className={formikFieldCss(formikBag, 'firstName')}
                                        value={formikBag.values.firstName}
                                        onChange={formikBag.handleChange}
                                        onBlur={event =>
                                            formikBag.setFieldValue(
                                                event.target.name,
                                                event.target.value.trim()
                                            )
                                        }
                                    />
                                </Control>
                                <Help
                                    bulmaColor={BulmaColor.Danger}
                                    isShown={!!formikBag.errors.firstName}
                                >
                                    {formikBag.errors.firstName}
                                </Help>
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor="lastName"
                            label="Last Name"
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Input
                                        id="lastName"
                                        name="lastName"
                                        aria-required="true"
                                        className={formikFieldCss(formikBag, 'lastName')}
                                        placeholder="Last Name"
                                        value={formikBag.values.lastName}
                                        onChange={formikBag.handleChange}
                                        onBlur={event =>
                                            formikBag.setFieldValue(
                                                event.target.name,
                                                event.target.value.trim()
                                            )
                                        }
                                    />
                                </Control>
                                <Help bulmaColor={BulmaColor.Danger} isShown={true}>
                                    {formikBag.errors.lastName}
                                </Help>
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor="title"
                            label="Preferred name"
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Input
                                        id="preferredName"
                                        name="preferredName"
                                        aria-required="true"
                                        placeholder="Enter Preferred Name"
                                        type="text"
                                        className={formikFieldCss(formikBag, 'preferredName')}
                                        value={formikBag.values.preferredName}
                                        onChange={formikBag.handleChange}
                                        onBlur={event =>
                                            formikBag.setFieldValue(
                                                event.target.name,
                                                event.target.value.trim()
                                            )
                                        }
                                    />
                                </Control>
                            </Field>
                        </Field>

                        {canViewEmployeeId() && (
                            <Field
                                isHorizontal={true}
                                htmlFor="personReference"
                                label="CBRE Employee ID"
                                labelSize={BulmaSize.Medium}
                            >
                                <Field>
                                    <Control>
                                        <Input
                                            id="personReference"
                                            name="personReference"
                                            aria-required="true"
                                            placeholder="CBRE Employee ID"
                                            type="text"
                                            className={formikFieldCss(formikBag, 'personReference')}
                                            value={formikBag.values.personReference}
                                            onChange={formikBag.handleChange}
                                            onBlur={event =>
                                                formikBag.setFieldValue(
                                                    event.target.name,
                                                    event.target.value.trim()
                                                )
                                            }
                                        />
                                    </Control>
                                    <Help bulmaColor={BulmaColor.Danger} isShown={true}>
                                        {formikBag.errors.personReference}
                                    </Help>
                                </Field>
                            </Field>
                        )}

                        <Field
                            isHorizontal={true}
                            htmlFor="businessTitle"
                            label="Business Title"
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Input
                                        id="businessTitle"
                                        name="businessTitle"
                                        aria-businessTitle="true"
                                        placeholder="Enter Business Title"
                                        type="text"
                                        className={formikFieldCss(formikBag, 'businessTitle')}
                                        value={formikBag.values.businessTitle}
                                        onChange={formikBag.handleChange}
                                        onBlur={event =>
                                            formikBag.setFieldValue(
                                                event.target.name,
                                                event.target.value.trim()
                                            )
                                        }
                                    />
                                </Control>
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor="title"
                            label="Email Address"
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Input
                                        id="emailAddress1"
                                        name="emailAddress1"
                                        aria-required="true"
                                        placeholder="work-email@email.com"
                                        type="text"
                                        className={formikFieldCss(formikBag, 'emailAddress1')}
                                        value={formikBag.values.emailAddress1}
                                        onChange={e => onEmailChange(e)}
                                        onBlur={event =>
                                            formikBag.setFieldValue(
                                                event.target.name,
                                                event.target.value.trim()
                                            )
                                        }
                                    />
                                </Control>
                                <Help bulmaColor={BulmaColor.Danger} isShown={true}>
                                    {formikBag.errors.emailAddress1}
                                </Help>
                            </Field>
                        </Field>
                        <Field
                            isHorizontal={true}
                            htmlFor="telephone1"
                            label="Main Number"
                            labelSize={BulmaSize.Medium}
                            className="is-"
                        >
                            <Field>
                                <Control>
                                    <Input
                                        id="telephone1"
                                        name="telephone1"
                                        aria-required="true"
                                        placeholder="02074033582"
                                        type="text"
                                        className={formikFieldCss(formikBag, 'telephone1')}
                                        value={formikBag.values.telephone1}
                                        onChange={formikBag.handleChange}
                                        onBlur={event =>
                                            formikBag.setFieldValue(
                                                event.target.name,
                                                event.target.value.trim()
                                            )
                                        }
                                    />
                                </Control>
                                <Help bulmaColor={BulmaColor.Danger} isShown={true}>
                                    {formikBag.errors.telephone1}
                                </Help>
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor="telephone2"
                            label="Alternative Number"
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Input
                                        id="telephone2"
                                        name="telephone2"
                                        aria-required="true"
                                        placeholder="02074033581"
                                        type="text"
                                        className={formikFieldCss(formikBag, 'telephone2')}
                                        value={formikBag.values.telephone2}
                                        onChange={formikBag.handleChange}
                                        onBlur={event =>
                                            formikBag.setFieldValue(
                                                event.target.name,
                                                event.target.value.trim()
                                            )
                                        }
                                    />
                                </Control>
                                <Help bulmaColor={BulmaColor.Danger} isShown={true}>
                                    {formikBag.errors.telephone2}
                                </Help>
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor="snowTicket"
                            label="SNOW Ticket"
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Input
                                        id="snowTicket"
                                        name="snowTicket"
                                        aria-businessTitle="true"
                                        placeholder="Enter SNOW Ticket"
                                        type="text"
                                        className={formikFieldCss(formikBag, 'snowTicket')}
                                        value={formikBag.values.snowTicket}
                                        onChange={formikBag.handleChange}
                                        onBlur={event =>
                                            formikBag.setFieldValue(
                                                event.target.name,
                                                event.target.value.trim()
                                            )
                                        }
                                    />
                                </Control>
                            </Field>
                        </Field>

                        <div className="title is-4 section-title">Roles</div>

                        <Field
                            isHorizontal={true}
                            className="roles-dropdown"
                            htmlFor="roles"
                            label="Roles"
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Select
                                        id="roles-selector"
                                        options={props.lookupAssignableRoles.map(x => ({
                                            key: x.key,
                                            value: x.value,
                                            label: x.value,
                                        }))}
                                        isMulti={true}
                                        onChange={onRoleChange}
                                        onBlur={formikBag.handleBlur}
                                        value={formikBag.values.roles}
                                    />
                                </Control>
                                <Help bulmaColor={BulmaColor.Danger} isShown={true}>
                                    <>{formikBag.errors.roles}</>
                                </Help>
                            </Field>
                        </Field>

                        <div className="title is-4 section-title">Access Rights</div>

                        <Field
                            isHorizontal={true}
                            className="operatingPlatforms-dropdown"
                            htmlFor="operatingPlatforms"
                            label="Operating Platforms"
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Select
                                        id="operatingPlatforms"
                                        value={formikBag.values.accessRights.operatingPlatforms.map(x => ({
                                            key: x,
                                            value: x,
                                            label: x,
                                        }))
                                            .sort((a, b) => a.label.localeCompare(b.label))}
                                        options={props.allOperatingPlatforms.map(x => ({
                                            key: x.key,
                                            value: x.value,
                                            label: x.key,
                                        }))
                                            .sort((a, b) => a.label.localeCompare(b.label))}
                                        isMulti={true}
                                        onChange={onOperatingPlatformChange}
                                    />
                                </Control>
                                <Help bulmaColor={BulmaColor.Danger} isShown={true}>
                                    {formikBag.errors.accessRights?.operatingPlatforms}
                                </Help>
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            className="lineOfBusiness-dropdown"
                            htmlFor="lineOfBusiness"
                            label="Line Of Business"
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Select
                                        id="linesOfBusiness"
                                        value={props.lineOfBusinesses
                                            .filter(x => formik.values.accessRights.linesOfBusiness.includes(x.key))
                                            .map(x => {
                                                return {
                                                    key: x.key,
                                                    value: x.key,
                                                    label: `${x.operatingPlatform}: ${x.label}`,
                                                    operatingPlatform: x.operatingPlatform,
                                                } as ICommonLookupModel;
                                            })
                                            .sort((a, b) => a.label.localeCompare(b.label))}
                                        options={props.lineOfBusinesses
                                            .filter(c => formikBag.values.accessRights.operatingPlatforms.includes(c.operatingPlatform))
                                            .map(x => {
                                                return {
                                                    key: x.key,
                                                    value: x.key,
                                                    label: `${x.operatingPlatform}: ${x.label}`,
                                                    operatingPlatform: x.operatingPlatform,
                                                } as ICommonLookupModel;
                                            })
                                            .sort((a, b) => a.label.localeCompare(b.label))}
                                        isMulti={true}
                                        isDisabled={isLinesOfBusinessDisabled}
                                        onChange={onLinesOfBusinessChange}
                                        isClearable={true}
                                    />
                                </Control>
                                <Help bulmaColor={BulmaColor.Danger} isShown={true}></Help>
                            </Field>
                        </Field>
                        <Field
                            isHorizontal={true}
                            className="division-dropdown"
                            htmlFor="division"
                            label="Division"
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Select
                                        id="division"
                                        value={props.divisions
                                            .filter(x =>
                                                formik.values.accessRights.divisions.includes(x.key)
                                            )
                                            .map(x => {
                                                return {
                                                    key: x.key,
                                                    value: x.key,
                                                    label: `${x.operatingPlatform} - ${x.lineOfBusiness}: ${x.label}`,
                                                    operatingPlatform: x.operatingPlatform,
                                                } as ICommonLookupModel;
                                            })
                                            .sort((a, b) => a.label.localeCompare(b.label))}
                                        options={props.divisions
                                            .filter(c =>
                                                formikBag.values.accessRights.operatingPlatforms.includes(c.operatingPlatform) &&
                                                formik.values.accessRights.linesOfBusiness.includes(c.lineOfBusinessId)
                                            )
                                            .map(x => {
                                                return {
                                                    key: x.key,
                                                    value: x.key,
                                                    label: `${x.operatingPlatform} - ${x.lineOfBusiness}: ${x.label}`,
                                                    operatingPlatform: x.operatingPlatform,
                                                } as ICommonLookupModel;
                                            })
                                            .sort((a, b) => a.label.localeCompare(b.label))}
                                        isMulti={true}
                                        isDisabled={isDivisionDisabled}
                                        onChange={onDivisionChange}
                                        isClearable={true}
                                    />
                                </Control>
                                <Help bulmaColor={BulmaColor.Danger} isShown={true}></Help>
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            className="businessUnit-dropdown"
                            htmlFor="businessUnit"
                            label="Business Unit"
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Select
                                        id="businessUnit"
                                        value={props.businessUnits
                                            .filter(x => formik.values.accessRights.businessUnits.includes(x.key))
                                            .map(x => {
                                                return {
                                                    key: x.key,
                                                    value: x.key,
                                                    label: `${x.operatingPlatform} - ${x.lineOfBusiness} - ${x.division}: ${x.label}`,
                                                    operatingPlatform: x.operatingPlatform,
                                                } as ICommonLookupModel;
                                            })
                                            .sort((a, b) => a.label.localeCompare(b.label))}
                                        options={props.businessUnits
                                            .filter(c =>
                                                formikBag.values.accessRights.operatingPlatforms.includes(c.operatingPlatform) &&
                                                formik.values.accessRights.linesOfBusiness.includes(c.lineOfBusinessId) &&
                                                formik.values.accessRights.divisions.includes(c.divisionId)
                                            )
                                            .map(c => ({
                                                key: c.key,
                                                value: c.key,
                                                label: `${c.operatingPlatform} - ${c.lineOfBusiness} - ${c.division}: ${c.label}`,
                                                operatingPlatform: c.operatingPlatform,
                                            } as ICommonLookupModel))
                                            .sort((a, b) => a.label.localeCompare(b.label))}
                                        isMulti={true}
                                        isDisabled={isBusinessUnitDisabled}
                                        onChange={onBusinessUnitChange}
                                        isClearable={true}
                                    />
                                </Control>
                                <Help bulmaColor={BulmaColor.Danger} isShown={true}></Help>
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            className="clients-dropdown"
                            htmlFor="clients"
                            label="Clients"
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Select
                                        id="clients"
                                        value={props.sites
                                            .filter(x => formik.values.accessRights.clients.includes(x.clientId))
                                            .map(c => ({
                                                key: c.clientId,
                                                value: c.clientId,
                                                label: c.clientName ?? '',
                                            }))
                                            .filter((c, index, self) => self.findIndex(t => t.key === c.key) === index)
                                            .sort((a, b) => a.label.localeCompare(b.label))}
                                        options={props.sites
                                            .filter(x => formik.values.accessRights.operatingPlatforms.includes(x.operatingPlatform))
                                            .filter(x => {
                                                if (
                                                    formik.values.accessRights.businessUnits.length > 0
                                                ) {
                                                    return props.businessUnits
                                                        .filter(y => formik.values.accessRights.businessUnits.includes(y.key))
                                                        .map(y => y.value)
                                                        .includes(x.businessUnit);
                                                } else {
                                                    return true;
                                                }
                                            })
                                            .map(c => ({
                                                key: c.clientId,
                                                value: c.clientId,
                                                label: c.clientName ?? '',
                                            }))
                                            .filter((c, index, self) => self.findIndex(t => t.key === c.key) === index)
                                            .sort((a, b) => a.label.localeCompare(b.label))}
                                        isMulti={true}
                                        isDisabled={isClientsDisabled}
                                        onChange={onClientsChange}
                                        isClearable={true}
                                    />
                                </Control>
                                <Help bulmaColor={BulmaColor.Danger} isShown={true}></Help>
                            </Field>
                        </Field>
                        <Field
                            isHorizontal={true}
                            className="sites-dropdown"
                            htmlFor="sites"
                            label="Sites"
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Select
                                        id="sites"
                                        value={props.sites
                                            .filter(x => formik.values.accessRights.sites.includes(x.id))
                                            .map(c => ({
                                                key: c.id,
                                                value: c.id,
                                                label: c.clientAndSiteName ?? '',
                                            }))
                                            .sort((a, b) => a.label.localeCompare(b.label))}
                                        options={props.sites
                                            .filter(c => formikBag.values.accessRights.operatingPlatforms.includes(c.operatingPlatform))
                                            .filter(x => {
                                                if (formik.values.accessRights.clients.length > 0) {
                                                    return formik.values.accessRights.clients.includes(
                                                        x.clientId
                                                    );
                                                } else {
                                                    return true;
                                                }
                                            })
                                            .filter(x => {
                                                if (
                                                    formik.values.accessRights.businessUnits
                                                        .length > 0
                                                ) {
                                                    return props.businessUnits
                                                        .filter(y => formik.values.accessRights.businessUnits.includes(y.key))
                                                        .map(y => y.value)
                                                        .includes(x.businessUnit);
                                                } else {
                                                    return true;
                                                }
                                            })
                                            .map(c => ({
                                                key: c.id,
                                                value: c.id,
                                                label: c.clientAndSiteName,
                                            }))
                                            .sort((a, b) => a.label.localeCompare(b.label))}
                                        isMulti={true}
                                        isDisabled={isSitesDisabled}
                                        onChange={onSitesChange}
                                        isClearable={true}
                                    />
                                </Control>
                                <Help bulmaColor={BulmaColor.Danger} isShown={true}></Help>
                            </Field>
                        </Field>

                        <div className="title is-4 section-title">Sites</div>

                        <div className="person-container">
                            {props.personAssignedSites && props.personAssignedSites.length > 0 && (
                                <AssignedSites selectedSites={props.personAssignedSites} />
                            )}
                        </div>

                        <Field
                            isHorizontal={true}
                            htmlFor="notify"
                            label="Notify by Email"
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Checkbox
                                        checked={formikBag.values.notify}
                                        id={'notify'}
                                        onChange={onNotifyChange}
                                    />
                                </Control>
                            </Field>
                        </Field>

                        {formikBag.values.notify && (
                            <Field
                                isHorizontal={true}
                                htmlFor="NotifyEmail"
                                label="Select Email"
                                labelSize={BulmaSize.Medium}
                            >
                                <Field>
                                    <Control>
                                        <Select
                                            id="NotifyEmail"
                                            // tslint:disable-next-line:max-line-length
                                            options={[
                                                {
                                                    key: formikBag.values.emailAddress1,
                                                    value: formikBag.values.emailAddress1,
                                                    label: formikBag.values.emailAddress1,
                                                },
                                            ]}
                                            isMulti={false}
                                            onChange={e => onNotifyEmailChange(e.key)}
                                            onBlur={formikBag.handleBlur}
                                            // tslint:disable-next-line:max-line-length
                                            value={{
                                                key: formikBag.values.notifyEmail,
                                                value: formikBag.values.notifyEmail,
                                                label: formikBag.values.notifyEmail,
                                            }}
                                        />
                                    </Control>
                                    <Help bulmaColor={BulmaColor.Danger} isShown={true}>
                                        {formikBag.errors.notifyEmail}
                                    </Help>
                                </Field>
                            </Field>
                        )}
                    </div>
                </div>
                <div className="action-groups">
                    <div className="action-group">
                        <Button id="person_upsert__cancel" buttonType="cancel" onClick={onCancel}>
                            Cancel
                        </Button>

                        <ButtonWithConfirmDialog
                            buttonId="archive"
                            buttonText="Delete User"
                            buttonType="archive"
                            title="Delete User"
                            message="Are you sure you want to delete this user?"
                            onConfirm={onDeleteUser}
                            isLoading={props.isLoading}
                            isButtonVisible={formikBag.values.isActive}
                        />

                        <ButtonWithConfirmDialog
                            buttonId="activate"
                            buttonText="Activate User"
                            buttonType="action"
                            title="Activate User"
                            message="Are you sure you want to activate this user?"
                            onConfirm={onActivateUser}
                            isLoading={props.isLoading}
                            isButtonVisible={!formikBag.values.isActive}
                        />
                    </div>
                    <div className="action-group">
                        <Button id="person_upsert_publish" onClick={onSave}>
                            Save
                        </Button>
                    </div>
                </div>
            </form>
        );
    };

    const onCancel = () => {
        props.onRouteChange(RouteUrl.People);
    };

    const onSubmit = (upsertRoleRequest: IPerson) => {
        props.onSubmit({
            ...upsertRoleRequest,
        });
    };

    const title = props.match.params.personId ? 'Edit Person' : 'Create new person';

    return (
        <Page title={title} breadcrumbCustomLinks={customLinks} isAdminPage={true}>
            <Loader loading={props.isLoading}>
                <ArticlePanel className="column">
                    <header>
                        <h2 className="title is-4">Details</h2>
                    </header>
                    {!canViewEmployeeId() && (
                        <Alert type="warning">
                            <p>You don't have Permission to view CBRE Employee Id</p>
                        </Alert>
                    )}
                    <Formik<Partial<IPerson>>
                        initialValues={props.upsertPersonRequest}
                        validationSchema={chosenValidationSchema}
                        onSubmit={onSubmit}
                        enableReinitialize={true}
                        isInitialValid={props.match.params.personId ? true : false}
                        render={renderForm}
                        validateOnBlur={true}
                        validateOnChange={true}
                    />
                </ArticlePanel>
            </Loader>
        </Page>
    );
};
