import { combineEpics, Epic, ofType } from 'redux-observable';
import { ajax, AjaxError } from 'rxjs/ajax';
import { catchError, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { onRouteChange } from 'actions/app-actions';
import { AppNotificationActions } from 'actions/app-notification-actions';
import {
    deleteSiteConfirmedFulfilled,
    IDeleteBuildingAction,
    IDeleteSiteConfirmedAction,
    UpsertSiteActions,
    UpsertSiteActionTypes,
} from 'actions/upsert-site-actions';
import { getErrorActions$ } from 'epics/epic-helpers';
import { IUpsertSiteRootState } from 'state/upsert-site-state';
import { SiteActions } from 'actions/actions-v2/site-actions-v2';
import appConfig from 'helpers/config-helper';

const config = appConfig();

const apiUrl = config.REACT_APP_BASE_API;

export const deleteSiteEpic: Epic<UpsertSiteActionTypes> = (action$) =>
    action$.pipe(
        ofType(UpsertSiteActions.DELETE_SITE),
        switchMap((action: IDeleteSiteConfirmedAction) =>
            ajax
                .delete(
                    `${apiUrl}/api/SiteApi?siteId=${action.payload.item.id}&clientId=${action.payload.item.clientId}`
                )
                .pipe(
                    mergeMap(() => [
                        deleteSiteConfirmedFulfilled(action.payload.item),
                        {
                            type: AppNotificationActions.SEND_SUCCESS_NOTIFICATION,
                            payload: {
                                title: 'Site Deleted',
                                message: `Site deleted.`,
                            },
                        },
                        onRouteChange('/Site'),
                    ]),
                    catchError<any, UpsertSiteActionTypes>((error: AjaxError) =>
                        getErrorActions$(' Site')(UpsertSiteActions.DELETE_SITE_REJECTED, error, {
                            errorMessage: 'Unable to delete the Site',
                        })
                    )
                )
        ),
        takeUntil(action$.pipe(ofType(UpsertSiteActions.DELETE_SITE_CANCELLED)))
    );

const deleteBuildingEpic: Epic<any, any, IUpsertSiteRootState> = (action$) =>
    action$.pipe(
        ofType(SiteActions.DELETE_BUILDING),
        mergeMap((action: IDeleteBuildingAction) => [
            onRouteChange(action.payload.backLinkUrl),
            {
                type: AppNotificationActions.SEND_SUCCESS_NOTIFICATION,
                payload: {
                    title: 'Success',
                    message: `Facility deleted`,
                },
            },
        ]),
        catchError<any, UpsertSiteActionTypes>((error: AjaxError) =>
            getErrorActions$('Facility')(
                UpsertSiteActions.DELETE_BUILDING_CONFIRMED_REJECTED,
                error,
                {
                    errorMessage: 'Unable to delete the facility',
                }
            )
        )
    );

export const upsertSiteEpics = combineEpics(deleteSiteEpic, deleteBuildingEpic);
