import {
    loadActions,
    clearForm,
    exportData,
    loadMacroActions,
} from 'actions/actions-v2/action-action-v2';

export default {
    loadAll: loadActions,
    clearForm,
    exportData,
    loadMacroActions,
};
