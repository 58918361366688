import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { CriticalSystemSpareActions } from 'actions/actions-v2/critical-system-spares-actions';
import { ICriticalSystemSpare } from 'models/critical-system-spares/critical-system-spare';
import { IEntitySite } from 'models/entity-site';

const controllerName = 'CriticalSystemSpare';
const apiPath = `/api/${controllerName}`;
const criticalSystemSparePageUrl = '/CriticalSystemSpares';

const getAllActions = {
    action: CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARES,
    actionFulfilled: CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARES_FULFILLED,
    actionRejected: CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARES_REJECTED
} as IActionType;

const getActions = {
    action: CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARE,
    actionFulfilled: CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARE_FULFILLED,
    actionRejected: CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARE_REJECTED
} as IActionType;

const saveActions = {
    action: CriticalSystemSpareActions.CREATE_CRITICAL_SYSTEM_SPARE,
    actionFulfilled: CriticalSystemSpareActions.CREATE_CRITICAL_SYSTEM_SPARE_FULFILLED,
    actionRejected: CriticalSystemSpareActions.CREATE_CRITICAL_SYSTEM_SPARE_REJECTED
} as IActionType;

const updateActions = {
    action: CriticalSystemSpareActions.UPDATE_CRITICAL_SYSTEM_SPARE,
    actionFulfilled: CriticalSystemSpareActions.UPDATE_CRITICAL_SYSTEM_SPARE_FULFILLED,
    actionRejected: CriticalSystemSpareActions.UPDATE_CRITICAL_SYSTEM_SPARE_REJECTED
} as IActionType;

const deleteActions = {
    action: CriticalSystemSpareActions.DELETE_CRITICAL_SYSTEM_SPARE,
    actionFulfilled: CriticalSystemSpareActions.DELETE_CRITICAL_SYSTEM_SPARE_FULFILLED,
    actionRejected: CriticalSystemSpareActions.DELETE_CRITICAL_SYSTEM_SPARE_REJECTED
} as IActionType;

const exportActions = {
    action: CriticalSystemSpareActions.EXPORT_CRITICAL_SYSTEM_SPARE,
    actionFulfilled: CriticalSystemSpareActions.EXPORT_CRITICAL_SYSTEM_SPARE_FULFILLED,
    actionRejected: CriticalSystemSpareActions.EXPORT_CRITICAL_SYSTEM_SPARE_REJECTED
} as IActionType;

const loadAllEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getAllActions).get<ICriticalSystemSpare[]>(apiPath);

const loadEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getActions).getByIdAndShard<IEntitySite>(apiPath);

const createEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, saveActions, criticalSystemSparePageUrl).post<ICriticalSystemSpare>(apiPath);

const updateEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateActions, criticalSystemSparePageUrl).put<ICriticalSystemSpare>(apiPath);

const deleteEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, deleteActions, criticalSystemSparePageUrl).deleteById<string>(apiPath);

const exportData: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, exportActions).exportBySiteId<ICriticalSystemSpare[]>(`${apiPath}/export`);

export const criticalSystemSpareEpicsV2 = combineEpics(
    loadAllEpic,
    loadEpic,
    createEpic,
    updateEpic,
    deleteEpic,
    exportData
);
