import { IAppAction } from '../app-action';
import { INextExpertReviewStatus, ISetProcessStepResult } from 'models/cerm/cerm-assessment-result';
import { IProcessStepAnswer } from 'models/cerm/process-step-answer';
import { ISnapshotFilters, ISnapshotExportFilters } from 'models/snapshot-filters';

export enum CermAssessmentActions {
    RECORD_ECERM_CLICK = '@@cerm/RECORD_ECERM_CLICK',
    RECORD_ECERM_CLICK_FULFILLED = '@@cerm/RECORD_ECERM_CLICK_FULFILLED',
    RECORD_ECERM_CLICK_REJECTED = '@@cerm/RECORD_ECERM_CLICK_REJECTED',

    REFRESH_ECERM_TOKEN = '@@cerm/REFRESH_ECERM_TOKEN',
    REFRESH_ECERM_TOKEN_FULFILLED = '@@cerm/REFRESH_ECERM_TOKEN_FULFILLED',
    REFRESH_ECERM_TOKEN__REJECTED = '@@cerm/REFRESH_ECERM_TOKEN__REJECTED',

    FINISH_CERM_EXPERT_ASSESSMENT = '@@cerm/FINISH_CERM_EXPERT_ASSESSMENT',
    FINISH_CERM_EXPERT_ASSESSMENT_FULFILLED = '@@cerm/FINISH_CERM_EXPERT_ASSESSMENT_FULFILLED',
    FINISH_CERM_EXPERT_ASSESSMENT__REJECTED = '@@cerm/FINISH_CERM_EXPERT_ASSESSMENT__REJECTED',

    START_CERM_EXPERT_ASSESSMENT = '@@cerm/START_CERM_EXPERT_ASSESSMENT',
    START_CERM_EXPERT_ASSESSMENT_FULFILLED = '@@cerm/START_CERM_EXPERT_ASSESSMENT__FULFILLED',
    START_CERM_EXPERT_ASSESSMENT__REJECTED = '@@cerm/START_CERM_EXPERT_ASSESSMENT__REJECTED',

    LOAD_CERM_ASSESSMENT_RESULT = '@@cerm/LOAD_CERM_ASSESSMENT_RESULT',
    LOAD_CERM_ASSESSMENT_RESULT_FULFILLED = '@@cerm/LOAD_CERM_ASSESSMENT_RESULT_FULFILLED',
    LOAD_CERM_ASSESSMENT_RESULT_REJECTED = '@@cerm/LOAD_CERM_ASSESSMENT_RESULT_REJECTED',

    SET_PROCESS_STEP_RESULT = '@@cerm/SET_PROCESS_STEP_RESULT',
    SET_PROCESS_STEP_RESULT_FULFILLED = '@@cerm/SET_PROCESS_STEP_RESULT_FULFILLED',
    SET_PROCESS_STEP_RESULT_REJECTED = '@@cerm/SET_PROCESS_STEP_RESULT_REJECTED',

    LOAD_PROCESS_STEP_ANSWER = '@@cerm/LOAD_PROCESS_STEP_ANSWER',
    LOAD_PROCESS_STEP_ANSWER_FULFILLED = '@@cerm/LOAD_PROCESS_STEP_ANSWER_FULFILLED',
    LOAD_PROCESS_STEP_ANSWER_REJECTED = '@@cerm/LOAD_PROCESS_STEP_ANSWER_REJECTED',
    LOAD_PROCESS_STEP_OUTCOME = '@@cerm/LOAD_PROCESS_STEP_OUTCOME',
    LOAD_PROCESS_STEP_OUTCOME_FULFILLED = '@@cerm/LOAD_PROCESS_STEP_OUTCOME_FULFILLED',
    LOAD_PROCESS_STEP_OUTCOME_REJECTED = '@@cerm/LOAD_PROCESS_STEP_OUTCOME_REJECTED',

    CREATE_PROCESS_STEP_ANSWER = '@@cerm/CREATE_PROCESS_STEP_ANSWER',
    CREATE_PROCESS_STEP_ANSWER_FULFILLED = '@@cerm/CREATE_PROCESS_STEP_ANSWER_FULFILLED',
    CREATE_PROCESS_STEP_ANSWER_REJECTED = '@@cerm/CREATE_PROCESS_STEP_ANSWER_REJECTED',

    SAVE_PROCESS_STEP_ANSWER = '@@cerm/SAVE_PROCESS_STEP_ANSWER',
    SAVE_PROCESS_STEP_ANSWER_FULFILLED = '@@cerm/SAVE_PROCESS_STEP_ANSWER_FULFILLED',
    SAVE_PROCESS_STEP_ANSWER_REJECTED = '@@cerm/SAVE_PROCESS_STEP_ANSWER_REJECTED',

    SET_PROCESS_STEP_ANSWER = '@@cerm/SET_PROCESS_STEP_ANSWER',

    LOAD_CERM_PROGRESS_CHART_DATA = '@@cerm/LOAD_CERM_PROGRESS_CHART_DATA',
    LOAD_CERM_PROGRESS_CHART_DATA_FULFILLED = '@@cerm/LOAD_CERM_PROGRESS_CHART_DATA_FULFILLED',
    LOAD_CERM_PROGRESS_CHART_DATA_REJECTED = '@@cerm/LOAD_CERM_PROGRESS_CHART_DATA_REJECTED',

    LOAD_CERM_SNAPSHOTS_OVERVIEW_ONLY = '@@cerm/LOAD_CERM_SNAPSHOTS_OVERVIEW_ONLY',
    LOAD_CERM_SNAPSHOTS_OVERVIEW_ONLY_FULFILLED = '@@cerm/LOAD_CERM_SNAPSHOTS_OVERVIEW_ONLY_FULFILLED',
    LOAD_CERM_SNAPSHOTS_OVERVIEW_ONLY_REJECTED = '@@cerm/LOAD_CERM_SNAPSHOTS_OVERVIEW_ONLY_REJECTED',

    LOAD_CERM_SNAPSHOTS_COMPARISON = '@@cerm/LOAD_CERM_SNAPSHOTS_COMPARISON',
    LOAD_CERM_SNAPSHOTS_COMPARISON_FULFILLED = '@@cerm/LOAD_CERM_SNAPSHOTS_COMPARISON_FULFILLED',
    LOAD_CERM_SNAPSHOTS_COMPARISON_REJECTED = '@@cerm/LOAD_CERM_SNAPSHOTS_COMPARISON_REJECTED',

    EXPORT_CERM_SNAPSHOTS_COMPARISON = '@@cerm/EXPORT_CERM_SNAPSHOTS_COMPARISON',
    EXPORT_CERM_SNAPSHOTS_COMPARISON_FULFILLED = '@@cerm/EXPORT_CERM_SNAPSHOTS_COMPARISON_FULFILLED',
    EXPORT_CERM_SNAPSHOTS_COMPARISON_REJECTED = '@@cerm/EXPORT_CERM_SNAPSHOTS_COMPARISON_REJECTED',

    LOAD_HISTORY = '@@cerm/LOAD_HISTORY',
    LOAD_HISTORY_FULFILLED = '@@cerm/LOAD_HISTORY_FULFILLED',
    LOAD_HISTORY_REJECTED = '@@cerm/LOAD_HISTORY_REJECTED',
    LOAD_HISTORY_CANCELLED = '@@cerm/LOAD_HISTORY_CANCELLED',

    LOAD_CERM_ASSESSMENT_EXPERT_REVIEW = '@@cerm/LOAD_CERM_ASSESSMENT_EXPERT_REVIEW',
    LOAD_CERM_ASSESSMENT_EXPERT_REVIEW_FULFILLED = '@@cerm/LOAD_CERM_ASSESSMENT_EXPERT_REVIEW_FULFILLED',
    LOAD_CERM_ASSESSMENT_EXPERT_REVIEW_REJECTED = '@@cerm/LOAD_CERM_ASSESSMENT_EXPERT_REVIEW_REJECTED',

    UPDATE_CERM_ASSESSMENT_EXPERT_REVIEW_STATUS = '@@cerm/UPDATE_CERM_ASSESSMENT_EXPERT_REVIEW_STATUS',
    UPDATE_CERM_ASSESSMENT_EXPERT_REVIEW_STATUS_FULFILLED = '@@cerm/UPDATE_CERM_ASSESSMENT_EXPERT_REVIEW_STATUS_FULFILLED',
    UPDATE_CERM_ASSESSMENT_EXPERT_REVIEW_STATUS_REJECTED = '@@cerm/UPDATE_CERM_ASSESSMENT_EXPERT_REVIEW_STATUS_REJECTED',
}

export const refreshECermToken = (): IAppAction => ({
    type: CermAssessmentActions.REFRESH_ECERM_TOKEN,
});

export const recordECermClick = (siteId: string): IAppAction => ({
    type: CermAssessmentActions.RECORD_ECERM_CLICK,
    payload: { siteId },
});

export const finishCermExpertReview = (siteId: string): IAppAction => ({
    type: CermAssessmentActions.FINISH_CERM_EXPERT_ASSESSMENT,
    payload: { siteId },
});

export const startCermExpertReview = (siteId: string, reviewType: string): IAppAction => ({
    type: CermAssessmentActions.START_CERM_EXPERT_ASSESSMENT,
    payload: { siteId, reviewType },
});

export const loadCermAssessmentResult = (siteId: string): IAppAction => ({
    type: CermAssessmentActions.LOAD_CERM_ASSESSMENT_RESULT,
    payload: siteId,
});

export const setProcessStepResult = (model: ISetProcessStepResult): IAppAction => ({
    type: CermAssessmentActions.SET_PROCESS_STEP_RESULT,
    payload: model,
});

export const loadProcessStepAnswer = (masterProcessStepId: string, siteId: string): IAppAction => ({
    type: CermAssessmentActions.LOAD_PROCESS_STEP_ANSWER,
    payload: {
        id: masterProcessStepId,
        shard: siteId,
    },
});
export const loadProcessStepOutcome = (
    masterProcessStepId: string,
    processStepOutcomeId: string,
    siteId: string
): IAppAction => ({
    type: CermAssessmentActions.LOAD_PROCESS_STEP_OUTCOME,
    payload: {
        id: `${masterProcessStepId}/${processStepOutcomeId}`,
        shard: siteId,
    },
});

export const setProcessStepAnswer = (model: IProcessStepAnswer): IAppAction => ({
    type: CermAssessmentActions.SET_PROCESS_STEP_ANSWER,
    payload: model,
});

export const createProcessStepAnswer = (model: IProcessStepAnswer): IAppAction => ({
    type: CermAssessmentActions.CREATE_PROCESS_STEP_ANSWER,
    payload: model,
});

export const saveProcessStepAnswer = (model: IProcessStepAnswer): IAppAction => {
    if (model.id) {
        return {
            type: CermAssessmentActions.SAVE_PROCESS_STEP_ANSWER,
            payload: model,
        };
    }
    return {
        type: CermAssessmentActions.CREATE_PROCESS_STEP_ANSWER,
        payload: model,
    };
};

export const loadCermProgressChartData = (siteId: string, period: number): IAppAction => ({
    type: CermAssessmentActions.LOAD_CERM_PROGRESS_CHART_DATA,
    payload: { siteId, period },
});

export const loadCermSnapshotsOverviewOnly = (siteId: string): IAppAction => ({
    type: CermAssessmentActions.LOAD_CERM_SNAPSHOTS_OVERVIEW_ONLY,
    payload: siteId,
});

export const loadSnapshotsFullResults = (filters: ISnapshotFilters): IAppAction => ({
    type: CermAssessmentActions.LOAD_CERM_SNAPSHOTS_COMPARISON,
    payload: filters,
});

export const exportSnapshotsComparison = (filters: ISnapshotExportFilters): IAppAction => ({
    type: CermAssessmentActions.EXPORT_CERM_SNAPSHOTS_COMPARISON,
    payload: filters,
});

export const loadHistory = (siteId: string, responseContinuationId: string): IAppAction => ({
    type: CermAssessmentActions.LOAD_HISTORY,
    payload: { siteId, responseContinuationId }
});

export const loadCermAssessmentExpertReview = (siteId: string): IAppAction => ({
    type: CermAssessmentActions.LOAD_CERM_ASSESSMENT_EXPERT_REVIEW,
    payload: siteId,
});

export const updateNextExpertReviewStatus = (model: INextExpertReviewStatus): IAppAction => ({
    type: CermAssessmentActions.UPDATE_CERM_ASSESSMENT_EXPERT_REVIEW_STATUS,
    payload: model,
});
