import * as React from 'react';

import './level-indicator.scss';

export enum Level {
    Low = 'low',
    Medium = 'medium',
    High = 'high'
}

export interface IProps {
    level: Level;
    text: string;
    value: number;
}

export const LevelIndicator: React.FC<IProps> = ({ text, value, level }) => (
    <div className={`LevelIndicator LevelIndicator--${level}`}>
        <span className="LevelIndicator__text">{text}</span>
        <span className="LevelIndicator__value">{value}</span>
    </div>
);
