import { useState, useEffect } from 'react';
import { debounce } from 'lodash';

export function useWindowSize() {
    const isClient = typeof window === 'object';

    const getSize = () => ({
        width: isClient ? window.innerWidth : undefined,
        height: isClient ? window.innerHeight : undefined
    });

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        if (!isClient) {
            return;
        }

        const handleResize = debounce(() => setWindowSize(getSize()), 400);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
}
