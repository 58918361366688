import React, { useState, useRef } from 'react';
import { IAttachmentUpload } from 'models/attachment/attachment-upload';
import cloudupload from 'assets/cloud_upload.png';

interface FileUploadProps {
    onFileUpload: (file: File) => void;
    onFileRemove: () => void;
    uploadFileDetails: IAttachmentUpload | null;
}

const CermFileUpload: React.FC<FileUploadProps> = ({ onFileUpload }) => {
    const [dragging, setDragging] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleDragOver = (e: React.DragEvent): void => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = (): void => {
        setDragging(false);
    };

    const handleDrop = (e: React.DragEvent): void => {
        e.preventDefault();
        setDragging(false);
        const files = e.dataTransfer.files;
        if (files && files.length > 0) {
            onFileUpload(files[0]);
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault();
        const file = e.target.files?.[0];
        if (file) {
            onFileUpload(file);
        }
    };

    const handleClick = (e: React.MouseEvent<HTMLElement>): void => {
        e.preventDefault();
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
        fileInputRef.current?.click();
     };

    return (
        <div className='cerm-file-upload-section'>
            <div
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                style={{
                    border: dragging ? '2px dashed #003f2d' : '2px dashed #003f2d',
                }}
                className='cerm-file-upload'
            >
                <input
                    type="file"
                    onChange={handleFileChange}
                    id="file-upload"
                    ref={fileInputRef}
                />
                <label htmlFor="file-upload" onClick={handleClick}>
                    <img src={cloudupload} alt="cloud_upload" />
                    Drag files to upload or
                </label>
                <div className='cerm-file-upload-btn'>
                    <button onClick={handleClick}>
                        Upload File
                    </button>
                </div>
            </div>
        </div>
    );
};
export default CermFileUpload;

