import React from 'react';
import {
    MockDrillParticipation,
    ISetMockDrillReportParticipation,
    IMockDrillReport,
    MockDrillReportStatus
} from 'models/mock-drills';
import { isEmpty } from 'lodash';
import { Alert, Button } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';

interface IProps {
    isLoading: boolean;
    siteId: string;
    userId: string;
    mockDrillReport: IMockDrillReport;
    setMockDrillReportParticipation: (model: ISetMockDrillReportParticipation) => void;
}

export const MockDrillReportParticipantAlert: React.FC<IProps> = ({
    isLoading,
    siteId,
    userId,
    mockDrillReport,
    setMockDrillReportParticipation
}) => {
    const translate = useTranslate();
    const onButtonClick = (participation: string) => {
        const payload = {
            id: mockDrillReport.id,
            siteId,
            userId,
            participation
        } as ISetMockDrillReportParticipation;
        setMockDrillReportParticipation(payload);
    };

    const shouldShowAlertToCurrentUser = () => {
        if (
            !mockDrillReport ||
            isEmpty(mockDrillReport.participants) ||
            mockDrillReport.status !== MockDrillReportStatus.Completed
        ) {
            return false;
        }

        const found = mockDrillReport.participants.find((p) => p.id === userId);
        return found && found.participation === MockDrillParticipation.Unanswered;
    };

    return shouldShowAlertToCurrentUser() ? (
        <Alert type="warning" title={translate('ConfirmDialog.Title')}>
            <p>{translate('MockDrills.Message.1')}</p>
            <p className="buttons">
                <Button
                    buttonType="archive"
                    onClick={() => onButtonClick(MockDrillParticipation.Rejected)}
                    isLoading={isLoading}
                >
                    {translate('MockDrills.Message.2')}
                </Button>
                <Button
                    onClick={() => onButtonClick(MockDrillParticipation.Confirmed)}
                    isLoading={isLoading}
                >
                    {translate('MockDrills.Message.3')}
                </Button>
            </p>
        </Alert>
    ) : null;
};
