import * as React from 'react';
//import { Control, Field } from '..';
import './emerald-read-only-field.scss';

interface IProps {
    id?: string;
    label: string;
    value?: string | JSX.Element | number;
}

export const EmeraldReadOnlyField: React.FC<React.PropsWithChildren<IProps>> = props => {
    return (
        <div className='columns'>
            <div className="column is-4" id={props.id}>
                <label className="emerald-label">{props.label}</label>
            </div>
            <div className="column is-6 emerald-read-only-field" id={props.id}>
                {props.children ? (
                    props.children
                ) : props.value ? (
                    props.value
                ) : (
                    <span className="na"></span>
                )}
            </div>
        </div>
    );
};
