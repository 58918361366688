import { compact, isNil } from 'lodash';
import { createSelector } from 'reselect';

import { IClient } from 'models/client';
import { ISiteRelationshipsRootState } from 'reducers/siteRelationships';

const getClientText = (client: IClient) =>
    isNil(client)
        ? null
        : compact([
            client.name
        ]).join(' ');

const getCurrentClientName = createSelector(
    (state: ISiteRelationshipsRootState) => state.siteRelationships.changeClientConfirmation,
    (state) => {
        if (isNil(state.site)) {
            return null;
        }

        return state.site.clientName || null;
    }
);

export default createSelector(
    (state: ISiteRelationshipsRootState) => state.siteRelationships.changeClientConfirmation,
    getCurrentClientName,
    (state, fromItemText) => ({
        toItem: state.client,
        toItemText: getClientText(state.client),
        fromItemText,
        fieldName: 'client',
        site: state.site,
        visible: Boolean(state.client && state.site)
    })
);
