//import { LocationChangeAction } from 'connected-react-router';
import { RouterActions } from "redux-first-history";
import { Action } from 'redux';
import { INotification } from 'models/notification';
import { IAppState } from 'reducers/app';
import { Menu } from '../components/nav/Nav';
import { IPersonLookup } from 'models/person-lookup';

export enum NavActions {
    INITIALISE_NAV = '@@nav/INITIALISE_NAV',
    INITIALISE_NAV_FULFILLED = '@@nav/INITIALISE_NAV_FULFILLED',
    INITIALISE_NAV_CANCELLED = '@@nav/INITIALISE_NAV_CANCELLED',
    INITIALISE_NAV_REJECTED = '@@nav/INITIALISE_NAV_REJECTED',
    TOGGLE_NAV_EXPANDED = '@@nav/TOGGLE_NAV_EXPANDED',
    TOGGLE_EXPAND_DROPDOWN_MENU = '@@nav/TOGGLE_EXPAND_DROPDOWN_MENU',
    TOGGLE_EXPAND_USER_MENU = '@@nav/TOGGLE_EXPAND_USER_MENU',
    NOTIFICATION_RECEIVED = '@@nav/NOTIFICATION_RECEIVED',
    NOTIFICATION_READ = '@@nav/NOTIFICATION_READ',
    NOTIFICATION_REJECTED = '@@nav/NOTIFICATION_REJECTED',
    LOAD_PEOPLE_LINKED_TO_SITE = '@@nav/LOAD_PEOPLE_LINKED_TO_SITE',
    LOAD_ALL_PEOPLE_LINKED_TO_SITE = '@@nav/LOAD_ALL_PEOPLE_LINKED_TO_SITE',
    MARK_ALL_READ = '@@nav/NMARK_ALL_READ',
    MARK_ALL_READ_FULFILLED = '@@nav/MARK_ALL_READ_FULFILLED',
    MARK_ALL_READ_CANCELED = '@@nav/MARK_ALL_READ_FULFILLED',
    MARK_ALL_READ_REJECTED = '@@nav/MARK_ALL_READ_FULFILLED',
}

export interface IToggleNavExpandedAction extends Action {
    type: NavActions.TOGGLE_NAV_EXPANDED;
}

export interface IToggleExpandDropdownMenu extends Action {
    type: NavActions.TOGGLE_EXPAND_DROPDOWN_MENU;
    payload: {
        menu: Menu;
    };
}

export interface INotificationReceived extends Action {
    type: NavActions.NOTIFICATION_RECEIVED;
    payload: {
        notification: INotification;
    };
}

export interface INotificationRead extends Action {
    type: NavActions.NOTIFICATION_READ;
    payload: {
        id: string;
        notificationLink: string;
    };
}

export interface ILoadPeopleLinkedToSite extends Action {
    type: NavActions.LOAD_PEOPLE_LINKED_TO_SITE;
    payload: {
        lookupSitePeopleRoles: IPersonLookup[];
    };
}

export interface ILoadAllPeopleLinkedToSite extends Action {
    type: NavActions.LOAD_ALL_PEOPLE_LINKED_TO_SITE;
    payload: {
        lookupSiteAllPeopleRoles: IPersonLookup[];
    };
}

export interface IToggleExpandUserMenu extends Action {
    type: NavActions.TOGGLE_EXPAND_USER_MENU;
    payload: {
        isExpanded: boolean;
    };
}

export interface IInitialiseNav extends Action {
    type: NavActions.INITIALISE_NAV;
}

export interface IMarkAllReadAction extends Action {
    type: NavActions.MARK_ALL_READ;
}

export interface IMarkAllReadActionFulfilled extends Action {
    type: NavActions.MARK_ALL_READ_FULFILLED;
}

export const onInitialiseNav = (): IInitialiseNav => ({
    type: NavActions.INITIALISE_NAV,
});

export interface IInitialiseNavFulfilled extends Action {
    type: NavActions.INITIALISE_NAV_FULFILLED;
    payload: {
        appState: IAppState;
    };
}

export const onInitialiseNavFulfilled = (appState: IAppState): IInitialiseNavFulfilled => ({
    type: NavActions.INITIALISE_NAV_FULFILLED,
    payload: {
        appState,
    },
});

export type NavActionTypes =
    | IInitialiseNav
    | ILoadPeopleLinkedToSite
    | IInitialiseNavFulfilled
    | IToggleNavExpandedAction
    | IToggleExpandDropdownMenu
    | IToggleExpandUserMenu
    | RouterActions
    | IMarkAllReadAction
    | IMarkAllReadActionFulfilled
    | INotificationReceived
    | INotificationRead;
