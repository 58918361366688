import moment from 'moment';
import * as React from 'react';

export enum Format {
    DateAndTime,
    DateOnly,
    TimeOnly,
    Relative
}

export interface IProps extends React.HTMLAttributes<HTMLSpanElement> {
    format?: Format;
    value: string;
}

export const DateTime: React.FC<IProps> = (props) => {
    const { value, format, ...spanProps } = props;

    const getDateString = () => {
        if (value == '0001-01-01T00:00:00+00:00') {
            return ''
        }
        return format === Format.Relative
            ? moment(value).fromNow()
            : moment(value).format(getFormatString(format))
    }

    return (
        <span {...spanProps}>
            {getDateString()
            }
        </span>
    );
};

DateTime.defaultProps = {
    format: Format.DateAndTime
};

function getFormatString(format: Format): string {
    switch (format) {
        case Format.DateAndTime:
            return 'DD MMM YYYY - HH:mm';

        case Format.DateOnly:
            return 'DD MMM YYYY';

        case Format.TimeOnly:
            return 'HH:mm';

        default:
            return undefined;
    }
}
