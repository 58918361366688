import { combineReducers } from 'redux';

import { ManageRoleActions, ManageRoleActionTypes } from '../../actions/manage-role-actions';

export interface IManageRolesFiltersState {
    filterKeywords: string[];
}

export const INITIAL_STATE: IManageRolesFiltersState = {
    filterKeywords: []
};

const filterKeywords = (
    state: IManageRolesFiltersState['filterKeywords'] = INITIAL_STATE.filterKeywords,
    action: ManageRoleActionTypes
) => {
    switch (action.type) {
        case ManageRoleActions.ADD_FILTER_KEYWORD:
            const keywordToAdd = action.payload.keyword;

            if (!keywordToAdd || state.includes(keywordToAdd)) {
                // empty or we already have the filter, just return what we have
                return state;
            }

            return state.concat(keywordToAdd);

        case ManageRoleActions.REMOVE_FILTER_KEYWORD:
            const keywordToRemove = action.payload.keyword;

            if (!keywordToRemove || !state.includes(keywordToRemove)) {
                // empty or we don't have the filter, just return what we have
                return state;
            }

            const newState = state.filter((keyword) => keyword !== keywordToRemove);
            return newState;

        case ManageRoleActions.CLEAR_FILTERS:
            return [];
        default:
            return state;
    }
};

export const reducer = combineReducers<IManageRolesFiltersState>({
    filterKeywords
});
