export const riskQuestionsOverviewJa = `
<div>
<p>下のリンクをクリックしてご覧ください:</p>
<p><a href="https://confluence.cbre.com/display/ED/Risk+Questions+Process" target= "_blank">リスクに関する質問のプロセス</a></p>
</div>
<br />
<div>
<p><a href="https://quantum.cbre.com/issues" target= "_blank">リクエストを出す</a></p>
<p><a href="https://quantum.cbre.com/requests" target= "_blank">クリック</a></p>
</div>`;
