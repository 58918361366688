import { IRootState } from '../store';
import { createSelector } from 'reselect';
import { getCombinedSiteFeatures } from 'sharedSelectors';

const getSiteId = (state: IRootState) => state.app.siteId;
const getShowHelpModule = (state: IRootState) => state.app.showHelpModule;
const getIncidents = (state: IRootState) => state.incidentsState.incidents;

const getIsLoading = (state: IRootState) => state.incidentsState.isLoading;

const getLookupSites = createSelector(
    (state: IRootState) => state.app,
    (state: any) => state.lookupSites
);

const getIsExportingIncidents =  createSelector(
    (state: IRootState) => state.incidentsState,
    (state: any) => state.isExportingIncidents
);
export default createSelector(
    getSiteId,
    getIncidents,
    getLookupSites,
    getIsExportingIncidents,
    getIsLoading,
    getCombinedSiteFeatures,
    getShowHelpModule,
    (siteId, incidents, lookupSites, isExportingIncidents, isLoading, combinedSiteFeatures, showHelpModule) => ({
        siteId,
        incidents,
        lookupSites,
        isExportingIncidents,
        isLoading,
        combinedSiteFeatures,
        showHelpModule,
    })
);
