import classnames from 'classnames';
import * as React from 'react';

import './panel.scss';

export const Panel: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
    const { children, className, ...otherProps } = props;
    const css = classnames('Panel', className);

    return (
        <div className={css} {...otherProps}>
            {props.children}
        </div>
    );
};

export const AsidePanel: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
    const { children, className, ...otherProps } = props;
    const css = classnames('Panel', 'Panel--aside', className);

    return (
        <aside className={css} {...otherProps}>
            {props.children}
        </aside>
    );
};

export const ArticlePanel: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
    const { children, className, ...otherProps } = props;
    const css = classnames('Panel', className);

    return (
        <article className={css} {...otherProps}>
            {props.children}
        </article>
    );
};
