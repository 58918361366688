import { loadRisks, clearForm, exportData, exportAllData } from 'actions/actions-v2/risk-action-v2';
import { loadMacroRisks} from 'actions/actions-v2/macro-risk-action-v2';
import { loadPersonsLookup } from 'actions/actions-v2/person-action-v2';


export default {
    loadAll: loadRisks,
    loadMacroRisks,
    exportData,
    exportAllData,
    clearForm,
    loadPersonsLookup,
};
