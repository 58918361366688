import { IAppAction } from 'actions/app-action';
import { IObsoleteSkillCategory } from "models";

export enum SkillCategoryActions {
  LOAD_SKILL_CATEGORIES = "@@skillCategory/LOAD_SKILL_CATEGORIES",
  LOAD_SKILL_CATEGORIES_FULFILLED = "@@skillCategory/LOAD_SKILL_CATEGORIES_FULFILLED",
  LOAD_SKILL_CATEGORIES_REJECTED = "@@skillCategory/LOAD_SKILL_CATEGORIES_REJECTED",

  LOAD_OBSOLETE_SKILL_CATEGORIES = "@@skillCategory/LOAD_OBSOLETE_SKILL_CATEGORIES",
  LOAD_OBSOLETE_SKILL_CATEGORY_FULFILLED = "@@skillCategory/LOAD_OBSOLETE_SKILL_CATEGORY_FULFILLED",
  LOAD_OBSOLETE_SKILL_CATEGORY_REJECTED = "@@skillCategory/LOAD_OBSOLETE_SKILL_CATEGORY_REJECTED",

  CREATE_SKILL_CATEGORY = "@@skillCategory/CREATE_SKILL_CATEGORY",
  CREATE_SKILL_CATEGORY_FULFILLED = "@@skillCategory/CREATE_SKILL_CATEGORY_FULFILLED",
  CREATE_SKILL_CATEGORY_REJECTED = "@@skillCategory/CREATE_SKILL_CATEGORY_REJECTED",

  UPDATE_SKILL_CATEGORY = "@@skillCategory/UPDATE_SKILL_CATEGORY",
  UPDATE_SKILL_CATEGORY_FULFILLED = "@@skillCategory/UPDATE_SKILL_CATEGORY_FULFILLED",
  UPDATE_SKILL_CATEGORY_REJECTED = "@@skillCategory/UPDATE_SKILL_CATEGORY_REJECTED",

  SORT_SKILL_CATEGORIES = '@@skillCategory/SORT_SKILL_CATEGORIES'
}

export const loadSkillCategories = (
  siteId: string,
  skillCardId: string
): IAppAction => ({
  type: SkillCategoryActions.LOAD_SKILL_CATEGORIES,
  payload: { id: siteId, shard: skillCardId },
});

export const loadObsoleteSkillCategories = (
  siteId: string,
  qualificationCardTypes: string
): IAppAction => ({
  type: SkillCategoryActions.LOAD_OBSOLETE_SKILL_CATEGORIES,
  payload: { siteId, qualificationCardTypes },
});

export const createSkillCategory = (
  skillCategory: IObsoleteSkillCategory
): IAppAction => ({
  type: SkillCategoryActions.CREATE_SKILL_CATEGORY,
  payload: skillCategory,
});

export const updateSkillCategory = (
  skillCategory: IObsoleteSkillCategory
): IAppAction => ({
  type: SkillCategoryActions.UPDATE_SKILL_CATEGORY,
  payload: skillCategory,
});

export const onSortSkillCategories = (
  key: Extract<keyof IObsoleteSkillCategory, string>,
  sortAscending: boolean
) => ({
  type: SkillCategoryActions.SORT_SKILL_CATEGORIES,
  payload: { key, sortAscending },
});
