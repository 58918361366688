import * as React from 'react';
import './Nav.scss';

export interface IStateProps {
    linkElement: React.ReactFragment;
}

export const NavLink: React.FC<
    IStateProps
> = (props) => {
    return (
        <a className="navbar-link">
            {props.linkElement}
        </a>
    );
};
