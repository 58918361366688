import { Formik, FormikProps } from 'formik';
import * as React from 'react';
import { object, ObjectSchema, string } from 'yup';
import { Control, Field, Help, Input } from 'components/form';
import { Modal } from 'components/modal';
import { BulmaColor } from 'enums/BulmaColor';
import { BulmaSize } from 'enums/BulmaSize';
import { IRole } from 'reducers/manageRoles/manageRoles-grid';
import { formikFieldCss } from 'utils/form-utils';
import { Select } from 'components/select';
import { IKeyValue } from 'models';
import { Button } from 'components/v2/components';
import { translate } from 'translations/translation-utils';
import { createIntl, createIntlCache } from 'react-intl';

export interface IProps {
    roleLevels: Array<IKeyValue<number>>;
    isShown: boolean;
    upsertRoleRequest?: IRole;
}

export interface IState {
    chosenValidationSchema: ObjectSchema<Partial<IRole>>;
}

const cache = createIntlCache();
const intl = createIntl({ locale: 'en', messages: {} }, cache);

export interface IActionProps {
    onClose: () => void;
    updateRole: (request: IRole) => void;
    createRole: (request: IRole) => void;
}

export interface IMergProps extends IProps, IActionProps { }

const roleValidationSchema = object<Partial<IRole>>().shape({
    value: string().required('Please select a site')
});

export class RoleUpsertModal extends React.Component<IMergProps, IState> {
    constructor(props: IMergProps) {
        super(props);
        this.state = {
            chosenValidationSchema: roleValidationSchema
        };
    }

    static get defaultProps(): Partial<IRole> {
        return {
            value: '',
            id: '',
            description: '',
            peopleInRole: 0,
            privilegesInRole: [],
        };
    }

    public render() {
        const renderForm = (formikBag: FormikProps<IRole>) => {
            const changeRoleLevel = (event: any) => {
                formikBag.setFieldValue('roleLevel', event.target.value);
            };
            return (
                <form id="roleUpsertModal" onSubmit={formikBag.handleSubmit}>
                    <div className="columns">
                        <div className="column is-9">
                            <Field isHorizontal={true} htmlFor="title" label={translate(intl, 'ManageRole.Index.RoleName')} labelSize={BulmaSize.Medium}>
                                <Field>
                                    <Control>
                                        <Input
                                            id="value"
                                            name="value"
                                            aria-required="true"
                                            placeholder={translate(intl, 'IncidentApprovals.Label.Name')}
                                            type="text"
                                            className={formikFieldCss(formikBag, 'value')}
                                            value={formikBag.values.value}
                                            onChange={formikBag.handleChange}
                                            onBlur={(event) => formikBag.setFieldValue(event.target.name, event.target.value.trim())}
                                        />
                                    </Control>
                                    <Help
                                        isShown={formikBag.touched.value}
                                        bulmaColor={BulmaColor.Danger}
                                    >
                                        {formikBag.errors.value}
                                    </Help>
                                </Field>
                            </Field>
                            <Field
                                isHorizontal={true}
                                htmlFor="roleLevel"
                                label={translate(intl, 'Roles.Level')}
                                labelSize={BulmaSize.Medium}
                            >
                                <Field>
                                    <Control>
                                        <Select
                                            id="roleLevel"
                                            name="roleLevel"
                                            options={this.props.roleLevels}
                                            className={formikFieldCss(formikBag, 'roleLevel')}
                                            value={formikBag.values.roleLevel}
                                            onChange={changeRoleLevel}
                                            onBlur={formikBag.handleBlur}
                                            emptyOptionsValue={translate(intl, 'RiskActionTab.Placeholder.emptyOptionsValue')}
                                        />
                                    </Control>
                                    <Help
                                        isShown={formikBag.touched.roleLevel}
                                        bulmaColor={BulmaColor.Danger}
                                    >
                                        {formikBag.errors.roleLevel}
                                    </Help>
                                </Field>
                            </Field>
                            <Field
                                isHorizontal={true}
                                htmlFor="description"
                                label={translate(intl, 'RiskDetailsPage.label.Description')}
                                labelSize={BulmaSize.Medium}
                            >
                                <Field>
                                    <Control>
                                        <textarea
                                            id="description"
                                            name="description"
                                            aria-required="true"
                                            className={formikFieldCss(formikBag, 'description', 'textarea')}
                                            placeholder={translate(intl, 'ManageRole.Index.DescriptionOfRole')}
                                            value={formikBag.values.description}
                                            onBlur={formikBag.handleBlur}
                                            onChange={formikBag.handleChange}
                                        />
                                    </Control>
                                </Field>
                            </Field>

                        </div>
                        <div className="column is-3">
                            <div className="field">
                                <Button
                                    id="riskUpsertModal__publish"
                                    onClick={this.onSubmit.bind(this, formikBag.values)}
                                    disabled={false}
                                >
                                    {translate(intl, 'Globals.Label.Save')}
                                </Button>
                            </div>

                            <p className="field">
                                <Button
                                    id="riskUpsertModal__cancel"
                                    buttonType="cancel"
                                    onClick={this.onClose}
                                >
                                    {translate(intl, 'Globals.Label.Cancel')}
                                </Button>
                            </p>
                        </div>
                    </div>
                </form>
            );
        };

        return (
            <Modal modalId="upsertRoleModal" isShown={this.props.isShown}>
                <Formik
                    initialValues={this.props.upsertRoleRequest}
                    validationSchema={this.state.chosenValidationSchema}
                    onSubmit={this.onSubmit}
                    render={renderForm}
                />
            </Modal>
        );
    }

    private onSubmit = (upsertRoleRequest: IRole) => {
        if (upsertRoleRequest.id) {
            this.props.updateRole(upsertRoleRequest);
        } else {
            this.props.createRole(upsertRoleRequest);
        }
    }

    private onClose: React.MouseEventHandler<HTMLButtonElement> = () => {
        this.props.onClose();
    }
}
