import { IAppAction } from '../app-action';
import { ISiteSkills } from 'models';

export enum SiteSkillsActions {
    LOAD_SITE_SKILLS = '@@siteSkills/LOAD_SITE_SKILLS',
    LOAD_SITE_SKILLS_FULFILLED = '@@siteSkills/LOAD_SITE_SKILLS_FULFILLED',
    LOAD_SITE_SKILLS_REJECTED = '@@siteSkills/LOAD_SITE_SKILLS_REJECTED',

    CREATE_SITE_SKILLS = '@@siteSkills/CREATE_SITE_SKILLS',
    CREATE_SITE_SKILLS_FULFILLED = '@@siteSkills/CREATE_SITE_SKILLS_FULFILLED',
    CREATE_SITE_SKILLS_REJECTED = '@@siteSkills/CREATE_SITE_SKILLS_REJECTED',

    UPDATE_SITE_SKILLS = '@@siteSkills/UPDATE_SITE_SKILLS',
    UPDATE_SITE_SKILLS_FULFILLED = '@@siteSkills/UPDATE_SITE_SKILLS_FULFILLED',
    UPDATE_SITE_SKILLS_REJECTED = '@@siteSkills/UPDATE_SITE_SKILLS_REJECTED',

    SORT_SITE_SKILLS = '@@siteSkills/SORT_SITE_SKILLS',

    RESET_SAVED_SITE_SKILL_CARD_STATE = '@@siteSkills/RESET_SAVED_SITE_SKILL_CARD_STATE'
}

export interface ISiteSkillsFilters {
    id: string;
}

export const loadSiteSkills = (siteSkillFilters: ISiteSkillsFilters): IAppAction => ({
    type: SiteSkillsActions.LOAD_SITE_SKILLS,
    payload: siteSkillFilters
});

export const createSiteSkills = (siteSkills: ISiteSkills): IAppAction => ({
    type: SiteSkillsActions.CREATE_SITE_SKILLS,
    payload: siteSkills
});

export const updateSiteSkills = (siteSkills: ISiteSkills): IAppAction => ({
    type: SiteSkillsActions.UPDATE_SITE_SKILLS,
    payload: siteSkills
});

export const onSortSiteSkills = (key: Extract<keyof ISiteSkills, string>, sortAscending: boolean) => ({
    type: SiteSkillsActions.SORT_SITE_SKILLS,
    payload: { key, sortAscending }
});

export const resetSavedSiteSkillCardState = () => ({
    type: SiteSkillsActions.RESET_SAVED_SITE_SKILL_CARD_STATE
});
