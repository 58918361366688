import { FormikProps } from 'formik';
import { createSelector } from 'reselect';
import { IProgressSection } from 'components/progress-bar/progress-bar';
import { ISiteProfile } from 'models/site-profile';
import { ISiteState, IUpsertSiteRootState } from 'state/upsert-site-state';
import { isCreating } from '../../../utils/id-utils';
import {
    RouteUrlBuilding,
    RouteUrlSite,
    RouteUrlSiteSummary,
    RouteUrlUsers,
    RouteUrlSystemIntegration,
} from '../routes';
import { checkPermissions } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import { IRootClientsState } from 'reducers/clients';
export const getUpsertSiteState = (state: IUpsertSiteRootState) => state.siteState;
export const getUpsertClientState = (state: IRootClientsState) => state.clientPage.upsertItem;

export const isCreatingSite = createSelector(getUpsertSiteState, (state: ISiteState) =>
    isCreating(state.site) ? true : false
);
export const convertToBoolean = (input: string): boolean | undefined => {
    try {
        return JSON.parse(input);
    } catch (e) {
        return undefined;
    }
};

export const convertToNumber = (input: string): number | undefined => {
    try {
        return JSON.parse(input);
    } catch (e) {
        return undefined;
    }
};

export const getProgressSections = (
    formikBag: FormikProps<Partial<ISiteProfile>>,
    id: string,
    permissions,
    translate: (key: string) => string,
) => {
    const sections: IProgressSection[] = [
        {
            title: translate('UpsertSite.SiteForm.Panel.Header.Site'),
            text: formikBag.values.name,
            urlLocation: RouteUrlSite(id),
        },
    ];

    if (formikBag.values.buildings && formikBag.values.buildings.length > 1) {
        sections.push({
            title: translate('UpsertSite.SiteForm.Field.SiteSummary'),
            text: translate('UpsertSite.SiteForm.Field.OverviewOfSite'),
            urlLocation: RouteUrlSiteSummary(id),
        });
    }

    if (formikBag.values && formikBag.values.buildings) {
        formikBag.values.buildings.map((building, index) => {
            if (building) {
                sections.push({
                    title: `${translate('UpsertSite.SiteForm.Panel.Header.Facility')} #${index + 1} `,
                    urlLocation: `${RouteUrlBuilding(id)}/${index}`,
                    text: building.buildingName,
                });
            }
        });
    }

    if (formikBag.values && formikBag.values.id) {
        sections.push({
            title: translate('UpsertSite.SiteForm.Panel.Header.Users'),
            text: translate('UpsertSite.SiteForm.Panel.Header.UsersInfo'),
            urlLocation: RouteUrlUsers(id),
        });
    }
    if (checkPermissions([Privilege.SystemIntegrations], permissions))
        sections.push({
            title: translate('UpsertSite.SiteForm.Panel.Header.SystemIntegration'),
            text: translate('UpsertSite.SiteForm.Panel.Header.SystemIntegrationInfo'),
            urlLocation: RouteUrlSystemIntegration(id),
        });

    return sections;
};
