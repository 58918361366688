export class EmeraldRadioButtonGroupCoreInputs
  implements IEmeraldRadioButtonGroupCoreInputs
{
  name!: string;
  disabled?: boolean;
  labelPosition?: string;
  orientation?: string;
  readOnly?: boolean;
  defaultSelected?: string | number;
  children?: any;
}

export interface IEmeraldRadioButtonGroupCoreInputs {
  name: string;
  disabled?: boolean;
  labelPosition?: string;
  orientation?: string;
  readOnly?: boolean;
  defaultSelected?: string | number;
  children?: any;
}
