import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { UserProfileActions } from 'actions/actions-v2/user-profile-action-v2';
import { IPerson } from 'models/person';
import { AppActions } from 'actions/app-actions';
import { IPersonAssignedSite } from 'models/site-profile';

const controllerName = 'userProfile';

const updateActions = {
    action: UserProfileActions.UPDATE_PERSON,
    actionFulfilled: UserProfileActions.UPDATE_PERSON_FULFILLED,
    actionRejected: UserProfileActions.UPDATE_PERSON_REJECTED,
} as IActionType;

const getActions = {
    action: UserProfileActions.LOAD_PERSON,
    actionFulfilled: UserProfileActions.LOAD_PERSON_FULFILLED,
    actionRejected: UserProfileActions.LOAD_PERSON_REJECTED,
} as IActionType;

const relationshipsActions = {
    action: UserProfileActions.PERSON_RELATIONSHIP,
    actionFulfilled: UserProfileActions.PERSON_RELATIONSHIP_FULFILLED,
    actionRejected: UserProfileActions.PERSON_RELATIONSHIP_REJECTED,
} as IActionType;

const updateLanguageAction = {
    action: UserProfileActions.UPDATE_LANGUAGE,
    actionFulfilled: UserProfileActions.UPDATE_LANGUAGE_FULFILLED,
    actionRejected: UserProfileActions.UPDATE_LANGUAGE_REJECTED,
} as IActionType;

const updateFaqEnableAction = {
    action: UserProfileActions.UPDATE_IS_SHOW_FAQ,
    actionFulfilled: UserProfileActions.UPDATE_IS_SHOW_FAQ_FULFILLED,
    actionRejected: UserProfileActions.UPDATE_IS_SHOW_FAQ_REJECTED,
} as IActionType;

const updateSelectedSiteAction = {
    action: AppActions.CHANGE_SITE,
    actionFulfilled: AppActions.CHANGE_SITE_FULFILLED,
    actionRejected: AppActions.CHANGE_SITE_REJECTED,
} as IActionType;

const updatePreferredRoleAction = {
    action: UserProfileActions.UPDATE_PREFERRED_ROLE,
    actionFulfilled: UserProfileActions.UPDATE_PREFERRED_ROLE_FULFILLED,
    actionRejected: UserProfileActions.UPDATE_PREFERRED_ROLE_REJECTED,
} as IActionType;

const getMyAssignedSitesActions = {
    action: UserProfileActions.LOAD_MY_ASSIGNED_SITES,
    actionFulfilled: UserProfileActions.LOAD_MY_ASSIGNED_SITES_FULFILLED,
    actionRejected: UserProfileActions.LOAD_MY_ASSIGNED_SITES_REJECTED,
} as IActionType;

const updatePreferredOperatingPlatformAction = {
    action: UserProfileActions.UPDATE_PREFERRED_OPERATING_PLATFORM,
    actionFulfilled: UserProfileActions.UPDATE_PREFERRED_OPERATING_PLATFORM_FULFILLED,
    actionRejected: UserProfileActions.UPDATE_PREFERRED_OPERATING_PLATFORM_REJECTED,
} as IActionType;
const updatePersonEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateActions).put<IPerson>(`/api/${controllerName}`);
const loadPersonEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getActions).getById<IPerson>(`/api/${controllerName}`);
const personRelationshipsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, relationshipsActions).get<IPerson>(`/api/${controllerName}/relationships`);
const updateLanguageEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateLanguageAction).put<IPerson>(`/api/${controllerName}`);
const updateFaqEnableEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateFaqEnableAction).put<IPerson>(`/api/${controllerName}`);
const updateSelectedSiteEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateSelectedSiteAction).put<IPerson>(
        `/api/${controllerName}/updateSelectedSite`
    );
const updatePreferredRoleEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updatePreferredRoleAction).put<IPerson>(`/api/${controllerName}`);
const loadMyAssignedSitesEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getMyAssignedSitesActions).get<IPersonAssignedSite[]>(`/api/${controllerName}/my-assigned-sites`);
const updatePreferredOperatingPlatformEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updatePreferredOperatingPlatformAction).put<IPerson>(`/api/${controllerName}`);

export const userProfileEpicsV2 = combineEpics(
    updateSelectedSiteEpic,
    updatePersonEpic,
    loadPersonEpic,
    updateLanguageEpic,
    updateFaqEnableEpic,
    updatePreferredRoleEpic,
    loadMyAssignedSitesEpic,
    updatePreferredOperatingPlatformEpic,
    personRelationshipsEpic,
);
