import * as React from 'react';
import { IIncident } from 'models/incident';
import { IncidentStatus } from 'models/incident-enums';
import { Spinner } from '../spinner';
import useTranslate from 'translations/translation-utils'

interface IIncidentStatusCountsProps {
    isLoading: boolean;
    items: IIncident[];
}

export const IncidentStatusCounts: React.FC<IIncidentStatusCountsProps> = ({
    isLoading,
    items
}) => {
    const translate = useTranslate()
    const getIncidentCount = (status: IncidentStatus) => {
        if (isLoading) {
            return <Spinner />;
        }

        return items.filter((x) => x.status === status).length;
    };

    return (
        <div className="status-spacing">
            <div>{translate('IncidentList.IncidentStatus.Open')}:</div>
            <span className="status-indicator is-urgent has-text-centered">
                <span className="status-count">{getIncidentCount(IncidentStatus.Open)}</span>
            </span>
            <div>{translate('IncidentList.IncidentStatus.Pending')}:</div>
            <span className="status-indicator orangecheck has-text-centered">
                <span className="status-count">{getIncidentCount(IncidentStatus.Pending)}</span>
            </span>
            <div>{translate('IncidentList.IncidentStatus.Closed')}:</div>
            <span className="status-indicator greencheck has-text-centered">
                <span className="status-count">{getIncidentCount(IncidentStatus.Closed)}</span>
            </span>
        </div>
    );
};
