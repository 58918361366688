import React from 'react';
import ReduxToastr from 'react-redux-toastr';

export const Toastr: React.FC = () => (
    <ReduxToastr
        timeOut={8000}
        newestOnTop={false}
        preventDuplicates={true}
        position="bottom-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar={true}
    />
);
