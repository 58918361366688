import * as React from 'react';
import { IRiskAssessmentState } from 'reducers/risk-assessment';
import RiskQuestionStepItem from './risk-question-step-item';
import { MaterialIconType } from 'routes/material-icon/material-icon-type';
import { MaterialIcons } from 'routes/material-icon/material-icon';

interface IProps extends IRiskAssessmentState {
    siteId: string;
    onQuestionSetAnswerChanged: (questionSetId: string, answer: boolean | null) => void;
    onQuestionAnswerChanged: (
        questionId: string,
        questionSetId: string,
        answer: boolean | null
    ) => void;
}

export const QuestionSetList: React.FC<IProps> = ({ siteId, ...props }) => {
    const [isExpanded, setIsExpanded] = React.useState(false);

    React.useEffect(() => {
        setIsExpanded(false);
    }, [siteId]);

    const onClickHandle = () => {
        setIsExpanded((prev) => !prev);
    };

    return (
        <>
            <div className="column is-12 btn_padding">
                <button className="green_icon" onClick={onClickHandle}>
                    < MaterialIcons type={isExpanded ? MaterialIconType.Minus : MaterialIconType.Plus} />
                </button>
            </div>
            {props.questionSets &&
                props.questionSets.map((questionSet) => (
                    <RiskQuestionStepItem
                        key={questionSet.masterQuestionSetId}
                        questionSet={questionSet}
                        expandAll={isExpanded}
                        onQuestionSetAnswerChanged={props.onQuestionSetAnswerChanged}
                        onQuestionAnswerChanged={props.onQuestionAnswerChanged}
                    />
                ))}
        </>
    );
};
