import messagesGb from './locales/en-GB.json';
import messagesEs from './locales/es.json';
import messagesJa from './locales/ja.json';
// import messagesZhCN from './locales/zh-CN.json';
// import messagesZhTW from './locales/zh-TW.json';
import messagesFr from './locales/fr.json';
import messagesDe from './locales/de.json';
import messagesPt from './locales/pt.json';
import messagesNl from './locales/nl.json';

export interface ILanguage {
    key: string;
    display: string;
    data: any;
}

export const languages: ILanguage[] = [
    {
        key: 'en-GB',
        display: 'English',
        data: messagesGb,
    },
    {
        key: 'es',
        display: 'Spanish',
        data: messagesEs,
    },
    {
        key: 'ja',
        display: '日本語',
        data: messagesJa,
    },
    {
        key: 'zh-CN',
        display: '中文（简体)',
        //data: messagesZhCN,
        data: messagesGb,
    },
    {
        key: 'zh-TW',
        display: '中文 (傳統的)',
        //data: messagesZhTW,
        data: messagesGb,
    },
    {
        key: 'fr',
        display: 'français',
        data: messagesFr,
    },
    {
        key: 'de',
        display: 'Deutsch',
        data: messagesDe,
    },
    {
        key: 'pt',
        display: 'português',
        data: messagesPt,
    },
    {
        key: 'nl',
        display: 'Dutch',
        data: messagesNl,
    },
].sort((a, b) => a.display.localeCompare(b.display));
