import React from 'react';
import {
    Timeline,
    TimelineHeader,
    TimelineHeaderGridValue,
    TimelineHeaderGridValueCollection,
    TimelineItem,
} from 'components/timeline';
import OpportunityQuestionResponse from './opportunity-questionnaire-response';
import OpportunityAdvice from './opportunity-questionnaire-advice';
import OpportunitySubQuestionList from './opportunity-questionnaire-subquestions-list';
import { IOpportunityQuestion } from 'models/opportunity-question-set';
import useTranslate from 'translations/translation-utils';

interface IProps {
    questionSet: Partial<IOpportunityQuestion>;
    expandAll?: boolean;
    onQuestionAnswerChanged: (questionId: string, questionSetId: string, answer: string) => void;
    selectedResponseAnwer: (questionId: string, questionSetId: string) => string;
    responseAnswer: string;
    questionSetId: string;
}

const OpportunitySubQuestionnaire: React.FC<IProps> = ({
    questionSet,
    expandAll,
    questionSetId,
    responseAnswer,
    onQuestionAnswerChanged,
    selectedResponseAnwer,
}) => {
    const translate = useTranslate();
    const [isToggled, setIsToggled] = React.useState(false);
    const [subResponseAnswer, setResponseAnswer] = React.useState('');
    const [allResponseAnswers, setAllResponseAnswers] = React.useState([]);

    React.useEffect(() => {
        setIsToggled(expandAll);
    }, [expandAll]);

    React.useEffect(() => {
        getAllResponseAnswers();
    }, [questionSet]);

    const getAllResponseAnswers = () => {
        let responseAns = [];
        if (questionSet.questions) {
            questionSet.questions.forEach((question) => {
                responseAns = [...new Set([...responseAns, ...question.responseAnswers])];
            });
        }
        if (questionSet.advices) {
            questionSet.advices.forEach((advice) => {
                responseAns = [...new Set([...responseAns, ...advice.responseAnswers])];
            });
        }

        setAllResponseAnswers(responseAns);
    };

    React.useEffect(() => {
        setIsToggled(!isToggled);
    }, [responseAnswer]);

    React.useEffect(() => {
        if (allResponseAnswers.includes(subResponseAnswer)) {
            setIsToggled(true);
        } else {
            setIsToggled(false);
        }
    }, [subResponseAnswer]);

    const setResponse = (questionId: string, response: string) => {
        if (response !== translate('OpportunityQ.Dropdown.DefaultLabel')) {
            onQuestionAnswerChanged(questionId, questionSetId, response);
        }
        setResponseAnswer(response);
    };

    const onToggleButtonClick = () => {
        if (subResponseAnswer === translate('OpportunityQ.Dropdown.DefaultLabel')) {
            setIsToggled(false);
        } else {
            setIsToggled(!isToggled);
        }
    };
    const checkResponse = (responses: string[], responseAns: string) => {
        if (responses && responses.includes(responseAns)) {
            return true;
        } else {
            return false;
        }
    };
    const renderTimeline = (questions: Partial<IOpportunityQuestion>) => {
        return checkResponse(questions.responseAnswers, responseAnswer) ? (
            <TimelineItem>
                <div className="timeline-inner">
                    <Timeline expanded={isToggled} key={`${questions.id}`}>
                        <TimelineHeader
                            expanded={isToggled}
                            onToggleButtonClick={onToggleButtonClick}
                            centered={true}
                            disabled={!questions.title}
                        >
                            <TimelineHeaderGridValueCollection>
                                <TimelineHeaderGridValue className="timeline-title column is-6">
                                    Question - {questions.title}
                                </TimelineHeaderGridValue>
                                <TimelineHeaderGridValue
                                    label={translate('OpportunityQ.TimelineHeader.Label')}
                                    className="column is-6 timeline-response"
                                >
                                    <OpportunityQuestionResponse
                                        onChange={setResponse}
                                        answers={allResponseAnswers}
                                        questionId={questions.id}
                                        selectedResponseAns={selectedResponseAnwer(
                                            questions.id,
                                            questionSetId
                                        )}
                                    />
                                </TimelineHeaderGridValue>
                            </TimelineHeaderGridValueCollection>
                        </TimelineHeader>
                        {questions.questions && (
                            <OpportunitySubQuestionList
                                questionSet={questions.questions}
                                responseAnswer={subResponseAnswer}
                                expandAll={expandAll}
                                onQuestionAnswerChanged={onQuestionAnswerChanged}
                                questionSetId={questionSetId}
                                selectedResponseAnwer={selectedResponseAnwer}
                            />
                        )}
                        {questions.advices &&
                            questions.advices.map((advice) => {
                                return checkResponse(advice.responseAnswers, subResponseAnswer) ? (
                                    <OpportunityAdvice advice={advice} />
                                ) : null;
                            })}
                    </Timeline>
                </div>
            </TimelineItem>
        ) : null;
    };

    return <>{renderTimeline(questionSet)}</>;
};

export default OpportunitySubQuestionnaire;
