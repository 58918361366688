import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import React from 'react';
//import ReactDOM from 'react-dom';
import App from 'routes/App';
import * as serviceWorker from './serviceWorker';
import './scss/site.scss';
import { createBrowserHistory } from 'history';
import { initializeAuthentication, getAuthenticationPlatform } from 'helpers/authentication';
export const history = createBrowserHistory();


window.opener = null;

initializeAuthentication();

if (localStorage.getItem("msal.error") === "invalid_request" && localStorage.getItem("msal.error.description")) {
    const allKeys = Object.keys(localStorage);
    allKeys.forEach(value => {
        if (!(value === "appVersion" || value === "loginRedirectUrl")) {
            localStorage.removeItem(value);
        }
    });
    window.location.href = window.location.protocol + "//" + window.location.host;
    window.location.reload();
}
const renderApp = () => {
    if (window.location.pathname.includes("/null")) {
        const loginRedirectUrl = localStorage.getItem("loginRedirectUrl");
        if (loginRedirectUrl) {
            history.push(loginRedirectUrl);
        } else {
            history.push("/");
        }
    }
    localStorage.removeItem("loginRedirectUrl");
    return (
        <>
            <App />
        </>
    )
}

getAuthenticationPlatform().run(() => {
    const container = document.getElementById("root");
    const root = createRoot(container!);
    root.render(renderApp());

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
});
