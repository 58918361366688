import * as React from 'react';

import { ActionUpsertModal } from '../../../../components/actionUpsertModal';
import { IActionLookups } from '../../../../models/action-lookups';
import { IAction } from 'models/action';

export interface IStateProps extends IActionLookups {
    userId: string;
    isVisible: boolean;
    isModalVisible: boolean;
}

export interface IDispatchProps {
    onCreate: (item: IAction) => void;
    hideModal: (riskId: string, siteId: string) => void;
}

export interface IOwnProps {
    siteId: string;
    riskId: string;
}

interface IProps extends IStateProps, IDispatchProps, IOwnProps { }

export const RiskAddLinkedActionModal: React.FC<IProps> = (props) => {
    if (!props.isVisible) {
        return null;
    }

    const hideModal = () => props.hideModal(props.riskId, props.siteId);

    const onConfirm = (item: IAction) => props.onCreate(item);

    const model: Partial<IAction> = {
        riskItemId: props.riskId,
        siteId: props.siteId,
        reminderDate: (new Date()).toISOString(),
        dueDate: (new Date()).toISOString(),
        assigneeId: props.userId
    };

    return (
        <ActionUpsertModal
            onClose={hideModal}
            isShown={props.isModalVisible}
            onSubmit={onConfirm}
            upsertActionRequest={model}
            {...props}
        />
    );
};

RiskAddLinkedActionModal.defaultProps = {
    isModalVisible: false
};
