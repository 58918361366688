import { IAppAction } from 'actions/app-action';
import { Epic, combineEpics } from 'redux-observable';
import { BaseEpic, IActionType } from './base-epic';
import { GlobalSkillActions } from 'actions/global-skill-actions';
import { IGlobalSkill } from 'models/admin-cdna/global-skill';

const controllerName = 'GlobalSkill';

const loadGlobalSkill = {
    action: GlobalSkillActions.LOAD_GLOBAL_SKILL,
    actionFulfilled: GlobalSkillActions.LOAD_GLOBAL_SKILL_FULFILLED,
    actionRejected: GlobalSkillActions.LOAD_GLOBAL_SKILL_REJECTED,
} as IActionType;

const loadGlobalSkillsByCategory = {
    action: GlobalSkillActions.LOAD_GLOBAL_SKILLS_BY_CATEGORY,
    actionFulfilled: GlobalSkillActions.LOAD_GLOBAL_SKILLS_BY_CATEGORY_FULFILLED,
    actionRejected: GlobalSkillActions.LOAD_GLOBAL_SKILLS_BY_CATEGORY_REJECTED,
} as IActionType;

const loadGlobalSkillsByCard = {
    action: GlobalSkillActions.LOAD_GLOBAL_SKILLS_BY_CARD,
    actionFulfilled: GlobalSkillActions.LOAD_GLOBAL_SKILLS_BY_CARD_FULFILLED,
    actionRejected: GlobalSkillActions.LOAD_GLOBAL_SKILLS_BY_CARD_REJECTED,
} as IActionType;

const createGlobalSkill = {
    action: GlobalSkillActions.CREATE_GLOBAL_SKILL,
    actionFulfilled: GlobalSkillActions.CREATE_GLOBAL_SKILL_FULFILLED,
    actionRejected: GlobalSkillActions.CREATE_GLOBAL_SKILL_REJECTED,
} as IActionType;

const updateGlobalSkill = {
    action: GlobalSkillActions.UPDATE_GLOBAL_SKILL,
    actionFulfilled: GlobalSkillActions.UPDATE_GLOBAL_SKILL_FULFILLED,
    actionRejected: GlobalSkillActions.UPDATE_GLOBAL_SKILL_REJECTED,
} as IActionType;

const deleteGlobalSkill = {
    action: GlobalSkillActions.DELETE_GLOBAL_SKILL,
    actionFulfilled: GlobalSkillActions.DELETE_GLOBAL_SKILL_FULFILLED,
    actionRejected: GlobalSkillActions.DELETE_GLOBAL_SKILL_REJECTED,
} as IActionType;

const loadGlobalSkillEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, loadGlobalSkill).getById<IGlobalSkill>(`/api/${controllerName}`);

const loadGlobalSkillsByCategoryEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, loadGlobalSkillsByCategory).getById<IGlobalSkill[]>(`/api/${controllerName}/category`);

const loadGlobalSkillsByCardEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, loadGlobalSkillsByCard).getById<IGlobalSkill[]>(`/api/${controllerName}/card`);

const createSkillEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createGlobalSkill).post<IGlobalSkill>(`/api/${controllerName}`);

const updateSkillEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateGlobalSkill).put<IGlobalSkill>(`/api/${controllerName}`);

const deleteSkillEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, deleteGlobalSkill).deleteByIdAndShard<IGlobalSkill>(`/api/${controllerName}`);

export const globalSkillEpics = combineEpics(
    loadGlobalSkillEpic,
    loadGlobalSkillsByCategoryEpic,
    loadGlobalSkillsByCardEpic,
    createSkillEpic,
    updateSkillEpic,
    deleteSkillEpic,
);