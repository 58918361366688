import * as React from 'react';
import { ModalCard, ModalCardBody, ModalCardFooter, ModalCardHeader } from '../modalCard';
import { Control, Field, Help } from '../form';
import { ISkill, engineerSkillLevels, SkillLevel } from 'models';
import Select from 'react-select';
import { BulmaSize } from 'enums/BulmaSize';
import NumberFormat from 'react-number-format';
import { BulmaColor } from 'enums/BulmaColor';
import { newGuid } from 'utils/id-utils';
import { Button } from '../v2/components';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';

export interface IProps {
    // global skills only used to check ref numbers not used already
    globalSkills: ISkill[];
    localSkills: ISkill[];
    skill?: ISkill;
    isNew: boolean;
    skillCategoryId: string;
    isAccountSkill?: boolean;
    accountSkills?: ISkill[];
    intl: any;
}

export interface IActions {
    onSaveLocalSkill: (skill: ISkill) => void;
    onCancel: () => void;
}

export interface IState {
    skill: ISkill;
    isShown: boolean;
    showError: boolean;
    isDescriptionValid: boolean,
    isReadyToSave: boolean
}

interface IMergeProps extends IProps, IActions { }

class EditLocalSkillClass extends React.Component<IMergeProps, IState> {
    constructor(props: IMergeProps) {
        super(props);
        this.state = {
            isShown: true,
            showError: false,
            skill: this.props.skill ? this.props.skill : this.getNewSkill(),
            isDescriptionValid: true,
            isReadyToSave: true
        };
    }

    public getNewSkill() {
        return {
            id: newGuid(),
            skillLevel: null,
            skillCategoryId: this.props.skillCategoryId,
            skillNumber: this.getNextSkillNumber(this.props.skillCategoryId),
            description: '',
            isActive: true,
            isDisabled: false
        };
    }

    public getNextSkillNumber(skillCategoryId: string): number {
        const skill = this.props.isAccountSkill ? this.props.accountSkills : this.props.localSkills.filter((skill) => !skill.isAccount || skill.isAccount !== true)
        const skillsInCategory = this.props.globalSkills
            .concat(skill)
            .filter((x) => x.skillCategoryId === skillCategoryId);
        const skillNumber: number =
            Math.max.apply(null, skillsInCategory && skillsInCategory.map((x) => x.skillNumber)) +
            1;
        return Number.isFinite(skillNumber) ? skillNumber : 1;
    }

    public isRefNumberValid(skillCategoryId: string, refNumber: number) {
        if (isNaN(refNumber)) {
            return false;
        }
        const skills = this.props.isAccountSkill ? this.props.accountSkills : this.props.localSkills.filter((skill) => !skill.isAccount || skill.isAccount !== true)
        const skillsInCategory = this.props.globalSkills
            .concat(
                skills.filter(
                    (x) =>
                        this.props.isNew ||
                        (this.props.skill && !(x.skillNumber === this.props.skill.skillNumber))
                )
            )
            .filter((x) => x.skillCategoryId === skillCategoryId);
        const refNumbersInCategory = skillsInCategory && skillsInCategory.map((x) => x.skillNumber);
        return !refNumbersInCategory.includes(refNumber);
    }

    public onShowConfirmation = () => {
        this.setState({ isShown: true });
    };

    public onClose = () => {
        this.props.onCancel();
    };

    public onSaveLocalSkill = () => {

        const updatedSkils = this.state.skill;
        if (this.props.isAccountSkill) {
            updatedSkils.isAccount = true;
        } else {
            updatedSkils.isAccount = false;
        }
        this.props.onSaveLocalSkill(updatedSkils);
    };

    public onChangeSkillLevel = (skillLevel: any) => {
        const sk = Array.isArray(skillLevel) ? skillLevel[0].value : skillLevel.value;
        const skillUpdated: ISkill = { ...this.state.skill, skillLevel: SkillLevel[sk] };
        this.setState({ skill: skillUpdated });
    };

    public onChangeSkillNumber = (text: React.ChangeEvent<HTMLInputElement>) => {
        const skillUpdated: ISkill = {
            ...this.state.skill,
            skillNumber: parseInt(text.target.defaultValue, 10),
        };
        this.setState({ skill: skillUpdated });
    };

    public onChangeDescription = (text: React.ChangeEvent<HTMLTextAreaElement>) => {
        const description = text.currentTarget.value;
        const isDescriptionValid = (description === null || description?.length === 0) ? true : description.length <= 2048;

        const skillUpdated: ISkill = {
            ...this.state.skill,
            description
        };

        const isRefValid: boolean = this.isRefNumberValid(
            this.props.skillCategoryId,
            skillUpdated.skillNumber
        );

        const isReadyToSave: boolean =
            !!skillUpdated.skillLevel &&
            isRefValid &&
            isDescriptionValid;

        this.setState({
            skill: skillUpdated,
            isDescriptionValid,
            isReadyToSave
        });
    };

    public render() {
        const isRefValid: boolean = this.isRefNumberValid(
            this.props.skillCategoryId,
            this.state.skill.skillNumber
        );
        return (
            <ModalCard modalId="textUpdate" isShown={this.state.isShown}>
                <ModalCardHeader
                    modalId="confirmation"
                    title={this.props.isNew
                        ? translate(this.props.intl, 'Engineer.EditLoalSkills.Message')
                        : translate(this.props.intl, 'Engineer.EditLoalSkills.Message2')}
                />
                <ModalCardBody modalId="confirmation">
                    {/* Skill Level */}
                    <Field
                        isHorizontal={true}
                        htmlFor="selectSkillLevel"
                        label={translate(this.props.intl, 'RiskRegisterPage.RiskFilter.Title.Level')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <Select
                                    id="selectSkillLevel"
                                    isMulti={false}
                                    classNamePrefix="multi-select"
                                    placeholder={translate(this.props.intl, 'Engineer.EditLoalSkills.Message3')}
                                    value={{
                                        value: this.state.skill.skillLevel as string,
                                        label: this.state.skill.skillLevel as string,
                                    }}
                                    onChange={this.onChangeSkillLevel}
                                    options={
                                        engineerSkillLevels &&
                                        engineerSkillLevels.map((x) => ({
                                            value: x.key,
                                            label: x.value,
                                        }))
                                    }
                                />
                            </Control>
                        </Field>
                    </Field>
                    {/* Ref */}
                    <Field
                        isHorizontal={true}
                        htmlFor="RefNumber"
                        label={translate(this.props.intl, 'Engineer.EditLoalSkills.Message4')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <NumberFormat
                                    id="RefNumber"
                                    allowEmptyFormatting={false}
                                    allowNegative={false}
                                    fixedDecimalScale={true}
                                    onChange={this.onChangeSkillNumber}
                                    className="column is-12"
                                    value={this.state.skill.skillNumber}
                                    width="100%"
                                />
                            </Control>
                            <Help isShown={!isRefValid} bulmaColor={BulmaColor.Danger}>
                                {`You need to select a unique reference number. A number above
                        ${(
                                        this.getNextSkillNumber(this.props.skillCategoryId) - 1
                                    ).toString()} will work.`}
                            </Help>
                        </Field>
                    </Field>
                    {/* Description */}
                    <Field
                        isHorizontal={true}
                        htmlFor="Description"
                        label={translate(this.props.intl, 'RiskDetailsPage.label.Description')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <textarea
                                    id="Description"
                                    className="textarea"
                                    rows={6}
                                    value={this.state.skill.description}
                                    onChange={this.onChangeDescription}
                                />
                                {this.state.isDescriptionValid}
                            </Control>
                            <Help isShown={!this.state.isDescriptionValid} bulmaColor={BulmaColor.Danger}>
                                {translate(this.props.intl, 'API.CDNA.LocalSkillDescriptionMaxCharacterWarning')}
                            </Help>
                        </Field>
                    </Field>
                </ModalCardBody>
                <ModalCardFooter>
                    <Button
                        className={'is-pulled-right'}
                        onClick={this.onSaveLocalSkill}
                        disabled={!this.state.isReadyToSave}
                    >
                        {translate(this.props.intl, 'Globals.Label.Save')}
                    </Button>
                    <Button onClick={this.onClose} buttonType="cancel">
                        {translate(this.props.intl, 'Globals.Label.Cancel')}
                    </Button>
                </ModalCardFooter>
            </ModalCard>
        );
    }
}

export const EditLocalSkill = injectIntl(EditLocalSkillClass);