import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { showFilterModal, downloadEngineers } from 'actions/actions-v2/engineer-action-v2';
// import { onRouteChange } from 'actions/app-actions';
import { MasterEngineerSkillsGridToolbar } from './master-engineer-skills-grid-toolbar';
import { IRootState } from 'routes/store';

const mapStateToProps = (state: IRootState) => {
    return {
        isExportingEngineers: state.engineersState.isExportingEngineers
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
    // onAddEngineer() {
    //     onRouteChange(`engineer/0`);
    // },
    onShowFilterModal() {
        dispatch(showFilterModal());
    },
    onDownloadEngineers() {
        dispatch(downloadEngineers());
    }
    };
};

export const MasterEngineerSkillsGridToolbarContainer = connect(mapStateToProps, mapDispatchToProps)(MasterEngineerSkillsGridToolbar);
