import { Dictionary } from 'lodash';
import { combineReducers } from 'redux';

import { ActionActions, ActionActionTypes } from '../../actions/action-actions';
import { ActionActions as ActionActionsV2 } from 'actions/actions-v2/action-action-v2';
import { IAction } from '../../models/action';
import { IActionAttachmentCollection } from '../../models/action-attachments';
import { IActionAuditHistory } from '../../models/action-audit-history';
import { getKey } from '../../utils/id-utils';
import { IBaseState } from '../base-state';

import {
    IActionFormState,
    INITIAL_STATE as ACTIONS_FORM_INITIAL_STATE,
    reducer as forms
} from './actions-form';
import {
    IActionsGridState,
    INITIAL_STATE as ACTIONS_GRID_INITIAL_STATE,
    reducer as grid
} from './actions-grid';
import { IAppAction } from 'actions/app-action';

export interface IRootActionsState extends IBaseState {
    actions: IActionsState;
}

export interface IActionsState {
    grid: IActionsGridState;
    isInitialising: boolean;
    forms: IActionFormState;

    attachments: Dictionary<{
        isLoading: boolean;
        data?: IActionAttachmentCollection[]
    }>;

    auditHistories: Dictionary<{
        page: number;
        pageSize: number;
        isLoading: boolean;
        data?: IActionAuditHistory[];
        errorMessage?: string;
    }>;

    deleteConfirmationItem: IAction;
}

const INITIAL_STATE: IActionsState = {
    grid: ACTIONS_GRID_INITIAL_STATE,
    forms: ACTIONS_FORM_INITIAL_STATE,

    isInitialising: true,
    attachments: {},
    deleteConfirmationItem: null,

    auditHistories: {},
};

const deleteConfirmationItem = (
    state: IAction = INITIAL_STATE.deleteConfirmationItem,
    action: ActionActionTypes) => {
    switch (action.type) {
        case ActionActions.DELETE_ACTION:
        case ActionActions.DELETE_ACTION_CONFIRM:
            return action.payload.item;
        case ActionActions.DELETE_ACTION_CANCEL:
        case ActionActions.DELETE_ACTION_FULFILLED:
        case ActionActions.DELETE_ACTION_CANCELLED:
            return null;
        default:
            return state;
    }
};

const attachments = (
    state: IActionsState['attachments'] = INITIAL_STATE.attachments,
    action: ActionActionTypes
) => {
    switch (action.type) {
        case ActionActions.RELOAD_ATTACHMENTS:
        return {
            ...state,
            [getKey(action.payload)]: {
                isLoading: false
            }
        };
        case ActionActions.LOAD_ATTACHMENTS:
            return {
                ...state,
                [getKey(action.payload)]: {
                    isLoading: true
                }
            };

        case ActionActions.LOAD_ATTACHMENTS_CANCELLED:
            return {
                ...state,
                [getKey(action.payload)]: {
                    ...state[getKey(action.payload)],
                    isLoading: false
                }
            };

        case ActionActions.LOAD_ATTACHMENTS_FULFILLED:
            return {
                ...state,
                [getKey(action.payload)]: {
                    data: action.payload.data,
                    isLoading: false
                }
            };

        case ActionActions.LOAD_ATTACHMENTS_REJECTED:
            return {
                ...state,
                [getKey(action.payload)]: {
                    data: null,
                    isLoading: false
                }
            };

        default:
            return state;
    }
};

const auditHistories = (
    state: IActionsState['auditHistories'] = INITIAL_STATE.auditHistories,
    action: ActionActionTypes
) => {
    switch (action.type) {
        case ActionActions.LOAD_HISTORY:
            return {
                ...state,
                [getKey(action.payload)]: {
                    isLoading: true,
                    errorMessage: null,
                    page: 0,
                    pageSize: 10,
                }
            };

        case ActionActions.LOAD_HISTORY_CANCELLED:
            return {
                ...state,
                [getKey(action.payload)]: {
                    ...state[getKey(action.payload)],
                    isLoading: false,
                    errorMessage: null,
                    page: state[getKey(action.payload)].page,
                    pageSize: state[getKey(action.payload)].pageSize,
                }
            };

        case ActionActions.LOAD_HISTORY_REJECTED:
            const key = getKey(action.payload);

            return {
                ...state,
                [key]: {
                    ...state[key],
                    isLoading: false,
                    errorMessage: action.payload.errorMessage
                }
            };

        case ActionActions.LOAD_HISTORY_FULFILLED:
            return {
                ...state,
                [getKey(action.payload)]: {
                    data: action.payload.data,
                    isLoading: false,
                    errorMessage: null,
                    page: state[getKey(action.payload)].page,
                    pageSize: state[getKey(action.payload)].pageSize,
                }
            };

        default:
            return state;
    }
};

const isInitialising = (
    state: IActionsState['isInitialising'] = INITIAL_STATE.isInitialising,
    action: IAppAction
) => {
    switch (action.type) {
        case ActionActionsV2.LOAD_ACTIONS_FULFILLED:
            return false;
        default:
            return state;
    }
};

export const actionsReducer = combineReducers<IActionsState>({
    grid,
    forms,
    isInitialising,
    deleteConfirmationItem,
    attachments,
    auditHistories,
});
