import React from 'react';
import { IToolbarIconItem, ToolbarIconType, IconToolbar } from 'components/v2/toolbar/IconToolbar';
import useTranslate from 'translations/translation-utils';
import appConfig from 'helpers/config-helper';
import { IAuditorTemplate } from 'models/iauditor';
import { useParams } from 'react-router';

interface IProps {
    multipleSites: boolean;
    siteId: string;
    isClient: boolean;
    disableExport: boolean;
    isShowHelp?: boolean;
    clearForm?: () => void;
    onClickHelp?: () => void;

    isLoading: boolean;
    templates: IAuditorTemplate[];
    siteCode: string;
    isDocumentLoading: boolean;
    loadTemplate: (siteCode: string, cermProcess: number) => void;
}

export const IauditorGridToolbarView: React.FC<IProps> = ({

    isShowHelp,
    templates,
    isLoading,
    siteCode,
    isDocumentLoading,
    onClickHelp,
    loadTemplate,
}) => {
    const config = appConfig();
    const params: any = useParams();
    const translate = useTranslate();

    const onAdd = () => {
        if (!isLoading) {
            if(templates?.length==1) {
                window.open(`${config.REACT_APP_IAUDITOR_WEB_URL}/inspection/new?templateId=${templates[0].templateId}`, '_blank');
            }
            else if(templates?.length>1){
                window.open(`${config.REACT_APP_IAUDITOR_WEB_URL}/templates/index`, '_blank');
            }
        }
    };

    React.useEffect(() => {
        if (siteCode && !isDocumentLoading) {
            loadTemplate(siteCode, Number(params.cermProcessId))
        }
    }, [siteCode, isDocumentLoading])

    const getToolbarItems = (): IToolbarIconItem[] => {
        return [
            templates?.length>0 && 
            {
                id: 'add-risk-button',
                title: translate('IAuditorComplianceChecklistPage.IAuditorComplianceChecklistToolbar.Title.AddInspection'),
                type: ToolbarIconType.add,
                onClick: onAdd,
            },
            isShowHelp && {
                id: 'show-help',
                title: translate('IAuditorComplianceChecklistPage.IAuditorComplianceChecklistToolbar.Title.Help'),
                type: ToolbarIconType.help,
                onClick: onClickHelp,
            },
        ];
    };

    return <IconToolbar items={getToolbarItems()} />;
};
