import * as React from 'react';

interface IProps {
    componentId?: string;
    title: string | React.ReactElement;
    type?: 'info' | 'warning';
    className?: string;
    intro?: string;
    bodyPadding?: boolean;
    footer?: string | React.ReactElement;
    collapsed?: boolean;
    headerElements?: string | React.ReactElement | JSX.Element;
    onCollapsed?: (collapsed: boolean) => void;
}

export const Panel: React.FC<React.PropsWithChildren<IProps>> = ({
    title,
    type = 'info',
    className,
    intro,
    bodyPadding,
    footer,
    collapsed = null,
    componentId,
    headerElements,
    onCollapsed,
    ...props
}) => {
    const [isCollapsed, setIsCollapsed] = React.useState(collapsed);

    const css = `component component-${type}${isCollapsed
        ? ` hide`
        : ''}${className
            ? ` ${className}`
            : ''}`;

    const headerId = componentId ? { id: `panel-title-${componentId}` } : null;
    const headerToggleId = componentId ? { id: `panel-toggle-${componentId}` } : null;

    const onCollapseToggle = () => {
        const altState = !isCollapsed;
        setIsCollapsed(altState);
        if (onCollapsed) {
            onCollapsed(altState);
        }
    };

    return (
        <div className={css}>
            <div className="header">
                <div className="header-content">
                    <div className={`title${intro ? ' title-with-intro' : ''}`} {...headerId}>
                        {title}
                        {intro && <small>{intro}</small>}
                    </div>

                    {headerElements && <div className="header-button">
                        {headerElements}
                    </div>}
                </div>
                {collapsed !== null && (
                    <div className="header-toggle" onClick={onCollapseToggle} {...headerToggleId} />
                )}
            </div>
            <div className={`body${bodyPadding ? ' body-padding' : ''}`}>{props.children}</div>
            {(footer || headerElements) && <div className="footer">
                {footer}
                <div className="header-button">
                    {headerElements}
                </div></div>}
        </div>
    );
};
