import { combineEpics, Epic, ofType } from 'redux-observable';
import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax';
import { catchError, debounceTime, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';

import { of } from 'rxjs';
import {
    ControlCentreActions,
    ControlCentreActionTypes,
    IFilterAndUpdateWidgetAction,
    // IInitialiseWidget,
    // IInitialiseWidgetFulfilled,
    IResetWidgetAction,
    IToggleCollapseExpandAction,
    IToggleFilterCheckedAction,
    IToggleShowSettingsAction,
    IToggleShowSitesAction,
    IToggleShowWidgetAction,
    IUpdateWidgetStateAction,
    IUpdateWidgetStateRejectedAction,
} from '../actions/control-centre-actions';
import { WidgetTypeName } from '../models/control-centre';
import {
    IRootControlCentreState,
    ISiteSelectorWidget,
    WidgetType,
} from '../reducers/control-centre';
import { navEpics } from './nav-epics';
import { getErrorActions$ } from './epic-helpers';
import { getApiHeaders } from './shared-header';

import appConfig from 'helpers/config-helper';

const config = appConfig();

const apiUrl = config.REACT_APP_BASE_API;

const getUpdateWidgetRoute = (widgetType: WidgetTypeName) => {
    switch (widgetType) {
        case 'risksRequiringAttention':
        case 'actionsRequiringAttention':
        case 'riskRegisterHealth':
        case 'cermComplianceOverview':
        case 'cermRegionOverview':
        case 'siteSelector':
            return widgetType;
        default:
            return '';
    }
};

const getWidget = (state: IRootControlCentreState, widgetId: string) =>
    state.controlCentre.widgets.find((widget) => widget.id === widgetId);

const updateWidgetStateEpic: Epic<ControlCentreActionTypes, any> = (action$) =>
    action$.pipe(
        ofType(ControlCentreActions.UPDATE_WIDGET_STATE),
        debounceTime(1000),
        switchMap((action: IUpdateWidgetStateAction) =>
            ajax
                .put(
                    `${apiUrl}/api/ControlCentreComponent/SetWidgetState/${getUpdateWidgetRoute(
                        action.payload.widget.type
                    )}`,
                    action.payload.widget,
                    getApiHeaders()
                )
                .pipe(
                    map<AjaxResponse, ControlCentreActionTypes>(() => ({
                        type: ControlCentreActions.UPDATE_WIDGET_STATE_FULFILLED,
                    })),
                    takeUntil(
                        action$.pipe(ofType(ControlCentreActions.UPDATE_WIDGET_STATE_CANCELLED))
                    ),
                    catchError<any, any>(() =>
                        of<IUpdateWidgetStateRejectedAction>({
                            type: ControlCentreActions.UPDATE_WIDGET_STATE_REJECTED,
                        })
                    )
                )
        )
    );

const updateWidgetFilterEpic: Epic<
    ControlCentreActionTypes,
    ControlCentreActionTypes,
    IRootControlCentreState
> = (action$, state$) =>
    action$.pipe(
        ofType(
            ControlCentreActions.TOGGLE_COLLAPSE_EXPAND,
            ControlCentreActions.TOGGLE_SHOW_SETTINGS,
            ControlCentreActions.TOGGLE_SHOW_WIDGET,
            ControlCentreActions.FILTER_AND_UPDATE_WIDGET,
            ControlCentreActions.RESET_WIDGET
        ),
        map(
            (
                action:
                    | IToggleCollapseExpandAction
                    | IToggleShowSettingsAction
                    | IToggleShowWidgetAction
                    | IFilterAndUpdateWidgetAction
                    | IResetWidgetAction
            ): IUpdateWidgetStateAction => {
                return {
                    type: ControlCentreActions.UPDATE_WIDGET_STATE,
                    payload: {
                        widget: getWidget(state$.value, action.payload.widgetId),
                    },
                };
            }
        )
    );

const toggleShowSiteEpic: Epic<
    ControlCentreActionTypes,
    ControlCentreActionTypes,
    IRootControlCentreState
> = (action$, state$) =>
    action$.pipe(
        ofType(ControlCentreActions.TOGGLE_SHOW_SITES),
        map(
            (action: IToggleShowSitesAction): IFilterAndUpdateWidgetAction => {
                const name: keyof ISiteSelectorWidget = 'excludedSites';
                return {
                    type: ControlCentreActions.FILTER_AND_UPDATE_WIDGET,
                    payload: {
                        widgetId: action.payload.widgetId,
                        filterName: name as keyof WidgetType,
                        value: state$.value.controlCentre.sites
                            .filter((site) => !site.isVisible)
                            .map((site) => site.id),
                    },
                };
            }
        )
    );

const toggleCheckedFilterEpic: Epic<
    ControlCentreActionTypes,
    ControlCentreActionTypes,
    IRootControlCentreState
> = (action$, state$) =>
    action$.pipe(
        ofType(ControlCentreActions.TOGGLE_FILTER_CHECKED),
        map(
            (action: IToggleFilterCheckedAction): IUpdateWidgetStateAction => {
                return {
                    type: ControlCentreActions.UPDATE_WIDGET_STATE,
                    payload: {
                        widget: getWidget(state$.value, action.payload.widgetId),
                    },
                };
            }
        )
    );

const loadControlCentre: Epic<ControlCentreActionTypes, any> = (action$) =>
    action$.pipe(
        ofType(ControlCentreActions.INITIALISE_CONTROL_CENTRE_STATE),
        map(() => `${apiUrl}/api/ControlCentreComponent`),
        mergeMap(
            // TODO: Cancel previous load first
            (url) =>
                ajax.getJSON(url, getApiHeaders()).pipe(
                    map<any, ControlCentreActionTypes>((response) => ({
                        type: ControlCentreActions.INITIALISE_CONTROL_CENTRE_STATE_FULFILLED,
                        payload: {
                            controlCentre: response,
                        },
                    })),
                    takeUntil(
                        action$.pipe(
                            ofType(ControlCentreActions.INITIALISE_CONTROL_CENTRE_STATE_CANCELLED)
                        )
                    ),
                    catchError<any, any>((error: AjaxError) =>
                        getErrorActions$('Control Centre load error')(
                            ControlCentreActions.INITIALISE_CONTROL_CENTRE_STATE_REJECTED,
                            error,
                            {
                                errorMessage: 'Unable to load control centre',
                            }
                        )
                    )
                )
        )
    );

// const loadWidget: Epic<IInitialiseWidget, any> = (action$) =>
//     action$.pipe(
//         ofType(ControlCentreActions.INITIALISE_WIDGET_STATE),
//         map(
//             (action) =>
//                 `${apiUrl}/api/ControlCentreComponent/LoadWidget/${action.payload.widgetType}`
//         ),
//         mergeMap(
//             // TODO: Cancel previous load first
//             (url) =>
//                 ajax.getJSON(url, getApiHeaders()).pipe(
//                     map<any, IInitialiseWidgetFulfilled>((response) => ({
//                         type: ControlCentreActions.INITIALISE_WIDGET_STATE_FULFILLED,
//                         payload: {
//                             ...response
//                         }
//                     })),
//                     takeUntil(
//                         action$.pipe(ofType(ControlCentreActions.INITIALISE_WIDGET_STATE_CANCELLED))
//                     ),
//                     catchError<any, any>((error: AjaxError) =>
//                         getErrorActions$('Control Centre load error')(
//                             ControlCentreActions.INITIALISE_WIDGET_STATE_REJECTED,
//                             error,
//                             {
//                                 errorMessage: 'Unable to load widget'
//                             }
//                         )
//                     )
//                 )
//         )
//     );

export const controlCentreEpics = combineEpics(
    updateWidgetStateEpic,
    updateWidgetFilterEpic,
    toggleShowSiteEpic,
    toggleCheckedFilterEpic,
    loadControlCentre,
    navEpics
);
