import { createSelector } from 'reselect';
import { IConfirmDeleteBuilding } from 'state/upsert-site-state';

const getBuildingToDelete = (state: IConfirmDeleteBuilding) => {
    return state.buildingItemToDelete;
};

export default createSelector([getBuildingToDelete], (itemForConfirmation) => ({
    itemForConfirmation,
}));
