import * as React from 'react';
import { TextAreaField } from 'components/form/fields/textarea-field';
import { TextField } from 'components/form/fields/text-field';
import { ConfirmDialog } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';
import { IGlobalSkillCategory } from 'models/competency-dna/cdna';
import { Formik, FormikErrors, FormikProps } from 'formik';
import { object, string } from 'yup';
import { Help } from 'components/form';
import { BulmaColor } from 'enums/BulmaColor';

export interface IProps {
    showDialogue: boolean;
    isLoading: boolean;
    skillCardId: string;
    skillCategory?: IGlobalSkillCategory;
    saveGlobalSkillCategory(globalSkillCategory: IGlobalSkillCategory): void;
    onCancel(): void;
}

export const GlobalSkillCategoryDialogue: React.FC<IProps> = ({
    showDialogue,
    isLoading,
    skillCardId,
    skillCategory,
    saveGlobalSkillCategory,
    onCancel,
}) => {

    const translate = useTranslate();
    let formikBag: FormikProps<Partial<IGlobalSkillCategory>>;

    const formValidationSchema = object<Partial<IGlobalSkillCategory>>().shape({
        title: string()
            .trim()
            .required(translate('CompetencyDNAProcessesAdmin.GlobalSkillCategory.Validation.Title'))
        ,
        description: string()
            .trim()
            .required(translate('CompetencyDNAProcessesAdmin.GlobalSkillCategory.Validation.Description'))
        ,
    });

    const titleTranslation = skillCategory?.id ?
        'CompetencyDNAProcessesAdmin.GlobalSkillCategory.Edit.Title' :
        'CompetencyDNAProcessesAdmin.GlobalSkillCategory.Add.Title';

    const getHelper = (formikErrors: FormikErrors<any> | any) => {
        return (
            <Help
                bulmaColor={BulmaColor.Danger}
                isShown={(formikErrors && formikErrors.length > 0)}
            >
                {formikErrors}
            </Help>
        );
    };

    const onHandleChange = (e: any) => {
        e.preventDefault();
        const { name, value } = e.target;
        formikBag.setFieldValue(name, value, true);
    }

    const getBody = (formik: FormikProps<Partial<IGlobalSkillCategory>>) => {
        return (
            <>
                <TextField
                    id="title"
                    label={translate('CompetencyDNAProcessesAdmin.GlobalSkillCategory.Label.Title')}
                    value={formik?.values?.title}
                    handleChange={onHandleChange}
                />
                {getHelper((formik.errors && formik.errors.title) ?? "")}
                <TextAreaField
                    id="description"
                    label={translate('CompetencyDNAProcessesAdmin.GlobalSkillCategory.Label.Description')}
                    value={formik?.values?.description}
                    handleChange={onHandleChange}
                />
                {getHelper((formik.errors && formik.errors.description) ?? "")}
            </>
        );
    };

    const isReadyToSave = () => {
        return formikBag?.values?.id ?
            formikBag?.isValid :
            formikBag?.dirty ?
                Object.keys(formikBag?.errors).length === 0 :
                false;
    }

    const renderModel = (formik: FormikProps<Partial<IGlobalSkillCategory>>) => {
        return (
            <ConfirmDialog
                id="globalSkillCategoryDialog"
                isVisible={showDialogue}
                onConfirm={formik.submitForm}
                onClose={onCancel}
                buttonConfirmText={translate('Globals.Label.Save')}
                confirmButtonDisabled={!isReadyToSave}
                title={translate(titleTranslation)}
                message={getBody(formik)}
                isLoading={isLoading}
            />
        );
    };

    return (
        <Formik<Partial<IGlobalSkillCategory>>
            initialValues={skillCategory}
            validationSchema={formValidationSchema}
            onSubmit={(values: IGlobalSkillCategory) => saveGlobalSkillCategory({ ...values, skillCardId })}
            render={(formik) => {
                formikBag = formik;
                return renderModel(formik);
            }}
            enableReinitialize={true}
            isInitialValid={true}
            validateOnChange={true}
            validateOnBlur={true}
        />
    );
};
