import { connect } from 'react-redux';

import { AuditHistory } from '../../../../components/auditHistory';
import actions from './actions';
import selector from './selector';

export const RiskAuditHistory = connect(
    selector,
    actions
)(AuditHistory);
