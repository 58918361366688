import * as React from 'react';
import selectors from './selectors';
import actions from './actions';
import { connect } from 'react-redux';
import { IRisk } from 'models/risk';
import { IDifference } from 'models/difference';
import EntityHistory from 'components/audit/entity-history';
import { IEntitySite } from 'models/entity-site';

interface IProps {
    id: string;
    siteId: string;
    loadHistory: (model: IEntitySite) => void;
    risk: IRisk;
    riskHistory: IDifference[][];
}

const RiskHistoryTab: React.FC<IProps> = (props) => {
    React.useEffect(() => {
        if (!props.id) {
            return;
        }
        props.loadHistory({
            id: props.id,
            siteId: props.siteId,
        });
    }, [props.id]);
    return (
        <div className="tabs-container tab-content">
            <div className="section">
                <div className="column is-12">
                    <EntityHistory entity={props.risk} entityHistory={props.riskHistory} />
                </div>
            </div>
        </div>
    );
};
export default connect(selectors, actions)(RiskHistoryTab);
