import { createSelector } from 'reselect';
import { IRootRisksState } from '../../../../reducers/risks';
import { IOwnProps } from './risk-add-linked-action-modal';
import { IRootState } from 'routes/store';
import { getFilteredLookupSitePeopleWithAllowedRoles } from 'sharedSelectors';

const getFormsState = (state: IRootRisksState) => state.risks.actionsForm;

const getIsVisible = createSelector([getFormsState], () => true);

const getIsModalVisible = (state: IRootRisksState, props: IOwnProps) => {
    const formState = getFormsState(state);
    return formState.showUpsertActionModal && formState.linkedToId === props.riskId;
};

const getUserId = (state: IRootRisksState) => {
    return state.app.userId;
};
const getLookupActionCategories = (state: IRootState) => state.app.lookupActionCategories;
const getLookupActionPriorities = (state: IRootState) => state.app.lookupActionPriorities;
const getLookupActionStatuses = (state: IRootState) => state.app.lookupActionStatus;
const getLookupSites = (state: IRootState) => state.app.lookupSites;

export default createSelector(
    getUserId,
    getIsVisible,
    getIsModalVisible,
    getLookupActionCategories,
    getLookupActionPriorities,
    getLookupActionStatuses,
    getFilteredLookupSitePeopleWithAllowedRoles,
    getLookupSites,
    (
        userId,
        isVisible,
        isModalVisible,
        lookupActionCategories,
        lookupActionPriorities,
        lookupActionStatuses,
        lookupSitePeopleRoles,
        lookupSites
    ) => ({
        userId,
        isVisible,
        isModalVisible,
        lookupActionCategories,
        lookupActionPriorities,
        lookupActionStatuses,
        lookupSitePeopleRoles,
        lookupSites,
    })
);
