import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import {
    loadAttachmentsSite,
    uploadAttachmentSite
} from 'actions/actions-v2/attachment-action-v2';
import { SiteDocumentsPage } from './site-documents-page';

const getSiteId = (state: IRootState) => state.app.siteId;
const getIsUploading = (state: IRootState) => state.attachmentState.isLoading;
const getAttachments = (state: IRootState) => state.siteState.attachments;

const mapStateToProps = createSelector(
    getSiteId,
    getIsUploading,
    getAttachments,
    (siteId, isUploading, attachments) => ({
        siteId,
        isUploading,
        attachments,
    })
);

const mapDispatchToProps = {
    loadAttachments: loadAttachmentsSite,
    uploadAttachment: uploadAttachmentSite
};

export const SiteDocumentsPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SiteDocumentsPage);
