import classNames from 'classnames';
import * as React from 'react';
import './Nav.scss';

export const NavBar: React.FC<React.AllHTMLAttributes<HTMLDivElement>> = (props) => {
    const css = classNames([
        'navbar',
        props.className
    ]);

    return (
        <nav className={css} role="navigation" aria-label="Navigation">
            <div className="container">
                {props.children}
            </div>
        </nav>
    );
};
export const EnvironmentSign: React.FC<React.AllHTMLAttributes<HTMLDivElement>> = () => {
    const urlHostIs = (input: string) =>
        window.location.host
            .toLowerCase() === (input.toLowerCase());

    const isLocal: boolean = urlHostIs('localhost');
    const isDev: boolean = urlHostIs('dcsquantum.azurewebsites.net');
    const isDev2: boolean = urlHostIs('dev2-dcsquantum.azurewebsites.net');
    const isQat: boolean = urlHostIs('qat-dcsquantum.azurewebsites.net');
    const isUat: boolean = urlHostIs('uat-quantum.cbre.com');

    return (
        <div className="navbar-home-environment">
            {isLocal && <span className="local">LOCAL</span>}
            {isDev && <span className="dev">DEV</span>}
            {isDev2 && <span className="dev">DEV2</span>}
            {isQat && <span className="qat">QAT</span>}
            {isUat && <span className="uat">UAT</span>}
        </div>
    );
};

