import * as React from 'react';

import { compact } from 'lodash';

import './Card.scss';

export interface IProps {
    className?: string;
}

const getClassName = (props: IProps) => compact(['card', props.className || null]).join(' ');

export const Card: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    return (
        <div className="card-container">
            <div className={getClassName(props)}>{props.children}</div>
        </div>
    );
};
