import { createSelector } from 'reselect';

import { IRootSiteProfileState } from '../../../reducers/site-profiles';
import { IRootState } from 'routes/store';

const getPermissions = createSelector(
    (state: IRootSiteProfileState) => state.app,
    state => state.permissions
);

const getSiteProfilesState = createSelector(
    (state: IRootSiteProfileState) => state.siteProfiles,
    state => state
);

const getExpandedItemIdArray = createSelector(
    (state: IRootSiteProfileState) => state.siteProfiles.grid.expandedItems,
    expandedItems => expandedItems
);

const getIsLoading = (state: IRootState) => state.siteFeatures.isLoading;
const getSites = (state: IRootState) => state.siteFeatures.sites;
const getResponseContinuation = (state: IRootState) => state.siteFeatures.responseContinuation;

export const getExpandedFilters = createSelector(
    (state: IRootSiteProfileState) => state,
    state => state.siteProfiles.grid.expandedFilters
);
export const getOperatingPlatforms = (state: IRootState) =>
    state.operatingPlatformState.operatingPlatforms;
    
export default createSelector(
    getIsLoading,
    getSiteProfilesState,
    getExpandedItemIdArray,
    getPermissions,
    getSites,
    getResponseContinuation,
    getExpandedFilters,
    getOperatingPlatforms,
    (
        isLoading,
        state,
        expandedItems,
        permissions,
        items,
        responseContinuation,
        expandedFilters,
        operatingPlatforms
    ) => ({
        isLoading,
        ...state,
        ...state.grid,
        expandedItems,
        permissions,
        items,
        responseContinuation,
        expandedFilters,
        operatingPlatforms,
    })
);
