import * as React from 'react';
import EntityHistory from 'components/history/entity-history';
import { IDifference } from 'models/difference';
import { IShiftHandover } from 'models/shifts/shift-handover';
import { Loader } from 'components/loader';
import useTranslate from 'translations/translation-utils';

interface IProps {
    id: string;
    siteId: string;
    isLoading: boolean;
    shiftHandover: IShiftHandover;
    shiftHandoverHistory: IDifference[][];
    loadHistory: (id: string, siteId: string) => void;
}

export const ShiftHandoverHistoryTab: React.FC<IProps> = ({
    id,
    siteId,
    isLoading,
    shiftHandover,
    shiftHandoverHistory,
    loadHistory,
}) => {
    React.useEffect(() => {
        if (!id || !siteId) {
            return;
        }

        loadHistory(id, siteId);
    }, [id, siteId]);

const translate = useTranslate();

    return (
        <div className="tabs-container tab-content">
            <div className="section">
                <div className="column is-12">
                    <div className="title is-4 section-title">
                        {translate('ShiftHandoverHistoryTab.Panel.Title')}
                    </div>
                    <Loader loading={isLoading}>
                        <EntityHistory
                            entity={shiftHandover}
                            entityHistory={shiftHandoverHistory}
                        />
                    </Loader>
                </div>
            </div>
        </div>
    );
};
