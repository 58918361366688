import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import OpportunitySiteInfoPage from './opportunity-site-info-page';
import { loadSite } from 'actions/actions-v2/site-actions-v2';
import { loadTeam, resetTeam } from 'actions/actions-v2/opportunity-support-team-actions-v2';
import { IKeyValue } from 'models/key-value';
import {
    createOpportunityAnswers,
    loadOpportunityQuestions,
    updateOpportunityAnswers,
    resetQuestionnaire,
} from 'actions/actions-v2/opportunity-questionnaire-actions';
import _ from 'lodash';

const getLoading = (state: IRootState) => state.opportunityQuestionsState.isLoading;
const getSaved = (state: IRootState) => state.opportunityQuestionsState.isSaved;
const getSiteid = (state: IRootState) => state.app.siteId;
const siteFullName = (state: IRootState) => state.siteState.site.name;
const customer = (state: IRootState) => state.siteState.site.clientName;
const getDivision = (state: IRootState) => state.siteState.site.division;
const getBusinessUnit = (state: IRootState) => state.siteState.site.businessUnit;
const getOpportunityQuestionnaire = (state: IRootState) =>
    state.opportunityQuestionsState.opportunityQuestionnaire;

const region = () => {
    const customerTypes: Array<IKeyValue<string>> = [
        { key: '0', value: 'Emea', label: 'EMEA' },
        { key: '1', value: 'APAC', label: 'APAC' },
        { key: '2', value: 'AMERS', label: 'AMERS' },
    ];
    return customerTypes;
};

const CustomerTypeValues = () => {
    const customerTypes: Array<IKeyValue<string>> = [
        { key: '0', value: 'Enterprise', label: 'Enterprise' },
        { key: '1', value: 'Enterprise -in CoLoDC', label: 'Enterprise -in CoLoDC' },
        { key: '2', value: 'CoLo operator', label: 'CoLo operator' },
        { key: '3', value: 'Hyperscale', label: 'Hyperscale' },
    ];
    return customerTypes;
};

const mapStateToProps = createSelector(
    getSiteid,
    siteFullName,
    customer,
    region,
    CustomerTypeValues,
    getLoading,
    getOpportunityQuestionnaire,
    getSaved,
    getDivision,
    getBusinessUnit,
    (
        siteId,
        siteName,
        customerName,
        regions,
        customerTypes,
        isLoading,
        opportunityQuestionnaire,
        isSaved,
        division,
        businessUnit
    ) => ({
        siteId,
        siteName,
        customerName,
        regions,
        customerTypes,
        isLoading,
        opportunityQuestionnaire,
        isSaved,
        division,
        businessUnit,
    })
);

const mapDispatchToProps = {
    loadSite,
    resetTeam,
    loadTeam,
    loadOpportunityQuestions,
    resetQuestionnaire,
    create: createOpportunityAnswers,
    update: updateOpportunityAnswers,
    reset: resetQuestionnaire,
};

export const OpportunititySiteInfoContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(OpportunitySiteInfoPage);
