import React from 'react';
import { IMasterProcessStep } from 'models/master-process-step/master-process-step';
import { IProcessStepSnapshot } from 'models/cerm/cerm-assessment-snapshot';
import { ToggleButton } from 'components/v2/components';
import { GetPercentage } from 'utils/decimal-utils';
import classNames from 'classnames';
import useTranslate from 'translations/translation-utils';

interface IProps {
    masterProcessStep: IMasterProcessStep;
    processStepLeft: IProcessStepSnapshot;
    processStepRight: IProcessStepSnapshot;
    processStepCurrent: IProcessStepSnapshot;
}

export const CermAssessmentSnapshotProcessStepRow: React.FC<IProps> = ({
    masterProcessStep,
    processStepLeft,
    processStepRight,
    processStepCurrent
}) => {
    const translate = useTranslate();
    const [expandStepOutcomes, setExpandStepOutcomes] = React.useState(false);
    const notFound = <span className="not-available">-</span>;

    const getProcessStepCompliance = (processStep: IProcessStepSnapshot) => {
        if (processStep) {
            return processStep?.applicable === false ? (
                <span className="no">{translate('IncidentDetails.Ongoing.No')}</span>
            ) : (
                GetPercentage(processStep?.compliance)
            );
        }

        // when new "masterProcessStep" is added - processStep is null/undefined
        // so display "-" for that row
        return notFound;
    };

    const getOutcomeRows = () => {

        return masterProcessStep.expectedOutcomes.map((outcome, i) => {

            return (
                <tr key={i} className="columns child-outcome">
                    <td className='column is-1 width-3'></td>
                    <td className='column is-1'>{i + 1}</td>
                    <td className='column is-4 width-40'>{outcome.title}</td>
                    <td className='column is-2'>{getOutcomeAnswer(processStepCurrent, outcome.id)}</td>
                    <td className='column is-2'>{getOutcomeAnswer(processStepLeft, outcome.id)}</td>
                    <td className='column is-2'>{getOutcomeAnswer(processStepRight, outcome.id)}</td>

                </tr>
            );
        });
    };

    const getFormattedAnswer = (answer?: boolean) => {
        switch (true) {
            case answer === true:
                return <span className="yes">{translate('IncidentDetails.Ongoing.Yes')}</span>;
            case answer === false:
                return <span className="no">{translate('IncidentDetails.Ongoing.No')}</span>;

            default:
                return <span className="not-answer">{translate('CermComparision.Title.3')}</span>;
        }
    };

    const getOutcomeAnswer = (processStepSnapshot: IProcessStepSnapshot, outcomeId: string) => {
        if (processStepSnapshot) {
            if (processStepSnapshot.applicable === false) {
                return <span className="not-applicable">N/A</span>;
            }

            const outcomeFound = processStepSnapshot.outcomes.find(
                (outcome) => outcome.id === outcomeId
            );
            if (outcomeFound) {
                return getFormattedAnswer(outcomeFound.answer);
            }
        }

        // when new "masterProcessStep" is added - processStepSnapshot is null/undefined
        // so display "-" for that row
        return notFound;
    };

    return masterProcessStep ? (
        <>
            <tr className={classNames('columns process-step', expandStepOutcomes ? 'expanded' : '')}>
                <td className='column is-1 width-3'>
                    <ToggleButton
                        buttonType="arrow"
                        isEnabled={!expandStepOutcomes}
                        onClick={() => setExpandStepOutcomes(!expandStepOutcomes)}
                    />
                </td>
                <td className='column is-1'>
                    <>

                        {masterProcessStep.processNumber}
                    </>
                </td>
                <td className='column is-4 width-40'>{masterProcessStep.title}</td>
                <td className='column is-2'>{getProcessStepCompliance(processStepCurrent)}</td>
                <td className='column is-2'>{getProcessStepCompliance(processStepLeft)}</td>
                <td className='column is-2'>{getProcessStepCompliance(processStepRight)}</td>
            </tr>
            {expandStepOutcomes ? getOutcomeRows() : null}
        </>
    ) : null;
};
