import { INavState } from '../nav';
import {
    IQuestionSetsGridState,
    reducer as grid
} from './questionSets-grid';

import { IAppState } from '../app';

export interface IRootQuestionSetState {
    questionSets: IQuestionSetsGridState;
    app: IAppState;
    nav: INavState;
}

export interface IQuestionSetFiltersState {
    filterKeywords: string[];
}

export const questionSetsReducer = grid;
