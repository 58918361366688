import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';

import { Epic, combineEpics } from 'redux-observable';
import { CommentActions } from 'actions/actions-v2/comment-action-v2';
import { IComment } from 'models/comment';

const controllerName = 'comment';

const createComments = {
    action: CommentActions.CREATE_COMMENT,
    actionFulfilled: CommentActions.CREATE_COMMENT_FULFILLED,
    actionRejected: CommentActions.CREATE_COMMENT_REJECTED
} as IActionType;

const createRiskComments = {
    action: CommentActions.CREATE_RISK_COMMENT,
    actionFulfilled: CommentActions.CREATE_RISK_COMMENT_FULFILLED,
    actionRejected: CommentActions.CREATE_RISK_COMMENT_REJECTED
} as IActionType;

const createActionComments = {
    action: CommentActions.CREATE_ACTION_COMMENT,
    actionFulfilled: CommentActions.CREATE_ACTION_COMMENT_FULFILLED,
    actionRejected: CommentActions.CREATE_ACTION_COMMENT_REJECTED
} as IActionType;

const updateComments = {
    action: CommentActions.UPDATE_COMMENT,
    actionFulfilled: CommentActions.UPDATE_COMMENT_FULFILLED,
    actionRejected: CommentActions.UPDATE_COMMENT_REJECTED
} as IActionType;

const loadComments = {
    action: CommentActions.LOAD_COMMENTS,
    actionFulfilled: CommentActions.LOAD_COMMENTS_FULFILLED,
    actionRejected: CommentActions.LOAD_COMMENTS_REJECTED
} as IActionType;

const loadRiskComments = {
    action: CommentActions.LOAD_RISK_COMMENTS,
    actionFulfilled: CommentActions.LOAD_RISK_COMMENTS_FULFILLED,
    actionRejected: CommentActions.LOAD_RISK_COMMENTS_REJECTED
} as IActionType;

const loadActionComments = {
    action: CommentActions.LOAD_ACTION_COMMENTS,
    actionFulfilled: CommentActions.LOAD_ACTION_COMMENTS_FULFILLED,
    actionRejected: CommentActions.LOAD_ACTION_COMMENTS_REJECTED
} as IActionType;

const deleteComments = {
    action: CommentActions.DELETE_COMMENT,
    actionFulfilled: CommentActions.DELETE_COMMENT_FULFILLED,
    actionRejected: CommentActions.DELETE_COMMENT_REJECTED
} as IActionType;

const createEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createComments).post<IComment>(`/api/${controllerName}`);
const createRiskCommentEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createRiskComments).post<IComment>(`/api/${controllerName}`);
const createActionCommentEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createActionComments).post<IComment>(`/api/${controllerName}`);
const updateEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateComments).put<IComment>(`/api/${controllerName}`);
const loadEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, loadComments).getBySiteIdAndQuery<IComment[]>(`/api/${controllerName}`);
const loadRiskCommentsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, loadRiskComments).getBySiteIdAndQuery<IComment[]>(`/api/${controllerName}`);
const loadActionCommentsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, loadActionComments).getBySiteIdAndQuery<IComment[]>(`/api/${controllerName}`);
const deleteEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, deleteComments).deleteByIdAndShard<string>(`/api/${controllerName}`);

export const commentEpicsV2 = combineEpics(
    createEpic,
    createRiskCommentEpic,
    createActionCommentEpic,
    updateEpic,
    loadEpic,
    loadRiskCommentsEpic,
    loadActionCommentsEpic,
    deleteEpic,
);
