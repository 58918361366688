import React from 'react';
import { IProgressBarModel } from 'components/step-progress-bar/StepProgressBar';
import { Answer, Tier } from '../Context';
import { IStateResult, State } from '../State';
import { Result } from './question-result';
import { QuestionReturnTemp } from './question-return-temp';

export class QuestionHeatExchangeA extends State {
    public result: IStateResult;
    private title: string = 'Does your site utilise \'free cooling\' ? ';
    private type: Answer;
    constructor() {
        super();
        this.handleClick = this.handleClick.bind(this);
    }
    public getResult(): IStateResult {
        return this.result;
    }

    public display(): JSX.Element {
        return (
            <div >
                <div className="is-size-3  has-text-centered">
                    {this.title}
                </div>
                <p className="has-text-centered">
                    Does your chilled water loop for IT halls and support spaces use a free cooling system?
                    e.g. Do you have plate heat exchangers, external dry coolers etc. which can reject heat
                    from the chilled water loop without requiring the chillers to run when outside conditions
                    are sufficiently cool?
                </p>
                <hr />
                <div className="formBody">
                    <div className="field">
                        <div className="control">
                            <input
                                className="radioButton"
                                type="radio"
                                name="answer"
                                id={Answer.Yes}
                                value={Answer.Yes}
                                onClick={this.handleClick}
                                checked={this.type === Answer.Yes}
                            />
                            <label htmlFor={Answer.Yes} className="radio is-size-5">

                                {Answer.Yes.toString()}
                            </label>
                            <p>
                                This site can partially or fully shut down the mechanical
                                cooling when it is sufficiently cool outside
                            </p>
                        </div>
                    </div>
                    <div className="field">
                        <div className="control">
                            <input
                                className="radioButton"
                                type="radio"
                                name="answer"
                                id={Answer.No}
                                value={Answer.No}
                                onClick={this.handleClick}
                                checked={this.type === Answer.No}
                            />
                            <label htmlFor={Answer.No} className="radio is-size-5">
                                {Answer.No.toString()}
                            </label>
                            <p>
                                This site is required to run the mechanical cooling to meet the
                                full cooling load of the chilled water loop at all times
                            </p>
                        </div>
                    </div>
                </div>
                <hr />
                {this.context.footer(this.handle.bind(this))}
            </div>);
    }
    public handle() {

        this.result = {
            title: this.title,
            value: this.type.toString()
        };

        if (this.type === Answer.No) {
            this.context.transitionTo(new QuestionReturnTemp());
        } else {
            const lastAnswer = this.context.answers[this.context.answers.length - 1];
            if (lastAnswer.value === 'No') {
                this.context.tier === Tier.HighSpec ?
                    this.context.transitionTo(new Result(5)) : this.context.transitionTo(new Result(6));
            } else {
                this.context.tier === Tier.HighSpec ?
                    this.context.transitionTo(new Result(11)) : this.context.transitionTo(new Result(12));
            }
        }
    }
    public getProgressBarModel(): IProgressBarModel[] {
        return [
            { isActive: true, name: 'Type of site' },
            { isActive: true, name: 'Question 1' },
            { isActive: true, name: 'Question 2' },
            { isActive: true, name: 'Question 3' },
            { isActive: false, name: 'Question 4' },
            { isActive: false, name: 'Summary' },
        ] as IProgressBarModel[];
    }
    private handleClick(event: any) {
        this.context.isDisable = false;
        this.type = event.currentTarget.value as Answer;
        this.context.handleRender();
    }
}
