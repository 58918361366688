import { formatDateString } from 'utils/date-utils';
import { ShiftHandoverType } from 'models/site-profile';

export const buildNewShiftHandoverUrl = (
    siteId: string,
    date: Date,
    type: ShiftHandoverType,
    facilityId: string
) => {
    let facilityLink = '';
    if (type === ShiftHandoverType.Facility) {
        facilityLink = `/${facilityId}`;
    }

    let dateLink = formatDateString(date, 'YYYY-MM-DD', null);
    dateLink = `/${dateLink}`;

    return `/ShiftHandovers/details/site/${siteId}${dateLink}${facilityLink}`;
};
