import { connect } from 'react-redux';

import { withRouter } from 'react-router';
import actions from './actions';
import selector from './selector';
import { PersonUpsertForm as View } from './view/index';

export const PersonUpsertPage = withRouter(connect(
    selector,
    actions
)(View));
