import { createSelector } from 'reselect';
import { ISiteState, IUpsertSiteRootState } from 'state/upsert-site-state';
import { getUpsertSiteState } from '../common';
import { IRootState } from 'routes/store';
import { IAttachmentModel } from '../../../../models/attachment/attachment-model';

const getAttachments = (state: IRootState) => state.siteState.attachments;
const getPermissions = (state: IUpsertSiteRootState) => state.app.permissions;
const getLookupSegments = (state: IRootState) => state.app.lookupSegment;
const getLookupServiceLines = (state: IRootState) => state.app.lookupServiceLine;
const getLookupCMMTypes = (state: IRootState) => state.app.lookupCmmType;
const getLookupTierLevel = (state: IRootState) => state.app.lookupTierLevel;
const getlookups = (state: IRootState) => state.app;
const getSiteMetaDataState = (state: IRootState) => state.siteMetaDataState;

export default createSelector(
    getUpsertSiteState,
    getAttachments,
    getPermissions,
    getLookupSegments,
    getLookupServiceLines,
    getLookupCMMTypes,
    getLookupTierLevel,
    getlookups,
    getSiteMetaDataState,
    (
        state: ISiteState,
        attachments: IAttachmentModel[],
        permissions,
        lookupSegments,
        lookupServiceLines,
        lookupCMMTypes,
        lookupTierLevels,
        app,
        siteMetaDataState
    ) => ({
        lookupSegments,
        lookupServiceLines,
        lookupCMMTypes,
        lookupTierLevels,
        clients: state.clients,
        isSubmitting: state.isSubmitting,
        attachments,
        permissions,
        lookupOperatingPlatform: app.lookupOperatingPlatform,
        lookupRiskPlatform: app.lookupRiskPlatform,
        lookupLineOfBusiness: siteMetaDataState.allLineOfBusinesses,
        divisions: siteMetaDataState.divisions,
        businessUnit: siteMetaDataState.businessUnit,
    })
);
