
import classnames from 'classnames';
import * as React from 'react';
import { MaterialIconType, MaterialIconColor, MaterialIconSize } from './material-icon-type'


export interface IProps {
    type: MaterialIconType;
    alt?: string;
    color?: MaterialIconColor;
    className?: string;
    isActive?: boolean;
    isDisabled?: boolean;
    size?: MaterialIconSize;
}

const getCss = (props: IProps) => classnames(
    'material-icons-outlined',
    `material-icon-${props.color}`,
    `md-${props.size}`,
    props.className,
    props.isActive ? 'active' : '',
    props.isDisabled ? 'disabled' : ''
);


export const MaterialIcons: React.FC<IProps> = (props) => (
    <span className={getCss(props)}>
        {props.type}
    </span>

);

MaterialIcons.defaultProps = {
    size: MaterialIconSize.default,
    color: MaterialIconColor.green
};
