import { IRisk } from 'models/risk';
import {
    IRiskTemplate,
    IActionTemplate,
    IEventTemplate,
    IMasterProcessStep
} from 'models/master-process-step/master-process-step';
import { getKeyValueLabel } from 'utils/keyvalue-utils';
import { getDateFromNow } from 'utils/date-utils';
import { IAction } from 'models/action';
import { IAppState } from 'reducers/app';
import { IProcessStepOutcome } from 'models/cerm/process-step-answer';
import { isEmpty } from 'lodash';
import { RiskCategory } from 'models/risk-category';
import { OPEN_AND_PENDING_REVIEW } from 'models/risk-statuses';
import { IOperatingPlatform } from 'models/operating-platform-model';

const getSubCategoryLabel = (
    categories: RiskCategory[],
    categoryId: string,
    subCategoryId: string,
) =>
    categories
        .find((x) => x.key === categoryId)
        .subCategory
        .find((x) => x.key === subCategoryId)
        .value;

export const buildRiskWithTemplate = (
    template: IRiskTemplate,
    siteId: string,
    lookups: IAppState,
    allOperatingPlatforms: IOperatingPlatform[],
): IRisk =>
(
    {
        isActive: true,
        title: template.title,
        description: template.description,
        siteId,
        likelihood: getKeyValueLabel(lookups.lookupRiskLikelihoods, template.riskLikelihoodId),
        likelihoodId: template.riskLikelihoodId,
        impact: getKeyValueLabel(lookups.lookupRiskImpacts, template.riskImpactId),
        impactId: template.riskImpactId,
        riskCategory: getKeyValueLabel(lookups.lookupRiskCategories, template.riskCategoryId),
        riskCategoryId: template.riskCategoryId,
        riskSubCategory: getSubCategoryLabel(lookups.lookupRiskCategories, template.riskCategoryId, template.riskSubCategoryId),
        riskSubCategoryId: template.riskSubCategoryId,
        riskStatus: getKeyValueLabel(lookups.lookupRiskStatus, OPEN_AND_PENDING_REVIEW),
        riskStatusId: OPEN_AND_PENDING_REVIEW,
        reviewDate: getDateFromNow(template.reviewDays, 'day'),
        origin: `${getTitlePrefix(siteId, lookups.lookupSites, allOperatingPlatforms)} assessment`
    } as IRisk);

export const buildActionWithTemplate = (
    outcomeId: string,
    template: IActionTemplate,
    siteId: string,
    lookups: IAppState,
    allOperatingPlatforms: IOperatingPlatform[],
) => {
    return {
        outcomeId,
        isActive: true,
        title: template.title,
        description: template.description,
        siteId,
        category: getKeyValueLabel(lookups.lookupActionCategories, template.categoryId),
        categoryId: template.categoryId,
        priority: getKeyValueLabel(lookups.lookupActionPriorities, template.priority),
        priorityId: template.priority,
        status: getKeyValueLabel(lookups.lookupActionStatus, 'Open'),
        statusId: 'Open',
        dueDate: getDateFromNow(Number(template.dueDateValue), template.dueDateUnit),
        reminderDate: getDateFromNow(1, 'month'),
        origin: `${getTitlePrefix(siteId, lookups.lookupSites, allOperatingPlatforms)} assessment`
    } as IAction;
};

export const buildEventWithTemplate = (
    outcomeId: string,
    template: IEventTemplate,
    masterProcessStep: IMasterProcessStep,
    processStepOutcomes: IProcessStepOutcome[]
) => {
    let hasExistingEvents = null;
    const found = processStepOutcomes.find(
        (outcome) => outcome.id === outcomeId && !isEmpty(outcome.eventIds)
    );
    if (found) {
        hasExistingEvents = true;
    }
    return {
        title:
            template.title ||
            `CERM ${masterProcessStep.processNumber} expects the following outcome ${masterProcessStep.title}`,
        startDate: getDateFromNow(0, 'day'),
        recurrenceUnit: template.recurrenceUnit || 'day',
        recurrenceValue: template.recurrenceValue || getRecurrenceValue(masterProcessStep.priority),
        numberOfOccurrences: 1,
        outcomeId,
        hasExistingEvents
    } as IEventTemplate;
};

const getRecurrenceValue = (priority: string) => {
    switch (priority) {
        case '1':
            return 60;
        case '3':
            return 30;
        case '9':
            return 14;
        case '27':
            return 7;
    }

    return 1;
};

const getTitlePrefix = (siteId: string, lookupSite: any[], allOperatingPlatforms: IOperatingPlatform[]) => {
    let site = lookupSite.find(x => x.key === siteId);
    const selectedOperatingPlatform =
        site && allOperatingPlatforms &&
        allOperatingPlatforms.find(
            x => x.value === site.operatingPlatform
        );
    return selectedOperatingPlatform?.shortForm.toUpperCase();
}
