import { IAppAction } from 'actions/app-action';
import { combineReducers } from 'redux';
import { ISkillCard } from 'models/competency-dna/skill-card';
import { SkillCardActions } from 'actions/skill-card-actions';
import { ISiteSkillCard } from 'models/competency-dna/site-skill-card';

export interface ISkillCardState {
    skillCards: ISkillCard[],
    siteSkillCards: ISiteSkillCard[],
    isLoading: boolean;
}

const INITIAL_STATE: ISkillCardState = {
    skillCards: [],
    siteSkillCards: [],
    isLoading: false
};

const skillCards = (
    state: ISkillCardState['skillCards'] = INITIAL_STATE.skillCards,
    action: IAppAction
) => {
    switch (action.type) {
        case SkillCardActions.LOAD_SKILL_CARDS_FULFILLED:
            return action.payload;
        case SkillCardActions.LOAD_SKILL_CARD_FULFILLED:
            return action.payload;
        case SkillCardActions.CREATE_SKILL_CARD_FULFILLED:
            return action.payload
                ? [...state, action.payload]
                : state;
        case SkillCardActions.UPDATE_SKILL_CARD_FULFILLED:
            const index = state.findIndex((item) => item.id === action.payload?.id);
            if (index !== -1) {
                return [
                    ...state.slice(0, index),
                    action.payload,
                    ...state.slice(index + 1),
                ];
            }
            return state;
        case SkillCardActions.DELETE_SKILL_CARD_FULFILLED:
            return state.filter((item) => item.id !== action.payload.id);
        default:
            return state;
    }
};

const siteSkillCards = (
    state: ISkillCardState['siteSkillCards'] = INITIAL_STATE.siteSkillCards,
    action: IAppAction
) => {
    switch (action.type) {
        case SkillCardActions.LOAD_SITE_SKILL_CARDS_FULFILLED:
            return action.payload;
        default:
            return state;
    }
};

const isLoading = (
    state: ISkillCardState['isLoading'] = INITIAL_STATE.isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case SkillCardActions.LOAD_SKILL_CARDS:
        case SkillCardActions.LOAD_SKILL_CARD:
        case SkillCardActions.LOAD_SITE_SKILL_CARDS:
        case SkillCardActions.CREATE_SKILL_CARD:
        case SkillCardActions.UPDATE_SKILL_CARD:
        case SkillCardActions.DELETE_SKILL_CARD:
            return true;
        case SkillCardActions.LOAD_SKILL_CARDS_FULFILLED:
        case SkillCardActions.LOAD_SKILL_CARDS_REJECTED:
        case SkillCardActions.LOAD_SKILL_CARD_FULFILLED:
        case SkillCardActions.LOAD_SKILL_CARD_REJECTED:
        case SkillCardActions.LOAD_SITE_SKILL_CARDS_FULFILLED:
        case SkillCardActions.LOAD_SITE_SKILL_CARDS_REJECTED:
        case SkillCardActions.CREATE_SKILL_CARD_FULFILLED:
        case SkillCardActions.CREATE_SKILL_CARD_REJECTED:
        case SkillCardActions.UPDATE_SKILL_CARD_FULFILLED:
        case SkillCardActions.UPDATE_SKILL_CARD_REJECTED:
        case SkillCardActions.DELETE_SKILL_CARD_FULFILLED:
        case SkillCardActions.DELETE_SKILL_CARD_REJECTED:
            return false;
        default:
            return state;
    }
};

export const skillCardReducer = combineReducers<ISkillCardState>({
    skillCards,
    siteSkillCards,
    isLoading
});