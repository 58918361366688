import * as React from 'react';
import { Control, Field } from '..';
import './read-only-field.scss';

interface IProps {
    id?: string;
    label: string;
    value?: string | JSX.Element | number;
}

export const ReadOnlyField: React.FC<React.PropsWithChildren<IProps>> = props => {
    return (
        <Field id={props.id}>
            <label className="label">{props.label}</label>
            <Control className="read-only-field">
                {props.children ? (
                    props.children
                ) : props.value ? (
                    props.value
                ) : (
                    <span className="na">N/A</span>
                )}
            </Control>
        </Field>
    );
};
