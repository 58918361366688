import { createSelector } from 'reselect';
import { IRootActionsState } from '../../../../reducers/actions';

const getCanCreateAction = createSelector(
    (state: IRootActionsState) => state.app,
    (state) => state.canCreateAction
);

const getUserId = createSelector(
    (state: IRootActionsState) => state.app,
    (appState) => appState.userId
);

const getSiteId = (state: IRootActionsState) => state.app.siteId;

export default createSelector(
    getCanCreateAction,
    getSiteId,
    getUserId,
    (canCreateAction, siteId, userId) => ({
        canCreateAction,
        siteId,
        userId
    })
);
