import * as React from 'react';
import { FormikProps } from 'formik';
import { IPersonLookup } from 'models/person-lookup';
import { object, string } from 'yup';
import { TextField } from 'components/form/fields/text-field';
import { TextAreaField } from 'components/form/fields/textarea-field';
import { DatePickerField } from 'components/form/fields/date-picker-field';
import { PopoutSelectField } from 'components/form/fields/popout-select-field';
import { IMockDrillReport, IMockDrillReportsSummary } from 'models/mock-drills';
import { ISelectOption } from 'models/select-option';
import { Loader } from 'components/loader';
import { scenarioTypeLabel, scenarioTypePlaceholder } from 'routes/mock-drills/common';
import useTranslate from 'translations/translation-utils';

interface IProps {
    siteId: string;
    isLoading: boolean;
    formikBag: FormikProps<IMockDrillReport>;
    lookupSitePeopleRoles: IPersonLookup[];
    mockDrillReportsSummary: IMockDrillReportsSummary;
    loadPersonsLookup: (siteId: string) => void;
    scenarioType: string;
}

export const mockDrillReportDetailsFormValidationSchema = object<Partial<IMockDrillReport>>().shape(
    {
        drillLeaderPersonId: string().required('Drill leader is required'),
        dateOfDrill: string().required('Date of drill is required'),
        eopTitle: string().trim().required('EOP/s title is required'),
    }
);

export const MockDrillReportDetailsTab: React.FC<IProps> = ({
    siteId,
    isLoading,
    formikBag,
    lookupSitePeopleRoles,
    mockDrillReportsSummary,
    loadPersonsLookup,
    scenarioType,
}) => {
    const translate = useTranslate();
    const [peopleRoles, setPeopleRoles] = React.useState([] as ISelectOption[]);

    React.useEffect(() => {
         loadPersonsLookup(siteId);
    }, [siteId]);

    React.useEffect(() => {
        if (lookupSitePeopleRoles) {
            const options: ISelectOption[] = lookupSitePeopleRoles.map((lookup) => {
                const roles = lookup.roleNames ? lookup.roleNames.join(', ') : 'N/A';
                if (roles) {
                    const label = `${lookup.label} (${roles})`;
                    return { label, value: lookup.id };
                } else {
                    return { label: lookup.label, value: lookup.id };
                }
            });

            setPeopleRoles(options);
        }
    }, [lookupSitePeopleRoles]);

    const handleDrillLeaderChange = (value: string) => {
        const person = lookupSitePeopleRoles.find((p) => p.id === value);
        if (person) {
            formikBag.setFieldValue('drillLeader', person.label);
        }

        formikBag.setFieldValue('drillLeaderPersonId', value);
        formikBag.setTouched(formikBag.touched);
    };

    const handleDateChange = (newDate: Date) => {
        formikBag.setFieldValue('dateOfDrill', newDate);
    };

    const drillDescription = mockDrillReportsSummary && (
        <p className="page-description">{mockDrillReportsSummary.description}</p>
    );

    return (
        <div className="tabs-container tab-content">
            <Loader loading={isLoading}>
                {mockDrillReportsSummary && mockDrillReportsSummary.description && (
                    <div className="columns">
                        <div className="column is-12 mock-drill-info">{drillDescription}</div>
                    </div>
                )}
                <div className="columns">
                    <div className="column is-4">
                        <PopoutSelectField
                            id="drillLeaderPersonId"
                            label={translate('MockDrills.Lable.1')+'*'}
                            value={formikBag.values.drillLeaderPersonId}
                            error={formikBag.errors.drillLeaderPersonId}
                            handleChange={handleDrillLeaderChange}
                            handleBlur={formikBag.handleBlur}
                            options={peopleRoles}
                        />
                    </div>
                    <div className="column is-4">
                        <DatePickerField
                            id="dateOfDrill"
                            label={translate('SchedulerWeek.Button.Calendar.buttonText')+'*'}
                            value={formikBag.values.dateOfDrill}
                            error={formikBag.errors.dateOfDrill}
                            handleChange={handleDateChange}
                            handleBlur={formikBag.handleBlur}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <TextField
                            id="eopTitle"
                            label={`${scenarioTypeLabel(scenarioType)}`}
                            placeholder={`${scenarioTypePlaceholder(scenarioType)}`}
                            value={formikBag.values.eopTitle}
                            error={formikBag.errors.eopTitle}
                            handleChange={formikBag.handleChange}
                            setVal={formikBag.setFieldValue}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <TextAreaField
                            id="actionsDescription"
                            label={translate('MockDrills.Lable.2')}
                            placeholder="Enter if required…"
                            error={formikBag.errors.actionsDescription}
                            value={formikBag.values.actionsDescription}
                            handleChange={formikBag.handleChange}
                            setVal={formikBag.setFieldValue}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <TextAreaField
                            id="lessonsLearnedDescription"
                            label={translate('MockDrills.Lable.3')}
                            placeholder={translate('MockDrills.Lable.4')+'...'}
                            error={formikBag.errors.lessonsLearnedDescription}
                            value={formikBag.values.lessonsLearnedDescription}
                            handleChange={formikBag.handleChange}
                            setVal={formikBag.setFieldValue}
                        />
                    </div>
                </div>
            </Loader>
        </div>
    );
};
