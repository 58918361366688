import * as React from 'react';
import { connect } from 'react-redux';
import { history } from 'routes/App';
import { formatDateString } from 'utils/date-utils';
import { IDashboardComponentProps } from 'models/dashboard/IDashboardComponentProps';
import { IDashboardIncidentComponent } from 'models/dashboard/IDashboardIncidentComponent';
import { Table, TableNoItem } from 'components/v2/components';
import { Loader } from 'components/loader';
import DashboardComponent, { loadComponentEffect } from '../DashboardComponent';
import selectors from './selectors';
import actions from './actions';
import { NavLink } from 'react-router-dom';
import useTranslate from 'translations/translation-utils';

const DashboardIncidentComponent: React.FC<IDashboardComponentProps<
    IDashboardIncidentComponent
>> = ({ siteId, isLoading, component, hasPermission, loadComponent, className }) => {

    const translate = useTranslate();

    const currentSiteId = React.useRef<string>(null);

    loadComponentEffect(hasPermission, currentSiteId, siteId, loadComponent);

    const navigateToPage = (id: string) => {
        history.push(`Incident/${id}/details`);
    };

    const getClassNameBySeverity = (severity: string) => {
        switch (severity) {
            case 'S1':
                return 'high';
            case 'S2':
                return 'medium';
            default:
                return '';
        }
    };

    const getClassNameByStatus = (status: string) => {
        switch (status) {
            case 'Open':
                return 'high';
            case 'Pending':
                return 'medium';
            default:
                return '';
        }
    };

    const geBoolDisplayValue = (trueFalse: boolean) => {
        switch (trueFalse) {
            case true:
                return 'Yes';
            default:
                return 'No';
        }
    };

    return (
        <DashboardComponent
            title={translate('Dashboard.Incidents.Title')}
            intro={translate('Dashboard.CalandarEvents.Intro')}
            className={`column-incident ${className}`}
            component={component}
            hasPermission={hasPermission}
            headerButton={
                <div className="has-text-centered">
                    <NavLink to="/Incidents" className="button is-primary is-fullwidth is-medium">
                        {translate('Dashboard.Incidents.View')}
                    </NavLink>
                </div>
            }
        >
            <Loader loading={isLoading}>
                <Table>
                    <thead>
                        <tr>
                            <th className="narrow">{translate('IncidentDetails.Label.Severity')}</th>
                            <th className="narrow">{translate('RiskActionTab.Label.Status')}</th>
                            <th>{translate('RiskRegisterPage.RiskRegisterTable.Title.Title')}</th>
                            <th className="narrow no-wrap">{translate('Dashboard.Incidents.BusinessImpacted')}</th>
                            <th className="narrow no-wrap">{translate('Dashboard.Incidents.DateOfIncident')}</th>
                        </tr> 
                    </thead>
                    <tbody>
                        {!component || !component.items || component.items.length < 1 ? (
                            <TableNoItem colSpan={6} />
                        ) : (
                            component.items.map((item) => {
                                return (
                                    <tr key={item.id} onClick={() => navigateToPage(item.id)}>
                                        <td>
                                            <mark className={getClassNameBySeverity(item.severity)}>
                                                {item.severity}
                                            </mark>
                                        </td>
                                        <td className="is-narrow">
                                            <mark className={getClassNameByStatus(item.status)}>
                                                {item.status}
                                            </mark>
                                        </td>
                                        <td>{item.title}</td>
                                        <td>{geBoolDisplayValue(item.isBusinessImpacted)}</td>
                                        <td>{formatDateString(item.incidentDate)}</td>
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                </Table>
            </Loader>
        </DashboardComponent>
    );
};

export default connect(selectors, actions)(DashboardIncidentComponent);
