import { IAppAction } from '../app-action';
import { ILogUploadModel } from 'models/logs-upload';

export enum LogsUploadActions {
    UPLOAD = '@@LogUpload/LOAD',
    UPLOAD_FULFILLED = '@@LogUpload/LOAD_FULFILLED',
    UPLOAD_REJECTED = '@@LogUpload/LOAD_REJECTED',
    DOWNLOAD_TEMPLATE = '@@LogUpload/DOWNLOAD_TEMPLATE',
    DOWNLOAD_TEMPLATE_FULFILLED = '@@LogUpload/DOWNLOAD_TEMPLATE_FULFILLED',
    DOWNLOAD_TEMPLATE_REJECTED = '@@LogUpload/DOWNLOAD_TEMPLATE_REJECTED',

    RESET_LOGS = '@@LogUpload/RESET_LOGS',
}

export const downloadTemplate = (logType: string, templateType: string): IAppAction => ({
    type: LogsUploadActions.DOWNLOAD_TEMPLATE,
    payload: {logType, templateType}
});

export const logsUpload = (model: ILogUploadModel): IAppAction => ({
    type: LogsUploadActions.UPLOAD,
    payload: model
});

export const logsUploadFulfilled = (model: ILogUploadModel): IAppAction => ({
    type: LogsUploadActions.UPLOAD_FULFILLED,
    payload: model
});

export const logsUploadRejected = (model: ILogUploadModel): IAppAction => ({
    type: LogsUploadActions.UPLOAD_REJECTED,
    payload: model
});
export const resetLogs = (): IAppAction => ({
    type: LogsUploadActions.RESET_LOGS
});
