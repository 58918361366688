import { IAppAction } from '../app-action';
import { IMockDrillsFilters, ICreateMockDrill, IUpdateMockDrill } from 'models/mock-drills';

export enum MockDrillsActions {
    LOAD_LIST = '@@MockDrills/LOAD_LIST',
    LOAD_LIST_FULFILLED = '@@MockDrills/LOAD_LIST_FULFILLED',
    LOAD_LIST_REJECTED = '@@MockDrills/LOAD_LIST_REJECTED',

    LOAD = '@@MockDrills/LOAD',
    LOAD_FULFILLED = '@@MockDrills/LOAD_FULFILLED',
    LOAD_REJECTED = '@@MockDrills/LOAD_REJECTED',

    CREATE = '@@MockDrills/CREATE',
    CREATE_FULFILLED = '@@MockDrills/CREATE_FULFILLED',
    CREATE_REJECTED = '@@MockDrills/CREATE_REJECTED',

    UPDATE = '@@MockDrills/UPDATE',
    UPDATE_FULFILLED = '@@MockDrills/UPDATE_FULFILLED',
    UPDATE_REJECTED = '@@MockDrills/UPDATE_REJECTED',

    DELETE = '@@MockDrills/DELETE',
    DELETE_FULFILLED = '@@MockDrills/DELETE_FULFILLED',
    DELETE_REJECTED = '@@MockDrills/DELETE_REJECTED',

    EXPORT = '@@MockDrills/EXPORT',
    EXPORT_FULFILLED = '@@MockDrills/EXPORT_FULFILLED',
    EXPORT_REJECTED = '@@MockDrills/EXPORT_REJECTED',

    CLEANUP = '@@MockDrills/CLEANUP',
}

export const loadMockDrills = (filters: IMockDrillsFilters): IAppAction => ({
    type: MockDrillsActions.LOAD_LIST,
    payload: filters,
});

export const loadMockDrill = (id: string, siteId: string): IAppAction => ({
    type: MockDrillsActions.LOAD,
    payload: { id, shard: siteId, siteId },
});

export const createMockDrill = (model: ICreateMockDrill, reloadUrl: string): IAppAction => ({
    type: MockDrillsActions.CREATE,
    payload: { ...model, reloadUrl },
});

export const updateMockDrill = (model: IUpdateMockDrill, reloadUrl: string): IAppAction => ({
    type: MockDrillsActions.UPDATE,
    payload: { ...model, reloadUrl },
});

export const deleteMockDrill = (id: string, siteId: string, redirectUrl: string): IAppAction => ({
    type: MockDrillsActions.DELETE,
    payload: {
        id,
        siteId,
        reloadUrl: redirectUrl,
    },
});

export const exportMockDrills = (siteId: string): IAppAction => ({
    type: MockDrillsActions.EXPORT,
    payload: {
        siteId,
    },
});

export const cleanupMockDrill = (): IAppAction => ({
    type: MockDrillsActions.CLEANUP,
});
