import * as React from 'react';
import { ArticlePanel } from 'components/panel';
import { Form, FormikProps } from 'formik';
import { Field, Control } from 'components/form';
import { BulmaSize } from 'enums/BulmaSize';
import Select, { Options } from 'react-select';
import './users-form.scss';
import { IPersonLookup } from 'models/person-lookup';
import { IKeyValue } from 'models/key-value';
import _ from 'lodash';
import { RouteComponentProps } from 'react-router';
import { IUpsertSitePageRouteParams } from '../upsert-site-page';
import { checkPermission } from 'utils/permission-utils';
import { ISiteProfile } from 'models/site-profile';
import { Button } from 'components/v2/components';
import { Privilege } from 'enums/Privilege';
import { IPersonToSite } from 'models/person';
import useTranslate from 'translations/translation-utils';

interface IProps extends RouteComponentProps<IUpsertSitePageRouteParams> {
    saveSiteUsers: (users: IPersonToSite[], siteId: string) => void;
    loadPersonsLookup: (siteId: string) => void;
    users: IPersonLookup[];
    siteId: string;
    permissions: Array<IKeyValue<string>>;
    formikBag: FormikProps<Partial<ISiteProfile>>;
    relationships: IPersonToSite[];
    loadRelationships: (siteId: string) => void;
}

interface ISiteRelationshipTypes {
    approver: string;
    siteLead: string;
    client: string;
    contractSupport: string;
    siteQuantumChampion: string;
    siteReliabilityChampion: string;
    clientProxy: string;
    id: string;
    siteId: string;
}

export const SiteUsers: React.FC<IProps> = props => {
    const [users, setUsers] = React.useState<IPersonLookup[]>([]);
    const [relationships, setRelationships] = React.useState<IPersonToSite[]>([]);

    React.useEffect(() => {
        props.loadPersonsLookup(props.siteId);
        props.loadRelationships(props.siteId);
    }, []);

    React.useEffect(() => {
        if (props.users) {
            setUsers(props.users);
        }
    }, [props.users]);

    React.useEffect(() => {
        if (props.relationships) {
            setRelationships(props.relationships);
        }
    }, [props.relationships]);

    const updateUsers = (
        key: keyof ISiteRelationshipTypes,
        values: Options<{
            key: string;
            value: string;
            label: string;
        }>
    ) => {
        const stateRelationships: IPersonToSite[] = [].concat(relationships);
        const currentState = stateRelationships.map(relationship => {
            if (
                relationship[key] === true &&
                (
                    !values ||
                    values?.findIndex(x => x.key === relationship.id) === -1
                )
            ) {
                return {
                    ...relationship,
                    [key]: false,
                };
            } else if (values?.findIndex(x => x.key === relationship.id) > -1) {
                return {
                    ...relationship,
                    [key]: true,
                };
            }

            return relationship;
        });
        setRelationships([].concat(currentState));
    };

    const onSaveUpdateUser = () => {
        const stateRelationships: IPersonToSite[] = [].concat(relationships);
        const propRelationships: IPersonToSite[] = [].concat(props.relationships);
        const relationshipChanges: IPersonToSite[] = [];
        stateRelationships.forEach((relationship: IPersonToSite) => {
            const originalRelationship = propRelationships.find(c => c.id === relationship.id);
            if (originalRelationship !== relationship) {
                relationshipChanges.push({
                    ...relationship,
                    siteId: relationship.siteId,
                    siteLead: relationship.siteLead,
                    approver: relationship.approver,
                    client: relationship.client,
                    contractSupport: relationship.contractSupport,
                    siteQuantumChampion: relationship.siteQuantumChampion,
                    siteReliabilityChampion: relationship.siteReliabilityChampion,
                    clientProxy: relationship.clientProxy,
                });
            }
        });

        props.saveSiteUsers(relationshipChanges, props.siteId);
        props.formikBag.submitForm();
    };
    const translate = useTranslate();
    const renderTable = (people: IPersonLookup[]): React.ReactNode => {
        return (
            <table className="table is-fullwidth is-hoverable">
                <thead>
                    <tr>
                        <th>{translate('GainAccess.Labels.FirstName')}</th>
                        <th>{translate('GainAccess.Labels.LastName')}</th>
                        <th>{translate('RiskApproversTab.Title.Email')}</th>
                        <th>{translate('GainAccess.Labels.Roles')}</th>
                    </tr>
                </thead>
                <tbody>
                    {people.map(person => {
                        return (
                            <tr key={person.id}>
                                <td>{person.firstName}</td>
                                <td>{person.lastName}</td>
                                <td>{person.email}</td>
                                <td>{person.roleNames.join(', ')}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    const usersForSite = users?.filter(u => u.siteIds.some(s => s === props.siteId)) ?? [];
    const clients = users?.filter(u => relationships.some(x => x.id === u.id && x.client)) ?? [];
    const siteLeads =
        users?.filter(u => relationships.some(x => x.id === u.id && x.siteLead)) ?? [];
    const siteQuantumChampions =
        users?.filter(u => relationships.some(x => x.id === u.id && x.siteQuantumChampion)) ?? [];
    const siteReliabilityChampions =
        users?.filter(u => relationships.some(x => x.id === u.id && x.siteReliabilityChampion)) ??
        [];
    const clientProxy =
        users?.filter(u => relationships.some(x => x.id === u.id && x.clientProxy)) ?? [];
    const contractSupports =
        users?.filter(u => relationships.some(x => x.id === u.id && x.contractSupport)) ?? [];
    const approvers =
        users?.filter(u => relationships.some(x => x.id === u.id && x.approver)) ?? [];
    const isEnabled = checkPermission(Privilege.SiteRolesEdit, props.permissions);

    return (
        <ArticlePanel className="column">
            <header>
                <h1 className="title is-4">{translate('UpsertSite.Users.SiteUsers.Panel.Text')}</h1>
                <small>{translate('UpsertSite.Users.SiteUsers.Panel.Text2')}</small>
            </header>

            <Form className="UsersForm">
                <Field
                    className="is-medium"
                    isHorizontal={true}
                    htmlFor=""
                    label={translate('Globals.SiteRoles.SiteLead')}
                    labelSize={BulmaSize.Medium}
                    infoText={translate('UpsertSite.Users.SiteUsers.Panel.Form')}
                >
                    <Field>
                        <Control>
                            <Select
                                isDisabled={!isEnabled}
                                id="contract-supports-multiselector"
                                options={usersForSite.map(x => ({
                                    key: x.id,
                                    value: x.id,
                                    label: x.label,
                                }))}
                                isMulti={true}
                                onChange={value => updateUsers('siteLead', value)}
                                value={siteLeads.map(x => ({
                                    key: x.id,
                                    value: x.id,
                                    label: x.label,
                                }))}
                            />
                        </Control>
                    </Field>
                </Field>

                <Field
                    className="is-medium"
                    isHorizontal={true}
                    htmlFor=""
                    label={translate('UpsertSite.Users.SiteUsers.Field.Label')}
                    labelSize={BulmaSize.Medium}
                >
                    <Field>
                        <Control>
                            <Select
                                isDisabled={!isEnabled}
                                id="Site-Quantum-champion-multiselector"
                                options={usersForSite.map(x => ({
                                    key: x.id,
                                    value: x.id,
                                    label: x.label,
                                }))}
                                isMulti={true}
                                onChange={value => updateUsers('siteQuantumChampion', value)}
                                value={siteQuantumChampions.map(x => ({
                                    key: x.id,
                                    value: x.id,
                                    label: x.label,
                                }))}
                            />
                        </Control>
                    </Field>
                </Field>

                <Field
                    className="is-medium"
                    isHorizontal={true}
                    htmlFor=""
                    label={translate('UpsertSite.Users.SiteUsers.Field.Label2')}
                    labelSize={BulmaSize.Medium}
                >
                    <Field>
                        <Control>
                            <Select
                                isDisabled={!isEnabled}
                                id="site-reliability-champion-multiselector"
                                options={usersForSite.map(x => ({
                                    key: x.id,
                                    value: x.id,
                                    label: x.label,
                                }))}
                                isMulti={true}
                                onChange={value => updateUsers('siteReliabilityChampion', value)}
                                value={siteReliabilityChampions.map(x => ({
                                    key: x.id,
                                    value: x.id,
                                    label: x.label,
                                }))}
                            />
                        </Control>
                    </Field>
                </Field>

                <Field
                    className="is-medium"
                    isHorizontal={true}
                    htmlFor=""
                    label={translate('UpsertSite.Users.SiteUsers.Field.Label6')}
                    labelSize={BulmaSize.Medium}
                    infoText={translate('UpsertSite.Users.SiteUsers.Field.Label3')}
                >
                    <Field>
                        <Control>
                            <Select
                                isDisabled={!isEnabled}
                                id="contract-supports-multiselector"
                                options={usersForSite.map(x => ({
                                    key: x.id,
                                    value: x.id,
                                    label: x.label,
                                }))}
                                isMulti={true}
                                onChange={value => updateUsers('contractSupport', value)}
                                value={contractSupports.map(x => ({
                                    key: x.id,
                                    value: x.id,
                                    label: x.label,
                                }))}
                            />
                        </Control>
                    </Field>
                </Field>

                <Field
                    className="is-medium"
                    isHorizontal={true}
                    htmlFor=""
                    label={translate('UpsertSite.Users.SiteUsers.Field.Label4')}
                    labelSize={BulmaSize.Medium}
                >
                    <Field>
                        <Control>
                            <Select
                                isDisabled={!isEnabled}
                                id="approvers-multiselector"
                                options={usersForSite.map(x => ({
                                    key: x.id,
                                    value: x.id,
                                    label: x.label,
                                }))}
                                isMulti={true}
                                onChange={value => updateUsers('approver', value)}
                                value={approvers.map(x => ({
                                    key: x.id,
                                    value: x.id,
                                    label: x.label,
                                }))}
                            />
                        </Control>
                    </Field>
                </Field>

                <Field
                    className="is-medium"
                    isHorizontal={true}
                    htmlFor=""
                    label={translate('Globals.Category.dropdown.Client')}
                    labelSize={BulmaSize.Medium}
                >
                    <Field>
                        <Control>
                            <Select
                                isDisabled={!isEnabled}
                                id="clients-multiselector"
                                options={usersForSite.map(x => ({
                                    key: x.id,
                                    value: x.id,
                                    label: x.label,
                                }))}
                                isMulti={true}
                                onChange={value => updateUsers('client', value)}
                                value={clients.map(x => ({
                                    key: x.id,
                                    value: x.id,
                                    label: x.label,
                                }))}
                            />
                        </Control>
                    </Field>
                </Field>
                {checkPermission(Privilege.SitesAddClientProxy, props.permissions) && (
                    <Field
                        className="is-medium"
                        isHorizontal={true}
                        htmlFor=""
                        label={translate('UpsertSite.Users.SiteUsers.Field.Label5')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <Select
                                    isDisabled={!isEnabled}
                                    id="Client-proxy-multiselector"
                                    options={usersForSite.map(x => ({
                                        key: x.id,
                                        value: x.id,
                                        label: x.label,
                                    }))}
                                    isMulti={true}
                                    onChange={value => updateUsers('clientProxy', value)}
                                    value={clientProxy.map(x => ({
                                        key: x.id,
                                        value: x.id,
                                        label: x.label,
                                    }))}
                                />
                            </Control>
                        </Field>
                    </Field>
                )}

                {usersForSite ? renderTable(usersForSite) : 'loading...'}

                <div className="action-groups">
                    <div className="action-group">
                        <Button
                            type="button"
                            buttonType="cancel"
                            onClick={() => props.history.goBack()}
                        >
                            {translate('Globals.Label.Cancel')}
                        </Button>
                    </div>
                    <div className="action-group">
                        <Button onClick={() => onSaveUpdateUser()}>
                            {translate('UpsertSite.Users.Button.SaveUsers')}
                        </Button>
                    </div>
                </div>
            </Form>
        </ArticlePanel >
    );
};
