import { connect } from "react-redux";
import { IRootState } from "routes/store";
import { sendVerificationCode, validateVerificationCode } from "../../actions/actions-v2/client-verification-action-v2";
import ClientMultifactorPage from "./client-multifactor-page";

const mapDispatchToProps = {
    sendVerificationCode,
    validateVerificationCode
};

const mapStateToProps = (state: IRootState) => {
    return {
        isLoading: state.clientVerificationState.isLoading,
        validationId: state.clientVerificationState.verificationId
    };
};

export const ClientVerificationContainer = connect(mapStateToProps, mapDispatchToProps)(ClientMultifactorPage);