export type EmeraldSidesheetTypeType = 'modal' | 'dismissible';

export type EmeraldSidesheetWidthType =
  | 'small'
  | 'medium'
  | 'wide'
  | 'full'
  | 'extended';

export enum EmeraldSidesheetType {
  Modal = 'modal',
  Dismissible = 'dismissible',
}

export enum EmeraldSidesheetWidth {
  Small = 'small',
  Medium = 'medium',
  Wide = 'wide',
  Extended = 'extended',
  Full = 'full',
}
