import moment from 'moment';

export {};

declare global {
    // tslint:disable-next-line: interface-name
    interface String {
        toDateString(format?: string): string;
    }
}

String.prototype.toDateString = function(format: string = 'DD MMM YYYY'): string {
    return moment(this).format(format);
};
