import { withRouter } from 'react-router';
import { IRootState } from '../store';
import {
    onSortEngineers,
    loadEngineer,
    toggleCategory,
    loadEngineers,
    onChangeStatus,
    clearEngineer,
    updateEngineer,
    deleteEngineer,
} from 'actions/actions-v2/engineer-action-v2';
import { connect } from 'react-redux';
import { Dispatch } from 'react';
import { IAppAction } from 'actions/app-action';
import { UpsertEngineerPage } from './upsert-engineer-page';
import { IEngineerSkill, IEngineersFilters, IEngineer } from 'models';
import { loadObsoleteSkillCategories } from 'actions/actions-v2';
import { AppActions } from 'actions/app-actions';

const mapStateToProps = (state: IRootState) => {
    return {
        engineer: state.engineerState.engineer,
        isLoading: state.engineerState.isLoading,
        siteId: state.app.siteId,
        siteSkillCards: state.skillCardState.siteSkillCards,
        skillCategories: state.engineersState.skillCategories,
        currentPerson: state.person.personProfile,
        permissions: state.app.permissions
    };
};

const mapDispatchToProps = (dispatch: Dispatch<IAppAction>) => {
    return {
        loadEngineer(id: string) {
            dispatch(loadEngineer(id));
        },
        loadEngineers(filters: IEngineersFilters) {
            dispatch(loadEngineers(filters));
        },
        onSort(key: string, sortAscending: boolean) {
            dispatch(onSortEngineers(key, sortAscending));
        },
        onToggleCategory(id: string) {
            dispatch(toggleCategory(id));
        },
        onChangeStatus(skill: IEngineerSkill) {
            dispatch(onChangeStatus(skill));
        },
        onUpdateEngineer(engineer: IEngineer) {
            dispatch(updateEngineer(engineer));
        },
        onArchiveEngineer(id: string) {
            dispatch(deleteEngineer(id));
        },
        loadUsers(siteId: string) {
            dispatch({
                type: AppActions.CHANGE_SITE,
                payload: {
                    siteId,
                },
            });
        },
        clearEngineer() {
            dispatch(clearEngineer());
        },
        loadSkillCategories(siteId: string, qualificationCardType: string) {
            //dispatch(loadSkillCategories(siteId, skillCardId)); //will enable this line after the implementation of the skills
            dispatch(loadObsoleteSkillCategories(siteId, qualificationCardType));
        }
    };
};

export const UpsertEngineerPageContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(UpsertEngineerPage)
);
