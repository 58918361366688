import { combineReducers } from 'redux';
import { ClientActions } from 'actions/client-actions';
import { ClientActions as ClientActionsV2 } from 'actions/actions-v2/client-action-v2';
import { IAuditor, IClient, IMyDataClient } from 'models/client';
import { IAppAction } from 'actions/app-action';

export interface IClientPage {
    isLoading: boolean;
    upsertItem: Partial<IClient>;
    iAuditorAccountName: IAuditor;
    myDataClients: IMyDataClient[];
}

export const INITIAL_STATE: IClientPage = {
    upsertItem: {},
    isLoading: false,
    iAuditorAccountName: null,
    myDataClients: null,
};

const upsertItem = (state: Partial<IClient> = INITIAL_STATE.upsertItem, action: IAppAction) => {
    switch (action.type) {
        case ClientActionsV2.UPDATE_CLIENT_FULFILLED:
        case ClientActionsV2.CREATE_CLIENT_FULFILLED:
            return {};
        case ClientActionsV2.LOAD_CLIENT_FULFILLED:
            return action.payload ? action.payload : state;
        case ClientActionsV2.RESET_CLIENT:
            return INITIAL_STATE.upsertItem;
        default:
            return state;
    }
};

const iAuditorAccountName = (
    state: IAuditor = INITIAL_STATE.iAuditorAccountName,
    action: IAppAction
) => {
    switch (action.type) {
        case ClientActionsV2.SAVE_IAUDITOR_ACCESS_TOKEN_FULFILLED:
            return action.payload;
        case ClientActionsV2.LOAD_IAUDITOR_ORGANIZATION_DETAILS_FULFILLED:
            if (action.payload && !action.payload.name) {
                return {
                    ...state,
                    name: '',
                };
            }
            return action.payload;
        case ClientActionsV2.REVOKE_IAUDITOR_ACCESS_TOKEN_FULFILLED:
            return {
                clientId: '',
                id: '',
                name: '',
                type: '',
            };
        default:
            return state;
    }
};

const myDataClients = (
    state: IMyDataClient[] = INITIAL_STATE.myDataClients,
    action: IAppAction
) => {
    switch (action.type) {
        case ClientActionsV2.LOAD_MYDATA_CLIENTS_FULFILLED:
            return action.payload ? action.payload : state;
        default:
            return state;
    }
};

const isLoading = (state: boolean = INITIAL_STATE.isLoading, action: IAppAction) => {
    switch (action.type) {
        case ClientActions.LOAD_PAGE_FULFILLED:
        case ClientActions.LOAD_CLIENT_FULFILLED:
        case ClientActionsV2.LOAD_CLIENT_FULFILLED:
        case ClientActionsV2.CREATE_CLIENT_FULFILLED:
        case ClientActionsV2.UPDATE_CLIENT_FULFILLED:
        case ClientActionsV2.SAVE_IAUDITOR_ACCESS_TOKEN_FULFILLED:
        case ClientActionsV2.SAVE_IAUDITOR_ACCESS_TOKEN_REJECTED:
        case ClientActionsV2.LOAD_IAUDITOR_ORGANIZATION_DETAILS_FULFILLED:
        case ClientActionsV2.LOAD_IAUDITOR_ORGANIZATION_DETAILS_REJECTED:
        case ClientActionsV2.LOAD_MYDATA_CLIENTS_FULFILLED:
            return false;
        case ClientActions.UPSERT_CLIENT_FULFILLED:
            return false;
        case ClientActions.UPSERT_CLIENT:
        case ClientActions.LOAD_PAGE:
        case ClientActionsV2.LOAD_CLIENT:
        case ClientActionsV2.LOAD_MYDATA_CLIENTS:
        case ClientActionsV2.SAVE_IAUDITOR_ACCESS_TOKEN:
        case ClientActionsV2.LOAD_IAUDITOR_ORGANIZATION_DETAILS:
        case ClientActionsV2.CREATE_CLIENT:
        case ClientActionsV2.UPDATE_CLIENT:
        case ClientActionsV2.DELETE_CLIENT:
            return true;
        default:
            return state;
    }
};

export const clientPage = combineReducers<IClientPage>({
    isLoading,
    upsertItem,
    iAuditorAccountName,
    myDataClients,
});
