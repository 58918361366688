import * as React from 'react';

import { compact } from 'lodash';
import './Form.scss';

export const Control: React.FC<React.AllHTMLAttributes<HTMLDivElement>> = (props) => {
    const css = compact([
        'control',
        props.className
    ]).join(' ');

    return (
        <div className={css}>
            {props.children}
        </div>
    );
};
