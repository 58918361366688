import React from 'react';
import classNames from 'classnames';

export interface ISchedulerItemProps {
    title: string;
    titleClassName?: string;
    onClick: () => void;
}

export const SchedulerItem: React.FC<React.PropsWithChildren<ISchedulerItemProps>> = ({
    title,
    titleClassName,
    children,
    onClick
}) => {
    return (
        <div className="scheduler-item" onClick={onClick}>
            <div className={classNames('scheduler-item-title', titleClassName)}>{title}</div>
            <div className="scheduler-item-body">{children}</div>
        </div>
    );
};
