import * as React from 'react';

import { GridSortLink } from '../../../../../components/gridSortLink';
import { Loader } from '../../../../../components/loader';
import Popup from 'reactjs-popup';
import { IRole } from 'reducers/manageRoles/manageRoles-grid';
import { SortDirection } from '../../../../../enums/SortDirection';
import { IRoleFilters } from 'models/role-filters';
import { IKeyValue } from 'models/key-value';
import { checkPermissions } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import useTranslate from 'translations/translation-utils';

export interface IDispatchProps {
    onSort: (sortColumn: string, sortAscending: boolean) => void;
    onShowRoleModal: (role: IRole) => void;
    onDeleteRole: (roleId: string) => void;
    onEditRolePrivileges: (roleId: string) => void;
    loadRoles: (filters: IRoleFilters) => void;
}

export interface IProps extends IDispatchProps {
    rolesState: IRole[];
    sortColumn: string;
    sortAscending: boolean;
    isLoading: boolean;
    manageRolesFilterRoles: string[];
    filterKeywords: string[];
    permissions: Array<IKeyValue<string>>;
}

export const ManageRolesTab: React.FC<IProps> = (props) => {
    const translate = useTranslate();
    React.useEffect(() => {
        props.loadRoles({ filterKeywords: [] });
    }, []);

    const filteredRoleStatesOnRoles = () => {

        function dynamicSort(property: string, sortDirection: boolean) {
            const sortOrder = sortDirection ? 1 : -1;
            return (a: any, b: any) => {
                const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                return result * sortOrder;
            };
        }

        const rolesStateFilteredOnRoles = props.rolesState ? props.rolesState.filter((f) => {
            if (props.manageRolesFilterRoles && props.manageRolesFilterRoles.length > 0) {
                return (props.manageRolesFilterRoles.indexOf(f.id) > -1);
            }
            return true;
        }) : [];

        const filteredByKeywords = rolesStateFilteredOnRoles.filter((role) => {
            if (props.filterKeywords && props.filterKeywords.length > 0) {
                if (role.description) {
                    const description = role.description.toLocaleLowerCase();
                    if (props.filterKeywords.some((r) => description.includes(r.toLocaleLowerCase()))) {
                        return true;
                    }
                }
                if (role.value) {
                    const roleName = role.value.toLocaleLowerCase();
                    if (props.filterKeywords.some((r) => roleName.includes(r.toLocaleLowerCase()))) {
                        return true;
                    }
                }
                return false;
            }
            return true;
        });

        return filteredByKeywords.sort(dynamicSort(props.sortColumn, props.sortAscending));
    };

    const filteredRoleStates = filteredRoleStatesOnRoles();

    const getRoleTabItem = (
        role: IRole,
        onShowRoleModal: (role: IRole) => void,
        onDeleteRole: (roleId: string) => void,
    ) => {

        return (
            <>
                {role && <div key={role.id} className="ManageRoleList">
                    <div className="columns">
                        <div className="column is-3">
                            {role.value}
                        </div>
                        <div className="column is-4">
                            {role.description}
                        </div>
                        <div className="column is-2">
                            {role.roleLevel}
                        </div>
                        <div className="column is-2">
                            {role.peopleInRole}
                        </div>
                        {checkPermissions([Privilege.ManageRoles], props.permissions) && <div className="column is-1">
                            <Popup
                                arrow={false}
                                trigger={<button className="trigger-button"> ...</button>}
                                position="bottom center"
                            >
                                <div className="popup-container">
                                    <button onClick={onShowRoleModal.bind(this, role)} className="popup-button">
                                        {translate('Roles.Edit')}
                                    </button>
                                    <button onClick={onDeleteRole.bind(this, role.id)} className="popup-button">
                                        {translate('Roles.Del')}
                                    </button>
                                    {/* <button onClick={EditRolePrivileges.bind(this, role.id)} className="popup-button">
                                        Edit Privileges
                                </button> */}
                                </div>
                            </Popup>
                        </div>}

                    </div>
                </div>}
            </>
        );
    };

    return (
        <Loader className="ManageRoleList" loading={props.isLoading}>
            <div className={`grid-headers columns ${props.isLoading ? 'is-loading' : ''}`}>
                <div className="column is-3">
                    {getGridHeader(translate('Roles.Role'), 'roleName', props)}
                </div>
                <div className="column is-4">
                    {getGridHeader(translate('RiskActionTab.Label.Description'), 'roleDescription', props)}
                </div>
                <div className="column is-2">
                    {getGridHeader(translate('Roles.Level'), 'roleLevel', props)}
                </div>
                <div className="column is-2">
                    {getGridHeader(translate('Roles.With'), 'peopleInRole', props)}
                </div>
                {checkPermissions([Privilege.ManageRoles], props.permissions) &&
                    <div className="column is-1">
                        <div className="GridSortLink">
                            {translate('IncidentRiskActions.Label.Action')}
                        </div>
                    </div>}

            </div>

            {filteredRoleStates && filteredRoleStates.map(
                (role) => getRoleTabItem(
                    role,
                    props.onShowRoleModal,
                    props.onDeleteRole,
                    // props.onEditRolePrivileges
                )
            )}
        </Loader>
    );
};



const getGridHeader = (label: string, fieldName: string, props: IProps) => {
    const onSort = (sortField: string, sortDirection: SortDirection) => props.onSort(
        sortField,
        sortDirection === SortDirection.Ascending
    );

    return (
        <GridSortLink
            currentSortFieldName={props.sortColumn}
            currentSortDirection={props.sortAscending ? SortDirection.Ascending : SortDirection.Descending}
            sortFieldName={fieldName}
            onSort={onSort}
        >
            <div className="is-uppercase">
                {label}
            </div>
        </GridSortLink>
    );
};
