import { createSelector } from 'reselect';

import { ISiteRelationshipsRootState } from 'reducers/siteRelationships';

const getSiteSelectorState = (state: ISiteRelationshipsRootState) =>
    state.siteRelationships.siteSelector;

const getClientsSelectorState = (state: ISiteRelationshipsRootState) =>
    state.siteRelationships.clientSelector;

const getIsLinkButtonEnabled = createSelector(
    getSiteSelectorState,
    getClientsSelectorState,
    (siteState, clientState) =>
        Boolean(
            siteState.selectedSite && clientState.selectedClient
        ) && !clientState.isSubmitting
);

export default createSelector(
    getSiteSelectorState,
    getClientsSelectorState,
    getIsLinkButtonEnabled,
    (siteState, clientState, isLinkButtonEnabled) => ({
        selectedSite: siteState.selectedSite,
        selectedClient: clientState.selectedClient,
        isLinkButtonEnabled,
        isLinkButtonSubmitting: clientState.isSubmitting
    })
);
