import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { getCss, processNumber } from 'components/common/common';
import { Percentage } from 'components/percentage';
import { IKeyValue } from 'models/key-value';
import { IKeyedItemExtended } from 'models/keyed-item';
import { RouteUrlProcessStep } from 'routes/upsert-cerm-process-step/routes';
import { ProcessStepDeleteConfirmation } from 'routes/upsert-cerm-process-step/StepArchiveConfirmation';
import { checkPermission, checkPermissions } from 'utils/permission-utils';
import { Panel } from '../../../../components/panel';
import { Timeline, TimelineHeader, TimelineItem } from '../../../../components/timeline';
import { ICermOverviewPillar, ICermOverviewProcessStep } from '../../../../models/cerm-overview';
import './cerm-overview-list.scss';
import { Button } from 'components/v2/components';
import { Privilege } from 'enums/Privilege';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconType } from 'routes/material-icon/material-icon-type';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';

export interface IProps {
    pillar: ICermOverviewPillar;
    expandedPillars: string[];
    expandedItems: string[];
    permissions: Array<IKeyValue<string>>;
    isLoading?: boolean;
    isDeleting?: boolean;
    onTogglePillar: (id: string) => void;
    onToggleProcessStep: (id: string) => void;
    onDelete: (item: IKeyedItemExtended) => void;
    operatingPlatform: string;
    intl: any;
    getTitlePrefix: () => void;
}

class CermOverviewPillarClass extends React.PureComponent<IProps> {
    public render() {
        const activeSteps = this.props.pillar.processSteps.filter(step => step.isActive);
        const isExpanded = this.props.expandedPillars.includes(this.props.pillar.title);
        const icon = isExpanded ? (
            <MaterialIcons type={MaterialIconType.Minus} />
        ) : (
            <MaterialIcons type={MaterialIconType.Plus} />
        );
        const { pillar } = this.props;
        return (
            <Panel key={pillar.title}>
                {' '}
                <header onClick={this.onToggle}>
                    <h2 className="title is-4">{pillar.title}</h2>
                    <Button className="pillar__toggle" onClick={this.onToggle}>
                        {icon}
                    </Button>
                </header>
                {isExpanded ? activeSteps.map(step => getProcessStep(step, this.props)) : null}
            </Panel>
        );
    }

    private onToggle: React.MouseEventHandler<HTMLButtonElement | HTMLDivElement> = e => {
        e.stopPropagation();
        this.props.onTogglePillar(this.props.pillar.title);
    };
}

const getProcessStep = (step: ICermOverviewProcessStep, props: IProps) => {
    const isExpanded = props.expandedItems.includes(step.id);
    const onToggle = () => props.onToggleProcessStep(step.id);
    const isDraft = step.status === 'Draft';
    const activeOutcomes = step.outcomes.filter(outcome => outcome.isActive);
    const intl = props.intl;
    const customProcessColumnTile = `${props.getTitlePrefix()} ${translate(
        intl,
        'ProcessOverview.ListPillar.Process'
    )}`;

    return (
        <div className="CermOverviewList__process process-step">
            <Timeline highlighted={isDraft} expanded={isExpanded} key={step.id}>
                <TimelineHeader expanded={isExpanded} onToggleExpanded={onToggle}>
                    <div key={step.id} className="columns">
                        <div className={getCss('processNumber')}>
                            <span className="grid__label">
                                {translate(intl, 'CermOverview.ListPillar.ProcessNumber')}
                            </span>
                            {processNumber(step.processNumber)}
                        </div>
                        <div className={getCss('title')}>
                            <span className="grid__label">{customProcessColumnTile}</span>
                            {step.title}
                        </div>
                        <div className={getCss('status')}>
                            <span className="grid__label">
                                {translate(intl, 'RiskRegisterPage.RiskFilter.Title.Status')}
                            </span>
                            {step.status}
                        </div>
                        <div className={getCss('numberOfSites')}>
                            <span className="grid__label">
                                {translate(intl, 'SideNavBar.Labels.Sites')}
                            </span>
                            {step.numberOfSites}
                        </div>
                        <div className={getCss('numberFullyCompliant')}>
                            <span className="grid__label">
                                {translate(intl, 'CermSiteOverview.List.Group.FullyComplete')}
                            </span>
                            {step.numberFullyCompliant}
                        </div>
                        <div className={getCss('percentCompliant')}>
                            <span className="grid__label">
                                {translate(intl, 'CermOverview.List.Compliant')}
                            </span>
                            <Percentage value={Number(step.percentCompliant.toFixed(0))} />
                        </div>
                        <div className={getCss('numberNotStarted')}>
                            <span className="grid__label">
                                {translate(intl, 'CermOverview.List.NotStarted')}
                            </span>
                            {step.numberNotStarted}
                        </div>
                        <div className={getCss('priority')}>
                            <span className="grid__label">
                                {translate(intl, 'RiskActionTab.Label.Priority')}
                            </span>
                            {step.priority}
                        </div>
                    </div>
                </TimelineHeader>

                {activeOutcomes.map((outcome, index) => (
                    <TimelineItem key={index}>
                        <div className="outcome">
                            <h3 className="outcome__title">
                                {translate(
                                    intl,
                                    'Cerm.CermOverview.CermOverviewListPillar.Outcome'
                                )}
                                {index + 1}
                            </h3>
                            <div className="outcome__description">{outcome.title}</div>
                            <div className="outcome__percentage">
                                <Percentage value={Number(outcome.percentCompliant.toFixed(0))} />
                            </div>
                        </div>
                    </TimelineItem>
                ))}
            </Timeline>

            {isExpanded && checkPermissions([Privilege.ProcessUpdate], props.permissions)
                ? getProcessStepActions(step, props)
                : null}
        </div>
    );
};

const checkPermissionsWithOperatingPlatform = props => {
    return checkPermission(Privilege.ProcessArchive, props.permissions);
};

const getProcessStepActions = (step: ICermOverviewProcessStep, props: IProps) => {
    const isDraft = step.status === 'Draft';
    const editUrl = RouteUrlProcessStep(props.operatingPlatform, step.id);
    const reloadUrl = `/CermProcessSteps/${props.operatingPlatform}`;
    const delteItem: IKeyedItemExtended = { ...step, id: step.id, reloadUrl: reloadUrl };
    const onDelete = () => props.onDelete(delteItem);
    const intl = props.intl;

    return (
        <div className="process-step__actions">
            <NavLink to={editUrl} className="process-step__action button button-action">
                {isDraft
                    ? translate(intl, 'Cerm.CermOverview.CermOverviewListPillar.EditButtonText')
                    : translate(intl, 'Cerm.CermOverview.CermOverviewListPillar.AmendButtonText')}
            </NavLink>
            {checkPermissionsWithOperatingPlatform(props) && (
                <Button onClick={onDelete} buttonType="archive">
                    {isDraft
                        ? translate(
                              intl,
                              'Cerm.CermOverview.CermOverviewListPillar.DeleteButtonText'
                          )
                        : translate(
                              intl,
                              'Cerm.CermOverview.CermOverviewListPillar.ArchiveButtonText'
                          )}
                </Button>
            )}

            <ProcessStepDeleteConfirmation
                confirmMessage={
                    isDraft
                        ? translate(intl, 'Cerm.CermOverview.CermOverviewListPillar.ConfirmMessage')
                        : translate(
                              intl,
                              'Cerm.CermOverview.CermOverviewListPillar.ConfirmMessage2'
                          )
                }
                isLoading={props.isDeleting}
            />
        </div>
    );
};

export const CermOverviewPillar = injectIntl(CermOverviewPillarClass);
