import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { BulkReassignPage } from './bulk-reassign-page';
import { loadPersonAssignModules, updatePersonAssignModules, loadPersonsLookup, loadPersonAssignedItem } from 'actions/actions-v2/person-action-v2';
import { getFilteredLookupSitePeopleForMockdrillParticipants, getFilteredLookupSitePeopleWithCRORolesExclusively } from 'sharedSelectors';
import { loadSitePersons, loadSiteAllPersons } from 'actions/actions-v2/person-action-v2';
import { IShiftEngineer } from 'models/shift';
import { loadEngineers } from 'actions/actions-v2/shift-actions';
import { loadPrivilegeRoles } from 'actions/actions-v2/role-actions-v2';
import { onSiteChange } from 'actions/app-actions';

const getSiteId = (state: IRootState) => state.app.siteId;
const getIsLoading = (state: IRootState) =>
    state.actionRegisterState.isLoading || state.lookups.isLoading;
const getPermissions = (state: IRootState) => state.app.permissions;
const getIsSitePersonsLoading = (state: IRootState) => state.persons.grid.isLoading;
const getSiteAllPersons = (state: IRootState) => state.persons.grid.siteAllPersons;
const getSitePersons = (state: IRootState) => state.persons.grid.sitePersons;
const getUserAssignModule = (state: IRootState) => state.persons.grid.userAssignModule;
const getPersonAssignedItem = (state: IRootState) => state.persons.grid.personAssignedItem;
const getEngineers = (state: IRootState) => state.shiftsState.engineers && state.shiftsState.engineers.length > 0 &&
    state.shiftsState.engineers.slice().sort((a: IShiftEngineer, b: IShiftEngineer) =>
    a.firstName === b.firstName ? (a.lastName > b.lastName ? 1 : -1) : (a.firstName > b.firstName ? 1 : -1));

const getMockdrillParticipantsPrivilege = (state: IRootState) => {
    return state.manageRoles.grid.privilegeRoles?.privilegeRoles;
}

const mapStateToProps = createSelector(
    getSiteId,
    getIsLoading,
    getPermissions,
    getIsSitePersonsLoading,
    getSiteAllPersons,
    getSitePersons,
    getUserAssignModule,
    getPersonAssignedItem,
    getEngineers,
    getFilteredLookupSitePeopleForMockdrillParticipants,
    getFilteredLookupSitePeopleWithCRORolesExclusively,
    getMockdrillParticipantsPrivilege,
    (siteId, isLoading, permissions, isSitePersonsLoading, siteAllPersons, sitePersons, userAssignModule, personAssignedItem, engineers,mockdrillSitePersons, croPersons, mockdrillParticipantsPrivilege) => ({
        siteId,
        isLoading: isLoading || isSitePersonsLoading || (!siteId),
        permissions,
        siteAllPersons,
        sitePersons,
        mockdrillSitePersons,
        mockdrillParticipantsPrivilege,
        croPersons,
        userAssignModule,
        personAssignedItem,
        engineers,
        })
);

const mapDispatchToProps = {
    loadPersonsLookup,
    loadSiteAllPersons,
    loadSitePersons,
    loadPersonAssignModules,
    updatePersonAssignModules,
    loadPersonAssignedItem,
    loadEngineers,
    loadPrivilegeRoles,
    onSiteChange,
};

export const BulkReassignPageContainer = connect(mapStateToProps, mapDispatchToProps)(BulkReassignPage);
