import { Action } from 'redux';
import { ISiteProfile } from '../models/site-profile';
import { ISiteProfilesGridState } from 'reducers/site-profiles/site-profiles-grid';

export enum SiteProfileActions {
    LOAD_SITE_PROFILES = '@@siteProfiles/LOAD_SITE_PROFILES',
    LOAD_SITE_PROFILES_FULFILLED = '@@siteProfiles/LOAD_SITE_PROFILES_FULFILLED',
    LOAD_SITE_PROFILES_REJECTED = '@@siteProfiles/LOAD_SITE_PROFILES_REJECTED',
    LOAD_SITE_PROFILES_CANCELLED = '@@siteProfiles/LOAD_SITE_PROFILES_CANCELLED',

    TOGGLE_ITEM_EXPANDED = '@@siteProfiles/TOGGLE_ITEM_EXPANDED',
    TOGGLE_FILTER_EXPANDED = '@@siteProfiles/TOGGLE_FILTER_EXPANDED',
    SHOW_ACTIONS = '@@siteProfiles/SHOW_ACTIONS',
    HIDE_ACTIONS = '@@siteProfiles/HIDE_ACTIONS',

    LOAD_PAGE = '@@siteProfiles/LOAD_PAGE',
    LOAD_PAGE_FULFILLED = '@@siteProfiles/LOAD_PAGE_FULFILLED',
    LOAD_PAGE_REJECTED = '@@siteProfiles/LOAD_PAGE_REJECTED',
    LOAD_PAGE_CANCELLED = '@@siteProfiles/LOAD_PAGE_CANCELLED',
    EXPORT_SITES = '@@siteProfiles/EXPORT_SITES',
    EXPORT_SITES_FULFILLED = '@@siteProfiles/EXPORT_SITEs_FULFILLED',
    EXPORT_SITES_REJECTED = '@@siteProfiles/EXPORT_SITES_REJECTED',
    EXPORT_SITES_CANCELLED = '@@siteProfiles/EXPORT_SITES_CANCELLED'
}

export interface ILoadPageAction extends Action {
    type: SiteProfileActions.LOAD_PAGE;
}

export interface IExportSitesAction extends Action {
    type: SiteProfileActions.EXPORT_SITES;
}

export interface IExportSitesFulfilledAction extends Action {
    type: SiteProfileActions.EXPORT_SITES_FULFILLED;
    payload: any;
}

export interface IExportSitesRejectedAction extends Action {
    type: SiteProfileActions.EXPORT_SITES_REJECTED;
}

export interface IExportSitesCanceledAction extends Action {
    type: SiteProfileActions.EXPORT_SITES_CANCELLED;
}

export interface ILoadPageRejectedAction extends Action {
    type: SiteProfileActions.LOAD_PAGE_REJECTED;
    error: true;
    payload: any;
}

export interface ILoadPageCancelledAction extends Action {
    type: SiteProfileActions.LOAD_PAGE_CANCELLED;
}

export interface ILoadPageFulfilledAction extends Action {
    type: SiteProfileActions.LOAD_PAGE_FULFILLED;
    payload: ISiteProfilesGridState;
}

export interface ILoadSiteProfilesAction extends Action {
    type: SiteProfileActions.LOAD_SITE_PROFILES;
}

export interface ILoadSiteProfilesRejectedAction extends Action {
    type: SiteProfileActions.LOAD_SITE_PROFILES_REJECTED;
    error: true;
    payload: any;
}

export interface ILoadSiteProfilesCancelledAction extends Action {
    type: SiteProfileActions.LOAD_SITE_PROFILES_CANCELLED;
}

export interface ILoadSiteProfilesFulfilledAction extends Action {
    type: SiteProfileActions.LOAD_SITE_PROFILES_FULFILLED;
    payload: {
        siteProfiles: ISiteProfile[];
    };
}

export interface IToggleItemExpanded extends Action {
    type: SiteProfileActions.TOGGLE_ITEM_EXPANDED;
    payload: {
        id: string;
    };
}

export interface ITOGGLE_FILTER_EXPANDED extends Action {
    type: SiteProfileActions.TOGGLE_FILTER_EXPANDED;
    payload: {
        id: string;
    };
}

export interface IHideActions extends Action {
    type: SiteProfileActions.HIDE_ACTIONS;
    payload: {
        id: string;
    };
}

export interface IShowActions extends Action {
    type: SiteProfileActions.SHOW_ACTIONS;
    payload: {
        id: string;
    };
}

export type SiteProfileActionTypes =
| ILoadSiteProfilesAction
| ILoadSiteProfilesCancelledAction
| ILoadSiteProfilesFulfilledAction
| ILoadSiteProfilesRejectedAction

| IToggleItemExpanded
| IHideActions
| IShowActions
| ILoadPageAction
| ILoadPageCancelledAction
| ILoadPageRejectedAction
| ILoadPageFulfilledAction
| IExportSitesAction
| IExportSitesCanceledAction
| IExportSitesFulfilledAction
| IExportSitesRejectedAction
| ITOGGLE_FILTER_EXPANDED
