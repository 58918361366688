import React from 'react';
import { connect } from 'react-redux';
import { IShift, IShiftFilters } from 'models/shift';
import { IObsoleteSkillCategory } from 'models/engineer';
import { ShiftsGridToolbar } from '../../components/shifts/shifts-grid-toolbar';
import ShiftPanel from '../../components/shifts/shift-panel';
import { Loader } from 'components/loader';
import actions from './actions';
import selectors from './selectors';
import Page from 'components/v2/page/Page';
import { HelpModuleContainer } from 'routes/help-module/help-module-container';
import { ModuleType } from 'models/help-module';
import { IKeyValue } from 'models';
import { checkPermission } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import { Alert } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';

interface IProps {
    isLoading: boolean;
    siteId: string;
    shifts: IShift[];
    skillCategories: IObsoleteSkillCategory[];
    permissions: Array<IKeyValue<string>>;
    loadShifts: (filters: IShiftFilters) => void;
    //loadSkillCategories(siteId: string, skillCardId?: string | string[]): void;
    loadObsoleteSkillCategories(siteId: string, qualificationCardType?: string): void;
}

const ShiftsPage: React.FC<IProps> = ({
    isLoading,
    siteId,
    shifts,
    skillCategories,
    permissions,
    loadShifts,
    //loadSkillCategories,
    loadObsoleteSkillCategories
}) => {
    const [showHelp, setShowHelp] = React.useState(false);
    const isReadOnly = !checkPermission(Privilege.CDNAUpdate, permissions);

    React.useEffect(() => {
        if (!siteId) {
            return;
        }
        loadShifts({ siteId });
        //loadSkillCategories(siteId); //Will enable this after the implementation of skills
        loadObsoleteSkillCategories(siteId);
    }, [siteId]);

    const onClickHelp = () => {
        setShowHelp(!showHelp);
    };
    const translate = useTranslate();
    return (
        <Page title={translate('Shifts.ShiftPage.Title')} className="shifts-page">
            <ShiftsGridToolbar
                onClickHelp={onClickHelp}
                isShowHelp={true}
                isReadOnly={isReadOnly} />

            {isReadOnly && (
                <Alert type="warning">
                    <p>{translate('EngineerPage.Lable.8')}</p>
                </Alert>
            )}

            <HelpModuleContainer
                isShown={showHelp}
                onClose={onClickHelp}
                moduleType={ModuleType.ShiftHandover}
            />

            <Loader loading={isLoading}>
                {shifts && shifts.length > 0
                    ? shifts.map((shift) => (
                        <ShiftPanel
                            key={shift.id}
                            shift={shift}
                            skillCategories={skillCategories}
                            isReadOnly={isReadOnly}
                        />
                    ))
                    : !isLoading && <div className="has-text-centered">{translate('Shifts.ShiftPage.Message')}</div>}
            </Loader>
        </Page>
    );
};

export default connect(selectors, actions)(ShiftsPage);
