import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import {
    loadGlobalMockDrill,
    createGlobalMockDrill,
    updateGlobalMockDrill,
    deleteGlobalMockDrill,
    cleanupGlobalMockDrill,
} from 'actions/actions-v2/global-mock-drills-actions';
import { GlobalMockdrillDetailsTab } from './global-mockdrill-details-tab';
import {
    loadAttachmentsGlobalMockDrill,
    uploadAttachmentGlobalMockDrill,
} from 'actions/actions-v2/attachment-action-v2';
import { checkPermission, checkPermissions } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';

const getGlobalMockDrill = (state: IRootState) => state.globalMockDrillsState.globalMockDrill;
const getIsLoading = (state: IRootState) => state.globalMockDrillsState.isLoading;
const getIsSaving = (state: IRootState) => state.globalMockDrillsState.isSaving;
const getAvailableSites = (state: IRootState) => state.app.lookupSites;
const getClients = (state: IRootState) => state.app.lookupClients;
const getIsLocked = (state: IRootState) => {
    return state.globalMockDrillsState.globalMockDrill
        ? state.globalMockDrillsState.globalMockDrill.isLocked
        : false;
};
const getAttachments = (state: IRootState) => state.globalMockDrillsState.attachments;

const canUserDeleteGlobalMockDrillsScenario = (state: IRootState) =>
    checkPermission(Privilege.GlobalMockDrillsScenarioDelete, state.app.permissions);
const getLineOfBusiness = (state: IRootState) => state.app.lookupLineOfBusiness;
const canUserEditGlobalMockDrillContent = (state: IRootState) =>
    checkPermissions(
        [Privilege.GlobalMockDrills],
        state.app.permissions);

const getPersonProfile = (state: IRootState) => state.person.personProfile;

const mapStateToProps = createSelector(
    getGlobalMockDrill,
    getIsLoading,
    getAvailableSites,
    getClients,
    getIsSaving,
    getIsLocked,
    getAttachments,
    canUserDeleteGlobalMockDrillsScenario,
    getLineOfBusiness,
    canUserEditGlobalMockDrillContent,
    getPersonProfile,
    (
        globalMockDrill,
        isLoading,
        availableSites,
        clients,
        isSaving,
        isLocked,
        attachments,
        userCanDeleteGlobalMockDrillsScenario,
        lineOfBusiness,
        canEditGlobalMockDrillContent,
        personProfile
    ) => ({
        globalMockDrill,
        isLoading,
        availableSites,
        clients,
        isSaving,
        isLocked,
        attachments,
        userCanDeleteGlobalMockDrillsScenario,
        lineOfBusiness,
        canEditGlobalMockDrillContent,
        personProfile
    })
);

const mapDispatchToProps = {
    loadGlobalMockDrill,
    createGlobalMockDrill,
    updateGlobalMockDrill,
    deleteGlobalMockDrill,
    cleanupGlobalMockDrill,
    loadAttachments: loadAttachmentsGlobalMockDrill,
    uploadAttachment: uploadAttachmentGlobalMockDrill,
};

export const GlobalMockDrillDetailsTabContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(GlobalMockdrillDetailsTab)
);
