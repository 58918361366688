import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter, useParams } from 'react-router';
import actions from './actions';
import { Loader } from 'components/loader';
import { ActionRegisterTable } from 'components/action-register/action-register-table';
import { ActionRegisterGridToolbar } from 'components/action-register/action-register-toolbar';
import { IAction } from 'models/action';
import { IActionFilters } from 'models/action-filters';
import selectors from './selectors';
import { ActionFilters } from './views/actionFilters';
import { OverlayPanel } from 'components/v2/components';
import Page from 'components/v2/page/Page';
import { usePageUIPreferences, UiPreferences } from 'utils/page-ui-preferences-utils';
import useTranslate from 'translations/translation-utils';
import { HelpModuleContainer } from 'routes/help-module/help-module-container';
import { ModuleType } from 'models/help-module';
import { checkFilterExists } from 'utils/array-utils';

interface IProps extends RouteComponentProps {
    siteId: string;
    isLoading: boolean;
    actions: IAction[];
    macroActions: IAction[];
    loadAll: (filters: IActionFilters) => void;
    exportData: (siteId: string) => void;
    clearForm: () => void;
    isExporting: boolean;
    showHelpModule: boolean;
    canViewMacroActions: boolean;
    loadMacroActions: (filters: IActionFilters) => void;
}

export const ActionRegisterPage: React.FC<IProps> = ({
    location,
    siteId,
    isLoading,
    actions: actionList,
    macroActions,
    loadAll,
    exportData,
    isExporting,
    clearForm,
    showHelpModule,
    canViewMacroActions,
    loadMacroActions,
}) => {
    const translate = useTranslate();
    const params: any = useParams();
    const getUpdatedFilters = (
        updatedActionFilters: IActionFilters,
        key: Extract<keyof IActionFilters, string>,
        element: string
    ) => {
        const specialFilters = ['Closed'];
        if (specialFilters.some(c => c === element)) {
            if (updatedActionFilters.filterStatus.find(c => c === 'Closed')) {
                return {
                    ...updatedActionFilters,
                    filterStatus: [],
                };
            }
            return {
                ...updatedActionFilters,
                filterStatus: [element],
            };
        }

        const newFilters = { ...updatedActionFilters };
        const index = newFilters[key].indexOf(element);
        if (index !== -1) {
            const items = [...newFilters[key].slice(0, index), ...newFilters[key].slice(index + 1)];
            newFilters[key] = items;
        } else {
            const items = [element, ...newFilters[key].slice(0, newFilters[key].length)];
            newFilters[key] = items;
        }
        const filtersWithOutSpecials = {
            ...newFilters,
            filterStatus: newFilters.filterStatus.filter(f => !specialFilters.some(c => c === f)),
        } as IActionFilters;

        return filtersWithOutSpecials;
    };

    const initializeFilters = () => {
        let initialFilters = {
            filterKeywords: [],
            filterPriority: [],
            filterCategory: [],
            filterSpecial: [],
            dateDueBefore: [],
            filterStatus: [],
            siteId: [],
        } as IActionFilters;
        const urlParams = new URLSearchParams(location.search);
        urlParams.forEach((element, key) => {
            initialFilters = getUpdatedFilters(initialFilters, key as any, element);
        });
        return initialFilters;
    };

    const [showFilters, setShowFilters] = React.useState(false);
    const [showHelp, setShowHelp] = React.useState(false);
    const [filters, setFilters] = usePageUIPreferences<IActionFilters>(
        UiPreferences.ActionsPageFilters,
        initializeFilters()
    );

    React.useEffect(() => {
        if (!siteId) {
            return;
        }
        loadAll(buildRequestFiltersObject(true));
        if (canViewMacroActions) {
            loadMacroActions(buildRequestFiltersObject(true));
        }
    }, [siteId, filters]);

    React.useEffect(() => {
        const actionsHasData = !actionList || actionList.length === 0;

        if (actionsHasData) {
            return;
        }
    }, [actionList]);

    React.useEffect(() => {
        let buildRequestFilters = buildRequestFiltersObject(false)
        if (params.specialFilter) {
            buildRequestFilters = {
                ...buildRequestFilters,
                filterSpecial: [params.specialFilter],
                filterStatus: ['Open', 'In Progress', 'IN_PROGRESS', 'TODO'],
            };
            setFilters(buildRequestFilters);
            if (siteId) {
                loadAll(buildRequestFilters);
            }
        }
    }, [params.specialFilter, location.key]);

    const buildRequestFiltersObject = (keepState: boolean) => {
        return {
            filterStatus: filters && keepState ? filters.filterStatus : [],
            filterCategory: filters && keepState ? filters.filterCategory : [],
            filterPriority: filters && keepState ? filters.filterPriority : [],
            filterSpecial: filters && keepState ?
                filters.filterSpecial ?
                    filters.filterSpecial :
                    [] :
                [],
            filterKeywords: filters && keepState ? filters.filterKeywords : [],
            dateDueBefore: filters && keepState ? filters.dateDueBefore : [],
            siteId,
        } as IActionFilters;
    };

    const toggleFilters = () => {
        setShowFilters(showFilters ? false : true);
    };

    const resetFilters = () => {
        setFilters(buildRequestFiltersObject(false));
    };

    const onExport = () => {
        exportData(siteId);
    };

    const onAddFilterKeyword = keyword => {
        if (!keyword) {
            return;
        }
        const newFilterKeywords = [...filters.filterKeywords, keyword];
        setFilters({ ...filters, filterKeywords: newFilterKeywords });
    };

    const onRemoveFilterKeyword = keyword => {
        const newFilterKeywords = filters.filterKeywords.filter(x => x !== keyword);
        setFilters({ ...filters, filterKeywords: newFilterKeywords });
    };

    const onFilter = (key: Extract<keyof IActionFilters, string>, element: string) => {
        const updatedFilters = getUpdatedFilters(buildRequestFiltersObject(true), key, element);
        setFilters(updatedFilters);
    };

    const onClickHelp = () => {
        setShowHelp(!showHelp);
    };
    const isFilterExists = checkFilterExists(filters);
    return (
        <Page
            title={translate('ActionRegisterPage.Title.ActionRegister')}
            className="action-register-page"
            scrollTopOnPageLoad={true}
        >
            <ActionRegisterGridToolbar
                onShowFilters={toggleFilters}
                onClearFilters={resetFilters}
                onExport={onExport}
                clearForm={clearForm}
                disableExport={isExporting || !actionList || actionList.length === 0}
                onClickHelp={onClickHelp}
                isShowHelp={showHelpModule}
                isFilterExists={isFilterExists}
            />
            <HelpModuleContainer
                isShown={showHelp}
                onClose={onClickHelp}
                moduleType={ModuleType.Action}
            />
            <OverlayPanel
                title={translate('ActionRegisterPage.Title.Filters')}
                isVisible={showFilters}
                onClose={() => setShowFilters(false)}
                onOutsideDialogClick={() => setShowFilters(false)}
                onClearFilters={resetFilters}
                isFilterExists={isFilterExists}
            >
                <ActionFilters
                    onRemoveFilterKeyword={onRemoveFilterKeyword}
                    onFilter={onFilter}
                    onAddFilterKeyword={onAddFilterKeyword}
                    filters={buildRequestFiltersObject(true)}
                />
            </OverlayPanel>
            <Loader loading={isLoading}>
                {actionList && actionList.length > 0 ? (
                    <>
                        <p className="header-note">{translate('ActionRegisterPage.Note')}</p>
                        {canViewMacroActions && macroActions.length > 0 && (
                            <ActionRegisterTable
                                componentId={'action-macro-list'}
                                actions={macroActions}
                                isMacroActions={true}
                                title={translate('ActionRegisterPage.ActionRegisterTable.Title.MacroTableTitle')}
                            />
                        )}
                        <ActionRegisterTable componentId={'action-list'} actions={actionList} />
                    </>
                ) : (
                    !isLoading && (
                        <div className="column is-12 has-text-centered">
                            {translate('ActionRegisterPage.Message.NoDataAvailable')}
                        </div>
                    )
                )}
            </Loader>
        </Page>
    );
};

export default withRouter(connect(selectors, actions)(ActionRegisterPage));
