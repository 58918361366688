import { getAuthenticationPlatform } from 'helpers/authentication';

export const getApiHeaders = (contentType: string = null, siteId: string = null) => {
    const token = getAuthenticationPlatform().getAccessToken();
    const offset = new Date().getTimezoneOffset();

    if (siteId !== null) {
        return {
            'Content-Type': contentType ? contentType : 'application/json',
            Authorization: `bearer ${token}`,
            timeZoneOffset: offset,
            siteId,
        };
    }

    return {
        'Content-Type': contentType ? contentType : 'application/json',
        Authorization: `bearer ${token}`,
        timeZoneOffset: offset,
    };
};

export const getHeadersWithoutContentType = () => {
    const token = getAuthenticationPlatform().getAccessToken();
    const offset = new Date().getTimezoneOffset();
    return {
        Authorization: `bearer ${token}`,
        timeZoneOffset: offset,
    };
};