import React, { useState } from 'react';
import { ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { Button } from 'components/v2/components';
import { ContentEditor } from '../content-editor/content-editor';
import _ from 'lodash';
import { HrTag } from 'components/v2/components'
import { IFaqs, IQuestionAnswer } from "models/faqs-model";
import { newGuid } from 'utils/id-utils';
import { useTranslate } from 'translations/translation-utils';
import { BulmaColor } from 'enums/BulmaColor';
import { Help } from 'components/form';
import parse from 'html-react-parser';

interface Iprops {
    language: string;
    faqs: IFaqs;
    setIsEdit: (isEdit: boolean) => void;
    updateFaqs: (model: IFaqs) => void;
    setFaqs: (model: IFaqs) => void;
}

export const FaqsEdit: React.FC<Iprops> = ({ faqs, setFaqs }) => {
    const translate = useTranslate();
    const getNewFaqItem = () => {
        return {
            question: '',
            answer: '',
        } as IQuestionAnswer;
    };

    const [addNew, setAddNew] = useState(false);
    const [newFaq, setNewFaqs] = useState(getNewFaqItem());
    const [addNewIndex, setAddNewIndex] = useState(null);
    const [showError, setShowError] = useState(false);

    const onEditorStateChange = (editorContent, index, key, type, parentIndex) => {
        let updatedFaqs = _.cloneDeep(faqs);
        if (updatedFaqs.sections[parentIndex]) {
            if (index === updatedFaqs.sections[parentIndex].questionAnswers.length) {
                setNewFaqs({ ...newFaq, [key]: editorContent })
            }
            if (updatedFaqs.sections[parentIndex].questionAnswers[index]) {
                updatedFaqs.sections[parentIndex].questionAnswers[index][key] = editorContent;
                setFaqs(updatedFaqs)

            }
        } else if (type === "section") {
            updatedFaqs.sections[index][key] = editorContent;
            setFaqs(updatedFaqs)

        }

    };
    const getState = (content) => {
        const contentBlocks = htmlToDraft(content);
        return ContentState.createFromBlockArray(contentBlocks);
    }
    const addNewQuestion = (parentIndex) => {
        setAddNewIndex(parentIndex);
        setAddNew(true)
    }

    const addQuestion = (parentIndex) => {
        let updatedFaqs = _.cloneDeep(faqs);

        if (parse(newFaq.answer)[0] && 
            parse(newFaq.answer)[0].props.children &&
            (typeof(parse(newFaq.answer)[0].props.children) === 'object' || 
            parse(newFaq.answer)[0].props.children.trim() !== '') && 
            parse(newFaq.question)[0] && 
            parse(newFaq.question)[0].props.children &&
            (typeof(parse(newFaq.question)[0].props.children) === 'object' || 
            parse(newFaq.question)[0].props.children.trim() !== '')) {
                updatedFaqs.sections[parentIndex].questionAnswers.push((newFaq));
                setFaqs(updatedFaqs)
                setAddNew(false);
                setShowError(false);
        } else {
                setShowError(true)
        }
    }
    return (
        <>
            {
                faqs && faqs.sections.map((section, parentIndex) => (
                    <section>
                        <div className="tabs-container ">
                            <ContentEditor contentKey={"editor-section" + parentIndex}
                                editorState={getState(section.name)}
                                content={section.name}
                                wrapperClassName="demo-wrapper"
                                editorClassName="faqs-section-header"
                                onEditorStateChange={onEditorStateChange}
                                index={parentIndex}
                                fieldKey={"name"}
                                type="section"
                            />
                            {section.questionAnswers.map((faq, index) =>
                            (
                                <>
                                    <div className="faq-tabs" key={`faq-tabs-${parentIndex}-${index}`}>
                                        <div className="tab" key={`tab-${parentIndex}-${index}`}>
                                            <input className="tab-checkbox" type="checkbox" id={`chck-${parentIndex}-${index}`} defaultChecked />
                                            <ContentEditor contentKey={"editor-que" + newGuid()}
                                                editorState={getState(faq.question)}
                                                content={faq.question}
                                                wrapperClassName="demo-wrapper"
                                                editorClassName="tab-label"
                                                onEditorStateChange={onEditorStateChange}
                                                index={index}
                                                fieldKey={"question"}
                                                type="question"
                                                parentIndex={parentIndex}
                                            />

                                            <div className="tab-content" key={`tab-content-${parentIndex}-${index}`}>
                                                {

                                                    <ContentEditor contentKey={"editor-ans" + newGuid()}
                                                        editorState={getState(faq.answer)}
                                                        content={faq.answer}
                                                        wrapperClassName="demo-wrapper"
                                                        editorClassName="demo-editor"
                                                        onEditorStateChange={onEditorStateChange}
                                                        index={index}
                                                        fieldKey={"answer"}
                                                        type="answer"
                                                        parentIndex={parentIndex}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {addNew && index === section.questionAnswers.length - 1 && addNewIndex === parentIndex &&
                                        (
                                            <>
                                                <div className="faq-tabs" key={"faq-tabs" + index + 1}>
                                                    <div className="tab" key={"tab" + index + 1}>
                                                        <input className="tab-checkbox" type="checkbox" id={"chck" + index + 1} checked />
                                                        {
                                                            <ContentEditor contentKey={"editor-que" + index + 1}
                                                                content={null}
                                                                wrapperClassName="demo-wrapper"
                                                                editorClassName="tab-label"
                                                                onEditorStateChange={onEditorStateChange}
                                                                index={index + 1}
                                                                fieldKey={"question"}
                                                                type="question"
                                                                parentIndex={parentIndex}
                                                            />
                                                        }
                                                        <div className="tab-content" key={"tab-content" + index + 1}>
                                                            {

                                                                <ContentEditor contentKey={"editor-ans" + index + 1}
                                                                    content={null}
                                                                    wrapperClassName="demo-wrapper"
                                                                    editorClassName="demo-editor"
                                                                    onEditorStateChange={onEditorStateChange}
                                                                    index={index + 1}
                                                                    fieldKey={"answer"}
                                                                    type="answer"
                                                                    parentIndex={parentIndex}
                                                                />


                                                            }


                                                        </div>
                                                    </div>
                                                </div>
                                                <Help
                                                    isShown={showError}
                                                    bulmaColor={BulmaColor.Danger}
                                                >
                                                    {translate('Faqs.Error.PleaseEnterQuestionandAnswer')}
                                                </Help>
                                                <div className="btn-float-right">
                                                    <Button
                                                        id="add question"
                                                        buttonType="save"
                                                        onClick={() => addQuestion(parentIndex)}
                                                    >
                                                        {translate('Faqs.Label.SaveQuestion')}
                                                    </Button>
                                                </div>

                                            </>
                                        )}
                                </>
                            )
                            )}
                            <HrTag
                                onClick={() => addNewQuestion(parentIndex)}
                                tooltipTitle={translate('Faqs.Label.AddNewQuestion')}
                            />
                        </div>
                    </section>
                ))
            }
        </>
    );
};
