import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import actions from './actions';
import selector from './selector';
import { SiteRelationshipsPage as View } from './site-relationships-page';

export const SiteRelationshipsPage = withRouter(connect(
    selector,
    actions
)(View));
