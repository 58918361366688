import * as React from 'react';
import { EmbeddedItemList } from 'components/embedded-items-list/embedded-item-list';
import { IEmbeddedLink } from 'models/embeddedLink';
import { DateTime, Format } from '../../../../components/dateTime';
import { GridSortLink } from '../../../../components/gridSortLink';
import { Level, LevelIndicator } from '../../../../components/levelIndicator';
import { Loader } from '../../../../components/loader';
import { ScaleView, ScaleViewType } from '../../../../components/scaleView';
import {
    Timeline,
    TimelineHeader,
    TimelineHeaderGridValue,
    TimelineHeaderGridValueCollection,
    TimelineItem,
    TimelineItemGroup,
} from '../../../../components/timeline';
import { SortDirection } from '../../../../enums/SortDirection';
import { IRisk, RiskLevelIdSortable } from '../../../../models/risk';
import { IUpsertRiskRequest } from '../../../../models/upsert-risk-request';
import { RiskAddAttachmentModal } from '../riskAddAttachmentModal';
import { RiskAddLinkedActionModal } from '../riskAddLinkedActionModal';
import { RiskAttachmentCollection } from '../riskAttachments';
import { RiskAuditHistory } from '../riskAuditHistory';
import { RiskDeleteConfirmation } from '../riskDeleteConfirmation';
import { RiskLinkedActions } from '../riskLinkedActions';
import { RiskLinkedBuildings } from '../riskLinkedBuildings';
import './risk-list.scss';
import { Button } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';
import { MaterialIconType } from 'routes/material-icon/material-icon-type';
import { MaterialIcons } from 'routes/material-icon/material-icon';

export interface IRiskListStateProps {
    canEditRisk: boolean;
    canDeleteRisk: boolean;
    items: IRisk[];
    isLoading: boolean;
    sortColumn: string;
    sortAscending: boolean;
    secondarySortColumn: string;
    secondarySortAscending: boolean;
    expandedItems: string[];
    deleteConfirmationItem: IRisk;
}

export interface IRiskListDispatchProps {
    onSort: (sortColumn: string, sortAscending: boolean) => void;
    onToggleExpanded: (id: string, siteId: string) => void;
    onOpenEditRiskModal: (risk: IUpsertRiskRequest) => void;
    onDeleteRisk: (risk: IRisk) => void;
    remove?: (id: string) => void;
}

interface IRiskListProps extends IRiskListStateProps, IRiskListDispatchProps { }

const getRiskLevel = (risk: IRisk) => {
    switch (risk.riskLevelIdSortable) {
        case RiskLevelIdSortable.Low:
            return Level.Low;
        case RiskLevelIdSortable.Medium:
            return Level.Medium;
        case RiskLevelIdSortable.High:
            return Level.High;
    }
};

const getFullName = (item: IEmbeddedLink) => {
    if (item === null || item === undefined) {
        return '';
    }
    if (item.items === null || item.items === undefined) {
        return '';
    }
    if (item.items.fullName === null || item.items.fullName === undefined) {
        return '';
    }
    return item.items.fullName;
};

const getRiskItem = (
    risk: IRisk,
    canEditRisk: boolean,
    isToggleExpanded: boolean,
    onToggleExpanded: () => void,
    onOpenEditRiskModal: () => void,
    translate: (key: string, concatKey?: string) => any,
    remove?: () => void
) => {
    return (
        <Timeline expanded={isToggleExpanded} key={`${risk.siteId}__${risk.id}`}>
            <TimelineHeader
                centered={true}
                expanded={isToggleExpanded}
                onToggleExpanded={onToggleExpanded}
            >
                <TimelineHeaderGridValueCollection>
                    <TimelineHeaderGridValue className="timeline-title column is-4">
                        {risk.title}
                    </TimelineHeaderGridValue>
                    <TimelineHeaderGridValue
                        className="column is-2"
                        label={translate('IncidentRiskActionsModel.Label.Category')}
                    >
                        {translate('Globals.Category.', risk.riskCategory)}
                    </TimelineHeaderGridValue>
                    <TimelineHeaderGridValue
                        className="column is-2"
                        label={translate('IncidentRiskActionsModel.Label.SubCategory')}
                    >
                        {risk.riskSubCategory}
                    </TimelineHeaderGridValue>

                    <TimelineHeaderGridValue
                        label={translate('IncidentRiskActionsModel.Label.Status')}
                    >
                        {translate('Globals.Status.', risk.riskStatus)}
                    </TimelineHeaderGridValue>

                    <TimelineHeaderGridValue label={translate('IncidentRiskActions.Label.Review')}>
                        <DateTime value={risk.reviewDate} format={Format.DateOnly} />
                    </TimelineHeaderGridValue>

                    <TimelineHeaderGridValue
                        className="column is-2"
                        label={translate('IncidentRiskActions.Label.Level')}
                    >
                        <LevelIndicator
                            level={getRiskLevel(risk)}
                            text={translate('RiskDetailsPage.RiskLevel.value.', risk.riskLevel)}
                            value={risk.riskLevelScore}
                        />
                    </TimelineHeaderGridValue>
                    {remove && (
                        <TimelineHeaderGridValue label="Action">
                            <label onClick={remove}>
                                < MaterialIcons type={MaterialIconType.Delete} />
                            </label>
                        </TimelineHeaderGridValue>
                    )}
                </TimelineHeaderGridValueCollection>
            </TimelineHeader>

            <TimelineItem>
                <div className="columns is-mobile">
                    <div className="column is-2-tablet grid__label">
                        {translate('IncidentRiskActionsModel.Label.FullTitle')}
                    </div>
                    <div className="column is-6-tablet">{risk.title}</div>
                    <div className="column is-4-tablet">
                        <div className="columns is-multiline is-mobile">
                            <div className="column is-12-mobile is-4-tablet">
                                {canEditRisk && (
                                    <Button onClick={onOpenEditRiskModal}>
                                        {translate('IncidentRiskActions.Button.Edit')}
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </TimelineItem>

            <TimelineItem>
                <div className="columns">
                    <div className="column is-2 grid__label">
                        {translate('IncidentRiskActions.Label.Description')}
                    </div>
                    <div className="column">{risk.description}</div>
                </div>
            </TimelineItem>

            <TimelineItemGroup>
                <TimelineItem>
                    <div className="columns">
                        <div className="column">
                            <span className="grid__label">
                                {translate('IncidentRiskActions.Label.Likelihood')}
                            </span>
                            <ScaleView
                                type={ScaleViewType.Five}
                                value={Number(risk.likelihoodId)}
                            />
                        </div>
                    </div>
                </TimelineItem>

                <TimelineItem>
                    <div className="columns">
                        <div className="column">
                            <span className="grid__label">
                                {translate('IncidentRiskActions.Label.Impact')}
                            </span>
                            <ScaleView type={ScaleViewType.Five} value={Number(risk.impactId)} />
                        </div>
                    </div>
                </TimelineItem>

                <TimelineItem>
                    <div className="columns">
                        <div className="column">
                            <span className="grid__label">
                                {translate('IncidentRiskActions.Label.Level')}
                            </span>
                            <LevelIndicator
                                level={getRiskLevel(risk)}
                                text={risk.riskLevel}
                                value={risk.riskLevelScore}
                            />
                        </div>
                    </div>
                </TimelineItem>
            </TimelineItemGroup>

            <TimelineItem>
                <div className="columns">
                    <div className="column is-2 grid__label">
                        {translate('IncidentRiskActions.Label.Assignee')}
                    </div>
                    <div className="column">{getFullName(risk.assignee)}</div>
                </div>
            </TimelineItem>

            <TimelineItem>
                <div className="columns">
                    <div className="column is-2 grid__label">
                        {translate('IncidentRiskActions.Label.Modified')}
                    </div>
                    <div className="column is-2">
                        <DateTime value={risk.lastUpdatedDateTime} format={Format.DateOnly} />
                    </div>
                    <div className="column">
                        <DateTime value={risk.lastUpdatedDateTime} format={Format.Relative} />
                    </div>
                </div>
            </TimelineItem>

            <TimelineItem>
                <RiskAttachmentCollection
                    item={risk}
                    title={
                        'Attachments (' +
                        (risk.linkedAttachmentsCount ? risk.linkedAttachmentsCount : 'none') +
                        ')'
                    }
                />
                <RiskAddAttachmentModal riskId={risk.id} siteId={risk.siteId} />
            </TimelineItem>

            <TimelineItem>
                <RiskLinkedActions
                    item={risk}
                    title={
                        'Actions (' +
                        (risk.linkedActionsCount ? risk.linkedActionsCount : '0') +
                        ' actions, ' +
                        risk.linkedActionsCountDueIn7Days +
                        ' due within 7 days)'
                    }
                />
                <RiskAddLinkedActionModal riskId={risk.id} siteId={risk.siteId} />
            </TimelineItem>

            <TimelineItem>
                <RiskLinkedBuildings
                    item={risk}
                    title={
                        'Buildings (' +
                        (risk.linkedBuildingsCount ? risk.linkedBuildingsCount : 'none' + ')')
                    }
                />
            </TimelineItem>

            <TimelineItem>
                <RiskAuditHistory
                    item={risk}
                    title={translate('IncidentRiskActions.Title.History')}
                />
            </TimelineItem>
            <TimelineItem>
                <EmbeddedItemList
                    link={'/CermAssessment'}
                    items={risk.parents}
                    title={translate('IncidentRiskActions.Title.Parent')}
                />
            </TimelineItem>
        </Timeline>
    );
};

const getGridHeader = (label: string, fieldName: string, props: IRiskListProps) => {
    if (props.onSort) {
        const onSort = (sortField: string, sortDirection: SortDirection) =>
            props.onSort(sortField, sortDirection === SortDirection.Ascending);

        return (
            <GridSortLink
                currentSortFieldName={props.sortColumn}
                currentSortDirection={
                    props.sortAscending ? SortDirection.Ascending : SortDirection.Descending
                }
                sortFieldName={fieldName}
                onSort={onSort}
            >
                {label}
            </GridSortLink>
        );
    } else {
        return <label>{label}</label>;
    }
};

export const mapRiskToUpsertModel = (risk: IRisk) => {
    const upsertRequest: IUpsertRiskRequest = {
        id: risk.id,
        site: risk.site,
        siteId: risk.siteId,
        title: risk.title || '',
        description: risk.description || '',
        likelihoodId: risk.likelihoodId || '',
        impactId: risk.impactId || '',
        riskCategoryId: risk.riskCategoryId || '',
        riskSubCategoryId: risk.riskSubCategoryId || '',
        riskStatusId: risk.riskStatusId || '',
        riskAssigneeId: risk.assigneeId || '',
        reviewDate: risk.reviewDate || '',
        eTag: risk.eTag,
        origin: risk.origin || '',
    };

    return upsertRequest;
};

export const RiskList: React.FC<IRiskListProps> = (props) => {
    const translate = useTranslate();
    return (
        <Loader className="RiskList" loading={props.isLoading}>
            {props.items && props.items.length > 0 ? (
                <React.Fragment>
                    <div
                        className={`Timeline-offset grid-headers columns ${props.isLoading ? 'is-loading' : ''
                            }`}
                    >
                        <div className="column is-4">
                            {getGridHeader(translate('IncidentList.Header.Title'), 'title', props)}
                        </div>
                        <div className="column is-2">
                            {getGridHeader(
                                translate('IncidentRiskActionsModel.Label.Category'),
                                'riskCategory',
                                props
                            )}
                        </div>
                        <div className="column is-2">
                            {getGridHeader(
                                translate('IncidentRiskActionsModel.Label.SubCategory'),
                                'riskSubCategory',
                                props
                            )}
                        </div>
                        <div className="column">
                            {getGridHeader(
                                translate('IncidentRiskActionsModel.Label.Status'),
                                'riskStatus',
                                props
                            )}
                        </div>
                        <div className="column">
                            {getGridHeader(
                                translate('IncidentRiskActions.Label.Review'),
                                'reviewDate',
                                props
                            )}
                        </div>
                        <div className="column is-2">
                            {getGridHeader(
                                translate('IncidentRiskActions.Label.Level'),
                                'riskLevelScore',
                                props
                            )}
                        </div>
                        {props.remove && (
                            <div className="column is-1">
                                {getGridHeader(
                                    translate('IncidentRiskActions.Label.Action'),
                                    'action',
                                    props
                                )}
                            </div>
                        )}
                    </div>

                    {props.items &&
                        props.items.map((risk) => {
                            return getRiskItem(
                                risk,
                                props.canEditRisk,
                                props.expandedItems.includes(risk.id),
                                () => props.onToggleExpanded(risk.id, risk.siteId),
                                () => props.onOpenEditRiskModal(mapRiskToUpsertModel(risk)),
                                translate,
                                () => props.remove(risk.id)
                            );
                        })}
                </React.Fragment>
            ) : (
                <p> {translate('IncidentRiskActions.Label.NoItemsFound')} </p>
            )}
            <RiskDeleteConfirmation
                confirmMessage={translate('IncidentRiskActions.Risk.confirmMessage')}
                itemForConfirmation={props.deleteConfirmationItem}
            />
        </Loader>
    );
};
