import * as React from 'react';
import { Control, Input } from '../form';
import { Tag } from '../tag';
import { Button } from '../v2/components';
import useTranslate from 'translations/translation-utils';

export interface IStateProps {
    keywordInput?: string;
    keywords: string[];
}

export interface IActionProps {
    onAddKeyword: (keyword: string) => void;
    onRemoveKeyword: (keyword: string) => void;
}

interface IProps extends IStateProps, IActionProps {}

export const KeywordFilterSet: React.FC<IProps> = (props) => {
const translate = useTranslate();
    const onAddKeyword: React.MouseEventHandler<HTMLFormElement> = (e) => {
        // KEVIN HALP - should this be put through reducer on keyup?
        e.preventDefault();
        const container = e.currentTarget.parentElement.parentElement.parentElement;
        const input = container.getElementsByClassName(
            'filter-keyword-text'
        )[0] as HTMLInputElement;
        props.onAddKeyword(input.value);
        input.value = '';
        // Think we need something like
        // https://stackoverflow.com/questions/35472283/read-input-in-a-functional-stateless-component
    };

    const onRemove = (keyword: string) => {
        props.onRemoveKeyword(keyword);
    };

    return (
        <div className="filter-fields filter-fields-keywords">
            <form onSubmit={onAddKeyword}>
                <Control>
                    <Input
                        id="enterKeyword"
                        type="search"
                        className="is-medium filter-keyword-text"
                        placeholder={translate('RiskRegisterPage.RiskFilter.Label.EnterKeyword')}
                        value={props.keywordInput}
                    />
                    <Button
                        id="addKeyword"
                        type="submit"
                    >
                        {translate('RiskRegisterPage.RiskFilter.Label.Add')}
                    </Button>
                </Control>
            </form>
            {props.keywords.length > 0 && (
                <div className="tags">
                    {props.keywords.map((keyword, i) => (
                        <Tag onDelete={onRemove.bind(this, keyword)} key={`tag-${i}`}>
                            {keyword}
                        </Tag>
                    ))}
                </div>
            )}
        </div>
    );
};

KeywordFilterSet.defaultProps = {
    keywords: []
};
