import { connect } from 'react-redux';

import { DeleteLinkConfirmation as View } from '../../common/delete-link-confirmation';
import actions from './actions';
import selector from './selector';

export const BuildingLinkDeleteConfirmation = connect(
    selector,
    actions
)(View);
