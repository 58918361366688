import classnames from 'classnames';
import * as React from 'react';

import './timeline-header-grid-value.scss';

export interface IProps {
    label?: string;
    className?: string;
}

const baseClassName = 'TimelineHeaderGridValue';

export const TimelineHeaderGridValue: React.FC<React.PropsWithChildren<IProps>> = ({ label, children, className }) => (
    <div className={classnames(baseClassName, className)}>
        <div className={`${baseClassName}__content`}>
            {label && (<div className={`${baseClassName}__label`}>{label}:</div>)}

            <div className={`${baseClassName}__value`}>
                {children}
            </div>
        </div>
    </div>
);
