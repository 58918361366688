import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { loadMasterProcessSteps } from 'actions/actions-v2/master-process-step-action-v2';
import {
    loadProcessStepAnswer,
    loadProcessStepOutcome,
    setProcessStepAnswer,
    loadCermAssessmentResult,
} from 'actions/actions-v2/cerm-assessment-action-v3';
import { CermAssessmentProcessStepOutcomePage } from './cerm-assessment-process-step-outcome-page';
import {
    loadAttachmentsOutcome,
    uploadAttachmentOutcome,
} from 'actions/actions-v2/attachment-action-v2';
import { getPermissions } from 'sharedSelectors';
import { loadCermAssessmentExpertReview } from 'actions/actions-v2/cerm-assessment-action-v3';

const getIsLoading = (state: IRootState) =>
    state.masterProcessStepState.isLoading || state.cermAssessmentResultState.isLoading;
const getSiteId = (state: IRootState) => state.app.siteId;
const getMasterProcessSteps = (state: IRootState) =>
    state.masterProcessStepState.masterProcessSteps;
const getProcessStepAnswer = (state: IRootState) =>
    state.cermAssessmentResultState.processStepAnswer;
const getAttachments = (state: IRootState) => state.cermAssessmentResultState.attachments;
const getCermAssessmentResult = (state: IRootState) =>
    state.cermAssessmentResultState.cermAssessmentResult;
const getLookupSites = (state: IRootState) => state.app.lookupSites;
const getExpertReviewStatus = (state: IRootState) =>
    state.cermAssessmentResultState.expertReviewStatus;
const getOperatingPlatforms = (state: IRootState) => state.operatingPlatformState.operatingPlatforms;

const mapStateToProps = createSelector(
    getIsLoading,
    getSiteId,
    getMasterProcessSteps,
    getProcessStepAnswer,
    getAttachments,
    getCermAssessmentResult,
    getLookupSites,
    getPermissions,
    getExpertReviewStatus,
    getOperatingPlatforms,
    (
        isLoading,
        siteId,
        masterProcessSteps,
        processStepAnswer,
        attachments,
        cermAssessmentResult,
        lookupSites,
        permissions,
        expertReviewStatus,
        allOperatingPlatforms
    ) => ({
        isLoading,
        siteId,
        masterProcessSteps,
        processStepAnswer,
        attachments,
        cermAssessmentResult,
        lookupSites,
        permissions,
        expertReviewStatus,
        allOperatingPlatforms
    })
);

const mapDispatchToProps = {
    loadMasterProcessSteps,
    loadProcessStepAnswer,
    loadProcessStepOutcome,
    setProcessStepAnswer,
    loadCermAssessmentResult,
    loadAttachments: loadAttachmentsOutcome,
    uploadAttachment: uploadAttachmentOutcome,
    loadCermAssessmentExpertReview,
};
export const CermAssessmentProcessStepOutcomePageContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CermAssessmentProcessStepOutcomePage)
);
