import * as React from 'react';
import Page from 'components/v2/page/Page';
import { sumBy, isEmpty } from 'lodash';
import { RouteComponentProps } from 'react-router';
import { IMasterProcessStep } from 'models/master-process-step/master-process-step';
import { Table, ToggleButton } from 'components/v2/components';
import Select from 'react-select';
import { Loader } from 'components/loader';
import { IKeyValue } from 'models';
import { ICermAssessmentSnapshot, IProcessStepSnapshot } from 'models/cerm/cerm-assessment-snapshot';
import { GetScorePercentage } from 'utils/decimal-utils';
import { CermAssessmentSnapshotProcessStepRow } from './shared/CermAssessmentSnapshotProcessStepRow';
import { ISnapshotFilters, ISnapshotExportFilters } from 'models/snapshot-filters';
import { CermAssessmentComparisonToolbar } from './shared/CermAssessmentComparisonToolbar';
import { getSiteOperatingPlatform } from 'models/site-profile';
import { ICommonLookupModel } from 'models/common-lookup-model';
import useTranslate from 'translations/translation-utils';
import { IOperatingPlatform } from 'models/operating-platform-model';
import { getTitlePrefixGeneric } from 'models/cerm/cerm-assessment-result';
import { Panel } from 'components/panel';


interface IParams {
    siteId: string;
}

interface IProps extends RouteComponentProps<IParams> {
    isLoading: boolean;
    isExporting: boolean;
    siteId: string;
    pillars: ICommonLookupModel[];
    masterProcessSteps: IMasterProcessStep[];
    snapshots: ICermAssessmentSnapshot[];
    onSiteChange: (siteId: string) => void;
    loadMasterProcessSteps: (operatingPlatform: string) => void;
    loadSnapshotsFullResults: (filters: ISnapshotFilters) => void;
    exportSnapshotsComparison: (filters: ISnapshotExportFilters) => void;
    lookupSites: IKeyValue<string>[];
    allOperatingPlatforms: IOperatingPlatform[];
    loadCermSnapshotsOverviewOnly: (siteId: string) => void;
    snapshotsFullResult: ICermAssessmentSnapshot[];
}

export const CermAssessmentComparisonPage: React.FC<IProps> = ({
    match,
    isLoading,
    isExporting,
    pillars,
    masterProcessSteps,
    snapshots,
    onSiteChange,
    loadMasterProcessSteps,
    loadSnapshotsFullResults,
    exportSnapshotsComparison,
    lookupSites,
    allOperatingPlatforms,
    loadCermSnapshotsOverviewOnly,
    snapshotsFullResult
}) => {
    const translate = useTranslate();
    const siteId = match.params.siteId;
    const cermAssessmentPageUrl = '/CermAssessment/';
    const operatingPlatform: string = getSiteOperatingPlatform(siteId, lookupSites);
    const titlePrefix = getTitlePrefixGeneric(operatingPlatform, allOperatingPlatforms);
    const customLinks: Array<IKeyValue<string>> = [
        { key: cermAssessmentPageUrl, value: `${titlePrefix} Assessment` },
    ];
    const [visibleItems, setVisibleItems] = React.useState<number[]>([]);
    const [selectedLeft, setSelectedLeft] = React.useState<IKeyValue<string>>(null);
    const [selectedRight, setSelectedRight] = React.useState<IKeyValue<string>>(null);
    const [currentSnapsshot, setCurrentSnapsshot] = React.useState<ICermAssessmentSnapshot>(null);

    React.useEffect(() => {
        if (siteId) {
            loadCermSnapshotsOverviewOnly(siteId);
        }
    }, [loadCermSnapshotsOverviewOnly, siteId]);

    React.useEffect(() => {
        if (snapshots && snapshots.length > 0) {
            const currentSnapsshot = snapshots.find(x => x.dateLabel == 'Current');
            setCurrentSnapsshot(currentSnapsshot);
        }
    }, [snapshots, snapshots.length]);

    React.useEffect(() => {
        if (match.params.siteId) {
            onSiteChange(match.params.siteId);
        }
    }, [onSiteChange]);

    React.useEffect(() => {
        if (getSiteOperatingPlatform(siteId, lookupSites) !== '') {
            loadMasterProcessSteps(getSiteOperatingPlatform(siteId, lookupSites));
        }
    }, [getSiteOperatingPlatform(siteId, lookupSites)]);

    React.useEffect(() => {
        const snapshotId: string[] = [];
        if (currentSnapsshot) {
            snapshotId.push(currentSnapsshot.id);
        }
        if (selectedLeft) {
            snapshotId.push(selectedLeft.value);
        }
        if (selectedRight) {
            snapshotId.push(selectedRight.value);
        }
        if (match.params.siteId && snapshotId.length > 0) {
            loadSnapshotsFullResults({
                siteId: match.params.siteId,
                ids: snapshotId,
            } as ISnapshotFilters);
        }
    }, [loadSnapshotsFullResults, currentSnapsshot, selectedLeft, selectedRight, match.params.siteId]);

    const getAvailablePillars = () => {
        return !pillars || !masterProcessSteps
            ? []
            : pillars.filter((p) => masterProcessSteps.some((s) => s.pillar === p.value) && p.operatingPlatform === operatingPlatform);
    }

    const getPillarScore = (pillar: string, snapshot: ICermAssessmentSnapshot) => {
        const result = 'N/A';
        if (!snapshot || isEmpty(snapshot.processSteps)) {
            return result;
        }

        const steps = snapshot.processSteps.filter(
            (step) =>
                step.pillar === pillar &&
                step.applicable === true &&
                masterProcessSteps.some((s) => step.id === s.id)
        );
        const totalSteps = steps.length;
        if (totalSteps < 1) {
            return result;
        }

        const totalCompliances = sumBy(steps, (s) => s.compliance);

        return GetScorePercentage(totalCompliances / totalSteps);
    };

    //const backToPage = () => history.push(cermAssessmentPageUrl);

    const getProcessStep = (snapshotIndex: number, processStepId: string): IProcessStepSnapshot => {
        if (snapshotIndex < 0 || !snapshotsFullResult || snapshotsFullResult[snapshotIndex].processSteps.length === 0) {
            return null;
        }

        return snapshotsFullResult[snapshotIndex].processSteps.find((step) => step.id === processStepId);
    };
    const getSnapshotsIndex = (snapshotId: string): number => {
        return snapshotsFullResult && snapshotsFullResult.findIndex(x => x.id === snapshotId);
    }
    const getPillarRows = (pillar: string, index: number): React.JSX.Element => {
        const pillarMasterProcessSteps = masterProcessSteps.filter(
            (step) => step.pillar === pillar
        );
        const processStepRows = pillarMasterProcessSteps.map((masterProcessStep, key) => (
            <CermAssessmentSnapshotProcessStepRow
                key={key}
                masterProcessStep={masterProcessStep}
                processStepCurrent={getProcessStep(getSnapshotsIndex(currentSnapsshot && currentSnapsshot.id), masterProcessStep.id)}
                processStepLeft={getProcessStep(getSnapshotsIndex(selectedLeft && selectedLeft.value), masterProcessStep.id)}
                processStepRight={getProcessStep(getSnapshotsIndex(selectedRight && selectedRight.value), masterProcessStep.id)}
            />
        ));

        return (
            <>
                <Table>
                    <thead>
                        <tr className='columns'>
                            <th className='column is-1 width-3'></th>
                            <th className='column is-1'>{titlePrefix}</th>
                            <th className='column is-4 width-40'>{titlePrefix} {translate('CermAssessment.Lable.2')}</th>
                            <th className='column is-2'>{translate('Cerm.ComparisonPanel.Labels.Current')} {getPillarScore(pillar, snapshotsFullResult[getSnapshotsIndex(currentSnapsshot && currentSnapsshot.id)])}</th>
                            <th className='column is-2'>{translate('Cerm.ComplianceScore.Labels.Compliance')} {getPillarScore(pillar, snapshotsFullResult[getSnapshotsIndex(selectedLeft && selectedLeft.value)])}</th>
                            <th className='column is-2'>{translate('Cerm.ComplianceScore.Labels.Compliance')} {getPillarScore(pillar, snapshotsFullResult[getSnapshotsIndex(selectedRight && selectedRight.value)])}</th>
                        </tr>
                    </thead>
                    <tbody key={index}>
                        {processStepRows}
                    </tbody>
                </Table>

            </>
        );
    };
    const getAccordian = (pillar: string, index: number): React.JSX.Element => {

        return (
            <>
                <Panel key={index} className='pillar-accordian'>
                    <header>
                        <ToggleButton
                            buttonType="arrow"
                            isEnabled={!(visibleItems.indexOf(index) > -1)}
                            onClick={() => toggleItem(index)}
                            className='comparison-toggle-arrow'
                        />
                        <h2 className="title is-4">{pillar}</h2>
                    </header>
                    {visibleItems.indexOf(index) > -1 &&
                        getPillarRows(pillar, index)
                    }
                </Panel>
            </>
        );
    }
    const onExport = (includeMeta: boolean): void => {
        const snapshotId: string[] = [
            currentSnapsshot ? currentSnapsshot.id : '',
            selectedLeft ? selectedLeft.value : '',
            selectedRight ? selectedRight.value : ''
        ];
        exportSnapshotsComparison({ siteId, ids: snapshotId, includeMeta, operatingPlatform: operatingPlatform });
    };
    const getOptions = (theOtherSelectedItem: any) => {
        const selectedItem = theOtherSelectedItem ? theOtherSelectedItem.value : null;
        return snapshots
            .filter((snapshot) => snapshot.id !== selectedItem)
            .map((snapshot) => {
                let label = `${snapshot.dateLabel} - ${GetScorePercentage(snapshot.score)}`;
                if (snapshot.isExpertReview) {
                    label = `${snapshot.dateLabel} (E) - ${GetScorePercentage(snapshot.score)}`;
                }

                return {
                    value: snapshot.id,
                    label
                };
            });
    };
    const getComparisonControl = (
        selectedItem: IKeyValue<string>,
        setSelectedItem: React.Dispatch<any>,
        theOtherSelectedValue: IKeyValue<string>
    ) => {
        return (
            <div className="column column-compare">
                <Select
                    isMulti={false}
                    classNamePrefix="dropdown-multi"
                    placeholder={translate('Cerm.ComparisonPanel.Labels.SelectAssessment')}
                    value={selectedItem}
                    onChange={(e) => setSelectedItem(e)}
                    options={getOptions(theOtherSelectedValue)}
                />
            </div>
        );
    };

    const toggleItem = (itemId: number): void => {
        if (visibleItems.indexOf(itemId) > -1) {
            setVisibleItems(visibleItems.filter((item) => item !== itemId));
        } else {
            setVisibleItems([...visibleItems, itemId]);
        }
    };

    return (
        <Page
            className="cerm-assessment-comparison-page"
            title={`${translate('CermAssessment.Lable.1')} ${titlePrefix} ${translate('SideNavBar.Labels.Assessment')}`}
            redirectOnSiteChange={true}
            redirectOnSiteChangeUrl={cermAssessmentPageUrl}
            breadcrumbCustomLinks={customLinks}
        >
            <div className='columns'>
                <div className='column is-6'><h2 className='title'> {titlePrefix} {translate('CermAssessment.page.Lable')}</h2></div>
                <div className='column is-6'><CermAssessmentComparisonToolbar isExporting={isExporting} onExport={onExport} className={'comparison-toolbar'} /></div>
            </div>

            {!isEmpty(masterProcessSteps) && !isEmpty(snapshots) && (
                <Loader loading={isLoading}>
                    <div className='columns comparison-page-header'>
                        <div className='column is-6'>
                        </div>
                        <div className='column is-2 current-compliance-label'>
                            {currentSnapsshot && currentSnapsshot.dateLabel} {translate('Cerm.ComplianceScore.Labels.Compliance')} - {GetScorePercentage(currentSnapsshot && currentSnapsshot.score)}
                        </div>
                        <div className='column is-2'>
                            {getComparisonControl(selectedLeft, setSelectedLeft, selectedRight)}
                        </div>
                        <div className='column is-2 padding-left-0'>
                            {getComparisonControl(selectedRight, setSelectedRight, selectedLeft)}
                        </div>
                    </div>
                    <div>
                        {getAvailablePillars().map((pillar, key) => getAccordian(pillar.value, key))}
                    </div>
                </Loader>
            )}
        </Page>
    );
};

