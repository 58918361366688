import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import selectors from './selectors';
import actions from './actions';
import { IRisk } from 'models/risk';
import { ButtonWithConfirmDialog, TabGroup, TabItem } from 'components/v2/components';
import { Loader } from 'components/loader';
import { ClientRiskDetailsTab } from './tabs/client-risk-details-tab';
import ClientHistoryTab from './tabs/client-risk-history-tab';
import ClientApproval from './client-approval/approval';
import { Alert } from 'components/v2/alert/Alert';
import Page from 'components/v2/page/Page';
import { IKeyValue } from 'models';
import * as RiskStatus from 'models/risk-statuses';
import { getUserId, isClientProxy } from 'helpers/helpers';
import { FileUploadModal } from 'components/fileUploadModal';
import { IAttachmentUpload } from 'models/attachment/attachment-upload';
import { IRiskApproval } from 'models/risk-approval';
import { IRiskClientRequestAmendment } from 'models/risk-client-request-amendment';
import ClientRiskDocumentsTab from './tabs/client-risk-documents-tab';
import { IAttachmentQuery } from 'models/attachment/attachment-query';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import { Entity } from 'models/entity';
import useTranslate from 'translations/translation-utils';

interface IProps extends RouteComponentProps<IParams> {
    siteId: string;
    isLoading?: boolean;
    risk: IRisk;
    loadRisk: (id: string, siteId: string) => void;
    onSiteChange: (site: string) => void;
    clearForm: () => void;
    isClient: boolean;
    attachments: IAttachmentModel[];
    clientApprovalRisk: (riskApproval: IRiskApproval) => void;
    uploadAttachmentRisk: (upload: IAttachmentUpload) => void;
    cleanupAttachment: () => void;
    isUploading: boolean;
    uploaded: boolean;
    clientRequestAmendment: (requestAmendment: IRiskClientRequestAmendment) => void;
    loadAttachments: (query: IAttachmentQuery) => void;
}

interface IParams {
    id: string;
    tab: string;
    siteId?: string;
}

enum Tab {
    details = 'details',
    actions = 'actions',
    documents = 'documents',
    history = 'history',
}

const ClientRiskRegisterPage: React.FC<IProps> = props => {
    const translate = useTranslate();
    const [isFileUploadDialogueVisible, setIsFileUploadDialogueVisible] = React.useState(false);
    const [riskStatus, setRiskStatus] = React.useState(null);

    const riskId = props.match.params.id;
    const currentTab = props.match.params.tab.toLowerCase();
    const risksClientPageUrl = '/RiskRegisterClient';
    const customLinks: Array<IKeyValue<string>> = [
        { key: risksClientPageUrl, value: translate('ClientRiskRegister.Label.1') },
    ];

    React.useEffect(() => {
        if (props.uploaded) {
            onApproval(riskStatus);
            setIsFileUploadDialogueVisible(false);
        }

        return () => {
            props.cleanupAttachment();
        };
    }, [props.uploaded]);

    React.useEffect(() => {
        if (props.match.params.siteId) {
            props.onSiteChange(props.match.params.siteId);
        }

        return () => {
            props.clearForm();
        };
    }, []);

    React.useEffect(() => {
        if (!riskId || !props.siteId) {
            return;
        }
        props.loadRisk(riskId, props.siteId);
    }, [props.siteId, riskId]);

    React.useEffect(() => {
        if (!riskId || !props.siteId) {
            return;
        }
        props.loadAttachments({ entity: Entity.Risk, id: riskId, siteId: props.siteId });
    }, [props.siteId, riskId]);

    const setNavLink = (selectedTab: Tab) => {
        return props.match.path
            .replace(':id', riskId)
            .replace(':tab', selectedTab)
            .replace(':siteId?', '');
    };

    const renderTab = () => {
        switch (currentTab) {
            case Tab.details:
                return <ClientRiskDetailsTab risk={props.risk} />;
            case Tab.documents:
                return <ClientRiskDocumentsTab id={riskId} />;
            case Tab.history:
                return <ClientHistoryTab id={riskId} />;
        }
    };

    const riskUid = !!props.risk.uid ? `(${props.risk.uid}) - ` : ``;
    const title =
        riskId && props.risk.title ? `${riskUid}${props.risk.title}` : translate('ClientRiskRegister.Lable.1');

    const onApproval = (result: boolean) => {
        const riskApproval = {
            siteId: props.siteId,
            riskId: props.risk.id,
            status: result,
            isClient: props.isClient,
        };

        props.clientApprovalRisk(riskApproval);
    };

    const onRiskApproval = (status: boolean) => {
        if (isClientProxy(props.siteId)) {
            setRiskStatus(status);
            setIsFileUploadDialogueVisible(true);
        } else {
            onApproval(status);
        }
    };

    const getAcceptMitigateRiskAndRequestAmendmentButtons = () => {
        if (
            !!!props.risk.clientApproval &&
            ((props.risk.sharedWithClient &&
                isClientProxy(props.siteId) &&
                props.risk?.riskStatusId === RiskStatus.MITIGATION_PENDING_CLIENT_APPROVAL) ||
                (props.risk.sharedWithClient &&
                    props.risk.cbreApprovalToShare &&
                    props.risk.cbreApprovalToShare.status &&
                    (props.isClient ||
                        props.risk.clientOptionalApprovers.some(userId => userId === getUserId()))))
        ) {
            return (
                <>
                    <p>{translate('ClientRiskRegister.Label.6')}</p>
                    <br />
                    {props.risk.mitigationPlans
                        ?.filter(x => x.status !== RiskStatus.MITIGATION_COMPLETED)
                        .length > 0 && (
                            <ButtonWithConfirmDialog
                                buttonClassName="standout-workflow-button"
                                buttonText={translate('ClientRiskRegister.Accept.OpenMitigationPlan.ButtonText')}
                                buttonId="action"
                                buttonType="action"
                                title={translate('ClientRiskRegister.Accept.OpenMitigationPlan.Title')}
                                buttonConfirmText={translate('Globals.Label.Confirm')}
                                onConfirm={() => onRiskApproval(false)}
                                message={translate('ClientRiskRegister.Accept.OpenMitigationPlan.Message')}
                                isLoading={props.isLoading}
                            />
                        )}
                    {(props.risk.mitigationPlans.length === 0
                        || props.risk.mitigationPlans.every(x => x.status === RiskStatus.MITIGATION_COMPLETED)
                    ) && (
                            <ButtonWithConfirmDialog
                                buttonClassName="standout-workflow-button"
                                buttonText={translate('ClientRiskRegister.Acknowledge.CompletedMitigationPlans.ButtonText')}
                                buttonId="action"
                                buttonType="action"
                                title={translate('ClientRiskRegister.Acknowledge.CompletedMitigationPlans.Title')}
                                buttonConfirmText={translate('Globals.Label.Confirm')}
                                onConfirm={() => onRiskApproval(false)}
                                message={translate('ClientRiskRegister.Acknowledge.CompletedMitigationPlans.Message')}
                                isLoading={props.isLoading}
                            />
                        )}
                    {props.risk.mitigationPlans
                        ?.filter(x => x.status !== RiskStatus.MITIGATION_COMPLETED)
                        .length > 0 && (
                            <ButtonWithConfirmDialog
                                buttonClassName="standout-workflow-button"
                                buttonText={translate('ClientRiskRegister.Acknowledge.OpenMitigationPlans.ButtonText')}
                                title={translate('ClientRiskRegister.Acknowledge.OpenMitigationPlans.Title')}
                                buttonConfirmText={translate('Globals.Label.Confirm')}
                                onConfirm={() => onRiskApproval(true)}
                                message={translate('ClientRiskRegister.Acknowledge.OpenMitigationPlans.Message')}
                                isLoading={props.isLoading}
                            />
                        )}
                    <ButtonWithConfirmDialog
                        buttonClassName="standout-workflow-button"
                        buttonText={translate('ClientRiskRegister.RequestAmendments.ButtonText')}
                        title={translate('ClientRiskRegister.RequestAmendments.Title')}
                        buttonConfirmText={translate('ConfirmNavigation.Label.Confirm')}
                        onConfirm={() =>
                            props.clientRequestAmendment({
                                id: props.risk.id,
                                siteId: props.risk.siteId,
                            } as IRiskClientRequestAmendment)
                        }
                        message={translate('ClientRiskRegister.RequestAmendments.Message')}
                        isLoading={props.isLoading}
                    />
                </>
            );
        }
    };

    const onConfirm = (files: File[]) => {
        props.uploadAttachmentRisk({
            id:
                props.risk.mitigationPlans.length > 0
                    ? props.risk.mitigationPlans[0].id
                    : props.risk.id,
            entity: props.risk.mitigationPlans.length > 0 ? Entity.RiskMitigationPlan : Entity.Risk,
            siteId: props.siteId,
            files,
        });
    };

    const getFileUploadModel = () => {
        return (
            <>
                <FileUploadModal
                    visible={isFileUploadDialogueVisible}
                    allowMultiple={isClientProxy(props.siteId)}
                    uploading={props.isUploading}
                    onConfirm={onConfirm}
                    onClose={() => setIsFileUploadDialogueVisible(false)}
                    customMessage={translate('ClientRiskRegister.Lable.14')}
                />
            </>
        );
    };

    return (
        <Page
            title={title}
            breadcrumbCustomLinks={customLinks}
            className="risk-register-page"
            redirectOnSiteChange={true}
            redirectOnSiteChangeUrl={risksClientPageUrl}
        >
            <Loader loading={props.isLoading}>
                {props.risk && props.risk.id && !props.risk.isActive ? (
                    <Alert type="warning" title={translate('ClientRiskRegister.Lable.15')}>
                        <p>{translate('ClientRiskRegister.Lable.16')}</p>
                    </Alert>
                ) : (
                    <>
                        <ClientApproval
                            siteId={props.siteId}
                            riskId={props.risk?.id}
                            risk={props.risk}
                        />
                        <TabGroup>
                            <TabItem
                                title={translate('RiskRegisterUpsertPage.Tabs.Details')}
                                tab={Tab.details}
                                to={setNavLink(Tab.details)}
                                currentTab={currentTab}
                            />
                            {props.attachments &&
                                props.attachments.filter(x => x.id.includes(riskId)).length > 0 && (
                                    <TabItem
                                        title={translate('RiskRegisterUpsertPage.Tabs.Documents')}
                                        tab={Tab.documents}
                                        to={setNavLink(Tab.documents)}
                                        currentTab={currentTab}
                                    />
                                )}
                            <TabItem
                                title={translate('RiskRegisterUpsertPage.Tabs.History')}
                                tab={Tab.history}
                                currentTab={currentTab}
                                to={setNavLink(Tab.history)}
                            />
                        </TabGroup>
                        {renderTab()}
                        <div className="standout-workflow">
                            <br />
                            {getAcceptMitigateRiskAndRequestAmendmentButtons()}
                            {getFileUploadModel()}
                        </div>
                    </>
                )}
            </Loader>
        </Page>
    );
};

export default withRouter(connect(selectors, actions)(ClientRiskRegisterPage));
