import * as React from 'react';

import classNames from 'classnames';
import { BulmaSize } from '../../enums/BulmaSize';
import './Form.scss';

export interface IProps extends React.AllHTMLAttributes<HTMLDivElement> {
    label?: string;
    labelSize?: BulmaSize;
    isHorizontal?: boolean;
    infoText?: string;
}

export const Field: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const css = classNames([
        'field',
        {
            'is-horizontal': props.isHorizontal
        },
        props.className
    ]);

    const labelCss = classNames([
        {
            [BulmaSize.Small]: !props.labelSize,
            [BulmaSize.Normal]: props.labelSize === BulmaSize.Normal,
            //[BulmaSize.Small]: props.labelSize === BulmaSize.Small,
            [BulmaSize.Medium]: props.labelSize === BulmaSize.Medium,
            [BulmaSize.Large]: props.labelSize === BulmaSize.Large
        }
    ]);

    return (
        <div className={css}>
            {(props.isHorizontal && (
                <>
                    {props.label && (
                        <div className={'field-label ' + labelCss}>
                            <label className="label" htmlFor={props.htmlFor}>{props.label}</label>
                            {props.infoText &&
                                <label className="info-text">{props.infoText}</label>
                            }
                        </div>
                    )}
                    <div className="field-body">
                        {props.children}
                    </div>
                </>
            ))}

            {(!props.isHorizontal && (
                <>
                    {props.label && (
                        <label className={'label ' + labelCss} htmlFor={props.htmlFor}>{props.label}</label>
                    )}
                    {props.children}
                </>
            ))}
        </div>
    );
};
