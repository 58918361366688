import { IShiftsState } from 'state';
import { IAppAction } from 'actions/app-action';
import { ShiftActions } from 'actions/actions-v2/shift-actions';
import { combineReducers } from 'redux';

const INITIAL_STATE: IShiftsState = {
    shifts: null,
    shift: null,
    isLoading: false,
    isSaving: false,
    engineers: null
};

const shifts = (state: IShiftsState['shifts'] = INITIAL_STATE.shifts, action: IAppAction) => {
    switch (action.type) {
        case ShiftActions.LOAD_SHIFTS_FULFILLED:
            return action.payload;
        case ShiftActions.LOAD_SHIFTS:
            return null;
        default:
            return state;
    }
};

const shift = (state: IShiftsState['shift'] = INITIAL_STATE.shift, action: IAppAction) => {
    switch (action.type) {
        case ShiftActions.LOAD_SHIFT_FULFILLED:
            return action.payload;
        case ShiftActions.CREATE_SHIFT_FULFILLED:
        case ShiftActions.UPDATE_SHIFT_FULFILLED:
            return action.payload ? action.payload : state;
        case ShiftActions.LOAD_SHIFT:
        case ShiftActions.DELETE_SHIFT_FULFILLED:
        case ShiftActions.CANCEL_UPSERT_SHIFT:
            return null;

        default:
            return state;
    }
};

const engineers = (state: IShiftsState['engineers'] = INITIAL_STATE.engineers, action: IAppAction) => {
    switch (action.type) {
        case ShiftActions.LOAD_ENGINEERS_FULFILLED:
            return action.payload.engineers;

        default:
            return state;
    }
};

const isLoading = (
    state: IShiftsState['isLoading'] = INITIAL_STATE.isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case ShiftActions.LOAD_SHIFTS:
        case ShiftActions.LOAD_SHIFT:
            return true;

        case ShiftActions.LOAD_SHIFTS_FULFILLED:
        case ShiftActions.LOAD_SHIFTS_REJECTED:
        case ShiftActions.LOAD_SHIFT_FULFILLED:
        case ShiftActions.LOAD_SHIFT_REJECTED:
            return false;

        default:
            return state;
    }
};

const isSaving = (state: IShiftsState['isSaving'] = INITIAL_STATE.isSaving, action: IAppAction) => {
    switch (action.type) {
        case ShiftActions.CREATE_SHIFT:
        case ShiftActions.UPDATE_SHIFT:
        case ShiftActions.DELETE_SHIFT:
            return true;

        case ShiftActions.CREATE_SHIFT_FULFILLED:
        case ShiftActions.CREATE_SHIFT_REJECTED:
        case ShiftActions.UPDATE_SHIFT_FULFILLED:
        case ShiftActions.UPDATE_SHIFT_REJECTED:
        case ShiftActions.DELETE_SHIFT_FULFILLED:
        case ShiftActions.DELETE_SHIFT_REJECTED:
            return false;

        default:
            return state;
    }
};

export const shiftsReducer = combineReducers<IShiftsState>({
    shifts,
    shift,
    isLoading,
    isSaving,
    engineers
});
