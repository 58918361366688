import * as React from 'react';

import { CheckboxFilter, ICustomCheckboxFilters } from '../../../../../components/checkboxFilter';
import { CheckboxFilterBackgroundColor } from '../../../../../components/filterSet/CheckboxFilterSet';
import { SearchFilter } from '../../../../../components/searchFilter';
import { IKeyValue } from '../../../../../models/key-value';
import { IActionFilters } from 'models/action-filters';
import useTranslate from 'translations/translation-utils';
export interface IStateProps {
    filters: IActionFilters;
    expandedFilters: string[];

    lookupActionCategory: Array<IKeyValue<string>>;
    lookupActionPriority: Array<IKeyValue<string>>;
    lookupActionStatus: Array<IKeyValue<string>>;
    lookupActionSpecial: Array<IKeyValue<string>>;
    hideFilters?: string[];
}

export interface IDispatchProps {
    onFilter: (field: string, value: string) => void;
    onToggleFilterExpanded: (id: string) => void;
    onAddFilterKeyword: (keyword: string) => void;
    onRemoveFilterKeyword: (keyword: string) => void;
}

interface IProps extends IStateProps, IDispatchProps { }

export const ActionFilters: React.FC<IProps> = (props) => {
    const getUnreservedActionStatuses = (actionStatuses: Array<IKeyValue<string>>) => {
        return actionStatuses.filter((status) => status.key !== 'Closed');
    };
    const getCustomActionStatuses = () => {
        const customFilters: ICustomCheckboxFilters[] = [
            {
                id: 'Closed',
                onFilter: props.onFilter,
                color: CheckboxFilterBackgroundColor.reserved,
                filters: [{ key: 'Closed', value: 'Closed (Only)' }],
            },
        ];
        return customFilters;
    };
    const commonProps = {
        expandedFilters: props.expandedFilters,
        onFilter: props.onFilter,
        onToggleFilterExpanded: props.onToggleFilterExpanded,
    };

    const showActionStatus =
        !props.hideFilters ||
        props.hideFilters.length === 0 ||
        !props.hideFilters.some((s) => s === 'actionStatus');
    const showActionCategory =
        !props.hideFilters ||
        props.hideFilters.length === 0 ||
        !props.hideFilters.some((s) => s === 'actionCategory');
    const showActionPriority =
        !props.hideFilters ||
        props.hideFilters.length === 0 ||
        !props.hideFilters.some((s) => s === 'actionPriority');
    const showActionSpecial =
        !props.hideFilters ||
        props.hideFilters.length === 0 ||
        !props.hideFilters.some((s) => s === 'actionSpecial');
    const translate = useTranslate();
    return (
        <>
            <SearchFilter
                title={translate('ActionRegisterPage.Title.Filters')}
                name="filterKeywords"
                values={props.filters.filterKeywords}
                onAddFilterKeyword={props.onAddFilterKeyword}
                onRemoveFilterKeyword={props.onRemoveFilterKeyword}
            />

            {showActionSpecial && (
                <CheckboxFilter
                    {...commonProps}
                    title={translate('ActionRegisterPage.ActionRegisterTable.Title.SpecialFilters')}
                    name="filterSpecial"
                    selectedFilters={props.filters.filterSpecial}
                    unreservedFilters={props.lookupActionSpecial}
                />
            )}

            {showActionStatus && (
                <CheckboxFilter
                    {...commonProps}
                    title={translate('ActionRegisterPage.ActionRegisterTable.Title.Status')}
                    name="filterStatus"
                    selectedFilters={props.filters.filterStatus}
                    unreservedFilters={getUnreservedActionStatuses(props.lookupActionStatus)}
                    customFilters={getCustomActionStatuses()}
                    secondaryTitle={translate('ActionRegisterPage.ActionRegisterTable.SecondaryTitle.Status')}
                />
            )}

            {showActionCategory && (
                <CheckboxFilter
                    {...commonProps}
                    title={translate('ActionRegisterPage.ActionRegisterTable.Title.Category')}
                    name="filterCategory"
                    selectedFilters={props.filters.filterCategory}
                    unreservedFilters={getUnreservedActionStatuses(props.lookupActionCategory)}
                />
            )}

            {showActionPriority && (
                <CheckboxFilter
                    {...commonProps}
                    title={translate('ActionRegisterPage.ActionRegisterTable.Title.Priority')}
                    name="filterPriority"
                    selectedFilters={props.filters.filterPriority}
                    unreservedFilters={props.lookupActionPriority}
                />
            )}
        </>
    );
};
