import { combineReducers } from 'redux';
import { IIncident, newIncident } from 'models';
import { IAppAction } from 'actions/app-action';
import { s2ab } from 'utils/export-utils';
import { IncidentActions as OldIncidentActions } from 'actions/incident-actions';
import { IncidentActions } from 'actions/actions-v2/incident-action-v2';
import { ActionActions } from 'actions/actions-v2/action-action-v2';
import {
    IIncidentSettings,
    IMandatoryClaimCollections,
    ISiteSpecificClaim,
    IGranularClaim,
} from 'models/incidents/incident-settings';
import { RiskActions as OldRiskActions } from 'actions/risk-actions';
import { ActionActions as OldActionActions } from 'actions/action-actions';
import { IDifference } from 'models/difference';
import { genericSort } from 'utils/sort-utils';
import { PersonActions } from 'actions/person-actions';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import { AttachmentActions } from 'actions/actions-v2/attachment-action-v2';
import { RiskActions } from 'actions/actions-v2/risk-action-v2';
import saveAs from 'file-saver';

export interface IIncidentState {
    isUploading: boolean;
    isVisible: boolean;
    isSaving: boolean;
    incidents: IIncident[];
    isExportingIncidents: boolean;
    isLoading: boolean;
    incident: IIncident;
    incidentsSettings: IIncidentSettings;
    attachments: IAttachmentModel[];
    isDeletingAttachment: boolean;
    history: IDifference[][];
}
const INITIAL_GRANULAR_CLAIM_STATE: IGranularClaim = {
    incidentSeverity: '',
    roles: [],
    hasLessonsLearned: null,
};
const INITIAL_SITE_SPECIFIC_CLAIM_STATE: ISiteSpecificClaim = {
    incidentSeverity: '',
    siteClaim: [],
    hasLessonsLearned: null,
};

const INITIAL_MANDATORY_CLAIM_STATE: IMandatoryClaimCollections = {
    siteSpecificClaims: [INITIAL_SITE_SPECIFIC_CLAIM_STATE],
    granularClaims: [INITIAL_GRANULAR_CLAIM_STATE],
};

const INITIAL_INCIDENT_SETTINGS_STATE: IIncidentSettings = {
    mandatoryApprovals: INITIAL_MANDATORY_CLAIM_STATE,
    mandatoryNotifications: INITIAL_MANDATORY_CLAIM_STATE,
};

const INITIAL_STATE: IIncidentState = {
    isSaving: false,
    incidents: [],
    isExportingIncidents: false,
    isLoading: false,
    incident: newIncident,
    attachments: [],
    isUploading: false,
    isVisible: false,
    incidentsSettings: INITIAL_INCIDENT_SETTINGS_STATE,
    isDeletingAttachment: false,
    history: [],
};

const incidents = (
    state: IIncidentState['incidents'] = INITIAL_STATE.incidents,
    action: IAppAction
) => {
    switch (action.type) {
        case IncidentActions.LOAD_INCIDENTS_FULFILLED:
        case OldIncidentActions.LOAD_INCIDENTS_FULFILLED:
            return action.payload ? action.payload : state;

        case IncidentActions.SORT_INCIDENTS:
        case OldIncidentActions.SORT_INCIDENTS:
            return genericSort(action.payload.key, [...state], action.payload.sortAscending);

        default:
            return state;
    }
};

const incidentsSettings = (
    state: IIncidentState['incidentsSettings'] = INITIAL_STATE.incidentsSettings,
    action: IAppAction
) => {
    switch (action.type) {
        case IncidentActions.LOAD_SETTINGS_FULFILLED:
            return action.payload ? action.payload : state;

        case OldIncidentActions.LOAD_INCIDENT_SETTINGS_FULFILLED:
            return action.payload;

        default:
            return state;
    }
};

const incident = (
    state: IIncidentState['incident'] = INITIAL_STATE.incident,
    action: IAppAction
) => {
    switch (action.type) {
        case OldActionActions.UPSERT_ACTION_TO_INCIDENT_FULFILLED:
        case OldRiskActions.UPSERT_RISK_TO_INCIDENT_FULFILLED:
            return action.payload ? action.payload.incident : state;

        case OldIncidentActions.LOAD_INCIDENT_SETTINGS:
            return INITIAL_STATE.incident;

        case IncidentActions.LOAD_INCIDENT_FULFILLED:
        case IncidentActions.SET_INCIDENT_APPROVAL_FULFILLED:
        case OldIncidentActions.LOAD_INCIDENT_FULFILLED:
            return action.payload ? action.payload : state;

        case IncidentActions.CREATE_INCIDENT_FULFILLED:
        case IncidentActions.UPDATE_INCIDENT_FULFILLED:
        case OldIncidentActions.UPSERT_INCIDENT_FULFILLED:
            return action.payload ? action.payload : state;

        case OldIncidentActions.CHANGE_APPROVAL_STATUS_FULFILLED:
            return action.payload;

        case OldIncidentActions.PASS_LIVE_VALUES_OF_INCIDENT_TO_PARENT:
            return action.payload;

        case OldIncidentActions.ADD_ATTACHMENT_FULFILLED:
            return action.payload.incident;

        case RiskActions.CREATE_RISK_FOR_INCIDENT_FULFILLED:
            if (!action.payload) {
                return state;
            }

            const newRisks =
                state.risksAndActions && state.risksAndActions.actions
                    ? state.risksAndActions.risks.filter((f) => f)
                    : [];
            newRisks.push(action.payload.id);

            return {
                ...state,
                risksAndActions: {
                    ...state.risksAndActions,
                    risks: newRisks,
                },
            };

        case ActionActions.CREATE_ACTION_FULFILLED_FOR_INCIDENT:
            if (!action.payload) {
                return state;
            }

            const newActions =
                state.risksAndActions && state.risksAndActions.actions
                    ? state.risksAndActions.actions.filter((f) => f)
                    : [];
            newActions.push(action.payload.id);

            return {
                ...state,
                risksAndActions: {
                    ...state.risksAndActions,
                    actions: newActions,
                },
            };

        case IncidentActions.CLEAN_UP_INCIDENT:
            return INITIAL_STATE.incident;

        default:
            return state;
    }
};

const isLoading = (
    state: IIncidentState['isLoading'] = INITIAL_STATE.isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case IncidentActions.LOAD_INCIDENTS:
        case IncidentActions.LOAD_INCIDENT:
        case IncidentActions.SET_INCIDENT_APPROVAL:
        case OldIncidentActions.LOAD_INCIDENTS:
        case OldIncidentActions.LOAD_INCIDENT:
        case PersonActions.LOAD_PERSON_LOOKUPS:
            return true;

        case IncidentActions.LOAD_INCIDENT_FULFILLED:
        case IncidentActions.LOAD_INCIDENT_REJECTED:
        case IncidentActions.LOAD_INCIDENTS_FULFILLED:
        case IncidentActions.LOAD_INCIDENTS_REJECTED:
        case IncidentActions.SET_INCIDENT_APPROVAL_FULFILLED:
        case IncidentActions.SET_INCIDENT_APPROVAL_REJECTED:
        case OldIncidentActions.LOAD_INCIDENT_FULFILLED:
        case OldIncidentActions.LOAD_INCIDENT_CANCELLED:
        case OldIncidentActions.LOAD_INCIDENT_REJECTED:
        case OldIncidentActions.LOAD_INCIDENTS_FULFILLED:
        case OldIncidentActions.LOAD_INCIDENTS_CANCELLED:
        case OldIncidentActions.LOAD_INCIDENTS_REJECTED:
        case PersonActions.LOAD_PERSON_LOOKUPS_FULFILLED:
        case PersonActions.LOAD_PERSON_LOOKUPS_CANCELLED:
        case PersonActions.LOAD_PERSON_LOOKUPS_REJECTED:
            return false;

        default:
            return state;
    }
};

const isExportingIncidents = (
    state: IIncidentState['isExportingIncidents'] = INITIAL_STATE.isExportingIncidents,
    action: IAppAction
) => {
    switch (action.type) {
        case IncidentActions.EXPORT_INCIDENTS:
        case OldIncidentActions.DOWNLOAD_INCIDENTS:
            return true;
        case IncidentActions.EXPORT_INCIDENTS_FULFILLED:
            if (action.payload) {
                saveAs(
                    new Blob([s2ab(atob(action.payload.fileContents))], {
                        type: 'application/excel',
                    }),
                    action.payload.fileDownloadName
                );

                return false;
            }

            return state;
        case OldIncidentActions.DOWNLOAD_INCIDENTS_FULFILLED:
            if (action.payload) {
                const blob = new Blob([s2ab(atob(action.payload.response.fileContents))], {
                    type: 'application/excel',
                });
                saveAs(blob, 'Incidents.xlsx');
            }

            return false;
        case IncidentActions.EXPORT_INCIDENTS_REJECTED:
        case OldIncidentActions.DOWNLOAD_INCIDENTS_REJECTED:
        case OldIncidentActions.DOWNLOAD_INCIDENTS_CANCELLED:
            return false;
        default:
            return state;
    }
};

const isSaving = (
    state: IIncidentState['isSaving'] = INITIAL_STATE.isSaving,
    action: IAppAction
) => {
    switch (action.type) {
        case IncidentActions.CREATE_INCIDENT_REJECTED:
        case IncidentActions.CREATE_INCIDENT_FULFILLED:
        case IncidentActions.UPDATE_INCIDENT_REJECTED:
        case IncidentActions.UPDATE_INCIDENT_FULFILLED:
        case IncidentActions.SET_INCIDENT_APPROVAL_FULFILLED:
        case IncidentActions.SET_INCIDENT_APPROVAL_REJECTED:
        case IncidentActions.DELETE_INCIDENT_FULFILLED:
        case IncidentActions.DELETE_INCIDENT_REJECTED:
        case ActionActions.CREATE_ACTION_FULFILLED_FOR_INCIDENT:
        case ActionActions.CREATE_ACTION_REJECTED_FOR_INCIDENT:
        case RiskActions.CREATE_RISK_FOR_INCIDENT_FULFILLED:
        case RiskActions.CREATE_RISK_FOR_INCIDENT_REJECTED:
        case OldIncidentActions.UPSERT_INCIDENT_CANCELLED:
        case OldIncidentActions.UPSERT_INCIDENT_REJECTED:
        case OldIncidentActions.UPSERT_INCIDENT_FULFILLED:
            return false;

        case IncidentActions.CREATE_INCIDENT:
        case IncidentActions.DELETE_INCIDENT:
        case IncidentActions.UPDATE_INCIDENT:
        case IncidentActions.SET_INCIDENT_APPROVAL:
        case ActionActions.CREATE_ACTION_FOR_INCIDENT:
        case RiskActions.CREATE_RISK_FOR_INCIDENT:
        case OldIncidentActions.UPSERT_INCIDENT:
            return true;

        default:
            return state;
    }
};

const isDeletingAttachment = (
    state: IIncidentState['isDeletingAttachment'] = INITIAL_STATE.isDeletingAttachment,
    action: IAppAction
) => {
    switch (action.type) {
        case OldIncidentActions.DELETE_ATTACHMENT:
            return true;

        case OldIncidentActions.DELETE_ATTACHMENT_COMPLETE:
        case OldIncidentActions.DELETE_ATTACHMENT_REJECTED:
            return false;

        default:
            return state;
    }
};

const attachments = (
    state: IIncidentState['attachments'] = INITIAL_STATE.attachments,
    action: IAppAction
) => {
    switch (action.type) {
        case OldIncidentActions.LOAD_INCIDENT_SETTINGS:
            return INITIAL_STATE.attachments;
        case AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_INCIDENT:
            return action.payload ? action.payload : state;
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_INCIDENT:
            if (action.payload) {
                return [...state, ...action.payload];
            }
            return state;
        case AttachmentActions.DELETE_ATTACHMENT_FULFILLED:
            if (action.payload) {
                return state.filter((item: IAttachmentModel) => item.fileName !== action.payload);
            }
            return state;
        default:
            return state;
    }
};

const isVisible = (
    state: IIncidentState['isVisible'] = INITIAL_STATE.isVisible,
    action: IAppAction
) => {
    switch (action.type) {
        case OldIncidentActions.SHOW_ADD_ATTACHMENT_MODAL:
            return true;

        case OldIncidentActions.HIDE_ADD_ATTACHMENT_MODAL:
        case OldIncidentActions.ADD_ATTACHMENT_FULFILLED:
            return false;

        default:
            return state;
    }
};

const isUploading = (
    state: IIncidentState['isUploading'] = INITIAL_STATE.isUploading,
    action: IAppAction
) => {
    switch (action.type) {
        case OldIncidentActions.ADD_ATTACHMENT:
        case OldIncidentActions.DELETE_ATTACHMENT:
            return true;

        case OldIncidentActions.DELETE_ATTACHMENT_COMPLETE:
        case OldIncidentActions.ADD_ATTACHMENT_FULFILLED:
        case OldIncidentActions.ADD_ATTACHMENT_REJECTED:
        case OldIncidentActions.ADD_ATTACHMENT_CANCELLED:
            return false;

        default:
            return state;
    }
};

const history = (state: IIncidentState['history'] = INITIAL_STATE.history, action: IAppAction) => {
    switch (action.type) {
        case IncidentActions.LOAD_HISTORY_FULFILLED:
            return action.payload ? action.payload : state;

        case OldIncidentActions.LOAD_HISTORY_FULFILLED:
            return action.payload.data;

        default:
            return state;
    }
};

export const incidentReducer = combineReducers<IIncidentState>({
    isLoading,
    incidents,
    isExportingIncidents,
    isSaving,
    attachments,
    isUploading,
    isVisible,
    incident,
    incidentsSettings,
    isDeletingAttachment,
    history,
});
