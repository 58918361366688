import * as React from 'react';
import { IAction } from 'models/action';
import { FormikProps } from 'formik';
import { TextField } from 'components/form/fields/text-field';
import { SelectField } from 'components/form/fields/select-field';
import { DatePickerField } from 'components/form/fields/date-picker-field';
import { IKeyValue } from 'models';
import { TextAreaField } from 'components/form/fields/textarea-field';
import { PopoutSelectField } from 'components/form/fields/popout-select-field';
import { ISelectOption } from 'models/select-option';
import { IPersonLookup } from 'models/person-lookup';
import { date, object, string } from 'yup';
import useTranslate from 'translations/translation-utils';
import appConfig from 'helpers/config-helper';
import { ConfirmDialog } from 'components/v2/components';
import Comments from 'components/comments/comments';
import { Entity } from 'models/entity';

interface IProps {
    formikBag: FormikProps<IAction>;
    categories: Array<IKeyValue<string>>;
    priorities: Array<IKeyValue<string>>;
    actionStatuses: Array<IKeyValue<string>>;
    lookupSitePeopleRoles: IPersonLookup[];
    lookupSiteFeatures: Array<IKeyValue<string[]>>;
    actionAutoFieldUpdatePermission?: boolean;
    isReadOnly?: boolean;
    commentSave?: boolean;
}

export const actionDetailsValidationSchema = object<Partial<IAction>>().shape({
    title: string().max(2048, 'Title can have maximum 2048 characters.').required('Please enter a title'),
    description: string().max(10000, 'Description can have maximum 10000 characters.').required('Please enter a description'),
    category: string().required('Please enter a category'),
    priority: string().required('Please enter a priority'),
    statusId: string().required('Please enter a status'),
    assigneeId: string().required('Please enter a assignee').nullable(true),
    reminderDate: date(),
    dueDate: date().when(
        'reminderDate',
        (reminderDateValue: Date, schema) =>
            reminderDateValue &&
            schema.min(reminderDateValue, 'Due date must be after Reminder date')
    ),
});

export const ActionDetailsForm: React.FC<IProps> = ({
    formikBag,
    lookupSiteFeatures,
    ...props
}) => {
    const config = appConfig();
    const translate = useTranslate();
    const handleReminderDateChange = (newDate: Date) => {
        formikBag.setFieldValue('reminderDate', newDate);
    };

    const [isErrorDialogVisible, setErrorDialogVisible] = React.useState(false);

    const handleDueDateChange = (newDate: Date) => {
        formikBag.setFieldValue('dueDate', newDate);
    };

    const handleCategoryChange = (field: { value: string; label: string }) => {
        const selectedvalue = props.categories.find(i => i.key === field.value);
        if (selectedvalue?.label === 'iAuditor') {
            setErrorDialogVisible(true);
        } else {
            formikBag.setFieldValue('category', selectedvalue.label);
            formikBag.setFieldValue('categoryId', field.value);
        }
    };

    const handlePriorityChange = (field: { value: string; label: string }) => {
        const selectedvalue = props.priorities.find(i => i.key === field.value);
        formikBag.setFieldValue('priorityId', field.value);
        formikBag.setFieldValue('priority', selectedvalue.label);
    };

    const handleActionStatusChange = (field: { value: string; label: string }) => {
        const selectedvalue = props.actionStatuses.find(i => i.key === field.value);
        formikBag.setFieldValue('statusId', field.value);
        formikBag.setFieldValue('status', selectedvalue.label);
    };

    const handleAssigneeChange = (value: string) => {
        formikBag.setFieldValue('assigneeId', value);
    };

    const getLookupSitePeople = () => {
        const options: ISelectOption[] = props.lookupSitePeopleRoles.map(lookup => {
            const roles = lookup.roleNames ? lookup.roleNames.join(', ') : 'N/A';
            if (roles) {
                const label = `${lookup.label} (${roles})`;
                return { label, value: lookup.id };
            } else {
                return { label: lookup.label, value: lookup.id };
            }
        });

        return options;
    };

    const getBody = () => {
        return (
            <>
                <div>
                    <strong>
                        {translate('ActionUpsertPage.Validation.MessageBodyIAuditorCategory')}
                    </strong>
                </div>
            </>
        );
    };

    const selectAssignee = () => {
        if (
            !getLookupSitePeople().some(option => option.value === formikBag.values.assigneeId) &&
            formikBag.values.assignee &&
            formikBag.values.assigneeId
        ) {
            return {
                label: formikBag.values.assignee && formikBag.values.assignee.displayLabel,
                value: formikBag.values.assignee && formikBag.values.assignee.id,
            };
        }
    };

    const isActionActiveAndNotClose = () => {
        const action = formikBag.values;
        return action && action.isActive && action.statusId !== 'Closed';
    };

    const disableFieldForAutoGeneratedAction =
        !props.actionAutoFieldUpdatePermission &&
        (!!formikBag.values.masterProcessStepId ||
            !!formikBag.values.outcomeId ||
            !!formikBag.values.riskProfileQuestionId);
    return (
        <>
            <div className="columns">
                <div className="column is-8">
                    <TextField
                        id="title"
                        label={translate('ActionDetailsPage.label.title')}
                        value={formikBag.values.title}
                        handleChange={formikBag.handleChange}
                        error={formikBag.errors.title}
                        setVal={formikBag.setFieldValue}
                        isDisabled={props.isReadOnly}
                    />
                </div>
                <div className="column is-4">
                    <SelectField
                        id="categoryId"
                        label={translate('ActionDetailsPage.label.Category')}
                        error={formikBag.errors.category}
                        value={{
                            value: formikBag.values.categoryId,
                            label:
                                (formikBag.values.category == null ||
                                    formikBag.values.category.length === 0) === true
                                    ? formikBag.values.category
                                    : translate(
                                        'ActionDetailsPage.Category.dropdown.',
                                        formikBag.values.category
                                    ),
                        }}
                        options={props.categories.map(c => ({
                            value: c.key,
                            label: translate('ActionDetailsPage.Category.dropdown.', c.label),
                        }))}
                        handleChange={handleCategoryChange}
                        handleBlur={formikBag.handleBlur}
                        isDisabled={props.isReadOnly || disableFieldForAutoGeneratedAction}
                    />
                </div>
            </div>
            <div className="columns">
                <div className="column is-full">
                    <TextAreaField
                        id="description"
                        label={translate('ActionDetailsPage.label.Description')}
                        placeholder="description ..."
                        error={formikBag.errors.description}
                        value={formikBag.values.description}
                        handleChange={formikBag.handleChange}
                        setVal={formikBag.setFieldValue}
                        isDisabled={props.isReadOnly}
                    />
                </div>
            </div>
            <div className="columns">
                {formikBag.values.category !== 'ElogBooks' &&
                    formikBag.values.category !== 'iAuditor' && (
                        <div className="column is-4">
                            <DatePickerField
                                id="reminderDate"
                                label={translate('ActionDetailsPage.label.ReminderDate')}
                                value={formikBag.values.reminderDate}
                                handleChange={handleReminderDateChange}
                                handleBlur={formikBag.handleBlur}
                                isDisabled={props.isReadOnly}
                            />
                        </div>
                    )}
                <div className="column is-4">
                    <DatePickerField
                        id="dueDate"
                        label={translate('ActionDetailsPage.label.DueDate')}
                        value={formikBag.values.dueDate}
                        handleChange={handleDueDateChange}
                        handleBlur={formikBag.handleBlur}
                        isDisabled={props.isReadOnly}
                        error={formikBag.errors.dueDate}
                    />
                </div>
                <div className="column is-4">
                    <SelectField
                        id="priorityId"
                        label={translate('ActionDetailsPage.label.Priority')}
                        error={formikBag.errors.priority}
                        value={{
                            value: formikBag.values.priorityId,
                            label:
                                (formikBag.values.priority == null ||
                                    formikBag.values.priority.length === 0) === true
                                    ? formikBag.values.priority
                                    : translate(
                                        'ActionDetailsPage.Priority.dropdown.',
                                        formikBag.values.priority
                                    ),
                        }}
                        options={props.priorities.map(c => ({
                            value: c.key,
                            label: translate('ActionDetailsPage.Priority.dropdown.', c.label),
                        }))}
                        handleChange={handlePriorityChange}
                        handleBlur={formikBag.handleBlur}
                        isDisabled={props.isReadOnly || disableFieldForAutoGeneratedAction}
                    />
                </div>
            </div>
            <div className="subtitle">{translate('ActionDetailsPage.label.Actionabledetails')}</div>
            <div className="columns">
                <div className="column is-4">
                    <SelectField
                        id="statusId"
                        label={translate('ActionDetailsPage.label.Status')}
                        error={formikBag.errors.status}
                        value={{
                            value: formikBag.values.statusId,
                            label:
                                (formikBag.values.status == null ||
                                    formikBag.values.status.length === 0) === true
                                    ? formikBag.values.status
                                    : translate(
                                        'ActionDetailsPage.Status.dropdown.',
                                        formikBag.values.status
                                    ),
                        }}
                        options={props.actionStatuses.map(c => ({
                            value: c.key,
                            label: translate('ActionDetailsPage.Status.dropdown.', c.value),
                        }))}
                        handleChange={handleActionStatusChange}
                        handleBlur={formikBag.handleBlur}
                        isDisabled={props.isReadOnly}
                    />
                </div>
                <div className={`column is-4 ${props.isReadOnly ? 'action-disabled' : ''}`}>
                    {!props.isReadOnly &&
                        formikBag.values.category !== 'ElogBooks' &&
                        formikBag.values.category !== 'iAuditor' &&
                        !formikBag.values.macroLevel ? (
                        <>
                            <PopoutSelectField
                                id="assigneeId"
                                label={`${translate('ActionDetailsPage.label.Assignedto')}*`}
                                value={formikBag.values.assigneeId ?? formikBag.values.assignee?.id}
                                error={formikBag.errors.assigneeId}
                                secondaryValue={selectAssignee()}
                                options={getLookupSitePeople()}
                                handleChange={handleAssigneeChange}
                                handleBlur={formikBag.handleBlur}
                                isDisabled={props.isReadOnly}
                            />
                            {selectAssignee() && (
                                <p className="has-text-warning">
                                    {translate('Globals.Warning.InactiveUser')}
                                </p>
                            )}
                        </>
                    ) : (
                        <TextField
                            id="Assignee"
                            label={translate('ActionDetailsPage.label.Assignedto')}
                            value={
                                formikBag.values.assignee && formikBag.values.assignee.displayLabel
                            }
                            isDisabled={props.isReadOnly}
                        />
                    )}
                </div>
                {(formikBag.values.category === 'ElogBooks' || formikBag.values.category === 'iAuditor') && (
                    <div className="column is-4">
                        <div className="field field-action">
                            <div className="label">
                                {translate('ActionDetailsPage.label.Source')}
                            </div>
                            <a

                                href={formikBag.values.actionLink ?
                                    formikBag.values.actionLink :
                                    `${config.REACT_APP_ELOGBOOKS_URL
                                    }AD/Action/Actions?buildingId=${formikBag.values.buildingId
                                    }&actionID=${formikBag.values.id}&customerId=${formikBag.values.customerId
                                    }&contractId=${formikBag.values.contractId}&sectorId=${formikBag.values.sectorId ? formikBag.values.sectorId : ''
                                    }&assetId=${formikBag.values.assetId}&docgroupid=${formikBag.values.documentGroupID
                                        ? formikBag.values.documentGroupID
                                        : ''
                                    }&statusId=${formikBag.values.statusId ? formikBag.values.statusId : 0
                                    }
                                         `}

                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <mark id="actionSource" className="source">
                                    {translate('ActionDetailsPage.label.ViewSource')}
                                </mark>
                            </a>
                        </div>
                    </div>
                )}

                <ConfirmDialog
                    title={translate('ActionUpsertPage.Validation.ValidationTitle')}
                    message={getBody()}
                    isVisible={isErrorDialogVisible}
                    showConfirmButton={false}
                    onClose={() => setErrorDialogVisible(false)}
                    onOutsideDialogClick={() => setErrorDialogVisible(false)}
                    buttonCancelText={translate('ActionUpsertPage.Validation.CloseButton')}
                />
            </div>
            {formikBag?.values?.id && (
                <Comments
                    id={formikBag.values.id}
                    entity={Entity.Action}
                    allowNewComment={isActionActiveAndNotClose() && !props.isReadOnly}
                    forceSave={props.commentSave}
                    overrideSiteId={formikBag.values.shard}
                />
            )}
        </>
    );
};
