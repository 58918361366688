import * as React from 'react';
import Page from 'components/v2/page/Page';
import { Loader } from 'components/loader';
import { SearchFilter } from '../../../components/searchFilter';
import { IKeyValue } from '../../../models/key-value';
import { SiteProfileGridToolbar } from './siteProfileGridToolbar/SiteProfileGridToolbar';
import { ISiteProfileListDispatchProps, SiteProfileList } from './siteProfileList';
import { ISiteFilters } from 'models/site-filters';
import { ISiteProfile } from 'models/site-profile';
import { OverlayPanel } from 'components/v2/components';
import { isEmpty } from 'lodash';
import { usePageUIPreferences, UiPreferences } from 'utils/page-ui-preferences-utils';
import { checkFilterExistsWithExceptions } from 'utils/array-utils';
import { CheckboxFilter } from '../../../components/checkboxFilter';
import useTranslate from 'translations/translation-utils';
import { IOperatingPlatform } from 'models/operating-platform-model';

export interface IProps {
    items: ISiteProfile[];
    permissions: Array<IKeyValue<string>>;
    expandedItems: string[];
    isLoading: boolean;
    responseContinuation: string;
    expandedFilters: string[];
    operatingPlatforms: IOperatingPlatform[];
}

export interface IDispatchProps extends ISiteProfileListDispatchProps {
    loadSites(filters: ISiteFilters): void;
    onToggleFilterExpanded: (id: string) => void;
}

interface ISiteState {
    showFilters: boolean;
    filters: ISiteFilters;
    sortColumn: string;
    sortAscending: boolean;
}

export const SiteProfilePage: React.FC<IProps & IDispatchProps> = ({
    isLoading,
    items,
    responseContinuation,
    loadSites,
    ...props
}) => {
    const [state, setState] = React.useState({
        sortColumn: '',
        sortAscending: false,
        showFilters: false,
    } as ISiteState);
    const [filters, setFilters] = usePageUIPreferences<ISiteFilters>(UiPreferences.SiteFilters, {
        filterKeywords: [],
        responseContinuation: null,
        operatingPlatform: [],
    });

    React.useEffect(() => {
        loadSites(filters);
    }, [filters]);
    const onSort = (key: string, sortAscending: boolean) => {
        props.onSort(key, sortAscending);
        setState({ ...state, sortAscending, sortColumn: key });
    };

    const onClearFilters = () => {
        const filters = {
            filterKeywords: [],
            operatingPlatform: [],
        } as ISiteFilters;
        setFilters(filters);
    };

    const onAddFilterKeyword = (keyword: string) => {
        const keywords = [
            keyword,
            ...filters.filterKeywords.slice(0, filters.filterKeywords.length),
        ];
        setFilters({ ...filters, filterKeywords: keywords });
    };

    const onRemoveFilterKeyword = (keyword: string) => {
        const index = filters.filterKeywords.indexOf(keyword);
        const keywords = [
            ...filters.filterKeywords.slice(0, index),
            ...filters.filterKeywords.slice(index + 1),
        ];
        setFilters({ ...filters, filterKeywords: keywords });
    };

    const loadMoreSites = () => {
        const filter = { ...filters, responseContinuation };
        loadSites(filter);
    };

    const onFilter = (key: Extract<keyof ISiteFilters, string>, element: string) => {
        if (!Array.isArray(filters[key])) {
            setFilters({ ...filters });
        }
        let operatingPlatforms = filters.operatingPlatform;

        const index = operatingPlatforms.indexOf(element);
        if (index !== -1) {
            const items = [
                ...operatingPlatforms.slice(0, index),
                ...operatingPlatforms.slice(index + 1),
            ];
            operatingPlatforms = items;
        } else {
            const items = [element, ...operatingPlatforms.slice(0, operatingPlatforms.length)];
            operatingPlatforms = items;
        }

        setFilters({ ...filters, operatingPlatform: operatingPlatforms });
    };

    const isFilterExists = checkFilterExistsWithExceptions(filters, ['responseContinuation']);
    const translate = useTranslate();

    return (
        <Page title="Sites &amp; Facilities" isAdminPage={true}>
            <SiteProfileGridToolbar
                sites={items}
                onShowFilters={() => setState({ ...state, showFilters: true })}
                onClearFilters={onClearFilters}
                permissions={props.permissions}
                isFilterExists={isFilterExists}
            />
            <OverlayPanel
                title={translate('RiskRegisterPage.Title.Filters')}
                isVisible={state.showFilters}
                onClose={() => setState({ ...state, showFilters: false })}
                onOutsideDialogClick={() => setState({ ...state, showFilters: false })}
                onClearFilters={onClearFilters}
                isFilterExists={isFilterExists}
            >
                <SearchFilter
                    title={translate('RiskRegisterPage.Title.Filters')}
                    name="filterKeywords"
                    values={filters.filterKeywords}
                    onAddFilterKeyword={onAddFilterKeyword}
                    onRemoveFilterKeyword={onRemoveFilterKeyword}
                />
                <CheckboxFilter
                    onFilter={onFilter}
                    title={translate('Globals.Category.dropdown.OP')}
                    name="operatingPlatform"
                    selectedFilters={filters.operatingPlatform}
                    unreservedFilters={props.operatingPlatforms}
                    expandedFilters={props.expandedFilters}
                    onToggleFilterExpanded={props.onToggleFilterExpanded}
                />
            </OverlayPanel>
            <Loader loading={isLoading}>
                {!isEmpty(items) ? (
                    <SiteProfileList
                        sortAscending={state.sortAscending}
                        isLoading={isLoading}
                        onSort={onSort}
                        onToggleExpanded={props.onToggleExpanded}
                        sortColumn={state.sortColumn}
                        items={items}
                        expandedItems={props.expandedItems}
                        hasMoreItems={!isEmpty(responseContinuation)}
                        loadMoreSites={loadMoreSites}
                    />
                ) : (
                    <p>{translate('QuestionnSetList.Timeline.NoResultFound')}</p>
                )}
            </Loader>
        </Page>
    );
};
