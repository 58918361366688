import queryString from 'query-string';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';

import {
    IChangePersonSearchValueAction,
    ILinkPersonAction,
    ILoadLinkedPeopleAction,
    ILoadPersonSearchResults,
    ISelectSiteAction,
    IUnlinkPersonConfirmedAction,
    SiteRelationshipsActions,
    SiteRelationshipsActionTypes,
} from '../../actions/site-relationships-actions';
import { IPerson } from '../../models/person';
import { ISiteRelationshipsRootState } from '../../reducers/siteRelationships';
import { getErrorActions$ } from '../epic-helpers';
import { getChangeSearchValueEpic } from './factories';
import { getApiHeaders } from 'epics/shared-header';

import appConfig from 'helpers/config-helper';

const config = appConfig();

const apiUrl = config.REACT_APP_BASE_API;

const changePersonSearchValueEpic = getChangeSearchValueEpic<
    IChangePersonSearchValueAction,
    ILoadPersonSearchResults
>(
    SiteRelationshipsActions.CHANGE_PERSON_SEARCH_VALUE,
    SiteRelationshipsActions.LOAD_PERSON_SEARCH_RESULTS
);

const loadPersonSearchResultsEpic: Epic<
    SiteRelationshipsActionTypes,
    any,
    ISiteRelationshipsRootState
> = (action$, state$) =>
    action$.pipe(
        ofType(SiteRelationshipsActions.LOAD_PERSON_SEARCH_RESULTS),
        filter(() => Boolean(state$.value.siteRelationships.siteSelector.selectedSite)),
        map<
            ILoadPersonSearchResults,
            {
                siteId: string;
                searchString: string;
                filterKeywords: string[];
            }
        >((action) => ({
            siteId: state$.value.siteRelationships.siteSelector.selectedSite.id,
            searchString: action.payload.searchString,
            filterKeywords: action.payload.searchString.split(' '),
        })),
        map((values) => ({
            url: `${apiUrl}/api/siteapi/${values.siteId}/people?${queryString.stringify({
                isAttached: false,
                filterKeywords: values.filterKeywords,
            })}`,
            searchString: values.searchString,
        })),
        switchMap(({ searchString, url }) =>
            ajax.getJSON(url, getApiHeaders()).pipe(
                map<IPerson[], SiteRelationshipsActionTypes>((response) => ({
                    type: SiteRelationshipsActions.LOAD_PERSON_SEARCH_RESULTS_FULFILLED,
                    payload: {
                        people: response,
                        searchString,
                    },
                })),
                takeUntil(
                    action$.pipe(
                        ofType<SiteRelationshipsActionTypes>(
                            SiteRelationshipsActions.CHANGE_SITE_SEARCH_VALUE,
                            SiteRelationshipsActions.CHANGE_PERSON_SEARCH_VALUE,
                            SiteRelationshipsActions.LOAD_PERSON_SEARCH_RESULTS,
                            SiteRelationshipsActions.LOAD_PERSON_SEARCH_RESULTS_CANCELLED
                        )
                    )
                ),
                catchError<any, any>((error: AjaxError) =>
                    getErrorActions$('People')(
                        SiteRelationshipsActions.LOAD_PERSON_SEARCH_RESULTS_REJECTED,
                        error,
                        {
                            errorMessage: 'Unable to load people',
                        }
                    )
                )
            )
        )
    );

const loadLinkedPeopleEpic: Epic<any> = (action$) =>
    action$.pipe(
        ofType(SiteRelationshipsActions.LOAD_LINKED_PEOPLE),
        map<
            ILoadLinkedPeopleAction,
            {
                siteId: string;
            }
        >((action) => ({
            siteId: action.payload.siteId,
        })),
        map((values) => ({
            url: `${apiUrl}/api/siteapi/${values.siteId}/people?${queryString.stringify({ isAttached: true })}`,
            siteId: values.siteId,
        })),
        switchMap(({ siteId, url }) =>
            ajax.getJSON(url, getApiHeaders()).pipe(
                map<IPerson[], SiteRelationshipsActionTypes>((response) => ({
                    type: SiteRelationshipsActions.LOAD_LINKED_PEOPLE_FULFILLED,
                    payload: {
                        people: response,
                        siteId,
                    },
                })),
                takeUntil(
                    action$.pipe(
                        ofType<SiteRelationshipsActionTypes>(
                            SiteRelationshipsActions.CHANGE_SITE_SEARCH_VALUE,
                            SiteRelationshipsActions.LOAD_LINKED_PEOPLE,
                            SiteRelationshipsActions.LOAD_LINKED_PEOPLE_CANCELLED
                        )
                    )
                ),
                catchError<any, any>((error: AjaxError) =>
                    getErrorActions$('Linked People')(
                        SiteRelationshipsActions.LOAD_LINKED_PEOPLE_REJECTED,
                        error,
                        {
                            errorMessage: 'Unable to load linked people.',
                        }
                    )
                )
            )
        )
    );

const linkPersonEpic: Epic<any> = (action$) =>
    action$.pipe(
        ofType(SiteRelationshipsActions.LINK_PERSON),
        switchMap<ILinkPersonAction, any>((action) =>
            ajax
                .post(
                    `${apiUrl}/api/siteapi/${action.payload.site.id}/people`,
                    action.payload.person,
                    getApiHeaders()
                )
                .pipe(
                    map<AjaxResponse, SiteRelationshipsActionTypes>(() => ({
                        type: SiteRelationshipsActions.LINK_PERSON_FULFILLED,
                        payload: {
                            ...action.payload,
                        },
                    })),
                    catchError<any, any>((error: AjaxError) =>
                        getErrorActions$('Link Person')(
                            SiteRelationshipsActions.LINK_PERSON_REJECTED,
                            error,
                            {
                                errorMessage: 'Unable to link person.',
                            }
                        )
                    )
                )
        )
    );

const unlinkPersonConfirmedEpic: Epic<any> = (action$) =>
    action$.pipe(
        ofType(SiteRelationshipsActions.UNLINK_PERSON_CONFIRMED),
        switchMap<IUnlinkPersonConfirmedAction, any>((action) =>
            ajax
                .delete(
                    `${apiUrl}/api/siteapi/${action.payload.site.id}/people/${action.payload.person.id}`,
                    getApiHeaders()
                )
                .pipe(
                    map<AjaxResponse, SiteRelationshipsActionTypes>(() => ({
                        type: SiteRelationshipsActions.UNLINK_PERSON_FULFILLED,
                        payload: {
                            ...action.payload,
                        },
                    })),
                    catchError<any, any>((error: AjaxError) =>
                        getErrorActions$('Un-link Person')(
                            SiteRelationshipsActions.UNLINK_PERSON_REJECTED,
                            error,
                            {
                                ...action.payload,
                                errorMessage: 'Unable to un-link person.',
                            }
                        )
                    )
                )
        )
    );

const triggerLinkedPeopleRefreshEpic: Epic<SiteRelationshipsActionTypes> = (action$) =>
    action$.pipe(
        ofType<SiteRelationshipsActionTypes>(
            SiteRelationshipsActions.SELECT_SITE,
            SiteRelationshipsActions.LINK_PERSON_FULFILLED,
            SiteRelationshipsActions.UNLINK_PERSON_FULFILLED
        ),
        map<ISelectSiteAction, SiteRelationshipsActionTypes>((action) => ({
            type: SiteRelationshipsActions.LOAD_LINKED_PEOPLE,
            payload: {
                siteId: action.payload.site.id,
            },
        }))
    );

export const personEpics = combineEpics(
    changePersonSearchValueEpic,
    linkPersonEpic,
    loadLinkedPeopleEpic,
    loadPersonSearchResultsEpic,
    triggerLinkedPeopleRefreshEpic,
    unlinkPersonConfirmedEpic
);
