import { ActionActions, ActionActionTypes } from '../../../../actions/action-actions';
import { IAction } from '../../../../models/action';

export default {
    onClose: (itemForConfirmation: IAction): ActionActionTypes => ({
        type: ActionActions.DELETE_ACTION_CANCEL,
        payload: {
            item: itemForConfirmation
        }
    }),
    onConfirm: (itemForConfirmation: IAction): ActionActionTypes => ({
        type: ActionActions.DELETE_ACTION_CONFIRM,
        payload: {
            item: itemForConfirmation
        }
    })
};
