import React, { useEffect } from 'react';
import { FormikProps } from 'formik';
import { IOpportunity, IOpportunityQuestion } from 'models/opportunity-question-set';
import OpportunityResponse from './opportunity-advice-form';
import { Control, Field, Help, Input } from 'components/form';
import { isNil } from 'lodash';
import { BulmaColor } from 'enums/BulmaColor';
import { BulmaSize } from 'enums/BulmaSize';

import { formikFieldCss, getKeyed } from 'utils/form-utils';
import { newGuid } from 'utils/id-utils';
import useTranslate from 'translations/translation-utils';

export interface IProps {
    formikBag: FormikProps<Partial<IOpportunity>>;
    path: string;
    setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
    setPath: React.Dispatch<React.SetStateAction<string>>;
    setObjectPath: React.Dispatch<React.SetStateAction<string>>;
}
const OpportunityQuestionForm: React.FC<IProps> = ({
    formikBag,
    path,
    setPath,
    setIsValid,
    setObjectPath,
}) => {
    const translate = useTranslate();
    const [selectedResponse, setSelectedResponse] = React.useState([]);
    const [prefix, setPrefix] = React.useState('');
    const [titleError, setTitleError] = React.useState('');
    const [responseError, setResponseError] = React.useState('');

    useEffect(() => {
        if (prefix !== '') {
            formikBag.setFieldValue(`${prefix}.responseAnswers`, selectedResponse);
        }
    }, [selectedResponse]);

    useEffect(() => {
        setObjectPath(prefix);
        const selected = obj('responseAnswers');
        selected ? setSelectedResponse(selected) : setSelectedResponse([]);
    }, [prefix]);

    useEffect(() => {
        const array = path.split('-').slice(1);
        const indexes = [];

        for (let i = 0, x = array && array.length; i < x; i++) {
            indexes.push(array[i][0]);
        }
        let prefixValue = '';

        for (let i = 0, x = indexes.length; i < x; i++) {
            if (!indexes[i]) {
                const questions =
                    prefixValue === '' ? formikBag.values[`questions`] : obj('questions');
                if (!questions) {
                    const newQuestion = [{ id: newGuid() }] as IOpportunityQuestion[];
                    formikBag.setFieldValue(`${dotPerfix(prefixValue)}questions`, newQuestion);
                    setSelectedResponse([]);
                    setPath(path + 0);
                    prefixValue = getPrefix(prefixValue, 0);
                } else {
                    formikBag.setFieldValue(`${dotPerfix(prefixValue)}questions`, [
                        ...questions,
                        { id: newGuid() },
                    ]);
                    const val = questions.length;
                    setPath(path + val);
                    prefixValue = getPrefix(prefixValue, val);
                }
            } else {
                prefixValue = getPrefix(prefixValue, indexes[i]);
            }
        }

        setPrefix(prefixValue);
        formikBag.setFieldValue(`${prefixValue}.path`, path);
    }, [path]);

    useEffect(() => {
        const element = value('title');

        if (element === '') {
            setTitleError(translate('OpportunityQ.AdvicePage.TitleError'));
        } else {
            setTitleError('');
        }

        if (selectedResponse.length === 0) {
            setResponseError(translate('OpportunityQ.AdvicePage.ResponseError'));
        } else {
            setResponseError('');
        }

        if (element === '' || selectedResponse.length === 0) {
            setIsValid(false);
        } else {
            setIsValid(true);
        }
    });

    const field = (name: Extract<keyof IOpportunityQuestion, string>) => `${prefix}.${name}`;

    function obj<T extends Extract<keyof IOpportunityQuestion, any>>(name: T) {
        const val = getKeyed(formikBag, 'values', field(name));
        return val;
    }

    function value<T extends Extract<keyof IOpportunityQuestion, string>>(name: T) {
        const val = getKeyed(formikBag, 'values', field(name));
        return !isNil(val) ? val : '';
    }

    const dotPerfix = (prefixValue: string): string => {
        return prefixValue !== '' ? (prefixValue = `${prefixValue}.`) : prefixValue;
    };

    const getPrefix = (prefixValue: string, i: any) => {
        prefixValue = dotPerfix(prefixValue);

        prefixValue = `${prefixValue}questions[${i}]`;
        return prefixValue;
    };

    const onUpdateResponse = (response) => {
        if (selectedResponse.includes(response)) {
            // remove from selected
            setSelectedResponse(selectedResponse.filter((x) => x !== response));
        } else {
            setSelectedResponse([...selectedResponse, response]);
        }
    };

    return (
        <>
            <Field isHorizontal={true} htmlFor="title" label={translate('OpportunityQ.QuestionForm.Title')} labelSize={BulmaSize.Medium}>
                <Field>
                    <Control>
                        <Input
                            id={field('title')}
                            name={field('title')}
                            aria-required="true"
                            placeholder={translate('OpportunityQ.QuestionForm.QuestionTitle')}
                            type="text"
                            className={formikFieldCss(formikBag, 'title')}
                            value={value('title')}
                            onChange={formikBag.handleChange}
                            onBlur={(event) => formikBag.setFieldValue(event.target.name, event.target.value.trim())}
                        />
                    </Control>
                    <Help isShown={true} bulmaColor={BulmaColor.Danger}>
                        {titleError}
                    </Help>
                </Field>
            </Field>
            <OpportunityResponse
                selectedResponse={selectedResponse}
                onUpdateResponse={onUpdateResponse}
            />
            <Help isShown={true} bulmaColor={BulmaColor.Danger}>
                {responseError}
            </Help>
        </>
    );
};

export default OpportunityQuestionForm;
