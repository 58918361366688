import { combineReducers } from 'redux';

import { PersonActions, PersonActionTypes } from '../../actions/person-actions';
import { PersonActions as newActions } from '../../actions/actions-v2/person-action-v2';
import { IUserAssignModule, IPerson, IPersonAssignedItem } from '../../models/person';
import { IGridViewState, INITIAL_STATE as INITIAL_GRID_STATE } from '../grid';
import { IAppAction } from 'actions/app-action';
import { sortArrayBy } from 'utils/sort-utils';
import { IPersonAssignedSite } from 'models/site-profile';

export interface IPersonsGridState extends IGridViewState<IPerson> {
    sitePersons: IPerson[];
    siteAllPersons: IPerson[];
    userAssignModule: IUserAssignModule;
    personAssignedItem: IPersonAssignedItem;
    siteAndPrivilegePersons: IPerson[];
    notificationPersons: IPerson[];
    personAssignedSites: IPersonAssignedSite[];
}

export const INITIAL_STATE: IPersonsGridState = {
    ...INITIAL_GRID_STATE,
    sitePersons: null,
    siteAllPersons: null,
    userAssignModule: null,
    personAssignedItem: null,
    siteAndPrivilegePersons: null,
    notificationPersons: [],
    personAssignedSites: [],
};

const items = (state: IPersonsGridState['items'] = INITIAL_STATE.items, action: IAppAction) => {
    switch (action.type) {
        case newActions.SORT_PERSONS:
            return sortArrayBy(action.payload.key, [...state], action.payload.sortAscending);
        case newActions.LOAD_PERSONS_REJECTED:
            return INITIAL_STATE.items;

        case newActions.LOAD_PERSONS_FULFILLED:
            if (action.payload) {
                if (action.isFromContinuationRequest) {
                    return [...state, ...action.payload];
                }

                return action.payload;
            }

            return state;

        case newActions.CREATE_PERSON_FULFILLED:
            if (action.payload) {
                return [action.payload, ...state];
            }

            return state;

        case newActions.UPDATE_PERSON_FULFILLED:
            if (action.payload) {
                const updatedPerson = action.payload;
                if (!updatedPerson.isActive) {
                    return state.filter((person) => person.id !== updatedPerson.id);
                }

                return state.map((person) =>
                    person.id === updatedPerson.id ? action.payload : person
                );
            }

            return state;

        case PersonActions.LOAD_PAGE_FULFILLED:
            return action.payload;

        default:
            return state;
    }
};

const sitePersons = (
    state: IPersonsGridState['sitePersons'] = INITIAL_STATE.sitePersons,
    action: IAppAction
) => {
    switch (action.type) {
        case newActions.LOAD_SITE_PERSONS:
            return INITIAL_STATE.sitePersons;

        case newActions.LOAD_SITE_PERSONS_FULFILLED:
            return action.payload;

        default:
            return state;
    }
};

const siteAllPersons = (
    state: IPersonsGridState['siteAllPersons'] = INITIAL_STATE.siteAllPersons,
    action: IAppAction
) => {
    switch (action.type) {
        case newActions.LOAD_SITE_ALL_PERSONS:
            return INITIAL_STATE.siteAllPersons;

        case newActions.LOAD_SITE_ALL_PERSONS_FULFILLED:
            return action.payload;

        default:
            return state;
    }
};

const siteAndPrivilegePersons = (
    state: IPersonsGridState['siteAndPrivilegePersons'] = INITIAL_STATE.siteAndPrivilegePersons,
    action: IAppAction
) => {
    switch (action.type) {
        case newActions.LOAD_PERSONS_BY_SITE_AND_PRIVILEGE:
            return INITIAL_STATE.siteAndPrivilegePersons;

        case newActions.LOAD_PERSONS_BY_SITE_AND_PRIVILEGE_FULFILLED:
            return action.payload;

        default:
            return state;
    }
};


const notificationPersons = (
    state: IPersonsGridState['notificationPersons'] = INITIAL_STATE.notificationPersons,
    action: IAppAction
) => {
    switch (action.type) {
        case newActions.LOAD_ALL_PERSONS:
            return INITIAL_STATE.sitePersons;

        case newActions.LOAD_ALL_PERSONS_FULFILLED:
            return action.payload;
        case newActions.RESET_NOTIFICATION_PERSONS:
            return INITIAL_STATE.notificationPersons;

        default:
            return state;
    }
};



const personAssignedItem = (
    state: IPersonsGridState['personAssignedItem'] = INITIAL_STATE.personAssignedItem,
    action: IAppAction
) => {
    switch (action.type) {
        case newActions.LOAD_PERSON_ASSIGNED_ITEM:
            return INITIAL_STATE.personAssignedItem;

        case newActions.LOAD_PERSON_ASSIGNED_ITEM_FULFILLED:
            return action.payload;

        default:
            return state;
    }
};

const assignModules = (
    state: IPersonsGridState['userAssignModule'] = INITIAL_STATE.userAssignModule,
    action: IAppAction
) => {
    switch (action.type) {
        case newActions.LOAD_PERSON_ASSIGN_MODULES:
            return INITIAL_STATE.userAssignModule;

        case newActions.LOAD_PERSON_ASSIGN_MODULES_FULFILLED:
        case newActions.UPDATE_PERSON_ASSIGN_MODULES_FULFILLED:
            return action.payload;

        default:
            return state;
    }
};

const isLoading = (state: boolean = INITIAL_STATE.isLoading, action: IAppAction) => {
    switch (action.type) {
        case newActions.LOAD_PERSONS:
        case newActions.LOAD_SITE_PERSONS:
        case newActions.LOAD_SITE_ALL_PERSONS:
        case newActions.LOAD_PERSONS_BY_SITE_AND_PRIVILEGE:
        case newActions.LOAD_ALL_PERSONS:
        case newActions.LOAD_PERSON_ASSIGNED_SITES:
            return true;
        case PersonActions.LOAD_PAGE_FULFILLED:
        case newActions.LOAD_PERSONS_FULFILLED:
        case newActions.LOAD_PERSONS_BY_SITE_AND_PRIVILEGE_FULFILLED:
        case newActions.LOAD_PERSONS_BY_SITE_AND_PRIVILEGE_REJECTED:
        case newActions.LOAD_PERSON_REJECTED:
        case newActions.LOAD_SITE_PERSONS_FULFILLED:
        case newActions.LOAD_SITE_PERSONS_REJECTED:
        case newActions.LOAD_ALL_PERSONS_FULFILLED:
        case newActions.LOAD_ALL_PERSONS_REJECTED:
        case newActions.LOAD_PERSON_ASSIGNED_SITES_FULFILLED:
        case newActions.LOAD_PERSON_ASSIGNED_SITES_REJECTED:
            return false;

        default:
            return state;
    }
};

const sortColumn = (
    state: IPersonsGridState['sortColumn'] = INITIAL_STATE.sortColumn,
    action: IAppAction
) => {
    switch (action.type) {
        case newActions.SORT_PERSONS:
            return action.payload.key;

        default:
            return state;
    }
};

const sortAscending = (
    state: IPersonsGridState['sortAscending'] = INITIAL_STATE.sortAscending,
    action: IAppAction
) => {
    switch (action.type) {
        case newActions.SORT_PERSONS:
            return action.payload.sortAscending;

        default:
            return state;
    }
};

const secondarySortColumn = (
    state: IPersonsGridState['secondarySortColumn'] = INITIAL_STATE.secondarySortColumn,
    action: PersonActionTypes
) => {
    switch (action.type) {
        default:
            return state;
    }
};

const secondarySortAscending = (
    state: IPersonsGridState['secondarySortAscending'] = INITIAL_STATE.secondarySortAscending,
    action: PersonActionTypes
) => {
    switch (action.type) {
        default:
            return state;
    }
};

const expandedItems = (
    state: IPersonsGridState['expandedItems'] = INITIAL_STATE.expandedItems,
    action: PersonActionTypes
) => {
    switch (action.type) {
        case PersonActions.TOGGLE_ITEM_EXPANDED:
            const id = action.payload.id;
            if (state.includes(id)) {
                return state.filter((c) => c !== id);
            }
            return [...state, id];

        default:
            return state;
    }
};

const responseContinuation = (
    state: string = INITIAL_STATE.responseContinuation,
    action: IAppAction
) => {
    switch (action.type) {
        case newActions.LOAD_PERSONS_FULFILLED:
            return action.responseContinuation;

        default:
            return state;
    }
};

const personAssignedSites = (state: IPersonsGridState['personAssignedSites'] = INITIAL_STATE.personAssignedSites, action: IAppAction) => {
    switch (action.type) {
        case newActions.LOAD_PERSON_ASSIGNED_SITES_FULFILLED:
            return action.payload;
        default:
            return state;
    }
};

export const reducer = combineReducers<IPersonsGridState>({
    items,
    sitePersons,
    siteAllPersons,
    userAssignModule: assignModules,
    personAssignedItem,
    siteAndPrivilegePersons,
    isLoading,
    sortColumn,
    sortAscending,
    secondarySortColumn,
    secondarySortAscending,
    expandedItems,
    responseContinuation,
    notificationPersons,
    personAssignedSites,
});
