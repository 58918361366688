import { createSelector } from 'reselect';
import { IBaseState } from 'reducers/base-state';
import { IRootState } from 'routes/store';
import { checkPermissions } from 'utils/permission-utils';

const getLoadingStatus = createSelector(
    (state: IRootState) => state.dashboardComponentsState.isActionComponentLoading,
    (state) => state
);

const getSiteId = (state: IBaseState) => state.app.siteId;

const getComponent = createSelector(
    (state: IRootState) => state.dashboardComponentsState.actionComponent,
    (state) => state
);

const getPermission = createSelector(
    (state: IRootState) => state.app.permissions,
    (permissions) => {
        if (!permissions || permissions.length < 1) {
            return false;
        }

        return checkPermissions(['ActionView'], permissions);
    }
);

export default createSelector(
    getLoadingStatus,
    getSiteId,
    getComponent,
    getPermission,
    (isLoading, siteId, component, hasPermission) => ({
        isLoading,
        siteId,
        component,
        hasPermission
    })
);
