import React from 'react';
import { Button } from './Button';
import classNames from 'classnames';

interface IProps {
    id: string;
    value: boolean;
    isReadOnly?: boolean;
    onChange: (value: boolean) => void;
}

export const ThumbButton: React.FC<IProps> = ({ id, value, isReadOnly, onChange }) => {
    const buttonUpClassName = classNames(`thumb-up`, value === true ? 'active' : '');

    const buttonDownClassName = classNames(`thumb-down`, value === false ? 'active' : '');

    const isDisabledClassName = classNames(isReadOnly === true ? `thumb-disabled` : '');

    const onButtonUpClick = () => {
        if (value !== true) {
            onChange(true);
        }
    };

    const onButtonDownClick = () => {
        if (value !== false) {
            onChange(false);
        }
    };

    return (
        <div className="thumb-buttons">
            <Button
                id={`btnUp${id}`}
                className={buttonUpClassName + " " + isDisabledClassName}
                onClick={onButtonUpClick}
                disabled={isReadOnly}
            />
            <Button
                id={`btnUp${id}`}
                className={buttonDownClassName + " " + isDisabledClassName}
                onClick={onButtonDownClick}
                disabled={isReadOnly}
            />
        </div>
    );
};
