import React from 'react';
import { connect } from 'react-redux';
import { history } from 'routes/App';
import selectors from './selectors';
import { OperatingPlatform } from 'models/site-profile';

interface IProps {
    isTestSite: boolean;
    isClient: boolean;
    siteOperationPlatform: string;
}

const MainReport: React.FC<IProps> = (props) => {

    const siteAnalyticsUrlWithClient =
        (props.siteOperationPlatform === OperatingPlatform.CriticalFacility)
            ? 'https://vantageanalytics.cbre.com/vantage-reporting/dashboard/2148/252461'
            : 'https://vantageanalytics.cbre.com/vantage-reporting/dashboard/2148/155688'


    const siteAnalyticsUrlWithoutClient =
        (props.siteOperationPlatform === OperatingPlatform.CriticalFacility)
            ? 'https://vantageanalytics.cbre.com/vantage-reporting/dashboard/2148/252461'
            : 'https://vantageanalytics.cbre.com/vantage-reporting/dashboard/2148/217799'


    React.useEffect(() => {
        if (!props.isTestSite) {
            const url = props.isClient
                ? siteAnalyticsUrlWithClient
                : siteAnalyticsUrlWithoutClient;

            window.open(url);
            history.goBack();
        }
    }, []);

    return <></>;
};

export default connect(selectors)(MainReport);
