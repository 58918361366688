import { IIncident } from 'models';
import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { checkPermission } from 'utils/permission-utils';

const getSiteId = (state: IRootState) => state.app.siteId;
const getIsSaving = (state: IRootState) => state.incidentsState.isSaving;
const getIncident = (state: IRootState) => {
    if (state.incidentsState.incident.localDateTime !== null) {
        const incident = {
            ...state.incidentsState.incident,
        } as IIncident;

        if (state.incidentsState.incident.finishLocalDateTime !== null) {
            return {
                ...incident,
            } as IIncident;
        }

        return incident;
    }

    return state.incidentsState.incident;
};
export const getIsLoading = (state: IRootState) => state.incidentsState.isLoading;
const getPersonLookups = (state: IRootState) => state.lookups.personLookups;
const getMandatorySettings = (state: IRootState) => state.incidentsState.incidentsSettings;
const getCurrentUserId = (state: IRootState) => state.app.userId;
const getCanArchive = (state: IRootState) =>
    checkPermission('IncidentArchive', state.app.permissions);
const getCanCreate = (state: IRootState) =>
    checkPermission("IncidentCreate", state.app.permissions);
const getCanUpdate = (state: IRootState) =>
    checkPermission("IncidentUpdate", state.app.permissions);

export default createSelector(
    getSiteId,
    getIncident,
    getIsLoading,
    getIsSaving,
    getPersonLookups,
    getMandatorySettings,
    getCurrentUserId,
    getCanArchive,
    getCanCreate,
    getCanUpdate,
    (
        siteId,
        incident,
        isLoading,
        isSaving,
        users,
        incidentSettings,
        currentUserId,
        canArchive,
        canCreate,
        canUpdate
    ) => ({
        siteId,
        incident: {
            ...incident,
            siteId: incident.siteId || siteId,
        },
        isLoading,
        isSaving,
        users,
        incidentSettings,
        currentUserId,
        canArchive,
        canCreate,
        canUpdate
    })
);
