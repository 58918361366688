import React, { useEffect } from 'react';
import Select from 'react-select';
import { IKeyValue } from '../../models/key-value';

export interface IStateProps {
    lookupSites?: Array<IKeyValue<string>>;
    siteId?: string;
    permissions: Array<IKeyValue<string>>;
    isClient: boolean;
    onLoadSite: (id: string) => void;
    onLoadMenu: (operatingPlatform: string) => void;
}

export interface IDispatchProps {
    onSiteChange?: (value: string) => void;
}

export const SideNavSiteSelector = ({
    lookupSites,
    siteId,
    permissions,
    onSiteChange,
    onLoadSite,
    onLoadMenu,
}) => {
    useEffect(() => {
        if (siteId) {
            const operatingPlatform = getOperatingPlatform(siteId, lookupSites);
            onLoadSite(siteId);
            onLoadMenu(operatingPlatform);
        }

        if (!permissions || permissions.length < 1) {
            return;
        }
    }, [siteId, permissions]);

    const getOperatingPlatform = (selectedSiteId: string, lookupSites: any) => {
        const site = lookupSites.find(x => x.key === selectedSiteId);
        return site ? site.operatingPlatform : '';
    };

    const selectedValue = () => {
        if (lookupSites.length > 0) {
            const currentValue = lookupSites.find(f => f.key === siteId);
            if (currentValue) {
                return { value: currentValue.key, label: currentValue.value };
            }
        }
        return null;
    };

    return (
        <Select
            id="site-selector"
            className="site_selector"
            isClearable={false}
            isMulti={false}
            value={selectedValue()}
            onChange={(value: any) => {
                onSiteChange(value.value);
            }}
            options={lookupSites.map(x => ({ value: x.key, label: x.value }))}
        />
    );
};
