import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { connect } from 'react-redux';
import { ShiftHandoverAdminUpsertPage } from './shift-handover-admin-upsert-page';
import {
    loadSpecificSiteShiftChecklist,
    createSpecificSiteShiftChecklist,
} from 'actions/actions-v2/shift-handover-actions';

const getSiteId = (state: IRootState) => state.app.siteId;
const getAdminShiftCheckList = (state: IRootState) => state.shiftHandoverState.adminShiftChecklist;
const getAdminShiftCheckListLoading = (state: IRootState) => state.shiftHandoverState.isLoading;

const mapStateToProps = createSelector(
    getSiteId,
    getAdminShiftCheckList,
    getAdminShiftCheckListLoading,
    (siteId, shiftCheckList, isLoading) => ({
        siteId,
        shiftCheckList,
        isLoading,
    })
);

const mapDispatchToProps = { loadSpecificSiteShiftChecklist, createSpecificSiteShiftChecklist };

export const shiftHandoverAdminUpsertPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ShiftHandoverAdminUpsertPage);
