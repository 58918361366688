import { isEmpty } from 'lodash';
import * as React from 'react';
import { GridContentExpander } from 'components/gridContentExpander';
import { IEmbeddedLink } from 'models/embeddedLink';

export interface IProps {
    link: string;
    title: string;
    items: IEmbeddedLink[];
}

export class EmbeddedItemList extends React.Component<IProps>  {

    public state = {
        isOpen: false
    };

    constructor(props: IProps) {
        super(props);

        this.onToggle = this.onToggle.bind(this);
    }

    public onToggle() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    public render() {

        return (
            <GridContentExpander
                buttonText={this.props.title}
                isExpanded={this.state.isOpen}
                isLoading={false}
                className="linked-actions"
                onOpen={this.onToggle}
                onClose={this.onToggle}
            >
                {isEmpty(this.props.items) && (
                    <p>No {this.props.title}.</p>
                )}

                {!isEmpty(this.props.items) && (
                    <div className="grid-data">
                        {this.props.items.map((item, i) => (
                            <div className="columns horizontal-table" key={i}>
                                <div className="column is-5">
                                    <div>
                                        <a
                                            title={item.displayLabel}
                                            href={this.props.link}
                                        >
                                            {item.displayLabel}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </GridContentExpander>
        );
    }
}
