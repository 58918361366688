import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { loadMasterProcessSteps } from 'actions/actions-v2/master-process-step-action-v2';
import { CermAssessmentProcessStepPage } from './cerm-assessment-process-step-page';
import {
    loadProcessStepAnswer,
    setProcessStepAnswer,
    loadCermAssessmentResult,
    loadCermAssessmentExpertReview,
} from 'actions/actions-v2/cerm-assessment-action-v3';
import { getPermissions } from 'sharedSelectors';

const getIsLoading = (state: IRootState) =>
    state.masterProcessStepState.isLoading || state.cermAssessmentResultState.isLoading;
const getSiteId = (state: IRootState) => state.app.siteId;
const getMasterProcessSteps = (state: IRootState) =>
    state.masterProcessStepState.masterProcessSteps;
const getProcessStepAnswer = (state: IRootState) =>
    state.cermAssessmentResultState.processStepAnswer;
const getCermAssessmentResult = (state: IRootState) =>
    state.cermAssessmentResultState.cermAssessmentResult;
const getSite = (state: IRootState) => state.siteState.site;
const getExpertReviewStatus = (state: IRootState) =>
    state.cermAssessmentResultState.expertReviewStatus;
const getOperatingPlatforms = (state: IRootState) => state.operatingPlatformState.operatingPlatforms;

const mapStateToProps = createSelector(
    getIsLoading,
    getSiteId,
    getMasterProcessSteps,
    getProcessStepAnswer,
    getCermAssessmentResult,
    getSite,
    getPermissions,
    getExpertReviewStatus,
    getOperatingPlatforms,
    (
        isLoading,
        siteId,
        masterProcessSteps,
        processStepAnswer,
        cermAssessmentResult,
        site,
        permissions,
        expertReviewStatus,
        allOperatingPlatforms
    ) => ({
        isLoading,
        siteId,
        masterProcessSteps,
        processStepAnswer,
        cermAssessmentResult,
        site,
        permissions,
        expertReviewStatus,
        allOperatingPlatforms
    })
);

const mapDispatchToProps = {
    loadMasterProcessSteps,
    loadProcessStepAnswer,
    loadCermAssessmentResult,
    setProcessStepAnswer,
    loadCermAssessmentExpertReview,
};

export const CermAssessmentProcessStepPageContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CermAssessmentProcessStepPage)
);
