export type EmeraldButtonColorType =
  | 'none'
  | 'primary'
  | 'accent'
  | 'warn'
  | 'error';

export enum EmeraldButtonColor {
  None = 'none',
  Primary = 'primary',
  Accent = 'accent',
  Warn = 'warn',
  Error = 'error',
}
