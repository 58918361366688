import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { Faqs } from './faqs';
import { loadFaqs, updateFaqs, createFaqs } from 'actions/actions-v2/faqs-action-v2'

const getLanguage = (state: IRootState) => state.person.personProfile.language;
const getFaqsContent = (state: IRootState) => state.faqsState.faqsContent;
const getIsLoading = (state: IRootState) => state.faqsState.isLoading;
const getPermissions = (state: IRootState) => state.app.permissions;
const getSiteId = (state: IRootState) => state.app.siteId;
const getLookupSites = (state: IRootState) => state.app.lookupSites;

const mapStateToProps = createSelector(
    getLanguage,
    getFaqsContent,
    getIsLoading,
    getPermissions,
    getSiteId,
    getLookupSites,
    (
        language,
        faqsContent,
        isLoading,
        permission,
        siteId,
        lookupSites
    ) => ({
        language, faqsContent, isLoading, permission, siteId, lookupSites
    })
);

const mapDispatchToProps = {
    loadFaqs,
    updateFaqs,
    createFaqs
};


export const FaqsContainer = connect(mapStateToProps, mapDispatchToProps)(Faqs);
