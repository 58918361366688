import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { getIsClient } from 'sharedSelectors';

const getSiteId = (state: IRootState) => state.app.siteId;
const getIsLoading = (state: IRootState) =>
    state.riskRegisterState.isLoading || state.lookups.isLoading;
const getRisk = (state: IRootState) => state.riskRegisterState.risk;
const getPersonLookups = (state: IRootState) => state.lookups.personLookups;
const getUploaded = (state: IRootState) => state.attachmentState.saved;
const getIsUploading = (state: IRootState) => state.attachmentState.isSaving;
const getAttachments = (state: IRootState) => state.riskRegisterState.attachments;

export default createSelector(
    getSiteId,
    getIsLoading,
    getRisk,
    getPersonLookups,
    getIsClient,
    getAttachments,
    getUploaded,
    getIsUploading,
    (siteId, isLoading, risk, users, isClient, attachments, uploaded, isUploading) => ({
        siteId,
        isLoading,
        risk: { ...risk, siteId },
        users,
        isClient,
        attachments,
        uploaded,
        isUploading,
    })
);
