import * as React from 'react';
import Page from 'components/v2/page/Page';
import { RouteComponentProps } from 'react-router';
import { history } from 'routes/App';
import { TabGroup, TabItem } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';
import { ShiftsHandoverAdminToolbar } from './shift-handover-admin-toolbar';
import { HelpModuleContainer } from 'routes/help-module/help-module-container';
import { ModuleType } from 'models/help-module';
import { ShiftHandoverAdminPageContainer } from './shift-handover-admin-container';
import { ShiftHandoverCategoryTableContainer } from './shift-handover-category/shift-handover-category-table-container';

interface IParams {
    id?: string;
    tab: string;
    siteId?: string;
    date?: string;
    facilityId?: string;
}

interface IProps extends RouteComponentProps<IParams> {
    isLoading: boolean;
}

enum Tab {
    shifthandoverchecklist = 'shifthandoverchecklist',
    shifthandoveritemcategory = 'shifthandoveritemcategory',
}

export const ShiftHandoverAdminUpsertPage: React.FC<IProps> = ({
    match,
    isLoading,
}) => {
    const currentTab = match.params.tab.toLowerCase();
    const [showHelp, setShowHelp] = React.useState(false);

    const setNavLink = (tab: Tab) => {
        return match.path.replace(':tab', tab)
    };

    const handleClick = (event: any, tab: Tab) => {
        event.preventDefault();
        history.push(setNavLink(tab));
    };

    const renderTab = () => {
        switch (currentTab) {
            case Tab.shifthandoverchecklist:
                return (
                    <ShiftHandoverAdminPageContainer />
                );
            case Tab.shifthandoveritemcategory:
                return (
                    <ShiftHandoverCategoryTableContainer />
                );
        }
    };

    const onClickHelp = () => {
        setShowHelp(!showHelp);
    };

    const translate = useTranslate();

    return (

        <Page
            title={translate('ShiftHandoversAdminPage.Title')}
            className="shift-handover-admin-page"
        >
            <ShiftsHandoverAdminToolbar onClickHelp={onClickHelp} isShowHelp={true} />
            <HelpModuleContainer
                isShown={showHelp}
                onClose={onClickHelp}
                moduleType={ModuleType.ShiftHandover}
            />

            <TabGroup isLoading={isLoading}>
                <TabItem
                    title={translate('ShiftHandoversAdminPage.Tab.ShiftHandoverChecklist')}
                    tab={Tab.shifthandoverchecklist}
                    currentTab={currentTab}
                    onClick={handleClick}
                />
                <TabItem
                    title={translate('ShiftHandoversAdminPage.Tab.HandoverCategory')}
                    tab={Tab.shifthandoveritemcategory}
                    currentTab={currentTab}
                    onClick={handleClick}
                />
            </TabGroup>
            {renderTab()}
        </Page>
    );
};
