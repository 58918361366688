import { connect } from 'react-redux';

import { LinkedBuildings } from '../../../../components/linkedBuildings';
import actions from './actions';
import selector from './selector';

export const RiskLinkedBuildings = connect(
    selector,
    actions
)(LinkedBuildings);
