import { IAppAction } from '../app-action';
import { IOpportunityQuestionnaire } from 'models/opportunity-question-set';

export enum OpportunityQuestionnaireActions {
    LOAD_OPPORTUNITY_QUESTIONNAIRE = '@@opportunityQuestionnaire/LOAD_OPPORTUNITY_QUESTIONNAIRE',
    LOAD_OPPORTUNITY_QUESTIONNAIRE_FULFILLED = '@@OpportunityQuestionnaireActions/LOAD_OPPORTUNITY_QUESTIONNAIRE_FULFILLED',
    LOAD_OPPORTUNITY_QUESTIONNAIRE_REJECTED = '@@OpportunityQuestionnaireActions/LOAD_OPPORTUNITY_QUESTIONNAIRE_REJECTED',

    CREATE_OPPORTUNITY_ANSWERS = '@@OpportunityQuestionnaireActions/CREATE_OPPORTUNITY_ANSWERS',
    CREATE_OPPORTUNITY_ANSWERS_FULFILLED = '@@OpportunityQuestionnaireActions/CREATE_OPPORTUNITY_ANSWERS_FULFILLED',
    CREATE_OPPORTUNITY_ANSWERS_REJECTED = '@@OpportunityQuestionnaireActions/CREATE_OPPORTUNITY_ANSWERS_REJECTED',

    UPDATE_OPPORTUNITY_ANSWERS = '@@OpportunityQuestionnaireActions/UPDATE_OPPORTUNITY_ANSWERS',
    UPDATE_OPPORTUNITY_ANSWERS_FULFILLED = '@@OpportunityQuestionnaireActions/UPDATE_OPPORTUNITY_ANSWERS_FULFILLED',
    UPDATE_OPPORTUNITY_ANSWERS_REJECTED = '@@OpportunityQuestionnaireActions/UPDATE_OPPORTUNITY_ANSWERS_REJECTED',

    RESET_OPPORTUNITY_QUESTIONNAIRE = '@@OpportunityQuestionnaireActions/RESET_OPPORTUNITY_QUESTIONNAIRE',
}

export const loadOpportunityQuestions = (siteId: string): IAppAction => ({
    type: OpportunityQuestionnaireActions.LOAD_OPPORTUNITY_QUESTIONNAIRE,
    payload: { siteId },
});

export const createOpportunityAnswers = (model: IOpportunityQuestionnaire): IAppAction => ({
    type: OpportunityQuestionnaireActions.CREATE_OPPORTUNITY_ANSWERS,
    payload: model,
});

export const updateOpportunityAnswers = (model: IOpportunityQuestionnaire): IAppAction => ({
    type: OpportunityQuestionnaireActions.UPDATE_OPPORTUNITY_ANSWERS,
    payload: model,
});

export const resetQuestionnaire = (): IAppAction => ({
    type: OpportunityQuestionnaireActions.RESET_OPPORTUNITY_QUESTIONNAIRE,
});
