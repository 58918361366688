import { createSelector } from 'reselect';

import { ICreateQuestionSetRootState } from '../../../../reducers/create-question-set';

export default createSelector(
    (state: ICreateQuestionSetRootState) => state,
    (state) => ({
        isSubmitting: state.editQuestionSet.isSubmitting
    })
);
