import * as React from 'react';
import { FormikProps } from 'formik';
import { IMockDrillReport, IMockDrillReportsSummary } from 'models/mock-drills';
import { Loader } from 'components/loader';
import { ReadOnlyField } from 'components/form/fields/read-only-field';
import { formatDateString } from 'utils/date-utils';
import { scenarioTypeLabel } from 'routes/mock-drills/common';
import useTranslate from 'translations/translation-utils';

interface IProps {
    isLoading: boolean;
    formikBag: FormikProps<IMockDrillReport>;
    mockDrillReportsSummary: IMockDrillReportsSummary;
    scenarioType: string;
}

export const MockDrillReportDetailsReadOnlyTab: React.FC<IProps> = ({
    isLoading,
    formikBag,
    mockDrillReportsSummary,
    scenarioType,
}) => {
    const translate = useTranslate();
    const drillDescription = mockDrillReportsSummary && (
        <p className="page-description">{mockDrillReportsSummary.description}</p>
    );

    return (
        <div className="tabs-container tab-content">
            <Loader loading={isLoading}>
                {mockDrillReportsSummary && mockDrillReportsSummary.description && (
                    <div className="columns">
                        <div className="column is-12 mock-drill-info">{drillDescription}</div>
                    </div>
                )}
                <div className="columns">
                    <div className="column is-4">
                        <ReadOnlyField
                            id="drillLeader"
                            label={translate('MockDrills.Lable.1')}
                            value={formikBag.values.drillLeader}
                        />
                    </div>
                    <div className="column is-4">
                        <ReadOnlyField
                            id="dateOfDrill"
                            label={translate('SchedulerWeek.Button.Calendar.buttonText')}
                            value={formatDateString(
                                formikBag.values.dateOfDrill,
                                'dddd D MMMM YYYY'
                            )}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <ReadOnlyField
                            id="eopTitle"
                            label={`${scenarioTypeLabel(scenarioType)}`}
                            value={formikBag.values.eopTitle}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <ReadOnlyField
                            id="actionsDescription"
                            label={translate('MockDrills.Lable.2')}
                            value={formikBag.values.actionsDescription}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <ReadOnlyField
                            id="lessonsLearnedDescription"
                            label={translate('MockDrills.Lable.3')}
                            value={formikBag.values.lessonsLearnedDescription}
                        />
                    </div>
                </div>
            </Loader>
        </div>
    );
};
