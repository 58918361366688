import { createSelector } from 'reselect';
import { IRootCermSiteOverviewState } from 'reducers/cerm-site-overview';

export default createSelector(
    (state: IRootCermSiteOverviewState) => state.cermSiteOverview,
    (state) => ({
        expandedFilters: state.expandedFilters,

        regionFilters: state.regionFilters,
        lineOfBusinessFilters: state.lineOfBusinessFilters,
        otherFilters: state.otherFilters,

        lookupRegionFilters: state.lookupRegionFilters,
        lookupLineOfBusinessFilters: state.lookupLineOfBusinessFilters,
        lookupOtherFilters: state.lookupOtherFilters,
    })
);
