import {
    loadRiskAssessment,
    setRiskAssessmentAnswers,
} from 'actions/actions-v2/risk-assessment-action-v2';
import { loadPersonsLookup } from 'actions/actions-v2/person-action-v2';
export default {
    loadRiskAssessment,
    setRiskAssessmentAnswers,
    loadPersonsLookup,
};
