import { TriState } from '../shared/checkbox.state';
import { IEmeraldDisabledInput } from '../shared/disabled.input';
import { IEmeraldIdInput } from '../shared/id.input';
import { IEmeraldLabelInput } from '../shared/label.input';
import { IEmeraldRequiredInput } from '../shared/required.input';
import { IEmeraldTitleInput } from '../shared/title.input';
import { IEmeraldClassesInput } from '../shared/classes.input';

export class EmeraldCheckboxCoreInputs implements IEmeraldCheckboxCoreInputs {
  disabled?: boolean;
  id?: string = `EmeraldCheckbox-${Math.random().toString().substring(2)}`;
  label?: string;
  required?: boolean;
  title?: string;
  name?: string;
  value?: string;
  checked?: TriState;
  labelPlacement?: string;
  color?: string;
  indeterminate?: boolean;
  classes?: string;
}

export interface IEmeraldCheckboxCoreInputs
  extends IEmeraldDisabledInput,
    IEmeraldIdInput,
    IEmeraldLabelInput,
    IEmeraldRequiredInput,
    IEmeraldTitleInput,
    IEmeraldClassesInput {
  name?: string;
  value?: string;
  checked?: TriState;
  labelPlacement?: string;
  color?: string;
  indeterminate?: boolean;
}
