import React from 'react';
import { Radio } from 'components/form';
import { Loader } from 'components/loader';
import { ConfirmDialog } from 'components/v2/components';
import { IPerson } from 'models/person';
import './role-selector.scss';
import useTranslate from 'translations/translation-utils';
import { getAuthenticationPlatform } from 'helpers/authentication';

interface IProps {
    personProfile: IPerson;
    isShowRoleSelectorDialog: boolean;
    signOffAfterRoleUpdate: boolean;
    isLoading: boolean;
    updatePreferredRole: (person: IPerson) => void;
    showRoleSelectorDialog: (show: boolean) => void;
}

const RoleSelectorDialog: React.FC<IProps> = ({
    personProfile,
    isShowRoleSelectorDialog,
    signOffAfterRoleUpdate,
    isLoading,
    updatePreferredRole,
    showRoleSelectorDialog,
}) => {
    const translate = useTranslate();
    const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
    const [selectedRole, setSelectedRole] = React.useState('');
    const [showWarning, setShowWarning] = React.useState(false);
    const [confirmButtonDisabled, setConfirmButtonDisabled] = React.useState(true);

    React.useEffect(() => {
        if (personProfile) {
            setSelectedRole(personProfile.preferredRole);
        }
        if (personProfile.roles.length > 1 && !personProfile.preferredRole) {
            setShowWarning(false);
            setShowConfirmDialog(true);
            showRoleSelectorDialog(true);
        }
    }, [personProfile]);

    React.useEffect(() => {
        setShowWarning(false);
        setShowConfirmDialog(isShowRoleSelectorDialog);
    }, [isShowRoleSelectorDialog]);

    React.useEffect(() => {
        if (signOffAfterRoleUpdate) {
            getAuthenticationPlatform().signOut();
        }
    }, [signOffAfterRoleUpdate]);

    const onRoleChange = (e) => {
        const selected = e.target.value;

        setSelectedRole(selected);
        setShowWarning(personProfile.preferredRole !== selected);
        setConfirmButtonDisabled(personProfile.preferredRole === selected);
    };

    const onConfirm = () => {
        const person = { ...personProfile };

        if (person.preferredRole !== selectedRole) {
            person.preferredRole = selectedRole;
            updatePreferredRole(person);
        } else {
            showRoleSelectorDialog(false);
        }
    };

    const onCancel = () => {
        setSelectedRole(personProfile.preferredRole);
        showRoleSelectorDialog(false);
        setConfirmButtonDisabled(true);
    }
    const getBody = () => {
        return (
            <Loader loading={isLoading}>
                <p>{translate('RoleSelectorDialog.Label.YouHaveMoreThanOneRole')}</p>

                <p className="role-selector">
                    <Radio
                        id="preferredRole"
                        name="preferredRole"
                        options={personProfile.roles.map((c) => ({
                            value: c.value,
                            key: c.value,
                        }))}
                        value={selectedRole}
                        isShowWarning={showWarning}
                        onChange={onRoleChange}
                        warningLabel={translate(
                            'RoleSelectorDialog.Label.YouWillBeLoggedOutFollowingARoleUpdate'
                        )}
                    />
                </p>
                <p>&nbsp;</p>
                <p>
                    <p>
                        <strong>{translate('RoleSelectorDialog.Label.Notes')}:</strong>
                    </p>
                    <p>
                        - {translate('RoleSelectorDialog.Label.NormallyAUserShouldHaveOneRoleOnly')}
                        <a
                            href="https://quantum.cbre.com/access"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <strong>{translate('RoleSelectorDialog.Label.Here')}</strong></a>.
                        <br />
                        {translate(
                            'RoleSelectorDialog.Label.YouWillBeLoggedOutFollowingARoleUpdate'
                        )}
                    </p>
                </p>
            </Loader>
        );
    };

    return (
        <ConfirmDialog
            title={translate('NavDropDown.ChangeRole')}
            message={getBody()}
            isVisible={showConfirmDialog}
            isLoading={isLoading}
            showConfirmButton={true}
            onConfirm={onConfirm}
            onClose={onCancel}
            buttonConfirmText={translate('Globals.Label.Confirm')}
            buttonCancelText={translate('Globals.Label.Cancel')}
            confirmButtonDisabled={confirmButtonDisabled}
        />
    );
};

export default RoleSelectorDialog;
