import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { getIsLoading } from '../../selectors';

const getPersonLookups = (state: IRootState) => state.lookups.personLookups;

const getLookupSites = createSelector(
    (state: IRootState) => state.app,
    (state: any) => state.lookupSites
);

export default createSelector(
    getLookupSites,
    getPersonLookups,
    getIsLoading,
    (lookupSites, personLookups, isLoading) => ({
        lookupSites,
        users: personLookups,
        isLoading
    })
);
