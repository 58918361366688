import React from 'react';
import useTranslate from 'translations/translation-utils';
import { Formik, FormikProps } from 'formik';
import { ICheckListItem, IShiftChecklist, ISiteArea } from 'models/shifts/master-shift-checklist';
import {
    Panel,
    Table,
    ConfirmNavigateAwayDialog,
    Toolbar,
    ToolbarGroupRight,
    FloatingButton,
    ConfirmDialog,
    Button,
    ToolbarGroupLeft,
    ButtonWithConfirmDialog,
    ToolbarGroupCenter,
} from 'components/v2/components';
import { TextAreaField } from 'components/form/fields/textarea-field';
import { Loader } from 'components/loader';
import { newGuid } from 'utils/id-utils';
import { Help, Input } from 'components/form';
import _ from 'lodash';
import { array, object } from 'yup';
import { BulmaColor } from 'enums/BulmaColor';
import { getKeyed } from 'utils/form-utils';
import ReactTooltip from 'react-tooltip';
import { MaterialIcons } from '../material-icon/material-icon';
import { MaterialIconType, MaterialIconColor } from '../material-icon/material-icon-type';
enum DialogType {
    CreateSiteArea,
    CreateCheckListItem,
    EditCheckLitItem,
    EditSiteArea,
}

interface IProps {
    shiftCheckList?: IShiftChecklist[];
    isLoading?: boolean;
    siteId?: string;
    loadSpecificSiteShiftChecklist?: (siteId: string) => void;
    createSpecificSiteShiftChecklist?: (model: IShiftChecklist) => void;
    updateSpecificSiteShiftChecklist?: (model: IShiftChecklist) => void;
    deleteSpecificSiteShiftChecklist?: (id: string, siteId: string) => void
}

const ShiftHandoverAdminPage: React.FC<IProps> = ({
    shiftCheckList,
    loadSpecificSiteShiftChecklist,
    createSpecificSiteShiftChecklist,
    updateSpecificSiteShiftChecklist,
    deleteSpecificSiteShiftChecklist,
    siteId,
    isLoading,
}) => {
    const translate = useTranslate();

    const getNewCheckListItem = () => {
        return {
            id: newGuid(),
            isEnabled: true,
            isDefault: false,
        } as ICheckListItem;
    };

    const getNewSiteArea = () => {
        return {
            id: newGuid(),
            isDefault: false,
            isEnabled: true,
        } as ISiteArea;
    };

    const getNewTemplateItem = () => {
        return {
            isEnabled: true,
            isDefault: false,
        } as IShiftChecklist;
    };
    const [checkListItem, setCheckListItem] = React.useState(getNewCheckListItem());
    const [editAreaInput, setEditAreaInput] = React.useState<string>('');
    const [editInput, setEditInput] = React.useState<string>('');
    const [newInput, setNewInput] = React.useState<string>('');
    const [addNew, setAddNew] = React.useState<boolean>(false);
    const [addNewIndex, setAddNewIndex] = React.useState<number>(null);
    const [editId, setEditId] = React.useState<string>('');
    const [editAreaIndex, setEditAreaIndex] = React.useState<number>(null);
    const [siteArea, setSiteArea] = React.useState(getNewSiteArea());
    const [editingSiteAreaName, setEditingSiteAreaName] = React.useState('');
    const [editingChecklistId, setEditingChecklist] = React.useState('');
    const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
    const [path, setPath] = React.useState('');
    const [dialogType, setDialogType] = React.useState(null as DialogType);
    const [currentTemplate, setCurrentTemplate] = React.useState('');
    const [updatedTemplate, setUpdatedTemplate] = React.useState('');
    const [showConfirmTemplateDialog, setShowConfirmTemplateDialog] = React.useState(false);
    const [templateItem, setTemplateItem] = React.useState(getNewTemplateItem());
    const [editTemplate, setEditTemplate] = React.useState('');
    const [isTemplateEdit, setIsTemplateEdit] = React.useState(false);
    const [newShiftHandoverCheckList, setNewShiftHandoverChecklist] = React.useState([]);
    const [isShowNavigationAwayDialog, setIsShowNavigationAwayDialog] = React.useState(false);
    const [isShowTemplateError, setIsShowTemplateError] = React.useState(false);

    const valSchema = object<Partial<IShiftChecklist>>().shape({
        siteAreas: array().of(
            object<ICheckListItem>().shape({
                checkListItems: array().min(1, translate('ShiftHandoversAdminPage.Error.SiteAreaError'))
            })
        ),
    });

    const myRef = React.useRef(null);
    const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })

    React.useEffect(() => {
        if (siteId) {
            loadSpecificSiteShiftChecklist(siteId);
            setTemplateItem({ ...templateItem, siteId })
        }
    }, [siteId, loadSpecificSiteShiftChecklist]);
    React.useEffect(() => {
        if (isTemplateEdit) {
            executeScroll()
        }
    }, [isTemplateEdit]);

    React.useEffect(() => {
        if (shiftCheckList) {
            setNewShiftHandoverChecklist(shiftCheckList)
            setUpdatedTemplate(shiftCheckList[0] ? shiftCheckList[0].templateLinkId : '')
        }
    }, [shiftCheckList])

    React.useEffect(() => {
        ReactTooltip.rebuild();
    });
    const handleSiteAreaUpdate = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setSiteArea({ ...siteArea, name: event.target.value });
    };

    const handleCheckListItemUpdate = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCheckListItem({ ...checkListItem, name: event.target.value });
    };
    const handleTemplateItemUpdate = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTemplateItem({ ...templateItem, templateName: event.target.value });
    };

    const onSubmitForm = (model: IShiftChecklist) => {
        updateSpecificSiteShiftChecklist(model);
    };

    const renderForm = (formik: FormikProps<IShiftChecklist>) => {
        const isOperatingPlatformExist =
            (formik.values !== undefined && formik.values.operatingPlatform && formik.values.operatingPlatform.trim() !== "");

        const findCheckList = (checkListId: string) => {
            return _(formik.values.siteAreas)
                .thru((coll) => {
                    const checkListItems: ICheckListItem[] = _.flatMap(coll, 'checkListItems') || [];
                    return _.union(coll, checkListItems);
                })
                .flatten()
                .find({ id: checkListId });
        };

        const findElement = (checkListId: string) => {
            formik.values.siteAreas.forEach((element, index) => {
                element.checkListItems.forEach((e, i) => {
                    if (e.id === checkListId) {
                        setPath(`siteAreas[${index}].checkListItems[${i}]`);
                    }
                });
            });

            return findCheckList(checkListId);
        };

        const resetAll = () => {
            setCheckListItem(getNewCheckListItem());
            setSiteArea(getNewSiteArea());
            setEditingSiteAreaName('');
            setEditingChecklist('');
            setShowConfirmDialog(false);
            setEditTemplate('');
            setTemplateItem({ ...getNewTemplateItem(), siteId: siteId })
            setIsShowTemplateError(false)
        };

        const createSiteAreaDialog = () => {
            resetAll();
            setShowConfirmDialog(true);
            setDialogType(DialogType.CreateSiteArea);
        };

        const editSiteAreaDialog = (index: number) => {
            setEditAreaIndex(index);
            const area = formik.values.siteAreas[index];
            setEditAreaInput(area.name);
        };

        const createChecklistItemDialog = (siteAreaName: string, index: number) => {
            setEditingSiteAreaName(siteAreaName);
            setAddNew(true);
            setAddNewIndex(index);
        };
        const onHandleEditChange = (event: any) => {
            const msg = event.target.value;
            setEditInput(msg);
        };
        const onHandleEditAreaChange = (event: any) => {
            const msg = event.target.value;
            setEditAreaInput(msg);
        };
        const onHandleChange = (event: any) => {
            const msg = event.target.value;
            setNewInput(msg);
        };
        const onHandleEditBlur = (event: any) => {
            const msg = event.target.value;

            if (msg.trim() !== '') {
                const element = findElement(editId);
                formik.setFieldValue(path, { ...element, name: msg });
            }

            setEditInput('');
            setEditId('');
        };

        const onHandleEditAreaBlur = (event: any) => {
            const msg = event.target.value;

            if (msg.trim() !== '') {
                const element = { ...formik.values.siteAreas[editAreaIndex], name: msg };
                formik.setFieldValue(`siteAreas[${editAreaIndex}]`, element);
            }

            setEditAreaInput('');
            setEditAreaIndex(null);
        };

        const onTrashSiteArea = (siteIndex: number) => {
            const siteAreas = [
                ...formik.values.siteAreas.slice(0, siteIndex),
                ...formik.values.siteAreas.slice(siteIndex + 1),
            ];
            formik.setFieldValue('siteAreas', siteAreas);
        };

        const onTrash = (checkListId: string, siteIndex: number) => {
            const checkListItems = formik.values.siteAreas[siteIndex].checkListItems.filter(
                (x) => x.id !== checkListId
            );

            formik.setFieldValue(`siteAreas[${siteIndex}].checkListItems`, checkListItems);
        };
        const onHideShow = (checkListId: string, siteIndex: number, isEnabled: boolean) => {
            const checkLists = formik.values.siteAreas[siteIndex].checkListItems.map((m) => {
                if (m.id === checkListId) {
                    return {
                        ...m,
                        isEnabled,
                    };
                }
                return m;
            });

            formik.setFieldValue(`siteAreas[${siteIndex}].checkListItems`, checkLists);
        };
        const onHideShowArea = (siteIndex: number, isEnabled: boolean) => {
            const area = { ...formik.values.siteAreas[siteIndex], isEnabled };

            formik.setFieldValue(`siteAreas[${siteIndex}]`, area);
        };

        const onHide = (checkListId: string, siteIndex: number) => {
            onHideShow(checkListId, siteIndex, false);
        };
        const onShow = (checkListId: string, siteIndex: number) => {
            onHideShow(checkListId, siteIndex, true);
        };
        const onHideArea = (siteIndex: number) => {
            onHideShowArea(siteIndex, false);
        };
        const onShowArea = (siteIndex: number) => {
            onHideShowArea(siteIndex, true);
        };
        const onHandleBlur = (event: any) => {
            const msg = event.target.value.trim();

            if (msg) {
                const element = {
                    id: newGuid(),
                    isEnabled: true,
                    isDefault: false,
                    name: msg,
                } as ICheckListItem;

                const checkListItems = [
                    ...formik.values.siteAreas[addNewIndex].checkListItems,
                    element,
                ];

                formik.setFieldValue(`siteAreas[${addNewIndex}].checkListItems`, checkListItems);
            }

            setAddNewIndex(null);
            setAddNew(false);
            setNewInput('');
        };
        const getErrors = (pathName: string) => getKeyed(formik, 'errors', pathName);

        const editChecklistItemDialog = (checklistItemId: string) => {
            setEditId(checklistItemId);
            const element = findElement(checklistItemId);
            setEditInput(element.name);
        };
        const renderTextBox = () => {
            return (
                <>
                    <div className="field">
                        <div className="control">
                            <Input
                                value={newInput}
                                onChange={onHandleChange}
                                onBlur={onHandleBlur}
                            />
                        </div>
                    </div>
                </>
            );
        };
        const renderEditTextBox = (id: string) => {
            return (
                <>
                    <div className="field">
                        <div className="control">
                            <Input
                                id={id}
                                value={editInput}
                                onChange={onHandleEditChange}
                                onBlur={onHandleEditBlur}
                            />
                        </div>
                    </div>
                </>
            );
        };

        const renderEditAreaTextBox = (id: number) => {
            return (
                <>
                    <div className="field">
                        <div className="control">
                            <Input
                                id={id.toString()}
                                value={editAreaInput}
                                onChange={onHandleEditAreaChange}
                                onBlur={onHandleEditAreaBlur}
                            />
                        </div>
                    </div>
                </>
            );
        };

        const onConfirmAddCheckListItem = () => {
            switch (dialogType) {
                case DialogType.CreateSiteArea:
                    if (siteArea.name && checkListItem.name) {
                        formik.setFieldValue('siteAreas', [
                            ...formik.values.siteAreas,
                            {
                                ...siteArea,
                                checkListItems: [
                                    {
                                        ...checkListItem,
                                    } as ICheckListItem,
                                ],
                            } as ISiteArea,
                        ]);
                    }
                    break;
                case DialogType.EditSiteArea:
                    if (siteArea.name && editingSiteAreaName) {
                        formik.setFieldValue(
                            'siteAreas',
                            formik.values.siteAreas.map((m) => {
                                if (m.name !== editingSiteAreaName) {
                                    return m;
                                } else {
                                    return {
                                        ...m,
                                        name: siteArea.name,
                                    };
                                }
                            })
                        );
                    }
                    break;
                case DialogType.CreateCheckListItem:
                    if (siteArea.name && editingSiteAreaName) {
                        formik.setFieldValue(
                            'siteAreas',
                            formik.values.siteAreas.map((m) => {
                                if (m.id !== editingSiteAreaName) {
                                    return m;
                                } else {
                                    return {
                                        ...m,
                                        checkListItems: m.checkListItems.push(checkListItem),
                                    };
                                }
                            })
                        );
                    }
                    break;

                case DialogType.EditCheckLitItem:
                    if (siteArea.name && editingSiteAreaName) {
                        formik.setFieldValue(
                            'siteAreas',
                            formik.values.siteAreas.map((m) => {
                                if (m.id !== editingSiteAreaName) {
                                    return m;
                                } else {
                                    return {
                                        ...m,
                                        checkListItems: m.checkListItems.map((mm) => {
                                            if (mm.id === editingChecklistId) {
                                                return {
                                                    ...mm,
                                                    name: checkListItem.name,
                                                };
                                            } else {
                                                return mm;
                                            }
                                        }),
                                    };
                                }
                            })
                        );
                    }
                    break;
            }
            resetAll();
        };

        const onSave = () => {
            formik.submitForm();
        };

        const getBody = () => {
            const showSiteAreaEditBox =
                dialogType === DialogType.CreateSiteArea || dialogType === DialogType.EditSiteArea;

            const showChecklistItemEditBox =
                dialogType === DialogType.CreateSiteArea ||
                dialogType === DialogType.CreateCheckListItem ||
                dialogType === DialogType.EditCheckLitItem;
            return (
                <>
                    <div className="columns">
                        <div className="column is-4" />
                    </div>
                    {showSiteAreaEditBox && (
                        <div className="columns">
                            <div className="column">
                                <TextAreaField
                                    id="siteArea"
                                    label={translate(
                                        'ShiftHandoverItemsTable.Fields.Items.SiteArea'
                                    )}
                                    value={siteArea.name}
                                    handleChange={handleSiteAreaUpdate}
                                />
                            </div>
                        </div>
                    )}
                    {showChecklistItemEditBox && (
                        <div className="columns">
                            <div className="column">
                                {
                                    <TextAreaField
                                        id="checkList"
                                        label={translate(
                                            'ShiftHandoverItemsTable.Fields.Items.label'
                                        )}
                                        value={checkListItem.name}
                                        handleChange={handleCheckListItemUpdate}
                                    />
                                }
                            </div>
                        </div>
                    )}
                </>
            );
        };

        const onMoveChecklistItemUp = (index, checklistIndex) => {
            const siteArea = _.cloneDeep(formik.values.siteAreas);
            const orderArea = siteArea[index].checkListItems;
            if (checklistIndex > 0) {
                [orderArea[checklistIndex], orderArea[checklistIndex - 1]] = [
                    orderArea[checklistIndex - 1],
                    orderArea[checklistIndex],
                ];
            }
            formik.setFieldValue('siteAreas', siteArea);
        };

        const onMoveChecklistItemDown = (index, checklistIndex) => {
            const siteArea = _.cloneDeep(formik.values.siteAreas);

            const orderArea = siteArea[index].checkListItems;
            if (checklistIndex < orderArea.length - 1) {
                [orderArea[checklistIndex], orderArea[checklistIndex + 1]] = [
                    orderArea[checklistIndex + 1],
                    orderArea[checklistIndex],
                ];
            }
            formik.setFieldValue('siteAreas', siteArea);
        };

        const onMoveSiteAreaUp = (index) => {
            const newSiteArea = _.cloneDeep(formik.values.siteAreas);
            if (index > 0) {
                [newSiteArea[index], newSiteArea[index - 1]] = [
                    newSiteArea[index - 1],
                    newSiteArea[index],
                ];
            }
            formik.setFieldValue('siteAreas', newSiteArea);
        };

        const onMoveSiteAreaDown = (index) => {
            const newSiteArea = _.cloneDeep(formik.values.siteAreas);
            if (index < formik.values.siteAreas.length - 1) {
                [newSiteArea[index], newSiteArea[index + 1]] = [
                    newSiteArea[index + 1],
                    newSiteArea[index],
                ];
            }
            formik.setFieldValue('siteAreas', newSiteArea);
        };

        const onTemplateButtonClickHandler = (currentTemplate: string) => {
            setIsTemplateEdit(false)
            setCurrentTemplate(currentTemplate);
            if (formik.dirty) {
                setIsShowNavigationAwayDialog(true)
                return
            } else {
                setUpdatedTemplate(currentTemplate);
            }
        };

        const getTemplateButtonClassName = (template: string) => {
            return template === updatedTemplate ? 'active' : '';
        };
        const getTemplateBody = () => {
            return (
                <div className="columns">
                    <div className="column">
                        {
                            <TextAreaField
                                id="templateLinkId"
                                label={translate('ShiftHandoverItemsTable.Fields.Items.TemplateName')}
                                value={templateItem.templateName}
                                handleChange={handleTemplateItemUpdate}
                                error={isShowTemplateError ? translate('ShiftHandoverItemsTable.Fields.Items.TemplateNameError') : ''}
                            />
                        }
                    </div>
                </div>
            )
        }
        const onConfirmAddSiftHandoverTemplate = () => {
            if (templateItem.templateName && templateItem.templateName.trim() !== '') {
                createSpecificSiteShiftChecklist(templateItem);
                setShowConfirmTemplateDialog(false)
                setIsShowTemplateError(false)
            } else {
                setIsShowTemplateError(true)
            }
        }
        const onEditShiftChecklistTemplate = () => {
            setEditTemplate(formik.values.templateName);
            setIsTemplateEdit(true);
        }
        const handleTemplateChange = (event) => {
            const msg = event.target.value;
            setEditTemplate(msg);
        }
        const onHandleEditTemplateBlur = (event, shiftChecklist: IShiftChecklist) => {
            const msg = event.target.value;
            if (msg.trim() !== '') {
                let updatedShiftChecklist = [...newShiftHandoverCheckList]
                setNewShiftHandoverChecklist(updatedShiftChecklist.map((item) => {
                    if (item.templateLinkId === shiftChecklist.templateLinkId) {
                        return ({
                            ...item,
                            templateName: msg
                        })
                    }
                    return item
                }))
                formik.setFieldValue('templateName', msg);
            }

            setEditTemplate('');
            setIsTemplateEdit(false)
        }
        const renderEditTemplateBox = (shiftChecklist: IShiftChecklist) => {
            return (
                <>
                    <div ref={myRef} className="shift-template-input field" >
                        <div className="control">
                            <Input
                                id={shiftChecklist.templateLinkId}
                                value={editTemplate}
                                onChange={handleTemplateChange}
                                onBlur={(e) => onHandleEditTemplateBlur(e, shiftChecklist)}
                            />
                        </div>
                    </div>
                </>
            );
        }
        const onArchiveTemplate = () => {
            deleteSpecificSiteShiftChecklist(formik.values.id, siteId)
        }
        const onConfirmNavigationAway = () => {
            setUpdatedTemplate(currentTemplate);
            setIsShowNavigationAwayDialog(false)
        }
        const createNewTemplate = () => {
            resetAll()
            setShowConfirmTemplateDialog(true)
        }
        return (
            <Loader loading={isLoading}>

                <Panel
                    title={translate('ShiftHandoverChecklistTable.Panel.Title')}
                    collapsed={null}
                >
                    <div
                        data-tip={translate('ShiftHandoversAdminPage.label.AddNewShiftChecklist')}
                        className="checkList-plus-icon"
                        onClick={createNewTemplate}
                    >
                        < MaterialIcons type={MaterialIconType.Add} color={MaterialIconColor.green} />
                    </div>
                    <div className="buttons has-addons">
                        {newShiftHandoverCheckList.map((template) => {
                            {
                                return isTemplateEdit && template.templateLinkId === updatedTemplate ? renderEditTemplateBox(template) : <Button
                                    className={getTemplateButtonClassName(template.templateLinkId)}
                                    onClick={() => onTemplateButtonClickHandler(template.templateLinkId)}
                                >
                                    {template.templateName}
                                </Button>
                            }
                        })}
                    </div>
                    {!!(newShiftHandoverCheckList.length === 1) && <p className='header-note'>{translate('ShiftHandoversAdminPage.label.YoucannotArchiveTemplate')}</p>}
                    <Table className="checklist">
                        <thead>
                            <tr>
                                <th>
                                    {translate('ShiftHandoverChecklistTable.TableHeaders.SiteArea')}
                                </th>
                                <th>
                                    {!isOperatingPlatformExist &&
                                        <div
                                            data-tip={translate('ShiftHandoversAdminPage.label.AddNewSiteArea')}
                                            onClick={createSiteAreaDialog}>
                                            < MaterialIcons type={MaterialIconType.Add} color={MaterialIconColor.green} />
                                        </div>
                                    }
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {formik.values &&
                                formik.values.siteAreas &&
                                formik.values.siteAreas.map((currentSiteArea, index) => {
                                    return (
                                        <>
                                            <tr className="header-category">
                                                <td>
                                                    {currentSiteArea.isDefault &&
                                                        translate(
                                                            'ShiftHandoverChecklistTable.SiteAreas.',
                                                            currentSiteArea.name
                                                        )}

                                                    {!currentSiteArea.isDefault &&
                                                        editAreaIndex !== index &&
                                                        currentSiteArea.name}
                                                    {!currentSiteArea.isDefault &&
                                                        editAreaIndex === index &&
                                                        renderEditAreaTextBox(index)}
                                                </td>
                                                <td>
                                                    <ul className="shift-checklist-icons">
                                                        {currentSiteArea.isDefault &&
                                                            currentSiteArea.isEnabled && (
                                                                <li
                                                                    className="is-pulled-right"
                                                                    onClick={() =>
                                                                        onHideArea(index)
                                                                    }
                                                                    data-tip={translate('ShiftHandoversAdminPage.label.Show')}
                                                                >
                                                                    < MaterialIcons type={MaterialIconType.Visibility} color={MaterialIconColor.green} />
                                                                </li>
                                                            )}
                                                        {currentSiteArea.isDefault &&
                                                            !currentSiteArea.isEnabled && (
                                                                <li
                                                                    className="is-pulled-right"
                                                                    onClick={() =>
                                                                        onShowArea(index)
                                                                    }
                                                                    data-tip={translate('ShiftHandoversAdminPage.label.Hide')}
                                                                >
                                                                    < MaterialIcons type={MaterialIconType.VisibilityOff} color={MaterialIconColor.green} />
                                                                </li>
                                                            )}
                                                        {(!isOperatingPlatformExist && !currentSiteArea.isDefault) && (
                                                            <>
                                                                <li
                                                                    className="is-pulled-right"
                                                                    onClick={() =>
                                                                        editSiteAreaDialog(index)
                                                                    }
                                                                    data-tip={translate('ShiftHandoversAdminPage.label.Edit')}
                                                                >
                                                                    < MaterialIcons type={MaterialIconType.Edit} color={MaterialIconColor.green} />
                                                                </li>

                                                                <li
                                                                    className="is-pulled-right"
                                                                    onClick={() =>
                                                                        onTrashSiteArea(index)
                                                                    }
                                                                    data-tip={translate('ShiftHandoversAdminPage.label.Delete')}
                                                                >
                                                                    < MaterialIcons type={MaterialIconType.Delete} color={MaterialIconColor.green} />
                                                                </li>
                                                            </>
                                                        )}
                                                        {!isOperatingPlatformExist && currentSiteArea.isEnabled && (
                                                            <>
                                                                <li
                                                                    className={`is-pulled-right ${currentSiteArea.isDefault
                                                                        ? 'shift-right'
                                                                        : ''
                                                                        }`}
                                                                    onClick={() =>
                                                                        createChecklistItemDialog(
                                                                            currentSiteArea.name,
                                                                            index
                                                                        )
                                                                    }
                                                                    data-tip={translate('ShiftHandoversAdminPage.label.AddNewCheckListItem')}
                                                                >
                                                                    < MaterialIcons type={MaterialIconType.Add} color={MaterialIconColor.green} />
                                                                </li>
                                                            </>
                                                        )}
                                                        <li className="is-pulled-right"
                                                            data-tip={translate('ShiftHandoversAdminPage.label.MoveUp')}
                                                        >
                                                            <a
                                                                id={'moveUpQuestionSet'}
                                                                onClick={() =>
                                                                    onMoveSiteAreaUp(index)
                                                                }
                                                                className={`${index === 0 ? 'hidden-link' : ''
                                                                    }`}
                                                            >
                                                                < MaterialIcons type={MaterialIconType.ArrowUp} color={MaterialIconColor.green} />
                                                            </a>

                                                        </li>
                                                        <li className="is-pulled-right"
                                                            data-tip={translate('ShiftHandoversAdminPage.label.MoveDown')}
                                                        >
                                                            <a
                                                                id={'moveDownQuestionSet'}
                                                                onClick={() =>
                                                                    onMoveSiteAreaDown(index)
                                                                }
                                                                className={`${index ===
                                                                    formik.values.siteAreas.length -
                                                                    1
                                                                    ? 'hidden-link'
                                                                    : ''
                                                                    }`}
                                                            >
                                                                < MaterialIcons type={MaterialIconType.ArrowDown} color={MaterialIconColor.green} />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            {currentSiteArea.isEnabled &&
                                                currentSiteArea.checkListItems.map(
                                                    (currentChecklistItem, itemIndex) => {
                                                        return (
                                                            <tr key={currentChecklistItem.id}>
                                                                <td className="site-area">
                                                                    {editId ===
                                                                        currentChecklistItem.id ? (
                                                                        renderEditTextBox(
                                                                            currentChecklistItem.id
                                                                        )
                                                                    ) : (
                                                                        <div>
                                                                            {translate(
                                                                                'ShiftHandoverChecklistTable.CheckLists.',
                                                                                currentChecklistItem.name
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    <ul className="shift-checklist">
                                                                        {(!isOperatingPlatformExist && !currentChecklistItem.isDefault) && (
                                                                            <li
                                                                                className="is-pulled-right"
                                                                                onClick={() =>
                                                                                    editChecklistItemDialog(
                                                                                        currentChecklistItem.id
                                                                                    )
                                                                                }
                                                                                data-tip={translate('ShiftHandoversAdminPage.label.Edit')}
                                                                            >
                                                                                < MaterialIcons type={MaterialIconType.Edit} color={MaterialIconColor.green} />
                                                                            </li>
                                                                        )}

                                                                        {(!isOperatingPlatformExist && !currentChecklistItem.isDefault) && (
                                                                            <li
                                                                                className="is-pulled-right"
                                                                                onClick={() =>
                                                                                    onTrash(
                                                                                        currentChecklistItem.id,
                                                                                        index
                                                                                    )
                                                                                }
                                                                                data-tip={translate('ShiftHandoversAdminPage.label.Delete')}
                                                                            >
                                                                                < MaterialIcons type={MaterialIconType.Delete} color={MaterialIconColor.green} />
                                                                            </li>
                                                                        )}
                                                                        {currentChecklistItem.isDefault &&
                                                                            currentChecklistItem.isEnabled && (
                                                                                <li
                                                                                    className="is-pulled-right"
                                                                                    onClick={() =>
                                                                                        onHide(
                                                                                            currentChecklistItem.id,
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                    data-tip={translate('ShiftHandoversAdminPage.label.Show')}
                                                                                >
                                                                                    < MaterialIcons type={MaterialIconType.Visibility} color={MaterialIconColor.green} />
                                                                                </li>
                                                                            )}

                                                                        {currentChecklistItem.isDefault &&
                                                                            !currentChecklistItem.isEnabled && (
                                                                                <li
                                                                                    className="is-pulled-right"
                                                                                    onClick={() =>
                                                                                        onShow(
                                                                                            currentChecklistItem.id,
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                    data-tip={translate('ShiftHandoversAdminPage.label.Hide')}
                                                                                >
                                                                                    < MaterialIcons type={MaterialIconType.VisibilityOff} color={MaterialIconColor.green} />
                                                                                </li>
                                                                            )}
                                                                        <li
                                                                            className={`is-pulled-right ${currentChecklistItem.isDefault
                                                                                ? 'shift-checklist-right'
                                                                                : 'shift-right'
                                                                                }`}
                                                                        >
                                                                            <a
                                                                                id={
                                                                                    'moveUpQuestionSet'
                                                                                }
                                                                                onClick={() =>
                                                                                    onMoveChecklistItemUp(
                                                                                        index,
                                                                                        itemIndex
                                                                                    )
                                                                                }
                                                                                className={`${itemIndex === 0
                                                                                    ? 'hidden-link'
                                                                                    : ''
                                                                                    }`}
                                                                                data-tip={translate('ShiftHandoversAdminPage.label.MoveUp')}
                                                                            >
                                                                                < MaterialIcons type={MaterialIconType.ArrowUp} color={MaterialIconColor.green} />
                                                                            </a>
                                                                        </li>
                                                                        <li className="is-pulled-right"
                                                                            data-tip={translate('ShiftHandoversAdminPage.label.MoveDown')} >
                                                                            <a
                                                                                id={
                                                                                    'moveDownQuestionSet'
                                                                                }
                                                                                onClick={() =>
                                                                                    onMoveChecklistItemDown(
                                                                                        index,
                                                                                        itemIndex
                                                                                    )
                                                                                }
                                                                                className={`${itemIndex ===
                                                                                    currentSiteArea
                                                                                        .checkListItems
                                                                                        .length -
                                                                                    1
                                                                                    ? 'hidden-link'
                                                                                    : ''
                                                                                    }`}

                                                                            >
                                                                                < MaterialIcons type={MaterialIconType.ArrowDown} color={MaterialIconColor.green} />
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                            {addNewIndex === index && addNew && (
                                                <tr>
                                                    <td>{renderTextBox()}</td>
                                                    <td />
                                                    <td />
                                                </tr>
                                            )}

                                            <Help isShown={true} bulmaColor={BulmaColor.Danger}>
                                                {getErrors(`siteAreas[${index}].checkListItems`)}
                                            </Help>
                                        </>
                                    );
                                })}
                        </tbody>
                    </Table>

                    <ConfirmDialog
                        title={translate('ShiftHandoverItemsTable.ConfirmDialog.Button.Text.AddChecklistTitle')}
                        message={getBody()}
                        isVisible={showConfirmDialog}
                        showConfirmButton={true}
                        onConfirm={onConfirmAddCheckListItem}
                        onClose={() => setShowConfirmDialog(false)}
                        onOutsideDialogClick={() => setShowConfirmDialog(false)}
                        buttonConfirmText={translate(
                            'ShiftHandoverItemsTable.ConfirmDialog.Button.Text.Confirm'
                        )}
                        buttonCancelText={translate(
                            'ShiftHandoverItemsTable.ConfirmDialog.Button.Text.Cancel'
                        )}
                    />
                    <ConfirmDialog
                        title={translate('ShiftHandoverItemsTable.ConfirmDialog.Button.Text.AddNewTemplate')}
                        message={getTemplateBody()}
                        isVisible={showConfirmTemplateDialog}
                        showConfirmButton={true}
                        onConfirm={onConfirmAddSiftHandoverTemplate}
                        onClose={() => setShowConfirmTemplateDialog(false)}
                        onOutsideDialogClick={() => setShowConfirmTemplateDialog(false)}
                        buttonConfirmText={translate(
                            'ShiftHandoverItemsTable.ConfirmDialog.Button.Text.Confirm'
                        )}
                        buttonCancelText={translate(
                            'ShiftHandoverItemsTable.ConfirmDialog.Button.Text.Cancel'
                        )}
                    />

                    <ConfirmDialog
                        title={translate('ShiftHandoverDetailsTab.ConfirmNavigation.Label.Confirm')}
                        message={translate('ConfirmNavigation.Label.Info')}
                        buttonCancelHide={false}
                        buttonConfirmText={translate('ShiftHandoverDetailsTab.ConfirmNavigation.Label.Confirm')}
                        isVisible={isShowNavigationAwayDialog}
                        onConfirm={onConfirmNavigationAway}
                        onClose={() => setIsShowNavigationAwayDialog(false)}
                        onOutsideDialogClick={() => setIsShowNavigationAwayDialog(false)}
                    />

                    <Toolbar type="save">
                        <ToolbarGroupLeft>
                            <ButtonWithConfirmDialog
                                buttonId="btnDeleteQuestion"
                                buttonType="archive"
                                buttonText={translate('ShiftHandoversAdminPage.ArchiveDialog.ButtonText')}
                                title={translate('ShiftHandoversAdminPage.ArchiveDialog.Title')}
                                message={translate('ShiftHandoversAdminPage.ArchiveDialog.Message')}
                                onConfirm={onArchiveTemplate}
                                isButtonDisabled={(isOperatingPlatformExist || !!(newShiftHandoverCheckList.length === 1))}
                            />
                        </ToolbarGroupLeft>
                        <ToolbarGroupCenter>
                            {(isOperatingPlatformExist || !!(newShiftHandoverCheckList.length === 1)) &&
                                <>
                                    <small> {translate('ShiftHandoversAdminPage.Edit.EditText')}</small>
                                </>}
                        </ToolbarGroupCenter>
                        <ToolbarGroupRight>
                            <Button
                                id="Edit"
                                buttonType="save"
                                isLoading={isLoading}
                                onClick={onEditShiftChecklistTemplate}
                                disabled={
                                    (isOperatingPlatformExist || !!(newShiftHandoverCheckList.length === 1)) ||
                                    (!isOperatingPlatformExist && isTemplateEdit)
                                }
                            >
                                {translate('ShiftHandoversAdminPage.Button.EditText')}
                            </Button>
                            <FloatingButton
                                id="save-risk-profile-questionnaire-button"
                                className="button is-medium is-primary is-pulled-right"
                                disabled={!formik.isValid}
                                onClick={onSave}
                                float={formik.isValid}
                                tooltip={translate('ShiftHandoversAdminPage.Button.SaveTooltip')}
                            >
                                {translate('Globals.Label.Save')}
                            </FloatingButton>
                            <ConfirmNavigateAwayDialog when={formik.dirty} />
                        </ToolbarGroupRight>
                    </Toolbar>
                </Panel>
            </Loader>
        );
    };
    const checklist = shiftCheckList && shiftCheckList !== undefined && shiftCheckList.filter((checkListItem) => checkListItem.templateLinkId === updatedTemplate)[0]
    return (
        <div className="tabs-container tab-content">
            <Formik<Partial<IShiftChecklist>>
                enableReinitialize={true}
                initialValues={checklist}
                validationSchema={valSchema}
                onSubmit={onSubmitForm}
                render={renderForm}
            />
        </div>
    );
};

export default ShiftHandoverAdminPage;
