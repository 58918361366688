import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';

const getRisks = (state: IRootState) => state.risks.grid.items;
const getSiteId = (state: IRootState) => state.app.siteId;
const getSite = (state: IRootState) => state.siteState.site;

export default createSelector(getRisks, getSiteId, getSite, (riskList, siteId, site) => ({
    riskList,
    siteId,
    site,
}));
