import React from 'react';
import { Alert } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';

export const PermissionDeniedPage = () => {
    const translate = useTranslate();

    return (
        <Alert type="warning" title={translate('ErrorPage.PermissionDeniedPage.PermissionDenied')}>
            <p>
                {translate('ErrorPage.PermissionDeniedPage.Message')}
            </p>
        </Alert>
    );
};
