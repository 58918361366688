
import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';

const getIsUploading = (state: IRootState) => state.actionRegisterState.isLoading;
const getHistory = (state: IRootState) => state.actionRegisterState.history;
const getAction = (state: IRootState) => state.actionRegisterState.action;

export default createSelector(
    getIsUploading,
    getHistory,
    getAction,
    (isLoading, actionHistory, action) => ({
        isLoading,
        actionHistory,
        action
    })
);
