import { IKeyValue } from 'models/key-value';
import { IAssetModel } from './asset/asset-model';

export interface IGlobalMockDrillsFilters {
    filterKeywords?: string[];
    filterScenarioTypes?: string[];
}

export interface IMockDrillsFilters extends IGlobalMockDrillsFilters {
    siteId: string | string[];
}

export interface IMockGlobalDrillInfo {
    id: string;
    scenarioType: string;
    name: string;
    numberOfSites: number;
}

export interface IMockDrillInfo {
    id: string;
    scenarioType: string;
    drillType: string;
    name: string;
    drillLeader: string;
    lastRun: Date | string;
}

export interface IGlobalMockDrill {
    id: string;
    scenarioType: string;
    name: string;
    description: string;
    sites: string[];
    isLocked?: boolean;
}

export interface IMockDrill extends IGlobalMockDrill {
    siteId: string;
    asset: IAssetModel;
}

export interface ICreateGlobalMockDrill {
    scenarioType: string;
    name: string;
    description: string;
    sitesToAdd: string[];
    sitesToRemove: string[];
    reloadUrl: string;
}
export interface IUpdateGlobalMockDrill extends ICreateGlobalMockDrill {
    id: string;
}

export interface ICreateMockDrill extends ICreateGlobalMockDrill {
    siteId?: string;
    asset: IAssetModel;
}

export interface IUpdateMockDrill extends ICreateMockDrill {
    id: string;
}

export interface IMockDrillReportsSummary {
    name: string;
    description: string;
    reports: IMockDrillReportInfo[];
}

export interface IMockDrillReportInfo {
    id: string;
    status: string;
    title: string;
    dateOfDrill: Date | string;
    totalActions: number;
    totalParticipants: number;
}
export interface ICreateMockDrillReport {
    siteId: string;
    title: string;
}

export interface IMockDrillReport {
    id: string;
    siteId: string;
    mockDrillId: string;
    mockDrillType: string;
    status: string;
    title: string;
    dateOfDrill: Date | string;
    drillLeader: string;
    drillLeaderPersonId: string;
    eopTitle: string;
    actionsDescription: string;
    lessonsLearnedDescription: string;
    participants: IMockDrillParticipant[];
    actions: IMockDrillReportAction[];
}

export interface IMockDrillParticipant {
    id: string;
    fullName: string;
    firstName: string;
    lastName: string;
    email: string;
    roleNames: string[];
    isActive: boolean;
    participation: string;
}

export interface IMockDrillReportAction {
    id: string;
    title: string;
    isActive: boolean;
}

export interface ISetMockDrillReportParticipation {
    id: string;
    siteId: string;
    userId: string;
    participation: string;
}

export enum MockDrillType {
    Global = 'Global',
    Local = 'Local',
}

export enum MockDrillReportStatus {
    Draft = 'Draft',
    Completed = 'Completed',
}

export enum MockDrillParticipation {
    Unanswered = 'Unanswered',
    Confirmed = 'Confirmed',
    Rejected = 'Rejected',
}

export enum MockDrillScenarioType {
    MockDrills = 'Mock Drills',
    LessonsLearned = 'Lessons Learned',
    ToolboxTalks = 'Toolbox Talks',
}

export const mockDrillScenarioType: Array<IKeyValue<string>> = [
    {
        key: MockDrillScenarioType.MockDrills.replace(/\s/g, ''),
        value: MockDrillScenarioType.MockDrills,
        label: MockDrillScenarioType.MockDrills,
    },
    {
        key: MockDrillScenarioType.LessonsLearned.replace(/\s/g, ''),
        value: MockDrillScenarioType.LessonsLearned,
        label: MockDrillScenarioType.LessonsLearned,
    },
    {
        key: MockDrillScenarioType.ToolboxTalks.replace(/\s/g, ''),
        value: MockDrillScenarioType.ToolboxTalks,
        label: MockDrillScenarioType.ToolboxTalks,
    },
];

export const getScenarioTypeStringFromParam = (param: string) => {
    switch (param) {
        case 'LessonsLearned':
            return MockDrillScenarioType.LessonsLearned.toString();
        case 'ToolboxTalks':
            return MockDrillScenarioType.ToolboxTalks.toString();
        default:
            return MockDrillScenarioType.MockDrills.toString();
    }
};
