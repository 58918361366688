import { combineReducers } from 'redux';
import { IAppAction } from 'actions/app-action';
import { MasterProcessStepActions } from 'actions/actions-v2/master-process-step-action-v2';
import { IMasterProcessStepState } from 'state';

const INITIAL_STATE: IMasterProcessStepState = {
    isLoading: false,
    masterProcessSteps: []
};

const isLoading = (
    state: IMasterProcessStepState['isLoading'] = INITIAL_STATE.isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case MasterProcessStepActions.LOAD_MASTER_PROCESS_STEPS:
            return true;

        case MasterProcessStepActions.LOAD_MASTER_PROCESS_STEPS_FULFILLED:
        case MasterProcessStepActions.LOAD_MASTER_PROCESS_STEPS_REJECTED:
            return false;

        default:
            return state;
    }
};

const masterProcessSteps = (
    state: IMasterProcessStepState['masterProcessSteps'] = INITIAL_STATE.masterProcessSteps,
    action: IAppAction
) => {
    switch (action.type) {
        case MasterProcessStepActions.LOAD_MASTER_PROCESS_STEPS_FULFILLED:
            if (action.payload) {
                return action.payload;
            }

        default:
            return state;
    }
};

export const masterProcessStepReducer = combineReducers<IMasterProcessStepState>({
    isLoading,
    masterProcessSteps
});
