import { createGlobalSkillCategory, deleteGlobalSkillCategory, loadGlobalSkillCategories, updateGlobalSkillCategory } from 'actions/global-skill-category-action';
import { IRootState } from '../store';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { getLineOfBusinessLookUps, getPermissions, getRegionsLookUps } from 'sharedSelectors';
import { createGlobalSkillCard, deleteGlobalSkillCard, loadGlobalSkillCards, updateGlobalSkillCard, publishSkillCard, loadGlobalSkillCardsHierarchyPathsByVisiblity, ResetGlobalSkillCardsHierarchyPathsByVisiblity } from 'actions/global-skill-card-actions';
import { CompetencyDNAProcessAdminPage } from './competency-dna-process-admin-page';
import { createGlobalSkill, loadGlobalSkillsByCategory, loadGlobalSkillsByCard, deleteGlobalSkill, updateGlobalSkill } from 'actions/global-skill-actions';

const getOperatingPlatforms = (state: IRootState) => state.operatingPlatformState.operatingPlatforms;
const getLookupCountryRegions = (state: IRootState) => state.app.lookupCountryRegion;
const getLookupCountry = (state: IRootState) => state.app.lookupCountry;
const getPersonProfile = (state: IRootState) => state.person.personProfile;
const isLoading = (state: IRootState) => state.adminCdnaGlobalState.isLoading;
const getGlobalSkillCards = (state: IRootState) => state.adminCdnaGlobalState.globalSkillCards;
const getGlobalSkillCategory = (state: IRootState) => state.adminCdnaGlobalState.globalSkillCategories;
const getGlobalAdminCdnaState = (state: IRootState) => state.adminCdnaGlobalState;

const mapStateToProps = createSelector(
    getLineOfBusinessLookUps,
    getRegionsLookUps,
    getOperatingPlatforms,
    getLookupCountryRegions,
    getLookupCountry,
    getPermissions,
    getPersonProfile,
    isLoading,
    getGlobalSkillCards,
    getGlobalSkillCategory,
    getGlobalAdminCdnaState,
    (
        lookupLineOfBusiness,
        regions,
        operatingPlatforms,
        lookupCountryRegion,
        lookupCountry,
        permissions,
        personProfile,
        isLoading,
        globalSkillCards,
        globalSkillCategories,
        adminCdnaGlobalState,
    ) => ({
        lookupLineOfBusiness,
        regions,
        operatingPlatforms,
        lookupCountryRegion,
        lookupCountry,
        permissions,
        personProfile,
        isLoading,
        globalSkillCards,
        globalSkillCategories,
        globalSkills: adminCdnaGlobalState.globalSkills,
        globalSkillCardsHierarchyPathsByVisiblity: adminCdnaGlobalState.globalSkillCardsHierarchyPathsByVisiblity
    })
);

const mapDispatchToProps = {
    loadGlobalSkillCategories,
    createGlobalSkillCategory,
    updateGlobalSkillCategory,
    deleteGlobalSkillCategory,
    loadGlobalSkillCards,
    createGlobalSkillCard,
    updateGlobalSkillCard,
    deleteGlobalSkillCard,
    createGlobalSkill,
    loadGlobalSkillsByCategory,
    loadGlobalSkillsByCard,
    deleteGlobalSkill,
    updateGlobalSkill,
    publishSkillCard,
    loadGlobalSkillCardsHierarchyPathsByVisiblity,
    ResetGlobalSkillCardsHierarchyPathsByVisiblity
};

export const CompetencyDNAProcessAdminContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CompetencyDNAProcessAdminPage);
