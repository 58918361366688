import { connect } from 'react-redux';

import actions from './actions';
import selector from './selectors';
import { ManagePrivilegeTab as View } from './view/manage-privileges-tab';

export const ManagePrivilegeTab = connect(
    selector,
    actions
)(View);
