export const cermAssesmentEnGB = `
<div>
<p>Click the link below to view:</p>
<p><a href="https://confluence.cbre.com/pages/viewpage.action?pageId=109992258" target= "_blank">CERM Assessment Process</a><p>
</div>
<br />
<div>
<p><a href="https://quantum.cbre.com/issues" target= "_blank">Report an Issue</a></p>
<p><a href="https://quantum.cbre.com/requests" target= "_blank">Raise a Request</a></p>
</div>`;

export const hermAssesmentEnGB = `
<div>
<p>Click the link below to view:</p>
<p><a href="https://confluence.cbre.com/pages/viewpage.action?pageId=109992258" target= "_blank">HERM Assessment Process</a><p>
</div>
<br />
<div>
<p><a href="https://quantum.cbre.com/issues" target= "_blank">Report an Issue</a></p>
<p><a href="https://quantum.cbre.com/requests" target= "_blank">Raise a Request</a></p>
</div>`;
