import * as React from 'react';
import EntityHistory from 'components/history/entity-history';
import { IDifference } from 'models/difference';
import { IMockDrillReport } from 'models/mock-drills';
import { Loader } from 'components/loader';
import useTranslate from 'translations/translation-utils';

interface IProps {
    id: string;
    siteId: string;
    isLoading: boolean;
    mockDrillReport: IMockDrillReport;
    mockDrillReportHistory: IDifference[][];
    loadHistory: (id: string, siteId: string) => void;
}

export const MockDrillReportHistoryTab: React.FC<IProps> = ({
    id,
    siteId,
    isLoading,
    mockDrillReport,
    mockDrillReportHistory,
    loadHistory
}) => {
    const translate = useTranslate();
    React.useEffect(() => {
        if (!id) {
            return;
        }

        loadHistory(id, siteId);
    }, [id]);

    return (
        <div className="tabs-container tab-content">
            <div className="section">
                <div className="column is-12">
                    <div className="title is-4 section-title">{translate('RiskRegisterUpsertPage.Tabs.History')}</div>
                    <Loader loading={isLoading}>
                        <EntityHistory
                            entity={mockDrillReport}
                            entityHistory={mockDrillReportHistory}
                        />
                    </Loader>
                </div>
            </div>
        </div>
    );
};
