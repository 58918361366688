import { IAppAction } from '../app-action';
import { ICermListFilters } from 'models/cerm-global-overview';

export enum CermGlobalOverviewActions {
    LOAD_CERM_GLOBAL_OVERVIEW_LIST = '@@cerm/LOAD_CERM_GLOBAL_OVERVIEW_LIST',
    LOAD_CERM_GLOBAL_OVERVIEW_LIST_FULFILLED = '@@cerm/LOAD_CERM_GLOBAL_OVERVIEW_LIST_FULFILLED',
    LOAD_CERM_GLOBAL_OVERVIEW_LIST_REJECTED = '@@cerm/LOAD_CERM_GLOBAL_OVERVIEW_LIST_REJECTED',

    EXPORT_GLOBAL_CERM_OVERVIEW = '@@cerm/EXPORT_GLOBAL_CERM_OVERVIEW',
    EXPORT_GLOBAL_CERM_OVERVIEW_FULFILLED = '@@cerm/EXPORT_GLOBAL_CERM_OVERVIEW_FULFILLED',
    EXPORT_GLOBAL_CERM_OVERVIEW_REJECTED = '@@cerm/EXPORT_GLOBAL_CERM_OVERVIEW_REJECTED',

    EXPORT_GLOBAL_CERM_SITES_OVERVIEW = '@@cerm/EXPORT_GLOBAL_CERM_SITES_OVERVIEW',
    EXPORT_GLOBAL_CERM_SITES_OVERVIEW_FULFILLED = '@@cerm/EXPORT_GLOBAL_CERM_OVERVIEW_FULFILLED',
    EXPORT_GLOBAL_CERM_SITES_OVERVIEW_REJECTED = '@@cerm/EXPORT_GLOBAL_CERM_OVERVIEW_REJECTED',

}

export const loadCermList = (filters: ICermListFilters): IAppAction => ({
    type: CermGlobalOverviewActions.LOAD_CERM_GLOBAL_OVERVIEW_LIST,
    payload: filters
});

export const exportData = (): IAppAction => ({
    type: CermGlobalOverviewActions.EXPORT_GLOBAL_CERM_OVERVIEW
});

export const exportCermSitesData = (operatingPlatform: string): IAppAction => ({
    type: CermGlobalOverviewActions.EXPORT_GLOBAL_CERM_SITES_OVERVIEW,
    payload: operatingPlatform
});
