import React from 'react';
import Page from '../../components/v2/page/Page';
import conceptAndDesign from 'assets/concept-and-design.png';
import continuesMonitoring from 'assets/continues-monitoring.png';
import energySavings from 'assets/energy-savings.png';
import projects from 'assets/projects.png';
import useTranslate from 'translations/translation-utils';
import downloadIcon from 'assets/icon-download.svg';
interface IProps {
    downloadPPT?: () => void;
}
const RomonetHomePage: React.FC<IProps> = ({ downloadPPT }) => {
    const onDownloadPPT = () => {
        downloadPPT();
    };

    const translate = useTranslate();
    return (
        <Page title={translate('RomonetHomePage.Title.Welcome')} className="romonet-home-page">
            <div className="columns content">
                <div className="column is-6">
                    <p>{translate('RomonetHomePage.Label.DataCentreAnalytics')}</p>
                </div>
                <div className="column is-6 content-body">
                    <div className="columns">
                        <div className="column is-6 content-body__img">
                            <img src={conceptAndDesign} />
                        </div>
                        <div className="column is-6 content-body__title">
                            <p>{translate('RomonetHomePage.Label.CONCEPT&DESIGN')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="columns content">
                <div className="column is-6 content-text">
                    <p>{translate('RomonetHomePage.Label.EnergyAnalysisEngineers')}</p>
                </div>
                <div className="column is-6 content-body">
                    <div className="columns">
                        <div className="column is-6 content-body__img">
                            <img src={projects} />
                        </div>
                        <div className="column is-6 content-body__title">
                            <p>{translate('RomonetHomePage.Label.Projects')}</p>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-6 content-body__img">
                            <img src={energySavings} />
                        </div>
                        <div className="column is-6 content-body__title">
                            <p>{translate('RomonetHomePage.Label.EnergySavings')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="columns  content">
                <div className="column is-6">
                    <p>
                        {translate('RomonetHomePage.Label.NavigationLink')}
                        <a href="https://romonetsales@cbre.com">
                            {translate('RomonetHomePage.Label.RomonetLink')}
                        </a>
                    </p>
                </div>
                <div className="column is-6 content-body">
                    <div className="columns">
                        <div className="column is-6 content-body__img">
                            <img src={continuesMonitoring} />
                        </div>
                        <div className="column is-6 content-body__title">
                            <p>{translate('RomonetHomePage.Label.ContinuesMonitoring')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="columns content">
                <div className="column is-6" />
                <div className="column is-6 content-body">
                    <div className="columns">
                        <div className="column is-6 content-body__img">
                            <a onClick={onDownloadPPT} target="_blank" rel="noopener noreferrer">
                                <img src={downloadIcon} />
                            </a>
                        </div>
                        <div className="column is-6 content-body__title">
                            <a onClick={onDownloadPPT} target="_blank" rel="noopener noreferrer">
                                <p>{translate('RomonetHomePage.Label.DownloadPPTLink')} </p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <p>{translate('RomonetHomePage.Label.Desclaimer')}</p>
            </footer>
        </Page>
    );
};

export default RomonetHomePage;
