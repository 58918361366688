import { createSelector } from 'reselect';
import { IRootClientsState } from 'reducers/clients';
import { IRootState } from 'routes/store';

const getClientsPage = createSelector(
    (state: IRootClientsState) => state,
    (state) => {
        return state.clientPage.upsertItem;
    }
);

const getIndustryLookUps = (state: IRootState) => state.app.lookupIndustry;

const getIsLoading = createSelector(
    (state: IRootClientsState) => state,
    (state) => {
        return state.clientPage.isLoading;
    }
);

const getIauditorAccountName = (state: IRootState) => state.clientPage.iAuditorAccountName;
const getMyDataClients = (state: IRootState) => state.clientPage.myDataClients;
const getPermissions = (state: IRootState) => state.app.permissions;
const getAppSettings = (state: IRootState) => state.app.appSettings;

export default createSelector(
    getClientsPage,
    getIndustryLookUps,
    getIsLoading,
    getIauditorAccountName,
    getMyDataClients,
    getPermissions,
    getAppSettings,
    (
        upsertRequest,
        industryLookUps,
        isLoading,
        iAuditorAccountName,
        myDataClients,
        permissions,
        appSettings,
    ) => ({
        upsertRequest,
        industryLookUps,
        isLoading,
        iAuditorAccountName,
        myDataClients,
        permissions,
        appSettings,
    })
);
