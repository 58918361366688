import React, { useEffect } from 'react';
import { Button } from 'components/v2/button/Button';
import { TextField } from 'components/form/fields/text-field';
import { Loader } from 'components/loader';
import { IVerificationCode } from 'models/verification-code';
import logo from 'assets/CBREQuantum-NewLogo_Green.svg'
import useTranslate from 'translations/translation-utils';

export interface IProps {
    validateVerificationCode: (model: IVerificationCode) => void;
    sendVerificationCode: () => void;
    isLoading: boolean;
    validationId: string;
}

const ClientMultifactorPage: React.FC<IProps> = (props) => {

    const translate = useTranslate();
    const [code, setCode] = React.useState('');

    useEffect(() => {
        props.sendVerificationCode();
    }, []);
    // const translate = useTranslate();

    const onChange = (val: any) => {
        setCode(val.target.value);
    };

    const onSubmit = () => {
        props.validateVerificationCode({
            code: code,
            verificationId: props.validationId,
        } as IVerificationCode);
    };

    return (
        <Loader loading={props.isLoading}>
            <div className="access-denied">
                <div className="columns">
                    <div className="column is-full">
                        <header>
                            <div className="logo">
                                <img src={logo} alt="CBRE Quantum" className="is-hidden-mobile" />
                                <img src={logo} alt="CBRE Quantum" className="is-hidden-tablet" />
                            </div>
                        </header>
                    </div>
                </div>
                <div className="columns is-multiline is-centered">
                    <div className="column is-half">
                        <ul className="details">
                            <div id="main_block">
                                <div id="msg_long_reason">
                                    <div className="title" id="msg_title">
                                        <b>{translate('ClientMultifactor.Lable.1')}</b>
                                    </div>
                                    <li>
                                        <b> {translate('ClientMultifactor.Lable.2')} </b>
                                    </li>
                                    <p>
                                        {translate('ClientMultifactor.Lable.3')}
                                    </p>
                                    <TextField
                                        id="validation-code"
                                        label=""
                                        value={code}
                                        handleChange={onChange}
                                    />
                                </div>
                            </div>
                        </ul>
                        <div className="column column-save">
                            <Button id="save" buttonType="save" onClick={onSubmit} type="submit">
                                {translate('Globals.Label.Submit')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Loader>
    );
};

export default ClientMultifactorPage;
