import { combineReducers } from 'redux';
import { IAppAction } from 'actions/app-action';
import { s2ab } from 'utils/export-utils';
import { EngineerActions } from 'actions/actions-v2/engineer-action-v2';
import { genericSort } from 'utils/sort-utils';
import { IEngineersState, getDefaultEngineersState } from 'state';
import { SkillCategoryActions } from 'actions/actions-v2';
import saveAs from 'file-saver';

const engineers = (
    state: IEngineersState['engineers'] = getDefaultEngineersState().engineers,
    action: IAppAction
) => {
    switch (action.type) {
        case EngineerActions.LOAD_ENGINEERS:
            return state;
        case EngineerActions.LOAD_ENGINEERS_FULFILLED:
            return action.payload;
        case EngineerActions.SORT_ENGINEERS:
            return genericSort(action.payload.key, [...state], action.payload.sortAscending);
        default:
            return state;
    }
};

const isLoading = (
    state: IEngineersState['isLoading'] = getDefaultEngineersState().isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case EngineerActions.LOAD_ENGINEERS:
            return true;
        case EngineerActions.LOAD_ENGINEERS_FULFILLED:
        case EngineerActions.LOAD_ENGINEERS_CANCELLED:
        case EngineerActions.LOAD_ENGINEERS_REJECTED:
            return false;
        default:
            return state;
    }
};

const isExportingEngineers = (
    state: IEngineersState['isExportingEngineers'] = getDefaultEngineersState()
        .isExportingEngineers,
    action: IAppAction
) => {
    switch (action.type) {
        case EngineerActions.DOWNLOAD_ENGINEERS:
            return true;
        case EngineerActions.DOWNLOAD_ENGINEERS_FULFILLED:
            if (action.payload) {
                saveAs(
                    new Blob([s2ab(atob(action.payload.response.fileContents))], {
                        type: 'application/excel',
                    }),
                    action.payload.fileDownloadName
                );
            }
            return false;
        case EngineerActions.DOWNLOAD_ENGINEERS_REJECTED:
        case EngineerActions.DOWNLOAD_ENGINEERS_CANCELLED:
            return false;
        default:
            return state;
    }
};

const isExportingAllEngineers = (
    state: IEngineersState['isExportingAllEngineers'] = getDefaultEngineersState()
        .isExportingAllEngineers,
    action: IAppAction
) => {
    switch (action.type) {
        case EngineerActions.EXPORT_ALL_ENGINEERS:
            return true;
        case EngineerActions.EXPORT_ALL_ENGINEERS_FULFILLED:
            return false;
        case EngineerActions.EXPORT_ALL_ENGINEERS_REJECTED:
            return false;
        default:
            return state;
    }
};

const isSaving = (
    state: IEngineersState['isSaving'] = getDefaultEngineersState().isSaving,
    action: IAppAction
) => {
    switch (action.type) {
        case EngineerActions.CREATE_ENGINEER_CANCELLED:
        case EngineerActions.CREATE_ENGINEER_REJECTED:
        case EngineerActions.CREATE_ENGINEER_FULFILLED:
        case EngineerActions.UPDATE_ENGINEER_CANCELLED:
        case EngineerActions.UPDATE_ENGINEER_REJECTED:
        case EngineerActions.UPDATE_ENGINEER_FULFILLED:
            return false;
        case EngineerActions.UPDATE_ENGINEER:
            return true;
        default:
            return state;
    }
};

const skillCategories = (
    state: IEngineersState['skillCategories'] = getDefaultEngineersState().skillCategories,
    action: IAppAction
) => {
    switch (action.type) {
        //case SkillCategoryActions.LOAD_SKILL_CATEGORIES_FULFILLED:
        case SkillCategoryActions.LOAD_OBSOLETE_SKILL_CATEGORY_FULFILLED:
            return action.payload;
        //case SkillCategoryActions.LOAD_SKILL_CATEGORIES:
        case SkillCategoryActions.LOAD_OBSOLETE_SKILL_CATEGORIES:
            return [];
        case SkillCategoryActions.SORT_SKILL_CATEGORIES:
            return genericSort(action.payload.key, [...state], action.payload.sortAscending);
        default:
            return state;
    }
};

export const engineersReducer = combineReducers<IEngineersState>({
    isLoading,
    engineers,
    isExportingEngineers,
    isExportingAllEngineers,
    isSaving,
    skillCategories,
});
