import { IAppAction } from 'actions/app-action';
import { combineReducers } from 'redux';
import { s2ab } from 'utils/export-utils';
import { IGlobalMockDrillsState } from 'state/mock-drills-state';
import { GlobalMockDrillsActions } from 'actions/actions-v2/global-mock-drills-actions';
import { AttachmentActions } from 'actions/actions-v2/attachment-action-v2';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import saveAs from 'file-saver';

const INITIAL_STATE: IGlobalMockDrillsState = {
    isLoading: false,
    globalMockDrills: [],
    globalMockDrill: null,
    isExporting: false,
    isSaving: false,
    attachments: [],
};

const isLoading = (
    state: IGlobalMockDrillsState['isLoading'] = INITIAL_STATE.isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case GlobalMockDrillsActions.LOAD_LIST:
        case GlobalMockDrillsActions.LOAD:
            return true;

        case GlobalMockDrillsActions.LOAD_LIST_FULFILLED:
        case GlobalMockDrillsActions.LOAD_LIST_REJECTED:
        case GlobalMockDrillsActions.LOAD_FULFILLED:
        case GlobalMockDrillsActions.LOAD_REJECTED:
            return false;

        default:
            return state;
    }
};

const globalMockDrills = (
    state: IGlobalMockDrillsState['globalMockDrills'] = INITIAL_STATE.globalMockDrills,
    action: IAppAction
) => {
    switch (action.type) {
        case GlobalMockDrillsActions.LOAD_LIST_FULFILLED:
            if (action.payload) {
                return action.payload;
            }

        default:
            return state;
    }
};

const globalMockDrill = (
    state: IGlobalMockDrillsState['globalMockDrill'] = INITIAL_STATE.globalMockDrill,
    action: IAppAction
) => {
    switch (action.type) {
        case GlobalMockDrillsActions.LOAD_FULFILLED:
            if (action.payload) {
                return action.payload;
            }

        case GlobalMockDrillsActions.CLEANUP:
            return {};

        default:
            return state;
    }
};

const isExporting = (
    state: IGlobalMockDrillsState['isExporting'] = INITIAL_STATE.isExporting,
    action: IAppAction
) => {
    switch (action.type) {
        case GlobalMockDrillsActions.EXPORT:
            return true;

        case GlobalMockDrillsActions.EXPORT_FULFILLED:
            const blob = new Blob([s2ab(atob(action.payload.fileContents))], {
                type: 'application/excel',
            });
            saveAs(blob, action.payload.fileDownloadName);
            return false;

        case GlobalMockDrillsActions.EXPORT_REJECTED:
            return false;

        default:
            return state;
    }
};

const isSaving = (
    state: IGlobalMockDrillsState['isSaving'] = INITIAL_STATE.isSaving,
    action: IAppAction
) => {
    switch (action.type) {
        case GlobalMockDrillsActions.CREATE:
        case GlobalMockDrillsActions.UPDATE:
        case GlobalMockDrillsActions.DELETE:
            return true;

        case GlobalMockDrillsActions.CREATE_FULFILLED:
        case GlobalMockDrillsActions.CREATE_REJECTED:
        case GlobalMockDrillsActions.UPDATE_FULFILLED:
        case GlobalMockDrillsActions.UPDATE_REJECTED:
        case GlobalMockDrillsActions.DELETE_FULFILLED:
        case GlobalMockDrillsActions.DELETE_REJECTED:
            return false;

        default:
            return state;
    }
};

const attachments = (
    state: IGlobalMockDrillsState['attachments'] = INITIAL_STATE.attachments,
    action: IAppAction
) => {
    switch (action.type) {
        case AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_GLOBAL_MOCK_DRILL:
            if (action.payload) {
                return action.payload;
            }
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_GLOBAL_MOCK_DRILL:
            if (action.payload) {
                return [...state, ...action.payload];
            }
            return state;
        case AttachmentActions.DELETE_ATTACHMENT_FULFILLED:
            if (action.payload) {
                return state.filter((item: IAttachmentModel) => item.fileName !== action.payload);
            }
            return state;
        default:
            return state;
    }
};

export const globalMockDrillsReducer = combineReducers<IGlobalMockDrillsState>({
    isLoading,
    globalMockDrills,
    globalMockDrill,
    isExporting,
    isSaving,
    attachments,
});
