import { createSelector } from 'reselect';
import { IUpsertSiteRootState } from 'state/upsert-site-state';
import { getUpsertSiteState } from '../common';
import { IRootState } from 'routes/store';

const getPermissions = (state: IUpsertSiteRootState) => state.app.permissions;

const getShouldLoadBenchmarkData = (state: IUpsertSiteRootState) =>
    state.siteState.shouldLoadBenchmarkData;

const getLookupTierLevel = (state: IRootState) => state.app.lookupTierLevel;
const getLookupCountry = (state: IRootState) => state.app.lookupCountry;
const getLookupRegions = (state: IRootState) => state.app.lookupRegion;
const getLookupFacilityTypes = (state: IRootState) => state.app.lookupFacilityType;
const getLookupCountryRegions = (state: IRootState) => state.app.lookupCountryRegion;
const getFacilities = (state: IRootState) => state.facilityState.facilities;
const getFacilityTypes = (state: IRootState) => state.siteMetaDataState.facilityType;

export default createSelector(
    getUpsertSiteState,
    getPermissions,
    getShouldLoadBenchmarkData,
    getLookupTierLevel,
    getLookupCountry,
    getLookupRegions,
    getLookupFacilityTypes,
    getLookupCountryRegions,
    getFacilities,
    getFacilityTypes,
    (
        state,
        permissions,
        shouldLoadBenchmarkData,
        lookupTierLevels,
        lookupCountries,
        lookupRegions,
        lookupFacilityTypes,
        lookupCountryRegions,
        facilities,
        facilityType,
    ) => ({
        lookupRegions,
        lookupCountries,
        lookupFacilityTypes,
        lookupTierLevels,
        lookupCountryRegions,
        facilities,
        isSubmiting: state.isSubmitting,
        permissions,
        shouldLoadBenchmarkData,
        facilityType
    })
);
