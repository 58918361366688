import React from 'react';
import { IProgressBarModel } from 'components/step-progress-bar/StepProgressBar';
import { IStateResult, State } from '../State';
import { QuestionAirflow } from './question-airflow';

export class QuestionCooledChillers extends State {

    public result: IStateResult;
    private title: string = 'What type of chillers do you have on your Chilled Water system?';
    private type: ChillerType;
    constructor() {
        super();
        this.handleClick = this.handleClick.bind(this);
    }
    public getResult(): IStateResult {
        return this.result;
    }
    public path(): string {
        return 'QuestionCooledChillers';
    }
    public display(): JSX.Element {
        return (
            <div >
                <div className="is-size-3  has-text-centered">
                    {this.title}
                </div>
                <p className="has-text-centered">
                    Please choose the type of chiller which most closely matches
                    your primary mechanical cooling system for rejecting heat from IT and support spaces.
                </p>
                <hr />
                <div className="formBody">
                    <div className="field">
                        <div className="control">
                            <input
                                className="radioButton"
                                type="radio"
                                name="answer"
                                value={ChillerType.WaterChillers}
                                id={ChillerType.WaterChillers}
                                onClick={this.handleClick}
                                checked={this.type === ChillerType.WaterChillers}
                            />
                            <label htmlFor={ChillerType.WaterChillers} className="radio is-size-5">

                                {ChillerType.WaterChillers.toString()}
                            </label>
                            <p>
                                Central chillers which reject heat to a water (glycol) loop pumped to external coolers
                            </p>
                        </div>
                    </div>
                    <div className="field">
                        <div className="control">
                            <input
                                className="radioButton"
                                type="radio"
                                name="answer"
                                value={ChillerType.AirChillers}
                                id={ChillerType.AirChillers}
                                onClick={this.handleClick}
                                checked={this.type === ChillerType.AirChillers}
                            />
                            <label htmlFor={ChillerType.AirChillers} className="radio is-size-5">
                                {ChillerType.AirChillers.toString()}
                            </label>
                            <p>
                                Central chillers which reject heat directly to the outside air (packaged air cooled chiller)
                            </p>
                        </div>
                    </div>
                </div>
                <hr />
                {this.context.footer(this.handle.bind(this))}
            </div>);
    }
    public handle() {
        this.result = {
            title: this.title,
            value: this.type.toString()
        };

        this.context.transitionTo(new QuestionAirflow());
    }
    public getProgressBarModel(): IProgressBarModel[] {
        return [
            { isActive: true, name: 'Type of site' },
            { isActive: true, name: 'Question 1' },
            { isActive: false, name: 'Question 2' },
            { isActive: false, name: 'Question 3' },
            { isActive: false, name: 'Question 4' },
            { isActive: false, name: 'Summary' },
        ] as IProgressBarModel[];
    }
    private handleClick(event: any) {
        this.context.isDisable = false;
        this.type = event.currentTarget.value as ChillerType;
        this.context.handleRender();
    }

}

export enum ChillerType {
    WaterChillers = 'Water cooled chillers',
    AirChillers = 'Air cooled chillers',
}
