// import { IRole } from 'reducers/manageRoles/manageRoles-grid';
import {
    ManageRoleActions, ManageRoleActionTypes
} from '../../../../actions/manage-role-actions';
import { createRole, updateRole } from 'actions/actions-v2/role-actions-v2';

export default {
    onClose: (): ManageRoleActionTypes => ({
        type: ManageRoleActions.SHOW_ROLE_MODAL,
        payload: {}
    }),
    createRole,
    updateRole,
    // onSubmit: (role: IRole) => {
    //    if (role.id) {
    //        return updateRole;
    //    } else {
    //        return createRole;
    //    }
    // }
};
