import { IEmeraldDisabledInput } from '../shared/disabled.input';
import { IEmeraldTitleInput } from '../shared/title.input';
import {
  EmeraldBadgeSize,
  EmeraldBadgeSizeType,
  EmeraldBadgeVariant,
} from './badge.size';

export class EmeraldBadgeCoreInputs implements IEmeraldBadgeCoreInputs {
  disabled?: boolean = false;
  title?: string;
  size?: EmeraldBadgeSizeType = EmeraldBadgeSize.SMALL;
  variant: EmeraldBadgeVariant = EmeraldBadgeVariant.DEFAULT;
  badgeCount?: string;
}

export interface IEmeraldBadgeCoreInputs
  extends IEmeraldDisabledInput,
    IEmeraldTitleInput {
  size?: EmeraldBadgeSizeType | EmeraldBadgeSize;
  variant: EmeraldBadgeVariant;
  badgeCount?: string;
}
