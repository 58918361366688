import React from 'react';
import '../../scss/quantum/pages/custom-popover.scss';

interface IProps {
    content: any;
    onClose?: () => void;
}

export const CustomPopOver: React.FC<React.PropsWithChildren<IProps>> = (props) => {

    return (
        <div className="popover__wrapper">
            {props.children}
            <div className="popover__content">
                <button
                    className="modal-close is-small"
                    aria-label="close"
                    onClick={props.onClose}
                />
                <div className="popover__message">{props.content}</div>
            </div>
        </div>
    );
};
