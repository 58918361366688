import { compact, isFunction } from 'lodash';
import * as React from 'react';

import { SliderLabel } from '.';

export interface IProps {
    labelStart?: SliderLabel;
    labelEnd?: SliderLabel;
    currentValue: number;
}

const getLabelText = (label: SliderLabel, value: number) => isFunction(label)
    ? (label as any)(value)
    : label;

const getLabel = ({ label, style }: { label: SliderLabel, style: React.CSSProperties }, value: number, idx: number) => {
    if (label) {
        return (
            <div key={idx} className="ValueSlider__label" style={style}>
                {getLabelText(label, value)}
            </div>
        );
    }

    return null;
};

export const SliderLabels: React.FC<IProps> = (props) => {
    const labels = compact([
        { label: props.labelStart, style: { left: 0 } },
        { label: props.labelEnd, style: { right: 0 } },
    ].map((args, i) => getLabel(args, props.currentValue, i)));

    if (!labels.length) {
        return null;
    }

    return (
        <div className="ValueSlider__labels">
            {labels}
        </div>
    );
};
