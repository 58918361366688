/* eslint-disable @typescript-eslint/no-inferrable-types */
import { IEmeraldTitleInput } from '../shared/title.input';
import {
  EmeraldBannerVariant,
  EmeraldBannerVariantType,
} from './banner.variant';

export abstract class EmeraldBannerCoreInputs
  implements IEmeraldBannerCoreInputs
{
  open?: boolean = false;
  title?: string;
  content: any = '';
  hasAction?: boolean = false;
  actionText?: string = '';
  autoDismiss?: boolean = false;
  autoDismissMilliseconds?: number = 5000;
  variant?: EmeraldBannerVariant | EmeraldBannerVariantType =
    EmeraldBannerVariant.INFO;
}

export interface IEmeraldBannerCoreInputs extends IEmeraldTitleInput {
  open?: boolean;
  content: any;
  hasAction?: boolean;
  actionText?: string;
  autoDismiss?: boolean;
  autoDismissMilliseconds?: number;
  variant?: EmeraldBannerVariant | EmeraldBannerVariantType;
}
