import { FormikProps } from 'formik';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Control, Field, Help, Input } from '../../../../components/form';
import { BulmaColor } from '../../../../enums/BulmaColor';
import { BulmaSize } from '../../../../enums/BulmaSize';
import { ICermProcessStep, ICermProcessStepLinks } from '../../../../models/cerm-process-step';
import _ from 'lodash';
import { Button } from 'components/v2/components';
import { ICermOverviewPillar } from 'models/cerm-overview';
import { formikFieldCss, getKeyed, scrollToFirstValidationError } from 'utils/form-utils';
import { onRouteChange } from 'actions/app-actions';
import {
    RouteUrlRiskTemplate,
    RouteUrlTechnologyPlatform,
} from 'routes/upsert-cerm-process-step/routes';
import { ProcessStepDeleteConfirmation } from 'routes/upsert-cerm-process-step/StepArchiveConfirmation';
import useTranslate from 'translations/translation-utils';

interface IRouteParams {
    operatingPlatform?: string;
}

export interface IProps extends RouteComponentProps<IRouteParams> {
    cermItems?: ICermOverviewPillar[];
    processSteps?: Partial<ICermProcessStep>
    formikBag?: FormikProps<Partial<ICermProcessStep>>;
    isLoading?: boolean;
    setErrorDialogVisible: (isDialogVisible: boolean) => void;
}

export const CermLinks: React.FC<IProps> = ({ formikBag, match, setErrorDialogVisible }) => {
    const translate = useTranslate();

    const field = (name: Extract<keyof ICermProcessStepLinks, string>) => `cermProcessStepsLinks.${name}`;
    const getErrors = (name: Extract<keyof ICermProcessStepLinks, string>) =>
        getKeyed(formikBag, 'errors', field(name));
    const isTouched = (name: Extract<keyof ICermProcessStepLinks, string>) =>
        getKeyed(formikBag, 'touched', field(name));

    const getHelpByKey = (name: Extract<keyof ICermProcessStepLinks, string>) => (
        <Help isShown={isTouched(name)} bulmaColor={BulmaColor.Danger}>
            {getErrors(name)}
        </Help>
    );
    const validateForm = (callback) => {
        formikBag.validateForm().then((errors) => {
            if (!Object.keys(errors).some((field) => field.length > 0)) {
                callback()
            } else {
                formikBag.submitForm();
                scrollToFirstValidationError();
                setErrorDialogVisible(true)
            }
        })
    }
    const validateWithoutField = (callback) => {
        formikBag.validateForm().then((errors) => {
            if (!Object.keys(errors).includes('cermProcessStepsLinks')) {
                callback();
            } else {
                formikBag.submitForm();
                scrollToFirstValidationError();
            }
        })
    }

    const onSaveAsDraft: React.MouseEventHandler<HTMLButtonElement> = async () => {
        validateForm(async () => {
            await formikBag.setFieldValue('status', 'Draft');
            formikBag.submitForm();
        })
    };

    const onPublish: React.MouseEventHandler<HTMLButtonElement> = async () => {
        validateForm(async () => {
            await formikBag.setFieldValue('status', 'Published');
            formikBag.submitForm();
        })
    };

    const onNaviagetToNext: React.MouseEventHandler<HTMLButtonElement> = () => {
        validateWithoutField(() => {
            onRouteChange(RouteUrlTechnologyPlatform(match.params.operatingPlatform, formikBag.values.id));
        })
    };

    const onNavigateBack: React.MouseEventHandler<HTMLButtonElement> = () => {
        validateWithoutField(() => {
            onRouteChange(RouteUrlRiskTemplate(match.params.operatingPlatform, formikBag.values.id));
        })
    };

    return (
        <>
            <Field
                isHorizontal={true}
                htmlFor={field('trainingLink')}
                label={translate('UpsertCermProcessStep.Views.LinksForm.TrainingLink')}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <Input
                            id={field('trainingLink')}
                            name={field('trainingLink')}
                            aria-required="true"
                            placeholder={translate('UpsertCermProcessStep.Views.LinksForm.TrainingLink')}
                            type="text"
                            className={formikFieldCss(formikBag, 'title')}
                            value={
                                formikBag.values.cermProcessStepsLinks &&
                                formikBag.values.cermProcessStepsLinks.trainingLink
                            }
                            onChange={formikBag.handleChange}
                            onBlur={(event) => formikBag.setFieldValue(event.target.name, event.target.value.trim())}

                        />
                    </Control>
                    {getHelpByKey('trainingLink')}
                </Field>
            </Field>

            <Field
                isHorizontal={true}
                htmlFor={field('documentLink')}
                label={translate('UpsertCermProcessStep.Views.LinksForm.DocLink')}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <Input
                            id={field('documentLink')}
                            name={field('documentLink')}
                            aria-required="true"
                            placeholder={translate('UpsertCermProcessStep.Views.LinksForm.DocLinkPlaceholder')}
                            type="text"
                            className={formikFieldCss(formikBag, 'title')}
                            value={
                                formikBag.values.cermProcessStepsLinks &&
                                formikBag.values.cermProcessStepsLinks.documentLink
                            }
                            onChange={formikBag.handleChange}
                            onBlur={(event) => formikBag.setFieldValue(event.target.name, event.target.value.trim())}
                        />
                    </Control>
                    {getHelpByKey('documentLink')}
                </Field>
            </Field>

            <div className="action-groups">
                <div className="action-group">
                    <Button buttonType="cancel" onClick={onNavigateBack}>
                        {translate('Globals.Label.Back')}
                    </Button>
                    <ProcessStepDeleteConfirmation
                        confirmMessage={
                            formikBag.values.status && formikBag.values.status.toLocaleLowerCase() === 'draft'
                                ? translate('UpsertCermProcessStep.Views.LinksForm.DraftConfirmation')
                                : translate('UpsertCermProcessStep.Views.LinksForm.ArchiveConfirmation')
                        }
                    />
                </div>
                <div className="action-group">
                    <Button onClick={onSaveAsDraft}>
                        {translate('Globals.Label.SaveAsDraft')}
                    </Button>

                    {formikBag.values.expectedOutcomes.length > 0 && (
                        <Button onClick={onPublish}>
                            {translate('Globals.Label.Publish')}
                        </Button>
                    )}

                    <Button onClick={onNaviagetToNext}>
                        {translate('Globals.Label.Next')}
                    </Button>
                </div>
            </div>
        </>
    );
};
