export interface IIncidentsFiltersState {
    filterKeywords: string[];
    isFilterModalOpen: boolean;
}

const defaultState: IIncidentsFiltersState = {
    filterKeywords: [],
    isFilterModalOpen: false
};

// tslint:disable-next-line:arrow-return-shorthand
export const getDefaultIncidentsFiltersState = (options?: any) => { return {...defaultState, ...options }; };
