import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { IMenuActions } from 'actions/actions-v2/menu-actions';
import { IMenu } from 'models/menu-model';
import { IAppAction } from 'actions/app-action';

const controllerName = 'menu';
const apiPath = `/api/${controllerName}`;

const getMenuActions = {
    action: IMenuActions.LOAD_MENU,
    actionFulfilled: IMenuActions.LOAD_MENU_FULFILLED,
    actionRejected: IMenuActions.LOAD_MENU_REJECTED,
} as IActionType;

const loadMenuEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getMenuActions).getByOperatingPlatform<IMenu[]>(`${apiPath}`);


export const IMenuEpics = combineEpics(loadMenuEpic);
