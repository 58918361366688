import * as React from 'react';
import { orderBy, isEmpty } from 'lodash';
import { Table, TableHeader, SortOrder, TableNoItem } from 'components/v2/components';
import { IAction } from 'models/action';
import selectors from './selectors';
import actions from './actions';
import { connect } from 'react-redux';
import { IActionFilters } from 'models/action-filters';
import useTranslate from 'translations/translation-utils';

interface IProps {
    siteId: string;
    riskId: string;
    riskMitigationPlanId: string;
    riskActions: IAction[];
    macroActions: IAction[];
    canViewMacroRisks: boolean;
}

export interface IActionProps {
    loadRiskActions: (filters: IActionFilters) => void;
    loadMacroActions: (filters: IActionFilters) => void;
}

const ClientRiskActionsTab: React.FC<IProps & IActionProps> = ({
    siteId,
    riskId,
    riskMitigationPlanId,
    riskActions,
    macroActions,
    canViewMacroRisks,
    loadRiskActions,
    loadMacroActions,
}) => {
    const translate = useTranslate();
    const [sortField, setSortField] = React.useState('');
    const [sortOrder, setSortOrder] = React.useState(SortOrder.off);
    const _actions = isEmpty(macroActions) ? riskActions : macroActions;

    React.useEffect(() => {
        if (!siteId || !riskId) {
            return;
        }
        if (canViewMacroRisks) {
            loadMacroActions({
                siteId,
                riskId,
            } as IActionFilters);
        }
        loadRiskActions({
            siteId: [siteId],
            riskId: [riskId],
        } as IActionFilters);
    }, [siteId, riskId]);

    const onToggleHandler = (field: string) => {
        if (sortField !== field) {
            setSortField(field);
            setSortOrder(SortOrder.asc);
            return;
        }
        const sort = sortOrder === SortOrder.asc ? SortOrder.desc : SortOrder.asc;
        setSortOrder(sort);
    };

    const getSortState = (field: string): SortOrder => {
        if (field === sortField) {
            return sortOrder;
        }
        return SortOrder.off;
    };

    const getSortedList = (): IAction[] => {
        if (sortOrder === SortOrder.off) {
            return _actions;
        }
        return sortOrder === SortOrder.asc
            ? orderBy(_actions, [sortField], ['asc'])
            : orderBy(_actions, [sortField], ['desc']);
    };

    const table = (riskActionList: IAction[]) => {
        return (
            <Table>
                <thead>
                    <tr>
                        <th>{translate('RiskActionTab.TableHeader.Title')}</th>
                        <th>{translate('RiskActionTab.TableHeader.Category')}</th>
                        <th>{translate('RiskActionTab.TableHeader.Status')}</th>
                        <TableHeader
                            sortState={getSortState('dueDate')}
                            onToggle={() => onToggleHandler('dueDate')}
                        >
                            {translate('RiskActionTab.TableHeader.Duedate')}
                        </TableHeader>
                    </tr>
                </thead>
                <tbody className="clickable">
                    {riskActionList && riskActionList.length > 0 ? (
                        riskActionList
                            .filter(x => x.riskMitigationPlanId === riskMitigationPlanId)
                            .map((item, index) => (
                                <tr key={index}>
                                    <td>{item.title}</td>
                                    <td>
                                        {translate('RiskActionTab.Category.dropdown.', item.category)}
                                    </td>
                                    <td>
                                        {translate('RiskActionTab.ActionStatus.dropdown.', item.status)}
                                    </td>
                                    <td className="narrow no-wrap">{item.dueDate.split('T')[0]}</td>
                                </tr>
                            ))
                    ) : (
                        <TableNoItem colSpan={5} />
                    )}
                </tbody>
            </Table>
        );
    };

    return (
        <div className="tabs-container tabs-container-table-only tab-risk-register-action">
            {table(getSortedList())}
        </div>
    );
};
export default connect(selectors, actions)(ClientRiskActionsTab);
