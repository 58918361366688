export type EmeraldIconButtonVariantType =
  | 'Primary'
  | 'Secondary'
  | 'Light'
  | 'Icon';

export enum EmeraldIconButtonVariant {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Light = 'Light',
  Icon = 'Icon',
}
