import React, { ReactNode, useEffect } from 'react';
import Breadcrumb from 'components/breadcrumb/breadcrumb';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { Helmet } from 'react-helmet';
import { IKeyValue } from 'models';
import { AutoRedirectOnSiteChanged } from 'components/AutoRedirectOnSiteChanged/AutoRedirectOnSiteChanged';
import { ConfirmNavigateAwayDialog } from '../dialog/ConfirmNavigateAwayDialog';
import { SiteSelectorDialogContainer } from 'components/singleSelectorDialog/SiteSelectorDialogContainer';

import { easeInScrollToTop } from 'utils/animation-utils';
import useTranslate from 'translations/translation-utils';
import { RoleSelectorDialogContainer } from '../role-selecter-dialog/role-selector-dialog-container';
import { OperatingPlatformSelectorDialogContainer } from '../../operating-platform-selecter-dialog/operating-platform-selector-dialog-container';
interface IProps {
    siteId: string;
    title: string;
    scrollTopOnPageLoad?: boolean;
    isAdminPage?: boolean;
    showConfirmNavigateAwayDialog?: boolean;
    redirectOnSiteChange?: boolean;
    redirectOnSiteChangeUrl?: string;
    breadcrumbCustomLinks?: Array<IKeyValue<string>>;
    showHeader?: boolean;
    className?: string;
    children?: ReactNode;
    selectedSite: string;
    isLoading: boolean;
}

const Page: React.FC<IProps> = ({
    siteId,
    title,
    scrollTopOnPageLoad = true,
    isAdminPage = false,
    showConfirmNavigateAwayDialog = false,
    redirectOnSiteChange = false,
    redirectOnSiteChangeUrl = '/',
    breadcrumbCustomLinks,
    showHeader = true,
    className,
    selectedSite,
    isLoading,
    ...props
}) => {
    useEffect(() => {
        return () => {
            if (scrollTopOnPageLoad) {
                easeInScrollToTop();
            }
        };
    }, [scrollTopOnPageLoad]);

    const translate = useTranslate();

    return (
        <div className={classNames('page', className)}>
            {redirectOnSiteChange && (
                <AutoRedirectOnSiteChanged siteId={siteId} redirectUrl={redirectOnSiteChangeUrl} />
            )}
            {showConfirmNavigateAwayDialog && <ConfirmNavigateAwayDialog when={true} />}
            <RoleSelectorDialogContainer />
            <OperatingPlatformSelectorDialogContainer />
            <SiteSelectorDialogContainer />
            <Helmet>
                <title>
                    {translate('QuantumTitle')} - {title}
                </title>
            </Helmet>
            <Breadcrumb
                isAdmin={isAdminPage}
                customLinks={breadcrumbCustomLinks}
                pageName={showHeader ? title : undefined}
            />
            {props.children}
        </div>
    );
};

const getSiteId = (state: IRootState) => state.app.siteId;
const getSelectedSite = (state: IRootState) => state.person.personProfile.selectedSite;
const getIsLoading = (state: IRootState) => state.person.isLoading;
const mapStateToProps = createSelector(
    getSiteId,
    getSelectedSite,
    getIsLoading,
    (siteId, selectedSite, isLoading) => ({
        siteId,
        selectedSite,
        isLoading,
    })
);

export default connect(mapStateToProps)(Page);
