import { ITemplateValidationSummary } from 'models/logs-upload';
import React from 'react';
import useTranslate, { translationApiRegex } from 'translations/translation-utils';

interface IProps {
    results: ITemplateValidationSummary;
}

export const TemplateValidationSummary: React.FC<IProps> = ({ results }) => {
    const translate = useTranslate();
    return (
        <>
            <div className="template-summary">
                <div className="tabs-container tab-content">
                    <div className="columns">
                        <div className="column">
                            <mark className="high">
                                {`${results.errorCount} ${translate(
                                    'TemplateValidation.Labels.Errors'
                                )}`}
                            </mark>
                        </div>
                        <div className="column invalid-row-count">
                            <mark className="high">
                                {`${results.invalidRowCount} ${translate(
                                    'TemplateValidation.Labels.InvalidRows'
                                )}`}
                            </mark>
                        </div>

                        <div className="column total-row-count">
                            <mark>
                                {`${results.totalRowCount} ${translate(
                                    'TemplateValidation.Labels.TotalRowCount'
                                )}`}
                            </mark>
                        </div>
                        <div className="column processed-valid-row-count">
                            <mark>
                                {`${results.totalRowCount - results.invalidRowCount} ${translate(
                                    'TemplateValidation.Labels.ValidRows'
                                )}`}
                            </mark>
                        </div>
                    </div>
                    {results.validationResults && (
                        <div className="template-error-tab columns">
                            <div className="tabs">
                                <div className="tab">
                                    <input type="checkbox" id="chck2" />
                                    <label className="tab-label" htmlFor="chck2">
                                        {translate('TemplateValidation.Labels.Validation Errors')}
                                    </label>
                                    <div className="tab-content-body">
                                        {results.validationResults.map((errorDescription, key) => {
                                            return (
                                                <div key={key}>
                                                    {errorDescription
                                                        .split('\r\n')
                                                        .map((description, index) => {
                                                            return (<>
                                                                <div key={index}>
                                                                    {
                                                                        description.match(translationApiRegex) === null
                                                                            ? description
                                                                            : translate(description)
                                                                    }
                                                                </div>
                                                            </>);
                                                        })}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
