import React from 'react';
import Page from 'components/v2/page/Page';
import moment from 'moment';
import {
    IShiftHandover,
    IShiftHandoverFilters,
    IShiftHandoverSettings,
    HandoverItemStatus,
    getOption,
    selectedFacilityValue,
} from 'models/shifts/shift-handover';
import { SchedulerWeek, ISchedulerItem, Alert } from 'components/v2/components';
import { onRouteChange } from 'actions/app-actions';
import { SchedulerButtonItem } from 'components/v2/scheduler/SchedulerButtonItem';
import { ShiftHandoverSchedulerItem } from './shared/ShiftHandoverSchedulerItem';
import { isEmpty } from 'lodash';
import { isTodayOrFutureDate } from 'utils/date-utils';
import { Loader } from 'components/loader';
import { SelectField } from 'components/form/fields/select-field';
import { usePageUIPreferences, UiPreferences } from 'utils/page-ui-preferences-utils';
import { buildNewShiftHandoverUrl } from './shared/ShiftHandoverUtils';
import useTranslate from 'translations/translation-utils';
import { HelpModuleContainer } from 'routes/help-module/help-module-container';
import { ModuleType } from 'models/help-module';
import { ShiftsHandoverToolbar } from '../../components/shifts/shifts-handover-toolbar';
import { checkPermissions } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import { IKeyValue } from 'models/key-value';

interface IProps {
    isLoading: boolean;
    siteId: string;
    shiftHandoverSettings: IShiftHandoverSettings;
    shiftHandovers: IShiftHandover[];
    permissions: Array<IKeyValue<string>>;
    loadShiftHandovers: (filters: IShiftHandoverFilters) => void;
    loadSettings: (siteId: string) => void;
}

export const ShiftHandoverPage: React.FC<IProps> = ({
    isLoading,
    siteId,
    shiftHandoverSettings,
    shiftHandovers,
    permissions,
    loadShiftHandovers,
    loadSettings,
}) => {
    const [showHelp, setShowHelp] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [startDate, setStartDate] = React.useState<Date>(null);
    const [endDate, setEndDate] = React.useState<Date>(null);
    const [selectedFacilityId, setSelectedFacilityId] = usePageUIPreferences<string>(
        UiPreferences.ShiftHandoversPageFacilityId,
        null
    );
    const totalDaysToShow = 4;
    const isSameSite = siteId === shiftHandoverSettings.siteId;
    const hasActiveShift = shiftHandoverSettings.hasActiveShift;
    const isFacilityHandoverType = shiftHandoverSettings.shiftHandoverType === 'Facility';
    const facilities = shiftHandoverSettings.facilities || [];

    const onClickHelp = () => {
        setShowHelp(!showHelp);
    };

    React.useEffect(() => {
        if (siteId && !isSameSite) {
            loadSettings(siteId);
            setSelectedFacilityId(null);
        }
    }, [siteId]);

    React.useEffect(() => {
        if (
            siteId &&
            isSameSite &&
            hasActiveShift &&
            startDate &&
            endDate &&
            ((isFacilityHandoverType && selectedFacilityId) || !isFacilityHandoverType)
        ) {
            loadShiftHandovers({
                siteId,
                facilityId: selectedFacilityId,
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
            });
        }
    }, [siteId, shiftHandoverSettings, selectedFacilityId, startDate, endDate]);

    React.useEffect(() => {
        if (isSameSite && !isEmpty(shiftHandoverSettings.facilities) && !selectedFacilityId) {
            setSelectedFacilityId(facilities[0].id);
        }
    }, [shiftHandoverSettings]);

    React.useEffect(() => {
        const offset = totalDaysToShow - 2;
        const sDate = moment(selectedDate).add(-offset, 'day');
        const eDate = moment(selectedDate).add(2, 'day');
        setStartDate(sDate.toDate());
        setEndDate(eDate.toDate());
    }, [selectedDate]);

    const onDateChange = (date: Date) => {
        setSelectedDate(date);
    };

    const navigateToDetailsPage = (id: string, date: Date) => {
        if (id) {
            onRouteChange(`/ShiftHandovers/${id}/details/site/${siteId}`);
        } else {
            const url = buildNewShiftHandoverUrl(
                siteId,
                date,
                shiftHandoverSettings.shiftHandoverType,
                selectedFacilityId
            );

            onRouteChange(url);
        }
    };

    const translate = useTranslate();

    const getScheduleItems = React.useMemo(() => {
        if (!shiftHandovers) {
            return [];
        }

        const items = shiftHandovers.map(
            (s) =>
            ({
                date: s.dateOfShiftHandover,
                content: (
                    <ShiftHandoverSchedulerItem
                        title={s.shiftName}
                        status={s.status}
                        itemsNew={s.handoverItems.countMatches((c) => c.status === null)}
                        itemsOngoing={s.handoverItems.countMatches(
                            (c) => c.status === HandoverItemStatus.Ongoing
                        )}
                        itemsClosed={s.handoverItems.countMatches(
                            (c) => c.status === HandoverItemStatus.Closed
                        )}                        
                        checklistsYes={s.checkListItems.countMatches((c) => c.checkListItemsVersion === s.checkListItemsVersion && c.status === true)}
                        checklistsNo={s.checkListItems.countMatches((c) => c.checkListItemsVersion === s.checkListItemsVersion && c.status === false)}
                        onClick={() => navigateToDetailsPage(s.id, null)}
                    />
                ),
            } as ISchedulerItem)
        );

        if (startDate && checkPermissions([Privilege.ShiftHandover], permissions)) {
            for (let i = 0; i < totalDaysToShow; i++) {
                const newDate = moment(startDate).add(i, 'day');
                if (isTodayOrFutureDate(newDate)) {
                    const date = newDate.toDate();
                    items.push({
                        date,
                        content: (
                            <SchedulerButtonItem
                                buttonText={translate(
                                    'ShiftHandoversPage.Button.CreateHandover.buttonText'
                                )}
                                onClick={() => navigateToDetailsPage(null, date)}
                            />
                        ),
                    });
                }
            }
        }

        return items;
    }, [shiftHandovers, selectedFacilityId]);

    const onFacilityChange = (field: { value: string; label: string }) => {
        setSelectedFacilityId(field.value);
    };

    return (
        <Page title={translate('ShiftHandoversPage.Title')} className="shift-handover-page">
            {hasActiveShift ? (
                <>
                    <ShiftsHandoverToolbar onClickHelp={onClickHelp} isShowHelp={true} />
                    <HelpModuleContainer
                        isShown={showHelp}
                        onClose={onClickHelp}
                        moduleType={ModuleType.ShiftHandover}
                    />
                    {isFacilityHandoverType && (
                        <SelectField
                            id="facilityId"
                            label={translate('ShiftHandoversPage.label.Facility')}
                            className="select-facility"
                            value={selectedFacilityValue(facilities, selectedFacilityId)}
                            options={facilities.map((f) => getOption(f))}
                            handleChange={onFacilityChange}
                        />
                    )}
                    <SchedulerWeek
                        selectedDate={selectedDate}
                        totalDaysToShow={totalDaysToShow}
                        items={getScheduleItems}
                        isLoading={isLoading}
                        onDateChange={onDateChange}
                    />
                    <ul className="legend">
                        <li className="is-acknowledged">
                            {translate('ShiftHandoversPage.legend.Acknowledged')}
                        </li>
                        <li className="is-awaiting">
                            {translate('ShiftHandoversPage.legend.Awaiting')}
                        </li>
                        <li className="is-draft">{translate('ShiftHandoversPage.legend.Draft')}</li>
                    </ul>
                </>
            ) : !isLoading && shiftHandoverSettings.siteId ? (
                <Alert type="warning" title={translate('ShiftHandoversPage.alert.Title')}>
                    <p>{translate('ShiftHandoversPage.alert.Message.Paragraph1')}</p>
                    <p>{translate('ShiftHandoversPage.alert.Message.Paragraph2')}</p>
                    <p>{translate('ShiftHandoversPage.alert.Message.Paragraph3')}</p>
                </Alert>
            ) : (
                <Loader loading={true} />
            )}
        </Page>
    );
};
