import classnames from 'classnames';
import * as React from 'react';

import './scale-view.scss';

export enum ScaleViewType {
    Five,
    Three
}

export interface IProps {
    value: number;
    type: ScaleViewType;
}

const css = (value: IProps['type']) => classnames(
    'ScaleView',
    {
        'ScaleView--is-3': value === ScaleViewType.Three,
        'ScaleView--is-5': value === ScaleViewType.Five,
    }
);

const valueCss = (value: IProps['value']) => classnames(
    'ScaleView__value',
    `ScaleView__value--${value}`
);

export const ScaleView: React.FC<IProps> = ({ type, value }) => (
    <div className={css(type)}>
        <div className={valueCss(value)}>{value}</div>
        <div className="ScaleView__track" />
    </div>
);
