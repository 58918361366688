import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { PersonActions } from 'actions/actions-v2/person-action-v2';
import { AppActions } from 'actions/app-actions';
import {
    IAssignModule,
    IPerson,
    IPersonAssignedItem,
    IPersonToSite,
    IUserAssignModule,
} from 'models/person';
import { IPersonLookup } from 'models/person-lookup';
import { IPersonAssignedSite } from 'models/site-profile';

const controllerName = 'person';

const createActions = {
    action: PersonActions.CREATE_PERSON,
    actionFulfilled: PersonActions.CREATE_PERSON_FULFILLED,
    actionRejected: PersonActions.CREATE_PERSON_REJECTED,
} as IActionType;

const updateActions = {
    action: PersonActions.UPDATE_PERSON,
    actionFulfilled: PersonActions.UPDATE_PERSON_FULFILLED,
    actionRejected: PersonActions.UPDATE_PERSON_REJECTED,
} as IActionType;

const getAllActions = {
    action: PersonActions.LOAD_PERSONS,
    actionFulfilled: PersonActions.LOAD_PERSONS_FULFILLED,
    actionRejected: PersonActions.LOAD_PERSONS_REJECTED,
} as IActionType;

const getAllPersonsActions = {
    action: PersonActions.LOAD_ALL_PERSONS,
    actionFulfilled: PersonActions.LOAD_ALL_PERSONS_FULFILLED,
    actionRejected: PersonActions.LOAD_ALL_PERSONS_REJECTED,
} as IActionType;

const getSitePersonsActions = {
    action: PersonActions.LOAD_SITE_PERSONS,
    actionFulfilled: PersonActions.LOAD_SITE_PERSONS_FULFILLED,
    actionRejected: PersonActions.LOAD_SITE_PERSONS_REJECTED,
} as IActionType;

const getSiteAllPersonsActions = {
    action: PersonActions.LOAD_SITE_ALL_PERSONS,
    actionFulfilled: PersonActions.LOAD_SITE_ALL_PERSONS_FULFILLED,
    actionRejected: PersonActions.LOAD_SITE_ALL_PERSONS_REJECTED,
} as IActionType;

const getActions = {
    action: PersonActions.LOAD_PERSON,
    actionFulfilled: PersonActions.LOAD_PERSON_FULFILLED,
    actionRejected: PersonActions.LOAD_PERSON_REJECTED,
} as IActionType;

const deleteActions = {
    action: PersonActions.DELETE_PERSON,
    actionFulfilled: PersonActions.DELETE_PERSON_FULFILLED,
    actionRejected: PersonActions.DELETE_PERSON_REJECTED,
} as IActionType;

const activateActions = {
    action: PersonActions.ACTIVATE_PERSON,
    actionFulfilled: PersonActions.ACTIVATE_PERSON_FULFILLED,
    actionRejected: PersonActions.ACTIVATE_PERSON_REJECTED,
} as IActionType;

const getPersonsLookup = {
    action: PersonActions.LOAD_PERSONS_LOOKUP,
    actionFulfilled: PersonActions.LOAD_PERSONS_LOOKUP_FULFILLED,
    actionRejected: PersonActions.LOAD_PERSONS_LOOKUP_REJECTED,
} as IActionType;

const getPeopleLinkedAction = {
    action: AppActions.CHANGE_SITE,
    optionalAction: AppActions.LOAD_PEOPLE_LINKED_TO_SITE,
    actionFulfilled: AppActions.LOAD_PEOPLE_LINKED_TO_SITE_FULFILLED,
    actionRejected: AppActions.LOAD_PEOPLE_LINKED_TO_SITE_REJECTED,
} as IActionType;

const getAllPeopleLinkedAction = {
    action: AppActions.CHANGE_SITE,
    optionalAction: AppActions.LOAD_ALL_PEOPLE_LINKED_TO_SITE,
    actionFulfilled: AppActions.LOAD_ALL_PEOPLE_LINKED_TO_SITE_FULFILLED,
    actionRejected: AppActions.LOAD_ALL_PEOPLE_LINKED_TO_SITE_REJECTED,
} as IActionType;

const getPersonAssignedItemAction = {
    action: PersonActions.LOAD_PERSON_ASSIGNED_ITEM,
    actionFulfilled: PersonActions.LOAD_PERSON_ASSIGNED_ITEM_FULFILLED,
    actionRejected: PersonActions.LOAD_PERSON_ASSIGNED_ITEM_REJECTED,
} as IActionType;

const getPersonAssignModulesAction = {
    action: PersonActions.LOAD_PERSON_ASSIGN_MODULES,
    actionFulfilled: PersonActions.LOAD_PERSON_ASSIGN_MODULES_FULFILLED,
    actionRejected: PersonActions.LOAD_PERSON_ASSIGN_MODULES_REJECTED,
} as IActionType;

const updatePersonAssignModulesAction = {
    action: PersonActions.UPDATE_PERSON_ASSIGN_MODULES,
    actionFulfilled: PersonActions.UPDATE_PERSON_ASSIGN_MODULES_FULFILLED,
    actionRejected: PersonActions.UPDATE_PERSON_ASSIGN_MODULES_REJECTED,
} as IActionType;

const getPersonsBySiteAndPrivilegeAction = {
    action: PersonActions.LOAD_PERSONS_BY_SITE_AND_PRIVILEGE,
    actionFulfilled: PersonActions.LOAD_PERSONS_BY_SITE_AND_PRIVILEGE_FULFILLED,
    actionRejected: PersonActions.LOAD_PERSONS_BY_SITE_AND_PRIVILEGE_REJECTED,
} as IActionType;

const loadRelationshipAction = {
    action: PersonActions.LOAD_PERSON_RELATIONSHIPS,
    actionFulfilled: PersonActions.LOAD_PERSON_RELATIONSHIPS_FULFILLED,
    actionRejected: PersonActions.LOAD_PERSON_RELATIONSHIPS_REJECTED,
} as IActionType;

const loadPersonAssignedSitesAction = {
    action: PersonActions.LOAD_PERSON_ASSIGNED_SITES,
    actionFulfilled: PersonActions.LOAD_PERSON_ASSIGNED_SITES_FULFILLED,
    actionRejected: PersonActions.LOAD_PERSON_ASSIGNED_SITES_REJECTED,
} as IActionType;

const createPersonEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createActions, '/ManageRole').post<IPerson>(`/api/${controllerName}`);
const updatePersonEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateActions, '/ManageRole').put<IPerson>(`/api/${controllerName}`);
const loadPersonsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getAllActions).get<IPerson[]>(`/api/${controllerName}`);
const loadAllPersonsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getAllPersonsActions).get<IPerson[]>(`/api/${controllerName}/GetAll`);
const loadSitePersonsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getSitePersonsActions).getById<IPerson[]>(`/api/${controllerName}/site`);
const loadSiteAllPersonsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getSiteAllPersonsActions).getById<IPerson[]>(`/api/${controllerName}/site/all`);
const loadPersonEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getActions).getById<IPerson>(`/api/${controllerName}`);
const deletePersonEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, deleteActions, '/ManageRole').deleteById<string>(`/api/${controllerName}`);
const activatePersonEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, activateActions, '/ManageRole').put<IPerson>(`/api/${controllerName}/Activate`);
const loadPersonsLookupEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getPersonsLookup).getById<IPersonLookup[]>(`/api/${controllerName}/site-users`);
const loadPeopleLinkedToSiteEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getPeopleLinkedAction).get<IPerson>(
        `/api/${controllerName}/GetPeopleLinkedToSite`
    );
const loadAllPeopleLinkedToSiteEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getAllPeopleLinkedAction).get<IPerson>(
        `/api/${controllerName}/GetAllPeopleLinkedToSite`
    );
const loadPersonAssignItemEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getPersonAssignedItemAction).put<IPersonAssignedItem>(`/api/${controllerName}/PersonAssignedItem`);
const loadPersonAssignModulesEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getPersonAssignModulesAction).getById<IAssignModule[]>(`/api/${controllerName}/AssignedModules`);
const updatePersonAssignModulesEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updatePersonAssignModulesAction).put<IUserAssignModule[]>(`/api/${controllerName}/AssignedModules`);
const loadPeopleBySiteAndPrivilegeEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getPersonsBySiteAndPrivilegeAction).getByIdAndShard<IPerson>(
        `/api/${controllerName}/GetBySiteIdAndPrivilegeName`
    );
const loadRelationships: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, loadRelationshipAction).getById<IPersonToSite[]>(
        `/api/${controllerName}/relationships`
    );
const loadPersonAssignedSitesEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, loadPersonAssignedSitesAction).getById<IPersonAssignedSite[]>(`/api/${controllerName}/person-assigned-sites`);

export const personEpicsV2 = combineEpics(
    createPersonEpic,
    updatePersonEpic,
    loadPersonsEpic,
    loadSitePersonsEpic,
    loadSiteAllPersonsEpic,
    loadPersonEpic,
    deletePersonEpic,
    activatePersonEpic,
    loadPersonsLookupEpic,
    loadPeopleLinkedToSiteEpic,
    loadAllPeopleLinkedToSiteEpic,
    loadAllPersonsEpic,
    loadPersonAssignItemEpic,
    loadPersonAssignModulesEpic,
    updatePersonAssignModulesEpic,
    loadPeopleBySiteAndPrivilegeEpic,
    loadRelationships,
    loadPersonAssignedSitesEpic,
);
