import { IEngineer, IEngineersFilters, IEngineerSkill } from 'models';
import { IEngineersState, IEngineerState } from 'state';
import { IAppAction, IGenericAction } from '.././app-action';

export const EngineerActions = {
    LOAD_ENGINEERS: '@@engineers/LOAD_ENGINEERS',
    LOAD_ENGINEERS_FULFILLED: '@@engineers/LOAD_ENGINEERS_FULFILLED',
    LOAD_ENGINEERS_REJECTED: '@@engineers/LOAD_ENGINEERS_REJECTED',
    LOAD_ENGINEERS_CANCELLED: '@@engineers/LOAD_ENGINEERS_CANCELLED',
    CLEAR_ENGINEER: '@@engineers/CLEAR_ENGINEER',
    LOAD_ENGINEER: '@@engineers/LOAD_ENGINEER',
    LOAD_ENGINEER_FULFILLED: '@@engineers/LOAD_ENGINEER_FULFILLED',
    LOAD_ENGINEER_REJECTED: '@@engineers/LOAD_ENGINEER_REJECTED',
    LOAD_ENGINEER_CANCELLED: '@@engineers/LOAD_ENGINEER_CANCELLED',
    CREATE_ENGINEER: '@@engineers/CREATE_ENGINEER',
    CREATE_ENGINEER_FULFILLED: '@@engineers/CREATE_ENGINEER_FULFILLED',
    CREATE_ENGINEER_REJECTED: '@@engineers/CREATE_ENGINEER_REJECTED',
    CREATE_ENGINEER_CANCELLED: '@@engineers/CREATE_ENGINEER_CANCELLED',
    UPDATE_ENGINEER: '@@engineers/UPDATE_ENGINEER',
    UPDATE_ENGINEER_FULFILLED: '@@engineers/UPDATE_ENGINEER_FULFILLED',
    UPDATE_ENGINEER_REJECTED: '@@engineers/UPDATE_ENGINEER_REJECTED',
    UPDATE_ENGINEER_CANCELLED: '@@engineers/UPDATE_ENGINEER_CANCELLED',
    DELETE_ENGINEER: '@@engineers/DELETE_ENGINEER',
    DELETE_ENGINEER_FULFILLED: '@@engineers/DELETE_ENGINEER_FULFILLED',
    DELETE_ENGINEER_REJECTED: '@@engineers/DELETE_ENGINEER_REJECTED',
    DELETE_ENGINEER_CANCELLED: '@@engineers/DELETE_ENGINEER_CANCELLED',
    EXPORT_ENGINEER: '@@engineers/EXPORT_ENGINEER',
    EXPORT_ENGINEER_FULFILLED: '@@engineers/EXPORT_ENGINEER_FULFILLED',
    EXPORT_ENGINEER_REJECTED: '@@engineers/EXPORT_ENGINEER_REJECTED',
    EXPORT_ENGINEER_CANCELLED: '@@engineers/EXPORT_ENGINEER_CANCELLED',

    LOAD_ENGINEER_SKILLS: '@@engineers/LOAD_ENGINEER_SKILLS',
    LOAD_ENGINEER_SKILLS_FULFILLED: '@@engineers/LOAD_ENGINEER_SKILLS_FULFILLED',
    LOAD_ENGINEER_SKILLS_REJECTED: '@@engineers/LOAD_ENGINEER_SKILLS_REJECTED',
    LOAD_ENGINEER_SKILLS_CANCELLED: '@@engineers/LOAD_ENGINEER_SKILLS_CANCELLED',
    UPSERT_ENGINEER_SKILL: '@@engineers/UPSERT_ENGINEER_SKILL',
    UPSERT_ENGINEER_SKILL_FULFILLED: '@@engineers/UPSERT_ENGINEER_SKILL_FULFILLED',
    UPSERT_ENGINEER_SKILL_REJECTED: '@@engineers/UPSERT_ENGINEER_SKILL_REJECTED',
    UPSERT_ENGINEER_SKILL_CANCELLED: '@@engineers/UPSERT_ENGINEER_SKILL_CANCELLED',

    ADD_FILTER_KEYWORD: '@@engineers/ADD_FILTER_KEYWORD',
    REMOVE_FILTER_KEYWORD: '@@engineers/REMOVE_FILTER_KEYWORD',
    CLEAR_FILTERS: '@@engineers/CLEAR_FILTERS',
    TOGGLE_FILTER_EXPANDED: '@@engineers/TOGGLE_FILTER_EXPANDED',
    CHANGE_FILTER_VALUE: '@@engineers/CHANGE_FILTER_VALUE',

    ADD_FILTER_KEYWORD_ENGINEER: '@@engineers/ADD_FILTER_KEYWORD_ENGINEER',
    REMOVE_FILTER_KEYWORD_ENGINEER: '@@engineers/REMOVE_FILTER_KEYWORD_ENGINEER',
    TOGGLE_FILTER_EXPANDED_ENGINEER: '@@engineers/TOGGLE_FILTER_EXPANDED_ENGINEER',
    CHANGE_FILTER_VALUE_ENGINEER: '@@engineers/CHANGE_FILTER_VALUE_ENGINEER',

    TOGGLE_CATEGORY: '@@engineers/TOGGLE_CATEGORY',

    TOGGLE_ENGINEER_SKILLS_CATEGORY: '@@engineers/TOGGLE_ENGINEER_SKILLS_CATEGORY',
    ADD_ENGINEER_SKILLS_CATEGORY: '@@engineers/ADD_ENGINEER_SKILLS_CATEGORY',
    TOGGLE_ITEM_EXPANDED: '@@engineers/TOGGLE_ITEM_EXPANDED',
    CHANGE_SORT_ORDER_VALUE: '@@engineers/CHANGE_SORT_ORDER_VALUE',
    SORT_ENGINEERS: '@@engineers/SORT_ENGINEERS',

    SHOW_FILTER_MODAL: '@@engineers/SHOW_FILTER_MODAL',
    HIDE_FILTER_MODAL: '@@engineers/HIDE_FILTER_MODAL',
    LOAD_PAGE: '@@engineers/LOAD_PAGE',
    LOAD_PAGE_FULFILLED: '@@engineers/LOAD_PAGE_FULFILLED',
    LOAD_PAGE_REJECTED: '@@engineers/LOAD_PAGE_REJECTED',
    LOAD_PAGE_CANCELLED: '@@engineers/LOAD_PAGE_CANCELLED',
    LOAD_LOOKUPS: '@@engineers/LOAD_LOOKUPS',
    LOAD_LOOKUPS_FULFILLED: '@@engineers/LOAD_LOOKUPS_FULFILLED',
    LOAD_LOOKUPS_REJECTED: '@@engineers/LOAD_LOOKUPS_REJECTED',
    DOWNLOAD_ENGINEERS: '@@engineers/DOWNLOAD_ENGINEERS',
    DOWNLOAD_ENGINEERS_FULFILLED: '@@engineers/DOWNLOAD_ENGINEERS_FULFILLED',
    DOWNLOAD_ENGINEERS_CANCELLED: '@@engineers/DOW/NLOAD_ENGINEERS_CANCELLED',
    DOWNLOAD_ENGINEERS_REJECTED: '@@engineers/DOWNLOAD_ENGINEERS_REJECTED',

    DOWNLOAD_ENGINEER_SKILLS: '@@engineers/DOWNLOAD_ENGINEER_SKILLS',
    DOWNLOAD_ENGINEER_SKILLS_FULFILLED: '@@engineers/DOWNLOAD_ENGINEER_SKILLS_FULFILLED',
    DOWNLOAD_ENGINEER_SKILLS_CANCELLED: '@@engineers/DOW/NLOAD_ENGINEER_SKILLS_CANCELLED',
    DOWNLOAD_ENGINEER_SKILLS_REJECTED: '@@engineers/DOWNLOAD_ENGINEER_SKILLS_REJECTED',

    CHANGE_STATUS: '@@engineers/CHANGE_STATUS',
    CHANGE_STATUS_FULFILLED: '@@engineers/CHANGE_STATUS_FULFILLED',
    CHANGE_STATUS_CANCELLED: '@@engineers/CHANGE_STATUS_CANCELLED',
    CHANGE_STATUS_REJECTED: '@@engineers/CHANGE_STATUS_REJECTED',
    CHANGE_TRAINING: '@@engineers/CHANGE_TRAINING',
    CHANGE_TRAINING_EXPIRY_DATE: '@@engineers/CHANGE_TRAINING_EXPIRY_DATE',
    LOAD_HISTORY: '@@engineers/LOAD_HISTORY',
    LOAD_HISTORY_FULFILLED: '@@engineers/LOAD_HISTORY_FULFILLED',
    LOAD_HISTORY_REJECTED: '@@engineers/LOAD_HISTORY_REJECTED',

    EXPORT_ALL_ENGINEERS: '@@engineers/EXPORT_ALL_ENGINEERS',
    EXPORT_ALL_ENGINEERS_FULFILLED: '@@engineers/EXPORT_ALL_ENGINEERS_FULFILLED',
    EXPORT_ALL_ENGINEERS_REJECTED: '@@engineers/EXPORT_ALL_ENGINEERS_REJECTED',

    RESET_ENGINEER_EXPANDED_CATEGORIES: '@@engineers/RESET_ENGINEER_EXPANDED_CATEGORIES',
};

export const loadPage = (): IAppAction => ({
    type: EngineerActions.LOAD_PAGE,
});

export const loadPageFulfilled = (engineers: IEngineer[]): IAppAction => ({
    type: EngineerActions.LOAD_PAGE_FULFILLED,
    payload: engineers,
});

export const loadPageRejected = (error: any): IAppAction => ({
    type: EngineerActions.LOAD_PAGE_REJECTED,
    payload: error,
});

export const loadPageCancelled = (): IAppAction => ({
    type: EngineerActions.LOAD_PAGE_CANCELLED,
});

export const loadEngineers = (filters: IEngineersFilters): IAppAction => ({
    type: EngineerActions.LOAD_ENGINEERS,
    payload: filters,
});

export const loadEngineersRejected = (error: any): IAppAction => ({
    type: EngineerActions.LOAD_ENGINEERS_REJECTED,
    payload: error,
});

export const loadEngineersCancelled = (): IAppAction => ({
    type: EngineerActions.LOAD_ENGINEERS_CANCELLED,
});

export const loadEngineersFulfilled = (engineers: IEngineer[]): IAppAction => ({
    type: EngineerActions.LOAD_ENGINEERS_FULFILLED,
    payload: engineers,
});

export const clearEngineer = (): IAppAction => ({
    type: EngineerActions.CLEAR_ENGINEER,
});

export const loadEngineer = (id: string): IAppAction => ({
    type: EngineerActions.LOAD_ENGINEER,
    payload: id,
});

export const loadEngineerRejected = (error: any): IAppAction => ({
    type: EngineerActions.LOAD_ENGINEER_REJECTED,
    payload: error,
});

export const loadEngineerCancelled = (): IAppAction => ({
    type: EngineerActions.LOAD_ENGINEER_CANCELLED,
});

export const loadEngineerFulfilled = (incident: IEngineer): IAppAction => ({
    type: EngineerActions.LOAD_ENGINEER_FULFILLED,
    payload: incident,
});

export const createEngineer = (engineer: IEngineer): IAppAction => ({
    type: EngineerActions.CREATE_ENGINEER,
    payload: engineer,
});
export const updateEngineer = (engineer: IEngineer): IAppAction => ({
    type: EngineerActions.UPDATE_ENGINEER,
    payload: engineer,
});
export const createEngineerRejected = (error: any): IAppAction => ({
    type: EngineerActions.CREATE_ENGINEER_REJECTED,
    payload: error,
});

export const createEngineerCancelled = (): IAppAction => ({
    type: EngineerActions.CREATE_ENGINEER_CANCELLED,
});

export const createEngineerFulfilled = (engineer: IEngineer): IGenericAction<IEngineer> => ({
    type: EngineerActions.CREATE_ENGINEER_FULFILLED,
    payload: engineer,
});

export const updateEngineerRejected = (error: any): IAppAction => ({
    type: EngineerActions.UPDATE_ENGINEER_REJECTED,
    payload: error,
});

export const updateEngineerCancelled = (): IAppAction => ({
    type: EngineerActions.UPDATE_ENGINEER_CANCELLED,
});

export const updateEngineerFulfilled = (engineer: IEngineer): IGenericAction<IEngineer> => ({
    type: EngineerActions.UPDATE_ENGINEER_FULFILLED,
    payload: engineer,
});

export const loadEngineerSkills = (): IAppAction => ({
    type: EngineerActions.LOAD_ENGINEER_SKILLS,
});

export const loadEngineerSkillsRejected = (error: any): IAppAction => ({
    type: EngineerActions.LOAD_ENGINEER_SKILLS_REJECTED,
    payload: error,
});

export const loadEngineerSkillsCancelled = (): IAppAction => ({
    type: EngineerActions.LOAD_ENGINEER_SKILLS_CANCELLED,
});

export const loadEngineerSkillsFulfilled = (engineerSkills: IEngineerSkill[]): IAppAction => ({
    type: EngineerActions.LOAD_ENGINEER_SKILLS_FULFILLED,
    payload: engineerSkills,
});

export const deleteEngineer = (id: string): IAppAction => ({
    type: EngineerActions.DELETE_ENGINEER,
    payload: id,
});

export const deleteEngineerRejected = (error: any): IAppAction => ({
    type: EngineerActions.DELETE_ENGINEER_REJECTED,
    payload: error,
});

export const deleteEngineerCancelled = (): IAppAction => ({
    type: EngineerActions.DELETE_ENGINEER_CANCELLED,
});

export const deleteEngineerFulfilled = (engineer: IEngineer): IGenericAction<IEngineer> => ({
    type: EngineerActions.DELETE_ENGINEER_FULFILLED,
    payload: engineer,
});

export const exportEngineer = (id: string): IAppAction => ({
    type: EngineerActions.EXPORT_ENGINEER,
    payload: id,
});

export const exportEngineerRejected = (error: any): IAppAction => ({
    type: EngineerActions.EXPORT_ENGINEER_REJECTED,
    payload: error,
});

export const exportEngineerCancelled = (): IAppAction => ({
    type: EngineerActions.EXPORT_ENGINEER_CANCELLED,
});

export const exportEngineerFulfilled = (engineer: IEngineer): IGenericAction<IEngineer> => ({
    type: EngineerActions.EXPORT_ENGINEER_FULFILLED,
    payload: engineer,
});

export const toggleCategory = (id: string): IAppAction => ({
    type: EngineerActions.TOGGLE_CATEGORY,
    payload: { id },
});

export const toggleEngineerSkillCategory = (id: string): IAppAction => ({
    type: EngineerActions.TOGGLE_ENGINEER_SKILLS_CATEGORY,
    payload: { id },
});

export const addEngineerSkillCategory = (id: string): IAppAction => ({
    type: EngineerActions.ADD_ENGINEER_SKILLS_CATEGORY,
    payload: { id },
});

export const onSortEngineers = (key: string, sortAscending: boolean) => ({
    type: EngineerActions.SORT_ENGINEERS,
    payload: { key, sortAscending },
});

export const setSortOrder = (sortColumn?: string, sortAscending?: boolean): IAppAction => ({
    type: EngineerActions.CHANGE_SORT_ORDER_VALUE,
    payload: { sortColumn, sortAscending },
});

export const setFilterValue = (field: string, value: string): IAppAction => ({
    type: EngineerActions.CHANGE_FILTER_VALUE,
    payload: { field, value },
});

export const clearFilters = (): IAppAction => ({
    type: EngineerActions.CLEAR_FILTERS,
});

export const addFilterKeyword = (keyword: string): IAppAction => ({
    type: EngineerActions.ADD_FILTER_KEYWORD,
    payload: { keyword },
});

export const removeFilterKeyword = (keyword: string): IAppAction => ({
    type: EngineerActions.REMOVE_FILTER_KEYWORD,
    payload: { keyword },
});

export const toggleFilterExpanded = (id: Extract<keyof IEngineersState, string>): IAppAction => ({
    type: EngineerActions.TOGGLE_FILTER_EXPANDED,
    payload: { id },
});

export const setFilterValueEngineer = (field: string, value: string): IAppAction => ({
    type: EngineerActions.CHANGE_FILTER_VALUE_ENGINEER,
    payload: { field, value },
});

export const addFilterKeywordEngineer = (keyword: string): IAppAction => ({
    type: EngineerActions.ADD_FILTER_KEYWORD_ENGINEER,
    payload: { keyword },
});

export const removeFilterKeywordEngineer = (keyword: string): IAppAction => ({
    type: EngineerActions.REMOVE_FILTER_KEYWORD_ENGINEER,
    payload: { keyword },
});

export const toggleFilterExpandedEngineer = (
    id: Extract<keyof IEngineerState, string>
): IAppAction => ({
    type: EngineerActions.TOGGLE_FILTER_EXPANDED_ENGINEER,
    payload: { id },
});

export const toggleItemExpanded = (id: string, siteId: string): IAppAction => ({
    type: EngineerActions.TOGGLE_ITEM_EXPANDED,
    payload: { id, siteId },
});

export const showFilterModal = (): IAppAction => ({
    type: EngineerActions.SHOW_FILTER_MODAL,
});

export const hideFilterModal = (): IAppAction => ({
    type: EngineerActions.HIDE_FILTER_MODAL,
});

export const loadLookups = (): IAppAction => ({
    type: EngineerActions.LOAD_LOOKUPS,
});

export const loadLookupsRejected = (error: any): IAppAction => ({
    type: EngineerActions.LOAD_LOOKUPS_REJECTED,
    payload: error,
});

export const downloadEngineers = (): IAppAction => ({
    type: EngineerActions.DOWNLOAD_ENGINEERS,
});

export const downloadEngineersFulfilled = (payload: any): IAppAction => ({
    type: EngineerActions.DOWNLOAD_ENGINEERS_FULFILLED,
    payload,
});

export const downloadEngineersCancelled = (): IAppAction => ({
    type: EngineerActions.DOWNLOAD_ENGINEERS_CANCELLED,
});

export const downloadEngineersRejected = (): IAppAction => ({
    type: EngineerActions.DOWNLOAD_ENGINEERS_REJECTED,
});

export const onChangeStatus = (skill: IEngineerSkill) => ({
    type: EngineerActions.CHANGE_STATUS,
    payload: skill,
});

export const onChangeTraining = (
    trainingTypeId: string,
    trainingCategoryId: string,
    remove: boolean
) => ({
    type: EngineerActions.CHANGE_TRAINING,
    payload: {
        trainingTypeId,
        trainingCategoryId,
        remove,
    },
});

export const onChangeTrainingExpiryDate = (
    trainingCategoryId: string,
    expiryDate: Date | string
) => ({
    type: EngineerActions.CHANGE_TRAINING_EXPIRY_DATE,
    payload: {
        trainingCategoryId,
        expiryDate,
    },
});

export const onChangeStatusFulfilled = (skill: IEngineerSkill) => ({
    type: EngineerActions.CHANGE_STATUS_FULFILLED,
    payload: skill,
});

export const onChangeStatusCancelled = () => ({
    type: EngineerActions.CHANGE_STATUS_CANCELLED,
});

export const onChangeStatusRejected = () => ({
    type: EngineerActions.CHANGE_STATUS_REJECTED,
});

export const loadHistory = (id: string): IAppAction => ({
    type: EngineerActions.LOAD_HISTORY,
    payload: id,
});

export const exportAllEngineers = (): IAppAction => ({
    type: EngineerActions.EXPORT_ALL_ENGINEERS,
});

export const resetExpandedCategories = (): IAppAction => ({
    type: EngineerActions.RESET_ENGINEER_EXPANDED_CATEGORIES,
});
