import * as React from 'react';
import { IRisk, RiskMitigationPlanStatus } from 'models/risk';
import { FormikProps } from 'formik';
import { TextField } from 'components/form/fields/text-field';
import { SelectField } from 'components/form/fields/select-field';
import { NumberField } from 'components/form/fields/number-field';
import { DatePickerField } from 'components/form/fields/date-picker-field';
import { IKeyValue } from 'models';
import { TextAreaField } from 'components/form/fields/textarea-field';
import { PopoutSelectField } from 'components/form/fields/popout-select-field';
import { IPersonLookup } from 'models/person-lookup';
import { getCurrencyIsoCodes } from 'utils/currency-utils';
import { object, string, number, date } from 'yup';
import { IMoney } from 'models/money';
import useTranslate from 'translations/translation-utils';
import { checkPermission, checkSiteHasFeature } from 'utils/permission-utils';
import { SiteFeatures } from 'actions/site-feature';
import appConfig from 'helpers/config-helper';
import { Privilege } from 'enums/Privilege';
import { ISiteProfile } from 'models/site-profile';
import { RiskCategory } from 'models/risk-category';
import { ICommonLookupModel } from 'models/common-lookup-model';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconColor, MaterialIconType } from 'routes/material-icon/material-icon-type';
import * as RiskStatus from 'models/risk-statuses';
import { Button, ConfirmDialog } from 'components/v2/components';
import { RiskApprovalsTab } from '../approvals/risk-approvals-tab';
import './view-approvers-dialogue.scss';
import { ReadOnlyField } from 'components/form/fields/read-only-field';
import { MitigationPlanForm } from './mitigation-plan-form';
import Comments from 'components/comments/comments';
import { Entity } from 'models/entity';
import { Control, Field } from 'components/form';
import { formatDate } from 'utils/date-utils';
import { IActionFilters } from 'models/action-filters';
import moment from 'moment';
import { IPerson } from 'models/person';
import Asset from 'components/asset/asset';
import { IAssetModel } from 'models/asset/asset-model';

interface IProps {
    formikBag: FormikProps<IRisk>;
    categories: RiskCategory[];
    impacts: Array<IKeyValue<string>>;
    likelihoods: Array<IKeyValue<string>>;
    riskStatuses: Array<IKeyValue<string>>;
    lookupSitePeopleRoles: IPersonLookup[];
    siteAndPrivilegePersons: IPerson[];
    siteAndPrivilegePersonsIsLoading: boolean;
    lookupSiteFeatures: Array<IKeyValue<string[]>>;
    permissions: Array<IKeyValue<string>>;
    riskId?: string;
    isReadOnly?: boolean;
    isReadOnlyMacroRisk?: boolean;
    isCermAssesment?: boolean;
    site: ISiteProfile;
    commentSave: boolean;
    users?: IPersonLookup[];
    isMitigationValid?: boolean;
    isMacro: boolean;
    setShowWarningPopup?: (value: boolean) => void;
    isRiskActiveAndNotClose?: () => boolean;
    canEditInExpertReview?: () => boolean;
    onReviewDateChange?: (value: Date) => void;
    setIsMitigationValid?: (value: boolean) => void;
    loadRiskActions: (filters: IActionFilters) => void;
    loadMacroActions: (filters: IActionFilters) => void;
}

export const riskDetailsFormValidationSchema = object<Partial<IRisk>>().shape({
    title: string().trim().required('Full title is required'),
    riskCategory: string().required('Category is required'),
    riskSubCategory: string().required('Sub category is required'),
    description: string().trim().required('Description is required'),
    likelihood: string().required('Likelihood is required'),
    impact: string().required('Impact is required'),
    riskStatus: string().required('Status is required'),
    reviewDate: date().required('Review date is required').nullable(true),
    assigneeId: string().required('Assignee is required').nullable(true),
    cost: object<Partial<IMoney>>()
        .notRequired()
        .nullable(true)
        .shape({
            currencyIsoCode: string()
                .nullable(true)
                .when('amount', {
                    is: val => val > 0,
                    then: string().required('Currency type is required'),
                }),
            amount: number().nullable(true).typeError('Please specify a valid number'),
        })
});

export const RiskDetailsForm: React.FC<IProps> = ({
    formikBag,
    lookupSiteFeatures,
    permissions,
    riskId,
    isReadOnly,
    isReadOnlyMacroRisk,
    isCermAssesment,
    site,
    commentSave,
    users,
    isMitigationValid,
    siteAndPrivilegePersons,
    siteAndPrivilegePersonsIsLoading,
    setShowWarningPopup,
    isRiskActiveAndNotClose,
    canEditInExpertReview,
    onReviewDateChange,
    setIsMitigationValid,
    loadRiskActions,
    loadMacroActions,
    ...props
}) => {
    const config = appConfig();
    const [riskLevel, setRiskLevel] = React.useState<string>(null);
    const [isViewApproversDialogVisible, setIsViewApproversDialogVisible] = React.useState(false);
    const [isActionEditable, setIsActionEditable] = React.useState(false)

    const onViewApproversDialogueClose = () => {
        setIsViewApproversDialogVisible(false);
    };

    const shareTypes: Array<IKeyValue<string>> = [
        { key: '0', value: 'Internal only' },
        { key: '1', value: 'Client facing' },
    ];

    const riskCategories = props.categories.filter(
        (x) => !x.operatingPlatform || x.operatingPlatform === site.operatingPlatform
    );

    const riskSubCategories: ICommonLookupModel[] = !!formikBag.values.riskCategory
        ? props.categories
            .find((x) => x.key === formikBag.values.riskCategoryId)
            ?.subCategory?.filter(
                (x) => !x.operatingPlatform || x.operatingPlatform === site.operatingPlatform
            ) ?? []
        : [];

    React.useEffect(() => {
        if (!site.id || !riskId) {
            return;
        }

        if (props.isMacro) {
            loadMacroActions({
                siteId: site.id,
                riskId,
            } as IActionFilters);
        } else {
            loadRiskActions({
                siteId: site.id,
                riskId,
            } as IActionFilters);
        }
    }, [site.id, riskId, props.isMacro]);

    React.useEffect(() => {
        if (formikBag.values.impactId) {
            setRiskLevel(getRiskLevel(formikBag.values.impactId, formikBag.values.likelihoodId));
        }
    }, [formikBag.values.impactId, formikBag.values.likelihoodId]);

    const translate = useTranslate();
    const currencies = getCurrencyIsoCodes();
    const allowReviewDateToBeEditable = !riskId && !isReadOnly;

    const handleDateChange = (newDate: Date) => {
        formikBag.setFieldValue('reviewDate', newDate);
        onReviewDateChange(newDate);
    };

    const handleCategoryChange = (field: { value: string; label: string }) => {
        const selectedValue = props.categories.find((i) => i.key === field.value);
        formikBag.setFieldValue('riskCategoryId', field.value);
        formikBag.setFieldValue('riskCategory', selectedValue.label);
        formikBag.setFieldValue('riskSubCategoryId', '');
        formikBag.setFieldValue('riskSubCategory', '');
    };

    const handleSubCategoryChange = (field: { value: string; label: string }) => {
        const selectedValue = props.categories
            .flatMap(x => x.subCategory)
            .find(i => i.key === field.value);
        formikBag.setFieldValue('riskSubCategoryId', field.value);
        formikBag.setFieldValue('riskSubCategory', selectedValue.label);
    };

    const handleSharedWithClientChange = (field: { value: string; label: string }) => {
        const isSharedWithClient = field.value === '1';

        if (
            !isSharedWithClient &&
            (formikBag.values.riskStatus === RiskStatus.ACCEPTED ||
                formikBag.values.riskStatus === RiskStatus.MITIGATION_PENDING_MITIGATION ||
                formikBag.values.riskStatus === RiskStatus.MITIGATION_PENDING_INTERNAL_APPROVAL ||
                formikBag.values.riskStatus === RiskStatus.OPEN_AND_PENDING_REVIEW)
        ) {
            setShowWarningPopup(true);
        } else {
            formikBag.setFieldValue('sharedWithClient', isSharedWithClient);
        }
    };

    const handleLikelihoodChange = (field: { value: string; label: string }) => {
        const selectedValue = props.likelihoods.find((i) => i.key === field.value);
        formikBag.setFieldValue('likelihoodId', field.value);
        formikBag.setFieldValue('likelihood', selectedValue.label);
        setRiskLevel(getRiskLevel(formikBag.values.impactId, field.value));
    };

    const handleImpactChange = (field: { value: string; label: string }) => {
        const selectedValue = props.impacts.find((i) => i.key === field.value);
        formikBag.setFieldValue('impactId', field.value);
        formikBag.setFieldValue('impact', selectedValue.label);
        setRiskLevel(getRiskLevel(field.value, formikBag.values.likelihoodId));
    };

    const handleCurrencyTypeChange = (field: { value: string; label: string }) => {
        formikBag.setFieldValue('cost.currencyIsoCode', field.value);
        if (field.value) {
            let c = formikBag.values.cost;
            if (!(c && c.amount)) {
                formikBag.setFieldValue('cost.amount', 0);
            }
        }
    };

    const handleRemedyCostChange = (event: any) => {
        if (!event.target.value) {
            let c = formikBag.values.cost;

            if (c && c.currencyIsoCode) {
                formikBag.setFieldValue('cost.amount', 0);
            } else {
                formikBag.setFieldValue('cost.amount', null);
            }

            return;
        }

        const amount = event.target.value;
        formikBag.setFieldValue('cost.amount', amount);
    };

    const handleAssigneeChange = (value: string) => {
        formikBag.setFieldValue('assigneeId', value);
    };

    const getRiskLevel = (impactId: string, likelihoodId: string) => {
        const impactScore = parseInt(impactId, 10) || 0;
        const likelihoodScore = parseInt(likelihoodId, 10) || 0;
        const riskScore = impactScore * likelihoodScore;

        switch (true) {
            case riskScore > 15:
                return 'High';
            case riskScore > 7 && riskScore < 16:
                return 'Medium';
            case riskScore > 0:
                return 'Low';
            default:
                return null;
        }
    };

    const getRiskLevelMarkup = () => {
        return riskLevel ? (
            <mark id="riskLevel" className={`${riskLevel.toLowerCase()}`}>
                {translate(
                    'RiskDetailsPage.RiskLevel.value.',
                    riskLevel[0].toUpperCase() + riskLevel.slice(1).toLowerCase()
                )}
            </mark>
        ) : null;
    };

    const getLookupSitePeople = () => {
        if (props.isMacro) {
            return siteAndPrivilegePersons &&
                siteAndPrivilegePersons.map(x => ({
                    value: x.id,
                    label: x.fullName,
                }));
        } else {
            return props.lookupSitePeopleRoles.map(lookup => {
                const roles = lookup.roleNames ? lookup.roleNames.join(', ') : 'N/A';
                if (roles) {
                    const label = `${lookup.label} (${roles})`;
                    return { label, value: lookup.id };
                } else {
                    return { label: lookup.label, value: lookup.id };
                }
            });
        }
    };

    const selectAssignee = () => {
        const lookupPeople = getLookupSitePeople();

        if (lookupPeople &&
            !lookupPeople.some(option => option.value === formikBag.values.assigneeId) &&
            formikBag.values.assignee &&
            formikBag.values.assigneeId
        ) {
            return {
                label: formikBag.values.assignee && formikBag.values.assignee.displayLabel,
                value: formikBag.values.assignee && formikBag.values.assignee.id,
            };
        }
    };

    const description =
        formikBag.values.description && isReadOnly
            ? `${translate(
                'RiskDetailsTab.Label.Elogbooks.',
                formikBag.values.description.split(':')[0]
            )} : ${formikBag.values.description.split(':').slice(1).join('')}`
            : formikBag.values.description;

    const disableFieldForAutoGeneratedRisk =
        !checkPermission(Privilege.RiskAutoFieldUpdate, permissions) &&
        (!!formikBag.values.masterProcessStepId ||
            !!formikBag.values.riskProfileQuestionId ||
            !!isCermAssesment);

    const setAssetFieldValues = (values: IAssetModel) => {
        formikBag.setFieldValue('asset.manufacturer', values.manufacturer);
        formikBag.setFieldValue('asset.model', values.model);
        formikBag.setFieldValue('asset.serial', values.serial);
        formikBag.setFieldValue('asset.cmmsReference', values.cmmsReference);
        formikBag.setFieldValue('asset.name', values.name);
        formikBag.setFieldValue('asset.age', values.age);
    }

    return (
        <>
            <div className="columns">
                <div className="column is-8">
                    <TextField
                        id="title"
                        label={translate('RiskDetailsPage.label.title')}
                        value={formikBag.values.title && isReadOnly
                            ? `${translate('RiskDetailsTab.Label.Elogbooks.', formikBag.values.title.split('-')[0])} - ${formikBag.values.title.split("-").slice(1).join("-")}`
                            : formikBag.values.title}
                        handleChange={formikBag.handleChange}
                        error={formikBag.errors.title}
                        setVal={formikBag.setFieldValue}
                        isDisabled={isReadOnly}
                    />
                </div>

                <div className="column is-4">
                    <PopoutSelectField
                        id="riskAssigneeId"
                        label={translate('RiskDetailsPage.label.Assignedto')}
                        value={formikBag.values.assigneeId ?? formikBag.values.assignee?.id}
                        error={formikBag.errors.assigneeId}
                        options={getLookupSitePeople()}
                        secondaryValue={selectAssignee()}
                        handleChange={handleAssigneeChange}
                        handleBlur={formikBag.handleBlur}
                        isDisabled={
                            isReadOnly ||
                            siteAndPrivilegePersonsIsLoading ||
                            (!riskId && props.isMacro)
                        }
                    />
                    {selectAssignee() && (
                        <p className="has-text-warning">
                            {translate('Globals.Warning.InactiveUser')}
                        </p>
                    )}
                </div>
            </div>

            <div className="columns">
                <div className="column is-8">
                    <TextAreaField
                        id="description"
                        label={translate('RiskDetailsPage.label.Description')}
                        placeholder={translate('RiskDetailsPage.placeholder.Description')}
                        error={formikBag.errors.description}
                        value={description}
                        handleChange={formikBag.handleChange}
                        setVal={formikBag.setFieldValue}
                        isDisabled={isReadOnly}
                    />
                </div>

                <div className="column is-4">
                    <div className="columns">
                        <div className="column is-6">
                            {checkSiteHasFeature(
                                formikBag.values.siteId,
                                SiteFeatures.ClientRisks,
                                lookupSiteFeatures
                            ) && (
                                    <SelectField
                                        id="sharedWithClient"
                                        label={translate('RiskDetailsTab.label.sharedWithClient')}
                                        error={formikBag.errors.sharedWithClient}
                                        value={{
                                            value: formikBag.values.sharedWithClient ? '1' : '0',
                                            label: formikBag.values.sharedWithClient
                                                ? translate('RiskDetailsTab.sharedWithClient.dropdown.Clientfacing')
                                                : translate('RiskDetailsTab.sharedWithClient.dropdown.Internalonly'),
                                        }}
                                        options={shareTypes.map((c) => ({
                                            value: c.key,
                                            label: translate(
                                                `RiskDetailsTab.sharedWithClient.dropdown.${c.value.replace(
                                                    /\s/g,
                                                    ''
                                                )}`
                                            ),
                                        }))}
                                        handleChange={handleSharedWithClientChange}
                                        handleBlur={formikBag.handleBlur}
                                        isDisabled={(!checkPermission(
                                            Privilege.RiskClientFacingUpdate,
                                            permissions
                                        ) || !isRiskActiveAndNotClose()) || isReadOnly || isCermAssesment || props.isMacro ||
                                            (formikBag?.values?.sharedWithClient === false &&
                                                formikBag?.values?.mitigationPlans?.length > 0 &&
                                                formikBag?.values?.mitigationPlans?.some(
                                                    x => x.status !== RiskMitigationPlanStatus.Completed &&
                                                        x.actions?.filter(a => a.isActive)?.length === 0
                                                ))}
                                    />
                                )
                            }
                        </div>
                        <div className="column is-6">
                            {allowReviewDateToBeEditable && (
                                <DatePickerField
                                    id="reviewDate"
                                    label={translate('RiskDetailsPage.label.Reviewdate')}
                                    value={formikBag.values.reviewDate}
                                    error={formikBag.errors.reviewDate}
                                    handleChange={handleDateChange}
                                    handleBlur={formikBag.handleBlur}
                                    minDate={moment(new Date()).add(1, 'day').toDate()}
                                />
                            )}
                            {!allowReviewDateToBeEditable && (
                                <TextField
                                    id="reviewDateReadonly"
                                    label={translate('RiskDetailsPage.label.Reviewdate')}
                                    value={formatDate(formikBag.values.reviewDate)}
                                    readOnly={true}
                                />
                            )}
                        </div>
                    </div>

                    <div className="columns">
                        <div className="column is-full">
                            <TextField
                                id="refNo"
                                label={translate('RiskDetailsTab.label.RefNo')}
                                value={formikBag.values.refNo}
                                handleChange={formikBag.handleChange}
                                setVal={formikBag.setFieldValue}
                                isDisabled={isReadOnly || isCermAssesment}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="columns">
                <div className="column is-4">
                    <SelectField
                        id="riskCategoryId"
                        label={translate('RiskDetailsPage.label.Category')}
                        error={formikBag.errors.riskCategory}
                        value={{
                            value: formikBag.values.riskCategory,
                            label:
                                (formikBag.values.riskCategory == null ||
                                    formikBag.values.riskCategory.length === 0) === true
                                    ? formikBag.values.riskCategory
                                    : translate(
                                        'RiskDetailsPage.Category.dropdown.',
                                        formikBag.values.riskCategory
                                    ),
                        }}
                        options={riskCategories.map((c) => ({
                            value: c.key,
                            label: translate('RiskDetailsPage.Category.dropdown.', c.value),
                        }))}
                        handleChange={handleCategoryChange}
                        handleBlur={formikBag.handleBlur}
                        isDisabled={
                            isReadOnly || disableFieldForAutoGeneratedRisk
                        }
                    />
                </div>

                <div className="column is-4">
                    <SelectField
                        id="riskSubCategoryId"
                        label={translate('RiskDetailsPage.label.SubCategory')}
                        error={formikBag.errors.riskSubCategory}
                        value={{
                            value: formikBag.values.riskSubCategory,
                            label: translate('RiskDetailsPage.SubCategory.dropdown.', formikBag.values.riskSubCategory),
                        }}
                        options={riskSubCategories.map(c => ({
                            value: c.key,
                            label: translate('RiskDetailsPage.SubCategory.dropdown.', c.value),
                        }))}
                        handleChange={handleSubCategoryChange}
                        handleBlur={formikBag.handleBlur}
                        isDisabled={
                            !formikBag.values.riskCategory ||
                            isReadOnly ||
                            disableFieldForAutoGeneratedRisk
                        }
                    />
                </div>

                <div className="column is-4">
                    <TextField
                        id="changeRequestNo"
                        label={translate('RiskDetailsTab.label.ChangeRequest')}
                        value={formikBag.values.changeRequestNo}
                        handleChange={formikBag.handleChange}
                        setVal={formikBag.setFieldValue}
                        isDisabled={isReadOnly || isCermAssesment}
                    />
                </div>
            </div>

            <div className="columns">
                <div className="column is-2">
                    <SelectField
                        id="likelihoodId"
                        label={translate('RiskDetailsPage.label.Likelihood')}
                        error={formikBag.errors.likelihood}
                        value={{
                            value: formikBag.values.likelihoodId,
                            label:
                                (formikBag.values.likelihood == null ||
                                    formikBag.values.likelihood.length === 0) === true
                                    ? formikBag.values.likelihood
                                    : translate(
                                        'RiskDetailsPage.Likelihood.dropdown.',
                                        formikBag.values.likelihood
                                    ),
                        }}
                        options={props.likelihoods.map((c) => ({
                            value: c.key,
                            label: translate('RiskDetailsPage.Likelihood.dropdown.', c.value),
                        }))}
                        handleChange={handleLikelihoodChange}
                        handleBlur={formikBag.handleBlur}
                        isDisabled={
                            isReadOnly || disableFieldForAutoGeneratedRisk
                        }
                    />
                </div>

                <div className="column is-2">
                    <SelectField
                        id="impactId"
                        label={translate('RiskDetailsPage.label.impact')}
                        error={formikBag.errors.impact}
                        value={{
                            value: formikBag.values.impactId,
                            label:
                                (formikBag.values.impact == null ||
                                    formikBag.values.impact.length === 0) === true
                                    ? formikBag.values.impact
                                    : translate(
                                        'RiskDetailsPage.Impact.dropdown.',
                                        formikBag.values.impact
                                    ),
                        }}
                        options={props.impacts.map((c) => ({
                            value: c.key,
                            label: translate('RiskDetailsPage.Impact.dropdown.', c.value),
                        }))}
                        handleChange={handleImpactChange}
                        handleBlur={formikBag.handleBlur}
                        isDisabled={
                            isReadOnly || disableFieldForAutoGeneratedRisk
                        }
                    />
                </div>

                <div className="column is-2">
                    <div className="field field-risk-level">
                        <div className="label">{translate('RiskDetailsPage.label.Risklevel')}</div>
                        {getRiskLevelMarkup()}
                    </div>
                </div>

                <div className="column is-2">
                    {(!isReadOnly
                        ? <ReadOnlyField
                            label={translate('RiskDetailsPage.label.RiskSource')}
                            id="origin"
                            value={formikBag.values.origin}
                        />
                        : <div className="field field-risk-level">
                            <div className="label">
                                {translate('RiskDetailsPage.label.RiskSource')}
                            </div>
                            <a href={`${config.REACT_APP_ELOGBOOKS_URL}/AD/building/overview?customerId=${formikBag.values.customerId}
                                                &contractId=${formikBag.values.contractId}&buildingId=${formikBag.values.buildingId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <mark id="actionSource" className="source">
                                    {translate('ActionDetailsPage.label.ViewSource')}
                                </mark>
                            </a>
                        </div>
                    )}
                </div>

                <div className="column is-4">
                    <ReadOnlyField
                        id="riskStatusId"
                        label={translate('RiskDetailsPage.label.Status')}
                        value={
                            (formikBag.values.impact == null ||
                                formikBag.values.impact.length === 0) === true
                                ? formikBag.values.impact
                                : translate(
                                    'RiskDetailsPage.Status.dropdown.',
                                    formikBag.values.riskStatus
                                )
                        }
                    />
                </div>
            </div>

            <div className="columns">
                <div className="column is-4">
                    <SelectField
                        id="costCurrencyIsoCode"
                        label={translate('RiskDetailsPage.label.Currencytype')}
                        error={formikBag.errors.cost && formikBag.errors.cost.currencyIsoCode}
                        value={{
                            value: formikBag.values.cost && formikBag.values.cost.currencyIsoCode,
                            label: formikBag.values.cost && formikBag.values.cost.currencyIsoCode,
                        }}
                        options={currencies.map((c) => ({
                            value: c.key,
                            label: c.value,
                        }))}
                        handleChange={handleCurrencyTypeChange}
                        handleBlur={formikBag.handleBlur}
                        isDisabled={isReadOnly}
                    />
                </div>
                <div className="column is-4">
                    <NumberField
                        id="amountCost"
                        label={translate('RiskDetailsPage.label.Estimatedcosttoremedy')}
                        error={formikBag.errors.cost && formikBag.errors.cost.amount}
                        value={formikBag.values.cost && formikBag.values.cost.amount}
                        handleChange={handleRemedyCostChange}
                        allowNegative={false}
                        thousandSeparator={false}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        handleBlur={formikBag.handleBlur}
                        isDisabled={isReadOnly}
                    />
                </div>
                <div className="column is-4">
                    <Field isHorizontal={false}>
                        <label className="label">&nbsp;</label>
                        <Control>
                            {checkSiteHasFeature(
                                formikBag.values.siteId,
                                SiteFeatures.ClientRisks,
                                lookupSiteFeatures
                            ) &&
                                !!users && (
                                    <div className="columns">
                                        <div className="column is-full">
                                            <Button
                                                id="viewApproverButton"
                                                onClick={() => setIsViewApproversDialogVisible(true)}
                                                type="button"
                                                className="button is-link is-full"
                                            >
                                                {translate('RiskDetailsPage.label.ViewApprovers')}&nbsp;
                                                <MaterialIcons
                                                    type={MaterialIconType.OpenInNew}
                                                    color={MaterialIconColor.white}
                                                />
                                            </Button>

                                            <ConfirmDialog
                                                id="viewApproverDialogue"
                                                isVisible={isViewApproversDialogVisible}
                                                onConfirm={onViewApproversDialogueClose}
                                                onClose={onViewApproversDialogueClose}
                                                onOutsideDialogClick={onViewApproversDialogueClose}
                                                buttonCancelHide={true}
                                                buttonConfirmText={translate('RiskRegisterPage.RiskRegisterToolbar.Title.Close')}
                                                title={translate('RiskDetailsTab.label.Approvals')}
                                                message={
                                                    <RiskApprovalsTab
                                                        formikBag={formikBag}
                                                        users={users}
                                                        isLoading={false}
                                                        isReadOnly={isReadOnly}
                                                        canEditInExpertReview={canEditInExpertReview}
                                                        riskAddApproverPrivilege={checkPermission(
                                                            Privilege.RiskAddApprover,
                                                            permissions
                                                        )}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                        </Control>
                    </Field>
                </div>
            </div>

            {formikBag?.values?.id && (
                <Comments
                    id={formikBag.values.id}
                    entity={Entity.Risk}
                    allowNewComment={isRiskActiveAndNotClose()}
                    forceSave={commentSave}
                    overrideSiteId={formikBag.values.shard}
                />
            )}

            {formikBag?.values?.mitigationPlans?.map(mitigationPlan => (
                <MitigationPlanForm
                    key={'mp_' + mitigationPlan.id}
                    formikBag={formikBag}
                    mitigationPlan={mitigationPlan}
                    isReadOnly={isReadOnly}
                    likelihoods={props.likelihoods}
                    impacts={props.impacts}
                    currencies={currencies}
                    canEditInExpertReview={canEditInExpertReview}
                    isMitigationValid={isMitigationValid}
                    setIsMitigationValid={setIsMitigationValid}
                    isActionEditable={isActionEditable}
                    setIsActionEditable={setIsActionEditable}
                    isMacro={props.isMacro}
                />
            ))}
            {!isCermAssesment &&
                config.REACT_APP_FEATURE_FLAG_ASSET_INTEGRATION === 'true' && (
                    <div className="columns">
                        <div className="column column is-12">
                            <Asset
                                showLabel={true}
                                isManadatory={false}
                                setValue={setAssetFieldValues}
                                isValid={() => { }}
                                selectedAsset={{
                                    siteId: formikBag.values?.asset?.siteId,
                                    manufacturer: formikBag?.values?.asset?.manufacturer,
                                    model: formikBag.values?.asset?.model,
                                    serial: formikBag.values?.asset?.serial,
                                    cmmsReference: formikBag.values?.asset?.cmmsReference,
                                    name: formikBag.values?.asset?.name,
                                    age: formikBag.values?.asset?.age,
                                }}
                                isDisabled={isReadOnly}
                            />
                        </div >
                    </div>
                )}
        </>
    );
};