import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { IRiskProfileMasterQuestionnaire } from 'models';
import { Loader } from 'components/loader';
import { RiskProfileMasterQuestions } from 'components/risk-profile-questions/risk-profile-master-questions';
import { IRiskProfileCategory } from 'models/risk-profile-questions/risk-profile-lookups';
import Page from 'components/v2/page/Page';
import { useParams } from 'react-router';
import useTranslate from 'translations/translation-utils';
import { IPerson } from 'models/person';
import { Form, FormikProps, Formik } from 'formik';
import { Control, Field } from 'components/form';
import { BulmaSize } from 'enums/BulmaSize';
import { Icon, IconType } from 'components/icon';
import { IOperatingPlatformProcess } from 'models/site-profile';
import { onRouteChange } from 'actions/app-actions';
import { RouteUrlRiskProfileOperatingPlatform } from 'routes/upsert-site/routes';
import { Select as QuantumSelect } from 'components/select';
import './risk-profile-master-questionnaire.scss'
interface IRouteParams {
    operatingPlatform?: string;
}

export interface IRiskProfileMasterQuestionnaireProps extends RouteComponentProps<IRouteParams> {
    riskProfileMasterQuestionnaire: IRiskProfileMasterQuestionnaire | null;
    riskProfileCategories: IRiskProfileCategory[];
    isLoading: boolean;
    loadMasterQuestionnaire: () => void;
    loadRiskProfileOverview: (operatingPlatform: string) => void;
    saveMasterQuestionnaire: (questionnaire: IRiskProfileMasterQuestionnaire) => void;
    createMasterQuestionnaire: (questionnaire: IRiskProfileMasterQuestionnaire) => void;
    person: IPerson;
    onSubmit: (request: IOperatingPlatformProcess) => void;
    onLoadPerson?: (personId: string) => void;
    userId: string;
}

interface MyFormValues {
    operatingPlatformDropdown: string;
}

export const RiskProfileMasterQuestionnairePage: React.FC<IRiskProfileMasterQuestionnaireProps> = (props) => {
    const params: IRouteParams = useParams();
    const translate = useTranslate();
    React.useEffect(() => {
        if (props.userId) {
            props.onLoadPerson(props.userId);
        }
        props.loadRiskProfileOverview(params.operatingPlatform);
    }, [props.match.params.operatingPlatform]);

    const getCustomTitle = (operatingPlatform: string) => {
        return `${translate('RiskProfileMasterQuestionnaire.Page.CusotmTitle')} - ${operatingPlatform}`
    };

    const initialValues: MyFormValues = {
        operatingPlatformDropdown: props.match.params.operatingPlatform,
    };

    const onSubmit = (upsertRoleRequest: IOperatingPlatformProcess) => {
        props.onSubmit(upsertRoleRequest);
    };

    let formikBag: FormikProps<IOperatingPlatformProcess>;

    const selectectedOperatingPlatform = (e: any) => {
        const value = e.target.value;
        formikBag.setFieldValue('operatingPlatformDropdown', value);
        onRouteChange(`${RouteUrlRiskProfileOperatingPlatform(value)}`);
    };

    const renderForm = (formik: FormikProps<IOperatingPlatformProcess>) => {
        formikBag = formik;
        if (formikBag.values.operatingPlatformDropdown !== props.match.params.operatingPlatform) {
            const operatingPlatform = props.match.params.operatingPlatform;
            if (operatingPlatform) {
                formikBag.setFieldValue('operatingPlatformDropdown', operatingPlatform);
            }
        }
        return (
            <Form id="riskProfileQuestionnaireDropdown">
                <Field
                    isHorizontal={true}
                    htmlFor={translate(
                        'RiskProfileQuestionnaire.Views.Dropdown.Title'
                    )}
                    label={translate(
                        'RiskProfileQuestionnaire.Views.Dropdown.Title'
                    )}
                    labelSize={BulmaSize.Medium}
                >
                    <Field>
                        <Control>
                            <QuantumSelect
                                label={translate(
                                    'RiskProfileQuestionnaire.Views.Dropdown.Title'
                                )}
                                id="operatingPlatformdropdown"
                                name="operatingPlatformDropdown"
                                aria-required="true"
                                options={props.person.accessRights.operatingPlatforms
                                    .map(x => ({
                                        key: x,
                                        value: x,
                                        label: x,
                                    }))
                                    .sort((a, b) => a.label.localeCompare(b.label))}
                                className="operatingPlatforms-dropdown"
                                value={formikBag.values.operatingPlatformDropdown}
                                onBlur={formikBag.handleBlur}
                                onChange={selectectedOperatingPlatform}
                            />
                        </Control>
                    </Field>
                </Field>
            </Form>
        );
    };

    return (
        <Page title={getCustomTitle(props.match.params.operatingPlatform)} isAdminPage={true}>
            {props.riskProfileMasterQuestionnaire ?
                <Loader loading={props.isLoading}>
                    {props.person.accessRights.operatingPlatforms.length > 1 ? (
                        <>
                            <div className="risk-profile-questionaire-dropdown">
                                <Formik<Partial<IOperatingPlatformProcess>>
                                    initialValues={initialValues}
                                    onSubmit={onSubmit}
                                    render={renderForm}
                                />
                                <div className="risk-profile-questionaire-dropdown-info-box">
                                    <div className="risk-profile-questionaire-dropdown-info">
                                        <Icon type={IconType.InfoCircle} />
                                    </div>
                                    <div className="risk-profile-questionaire-dropdown-info-text">
                                        {translate(
                                            'RiskProfileQuestionnaire.Views.Dropdown.Info'
                                        )}
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </>
                    ) : null}
                    <RiskProfileMasterQuestions
                        riskProfileMasterQuestionnaire={props.riskProfileMasterQuestionnaire}
                        onSaveMasterQuestionnaire={props.saveMasterQuestionnaire}
                        categories={props.riskProfileCategories}
                        isLoading={props.isLoading}
                        onCreateMasterQuestionnaire={props.createMasterQuestionnaire}
                    />
                </Loader> : ''
            }
        </Page>
    );

}