import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { IKeyValue } from 'models/key-value';
import { SiteRolesActions } from '../../actions/actions-v2/site-roles-actions-v2';

const siteRoleControllerName = 'siterole';

const getSiteUserRoles = {
    action: SiteRolesActions.LOAD_SITEROLES,
    actionFulfilled: SiteRolesActions.LOAD_SITEROLES_FULFILLED,
    actionRejected: SiteRolesActions.LOAD_SITEROLES_REJECTED,
} as IActionType;

const loadSiteRolesEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getSiteUserRoles).get<IKeyValue<string>>(
        `/api/${siteRoleControllerName}`
    );

export const siteRolesEpicsV2 = combineEpics(loadSiteRolesEpic);
