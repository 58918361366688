import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { loadMasterProcessSteps } from 'actions/actions-v2/master-process-step-action-v2';
import {
    loadSnapshotsFullResults,
    exportSnapshotsComparison,
    loadCermSnapshotsOverviewOnly
} from 'actions/actions-v2/cerm-assessment-action-v3';
import { CermAssessmentComparisonPage } from './cerm-assessment-comparison-page';
import { onSiteChange } from 'actions/app-actions';
import { IOperatingPlatform } from 'models/operating-platform-model';

const getIsLoading = (state: IRootState) =>
    state.masterProcessStepState.isLoading || state.cermAssessmentResultState.isLoading;
const getPillars = (state: IRootState) => state.app.lookupPillars;
const getMasterProcessSteps = (state: IRootState) =>
    state.masterProcessStepState.masterProcessSteps;
const getSnapshots = (state: IRootState) => state.cermAssessmentResultState.snapshots;
const getSnapshotsFullResult = (state: IRootState) => state.cermAssessmentResultState.snapshotsFullResult;
const getIsExporting = (state: IRootState) => state.cermAssessmentResultState.isExporting;
const getLookupSites = (state: IRootState) => state.app.lookupSites;
const getOperatingPlatforms = (state: IRootState): IOperatingPlatform[] => state.operatingPlatformState.operatingPlatforms;

const mapStateToProps = createSelector(
    getIsLoading,
    getPillars,
    getMasterProcessSteps,
    getSnapshots,
    getIsExporting,
    getLookupSites,
    getOperatingPlatforms,
    getSnapshotsFullResult,
    (isLoading, pillars, masterProcessSteps, snapshots, isExporting, lookupSites, allOperatingPlatforms, snapshotsFullResult) => ({
        isLoading,
        pillars,
        masterProcessSteps,
        snapshots,
        isExporting,
        lookupSites,
        allOperatingPlatforms,
        snapshotsFullResult
    })
);

const mapDispatchToProps = {
    onSiteChange,
    loadMasterProcessSteps,
    loadSnapshotsFullResults,
    exportSnapshotsComparison,
    loadCermSnapshotsOverviewOnly
};

export const CermAssessmentComparisonPageContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CermAssessmentComparisonPage));
