import React from 'react';
import { IconToolbar, IToolbarIconItem, ToolbarIconType } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';
import { IOperatingPlatform } from 'models/operating-platform-model';

interface IProps {
    isLoading: boolean;
    isExporting: boolean;
    hasPermissionToExportSitesOverview: boolean;
    isFilterExists?: boolean;
    onShowFilters: () => void;
    onExport: () => void;
    onClearFilters: () => void;
    onCermDownload: (operatingPlatform: string) => void;
    OpsList: IOperatingPlatform[];
}

export const CermGlobalOverviewPageToolbar: React.FC<IProps> = ({
    isLoading,
    isExporting,
    hasPermissionToExportSitesOverview,
    isFilterExists,
    onShowFilters,
    onClearFilters,
    onExport,
    onCermDownload,
    OpsList,
}) => {
    const translate = useTranslate();

    const opExportBtns = () => {
        let opExportoptions = [];
        let exportGlobalOverview = [
            {
                id: 'export-cerm-global-overview',
                title: 'Global Overview',
                type: ToolbarIconType.excel,
                onClick: onExport,
                isDisabled: isExporting,
            }]
        opExportoptions =
            hasPermissionToExportSitesOverview &&
            OpsList &&
            OpsList.map(op => {
                let opId = op.id;
                return {
                    id: `${'export-'}${op.shortForm}${'-site-overview'}`,
                    title: `${op.shortForm.toUpperCase()} ${translate(
                        'GlobalOverview.Toolbar.SitesOverview'
                    )}`,
                    type: ToolbarIconType.excel,
                    onClick: () => { onCermDownload(opId) },
                    isDisabled: isExporting,
                    isConfirmRequired: true,
                    confirmationMessage: translate('CermGlobalOverview.Toolbar.Message'),
                };
            });
        const exportedOptions = exportGlobalOverview.concat(opExportoptions);
        return exportedOptions;
    };

    const getToolbarItems = (): IToolbarIconItem[] => {
        return [
            {
                id: 'export-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.Export'),
                type: ToolbarIconType.download,
                isDisabled: isExporting,
                children: opExportBtns(),
            },
            {
                id: 'clear-all-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.ClearFilters'),
                type: isFilterExists ? ToolbarIconType.filterApplied : ToolbarIconType.clearFilter,
                isDisabled: isLoading,
                onClick: onClearFilters,
            },
            {
                id: 'show-filters-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.ShowFilters'),
                type: ToolbarIconType.filter,
                isDisabled: isLoading,
                onClick: onShowFilters,
            },
        ];
    };

    return <IconToolbar items={getToolbarItems()} />;
};
