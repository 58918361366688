export const cermAssesmentJa = `
<div>
<p>下のリンクをクリックしてご覧ください:</p>
<p><a href="https://confluence.cbre.com/pages/viewpage.action?pageId=109992258" target= "_blank">CERM 評価プロセス</a><p>
</div>
<br />
<div>
<p><a href="https://quantum.cbre.com/issues" target= "_blank">問題を報告する</a></p>
<p><a href="https://quantum.cbre.com/requests" target= "_blank">リクエストを出す</a></p>
</div>`;

export const hermAssesmentJa = `
<div>
<p>下のリンクをクリックしてご覧ください:</p>
<p><a href="https://confluence.cbre.com/pages/viewpage.action?pageId=109992258" target= "_blank">HERM 評価プロセス</a><p>
</div>
<br />
<div>
<p><a href="https://quantum.cbre.com/issues" target= "_blank">問題を報告する</a></p>
<p><a href="https://quantum.cbre.com/requests" target= "_blank">リクエストを出す</a></p>
</div>`;
