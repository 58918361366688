import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { GlobalMockDrillsActions } from 'actions/actions-v2/global-mock-drills-actions';
import { IGlobalMockDrill } from 'models/mock-drills';

const controllerName = 'GlobalMockDrills';
const apiPath = `/api/${controllerName}`;
const redirectUrl = '/GlobalMockDrills';

const getListActions = {
    action: GlobalMockDrillsActions.LOAD_LIST,
    actionFulfilled: GlobalMockDrillsActions.LOAD_LIST_FULFILLED,
    actionRejected: GlobalMockDrillsActions.LOAD_LIST_REJECTED
} as IActionType;

const getActions = {
    action: GlobalMockDrillsActions.LOAD,
    actionFulfilled: GlobalMockDrillsActions.LOAD_FULFILLED,
    actionRejected: GlobalMockDrillsActions.LOAD_REJECTED
} as IActionType;

const createActions = {
    action: GlobalMockDrillsActions.CREATE,
    actionFulfilled: GlobalMockDrillsActions.CREATE_FULFILLED,
    actionRejected: GlobalMockDrillsActions.CREATE_REJECTED
} as IActionType;

const updateActions = {
    action: GlobalMockDrillsActions.UPDATE,
    actionFulfilled: GlobalMockDrillsActions.UPDATE_FULFILLED,
    actionRejected: GlobalMockDrillsActions.UPDATE_REJECTED
} as IActionType;

const deleteActions = {
    action: GlobalMockDrillsActions.DELETE,
    actionFulfilled: GlobalMockDrillsActions.DELETE_FULFILLED,
    actionRejected: GlobalMockDrillsActions.DELETE_REJECTED,
} as IActionType;

const exportActions = {
    action: GlobalMockDrillsActions.EXPORT,
    actionFulfilled: GlobalMockDrillsActions.EXPORT_FULFILLED,
    actionRejected: GlobalMockDrillsActions.EXPORT_REJECTED
} as IActionType;

const loadGlobalMockDrillsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getListActions).get<IGlobalMockDrill[]>(apiPath);

const loadGlobalMockDrillEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getActions).getById<IGlobalMockDrill>(apiPath);

const createGlobalMockDrillsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createActions, redirectUrl).post<IGlobalMockDrill[]>(apiPath);

const updateGlobalMockDrillsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateActions, redirectUrl).put<IGlobalMockDrill[]>(apiPath);

const deleteGlobalMockDrillsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, deleteActions, redirectUrl).delete<IGlobalMockDrill>(apiPath);

const exportGlobalMockDrillsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, exportActions).exportByQueryModel(`${apiPath}/export`);

export const globalMockDrillsEpicsV2 = combineEpics(
    loadGlobalMockDrillsEpic,
    loadGlobalMockDrillEpic,
    createGlobalMockDrillsEpic,
    updateGlobalMockDrillsEpic,
    deleteGlobalMockDrillsEpic,
    exportGlobalMockDrillsEpic
);
