import classnames from 'classnames';
import * as React from 'react';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconColor, MaterialIconSize, MaterialIconType } from 'routes/material-icon/material-icon-type';

import './timeline-header.scss';

export interface IProps {
    expanded: boolean;
    centered?: boolean;
    onToggleExpanded?: () => void;
    disabled?: boolean;
    onClick?: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
    onToggleButtonClick?: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
}

const getClassName = (props: IProps) => classnames(
    'TimelineHeader',
    {
        'TimelineHeader--centered': props.centered,
        'TimelineHeader--expanded': props.expanded
    }
);

export const TimelineHeader: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const onClick: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement> = (e) => {
        if (props.onToggleExpanded) {
            e.stopPropagation();
            props.onToggleExpanded();
        }
        if (props.onClick) {
            props.onClick(e);
        }
    };

    const onToggleButtonClick: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement> = (e) => {
        if (props.onToggleExpanded) {
            e.stopPropagation();
            props.onToggleExpanded();
        }
        if (props.onToggleButtonClick) {
            props.onToggleButtonClick(e);
        } else if (props.onClick) {
            props.onClick(e);
        }
    };

    return (
        <div className={getClassName(props)} role="button" onClick={onClick}>
            {props.children}
            <div
                onClick={onToggleButtonClick}
                className={
                    classnames(
                        'TimelineHeader__toggle',
                        {
                            disabled: props.disabled
                        }
                    )}
            >
                < MaterialIcons type={props.expanded ? MaterialIconType.Minus : MaterialIconType.Plus} color={MaterialIconColor.white} size={MaterialIconSize.xs} />
            </div>
        </div>
    );
};

TimelineHeader.defaultProps = {
    expanded: false,
    centered: false
};
