export type EmeraldInputTypeType =
  | 'color'
  | 'date'
  | 'month'
  | 'datetime-local'
  | 'email'
  | 'number'
  | 'password'
  | 'search'
  | 'tel'
  | 'text'
  | 'textarea'
  | 'number'
  | 'time'
  | 'url'
  | 'week';

export enum EmeraldInputType {
  Date = 'date',
  Month = 'month',
  LocalTime = 'datetime-local',
  Week = 'week',
  Time = 'time',
  Color = 'color',
  Email = 'email',
  Number = 'number',
  Password = 'password',
  Search = 'search',
  PhoneNumber = 'tel',
  Text = 'text',
  TextArea = 'textarea',
  Url = 'url',
}
