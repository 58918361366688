import { combineReducers } from 'redux';

import { SiteRelationshipsActions, SiteRelationshipsActionTypes } from '../../actions/site-relationships-actions';
import { IBuilding } from '../../models/building';

export interface IManageBuildingsState {
    buildings: IBuilding[];
    isLoading: boolean;
    idsBeingRemoved: string[];
}

export const INITIAL_STATE: IManageBuildingsState = {
    buildings: [],
    isLoading: false,
    idsBeingRemoved: []
};

const buildings = (
    state: IManageBuildingsState['buildings'] = INITIAL_STATE.buildings,
    action: SiteRelationshipsActionTypes
) => {
    switch (action.type) {
        case SiteRelationshipsActions.LOAD_LINKED_BUILDINGS_FULFILLED:
            return action.payload.buildings;

        case SiteRelationshipsActions.CHANGE_SITE_SEARCH_VALUE:
        case SiteRelationshipsActions.LOAD_LINKED_BUILDINGS_CANCELLED:
        case SiteRelationshipsActions.LOAD_BUILDING_SEARCH_RESULTS_REJECTED:
            return [];

        default:
            return state;
    }
};

const isLoading = (
    state: IManageBuildingsState['isLoading'] = INITIAL_STATE.isLoading,
    action: SiteRelationshipsActionTypes
) => {
    switch (action.type) {
        case SiteRelationshipsActions.LOAD_LINKED_BUILDINGS:
            return true;

        case SiteRelationshipsActions.CHANGE_SITE_SEARCH_VALUE:
        case SiteRelationshipsActions.LOAD_LINKED_BUILDINGS_CANCELLED:
        case SiteRelationshipsActions.LOAD_LINKED_BUILDINGS_FULFILLED:
        case SiteRelationshipsActions.LOAD_BUILDING_SEARCH_RESULTS_REJECTED:
            return false;

        default:
            return state;
    }
};

const idsBeingRemoved = (
    state: IManageBuildingsState['idsBeingRemoved'] = INITIAL_STATE.idsBeingRemoved,
    action: SiteRelationshipsActionTypes
) => {
    switch (action.type) {
        case SiteRelationshipsActions.UNLINK_BUILDING:
            return [
                ...state,
                action.payload.building.id
            ];

        case SiteRelationshipsActions.UNLINK_BUILDING_CANCELLED:
        case SiteRelationshipsActions.UNLINK_BUILDING_FULFILLED:
        case SiteRelationshipsActions.UNLINK_BUILDING_REJECTED:
            const id = action.payload.building.id;
            return state.filter((x) => x !== id);

        case SiteRelationshipsActions.CHANGE_SITE_SEARCH_VALUE:
        case SiteRelationshipsActions.LOAD_LINKED_BUILDINGS:
            return [];

        default:
            return state;
    }
};

export const reducer = combineReducers<IManageBuildingsState>({
    buildings,
    isLoading,
    idsBeingRemoved
});
