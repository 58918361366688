import { TooltipPlacement, TooltipTriggerType } from './tooltip.types';

export class EmeraldTooltipCoreInputs implements IEmeraldTooltipCoreInputs {
  id?: string = Math.random().toString(36).substr(2, 9);
  closeOnTriggerHidden?: boolean = false;
  closeOnOutsideClick?: boolean = true;
  trigger?: TooltipTriggerType | TooltipTriggerType[] | null = 'hover';
  delayHide?: number = 0;
  delayShow?: number = 0;
  defaultVisible?: boolean = false;
  followCursor?: boolean = false;
  interactive?: boolean = false;
  offset?: [number, number];
  placement?: TooltipPlacement = 'auto';
  visible?: boolean = false;
  mutationObserverOptions?: MutationObserverInit | null | undefined;
  triggerElement: any;
  persistent?: boolean = false;
}

export interface IEmeraldTooltipCoreInputs {
  /**
   * Unique identifier for the tooltip
   *
   */
  id?: string;
  /**
   * Whether to close the tooltip when its trigger is out of boundary
   * @default false
   */
  closeOnTriggerHidden?: boolean;
  /**
   * Event or events that trigger the tooltip
   * @default hover
   */
  trigger?: TooltipTriggerType | TooltipTriggerType[] | null;
  /**
   * Delay in hiding the tooltip (ms)
   * @default 0
   */
  delayHide?: number;
  /**
   * Delay in showing the tooltip (ms)
   * @default 0
   */
  delayShow?: number;
  /**
   * Whether to make the tooltip spawn at cursor position
   * @default false
   */
  followCursor?: boolean;
  /**
   * Options to MutationObserver, used internally for updating
   * tooltip position based on its DOM changes
   * @default {attributes:true,childList:true,subtree:true}
   */
  mutationObserverOptions?: MutationObserverInit | null;
  /**
   * Whether tooltip is shown by default
   * @default false
   */
  defaultVisible?: boolean;
  /**
   * Used to create controlled tooltip
   */
  visible?: boolean;
  /**
   * If `true`, a click outside the trigger element closes the tooltip
   * @default true
   */
  closeOnOutsideClick?: boolean;
  /**
   * If `true`, hovering the tooltip will keep it open. Normally tooltip closes when the mouse cursor moves out of
   * the trigger element. If it moves to the tooltip element, the tooltip stays open.
   * @default false
   */
  interactive?: boolean;
  /**
   * Alias for popper.js placement, see https://popper.js.org/docs/v2/constructors/#placement
   */
  placement?: TooltipPlacement;
  /**
   * Shorthand for popper.js offset modifier, see https://popper.js.org/docs/v2/modifiers/offset/
   * default [0, 6]
   */
  offset?: [number, number];
  /**
   *triggerElement should be any HTMLElement to trigger the Tooltip Content
   */
  triggerElement: any;
  /**
   * If `true`, the tooltip will not close when the user hovers over it
   * @default false
   */
  persistent?: boolean;
}
