import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';

const getIsLoadingHistory = (state: IRootState) =>
    state.cermAssessmentResultState.isLoadingHistory;
const getHistory = (state: IRootState) => state.cermAssessmentResultState.history;
const getHistoryResponseContinuation = (state: IRootState) => state.cermAssessmentResultState.historyResponseContinuation;

export default createSelector(
    getIsLoadingHistory,
    getHistory,
    getHistoryResponseContinuation,
    (
        isLoadingHistory,
        history,
        historyResponseContinuation,
    ) => ({
        isLoadingHistory,
        history,
        historyResponseContinuation,
    })
);
