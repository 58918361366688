import { createSelector } from 'reselect';

import { IAuditItemValue } from '../../../../components/auditHistory';
import { IKeyedItem } from '../../../../models/keyed-item';
import { IRootRisksState } from '../../../../reducers/risks';
import { getKey } from '../../../../utils/id-utils';

const getIsExpanded = (state: IRootRisksState, props: { item: IKeyedItem }) =>
    state.risks.grid.expandedHistoryItems.hasOwnProperty(props.item.id);

const getIsLoading = (state: IRootRisksState, props: { item: IKeyedItem }) => {
    const match = state.risks.auditHistories[getKey(props.item)];
    return Boolean(match && match.isLoading);
};

const getAuditHistory = (state: IRootRisksState, props: { item: IKeyedItem }) => {
    const match = state.risks.auditHistories[getKey(props.item)];
    return (match && match.data) || [];
};

const getPageSize = (state: IRootRisksState, props: { item: IKeyedItem }) => {
    const match = state.risks.auditHistories[getKey(props.item)];
    return (match && match.pageSize);
};

const getContinuationToken = (state: IRootRisksState, props: { item: IKeyedItem }) => {
    const match = state.risks.auditHistories[getKey(props.item)];
    return (match && match.continuationToken);
};

const fields: Array<IAuditItemValue<IPropertyChange>> = [
    { label: 'Who', value: (changedProp) => changedProp.lastUpdatedByPersonName },
    { label: 'What Changed', value: (changedProp) => changedProp.updatedProperty },
    { label: 'Changed To', value: (changedProp) => changedProp.updatedPropertyValue }
];

export default () => createSelector(
    getIsExpanded,
    getIsLoading,
    getAuditHistory,
    getContinuationToken,
    getPageSize,
    (isExpanded, isLoading, data, continuationToken, pageSize) => ({
        isExpanded,
        isLoading,
        data,
        fields,
        continuationToken,
        pageSize
    })
);

export interface IPropertyChange {
        status: string;
        updatedEntityType: string;
        lastUpdatedByPersonName: string;
        updatedProperty: string;
        updatedPropertyValue: string;
        lastUpdatedDateTime: string;
        lastUpdatedByPersonId: string;
    }
