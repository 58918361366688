import * as React from 'react';
import { IKeyValue } from 'models';
import { Panel } from '../v2/components';
import useTranslate from 'translations/translation-utils';

interface IComponent {
    totalItems: number;
    items: any[];
}

interface IProps {
    hasPermission: boolean;
    title: string;
    intro: string;
    className?: string;
    footer?: React.ReactElement;
    legends?: Array<IKeyValue<string>>;
    component?: IComponent;
    componentTotalItems?: number;
    componentItemsLength?: number;
    headerButton?: React.ReactElement;
}

export const loadComponentEffect = (
    hasPermission: boolean,
    currentSiteId: React.MutableRefObject<string>,
    siteId: string,
    loadComponent: (siteId: string) => void
) => {
    React.useEffect(() => {
        if (hasPermission && siteId && currentSiteId.current !== siteId) {
            currentSiteId.current = siteId;
            loadComponent(siteId);
        }
    }, [siteId, hasPermission]);
};

const DashboardComponent: React.FC<React.PropsWithChildren<IProps>> = ({
    hasPermission,
    title,
    intro,
    className,
    footer,
    legends,
    component,
    headerButton,
    ...props
}) => {
    const translate = useTranslate();

    const getFooter = () => {
        if (!component || !component.totalItems || !component.items) {
            return footer;
        }

        const itemsTotal = component.totalItems;
        const showTotal = Math.min(3, component.items.length);

        let legendsInfo;
        if (legends && legends.length > 0) {
            legendsInfo = (
                <>
                    <ul className="legend">
                        {legends.map((legend, index) => (
                            <li key={index} className={legend.value}>
                                {legend.key}
                            </li>
                        ))}
                    </ul>
                    <div className="is-clearfix" />
                </>
            );
        }

        return (
            <>
                {footer}
                {translate('Dashboard.DashboardComponents.Showing')} {showTotal} {translate('Dashboard.DashboardComponents.OutOf')} {itemsTotal}
                {legendsInfo}
            </>
        );
    };

    return hasPermission ? (
        <div className={`column ${className}`}>
            <Panel title={title} intro={intro} footer={getFooter()} headerElements={headerButton} >
                {props.children}
            </Panel>
        </div>
    ) : null;
};

export default DashboardComponent;
