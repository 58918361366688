import * as React from 'react';
import { ModalCard, ModalCardBody, ModalCardFooter, ModalCardHeader } from '../modalCard';
import { Field, Control, Help } from '../form';
import { BulmaSize } from 'enums/BulmaSize';
import { BulmaColor } from 'enums/BulmaColor';
import { Button } from '../v2/components';
import { injectIntl } from 'react-intl';
import { translate } from 'translations/translation-utils';

export interface IConfirmWithCommentDialogProps {
    confirmMessage: string;
    onClose: () => void;
    onConfirm: (comment: string) => void;
    requireComment: boolean;
    intl: any
}

export interface IConfirmWithCommentDialogState {
    comment: string;
    showRequireCommentMessage: boolean;
}

class ConfirmWithCommentDialogForm extends React.Component<IConfirmWithCommentDialogProps, IConfirmWithCommentDialogState> {
    constructor(props: IConfirmWithCommentDialogProps) {
        super(props);
        this.state = { comment: '', showRequireCommentMessage: false };
    }

    onConfirm = () => {
        if (!this.props.requireComment || (this.props.requireComment && this.state.comment)) {
            this.props.onConfirm(this.state.comment);
        } else {
            this.setState({ showRequireCommentMessage: true });
        }
    }

    handleChange = (value: string) => {
        this.setState({ comment: value });
    }

    render() {
        const { confirmMessage, intl } = this.props;
        return (
            <ModalCard modalId="confirmation" isShown={true}>
                <ModalCardHeader modalId="confirmation" title={translate(intl, 'Comments.ConfirmDialog.Title')} />
                <ModalCardBody modalId="confirmation">
                    {confirmMessage && (<p><b>{confirmMessage}</b></p>)}
                    {!confirmMessage && (<p><b>  {translate(intl, 'IncidentDetails.ConfirmDialog.pleaseConfirm')}</b></p>)}
                    <br />
                    <Field isHorizontal={true} htmlFor="site" label={translate(intl, 'IncidentApprovals.Label.Comment')} labelSize={BulmaSize.Medium}>
                        <Field>
                            <Control>
                                <textarea
                                    id="comment-id"
                                    autoFocus={true}
                                    style={{ width: '100%', height: 70 }}
                                    value={this.state.comment}
                                    onChange={(e) => this.handleChange(e.currentTarget.value)}
                                />
                            </Control>
                        </Field>
                    </Field>
                    {this.state.showRequireCommentMessage &&
                        <Help bulmaColor={BulmaColor.Danger} isShown={true}>
                            {translate(intl, 'IncidentDetails.ConfirmDialog.Addcomment')}

                        </Help>
                    }
                </ModalCardBody>
                <ModalCardFooter>
                    <Button onClick={this.onConfirm}>  {translate(intl, 'IncidentDetails.ConfirmDialog.title')}</Button>
                    <Button onClick={this.props.onClose} buttonType="cancel"> {translate(intl, 'IncidentPage.Button.Cancel')}</Button>
                </ModalCardFooter>
            </ModalCard>
        );
    }
}


export const ConfirmWithCommentDialog = injectIntl(ConfirmWithCommentDialogForm);