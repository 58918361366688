import React from 'react';
import { ISupportTeamModel } from 'models/opportunity-support-team/support-team-model';
import useTranslate from 'translations/translation-utils';

interface IProps {
    supportTeam?: ISupportTeamModel;
}

const SupportTeamForm: React.FC<IProps> = (supportTeam) => {
    const supportTeamvalues = supportTeam.supportTeam;
    const translate = useTranslate();
    return (
        supportTeamvalues && (
            <div className="tabs-container tab-content opportunity-support-team">
                <div className="column">
                    <div className="column is-12">
                        <h3>{translate('OpportunityST.SupportTeam.Form.H3')}</h3>
                    </div>
                    <section className="opportunity-support-team__content">
                        <div className="columns">
                            <div className="column is-4 opportunity-support-team__content-label">
                                {translate('OpportunityST.SupportTeam.Form.Div1')}
                            </div>
                            <div className="column is-8">
                                {supportTeamvalues && supportTeamvalues.critical_Services_Projects}
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-4 opportunity-support-team__content-label ">
                                {translate('OpportunityST.SupportTeam.Form.Div2')}
                            </div>
                            <div className="column is-8">
                                {supportTeamvalues && supportTeamvalues.white_Space}
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-4 opportunity-support-team__content-label ">
                                {translate('OpportunityST.SupportTeam.Form.Div3')}
                            </div>
                            <div className="column is-8">
                                {supportTeamvalues && supportTeamvalues.technology_Services}
                            </div>
                        </div>

                        <div className="columns">
                            <div className="column is-4 opportunity-support-team__content-label ">
                                {translate('OpportunityST.SupportTeam.Form.Div4')}
                            </div>
                            <div className="column is-8">
                                {supportTeamvalues && supportTeamvalues.romonetOREkkoSense}
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    );
};

export default SupportTeamForm;
