import React from 'react';
import { IDifference } from 'models/difference';
import './difference-item.scss';

interface IDifferenceProps {
  difference: IDifference;
}

export default class EngineerDifferenceItem extends React.PureComponent<IDifferenceProps> {

  private newValue: string = null;
  private oldValue: string = null;
  private setValues() {
    const { difference } = this.props;
    if (difference.propertyName.split('[')[0] === 'Skills') {
      this.newValue = `${difference.parentObject2.skillCategoryNumber} - Status: ${difference.parentObject2.status}`;
      this.oldValue = `${difference.parentObject1.skillCategoryNumber} - Status: ${difference.parentObject1.status}`;
    } else if ((difference.propertyName.split('[')[0] === 'Training')) {
      this.newValue = `Training: ${difference.parentObject2.trainingCategoryName} - ${difference.parentObject2.trainingTypeName}`;
      this.oldValue = `${difference.parentObject1.trainingCategoryName} - ${difference.parentObject1.trainingTypeName}`;
    } else {
      this.newValue = null;
      this.oldValue = null;
    }
  }
  public render(): JSX.Element {
    this.setValues();
    return (
      <div className="difference-container">
        {this.newValue && <div className="new-value">{this.newValue}</div>}
        {this.oldValue && this.oldValue !== ' - ' && <div className="old-value">
          {this.oldValue}</div>}
      </div>
    );
  }
}
