import React from 'react';
import { history } from 'routes/App';
import { IToolbarIconItem, ToolbarIconType, IconToolbar } from '../v2/toolbar/IconToolbar';
import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import useTranslate from 'translations/translation-utils';
import { IKeyValue } from 'models';

interface IProps {
    multipleSites: boolean;
    siteId: string;
    isClient: boolean;
    disableExport: boolean;
    isShowHelp?: boolean;
    isFilterExists?: boolean;
    onShowFilters: () => void;
    onClearFilters: () => void;
    onExport: (siteId: string, clientExport: boolean) => void;
    onExportAll: (clientExport: boolean, operatingPlatform: string) => void;
    clearForm?: () => void;
    onClickHelp?: () => void;
    operatingPlatform: string;
    permissions: Array<IKeyValue<string>>;
}

const RiskRegisterGridToolbarView: React.FC<IProps> = ({
    multipleSites,
    siteId,
    isClient,
    disableExport,
    isShowHelp,
    isFilterExists,
    onShowFilters,
    onClearFilters,
    onExport,
    onExportAll,
    clearForm,
    onClickHelp,
    operatingPlatform,
}) => {
    const translate = useTranslate();

    const onClickForSite = () => {
        onExport(siteId, isClient);
    };

    const onClickAll = () => {
        onExportAll(isClient, operatingPlatform);
    };

    const getToolbarItems = (): IToolbarIconItem[] => {
        return [
            !isClient && {
                id: 'add-risk-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.AddRisk'),
                type: ToolbarIconType.add,
                onClick: () => {
                    if (clearForm) {
                        clearForm();
                    }
                    history.push('/RiskRegister/Details');
                },
            },
            multipleSites && {
                id: 'export-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.Export'),
                type: ToolbarIconType.download,
                isDisabled: disableExport,
                children: [
                    {
                        id: 'export-incidents-button',
                        title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.Export'),
                        type: ToolbarIconType.excel,
                        isDisabled: disableExport,
                        onClick: onClickForSite,
                    },
                    {
                        id: 'export-this-site-button',
                        title: translate(`RiskRegisterPage.RiskRegisterToolbar.Title.ExportAll`),
                        type: ToolbarIconType.excel,
                        isDisabled: disableExport,
                        onClick: onClickAll,
                    },
                ],
            },
            !multipleSites && {
                id: 'export-incidents-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.Export'),
                type: ToolbarIconType.excel,
                isDisabled: disableExport,
                onClick: onClickForSite,
            },
            {
                id: 'clear-all-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.ClearFilters'),
                type: isFilterExists ? ToolbarIconType.filterApplied : ToolbarIconType.clearFilter,
                onClick: onClearFilters,
            },
            {
                id: 'show-filters-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.ShowFilters'),
                type: ToolbarIconType.filter,
                onClick: onShowFilters,
            },
            isShowHelp && {
                id: 'show-help',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.Help'),
                type: ToolbarIconType.help,
                onClick: onClickHelp,
            },
        ];
    };

    return <IconToolbar items={getToolbarItems()} />;
};

const mapStateToProps = (state: IRootState) => {
    return {
        multipleSites: state.app.lookupSites.length > 1,
        siteId: state.app.siteId,
    };
};

export const RiskRegisterGridToolbar = connect(mapStateToProps)(RiskRegisterGridToolbarView);
