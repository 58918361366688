import * as React from 'react';
import Page from 'components/v2/page/Page';
import { IOpportunity } from 'models/opportunity-question-set';
import { OpportunityGridToolbar } from '../opportunity-grid-toolbar/opportunity-grid-toolbar';
import OpportunityQuestionsList from './opportunity-questions-list';
import { Loader } from 'components/loader';
import useTranslate from 'translations/translation-utils';

export interface IProps {
    loadOpportunityQuestionsList: () => void;
    cleanUp: () => void;
    opportunityQuestionsList: IOpportunity[];
    isLoading: boolean;
}

const OpportunityQuestionSets: React.FC<IProps> = ({
    loadOpportunityQuestionsList,
    opportunityQuestionsList,
    isLoading,
    cleanUp,
}) => {
    const translate = useTranslate();

    React.useEffect(() => {
        loadOpportunityQuestionsList();
    }, []);

    return (
        <Page title={translate('OpportunityQ.QuestionSet.PageTitle')} isAdminPage={true}>
            <OpportunityGridToolbar cleanUp={cleanUp} />
            <Loader loading={isLoading}>
                <OpportunityQuestionsList opportunities={opportunityQuestionsList} />
            </Loader>
        </Page>
    );
};

export default OpportunityQuestionSets;
