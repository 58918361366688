import * as React from 'react';
import { CermProgressChartContainer } from 'components/cerm-progress-chart/cerm-progress-chart-container';
import { IKeyValue } from 'models';
import { getSiteOperatingPlatform } from 'models/site-profile';


interface IProps {
    siteId: string;
    lookupSites: IKeyValue<string>[];
}



export const CermAssessmentOverviewTab: React.FC<IProps> = ({ siteId, lookupSites }) => {
    return (
        <div className="tabs-container tab-content">
            <CermProgressChartContainer siteId={siteId} operatingPlatform={getSiteOperatingPlatform(siteId, lookupSites)} />
        </div>
    );
};
