
import { onRouteChange } from 'actions/app-actions';

import {
    ISelectValue,
    ManageRoleActions,
    ManageRoleActionTypes,
    onAddRemovePrivilege,
    onDeleteRole,
    onEditRolePrivileges,
    onNavigatePeopleTab,
    onNavigatePrivilegesTab,
    onNavigateRolesTab,
    onShowRoleModal,
    onToggleAllClientRelationshipCheckedFilter,
    onToggleExpandClient,
} from '../../../actions/manage-role-actions';
import { IManageRoleState } from '../../../reducers/manageRoles';
import { loadPersons, onSort, exportPeople } from 'actions/actions-v2/person-action-v2';

export default {
    onListSort: onSort,
    onRouteChange,
    onAddRemovePrivilege,
    onShowRoleModal,
    onDeleteRole,
    onEditRolePrivileges,
    onNavigateRolesTab,
    onNavigatePrivilegesTab,
    onNavigatePeopleTab,
    onToggleExpanded: (id: string): ManageRoleActionTypes => ({
        type: ManageRoleActions.TOGGLE_ITEM_EXPANDED,
        payload: {
            personId: id
        }
    }),
    onToggleFilterExpanded: (id: Extract<keyof IManageRoleState, string>): ManageRoleActionTypes => ({
        type: ManageRoleActions.TOGGLE_FILTER_EXPANDED,
        payload: {
            personId: id
        }
    }),
    onRoleChange: (value: ISelectValue[], action: any): ManageRoleActionTypes => ({
        type: ManageRoleActions.CHANGE_ROLES,
        payload: {
            value,
            action
        }
    }),
    onClearFilters: (): ManageRoleActionTypes => ({
        type: ManageRoleActions.CLEAR_FILTERS
    }),
    loadPersons,
    onToggleAllClientRelationshipCheckedFilter,
    onToggleAllRelationshipCheckedFilter: onToggleAllClientRelationshipCheckedFilter,
    onToggleExpandClient,
    onExport: exportPeople
};
