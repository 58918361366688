import React from 'react';
import { SelectField } from 'components/form/fields/select-field';
import { IKeyValue } from 'models/key-value';
import { isEmpty } from 'lodash';
import { IOpportunityQuestionnaire } from 'models/opportunity-question-set';
import { TextField } from 'components/form/fields/text-field';
import { FormikProps } from 'formik';
import { formatDateTime } from 'utils/date-utils';
import useTranslate from 'translations/translation-utils';

interface IProps {
    customerName?: string;
    siteName?: string;
    siteId?: string;
    regions?: Array<IKeyValue<string>>;
    customercontactname?: string;
    customercontactrole?: string;
    contractmonths?: string;
    contractmanager?: string;
    customerTypes?: Array<IKeyValue<string>>;
    completedby?: string;
    completedon?: string;
    division: string;
    businessUnit: string;
    formikBag: FormikProps<Partial<IOpportunityQuestionnaire>>;
    loadTeam: (region: string) => void;
}

const OpportunitySiteInfoFrom: React.FC<IProps> = ({
    customerName,
    customerTypes,
    siteName,
    regions,
    division,
    businessUnit,
    formikBag,
    loadTeam,
}) => {
    const translate = useTranslate();
    const onCustomerTypeChange = (field: { value: string; label: string }) => {
        formikBag.setFieldValue('customerType', field.value);
    };

    const onRegionChange = (field: { value: string; label: string }) => {
        formikBag.setFieldValue('region', field.value);
    };

    React.useEffect(() => {
        // tslint:disable-next-line: no-unused-expression
        formikBag.values.region && loadTeam(formikBag.values.region);
    }, [formikBag.values.region]);

    const getSelectedValue = (collection: Array<IKeyValue<string>>, returnvalue: string) => {
        if (!returnvalue || isEmpty(collection)) {
            return { value: '', label: translate('Globals.Label.Select'), key: '' };
        }
        const facility = collection.find((f) => f.value === returnvalue);
        if (!facility) {
            return { value: '', label: translate('Globals.Label.Select'), key: '' };
        }
        return { value: facility.value, label: facility.label, key: facility.key };
    };
    const checkDate = () => {
        if (formatDateTime(formikBag.values.lastUpdatedDateTime) === '01 Jan 0001 05:53') {
            return '';
        } else {
            return formikBag.values.lastUpdatedDateTime
                ? formatDateTime(formikBag.values.lastUpdatedDateTime)
                : '';
        }
    };

    const translatedCustomerType = () => {
        return customerTypes &&
            customerTypes.map((m) => ({
                value: m.value,
                label: translate('OpportunityS.CustomerType.Dropdown.', m.value),
                key: m.key,
            }));
    }
    return (
        <div className="tabs-container tab-content opportunity-site-form ">
            <div className="columns">
                <div className="column is-4">
                    <TextField
                        id="customerName"
                        label={translate('OpportunityQ.SiteInfoPage.Customer')}
                        value={customerName}
                        handleChange={formikBag.handleChange}
                        setVal={formikBag.setFieldValue}
                    />
                </div>

                <div className="column is-4">
                    <TextField
                        id="site-name"
                        label={translate('OpportunityS.SiteInfoForm.TextField.Label')}
                        value={siteName}
                        handleChange={formikBag.handleChange}
                        setVal={formikBag.setFieldValue}
                    />
                </div>
                <div className="column is-4">
                    <SelectField
                        id="region"
                        label={translate('GainAccess.Labels.Regions')}
                        className="select-region"
                        value={getSelectedValue(regions, formikBag.values.region)}
                        options={
                            regions &&
                            regions.map((m) => ({
                                value: m.value,
                                label: m.label,
                                key: m.key,
                            }))
                        }
                        handleChange={onRegionChange}
                    />
                </div>
            </div>
            <div className="columns is-12">
                <div className="column is-4">
                    <TextField
                        id="division"
                        label={translate('Cerm.GlobalOverviewFilters.Labels.Division')}
                        value={division ? division : ''}
                        handleChange={formikBag.handleChange}
                        setVal={formikBag.setFieldValue}
                    />
                </div>
                <div className="column is-4">
                    <TextField
                        id="sbusiness-unit"
                        label={translate('Cerm.AssessmentFilters.Labels.BusinessUnit')}
                        value={businessUnit ? businessUnit : ''}
                        handleChange={formikBag.handleChange}
                        setVal={formikBag.setFieldValue}
                    />
                </div>
                <div className="column is-4">
                    <TextField
                        id="customerName"
                        label={translate('OpportunityS.SiteInfoForm.TextField2.Label')}
                        value={formikBag.values.customerName ? formikBag.values.customerName : ''}
                        handleChange={formikBag.handleChange}
                        setVal={formikBag.setFieldValue}
                    />
                </div>
            </div>

            <div className="columns">
                <div className="column is-4">
                    <TextField
                        id="customerRole"
                        label={translate('OpportunityS.SiteInfoForm.TextField3.Label')}
                        value={formikBag.values.customerRole ? formikBag.values.customerRole : ''}
                        handleChange={formikBag.handleChange}
                        setVal={formikBag.setFieldValue}
                    />
                </div>
                <div className="column is-4">
                    <TextField
                        id="contractMonths"
                        label={translate('OpportunityS.SiteInfoForm.TextField4.Label')}
                        value={
                            formikBag.values.contractMonths ? formikBag.values.contractMonths : ''
                        }
                        handleChange={formikBag.handleChange}
                        setVal={formikBag.setFieldValue}
                    />
                </div>
                <div className="column is-4">
                    <TextField
                        id="contractManager"
                        label={translate('OpportunityS.SiteInfoForm.TextField5.Label')}
                        value={
                            formikBag.values.contractManager ? formikBag.values.contractManager : ''
                        }
                        handleChange={formikBag.handleChange}
                        setVal={formikBag.setFieldValue}
                    />
                </div>
            </div>

            <div className="columns is-12">
                <div className="column is-4">
                    <SelectField
                        id="customertype"
                        label={translate('OpportunityS.SiteInfoForm.TextField6.Label')}
                        className="select-customer-type"
                        value={getSelectedValue(translatedCustomerType(), formikBag.values.customerType)}
                        options={translatedCustomerType()}
                        handleChange={onCustomerTypeChange}
                    />
                </div>
                <div className="column is-4">
                    <TextField
                        id="lastUpdatedByPersonName"
                        label={translate('OpportunityS.SiteInfoForm.TextField7.Label')}
                        value={
                            formikBag.values.lastUpdatedByPersonName
                                ? formikBag.values.lastUpdatedByPersonName
                                : ''
                        }
                        handleChange={formikBag.handleChange}
                        setVal={formikBag.setFieldValue}
                        isDisabled={true}
                    />
                </div>
                <div className="column is-4">
                    <TextField
                        id="lastUpdatedDateTime"
                        label={translate('Cerm.ComparisonPanel.Labels.LastUpdated')}
                        value={checkDate()}
                        handleChange={formikBag.handleChange}
                        setVal={formikBag.setFieldValue}
                        isDisabled={true}
                    />
                </div>
            </div>
        </div>
    );
};
export default OpportunitySiteInfoFrom;
