import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { IKeyValue } from 'models';
import { IRootRiskAssessmentState } from 'reducers/risk-assessment';

const getSiteId = createSelector(
    (state: IRootState) => state.app,
    (appState) => appState.siteId
);

const getExpandedFilters = (state: IRootRiskAssessmentState) =>
    state.riskAssessmentState.expandedFilters;

const getLookupCategoryFilters = (state: IRootState) => state.app.lookupQuestionCategories;

const getLookupUrgencyFilters = (state: IRootState) => state.app.lookupQuestionUrgencies;

const getLookupOtherFilters = (): Array<IKeyValue<string>> => [
    { value: 'Hide Completed', key: 'Hide Completed', label: 'Hide Completed' }
];

export default createSelector(
    getSiteId,
    getExpandedFilters,
    getLookupCategoryFilters,
    getLookupUrgencyFilters,
    getLookupOtherFilters,
    (siteId, expandedFilters, lookupCategoryFilters, lookupUrgencyFilters, lookupOtherFilters) => ({
        siteId,
        expandedFilters,
        lookupCategoryFilters,
        lookupUrgencyFilters,
        lookupOtherFilters
    })
);
