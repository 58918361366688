import { connect } from 'react-redux';

import actions from './actions';
import { AttachClientForm as View } from './attach-client-form';
import selector from './selector';

export const AttachClientForm = connect(
    selector,
    actions
)(View);
