import * as React from 'react';
import { checkPermission } from 'utils/permission-utils';
import { IKeyValue } from 'models';
import useTranslate from 'translations/translation-utils';
import { Privilege } from 'enums/Privilege';
import { SelectField } from 'components/form/fields/select-field';
import { RiskMacroLevel, RiskMacroLevelKvp } from 'models/risk-macro-levels';
import { IRisk } from 'models/risk';
import { FormikProps } from 'formik';

interface IProps {
    formikBag: FormikProps<IRisk>;
    riskId?: string;
    permissions: Array<IKeyValue<string>>;
    lookupDivisions: Array<IKeyValue<string>>;
    lookupBusinessUnits: Array<IKeyValue<string>>;
    lookupRegions: Array<IKeyValue<string>>;
    lookupCountries: Array<IKeyValue<string>>;
    clients: Array<IKeyValue<string>>;
    isMacro: boolean;
    isMacroLevelAndSelected: (isMacroLevel: boolean, isSelected: boolean) => void;
    setIsMacroValid: (isValid: boolean) => void;
}

export const MacroRiskForm: React.FC<IProps> = ({
    formikBag,
    riskId,
    permissions,
    lookupDivisions,
    lookupBusinessUnits,
    lookupRegions,
    lookupCountries,
    clients,
    isMacro,
    isMacroLevelAndSelected,
    setIsMacroValid,
}) => {
    const translate = useTranslate();
    const [isDisabled, setIsDisabled] = React.useState(false);
    const [macroLevelValues, setMacroLevelValues] = React.useState([]);
    const [macroLevelValueError, setMacroLevelValueError] = React.useState(null);
    const selectedMacroLevel = {
        value: formikBag.values?.macroLevel ?? RiskMacroLevel.Site,
        label: formikBag.values?.macroLevel ?? RiskMacroLevel.Site,
    };

    React.useEffect(() => {
        setMacroLevelValues(formikBag?.values?.macroLevelValues ?? []);
        validateMacroLevelValue(formikBag?.values?.macroLevelValues);
    }, [formikBag?.values]);

    React.useEffect(() => {
        setIsDisabled(!!riskId);
    }, [riskId]);

    const handleMacroLevel = (field: { value: string; label: string }) => {
        formikBag.setFieldValue('macroLevel', field.value);
        formikBag.setFieldValue('macroLevelValues', null);

        isMacroLevelAndSelected(field.value !== RiskMacroLevel.Site, false);
    };

    const handleMacroLevelValues = (e: Array<IKeyValue<string>>) => {
        formikBag.setFieldValue('macroLevelValues', e);

        validateMacroLevelValue(e);
        setMacroLevelValues(e);
        isMacroLevelAndSelected(
            formikBag.values.macroLevel !== RiskMacroLevel.Site,
            e && e.length > 0
        );
    };

    const validateMacroLevelValue = (e: Array<IKeyValue<string>>) => {
        if (isMacro && selectedMacroLevel.value !== RiskMacroLevel.Site && (!e || e.length === 0)) {
            setMacroLevelValueError(translate('RiskRegisterUpsertPage.Macro.LevelValueError'));
            setIsMacroValid(false);
        } else {
            setMacroLevelValueError(null);
            setIsMacroValid(true);
        }
    };

    return checkPermission(Privilege.RiskMacroLevelUpdate, permissions) ? (
        <div className="columns">
            <div className="column is-3">
                <SelectField
                    id="macroLevel"
                    label={translate('RiskRegisterUpsertPage.Macro.LevelLabel')}
                    value={selectedMacroLevel}
                    options={RiskMacroLevelKvp.map(c => ({
                        key: c.key,
                        value: c.value,
                        label: c.label,
                    }))}
                    isDisabled={isDisabled}
                    handleChange={handleMacroLevel}
                />
            </div>

            <div className="column is-3">
                {formikBag.values?.macroLevel === RiskMacroLevel.Division && (
                    <SelectField
                        id="macroLevelValueDivision"
                        label={translate('RiskRegisterUpsertPage.Macro.LevelValueLabel')}
                        value={macroLevelValues}
                        error={macroLevelValueError}
                        options={lookupDivisions.map(c => ({
                            key: c.key,
                            value: c.value,
                            label: c.label,
                        }))}
                        isMulti={true}
                        isDisabled={isDisabled}
                        handleChange={handleMacroLevelValues}
                    />
                )}

                {formikBag.values?.macroLevel === RiskMacroLevel.BusinessUnit && (
                    <SelectField
                        id="macroLevelValueBusinessUnit"
                        label={translate('RiskRegisterUpsertPage.Macro.LevelValueLabel')}
                        value={macroLevelValues}
                        error={macroLevelValueError}
                        options={lookupBusinessUnits.map(c => ({
                            key: c.key,
                            value: c.value,
                            label: c.label,
                        }))}
                        isMulti={true}
                        isDisabled={isDisabled}
                        handleChange={handleMacroLevelValues}
                    />
                )}

                {formikBag.values?.macroLevel === RiskMacroLevel.Region && (
                    <SelectField
                        id="macroLevelValueRegion"
                        label={translate('RiskRegisterUpsertPage.Macro.LevelValueLabel')}
                        value={macroLevelValues}
                        error={macroLevelValueError}
                        options={lookupRegions.map(c => ({
                            key: c.key,
                            value: c.value,
                            label: c.label,
                        }))}
                        isMulti={true}
                        isDisabled={isDisabled}
                        handleChange={handleMacroLevelValues}
                    />
                )}

                {formikBag.values?.macroLevel === RiskMacroLevel.Country && (
                    <SelectField
                        id="macroLevelValueCountry"
                        label={translate('RiskRegisterUpsertPage.Macro.LevelValueLabel')}
                        value={macroLevelValues}
                        error={macroLevelValueError}
                        options={lookupCountries.map(c => ({
                            key: c.key,
                            value: c.value,
                            label: c.label,
                        }))}
                        isMulti={true}
                        isDisabled={isDisabled}
                        handleChange={handleMacroLevelValues}
                    />
                )}

                {formikBag.values?.macroLevel === RiskMacroLevel.Account && (
                    <SelectField
                        id="macroLevelValueAccount"
                        label={translate('RiskRegisterUpsertPage.Macro.LevelValueLabel')}
                        value={macroLevelValues}
                        error={macroLevelValueError}
                        options={clients.map(c => ({
                            key: c.key,
                            value: c.value,
                            label: c.label,
                        }))}
                        isMulti={true}
                        isDisabled={isDisabled}
                        handleChange={handleMacroLevelValues}
                    />
                )}
            </div>
        </div>
    ) : null;
};
