import { of } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { AppNotificationActions } from '../actions/app-notification-actions';

export interface IXhr {
    ajaxData?: any;
    statusText: string;
}

export interface IRequest {
    async: boolean;
    crossDomain: boolean;
    withCredentials: boolean;
    headers: Headers;
    method: string;
    responseType: string;
    timeout: number;
    url: string;
    body: string;
}

export interface IResponse {
    message: string;
    name: string;
    xhr: IXhr;
    request: IRequest;
    status: number;
    responseType: string;
    response: string;
}

export const getErrorActions$ = <TActionType>(errorTitle: string) =>
    // TODO: Fix this output type
    (action: TActionType, ajaxError: AjaxError | IResponse, payload: any): any => of(
    {
        type: action,
        payload,
        error: true
    },
    {
        type: AppNotificationActions.SEND_ERROR_NOTIFICATION,
        payload: {
            errorTitle,
            errorMessage: payload.errorMessage || ajaxError.xhr.statusText
        }
    }
);
