import classNames from 'classnames';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import './Nav.scss';
import { logOut } from 'utils/auth-helper';

export interface IStateProps extends React.AllHTMLAttributes<HTMLDivElement> {
    text?: string;
    link: string;
    isSelected?: boolean;
    hasDropdown?: boolean;
    hasContainer?: boolean;
    isHref?: boolean;
    isCentre?: boolean;
    navState?: any;
    subtext?: string
    onLinkClick?: () => void;
}

export const NavItem: React.FC<IStateProps> = (props) => {
    const css = classNames([
        'navbar-item is-hoverable',
        {
            'has-dropdown': props.hasDropdown,
            selected: props.isSelected,
            'is-centre': props.isCentre,
        },
        props.className,
        props.subtext ? 'nav-main-item' : ''
    ]);
    let goodLink = '/';
    if (!(props.link === '' || props.link === undefined || props.link === null)) {
        goodLink = props.link;
    }

    return (
        <>
            {props.hasContainer && (
                <div className={css} onClick={props.onClick}>
                    {props.text && <NavLink to={goodLink}>
                        {props.text}
                        {props.subtext && <span>{props.subtext}</span>}
                    </NavLink>}
                    {props.children}
                </div>
            )}
            {!props.hasContainer && !props.isHref && !props.onLinkClick && (
                <NavLink className={css} to={{
                    pathname: goodLink,
                    state: props.navState ? props.navState : {}
                }}>
                    {props.text}
                    {props.subtext && <span>{props.subtext}</span>}
                </NavLink>
            )}
            {!props.hasContainer && props.isHref && (
                <a className={css} href="#" onClick={() => logOut()}>
                    {props.text}
                    {props.subtext && <span>{props.subtext}</span>}
                </a>
            )}
            {props.onLinkClick && (
                <a className={css} href="#" onClick={() => props.onLinkClick()}>
                    {props.text}
                    {props.subtext && <span>{props.subtext}</span>}
                </a>
            )}
        </>
    );
};
