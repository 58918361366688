import React from 'react';
import { orderBy } from 'lodash';
import { Table, Panel, TableHeader, SortOrder } from '../v2/components';
import { Link } from 'react-router-dom';
import { IClientSiteSummary } from 'models/risk';
import { IKeyValue } from 'models';
import useTranslate from 'translations/translation-utils';

interface IProps {
    summary: IClientSiteSummary[];
    showClientRisks: boolean;
    showCriticalSpares: boolean;
    isLoading: boolean;
    // tslint:disable-next-line: array-type
    siteFeatures: IKeyValue<string[]>[];
    onSiteChange: (siteId: string) => void;
    openSite: (siteId: string) => void;
}

export const ClientSiteSummaryTable: React.FC<IProps> = ({ summary, isLoading, onSiteChange, showClientRisks, showCriticalSpares, siteFeatures, openSite }) => {
    const [sortField, setSortField] = React.useState('siteName');
    const [sortOrder, setSortOrder] = React.useState(SortOrder.asc);
    const translate = useTranslate();

    const onToggleHandler = (field: string) => {
        if (sortField !== field) {
            setSortField(field);
            setSortOrder(SortOrder.desc);
            return;
        }
        const sort = sortOrder === SortOrder.asc ? SortOrder.desc : SortOrder.asc;
        setSortOrder(sort);
    };

    const getSortState = (field: string): SortOrder => {
        if (field === sortField) {
            return sortOrder;
        }
        return SortOrder.off;
    };

    const getSortedList = (): IClientSiteSummary[] => {
        if (sortOrder === SortOrder.off) {
            return summary;
        }
        return sortOrder === SortOrder.asc
            ? orderBy(summary, [sortField], ['asc'])
            : orderBy(summary, [sortField], ['desc']);
    };

    const getNumberWithFormat = (fieldName: string, amount: number, siteId: string) => {
        if (fieldName === 'belowMin' && amount > 0) {
            return (
                <Link onClick={(e) => { onSiteChange(siteId); e.stopPropagation(); }} to={{ pathname: `/ClientCriticalSystemSpares/BelowMin` }}>
                    <mark className="high">{amount}</mark>
                </Link>);
        }
        if (fieldName === 'closeToMin' && amount > 0) {
            return (
                <Link onClick={(e) => { onSiteChange(siteId); e.stopPropagation(); }} to={{ pathname: `/ClientCriticalSystemSpares/CloseToMin` }}>
                    <mark className="medium">{amount}</mark>
                </Link>);
        }
        return <div>{amount}</div>;
    };

    const onOpenSite = (siteId: string) => {
        openSite(siteId);
    };

    const shouldHaveAccess = (siteId: string, siteFeature: string) => {
        const site = siteFeatures.find((x) => x.key === siteId);
        if (site) {
            if (site.value.includes(siteFeature)) {
                return true;
            }
        }
        return false;
    };

    const footer = () => (
        <>
            <ul className="legend table-footer">
                <li className="high">{translate('CriticalSystemSparesTable.Footer.L1')}</li>
                <li className="medium">{translate('CriticalSystemSparesTable.Footer.L2')}</li>
            </ul>
            <div className="is-clearfix" />
        </>
    );

    return (
        <Panel title="Your Sites" footer={footer()}>
            {!isLoading && summary.length > 0 &&
                <Table>
                    <thead>
                        <tr>
                            <th className="group empty-header" colSpan={1} />
                            {showClientRisks && <th className="group" colSpan={3}>{translate('SideNavBar.Labels.Risks')}</th>}
                            {showCriticalSpares && <th className="group" colSpan={2}>{translate('SideNavBar.Labels.SystemSpares')}</th>}
                        </tr>
                        <tr>
                            <TableHeader sortState={getSortState('siteName')} onToggle={() => onToggleHandler('siteName')}>
                                {translate('SideNavBar.Labels.Site')}
                            </TableHeader>
                            {showClientRisks &&
                                <TableHeader sortState={getSortState('numberOpenPendingReview')} onToggle={() => onToggleHandler('numberOpenPendingReview')} className="narrow-2">
                                    {translate('Globals.Status.Open&PendingReview')}
                                </TableHeader>}
                            {showClientRisks &&
                                <TableHeader sortState={getSortState('numberOpenRiskAccepted')} onToggle={() => onToggleHandler('numberOpenRiskAccepted')} className="narrow-2">
                                    {translate('RiskDetailsPage.Status.dropdown.Open&RiskAccepted')}
                                </TableHeader>}
                            {showClientRisks &&
                                <TableHeader
                                    sortState={getSortState('numberOpenRemedyAuthorised')}
                                    onToggle={() => onToggleHandler('numberOpenRemedyAuthorised')}
                                    className="narrow-2"
                                >
                                    {translate('Globals.Status.Open&RemedyAuthorised')}
                                </TableHeader>}
                            {showCriticalSpares &&
                                <TableHeader sortState={getSortState('numberPartsBelowMinimum')} onToggle={() => onToggleHandler('numberPartsBelowMinimum')} className="narrow-2">
                                    {translate('ClientDashboard.ClientSiteSummary.BelowMinQty')}
                                </TableHeader>}
                            {showCriticalSpares &&
                                <TableHeader
                                    sortState={getSortState('numberPartsCloseToMinimum')}
                                    onToggle={() => onToggleHandler('numberPartsCloseToMinimum')}
                                    className="narrow-2"
                                >
                                    {translate('ClientDashboard.ClientSiteSummary.CloseToMinQty')}
                                </TableHeader>}
                        </tr>
                    </thead>
                    <tbody className="clickable">
                        {getSortedList().map((item) => (
                            <tr key={item.siteId} onClick={() => onOpenSite(item.siteId)}>
                                <td>{item.siteName}</td>
                                {showClientRisks && <td className="narrow-2 centered">{shouldHaveAccess(item.siteId, 'Client Risks') ? item.numberOpenPendingReview : 'N/A'}</td>}
                                {showClientRisks && <td className="narrow-2 centered">{shouldHaveAccess(item.siteId, 'Client Risks') ? item.numberOpenRiskAccepted : 'N/A'}</td>}
                                {showClientRisks && <td className="narrow-2 centered">{shouldHaveAccess(item.siteId, 'Client Risks') ? item.numberOpenRemedyAuthorised : 'N/A'}</td>}
                                {showCriticalSpares && <td className="narrow-2 centered">{shouldHaveAccess(item.siteId, 'Critical Spares') ?
                                    getNumberWithFormat('belowMin', item.numberPartsBelowMinimum, item.siteId) : 'N/A'}</td>}
                                {showCriticalSpares && <td className="narrow-2 centered">{shouldHaveAccess(item.siteId, 'Critical Spares') ?
                                    getNumberWithFormat('closeToMin', item.numberPartsCloseToMinimum, item.siteId) : 'N/A'}</td>}
                            </tr>
                        ))}
                    </tbody>
                </Table>}
            {!isLoading && summary.length === 0 && <div className="column is-12 has-text-centered">No data is available</div>}
        </Panel>
    );
};
