import { Action } from 'redux';

import { IChangeSearchValueAction, ILoadSearchResultsAction } from '.';
import { IBuilding } from '../../models/building';
import { ISiteProfile } from '../../models/site-profile';

export enum BuildingActions {
    // Buildings
    SELECT_BUILDING = '@@siteRelationships/SELECT_BUILDING',
    CHANGE_BUILDING_SEARCH_VALUE = '@@siteRelationships/CHANGE_BUILDING_SEARCH_VALUE',
    LOAD_BUILDING_SEARCH_RESULTS = '@@siteRelationships/LOAD_BUILDING_SEARCH_RESULTS',
    LOAD_BUILDING_SEARCH_RESULTS_FULFILLED = '@@siteRelationships/LOAD_BUILDING_SEARCH_RESULTS_FULFILLED',
    LOAD_BUILDING_SEARCH_RESULTS_REJECTED = '@@siteRelationships/LOAD_BUILDING_SEARCH_RESULTS_REJECTED',
    LOAD_BUILDING_SEARCH_RESULTS_CANCELLED = '@@siteRelationships/LOAD_BUILDING_SEARCH_RESULTS_CANCELLED',

    LOAD_LINKED_BUILDINGS = '@@siteRelationships/LOAD_LINKED_BUILDINGS',
    LOAD_LINKED_BUILDINGS_FULFILLED = '@@siteRelationships/LOAD_LINKED_BUILDINGS_FULFILLED',
    LOAD_LINKED_BUILDINGS_REJECTED = '@@siteRelationships/LOAD_LINKED_BUILDINGS_REJECTED',
    LOAD_LINKED_BUILDINGS_CANCELLED = '@@siteRelationships/LOAD_LINKED_BUILDINGS_CANCELLED',

    LINK_BUILDING = '@@siteRelationships/LINK_BUILDING',
    LINK_BUILDING_FULFILLED = '@@siteRelationships/LINK_BUILDING_FULFILLED',
    LINK_BUILDING_REJECTED = '@@siteRelationships/LINK_BUILDING_REJECTED',
    LINK_BUILDING_CANCELLED = '@@siteRelationships/LINK_BUILDING_CANCELLED',

    UNLINK_BUILDING = '@@siteRelationships/UNLINK_BUILDING',
    UNLINK_BUILDING_CONFIRMED = '@@siteRelationships/UNLINK_BUILDING_CONFIRMED',
    UNLINK_BUILDING_FULFILLED = '@@siteRelationships/UNLINK_BUILDING_FULFILLED',
    UNLINK_BUILDING_REJECTED = '@@siteRelationships/UNLINK_BUILDING_REJECTED',
    UNLINK_BUILDING_CANCELLED = '@@siteRelationships/UNLINK_BUILDING_CANCELLED',
}

export interface ISelectBuilding extends Action {
    type: BuildingActions.SELECT_BUILDING;
    payload: {
        building: IBuilding;
    };
}

export const selectBuilding = (building: IBuilding): ISelectBuilding => ({
    type: BuildingActions.SELECT_BUILDING,
    payload: {
        building
    }
});

export interface IChangeBuildingSearchValueAction extends IChangeSearchValueAction {
    type: BuildingActions.CHANGE_BUILDING_SEARCH_VALUE;
}

export const changeBuildingSearchValue = (value: string): IChangeBuildingSearchValueAction => ({
    type: BuildingActions.CHANGE_BUILDING_SEARCH_VALUE,
    payload: { value }
});

export interface ILoadBuildingSearchResults extends ILoadSearchResultsAction {
    type: BuildingActions.LOAD_BUILDING_SEARCH_RESULTS;
}

export const loadBuildingSearchResults = (searchString: string): ILoadSearchResultsAction => ({
    type: BuildingActions.LOAD_BUILDING_SEARCH_RESULTS,
    payload: {
        searchString
    }
});

export interface ILoadBuildingSearchResultsFulfilled extends Action {
    type: BuildingActions.LOAD_BUILDING_SEARCH_RESULTS_FULFILLED;
    payload: {
        searchString: string;
        buildings: IBuilding[];
    };
}

export const loadBuildingSearchResultsFulfilled = (
    searchString: string,
    buildings: IBuilding[]
): ILoadBuildingSearchResultsFulfilled => ({
    type: BuildingActions.LOAD_BUILDING_SEARCH_RESULTS_FULFILLED,
    payload: {
        searchString,
        buildings
    }
});

export interface ILoadBuildingSearchResultsCancelled extends Action {
    type: BuildingActions.LOAD_BUILDING_SEARCH_RESULTS_CANCELLED;
    payload: {
        searchString: string;
    };
}

export const loadBuildingSearchResultsCancelled = (
    searchString: string
): ILoadBuildingSearchResultsCancelled => ({
    type: BuildingActions.LOAD_BUILDING_SEARCH_RESULTS_CANCELLED,
    payload: {
        searchString
    }
});

export interface ILoadBuildingSearchResultsRejected extends Action {
    type: BuildingActions.LOAD_BUILDING_SEARCH_RESULTS_REJECTED;
    error: true;
    payload: any;
}

export const loadBuildingSearchResultsRejected = (
    searchString: string,
    error?: any
): ILoadBuildingSearchResultsRejected => ({
    type: BuildingActions.LOAD_BUILDING_SEARCH_RESULTS_REJECTED,
    payload: {
        searchString,
        error
    },
    error: true
});

export interface ILoadLinkedBuildingsAction extends Action {
    type: BuildingActions.LOAD_LINKED_BUILDINGS;
    payload: {
        siteId: string;
    };
}

export const loadLinkedBuildings = (siteId: string): ILoadLinkedBuildingsAction => ({
    type: BuildingActions.LOAD_LINKED_BUILDINGS,
    payload: {
        siteId
    }
});

export interface ILoadLinkedBuildingsCancelledAction extends Action {
    type: BuildingActions.LOAD_LINKED_BUILDINGS_CANCELLED;
    payload: {
        siteId: string;
    };
}

export const loadLinkedBuildingsCancelled = (siteId: string): ILoadLinkedBuildingsCancelledAction => ({
    type: BuildingActions.LOAD_LINKED_BUILDINGS_CANCELLED,
    payload: {
        siteId
    }
});

export interface ILoadLinkedBuildingsFulfilledAction extends Action {
    type: BuildingActions.LOAD_LINKED_BUILDINGS_FULFILLED;
    payload: {
        siteId: string;
        buildings: IBuilding[];
    };
}

export const loadLinkedBuildingsFulfilled = (
    siteId: string,
    buildings: IBuilding[]
): ILoadLinkedBuildingsFulfilledAction => ({
    type: BuildingActions.LOAD_LINKED_BUILDINGS_FULFILLED,
    payload: {
        siteId,
        buildings
    }
});

export interface ILoadLinkedBuildingsRejectedAction extends Action {
    type: BuildingActions.LOAD_LINKED_BUILDINGS_REJECTED;
    error: true;
    payload: any;
}

export const loadLinkedBuildingsRejected = (
    siteId: string,
    error: any
): ILoadLinkedBuildingsRejectedAction => ({
    type: BuildingActions.LOAD_LINKED_BUILDINGS_REJECTED,
    payload: {
        siteId,
        error
    },
    error: true
});

export interface ILinkBuildingAction extends Action {
    type: BuildingActions.LINK_BUILDING;
    payload: {
        site: ISiteProfile;
        building: IBuilding;
    };
}

export const linkBuilding = (
    site: ISiteProfile,
    building: IBuilding
): ILinkBuildingAction => ({
    type: BuildingActions.LINK_BUILDING,
    payload: {
        site,
        building
    }
});

export interface ILinkBuildingActionFulfilled extends Action {
    type: BuildingActions.LINK_BUILDING_FULFILLED;
    payload: {
        site: ISiteProfile;
        building: IBuilding;
    };
}

export const linkBuildingFulfilled = (
    site: ISiteProfile,
    building: IBuilding
): ILinkBuildingActionFulfilled => ({
    type: BuildingActions.LINK_BUILDING_FULFILLED,
    payload: {
        site,
        building
    }
});

export interface ILinkBuildingActionCancelled extends Action {
    type: BuildingActions.LINK_BUILDING_CANCELLED;
    payload: {
        site: ISiteProfile;
        building: IBuilding;
    };
}

export const linkBuildingCancelled = (
    site: ISiteProfile,
    building: IBuilding
): ILinkBuildingActionCancelled => ({
    type: BuildingActions.LINK_BUILDING_CANCELLED,
    payload: {
        site,
        building
    }
});

export interface ILinkBuildingActionRejected extends Action {
    type: BuildingActions.LINK_BUILDING_REJECTED;
    error: true;
    payload: {
        site: ISiteProfile;
        building: IBuilding;
        error?: any;
    };
}

export const linkBuildingRejected = (
    site: ISiteProfile,
    building: IBuilding,
    error?: any
): ILinkBuildingActionRejected => ({
    type: BuildingActions.LINK_BUILDING_REJECTED,
    payload: {
        site,
        building,
        error
    },
    error: true
});

export interface IUnlinkBuildingAction extends Action {
    type: BuildingActions.UNLINK_BUILDING;
    payload: {
        site: ISiteProfile;
        building: IBuilding;
    };
}

export const unlinkBuilding = (
    site: ISiteProfile,
    building: IBuilding
): IUnlinkBuildingAction => ({
    type: BuildingActions.UNLINK_BUILDING,
    payload: {
        site,
        building
    }
});

export interface IUnlinkBuildingConfirmedAction extends Action {
    type: BuildingActions.UNLINK_BUILDING_CONFIRMED;
    payload: {
        site: ISiteProfile;
        building: IBuilding;
    };
}

export const unlinkBuildingConfirmed = (
    site: ISiteProfile,
    building: IBuilding
): IUnlinkBuildingConfirmedAction => ({
    type: BuildingActions.UNLINK_BUILDING_CONFIRMED,
    payload: {
        site,
        building
    }
});

export interface IUnlinkBuildingActionFulfilled extends Action {
    type: BuildingActions.UNLINK_BUILDING_FULFILLED;
    payload: {
        site: ISiteProfile;
        building: IBuilding;
    };
}

export const unlinkBuildingFulfilled = (
    site: ISiteProfile,
    building: IBuilding
): IUnlinkBuildingActionFulfilled => ({
    type: BuildingActions.UNLINK_BUILDING_FULFILLED,
    payload: {
        site,
        building
    }
});

export interface IUnlinkBuildingActionCancelled extends Action {
    type: BuildingActions.UNLINK_BUILDING_CANCELLED;
    payload: {
        site: ISiteProfile;
        building: IBuilding;
    };
}

export const unlinkBuildingCancelled = (
    site: ISiteProfile,
    building: IBuilding
): IUnlinkBuildingActionCancelled => ({
    type: BuildingActions.UNLINK_BUILDING_CANCELLED,
    payload: {
        site,
        building
    }
});

export interface IUnlinkBuildingActionRejected extends Action {
    type: BuildingActions.UNLINK_BUILDING_REJECTED;
    error: true;
    payload: {
        site: ISiteProfile;
        building: IBuilding;
        error?: any;
    };
}

export const unlinkBuildingRejected = (
    site: ISiteProfile,
    building: IBuilding,
    error?: any
): IUnlinkBuildingActionRejected => ({
    type: BuildingActions.UNLINK_BUILDING_REJECTED,
    payload: {
        site,
        building,
        error
    },
    error: true
});

export type BuildingActionTypes =
    | ISelectBuilding
    | IChangeBuildingSearchValueAction
    | ILoadBuildingSearchResults
    | ILoadBuildingSearchResultsFulfilled
    | ILoadBuildingSearchResultsCancelled
    | ILoadBuildingSearchResultsRejected

    | ILoadLinkedBuildingsAction
    | ILoadLinkedBuildingsCancelledAction
    | ILoadLinkedBuildingsFulfilledAction
    | ILoadLinkedBuildingsRejectedAction

    | ILinkBuildingAction
    | ILinkBuildingActionCancelled
    | ILinkBuildingActionFulfilled
    | ILinkBuildingActionRejected

    | IUnlinkBuildingAction
    | IUnlinkBuildingConfirmedAction
    | IUnlinkBuildingActionCancelled
    | IUnlinkBuildingActionFulfilled
    | IUnlinkBuildingActionRejected;
