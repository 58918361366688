import {
    loadAction,
    createAction,
    updateAction,
    closeAction,
    removeAction,
    clearForm,
    loadMacroAction,
    closeMacroAction,
    updateMacroAction,
    archiveMacroAction,
} from 'actions/actions-v2/action-action-v2';
import { loadPersonsLookup } from 'actions/actions-v2/person-action-v2';
import { loadPerson } from 'actions/actions-v2/user-profile-action-v2';
import { loadCermAssessmentExpertReview } from 'actions/actions-v2/cerm-assessment-action-v3';
import { loadMockDrillReport } from 'actions/actions-v2/mock-drill-report-actions';

export default {
    loadAction,
    createAction,
    updateAction,
    closeAction,
    removeAction,
    clearForm,
    loadPersonsLookup,
    loadPersonProfile: loadPerson,
    loadCermAssessmentExpertReview,
    loadMockDrillReport,
    loadMacroAction,
    updateMacroAction,
    archiveMacroAction,
    closeMacroAction,
};
