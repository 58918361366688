import { IAppAction } from '../app-action';
import { IIncident, IIncidentApproval } from 'models/incident';
import { IIncidentsFilters } from 'models';

export enum IncidentActions {
    LOAD_INCIDENTS = '@@incident/LOAD_INCIDENTS',
    LOAD_INCIDENTS_FULFILLED = '@@incident/LOAD_INCIDENTS_FULFILLED',
    LOAD_INCIDENTS_REJECTED = '@@incident/LOAD_INCIDENTS_REJECTED',

    LOAD_INCIDENT = '@@incident/LOAD_INCIDENT',
    LOAD_INCIDENT_FULFILLED = '@@incident/LOAD_INCIDENT_FULFILLED',
    LOAD_INCIDENT_REJECTED = '@@incident/LOAD_INCIDENT_REJECTED',

    CREATE_INCIDENT = '@@incident/CREATE_INCIDENT',
    CREATE_INCIDENT_FULFILLED = '@@incident/CREATE_INCIDENT_FULFILLED',
    CREATE_INCIDENT_REJECTED = '@@incident/CREATE_INCIDENT_REJECTED',

    UPDATE_INCIDENT = '@@incident/UPDATE_INCIDENT',
    UPDATE_INCIDENT_FULFILLED = '@@incident/UPDATE_INCIDENT_FULFILLED',
    UPDATE_INCIDENT_REJECTED = '@@incident/UPDATE_INCIDENT_REJECTED',

    DELETE_INCIDENT = '@@incident/DELETE_INCIDENT',
    DELETE_INCIDENT_FULFILLED = '@@incident/DELETE_INCIDENT_FULFILLED',
    DELETE_INCIDENT_REJECTED = '@@incident/DELETE_INCIDENT_REJECTED',

    SET_INCIDENT_APPROVAL = '@@incident/SET_INCIDENT_APPROVAL',
    SET_INCIDENT_APPROVAL_FULFILLED = '@@incident/SET_INCIDENT_APPROVAL_FULFILLED',
    SET_INCIDENT_APPROVAL_REJECTED = '@@incident/SET_INCIDENT_APPROVAL_REJECTED',

    LOAD_HISTORY = '@@incident/LOAD_HISTORY',
    LOAD_HISTORY_FULFILLED = '@@incident/LOAD_HISTORY_FULFILLED',
    LOAD_HISTORY_REJECTED = '@@incident/LOAD_HISTORY_REJECTED',

    EXPORT_INCIDENTS = '@@incident/EXPORT_INCIDENTS',
    EXPORT_INCIDENTS_FULFILLED = '@@incident/EXPORT_INCIDENTS_FULFILLED',
    EXPORT_INCIDENTS_REJECTED = '@@incident/EXPORT_INCIDENTS_REJECTED',

    LOAD_SETTINGS = '@@incident/LOAD_SETTINGS',
    LOAD_SETTINGS_FULFILLED = '@@incident/LOAD_SETTINGS_FULFILLED',
    LOAD_SETTINGS_REJECTED = '@@incident/LOAD_SETTINGS_REJECTED',

    CLEAN_UP_INCIDENT = '@@incident/CLEAN_UP_INCIDENT',

    SORT_INCIDENTS = '@@incident/SORT_INCIDENTS',
}

export const loadIncidents = (filters: IIncidentsFilters): IAppAction => ({
    type: IncidentActions.LOAD_INCIDENTS,
    payload: filters,
});

export const loadIncident = (id: string, siteId: string): IAppAction => ({
    type: IncidentActions.LOAD_INCIDENT,
    payload: {
        id,
        shard: siteId,
        siteId,
    },
});

export const createIncident = (incident: IIncident, shouldRedirect: boolean): IAppAction => {
    const reloadUrl = shouldRedirect ? '/Incidents' : '';
    return {
        type: IncidentActions.CREATE_INCIDENT,
        payload: { ...incident, reloadUrl },
    };
};

export const updateIncident = (incident: IIncident, shouldRedirect: boolean): IAppAction => {
    const reloadUrl = shouldRedirect ? '/Incidents' : '';
    return {
        type: IncidentActions.UPDATE_INCIDENT,
        payload: { ...incident, reloadUrl },
    };
};

export const deleteIncident = (id: string, siteId: string): IAppAction => ({
    type: IncidentActions.DELETE_INCIDENT,
    payload: { id, siteId },
});

export const setIncidentApproval = (approval: IIncidentApproval): IAppAction => {
    return {
        type: IncidentActions.SET_INCIDENT_APPROVAL,
        payload: approval,
    };
};

export const exportIncidents = (siteId?: string): IAppAction => ({
    type: IncidentActions.EXPORT_INCIDENTS,
    payload: {
        siteId: siteId ?? '',
    },
});

export const loadHistory = (id: string, siteId: string): IAppAction => ({
    type: IncidentActions.LOAD_HISTORY,
    payload: {
        id,
        siteId,
    },
});

export const loadIncidentSettings = (): IAppAction => ({
    type: IncidentActions.LOAD_SETTINGS,
});

export const cleanUpIncident = () => ({
    type: IncidentActions.CLEAN_UP_INCIDENT,
});

export const onSort = (key: Extract<keyof IIncident, string>, sortAscending: boolean) => ({
    type: IncidentActions.SORT_INCIDENTS,
    payload: { key, sortAscending },
});
