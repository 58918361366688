import * as React from 'react';
import { FormikProps } from 'formik';
import { IIncident } from 'models';
import { IDifference } from 'models/difference';
import EntityHistory from 'components/history/entity-history';
import useTranslate from 'translations/translation-utils';

interface IProps {
    form: FormikProps<Partial<IIncident>>;
    incidentHistory: IDifference[][];
    loadHistory: (id: string, siteId: string) => void;
}

export const IncidentHistoryView: React.FC<IProps> = ({ form, ...props }) => {
    React.useEffect(() => {
        if (form.values && form.values.id) {
            props.loadHistory(form.values.id, form.values.siteId);
        }
    }, [form.values]);
    const translate = useTranslate();
    return (
        <div className="section">
            <div className="column is-12">
                <div className="title is-4 section-title">
                    {translate('IncidentHistory.SectionTitle')}
                </div>
                <EntityHistory entity={form.values} entityHistory={props.incidentHistory} />
            </div>
        </div>
    );
};
