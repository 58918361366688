import { createSelector } from 'reselect';

import { ICreateQuestionSetRootState, IQuestionSetsState } from '../../../reducers/create-question-set';
import { isCreating } from '../../../utils/id-utils';

export const getUpsertQuestionSetState = (state: ICreateQuestionSetRootState) => state.editQuestionSet;

export const isCreatingQuestionSet = createSelector(
    getUpsertQuestionSetState,
    (state: IQuestionSetsState) => {
     return isCreating(state.questionSet);
    }
);
