import React from 'react';
import { IProgressBarModel } from 'components/step-progress-bar/StepProgressBar';
import { IStateResult, State } from '../State';
import { QuestionAirflow } from './question-airflow';

export class QuestionDxType extends State {

    public result: IStateResult;
    private title: string = 'Type of DX cooling system';
    private type: DxType;
    constructor() {
        super();
        this.handleClick = this.handleClick.bind(this);
    }
    public getResult(): IStateResult {
        return this.result;
    }
    public path(): string {
        return 'QuestionDxType';
    }
    public display(): JSX.Element {
        return (
            <div >
                <div className="is-size-3  has-text-centered">
                    {this.title}
                </div>
                <p className="has-text-centered">
                    There are two major types of DX cooling system from the perspective of selecting a benchmark model
                </p>
                <hr />
                <div className="formBody">
                    <div className="field">
                        <div className="control">
                            <input
                                className="radioButton"
                                type="radio"
                                name="answer"
                                id={DxType.SplitDx}
                                value={DxType.SplitDx}
                                onClick={this.handleClick}
                                checked={this.type === DxType.SplitDx}
                            />
                            <label htmlFor={DxType.SplitDx} className="radio is-size-5">
                                {DxType.SplitDx.toString()}
                            </label>
                            <p>
                                The internal cooling compressor in your CRAC / CRAH units has gas pipework to an external
                                fan cooled condenser, these are typically one condenser per CRAC / CRAH
                            </p>
                        </div>
                    </div>
                    <div className="field">
                        <div className="control">
                            <input
                                className="radioButton"
                                type="radio"
                                name="answer"
                                id={DxType.GlycolDx}
                                value={DxType.GlycolDx}
                                onClick={this.handleClick}
                                checked={this.type === DxType.GlycolDx}
                            />
                            <label htmlFor={DxType.GlycolDx} className="radio is-size-5">
                                {DxType.GlycolDx.toString()}
                            </label>
                            <p>
                                The internal cooling compressor in your CRAC / CRAH units is connected to a building glycol
                                (water) loop with shared external coolers, these may be dry coolers, cooling towers or hybrid
                            </p>
                        </div>
                    </div>
                </div>
                <hr />
                {this.context.footer(this.handle.bind(this))}
            </div>);
    }
    public handle() {
        this.result = {
            title: this.title,
            value: this.type.toString()
        };

        this.context.transitionTo(new QuestionAirflow());
    }
    public getProgressBarModel(): IProgressBarModel[] {
        return [
            { isActive: true, name: 'Type of site' },
            { isActive: true, name: 'Question 1' },
            { isActive: false, name: 'Question 2' },
            { isActive: false, name: 'Question 3' },
            { isActive: false, name: 'Question 4' },
            { isActive: false, name: 'Summary' },
        ] as IProgressBarModel[];
    }
    private handleClick(event: any) {
        this.context.isDisable = false;
        this.type = event.currentTarget.value as DxType;
        this.context.handleRender();
    }
}

export enum DxType {
    SplitDx = 'Split DX',
    GlycolDx = 'Glycol loop',
}
