import * as React from 'react';
import { IClient } from 'models/client';
import { ISiteProfile } from 'models/site-profile';
import { ClientSelector } from '../clientSelector';
import './attach-client-form.scss';
import { Button } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';

interface IProps {
    selectedSite: ISiteProfile;
    selectedClient: IClient;
    isLinkButtonEnabled: boolean;
    isLinkButtonSubmitting: boolean;
    onLink: (site: ISiteProfile, client: IClient) => void;
}

export const AttachClientForm: React.FC<IProps> = (props) => {
    const translate = useTranslate();

    if (!props.selectedSite) {
        return null;
    }

    const onLink = () => props.onLink(
        props.selectedSite,
        props.selectedClient
    );

    return (
        <>
            <h2 className="title is-5">{props.selectedSite.name}</h2>

            <h3 className="title is-5">
                Current Client: {props.selectedSite.clientName || 'none'}
            </h3>

            <p>{translate('SiteRelationShips.AttachClientForm.Paragraph.Message')}</p>

            <div className="AttachClientForm__form">
                <ClientSelector />
                <Button
                    onClick={onLink}
                    isLoading={props.isLinkButtonSubmitting}
                    disabled={!props.isLinkButtonEnabled}
                >
                    {translate('SiteRelationShips.AttachClientForm.Button.Change')}
                </Button>
            </div>
        </>
    );
};
