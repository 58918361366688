import { Formik, FormikProps } from 'formik';
import _ from 'lodash';
import * as React from 'react';
import Select from 'react-select';
import { Checkbox } from 'components/form/Checkbox';
import { IKeyValue } from 'models/key-value';
import { ISiteProfile } from 'models/site-profile';
import { Toolbar, ToolbarGroupRight, FloatingButton } from 'components/v2/components';
import { Pagination } from '../../common-pagination/pagination';
import { ICommonLookupModel } from 'models/common-lookup-model';
import { getUniqueItems } from 'components/common/common';
import { SiteFeatures } from 'actions/site-feature';
import useTranslate from 'translations/translation-utils';
import { IOperatingPlatform } from 'models/operating-platform-model';

interface IProps {
    sites: ISiteProfile[];
    clients: string[];
    lineOfBusinesses: ICommonLookupModel[];
    regions: string[];
    features: string[];
    operatingPlatforms: IOperatingPlatform[];
}

export interface IActionProps {
    onSubmit: (request: Array<IKeyValue<string[]>>) => void;
}

export interface IMergeProps extends IProps, IActionProps { }

export const SiteFeaturesEdit: React.FC<IMergeProps> = (props) => {
    const [sites, setSites] = React.useState<ISiteProfile[]>([]);
    const [clientFilters, setClientFilters] = React.useState<string[]>([]);
    const [lineOfBusinessFilters, setLineOfBusinessFilters] = React.useState<string[]>([]);
    const [regionsFilters, setRegionsFilters] = React.useState<string[]>([]);
    const [operatingPlatformFilters, setOperatingPlatformFilter] = React.useState<string[]>([]);
    const [isDirty, setIsDirty] = React.useState<boolean>(false);
    const [pageSize, setpageSize] = React.useState<number>(10);
    const excludeFeatures = [SiteFeatures.Incidents.toString(), SiteFeatures.CDNA.toString(),
    SiteFeatures.RiskProfiles.toString(), SiteFeatures.CriticalSpares.toString(),
    SiteFeatures.MockDrills.toString(), SiteFeatures.SiteDocuments.toString(),
    SiteFeatures.OpportunityQuestionnaire.toString()
    ]

    const translate = useTranslate();

    React.useEffect(() => {
        if (props.sites) {
            setSites(props.sites);
        }
    }, [props.sites]);

    React.useEffect(() => {
        if (props.sites) {
            setFilteredSites();
            setCurrentPage(1);
        }
    }, [lineOfBusinessFilters, clientFilters, regionsFilters, operatingPlatformFilters, props.sites]);

    const [currentPage, setCurrentPage] = React.useState(1);
    const currentTableData = React.useMemo(() => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return sites.slice(firstPageIndex, lastPageIndex);
    }, [pageSize, currentPage, sites]);

    const loadSiteFeaturesRow = (site: ISiteProfile) => {
        return (
            <tbody>
                <tr>
                    <td>{site.clientAndSiteName}</td>
                    <td>{site.lineOfBusiness}</td>
                    <td>{site.buildings && _.uniq(site.buildings.map((m) => m.region))}</td>
                    <td>{site.clientName}</td>
                    {props.features &&
                        props.features.filter(x => !excludeFeatures.includes(x)).map((feature, key) => {
                            return (
                                <td key={`individual_checkbox_${key}`}>
                                    <Checkbox
                                        id={`${site.id}_${key.toString()}`}
                                        key={key}
                                        checked={
                                            site.siteFeatures &&
                                            site.siteFeatures.indexOf(feature) !== -1
                                        }
                                        onChange={() => addRemoveFeature(site.id, feature)}
                                    />
                                </td>
                            );
                        })}
                </tr>
            </tbody>
        );
    };

    const onSubmit = () => {
        const updateItems = sites.filter(
            (m) => !_.isEqual(m.siteFeatures, props.sites.filter((f) => f.id === m.id)[0].siteFeatures)
        );

        props.onSubmit(
            updateItems.map((m) => {
                return {
                    key: m.id,
                    value: m.siteFeatures,
                };
            })
        );

        setIsDirty(false);
    };

    const onOperatingPlatformChange = (operatingPlatform: Array<IKeyValue<string>>) => {
        setOperatingPlatformFilter(operatingPlatform ? operatingPlatform.map((x) => x.value) : []);
    };

    const getLineOfBusinesses = () => {
        const lineOfBusiness = operatingPlatformFilters && operatingPlatformFilters.length > 0
            ? props.lineOfBusinesses
                .filter((x) => operatingPlatformFilters.includes(x.operatingPlatform))
                .map((x) => ({ value: x.value, label: x.value }))
            : props.lineOfBusinesses;

        return getUniqueItems(lineOfBusiness);
    }

    const filterLineOfBusiness = (lineOfBusinesses: Array<IKeyValue<string>>) => {
        setLineOfBusinessFilters(lineOfBusinesses ? lineOfBusinesses.map((x) => x.value) : []);
    };

    const filterRegions = (regions: Array<IKeyValue<string>>) => {
        setRegionsFilters(regions ? regions.map((x) => x.key) : []);
    };

    const filterClientNames = (clients: Array<IKeyValue<string>>) => {
        setClientFilters(clients ? clients.map((x) => x.key) : []);
    };

    const addRemoveMultipleFeatures = (feature: string) => {
        if (sites.every((e) => e.siteFeatures.indexOf(feature) !== -1)) {
            // remove
            setSites(sites ? sites.map(m => {
                return { ...m, siteFeatures: m.siteFeatures ? [].concat(m.siteFeatures).filter(f => f !== feature) : [] }
            }) : []);
        } else {
            // Add
            setSites(sites ? sites.map(m => {
                const newFeatures = m.siteFeatures ? m.siteFeatures.filter((f) => f !== feature) : [];
                newFeatures.push(feature);
                return { ...m, siteFeatures: newFeatures }
            }) : []);
        }

        setIsDirty(true);
    };

    const addRemoveFeature = (siteId: string, feature: string) => {
        setSites(sites ?
            sites.map(m => {
                if (m.id !== siteId) {
                    return m;
                }
                if (m.id === siteId && m.siteFeatures.indexOf(feature) !== -1) {
                    return { ...m, siteFeatures: m.siteFeatures ? [].concat(m.siteFeatures).filter(f => f !== feature) : [] }
                }
                else {
                    const newFeatures = m.siteFeatures ? m.siteFeatures.filter((f) => f !== feature) : [];
                    newFeatures.push(feature);
                    return { ...m, siteFeatures: newFeatures }
                }
            })
            : []
        );

        setIsDirty(true);
    };

    const setFilteredSites = () => {
        let filteredSites = [].concat(props.sites);

        if (operatingPlatformFilters.length > 0) {
            filteredSites = [].concat(filteredSites).filter((f) => operatingPlatformFilters.indexOf(f.operatingPlatform) !== -1);
        }
        if (lineOfBusinessFilters.length > 0) {
            filteredSites = [].concat(filteredSites).filter((f) => lineOfBusinessFilters.indexOf(f.lineOfBusiness) !== -1);
        }
        if (regionsFilters.length > 0) {
            filteredSites = [].concat(filteredSites).filter((f) => f.buildings && f.buildings.some((e) => regionsFilters.indexOf(e.region) !== -1));
        }
        if (clientFilters.length > 0) {
            filteredSites = [].concat(filteredSites).filter((f) => clientFilters.indexOf(f.clientName) !== -1);
        }

        setSites(filteredSites);
    };

    const getPageSizeOption = () => {
        return [
            {
                key: '10',
                value: '10',
                label: '10',
            },
            {
                key: '50',
                value: '50',
                label: '50',
            }, {
                key: '100',
                value: '100',
                label: '100',
            },
            {
                key: 'All',
                value: 'All',
                label: translate('Globals.Label.All'),
            },
        ];
    };

    const handlePageSizeChange = (e: any) => {
        let pageSize = parseInt(e.currentTarget.value);

        if (e.currentTarget.value == 'All') {
            pageSize = sites.length;
        }

        setpageSize(pageSize);
    };

    const renderForm = (formikBag: FormikProps<ISiteProfile[]>) => {
        return (
            <form
                className="site-features-edit-table"
                id="site-features-edit-table"
                onSubmit={formikBag.handleSubmit}
            >
                <div className="columns">
                    <div className="column is-12">
                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">
                                    {translate('Globals.Label.OperatingPlatform')}
                                </label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control">
                                        <Select
                                            id="operatingPlatform"
                                            classNamePrefix="dropdown-multi"
                                            options={props.operatingPlatforms && props.operatingPlatforms.map((x) => ({
                                                value: x.value,
                                                label: x.value,
                                            }))}
                                            onChange={onOperatingPlatformChange}
                                            isMulti={true}
                                            value={operatingPlatformFilters.map(
                                                (x) => ({
                                                    key: x,
                                                    value: x,
                                                    label: x,
                                                })
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">
                                    {translate('Globals.Label.LineOfBusiness')}
                                </label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control">
                                        <Select
                                            id="lineOfBusinesses"
                                            classNamePrefix="dropdown-multi"
                                            options={getLineOfBusinesses()}
                                            isMulti={true}
                                            onChange={filterLineOfBusiness}
                                            value={lineOfBusinessFilters.map(
                                                (x) => ({
                                                    key: x,
                                                    value: x,
                                                    label: x,
                                                })
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">
                                    {translate('Globals.Label.Regions')}
                                </label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control">
                                        <Select
                                            id="regions"
                                            classNamePrefix="dropdown-multi"
                                            options={props.regions.map((x) => ({
                                                key: x,
                                                value: x,
                                                label: x,
                                            }))}
                                            isMulti={true}
                                            onChange={filterRegions}
                                            value={regionsFilters.map((x) => ({
                                                key: x,
                                                value: x,
                                                label: x,
                                            }))}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">
                                    {translate('Globals.Label.Clients')}
                                </label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control">
                                        <Select
                                            id="Clients"
                                            classNamePrefix="dropdown-multi"
                                            options={props.clients.map((x) => ({
                                                key: x,
                                                value: x,
                                                label: x,
                                            }))}
                                            isMulti={true}
                                            onChange={filterClientNames}
                                            value={clientFilters.map((x) => ({
                                                key: x,
                                                value: x,
                                                label: x,
                                            }))}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='record-count'>
                            <label >{translate('Globals.Label.Records')}: </label>{sites.length}
                        </div>

                        {sites.length > 0
                            ? (<div>
                                <table className="table table-sticky-header">
                                    <thead>
                                        <tr>
                                            <th>{translate('Cerm.SiteSelect.Cloumns.SiteName')}</th>
                                            <th>{translate('Cerm.AssessmentFilters.Labels.LineOfBusiness')}</th>
                                            <th>{translate('Cerm.AssessmentFilters.Labels.Regions')}</th>
                                            <th>{translate('Globals.Label.Clients')}</th>
                                            {props.features &&
                                                props.features.filter(x => !excludeFeatures.includes(x)).map((feature, key) => {
                                                    return (
                                                        <th key={key} className="tick-box">
                                                            {feature}
                                                            <Checkbox
                                                                id={`main_${feature}_${key.toString()}`}
                                                                key={key}
                                                                // tslint:disable-next-line: max-line-length
                                                                checked={sites.every(
                                                                    (e) =>
                                                                        e.siteFeatures.indexOf(
                                                                            feature
                                                                        ) !== -1
                                                                )}
                                                                // tslint:disable-next-line: max-line-length
                                                                onChange={() => addRemoveMultipleFeatures(
                                                                    feature)}
                                                            />
                                                        </th>
                                                    );
                                                })}
                                        </tr>
                                    </thead>
                                    {currentTableData.map((site) => {
                                        return loadSiteFeaturesRow(site);
                                    })}
                                </table>
                                <div className='div-display-inline'>
                                    <label className="label row-per-page">
                                        {translate('Globals.Label.RowsPerPage')}
                                    </label>
                                    <select
                                        className='pagination-select'
                                        name="page-size" id="page_size"
                                        onChange={handlePageSizeChange}>
                                        {getPageSizeOption().map((x) => {
                                            return <option key={x.key} value={x.value}>{x.label}</option>
                                        })}
                                    </select>
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={sites.length}
                                        pageSize={pageSize}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </div>
                            </div>
                            ) : (
                                <div>
                                    {translate('SiteFeatures.Edit.Div.NoRecordFound')}
                                </div>
                            )
                        }

                        <Toolbar type="save">
                            <ToolbarGroupRight>
                                <FloatingButton
                                    id="save-site-features"
                                    onClick={onSubmit}
                                    type="button"
                                    float={isDirty}
                                    disabled={!isDirty}
                                    tooltip={translate('SiteFeatures.Edit.Div.Message')}
                                >
                                    {translate('SiteFeatures.Edit.Div.Update')}
                                </FloatingButton>
                            </ToolbarGroupRight>
                        </Toolbar>
                    </div>
                </div>
            </form>
        );
    };

    return <Formik initialValues={props.sites} onSubmit={onSubmit} render={renderForm} />;
};
