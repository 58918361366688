import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';

const getLookupActionPriorities = (state: IRootState) => state.app.lookupActionPriorities;
const getActionCategories = (state: IRootState) => state.app.lookupActionCategories;

export default createSelector(
    getLookupActionPriorities,
    getActionCategories,
    (lookupActionPriorities,actionCategories) => ({
        lookupActionPriorities,
        actionCategories
    })
);
