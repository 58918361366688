import { IRiskProfileQuestionnaire, IRiskProfileMasterQuestionnaire } from 'models';

export interface IRiskProfileQuestionnaireState {
    riskProfileMasterQuestionnaire: IRiskProfileMasterQuestionnaire | null;
    riskProfileQuestionnaire: IRiskProfileQuestionnaire | null;
    isLoading: boolean;
    isExporting: boolean;
}

const defaultState: IRiskProfileQuestionnaireState = {
    riskProfileMasterQuestionnaire: null,
    riskProfileQuestionnaire: null,
    isLoading: false,
    isExporting: false
};

// tslint:disable-next-line:arrow-return-shorthand
export const getDefaultRiskProfileQuestionnaireContainerState = (options?: any) => { return {...defaultState, ...options }; };
