import { IAppAction } from '../app-action';
import { IFaqs } from 'models/faqs-model';

export enum IFaqsActions {
    LOAD_FAQS = '@@Faqs/LOAD_FAQS',
    LOAD_FAQS_FULFILLED = '@@Faqs/LOAD_FAQS_FULFILLED',
    LOAD_FAQS_REJECTED = '@@Faqs/LOAD_FAQS_REJECTED',

    CREATE_FAQS = '@@Faqs/CREATE_FAQS',
    CREATE_FAQS_FULFILLED = '@@Faqs/CREATE_FAQS_FULFILLED',
    CREATE_FAQS_REJECTED = '@@Faqs/CREATE_FAQS_REJECTED',

    UPDATE_FAQS = '@@Faqs/UPDATE_FAQS',
    UPDATE_FAQS_FULFILLED = '@@Faqs/UPDATE_FAQS_FULFILLED',
    UPDATE_FAQS_REJECTED = '@@Faqs/UPDATE_FAQS_REJECTED',
}

export const loadFaqs = (language: string, operatingPlatform: string): IAppAction => ({
    type: IFaqsActions.LOAD_FAQS,
    payload: { language, operatingPlatform },
});

export const updateFaqs = (model: IFaqs): IAppAction => ({
    type: IFaqsActions.UPDATE_FAQS,
    payload: model,
});
export const createFaqs = (model: IFaqs): IAppAction => ({
    type: IFaqsActions.UPDATE_FAQS,
    payload: model,
});
