import React from 'react';
import {
    IToolbarIconItem,
    ToolbarIconType,
    IconToolbar,
} from '../../../components/v2/toolbar/IconToolbar';
import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { IKeyValue } from 'models/key-value';
import { checkRole } from 'utils/permission-utils';
import useTranslate from 'translations/translation-utils';

interface IProps {
    permissions?: Array<IKeyValue<string>>;
    isExporting?: boolean;
    onExport?: () => void;
}

const RomonetToolbarView: React.FC<IProps> = ({ isExporting, onExport, permissions }) => {

    const translate = useTranslate();

    const onClickAll = () => {
        onExport();
    };

    const getToolbarItems = (): IToolbarIconItem[] => {
        return [
            !checkRole('Client', permissions) && {
                id: 'export-button',
                title: translate('IncidentPage.Title.Export'),
                type: ToolbarIconType.download,
                isDisabled: isExporting,
                onClick: onClickAll,
            },
        ];
    };

    return <IconToolbar items={getToolbarItems()} />;
};

const mapStateToProps = (state: IRootState) => {
    return {
        siteId: state.app.siteId,
        permissions: state.app.permissions,
        isExporting: state.romonetState.isExporting,
    };
};

export const RomonetToolbar = connect(mapStateToProps)(RomonetToolbarView);
