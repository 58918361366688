import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { IFaqsActions } from 'actions/actions-v2/faqs-action-v2';

const controllerName = 'Faqs';
const apiPath = `/api/${controllerName}`;

const getFaqsActions = {
    action: IFaqsActions.LOAD_FAQS,
    actionFulfilled: IFaqsActions.LOAD_FAQS_FULFILLED,
    actionRejected: IFaqsActions.LOAD_FAQS_REJECTED,
} as IActionType;

const updateFaqsActions = {
    action: IFaqsActions.UPDATE_FAQS,
    actionFulfilled: IFaqsActions.UPDATE_FAQS_FULFILLED,
    actionRejected: IFaqsActions.UPDATE_FAQS_REJECTED,
} as IActionType;

const createFaqsActions = {
    action: IFaqsActions.CREATE_FAQS,
    actionFulfilled: IFaqsActions.CREATE_FAQS_FULFILLED,
    actionRejected: IFaqsActions.CREATE_FAQS_REJECTED,
} as IActionType;

const loadFaqsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getFaqsActions).get(`${apiPath}`);

const createFaqsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createFaqsActions).post(`${apiPath}`);

const updateFaqsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateFaqsActions).put(`${apiPath}`);

export const IFaqsEpicsV2 = combineEpics(loadFaqsEpic, createFaqsEpic, updateFaqsEpic);
