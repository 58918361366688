import classnames from 'classnames';
import { isNil } from 'lodash';
import * as React from 'react';

import { BulmaColor } from '../../enums/BulmaColor';

import './Form.scss';

export interface IProps {
    bulmaColor?: BulmaColor;
    isShown?: boolean;
}

const getCss = (bulmaColor?: BulmaColor) => classnames(
    'help',
    {
        [`is-${bulmaColor}`]: Boolean(bulmaColor)
    }
);

export const Help: React.FC<React.PropsWithChildren<IProps>> = ({
    bulmaColor,
    isShown,
    children
}) => isShown && !isNil(children)
        ? (
            <p className={getCss(bulmaColor)}>
                {children}
            </p>
        )
        : null;

Help.defaultProps = {
    isShown: false
};
