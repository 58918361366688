import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from './../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { SiteSkillsActions } from 'actions/actions-v2';
import { ISiteSkills } from 'models';

const controllerName = 'siteSkills';

const createActions = {
    action: SiteSkillsActions.CREATE_SITE_SKILLS,
    actionFulfilled: SiteSkillsActions.CREATE_SITE_SKILLS_FULFILLED,
    actionRejected: SiteSkillsActions.CREATE_SITE_SKILLS_REJECTED
} as IActionType;

const updateActions = {
    action: SiteSkillsActions.UPDATE_SITE_SKILLS,
    actionFulfilled: SiteSkillsActions.UPDATE_SITE_SKILLS_FULFILLED,
    actionRejected: SiteSkillsActions.UPDATE_SITE_SKILLS_REJECTED
} as IActionType;

const getActions = {
    action: SiteSkillsActions.LOAD_SITE_SKILLS,
    actionFulfilled: SiteSkillsActions.LOAD_SITE_SKILLS_FULFILLED,
    actionRejected: SiteSkillsActions.LOAD_SITE_SKILLS_REJECTED
} as IActionType;

const deleteActions = {

} as IActionType;

const createSiteSkillsEpic: Epic<IAppAction, IAppAction> = (action$, state$) => new BaseEpic(action$, state$, createActions).post<ISiteSkills>(`/api/${controllerName}`);
const updateSiteSkillsEpic: Epic<IAppAction, IAppAction> = (action$, state$) => new BaseEpic(action$, state$, updateActions).put<ISiteSkills>(`/api/${controllerName}`);
const loadSiteSkillsEpic: Epic<IAppAction, IAppAction> = (action$, state$) => new BaseEpic(action$, state$, getActions).get<ISiteSkills[]>(`/api/${controllerName}`);
const deleteSiteSkillsEpic: Epic<IAppAction, IAppAction> = (action$, state$) => new BaseEpic(action$, state$, deleteActions).deleteById<string>(`/api/${controllerName}`);

export const siteSkillsEpicsV2 = combineEpics(
    createSiteSkillsEpic,
    updateSiteSkillsEpic,
    loadSiteSkillsEpic,
    deleteSiteSkillsEpic
);
