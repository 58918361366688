import {
    loadAttachments,
    onClose,
    onDelete,
    onShowAddAttachmentModal as onShowAddAttachmentModal,
} from 'actions/cerm-assessment-actions';

export default {
    loadAttachments,
    onClose,
    onShowAddAttachmentModal,
    onDelete
};
