import React, { useState } from 'react';
import { SelectField } from 'components/form/fields/select-field';
import { RomonetToolbar } from './romonet-toolbar';
import { IKeyValue } from 'models/key-value';
import useTranslate from 'translations/translation-utils';
import { getReportTypes, getDateRange } from 'models/romonet/romonet-page';
import { isEmpty } from 'lodash';
import { replaceCharacter } from 'utils/string-utils';
import { ISiteProfile } from 'models/site-profile';
import { Helmet } from 'react-helmet';

interface IProps {
    title: string;
    siteId?: string;
    facilities?: Array<IKeyValue<string>>;
    romonetReportType: string;
    romonetDateFilter: Array<IKeyValue<string>>;
    romonetReport: string;
    isMultiReport?: boolean;
    site: ISiteProfile;
    downloadReports?: (reportType: string, facility: string, date: string) => void;
    loadReport?: (reportType: string, facility: string, date: string) => void;
    loadDateFilters: (reportType: string, facility) => void;
    loadSite?: (id: string) => void;
    resetReport?: () => void;
}

const RomonetHeader: React.FC<IProps> = ({
    title,
    siteId,
    facilities,
    romonetReportType,
    romonetReport,
    romonetDateFilter,
    isMultiReport,
    site,
    downloadReports,
    loadReport,
    loadDateFilters,
    loadSite,
    resetReport,
}) => {
    const [selectedFacilityId, setSelectedFacilityId] = useState(null);
    const [selectedDateId, setSelectedDateId] = useState(null);
    const [selectedReportType, setSelectedReportType] = useState(null);
    const translate = useTranslate();

    React.useEffect(() => {
        resetReport();
        setSelectedReportType(null);
        setSelectedDateId(null);
    }, []);

    React.useEffect(() => {
        resetReport();
        setSelectedReportType(null);
        setSelectedDateId(null);
        siteId && loadSite(siteId);
    }, [siteId]);

    React.useEffect(() => {
        if (!isEmpty(facilities)) {
            setSelectedFacilityId(facilities[0].value);
        }
    }, [site]);

    React.useEffect(() => {
        setSelectedReportType(null);
        if (!isEmpty(facilities) && !isMultiReport && selectedFacilityId) {
            setSelectedDateId(null);
            loadDateFilters(romonetReportType, selectedFacilityId);
        } else if (isMultiReport && selectedFacilityId) {
            setSelectedReportType(getReportTypes[0].value);
        }
    }, [selectedFacilityId]);

    React.useEffect(() => {
        if (!isEmpty(romonetDateFilter)) {
            setSelectedDateId(romonetDateFilter[0]);
        }
    }, [romonetDateFilter]);

    React.useEffect(() => {
        const report = isMultiReport ? selectedReportType : romonetReportType;
        if (
            selectedDateId &&
            romonetDateFilter &&
            romonetDateFilter.length !== 0 &&
            selectedFacilityId
        ) {
            loadReport(report, selectedFacilityId, selectedDateId);
        }
    }, [selectedDateId]);

    React.useEffect(() => {
        if (isMultiReport && selectedReportType && selectedFacilityId) {
            setSelectedDateId(null);
            loadDateFilters(selectedReportType, selectedFacilityId);
        }
    }, [selectedReportType]);

    const selectedFacilityValue = (
        // tslint:disable-next-line: no-shadowed-variable
        facilities: Array<IKeyValue<string>>,
        // tslint:disable-next-line: no-shadowed-variable
        selectedFacilityId: string
    ) => {
        if (!selectedFacilityId || isEmpty(facilities)) {
            return null;
        }
        const facility = facilities.find((f) => f.value === selectedFacilityId);
        if (!facility) {
            return null;
        }
        return { value: facility.value, label: facility.label, key: facility.key };
    };

    const selectedDateValue = (
        // tslint:disable-next-line: no-shadowed-variable
        romonetDates: Array<IKeyValue<string>>,
        // tslint:disable-next-line: no-shadowed-variable
        selectedDateId: string
    ) => {
        if (!selectedDateId || isEmpty(facilities)) {
            return null;
        }
        const romonetDate = romonetDates && romonetDates.find((f) => f.key === selectedDateId);
        if (!romonetDate) {
            return null;
        }
        return {
            value: romonetDate.value,
            label: replaceCharacter(romonetDate.label, '.html'),
            key: romonetDate.key,
        };
    };

    const selectedReportValue = (
        // tslint:disable-next-line: no-shadowed-variable
        reports: Array<IKeyValue<string>>,
        // tslint:disable-next-line: no-shadowed-variable
        selectedReportType: string
    ) => {
        if (!selectedReportType || isEmpty(reports)) {
            return null;
        }

        const report = reports.find((f) => f.value === selectedReportType);
        if (!report) {
            return null;
        }

        return { value: report.value, label: translate(report.label), key: report.key };
    };
    const onFacilityChange = (field: { value: string; label: string }) => {
        if (selectedFacilityId !== field.value) {
            resetReport();
            setSelectedReportType(null);
            setSelectedDateId(null);
        }

        setSelectedFacilityId(field.value);
    };

    const onDateChange = (field: { value: string; label: string }) => {
        setSelectedDateId(field.value);
    };

    const onReportTypeChange = (field: { value: string; label: string }) => {
        if (selectedReportType !== field.value) {
            setSelectedDateId(null);
            resetReport();
        }
        setSelectedReportType(field.value);
    };

    const onExport = () => {
        const report = isMultiReport ? selectedReportType : romonetReportType;
        downloadReports(report, selectedFacilityId, selectedDateId);
    };
    return (
        <div className="page romonet-layout">
            <div className="columns">
                <div className={`column ${isMultiReport ? 'is-3' : 'is-5'}`}>
                    {title && <h1>{title}</h1>}
                    <Helmet>
                        <title>Quantum - {title}</title>
                    </Helmet>
                </div>
                <div className="column is-3">
                    <SelectField
                        id="facilityId"
                        label={translate('Romonet.Label.Faility')}
                        className="select-facility"
                        value={selectedFacilityValue(facilities, selectedFacilityId)}
                        options={facilities.map((m) => ({
                            value: m.value,
                            label: m.label,
                            key: m.key,
                        }))}
                        handleChange={onFacilityChange}
                    />
                </div>
                {isMultiReport && (
                    <div className="column is-2">
                        <SelectField
                            id="facilityId"
                            label={translate('Romonet.Label.ReportType')}
                            className="select-facility"
                            value={selectedReportValue(getReportTypes, selectedReportType)}
                            options={
                                selectedFacilityId
                                    ? getReportTypes.map((m) => ({
                                          ...m,
                                          label: translate(m.label),
                                      }))
                                    : []
                            }
                            handleChange={onReportTypeChange}
                        />
                    </div>
                )}
                <div className="column is-2">
                    <div className="columns is-mobile romonet-date">
                        <SelectField
                            id="dateId"
                            label={translate('Romonet.Label.DateRange')}
                            className="select-date"
                            value={selectedDateValue(
                                getDateRange(romonetDateFilter),
                                selectedDateId
                            )}
                            options={
                                getDateRange(romonetDateFilter) &&
                                getDateRange(romonetDateFilter).map((m) => ({
                                    value: m.value,
                                    label: replaceCharacter(m.label, '.html'),
                                    key: m.key,
                                }))
                            }
                            handleChange={onDateChange}
                        />
                    </div>
                </div>
                <div className="column is-2">
                    {romonetReport && <RomonetToolbar onExport={onExport} />}
                </div>
            </div>
        </div>
    );
};

export default RomonetHeader;
