import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { connect } from 'react-redux';
import RomonetHeader from './romonet-header';
import { loadSite } from 'actions/actions-v2/site-actions-v2';
import {
    downloadReports,
    loadReport,
    loadDateFilters,
    resetReport,
} from 'actions/actions-v2/romonet-actions';

const getSiteid = (state: IRootState) => state.app.siteId;
const getRomonetDateFilter = (state: IRootState) => state.romonetState.romonetDateFilter;

const getFacilities = (state: IRootState) =>
    state.siteState.site.buildings.map((obj) => ({
        key: obj.id,
        label: obj.buildingName,
        value: obj.buildingCode,
    }));
const getSite = (state: IRootState) => state.siteState.site;
const getReport = (state: IRootState) => state.romonetState.romonetReport;

const mapStateToProps = createSelector(
    getSiteid,
    getFacilities,
    getSite,
    getRomonetDateFilter,
    getReport,
    (siteId, facilities, site, romonetDateFilter, romonetReport) => ({
        siteId,
        facilities,
        site,
        romonetDateFilter,
        romonetReport,
    })
);

const mapDispatchToProps = {
    loadSite,
    downloadReports,
    loadReport,
    loadDateFilters,
    resetReport,
};

export const RomonetHeaderContainer = connect(mapStateToProps, mapDispatchToProps)(RomonetHeader);
