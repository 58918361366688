import * as React from 'react';
import { connect } from 'react-redux';
import { IKeyValue } from 'models';
import selectors from './selectors';
import useTranslate from 'translations/translation-utils';

interface IProps {
    selectedSite: IKeyValue<string>;
}

const SiteName: React.FC<IProps> = ({ selectedSite }) => {
    const translate = useTranslate();
    return <>{selectedSite ? selectedSite.value : translate('SideNavBar.Labels.Site')+"..."}</>;
};

export default connect(selectors)(SiteName);
