import * as React from 'react';
import { compact } from 'lodash';
import { Loader } from 'components/loader';
import { IPerson } from 'models/person';
import { ISiteProfile } from 'models/site-profile';
import './linked-people.scss';
import { Button } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';

export interface IProps {
    site: ISiteProfile;
    people: IPerson[];
    loading: boolean;
    personIdsBeingRemoved: string[];
    onUnlink: (site: ISiteProfile, person: IPerson) => void;
}

export const LinkedPeople: React.FC<IProps> = (props) => {
    const translate = useTranslate();

    if (!props.site) {
        return null;
    }

    const onUnlink = (person: IPerson) => props.onUnlink(props.site, person);

    const getPersonText = (person: IPerson) => compact([
        person.firstName,
        person.lastName
    ]).join(' ');

    const getRow = (
        person: IPerson,
        isBeingRemoved: boolean,
        onUnlink: (person: IPerson) => void
    ) => {
        const onClick = () => onUnlink(person);
        return (
            <div key={person.id} className="LinkedPeople__row">
                <div className="LinkedPeople__row-item">{getPersonText(person)}</div>
                <Button onClick={onClick} isLoading={isBeingRemoved} disabled={isBeingRemoved}>
                    {translate('IncidentRiskActions.Trash.Remove')}
                </Button>
            </div>
        );
    };

    return (
        <Loader className="LinkedPeople" loading={props.loading}>
            <h3 className="title is-5">{translate('LinkedPeople.Loader.Title.RemovePeople')}</h3>

            {props.people.length > 0 && (
                <>
                    <p>{translate('LinkedPeople.Loader.Title.Message')}</p>

                    {props.people.map((x) => getRow(
                        x,
                        props.personIdsBeingRemoved.includes(x.id),
                        onUnlink
                    ))}
                </>
            )}

            {props.people.length === 0 && (
                <p>{translate('LinkedPeople.Loader.Title.NoAttachedPeople')}</p>
            )}
        </Loader>
    );
};
