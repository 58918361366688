export function GetPercentage(a?: number) {
    if (a === null) {
        return '-';
    }

    return `${Math.round(a)}%`;
}

export function GetScorePercentage(a?: number) {
    if (a === null) {
        return '-';
    }
    if ((a >= 99) && (a < 100))
        return `${Math.floor(a)}%`;
    return `${Math.round(a)}%`;
}

export const GetDisplayString = (value: number): string =>
    !value || value <= 0 ? '-' : value.toString();
