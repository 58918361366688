import * as React from 'react';
import Page from 'components/v2/page/Page';
import Attachments from 'components/attachemnts/attachments';
import { history } from 'routes/App';
import { RouteComponentProps } from 'react-router';
import { IMasterProcessStep } from 'models/master-process-step/master-process-step';
import {
    Toolbar,
    ToolbarGroup,
    Button,
    ToolbarGroupRight,
    Panel,
    FloatingButton,
} from 'components/v2/components';
import { IProcessStepAnswer } from 'models/cerm/process-step-answer';
import { Entity } from 'models/entity';
import { TextAreaField } from 'components/form/fields/textarea-field';
import Comments from 'components/comments/comments';
import { CermProcessStepRiskPanelContainer } from 'components/cerm/CermProcessStepRiskPanelContainer';
import { Loader } from 'components/loader';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import { IAttachmentQuery } from 'models/attachment/attachment-query';
import { IAttachmentUpload } from 'models/attachment/attachment-upload';
import {
    mapOutcomeCompliance,
    isValidProcessStepAnswer,
    isProcessStepNotApplicable,
} from 'components/cerm/shared/cerm-utils';
import { IKeyValue } from 'models';
import { CermOutcomeTriStateToggle } from './shared/CermOutcomeTriStateToggle';
import { useMasterProcessSteps } from 'components/cerm/shared/useMasterProcessSteps';
import { useProcessStepAnswer, useProcessStepOutcome } from 'components/cerm/shared/useProcessStepAnswer';
import { ICermAssessmentResult, IExpertReviewStatus, getTitlePrefixGeneric } from 'models/cerm/cerm-assessment-result';
import { useCermAssessmentResult } from 'components/cerm/shared/useCermAssessmentResult';
import { CermProcessStepNotApplicableAlert } from './shared/CermProcessStepNotApplicableAlert';
import { easeInScrollToTop } from '../../utils/animation-utils';
import { getSiteOperatingPlatform } from 'models/site-profile';
import { Privilege } from 'enums/Privilege';
import { checkPermission } from 'utils/permission-utils';
import { getUserId } from 'helpers/helpers';
import useTranslate from 'translations/translation-utils';
import { IOperatingPlatform } from 'models/operating-platform-model';

interface IParams {
    id: string;
    outcomeId: string;
}
interface IProps extends RouteComponentProps<IParams> {
    isLoading: boolean;
    siteId: string;
    masterProcessSteps: IMasterProcessStep[];
    processStepAnswer: IProcessStepAnswer;
    cermAssessmentResult: ICermAssessmentResult;
    attachments: IAttachmentModel[];
    permissions: Array<IKeyValue<string>>;
    lookupSites: IKeyValue<string>[];
    expertReviewStatus: IExpertReviewStatus;
    loadMasterProcessSteps: (operatingPlatform: string) => void;
    loadProcessStepAnswer: (
        masterProcessStepId: string,
        siteId: string
    ) => void;
    loadProcessStepOutcome: (
        masterProcessStepId: string,
        processStepOutcomeId: string,
        siteId: string
    ) => void;
    loadCermAssessmentResult: (siteId: string) => void;
    setProcessStepAnswer: (processStepAnswer: IProcessStepAnswer) => void;
    loadAttachments: (query: IAttachmentQuery) => void;
    uploadAttachment: (upload: IAttachmentUpload) => void;
    loadCermAssessmentExpertReview(siteId: string);
    allOperatingPlatforms: IOperatingPlatform[];
}

export const CermAssessmentProcessStepOutcomePage: React.FC<IProps> = ({
    match,
    isLoading,
    siteId,
    masterProcessSteps,
    processStepAnswer,
    cermAssessmentResult,
    permissions,
    attachments,
    lookupSites,
    expertReviewStatus,
    loadMasterProcessSteps,
    loadProcessStepAnswer,
    loadProcessStepOutcome,
    loadCermAssessmentResult,
    setProcessStepAnswer,
    loadAttachments,
    uploadAttachment,
    loadCermAssessmentExpertReview,
    allOperatingPlatforms
}) => {
    const translate = useTranslate();
    const [clarification, setClarification] = React.useState(null);
    const [shouldBlockNavigation, setShouldBlockNavigation] = React.useState(false);
    const [saveComment, setSaveComment] = React.useState(false);
    const [nextExpectedOutcomeUrl, setNextExpectedOutcomeUrl] = React.useState(null);
    const operatingPlatform = getSiteOperatingPlatform(siteId, lookupSites);
    const masterProcessStepId = match.params.id;
    const outcomeId = match.params.outcomeId;
    const outcome = isValidProcessStepAnswer(processStepAnswer)
        ? processStepAnswer.processStepOutcomes.find((s) => s.id === outcomeId)
        : null;
    const processStep = masterProcessSteps
        ? masterProcessSteps.find((s) => s.id === masterProcessStepId)
        : null;
    const expectedOutcome = processStep
        ? processStep.expectedOutcomes.find((s) => s.id === outcomeId)
        : null;
    const expectedOutcomeIndex = processStep
        ? processStep.expectedOutcomes.findIndex((s) => s.id === outcomeId)
        : null;
    const nextExpectedOutcomeIndex = expectedOutcomeIndex + 1;
    const titlePrefix = getTitlePrefixGeneric(operatingPlatform, allOperatingPlatforms);
    const cermAssessmentPageUrl = '/CermAssessment/assessment';
    const cermAssessmentProcessStepPageUrl = `/CermAssessment/ProcessStep/${masterProcessStepId}`;
    const customLinks: Array<IKeyValue<string>> = [
        { key: cermAssessmentPageUrl, value: `${titlePrefix} Assessment` },
        {
            key: cermAssessmentProcessStepPageUrl,
            value: processStep ? `${titlePrefix} ${processStep.processNumber}` : null,
        },
    ];

    const canEdit = checkPermission(Privilege.CermAssessmentOutcomeUpdate, permissions)
    const canEditInExpertReview = (): boolean => {
        if (
            masterProcessStepId &&
            expertReviewStatus &&
            expertReviewStatus.isInReview === true
        ) {
            return (
                expertReviewStatus &&
                (expertReviewStatus.startedById === undefined ||
                    expertReviewStatus.startedById === null ||
                    (expertReviewStatus.startedById &&
                        (expertReviewStatus.startedById === getUserId() ||
                            checkPermission(
                                Privilege.CermAssessmentExpertReviewFinish,
                                permissions
                            ))))
            );
        }

        return true;
    };

    useMasterProcessSteps(masterProcessSteps, loadMasterProcessSteps, operatingPlatform);
    useCermAssessmentResult(siteId, cermAssessmentResult, loadCermAssessmentResult);
    useProcessStepOutcome(
        siteId,
        masterProcessStepId,
        outcomeId,
        loadProcessStepOutcome,
        processStepAnswer
    );
    useProcessStepAnswer(
        siteId,
        masterProcessStepId,
        loadProcessStepAnswer,
        processStepAnswer
    );

    React.useEffect(() => {
        if (outcome) {
            setClarification(outcome.clarification);
        }
    }, [processStepAnswer, outcomeId, nextExpectedOutcomeUrl]);

    React.useEffect(() => {
        if (siteId && !expertReviewStatus) {
            loadCermAssessmentExpertReview(siteId);
        }
    }, [siteId]);

    const handleClarificationChange = () => {
        setProcessStepAnswer({
            ...processStepAnswer,
            processStepOutcomes: processStepAnswer.processStepOutcomes.map((o) => {
                return o.id === outcomeId ? { ...o, clarification } : o;
            }),
        });
        setShouldBlockNavigation(true);
    };

    const handleComplianceChange = (compliance: boolean, id: string) => {
        const processStepAnswerToBeUpdated = mapOutcomeCompliance(
            processStepAnswer,
            id,
            compliance
        );
        setProcessStepAnswer(processStepAnswerToBeUpdated);
        setShouldBlockNavigation(true);
    };

    const backToPage = () => history.push(cermAssessmentProcessStepPageUrl);
    const onSave = () => setSaveComment(true);
    const nextExpectedOutcomeExists = () =>
        expectedOutcomeIndex + 1 < processStep.expectedOutcomes.length;

    const onBtnClick = () => {
        setNextExpectedOutcomeUrl(null);
        onSave();
    };
    const onSaveAndNextBtnClick = () => {
        const nextExpectedOutcome = processStep.expectedOutcomes[nextExpectedOutcomeIndex];
        if (nextExpectedOutcome === null) {
            return;
        }

        setClarification(null);
        setNextExpectedOutcomeUrl(`${cermAssessmentProcessStepPageUrl}/${nextExpectedOutcome.id}`);
        onSave();
        easeInScrollToTop();
    };

    const pageTitle = processStep
        ? `${titlePrefix} ${processStep.processNumber}.${expectedOutcomeIndex + 1}: ${expectedOutcome.title}`
        : `${titlePrefix} Assessment`;
    const compositeId = `${outcomeId}_${siteId}`;

    return (
        <Page
            title={pageTitle}
            className="cerm-assessment-process-step-outcome-page"
            breadcrumbCustomLinks={customLinks}
            showConfirmNavigateAwayDialog={shouldBlockNavigation}
            redirectOnSiteChange={true}
            redirectOnSiteChangeUrl={cermAssessmentPageUrl}
            scrollTopOnPageLoad={true}
            showHeader={false}
        >
            {isProcessStepNotApplicable(cermAssessmentResult, masterProcessStepId) ? (
                <CermProcessStepNotApplicableAlert />
            ) : (
                <Loader loading={isLoading}>
                    {processStep && outcome ? (
                        <>
                            <h1>
                                <CermOutcomeTriStateToggle
                                    masterOutcome={expectedOutcome}
                                    processStepAnswer={processStepAnswer}
                                    compliance={outcome.compliance}
                                    canEdit={canEdit}
                                    onChange={(value) => handleComplianceChange(value, outcomeId)}
                                />
                                {pageTitle}
                            </h1>
                            <Panel title={translate('CermProcessStep.Lable.1')} bodyPadding={true}>
                                <div className="subtitle">{translate('RiskRegisterUpsertPage.Tabs.Details')}</div>
                                <TextAreaField
                                    id="clarification"
                                    label={translate('CermProcessStep.Lable.2')}
                                    placeholder={translate('CermProcessStep.Lable.2') + "..."}
                                    value={clarification ? clarification : ''}
                                    handleChange={(event) =>
                                        setClarification(event.currentTarget.value)
                                    }
                                    handleBlur={() => handleClarificationChange()}
                                    isDisabled={!canEdit}
                                />
                                <Attachments
                                    entity={Entity.AssessmentProcessStepOutcome}
                                    attachments={attachments}
                                    loadAttachments={loadAttachments}
                                    uploadAttachment={uploadAttachment}
                                    id={compositeId}
                                    isReadOnly={!canEdit}
                                />
                            </Panel>
                            <CermProcessStepRiskPanelContainer
                                masterProcessStep={processStep}
                                processStepAnswer={processStepAnswer}
                                setShouldBlockNavigation={setShouldBlockNavigation}
                                outcomeId={outcomeId}
                                submitUrl={nextExpectedOutcomeUrl}
                                canEdit={canEdit}
                                canEditInExpertReview={canEditInExpertReview}
                            />
                            <Comments
                                id={compositeId}
                                entity={Entity.AssessmentProcessStepOutcome}
                                allowNewComment={true}
                                forceSave={saveComment}
                                canEdit={canEdit}
                            />
                            <Toolbar type="save">
                                <ToolbarGroup>
                                    <Button buttonType="cancel" onClick={backToPage}>
                                        {translate('Globals.Label.Cancel')}
                                    </Button>
                                </ToolbarGroup>
                                <ToolbarGroupRight>
                                    {nextExpectedOutcomeExists() && (
                                        <Button
                                            key="saveAndNext"
                                            type="submit"
                                            form="riskPanelForms"
                                            onClick={onSaveAndNextBtnClick}
                                            disabled={!canEdit}
                                        >
                                            {translate('Globals.Label.SaveNext')}
                                        </Button>
                                    )}
                                    <FloatingButton
                                        key="save"
                                        type="submit"
                                        form="riskPanelForms"
                                        float={shouldBlockNavigation}
                                        onClick={onBtnClick}
                                        tooltip={translate('CompetencyDnaProcess.Label.15')}
                                        disabled={!canEdit}
                                    >
                                        {translate('Globals.Label.Save')}
                                    </FloatingButton>
                                </ToolbarGroupRight>
                            </Toolbar>
                        </>
                    ) : null}
                </Loader>
            )}
        </Page>
    );
};