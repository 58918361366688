import { IEmeraldAccordionCoreInputs } from './accordion.inputs';
export class EmeraldAccordionGroupCoreInputs
  implements IEmeraldAccordionGroupCoreInputs
{
  id?: string = Math.random().toString(36).substring(7);
  data?: IEmeraldAccordionCoreInputs[] = [];
  openMultiple?: boolean = true;
}
export interface IEmeraldAccordionGroupCoreInputs {
  id?: string;
  data?: IEmeraldAccordionCoreInputs[];
  openMultiple?: boolean;
}
