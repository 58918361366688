import * as React from 'react';
import { EntitytHistoryItem } from 'components/history/entity-history-item';
import { IDifference } from 'models/difference';
import { CreatedEntitytHistoryItem } from './create-entity-item';
import useTranslate from 'translations/translation-utils';

interface IProps {
    entityHistory: IDifference[][];
    entity?: any;
}

const EntityHistory: React.FC<IProps> = (props) => {
    const translate = useTranslate();
    return (
        <div className="History">
            <div className="Timeline-offset history grid-headers columns ">
                <div className="column is-10">{translate('EntityHistory.Description')}</div>
                <div className="column is-5">{translate('EntityHistory.DateTime')}</div>
            </div>
            {props.entityHistory &&
                props.entityHistory.map((entityHistory, key) => {
                    return (
                        <EntitytHistoryItem
                            key={`event-history-item-${key}`}
                            differences={entityHistory}
                        />
                    );
                })}
            {props.entity && <CreatedEntitytHistoryItem entity={props.entity} />}
        </div>
    );
};

export default EntityHistory;
