import { IAppAction } from '../app-action';
import { IRiskProfileQuestionnaire, IRiskProfileMasterQuestionnaire } from 'models';

export enum RiskProfileQuestionnaireActions {
    LOAD_RISK_PROFILE_MASTER_QUESTIONNAIRE = '@@riskProfileQuestionnaire/LOAD_RISK_PROFILE_MASTER_QUESTIONNAIRE',
    LOAD_RISK_PROFILE_MASTER_QUESTIONNAIRE_FULFILLED = '@@riskProfileQuestionnaire/LOAD_RISK_PROFILE_MASTER_QUESTIONNAIRE_FULFILLED',
    LOAD_RISK_PROFILE_MASTER_QUESTIONNAIRE_REJECTED = '@@riskProfileQuestionnaire/LOAD_RISK_PROFILE_MASTER_QUESTIONNAIRE_REJECTED',

    UPDATE_RISK_PROFILE_MASTER_QUESTIONNAIRE = '@@riskProfileQuestionnaire/UPDATE_RISK_PROFILE_MASTER_QUESTIONNAIRE',
    UPDATE_RISK_PROFILE_MASTER_QUESTIONNAIRE_FULFILLED = '@@riskProfileQuestionnaire/UPDATE_RISK_PROFILE_MASTER_QUESTIONNAIRE_FULFILLED',
    UPDATE_RISK_PROFILE_MASTER_QUESTIONNAIRE_REJECTED = '@@riskProfileQuestionnaire/UPDATE_RISK_PROFILE_MASTER_QUESTIONNAIRE_REJECTED',

    LOAD_RISK_PROFILE_QUESTIONNAIRE = '@@riskProfileQuestionnaire/LOAD_RISK_PROFILE_QUESTIONNAIRE',
    LOAD_RISK_PROFILE_QUESTIONNAIRE_FULFILLED = '@@riskProfileQuestionnaire/LOAD_RISK_PROFILE_QUESTIONNAIRE_FULFILLED',
    LOAD_RISK_PROFILE_QUESTIONNAIRE_REJECTED = '@@riskProfileQuestionnaire/LOAD_RISK_PROFILE_QUESTIONNAIRE_REJECTED',

    CREATE_RISK_PROFILE_QUESTIONNAIRE = '@@riskProfileQuestionnaire/CREATE_RISK_PROFILE_QUESTIONNAIRE',
    CREATE_RISK_PROFILE_QUESTIONNAIRE_FULFILLED = '@@riskProfileQuestionnaire/CREATE_RISK_PROFILE_QUESTIONNAIRE_FULFILLED',
    CREATE_RISK_PROFILE_QUESTIONNAIRE_REJECTED = '@@riskProfileQuestionnaire/CREATE_RISK_PROFILE_QUESTIONNAIRE_REJECTED',

    UPDATE_RISK_PROFILE_QUESTIONNAIRE = '@@riskProfileQuestionnaire/UPDATE_RISK_PROFILE_QUESTIONNAIRE',
    UPDATE_RISK_PROFILE_QUESTIONNAIRE_FULFILLED = '@@riskProfileQuestionnaire/UPDATE_RISK_PROFILE_QUESTIONNAIRE_FULFILLED',
    UPDATE_RISK_PROFILE_QUESTIONNAIRE_REJECTED = '@@riskProfileQuestionnaire/UPDATE_RISK_PROFILE_QUESTIONNAIRE_REJECTED',

    EXPORT_RISK_PROFILE_QUESTIONNAIRE = '@@riskProfileQuestionnaire/EXPORT_RISK_PROFILE_QUESTIONNAIRE',
    EXPORT_RISK_PROFILE_QUESTIONNAIRE_FULFILLED = '@@riskProfileQuestionnaire/EXPORT_RISK_PROFILE_QUESTIONNAIRE_FULFILLED',
    EXPORT_RISK_PROFILE_QUESTIONNAIRE_REJECTED = '@@riskProfileQuestionnaire/EXPORT_RISK_PROFILE_QUESTIONNAIRE_REJECTED',

    EXPORT_ALL_RISK_PROFILE_QUESTIONNAIRE = '@@riskProfileQuestionnaire/EXPORT_ALL_RISK_PROFILE_QUESTIONNAIRE',
    EXPORT_ALL_RISK_PROFILE_QUESTIONNAIRE_FULFILLED = '@@riskProfileQuestionnaire/EXPORT_ALL_RISK_PROFILE_QUESTIONNAIRE_FULFILLED',
    EXPORT_ALL_RISK_PROFILE_QUESTIONNAIRE_REJECTED = '@@riskProfileQuestionnaire/EXPORT_ALL_RISK_PROFILE_QUESTIONNAIRE_REJECTED',

    LOAD_RISK_PROFILE_MASTER_OVERVIEW = '@@riskProfileQuestionnaire/LOAD_RISK_PROFILE_MASTER_OVERVIEW',
    LOAD_RISK_PROFILE_MASTER_OVERVIEW_FULFILLED = '@@riskProfileQuestionnaire/LOAD_RISK_PROFILE_MASTER_OVERVIEW_FULFILLED',
    LOAD_RISK_PROFILE_MASTER_OVERVIEW_REJECTED = '@@riskProfileQuestionnaire/LOAD_RISK_PROFILE_MASTER_OVERVIEW_REJECTED',

    CREATE_RISK_PROFILE_MASTER_QUESTIONNAIRE = '@@riskProfileQuestionnaire/CREATE_RISK_PROFILE_MASTER_QUESTIONNAIRE',
    CREATE_RISK_PROFILE_MASTER_QUESTIONNAIRE_FULFILLED = '@@riskProfileQuestionnaire/CREATE_RISK_PROFILE_MASTER_QUESTIONNAIRE_FULFILLED',
    CREATE_RISK_PROFILE_MASTER_QUESTIONNAIRE_REJECTED = '@@riskProfileQuestionnaire/CREATE_RISK_PROFILE_MASTER_QUESTIONNAIRE_REJECTED',

}


export const loadRiskProfileMasterQuestionnaire = (): IAppAction => ({
    type: RiskProfileQuestionnaireActions.LOAD_RISK_PROFILE_MASTER_QUESTIONNAIRE,
    payload: { id: 'MasterQuestionnaire' }
});

export const loadRiskProfileMasterOverview = (operatimgPlatform: string): IAppAction => ({
    type: RiskProfileQuestionnaireActions.LOAD_RISK_PROFILE_MASTER_OVERVIEW,
    payload: { id: 'MasterQuestionnaire', operatingPlatform: operatimgPlatform }
});

export const updateRiskProfileMasterQuestionnaire = (riskProfileMasterQuestionnaire: IRiskProfileMasterQuestionnaire): IAppAction => ({
    type: RiskProfileQuestionnaireActions.UPDATE_RISK_PROFILE_MASTER_QUESTIONNAIRE,
    payload: riskProfileMasterQuestionnaire
});

export const createRiskProfileMasterQuestionnaire = (riskProfileMasterQuestionnaire: IRiskProfileMasterQuestionnaire): IAppAction => ({
    type: RiskProfileQuestionnaireActions.CREATE_RISK_PROFILE_MASTER_QUESTIONNAIRE,
    payload: riskProfileMasterQuestionnaire
});

export const loadRiskProfileQuestionnaire = (id: string, operatingPlatform: string): IAppAction => ({
    type: RiskProfileQuestionnaireActions.LOAD_RISK_PROFILE_QUESTIONNAIRE,
    payload: { id, operatingPlatform }
});

export const createRiskProfileQuestionnaire = (riskProfileQuestionnaire: IRiskProfileQuestionnaire): IAppAction => ({
    type: RiskProfileQuestionnaireActions.CREATE_RISK_PROFILE_QUESTIONNAIRE,
    payload: riskProfileQuestionnaire
});

export const updateRiskProfileQuestionnaire = (riskProfileQuestionnaire: IRiskProfileQuestionnaire): IAppAction => ({
    type: RiskProfileQuestionnaireActions.UPDATE_RISK_PROFILE_QUESTIONNAIRE,
    payload: riskProfileQuestionnaire
});

export const exportRiskProfileQuestionnaire = (siteId: string, operatingPlatform: string): IAppAction => ({
    type: RiskProfileQuestionnaireActions.EXPORT_RISK_PROFILE_QUESTIONNAIRE,
    payload: { siteId, operatingPlatform }
});

export const exportAllRiskProfileQuestionnaire = (operatingPlatform: string): IAppAction => ({
    type: RiskProfileQuestionnaireActions.EXPORT_ALL_RISK_PROFILE_QUESTIONNAIRE,
    payload: { operatingPlatform }
});
