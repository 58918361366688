import { RiskActions, RiskActionTypes } from '../../../../actions/risk-actions';
import { IRisk } from '../../../../models/risk';

export default {
    onClose: (itemForConfirmation: IRisk): RiskActionTypes => ({
        type: RiskActions.DELETE_RISK_CANCEL,
        payload: {
            item: itemForConfirmation
        }
    }),
    onConfirm: (itemForConfirmation: IRisk): RiskActionTypes => ({
        type: RiskActions.DELETE_RISK_CONFIRM,
        payload: {
            item: itemForConfirmation
        }
    })
};
