import * as React from 'react';
import { Help, Control, Field } from '..';
import { BulmaColor } from 'enums/BulmaColor';
import { DatePicker } from 'components/datepicker';
import { formatDateString } from 'utils/date-utils';
import iconCalendar from 'assets/icon-calendar.svg';

interface IProps {
    id: string;
    label: string;
    value: string | Date;
    error?: string;
    maxDate?: Date;
    minDate?: Date;
    placeholderText?: string;
    handleChange?: any;
    isDisabled?: boolean;
    selected?: string | Date;
    handleBlur?: (e: any) => void;
    showMonthDropdown?: boolean;
    showYearDropdown?: boolean;
    isInTable?: boolean;
    isHorizontal?: boolean;
}

export const DatePickerField: React.FC<IProps> = (props) => {
    return (
        <Field isHorizontal={props.isHorizontal} htmlFor={props.id} label={props.label}>
            <Control className="date-picker-quantum">
                <img src={iconCalendar} className="date-picker-icon" />
                <DatePicker
                    id={props.id}
                    className={props.isInTable ? 'date-picker-in-table' : ''}
                    value={formatDateString(props.value, 'DD MMM YYYY', '')}
                    maxDate={props.maxDate == null ? new Date(9999, 12, 31) : props.maxDate}
                    minDate={props.minDate}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    placeholderText={props.placeholderText}
                    disabled={props.isDisabled}
                    selected={props.selected ? new Date(props.selected) : new Date()}
                    showMonthDropdown={props.showMonthDropdown}
                    showYearDropdown={props.showMonthDropdown}
                    dropdownMode="select"
                    popperProps={{ strategy: 'fixed' }}
                />
            </Control>
            <Help bulmaColor={BulmaColor.Danger} isShown={true}>
                {props.error}
            </Help>
        </Field>
    );
};
