import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';

const getPillars = (state: IRootState) => state.cermAssessmentState.pillars;
const gerSiteId = (state: IRootState) => state.app.siteId;

export default createSelector(
    getPillars,
    gerSiteId,
    (pillars, siteId) => ({
        pillars,
        siteId
    })
);
