import { compact } from 'lodash';
import { createSelector } from 'reselect';

import { ITypeaheadItem } from 'components/typeahead';
import { IClient } from 'models/client';
import { ISiteRelationshipsRootState } from 'reducers/siteRelationships';
//import { IClientSelectorState } from 'reducers/siteRelationships/client-selector';

const getClientSelectorState = (state: ISiteRelationshipsRootState) => state.siteRelationships.clientSelector;

const getIsLoading = createSelector(
    getClientSelectorState,
    (state) => state.isLoading
);

const getInputValue = createSelector(
    getClientSelectorState,
    (state) => state.searchValue
);

const getSiteDisplayText = (client: IClient) => compact([
    client.name
]).join(' ');

const getValues = createSelector(
    [getClientSelectorState],
    (state) => state.clients.map<ITypeaheadItem<IClient>>((client) => ({
        text: getSiteDisplayText(client),
        value: client
    }))
);

const getPlaceholder = () => 'Search';

export default createSelector(
    [getInputValue, getIsLoading, getValues, getPlaceholder],
    (inputValue, loading, values, placeholder) => ({
        inputValue,
        loading,
        values,
        placeholder
    })
);
