import React from 'react';
import { RouteComponentProps } from 'react-router';
import { IKeyValue } from 'models';
import { history } from 'routes/App';
import { SingleSelectorDialog } from './SingleSelectorDialog';
import useTranslate from 'translations/translation-utils';

interface IParams {
    siteId?: string;
}

interface IProps extends RouteComponentProps<IParams> {
    siteId?: string;
    lookupSites: Array<IKeyValue<string>>;
    onSiteChange: (id: string) => void;
}

export const SiteSelectorDialog: React.FC<IProps> = ({
    match,
    siteId,
    lookupSites,
    onSiteChange,
}) => {
    const translate = useTranslate();
    React.useEffect(() => {
        let paramSiteId = match.params.siteId;
        if (paramSiteId && paramSiteId !== siteId) {
            onSiteChange(paramSiteId);
        }

        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('siteId')) {
            paramSiteId = urlParams.get('siteId');

            if (paramSiteId !== siteId) {
                onSiteChange(paramSiteId);
                urlParams.delete('siteId');

                history.push({
                    pathname: window.location.pathname,
                    search: '?' + urlParams.toString(),
                });
            }
        }
    }, [onSiteChange]);

    const shouldRenderSiteSelector = (): boolean => {
        if (siteId) {
            return false;
        }

        const matchPages = [
            'Dashboard',
            'Risk',
            'Action',
            'Engineers',
            'CompetencyDNAProcess',
            'CermAssessment',
            'Assessment',
            'CermProcessOwners',
            'RiskAssessment',
            'RiskProfileQuestionnaire',
            'Calendar',
            'Shifts',
            'CriticalSystemSpares',
            'RiskRegister',
            'RiskRegisterClient',
            'OpenPending',
            'OpenAccepted',
            'OpenApproved',
            'ClientRisks',
            'ClosedRisks',
            'Incident',
            'MockDrills',
            'SiteDocuments',
            'ShiftHandovers',
            'OpportunityQuestionnaire',
            'ClientDashboard',
            'ClientSitesOverview',
            'MockDrill',
            'CermSiteOverview',
            'CermProcessSteps',
            'CermGlobalOverview',
            'Site',
            'QuestionSets',
            'ShiftHandoverAdmin',
            'ShiftHandoverAdminUpdate',
            'Client',
            'ManageRole',
            'ManageRelationships',
            'SendNotifications',
            'Questionnaire',
            'ContactForm',
            'PersonProfile',
            'Notifications',
            'ManageEngineerSkills',
            'RiskProfileMasterQuestionnaire',
            'ClientCriticalSystemSpares',
            'GlobalMockDrills',
            'LogsUpload',
            'BulkAssignment',
            'Opportunity',
            'Opportunities',
        ];
        const pathName = window.location.pathname.substring(1).toLocaleLowerCase();
        return matchPages.some(page => pathName.startsWith(page.toLocaleLowerCase()));
    };
    return shouldRenderSiteSelector() ? (
        <SingleSelectorDialog
            fullItemsList={lookupSites}
            selectItemId={siteId}
            onSelectChange={onSiteChange}
            selectorType="Site"
            subHeading={translate('SelectorDialog.Label.3')}
            infoMessage={translate('SelectorDialog.Label.4')}
        />
    ) : null;
};
