import { IGridViewState, INITIAL_STATE as INITIAL_GRID_STATE } from 'reducers/grid';
import { IEngineer } from 'models/engineer';

export interface IEngineersGridState extends IGridViewState<IEngineer> {
}

const defaultState: IEngineersGridState = {
    ...INITIAL_GRID_STATE,
};

// tslint:disable-next-line:arrow-return-shorthand
export const getDefaultEngineersGridState = (options?: any) => { return {...defaultState, ...options }; };
