import * as React from 'react';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconType } from 'routes/material-icon/material-icon-type';
import './Card.scss';

export interface IModalCardHeaderToolbarItem {
    type: 'text' | 'icon';
    selected?: boolean;
    icon?: MaterialIconType;
    action?: (componentId: string) => void;
    label?: string;
    text?: string;
}

export interface IProps {
    widgetId?: string;
    title: string;
    subtitle?: string;
    sideNote?: string;
    toolbarItems?: IModalCardHeaderToolbarItem[];
    onClick?: () => void;
    additionalAction?: JSX.Element;
}

export const CardHeader: React.FC<React.PropsWithChildren<IProps>> = props => {
    const onAction = (toolbarItem: IModalCardHeaderToolbarItem) => {
        toolbarItem.action(props.widgetId);
    };

    return (
        <header className="card-header" onClick={props.onClick}>
            <div className="card-header-title">
                <span className="title-area">
                    <div className="media-content">
                        <p className="title is-4">
                            <span>{props.title}</span>
                        </p>
                        {
                            !!props.additionalAction &&
                            props.additionalAction
                        }
                        <p className="subtitle is-6" title={props.subtitle}>
                            <small>{props.subtitle}</small>
                        </p>
                        <p className="side-note">
                            <span>{props.sideNote}</span>
                        </p>
                    </div>
                </span>
            </div >
            {
                props.toolbarItems && (
                    <div className="card-header-icon">
                        {props.toolbarItems.map((toolbarItem, orderIndex) => (
                            <div
                                key={orderIndex}
                                className={
                                    'card-header__item is-block' +
                                    (toolbarItem.type === 'text' ? ' is-fixed-60 is-right' : '')
                                }
                            >
                                {props.children && props.children}
                                {toolbarItem.type === 'icon' && toolbarItem.label && toolbarItem.icon && (
                                    <>
                                        {toolbarItem.action && (
                                            <a
                                                onClick={onAction.bind(this, toolbarItem)}
                                                aria-label={toolbarItem.label}
                                            >
                                                <MaterialIcons
                                                    type={toolbarItem.icon}
                                                    className={
                                                        toolbarItem.selected
                                                            ? 'card-header-icon--selected'
                                                            : ''
                                                    }
                                                />
                                            </a>
                                        )}
                                        {!toolbarItem.action && (
                                            <a aria-label={toolbarItem.label}>
                                                <MaterialIcons type={toolbarItem.icon} />
                                            </a>
                                        )}
                                    </>
                                )}
                                {toolbarItem.type === 'text' && toolbarItem.text && (
                                    <>
                                        {toolbarItem.action && (
                                            <a
                                                className="is-medium"
                                                onClick={onAction.bind(this, toolbarItem)}
                                            >
                                                {toolbarItem.text}
                                            </a>
                                        )}
                                        {!toolbarItem.action && <span>{toolbarItem.text}</span>}
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                )
            }
        </header >
    );
};
