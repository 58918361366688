import * as React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { Tab, Tabs } from 'components/tabs';
import { BulmaSize } from 'enums/BulmaSize';
import { IKeyValue } from 'models/key-value';
import { ISiteProfile } from 'models/site-profile';
import { ClientLinkChangeConfirmation } from 'routes/siteRelationships/views/clients/clientLinkChangeConfirmation';
import { RouteUrl } from '../routes';
import { BuildingLinkDeleteConfirmation } from './buildings/buildingLinkDeleteConfirmation';
import { AttachClientForm } from './clients/attachClientForm';
import { AttachPersonForm } from './people/attachPersonForm';
import { LinkedPeople } from './people/linkedPeople';
import { PersonLinkDeleteConfirmation } from './people/personLinkDeleteConfirmation';
import './site-relationships-page.scss';
import { SiteSelector } from './sites/siteSelector';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';

export interface IStateProps {
    permissions: Array<IKeyValue<string>>;
    selectedSite: ISiteProfile | null;
    isInitialising: boolean;
}

export interface IActionProps {
    selectRelationshipType: (url: RouteUrl) => void;
    intl: any;
}

interface IProps extends IStateProps, IActionProps, RouteComponentProps<{}> { }

const getRouteLabel = (route: RouteUrl) => {
    // TODO: Localise
    switch (route) {
        case RouteUrl.People:
            return 'People';

        case RouteUrl.Clients:
            return 'Client';

        default:
            return null;
    }
};

const getRouteTab = (route: RouteUrl, props: IProps) => (
    <Tab
        key={route}
        text={getRouteLabel(route)}
        active={props.location.pathname === route}
        // tslint:disable-next-line:jsx-no-lambda
        onClick={() => props.selectRelationshipType(route)}
    />
);

class SiteRelationshipsPageClass extends React.PureComponent<IProps> {
    public render() {
        const props = this.props;
        const intl = props.intl;

        return (
            <>
                <div className="section">
                    <div className="container">
                        <h4 className="title is-4 is-uppercase">{translate(intl, 'SiteRelationships.Page.Header.RelationshipsBySite')}</h4>
                        <SiteSelector />
                    </div>
                </div>

                {props.selectedSite && (
                    <>
                        <div className="section">
                            <div className="container">
                                <Tabs isToggle={true} bulmaSize={BulmaSize.Large} isFullwidth={true}>
                                    {
                                        [
                                            RouteUrl.People,
                                            RouteUrl.Clients
                                        ].map((route) => getRouteTab(route, props))
                                    }
                                </Tabs>
                            </div>
                        </div>

                        <Switch>
                            <Route
                                exact={true}
                                path={RouteUrl.People}
                                // tslint:disable-next-line:jsx-no-lambda
                                render={() => (
                                    <>
                                        <div className="section">
                                            <div className="container">
                                                <AttachPersonForm />
                                            </div>
                                        </div>

                                        <div className="section">
                                            <div className="container">
                                                <LinkedPeople />
                                            </div>
                                        </div>
                                    </>
                                )}
                            />

                            <Route
                                exact={true}
                                path={RouteUrl.Clients}
                                // tslint:disable-next-line:jsx-no-lambda
                                render={() => (
                                    <>
                                        <div className="section">
                                            <div className="container">
                                                <AttachClientForm />
                                            </div>
                                        </div>
                                    </>
                                )}
                            />
                        </Switch>
                    </>
                )}

                <BuildingLinkDeleteConfirmation />
                <ClientLinkChangeConfirmation />
                <PersonLinkDeleteConfirmation />
            </>
        );
    }
}

export const SiteRelationshipsPage = injectIntl(SiteRelationshipsPageClass);