import { onRouteChange } from 'actions/app-actions';
import { BuildingActions } from 'actions/building-actions';
import { deleteBuilding } from 'actions/actions-v2/site-actions-v2';
import { loadFacility } from 'actions/actions-v2/facility-action-v2';

export default {
    onAddBuilding: (newIndex: number) => onRouteChange(`/buildings/${newIndex}`),
    onDeleteBuilding: deleteBuilding,
    loadFacility,
    onGetBenchmarkData: (siteId: string, buildingId: string) => ({
        type: BuildingActions.LOAD_PUE_BENCHMARKS,
        payload: {
            siteId,
            buildingId
        }
    }),
    onChangeMonth: (date: Date) => ({
        type: BuildingActions.CHANGE_PUE_CHART_MONTH,
        payload: {
            date
        }
    })
};
