import classnames from 'classnames';
import * as React from 'react';

import './Modal.scss';

export interface IStateProps {
    modalId: string;
    isShown: boolean;
}

export interface IActionProps {
    onClose?: () => void;
}

export const Modal: React.FC<React.PropsWithChildren<IStateProps & IActionProps>> = (props) => {
    const css = classnames(
        'modal',
        {
            'is-active': props.isShown
        }
    );

    if (!props.isShown) {
        return null;
    }

    return (
        <div
            id={props.modalId}
            className={css}
            role="dialog"
            aria-labelledby={props.modalId + '-modal-dialog-title'}
            aria-describedby={props.modalId + '-modal-dialog-desc'}
        >
            <div className="modal-background" />
            <div className="modal-content">
                <div className="box">
                    {props.children}
                </div>
            </div>
            {(props.onClose && (
                <button
                    className="modal-close is-large"
                    aria-label="close"
                    onClick={props.onClose}
                />
            ))}
        </div>
    );
};
