import { createSelector } from 'reselect';
import { IRootQuestionSetState } from '../../../../reducers/questionSet';

const getApp = (state: IRootQuestionSetState) => state.app;

export default createSelector(
    getApp,
    (app) => ({
        permissions: app.permissions
    })
);
