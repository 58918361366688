import * as React from 'react';
import { Help, Input, Control, Field } from '..';
import { BulmaColor } from 'enums/BulmaColor';

interface IProps {
    id: string;
    label: string;
    value: string;
    placeholder?: string;
    maxLength?: number;
    error?: string;
    isDisabled?: boolean;
    isHorizontal?: boolean;
    readOnly?: boolean;
    handleChange?: (field: any) => void;
    handleBlur?: (e: any) => void;
    setVal?: any;
}

export const TextField: React.FC<IProps> = (props) => {
    const trim = (evt) => props.setVal && props.setVal(evt.target.name, evt.target.value.trim())
    return (
        <Field isHorizontal={props.isHorizontal}>
            <label className="label">{props.label}</label>
            <Control>
                <Input
                    className="is-medium"
                    id={props.id}
                    name={props.id}
                    value={props.value}
                    placeholder={props.placeholder}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur ?? trim}
                    maxLength={props.maxLength}
                    isDisabled={props.isDisabled}
                    readOnly={props.readOnly}
                />
            </Control>
            <Help bulmaColor={BulmaColor.Danger} isShown={true}>
                {props.error}
            </Help>
        </Field>
    );
};
