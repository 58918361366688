import { createSelector } from 'reselect';
import { IKeyedItem } from '../../../../models/keyed-item';

const getActionItem = (props: { itemForConfirmation: IKeyedItem }) => {
    return props.itemForConfirmation;
};

export default () => createSelector(
    getActionItem,
    (data) => ({
        data
    })
);
