import { Action } from 'redux';
import { ICermSiteOverviewGroup } from 'models/cerm-site-overview';
import { ICermSiteOverviewGridState } from 'reducers/cerm-site-overview/grid';

export enum CermSiteOverviewFilterActions {
    CHANGE_SORT_COLUMN = '@@cermSiteOverview/CHANGE_SORT_COLUMN',

    CHANGE_FILTER_VALUE = '@@cermSiteOverview/CHANGE_FILTER_VALUE',
    CLEAR_FILTERS= '@@cermSiteOverview/CLEAR_FILTERS',

    SHOW_FILTER_MODAL = '@@cermSiteOverview/SHOW_FILTER_MODAL',
    HIDE_FILTER_MODAL = '@@cermSiteOverview/HIDE_FILTER_MODAL',

    TOGGLE_FILTER_EXPANDED = '@@cermSiteOverview/TOGGLE_FILTER_EXPANDED',
    COLLAPSE_ALL_FILTERS = '@@cermSiteOverview/COLLAPSE_ALL_FILTERS',
}

export interface IToggleFilterExpandedAction extends Action {
    type: CermSiteOverviewFilterActions.TOGGLE_FILTER_EXPANDED;
    payload: {
        id: Extract<keyof ICermSiteOverviewGridState, string>;
    };
}

export const toggleFilterExpanded = (
    id: Extract<keyof ICermSiteOverviewGridState, string>
): IToggleFilterExpandedAction => ({
    type: CermSiteOverviewFilterActions.TOGGLE_FILTER_EXPANDED,
    payload: {
        id
    }
});

export interface IShowFilterModalAction extends Action {
    type: CermSiteOverviewFilterActions.SHOW_FILTER_MODAL;
}

export const showFilterModal = (): IShowFilterModalAction => ({
    type: CermSiteOverviewFilterActions.SHOW_FILTER_MODAL
});

export interface IHideFilterModalAction extends Action {
    type: CermSiteOverviewFilterActions.HIDE_FILTER_MODAL;
}

export const hideFilterModal = (): IHideFilterModalAction => ({
    type: CermSiteOverviewFilterActions.HIDE_FILTER_MODAL
});

export interface IChangeFilterValueAction extends Action {
    type: CermSiteOverviewFilterActions.CHANGE_FILTER_VALUE;
    payload: {
        field: keyof ICermSiteOverviewGridState;
        value: string;
    };
}

export const changeFilterValue = (
    field: keyof ICermSiteOverviewGridState,
    value: string
): IChangeFilterValueAction => ({
    type: CermSiteOverviewFilterActions.CHANGE_FILTER_VALUE,
    payload: {
        field,
        value
    }
});

export interface IClearFiltersAction extends Action {
    type: CermSiteOverviewFilterActions.CLEAR_FILTERS;
}

export const clearFilters = (): IClearFiltersAction => ({
    type: CermSiteOverviewFilterActions.CLEAR_FILTERS
});

export interface IChangeSortColumnAction extends Action {
    type: CermSiteOverviewFilterActions.CHANGE_SORT_COLUMN;
    payload: {
        column: Extract<keyof ICermSiteOverviewGroup, string>;
        isAscending: boolean;
    };
}

export const changeSortColumn = (
    column: Extract<keyof ICermSiteOverviewGroup, string>,
    isAscending: boolean
): IChangeSortColumnAction => ({
    type: CermSiteOverviewFilterActions.CHANGE_SORT_COLUMN,
    payload: {
        column,
        isAscending
    }
});

export interface ICollapseAllFiltersAction extends Action {
    type: CermSiteOverviewFilterActions.COLLAPSE_ALL_FILTERS;
}

export const collapseAllFilters = (): ICollapseAllFiltersAction => ({
    type: CermSiteOverviewFilterActions.COLLAPSE_ALL_FILTERS
});

export type CermSiteOverviewFilterActionTypes =
| ICollapseAllFiltersAction

| IChangeSortColumnAction
| IToggleFilterExpandedAction
| IHideFilterModalAction
| IShowFilterModalAction
| IChangeFilterValueAction
| IClearFiltersAction;
