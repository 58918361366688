import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { withRouter } from 'react-router';
import { createSelector } from 'reselect';
import { CermTechnologyPlatform } from './cerm-technology-platform-form';
import { ICermOverviewRootState } from 'reducers/cerm-overview';


const getCermItem = (state: ICermOverviewRootState) => state.cermOverview.items;
const getIsLoading = (state: IRootState) => state.cermOverview.isLoading;

const mapStateToProps = createSelector(getIsLoading, getCermItem, (isLoading, cermItems) => ({
    isLoading,
    cermItems,
}));

export const CermTechnologyPlatformContainer = withRouter(connect(mapStateToProps)(CermTechnologyPlatform));