import React from 'react';
import { Alert } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';

export const CermProcessStepNotApplicableAlert = () => {

    const translate = useTranslate();
    return (
        <Alert type="warning" title={translate('Cerm.Shared.CermProcessStepNotApplicable.Message')}>
            <p>
                {translate('Cerm.Shared.CermProcessStepNotApplicable.Message2')}
            </p>
        </Alert>
    );
};
