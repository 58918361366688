import { INotificationDetails } from 'models/notification-details';
import { notificationStateActions } from 'actions/notification-actions';
import { IAppAction } from 'actions/app-action';

export default {
    onSend: (notification: INotificationDetails, userIds: string[]): IAppAction => ({
        type: notificationStateActions.SEND_NOTIFICATION,
        payload: { notification, userIds },
    }),
};
