import { IAppAction } from '../app-action';
import {
    ICriticalSystemSpare,
    ICriticalSystemSpareRequestFilters
} from 'models/critical-system-spares/critical-system-spare';
import { IEntitySite } from 'models/entity-site';

export enum CriticalSystemSpareActions {
    LOAD_CRITICAL_SYSTEM_SPARES = '@@critical_system_spare/LOAD_CRITICAL_SYSTEM_SPARES',
    LOAD_CRITICAL_SYSTEM_SPARES_FULFILLED = '@@critical_system_spare/LOAD_CRITICAL_SYSTEM_SPARES_FULFILLED',
    LOAD_CRITICAL_SYSTEM_SPARES_REJECTED = '@@critical_system_spare/LOAD_CRITICAL_SYSTEM_SPARES_REJECTED',

    LOAD_CRITICAL_SYSTEM_SPARE = '@@critical_system_spare/LOAD_CRITICAL_SYSTEM_SPARE',
    LOAD_CRITICAL_SYSTEM_SPARE_FULFILLED = '@@critical_system_spare/LOAD_CRITICAL_SYSTEM_SPARE_FULFILLED',
    LOAD_CRITICAL_SYSTEM_SPARE_REJECTED = '@@critical_system_spare/LOAD_CRITICAL_SYSTEM_SPARE_REJECTED',

    CREATE_CRITICAL_SYSTEM_SPARE = '@@critical_system_spare/CREATE_CRITICAL_SYSTEM_SPARE',
    CREATE_CRITICAL_SYSTEM_SPARE_FULFILLED = '@@critical_system_spare/CREATE_CRITICAL_SYSTEM_SPARE_FULFILLED',
    CREATE_CRITICAL_SYSTEM_SPARE_REJECTED = '@@critical_system_spare/CREATE_CRITICAL_SYSTEM_SPARE_REJECTED',

    UPDATE_CRITICAL_SYSTEM_SPARE = '@@critical_system_spare/UPDATE_CRITICAL_SYSTEM_SPARE',
    UPDATE_CRITICAL_SYSTEM_SPARE_FULFILLED = '@@critical_system_spare/UPDATE_CRITICAL_SYSTEM_SPARE_FULFILLED',
    UPDATE_CRITICAL_SYSTEM_SPARE_REJECTED = '@@critical_system_spare/UPDATE_CRITICAL_SYSTEM_SPARE_REJECTED',

    DELETE_CRITICAL_SYSTEM_SPARE = '@@critical_system_spare/DELETE_CRITICAL_SYSTEM_SPARE',
    DELETE_CRITICAL_SYSTEM_SPARE_FULFILLED = '@@critical_system_spare/DELETE_CRITICAL_SYSTEM_SPARE_FULFILLED',
    DELETE_CRITICAL_SYSTEM_SPARE_REJECTED = '@@critical_system_spare/DELETE_CRITICAL_SYSTEM_SPARE_REJECTED',

    EXPORT_CRITICAL_SYSTEM_SPARE = '@@critical_system_spare/EXPORT_CRITICAL_SYSTEM_SPARE',
    EXPORT_CRITICAL_SYSTEM_SPARE_FULFILLED = '@@critical_system_spare/EXPORT_CRITICAL_SYSTEM_SPARE_FULFILLED',
    EXPORT_CRITICAL_SYSTEM_SPARE_REJECTED = '@@critical_system_spare/EXPORT_CRITICAL_SYSTEM_SPARE_REJECTED',

    CLEAR_FORM = '@@critical_system_spare/CLEAR_FORM',

    LOAD_CRITICAL_SYSTEM_SPARES_SUMMARY = '@@critical_system_spare/LOAD_CRITICAL_SYSTEM_SPARES_SUMMARY',
    LOAD_CRITICAL_SYSTEM_SPARES_SUMMARY_FULFILLED = '@@critical_system_spare/LOAD_CRITICAL_SYSTEM_SPARES_SUMMARY_FULFILLED',
    LOAD_CRITICAL_SYSTEM_SPARES_SUMMARY_REJECTED = '@@critical_system_spare/LOAD_CRITICAL_SYSTEM_SPARES_SUMMARY_REJECTED',

    LOAD_CRITICAL_SYSTEM_SPARES_SITE_SUMMARIES = '@@critical_system_spare/LOAD_CRITICAL_SYSTEM_SPARES_SITE_SUMMARIES',
    LOAD_CRITICAL_SYSTEM_SPARES_SITE_SUMMARIES_FULFILLED = '@@critical_system_spare/LOAD_CRITICAL_SYSTEM_SPARES_SITE_SUMMARIES_FULFILLED',
    LOAD_CRITICAL_SYSTEM_SPARES_SITE_SUMMARIES_REJECTED = '@@critical_system_spare/LOAD_CRITICAL_SYSTEM_SPARES_SITE_SUMMARIES_REJECTED',
}

export const loadAll = (filters: ICriticalSystemSpareRequestFilters): IAppAction => ({
    type: CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARES,
    payload: filters
});

export const load = (model: IEntitySite): IAppAction => ({
    type: CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARE,
    payload: {
        id: model.id,
        shard: model.siteId,
        siteId: model.siteId,
    },
});

export const create = (criticalSystemSpare: Partial<ICriticalSystemSpare>): IAppAction => ({
    type: CriticalSystemSpareActions.CREATE_CRITICAL_SYSTEM_SPARE,
    payload: criticalSystemSpare
});

export const update = (criticalSystemSpare: Partial<ICriticalSystemSpare>): IAppAction => ({
    type: CriticalSystemSpareActions.UPDATE_CRITICAL_SYSTEM_SPARE,
    payload: criticalSystemSpare
});

export const remove = (id: string): IAppAction => ({
    type: CriticalSystemSpareActions.DELETE_CRITICAL_SYSTEM_SPARE,
    payload: id
});

// export const exportData = (filters: ICriticalSystemSpareRequestFilters): IAppAction => ({
//     type: CriticalSystemSpareActions.EXPORT_CRITICAL_SYSTEM_SPARE,
//     payload: filters
// });

export const exportData = (siteId: string): IAppAction => ({
    type: CriticalSystemSpareActions.EXPORT_CRITICAL_SYSTEM_SPARE,
    payload: { siteId }
});

export const clearForm = (): IAppAction => ({
    type: CriticalSystemSpareActions.CLEAR_FORM,
});

export const loadSummary = (siteId: string): IAppAction => ({
    type: CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARES_SUMMARY,
    payload: siteId
});

export const loadSiteSummaries = (): IAppAction => ({
    type: CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARES_SITE_SUMMARIES
});
