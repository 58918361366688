import React from 'react';
import { IDifference } from 'models/difference';
import './difference-item.scss';
import { lowerCaseString, ConvertArrayFieldToWords } from 'utils/utils';

interface IDifferenceProps {
    difference: IDifference;
}

export default class DifferenceItem extends React.PureComponent<IDifferenceProps> {

    private checkIfArrayAndReturnValues(obj: object, propertyName: string) {
      const val = obj[propertyName];
      if (val) {
        return val;
      }
      if (propertyName.includes('[')) {
         return obj;
      }
      const lowerCasePropertyName = lowerCaseString(propertyName);
      const value = obj[lowerCasePropertyName];

      if (value) {
      if (Array.isArray(value)) {
        return value.join(', ');
      }
      }
      if (value === false) {
          return 'No';
      }
      if (value === true) {
        return 'Yes';
      }
      return value;
    }

    public render(): JSX.Element {
        const { difference }  = this.props;
        let oldValue: string | number | string[];
        let newValue: string | number | string[];
        let propertyNameDisplay = difference.propertyName.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1');

        propertyNameDisplay = ConvertArrayFieldToWords(propertyNameDisplay);
        if (difference.propertyName.includes('[')) {
          newValue = this.checkIfArrayAndReturnValues(difference.object2, difference.propertyName);
          oldValue = this.checkIfArrayAndReturnValues(difference.object1, difference.propertyName);
        } else {
           newValue = this.checkIfArrayAndReturnValues(difference.parentObject2, difference.propertyName);
           oldValue = this.checkIfArrayAndReturnValues(difference.parentObject1, difference.propertyName);
        }

        return (
            <div className="difference-container">
                {newValue && <div className="field-title">{propertyNameDisplay} : </div>}
                {(Array.isArray(newValue) ? newValue.length !== 0 : newValue) && <div className="new-value">{newValue}</div>}
                {(Array.isArray(oldValue) ? oldValue.length !== 0 : oldValue) && <div className="old-value">
                 {oldValue}</div>}
            </div>
        );
       }
}
