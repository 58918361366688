import * as React from 'react';
import { RouteUrl } from '../../routes';
import { IconToolbar, IToolbarIconItem, ToolbarIconType } from 'components/v2/components';
import { history } from 'routes/App';
import useTranslate from 'translations/translation-utils';

export interface IActionProps {
    isFilterExists?: boolean;
    onShowFilters: () => void;
    onClearFilters: () => void;
}

export const ClientGridToolbar: React.FC<IActionProps> = ({ onShowFilters, onClearFilters, isFilterExists }) => {
    const translate = useTranslate();
    const getToolbarItems = (): IToolbarIconItem[] => {
        return [
            {
                id: 'add-action-button',
                title: translate('ActionUpsertPage.Title'),
                type: ToolbarIconType.add,
                isActive: false,
                onClick: () => {
                    history.push(RouteUrl.ClientUpsert);
                }
            },
            {
                id: 'clear-all-button',
                title: translate('IncidentPage.Title.ClearFilters'),
                type: isFilterExists ? ToolbarIconType.filterApplied : ToolbarIconType.clearFilter,
                isActive: false,
                onClick: onClearFilters
            },
            {
                id: 'show-filters-button',
                title: translate('IncidentPage.Title.ShowFilters'),
                type: ToolbarIconType.filter,
                isActive: false,
                onClick: onShowFilters
            }
        ];
    };

    return <IconToolbar items={getToolbarItems()} />;
};
