import * as React from 'react';

import { compact } from 'lodash';
import { BulmaColor } from '../../enums/BulmaColor';
import './Form.scss';

export interface IProps extends React.AllHTMLAttributes<HTMLInputElement> {
  bulmaColor?: BulmaColor;
  isstatic?: boolean;
  isDisabled?: boolean;
  readOnly?: boolean;
  autocomplete?: string;
}

export const Input: React.FC<IProps> = (props) => {
  const { isstatic, bulmaColor, ...otherProps } = props;

  const css = compact([
    'input',
    isstatic ? 'is-static' : null,
    props.size ? 'is-' + props.size : null,
    bulmaColor ? 'is-' + bulmaColor : null,
    props.className,
  ]).join(' ');

  return (
    <input
      {...otherProps}
      readOnly={props.readOnly || isstatic}
      disabled={props.isDisabled || false}
      autoComplete={props.autocomplete}
      className={css}
    />
  );
};

Input.defaultProps = {
  type: 'text',
};
