import React, { useEffect } from 'react';
import { Panel } from 'components/v2/components';
import { IClientRisksSummary } from 'models/risk';
import { Loader } from 'components/loader';
import { ICriticalSystemSparesSummary } from 'models/critical-system-spares/critical-system-spare';
import { CriticalSystemSparesSummaryTable } from 'components/critical-system-spares/critical-system-spares-summary-table';
import { RiskRegisterSummary } from 'components/risk-register/risk-register-summary';
import Page from 'components/v2/page/Page';
import useTranslate from 'translations/translation-utils';

interface IClientDashboardProps {
    siteId: string;
    risks: IClientRisksSummary;
    criticalSpares: ICriticalSystemSparesSummary[];
    isRisksLoading: boolean;
    isCriticalSparesLoading: boolean;
    showCriticalSpares: boolean;
    showClientRisks: boolean;
    loadRisksSummary: (siteId: string) => void;
    loadCriticalSparesSummary: (siteId: string) => void;
}

export const ClientDashboardPage: React.FC<IClientDashboardProps> = ({
    siteId,
    risks,
    criticalSpares,
    showCriticalSpares,
    showClientRisks,
    loadRisksSummary,
    loadCriticalSparesSummary,
    isRisksLoading,
    isCriticalSparesLoading
}) => {
    const translate = useTranslate();
    const [dataLoadBegun, setDataLoadBegun] = React.useState(false);

    useEffect(() => {
        if (siteId) {
            if (showClientRisks) {
                loadRisksSummary(siteId);
            }
            if (showCriticalSpares) {
                loadCriticalSparesSummary(siteId);
            }
            setDataLoadBegun(true);
        }
    }, [siteId]);

    const notReadyToDisplayRisks: boolean = isRisksLoading || !dataLoadBegun;

    return (
        <Page title={translate('SideNavBar.Labels.Dashboard')}>
            {showClientRisks && (
                <RiskRegisterSummary risks={risks} isLoading={notReadyToDisplayRisks} />
            )}
            {showCriticalSpares && (
                <Panel title={translate('SideNavBar.Labels.SystemSpares')} intro={translate('SpareDetails.Lable.16')}>
                    <Loader loading={isCriticalSparesLoading}>
                        {criticalSpares && criticalSpares.length > 0 && (
                            <CriticalSystemSparesSummaryTable criticalSpares={criticalSpares} />
                        )}
                    </Loader>
                </Panel>
            )}
        </Page>
    );
};
