export type EmeraldButtonVariantType =
  | 'Primary'
  | 'TextButton'
  | 'Secondary'
  | 'Light'
  | 'Custom';

export enum EmeraldButtonVariant {
  Primary = 'Primary',
  TextButton = 'TextButton',
  Secondary = 'Secondary',
  Light = 'Light',
  Custom = 'Custom',
}
