import { IAppAction } from './../app-action';
import { IComment } from 'models/comment';
import { ICommentQuery } from 'models/comment-query';
import { Entity } from 'models/entity';

export enum CommentActions {
    LOAD_COMMENTS = '@@comment/LOAD_COMMENTS',
    LOAD_COMMENTS_FULFILLED = '@@comment/LOAD_COMMENTS_FULFILLED',
    LOAD_COMMENTS_REJECTED = '@@comment/LOAD_COMMENTS_REJECTED',

    CREATE_COMMENT = '@@comment/CREATE_COMMENT',
    CREATE_COMMENT_FULFILLED = '@@comment/CREATE_COMMENT_FULFILLED',
    CREATE_COMMENT_REJECTED = '@@comment/CREATE_COMMENT_REJECTED',

    UPDATE_COMMENT = '@@comment/UPDATE_COMMENT',
    UPDATE_COMMENT_FULFILLED = '@@comment/UPDATE_COMMENT_FULFILLED',
    UPDATE_COMMENT_REJECTED = '@@comment/UPDATE_COMMENT_REJECTED',

    DELETE_COMMENT = '@@comment/DELETE_COMMENT',
    DELETE_COMMENT_FULFILLED = '@@comment/DELETE_COMMENT_FULFILLED',
    DELETE_COMMENT_REJECTED = '@@comment/DELETE_COMMENT_REJECTED',

    SET_MESSAGE = '@@comment/SET_MESSAGE',
    SET_EDIT_MESSAGE = '@@comment/SET_EDIT_MESSAGE',
    SET_EDIT_ID = '@@comment/SET_EDIT_ID',

    LOAD_RISK_COMMENTS = '@@comment/LOAD_RISK_COMMENTS',
    LOAD_RISK_COMMENTS_FULFILLED = '@@comment/LOAD_RISK_COMMENTS_FULFILLED',
    LOAD_RISK_COMMENTS_REJECTED = '@@comment/LOAD_RISK_COMMENTS_REJECTED',

    CREATE_RISK_COMMENT = '@@comment/CREATE_RISK_COMMENT',
    CREATE_RISK_COMMENT_FULFILLED = '@@comment/CREATE_RISK_COMMENT_FULFILLED',
    CREATE_RISK_COMMENT_REJECTED = '@@comment/CREATE_RISK_COMMENT_REJECTED',


    LOAD_ACTION_COMMENTS = '@@comment/LOAD_ACTION_COMMENTS',
    LOAD_ACTION_COMMENTS_FULFILLED = '@@comment/LOAD_ACTION_COMMENTS_FULFILLED',
    LOAD_ACTION_COMMENTS_REJECTED = '@@comment/LOAD_ACTION_COMMENTS_REJECTED',

    CREATE_ACTION_COMMENT = '@@comment/CREATE_ACTION_COMMENT',
    CREATE_ACTION_COMMENT_FULFILLED = '@@comment/CREATE_ACTION_COMMENT_FULFILLED',
    CREATE_ACTION_COMMENT_REJECTED = '@@comment/CREATE_ACTION_COMMENT_REJECTED',
}

export const loadComments = (model: ICommentQuery): IAppAction => {
    switch (model.entity) {
        case Entity.Risk:
            return ({
                type: CommentActions.LOAD_RISK_COMMENTS,
                payload: model
            });
        case Entity.Action:
            return ({
                type: CommentActions.LOAD_ACTION_COMMENTS,
                payload: model
            });
        default:
            return ({
                type: CommentActions.LOAD_COMMENTS,
                payload: model
            });
    }
};

export const createComment = (model: IComment): IAppAction => {
    switch (model.bucket) {
        case Entity.Risk:
            return ({
                type: CommentActions.CREATE_RISK_COMMENT,
                payload: model
            });
        case Entity.Action:
            return ({
                type: CommentActions.CREATE_ACTION_COMMENT,
                payload: model
            });
        default:
            return ({
                type: CommentActions.CREATE_COMMENT,
                payload: model
            });
    }
};

export const updateComment = (model: IComment): IAppAction => ({
    type: CommentActions.UPDATE_COMMENT,
    payload: model
});

export const deleteComment = (id: string, shard: string): IAppAction => ({
    type: CommentActions.DELETE_COMMENT,
    payload: { id, shard }
});

export const setMessage = (message: string): IAppAction => ({
    type: CommentActions.SET_MESSAGE,
    payload: message
});

export const setEditMessage = (message: string): IAppAction => ({
    type: CommentActions.SET_EDIT_MESSAGE,
    payload: message
});

export const setEditId = (id: string): IAppAction => ({
    type: CommentActions.SET_EDIT_ID,
    payload: id
});
