import * as React from 'react';
import { connect } from 'react-redux';
import { DatePickerField } from 'components/form/fields/date-picker-field';
import { FormikProps } from 'formik';
import {
    ICriticalSystemSpare,
    Criticality,
    ICriticalSystemSpareSubCategory,
} from 'models/critical-system-spares/critical-system-spare';
import { NumberField } from 'components/form/fields/number-field';
import { Panel } from 'components/v2/components';
import { SelectField } from 'components/form/fields/select-field';
import { TextField } from 'components/form/fields/text-field';
import selectors from './selectors';
import { IKeyValue } from 'models/key-value';
import useTranslate from 'translations/translation-utils';
import Asset from 'components/asset/asset';
import { IAssetModel } from 'models/asset/asset-model';
import appConfig from 'helpers/config-helper';
import _ from 'lodash';

interface IProps {
    formikBag: FormikProps<ICriticalSystemSpare>;
    categories: Array<IKeyValue<string>>;
    subCategories: ICriticalSystemSpareSubCategory[];
}

export const SpareDetailsTab: React.FC<IProps> = ({ formikBag, categories, subCategories }) => {
    const translate = useTranslate();
    const config = appConfig();

    const testFrequencyOptions = [
        { value: 'daily', label: 'Daily' },
        { value: 'weekly', label: 'Weekly' },
        { value: 'monthly', label: 'Monthly' },
        { value: 'quarterly', label: 'Quarterly (every 3 months)' },
        { value: 'annually', label: 'Annually' },
    ];
    const isCriticalEnabled =
        formikBag.values.isCritical === undefined || formikBag.values.isCritical;
    const subCategoryOptions = subCategories.filter(
        c => c.category === formikBag.values.categoryId
    );

    const handleDateChange = (newDate: Date) => {
        formikBag.setFieldValue('lastTestedDate', newDate);
    };
    const handleFrequencyChange = (field: { value: string; label: string }) => {
        formikBag.setFieldValue('testFrequencyId', field.value);

        const fieldLabel = testFrequencyOptions.find(c => c.value === field.value)?.label;
        formikBag.setFieldValue('testFrequency', fieldLabel ?? field.label);
    };
    const handleOwnerChange = (field: { value: string; label: string }) => {
        formikBag.setFieldValue('ownerId', field.value);
        formikBag.setFieldValue('ownerName', field.label);
    };
    const handleCategoryChange = (field: { value: string; label: string }) => {
        formikBag.setFieldValue('categoryId', field.value);

        const fieldLabel = categories.find(c => c.key === field.value)?.label;
        formikBag.setFieldValue('categoryName', fieldLabel ?? field.label);

        formikBag.setFieldValue('subCategoryId', '');
        formikBag.setFieldValue('subCategoryName', '');
    };
    const handleSubCategoryChange = (field: { value: string; label: string }) => {
        formikBag.setFieldValue('subCategoryId', field.value);

        const fieldLabel = subCategories.find(c => c.key === field.value)?.value;
        formikBag.setFieldValue('subCategoryName', fieldLabel);
    };
    const handleCriticalityChange = (field: { value: string; label: string }) => {
        formikBag.setFieldValue('isCritical', field.value === Criticality.Critical);
    };
    const checkSubCategoryExisted = () => {
        if (!formikBag.values.subCategoryId) {
            return true;
        }
        return subCategoryOptions.some(
            subCategory => subCategory.value === formikBag.values.subCategoryId
        );
    };

    const setAssetFieldValues = (values: IAssetModel) => {
        formikBag.setFieldValue('asset.manufacturer', values.manufacturer);
        formikBag.setFieldValue('asset.model', values.model);
        formikBag.setFieldValue('asset.serial', values.serial);
        formikBag.setFieldValue('asset.cmmsReference', values.cmmsReference);
        formikBag.setFieldValue('asset.name', values.name);
        formikBag.setFieldValue('asset.age', values.age);
    }

    return (
        <>
            <div className="tabs-container tab-content">
                <div className="section">
                    <div className="columns">
                        <div className="column">
                            <TextField
                                id="name"
                                label={translate('SpareDetails.Lable.1') + '*'}
                                value={formikBag.values.name}
                                handleChange={formikBag.handleChange}
                                setVal={formikBag.setFieldValue}
                                error={formikBag.errors.name}
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column">
                            <SelectField
                                id="categoryId"
                                label={
                                    translate('RiskRegisterPage.RiskFilter.Title.Category') + '*'
                                }
                                error={formikBag.errors.categoryName}
                                value={{
                                    value: formikBag.values.categoryId,
                                    label: translate('SpareDetails.Category.dropdown.', formikBag.values.categoryId),
                                }}
                                options={categories.map(c => ({
                                    value: c.key,
                                    label: translate('SpareDetails.Category.dropdown.', c.value),
                                }))}
                                handleChange={handleCategoryChange}
                            />
                        </div>
                        <div className="column">
                            <SelectField
                                id="subCategoryId"
                                label={translate('SpareDetails.Lable.2') + '*'}
                                error={formikBag.errors.subCategoryName}
                                value={{
                                    value: formikBag.values.subCategoryId,
                                    label: translate('SpareDetails.SubCategory.dropdown.', formikBag.values.subCategoryId),
                                }}
                                options={_
                                    .sortBy(subCategoryOptions, x => x.key.toLowerCase())
                                    .map(c => ({
                                        value: c.key,
                                        label: translate('SpareDetails.SubCategory.dropdown.', c.value),
                                    }))}
                                handleChange={handleSubCategoryChange}
                            />
                            {!checkSubCategoryExisted() && (
                                <p className="has-text-warning">
                                    {translate('SpareDetailsTab.Lable.1')}
                                </p>
                            )}
                        </div>
                        <div className="column js-4">
                            <SelectField
                                id="isCritical"
                                label={translate('FilterDialog.Checkbox.Criticality') + '*'}
                                error={formikBag.errors.isCritical}
                                value={{
                                    value: isCriticalEnabled
                                        ? Criticality.Critical
                                        : Criticality.NonCritical,
                                    label: isCriticalEnabled
                                        ? translate('SpareDetails.Criticality.dropdown.', Criticality.Critical)
                                        : translate('SpareDetails.Criticality.dropdown.', Criticality.NonCritical),
                                }}
                                options={[
                                    { value: Criticality.Critical, label: translate('SpareDetails.Criticality.dropdown.', Criticality.Critical) },
                                    { value: Criticality.NonCritical, label: translate('SpareDetails.Criticality.dropdown.', Criticality.NonCritical), },
                                ]}
                                handleChange={handleCriticalityChange}
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column">
                            <NumberField
                                id="quantityMin"
                                label={translate('SpareDetails.Lable.4') + '*'}
                                value={formikBag.values.quantityMin}
                                handleChange={formikBag.handleChange}
                                error={formikBag.errors.quantityMin}
                                decimalSeparator={false}
                                allowNegative={false}
                            />
                        </div>
                        <div className="column">
                            <NumberField
                                id="quantityMax"
                                label={translate('SpareDetails.Lable.5') + '*'}
                                value={formikBag.values.quantityMax}
                                handleChange={formikBag.handleChange}
                                error={formikBag.errors.quantityMax}
                                allowNegative={false}
                            />
                        </div>
                        <div className="column">
                            <SelectField
                                id="ownerId"
                                label={translate('SpareDetails.Lable.6')}
                                value={{
                                    value: formikBag.values.ownerId,
                                    label: formikBag.values.ownerName,
                                }}
                                options={[
                                    { value: 'cbre', label: 'CBRE' },
                                    {
                                        value: 'client',
                                        label: translate('Globals.Category.dropdown.Client'),
                                    },
                                ]}
                                handleChange={handleOwnerChange}
                            />
                        </div>
                    </div>
                    <div className="subtitle">{translate('SpareDetails.Lable.8')}</div>
                    <div className="columns">
                        <div className="column">
                            <TextField
                                id="modelNumber"
                                label={translate('SpareDetails.Lable.9')}
                                value={formikBag.values.modelNumber}
                                setVal={formikBag.setFieldValue}
                                handleChange={formikBag.handleChange}
                            />
                        </div>
                        <div className="column">
                            <TextField
                                id="partNumber"
                                label={translate('SpareDetails.Lable.10')}
                                value={formikBag.values.partNumber}
                                setVal={formikBag.setFieldValue}
                                handleChange={formikBag.handleChange}
                            />
                        </div>
                        <div className="column">
                            <TextField
                                id="serialNumber"
                                label={translate('SpareDetails.Lable.11')}
                                value={formikBag.values.serialNumber}
                                setVal={formikBag.setFieldValue}
                                handleChange={formikBag.handleChange}
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column">
                            <NumberField
                                id="estimateValue"
                                label={translate('SpareDetails.Lable.12')}
                                value={formikBag.values.estimateValue || 0}
                                handleChange={formikBag.handleChange}
                                thousandSeparator={true}
                                allowNegative={false}
                            />
                        </div>
                        <div className="column">
                            <TextField
                                id="manufacturer"
                                label={translate('CriticalSystemSparesTable.Columns.Manufacturer')}
                                value={formikBag.values.manufacturer}
                                setVal={formikBag.setFieldValue}
                                handleChange={formikBag.handleChange}
                            />
                        </div>
                        <div className="column">
                            <TextField
                                id="location"
                                label={translate('SpareDetails.Lable.13')}
                                value={formikBag.values.location}
                                setVal={formikBag.setFieldValue}
                                handleChange={formikBag.handleChange}
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column">
                            <NumberField
                                id="amp"
                                label="AMPs"
                                value={formikBag.values.amp || 0}
                                handleChange={formikBag.handleChange}
                                thousandSeparator={true}
                                allowNegative={false}
                            />
                        </div>
                        <div className="column">
                            <NumberField
                                id="volts"
                                label="Volts"
                                value={formikBag.values.volts || 0}
                                handleChange={formikBag.handleChange}
                                thousandSeparator={true}
                                allowNegative={false}
                            />
                        </div>
                        <div className="column">
                            <DatePickerField
                                id="lastTestedDate"
                                label={translate('SpareDetails.Lable.14')}
                                value={formikBag.values.lastTestedDate}
                                handleChange={handleDateChange}
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-one-third">
                            <SelectField
                                id="testFrequency"
                                label={translate('SpareDetails.Lable.17')}
                                value={{
                                    value: formikBag.values.testFrequencyId,
                                    label: translate('SpareDetails.TestFrequency.dropdown.', formikBag.values.testFrequencyId),
                                }}
                                options={testFrequencyOptions.map(f => ({
                                    value: f.value,
                                    label: translate('SpareDetails.TestFrequency.dropdown.', f.value),
                                }))}
                                handleChange={handleFrequencyChange}
                            />
                        </div>
                    </div>
                </div>
                {config.REACT_APP_FEATURE_FLAG_ASSET_INTEGRATION === 'true' && (
                    <div className="section">
                        <div className="columns">
                            <div className="column column is-12">
                                <Asset
                                    showLabel={true}
                                    isManadatory={false}
                                    setValue={setAssetFieldValues}
                                    isValid={() => { }}
                                    selectedAsset={{
                                        siteId: formikBag.values?.asset?.siteId,
                                        manufacturer: formikBag.values?.asset?.manufacturer,
                                        model: formikBag.values?.asset?.model,
                                        serial: formikBag.values?.asset?.serial,
                                        cmmsReference: formikBag.values?.asset?.cmmsReference,
                                        name: formikBag.values?.asset?.name,
                                        age: formikBag.values?.asset?.age,
                                    }}
                                    isDisabled={false}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Panel title={translate('SpareDetails.Lable.15')}>
                <div className="section">
                    <textarea
                        id="notes"
                        className="textarea is-primary"
                        placeholder={translate('SpareDetails.Lable.15') + '...'}
                        value={formikBag.values.notes ? formikBag.values.notes : ''}
                        onChange={formikBag.handleChange}
                        onBlur={formikBag.handleBlur}
                    />
                </div>
            </Panel>
        </>
    );
};

export default connect(selectors, null)(SpareDetailsTab);
