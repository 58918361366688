import { combineReducers } from 'redux';
import { CalendarActions, CalendarActionTypes } from 'actions/calendar-actions';
import { CalendarActionsV2 } from 'actions/actions-v2/calendar-action-v2';
import { ICalendarState } from 'state/calendar-state';
import { IAppAction } from 'actions/app-action';
const INITIAL_STATE: ICalendarState = {
    calendarItems: [],
    lookupSites: [],
    selectedValues: [],
    isLoading: false,
    isSaving: true,
    calendarEvent: {
        uid: '',
        start: new Date(),
        end: new Date(),
        calendarItemType: 'Site Events',
        className: 'yellow',
    },
    siteLinkUrl: '',
};

const isLoading = (state: boolean = INITIAL_STATE.isLoading, action: IAppAction) => {
    switch (action.type) {
        case CalendarActions.LOAD_PAGE:
        case CalendarActions.LOAD_CALENDAR_EVENTS:
        case CalendarActionsV2.CREATE_CALENDAR_EVENTS:
        case CalendarActionsV2.UPDATE_CALENDAR_EVENTS:
        case CalendarActionsV2.DELETE_CALENDAR_EVENTS:
        case CalendarActionsV2.LOAD_CALENDAR_EVENT:
        case CalendarActionsV2.SUBSCRIBE_CALENDAR_EVENT:
            return true;

        case CalendarActions.LOAD_PAGE_FULFILLED:
        case CalendarActions.LOAD_PAGE_CANCELLED:
        case CalendarActions.LOAD_PAGE_REJECTED:
        case CalendarActions.LOAD_CALENDAR_EVENTS_CANCELLED:
        case CalendarActions.LOAD_CALENDAR_EVENTS_FULFILLED:
        case CalendarActions.LOAD_CALENDAR_EVENTS_REJECTED:
        case CalendarActionsV2.CREATE_CALENDAR_EVENTS_FULFILLED:
        case CalendarActionsV2.UPDATE_CALENDAR_EVENTS_FULFILLED:
        case CalendarActionsV2.DELETE_CALENDAR_EVENTS_FULFILLED:
        case CalendarActionsV2.LOAD_CALENDAR_EVENT_FULFILLED:
        case CalendarActionsV2.CREATE_CALENDAR_EVENTS_REJECTED:
        case CalendarActionsV2.UPDATE_CALENDAR_EVENTS_REJECTED:
        case CalendarActionsV2.DELETE_CALENDAR_EVENTS_REJECTED:
        case CalendarActionsV2.LOAD_CALENDAR_EVENT_REJECTED:
        case CalendarActionsV2.SUBSCRIBE_CALENDAR_EVENT_FULFILLED:
        case CalendarActionsV2.SUBSCRIBE_CALENDAR_EVENT_REJECTED:
            return false;

        default:
            return state;
    }
};

const isSaving = (state: boolean = INITIAL_STATE.isSaving, action: IAppAction) => {
    switch (action.type) {
        case CalendarActionsV2.CREATE_CALENDAR_EVENTS:
        case CalendarActionsV2.UPDATE_CALENDAR_EVENTS:
        case CalendarActionsV2.DELETE_CALENDAR_EVENTS:
            return true;

        case CalendarActionsV2.CREATE_CALENDAR_EVENTS_FULFILLED:
        case CalendarActionsV2.UPDATE_CALENDAR_EVENTS_FULFILLED:
        case CalendarActionsV2.DELETE_CALENDAR_EVENTS_FULFILLED:
            return false;

        default:
            return state;
    }
};
const calendarEvent = (
    state: ICalendarState['calendarEvent'] = INITIAL_STATE.calendarEvent,
    action: IAppAction
) => {
    switch (action.type) {
        case CalendarActionsV2.LOAD_CALENDAR_EVENT:
            return [];

        case CalendarActionsV2.LOAD_CALENDAR_EVENT_FULFILLED:
            return action.payload;
        case CalendarActionsV2.RESET_CALENDAR_EVENT:
            return INITIAL_STATE.calendarEvent;

        default:
            return state;
    }
};

const calendarItems = (
    state: ICalendarState['calendarItems'] = INITIAL_STATE.calendarItems,
    action: IAppAction
) => {
    switch (action.type) {
        case CalendarActions.LOAD_CALENDAR_EVENTS_FULFILLED:
            return action.payload.calendarItems;
        case CalendarActionsV2.CREATE_CALENDAR_EVENTS_FULFILLED:
            return [...state, action.payload];
        case CalendarActionsV2.UPDATE_CALENDAR_EVENTS_FULFILLED:
            const event = action.payload;
            const events = state.map((e) => {
                if (event) {
                    if (e.id === event.id) {
                        return event;
                    }
                }
                return e;
            });
            return [...state, events];
        case CalendarActionsV2.DELETE_CALENDAR_EVENTS_FULFILLED:
            return state.filter((item) => item.id !== action.payload.id);
        default:
            return state;
    }
};

const selectedValues = (
    state: ICalendarState['selectedValues'] = INITIAL_STATE.selectedValues,
    action: CalendarActionTypes
) => {
    switch (action.type) {
        case CalendarActions.FILTER_CALENDAR_ITEMS:
            if (state.indexOf(action.payload.type) > -1) {
                return state.filter((f) => f !== action.payload.type);
            }
            const newState = state.filter((f) => f);
            newState.push(action.payload.type);
            return newState;
        default:
            return state;
    }
};

const lookupSites = (
    state: ICalendarState['lookupSites'] = INITIAL_STATE.lookupSites,
    action: CalendarActionTypes
) => {
    switch (action.type) {
        default:
            return state;
    }
};
const siteLinkUrl = (
    state: ICalendarState['siteLinkUrl'] = INITIAL_STATE.siteLinkUrl,
    action: IAppAction
) => {
    switch (action.type) {
        case CalendarActionsV2.SUBSCRIBE_CALENDAR_EVENT_FULFILLED:
            return action.payload;
        default:
            return state;
    }
};

export const reducer = combineReducers<ICalendarState>({
    lookupSites,
    calendarItems,
    selectedValues,
    isLoading,
    calendarEvent,
    isSaving,
    siteLinkUrl,
});
