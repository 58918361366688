import { SiteRelationshipsActions, SiteRelationshipsActionTypes } from 'actions/site-relationships-actions';
import { ITypeaheadItem } from 'components/typeahead';
import { ISiteProfile } from 'models/site-profile';

export default {
    onValueChanged: (value: string): SiteRelationshipsActionTypes => ({
        type: SiteRelationshipsActions.CHANGE_SITE_SEARCH_VALUE,
        payload: {
            value
        }
    }),

    onSelectionChanged: (selection: ITypeaheadItem<ISiteProfile>): SiteRelationshipsActionTypes => ({
        type: SiteRelationshipsActions.SELECT_SITE,
        payload: {
            site: selection.value
        }
    })
};
