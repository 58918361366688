import * as React from 'react';
import './Checkbox.scss';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconColor, MaterialIconType } from 'routes/material-icon/material-icon-type';

export enum TriStateStatus {
    allChecked = 1,
    someChecked = 2,
    noneChecked = 3,
}

export interface ICheckbox extends React.AllHTMLAttributes<HTMLInputElement> {
    label?: string;
    checked: boolean;
    id: string;
    filterValue?: string;
    triStateStatus?: TriStateStatus;
    value?: string;
}

// tslint:disable-next-line:no-empty-interface
export interface IProps extends ICheckbox {
}

export const Checkbox: React.FC<IProps> = (props) => {

    const getIconUncheckedClass = () => {
        if (props.triStateStatus &&
            props.triStateStatus === TriStateStatus.someChecked) {
            return 'icon-checkbox__icon';
        }
        return props.checked ? 'icon-checkbox__icon' : 'icon-checkbox__icon--hidden';
    };

    const getNoTextClass = () => {
        if (props.triStateStatus &&
            props.triStateStatus === TriStateStatus.someChecked) {
            return 'sub-checked';
        }
        return '';
    };

    const getIconType = () => {
        if (props.triStateStatus &&
            props.triStateStatus === TriStateStatus.allChecked) {
            return MaterialIconType.Check;
        }
        if (props.triStateStatus &&
            props.triStateStatus === TriStateStatus.someChecked) {
            return MaterialIconType.Minus;
        }
        return props.checked ? MaterialIconType.Check : null;
    };

    return (
        <div className="icon-checkbox">
            <input
                id={props.id}
                type={'checkbox'}
                checked={props.checked}
                onChange={props.onChange}
                disabled={props.disabled}
                value={props.value}
            />
            <label htmlFor={props.id} className={props.label ? 'has-text' : getNoTextClass()}>
                <span className="icon-checkbox__label">{props.label && (props.label)}</span>
                < MaterialIcons className={getIconUncheckedClass()} type={getIconType()} color={MaterialIconColor.white} />
            </label>
        </div>
    );
};
