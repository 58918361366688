import { createSelector } from 'reselect';
import { IConfirmDeleteOutcome } from 'reducers/upsert-cerm-process-step';

const getOutcomeToDelete = (state: IConfirmDeleteOutcome) => {
    return state.outcomeItemToDelete;
};

export default createSelector(
    [getOutcomeToDelete],
    (itemForConfirmation) => ({
        itemForConfirmation,
    })
);
