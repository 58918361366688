import React from 'react';
import { Table, Panel, TableHeader, SortOrder } from '../v2/components';
import { onRouteChange } from 'actions/app-actions';
import { IRisk, ClientSharedStatus } from 'models/risk';
import { DateTime, Format } from '../dateTime';
import { sortArrayBy } from 'utils/sort-utils';
import {
    getRiskActionCount,
    getRiskActionCountClass,
    getRiskLevel,
} from './shared/risk-register-utils';
import { usePageUIPreferences, UiPreferences } from 'utils/page-ui-preferences-utils';
import useTranslate from 'translations/translation-utils';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import {
    MaterialIconColor,
    MaterialIconSize,
    MaterialIconType,
} from 'routes/material-icon/material-icon-type';
import './risk.scss'

interface IProps {
    componentId?: string;
    title?: string;
    risks: IRisk[];
    removeColumnHeaders?: boolean;
    removePanelHeader?: boolean;
    type?: 'info' | 'warning';
    collapsed?: boolean;
}

export const RiskRegisterTable: React.FC<IProps> = ({
    title,
    risks,
    removeColumnHeaders: removeColumnHeaders,
    removePanelHeader: removePanelHeader,
    type,
    collapsed,
    componentId = 'risk-table',
}) => {
    const translate = useTranslate();
    const [sortField, setSortField] = usePageUIPreferences(
        UiPreferences.RisksPageSortField,
        'riskLevelIdSortable'
    );
    const [sortOrder, setSortOrder] = usePageUIPreferences(
        UiPreferences.RisksPageSortOrder,
        SortOrder.desc
    );

    React.useEffect(() => {
        setSortOrder(SortOrder.off);
    }, []);

    const onToggleHandler = (field: string) => {
        if (sortField !== field) {
            setSortField(field);
            setSortOrder(SortOrder.asc);
            return;
        }
        const sort = sortOrder === SortOrder.asc ? SortOrder.desc : SortOrder.asc;
        setSortOrder(sort);
    };

    const getSortState = (field: string): SortOrder => {
        if (field === sortField) {
            return sortOrder;
        }
        return SortOrder.off;
    };

    const getSortedList = (): IRisk[] => {
        if (sortOrder === SortOrder.off) {
            return sortArrayBy('riskLevelIdSortable', risks, false);
        }
        return sortOrder === SortOrder.asc
            ? sortArrayBy(sortField, risks, true)
            : sortArrayBy(sortField, risks, false);
    };

    const openDetails = (id: string) => {
        onRouteChange(`/RiskRegister/${id}/details`);
    };

    const getSortableTableHeader = (
        key: Extract<keyof IRisk, string>,
        displayName: string,
        className?: string
    ) => {
        return (
            <TableHeader
                sortState={getSortState(key)}
                onToggle={() => onToggleHandler(key)}
                className={className}
            >
                {displayName}
            </TableHeader>
        );
    };

    const buildTableInPanel = () => (
        <Panel
            title={!title ? translate('RiskRegisterPage.RiskRegisterTable.Title.ListOfRisks') : title}
            type={type}
            collapsed={collapsed}
            componentId={componentId}
        >
            {buildTable()}
        </Panel>
    );

    const buildTable = () => (
        <Table>
            {!removeColumnHeaders && (
                <thead>
                    <tr>
                        {getSortableTableHeader(
                            'uid',
                            translate('RiskRegisterPage.RiskRegisterTable.Title.Uid')
                        )}
                        {getSortableTableHeader(
                            'createdDateTime',
                            translate('RiskRegisterPage.RiskRegisterTable.Title.CreatedDateTime')
                        )}
                        {getSortableTableHeader(
                            'riskLevelIdSortable',
                            translate('RiskRegisterPage.RiskRegisterTable.Title.Level')
                        )}
                        {getSortableTableHeader(
                            'title',
                            translate('RiskRegisterPage.RiskRegisterTable.Title.Title')
                        )}
                        {getSortableTableHeader(
                            'riskCategory',
                            translate('RiskRegisterPage.RiskRegisterTable.Title.Category')
                        )}
                        {getSortableTableHeader(
                            'riskSubCategory',
                            translate('RiskRegisterPage.RiskRegisterTable.Title.SubCategory')
                        )}
                        {getSortableTableHeader(
                            'assignee',
                            translate('RiskRegisterPage.RiskRegisterTable.Title.Assignee')
                        )}
                        {getSortableTableHeader(
                            'riskStatus',
                            translate('RiskRegisterPage.RiskRegisterTable.Title.Status')
                        )}
                        {getSortableTableHeader(
                            'clientSharedStatus',
                            translate('RiskRegisterPage.RiskRegisterTable.Title.ClientShared'),
                            'narrow no-wrap'
                        )}
                        {getSortableTableHeader(
                            'reviewDate',
                            translate('RiskRegisterPage.RiskRegisterTable.Title.Review')
                        )}
                    </tr>
                </thead>
            )}
            <tbody className="clickable">
                {getSortedList().map((item, index) => {
                    const actionCount = getRiskActionCount(item);

                    return (
                        <tr key={index} onClick={() => openDetails(item.id)}>
                            <td>{item.uid}</td>
                            <td className="no-wrap">
                                <DateTime value={item.createdDateTime} format={Format.DateOnly} />
                            </td>
                            <td>
                                <span>{getRiskLevel(item)}</span>
                            </td>
                            <td>{item.title && item.riskCategory === 'ElogBooks' ? `${translate('RiskDetailsTab.Label.Elogbooks.', item.title.split('-')[0])} - ${item.title.split("-").slice(1).join("-")}` : item.title}</td>
                            <td>{translate('Globals.Category.', item.riskCategory)}</td>
                            <td>{item.riskSubCategory}</td>
                            <td>{item?.assignee?.displayLabel}</td>
                            <td>{translate('RiskDetailsPage.Status.dropdown.', item.riskStatus)}{item.mitigationLevel ? ' - ' + item.mitigationLevel : ''}</td>
                            <td>
                                {item.clientSharedStatus !== ClientSharedStatus.InternalOnly && (
                                    <span>
                                        <MaterialIcons type={MaterialIconType.Person}
                                            color={
                                                item.clientSharedStatus ===
                                                    ClientSharedStatus.ExternalConfirmed
                                                    ? MaterialIconColor.lightGreen
                                                    : item.clientSharedStatus ===
                                                        ClientSharedStatus.ExternalAwaitingConfirmation
                                                        ? MaterialIconColor.orange
                                                        : MaterialIconColor.red
                                            }
                                            size={MaterialIconSize.medium}
                                        />
                                    </span>
                                )}
                            </td>
                            <td className="no-wrap">
                                <DateTime value={item.reviewDate} format={Format.DateOnly} />
                                <span className={getRiskActionCountClass(item, actionCount)}>
                                    {actionCount > 0 ? actionCount : ''}
                                </span>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );

    return <>{removePanelHeader ? buildTable() : buildTableInPanel()}</>;
};
