import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';

const getIsLoading = (state: IRootState) => state.shiftsState.isLoading;
const getSiteId = (state: IRootState) => state.app.siteId;
const getShifts = (state: IRootState) => state.shiftsState.shifts;
const getPermissions = (state: IRootState) => state.app.permissions;
const getLookupSkillCategories = (state: IRootState) => {
    return state.engineersState.skillCategories.filter((c) => c.isActive);
};

export default createSelector(
    getIsLoading,
    getSiteId,
    getShifts,
    getPermissions,
    getLookupSkillCategories,
    (isLoading, siteId, shifts, permissions, skillCategories) => ({
        isLoading,
        siteId,
        shifts,
        permissions,
        skillCategories,
    })
);
