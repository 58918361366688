import * as React from 'react';
import { IDifference } from 'models/difference';
import { CreatedEngineerEntitytHistoryItem } from './create-engineer-entity-item';
import { EngineerEntitytHistoryItem } from './engineer-entity-history-item';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';

interface IProps {
    entityHistory: IDifference[][];
    entity: any;
    intl: any;
}

class EngineerEntityHistoryClass extends React.PureComponent<IProps> {
    public render() {
        return (
            <div className="History">
                <div className="Timeline-offset history grid-headers columns ">
                    <div className="column is-10">
                        {translate(this.props.intl, 'RiskDetailsPage.label.Description')}
                    </div>
                    <div className="column is-5">
                        {translate(this.props.intl, 'History.HistoryItem.DateTime')}
                    </div>
                </div>
                {this.props.entityHistory && this.props.entityHistory.map((entityHistory) => {
                    // tslint:disable-next-line: jsx-key
                    return <EngineerEntitytHistoryItem differences={entityHistory} />;
                })}
                <CreatedEngineerEntitytHistoryItem entity={this.props.entity} />
            </div>
        );
    }
}

export const EngineerEntityHistory = injectIntl(EngineerEntityHistoryClass);