/* eslint-disable @typescript-eslint/no-inferrable-types */
import { IEmeraldDisabledInput } from '../shared/disabled.input';
import { IEmeraldErrorTextInput } from '../shared/error.text.input';
import { IEmeraldHelperTextInput } from '../shared/helper.text.input';
import { IEmeraldIdInput } from '../shared/id.input';
import { IEmeraldLabelInput } from '../shared/label.input';
import { IEmeraldLeadingIconInput } from '../shared/leading-icon.input';
import { IEmeraldOutlinedInput } from '../shared/outlined.input';
import { IEmeraldRequiredInput } from '../shared/required.input';
import { IEmeraldTitleInput } from '../shared/title.input';
import { IEmeraldClassesInput } from '../shared/classes.input';
import { IEmeraldValidInput } from '../shared/valid.input';

export type Option<T> = {
  label: string;
  value: T;
  selected?: boolean;
  disabled?: boolean;
  children?: Option<T>[];
  filteredChildren?: Option<T>[]; // Used for filtering only
};

export class EmeraldDropdownCoreInputs<T>
  implements IEmeraldDropdownCoreInputs<T>
{
  options: Option<T>[] = [];
  multiSelect?: boolean = false;
  id?: string = `dropdown${Math.random()}`;
  title?: string;
  label?: string = `Select Item`;
  disabled?: boolean = false;
  required?: boolean = false;
  outlined?: boolean = false;
  errorText?: string;
  helperText?: string;
  leadingIcon?: string;
  classes?: string = '';
  isValid?: boolean = true;
}

export interface IEmeraldDropdownCoreInputs<T>
  extends IEmeraldClassesInput,
    IEmeraldIdInput,
    IEmeraldLabelInput,
    IEmeraldDisabledInput,
    IEmeraldOutlinedInput,
    IEmeraldRequiredInput,
    IEmeraldLeadingIconInput,
    IEmeraldErrorTextInput,
    IEmeraldHelperTextInput,
    IEmeraldTitleInput,
    IEmeraldValidInput {
  options: Option<T>[];
  multiSelect?: boolean;
  preSelectedValue?: string;
  preSelectedLabel?: string;
}
