import { createSelector } from 'reselect';
import { ActionOrEvent } from '../../../models/cerm-process-step';
import { IKeyValue } from '../../../models/key-value';
import {
    ICermProcessStepState,
    IUpsertCermProcessStepRootState,
} from '../../../reducers/upsert-cerm-process-step';
import { isCreating } from '../../../utils/id-utils';

export const getUpsertCermProcessStepState = (state: IUpsertCermProcessStepRootState) =>
    state.cermProcessStepState;

export const isCreatingQuestionSet = createSelector(
    getUpsertCermProcessStepState,
    (state: ICermProcessStepState) => (isCreating(state.cermProcessStep) ? true : false)
);

export const convertToBoolean = (input: string): boolean | undefined => {
    try {
        return JSON.parse(input);
    } catch (e) {
        return undefined;
    }
};

export const convertToNumber = (input: string): number | undefined => {
    try {
        return JSON.parse(input);
    } catch (e) {
        return undefined;
    }
};

export const actionOrEventChoices: Array<IKeyValue<ActionOrEvent>> = [
    {
        key: 'Action',
        value: ActionOrEvent.Action,
    },
    {
        key: 'Event',
        value: ActionOrEvent.Event,
    },
];

export const yesNoOptions: Array<IKeyValue<string>> = [
    {
        key: 'Yes',
        value: JSON.stringify(true),
    },
    {
        key: 'No',
        value: JSON.stringify(false),
    },
];

export const stringYesNoOptions: Array<IKeyValue<string>> = [
    {
        key: 'Yes',
        value: 'Yes',
    },
    {
        key: 'No',
        value: 'No',
    },
    {
        key: 'TBC',
        value: 'TBC',
    },
];

export const dueDateUnitOptions: Array<IKeyValue<string>> = [
    {
        key: 'Days',
        value: 'day',
    },
    {
        key: 'Months',
        value: 'month',
    },
];
