import * as React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { RouteComponentProps } from 'react-router';
import { array, bool, number, object, string } from 'yup';
import { IProgressSection, ProgressBar } from 'components/progress-bar/progress-bar';
import { ArticlePanel } from '../../../components/panel';
import {
    ICermOutcome,
    ICermProcessStep,
    cermValidationGroup,
    cermProcessStepFieldTitle,
} from '../../../models/cerm-process-step';
import { IKeyValue } from '../../../models/key-value';
import { IActionTemplate, IEventTemplate } from '../../../models/question-set';
import { CermProcessStepForm } from './cerm-process-step-form';
import { CermProcessStepOutcomeForm } from './cerm-process-step-outcome-form';
import { RiskTemplateForm } from './risk-template-form';
import './upsert-cerm-process-step-page.scss';
import Page from 'components/v2/page/Page';
import { CermLinksFormContainer } from './cerm-links/cerm-links-form-container';
import { Loader } from 'components/loader';
import { CermTechnologyPlatformContainer } from './cerm-technology-platform/cerm-technology-platform-form-container';
import { ConfirmDialog } from 'components/v2/components';
import { onRouteChange } from 'actions/app-actions';
import useTranslate from 'translations/translation-utils';
import {
    RouteUrlCermLinks,
    RouteUrlOutcome,
    RouteUrlProcessStep,
    RouteUrlRiskTemplate,
    RouteUrlTechnologyPlatform,
} from '../routes';
import { IOperatingPlatform } from 'models/operating-platform-model';

export interface IUpsertCermProcessStepPageRouteParams {
    id: string;
    outcomeIndex: string;
    operatingPlatform: string;
}

export interface IProps extends RouteComponentProps<IUpsertCermProcessStepPageRouteParams> {
    isInitialising: boolean;
    pageTitle: string;
    progressSubtitle: string;
    permissions: Array<IKeyValue<string>>;
    initialValues?: Partial<ICermProcessStep>;
    isSubmitting: boolean;
    onSubmit: (cermProcessStep: Partial<ICermProcessStep>) => void;
    onChangeRoot: (urlLocation: string) => void;
    onPageLoad: (id: string) => void;
    operatingPlatforms: IOperatingPlatform[];
}

export const UpsertCermProcessStepPage: React.FC<IProps> = props => {
    const [isErrorDialogVisible, setErrorDialogVisible] = React.useState(false);
    const [isScoreCalculationDialogVisible, setScoreCalculationDialogVisible] = React.useState(
        false
    );

    const translate = useTranslate();

    React.useEffect(() => {
        props.onPageLoad(props.match.params.id);
    }, []);

    const cermProcessStepSchema = object<Partial<ICermProcessStep>>().shape({
        title: string()
            .trim()
            .required(translate('UpsertCermProcessStep.Validation.Step.Title'))
            .nullable(true),
        service: string()
            .required(translate('UpsertCermProcessStep.Validation.Step.Service'))
            .nullable(true),
        phase: string()
            .required(translate('UpsertCermProcessStep.Validation.Step.Phase'))
            .nullable(true),
        pillar: string()
            .required(translate('UpsertCermProcessStep.Validation.Step.Pillar'))
            .nullable(true),
        processNumber: number()
            .required(translate('UpsertCermProcessStep.Validation.Step.ProcessNumber'))
            .nullable(true),
        priority: string()
            .required(translate('UpsertCermProcessStep.Validation.Step.Priority'))
            .nullable(true),
        reviewPeriodMonths: number()
            .required(translate('UpsertCermProcessStep.Validation.Step.ReviewPeriod'))
            .nullable(true),
        expectedOutcomes: array().of(
            object<Partial<ICermOutcome>>().shape({
                title: string()
                    .trim()
                    .required(translate('UpsertCermProcessStep.Validation.Outcome.Title')),
                compliantResponse: bool().required(
                    translate('UpsertCermProcessStep.Validation.Outcome.Compliant')
                ),
                action: object<Partial<IActionTemplate>>()
                    .notRequired()
                    .shape({
                        description: string()
                            .max(10000, 'Description can have maximum 10000 characters.')
                            .required(translate('UpsertCermProcessStep.Validation.Outcome.Action'))
                            .nullable(false),
                        priority: string().notRequired(),
                    }),
                event: object<Partial<IEventTemplate>>()
                    .notRequired()
                    .nullable(true)
                    .shape({
                        title: string()
                            .required(translate('UpsertCermProcessStep.Validation.Outcome.Event'))
                            .nullable(false),
                        recurrenceValue: number().notRequired().nullable(true),
                    }),
            })
        ),
        cermProcessStepsLinks: object()
            .shape({
                trainingLink: string()
                    .matches(
                        /^$|(https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/,
                        translate('UpsertCermProcessStep.Validation.Links.Training')
                    )
                    .notRequired()
                    .nullable(true),
                documentLink: string()
                    .matches(
                        /^$|(https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/,
                        translate('UpsertCermProcessStep.Validation.Links.Document')
                    )
                    .notRequired()
                    .nullable(true),
            })
            .nullable(true),
        cermProcessStepPlatform: object()
            .shape({
                moreInfoLink: string()
                    .matches(
                        /^$|(https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/,
                        translate('UpsertCermProcessStep.Validation.Platform.MoreInfo')
                    )
                    .notRequired()
                    .nullable(true),
            })
            .nullable(true),
        riskTemplate: object()
            .shape({
                title: string()
                    .trim()
                    .required(translate('UpsertCermProcessStep.Validation.Risk.Title'))
                    .nullable(true),
                riskCategoryId: string()
                    .required(translate('UpsertCermProcessStep.Validation.Risk.Category'))
                    .nullable(true),
                riskSubCategoryId: string()
                    .required(translate('UpsertCermProcessStep.Validation.Risk.Subcategory'))
                    .nullable(true),
                description: string()
                    .trim()
                    .required(translate('UpsertCermProcessStep.Validation.Risk.Description'))
                    .nullable(true),
                riskLikelihoodId: string()
                    .required(translate('UpsertCermProcessStep.Validation.Risk.Likelihood'))
                    .nullable(true),
                riskImpactId: string()
                    .required(translate('UpsertCermProcessStep.Validation.Risk.Impact'))
                    .nullable(true),
            })
            .nullable(true),
    });

    const onFormikSubmit = (cermProcessStep: Partial<ICermProcessStep>) => {
        cermProcessStep.operatingPlatform = props.match.params.operatingPlatform;
        cermProcessStep.reloadUrl = getLinkWithOperatingPlatform('/CermProcessSteps');
        props.onSubmit(cermProcessStep);
        setScoreCalculationDialogVisible(false);
    };

    const getLinkWithOperatingPlatform = (url: string): string => {
        return url + '/' + props.match.params.operatingPlatform;
    };

    const getTitlePrefix = () => {
        const selectedOperatingPlatform =
            props.operatingPlatforms &&
            props.operatingPlatforms.find(x => x.value === props.match.params.operatingPlatform);
        return selectedOperatingPlatform?.shortForm.toUpperCase();
    };

    const getPageTitle = () => {
        const processStep = props.initialValues;
        if (processStep && processStep.title) {
            return `${getTitlePrefix()} ${processStep.processNumber}: ${processStep.title}`;
        }

        return translate('UpsertCermProcessStep.Views.ProcessStepPage.Add');
    };

    const title = () => {
        if (props.location.pathname.includes('RiskTemplate')) {
            return translate('UpsertCermProcessStep.Views.ProcessStepPage.RiskTemplate');
        } else if (props.location.pathname.includes('Outcomes')) {
            return `${getTitlePrefix()} ${translate(
                'UpsertCermProcessStep.Views.ProcessStepPage.Outcome'
            )}`;
        } else if (props.location.pathname.includes('CermLinks')) {
            return `${getTitlePrefix()} ${translate(
                'UpsertCermProcessStep.Views.ProcessStepPage.Links'
            )}`;
        } else if (props.location.pathname.includes('TechnologyPlatform')) {
            return `${getTitlePrefix()} ${translate(
                'UpsertCermProcessStep.Views.ProcessStepPage.Message9'
            )}`;
        } else {
            return `${getTitlePrefix()} ${translate(
                'UpsertCermProcessStep.Views.ProcessStepPage.Message8'
            )}`;
        }
    };

    const subTitle = () => {
        if (props.location.pathname.includes('RiskTemplate')) {
            return `${translate('UpsertCermProcessStep.Views.ProcessStepPage.Message')}`;
        } else if (props.location.pathname.includes('Outcomes')) {
            return `${translate('UpsertCermProcessStep.Views.ProcessStepPage.Message2')}`;
        } else if (props.location.pathname.includes('CermLinks')) {
            return `${translate('UpsertCermProcessStep.Views.ProcessStepPage.Message3')}`;
        } else if (props.location.pathname.includes('TechnologyPlatform')) {
            return `${translate('UpsertCermProcessStep.Views.ProcessStepPage.Message5')}`;
        } else {
            return `${translate(
                'UpsertCermProcessStep.Views.ProcessStepPage.Message6'
            )} ${getTitlePrefix()} ${translate(
                'UpsertCermProcessStep.Views.ProcessStepPage.Message7'
            )}`;
        }
    };

    const GetForm = (
        formikBag: import('formik').FormikProps<Partial<ICermProcessStep>>
    ): React.ReactNode => {
        if (props.location.pathname.includes('RiskTemplate')) {
            return (
                <RiskTemplateForm
                    formikBag={formikBag}
                    setErrorDialogVisible={setErrorDialogVisible}
                />
            );
        } else if (props.location.pathname.includes('Outcomes')) {
            return (
                <CermProcessStepOutcomeForm
                    shouldPublish={false}
                    formikBag={formikBag}
                    setErrorDialogVisible={setErrorDialogVisible}
                />
            );
        } else if (props.location.pathname.includes('CermLinks')) {
            return (
                <CermLinksFormContainer
                    formikBag={formikBag}
                    setErrorDialogVisible={setErrorDialogVisible}
                />
            );
        } else if (props.location.pathname.includes('TechnologyPlatform')) {
            return (
                <CermTechnologyPlatformContainer
                    formikBag={formikBag}
                    setErrorDialogVisible={setErrorDialogVisible}
                />
            );
        } else {
            return (
                <CermProcessStepForm
                    isReviewing={false}
                    formikBag={formikBag}
                    setErrorDialogVisible={setErrorDialogVisible}
                />
            );
        }
    };
    const getJsx = (error, parentError) => {
        if (Array.isArray(error)) {
            return error.map((err, index) => {
                return (
                    <>
                        {err && (
                            <div className="validation-header">
                                <strong>
                                    {`${getTitlePrefix()} ${cermProcessStepFieldTitle[parentError]
                                        }`}{' '}
                                    #{index + 1}
                                </strong>
                            </div>
                        )}
                        {getErrors(err)}
                    </>
                );
            });
        } else if (typeof error === 'object') {
            return (
                <>
                    {parentError && (
                        <div className="validation-header">
                            <strong>
                                {parentError == 'riskTemplate'
                                    ? cermProcessStepFieldTitle[parentError]
                                    : `${getTitlePrefix()} ${cermProcessStepFieldTitle[parentError]
                                    }`}
                            </strong>
                        </div>
                    )}
                    {Object.keys(error).map(err => {
                        return (
                            <>
                                <div>
                                    <span>{error[err]}</span>
                                </div>
                            </>
                        );
                    })}
                </>
            );
        } else {
            return (
                <div>
                    <span>{error}</span>
                </div>
            );
        }
    };

    const getErrors = errors => {
        return (
            errors &&
            Object.keys(errors).map(error => {
                return <>{getJsx(errors[error], error)}</>;
            })
        );
    };

    const getBody = formikBag => {
        return (
            <>
                {Object.keys(formikBag.errors).some(error =>
                    cermValidationGroup.includes(error)
                ) ? (
                    <div className="validation-header">
                        <strong>{`${getTitlePrefix()} Process Step`}</strong>
                    </div>
                ) : (
                    ''
                )}
                {getErrors(formikBag.errors)}
            </>
        );
    };

    const getProgressSections = (
        formikBag: FormikProps<Partial<ICermProcessStep>>,
        operatingPlateform: string
    ) => {
        const AdditionalRiskTitle = () => {
            if (formikBag.values.riskTemplate && formikBag.values.riskTemplate.title) {
                return formikBag.values.riskTemplate.title;
            }
            return '';
        };

        // Edit Record Progress
        const sections: IProgressSection[] = [
            {
                title: translate(
                    'UpsertCermProcessStep.Views.ProcessStepPage.Progress.ProcessStep'
                ),
                text: formikBag.values.title,
                urlLocation: RouteUrlProcessStep(operatingPlateform, formikBag.values.id),
            },
            {
                title: translate(
                    'UpsertCermProcessStep.Views.ProcessStepPage.Progress.AdditionalRisk'
                ),
                text: AdditionalRiskTitle(),
                urlLocation: RouteUrlRiskTemplate(operatingPlateform, formikBag.values.id),
            },
            {
                title: translate('UpsertCermProcessStep.Views.ProcessStepPage.Progress.AddLinks'),
                text: translate(
                    'UpsertCermProcessStep.Views.ProcessStepPage.Progress.TrainingDocumentation'
                ),
                urlLocation: RouteUrlCermLinks(operatingPlateform, formikBag.values.id),
            },
            {
                title: translate(
                    'UpsertCermProcessStep.Views.ProcessStepPage.Progress.TechnologyPlatform'
                ),
                text: translate(
                    'UpsertCermProcessStep.Views.ProcessStepPage.Progress.TechnologyPlatformSubText'
                ),
                urlLocation: RouteUrlTechnologyPlatform(operatingPlateform, formikBag.values.id),
            },
        ];

        if (formikBag.values && formikBag.values.expectedOutcomes) {
            const questionSections = formikBag.values.expectedOutcomes.map<IProgressSection>(
                (expectedOutcome, index) => {
                    if (expectedOutcome.id !== null || expectedOutcome.id === '') {
                        return {
                            title: `${translate(
                                'UpsertCermProcessStep.Views.ProcessStepPage.Progress.Outcome'
                            )} #${index + 1} `,
                            urlLocation: `${RouteUrlOutcome(
                                operatingPlateform,
                                formikBag.values.id
                            )}${index}`,
                            text: expectedOutcome.title,
                        };
                    }
                }
            );

            sections.push(...questionSections);
        }

        return sections;
    };

    const onChangeRoot = (formikBag: FormikProps<Partial<ICermProcessStep>>, url: string) => {
        if (Object.keys(formikBag.errors).some(field => field.length > 0)) {
            setErrorDialogVisible(true);
        } else {
            return onRouteChange(url);
        }
    };

    return (
        <Page
            title={getPageTitle()}
            breadcrumbCustomLinks={[
                {
                    key: getLinkWithOperatingPlatform('/CermProcessSteps'),
                    value: `${getTitlePrefix()}  ${translate(
                        'UpsertCermProcessStep.Views.ProcessStepPage.Breadcrumb'
                    )}`,
                },
            ]}
            isAdminPage={true}
        >
            <Loader loading={props.isSubmitting}>
                <div className="section UpsertCermProcessStepPage">
                    <div className="UpsertCermProcessStepForm">
                        <div className="container">
                            <Formik<Partial<ICermProcessStep>>
                                initialValues={props.initialValues}
                                validationSchema={cermProcessStepSchema}
                                onSubmit={() => setScoreCalculationDialogVisible(true)}
                                enableReinitialize={true}
                                render={formikBag => (
                                    <div className="columns">
                                        <ProgressBar
                                            title={translate(
                                                'UpsertCermProcessStep.Views.ProcessStepPage.Progress'
                                            )}
                                            subtitle={props.progressSubtitle}
                                            sections={getProgressSections(
                                                formikBag,
                                                props.match.params.operatingPlatform
                                            )}
                                            onChangeRoot={url => onChangeRoot(formikBag, url)}
                                            currentPath={props.location.pathname}
                                        />
                                        <ArticlePanel className="column">
                                            <header>
                                                <h2 className="title is-4">{title()}</h2>
                                                <small>{subTitle()}</small>
                                            </header>
                                            <Form>{GetForm(formikBag)}</Form>
                                        </ArticlePanel>
                                        <ConfirmDialog
                                            title={translate(
                                                'ActionUpsertPage.Validation.ValidationTitle'
                                            )}
                                            message={getBody(formikBag)}
                                            isVisible={isErrorDialogVisible}
                                            showConfirmButton={false}
                                            onClose={() => setErrorDialogVisible(false)}
                                            onOutsideDialogClick={() =>
                                                setErrorDialogVisible(false)
                                            }
                                            buttonCancelText={translate(
                                                'ConfirmActionDialog.Button.Close'
                                            )}
                                        />
                                        <ConfirmDialog
                                            title={translate(
                                                'UpsertCermProcessStep.Views.ProcessStepPage.Title'
                                            )}
                                            message={translate(
                                                'UpsertCermProcessStep.Views.ProcessStepPage.TitleMessage'
                                            )}
                                            isVisible={isScoreCalculationDialogVisible}
                                            showConfirmButton={false}
                                            onClose={() => onFormikSubmit(formikBag.values)}
                                            onOutsideDialogClick={() =>
                                                onFormikSubmit(formikBag.values)
                                            }
                                            buttonCancelText={translate(
                                                'ConfirmActionDialog.Button.Close'
                                            )}
                                        />
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                </div>
            </Loader>
        </Page>
    );
};
