import { FormikProps } from 'formik';
import _ from 'lodash';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { onRouteChange } from 'actions/app-actions';
import { Control, Field, Help, Radio } from '../../../../components/form';
import { BulmaColor } from '../../../../enums/BulmaColor';
import { BulmaSize } from '../../../../enums/BulmaSize';
import {
    ActionOrEvent,
    ICermOutcome,
    ICermProcessStep,
} from '../../../../models/cerm-process-step';
import {
    canSubmit,
    formikFieldCssByState,
    getKeyed,
    scrollToFirstValidationError,
} from '../../../../utils/form-utils';
import { OutcomeArchiveConfirmation } from '../../OutcomeArchiveConfirmation';
import { RouteUrlOutcome, RouteUrlTechnologyPlatform } from '../../routes';
import { ActionForm } from '../action-form';
import { actionOrEventChoices, convertToNumber, yesNoOptions } from '../common';
import { EventTemplateForm } from '../event-template-form';
import { IUpsertCermProcessStepPageRouteParams } from '../upsert-cerm-process-step-page';
import { Button } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';
import { IOperatingPlatform } from 'models/operating-platform-model';

export interface IProps extends RouteComponentProps<IUpsertCermProcessStepPageRouteParams> {
    formikBag: FormikProps<Partial<ICermProcessStep>>;
    isSubmitting: boolean;
    shouldPublish: boolean;
    index?: number;
    setErrorDialogVisible: (isDialogVisible: boolean) => void;
    operatingPlatforms: IOperatingPlatform[];
}

export const CermProcessStepOutcomeForm: React.FC<IProps> = ({
    formikBag,
    match,
    isSubmitting,
    shouldPublish,
    index,
    setErrorDialogVisible,
    operatingPlatforms,
}) => {
    const indexParam = Number(match.params && match.params.outcomeIndex);
    const outcomeIndex = indexParam >= 0 ? indexParam : index >= 0 ? index : 0;
    const translate = useTranslate();
    const validateForm = callback => {
        formikBag.validateForm().then(errors => {
            if (!Object.keys(errors).some(field => field.length > 0)) {
                callback();
            } else {
                formikBag.submitForm();
                scrollToFirstValidationError();
                setErrorDialogVisible(true);
            }
        });
    };
    const validateWithoutField = callback => {
        formikBag.validateForm().then(errors => {
            if (!Object.keys(errors).includes('expectedOutcomes')) {
                callback();
            } else {
                formikBag.submitForm();
                scrollToFirstValidationError();
            }
        });
    };
    const onDeleteOutcomeHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
        let newExpectedOutcomes = [];
        if (value('id')) {
            newExpectedOutcomes = formikBag.values.expectedOutcomes.filter(outcome => {
                return outcome.id !== value('id');
            });
        } else {
            formikBag.values.expectedOutcomes.splice(outcomeIndex, 1);
            newExpectedOutcomes = formikBag.values.expectedOutcomes;
        }

        formikBag.setFieldValue(`expectedOutcomes`, newExpectedOutcomes);

        onRouteChange(`${backLinkUrl}`);
    };

    const onSaveAsDraft: React.MouseEventHandler<HTMLButtonElement> = async () => {
        validateForm(async () => {
            await formikBag.setFieldValue('status', 'Draft');
            formikBag.submitForm();
        });
    };

    const onPublish: React.MouseEventHandler<HTMLButtonElement> = async () => {
        validateForm(async () => {
            await formikBag.setFieldValue('status', 'Published');
            formikBag.submitForm();
        });
    };

    const onNavigateNext: React.MouseEventHandler<HTMLButtonElement> = () => {
        validateWithoutField(async () => {
            onRouteChange(
                `${RouteUrlOutcome(match.params.operatingPlatform, formikBag.values.id)}${outcomeIndex + 1
                }`
            );
        });
    };

    const onNavigateBack: React.MouseEventHandler<HTMLButtonElement> = () => {
        validateWithoutField(async () => {
            onRouteChange(backLinkUrl);
        });
    };

    const backLinkUrl =
        outcomeIndex === 0
            ? RouteUrlTechnologyPlatform(match.params.operatingPlatform, formikBag.values.id)
            : `${RouteUrlOutcome(match.params.operatingPlatform, formikBag.values.id)}${outcomeIndex - 1
            }`;

    const prefix = `expectedOutcomes[${outcomeIndex}]`;
    const field = (name: Extract<keyof ICermOutcome, string>) => `${prefix}.${name}`;

    function value<T extends Extract<keyof ICermOutcome, string>>(name: T) {
        const val = getKeyed(formikBag, 'values', field(name));
        return !_.isNil(val) ? val : '';
    }

    const handleActionEvent = (event: any) => {
        formikBag.setFieldValue(field('actionOrEvent'), parseInt(event.target.value, 10));
    };

    const handleComplianceResponseEvent = (event: any) => {
        formikBag.setFieldValue(
            field('compliantResponse'),
            event.target.value === JSON.stringify(true)
        );
    };

    const getErrors = (name: Extract<keyof ICermOutcome, string>) =>
        getKeyed(formikBag, 'errors', field(name));

    const isTouched = (name: Extract<keyof ICermOutcome, string>) =>
        getKeyed(formikBag, 'touched', field(name));

    const getFieldCss = (name: Extract<keyof ICermOutcome, string>, otherCss?: string) =>
        formikFieldCssByState(Boolean(getErrors(name)), isTouched(name), otherCss);

    const getHelpByKey = (name: Extract<keyof ICermOutcome, string>) => (
        <Help isShown={formikBag.submitCount > 0 || isTouched(name)} bulmaColor={BulmaColor.Danger}>
            {getErrors(name)}
        </Help>
    );

    if (!formikBag.values.expectedOutcomes[outcomeIndex]) {
        const defaultValues: ICermOutcome = {
            compliantResponse: true,
            id: '',
            title: '',
            action: null,
            event: null,
            actionOrEvent: ActionOrEvent.Action,
        };
        formikBag.setFieldValue(`${prefix}`, defaultValues);
        return <></>;
    }

    return (
        <>
            <header className={!shouldPublish ? 'is-hidden' : null}>
                <h2 className="title is-5">
                    {`${translate(
                        'psertCermProcessStep.Views.ProcessStepPage.Progress.ExpectedOutcome'
                    )} #` +
                        (outcomeIndex + 1)}
                </h2>
            </header>

            <Field
                isHorizontal={true}
                htmlFor={field('title')}
                label={`${translate(
                    'psertCermProcessStep.Views.ProcessStepPage.Progress.Outcome'
                )} #${outcomeIndex + 1}`}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <textarea
                            id={field('title')}
                            name={field('title')}
                            aria-required="true"
                            placeholder={translate(
                                'UpsertCermProcessStep.Views.CermProcessStepOutcomeForm.Message'
                            )}
                            className={getFieldCss('title', 'textarea')}
                            value={value('title')}
                            onChange={formikBag.handleChange}
                            onBlur={formikBag.handleBlur}
                        />
                    </Control>
                    {getHelpByKey('title')}
                </Field>
            </Field>

            <Field
                isHorizontal={true}
                htmlFor={field('compliantResponse')}
                label={translate('UpsertCermProcessStep.Views.CermProcessStepOutcomeForm.Message2')}
                labelSize={BulmaSize.Medium}
                className="is-vcentered"
            >
                <Field>
                    <Control>
                        Which response indicates compliance?
                        <Radio
                            id={field('compliantResponse')}
                            name={field('compliantResponse')}
                            options={yesNoOptions}
                            className={getFieldCss('compliantResponse')}
                            value={`${value('compliantResponse')}`}
                            onBlur={formikBag.handleBlur}
                            onChange={handleComplianceResponseEvent}
                        />
                    </Control>
                    {getHelpByKey('compliantResponse')}
                </Field>

                <Field>
                    <Control>
                        {translate(
                            'UpsertCermProcessStep.Views.CermProcessStepOutcomeForm.EventQuestion'
                        )}
                        <Radio
                            id={field('actionOrEvent')}
                            name={field('actionOrEvent')}
                            options={actionOrEventChoices}
                            className={getFieldCss('actionOrEvent')}
                            value={convertToNumber(value('actionOrEvent'))}
                            onBlur={formikBag.handleBlur}
                            onChange={handleActionEvent}
                        />
                    </Control>
                    {getHelpByKey('actionOrEvent')}
                </Field>
            </Field>

            {convertToNumber(value('actionOrEvent')) === ActionOrEvent.Action && (
                <ActionForm index={outcomeIndex} formikBag={formikBag} />
            )}

            {convertToNumber(value('actionOrEvent')) === ActionOrEvent.Event && (
                <EventTemplateForm
                    index={outcomeIndex}
                    formikBag={formikBag}
                    operatingPlatforms={operatingPlatforms}
                />
            )}

            {!shouldPublish && (
                <div className="action-groups">
                    <div className="action-group">
                        <Button onClick={onNavigateBack} buttonType="cancel">
                            {translate('Globals.Label.Back')}
                        </Button>
                        {formikBag.values.id && (
                            <>
                                <Button buttonType="archive" onClick={onDeleteOutcomeHandler}>
                                    {formikBag.values.status === 'Published' ? 'Archive' : 'Delete'}
                                </Button>
                                <OutcomeArchiveConfirmation
                                    confirmMessage={
                                        formikBag.values.status &&
                                            formikBag.values.status.toLocaleLowerCase() === 'draft'
                                            ? translate(
                                                'UpsertCermProcessStep.Views.CermProcessStepOutcomeForm.Message3Delete'
                                            )
                                            : translate(
                                                'UpsertCermProcessStep.Views.CermProcessStepOutcomeForm.Message3Archive'
                                            )
                                    }
                                />
                            </>
                        )}
                    </div>

                    <div className="action-group">
                        <Button
                            disabled={!canSubmit}
                            onClick={onSaveAsDraft}
                            isLoading={isSubmitting}
                        >
                            {translate(
                                'UpsertCermProcessStep.Views.CermProcessStepOutcomeForm.Message4'
                            )}
                        </Button>

                        <Button onClick={onPublish} isLoading={isSubmitting}>
                            {translate('Button.Captions.Publish')}
                        </Button>

                        <Button onClick={onNavigateNext}>
                            {outcomeIndex !== formikBag.values.expectedOutcomes.length - 1
                                ? translate('Globals.Label.Next')
                                : translate(
                                    'UpsertCermProcessStep.Views.CermProcessStepOutcomeForm.AddOutcome'
                                )}
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
};
