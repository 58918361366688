import { toastr } from 'react-redux-toastr';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { map, tap } from 'rxjs/operators';
import {
    AppNotificationActions,
    AppNotificationActionTypes,
    ISendErrorNotification,
    ISendErrorNotificationComplete,
    ISendSuccessNotification,
    ISendSuccessNotificationComplete
} from '../actions/app-notification-actions';

const sendSuccessNotificationEpic: Epic<AppNotificationActionTypes> = (action$) =>
    action$.pipe(
        ofType(AppNotificationActions.SEND_SUCCESS_NOTIFICATION),
        tap<ISendSuccessNotification>((action) =>
            toastr.success(action.payload.title, action.payload.message)
        ),
        map<ISendSuccessNotification, ISendSuccessNotificationComplete>(() => ({
            type: AppNotificationActions.SEND_SUCCESS_NOTIFICATION_COMPLETE
        }))
    );

const sendErrorNotificationEpic: Epic<AppNotificationActionTypes> = (action$) =>
    action$.pipe(
        ofType(AppNotificationActions.SEND_ERROR_NOTIFICATION),
        tap<ISendErrorNotification>((action) =>
            toastr.error(action.payload.errorTitle, action.payload.errorMessage)
        ),
        map<ISendErrorNotification, ISendErrorNotificationComplete>(() => ({
            type: AppNotificationActions.SEND_ERROR_NOTIFICATION_COMPLETE
        }))
    );

export const appNotificationEpics = combineEpics(
    sendSuccessNotificationEpic,
    sendErrorNotificationEpic
);
