import * as React from 'react';
import { GridSortLink } from 'components/gridSortLink';
import { Loader } from 'components/loader';
import { SortDirection } from 'enums/SortDirection';
import { IIncident } from 'models';
import { onRouteChange } from 'actions/app-actions';
import {
    IncidentStatus,
    incidentStatuses,
    incidentSeverities,
    incidentTypes,
    IncidentSeverity,
} from 'models/incident-enums';
import { formatDateTime } from 'utils/date-utils';
import './incidents.scss';
import useTranslate from 'translations/translation-utils';

export interface IIncidentsListProps {
    incidents: IIncident[];
    isLoading: boolean;
    sortColumn: string;
    sortAscending: boolean;
    secondarySortColumn: string;
    secondarySortAscending: boolean;
    onSort: (sortColumn: string, sortAscending: boolean) => void;
}

export const IncidentsList: React.FC<IIncidentsListProps> = (props) => {
    const translate = useTranslate();
    const openIncident = (incidentId: string) => {
        onRouteChange(`/Incident/${incidentId}/details`);
    };

    const getIncidentItem = (incident: IIncident) => (
        <div
            className={`grid-view__item is-hoverable`}
            key={incident.id}
            onClick={() => openIncident(incident.id)}
            style={{ cursor: 'pointer' }}
        >
            <div className="columns incidents-columns">
                <div className="column is-1">
                    <span
                        className={
                            incident.severity === IncidentSeverity.S1 ||
                                incident.severity === IncidentSeverity.S2
                                ? 'status-indicator has-text-centered incidents-severity-high'
                                : 'status-indicator has-text-centered incidents-severity-medium'
                        }
                    >
                        <span className="status-count">
                            {getSeverity(incident.severity.toString())}
                        </span>
                    </span>
                </div>
                <div className="column is-2">{incident.uid}</div>
                <div className="column is-5">{incident.title}</div>
                <div className="column is-1">{getType(incident.type.toString())}</div>
                <div className="column is-2">
                    {formatDateTime(incident.createdDateTime.toString())}
                </div>
                <div className="column is-1">
                    <span
                        style={{ borderRadius: 5 }}
                        className={
                            incident.status === IncidentStatus.Open
                                ? 'incidents-status incidents-status-open'
                                : incident.status === IncidentStatus.Pending
                                    ? 'incidents-status incidents-status-pending'
                                    : incident.status === IncidentStatus.Closed
                                        ? 'incidents-status incidents-status-closed'
                                        : 'incidents-status incidents-status-draft'
                        }
                    >
                        {getStatus(incident.status.toString())}
                    </span>
                </div>
            </div>
        </div>
    );
    const getSeverity = (value: string) => {
        const severity = incidentSeverities.find((x) => x.key === value);
        if (severity) {
            return severity.value;
        }
    };

    const getStatus = (value: string) => {
        const status = incidentStatuses.find((x) => x.key === value);
        return translate('IncidentList.IncidentStatus.', status.value);
    };
    const getType = (value: string) => {
        const type = incidentTypes.find((x) => x.key === value);
        return translate('IncidentDetails.typeOptions.', type.value);
    };

    const getGridHeader = (label: string, fieldName: string, props: IIncidentsListProps) => {
        const onSort = (sortField: string, sortDirection: SortDirection) =>
            props.onSort(sortField, sortDirection === SortDirection.Ascending);
        return (
            <GridSortLink
                currentSortFieldName={props.sortColumn}
                currentSortDirection={
                    props.sortAscending ? SortDirection.Ascending : SortDirection.Descending
                }
                sortFieldName={fieldName}
                onSort={onSort}
            >
                {label}
            </GridSortLink>
        );
    };

    return (
        <Loader className="IncidentsList" loading={props.isLoading}>
            {props.incidents && props.incidents.length > 0 ? (
                <>
                    <div className={`grid-view`}>
                        <div
                            className={`grid-headers columns ${props.isLoading ? 'is-loading' : ''
                                }`}
                        >
                            <div className="column is-1">
                                {getGridHeader(
                                    translate('IncidentList.Header.Severty'),
                                    'severity',
                                    props
                                )}
                            </div>
                            <div className="column is-2">
                                {getGridHeader(
                                    translate('IncidentList.Header.Uid'),
                                    'uid',
                                    props
                                )}
                            </div>
                            <div className="column is-5">
                                {getGridHeader(
                                    translate('IncidentList.Header.Title'),
                                    'title',
                                    props
                                )}
                            </div>
                            <div className="column is-1">
                                {getGridHeader(
                                    translate('IncidentList.Header.Type'),
                                    'type',
                                    props
                                )}
                            </div>
                            <div className="column is-2">
                                {getGridHeader(
                                    translate('IncidentList.Header.Date'),
                                    'createdDateTime',
                                    props
                                )}
                            </div>
                            <div className="column is-1">
                                {getGridHeader(translate('IncidentRiskActions.Label.Status'), 'status', props)}
                            </div>
                        </div>
                        {props.incidents &&
                            props.incidents.length > 0 &&
                            props.incidents.map((incident) => getIncidentItem(incident))}
                    </div>
                </>
            ) : (
                <p className="has-text-centered">{translate('IncidentList.Label.NoData')}</p>
            )}
        </Loader>
    );
};

