import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { connect } from 'react-redux';
import { ShiftHandoverAdminUpsertPage } from './shift-handover-operating-platform-admin-upsert-page';
import {
    loadSpecificOperatingPlatformShiftChecklist,
    createSpecificSiteShiftOperatingPlatformChecklist,
    loadSelectedOperatingPlatform,
} from 'actions/actions-v2/shift-handover-actions';

const getSiteId = (state: IRootState) => state.app.siteId;
const getAdminShiftCheckList = (state: IRootState) => state.shiftHandoverState.adminShiftChecklist;
const getAdminShiftCheckListLoading = (state: IRootState) => state.shiftHandoverState.isLoading;
const getPerson = createSelector(
    (state: any) => state.person,
    (state) => state.personProfile
);
const getSelectedOperatingPlatform = (state: IRootState) => state.shiftHandoverState.selectedOperatingPlatform;

const mapStateToProps = createSelector(
    getSiteId,
    getAdminShiftCheckList,
    getAdminShiftCheckListLoading,
    getPerson,
    getSelectedOperatingPlatform,
    (siteId, shiftCheckList, isLoading, person, selectedOperatingPlatform) => ({
        siteId,
        shiftCheckList,
        isLoading,
        person,
        selectedOperatingPlatform,
    })
);
const mapDispatchToProps = { loadSpecificOperatingPlatformShiftChecklist, createSpecificSiteShiftOperatingPlatformChecklist, loadSelectedOperatingPlatform };

export const shiftHandoverAdminNewUpsertPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ShiftHandoverAdminUpsertPage);
