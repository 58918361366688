import React from 'react';
import { orderBy } from 'lodash';
import { Table, Panel, TableHeader, SortOrder } from '../v2/components';
import { ICriticalSystemSpare, Status } from 'models/critical-system-spares/critical-system-spare';
import { onRouteChange } from 'actions/app-actions';
import { TableTd } from '../v2/table/TableTd';
import useTranslate from 'translations/translation-utils';

interface IProps {
    criticalSpares: ICriticalSystemSpare[];
    isClient: boolean;
}

export const CriticalSystemSparesTable: React.FC<IProps> = ({ criticalSpares, isClient }) => {

    const translate = useTranslate();
    const [sortField, setSortField] = React.useState('categoryName');
    const [sortOrder, setSortOrder] = React.useState(SortOrder.off);

    const onToggleHandler = (field: string) => {
        if (sortField !== field) {
            setSortField(field);
            setSortOrder(SortOrder.asc);
            return;
        }
        const sort = sortOrder === SortOrder.asc ? SortOrder.desc : SortOrder.asc;
        setSortOrder(sort);
    };

    const getSortState = (field: string): SortOrder => {
        if (field === sortField) {
            return sortOrder;
        }
        return SortOrder.off;
    };

    const getSortedList = (): ICriticalSystemSpare[] => {
        if (sortOrder === SortOrder.off) {
            return criticalSpares;
        }
        return sortOrder === SortOrder.asc
            ? orderBy(criticalSpares, [sortField], ['asc'])
            : orderBy(criticalSpares, [sortField], ['desc']);
    };

    const getStatus = (item: ICriticalSystemSpare) => {
        if (item.status === Status.BelowMin) {
            return <mark className="high">{translate('CriticalSystemSparesTable.Status.S1')}</mark>;
        }
        if (item.status === Status.CloseToMin) {
            return <mark className="medium">{translate('CriticalSystemSparesTable.Status.S2')}</mark>;
        }
        return <mark className="low">&nbsp;</mark>;
    };

    const getCriticality = (item: ICriticalSystemSpare) => {
        if (item.isCritical === true) {
            return <span className="tick" />;
        }
    };

    const openDetails = (id: string) => {
        let pageUrl = '/CriticalSystemSpare';
        if (isClient) {
            pageUrl = '/ClientCriticalSystemSpare';
        }

        onRouteChange(`${pageUrl}/${id}/details`);
    };

    const footer = () => (
        <>
            <ul className="legend table-footer">
                <li className="high">{translate('CriticalSystemSparesTable.Footer.L1')}</li>
                <li className="medium">{translate('CriticalSystemSparesTable.Footer.L2')}</li>
            </ul>
            <div className="is-clearfix" />
        </>
    );

    return (
        <Panel title={translate('CriticalSystemSparesTable.Title')} footer={footer()}>
            <Table>
                <thead>
                    <tr>
                        <TableHeader
                            sortState={getSortState('categoryName')}
                            onToggle={() => onToggleHandler('categoryName')}
                            className="narrow no-wrap"
                        >
                            {translate('CriticalSystemSparesTable.Columns.Category')}
                        </TableHeader>
                        <TableHeader
                            sortState={getSortState('subCategoryName')}
                            onToggle={() => onToggleHandler('subCategoryName')}
                            className="narrow no-wrap"
                        >
                            {translate('CriticalSystemSparesTable.Columns.SubCategory')}
                        </TableHeader>
                        <TableHeader
                            sortState={getSortState('name')}
                            onToggle={() => onToggleHandler('name')}
                            className="narrow no-wrap"
                        >
                            {translate('CriticalSystemSparesTable.Columns.PartNameID')}
                        </TableHeader>
                        <TableHeader
                            sortState={getSortState('manufacturer')}
                            onToggle={() => onToggleHandler('manufacturer')}
                            className="narrow no-wrap"
                        >
                            {translate('CriticalSystemSparesTable.Columns.Manufacturer')}
                        </TableHeader>
                        <TableHeader
                            sortState={getSortState('modelNumber')}
                            onToggle={() => onToggleHandler('modelNumber')}
                            className="narrow no-wrap"
                        >
                            {translate('CriticalSystemSparesTable.Columns.Model')}
                        </TableHeader>
                        <TableHeader
                            sortState={getSortState('quantityCurrent')}
                            onToggle={() => onToggleHandler('quantityCurrent')}
                            className="narrow"
                        >
                            {translate('CriticalSystemSparesTable.Columns.CurrentQTY')}
                        </TableHeader>
                        <TableHeader
                            sortState={getSortState('location')}
                            onToggle={() => onToggleHandler('location')}
                            className="narrow no-wrap"
                        >
                            {translate('CriticalSystemSparesTable.Columns.Location')}
                        </TableHeader>
                        <TableHeader
                            sortState={getSortState('status')}
                            onToggle={() => onToggleHandler('status')}
                            className="narrow"
                        >
                            {translate('CriticalSystemSparesTable.Columns.Status')}
                        </TableHeader>
                        <TableHeader
                            sortState={getSortState('isCritical')}
                            onToggle={() => onToggleHandler('isCritical')}
                            className="narrow"
                        >
                            {translate('CriticalSystemSparesTable.Columns.Critical')}
                        </TableHeader>
                    </tr>
                </thead>
                <tbody className="clickable">
                    {getSortedList().map((item) => (
                        <tr key={item.id} onClick={() => openDetails(item.id)}>
                            <td>{item.categoryName}</td>
                            <td>{item.subCategoryName}</td>
                            <td>{item.name}</td>
                            <TableTd type="ellipsis">{item.manufacturer}</TableTd>
                            <TableTd type="ellipsis">{item.modelNumber}</TableTd>
                            <td>{item.quantityCurrent}</td>
                            <td>{item.location}</td>
                            <td>{getStatus(item)}</td>
                            <td>{getCriticality(item)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Panel>
    );
};
