import * as React from 'react';
import { Loader } from 'components/loader';
import { ISkill, SkillLevel, IEngineerFilters, IObsoleteSkillCategory } from 'models';
import '../engineers/engineer.scss';
import './engineer.scss';
import { Card, CardHeader, CardContent } from '../card';
import { IModalCardHeaderToolbarItem } from '../card/CardHeader';
import { getSkillLevelText, SkillLevelTextType } from 'business-area-functions/cdna';
import { MaterialIconType } from 'routes/material-icon/material-icon-type';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';

export interface IMasterEngineerSkillsProps {
    engineerSkills: ISkill[];
    skillCategories: IObsoleteSkillCategory[];
    isLoading: boolean;
    filters: IEngineerFilters;
    expandedCategories: string[];
    onToggleCategory: (id: string) => void;
    save(): void;
    intl: any;
}

export interface IMasterEngineerSkillsState {
    isEditDialogOpen: boolean;
    addToSkillCategoryId: string;
}

class MasterEngineerSkillsClass extends React.Component<IMasterEngineerSkillsProps, IMasterEngineerSkillsState> {
    constructor(props: IMasterEngineerSkillsProps) {
        super(props);
        this.state = { isEditDialogOpen: false, addToSkillCategoryId: '' };
    }

    public getIsCategoryExpanded = (identifier: string) => this.props.expandedCategories && this.props.expandedCategories.includes(identifier);

    public toolbarItems = (id: string): IModalCardHeaderToolbarItem[] => [
        {
            type: 'icon', action: this.props.onToggleCategory,
            icon: this.getIsCategoryExpanded(id) ? MaterialIconType.AngleUp : MaterialIconType.AngleDown,
            label: this.getIsCategoryExpanded(id) ? 'Expand' : 'Collapse'
        }
    ]

    public render() {
        const props = this.props;
        return (
            <Loader className="EngineerSkills" loading={props.isLoading}>
                {props.engineerSkills ?
                    <React.Fragment>
                        {this.props.skillCategories
                            // apply skill category side filter in case some skill categories shouldn't be displayed at all
                            .filter((categoryFilter) => props.filters.categories.length === 0 || props.filters.categories.some((x) => x.toString() === categoryFilter.id.toString()))
                            .map((skillCategory) =>
                                <div id={skillCategory.id} key={skillCategory.id}>
                                    <Card>
                                        <CardHeader
                                            widgetId={skillCategory.id}
                                            title={skillCategory.id + ': ' + skillCategory.name}
                                            toolbarItems={this.toolbarItems(skillCategory.id)}
                                        />
                                        <div className={`grid-view`}>
                                            {props.expandedCategories && props.expandedCategories.includes(skillCategory.id) &&
                                                <>
                                                    <div className={`centered-grid-headers grid-headers columns ${props.isLoading ? 'is-loading' : ''}`}>
                                                        <div className="column is-1">Level</div>
                                                        <div className="column is-1">Ref</div>
                                                        <div className="column is-10">{translate(this.props.intl, 'Engineer.Skills.Label.3')}</div>
                                                    </div>
                                                    <CardContent className="Skills">
                                                        {props.engineerSkills && props.engineerSkills.length > 0 && props.engineerSkills
                                                            // apply skill category side filter in case some skill categories shouldn't be displayed at all
                                                            .filter((a) => a.skillCategoryId === skillCategory.id)
                                                            // apply skill level side filter in case some skill levels shouldn't be displayed at all
                                                            .filter((b) => props.filters.levels.length === 0 ||
                                                                props.filters.levels.some((x) => x.toString() === b.skillLevel.toString()))
                                                            // apply filter text side filter so that only skills containing keywords are displayed
                                                            .filter((d) => props.filters.filterKeywords.length === 0 ||
                                                                props.filters.filterKeywords.every((word) => d.description.includes(word)))
                                                            // render the individual skill
                                                            .map((skill) => this.getEngineerSkillItem(skill))}
                                                    </CardContent>
                                                    {/* {this.state.isEditDialogOpen &&
                <EnterTextDialog text="Edit it" id={'aa'} onConfirm={this.props.save} onCancel={this.enterTextDialogClose}/>} */}
                                                </>
                                            }
                                        </div>
                                    </Card>
                                </div>
                            )}
                    </React.Fragment>
                    : <p> {translate(this.props.intl, 'CompetencyDnaProcess.Label.16')}</p>}
            </Loader>
        );
    }

    public add() {
        this.setState({ isEditDialogOpen: true });
    }

    private getEngineerSkillItem = (skill: ISkill) => (
        <div
            className={`grid-view__item is-hoverable`}
            id={'master-engineer-skill-' + skill.id.toString()}
            key={skill.id}
            onClick={this.add}
        >
            <div className="columns engineer-columns" style={{ cursor: 'pointer' }}>
                <div className="column is-1">
                    <span
                        className={
                            skill.skillLevel === SkillLevel.C ? 'engineer-c' :
                                skill.skillLevel === SkillLevel.B ? 'engineer-b' :
                                    skill.skillLevel === SkillLevel.A ? 'engineer-a' : ''}
                    >
                        {getSkillLevelText(skill.skillLevel, SkillLevelTextType.Letter)}
                    </span>
                </div>
                <div className="column is-1">
                    {skill.skillCategoryId.toString() + '.'.toString() + skill.skillNumber.toString()}
                </div>
                <div className="column is-10">
                    {skill.description}
                </div>
            </div>
        </div>
    )
}

export const MasterEngineerSkills = injectIntl(MasterEngineerSkillsClass);