import { FormikProps } from 'formik';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { isNil } from 'lodash';
import { Select } from 'components/select';
import { Control, Field, Help, Input, Radio } from '../../../../components/form';
import { ValueSlider } from '../../../../components/value-slider';
import { BulmaColor } from '../../../../enums/BulmaColor';
import { BulmaSize } from '../../../../enums/BulmaSize';
import { ICermOutcome, ICermProcessStep } from '../../../../models/cerm-process-step';
import { IKeyValue } from '../../../../models/key-value';
import { IActionTemplate } from '../../../../models/master-process-step/master-process-step';
import { formikFieldCssByState, getKeyed } from '../../../../utils/form-utils';
import { dueDateUnitOptions } from '../common';
import { IUpsertCermProcessStepPageRouteParams } from '../upsert-cerm-process-step-page';
import useTranslate from 'translations/translation-utils';
import { IOperatingPlatform } from 'models/operating-platform-model';
import { getTitlePrefixGeneric } from 'models/cerm/cerm-assessment-result';

export interface IProps extends RouteComponentProps<IUpsertCermProcessStepPageRouteParams> {
    lookupActionPriorities: Array<IKeyValue<string>>;
    lookupActionCategories: Array<IKeyValue<string>>;
    formikBag: FormikProps<Partial<ICermProcessStep>>;
    index?: number;
    operatingPlatforms: IOperatingPlatform[];
}

export const ActionForm: React.FC<IProps> = ({
    lookupActionPriorities,
    lookupActionCategories,
    formikBag,
    match,
    index,
    operatingPlatforms,
}) => {
    const translate = useTranslate();
    const indexParam = Number(match.params && match.params.outcomeIndex);
    const outcomeIndex = indexParam >= 0 ? indexParam : index >= 0 ? index : 0;

    const entityName: Extract<keyof ICermOutcome, string> = 'action';
    const prefix = `expectedOutcomes[${outcomeIndex}].${entityName}`;

    const titlePrefix =
        operatingPlatforms &&
        operatingPlatforms.length > 0 &&
        getTitlePrefixGeneric(match.params.operatingPlatform, operatingPlatforms);

    if (!formikBag.values.expectedOutcomes[outcomeIndex].action) {
        const defaultValues: IActionTemplate = {
            // tslint:disable-next-line:max-line-length
            title: `${translate('ActionForm.Template.Title')}`,
            description: `${titlePrefix} ${formikBag.values.processNumber} ${translate(
                'ActionForm.Template.Description'
            )}`,
            categoryId: '9',
            priority: '1',
            dueDateUnit: 'day',
            dueDateValue: '60',
        };

        switch (formikBag.values.priority) {
            case '1':
                defaultValues.priority = '1';
                defaultValues.dueDateValue = '60';
                break;
            case '3':
                defaultValues.priority = '2';
                defaultValues.dueDateValue = '30';
                break;
            case '9':
                defaultValues.priority = '3';
                defaultValues.dueDateValue = '14';
                break;
            case '27':
                defaultValues.priority = '3';
                defaultValues.dueDateValue = '7';
                break;
        }
        formikBag.setFieldValue(`${prefix}`, defaultValues);
        return <></>;
    }

    const field = (name: Extract<keyof IActionTemplate, string>) => `${prefix}.${name}`;

    function value<T extends Extract<keyof IActionTemplate, string>>(name: T) {
        const val = getKeyed(formikBag, 'values', field(name));
        return !isNil(val) ? val : '';
    }

    const getErrors = (name: Extract<keyof IActionTemplate, string>) =>
        getKeyed(formikBag, 'errors', field(name));
    const isTouched = (name: Extract<keyof IActionTemplate, string>) =>
        getKeyed(formikBag, 'touched', field(name));

    const getFieldCss = (name: Extract<keyof IActionTemplate, string>, otherCss?: string) =>
        formikFieldCssByState(Boolean(getErrors(name)), isTouched(name), otherCss);

    const getHelpByKey = (name: Extract<keyof IActionTemplate, string>) => (
        <Help isShown={formikBag.submitCount > 0 || isTouched(name)} bulmaColor={BulmaColor.Danger}>
            {getErrors(name)}
        </Help>
    );

    const onPriorityChanged = (newValue: IKeyValue<string>) => {
        if (newValue !== undefined) {
            formikBag.setFieldValue(field('priority'), newValue.key);
        }
    };

    const getPriorityValue = () => {
        const priorityValue = value('priority');
        return priorityValue !== undefined || priorityValue !== '' ? priorityValue : '1';
    };

    const handleDueDateValueEvent = (event: any) => {
        formikBag.setFieldValue(field('dueDateValue'), event.target.value.toString());
    };

    return (
        <>
            <header>
                <h2 className="title is-5">{translate('ActionForm.Template.Title')}</h2>
            </header>

            <Field
                isHorizontal={true}
                htmlFor={field('description')}
                label={translate('ActionDetails.Lable.2')}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <textarea
                            id={field('description')}
                            name={field('description')}
                            className={getFieldCss('description', 'textarea')}
                            placeholder={translate('ActionDetails.Lable.3')}
                            value={value('description')}
                            onBlur={formikBag.handleBlur}
                            onChange={formikBag.handleChange}
                        />
                    </Control>
                    {getHelpByKey('description')}
                </Field>
            </Field>

            <Field
                isHorizontal={true}
                htmlFor={field('categoryId')}
                label={translate('ActionRegisterPage.ActionRegisterTable.Title.Category')}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <Select
                            id={field('categoryId')}
                            name={field('categoryId')}
                            aria-required="true"
                            options={lookupActionCategories}
                            className={getFieldCss('categoryId')}
                            value={value('categoryId')}
                            onChange={formikBag.handleChange}
                            onBlur={formikBag.handleBlur}
                            emptyOptionsValue={translate(
                                'RiskActionTab.Placeholder.emptyOptionsValue'
                            )}
                        />
                    </Control>
                </Field>
            </Field>

            <Field
                isHorizontal={true}
                htmlFor={field('priority')}
                label={translate('ActionRegisterPage.ActionRegisterTable.Title.Priority')}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        {lookupActionPriorities.length > 0 && (
                            <ValueSlider
                                name={field('priority')}
                                values={lookupActionPriorities}
                                selectedValue={getPriorityValue()}
                                onChange={onPriorityChanged}
                            />
                        )}
                    </Control>
                    {getHelpByKey('priority')}
                </Field>
            </Field>

            <Field
                isHorizontal={true}
                htmlFor={field('dueDateValue')}
                label={translate('ActionDetails.Lable.5')}
                labelSize={BulmaSize.Medium}
                className="is-vcentered actionDueDate"
            >
                <p className="is-italic">Action due in</p>
                <Field>
                    <Control>
                        <Input
                            id={field('dueDateValue')}
                            name={field('dueDateValue')}
                            placeholder="0"
                            type="number"
                            min={0}
                            className={getFieldCss('dueDateValue')}
                            value={value('dueDateValue')}
                            onChange={handleDueDateValueEvent}
                            onBlur={formikBag.handleBlur}
                        />
                    </Control>
                </Field>
                <Field>
                    <Control>
                        <Radio
                            id={field('dueDateUnit')}
                            name={field('dueDateUnit')}
                            options={dueDateUnitOptions}
                            className={getFieldCss('dueDateUnit')}
                            value={value('dueDateUnit')}
                            onBlur={formikBag.handleBlur}
                            onChange={formikBag.handleChange}
                        />
                    </Control>
                </Field>
            </Field>
        </>
    );
};
