import { combineReducers } from 'redux';
import {
    RiskAssessmentActions,
    RiskAssessmentActionTypes,
} from 'actions/risk-assessment-actions';
import { RiskAssessmentActions as RiskAssessmentActionsV2 } from 'actions/actions-v2/risk-assessment-action-v2';
import { SortDirection } from 'enums/SortDirection';
import { IKeyedItem } from 'models/keyed-item';
import { IBaseState } from '../base-state';
import { IAppAction } from 'actions/app-action';
import { ISetRiskAssessmentAnswers } from 'models/risk-assessments/set-risk-assessment-answers';

export interface IRootRiskAssessmentState extends IBaseState {
    riskAssessmentState: IRiskAssessmentState;
}

export interface IFiltersState {
    filterKeywords: string[];
    isFilterModalOpen: boolean;
}

export interface IAssessmentQuestion {
    id: string;
    title: string;
    answer?: boolean;
}

export interface IAssessmentQuestionSet extends IKeyedItem {
    completed: boolean;
    status: string;
    urgency: string;
    category: string;
    title: string;
    relevancyQuestion: string;
    masterQuestionSetId: string;
    answer?: boolean;
    questions: IAssessmentQuestion[];
}

export interface ISortProps {
    sortColumn: string;
    sortDirection: SortDirection;
}

export interface IRiskAssessmentState {
    isLoading: boolean;
    isInitialising: boolean;
    reviewQuestionSet: Partial<IAssessmentQuestionSet>;
    expandedQuestions: string[];
    expandedFilters: string[];
    questionSets: IAssessmentQuestionSet[];
}

export const FILTERS_INITIAL_STATE: IFiltersState = {
    filterKeywords: [],
    isFilterModalOpen: false,
};

export const SORT_PROPS_INITIAL_STATE: ISortProps = {
    sortColumn: '',
    sortDirection: SortDirection.Descending,
};

const INITIAL_STATE: IRiskAssessmentState = {
    isLoading: false,
    isInitialising: true,
    reviewQuestionSet: null,
    questionSets: [],
    expandedQuestions: [],
    expandedFilters: ['filterCategory'],
};

const isLoading = (
    state: IRiskAssessmentState['isLoading'] = INITIAL_STATE.isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case RiskAssessmentActionsV2.LOAD_RISK_ASSESSMENTS:
        case RiskAssessmentActionsV2.SET_RISK_ASSESSMENT_ANSWERS:
        case RiskAssessmentActions.LOAD_RISK_QUESTIONS:
            return true;

        case RiskAssessmentActions.LOAD_RISK_QUESTIONS_FULFILLED:
        case RiskAssessmentActions.LOAD_RISK_QUESTIONS_REJECTED:
        case RiskAssessmentActionsV2.LOAD_RISK_ASSESSMENTS_FULFILLED:
        case RiskAssessmentActionsV2.LOAD_RISK_ASSESSMENTS_REJECTED:
        case RiskAssessmentActionsV2.SET_RISK_ASSESSMENT_ANSWERS_FULFILLED:
        case RiskAssessmentActionsV2.SET_RISK_ASSESSMENT_ANSWERS_REJECTED:
            return false;

        default:
            return state;
    }
};

const isInitialising = (
    state: IRiskAssessmentState['isInitialising'] = INITIAL_STATE.isInitialising,
    action: IAppAction
) => {
    switch (action.type) {
        case RiskAssessmentActionsV2.LOAD_RISK_ASSESSMENTS:
            return true;
        case RiskAssessmentActions.LOAD_PAGE_FULFILLED:
        case RiskAssessmentActionsV2.LOAD_RISK_ASSESSMENTS_FULFILLED:
            return false;
        default:
            return state;
    }
};

const reviewQuestionSet = (
    state: IRiskAssessmentState['reviewQuestionSet'] = INITIAL_STATE.reviewQuestionSet,
    action: RiskAssessmentActionTypes
) => {
    switch (action.type) {
        case RiskAssessmentActions.LOAD_PAGE_FULFILLED:
            return action.payload.riskAssessmentState.reviewQuestionSet !== undefined
                ? action.payload.riskAssessmentState.reviewQuestionSet
                : state;
        default:
            return state;
    }
};

const questionSets = (
    state: IRiskAssessmentState['questionSets'] = INITIAL_STATE.questionSets,
    action: IAppAction
) => {
    switch (action.type) {
        case RiskAssessmentActions.LOAD_PAGE_FULFILLED:
            return action.payload.riskAssessmentState.questionSets !== undefined
                ? action.payload.riskAssessmentState.questionSets
                : state;

        case RiskAssessmentActionsV2.LOAD_RISK_ASSESSMENTS_FULFILLED:
            if (action.payload) {
                return action.payload;
            }
            return state;

        case RiskAssessmentActions.LOAD_RISK_QUESTIONS_FULFILLED:
            return action.payload.questionSets;

        case RiskAssessmentActions.SET_QUESTION_SET:
            return state.map((item) => {
                if (item.masterQuestionSetId === action.payload.questionSetId) {
                    return {
                        ...item,
                        answer: action.payload.answer,
                    };
                }
                return item;
            });
        case RiskAssessmentActions.SET_QUESTION:
            return state.map((item) => {
                if (item.masterQuestionSetId === action.payload.questionSetId) {
                    return {
                        ...item,
                        questions: item.questions.map((question) => {
                            if (question.id === action.payload.questionId) {
                                return {
                                    ...question,
                                    answer: action.payload.answer,
                                };
                            }
                            return question;
                        }),
                    };
                }
                return item;
            });

        case RiskAssessmentActionsV2.SET_RISK_ASSESSMENT_ANSWERS_FULFILLED:
            const payload = action.payload as ISetRiskAssessmentAnswers;
            if (payload) {
                return state.map((item) => {
                    const found = payload.questionSets.find(
                        (qs) => qs.questionSetId === item.masterQuestionSetId
                    );

                    if (!found) {
                        return item;
                    }

                    return {
                        ...item,
                        answer: found.answer,
                        questions: item.questions.map((question) => {
                            const foundQuestion = found.questions.find(
                                (q) => q.questionId === question.id
                            );

                            if (!foundQuestion) {
                                return question;
                            }

                            return {
                                ...question,
                                answer: foundQuestion.answer,
                            };
                        }),
                    };
                });
            }

            return state;

        default:
            return state;
    }
};

const expandedQuestions = (
    state: IRiskAssessmentState['expandedQuestions'] = INITIAL_STATE.expandedQuestions,
    action: RiskAssessmentActionTypes
) => {
    switch (action.type) {
        case RiskAssessmentActions.LOAD_PAGE_FULFILLED:
            return action.payload.riskAssessmentState.expandedQuestions !== undefined
                ? action.payload.riskAssessmentState.expandedQuestions
                : state;
        default:
            return state;
    }
};

const expandedFilters = (
    state: IRiskAssessmentState['expandedFilters'] = INITIAL_STATE.expandedFilters,
    action: RiskAssessmentActionTypes
) => {
    switch (action.type) {
        case RiskAssessmentActions.LOAD_PAGE_FULFILLED:
            return action.payload.riskAssessmentState.expandedFilters !== undefined
                ? action.payload.riskAssessmentState.expandedFilters
                : state;
        case RiskAssessmentActions.TOGGLE_FILTER_EXPANDED:
            const id = action.payload.id;

            if (state.includes(id)) {
                return state.filter((item) => item !== id);
            } else {
                if (id) {
                    return state.concat(id);
                }
            }

            return state;
        default:
            return state;
    }
};

export const riskAssessmentReducer = combineReducers<IRiskAssessmentState>({
    isLoading,
    isInitialising,
    reviewQuestionSet,
    questionSets,
    expandedQuestions,
    expandedFilters,
});
