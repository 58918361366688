import { connect } from 'react-redux';

import actions from './actions';
import selector from './selectors';
import { ManageRolesTab as View } from './view/manage-roles-tab';

export const ManageRolesTab = connect(
    selector,
    actions
)(View);
