import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import {
    loadGlobalMockDrill,
    createGlobalMockDrill,
    updateGlobalMockDrill,
    cleanupGlobalMockDrill,
} from 'actions/actions-v2/global-mock-drills-actions';
import { UpsertGlobalMockDrillPage } from './upsert-global-mock-drill-page';
import {
    loadAttachmentsGlobalMockDrill,
    uploadAttachmentGlobalMockDrill,
} from 'actions/actions-v2/attachment-action-v2';

const getGlobalMockDrill = (state: IRootState) => state.globalMockDrillsState.globalMockDrill;
const getIsLoading = (state: IRootState) => state.globalMockDrillsState.isLoading;
const getIsSaving = (state: IRootState) => state.globalMockDrillsState.isSaving;
const getAvailableSites = (state: IRootState) => state.app.lookupSites;
const getClients = (state: IRootState) => state.app.lookupClients;
const getIsLocked = (state: IRootState) => {
    return state.globalMockDrillsState.globalMockDrill
        ? state.globalMockDrillsState.globalMockDrill.isLocked
        : false;
};
const getAttachments = (state: IRootState) => state.globalMockDrillsState.attachments;

const mapStateToProps = createSelector(
    getGlobalMockDrill,
    getIsLoading,
    getAvailableSites,
    getClients,
    getIsSaving,
    getIsLocked,
    getAttachments,
    (globalMockDrill, isLoading, availableSites, clients, isSaving, isLocked, attachments) => ({
        globalMockDrill,
        isLoading,
        availableSites,
        clients,
        isSaving,
        isLocked,
        attachments,
    })
);

const mapDispatchToProps = {
    loadGlobalMockDrill,
    createGlobalMockDrill,
    updateGlobalMockDrill,
    cleanupGlobalMockDrill,
    loadAttachments: loadAttachmentsGlobalMockDrill,
    uploadAttachment: uploadAttachmentGlobalMockDrill,
};

export const UpsertGlobalMockDrillContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(UpsertGlobalMockDrillPage)
);
