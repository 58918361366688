import { IGridViewState, INITIAL_STATE as INITIAL_GRID_STATE } from 'reducers/grid';
import { IIncident } from 'models';

export interface IIncidentsGridState extends IGridViewState<IIncident> {
}

const defaultState: IIncidentsGridState = {
    ...INITIAL_GRID_STATE,
};

// tslint:disable-next-line:arrow-return-shorthand
export const getDefaultIncidentsGridState = (options?: any) => { return {...defaultState, ...options }; };
