import React from 'react';
import { Loader } from 'components/loader';
import { ConfirmDialog } from 'components/v2/components';
import { IPerson } from 'models/person';
import './operating-platform-selector.scss';
import useTranslate from 'translations/translation-utils';
import Select from 'react-select';
import { RouteComponentProps } from 'react-router';
import { RouteUrlOperatingPlatform, RouteQuestionSetsUrlOperatingPlatform, RouteUrlRiskProfileOperatingPlatform } from 'routes/upsert-site/routes';
import { onRouteChange } from 'actions/app-actions';
import { getUserId } from 'helpers/helpers';
interface IParams {
    operatingPlatform?: string;
}

interface IProps extends RouteComponentProps<IParams> {
    personProfile: IPerson;
    isShowOperatingPlatformSelectorDialog: boolean;
    isLoading: boolean;
    updatePreferredOperatingPlatform: (person: IPerson) => void;
    showOperatingPlatformSelectorDialog: (show: boolean) => void;
    loadPerson?: (personId: string) => void;
}

const OperatingPlatformSelectorDialog: React.FC<IProps> = ({
    match,
    personProfile,
    isShowOperatingPlatformSelectorDialog,
    isLoading,
    updatePreferredOperatingPlatform,
    showOperatingPlatformSelectorDialog,
    loadPerson
}) => {
    const translate = useTranslate();
    const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
    const [selectedOperatingPlatform, setSelectedOperatingPlatform] = React.useState('Select');
    const [confirmButtonDisabled, setConfirmButtonDisabled] = React.useState(true);

    React.useEffect(() => {

        if (personProfile && personProfile.preferredOperatingPlatform) {
            setSelectedOperatingPlatform(personProfile.preferredOperatingPlatform);
        }

    }, [personProfile]);


    React.useEffect(() => {
        setShowConfirmDialog(isShowOperatingPlatformSelectorDialog);
        if (isShowOperatingPlatformSelectorDialog) {
            if (!getUserId()) {
                return;
            }
            loadPerson(getUserId())
        }
    }, [isShowOperatingPlatformSelectorDialog]);

    const onChange = (e: any) => {
        const selected = e.value;
        setSelectedOperatingPlatform(selected)
        setConfirmButtonDisabled(personProfile.preferredOperatingPlatform === selected);
    }

    const onConfirm = () => {
        const person = { ...personProfile };
        person.preferredOperatingPlatform = selectedOperatingPlatform;
        updatePreferredOperatingPlatform(person);
        if (window.location.href.indexOf("CermProcessSteps") > -1) {
            let paramOperatingPlatform = match.params.operatingPlatform
            if (paramOperatingPlatform !== selectedOperatingPlatform) {
                onRouteChange(`${RouteUrlOperatingPlatform(selectedOperatingPlatform)}`);
            }
        }
        if (window.location.href.indexOf("QuestionSets") > -1) {
            let paramOperatingPlatform = match.params.operatingPlatform
            if (paramOperatingPlatform !== selectedOperatingPlatform) {
                onRouteChange(`${RouteQuestionSetsUrlOperatingPlatform(selectedOperatingPlatform)}`);
            }
        }
        if (window.location.href.indexOf("RiskProfileMasterQuestionnaire") > -1) {
            let paramOperatingPlatform = match.params.operatingPlatform
            if (paramOperatingPlatform !== selectedOperatingPlatform) {
                onRouteChange(`${RouteUrlRiskProfileOperatingPlatform(selectedOperatingPlatform)}`);
            }
        }
        showOperatingPlatformSelectorDialog(false);
    };

    const onCancel = () => {
        if (personProfile && personProfile.preferredOperatingPlatform) {
            setSelectedOperatingPlatform(personProfile.preferredOperatingPlatform);
        }
        else {
            setSelectedOperatingPlatform('Select');
        }
        showOperatingPlatformSelectorDialog(false);
        setConfirmButtonDisabled(true);
    }

    const getBody = () => {
        return (
            <Loader loading={isLoading}>
                <p>{translate('OperatingPlatformSelectorDialog.Label.OperatingPlatform')}</p>

                <p className="op-selector">
                    <Select
                        isMulti={false}
                        classNamePrefix="dropdown-multi"
                        placeholder={translate('OperatingPlatformSelectorDialog.Dropdown.SelectLabel')}
                        value={{
                            key: selectedOperatingPlatform,
                            value: selectedOperatingPlatform,
                            label: selectedOperatingPlatform
                        }}
                        onChange={(e) => onChange(e)}
                        options={personProfile.accessRights.operatingPlatforms
                            .map(x => ({
                                key: x,
                                value: x,
                                label: x,
                            }))
                            .sort((a, b) => a.label.localeCompare(b.label))}
                    />
                </p>
                <p>&nbsp;</p>
                <p>
                    <p>
                        {translate('OperatingPlatformSelectorDialog.Label.DefaultAdminPages')}
                    </p>
                </p>
            </Loader>
        );
    };

    return (
        <ConfirmDialog
            title={translate('NavDropDown.SelecteOpLabel')}
            message={getBody()}
            isVisible={showConfirmDialog}
            isLoading={isLoading}
            showConfirmButton={true}
            onConfirm={onConfirm}
            onClose={onCancel}
            buttonConfirmText={translate('Globals.Label.Confirm')}
            buttonCancelText={translate('Globals.Label.Cancel')}
            confirmButtonDisabled={confirmButtonDisabled}
        />
    );
};

export default OperatingPlatformSelectorDialog;
