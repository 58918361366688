import * as React from 'react';
import Page from 'components/v2/page/Page';
import { RouteComponentProps, useLocation } from 'react-router';
import { TabGroup, TabItem } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';
import { IAuditorInspectionsDocumentsContainer } from './tabs/documents/iauditor-inspections-documents-container'
import { IauditorGridToolbar } from './iauditor-toolbar-container';
import { ICermLinks } from 'models/cerm-overview';
import { IcheckList } from 'models/iauditor';
import appConfig from '../../helpers/config-helper';
import { history } from 'routes/App';
import { OpportunititySiteInfoContainer } from 'routes/opportunity-siteinfo/opportunity-site-info-container';
import { IKeyValue } from 'models';

interface IParams {
    tab: string;
    cermProcessId: string;
}

interface IProps extends RouteComponentProps<IParams> {
    siteId: string;
    isLoading: boolean;
    cermLinks: ICermLinks;
    siteCode: string;
    isDocumentLoading: boolean;
    isLoadingTemplate: boolean;
    complianceCheckList: IcheckList;
    authToken: string;
    getECermToken: () => void;
    getCermProcessLinks: (cermProcessId: string) => void;
    loadComplianceChecklist: (siteCode: string, cermProcess: number) => void;
    showOpportunityQuestionnaire: boolean;
    hasIAuditorView: boolean;
    PersonsLookup: (siteId: string) => void;
    lookupSites: IKeyValue<string>[];
    selectedMenuName: string;
}

enum Tab {
    documents = 'documents',
    training = 'training',
    relatedDocument = 'relateddocument',
    processReport = 'processreport',
    opportunityQuestionnaire = 'opportunityquestionnaire',
}

export const IauditorInspectionsUpsertPage: React.FC<IProps> = ({
    match,
    isLoading,
    siteId,
    cermLinks,
    siteCode,
    isDocumentLoading,
    complianceCheckList,
    authToken,
    getECermToken,
    getCermProcessLinks,
    loadComplianceChecklist,
    showOpportunityQuestionnaire,
    hasIAuditorView,
    PersonsLookup,
    selectedMenuName,
}) => {
    const config = appConfig();
    const ecermUrl = config.REACT_APP_ECERM_URL.toLowerCase();
    const translate = useTranslate();
    const location = useLocation();
    const cermprocessId = match.params.cermProcessId;
    const currentTab = match.params.tab.toLowerCase();
    const enableOpportunityQuestionnaire = cermprocessId === '100' && showOpportunityQuestionnaire;

    React.useEffect(() => {
        PersonsLookup(siteId);
    }, [])

    React.useEffect(() => {
        if (config.REACT_APP_FEATURE_FLAG_DYNAMIC_MENU === 'true' &&
            !selectedMenuName) {
            history.push(`${location.pathname}/PageNotFound`)
            return;
        }

        if (cermprocessId) {
            getCermProcessLinks(cermprocessId)
        }
    }, [cermprocessId]);

    React.useEffect(() => {
        if (siteCode) {
            loadComplianceChecklist(siteCode, Number(cermprocessId))
        }
    }, [siteCode, cermprocessId]);

    React.useEffect(() => {
        if (authToken == null || authToken === '') {
            getECermToken();
        }
    }, [authToken]);

    const handleClick = (url: string) => {
        if (url.toLocaleLowerCase().startsWith(ecermUrl)) {
            let separator = '?';
            if (url.indexOf('?') !== -1) {
                separator = '&';
            }
            window.open(`${url}${separator}auth-token=${authToken}`, '_blank');
        } else {
            window.open(url, '_blank');
        }
    };

    const setNavLink = (selectedTab: Tab) => {
        return match.path
            .replace(':cermProcessId', match.params.cermProcessId)
            .replace(':tab', selectedTab);
    };

    const handleTabClick = (event: any, tab: Tab) => {
        event.preventDefault();
        history.push(setNavLink(tab));
    };

    const renderTab = () => {
        switch (currentTab) {
            case Tab.documents:
                return <IAuditorInspectionsDocumentsContainer />
            case Tab.opportunityQuestionnaire:
                return <OpportunititySiteInfoContainer isHideTitle={true} />
        }
    };

    return (
        <Page
            title={selectedMenuName}
            className="iauditor-checklist-upsert-page"
            redirectOnSiteChange={false}
        >
            {hasIAuditorView && complianceCheckList.isTokenExist && (
                <IauditorGridToolbar
                    isLoading={isLoading}
                    isClient={false}
                    isShowHelp={false}
                    disableExport={true}
                    isDocumentLoading={isDocumentLoading}
                />
            )}

            <TabGroup isLoading={isLoading}>
                {hasIAuditorView && (
                    <TabItem
                        title={translate('IAuditorComplianceChecklistPage.Tab.Documents')}
                        tab={Tab.documents}
                        currentTab={currentTab}
                        onClick={handleTabClick}
                    />
                )}

                {enableOpportunityQuestionnaire && (
                    <TabItem
                        title={translate('SideNavBar.Labels.OpportunityQuestionnaire')}
                        tab={Tab.opportunityQuestionnaire}
                        currentTab={currentTab}
                        onClick={handleTabClick}
                    />
                )}

                {hasIAuditorView && cermLinks.trainingLink && (
                    <TabItem
                        title={translate('IAuditorComplianceChecklistPage.Tab.Training')}
                        tab={Tab.training}
                        currentTab={currentTab}
                        onClick={() => handleClick(cermLinks.trainingLink)}
                    />
                )}

                {hasIAuditorView && cermLinks.documentLink && (
                    <TabItem
                        title={translate('IAuditorComplianceChecklistPage.Tab.RelatedDocuments')}
                        tab={Tab.relatedDocument}
                        currentTab={currentTab}
                        onClick={() => handleClick(cermLinks.documentLink)}
                    />
                )}
            </TabGroup>
            {renderTab()}
        </Page>
    );
};
