import React from 'react';
import { IProgressBarModel } from 'components/step-progress-bar/StepProgressBar';
import { Temperature } from '../Context';
import { IStateResult, State } from '../State';
import { Result } from './question-result';

export class QuestionSypplyAirTempA extends State {
    public result: IStateResult;
    private title: string = 'Supply Air Temperature';
    private type: Temperature;
    constructor() {
        super();
        this.handleClick = this.handleClick.bind(this);
    }
    public getResult(): IStateResult {
        return this.result;
    }

    public display(): JSX.Element {
        return (
            <div >
                <div className="is-size-3  has-text-centered">
                    {this.title}
                </div>
                <p className="has-text-centered">
                    Which supply air temperature range does your outside air
                    economised system maintain for your IT equipment?
                </p>
                <hr />
                <div className="formBody">
                    <div className="field">
                        <div className="control">
                            <input
                                className="radioButton"
                                type="radio"
                                name="answer"
                                value={Temperature.C25}
                                id={Temperature.C25}
                                onClick={this.handleClick}
                                checked={this.type === Temperature.C25}
                            />
                            <label htmlFor={Temperature.C25} className="radio is-size-5">
                                {Temperature.C25.toString()}
                            </label>
                        </div>
                    </div>
                    <div className="field">
                        <div className="control">
                        <input
                                    className="radioButton"
                                    type="radio"
                                    name="answer"
                                    value={Temperature.C35}
                                    id={Temperature.C35}
                                    onClick={this.handleClick}
                                    checked={this.type === Temperature.C35}
                                />
                            <label htmlFor={Temperature.C35} className="radio is-size-5">
                                {Temperature.C35.toString()}
                            </label>
                        </div>
                    </div>
                </div>
                <hr />
                {this.context.footer(this.handle.bind(this))}
            </div>);
    }
    public handle() {

        this.result = {
            title: this.title,
            value: this.type.toString()
        };

        this.type === Temperature.C25 ?
            this.context.transitionTo(new Result(30)) :
            this.context.transitionTo(new Result(32));
    }
    public getProgressBarModel(): IProgressBarModel[] {
        return [
            { isActive: true, name: 'Type of site' },
            { isActive: true, name: 'Question 1' },
            { isActive: true, name: 'Question 2' },
            { isActive: false, name: 'Question 3' },
            { isActive: false, name: 'Question 4' },
            { isActive: false, name: 'Summary' },
        ] as IProgressBarModel[];
    }
    private handleClick(event: any) {
        this.context.isDisable = false;
        this.type = event.currentTarget.value as Temperature;
        this.context.handleRender();
    }
}
