import { combineReducers } from 'redux';

import { IBaseState } from '../base-state';
import { IBuildingSelectorState,
    reducer as buildingSelector
} from './building-selector';
import {
    IChangeClientConfirmationState,
    reducer as changeClientConfirmation
} from './change-client-confirmation';
import {
    IClientSelectorState,
    reducer as clientSelector
} from './client-selector';
import {
    IDeleteBuildingConfirmationState,
    reducer as deleteBuildingConfirmation
} from './delete-building-confirmation';
import {
    IDeletePersonConfirmationState,
    reducer as deletePersonConfirmation
} from './delete-person-confirmation';
import {
    IManageBuildingsState,
    reducer as manageBuildings
} from './manage-buildings';
import {
    IManagePeopleState,
    reducer as managePeople
} from './manage-people';
import {
    IPersonSelectorState,
    reducer as personSelector
} from './person-selector';
import {
    ISiteSelectorState,
    reducer as siteSelector
} from './site-selector';

export interface ISiteRelationshipsRootState extends IBaseState {
    siteRelationships: ISiteRelationshipsState;
}

export interface ISiteRelationshipsState {
    buildingSelector: IBuildingSelectorState;
    clientSelector: IClientSelectorState;
    deleteBuildingConfirmation: IDeleteBuildingConfirmationState;
    deletePersonConfirmation: IDeletePersonConfirmationState;
    changeClientConfirmation: IChangeClientConfirmationState;
    manageBuildings: IManageBuildingsState;
    managePeople: IManagePeopleState;
    personSelector: IPersonSelectorState;
    siteSelector: ISiteSelectorState;
}

export const siteRelationshipsReducer = combineReducers<ISiteRelationshipsState>({
    buildingSelector,
    clientSelector,
    deleteBuildingConfirmation,
    deletePersonConfirmation,
    changeClientConfirmation,
    manageBuildings,
    managePeople,
    personSelector,
    siteSelector,
});
