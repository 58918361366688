import React,{ ReactNode } from 'react';
import { checkPermission, QuantumClaim } from 'utils/permission-utils';
import { IKeyValue } from '../../models/key-value';

export interface IProps {
    permissionToCheck: QuantumClaim;
    permissions: Array<IKeyValue<string>>;
    children?: ReactNode
}

export class PermissionWrapper extends React.PureComponent<IProps> {

    public render() {
        if (checkPermission(this.props.permissionToCheck, this.props.permissions)) {
            return this.props.children;
        } else {
            return <></>;
        }
    }
}
