import { createSelector } from 'reselect';
import { ISiteProfile } from '../../../models/site-profile';
import { getUpsertSiteState, getUpsertClientState } from './common';
import { IRootState } from '../../store';

const getPermissions = (state: IRootState) => state.app.permissions;

const getIsLoading = (state: IRootState) => state.siteState.isSubmitting;

const getPageTitle = (state: IRootState) =>
    state.siteState.site.id ? 'Edit Site & Facilities' : 'Create Site & Facilities';

const getInitialValues = createSelector(getUpsertSiteState, (state) => ({
    ...state.site,
    buildings: state.site.buildings
        ? state.site.buildings.length > 0
            ? state.site.buildings
            : []
        : [],
}));

const getSelectedMonth = (state: IRootState) => state.siteState.selectedMonth;

export const getPueProjections = (state: IRootState) => state.siteState.pueProjection;

const getPersonLookups = (state: IRootState) => state.lookups.personLookups;

const getSiteId = (state: IRootState) => state.app.siteId;

const getShowHelpModule = (state: IRootState) => state.app.showHelpModule;

const getFacilities = (state: IRootState) => state.facilityState.facilities;

export const getOperatingPlatforms = (state: IRootState) => state.operatingPlatformState.operatingPlatforms;

export default createSelector(
    getPermissions,
    getPageTitle,
    getInitialValues,
    getIsLoading,
    getSelectedMonth,
    getPueProjections,
    getPersonLookups,
    getSiteId,
    getShowHelpModule,
    getFacilities,
    getUpsertClientState,
    getOperatingPlatforms,
    (
        permissions,
        pageTitle,
        initialValues: Partial<ISiteProfile>,
        isLoading,
        selectedMonth,
        pueProjections,
        personLookups,
        siteId,
        showHelpModule,
        facilities,
        client,
        operatingPlatforms
    ) => ({
        permissions,
        pageTitle,
        initialValues,
        isLoading,
        selectedMonth,
        pueProjections,
        personLookups,
        siteId,
        showHelpModule,
        facilities,
        client,
        operatingPlatforms
    })
);
