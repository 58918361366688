import * as React from 'react';
import { compact } from 'lodash';
import { ModalCard, ModalCardBody, ModalCardFooter, ModalCardHeader } from 'components/modalCard';
import { ISiteProfile } from 'models/site-profile';
import useTranslate from 'translations/translation-utils';
import './change-link-confirmation.scss';
import { Button } from 'components/v2/components';

export interface IChangeLinkStateProps<T> {
    visible: boolean;
    site: ISiteProfile;
    toItem: T;
    fromItemText: string | null;
    toItemText: string;
    fieldName: string;
}

export interface IChangeLinkActionProps<T> {
    onConfirm: (site: ISiteProfile, item: T) => void;
    onClose: (site: ISiteProfile, item: T) => void;
}

interface IProps<T> extends IChangeLinkStateProps<T>, IChangeLinkActionProps<T> { }

export const ChangeLinkConfirmation: React.FC<IProps<any>> = (props) => {
    const translate = useTranslate();

    if (!props.visible) {
        return null;
    }

    const getSiteDisplayText = (site: ISiteProfile) => compact([
        site.clientName && `(${site.clientName})`,
        site.name
    ]).join(' ');
    const onClose = () => props.onClose(props.site, props.toItem);
    const onConfirm = () => props.onConfirm(props.site, props.toItem);
    const getFromText = (text: string | null) => text == null
        ? null
        : (
            <> {translate('SiteRelationships.Common.DeleteLinkConfirmation')} <b> {text}</b> </>
        );

    return (
        <ModalCard className="ChangeLinkConfirmation" modalId="confirmation" isShown={props.visible}>
            <ModalCardHeader modalId="confirmation" title={'Confirmation'} />
            <ModalCardBody modalId="confirmation">
                <p>{translate('SiteRelationShips.AttachClientForm.Button.Change')} {getSiteDisplayText(props.site)} {props.fieldName}
                    {getFromText(props.fromItemText)} {translate('SiteRelationships.Common.DeleteLinkConfirmation.To')} <b>{props.toItemText}</b>.</p>
            </ModalCardBody>
            <ModalCardFooter>
                {props.onConfirm && (
                    <Button onClick={onConfirm}>{translate('RiskRegisterUpsertPage.ConfirmDialog.ConfirmTitle')}</Button>
                )}
                <Button onClick={onClose}>{translate('RiskRegisterPage.RiskRegisterToolbar.Title.Close')}</Button>
            </ModalCardFooter>
        </ModalCard>
    );
};
