import { CermSiteOverviewActions, CermSiteOverviewActionTypes,
     collapseAllItems, expandItems } from 'actions/cerm-site-overview';
import { history } from 'routes/App';
import { RouteUrl } from '../routes';

export default {
    selectTab: (tab: RouteUrl) => history.push(tab),
    expandItems: (ids: string[]) => expandItems(...ids),
    collapseAllItems,
    onPageLoad: (): CermSiteOverviewActionTypes => ({
        type: CermSiteOverviewActions.LOAD_PAGE
    }),
    onTabChange: (): CermSiteOverviewActionTypes => ({
        type: CermSiteOverviewActions.LOAD_DATA
    }),
    onClearFilters: (): CermSiteOverviewActionTypes => ({
        type: CermSiteOverviewActions.CLEAR_FILTERS
    })
};
