import queryString from 'query-string';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax';
import {
    catchError,
    debounceTime,
    filter,
    flatMap,
    map,
    mergeMap,
    switchMap,
    takeUntil,
} from 'rxjs/operators';
import { IRiskAssessmentState, IRootRiskAssessmentState } from 'reducers/risk-assessment';
import { onRouteChange } from '../actions/app-actions';
import {
    IAddAttachmentAction,
    IConfirmClose,
    ILoadAttachmentsAction,
    ILoadAttachmentsFulfilledAction,
    IReloadAttachmentsAction,
    ISetAnswerAction,
    ISetQuestionSetAction,
    RiskAssessmentActions,
    RiskAssessmentActionTypes,
} from '../actions/risk-assessment-actions';
import { getErrorActions$ } from './epic-helpers';
import { getApiHeaders } from './shared-header';

import appConfig from 'helpers/config-helper';

const config = appConfig();

const apiUrl = config.REACT_APP_BASE_API;

const apiEndPoint = `${apiUrl}/api/RiskAssessmentApi`;

const SAVING_DELAY = 3000;

const refreshAttachmentsIfStillScanning: Epic<
    RiskAssessmentActionTypes,
    any,
    IRootRiskAssessmentState
> = (action$, state$) =>
    action$.pipe(
        ofType<ILoadAttachmentsFulfilledAction>(RiskAssessmentActions.LOAD_ATTACHMENTS_FULFILLED),
        filter((action) => action.payload.data.some((x) => x.isClean === null)),
        debounceTime(SAVING_DELAY),
        map<ILoadAttachmentsFulfilledAction, IReloadAttachmentsAction>((action) => ({
            type: RiskAssessmentActions.RELOAD_ATTACHMENTS,
            payload: {
                questionId: action.payload.id,
                siteId: state$.value.app.siteId,
            },
        }))
    );

const navigateToReviewPage: Epic<any> = (action$) =>
    action$.pipe(
        ofType<RiskAssessmentActionTypes>(RiskAssessmentActions.START_REVIEW),
        flatMap<IConfirmClose, RiskAssessmentActionTypes | any>((action) => [
            onRouteChange(`/review-close/`),
            {
                type: RiskAssessmentActions.LOAD_ATTACHMENTS,
                payload: {
                    questionId: action.payload.questionSet.id,
                },
            },
        ])
    );

const loadAssessment: Epic<RiskAssessmentActionTypes, any, IRootRiskAssessmentState> = (
    action$,
    state$
) =>
    action$.pipe(
        ofType(RiskAssessmentActions.LOAD_RISK_QUESTIONS),
        map(() => ({
            siteId: state$.value.app.siteId,
        })),
        map((query) => `${apiEndPoint}?${queryString.stringify(query)}`),
        switchMap((url) =>
            ajax.getJSON(url, getApiHeaders()).pipe(
                map<any, RiskAssessmentActionTypes>((response) => ({
                    type: RiskAssessmentActions.LOAD_RISK_QUESTIONS_FULFILLED,
                    payload: response,
                })),
                catchError<any, any>((error: AjaxError) =>
                    getErrorActions$('Assessments')(
                        RiskAssessmentActions.LOAD_RISK_QUESTIONS_FULFILLED,
                        error,
                        {
                            errorMessage: 'Unable to load assessment',
                        }
                    )
                )
            )
        )
    );

const loadAttachmentsEpic: Epic<RiskAssessmentActionTypes, any, IRootRiskAssessmentState> = (
    action$,
    state$
) =>
    action$.pipe(
        ofType<ILoadAttachmentsAction | IReloadAttachmentsAction>(
            RiskAssessmentActions.LOAD_ATTACHMENTS,
            RiskAssessmentActions.RELOAD_ATTACHMENTS
        ),
        map((action) => ({
            id: action.payload.questionId,
            siteId: state$.value.app.siteId,
        })),
        map((query) => ({
            url: `${apiEndPoint}/attachments?${queryString.stringify(query)}`,
            query,
        })),
        mergeMap(
            // TODO: Cancel previous load first
            ({ url, query }) =>
                ajax.getJSON(url, getApiHeaders()).pipe(
                    map<any, ILoadAttachmentsFulfilledAction>((response) => ({
                        type: RiskAssessmentActions.LOAD_ATTACHMENTS_FULFILLED,
                        payload: {
                            ...query,
                            data: response,
                        },
                    })),
                    takeUntil(
                        action$.pipe(ofType(RiskAssessmentActions.LOAD_ATTACHMENTS_REJECTED))
                    ),
                    catchError<any, any>((error: AjaxError) =>
                        getErrorActions$('assessments')(
                            RiskAssessmentActions.LOAD_ATTACHMENTS_REJECTED,
                            error,
                            {
                                errorMessage: 'Unable to load attachments',
                            }
                        )
                    )
                )
        )
    );

const setApplicability: Epic<RiskAssessmentActionTypes, any, IRootRiskAssessmentState> = (
    action$,
    state$
) =>
    action$.pipe(
        ofType(RiskAssessmentActions.SET_QUESTION_SET),
        switchMap((action: ISetQuestionSetAction) =>
            ajax
                .post(
                    `${apiEndPoint}/SetQuestionSet/${state$.value.app.siteId}`,
                    action.payload,
                    getApiHeaders()
                )
                .pipe(
                    map<AjaxResponse, RiskAssessmentActionTypes>((response) => ({
                        type: RiskAssessmentActions.SET_QUESTION_SET_FULFILLED,
                        payload: response.response,
                    })),
                    takeUntil(
                        action$.pipe(ofType(RiskAssessmentActions.SET_QUESTION_SET_CANCELLED))
                    ),
                    catchError<any, any>((error: AjaxError) =>
                        getErrorActions$('Assessments')(
                            RiskAssessmentActions.SET_QUESTION_SET_REJECTED,
                            error,
                            {
                                errorMessage: 'Unable to set process step',
                            }
                        )
                    )
                )
        )
    );

const setOutcome: Epic<RiskAssessmentActionTypes, any, IRootRiskAssessmentState> = (
    action$,
    state$
) =>
    action$.pipe(
        ofType(RiskAssessmentActions.SET_QUESTION),
        switchMap((action: ISetAnswerAction) =>
            ajax
                .post(
                    `${apiEndPoint}/SetQuestion/${state$.value.app.siteId}`,
                    action.payload,
                    getApiHeaders()
                )
                .pipe(
                    map<AjaxResponse, RiskAssessmentActionTypes>((response) => ({
                        type: RiskAssessmentActions.SET_QUESTION_FULFILLED,
                        payload: response.response,
                    })),
                    takeUntil(action$.pipe(ofType(RiskAssessmentActions.SET_QUESTION_CANCELLED))),
                    catchError<any, any>((error: AjaxError) =>
                        getErrorActions$('Assessments')(
                            RiskAssessmentActions.SET_QUESTION_REJECTED,
                            error,
                            {
                                errorMessage: 'Unable to set expected answer',
                            }
                        )
                    )
                )
        )
    );

const endReview: Epic<RiskAssessmentActionTypes, any, IRootRiskAssessmentState> = (
    action$,
    state$
) =>
    action$.pipe(
        ofType(RiskAssessmentActions.END_REVIEW),
        switchMap((action: ISetAnswerAction) =>
            ajax
                .post(
                    `${apiEndPoint}/SetAnswer/${state$.value.app.siteId}`,
                    action.payload,
                    getApiHeaders()
                )
                .pipe(
                    map<AjaxResponse, RiskAssessmentActionTypes>((response) => ({
                        type: RiskAssessmentActions.SET_QUESTION_FULFILLED,
                        payload: response.response,
                    })),
                    takeUntil(action$.pipe(ofType(RiskAssessmentActions.SET_QUESTION_CANCELLED))),
                    catchError<any, any>((error: AjaxError) =>
                        getErrorActions$('Assessments')(
                            RiskAssessmentActions.SET_QUESTION_REJECTED,
                            error,
                            {
                                errorMessage: 'Unable to set answer',
                            }
                        )
                    )
                )
        )
    );

const addAttachmentsEpic: Epic<any> = (action$, state$) =>
    action$.pipe(
        ofType<IAddAttachmentAction>(RiskAssessmentActions.ADD_ATTACHMENT),
        map((action) => action.payload),
        map((data) => {
            const formData = new FormData();
            formData.append('id', data.questionSetId);
            formData.append('childId', data.questionId);
            formData.append('siteId', state$.value.app.siteId);

            data.files.forEach((file) => formData.append(file.name, file));
            return { formData, data };
        }),
        mergeMap(
            // TODO: Cancel previous load first
            ({ data, formData }) =>
                ajax.post(`${apiEndPoint}/attachments`, formData, getApiHeaders()).pipe(
                    mergeMap<AjaxResponse, any>(() => [
                        {
                            type: RiskAssessmentActions.ADD_ATTACHMENT_FULFILLED,
                            payload: {
                                ...data,
                            },
                        },
                        {
                            type: RiskAssessmentActions.LOAD_ATTACHMENTS,
                            payload: {
                                ...data,
                            },
                        },
                    ]),
                    takeUntil(action$.pipe(ofType(RiskAssessmentActions.ADD_ATTACHMENT_CANCELLED))),
                    catchError<any, any>((error: AjaxError) =>
                        getErrorActions$('Risks')(
                            RiskAssessmentActions.ADD_ATTACHMENT_REJECTED,
                            error,
                            {
                                errorMessage: 'Unable to upload attachment',
                            }
                        )
                    )
                )
        )
    );

const loadPageEpic: Epic<any> = (action$, state$) =>
    action$.pipe(
        ofType(RiskAssessmentActions.LOAD_PAGE),
        switchMap(() =>
            ajax
                .getJSON(`${apiEndPoint}/loadPage/${state$.value.app.siteId}`, getApiHeaders())
                .pipe(
                    map<IRiskAssessmentState, RiskAssessmentActionTypes>((state) => ({
                        type: RiskAssessmentActions.LOAD_PAGE_FULFILLED,
                        payload: state,
                    })),
                    takeUntil(action$.pipe(ofType(RiskAssessmentActions.LOAD_PAGE_CANCELLED))),
                    catchError<any, any>((error: AjaxError) =>
                        getErrorActions$('RiskAssessment')(
                            RiskAssessmentActions.LOAD_PAGE_REJECTED,
                            error,
                            {
                                errorMessage: 'Unable to load page',
                            }
                        )
                    )
                )
        )
    );

export const riskAssessmentEpics = combineEpics(
    endReview,
    addAttachmentsEpic,
    refreshAttachmentsIfStillScanning,
    loadAttachmentsEpic,
    navigateToReviewPage,
    setApplicability,
    setOutcome,
    loadAssessment,
    loadPageEpic
);
