import * as React from 'react';
import { NavLink } from 'react-router-dom';
import useTranslate from 'translations/translation-utils';

const DashboardQuickLinks: React.FC = () => {
    const buttonCssClasses = 'button is-primary is-fullwidth is-medium';

    const translate = useTranslate();

    return (
        <div className="dashboard-top-buttons">
            <div className="columns is-variable is-1">
                <div className="column is-1 hide-top-buttons-heading">
                    <div className="columns">
                        <h2>{translate('Dashboard.QuickLinks')}</h2>
                    </div>
                </div>

                <div className="column">
                    <div className="columns is-mobile is-multiline">
                        <div className="column is-narrow is-6-mobile">
                            <NavLink to="/ShiftHandovers" className={buttonCssClasses}>
                                {translate('SideNavBar.Labels.ShiftHandover')}
                            </NavLink>
                        </div>
                        <div className="column is-narrow is-6-mobile">
                            <NavLink to="/CriticalSystemSpares" className={buttonCssClasses}>
                                {translate('LogsUpload.LogType.CriticalSpares')}
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardQuickLinks;
