import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import selectors from './selectors';
import actions from './actions';
import {
    Criticality,
    ICriticalSystemSpare,
    ICriticalSystemSpareFilters,
    ICriticalSystemSpareRequestFilters,
    ICriticalSystemSpareSubCategory,
    Status,
} from 'models/critical-system-spares/critical-system-spare';
import { Loader } from 'components/loader';
import { CriticalSystemSparesTable } from 'components/critical-system-spares/critical-system-spares-table';
import { CriticalSystemSparesGridToolbar } from 'components/critical-system-spares/critical-system-spares-toolbar';
import { CriticalSystemSparesFilters } from 'components/critical-system-spares/critical-system-spares-filters';
import { onRouteChange } from 'actions/app-actions';
import { OverlayPanel } from 'components/v2/components';
import Page from 'components/v2/page/Page';
import { HelpModuleContainer } from 'routes/help-module/help-module-container';
import { ModuleType } from 'models/help-module';
import { checkFilterExists } from 'utils/array-utils';
import { IKeyValue } from 'models/key-value';
import useTranslate from 'translations/translation-utils';

interface IProps extends RouteComponentProps<IRouteParams> {
    siteId: string;
    isLoading: boolean;
    criticalSpares: ICriticalSystemSpare[];
    loadAll: (filters: ICriticalSystemSpareRequestFilters) => void;
    exportData: (siteId: string) => void;
    clearForm: () => void;
    isExporting: boolean;
    categories: Array<IKeyValue<string>>;
    subCategories: ICriticalSystemSpareSubCategory[];
    siteName: string;
    isClient: boolean;
    showCriticalSpares: boolean;
}

interface IRouteParams {
    category?: string;
    status?: string;
}

export const CriticalSystemSparesPage: React.FC<IProps> = ({
    siteId,
    isLoading,
    criticalSpares,
    loadAll,
    exportData,
    isExporting,
    categories,
    subCategories,
    clearForm,
    isClient,
    match,
    showCriticalSpares,
}) => {
    const translate = useTranslate();
    const getCategory = () => {
        if (match.params.category) {
            const category = { value: match.params.category, label: match.params.category };
            return category;
        }
        return null;
    };

    const getStatus = () => {
        if (match.params.status) {
            return [match.params.status];
        }
        return [];
    };

    const [showFilters, setShowFilters] = React.useState(false);
    const [filterKeywords, setFilterKeywords] = React.useState([]);
    const [selectedCategory, setSelectedCategory] = React.useState(getCategory());
    const [selectedSubCategory, setSelectedSubCategory] = React.useState(null);
    const [filteredSubCategories, setFilteredSubCategories] = React.useState(null);
    const [selectedCriticality, setSelectedCriticality] = React.useState([
        Criticality.Critical.toString(),
    ]);
    const [selectedStatuses, setSelectedStatuses] = React.useState(getStatus());
    const [selectedCheckboxes, setSelectedCheckboxes] = React.useState([]);
    const [updatedCriticalSpares, setUpdatedCriticalSpares] = React.useState(null);
    const [showHelp, setShowHelp] = React.useState(false);

    const onClickHelp = () => {
        setShowHelp(!showHelp);
    };

    React.useEffect(() => {
        if (!siteId) {
            return;
        }
        if (siteId && !showCriticalSpares) {
            onRouteChange('/ClientDashboard');
        }
        loadAll(buildRequestFiltersObject());
    }, [
        siteId,
        filterKeywords,
        selectedCategory,
        selectedSubCategory,
        selectedCriticality,
        selectedCheckboxes,
        selectedStatuses,
    ]);

    React.useEffect(() => {
        const criticalSparesHasData = !criticalSpares || criticalSpares.length === 0;

        // if criticalSpares in redux is empty and we have a stale copy in component - refresh component copy
        if (criticalSparesHasData && updatedCriticalSpares) {
            setUpdatedCriticalSpares(null);
            return;
        }

        if (criticalSparesHasData) {
            return;
        }

        const updatedCriticalSparesList = criticalSpares.map((x) => ({
            ...x,
            status: generateStatus(x),
        }));
        setUpdatedCriticalSpares(updatedCriticalSparesList);
    }, [criticalSpares]);

    const buildFiltersObject = (): ICriticalSystemSpareFilters => {
        return {
            siteId,
            filterKeywords,
            selectedCategory,
            selectedSubCategory,
            selectedCriticality,
            selectedStatuses,
            showArchivedParts: selectedCheckboxes.length > 0 ? true : false,
        };
    };

    const buildRequestFiltersObject = (): ICriticalSystemSpareRequestFilters => {
        const filters = buildFiltersObject();
        return {
            siteId: filters.siteId,
            filterKeywords: filters.filterKeywords,
            selectedCategory: filters.selectedCategory ? filters.selectedCategory.value : '',
            selectedSubCategory: filters.selectedSubCategory
                ? filters.selectedSubCategory.value
                : '',
            selectedCriticality: filters.selectedCriticality ? filters.selectedCriticality : [],
            selectedStatuses: filters.selectedStatuses ? filters.selectedStatuses : [],
            showArchivedParts: filters.showArchivedParts,
        };
    };

    const toggleFilters = () => {
        setShowFilters(showFilters ? false : true);
    };

    const resetFilters = () => {
        setFilterKeywords([]);
        setSelectedCategory({ value: '', label: '' });
        setSelectedSubCategory('');
        setSelectedCriticality([]);
        setSelectedCheckboxes([]);
        setSelectedStatuses([]);
    };

    const onExport = () => {
        exportData(siteId);
    };

    const onAddFilterKeyword = (keyword) => {
        if (!keyword) {
            return;
        }
        const newFilterKeywords = [...filterKeywords, keyword];
        setFilterKeywords(newFilterKeywords);
    };

    const onRemoveFilterKeyword = (keyword) => {
        const newFilterKeywords = filterKeywords.filter((x) => x !== keyword);
        setFilterKeywords(newFilterKeywords);
    };

    const onSelectCategory = (value: { value: string; label: string }) => {
        setSelectedCategory(value);
        setSelectedSubCategory(null);
        setFilteredSubCategories(subCategories.filter((x) => x.category === value.value));
    };

    const onSelectSubCategory = (value: { value: string; label: string }) => {
        setSelectedSubCategory(value);
    };

    const onFilter = (_: Extract<keyof ICriticalSystemSpareFilters, string>, element: string) => {
        if (element === 'showArchivedRecords'.toString()) {
            setSelectedCheckboxes(selectedCheckboxes.length > 0 ? [] : [element]);
        } else if (isCriticalityFilter(element)) {
            filterCriticality(element);
        } else {
            filterStatus(element);
        }
    };

    const filterCriticality = (element: string) => {
        const index = selectedCriticality.indexOf(element);
        if (index !== -1) {
            const items = [
                ...selectedCriticality.slice(0, index),
                ...selectedCriticality.slice(index + 1),
            ];
            setSelectedCriticality(items);
        } else {
            const items = [element, ...selectedCriticality.slice(0, selectedCriticality.length)];
            setSelectedCriticality(items);
        }
    };

    const filterStatus = (element: string) => {
        const index = selectedStatuses.indexOf(element);
        if (index !== -1) {
            const items = [
                ...selectedStatuses.slice(0, index),
                ...selectedStatuses.slice(index + 1),
            ];
            setSelectedStatuses(items);
        } else {
            const items = [element, ...selectedStatuses.slice(0, selectedStatuses.length)];
            setSelectedStatuses(items);
        }
    };

    const isCriticalityFilter = (element: string) => {
        return (
            element.indexOf(Criticality.Critical) !== -1 ||
            element.indexOf(Criticality.NonCritical) !== -1
        );
    };

    const generateStatus = (item: ICriticalSystemSpare): Status => {
        if (item.quantityCurrent < item.quantityMin) {
            return Status.BelowMin;
        }
        if (item.quantityCurrent - item.quantityMin <= 2) {
            return Status.CloseToMin;
        }
        return Status.Ok;
    };

    const isFilterExists = checkFilterExists(buildRequestFiltersObject());
    return (
        <Page title={translate('CriticalSystemSpares.Lable.1')} className="critical-spares-page">
            <CriticalSystemSparesGridToolbar
                isClient={isClient}
                onShowFilters={toggleFilters}
                onClearFilters={resetFilters}
                onExport={onExport}
                clearForm={clearForm}
                disableExport={
                    isExporting || !updatedCriticalSpares || updatedCriticalSpares.length === 0
                }
                onClickHelp={onClickHelp}
                isShowHelp={false}
                isFilterExists={isFilterExists}
            />
            <HelpModuleContainer
                isShown={showHelp}
                onClose={onClickHelp}
                moduleType={ModuleType.CriticalSpare}
            />
            <OverlayPanel
                title={translate('RiskRegisterPage.Title.Filters')}
                isVisible={showFilters}
                onClose={() => setShowFilters(false)}
                onOutsideDialogClick={() => setShowFilters(false)}
                onClearFilters={resetFilters}
                isFilterExists={isFilterExists}
            >
                <CriticalSystemSparesFilters
                    filters={buildFiltersObject()}
                    categories={categories}
                    subCategories={filteredSubCategories ? filteredSubCategories : subCategories}
                    onAddFilterKeyword={onAddFilterKeyword}
                    onRemoveFilterKeyword={onRemoveFilterKeyword}
                    onSelectCategory={onSelectCategory}
                    onSelectSubCategory={onSelectSubCategory}
                    onFilter={onFilter}
                    selectedCheckboxes={selectedCheckboxes}
                    selectedStatuses={selectedStatuses}
                    selectedCriticality={selectedCriticality}
                />
            </OverlayPanel>
            <Loader className="" loading={isLoading}>
                {updatedCriticalSpares && updatedCriticalSpares.length > 0 ? (
                    <CriticalSystemSparesTable
                        criticalSpares={updatedCriticalSpares}
                        isClient={isClient}
                    />
                ) : (
                    !isLoading && (
                        <div className="column is-12 has-text-centered">{translate('RiskRegisterPage.Message.NoDataAvailable')}</div>
                    )
                )}
            </Loader>
        </Page>
    );
};

export default withRouter(connect(selectors, actions)(CriticalSystemSparesPage));
