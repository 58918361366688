import { createSelector } from 'reselect';
import { IRootManageRoleState } from 'reducers/manageRoles';
import { IRootState } from 'routes/store';
import { getClients } from 'routes/site-features/selectors';

const getUpsertPersonRequest = createSelector(
    (state: IRootManageRoleState) => state,
    state => state.manageRoles.personForm.upsertItem
);
const getRelationships = createSelector(
    (state: IRootManageRoleState) => state,
    state => state.manageRoles.personForm.relationships
);

const getIsLoading = (state: IRootState) => state.manageRoles.personForm.isLoading;

const getLookupAssignableRoles = createSelector(
    (state: IRootState) => state,
    state => state.app.lookupAssignableRoles
);

const getSites = (state: IRootState) => state.siteFeatures.sites;
const getPersonAssignedSites = (state: IRootState) => state.persons.grid.personAssignedSites;

const getPrivileges = (state: IRootState) => state.manageRoles.grid.privileges?.privileges;
const getlookups = (state: IRootState) => state.app;
const getSiteMetaState = (state: IRootState) => state.siteMetaDataState;
const getOperatingPlatforms = (state: IRootState) =>
    state.operatingPlatformState.operatingPlatforms;

export default createSelector(
    getUpsertPersonRequest,
    getIsLoading,
    getSites,
    getPersonAssignedSites,
    getOperatingPlatforms,
    getClients,
    getLookupAssignableRoles,
    getPrivileges,
    getRelationships,
    getlookups,
    getSiteMetaState,
    (
        upsertPersonRequest,
        isLoading,
        sites,
        personAssignedSites,
        allOperatingPlatforms,
        clients,
        lookupAssignableRoles,
        privileges,
        relationships,
        app,
        siteMetaDataState,
    ) => ({
        upsertPersonRequest,
        isLoading,
        sites,
        personAssignedSites,
        allOperatingPlatforms,
        divisions: siteMetaDataState.divisions,
        clients,
        lookupAssignableRoles,
        privileges,
        relationships,
        permissions: app.permissions,
        lineOfBusinesses: siteMetaDataState.allLineOfBusinesses,
        businessUnits: siteMetaDataState.businessUnit,
    })
);
