import { Action } from 'redux';
import { IAttachmentCollection } from 'models/attachment-collection';
import { IKeyedItem } from 'models/keyed-item';
import {
    IAssessmentQuestion,
    IAssessmentQuestionSet,
    IRiskAssessmentState
} from '../reducers/risk-assessment';

export enum RiskAssessmentActions {
    CHANGE_FILTER_VALUE = '@@riskAssessment/CHANGE_FILTER_VALUE',
    CLEAR_FILTERS = '@@riskAssessment/CLEAR_FILTERS',
    SORT = '@@riskAssessment/SORT',
    REVIEW_EDIT = '@@riskAssessment/REVIEW_EDIT',

    LOAD_RISK_QUESTIONS = '@@riskAssessment/LOAD_RISK_QUESTIONS',
    LOAD_RISK_QUESTIONS_FULFILLED = '@@riskAssessment/LOAD_RISK_QUESTIONS_FULFILLED',
    LOAD_RISK_QUESTIONS_REJECTED = '@@riskAssessment/LOAD_RISK_QUESTIONS_REJECTED',

    SET_QUESTION_SET = '@@riskAssessment/SET_QUESTION_SET',
    SET_QUESTION_SET_FULFILLED = '@@riskAssessment/SET_QUESTION_SET_FULFILLED',
    SET_QUESTION_SET_CANCELLED = '@@riskAssessment/SET_QUESTION_SET_CANCELLED',
    SET_QUESTION_SET_REJECTED = '@@riskAssessment/SET_QUESTION_SET_REJECTED',

    END_REVIEW = '@@riskAssessment/END_REVIEW',
    START_REVIEW = '@@riskAssessment/START_REVIEW',

    SET_QUESTION = '@@riskAssessment/SET_ANSWER',
    SET_QUESTION_FULFILLED = '@@riskAssessment/SET_ANSWER_FULFILLED',
    SET_QUESTION_CANCELLED = '@@riskAssessment/SET_ANSWER_CANCELLED',
    SET_QUESTION_REJECTED = '@@riskAssessment/SET_ANSWER_REJECTED',

    REVIEW_QUESTION_SET = '@@riskAssessment/REVIEW_QUESTION_SET',
    REVIEW_QUESTION_SET_FULFILLED = '@@riskAssessment/REVIEW_QUESTION_SET_FULFILLED',
    REVIEW_QUESTION_SET_REJECTED = '@@riskAssessment/REVIEW_QUESTION_SET_REJECTED',

    TOGGLE_FILTER_EXPANDED = '@@riskAssessment/TOGGLE_FILTER_EXPANDED',
    TOGGLE_QUESTION_SET = '@@riskAssessment/TOGGLE_QUESTION_SET',

    SHOW_ADD_ATTACHMENT_MODAL = '@@RiskQuestions/SHOW_ADD_ATTACHMENT_MODAL',
    HIDE_ADD_ATTACHMENT_MODAL = '@@RiskQuestions/HIDE_ADD_ATTACHMENT_MODAL',
    ADD_ATTACHMENT = '@@RiskQuestions/ADD_ATTACHMENT',
    ADD_ATTACHMENT_FULFILLED = '@@RiskQuestions/ADD_ATTACHMENT_FULFILLED',
    ADD_ATTACHMENT_REJECTED = '@@RiskQuestions/ADD_ATTACHMENT_REJECTED',
    ADD_ATTACHMENT_CANCELLED = '@@RiskQuestions/ADD_ATTACHMENT_CANCELLED',

    LOAD_ATTACHMENTS = '@@RiskQuestions/LOAD_ATTACHMENTS',
    LOAD_ATTACHMENTS_FULFILLED = '@@RiskQuestions/LOAD_ATTACHMENTS_FULFILLED',
    LOAD_ATTACHMENTS_REJECTED = '@@RiskQuestions/LOAD_ATTACHMENTS_REJECTED',

    HIDE_ATTACHMENTS = '@@RiskQuestions/HIDE_ATTACHMENTS',
    RELOAD_ATTACHMENTS = '@@RiskQuestions/RELOAD_ATTACHMENTS',
    LOAD_PAGE = '@@RiskQuestions/LOAD_PAGE',
    LOAD_PAGE_FULFILLED = '@@RiskQuestions/LOAD_PAGE_FULFILLED',
    LOAD_PAGE_REJECTED = '@@RiskQuestions/LOAD_PAGE_REJECTED',
    LOAD_PAGE_CANCELLED = '@@RiskQuestions/LOAD_PAGE_CANCELLED'
}

export interface ILoadPageAction extends Action {
    type: RiskAssessmentActions.LOAD_PAGE;
}

export interface ILoadPageRejectedAction extends Action {
    type: RiskAssessmentActions.LOAD_PAGE_REJECTED;
    error: true;
    payload: any;
}

export interface ILoadPageCancelledAction extends Action {
    type: RiskAssessmentActions.LOAD_PAGE_CANCELLED;
}

export interface ILoadPageFulfilledAction extends Action {
    type: RiskAssessmentActions.LOAD_PAGE_FULFILLED;
    payload: any;
}

export interface IStartReview extends Action {
    type: RiskAssessmentActions.START_REVIEW;
    payload: {
        questionSet: IAssessmentQuestionSet;
    };
}

export const onStartReview = (questionSet: IAssessmentQuestionSet): RiskAssessmentActionTypes => ({
    type: RiskAssessmentActions.START_REVIEW,
    payload: {
        questionSet
    }
});

export interface ISetFilterValueAction extends Action {
    type: RiskAssessmentActions.CHANGE_FILTER_VALUE;
    payload: {
        field: keyof IRiskAssessmentState;
        value: string;
    };
}

export const onFilter = (
    field: keyof IRiskAssessmentState,
    value: string
): RiskAssessmentActionTypes => ({
    type: RiskAssessmentActions.CHANGE_FILTER_VALUE,
    payload: {
        field,
        value
    }
});

export interface ILoadAttachmentsFulfilledAction extends Action {
    type: RiskAssessmentActions.LOAD_ATTACHMENTS_FULFILLED;
    payload: {
        id: string;
        data: IAttachmentCollection[];
    };
}

export interface ISort extends Action {
    type: RiskAssessmentActions.SORT;
    payload: {
        sortColumn: string;
    };
}

export interface ISetOutcomeEndReview extends Action {
    type: RiskAssessmentActions.END_REVIEW;
    payload: {
        questionSet: IAssessmentQuestionSet;
    };
}

export const onEndReview = (questionSet: IAssessmentQuestionSet): RiskAssessmentActionTypes => ({
    type: RiskAssessmentActions.END_REVIEW,
    payload: {
        questionSet
    }
});

export interface IShowAddAttachmentModalAction extends Action {
    type: RiskAssessmentActions.SHOW_ADD_ATTACHMENT_MODAL;
}

export const onShowAddAttachmentModal = (): RiskAssessmentActionTypes => ({
    type: RiskAssessmentActions.SHOW_ADD_ATTACHMENT_MODAL
});

export interface IHideAddAttachmentModalAction extends Action {
    type: RiskAssessmentActions.HIDE_ADD_ATTACHMENT_MODAL;
}

export const onHideAttachmentModal = (): RiskAssessmentActionTypes => ({
    type: RiskAssessmentActions.HIDE_ADD_ATTACHMENT_MODAL
});

export interface IAddAttachmentAction extends Action {
    type: RiskAssessmentActions.ADD_ATTACHMENT;
    payload: {
        questionSetId: string;
        questionId: string;
        files: File[];
    };
}

export const onAddAttachment = (
    questionSetId: string,
    questionId: string,
    files: File[]
): RiskAssessmentActionTypes => ({
    type: RiskAssessmentActions.ADD_ATTACHMENT,
    payload: {
        questionSetId,
        questionId,
        files
    }
});

export interface IAddAttachmentCancelledAction extends Action {
    type: RiskAssessmentActions.ADD_ATTACHMENT_CANCELLED;
    payload: {
        questionId: string;
    };
}

export interface IAddAttachmentFulfilledAction extends Action {
    type: RiskAssessmentActions.ADD_ATTACHMENT_FULFILLED;
    payload: {
        questionId: string;
    };
}

export interface IAddAttachmentRejectedAction extends Action {
    type: RiskAssessmentActions.ADD_ATTACHMENT_REJECTED;
    payload: {
        questionId: string;
        files: File[];
    };
}

export interface IConfirmClose extends Action {
    type: RiskAssessmentActions.REVIEW_EDIT;
    payload: {
        questionSet: IAssessmentQuestionSet;
    };
}

export interface ILoadRiskQuestionRejected extends Action {
    type: RiskAssessmentActions.LOAD_RISK_QUESTIONS_REJECTED;
}

export interface ILoadRiskQuestionsFulfilled extends Action {
    type: RiskAssessmentActions.LOAD_RISK_QUESTIONS_FULFILLED;
    payload: IRiskAssessmentState;
}

export interface ILoadRiskQuestions extends Action {
    type: RiskAssessmentActions.LOAD_RISK_QUESTIONS;
    payload: {
        siteId: string;
    };
}

export const onLoadRiskQuestions = (siteId: string): RiskAssessmentActionTypes => ({
    type: RiskAssessmentActions.LOAD_RISK_QUESTIONS,
    payload: {
        siteId
    }
});

export interface IToggleFilterExpanded extends Action {
    type: RiskAssessmentActions.TOGGLE_FILTER_EXPANDED;
    payload: {
        id: Extract<keyof IRiskAssessmentState, string>;
    };
}

export const onToggleFilterExpanded = (
    id: Extract<keyof IRiskAssessmentState, string>
): RiskAssessmentActionTypes => ({
    type: RiskAssessmentActions.TOGGLE_FILTER_EXPANDED,
    payload: {
        id
    }
});

export interface IClearFiltersRiskQuestionsAction extends Action {
    type: RiskAssessmentActions.CLEAR_FILTERS;
}

export const onClearFilters = (): RiskAssessmentActionTypes => ({
    type: RiskAssessmentActions.CLEAR_FILTERS
});

export interface ISetQuestionSetAction extends Action {
    type: RiskAssessmentActions.SET_QUESTION_SET;
    payload: {
        questionSetId: string;
        answer?: boolean;
    };
}

export const onSetQuestionSet = (
    questionSetId: string,
    answer?: boolean
): RiskAssessmentActionTypes => ({
    type: RiskAssessmentActions.SET_QUESTION_SET,
    payload: {
        questionSetId,
        answer
    }
});

export interface ISetRiskQuestionSetActionFulfilledAction extends Action {
    type: RiskAssessmentActions.SET_QUESTION_SET_FULFILLED;
    payload: {
        success?: boolean;
        questionSetId: string;
        answer?: boolean;
    };
}

export interface ISetRiskQuestionsSetRejectedAction extends Action {
    type: RiskAssessmentActions.SET_QUESTION_SET_REJECTED;
    payload: {
        questionSetId: string;
        answer?: boolean;
    };
}

export interface ISetAnswerAction extends Action {
    type: RiskAssessmentActions.SET_QUESTION;
    payload: {
        questionId: string;
        questionSetId: string;
        answer?: boolean;
    };
}

export const onSetQuestion = (
    question: IAssessmentQuestion,
    questionSetId: string
): RiskAssessmentActionTypes => ({
    type: RiskAssessmentActions.SET_QUESTION,
    payload: {
        questionSetId,
        questionId: question.id,
        answer: question.answer
    }
});

export interface ILoadAttachmentsAction extends Action {
    type: RiskAssessmentActions.LOAD_ATTACHMENTS;
    payload: {
        questionId: string;
    };
}

export const loadAttachments = (questionId: string): RiskAssessmentActionTypes => ({
    type: RiskAssessmentActions.LOAD_ATTACHMENTS,
    payload: {
        questionId
    }
});

export interface IReloadAttachmentsAction extends Action {
    type: RiskAssessmentActions.RELOAD_ATTACHMENTS;
    payload: {
        questionId: string;
    };
}

export const reloadAttachments = (questionId: string): RiskAssessmentActionTypes => ({
    type: RiskAssessmentActions.RELOAD_ATTACHMENTS,
    payload: {
        questionId
    }
});

export interface IHideAttachmentsAction extends Action {
    type: RiskAssessmentActions.HIDE_ATTACHMENTS;
    payload: {
        questionSet: IKeyedItem;
    };
}

export const onHideAttachments = (item: IKeyedItem): RiskAssessmentActionTypes => ({
    type: RiskAssessmentActions.HIDE_ATTACHMENTS,
    payload: {
        questionSet: item
    }
});

export interface IReviewSetAnswerAction extends Action {
    type: RiskAssessmentActions.REVIEW_QUESTION_SET;
    payload: {
        questionSet: IAssessmentQuestionSet;
    };
}

export const onReviewSetAnswer = (
    questionSet: IAssessmentQuestionSet
): RiskAssessmentActionTypes => ({
    type: RiskAssessmentActions.REVIEW_QUESTION_SET,
    payload: {
        questionSet
    }
});

export interface ISetQuestionFulfilledAction extends Action {
    type: RiskAssessmentActions.SET_QUESTION_FULFILLED;
    payload: {
        success?: boolean;
        questionSetId: string;
        questionId: string;
        answer?: boolean;
    };
}

export interface ISetQuestionRejectedAction extends Action {
    type: RiskAssessmentActions.SET_QUESTION_REJECTED;
    payload: {
        questionSetId: string;
        answer?: boolean;
    };
}

export type RiskAssessmentActionTypes =
    | IStartReview
    | ISetFilterValueAction
    | ILoadAttachmentsAction
    | IHideAttachmentsAction
    | ILoadAttachmentsFulfilledAction
    | IReloadAttachmentsAction
    | ISetOutcomeEndReview
    | ISort
    | IConfirmClose
    | ILoadRiskQuestionRejected
    | ILoadRiskQuestionsFulfilled
    | ILoadRiskQuestions
    | ISetQuestionRejectedAction
    | ISetQuestionFulfilledAction
    | ISetAnswerAction
    | IToggleFilterExpanded
    | IReviewSetAnswerAction
    | ISetQuestionSetAction
    | ISetRiskQuestionSetActionFulfilledAction
    | IClearFiltersRiskQuestionsAction
    | ISetRiskQuestionsSetRejectedAction
    | IShowAddAttachmentModalAction
    | IHideAddAttachmentModalAction
    | IAddAttachmentAction
    | IAddAttachmentFulfilledAction
    | IAddAttachmentRejectedAction
    | IAddAttachmentCancelledAction
    | ILoadPageAction
    | ILoadPageCancelledAction
    | ILoadPageRejectedAction
    | ILoadPageFulfilledAction;
