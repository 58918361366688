import * as React from 'react';
import { IIncident, IApproval, IIncidentApproval } from 'models/incident';
import { IncidentApprovalBarNormal } from './approval-bar';
import { IncidentReapprovalBar } from './reapproval-bar';
import { IncidentApprovalStatus } from 'models';
import { isEmpty } from 'lodash';

export interface IProps {
    userId: string;
    incident: IIncident;
    setIncidentApproval: (approval: IIncidentApproval) => void;
}

export const IncidentApprovalBar: React.FC<IProps> = (props) => {
    const [latestApprovalStatus, setLatestApprovalStatus] = React.useState(null);
    React.useEffect(() => {
        if (props.userId && props.incident && canGetLatestApprovalStatus()) {
            setLatestApprovalStatus(getLatestApprovalStatus());
        }
    }, [props.userId, props.incident])
    const getLatestApprovalStatus = (): IncidentApprovalStatus | null => {
        const latestApproval: IApproval = getLatestApprover(props.userId);
        return latestApproval ? latestApproval.status : null;
    }

    const reapprove = () => {
        setLatestApprovalStatus(null)
    }

    const approve = (approval: IIncidentApproval) => {
        props.setIncidentApproval({
            ...approval,
            id: props.incident.id,
            siteId: props.incident.siteId
        });
    }

    const canGetLatestApprovalStatus = (): boolean => {
        return (
            (props.incident.approvalResponses.length > 0 &&
                props.incident.approvalResponses.length > 0 &&
                props.incident.approvalResponses.length !==
                props.incident.approvalResponses.length)
        );
    }

    const getLatestApprover = (userId: string): IApproval | null => {
        if (
            !props.incident.approvalResponses ||
            !props.incident.approvalResponses.some((s) => s.approver === userId)
        ) {
            return null;
        }

        return props.incident && !isEmpty(props.incident.approvalResponses)
            ? props.incident.approvalResponses
                .filter((x) => x.approver === userId)
                .reduce((a, b) => (a.statusDate > b.statusDate ? a : b))
            : null;
    }
    return latestApprovalStatus ? (
        <IncidentReapprovalBar
            approvalStatus={latestApprovalStatus}
            reapprove={reapprove}
        />
    ) : (
        <IncidentApprovalBarNormal changeApprovalStatus={approve} />
    );
}
