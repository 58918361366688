import { createSelector } from 'reselect';
import { IRootManageRoleState } from 'reducers/manageRoles';
import { IKeyValue } from 'models';

const getShowAddEditRoleModal = createSelector(
    (state: IRootManageRoleState) => state,
    (state) => state.manageRoles.roleForm.showUpsertRoleModal
);

const getUpsertRoleRequest = createSelector(
    (state: IRootManageRoleState) => state,
    (state) => {
        return state.manageRoles.roleForm.upsertItem;
    }
);

const getRoleLevels = createSelector(
    (state: IRootManageRoleState) => state,
    (state) => {
        const result: Array<IKeyValue<number>> = [];
        const roleLevel = state.app.permissions.find((c) => c.key === 'RoleLevel');
        // tslint:disable-next-line: radix
        let numberRoleLevel = roleLevel ? parseInt(roleLevel.value) : 1;
        if (isNaN(numberRoleLevel)) {
            numberRoleLevel = 1;
        }
        for (let i = 1; i <= numberRoleLevel; i++) {
            result.push({
                key: i.toString(),
                value: i
            } as IKeyValue<number>);
        }
        return result;
    }
);

export default createSelector(
    getShowAddEditRoleModal,
    getUpsertRoleRequest,
    getRoleLevels,
    (isShown, upsertRoleRequest, roleLevels) => ({
        isShown,
        upsertRoleRequest,
        roleLevels
    })
);
