import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { getColumnCss } from 'components/common/common';
import { IKeyedItemExtended } from 'models/keyed-item';
import { GridSortLink } from '../../../../components/gridSortLink';
import { SortDirection } from '../../../../enums/SortDirection';
import { ICermOverviewPillar, ICermOverviewProcessStep } from '../../../../models/cerm-overview';
import { IKeyValue } from '../../../../models/key-value';
import { CermOverviewPillar } from './cerm-overview-list-pillar';
import './cerm-overview-list.scss';
import { MaterialIconType } from 'routes/material-icon/material-icon-type';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';
import { Form, FormikProps, Formik } from 'formik';
import { Control, Field } from 'components/form';
import { BulmaSize } from 'enums/BulmaSize';
import { IOperatingPlatformProcess } from 'models/site-profile';
import { Select as QuantumSelect } from 'components/select';
import { IPerson } from 'models/person';
import { onRouteChange } from 'actions/app-actions';
import { RouteUrlOperatingPlatform } from 'routes/upsert-site/routes';
import { Icon, IconType } from 'components/icon';

interface IRouteParams {
    operatingPlatform?: string;
}

export interface IProps extends RouteComponentProps<IRouteParams> {
    permissions: Array<IKeyValue<string>>;
    items: ICermOverviewPillar[];
    isLoading: boolean;
    expandedPillars: string[];
    expandedItems: string[];
    sortColumn: string;
    isSortAscending: boolean;
    isDeleting: boolean;
    intl: any;
    onSort: (
        field: Extract<keyof ICermOverviewProcessStep, string>,
        isSortAscending: boolean
    ) => void;
    onTogglePillar: (id: string) => void;
    onToggleProcessStep: (id: string) => void;
    onDelete: (item: IKeyedItemExtended) => void;
    onCollapseAll: () => void;
    onExpandAll: (pillars: string[], steps: string[]) => void;
    person: IPerson;
    getTitlePrefix: () => void;
    onSubmit: (request: IOperatingPlatformProcess) => void;
    isOpExist: boolean;
}

interface MyFormValues {
    operatingPlatformDropdown: string;
}

class CermOverviewListClass extends React.Component<IProps> {
    public state = {
        pillarsExpanded: false,
    };

    constructor(props: IProps) {
        super(props);
        this.onClickHandle = this.onClickHandle.bind(this);
    }

    public componentWillMount() {
        this.props.onExpandAll(
            this.props.items.map(({ title }) => title),
            []
        );
    }

    public onClickHandle() {
        const expanded = this.state.pillarsExpanded;
        if (expanded) {
            this.props.onCollapseAll();
        } else {
            const steps = this.props.items.map(({ processSteps }) =>
                processSteps.map(({ id }) => id)
            );

            const arraySteps = [].concat(...steps);

            this.props.onExpandAll(
                this.props.items.map(({ title }) => title),
                arraySteps
            );
        }
        this.setState({ pillarsExpanded: !expanded });
    }

    public getCustomHeaderTile() {
        const title = `${this.props.getTitlePrefix()}  ${translate(
            this.props.intl,
            'CermProcessStepsPage.Table.Column.Process'
        )}`;
        return title;
    }

    public render() {
        const intl = this.props.intl;

        const getGridHeader = (
            fieldName: Extract<keyof ICermOverviewProcessStep, string>,
            label: string,
            props: IProps
        ) => {
            const onSort = (
                sortField: Extract<keyof ICermOverviewProcessStep, string>,
                sortDirection: SortDirection
            ) => props.onSort(sortField, sortDirection === SortDirection.Ascending);

            return (
                <GridSortLink
                    currentSortFieldName={props.sortColumn}
                    currentSortDirection={
                        props.isSortAscending ? SortDirection.Ascending : SortDirection.Descending
                    }
                    sortFieldName={fieldName}
                    onSort={onSort}
                >
                    {label}
                </GridSortLink>
            );
        };

        let formikBag: FormikProps<IOperatingPlatformProcess>;
        const selectOP = (e: any) => {
            const value = e.target.value;
            formikBag.setFieldValue('operatingPlatformDropdown', value);
            onRouteChange(`${RouteUrlOperatingPlatform(value)}`);
        };
        const renderForm = (formik: FormikProps<IOperatingPlatformProcess>) => {
            formikBag = formik;
            return (
                <Form id="processOverviewDropdown">
                    <Field
                        isHorizontal={true}
                        htmlFor={translate(
                            this.props.intl,
                            'UpsertCermProcessStep.Views.Dropdown.Title'
                        )}
                        label={translate(
                            this.props.intl,
                            'UpsertCermProcessStep.Views.Dropdown.Title'
                        )}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <QuantumSelect
                                    label={translate(
                                        this.props.intl,
                                        'UpsertCermProcessStep.Views.Dropdown.Title'
                                    )}
                                    id="operatingPlatformdropdown"
                                    name="operatingPlatformDropdown"
                                    aria-required="true"
                                    options={this.props.person.accessRights.operatingPlatforms
                                        .map(x => ({
                                            key: x,
                                            value: x,
                                            label: x,
                                        }))
                                        .sort((a, b) => a.label.localeCompare(b.label))}
                                    className="operatingPlatforms-dropdown"
                                    value={formikBag.values.operatingPlatformDropdown}
                                    onBlur={formikBag.handleBlur}
                                    onChange={selectOP}
                                />
                            </Control>
                        </Field>
                    </Field>
                </Form>
            );
        };

        const onSubmit = (upsertRoleRequest: IOperatingPlatformProcess) => {
            this.props.onSubmit(upsertRoleRequest);
        };
        const initialValues: MyFormValues = {
            operatingPlatformDropdown: this.props.match.params.operatingPlatform,
        };

        return (
            <>
                <div className="CermOverviewList">
                    {this.props.isOpExist &&
                    this.props.person.accessRights.operatingPlatforms.length > 1 ? (
                        <>
                            <div className="process-overview-dropdown">
                                <Formik<Partial<IOperatingPlatformProcess>>
                                    initialValues={initialValues}
                                    onSubmit={onSubmit}
                                    render={renderForm}
                                />
                                <div className="process-overview-dropdown-info-box">
                                    <div className="process-overview-dropdown-info">
                                        <Icon type={IconType.InfoCircle} />
                                    </div>
                                    <div className="process-overview-dropdown-info-text">
                                        {translate(
                                            this.props.intl,
                                            'UpsertCermProcessStep.Views.Dropdown.Info'
                                        )}
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </>
                    ) : null}
                    {this.props.items.length > 0 ? (
                        <>
                            <div className="CermOverviewList__headers Timeline-offset columns is-vcentered">
                                <div className="column has-text-weight-bold">
                                    <button
                                        className="TimelineHeader__toggle TimelineColumnHeader"
                                        onClick={this.onClickHandle}
                                    >
                                        <MaterialIcons
                                            type={
                                                this.state.pillarsExpanded
                                                    ? MaterialIconType.Minus
                                                    : MaterialIconType.Plus
                                            }
                                        />
                                    </button>
                                </div>
                                <div
                                    className={
                                        getColumnCss('processNumber') + 'has-text-weight-bold'
                                    }
                                >
                                    {getGridHeader('processNumber', '#', this.props)}
                                </div>

                                <div className={getColumnCss('title') + ' has-text-weight-bold'}>
                                    {getGridHeader('title', this.getCustomHeaderTile(), this.props)}
                                </div>

                                <div className={getColumnCss('status') + ' has-text-weight-bold'}>
                                    {getGridHeader(
                                        'status',
                                        translate(intl, 'RiskRegisterPage.RiskFilter.Title.Status'),
                                        this.props
                                    )}
                                </div>

                                <div
                                    className={
                                        getColumnCss('numberOfSites') + ' has-text-weight-bold'
                                    }
                                >
                                    {getGridHeader(
                                        'numberOfSites',
                                        translate(intl, 'SideNavBar.Labels.Sites'),
                                        this.props
                                    )}
                                </div>

                                <div
                                    className={
                                        getColumnCss('numberFullyCompliant') +
                                        ' has-text-weight-bold'
                                    }
                                >
                                    {getGridHeader(
                                        'numberFullyCompliant',
                                        translate(
                                            intl,
                                            'CermSiteOverview.List.Group.FullyComplete'
                                        ),
                                        this.props
                                    )}
                                </div>

                                <div
                                    className={
                                        getColumnCss('percentCompliant') + ' has-text-weight-bold'
                                    }
                                >
                                    {getGridHeader(
                                        'percentCompliant',
                                        translate(intl, 'CermOverview.List.Compliant'),
                                        this.props
                                    )}
                                </div>

                                <div
                                    className={
                                        getColumnCss('numberNotStarted') + ' has-text-weight-bold'
                                    }
                                >
                                    {getGridHeader(
                                        'numberNotStarted',
                                        translate(intl, 'CermOverview.List.NotStarted'),
                                        this.props
                                    )}
                                </div>

                                <div className={getColumnCss('priority') + ' has-text-weight-bold'}>
                                    {getGridHeader(
                                        'priority',
                                        translate(intl, 'RiskActionTab.Label.Priority'),
                                        this.props
                                    )}
                                </div>
                            </div>

                            {this.props.items.map(pillar => (
                                <CermOverviewPillar
                                    key={pillar.title}
                                    pillar={pillar}
                                    expandedItems={this.props.expandedItems}
                                    expandedPillars={this.props.expandedPillars}
                                    onDelete={this.props.onDelete}
                                    onTogglePillar={this.props.onTogglePillar}
                                    onToggleProcessStep={this.props.onToggleProcessStep}
                                    permissions={this.props.permissions}
                                    operatingPlatform={this.props.match.params.operatingPlatform}
                                    isDeleting={this.props.isDeleting}
                                    getTitlePrefix={this.props.getTitlePrefix}
                                />
                            ))}
                        </>
                    ) : (
                        <p>{translate(intl, 'CermOverview.List.Message')}</p>
                    )}
                </div>
            </>
        );
    }
}

export const CermOverviewList = injectIntl(CermOverviewListClass);
