import { IAppAction } from '../app-action';
import { ICermProcessOwner } from 'models/cerm-process-owners';

export enum CermProcessOwnerActions {
    LOAD_CERM_PROCESS = '@@cerm-process-owner/LOAD_CERM_PROCESS',
    LOAD_CERM_PROCESS_FULFILLED = '@@cerm-process-owner/LOAD_CERM_PROCESS_FULFILLED',
    LOAD_CERM_PROCESS_REJECTED = '@@cerm-process-owner/LOAD_CERM_PROCESS_REJECTED',

    CREATE_CERM_PROCESS = '@@cerm-process-owner/CREATE_CERM_PROCESS',
    CREATE_CERM_PROCESS_FULFILLED = '@@cerm-process-owner/CREATE_CERM_PROCESS_FULFILLED',
    CREATE_CERM_PROCESS_REJECTED = '@@cerm-process-owner/CREATE_CERM_PROCESS_REJECTED',

    UPDATE_CERM_PROCESS = '@@cerm-process-owner/UPDATE_CERM_PROCESS',
    UPDATE_CERM_PROCESS_FULFILLED = '@@cerm-process-owner/UPDATE_CERM_PROCESS_FULFILLED',
    UPDATE_CERM_PROCESS_REJECTED = '@@cerm-process-owner/UPDATE_CERM_PROCESS_REJECTED',

    EXPORT_SITE_USERS = '@@cerm-process-owner/EXPORT_SITE_USERS',
    EXPORT_SITE_USERS_FULFILLED = '@@cerm-process-owner/EXPORT_SITE_USERS_FULFILLED',
    EXPORT_SITE_USERS_REJECTED = '@@cerm-process-owner/EXPORT_SITE_USERS_REJECTED',
}

export const loadCermProcessOwner = (siteId: string): IAppAction => {
    return {
        type: CermProcessOwnerActions.LOAD_CERM_PROCESS,
        payload: siteId,
    };
};

export const createCermProcessOwner = (payload: ICermProcessOwner): IAppAction => ({
    type: CermProcessOwnerActions.CREATE_CERM_PROCESS,
    payload,
});

export const updateCermProcessOwner = (payload: ICermProcessOwner): IAppAction => ({
    type: CermProcessOwnerActions.UPDATE_CERM_PROCESS,
    payload,
});

export const exportSiteUsers = (): IAppAction => ({
    type: CermProcessOwnerActions.EXPORT_SITE_USERS,
});
