import * as React from 'react';
import { IKeyValue } from 'models/key-value';
import { RouteUrlSite } from 'routes/upsert-site/routes';
import { ISiteProfile } from 'models/site-profile';
import { connect } from 'react-redux';
import { IconToolbar, IToolbarIconItem, ToolbarIconType } from 'components/v2/components';
import { checkPermission } from 'utils/permission-utils';
import { history } from 'routes/App';
import selectors from './selectors';
import actions from './actions';
import { Privilege } from 'enums/Privilege';
import useTranslate from 'translations/translation-utils';

export interface IActionProps {
    permissions: Array<IKeyValue<string>>;
    isExportingSites: boolean;
    sites: ISiteProfile[];
    isFilterExists?: boolean;
    onShowFilters: () => void;
    onClearFilters: () => void;
    onExportSites: () => void;
    setInitialValues: () => void;
}

const SiteProfileGridToolbarView: React.FC<IActionProps> = ({
    permissions,
    isExportingSites,
    sites,
    isFilterExists,
    onShowFilters,
    onClearFilters,
    onExportSites,
    setInitialValues
}) => {
    const createNewSite = () => {
        setInitialValues();
        history.push(`${RouteUrlSite()}`);
    };

    const translate = useTranslate();
    const getToolbarItems = (): IToolbarIconItem[] => {
        return [
            checkPermission(Privilege.SiteCreate, permissions) && {
                id: 'add-site-button',
                title: translate('SiteProfileGridToolbar.CheckPermissions.Title'),
                type: ToolbarIconType.add,
                onClick: createNewSite
            },
            checkPermission('SiteExport', permissions) &&
            sites &&
            sites.length > 0 && {
                id: 'export-button',
                title: translate('SiteProfileGridToolbar.CheckPermissions.ExportSites'),
                type: ToolbarIconType.download,
                isDisabled: isExportingSites,
                onClick: onExportSites
            },
            {
                id: 'clear-all-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.ClearFilters'),
                type: isFilterExists ? ToolbarIconType.filterApplied : ToolbarIconType.clearFilter,
                onClick: onClearFilters
            },
            {
                id: 'show-filters-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.ShowFilters'),
                type: ToolbarIconType.filter,
                onClick: onShowFilters
            }
        ];
    };

    return <IconToolbar items={getToolbarItems()} />;
};

export const SiteProfileGridToolbar = connect(selectors, actions)(SiteProfileGridToolbarView);
