import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax';
import { catchError, flatMap, map, mapTo, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { getErrorActions$ } from './epic-helpers';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { onRouteChange } from './../actions/app-actions';
import { ISendSuccessNotification } from './../actions/app-notification-actions';
import { IPrivilege } from './../reducers/manageRoles/manageRoles-grid';
import { RouteUrl } from './../routes/manageRoles/routes';
import {
    IAddRemovePrivilege,
    IDeleteRole,
    IEditRolePrivileges,
    ILoadManagePrivilegesTabFulfilledAction,
    ILoadManageRolesPersonAction,
    INavigatePrivilegesTab,
    IToggleItemExpanded,
    ManageRoleActions,
    ManageRoleActionTypes,
} from '../actions/manage-role-actions';
import { IRootManageRoleState, IManageRoleState } from '../reducers/manageRoles';
import { PersonActions } from './../actions/actions-v2/person-action-v2';
import { IActionType, BaseEpic } from './base-epic';
import { IAppAction } from './../actions/app-action';
import { IResponse } from './../models/reponse/response';
import { getApiHeaders } from './shared-header';
import appConfig from 'helpers/config-helper';

const config = appConfig();

const apiUrl = config.REACT_APP_BASE_API;

const changeQueryEpic: Epic<ManageRoleActionTypes> = (action$) =>
    action$.pipe(
        ofType(
            ManageRoleActions.CHANGE_FILTER_VALUE,
            ManageRoleActions.CHANGE_SORT_ORDER_VALUE,
            ManageRoleActions.ADD_FILTER_KEYWORD,
            ManageRoleActions.REMOVE_FILTER_KEYWORD,
            ManageRoleActions.CLEAR_FILTERS,
            ManageRoleActions.CHANGE_ROLES
        ),
        mapTo<ManageRoleActionTypes, ManageRoleActionTypes>({
            type: ManageRoleActions.LOAD_MANAGE_ROLES,
        })
    );

const onLoadSitesEpic: Epic<ManageRoleActionTypes, ManageRoleActionTypes, IManageRoleState> = (
    action$
) =>
    action$.pipe(
        ofType(ManageRoleActions.LOAD_SITES),
        map(() => `${apiUrl}/api/site?`),
        mergeMap(
            // TODO: Cancel previous load first
            (url) =>
                ajax.getJSON(url, getApiHeaders()).pipe(
                    map<any, ManageRoleActionTypes>((response) => ({
                        type: ManageRoleActions.LOAD_SITES_FULFILLED,
                        payload: {
                            sites: response.value,
                        },
                    })),
                    catchError<any, any>((error: AjaxError) =>
                        getErrorActions$('Load sites features')(
                            ManageRoleActions.LOAD_SITES_REJECTED,
                            error,
                            {
                                errorMessage: 'Unable to load sites features',
                            }
                        )
                    )
                )
        )
    );

const loadPrivilegeTabEpic: Epic<
    ManageRoleActionTypes,
    ManageRoleActionTypes,
    IRootManageRoleState
> = (action$) =>
        action$.pipe(
            ofType(ManageRoleActions.LOAD_PRIVILEGES_TAB),
            map(() => ({ url: `${apiUrl}/api/privilege` })),
            mergeMap((data) =>
                ajax.getJSON(data.url, getApiHeaders()).pipe(
                    map<IPrivilege[], ManageRoleActionTypes>(
                        (response): ILoadManagePrivilegesTabFulfilledAction => ({
                            type: ManageRoleActions.LOAD_PRIVILEGES_TAB_FULFILLED,
                            payload: {
                                privilegesState: response,
                            },
                        })
                    ),
                    takeUntil(
                        action$.pipe(
                            ofType(
                                ManageRoleActions.LOAD_PRIVILEGES_TAB,
                                ManageRoleActions.LOAD_PRIVILEGES_TAB_CANCELLED
                            )
                        )
                    ),
                    catchError<any, any>((error: AjaxError) =>
                        getErrorActions$('Load Privileges')(
                            ManageRoleActions.LOAD_PRIVILEGES_TAB_REJECTED,
                            error,
                            {
                                errorMessage: 'Unable to load privileges',
                            }
                        )
                    )
                )
            )
        );

const expandPersonEpic: Epic<ManageRoleActionTypes, ManageRoleActionTypes, IRootManageRoleState> = (
    action$
) =>
    action$.pipe(
        ofType<IToggleItemExpanded>(ManageRoleActions.TOGGLE_ITEM_EXPANDED),
        map<IToggleItemExpanded, ILoadManageRolesPersonAction>((action) => ({
            type: ManageRoleActions.LOAD_MANAGE_ROLES_PERSON,
            payload: {
                personId: action.payload.personId,
            },
        }))
    );
const navigateToPrivilegesTab: Epic<any> = (action$) =>
    action$.pipe(
        ofType(ManageRoleActions.NAVIGATE_PRIVILEGES_TAB),
        flatMap<INavigatePrivilegesTab, ManageRoleActionTypes | any>(() => [
            onRouteChange(RouteUrl.Privileges),
            {
                type: ManageRoleActions.LOAD_PRIVILEGES_TAB,
            },
            {
                type: ManageRoleActions.CLEAR_FILTERS,
            },
        ])
    );

const navigateToPeopleTab: Epic<any> = (action$) =>
    action$.pipe(
        ofType(ManageRoleActions.NAVIGATE_PEOPLE_TAB),
        flatMap<any, ManageRoleActionTypes | any>(() => [
            onRouteChange(RouteUrl.People),
            {
                type: ManageRoleActions.LOAD_MANAGE_ROLES,
            },
            {
                type: ManageRoleActions.CLEAR_FILTERS,
            },
        ])
    );

const editRolePrivileges: Epic<any> = (action$) =>
    action$.pipe(
        ofType(ManageRoleActions.EDIT_ROLE_PRIVILEGES),
        flatMap<IEditRolePrivileges, ManageRoleActionTypes | any>((action) => [
            {
                type: ManageRoleActions.CHANGE_FILTER_VALUE,
                payload: {
                    field: 'manageRolesFilterRoles',
                    value: action.roleId,
                },
            },
            {
                type: ManageRoleActions.LOAD_PRIVILEGES_TAB,
            },
        ])
    );

const onAddRemovePrivilege: Epic<
    ManageRoleActionTypes,
    ManageRoleActionTypes,
    IRootManageRoleState
> = (action$) =>
        action$.pipe(
            ofType(ManageRoleActions.ADD_REMOVE_PRIVILEGE),
            switchMap((action: IAddRemovePrivilege) =>
                ajax
                    .post(
                        `${apiUrl}/api/privilege/AddRemovePrivileges`,
                        action.payload,
                        getApiHeaders()
                    )
                    .pipe(
                        flatMap<AjaxResponse, any | ISendSuccessNotification>(() => [
                            {
                                type: ManageRoleActions.ADD_REMOVE_PRIVILEGE_FULFILLED,
                                payload: {
                                    ...action.payload,
                                },
                            }
                        ]),
                        catchError<any, any>((error: AjaxError) =>
                            getErrorActions$('Manage Roles Privilege')(
                                ManageRoleActions.ADD_REMOVE_PRIVILEGE_REJECTED,
                                error,
                                {
                                    errorMessage: 'Unable to save the role privilege',
                                }
                            )
                        )
                    )
            )
        );
const deleteRole: Epic<ManageRoleActionTypes, ManageRoleActionTypes, IRootManageRoleState> = (
    action$
) =>
    action$.pipe(
        ofType(ManageRoleActions.DELETE_ROLE),
        switchMap((action: IDeleteRole) =>
            ajax.delete(`${apiUrl}/api/role/${action.payload.roleId}`, getApiHeaders()).pipe(
                flatMap<any, any>((response: AjaxResponse) => {
                    const serverResponse = response.response as IResponse<boolean>;
                    if (serverResponse.isSuccess) {
                        return [
                            {
                                type: ManageRoleActions.DELETE_ROLE_FULFILLED,
                                payload: {
                                    roleId: action.payload.roleId,
                                },
                            },
                        ];
                    }
                    // return [ getErrorNotification(serverResponse) ];
                }),
                catchError<any, any>((error: AjaxError) =>
                    getErrorActions$('Delete role rejected')(
                        ManageRoleActions.DELETE_ROLE_REJECTED,
                        error,
                        {
                            errorMessage: error.response,
                        }
                    )
                )
            )
        )
    );

const exportUsersActions = {
    action: PersonActions.EXPORT_PEOPLE,
    actionFulfilled: PersonActions.EXPORT_PEOPLE_FULFILLED,
    actionRejected: PersonActions.EXPORT_PEOPLE_REJECTED,
} as IActionType;

const exportUsersEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, exportUsersActions).export(`/api/person/export`);

export const manageRoleEpics = combineEpics(
    deleteRole,
    onAddRemovePrivilege,
    editRolePrivileges,
    loadPrivilegeTabEpic,
    navigateToPeopleTab,
    navigateToPrivilegesTab,
    changeQueryEpic,
    expandPersonEpic,
    onLoadSitesEpic,
    exportUsersEpic
);
