import { OperatingPlatform } from 'models/site-profile';
import { IOperatingPlatform } from 'models/operating-platform-model';

export interface ICermAssessmentResult {
    score: number;
    expertReviewStatus: IExpertReviewStatus;
    processStepResults: IProcessStepResult[];
}

export interface IExpertReviewStatus {
    startDate: Date | string;
    startedById?: string;
    startedByName?: string;
    finishDate: Date | string;
    nextDate?: Date;
    nextById?: string;
    nextByName?: string;
    isInReview: boolean;
    reviewType?: string;
}

export interface INextExpertReviewStatus {
    nextDate?: Date | string;
    nextById?: string;
    nextByName?: string;
}

export interface IProcessStepResult {
    pillar: string;
    service: string;
    priority: string;
    masterProcessStepId: string;
    completedOutcomes: number;
    compliance: number;
    applicable: boolean | null;
    cermTechnologyPlatform: string;
}

export interface ISetProcessStepResult {
    siteId: string;
    applicableToAccount: boolean;
    siteIds: string[];
    processStepResult: IProcessStepResult[];
}

export interface ISetProcessStepResultResponse {
    score: number;
    processStepResult: IProcessStepResult[];
}

export const getTitlePrefix = (operatingPlatform: string) => {
    switch (operatingPlatform) {
        case OperatingPlatform.Healthcare:
            return 'HERM';
        case OperatingPlatform.DataCenter:
            return 'CERM';
        default:
            return '';
    }
};

export const getTitlePrefixGeneric = (operatingPlatform: string, allOperatingPlatforms: IOperatingPlatform[]) => {
    const selectedOperatingPlatform =
        allOperatingPlatforms &&
        allOperatingPlatforms.find(
            x => x.value === operatingPlatform
        );
    return selectedOperatingPlatform?.shortForm.toUpperCase();
};


export enum ExpertReviewType {
    Virtual = 'Virtual',
    OnSite = 'On-site',
}