import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ActionPage as View } from './action-page';
import actions from './actions';
import selectors from './selectors';

export const ActionPage = withRouter(connect(
    selectors,
    actions
)(View));
