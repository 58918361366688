import { combineReducers } from 'redux';
import { IAppAction } from 'actions/app-action';
import { s2ab } from 'utils/export-utils';
import { EngineerActions } from 'actions/actions-v2/engineer-action-v2';
import { IEngineerState, getDefaultEngineerState } from 'state';
import { AttachmentActions } from 'actions/actions-v2/attachment-action-v2';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import _ from 'lodash';
import saveAs from 'file-saver';
const engineer = (
    state: IEngineerState['engineer'] = getDefaultEngineerState().engineer,
    action: IAppAction
) => {
    switch (action.type) {
        case EngineerActions.CLEAR_ENGINEER:
            return getDefaultEngineerState().engineer;
        case EngineerActions.LOAD_ENGINEER_FULFILLED:
            if (action.payload) {
                return action.payload;
            }
            return state;
        case EngineerActions.CREATE_ENGINEER_FULFILLED:
        case EngineerActions.UPDATE_ENGINEER_FULFILLED:
            /*  if (action.payload) {
                return action.payload;
            } */
            return state;
        case EngineerActions.CHANGE_STATUS:
            const skill = action.payload;
            const skills = state.skills.map((s) => {
                if (s.skillId === skill.skillId) {
                    return skill;
                }
                return s;
            });
            return { ...state, skills };

        case EngineerActions.CHANGE_TRAINING:
            const { trainingCategoryId, trainingTypeId, remove } = action.payload;

            const existingTrainingCategoryData = state.training.filter(
                (x) => x.trainingCategoryId === trainingCategoryId
            );

            let trainingTypeIds = [];

            // when row does not contain ticked checkboxes
            if (existingTrainingCategoryData.length === 0) {
                trainingTypeIds = [trainingTypeId];
            } else {
                // row contains ticked checkboxes
                trainingTypeIds = existingTrainingCategoryData[0].trainingTypeId.split(',');
                if (remove) {
                    trainingTypeIds = trainingTypeIds.filter((x) => x !== trainingTypeId);
                } else {
                    if (trainingTypeId === '1' || trainingTypeId === '2') {
                        trainingTypeIds = [trainingTypeId];
                    } else {
                        if (!trainingTypeIds.includes('1') && !trainingTypeIds.includes('2')) {
                            trainingTypeIds.push(trainingTypeId);
                        }
                    }
                }
            }
            const currentTraining = _.cloneDeep(state.training);
            if (
                currentTraining.some(
                    (item) => item.trainingCategoryId === action.payload.trainingCategoryId
                )
            ) {
                currentTraining.map((item) => {
                    if (item.trainingCategoryId === action.payload.trainingCategoryId) {
                        if (trainingTypeIds.includes('3') || trainingTypeIds.includes('7')) {
                            item.trainingTypeId = trainingTypeIds.toString();
                        } else {
                            item.trainingTypeId = trainingTypeIds.toString();
                            delete item.trainigExpiryDate;
                        }
                    }
                    return item;
                });
            } else {
                currentTraining.push({
                    trainingCategoryId: action.payload.trainingCategoryId,
                    trainingTypeId: trainingTypeIds.toString(),
                    isActionGenerated: false,
                });
            }
            return { ...state, training: currentTraining };

        case EngineerActions.CHANGE_TRAINING_EXPIRY_DATE:
            const updatedTraining = _.cloneDeep(state.training);
            if (
                updatedTraining.some(
                    (item) => item.trainingCategoryId === action.payload.trainingCategoryId
                )
            ) {
                updatedTraining.forEach((item) => {
                    if (item.trainingCategoryId === action.payload.trainingCategoryId) {
                        {
                            item.trainigExpiryDate = action.payload.expiryDate;
                            item.isActionGenerated = false;
                        }
                    }
                });
            } else {
                updatedTraining.push({
                    trainingCategoryId: action.payload.trainingCategoryId,
                    trainingTypeId: '',
                    trainigExpiryDate: action.payload.expiryDate,
                    isActionGenerated: false,
                });
            }
            return { ...state, training: updatedTraining };
        default:
            return state;
    }
};

const expandedCategories = (
    state: IEngineerState['expandedCategories'] = getDefaultEngineerState().expandedCategories,
    action: IAppAction
) => {
    switch (action.type) {
        case EngineerActions.TOGGLE_CATEGORY:
            const id = action.payload.id;
            if (state.includes(id)) {
                return state.filter((x) => x !== id);
            }
            return [...state, id];
        case EngineerActions.LOAD_ENGINEER_FULFILLED:
            if (action.payload && action.payload.skills) {
                return ['Overview'].concat(action.payload.skills.map((x) => x.skillCategoryId));
            }
            return state;
        default:
            return state;
    }
};

const isLoading = (
    state: IEngineerState['isLoading'] = getDefaultEngineerState().isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case EngineerActions.LOAD_ENGINEER:
        case EngineerActions.CREATE_ENGINEER:
        case EngineerActions.UPDATE_ENGINEER:
        case EngineerActions.DELETE_ENGINEER:
        case EngineerActions.EXPORT_ENGINEER:
            return true;
        case EngineerActions.LOAD_ENGINEER_FULFILLED:
        case EngineerActions.LOAD_ENGINEER_CANCELLED:
        case EngineerActions.LOAD_ENGINEER_REJECTED:
        case EngineerActions.CREATE_ENGINEER_CANCELLED:
        case EngineerActions.CREATE_ENGINEER_FULFILLED:
        case EngineerActions.CREATE_ENGINEER_REJECTED:
        case EngineerActions.UPDATE_ENGINEER_FULFILLED:
        case EngineerActions.UPDATE_ENGINEER_REJECTED:
        case EngineerActions.DELETE_ENGINEER_FULFILLED:
        case EngineerActions.DELETE_ENGINEER_REJECTED:
        case EngineerActions.EXPORT_ENGINEER_FULFILLED:
        case EngineerActions.EXPORT_ENGINEER_REJECTED:
            return false;
        default:
            return state;
    }
};

const isExportingEngineer = (
    state: IEngineerState['isExportingEngineer'] = getDefaultEngineerState().isExportingEngineer,
    action: IAppAction
) => {
    switch (action.type) {
        case EngineerActions.EXPORT_ENGINEER:
            return true;
        case EngineerActions.EXPORT_ENGINEER_FULFILLED:
            const blob = new Blob([s2ab(atob(action.payload.fileContents))], {
                type: 'application/excel',
            });
            saveAs(blob, action.payload.fileDownloadName);
            return false;
        case EngineerActions.EXPORT_ENGINEER_REJECTED:
        case EngineerActions.EXPORT_ENGINEER_CANCELLED:
            return false;
        default:
            return state;
    }
};

const isSaving = (
    state: IEngineerState['isSaving'] = getDefaultEngineerState().isSaving,
    action: IAppAction
) => {
    switch (action.type) {
        case EngineerActions.CREATE_ENGINEER_CANCELLED:
        case EngineerActions.CREATE_ENGINEER_REJECTED:
        case EngineerActions.CREATE_ENGINEER_FULFILLED:
        case EngineerActions.UPDATE_ENGINEER_CANCELLED:
        case EngineerActions.UPDATE_ENGINEER_REJECTED:
        case EngineerActions.UPDATE_ENGINEER_FULFILLED:
            return false;
        case EngineerActions.CREATE_ENGINEER:
        case EngineerActions.UPDATE_ENGINEER:
            return true;
        default:
            return state;
    }
};

const history = (
    state: IEngineerState['history'] = getDefaultEngineerState().history,
    action: IAppAction
) => {
    switch (action.type) {
        case EngineerActions.LOAD_HISTORY_FULFILLED:
            if (action.payload) {
                return action.payload;
            }

            return state;
        default:
            return state;
    }
};
const attachments = (
    state: IEngineerState['attachments'] = getDefaultEngineerState().attachments,
    action: IAppAction
) => {
    switch (action.type) {
        case AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_ENGINEER:
            if (action.payload) {
                return action.payload;
            }
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED:
            if (action.payload) {
                return [...state, ...action.payload];
            }
            return state;
        case AttachmentActions.DELETE_ATTACHMENT_FULFILLED:
            if (action.payload) {
                return state.filter((item: IAttachmentModel) => item.fileName !== action.payload);
            }
            return state;
        default:
            return state;
    }
};

const isUploading = (
    state: IEngineerState['isUploading'] = getDefaultEngineerState().isUploading,
    action: IAppAction
) => {
    switch (action.type) {
        case AttachmentActions.UPLOAD_ATTACHMENT:
            return true;
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED:
        case AttachmentActions.UPLOAD_ATTACHMENT_REJECTED:
            return false;
        default:
            return state;
    }
};

export const engineerReducer = combineReducers<IEngineerState>({
    isLoading,
    engineer,
    isExportingEngineer,
    isSaving,
    expandedCategories,
    history,
    attachments,
    isUploading,
});
