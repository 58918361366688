import React from 'react';
import {
    IToolbarIconItem,
    ToolbarIconType,
    IconToolbar,
} from '../../components/v2/toolbar/IconToolbar';
import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { checkPermission } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import { IKeyValue } from 'models/key-value';
import useTranslate from 'translations/translation-utils';

interface IProps {
    permissions: Array<IKeyValue<string>>;
    isClient?: boolean;
    isExporting?: boolean;
    onExport?: (siteId: string, clientExport: boolean) => void;
}

const CermProcessOwnerToolbarView: React.FC<IProps> = ({
    permissions,
    isClient,
    isExporting,
    onExport,
}) => {
    const translate = useTranslate();
    const onClickAll = () => {
        onExport(null, isClient);
    };

    const getToolbarItems = (): IToolbarIconItem[] => {
        return [
            checkPermission(Privilege.CermProcessOwnersDownloadAccessibility, permissions) && {
                id: 'export-button',
                title: translate('CermProcessOwner.Lable.4'),
                type: ToolbarIconType.download,
                isDisabled: isExporting,
                onClick: onClickAll,
            },
        ];
    };

    return <IconToolbar items={getToolbarItems()} />;
};

const mapStateToProps = (state: IRootState) => {
    return {
        siteId: state.app.siteId,
        permissions: state.app.permissions,
    };
};

export const CermProcessOwnerToolbar = connect(mapStateToProps)(CermProcessOwnerToolbarView);
