import { createSelector } from 'reselect';
import { IRootCermAssessmentState } from '../../../../reducers/cermAssessments';

const getIsVisible = createSelector(
    (state: IRootCermAssessmentState) => state.cermAssessmentState,
    (state) => state.isVisible
);

const getIsLoading = (state: IRootCermAssessmentState) => state.cermAssessmentState.isLoading;

export default createSelector(
    [getIsVisible, getIsLoading],
    (isVisible, isUploading)  => ({
        isVisible,
        isModalVisible: isVisible,
        isUploading
    })
);
