import React, { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import INavItem from 'models/nav-item';
import { uniqueId } from 'lodash';
import { easeInScrollToTop } from 'utils/animation-utils';
import useTranslate from 'translations/translation-utils';
import { logOut } from 'utils/auth-helper';
import { genericSort } from 'utils/sort-utils';

interface IStateProps extends RouteComponentProps {
    navItem?: INavItem;
    onMenuItemClick: () => void;
}

const SideNavMenuItem: React.FC<IStateProps> = ({
    navItem,
    location,
    history,
    match,
    onMenuItemClick,
}) => {
    const [itemStatus, setItemStatus] = useState('');
    const translate = useTranslate();

    useEffect(() => {
        if (
            hasChild() &&
            navItem.children.some(item => !item.isPreview && isLocationMatch(item.link))
        ) {
            setItemStatus('show');
        }
    }, [location]);

    const hasChild = () => {
        return navItem.children && navItem.children.length > 0;
    };

    const isMenuItemVisible = () => {
        if (!navItem.isVisible) {
            return false;
        }

        if (hasChild() && navItem.children.every(c => !c.isVisible)) {
            return false;
        }

        return true;
    };

    const getListItemClassName = () => {
        let className = `${itemStatus}`;
        if (hasChild() && navItem.isMobile) {
            className += ' is-hidden-tablet';
        }

        return className;
    };

    const getLinkItemClassName = () => {
        let className = '';
        if (hasChild()) {
            className = 'sidenav-menu-toggle';
        } else if (!navItem.isPreview && isLocationMatch(navItem.link)) {
            className = 'active';
        }

        // To be reverted in User Story 2635263: [Quantum UX] Navigation
        // if (navItem.iconName) {
        //     className += ` menuicon menuicon-${navItem.iconName}`;
        // }

        return className;
    };

    const isLocationMatch = (link: string): boolean => {
        if (link === location.pathname) {
            return true;
        }
        const linkPathToCompare = getCompareString(link);
        const locationPathToCompare = location.pathname && getCompareString(removeUrlParams(location.pathname));
        return `${locationPathToCompare}/`.search(new RegExp(`${linkPathToCompare}/`, 'i')) > -1;
    };

    const getCompareString = (value: string) => {
        return value.slice(-1) === 's' ? value.replace(/.$/s, '') : value;
    };

    const removeUrlParams = (value: string) => {
        const x = value.split('/').slice(1);
        const y = '/' + x[0];
        return y;
    };

    const getBadge = () => {
        if (hasChild()) {
            const found = navItem.children.some(l => l.badges > 0);
            return found ? <span className="badge" /> : null;
        }

        return navItem.badges > 0 ? <span className="badge">{navItem.badges}</span> : null;
    };

    const onItemClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (!hasChild()) {
            easeInScrollToTop();
            onMenuItemClick();
            return;
        }

        setItemStatus(itemStatus === '' ? 'show' : '');
        event.preventDefault();
    };
    const messageKey = `SideNavBar.Labels.${navItem.text.replace(/[^A-Z0-9]+/gi, '')}`;
    const children =
        navItem.text === 'Admin' ? genericSort('text', navItem.children, true) : navItem.children;
    return isMenuItemVisible() ? (
        <li className={getListItemClassName()}>
            {navItem.isHref ? (
                <a href="!#" className={getLinkItemClassName()} onClick={() => logOut()}>
                    {translate(messageKey)}
                    {getBadge()}
                </a>
            ) : (
                <Link to={navItem.link} className={getLinkItemClassName()} onClick={onItemClick}>
                    {!navItem.isUserName ? messageKey && translate(messageKey) : navItem.text}
                    {getBadge()}
                </Link>
            )}
            {hasChild() ? (
                <ul>
                    {children.map(childItem => {
                        return (
                            <SideNavMenuItem
                                history={history}
                                location={location}
                                match={match}
                                key={uniqueId(navItem.text)}
                                navItem={childItem}
                                onMenuItemClick={onMenuItemClick}
                            />
                        );
                    })}
                </ul>
            ) : null}
        </li>
    ) : null;
};

export default withRouter(SideNavMenuItem);
