import { createSelector } from 'reselect';
import { getSitesWithFeatures, getFilteredLookupSitePeopleWithAllowedRoles } from 'sharedSelectors';

export default createSelector(
    getFilteredLookupSitePeopleWithAllowedRoles,
    getSitesWithFeatures,
    (lookupSitePeopleRoles, lookupSiteFeatures) => ({
        lookupSitePeopleRoles,
        lookupSiteFeatures,
    })
);
