import { IAppAction } from 'actions/app-action';
import { Epic, combineEpics } from 'redux-observable';
import { BaseEpic, IActionType } from './base-epic';
import { SkillCardActions } from 'actions/skill-card-actions';
import { ISkillCard } from 'models/competency-dna/skill-card';
import { ISiteSkillCard } from 'models/competency-dna/site-skill-card';

const controllerName = 'SkillCard';

const loadSkillCardsActions = {
    action: SkillCardActions.LOAD_SKILL_CARDS,
    actionFulfilled: SkillCardActions.LOAD_SKILL_CARDS_FULFILLED,
    actionRejected: SkillCardActions.LOAD_SKILL_CARDS_REJECTED,
} as IActionType;

const loadSkillCardActions = {
    action: SkillCardActions.LOAD_SKILL_CARD,
    actionFulfilled: SkillCardActions.LOAD_SKILL_CARD_FULFILLED,
    actionRejected: SkillCardActions.LOAD_SKILL_CARD_REJECTED,
} as IActionType;

const loadSiteSkillCardsActions = {
    action: SkillCardActions.LOAD_SITE_SKILL_CARDS,
    actionFulfilled: SkillCardActions.LOAD_SITE_SKILL_CARDS_FULFILLED,
    actionRejected: SkillCardActions.LOAD_SITE_SKILL_CARDS_REJECTED,
} as IActionType;

const createSkillCardActions = {
    action: SkillCardActions.CREATE_SKILL_CARD,
    actionFulfilled: SkillCardActions.CREATE_SKILL_CARD_FULFILLED,
    actionRejected: SkillCardActions.CREATE_SKILL_CARD_REJECTED,
} as IActionType;

const updateSkillCardActions = {
    action: SkillCardActions.UPDATE_SKILL_CARD,
    actionFulfilled: SkillCardActions.UPDATE_SKILL_CARD_FULFILLED,
    actionRejected: SkillCardActions.UPDATE_SKILL_CARD_REJECTED,
} as IActionType;

const deleteSkillCardActions = {
    action: SkillCardActions.DELETE_SKILL_CARD,
    actionFulfilled: SkillCardActions.DELETE_SKILL_CARD_FULFILLED,
    actionRejected: SkillCardActions.DELETE_SKILL_CARD_REJECTED,
} as IActionType;

const loadSkillCardsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, loadSkillCardsActions).get<ISkillCard[]>(`/api/${controllerName}`);

const loadSkillCardEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, loadSkillCardActions).get<ISkillCard>(`/api/${controllerName}`);

const loadSiteSkillCardsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, loadSiteSkillCardsActions).getById<ISiteSkillCard[]>(`/api/${controllerName}/cards`);

const createSkillCardEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createSkillCardActions).post<ISkillCard>(`/api/${controllerName}`);

const updateSkillCardEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateSkillCardActions).put<ISkillCard>(`/api/${controllerName}`);

const deleteSkillCardEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, deleteSkillCardActions).deleteById<ISkillCard>(`/api/${controllerName}`);

export const skillCardEpics = combineEpics(
    loadSkillCardsEpic,
    loadSkillCardEpic,
    loadSiteSkillCardsEpic,
    createSkillCardEpic,
    updateSkillCardEpic,
    deleteSkillCardEpic
);