import * as React from 'react';
import './ModalCard.scss';

export interface IProps {
    modalId: string;
    isInterstatial?: boolean;
}

export const ModalCardBody: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    return (
        <section
            id={props.modalId + '-modal-dialog-desc'}
            className={'modal-card-body ' + (props.isInterstatial ? 'SingleSelector-siteContainer' : '')}
        >
            {props.children}
        </section>
    );
};
