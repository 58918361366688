import { createSelector } from 'reselect';
import { getCurrentSiteFeatures } from 'sharedSelectors';
import { IRootState } from 'routes/store';
import {
    checkPermissions,
    checkSiteFeature,
    checkRole,
    checkIfAnySiteFeature,
} from 'utils/permission-utils';
import { SiteFeatures } from 'actions/site-feature';
import INavItem from 'models/nav-item';
import { Privilege } from 'enums/Privilege';
import { IKeyValue } from 'models';
import { getDataCenterNavItems } from './navigationJSON/dataCenterNavigationLinks';
import { getHealthcareNavItems } from './navigationJSON/healthcareNavigationLinks';
import { getAdminNavItems } from './navigationJSON/adminNavigationLinks';
import { OperatingPlatform } from '../../models/site-profile';
import appConfig from 'helpers/config-helper';

export const getSites = createSelector(
    (state: IRootState) => state.app,
    appState => (appState.lookupSites.length > 0 ? appState.lookupSites : [])
);

const getSiteId = createSelector(
    (state: IRootState) => state.app.siteId,
    appState => appState
);

const getTestSite = (state: IRootState) => state.siteProfiles.isTestSite;
const getMenus = (state: IRootState) => state.menuState.menus;
const getPermissions = (state: IRootState) => state.app.permissions;


const getClientNavItems = (
    siteId: string,
    hasMultipleSites: boolean,
    currentSiteFeatures: string[],
    permissions: IKeyValue<string>[]
) => {
    const navItems: INavItem[] = [
        {
            text: 'All Sites Overview',
            link: '/ClientSitesOverview',
            isVisible: hasMultipleSites,
        },
        {
            text: 'Site Dashboard',
            link: '/ClientDashboard',
            isVisible: siteId !== null,
        },
        {
            text: 'Risks Register',
            link: '/RiskRegisterClient',
            isVisible:
                siteId !== null && checkSiteFeature(SiteFeatures.ClientRisks, currentSiteFeatures),
        },
        {
            text: 'Mock Drills',
            link: '/MockDrills/MockDrills',
            isVisible:
                siteId !== null &&
                checkSiteFeature(SiteFeatures.ClientMockDrills, currentSiteFeatures),
        },
        {
            text: 'Lessons learned',
            link: '/MockDrills/LessonsLearned',
            isVisible:
                siteId !== null &&
                checkSiteFeature(SiteFeatures.ClientMockDrills, currentSiteFeatures),
        },
        {
            text: 'Toolbox talks',
            link: '/MockDrills/ToolboxTalks',
            isVisible:
                siteId !== null &&
                checkSiteFeature(SiteFeatures.ClientMockDrills, currentSiteFeatures),
        },
        {
            text: 'Shift Handover',
            link: '/ShiftHandovers',
            isVisible:
                siteId !== null &&
                checkSiteFeature(SiteFeatures.ClientShiftHandover, currentSiteFeatures),
        },
        {
            text: 'Critical Spares',
            link: '/ClientCriticalSystemSpares',
            isVisible:
                siteId !== null &&
                checkPermissions([Privilege.CriticalSystemsView], permissions) &&
                checkSiteFeature(SiteFeatures.ClientFacingCriticalSpares, currentSiteFeatures),
        },
        {
            text: 'Romonet',
            link: '/',
            isVisible:
                checkIfAnySiteFeature(
                    [
                        SiteFeatures.ClientRomonetHomePage,
                        SiteFeatures.ClientRomonetExecutiveDashboard,
                        SiteFeatures.ClientFacilityOperationsSnapshot,
                        SiteFeatures.ClientProjectTrackingDashboard,
                        SiteFeatures.ClientEngineerReport,
                        SiteFeatures.ClientDataReports,
                    ],
                    currentSiteFeatures
                ) && checkPermissions([Privilege.Romonet], permissions),
            children: [
                {
                    text: 'Romonet Home Page',
                    link: '/Romonet/HomePage',
                    isVisible: checkSiteFeature(
                        SiteFeatures.ClientRomonetHomePage,
                        currentSiteFeatures
                    ),
                },
                {
                    text: 'Romonet Executive Dashboard',
                    link: '/Romonet/ExecutiveDashboard',
                    isVisible:
                        checkSiteFeature(
                            SiteFeatures.ClientRomonetExecutiveDashboard,
                            currentSiteFeatures
                        ) && checkPermissions([Privilege.Romonet], permissions),
                },
                {
                    text: 'Facility Operations Snapshot',
                    link: '/Romonet/FacilityOperationsSnapshot',
                    isVisible:
                        checkSiteFeature(
                            SiteFeatures.ClientFacilityOperationsSnapshot,
                            currentSiteFeatures
                        ) && checkPermissions([Privilege.Romonet], permissions),
                },
                {
                    text: 'CBRE Detailed Hall Report',
                    link: '/Romonet/DetailedHallReport',
                    isVisible:
                        checkSiteFeature(
                            SiteFeatures.ClientDetailedHallReport,
                            currentSiteFeatures
                        ) && checkPermissions([Privilege.Romonet], permissions),
                },
                {
                    text: 'Project Tracking Dashboard',
                    link: '/Romonet/ProjectTrackingDashboard',
                    isVisible:
                        checkSiteFeature(
                            SiteFeatures.ClientProjectTrackingDashboard,
                            currentSiteFeatures
                        ) && checkPermissions([Privilege.Romonet], permissions),
                },
                {
                    text: 'Engineer Report',
                    link: '/Romonet/EngineerReport',
                    isVisible:
                        checkSiteFeature(SiteFeatures.ClientEngineerReport, currentSiteFeatures) &&
                        checkPermissions([Privilege.Romonet], permissions),
                },
                {
                    text: 'Data Reports',
                    link: '/Romonet/DataReports',
                    isVisible:
                        checkSiteFeature(SiteFeatures.ClientDataReports, currentSiteFeatures) &&
                        checkPermissions([Privilege.Romonet], permissions),
                },
            ],
        },
        {
            text: 'Quantum Analytics',
            link: '/ClientQuantumAnalytics',
            isNotSingleSiteRelated: true,
            isVisible:
                checkPermissions([Privilege.ReportTableau], permissions) &&
                checkSiteFeature(SiteFeatures.ClientQuantumAnalytics, currentSiteFeatures),
        },
    ];
    return navItems;
};

const getfullNameNavItem = (fullName: string) => {
    const fullNameNavItem: INavItem[] = [
        {
            text: fullName,
            link: '/',
            isVisible: true,
            isMobile: true,
            iconName: 'avatar',
            isUserName: true,
            children: [
                {
                    text: 'Profile',
                    link: '/PersonProfile',
                    isVisible: true,
                    isNotSingleSiteRelated: true,
                },
                {
                    text: 'Sign Out',
                    link: '/AzureADB2C/Account/SignOut',
                    isVisible: true,
                    isHref: true,
                },
            ],
        },
    ];

    return fullNameNavItem;
};

const getOperatingPlatformV1 = (SelectedSiteId: string, lookupSites: any) => {
    let site = lookupSites.find(x => x.key === SelectedSiteId);
    return site ? site.operatingPlatform : '';
};

const getAvailableNavItems = (
    fullName: string,
    permissions: IKeyValue<string>[],
    currentSiteFeatures: string[],
    lookupSites?: IKeyValue<string>[],
    siteId?: string,
    selectedOperatingPlatform?: string
) => {
    let adminNavItems = getAdminNavItems(permissions, selectedOperatingPlatform);
    let fullNameNavItem = getfullNameNavItem(fullName);
    const config = appConfig();
    if (config.REACT_APP_FEATURE_FLAG_DYNAMIC_MENU === 'false' && siteId && lookupSites.length) {
        switch (getOperatingPlatformV1(siteId, lookupSites)) {
            case OperatingPlatform.Healthcare:
                return getHealthcareNavItems(siteId, permissions, currentSiteFeatures).concat(
                    adminNavItems.concat(fullNameNavItem)
                );
            case OperatingPlatform.DataCenter:
                return getDataCenterNavItems(siteId, permissions, currentSiteFeatures).concat(
                    adminNavItems.concat(fullNameNavItem)
                );
            default:
                return [];
        }
    }
    const navMenuItems = adminNavItems.concat(fullNameNavItem);
    return navMenuItems;
};

export const getNavItems = createSelector(
    (state: IRootState) => state.app.fullName,
    (state: IRootState) => state.app.permissions,
    (state: IRootState) => state.app.lookupSites,
    (state: IRootState) => state.app.siteId,
    (state: IRootState) => getCurrentSiteFeatures(state),
    (state: IRootState) => state.person.personProfile.accessRights.operatingPlatforms,
    (state: IRootState) => state.person.personProfile.preferredOperatingPlatform,
    (fullName, permissions, lookupSites, siteId, currentSiteFeatures, selectOperatingPlatform, preferredOperatingPlatform) => {
        if (fullName === null || lookupSites.length < 1) {
            return [];
        }
        // if client then use client menu instead
        if (checkRole('Client', permissions)) {
            const hasMultipleSites = lookupSites && lookupSites.length > 1;
            if (!siteId && lookupSites && lookupSites.length === 1) {
                siteId = lookupSites[0].key;
            }
            return getClientNavItems(siteId, hasMultipleSites, currentSiteFeatures, permissions);
        }
        if (selectOperatingPlatform && selectOperatingPlatform.length > 1) {
            selectOperatingPlatform = selectOperatingPlatform.slice().sort((a, b) => a.localeCompare(b))
        }
        const selectedOperatingPlatform =
            preferredOperatingPlatform ?
                preferredOperatingPlatform :
                selectOperatingPlatform && selectOperatingPlatform.length >= 1 ?
                    selectOperatingPlatform[0] :
                    '';

        return getAvailableNavItems(
            fullName,
            permissions,
            currentSiteFeatures,
            lookupSites,
            siteId,
            selectedOperatingPlatform
        );
    }
);

export default createSelector(
    getNavItems,
    getSites,
    getSiteId,
    getTestSite,
    getMenus,
    getPermissions,
    (navItems, lookupSites, siteId, isTestSite, menus, permissions) => ({
        navItems,
        lookupSites,
        siteId,
        isTestSite,
        menus,
        permissions,
    })
);
