import React from 'react';
import './Nav.scss';
import ReactTooltip from 'react-tooltip';
import { history } from 'routes/App';
import { IPerson } from 'models/person';
import { CustomPopOver } from '../custom-popover/custom-popover';
import useTranslate from 'translations/translation-utils';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconColor, MaterialIconSize, MaterialIconType } from 'routes/material-icon/material-icon-type';

interface IProps {
    person: IPerson
    updateIsShowFaq: (person: IPerson) => void;
}

export const NavHelpModule: React.FC<IProps> = ({ person, updateIsShowFaq }) => {
    const translate = useTranslate();
    const [showTooltip, setShowTooltip] = React.useState(true)
    const showHelpModule = () => {
        history.push('/FAQS');
        updatePersonProfile()
    }
    const updatePersonProfile = () => {
        if (!person.isShowFaqs) {
            const personProfile = { ...person };
            personProfile.isShowFaqs = true;
            updateIsShowFaq(personProfile);
            setShowTooltip(true);
        }
    }
    React.useEffect(() => {

        if (person) {
            setShowTooltip(person.isShowFaqs)
        }
    }, [person])

    const onClose = () => {
        setShowTooltip(true);
        updatePersonProfile()
    }
    return (
        <div>
            <div className={`${!showTooltip ? 'is-active' : ''} modal `}>
                <div className="modal-background" />
            </div>
            <div className='navbar-help'>
                {
                    !showTooltip ?
                        <CustomPopOver onClose={onClose} content={<div>{translate('NavBar.Title.Faqs')} </div>}>
                            <a className='navbar-help-icon' onClick={showHelpModule}>
                                <span className="icon" >

                                    < MaterialIcons type={MaterialIconType.Help} color={MaterialIconColor.white} size={MaterialIconSize.medium} />
                                </span>
                            </a>
                        </CustomPopOver>
                        :
                        <>
                            <a data-tip={translate('NavBar.Title.Tooltip')} className='navbar-help-icon' onClick={showHelpModule}>
                                <span className="icon" >
                                    < MaterialIcons type={MaterialIconType.Help} color={MaterialIconColor.white} size={MaterialIconSize.medium} />
                                </span>
                            </a>

                            <ReactTooltip className='custom-tooltip' place="bottom" />
                        </>
                }
            </div>
        </div >
    );
};
