import classnames from 'classnames';
import * as React from 'react';

import { FilterSet } from '../filterSet';
import { KeywordFilterSet } from '../filterSet/KeywordFilterSet';

export interface IProps<T = any> {
    title: string;
    name: Extract<keyof T, string>;
    values: string[];
    onAddFilterKeyword: (keyword: string) => void;
    onRemoveFilterKeyword: (keyword: string) => void;

    className?: string;
}

export const SearchFilter: React.FC<IProps> = (props) => (
    <div className={classnames(props.className)}>
        <FilterSet title={props.title} fieldName={props.name}>
            <KeywordFilterSet
                keywords={props.values}
                onAddKeyword={props.onAddFilterKeyword}
                onRemoveKeyword={props.onRemoveFilterKeyword}
            />
        </FilterSet>
    </div>
);
