import React from 'react';
import { getAllOpportunityResponse } from 'models/opportunity-question-set';
import { Checkbox } from 'components/form/Checkbox';
import useTranslate from 'translations/translation-utils';

export interface IProps {
    selectedResponse: string[];
    onUpdateResponse?: (response) => void;
}
const OpportunityResponseForm: React.FC<IProps> = ({ selectedResponse, onUpdateResponse }) => {
    const translate = useTranslate();

    return (
        <div className="multiselect-reponse">
            <p>{translate('OpportunityQ.AdviceForm.ResponseText')}</p>
            <div className="multiselect-reponse-body">
                <div className="columns is-multiline">
                    {getAllOpportunityResponse() &&
                        getAllOpportunityResponse().map((response, key) => {
                            return (
                                <>
                                    <div key={key} className="column is-one-quarter">
                                        {/* {response} */}
                                        <Checkbox
                                            id={`main_${response}_${key.toString()}`}
                                            key={key}
                                            checked={selectedResponse.includes(response)}
                                            onChange={() => onUpdateResponse(response)}
                                            label={response}
                                        />
                                    </div>
                                </>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default OpportunityResponseForm;
