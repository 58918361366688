export {};

declare global {
    // tslint:disable-next-line: interface-name
    interface Array<T> {
        countMatches(predicate: (value: T, index: number, obj: T[]) => unknown): number;
    }
}

Array.prototype.countMatches = function<T>(
    predicate: (value: T, index: number, obj: T[]) => unknown
): number {
    if (!this || this.length < 1) {
        return 0;
    }

    return this.reduce((n, val, i) => {
        return n + (predicate(val, i, this) ? 1 : 0);
    }, 0);
};
