import { Action } from 'redux';
import { IPersonLookup } from 'models/person-lookup';
import { IPersonToSite } from 'models/person';

export enum PersonActions {

    UPDATE_PERSON_SITE_ROLES = '@@persons/UPDATE_PERSON_SITE_ROLES',
    UPDATE_PERSON_SITE_ROLES_FULFILLED = '@@persons/UPDATE_PERSON_SITE_ROLES_FULFILLED',
    UPDATE_PERSON_SITE_ROLES_REJECTED = '@@persons/UPDATE_PERSON_SITE_ROLES_REJECTED',
    UPDATE_PERSON_SITE_ROLES_CANCELLED = '@@persons/UPDATE_PERSON_SITE_ROLES_CANCELLED',

    LOAD_PERSON_LOOKUPS = '@@persons/LOAD_PERSON_LOOKUPS',
    LOAD_PERSON_LOOKUPS_FULFILLED = '@@persons/LOAD_PERSON_LOOKUPS_FULFILLED',
    LOAD_PERSON_LOOKUPS_REJECTED = '@@persons/LOAD_PERSON_LOOKUPS_REJECTED',
    LOAD_PERSON_LOOKUPS_CANCELLED = '@@persons/LOAD_PERSON_LOOKUPS_CANCELLED',

    TOGGLE_ITEM_EXPANDED = '@@persons/TOGGLE_ITEM_EXPANDED',
    CHANGE_SORT_ORDER_VALUE = '@@persons/CHANGE_SORT_ORDER_VALUE',
    CHANGE_FILTER_VALUE = '@@persons/CHANGE_FILTER_VALUE',
    SHOW_ACTIONS = '@@persons/SHOW_ACTIONS',
    HIDE_ACTIONS = '@@persons/HIDE_ACTIONS',
    LOAD_PAGE = '@@persons/LOAD_PAGE',
    LOAD_PAGE_FULFILLED = '@@persons/LOAD_PAGE_FULFILLED',
    LOAD_PAGE_REJECTED = '@@persons/LOAD_PAGE_REJECTED',
    LOAD_PAGE_CANCELLED = '@@persons/LOAD_PAGE_CANCELLED'
}

export interface ILoadPageAction extends Action {
    type: PersonActions.LOAD_PAGE;
}

export interface ILoadPageRejectedAction extends Action {
    type: PersonActions.LOAD_PAGE_REJECTED;
    error: true;
    payload: any;
}

export interface ILoadPageCancelledAction extends Action {
    type: PersonActions.LOAD_PAGE_CANCELLED;
}

export interface ILoadPageFulfilledAction extends Action {
    type: PersonActions.LOAD_PAGE_FULFILLED;
    payload: any;
}

export interface ILoadPersonLookUpsAction extends Action {
    type: PersonActions.LOAD_PERSON_LOOKUPS;
}

export const onLoadPersonLookUps = (): ILoadPersonLookUpsAction => ({
    type: PersonActions.LOAD_PERSON_LOOKUPS,
});

export interface IUpdatedPersonSiteRolesAction extends Action {
    type: PersonActions.UPDATE_PERSON_SITE_ROLES;
    payload: {
        siteId: string;
        users: IPersonToSite[];
    };
}

export const onUpdatedPersonSiteRoles = (users: IPersonToSite[], siteId: string): IUpdatedPersonSiteRolesAction => ({
    type: PersonActions.UPDATE_PERSON_SITE_ROLES,
    payload: {
        users,
        siteId
    }
});

export interface ILoadPersonLookUpsRejectedAction extends Action {
    type: PersonActions.LOAD_PERSON_LOOKUPS_REJECTED;
    error: true;
    payload: any;
}

export interface ILoadPersonLookUpsCancelledAction extends Action {
    type: PersonActions.LOAD_PERSON_LOOKUPS_CANCELLED;
}

export const updateStatePersonLookups = (users: IPersonLookup[]): ILoadPersonLookUpsFulfilledAction => ({
    type: PersonActions.LOAD_PERSON_LOOKUPS_FULFILLED,
    payload: {
        persons: users
    }
});

export interface ILoadPersonLookUpsFulfilledAction extends Action {
    type: PersonActions.LOAD_PERSON_LOOKUPS_FULFILLED;
    payload: {
        persons: IPersonLookup[]
    };
}

export interface ISetSortOrderAction extends Action {
    type: PersonActions.CHANGE_SORT_ORDER_VALUE;
    payload: {
        sortColumn?: string;
        sortAscending?: boolean;
    };
}

export interface ISetFilterValueAction extends Action {
    type: PersonActions.CHANGE_FILTER_VALUE;
    payload: {
        field: string;
        value: string;
    };
}

export interface IToggleItemExpanded extends Action {
    type: PersonActions.TOGGLE_ITEM_EXPANDED;
    payload: {
        id: string;
    };
}

export interface IHideActions extends Action {
    type: PersonActions.HIDE_ACTIONS;
    payload: {
        id: string;
    };
}

export interface IShowActions extends Action {
    type: PersonActions.SHOW_ACTIONS;
    payload: {
        id: string;
    };
}

export type PersonActionTypes =
| IUpdatedPersonSiteRolesAction

| ILoadPersonLookUpsAction
| ILoadPersonLookUpsRejectedAction
| ILoadPersonLookUpsCancelledAction
| ILoadPersonLookUpsFulfilledAction

| ISetFilterValueAction
| ISetSortOrderAction
| IToggleItemExpanded
| IHideActions
| IShowActions
| ILoadPageAction
| ILoadPageCancelledAction
| ILoadPageRejectedAction
| ILoadPageFulfilledAction;
