import { Privilege } from 'enums/Privilege';
import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { checkPermission } from 'utils/permission-utils';

const getAttachments = (state: IRootState) => state.riskRegisterState.attachments;
const getPersonLookups = (state: IRootState) => state.lookups.personLookups;
const getHistory = (state: IRootState) => state.riskRegisterState.history;
const getRisk = (state: IRootState) => state.riskRegisterState.risk;
const getRiskActions = (state: IRootState) => state.riskRegisterState.actions;
const getSiteId = (state: IRootState) => state.app.siteId;
const getMacroActions = (state: IRootState) => state.actionRegisterState.macroActions;
const getCanViewMacroRisks = (state: IRootState) =>
    checkPermission(Privilege.RiskMacroLevelUpdate, state.app.permissions);

export default createSelector(
    getAttachments,
    getPersonLookups,
    getHistory,
    getRisk,
    getRiskActions,
    getSiteId,
    getMacroActions,
    getCanViewMacroRisks,
    (
        attachments,
        users,
        riskHistory,
        risk,
        riskActions,
        siteId,
        macroActions,
        canViewMacroRisks
    ) => ({
        attachments,
        users,
        riskHistory,
        risk,
        riskActions,
        siteId,
        macroActions,
        canViewMacroRisks,
    })
);
