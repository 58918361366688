import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { connect } from 'react-redux';
import { OpportunityQuestionnaire } from './opportunity-questionnaire-page';
import { loadOpportunityQuestionsList } from 'actions/actions-v2/opportunity-action';

const getLoading = (state: IRootState) => state.opportunityQuestionsState.isLoading;
const getquestionsSet = (state: IRootState) => state.opportunityState.opportunityQuestionsList;
const getSiteId = (state: IRootState) => state.app.siteId;

const mapStateToProps = createSelector(
    getLoading,
    getquestionsSet,
    getSiteId,
    (isLoading, opportunityQuestions, siteId) => ({
        isLoading,
        opportunityQuestions,
        siteId,
    })
);

const mapDispatchToProps = {
    loadOpportunityQuestions: loadOpportunityQuestionsList,
};

export const OpportunityQuestionnaireContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(OpportunityQuestionnaire);
