import * as React from 'react';
import classnames from 'classnames';
import { uniqueId } from 'lodash';
import './grid-content-expander.scss';
import { Button } from '../v2/components';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconType } from 'routes/material-icon/material-icon-type';

export interface IProps extends React.AllHTMLAttributes<HTMLDivElement> {
    buttonText: string;
    isLoading: boolean;
    isExpanded: boolean;

    onOpen: () => void;
    onClose: () => void;
}

const getIcon = (isExpanded: boolean) => {
    return isExpanded
        ? < MaterialIcons type={MaterialIconType.Minus} />
        : < MaterialIcons type={MaterialIconType.Plus} />;
};

const getButtonText = (props: IProps) => {
    return (
        <>
            {props.buttonText}
            {getIcon(props.isExpanded)}
        </>
    );
};

export const GridContentExpander: React.FC<IProps> = (props) => {
    const id = uniqueId('GridContentExpander');
    const { buttonText, isLoading, isExpanded, onOpen, onClose, className, ...divProps } = props;

    const onClick: React.MouseEventHandler<HTMLButtonElement> = () => {
        if (props.isExpanded) {
            props.onClose();
        } else {
            props.onOpen();
        }
    };

    const css = classnames(
        'GridContentExpander',
        {
            'GridContentExpander--expanded': !isLoading && isExpanded
        },
        className
    );

    return (
        <div {...divProps} className={css}>

            <Button
                className="GridContentExpander__button"
                onClick={onClick}
                disabled={props.isLoading}
                aria-expanded={props.isExpanded}
                aria-controls={id}
                isLoading={props.isLoading}
            >
                {getButtonText(props)}
            </Button>

            {props.isExpanded && !props.isLoading && (
                <div id={id} aria-live="assertive">
                    {props.children}
                </div>
            )}
        </div>
    );
};
