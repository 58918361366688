import React from 'react';
import Page from 'components/v2/page/Page';
import * as Yup from 'yup';
import {
    Panel,
    Table,
    SortOrder,
    Button,
    Toolbar,
    ToolbarGroup,
    ToolbarGroupRight,
    FloatingButton,
    ConfirmDialog,
    TableHeaders
} from 'components/v2/components';
import {
    IMockDrill,
    ICreateMockDrill,
    IUpdateMockDrill,
    mockDrillScenarioType,
    getScenarioTypeStringFromParam,
} from 'models/mock-drills';
import { FormikProps, Formik, FormikActions } from 'formik';
import { TextField } from 'components/form/fields/text-field';
import { TextAreaField } from 'components/form/fields/textarea-field';
import { SelectField, IOption } from 'components/form/fields/select-field';
import { IKeyValue } from 'models';
import { Loader } from 'components/loader';
import { Checkbox } from 'components/form/Checkbox';
import { RouteComponentProps } from 'react-router-dom';
import { history } from 'routes/App';
import { UiPreferences } from 'utils/page-ui-preferences-utils';
import { sortArrayBy } from 'utils/sort-utils';
import { isEmpty } from 'lodash';
import { getRegions } from 'enums/Regions';
import { ISiteInfo } from 'models/site-profile';
import { useTranslate } from 'translations/translation-utils';
import { ICommonLookupModel } from 'models/common-lookup-model';
import Asset from 'components/asset/asset';
import { IAssetModel } from 'models/asset/asset-model';
import appConfig from 'helpers/config-helper';
import { IPerson } from 'models/person';
import { getUserAccessOperatingPlatform } from 'helpers/helpers';

interface IRouteParams {
    id: string;
    scenarioType: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
    isLoading: boolean;
    isSaving: boolean;
    isLocked: boolean;
    siteId: string;
    clients: Array<IKeyValue<string>>;
    availableSites: ISiteInfo[];
    mockDrill: IMockDrill;
    loadMockDrill: (id: string, siteId: string) => void;
    createMockDrill: (model: ICreateMockDrill, reloadUrl: string) => void;
    updateMockDrill: (model: IUpdateMockDrill, reloadUrl: string) => void;
    cleanupMockDrill: () => void;
    lineOfBusiness: ICommonLookupModel[];
    personProfile: IPerson;
}

export const MockDrillUpsertPage: React.FC<IProps> = ({
    match,
    isLoading,
    isSaving,
    isLocked,
    siteId,
    clients,
    availableSites,
    mockDrill,
    loadMockDrill,
    createMockDrill,
    updateMockDrill,
    cleanupMockDrill,
    lineOfBusiness,
    personProfile,
}) => {
    const mockDrillId = match.params.id;
    const config = appConfig();
    const scenarioType = match.params.scenarioType;
    const scenarioTypeString = getScenarioTypeStringFromParam(scenarioType);
    const mockDrillsPageUrl = `/MockDrills/${scenarioType}`;
    const customLinks: Array<IKeyValue<string>> = [
        { key: mockDrillsPageUrl, value: scenarioTypeString },
    ];
    // dropdown boxes
    const [selectedLineOfBusiness, setSelectedLineOfBusiness] = React.useState(null);
    const [selectedRegions, setSelectedRegions] = React.useState(null);
    const [selectedClient, setSelectedClient] = React.useState(null);
    const [sortedSites, setSortedSites] = React.useState(availableSites);
    // checkboxes
    const [sharedToOtherSites, setSharedToOtherSites] = React.useState<boolean>(false);
    const [selectedSites, setSelectedSites] = React.useState([]);
    const [selectAllSites, setSelectAllSites] = React.useState(false);

    const [shouldBlockNavigation, setShouldBlockNavigation] = React.useState(false);
    const [dialogIsVisible, setDialogIsVisible] = React.useState(false);
    const [submitPayload, setSubmitPayload] = React.useState(null);
    const [assetPayload, setAssetPayload] = React.useState<IAssetModel>();
    const [selectedOperatingPlatform, setSelectedOperatingPlatform] = React.useState(null);

    //const linesOfBusiness = getLineOfBusinesses;
    const regions = getRegions;

    const translate = useTranslate();

    const getAvailableSites = (availableSites: any) => {
        return availableSites
    }

    const handleOperatingPlatformChange = (value: IOption[]) => {
        setSelectedOperatingPlatform(value && value.length === 0 ? null : value);
    };

    const operatingPlatformSelected = selectedOperatingPlatform ? selectedOperatingPlatform.value : getUserAccessOperatingPlatform(personProfile);

    React.useEffect(() => {
        return () => {
            cleanupMockDrill();
        };
    }, []);

    React.useEffect(() => {
        if (mockDrillId && siteId && mockDrill === null) {
            loadMockDrill(mockDrillId, siteId);
        }
    }, [siteId]);

    React.useEffect(() => {
        if (!siteId || !availableSites) {
            return;
        }
        setSortedSites(getAvailableSites(availableSites).filter((site) => site.key !== siteId));
    }, [siteId, availableSites]);

    React.useEffect(() => {
        if (!mockDrill) {
            return;
        }

        setSelectedSites([...mockDrill.sites, mockDrill.siteId]);
        setSharedToOtherSites(mockDrill.sites.length > 1);
    }, [mockDrill]);

    const validationSchema = Yup.object<Partial<IMockDrill>>().shape({
        name: Yup.string().trim().required(translate('Validations.ScenarioTitle')),
    });

    const renderForm = (formikProps: FormikProps<Partial<IMockDrill>>) => {
        if (formikProps.dirty && !formikProps.isSubmitting) {
            setShouldBlockNavigation(true);
        }

        const handleScenarioTypeChange = (field: { value: string; label: string }) => {
            formikProps.setFieldValue('scenarioType', field.value);
        };

        return formikProps.values ? (
            <form id="mockDrillForm" onSubmit={formikProps.handleSubmit}>
                <SelectField
                    id="scenarioType"
                    label={translate('MockDrills.LocalUpsertPage.Labels.ScenarioType')}
                    error={formikProps.errors.scenarioType}
                    value={{
                        label: formikProps.values.scenarioType ?? scenarioTypeString,
                        value: formikProps.values.scenarioType ?? scenarioTypeString,
                    }}
                    options={mockDrillScenarioType.map((c) => ({
                        label: c.label,
                        value: translate(
                            'MockDrills.ScenarioType.'.concat(c.value.replace(/\s/g, ''))
                        ),
                    }))}
                    handleChange={handleScenarioTypeChange}
                    handleBlur={formikProps.handleBlur}
                />
                <TextField
                    id="name"
                    label={translate('GlobalMockDrills.ScenarioTitle') + '*'}
                    placeholder={translate('GlobalMockDrills.ScenarioDes.2') + '...'}
                    value={formikProps.values.name}
                    handleChange={formikProps.handleChange}
                    error={formikProps.errors.name}
                    setVal={formikProps.setFieldValue}
                />
                <TextAreaField
                    id="description"
                    label={translate('GlobalMockDrills.ScenarioDes')}
                    placeholder={translate('GlobalMockDrills.ScenarioDes.3') + '...'}
                    value={formikProps.values.description}
                    handleChange={formikProps.handleChange}
                    error={formikProps.errors.description}
                    setVal={formikProps.setFieldValue}
                />
            </form>
        ) : null;
    };

    const onSubmit = (data: IMockDrill) => {
        const filteredArray = data?.sites?.filter(el=> el !== null );

        const updatedSelectedSites = ((filteredArray && filteredArray.length > 0)
            && (selectedSites && selectedSites.length > 0)) ?
            selectedSites.filter(site => !filteredArray.includes(site)) :
            selectedSites;

        const sitesToAdd = ((filteredArray && filteredArray.length > 0) &&
            (updatedSelectedSites && updatedSelectedSites.length > 0)) ?
            updatedSelectedSites.filter(site => site !== null && !filteredArray.includes(site)) :
            updatedSelectedSites;

        const sitesToRemove = (mockDrill && mockDrill.sites && mockDrill.sites.length > 0) ?
            mockDrill.sites.filter(site => !site.includes(siteId) && !selectedSites.includes(site)) :
            [];

        if (data?.id) {
            const payload = {
                siteId,
                id: data.id,
                scenarioType: data.scenarioType,
                name: data.name,
                description: data.description,
                sitesToAdd,
                sitesToRemove,
                asset: assetPayload
            };
            setSubmitPayload(payload);
        } else {
            const payload = {
                siteId,
                scenarioType: data.scenarioType ?? scenarioTypeString,
                name: data.name,
                description: data.description,
                sitesToAdd: [siteId, ...sitesToAdd],
                sitesToRemove: [],
                asset: assetPayload
            };
            setSubmitPayload(payload);
        }

        setDialogIsVisible(true);
    };

    const onConfirmButtonHandler = () => {
        setShouldBlockNavigation(false);

        if (!submitPayload) {
            return;
        }

        if (submitPayload.id) {
            UpdateMockDrill(submitPayload);
            return;
        }

        CreateMockDrill(submitPayload);
    };

    const CreateMockDrill = (data) => {
        const payload = {
            siteId,
            scenarioType: data.scenarioType ?? mockDrillScenarioType[0].value,
            name: data.name,
            description: data.description,
            sitesToAdd: data.sitesToAdd,
            sitesToRemove: [],
            asset: data.asset
        } as ICreateMockDrill;
        createMockDrill(payload, `/MockDrills/${scenarioType}`);
    };

    const UpdateMockDrill = (data) => {
        const payload = {
            id: data.id,
            siteId,
            scenarioType: data.scenarioType,
            name: data.name,
            description: data.description,
            sitesToAdd: data.sitesToAdd,
            sitesToRemove: data.sitesToRemove,
            asset: data.asset
        } as IUpdateMockDrill;
        updateMockDrill(payload, `/MockDrills/${scenarioType}`);
    };

    const onCancelButtonHandler = () => {
        history.push(mockDrillsPageUrl);
    };

    const onSharedToOtherSitesCheckboxHandler = (event: React.FormEvent<HTMLInputElement>) => {
        const checked = event.currentTarget.checked;
        setSharedToOtherSites(checked);
        if (!checked) {
            setSelectAllSites(false);
            setSelectedSites([siteId]);
        }
    };

    const handleLineOfBusinessChange = (value: IOption[]) => {
        setSelectedLineOfBusiness(value && value.length === 0 ? null : value);
    };

    const handleRegionChange = (value: IOption[]) => {
        setSelectedRegions(value && value.length === 0 ? null : value);
    };

    const handleClientChange = (value: IOption[]) => {
        setSelectedClient(value && value.length === 0 ? null : value);
    };

    const onToggleHandler = (field: string, sortOrder: SortOrder) => {
        if (sortOrder === SortOrder.off) {
            setSortedSites(availableSites);
        }

        setSortedSites(sortArrayBy(field, availableSites, sortOrder === SortOrder.asc));
    };

    const onCheckboxClickHandler = (value) => {
        const id = value.target.id;
        if (selectedSites.includes(id)) {
            setSelectedSites(selectedSites.filter((x) => x !== id));
        } else {
            setSelectedSites([...selectedSites, id]);
        }
        setShouldBlockNavigation(true);
    };

    const onSelectAllCheckboxClickHandler = () => {
        if (selectAllSites) {
            // if previous state is true - reset the list
            setSelectedSites([]);
        } else {
            setSelectedSites(getFilteredSitesArray(sortedSites).map((x) => x.key));
        }
        setSelectAllSites(!selectAllSites);
        setShouldBlockNavigation(true);
    };

    const getFilteredSitesArray = (sitesArray: ISiteInfo[]) => {
        let filteredArray = [...sitesArray];

        if (selectedOperatingPlatform) {
            filteredArray = filteredArray.filter((x) =>
                x.operatingPlatform === selectedOperatingPlatform.value);
        }
        if (selectedLineOfBusiness) {
            filteredArray = filteredArray.filter((x) =>
                selectedLineOfBusiness.some((i) => i.value === x.lineOfBusiness)
            );
        }
        if (selectedRegions) {
            filteredArray = filteredArray.filter((x) =>
                selectedRegions.some((i) => i.value === x.region)
            );
        }

        if (selectedClient) {
            filteredArray = filteredArray.filter((x) =>
                selectedClient.some((i) => i.value === x.clientName)
            );
        }

        return filteredArray;
    };

    const buildSitesTable = () => {
        if (isEmpty(sortedSites)) {
            return (
                <div className="no-data">
                    <h2>{translate('SiteOverview.Lable.2')}</h2>
                </div>
            );
        }

        return (
            <Table>
                <TableHeaders
                    headers={[
                        {
                            title: translate('Cerm.SiteSelect.Cloumns.SiteName'),
                            sortField: 'value',
                        },
                        {
                            title: translate('UserSelector.Labels.OperatingPlatform'),
                            sortField: 'value',
                        },
                        {
                            title: 'LOB',
                            sortField: 'lineOfBusiness',
                        },
                        {
                            title: translate('GainAccess.Labels.Regions'),
                            sortField: 'region',
                        },
                        {
                            title: translate('LinkedBuildings.Country'),
                            sortField: 'country',
                        },
                        {
                            title: translate('Cerm.SiteSelect.Cloumns.Include'),
                        }
                    ]}
                    sortFieldUiPreference={UiPreferences.GlobalMockDrillPageSiteListSortField}
                    sortOrderUiPreference={UiPreferences.GlobalMockDrillPageSiteListSortOrder}
                    onToggle={onToggleHandler}
                />
                <tbody>
                    <tr>
                        <th colSpan={5} className="includeRow">
                            {translate('Cerm.SiteSelect.Cloumns.IncludeAll')}
                        </th>
                        <th className="includeRowCheckbox">
                            <Checkbox
                                id="selectAllCheckbox"
                                checked={selectAllSites}
                                onChange={onSelectAllCheckboxClickHandler}
                            />
                        </th>
                    </tr>
                    {getFilteredSitesArray(sortedSites).map((item: ISiteInfo) => (
                        <tr key={item.key}>
                            <td>{item.value}</td>
                            <td>{item.operatingPlatform}</td>
                            <td>{item.lineOfBusiness}</td>
                            <td>{item.region}</td>
                            <td>{item.country}</td>
                            <td>
                                <Checkbox
                                    id={item.key}
                                    checked={
                                        selectedSites.includes(item.key) // || item.key === mockDrill.siteId
                                    }
                                    onChange={onCheckboxClickHandler}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    };

    const getDialogMessage = (
        <p>
            {translate('MockDrill.Repost.Lable.9')}
            <br />
            {translate('MockDrill.Repost.Lable.10')}
        </p>
    );

    const shouldDisplayForm = (mockDrillId && mockDrill) || !mockDrillId;

    const buttonText = mockDrillId ? translate('Globals.Label.Save') : translate('IncidentPage.Button.Create');

    const getLinesOfBusiness = () => {
        if ((selectedOperatingPlatform === null)) {
            return lineOfBusiness.filter(x => x.operatingPlatform === getUserAccessOperatingPlatform(personProfile)[0]);
        }
        return lineOfBusiness.filter(x => x.operatingPlatform === selectedOperatingPlatform.value);
    }

    const setAssetFieldValues = (values: IAssetModel) => {
        setAssetPayload((prevAssetPayload) => ({ ...prevAssetPayload, ...values, }));
    }

    return (
        <Page
            title={`${mockDrillId ? translate('ShiftHandoversAdminPage.label.Edit') : translate('FacilityPopup.Button.Add')} ${translate('MockDrill.Repost.Lable.11')}`}
            className="mock-drills-page"
            isAdminPage={true}
            redirectOnSiteChange={true}
            redirectOnSiteChangeUrl={mockDrillsPageUrl}
            breadcrumbCustomLinks={customLinks}
            showConfirmNavigateAwayDialog={shouldBlockNavigation}
        >
            {isLocked ? (
                <p>
                    {translate('GlobalMockDrills.Title.4')}
                </p>
            ) : (
                shouldDisplayForm && (
                    <>
                        <Loader loading={isLoading}>
                            <Panel title={translate('GlobalMockDrills.ScenarioDetails')} bodyPadding={true}>
                                <Formik
                                    initialValues={mockDrill}
                                    validationSchema={validationSchema}
                                    onSubmit={(
                                        data: IMockDrill,
                                        formikActions: FormikActions<IMockDrill>
                                    ) => {
                                        formikActions.setSubmitting(true);
                                        onSubmit(data);
                                    }}
                                    render={renderForm}
                                    enableReinitialize={true}
                                />
                                <div className="checkbox-shared-to-sites">
                                    <Checkbox
                                        id="SharedToOtherSites"
                                        label={translate('GlobalMockDrills.Share')}
                                        checked={sharedToOtherSites}
                                        onChange={onSharedToOtherSitesCheckboxHandler}
                                    />
                                </div>
                                {config.REACT_APP_FEATURE_FLAG_ASSET_INTEGRATION === 'true' && (
                                    <div className="column column is-12">
                                        <Asset
                                            showLabel={true}
                                            isManadatory={false}
                                            setValue={setAssetFieldValues}
                                            isValid={() => { }}
                                            selectedAsset={{
                                                siteId: mockDrill?.asset?.siteId,
                                                manufacturer: mockDrill?.asset?.manufacturer,
                                                model: mockDrill?.asset?.model,
                                                serial: mockDrill?.asset?.serial,
                                                cmmsReference: mockDrill?.asset?.cmmsReference,
                                                name: mockDrill?.asset?.name,
                                                age: mockDrill?.asset?.age,
                                            }}
                                            isDisabled={false}
                                        />
                                    </div >
                                )}
                            </Panel>
                            {sharedToOtherSites && (
                                <Panel
                                    title={translate('GlobalMockDrills.Title.1')}
                                    bodyPadding={true}
                                    collapsed={false}
                                >
                                    <div className="columns">
                                        {personProfile.accessRights.operatingPlatforms.length > 1 ?
                                            <div className="column">
                                                <SelectField
                                                    isMulti={false}
                                                    id="operatingPlatformId"
                                                    label={translate('UserSelector.Labels.OperatingPlatform')}
                                                    value={selectedOperatingPlatform}
                                                    options={personProfile.accessRights &&
                                                        personProfile.accessRights.operatingPlatforms &&
                                                        personProfile.accessRights.operatingPlatforms.map((c) => ({
                                                            value: c,
                                                            label: c,
                                                        })).sort((a, b) => a.label.localeCompare(b.label))}
                                                    handleChange={handleOperatingPlatformChange}
                                                />
                                            </div> :
                                            <div className="column">
                                                <div className="field">
                                                    <label className="label">{translate('UserSelector.Labels.OperatingPlatform')}</label>
                                                    <div className="control">{operatingPlatformSelected}</div>
                                                </div>
                                            </div>}
                                        <div className="column">
                                            <SelectField
                                                isMulti={true}
                                                id="lineOfBusinessId"
                                                label={translate('Cerm.GlobalOverviewFilters.Labels.LineOfBusiness')}
                                                value={selectedLineOfBusiness}
                                                options={getLinesOfBusiness().map((c) => ({
                                                    value: c.value,
                                                    label: c.value,
                                                }))}
                                                handleChange={handleLineOfBusinessChange}
                                            />
                                        </div>
                                        <div className="column">
                                            <SelectField
                                                isMulti={true}
                                                id="regionId"
                                                label={translate('Cerm.GlobalOverviewFilters.Labels.Regions')}
                                                value={selectedRegions}
                                                options={regions.map((c) => ({
                                                    value: c.key,
                                                    label: c.value,
                                                }))}
                                                handleChange={handleRegionChange}
                                            />
                                        </div>
                                        <div className="column">
                                            <SelectField
                                                isMulti={true}
                                                id="clientId"
                                                label={translate('Globals.Category.dropdown.Clients')}
                                                value={selectedClient}
                                                options={clients.map((c) => ({
                                                    value: c.key,
                                                    label: c.value,
                                                }))}
                                                handleChange={handleClientChange}
                                            />
                                        </div>
                                    </div>
                                    {buildSitesTable()}
                                </Panel>
                            )}
                        </Loader>
                        <Toolbar type="save">
                            <ToolbarGroup>
                                <Button buttonType="cancel" onClick={onCancelButtonHandler}>
                                    {translate('Globals.Label.Cancel')}
                                </Button>
                            </ToolbarGroup>
                            <ToolbarGroupRight>
                                <FloatingButton
                                    type="submit"
                                    form="mockDrillForm"
                                    float={shouldBlockNavigation}
                                    tooltip={translate('CompetencyDnaProcess.Label.15')}
                                    isLoading={isLoading || isSaving}
                                    disabled={isSaving}
                                >
                                    {buttonText}
                                </FloatingButton>
                            </ToolbarGroupRight>
                        </Toolbar>
                        <ConfirmDialog
                            title={translate('CermAssesmentTab.Title.Information')}
                            message={getDialogMessage}
                            onConfirm={onConfirmButtonHandler}
                            onClose={() => setDialogIsVisible(false)}
                            onOutsideDialogClick={() => setDialogIsVisible(false)}
                            isVisible={dialogIsVisible}
                            isLoading={isSaving}
                        />
                    </>
                )
            )}
        </Page>
    );
};
