import { Epic, ofType } from 'redux-observable';
import { debounceTime, filter, map } from 'rxjs/operators';

import {
    IChangeSearchValueAction,
    ILoadSearchResultsAction,
    SiteRelationshipsActionTypes
} from '../../actions/site-relationships-actions';

const keypressDebounce = 250;

export function getChangeSearchValueEpic<
    TChange extends IChangeSearchValueAction,
    TLoader extends ILoadSearchResultsAction
>(change: TChange['type'], loader: TLoader['type']): Epic<SiteRelationshipsActionTypes> {
    return (action$) =>
        action$.pipe(
            ofType(change),
            filter<TChange>((action) => Boolean(action.payload.value)),
            debounceTime(keypressDebounce),
            map<TChange, SiteRelationshipsActionTypes>((action) => ({
                type: loader,
                payload: {
                    searchString: action.payload.value
                }
            }))
        );
}
