import * as React from 'react';
import { FileUploadModal } from '../fileUploadModal/file-upload-modal';
import { IAttachmentUpload } from 'models/attachment/attachment-upload';
import useTranslate from 'translations/translation-utils';

interface IProps {
    id: string;
    entity: string;
    siteId: string;
    isVisible: boolean;
    isUploading: boolean;
    isImage?: boolean;
    onConfirm: (upload: IAttachmentUpload) => void;
    hideModal: () => void;
}

export const AttachmentModal: React.FC<IProps> = ({
    id,
    entity,
    siteId,
    ...props
}) => {
    const translate = useTranslate();
    if (!props.isVisible) {
        return null;
    }

    const onConfirm = (files: File[]) =>
        props.onConfirm({
            id,
            entity,
            siteId,
            files
        });

    return (
        <FileUploadModal
            visible={props.isVisible}
            uploading={props.isUploading}
            onConfirm={onConfirm}
            onClose={props.hideModal}
            aloudFiles={props.isImage == true ? translate('Attachments.UploadImageFile.Label.RecommendedFile') : undefined}
        />
    );
};
