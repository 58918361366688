import { combineReducers } from 'redux';

import { SiteRolesActions as Actions } from '../../actions/actions-v2/site-roles-actions-v2';
import { IAppAction } from 'actions/app-action';
import { IOption } from 'components/form/fields/select-field';

export interface ISiteRolesState {
    siteRoles: IOption[];
}

export const INITIAL_STATE: ISiteRolesState = {
    siteRoles: [],
};

const siteRoles = (
    state: ISiteRolesState['siteRoles'] = INITIAL_STATE.siteRoles,
    action: IAppAction
) => {
    switch (action.type) {
        case Actions.LOAD_SITEROLES:
            return INITIAL_STATE.siteRoles;

        case Actions.LOAD_SITEROLES_FULFILLED:
            return action.payload;

        default:
            return state;
    }
};

export const siteRoleReducer = combineReducers<ISiteRolesState>({
    siteRoles,
});
