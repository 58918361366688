import React from 'react';
import parse from 'html-react-parser';
import { IFaqs } from "models/faqs-model";
import _ from 'lodash';
import { useTranslate } from 'translations/translation-utils';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconType } from 'routes/material-icon/material-icon-type';

interface Iprops {
    language: string;
    faqs: IFaqs;
    isReorder: boolean;
    setIsReOrder: (isReorder: boolean) => void;
    setFaqs: (model: IFaqs) => void;

}

export const FaqsReadonly: React.FC<Iprops> = ({ faqs, setFaqs, ...props }) => {
    const translate = useTranslate()
    const onDeleteFaqs = (index, parentIndex) => {
        let updatedFaqs = _.cloneDeep(faqs);
        updatedFaqs.sections[parentIndex].questionAnswers.splice(index, 1);
        setFaqs(updatedFaqs)
    }
    const onMoveFaqUp = (index, parentIndex) => {
        let updatedFaqs = _.cloneDeep(faqs);
        const questionAnswers = updatedFaqs.sections[parentIndex].questionAnswers;
        if (index > 0) {
            [questionAnswers[index], questionAnswers[index - 1]] = [
                questionAnswers[index - 1],
                questionAnswers[index],
            ];
        }
        setFaqs(updatedFaqs)

    }
    const onMoveFaqDown = (index, parentIndex) => {
        let updatedFaqs = _.cloneDeep(faqs);
        const questionAnswers = updatedFaqs.sections[parentIndex].questionAnswers;
        if (index < questionAnswers.length - 1) {
            [questionAnswers[index], questionAnswers[index + 1]] = [
                questionAnswers[index + 1],
                questionAnswers[index],
            ];
        }
        setFaqs(updatedFaqs)
    }
    return (
        <>
            {
                faqs && faqs.sections?.map((section, parentIndex) => (
                    <section>
                        <div className="tabs-container ">
                            <h2 className="faqs-section-header">{parse(section?.name)}</h2>
                            {section.questionAnswers?.map((faq, index) =>
                            (
                                <>
                                    <div className="faq-tabs" key={`faq-tabs-${parentIndex}-${index}`}>
                                        <div className="tab" key={`tab-${parentIndex}-${index}`}>
                                            <input className="tab-checkbox" type="checkbox" id={`chck-${parentIndex}-${index}`} />
                                            <label className="tab-label" htmlFor={`chck-${parentIndex}-${index}`} > {parse(faq?.question)}   </label>

                                            <div className="tab-content" key={`tab-content-${parentIndex}-${index}`}>
                                                {faq?.answer && parse(faq?.answer)}
                                            </div>
                                        </div>
                                    </div>
                                    {props.isReorder && <div className="faq-tabs-icon">
                                        <span
                                            className="is-pulled-right"
                                            onClick={() =>
                                                onDeleteFaqs(index, parentIndex)
                                            }
                                            data-tip={translate('ShiftHandoversAdminPage.label.Delete')}
                                        >
                                            < MaterialIcons type={MaterialIconType.Delete} />
                                        </span>

                                        <span className="is-pulled-right"
                                            data-tip={translate('ShiftHandoversAdminPage.label.MoveUp')}
                                        >
                                            <a
                                                id={'moveUpQuestionSet'}
                                                onClick={() =>
                                                    onMoveFaqUp(index, parentIndex)
                                                }
                                                className={`${index === 0 ? 'hidden-link' : ''
                                                    }`}
                                            >
                                                < MaterialIcons type={MaterialIconType.ArrowUp} />

                                            </a>
                                        </span>
                                        <span className="is-pulled-right"
                                            data-tip={translate('ShiftHandoversAdminPage.label.MoveDown')}
                                        >
                                            <a
                                                id={'moveDownQuestionSet'}
                                                onClick={() =>
                                                    onMoveFaqDown(index, parentIndex)
                                                }
                                                className={`${index ===
                                                    section.questionAnswers.length -
                                                    1
                                                    ? 'hidden-link'
                                                    : ''
                                                    }`}
                                            >
                                                < MaterialIcons type={MaterialIconType.ArrowDown} />
                                            </a>
                                        </span>
                                    </div>}

                                </>
                            )
                            )}
                        </div>
                    </section>
                ))
            }
        </>
    );
};
