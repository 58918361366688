import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { IRisk } from 'models/risk';
import { IRiskApproval } from 'models/risk-approval';
import { RiskActions } from 'actions/actions-v2/risk-action-v2';
import { IAction } from 'models/action';
import { IEntitySite } from 'models/entity-site';

const controllerName = 'risk';
const apiPath = `/api/${controllerName}`;
const riskRegisterPageUrl = '/Risks';

const createRisks = {
    action: RiskActions.CREATE_RISK,
    actionFulfilled: RiskActions.CREATE_RISK_FULFILLED,
    actionRejected: RiskActions.CREATE_RISK_REJECTED,
} as IActionType;

const createRiskMitigationPlan = {
    action: RiskActions.CREATE_RISK_MITIGATION_PLAN,
    actionFulfilled: RiskActions.CREATE_RISK_MITIGATION_PLAN_FULFILLED,
    actionRejected: RiskActions.CREATE_RISK_MITIGATION_PLAN_REJECTED,
} as IActionType;

const acceptRisk = {
    action: RiskActions.ACCEPT_RISK,
    actionFulfilled: RiskActions.ACCEPT_RISK_FULFILLED,
    actionRejected: RiskActions.ACCEPT_RISK_REJECTED,
} as IActionType;

const updateRisks = {
    action: RiskActions.UPDATE_RISK,
    actionFulfilled: RiskActions.UPDATE_RISK_FULFILLED,
    actionRejected: RiskActions.UPDATE_RISK_REJECTED,
} as IActionType;

const createRiskForIncident = {
    action: RiskActions.CREATE_RISK_FOR_INCIDENT,
    actionFulfilled: RiskActions.CREATE_RISK_FOR_INCIDENT_FULFILLED,
    actionRejected: RiskActions.CREATE_RISK_FOR_INCIDENT_REJECTED,
} as IActionType;

const updateRiskForIncident = {
    action: RiskActions.UPDATE_RISK_FOR_INCIDENT,
    actionFulfilled: RiskActions.UPDATE_RISK_FOR_INCIDENT_FULFILLED,
    actionRejected: RiskActions.UPDATE_RISK_FOR_INCIDENT_REJECTED,
} as IActionType;

const getAllRisks = {
    action: RiskActions.LOAD_RISKS,
    actionFulfilled: RiskActions.LOAD_RISKS_FULFILLED,
    actionRejected: RiskActions.LOAD_RISKS_REJECTED,
} as IActionType;

const getRisks = {
    action: RiskActions.LOAD_RISK,
    actionFulfilled: RiskActions.LOAD_RISK_FULFILLED,
    actionRejected: RiskActions.LOAD_RISK_REJECTED,
} as IActionType;

const loadHistory = {
    action: RiskActions.LOAD_HISTORY,
    actionFulfilled: RiskActions.LOAD_HISTORY_FULFILLED,
    actionRejected: RiskActions.LOAD_HISTORY_REJECTED,
} as IActionType;

const deleteRisk = {
    action: RiskActions.DELETE_RISK,
    actionFulfilled: RiskActions.DELETE_RISK_FULFILLED,
    actionRejected: RiskActions.DELETE_RISK_REJECTED,
} as IActionType;

const closedRisk = {
    action: RiskActions.CLOSE_RISK,
    actionFulfilled: RiskActions.CLOSE_RISK_FULFILLED,
    actionRejected: RiskActions.CLOSE_RISK_REJECTED,
} as IActionType;

const openClosedRisk = {
    action: RiskActions.OPEN_CLOSED_RISK,
    actionFulfilled: RiskActions.OPEN_CLOSED_RISK_FULFILLED,
    actionRejected: RiskActions.OPEN_CLOSED_RISK_REJECTED,
} as IActionType;

const CbreApprovalRisk = {
    action: RiskActions.CBRE_APPROVAL_RISK,
    actionFulfilled: RiskActions.CBRE_APPROVAL_RISK_FULFILLED,
    actionRejected: RiskActions.CBRE_APPROVAL_RISK_REJECTED,
} as IActionType;

const ClientApprovalRisk = {
    action: RiskActions.CLIENT_APPROVAL_RISK,
    actionFulfilled: RiskActions.CLIENT_APPROVAL_RISK_FULFILLED,
    actionRejected: RiskActions.CLIENT_APPROVAL_RISK_REJECTED,
} as IActionType;

const clientRequestAmendments = {
    action: RiskActions.CLIENT_REQUEST_AMENDMENT,
    actionFulfilled: RiskActions.CLIENT_REQUEST_AMENDMENT_FULFILLED,
    actionRejected: RiskActions.CLIENT_REQUEST_AMENDMENT_REJECTED,
} as IActionType;

const loadRiskActions = {
    action: RiskActions.LOAD_RISK_ACTIONS,
    actionFulfilled: RiskActions.LOAD_RISK_ACTIONS_FULFILLED,
    actionRejected: RiskActions.LOAD_RISK_ACTIONS_REJECTED,
} as IActionType;

const exportActions = {
    action: RiskActions.EXPORT_RISKS,
    actionFulfilled: RiskActions.EXPORT_RISKS_FULFILLED,
    actionRejected: RiskActions.EXPORT_RISKS_REJECTED,
} as IActionType;

const exportAllActions = {
    action: RiskActions.EXPORT_ALL_RISKS,
    actionFulfilled: RiskActions.EXPORT_ALL_RISKS_FULFILLED,
    actionRejected: RiskActions.EXPORT_ALL_RISKS_REJECTED,
} as IActionType;

const resetRisk = {
    action: RiskActions.RESET_RISK,
    actionFulfilled: RiskActions.RESET_RISK_FULFILLED,
    actionRejected: RiskActions.RESET_RISK_REJECTED,
} as IActionType;


const createRiskEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createRisks, riskRegisterPageUrl).post<IRisk>(apiPath);

const createRiskMitigationPlanEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createRiskMitigationPlan).put<IRisk>(`/api/${controllerName}/createMitigationPlan`);

const acceptRiskEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, acceptRisk, riskRegisterPageUrl).put<IRisk>(`/api/${controllerName}/accept`);

const updateRiskEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateRisks, riskRegisterPageUrl).put<IRisk>(apiPath);

const createRiskForIncidentEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createRiskForIncident).post<IRisk>(apiPath);

const updateRiskForIncidentEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateRiskForIncident).post<IRisk>(apiPath);

const loadRisksEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getAllRisks).get<IRisk[]>(apiPath);

const loadRiskEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getRisks).getByIdAndShard<IRisk>(apiPath);

const deleteRiskEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, deleteRisk, riskRegisterPageUrl).deleteByIdAndShardAndParam<string>(
        apiPath
    );

const closeRiskEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, closedRisk, riskRegisterPageUrl).put<IRisk>(
        `/api/${controllerName}/close`
    );

const openClosedRiskEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, openClosedRisk, riskRegisterPageUrl).put<IRisk>(
        `/api/${controllerName}/OpenClosedRisk`
    );

const loadHistoryEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, loadHistory).getByIdAndShard<IEntitySite>(`${apiPath}/history`);

const cbreApprovalRiskEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, CbreApprovalRisk, riskRegisterPageUrl).put<IRiskApproval>(
        `/api/${controllerName}/approval`
    );

const clientApprovalRiskEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, ClientApprovalRisk, '/RiskRegisterClient/OpenPending').put<IRiskApproval>(
        `/api/${controllerName}/approval`
    );

const clientRequestAmendmentEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, clientRequestAmendments).post<IRisk>(`/api/${controllerName}/ClientRequestAmendments`);

const loadRiskActionsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, loadRiskActions).get<IAction>(`/api/action`);

const exportRisksEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, exportActions).exportByQueryModel(`${apiPath}/export`);

const exportAllRisksEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, exportAllActions).exportByQueryModel(`${apiPath}/exportAll`);

const resetRiskEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, resetRisk, riskRegisterPageUrl).put<IRisk>(`/api/${controllerName}/Reset`);

export const riskEpicsV2 = combineEpics(
    createRiskEpic,
    createRiskMitigationPlanEpic,
    acceptRiskEpic,
    updateRiskEpic,
    createRiskForIncidentEpic,
    updateRiskForIncidentEpic,
    loadRisksEpic,
    loadRiskEpic,
    deleteRiskEpic,
    loadHistoryEpic,
    cbreApprovalRiskEpic,
    clientApprovalRiskEpic,
    loadRiskActionsEpic,
    exportRisksEpic,
    exportAllRisksEpic,
    closeRiskEpic,
    openClosedRiskEpic,
    clientRequestAmendmentEpic,
    resetRiskEpic
);
