import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { getCurrentSelectedSiteId, getCurrentSiteFeatures } from 'sharedSelectors';
import { ClientDashboardPage } from './client-dashboard-page';
import { loadRisksSummary } from 'actions/actions-v2/risk-action-v2';
import { loadSummary } from 'actions/actions-v2/critical-system-spares-actions';
import { IRootState } from 'routes/store';

const getCriticalSparesSummary = (state: IRootState) =>
    state.criticalSystemSparesState.criticalSystemSparesSummary;
const getClientRisksSummary = (state: IRootState) => state.riskRegisterState.clientRisksSummary;
const getIsRisksLoading = (state: IRootState) => state.riskRegisterState.isLoading;
const getIsCriticalSparesLoading = (state: IRootState) => state.criticalSystemSparesState.isLoading;

const mapStateToProps = createSelector(
    getCurrentSelectedSiteId,
    getClientRisksSummary,
    getCriticalSparesSummary,
    getIsRisksLoading,
    getIsCriticalSparesLoading,
    getCurrentSiteFeatures,
    (
        siteId,
        risks,
        criticalSparesSummary,
        isRisksLoading,
        isCriticalSparesLoading,
        currentSiteFeatures
    ) => ({
        siteId,
        risks,
        isRisksLoading,
        isCriticalSparesLoading,
        criticalSpares: criticalSparesSummary,
        showClientRisks: currentSiteFeatures.includes('Client Risks'),
        showCriticalSpares: currentSiteFeatures.includes('Client facing critical spares')
    })
);

const mapDispatchToProps = {
    loadRisksSummary,
    loadCriticalSparesSummary: loadSummary
};

export const ClientDashboardContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientDashboardPage);
