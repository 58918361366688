import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { IauditorInspectionsUpsertPage } from './iauditor-inspections-upsert-page';
import { getCermProcessLinks } from 'actions/actions-v2/cerm-process-step-action-v2';
import { loadTemplate, loadComplianceChecklist } from 'actions/actions-v2/iauditor-action';
import { refreshECermToken } from 'actions/actions-v2/cerm-assessment-action-v3';
import { getCurrentSiteFeatures } from 'sharedSelectors';
import { SiteFeatures } from 'actions/site-feature';
import { checkPermission } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import { loadPersonsLookup } from 'actions/actions-v2/person-action-v2';

const getSiteId = (state: IRootState) => state.app.siteId;
const getLookupSites = (state: IRootState) => state.app.lookupSites;
const getCermLinks = (state: IRootState) => state.cermOverview.cermLinks;
const getSiteCode = (state: IRootState) => state.siteState.site.siteCode;
const getIsLoading = (state: IRootState) => state.iauditorState.isLoading;
const getIsLoadingTemplate = (state: IRootState) => state.iauditorState.isLoadingTemplate;
const getComplianceCheckList = (state: IRootState) => state.iauditorState.complianceCheckList;
const getAuthToken = (state: IRootState) => state.cermAssessmentResultState.eCermToken;
const getPermissions = (state: IRootState) => state.app.permissions;
const getPageTitle = (state: IRootState) => state.menuState.selectedMenu;


const mapStateToProps = createSelector(
    getSiteId,
    getLookupSites,
    getCermLinks,
    getSiteCode,
    getIsLoading,
    getIsLoadingTemplate,
    getComplianceCheckList,
    getAuthToken,
    getCurrentSiteFeatures,
    getPermissions,
    getPageTitle,
    (
        siteId,
        lookupSites,
        cermLinks,
        siteCode,
        isDocumentLoading,
        isLoadingTemplate,
        complianceCheckList,
        authToken,
        siteFeatures,
        permissions,
        selectedMenuName,
    ) => ({
        siteId,
        lookupSites,
        cermLinks,
        siteCode,
        isDocumentLoading,
        isLoadingTemplate,
        complianceCheckList,
        authToken,
        showOpportunityQuestionnaire: siteFeatures.includes(SiteFeatures.OpportunityQuestionnaire),
        hasIAuditorView: checkPermission(Privilege.IAuditorView, permissions),
        selectedMenuName,
    })
);

const mapDispatchToProps = {
    getCermProcessLinks,
    loadTemplate,
    loadComplianceChecklist,
    getECermToken: refreshECermToken,
    PersonsLookup: loadPersonsLookup,
};

export const IauditorInspectionsUpsertContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(IauditorInspectionsUpsertPage)
);
