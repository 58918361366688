import { createSelector } from 'reselect';
import { IRootCermAssessmentState } from 'reducers/cermAssessments';
import { IRootState } from 'routes/store';

const getLookupRiskCategories = (state: IRootCermAssessmentState) => state.app.lookupRiskCategories;
const getLookupRiskStatus = (state: IRootCermAssessmentState) => state.app.lookupRiskStatus;
const getLookupRiskLikelihoods = (state: IRootCermAssessmentState) =>
    state.app.lookupRiskLikelihoods;
const getLookupRiskImpacts = (state: IRootCermAssessmentState) => state.app.lookupRiskImpacts;
const getSite = (state: IRootState) => state.siteState.site;

export default createSelector(
    getLookupRiskCategories,
    getLookupRiskStatus,
    getLookupRiskLikelihoods,
    getLookupRiskImpacts,
    getSite,
    (lookupRiskCategories, lookupRiskStatus, lookupRiskLikelihoods, lookupRiskImpacts, site) => ({
        lookupRiskCategories,
        lookupRiskStatus,
        lookupRiskLikelihoods,
        lookupRiskImpacts,
        site,
    })
);
