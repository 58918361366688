import * as React from 'react';
import { connect } from 'react-redux';
import selectors from './selectors';
import actions from './actions';
import { IEngineer, IEngineerTraining } from 'models';
import { Loader } from 'components/loader';
import { Checkbox } from 'components/form/Checkbox';
import { IEngineerTrainingType } from 'models/IEngineerTrainingType';
import { IEngineerTrainingCategory } from 'models/IEngineerTrainingCategory';
import { DatePickerField } from 'components/form/fields/date-picker-field';
import { isTodayOrFutureDate, getTimezoneDiffrence, isValidDate } from 'utils/date-utils';
import { Help } from 'components/form';
import { BulmaColor } from 'enums/BulmaColor';
import _ from 'lodash';
import useTranslate from 'translations/translation-utils';

interface IProps {
    engineer: IEngineer;
    isLoading: boolean;
    trainingTypes: IEngineerTrainingType[];
    mechanicalTrainingCategories: IEngineerTrainingCategory[];
    coolingTrainingCategories: IEngineerTrainingCategory[];
    electricalTrainingCategories: IEngineerTrainingCategory[];
    fireTrainingCategories: IEngineerTrainingCategory[];
    statutoryTrainingCategories: IEngineerTrainingCategory[];
    processTrainingCategories: IEngineerTrainingCategory[];
    permitTrainingCategories: IEngineerTrainingCategory[];
    isReadonly: boolean;
    onUpdateTraining: (trainingTypeId: string, trainingCategoryId: string, remove: boolean) => void;
    onUpdateTraingExpiryDate: (
        trainingCategoryId: string,
        trainingExpiryDate: Date | string
    ) => void;
}

export const TrainingTab: React.FC<IProps> = ({
    engineer,
    isLoading,
    trainingTypes,
    mechanicalTrainingCategories,
    coolingTrainingCategories,
    electricalTrainingCategories,
    fireTrainingCategories,
    statutoryTrainingCategories,
    processTrainingCategories,
    permitTrainingCategories,
    isReadonly,
    onUpdateTraining,
    onUpdateTraingExpiryDate,
}) => {
    const translate = useTranslate();
    const [isValidExpiryDate, setIsValidExpiryDate] = React.useState<boolean>(true);
    const [trainingIds, setTrainingIds] = React.useState<IEngineerTraining[]>([]);
    const [currentTrainingCategory, setCurrentTrainingCategory] = React.useState<string>('');

    const validCategoryIds = ['23', '22', '36', '54', '53'];
    const totalColSpan = trainingTypes ? trainingTypes.length + 2 : 1;
    const excludedTrainingIds = ['1', '2'];

    const handleDateChange = (trainingCategoryId, newDate) => {
        if (!isTodayOrFutureDate(newDate)) {
            setIsValidExpiryDate(false);
            setCurrentTrainingCategory(trainingCategoryId);
        } else {
            setIsValidExpiryDate(true);
            setCurrentTrainingCategory(trainingCategoryId);
            setTrainingIds(
                trainingIds.filter((training) => training.trainingCategoryId !== trainingCategoryId)
            );

            onUpdateTraingExpiryDate(trainingCategoryId, getTimezoneDiffrence(newDate));
        }
    };

    const setDateValue = (trainingCategory) => {
        const newTraining = engineer.training.filter(
            (training) => training.trainingCategoryId === trainingCategory
        );
        const expiryDate = newTraining && newTraining[0] && newTraining[0].trainigExpiryDate;

        return expiryDate ? expiryDate : '';
    };

    const setSelectedDateValue = (trainingCategory) => {
        const newTraining = engineer.training.filter(
            (training) => training.trainingCategoryId === trainingCategory
        );
        const expiryDate = newTraining && newTraining[0] && newTraining[0].trainigExpiryDate;

        return !isTodayOrFutureDate(new Date(expiryDate)) ? '' : expiryDate;
    };

    const checkTrainingTypeId = (trainingTypeId) => {
        return trainingTypeId === '3' || trainingTypeId === '7';
    };

    const onChangeTraining = (
        trainingTypeId: string,
        trainingCategoryId: string,
        remove: boolean
    ) => {
        if (checkTrainingTypeId(trainingTypeId) && checkCategoryExpiryDate(trainingCategoryId)) {
            const expirydate = setDateValue(trainingCategoryId);

            if (remove === false && (!isValidDate(expirydate) || expirydate === '')) {
                let ids = [];
                let trainingCategory = trainingIds;

                if (trainingIds.some((item) => item.trainingCategoryId === trainingCategoryId)) {
                    trainingCategory = trainingIds.map((training) => {
                        if (training.trainingCategoryId === trainingCategoryId) {
                            ids = _.uniq([...training.trainingTypeId, trainingTypeId]);
                            training.trainingTypeId = ids.toString();
                        }

                        return training;
                    });
                } else {
                    const traininig = engineer.training.filter(
                        (training) => training.trainingCategoryId === trainingCategoryId
                    );

                    if (
                        traininig.length === 0 ||
                        (traininig &&
                            traininig[0] &&
                            !excludedTrainingIds.includes(traininig[0].trainingTypeId))
                    ) {
                        trainingCategory.push({
                            trainingCategoryId,
                            trainingTypeId,
                        });
                    }
                }

                setIsValidExpiryDate(true);
                setCurrentTrainingCategory(trainingCategoryId);
                setTrainingIds([...trainingCategory]);
            } else {
                const trainingCategory = trainingIds.map((training) => {
                    if (training.trainingCategoryId === trainingCategoryId) {
                        training.trainingTypeId = training.trainingTypeId
                            .split(',')
                            .filter((item) => item !== trainingTypeId)
                            .toString();
                    }

                    return training;
                });

                const updatedTrainingcategory = trainingCategory.filter(
                    (item) => item.trainingTypeId !== ''
                );

                setCurrentTrainingCategory(trainingCategoryId);
                setTrainingIds(updatedTrainingcategory);
            }
        }

        if (trainingTypeId === '1' || trainingTypeId === '2') {
            setTrainingIds(
                trainingIds.filter((item) => item.trainingCategoryId !== trainingCategoryId)
            );
        }

        onUpdateTraining(trainingTypeId, trainingCategoryId, remove);
    };

    const checkCategoryExpiryDate = (trainingCategoryId) => {
        return validCategoryIds.includes(trainingCategoryId);
    };

    const checkBoxValidTrainingSelected = (checkboxTrainingCategory: IEngineerTrainingCategory) => {
        return trainingTypes.some((trainingType) =>
            !excludedTrainingIds.includes(trainingType.id) &&
            getCheckboxStatus(checkboxTrainingCategory, trainingType)
        );
    };

    const getCheckboxStatus = (
        checkboxTrainingCategory: IEngineerTrainingCategory,
        trainingType: IEngineerTrainingType
    ) => {
        if (!engineer.training) {
            return false;
        }

        const trainingTypeIdValue = engineer.training.find(
            (x) => x.trainingCategoryId === checkboxTrainingCategory.id
        );

        if (!trainingTypeIdValue) {
            return false;
        }

        const trainingTypeIds: string[] = trainingTypeIdValue.trainingTypeId.split(',');

        if (!trainingTypeIds) {
            return false;
        }

        if (trainingTypeIds.some((x) => x === trainingType.id)) {
            return true;
        }

        return false;
    };

    const getEngineerTabItem = (
        trainingCategory: IEngineerTrainingCategory,
        engTrainingTypes: IEngineerTrainingType[]
    ) => {
        return (
            <tr key={trainingCategory.id}>
                <td className="desc">{trainingCategory.name} </td>
                <td className="training-expiry-date">
                    {checkBoxValidTrainingSelected(trainingCategory) && (
                        <>
                            <DatePickerField
                                id={`trainingExpiryDate${trainingCategory.id}`}
                                label=""
                                value={setDateValue(trainingCategory.id)}
                                selected={setSelectedDateValue(trainingCategory.id)}
                                handleChange={(e) => handleDateChange(trainingCategory.id, e)}
                                showMonthDropdown={true}
                                showYearDropdown={true}
                                minDate={new Date()}
                                isInTable={true}
                                isDisabled={isReadonly}
                            />

                            {currentTrainingCategory === trainingCategory.id && (
                                <Help isShown={!isValidExpiryDate} bulmaColor={BulmaColor.Danger}>
                                    {translate('TrainingTab.Lable.1')}
                                </Help>
                            )}
                        </>
                    )}
                </td>

                {engTrainingTypes &&
                    engTrainingTypes.map((trainingType, key) => {
                        const checked = getCheckboxStatus(trainingCategory, trainingType);

                        return (
                            <td key={key}>
                                <Checkbox
                                    id={`${trainingType.id}${trainingCategory.id}`}
                                    checked={checked}
                                    onChange={() =>
                                        onChangeTraining(
                                            trainingType.id,
                                            trainingCategory.id,
                                            checked
                                        )
                                    }
                                    disabled={isReadonly}
                                />
                            </td>
                        );
                    })}
            </tr>
        );
    };

    return (
        <Loader className="engineer-training-matrix" loading={isLoading}>
            <table className="table table-sticky-header is-bordered">
                <thead>
                    <tr>
                        <th>{translate('TrainingTab.Lable.2')}</th>
                        <th className="training-expiry-header">
                            <span className="is-vertical-center">{translate('TrainingTab.Lable.3')}</span>
                        </th>
                        {trainingTypes &&
                            trainingTypes.map((trainingType, key) => (
                                <th key={key} className="tick-box">
                                    <span className="is-vertical-center">{trainingType.name}</span>
                                </th>
                            ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th
                            className="engineer-training-matrix__header alternate-style"
                            colSpan={totalColSpan}
                        >
                            {translate('TrainingTab.Lable.4')}
                        </th>
                    </tr>
                    {mechanicalTrainingCategories &&
                        mechanicalTrainingCategories.map((trainingCategory) =>
                            getEngineerTabItem(trainingCategory, trainingTypes)
                        )}
                    <tr>
                        <th
                            className="engineer-training-matrix__header alternate-style"
                            colSpan={totalColSpan}
                        >
                            {translate('TrainingTab.Lable.5')}
                        </th>
                    </tr>
                    {coolingTrainingCategories &&
                        coolingTrainingCategories.map((trainingCategory) =>
                            getEngineerTabItem(trainingCategory, trainingTypes)
                        )}
                    <tr>
                        <th
                            className="engineer-training-matrix__header alternate-style"
                            colSpan={totalColSpan}
                        >
                            {translate('TrainingTab.Lable.6')}
                        </th>
                    </tr>
                    {electricalTrainingCategories &&
                        electricalTrainingCategories.map((trainingCategory) =>
                            getEngineerTabItem(trainingCategory, trainingTypes)
                        )}
                    <tr>
                        <th
                            className="engineer-training-matrix__header alternate-style"
                            colSpan={totalColSpan}
                        >
                            {translate('TrainingTab.Lable.7')}
                        </th>
                    </tr>
                    {fireTrainingCategories &&
                        fireTrainingCategories.map((trainingCategory) =>
                            getEngineerTabItem(trainingCategory, trainingTypes)
                        )}
                    <tr>
                        <th
                            className="engineer-training-matrix__header alternate-style"
                            colSpan={totalColSpan}
                        >
                            {translate('TrainingTab.Lable.8')}
                        </th>
                    </tr>
                    {statutoryTrainingCategories &&
                        statutoryTrainingCategories.map((trainingCategory) =>
                            getEngineerTabItem(trainingCategory, trainingTypes)
                        )}
                    <tr>
                        <th
                            className="engineer-training-matrix__header alternate-style"
                            colSpan={totalColSpan}
                        >
                            {translate('TrainingTab.Lable.9')}
                        </th>
                    </tr>
                    {processTrainingCategories &&
                        processTrainingCategories.map((trainingCategory) =>
                            getEngineerTabItem(trainingCategory, trainingTypes)
                        )}
                    <tr>
                        <th
                            className="engineer-training-matrix__header alternate-style"
                            colSpan={totalColSpan}
                        >
                            {translate('TrainingTab.Lable.10')}
                        </th>
                    </tr>
                    {permitTrainingCategories &&
                        permitTrainingCategories.map((trainingCategory) =>
                            getEngineerTabItem(trainingCategory, trainingTypes)
                        )}
                </tbody>
            </table>
        </Loader>
    );
};

export default connect(selectors, actions)(TrainingTab);
