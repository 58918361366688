import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { connect } from 'react-redux';
import ShiftHandoverAdminPage from './shift-handover-operating-platform-admin-page';
import {
    loadSpecificOperatingPlatformShiftChecklist,
    createSpecificSiteShiftOperatingPlatformChecklist,
    updateSpecificSiteShiftOperatingPlatformChecklist,
    deleteSpecificSiteShiftOperatingPlatformChecklist,
    loadSelectedOperatingPlatform,
} from 'actions/actions-v2/shift-handover-actions';

const getSiteId = (state: IRootState) => state.app.siteId;
const getAdminShiftCheckList = (state: IRootState) => state.shiftHandoverState.adminOperatingPlatformShiftChecklist;
const getAdminShiftCheckListLoading = (state: IRootState) => state.shiftHandoverState.isLoading;
const getSelectedOperatingPlatform = (state: IRootState) => state.shiftHandoverState.selectedOperatingPlatform;


const mapStateToProps = createSelector(
    getSiteId,
    getAdminShiftCheckList,
    getAdminShiftCheckListLoading,
    getSelectedOperatingPlatform,
    (siteId, shiftCheckList, isLoading, selectedOperatingPlatform) => ({
        siteId,
        shiftCheckList,
        isLoading,
        selectedOperatingPlatform,
    })
);

const mapDispatchToProps = {
    loadSpecificOperatingPlatformShiftChecklist,
    createSpecificSiteShiftOperatingPlatformChecklist,
    updateSpecificSiteShiftOperatingPlatformChecklist,
    deleteSpecificSiteShiftOperatingPlatformChecklist,
    loadSelectedOperatingPlatform,
};

export const ShiftHandoverAdminPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ShiftHandoverAdminPage);
