import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';

const getIsSaving = (state: IRootState) => state.incidentsState.isSaving;

const getAllAvailableRisks = (state: IRootState) => state.risks.grid.items;

const getAllAvailableActions = (state: IRootState) => state.actions.grid.items;

const getExpendedRiskItems = (state: IRootState) => state.risks.grid.expandedItems;

const getExpendedActionItems = (state: IRootState) => state.actions.grid.expandedItems;

const getIsLoadingIncidentRisksOrActions = (state: IRootState) => {
    return state.actions.grid.isLoading || state.risks.grid.isLoading || state.incidentsState.isLoading;
};

export default createSelector(
    getIsSaving,
    getAllAvailableRisks,
    getAllAvailableActions,
    getExpendedRiskItems,
    getExpendedActionItems,
    getIsLoadingIncidentRisksOrActions,
    (isSaving, allAvailableRisks, allAvailableActions, expendedRiskItems, expendedActionItems, isLoading) => ({
        isSaving,
        allAvailableRisks,
        allAvailableActions,
        expendedRiskItems,
        expendedActionItems,
        isLoading
    })
);
