import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Loader } from 'components/loader';
import { IKeyValue } from '../../../models/key-value';
import { CermOverviewFilters } from './cerm-overview-filters';
import { CermOverviewList } from './cerm-overview-list';
import { ICermFilters } from 'models/filters/cerm-assessment-filters';
import { CermOverviewToolbar } from './cerm-overview-toolbar/cerm-overview-toolbar';
import { OverlayPanel } from 'components/v2/components';
import Page from 'components/v2/page/Page';
import { HelpModuleContainer } from 'routes/help-module/help-module-container';
import { ModuleType } from 'models/help-module';
import { checkFilterExistsWithIgnoreKey } from 'utils/array-utils';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';
import { IPerson } from 'models/person';
import { IOperatingPlatform } from 'models/operating-platform-model';
import { IOperatingPlatformProcess } from 'models/site-profile';

interface IRouteParams {
    operatingPlatform?: string;
}

export interface IProps extends RouteComponentProps<IRouteParams> {
    pageTitle: string;
    permissions: Array<IKeyValue<string>>;
    isInitialising: boolean;
    onPageLoad: (filters: ICermFilters) => void;
    onSort: (key: string, sortAscending: boolean) => void;
    showHelpModule: boolean;
    intl: any;
    person: IPerson;
    operatingPlatformValues: IOperatingPlatform[];
    onSubmit: (request: IOperatingPlatformProcess) => void;
    onLoadPerson?: (personId: string) => void;
    userId: string;
}

interface IState {
    showFilters: boolean;
    filters: ICermFilters;
    sortColumn: string;
    sortAscending: boolean;
    showHelp: boolean;
}

class CermOverviewPageClass extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            sortColumn: '',
            sortAscending: false,
            showFilters: false,
            showHelp: false,
            filters: {
                siteId: [],
                phaseFilters: [],
                pillarFilters: [],
                priorityFilters: [],
                serviceFilters: [],
                timePeriodFilters: [],
                filterKeywords: [],
                operatingPlatform: [this.props.match.params.operatingPlatform],
            },
        };
    }

    public getInitialValues() {
        return {
            sortColumn: '',
            sortAscending: false,
            showFilters: false,
            showHelp: false,
            filters: {
                siteId: [],
                phaseFilters: [],
                pillarFilters: [],
                priorityFilters: [],
                serviceFilters: [],
                timePeriodFilters: [],
                filterKeywords: [],
                operatingPlatform: [this.props.match.params.operatingPlatform],
            },
        };
    }

    public componentDidMount() {
        if (this.props.userId) this.props.onLoadPerson(this.props.userId);
        if (this.isOpExists()) this.props.onPageLoad(this.state.filters);
    }

    public componentWillReceiveProps(nextProps) {
        if (
            nextProps.match.params.operatingPlatform !== this.props.match.params.operatingPlatform
        ) {
            this.setState({ ...this.getInitialValues() }, () => {
                this.props.onPageLoad(this.getInitialValues().filters);
            });
        }
    }

    public getTitlePrefix = () => {
        const selectedOperatingPlatform =
            this.props.operatingPlatformValues &&
            this.props.operatingPlatformValues.find(
                x => x.value === this.props.match.params.operatingPlatform
            );
        return selectedOperatingPlatform?.shortForm.toUpperCase();
    };

    public getcustomTitle() {
        return translate(this.props.intl, 'ProcessStepsPage.Breadcrumb.Title');
    }

    public isFilterExists() {
        return checkFilterExistsWithIgnoreKey(this.state.filters, 'operatingPlatform');
    }

    public isOpExists() {
        const check =
            this.props.operatingPlatformValues &&
            this.props.operatingPlatformValues.some(i =>
                i.key.includes(this.props.match.params.operatingPlatform)
            );
        return check;
    }

    public render() {
        const props = this.props;
        return (
            <Page title={this.getcustomTitle()} isAdminPage={true}>
                <CermOverviewToolbar
                    permissions={props.permissions}
                    onShowFilters={() => this.setState({ showFilters: true })}
                    onClearFilters={this.onClearFilters}
                    isShowHelp={this.props.showHelpModule || true}
                    onClickHelp={this.onClickHelp}
                    isFilterExists={this.isFilterExists()}
                    operatingPlatform={this.props.match.params.operatingPlatform}
                    isOpExist={this.isOpExists()}
                />
                <HelpModuleContainer
                    isShown={this.state.showHelp}
                    onClose={this.onClickHelp}
                    moduleType={ModuleType.Assesment}
                />
                <OverlayPanel
                    title={translate(this.props.intl, 'RiskRegisterPage.Title.Filters')}
                    isVisible={this.state.showFilters}
                    onClose={() => this.setState({ showFilters: false })}
                    onOutsideDialogClick={() => this.setState({ showFilters: false })}
                    onClearFilters={this.onClearFilters}
                    isFilterExists={this.isFilterExists()}
                >
                    <CermOverviewFilters
                        onFilter={this.onFilter}
                        onAddFilterKeyword={this.onAddFilterKeyword}
                        onRemoveFilterKeyword={this.onRemoveFilterKeyword}
                        filters={this.state.filters}
                    />
                </OverlayPanel>
                <Loader loading={props.isInitialising}>
                    {!props.isInitialising && (
                        <CermOverviewList
                            permissions={props.permissions}
                            onSort={this.onSort}
                            isSortAscending={this.state.sortAscending}
                            sortColumn={this.state.sortColumn}
                            person={props.person}
                            getTitlePrefix={this.getTitlePrefix}
                            onSubmit={props.onSubmit}
                            isOpExist={this.isOpExists()}
                        />
                    )}
                </Loader>
            </Page>
        );
    }

    private onClickHelp = () => {
        this.setState({ showHelp: !this.state.showHelp });
    };
    private onSort = (key: string, sortAscending: boolean) => {
        this.props.onSort(key, sortAscending);
        this.setState({
            sortAscending,
            sortColumn: key,
        });
    };

    private onAddFilterKeyword = (keyword: string) => {
        const filters = { ...this.state.filters };
        const keywords = [
            keyword,
            ...filters.filterKeywords.slice(0, filters.filterKeywords.length),
        ];
        filters.filterKeywords = keywords;
        this.setState({ filters });
        this.props.onPageLoad(filters);
    };
    private onClearFilters = () => {
        const filters = {
            siteId: [],
            phaseFilters: [],
            pillarFilters: [],
            priorityFilters: [],
            serviceFilters: [],
            timePeriodFilters: [],
            filterKeywords: [],
            operatingPlatform: [this.props.match.params.operatingPlatform],
        };

        this.setState({ filters });
        this.props.onPageLoad(filters);
    };

    private onRemoveFilterKeyword = (keyword: string) => {
        const filters = { ...this.state.filters };
        const index = filters.filterKeywords.indexOf(keyword);
        const keywords = [
            ...filters.filterKeywords.slice(0, index),
            ...filters.filterKeywords.slice(index + 1),
        ];
        filters.filterKeywords = keywords;
        this.setState({ filters });
        this.props.onPageLoad(filters);
    };
    private onFilter = (key: Extract<keyof ICermFilters, string>, element: string) => {
        const filters = { ...this.state.filters };
        const index = filters[key].indexOf(element);
        if (index !== -1) {
            const items = [...filters[key].slice(0, index), ...filters[key].slice(index + 1)];
            filters[key] = items;
        } else {
            const items = [element, ...filters[key].slice(0, filters[key].length)];
            filters[key] = items;
        }
        filters.operatingPlatform = [this.props.match.params.operatingPlatform];
        this.setState({ filters });
        this.props.onPageLoad(filters);
    };
}

export const CermOverviewPage = injectIntl(CermOverviewPageClass);
