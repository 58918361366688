import { IKeyValue } from 'models';
import { isEmpty } from 'lodash';

export const getKeyValueLabel = (lookups: Array<IKeyValue<string>>, key: string): string => {
    if (isEmpty(lookups)) {
        return '';
    }

    const found = lookups.find((l) => l.key === key);
    return found ? found.label : '';
};

export const getLabelsWithSeparator = (
    lookups: Array<IKeyValue<string>>,
    limit = false,
    limitText = 'Values',
    separator = ', '
): string => {
    if (isEmpty(lookups)) {
        return '';
    }

    const labels = lookups.map(l => l.label);

    if (!limit || (limit && lookups.length <= 2)) {
        return labels.join(separator);
    } else {
        return labels.length + ' ' + limitText;
    }
};
