import * as React from 'react';
import classNames from 'classnames';
import { IconToolbar, IToolbarIconItem } from '../components';

interface IProps {
    item: IToolbarIconItem;
    className?: string;
    children?: JSX.Element;
}

export const IconToolbarItem: React.FC<IProps> = ({ item, className, ...props }) => {
    const wrapperRef = React.useRef(null);
    const [showDropDown, setShowDropDown] = React.useState(false);

    React.useEffect(() => {
        if (item.children) {
            document.addEventListener('click', handleClickOutside, false);
            return () => {
                document.removeEventListener('click', handleClickOutside, false);
            };
        }
    }, []);

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setShowDropDown(false);
        }
    };

    const onClick = () => {
        if (item.isDisabled) {
            return null;
        }

        if (item.children) {
            setShowDropDown(!showDropDown);
            return;
        }

        item.onClick();
    };

    const itemProps = {
        id: item.id,
        title: item.title,
        className: classNames(
            `icon-toolbar-item-${item.type}`,
            className,
            item.isActive ? 'active' : '',
            item.isDisabled ? 'disabled' : ''
        )
    };

    return (
        <li {...itemProps} ref={wrapperRef} onClick={onClick}>
            <span onClick={onClick}>{item.title}</span>
            {props.children}
            {item.children && (
                <IconToolbar
                    items={item.children}
                    className={`${classNames('', showDropDown ? 'active' : '')}`}
                />
            )}
        </li>
    );
};
