import React, { Dispatch, useEffect } from 'react';
import appConfig from '../../helpers/config-helper';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { IAppAction } from 'actions/app-action';
import { refreshECermToken, recordECermClick } from 'actions/actions-v2/cerm-assessment-action-v3';
import { Loader } from 'components/loader';
import ecerm from 'assets/ecerm.png';
import useTranslate from 'translations/translation-utils';

interface IProps {
    currentSiteId: string;
    authToken: string;
    getECermToken: () => void;
    getRecordECermClick: (currentSiteId: string) => void;
}

const ECermPageView: React.FC<IProps> = ({
    currentSiteId,
    authToken,
    getECermToken,
    getRecordECermClick,
}) => {
    const translate = useTranslate();
    const config = appConfig();

    useEffect(() => {
        getECermToken();
    }, []);

    useEffect(() => {
        if (authToken) {
            window.open(`${config.REACT_APP_ECERM_URL}`, '_blank');
        }
    }, [authToken]);

    const openNewWindow = () => {
        getRecordECermClick(currentSiteId);
        window.open(`${config.REACT_APP_ECERM_URL}`, '_blank');
    };

    if (!authToken) {
        return <Loader loading={!authToken} showSpinner={!authToken} />;
    }

    return (
        <React.Fragment>
            <div className="tabs-container tab-content e-cerm-tab">
                <p className="ecerm-info">
                    {translate('ecerm.Lable.3') + '  '} <a onClick={openNewWindow}>{translate('ecerm.Lable.1')}</a>{'  ' +  translate('ecerm.Lable.2')}
                </p>
                <a className="ecerm-icon">
                    <img src={ecerm} alt="e-cerm_logo" onClick={openNewWindow} />
                </a>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state: IRootState) => {
    return {
        authToken: state.cermAssessmentResultState.eCermToken,
        currentSiteId: state.app.siteId,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<IAppAction>) => {
    return {
        getECermToken() {
            dispatch(refreshECermToken());
        },
        getRecordECermClick(siteId: string) {
            dispatch(recordECermClick(siteId));
        },
    };
};

const ECermPage = withRouter(connect(mapStateToProps, mapDispatchToProps)(ECermPageView));

export default ECermPage;
