import React from 'react';
import { ContactForm } from 'components/form/ContactForm';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';

interface IProps {
    intl: any;
}

class ContactFormPageClass extends React.PureComponent<IProps> {
    private returnUrl: string;
    private initial: { subject: 'subject', message: 'message' };

    public componentWillMount() {
        const urlParams = new URLSearchParams(window.location.search);
        this.returnUrl = urlParams.get('returnUrl');
    }

    public render() {
        const intl = this.props;

        return (
            <ContactForm
                title={translate(intl, 'Form.ContactFormPage.ContactForm')}
                subtitle={translate(intl, 'Form.ContactFormPage.RaiseTicket')}
                returnUrl={this.returnUrl}
                initialValues={this.initial}
                onSubmit={this.onSubmit}
                validate={this.validate}
            />
        );
    }
    private validate = (values: any) => {
        const errors = {} as any;
        if (!values.subject) {
            errors.subject = 'Required';
        }
        if (!values.message) {
            errors.message = 'Required';
        }
        return errors;
    }
    private onSubmit = (values: any, setSubmitting: any) => {
        setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
        }, 400);
    }
}

export const ContactFormPage = injectIntl(ContactFormPageClass);
