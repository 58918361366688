import React from 'react';
import { IProgressBarModel } from 'components/step-progress-bar/StepProgressBar';
import { Tier } from '../Context';
import { IStateResult, State } from '../State';
import { Result } from './question-result';

export class QuestionReturnTemp extends State {
    public result: IStateResult;
    private title: string = 'Data hall return temperature';
    private type: ReturnTemp;
    constructor() {
        super();
        this.handleClick = this.handleClick.bind(this);
    }
    public getResult(): IStateResult {
        return this.result;
    }

    public display(): JSX.Element {
        return (
            <div >
                <div className="is-size-3  has-text-centered">
                    {this.title}
                </div>
                <p className="has-text-centered">
                    Is the return temperature to your data hall cooling plant CRAC, CRAH, AHUs above or below 24°C (75°F)?
                    To select the appropriate chiller and free cooling performance it is important to select the correct air
                    system temperatures, these are grouped into
                </p>
                <hr />
                <div className="formBody">
                    <div className="field">
                        <div className="control">
                            <input
                                className="radioButton"
                                type="radio"
                                name="answer"
                                id={ReturnTemp.Lower}
                                value={ReturnTemp.Lower}
                                onClick={this.handleClick}
                                checked={this.type === ReturnTemp.Lower}
                            />
                            <label htmlFor={ReturnTemp.Lower} className="radio is-size-5">

                                {ReturnTemp.Lower.toString()}
                            </label>
                            <p>
                                which supply cold air and manage the return temperature to below 24°C (75°F),
                                these systems generally control based on Return Air Temperature
                            </p>
                        </div>
                    </div>
                    <div className="field">
                        <div className="control">
                            <input
                                className="radioButton"
                                type="radio"
                                name="answer"
                                id={ReturnTemp.Higher}
                                value={ReturnTemp.Higher}
                                onClick={this.handleClick}
                                checked={this.type === ReturnTemp.Higher}
                            />
                            <label htmlFor={ReturnTemp.Higher} className="radio is-size-5">
                                {ReturnTemp.Higher.toString()}
                            </label>
                            <p>
                                which supply warmer air, typically with substantial air flow management or
                                containment and allow the return air to be above 24°C (75°F),
                                these systems frequently control the Supply Air Temperature
                            </p>
                        </div>
                    </div>
                </div>
                <hr />
                {this.context.footer(this.handle.bind(this))}
            </div>);
    }
    public handle() {

        this.result = {
            title: this.title,
            value: this.type.toString()
        };

        const airflowAnswer = this.context.answers[this.context.answers.length - 2];

        if (airflowAnswer.value === 'No') {
            if (this.type === ReturnTemp.Lower && this.context.tier === Tier.HighSpec) {
                this.context.transitionTo(new Result(1));
            } else if (this.type === ReturnTemp.Lower && this.context.tier === Tier.LowSpec) {
                this.context.transitionTo(new Result(2));
            } else if (this.type === ReturnTemp.Higher && this.context.tier === Tier.HighSpec) {
                this.context.transitionTo(new Result(3));
            } else if (this.type === ReturnTemp.Higher && this.context.tier === Tier.LowSpec) {
                this.context.transitionTo(new Result(4));
            }

        } else {
            if (this.type === ReturnTemp.Lower && this.context.tier === Tier.HighSpec) {
                this.context.transitionTo(new Result(7));
            } else if (this.type === ReturnTemp.Lower && this.context.tier === Tier.LowSpec) {
                this.context.transitionTo(new Result(8));
            } else if (this.type === ReturnTemp.Higher && this.context.tier === Tier.HighSpec) {
                this.context.transitionTo(new Result(9));
            } else if (this.type === ReturnTemp.Higher && this.context.tier === Tier.LowSpec) {
                this.context.transitionTo(new Result(10));
            }
        }
    }
    public getProgressBarModel(): IProgressBarModel[] {
        return [
            { isActive: true, name: 'Type of site' },
            { isActive: true, name: 'Question 1' },
            { isActive: true, name: 'Question 2' },
            { isActive: true, name: 'Question 3' },
            { isActive: true, name: 'Question 4' },
            { isActive: false, name: 'Summary' },
        ] as IProgressBarModel[];
    }
    private handleClick(event: any) {
        this.context.isDisable = false;
        this.type = event.currentTarget.value as ReturnTemp;
        this.context.handleRender();
    }
}

export enum ReturnTemp {
    Higher = '“high” temperature systems, above 24°C (75°F)',
    Lower = '“low” temperature systems, below 24°C (75°F)',
}
