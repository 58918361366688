import * as React from "react";
import { EmeraldProgressSpinner } from "@emerald-react/progress-indicator";

type SpinnerProps = {
    showWhiteSpinner?: boolean;
};

export const Spinner: React.FC<SpinnerProps> = ({ showWhiteSpinner }) => (
    <EmeraldProgressSpinner
        determinate={false}
        sizeInRem={2}
        isWhite={showWhiteSpinner}
    />
);
