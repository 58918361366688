// import { loadEngineers } from 'actions/actions-v2/engineer-action-v2';
import {
    loadEngineers,
    loadShift,
    createShift,
    updateShift,
    deleteShift,
    cancelUpsertShift
} from 'actions/actions-v2/shift-actions';

export default {
    loadEngineers,
    loadShift,
    createShift,
    updateShift,
    deleteShift,
    cancelUpsertShift
};
