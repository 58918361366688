import { QuestionSetActions, QuestionSetActionTypes } from 'actions/question-set-actions';
import { loadQuestionSets, deleteQuestionSet, downloadQuestionSet } from 'actions/actions-v2/question-set-actions';

export default {
    loadQuestionSets,
    deleteQuestionSet,
    downloadQuestionSet,
    onSort: (sortColumn: string, sortAscending: boolean): QuestionSetActionTypes => ({
        type: QuestionSetActions.CHANGE_SORT_ORDER_VALUE,
        payload: {
            sortColumn,
            sortAscending
        }
    }),
    onToggleExpanded: (id: string): QuestionSetActionTypes => ({
        type: QuestionSetActions.TOGGLE_ITEM_EXPANDED,
        payload: {
            id
        }
    }),
    onExpandAll:  (values: string[]): QuestionSetActionTypes => ({
        type: QuestionSetActions.EXPAND_ALL,
        payload: {
            values
        }
    }),
    onCollapseAll: (): QuestionSetActionTypes => ({
        type: QuestionSetActions.COLLAPSE_ALL
    })
};
