import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { connect } from 'react-redux';
import ShiftHandoverCategoryTable from './shift-handover-operating-platform-category-table';
import {
    loadShiftHandoverOperatingPlatformCategory,
    createShiftHandoverOperatingPlatformCategory,
    updateShiftHandoverOperatingPlatformCategory,
    deleteShiftHandoverCategory,
    loadSelectedOperatingPlatform,
} from 'actions/actions-v2/shift-handover-actions';

const getSiteId = (state: IRootState) => state.app.siteId;
const getShifthandoverItemCategory = (state: IRootState) =>
    state.shiftHandoverState.shiftHandoverOperatingPlatformCategories;
const getAdminShiftCheckListLoading = (state: IRootState) => state.shiftHandoverState.isLoading;
const getSelectedOperatingPlatform = (state: IRootState) => state.shiftHandoverState.selectedOperatingPlatform;

const mapStateToProps = createSelector(
    getSiteId,
    getShifthandoverItemCategory,
    getAdminShiftCheckListLoading,
    getSelectedOperatingPlatform,
    (siteId, shiftHandoverItemCategories, isLoading, selectedOperatingPlatform) => ({
        siteId,
        shiftHandoverItemCategories,
        isLoading,
        selectedOperatingPlatform,
    })
);

const mapDispatchToProps = {
    load: loadShiftHandoverOperatingPlatformCategory,
    create: createShiftHandoverOperatingPlatformCategory,
    update: updateShiftHandoverOperatingPlatformCategory,
    delete: deleteShiftHandoverCategory,
    loadSelectedOperatingPlatform,
};

export const ShiftHandoverCategoryTableContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ShiftHandoverCategoryTable);
