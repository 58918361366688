import { IProcessStepAnswer } from 'models/cerm/process-step-answer';
import * as React from 'react';

export const useProcessStepAnswer = (
    siteId: string,
    masterProcessStepId: string,
    loadProcessStepAnswer: (
        masterProcessStepId: string,
        siteId: string
    ) => void,
    processStepAnswer: IProcessStepAnswer = null
) => {
    React.useEffect(() => {
        if (
            !processStepAnswer &&
            siteId &&
            masterProcessStepId
        ) {
            loadProcessStepAnswer(
                masterProcessStepId,
                siteId);
        }
    }, [
        processStepAnswer,
        masterProcessStepId,
        siteId,
        loadProcessStepAnswer
    ]);
};

export const useProcessStepOutcome = (
    siteId: string,
    masterProcessStepId: string,
    processStepOutcomeId: string,
    loadProcessStepOutcome: (
        masterProcessStepId: string,
        processStepOutcomeId: string,
        siteId: string,
    ) => void,
    processStepAnswer: IProcessStepAnswer = null
) => {
    React.useEffect(() => {
        if (
            processStepAnswer &&
            masterProcessStepId &&
            processStepOutcomeId &&
            siteId
        ) {
            loadProcessStepOutcome(
                masterProcessStepId,
                processStepOutcomeId,
                siteId
            );
        }
    }, [
        masterProcessStepId,
        processStepOutcomeId,
        siteId,
        loadProcessStepOutcome
    ]);
};