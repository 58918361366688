import React from 'react';
import Page from 'components/v2/page/Page';
import { ConfirmDialog, TabGroup, TabItem } from 'components/v2/components';
import {
    IGlobalMockDrill,
    IUpdateGlobalMockDrill,
    ICreateGlobalMockDrill,
} from 'models/mock-drills';

import { IKeyValue } from 'models';
import { Loader } from 'components/loader';
import { RouteComponentProps } from 'react-router-dom';
import { history } from 'routes/App';
import { ISiteInfo } from 'models/site-profile';
import { useTranslate } from 'translations/translation-utils';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import { IAttachmentQuery } from 'models/attachment/attachment-query';
import { IAttachmentUpload } from 'models/attachment/attachment-upload';
import { GlobalMockDrillAttachmentTabContainer } from './tabs/attachment/global-mock-drill-attachments-tab-container';
import { GlobalMockDrillDetailsTabContainer } from './tabs/details/global-mockdrill-details-tab-container';

interface IRouteParams {
    id: string;
    tab: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
    isLoading: boolean;
    isSaving: boolean;
    isLocked: boolean;
    clients: Array<IKeyValue<string>>;
    availableSites: ISiteInfo[];
    globalMockDrill: IGlobalMockDrill;
    attachments: IAttachmentModel[];
    loadGlobalMockDrill: (id: string) => void;
    createGlobalMockDrill: (model: ICreateGlobalMockDrill) => void;
    updateGlobalMockDrill: (model: IUpdateGlobalMockDrill) => void;
    cleanupGlobalMockDrill: () => void;
    loadAttachments: (query: IAttachmentQuery) => void;
    uploadAttachment: (upload: IAttachmentUpload) => void;
}

enum Tab {
    details = 'details',
    attachments = 'attachments',
    history = 'history',
}
export const UpsertGlobalMockDrillPage: React.FC<IProps> = ({
    match,
    isLoading,
    isLocked,
    globalMockDrill,
    cleanupGlobalMockDrill,
    loadGlobalMockDrill,
}) => {
    const translate = useTranslate();
    const globalMockDrillId = match.params.id;
    const globalMockDrillsPageUrl = '/GlobalMockDrills/';
    const currentTab = match.params.tab.toLowerCase();
    const customLinks: Array<IKeyValue<string>> = [
        { key: globalMockDrillsPageUrl, value: translate('GlobalMockDrills.Title.2') },
    ];
    const [showTabDisabledDialog, setShowTabDisabledDialog] = React.useState(false);

    React.useEffect(() => {
        cleanupGlobalMockDrill();
    }, []);
    React.useEffect(() => {
        if (!globalMockDrillId) {
            return;
        }
        loadGlobalMockDrill(globalMockDrillId);
    }, [globalMockDrillId]);

    const shouldDisplayForm = (globalMockDrillId && globalMockDrill) || !globalMockDrillId;

    const renderTab = () => {
        switch (currentTab) {
            case Tab.details:
                return <GlobalMockDrillDetailsTabContainer />;
            case Tab.attachments:
                return <GlobalMockDrillAttachmentTabContainer id={globalMockDrillId} />;
        }
    };

    const setNavLink = (selectedTab: Tab) => {
        if (globalMockDrillId) {
            return match.path.replace(':id?', globalMockDrillId).replace(':tab', selectedTab);
        }

        return match.path.replace(':id?/', '').replace(':tab', selectedTab);
    };
    const ismockdrillIdAvailable = () => {
        return globalMockDrillId && globalMockDrillId.length > 1 ? true : false;
    };
    const handleClick = (event: any, tab: Tab) => {
        event.preventDefault();
        if (!ismockdrillIdAvailable()) {
            setShowTabDisabledDialog(true);
            return;
        }

        history.push(setNavLink(tab));
    };

    return (
        <Page
            title={`${globalMockDrillId ? translate('ShiftHandoversAdminPage.label.Edit') : translate('FacilityPopup.Button.Add')} ${translate('GlobalMockDrills.Title.3')}`}
            breadcrumbCustomLinks={customLinks}
            isAdminPage={true}
            className="global-mock-drills-page"
        >
            {isLocked ? (
                <p>
                    {translate('GlobalMockDrills.Title.4')}
                </p>
            ) : (
                shouldDisplayForm && (
                    <>
                        <Loader loading={isLoading}>
                            <TabGroup>
                                <TabItem
                                    title={translate('ActionUpsertPage.Tabs.Details')}
                                    tab={Tab.details}
                                    currentTab={currentTab}
                                    onClick={handleClick}
                                />

                                <TabItem
                                    title={translate('ActionUpsertPage.Tabs.Attachments')}
                                    tab={Tab.attachments}
                                    currentTab={currentTab}
                                    onClick={handleClick}
                                />
                            </TabGroup>
                            {renderTab()}

                            <ConfirmDialog
                                title={translate('ConfirmDialog.Title')}
                                message={translate(
                                    'MockDrills.GlobalUpsertPage.ConfirmDialog.Message'
                                )}
                                buttonCancelHide={true}
                                buttonConfirmText={translate('ConfirmDialog.ButtonConfirmText')}
                                isVisible={showTabDisabledDialog}
                                onConfirm={() => setShowTabDisabledDialog(false)}
                                onOutsideDialogClick={() => setShowTabDisabledDialog(false)}
                            />
                        </Loader>
                    </>
                )
            )}
        </Page>
    );
};
