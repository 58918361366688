import React from 'react';
import Page from '../../../components/v2/page/Page';
import useTranslate from 'translations/translation-utils';
import { DetailedHallReportsHeaderContainer } from './detailed-hall-report-header-container';
import { RomonetIframeContainer } from '../romonet-iframe/romonet-iframe-container';
import { RomonetReportType } from 'models/romonet/romonet-page';

const DetailedHallReport: React.FC = () => {
    const translate = useTranslate();
    return (
        <Page title="" className="data-reports">
            <DetailedHallReportsHeaderContainer
                title={translate('Romonet.DetailedHallReport.Title')}
                romonetReportType={RomonetReportType.DetailReport}
            />
            <div className="container">
                <RomonetIframeContainer />
            </div>
        </Page>
    );
};

export default DetailedHallReport;
