import { combineEpics, Epic, ofType } from 'redux-observable';
import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax';
import { catchError, flatMap, map, mergeMap, switchMap } from 'rxjs/operators';

// tslint:disable-next-line: max-line-length
import {
    ISaveSitesFeatures,
    ISaveSitesFeaturesFulfilled,
    SiteFeatureActions,
    SiteFeatureActionTypes,
} from './../actions/site-feature';
import { ISiteFeatureState } from './../reducers/site-features';
import { getErrorActions$, IResponse as IErrorResult } from './epic-helpers';
import { getApiHeaders } from './shared-header';

import appConfig from 'helpers/config-helper';

const config = appConfig();

const apiUrl = config.REACT_APP_BASE_API;

const onLoadSitesFeaturesRequestEpic: Epic<
    SiteFeatureActionTypes,
    SiteFeatureActionTypes,
    ISiteFeatureState
> = (action$) =>
    action$.pipe(
        ofType(SiteFeatureActions.LOAD_SITE_FEATURES),
        map(() => `${apiUrl}/Api/SiteFeature?`),
        mergeMap(
            // TODO: Cancel previous load first
            (url) =>
                ajax.getJSON(url, getApiHeaders()).pipe(
                    map<any, SiteFeatureActionTypes>((response) => ({
                        type: SiteFeatureActions.LOAD_SITE_FEATURES_FULFILLED,
                        payload: {
                            sites: response,
                        },
                    })),
                    catchError<any, any>((error: AjaxError) =>
                        getErrorActions$('Load sites features')(
                            SiteFeatureActions.LOAD_SITE_FEATURES_REJECTED,
                            error,
                            {
                                errorMessage: 'Unable to load sites features',
                            }
                        )
                    )
                )
        )
    );

const onSaveSitesFeatures: Epic<
    SiteFeatureActionTypes,
    SiteFeatureActionTypes,
    ISiteFeatureState
> = (action$) =>
    action$.pipe(
        ofType(SiteFeatureActions.SAVE_SITE_FEATURES),
        switchMap((action: ISaveSitesFeatures) =>
            ajax
                .post(`${apiUrl}/api/SiteFeature/Update`, action.payload.sites, getApiHeaders())
                .pipe(
                    flatMap<AjaxResponse, ISaveSitesFeaturesFulfilled | any>(() => [
                        {
                            type: SiteFeatureActions.SAVE_SITE_FEATURES_FULFILLED,
                            payload: {
                                person: action.payload.sites,
                            },
                        },
                        {
                            type: SiteFeatureActions.LOAD_SITE_FEATURES,
                        },
                    ]),
                    catchError<any, any>((error: IErrorResult) =>
                        getErrorActions$('Update sites features')(
                            SiteFeatureActions.SAVE_SITE_FEATURES_REJECTED,
                            error,
                            {
                                errorMessage: JSON.stringify(error.response),
                            }
                        )
                    )
                )
        )
    );

export const siteFeaturesEpics = combineEpics(onLoadSitesFeaturesRequestEpic, onSaveSitesFeatures);
