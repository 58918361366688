import * as React from 'react';
import { SortOrder, TableHeader } from './TableHeader';
import { usePageUIPreferences, UiPreferences } from 'utils/page-ui-preferences-utils';

export interface ITableHeader {
    title: string | JSX.Element;
    sortField?: string;
    className?: string;
}

interface IProps {
    headers: ITableHeader[];
    sortFieldUiPreference: UiPreferences;
    sortOrderUiPreference: UiPreferences;
    defaultSortField?: string;
    defaultSortOrder?: SortOrder;
    onToggle: (field: string, sortState: SortOrder) => void;
}

export const TableHeaders: React.FC<IProps> = ({
    headers,
    sortFieldUiPreference,
    sortOrderUiPreference,
    defaultSortField = null,
    defaultSortOrder = SortOrder.off,
    onToggle
}) => {
    const [sortField, setSortField] = usePageUIPreferences(sortFieldUiPreference, defaultSortField);
    const [sortOrder, setSortOrder] = usePageUIPreferences(sortOrderUiPreference, defaultSortOrder);

    const onToggleHandler = (field: string) => {
        if (sortField !== field) {
            setSortField(field);
            setSortOrder(SortOrder.asc);
            onToggle(field, SortOrder.asc);
            return;
        }

        const sort = sortOrder === SortOrder.asc ? SortOrder.desc : SortOrder.asc;
        setSortOrder(sort);
        onToggle(field, sort);
    };

    const getSortState = (field: string): SortOrder =>
        field === sortField ? sortOrder : SortOrder.off;

    return (
        <thead>
            <tr>
                {headers.map((header, key) =>
                    header.sortField ? (
                        <TableHeader
                            key={key}
                            className={header.className}
                            sortState={getSortState(header.sortField)}
                            onToggle={() => onToggleHandler(header.sortField)}
                        >
                            {header.title}
                        </TableHeader>
                    ) : (
                        header.title && <th className={header.className}>{header.title}</th>
                    )
                )}
            </tr>
        </thead>
    );
};
