import React from 'react';
import { history } from 'routes/App';

interface IProps {
    siteId: string;
    redirectUrl: string;
}

export const AutoRedirectOnSiteChanged: React.FC<IProps> = ({ siteId, redirectUrl }) => {
    const refSiteId = React.useRef<string>(null);

    React.useEffect(() => {
        redirectIfSiteHasChanged();
    }, [siteId]);

    const redirectIfSiteHasChanged = () => {
        if (!refSiteId.current) {
            refSiteId.current = siteId;
        }

        if (siteId !== refSiteId.current) {
            refSiteId.current = siteId;
            history.push(redirectUrl);
        }
    };

    return null;
};
