import { combineReducers } from 'redux';
import {
    CreateQuestionSetActions,
    CreateQuestionSetActionTypes,
} from '../../actions/create-question-set-actions';
import { IKeyValue } from '../../models/key-value';
import { IQuestionSet, IQuestion } from '../../models/question-set';
import { IBaseState } from '../base-state';
import { QuestionSetActions } from 'actions/actions-v2/question-set-actions';
import { IAppAction } from 'actions/app-action';
import { isEmpty } from 'lodash';

export interface ICreateQuestionSetRootState extends IBaseState {
    editQuestionSet: IQuestionSetsState;
}

export interface IQuestionSetsState {
    categories: Array<IKeyValue<string>>;
    urgencies: Array<IKeyValue<string>>;
    questionSetPriorities: Array<IKeyValue<string>>;
    actionPriorities: Array<IKeyValue<string>>;
    questionSet: Partial<IQuestionSet>;
    isSubmitting: boolean;
    isInitialising: boolean;
}

export const emptyQuestion: IQuestion = {
    id: '',
    title: '',
    instructions: '',
    risk: {},
    action: {},
    hasRisk: 'false',
    compliantResponse: 'false',
};

export const INITIAL_STATE: IQuestionSetsState = {
    categories: [],
    urgencies: [],
    questionSetPriorities: [],
    actionPriorities: [],
    questionSet: {
        title: '',
        category: '',
        urgency: '',
        relevancyQuestion: '',
        questions: [emptyQuestion],
    },
    isInitialising: false,
    isSubmitting: false,
};

const categories = (state = INITIAL_STATE.categories, action: CreateQuestionSetActionTypes) => {
    switch (action.type) {
        case CreateQuestionSetActions.LOAD_PAGE_FULFILLED:
            return action.payload.editQuestionSet.categories;
        default:
            return state;
    }
};

const urgencies = (state = INITIAL_STATE.urgencies, action: CreateQuestionSetActionTypes) => {
    switch (action.type) {
        case CreateQuestionSetActions.LOAD_PAGE_FULFILLED:
            return action.payload.editQuestionSet.urgencies;
        default:
            return state;
    }
};

const questionSetPriorities = (
    state = INITIAL_STATE.questionSetPriorities,
    action: CreateQuestionSetActionTypes
) => {
    switch (action.type) {
        case CreateQuestionSetActions.LOAD_PAGE_FULFILLED:
            return action.payload.editQuestionSet.questionSetPriorities;
        default:
            return state;
    }
};

const actionPriorities = (
    state = INITIAL_STATE.actionPriorities,
    action: CreateQuestionSetActionTypes
) => {
    switch (action.type) {
        case CreateQuestionSetActions.LOAD_PAGE_FULFILLED:
            return action.payload.editQuestionSet.actionPriorities;
        default:
            return state;
    }
};

export const questionSet = (state = INITIAL_STATE.questionSet, action: IAppAction) => {
    switch (action.type) {
        case QuestionSetActions.LOAD_FULFILLED:
            if (action.payload) {
                return {
                    ...action.payload,
                    questions:
                        action.payload && !isEmpty(action.payload.questions)
                            ? action.payload.questions.map((q) => ({
                                  ...q,
                                  hasRisk: q.hasRisk,
                              }))
                            : [{ hasRisk: false }],
                };
            }

            return state;

        case CreateQuestionSetActions.LOAD_PAGE_FULFILLED:
            const question = { ...action.payload.editQuestionSet.questionSet };
            if (!question.id) {
                return INITIAL_STATE.questionSet;
            }
            return {
                ...question,
                questions:
                    question && question.questions && question.questions.length > 0
                        ? question.questions.map((q) => ({
                              ...q,
                              hasRisk: q.hasRisk,
                          }))
                        : [{ hasRisk: false }],
            };

        case CreateQuestionSetActions.UPDATE_QUESTION_SET_FULFILLED:
            return action.payload.questionSet;

        case QuestionSetActions.CLEANUP:
            return INITIAL_STATE.questionSet;

        default:
            return state;
    }
};

const isSubmitting = (state = INITIAL_STATE.isSubmitting, action: IAppAction) => {
    switch (action.type) {
        case QuestionSetActions.CREATE:
        case QuestionSetActions.UPDATE:
        case CreateQuestionSetActions.UPDATE_QUESTION_SET:
            return true;

        case QuestionSetActions.CREATE_FULFILLED:
        case QuestionSetActions.CREATE_REJECTED:
        case QuestionSetActions.UPDATE_FULFILLED:
        case QuestionSetActions.UPDATE_REJECTED:
        case CreateQuestionSetActions.UPDATE_QUESTION_SET_FULFILLED:
            return false;

        default:
            return state;
    }
};

const isInitialising = (state = INITIAL_STATE.isInitialising, action: IAppAction) => {
    switch (action.type) {
        case QuestionSetActions.LOAD:
            return true;

        case QuestionSetActions.LOAD_FULFILLED:
        case QuestionSetActions.LOAD_REJECTED:
        case CreateQuestionSetActions.LOAD_PAGE_FULFILLED:
            return false;

        default:
            return state;
    }
};

export const reducer = combineReducers<IQuestionSetsState>({
    categories,
    urgencies,
    isInitialising,
    questionSetPriorities,
    actionPriorities,
    questionSet,
    isSubmitting,
});
