import { createIntl, useIntl } from 'react-intl';
import messages from 'translations/messages';
import { languages } from 'translations/languages';
import { flatten } from 'flat';

export const translationApiRegex = /API(\.[a-zA-Z0-9]+)*/g;

export const useTranslate = () => {
    const intl = useIntl();

    const getTranslation = (key: string, concatKey: string = '') => {
        return translate(intl, key, concatKey);
    };

    return getTranslation;
};

export const translate = (intl: any, key: string, concatKey: string = ''): string => {
    if (concatKey == null) {
        return '';
    }

    const originalConcatKey = concatKey;
    const combinedKey = key.concat(concatKey.replace(/\s/g, ''));

    try {
        if (combinedKey.match(translationApiRegex) === null) {
            return intl.formatMessage(messages[combinedKey]);
        }

        return combinedKey.replace(translationApiRegex,
            apiStr => intl.formatMessage(messages[apiStr]));
    } catch {
        return originalConcatKey;
    }
};

export const getIntl = (languageCode?: string) => {
    const _locale = languageCode !== undefined
        && languageCode !== null
        && languageCode !== ''
        ? languageCode
        : 'en-GB';
    const foundLanguage = languages.find(l => l.key === _locale);
    const defaultLanguage = languages.find(l => l.key === "en-GB");
    const msg = foundLanguage
        ? foundLanguage.data
        : (defaultLanguage as any).data;

    return createIntl({
        locale: 'en',
        messages: flatten(msg) as any
    })
};

export default useTranslate;
