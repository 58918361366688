import { LOCATION_CHANGE } from "redux-first-history";
import { combineReducers } from 'redux';

import { SiteRelationshipsActions, SiteRelationshipsActionTypes } from 'actions/site-relationships-actions';
import { IBuilding } from 'models/building';

export interface IBuildingSelectorState {
    selectedBuilding: IBuilding;
    searchValue: string;
    buildings: IBuilding[];

    isLoading: boolean;
    isSubmitting: boolean;
}

export const INITIAL_STATE: IBuildingSelectorState = {
    selectedBuilding: null,
    searchValue: '',
    buildings: [],
    isLoading: false,
    isSubmitting: false
};

const selectedBuilding = (
    state: IBuildingSelectorState['selectedBuilding'] = INITIAL_STATE.selectedBuilding,
    action: SiteRelationshipsActionTypes
) => {
    switch (action.type) {
        case SiteRelationshipsActions.SELECT_BUILDING:
            return action.payload.building;

        case LOCATION_CHANGE:
        case SiteRelationshipsActions.CHANGE_SITE_SEARCH_VALUE:
        case SiteRelationshipsActions.CHANGE_BUILDING_SEARCH_VALUE:
            return null;

        default:
            return state;
    }
};

const searchValue = (
    state: IBuildingSelectorState['searchValue'] = INITIAL_STATE.searchValue,
    action: SiteRelationshipsActionTypes
) => {
    switch (action.type) {
        case SiteRelationshipsActions.CHANGE_BUILDING_SEARCH_VALUE:
            return action.payload.value;

        case SiteRelationshipsActions.SELECT_BUILDING:
            return action.payload.building.buildingName;

        case LOCATION_CHANGE:
        case SiteRelationshipsActions.LINK_BUILDING_FULFILLED:
            return '';

        default:
            return state;
    }
};

const buildings = (
    state: IBuildingSelectorState['buildings'] = INITIAL_STATE.buildings,
    action: SiteRelationshipsActionTypes
) => {
    switch (action.type) {
        case LOCATION_CHANGE:
        case SiteRelationshipsActions.CHANGE_SITE_SEARCH_VALUE:
        case SiteRelationshipsActions.CHANGE_BUILDING_SEARCH_VALUE:
        case SiteRelationshipsActions.LOAD_BUILDING_SEARCH_RESULTS:
            return [];

        case SiteRelationshipsActions.LOAD_BUILDING_SEARCH_RESULTS_FULFILLED:
            return action.payload.buildings;

        default:
            return state;
    }
};

const isLoading = (
    state: IBuildingSelectorState['isLoading'] = INITIAL_STATE.isLoading,
    action: SiteRelationshipsActionTypes
) => {
    switch (action.type) {
        case SiteRelationshipsActions.LOAD_BUILDING_SEARCH_RESULTS:
            return true;

        case LOCATION_CHANGE:
        case SiteRelationshipsActions.CHANGE_SITE_SEARCH_VALUE:
        case SiteRelationshipsActions.CHANGE_BUILDING_SEARCH_VALUE:
        case SiteRelationshipsActions.LOAD_BUILDING_SEARCH_RESULTS_FULFILLED:
        case SiteRelationshipsActions.LOAD_BUILDING_SEARCH_RESULTS_CANCELLED:
        case SiteRelationshipsActions.LOAD_BUILDING_SEARCH_RESULTS_REJECTED:
            return false;

        default:
            return state;
    }
};

const isSubmitting = (
    state: IBuildingSelectorState['isSubmitting'] = INITIAL_STATE.isSubmitting,
    action: SiteRelationshipsActionTypes
) => {
    switch (action.type) {
        case SiteRelationshipsActions.LINK_BUILDING:
            return true;

        case SiteRelationshipsActions.LINK_BUILDING_CANCELLED:
        case SiteRelationshipsActions.LINK_BUILDING_FULFILLED:
        case SiteRelationshipsActions.LINK_BUILDING_REJECTED:
            return false;

        default:
            return state;
    }
};

export const reducer = combineReducers<IBuildingSelectorState>({
    selectedBuilding,
    searchValue,
    buildings,
    isLoading,
    isSubmitting
});
