import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { SiteForm as View } from 'routes/upsert-site/views/site-form/site-form';
import actions from './actions';
import selector from './selector';

export const SiteForm = withRouter(connect(
    selector,
    actions
)(View));
