import { createSelector } from 'reselect';
import { IRootClientsState } from '../../../reducers/clients';

const getPermissions = (state: IRootClientsState) => state.app.permissions;
const getClientsState = (state: IRootClientsState) => state.clients;

const getIsInitialising = (state: IRootClientsState) => state.clients.isInitialising;

export default createSelector(
    getClientsState,
    getPermissions,
    getIsInitialising,
    (state, permissions, isInitialising) => ({
        ...state,
        permissions,
        isInitialising
    })
);
