import { IRootState } from '../store';
import { connect } from 'react-redux';
import { Dispatch } from 'react';
import { IAppAction } from 'actions/app-action';
import { createSelector } from 'reselect';
import {
    loadRiskProfileQuestionnaire,
    updateRiskProfileQuestionnaire,
    exportRiskProfileQuestionnaire,
    exportAllRiskProfileQuestionnaire
} from 'actions/actions-v2';
import { RiskProfileQuestionnairePage } from './risk-profile-questionnaire-page';
import { getCurrentSiteName } from '../competency-dna-process/competency-dna-process-container';
import { getCurrentSiteId } from 'helpers/helpers';
import { IRiskProfileQuestionnaire } from 'models';
import { withRouter } from 'react-router';

const getRiskProfileQuestionnaire = (state: IRootState) =>
    state.riskProfileQuestionnaireState.riskProfileQuestionnaire;
const getIsLoading = (state: IRootState) =>
    state.riskProfileQuestionnaireState.isLoading;
const getRiskProfileCategories = (state: IRootState) =>
    state.app.riskProfileCategories;
const getIsExporting = (state: IRootState) =>
    state.riskProfileQuestionnaireState.isExporting;
const getSiteLookup = (state: IRootState) => state.app.lookupSites;

const mapStateToProps = createSelector(
    getRiskProfileQuestionnaire,
    getRiskProfileCategories,
    getIsLoading,
    getCurrentSiteId,
    getCurrentSiteName,
    getIsExporting,
    getSiteLookup,
    (
        riskProfileQuestionnaire,
        riskProfileCategories,
        isLoading,
        siteId,
        siteName,
        isExporting,
        lookupSites
    ) => ({
        riskProfileQuestionnaire,
        riskProfileCategories,
        isLoading,
        siteId,
        siteName,
        isExporting,
        lookupSites
    })
);

const mapDispatchToProps = (dispatch: Dispatch<IAppAction>) => {
    return {
        loadQuestionnaire(siteId: string, operatingPlatform: string) {
            dispatch(loadRiskProfileQuestionnaire(siteId, operatingPlatform));
        },
        saveQuestionnaire(questionnaire: IRiskProfileQuestionnaire) {
            dispatch(updateRiskProfileQuestionnaire(questionnaire));
        },
        exportQuestionnaire(siteId: string, operatingPlatform: string) {
            dispatch(exportRiskProfileQuestionnaire(siteId, operatingPlatform));
        },
        exportAllQuestionnaire(operatingPlatform: string) {
            dispatch(exportAllRiskProfileQuestionnaire(operatingPlatform));
        }
    };
};

export const RiskProfileQuestionnaireContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(RiskProfileQuestionnairePage));
