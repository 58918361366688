import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { loadCermProgressChartData } from 'actions/actions-v2/cerm-assessment-action-v3';
import { CermAssessmentOverviewTab } from './cerm-assessment-overview-tab';

const getSiteId = (state: IRootState) => state.app.siteId;
const getChartData = (state: IRootState) => state.cermAssessmentResultState.cermProgressChartData;
const getLookupSites = (state: IRootState) => state.app.lookupSites;

const mapStateToProps = createSelector(
    getSiteId,
    getChartData,
    getLookupSites,
    (siteId, chartData, lookupSites) => ({
        siteId,
        chartData,
        lookupSites
    }));

const mapDispatchToProps = {
    getChartData: loadCermProgressChartData
};

export const CermAssessmentOverviewTabContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CermAssessmentOverviewTab);
