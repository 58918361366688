import * as React from 'react';

interface IProps {
    type: 'info' | 'warning' | 'error';
    title?: string;
    showIcon?: boolean;
}

export const Alert: React.FC<React.PropsWithChildren<IProps>> = ({ type, title, showIcon = true, ...props }) => {
    return (
        <div className={`alert alert-${type}`}>
            {showIcon && <i>Info</i>}
            <div className="alert-content">
                {title && <h2>{title}</h2>}
                {props.children}
            </div>
        </div>
    );
};
