export class PropertyHelper {
  public static ReduceProperty<T>(Value?: T, PropertyPath?: string): any {
    let o: any = Value;

    if (PropertyPath) {
      PropertyPath = PropertyPath.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
      PropertyPath = PropertyPath.replace(/^\./, ''); // strip a leading dot
      const a = PropertyPath.split('.').filter((r) => r);
      for (let i = 0, n = a.length; i < n; ++i) {
        const k = a[i];
        if (o && k in o) {
          o = o[k];
        } else {
          return;
        }
      }
    }
    return o;
  }

  public static SetProperty<T>(
    Value?: T,
    PropertyPath?: string,
    NewValue?: any
  ): void {
    if (PropertyPath) {
      let o: any = Value;
      PropertyPath = PropertyPath.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
      PropertyPath = PropertyPath.replace(/^\./, ''); // strip a leading dot
      const a = PropertyPath.split('.').filter((r) => r);
      for (let i = 0, n = a.length - 1; i < n; ++i) {
        const k = a[i];
        if (k in o) {
          o = o[k];
        } else {
          return;
        }
      }
      if (a.length > 0) {
        if (!o) {
          o = {} as T;
        }
        o[a[a.length - 1]] = NewValue;
      } else {
        o = NewValue;
      }
    }
  }
}
