import { PropertyHelper } from '../property-helper/property-helper';
import { ILabelAccessor } from './label-accessor';
import { IValueAccessor } from './value-accessor';

export class DataBoundItem<T> implements IValueAccessor<T>, ILabelAccessor {
  valueAccessor?: string;
  value: string | number | readonly string[];
  labelAccessor?: string;
  label?: string;
  disabled?: boolean;
  checked?: boolean;
  protected originalValue: T;
  constructor(Data: T) {
    this.label = (Data as any).toString();
    this.value = Data as any;
    this.originalValue = Data;
  }

  static InitList<T>(Items: Array<T>): Array<DataBoundItem<T>> {
    const newList: Array<DataBoundItem<T>> = new Array<DataBoundItem<T>>();
    Items.forEach((r) => {
      const item: DataBoundItem<T> = new DataBoundItem<T>(r);
      newList.push(item);
    });
    return newList;
  }

  static IsObjectList<T>(Items: Array<T>) {
    const cast = Items.find((r) => true) as any as DataBoundItem<T>;
    return typeof cast === 'object';
  }

  loadValueToProperty(
    property: keyof this,
    parentValueAccessor: string | undefined,
    reload = false
  ) {
    if (this[property] === undefined || reload) {
      if (this.valueAccessor) {
        this[property] = PropertyHelper.ReduceProperty(
          this.originalValue,
          this.valueAccessor
        );
      } else if (parentValueAccessor) {
        this[property] = PropertyHelper.ReduceProperty(
          this.originalValue,
          parentValueAccessor
        );
      }
    }
  }

  loadValue(parentValueAccessor: string, reload = false): void {
    this.loadValueToProperty('value', parentValueAccessor, reload);
  }

  updateValueToProperty(Value: any, Property: keyof this & string) {
    PropertyHelper.SetProperty(this.originalValue, Property, Value);
  }

  updateValue(Value: T | null, ParentValueAccessor: string): void {
    if (this.valueAccessor) {
      this.updateValueToProperty(
        Value,
        this.valueAccessor as keyof this & string
      );
    } else if (ParentValueAccessor) {
      this.updateValueToProperty(
        Value,
        ParentValueAccessor as keyof this & string
      );
    }
    this.loadValue(ParentValueAccessor, true);
  }

  loadLabel(parentLabelAccessor: string, reload = false): void {
    this.loadValueToProperty('label', parentLabelAccessor, reload);
  }
}
