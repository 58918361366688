export const cermAssesmentEs = `
<div>
<p>Haga clic en el enlace de abajo para ver:</p>
<p><a href = "https://confluence.cbre.com/pages/viewpage.action?pageId=109992258" target= "_blank">Proceso de Evaluación CERM</a><p>
</div>
<br />
<div>
<p><a href="https://quantum.cbre.com/issues" target= "_blank">Reportar un problema</a></p>
<p><a href="https://quantum.cbre.com/requests" target= "_blank">Levantar una solicitud</a></p>
</div>`;

export const hermAssesmentEs = `
<div>
<p>Haga clic en el enlace de abajo para ver:</p>
<p><a href = "https://confluence.cbre.com/pages/viewpage.action?pageId=109992258" target= "_blank">Proceso de evaluación HERMs</a><p>
</div>
<br />
<div>
<p><a href="https://quantum.cbre.com/issues" target= "_blank">Reportar un problema</a></p>
<p><a href="https://quantum.cbre.com/requests" target= "_blank">Levantar una solicitud</a></p>
</div>`;
