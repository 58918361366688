import * as React from 'react';
import { connect } from 'react-redux';
import { IEngineer } from 'models';
import { IDifference } from 'models/difference';
import { EngineerEntityHistory } from 'components/engineer-history/engineer-entity-history';
import selectors from './selectors';
import actions from './actions';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';

interface IProps {
    engineer: IEngineer;
    isLoading: boolean;
    engineerHistory: IDifference[][];
    loadHistory: (id: string) => void;
    intl: any;
}

class HistoryTab extends React.PureComponent<IProps> {

    public componentDidMount() {
        this.props.loadHistory(this.props.engineer.id);
    }

    public render() {
        return (
            <div className="section">
                <div className="column is-12">
                    <div className="title is-4 section-title">
                        {translate(this.props.intl, 'RiskRegisterUpsertPage.Tabs.History')}
                    </div>
                    <EngineerEntityHistory entity={this.props.engineer} entityHistory={this.props.engineerHistory} />
                </div>
            </div>
        );
    }
}

export default connect(selectors, actions)(injectIntl(HistoryTab));
