export enum Privilege {
    ClientDomain = 'ClientDomain',
    AllAttachmentsUpload = 'AllAttachmentsUpload',
    AllAttachmentsDelete = 'AllAttachmentsDelete',
    ActionView = 'ActionView',
    ClientView = 'ClientView',
    CDNAUpdate = 'CDNAUpdate',
    CDNAView = 'CDNAView',
    CompetencyDNAProcess = 'CompetencyDNAProcess',
    ControlCentre = 'ControlCentre',
    CriticalSystems = 'CriticalSystems',
    CriticalSystemsView = 'CriticalSystemsView',
    ProcessUpdate = 'ProcessUpdate',
    ManagePeople = 'ManagePeople',
    ManageRoles = 'ManageRoles',
    RiskQuestionsOverviewUpdate = 'RiskQuestionsOverviewUpdate',
    PeopleCBREView = 'PeopleCBREView',
    PeopleCBRECreate = 'PeopleCBRECreate',
    PeopleCBREEdit = 'PeopleCBREEdit',
    RiskClientFacingUpdate = 'RiskClientFacingUpdate',
    RiskProfileQuestionnaire = 'RiskProfileQuestionnaire',
    RiskView = 'RiskView',
    IncidentCreate = 'IncidentCreate',
    IncidentUpdate = 'IncidentUpdate',
    IncidentView = 'IncidentView',
    SendManualNotifications = 'SendManualNotifications',
    SiteFeatures = 'SiteFeatures',
    SiteRolesEdit = 'SiteRolesEdit',
    SiteView = 'SiteView',
    SiteUpdate = 'SiteUpdate',
    ShiftHandover = 'ShiftHandover',
    ShiftHandoverRead = 'ShiftHandoverRead',
    ShiftHandoverDelete = 'ShiftHandoverDelete',
    ShiftHandoverAdmin = 'ShiftHandoverAdmin',
    ShiftHandoverAdminUpdate = 'ShiftHandoverAdminUpdate',
    AllCommentUpdate = 'AllCommentUpdate',
    ClientRiskRegister = 'ClientRiskRegister',
    GlobalOverview = 'GlobalOverview',
    CermAssessmentExport = 'CermAssessmentExport',
    GlobalMockDrills = 'GlobalMockDrills',
    GlobalMockDrillsAddToSites = 'GlobalMockDrillsAddToSites',
    GlobalMockDrillsScenarioDelete = 'GlobalMockDrillsScenarioDelete',
    MockDrillsRead = 'MockDrillsRead',
    MockDrillsReadWrite = 'MockDrillsReadWrite',
    MockDrillsLocalReportDelete = 'MockDrillsLocalReportDelete',
    MockDrillsLocalScenarioDelete = 'MockDrillsLocalScenarioDelete',
    MockDrillsParticipants = 'MockDrillsParticipants',
    NotRequired = 'NotRequired',
    DemoSite = 'DemoSite',
    Romonet = 'Romonet',
    CDNAReportAllProfessionalsExport = 'CDNAReportAllProfessionalsExport',
    CermAssessmentApplicabilityUpdate = 'CermAssessmentApplicabilityUpdate',
    CermAssessmentExpertReviewFinish = 'CermAssessmentExpertReviewFinish',
    CermAssessmentApplicabilityAccountLevelUpdate = 'CermAssessmentApplicabilityAccountLevelUpdate',
    CermAssessmentOutcomeUpdate = "CermAssessmentOutcomeUpdate",
    CermProcessOwnersDownloadAccessibility = 'CermProcessOwnersDownloadAccessibility',
    LogsUpload = 'LogsUpload',
    BulkAssignmentUpdate = 'BulkAssignmentUpdate',
    PowerBi = 'PowerBi',
    RomonetEngineeringReportAccess = 'RomonetEngineeringReportAccess',
    SiteCreate = 'SiteCreate',
    ManageOpportunity = 'ManageOpportunity',
    SystemIntegrations = 'SystemIntegrations',
    CompetencyDNAProcessCreate = 'CompetencyDNAProcessCreate',
    CompetencyDNACategorySkillHideShow = 'CompetencyDNACategorySkillHideShow',
    CompetencyDNASkillYesNo = 'CompetencyDNASkillYesNo',
    CompetencyDNAAccountSkill = 'CompetencyDNAAccountSkill',
    CermAssessmentExpertReviewCreate = 'CermAssessmentExpertReviewCreate',
    UserManagementPrivilegeEdit = 'UserManagementPrivilegeEdit',
    UploadUser = 'UploadUser',
    EditLocalMockDrillReportTitle = 'EditLocalMockDrillReportTitle',
    UserManagementPrivilegeView = 'UserManagementPrivilegeView',
    UserManagementRoleView = 'UserManagementRoleView',
    OpportunityQuestionnaireUpdate = 'OpportunityQuestionnaireUpdate',
    CalendarUpdate = 'CalendarUpdate',
    ReportTableau = 'ReportTableau',
    IAuditorClientTokenUpdate = 'IAuditorClientTokenUpdate',
    IAuditorView = 'IAuditorView',
    RiskProfileMasterQuestionnaire = 'RiskProfileMasterQuestionnaire',
    SiteImageUpload = 'SiteImageUpload',
    CermAssessmentUpdate = 'CermAssessmentUpdate',
    RiskAddApprover = 'RiskAddApprover',
    ActionAutoFieldUpdate = 'ActionAutoFieldUpdate',
    RiskAutoFieldUpdate = 'RiskAutoFieldUpdate',
    AllFAQsUpdate = 'AllFAQsUpdate',
    ClientDelete = 'ClientDelete',
    SitesAddClientProxy = 'SitesAddClientProxy',
    ProcessArchive = 'ProcessArchive',
    CDNADcProfessionalsApplicability = 'CDNADcProfessionalsApplicability',
    RiskMacroLevelUpdate = 'RiskMacroLevelUpdate',
    RiskReset = 'RiskReset',
    OperatingPlatformUpdate = 'OperatingPlatformUpdate',
    OperatingPlatformDelete = 'OperatingPlatformDelete',
    CompetencyDNAProcessAdminCreate = 'GlobalCompetencyDNAProcessesCreate',
    CompetencyDNAProcessAdminUpdate = 'GlobalCompetencyDNAProcessesUpdate',
    CompetencyDNAProcessAdminDelete = 'GlobalCompetencyDNAProcessesDelete',
    CompetencyDNAProcessAdminView = 'GlobalCompetencyDNAProcessesView',
}