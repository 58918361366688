import { IAssetQuery } from 'models/asset/asset-query';
import { IAppAction } from './app-action';

export enum AssetActions {
    LOAD_ASSET_MANUFACTURER = '@@asset/LOAD_ASSET_MANUFACTURER',
    LOAD_ASSET_MANUFACTURER_FULFILLED = '@@asset/LOAD_ASSET_MANUFACTURER_FULFILLED',
    LOAD_ASSET_MANUFACTURER_REJECTED = '@@asset/LOAD_ASSET_MANUFACTURER_REJECTED',

    LOAD_ASSET_MODEL = '@@asset/LOAD_ASSET_MODEL',
    LOAD_ASSET_MODEL_FULFILLED = '@@asset/LOAD_ASSET_MODEL_FULFILLED',
    LOAD_ASSET_MODEL_REJECTED = '@@asset/LOAD_ASSET_MODEL_REJECTED',
}

export const loadAssetManufacturers = (query: IAssetQuery): IAppAction => ({
    type: AssetActions.LOAD_ASSET_MANUFACTURER,
    payload: query,
});

export const loadAssetModels = (query: IAssetQuery): IAppAction => ({
    type: AssetActions.LOAD_ASSET_MODEL,
    payload: query,
});