import * as React from 'react';
import { ModalCard, ModalCardBody, ModalCardFooter, ModalCardHeader } from '../modalCard';
import { Control, Field } from '../form';
import { IRiskProfileQuestionSetQuestion } from 'models';
import Select from 'react-select';
import { BulmaSize } from 'enums/BulmaSize';
import { IRiskProfileCategory } from 'models/risk-profile-questions/risk-profile-lookups';
import { Button } from '../v2/components';
import { v1 as uuidv1 } from 'uuid';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';

export interface IProps {
    questionSet?: IRiskProfileQuestionSetQuestion;
    newQuestionSetNumber?: number;
    riskProfileCategories: IRiskProfileCategory[];
    intl: any;
}

export interface IActions {
    onSaveQuestionSet: (questionSet: IRiskProfileQuestionSetQuestion) => void;
    onCancel: () => void;
}

export interface IState {
    questionSet: IRiskProfileQuestionSetQuestion;
    isShown: boolean;
    showError: boolean;
}

interface IMergeProps extends IProps, IActions { }

class EditRiskProfileQuestionSetClass extends React.Component<IMergeProps, IState>  {

    constructor(props: IMergeProps) {
        super(props);
        this.state = {
            isShown: true, showError: false,
            questionSet: this.props.questionSet ? this.props.questionSet : this.getNewQuestionSet()
        };
    }

    public getNewQuestionSet(): IRiskProfileQuestionSetQuestion {
        return {
            questionSetId: this.getNewId(), questionSetOrder: this.props.newQuestionSetNumber,
            categoryId: null, category: '', questionSetText: '', questions: []
        };
    }

    public getNewId() {
        return uuidv1();
    }

    public onShowConfirmation = () => {
        this.setState({ isShown: true });
    }

    public onClose = () => {
        this.props.onCancel();
    }

    public onSaveQuestionSet = () => {
        this.props.onSaveQuestionSet(this.state.questionSet);
    }

    public onChangeCategory = (category: any) => {
        const cat = Array.isArray(category) ? category[0].value : category.value;
        const questionSetUpdated: IRiskProfileQuestionSetQuestion = {
            ...this.state.questionSet, categoryId: cat,
            category: this.props.riskProfileCategories.find((x) => x.id === cat).name
        };
        this.setState({ questionSet: questionSetUpdated });
    }

    public onChangeText = (text: React.ChangeEvent<HTMLTextAreaElement>) => {
        const questionSetUpdated: IRiskProfileQuestionSetQuestion = { ...this.state.questionSet, questionSetText: text.currentTarget.value };
        this.setState({ questionSet: questionSetUpdated });
    }

    public render() {
        const { questionSet } = this.state;
        const isReadyToSave: boolean = questionSet.categoryId && questionSet.questionSetText.length > 0;
        return (
            <ModalCard modalId="textUpdate" isShown={this.state.isShown}>
                <ModalCardHeader modalId="confirmation" title={this.props.newQuestionSetNumber ? translate(this.props.intl, 'RiskProfileQuestion.Set.Message') : translate(this.props.intl, 'RiskProfileQuestion.Set.Message2')} />
                <ModalCardBody modalId="confirmation">
                    {/* Category */}
                    <Field isHorizontal={true} htmlFor="selectCategory" label="Category" labelSize={BulmaSize.Medium}>
                        <Field>
                            <Control>
                                <Select
                                    id="selectCategory"
                                    isMulti={false}
                                    placeholder={translate(this.props.intl, 'DropDown.PlaceHolderText.SelectCategory')}
                                    value={{ value: this.state.questionSet.categoryId, label: this.state.questionSet.category }}
                                    onChange={this.onChangeCategory}
                                    options={this.props.riskProfileCategories.map((x) => ({ value: x.id, label: x.name }))}
                                />
                            </Control>
                        </Field>
                    </Field>
                    {/* Text */}
                    <Field isHorizontal={true} htmlFor="Text" label={translate(this.props.intl, 'RiskProfileQuestion.Set.Question')} labelSize={BulmaSize.Medium}>
                        <Field>
                            <Control>
                                <textarea id="Text" className="textarea" rows={4} value={this.state.questionSet.questionSetText} onChange={this.onChangeText} />
                            </Control>
                        </Field>
                    </Field>
                </ModalCardBody>
                <ModalCardFooter>
                    <Button className={'is-pulled-right'} onClick={this.onSaveQuestionSet} disabled={!isReadyToSave}>
                        {this.props.newQuestionSetNumber ? translate(this.props.intl, 'FacilityPopup.Button.Add') : translate(this.props.intl, 'SiteFeatures.Edit.Div.Update')}
                    </Button>
                    <Button onClick={this.onClose}>Cancel</Button>
                </ModalCardFooter>
            </ModalCard>
        );
    }
}

export const EditRiskProfileQuestionSet = injectIntl(EditRiskProfileQuestionSetClass);