import React from 'react';
import { connect } from 'react-redux';
import { IKeyValue } from 'models';
import selectors from './selectors';
import DashboardActionComponent from 'components/dashboard/dashboard-action/DashboardActionComponent';
import DashboardRiskRegisterComponent from 'components/dashboard/dashboard-risk-register/DashboardRiskRegisterComponent';
import DashboardCalendarEventComponent from 'components/dashboard/dashboard-calendar-event/DashboardCalendarEventComponent';
import DashboardIncidentComponent from 'components/dashboard/dashboard-incidents/DashboardIncidentComponent';
import DashboardCermOverviewComponent from 'components/dashboard/dashboard-cerm-overview/DashboardCermOverviewComponent';
import DashboardPortfolioOverview from 'components/dashboard/dashboard-portfolio-overview/dashboard-portfolio-overview';
import DashboardQuickLinks from 'components/dashboard/dashboard-quick-links/dashboard-quick-links';
import Page from 'components/v2/page/Page';
import { checkPermissions, checkRole } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import useTranslate from 'translations/translation-utils';
import { IOperatingPlatform } from 'models/operating-platform-model';
import { getTitlePrefixGeneric } from 'models/cerm/cerm-assessment-result';
import { IPerson } from 'models/person';
import Helmet from 'react-helmet';
import { scriptToInject } from '../beacon-script'
import { findValueByKey } from 'helpers/helpers';

interface IStateProps {
    selectedSite: IKeyValue<string>;
    permissions: Array<IKeyValue<string>>;
    isTestSite: boolean;
    allOperatingPlatforms: IOperatingPlatform[];
    person: IPerson;
    appSettings?: Array<IKeyValue<string>>;
}

const getOperatingPlatform = (site: any) => {
    return site ? site.operatingPlatform : '';
};

const Dashboard: React.FC<IStateProps> = props => {
    const translate = useTranslate();
    const titlePrefix = getTitlePrefixGeneric(
        getOperatingPlatform(props.selectedSite),
        props.allOperatingPlatforms
    );
    const beaconKey = findValueByKey('REACT_APP_UEM_BEACON_KEY', props.appSettings);
    const uemScript = beaconKey && scriptToInject(beaconKey);
    return (
        <Page title={translate('SideNavBar.Labels.Dashboard')} className="page">
            {uemScript && beaconKey && <Helmet script={[{
                type: 'text/javascript',
                innerHTML: uemScript
            }]} />}
            {!checkPermissions([Privilege.PowerBi], props.permissions) &&
                !checkRole('Technician', props.permissions) && <DashboardQuickLinks />}

            {checkPermissions([Privilege.PowerBi], props.permissions) && !props.isTestSite && (
                <DashboardPortfolioOverview
                    operatingPlatform={getOperatingPlatform(props.selectedSite)}
                    isTestSite={props.isTestSite}
                />
            )}
            <div className="columns is-multiline is-variable">
                <DashboardActionComponent className="is-12-tablet is-8-desktop" />
                <DashboardCalendarEventComponent
                    hasPermission={true}
                    className="is-12-tablet is-4-desktop"
                />
                {<DashboardIncidentComponent className="is-12" />}
                <DashboardRiskRegisterComponent className="is-12" />
                {
                    <DashboardCermOverviewComponent
                        hasPermission={true}
                        className="is-12-tablet is-6-desktop"
                        prefix={titlePrefix}
                    />
                }
            </div>
        </Page>
    );
};

export default connect(selectors)(Dashboard);
