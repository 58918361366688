import { combineEpics, Epic, ofType } from 'redux-observable';
import { ajax, AjaxError } from 'rxjs/ajax';
import { catchError, switchMap, flatMap } from 'rxjs/operators';
import {
    PersonActions,
    PersonActionTypes,
    IUpdatedPersonSiteRolesAction,
} from '../actions/person-actions';
import { IRootPersonState } from '../reducers/persons';
import { getErrorActions$ } from './epic-helpers';
import { AppNotificationActions } from './../actions/app-notification-actions';
import { getApiHeaders } from './shared-header';

import appConfig from 'helpers/config-helper';

const config = appConfig();

const apiUrl = config.REACT_APP_BASE_API;

const updatePersonSiteRolesEpic: Epic<PersonActionTypes, PersonActionTypes, IRootPersonState> = (
    action$
) =>
    action$.pipe(
        ofType(PersonActions.UPDATE_PERSON_SITE_ROLES),
        switchMap((action: IUpdatedPersonSiteRolesAction) =>
            ajax
                .put(
                    `${apiUrl}/Api/Person/${action.payload.siteId}/relationships`,
                    action.payload.users,
                    getApiHeaders()
                )
                .pipe(
                    flatMap<any, any>(() => [
                        {
                            type: PersonActions.UPDATE_PERSON_SITE_ROLES_FULFILLED,
                        },
                        {
                            type: AppNotificationActions.SEND_SUCCESS_NOTIFICATION,
                            payload: {
                                title: 'Site Relationship roles updating',
                                message: 'Request to update site relationship roles has been sent',
                            },
                        },
                    ]),
                    catchError<any, any>((error: AjaxError) =>
                        getErrorActions$('Risks')(
                            PersonActions.UPDATE_PERSON_SITE_ROLES_REJECTED,
                            error,
                            {
                                errorMessage: error.response,
                            }
                        )
                    )
                )
        )
    );

export const personEpics = combineEpics(updatePersonSiteRolesEpic);
