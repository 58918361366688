import * as React from 'react';
import { Loader } from 'components/loader';
import {
    IEngineer,
    IEngineerSkill,
    SkillLevel,
    IEngineerFilters,
    IObsoleteSkillCategory,
    IKeyValue,
} from 'models';
import '../engineers/engineer.scss';
import './engineer.scss';
import { Card, CardHeader, CardContent } from '../card';
import { ButtonWithDatePicker, TriStateToggle } from 'components/v2/components';
import { formatDate, getTimezoneDiffrence, isFutureDate } from 'utils/date-utils';
import { IModalCardHeaderToolbarItem } from '../card/CardHeader';
import {
    getSkillLevel,
    getSkillLevelText,
    SkillLevelTextType,
    getLocalSkillLevel,
    getItemPercentageSummary,
} from 'business-area-functions/cdna';
import moment from 'moment';
import { CardSubheader } from '../card/CardSubheader';
import { MaterialIconType } from 'routes/material-icon/material-icon-type';
import { DatePickerField } from 'components/form/fields/date-picker-field';
import { Checkbox } from 'components/form/Checkbox';
import { checkPermission } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import useTranslate from 'translations/translation-utils';

export interface IEngineerSkillsProps {
    engineer: IEngineer;
    skillCategories: IObsoleteSkillCategory[];
    isLoading: boolean;
    filters: IEngineerFilters;
    expandedCategories: string[];
    isReadOnly?: boolean;
    permissions: Array<IKeyValue<string>>;
    onToggleCategory: (id: string) => void;
    onChangeStatus: (skill: IEngineerSkill, engineerId: string) => void;
    onSaveEngineer: (engineer: IEngineer) => void;
}

export const EngineerSkills: React.FC<IEngineerSkillsProps> = (props) => {

    const translate = useTranslate();
    const [toggleSelectAll, setToggleSelectAll] = React.useState<string[]>([]);

    React.useEffect(() => {
        if (!props.engineer) {
            return;
        }

        props.engineer?.skills.forEach(skill => {
            if (skill.updatedDate !== null && skill.reassessmentDueDate == null) {
                skill.reassessmentDueDate = setReAsessmentDueDate(skill.updatedDate);
            }
        });
    }, [props.engineer, props.engineer?.skills]);

    const checkLeapYear = (year) => {
        const leap = new Date(year, 1, 29).getDate() === 29;
        if (leap) {
            return true;
        } else {
            return false;
        }
    }

    const setReAsessmentDueDate = (date) => {
        if (date !== undefined) {
            let reassementDueDate = new Date(date);
            reassementDueDate.setFullYear(reassementDueDate.getFullYear() + 1);
            let year = reassementDueDate.getFullYear();
            let month = reassementDueDate.getMonth();
            const isLeapYear = checkLeapYear(year);
            if (isLeapYear && month === 1) {
                reassementDueDate.setDate(reassementDueDate.getDate() + 1);
            }
            return reassementDueDate;
        }
    }

    const engineerSkillsExist = props.engineer && props.engineer.skills.length > 0;
    const availableSkillCategories = props.skillCategories.filter(
        (categoryFilter) =>
            (props.filters.categories.length === 0 && categoryFilter.isActive === true) ||
            props.filters.categories.some((x) => x.toString() === categoryFilter.id.toString())
    );

    const isGlobalCategory = (skillCategory: IObsoleteSkillCategory) =>
        engineerSkillsExist &&
        props.engineer.skills.filter(
            (item) => item.skillCategoryId === skillCategory.id && item.isGlobal === true
        ).length > 0;

    const isNonGlobalCategory = (skillCategory: IObsoleteSkillCategory) =>
        engineerSkillsExist &&
        props.engineer.skills.filter(
            (item) => item.skillCategoryId === skillCategory.id && !item.isGlobal
        ).length > 0;

    const filterSkills = (skillCategory: IObsoleteSkillCategory) =>
        engineerSkillsExist &&
        props.engineer.skills
            // apply skill category side filter in case some skill categories shouldn't be displayed at all
            .filter((a) => a.skillCategoryId === skillCategory.id)
            // apply skill level side filter in case some skill levels shouldn't be displayed at all
            .filter(
                (b) =>
                    props.filters.levels.length === 0 ||
                    props.filters.levels.some((x) => x.toString() === b.skillLevel.toString())
            )
            // apply status side filter in case some statuses shouldn't be displayed at all
            .filter(
                (c) =>
                    props.filters.statuses.length === 0 ||
                    props.filters.statuses.some((x) =>
                        x.toString() === 'Yes'
                            ? c.status === true
                            : x.toString() === 'No'
                                ? c.status === false
                                : c.status === undefined || c.status === null
                    )
            )
            // apply filter text side filter so that only skills containing keywords are displayed
            .filter(
                (d) =>
                    props.filters.filterKeywords.length === 0 ||
                    props.filters.filterKeywords.every((word) => d.skillDescription.includes(word))
            );

    const filteredGlobalEngineerSkills = (skillCategory: IObsoleteSkillCategory) =>
        filterSkills(skillCategory).filter((g) => g.isGlobal === true);

    const filteredNonGlobalEngineerSkills = (skillCategory: IObsoleteSkillCategory) =>
        filterSkills(skillCategory).filter((x) => !x.isGlobal);

    const getIsCategoryExpanded = (identifier: string) =>
        props.expandedCategories && props.expandedCategories.includes(identifier);

    const toolbarItems = (id: string): IModalCardHeaderToolbarItem[] => [
        {
            type: 'icon',
            action: props.onToggleCategory,
            icon: getIsCategoryExpanded(id) ? MaterialIconType.AngleUp : MaterialIconType.AngleDown,
            label: getIsCategoryExpanded(id) ? translate('Engineer.Skills.Label.1') : translate('Engineer.Skills.Label.2'),
        },
    ];

    const onStatusChange = (skill: IEngineerSkill, status?: boolean) => {
        let reassessmentDate;
        if (skill.updatedDate !== null) {
            reassessmentDate = setReAsessmentDueDate(skill.updatedDate);
        }
        else {
            reassessmentDate = setReAsessmentDueDate(new Date());
        }
        props.onChangeStatus(
            {
                ...skill,
                status,
                updatedDate:
                    status == null
                        ? null
                        : skill.updatedDate == null
                            ? new Date()
                            : skill.updatedDate,
                reassessmentDueDate: (status === false && skill.updatedDate !== null) ? skill.reassessmentDueDate : reassessmentDate,
            },
            props.engineer.id
        );
    };

    const showDatePicker = (skill: IEngineerSkill) => {
        return skill.status != null && (skill.status === true || skill.status === false);
    };

    const showReassessmentDate = (skill: IEngineerSkill) =>
      isSkillIncluded(skill) &&
      (skill.status === true || skill.status === false) &&
      skill.updatedDate !== null &&
      (skill.reassessmentDueDate !== null ||
        skill.reassessmentDueDate !== undefined);

    const onDateChange = (skill: IEngineerSkill, newDate?: Date) => {
        if (newDate && !isFutureDate(newDate)) {
            const reassessmentDueDate = setReAsessmentDueDate(newDate);
            props.onChangeStatus(
                {
                    ...skill,
                    updatedDate: getTimezoneDiffrence(newDate),
                    reassessmentDueDate: getTimezoneDiffrence(reassessmentDueDate),
                },
                props.engineer.id
            );
        }
    };

    const isSkillIncluded = (skill: IEngineerSkill) => {
        return skill.isIncluded !== null ? skill.isIncluded : true;
    };

    const onChangeIncludeStatus = (skill: IEngineerSkill, checked: boolean) => {
        props.onChangeStatus(
            {
                ...skill,
                status: checked ? skill.status : null,
                isIncluded: checked,
            },
            props.engineer.id
        );
    };

    const onIncludeCheckboxChange = (
        skill: IEngineerSkill,
        event: React.FormEvent<HTMLInputElement>
    ) => {
        const checked = event.currentTarget.checked;
        onChangeIncludeStatus(skill, checked);
    };

    const onToggleSelectAll = (skillCategory: IObsoleteSkillCategory, event: React.FormEvent<HTMLInputElement>) => {
        const checked = event.currentTarget.checked;

        if (checked) {
            setToggleSelectAll([...toggleSelectAll, skillCategory.id]);
        } else {
            setToggleSelectAll([...toggleSelectAll.filter((x) => x !== skillCategory.id)]);
        }

        const globalSkills = filteredGlobalEngineerSkills(skillCategory);
        if (globalSkills) {
            for (const globalSkill of globalSkills) {
                onChangeIncludeStatus(globalSkill, checked);
            }
        }
        const nonGlobalSkills = filteredNonGlobalEngineerSkills(skillCategory);
        if (nonGlobalSkills) {
            for (const nonGlobalSkill of nonGlobalSkills) {
                onChangeIncludeStatus(nonGlobalSkill, checked);
            }
        }
    };

    const getEngineerSkillItem = (skill: IEngineerSkill) => {
        return (
            <div
                className={`grid-view__item is-hoverable`}
                id={'engineer-skill-' + skill.skillId.toString()}
                key={skill.skillId}
            >
                <div className="columns engineer-columns">
                    <div className="column is-1">
                        <span
                            style={{ textAlign: 'left' }}
                            className={
                                skill.skillLevel === SkillLevel.C
                                    ? 'engineer-c'
                                    : skill.skillLevel === SkillLevel.B
                                        ? 'engineer-b'
                                        : skill.skillLevel === SkillLevel.A
                                            ? 'engineer-a'
                                            : ''
                            }
                        >
                            {getSkillLevelText(skill.skillLevel, SkillLevelTextType.Letter)}
                        </span>
                    </div>
                    <div className="column is-1">
                        {skill.skillCategoryNumber.toString() +
                            '.'.toString() +
                            skill.skillNumber.toString()}
                    </div>
                    <div className="column is-4">
                        <span className="whitespace-wrap word-break">
                            {skill.skillDescription}
                        </span>
                    </div>
                    <div className="column is-2">
                        {showDatePicker(skill) && (
                            <DatePickerField
                                id={`trainingExpiryDate${skill.skillId}`}
                                label=""
                                value={
                                    skill.updatedDate !== null
                                        ? formatDate(moment(skill.updatedDate).toDate().toDateString()) : ''
                                }
                                selected={
                                    skill.updatedDate !== null
                                        ? formatDate(moment(skill.updatedDate).toDate().toDateString()) : ''
                                }
                                handleChange={(e) => onDateChange(skill, e)}
                                showMonthDropdown={true}
                                showYearDropdown={true}
                                maxDate={new Date()}
                                isInTable={true}
                            />
                        )}
                    </div>
                    <div className="column is-1 reassessmentDueDate">
                        {showReassessmentDate(skill) && formatDate(moment(skill.reassessmentDueDate).toDate().toDateString())
                        }
                    </div>

                    <div className="column is-1 has-text-centered">
                        <TriStateToggle
                            selectedValue={skill.status}
                            onChange={(e) => onStatusChange(skill, e)}
                            toggleFor={skill.skillId}
                            isDisabled={props.isReadOnly || !isSkillIncluded(skill)}
                        />
                    </div>
                    <div className="column is-2 has-text-centered">
                        <Checkbox
                            id={skill.skillId}
                            checked={isSkillIncluded(skill)}
                            onChange={(e) => onIncludeCheckboxChange(skill, e)}
                            disabled={
                                props.isReadOnly ||
                                !checkPermission(
                                    Privilege.CDNADcProfessionalsApplicability,
                                    props.permissions
                                )
                            }
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Loader className="EngineerSkills" loading={props.isLoading}>
            {props.engineer ? (
                <>
                    {availableSkillCategories.map((skillCategory) => (
                        filterSkills(skillCategory).length > 0 && (
                        <div id={skillCategory.id} key={skillCategory.id}>
                            <Card>
                                <CardHeader
                                    widgetId={skillCategory.id}
                                    title={skillCategory.code + ': ' + skillCategory.name}
                                    subtitle={skillCategory.description}
                                    additionalAction={
                                        filterSkills(skillCategory).length > 0 ? (
                                            <>
                                                <ButtonWithDatePicker
                                                    buttonText={translate('Engineer.Skills.ReassessWholeQualification')}
                                                    onChange={date =>
                                                        filterSkills(skillCategory)
                                                            .filter((skill) => skill.isIncluded === true && skill.status === true)
                                                            .forEach(skill => onDateChange(skill, new Date(date)))
                                                    }
                                                    maxDate={new Date()}
                                                />
                                                <small className='additional-small'>{engineerSkillsExist &&
                                                    (skillCategory.code.includes('LQS')
                                                        || skillCategory.code.includes('LITQS')
                                                        || skillCategory.code.includes('AQS')
                                                        || skillCategory.code.includes('AITQS'))
                                                    ? getSkillLevelText(
                                                        getLocalSkillLevel(
                                                            props.engineer.skills
                                                                .filter((skill) => skill.skillCategoryId === skillCategory.id)
                                                                .filter((skill) => skill.isIncluded === null || skill.isIncluded === true)
                                                        ),
                                                        SkillLevelTextType.LevelAndCategory)
                                                    : getSkillLevelText(
                                                        getSkillLevel(
                                                            props.engineer.skills
                                                                .filter((skill) => skill.skillCategoryId === skillCategory.id)
                                                                .filter((skill) => skill.isIncluded === null || skill.isIncluded === true)
                                                        ), SkillLevelTextType.LevelAndCategory,
                                                    )}</small>
                                            </>) :
                                            undefined
                                    }
                                    toolbarItems={toolbarItems(skillCategory.id)}
                                    sideNote={engineerSkillsExist ?
                                        getItemPercentageSummary(
                                            props.engineer.skills
                                                .filter((skill) => skill.skillCategoryId === skillCategory.id)
                                                .filter((skill) => skill.isIncluded === null || skill.isIncluded === true)
                                        ) : ''}
                                />
                                <div className={`grid-view`}>
                                    {props.expandedCategories &&
                                        props.expandedCategories.includes(skillCategory.id) && (
                                            <>
                                                {engineerSkillsExist &&
                                                    isGlobalCategory(skillCategory) && (
                                                        <>
                                                            <CardSubheader title={translate('CompetencyDnaProcess.Label.5')} />
                                                            <div className={`centered-grid-headers grid-headers columns ${props.isLoading ? 'is-loading' : ''}`}>
                                                                <div className="column is-1">{translate('RiskRegisterPage.RiskFilter.Title.Level')}</div>
                                                                <div className="column is-1">Ref</div>
                                                                <div className="column is-4">{translate('Engineer.Skills.Label.3')}</div>
                                                                <div className="column is-2">{translate('SchedulerWeek.Button.Calendar.assessedDate')}</div>
                                                                <div className="column is-1">{translate('SchedulerWeek.Button.Calendar.reassessmentDuedate')}</div>
                                                                <div className="column is-1 has-text-centered">{translate('RiskRegisterPage.RiskFilter.Title.Status')}</div>
                                                                <div className="column is-2 has-text-centered">{translate('Engineer.Skills.Label.4')}</div>
                                                            </div>

                                                            <div className="centered-grid-headers grid-headers columns">
                                                                <div className="column is-10" />
                                                                <div className="column is-2 has-text-centered">
                                                                    {translate('Globals.Label.SelectAll')}
                                                                    <Checkbox
                                                                        id={
                                                                            'selectAll-' +
                                                                            skillCategory.id
                                                                        }
                                                                        checked={toggleSelectAll.includes(skillCategory.id)}
                                                                        onChange={(e) =>
                                                                            onToggleSelectAll(
                                                                                skillCategory,
                                                                                e
                                                                            )
                                                                        }
                                                                        disabled={
                                                                            props.isReadOnly ||
                                                                            !checkPermission(
                                                                                Privilege.CDNADcProfessionalsApplicability,
                                                                                props.permissions
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>

                                                            <CardContent className="Skills">
                                                                {filteredGlobalEngineerSkills(skillCategory)
                                                                    .map((skill) => getEngineerSkillItem(skill))
                                                                }
                                                            </CardContent>
                                                        </>
                                                    )}

                                                {isNonGlobalCategory(skillCategory) && (
                                                    <>
                                                        <CardSubheader title="Local, Site & Account Specific Competency DNA Demonstrations" />
                                                        <div className={`centered-grid-headers grid-headers columns ${props.isLoading ? 'is-loading' : ''}`}>
                                                            <div className="column is-1">{translate('RiskRegisterPage.RiskFilter.Title.Level')}</div>
                                                            <div className="column is-1">Ref</div>
                                                            <div className="column is-4">{translate('Engineer.Skills.Label.3')}</div>
                                                            <div className="column is-2">{translate('SchedulerWeek.Button.Calendar.assessedDate')}</div>
                                                            <div className="column is-1">{translate('SchedulerWeek.Button.Calendar.reassessmentDuedate')}</div>
                                                            <div className="column is-1 has-text-centered">{translate('RiskRegisterPage.RiskFilter.Title.Status')}</div>
                                                            <div className="column is-2 has-text-centered">{translate('Engineer.Skills.Label.4')}</div>
                                                        </div>
                                                        <CardContent className="LocalSkills">
                                                            {filteredNonGlobalEngineerSkills(skillCategory)
                                                                .map((skill) => getEngineerSkillItem(skill))
                                                            }
                                                        </CardContent>
                                                    </>
                                                )}
                                            </>
                                        )}
                                </div>
                            </Card>
                        </div>
                    )))}
                </>
            ) : (
                <p> {translate('CompetencyDnaProcess.Label.16')}</p>
            )}
        </Loader>
    );
};