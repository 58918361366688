import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { ProfileProvider } from './profile-provider';
import { createSelector } from 'reselect';

const getUser = (state: IRootState) => state.person.personProfile.language;

export const getUserLanguage = createSelector(getUser, (language) => language);

const mapStateToProps = (state: IRootState) => {
    return {
        language: getUserLanguage(state),
    };
};

export const ProfileProviderContainer = connect(mapStateToProps)(ProfileProvider);
