import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { getPermissions, getIsClient } from 'sharedSelectors';

export const getSites = createSelector(
    (state: IRootState) => state.app,
    (appState) => (appState.lookupSites.length > 0 ? appState.lookupSites : [])
);

const getSelectedSiteId = createSelector(
    (state: IRootState) => state.app.siteId,
    (appState) => appState
);

export default createSelector(
    getSites,
    getSelectedSiteId,
    getPermissions,
    getIsClient,
    (lookupSites, siteId, permissions, isClient) => ({
        lookupSites,
        siteId,
        permissions,
        isClient,
    })
);
