import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';

const getRiskCategories = (state: IRootState) => state.app.lookupRiskCategories;
const getRiskLikelihoods = (state: IRootState) => state.app.lookupRiskLikelihoods;
const getRiskImpacts = (state: IRootState) => state.app.lookupRiskImpacts;

export default createSelector(
    getRiskCategories,
    getRiskLikelihoods,
    getRiskImpacts,
    (riskCategories, riskLikelihoods, riskImpacts) => ({
        riskCategories,
        riskLikelihoods,
        riskImpacts,
    })
);
