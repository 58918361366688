import { IAppAction } from 'actions/app-action';
import { combineReducers } from 'redux';
import { IOperatingPlatformState } from 'state/operating-platform-state';
import { IOperatingPlatformActions } from 'actions/actions-v2/operating-platform-action';

const INITIAL_STATE: IOperatingPlatformState = {
    isLoading: false,
    operatingPlatforms: [],
    operatingPlatformMenus: [],
    menusForOperatingPlatform: [],
    newOperatingPlatformGeneratedMenus: [],
    isUpdatedOperatingPlatformMenus: false,
    isDeletedOperatingPlatform: false
};

const isLoading = (
    state: IOperatingPlatformState['isLoading'] = INITIAL_STATE.isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case IOperatingPlatformActions.LOAD_OPERATING_PLATFORM:
        case IOperatingPlatformActions.UPDATE_OPERATING_PLATFORM:
        case IOperatingPlatformActions.LOAD_MENUS_FOR_OPERATING_PLATFORM:
            return true;
        case IOperatingPlatformActions.LOAD_OPERATING_PLATFORM_FULFILLED:
        case IOperatingPlatformActions.LOAD_OPERATING_PLATFORM_REJECTED:
        case IOperatingPlatformActions.UPDATE_OPERATING_PLATFORM_FULFILLED:
        case IOperatingPlatformActions.UPDATE_OPERATING_PLATFORM_REJECTED:
        case IOperatingPlatformActions.LOAD_MENUS_FOR_OPERATING_PLATFORM_FULFILLED:
        case IOperatingPlatformActions.GET_OPERATING_PLATFORM_MENUS_REJECTED:
            return false;
        case IOperatingPlatformActions.GET_OPERATING_PLATFORM_MENUS:
        case IOperatingPlatformActions.LOAD_DEFAULT_MENUS:
        case IOperatingPlatformActions.LOAD_OPERATING_PLATFORM_FULFILLED:

        default:
            return state;
    }
};

const operatingPlatforms = (
    state: IOperatingPlatformState['operatingPlatforms'] = INITIAL_STATE.operatingPlatforms,
    action: IAppAction
) => {
    switch (action.type) {
        case IOperatingPlatformActions.LOAD_OPERATING_PLATFORM_FULFILLED:
            if (action.payload) {
                return action.payload;
            }

        default:
            return state;
    }
};

const newOperatingPlatformGeneratedMenus = (
    state: IOperatingPlatformState['newOperatingPlatformGeneratedMenus'] = INITIAL_STATE.newOperatingPlatformGeneratedMenus,
    action: IAppAction
) => {
    switch (action.type) {
        case IOperatingPlatformActions.CREATE_OPERATING_PLATFORM:
        case IOperatingPlatformActions.CREATE_OPERATING_PLATFORM_REJECTED:
            return [];
        case IOperatingPlatformActions.CREATE_OPERATING_PLATFORM_FULFILLED:
            if (action?.payload) {
                return action?.payload;
            }
            break;
        case IOperatingPlatformActions.RESET_OPERATING_PLATFORM_MENUS:
            return INITIAL_STATE.newOperatingPlatformGeneratedMenus;
        default:
            return state;
    }
};

const operatingPlatformMenus = (
    state: IOperatingPlatformState['operatingPlatformMenus'] = INITIAL_STATE.operatingPlatformMenus,
    action: IAppAction
) => {
    switch (action.type) {
        case IOperatingPlatformActions.GET_OPERATING_PLATFORM_MENUS:
            if (action.payload) {
                return action.payload.operatingPlatformMenus;
            }
            break;
        case IOperatingPlatformActions.RESET_UPDATED_OPERATING_PLATFORM_MENUS:
            return INITIAL_STATE.operatingPlatformMenus;
        default:
            return state;
    }
};

const menusForOperatingPlatform = (state: IOperatingPlatformState['menusForOperatingPlatform'] = INITIAL_STATE.menusForOperatingPlatform, action: IAppAction) => {
    switch (action.type) {
        case IOperatingPlatformActions.LOAD_MENUS_FOR_OPERATING_PLATFORM_FULFILLED:
            if (action.payload) {
                return action.payload;
            }
            break;
        case IOperatingPlatformActions.RESET_MENUS_FOR_OPERATING_PLATFORM:
            return INITIAL_STATE.menusForOperatingPlatform;
        default:
            return state;
    }
};

const isUpdatedOperatingPlatformMenus = (
    state: IOperatingPlatformState['isUpdatedOperatingPlatformMenus'] = INITIAL_STATE.isUpdatedOperatingPlatformMenus,
    action: IAppAction
) => {
    switch (action.type) {
        case IOperatingPlatformActions.UPDATE_OPERATING_PLATFORM_MENUS:
        case IOperatingPlatformActions.UPDATE_OPERATING_PLATFORM_MENUS_REJECTED:
        case IOperatingPlatformActions.UPDATE_OPERATING_PLATFORM_MENUS_FULFILLED:
            return true;
        case IOperatingPlatformActions.RESET_UPDATED_OPERATING_PLATFORM_MENUS_FLAG:
            return INITIAL_STATE.isUpdatedOperatingPlatformMenus;
        default:
            return state;
    }
};

const isDeletedOperatingPlatform = (
    state: IOperatingPlatformState['isDeletedOperatingPlatform'] = INITIAL_STATE.isDeletedOperatingPlatform,
    action: IAppAction
) => {
    switch (action.type) {
        case IOperatingPlatformActions.DELETE_OPERATING_PLATFORM:
        case IOperatingPlatformActions.DELETE_OPERATING_PLATFORM_REJECTED:
        case IOperatingPlatformActions.DELETE_OPERATING_PLATFORM_FULFILLED:
            return true;
        case IOperatingPlatformActions.RESET_IS_DELETED_OPERATING_PLATFORM_FLAG:
            return INITIAL_STATE.isDeletedOperatingPlatform;
        default:
            return state;
    }
};

export const IOperatingPlatformReducer = combineReducers<IOperatingPlatformState>({
    isLoading,
    operatingPlatforms,
    operatingPlatformMenus: operatingPlatformMenus,
    menusForOperatingPlatform,
    newOperatingPlatformGeneratedMenus,
    isUpdatedOperatingPlatformMenus,
    isDeletedOperatingPlatform
});
