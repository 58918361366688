import { Action } from 'redux';
import { IRootClientsState } from 'reducers/clients';
import { IClient, IMyDataClient } from '../models/client';
import { IAction } from 'models/action';

export enum ClientActions {
    UPSERT_CLIENT = '@@clients/UPSERT_CLIENT',
    UPSERT_CLIENT_FULFILLED = '@@clients/UPSERT_CLIENT_FULFILLED',
    UPSERT_CLIENT_REJECTED = '@@clients/UPSERT_CLIENT_REJECTED',

    LOAD_CLIENT = '@@clients/LOAD_CLIENT',
    LOAD_CLIENT_FULFILLED = '@@clients/LOAD_CLIENT_FULFILLED',
    LOAD_CLIENT_REJECTED = '@@clients/LOAD_CLIENT_REJECTED',
    LOAD_CLIENT_CANCELLED = '@@clients/LOAD_CLIENT_CANCELLED',

    LOAD_CLIENTS = '@@clients/LOAD_CLIENTS',
    LOAD_CLIENTS_FULFILLED = '@@clients/LOAD_CLIENTS_FULFILLED',
    LOAD_CLIENTS_REJECTED = '@@clients/LOAD_CLIENTS_REJECTED',
    LOAD_CLIENTS_CANCELLED = '@@clients/LOAD_CLIENTS_CANCELLED',
    TOGGLE_ITEM_EXPANDED = '@@clients/TOGGLE_ITEM_EXPANDED',

    SORT_CLIENT = '@@clients/SORT_CLIENT',

    CHANGE_FILTER_VALUE = '@@clients/CHANGE_FILTER_VALUE',
    SHOW_ACTIONS = '@@clients/SHOW_ACTIONS',
    HIDE_ACTIONS = '@@clients/HIDE_ACTIONS',

    ADD_FILTER_KEYWORD= '@@clients/ADD_FILTER_KEYWORD',
    REMOVE_FILTER_KEYWORD= '@@clients/REMOVE_FILTER_KEYWORD',
    CLEAR_FILTERS= '@@clients/CLEAR_FILTERS',
    LOAD_PAGE = '@@clients/LOAD_PAGE',
    LOAD_PAGE_FULFILLED = '@@clients/LOAD_PAGE_FULFILLED',
    LOAD_PAGE_REJECTED = '@@clients/LOAD_PAGE_REJECTED',
    LOAD_PAGE_CANCELLED = '@@clients/LOAD_PAGE_CANCELLED',


    LOAD_MYDATA_CLIENTS = '@@clients/LOAD_MYDATA_CLIENTS',
    LOAD_MYDATA_CLIENTS_FULFILLED = '@@clients/LOAD_MYDATA_CLIENTS_FULFILLED',
    LOAD_MYDATA_CLIENTS_REJECTED = '@@clients/LOAD_MYDATA_CLIENTS_REJECTED',
    LOAD_MYDATA_CLIENTS_CANCELLED = '@@clients/LOAD_MYDATA_CLIENTS_CANCELLED',
}

export const onSort = (key: Extract<keyof IAction, string>, sortAscending: boolean) => ({
    type: ClientActions.SORT_CLIENT,
    payload: { key, sortAscending }
});

export interface ISetSortOrderAction extends Action {
    type: ClientActions.SORT_CLIENT;
    payload: {
        key: string;
        sortAscending: boolean;
    };
}

export interface ILoadPageAction extends Action {
    type: ClientActions.LOAD_PAGE;
}

export interface ILoadPageRejectedAction extends Action {
    type: ClientActions.LOAD_PAGE_REJECTED;
    error: true;
    payload: any;
}

export interface ILoadPageCancelledAction extends Action {
    type: ClientActions.LOAD_PAGE_CANCELLED;
}

export interface ILoadPageFulfilledAction extends Action {
    type: ClientActions.LOAD_PAGE_FULFILLED;
    payload: IRootClientsState;
}

export interface IAddFilterKeyword extends Action {
    type: ClientActions.ADD_FILTER_KEYWORD;
    payload: {
        keyword: string;
    };
}

export interface IRemoveFilterKeyword extends Action {
    type: ClientActions.REMOVE_FILTER_KEYWORD;
    payload: {
        keyword: string;
    };
}

export interface IClearFilters extends Action {
    type: ClientActions.CLEAR_FILTERS;
}

export interface IUpsertClientAction extends Action {
    type: ClientActions.UPSERT_CLIENT;
    payload: {
        client: IClient;
    };
}

export interface IUpsertClientCancelledAction extends Action {
    type: ClientActions.UPSERT_CLIENT_REJECTED;
}

export interface IUploadClientFulfilledAction extends Action {
    type: ClientActions.UPSERT_CLIENT_FULFILLED;
    payload: {
        client: IClient;
    };
}

export interface ILoadClientsAction extends Action {
    type: ClientActions.LOAD_CLIENTS;
}

export interface ILoadClientsRejectedAction extends Action {
    type: ClientActions.LOAD_CLIENTS_REJECTED;
    error: true;
    payload: any;
}

export interface ILoadClientsCancelledAction extends Action {
    type: ClientActions.LOAD_CLIENTS_CANCELLED;
}

export interface ILoadClientsFulfilledAction extends Action {
    type: ClientActions.LOAD_CLIENTS_FULFILLED;
    payload: {
        clients: IClient[];
    };
}

export interface ISetFilterValueAction extends Action {
    type: ClientActions.CHANGE_FILTER_VALUE;
    payload: {
        field: string;
        value: string;
    };
}

export interface IToggleItemExpanded extends Action {
    type: ClientActions.TOGGLE_ITEM_EXPANDED;
    payload: {
        id: string;
    };
}

export interface IHideActions extends Action {
    type: ClientActions.HIDE_ACTIONS;
    payload: {
        id: string;
    };
}

export interface IShowActions extends Action {
    type: ClientActions.SHOW_ACTIONS;
    payload: {
        id: string;
    };
}

export interface ILoadClientAction extends Action {
    type: ClientActions.LOAD_CLIENT;
    payload: {
        clientId: string;
    };
}

export interface ILoadClientRejectedAction extends Action {
    type: ClientActions.LOAD_CLIENT_REJECTED;
}

export interface ILoadClientCancelledAction extends Action {
    type: ClientActions.LOAD_CLIENT_CANCELLED;
}

export interface ILoadClientFulfilledAction extends Action {
    type: ClientActions.LOAD_CLIENT_FULFILLED;
    payload: {
        client: IClient;
    };
}

export interface ILoadMyDataClientsAction extends Action {
    type: ClientActions.LOAD_MYDATA_CLIENTS;
}

export interface ILoadMyDataClientsRejectedAction extends Action {
    type: ClientActions.LOAD_MYDATA_CLIENTS_REJECTED;
    error: true;
    payload: any;
}

export interface ILoadMyDataClientsCancelledAction extends Action {
    type: ClientActions.LOAD_MYDATA_CLIENTS_CANCELLED;
}

export interface ILoadMyDataClientsFulfilledAction extends Action {
    type: ClientActions.LOAD_MYDATA_CLIENTS_FULFILLED;
    payload: {
        myDataClients: IMyDataClient[];
    };
}

export type ClientActionTypes =
| IUpsertClientAction
| IUpsertClientCancelledAction
| IUploadClientFulfilledAction

| IRemoveFilterKeyword
| IAddFilterKeyword
| IClearFilters

| ILoadClientsAction
| ILoadClientsCancelledAction
| ILoadClientsFulfilledAction
| ILoadClientsRejectedAction

| ILoadClientAction
| ILoadClientCancelledAction
| ILoadClientFulfilledAction
| ILoadClientRejectedAction

| ISetFilterValueAction
| ISetSortOrderAction
| IToggleItemExpanded
| IHideActions
| IShowActions
| ILoadPageAction
| ILoadPageCancelledAction
| ILoadPageRejectedAction
| ILoadPageFulfilledAction

| ILoadMyDataClientsAction
| ILoadMyDataClientsCancelledAction
| ILoadMyDataClientsFulfilledAction
| ILoadMyDataClientsRejectedAction;
