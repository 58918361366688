import React from 'react';
import { TriStateToggle, ConfirmDialog } from 'components/v2/components';
import { IMasterOutcome } from 'models/master-process-step/master-process-step';
import { IProcessStepAnswer } from 'models/cerm/process-step-answer';
import { getOutcomeById } from 'components/cerm/shared/cerm-utils';
import useTranslate from 'translations/translation-utils';

interface IProps {
    masterOutcome: IMasterOutcome;
    processStepAnswer: IProcessStepAnswer;
    compliance: boolean;
    canEdit?: boolean;
    onChange: (value?: boolean) => void;
}

export const CermOutcomeTriStateToggle: React.FC<IProps> = ({
    masterOutcome,
    processStepAnswer,
    compliance,
    canEdit,
    onChange
}) => {
    const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
    const [selectedOutcomeCompliance, setSelectedOutcomeCompliance] = React.useState<boolean>(null);
    const translate = useTranslate();

    const onProcessStepApplicableChange = (value?: boolean) => {
        if(canEdit!=null && canEdit==false) {
            setShowConfirmDialog(true)
            return
        }
        if (value !== null && value === masterOutcome.compliantResponse) {
            const found = getOutcomeById(processStepAnswer, masterOutcome.id);
            if (found && found.actionId) {
                setSelectedOutcomeCompliance(value);
                setShowConfirmDialog(true);
                return;
            }
        }

        onChange(value);
    };

    const onConfirmSettingOutcomeCompliance = () => {
        onChange(selectedOutcomeCompliance);
        setShowConfirmDialog(false);
    };

    const onConfirmDialogCancel = () => {
        setShowConfirmDialog(false);
    };

    return (
        <>
            <TriStateToggle
                toggleFor={masterOutcome.id}
                selectedValue={compliance}
                onChange={(value) => onProcessStepApplicableChange(value)}
            />
            {showConfirmDialog && (canEdit==null || canEdit==true) && (
                <ConfirmDialog
                    id="confirmDialog"
                    title={translate('ShiftHandoverDetailsTab.ConfirmNavigation.Label.Confirm')}
                    message={translate('CermAssesmentTab.Title.Message.2')}
                    isVisible={showConfirmDialog}
                    onConfirm={onConfirmSettingOutcomeCompliance}
                    onClose={onConfirmDialogCancel}
                    onOutsideDialogClick={onConfirmDialogCancel}
                />
            )}
            {showConfirmDialog && (canEdit!=null && canEdit==false) && (
                    <ConfirmDialog
                        title={translate('CermAssesmentTab.Title.Information')}
                        message={translate('CermAssesmentTab.Title.Message')}
                        buttonCancelHide={true}
                        buttonConfirmText={translate('CermAssesmentTab.Title.Ok')}
                        isVisible={showConfirmDialog}
                        onConfirm={() => setShowConfirmDialog(false)}
                        onOutsideDialogClick={() => setShowConfirmDialog(false)}
                    />
            )}
        </>
    );
};
