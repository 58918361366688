import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { ISite, ISiteMyDataFacilityAssignment } from 'reducers/manageRoles/manageRoles-grid';
import { Epic, combineEpics } from 'redux-observable';
import { ISiteAssessmentSummary, ISiteProfile } from 'models/site-profile';
import { SiteActions } from 'actions/actions-v2/site-actions-v2';
import { IPersonToSite } from 'models/person';

const controllerName = 'site';

const createActions = {
    action: SiteActions.CREATE_SITE,
    actionFulfilled: SiteActions.CREATE_SITE_FULFILLED,
    actionRejected: SiteActions.CREATE_SITE_REJECTED,
} as IActionType;

const updateActions = {
    action: SiteActions.UPDATE_SITE,
    actionFulfilled: SiteActions.UPDATE_SITE_FULFILLED,
    actionRejected: SiteActions.UPDATE_SITE_REJECTED,
} as IActionType;

const updateNoNavActions = {
    action: SiteActions.UPDATE_SITE_NO_NAV,
    actionFulfilled: SiteActions.UPDATE_SITE_FULFILLED_NO_NAV,
    actionRejected: SiteActions.UPDATE_SITE_REJECTED_NO_NAV,
} as IActionType;

const updateAssessmentSummaryActions = {
    action: SiteActions.UPDATE_SITE_ASSESSMENT_SUMMARY,
    actionFulfilled: SiteActions.UPDATE_SITE_ASSESSMENT_SUMMARY_FULFILLED,
    actionRejected: SiteActions.UPDATE_SITE_ASSESSMENT_SUMMARY_REJECTED,
} as IActionType;

const getAllActions = {
    action: SiteActions.LOAD_SITES,
    actionFulfilled: SiteActions.LOAD_SITES_FULFILLED,
    actionRejected: SiteActions.LOAD_SITES_REJECTED,
} as IActionType;

const getActions = {
    action: SiteActions.LOAD_SITE,
    actionFulfilled: SiteActions.LOAD_SITE_FULFILLED,
    actionRejected: SiteActions.LOAD_SITE_REJECTED,
} as IActionType;

const deleteActions = {
    action: SiteActions.DELETE_SITE,
    actionFulfilled: SiteActions.DELETE_SITE_FULFILLED,
    actionRejected: SiteActions.DELETE_SITE_REJECTED,
} as IActionType;

const exportActions = {
    action: SiteActions.EXPORT_SITES,
    actionFulfilled: SiteActions.EXPORT_SITES_FULFILLED,
    actionRejected: SiteActions.EXPORT_SITES_REJECTED,
} as IActionType;

const validateMyDataFacilityActions = {
    action: SiteActions.VALIDATE_MYDATA_FACILITY_SITES,
    actionFulfilled: SiteActions.VALIDATE_MYDATA_FACILITY_SITES_FULFILLED,
    actionRejected: SiteActions.VALIDATE_MYDATA_FACILITY_SITES_REJECTED,
} as IActionType;

const loadRelationshipsActions = {
    action: SiteActions.LOAD_RELATIONSHIPS,
    actionFulfilled: SiteActions.LOAD_RELATIONSHIPS_FULFILLED,
    actionRejected: SiteActions.LOAD_RELATIONSHIPS_REJECTED,
} as IActionType;

const createSiteEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createActions).post<ISiteProfile>(`/api/${controllerName}`);

const updateSiteEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateActions, '/Site').put<ISiteProfile>(`/api/${controllerName}`);

const updateSiteNoNavEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateNoNavActions).put<ISiteProfile>(`/api/${controllerName}`);

const updateSiteAssessmentSummaryEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateAssessmentSummaryActions).put<ISiteAssessmentSummary>(
        `/api/${controllerName}/AssessmentSummary`
    );

const loadSitesEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getAllActions).get<ISite[]>(`/api/${controllerName}`);

const loadSiteEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getActions).getById<ISiteProfile>(`/api/${controllerName}`);

const deleteSiteEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, deleteActions, '/Site').deleteById<string>(`/api/${controllerName}`);

const exportSiteEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, exportActions).get<[]>(`/api/${controllerName}/export`);

const validateMyDataFacilitySiteEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, validateMyDataFacilityActions).put<ISiteMyDataFacilityAssignment[]>(`/api/${controllerName}/GetMyDataFacilityAssignments`);

const loadRelationshipsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, loadRelationshipsActions).getById<IPersonToSite[]>(`/api/${controllerName}/relationships`);

export const siteEpicsV2 = combineEpics(
    createSiteEpic,
    updateSiteEpic,
    updateSiteNoNavEpic,
    updateSiteAssessmentSummaryEpic,
    loadSitesEpic,
    loadSiteEpic,
    deleteSiteEpic,
    exportSiteEpic,
    validateMyDataFacilitySiteEpic,
    loadRelationshipsEpic,
);
