import * as React from 'react';
import OpportunityQuestionnaireItem from './opportunity-questionnaire-item';
import { IOpportunity, IOpportunityAnswer } from 'models/opportunity-question-set';
import { groupBy } from 'lodash';
import useTranslate from 'translations/translation-utils';
interface IProps {
    siteId?: string;
    opportunityQuestions: IOpportunity[];
    opportunityAnswers: IOpportunityAnswer[];
    onQuestionAnswerChanged: (questionId: string, questionSetId: string, answer: string) => void;
}

export const OpportunityQuestionSetList: React.FC<IProps> = ({ siteId, ...props }) => {
    const translate = useTranslate();
    const [isExpanded, setIsExpanded] = React.useState(false);
    const [allResponseAnswers, setAllResponseAnswers] = React.useState([]);
    const questionsGroup = groupBy(props.opportunityQuestions, (o) => o.category);

    React.useEffect(() => {
        setIsExpanded(false);
    }, [siteId]);

    React.useEffect(() => {
        getAllResponseAnswers();
    }, [props.opportunityQuestions]);

    const getAllResponseAnswers = () => {
        let responseAnswer = [];
        props.opportunityQuestions &&
            props.opportunityQuestions.forEach((questionsList) => {
                // tslint:disable-next-line: no-unused-expression
                questionsList.questions &&
                    questionsList.questions.forEach((question) => {
                        responseAnswer = [
                            ...new Set([...responseAnswer, ...question.responseAnswers]),
                        ];
                    });
            });
        setAllResponseAnswers(responseAnswer);
    };
    return (
        <>
            {Object.keys(questionsGroup).map((category, index) => {
                return (
                    <div key={index} className="component component-info">
                        <div className="header">
                            <div className="header-content">
                                <div className="title">
                                    {translate('OpportunityQ.OpportunityCategory.', category)}
                                </div>
                            </div>
                        </div>
                        {questionsGroup[category] &&
                            questionsGroup[category].map((opportunity, i) => (
                                <OpportunityQuestionnaireItem
                                    key={i}
                                    opportunity={opportunity}
                                    expandAll={isExpanded}
                                    onQuestionAnswerChanged={props.onQuestionAnswerChanged}
                                    allResponseAnswers={allResponseAnswers}
                                    opportunityAnswers={props.opportunityAnswers}
                                />
                            ))}
                    </div>
                );
            })}
        </>
    );
};
