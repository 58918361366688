import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import actions from './actions';
import selector from './selector';
import { UpsertCermProcessStepPage as View } from './upsert-cerm-process-step-page';

export const UpsertCermProcessStepPage = withRouter(connect(
    selector,
    actions
)(View));
