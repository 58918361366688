import React, { useState } from 'react';
import Page from 'components/v2/page/Page';
import { Button, FloatingButton, Toolbar, ToolbarGroupLeft, ToolbarGroupRight } from 'components/v2/components';
import { FaqsReadonly } from './faqs-readonly';
import { FaqsEdit } from './faqs-edit'
import { IFaqs } from 'models/faqs-model'
import _ from 'lodash';
import { Loader } from 'components/loader';
import ReactTooltip from 'react-tooltip';
import { checkPermissions } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import { IKeyValue } from 'models/key-value';
import { useTranslate } from 'translations/translation-utils';
import { getSiteOperatingPlatform } from 'models/site-profile';
interface Iprops {
    language: string;
    faqsContent: IFaqs;
    isLoading: boolean;
    permission: Array<IKeyValue<string>>;
    loadFaqs: (language: string, operatingPlatform: string) => void;
    updateFaqs: (model: IFaqs) => void;
    createFaqs: (model: IFaqs) => void;
    siteId: string;
    lookupSites: Array<IKeyValue<string>>;
}

export const Faqs: React.FC<Iprops> = (props) => {
    const translate = useTranslate();
    const [faqs, setFaqs] = useState(null)
    const [isEdit, setIsEdit] = useState(false);
    const [isReorder, setIsReOrder] = useState(false);

    const operatingPlatform = getSiteOperatingPlatform(props.siteId, props.lookupSites);
    React.useEffect(() => {
        ReactTooltip.rebuild();
    });

    React.useEffect(() => {
        if (props.language && operatingPlatform)
            props.loadFaqs(props.language, operatingPlatform)
    }, [props.language, operatingPlatform])

    React.useEffect(() => {
        if (props.faqsContent) {
            setFaqs(props.faqsContent)
        }
    }, [props.faqsContent])

    const onSave = () => {
        let updatedFaqs = { ...faqs }
        updatedFaqs = {
            ...updatedFaqs,
            versionNo: updatedFaqs.versionNo + 1,
            operatingPlatform: operatingPlatform
        }
        props.updateFaqs(updatedFaqs);
        setIsEdit(false);
    }
    const onReorder = () => {
        setIsEdit(false);
        setIsReOrder(true);
    }
    const onEdit = () => {
        setIsEdit(true);
        setIsReOrder(false);
    }
    const onCancel = () => {
        setIsEdit(false);
        setIsReOrder(false);
        setFaqs(props.faqsContent)
    }
    const isFaqUnChanged = _.isEqual(
        props.faqsContent && props.faqsContent,
        faqs
    );
    const isDirty = !(isFaqUnChanged)
    return (
        <Page
            title={'FAQS'}
            className="faqs-section"
            scrollTopOnPageLoad={true}
            showConfirmNavigateAwayDialog={isDirty}
        >
            <Loader className="faqs" loading={props.isLoading}>
                {checkPermissions([Privilege.AllFAQsUpdate], props.permission) && props.language === 'en-GB' && <div className="columns btn-float-right">
                    <div className="column is-full">
                        <Button
                            id="btnEditTemplate"
                            buttonType="save"
                            onClick={onReorder}
                        >
                            {translate('Faqs.Label.Reorder')}
                        </Button>
                        <Button
                            id="btnEditTemplate"
                            buttonType="save"
                            onClick={onEdit}
                        >
                            {translate('Faqs.Label.Edit')}
                        </Button>
                    </div>
                </div>}
                {
                    isEdit ?
                        <FaqsEdit
                            faqs={faqs}
                            language={props.language}
                            setIsEdit={setIsEdit}
                            updateFaqs={props.updateFaqs}
                            setFaqs={setFaqs}
                        />

                        : <FaqsReadonly
                            faqs={faqs}
                            language={props.language}
                            setIsReOrder={setIsReOrder}
                            isReorder={isReorder}
                            setFaqs={setFaqs}
                        />
                }

                {checkPermissions([Privilege.AllFAQsUpdate], props.permission) && props.language === 'en-GB' && <Toolbar type="save">

                    <ToolbarGroupLeft>
                        <Button
                            id="btnEditTemplate"
                            buttonType="cancel"
                            onClick={onCancel}
                        >
                            {translate('Globals.Label.Cancel')}
                        </Button>
                    </ToolbarGroupLeft>

                    <ToolbarGroupRight>
                        <FloatingButton
                            id="save"
                            disabled={!isDirty}
                            onClick={onSave}
                            float={isDirty}
                            tooltip={translate('Globals.Label.PleaseClick')}
                        >
                            {translate('Globals.Label.Save')}
                        </FloatingButton>
                    </ToolbarGroupRight>
                </Toolbar>}

            </Loader>
        </Page >
    );
};
