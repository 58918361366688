import { IEmeraldTitleInput } from '../shared/title.input';
import { EmeraldToolbarColor, EmeraldToolbarColorType } from './toolbar.color';

export class EmeraldToolbarCoreInputs implements IEmeraldToolbarCoreInputs {
  color?: EmeraldToolbarColorType = EmeraldToolbarColor.Primary;
  title?: string = 'Tool bar title';
  navigateTo?: string = '/';
  fixed?: boolean = false;
  short?: boolean;
  dense?: boolean;
  prominent?: boolean;
  shortCollapsed?: boolean;
  denseProminent?: boolean;
  handleMenuButtonClick?: (value: boolean) => void;
}

export interface IEmeraldToolbarCoreInputs extends IEmeraldTitleInput {
  color?: EmeraldToolbarColorType;
  navigateTo?: string;
  fixed?: boolean;
  short?: boolean;
  dense?: boolean;
  prominent?: boolean;
  shortCollapsed?: boolean;
  denseProminent?: boolean;
  handleMenuButtonClick?: (value: boolean) => void;
}
