import * as React from 'react';
import { Panel, ConfirmDialog, ToolbarGroupRight, Toolbar, Button } from 'components/v2/components';
import { IKeyValue } from 'models';
import { IMasterProcessStep } from 'models/master-process-step/master-process-step';
import { ICermAssessmentResult, IProcessStepResult, ISetProcessStepResult } from 'models/cerm/cerm-assessment-result';
import { CermProcessStepsTableContainer } from 'components/cerm/CermProcessStepsTableContainer';
import { Loader } from 'components/loader';
import { usePageUIPreferences, UiPreferences } from 'utils/page-ui-preferences-utils';
import useTranslate from 'translations/translation-utils';
import { IExpertReviewStatus } from 'models/cerm/cerm-assessment-result';
import { getUserId } from 'helpers/helpers';
import { FloatingButtons } from 'components/v2/button/FloatingButtons';
import { SiteSelectModal } from 'components/cerm/siteSelectModal';
import { checkPermission } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import { getSiteOperatingPlatform } from 'models/site-profile';
import { ICommonLookupModel } from 'models/common-lookup-model';

interface IProps {
    isLoading: boolean;
    siteId: string;
    pillars: ICommonLookupModel[];
    masterProcessSteps: IMasterProcessStep[];
    cermAssessmentResult: ICermAssessmentResult;
    lookupSites: Array<IKeyValue<string>>;
    expertReviewStatus: IExpertReviewStatus;
    setProcessStepResult: (modal) => void;
    permissions: Array<IKeyValue<string>>;
    lookupSitesClient: Array<IKeyValue<string>>;
    checkCermAssessmentDirty: (isDirty: boolean) => void;
}

export const CermAssessmentTab: React.FC<IProps> = ({
    isLoading,
    siteId,
    pillars,
    masterProcessSteps,
    cermAssessmentResult,
    lookupSites,
    expertReviewStatus,
    setProcessStepResult,
    permissions,
    lookupSitesClient,
    checkCermAssessmentDirty
}) => {
    const [isSiteSelectShown, setIsSiteSelectionShown] = React.useState<boolean>(false);
    const translate = useTranslate();
    const [showTabDisabledDialog, setShowTabDisabledDialog] = React.useState(false);
    const [pillarsCollapsed, setPillarsCollapsed] = usePageUIPreferences<string[]>(
        UiPreferences.CermAssessmentPageCollapsedPillars,
        []
    );
    const [processStepResults, setProcessStepResults] = React.useState<IProcessStepResult[]>([]);
    React.useEffect(() => {
        (processStepResults.length !== 0) ?
            checkCermAssessmentDirty(true) :
            checkCermAssessmentDirty(false)
    }, [processStepResults])
    React.useEffect(() => {
        setProcessStepResults([])
    }, [cermAssessmentResult])
    const sitOperatingPlatform = getSiteOperatingPlatform(siteId, lookupSites);

    const getAvailablePillars = () => {
        return !pillars || !masterProcessSteps
            ? []
            : pillars.filter((p) => masterProcessSteps.some((s) => s.pillar === p.value) && p.operatingPlatform === sitOperatingPlatform);
    }


    const getPillarMasterProcessSteps = (pillar: string) => {
        return masterProcessSteps ? masterProcessSteps.filter((s) => s.pillar === pillar) : null;
    };

    const getPillarProcessStepResults = (pillar: string): IProcessStepResult[] => {
        return cermAssessmentResult
            ? cermAssessmentResult.processStepResults.filter((s) => s.pillar === pillar)
            : null;
    };

    const isPillarCollapsed = (pillar: string) => pillarsCollapsed.indexOf(pillar) > -1;

    const onPillarPanelCollapsed = (pillar: string, collapsed: boolean) => {
        const collapsedItems = collapsed
            ? [...pillarsCollapsed, pillar]
            : pillarsCollapsed.filter((item) => item !== pillar);

        setPillarsCollapsed(collapsedItems);
    };

    const canEdit = () => {
        return (expertReviewStatus === null
            || expertReviewStatus.startedById === undefined
            || (expertReviewStatus.startedById === null)
            || expertReviewStatus.isInReview === false
            || expertReviewStatus.startedById === getUserId()
        )
    }
    const updateProcessStepResult = (processStepResult: IProcessStepResult) => {
        let newProcessStepResults = processStepResults.filter(x => { return x.masterProcessStepId != processStepResult.masterProcessStepId })
        let originalProcessStepResult = cermAssessmentResult?.processStepResults?.find(x => { return x.masterProcessStepId == processStepResult.masterProcessStepId })
        if (originalProcessStepResult) {
            if (originalProcessStepResult.applicable != processStepResult.applicable || originalProcessStepResult.cermTechnologyPlatform != processStepResult.cermTechnologyPlatform) {
                newProcessStepResults.push(processStepResult)
            }
        }
        else {
            newProcessStepResults.push(processStepResult)
        }
        setProcessStepResults(newProcessStepResults)
    }
    const onSave = () => {
        let processStepResult: ISetProcessStepResult = {
            siteId,
            siteIds: [],
            applicableToAccount: false,
            processStepResult: processStepResults
        }
        setProcessStepResult(processStepResult);
    };
    const onSaveAccount = () => {
        setIsSiteSelectionShown(true)
    };

    const onSubmitAccount = (siteIds: string[]) => {
        let processStepResult: ISetProcessStepResult = {
            siteId,
            siteIds: siteIds,
            applicableToAccount: true,
            processStepResult: processStepResults
        }
        setProcessStepResult(processStepResult)
        setIsSiteSelectionShown(false)
    };

    const [sameClientSites, setSameClientSites] = React.useState<Array<IKeyValue<string>>>()

    const getClientSitesByOperatingPlatform = (clientSites: any[]) => {

        return clientSites.filter(x => x.operatingPlatform === sitOperatingPlatform);
    }

    React.useEffect(() => {
        if (lookupSitesClient && lookupSites && siteId) {
            let clientName = lookupSitesClient?.find(x => x.key == siteId)?.value;

            let clientSites = lookupSites?.filter(x =>
                lookupSitesClient?.findIndex(y => y.value == clientName && x.key == y.key) >= 0);
            setSameClientSites(getClientSitesByOperatingPlatform(clientSites))
        }
        else {
            setSameClientSites(null)
        }
    }, [lookupSitesClient, lookupSites, siteId])

    return (
        <div className="tabs-container tab-content tab-cerm-assessment">
            <Loader loading={isLoading}>
                {getAvailablePillars().map((pillar, key) => (
                    <Panel
                        key={"panel_" + key}
                        title={pillar.value}
                        collapsed={isPillarCollapsed(pillar.key)}
                        onCollapsed={(collapsed) => onPillarPanelCollapsed(pillar.key, collapsed)}
                        className="component-pillar"
                    >
                        <CermProcessStepsTableContainer
                            pillar={pillar.value}
                            pillarMasterProcessSteps={getPillarMasterProcessSteps(pillar.value)}
                            pillarProcessStepResults={getPillarProcessStepResults(pillar.value)}
                            ShowTabDisabledDialog={setShowTabDisabledDialog}
                            expertReviewStatus={expertReviewStatus}
                            updateProcessStepResult={updateProcessStepResult}
                        />
                    </Panel>
                ))}
                <ConfirmDialog
                    title={translate('CermAssesmentTab.Title.Information')}
                    message={translate('CermAssesmentTab.Title.Message')}
                    buttonCancelHide={true}
                    buttonConfirmText={translate('CermAssesmentTab.Title.Ok')}
                    isVisible={showTabDisabledDialog}
                    onConfirm={() => setShowTabDisabledDialog(false)}
                    onOutsideDialogClick={() => setShowTabDisabledDialog(false)}
                />
                {canEdit() == true && processStepResults?.length > 0 && (
                    <Toolbar type="save">
                        <ToolbarGroupRight>
                            <FloatingButtons>
                                {checkPermission(Privilege.CermAssessmentApplicabilityAccountLevelUpdate, permissions) && (
                                    <Button
                                        id="save-account-cerm-assessment"
                                        type="button"
                                        disabled={!(sameClientSites && sameClientSites != null)}
                                        onClick={onSaveAccount}
                                    >
                                        {translate('CermComparision.Title.5')}
                                    </Button>
                                )}
                                <Button
                                    id="save-cerm-assessment"
                                    type="button"
                                    onClick={onSave}
                                >
                                    {translate('CermComparision.Title.6')}
                                </Button>
                            </FloatingButtons>
                        </ToolbarGroupRight>
                    </Toolbar>
                )}
                <SiteSelectModal isShown={isSiteSelectShown}
                    siteId={siteId}
                    lookupSites={sameClientSites}
                    onClose={function (): void {
                        setIsSiteSelectionShown(false)
                    }}
                    onSubmit={onSubmitAccount}>
                </SiteSelectModal>
            </Loader>
        </div>
    );
};
