import * as React from 'react';
import { IPerson } from 'models/person';
import { ISiteProfile } from 'models/site-profile';
import { PersonSelector } from '../personSelector';
import './attach-person-form.scss';
import { Button } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';

interface IProps {
    selectedSite: ISiteProfile;
    selectedPerson: IPerson;
    isLinkButtonEnabled: boolean;
    isLinkButtonSubmitting: boolean;
    onLink: (site: ISiteProfile, person: IPerson) => void;
}

export const AttachPersonForm: React.FC<IProps> = (props) => {
    const translate = useTranslate();

    if (!props.selectedSite) {
        return null;
    }

    const onLink = () => props.onLink(
        props.selectedSite,
        props.selectedPerson
    );

    return (
        <>
            <h2 className="title is-5">{props.selectedSite.name}</h2>

            <h3 className="title is-5">{translate('AttachPerson.Form.AttachPeople')}</h3>

            <p>{translate('AttachPerson.Form.AttachPersontoSite')}</p>

            <div className="AttachPersonForm__form">
                <PersonSelector />
                <Button
                    onClick={onLink}
                    isLoading={props.isLinkButtonSubmitting}
                    disabled={!props.isLinkButtonEnabled}
                >
                    {translate('AttachPerson.Form.Attach')}
                </Button>
            </div>
        </>
    );
};
