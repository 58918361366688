import { mapValues } from 'lodash';
import { combineReducers } from 'redux';

import { CermSiteOverviewActions, CermSiteOverviewActionTypes } from 'actions/cerm-site-overview';
import { ICermSiteOverviewGroup } from 'models/cerm-site-overview';
import { IKeyValue } from 'models/key-value';
import { IGridViewState, INITIAL_STATE as gridInitialState } from 'reducers/grid';

export interface ICermSiteOverviewGridState extends IGridViewState<ICermSiteOverviewGroup> {
    expandedFilters: Array<Extract<keyof ICermSiteOverviewGridState, string>>;
    averageScore: number | null;
    isInitialising: boolean;
    searchableProperties: string[];

    regionFilters: string[];
    lineOfBusinessFilters: string[];
    otherFilters: string[];

    lookupRegionFilters: Array<IKeyValue<string>>;
    lookupLineOfBusinessFilters: Array<IKeyValue<string>>;
    lookupOtherFilters: Array<IKeyValue<string>>;
}

export const INITIAL_STATE: ICermSiteOverviewGridState = {
    ...gridInitialState,
    expandedFilters: [
        'regionFilters',
        'lineOfBusinessFilters',
        'otherFilters'
    ],

    averageScore: null,
    searchableProperties: [],
    isInitialising: true,

    regionFilters: [],
    lineOfBusinessFilters: [],
    otherFilters: [],

    lookupRegionFilters: [],
    lookupLineOfBusinessFilters: [],
    lookupOtherFilters: []
};

const lookupRegionFilters = (
    state = INITIAL_STATE.lookupRegionFilters,
    action: CermSiteOverviewActionTypes
) => {
    switch (action.type) {
        case CermSiteOverviewActions.LOAD_PAGE_FULFILLED:
             return action.payload.grid !== undefined ? action.payload.grid.lookupRegionFilters : state;
        default:
            return state;
    }
};

const lookupLineOfBusinessFilters = (
    state = INITIAL_STATE.lookupLineOfBusinessFilters,
    action: CermSiteOverviewActionTypes
) => {
    switch (action.type) {
        case CermSiteOverviewActions.LOAD_PAGE_FULFILLED:
             return action.payload.grid !== undefined ? action.payload.grid.lookupLineOfBusinessFilters : state;
        default:
            return state;
    }
};

const lookupOtherFilters = (
    state = INITIAL_STATE.lookupOtherFilters,
    action: CermSiteOverviewActionTypes
) => {
    switch (action.type) {
        case CermSiteOverviewActions.LOAD_PAGE_FULFILLED:
             return action.payload.grid !== undefined ? action.payload.grid.lookupOtherFilters : state;
        default:
            return state;
    }
};

const passThrough = <T>(defaultValue: T) => (state: T = defaultValue) => state;
const passThroughReducer = mapValues(INITIAL_STATE, passThrough);

type SubType<Base, Condition> = Pick<Base, {
    [Key in keyof Base]: Base[Key] extends Condition ? Key : never
}[keyof Base]>;

function toggleFilters<K extends keyof SubType<ICermSiteOverviewGridState, string[]>>(
    filter: K
) {
    return (state: string[] = INITIAL_STATE[filter], action: CermSiteOverviewActionTypes) => {
        switch (action.type) {
            case CermSiteOverviewActions.CHANGE_FILTER_VALUE:
                if (action.payload.field !== filter) {
                    return state;
                }

                const filterValue = action.payload.value;
                if (state.includes(filterValue)) {
                    return state.filter((item) => item !== filterValue);
                } else {
                    if (filterValue) {
                        return state.concat(filterValue);
                    }

                    return state;
                }

            case CermSiteOverviewActions.LOAD_PAGE_FULFILLED:
                // tslint:disable-next-line:no-string-literal
                return action.payload.grid[filter] !== undefined ? action.payload.grid[filter] : state;

            case CermSiteOverviewActions.CLEAR_FILTERS:
                return [];

            default:
                return state;
        }
    };
}

const expandedFilters = (
    state = INITIAL_STATE.expandedFilters,
    action: CermSiteOverviewActionTypes
) => {
    switch (action.type) {
        case CermSiteOverviewActions.LOAD_PAGE_FULFILLED:
                return action.payload.grid !== undefined ? action.payload.grid.expandedFilters : state;
        case CermSiteOverviewActions.TOGGLE_FILTER_EXPANDED:
            const id = action.payload.id;
            if (state.includes(id)) {
                return state.filter((c) => c !== id);
            }
            return [...state, id];

        default:
            return state;
    }
};

const expandedItems = (
    state = INITIAL_STATE.expandedItems,
    action: CermSiteOverviewActionTypes
) => {
    switch (action.type) {
        case CermSiteOverviewActions.LOAD_PAGE_FULFILLED:
                return action.payload.grid.expandedItems !== undefined ? action.payload.grid.expandedItems : state;
        case CermSiteOverviewActions.TOGGLE_ITEM_EXPANDED:
            const id = action.payload.id;
            if (state.includes(id)) {
                return state.filter((c) => c !== id);
            }
            return [...state, id];

        case CermSiteOverviewActions.COLLAPSE_ALL_ITEMS:
            return [];

        case CermSiteOverviewActions.EXPAND_ITEMS:
            return action.payload.ids;

        default:
            return state;
    }
};

const sortColumn = (
    state = INITIAL_STATE.sortColumn,
    action: CermSiteOverviewActionTypes
) => {
    switch (action.type) {
        case CermSiteOverviewActions.LOAD_PAGE_FULFILLED:
            return action.payload.grid !== undefined ? action.payload.grid.sortColumn : state;
        case CermSiteOverviewActions.CHANGE_SORT_COLUMN:
            return action.payload.column;

        default:
            return state;
    }
};

const sortAscending = (
    state = INITIAL_STATE.sortAscending,
    action: CermSiteOverviewActionTypes
) => {
    switch (action.type) {
        case CermSiteOverviewActions.LOAD_PAGE_FULFILLED:
            return action.payload.grid !== undefined ? action.payload.grid.sortAscending : state;
        case CermSiteOverviewActions.CHANGE_SORT_COLUMN:
            return action.payload.isAscending;

        default:
            return state;
    }
};

const isLoading = (
    state = INITIAL_STATE.isLoading,
    action: CermSiteOverviewActionTypes
) => {
    switch (action.type) {
        case CermSiteOverviewActions.LOAD_PAGE:
        case CermSiteOverviewActions.LOAD_DATA:
            return true;
        case CermSiteOverviewActions.LOAD_PAGE_CANCELLED:
        case CermSiteOverviewActions.LOAD_PAGE_REJECTED:
        case CermSiteOverviewActions.LOAD_DATA_CANCELLED:
        case CermSiteOverviewActions.LOAD_DATA_FULFILLED:
        case CermSiteOverviewActions.LOAD_DATA_REJECTED:
            return false;

        default:
            return state;
    }
};

const items = (
    state = INITIAL_STATE.items,
    action: CermSiteOverviewActionTypes
) => {
    switch (action.type) {
        case CermSiteOverviewActions.LOAD_PAGE_FULFILLED:
             return action.payload.grid !== undefined ? action.payload.grid.items : state;
        case CermSiteOverviewActions.LOAD_DATA_FULFILLED:
            return action.payload.data;

        default:
            return state;
    }
};

const isInitialising = (
    state: ICermSiteOverviewGridState['isInitialising'] = INITIAL_STATE.isInitialising,
    action: CermSiteOverviewActionTypes
) => {
    switch (action.type) {
        case CermSiteOverviewActions.LOAD_PAGE_FULFILLED:
            return false;
        default:
            return state;
    }
};

const regionFilters = toggleFilters('regionFilters');
const lineOfBusinessFilters = toggleFilters('lineOfBusinessFilters');
const otherFilters = toggleFilters('otherFilters');

export const reducer = combineReducers<ICermSiteOverviewGridState>({
    ...passThroughReducer as any,
    lookupRegionFilters,
    lookupLineOfBusinessFilters,
    lookupOtherFilters,
    expandedFilters,
    expandedItems,
    lineOfBusinessFilters,
    otherFilters,
    regionFilters,
    isInitialising,
    sortAscending,
    sortColumn,
    isLoading,
    items,
});
