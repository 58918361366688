import React from 'react';
import { history } from 'routes/App';
import { RouteComponentProps, withRouter } from 'react-router';
import { TabGroup, TabItem, OverlayPanel } from 'components/v2/components';
import { ClientRiskRegisterTable } from 'components/risk-register/client-risk-register-table';
import { IRisk } from 'models/risk';
import { IRiskFilters } from 'models/IRiskFilters';
import { connect } from 'react-redux';
import selectors from './selectors';
import actions from './actions';
import { Loader } from 'components/loader';
import { RiskFilters } from '../risks/views/riskFilters';
import { RiskRegisterGridToolbar } from 'components/risk-register/risk-register-toolbar';
import { onRouteChange } from 'actions/app-actions';
import Page from 'components/v2/page/Page';
import { usePageUIPreferences, UiPreferences } from 'utils/page-ui-preferences-utils';
import { ModuleType } from 'models/help-module';
import { HelpModuleContainer } from '../help-module/help-module-container';
import { checkFilterExistsWithExceptions } from 'utils/array-utils';
import { IKeyValue } from 'models';
import { getSiteOperatingPlatform } from 'models/site-profile';
import * as RiskStatus from 'models/risk-statuses';
import { RiskRegisterOverview } from 'components/risk-register/risk-register-overview';
import useTranslate from 'translations/translation-utils';

interface IParams {
    tab: string;
}

interface IProps extends RouteComponentProps<IParams> {
    siteId: string;
    isLoading: boolean;
    risks: IRisk[];
    loadAll: (filters: IRiskFilters) => void;
    exportData?: (siteId: string, clientExport: boolean) => void;
    exportAllData?: (clientExport: boolean, operatingPlatform: string) => void;
    isExporting: boolean;
    showClientRisks: boolean;
    isClient: boolean;
    showHelpModule: boolean;
    lookupSites: IKeyValue<string>[];
    permissions: Array<IKeyValue<string>>;
}

enum Tab {
    OpenPending = 'OpenPending',
    OpenAccepted = 'OpenAccepted',
    OpenApproved = 'OpenApproved',
    CompletedMitigation = 'CompletedMitigation',
    ClientRisks = 'ClientRisks',
    ClosedRisks = 'ClosedRisks',
}

export const RiskRegisterClientPage: React.FC<IProps> = ({
    siteId,
    loadAll,
    risks,
    match,
    isLoading,
    exportData,
    exportAllData,
    isExporting,
    showClientRisks,
    isClient,
    showHelpModule,
    lookupSites,
    permissions,
}) => {
    const paramsTab = match.params.tab ? match.params.tab : Tab.OpenPending;

    const getUpdatedFilters = (
        updatedRiskFilters: IRiskFilters,
        key: Extract<keyof IRiskFilters, string>,
        element: string
    ) => {
        const specialFilters = [RiskStatus.CLOSED];
        if (specialFilters.some((c) => c === element)) {
            return {
                ...updatedRiskFilters,
                riskStatus: [element],
            };
        }

        const newFilters = { ...updatedRiskFilters };
        const index = newFilters[key].indexOf(element);
        if (index !== -1) {
            const items = [...newFilters[key].slice(0, index), ...newFilters[key].slice(index + 1)];
            newFilters[key] = items;
        } else {
            const items = [element, ...newFilters[key].slice(0, newFilters[key].length)];
            newFilters[key] = items;
        }
        const filtersWithOutSpecials = {
            ...newFilters,
            riskStatus: newFilters.riskStatus.filter((f) => !specialFilters.some((c) => c === f)),
        } as IRiskFilters;

        return filtersWithOutSpecials;
    };

    const getRiskStatus = () => {
        switch (paramsTab) {
            case Tab.OpenPending:
                return [RiskStatus.MITIGATION_PENDING_CLIENT_APPROVAL];
            case Tab.OpenAccepted:
                return [RiskStatus.ACCEPTED];
            case Tab.OpenApproved:
                return [RiskStatus.MITIGATION_PENDING_MITIGATION];
            case Tab.CompletedMitigation:
                return [RiskStatus.OPEN_AND_PENDING_REVIEW];
            case Tab.ClosedRisks:
                return [RiskStatus.CLOSED];
            case Tab.ClientRisks:
            default:
                return [];
        }
    };

    const initializeFilters = () => {
        let initialFilters = {
            filterKeywords: [],
            riskCategory: [],
            riskLevel: [],
            riskSpecial: ['ClientRisksOnly'],
            riskStatus: getRiskStatus(),
            siteId,
        } as IRiskFilters;
        const urlParams = new URLSearchParams(location.search);
        urlParams.forEach((element, key) => {
            initialFilters = getUpdatedFilters(initialFilters, key as any, element);
        });
        return initialFilters;
    };

    const [showFilters, setShowFilters] = React.useState(false);
    const [showHelp, setShowHelp] = React.useState(false);
    const [currentTab, setCurrentTab] = React.useState<string>(paramsTab);
    const [filters, setFilters] = usePageUIPreferences<IRiskFilters>(
        UiPreferences.RisksClientPageFilters,
        initializeFilters()
    );
    const operatingPlatform = getSiteOperatingPlatform(siteId, lookupSites);
    const buildRequestFiltersObject = (keepState: boolean) => {
        const basicSearch = {
            riskStatus: getRiskStatus(),
            riskCategory: filters && keepState ? filters.riskCategory : [],
            riskLevel: filters && keepState ? filters.riskLevel : [],
            riskSpecial: ['ClientRisksOnly'],
            filterKeywords: filters && keepState ? filters.filterKeywords : [],
            siteId,
        } as IRiskFilters;

        if (currentTab === Tab.CompletedMitigation) {
            basicSearch.riskSpecial.push(Tab.CompletedMitigation);
        }

        if (currentTab === Tab.ClosedRisks) {
            basicSearch.riskSpecial.push('Closed');
        }

        return basicSearch;
    };

    React.useEffect(() => {
        if (!currentTab || !siteId) {
            return;
        }
        if (siteId && isClient && !showClientRisks) {
            onRouteChange('/ClientDashboard');
        }

        loadAll(buildRequestFiltersObject(true));
    }, [siteId, currentTab, filters]);

    const toggleFilters = () => {
        setShowFilters(showFilters ? false : true);
    };

    const setNavLink = (tab: Tab) => {
        if (match.params.tab) {
            return match.path.replace(':tab', tab);
        } else {
            return match.path.replace(match.path, `${match.path}/${tab}`);
        }
    };

    const handleTabClick = (event: any, tab: Tab) => {
        event.preventDefault();
        setCurrentTab(tab);
        history.push(setNavLink(tab));
    };

    const onAddFilterKeyword = (keyword) => {
        if (!keyword) {
            return;
        }
        const newFilterKeywords = [...filters.filterKeywords, keyword];
        setFilters({ ...filters, filterKeywords: newFilterKeywords });
    };

    const onRemoveFilterKeyword = (keyword) => {
        const newFilterKeywords = filters.filterKeywords.filter((x) => x !== keyword);
        setFilters({ ...filters, filterKeywords: newFilterKeywords });
    };

    const onFilter = (key: Extract<keyof IRiskFilters, string>, element: string) => {
        const updatedFilters = getUpdatedFilters(filters, key, element);
        setFilters(updatedFilters);
    };

    const resetFilters = () => {
        setFilters(buildRequestFiltersObject(false));
    };
    const onClickHelp = () => {
        setShowHelp(!showHelp);
    };
    const translate = useTranslate();
    const isFilterExists = checkFilterExistsWithExceptions(filters, ['riskStatus', 'riskSpecial']);
    return (
        <Page title={translate('SideNavBar.Labels.ClientRiskRegister')} className="risks-register-page">
            <RiskRegisterGridToolbar
                isClient={true}
                onShowFilters={toggleFilters}
                isShowHelp={showHelpModule || true}
                onClearFilters={resetFilters}
                onExport={exportData}
                onExportAll={exportAllData}
                disableExport={isExporting || !risks || risks.length === 0}
                onClickHelp={onClickHelp}
                isFilterExists={isFilterExists}
                operatingPlatform={operatingPlatform}
                permissions={permissions}
            />
            <HelpModuleContainer
                isShown={showHelp}
                onClose={onClickHelp}
                moduleType={ModuleType.ClientRisk}
            />
            <OverlayPanel
                title={translate('RiskRegisterPage.Title.Filters')}
                isVisible={showFilters}
                onClose={() => setShowFilters(false)}
                onOutsideDialogClick={() => setShowFilters(false)}
                onClearFilters={resetFilters}
                isFilterExists={isFilterExists}
            >
                <RiskFilters
                    hideFilters={['riskStatus', 'riskSpecial']}
                    onRemoveFilterKeyword={onRemoveFilterKeyword}
                    onFilter={onFilter}
                    onAddFilterKeyword={onAddFilterKeyword}
                    filters={filters}
                />
            </OverlayPanel>
            <RiskRegisterOverview isClientView={true} risks={risks} />
            <TabGroup type="multi-line">
                <TabItem
                    title={translate('RiskActionTab.ActionStatus.dropdown.PendingApproval')}
                    tab={Tab.OpenPending}
                    currentTab={currentTab}
                    onClick={handleTabClick}
                />
                <TabItem
                    title={translate('Globals.Status.Open&RiskAccepted')}
                    tab={Tab.OpenAccepted}
                    currentTab={currentTab}
                    onClick={handleTabClick}
                />
                <TabItem
                    title={translate('Globals.Status.MitigationLevel.PendingMitigation')}
                    tab={Tab.OpenApproved}
                    currentTab={currentTab}
                    onClick={handleTabClick}
                />
                <TabItem
                    title={translate('RiskRegisterClient.Page.CompltedMitigation')}
                    tab={Tab.CompletedMitigation}
                    currentTab={currentTab}
                    onClick={handleTabClick}
                />
                <TabItem
                    title={translate('Globals.Status.OpenRisks')}
                    tab={Tab.ClientRisks}
                    currentTab={currentTab}
                    onClick={handleTabClick}
                />
                <TabItem
                    title={translate('Globals.Status.ClosedRisks')}
                    tab={Tab.ClosedRisks}
                    currentTab={currentTab}
                    onClick={handleTabClick}
                />
            </TabGroup>
            <div className="tabs-container tabs-container-table-only">
                <Loader loading={isLoading}>
                    {risks && risks.length > 0 ? (
                        <ClientRiskRegisterTable risks={risks} />
                    ) : (
                        !isLoading && (
                            <div className="section has-text-centered">{translate('RiskRegisterPage.Message.NoDataAvailable')}</div>
                        )
                    )}
                </Loader>
            </div>
        </Page>
    );
};

export default withRouter(connect(selectors, actions)(RiskRegisterClientPage));
