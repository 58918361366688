// import { IKeyValue } from 'models/key-value';
// import { getDefaultEngineersGridState, IEngineersGridState } from './engineers-grid-state';
import { IEngineer, IObsoleteSkillCategory } from 'models/engineer';

export interface IEngineersState {
    engineers: IEngineer[];
    isLoading: boolean;
    isExportingEngineers: boolean;
    isExportingAllEngineers: boolean;
    isSaving: boolean;
    skillCategories: IObsoleteSkillCategory[];
}

const defaultState: IEngineersState = {
    engineers: [],
    isLoading: false,
    isExportingEngineers: false,
    isExportingAllEngineers: false,
    isSaving: false,
    skillCategories: [],
};

// tslint:disable-next-line:arrow-return-shorthand
export const getDefaultEngineersState = (options?: any) => { return {...defaultState, ...options }; };
