import * as React from 'react';
import { TextAreaField } from 'components/form/fields/textarea-field';
import { ConfirmDialog } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';
import { IGlobalSkill } from 'models/admin-cdna/global-skill';
import { engineerSkillLevels } from 'models';
import { IOption, SelectField } from 'components/form/fields/select-field';
import { Formik, FormikErrors, FormikProps } from 'formik';
import { object, string } from 'yup';
import { Help } from 'components/form';
import { BulmaColor } from 'enums/BulmaColor';

export interface IProps {
    skillCategoryId: string;
    globalSkill?: IGlobalSkill;
    isVisible: boolean;
    isLoading: boolean;
    onSave(globalSkill: IGlobalSkill): void;
    onCancel(): void;
}

export const GlobalSkillDialog: React.FC<IProps> = ({
    skillCategoryId,
    globalSkill,
    isVisible,
    isLoading,
    onSave,
    onCancel,
}) => {
    const translate = useTranslate();

    let formikBag: FormikProps<Partial<IGlobalSkill>>;

    const formValidationSchema = object<Partial<IGlobalSkill>>().shape({
        skillLevel: string()
            .trim()
            .required(translate('CompetencyDNAProcessesAdmin.GlobalSkill.Validation.SkillLevel'))
            .nullable()
        ,
        description: string()
            .max(2048, translate('API.CDNA.GlobalSkillDescriptionRequiredAndMaxCharacterWarning'))
            .required(translate('API.CDNA.GlobalSkillDescriptionRequiredAndMaxCharacterWarning'))
            .nullable()
        ,
    });

    const dialogTitleTranslationKey = globalSkill?.id
        ? 'CompetencyDNAProcessesAdmin.GlobalSkill.DialogTitle.Edit'
        : 'CompetencyDNAProcessesAdmin.GlobalSkill.DialogTitle.Add';

    const isValid = () => {
        return formikBag?.values?.id ?
            formikBag?.isValid :
            formikBag?.dirty ?
                Object.keys(formikBag?.errors).length === 0 :
                false;
    }

    const getHelper = (formikErrors: FormikErrors<any> | any) => {
        return (
            <Help
                bulmaColor={BulmaColor.Danger}
                isShown={(formikErrors && formikErrors.length > 0)}
            >
                {formikErrors}
            </Help>
        );
    };

    const onHandleChange = async (e: any) => {
        e.preventDefault();
        const { name, value } = e.target;
        await formikBag.setFieldValue(name, value, true);
    }

    const onChangeSkillLevel = async (level: IOption) =>
        await formikBag.setFieldValue('skillLevel', level.value);

    const getBody = (formik: FormikProps<Partial<IGlobalSkill>>) => {
        return (
            <>
                <SelectField
                    id="skillLevel"
                    label={translate('RiskRegisterPage.RiskFilter.Title.Level')}
                    value={{
                        value: formik?.values?.skillLevel,
                        label: formik?.values?.skillLevel,
                    }}
                    handleChange={onChangeSkillLevel}
                    options={engineerSkillLevels.map((x) => ({
                        key: x.key,
                        value: x.key,
                        label: x.value,
                    }))}
                />
                {getHelper((formik.errors && formik.errors.skillLevel) ?? "")}
                <TextAreaField
                    id="description"
                    label={translate('AddLocalSkillCat.Label.4')}
                    value={formik?.values?.description}
                    handleChange={onHandleChange}
                />
                {getHelper((formik.errors && formik.errors.description) ?? "")}
            </>
        );
    }

    const renderModel = (formik: FormikProps<Partial<IGlobalSkill>>) => {
        return (
            <ConfirmDialog
                id="globalSkillDialog"
                isVisible={isVisible}
                onConfirm={formik.submitForm}
                onClose={() => {
                    onCancel();
                }}
                buttonConfirmText={translate('Globals.Label.Save')}
                confirmButtonDisabled={!isValid()}
                title={translate(dialogTitleTranslationKey)}
                message={getBody(formik)}
                isLoading={isLoading}
            />
        );
    };

    return (
        <>
            <Formik<Partial<IGlobalSkill>>
                initialValues={globalSkill}
                validationSchema={formValidationSchema}
                onSubmit={(values: IGlobalSkill) => onSave({ ...values, skillCategoryId })}
                render={(formik) => {
                    formikBag = formik;
                    return renderModel(formik);
                }}
                enableReinitialize={true}
                isInitialValid={true}
                validateOnChange={true}
                validateOnBlur={true}
            />
        </>
    );
};
