import { refreshECermToken } from 'actions/actions-v2/cerm-assessment-action-v3';
import { NavActions, NavActionTypes } from '../../actions/nav-actions';

export default {
    onNotificationRead: (id: string, notificationLink: string): NavActionTypes => ({
        type: NavActions.NOTIFICATION_READ,
        payload: {
            id,
            notificationLink,
        },
    }),
    onMarkAllRead: (): NavActionTypes => ({
        type: NavActions.MARK_ALL_READ,
    }),
    getECermToken : refreshECermToken,
};
