import { RiskActions, RiskActionTypes } from '../../../../actions/risk-actions';
import { IKeyedItem } from '../../../../models/keyed-item';

export default {
    onLoadMore: (item: IKeyedItem, continuationToken: string, pageSize: number): RiskActionTypes => ({
        type: RiskActions.LOAD_MORE_HISTORY,
        payload: {
            id: item.id,
            siteId: item.siteId,
            continuationToken,
            pageSize
        }
    }),
    onOpen: (item: IKeyedItem): RiskActionTypes => ({
        type: RiskActions.SHOW_HISTORY,
        payload: {
            id: item.id,
            siteId: item.siteId
        }
    }),

    onClose: (item: IKeyedItem): RiskActionTypes => ({
        type: RiskActions.HIDE_HISTORY,
        payload: {
            id: item.id,
            siteId: item.siteId
        }
    }),
};
