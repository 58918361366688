import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Page from 'components/v2/page/Page';
import { IMockDrillsFilters, IMockDrillInfo, MockDrillType } from 'models/mock-drills';
import { Table, SortOrder, OverlayPanel, TableHeaders } from 'components/v2/components';
import { Loader } from 'components/loader';
import { MockDrillsPageToolbar } from './shared/mock-drills-page-toolbar';
import { MockDrillsPageFilters } from './shared/mock-drills-page-filters';
import { onRouteChange } from 'actions/app-actions';
import { sortArrayBy } from 'utils/sort-utils';
import { UiPreferences, usePageUIPreferences } from 'utils/page-ui-preferences-utils';
import { isEmpty } from 'lodash';
import { formatDateString } from 'utils/date-utils';
import { getDisplayName } from 'utils/string-utils';
import { HelpModuleContainer } from 'routes/help-module/help-module-container';
import { ModuleType } from 'models/help-module';
import { checkFilterExists } from 'utils/array-utils';
import useTranslate from 'translations/translation-utils';

interface IRouteParams {
    scenarioType?: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
    siteId: string;
    isLoading: boolean;
    isExporting: boolean;
    mockDrills: IMockDrillInfo[];
    hasReadWritePermission: boolean;
    loadMockDrills: (filters: IMockDrillsFilters) => void;
    exportMockDrills: (siteId: string) => void;
    showHelpModule: boolean;
}

export const MockDrillsPage: React.FC<IProps> = ({
    siteId,
    isLoading,
    isExporting,
    mockDrills,
    hasReadWritePermission,
    loadMockDrills,
    exportMockDrills,
    showHelpModule,
    match,
}) => {
    const translate = useTranslate();
    const getUpdatedFilters = (
        updatedFilters: IMockDrillsFilters,
        key: Extract<keyof IMockDrillsFilters, string>,
        element: string
    ) => {
        const newFilters = { ...updatedFilters };
        const index = newFilters[key].indexOf(element);

        if (index !== -1) {
            const items = [...newFilters[key].slice(0, index), ...newFilters[key].slice(index + 1)];
            newFilters[key] = items;
        } else {
            const items = [element, ...newFilters[key].slice(0, newFilters[key].length)];
            newFilters[key] = items;
        }

        return newFilters;
    };

    const initializeFilters = () => {
        const initialFilters = {
            filterKeywords: [],
            filterScenarioTypes: [],
            siteId,
        } as IMockDrillsFilters;

        return initialFilters;
    };

    const scenarioType = match.params.scenarioType ?? 'MockDrills';
    const [sortedList, setSortedList] = React.useState<IMockDrillInfo[]>(null);
    const [showFilters, setShowFilters] = React.useState(false);
    const [showHelp, setShowHelp] = React.useState(false);
    const [filters, setFilters] = usePageUIPreferences<IMockDrillsFilters>(
        UiPreferences.MockDrillsPageFilters,
        initializeFilters()
    );

    React.useEffect(() => {
        if (!siteId) {
            return;
        }

        loadMockDrills(buildRequestFiltersObject(true));
    }, [siteId, filters]);

    React.useEffect(() => {
        if (mockDrills === null) {
            return;
        }

        setSortedList(mockDrills);
    }, [mockDrills]);

    React.useEffect(() => {
        if (scenarioType) {
            setFilters({
                ...buildRequestFiltersObject(true),
                filterScenarioTypes: [scenarioType],
            });
        }
    }, [scenarioType]);

    const buildRequestFiltersObject = (keepState: boolean) => {
        return {
            filterKeywords: filters && keepState ? filters.filterKeywords ? filters.filterKeywords : [] : [],
            filterScenarioTypes: filters && keepState ? filters.filterScenarioTypes ? filters.filterScenarioTypes : [] : [],
            siteId,
        } as IMockDrillsFilters;
    };

    const onAddFilterKeywordHandler = (keyword: string) => {
        if (!keyword) {
            return;
        }
        const newFilterKeywords = [...filters.filterKeywords, keyword];
        setFilters({ ...filters, filterKeywords: newFilterKeywords });
    };

    const onRemoveFilterKeywordHandler = (keyword) => {
        const newFilterKeywords = filters.filterKeywords.filter((x) => x !== keyword);
        setFilters({ ...filters, filterKeywords: newFilterKeywords });
    };

    const onResetFiltersHandler = () => {
        setFilters(buildRequestFiltersObject(false));
    };

    const onToggleHandler = (field: string, sortOrder: SortOrder) => {
        if (sortOrder === SortOrder.off) {
            setSortedList(mockDrills);
        }

        setSortedList(sortArrayBy(field, mockDrills, sortOrder === SortOrder.asc));
    };

    const onFilter = (key: Extract<keyof IMockDrillsFilters, string>, element: string) => {
        const updatedFilters = getUpdatedFilters(buildRequestFiltersObject(true), key, element);
        setFilters(updatedFilters);
    };

    const buildMainTable = () => {
        if (sortedList === null) {
            return null;
        }

        if (isEmpty(sortedList)) {
            return (
                <div className="no-data">
                    <h2>{translate('GlobalMockDrills.Title.5')}</h2>
                    {hasReadWritePermission && (
                        <>
                            <p>
                                {translate('GlobalMockDrills.Title.9')}
                                <br />
                                {translate('GlobalMockDrills.Title.10')}
                            </p>
                            <p>
                                <strong>
                                    {translate('GlobalMockDrills.Title.6a')}
                                </strong>
                            </p>
                        </>
                    )}
                </div>
            );
        }

        return (
            <Table>
                <TableHeaders
                    headers={[
                        {
                            title: translate('GlobalMockDrills.Title.7'),
                            sortField: 'name',
                        },
                        {
                            title: translate('Attachments.AttachmentList.Type'),
                            sortField: 'scenarioType',
                        },
                        {
                            title: translate('Attachments.AttachmentList.Leader'),
                            sortField: 'drillLeader',
                            className: 'drill-leader',
                        },
                        {
                            title: translate('Attachments.AttachmentList.LastRun'),
                            sortField: 'lastRun',
                            className: 'narrow no-wrap',
                        },
                    ]}
                    sortFieldUiPreference={UiPreferences.MockDrillsPageSortField}
                    sortOrderUiPreference={UiPreferences.MockDrillsPageSortOrder}
                    onToggle={onToggleHandler}
                />
                {buildRows(MockDrillType.Local)}
                {buildRows(MockDrillType.Global)}
            </Table>
        );
    };

    const buildRows = (type: string) => {
        const list = sortedList.filter((s) => s.drillType === type);
        if (isEmpty(list)) {
            return null;
        }

        return (
            <>
                <tbody>
                    <tr className="sub-header">
                        <td colSpan={4}>
                            {type === MockDrillType.Local ? translate('MockDrill.Repost.Lable.22') : translate('GlobalMockDrills.Title.2')}
                        </td>
                    </tr>
                </tbody>
                <tbody className="clickable">
                    {list.map((item) => (
                        <tr
                            key={item.id}
                            onClick={() =>
                                onRouteChange(`/MockDrill/${item.scenarioType.replace(/\s/g, '')}/Reports/${item.drillType}/${item.id}`)
                            }
                        >
                            <td>{item.name}</td>
                            <td>{item.scenarioType}</td>
                            <td>{getDisplayName(item.drillLeader)}</td>
                            <td>{formatDateString(item.lastRun, 'DD MMM YYYY', '-')}</td>
                        </tr>
                    ))}
                </tbody>
            </>
        );
    };

    const onClickHelp = () => {
        setShowHelp(!showHelp);
    };

    const isFilterExists = checkFilterExists(filters);
    return (
        <Page title={translate('GlobalMockDrills.Scenarios')} className="mock-drills-page">
            <MockDrillsPageToolbar
                onShowFilters={() => setShowFilters(!showFilters)}
                onClearFilters={onResetFiltersHandler}
                disableExport={isExporting}
                onExport={() => exportMockDrills(siteId)}
                hasReadWritePermission={hasReadWritePermission}
                isShowHelp={showHelpModule || true}
                onClickHelp={onClickHelp}
                isFilterExists={isFilterExists}
                scenarioType={scenarioType}
            />
            <HelpModuleContainer
                isShown={showHelp}
                onClose={onClickHelp}
                moduleType={ModuleType.MockDrill}
            />
            <OverlayPanel
                title={translate('RiskRegisterPage.Title.Filters')}
                isVisible={showFilters}
                onClose={() => setShowFilters(false)}
                onOutsideDialogClick={() => setShowFilters(false)}
                onClearFilters={onResetFiltersHandler}
                isFilterExists={isFilterExists}
            >
                <MockDrillsPageFilters
                    filters={buildRequestFiltersObject(true)}
                    onAddFilterKeyword={onAddFilterKeywordHandler}
                    onRemoveFilterKeyword={onRemoveFilterKeywordHandler}
                    onFilter={onFilter}
                />
            </OverlayPanel>
            <Loader loading={isLoading}>{buildMainTable()}</Loader>
        </Page>
    );
};
