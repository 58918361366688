import { IAppAction } from '../app-action';

export enum SupportTeamActions {
    LOAD_TEAM = '@@loadteam/LOAD_TEAM',
    LOAD_TEAM_FULFILLED = '@@loadteam/LOAD_TEAM_FULFILLED',
    LOAD_TEAM_REJECTED = '@@loadteam/LOAD_TEAM_REJECTED',
    RESET_SUPPORT_TEAM = '@@loadteam/RESET_SUPPORT_TEAM',
}

export const loadTeam = (region: string): IAppAction => ({
    type: SupportTeamActions.LOAD_TEAM,
    payload: { region },
});

export const resetTeam = (): IAppAction => ({
    type: SupportTeamActions.RESET_SUPPORT_TEAM,
});
