import { createSelector } from 'reselect';
import { ISiteRelationshipsRootState } from 'reducers/siteRelationships';

const getSiteSelectorState = (state: ISiteRelationshipsRootState) =>
    state.siteRelationships.siteSelector;

const getPeopleSelectorState = (state: ISiteRelationshipsRootState) =>
    state.siteRelationships.personSelector;

const getIsLinkButtonEnabled = createSelector(
    getSiteSelectorState,
    getPeopleSelectorState,
    (siteState, peopleState) =>
        Boolean(
            siteState.selectedSite && peopleState.selectedPerson
        ) && !peopleState.isSubmitting
);

export default createSelector(
    getSiteSelectorState,
    getPeopleSelectorState,
    getIsLinkButtonEnabled,
    (siteState, peopleState, isLinkButtonEnabled) => ({
        selectedSite: siteState.selectedSite,
        selectedPerson: peopleState.selectedPerson,
        isLinkButtonEnabled,
        isLinkButtonSubmitting: peopleState.isSubmitting
    })
);
