import { IEmeraldTitleInput } from '../shared/title.input';

export class EmeraldDividerCoreInputs implements IEmeraldDividerCoreInputs {
  vertical?: boolean;
  isWhite?: boolean;
  allowMargin?: boolean;
  title?: string;
}
export interface IEmeraldDividerCoreInputs extends IEmeraldTitleInput {
  vertical?: boolean;
  isWhite?: boolean;
  allowMargin?: boolean;
}
