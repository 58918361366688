//No More used
import * as React from 'react';

import { IAuditHistory } from '../../models/audit-history';
import { IKeyedItem } from '../../models/keyed-item';
import { DateTime, Format } from '../dateTime';
import { GridContentExpander } from '../gridContentExpander';
import useTranslate from 'translations/translation-utils';

import moment from 'moment';
import './audit-history.scss';

export interface IAuditItemValue<T> {
    label: string;
    value: (input: T) => string;
}

export interface IAuditHistoryStateProps<T extends IAuditHistory = IAuditHistory> {
    continuationToken: string;
    pageSize: number;
    item: IKeyedItem;
    title: string;
    data: T[];
    fields: Array<IAuditItemValue<T>>;
    isExpanded: boolean;
    isLoading: boolean;
    errorMessage?: string;
}

export interface IAuditHistoryActionProps {
    onLoadMore?: (item: IKeyedItem, continuationToken: string, pageSize: number) => void;
    onOpen: (item: IKeyedItem) => void;
    onClose: (item: IKeyedItem) => void;
    onRetry?: (item: IKeyedItem) => void;
}

interface IProps extends IAuditHistoryStateProps, IAuditHistoryActionProps { }

export const AuditHistory: React.FC<IProps> = (props) => {
    const onLoadMore = () => props.onLoadMore(props.item, props.continuationToken, props.pageSize);
    const onOpen = () => props.onOpen(props.item);
    const onClose = () => props.onClose(props.item);
    const translate = useTranslate();

    const formatValue = (field: IAuditItemValue<IAuditHistory>, item: IAuditHistory) => {
        const date = moment(field.value(item), 'YYYY-MM-DD', true);
        if (date.isValid()) {
            return (<DateTime value={field.value(item)} format={Format.DateOnly} />);
        }
        return field.value(item);
    };

    const loadMoreButton = () => {
        return (<div className="button" onClick={onLoadMore}>{translate('AuditHistory.ButtonText.LoadMore')}</div>);
    };

    return (
        <GridContentExpander
            buttonText={translate('AuditHistory.ButtonText.History')}
            isExpanded={props.isExpanded}
            isLoading={props.isLoading}
            className="audit-history"
            onOpen={onOpen}
            onClose={onClose}
        >
            <>
                {(props.errorMessage) && (
                    <p className={'has-text-danger'}>{props.errorMessage}</p>
                )}

                {(!props.errorMessage && (!props.data || props.data.length === 0)) && (
                    <p>{translate('AuditHistory.Messages.Error1')}</p>
                )}

                {!props.errorMessage && (props.data && props.data.length > 0) && (
                    <div className="grid-data">
                        <div className="columns horizontal-table grid-data__headers">
                            <div className="column is-4">
                                {translate('AuditHistory.Messages.Updated')}
                            </div>
                            {props.fields.map((field) => (
                                <div className="column" key={field.label}>
                                    {field.label}
                                </div>
                            ))}
                        </div>
                        {props.data.map((item, i) => (
                            <div className="columns horizontal-table" key={i}>
                                <div className="column is-2">
                                    <h2 className="mobile-header">{translate('AuditHistory.Messages.Updated')}</h2>
                                    <div className="is-hidden-desktop is-hidden-tablet">
                                        <DateTime value={item.lastUpdatedDateTime} format={Format.DateAndTime} />
                                    </div>
                                    <div className="is-hidden-mobile">
                                        <DateTime value={item.lastUpdatedDateTime} format={Format.DateOnly} />
                                    </div>
                                </div>
                                <div className="column is-2 is-hidden-mobile">
                                    <div>
                                        <DateTime value={item.lastUpdatedDateTime} format={Format.TimeOnly} />
                                    </div>
                                </div>
                                {props.fields.map((field, index) => (
                                    <div className="column" key={index} >
                                        {formatValue(field, item)}
                                    </div>
                                ))}
                            </div>
                        ))}
                        {props.continuationToken && loadMoreButton()}
                    </div>
                )}
            </>
        </GridContentExpander>
    );
};
