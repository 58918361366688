import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { flatten } from 'flat';
import { languages } from '../../translations/languages';
import { history } from 'routes/App';

interface IProfileProviderProps {
    children: JSX.Element | JSX.Element[];
    language: string | null;
}

export const ProfileProvider = React.memo((props: IProfileProviderProps) => {
    let languageCode: string = props.language ? props.language : navigator.language; // language without region code
    if (history.location.pathname === '/Account/AccessDenied') {
        languageCode = navigator.language
    }
    const foundLanguage = languages.find((l) => l.key === languageCode);
    const defaultLanguage = languages.find((l) => l.key === 'en-GB');
    const msg = foundLanguage ? foundLanguage.data : (defaultLanguage as any).data;

    return (
        <IntlProvider locale={languageCode} messages={flatten(msg)}>
            {props.children}
        </IntlProvider>
    );
});
