import {
    loadIncident,
    createIncident,
    updateIncident,
    deleteIncident,
    cleanUpIncident,
    loadIncidentSettings,
} from 'actions/actions-v2/incident-action-v2';
import { loadPersonsLookup } from 'actions/actions-v2/person-action-v2';
import { onSendEmails } from 'actions/incident-actions';
import { loadPerson } from 'actions/actions-v2/user-profile-action-v2';

export default {
    loadIncident,
    loadIncidentSettings,
    createIncident,
    updateIncident,
    cleanUpIncident,
    onSendEmails,
    deleteIncident,
    loadPersonsLookup,
    loadPersonProfile: loadPerson,
};
