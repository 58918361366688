import { LOCATION_CHANGE, RouterState } from "redux-first-history";
import { combineReducers } from 'redux';
import { NavActions } from 'actions/nav-actions';
import { SiteFeatures } from 'actions/site-feature';
import { NavLocation } from 'models/nav-location';
import { INotification, INotificationCount } from 'models/notification';
import { AppActions, AppActionTypes } from '../actions/app-actions';
import { IKeyValue } from '../models/key-value';
import { NavActions as NavActionsV2 } from 'actions/actions-v2/nav-actions-v2';
import { IAppAction } from 'actions/app-action';
import { IPersonLookup } from 'models/person-lookup';
import { IEngineerTrainingType } from 'models/IEngineerTrainingType';
import { IEngineerTrainingCategory } from 'models/IEngineerTrainingCategory';
import { ICriticalSystemSpareSubCategory } from 'models/critical-system-spares/critical-system-spare';
import { IRiskProfileCategory } from 'models/risk-profile-questions/risk-profile-lookups';
import { logOut } from 'utils/auth-helper';
import { UserProfileActions } from 'actions/actions-v2/user-profile-action-v2';
import { NotificationActions } from 'actions/actions-v2/notification-action-v2';
import _ from 'lodash';
import { IBusinessUnitLookupModel } from 'models/businessUnit-lookup-model';
import { ICommonLookupModel } from 'models/common-lookup-model';
import { RiskCategory } from 'models/risk-category';

export interface IAppState {
    userId: string;
    avatarUrl: string;
    fullName: string;
    isClient: boolean;
    canCreateRisk: boolean;
    canEditRisk: boolean;
    canDeleteRisk: boolean;
    canCreateAction: boolean;
    canEditAction: boolean;
    canManageOperationalRisk: boolean;
    siteId: string;
    isSessionAlive: boolean;
    allowedDropDownRoles: string[];
    permissions: Array<IKeyValue<string>>;
    lookupSites: Array<IKeyValue<string>>;
    lookupSitesClient: Array<IKeyValue<string>>;
    navLocation: NavLocation;
    lookupPhases: Array<IKeyValue<string>>;
    lookupPriorities: Array<IKeyValue<string>>;
    lookupServiceFilters: Array<IKeyValue<string>>;
    lookupRiskCategories: RiskCategory[];
    lookupRiskStatus: Array<IKeyValue<string>>;
    lookupRiskLikelihoods: Array<IKeyValue<string>>;
    lookupRiskImpacts: Array<IKeyValue<string>>;
    lookupActionPriorities: Array<IKeyValue<string>>;
    lookupActionCategories: Array<IKeyValue<string>>;
    lookupPillars: ICommonLookupModel[];
    lookupRoles: Array<IKeyValue<string>>;
    lookupAssignableRoles: Array<IKeyValue<string>>;
    lookupQuestionCategories: Array<IKeyValue<string>>;
    lookupQuestionUrgencies: Array<IKeyValue<string>>;
    lookupQuestionStatuses: Array<IKeyValue<string>>;
    lookupQuestionCompletion: Array<IKeyValue<string>>;
    lookupIndustry: Array<IKeyValue<string>>;
    lookupSegment: Array<IKeyValue<string>>;
    lookupServiceLine: Array<IKeyValue<string>>;
    lookupCmmType: Array<IKeyValue<string>>;
    lookupFacilityType: Array<IKeyValue<string>>;
    lookupTierLevel: Array<IKeyValue<string>>;
    lookupRegion: Array<IKeyValue<string>>;
    lookupRiskCategoryLevels: Array<IKeyValue<string>>;
    lookupRiskLevel: Array<IKeyValue<string>>;
    lookupActionStatus: Array<IKeyValue<string>>;
    lookupQuestionSetPriority: Array<IKeyValue<string>>;
    lookupCermProcessStepService: Array<IKeyValue<string>>;
    lookupLineOfBusiness: ICommonLookupModel[];
    lookupCountry: Array<IKeyValue<string>>;
    lookupCountryRegion: Array<IKeyValue<string>>;
    lookupRiskSpecial: Array<IKeyValue<string>>;
    lookupClients: Array<IKeyValue<string>>;
    lookupRiskQuestionCategories: Array<IKeyValue<string>>;
    features: string[];
    lookupSitesFeatures: Array<IKeyValue<string[]>>;
    notifications: INotification[];
    lookupSitePeopleRoles: IPersonLookup[];
    lookupSiteAllPeopleRoles: IPersonLookup[];
    engineerTrainingTypes: IEngineerTrainingType[];
    engineerTrainingCategories: IEngineerTrainingCategory[];
    riskProfileCategories: IRiskProfileCategory[];
    lookupEnterpriseDivisions: ICommonLookupModel[];
    lookupLocalDivisions: ICommonLookupModel[];
    lookupDcsDivisions: Array<IKeyValue<string>>;
    lookupCriticalSystemSpareCategories: Array<IKeyValue<string>>;
    lookupCriticalSystemSpareSubCategories: ICriticalSystemSpareSubCategory[];
    showHelpModule: boolean;
    lookupActionSpecial: Array<IKeyValue<string>>;
    notificationCount: INotificationCount[];
    lookupOperatingPlatform: Array<IKeyValue<string>>;
    lookupRiskPlatform: Array<IKeyValue<string>>;
    lookupBusinessUnit: IBusinessUnitLookupModel[];
    appSettings: Array<IKeyValue<string>>;
}

const engineerTrainingTypes = (
    state: IAppState['engineerTrainingTypes'] = APP_USER_INITIAL_STATE.engineerTrainingTypes,
    action: any
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.engineerTrainingTypes;
        default:
            return state;
    }
};

const engineerTrainingCategories = (
    state: IAppState['engineerTrainingCategories'] = APP_USER_INITIAL_STATE.engineerTrainingCategories,
    action: any
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.engineerTrainingCategories;
        default:
            return state;
    }
};

const lookupSitePeopleRoles = (
    state: IAppState['lookupSitePeopleRoles'] = APP_USER_INITIAL_STATE.lookupSitePeopleRoles,
    action: any
) => {
    switch (action.type) {
        case AppActions.LOAD_PEOPLE_LINKED_TO_SITE_FULFILLED:
            return action.payload;
        default:
            return state;
    }
};

const lookupSiteAllPeopleRoles = (
    state: IAppState['lookupSiteAllPeopleRoles'] = APP_USER_INITIAL_STATE.lookupSiteAllPeopleRoles,
    action: any
) => {
    switch (action.type) {
        case AppActions.LOAD_ALL_PEOPLE_LINKED_TO_SITE_FULFILLED:
            return action.payload;
        default:
            return state;
    }
};

const siteId = (state: IAppState['siteId'] = APP_USER_INITIAL_STATE.siteId, action: any) => {
    switch (action.type) {
        case UserProfileActions.LOAD_PERSON_FULFILLED:
            return action.payload.selectedSite;
        case AppActions.CHANGE_SITE:
            return action.payload.siteId;
        default:
            return state;
    }
};

const fullName = (
    state: IAppState['fullName'] = APP_USER_INITIAL_STATE.siteId,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.fullName;
        default:
            return state;
    }
};

const location = (
    state: IAppState['navLocation'] = APP_USER_INITIAL_STATE.navLocation,
    action: IAppAction
) => {
    switch (action.type) {
        case LOCATION_CHANGE:
            return getLocation(action.payload);
        default:
            return state;
    }
};

const getLocation = (routeState: RouterState): NavLocation => {
    const path = routeState.location.pathname.match(/([^\/]*)\/*$/)[1];

    switch (path.toLowerCase()) {
        case 'risk':
            return NavLocation.Risk;
        case 'action':
            return NavLocation.Action;
        case 'cermassessment':
            return NavLocation.CermAssessment;
        case 'cermsiteoverview':
            return NavLocation.CermOverview;
        case 'cermprocesssteps':
            return NavLocation.CermProcess;
        case 'riskassessment':
            return NavLocation.RiskAssessment;
        case 'questionsets':
            return NavLocation.RiskQuestions;
        case 'calendar':
            return NavLocation.Calendar;
        case 'person':
            return NavLocation.Person;
        case 'client':
            return NavLocation.Client;
        case 'site':
            return NavLocation.Site;
        case 'managerelationships':
            return NavLocation.ManageRelationships;
        case 'managerole':
            return NavLocation.ManageRoles;
        default:
            return NavLocation.Home;
    }
};

const permissions = (
    state: IAppState['permissions'] = APP_USER_INITIAL_STATE.permissions,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.permissions;
        default:
            return state;
    }
};

export const lookupSites = (
    state: IAppState['lookupSites'] = APP_USER_INITIAL_STATE.lookupSites,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupSites;
        default:
            return state;
    }
};

export const lookupSitesClient = (
    state: IAppState['lookupSitesClient'] = APP_USER_INITIAL_STATE.lookupSitesClient,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupSites.map((x) => {
                return { key: x.key, value: x.clientName };
            });
        default:
            return state;
    }
};

const id = (state: IAppState['userId'] = APP_USER_INITIAL_STATE.userId, action: IAppAction) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.userId;
        default:
            return state;
    }
};

const avatarUrl = (
    state: IAppState['avatarUrl'] = APP_USER_INITIAL_STATE.avatarUrl,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.avatarUrl;
        default:
            return state;
    }
};

const canCreateRisk = (
    state: IAppState['canCreateRisk'] = APP_USER_INITIAL_STATE.canCreateRisk,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.canCreateRisk;
        default:
            return state;
    }
};

const canEditRisk = (
    state: IAppState['canEditRisk'] = APP_USER_INITIAL_STATE.canEditRisk,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.canEditRisk;
        default:
            return state;
    }
};

const canDeleteRisk = (
    state: IAppState['canDeleteRisk'] = APP_USER_INITIAL_STATE.canDeleteRisk,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.canDeleteRisk;
        default:
            return state;
    }
};

const canCreateAction = (
    state: IAppState['canCreateAction'] = APP_USER_INITIAL_STATE.canCreateAction,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.canCreateAction;
        default:
            return state;
    }
};

const lookupPhases = (
    state: IAppState['lookupPhases'] = APP_USER_INITIAL_STATE.lookupPhases,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupPhases;
        default:
            return state;
    }
};

const lookupPriorities = (
    state: IAppState['lookupPriorities'] = APP_USER_INITIAL_STATE.lookupPriorities,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupPriorities;
        default:
            return state;
    }
};

const lookupServiceFilters = (
    state: IAppState['lookupServiceFilters'] = APP_USER_INITIAL_STATE.lookupServiceFilters,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupServiceFilters;
        default:
            return state;
    }
};

const lookupRiskCategories = (
    state: IAppState['lookupRiskCategories'] = APP_USER_INITIAL_STATE.lookupRiskCategories,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupRiskCategories;
        default:
            return state;
    }
};

const lookupRiskStatus = (
    state: IAppState['lookupRiskStatus'] = APP_USER_INITIAL_STATE.lookupRiskStatus,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupRiskStatus;
        default:
            return state;
    }
};

const lookupRiskLikelihoods = (
    state: IAppState['lookupRiskLikelihoods'] = APP_USER_INITIAL_STATE.lookupRiskLikelihoods,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupRiskLikelihoods;
        default:
            return state;
    }
};

const lookupRiskImpacts = (
    state: IAppState['lookupRiskImpacts'] = APP_USER_INITIAL_STATE.lookupRiskImpacts,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupRiskImpacts;
        default:
            return state;
    }
};

const lookupActionPriorities = (
    state: IAppState['lookupActionPriorities'] = APP_USER_INITIAL_STATE.lookupActionPriorities,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupActionPriorities;
        default:
            return state;
    }
};
const lookupPillars = (
    state: IAppState['lookupActionPriorities'] = APP_USER_INITIAL_STATE.lookupPillars,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupPillars;
        default:
            return state;
    }
};

const lookupActionCategories = (
    state: IAppState['lookupActionCategories'] = APP_USER_INITIAL_STATE.lookupActionCategories,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupActionCategories;
        default:
            return state;
    }
};
const canEditAction = (
    state: IAppState['canEditAction'] = APP_USER_INITIAL_STATE.canEditAction,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.canEditAction;
        default:
            return state;
    }
};
const canManageOperationalRisk = (
    state: IAppState['canManageOperationalRisk'] = APP_USER_INITIAL_STATE.canManageOperationalRisk,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActions.INITIALISE_NAV_FULFILLED:
            return action.payload.canManageOperationalRisk !== undefined
                ? action.payload.canManageOperationalRisk
                : state;
        default:
            return state;
    }
};

const lookupRoles = (
    state: IAppState['lookupRoles'] = APP_USER_INITIAL_STATE.lookupRoles,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupRoles;
        default:
            return state;
    }
};

const lookupAssignableRoles = (
    state: IAppState['lookupAssignableRoles'] = APP_USER_INITIAL_STATE.lookupAssignableRoles,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupAssignableRoles;
        default:
            return state;
    }
};

const lookupQuestionCategories = (
    state: IAppState['lookupQuestionCategories'] = APP_USER_INITIAL_STATE.lookupQuestionCategories,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupQuestionCategories;
        default:
            return state;
    }
};

const lookupQuestionUrgencies = (
    state: IAppState['lookupQuestionUrgencies'] = APP_USER_INITIAL_STATE.lookupQuestionUrgencies,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupQuestionUrgencies;
        default:
            return state;
    }
};

const lookupQuestionStatuses = (
    state: IAppState['lookupQuestionStatuses'] = APP_USER_INITIAL_STATE.lookupQuestionStatuses,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupQuestionStatuses;
        default:
            return state;
    }
};

const lookupQuestionCompletion = (
    state: IAppState['lookupQuestionCompletion'] = APP_USER_INITIAL_STATE.lookupQuestionCompletion,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupQuestionCompletion;
        default:
            return state;
    }
};

const features = (
    state: IAppState['features'] = APP_USER_INITIAL_STATE.features,
    action: IAppAction
) => {
    switch (action.type) {
        default:
            return state;
    }
};
const notificationCount = (
    state: IAppState['notificationCount'] = APP_USER_INITIAL_STATE.notificationCount,
    action: IAppAction
) => {
    switch (action.type) {
        case NotificationActions.LOAD_NOTIFICATION_COUNT_FULFILLED:
            if (action.payload) {
                return action.payload;
            }
            return state;
        case NavActions.NOTIFICATION_RECEIVED:
            const notificationCount = _.cloneDeep(state);
            if (action.payload.notification?.messageType !== 'notification') {
                notificationCount.map((notification) => {
                    if (action.payload.notification?.messageType === notification.messageType) {
                        if (
                            notification.message.siteId ===
                            action.payload.notification.message.siteId
                        ) {
                            return (notification.message.count =
                                action.payload.notification.message.count);
                        }
                        return notification;
                    }
                });
            }

            return notificationCount;

        default:
            return state;
    }
};

const notifications = (
    state: IAppState['notifications'] = APP_USER_INITIAL_STATE.notifications,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActions.MARK_ALL_READ_FULFILLED:
            return state.map((m) => {
                return {
                    ...m,
                    read: true,
                };
            });
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.notifications;
        case NavActions.NOTIFICATION_RECEIVED:
            if (action.payload.notification?.messageType === 'notification') {
                if (
                    action.payload.notification.message &&
                    action.payload.notification.message.id !== undefined
                ) {
                    const receivedNotification = state.find(
                        (n) => n.id === action.payload.notification.message.id
                    );
                    if (receivedNotification !== undefined) {
                        return state.map((item: INotification) => {
                            if (item.id === action.payload.notification.message.id) {
                                return action.payload.notification.message;
                            }
                            return item;
                        });
                    } else {
                        return [
                            action.payload.notification.message,
                            ...state.slice(0, state.length),
                        ];
                    }
                }
            }

            return state;
        default:
            return state;
    }
};

const lookupSitesFeatures = (
    state: IAppState['lookupSitesFeatures'] = APP_USER_INITIAL_STATE.lookupSitesFeatures,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupSitesFeatures;
        default:
            return state;
    }
};

const lookupIndustry = (
    state: IAppState['lookupIndustry'] = APP_USER_INITIAL_STATE.lookupIndustry,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupIndustry;
        default:
            return state;
    }
};

const lookupSegment = (
    state: IAppState['lookupSegment'] = APP_USER_INITIAL_STATE.lookupSegment,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupSegment;
        default:
            return state;
    }
};
const lookupServiceLine = (
    state: IAppState['lookupServiceLine'] = APP_USER_INITIAL_STATE.lookupServiceLine,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupServiceLine;
        default:
            return state;
    }
};
const lookupCountry = (
    state: IAppState['lookupCountry'] = APP_USER_INITIAL_STATE.lookupCountry,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupCountry;
        default:
            return state;
    }
};
const lookupCountryRegion = (
    state: IAppState['lookupCountryRegion'] = APP_USER_INITIAL_STATE.lookupCountryRegion,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupCountryRegion;
        default:
            return state;
    }
};
const lookupLineOfBusiness = (
    state: IAppState['lookupLineOfBusiness'] = APP_USER_INITIAL_STATE.lookupLineOfBusiness,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupLineOfBusiness;
        default:
            return state;
    }
};
const lookupRiskLevel = (
    state: IAppState['lookupRiskLevel'] = APP_USER_INITIAL_STATE.lookupRiskLevel,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupRiskLevel;
        default:
            return state;
    }
};
const lookupQuestionSetPriority = (
    state: IAppState['lookupQuestionSetPriority'] = APP_USER_INITIAL_STATE.lookupQuestionSetPriority,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupQuestionSetPriority;
        default:
            return state;
    }
};
const lookupCermProcessStepService = (
    state: IAppState['lookupCermProcessStepService'] = APP_USER_INITIAL_STATE.lookupCermProcessStepService,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupCermProcessStepService;
        default:
            return state;
    }
};
const lookupRiskCategoryLevels = (
    state: IAppState['lookupRiskCategoryLevels'] = APP_USER_INITIAL_STATE.lookupRiskCategoryLevels,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupRiskCategoryLevels;
        default:
            return state;
    }
};
const lookupTierLevel = (
    state: IAppState['lookupTierLevel'] = APP_USER_INITIAL_STATE.lookupTierLevel,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupTierLevel;
        default:
            return state;
    }
};
const lookupFacilityType = (
    state: IAppState['lookupFacilityType'] = APP_USER_INITIAL_STATE.lookupFacilityType,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupFacilityType;
        default:
            return state;
    }
};
const lookupCmmType = (
    state: IAppState['lookupCmmType'] = APP_USER_INITIAL_STATE.lookupCmmType,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupCmmType;
        default:
            return state;
    }
};
const lookupActionStatus = (
    state: IAppState['lookupActionStatus'] = APP_USER_INITIAL_STATE.lookupActionStatus,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupActionStatus;
        default:
            return state;
    }
};
const lookupRiskSpecial = (
    state: IAppState['lookupRiskSpecial'] = APP_USER_INITIAL_STATE.lookupRiskSpecial,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupRiskSpecial;
        default:
            return state;
    }
};

const isSessionAlive = (
    state: IAppState['isSessionAlive'] = APP_USER_INITIAL_STATE.isSessionAlive,
    action: AppActionTypes
) => {
    switch (action.type) {
        case AppActions.IS_SESSION_ALIVE_FULFILLED:
            if (!action.payload) {
                logOut();
            } else {
                return action.payload;
            }
        default:
            return state;
    }
};

const lookupRiskQuestionCategories = (
    state: IAppState['lookupRiskQuestionCategories'] = APP_USER_INITIAL_STATE.lookupRiskQuestionCategories,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupRiskQuestionCategories;
        default:
            return state;
    }
};

const lookupRegion = (
    state: IAppState['lookupRegion'] = APP_USER_INITIAL_STATE.lookupRegion,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupRegion;
        default:
            return state;
    }
};

const riskProfileCategories = (
    state: IAppState['riskProfileCategories'] = APP_USER_INITIAL_STATE.riskProfileCategories,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.riskProfileCategories;
        default:
            return state;
    }
};

const lookupDcsDivisions = (
    state: IAppState['lookupDcsDivisions'] = APP_USER_INITIAL_STATE.lookupDcsDivisions,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupDcsDivisions;
        default:
            return state;
    }
};

const lookupEnterpriseDivisions = (
    state: IAppState['lookupEnterpriseDivisions'] = APP_USER_INITIAL_STATE.lookupEnterpriseDivisions,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupEnterpriseDivisions;
        default:
            return state;
    }
};

const lookupLocalDivisions = (
    state: IAppState['lookupLocalDivisions'] = APP_USER_INITIAL_STATE.lookupLocalDivisions,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupLocalDivisions;
        default:
            return state;
    }
};

const lookupClients = (
    state: IAppState['lookupClients'] = APP_USER_INITIAL_STATE.lookupClients,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupClients;
        default:
            return state;
    }
};

const lookupCriticalSystemSpareCategories = (
    state: IAppState['lookupCriticalSystemSpareCategories'] = APP_USER_INITIAL_STATE.lookupCriticalSystemSpareCategories,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupCriticalSystemSpareCategories;
        default:
            return state;
    }
};

const lookupCriticalSystemSpareSubCategories = (
    state: IAppState['lookupCriticalSystemSpareSubCategories'] = APP_USER_INITIAL_STATE.lookupCriticalSystemSpareSubCategories,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupCriticalSystemSpareSubCategories;
        default:
            return state;
    }
};

const showHelpModule = (
    state: IAppState['showHelpModule'] = APP_USER_INITIAL_STATE.showHelpModule,
    action: IAppAction
) => {
    switch (action.type) {
        default:
            return state;
    }
};

const isClient = (
    state: IAppState['isClient'] = APP_USER_INITIAL_STATE.isClient,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.isClient;
        default:
            return state;
    }
};

const allowedDropDownRoles = (
    state: IAppState['allowedDropDownRoles'] = APP_USER_INITIAL_STATE.allowedDropDownRoles,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.allowedDropDownRoles;
        default:
            return state;
    }
};

const lookupActionSpecial = (
    state: IAppState['lookupActionSpecial'] = APP_USER_INITIAL_STATE.lookupActionSpecial,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupActionSpecial;
        default:
            return state;
    }
};

const lookupOperatingPlatform = (
    state: IAppState['lookupOperatingPlatform'] = APP_USER_INITIAL_STATE.lookupOperatingPlatform,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupOperatingPlatform;
        default:
            return state;
    }
};

const lookupBusinessUnit = (
    state: IAppState['lookupBusinessUnit'] = APP_USER_INITIAL_STATE.lookupBusinessUnit,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupBusinessUnit;
        default:
            return state;
    }
};

const lookupRiskPlatform = (
    state: IAppState['lookupRiskPlatform'] = APP_USER_INITIAL_STATE.lookupRiskPlatform,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_DATA_FULFILLED:
            return action.payload.lookupRiskPlatform;
        default:
            return state;
    }
};

const appSettings = (
    state: IAppState['appSettings'] = APP_USER_INITIAL_STATE.appSettings,
    action: IAppAction
) => {
    switch (action.type) {
        case NavActionsV2.LOAD_APP_SETTINGS_FULFILLED:
            return action.payload;
        default:
            return state;
    }
};

export const APP_USER_INITIAL_STATE: IAppState = {
    userId: null,
    avatarUrl: null,
    fullName: null,
    canCreateRisk: false,
    canEditRisk: false,
    canDeleteRisk: false,
    canCreateAction: false,
    canEditAction: false,
    siteId: null,
    isClient: false,
    permissions: [],
    allowedDropDownRoles: [],
    isSessionAlive: true,
    canManageOperationalRisk: false,
    lookupSites: [],
    lookupSitesClient: [],
    navLocation: NavLocation.Home,
    lookupPhases: [],
    lookupPriorities: [],
    lookupServiceFilters: [],
    lookupRiskCategories: [],
    lookupRiskStatus: [],
    lookupRiskLikelihoods: [],
    lookupRiskImpacts: [],
    lookupActionPriorities: [],
    lookupActionCategories: [],
    lookupPillars: [],
    lookupRoles: [],
    lookupAssignableRoles: [],
    lookupQuestionCategories: [],
    lookupQuestionUrgencies: [],
    lookupQuestionCompletion: [],
    lookupQuestionStatuses: [],
    features: [SiteFeatures.PUE, SiteFeatures.Incidents],
    lookupSitesFeatures: [],
    notifications: [],
    lookupActionStatus: [],
    lookupIndustry: [],
    lookupSegment: [],
    lookupServiceLine: [],
    lookupCmmType: [],
    lookupFacilityType: [],
    lookupTierLevel: [],
    lookupRiskCategoryLevels: [],
    lookupRiskLevel: [],
    lookupQuestionSetPriority: [],
    lookupCermProcessStepService: [],
    lookupLineOfBusiness: [],
    lookupCountry: [],
    lookupCountryRegion: [],
    lookupRiskSpecial: [],
    lookupSitePeopleRoles: [],
    lookupSiteAllPeopleRoles: [],
    lookupRiskQuestionCategories: [],
    lookupCriticalSystemSpareCategories: [],
    lookupCriticalSystemSpareSubCategories: [],
    lookupClients: [],
    lookupRegion: [],
    engineerTrainingTypes: [],
    engineerTrainingCategories: [],
    riskProfileCategories: [],
    lookupDcsDivisions: [],
    lookupEnterpriseDivisions: [],
    lookupLocalDivisions: [],
    showHelpModule: false,
    lookupActionSpecial: [],
    notificationCount: [],
    lookupOperatingPlatform: [],
    lookupBusinessUnit: [],
    lookupRiskPlatform: [],
    appSettings: [],
};

export const appReducer = combineReducers<IAppState>({
    userId: id,
    allowedDropDownRoles,
    avatarUrl,
    fullName,
    isClient,
    canCreateRisk,
    canEditRisk,
    canDeleteRisk,
    canCreateAction,
    canEditAction,
    siteId,
    permissions,
    lookupSites,
    lookupSitesClient,
    canManageOperationalRisk,
    navLocation: location,
    lookupPhases,
    lookupPriorities,
    lookupServiceFilters,
    lookupRiskCategories,
    lookupRiskStatus,
    lookupRiskLikelihoods,
    lookupRiskImpacts,
    lookupActionPriorities,
    lookupActionCategories,
    lookupPillars,
    lookupRoles,
    lookupAssignableRoles,
    lookupQuestionCategories,
    lookupQuestionUrgencies,
    lookupQuestionStatuses,
    lookupQuestionCompletion,
    features,
    lookupSitesFeatures,
    notifications,
    lookupIndustry,
    lookupSegment,
    lookupServiceLine,
    lookupCmmType,
    lookupFacilityType,
    lookupTierLevel,
    lookupRiskCategoryLevels,
    lookupRiskLevel,
    lookupQuestionSetPriority,
    lookupCermProcessStepService,
    lookupLineOfBusiness,
    lookupCountry,
    lookupCountryRegion,
    lookupActionStatus,
    lookupRiskSpecial,
    lookupSitePeopleRoles,
    lookupSiteAllPeopleRoles,
    isSessionAlive,
    lookupRiskQuestionCategories,
    lookupRegion,
    engineerTrainingTypes,
    engineerTrainingCategories,
    lookupCriticalSystemSpareCategories,
    lookupCriticalSystemSpareSubCategories,
    riskProfileCategories,
    lookupDcsDivisions,
    lookupLocalDivisions,
    lookupEnterpriseDivisions,
    lookupClients,
    showHelpModule,
    lookupActionSpecial,
    notificationCount,
    lookupOperatingPlatform,
    lookupBusinessUnit,
    lookupRiskPlatform,
    appSettings,
});
