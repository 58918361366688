import * as React from 'react';
import { ConfirmDialog } from '../dialog/ConfirmDialog';
import { IconToolbarItem, IToolbarIconItem } from '../components';

interface IProps {
    item: IToolbarIconItem;
}

export const IconToolbarItemWithConfirmDialog: React.FC<IProps> = ({ item }) => {
    const [isDialogVisible, setIsDialogVisible] = React.useState(false);

    const onConfirmButtonClick = () => {
        item.onClick();
        setIsDialogVisible(false);
    };

    const onClose = () => {
        setIsDialogVisible(false);
    };

    return (
        <IconToolbarItem item={{ ...item, onClick: () => setIsDialogVisible(true) }}>
            <ConfirmDialog
                title={item.title}
                message={item.confirmationMessage}
                isVisible={isDialogVisible}
                onConfirm={onConfirmButtonClick}
                onClose={onClose}
                onOutsideDialogClick={onClose}
            />
        </IconToolbarItem>
    );
};
