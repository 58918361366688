import { createSelector } from 'reselect';
import { IRootCermAssessmentState } from 'reducers/cermAssessments';

const getAttachments = (state: IRootCermAssessmentState, props: { childId: string }) => {
    return state.cermAssessmentState.attachments.filter((x) => x.childId === props.childId);
};

const getIsLoading = (state: IRootCermAssessmentState) => state.cermAssessmentState.isLoading;
const getPermissions = createSelector(
    (state: IRootCermAssessmentState) => state.app,
    (state) => state.permissions
);

export default createSelector(
    [getAttachments, getPermissions, getIsLoading],
    (data, permissions, isLoading) => ({
        data,
        permissions,
        isLoading
    })
);
