import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { getCurrentSelectedSiteId } from 'sharedSelectors';

const getPermissions = (state: IRootState) => state.app.permissions;
const getAttachmentState = (state: IRootState) => state.attachmentState;

export default createSelector(
    getCurrentSelectedSiteId,
    getPermissions,
    getAttachmentState,
    (siteId, permissions, attachmentState) => ({
        siteId,
        permissions,
        isModalVisible: attachmentState.showModal,
        isLoading: attachmentState.isLoading,
        isSaving: attachmentState.isSaving,
    })
);
