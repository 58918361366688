import { combineReducers } from 'redux';
import { SiteProfileActions, SiteProfileActionTypes } from '../../actions/site-profile-actions';
import { ISiteProfile } from '../../models/site-profile';
import { s2ab } from 'utils/export-utils';
import { IAppAction } from 'actions/app-action';
import { SiteActions } from 'actions/actions-v2/site-actions-v2';
import saveAs from 'file-saver';

export interface ISiteProfilesGridState {
    isExportingSites: boolean;
    items: ISiteProfile[];
    isLoading: boolean;
    expandedItems: string[];
    expandedFilters: string[];
}

export const INITIAL_STATE: ISiteProfilesGridState = {
    items: [],
    isLoading: false,
    isExportingSites: false,
    expandedItems: [],
    expandedFilters: ['operatingPlatform'],
};

const items = (state: ISiteProfilesGridState['items'] = INITIAL_STATE.items, action: IAppAction) => {
    switch (action.type) {
        case SiteProfileActions.LOAD_SITE_PROFILES_CANCELLED:
        case SiteProfileActions.LOAD_SITE_PROFILES_REJECTED:
            return INITIAL_STATE.items;

        case SiteProfileActions.LOAD_SITE_PROFILES_FULFILLED:
            return action.payload.siteProfiles;
        default:
            return state;
    }
};

const isExportingSites = (state: boolean = INITIAL_STATE.isExportingSites, action: IAppAction) => {
    switch (action.type) {
        case SiteActions.EXPORT_SITES:
            return true;

        case SiteActions.EXPORT_SITES_FULFILLED:
            const blob = new Blob([s2ab(atob(action.payload.fileContents))], {
                type: 'application/excel'
            });
            saveAs(blob, action.payload.fileDownloadName);
            return false;

        case SiteActions.EXPORT_SITES_REJECTED:
            return false;

        default:
            return state;
    }
};

const isLoading = (state: boolean = INITIAL_STATE.isLoading, action: SiteProfileActionTypes) => {
    switch (action.type) {
        case SiteProfileActions.LOAD_SITE_PROFILES:
        case SiteProfileActions.LOAD_PAGE:
            return true;
        case SiteProfileActions.LOAD_SITE_PROFILES_CANCELLED:
        case SiteProfileActions.LOAD_SITE_PROFILES_FULFILLED:
        case SiteProfileActions.LOAD_SITE_PROFILES_REJECTED:
            return false;

        default:
            return state;
    }
};
const expandedItems = (
    state: ISiteProfilesGridState['expandedItems'] = INITIAL_STATE.expandedItems,
    action: SiteProfileActionTypes
) => {
    switch (action.type) {
        case SiteProfileActions.TOGGLE_ITEM_EXPANDED:
            const id = action.payload.id;
            if (state.includes(id)) {
                return state.filter((c) => c !== id);
            }
            return [...state, id];

        default:
            return state;
    }
};

const expandedFilters = (
    state: ISiteProfilesGridState['expandedFilters'] = INITIAL_STATE.expandedFilters,
    action: SiteProfileActionTypes
) => {
    switch (action.type) {
        case SiteProfileActions.TOGGLE_FILTER_EXPANDED:
            const id = action.payload.id;

            if (state.includes(id)) {
                const newState = state.filter((item) => item !== id);
                return newState;
            } else {
                if (id) {
                    const newState = state.concat([id]);
                    return newState;
                }
            }
            return state;

        default:
            return state;
    }
};

export const reducer = combineReducers<ISiteProfilesGridState>({
    items,
    isLoading,
    isExportingSites,
    expandedItems,
    expandedFilters
});
