import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { showFilterModal, downloadEngineers, exportAllEngineers } from 'actions/actions-v2/engineer-action-v2';
import { EngineersGridToolbar } from './engineers-grid-toolbar';
import { IRootState } from 'routes/store';

const mapStateToProps = (state: IRootState) => {
    return {
        isExportingEngineers: state.engineersState.isExportingEngineers,
        isExportingAllEngineers: state.engineersState.isExportingAllEngineers,
        permissions: state.app.permissions
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
    onShowFilterModal() {
        dispatch(showFilterModal());
    },
    onDownloadEngineers() {
        dispatch(downloadEngineers());
    },
    onExport() {
        dispatch(exportAllEngineers());
    }
    };
};

export const EngineersGridToolbarContainer = connect(mapStateToProps, mapDispatchToProps)(EngineersGridToolbar);
