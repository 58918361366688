export const siteFacilitiesEs = `
<h1>¿Qué es Lorem Ipsum Site Facilities?</h1>
<div>
Lorem Ipsum es simplemente texto ficticio de la industria de la impresión y la composición tipográfica.
Lorem Ipsum ha sido el texto de relleno estándar de la industria desde la
1500, cuando un impresor desconocido tomó una galera de tipos y la codificó para
hacer un libro de muestras tipo. con software de autoedición como Aldus
PageMaker que incluye versiones de Lorem Ipsum.
<p> Ha sobrevivido no solo cinco siglos,</p>
<p>
sino también el salto a la tipografía electrónica, permaneciendo esencialmente
sin alterar.
</p>
<p>
Se popularizó en la década de 1960 con el lanzamiento de hojas Letraset.
que contiene pasajes de Lorem Ipsum, y más recientemente
</p>
<a>Contraria a la creencia popular</a>
</div> `;
