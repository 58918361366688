import { FormikProps } from 'formik';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { IKeyedItemExtended } from 'models/keyed-item';
import { Control, Field, Help, Input } from '../../../../components/form';
import { Select } from '../../../../components/select';
import { ValueSlider } from '../../../../components/value-slider';
import { BulmaColor } from '../../../../enums/BulmaColor';
import { BulmaSize } from '../../../../enums/BulmaSize';
import { ICermProcessStep, cermProcessStepFieldTitle } from '../../../../models/cerm-process-step';
import { IKeyValue } from '../../../../models/key-value';
import {
    canSubmit,
    formikFieldCss,
    scrollToFirstValidationError,
} from '../../../../utils/form-utils';
import { ProcessStepDeleteConfirmation } from '../../StepArchiveConfirmation';
import _ from 'lodash';
import { onRouteChange } from 'actions/app-actions';
import { RouteUrlRiskTemplate } from '../../routes';
import { Button } from 'components/v2/components';
import { ICommonLookupModel } from 'models/common-lookup-model';
import { CustomSelect } from 'components/CustomSelect';
import { Privilege } from 'enums/Privilege';
import { checkPermission } from 'utils/permission-utils';
import useTranslate from 'translations/translation-utils';

interface IRouteParams {
    operatingPlatform?: string;
}

export interface IProps extends RouteComponentProps<IRouteParams> {
    formikBag: FormikProps<Partial<ICermProcessStep>>;
    lookupServices: Array<IKeyValue<string>>;
    lookupPhases: Array<IKeyValue<string>>;
    lookupPillars: ICommonLookupModel[];
    lookupPriorities: Array<IKeyValue<string>>;
    isSubmitting: boolean;
}

export interface IDispatchProps {
    onDeleteProcessStep: (item: IKeyedItemExtended) => void;
    isReviewing: boolean;
    setErrorDialogVisible: (isDialogVisible: boolean) => void;
    permissions: Array<IKeyValue<string>>;
}

export const CermProcessStepForm: React.FC<IProps & IDispatchProps> = ({
    formikBag,
    lookupServices,
    lookupPhases,
    lookupPillars,
    lookupPriorities,
    setErrorDialogVisible,
    onDeleteProcessStep,
    isReviewing,
    match,
    isSubmitting,
    permissions,
}) => {
    const translate = useTranslate();

    React.useEffect(() => {
        formikBag.setFieldTouched('title', true);
    }, []);

    const onCancel: React.MouseEventHandler<HTMLButtonElement> = () => {
        onRouteChange(`/CermProcessSteps/${match.params.operatingPlatform}`);
    };

    const validateForm = (callback) => {
        formikBag.validateForm().then((errors) => {
            if (!Object.keys(errors).some((field) => field.length > 0)) {
                callback();
            } else {
                formikBag.submitForm();
                scrollToFirstValidationError();
                setErrorDialogVisible(true);
            }
        });
    }

    const onSaveAsDraft: React.MouseEventHandler<HTMLButtonElement> = () => {
        validateForm(async () => {
            await formikBag.setFieldValue('status', 'Draft');
            formikBag.submitForm();
        });
    };

    const onPublish: React.MouseEventHandler<HTMLButtonElement> = () => {
        validateForm(async () => {
            await formikBag.setFieldValue('status', 'Published');
            formikBag.submitForm();
        })
    };

    const onNavigateToRisk: React.MouseEventHandler<HTMLButtonElement> = () => {
        formikBag.validateForm().then((errors) => {
            if (
                !Object.keys(errors).some(
                    (field) => field.length > 0 && !cermProcessStepFieldTitle.hasOwnProperty(field)
                )
            ) {
                onRouteChange(RouteUrlRiskTemplate(match.params.operatingPlatform, formikBag.values.id));
            } else {
                formikBag.submitForm();
                scrollToFirstValidationError();
            }
        })
    };

    const onDeleteProcessStepHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
        onDeleteProcessStep({
            id: formikBag.values.id,
            siteId: formikBag.values.siteId,
            bucket: formikBag.values.bucket,
            shard: formikBag.values.shard,
            eTag: formikBag.values.eTag,
            reloadUrl: `/CermProcessSteps/${match.params.operatingPlatform}`
        });
    };

    const onPriorityChanged = (newValue: IKeyValue<string>) => {
        if (newValue !== undefined) {
            formikBag.setFieldValue('priority', newValue.value);
        }
    };

    const getPriorityValue = () => {
        const priorityValue = formikBag.values.priority;
        return priorityValue !== undefined || priorityValue !== '' ? priorityValue : '1';
    };

    const getLookupPillars = () => {
        return lookupPillars.filter(x => x.operatingPlatform === match.params.operatingPlatform);
    }

    const checkPermissionsWithOperatingPlatform = () => {
        return checkPermission(Privilege.ProcessArchive, permissions);
    };

    return (
        <>
            <header className={!isReviewing ? 'is-hidden' : null}>
                <h2 className="title is-5">Process Step</h2>
            </header>
            <Field isHorizontal={true} htmlFor="title" label={translate('UpsertCermProcessStep.Views.ProcessStepForm.Title')} labelSize={BulmaSize.Medium}>
                <Field>
                    <Control>
                        <Input
                            id="title"
                            name="title"
                            aria-required="true"
                            placeholder={translate('UpsertCermProcessStep.Views.ProcessStepForm.TitlePlaceholder')}
                            type="text"
                            className={formikFieldCss(formikBag, 'title')}
                            value={formikBag.values.title}
                            onChange={formikBag.handleChange}
                            onBlur={(event) =>
                                formikBag.setFieldValue(
                                    event.target.name,
                                    event.target.value.trim(),
                                )
                            }
                        />
                    </Control>
                    <Help
                        isShown={formikBag.touched.title}
                        bulmaColor={BulmaColor.Danger}
                    >
                        {formikBag.errors.title}
                    </Help>
                </Field>
            </Field>
            <Field isHorizontal={true} htmlFor="service" label={translate('UpsertCermProcessStep.Views.ProcessStepForm.Service')} labelSize={BulmaSize.Medium}>
                <Field>
                    <Control>
                        <Select
                            id="service"
                            name="service"
                            aria-required="true"
                            options={lookupServices}
                            className={formikFieldCss(formikBag, 'service')}
                            value={formikBag.values.service}
                            onChange={formikBag.handleChange}
                            onBlur={formikBag.handleBlur}
                            emptyOptionsValue={translate('UpsertCermProcessStep.Views.ProcessStepForm.SelectPlaceholder')}
                        />
                    </Control>
                    <Help
                        isShown={formikBag.touched.service}
                        bulmaColor={BulmaColor.Danger}
                    >
                        {formikBag.errors.service}
                    </Help>
                </Field>
            </Field>
            <Field isHorizontal={true} htmlFor="phase" label={translate('UpsertCermProcessStep.Views.ProcessStepForm.Phase')} labelSize={BulmaSize.Medium}>
                <Field>
                    <Control>
                        <Select
                            id="phase"
                            name="phase"
                            aria-required="true"
                            options={lookupPhases}
                            className={formikFieldCss(formikBag, 'phase')}
                            value={formikBag.values.phase}
                            onChange={formikBag.handleChange}
                            onBlur={formikBag.handleBlur}
                            emptyOptionsValue={translate('UpsertCermProcessStep.Views.ProcessStepForm.SelectPlaceholder')}
                        />
                    </Control>
                    <Help
                        isShown={formikBag.touched.phase}
                        bulmaColor={BulmaColor.Danger}
                    >
                        {formikBag.errors.phase}
                    </Help>
                </Field>
            </Field>
            <Field isHorizontal={true} htmlFor="pillar" label={translate('UpsertCermProcessStep.Views.ProcessStepForm.Pillar')} labelSize={BulmaSize.Medium}>
                <Field>
                    <Control>
                        <CustomSelect
                            id="pillar"
                            name="pillar"
                            aria-required="true"
                            options={getLookupPillars()}
                            className={formikFieldCss(formikBag, 'pillar')}
                            value={formikBag.values.pillar}
                            onChange={formikBag.handleChange}
                            onBlur={formikBag.handleBlur}
                            emptyOptionsValue={translate('UpsertCermProcessStep.Views.ProcessStepForm.SelectPlaceholder')}
                        />
                    </Control>
                    <Help
                        isShown={formikBag.touched.pillar}
                        bulmaColor={BulmaColor.Danger}
                    >
                        {formikBag.errors.pillar}
                    </Help>
                </Field>
            </Field>
            <Field isHorizontal={true} htmlFor="processNumber" label={translate('UpsertCermProcessStep.Views.ProcessStepForm.ProcessNumber')} labelSize={BulmaSize.Medium}>
                <Field className="is-narrow">
                    <Control>
                        <Input
                            id="processNumber"
                            name="processNumber"
                            aria-required="true"
                            placeholder="000"
                            type="number"
                            max={999}
                            min={0}
                            step={1}
                            className={formikFieldCss(formikBag, 'processNumber')}
                            value={formikBag.values.processNumber}
                            onChange={formikBag.handleChange}
                            onBlur={formikBag.handleBlur}
                        />
                    </Control>
                    <Help
                        isShown={formikBag.touched.processNumber}
                        bulmaColor={BulmaColor.Danger}
                    >
                        {formikBag.errors.processNumber}
                    </Help>
                </Field>
            </Field>

            <Field
                isHorizontal={true}
                htmlFor="priority"
                label={translate('UpsertCermProcessStep.Views.ProcessStepForm.Priority')}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        {
                            lookupPriorities.length > 0 && (
                                <ValueSlider
                                    name="priority"
                                    values={lookupPriorities}
                                    selectedValue={getPriorityValue()}
                                    onChange={onPriorityChanged}
                                />)
                        }
                    </Control>
                    <Help
                        isShown={formikBag.touched.priority}
                        bulmaColor={BulmaColor.Danger}
                    >
                        {formikBag.errors.priority}
                    </Help>
                </Field>
            </Field>
            <Field
                isHorizontal={true}
                htmlFor="reviewPeriodMonths"
                label={translate('UpsertCermProcessStep.Views.ProcessStepForm.ReviewPeriod')}
                labelSize={BulmaSize.Medium}
                className="is-vcentered reviewPeriodMonths"
            >
                <p className="is-italic">Every</p>
                <Field>
                    <Control>
                        <Input
                            id="reviewPeriodMonths"
                            name="reviewPeriodMonths"
                            aria-required="true"
                            placeholder="000"
                            type="number"
                            max={24}
                            min={0}
                            step={1}
                            className={formikFieldCss(formikBag, 'reviewPeriodMonths')}
                            value={formikBag.values.reviewPeriodMonths}
                            onChange={formikBag.handleChange}
                            onBlur={formikBag.handleBlur}
                        />
                    </Control>
                    <Help
                        isShown={formikBag.touched.reviewPeriodMonths}
                        bulmaColor={BulmaColor.Danger}
                    >
                        {formikBag.errors.reviewPeriodMonths}
                    </Help>
                </Field>
                <p className="is-italic">
                    {translate('UpsertCermProcessStep.Views.ProcessStepForm.MonthsLabel')}
                </p>
            </Field>

            {!isReviewing && <div className="action-groups">
                <div className="action-group">
                    <Button buttonType="cancel" onClick={onCancel}>
                        {translate('Globals.Label.Cancel')}
                    </Button>
                    {formikBag.values.id && checkPermissionsWithOperatingPlatform() &&
                        <Button onClick={onDeleteProcessStepHandler}>
                            {formikBag.values.status && formikBag.values.status.toLowerCase() === 'draft'
                                ? translate('UpsertCermProcessStep.Views.ProcessStepForm.DeleteStepButton')
                                : translate('UpsertCermProcessStep.Views.ProcessStepForm.ArchiveProcessButton')}
                        </Button>
                    }
                    <ProcessStepDeleteConfirmation
                        confirmMessage={
                            formikBag.values.status && formikBag.values.status.toLocaleLowerCase() === 'draft'
                                ? translate('UpsertCermProcessStep.Views.ProcessStepForm.DraftDeleteConfirmation')
                                : translate('UpsertCermProcessStep.Views.ProcessStepForm.ArchiveDeleteConfirmation')
                        }
                    />
                </div>
                <div className="action-group">
                    <Button
                        disabled={!canSubmit}
                        onClick={onSaveAsDraft}
                        isLoading={isSubmitting}>
                        {translate('Globals.Label.SaveAsDraft')}
                    </Button>

                    {formikBag.values.expectedOutcomes.length > 0 && <Button
                        onClick={onPublish}
                        isLoading={isSubmitting}
                    >
                        {translate('Globals.Label.Publish')}
                    </Button>}

                    <Button
                        onClick={onNavigateToRisk}
                    >
                        {translate('Globals.Label.Next')}
                    </Button>
                </div>
            </div>}
        </>
    );
};
