import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from './../base-epic';

import { Epic, combineEpics } from 'redux-observable';
import { ClientActions } from 'actions/actions-v2/client-action-v2';
import { IClient, IMyDataClient } from 'models/client';

const controllerName = 'client';
const iAuditorControllerName = 'iauditor';
const myDataControllerName = 'MyData';

const createActions = {
    action: ClientActions.CREATE_CLIENT,
    actionFulfilled: ClientActions.CREATE_CLIENT_FULFILLED,
    actionRejected: ClientActions.CREATE_CLIENT_REJECTED,
} as IActionType;

const updateActions = {
    action: ClientActions.UPDATE_CLIENT,
    actionFulfilled: ClientActions.UPDATE_CLIENT_FULFILLED,
    actionRejected: ClientActions.UPDATE_CLIENT_REJECTED,
} as IActionType;

const getAllActions = {
    action: ClientActions.LOAD_CLIENTS,
    actionFulfilled: ClientActions.LOAD_CLIENTS_FULFILLED,
    actionRejected: ClientActions.LOAD_CLIENTS_REJECTED,
} as IActionType;

const getActions = {
    action: ClientActions.LOAD_CLIENT,
    actionFulfilled: ClientActions.LOAD_CLIENT_FULFILLED,
    actionRejected: ClientActions.LOAD_CLIENT_REJECTED,
} as IActionType;

const getIauditorActions = {
    action: ClientActions.LOAD_IAUDITOR_ORGANIZATION_DETAILS,
    actionFulfilled: ClientActions.LOAD_IAUDITOR_ORGANIZATION_DETAILS_FULFILLED,
    actionRejected: ClientActions.LOAD_IAUDITOR_ORGANIZATION_DETAILS_REJECTED,
} as IActionType;

const saveActions = {
    action: ClientActions.SAVE_IAUDITOR_ACCESS_TOKEN,
    actionFulfilled: ClientActions.SAVE_IAUDITOR_ACCESS_TOKEN_FULFILLED,
    actionRejected: ClientActions.SAVE_IAUDITOR_ACCESS_TOKEN_REJECTED,
} as IActionType;

const revokeActions = {
    action: ClientActions.REVOKE_IAUDITOR_ACCESS_TOKEN,
    actionFulfilled: ClientActions.REVOKE_IAUDITOR_ACCESS_TOKEN_FULFILLED,
    actionRejected: ClientActions.REVOKE_IAUDITOR_ACCESS_TOKEN_REJECTED,
} as IActionType;

const deleteActions = {
    action: ClientActions.DELETE_CLIENT,
    actionFulfilled: ClientActions.DELETE_CLIENT_FULFILLED,
    actionRejected: ClientActions.DELETE_CLIENT_REJECTED,
} as IActionType;

const getAllMyDataActions = {
    action: ClientActions.LOAD_MYDATA_CLIENTS,
    actionFulfilled: ClientActions.LOAD_MYDATA_CLIENTS_FULFILLED,
    actionRejected: ClientActions.LOAD_MYDATA_CLIENTS_REJECTED,
} as IActionType;

const createClientEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createActions, '/Client').post<IClient>(`/api/${controllerName}`);
const updateClientEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateActions, '/Client').put<IClient>(`/api/${controllerName}`);
const loadClientsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getAllActions).get<IClient[]>(`/api/${controllerName}`);
const loadClientEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getActions).getById<IClient>(`/api/${controllerName}`);
const deleteClientEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, deleteActions, '/Client').deleteById<string>(`/api/${controllerName}`);
const saveAccessTokenEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, saveActions).get<IClient>(`/api/${iAuditorControllerName}`);
const revokeAccessTokenEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, revokeActions).deleteById<string>(`/api/${iAuditorControllerName}`);
const getIauditorAccessTokenEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getIauditorActions).getById<IClient>(`/api/${iAuditorControllerName}`);
const loadMyDataClientsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getAllMyDataActions).get<IMyDataClient[]>(`/api/${myDataControllerName}/client`);

export const clientEpicsV2 = combineEpics(
    createClientEpic,
    updateClientEpic,
    loadClientsEpic,
    loadClientEpic,
    deleteClientEpic,
    saveAccessTokenEpic,
    revokeAccessTokenEpic,
    getIauditorAccessTokenEpic,
    loadMyDataClientsEpic
);
