import React from 'react';
import { Panel, ThumbButton, Table } from '../v2/components';
import { IShiftChecklist, ICheckListItem } from 'models/shifts/master-shift-checklist';
import { isEmpty } from 'lodash';
import { FormikProps } from 'formik';
import { IShiftHandover, IShiftChecklistItem } from 'models/shifts/shift-handover';
import { TextField } from '../form/fields/text-field';
import { getKeyed, getField } from 'utils/form-utils';
import useTranslate from 'translations/translation-utils';
import { SelectField } from 'components/form/fields/select-field';

interface IProps {
    shiftChecklist: IShiftChecklist[];
    formikBag: FormikProps<IShiftHandover>;
    isReadOnly?: boolean;
}

export const ShiftHandoverChecklistTable: React.FC<IProps> = ({
    shiftChecklist,
    formikBag,
    isReadOnly,
}) => {
    function val<T extends Extract<keyof IShiftChecklistItem, string>>(name: T, prefix: string) {
        return getKeyed(formikBag, 'values', `${prefix}.${name}`);
    }
    const setThumbValue = (value: boolean, index: number) => {
        formikBag.setFieldValue(`checkListItems[${index}].status`, value);
        formikBag.setFieldValue(`checkListItems[${index}].checkListItemsVersion`, formikBag.values.checkListItemsVersion);
    };
    const setCommentsValue = (event, index: number) => {
        formikBag.setFieldValue(`checkListItems[${index}].comments`, event.target.value);
        formikBag.setFieldValue(`checkListItems[${index}].checkListItemsVersion`, formikBag.values.checkListItemsVersion);
    };

    const setShiftChecklist = (item: ICheckListItem) => {
        const checklistItem = { id: item.id, comments: '', status: null } as IShiftChecklistItem;
        const checklists = [...formikBag.values.checkListItems, checklistItem];
        formikBag.setFieldValue('checkListItems', checklists);
        return checklistItem;
    };

    React.useEffect(() => {
        const checkLists: IShiftChecklistItem[] = [...formikBag.values.checkListItems];
        if (isEmpty(shiftChecklist)) {
            return;
        }

        const newCheckList = shiftChecklist.find(item => item.id === formikBag.values.checkListItemsVersion);
        const allCheckListItemsInSite = newCheckList?.siteAreas
            .filter(x => x.isEnabled)
            .flatMap(x => x.checkListItems.filter(c => c.isEnabled)) || [];

        let updated = false;
        allCheckListItemsInSite.forEach(checklistItem => {
            if (!formikBag.values.checkListItems.some(x => x.id === checklistItem.id)) {
                checkLists.push({
                    id: checklistItem.id,
                    comments: '',
                    status: null,
                } as IShiftChecklistItem);
                updated = true;
            }
        });

        if (updated) {
            formikBag.setFieldValue('checkListItems', checkLists);
        }
    }, [shiftChecklist, formikBag.values.checkListItemsVersion, formikBag]);

    const translate = useTranslate();
    const handleTemplateChange = (field: { value: string; label: string }) => {
        formikBag.setFieldValue('checkListItemsVersion', field.value);
    };

    const selectTemplateValue = () => {
        const shiftCheckListItem = shiftChecklist.find(checkListItem => checkListItem.id === formikBag.values.checkListItemsVersion);
        return shiftCheckListItem ? { value: shiftCheckListItem.id, label: shiftCheckListItem.templateName } : { value: '', label: '' };
    };

    const shiftCheckListItem = shiftChecklist.find(checkListItem => checkListItem.id === formikBag.values.checkListItemsVersion);

    return (
        <>
            <SelectField
                id="checkListItemsVersion"
                label={translate("ShiftHandoverChecklistTable.Template.Title")}
                value={selectTemplateValue()}
                options={shiftChecklist.map(c => ({
                    value: c.id,
                    label: c.templateName,
                }))}
                handleChange={handleTemplateChange}
                handleBlur={formikBag.handleBlur}
                isDisabled={isReadOnly}
            />
            <Panel title={translate('ShiftHandoverChecklistTable.Panel.Title')} collapsed={null}>
                <Table className="checklist">
                    <thead>
                        <tr>
                            <th>{translate('ShiftHandoverChecklistTable.TableHeaders.SiteArea')}</th>
                            <th>{translate('ShiftHandoverChecklistTable.TableHeaders.Status')}</th>
                            <th>{translate('ShiftHandoverChecklistTable.TableHeaders.Notes')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isEmpty(shiftChecklist) &&
                            shiftCheckListItem && shiftCheckListItem.siteAreas.map(item => (
                                item.isEnabled && (
                                    <React.Fragment key={item.id}>
                                        <tr className="header-category">
                                            <td colSpan={3}>
                                                {translate('ShiftHandoverChecklistTable.SiteAreas.', item.name)}
                                            </td>
                                        </tr>
                                        {item.checkListItems.filter(c => c.isEnabled).map(value => {
                                            let element = formikBag.values.checkListItems.find(x => x.id === value.id && (!x.checkListItemsVersion || x.checkListItemsVersion === formikBag.values.checkListItemsVersion));
                                            if (!element) {
                                                element = setShiftChecklist(value);
                                            }
                                            const index = formikBag.values.checkListItems.indexOf(
                                                element
                                            );

                                            return (
                                                <tr key={value.id}>
                                                    <td className="site-area">
                                                        {translate(
                                                            'ShiftHandoverChecklistTable.CheckLists.',
                                                            value.name
                                                        )}
                                                    </td>
                                                    <td className="narrow">
                                                        <ThumbButton
                                                            id={value.id}
                                                            value={element.status}
                                                            onChange={(v) =>
                                                                setThumbValue(v, index)
                                                            }
                                                            isReadOnly={isReadOnly}
                                                        />
                                                    </td>
                                                    <td>
                                                        {isReadOnly ? (
                                                            <span
                                                                id={getField<
                                                                    IShiftChecklistItem
                                                                >(
                                                                    'comments',
                                                                    `checkListItems[${index}]`
                                                                )}
                                                            >
                                                                {val(
                                                                    'comments',
                                                                    `checkListItems[${index}]`
                                                                )}
                                                            </span>
                                                        ) : (
                                                            <TextField
                                                                id={getField<
                                                                    IShiftChecklistItem
                                                                >(
                                                                    'comments',
                                                                    `checkListItems[${index}]`
                                                                )}
                                                                label=""
                                                                placeholder="Enter any comments..."
                                                                value={val(
                                                                    'comments',
                                                                    `checkListItems[${index}]`
                                                                )}
                                                                handleChange={
                                                                    (e) => setCommentsValue(e, index)
                                                                }
                                                                handleBlur={
                                                                    formikBag.handleBlur
                                                                }
                                                            />
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </React.Fragment>
                                )
                            ))}
                    </tbody>
                </Table>
            </Panel>
        </>
    );
};