import { IEmeraldHelperTextInput } from '../shared/helper.text.input';
import { EmeraldProgressBarVariance } from './progress-bar.variants';
import {
  EmeraldProgressIndicatorCoreInputs,
  IEmeraldProgressIndicatorCoreInputs,
} from './progress-indicator.inputs';

export abstract class EmeraldProgressBarCoreInputs
  extends EmeraldProgressIndicatorCoreInputs
  implements IEmeraldProgressBarCoreInputs
{
  buffer?: number = -1;
  variance?: EmeraldProgressBarVariance = EmeraldProgressBarVariance.ACTIVE;
  helperText?: string;
  statusIcon?: string;
}

export interface IEmeraldProgressBarCoreInputs
  extends IEmeraldProgressIndicatorCoreInputs,
    IEmeraldHelperTextInput {
  buffer?: number;
  variance?: EmeraldProgressBarVariance;
  statusIcon?: string;
}
