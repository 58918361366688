import * as React from 'react';
import selectors from './selectors';
import actions from './actions';
import { connect } from 'react-redux';
import { IAttachmentQuery } from 'models/attachment/attachment-query';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import { Entity } from 'models/entity';
import Attachments from 'components/attachemnts/attachments';

interface IProps {
    id: string;
    loadAttachments: (query: IAttachmentQuery) => void;
    attachments: IAttachmentModel[];
}

export const SpareDocumentsTab: React.FC<IProps> = (props) => {
    return (
        <div className="tabs-container tabs-container-table-only">
            <Attachments
                entity={Entity.CriticalSpare}
                attachments={props.attachments}
                loadAttachments={props.loadAttachments}
                isReadOnly={true}
                id={props.id}
            />
        </div>
    );
};

export default connect(selectors, actions)(SpareDocumentsTab);
