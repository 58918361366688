import classnames from 'classnames';
import { map } from 'lodash';
import * as React from 'react';
import Select from 'react-select';
import { GridSortLink } from 'components/gridSortLink';
import { Loader } from 'components/loader';
import { Percentage } from 'components/percentage';
import { SortDirection } from 'enums/SortDirection';
import { ICermSiteOverviewGroup } from 'models/cerm-site-overview';
import { CermSiteOverviewListGroup } from './cerm-site-overview-list-group';
import './cerm-site-overview-list.scss';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconType } from 'routes/material-icon/material-icon-type';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';

export interface IProps {
    groups: ICermSiteOverviewGroup[];
    isLoading: boolean;
    sortColumn: string;
    sortDirection: SortDirection;
    expandedItems: string[];
    onSort: (field: Extract<keyof ICermSiteOverviewGroup, string>, isSortAscending: boolean) => void;
    onToggleExpanded: (id: string) => void;
    onExpandItems: () => void;
    onCollapseItems: () => void;
    averageScore: number;
    intl: any;
}

interface IState {
    isExpanded: boolean;
    optionValue: any;
}

class CermSiteOverviewListClass extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.onClickHandle = this.onClickHandle.bind(this);
        this.onHandleChange = this.onHandleChange.bind(this);
        this.getColumns = this.getColumns.bind(this);
        let categoryType = 'Services';
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('categoryType')) {
            categoryType = urlParams.get('categoryType');
        }
        this.state = {
            isExpanded: false,
            optionValue: { value: categoryType.toLowerCase(), label: categoryType }
        };
    }

    public onHandleChange(value: any) {
        this.setState({ optionValue: value });
    }

    public onClickHandle() {
        const isExpanded = this.state.isExpanded;
        if (isExpanded) {
            this.props.onCollapseItems();
        } else {
            this.props.onExpandItems();
        }
        this.setState({ isExpanded: !isExpanded });
    }
    public render() {
        const intl = this.props.intl;

        return (
            <>
                <div className="columns">
                    <div className="column is-3">
                        <Select
                            options={this.getValues()}
                            onChange={this.onHandleChange}
                            value={this.state.optionValue}
                        />
                    </div>
                    <div className="column is-9 has-text-right">
                        Average <Percentage value={this.props.averageScore} maxDecimals={0} />
                    </div>
                </div>
                <Loader
                    loading={this.props.isLoading}
                    className="CermSiteOverviewList"
                >
                    {this.props.groups.length > 0
                        ? (
                            <>
                                <div className="Timeline-offset column has-text-weight-bold">
                                    <button className="TimelineHeader__toggle TimelineColumnHeader" onClick={this.onClickHandle}>
                                        < MaterialIcons type={this.state.isExpanded ? MaterialIconType.Minus : MaterialIconType.Plus} />
                                    </button>
                                </div>
                                <div className="CermSiteOverviewList__headers Timeline-offset columns is-vcentered">
                                    <div className={this.getColumnCss('title')}>
                                        {this.getGridHeader('Title', translate(intl, 'RiskRegisterPage.RiskRegisterTable.Title.Title'), this.props)}
                                    </div>

                                    <div className={this.getColumnCss('completion', 'column--numeric')}>
                                        {this.getGridHeader('Completion', translate(intl, 'CermSiteOverview.List.Completion'), this.props)}
                                    </div>
                                    {this.getColumns()}

                                    <div className={this.getColumnCss('averageScore', 'column--numeric')}>
                                        {this.getGridHeader('AverageScore', translate(intl, 'CermSiteOverview.List.Score'), this.props)}
                                    </div>
                                </div>

                                {this.props.groups.map((group) =>
                                    <CermSiteOverviewListGroup
                                        key={group.id}
                                        group={group}
                                        expandedItems={this.props.expandedItems}
                                        onToggleExpanded={this.props.onToggleExpanded}
                                        getServiceScoreLabel={this.getServiceScoreLabel}
                                        getColumnCss={this.getColumnCss}
                                        optionValue={this.state.optionValue.value}
                                    />
                                )}
                            </>
                        )
                        : null
                    }
                </Loader>
            </>
        );
    }
    private getColumns() {
        if (this.state.optionValue.value === 'services') {
            return map(this.props.groups[0].serviceScores, (_, key) => (
                <div key={key} className={this.getColumnCss(key, 'column--numeric')}>
                    {this.getGridHeader(key, this.getServiceScoreLabel(key), this.props)}
                </div>
            ));
        } else {
            return this.pillarColumns().map((pillar: string) => (
                <div key={pillar} className={this.getColumnCss(pillar, 'column--numeric')}>
                    {this.getGridHeader(pillar, this.getServiceScoreLabel(pillar), this.props)}
                </div>
            ));
        }
    }
    private pillarColumns = () => {
        return ['100', '200', '300', '400', '500'];
    }
    private getValues = () => {
        return [
            { value: 'services', label: 'Services' },
            { value: 'pillars', label: 'Pillars' }
        ];
    }
    private getColumnCss = (name: string, ...others: string[]) => classnames(
        'column',
        `column--${name}`,
        ...others
    )
    private getServiceScoreLabel = (key: string) => {
        switch (key.toLowerCase()) {
            case 'm&e':
                return 'M&E';
            case 'it':
                return 'IT';
            case 'core':
                return 'Core';
            case 'energy':
                return 'Energy';
            default:
                return key;
        }
    }
    private getGridHeader = (
        fieldName: string,
        label: string,
        props: IProps) => {
        const onSort = (
            sortField: Extract<keyof ICermSiteOverviewGroup, string>,
            sortDirection: SortDirection
        ) => props.onSort(
            sortField,
            sortDirection === SortDirection.Ascending
        );
        return (
            <GridSortLink
                currentSortFieldName={props.sortColumn}
                currentSortDirection={props.sortDirection}
                sortFieldName={fieldName}
                onSort={onSort}
            >
                {label}
            </GridSortLink>
        );
    }
}

export const CermSiteOverviewList = injectIntl(CermSiteOverviewListClass);
