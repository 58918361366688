import * as React from 'react';
import Page from 'components/v2/page/Page';
import { RouteComponentProps } from 'react-router';
import { CheckboxFilter } from '../../../components/checkboxFilter';
import { SearchFilter } from '../../../components/searchFilter';
import { IKeyValue } from '../../../models/key-value';
import { IQuestionSet, IQuestionSetFilters } from '../../../models/question-set';
import { QuestionSetGridToolbar } from './questionSetGridToolbar';
import { IQuestionSetListDispatchProps, QuestionSetList } from './questionSetList';
import { OverlayPanel } from 'components/v2/components';
import { UiPreferences, usePageUIPreferences } from 'utils/page-ui-preferences-utils';
import { checkFilterExistsWithIgnoreKey } from 'utils/array-utils';
import { HelpModuleContainer } from 'routes/help-module/help-module-container';
import { ModuleType } from 'models/help-module';
import { OperatingPlatform } from 'models/site-profile';
import { useLocation } from 'react-router-dom';
import useTranslate from 'translations/translation-utils';
import { IPerson } from 'models/person';
import { Form, FormikProps, Formik } from 'formik';
import { Control, Field } from 'components/form';
import { BulmaSize } from 'enums/BulmaSize';
import { Icon, IconType } from 'components/icon';
import { IOperatingPlatformProcess } from 'models/site-profile';
import { onRouteChange } from 'actions/app-actions';
import { RouteQuestionSetsUrlOperatingPlatform } from 'routes/upsert-site/routes';
import { Select as QuantumSelect } from 'components/select';
import './question-sets-page.scss';
import { IOperatingPlatform } from 'models/operating-platform-model';

interface IRouteParams {
    operatingPlatform?: string;
}

export interface IProps extends RouteComponentProps<IRouteParams>, IQuestionSetListDispatchProps {
    items: IQuestionSet[];
    isLoading: boolean;
    isExporting: boolean;
    sortColumn: string;
    sortAscending: boolean;
    secondarySortColumn: string;
    secondarySortAscending: boolean;
    expandedItems: string[];
    expandedFilters: string[];
    lookupStatuses: Array<IKeyValue<string>>;
    lookupCompletion: Array<IKeyValue<string>>;
    permissions: Array<IKeyValue<string>>;
    isInitialising: boolean;
    loadQuestionSets: (filters: IQuestionSetFilters) => void;
    downloadQuestionSet: (operatingPlatform: string) => void;
    person: IPerson;
    onSubmit: (request: IOperatingPlatformProcess) => void;
    operatingPlatformList: IOperatingPlatform[];
}
interface MyFormValues {
    operatingPlatformDropdown: string;
}

export const QuestionSetsPage: React.FC<IProps> = ({
    loadQuestionSets,
    downloadQuestionSet,
    ...props
}) => {
    //downloadQuestionSet();
    const translate = useTranslate();
    const getUpdatedFilters = (
        updatedFilters: IQuestionSetFilters,
        key: Extract<keyof IQuestionSetFilters, string>,
        element: string
    ): IQuestionSetFilters => {
        const newFilters = { ...updatedFilters };
        const index = newFilters[key].indexOf(element);
        if (index !== -1) {
            const items = [...newFilters[key].slice(0, index), ...newFilters[key].slice(index + 1)];
            newFilters[key] = items;
        } else {
            const items = [element, ...newFilters[key].slice(0, newFilters[key].length)];
            newFilters[key] = items;
        }
        const filtersWithoutSpecials = {
            ...newFilters
        } as IQuestionSetFilters;

        return filtersWithoutSpecials;
    };
    const location = useLocation();

    const initializeFilters = (operatingPlatform: string) => {
        let initialFilters = {
            keywords: [],
            statuses: [],
            others: [],
            operatingPlatform: []
        } as IQuestionSetFilters;
        const urlParams = new URLSearchParams(props.location.search);
        urlParams.forEach((element, key) => {
            initialFilters = getUpdatedFilters(initialFilters, key as any, element);
        });
        initialFilters.operatingPlatform = [operatingPlatform];
        return initialFilters;
    };


    const [showFilters, setShowFilters] = React.useState(false);
    const [filters, setFilters] = usePageUIPreferences<IQuestionSetFilters>(
        props.match.params.operatingPlatform === OperatingPlatform.Healthcare
            ? UiPreferences.RiskQuestionSetsPageFiltersHC
            : UiPreferences.RiskQuestionSetsPageFiltersDC,
        initializeFilters(
            props.match.params.operatingPlatform === OperatingPlatform.Healthcare
                ? OperatingPlatform.Healthcare
                : OperatingPlatform.DataCenter
        )
    );
    const [showHelp, setShowHelp] = React.useState(false);

    const onClickHelp = () => {
        setShowHelp(!showHelp);
    };
    React.useEffect(() => {
        setFilters(buildRequestFiltersObject(false));
    }, [location.key]);

    React.useEffect(() => {
        loadQuestionSets(buildRequestFiltersObject(true));
    }, [filters]);

    const buildRequestFiltersObject = (keepState: boolean) => {
        return {
            statuses: filters && keepState ? filters.statuses : [],
            others: filters && keepState ? filters.others : [],
            keywords: filters && keepState ? filters.keywords : [],
            operatingPlatform: [props.match.params.operatingPlatform]
        } as IQuestionSetFilters;
    };

    const toggleFilters = () => {
        setShowFilters(showFilters ? false : true);
    };

    const resetFilters = () => {
        setFilters(buildRequestFiltersObject(false));
    };

    const onAddFilterKeyword = (keyword) => {
        if (!keyword) {
            return;
        }
        const newKeywords = [...filters.keywords, keyword];
        setFilters({ ...filters, keywords: newKeywords });
    };

    const onRemoveFilterKeyword = (keyword) => {
        const newKeywords = filters.keywords.filter((x) => x !== keyword);
        setFilters({ ...filters, keywords: newKeywords });
    };

    const onFilter = (key: Extract<keyof IQuestionSetFilters, string>, element: string) => {
        const updatedFilters = getUpdatedFilters(filters, key, element);
        setFilters(updatedFilters);
    };

    const commonProps = {
        expandedFilters: ['statuses', 'others'],
        onFilter,
        onToggleFilterExpanded: void 0
    };
    const isFilterExists = checkFilterExistsWithIgnoreKey(filters, 'operatingPlatform');
    const getCustomPageTitle = () => {
        return props.match.params.operatingPlatform;
    }
    const getFilteredStatus = () => {
        return props.lookupStatuses.filter(x => x.key !== 'Archived'); //--Ref:1171052
    }

    const initialValues: MyFormValues = {
        operatingPlatformDropdown: props.match.params.operatingPlatform,
    };

    const onSubmit = (upsertRoleRequest: IOperatingPlatformProcess) => {
        props.onSubmit(upsertRoleRequest);
    };

    let formikBag: FormikProps<IOperatingPlatformProcess>;

    const selectectedOperatingPlatform = (e: any) => {
        const value = e.target.value;
        formikBag.setFieldValue('operatingPlatformDropdown', value);
        onRouteChange(`${RouteQuestionSetsUrlOperatingPlatform(value)}`);
    };

    const renderForm = (formik: FormikProps<IOperatingPlatformProcess>) => {
        formikBag = formik;
        if (formikBag.values.operatingPlatformDropdown !== props.match.params.operatingPlatform) {
            const operatingPlatform = props.match.params.operatingPlatform;
            if (operatingPlatform) {
                formikBag.setFieldValue('operatingPlatformDropdown', operatingPlatform);
            }
        }
        return (
            <Form id="riskProfileQuestionnaireDropdown">
                <Field
                    isHorizontal={true}
                    htmlFor={translate(
                        'RiskQuestions.Views.Dropdown.Title'
                    )}
                    label={translate(
                        'RiskQuestions.Views.Dropdown.Title'
                    )}
                    labelSize={BulmaSize.Medium}
                >
                    <Field>
                        <Control>
                            <QuantumSelect
                                label={translate(
                                    'RiskQuestions.Views.Dropdown.Title'
                                )}
                                id="operatingPlatformdropdown"
                                name="operatingPlatformDropdown"
                                aria-required="true"
                                options={props.person.accessRights.operatingPlatforms
                                    .map(x => ({
                                        key: x,
                                        value: x,
                                        label: x,
                                    }))
                                    .sort((a, b) => a.label.localeCompare(b.label))}
                                className="operatingPlatforms-dropdown"
                                value={formikBag.values.operatingPlatformDropdown}
                                onBlur={formikBag.handleBlur}
                                onChange={selectectedOperatingPlatform}
                            />
                        </Control>
                    </Field>
                </Field>
            </Form>
        );
    };
    return (
        <Page title={`${translate('QuestionnSets.Page.Title')}` + getCustomPageTitle()} isAdminPage={true}>
            {props.person.accessRights.operatingPlatforms.length > 1 ? (
                <>
                    <div className="risk-profile-questionaire-dropdown">
                        <Formik<Partial<IOperatingPlatformProcess>>
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                            render={renderForm}
                        />
                        <div className="risk-profile-questionaire-dropdown-info-box">
                            <div className="risk-profile-questionaire-dropdown-info">
                                <Icon type={IconType.InfoCircle} />
                            </div>
                            <div className="risk-profile-questionaire-dropdown-info-text">
                                {translate(
                                    'RiskQuestions.Views.Dropdown.Info'
                                )}
                            </div>
                        </div>
                    </div>
                    <hr />
                </>
            ) : null}
            <QuestionSetGridToolbar
                isInitialising={props.isInitialising}
                onShowFilters={toggleFilters}
                onClearFilters={resetFilters}
                onDownload={downloadQuestionSet}
                isFilterExists={isFilterExists}
                onClickHelp={onClickHelp} isShowHelp={true}
                operatingPlatformList={props.operatingPlatformList}
            />
            <HelpModuleContainer
                isShown={showHelp}
                onClose={onClickHelp}
                moduleType={ModuleType.RiskQuestionsOverview}
            />
            <OverlayPanel
                title={translate('RiskRegisterPage.Title.Filters')}
                isVisible={showFilters}
                onClose={() => setShowFilters(false)}
                onOutsideDialogClick={() => setShowFilters(false)}
                onClearFilters={resetFilters}
                isFilterExists={isFilterExists}
            >
                <SearchFilter
                    title={translate('QuestionnSets.SearchFilter.Title')}
                    name="keywords"
                    values={filters.keywords}
                    onRemoveFilterKeyword={onRemoveFilterKeyword}
                    onAddFilterKeyword={onAddFilterKeyword}
                />
                <CheckboxFilter
                    title={translate('RiskRegisterPage.RiskFilter.Title.Status')}
                    name="statuses"
                    selectedFilters={filters.statuses}
                    unreservedFilters={getFilteredStatus()}
                    {...commonProps}
                />
                <CheckboxFilter
                    title={translate('RiskActionTab.Category.dropdown.Other')}
                    name="others"
                    selectedFilters={filters.others}
                    unreservedFilters={props.lookupCompletion}
                    {...commonProps}
                />
            </OverlayPanel>
            <QuestionSetList {...props} />
        </Page>
    );
};
