import { Select } from 'components/select';
import { FormikProps } from 'formik';
import { IKeyValue } from 'models';
import { ICommonLookupModel } from 'models/common-lookup-model';
import { RiskCategory } from 'models/risk-category';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Control, Field, Help, Input } from '../../../../components/form';
import { BulmaColor } from '../../../../enums/BulmaColor';
import { BulmaSize } from '../../../../enums/BulmaSize';
import { IQuestion, IRiskTemplate } from '../../../../models/question-set';
import { formikFieldCssByState } from '../../../../utils/form-utils';
import { ICreateQuestionSetPageRouteParams } from '../create-question-set-page';
import useTranslate from 'translations/translation-utils';

export interface IProps extends RouteComponentProps<ICreateQuestionSetPageRouteParams> {
    riskCategories: RiskCategory[];
    riskLikelihoods: Array<IKeyValue<string>>;
    riskImpacts: Array<IKeyValue<string>>;
    formikBag: FormikProps<Partial<any>>;
    operatingPlatform: string;
}

export const RiskForm: React.FC<IProps> = ({
    riskCategories,
    riskLikelihoods,
    riskImpacts,
    formikBag,
    operatingPlatform,
    match,
}) => {
    const translate = useTranslate();
    const questionIndex = Number(match.params.questionIndex) || 0;
    const entityName: Extract<keyof IQuestion, string> = 'risk';
    const categories = riskCategories.filter(
        (x) => !x.operatingPlatform || x.operatingPlatform === operatingPlatform
    );

    const subCategories: ICommonLookupModel[] = !!value('riskCategoryId')
        ? riskCategories
            .find((x) => x.key === value('riskCategoryId'))
            ?.subCategory?.filter(
                (x) => !x.operatingPlatform || x.operatingPlatform === operatingPlatform
            )
        ?? []
        : [];

    const field = (name: Extract<keyof IRiskTemplate, string>) => `questions.${questionIndex}.${entityName}.${name}`;

    function getKeyed<T extends Extract<keyof IRiskTemplate, string>>(key: 'values' | 'errors', name: T): string;
    function getKeyed<T extends Extract<keyof IRiskTemplate, string>>(key: 'touched', name: T): boolean;
    function getKeyed<T extends Extract<keyof IRiskTemplate, string>>(key: 'values' | 'errors' | 'touched', name: T) {
        return formikBag[key] &&
            formikBag[key].questions &&
            formikBag[key].questions[questionIndex] &&
            formikBag[key].questions[questionIndex].risk &&
            formikBag[key].questions[questionIndex].risk[name];
    }

    function value<T extends Extract<keyof IRiskTemplate, string>>(name: T) {
        return getKeyed<T>('values', name)?.trimStart() || '';
    }

    const getErrors = (name: Extract<keyof IRiskTemplate, string>) => getKeyed('errors', name);
    const isTouched = (name: Extract<keyof IRiskTemplate, string>) => getKeyed('touched', name);
    const getFieldCss = (name: Extract<keyof IRiskTemplate, string>, otherCss?: string) =>
        formikFieldCssByState(Boolean(getErrors(name)), isTouched(name), otherCss);

    const getHelpByKey = (name: Extract<keyof IRiskTemplate, string>) => (
        <Help isShown={formikBag.submitCount > 0 || isTouched(name)} bulmaColor={BulmaColor.Danger}>
            {getErrors(name)}
        </Help>
    );

    const handleCategoryChange = (e: any) => {
        formikBag.setFieldValue(field('riskSubCategoryId'), '');
        formikBag.handleChange(e);
    };

    return (
        <>
            <header>
                <h2 className="title is-4">{translate('RiskForm.Lable.1')}</h2>
            </header>

            <Field
                isHorizontal={true}
                htmlFor={field('title')}
                label={translate('RiskForm.Lable.2') + '*'}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <Input
                            id={field('title')}
                            name={field('title')}
                            placeholder={translate('RiskForm.Lable.3')}
                            type="text"
                            className={getFieldCss('title')}
                            value={value('title')}
                            onChange={formikBag.handleChange}
                            onBlur={(event) => formikBag.setFieldValue(event.target.name, event.target.value.trim())}
                        />
                    </Control>
                    {getHelpByKey('title')}
                </Field>
            </Field>
            <Field
                isHorizontal={true}
                htmlFor={field('riskCategoryId')}
                label={translate('RiskRegisterPage.RiskFilter.Title.Category') + '*'}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <Select
                            id={field('riskCategoryId')}
                            name={field('riskCategoryId')}
                            aria-required="true"
                            options={categories}
                            className={getFieldCss('riskCategoryId')}
                            value={value('riskCategoryId')}
                            onBlur={formikBag.handleBlur}
                            onChange={handleCategoryChange}
                            emptyOptionsValue={translate('ActionDetails.Lable.4') + '...'}
                        />
                    </Control>
                    {getHelpByKey('riskCategoryId')}
                </Field>
            </Field>
            <Field
                isHorizontal={true}
                htmlFor={field('riskSubCategoryId')}
                label={translate('RiskRegisterPage.RiskRegisterTable.Title.SubCategory') + '*'}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <Select
                            id={field('riskSubCategoryId')}
                            name={field('riskSubCategoryId')}
                            aria-required="true"
                            options={subCategories}
                            className={getFieldCss('riskSubCategoryId')}
                            value={value('riskSubCategoryId')}
                            onBlur={formikBag.handleBlur}
                            onChange={formikBag.handleChange}
                            emptyOptionsValue={translate('ActionDetails.Lable.4') + '...'}
                            disabled={!value('riskCategoryId')}
                        />
                    </Control>
                    {getHelpByKey('riskSubCategoryId')}
                </Field>
            </Field>
            <Field
                isHorizontal={true}
                htmlFor={field('description')}
                label={translate('RiskForm.Lable.4') + '*'}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <textarea
                            id={field('description')}
                            name={field('description')}
                            className={getFieldCss('description', 'textarea')}
                            placeholder={translate('RiskForm.Lable.5')}
                            value={value('description')}
                            onBlur={formikBag.handleBlur}
                            onChange={formikBag.handleChange}
                        />
                    </Control>
                    {getHelpByKey('description')}
                </Field>
            </Field>
            <Field
                isHorizontal={true}
                htmlFor={field('riskLikelihoodId')}
                label={translate('RiskRegisterPage.RiskRegisterTable.Title.Likelihood') + '*'}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <Select
                            id={field('riskLikelihoodId')}
                            name={field('riskLikelihoodId')}
                            aria-required="true"
                            options={riskLikelihoods}
                            className={getFieldCss('riskLikelihoodId')}
                            value={value('riskLikelihoodId')}
                            onBlur={formikBag.handleBlur}
                            onChange={formikBag.handleChange}
                            emptyOptionsValue={translate('ActionDetails.Lable.4') + '...'}
                        />
                    </Control>
                    {getHelpByKey('riskLikelihoodId')}
                </Field>
            </Field>
            <Field
                isHorizontal={true}
                htmlFor={field('riskImpactId')}
                label={translate('RiskForm.Lable.6') + '*'}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <Select
                            id={field('riskImpactId')}
                            name={field('riskImpactId')}
                            aria-required="true"
                            options={riskImpacts}
                            className={getFieldCss('riskImpactId')}
                            value={value('riskImpactId')}
                            onBlur={formikBag.handleBlur}
                            onChange={formikBag.handleChange}
                            emptyOptionsValue={translate('ActionDetails.Lable.4') + '...'}
                        />
                    </Control>
                    {getHelpByKey('riskImpactId')}
                </Field>
            </Field>
        </>
    );
};
