import { createSelector } from 'reselect';
import { IRootState } from '../store';
import { getSitePeopleWithSpecificRoles } from 'sharedSelectors';

const getIsLoading = (state: IRootState) => state.cermProcessOwnerState.isLoading;
const getIsSaving = (state: IRootState) => state.cermProcessOwnerState.isSaving;
const getIsExporting = (state: IRootState) => state.cermProcessOwnerState.isExporting;
const getSiteId = (state: IRootState) => state.app.siteId;
const getIsSitePersonsLoading = (state: IRootState) => state.persons.grid.isLoading;
const getCermProcessOwner = (state: IRootState) => state.cermProcessOwnerState.cermProcessOwner;

export default createSelector(
    getIsLoading,
    getIsSaving,
    getIsExporting,
    getSiteId,
    getSitePeopleWithSpecificRoles,
    getCermProcessOwner,
    getIsSitePersonsLoading,
    (
        isLoading,
        isSaving,
        isExporting,
        siteId,
        personsList,
        cermProcessOwner,
        isSitePersonsLoading
    ) => ({
        isLoading: isLoading || isSitePersonsLoading,
        isSaving,
        isExporting,
        siteId,
        personsList,
        cermProcessOwner,
    })
);
