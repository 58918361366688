import { Formik, FormikProps } from 'formik';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { object, string } from 'yup';
import { onRouteBack } from 'actions/app-actions';
import { CustomSelect } from 'components/CustomSelect';
import { Control, Field, Help, Input } from 'components/form';
import { Loader } from 'components/loader/Loader';
import { ArticlePanel } from 'components/panel';
import { BulmaColor } from 'enums/BulmaColor';
import { BulmaSize } from 'enums/BulmaSize';
import { IClient, IAuditor, IMyDataClient } from 'models/client';
import { IKeyValue } from 'models/key-value';
import { formikFieldCss } from 'utils/form-utils';
import Page from 'components/v2/page/Page';
import { Button, ButtonWithConfirmDialog } from 'components/v2/components';
import CryptoJS from 'crypto-js';
import { checkPermissions } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import { Checkbox } from 'components/form/Checkbox';
import { NumberField } from 'components/form/fields/number-field';
import ReactTooltip from 'react-tooltip';
import {
    MaterialIconColor,
    MaterialIconSize,
    MaterialIconType,
} from 'routes/material-icon/material-icon-type';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import useTranslate from 'translations/translation-utils';
import { findValueByKey } from 'helpers/helpers';

export interface IRouterClientId {
    clientId?: string;
}

export interface IProps extends RouteComponentProps<IRouterClientId> {
    industryLookUps: Array<IKeyValue<string>>;
    upsertRequest?: IClient;
    isLoading: boolean;
    iAuditorAccountName: IAuditor;
    myDataClients: IMyDataClient[];
    permissions?: Array<IKeyValue<string>>;
    appSettings?: Array<IKeyValue<string>>;
    onRouteChange: (url: string) => void;
    onCreate: (request: IClient) => void;
    onUpdate: (request: IClient) => void;
    onDelete: (id: string) => void;
    loadClient: (clientId: string) => void;
    loadMyDataClients: () => void;
    getOrganizationDetails: (clientId: string) => void;
    saveAccessToken: (accessToken: string, clientId: string) => void;
    revokeAccessToken: (clientId: string) => void;
    resetClient: () => void;    
    loadAppSettings: () => void;
}

export const ClientUpsertPage: React.FC<IProps> = (props) => {
    const translate = useTranslate();
    let formikBag: FormikProps<IClient>;
    const clientsPageUrl = '/Client';
    const customLinks: Array<IKeyValue<string>> = [{ key: clientsPageUrl, value: translate('ClientUpsert.Lable.Title') }];
    const [isValid, setIsValid] = React.useState(false);
    const [accessToken, setAccessToken] = React.useState('');
    const [showError, setShowError] = React.useState(false);
    const [customRetentionPeriodEnabled, setCustomRetentionPeriodEnabled] = React.useState<boolean>(true);

    const [error, setError] = React.useState('');
    const [originalStart, setOriginalStart] = React.useState([]);
    const [newDomain, setNewDomain] = React.useState('');
    const [isDirty, setIsDirty] = React.useState(false);
    const [isAddingDomain, setIsAddingDomain] = React.useState(false);
    const [clientOptions, setClientOptions] = React.useState<IKeyValue<string>[]>([])
    const [clientName, setClientName] = React.useState('')

    React.useEffect(() => {
        props.resetClient()
        props.loadMyDataClients()
        setClientName('')
        props.loadAppSettings()
    }, []);

    React.useEffect(() => {
        props.resetClient()
        if (props.match.params.clientId) {
            props.getOrganizationDetails(props.match.params.clientId);
            props.loadClient(props.match.params.clientId);
            props.loadMyDataClients()
        }
    }, [props.match.params.clientId])

    React.useEffect(() => {
        if (props.myDataClients) {
            setClientOptions(props.myDataClients.map((x) => {
                return {
                    key: x.clientCode,
                    value: x.clientCode,
                    label: x.clientCode
                }
            }))
        }
        if (props.upsertRequest && props.myDataClients) {
            const client = props.myDataClients.filter((client) => client.clientCode === props.upsertRequest.clientCode)[0]
            setClientName(client ? client.clientName : '')
        }
    }, [props.myDataClients, props.upsertRequest])

    const validationSchema = object<Partial<IClient>>().shape({
        name: string().trim().required(translate('ClientUpsert.Error.NameIsRequired')),
        industry: string().required(translate('ClientUpsert.Error.IndustryIsRequired')).nullable(true),
    });

    const clientCodeChange = (e: any) => {
        const value = e.target.value
        formikBag.setFieldValue('clientCode', value)
        const index = props.myDataClients?.findIndex((x) => x.clientCode == value)
        setClientName((index >= 0) ? props.myDataClients[index].clientName : '')
    }
    const setNewDomainField = (event: any) => {
        setNewDomain(event.target.value.trim());
    };

    const checkIsDirty = (newList: string[]) => {
        const clean =
            originalStart.length === newList.length &&
            originalStart.every((value, index) => value === newList[index]);
        setIsDirty(!clean);
    };

    const clear = () => {
        setError('');
        setNewDomain('');
        setIsAddingDomain(false);
    };

    const addDomain = () => {
        if (formikBag.values.domains?.some((c) => c === newDomain)) {
            setError(translate('ClientUpsert.Lable.1'));
            return;
        }

        if (newDomain.length <= 5) {
            setError(translate('ClientUpsert.Lable.2'));
            return;
        }

        const newList = formikBag.values.domains ? formikBag.values.domains?.map((c) => c) : [];
        newList.push(newDomain);
        formikBag.setFieldValue('domains', newList);
        checkIsDirty(newList);
        clear();
    };

    const isAddingDomainToggle = () => {
        setIsAddingDomain(true);
    };

    const deleteDomain = (name: string) => {
        const newList = formikBag.values.domains.filter((c) => c !== name);
        formikBag.setFieldValue('domains', newList);
        checkIsDirty(newList);
    };

    React.useEffect(() => {
        if (props.iAuditorAccountName) {
            setAccessToken(props.iAuditorAccountName.name);
        }
    }, [props.iAuditorAccountName]);

    React.useEffect(() => {
        const enabled = !props.upsertRequest || props.upsertRequest.retentionPeriod === null;
        setCustomRetentionPeriodEnabled(enabled);
        if (props.upsertRequest && props.upsertRequest.domains) {
            setOriginalStart(props.upsertRequest.domains);
        } else {
            setOriginalStart([]);
        }
    }, [props.upsertRequest]);

    React.useEffect(() => {
        ReactTooltip.rebuild();
    });

    const title =
        props.upsertRequest && props.upsertRequest.name
            ? props.upsertRequest.name
            : translate('ClientUpsert.Lable.3');

    const checkValidToken = () => {
        if (props.iAuditorAccountName && props.iAuditorAccountName.name === '') {
            return false;
        } else if (props.iAuditorAccountName && !props.iAuditorAccountName.name) {
            return true;
        }
        return false;
    };

    const getCloseModalBody = () => {
        return <p>{translate('ClientUpsert.Lable.4')}</p>;
    };

    const getDeleteModalBody = () => {
        return <p>{translate('ClientUpsert.Lable.5')}</p>;
    };

    const onCancel = () => {
        onRouteBack();
    };

    const onSubmit = (client: IClient) => {
        if (client.id) {
            props.onUpdate(client);
        } else {
            props.onCreate(client);
        }
    };

    const onDelete = () => {
        props.onDelete(props.match.params.clientId);
    };

    const onRetentionPeriodCheckboxChange = (event: React.FormEvent<HTMLInputElement>) => {
        const checked = event.currentTarget.checked;
        setCustomRetentionPeriodEnabled(checked);

        if (checked && formikBag.values.retentionPeriod) {
            formikBag.setFieldValue('retentionPeriod', '');
        }
    };

    const onSubmitToken = () => {
        if (!accessToken.trim()) {
            setShowError(true);
        } else {
            setShowError(false);

            const key = CryptoJS.enc.Base64.parse(findValueByKey('REACT_APP_IAUDITOR_ENCRYPTION_KEY', props.appSettings));
            const iv = CryptoJS.enc.Base64.parse(findValueByKey('REACT_APP_IAUDITOR_ENCRYPTION_IV', props.appSettings));
            const token = CryptoJS.AES.encrypt(accessToken, key, { iv }).toString();

            if (token) {
                props.saveAccessToken(token, props.match.params.clientId);
            }
        }
    };

    const onRevokeToken = () => {
        if (accessToken) {
            props.revokeAccessToken(props.match.params.clientId);
        }
    };

    const renderIAuditorForm = () => {
        return (
            <form id="clientUpsertPage">
                <div className="columns">
                    <div className="column is-12">
                        <Field
                            isHorizontal={true}
                            htmlFor="accessToken"
                            label={translate('ClientUpsert.Lable.6')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Input
                                        id="accessToken"
                                        name="accessToken"
                                        aria-required="true"
                                        placeholder={translate('ClientUpsert.Lable.6')}
                                        type="text"
                                        isDisabled={
                                            props.iAuditorAccountName &&
                                                props.iAuditorAccountName.name
                                                ? true
                                                : false
                                        }
                                        value={accessToken ? accessToken : ''}
                                        onChange={(event) =>
                                            setAccessToken(event.currentTarget.value)
                                        }
                                        autocomplete="off"
                                    />
                                </Control>
                                <Help bulmaColor={BulmaColor.Danger} isShown={showError}>
                                    {translate('ClientUpsert.Lable.7')}
                                </Help>
                                <Help bulmaColor={BulmaColor.Danger} isShown={checkValidToken()}>
                                    {translate('ClientUpsert.Lable.8')}
                                </Help>
                            </Field>
                        </Field>
                    </div>
                </div>

                <div className="action-groups">
                    <div className="action-group">
                        <Button id="person_upsert__cancel" buttonType="cancel" onClick={onCancel}>
                            {translate('Globals.Label.Cancel')}
                        </Button>
                    </div>

                    {checkPermissions([Privilege.IAuditorClientTokenUpdate], props.permissions) && (
                        <div className="action-group">
                            <ButtonWithConfirmDialog
                                message={getCloseModalBody()}
                                onConfirm={onRevokeToken}
                                buttonText={translate('ClientUpsert.Lable.9')}
                                title={translate('RiskRegisterUpsertPage.ConfirmDialog.ConfirmTitle')}
                                isButtonDisabled={
                                    props.iAuditorAccountName && !props.iAuditorAccountName.name
                                        ? true
                                        : false
                                }
                            />
                            <Button
                                id="person_upsert_publish"
                                onClick={onSubmitToken}
                                disabled={
                                    props.iAuditorAccountName && props.iAuditorAccountName.name
                                        ? true
                                        : false
                                }
                            >
                                {translate('Globals.Label.Save')}
                            </Button>
                        </div>
                    )}
                </div>
            </form>
        );
    };

    const renderMyDataForm = () => {
        return (
            <>
                <div className="columns my-data">
                    <div className="column is-12">

                        <Field
                            isHorizontal={true}
                            htmlFor="clientCode"
                            label={translate('ClientUpsert.Lable.10')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <CustomSelect
                                        id="clientCode"
                                        name="clientCode"
                                        aria-required="true"
                                        placeholder={translate('ClientUpsert.Lable.11')}
                                        options={clientOptions}
                                        className={formikFieldCss(formikBag, 'clientCode')}
                                        value={formikBag.values.clientCode || ''}
                                        onChange={clientCodeChange}
                                        onBlur={formikBag.handleBlur}
                                        emptyOptionsValue={translate('ClientUpsert.Lable.12')}
                                    />
                                </Control>
                                <Help
                                    bulmaColor={BulmaColor.Danger}
                                    isShown={formikBag.touched.clientCode}
                                >
                                    {formikBag.errors.clientCode}
                                </Help>
                            </Field>
                        </Field>
                        <Field
                            isHorizontal={true}
                            htmlFor="clientName"
                            label={translate('ClientUpsert.Lable.13')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Input
                                        id="clientName"
                                        name="clientName"
                                        aria-required="true"
                                        placeholder={translate('ClientUpsert.Lable.13')}
                                        type="text"
                                        className={formikFieldCss(formikBag, 'name')}
                                        value={clientName}
                                        isDisabled={true}
                                    />
                                </Control>
                            </Field>
                        </Field>
                    </div>
                </div>
            </>
        )
    }

    const renderDomainForm = () => {
        return (
            <>
                {formikBag.values.domains &&
                    formikBag.values.domains.map((item, index) => (
                        <div className="columns client-domain">
                            <div className="column is-12">
                                <Field
                                    key={"lblDomain" + index}
                                    isHorizontal={true}
                                    htmlFor="domain"
                                    label={translate('ClientUpsert.Lable.14')}
                                    labelSize={BulmaSize.Medium}
                                >
                                    <Field>
                                        <Control>
                                            <Input
                                                key={"txtDomain" + index}
                                                id={"txtDomain" + index}
                                                name="domin"
                                                aria-required="true"
                                                placeholder={translate('ClientUpsert.Lable.14')}
                                                type="text"
                                                isDisabled={true}
                                                value={item}
                                                onChange={() => { }}
                                                autocomplete="off"
                                            />
                                        </Control>
                                    </Field>
                                    {checkPermissions([Privilege.ClientDomain], props.permissions) &&
                                        <a data-tip={translate('ShiftHandoversAdminPage.label.Delete')} onClick={() => deleteDomain(item)}>
                                            <MaterialIcons
                                                type={MaterialIconType.Delete}
                                                color={MaterialIconColor.green}
                                                size={MaterialIconSize.medium}
                                            />
                                        </a>
                                    }
                                </Field>
                            </div>
                        </div>
                    ))}
                {isAddingDomain && (
                    <div className="columns">
                        <div className="column is-12">
                            <Field
                                key={"lblNewDomain"}
                                isHorizontal={true}
                                htmlFor="newDomain"
                                label={translate('ClientUpsert.Lable.14')}
                                labelSize={BulmaSize.Medium}
                            >
                                <Field>
                                    <Control>
                                        <Input
                                            id={"newDomain"}
                                            name="newDomin"
                                            aria-required="true"
                                            placeholder={translate('ClientUpsert.Lable.14')}
                                            type="text"
                                            isDisabled={false}
                                            value={newDomain}
                                            onChange={setNewDomainField}
                                            autocomplete="off"
                                        />
                                        <p className="help is-danger">{error}</p>
                                    </Control>
                                </Field>
                                <a data-tip={translate('ClientUpsert.Lable.15')} onClick={() => addDomain()}>
                                    <MaterialIcons
                                        type={MaterialIconType.Add}
                                        color={MaterialIconColor.green}
                                        size={MaterialIconSize.medium}
                                    />
                                </a>
                                <a data-tip={translate('ShiftHandoversAdminPage.label.Delete')} onClick={() => clear()}>
                                    <MaterialIcons
                                        type={MaterialIconType.Delete}
                                        color={MaterialIconColor.green}
                                        size={MaterialIconSize.medium}
                                    />
                                </a>
                            </Field>
                        </div>
                    </div>
                )}
            </>
        );
    };

    const renderForm = (formik: FormikProps<IClient>) => {
        formikBag = formik;
        setIsValid(formik.isValid);

        const onSave = () => {
            formikBag && formikBag.validateForm().then(() => {
                if (isValid || (!formikBag.dirty && isDirty && error == '')) {
                    formikBag.submitForm();
                }
            });
        };

        return (
            <form id="clientUpsertPage" onSubmit={formikBag.handleSubmit}>
                <div className="columns">
                    <div className="column is-12">
                        <Field
                            isHorizontal={true}
                            htmlFor="name"
                            label={translate('IncidentApprovals.Label.Name')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Input
                                        id="name"
                                        name="name"
                                        aria-required="true"
                                        placeholder={translate('IncidentApprovals.Label.Name')}
                                        type="text"
                                        className={formikFieldCss(formikBag, 'name')}
                                        value={formikBag.values.name || ''}
                                        onChange={formikBag.handleChange}
                                        onBlur={(event) => formikBag.setFieldValue(event.target.name, event.target.value.trim())}
                                    />
                                </Control>
                                <Help
                                    bulmaColor={BulmaColor.Danger}
                                    isShown={formikBag.touched.name}
                                >
                                    {formikBag.errors.name}
                                </Help>
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor="retentionPeriodCheckbox"
                            label={translate('ClientUpsert.Lable.16')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Checkbox
                                        label={translate('ClientUpsert.Lable.17')}
                                        checked={customRetentionPeriodEnabled}
                                        id={'retentionPeriodCheckbox'}
                                        onChange={onRetentionPeriodCheckboxChange}
                                    />
                                </Control>
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor="retentionPeriod"
                            label={translate('ClientUpsert.Lable.18')}
                            labelSize={BulmaSize.Medium}
                        >
                            <NumberField
                                id="retentionPeriod"
                                error={formikBag.errors.retentionPeriod}
                                value={formikBag.values.retentionPeriod || ''}
                                handleChange={formikBag.handleChange}
                                handleBlur={formikBag.handleBlur}
                                allowNegative={false}
                                thousandSeparator={false}
                                fixedDecimalScale={true}
                                decimalScale={0}
                                isDisabled={customRetentionPeriodEnabled}
                            />
                            <span data-tip={translate('ClientUpsert.Lable.19')}>
                                <MaterialIcons
                                    type={MaterialIconType.Info}
                                    size={MaterialIconSize.medium}
                                />
                            </span>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor="industry"
                            label={translate('ClientUpsert.Lable.20')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <CustomSelect
                                        id="industry"
                                        name="industry"
                                        aria-required="true"
                                        placeholder={translate('ClientUpsert.Lable.21')}
                                        options={props.industryLookUps}
                                        className={formikFieldCss(formikBag, 'industry')}
                                        value={formikBag.values.industry || ''}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                        emptyOptionsValue={translate('ClientUpsert.Lable.12')}
                                    />
                                </Control>
                                <Help
                                    bulmaColor={BulmaColor.Danger}
                                    isShown={formikBag.touched.industry}
                                >
                                    {formikBag.errors.industry}
                                </Help>
                            </Field>
                        </Field>
                    </div>
                </div>
                <header>
                    <h2 className="title is-4">{translate('ClientUpsert.Lable.22')}</h2>
                </header>
                {renderMyDataForm()}
                <header>
                    <div className="columns">
                        <div className="column is-10">
                            <h2 className="title is-4">{translate('ClientUpsert.Lable.25')}</h2>
                        </div>
                        <div className="column is-2 client-domain-header-icon">
                            {checkPermissions([Privilege.ClientDomain], props.permissions) &&
                                <a data-tip={translate('ClientUpsert.Lable.25')} onClick={() => isAddingDomainToggle()}>
                                    <MaterialIcons
                                        type={MaterialIconType.Add}
                                        color={MaterialIconColor.green}
                                        size={MaterialIconSize.medium}
                                    />
                                </a>
                            }
                        </div>
                    </div>

                </header>

                {renderDomainForm()}

                <div className="action-groups">
                    <div className="action-group">
                        <Button id="person_upsert__cancel" buttonType="cancel" onClick={onCancel}>
                            {translate('Globals.Label.Cancel')}
                        </Button>

                        {props.match.params.clientId &&
                            checkPermissions([Privilege.ClientDelete], props.permissions) && (
                                <ButtonWithConfirmDialog
                                    buttonType="archive"
                                    buttonText={translate('RiskActionTab.Button.Archive')}
                                    title={translate('RiskRegisterUpsertPage.ConfirmDialog.ConfirmTitle')}
                                    message={getDeleteModalBody()}
                                    onConfirm={onDelete}
                                />
                            )}
                    </div>

                    <div className="action-group">
                        <Button id="person_upsert_publish" onClick={onSave} disabled={!isValid && !isDirty}>
                            {translate('Globals.Label.Save')}
                        </Button>
                    </div>
                </div>
            </form>
        );
    };

    return (
        <Page title={title} breadcrumbCustomLinks={customLinks} isAdminPage={true}>
            <Loader loading={props.isLoading} className="column">
                <ArticlePanel className="column">
                    <header>
                        <h2 className="title is-4">{translate('RiskRegisterUpsertPage.Tabs.Details')}</h2>
                    </header>
                    <Formik
                        initialValues={props.upsertRequest}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                        render={renderForm}
                        enableReinitialize={true}
                        isInitialValid={props.match.params.clientId ? true : false}
                        validateOnBlur={true}
                        validateOnChange={true}
                    />
                </ArticlePanel >
                <ArticlePanel className="column margin-small">
                    <header>
                        <h2 className="title is-4">{translate('ClientUpsert.Lable.24')}</h2>
                    </header>

                    {renderIAuditorForm()}
                </ArticlePanel>
            </Loader>
        </Page>
    );
};
