import { IAppAction } from '../app-action';
import { IMenu } from 'models/menu-model';

export enum IMenuActions {
    LOAD_MENU = '@@menu/LOAD_MENU',
    LOAD_MENU_FULFILLED = '@@menu/LOAD_MENU_FULFILLED',
    LOAD_MENU_REJECTED = '@@menu/LOAD_MENU_REJECTED',
    SELECTED_MENU = '@@menu/SELECTED_MENU',
    SELECTED_MENU_FULFILLED = '@@menu/SELECTED_MENU_FULFILLED',
    SELECTED_MENU_REJECTED = '@@menu/SELECTED_MENU_REJECTED',
}

export const loadMenu = (operatingPlatform: string): IAppAction => ({
    type: IMenuActions.LOAD_MENU,
    payload: { operatingPlatform },
});


export const loadMenuFulfilled = (menus: IMenu[]): IAppAction => ({
    type: IMenuActions.LOAD_MENU_FULFILLED,
    payload: menus,
});

export const loadMenuRejected = (error: any): IAppAction => ({
    type: IMenuActions.LOAD_MENU_REJECTED,
    payload: error,
});

export const selectedMenu = (selectedMenuName: string): IAppAction => ({
    type: IMenuActions.SELECTED_MENU,
    payload: { selectedMenuName },
});
