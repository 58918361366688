import * as React from 'react';
import * as H from 'history';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

interface IProps {
    title: string | JSX.Element;
    tab: string;
    currentTab: string;
    to?: H.Pathname;
    isVisible?: boolean;
    isDisabled?: boolean;
    onClick?: (event: any, tab: string) => void;
}

export const TabItem: React.FC<IProps> = ({
    title,
    tab,
    currentTab,
    to,
    isDisabled = false,
    isVisible = true,
    onClick
}) => {
    const tabClassName = classNames(currentTab === tab && 'is-active', isDisabled && 'is-disabled');

    const getLink = () => {
        if (to) {
            return (
                <NavLink
                    id={`${tab.toLowerCase()}-tab-link`}
                    to={to}
                    onClick={(event) => (onClick ? onClick(event, tab) : null)}
                >
                    {title}
                </NavLink>
            );
        }

        return (
            <a id={`${tab.toLowerCase()}-tab-link`} onClick={(event) => onClick(event, tab)}>
                {title}
            </a>
        );
    };

    return isVisible ? <li className={tabClassName}>{getLink()}</li> : null;
};
