import _ from 'lodash';
import * as React from 'react';
import { IPersonAssignedSite, ISiteProfile } from 'models/site-profile';
import './site-selector.scss';
import { Panel } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';

interface IProps {
    selectedSites: ISiteProfile[] | IPersonAssignedSite[];
}
export const AssignedSites: React.FC<IProps> = ({ selectedSites }) => {
    const translate = useTranslate();
    return (
        <div className="columns">
            <div className="column is-12">
                <Panel title={translate('SideNavBar.Labels.AssignedSites')} bodyPadding={true} collapsed={true}>
                    <div className="content">
                        <div className="columns">
                            <div className="column is-3">{translate('SideNavBar.Labels.Site')}</div>
                            <div className="column is-2">{translate('Globals.Category.dropdown.Client')}</div>
                            <div className="column is-1">{translate('Globals.Category.dropdown.OP')}</div>
                            <div className="column is-1">{translate('Cerm.GlobalOverviewFilters.Labels.LineOfBusiness')}</div>
                            <div className="column is-2">{translate('Cerm.GlobalOverviewFilters.Labels.Division')}</div>
                            <div className="column is-2">{translate('Globals.Label.BusinessUnit')}</div>
                            <div className="column is-1">{translate('LinkedBuildings.Country')}</div>
                        </div>
                        <div className="sites-container-rows">
                            {selectedSites.map((site) => {
                                return loadSiteFeaturesRow(site);
                            })}
                        </div>
                    </div>
                </Panel>
            </div>
        </div>
    );
};

const loadSiteFeaturesRow = (site: ISiteProfile) => {
    return (
        <div className="columns">
            <div className="column is-3">{site.name}</div>
            <div className="column is-2">{site.clientName}</div>
            <div className="column is-1">{site.operatingPlatform}</div>
            <div className="column is-1">{site.lineOfBusiness}</div>
            <div className="column is-2">{site.division}</div>
            <div className="column is-2">{site.businessUnit}</div>
            <div className="column is-1">{site.country}</div>
        </div>
    );
};
