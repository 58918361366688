import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { SupportTeamActions } from '../../actions/actions-v2/opportunity-support-team-actions-v2';
import { ISupportTeamModel } from 'models/opportunity-support-team/support-team-model';

const supportTeamControllerName = 'OpportunityQuestionnaire';

const getsupportTeam = {
    action: SupportTeamActions.LOAD_TEAM,
    actionFulfilled: SupportTeamActions.LOAD_TEAM_FULFILLED,
    actionRejected: SupportTeamActions.LOAD_TEAM_REJECTED,
} as IActionType;

const loadSiteRolesEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getsupportTeam).get<ISupportTeamModel>(
        `/api/${supportTeamControllerName}/GetSupportTeam`
    );

export const supportTeamEpicsV2 = combineEpics(loadSiteRolesEpic);
