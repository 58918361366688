import * as React from 'react';
import classNames from 'classnames';
import { IconToolbarItemWithConfirmDialog, IconToolbarItem } from '../components';

interface IProps {
    items: IToolbarIconItem[];
    className?: string;
    isLoading?: boolean;
}

export const IconToolbar: React.FC<IProps> = ({ items, className }) => {
    const toolbarIcons = items
        ? items.map((item) => {
            if (!item) {
                return null;
            }

            return item.isConfirmRequired ? (
                <IconToolbarItemWithConfirmDialog key={item.id} item={item} />
            ) : (
                <IconToolbarItem key={item.id} item={item} className={className} />
            );
        })
        : null;

    return (
        <>
            <ul className={classNames('icon-toolbar', className)}>{toolbarIcons}</ul>
            <div className="is-clearfix" />
        </>
    );
};

export enum ToolbarIconType {
    add = 'add',
    edit = 'edit',
    info = 'info',
    download = 'download',
    filter = 'filter',
    clearFilter = 'clear-filter',
    expertReview = 'expert-review',
    excel = 'excel',
    help = 'help',
    filterApplied = 'filter-applied',
}

export interface IToolbarIconItem {
    id: string;
    type: ToolbarIconType;
    title: string;
    isActive?: boolean;
    isDisabled?: boolean;
    isConfirmRequired?: boolean;
    confirmationMessage?: string;
    children?: IToolbarIconItem[];
    onClick?: () => void;
}
