import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { getIsClient } from 'sharedSelectors';

const getSite = (state: IRootState) => state.siteState.site;

export default createSelector(getSite, getIsClient, (site, isClient) => ({
    isTestSite: site.isTestSite,
    isClient,
    siteOperationPlatform: site.operatingPlatform,
}));
