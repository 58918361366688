import { Formik, FormikProps } from 'formik';
import moment from 'moment';
import * as React from 'react';
import NumberFormat from 'react-number-format';
import { RouteComponentProps, withRouter } from 'react-router';
import { date, object, ObjectSchema, string } from 'yup';
import { BulmaColor } from 'enums/BulmaColor';
import { BulmaSize } from 'enums/BulmaSize';
import { enumEnergyBillPeriods } from 'models/building';
import { IEnergyBill } from 'models/energy-bill';
import { IUpsertSitePageRouteParams } from 'routes/upsert-site/views/upsert-site-page';
import { formikFieldCss } from 'utils/form-utils';
import { DatePicker } from '../datepicker';
import { Control, Field, Help } from '../form';
import { ArticlePanel } from '../panel';
import _ from 'lodash';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
// tslint:disable-next-line: no-submodule-imports
import { enGB } from 'date-fns/locale';
import { Button } from '../v2/components';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';

registerLocale('en-gb', enGB);

export interface IState {
    chosenValidationSchema: ObjectSchema<Partial<IEnergyBill>>;
}

export interface IProps extends RouteComponentProps<IUpsertSitePageRouteParams> {
    energyBillType: enumEnergyBillPeriods;
    energyBills?: IEnergyBill[];
    energyBill?: IEnergyBill;
    intl: any;
}

export interface IActionProps {
    onSubmit: (request: IEnergyBill) => void;
}

export interface IMergeProps extends IProps, IActionProps { }

class EnergyBillForm extends React.PureComponent<IMergeProps, IState> {
    private energyBillSchema: ObjectSchema<Partial<IEnergyBill>> = object<Partial<IEnergyBill>>().shape({
        date: date().required(translate(this.props.intl, 'EnergyBillForm.Message')).nullable(false),
        electricityAmount: string().required(translate(this.props.intl, 'EnergyBillForm.Message2')).nullable(false),
        itUsage: string().when('electricityAmount', (amount: string) => {
            if (amount !== undefined) {
                return string().test(
                    'is-lower', translate(this.props.intl, 'EnergyBillForm.Message3'),
                    (value) => Number(value.replace(/\,/g, '')) < Number(amount.replace(/\,/g, '')));
            }
        })
    });

    constructor(props: IMergeProps) {
        super(props);
        this.state = { chosenValidationSchema: this.energyBillSchema };
    }

    public render() {
        const intl = this.props.intl;

        const renderForm = (formikBag: FormikProps<IEnergyBill>) => {
            const handleDateChange = (newDate: Date) => {
                const dayOnly = moment(newDate).clone().startOf('day');
                const dayOfWeek = moment(newDate).weekday();
                if (this.props.energyBillType === enumEnergyBillPeriods.week) {
                    if (dayOfWeek === 1) {
                        formikBag.setFieldValue('date', dayOnly.toDate());
                    } else {
                        const dateAdjustedToMonday = moment(dayOnly).subtract((dayOfWeek - 1), 'days');
                        formikBag.setFieldValue('date', dateAdjustedToMonday.toDate());
                    }
                } else {
                    formikBag.setFieldValue('date', newDate);
                }
            };

            return (
                <ArticlePanel className="column">
                    <header>
                        <h2 className="title is-4">{translate(this.props.intl, 'EnergyBillForm.Message4')}</h2>
                        <small>
                            {translate(intl, 'EnergyBillForm.Message5')}
                        </small>
                    </header>

                    <form id="energyBillForm" onSubmit={formikBag.handleSubmit}>
                        <Field
                            isHorizontal={true}
                            htmlFor="DateFrom"
                            label={translate(this.props.intl, 'SchedulerWeek.Button.Calendar.buttonText')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    {this.props.energyBillType === enumEnergyBillPeriods.month && (
                                        <DatePicker
                                            id="dateFrom"
                                            name="dateFrom"
                                            selected={formikBag.values.date}
                                            onChange={handleDateChange}
                                            showMonthYearPicker={true}
                                            dateFormat="dd MMM yyyy"
                                            locale="en-gb"
                                        />
                                    )}
                                    {this.props.energyBillType === enumEnergyBillPeriods.week && (
                                        <ReactDatePicker
                                            id="dateFrom2"
                                            name="dateFrom2"
                                            selected={formikBag.values.date}
                                            onChange={handleDateChange}
                                            showWeekNumbers={true}
                                            dateFormat="'Week' ww yyyy, dd MMM"
                                            highlightDates={[
                                                moment(formikBag.values.date)
                                                    .add(1, 'days')
                                                    .toDate(),
                                                moment(formikBag.values.date)
                                                    .add(2, 'days')
                                                    .toDate(),
                                                moment(formikBag.values.date)
                                                    .add(3, 'days')
                                                    .toDate(),
                                                moment(formikBag.values.date)
                                                    .add(4, 'days')
                                                    .toDate(),
                                                moment(formikBag.values.date)
                                                    .add(5, 'days')
                                                    .toDate(),
                                                moment(formikBag.values.date)
                                                    .add(6, 'days')
                                                    .toDate()
                                            ]}
                                            locale="en-gb"
                                        />
                                    )}
                                </Control>
                                <Help isShown={true} bulmaColor={BulmaColor.Danger}>
                                    <>{formikBag.errors.date}</>
                                </Help>
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor="electricityAmount"
                            label={translate(this.props.intl, 'EnergyBillForm.Message6')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <NumberFormat
                                        id=""
                                        name="electricityAmount"
                                        aria-required="true"
                                        placeholder={translate(this.props.intl, 'EnergyBillForm.Message7')}
                                        className={
                                            'input ' +
                                            formikFieldCss(formikBag, 'electricityAmount')
                                        }
                                        value={formikBag.values.electricityAmount}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                        thousandSeparator={true}
                                        onFocus={this.handleMouseDown}
                                    />
                                </Control>
                                <Help
                                    isShown={formikBag.touched.electricityAmount}
                                    bulmaColor={BulmaColor.Danger}
                                >
                                    {formikBag.errors.electricityAmount}
                                </Help>
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor="itUsage"
                            label={translate(this.props.intl, 'EnergyBillForm.Message8')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <NumberFormat
                                        id="itUsage"
                                        name="itUsage"
                                        aria-required="true"
                                        placeholder={translate(this.props.intl, 'EnergyBillForm.Message9')}
                                        className={'input ' + formikFieldCss(formikBag, 'itUsage')}
                                        value={formikBag.values.itUsage}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                        thousandSeparator={true}
                                        onFocus={this.handleMouseDown}
                                    />
                                </Control>
                                <Help
                                    isShown={formikBag.touched.itUsage}
                                    bulmaColor={BulmaColor.Danger}
                                >
                                    {formikBag.errors.itUsage}
                                </Help>
                            </Field>
                        </Field>

                        <div
                            className="action-group is-pulled-right"
                            style={{
                                marginTop: 20,
                                marginBottom: 20,
                                paddingTop: 20,
                                paddingBottom: 20
                            }}
                        >
                            <br />
                            <Button
                                id="riskUpsertModal__cancel"
                                buttonType="cancel"
                                type="button"
                                onClick={this.onClose}
                            >
                                {translate(this.props.intl, 'Globals.Label.Cancel')}
                            </Button>
                            <Button
                                id="riskUpsertModal__save-draft"
                                onClick={this.onSaveAs.bind(this, formikBag)}
                            >
                                {translate(this.props.intl, 'Globals.Label.Submit')}
                            </Button>
                            <br />
                        </div>
                    </form>
                </ArticlePanel>
            );
        };
        return (
            <Formik initialValues={this.props.energyBill} validationSchema={this.energyBillSchema} onSubmit={this.onSubmit} render={renderForm} />
        );
    }

    private handleMouseDown(event: any) {
        if (event.target.value === '0') {
            event.target.value = '';
        }
    }

    private onSubmit = (energyBill: IEnergyBill) => {
        this.props.onSubmit(energyBill);
    }

    private checkValidation = (formikBag: FormikProps<IEnergyBill>) => {
        if (this.props.energyBills && this.props.energyBills.length > 0) {
            let isValid = true;
            _.forEach(this.props.energyBills, (energyBill) => {
                if (energyBill && energyBill.id !== formikBag.values.id) {
                    const inputDate = moment(formikBag.values.date).utc();
                    const existingDate = moment(energyBill.date).utc();
                    if (this.props.energyBillType === enumEnergyBillPeriods.week) {
                        if (inputDate.week() === existingDate.week() && inputDate.year() === existingDate.year()) {
                            formikBag.setFieldError('date', `Week beginning ${moment(energyBill.date).format('DD MMM YYYY')} has already been entered`);
                            isValid = false;
                        }
                    } else {
                        if (inputDate.month() === existingDate.month() && inputDate.year() === existingDate.year()) {
                            formikBag.setFieldError('date', `The month ${moment(energyBill.date).format('MMM YYYY')} has already been entered`);
                            isValid = false;
                        }
                    }
                }
            });
            if (!isValid) {
                return false;
            } else {
                formikBag.setFieldError('date', null);
            }
        } else {
            formikBag.setFieldError('date', null);
        }
        return true;
    }

    private onSaveAs = (formikBag: FormikProps<IEnergyBill>) => {
        if (this.checkValidation(formikBag)) {
            this.setState({ chosenValidationSchema: this.energyBillSchema }, formikBag.submitForm);
        }
    }

    private onClose: React.MouseEventHandler<HTMLButtonElement> = () => {
        this.props.history.goBack();
    }
}

export default withRouter(injectIntl(EnergyBillForm));
