import { IGlobalSkillCategory, IGlobalSkillCategoryQuery } from 'models/competency-dna/cdna';
import { IAppAction } from './app-action';
export enum GlobalSkillCategoryActions {
    LOAD_GLOBAL_SKILL_CATEGORIES = '@@globalSkillCategory/LOAD_GLOBAL_SKILL_CATEGORIES',
    LOAD_GLOBAL_SKILL_CATEGORIES_FULFILLED = '@@globalSkillCategory/LOAD_GLOBAL_SKILL_CATEGORIES_FULFILLED',
    LOAD_GLOBAL_SKILL_CATEGORIES_REJECTED = '@@globalSkillCategory/LOAD_GLOBAL_SKILL_CATEGORIES_REJECTED',
    CREATE_GLOBAL_SKILL_CATEGORY = '@@globalSkillCategory/CREATE_GLOBAL_SKILL_CATEGORY',
    CREATE_GLOBAL_SKILL_CATEGORY_FULFILLED = '@@globalSkillCategory/CREATE_GLOBAL_SKILL_CATEGORY_FULFILLED',
    CREATE_GLOBAL_SKILL_CATEGORY_REJECTED = '@@globalSkillCategory/CREATE_GLOBAL_SKILL_CATEGORY_REJECTED',

    UPDATE_GLOBAL_SKILL_CATEGORY = '@@globalSkillCategory/UPDATE_GLOBAL_SKILL_CATEGORY',
    UPDATE_GLOBAL_SKILL_CATEGORY_FULFILLED = '@@globalSkillCategory/UPDATE_GLOBAL_SKILL_CATEGORY_FULFILLED',
    UPDATE_GLOBAL_SKILL_CATEGORY_REJECTED = '@@globalSkillCategory/UPDATE_GLOBAL_SKILL_CATEGORY_REJECTED',

    DELETE_GLOBAL_SKILL_CATEGORY = '@@globalSkillCategory/DELETE_GLOBAL_SKILL_CATEGORY',
    DELETE_GLOBAL_SKILL_CATEGORY_FULFILLED = '@@globalSkillCategory/DELETE_GLOBAL_SKILL_CATEGORY_FULFILLED',
    DELETE_GLOBAL_SKILL_CATEGORY_REJECTED = '@@globalSkillCategory/DELETE_GLOBAL_SKILL_CATEGORY_REJECTED',
}

export const loadGlobalSkillCategories = (query: IGlobalSkillCategoryQuery): IAppAction => ({
    type: GlobalSkillCategoryActions.LOAD_GLOBAL_SKILL_CATEGORIES,
    payload: query,
});

export const createGlobalSkillCategory = (globalSkillCategory: IGlobalSkillCategory): IAppAction => ({
    type: GlobalSkillCategoryActions.CREATE_GLOBAL_SKILL_CATEGORY,
    payload: globalSkillCategory,
});

export const updateGlobalSkillCategory = (globalSkillCategory: IGlobalSkillCategory): IAppAction => ({
    type: GlobalSkillCategoryActions.UPDATE_GLOBAL_SKILL_CATEGORY,
    payload: globalSkillCategory,
});

export const deleteGlobalSkillCategory = (globalSkillCategoryId: string, skillCardId: string): IAppAction => ({
    type: GlobalSkillCategoryActions.DELETE_GLOBAL_SKILL_CATEGORY,
    payload: {
        id: globalSkillCategoryId,
        shard: skillCardId,
    },
});
