import { combineReducers } from 'redux';
import { ManageRoleActions, ManageRoleActionTypes } from 'actions/manage-role-actions';
import { IRole } from './manageRoles-grid';
import { RoleActions } from 'actions/actions-v2/role-actions-v2';
import { IAppAction } from 'actions/app-action';

export interface IRoleFormState {
    showUpsertRoleModal: boolean;
    upsertItem: Partial<IRole>;
}

export const INITIAL_STATE: IRoleFormState = {
    showUpsertRoleModal: false,
    upsertItem: null
};

const showUpsertRoleModal = (
    state: IRoleFormState['showUpsertRoleModal'] = INITIAL_STATE.showUpsertRoleModal,
    action: IAppAction
) => {
    switch (action.type) {
        case RoleActions.CREATE_ROLE_FULFILLED:
        case RoleActions.UPDATE_ROLE_FULFILLED:
        case ManageRoleActions.SHOW_ROLE_MODAL:
            return !state;
        default:
            return state;
    }
};

const upsertItem = (
    state: Partial<IRole> = INITIAL_STATE.upsertItem,
    action: ManageRoleActionTypes) => {
    switch (action.type) {
        case ManageRoleActions.SHOW_ROLE_MODAL:
        if (action.payload.role) {
            return action.payload.role;
        }
        return null;
        default:
            return state;
    }
};

export const reducer = combineReducers<IRoleFormState>({
    showUpsertRoleModal,
    upsertItem
});
