export interface ITreeNode {
  label: string;
  leadingIcon?: string;
  trailingIcon?: string;
  disabled?: boolean;
  nodes?: TreeNode[];
}

export class TreeNode implements ITreeNode {
  id?: string | number;
  label!: string;
  leadingIcon?: string;
  trailingIcon?: string;
  disabled?: boolean;
  nodes?: TreeNode[];
  level?: number;
  isOverflow?: boolean;
  expanded?: boolean;
}

export interface IEmeraldTreeViewInputs {
  nodes: Array<TreeNode>;
  fontSizeInRem?: number;
  expandAllNodes?: boolean;
}

export class EmeraldTreeViewInputs implements IEmeraldTreeViewInputs {
  nodes: Array<TreeNode> = [];
  fontSizeInRem?: number;
  expandAllNodes?: boolean;
  onNodeClick?: (event: any, node: TreeNode, level: number) => void;
  nodeTemplate?: (node: TreeNode, level: number) => any;
}
