export interface IVisibility {
    operatingPlatforms: string[],
    linesOfBusiness: string[],
    regions: string[],
    countries: string[]
}

export enum Visibility {
    OperatingPlatform = 'operatingPlatform',
    LineOfBusiness = 'lineOfBusiness',
    Regions = 'regions',
    Country = 'country',
}

export interface ICountryModel {
    region: string;
    key: string;
    value: string;
    label?: string;
}