import React from 'react';
import { IEngineer, IEngineerFilters, IEngineersFilters, IKeyValue, IObsoleteSkillCategory } from 'models';
import { Loader } from 'components/loader';
import { RouteComponentProps } from 'react-router';
import { EngineerFilters } from 'components/engineer-filters/engineer-filters';
import { EngineerGridToolbarContainer } from 'components/engineer-grid-toolbar/engineer-grid-toolbar-container';
import { Tab } from 'models/engineer-enums';
import EngineerDetails from './tabs/details/details-tab';
import DocumentsTab from './tabs/documents/documents-tab';
import HistoryTab from './tabs/history/history-tab';
import TrainingTab from './tabs/training/training-tab';
import { history } from 'routes/App';
import { NavLink } from 'react-router-dom';
import {
    OverlayPanel,
    Button,
    ButtonWithConfirmDialog,
    ToolbarGroup,
    Toolbar,
    ToolbarGroupRight,
    Alert,
} from 'components/v2/components';
import Page from 'components/v2/page/Page';
import { checkFilterExists } from 'utils/array-utils';
import { IPerson } from 'models/person';
import { checkPermissions } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import useTranslate from 'translations/translation-utils';
import { ISiteSkillCard } from 'models/competency-dna/site-skill-card';

export interface IEngineerProps extends RouteComponentProps<IParams> {
    engineer: IEngineer;
    isLoading: boolean;
    siteSkillCards: ISiteSkillCard[];
    skillCategories: IObsoleteSkillCategory[];
    siteId: string;
    currentPerson: IPerson;
    permissions: Array<IKeyValue<string>>;
    loadEngineer(id: string): void;
    clearEngineer(): void;
    loadUsers: (siteId: string) => void;
    loadEngineers(filters: IEngineersFilters): void;
    onUpdateEngineer: (engineer: IEngineer) => void;
    loadSkillCategories(siteId: string, qualificationCardType: string): void;
    onArchiveEngineer: (id: string) => void;
}

interface IParams {
    engineerId: string;
    tab: string;
    userId: string;
}

export const UpsertEngineerPage: React.FC<IEngineerProps> = ({
    engineer,
    isLoading,
    siteSkillCards,
    skillCategories,
    siteId,
    currentPerson,
    permissions,
    loadEngineer,
    clearEngineer,
    loadUsers,
    loadEngineers,
    onUpdateEngineer,
    loadSkillCategories,
    onArchiveEngineer,
    match
}) => {
    const translate = useTranslate();
    const [showFilters, setShowFilters] = React.useState(false);
    const [filters, setFilters] = React.useState({
        levels: [],
        categories: [],
        statuses: [],
        filterKeywords: [],
    } as IEngineerFilters);

    const engineersPageUrl = match.path.includes('profile') ? '/Dashboard' : '/Engineers';
    const customLinks: Array<IKeyValue<string>> = [
        { key: engineersPageUrl, value: translate('SideNavBar.Labels.DCProfessionals') },
    ];

    const [site, setSite] = React.useState('');
    const isReadOnly = match.path.includes('profile')
        || (
            !checkPermissions([Privilege.CDNAUpdate], permissions) &&
            checkPermissions([Privilege.CDNAView], permissions) &&
            engineer.userId === currentPerson.id
        );

    React.useEffect(() => {
        if (match.params.engineerId) {
            loadEngineer(match.params.engineerId);
        } else {
            loadEngineers({
                sites: [siteId],
                levels: [],
                filterKeywords: [],
            });
            loadUsers(siteId);
            clearEngineer();
        }

        loadSkillCategories(
            siteId,
            match.params.tab);
        loadUsers(siteId);
    }, [
        match.params.engineerId,
        match.params.tab,
        siteId,
        siteSkillCards
    ]);

    React.useEffect(() => {
        if (siteId && !site) {
            setSite(siteId);
        }

        if (siteId && site && siteId !== site) {
            history.push(engineersPageUrl);
        }
    }, [siteId]);

    const setNavLink = (tab: Tab | string) =>
        match.path
            .replace(':engineerId?', match.params.engineerId)
            .replace(':tab', tab);

    const renderTab = (tab: string) => {
        switch (tab.toLowerCase()) {
            case Tab.training:
                return <TrainingTab isReadonly={isReadOnly} />;
            case Tab.documents:
                return <DocumentsTab isReadOnly={isReadOnly} />;
            case Tab.history:
                return <HistoryTab />;
            default:
                return (
                    <EngineerDetails
                        filters={filters}
                        card={siteSkillCards
                            .find(x => x.name.toUpperCase() === match.params.tab.toUpperCase())}
                        isReadOnly={isReadOnly}
                    />
                );
        }
    };

    const isActive = (value: string): string =>
        match.params.tab.toUpperCase() === value.toUpperCase()
            ? 'is-active'
            : '#';

    const onAddFilterKeyword = (keyword: string) => {
        const keywords = [
            keyword,
            ...filters.filterKeywords.slice(0, filters.filterKeywords.length),
        ];
        setFilters({ ...filters, filterKeywords: keywords });
    };

    const onClearFilters = () => {
        setFilters({
            levels: [],
            categories: [],
            statuses: [],
            filterKeywords: [],
        });
    };

    const onRemoveFilterKeyword = (keyword: string) => {
        const index = filters.filterKeywords.indexOf(keyword);
        const keywords = [
            ...filters.filterKeywords.slice(0, index),
            ...filters.filterKeywords.slice(index + 1),
        ];
        setFilters({ ...filters, filterKeywords: keywords });
    };

    const onFilter = (key: Extract<keyof IEngineerFilters, string>, element: string) => {
        const newFilters = { ...filters };
        const index = newFilters[key].indexOf(element);
        if (index !== -1) {
            const items = [...newFilters[key].slice(0, index), ...newFilters[key].slice(index + 1)];
            newFilters[key] = items;
        } else {
            const items = [element, ...newFilters[key].slice(0, newFilters[key].length)];
            newFilters[key] = items;
        }
        setFilters(newFilters);
    };

    const engId = match.params.engineerId;

    const onAddQualificationType = () => {
        history.push(`/Engineers/Engineer/${engineer.id}/${engineer.userId}`);
    };
    const isFilterExists = checkFilterExists(filters);
    return (
        <Page
            title={translate('SideNavBar.Labels.CompetencyDNA')}
            className="engineer-page"
            redirectOnSiteChange={true}
            redirectOnSiteChangeUrl={engineersPageUrl}
            breadcrumbCustomLinks={customLinks}
        >
            <OverlayPanel
                title={translate('RiskRegisterPage.Title.Filters')}
                isVisible={showFilters}
                onClose={() => setShowFilters(false)}
                onOutsideDialogClick={() => setShowFilters(false)}
                onClearFilters={onClearFilters}
                isFilterExists={isFilterExists}
            >
                <EngineerFilters
                    onFilter={onFilter}
                    filters={filters}
                    onAddFilterKeyword={onAddFilterKeyword}
                    onRemoveFilterKeyword={onRemoveFilterKeyword}
                    showStatusFilter={true}
                    skillCategories={skillCategories}
                />
            </OverlayPanel>
            <EngineerGridToolbarContainer
                onShowFilters={() => setShowFilters(true)}
                onClearFilters={onClearFilters}
                items={engineer}
                displayExportButton={engId !== undefined}
                isFilterExists={isFilterExists}
            />
            {isReadOnly && (
                <Alert type="warning">
                    <p>{translate('EngineerPage.Lable.8')}</p>
                </Alert>
            )}

            <div className="columns is-multiline is-mobile">
                <div className="column is-12">
                    {engineer.skills && engineer.userId !== '' && (
                        <div style={{ fontSize: '2em', textAlign: 'center' }}>
                            {engineer.firstName + ' ' + engineer.lastName}
                        </div>
                    )}
                </div>
                <div className="column is-12">
                    <Loader loading={isLoading}>
                        <>
                            {engineer && engineer.skills && engineer.userId !== '' && (
                                <>
                                    <div className="tabs is-boxed is-large">
                                        <ul>
                                            {engineer
                                                .qualificationCardTypes
                                                .map(x => (
                                                    <li key={x} className={isActive(x)}>
                                                        <NavLink
                                                            id="details-tab-link"
                                                            to={setNavLink(x)}
                                                        >
                                                            <span>{x.toUpperCase()}</span>
                                                        </NavLink>
                                                    </li>
                                                ))}
                                            <li className={isActive(Tab.training)}>
                                                <NavLink
                                                    id="training-tab-link"
                                                    to={setNavLink(Tab.training)}
                                                >
                                                    <span>{translate('RiskActionTab.Category.dropdown.Training')}</span>
                                                </NavLink>
                                            </li>
                                            <li className={isActive(Tab.documents)}>
                                                <NavLink
                                                    id="documents-tab-link"
                                                    to={setNavLink(Tab.documents)}
                                                >
                                                    <span>{translate('RiskRegisterUpsertPage.Tabs.Documents')}</span>
                                                </NavLink>
                                            </li>
                                            <li className={isActive(Tab.history)}>
                                                <NavLink
                                                    id="history-tab-link"
                                                    to={setNavLink(Tab.history)}
                                                >
                                                    <span>{translate('RiskRegisterUpsertPage.Tabs.History')}</span>
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="tabs-container">
                                        {renderTab(match.params.tab)}
                                    </div>
                                </>
                            )}

                            {engId !== undefined && !isReadOnly && (
                                <Toolbar type="save">
                                    <ToolbarGroup>
                                        <NavLink to="/engineers" className="button button-cancel">
                                            {translate('Globals.Label.Cancel')}
                                        </NavLink>
                                        <ButtonWithConfirmDialog
                                            buttonType="archive"
                                            buttonText={translate('EngineerPage.Lable.9')}
                                            title={translate('EngineerPage.Lable.10')}
                                            message={translate('EngineerPage.Lable.11')}
                                            onConfirm={() => onArchiveEngineer(engineer.id)}
                                            isLoading={isLoading}
                                        />
                                    </ToolbarGroup>
                                    <ToolbarGroupRight>
                                        <Button buttonType="archive" onClick={onAddQualificationType}>
                                            {translate('EngineerPage.Lable.12')}
                                        </Button>
                                        <Button onClick={() => onUpdateEngineer(engineer)}>{translate('Globals.Label.Save')}</Button>
                                    </ToolbarGroupRight>
                                </Toolbar>
                            )}
                        </>
                    </Loader>
                </div>
            </div>
        </Page>
    );
};
