import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';

const getHistory = (state: IRootState) => state.incidentsState.history;

export default createSelector(
    getHistory,
    (history) => ({
        incidentHistory: history,
    })
);
