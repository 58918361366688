export const RouteUrlProcessStep = (operatingPlatform: string, id?: string) => {
    return id && id !== undefined
        ? `/CermProcessSteps/${operatingPlatform}/${id}/Upsert/ProcessStep/`
        : `/CermProcessSteps/${operatingPlatform}/Upsert/ProcessStep/`;
};

export const RouteUrlRiskTemplate = (operatingPlatform: string, id?: string) => {
    return id && id !== undefined
        ? `/CermProcessSteps/${operatingPlatform}/${id}/Upsert/RiskTemplate/`
        : `/CermProcessSteps/${operatingPlatform}/Upsert/RiskTemplate/`;
};

export const RouteUrlOutcome = (operatingPlatform: string, id?: string) => {
    return id && id !== undefined
        ? `/CermProcessSteps/${operatingPlatform}/${id}/Upsert/Outcomes/`
        : `/CermProcessSteps/${operatingPlatform}/Upsert/Outcomes/`;
};

export const RouteUrlCermLinks = (operatingPlatform: string, id?: string) => {
    return id && id !== undefined
        ? `/CermProcessSteps/${operatingPlatform}/${id}/Upsert/CermLinks/`
        : `/CermProcessSteps/${operatingPlatform}/Upsert/CermLinks/`;
};

export const RouteUrlTechnologyPlatform = (operatingPlatform: string, id?: string) => {
    return id && id !== undefined
        ? `/CermProcessSteps/${operatingPlatform}/${id}/Upsert/TechnologyPlatform/`
        : `/CermProcessSteps/${operatingPlatform}/Upsert/TechnologyPlatform/`;
};
