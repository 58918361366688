import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';

import { Epic, combineEpics } from 'redux-observable';
import { IAction } from 'models/action';
import { CermActions } from 'actions/actions-v2/cerm-assessment-action-v2';

const controllerName = 'cermAssessment';

const getActions = {
    action: CermActions.LOAD_CERM_ASSESSMENT,
    actionFulfilled: CermActions.LOAD_CERM_ASSESSMENT_FULFILLED,
    actionRejected: CermActions.LOAD_CERM_ASSESSMENT_REJECTED
} as IActionType;

const setOutcomeActions = {
    action: CermActions.SET_OUTCOME,
    actionFulfilled: CermActions.SET_OUTCOME_FULFILLED,
    actionRejected: CermActions.SET_OUTCOME_REJECTED
} as IActionType;

const setProcessStepActions = {
    action: CermActions.SET_PROCESS_STEP,
    actionFulfilled: CermActions.SET_PROCESS_STEP_FULFILLED,
    actionRejected: CermActions.SET_PROCESS_STEP_REJECTED
} as IActionType;

const setRiskActions = {
    action: CermActions.SET_RISK_DETAILS,
    actionFulfilled: CermActions.SET_RISK_DETAILS_FULFILLED,
    actionRejected: CermActions.SET_RISK_DETAILS_REJECTED
} as IActionType;

const loadCermAssessmentEpic: Epic<IAppAction, IAppAction> = (action$, state$) => new BaseEpic(action$, state$, getActions).getById<IAction[]>(`/api/${controllerName}`);
const setOutcomeActionEpic: Epic<IAppAction, IAppAction> = (action$, state$) => new BaseEpic(action$, state$, setOutcomeActions).post<IAction[]>(`/api/${controllerName}`);
const setProcessStepEpic: Epic<IAppAction, IAppAction> = (action$, state$) => new BaseEpic(action$, state$, setProcessStepActions).put<IAction[]>(`/api/${controllerName}`);
const setRiskAssessmentEpic: Epic<IAppAction, IAppAction> = (action$, state$) => new BaseEpic(action$, state$, setRiskActions).patch<string>(`/api/${controllerName}`);
export const cermAssessmentEpicsV2 = combineEpics(
    loadCermAssessmentEpic,
    setOutcomeActionEpic,
    setProcessStepEpic,
    setRiskAssessmentEpic
);
