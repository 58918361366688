import { Action } from 'redux';
import { IBuilding } from '../models/building';
import { IRisk } from '../models/risk';
import { IRiskAttachmentCollection } from '../models/risk-attachments';
import { IUpsertRiskRequest } from '../models/upsert-risk-request';
import { IRisksState } from '../reducers/risks';
import { IPropertyChange } from '../routes/risks/views/riskAuditHistory/selector';
import { addMonthsToNow } from 'utils/date-utils';
import { IIncident } from 'models';
import * as RiskStatus from '../models/risk-statuses';

export enum RiskActions {
    LOAD_RISKS = '@@risks/LOAD_RISKS',
    LOAD_RISKS_FULFILLED = '@@risks/LOAD_RISKS_FULFILLED',
    LOAD_RISKS_REJECTED = '@@risks/LOAD_RISKS_REJECTED',
    LOAD_RISKS_CANCELLED = '@@risks/LOAD_RISKS_CANCELLED',

    SHOW_UPSERT_RISK_MODAL = '@@risks/SHOW_UPSERT_RISK_MODAL',
    HIDE_UPSERT_RISK_MODAL = '@@risks/HIDE_UPSERT_RISK_MODAL',
    UPSERT_RISK = '@@risks/UPSERT_RISK',
    UPSERT_RISK_FULFILLED = '@@risks/UPSERT_RISK_FULFILLED',
    UPSERT_RISK_REJECTED = '@@risks/UPSERT_RISK_REJECTED',
    UPSERT_RISK_CANCELLED = '@@risks/UPSERT_RISK_CANCELLED',

    UPSERT_RISK_TO_INCIDENT = '@@risks/UPSERT_RISK_TO_INCIDENT',
    UPSERT_RISK_TO_INCIDENT_FULFILLED = '@@risks/UPSERT_RISK_TO_INCIDENT_FULFILLED',
    UPSERT_RISK_TO_INCIDENT_REJECTED = '@@risks/UPSERT_RISK_TO_INCIDENT_REJECTED',
    UPSERT_RISK_TO_INCIDENT_CANCELLED = '@@risks/UPSERT_RISK_TO_INCIDENT_CANCELLED',

    DELETE_RISK = '@@risks/DELETE_RISK',
    DELETE_RISK_FULFILLED = '@@risks/DELETE_RISK_FULFILLED',
    DELETE_RISK_REJECTED = '@@risks/DELETE_RISK_REJECTED',
    DELETE_RISK_CANCELLED = '@@risks/DELETE_RISK_CANCELLED',
    DELETE_RISK_CONFIRM = '@@risks/DELETE_RISK_CONFIRM',
    DELETE_RISK_CANCEL = '@@risks/DELETE_RISK_CANCEL',

    PUBLISH_RISK = '@@risks/PUBLISH_RISK',
    PUBLISH_RISK_FULFILLED = '@@risks/PUBLISH_RISK_FULFILLED',
    PUBLISH_RISK_REJECTED = '@@risks/PUBLISH_RISK_REJECTED',
    PUBLISH_RISK_CANCELLED = '@@risks/PUBLISH_RISK_CANCELLED',

    SHOW_HISTORY = '@@risks/SHOW_HISTORY',
    HIDE_HISTORY = '@@risks/HIDE_HISTORY',

    LOAD_HISTORY = '@@risks/LOAD_HISTORY',
    LOAD_HISTORY_FULFILLED = '@@risks/LOAD_HISTORY_FULFILLED',
    LOAD_HISTORY_REJECTED = '@@risks/LOAD_HISTORY_REJECTED',
    LOAD_HISTORY_CANCELLED = '@@risks/LOAD_HISTORY_CANCELLED',

    LOAD_MORE_HISTORY = '@@risks/LOAD_MORE_HISTORY',
    LOAD_MORE_HISTORY_FULFILLED = '@@risks/LOAD_MORE_HISTORY_FULFILLED',
    LOAD_MORE_HISTORY_REJECTED = '@@risks/LOAD_MORE_HISTORY_REJECTED',
    LOAD_MORE_HISTORY_CANCELLED = '@@risks/LOAD_MORE_HISTORY_CANCELLED',

    SHOW_ATTACHMENTS = '@@risks/SHOW_ATTACHMENTS',
    HIDE_ATTACHMENTS = '@@risks/HIDE_ATTACHMENTS',

    RELOAD_ATTACHMENTS = '@@risks/RELOAD_ATTACHMENTS',
    LOAD_ATTACHMENTS = '@@risks/LOAD_ATTACHMENTS',
    LOAD_ATTACHMENTS_FULFILLED = '@@risks/LOAD_ATTACHMENTS_FULFILLED',
    LOAD_ATTACHMENTS_REJECTED = '@@risks/LOAD_ATTACHMENTS_REJECTED',
    LOAD_ATTACHMENTS_CANCELLED = '@@risks/LOAD_ATTACHMENTS_CANCELLED',

    SHOW_LINKED_ACTIONS = '@@risks/SHOW_LINKED_ACTIONS',
    HIDE_LINKED_ACTIONS = '@@risks/HIDE_LINKED_ACTIONS',

    SHOW_LINKED_BUILDINGS = '@@risks/SHOW_LINKED_BUILDINGS',
    HIDE_LINKED_BUILDINGS = '@@risks/HIDE_LINKED_BUILDINGS',
    LOAD_LINKED_BUILDINGS = '@@risks/LOAD_LINKED_BUILDINGS',
    LOAD_LINKED_BUILDINGS_FULFILLED = '@@risks/LOAD_LINKED_BUILDINGS_FULFILLED',
    LOAD_LINKED_BUILDINGS_REJECTED = '@@risks/LOAD_LINKED_BUILDINGS_REJECTED',
    LOAD_LINKED_BUILDINGS_CANCELLED = '@@risks/LOAD_LINKED_BUILDINGS_CANCELLED',

    ADD_FILTER_KEYWORD = '@@risks/ADD_FILTER_KEYWORD',
    REMOVE_FILTER_KEYWORD = '@@risks/REMOVE_FILTER_KEYWORD',
    CLEAR_FILTERS = '@@risks/CLEAR_FILTERS',

    TOGGLE_FILTER_EXPANDED = '@@risks/TOGGLE_FILTER_EXPANDED',
    TOGGLE_ITEM_EXPANDED = '@@risks/TOGGLE_ITEM_EXPANDED',
    CHANGE_SORT_ORDER_VALUE = '@@risks/CHANGE_SORT_ORDER_VALUE',
    CHANGE_IS_PUBLISHED_VALUE = '@@risks/CHANGE_IS_PUBLISHED_VALUE',
    CHANGE_FILTER_VALUE = '@@risks/CHANGE_FILTER_VALUE',
    SHOW_ACTIONS = '@@risks/SHOW_ACTIONS',
    HIDE_ACTIONS = '@@risks/HIDE_ACTIONS',

    SHOW_ADD_ATTACHMENT_MODAL = '@@risks/SHOW_ADD_ATTACHMENT_MODAL',
    HIDE_ADD_ATTACHMENT_MODAL = '@@risks/HIDE_ADD_ATTACHMENT_MODAL',
    ADD_ATTACHMENT = '@@risks/ADD_ATTACHMENT',
    ADD_ATTACHMENT_FULFILLED = '@@risks/ADD_ATTACHMENT_FULFILLED',
    ADD_ATTACHMENT_REJECTED = '@@risks/ADD_ATTACHMENT_REJECTED',
    ADD_ATTACHMENT_CANCELLED = '@@risks/ADD_ATTACHMENT_CANCELLED',

    SHOW_FILTER_MODAL = '@@risks/SHOW_FILTER_MODAL',
    HIDE_FILTER_MODAL = '@@risks/HIDE_FILTER_MODAL',

    DOWNLOAD_RISKS = '@@risks/DOWNLOAD_RISKS',
    DOWNLOAD_RISKS_FULFILLED = '@@risks/DOWNLOAD_RISKS_FULFILLED',
    DOWNLOAD_RISKS_CANCELED = '@@risks/DOWNLOAD_RISKS_CANCELED',
    DOWNLOAD_RISKS_REJECTED = '@@risks/DOWNLOAD_RISKS_REJECTED',
}

export const onShowUpsertRiskModal = (siteIdContext: string, userId: string): RiskActionTypes => ({
    type: RiskActions.SHOW_UPSERT_RISK_MODAL,
    payload: {
        item: {
            id: '',
            site: '',
            siteId: siteIdContext ? siteIdContext : '',
            title: '',
            description: '',
            likelihoodId: '',
            impactId: '',
            riskCategoryId: '',
            riskSubCategoryId: '',
            riskStatusId: RiskStatus.OPEN_AND_PENDING_REVIEW,
            riskAssigneeId: userId,
            reviewDate: addMonthsToNow(1).toISOString(),
            eTag: '',
            origin: 'Risk log',
            assigneeId: '',
        },
    },
});

export interface IDownloadRisksAction extends Action {
    type: RiskActions.DOWNLOAD_RISKS;
    payload: {
        siteId: string;
    };
}

export interface IDownloadRisksFulfilledAction extends Action {
    type: RiskActions.DOWNLOAD_RISKS_FULFILLED;
    payload: any;
}

export interface IDownloadRisksCanceledAction extends Action {
    type: RiskActions.DOWNLOAD_RISKS_CANCELED;
}

export interface IDownloadRisksRejectedAction extends Action {
    type: RiskActions.DOWNLOAD_RISKS_REJECTED;
}

export interface ILoadRisksAction extends Action {
    type: RiskActions.LOAD_RISKS;
}

export interface ILoadRisksRejectedAction extends Action {
    type: RiskActions.LOAD_RISKS_REJECTED;
    error: true;
    payload: any;
}

export interface ILoadRisksCancelledAction extends Action {
    type: RiskActions.LOAD_RISKS_CANCELLED;
}

export interface ILoadRisksFulfilledAction extends Action {
    type: RiskActions.LOAD_RISKS_FULFILLED;
    payload: {
        risks: IRisk[];
    };
}

export interface ISetIsPublishedFilterAction extends Action {
    type: RiskActions.CHANGE_IS_PUBLISHED_VALUE;
    payload: {
        isPublished: boolean;
    };
}

export interface ISetSortOrderAction extends Action {
    type: RiskActions.CHANGE_SORT_ORDER_VALUE;
    payload: {
        sortColumn?: string;
        sortAscending?: boolean;
    };
}

export interface ISetFilterValueAction extends Action {
    type: RiskActions.CHANGE_FILTER_VALUE;
    payload: {
        field: string;
        value: string;
    };
}

export interface IClearFilters extends Action {
    type: RiskActions.CLEAR_FILTERS;
    payload: {};
}

export interface IAddFilterKeyword extends Action {
    type: RiskActions.ADD_FILTER_KEYWORD;
    payload: {
        keyword: string;
    };
}

export interface IRemoveFilterKeyword extends Action {
    type: RiskActions.REMOVE_FILTER_KEYWORD;
    payload: {
        keyword: string;
    };
}

export interface IToggleFilterExpanded extends Action {
    type: RiskActions.TOGGLE_FILTER_EXPANDED;
    payload: {
        id: Extract<keyof IRisksState, string>;
    };
}

export interface IToggleItemExpanded extends Action {
    type: RiskActions.TOGGLE_ITEM_EXPANDED;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface IHideActions extends Action {
    type: RiskActions.HIDE_ACTIONS;
    payload: {
        id: string;
    };
}

export interface IShowActions extends Action {
    type: RiskActions.SHOW_ACTIONS;
    payload: {
        id: string;
    };
}

export interface IDeleteRisk extends Action {
    type: RiskActions.DELETE_RISK;
    payload: {
        item: IRisk;
    };
}

export interface IDeleteRiskConfirm extends Action {
    type: RiskActions.DELETE_RISK_CONFIRM;
    payload: {
        item: IRisk;
    };
}

export interface IDeleteRiskRejectedAction extends Action {
    type: RiskActions.DELETE_RISK_REJECTED;
    error: true;
    payload: any;
}

export interface IDeleteRiskCancelledAction extends Action {
    type: RiskActions.DELETE_RISK_CANCELLED;
}

export interface IDeleteRiskFulfilledAction extends Action {
    type: RiskActions.DELETE_RISK_FULFILLED;
    payload: {};
}

export interface IDeleteRiskCancel extends Action {
    type: RiskActions.DELETE_RISK_CANCEL;
    payload: {
        item: IRisk;
    };
}

export interface IShowUpsertRiskModal extends Action {
    type: RiskActions.SHOW_UPSERT_RISK_MODAL;
    payload: {
        item: IUpsertRiskRequest;
    };
}

export interface IHideUpsertRiskModal extends Action {
    type: RiskActions.HIDE_UPSERT_RISK_MODAL;
}

export interface IUpsertRisk extends Action {
    type: RiskActions.UPSERT_RISK;
    payload: {
        request: IUpsertRiskRequest;
    };
}

export interface IUpsertRiskRejectedAction extends Action {
    type: RiskActions.UPSERT_RISK_REJECTED;
    error: true;
    payload: any;
}

export interface IUpsertRiskCancelledAction extends Action {
    type: RiskActions.UPSERT_RISK_CANCELLED;
}

export interface IUpsertRiskFulfilledAction extends Action {
    type: RiskActions.UPSERT_RISK_FULFILLED;
    payload: {};
}

export interface IPublishRisk extends Action {
    type: RiskActions.PUBLISH_RISK;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface IPublishRiskFulfilledAction extends Action {
    type: RiskActions.PUBLISH_RISK_FULFILLED;
    payload: {};
}

export interface IPublishRiskCancelledAction extends Action {
    type: RiskActions.PUBLISH_RISK_CANCELLED;
    payload: {};
}

export interface IPublishRiskRejectedAction extends Action {
    type: RiskActions.PUBLISH_RISK_REJECTED;
    payload: {};
}

export interface IShowHistory extends Action {
    type: RiskActions.SHOW_HISTORY;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface IHideHistory extends Action {
    type: RiskActions.HIDE_HISTORY;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface ILoadAuditHistory extends Action {
    type: RiskActions.LOAD_HISTORY;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface ILoadAuditHistoryCancelled extends Action {
    type: RiskActions.LOAD_HISTORY_CANCELLED;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface ILoadAuditHistoryRejectedAction extends Action {
    type: RiskActions.LOAD_HISTORY_CANCELLED;
    error: true;
    payload: any;
}

export interface ILoadAuditHistoryFulfilledAction extends Action {
    type: RiskActions.LOAD_HISTORY_FULFILLED;
    payload: {
        id: string;
        siteId: string;
        data: IPropertyChange[];
        continuationToken: string;
        pageSize: number;
    };
}

export interface ILoadMoreAuditHistory extends Action {
    type: RiskActions.LOAD_MORE_HISTORY;
    payload: {
        id: string;
        siteId: string;
        continuationToken: string;
        pageSize: number;
    };
}

export interface ILoadMoreAuditHistoryCancelled extends Action {
    type: RiskActions.LOAD_MORE_HISTORY_CANCELLED;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface ILoadMoreAuditHistoryRejectedAction extends Action {
    type: RiskActions.LOAD_MORE_HISTORY_CANCELLED;
    error: true;
    payload: any;
}

export interface ILoadMoreAuditHistoryFulfilledAction extends Action {
    type: RiskActions.LOAD_MORE_HISTORY_FULFILLED;
    payload: {
        id: string;
        siteId: string;
        data: IPropertyChange[];
        continuationToken: string;
    };
}

export interface IShowAttachments extends Action {
    type: RiskActions.SHOW_ATTACHMENTS;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface IHideAttachments extends Action {
    type: RiskActions.HIDE_ATTACHMENTS;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface IReloadAttachmentsAction extends Action {
    type: RiskActions.RELOAD_ATTACHMENTS;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface ILoadAttachmentsAction extends Action {
    type: RiskActions.LOAD_ATTACHMENTS;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface ILoadAttachmentsCancelledAction extends Action {
    type: RiskActions.LOAD_ATTACHMENTS_CANCELLED;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface ILoadAttachmentsRejectedAction extends Action {
    type: RiskActions.LOAD_ATTACHMENTS_CANCELLED;
    error: true;
    payload: any;
}

export interface ILoadAttachmentsFulfilledAction extends Action {
    type: RiskActions.LOAD_ATTACHMENTS_FULFILLED;
    payload: {
        id: string;
        siteId: string;
        data: IRiskAttachmentCollection[];
    };
}

export interface IShowLinkedActionsAction extends Action {
    type: RiskActions.SHOW_LINKED_ACTIONS;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface IHideLinkedActionsAction extends Action {
    type: RiskActions.HIDE_LINKED_ACTIONS;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface IShowLinkedBuildingsAction extends Action {
    type: RiskActions.SHOW_LINKED_BUILDINGS;
    payload: {
        id: string;
        siteId: string;
    };
}
export interface IHideLinkedBuildingsAction extends Action {
    type: RiskActions.HIDE_LINKED_BUILDINGS;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface ILoadLinkedBuildingsAction extends Action {
    type: RiskActions.LOAD_LINKED_BUILDINGS;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface ILoadLinkedBuildingsCancelledAction extends Action {
    type: RiskActions.LOAD_LINKED_BUILDINGS_CANCELLED;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface ILoadLinkedBuildingsRejectedAction extends Action {
    type: RiskActions.LOAD_LINKED_BUILDINGS_REJECTED;
    error: true;
    payload: any;
}

export interface ILoadLinkedBuildingsFulfilledAction extends Action {
    type: RiskActions.LOAD_LINKED_BUILDINGS_FULFILLED;
    payload: {
        id: string;
        siteId: string;
        data: IBuilding[];
    };
}

export interface IShowAddAttachmentModalAction extends Action {
    type: RiskActions.SHOW_ADD_ATTACHMENT_MODAL;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface IHideAddAttachmentModalAction extends Action {
    type: RiskActions.HIDE_ADD_ATTACHMENT_MODAL;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface IAddAttachmentAction extends Action {
    type: RiskActions.ADD_ATTACHMENT;
    payload: {
        id: string;
        siteId: string;
        files: File[];
    };
}

export interface IAddAttachmentCancelledAction extends Action {
    type: RiskActions.ADD_ATTACHMENT_CANCELLED;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface IAddAttachmentFulfilledAction extends Action {
    type: RiskActions.ADD_ATTACHMENT_FULFILLED;
    payload: {
        id: string;
        siteId: string;
    };
}

export interface IAddAttachmentRejectedAction extends Action {
    type: RiskActions.ADD_ATTACHMENT_REJECTED;
    payload: {
        id: string;
        siteId: string;
        files: File[];
    };
}

export interface IShowFilterModalAction extends Action {
    type: RiskActions.SHOW_FILTER_MODAL;
}

export interface IHideFilterModalAction extends Action {
    type: RiskActions.HIDE_FILTER_MODAL;
}

export const onClose = (): RiskActionTypes => ({
    type: RiskActions.HIDE_UPSERT_RISK_MODAL,
});

export const onToggleExpanded = (id: string, siteId: string): RiskActionTypes => ({
    type: RiskActions.TOGGLE_ITEM_EXPANDED,
    payload: {
        id,
        siteId,
    },
});

export const onSubmitRiskOnIncident = (
    upsertRiskRequest: IUpsertRiskRequest,
    incident: Partial<IIncident>
): RiskActionTypes => ({
    type: RiskActions.UPSERT_RISK_TO_INCIDENT,
    payload: {
        incident,
        request: upsertRiskRequest,
    },
});

export interface IUpsertRiskToIncident extends Action {
    type: RiskActions.UPSERT_RISK_TO_INCIDENT;
    payload: {
        incident: Partial<IIncident>;
        request: IUpsertRiskRequest;
    };
}

export interface IUpsertRiskToIncidentRejectedAction extends Action {
    type: RiskActions.UPSERT_RISK_TO_INCIDENT_REJECTED;
    error: true;
    payload: any;
}

export interface IUpsertRiskToIncidentCancelledAction extends Action {
    type: RiskActions.UPSERT_RISK_TO_INCIDENT_CANCELLED;
}

export interface IUpsertRiskToIncidentFulfilledAction extends Action {
    type: RiskActions.UPSERT_RISK_TO_INCIDENT_FULFILLED;
    payload: {
        incident: IIncident;
    };
}

export type RiskActionTypes =
    | IUpsertRiskToIncident
    | IUpsertRiskToIncidentRejectedAction
    | IUpsertRiskToIncidentCancelledAction
    | IUpsertRiskToIncidentFulfilledAction
    | ILoadRisksAction
    | ILoadRisksCancelledAction
    | ILoadRisksFulfilledAction
    | ILoadRisksRejectedAction
    | ISetIsPublishedFilterAction
    | IDeleteRisk
    | IDeleteRiskCancelledAction
    | IDeleteRiskFulfilledAction
    | IDeleteRiskRejectedAction
    | IDeleteRiskCancel
    | IDeleteRiskConfirm
    | IShowUpsertRiskModal
    | IUpsertRisk
    | IUpsertRiskCancelledAction
    | IUpsertRiskFulfilledAction
    | IUpsertRiskRejectedAction
    | IHideUpsertRiskModal
    | IPublishRisk
    | IPublishRiskCancelledAction
    | IPublishRiskFulfilledAction
    | IPublishRiskRejectedAction
    | IShowHistory
    | IHideHistory
    | ILoadAuditHistory
    | ILoadAuditHistoryCancelled
    | ILoadAuditHistoryFulfilledAction
    | ILoadAuditHistoryRejectedAction
    | ILoadMoreAuditHistory
    | ILoadMoreAuditHistoryCancelled
    | ILoadMoreAuditHistoryFulfilledAction
    | ILoadMoreAuditHistoryRejectedAction
    | IShowAttachments
    | IHideAttachments
    | IReloadAttachmentsAction
    | ILoadAttachmentsAction
    | ILoadAttachmentsCancelledAction
    | ILoadAttachmentsFulfilledAction
    | ILoadAttachmentsRejectedAction
    | IShowLinkedActionsAction
    | IHideLinkedActionsAction
    | IShowLinkedBuildingsAction
    | IHideLinkedBuildingsAction
    | ILoadLinkedBuildingsAction
    | ILoadLinkedBuildingsCancelledAction
    | ILoadLinkedBuildingsFulfilledAction
    | ILoadLinkedBuildingsRejectedAction
    | IClearFilters
    | IAddFilterKeyword
    | IRemoveFilterKeyword
    | ISetFilterValueAction
    | ISetSortOrderAction
    | IToggleFilterExpanded
    | IToggleItemExpanded
    | IHideActions
    | IShowActions
    | IShowAddAttachmentModalAction
    | IHideAddAttachmentModalAction
    | IAddAttachmentAction
    | IAddAttachmentFulfilledAction
    | IAddAttachmentRejectedAction
    | IAddAttachmentCancelledAction
    | IShowFilterModalAction
    | IHideFilterModalAction
    | IDownloadRisksAction
    | IDownloadRisksFulfilledAction
    | IDownloadRisksCanceledAction
    | IDownloadRisksRejectedAction;
