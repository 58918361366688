import { FormikProps } from 'formik';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { isNil } from 'lodash';
import { IEventTemplate } from 'models/question-set';
import { Control, Field, Help, Input, Radio } from '../../../../components/form';
import { BulmaColor } from '../../../../enums/BulmaColor';
import { BulmaSize } from '../../../../enums/BulmaSize';
import { ICermOutcome, ICermProcessStep } from '../../../../models/cerm-process-step';
import { formikFieldCssByState, getKeyed } from '../../../../utils/form-utils';
import { dueDateUnitOptions } from '../common';
import { IUpsertCermProcessStepPageRouteParams } from '../upsert-cerm-process-step-page';
import useTranslate from 'translations/translation-utils';
import { IOperatingPlatform } from 'models/operating-platform-model';
import { getTitlePrefixGeneric } from 'models/cerm/cerm-assessment-result';

export interface IProps extends RouteComponentProps<IUpsertCermProcessStepPageRouteParams> {
    formikBag: FormikProps<Partial<ICermProcessStep>>;
    index?: number;
    operatingPlatforms: IOperatingPlatform[];
}

export const EventTemplateForm: React.FC<IProps> = ({
    formikBag,
    match,
    index,
    operatingPlatforms,
}) => {
    const indexParam = Number(match.params && match.params.outcomeIndex);
    const outcomeIndex = indexParam >= 0 ? indexParam : index >= 0 ? index : 0;

    const entityName: Extract<keyof ICermOutcome, string> = 'event';
    const prefix = `expectedOutcomes[${outcomeIndex}].${entityName}`;
    const titlePrefix =
        operatingPlatforms &&
        operatingPlatforms.length > 0 &&
        getTitlePrefixGeneric(match.params.operatingPlatform, operatingPlatforms);
    const translate = useTranslate();

    if (!formikBag.values.expectedOutcomes[outcomeIndex].event) {
        const defaultValues: IEventTemplate = {
            // tslint:disable-next-line:max-line-length
            title: `${titlePrefix} ${formikBag.values.processNumber} ${translate(
                'Cerm.Templatees.Title'
            )} ${formikBag.values.title}`,
            recurrenceUnit: 'day',
            numberOfOccurrences: 0,
        };

        switch (formikBag.values.priority) {
            case '1':
                defaultValues.recurrenceValue = 60;
                break;
            case '3':
                defaultValues.recurrenceValue = 30;
                break;
            case '9':
                defaultValues.recurrenceValue = 14;
                break;
            case '27':
                defaultValues.recurrenceValue = 7;
                break;
        }
        formikBag.setFieldValue(`${prefix}`, defaultValues);
        return <></>;
    }

    const field = (name: Extract<keyof IEventTemplate, string>) => `${prefix}.${name}`;

    function value<T extends Extract<keyof IEventTemplate, string>>(name: T) {
        const val = getKeyed(formikBag, 'values', field(name));
        return !isNil(val) ? val : '';
    }

    const getErrors = (name: Extract<keyof IEventTemplate, string>) =>
        getKeyed(formikBag, 'errors', field(name));
    const isTouched = (name: Extract<keyof IEventTemplate, string>) =>
        getKeyed(formikBag, 'touched', field(name));

    const getFieldCss = (name: Extract<keyof IEventTemplate, string>, otherCss?: string) =>
        formikFieldCssByState(Boolean(getErrors(name)), isTouched(name), otherCss);

    const getHelpByKey = (name: Extract<keyof IEventTemplate, string>) => (
        <Help isShown={formikBag.submitCount > 0 || isTouched(name)} bulmaColor={BulmaColor.Danger}>
            {getErrors(name)}
        </Help>
    );

    return (
        <>
            <header>
                <h2 className="title is-5">
                    {translate('UpsertCermProcessStep.Views.EventTemplateForm.EventTemplate')}
                </h2>
            </header>

            <Field
                isHorizontal={true}
                htmlFor={field('title')}
                label={translate('Cerm.ProcessStepOutcomes.Labels.EventTitle')}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <Input
                            id={field('title')}
                            name={field('title')}
                            aria-required="true"
                            placeholder={translate(
                                'UpsertCermProcessStep.Views.EventTemplateForm.Message10'
                            )}
                            type="text"
                            className={getFieldCss('title')}
                            value={value('title')}
                            onBlur={formikBag.handleBlur}
                            onChange={formikBag.handleChange}
                        />
                    </Control>
                    {getHelpByKey('title')}
                </Field>
            </Field>

            <Field
                isHorizontal={true}
                htmlFor={field('recurrenceValue')}
                label={translate('UpsertCermProcessStep.Views.EventTemplateForm.Message11')}
                labelSize={BulmaSize.Medium}
                className="is-vcentered actionDueDate"
            >
                <p className="is-italic">Occurs Every</p>
                <Field>
                    <Control>
                        <Input
                            id={field('recurrenceValue')}
                            name={field('recurrenceValue')}
                            placeholder="0"
                            type="number"
                            min={0}
                            className={getFieldCss('recurrenceValue')}
                            value={value('recurrenceValue')}
                            onChange={formikBag.handleChange}
                            onBlur={formikBag.handleBlur}
                        />
                    </Control>
                </Field>
                <Field>
                    <Control>
                        <Radio
                            id={field('recurrenceUnit')}
                            name={field('recurrenceUnit')}
                            options={dueDateUnitOptions}
                            className={getFieldCss('recurrenceUnit')}
                            value={value('recurrenceUnit')}
                            onBlur={formikBag.handleBlur}
                            onChange={formikBag.handleChange}
                        />
                    </Control>
                </Field>
            </Field>
        </>
    );
};
