import { ICriticalSystemSparesState } from 'state';
import { IAppAction } from 'actions/app-action';
import { CriticalSystemSpareActions } from 'actions/actions-v2/critical-system-spares-actions';
import { combineReducers } from 'redux';
import { ICriticalSystemSpare } from 'models/critical-system-spares/critical-system-spare';
import { AttachmentActions } from 'actions/actions-v2/attachment-action-v2';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import { s2ab } from 'utils/export-utils';
import saveAs from 'file-saver';

const INITIAL_STATE: ICriticalSystemSparesState = {
    criticalSystemSpares: [],
    criticalSystemSpare: {
        categoryId: '',
        name: '',
        subCategoryId: '',
        quantityMax: 0,
        quantityMin: 0,
        quantityCurrent: 0,
        lastTestedDate: new Date(),
        isActive: true,
        isCritical: true,
    } as ICriticalSystemSpare,
    criticalSystemSparesSummary: [],
    criticalSystemSparesSiteSummaries: [],
    isLoading: false,
    isExporting: false,
    isSaving: false,
    attachments: [],
    isUploading: false
};

const criticalSystemSpares = (
    state: ICriticalSystemSparesState['criticalSystemSpares'] = INITIAL_STATE.criticalSystemSpares,
    action: IAppAction
) => {
    switch (action.type) {
        case CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARES_FULFILLED:
            return action.payload;
        default:
            return state;
    }
};

const criticalSystemSpare = (
    state: ICriticalSystemSparesState['criticalSystemSpare'] = INITIAL_STATE.criticalSystemSpare,
    action: IAppAction
) => {
    switch (action.type) {
        case CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARE_FULFILLED:
            return action.payload;

        case CriticalSystemSpareActions.CREATE_CRITICAL_SYSTEM_SPARE_FULFILLED:
        case CriticalSystemSpareActions.UPDATE_CRITICAL_SYSTEM_SPARE_FULFILLED:
            if (action.payload) {
                return action.payload;
            }

            return state;

        case CriticalSystemSpareActions.DELETE_CRITICAL_SYSTEM_SPARE_FULFILLED:
        case CriticalSystemSpareActions.CLEAR_FORM:
            return INITIAL_STATE.criticalSystemSpare;
        default:
            return state;
    }
};

const criticalSystemSparesSummary = (
    state: ICriticalSystemSparesState['criticalSystemSparesSummary'] = INITIAL_STATE.criticalSystemSparesSummary,
    action: IAppAction
) => {
    switch (action.type) {
        case CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARES_SUMMARY_FULFILLED:
            return action.payload;
        default:
            return state;
    }
};

const criticalSystemSparesSiteSummaries = (
    state: ICriticalSystemSparesState['criticalSystemSparesSiteSummaries'] = INITIAL_STATE.criticalSystemSparesSiteSummaries,
    action: IAppAction
) => {
    switch (action.type) {
        case CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARES_SITE_SUMMARIES:
            return action.payload;
        default:
            return state;
    }
};

const isLoading = (state: ICriticalSystemSparesState['isLoading'] = INITIAL_STATE.isLoading, action: IAppAction) => {
    switch (action.type) {
        case CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARES:
        case CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARE:
        case CriticalSystemSpareActions.CREATE_CRITICAL_SYSTEM_SPARE:
        case CriticalSystemSpareActions.UPDATE_CRITICAL_SYSTEM_SPARE:
        case CriticalSystemSpareActions.DELETE_CRITICAL_SYSTEM_SPARE:
        case CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARES_SUMMARY:
        case CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARES_SITE_SUMMARIES:
            return true;

        case CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARES_FULFILLED:
        case CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARES_REJECTED:
        case CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARE_FULFILLED:
        case CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARE_REJECTED:
        case CriticalSystemSpareActions.CREATE_CRITICAL_SYSTEM_SPARE_FULFILLED:
        case CriticalSystemSpareActions.CREATE_CRITICAL_SYSTEM_SPARE_REJECTED:
        case CriticalSystemSpareActions.UPDATE_CRITICAL_SYSTEM_SPARE_FULFILLED:
        case CriticalSystemSpareActions.UPDATE_CRITICAL_SYSTEM_SPARE_REJECTED:
        case CriticalSystemSpareActions.DELETE_CRITICAL_SYSTEM_SPARE_FULFILLED:
        case CriticalSystemSpareActions.DELETE_CRITICAL_SYSTEM_SPARE_REJECTED:
        case CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARES_SUMMARY_FULFILLED:
        case CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARES_SUMMARY_REJECTED:
        case CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARES_SITE_SUMMARIES_FULFILLED:
        case CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARES_SITE_SUMMARIES_REJECTED:
            return false;

        default:
            return state;
    }
};

const isExporting = (
    state: ICriticalSystemSparesState['isExporting'] = INITIAL_STATE.isExporting,
    action: IAppAction
) => {
    switch (action.type) {
        case CriticalSystemSpareActions.EXPORT_CRITICAL_SYSTEM_SPARE:
            return true;
        case CriticalSystemSpareActions.EXPORT_CRITICAL_SYSTEM_SPARE_FULFILLED:
            const blob = new Blob([s2ab(atob(action.payload.fileContents))], {
                type: 'application/excel'
            });
            saveAs(blob, action.payload.fileDownloadName);
            return false;
        case CriticalSystemSpareActions.EXPORT_CRITICAL_SYSTEM_SPARE_REJECTED:
            return false;
        default:
            return state;
    }
};

const isSaving = (state: ICriticalSystemSparesState['isSaving'] = INITIAL_STATE.isSaving, action: IAppAction) => {
    switch (action.type) {
        case CriticalSystemSpareActions.CREATE_CRITICAL_SYSTEM_SPARE:
        case CriticalSystemSpareActions.UPDATE_CRITICAL_SYSTEM_SPARE:
        case CriticalSystemSpareActions.DELETE_CRITICAL_SYSTEM_SPARE:
            return true;

        case CriticalSystemSpareActions.CREATE_CRITICAL_SYSTEM_SPARE_FULFILLED:
        case CriticalSystemSpareActions.CREATE_CRITICAL_SYSTEM_SPARE_REJECTED:
        case CriticalSystemSpareActions.UPDATE_CRITICAL_SYSTEM_SPARE_FULFILLED:
        case CriticalSystemSpareActions.UPDATE_CRITICAL_SYSTEM_SPARE_REJECTED:
        case CriticalSystemSpareActions.DELETE_CRITICAL_SYSTEM_SPARE_FULFILLED:
        case CriticalSystemSpareActions.DELETE_CRITICAL_SYSTEM_SPARE_REJECTED:
            return false;

        default:
            return state;
    }
};

const attachments = (
    state: ICriticalSystemSparesState['attachments'] = INITIAL_STATE.attachments,
    action: IAppAction
) => {
    switch (action.type) {
        case AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_SPARE:
            if (action.payload) {
                return action.payload;
            }
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_SPARE:
            if (action.payload) {
                return [...state, ...action.payload];
            }
            return state;
        case AttachmentActions.DELETE_ATTACHMENT_FULFILLED:
            if (action.payload) {
                return state.filter((item: IAttachmentModel) => item.fileName !== action.payload);
            }
            return state;
        default:
            return state;
    }
};

const isUploading = (
    state: ICriticalSystemSparesState['isUploading'] = INITIAL_STATE.isUploading,
    action: IAppAction
) => {
    switch (action.type) {
        case AttachmentActions.UPLOAD_ATTACHMENT:
            return true;
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED:
        case AttachmentActions.UPLOAD_ATTACHMENT_REJECTED:
            return false;
        default:
            return state;
    }
};

export const criticalSystemSparesReducer = combineReducers<ICriticalSystemSparesState>({
    criticalSystemSpares,
    criticalSystemSpare,
    criticalSystemSparesSummary,
    criticalSystemSparesSiteSummaries,
    isLoading,
    isExporting,
    isSaving,
    attachments,
    isUploading
});
