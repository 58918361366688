import { Action } from 'redux';
import { IKeyValue } from 'models/key-value';
import { ISiteProfile } from 'models/site-profile';

export enum SiteFeatures {
    PUE = 'PUE',
    Incidents = 'Incidents',
    CDNA = 'CDNA',
    RiskProfiles = 'Risk Profiles',
    CriticalSpares = 'Critical Spares',
    ClientMockDrills = 'Client Mock Drills',
    ClientShiftHandover = 'Client Shift Handover',
    ClientFacingCriticalSpares = 'Client facing critical spares',
    ClientRisks = 'Client Risks',
    MockDrills = 'Mock Drills',
    SiteDocuments = 'Site Documents',
    RomonetHomePage = 'Romonet home page',
    RomonetExecutiveDashboard = 'Romonet Executive dashboard',
    FacilityOperationsSnapshot = 'Romonet Facility operations snapshot',
    ProjectTrackingDashboard = 'Romonet Project tracking dashboard',
    EngineerReport = 'Romonet Engineer report',
    DataReports = 'Romonet Data reports',
    DetailedHallReport = 'Romonet Detailed Hall Report',
    ClientRomonetHomePage = 'Client Romonet home page',
    ClientRomonetExecutiveDashboard = 'Client Romonet Executive dashboard',
    ClientFacilityOperationsSnapshot = 'Client Romonet Facility operations snapshot',
    ClientProjectTrackingDashboard = 'Client Romonet Project tracking dashboard',
    ClientEngineerReport = 'Client Romonet Engineer report',
    ClientDataReports = 'Client Romonet Data reports',
    ClientDetailedHallReport = 'Client Detailed Hall Report',
    OpportunityQuestionnaire = 'Opportunity Questionnaire',
    ClientQuantumAnalytics = 'Client Quantum Analytics',
}

export const getAllSiteFeatures = (): string[] =>
    Object.keys(SiteFeatures).map((key) => SiteFeatures[key]);

export enum SiteFeatureActions {
    LOAD_SITE_FEATURES = '@@site-feature/LOAD_SITE_FEATURES',
    LOAD_SITE_FEATURES_REJECTED = '@@site-feature/LOAD_SITE_FEATURES_REJECTED',
    LOAD_SITE_FEATURES_FULFILLED = '@@site-feature/LOAD_SITE_FEATURES_FULFILLED',
    SAVE_SITE_FEATURES = '@@site-feature/SAVE_SITE_FEATURES',
    SAVE_SITE_FEATURES_FULFILLED = '@@site-feature/SAVE_SITE_FEATURES_FULFILLED',
    SAVE_SITE_FEATURES_REJECTED = '@@site-feature/SAVE_SITE_FEATURES_REJECTED',
    SAVE_SITE_FEATURES_CANCELLED = '@@site-feature/SAVE_SITE_FEATURES_CANCELLED',
}

export interface ILoadSitesFeatures extends Action {
    type: SiteFeatureActions.LOAD_SITE_FEATURES;
}

export const onLoadSitesFeatures = (): ILoadSitesFeatures => ({
    type: SiteFeatureActions.LOAD_SITE_FEATURES,
});

export interface ILoadSitesFeaturesFulfilled extends Action {
    type: SiteFeatureActions.LOAD_SITE_FEATURES_FULFILLED;
    payload: {
        sites: ISiteProfile[];
    };
}

export const onLoadSitesFeaturesFulfilled = (
    sites: ISiteProfile[]
): ILoadSitesFeaturesFulfilled => ({
    type: SiteFeatureActions.LOAD_SITE_FEATURES_FULFILLED,
    payload: {
        sites,
    },
});

export interface ISaveSitesFeatures extends Action {
    type: SiteFeatureActions.SAVE_SITE_FEATURES;
    payload: {
        sites: Array<IKeyValue<string[]>>;
    };
}

export const onSaveSitesFeatures = (sites: Array<IKeyValue<string[]>>): ISaveSitesFeatures => ({
    type: SiteFeatureActions.SAVE_SITE_FEATURES,
    payload: {
        sites,
    },
});

export interface ISaveSitesFeaturesFulfilled extends Action {
    type: SiteFeatureActions.SAVE_SITE_FEATURES_FULFILLED;
}

export const onSaveSitesFeaturesFulfilled = (): ISaveSitesFeaturesFulfilled => ({
    type: SiteFeatureActions.SAVE_SITE_FEATURES_FULFILLED,
});

export type SiteFeatureActionTypes =
    | ISaveSitesFeatures
    | ISaveSitesFeaturesFulfilled
    | ILoadSitesFeatures
    | ILoadSitesFeaturesFulfilled;
