import React from 'react';
import moment from 'moment';
import { Button } from '../components';
import classNames from 'classnames';
import { isToday, formatDateString } from 'utils/date-utils';
import { ButtonWithDatePicker } from '../date-picker/ButtonWithDatePicker';
import { Loader } from 'components/loader';
import useTranslate from 'translations/translation-utils';

export interface ISchedulerItem {
    date: Date;
    content: JSX.Element;
}

interface IProps {
    selectedDate: Date;
    totalDaysToShow?: number;
    items: ISchedulerItem[];
    isLoading?: boolean;
    onDateChange: (date: Date) => void;
}

export const SchedulerWeek: React.FC<IProps> = ({
    selectedDate,
    totalDaysToShow = 4,
    items,
    isLoading,
    onDateChange,
}) => {
const translate = useTranslate();
    const navigateToDate = (dayAction: number) => {
        const newDate =
            dayAction === 0 ? moment(new Date()) : moment(selectedDate).add(dayAction, 'day');
        onDateChange(newDate.toDate());
    };

    const getColumns = () => {
        const dates: moment.Moment[] = [];
        let offset = totalDaysToShow - 2;
        while (offset > -2) {
            const newDate = moment(selectedDate).add(-offset, 'day');
            dates.push(newDate);
            offset--;
        }

        return (
            <div className="columns">
                {dates.map((d, index) => (
                    <div key={index} className={classNames('column', isToday(d) && 'is-today')}>
                        <div key={'a_' + index} className="day-header">
                            <strong>{d.format('D')}</strong> {d.format('dddd')}
                        </div>
                        <div className="day-body">{getDayItems(d)}</div>
                    </div>
                ))}
            </div>
        );
    };

    const getDayItems = (day: moment.Moment) => {
        return items.filter((item) => day.isSame(item.date, 'day')).map((item) => item.content);
    };

    const getSelectedMonthYear = selectedDate && formatDateString(selectedDate, 'MMM YYYY', '');

    return (
        <div className="scheduler scheduler-week">
            <Loader loading={isLoading}>
                <div className="toolbar-date">
                    <div className="buttons has-addons">
                        <Button onClick={() => navigateToDate(-2)} className="previous-day">
                            {translate('SchedulerWeek.Button.PreviousDay.buttonText')}
                        </Button>
                        <Button onClick={() => navigateToDate(0)} className="today">
                            {translate('SchedulerWeek.Button.Today.buttonText')}
                        </Button>
                        <Button onClick={() => navigateToDate(2)} className="next-day">
                            {translate('SchedulerWeek.Button.NextDay.buttonText')}
                        </Button>
                    </div>
                    <div className="selected-date-month">{getSelectedMonthYear}</div>
                    <ButtonWithDatePicker
                        buttonText={translate('SchedulerWeek.Button.Calendar.buttonText')}
                        selected={selectedDate}
                        popperPlacement="bottom-end"
                        onChange={onDateChange}
                    />
                </div>
                {getColumns()}
            </Loader>
        </div>
    );
};
