import { compact } from 'lodash';
import * as React from 'react';

import './Card.scss';

export interface IProps {
    className?: string;
}

const getClassName = (props: IProps) => compact([
    'card-content',
    props.className || null,
]).join(' ');

export const CardContent: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    return (
        <div className={getClassName(props)}>
            {props.children}
        </div>
    );
};
