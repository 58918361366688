import React from 'react';
import { IProgressBarModel } from '../step-progress-bar/StepProgressBar';
import { IStateResult, State } from './State';

export class Context {
    public answers: IStateResult[] = new Array();
    public id: string;
    public siteId: string;
    public tier: Tier;
    public isDisable: boolean = true;
    public handleSubmit: (numb: number, val: string) => void;
    public handleRender: () => void;
    public handleNavigate: (shouldNavigateAway?: boolean) => void;
    private state: State;
    private states: State[] = new Array();

    constructor(state: State) {
        this.transitionTo(state);
    }
    public back(): void {
        const priviousState = this.states[this.states.length - 1];
        if (priviousState !== undefined) {
            this.state = priviousState;
            this.state.setContext(this);
            this.states.pop();
            this.answers.pop();
            this.isDisable = true;
            this.handleRender();
        }
    }
    public clear(): void {
        this.answers = [];
        this.states = [];
    }
    public transitionTo(state: State): void {

        if (this.state) {
            this.states.push(this.state);
            this.answers.push(this.state.result);
        }
        this.state = state;
        this.state.setContext(this);
        if (this.handleRender) {
            this.isDisable = true;
            this.handleRender();
        }
    }

    public footer(handle: any, isBackBtnDisable: boolean = false): JSX.Element {
        return  (
        <div className="action-groups">
            <div className="action-group">
                <button
                    // tslint:disable-next-line: jsx-no-bind
                    onClick={this.back.bind(this)}
                    className="button button is-light is-medium is-uppercase"
                    type="button"
                    disabled={isBackBtnDisable}
                >Back
                </button>
            </div>
            <div className="action-group">
                <button
                    onClick={handle}
                    className="button button is-primary is-medium is-uppercase"
                    type="button"
                    disabled={this.isDisable}
                >Next Question
                </button>
            </div>
        </div>);
    }

    public request(response: boolean) {
        this.state.handle(response);
    }
    public display(): JSX.Element {
        return this.state.display();
    }

    public getProgressBarModel(): IProgressBarModel []  {
      return this.state.getProgressBarModel();
    }
}
export enum Answer {
    Yes = 'Yes',
    No = 'No',
}

export enum Temperature {
    C25 = 'ASHRAE Class A1, your system maintains an IT supply ' +
          'temperature below 27°C / 80.5°F normal, allowable up to 32°C / 90°F',
    C35 = 'ASHRAE Class A2 or above, your system allows IT supply temperature ' +
          'above 32°C / 90°F before engaging any mechanical cooling (if present)',
}

export enum Tier {
    HighSpec,
    LowSpec
}

export enum ModelTypeName {
    'Chilled Water with Cooling TowerI' = 1,
    'Chilled Water with Cooling TowerI ' = 2,
    'Chilled Water with Cooling TowerI  ' = 3,
    'Chilled Water with Cooling TowerI   ' = 4,
    'Chilled Water with Cooling Tower & Free CoolingI' = 5,
    'Chilled Water with Cooling Tower & Free CoolingI ' = 6,
    'Chilled Water with Cooling Tower - Air ContainmentI' = 7,
    'Chilled Water with Cooling Tower - Air ContainmentI ' = 8,
    'Chilled Water with Cooling Tower - Air ContainmentI  ' = 9,
    'Chilled Water with Cooling Tower - Air Containment' = 10,
    'Chilled Water with Cooling Tower & Free Cooling' = 11,
    'Chilled Water with Cooling Tower & Free Cooling ' = 12,
    'Packaged Air Cooled Chiller' = 13,
    'Packaged Air Cooled Chiller ' = 14,
    'Packaged Free Cooling Air Cooled Chiller' = 15,
    'Packaged Free Cooling Air Cooled Chiller ' = 16,
    'Split DX CRAC System' = 17,
    'Split DX CRAC System ' = 18,
    'Split DX CRAC System - Air Containment' = 19,
    'Split DX CRAC System - Air Containment ' = 20,
    'Dual Coil DX with Glycol Loop' = 21,
    'Dual Coil DX with Glycol Loop ' = 22,
    'Dual Coil DX with Glycol Loop - Air Containment' = 23,
    'Dual Coil DX with Glycol Loop - Air Containment ' = 24,
    'Indirect Air Economised 25C IT Supply' = 25,
    'Adiabatic Indirect Air Economised 25C IT Supply' = 26,
    'Indirect Air Economised 35C IT Supply' = 27,
    'Adiabatic Indirect Air Economised 35C IT Supply' = 28,
    'Direct Air Economised 25C IT Supply' = 29,
    'Adiabatic Direct Air Economised 25C IT Supply' = 30,
    'Direct Air Economised 35C IT Supply' = 31,
    'Adiabatic Direct Air Economised 35C IT Supply' = 32,
}

export enum ModelTypeDescription {
    'Chilled Water with Cooling Tower - 9C CHW supply - No Containment - 21C IT Return - Low chiller CoPI' = 1,
    'Chilled Water with Cooling Tower - 9C CHW supply - No Containment - 21C IT Return - Low chiller CoPI ' = 2,
    'Chilled Water with Cooling Tower - 15C CHW supply - No Containment - 27C IT Return - Med chiller CoPI' = 3,
    'Chilled Water with Cooling Tower - 15C CHW supply - No Containment - 27C IT Return - Med chiller CoPI ' = 4,
// tslint:disable-next-line: max-line-length
    'Chilled Water with Cooling Tower & Free Cooling Plate HX - 15C CHW supply - No Containment - 27C IT Return - Med chiller CoPI' = 5,
// tslint:disable-next-line: max-line-length
    'Chilled Water with Cooling Tower & Free Cooling Plate HX - 15C CHW supply - No Containment - 27C IT Return - Med chiller CoPI ' = 6,
    'Chilled Water with Cooling Tower - 15C CHW supply - Variable Speed CRAC With Containment - High chiller CoPI' = 7,
    'Chilled Water with Cooling Tower - 15C CHW supply - Variable Speed CRAC With Containment - High chiller CoPI ' = 8,
    'Chilled Water with Cooling Tower - 21C CHW supply - Variable Speed CRAC With Containment - High chiller CoPI' = 9,
    'Chilled Water with Cooling Tower - 21C CHW supply - Variable Speed CRAC With Containment - High chiller CoP' = 10,
// tslint:disable-next-line: max-line-length
    'Chilled Water with Cooling Tower & Free Cooling Plate HX - 21C CHW supply - Variable Speed CRAC With air containment - High chiller CoP' = 11,
// tslint:disable-next-line: max-line-length
    'Chilled Water with Cooling Tower & Free Cooling Plate HX - 21C CHW supply - Variable Speed CRAC With air containment - High chiller CoP ' = 12,
    'Packaged Air Cooled Chiller - 9C CHW supply - No air containment - 21C IT Return - Low chiller CoP' = 13,
    'Packaged Air Cooled Chiller - 9C CHW supply - No air containment - 21C IT Return - Low chiller CoP ' = 14,
// tslint:disable-next-line: max-line-length
    'Packaged Free Cooling Air Cooled Chiller - 15C CHW supply - Variable Speed CRAC With Containment - 21C IT supply - High chiller CoP' = 15,
// tslint:disable-next-line: max-line-length
    'Packaged Free Cooling Air Cooled Chiller - 15C CHW supply - Variable Speed CRAC With Containment - 21C IT supply - High chiller CoP ' = 16,
    'Split DX Cooled Data Center with Air Cooled Condensers - No air Containment - Low compressor CoP' = 17,
    'Split DX Cooled Data Center with Air Cooled Condensers - No air Containment - Low compressor CoP ' = 18,
    'Split DX Cooled Data Center with Air Cooled Condensers - Med compressor CoP' = 19,
    'Split DX Cooled Data Center with Air Cooled Condensers - Med compressor CoP ' = 20,
    'Dual Coil DX CRAC with Shared Glycol Loop - Low air supply temp - Low DX CoP - No air Containment' = 21,
    'Dual Coil DX CRAC with Shared Glycol Loop - Low air supply temp - Low DX CoP - No air Containment ' = 22,
    'Dual Coil DX CRAC with Shared Glycol Loop - Higher supply air temp - Variable Speed fans - Air containment' = 23,
    'Dual Coil DX CRAC with Shared Glycol Loop - Higher supply air temp - Variable Speed fans - Air containment ' = 24,
    'Indirect Air Economisation with DX backup - Non-Adiabatic Indirect air system - Low IT temp range' = 25,
    'Indirect Air Economisation with DX backup - Adiabatic Indirect air system - Low IT temp range' = 26,
    'Indirect Air Economisation with DX - Non-Adiabatic Indirect air system - High IT temp range' = 27,
    'Indirect Air Economisation with DX - Adiabatic Indirect air system - High IT temp range' = 28,
    'Data Hall with AHUs consisting of Supply and Extract Fans - Data hall controls < 25C and 80%RH Max' = 29,
    'Data Hall with AHUs consisting of Supply and Extract Fans - Data hall controls < 25C and 80%RH Max ' = 30,
    'Data Hall with AHUs consisting of Supply and Extract Fans - Data hall controls < 35C and 55%RH Max' = 31,
    'Data Hall with AHUs consisting of Supply and Extract Fans - Data hall controls < 35C and 55%RH Max ' = 32,
}
