import { IAppAction } from '../../actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { ClientVerificationActions } from '../../actions/actions-v2/client-verification-action-v2';

const controllerName = 'clientVerification';

const clientVerificationActions = {
    action: ClientVerificationActions.SEND_VERIFICATION,
    actionFulfilled: ClientVerificationActions.SEND_VERIFICATION_FULFILLED,
    actionRejected: ClientVerificationActions.SEND_VERIFICATION_REJECTED
} as IActionType;

const codeVerificationActions = {
    action: ClientVerificationActions.VALIDATE_VERIFICATION_CODE,
    actionFulfilled: ClientVerificationActions.VALIDATE_VERIFICATION_CODE_FULFILLED,
    actionRejected: ClientVerificationActions.VALIDATE_VERIFICATION_CODE_REJECTED
} as IActionType;

const sendVerificationEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, clientVerificationActions).post<string>(`/api/${controllerName}`);

const codeVerificationEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, codeVerificationActions, '/').put<any>(`/api/${controllerName}`);


export const clientVerificationEpicsV2 = combineEpics(sendVerificationEpic, codeVerificationEpic);