import { IAppAction } from '../app-action';

export enum MasterProcessStepActions {
    LOAD_MASTER_PROCESS_STEPS = '@@cerm/LOAD_MASTER_PROCESS_STEPS',
    LOAD_MASTER_PROCESS_STEPS_FULFILLED = '@@cerm/LOAD_MASTER_PROCESS_STEPS_FULFILLED',
    LOAD_MASTER_PROCESS_STEPS_REJECTED = '@@cerm/LOAD_MASTER_PROCESS_STEPS_REJECTED',
}

export const loadMasterProcessSteps = (operatingPlatform: string): IAppAction => ({
    type: MasterProcessStepActions.LOAD_MASTER_PROCESS_STEPS,
    payload: { operatingPlatform }
});
