import * as React from 'react';
import Page from 'components/v2/page/Page';
import { IRiskAssessmentFilters } from 'models/risk-assessment-filters';
import { RiskAssessmentFilters } from './risk-assessment-filters';
import { RiskAssessmentToolbar } from '../risk-assessment-toolbar/risk-assessment-toolbar';
import { IRiskAssessmentState, IAssessmentQuestionSet } from 'reducers/risk-assessment';
import { ISetRiskAssessmentAnswers } from 'models/risk-assessments/set-risk-assessment-answers';
import { QuestionSetList } from './risk-assessment/views/question-set-list/view/question-set-list';
import { Loader } from 'components/loader';
import { OverlayPanel, FloatingButton, Toolbar, ToolbarGroupRight } from 'components/v2/components';
import { isEmpty } from 'lodash';
import './risk-assessment/views/risk-assessment.scss';
import { checkFilterExistsWithIgnoreKey } from 'utils/array-utils';
import { IPersonLookup } from 'models/person-lookup';
import { checkIfUserAssignedToSite } from 'helpers/helpers';
import { HelpModuleContainer } from 'routes/help-module/help-module-container';
import { ModuleType } from 'models/help-module';
import { IKeyValue } from 'models';
import { getSiteOperatingPlatform } from 'models/site-profile';
import useTranslate from 'translations/translation-utils';

interface IProps extends IRiskAssessmentState {
    siteId: string;
    isInitialising: boolean;
    lookupSitePeopleRoles: IPersonLookup[];
    lookupSiteRiskQuestionUpdatePeopleRoles: IPersonLookup[];
    loadRiskAssessment: (filters: IRiskAssessmentFilters) => void;
    setRiskAssessmentAnswers: (model: ISetRiskAssessmentAnswers) => void;
    loadPersonsLookup: (siteId: string) => void;
    lookupSite: IKeyValue<string>[];
}

export const RiskQuestionsPage: React.FC<IProps> = ({ siteId, questionSets, ...props }) => {
    const initialFilterState: IRiskAssessmentFilters = {
        filterCategory: [],
        filterUrgency: [],
        filterOther: [],
        siteId: [siteId],
        operatingPlatform: [getSiteOperatingPlatform(siteId, props.lookupSite)]
    };
    const [showFilters, setShowFilters] = React.useState(false);
    const [filters, setFilters] = React.useState(initialFilterState);
    const [curQuestionSets, setCurQuestionSets] = React.useState<IAssessmentQuestionSet[]>([]);
    const [isDirty, setIsDirty] = React.useState(false);
    const [showHelp, setShowHelp] = React.useState(false);
    const translate = useTranslate()
    React.useEffect(() => {
        props.loadPersonsLookup(siteId);

    }, []);
    React.useEffect(() => {
        if (siteId) {
            props.loadRiskAssessment(initialFilterState);
        }
    }, [siteId]);

    React.useEffect(() => {
        setCurQuestionSets(questionSets);
    }, [questionSets]);

    const onClearFilters = () => {
        setFilters(initialFilterState);
    };

    const onClickHelp = () => {
        setShowHelp(!showHelp);
    };
    const onFilter = (key: Extract<keyof IRiskAssessmentFilters, string>, element: string) => {
        const filtersToUpdate = { ...filters };
        const index = filtersToUpdate[key].indexOf(element);
        if (index !== -1) {
            const items = [
                ...filtersToUpdate[key].slice(0, index),
                ...filtersToUpdate[key].slice(index + 1),
            ];
            filtersToUpdate[key] = items;
        } else {
            const items = [element, ...filtersToUpdate[key].slice(0, filtersToUpdate[key].length)];
            filtersToUpdate[key] = items;
        }

        setFilters(filtersToUpdate);
    };

    const onQuestionAnswerChanged = (
        questionId: string,
        questionSetId: string,
        answer: boolean | null
    ) => {
        setIsDirty(true);
        setCurQuestionSets((current) =>
            current.map((qs) => {
                if (qs.masterQuestionSetId === questionSetId) {
                    return {
                        ...qs,
                        questions: qs.questions.map((q) =>
                            q.id === questionId ? { ...q, answer } : q
                        ),
                    };
                }

                return qs;
            })
        );
    };

    const onQuestionSetAnswerChanged = (questionSetId: string, answer: boolean | null) => {
        setIsDirty(true);
        setCurQuestionSets((current) =>
            current.map((qs) => (qs.masterQuestionSetId === questionSetId ? { ...qs, answer } : qs))
        );
    };

    const onSave = () => {
        let newAnswers = curQuestionSets.map((qs) => ({
            questionSetId: qs.masterQuestionSetId,
            answer: qs.answer,
            questions: qs.questions.map((q) => ({ questionId: q.id, answer: q.answer })),
        }));

        newAnswers = newAnswers.filter((qs) => {
            const current = questionSets.find(
                (cqs) => cqs.masterQuestionSetId === qs.questionSetId
            );
            if (current.answer !== qs.answer) {
                return qs;
            }

            for (const q of qs.questions) {
                const found = current.questions.find((cq) => cq.id === q.questionId);
                if (!found || found.answer !== q.answer) {
                    return qs;
                }
            }
        });
        props.setRiskAssessmentAnswers({
            siteId,
            questionSets: newAnswers,
        });
        setIsDirty(false);
    };

    const filteredQuestionSets = curQuestionSets.filter(
        (q) =>
            (isEmpty(filters.filterCategory) ||
                filters.filterCategory.some((c) => c === q.category)) &&
            (isEmpty(filters.filterUrgency) || filters.filterUrgency.some((c) => c === q.urgency))
    );
    const isFilterExists = checkFilterExistsWithIgnoreKey(filters, 'operatingPlatform');

    return (
        <Page title={translate('SideNavBar.Labels.RiskQuestions')} showConfirmNavigateAwayDialog={isDirty}>
            <p className='header-note'>{translate('RiskAssessment.Message.Note')}</p>
            <OverlayPanel
                title={translate('RiskRegisterPage.Title.Filters')}
                isVisible={showFilters}
                onClose={() => setShowFilters(false)}
                onOutsideDialogClick={() => setShowFilters(false)}
                onClearFilters={onClearFilters}
                isFilterExists={isFilterExists}
            >
                <RiskAssessmentFilters onFilter={onFilter} filters={filters} />
            </OverlayPanel>
            <RiskAssessmentToolbar
                onShowFilters={() => setShowFilters(true)}
                onClearFilters={onClearFilters}
                isFilterExists={isFilterExists}
                onClickHelp={onClickHelp}
                isShowHelp={true}
            />
            <HelpModuleContainer
                isShown={showHelp}
                onClose={onClickHelp}
                moduleType={ModuleType.RiskQuestionsOverview}
            />
            <Loader loading={props.isLoading}>
                <QuestionSetList
                    {...props}
                    siteId={siteId}
                    questionSets={filteredQuestionSets}
                    onQuestionAnswerChanged={onQuestionAnswerChanged}
                    onQuestionSetAnswerChanged={onQuestionSetAnswerChanged}
                />
                <Toolbar type="save">
                    <ToolbarGroupRight>
                        <FloatingButton
                            id="btnSave"
                            buttonType="save"
                            float={isDirty}
                            onClick={onSave}
                            disabled={!isDirty || !checkIfUserAssignedToSite(props.lookupSiteRiskQuestionUpdatePeopleRoles)}
                        >{translate('Globals.Label.Save')}</FloatingButton>
                    </ToolbarGroupRight>
                </Toolbar>
            </Loader>
        </Page>
    );
};
