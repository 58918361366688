import { IKeyValue } from '.';

export enum SkillLevel {
    A = 'A',
    B = 'B',
    C = 'C',
}

export enum Tab {
    training = 'training',
    documents = 'documents',
    history = 'history',
}

export const engineerSkillLevels: Array<IKeyValue<string>> = [
    { key: SkillLevel.A, value: SkillLevel.A.toString() },
    { key: SkillLevel.B, value: SkillLevel.B.toString() },
    { key: SkillLevel.C, value: SkillLevel.C.toString() },
];

export enum SkillStatus {
    YES = 'Yes',
    NO = 'No',
    UNANSWERED = 'Unanswered',
}

export const engineerSkillStatuses: Array<IKeyValue<string>> = [
    { key: SkillStatus.YES, value: 'Yes' },
    { key: SkillStatus.NO, value: 'No' },
    { key: SkillStatus.UNANSWERED, value: 'Unanswered' },
];
/**
 * @deprecated
 */
export enum QualificationType {
    ME = 'M&E',
    IT = 'IT',
    REMOVE = 'remove',
}
