import { compact } from 'lodash';
import * as React from 'react';

import { BulmaSize } from '../../enums/BulmaSize';
import './Tabs.scss';

export interface IProps extends React.AllHTMLAttributes<HTMLDivElement> {
    isFullwidth?: boolean;
    bulmaSize?: BulmaSize;
    isToggle?: boolean;
}

export const Tabs: React.FC<IProps> = (props) => {
    const { isFullwidth, bulmaSize, isToggle, ...otherProps } = props;
    const css = compact([
        'tabs',
        isToggle ? 'is-toggle' : null,
        isFullwidth ? 'is-fullwidth' : null,
        bulmaSize ? bulmaSize : null,
        props.className
    ]).join(' ');

    return (
        <div className={css} {...otherProps}>
            <ul>
                {props.children}
            </ul>
        </div>
    );
};
