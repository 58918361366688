import { AnyAction } from 'redux';

import { SiteRelationshipsActions } from '../../actions/site-relationships-actions';
import { IClient } from '../../models/client';
import { ISiteProfile } from '../../models/site-profile';

export interface IChangeClientConfirmationState {
    client: IClient;
    site: ISiteProfile;
}

export const INITIAL_STATE: IChangeClientConfirmationState = {
    client: null,
    site: null
};

export const reducer = (
    state: IChangeClientConfirmationState = INITIAL_STATE,
    action: AnyAction
) => {
    switch (action.type) {
        case SiteRelationshipsActions.CHANGE_CLIENT:
            return {
                ...action.payload
            };

        case SiteRelationshipsActions.CHANGE_CLIENT_CONFIRMED:
        case SiteRelationshipsActions.CHANGE_CLIENT_CANCELLED:
            return INITIAL_STATE;

        default:
            return state;
    }
};
