import queryString from 'query-string';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { ajax, AjaxError } from 'rxjs/ajax';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

import {
    IChangeSiteSearchValueAction,
    ILoadSiteSearchResultsAction,
    SiteRelationshipsActions,
    SiteRelationshipsActionTypes,
} from '../../actions/site-relationships-actions';
import { ISiteProfile } from '../../models/site-profile';
import { getErrorActions$ } from '../epic-helpers';
import { getChangeSearchValueEpic } from './factories';
import { getApiHeaders } from 'epics/shared-header';
import appConfig from 'helpers/config-helper';

const config = appConfig();

const apiUrl = config.REACT_APP_BASE_API;

const changeSiteSearchValueEpic = getChangeSearchValueEpic<
    IChangeSiteSearchValueAction,
    ILoadSiteSearchResultsAction
>(
    SiteRelationshipsActions.CHANGE_SITE_SEARCH_VALUE,
    SiteRelationshipsActions.LOAD_SITE_SEARCH_RESULTS
);

const loadSiteSearchResultsEpic: Epic<any> = (action$) =>
    action$.pipe(
        ofType(SiteRelationshipsActions.LOAD_SITE_SEARCH_RESULTS),
        map<ILoadSiteSearchResultsAction, { url: string; searchString: string }>((action) => ({
            url: `${apiUrl}/api/siteapi?${queryString.stringify({
                filterKeywords: action.payload.searchString.split(' '),
            })}`,
            searchString: action.payload.searchString,
        })),
        switchMap(({ searchString, url }) =>
            ajax.getJSON(url, getApiHeaders()).pipe(
                map<ISiteProfile[], SiteRelationshipsActionTypes>((response) => ({
                    type: SiteRelationshipsActions.LOAD_SITE_SEARCH_RESULTS_FULFILLED,
                    payload: {
                        sites: response,
                        searchString,
                    },
                })),
                takeUntil(
                    action$.pipe(
                        ofType(
                            SiteRelationshipsActions.CHANGE_SITE_SEARCH_VALUE,
                            SiteRelationshipsActions.LOAD_SITE_SEARCH_RESULTS,
                            SiteRelationshipsActions.LOAD_SITE_SEARCH_RESULTS_CANCELLED
                        )
                    )
                ),
                catchError<any, any>((error: AjaxError) =>
                    getErrorActions$('Sites')(
                        SiteRelationshipsActions.LOAD_SITE_SEARCH_RESULTS_REJECTED,
                        error,
                        {
                            errorMessage: 'Unable to load sites',
                        }
                    )
                )
            )
        )
    );

export const siteEpics = combineEpics(
    changeSiteSearchValueEpic,
    // loadPageEpic,
    loadSiteSearchResultsEpic
);
