import * as React from 'react';
import { FormikProps } from 'formik';
import { IIncident, IncidentStatus } from 'models';
import { Field, Control } from 'components/form';
import Select from 'react-select';
import { BulmaSize } from 'enums/BulmaSize';
import { connect } from 'react-redux';
import selectors from './selectors';
import actions from './actions';
import { IRisk } from 'models/risk';
import { IAction } from 'models/action';
import { RiskList } from 'routes/risks/views/riskList';
import { ActionList } from 'routes/actions/views/actionList/action-list';
import { RiskUpsertModal } from './upsert-risk-modal';
import { IUpsertRiskRequest } from 'models/upsert-risk-request';
import { ActionUpsert } from './upsert-action-modal';
import { getActions, getRisks } from 'business-area-functions/incidents';
import { Loader } from 'components/loader';
import { IActionFilters } from 'models/action-filters';
import { IRiskFilters } from 'models/IRiskFilters';
import { Button } from 'components/v2/components';
import { useTranslate } from 'translations/translation-utils';
interface IProps {
    expendedRiskItems: string[];
    expendedActionItems: string[];
    formikBag: FormikProps<Partial<IIncident>>;
    allAvailableRisks: IRisk[];
    allAvailableActions: IAction[];
    isLoading: boolean;
    isSaving: boolean;
    loadRisks: (filters: IRiskFilters) => void;
    loadActions: (filters: IActionFilters) => void;
    onShowUpsertRiskModal: (siteId: string, userId: string) => void;
    onShowUpsertActionModal: (site: string, userId: string) => void;
    onToggleRisk: (id: string, siteId: string) => void;
    onToggleActions: (id: IAction) => void;
    createRiskForIncident: (risk: Partial<IRisk>) => void;
    createActionForIncident: (action: IAction) => void;
}

const IncidentRiskActions: React.FC<IProps> = (props) => {
    const translate = useTranslate();
    const [availableRisks, setAvailableRisks] = React.useState<IRisk[]>([])
    const [availableActions, setAvailableActions] = React.useState<IAction[]>([]);
    const [addedRisks, setAddedRisks] = React.useState<IRisk[]>([])
    const [addedActions, setAddedActions] = React.useState<IAction[]>([])

    React.useEffect(() => {
        const siteId = props.formikBag.values.siteId;
        if (siteId) {
            loadRisksAndActions(siteId);
        }
    }, [])

    React.useEffect(() => {
        const risksAndActions = props.formikBag.values.risksAndActions;

        if (risksAndActions) {
            const siteId = props.formikBag.values.siteId;
            if (siteId) {
                loadRisksAndActions(siteId);
            }
        }
    }, [props?.formikBag?.values?.risksAndActions]);

    React.useEffect(() => {
        setAvailableRisks(props.allAvailableRisks ? props.allAvailableRisks : []);
        setAvailableActions(props.allAvailableActions ? props.allAvailableActions : []);
        setAddedRisks(getRisks(
            props.allAvailableRisks,
            props.formikBag.values.risksAndActions
        ));
        setAddedActions(getActions(
            props.allAvailableActions,
            props.formikBag.values.risksAndActions
        ));

        const siteId = props.formikBag.values.siteId;
        if (props.formikBag.values.siteId !== siteId) {
            loadRisksAndActions(siteId);
        }
    }, [props.formikBag]);

    const addRisksToFormikBag = async (newRisks: IRisk[]) => {
        const risksIds = [] as string[];
        newRisks.map((m) => risksIds.push(m.id));
        if (
            props.formikBag.values.risksAndActions &&
            props.formikBag.values.risksAndActions
        ) {
            props.formikBag.values.risksAndActions.risks.map((v) => risksIds.push(v));
        }
        await props.formikBag.setFieldValue('risksAndActions.risks', risksIds);
    };

    const removeRiskFromFormikBag = (id: string) => {
        if (
            props.formikBag.values.risksAndActions &&
            props.formikBag.values.risksAndActions.risks
        ) {
            const updateRisks = props.formikBag.values.risksAndActions.risks.filter(
                (f) => f !== id
            );
            props.formikBag.setFieldValue('risksAndActions.risks', updateRisks);
        }
    };

    const addActionsToFormikBag = async (newActions: IAction[]) => {
        const actionIds = [] as string[];
        newActions.map((m) => actionIds.push(m.id));
        if (
            props.formikBag.values.risksAndActions &&
            props.formikBag.values.risksAndActions.actions
        ) {
            props.formikBag.values.risksAndActions.actions.map((v) => actionIds.push(v));
        }
        await props.formikBag.setFieldValue('risksAndActions.actions', actionIds);
    };

    const removeActionFromFormikBag = (id: string) => {
        if (
            props.formikBag.values.risksAndActions &&
            props.formikBag.values.risksAndActions.actions
        ) {
            const updateActions = props.formikBag.values.risksAndActions.actions.filter(
                (f) => f !== id
            );
            props.formikBag.setFieldValue('risksAndActions.actions', updateActions);
        }
    };

    const upsertRiskToIncident = (request: IUpsertRiskRequest) => {
        props.createRiskForIncident(request);
    };

    const upsertActionToIncident = (request: IAction) => {
        props.createActionForIncident(request);
    };

    const getAvailableActionsToBeAdded = () => {
        if (
            !props.formikBag.values.risksAndActions ||
            !props.formikBag.values.risksAndActions.actions
        ) {
            return availableActions.map((m) => ({
                label: m.title,
                ...m,
            }));
        }
        return availableActions
            .filter((f) => !props.formikBag.values.risksAndActions.actions.includes(f.id))
            .map((m) => ({
                label: m.title,
                ...m,
            }));
    };

    const getAvailableRisksToBeAdded = () => {
        if (
            !props.formikBag.values.risksAndActions ||
            !props.formikBag.values.risksAndActions.risks
        ) {
            return availableRisks.map((m) => ({
                label: m.title,
                ...m,
            }));
        }
        return availableRisks
            .filter((f) => !props.formikBag.values.risksAndActions.risks.includes(f.id))
            .map((m) => ({
                label: m.title,
                ...m,
            }));
    };

    const loadRisksAndActions = (siteId: string) => {
        props.loadRisks({
            siteId: [siteId],
            riskSpecial: ['IncludeClosed']
        } as IRiskFilters);

        props.loadActions({
            siteId: [siteId],
            filterSpecial: ['IncludeClosed']
        } as IActionFilters);
    };

    return (
        <Loader loading={props.isLoading}>
            <div className="section">
                <div className="column is-12">
                    <div className="title is-4 section-title">
                        {translate('IncidentRiskActions.Label.AssociatedRisks')}
                    </div>

                    <Field
                        isHorizontal={true}
                        htmlFor="risksToBeAdded"
                        label={translate('IncidentRiskActions.Label.AddRisks')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control className="columns">
                                <div className="column is-10">
                                    <Select
                                        id="risksToBeAdded"
                                        options={getAvailableRisksToBeAdded()}
                                        isMulti={true}
                                        onChange={addRisksToFormikBag}
                                        value={null}
                                        isDisabled={
                                            props.formikBag.values.status ===
                                            IncidentStatus.Closed
                                        }
                                    />
                                </div>
                                <div className="column is-2">
                                    <Button
                                        type="button"
                                        onClick={props.onShowUpsertRiskModal.bind(
                                            this,
                                            props.formikBag.values.siteId,
                                            null
                                        )}
                                        disabled={
                                            props.formikBag.values.status ===
                                            IncidentStatus.Closed
                                        }
                                    >
                                        {translate('IncidentRiskActions.Label.NewRisk')}
                                    </Button>
                                    <RiskUpsertModal
                                        onSubmit={upsertRiskToIncident}
                                        isSaving={props.isSaving}
                                    />
                                </div>
                            </Control>
                        </Field>
                    </Field>
                    <RiskList
                        canEditRisk={false}
                        canDeleteRisk={false}
                        items={addedRisks}
                        isLoading={false}
                        sortColumn={'id'}
                        sortAscending={true}
                        secondarySortColumn={'priority'}
                        secondarySortAscending={true}
                        expandedItems={props.expendedRiskItems}
                        deleteConfirmationItem={null}
                        onSort={null}
                        onToggleExpanded={props.onToggleRisk}
                        onOpenEditRiskModal={null}
                        onDeleteRisk={null}
                        remove={removeRiskFromFormikBag}
                    />
                </div>

                <div className="column is-12">
                    <div className="title is-4 section-title">
                        {translate('IncidentRiskActions.Label.AssociatedActions')}
                    </div>

                    <Field
                        isHorizontal={true}
                        htmlFor="actionsToBeAdded"
                        label={translate('IncidentRiskActions.Label.AddActions')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control className="columns">
                                <div className="column is-10">
                                    <Select
                                        id="actionsToBeAdded"
                                        options={getAvailableActionsToBeAdded()}
                                        isMulti={true}
                                        onChange={addActionsToFormikBag}
                                        value={null}
                                        isDisabled={
                                            props.formikBag.values.status ===
                                            IncidentStatus.Closed
                                        }
                                    />
                                </div>
                                <div className="column is-2">
                                    <Button
                                        type="button"
                                        onClick={props.onShowUpsertActionModal.bind(
                                            this,
                                            props.formikBag.values.siteId,
                                            null
                                        )}
                                        disabled={
                                            props.formikBag.values.status ===
                                            IncidentStatus.Closed
                                        }
                                    >
                                        {translate('IncidentRiskActions.Label.NewAction')}
                                    </Button>
                                    <ActionUpsert
                                        onCreate={upsertActionToIncident}
                                        disableClosedAndCompleted={true}
                                        isSaving={props.isSaving}
                                    />
                                </div>
                            </Control>
                        </Field>
                    </Field>
                    <ActionList
                        items={addedActions}
                        isLoading={false}
                        canEditAction={false}
                        sortColumn={'id'}
                        sortAscending={true}
                        expandedItems={props.expendedActionItems}
                        deleteConfirmationItem={null}
                        onSort={null}
                        onToggleExpanded={props.onToggleActions}
                        onOpenEditActionModal={null}
                        remove={removeActionFromFormikBag}
                    />
                </div>
            </div>
        </Loader>
    );
}
export default connect(selectors, actions)(IncidentRiskActions);
