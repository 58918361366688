import { IRootState } from './../../../../routes/store';
import { createSelector } from 'reselect';

const getEngineer = (state: IRootState) => state.engineerState.engineer;
const getIsLoading = (state: IRootState) => state.engineerState.isLoading;

const getTrainingTypes = (state: IRootState) => state.app.engineerTrainingTypes;
const getMechanicalTrainingCategories = (state: IRootState) => state.app.engineerTrainingCategories.filter((f) => f.area === 'Mechanical');
const getCoolingTrainingCategories = (state: IRootState) => state.app.engineerTrainingCategories.filter((f) => f.area === 'Cooling');
const getElectricalTrainingCategories = (state: IRootState) => state.app.engineerTrainingCategories.filter((f) => f.area === 'Electrical');
const getFireTrainingCategories = (state: IRootState) => state.app.engineerTrainingCategories.filter((f) => f.area === 'Fire');
const getStatutoryTrainingCategories = (state: IRootState) => state.app.engineerTrainingCategories.filter((f) => f.area === 'Statutory');
const getProcessTrainingCategories = (state: IRootState) => state.app.engineerTrainingCategories.filter((f) => f.area === 'Processes');
const getPermitTrainingCategories = (state: IRootState) => state.app.engineerTrainingCategories.filter((f) => f.area === 'Permit training');

export default createSelector(
    getEngineer,
    getIsLoading,
    getTrainingTypes,
    getMechanicalTrainingCategories,
    getCoolingTrainingCategories,
    getElectricalTrainingCategories,
    getFireTrainingCategories,
    getStatutoryTrainingCategories,
    getProcessTrainingCategories,
    getPermitTrainingCategories,
    (
        engineer,
        isLoading,
        trainingTypes,
        mechanicalTrainingCategories,
        coolingTrainingCategories,
        electricalTrainingCategories,
        fireTrainingCategories,
        statutoryTrainingCategories,
        processTrainingCategories,
        permitTrainingCategories) => ({
        engineer,
        isLoading,
        trainingTypes,
        mechanicalTrainingCategories,
        coolingTrainingCategories,
        electricalTrainingCategories,
        fireTrainingCategories,
        statutoryTrainingCategories,
        processTrainingCategories,
        permitTrainingCategories
    })
);
