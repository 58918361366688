export const replaceModuleOrRoleNameWithTranslationKey = (moduleName: string) => {
    switch (moduleName.toLowerCase()) {
        case "Site Roles".toLowerCase():
            return "BulkReassign.ModuleName.SiteRoles";
        case "Actions".toLowerCase():
            return "BulkReassign.ModuleName.Actions";
        case "Risks".toLowerCase():
            return "BulkReassign.ModuleName.Risks";
        case "Incidents".toLowerCase():
            return "BulkReassign.ModuleName.Incidents";
        case "Shift & Gaps".toLowerCase():
            return "BulkReassign.ModuleName.ShiftsGaps";
        case "Mock Drills".toLowerCase():
            return "BulkReassign.ModuleName.MockDrills";
        case "Lessons Learned".toLowerCase():
            return "BulkReassign.ModuleName.LessonsLearned";
        case "ToolBox Talks".toLowerCase():
            return "BulkReassign.ModuleName.ToolboxTalks";
        case "Leader".toLowerCase():
            return "BulkReassign.ModuleName.Leader";
        case "Participants".toLowerCase():
            return "BulkReassign.ModuleName.Participants";
        case "L1/L2 Approvers".toLowerCase():
            return "BulkReassign.ModuleName.L1L2Approvers";
        case "Clients".toLowerCase():
            return "BulkReassign.ModuleName.Clients";
        case "Client Proxy".toLowerCase():
            return "BulkReassign.ModuleName.ClientProxy";
        case "Contract Supports".toLowerCase():
            return "BulkReassign.ModuleName.ContractSupports";
        case "Site Leads".toLowerCase():
            return "BulkReassign.ModuleName.SiteLeads";
        case "Cerm Process Owners (100-500)".toLowerCase():
            return "BulkReassign.ModuleName.CermProcessOwners";
        case "Site QuantumChampions".toLowerCase():
            return "BulkReassign.ModuleName.SiteQuantumChampions";
        case "Site ReliabilityChampions".toLowerCase():
            return "BulkReassign.ModuleName.SiteReliabilityChampions";
        default:
            return moduleName;
    }
};
