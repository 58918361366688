import { combineReducers } from 'redux';

import { SiteRelationshipsActions, SiteRelationshipsActionTypes } from '../../actions/site-relationships-actions';
import { ISiteProfile } from '../../models/site-profile';

export interface ISiteSelectorState {
    selectedSite: ISiteProfile;
    searchValue: string;
    sites: ISiteProfile[];
    isLoading: boolean;
}

export const INITIAL_STATE: ISiteSelectorState = {
    selectedSite: null,
    searchValue: '',
    sites: [],
    isLoading: false
};

const selectedSite = (
    state: ISiteSelectorState['selectedSite'] = INITIAL_STATE.selectedSite,
    action: SiteRelationshipsActionTypes
) => {
    switch (action.type) {
        case SiteRelationshipsActions.SELECT_SITE:
            return action.payload.site;

        case SiteRelationshipsActions.CHANGE_CLIENT_FULFILLED:
            return {
                ...state,
                clientName: action.payload.client.name
            };

        case SiteRelationshipsActions.CHANGE_SITE_SEARCH_VALUE:
            return null;

        default:
            return state;
    }
};

const searchValue = (
    state: ISiteSelectorState['searchValue'] = INITIAL_STATE.searchValue,
    action: SiteRelationshipsActionTypes
) => {
    switch (action.type) {
        case SiteRelationshipsActions.CHANGE_SITE_SEARCH_VALUE:
            return action.payload.value;

        case SiteRelationshipsActions.SELECT_SITE:
            return action.payload.site.name;

        default:
            return state;
    }
};

const sites = (
    state: ISiteSelectorState['sites'] = INITIAL_STATE.sites,
    action: SiteRelationshipsActionTypes
) => {
    switch (action.type) {
        case SiteRelationshipsActions.CHANGE_SITE_SEARCH_VALUE:
        case SiteRelationshipsActions.LOAD_SITE_SEARCH_RESULTS:
            return [];

        case SiteRelationshipsActions.LOAD_SITE_SEARCH_RESULTS_FULFILLED:
            return action.payload.sites;

        default:
            return state;
    }
};

const isLoading = (
    state: ISiteSelectorState['isLoading'] = INITIAL_STATE.isLoading,
    action: SiteRelationshipsActionTypes
) => {
    switch (action.type) {
        case SiteRelationshipsActions.LOAD_SITE_SEARCH_RESULTS:
            return true;

        case SiteRelationshipsActions.LOAD_SITE_SEARCH_RESULTS_FULFILLED:
        case SiteRelationshipsActions.LOAD_SITE_SEARCH_RESULTS_CANCELLED:
        case SiteRelationshipsActions.LOAD_SITE_SEARCH_RESULTS_REJECTED:
            return false;

        default:
            return state;
    }
};

export const reducer = combineReducers<ISiteSelectorState>({
    selectedSite,
    searchValue,
    sites,
    isLoading
});
