// import { CermOverviewActions, CermOverviewActionTypes } from 'actions/cerm-overview-actions';

// export default {
//     onPageLoad: (): CermOverviewActionTypes => ({
//         type: CermOverviewActions.LOAD_PAGE
//     })
// };

// After refactor
import { loadCermProcessPage, onSort, } from 'actions/actions-v2/cerm-process-step-action-v2';
import { loadPerson } from 'actions/actions-v2/user-profile-action-v2';
export default {
    onPageLoad: loadCermProcessPage,
    onSort,
    onLoadPerson: loadPerson,
};
