import * as React from 'react';
import { Field, Control, Help } from 'components/form';
import { connect } from 'react-redux';
import { TextField } from 'components/form/fields/text-field';
import Select from 'react-select';
import { useTranslate } from 'translations/translation-utils';
import { BulmaSize } from 'enums/BulmaSize';
import { IKeyValue } from 'models';
import actions from './actions';
import selectors from './selectors';
import { AssetFields, IAssetManufacturer, IAssetModel, IModelDetails } from 'models/asset/asset-model';
import { IAssetQuery } from 'models/asset/asset-query';
import { BulmaColor } from 'enums/BulmaColor';
import { Formik, FormikErrors, FormikProps } from 'formik';
import { object, string } from 'yup';

interface IProps {
    siteId?: string;
    showLabel: boolean;
    manufacturers: IAssetManufacturer[];
    models: IModelDetails[];
    selectedAsset: IAssetModel;
    loadAssetManufacturers: (query: IAssetQuery) => void;
    loadAssetModels: (query: IAssetQuery) => void;
    isManadatory: boolean;
    setValue: (value: IAssetModel) => void;
    isValid: (isValid: boolean) => void;
    isDisabled: boolean;
}

let trigger: NodeJS.Timeout = null;

const Asset: React.FC<IProps> = (props) => {
    const translate = useTranslate();

    let formikBag: FormikProps<Partial<IAssetModel>>;

    const assetFormValidationSchema = object<Partial<IAssetModel>>().shape({
        name: string().trim().required(translate('Validations.Equipment')).nullable(),
        model: string().trim().required(translate('Validations.EquipmentModel')).nullable(),
        manufacturer: string().trim().required(translate('Validations.EquipmentManufacturer')).nullable()
    });

    React.useEffect(() => {
        if (props.manufacturers.length === 0) {
            props.loadAssetManufacturers({ siteId: props.siteId, search: '' });
        }

        if (props.models.length === 0) {
            props.loadAssetModels({ siteId: props.siteId, search: '' });
        }

    }, []);

    const search = (value: any, key: string) => {
        clearTimeout(trigger);

        trigger = setTimeout(() => {
            const query: IAssetQuery = { siteId: props.siteId, search: value };

            if (key === AssetFields.manufacturer) {
                props.loadAssetManufacturers(query);
            }

            if (key === AssetFields.model) {
                props.loadAssetModels(query);
            }
        }, 500);
    };

    const onSubmit = (value: any) => {
        props.setValue(value);
        props.isValid(!formikBag.errors || Object.keys(formikBag.errors).length === 0);
    };

    const onHandleChange = async (e: any) => {
        e.preventDefault();
        const { name, value } = e.target;
        await formikBag.setFieldValue(name, value, true);
        onSubmit({ ...formikBag.values });
    }

    const handleManufacturerChange = async (itemValue: IKeyValue<string>) => {
        if (itemValue && itemValue.value) {
            await formikBag.setFieldValue(AssetFields.manufacturer, itemValue.value);
            onSubmit({ ...formikBag.values });
            search("", AssetFields.manufacturer);
        }
    };

    const handleModelChange = async (itemValue: IKeyValue<string>) => {
        if (itemValue && itemValue.value) {
            await formikBag.setFieldValue(AssetFields.model, itemValue.value);
            onSubmit({ ...formikBag.values });
            search("", AssetFields.model);
        }
    };

    const getManufacturers = (): any => {
        if (props.manufacturers) {
            const manufacturers: Array<IKeyValue<string>> = [
                {
                    key: translate('Globals.Label.Other'),
                    value: translate('Globals.Label.Other'),
                    label: translate('Globals.Label.Other')
                },
                {
                    key: translate('Globals.Label.NotApplicable'),
                    value: translate('Globals.Label.NotApplicable'),
                    label: translate('Globals.Label.NotApplicable')
                }
            ];
            props.manufacturers.map((i) => manufacturers.push({ key: i.manufacturer, value: i.manufacturer, label: i.manufacturer }));
            return manufacturers;
        }
        return [];
    };

    const getModels = (): any => {
        if (props.models) {
            const assetModels: Array<IKeyValue<string>> = [
                {
                    key: translate('Globals.Label.Other'),
                    value: translate('Globals.Label.Other'),
                    label: translate('Globals.Label.Other')
                },
                {
                    key: translate('Globals.Label.NotApplicable'),
                    value: translate('Globals.Label.NotApplicable'),
                    label: translate('Globals.Label.NotApplicable')
                }
            ];
            props.models.map((i) => assetModels.push({ key: i.model, value: i.model, label: i.model }));
            return assetModels;
        }
        return [];
    };

    const getHelper = (formikErrors: FormikErrors<any> | any) => {
        return (
            <Help bulmaColor={BulmaColor.Danger} isShown={(formikErrors && formikErrors.length > 0)}>
                {formikErrors}
            </Help>
        );
    };

    const handleInputChange = (key: string, newValue: string, action: any) => {
        if (action.action === "input-change") {
            search(newValue, key);
        }
    }

    const renderAsset = (formik: FormikProps<Partial<IAssetModel>>) => {

        return <>
            <Field
                isHorizontal={false}
                htmlFor="rootCause"
                label={props.showLabel ? translate('Asset.Label.Title') : ""}
                labelSize={BulmaSize.Medium}
            >
                <Field isHorizontal={false} label=" ">
                    <Field>
                        <Control className="columns is-desktop">
                            <div className="column is-4">
                                <TextField
                                    id="cmmsReference"
                                    label={`${translate('IncidentDetails.Label.CMMSAsset')} #`}
                                    value={formik.values.cmmsReference ?? ""}
                                    handleChange={onHandleChange}
                                    setVal={formik.setFieldValue}
                                    isDisabled={props.isDisabled}
                                />
                            </div>
                            <div className="column is-4">
                                <Field
                                    isHorizontal={false}
                                    htmlFor="equipmentManufacturer"
                                    label={`${translate('IncidentDetails.Label.EquipmentManufacturer')}${props.isManadatory ? '*' : ''}`}
                                    labelSize={BulmaSize.Normal}
                                >
                                    <Field>
                                        <Control>
                                            <Select
                                                id="equipmentManufacturer"
                                                options={getManufacturers()}
                                                onChange={handleManufacturerChange}
                                                className="form-select-box"
                                                value={{
                                                    key: formik.values.manufacturer,
                                                    value: formik.values.manufacturer,
                                                    label: formik.values.manufacturer
                                                }}
                                                isSearchable={true}
                                                onInputChange={(newValue, { action }) => handleInputChange(AssetFields.manufacturer, newValue, { action })}
                                                backspaceRemovesValue={true}
                                                onBlur={() => { formik.submitForm(); }}
                                                isDisabled={props.isDisabled}
                                            />
                                        </Control>
                                        {getHelper((props.isManadatory && formik.errors && formik.errors.manufacturer) ?? "")}
                                    </Field>
                                </Field>
                            </div>
                            <div className="column is-4">
                                <Field
                                    isHorizontal={false}
                                    htmlFor="equipmentModel"
                                    label={`${translate('IncidentDetails.Label.EquipmentModel')} #${props.isManadatory ? '*' : ''}`}
                                    labelSize={BulmaSize.Normal}
                                >
                                    <Field>
                                        <Control>
                                            <Select
                                                id="equipmentModel"
                                                options={getModels()}
                                                onChange={handleModelChange}
                                                className="form-select-box"
                                                value={{
                                                    key: formik.values.model,
                                                    value: formik.values.model,
                                                    label: formik.values.model,
                                                }}
                                                isSearchable={true}
                                                onInputChange={(newValue, { action }) => handleInputChange(AssetFields.model, newValue, { action })}
                                                backspaceRemovesValue={true}
                                                onBlur={() => { formik.submitForm(); }}
                                                isDisabled={props.isDisabled}
                                            />
                                        </Control>
                                        {getHelper((props.isManadatory && formik.errors && formik.errors.model) ?? "")}
                                    </Field>
                                </Field>
                            </div>
                        </Control>
                    </Field>
                </Field>
                <Field isHorizontal={false} label=" ">
                    <Field>
                        <Control className="columns is-desktop">
                            <div className="column is-4">
                                <TextField
                                    id="serial"
                                    label={`${translate('IncidentDetails.Label.EquipmentSerial')} #`}
                                    value={formik.values.serial ?? ""}
                                    handleChange={onHandleChange}
                                    setVal={formik.setFieldValue}
                                    isDisabled={props.isDisabled}
                                />
                            </div>
                            <div className="column is-4">
                                <TextField
                                    id="name"
                                    label={`${translate('IncidentDetails.Label.EquipmentName')}${props.isManadatory ? '*' : ''}`}
                                    value={formik.values.name ?? ""}
                                    handleChange={onHandleChange}
                                    error={(props.isManadatory && formik.errors && formik.errors.name) ?? ''}
                                    setVal={formik.setFieldValue}
                                    isDisabled={props.isDisabled}
                                />
                            </div>
                            <div className="column is-4">
                                <TextField
                                    id="age"
                                    label={translate('IncidentDetails.Label.EquipmentAge')}
                                    value={formik.values.age ?? ""}
                                    handleChange={onHandleChange}
                                    setVal={formik.setFieldValue}
                                    isDisabled={props.isDisabled}
                                />
                            </div>
                        </Control>
                    </Field>
                </Field>
            </Field>
        </>
    }

    return <>
        <Formik<Partial<IAssetModel>>
            initialValues={props.selectedAsset}
            validationSchema={props.isManadatory ? assetFormValidationSchema : null}
            onSubmit={() => { }}
            render={(formik) => {
                formikBag = formik;
                props.isValid(!formikBag.errors || Object.keys(formikBag.errors).length === 0);
                return renderAsset(formik);
            }}
            enableReinitialize={true}
            isInitialValid={true}
            validateOnChange={true}
        />

    </>
};

export default connect(selectors, actions)(Asset);
