import { createSelector } from 'reselect';
import { IBaseState } from 'reducers/base-state';
import { IRootState } from 'routes/store';
import { getPermissions } from 'sharedSelectors';

const getLoadingStatus = createSelector(
    (state: IRootState) => state.dashboardComponentsState.isCermOverviewComponentLoading,
    (state) => state
);

const getSiteId = (state: IBaseState) => state.app.siteId;

const getComponent = createSelector(
    (state: IRootState) => state.dashboardComponentsState.cermOverviewComponent,
    (state) => state
);

export default createSelector(
    getLoadingStatus,
    getSiteId,
    getComponent,
    getPermissions,
    (isLoading, siteId, component, permissions) => ({
        isLoading,
        siteId,
        component,
        permissions
    })
);
