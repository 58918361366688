import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { connect } from 'react-redux';
import EngineerReport from './engineer-report';

import {
    loadAttachmentsEngineerReport,
    uploadAttachmentEngineerReport,
} from 'actions/actions-v2/attachment-action-v2';

const getIsUploading = (state: IRootState) => state.romonetState.isLoading;
const getAttachments = (state: IRootState) => state.romonetState.attachments;
const getSiteid = (state: IRootState) => state.app.siteId;
const getPermissions = (state: IRootState) => state.app.permissions;

const mapStateToProps = createSelector(
    getIsUploading,
    getAttachments,
    getSiteid,
    getPermissions,
    (isUploading, attachments, siteId,permissions) => ({
        isUploading,
        attachments,
        siteId,
        permissions,
    })
);

const mapDispatchToProps = {
    loadAttachmentsEngineerReport,
    uploadAttachmentEngineerReport,
};

export const EngineerReportContainer = connect(mapStateToProps, mapDispatchToProps)(EngineerReport);
