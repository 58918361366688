import { orderBy, property } from 'lodash';
import { createSelector } from 'reselect';

import { ICermOverviewRootState } from '../../../../reducers/cerm-overview';

export default createSelector(
    (state: ICermOverviewRootState) => state.cermOverview,
    (state) => ({
        items: state !== undefined && state.items !== undefined ? orderBy(state.items, property('title')) : [],
        expandedPillars: state !== undefined && state.expandedPillars !== undefined ? state.expandedPillars : [],
        expandedItems: state !== undefined && state.expandedItems !== undefined ? state.expandedItems : [],
        isLoading: state !== undefined && state.isLoading ? state.isLoading : false,
        isDeleting: state !== undefined && state.isDeleting ? state.isDeleting : false,
        // sortColumn:  state !== undefined && state.sortColumn ? state.sortColumn : '',
        // isSortAscending: state !== undefined && state.sortAscending ? state.sortAscending : true
    })
);
