import * as React from 'react';
import { connect } from 'react-redux';
import { IEngineer } from 'models';
import selectors from './selectors';
import actions from './actions';
import { IAttachmentQuery } from 'models/attachment/attachment-query';
import { IAttachmentUpload } from 'models/attachment/attachment-upload';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import Attachments from 'components/attachemnts/attachments';
import { Entity } from 'models/entity';

interface IProps {
    engineer: IEngineer;
    loadAttachments: (query: IAttachmentQuery) => void;
    uploadAttachment: (upload: IAttachmentUpload) => void;
    attachments: IAttachmentModel[];
    isReadOnly: boolean;
}

const DocumentsTab: React.FC<IProps> = (props) => {
    return (
        <div className="tabs-container-table-only">
            <Attachments
                entity={Entity.Engineer}
                attachments={props.attachments}
                loadAttachments={props.loadAttachments}
                uploadAttachment={props.uploadAttachment}
                id={props.engineer.id}
                isReadOnly={props.isReadOnly}
            />
        </div>
    );
};

export default connect(selectors, actions)(DocumentsTab);
