import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { LogsUploadActions } from 'actions/actions-v2/logs-upload-actions';
import { ITemplateValidationSummary } from 'models/logs-upload';

const controllerName = 'OfflineLogs';
const apiPath = `/api/${controllerName}`;
const downloadControllerName = 'Download';
const apiPathDownload = `/api/${downloadControllerName}`;

const logUploadActions = {
    action: LogsUploadActions.UPLOAD,
    actionFulfilled: LogsUploadActions.UPLOAD_FULFILLED,
    actionRejected: LogsUploadActions.UPLOAD_REJECTED,
} as IActionType;

const logUploadEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, logUploadActions).logUpload<ITemplateValidationSummary>(apiPath);

const logTemplateDownloadActions = {
    action: LogsUploadActions.DOWNLOAD_TEMPLATE,
    actionFulfilled: LogsUploadActions.DOWNLOAD_TEMPLATE_FULFILLED,
    actionRejected: LogsUploadActions.DOWNLOAD_TEMPLATE_REJECTED,
} as IActionType;

const logDownloadTemplateEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, logTemplateDownloadActions).exportByQueryModel(`${apiPathDownload}/DownloadTemplate`);

export const uploadLogEpics = combineEpics(logUploadEpic, logDownloadTemplateEpic);
