import * as React from 'react';
import { Route, RouteProps } from 'react-router';
import { checkPermissions, checkSiteHasFeatures } from 'utils/permission-utils';
import { IKeyValue } from 'models/key-value';
import { SiteFeatures } from 'actions/site-feature';
import { Alert } from 'components/v2/components';
import { Privilege } from 'enums/Privilege';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';

interface IProtectedRouteProps extends RouteProps {
    privileges: Privilege[];
    permissions: Array<IKeyValue<string>>;
    siteFeatures?: SiteFeatures[] | null;
    siteId: string;
    isClientView?: boolean;
    isClient: boolean;
    lookupSitesFeatures: Array<IKeyValue<string[]>>;
    intl: any;
}

class ProtectedRouteClass extends React.PureComponent<IProtectedRouteProps> {
    public render() {
        const intl = this.props.intl;
        const { siteId, siteFeatures, lookupSitesFeatures, isClient, isClientView } = {
            ...this.props,
        };

        if (
            siteId &&
            siteFeatures &&
            lookupSitesFeatures &&
            lookupSitesFeatures.length > 0 &&
            !checkSiteHasFeatures(siteId, siteFeatures, lookupSitesFeatures)
        ) {
            return (
                <Alert type="warning" title={translate(intl, 'ProtectedRoute.View.Title')}>
                    <p>{translate(intl, 'Routes.ProtectedRoutes.Message2')}</p>
                </Alert>
            );
        }

        if (isClient && isClientView !== true) {
            return (
                <Alert type="warning" title={translate(intl, 'AccessDenied.Label.AccessDenied')}>
                    <p>{translate(intl, 'Routes.ProtectedRoutes.Message')}</p>
                </Alert>
            );
        }

        const permissions = this.props.permissions;
        if (!permissions || permissions.length < 1) {
            return null;
        }

        if (
            !this.props.privileges.includes(Privilege.NotRequired) &&
            !checkPermissions(this.props.privileges, permissions)
        ) {
            return (
                <Alert type="warning" title={translate(intl, 'AccessDenied.Label.AccessDenied')}>
                    <p>{translate(intl, 'Routes.ProtectedRoutes.Message')}</p>
                </Alert>
            );
        }

        return <Route {...this.props} />;
    }
}

export const ProtectedRoute = injectIntl(ProtectedRouteClass);
