import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { IKeyValue } from '../../models/key-value';
import { CheckboxFilter } from '../checkboxFilter';
import { SearchFilter } from '../searchFilter';
import { ICermFilters } from 'models/filters/cerm-assessment-filters';
import { ICommonLookupModel } from 'models/common-lookup-model';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';

interface IRouteParams {
    operatingPlatform?: string;
}
export interface IStateProps {
    filters: ICermFilters;
    expandedFilters: string[];
    lookupServiceFilters: Array<IKeyValue<string>>;
    lookupPriorityFilters: Array<IKeyValue<string>>;
    lookupPillarFilters: ICommonLookupModel[];
    lookupPhaseFilters: Array<IKeyValue<string>>;
    intl: any;
}

export interface IDispatchProps {
    onFilter: (field: string, value: string) => void;
    onToggleFilterExpanded: (id: string) => void;
    onAddFilterKeyword: (keyword: string) => void;
    onRemoveFilterKeyword: (keyword: string) => void;
}

interface IProps extends IStateProps, IDispatchProps, RouteComponentProps<IRouteParams> { }

class CermAssessmentFiltersClass extends React.PureComponent<IProps> {
    public componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);

        urlParams.forEach((element, key) => {
            this.props.onFilter(key, element);
        });
    }
    getPillars = () => {
        return this.props.lookupPillarFilters.filter(x => x.operatingPlatform === this.props.match.params.operatingPlatform)
            .map(m => { return { key: m.value, value: m.value } });
    }

    public render() {

        const commonProps = {
            expandedFilters: this.props.expandedFilters,
            onFilter: this.props.onFilter,
            onToggleFilterExpanded: this.props.onToggleFilterExpanded,
        };

        return (
            <>
                <SearchFilter
                    title={translate(this.props.intl, 'RiskRegisterPage.Title.Filters')}
                    name="filterKeywords"
                    values={this.props.filters.filterKeywords}
                    onAddFilterKeyword={this.props.onAddFilterKeyword}
                    onRemoveFilterKeyword={this.props.onRemoveFilterKeyword}
                />

                <CheckboxFilter
                    {...commonProps}
                    title={translate(this.props.intl, 'Cerm.AssessmentFilters.Labels.Service')}
                    name="serviceFilters"
                    selectedFilters={this.props.filters.serviceFilters}
                    unreservedFilters={this.props.lookupServiceFilters}
                />

                <CheckboxFilter
                    {...commonProps}
                    title={translate(this.props.intl, 'Cerm.AssessmentFilters.Labels.Priority')}
                    name="priorityFilters"
                    selectedFilters={this.props.filters.priorityFilters}
                    unreservedFilters={this.props.lookupPriorityFilters}
                />

                <CheckboxFilter
                    {...commonProps}
                    title={translate(this.props.intl, 'Cerm.AssessmentFilters.Labels.Pillar')}
                    name="pillarFilters"
                    selectedFilters={this.props.filters.pillarFilters}
                    unreservedFilters={this.getPillars()}
                />

                <CheckboxFilter
                    {...commonProps}
                    title={translate(this.props.intl, 'Cerm.AssessmentFilters.Labels.Phase')}
                    name="phaseFilters"
                    selectedFilters={this.props.filters.phaseFilters}
                    unreservedFilters={this.props.lookupPhaseFilters}
                />
            </>
        );
    }
}

export const CermAssessmentFilters = injectIntl(CermAssessmentFiltersClass);