export enum Entity {
    Action = 'Action',
    Risk = 'Risk',
    RiskMitigationPlan = 'RiskMitigationPlan',
    CriticalSpare = 'CriticalSpare',
    Engineer = 'Engineer',
    AssessmentProcessStep = 'AssessmentProcessStep',
    AssessmentProcessStepOutcome = 'AssessmentProcessStepOutcome',
    Incident = 'Incident',
    MockDrillReport = 'MockDrillReport',
    Site = 'Site',
    SiteImage = 'SiteImage',
    ShiftHandover = 'ShiftHandover',
    EngineerReport = 'EngineerReport',
    GlobalMockDrill = 'GlobalMockDrill',
}
