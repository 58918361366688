import * as React from 'react';
import { isEmpty } from 'lodash';
import { IKeyValue } from 'models/key-value';
import { checkPermission } from 'utils/permission-utils';
import { Spinner } from '../spinner';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import { formatDateToUniversalDateTime } from 'utils/date-utils';
import { Table, TableNoItem, ButtonWithConfirmDialog } from '../v2/components';
import useTranslate from 'translations/translation-utils';
import { Privilege } from 'enums/Privilege';

interface IProps {
    id?: string;
    data: IAttachmentModel[];
    disabled?: boolean;
    isImage?: boolean;
    permissions: Array<IKeyValue<string>>;
    isLoading?: boolean;
    onDelete: (name: string) => void;
}

export const AttachmentList: React.FC<IProps> = (props) => {
    const translate = useTranslate();
    const getScanStatus = (item: IAttachmentModel) => {
        if (item.isClean === null) {
            return (
                <div className="tag is-warning is-small">
                    <span className="icon is-small is-loading">
                        <Spinner />
                    </span>
                    <span>{translate('Attachments.AttachmentList.ScanStatus.Scanning')}</span>
                </div>
            );
        }

        if (item.isClean) {
            return (
                <span className="tag is-success is-clearfix">
                    {translate('Attachments.AttachmentList.ScanStatus.Scanned&Uploaded')}
                </span>
            );
        }

        return (
            <span className="tag is-danger is-clearfix">
                {translate('Attachments.AttachmentList.ScanStatus.Malwarefound')}
            </span>
        );
    };

    const getDownloadLink = (item: IAttachmentModel) => {
        if (item.isClean) {
            return (
                <a
                    className="button download"
                    target="_blank"
                    rel="noopener noreferrer"
                    title={item.fileName}
                    href={item.isClean ? item.blobStorageUri : ''}
                >
                    {item.fileName}
                </a>
            );
        }

        return null;
    };

    const getDeleteLink = (item: IAttachmentModel) => {
        let disabled = props.disabled
        if (props.isImage && props.isImage==true) {
            if(!checkPermission(Privilege.AllAttachmentsDelete, props.permissions)) {
                return null;
            }
            disabled=false
        } 
        else
        if(props.disabled || !checkPermission(Privilege.AllAttachmentsDelete, props.permissions)) {
            return null
        }

        return (
            <ButtonWithConfirmDialog
                title={translate('Attachments.AttachmentList.ButtonWithConfirmDialog.Title')}
                message={props.isImage && props.isImage==true?
                    translate('Attachments.AttachmentList.ButtonWithConfirmDialog.ImageMessage')
                    :translate('Attachments.AttachmentList.ButtonWithConfirmDialog.Message')}
                onConfirm={() => props.onDelete(item.fileName)}
                buttonText={translate(
                    'Attachments.AttachmentList.ButtonWithConfirmDialog.ButtonText'
                )}
                buttonType="archive"
                buttonClassName="remove"
                isButtonDisabled={disabled}
            />
        );
    };

    return (
        <Table>
            <thead>
                <tr>
                    <th>{translate('Attachments.AttachmentList.FileName')}</th>
                    <th>{translate('Attachments.AttachmentList.DateUploaded')}</th>
                    <th>{translate('Attachments.AttachmentList.Status')}</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                {!isEmpty(props.data) ? (
                    props.data.map((item, i) => (
                        <tr key={i}>
                            <td className="ellipsis">
                                <span>{item.fileName}</span>
                            </td>
                            <td>{formatDateToUniversalDateTime(item.createdDate)}</td>
                            <td>{getScanStatus(item)}</td>
                            <td>
                                {getDownloadLink(item)} {getDeleteLink(item)}
                            </td>
                        </tr>
                    ))
                ) : (
                    <TableNoItem
                        colSpan={5}
                        noItemLabel={translate('Attachments.AttachmentList.NoItemLabel')}
                    />
                )}
            </tbody>
        </Table>
    );
};
