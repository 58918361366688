import * as React from 'react';
import { GridSortLink } from '../../../../../components/gridSortLink';
import { Loader } from '../../../../../components/loader';
import { Checkbox } from 'components/form/Checkbox';
import { IPrivilege } from 'reducers/manageRoles/manageRoles-grid';
import { SortDirection } from '../../../../../enums/SortDirection';
import { IKeyValue } from '../../../../../models/key-value';
import { Toolbar, ToolbarGroupRight, FloatingButton } from 'components/v2/components';
import { IRolePriviledge } from 'models/role-priviledge-list'
import { checkPermissions } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconColor, MaterialIconType } from 'routes/material-icon/material-icon-type';
import useTranslate from 'translations/translation-utils';

export interface IDispatchProps {
    onSort: (sortColumn: string, sortAscending: boolean) => void;
    onAddRemovePrivilege: (values: IRolePriviledge[]) => void;
    load: () => void;
}

export interface IProps extends IDispatchProps {
    privilegesState: IPrivilege[];
    sortColumn: string;
    sortAscending: boolean;
    lookupRoles: Array<IKeyValue<string>>;
    isLoading: boolean;
    manageRolesFilterRoles: string[];
    filterKeywords: string[];
    permissions: Array<IKeyValue<string>>;
}

export const ManagePrivilegeTab: React.FC<IProps> = (props) => {
    const translate = useTranslate();
    const [allchecked, setAllChecked] = React.useState<IRolePriviledge[]>([]);
    const [currentPrivilegesState, setCurrentPrivilegesState] = React.useState<IPrivilege[]>([]);
    const [isDirty, setIsDirty] = React.useState(true);

    React.useEffect(() => {
        props.load();
    }, []);

    React.useEffect(() => {
        if (props.privilegesState) {
            setCurrentPrivilegesState(props.privilegesState);
        }
    }, [props.privilegesState]);

    React.useEffect(() => {
        ReactTooltip.rebuild();
    });

    const collectChecked = (roleLookUp: IKeyValue<string>, id: string, remove: boolean) => {
        const value: IRolePriviledge = { roleLookUp, id, remove };
        let currentPriviledge = currentPrivilegesState;
        let existingVal = allchecked;

        if (remove) {
            currentPriviledge = currentPriviledge.map((p) => {
                if (p.id === id) {
                    return {
                        ...p,
                        rolesInPrivilege: p.rolesInPrivilege.filter((r) => {
                            return r.key !== roleLookUp.key;
                        }),
                    };
                }
                return p;
            });
        } else {
            currentPriviledge = currentPriviledge.map((p) => {
                if (p.id === id) {
                    return {
                        ...p,
                        rolesInPrivilege: [...p.rolesInPrivilege, roleLookUp],
                    };
                }
                return p;
            });
        };

        if (
            existingVal.filter((obj) => obj.roleLookUp.key === roleLookUp.key && obj.id === id)
                .length > 0
        ) {
            existingVal = existingVal.map((privilege) => {
                if (privilege.roleLookUp.key === roleLookUp.key && privilege.id === id) {
                    return {
                        ...privilege,
                        remove,
                    };
                }

                return privilege;
            });
        } else {
            existingVal.push(value);
        }

        setIsDirty(_.isEqual(currentPriviledge, props.privilegesState));
        setCurrentPrivilegesState(currentPriviledge);
    };

    const filteredRoles = props.lookupRoles.filter((f) => {
        if (props.manageRolesFilterRoles && props.manageRolesFilterRoles.length > 0) {
            return props.manageRolesFilterRoles.indexOf(f.key) > -1;
        }

        return true;
    });

    const privilegeModules = currentPrivilegesState
        .map((privilege) => privilege.module)
        .filter((privilege, index, self) => self.indexOf(privilege) === index)
        .sort();

    const onAddAndRemovePriviledge = (allPrivilege: IRolePriviledge[]) => {
        props.onAddRemovePrivilege(allPrivilege);
        setAllChecked([]);
        setIsDirty(true);
    };

    const filteredPrivilegeState = () => {
        function dynamicSort(property: string, sortDirection: boolean) {
            const sortOrder = sortDirection ? 1 : -1;
            return (a: any, b: any) => {
                const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
                return result * sortOrder;
            };
        }

        const privilegesStateFilteredOnRoles = currentPrivilegesState
            ? currentPrivilegesState.filter((f) => {
                if (!props.manageRolesFilterRoles || props.manageRolesFilterRoles.length === 0) {
                    return true;
                }

                return f.rolesInPrivilege.some((privilege) => {
                    return props.manageRolesFilterRoles.indexOf(privilege.key) > -1;
                });
            })
            : [];

        const filteredByKeywords = privilegesStateFilteredOnRoles.filter((privilege) => {
            if (props.filterKeywords && props.filterKeywords.length > 0) {
                if (privilege.privilegeName) {
                    const privilegeName = privilege.privilegeName.toLocaleLowerCase();

                    if (
                        props.filterKeywords.some((r) =>
                            privilegeName.includes(r.toLocaleLowerCase())
                        )
                    ) {
                        return true;
                    }
                }

                if (privilege.description) {
                    const privilegeDescription = privilege.description.toLocaleLowerCase();

                    if (
                        props.filterKeywords.some((r) =>
                            privilegeDescription.includes(r.toLocaleLowerCase())
                        )
                    ) {
                        return true;
                    }
                }

                return false;
            }

            return true;
        });

        return filteredByKeywords.sort(dynamicSort(props.sortColumn, props.sortAscending));
    };

    const privilegeState = filteredPrivilegeState();

    return (
        <Loader className="ManageRoleList" loading={props.isLoading}>
            <table className="table table-sticky-header">
                <thead>
                    <tr>
                        <th>{getGridHeader('Privilege', 'privilegeName', props)}</th>
                        {filteredRoles &&
                            filteredRoles.map((lookUp, key) => {
                                return (
                                    <th key={key}>
                                        <span className="is-vertical-center">{lookUp.value}</span>
                                    </th>
                                );
                            })}
                    </tr>
                </thead>
                <tbody>
                    {privilegeState &&
                        privilegeModules.map((module) => (
                            <>
                                {getPrivilegeModuleItem(module, filteredRoles.length)}
                                {privilegeState
                                    .filter((privilege) => privilege.module === module)
                                    .map((privilege) =>
                                        getPrivilegeTabItem(
                                            privilege,
                                            filteredRoles,
                                            collectChecked
                                        )
                                    )}
                            </>
                        ))}
                </tbody>
            </table>

            {
                checkPermissions([Privilege.UserManagementPrivilegeEdit], props.permissions) && (
                    <Toolbar type="save">
                        <ToolbarGroupRight>
                            <FloatingButton
                                id="save-site-features"
                                onClick={() => onAddAndRemovePriviledge(allchecked)}
                                type="button"
                                float={!isDirty}
                                tooltip={translate('Globals.Label.PleaseClick')}
                            >
                                Save
                            </FloatingButton>
                        </ToolbarGroupRight>
                    </Toolbar>
                )}
        </Loader>
    );
};

const getPrivilegeModuleItem = (module: string, colspan: number) => {
    return (
        <tr className="header-category">
            <td>{module}</td>
            <td colSpan={colspan} />
        </tr>
    );
};

const getPrivilegeTabItem = (
    privilege: IPrivilege,
    lookupRoles: Array<IKeyValue<string>>,
    collectChecked: (roleLookUp: IKeyValue<string>, id: string, remove: boolean) => void,
) => {
    return (
        <tr key={privilege.id}>
            <td>
                {privilege.displayName ?? privilege.privilegeName}
                <span data-tip={privilege.description}>
                    < MaterialIcons type={MaterialIconType.Info} color={MaterialIconColor.green} className="float-right" />
                </span>
            </td>

            {lookupRoles &&
                lookupRoles.map((lookUp, key) => {
                    const checked = privilege.rolesInPrivilege.some((c) => c.key === lookUp.key);

                    return (
                        <td key={key}>
                            <Checkbox
                                id={`${lookUp.key}${privilege.id}`}
                                checked={checked}
                                onChange={collectChecked.bind(this, lookUp, privilege.id, checked)}
                            />
                        </td>
                    );
                })}
        </tr>
    );
};

const getGridHeader = (label: string, fieldName: string, props: IProps) => {
    const onSort = (sortField: string, sortDirection: SortDirection) =>
        props.onSort(sortField, sortDirection === SortDirection.Ascending);
    return (
        <GridSortLink
            currentSortFieldName={props.sortColumn}
            currentSortDirection={
                props.sortAscending ? SortDirection.Ascending : SortDirection.Descending
            }
            sortFieldName={fieldName}
            onSort={onSort}
        >
            {label}
        </GridSortLink>
    );
};
