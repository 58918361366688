import { Action } from 'redux';
import { IChangeSearchValueAction, ILoadSearchResultsAction } from '.';
import { IPerson } from '../../models/person';
import { ISiteProfile } from '../../models/site-profile';

export enum PeopleActions {
    SELECT_PERSON = '@@siteRelationships/SELECT_PERSON',
    CHANGE_PERSON_SEARCH_VALUE = '@@siteRelationships/CHANGE_PERSON_SEARCH_VALUE',
    LOAD_PERSON_SEARCH_RESULTS = '@@siteRelationships/LOAD_PERSON_SEARCH_RESULTS',
    LOAD_PERSON_SEARCH_RESULTS_FULFILLED = '@@siteRelationships/LOAD_PERSON_SEARCH_RESULTS_FULFILLED',
    LOAD_PERSON_SEARCH_RESULTS_REJECTED = '@@siteRelationships/LOAD_PERSON_SEARCH_RESULTS_REJECTED',
    LOAD_PERSON_SEARCH_RESULTS_CANCELLED = '@@siteRelationships/LOAD_PERSON_SEARCH_RESULTS_CANCELLED',

    LOAD_LINKED_PEOPLE = '@@siteRelationships/LOAD_LINKED_PEOPLE',
    LOAD_LINKED_PEOPLE_FULFILLED = '@@siteRelationships/LOAD_LINKED_PEOPLE_FULFILLED',
    LOAD_LINKED_PEOPLE_REJECTED = '@@siteRelationships/LOAD_LINKED_PEOPLE_REJECTED',
    LOAD_LINKED_PEOPLE_CANCELLED = '@@siteRelationships/LOAD_LINKED_PEOPLE_CANCELLED',

    LINK_PERSON = '@@siteRelationships/LINK_PERSON',
    LINK_PERSON_FULFILLED = '@@siteRelationships/LINK_PERSON_FULFILLED',
    LINK_PERSON_REJECTED = '@@siteRelationships/LINK_PERSON_REJECTED',
    LINK_PERSON_CANCELLED = '@@siteRelationships/LINK_PERSON_CANCELLED',

    UNLINK_PERSON = '@@siteRelationships/UNLINK_PERSON',
    UNLINK_PERSON_CONFIRMED = '@@siteRelationships/UNLINK_PERSON_CONFIRMED',
    UNLINK_PERSON_FULFILLED = '@@siteRelationships/UNLINK_PERSON_FULFILLED',
    UNLINK_PERSON_REJECTED = '@@siteRelationships/UNLINK_PERSON_REJECTED',
    UNLINK_PERSON_CANCELLED = '@@siteRelationships/UNLINK_PERSON_CANCELLED',
}

export interface ISelectPerson extends Action {
    type: PeopleActions.SELECT_PERSON;
    payload: {
        person: IPerson;
    };
}

export const selectPerson = (person: IPerson): ISelectPerson => ({
    type: PeopleActions.SELECT_PERSON,
    payload: {
        person
    }
});

export interface IChangePersonSearchValueAction extends IChangeSearchValueAction {
    type: PeopleActions.CHANGE_PERSON_SEARCH_VALUE;
}

export const changePersonSearchValue = (value: string): IChangeSearchValueAction => ({
    type: PeopleActions.CHANGE_PERSON_SEARCH_VALUE,
    payload: {
        value
    }
});

export interface ILoadPersonSearchResults extends ILoadSearchResultsAction {
    type: PeopleActions.LOAD_PERSON_SEARCH_RESULTS;
}

export const loadPersonSearchResults = (searchString: string): ILoadSearchResultsAction => ({
    type: PeopleActions.LOAD_PERSON_SEARCH_RESULTS,
    payload: {
        searchString
    }
});

export interface ILoadPersonSearchResultsFulfilled extends Action {
    type: PeopleActions.LOAD_PERSON_SEARCH_RESULTS_FULFILLED;
    payload: {
        searchString: string;
        people: IPerson[];
    };
}

export const loadPersonSearchResultsFulfilled = (
    searchString: string,
    people: IPerson[]
): ILoadPersonSearchResultsFulfilled => ({
    type: PeopleActions.LOAD_PERSON_SEARCH_RESULTS_FULFILLED,
    payload: {
        searchString,
        people
    }
});

export interface ILoadPersonSearchResultsCancelled extends Action {
    type: PeopleActions.LOAD_PERSON_SEARCH_RESULTS_CANCELLED;
    payload: {
        searchString: string;
    };
}

export const loadPersonSearchResultsCancelled = (searchString: string): ILoadPersonSearchResultsCancelled => ({
    type: PeopleActions.LOAD_PERSON_SEARCH_RESULTS_CANCELLED,
    payload: {
        searchString
    }
});

export interface ILoadPersonSearchResultsRejected extends Action {
    type: PeopleActions.LOAD_PERSON_SEARCH_RESULTS_REJECTED;
    error: true;
    payload: any;
}

export const loadPersonSearchResultsRejected = (
    searchString: string,
    error: any
): ILoadPersonSearchResultsRejected => ({
    type: PeopleActions.LOAD_PERSON_SEARCH_RESULTS_REJECTED,
    error: true,
    payload: {
        searchString,
        error
    }
});

export interface ILoadLinkedPeopleAction extends Action {
    type: PeopleActions.LOAD_LINKED_PEOPLE;
    payload: {
        siteId: string;
    };
}

export const loadLinkedPeople = (siteId: string): ILoadLinkedPeopleAction => ({
    type: PeopleActions.LOAD_LINKED_PEOPLE,
    payload: {
        siteId
    }
});

export interface ILoadLinkedPeopleCancelledAction extends Action {
    type: PeopleActions.LOAD_LINKED_PEOPLE_CANCELLED;
    payload: {
        siteId: string;
    };
}

export const loadLinkedPeopleCancelled = (siteId: string): ILoadLinkedPeopleCancelledAction => ({
    type: PeopleActions.LOAD_LINKED_PEOPLE_CANCELLED,
    payload: {
        siteId
    }
});

export interface ILoadLinkedPeopleFulfilledAction extends Action {
    type: PeopleActions.LOAD_LINKED_PEOPLE_FULFILLED;
    payload: {
        siteId: string;
        people: IPerson[];
    };
}

export const loadLinkedPeopleFulfilled = (
    siteId: string,
    people: IPerson[]
): ILoadLinkedPeopleFulfilledAction => ({
    type: PeopleActions.LOAD_LINKED_PEOPLE_FULFILLED,
    payload: {
        siteId,
        people
    }
});

export interface ILoadLinkedPeopleRejectedAction extends Action {
    type: PeopleActions.LOAD_LINKED_PEOPLE_REJECTED;
    error: true;
    payload: any;
}

export const loadLinkedPeopleRejected = (
    siteId: string,
    error: any
): ILoadLinkedPeopleRejectedAction => ({
    type: PeopleActions.LOAD_LINKED_PEOPLE_REJECTED,
    error: true,
    payload: {
        siteId,
        error
    }
});

export interface ILinkPersonAction extends Action {
    type: PeopleActions.LINK_PERSON;
    payload: {
        site: ISiteProfile;
        person: IPerson;
    };
}

export const linkPerson = (site: ISiteProfile, person: IPerson): ILinkPersonAction => ({
    type: PeopleActions.LINK_PERSON,
    payload: {
        site,
        person
    }
});

export interface ILinkPersonActionFulfilled extends Action {
    type: PeopleActions.LINK_PERSON_FULFILLED;
    payload: {
        site: ISiteProfile;
        person: IPerson;
    };
}

export const linkPersonFulfilled = (
    site: ISiteProfile,
    person: IPerson
): ILinkPersonActionFulfilled => ({
    type: PeopleActions.LINK_PERSON_FULFILLED,
    payload: {
        site,
        person
    }
});

export interface ILinkPersonActionCancelled extends Action {
    type: PeopleActions.LINK_PERSON_CANCELLED;
    payload: {
        site: ISiteProfile;
        person: IPerson;
    };
}

export const linkPersonCancelled = (
    site: ISiteProfile,
    person: IPerson
): ILinkPersonActionCancelled => ({
    type: PeopleActions.LINK_PERSON_CANCELLED,
    payload: {
        site,
        person
    }
});

export interface ILinkPersonActionRejected extends Action {
    type: PeopleActions.LINK_PERSON_REJECTED;
    error: true;
    payload: any;
}

export const linkPersonRejected = (
    site: ISiteProfile,
    person: IPerson,
    error: any
): ILinkPersonActionRejected => ({
    type: PeopleActions.LINK_PERSON_REJECTED,
    error: true,
    payload: {
        site,
        person,
        error
    }
});

export interface IUnlinkPersonAction extends Action {
    type: PeopleActions.UNLINK_PERSON;
    payload: {
        site: ISiteProfile;
        person: IPerson;
    };
}

export const unlinkPerson = (site: ISiteProfile, person: IPerson): IUnlinkPersonAction => ({
    type: PeopleActions.UNLINK_PERSON,
    payload: {
        site,
        person
    }
});

export interface IUnlinkPersonConfirmedAction extends Action {
    type: PeopleActions.UNLINK_PERSON_CONFIRMED;
    payload: {
        site: ISiteProfile;
        person: IPerson;
    };
}

export const unlinkPersonConfirmed = (
    site: ISiteProfile,
    person: IPerson
): IUnlinkPersonConfirmedAction => ({
    type: PeopleActions.UNLINK_PERSON_CONFIRMED,
    payload: {
        site,
        person
    }
});

export interface IUnlinkPersonActionFulfilled extends Action {
    type: PeopleActions.UNLINK_PERSON_FULFILLED;
    payload: {
        site: ISiteProfile;
        person: IPerson;
    };
}

export const unlinkPersonFulfilled = (site: ISiteProfile, person: IPerson) => ({
    type: PeopleActions.UNLINK_PERSON_FULFILLED,
    payload: {
        site,
        person
    }
});

export interface IUnlinkPersonActionCancelled extends Action {
    type: PeopleActions.UNLINK_PERSON_CANCELLED;
    payload: {
        site: ISiteProfile;
        person: IPerson;
    };
}

export const unlinkPersonCancelled = (site: ISiteProfile, person: IPerson) => ({
    type: PeopleActions.UNLINK_PERSON_CANCELLED,
    payload: {
        site,
        person
    }
});

export interface IUnlinkPersonActionRejected extends Action {
    type: PeopleActions.UNLINK_PERSON_REJECTED;
    error: true;
    payload: {
        site: ISiteProfile;
        person: IPerson;
    };
}

export const unlinkPersonRejected = (
    site: ISiteProfile,
    person: IPerson,
    error: any
) => ({
    type: PeopleActions.UNLINK_PERSON_REJECTED,
    error: true,
    payload: {
        site,
        person,
        error
    }
});

export type PeopleActionTypes =
| ISelectPerson
| IChangePersonSearchValueAction
| ILoadPersonSearchResults
| ILoadPersonSearchResultsFulfilled
| ILoadPersonSearchResultsCancelled
| ILoadPersonSearchResultsRejected

| ILoadLinkedPeopleAction
| ILoadLinkedPeopleCancelledAction
| ILoadLinkedPeopleFulfilledAction
| ILoadLinkedPeopleRejectedAction

| ILinkPersonAction
| ILinkPersonActionCancelled
| ILinkPersonActionFulfilled
| ILinkPersonActionRejected

| IUnlinkPersonAction
| IUnlinkPersonConfirmedAction
| IUnlinkPersonActionCancelled
| IUnlinkPersonActionFulfilled
| IUnlinkPersonActionRejected;
