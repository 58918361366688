import { Action } from 'redux';
import { IAttachmentCollection } from 'models/attachment-collection';
import { ICermProcessStep } from 'models/cerm-process-step';
import { IKeyedItem } from 'models/keyed-item';
import { ICermAssessmentsState, IOutcome, IProcessStep } from '../reducers/cermAssessments';
import { IAppAction } from './app-action';

export enum CermAssessmentActions {
    SORT = '@@cermAssessment/SORT',
    CONFIRM_CLOSE = '@@cermAssessment/CONFIRM_CLOSE',

    // LOAD_ASSESSMENT = '@@cermAssessment/LOAD_ASSESSMENT',
    // LOAD_ASSESSMENTS_FULFILLED = '@@cermAssessment/LOAD_ASSESSMENTS_FULFILLED',
    // LOAD_ASSESSMENT_REJECTED = '@@cermAssessment/LOAD_ASSESSMENT_REJECTED',

    // SET_PROCESS_STEP_APPLICABILITY = '@@cermAssessment/SET_PROCESS_STEP_APPLICABILITY',
    // SET_PROCESS_STEP_APPLICABILITY_FULFILLED = '@@cermAssessment/SET_PROCESS_STEP_APPLICABILITY_FULFILLED',
    // SET_PROCESS_STEP_APPLICABILITY_REJECTED = '@@cermAssessment/SET_PROCESS_STEP_APPLICABILITY_REJECTED',

    SET_OUTCOME_END_REVIEW= '@@cermAssessment/SET_OUTCOME_END_REVIEW',

    SET_RISK_DETAILS = '@@cermAssessment/SET_ADDITIONAL_RISK',
    SET_RISK_DETAILS_FULFILLED = '@@cermAssessment/SET_RISK_DETAILS_FULFILLED',
    SET_RISK_DETAILS_REJECTED = '@@cermAssessment/SET_RISK_DETAILS_REJECTED',

    SET_OUTCOME = '@@cermAssessment/SET_OUTCOME',
    // SET_OUTCOME_FULFILLED = '@@cermAssessment/SET_OUTCOME_FULFILLED',
    // SET_OUTCOME_REJECTED = '@@cermAssessment/SET_OUTCOME_REJECTED',

    ADD_FILTER_KEYWORD= '@@cermAssessment/ADD_FILTER_KEYWORD',
    REMOVE_FILTER_KEYWORD= '@@cermAssessment/REMOVE_FILTER_KEYWORD',
    CHANGE_FILTER_VALUE = '@@cermAssessment/CHANGE_FILTER_VALUE',
    CLEAR_FILTERS= '@@cermAssessment/CLEAR_FILTERS',

    COLLAPSE_ALL= '@@cermAssessment/COLLAPSE_ALL',
    EXPAND_ALL= '@@cermAssessment/EXPAND_ALL',

    SHOW_FILTER_MODAL = '@@cermAssessment/SHOW_FILTER_MODAL',
    HIDE_FILTER_MODAL = '@@cermAssessment/HIDE_FILTER_MODAL',

    TOGGLE_FILTER_EXPANDED = '@@cermAssessment/TOGGLE_FILTER_EXPANDED',
    TOGGLE_PILLAR = '@@cermAssessment/TOGGLE_PILLAR',
    TOGGLE_STEP = '@@cermAssessment/TOGGLE_STEP',

    SHOW_ADD_ATTACHMENT_MODAL = '@@CermAssessment/SHOW_ADD_ATTACHMENT_MODAL',
    HIDE_ADD_ATTACHMENT_MODAL = '@@CermAssessment/HIDE_ADD_ATTACHMENT_MODAL',
    ADD_ATTACHMENT = '@@CermAssessment/ADD_ATTACHMENT',
    ADD_ATTACHMENT_FULFILLED = '@@CermAssessment/ADD_ATTACHMENT_FULFILLED',
    ADD_ATTACHMENT_REJECTED = '@@CermAssessment/ADD_ATTACHMENT_REJECTED',
    ADD_ATTACHMENT_CANCELLED = '@@CermAssessment/ADD_ATTACHMENT_CANCELLED',

    LOAD_ATTACHMENTS = '@@CermAssessment/LOAD_ATTACHMENTS',
    LOAD_ATTACHMENTS_FULFILLED = '@@CermAssessment/LOAD_ATTACHMENTS_FULFILLED',
    HIDE_ATTACHMENTS = '@@CermAssessment/HIDE_ATTACHMENTS',
    DELETE_ATTACHMENT = '@@CermAssessment/DELETE_ATTACHMENT',
    DELETE_ATTACHMENT_COMPLETE = '@@CermAssessment/DELETE_ATTACHMENT_COMPLETE',
    DELETE_ATTACHMENT_REJECTED = '@@cermAssessment/DELETE_ATTACHMENT_REJECTED',
    RELOAD_ATTACHMENTS = '@@CermAssessment/RELOAD_ATTACHMENTS',
    // LOAD_PAGE = '@@CermAssessment/LOAD_PAGE',
    // LOAD_PAGE_FULFILLED = '@@CermAssessment/LOAD_PAGE_FULFILLED',
    // LOAD_PAGE_REJECTED = '@@CermAssessment/LOAD_PAGE_REJECTED',
    // LOAD_PAGE_CANCELLED = '@@CermAssessment/LOAD_PAGE_CANCELLED'
}

// export interface ILoadPageAction extends Action {
//     type: CermAssessmentActions.LOAD_PAGE;
// }

// export interface ILoadPageRejectedAction extends Action {
//     type: CermAssessmentActions.LOAD_PAGE_REJECTED;
//     error: true;
//     payload: any;
// }

// export interface ILoadPageCancelledAction extends Action {
//     type: CermAssessmentActions.LOAD_PAGE_CANCELLED;
// }

// export interface ILoadPageFulfilledAction extends Action {
//     type: CermAssessmentActions.LOAD_PAGE_FULFILLED;
//     payload: any;
// }

export interface ILoadAttachmentsFulfilledAction extends Action {
    type: CermAssessmentActions.LOAD_ATTACHMENTS_FULFILLED;
    payload: {
        id: string,
        data: IAttachmentCollection[];
    };
}

export interface ISort extends Action {
    type: CermAssessmentActions.SORT;
    payload: {
        sortColumn: string;
    };
}

export interface ISetOutcomeEndReview extends Action {
    type: CermAssessmentActions.SET_OUTCOME_END_REVIEW;
    payload: {
        outcome: IOutcome;
    };
}

export const onSetOutcomeEndReview = (outcome: IOutcome): CermAssessmentActionTypes => ({
    type: CermAssessmentActions.SET_OUTCOME_END_REVIEW,
    payload: {
        outcome
    }
});

export interface IShowAddAttachmentModalAction extends Action {
    type: CermAssessmentActions.SHOW_ADD_ATTACHMENT_MODAL;
}

export const onShowAddAttachmentModal = (): CermAssessmentActionTypes => ({
    type: CermAssessmentActions.SHOW_ADD_ATTACHMENT_MODAL,
});

export interface IHideAddAttachmentModalAction extends Action {
    type: CermAssessmentActions.HIDE_ADD_ATTACHMENT_MODAL;
}

export const onHideAttachmentModal = (): CermAssessmentActionTypes => ({
    type: CermAssessmentActions.HIDE_ADD_ATTACHMENT_MODAL
});

export interface IAddAttachmentAction extends Action {
    type: CermAssessmentActions.ADD_ATTACHMENT;
    payload: {
        id: string;
        childId: string;
        files: File[];
    };
}

export const onAddAttachment = (id: string, childId: string, files: File[]): CermAssessmentActionTypes => ({
    type: CermAssessmentActions.ADD_ATTACHMENT,
    payload: {
        id,
        childId,
        files
    }
});

export interface IAddAttachmentCancelledAction extends Action {
    type: CermAssessmentActions.ADD_ATTACHMENT_CANCELLED;
    payload: {
        id: string;
    };
}

export interface IAddAttachmentFulfilledAction extends Action {
    type: CermAssessmentActions.ADD_ATTACHMENT_FULFILLED;
    payload: {
        id: string;
    };
}

export interface IAddAttachmentRejectedAction extends Action {
    type: CermAssessmentActions.ADD_ATTACHMENT_REJECTED;
    payload: {
        id: string;
        files: File[];
    };
}

export interface IConfirmClose extends Action {
    type: CermAssessmentActions.CONFIRM_CLOSE;
    payload: {
        processStep: IProcessStep;
    };
}

// export interface ILoadAssessmentRejected extends Action {
//     type: CermAssessmentActions.LOAD_ASSESSMENT_REJECTED;
// }

// export interface ILoadAssessmentFulfilled extends Action {
//     type: CermAssessmentActions.LOAD_ASSESSMENTS_FULFILLED;
//     payload: ICermAssessmentsState;
// }

// export interface ILoadAssessment extends Action {
//     type: CermAssessmentActions.LOAD_ASSESSMENT;
//     payload: {
//         siteId: string;
//     };
// }

export interface ITogglePillar extends Action {
    type: CermAssessmentActions.TOGGLE_PILLAR;
    payload: {
        id: string;
    };
}

export interface IToggleStep extends Action {
    type: CermAssessmentActions.TOGGLE_STEP;
    payload: {
        id: string;
    };
}

export interface IToggleFilterExpanded extends Action {
    type: CermAssessmentActions.TOGGLE_FILTER_EXPANDED;
    payload: {
        id: Extract<keyof ICermAssessmentsState, string>;
    };
}

export interface IShowFilterModalAction extends Action {
    type: CermAssessmentActions.SHOW_FILTER_MODAL;
}

export interface IHideFilterModalAction extends Action {
    type: CermAssessmentActions.HIDE_FILTER_MODAL;
}

export interface ISetFilterValueAction extends Action {
    type: CermAssessmentActions.CHANGE_FILTER_VALUE;
    payload: {
        field: string;
        value: string;
    };
}

export interface IAddFilterKeyword extends Action {
    type: CermAssessmentActions.ADD_FILTER_KEYWORD;
    payload: {
        keyword: string;
    };
}

export interface IRemoveFilterKeyword extends Action {
    type: CermAssessmentActions.REMOVE_FILTER_KEYWORD;
    payload: {
        keyword: string;
    };
}

export interface ICollapseAllCermAssessmentAction extends Action {
    type: CermAssessmentActions.COLLAPSE_ALL;
}

export interface IExpandAllCermAssessmentAction extends Action {
    type: CermAssessmentActions.EXPAND_ALL;
    payload: {
        pillars: string[],
        steps: string[]
    };
}

export interface IClearFiltersCermAssessmentAction extends Action {
    type: CermAssessmentActions.CLEAR_FILTERS;
}

// export interface ISetApplicabilityCermAssessmentAction extends Action {
//     type: CermAssessmentActions.SET_PROCESS_STEP_APPLICABILITY;
//     payload: {
//         processStepId: string;
//         answer?: boolean;
//     };
// }

// export interface ISetApplicabilityCermAssessmentFulfilledAction extends Action {
//     type: CermAssessmentActions.SET_PROCESS_STEP_APPLICABILITY_FULFILLED;
//     payload: {
//         success?: boolean;
//         pillars: IPillar[];
//         siteScore: number;
//     };
// }

// export interface ISetApplicabilityCermAssessmentRejectedAction extends Action {
//     type: CermAssessmentActions.SET_PROCESS_STEP_APPLICABILITY_REJECTED;
//     payload: {
//         processStepId: string;
//         answer?: boolean;
//     };
// }

export interface ISetAnswerCermAssessmentAction extends Action {
    type: CermAssessmentActions.SET_OUTCOME;
    payload: {
        outcome: IOutcome
    };
}

export interface ILoadAttachmentsAction extends Action {
    type: CermAssessmentActions.LOAD_ATTACHMENTS;
    payload: {
        id: string;
    };
}

export const loadAttachments = (id: string): CermAssessmentActionTypes => ({
    type: CermAssessmentActions.LOAD_ATTACHMENTS,
    payload: {
        id
    }
});

export interface IReloadAttachmentsAction extends Action {
    type: CermAssessmentActions.RELOAD_ATTACHMENTS;
    payload: {
        id: string;
    };
}

export const reloadAttachments = (id: string): CermAssessmentActionTypes => ({
    type: CermAssessmentActions.RELOAD_ATTACHMENTS,
    payload: {
        id
    }
});

export interface IDeleteAttachmentAction extends Action {
    type: CermAssessmentActions.DELETE_ATTACHMENT;
    payload: {
        attachmentId: string,
        assessmentId: string,
    };
}

export interface IDeleteAttachmentCompleteAction extends Action {
    type: CermAssessmentActions.DELETE_ATTACHMENT_COMPLETE;
    payload: {
        id: string
    };
}

export interface IDeleteAttachmentRejected extends Action {
    type: CermAssessmentActions.DELETE_ATTACHMENT_REJECTED;
    payload: {
        id: string;
    };
}

export interface IHideAttachmentsAction extends Action {
    type: CermAssessmentActions.HIDE_ATTACHMENTS;
    payload: {
        outcome: IKeyedItem
    };
}

export const onClose = (item: IKeyedItem): CermAssessmentActionTypes => ({
    type: CermAssessmentActions.HIDE_ATTACHMENTS,
    payload: {
        outcome: item
    }
});

export const onDelete = (attachmentId: string, assessmentId: string): CermAssessmentActionTypes => ({
    type: CermAssessmentActions.DELETE_ATTACHMENT,
    payload: {
        attachmentId,
        assessmentId,
    }
});

export const onSaveAndNext = (outcome: IOutcome): IAppAction => ({
    type: CermAssessmentActions.SET_OUTCOME,
    payload: {
        outcome
    }
});

export const onSaveRiskDetails = (processStep: Partial<ICermProcessStep>): CermAssessmentActionTypes => ({
    type: CermAssessmentActions.SET_RISK_DETAILS,
    payload: {
        processStep
    }
});

export interface ISetRiskDetailsAction extends Action {
    type: CermAssessmentActions.SET_RISK_DETAILS;
    payload: {
        processStep: Partial<ICermProcessStep>
    };
}

export interface ISetRiskDetailsFulfilledAction extends Action {
    type: CermAssessmentActions.SET_RISK_DETAILS_FULFILLED;
    payload: {
        processStep: Partial<ICermProcessStep>
    };
}

// export interface ISetAnswerCermAssessmentFulfilledAction extends Action {
//     type: CermAssessmentActions.SET_OUTCOME_FULFILLED;
//     payload: {
//         success?: boolean;
//         pillars: IPillar[];
//         siteScore: number;
//     };
// }

// export interface ISetAnswerCermAssessmentRejectedAction extends Action {
//     type: CermAssessmentActions.SET_OUTCOME_REJECTED;
//     payload: {
//         processStepId: string;
//         outcomeId: string;
//         answer?: boolean;
//     };
// }

// export interface ISetAnswerCermAssessmentRejectedAction extends Action {
//     type: CermAssessmentActions.SET_OUTCOME_REJECTED;
//     payload: {
//         processStepId: string;
//         outcomeId: string;
//         answer?: boolean;
//     };
// }

export type CermAssessmentActionTypes =
ILoadAttachmentsAction
| ISetRiskDetailsFulfilledAction
| IHideAttachmentsAction
| ILoadAttachmentsFulfilledAction
| IReloadAttachmentsAction
| ISetRiskDetailsAction
| ISetOutcomeEndReview
| ISort
| IConfirmClose
// | ILoadAssessmentRejected
// | ILoadAssessmentFulfilled
// | ILoadAssessment
// | ISetAnswerCermAssessmentRejectedAction
// | ISetAnswerCermAssessmentFulfilledAction
// | ISetAnswerCermAssessmentAction
| ITogglePillar
| IToggleStep
| IToggleFilterExpanded
| IHideFilterModalAction
| IShowFilterModalAction
| ISetFilterValueAction
| ICollapseAllCermAssessmentAction
// | IExpandAllCermAssessmentAction
| IAddFilterKeyword
| IRemoveFilterKeyword
// | ISetApplicabilityCermAssessmentAction
// | ISetApplicabilityCermAssessmentFulfilledAction
| IClearFiltersCermAssessmentAction
// | ISetApplicabilityCermAssessmentRejectedAction
| IShowAddAttachmentModalAction
| IHideAddAttachmentModalAction
| IAddAttachmentAction
| IAddAttachmentFulfilledAction
| IAddAttachmentRejectedAction
| IAddAttachmentCancelledAction
| IDeleteAttachmentCompleteAction
| IDeleteAttachmentAction;
// | ILoadPageAction
// | ILoadPageCancelledAction
// | ILoadPageRejectedAction
// | ILoadPageFulfilledAction;
