import * as React from 'react';
import { CheckboxFilter } from 'components/checkboxFilter';
import { IKeyValue } from 'models/key-value';
import useTranslate from 'translations/translation-utils';

export interface IProps {
    expandedFilters: string[];

    regionFilters: string[];
    lineOfBusinessFilters: string[];
    otherFilters: string[];

    lookupRegionFilters: Array<IKeyValue<string>>;
    lookupLineOfBusinessFilters: Array<IKeyValue<string>>;
    lookupOtherFilters: Array<IKeyValue<string>>;

    onFilter: (field: string, value: string) => void;
    onToggleFilterExpanded: (id: string) => void;
}

export const CermSiteOverviewFilters: React.FC<IProps> = (props) => {
    const translate = useTranslate();
    const commonProps = {
        expandedFilters: props.expandedFilters,
        onFilter: props.onFilter,
        onToggleFilterExpanded: props.onToggleFilterExpanded,
    };

    return (
        <>
            <CheckboxFilter
                {...commonProps}
                title={translate('GainAccess.Labels.Regions')}
                name="regionFilters"
                selectedFilters={props.regionFilters}
                unreservedFilters={props.lookupRegionFilters}
            />

            <CheckboxFilter
                {...commonProps}
                title={translate('Cerm.GlobalOverviewFilters.Labels.LineOfBusiness')}
                name="lineOfBusinessFilters"
                selectedFilters={props.lineOfBusinessFilters}
                unreservedFilters={props.lookupLineOfBusinessFilters}
            />

            <CheckboxFilter
                {...commonProps}
                title={translate('RiskActionTab.Category.dropdown.Other')}
                name="otherFilters"
                selectedFilters={props.otherFilters}
                unreservedFilters={props.lookupOtherFilters}
            />
        </>
    );
};
