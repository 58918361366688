import INavItem from 'models/nav-item';
import { IKeyValue } from 'models';
import { checkPermissions } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';

export const getAdminNavItems = (
    permissions: IKeyValue<string>[],
    selectOperatingPlatform: string
) => {
    const navItems: INavItem[] = [
        {
            text: 'Admin',
            link: '/',
            isVisible: true,
            children: [
                {
                    text: 'Sites',
                    link: '/Site',
                    isNotSingleSiteRelated: true,
                    isVisible: checkPermissions([Privilege.SiteView], permissions),
                },
                {
                    text: 'Clients',
                    link: '/Client',
                    isNotSingleSiteRelated: true,
                    isVisible: checkPermissions([Privilege.ClientView], permissions),
                },
                {
                    text: 'Global Competency DNA Processes',
                    link: '/CompetencyDNAProcessAdmin',
                    isNotSingleSiteRelated: false,
                    isVisible:
                        checkPermissions([Privilege.CompetencyDNAProcessAdminView], permissions)
                },
                {
                    text: 'User Management',
                    link: '/ManageRole',
                    isNotSingleSiteRelated: true,
                    isVisible: checkPermissions(
                        [
                            Privilege.ManageRoles,
                            Privilege.ManagePeople,
                            Privilege.UserManagementRoleView,
                        ],
                        permissions
                    ),
                },
                {
                    text: 'Risk Profile Overview',
                    link: `/RiskProfileMasterQuestionnaire/${selectOperatingPlatform}`,
                    isNotSingleSiteRelated: true,
                    isVisible: checkPermissions(
                        [Privilege.RiskProfileMasterQuestionnaire],
                        permissions
                    ),
                },
                {
                    text: 'Risk Questions Overview',
                    link: `/QuestionSets/${selectOperatingPlatform}`,
                    isNotSingleSiteRelated: true,
                    isVisible: checkPermissions(
                        [Privilege.RiskQuestionsOverviewUpdate],
                        permissions
                    ),
                },
                {
                    text: 'Opportunity Questionnaire Admin',
                    link: '/Opportunities',
                    isNotSingleSiteRelated: true,
                    isVisible: checkPermissions([Privilege.ManageOpportunity], permissions),
                },
                {
                    text: 'Operating Platform',
                    link: `/operating-platform/list`,
                    isNotSingleSiteRelated: true,
                    isVisible: checkPermissions([Privilege.OperatingPlatformUpdate], permissions),
                },
                {
                    text: 'Site Relationships',
                    link: '/ManageRelationships',
                    isNotSingleSiteRelated: true,
                    isVisible: false,
                },
                {
                    text: 'Sites Features',
                    link: '/SiteFeatures',
                    isNotSingleSiteRelated: true,
                    isVisible: checkPermissions([Privilege.SiteFeatures], permissions),
                },
                {
                    text: 'Send Notifications',
                    link: '/SendNotifications',
                    isNotSingleSiteRelated: true,
                    isVisible: checkPermissions([Privilege.SendManualNotifications], permissions),
                },
                {
                    text: 'Global Overview',
                    link: '/CermGlobalOverview',
                    isNotSingleSiteRelated: true,
                    isVisible: checkPermissions([Privilege.GlobalOverview], permissions),
                },
                {
                    text: 'Process Overview',
                    link: `/CermProcessSteps/${selectOperatingPlatform}`,
                    isVisible: checkPermissions([Privilege.ProcessUpdate], permissions),
                },
                {
                    text: 'Global Mock Drills',
                    link: '/GlobalMockDrills',
                    isNotSingleSiteRelated: true,
                    isVisible: checkPermissions(
                        [Privilege.GlobalMockDrills, Privilege.GlobalMockDrillsAddToSites],
                        permissions
                    ),
                },
                {
                    text: 'Logs Upload',
                    link: '/LogsUpload',
                    isNotSingleSiteRelated: true,
                    isVisible: checkPermissions([Privilege.LogsUpload], permissions),
                },
                {
                    text: 'Bulk Assignment',
                    link: '/BulkAssignment',
                    isNotSingleSiteRelated: true,
                    isVisible: checkPermissions([Privilege.BulkAssignmentUpdate], permissions),
                },
                {
                    text: 'Shift Handovers New Admin',
                    link: `/shift-handover-new-admin/shift-handover-checklist/${selectOperatingPlatform}`,
                    isNotSingleSiteRelated: false,
                    isVisible: checkPermissions([Privilege.ShiftHandoverAdminUpdate], permissions),
                },

            ],
        },
    ];

    return navItems;
};
