// Risk statuses
export const OPEN_AND_PENDING_REVIEW = 'Open and Pending Review';
export const MITIGATION_PENDING_INTERNAL_APPROVAL = 'Pending Internal Approval';
export const MITIGATION_PENDING_CLIENT_APPROVAL = 'Pending Client Approval';
export const MITIGATION_PENDING_MITIGATION = 'Pending Mitigation';
export const ACCEPTED = 'Accepted';
export const CLOSED = 'Closed';


// Risk mitigation plan statuses
export const MITIGATION_DRAFT = "Draft";
export const MITIGATION_APPROVED = "Approved";
export const MITIGATION_COMPLETED = "Completed";