import { IAppAction } from './../app-action';
import { IAttachmentQuery } from 'models/attachment/attachment-query';
import { IAttachmentUpload } from 'models/attachment/attachment-upload';

export enum AttachmentActions {
    LOAD_ATTACHMENTS_INCIDENT = '@@attachment/LOAD_ATTACHMENTS_INCIDENT',
    LOAD_ATTACHMENTS_FULFILLED_INCIDENT = '@@attachment/LOAD_ATTACHMENTS_FULFILLED_INCIDENT',
    LOAD_ATTACHMENTS_REJECTED_INCIDENT = '@@attachment/LOAD_ATTACHMENTS_REJECTED_INCIDENT',

    LOAD_ATTACHMENTS_PROCESS_STEP = '@@attachment/LOAD_ATTACHMENTS_INCIDENT',
    LOAD_ATTACHMENTS_FULFILLED_PROCESS_STEP = '@@attachment/LOAD_ATTACHMENTS_FULFILLED_PROCESS_STEP',
    LOAD_ATTACHMENTS_REJECTED_PROCESS_STEP = '@@attachment/LOAD_ATTACHMENTS_REJECTED_PROCESS_STEP',

    LOAD_ATTACHMENTS_ENGINEER = '@@attachment/LOAD_ATTACHMENTS_ENGINEER',
    LOAD_ATTACHMENTS_FULFILLED_ENGINEER = '@@attachment/LOAD_ATTACHMENTS_FULFILLED_ENGINEER',
    LOAD_ATTACHMENTS_REJECTED_ENGINEER = '@@attachment/LOAD_ATTACHMENTS_REJECTED_ENGINEER',

    LOAD_ATTACHMENTS_SPARE = '@@attachment/LOAD_ATTACHMENTS_SPARE',
    LOAD_ATTACHMENTS_FULFILLED_SPARE = '@@attachment/LOAD_ATTACHMENTS_FULFILLED_SPARE',
    LOAD_ATTACHMENTS_REJECTED_SPARE = '@@attachment/LOAD_ATTACHMENTS_REJECTED_SPARE',

    LOAD_ATTACHMENTS_RISK = '@@attachment/LOAD_ATTACHMENTS_RISK',
    LOAD_ATTACHMENTS_FULFILLED_RISK = '@@attachment/LOAD_ATTACHMENTS_FULFILLED_RISK',
    LOAD_ATTACHMENTS_REJECTED_RISK = '@@attachment/LOAD_ATTACHMENTS_REJECTED_RISK',

    LOAD_ATTACHMENTS_ACTION = '@@attachment/LOAD_ATTACHMENTS_ACTION',
    LOAD_ATTACHMENTS_FULFILLED_ACTION = '@@attachment/LOAD_ATTACHMENTS_FULFILLED_ACTION',
    LOAD_ATTACHMENTS_REJECTED_ACTION = '@@attachment/LOAD_ATTACHMENTS_REJECTED_ACTION',

    LOAD_ATTACHMENTS_OUTCOME = '@@attachment/LOAD_ATTACHMENTS_OUTCOME',
    LOAD_ATTACHMENTS_FULFILLED_OUTCOME = '@@attachment/LOAD_ATTACHMENTS_FULFILLED_OUTCOME',
    LOAD_ATTACHMENTS_REJECTED_OUTCOME = '@@attachment/LOAD_ATTACHMENTS_REJECTED_OUTCOME',

    LOAD_ATTACHMENTS_MOCK_DRILL_REPORT = '@@attachment/LOAD_ATTACHMENTS_MOCK_DRILL_REPORT',
    LOAD_ATTACHMENTS_FULFILLED_MOCK_DRILL_REPORT = '@@attachment/LOAD_ATTACHMENTS_FULFILLED_MOCK_DRILL_REPORT',
    LOAD_ATTACHMENTS_REJECTED_MOCK_DRILL_REPORT = '@@attachment/LOAD_ATTACHMENTS_REJECTED_MOCK_DRILL_REPORT',

    LOAD_ATTACHMENTS_SHIFT_HANDOVER = '@@attachment/LOAD_ATTACHMENTS_SHIFT_HANDOVER',
    LOAD_ATTACHMENTS_FULFILLED_SHIFT_HANDOVER = '@@attachment/LOAD_ATTACHMENTS_FULFILLED_SHIFT_HANDOVER',
    LOAD_ATTACHMENTS_REJECTED_SHIFT_HANDOVER = '@@attachment/LOAD_ATTACHMENTS_REJECTED_SHIFT_HANDOVER',

    LOAD_ATTACHMENTS_SITE = '@@attachment/LOAD_ATTACHMENTS_SITE',
    LOAD_ATTACHMENTS_FULFILLED_SITE = '@@attachment/LOAD_ATTACHMENTS_FULFILLED_SITE',
    LOAD_ATTACHMENTS_REJECTED_SITE = '@@attachment/LOAD_ATTACHMENTS_REJECTED_SITE',

    LOAD_ATTACHMENTS_ENGINEER_REPORT = '@@attachment/LOAD_ATTACHMENTS_ENGINEER_REPORT',
    LOAD_ATTACHMENTS_ENGINEER_REPORT_FULFILLED = '@@attachment/LOAD_ATTACHMENTS_ENGINEER_REPORT_FULFILLED',
    LOAD_ATTACHMENTS_ENGINEER_REPORT_REJECTED = '@@attachment/LOAD_ATTACHMENTS_ENGINEER_REPORT_REJECTED',

    LOAD_ATTACHMENTS_GLOBAL_MOCK_DRILL = '@@attachment/LOAD_ATTACHMENTS_GLOBAL_MOCK_DRILL',
    LOAD_ATTACHMENTS_FULFILLED_GLOBAL_MOCK_DRILL = '@@attachment/LOAD_ATTACHMENTS_FULFILLED_GLOBAL_MOCK_DRILL',
    LOAD_ATTACHMENTS_REJECTED_GLOBAL_MOCK_DRILL = '@@attachment/LOAD_ATTACHMENTS_REJECTED_GLOBAL_MOCK_DRILL',

    UPLOAD_ATTACHMENT_SPARE = '@@attachment/UPLOAD_ATTACHMENT_SPARE',
    UPLOAD_ATTACHMENT_FULFILLED_SPARE = '@@attachment/UPLOAD_ATTACHMENT_FULFILLED_SPARE',
    UPLOAD_ATTACHMENT_REJECTED_SPARE = '@@attachment/UPLOAD_ATTACHMENT_REJECTED_SPARE',

    UPLOAD_ATTACHMENT_RISK = '@@attachment/UPLOAD_ATTACHMENT_RISK',
    UPLOAD_ATTACHMENT_FULFILLED_RISK = '@@attachment/UPLOAD_ATTACHMENT_FULFILLED_RISK',
    UPLOAD_ATTACHMENT_REJECTED_RISK = '@@attachment/UPLOAD_ATTACHMENT_REJECTED_RISK',

    UPLOAD_ATTACHMENT_ACTION = '@@attachment/UPLOAD_ATTACHMENT_ACTION',
    UPLOAD_ATTACHMENT_FULFILLED_ACTION = '@@attachment/UPLOAD_ATTACHMENT_FULFILLED_ACTION',
    UPLOAD_ATTACHMENT_REJECTED_ACTION = '@@attachment/UPLOAD_ATTACHMENT_REJECTED_ACTION',

    UPLOAD_ATTACHMENT_OUTCOME = '@@attachment/UPLOAD_ATTACHMENT_OUTCOME',
    UPLOAD_ATTACHMENT_FULFILLED_OUTCOME = '@@attachment/UPLOAD_ATTACHMENT_FULFILLED_OUTCOME',
    UPLOAD_ATTACHMENT_REJECTED_OUTCOME = '@@attachment/UPLOAD_ATTACHMENT_REJECTED_OUTCOME',

    UPLOAD_ATTACHMENT_INCIDENT = '@@attachment/UPLOAD_ATTACHMENT_INCIDENT',
    UPLOAD_ATTACHMENT_FULFILLED_INCIDENT = '@@attachment/UPLOAD_ATTACHMENT_FULFILLED_INCIDENT',
    UPLOAD_ATTACHMENT_REJECTED_INCIDENT = '@@attachment/UPLOAD_ATTACHMENT_REJECTED_INCIDENT',

    UPLOAD_ATTACHMENT_PROCESS_STEP = '@@attachment/UPLOAD_ATTACHMENT_PROCESS_STEP',
    UPLOAD_ATTACHMENT_FULFILLED_PROCESS_STEP = '@@attachment/UPLOAD_ATTACHMENT_FULFILLED_PROCESS_STEP',
    UPLOAD_ATTACHMENT_REJECTED_PROCESS_STEP = '@@attachment/UPLOAD_ATTACHMENT_REJECTED_PROCESS_STEP',

    UPLOAD_ATTACHMENT_MOCK_DRILL_REPORT = '@@attachment/UPLOAD_ATTACHMENT_MOCK_DRILL_REPORT',
    UPLOAD_ATTACHMENT_FULFILLED_MOCK_DRILL_REPORT = '@@attachment/UPLOAD_ATTACHMENT_FULFILLED_MOCK_DRILL_REPORT',
    UPLOAD_ATTACHMENT_REJECTED_MOCK_DRILL_REPORT = '@@attachment/UPLOAD_ATTACHMENT_REJECTED_MOCK_DRILL_REPORT',

    UPLOAD_ATTACHMENT_SHIFT_HANDOVER = '@@attachment/UPLOAD_ATTACHMENT_SHIFT_HANDOVER',
    UPLOAD_ATTACHMENT_FULFILLED_SHIFT_HANDOVER = '@@attachment/UPLOAD_ATTACHMENT_FULFILLED_SHIFT_HANDOVER',
    UPLOAD_ATTACHMENT_REJECTED_SHIFT_HANDOVER = '@@attachment/UPLOAD_ATTACHMENT_REJECTED_SHIFT_HANDOVER',

    UPLOAD_ATTACHMENT_GLOBAL_MOCK_DRILL = '@@attachment/UPLOAD_ATTACHMENT_GLOBAL_MOCK_DRILL',
    UPLOAD_ATTACHMENT_FULFILLED_GLOBAL_MOCK_DRILL = '@@attachment/UPLOAD_ATTACHMENT_FULFILLED_GLOBAL_MOCK_DRILL',
    UPLOAD_ATTACHMENT_REJECTED_GLOBAL_MOCK_DRILL = '@@attachment/UPLOAD_ATTACHMENT_REJECTED_GLOBAL_MOCK_DRILL',

    UPLOAD_ATTACHMENT_ENGINEER_REPORT = '@@attachment/UPLOAD_ATTACHMENT_ENGINEER_REPORT',
    UPLOAD_ATTACHMENT_ENGINEER_REPORT_FULFILLED = '@@attachment/UPLOAD_ATTACHMENT_ENGINEER_REPORT_FULFILLED',
    UPLOAD_ATTACHMENT_ENGINEER_REPORT_REJECTED = '@@attachment/UPLOAD_ATTACHMENT_ENGINEER_REPORT_REJECTED',

    LOAD_ATTACHMENTS_CERM_ASSESSMENT = '@@attachment/LOAD_ATTACHMENTS_CERM_ASSESSMENT',
    LOAD_ATTACHMENTS_CERM_ASSESSMENT_FULFILLED = '@@attachment/LOAD_ATTACHMENTS_CERM_ASSESSMENT_FULFILLED',
    LOAD_ATTACHMENTS_CERM_ASSESSMENT_REJECTED = '@@attachment/LOAD_ATTACHMENTS_CERM_ASSESSMENT_REJECTED',

    UPLOAD_ATTACHMENT = '@@attachment/UPLOAD_ATTACHMENT',
    UPLOAD_ATTACHMENT_FULFILLED = '@@attachment/UPLOAD_ATTACHMENT_FULFILLED',
    UPLOAD_ATTACHMENT_REJECTED = '@@attachment/UPLOAD_ATTACHMENT_REJECTED',
    UPLOAD_ATTACHMENT_CANCELLED = '@@attachment/UPLOAD_ATTACHMENT_CANCELLED',

    UPLOAD_ATTACHMENT_SITE = '@@attachment/UPLOAD_ATTACHMENT_SITE',
    UPLOAD_ATTACHMENT_FULFILLED_SITE = '@@attachment/UPLOAD_ATTACHMENT_FULFILLED_SITE',
    UPLOAD_ATTACHMENT_REJECTED_SITE = '@@attachment/UPLOAD_ATTACHMENT_REJECTED_SITE',

    DELETE_ATTACHMENT = '@@attachment/DELETE_ATTACHMENT',
    DELETE_ATTACHMENT_FULFILLED = '@@attachment/DELETE_ATTACHMENT_FULFILLED',
    DELETE_ATTACHMENT_REJECTED = '@@attachment/DELETE_ATTACHMENT_REJECTED',

    SHOW_ATTACHMENT_MODAL = '@@attachment/SHOW_ATTACHMENT_MODAL',
    HIDE_ATTACHMENT_MODAL = '@@attachment/HIDE_ATTACHMENT_MODAL',

    CLEANUP = '@@attachment/CLEANUP',
}

export const loadAttachmentsCermAssessment = (query: IAttachmentUpload): IAppAction => ({
    type: AttachmentActions.LOAD_ATTACHMENTS_CERM_ASSESSMENT,
    payload: query,
});

export const loadAttachmentsRisks = (query: IAttachmentUpload): IAppAction => ({
    type: AttachmentActions.LOAD_ATTACHMENTS_RISK,
    payload: query,
});

export const uploadAttachmentRisk = (upload: IAttachmentQuery): IAppAction => ({
    type: AttachmentActions.UPLOAD_ATTACHMENT_RISK,
    payload: upload,
});

export const loadAttachmentsAction = (query: IAttachmentUpload): IAppAction => ({
    type: AttachmentActions.LOAD_ATTACHMENTS_ACTION,
    payload: query,
});

export const uploadAttachmentAction = (upload: IAttachmentQuery): IAppAction => ({
    type: AttachmentActions.UPLOAD_ATTACHMENT_ACTION,
    payload: upload,
});

export const loadAttachmentsOutcome = (query: IAttachmentUpload): IAppAction => ({
    type: AttachmentActions.LOAD_ATTACHMENTS_OUTCOME,
    payload: query,
});

export const uploadAttachmentOutcome = (upload: IAttachmentQuery): IAppAction => ({
    type: AttachmentActions.UPLOAD_ATTACHMENT_OUTCOME,
    payload: upload,
});

export const loadAttachmentsCriticalSpare = (query: IAttachmentUpload): IAppAction => ({
    type: AttachmentActions.LOAD_ATTACHMENTS_SPARE,
    payload: query,
});

export const uploadAttachmentCriticalSpare = (upload: IAttachmentQuery): IAppAction => ({
    type: AttachmentActions.UPLOAD_ATTACHMENT_SPARE,
    payload: upload,
});

export const loadAttachmentsEngineer = (query: IAttachmentQuery): IAppAction => ({
    type: AttachmentActions.LOAD_ATTACHMENTS_ENGINEER,
    payload: query,
});

export const uploadAttachment = (upload: IAttachmentUpload): IAppAction => ({
    type: AttachmentActions.UPLOAD_ATTACHMENT,
    payload: upload,
});

export const loadAttachmentsIncident = (upload: IAttachmentUpload): IAppAction => ({
    type: AttachmentActions.LOAD_ATTACHMENTS_INCIDENT,
    payload: upload,
});

export const uploadAttachmentProcessStep = (upload: IAttachmentUpload): IAppAction => ({
    type: AttachmentActions.UPLOAD_ATTACHMENT_PROCESS_STEP,
    payload: upload,
});

export const loadAttachmentsProcessStep = (upload: IAttachmentUpload): IAppAction => ({
    type: AttachmentActions.LOAD_ATTACHMENTS_PROCESS_STEP,
    payload: upload,
});

export const uploadAttachmentMockDrillReport = (upload: IAttachmentUpload): IAppAction => ({
    type: AttachmentActions.UPLOAD_ATTACHMENT_MOCK_DRILL_REPORT,
    payload: upload,
});

export const loadAttachmentsMockDrillReport = (upload: IAttachmentUpload): IAppAction => ({
    type: AttachmentActions.LOAD_ATTACHMENTS_MOCK_DRILL_REPORT,
    payload: upload,
});

export const uploadAttachmentShiftHandover = (upload: IAttachmentUpload): IAppAction => ({
    type: AttachmentActions.UPLOAD_ATTACHMENT_SHIFT_HANDOVER,
    payload: upload,
});

export const loadAttachmentsShiftHandover = (upload: IAttachmentUpload): IAppAction => ({
    type: AttachmentActions.LOAD_ATTACHMENTS_SHIFT_HANDOVER,
    payload: upload,
});

export const uploadAttachmentSite = (upload: IAttachmentUpload): IAppAction => ({
    type: AttachmentActions.UPLOAD_ATTACHMENT_SITE,
    payload: upload,
});

export const loadAttachmentsSite = (upload: IAttachmentUpload): IAppAction => ({
    type: AttachmentActions.LOAD_ATTACHMENTS_SITE,
    payload: upload,
});

export const uploadAttachmentIncident = (upload: IAttachmentUpload): IAppAction => ({
    type: AttachmentActions.UPLOAD_ATTACHMENT_INCIDENT,
    payload: upload,
});

export const loadAttachmentsEngineerReport = (upload: IAttachmentUpload): IAppAction => ({
    type: AttachmentActions.LOAD_ATTACHMENTS_ENGINEER_REPORT,
    payload: upload,
});

export const uploadAttachmentEngineerReport = (upload: IAttachmentUpload): IAppAction => ({
    type: AttachmentActions.UPLOAD_ATTACHMENT_ENGINEER_REPORT,
    payload: upload,
});

export const loadAttachmentsGlobalMockDrill = (query: IAttachmentUpload): IAppAction => ({
    type: AttachmentActions.LOAD_ATTACHMENTS_GLOBAL_MOCK_DRILL,
    payload: query,
});

export const uploadAttachmentGlobalMockDrill = (upload: IAttachmentQuery): IAppAction => ({
    type: AttachmentActions.UPLOAD_ATTACHMENT_GLOBAL_MOCK_DRILL,
    payload: upload,
});

export const deleteAttachment = (model: IAttachmentQuery): IAppAction => ({
    type: AttachmentActions.DELETE_ATTACHMENT,
    payload: model,
});

export const showModal = (): IAppAction => ({
    type: AttachmentActions.SHOW_ATTACHMENT_MODAL,
});

export const hideModal = (): IAppAction => ({
    type: AttachmentActions.UPLOAD_ATTACHMENT_CANCELLED,
});

export const cleanupAttachment = (): IAppAction => ({
    type: AttachmentActions.CLEANUP,
});
