import * as React from 'react';
import { ToggleButton } from '../v2/components';
import { FormikProps } from 'formik';
import { IAction } from 'models/action';
import { getActionPriority } from '../action-register/shared/action-register-utils';
import { ActionDetailsFormContainer } from 'routes/actions/upsert/tabs/details/actions-details-form-container';
import { isEmpty } from 'lodash';

interface IProps {
    actionFormik: FormikProps<IAction>;
    canEdit: boolean;
}

export const CermProcessStepOutcomeActionTable: React.FC<IProps> = ({ actionFormik, canEdit }) => {
    const [showActionDetails, setShowActionDetails] = React.useState(true);

    React.useEffect(() => {
        if (actionFormik.values.id) {
            setShowActionDetails(false);
        }
    }, [actionFormik.values.id]);

    React.useEffect(() => {
        if (!isEmpty(actionFormik.errors)) {
            setShowActionDetails(true);
        }
    }, [actionFormik.isValidating]);

    return (
        <>
            <tr onClick={() => setShowActionDetails(!showActionDetails)}>
                <td className="no-wrap">
                    <strong>Action</strong>
                </td>
                <td className="is-hidden-touch">{getActionPriority(actionFormik.values)}</td>
                <td>{actionFormik.values.title}</td>
                <td className="is-hidden-touch">{actionFormik.values.category}</td>
                <td className="is-hidden-touch">{actionFormik.values.status}</td>
                <td className="narrow no-wrap is-hidden-touch">
                    {actionFormik.values.dueDate.toDateString()}
                </td>
                <td className="narrow">
                    <ToggleButton
                        isEnabled={showActionDetails && canEdit}
                        onClick={() => setShowActionDetails(!showActionDetails)}
                    />
                </td>
            </tr>
            {/* It is mandatory to use css to hide the form instead of make it not render in DOM in order for the Formik validation to work properly */}
            <tr className={showActionDetails ? '' : 'is-hidden'}>
                <td colSpan={7} className="form">
                    <ActionDetailsFormContainer formikBag={actionFormik} isReadOnly={!canEdit} />
                </td>
            </tr>
        </>
    );
};
