import React, { useState, useEffect } from 'react';
import Page from 'components/v2/page/Page';
import { Loader } from 'components/loader';
import { Button } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';
import { SelectField } from 'components/form/fields/select-field';
import { IKeyValue } from 'models';
import { ISelectOption } from 'models/select-option';
import { IPerson, IPersonAssignedItem, IUserAssignModule } from 'models/person';
import { cloneDeep } from 'lodash';
import { RouteComponentProps } from 'react-router';
import { IShiftEngineer } from 'models/shift';
import { IPersonLookup } from 'models/person-lookup';
import { IPrivilege } from 'reducers/manageRoles/manageRoles-grid';
import { isEmpty } from 'lodash';
import { buildSitePeoplePrivilegeSelectOptions } from 'utils/form-utils';
import './bulk-reassign.scss';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconColor, MaterialIconType } from 'routes/material-icon/material-icon-type';
import { AssignedItemDialog } from 'components/assignedItemDialog/assignedItem-dialog';
import { Modal } from 'components/modal';
import { replaceModuleOrRoleNameWithTranslationKey } from './bul-reassign-helper';

interface IProps extends RouteComponentProps<IParams> {
    siteId: string;
    isLoading: boolean;
    permissions: Array<IKeyValue<string>>;
    siteAllPersons: IPerson[];
    sitePersons: IPerson[];
    mockdrillSitePersons: IPersonLookup[];
    mockdrillParticipantsPrivilege: IPrivilege;
    croPersons: IPersonLookup[];
    userAssignModule: IUserAssignModule;
    personAssignedItem: IPersonAssignedItem;
    engineers: IShiftEngineer[];
    loadSiteAllPersons: (siteId: string) => void;
    loadSitePersons: (siteId: string) => void;
    loadPersonsLookup: (siteId: string) => void;
    loadPersonAssignedItem: (userAssignModules: IUserAssignModule) => void;
    loadPersonAssignModules: (id: string, siteId: string) => void;
    updatePersonAssignModules: (userAssignModules: IUserAssignModule) => void;
    loadEngineers: (siteId: string) => void;
    loadPrivilegeRoles: (id: string) => void;
    onSiteChange: (siteId: string) => void;
}

interface IParams {
    id?: string;
    siteId?: string;
}

export const BulkReassignPage: React.FC<IProps> = ({
    siteId,
    isLoading,
    siteAllPersons,
    sitePersons,
    mockdrillSitePersons,
    mockdrillParticipantsPrivilege,
    croPersons,
    userAssignModule,
    personAssignedItem,
    engineers,
    match,
    loadSiteAllPersons,
    loadSitePersons,
    loadPersonsLookup,
    loadPersonAssignedItem,
    loadPersonAssignModules,
    updatePersonAssignModules,
    loadEngineers,
    loadPrivilegeRoles,
    onSiteChange,
}) => {
    const translate = useTranslate()
    const [assignFrom, setAssignFrom] = useState<ISelectOption>()
    const [assignToSitePersonOptions, setAssignToPersonOptions] = useState<ISelectOption[]>([])
    const [assignToEngineerOptions, setAssignToEngineerOptions] = useState<ISelectOption[]>([])
    const [userModule, setUserModule] = useState<IUserAssignModule>()
    const [isUpdating, setIsUpdating] = useState(false)
    const [availableParticipants, setAvailableParticipants] = useState<ISelectOption[]>([])

    const [isShowDialog, setIsShowDialog] = useState(false)
    const [moduleName, setModuleName] = useState()
    const [roleName, setRoleName] = useState()
    const [popupDataLoaded, setPopupDataLoaded] = useState(false)

    useEffect(() => {
        if (siteId) {
            if (match.params.siteId && match.params.siteId != siteId) {
                onSiteChange(match.params.siteId)
                loadSiteAllPersons(match.params.siteId)
                loadSitePersons(match.params.siteId)
                loadEngineers(match.params.siteId)
            }
            else {
                loadSiteAllPersons(siteId)
                loadSitePersons(siteId)
                loadEngineers(siteId)
            }
        }
    }, [siteId, match.params.siteId]);

    useEffect(() => {
        loadPrivilegeRoles('MockDrillsParticipants')
        if (mockdrillSitePersons && mockdrillSitePersons.length == 0) {
            loadPersonsLookup(siteId)
        }
    }, [])

    const getCurrentAvailableparticipants = (participants) => {
        return participants?.filter(
            (people) => (participants.length === 1 || people.id !== assignFrom?.value)
        );
    };

    React.useEffect(() => {
        let persons = [...mockdrillSitePersons]
        if (!isEmpty(mockdrillSitePersons) && mockdrillParticipantsPrivilege) {
            if (mockdrillParticipantsPrivilege?.rolesInPrivilege.findIndex(x => x.value == 'CRO') > -1)
                persons = persons.concat(croPersons)
        }
        setAvailableParticipants(buildSitePeoplePrivilegeSelectOptions(persons, mockdrillParticipantsPrivilege));
    }, [mockdrillSitePersons, mockdrillParticipantsPrivilege]);

    React.useEffect(() => {
        if (!isEmpty(sitePersons)) {
            setAvailableParticipants(buildSitePeoplePrivilegeSelectOptions(getCurrentAvailableparticipants(mockdrillSitePersons), mockdrillParticipantsPrivilege));
        }
    }, [sitePersons, mockdrillSitePersons, mockdrillParticipantsPrivilege]);

    useEffect(() => {
        setUserModule(userAssignModule)
        if (userAssignModule != null) {
            setIsUpdating(false)
        }
    }, [userAssignModule]);

    useEffect(() => {
        if (match.params.id && siteAllPersons) {
            const index = siteAllPersons?.findIndex(x => x.id == match.params.id)
            if (index != -1) {
                const val = { label: siteAllPersons[index].firstName + " " + siteAllPersons[index].lastName, value: siteAllPersons[index].id }
                setAssignFrom(val)
            }
        }
    }, [siteAllPersons, match.params.id]);

    const getAssignFromPerson = () => {
        const options: ISelectOption[] = siteAllPersons?.map((person) => {
            return { label: person.firstName + " " + person.lastName, value: person.id }
        })

        return options
    }

    const getAssignToPersonOptions = (moduleName, roleName) => {
        switch (moduleName) {
            case "Shift & Gaps":
                return assignToEngineerOptions
            case "Mock Drills":
            case "ToolBox Talks":
            case "Lessons Learned":
                if (roleName == "Participants") {
                    return getAssignToMockDrillParticipant()
                }
        }
        return assignToSitePersonOptions
    }

    const loadAssignedItem = () => {
        let usrAssignModule: IUserAssignModule = {
            userId: assignFrom.value,
            siteId: match.params.siteId ?? siteId,
            assignModules: userModule?.assignModules,
            messages: []
        }
        loadPersonAssignedItem(usrAssignModule)
        setIsUpdating(true)
    }

    const getAssignToSitePerson = () => {
        const options: ISelectOption[] = sitePersons?.filter(x => x.id != assignFrom?.value).map((person) => {
            return { label: person.firstName + " " + person.lastName, value: person.id };
        })

        return options
    }

    const getAssignToEngineer = () => {
        if (typeof (engineers) == "boolean") {
            return []
        }
        const options: ISelectOption[] = engineers?.filter(x => x.userId != assignFrom?.value).map((person) => {
            return { label: person.firstName + " " + person.lastName, value: person.userId };
        })

        return options
    }

    const getAssignToMockDrillParticipant = () => {
        return availableParticipants?.sort((a: ISelectOption, b: ISelectOption) =>
            a.value === b.value ? (a.label > b.label ? 1 : -1) : (a.label > b.label ? 1 : -1))
    }
    const handleAssignFromChange = (value: any) => {
        setAssignFrom(value)
        setIsUpdating(true)
        setPopupDataLoaded(false)
    }

    const getAssignToValue = (moduleName: string, roleName: string): ISelectOption => {
        let result = null
        const moduleIndex = userModule?.assignModules?.findIndex(m => m.moduleName == moduleName)
        if (moduleIndex != -1) {
            const roleIndex = userModule?.assignModules[moduleIndex].roles?.findIndex(r => r.roleName == roleName)
            if (roleIndex != -1) {
                const userId = userModule?.assignModules[moduleIndex].roles[roleIndex].userId
                if (userId) {
                    if (userId) {
                        const userIndex = getAssignToPersonOptions(moduleName, roleName)?.findIndex(x => x.value == userId)
                        if (userIndex != -1) {
                            {
                                result = getAssignToPersonOptions(moduleName, roleName)[userIndex]
                            }
                        }
                    }
                }
            }
        }
        return result
    }

    const handleAssignToChange = (value: ISelectOption, moduleName: string, roleName: string) => {
        let newUserModule: IUserAssignModule = cloneDeep(userModule)
        const moduleIndex = newUserModule?.assignModules?.findIndex(m => m.moduleName == moduleName)
        if (moduleIndex != -1) {
            const roleIndex = newUserModule?.assignModules[moduleIndex].roles?.findIndex(r => r.roleName == roleName)
            if (roleIndex != -1) {
                newUserModule.assignModules[moduleIndex].roles[roleIndex].userId = value?.value
            }
        }
        setUserModule(newUserModule)
    }

    useEffect(() => {
        if (assignFrom) {
            let sId = match.params.siteId ?? siteId
            loadPersonAssignModules(assignFrom?.value, sId)
            setAssignToPersonOptions(getAssignToSitePerson())
            setAssignToEngineerOptions(getAssignToEngineer())
        }
    }, [assignFrom])

    const handleReassign = () => {
        let usrAssignModule: IUserAssignModule = {
            userId: assignFrom.value,
            siteId: match.params.siteId ?? siteId,
            assignModules: userModule?.assignModules,
            messages: []
        }
        updatePersonAssignModules(usrAssignModule)
        setIsUpdating(true)
        setPopupDataLoaded(false)
    }

    useEffect(() => {
        if (personAssignedItem && assignFrom && assignFrom.value) {
            setPopupDataLoaded(true)
            setIsShowDialog(true)
            setIsUpdating(false)
        }
    }, [personAssignedItem])

    const showAssignedItem = (module, role) => {
        setModuleName(module)
        setRoleName(role)
        if (popupDataLoaded === true) {
            setIsShowDialog(true)
        }
        else {
            loadAssignedItem()
        }
    }

    return (
        <Loader loading={isLoading || isUpdating}>
            <Page title={translate('BulkReassign.PageTitle')}>
                <div className="tabs-container tab-content">
                    <div className="columns">
                        <div className="column is-5 bulkColumn">
                            <div className="Field">
                                <div className="label">{translate('BulkReassign.Label.AssignFrom')}</div>
                            </div>
                        </div>
                        <div className="column is-4">
                            <SelectField
                                id="logs-upload-type-selector"
                                label={""}
                                value={assignFrom}
                                options={getAssignFromPerson()}
                                handleChange={handleAssignFromChange}
                            />
                        </div>
                    </div>
                    {assignFrom && assignFrom.value &&
                        <div className="columns">
                            <div className="column is-5">
                                <div className="Field">
                                    <div className="label">{translate('BulkReassign.AssignTo.Item')}</div>
                                </div>
                            </div>
                            <div className="column is-4">
                                <div className="Field">
                                    <div className="label">{translate('BulkReassign.AssignTo.AssignTo')}</div>
                                </div>
                            </div>
                        </div>
                    }
                    {assignFrom && assignFrom.value && userModule?.assignModules?.length == 0 &&
                        <div className="columns">
                            <div className="column is-full">
                                <div className="Field">
                                    <div className="label">{translate('SiteFeatures.Edit.Div.NoRecordFound')}</div>
                                </div>
                            </div>
                        </div>
                    }
                    {assignFrom && assignFrom.value && userModule && userModule?.assignModules?.map((m, index) => {
                        return <div key={'divRow_' + m.moduleName + index}>
                            {m.roles?.length > 0 && m.moduleName != m.roles[0].roleName &&
                                <div className="columns" key={m.moduleName + '_' + index}>
                                    <div className="column is-4 bulkColumn">
                                        <div className="Field">
                                            <div className="label">
                                                {translate(
                                                    replaceModuleOrRoleNameWithTranslationKey(m.moduleName))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                m.roles.map((r, rIndex) => {
                                    return <div className="columns" key={m + '_' + m.moduleName + '_' + r.roleName + '_' + rIndex}>
                                        <div className="column is-4 bulkColumn">
                                            <div className="Field">
                                                {
                                                    m.moduleName !== r.roleName
                                                        ? <li>
                                                            {translate(
                                                                replaceModuleOrRoleNameWithTranslationKey(r.roleName))}
                                                        </li>
                                                        : (
                                                            <div className="label">
                                                                {translate(
                                                                    replaceModuleOrRoleNameWithTranslationKey(r.roleName))
                                                                }
                                                            </div>
                                                        )
                                                }
                                            </div>
                                        </div>
                                        <div className="column is-1 bulkColumn">
                                            {m.moduleName != 'Site Roles' &&
                                                <a
                                                    title={translate('BulkReassign.Label.AssignedItems')}
                                                    onClick={() => showAssignedItem(m.moduleName, r.roleName)}
                                                >
                                                    < MaterialIcons type={MaterialIconType.Info} color={MaterialIconColor.green} />
                                                </a>
                                            }
                                        </div>
                                        <div className="column is-4">
                                            <SelectField
                                                id={"select" + m + "_" + index + "_" + rIndex}
                                                label={translate('BulkReassign.Label.AssignTo')}
                                                value={getAssignToValue(m.moduleName, r.roleName)}
                                                options={getAssignToPersonOptions(m.moduleName, r.roleName)}
                                                handleChange={(value) => handleAssignToChange(value, m.moduleName, r.roleName)}
                                            />
                                        </div>
                                    </div>
                                })}
                        </div>
                    })}
                    {userAssignModule?.assignModules &&
                        <div className="columns">
                            <div className="column is-12 bulk-assignedItem-aligh-left">
                                <Button
                                    id="reassignButton"
                                    buttonType="save"
                                    onClick={handleReassign}
                                    disabled={assignFrom && assignFrom.value && userModule?.assignModules?.length == 0}
                                >
                                    {translate('BulkReassign.Button.Reassign')}
                                </Button>
                            </div>
                        </div>
                    }
                    {assignFrom && assignFrom.value && userAssignModule?.assignModules && userModule?.messages?.length > 0 &&
                        <div className="columns">
                            <div className="column is-12">
                                <h3 className="has-text-warning margin-small">Warnings:</h3>
                                {userModule?.messages?.map(x => { return <p className="has-text-warning margin-small">{x}</p> })}
                            </div>
                        </div>
                    }
                </div>
                <Modal modalId="assignedItemDialog" isShown={isShowDialog}>
                    <AssignedItemDialog
                        personAssignedItem={personAssignedItem}
                        moduleName={moduleName}
                        roleName={roleName}
                        setIsShown={setIsShowDialog}
                    />
                </Modal>
            </Page>
        </Loader>
    )
}
