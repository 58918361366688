import { IEmeraldDisabledInput } from '../shared/disabled.input';
import { IEmeraldIdInput } from '../shared/id.input';
import { IEmeraldLabelInput } from '../shared/label.input';
import { IEmeraldOutlinedInput } from '../shared/outlined.input';
import { IEmeraldReadyOnlyInput } from '../shared/read-only.input';
import { IEmeraldRequiredInput } from '../shared/required.input';
import { IEmeraldTitleInput } from '../shared/title.input';
import {
  EmeraldDatepickerMode,
  EmeraldDatepickerModeType,
} from './datepicker.mode';

export type HourFormatType = '12' | '24';
export type ViewType = 'date' | 'month' | 'year';
export type AppendToType = 'self' | HTMLElement;
export type IconPositionType = 'left' | 'right';
export type ValueType = string | Date | Date[];

export enum EmeraldViewType {
  date = 'date',
  month = 'month',
  year = 'year',
}

export enum EmeraldHourFormat {
  h_12 = '12',
  h_24 = '24',
}

export interface PrimeDatePickerInputs {
  hourFormat?: HourFormatType;
  view?: ViewType;
  appendTo?: AppendToType;
  iconPos?: IconPositionType;
}

export class EmeraldDatepickerCoreInputs
  implements IEmeraldDatepickerCoreInputs
{
  id?: string = `datepicker-id${Math.random()}`;
  name?: string = 'datepicker-input';
  label?: string;
  title?: string;
  disabled?: boolean = false;
  placeholder?: string;
  value?: string | Date | Date[];
  visible?: boolean = false;
  viewDate?: Date;
  style?: any;
  className?: string = '';
  inline?: boolean = false;
  inputId?: string = `datepicker-input${Math.random()}`;
  inputStyle?: object;
  inputClassName?: string = '';
  isFilledContainer?: boolean = false;
  outlined?: boolean = false;
  required?: boolean = false;
  readOnly?: boolean = false;
  keepInvalid?: boolean = false;
  mask?: string = '';
  tabIndex?: number;
  showIcon?: boolean = false;
  icon?: string = 'pi pi-calendar';
  showOnFocus?: boolean = true;
  numberOfMonths?: number = 1;
  view?: ViewType = 'date';
  touchUI?: boolean = false;
  showTime? = false;
  timeOnly? = false;
  showSeconds?: boolean = false;
  showMillisec?: boolean = false;
  hourFormat?: HourFormatType = '12';
  locale?: string = '';
  stepHour?: number = 1;
  stepMinute?: number = 1;
  stepSecond?: number = 1;
  stepMillisec?: number = 1;
  shortYearCutoff?: string = '+10';
  hideOnDateTimeSelect?: boolean = false;
  showWeek?: boolean = false;
  dateFormat? = 'mm/dd/yy';
  panelStyle?: object;
  panelClassName?: string;
  disabledDates?: Date[];
  disabledDays?: number[];
  minDate?: Date;
  maxDate?: Date;
  maxDateCount?: number;
  showMinMaxRange?: boolean = false;
  showOtherMonths?: boolean = false;
  selectOtherMonths?: boolean = false;
  showButtonBar?: boolean = false;
  todayButtonClassName?: string = 'p-secondary-button';
  clearButtonClassName?: string = 'p-secondary-button';
  baseZIndex?: number = 0;
  autoZIndex?: boolean = true;
  tooltip?: any;
  tooltipOptions?: object;
  selectionMode?: EmeraldDatepickerModeType = EmeraldDatepickerMode.Single;
  showClearIcon?: boolean;
  appendTo?: AppendToType;
  iconPos?: IconPositionType;
  yearNavigator?: boolean;
  monthNavigator?: boolean;
  disableFloatLabel?: boolean;
}

export interface IEmeraldDatepickerCoreInputs
  extends IEmeraldDisabledInput,
    IEmeraldLabelInput,
    IEmeraldIdInput,
    IEmeraldTitleInput,
    IEmeraldOutlinedInput,
    IEmeraldRequiredInput,
    IEmeraldReadyOnlyInput,
    PrimeDatePickerInputs {
  value?: string | string[] | Date | Date[];
  name?: string;
  visible?: boolean;
  viewDate?: Date;
  style?: any;
  className?: string;
  inline?: boolean;
  inputId?: string;
  inputStyle?: object;
  inputClassName?: string;
  isFilledContainer?: boolean;
  keepInvalid?: boolean;
  mask?: string;
  tabIndex?: number;
  placeholder?: string;
  showIcon?: boolean;
  icon?: string;
  showOnFocus?: boolean;
  numberOfMonths?: number;
  touchUI?: boolean;
  showTime?: boolean;
  timeOnly?: boolean;
  showSeconds?: boolean;
  showMillisec?: boolean;
  locale?: string;
  stepHour?: number;
  stepMinute?: number;
  stepSecond?: number;
  stepMillisec?: number;
  shortYearCutoff?: string;
  hideOnDateTimeSelect?: boolean;
  showWeek?: boolean;
  dateFormat?: string;
  panelStyle?: object;
  panelClassName?: string;
  disabledDates?: Date[];
  disabledDays?: number[];
  minDate?: Date;
  maxDate?: Date;
  maxDateCount?: number;
  showMinMaxRange?: boolean;
  showOtherMonths?: boolean;
  selectOtherMonths?: boolean;
  showButtonBar?: boolean;
  todayButtonClassName?: string;
  clearButtonClassName?: string;
  baseZIndex?: number;
  autoZIndex?: boolean;
  tooltip?: any;
  tooltipOptions?: object;
  selectionMode?: EmeraldDatepickerMode | EmeraldDatepickerModeType;
  showClearIcon?: boolean;
  hourFormat?: HourFormatType;
  view?: ViewType;
  appendTo?: AppendToType;
  iconPos?: IconPositionType;
  yearNavigator?: boolean;
  monthNavigator?: boolean;
  disableFloatLabel?: boolean;
}

export class EmeraldDatepickerSharedInputs
  implements IEmeraldDatepickerSharedInputs
{
  selectionMode?: EmeraldDatepickerMode | EmeraldDatepickerModeType =
    EmeraldDatepickerMode.Single;
  className?: string;
  inputClassName?: string;
  dateFormat?: string = 'mm/dd/yy';
  minDate?: Date;
  maxDate?: Date;
  hourFormat?: HourFormatType;
}

export interface IEmeraldDatepickerSharedInputs {
  selectionMode?: EmeraldDatepickerMode | EmeraldDatepickerModeType;
  className?: string;
  inputClassName?: string;
  dateFormat?: string;
  minDate?: Date;
  maxDate?: Date;
  hourFormat?: HourFormatType;
}
