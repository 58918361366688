import * as React from 'react';
import { FormikProps } from 'formik';
import { IRisk, ApprovalStatus } from 'models/risk';
import { IKeyValue, IPersonSummary } from 'models';
import { IPersonLookup } from 'models/person-lookup';
import { getPersonInfo } from 'business-area-functions/incidents';
import { Panel, Button } from 'components/v2/components';
import Select from 'react-select';
import { BulmaSize } from 'enums/BulmaSize';
import { genericSort } from 'utils/sort-utils';
import { Control, Field } from 'components/form';
import { useTranslate } from 'translations/translation-utils';
import { getUserId } from 'helpers/helpers';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconColor, MaterialIconSize, MaterialIconType } from 'routes/material-icon/material-icon-type';
import * as RiskStatus from 'models/risk-statuses';
import './risk-approvals-tab.scss';

interface IProps {
    formikBag: FormikProps<IRisk>;
    lookupSites?: Array<IKeyValue<string>>;
    users: IPersonLookup[];
    isLoading: boolean;
    isReadOnly: boolean;
    canEditInExpertReview: () => boolean;
    riskAddApproverPrivilege: boolean;
}
export const RiskApprovalsTab: React.FC<IProps> = (props) => {
    const [siteUsers, setSiteUsers] = React.useState<IPersonSummary[]>([]);
    const [addedPeople, setAddedPeople] = React.useState<IPersonSummary[]>([]);
    const [addPeople, setAddPeople] = React.useState<IPersonSummary[]>([]);
    const [addedPeopleClient, setAddedPeopleClient] = React.useState<IPersonSummary[]>([]);
    const [addPeopleClient, setAddPeopleClient] = React.useState<IPersonSummary[]>([]);
    const translate = useTranslate();

    React.useEffect(() => {
        if (props.users && props.users.length !== 0) {
            const siteId = props.formikBag.values.siteId;
            const optionalApprovers = props.formikBag.values.cbreOptionalApprovers;
            const optionalClientApprovers = props.formikBag.values.clientOptionalApprovers;
            const siteUsersIds = getSiteUserIds(siteId, props.users);
            const siteUsersInfo = getPersonInfo(siteId, siteUsersIds, props.users);
            const mandatoryUsersInfo = getMandatoryUsers(
                props.formikBag,
                props.users,
                false
            );
            const mandatoryClientUsersInfo = getMandatoryUsers(
                props.formikBag,
                props.users,
                true
            );
            const usedIds = mandatoryUsersInfo
                .map((m) => m.id)
                .concat(mandatoryClientUsersInfo.map((m) => m.id))
                .concat(optionalApprovers)
                .concat(optionalClientApprovers)
                .filter((x) => x !== undefined);
            const availableUsers = siteUsersInfo.filter((f) => usedIds.indexOf(f.id) === -1);
            const addedPeople = getPersonInfo(siteId, optionalApprovers, props.users, false);
            const addedPeopleClient = getPersonInfo(
                siteId,
                optionalClientApprovers,
                props.users,
                false
            );
            setSiteUsers(availableUsers);
            setAddedPeople(addedPeople);
            setAddedPeopleClient(addedPeopleClient)
            setAddPeople([])
            setAddPeopleClient([])
        }
    }, [props.formikBag, props.users])

    const getMandatoryUsers = (
        formikBag: FormikProps<Partial<IRisk>>,
        users: any[],
        isClient: boolean
    ) => {
        const siteId = formikBag.values.siteId;
        const mandatoryUserIds = [] as string[];
        users.map((f) => {
            if (isClient) {
                if (f.clients.some(s => s === siteId) ||
                    f.clientProxy.some(s => s === siteId)) {
                    mandatoryUserIds.push(f.id);
                }
            }
            if (!isClient) {
                if (f.approvers.some((s: any) => s === siteId)) {
                    mandatoryUserIds.push(f.id);
                }
            }
        });
        return getPersonInfo(siteId, mandatoryUserIds, users, false);
    }
    const getSiteUserIds = (siteId: string, users: IPersonLookup[]) => {
        return users
            .map((f) => {
                if (f.siteIds.some((s) => s === siteId)) {
                    return f.id;
                }
            })
            .filter((x) => x !== undefined);
    }

    const updateCurrentApprovers = (value: any, isClientVersion: boolean) => {
        const people = [] as IPersonSummary[];
        value && value.map((v) => people.push(v));
        if (isClientVersion) {
            setAddPeopleClient(people);

        } else {
            setAddPeople(people)
        }
    };

    const updateFormikBagApprovers = (isClientVersion: boolean) => {
        const people = [] as string[];
        let optionalApprovers = [];
        let otherOptionalApprovers = [];
        if (isClientVersion) {
            addPeopleClient.map((v) => people.push(v.id));
            optionalApprovers = props.formikBag.values.clientOptionalApprovers?.concat(people) ?? people;
            otherOptionalApprovers = props.formikBag.values.cbreOptionalApprovers;
            props.formikBag.setFieldValue('clientOptionalApprovers', optionalApprovers);
        } else {
            addPeople.map((v) => people.push(v.id));
            optionalApprovers = props.formikBag.values.cbreOptionalApprovers?.concat(people) ?? people;
            otherOptionalApprovers = props.formikBag.values.clientOptionalApprovers;
            props.formikBag.setFieldValue('cbreOptionalApprovers', optionalApprovers);
        }
        const siteId = props.formikBag.values.siteId;
        const siteUsersIds = getSiteUserIds(siteId, props.users);
        const mandatoryUsersInfo = getMandatoryUsers(
            props.formikBag,
            props.users,
            false
        );
        const mandatoryClientUsersInfo = getMandatoryUsers(
            props.formikBag,
            props.users,
            true
        );
        const siteUsersInfo = getPersonInfo(siteId, siteUsersIds, props.users);
        const usedIds = mandatoryUsersInfo
            .map((m) => m.id)
            .concat(mandatoryClientUsersInfo.map((m) => m.id))
            .concat(optionalApprovers)
            .concat(otherOptionalApprovers)
            .filter((x) => x !== undefined);
        const availableUsers = siteUsersInfo.filter((f) => usedIds.indexOf(f.id) === -1);
        if (isClientVersion) {
            setSiteUsers(availableUsers);
            setAddedPeopleClient(getPersonInfo(siteId, optionalApprovers, props.users));
            setAddPeopleClient([]);
        } else {
            setSiteUsers(availableUsers);
            setAddedPeople(getPersonInfo(siteId, optionalApprovers, props.users));
            setAddedPeople([]);
        }
    };

    const removeUserFormikBagApprovers = (id: string, isClientVersion: boolean) => {
        const people = [] as string[];
        let optionalApprovers = [];
        let otherOptionalApprovers = [];
        if (isClientVersion) {
            addPeopleClient.map((v) => people.push(v.id));
            optionalApprovers = props.formikBag.values.clientOptionalApprovers.filter(
                (f) => f !== id
            );
            otherOptionalApprovers = props.formikBag.values.cbreOptionalApprovers;
            props.formikBag.setFieldValue('clientOptionalApprovers', optionalApprovers);
        } else {
            addPeople.map((v) => people.push(v.id));
            optionalApprovers = props.formikBag.values.cbreOptionalApprovers.filter(
                (f) => f !== id
            );
            otherOptionalApprovers = props.formikBag.values.clientOptionalApprovers;
            props.formikBag.setFieldValue('cbreOptionalApprovers', optionalApprovers);
        }
        const siteId = props.formikBag.values.siteId;
        const siteUsersIds = getSiteUserIds(siteId, props.users);
        const siteUsersInfo = getPersonInfo(siteId, siteUsersIds, props.users);
        const mandatoryUsersInfo = getMandatoryUsers(
            props.formikBag,
            props.users,
            false
        );
        const mandatoryClientUsersInfo = getMandatoryUsers(
            props.formikBag,
            props.users,
            true
        );
        const usedIds = mandatoryUsersInfo
            .map((m) => m.id)
            .concat(mandatoryClientUsersInfo.map((m) => m.id))
            .concat(optionalApprovers)
            .concat(otherOptionalApprovers)
            .filter((x) => x !== undefined);
        const availableUsers = siteUsersInfo.filter((f) => usedIds.indexOf(f.id) === -1);
        if (isClientVersion) {
            setSiteUsers(availableUsers);
            setAddedPeopleClient(getPersonInfo(siteId, optionalApprovers, props.users));
            setAddPeopleClient([]);
        } else {
            setSiteUsers(availableUsers);
            setAddedPeople(getPersonInfo(siteId, optionalApprovers, props.users));
            setAddPeople([]);
        }
    };
    const renderAddPerson = (isClientVersion: boolean, isDisabled: boolean) => {
        return isRiskActiveAndNotClose() ? (
            <div>
                <Field
                    isHorizontal={true}
                    htmlFor="addPerson"
                    label={translate('RiskApproversTab.Labels.Add')}
                    labelSize={BulmaSize.Medium}
                >
                    <Field>
                        <Control className="columns">
                            <div className="column is-9">
                                <Select
                                    id={`${'addPerson' + isClientVersion.toString()}`}
                                    isDisabled={isDisabled || !props.riskAddApproverPrivilege}
                                    options={genericSort('fullName', siteUsers?.filter(x => isClientVersion || x.id != getUserId()), true).map(
                                        (x) => ({
                                            key: x.id,
                                            label: x.fullName,
                                            value: x.id,
                                            ...x,
                                        })
                                    )}
                                    isMulti={true}
                                    onChange={(e) => updateCurrentApprovers(e, isClientVersion)}
                                    value={
                                        isClientVersion
                                            ? addPeopleClient.map((x) => ({
                                                key: x.id,
                                                label: x.fullName,
                                                value: x.id,
                                                ...x,
                                            }))
                                            : addPeople.map((x) => ({
                                                key: x.id,
                                                label: x.fullName,
                                                value: x.id,
                                                ...x,
                                            }))
                                    }
                                />
                            </div>
                            <div className="column is-2">
                                {props.canEditInExpertReview() && !props.isReadOnly && (
                                    <Button
                                        id="add-approver-button"
                                        onClick={() => updateFormikBagApprovers(isClientVersion)}
                                        disabled={
                                            !props.riskAddApproverPrivilege || isClientVersion
                                                ? addPeopleClient.length === 0
                                                : addPeople.length === 0
                                        }
                                    >
                                        {translate('RiskApproversTab.Labels.Add')}
                                    </Button>
                                )}

                            </div>
                        </Control>
                    </Field>
                </Field>
            </div>
        ) : null;
    }

    const renderPersonTable = (people: IPersonSummary[], isClientVersion: boolean): React.ReactNode => {
        const approv: any = isClientVersion
            ? props.formikBag.values.clientApproval
                ? props.formikBag.values.clientApproval
                : undefined
            : props.formikBag.values.cbreApprovalToShare
                ? props.formikBag.values.cbreApprovalToShare
                : undefined;
        const updatedPeople = people.filter((elem) => !getMandatoryUsers(
            props.formikBag,
            props.users,
            isClientVersion
        ).find(({ id }) => elem.id === id))

        const isCbreApprovalComplete = !!props.formikBag.values.cbreApprovalToShare;
        const isClientApprovalComplete = !!props.formikBag.values.clientApproval;
        return (
            <>
                <table className="table is-fullwidth is-hoverable">
                    <thead>
                        <tr>
                            <th>{translate('RiskApproversTab.Title.Name')}</th>
                            <th>{translate('RiskApproversTab.Title.Email')}</th>
                            <th>{translate('RiskApproversTab.Title.Status')}</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {props.users &&
                            getMandatoryUsers(
                                props.formikBag,
                                props.users,
                                isClientVersion
                            ).map((person) => {
                                return (
                                    <tr key={person.id}>
                                        <td>{person.firstName} {person.lastName} (
                                            {person.siteRoles
                                                .map(item =>
                                                    translate(
                                                        'RiskApproversTab.SiteRoles.',
                                                        item
                                                    )
                                                )
                                                .join(', ')})
                                        </td>
                                        <td>{person.email}</td>
                                        <td>
                                            {approv !== undefined && approv.id === person.id
                                                ? isClientVersion
                                                    ? approv.approvalStatus ===
                                                        ApprovalStatus.Accepted
                                                        ? translate(
                                                            'RiskApproversTab.Status.AcceptedRisk'
                                                        )
                                                        : approv.approvalStatus ===
                                                            ApprovalStatus.Approved
                                                            ? translate('RiskApproversTab.Status.Approved')
                                                            : translate('RiskApproversTab.Status.Unanswered')
                                                    : approv.status
                                                        ? translate(
                                                            'RiskApproversTab.Status.Approved')
                                                        : translate('RiskApproversTab.Status.Rejected')
                                                : translate('RiskApproversTab.Status.Unanswered')}
                                        </td>
                                        <td />
                                    </tr>
                                );
                            })}
                        {updatedPeople.map((person) => {
                            return (
                                <tr key={person.id}>
                                    <td>{person.firstName} {person.lastName} {
                                        person.siteRoles.length > 0
                                            ? '(' +
                                            person.siteRoles
                                                .map(item =>
                                                    translate('RiskApproversTab.SiteRoles.', item)
                                                )
                                                .join(', ') +
                                            ')'
                                            : ''}
                                    </td>
                                    <td>{person.email}</td>
                                    <td>
                                        {approv !== undefined && approv.id === person.id
                                            ? isClientVersion
                                                ? approv.approvalStatus === ApprovalStatus.Accepted
                                                    ? translate('RiskApproversTab.Status.AcceptedRisk')
                                                    : approv.approvalStatus ===
                                                        ApprovalStatus.Approved
                                                        ? translate(
                                                            'RiskApproversTab.Status.Approved'
                                                        )
                                                        : translate(
                                                            'RiskApproversTab.Status.Unanswered'
                                                        )
                                                : approv.status
                                                    ? translate(
                                                        'RiskApproversTab.Status.Approved'
                                                    )
                                                    : translate(
                                                        'RiskApproversTab.Status.Rejected'
                                                    )
                                            : translate('RiskApproversTab.Status.Unanswered')}
                                    </td>
                                    <td>
                                        {((isClientVersion && !isClientApprovalComplete) ||
                                            (!isClientVersion && !isCbreApprovalComplete)) &&
                                            !props.isReadOnly && (
                                                <a
                                                    className="icon-button"
                                                    onClick={() =>
                                                        removeUserFormikBagApprovers(
                                                            person.id,
                                                            isClientVersion
                                                        )
                                                    }
                                                >
                                                    <MaterialIcons type={MaterialIconType.Delete} color={MaterialIconColor.green} size={MaterialIconSize.medium} />
                                                </a>
                                            )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {siteUsers.length > 0 &&
                    renderAddPerson(
                        isClientVersion,
                        isClientVersion ? isClientApprovalComplete : isCbreApprovalComplete
                    )}
            </>
        );
    }

    const isRiskActiveAndNotClose = () => {
        const risk = props.formikBag.values;
        return risk && risk.isActive && risk.riskStatusId !== RiskStatus.CLOSED;
    }

    return (
        <div className="approvers-container">
            <div className="approvers-block left">
                <div>{translate('RiskApproversTab.Labels.AddApprover')}</div>
                <Panel title={translate('RiskApproversTab.Labels.CBREApprovalsList')}>
                    {renderPersonTable(addedPeople, false)}
                </Panel>
                {siteUsers.length > 0 && (
                    <div>{translate('RiskApproversTab.Labels.CBREApprovalsListNote')}</div>
                )}
            </div>
            <div className="approvers-block right">
                <br />
                <Panel title={translate('RiskApproversTab.Labels.ClientApprovalsList')}>
                    {renderPersonTable(addedPeopleClient, true)}
                </Panel>
                <div className="approvers-save-label">
                    {translate('RiskApproversTab.Labels.HowToSave')}
                </div>
            </div>
        </div>
    );
}
