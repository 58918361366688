import { FormikProps } from 'formik';
import * as React from 'react';
import { isNil } from 'lodash';
import { RouteComponentProps } from 'react-router';
import { Control, Field, Help, Input } from 'components/form';
import { Select } from 'components/select';
import { BulmaColor } from 'enums/BulmaColor';
import { BulmaSize } from 'enums/BulmaSize';
import { IKeyValue } from 'models/key-value';
import { IRiskTemplate } from 'models/question-set';
import { IProcessStep } from 'reducers/cermAssessments';
import { formikFieldCssByState, getKeyed, orderDropdownByKey } from 'utils/form-utils';
import { ICermAssessmentRouteProps } from '../../../cerm-assessments-page';
import { Button } from 'components/v2/components';
import { ISiteProfile } from 'models/site-profile';
import { ICommonLookupModel } from 'models/common-lookup-model';

export interface IProps extends RouteComponentProps<ICermAssessmentRouteProps> {
    onChangeRoot: (urlLocation: string) => void;
    onSaveRiskDetails: (processStep: Partial<IProcessStep>) => void;
    setAssessmentRisk: (processStep: Partial<IProcessStep>) => void;
    lookupRiskCategories: ICommonLookupModel[];
    lookupRiskStatus: Array<IKeyValue<string>>;
    lookupRiskLikelihoods: Array<IKeyValue<string>>;
    lookupRiskImpacts: Array<IKeyValue<string>>;
    formikBag: FormikProps<Partial<IProcessStep>>;
    site: ISiteProfile;
}

export const RiskTemplateForm: React.FC<IProps> = ({
    lookupRiskCategories,
    lookupRiskStatus,
    lookupRiskLikelihoods,
    lookupRiskImpacts,
    formikBag,
    setAssessmentRisk,
    onChangeRoot,
    site,
}) => {
    const onHandelSaveRiskDetails = () => {
        if (formikBag.isValid || (!formikBag.dirty && value('title'))) {
            setAssessmentRisk(formikBag.values);
            onChangeRoot(`/CermAssessment`);
        } else {
            formikBag.submitForm();
        }
    };

    const onHandleBack = () => {
        if (formikBag.isValid || (!formikBag.dirty && value('title'))) {
            onChangeRoot(`${formikBag.values.expectedOutcomeAnswers.length - 1}`);
        } else {
            formikBag.submitForm();
        }
    };

    function value<T extends Extract<keyof IRiskTemplate, string>>(name: T) {
        const val = getKeyed(formikBag, 'values', field(name));
        return !isNil(val) ? val : '';
    }

    const field = (name: Extract<keyof IRiskTemplate, string>) => `riskTemplate.${name}`;
    const getErrors = (name: Extract<keyof IRiskTemplate, string>) => getKeyed(formikBag, 'errors', field(name));
    const isTouched = (name: Extract<keyof IRiskTemplate, string>) => getKeyed(formikBag, 'touched', field(name));
    const getFieldCss = (name: Extract<keyof IRiskTemplate, string>, otherCss?: string) =>
        formikFieldCssByState(Boolean(getErrors(name)), isTouched(name), otherCss);

    const getHelpByKey = (name: Extract<keyof IRiskTemplate, string>) => (
        <Help isShown={formikBag.submitCount > 0 || isTouched(name)} bulmaColor={BulmaColor.Danger}>
            {getErrors(name)}
        </Help>
    );

    const riskCategories = lookupRiskCategories.filter(
        (x) => !x.operatingPlatform || x.operatingPlatform === site.operatingPlatform
    );

    return (
        <>
            <Field
                isHorizontal={true}
                htmlFor={field('title')}
                label="Full Title"
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <Input
                            htmlFor={field('title')}
                            name={field('title')}
                            aria-required="true"
                            placeholder="Risk Title"
                            type="text"
                            className={getFieldCss('title')}
                            value={value('title')}
                            onChange={formikBag.handleChange}
                            onBlur={(event) => formikBag.setFieldValue(event.target.name, event.target.value.trim())}
                        />
                    </Control>
                    {getHelpByKey('title')}
                </Field>
            </Field>

            <Field
                isHorizontal={true}
                htmlFor={field('description')}
                label="Description"
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <textarea
                            id={field('description')}
                            name={field('description')}
                            className={getFieldCss('description', 'textarea')}
                            placeholder="Detailed description of action"
                            value={formikBag.values.riskTemplate.description}
                            onChange={formikBag.handleChange}
                            onBlur={(event) => formikBag.setFieldValue(event.target.name, event.target.value.trim())}
                        />
                    </Control>
                    {getHelpByKey('description')}
                </Field>
            </Field>

            <Field
                isHorizontal={true}
                htmlFor={field('riskLikelihoodId')}
                label="Likelihood"
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <Select
                            id={field('riskLikelihoodId')}
                            name={field('riskLikelihoodId')}
                            aria-required="true"
                            options={orderDropdownByKey(lookupRiskLikelihoods)}
                            className={getFieldCss('riskLikelihoodId')}
                            value={formikBag.values.riskTemplate.riskLikelihoodId}
                            onChange={formikBag.handleChange}
                            onBlur={formikBag.handleBlur}
                        />
                    </Control>
                </Field>
            </Field>

            <Field
                isHorizontal={true}
                htmlFor={field('riskImpactId')}
                label="Impact"
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <Select
                            id={field('riskImpactId')}
                            name={field('riskImpactId')}
                            aria-required="true"
                            options={orderDropdownByKey(lookupRiskImpacts)}
                            className={getFieldCss('riskImpactId')}
                            value={formikBag.values.riskTemplate.riskImpactId}
                            onChange={formikBag.handleChange}
                            onBlur={formikBag.handleBlur}
                        />
                    </Control>
                </Field>
            </Field>

            <Field
                isHorizontal={true}
                htmlFor={field('riskCategoryId')}
                label="Category"
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <Select
                            id={field('riskCategoryId')}
                            name={field('riskCategoryId')}
                            aria-required="true"
                            options={orderDropdownByKey(riskCategories)}
                            className={getFieldCss('riskCategoryId')}
                            value={formikBag.values.riskTemplate.riskCategoryId}
                            onChange={formikBag.handleChange}
                            onBlur={formikBag.handleBlur}
                        />
                    </Control>
                </Field>
            </Field>

            <Field
                isHorizontal={true}
                htmlFor={field('riskStatusId')}
                label="Status"
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <Select
                            id={field('riskStatusId')}
                            name={field('riskStatusId')}
                            aria-required="true"
                            options={orderDropdownByKey(lookupRiskStatus)}
                            className={getFieldCss('riskStatusId')}
                            value={formikBag.values.riskTemplate.riskStatusId}
                            onChange={formikBag.handleChange}
                            onBlur={formikBag.handleBlur}
                        />
                    </Control>
                </Field>
            </Field>

            <Field
                isHorizontal={true}
                htmlFor={field('reviewDays')}
                label="Review Days"
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <Input
                            htmlFor={field('reviewDays')}
                            name={field('reviewDays')}
                            aria-required="true"
                            placeholder="Review"
                            type="number"
                            className={getFieldCss('reviewDays')}
                            value={value('reviewDays')}
                            onChange={formikBag.handleChange}
                            onBlur={(event) => formikBag.setFieldValue(event.target.name, event.target.value.trim())}
                        />
                    </Control>
                    {getHelpByKey('reviewDays')}
                </Field>
            </Field>
            <div className="action-groups">
                <div className="action-group">
                    <Button onClick={onHandleBack} buttonType="cancel">
                        Back
                    </Button>
                </div>
                <div className="action-group">
                    <Button onClick={onHandelSaveRiskDetails}>Save & Complete</Button>
                </div>
            </div>
        </>
    );
};
