import * as React from 'react';
import { ICermAssessmentResult } from 'models/cerm/cerm-assessment-result';

export const useCermAssessmentResult = (
    siteId: string,
    cermAssessmentResult: ICermAssessmentResult,
    loadCermAssessmentResult: (siteId: string) => void
) => {
    React.useEffect(() => {
        if (siteId && !cermAssessmentResult) {
            loadCermAssessmentResult(siteId);
        }
    }, [siteId, loadCermAssessmentResult]);
};
