import { IEngineer } from 'models';
import { IDifference } from 'models/difference';
import { IAttachmentModel } from 'models/attachment/attachment-model';

export interface IEngineerState {
    engineer: IEngineer;
    expandedCategories: string[];
    isLoading: boolean;
    isUploading: boolean;
    isExportingEngineer: boolean;
    isSaving: boolean;
    history: IDifference[][];
    attachments: IAttachmentModel[];
}

const defaultState: IEngineerState = {
    engineer: {
    } as IEngineer,
    expandedCategories: [],
    isLoading: false,
    isUploading: false,
    isExportingEngineer: false,
    isSaving: false,
    history: [],
    attachments: []
};

export const getDefaultEngineerState = (options?: any) => {
    return {...defaultState, ...options };
};
