import { IKeyedItem } from '../models/keyed-item';

export const getKey = ({ id, siteId }: { id: string, siteId: string }) => `${siteId}___${id}`;
export type FilteredKeyOf<T, TK> = keyof Pick<T, { [K in keyof T]: T[K] extends TK ? K : never }[keyof T]>;
export const isCreating = (item: Partial<IKeyedItem>) => !(item && item.id) ? true : false;

export const newGuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // tslint:disable-next-line: no-bitwise
      const r = Math.random() * 16 | 0;
       // tslint:disable-next-line: no-bitwise
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
};
