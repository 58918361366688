import { connect } from 'react-redux';

import { AttachmentCollectionStatic } from 'components/attachmentCollection/attachment-collection-static';
import actions from './actions';
import selector from './selector';

export const  ReviewOutcomeAttachmentCollection = connect(
    selector,
    actions
)(AttachmentCollectionStatic);
