import { getAuthenticationPlatform } from 'helpers/authentication';
import jwtDecode from 'jwt-decode';
import { DecodedToken } from 'models/decoded-token';

export const getToken = async () => {
    const token = await getAuthenticationPlatform().getAccessToken();
    return token;
};
export const logOut = () => {
    getAuthenticationPlatform().signOut();
};

export const getTokenExpiry = () => {
    const token = getAuthenticationPlatform().getAccessToken();
    const decodedToken: DecodedToken = jwtDecode(token);
    return decodedToken.exp;
};
