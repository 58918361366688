import { FormikProps } from 'formik';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import useTranslate from 'translations/translation-utils';
import { Control, Field, Help, Radio } from '../../../../components/form';
import { BulmaColor } from '../../../../enums/BulmaColor';
import { BulmaSize } from '../../../../enums/BulmaSize';
import { IQuestion } from '../../../../models/question-set';
import { formikFieldCssByState } from '../../../../utils/form-utils';
import { yesNoOptions } from '../common';
import { ICreateQuestionSetPageRouteParams } from '../create-question-set-page';

export interface IProps extends RouteComponentProps<ICreateQuestionSetPageRouteParams> {
    formikBag: FormikProps<Partial<any>>;
}

export const ToggleRiskForm: React.FC<IProps> = ({ formikBag, match }) => {
    const translate = useTranslate();
    const questionIndex = Number(match.params.questionIndex) || 0;

    const field = (name: Extract<keyof IQuestion, string>) => `questions.${questionIndex}.${name}`;

    function getKeyed<T extends Extract<keyof IQuestion, string>>(key: 'values' | 'errors', name: T): string;
    function getKeyed<T extends Extract<keyof IQuestion, string>>(key: 'touched', name: T): boolean;
    function getKeyed<T extends Extract<keyof IQuestion, string>>(key: 'values' | 'errors' | 'touched', name: T) {
        return formikBag[key] &&
            formikBag[key].questions &&
            formikBag[key].questions[questionIndex] &&
            formikBag[key].questions[questionIndex][name];
    }

    function value<T extends Extract<keyof IQuestion, string>>(name: T) {
        return getKeyed<T>('values', name) || '';
    }

    const getErrors = (name: Extract<keyof IQuestion, string>) => getKeyed('errors', name);
    const isTouched = (name: Extract<keyof IQuestion, string>) => getKeyed('touched', name);

    const getFieldCss = (name: Extract<keyof IQuestion, string>, otherCss?: string) =>
        formikFieldCssByState(Boolean(getErrors(name)), isTouched(name), otherCss);

    const getHelpByKey = (name: Extract<keyof IQuestion, string>) => (
        <Help
            isShown={formikBag.submitCount > 0 || isTouched(name)}
            bulmaColor={BulmaColor.Danger}
        >
            {getErrors(name)}
        </Help>
    );

    // tslint:disable-next-line: no-shadowed-variable
    const UpdateHasRisk = (value: any) => {
        formikBag.setFieldValue(field('hasRisk'), value.target.value.toString());
        formikBag.setFieldValue(`questions.${questionIndex}.risk`, {});
    };

    return (
        <Field
            isHorizontal={true}
            htmlFor={field('hasRisk')}
            label={translate('RiskForm.Lable.7')}
            labelSize={BulmaSize.Medium}
            className="is-vcentered"
        >
            <Field>
                <Control>
                    <Radio
                        id={field('hasRisk')}
                        name={field('hasRisk')}
                        options={yesNoOptions}
                        className={getFieldCss('hasRisk')}
                        value={`${value('hasRisk')}`}
                        onBlur={formikBag.handleBlur}
                        onChange={UpdateHasRisk}
                    />
                </Control>
                {getHelpByKey('hasRisk')}
            </Field>
        </Field>
    );
};
