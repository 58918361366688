import { connect } from 'react-redux';

import {
    IDispatchProps,
    IOwnProps,
    IStateProps,
    RiskAddLinkedActionModal as View
} from './risk-add-linked-action-modal';

import actions from './actions';
import selector from './selector';

export const RiskAddLinkedActionModal = connect<IStateProps, IDispatchProps, IOwnProps, {}>(
    selector,
    actions
)(View);
