import { connect } from 'react-redux';

import { Typeahead } from 'components/typeahead';
import actions from './actions';
import selector from './selector';

export const SiteSelector = connect(
    selector,
    actions
)(Typeahead);
