import { combineReducers } from 'redux';

import { SiteRelationshipsActions, SiteRelationshipsActionTypes } from '../../actions/site-relationships-actions';
import { IPerson } from '../../models/person';

export interface IManagePeopleState {
    people: IPerson[];
    isLoading: boolean;
    idsBeingRemoved: string[];
}

export const INITIAL_STATE: IManagePeopleState = {
    people: [],
    isLoading: false,
    idsBeingRemoved: []
};

const people = (
    state: IManagePeopleState['people'] = INITIAL_STATE.people,
    action: SiteRelationshipsActionTypes
) => {
    switch (action.type) {
        case SiteRelationshipsActions.LOAD_LINKED_PEOPLE_FULFILLED:
            return action.payload.people;

        case SiteRelationshipsActions.CHANGE_SITE_SEARCH_VALUE:
        case SiteRelationshipsActions.LOAD_LINKED_PEOPLE_CANCELLED:
        case SiteRelationshipsActions.LOAD_PERSON_SEARCH_RESULTS_REJECTED:
            return [];

        default:
            return state;
    }
};

const isLoading = (
    state: IManagePeopleState['isLoading'] = INITIAL_STATE.isLoading,
    action: SiteRelationshipsActionTypes
) => {
    switch (action.type) {
        case SiteRelationshipsActions.LOAD_LINKED_PEOPLE:
            return true;

        case SiteRelationshipsActions.CHANGE_SITE_SEARCH_VALUE:
        case SiteRelationshipsActions.LOAD_LINKED_PEOPLE_CANCELLED:
        case SiteRelationshipsActions.LOAD_LINKED_PEOPLE_FULFILLED:
        case SiteRelationshipsActions.LOAD_PERSON_SEARCH_RESULTS_REJECTED:
            return false;

        default:
            return state;
    }
};

const idsBeingRemoved = (
    state: IManagePeopleState['idsBeingRemoved'] = INITIAL_STATE.idsBeingRemoved,
    action: SiteRelationshipsActionTypes
) => {
    switch (action.type) {
        case SiteRelationshipsActions.UNLINK_PERSON:
            return [
                ...state,
                action.payload.person.id
            ];

        case SiteRelationshipsActions.UNLINK_PERSON_CANCELLED:
        case SiteRelationshipsActions.UNLINK_PERSON_FULFILLED:
        case SiteRelationshipsActions.UNLINK_PERSON_REJECTED:
            const id = action.payload.person.id;
            return state.filter((x) => x !== id);

        case SiteRelationshipsActions.CHANGE_SITE_SEARCH_VALUE:
        case SiteRelationshipsActions.LOAD_LINKED_PEOPLE:
            return [];

        default:
            return state;
    }
};

export const reducer = combineReducers<IManagePeopleState>({
    people,
    isLoading,
    idsBeingRemoved
});
