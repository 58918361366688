import { createSelector } from 'reselect';

import { IKeyedItem } from '../../../../models/keyed-item';
import { IRootActionsState } from '../../../../reducers/actions';
import { getKey } from '../../../../utils/id-utils';

const getIsExpanded = (state: IRootActionsState, props: { item: IKeyedItem }) =>
    state.actions.grid.expandedAttachments.hasOwnProperty(props.item.id);

const getIsLoading = (state: IRootActionsState, props: { item: IKeyedItem }) => {
    const match = state.actions.attachments[getKey(props.item)];
    return Boolean(match && match.isLoading);
};

const getAttachmentCollection = (state: IRootActionsState, props: { item: IKeyedItem }) => {
    const match = state.actions.attachments[getKey(props.item)];
    return (match && match.data) || [];
};

export default () => createSelector(
    getIsExpanded,
    getIsLoading,
    getAttachmentCollection,
    (isExpanded, isLoading, data) => ({
        isExpanded,
        isLoading,
        data,
        isDisabled: true,
    })
);
