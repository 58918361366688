import * as React from 'react';
import { connect } from 'react-redux';
import selectors from './selectors';
import Cookies from 'universal-cookie';
import { Alert } from 'components/v2/alert/Alert';
import { Button } from 'components/v2/button/Button';
import { IRisk } from 'models/risk';
import { checkSitePermission } from 'utils/permission-utils';
import { IKeyValue } from 'models';
import { formatDateTime } from '../../../../utils/date-utils';
import { getUserId } from 'helpers/helpers';
import useTranslate from 'translations/translation-utils';

interface IProps {
    isLoading?: boolean;
    siteId: string;
    risk: IRisk;
    permissions: Array<IKeyValue<string>>;
}

export const CbreApproval: React.FC<IProps> = (props) => {
    const [isDismiss, setIsDismiss] = React.useState(false);
    const translate = useTranslate();

    const dismiss = () => {
        const cookies = new Cookies();
        cookies.set(`${props.risk.id}dismissed`, true);
        setIsDismiss(true);
    };

    const checkIfDismissed = () => {
        const cookies = new Cookies();
        const siteCookie = cookies.get(`${props.risk.id}dismissed`);

        if (siteCookie) {
            return siteCookie;
        }

        return false;
    };

    const hasPermission = () => {
        if (props.risk.sharedWithClient && props.risk.isActive) {
            if (checkSitePermission(props.siteId, 'Approver', props.permissions)) {
                return true;
            } else if (props.risk.cbreOptionalApprovers.some((userId) => userId === getUserId())) {
                return true;
            }
        }

        return false;
    };

    return !isDismiss && hasPermission() ? (
        props.risk.cbreApprovalToShare ? (
            props.risk.cbreApprovalToShare.status ? (
                !checkIfDismissed() ? (
                    <Alert
                        type="warning"
                        title={translate('RiskRegisterUpsertPage.Approval.Title.RiskApproved')}
                    >
                        <div className="columns">
                            <div className="column">
                                {translate('RiskRegisterUpsertPage.Approval.Title.RiskApprovedBy')}{' '}
                                {props.risk.cbreApprovalToShare.fullName}{' '}
                                {translate('RiskRegisterUpsertPage.Approval.Label.RigisterOn')}{' '}
                                {formatDateTime(props.risk.cbreApprovalToShare.approvalTime)}
                            </div>
                            <div className="column is-narrow">
                                <Button
                                    buttonType="save"
                                    onClick={dismiss}
                                    isLoading={props.isLoading}
                                >
                                    {translate('RiskRegisterUpsertPage.Approval.Label.Dismiss')}
                                </Button>
                            </div>
                        </div>
                    </Alert>
                ) : null
            ) : null
        ) : null
    ) : null;
};

export default connect(selectors)(CbreApproval);
