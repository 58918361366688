import * as React from 'react';
import { Loader } from 'components/loader';
import { IRiskProfileMasterQuestionnaire, IRiskProfileQuestionSetQuestion, IRiskProfileQuestion } from 'models';
import '../engineers/engineer.scss';
import { Card, CardHeader } from '../card';
import { Timeline, TimelineHeader, TimelineHeaderGridValueCollection, TimelineHeaderGridValue, TimelineItem } from '../timeline';
import { Input } from '../form';
import { EditRiskProfileQuestionSet } from './edit-risk-profile-question-set';
import { IRiskProfileCategory } from 'models/risk-profile-questions/risk-profile-lookups';
import { EditRiskProfileQuestion } from './edit-risk-profile-question';
import { IModalCardHeaderToolbarItem } from '../card/CardHeader';
import { newGuid } from 'utils/id-utils';
import { ConfirmNavigateAwayDialog, Toolbar, ToolbarGroupRight, FloatingButton, Button } from '../v2/components';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconColor, MaterialIconType } from 'routes/material-icon/material-icon-type';
import useTranslate from 'translations/translation-utils';

export interface IRiskProfileMasterQuestionsProps {
    riskProfileMasterQuestionnaire: IRiskProfileMasterQuestionnaire;
    categories: IRiskProfileCategory[];
    isLoading: boolean;
    onSaveMasterQuestionnaire: (questionnaire: IRiskProfileMasterQuestionnaire) => void;
    onCreateMasterQuestionnaire: (questionnaire: IRiskProfileMasterQuestionnaire) => void;

}

export interface IRiskProfileMasterQuestionsState {
    questionnaire: IRiskProfileMasterQuestionnaire;
    newQuestionsText: INewQuestionsText[];
    isDirty: boolean;
    temp: boolean;
    isEditQuestionSetOpen: boolean;
    questionSetToEdit: IRiskProfileQuestionSetQuestion | null;
    newQuestionSetNumber: number | null;
    isEditQuestionOpen: boolean;
    questionToEdit: IRiskProfileQuestion | null;
    questionSetIdOfQuestionToEdit: string;
    hasAnEmptyQuestionSet: boolean;
}

export interface INewQuestionsText {
    questionSetId: string;
    questionText: string;
}

export const RiskProfileMasterQuestions: React.FC<IRiskProfileMasterQuestionsProps> = (props) => {
    const [questionnaire, setQuestionnaire] = React.useState(null);
    const [newQuestionsText, setNewQuestionsText] = React.useState([]);
    const [isDirty, setIsDirty] = React.useState(false);
    const [isEditQuestionSetOpen, setIsEditQuestionSetOpen] = React.useState(false);
    const [questionSetToEdit, setQuestionSetToEdit] = React.useState(null);
    const [newQuestionSetNumber, setNewQuestionSetNumber] = React.useState(null);
    const [isEditQuestionOpen, setIsEditQuestionOpen] = React.useState(false);
    const [questionToEdit, setQuestionToEdit] = React.useState(null);
    const [questionSetIdOfQuestionToEdit, setQuestionSetIdOfQuestionToEdit] = React.useState(null);
    const [hasAnEmptyQuestionSet, setHasAnEmptyQuestionSet] = React.useState(false);
    const translate = useTranslate();


    React.useEffect(() => {
        if (props.riskProfileMasterQuestionnaire) {
            setQuestionnaire(props.riskProfileMasterQuestionnaire);
            setNewQuestionsText(props.riskProfileMasterQuestionnaire.questionSets.map((x) =>
                ({ questionSetId: x.questionSetId, questionText: '' })))
        }
        setIsDirty(false);
    }, [props.riskProfileMasterQuestionnaire])

    const toolbarItems = (): IModalCardHeaderToolbarItem[] => [
        {
            type: 'icon', action: newQuestionSet,
            icon: MaterialIconType.Add,
            label: translate('ShiftHandoverSchedulerItem.stat.New')
        }
    ]

    const closeQuestionSetModal = () => {
        setIsEditQuestionSetOpen(false);
        setQuestionSetToEdit(null);
        setNewQuestionSetNumber(null);
    }

    const closeQuestionModal = () => {
        setIsEditQuestionOpen(false);
        setQuestionToEdit(null);
    }
    const newQuestionSet = () => {
        const newQuestionSetNumber = questionnaire.questionSets.length + 1;
        setQuestionSetToEdit(null);
        setNewQuestionSetNumber(newQuestionSetNumber);
        setIsEditQuestionSetOpen(true);
    }

    const editQuestionSet = (questionSet: IRiskProfileQuestionSetQuestion) => {
        setQuestionSetToEdit(questionSet)
        setNewQuestionSetNumber(null);
        setIsEditQuestionSetOpen(true);
    }

    const saveQuestionSet = (questionSet: IRiskProfileQuestionSetQuestion) => {
        let updatedQuestionnaire = questionnaire;
        // save an edit
        if (questionSetToEdit) {
            const indexToReplace = updatedQuestionnaire.questionSets.findIndex((x) => x.questionSetId === questionSet.questionSetId);
            updatedQuestionnaire = {
                ...updatedQuestionnaire, id: updatedQuestionnaire.id, questionSets: updatedQuestionnaire.questionSets.map((obj, index) => {
                    return index === indexToReplace ? questionSet : obj;
                })
            };
        } else { // save a new question set
            updatedQuestionnaire = { ...updatedQuestionnaire, id: updatedQuestionnaire.id, questionSets: updatedQuestionnaire.questionSets.concat(questionSet) };
            let updatedNewQuestionsText = newQuestionsText;
            const newQuestionText: INewQuestionsText = { questionSetId: questionSet.questionSetId, questionText: '' };
            updatedNewQuestionsText = newQuestionsText.concat(newQuestionText);
            setNewQuestionsText(updatedNewQuestionsText);
            setHasAnEmptyQuestionSet(true);
        }
        setQuestionnaire(updatedQuestionnaire);
        setIsEditQuestionSetOpen(false);
        setIsDirty(true);
        setQuestionSetToEdit(null);
        setNewQuestionSetNumber(null)
    }

    const deleteQuestionSet = (questionSetId: string) => {
        let updatedQuestionnaire = questionnaire;
        const indexToDelete = updatedQuestionnaire.questionSets.findIndex((x) => x.questionSetId === questionSetId);
        updatedQuestionnaire = {
            ...updatedQuestionnaire, id: updatedQuestionnaire.id, questionSets: updatedQuestionnaire.questionSets.filter((y) => y.questionSetId !== questionSetId).map((qs, index) => {
                return index > indexToDelete ?
                    { ...qs, questionOrder: qs.questionSetOrder - 1 } : qs;
            })
        };
        if (hasAnEmptyQuestionSet) {
            const hasNoEmptyQuestionSet = updatedQuestionnaire.questionSets.every((x) => x.questions.length > 0);
            setHasAnEmptyQuestionSet(!hasNoEmptyQuestionSet)
        }
        setQuestionnaire(updatedQuestionnaire);
        setIsDirty(true);
    }

    const onChangeText = (questionSetId: string, text: string) => {
        let updatedNewQuestionsText = newQuestionsText;

        const question: INewQuestionsText = { questionSetId, questionText: text };
        const indexToReplace = updatedNewQuestionsText.findIndex((x) => x.questionSetId === questionSetId);
        updatedNewQuestionsText = updatedNewQuestionsText.map((obj, index) => {
            return index === indexToReplace ? question : obj;
        });
        setNewQuestionsText(updatedNewQuestionsText);
        setIsDirty(true);
    }

    const addQuestion = (questionSetId: string) => {
        // assemble new question object
        const questionNumber = questionnaire.questionSets.find((x) => x.questionSetId === questionSetId).questions.length;
        const questionText = newQuestionsText.find((x) => x.questionSetId === questionSetId).questionText;
        const question: IRiskProfileQuestion = { questionId: newGuid(), questionOrder: questionNumber + 1, questionText };
        // add the new question to the question set in the questionnaire
        let updatedQuestionnaire = questionnaire;
        let questionSet = updatedQuestionnaire.questionSets.find((x) => x.questionSetId === questionSetId);
        questionSet = { ...questionSet, questions: questionSet.questions.concat(question) };
        const indexToReplace = updatedQuestionnaire.questionSets.findIndex((x) => x.questionSetId === questionSetId);
        updatedQuestionnaire = {
            ...updatedQuestionnaire, id: updatedQuestionnaire.id, questionSets: updatedQuestionnaire.questionSets.map((obj, index) => {
                return index === indexToReplace ? questionSet : obj;
            })
        };
        // wipe the text for the new record
        let updatedNewQuestionsText = newQuestionsText;
        const blankQuestion: INewQuestionsText = { questionSetId, questionText: '' };
        const indexToReplaceQuestions = updatedNewQuestionsText.findIndex((x) => x.questionSetId === questionSetId);
        updatedNewQuestionsText = updatedNewQuestionsText.map((obj, index) => {
            return index === indexToReplaceQuestions ? blankQuestion : obj;
        });
        if (hasAnEmptyQuestionSet) {
            const hasNoEmptyQuestionSet = updatedQuestionnaire.questionSets.every((x) => x.questions.length > 0);
            setHasAnEmptyQuestionSet(!hasNoEmptyQuestionSet)
        }
        setQuestionnaire(updatedQuestionnaire);
        setNewQuestionsText(updatedNewQuestionsText)
        setIsDirty(true)
    }

    const saveQuestion = (question: IRiskProfileQuestion, questionSetId: string) => {
        let updatedQuestionnaire = questionnaire;
        // get question set and replace this question within it
        let questionSet = updatedQuestionnaire.questionSets.find((x) => x.questionSetId === questionSetId);
        const indexToReplaceQuestion = questionSet.questions.findIndex((x) => x.questionId === question.questionId);
        questionSet = {
            ...questionSet, questions:
                questionSet.questions.map((obj, index) => {
                    return index === indexToReplaceQuestion ? question : obj;
                })
        };
        // replace the questionSet with the updated questionSet
        const indexToReplaceQuestionSet = updatedQuestionnaire.questionSets.findIndex((x) => x.questionSetId === questionSetId);
        updatedQuestionnaire = {
            ...updatedQuestionnaire, id: updatedQuestionnaire.id, questionSets: updatedQuestionnaire.questionSets.map((obj, index) => {
                return index === indexToReplaceQuestionSet ? questionSet : obj;
            })
        };
        // wipe the text for the new record
        let updatedNewQuestionsText = newQuestionsText;
        const blankQuestion: INewQuestionsText = { questionSetId, questionText: '' };
        const indexToReplaceQuestions = updatedNewQuestionsText.findIndex((x) => x.questionSetId === questionSetId);
        updatedNewQuestionsText = updatedNewQuestionsText.map((obj, index) => {
            return index === indexToReplaceQuestions ? blankQuestion : obj;
        });
        setQuestionnaire(updatedQuestionnaire);
        setNewQuestionsText(updatedNewQuestionsText);
        setIsDirty(true);
        setIsEditQuestionOpen(false);
        setQuestionSetIdOfQuestionToEdit(null)
    }

    const editQuestion = (question: IRiskProfileQuestion, questionSetId: string) => {
        setQuestionToEdit(question);
        setIsEditQuestionOpen(true);
        setQuestionSetIdOfQuestionToEdit(questionSetId);
    }

    const deleteQuestion = (questionSetId: string, questionId: string) => {
        let updatedQuestionnaire = questionnaire;
        let questionSet = updatedQuestionnaire.questionSets.find((x) => x.questionSetId === questionSetId);
        questionSet = {
            ...questionSet, questions:
                questionSet.questions.filter((y) => y.questionId !== questionId).map((x, index) =>
                    ({ questionId: x.questionId, questionText: x.questionText, questionOrder: index + 1 })
                )
        };
        const indexToReplace = updatedQuestionnaire.questionSets.findIndex((x) => x.questionSetId === questionSetId);
        updatedQuestionnaire = {
            ...updatedQuestionnaire, id: updatedQuestionnaire.id, questionSets: updatedQuestionnaire.questionSets.map((obj, index) => {
                return index === indexToReplace ? questionSet : obj;
            })
        };
        const hasNoEmptyQuestionSet = updatedQuestionnaire.questionSets.every((x) => x.questions.length > 0);
        if (!hasNoEmptyQuestionSet) {
            setHasAnEmptyQuestionSet(true);
        }
        setQuestionnaire(updatedQuestionnaire);
        setIsDirty(true);
    }

    const onSave = () => {
        const updatedQuestionnaire: IRiskProfileMasterQuestionnaire = questionnaire;
        if (updatedQuestionnaire.id)
            props.onSaveMasterQuestionnaire(updatedQuestionnaire);
        else
            props.onCreateMasterQuestionnaire(updatedQuestionnaire);
    }

    const moveQuestionSetUp = (questionSet: IRiskProfileQuestionSetQuestion) => {
        // Find item to replace and new location
        const questionSetToRelocate = questionnaire.questionSets.find((c) => c.questionSetId === questionSet.questionSetId);
        const newPosition = questionnaire.questionSets.indexOf(questionSet) - 1;
        // create new array without item
        const questionSetsWithoutRelocationItem = questionnaire.questionSets.filter((c) => c.questionSetId !== questionSet.questionSetId);
        // slot it in item
        questionSetsWithoutRelocationItem.splice(newPosition, 0, questionSetToRelocate);
        // order
        const newArrayOrdered = questionSetsWithoutRelocationItem.map((m: IRiskProfileQuestionSetQuestion, index) => {
            return {
                ...m,
                questionSetOrder: index
            };
        });
        setQuestionnaire({
            ...questionnaire,
            questionSets: newArrayOrdered
        })
        setIsDirty(true);
    }

    const moveQuestionSetDown = (questionSet: IRiskProfileQuestionSetQuestion) => {
        // Find item to replace and new location
        const questionSetToRelocate = questionnaire.questionSets.find((c) => c.questionSetId === questionSet.questionSetId);
        const newPosition = questionnaire.questionSets.indexOf(questionSet) + 1;
        // create new array without item
        const questionSetsWithoutRelocationItem = questionnaire.questionSets.filter((c) => c.questionSetId !== questionSet.questionSetId);
        // slot it in item
        questionSetsWithoutRelocationItem.splice(newPosition, 0, questionSetToRelocate);
        // order
        const newArrayOrdered = questionSetsWithoutRelocationItem.map((m: IRiskProfileQuestionSetQuestion, index) => {
            return {
                ...m,
                questionSetOrder: index
            };
        });
        setQuestionnaire({
            ...questionnaire,
            questionSets: newArrayOrdered
        })
        setIsDirty(true)
    }

    const moveQuestionUp = (questionSet: IRiskProfileQuestionSetQuestion, question: IRiskProfileQuestion) => {
        let updatedQuestionnaire = questionnaire;
        // update order for move up questionSet
        const questionToMoveUpIndex = questionSet.questions.findIndex((x) => x.questionId === question.questionId);
        question = { ...question, questionOrder: questionToMoveUpIndex };
        const questionToMoveDownIndex = questionToMoveUpIndex - 1;
        const questionToMoveDownOrder = questionToMoveUpIndex + 1; // index 0,1 = order number 1, 2
        let questionToMoveDown = questionSet.questions.find((x) => x.questionOrder === questionToMoveDownIndex + 1);
        questionToMoveDown = { ...questionToMoveDown, questionOrder: questionToMoveDownOrder };
        questionSet = {
            ...questionSet, questions: questionSet.questions.map((obj, index) => {
                return index === questionToMoveUpIndex ? questionToMoveDown :
                    index === questionToMoveDownIndex ? question : obj;
            })
        };
        // put the questionSet back in
        const questionSetIndex = updatedQuestionnaire.questionSets.findIndex((x) => x.questionSetId === questionSet.questionSetId);
        updatedQuestionnaire = {
            ...updatedQuestionnaire, id: updatedQuestionnaire.id, questionSets: updatedQuestionnaire.questionSets.map((obj, index) => {
                return index === questionSetIndex ? questionSet : obj;
            })
        };
        setQuestionnaire(updatedQuestionnaire);
        setIsDirty(true);
    }

    const moveQuestionDown = (questionSet: IRiskProfileQuestionSetQuestion, question: IRiskProfileQuestion) => {
        let updatedQuestionnaire = questionnaire;
        // update order for move down questionSet
        const questionToMoveDownIndex = questionSet.questions.findIndex((x) => x.questionId === question.questionId);
        let questionToMoveUp = questionSet.questions.find((x) => x.questionOrder === questionToMoveDownIndex + 2);
        question = { ...question, questionOrder: questionToMoveDownIndex + 2 };
        const questionToMoveUpIndex = questionToMoveDownIndex + 1;
        const questionToMoveUpOrder = questionToMoveDownIndex + 1; // index 0,1 = order number 1, 2
        questionToMoveUp = { ...questionToMoveUp, questionOrder: questionToMoveUpOrder };
        questionSet = {
            ...questionSet, questions: questionSet.questions.map((obj, index) => {
                return index === questionToMoveDownIndex ? questionToMoveUp :
                    index === questionToMoveUpIndex ? question : obj;
            })
        };
        // put the questionSet back in
        const questionSetIndex = updatedQuestionnaire.questionSets.findIndex((x) => x.questionSetId === questionSet.questionSetId);
        updatedQuestionnaire = {
            ...updatedQuestionnaire, id: updatedQuestionnaire.id, questionSets: updatedQuestionnaire.questionSets.map((obj, index) => {
                return index === questionSetIndex ? questionSet : obj;
            })
        };
        setQuestionnaire(updatedQuestionnaire);
        setIsDirty(true);
    }
    return (
        <Loader className="Questions" loading={props.isLoading}>
            {questionnaire && props.categories ? (
                <div className="risk-profile-questions">
                    {isEditQuestionSetOpen && (
                        <EditRiskProfileQuestionSet
                            questionSet={questionSetToEdit}
                            newQuestionSetNumber={newQuestionSetNumber}
                            riskProfileCategories={props.categories}
                            onSaveQuestionSet={saveQuestionSet}
                            onCancel={closeQuestionSetModal}
                        />
                    )}
                    {isEditQuestionOpen && (
                        <EditRiskProfileQuestion
                            question={questionToEdit}
                            questionSetId={questionSetIdOfQuestionToEdit}
                            onSaveQuestion={saveQuestion}
                            onCancel={closeQuestionModal}
                        />
                    )}
                    <ConfirmNavigateAwayDialog when={isDirty} />
                    <Card>
                        <CardHeader
                            widgetId={props.riskProfileMasterQuestionnaire.id}
                            title={translate('RiskProfile.MasterQuestions.Title')}
                            toolbarItems={toolbarItems()}
                        />
                        {questionnaire &&
                            questionnaire.questionSets.map((questionSet) => (
                                <div
                                    id={questionSet.questionSetId}
                                    key={questionSet.questionSetId}
                                    className="risk-profile-questions"
                                >
                                    <Timeline
                                        expanded={true}
                                        key={`${questionSet.questionSetId}__${questionSet.questionSetId}`}
                                    >
                                        <TimelineHeader centered={true} expanded={true}>
                                            <TimelineHeaderGridValueCollection>
                                                <TimelineHeaderGridValue className="timeline-title column is-2">
                                                    <div
                                                        className="is-clickable"
                                                        id={
                                                            'editQuestionSet' +
                                                            questionSet.questionSetId
                                                        }
                                                        onClick={() =>
                                                            editQuestionSet(questionSet)
                                                        }
                                                    >
                                                        {questionSet.category}
                                                    </div>
                                                </TimelineHeaderGridValue>
                                                <TimelineHeaderGridValue className="column is-8">
                                                    <div
                                                        className="is-clickable"
                                                        id={
                                                            'editQuestionSet2' +
                                                            questionSet.questionSetId
                                                        }
                                                        onClick={() =>
                                                            editQuestionSet(questionSet)
                                                        }
                                                    >
                                                        {questionSet.questionSetText}
                                                    </div>
                                                </TimelineHeaderGridValue>
                                                <TimelineHeaderGridValue className="column is-2">
                                                    <a
                                                        id={
                                                            'moveUpQuestionSet' +
                                                            questionSet.questionSetId
                                                        }
                                                        className={
                                                            questionnaire.questionSets.indexOf(
                                                                questionSet
                                                            ) > 0
                                                                ? ''
                                                                : 'hidden-link'
                                                        }
                                                        onClick={() =>
                                                            moveQuestionSetUp(questionSet)
                                                        }
                                                    >
                                                        < MaterialIcons type={MaterialIconType.ArrowUp} color={MaterialIconColor.green} />
                                                    </a>
                                                    <a
                                                        id={
                                                            'moveDownQuestionSet' +
                                                            questionSet.questionSetId
                                                        }
                                                        className={
                                                            questionnaire.questionSets.indexOf(
                                                                questionSet
                                                            ) !==
                                                                questionnaire
                                                                    .questionSets.length -
                                                                1
                                                                ? ''
                                                                : 'hidden-link'
                                                        }
                                                        onClick={() =>
                                                            moveQuestionSetDown(
                                                                questionSet
                                                            )
                                                        }
                                                    >
                                                        < MaterialIcons type={MaterialIconType.ArrowDown} color={MaterialIconColor.green} />
                                                    </a>
                                                    <a
                                                        id={
                                                            'deleteQuestionSet' +
                                                            questionSet.questionSetId
                                                        }
                                                        onClick={() =>
                                                            deleteQuestionSet(
                                                                questionSet.questionSetId
                                                            )
                                                        }
                                                    >
                                                        < MaterialIcons type={MaterialIconType.Delete} color={MaterialIconColor.green} />
                                                    </a>
                                                </TimelineHeaderGridValue>
                                            </TimelineHeaderGridValueCollection>
                                        </TimelineHeader>
                                        {questionSet.questions.length > 0 &&
                                            questionSet.questions.map((question) => (
                                                <TimelineItem key={question.questionId}>
                                                    <div className="columns">
                                                        <div
                                                            id={
                                                                'editQuestion' +
                                                                question.questionId
                                                            }
                                                            className="column is-2 is-clickable"
                                                            onClick={() =>
                                                                editQuestion(
                                                                    question,
                                                                    questionSet.questionSetId
                                                                )
                                                            }
                                                        >
                                                            {translate('QuestionnSetList.Timeline.Item')}{' '}
                                                            {question.questionOrder.toString()}
                                                        </div>
                                                        <div
                                                            id={
                                                                'editQuestion2' +
                                                                question.questionId
                                                            }
                                                            className="column is-8 is-clickable"
                                                            onClick={() =>
                                                                editQuestion(
                                                                    question,
                                                                    questionSet.questionSetId
                                                                )
                                                            }
                                                        >
                                                            {question.questionText}
                                                        </div>
                                                        <div className="column is-2">
                                                            <a
                                                                id={
                                                                    'moveUpQuestion' +
                                                                    question.questionId
                                                                }
                                                                className={
                                                                    question.questionOrder !== 1
                                                                        ? ''
                                                                        : 'hidden-link'
                                                                }
                                                                onClick={() =>
                                                                    moveQuestionUp(
                                                                        questionSet,
                                                                        question
                                                                    )
                                                                }
                                                            >

                                                                < MaterialIcons type={MaterialIconType.ArrowUp} color={MaterialIconColor.green} />
                                                            </a>
                                                            <a
                                                                id={
                                                                    'moveDownQuestion' +
                                                                    question.questionId
                                                                }
                                                                className={
                                                                    question.questionOrder !==
                                                                        questionSet.questions.length
                                                                        ? ''
                                                                        : 'hidden-link'
                                                                }
                                                                onClick={() =>
                                                                    moveQuestionDown(
                                                                        questionSet,
                                                                        question
                                                                    )
                                                                }
                                                            >

                                                                < MaterialIcons type={MaterialIconType.ArrowDown} color={MaterialIconColor.green} />
                                                            </a>
                                                            <a
                                                                id={
                                                                    'deleteQuestion' +
                                                                    question.questionId
                                                                }
                                                                onClick={() =>
                                                                    deleteQuestion(
                                                                        questionSet.questionSetId,
                                                                        question.questionId
                                                                    )
                                                                }
                                                            >
                                                                < MaterialIcons type={MaterialIconType.Delete} color={MaterialIconColor.green} />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </TimelineItem>
                                            ))}
                                        <TimelineItem key={'add-button'}>
                                            <div className="columns">
                                                <div className="column is-2">
                                                    {translate('QuestionnSetList.Timeline.Item')} {questionSet.questions.length + 1}
                                                </div>
                                                <div className="column is-8">
                                                    <Input
                                                        id={
                                                            'newQuestion' +
                                                            questionSet.questionSetId
                                                        }
                                                        placeholder={translate('RiskProfile.MasterQuestions.TypeForTheNewQ')}
                                                        onChange={(e: any) =>
                                                            onChangeText(
                                                                questionSet.questionSetId,
                                                                e.target.value
                                                            )
                                                        }
                                                        value={
                                                            newQuestionsText.length >
                                                                0
                                                                ? newQuestionsText.find(
                                                                    (x) =>
                                                                        x.questionSetId ===
                                                                        questionSet.questionSetId
                                                                ).questionText
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div className="column is-2">
                                                    <Button
                                                        id={
                                                            'newQuestionAdd' +
                                                            questionSet.questionSetId
                                                        }
                                                        disabled={
                                                            newQuestionsText.find(
                                                                (x) =>
                                                                    x.questionSetId ===
                                                                    questionSet.questionSetId
                                                            ).questionText.length === 0
                                                        }
                                                        onClick={() =>
                                                            addQuestion(
                                                                questionSet.questionSetId
                                                            )
                                                        }
                                                    >
                                                        {translate('FacilityPopup.Button.Add')}
                                                    </Button>
                                                </div>
                                            </div>
                                        </TimelineItem>
                                    </Timeline>
                                </div>
                            ))}
                    </Card>
                    <Toolbar type="save">
                        <ToolbarGroupRight>
                            <FloatingButton
                                id="save-risk-profile-questionnaire-button"
                                className="button is-medium is-primary is-pulled-right"
                                disabled={!isDirty || hasAnEmptyQuestionSet}
                                onClick={onSave}
                                float={isDirty}
                                tooltip={translate('Globals.Label.PleaseClick')}
                            >
                                {translate('Globals.Label.Save')}
                            </FloatingButton>
                        </ToolbarGroupRight>
                    </Toolbar>
                </div>
            ) : (
                <p> {translate('CompetencyDnaProcess.Label.16')}</p>
            )}
        </Loader>
    );
}
