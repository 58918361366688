import _ from 'lodash';

export const genericSort = (key: Extract<keyof any, string>, items: any[], isAscending: boolean) => {
    if (isAscending) {
        return items.sort((a, b) => {
            if (a[key] < b[key]) { return -1; }
            if (a[key] > b[key]) { return 1; }
            return 0;
        });
    } else {
        return items.sort((a, b) => {
            if (a[key] > b[key]) { return -1; }
            if (a[key] < b[key]) { return 1; }
            return 0;
        });
    }
};

export const getItemStringInLowerCase = (item: any, key: string) => {
    const value = _.get(item, key);
    return value ? value.toString().toLocaleLowerCase() : '';
};

export const sortArrayBy = (key: Extract<keyof any, string>, items: any[], isAscending: boolean) => {
    return _.orderBy(items, [(item) => getItemStringInLowerCase(item, key)], [isAscending ? 'asc' : 'desc']);
};
