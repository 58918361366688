import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { logsUpload, downloadTemplate, resetLogs } from 'actions/actions-v2/logs-upload-actions';
import { LogsUploadPage } from './log-upload-page';
import { getSites } from 'components/sideNav/selectors';
import { loadSite } from 'actions/actions-v2/site-actions-v2';

const getIsLoading = (state: IRootState) => state.logsUploadState.isLoading;
const getResults = (state: IRootState) => state.logsUploadState.results;
const getPermissions = (state: IRootState) => state.app.permissions;
const getsiteData = (state: IRootState) => state.siteState.site;

const mapStateToProps = createSelector(
    getSites,
    getIsLoading,
    getResults,
    getPermissions,
    getsiteData,
    (lookupSites, isLoading, templateValidationSummary, permissions,site) => ({
        lookupSites,
        isLoading,
        templateValidationSummary,
        permissions,
        site
    })
);

const mapDispatchToProps = {
    logsUpload,
    downloadTemplate,
    resetLogs,
    loadSite
};

export const LogsUploadPageContainer = connect(mapStateToProps, mapDispatchToProps)(LogsUploadPage);
