import * as React from 'react';
import classNames from 'classnames';

interface IProps extends React.TdHTMLAttributes<HTMLTableCellElement> {
    type?: 'default' | 'ellipsis' | 'nowrap';
}

export const TableTd: React.FC<IProps> = ({ type = 'default', className, children, ...props }) => {
    let tdContent = children;
    let tdClassName = className;
    switch (type) {
        case 'ellipsis':
            tdClassName = classNames(className, 'ellipsis');
            tdContent = <span>{children}</span>;
            break;

        case 'nowrap':
            tdClassName = classNames(className, 'no-wrap');
            break;
    }
    return (
        <td className={tdClassName} {...props}>
            {tdContent}
        </td>
    );
};
