import moment from 'moment';
import { enumEnergyBillPeriods } from 'models/building';
import { IEnergyBill } from 'models/energy-bill';
import { IDataLoadProjection } from 'models/pue-benchmarks';
import { enumBenchmarks, enumQuarters } from 'models/pue-benchmarks-enums';

export const getQuartersListFromActuals = (energyBills: IEnergyBill[]) => {
    const y = filterUniqueDates(energyBills.map((x) => moment(x.date).startOf('quarter').utc().toDate()));
    // creating a new set will remove duplicates from the array of quarters leaving only unique values
    return y;
};

export function filterUniqueDates(data: Date[]) {
    const lookup = new Set();
    return data.filter((date) => {
       const serialised = date.getTime();
       if (lookup.has(serialised)) {
        return false;
      } else {
        lookup.add(serialised);
        return true;
      }
    });
  }

export const getLatestQuarter = (energyBills: IEnergyBill[]) => {
    const z = getQuartersListFromActuals(energyBills);
    const z2 = z.slice(-1);
    const z3 = z2[0];
    return z3;
  };

export const getActualsValueForQuarter = (energyBills: IEnergyBill[], quarter: Date) => {
    // tslint:disable-next-line:max-line-length
    const actualsPeriods = energyBills.filter((x) => moment(x.date).startOf('quarter').isSame(moment(quarter)))
        // tslint:disable-next-line:max-line-length
        .map((z) => z.itUsage ? Number(z.electricityAmount.toString().replace(',', '')) / Number(z.itUsage.toString().replace(',', '')) : 0);
    return Math.round((actualsPeriods.reduce((a, b) => a + b, 0) / actualsPeriods.length) * 100) / 100;
};

// tslint:disable-next-line:max-line-length
export const getBenchmarkValueForQuarter = (actualUtilisationRate: number, pueProjections: IDataLoadProjection, benchmarkType: enumBenchmarks, quarterName: enumQuarters) => {
    const percentages: number[] = getUtilisationComparisonPercentages(actualUtilisationRate);
    if (percentages.length === 1) {
         // use single value
    return Math.round(getBenchmarkDataValue(pueProjections, benchmarkType, percentages[0], quarterName) * 100) / 100;
    } else {
         // interpolate two values
        return getInterpolatedValue(percentages[0], percentages[1], actualUtilisationRate,
            getBenchmarkDataValue(pueProjections, benchmarkType, percentages[0], quarterName),
            getBenchmarkDataValue(pueProjections, benchmarkType, percentages[1], quarterName));
    }
};

export const getUtilisationComparisonPercentages = (percentage: number) => {
    const percentages = [0.25, 0.5, 0.75, 1];
    let result: any = [];
    percentages.some((a) => {
        if (a > percentage) {
            return result.push(a);
        }
        result = [a];
        return a === percentage;
    });
    return result;
};

export const getBenchmarkDataValue = (
    pueProjections: IDataLoadProjection,
    // tslint:disable-next-line:variable-name
    _benchmarkType: enumBenchmarks,
    percentage: number,
    quarterName: enumQuarters) => {
        // tslint:disable-next-line:max-line-length
        return pueProjections.loadProjection.find((t: any) => t.itLoad === percentage).projection.quarterly[quarterName];
};

export const getInterpolatedValue = (
        lowerValueInput: number, higherValueInput: number, actualValueInput: number,
        lowerValueOutput: number, higherValueOutput: number) => {
        return Math.round(((((actualValueInput - lowerValueInput) * (higherValueOutput - lowerValueOutput))
        / (higherValueInput - lowerValueInput)) + lowerValueOutput) * 100) / 100;
};

// tslint:disable-next-line:max-line-length
// used to determine the quality of data - less than one month = sktechy data, 1-2 months = weak data, 2+ months = solid data
// tslint:disable-next-line:max-line-length
export const getNumberMonthsOfDataInQuarter = (energyBills: IEnergyBill[], energyBillPeriods: enumEnergyBillPeriods, quarter: Date) => {
    // tslint:disable-next-line:max-line-length
    const actualsMonths = energyBills.filter((x) =>
        (moment(x.date).startOf('quarter').isSame(
            moment(quarter)) && energyBillPeriods === enumEnergyBillPeriods.month)).length;
    const actualsWeeks = energyBills.filter((x) =>
        (moment(x.date).startOf('quarter').isSame(
            moment(quarter)) && energyBillPeriods === enumEnergyBillPeriods.week)).length;
    const numberMonthsOfData: number = actualsMonths + (actualsWeeks / 4);
    return numberMonthsOfData;
};

export const getActualsUtilisationValue = (energyBills: IEnergyBill[], quarter: Date, capacity: number) => {
    // tslint:disable-next-line:max-line-length
    const actualsValues = energyBills.filter((x) => moment(x.date).startOf('quarter').isSame(moment(quarter))).map((y) => Number(y.itUsage));
    const itAverage: number = averageValues(actualsValues);
    return capacity ? itAverage / (capacity) : 0;
};

export const averageValues = (data: number[]) => {
    return data.reduce((a, b) => a + b, 0) / data.length;
};

export const getWeekNumberFromWeekStartDate = (date: Date): number => {
    return moment(date).isoWeek();
};
