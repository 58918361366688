import { combineEpics, Epic, ofType } from 'redux-observable';
import { ajax, AjaxError } from 'rxjs/ajax';
import { catchError, switchMap, flatMap } from 'rxjs/operators';
import { NavActions, NavActionTypes } from 'actions/nav-actions';
import { getApiHeaders } from './shared-header';
import { getErrorActions$ } from './epic-helpers';
import appConfig from 'helpers/config-helper';

const config = appConfig();

const apiUrl = config.REACT_APP_BASE_API;
const markAllReadEpic: Epic<NavActionTypes> = (action$) =>
    action$.pipe(
        ofType(NavActions.MARK_ALL_READ),
        switchMap(() =>
            ajax.post(`${apiUrl}/api/notification/MarkAllRead`, {}, getApiHeaders()).pipe(
                flatMap<any, NavActionTypes>(() => [
                    {
                        type: NavActions.MARK_ALL_READ_FULFILLED,
                    },
                ]),
                catchError<any, NavActionTypes>((error: AjaxError) =>
                    getErrorActions$('Notifications')(NavActions.MARK_ALL_READ_REJECTED, error, {
                        errorMessage: 'Unable to mark all as read',
                    })
                )
            )
        )
    );

export const navEpics = combineEpics(markAllReadEpic);
