import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { getCurrentSelectedSiteId } from 'sharedSelectors';

const getPermissions = (state: IRootState) => state.app.permissions;
const getAssetState = (state: IRootState) => state.assetState;

export default createSelector(
    getCurrentSelectedSiteId,
    getPermissions,
    getAssetState,
    (siteId, permissions, assetState) => ({
        siteId,
        permissions,
        manufacturers: assetState.assetManufacturers,
        models: assetState.assetModels
    })
);
