import { uniqueId } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { INotification } from 'models/notification';
import './Nav.scss';
import { NavItem } from './NavItem';
import { NavLink as QuantumNavLink } from './NavLink';
import { getPathName, isInternalRoute } from 'utils/string-utils';
import { onRouteChange } from 'actions/app-actions';
import appConfig from 'helpers/config-helper';
import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { refreshECermToken } from 'actions/actions-v2/cerm-assessment-action-v3';
import { connect } from 'react-redux';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconColor, MaterialIconSize, MaterialIconType } from 'routes/material-icon/material-icon-type';
import useTranslate from 'translations/translation-utils';

const getECermToken = createSelector((state: IRootState) => state,
    (state) => {
        return state.cermAssessmentResultState.eCermToken;
    }
);

const selectors = createSelector(
    getECermToken,
    (ecermToken) => ({
        ecermToken
    })
);

const actions = {
    getECermToken: refreshECermToken,
};


export interface IProps extends React.AllHTMLAttributes<HTMLDivElement> {
    linkElement: any;
    notifications: INotification[];
    ecermToken: string;
    onNotificationRead: (id: string, notificationLink: string) => void;
    onMarkAllRead: () => void;
    getECermToken: () => void;
}

const NotificationNavDropdownComponent: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const divRef = React.useRef<HTMLDivElement>(null);
    const [isActive, setIsActive] = React.useState(false);
    const getIcon = (notification: INotification) => {
        switch (notification.icon) {
            case 'user':
                return MaterialIconType.Person;
            case 'warning':
                return MaterialIconType.Warning;
            default:
                return MaterialIconType.Info;
        }
    };

    React.useEffect(() => {
        if (props.ecermToken == null || props.ecermToken == '')
            props.getECermToken();
    }, [props.ecermToken]);

    const onClickLink = (e, notification: INotification) => {
        e.stopPropagation();
        if (isInternalRoute(notification.link)) {
            const pathname = getPathName(notification.link);
            onRouteChange(pathname);
        } else {
            const ecermUrl = appConfig().REACT_APP_ECERM_URL.toLowerCase();
            if (notification.link.toLocaleLowerCase().startsWith(ecermUrl)) {
                window.open(`${notification.link}`, '_self');
            }
            else {
                window.location.href = notification.link;
            }
        }
        setIsActive(false)
        props.onNotificationRead(notification.id, notification.link);
    };
    React.useEffect(() => {
        document.addEventListener('click', handleClickOutside, false);
        return () => {
            document.removeEventListener('click', handleClickOutside, false);
        };
    });
    const handleClickOutside = (event) => {
        if (divRef.current && !divRef.current.contains(event.target)) {
            setIsActive(false);
        }
    }
    const onMarkAllRead = (e) => {
        e.stopPropagation();
        setIsActive(false);
        props.onMarkAllRead();
    }
    const onSeeAllNotification = (e) => {
        e.stopPropagation();
        setIsActive(false);
        onRouteChange(`/notifications/`);
    }
    const translate = useTranslate();
    return (
        <div className="notification-top-container" onClick={() => setIsActive(true)} ref={divRef}>
            <NavItem hasContainer={true} hasDropdown={true} className={props.className} link={'/'} >
                <QuantumNavLink linkElement={props.linkElement} />
                <div className={`navbar-dropdown notifications-container ${isActive ? 'is-active' : ''}`}>
                    <div className="notification-top-container">
                        <h2 className="notifications-title">{translate('NavDropDown.Notifications')}</h2>
                        <button
                            onClick={onMarkAllRead}
                            className="mark-read-button button is-text is-medium"
                            disabled={props.notifications.every((s) => s.read)}
                        >
                            {translate('NavDropDown.MarkAsRead')}
                        </button>
                    </div>
                    <ul className="notifications">
                        {props.notifications.map((notification) => {
                            return (
                                <li
                                    className={`notification-media ${notification.read ? '' : ' unread'
                                        }`}
                                    key={uniqueId(notification.id)}
                                >
                                    <a
                                        className="navbar-item is-hoverable"
                                        ///  href={isSafe(notification.link) ? notification.link : '#'}
                                        // tslint:disable-next-line: jsx-no-lambda
                                        onClick={(e) => onClickLink(e, notification)}
                                    >
                                        <div className="notification-media-left">
                                            <div className="notification-icon">
                                                < MaterialIcons type={getIcon(notification)} color={MaterialIconColor.grey} size={MaterialIconSize.medium} />
                                            </div>
                                        </div>
                                        <div className="notification-media-body">
                                            <div className="notification-media-heading ellipsis">
                                                {notification.title}
                                            </div>
                                            <div className="notification-media-text block-ellipsis">
                                                {notification.messageBody}
                                            </div>
                                        </div>
                                        <div className="notification-media-right">
                                            <div className="notification-media-time text-muted f-s-11">
                                                <span
                                                    className={`dot ${notification.read ? 'dot-read' : 'dot-unread'
                                                        }`}
                                                />
                                                <br />
                                                <span>
                                                    {moment(notification.created).utc().fromNow()}
                                                </span>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            );
                        })}
                        <li className="dropdown-footer text-center">
                            <div className="view-all-section">
                                {props.notifications.length === 0 && (
                                    <div className="is-centre no-notifications is-text">
                                        <div>{translate('NavDropDown.NoNotifications')}</div>
                                        < MaterialIcons type={MaterialIconType.CheckCircle} color={MaterialIconColor.grey} size={MaterialIconSize.medium} />
                                    </div>
                                )}
                                {props.notifications.length > 0 && (
                                    <a
                                        className="button is-primary is-centre"
                                        onClick={onSeeAllNotification}
                                    >
                                        {translate('NavDropDown.SeeAllNotifications')}
                                    </a>
                                )}
                            </div>
                        </li>
                    </ul>
                </div>
            </NavItem>
        </div>
    );
};

const NotificationNavDropdown = connect(selectors, actions)(NotificationNavDropdownComponent);
export default NotificationNavDropdown;