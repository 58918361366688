import * as React from 'react';
import Attachments from 'components/attachemnts/attachments';
import { Entity } from 'models/entity';
import { IAttachmentQuery } from 'models/attachment/attachment-query';
import { IAttachmentModel } from 'models/attachment/attachment-model';

interface IProps {
    id: string;
    attachments: IAttachmentModel[];
    loadAttachments: (query: IAttachmentQuery) => void;
}

export const MockDrillReportScenarioAttachmentsTab: React.FC<IProps> = (props) => {
    return (
        <div className="tabs-container tabs-container-table-only">
            <Attachments
                id={props.id}
                entity={Entity.GlobalMockDrill}
                attachments={props.attachments}
                loadAttachments={props.loadAttachments}
                isReadOnly={true}
            />
        </div>
    );
};
