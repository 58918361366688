import React from 'react';
import { ClientSharedStatus, IRisk, RiskLevelIdSortable } from 'models/risk';
import useTranslate from 'translations/translation-utils';
import * as RiskStatus from 'models/risk-statuses';
import { getRiskReviewDays } from './shared/risk-register-utils';

interface IProps {
    risks: IRisk[];
    isClientView: boolean;
}

export const RiskRegisterOverview: React.FC<IProps> = ({ risks, isClientView }) => {
    const translate = useTranslate();

    return (
        <div>
            {risks && (risks.length > 0 || isClientView) && (
                <div className="columns risk-overview-row">
                    <div className="column is-8">
                        <table className="risk-overview-count ">
                            <tbody>
                                <tr>
                                    <td className="risk-impact-width">
                                        {translate('RiskRegisterPage.Overview.Status.High')}:
                                        <mark className="high risk-overview-status">
                                            {
                                                risks?.filter(
                                                    x =>
                                                        x.riskLevelIdSortable ===
                                                        RiskLevelIdSortable.High
                                                ).length
                                            }
                                        </mark>
                                    </td>
                                    <td className="risk-impact-width">
                                        {translate('RiskRegisterPage.Overview.Status.Medium')}:
                                        <mark className="medium risk-overview-status">
                                            {
                                                risks?.filter(
                                                    x =>
                                                        x.riskLevelIdSortable ===
                                                        RiskLevelIdSortable.Medium
                                                ).length
                                            }
                                        </mark>
                                    </td>
                                    <td className="risk-impact-width">
                                        {translate('RiskRegisterPage.Overview.Status.Low')}:
                                        <mark className="low risk-overview-status">
                                            {
                                                risks?.filter(
                                                    x =>
                                                        x.riskLevelIdSortable ===
                                                        RiskLevelIdSortable.Low
                                                ).length
                                            }
                                        </mark>
                                    </td>
                                    <td className="risk-pipe">|</td>
                                    <td className="risk-pipe-text">
                                        {translate(
                                            'RiskRegisterPage.Overview.Count.RisksInMitigation'
                                        )}{':  '}
                                        <b>
                                            {
                                                risks?.filter(
                                                    x =>
                                                        x.riskStatusId ===
                                                        RiskStatus.MITIGATION_PENDING_INTERNAL_APPROVAL ||
                                                        x.riskStatusId ===
                                                        RiskStatus.MITIGATION_PENDING_CLIENT_APPROVAL ||
                                                        x.riskStatusId ===
                                                        RiskStatus.MITIGATION_PENDING_MITIGATION
                                                ).length
                                            }
                                        </b>
                                    </td>
                                    {!isClientView && (
                                        <>
                                            <td className="risk-pipe">|</td>
                                            <td className="risk-pipe-text">
                                                {translate(
                                                    'RiskRegisterPage.Overview.Count.RisksAcceptedByClient'
                                                )}{':  '}
                                                <b>
                                                    {
                                                        risks?.filter(
                                                            x =>
                                                                x.clientSharedStatus ===
                                                                ClientSharedStatus.ExternalConfirmed &&
                                                                x.riskStatusId ===
                                                                RiskStatus.ACCEPTED
                                                        ).length
                                                    }
                                                </b>
                                            </td>
                                        </>
                                    )}
                                    <td className="risk-pipe">|</td>
                                    <td className="risk-pipe-text">
                                        {translate(
                                            'RiskRegisterPage.Overview.Count.RisksPastReviewDate'
                                        )}
                                        {':  '}
                                        <b>
                                            {
                                                risks?.filter(
                                                    x => x.reviewDate && getRiskReviewDays(x) < 0
                                                ).length
                                            }
                                        </b>
                                    </td>
                                    <td className="risk-pipe">|</td>
                                    <td className="risk-pipe-text">
                                        {translate('RiskRegisterPage.Overview.Count.RisksComingUp')}
                                        {':  '}
                                        <b>
                                            {
                                                risks?.filter(
                                                    x =>
                                                        x.reviewDate &&
                                                        getRiskReviewDays(x) >= 0 &&
                                                        getRiskReviewDays(x) < 7
                                                ).length
                                            }
                                        </b>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
};
