import * as React from 'react';
import { Entity } from 'models/entity';
import { IAttachmentQuery } from 'models/attachment/attachment-query';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import selectors from './selectors';
import actions from './actions';
import { connect } from 'react-redux';
import Documents from 'components/documents/documents';

interface IProps {
    id: string;
    loadAttachments: (query: IAttachmentQuery) => void;
    attachments: IAttachmentModel[];
}

const ClientRiskMitigationDocumentsTab: React.FC<IProps> = (props) => {
    return (
        <div className="tabs-container tabs-container-table-only">
            <Documents
                entity={Entity.RiskMitigationPlan}
                attachments={props.attachments.filter(x => x.id.includes(props.id))}
                loadAttachments={props.loadAttachments}
                uploadAttachment={null}
                id={props.id}
                isReadOnly={true}
                dontLoadAutomatically={true}
            />
        </div>
    );
};
export default connect(selectors, actions)(ClientRiskMitigationDocumentsTab);
