import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import actions from './actions';
import selector from './selector';
import { UpsertSitePage as View } from './upsert-site-page';

export const UpsertSitePage = withRouter(connect(
    selector,
    actions
)(View));
