import { combineReducers } from 'redux';
import { OpportunityQuestionsActions } from 'actions/actions-v2/opportunity-action';
/* import { IOpportunity } from 'models/opportunity-question-set'; */
import { IOpportunityState } from 'state/opportunity-state';
import { IAppAction } from 'actions/app-action';
import { IOpportunity } from 'models/opportunity-question-set';

export const INITIAL_STATE: IOpportunityState = {
    isLoading: false,
    opportunityQuestionsList: [],
    opportunity: {} as IOpportunity,
};

const isLoading = (state: boolean = INITIAL_STATE.isLoading, action: IAppAction) => {
    switch (action.type) {
        case OpportunityQuestionsActions.LOAD_OPPORTUNITY_QUESTIONS:
        case OpportunityQuestionsActions.CREATE_OPPORTUNITY_QUESTIONS:
        case OpportunityQuestionsActions.UPDATE_OPPORTUNITY_QUESTIONS:
        case OpportunityQuestionsActions.DELETE_OPPORTUNITY_QUESTIONS:
            return true;

        case OpportunityQuestionsActions.LOAD_OPPORTUNITY_QUESTIONS_FULFILLED:
        case OpportunityQuestionsActions.CREATE_OPPORTUNITY_QUESTIONS_FULFILLED:
        case OpportunityQuestionsActions.UPDATE_OPPORTUNITY_QUESTIONS_FULFILLED:
        case OpportunityQuestionsActions.DELETE_OPPORTUNITY_QUESTIONS_FULFILLED:
            return false;

        default:
            return state;
    }
};

const opportunity = (
    state: IOpportunityState['opportunity'] = INITIAL_STATE.opportunity,
    action: IAppAction
) => {
    switch (action.type) {
        case OpportunityQuestionsActions.LOAD_OPPORTUNITY_QUESTIONS_REJECTED:
            return state;

        case OpportunityQuestionsActions.LOAD_OPPORTUNITY_QUESTIONS_FULFILLED:
            return action.payload ? action.payload : state;

        case OpportunityQuestionsActions.CLEAN_UP_OPPORTUNITY:
            return INITIAL_STATE.opportunity;

        default:
            return state;
    }
};

const opportunityQuestionsList = (
    state: IOpportunityState['opportunityQuestionsList'] = INITIAL_STATE.opportunityQuestionsList,
    action: IAppAction
) => {
    switch (action.type) {
        case OpportunityQuestionsActions.LOAD_OPPORTUNITY_QUESTIONS_LIST_REJECTED:
            return INITIAL_STATE.opportunityQuestionsList;

        case OpportunityQuestionsActions.LOAD_OPPORTUNITY_QUESTIONS_LIST_FULFILLED:
            return action.payload ? action.payload : state;

        /*   case OpportunityQuestionsActions.DELETE_FULFILLED:
            if (action.payload) {
                return state.filter((questionSet) => questionSet.id !== action.payload);
            }

            return state; */

        default:
            return state;
    }
};

export const opportunityReducer = combineReducers<IOpportunityState>({
    isLoading,
    opportunityQuestionsList,
    opportunity,
});
