import { Epic, combineEpics } from 'redux-observable';
import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { OpportunityQuestionnaireActions } from 'actions/actions-v2/opportunity-questionnaire-actions';
import { IAction } from 'models/action';

const controllerName = '/api/OpportunityQuestionnaire';

const getAllActions = {
    action: OpportunityQuestionnaireActions.LOAD_OPPORTUNITY_QUESTIONNAIRE,
    actionFulfilled: OpportunityQuestionnaireActions.LOAD_OPPORTUNITY_QUESTIONNAIRE_FULFILLED,
    actionRejected: OpportunityQuestionnaireActions.LOAD_OPPORTUNITY_QUESTIONNAIRE_REJECTED,
} as IActionType;

const createOpportunityQuestionsActions = {
    action: OpportunityQuestionnaireActions.CREATE_OPPORTUNITY_ANSWERS,
    actionFulfilled: OpportunityQuestionnaireActions.CREATE_OPPORTUNITY_ANSWERS_FULFILLED,
    actionRejected: OpportunityQuestionnaireActions.CREATE_OPPORTUNITY_ANSWERS_REJECTED,
} as IActionType;

const updateOpportunityQuestionsActions = {
    action: OpportunityQuestionnaireActions.UPDATE_OPPORTUNITY_ANSWERS,
    actionFulfilled: OpportunityQuestionnaireActions.UPDATE_OPPORTUNITY_ANSWERS_FULFILLED,
    actionRejected: OpportunityQuestionnaireActions.UPDATE_OPPORTUNITY_ANSWERS_REJECTED,
} as IActionType;

const loadOpportunityQuestionsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getAllActions).get<IAction[]>(controllerName);

const createOpportunityAnswersEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createOpportunityQuestionsActions).post<IAction>(controllerName);

const updateOpportunityAnswersEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateOpportunityQuestionsActions).put<IAction>(controllerName);

export const opportunityQuestionnaireEpics = combineEpics(
    loadOpportunityQuestionsEpic,
    createOpportunityAnswersEpic,
    updateOpportunityAnswersEpic
);
