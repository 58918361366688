import { IAppAction } from '../app-action';
import { IShift, IShiftFilters } from 'models/shift';

export enum ShiftActions {
    LOAD_SHIFTS = '@@shift/LOAD_SHIFTS',
    LOAD_SHIFTS_FULFILLED = '@@shift/LOAD_SHIFTS_FULFILLED',
    LOAD_SHIFTS_REJECTED = '@@shift/LOAD_SHIFTS_REJECTED',

    LOAD_SHIFT = '@@shift/LOAD_SHIFT',
    LOAD_SHIFT_FULFILLED = '@@shift/LOAD_SHIFT_FULFILLED',
    LOAD_SHIFT_REJECTED = '@@shift/LOAD_SHIFT_REJECTED',

    CREATE_SHIFT = '@@shift/CREATE_SHIFT',
    CREATE_SHIFT_FULFILLED = '@@shift/CREATE_SHIFT_FULFILLED',
    CREATE_SHIFT_REJECTED = '@@shift/CREATE_SHIFT_REJECTED',

    UPDATE_SHIFT = '@@shift/UPDATE_SHIFT',
    UPDATE_SHIFT_FULFILLED = '@@shift/UPDATE_SHIFT_FULFILLED',
    UPDATE_SHIFT_REJECTED = '@@shift/UPDATE_SHIFT_REJECTED',

    DELETE_SHIFT = '@@shift/DELETE_SHIFT',
    DELETE_SHIFT_FULFILLED = '@@shift/DELETE_SHIFT_FULFILLED',
    DELETE_SHIFT_REJECTED = '@@shift/DELETE_SHIFT_REJECTED',

    CANCEL_UPSERT_SHIFT = '@@shift/CANCEL_UPSERT_SHIFT',

    LOAD_ENGINEERS = '@@shift/LOAD_ENGINEERS',
    LOAD_ENGINEERS_FULFILLED = '@@shift/LOAD_ENGINEERS_FULFILLED',
    LOAD_ENGINEERS_REJECTED = '@@shift/LOAD_ENGINEERS_REJECTED'
}

export const loadShifts = (filters: IShiftFilters): IAppAction => ({
    type: ShiftActions.LOAD_SHIFTS,
    payload: filters
});

export const loadShift = (id: string): IAppAction => ({
    type: ShiftActions.LOAD_SHIFT,
    payload: id
});

export const loadEngineers = (siteId: string): IAppAction => ({
    type: ShiftActions.LOAD_ENGINEERS,
    payload: siteId
});

export const createShift = (shift: IShift): IAppAction => ({
    type: ShiftActions.CREATE_SHIFT,
    payload: shift
});

export const cancelUpsertShift = (shift: IShift): IAppAction => ({
    type: ShiftActions.CANCEL_UPSERT_SHIFT,
    payload: shift
});

export const updateShift = (shift: IShift): IAppAction => ({
    type: ShiftActions.UPDATE_SHIFT,
    payload: shift
});

export const deleteShift = (id: string): IAppAction => ({
    type: ShiftActions.DELETE_SHIFT,
    payload: id
});
