import React, { useEffect } from 'react';
import { Control, Field, Help, Input } from 'components/form';
import { FormikProps } from 'formik';
import { Select } from 'components/select';
import { BulmaColor } from 'enums/BulmaColor';
import { BulmaSize } from 'enums/BulmaSize';
import { IOpportunity, getCategories } from 'models/opportunity-question-set';
import { formikFieldCss } from 'utils/form-utils';
import useTranslate from 'translations/translation-utils';

export interface IProps {
    formikBag: FormikProps<Partial<IOpportunity>>;
    showError: boolean;
    setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
}

const OpportunityMasterForm: React.FC<IProps> = ({ formikBag, setIsValid, showError }) => {
    const translate = useTranslate();

    useEffect(() => {
        if (
            !formikBag.values.title ||
            formikBag.values.title === '' ||
            !formikBag.values.category ||
            formikBag.values.category === ''
        ) {
            setIsValid(false);
        } else {
            setIsValid(true);
        }
    }, [formikBag.values.title, formikBag.values.category]);

    useEffect(() => {
        if (showError) {
            formikBag.validateForm();
        }
    }, [showError]);

    return (
        <>
            <Field isHorizontal={true} htmlFor="title" label={translate('OpportunityQ.MasterForm.Title')} labelSize={BulmaSize.Medium}>
                <Field>
                    <Control>
                        <Input
                            id="title"
                            name="title"
                            aria-required="true"
                            placeholder={translate('OpportunityQ.MasterForm.TitlePlaceholder')}
                            type="text"
                            className={formikFieldCss(formikBag, 'title')}
                            value={formikBag.values.title}
                            onChange={formikBag.handleChange}
                            onBlur={(event) => formikBag.setFieldValue(event.target.name, event.target.value.trim())}
                        />
                    </Control>
                    <Help isShown={true} bulmaColor={BulmaColor.Danger}>
                        {formikBag.errors.title}
                    </Help>
                </Field>
            </Field>

            <Field
                isHorizontal={true}
                htmlFor="category"
                label={translate('OpportunityQ.MasterForm.Category')}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <Select
                            id="category"
                            name="category"
                            aria-required="true"
                            options={getCategories.map((m) => ({
                                ...m,
                                label: translate(
                                    'OpportunityQ.OpportunityCategory.',
                                    m.label
                                ),
                            }))}
                            className={formikFieldCss(formikBag, 'category')}
                            value={formikBag.values.category}
                            onChange={formikBag.handleChange}
                            onBlur={formikBag.handleBlur}
                            emptyOptionsValue={translate('OpportunityQ.MasterForm.EmptyOption')}
                        />
                    </Control>
                    <Help isShown={true} bulmaColor={BulmaColor.Danger}>
                        {formikBag.errors.category}
                    </Help>
                </Field>
            </Field>
        </>
    );
};

export default OpportunityMasterForm;
