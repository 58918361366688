//No More used
import * as React from 'react';
import { Icon, IconType } from '../icon';
import './PopoutSelect.scss';

export const PopupSelectDropdownIndicator: React.FC = () => (
    <div className="popout-select__dropdown-indicator">
        <Icon type={IconType.Search} />
    </div>
);
