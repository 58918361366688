import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';

import { Epic, combineEpics } from 'redux-observable';
import { CermProcessStepActions } from 'actions/actions-v2/cerm-process-step-action-v2';
import { ICermProcessStep } from 'models/cerm-process-step';
import { ICermOverviewPillar } from 'models/cerm-overview';
import { UpsertCermProcessStepActions } from 'actions/upsert-cerm-process-step-actions'

const controllerName = 'cermProcessStep';

const getActions = {
    action: CermProcessStepActions.LOAD_CERM_PROCESS_PAGE,
    actionFulfilled: CermProcessStepActions.LOAD_CERM_PROCESS_PAGE_FULFILLED,
    actionRejected: CermProcessStepActions.LOAD_CERM_PROCESS_PAGE_REJECTED,
} as IActionType;

const getProcessActions = {
    action: CermProcessStepActions.LOAD_CERM_PROCESS,
    actionFulfilled: CermProcessStepActions.LOAD_CERM_PROCESS_FULFILLED,
    actionRejected: CermProcessStepActions.LOAD_CERM_PROCESS_REJECTED,
} as IActionType;

const createActions = {
    action: CermProcessStepActions.CREATE_CERM_PROCESS,
    actionFulfilled: CermProcessStepActions.CREATE_CERM_PROCESS_FULFILLED,
    actionRejected: CermProcessStepActions.CREATE_CERM_PROCESS_REJECTED,
} as IActionType;

const updateActions = {
    action: CermProcessStepActions.UPDATE_CERM_PROCESS,
    actionFulfilled: CermProcessStepActions.UPDATE_CERM_PROCESS_FULFILLED,
    actionRejected: CermProcessStepActions.UPDATE_CERM_PROCESS_REJECTED,
} as IActionType;

const deleteActions = {
    action: UpsertCermProcessStepActions.DELETE_PROCESS_STEP_CONFIRMED,
    actionFulfilled: UpsertCermProcessStepActions.DELETE_PROCESS_STEP_CONFIRMED_FULFILLED,
    actionRejected: UpsertCermProcessStepActions.DELETE_PROCESS_STEP_CONFIRMED_REJECTED,
} as IActionType;

const getCermLinksActions = {
    action: CermProcessStepActions.GET_CERM_PROCESS_LINKS,
    actionFulfilled: CermProcessStepActions.GET_CERM_PROCESS_LINKS_FULFILLED,
    actionRejected: CermProcessStepActions.GET_CERM_PROCESS_LINKS_REJECTED,
} as IActionType;

const loadCermProcessStepEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getProcessActions).getById<ICermOverviewPillar[]>(
        `/api/${controllerName}`
    );
const loadCermProcessStepPageEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getActions).get<ICermOverviewPillar[]>(`/api/${controllerName}`);
const createActionEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createActions, '/CermProcessSteps').post<ICermProcessStep>(
        `/api/${controllerName}`
    );
const updateActionEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateActions, '/CermProcessSteps').put<ICermProcessStep>(
        `/api/${controllerName}`
    );
const getermActionEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getCermLinksActions).getById(`/api/${controllerName}/processNumber`);

const deleteActionsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, deleteActions, '/CermProcessSteps').delete<string>(`/api/${controllerName}`);

export const cermProcessStepEpicsV2 = combineEpics(
    loadCermProcessStepPageEpic,
    loadCermProcessStepEpic,
    createActionEpic,
    updateActionEpic,
    getermActionEpic,
    deleteActionsEpic
);
