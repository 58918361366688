import * as React from 'react';
import { Control, Field } from 'components/form';
import { NavLink } from 'react-router-dom';
import { Loader } from '../loader';
import { Button } from '../v2/components';
import useTranslate from 'translations/translation-utils';

export interface IMasterEngineerSkillsGridToolbarProps {
    // items: IEngineer[];
    isExportingEngineers: boolean;
    onClearFilters: () => void;
    // onAddEngineer: () => void;
    onShowFilterModal: () => void;
    onDownloadEngineers: () => void;
}

export const MasterEngineerSkillsGridToolbar: React.FC<IMasterEngineerSkillsGridToolbarProps> = (props) => {
    const onClearFilters: React.MouseEventHandler<HTMLButtonElement> = () => {
        props.onClearFilters();
    };

    const exportEngineers = () => {
        props.onDownloadEngineers();
    };

    const translate = useTranslate();

    return (
        <div className="grid-toolbar">
            <div className="columns is-multiline is-mobile">
                <div className="column is-12-mobile is-9">
                    <Field className="is-grouped is-grouped-left" >
                        <Control className="is-expanded-mobile">
                            <Button buttonType="clear" onClick={onClearFilters}>{translate('RiskRegisterPage.RiskRegisterToolbar.Title.ClearFilters')}</Button>
                        </Control>
                    </Field>
                </div>
                <div className="column is-12-mobile is-3">
                    <Field className="is-grouped is-grouped-right">
                        <Control>
                            {
                                props &&
                                <Loader loading={props.isExportingEngineers}>
                                    <Button id="export-engineers-button" onClick={exportEngineers}>{translate('RiskRegisterPage.RiskRegisterToolbar.Title.Export')}</Button>
                                </Loader>
                            }
                        </Control>
                        <Control className="is-expanded">
                            <NavLink id="create-new-engineer-button" to="/Engineer/0">{translate('EngineerSkills.Lable.NewEngSkill')}</NavLink>
                        </Control>
                    </Field>
                </div>
            </div>
        </div>
    );
};
