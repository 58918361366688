import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { IKeyValue } from 'models/key-value';
import { checkPermission } from 'utils/permission-utils';
import { IconToolbar, IToolbarIconItem, ToolbarIconType } from 'components/v2/components';
import { history } from 'routes/App';
import useTranslate from 'translations/translation-utils';
import { IOperatingPlatform } from 'models/operating-platform-model';

interface IRouteParams {
    operatingPlatform?: string;
}

export interface IProps extends RouteComponentProps<IRouteParams> {
    isInitialising: boolean;
    permissions: Array<IKeyValue<string>>;
    isFilterExists?: boolean;
    isShowHelp: boolean;
    onShowFilters: () => void;
    onClearFilters: () => void;
    onDownload: (operatingPlatform: string) => void;
    onClickHelp: () => void;
    operatingPlatformList: IOperatingPlatform[];
}

export const QuestionSetGridToolbar: React.FC<IProps> = ({
    isInitialising,
    permissions,
    isFilterExists,
    isShowHelp,
    onShowFilters,
    onClearFilters,
    onDownload,
    onClickHelp,
    ...props
}) => {

    const getOperatingPlatformExportButtons = () => {
        let operatingPlatformExportoptions = [];
        operatingPlatformExportoptions =
           props.operatingPlatformList &&
       props.operatingPlatformList.map(op => {
                return {
                    id: `${'export-'}${op.shortForm}${'-button'}`,
                    title: `${translate(
                        'QuestionSets.GridToolbar.Title.DownloadOptions'
                    )}`.replace("CERM", op.value),
                    type: ToolbarIconType.excel,
                    onClick: () => { handleDownload(op.value) },
                    isConfirmRequired: true,
                    confirmationMessage: translate('QuestionnSets.GridToolbar.Title.Message'),
                };
            });
        return operatingPlatformExportoptions;
    };
    
    const getOperatingPlatform = () => {
        return props.match.params.operatingPlatform;
    }

    const handleDownload = (operatingPlatform: string) => {
        onDownload(operatingPlatform);
    }
    const translate = useTranslate();
    const getToolbarItems = (): IToolbarIconItem[] => {
        return [
            (checkPermission('RiskQuestionsOverviewUpdate', permissions)
            ) &&
            !isInitialising && {
                id: 'add-question-set-button',
                title: translate('OpportunityQ.QuestionSet.ToolbarCreate'),
                type: ToolbarIconType.add,
                onClick: () => {
                    history.push('/QuestionSets/ManageQuestionSets/Questions/' + getOperatingPlatform());
                }
            },
            {
                id: 'export-button',
                title: translate('QuestionnSets.GridToolbar.Title.ExportRiskQuestions'),
                type: ToolbarIconType.download,
                children: getOperatingPlatformExportButtons()
            },
           
            {
                id: 'clear-all-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.ClearFilters'),
                type: isFilterExists ? ToolbarIconType.filterApplied : ToolbarIconType.clearFilter,
                onClick: onClearFilters
            },
            {
                id: 'show-filters-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.ShowFilters'),
                type: ToolbarIconType.filter,
                onClick: onShowFilters
            },
            isShowHelp && {
                id: 'show-help',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.Help'),
                type: ToolbarIconType.help,
                onClick: onClickHelp,
            },
        ];
    };

    return <IconToolbar items={getToolbarItems()} />;
};
