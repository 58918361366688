import * as React from 'react';

import { IRole } from 'reducers/manageRoles/manageRoles-grid';
import { RouteUrl } from '../../routes';
import { IconToolbar, IToolbarIconItem, ToolbarIconType } from 'components/v2/components';
import { history } from 'routes/App';
import useTranslate from 'translations/translation-utils';

export interface IActionProps {
    isExporting: boolean;
    isFilterExists: boolean;
    onExport: () => void;
    onShowFilters: () => void;
    onClearFilters: () => void;
    onShowRoleModal: (role?: IRole) => void;
    path: string;
}

export const ManageRoleGridToolbar: React.FC<IActionProps> = ({
    isFilterExists,
    isExporting,
    onExport,
    onShowFilters,
    onClearFilters,
    onShowRoleModal,
    path
}) => {
    const translate = useTranslate();
    const getToolbarItems = (): IToolbarIconItem[] => {
        return [
            path === RouteUrl.People && {
                id: 'add-person-button',
                title: translate('Roles.AddPerson'),
                type: ToolbarIconType.add,
                onClick: () => {
                    history.push(RouteUrl.PersonUpsert);
                }
            },
            {
                id: 'export-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.Export'),
                type: ToolbarIconType.download,
                isDisabled: isExporting,
                onClick: onExport
            },
            path === RouteUrl.Roles && {
                id: 'add-role-button',
                title: translate('Roles.Add'),
                type: ToolbarIconType.add,
                onClick: onShowRoleModal
            },
            path === RouteUrl.People && {
                id: 'clear-all-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.ClearFilters'),
                type: isFilterExists ? ToolbarIconType.filterApplied : ToolbarIconType.clearFilter,
                onClick: onClearFilters
            },
            path === RouteUrl.People && {
                id: 'show-filters-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.ShowFilters'),
                type: ToolbarIconType.filter,
                onClick: onShowFilters
            }
        ];
    };

    return <IconToolbar items={getToolbarItems()} />;
};
