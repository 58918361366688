import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { showFilterModal, exportEngineer } from 'actions/actions-v2/engineer-action-v2';
import { EngineerGridToolbar } from './engineer-grid-toolbar';
import { IRootState } from 'routes/store';

const mapStateToProps = (state: IRootState) => {
    return {
        isExportingEngineer: state.engineerState.isExportingEngineer
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
    onShowFilterModal() {
        dispatch(showFilterModal());
    },
    onDownloadEngineer(id: string) {
        dispatch(exportEngineer(id));
    }
    };
};

export const EngineerGridToolbarContainer = connect(mapStateToProps, mapDispatchToProps)(EngineerGridToolbar);
