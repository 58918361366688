import React from 'react';
import { IToolbarIconItem, IconToolbar, ToolbarIconType } from 'components/v2/components';
import { IKeyValue } from 'models';
import { checkPermission } from 'utils/permission-utils';
import { useTranslate } from 'translations/translation-utils';

interface IProps {
    isAlreadyInReview: boolean;
    isExporting: boolean;
    permissions: Array<IKeyValue<string>>;
    isShowHelp: boolean;
    onClickHelp: () => void;
    onExport: () => void;
    onStartReview: () => void;
}

// TODO implement download this is not in this user story but is in this sprint
// export const CermAssessmentToolbar: React.FC<IProps> = ({ isAlreadyInReview, permissions, isExporting, onExport, onStartReview }) => {
export const CermAssessmentToolbar: React.FC<IProps> = ({
    isAlreadyInReview,
    permissions,
    isShowHelp,
    onStartReview,
    onClickHelp,
}) => {
    const translate = useTranslate();
    const getToolbarItems = (): IToolbarIconItem[] => {
        return [

            checkPermission('CermAssessmentExpertReviewCreate', permissions) && {
                id: 'start-review',
                title: translate('CermComparision.Title.4'),
                type: ToolbarIconType.expertReview,
                isDisabled: isAlreadyInReview,
                onClick: onStartReview,
            },

            isShowHelp && {
                id: 'show-help',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.Help'),
                type: ToolbarIconType.help,
                onClick: onClickHelp,
            },
        ];
        // TODO implement download this is not in this user story but is in this sprint
        /*  toolBarItems.push({
             id: 'export-button',
             title: 'Export',
             type: ToolbarIconType.download,
             isDisabled: true,
             isActive: false
             // onClick: onExport
         }); */
    };

    return <IconToolbar items={getToolbarItems()} />;
};
