import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Loader } from 'components/loader';
import { IAction } from 'models/action';
import { Formik, FormikProps } from 'formik';
import { history } from 'routes/App';
import { Button } from 'components/v2/button/Button';
import { ActionDetailsTab } from './tabs/details/actions-details-tab';
import ActionAttachmentsTab from './tabs/attachments/actions-attachments-tab';
import ActionHistoryTab from './tabs/history/action-history-tab';
import {
    TabGroup,
    TabItem,
    ConfirmDialog,
    ButtonWithConfirmDialog,
    ButtonWithCheckboxConfirmDialog,
    Alert,
} from 'components/v2/components';
import { IPersonLookup } from 'models/person-lookup';
import selectors from './selectors';
import actions from './actions';
import { IKeyValue } from 'models';
import { actionDetailsValidationSchema } from './tabs/details/actions-details-form';
import Page from 'components/v2/page/Page';
import { isEmpty } from 'lodash';
import useTranslate from 'translations/translation-utils';
import { getUserId } from 'helpers/helpers';
import { IExpertReviewStatus } from 'models/cerm/cerm-assessment-result';
import { checkPermission } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import { Link } from 'react-router-dom';
import { IMockDrillReport } from 'models/mock-drills';
import { TextField } from 'components/form/fields/text-field';
import { getLabelsWithSeparator } from 'utils/keyvalue-utils';

interface IProps extends RouteComponentProps<IParams> {
    siteId: string;
    isLoading?: boolean;
    action: IAction;
    users: IPersonLookup[];
    lookupSiteFeatures: Array<IKeyValue<string[]>>;
    expertReviewStatus: IExpertReviewStatus;
    permissions: Array<IKeyValue<string>>;
    mockDrillReport: IMockDrillReport;
    loadAction: (id: string, siteId: string, category: string) => void;
    createAction: (model: Partial<IAction>) => void;
    updateAction: (model: Partial<IAction>) => void;
    closeAction: (model: IAction) => void;
    removeAction: (id: string, siteId: string) => void;
    clearForm: () => void;
    loadPersonsLookup: (siteId: string) => void;
    loadPersonProfile: (id: string) => void;
    loadCermAssessmentExpertReview(siteId: string);
    loadMockDrillReport: (id: string, siteId: string) => void;
    loadMacroAction(id: string, siteId: string);
    updateMacroAction(model: Partial<IAction>): void;
    archiveMacroAction(id: string, siteId: string): void;
    closeMacroAction(model: IAction): void;
}

interface IParams {
    id?: string;
    tab: string;
    siteId?: string;
    category?: string;
}

enum Tab {
    details = 'details',
    attachments = 'attachments',
    history = 'history',
}

const ActionUpsertPage: React.FC<IProps> = ({
    siteId,
    match,
    isLoading,
    action,
    users,
    loadPersonsLookup,
    loadPersonProfile,
    loadCermAssessmentExpertReview,
    lookupSiteFeatures,
    expertReviewStatus,
    permissions,
    mockDrillReport,
    ...props
}) => {
    const translate = useTranslate();
    const [isValid, setIsValid] = React.useState(false);
    const [showTabDisabledDialog, setShowTabDisabledDialog] = React.useState(false);
    const [linkedIncidentsListDialogVisible, setLinkedIncidentsListDialogVisible] = React.useState(false);
    const isMacro = match.params.category?.toLowerCase() === 'macro';
    const actionId = match.params.id;
    const currentTab = match.params.tab.toLowerCase();
    const actionsPageUrl = '/Actions';
    const customLinks: Array<IKeyValue<string>> = [{ key: actionsPageUrl, value: translate('ActionUpsertPage.Title') }];
    let formikBag: FormikProps<IAction>;
    const [commentSave, setCommentSave] = React.useState(false);

    const isReadOnly =
        action &&
        (action.category === 'ElogBooks' ||
            action.category === 'iAuditor' ||
            (isMacro && getUserId() !== action.assigneeId));
    const canEditInExpertReview = (): boolean => {
        if ((action.masterProcessStepId || action.outcomeId) &&
            expertReviewStatus &&
            expertReviewStatus.isInReview === true) {
            return expertReviewStatus &&
                (expertReviewStatus.startedById === undefined ||
                    expertReviewStatus.startedById === null ||
                    (expertReviewStatus.startedById &&
                        (expertReviewStatus.startedById === getUserId() ||
                            checkPermission(
                                Privilege.CermAssessmentExpertReviewFinish,
                                permissions
                            )
                        )
                    )
                )
        }

        return true;
    };

    const getSiteId = () => {
        return match.params.siteId ? match.params.siteId : siteId;
    };

    React.useEffect(() => {
        loadPersonsLookup(getSiteId());
        return () => {
            props.clearForm();
        };
    }, []);

    React.useEffect(() => {
        if (isMacro) {
            props.loadMacroAction(actionId, getSiteId());
        } else if (!isEmpty(lookupSiteFeatures) && getSiteId() && actionId) {
            props.loadAction(actionId, getSiteId(), match.params.category);
        }
    }, [siteId, actionId]);

    React.useEffect(() => {
        if (!getUserId()) {
            return;
        }
        if (match.params.siteId) {
            loadPersonProfile(getUserId());
        }
        if (siteId) {
            loadCermAssessmentExpertReview(siteId);
        }
    }, [siteId]);

    React.useEffect(() => {
        if (
            siteId &&
            actionId &&
            action &&
            action.relatedEntity?.id &&
            action.relatedEntity?.type === 'MockDrillReport'
        ) {
            props.loadMockDrillReport(action.relatedEntity.id, siteId);
        }
    }, [siteId, actionId, action]);

    const setNavLink = (selectedTab: Tab) => {
        if (actionId) {
            return match.path
                .replace(':id?', actionId)
                .replace(':tab', selectedTab)
                .replace(':siteId?', match.params.siteId ? match.params.siteId : '')
                .replace(':category?', match.params.category);
        }

        return match.path
            .replace(':id?/', '')
            .replace(':tab', selectedTab)
            .replace(':siteId?', siteId)
            .replace(':category?', match.params.category);
    };

    const onSubmit = (value: Partial<IAction>) => {
        if (isMacro) {
            if (value.id) {
                props.updateMacroAction(value);
            }
        } else {
            if (value.id) {
                props.updateAction(value);
            } else {
                props.createAction(value);
            }
        }
    };

    const onArchive = () => {
        if (isMacro) {
            props.archiveMacroAction(actionId, getSiteId());
        } else {
            props.removeAction(actionId, getSiteId());
        }
    };

    const handleClick = (event: any, tab: Tab) => {
        event.preventDefault();
        if (!isActionIdAvailable()) {
            setShowTabDisabledDialog(true);
            return;
        }

        formikBag.validateForm().then(errors => {
            if (!Object.keys(errors).some(field => field.length > 0) ||
                formikBag.initialValues.statusId === 'Closed') {
                history.push(setNavLink(tab));
            }
        });
    };

    const onSaveButtonClick = () => {
        onSave();
    };

    const onSave = () => {
        formikBag.validateForm().then(() => {
            if (isValid) {
                formikBag.submitForm();
                setCommentSave(true);
            }
        });
    };

    const onCloseAction = () => {
        if (isMacro) {
            props.closeMacroAction(action);
        } else {
            props.closeAction(action);
        }
    };

    const renderTab = (formik: FormikProps<IAction>) => {
        formikBag = formik;
        setIsValid(formik.isValid);
        switch (currentTab) {
            case Tab.details:
                return isActionActiveAndNotClose() ? (
                    <ActionDetailsTab
                        formikBag={formikBag}
                        commentSave={commentSave}
                        isReadOnly={isReadOnly}
                    />
                ) : (
                    <ActionDetailsTab formikBag={formikBag} isReadOnly={isReadOnly} />
                );
            case Tab.attachments:
                return (
                    <ActionAttachmentsTab
                        id={actionId}
                        canEditInExpertReview={canEditInExpertReview}
                    />
                );
            case Tab.history:
                return <ActionHistoryTab id={actionId} />;
        }
    };

    const isActionIdAvailable = () => {
        return actionId && actionId.length > 1 ? true : false;
    };

    const isActionActiveAndNotClose = () => {
        return action.isActive && action.statusId !== 'Closed';
    };
    const getCloseActionButton = () => {
        if (!isActionActiveAndNotClose() || !isActionIdAvailable()) {
            return null;
        }

        const commonProps = {
            buttonId: 'closeAction',
            buttonText: translate('ActionUpsertPage.ConfirmDialog.ButtonCloseActionText'),
            title: translate('ActionUpsertPage.ConfirmDialog.ConfirmTitle'),
            onConfirm: onCloseAction,
            isLoading,
        };

        if (action.outcomeId) {
            return (
                <ButtonWithCheckboxConfirmDialog
                    message={
                        <p>
                            <strong>
                                {translate(
                                    `${action.parentRisk && action.parentRisk.origin && action.parentRisk.origin.includes('HERM') ?
                                        'ActionUpsertPage.CloseButtonAction.ButtonWithCheckboxConfirmDialog.MessageHERM' :
                                        'ActionUpsertPage.CloseButtonAction.ButtonWithCheckboxConfirmDialog.MessageCERM'}`
                                )}
                            </strong>
                        </p>
                    }
                    checkboxLabel={translate(
                        `${action.parentRisk && action.parentRisk.origin && action.parentRisk.origin.includes('HERM') ?
                            'ActionUpsertPage.CloseButtonAction.ButtonWithCheckboxConfirmDialog.CheckBoxLabelHERM' :
                            'ActionUpsertPage.CloseButtonAction.ButtonWithCheckboxConfirmDialog.CheckBoxLabelCERM'}`
                    )}
                    {...commonProps}
                />
            );
        }

        return (
            <ButtonWithConfirmDialog
                message={translate(
                    'ActionUpsertPage.CloseButtonAction.ButtonWithConfirmDialog.Message'
                )}
                {...commonProps}
            />
        );
    };

    const getArchiveButton = () => {
        if (!isActionIdAvailable() || !action || !action.isActive) {
            return null;
        }

        let message = <>{translate('ActionUpsertPage.GetArchiveButton.Message')}</>;
        let checkboxLabel = translate('ActionUpsertPage.GetArchiveButton.CheckboxLabel');
        if (action.outcomeId) {
            message = (
                <p>
                    <strong>
                        {translate(`${action.parentRisk && action.parentRisk.origin && action.parentRisk.origin.includes('HERM') ?
                            'ActionUpsertPage.GetArchiveButton.MasterProcessStepIdMessageHERM' :
                            'ActionUpsertPage.GetArchiveButton.MasterProcessStepIdMessageCERM'}`)}
                    </strong>
                </p>
            );
            checkboxLabel = translate(`${action.parentRisk && action.parentRisk.origin && action.parentRisk.origin.includes('HERM') ?
                'ActionUpsertPage.GetArchiveButton.MasterProcessStepIdCheckboxLabelHERM' :
                'ActionUpsertPage.GetArchiveButton.MasterProcessStepIdCheckboxLabelCERM'}`

            );
        }

        return (
            <ButtonWithCheckboxConfirmDialog
                buttonId="archive"
                buttonText={translate(
                    'ActionUpsertPage.ButtonWithCheckboxConfirmDialog.buttonText'
                )}
                buttonType="archive"
                title={translate('ActionUpsertPage.ButtonWithCheckboxConfirmDialog.Title')}
                message={message}
                checkboxLabel={checkboxLabel}
                onConfirm={onArchive}
                isLoading={isLoading}
            />
        );
    };

    const hasParentRisk = () => {
        return action.riskItemId;
    };

    const hasParentIncident = () => {
        return action.parents && action.parents.some((x) => x.bucket === 'Incident');
    };

    const hasParentProcessStepAnswer = () => {
        return action.masterProcessStepId && action.outcomeId;
    };

    const hasParentMockDrill = () => {
        return (
            action.relatedEntity != null &&
            action.relatedEntity.id &&
            action.relatedEntity.type === 'MockDrillReport'
        );
    };

    const onParentRiskButtonClick = () => {
        const result = isMacro ? '?macro=true' : '';

        history.push('/RiskRegister/' + action.riskItemId + '/details' + result);
    };

    const onParentIncidentButtonClick = () => {
        if (!hasParentIncident()) {
            return;
        }

        const incidents = action.parents.filter((x) => x.bucket === 'Incident');
        if (incidents.length === 1) {
            history.push(`/Incident/${incidents[0].id}/details`);
        } else {
            setLinkedIncidentsListDialogVisible(true);
        }
    };

    const onParentProcessStepAnswerButtonClick = () => {
        history.push(
            '/CermAssessment/ProcessStep/' + action.masterProcessStepId + '/' + action.outcomeId
        );
    };

    const onParentMockDrillButtonClick = () => {
        history.push(
            '/MockDrill/MockDrills/Reports/' +
            mockDrillReport.mockDrillType +
            '/' +
            mockDrillReport.mockDrillId +
            '/' +
            encodeURIComponent(mockDrillReport.title) +
            '/' +
            mockDrillReport.id +
            '/Details'
        );
    };

    const getLinkedIncidentsList = () => {
        if (!hasParentIncident()) {
            return;
        }

        const incidents = action.parents.filter(x => x.bucket === 'Incident');

        return (
            <>
                {
                    incidents.map(incident => (
                        <Link to={`/Incident/${incident.id}/details`} >
                            <p>{incident.displayLabel}</p>
                        </Link>
                    ))
                }
            </>
        );
    };

    const title = actionId && action.title ? action.title : translate('ActionUpsertPage.Title');

    return (
        <Page
            title={title}
            breadcrumbCustomLinks={customLinks}
            className="action-register-page"
            redirectOnSiteChange={match.params.siteId ? false : true}
            redirectOnSiteChangeUrl={actionsPageUrl}
            scrollTopOnPageLoad={true}
        >
            {!canEditInExpertReview() && (action.masterProcessStepId || action.outcomeId) && (
                <Alert type="warning">
                    <p>
                        {expertReviewStatus && `${translate('Alert.Message.Youreinreviewmode')}
                    ${expertReviewStatus.startDate.toDateString()}
                    ${expertReviewStatus.startedByName &&
                            ' by ' + expertReviewStatus.startedByName + '.'
                            }                    
                    ${translate('Alert.Message.Youarenotallowedtoeditit')}
                    `}`
                    </p>
                </Alert>
            )}
            {isMacro && (
                <>
                    <div style={{ display: 'inline-flex' }}>
                        <div className="column is-6">
                            <TextField
                                id="Level"
                                label={translate('ActionDetailsPage.label.MacroLevel')}
                                value={action.macroLevel}
                                isDisabled={true}
                            />
                        </div>
                        <div className="column is-6">
                            <TextField
                                id="MacroLevelValues"
                                label={translate('ActionDetailsPage.label.MacroLevelValues')}
                                value={getLabelsWithSeparator(action.macroLevelValues, true)}
                                isDisabled={true}
                            />
                        </div>
                    </div>
                </>
            )}
            <Loader loading={isLoading}>
                <TabGroup>
                    <TabItem
                        title={translate('ActionUpsertPage.Tabs.Details')}
                        tab={Tab.details}
                        currentTab={currentTab}
                        onClick={handleClick}
                    />
                    {!isReadOnly ? (
                        <TabItem
                            title={translate('ActionUpsertPage.Tabs.Attachments')}
                            tab={Tab.attachments}
                            currentTab={currentTab}
                            onClick={handleClick}
                        />
                    ) : null}
                    {!isReadOnly ? (
                        <TabItem
                            title={translate('ActionUpsertPage.Tabs.History')}
                            tab={Tab.history}
                            currentTab={currentTab}
                            onClick={handleClick}
                        />
                    ) : null}
                </TabGroup>
                <Formik<Partial<IAction>>
                    initialValues={action}
                    onSubmit={onSubmit}
                    validationSchema={actionDetailsValidationSchema}
                    render={renderTab}
                    enableReinitialize={true}
                    isInitialValid={actionId ? true : false}
                    validateOnBlur={true}
                    validateOnChange={true}
                />
                <ConfirmDialog
                    title={translate('ActionUpsertPage.ConfirmDialog.Title')}
                    message={translate('ActionUpsertPage.ConfirmDialog.Message')}
                    buttonCancelHide={true}
                    buttonConfirmText={translate(
                        'ActionUpsertPage.ConfirmDialog.ButtonConfirmText'
                    )}
                    isVisible={showTabDisabledDialog}
                    onConfirm={() => setShowTabDisabledDialog(false)}
                    onOutsideDialogClick={() => setShowTabDisabledDialog(false)}
                />
                <div className="columns columns-buttons">
                    <div className="column">
                        <Button
                            id="cancel"
                            buttonType="cancel"
                            isLoading={isLoading}
                            onClick={() => history.push('/Actions')}
                        >
                            {translate('ActionUpsertPage.Button.Cancel')}
                        </Button>

                        {!isReadOnly && canEditInExpertReview() && getArchiveButton()}

                        {hasParentRisk() && (
                            <Button
                                id="riskLink"
                                buttonType="action"
                                isLoading={isLoading}
                                onClick={onParentRiskButtonClick}
                            >
                                {translate('ActionUpsertPage.Button.RiskLink')}
                            </Button>
                        )}

                        {hasParentIncident() && (
                            <>
                                <Button
                                    id="incidentLink"
                                    buttonType="action"
                                    isLoading={isLoading}
                                    onClick={onParentIncidentButtonClick}
                                >
                                    {translate('ActionUpsertPage.Button.IncidentLink')}
                                </Button>

                                <ConfirmDialog
                                    title={translate('ActionUpsertPage.Button.IncidentLinkConfirmTitle')}
                                    message={getLinkedIncidentsList()}
                                    isVisible={linkedIncidentsListDialogVisible}
                                    showConfirmButton={false}
                                    onClose={() => setLinkedIncidentsListDialogVisible(false)}
                                    onOutsideDialogClick={() => setLinkedIncidentsListDialogVisible(false)}
                                    buttonCancelText={translate('Globals.Label.Cancel')}
                                />
                            </>
                        )}

                        {hasParentProcessStepAnswer() && (
                            <Button
                                id="processStepAnswerLink"
                                buttonType="action"
                                isLoading={isLoading}
                                onClick={onParentProcessStepAnswerButtonClick}
                            >
                                {translate('ActionUpsertPage.Button.ProcessStepAnswerLink')}
                            </Button>
                        )}

                        {hasParentMockDrill() && (
                            <Button
                                id="mockDrillLink"
                                buttonType="action"
                                isLoading={isLoading}
                                onClick={onParentMockDrillButtonClick}
                            >
                                {translate('ActionUpsertPage.Button.MockDrillLink')}
                            </Button>
                        )}
                    </div>
                    {(!isReadOnly && canEditInExpertReview()) && isActionActiveAndNotClose() && (
                        <>
                            <div className="column column-save">
                                {getCloseActionButton()}
                                <Button
                                    id="save"
                                    buttonType="save"
                                    onClick={onSaveButtonClick}
                                    isLoading={isLoading}
                                    type="submit"
                                >
                                    {translate('ActionUpsertPage.Button.Save')}
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </Loader>
        </Page>
    );
};

export default withRouter(connect(selectors, actions)(ActionUpsertPage));
