import { IEmeraldTitleInput } from '../shared/title.input';
import { IEmeraldClassesInput } from '../shared/classes.input';

export type EmeraldIconTypeType = 'Filled' | 'Outlined' | 'Rounded';

export enum EmeraldIconType {
  Filled = 'Filled',
  Outlined = 'Outlined',
  Rounded = 'Rounded',
}

export class EmeraldIconCoreInputs implements IEmeraldIconCoreInputs {
  id?: string;
  icon?: string;
  color?: string;
  fontsizeInRem?: number;
  title?: string;
  type?: EmeraldIconTypeType = EmeraldIconType.Filled;
  tabIndex?: number;
  role?: string;
  classes?: string;
  ariaHidden?: boolean = false;
  ariaLabel?: string;
}

export interface IEmeraldIconCoreInputs
  extends IEmeraldClassesInput,
    IEmeraldTitleInput {
  id?: string;
  icon?: string;
  color?: string;
  fontsizeInRem?: number;
  type?: EmeraldIconTypeType;
  tabIndex?: number;
  role?: string;
  ariaHidden?: boolean;
  ariaLabel?: string;
}
