import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Page from 'components/v2/page/Page';
import { IMockGlobalDrillInfo, IGlobalMockDrillsFilters } from 'models/mock-drills';
import { Table, SortOrder, OverlayPanel, TableHeaders } from 'components/v2/components';
import { Loader } from 'components/loader';
import { GlobalMockDrillsFilters } from './shared/global-mock-drills-filters';
import { GlobalMockDrillsPageToolbar } from './shared/global-mock-drills-toolbar';
import { onRouteChange } from 'actions/app-actions';
import { sortArrayBy } from 'utils/sort-utils';
import { UiPreferences, usePageUIPreferences } from 'utils/page-ui-preferences-utils';
import { isEmpty } from 'lodash';
import { checkFilterExistsWithIgnoreKey } from 'utils/array-utils';
import { HelpModuleContainer } from 'routes/help-module/help-module-container';
import { ModuleType } from 'models/help-module';
import { useLocation } from 'react-router-dom';
import useTranslate from 'translations/translation-utils';

interface IRouteParams {
    scenarioType?: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
    siteId: string;
    isLoading: boolean;
    isExporting: boolean;
    globalMockDrills: IMockGlobalDrillInfo[];
    showHelpModule: boolean;
    canCreateGlobalMockDrill: boolean;
    canExportGlobalMockDrill: boolean;
    loadGlobalMockDrills: (filters: IGlobalMockDrillsFilters) => void;
    exportGlobalMockDrills: () => void;
}

export const GlobalMockDrillsPage: React.FC<IProps> = ({
    isLoading,
    isExporting,
    globalMockDrills,
    showHelpModule,
    canCreateGlobalMockDrill,
    canExportGlobalMockDrill,
    loadGlobalMockDrills,
    exportGlobalMockDrills,
    match,
}) => {
    const translate = useTranslate();
    const [showHelp, setShowHelp] = React.useState(false);
    const getUpdatedFilters = (
        updatedFilters: IGlobalMockDrillsFilters,
        key: Extract<keyof IGlobalMockDrillsFilters, string>,
        element: string
    ) => {
        const newFilters = { ...updatedFilters };
        const index = newFilters[key].indexOf(element);

        if (index !== -1) {
            const items = [...newFilters[key].slice(0, index), ...newFilters[key].slice(index + 1)];
            newFilters[key] = items;
        } else {
            const items = [element, ...newFilters[key].slice(0, newFilters[key].length)];
            newFilters[key] = items;
        }

        return newFilters;
    };
    const location = useLocation();
    const initializeFilters = () => {
        const initialFilters = {
            filterKeywords: [],
            filterScenarioTypes: [],
        } as IGlobalMockDrillsFilters;
        return initialFilters;
    };

    const scenarioTypeParam = match.params.scenarioType;
    const [sortedList, setSortedList] = React.useState(globalMockDrills);
    const [showFilters, setShowFilters] = React.useState(false);
    const [filters, setFilters] = usePageUIPreferences<IGlobalMockDrillsFilters>(
        UiPreferences.GlobalMockDrillsPageFilters,
        initializeFilters(
        )
    );

    React.useEffect(() => {
        setFilters(buildRequestFiltersObject(false));
    }, [location.key]);

    React.useEffect(() => {
        loadGlobalMockDrills(buildRequestFiltersObject(true));
    }, [filters]);

    React.useEffect(() => {
        if (!globalMockDrills) {
            return;
        }

        setSortedList(globalMockDrills);
    }, [globalMockDrills]);

    React.useEffect(() => {
        if (scenarioTypeParam) {
            setFilters({
                ...buildRequestFiltersObject(true),
                filterScenarioTypes: [scenarioTypeParam],
            });
        }
    }, [scenarioTypeParam]);

    const buildRequestFiltersObject = (keepState: boolean) => {
        return {
            filterKeywords: keepState ? filters.filterKeywords ? filters.filterKeywords : [] : [],
            filterScenarioTypes: keepState ? filters.filterScenarioTypes ? filters.filterScenarioTypes : [] : [],
        } as IGlobalMockDrillsFilters;
    };

    const onAddFilterKeywordHandler = (keyword: string) => {
        if (!keyword) {
            return;
        }
        const newFilterKeywords = [...filters.filterKeywords, keyword];
        setFilters({ ...filters, filterKeywords: newFilterKeywords });
    };

    const onRemoveFilterKeywordHandler = (keyword) => {
        const newFilterKeywords = filters.filterKeywords.filter((x) => x !== keyword);
        setFilters({ ...filters, filterKeywords: newFilterKeywords });
    };

    const onResetFiltersHandler = () => {
        setFilters(buildRequestFiltersObject(false));
    };

    const onToggleHandler = (field: string, sortOrder: SortOrder) => {
        if (sortOrder === SortOrder.off) {
            setSortedList(globalMockDrills);
        }

        setSortedList(sortArrayBy(field, globalMockDrills, sortOrder === SortOrder.asc));
    };

    const onFilter = (key: Extract<keyof IGlobalMockDrillsFilters, string>, element: string) => {
        const updatedFilters = getUpdatedFilters(filters, key, element);
        setFilters(updatedFilters);
    };

    const buildMainTable = () => {
        if (isEmpty(sortedList)) {
            return (
                <div className="no-data">
                    <h2>{translate('GlobalMockDrills.Title.5')}</h2>
                    <p>
                        <strong>
                            {translate('GlobalMockDrills.Title.6')}
                        </strong>
                    </p>
                </div>
            );
        }

        return (
            <Table>
                <TableHeaders
                    headers={[
                        {
                            title: translate('GlobalMockDrills.Title.7'),
                            sortField: 'name',
                        },
                        {
                            title: translate('Attachments.AttachmentList.Type'),
                            sortField: 'scenarioType',
                        },
                        {
                            title: translate('GlobalMockDrills.Title.8'),
                            sortField: 'numberOfSites',
                            className: 'no-of-sites',
                        },
                    ]}
                    sortFieldUiPreference={UiPreferences.GlobalMockDrillsPageSortField}
                    sortOrderUiPreference={UiPreferences.GlobalMockDrillsPageSortOrder}
                    onToggle={onToggleHandler}
                />
                <tbody className="clickable">
                    {sortedList.map((item) => (
                        <tr
                            key={item.id}
                            onClick={() => onRouteChange(`/GlobalMockDrill/details/${item.id}`)}
                        >
                            <td>{item.name}</td>
                            <td>{item.scenarioType}</td>
                            <td>{item.numberOfSites}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    };
    const onClickHelp = () => {
        setShowHelp(!showHelp);
    };

    const isFilterExists = checkFilterExistsWithIgnoreKey(filters, 'operatingPlatform');
    return (
        <Page title={`${translate('GlobalMockDrills.Title.2')}`} isAdminPage={true} className="global-mock-drills-page">
            <GlobalMockDrillsPageToolbar
                onShowFilters={() => setShowFilters(!showFilters)}
                onClearFilters={onResetFiltersHandler}
                disableExport={isExporting || !canExportGlobalMockDrill}
                onExport={exportGlobalMockDrills}
                isFilterExists={isFilterExists}
                isShowHelp={showHelpModule || true}
                onClickHelp={onClickHelp}
                disableCreate={!canCreateGlobalMockDrill}
            />
            <HelpModuleContainer
                isShown={showHelp}
                onClose={onClickHelp}
                moduleType={ModuleType.MockDrill}
            />
            <OverlayPanel
                title={translate('RiskRegisterPage.Title.Filters')}
                isVisible={showFilters}
                onClose={() => setShowFilters(false)}
                onOutsideDialogClick={() => setShowFilters(false)}
                onClearFilters={onResetFiltersHandler}
                isFilterExists={isFilterExists}
            >

                <GlobalMockDrillsFilters
                    filters={buildRequestFiltersObject(true)}
                    onAddFilterKeyword={onAddFilterKeywordHandler}
                    onRemoveFilterKeyword={onRemoveFilterKeywordHandler}
                    onFilter={onFilter}
                />
            </OverlayPanel>
            <Loader loading={isLoading}>{buildMainTable()}</Loader>
        </Page>
    );
};
