import * as React from 'react';
import { Button } from '../components';
import { ConfirmDialogWithCheckbox } from './ConfirmDialogWithCheckbox';

interface IProps {
    title: string;
    message: string | JSX.Element;
    buttonText: string;
    buttonId?: string;
    buttonType?: 'save' | 'action' | 'cancel' | 'filter' | 'archive' | 'clear' | 'upload';
    buttonConfirmText?: string;
    checkboxLabel?: string;
    isLoading?: boolean;
    buttonDisabled?: boolean;
    onConfirm: (checked: boolean) => void;
}

export const ButtonWithCheckboxConfirmDialog: React.FC<IProps> = ({
    buttonText,
    buttonId = 'confirmButton',
    buttonType,
    onConfirm,
    ...props
}) => {
    const [isDialogVisible, setIsDialogVisible] = React.useState(false);

    const onConfirmButtonClick = (checked: boolean) => {
        onConfirm(checked);
        setIsDialogVisible(false);
    };

    const onClose = () => {
        setIsDialogVisible(false);
    };

    return (
        <>
            <Button
                id={buttonId}
                isLoading={props.isLoading}
                buttonType={buttonType}
                onClick={() => setIsDialogVisible(true)}
                disabled={props.buttonDisabled}
            >
                {buttonText}
            </Button>
            <ConfirmDialogWithCheckbox
                isVisible={isDialogVisible}
                onConfirm={onConfirmButtonClick}
                onClose={onClose}
                onOutsideDialogClick={onClose}
                {...props}
            />
        </>
    );
};
