import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { CermSiteOverviewPage as View } from './cerm-site-overview-page';
import selector from './selector';
import actions from './actions';

export const CermSiteOverviewPage = withRouter(connect(
    selector,
    actions
)(View));
