import { createSelector } from 'reselect';
import { IUpsertCermProcessStepRootState } from 'reducers/upsert-cerm-process-step';
import { IRootState } from 'routes/store';

const getLookupActionPriorities = (state: IUpsertCermProcessStepRootState) => state.app.lookupActionPriorities;
const getLookupActionCategories = (state: IUpsertCermProcessStepRootState) =>  state.app.lookupActionCategories;
const getOperatingPlatforms = (state: IRootState) =>
    state.operatingPlatformState.operatingPlatforms;


export default createSelector(
    getLookupActionPriorities,
    getLookupActionCategories,
    getOperatingPlatforms,
    (lookupActionPriorities, lookupActionCategories,operatingPlatforms) => ({
        lookupActionPriorities,
        lookupActionCategories,
        operatingPlatforms
    })
);
