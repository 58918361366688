import { connect } from 'react-redux';

import actions from './actions';
import selectors from './selectors';
import { SiteProfilePage as View } from './site-profile-page';

export const SiteProfilePage = connect(
    selectors,
    actions
)(View);
