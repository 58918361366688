import { createSelector } from 'reselect';
import { IRootState } from '../store';

const getUsers = createSelector((state: IRootState) => state,
    (state) =>  {
        const y = state.persons.grid.items;
        return y;
    }
);

const getIsSaving = createSelector((state: IRootState) => state,
    (state) =>  {
        const y = state.notificationContainer.isSaving;
        return y;
    }
);

export default createSelector(
    getUsers,
    getIsSaving,
    (users, isSaving) => ({
        users,
        isSaving
    })
);
