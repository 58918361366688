import { IClientVerificationState } from "../../state/client-verification-state";
import { IAppAction } from '../../actions/app-action';
import { ClientVerificationActions } from '../../actions/actions-v2/client-verification-action-v2';
import { combineReducers } from "redux";
const INITIAL_STATE: IClientVerificationState = {
    verificationId: null,
    isLoading: false,
};


const isLoading = (
    state: IClientVerificationState['isLoading'] = INITIAL_STATE.isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case ClientVerificationActions.SEND_VERIFICATION:
            return true;
        case ClientVerificationActions.SEND_VERIFICATION_FULFILLED:
        case ClientVerificationActions.SEND_VERIFICATION_REJECTED:
            return false;
        default:
            return state;
    }
};

const verificationId = (
    state: IClientVerificationState['verificationId'] = INITIAL_STATE.verificationId,
    action: IAppAction
) => {
    switch (action.type) {
        case ClientVerificationActions.SEND_VERIFICATION_FULFILLED:
            return action.payload ? action.payload : state;
        default:
            return state;
    }
};



export const clientVerificationReducer = combineReducers<IClientVerificationState>({
    isLoading,
    verificationId
});