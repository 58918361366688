import { sendRequestSessionAlive } from 'actions/app-actions';
import { loadAppData as onInitialiseNav } from 'actions/actions-v2/nav-actions-v2';
import { loadOperatingPlatform, getOperatingPlatformMenus } from 'actions/actions-v2/operating-platform-action';
import { loadSiteMetaData } from 'actions/actions-v2/site-metadata-actions-v2';
import { loadMenu } from 'actions/actions-v2/menu-actions';
import { loadSiteSkillCards } from 'actions/skill-card-actions';

export default {
    onInitialiseNav,
    loadOperatingPlatform,
    sendRequestSessionAlive,
    loadSiteMetaData,
    loadMenu,
    getOperatingPlatformMenus,
    loadSiteSkillCards
};
