import { IAppAction } from 'actions/app-action';
import { combineReducers } from 'redux';
import { s2ab } from 'utils/export-utils';
import { AttachmentActions } from 'actions/actions-v2/attachment-action-v2';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import {
    IShiftHandover,
    IShiftHandoverCategories,
    IShiftHandoverSettings,
    IShiftHandoverOperatingPlatformCategories
} from 'models/shifts/shift-handover';
import { IShiftHandoverState } from 'state/shift-handover-state';
import { ShiftHandoverActions } from 'actions/actions-v2/shift-handover-actions';
import saveAs from 'file-saver';

const INITIAL_STATE: IShiftHandoverState = {
    isLoading: false,
    isExporting: false,
    isCreated: false,
    shiftHandovers: [],
    shiftHandover: {
        checkListItems: [],
        handoverItems: [],
        dateOfShiftHandover: null,
        notes: '', // in order for textarea to reset, it has not empty string instead of null
        checkListItemsVersion: '',
    } as IShiftHandover,
    attachments: [],
    history: [],
    settings: {} as IShiftHandoverSettings,
    shiftChecklist: [],
    adminShiftChecklist: [],
    shiftAcknowledge: false,
    shiftHandoverCategories: {} as IShiftHandoverCategories,
    adminOperatingPlatformShiftChecklist: [],
    shiftHandoverOperatingPlatformCategories: {} as IShiftHandoverOperatingPlatformCategories,
    selectedOperatingPlatform: '',
};

const isLoading = (
    state: IShiftHandoverState['isLoading'] = INITIAL_STATE.isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case ShiftHandoverActions.ACKNOWLEDGE_SHIFT_HANDOVER:
        case ShiftHandoverActions.LOAD:
        case ShiftHandoverActions.LOAD_LIST:
        case ShiftHandoverActions.LOAD_SETTINGS:
        case ShiftHandoverActions.LOAD_LAST_SUBMITTED:
        case ShiftHandoverActions.CREATE:
        case ShiftHandoverActions.UPDATE:
        case ShiftHandoverActions.DELETE_SHIFT_HANDOVER:
        case ShiftHandoverActions.LOAD_CHECKLIST_ADMIN:
        case ShiftHandoverActions.CREATE_CHECKLIST_ADMIN:
        case ShiftHandoverActions.UPDATE_CHECKLIST_ADMIN:
        case ShiftHandoverActions.LOAD_SHIFT_HANDOVER_CATEGORY:
        case ShiftHandoverActions.UPDATE_SHIFT_HANDOVER_CATEGORY:
        case ShiftHandoverActions.CREATE_SHIFT_HANDOVER_CATEGORY:
        case ShiftHandoverActions.DELETE_CHECKLIST_ADMIN:
        case ShiftHandoverActions.LOAD_CHECKLIST_VERSION:
        case ShiftHandoverActions.LOAD_OPERATING_PLATFORM_CHECKLIST_ADMIN:
        case ShiftHandoverActions.CREATE_OPERATING_PLATFORM_CHECKLIST_ADMIN:
        case ShiftHandoverActions.UPDATE_OPERATING_PLATFORM_CHECKLIST_ADMIN:
        case ShiftHandoverActions.DELETE_OPERATING_PLATFORM_CHECKLIST_ADMIN:
        case ShiftHandoverActions.LOAD_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY:
        case ShiftHandoverActions.UPDATE_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY:
        case ShiftHandoverActions.CREATE_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY:
            return true;

        case ShiftHandoverActions.ACKNOWLEDGE_FULFILLED:
        case ShiftHandoverActions.LOAD_FULFILLED:
        case ShiftHandoverActions.LOAD_REJECTED:
        case ShiftHandoverActions.LOAD_LIST_FULFILLED:
        case ShiftHandoverActions.LOAD_LIST_REJECTED:
        case ShiftHandoverActions.LOAD_SETTINGS_FULFILLED:
        case ShiftHandoverActions.LOAD_SETTINGS_REJECTED:
        case ShiftHandoverActions.LOAD_LAST_SUBMITTED_FULFILLED:
        case ShiftHandoverActions.LOAD_LAST_SUBMITTED_REJECTED:
        case ShiftHandoverActions.CREATE_FULFILLED:
        case ShiftHandoverActions.CREATE_REJECTED:
        case ShiftHandoverActions.UPDATE_FULFILLED:
        case ShiftHandoverActions.UPDATE_REJECTED:
        case ShiftHandoverActions.DELETE_SHIFT_HANDOVER_FULFILLED:
        case ShiftHandoverActions.DELETE_SHIFT_HANDOVER_REJECTED:
        case ShiftHandoverActions.LOAD_CHECKLIST_ADMIN_FULFILLED:
        case ShiftHandoverActions.CREATE_CHECKLIST_ADMIN_FULFILLED:
        case ShiftHandoverActions.CREATE_CHECKLIST_ADMIN_REJECTED:
        case ShiftHandoverActions.UPDATE_CHECKLIST_ADMIN_FULFILLED:
        case ShiftHandoverActions.UPDATE_CHECKLIST_ADMIN_REJECTED:
        case ShiftHandoverActions.DELETE_CHECKLIST_ADMIN_FULFILLED:
        case ShiftHandoverActions.DELETE_CHECKLIST_ADMIN_REJECTED:
        case ShiftHandoverActions.LOAD_CHECKLIST_ADMIN_REJECTED:
        case ShiftHandoverActions.LOAD_SHIFT_HANDOVER_CATEGORY_FULFILLED:
        case ShiftHandoverActions.LOAD_SHIFT_HANDOVER_CATEGORY_REJECTED:
        case ShiftHandoverActions.UPDATE_SHIFT_HANDOVER_CATEGORY_FULFILLED:
        case ShiftHandoverActions.UPDATE_SHIFT_HANDOVER_CATEGORY_REJECTED:
        case ShiftHandoverActions.CREATE_SHIFT_HANDOVER_CATEGORY_FULFILLED:
        case ShiftHandoverActions.CREATE_SHIFT_HANDOVER_CATEGORY_REJECTED:
        case ShiftHandoverActions.LOAD_CHECKLIST_VERSION_FULFILLED:
        case ShiftHandoverActions.LOAD_CHECKLIST_VERSION_REJECTED:
        case ShiftHandoverActions.LOAD_OPERATING_PLATFORM_CHECKLIST_ADMIN_FULFILLED:
        case ShiftHandoverActions.CREATE_OPERATING_PLATFORM_CHECKLIST_ADMIN_FULFILLED:
        case ShiftHandoverActions.CREATE_OPERATING_PLATFORM_CHECKLIST_ADMIN_REJECTED:
        case ShiftHandoverActions.UPDATE_OPERATING_PLATFORM_CHECKLIST_ADMIN_FULFILLED:
        case ShiftHandoverActions.UPDATE_OPERATING_PLATFORM_CHECKLIST_ADMIN_REJECTED:
        case ShiftHandoverActions.LOAD_OPERATING_PLATFORM_CHECKLIST_ADMIN_REJECTED:
        case ShiftHandoverActions.DELETE_OPERATING_PLATFORM_CHECKLIST_ADMIN_FULFILLED:
        case ShiftHandoverActions.DELETE_OPERATING_PLATFORM_CHECKLIST_ADMIN_REJECTED:
        case ShiftHandoverActions.LOAD_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY_FULFILLED:
        case ShiftHandoverActions.LOAD_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY_REJECTED:
        case ShiftHandoverActions.UPDATE_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY_FULFILLED:
        case ShiftHandoverActions.UPDATE_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY_REJECTED:
        case ShiftHandoverActions.CREATE_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY_FULFILLED:
        case ShiftHandoverActions.CREATE_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY_REJECTED:
            return false;

        default:
            return state;
    }
};

const isExporting = (
    state: IShiftHandoverState['isExporting'] = INITIAL_STATE.isExporting,
    action: IAppAction
) => {
    switch (action.type) {
        case ShiftHandoverActions.EXPORT:
            return true;

        case ShiftHandoverActions.EXPORT_FULFILLED:
            const blob = new Blob([s2ab(atob(action.payload.fileContents))], {
                type: 'application/excel',
            });
            saveAs(blob, action.payload.fileDownloadName);
            return false;

        case ShiftHandoverActions.EXPORT_REJECTED:
            return false;

        default:
            return state;
    }
};

const isCreated = (
    state: IShiftHandoverState['isCreated'] = INITIAL_STATE.isCreated,
    action: IAppAction
) => {
    switch (action.type) {
        case ShiftHandoverActions.CREATE_FULFILLED:
            return true;

        case ShiftHandoverActions.CLEANUP:
            return INITIAL_STATE.isCreated;

        default:
            return state;
    }
};

const shiftHandovers = (
    state: IShiftHandoverState['shiftHandovers'] = INITIAL_STATE.shiftHandovers,
    action: IAppAction
) => {
    switch (action.type) {
        case ShiftHandoverActions.LOAD_LIST_FULFILLED:
            if (action.payload) {
                return action.payload;
            }

        case ShiftHandoverActions.CLEANUP_LIST:
            return INITIAL_STATE.shiftHandovers;

        default:
            return state;
    }
};

const shiftHandover = (
    state: IShiftHandoverState['shiftHandover'] = INITIAL_STATE.shiftHandover,
    action: IAppAction
) => {
    switch (action.type) {
        case ShiftHandoverActions.ACKNOWLEDGE_FULFILLED:
        case ShiftHandoverActions.LOAD_FULFILLED:
        case ShiftHandoverActions.CREATE_FULFILLED:
        case ShiftHandoverActions.UPDATE_FULFILLED:
            if (action.payload) {
                return action.payload;
            }

            return state;

        case ShiftHandoverActions.LOAD_LAST_SUBMITTED_FULFILLED:
            if (action.payload) {
                return {
                    ...state,
                    checkListItems: action.payload.checkListItems,
                    handoverItems: action.payload.handoverItems,
                    checkListItemsVersion: action.payload.checkListItemsVersion,
                    checkListItemsVersions: action.payload.checkListItemsVersions,
                };
            }

            return state;

        case ShiftHandoverActions.CLEANUP:
            return INITIAL_STATE.shiftHandover;

        default:
            return state;
    }
};

const attachments = (
    state: IShiftHandoverState['attachments'] = INITIAL_STATE.attachments,
    action: IAppAction
) => {
    switch (action.type) {
        case AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_SHIFT_HANDOVER:
            if (action.payload) {
                return action.payload;
            }
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_SHIFT_HANDOVER:
            if (action.payload) {
                return [...state, ...action.payload];
            }
            return state;
        case AttachmentActions.DELETE_ATTACHMENT_FULFILLED:
            if (action.payload) {
                return state.filter((item: IAttachmentModel) => item.fileName !== action.payload);
            }
            return state;

        case ShiftHandoverActions.CLEANUP:
            return INITIAL_STATE.attachments;

        default:
            return state;
    }
};

const history = (
    state: IShiftHandoverState['history'] = INITIAL_STATE.history,
    action: IAppAction
) => {
    switch (action.type) {
        case ShiftHandoverActions.LOAD_HISTORY_FULFILLED:
            return action.payload ? action.payload : state;

        case ShiftHandoverActions.LOAD_HISTORY_REJECTED:
            return state;

        case ShiftHandoverActions.CLEANUP:
            return INITIAL_STATE.history;

        default:
            return state;
    }
};

const settings = (
    state: IShiftHandoverState['settings'] = INITIAL_STATE.settings,
    action: IAppAction
) => {
    switch (action.type) {
        case ShiftHandoverActions.LOAD_SETTINGS_FULFILLED:
            return action.payload ? action.payload : state;
        case ShiftHandoverActions.LOAD_SETTINGS_REJECTED:
            return state;
        default:
            return state;
    }
};

const shiftChecklist = (
    state: IShiftHandoverState['shiftChecklist'] = INITIAL_STATE.shiftChecklist,
    action: IAppAction
) => {
    switch (action.type) {
        case ShiftHandoverActions.LOAD_CHECKLIST_VERSION_FULFILLED:
            if (action.payload) {
                return action.payload;
            }

        default:
            return state;
    }
};
const adminShiftChecklist = (
    state: IShiftHandoverState['adminShiftChecklist'] = INITIAL_STATE.adminShiftChecklist,
    action: IAppAction
) => {
    switch (action.type) {
        case ShiftHandoverActions.CREATE_CHECKLIST_ADMIN_FULFILLED:
        case ShiftHandoverActions.UPDATE_CHECKLIST_ADMIN_FULFILLED:
            if (action.payload) {
                if (
                    state.some(
                        (checkList) => checkList.templateLinkId === action.payload.templateLinkId
                    )
                ) {
                    return state.map((checkList) => {
                        if (checkList.templateLinkId === action.payload.templateLinkId) {
                            checkList = action.payload;
                        }
                        return checkList;
                    });
                } else {
                    return [...state, action.payload];
                }
            }
        case ShiftHandoverActions.LOAD_CHECKLIST_ADMIN_FULFILLED:
            if (action.payload) {
                return action.payload;
            }
        case ShiftHandoverActions.DELETE_CHECKLIST_ADMIN_FULFILLED:
            if (action.payload) {
                return state.filter((item) => item.id !== action.payload.id);
            }

        default:
            return state;
    }
};

const selectedOperatingPlatform = (state: IShiftHandoverState['selectedOperatingPlatform'] = INITIAL_STATE.selectedOperatingPlatform,
    action: IAppAction
) => {
    switch (action.type) {
        case ShiftHandoverActions.LOAD_SHIFT_HANDOVER_OPERATING_PLATFORM:
            if (action.payload) {
                return action.payload.selectedOperatingPlatform;
            }
            break;
        default:
            return state;
    }
};

const adminOperatingPlatformShiftChecklist = (
    state: IShiftHandoverState['adminOperatingPlatformShiftChecklist'] = INITIAL_STATE.adminOperatingPlatformShiftChecklist,
    action: IAppAction
) => {
    switch (action.type) {
        case ShiftHandoverActions.CREATE_OPERATING_PLATFORM_CHECKLIST_ADMIN_FULFILLED:
        case ShiftHandoverActions.UPDATE_OPERATING_PLATFORM_CHECKLIST_ADMIN_FULFILLED:
            if (action.payload) {
                if (
                    state.some(
                        (checkList) => checkList.templateLinkId === action.payload.templateLinkId
                    )
                ) {
                    return state.map((checkList) => {
                        if (checkList.templateLinkId === action.payload.templateLinkId) {
                            checkList = action.payload;
                        }
                        return checkList;
                    });
                } else {
                    return [...state, action.payload];
                }
            }
        case ShiftHandoverActions.LOAD_OPERATING_PLATFORM_CHECKLIST_ADMIN_FULFILLED:
            if (action.payload) {
                return action.payload;
            }
        case ShiftHandoverActions.DELETE_OPERATING_PLATFORM_CHECKLIST_ADMIN:
            if (action.payload) {
                return state.filter((item) => item.id !== action.payload.id);
            }

        default:
            return state;
    }
};

const shiftAcknowledge = (
    state: IShiftHandoverState['shiftAcknowledge'] = INITIAL_STATE.shiftAcknowledge,
    action: IAppAction
) => {
    switch (action.type) {
        case ShiftHandoverActions.ACKNOWLEDGE_FULFILLED:
            if (action.payload) {
                return action.payload;
            }
            break;
        default:
            return state;
    }
};

const shiftHandoverCategories = (
    state: IShiftHandoverState['shiftHandoverCategories'] = INITIAL_STATE.shiftHandoverCategories,
    action: IAppAction
) => {
    switch (action.type) {
        case ShiftHandoverActions.LOAD_SHIFT_HANDOVER_CATEGORY_FULFILLED:
        case ShiftHandoverActions.CREATE_SHIFT_HANDOVER_CATEGORY_FULFILLED:
            return action.payload;
        default:
            return state;
    }
};

const shiftHandoverOperatingPlatformCategories = (
    state: IShiftHandoverState['shiftHandoverOperatingPlatformCategories'] = INITIAL_STATE.shiftHandoverOperatingPlatformCategories,
    action: IAppAction
) => {
    switch (action.type) {
        case ShiftHandoverActions.LOAD_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY_FULFILLED:
        case ShiftHandoverActions.CREATE_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY_FULFILLED:
            return action.payload;
        default:
            return state;
    }
};

export const shiftHandoverReducer = combineReducers<IShiftHandoverState>({
    isLoading,
    isExporting,
    isCreated,
    shiftHandovers,
    shiftHandover,
    attachments,
    history,
    settings,
    shiftChecklist,
    shiftAcknowledge,
    adminShiftChecklist,
    shiftHandoverCategories,
    adminOperatingPlatformShiftChecklist,
    shiftHandoverOperatingPlatformCategories,
    selectedOperatingPlatform,
});
