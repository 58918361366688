import React from 'react';
import { IAction, ActionPriority } from 'models/action';
import useTranslate from 'translations/translation-utils';

export const getActionPriority = (action: IAction) => {
const translate = useTranslate();
    switch (action.priorityId) {
        case ActionPriority.High:
            return <mark className="high">{translate('ActionDetailsPage.Priority.dropdown.High')}</mark>;

        case ActionPriority.Medium:
            return <mark className="medium">{translate('ActionDetailsPage.Priority.dropdown.Medium')}</mark>;

        case ActionPriority.Low:
        default:
            return <mark className="low">{translate('ActionDetailsPage.Priority.dropdown.Low')}</mark>;
    }
};
