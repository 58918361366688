import React from 'react';
import { SearchFilter } from '../searchFilter';
import {
    ICriticalSystemSpareFilters,
    Criticality,
} from 'models/critical-system-spares/critical-system-spare';
import { IKeyValue } from 'models';
import Select from 'react-select';
import { CheckboxFilter } from '../checkboxFilter';
import { FilterSet } from '../filterSet';
import useTranslate from 'translations/translation-utils';

interface IProps {
    filters: ICriticalSystemSpareFilters;
    categories: Array<IKeyValue<string>>;
    subCategories: Array<IKeyValue<string>>;
    onAddFilterKeyword: (keyword: string) => void;
    onRemoveFilterKeyword: (keyword: string) => void;
    onSelectCategory: (value: any) => void;
    onSelectSubCategory: (value: any) => void;
    onFilter: (key: string, element: string) => void;
    selectedCheckboxes: string[];
    selectedStatuses: string[];
    selectedCriticality: string[];
}

export const CriticalSystemSparesFilters: React.FC<IProps> = (props) => {
    const translate = useTranslate();

    const commonProps = {
        expandedFilters: ['criticality', 'status', 'showArchivedParts'],
        onFilter: props.onFilter,
        onToggleFilterExpanded: null,
    };

    const statuses: Array<IKeyValue<string>> = [
        { key: 'BelowMin', value: 'Below Min' },
        { key: 'CloseToMin', value: 'Close To Min' },
        { key: 'OK', value: 'OK' },
    ];

    const checkboxes: Array<IKeyValue<string>> = [
        { key: 'showArchivedRecords', value: 'Show archived parts only' },
    ];

    const criticality: Array<IKeyValue<string>> = [
        { key: Criticality.Critical, value: Criticality.Critical },
        { key: Criticality.NonCritical, value: Criticality.NonCritical },
    ];

    return (
        <>
            <SearchFilter
                title={translate('RiskRegisterPage.Title.Filters')}
                name="filterKeywords"
                values={props.filters.filterKeywords}
                onAddFilterKeyword={props.onAddFilterKeyword}
                onRemoveFilterKeyword={props.onRemoveFilterKeyword}
            />
            <FilterSet title="Category" fieldName="category">
                <div className="filter-fields">
                    <div className="field">
                        <Select
                            isMulti={false}
                            classNamePrefix="dropdown-multi"
                            placeholder={translate('DropDown.PlaceHolderText.SelectCategory')}
                            value={props.filters.selectedCategory}
                            onChange={(e) => props.onSelectCategory(e)}
                            options={props.categories.map((x) => ({
                                value: x.key,
                                label: x.value,
                            }))}
                        />
                    </div>
                </div>
            </FilterSet>
            <FilterSet title="Sub-Category" fieldName="subCategory">
                <div className="filter-fields">
                    <div className="field">
                        <Select
                            isMulti={false}
                            classNamePrefix="dropdown-multi"
                            placeholder={translate('DropDown.PlaceHolderText.SelectSubCategory')}
                            value={props.filters.selectedSubCategory}
                            onChange={(e) => props.onSelectSubCategory(e)}
                            options={props.subCategories.map((x) => ({
                                value: x.key,
                                label: x.value,
                            }))}
                        />
                    </div>
                </div>
            </FilterSet>
            <CheckboxFilter
                {...commonProps}
                title={translate('FilterDialog.Checkbox.Criticality')}
                name="criticality"
                selectedFilters={props.selectedCriticality}
                unreservedFilters={criticality}
            />
            <CheckboxFilter
                {...commonProps}
                title={translate('FilterDialog.Checkbox.Status')}
                name="status"
                selectedFilters={props.selectedStatuses}
                unreservedFilters={statuses}
            />
            <CheckboxFilter
                {...commonProps}
                title={translate('FilterDialog.Checkbox.ArchivedParts')}
                name="showArchivedParts"
                selectedFilters={props.selectedCheckboxes}
                unreservedFilters={checkboxes}
            />
        </>
    );
};
