import * as React from 'react';
import Documents from 'components/documents/documents';
import { Entity } from 'models/entity';
import { IAttachmentQuery } from 'models/attachment/attachment-query';
import { IAttachmentUpload } from 'models/attachment/attachment-upload';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import selectors from './selectors';
import actions from './actions';
import { connect } from 'react-redux';
import './../risk-register.scss';

interface IProps {
    id: string;
    loadAttachments: (query: IAttachmentQuery) => void;
    uploadAttachment: (upload: IAttachmentUpload) => void;
    attachments: IAttachmentModel[];
    isReadOnly: boolean;
}

const RiskMitigationDocuments: React.FC<IProps> = (props) => {
    return (
        <div className="tabs-container tabs-container-table-only">
            <Documents
                entity={Entity.RiskMitigationPlan}
                attachments={props.attachments.filter(x => x.id.includes(props.id))}
                loadAttachments={props.loadAttachments}
                uploadAttachment={props.uploadAttachment}
                id={props.id}
                isReadOnly={props.isReadOnly}
                dontLoadAutomatically={true}
            />
        </div>
    );
};

export default connect(selectors, actions)(RiskMitigationDocuments);
