//import { RouterAction } from 'connected-react-router';
import { RouterActions } from "redux-first-history";
import { Action } from 'redux';

import { BuildingActions, BuildingActionTypes } from './building-actions';
import { ClientActions, ClientActionTypes } from './client-actions';
import { PeopleActions, PeopleActionTypes } from './people-actions';
import { SiteActions, SiteActionTypes } from './site-actions';

export * from './building-actions';
export * from './client-actions';
export * from './people-actions';
export * from './site-actions';

export interface IChangeSearchValueAction extends Action {
    payload: {
        value: string;
    };
}

export interface ILoadSearchResultsAction extends Action {
    payload: {
        searchString: string;
    };
}

export const SiteRelationshipsActions = {
    ...SiteActions,
    ...PeopleActions,
    ...BuildingActions,
    ...ClientActions
};

export type SiteRelationshipsActionTypes =
    | SiteActionTypes
    | PeopleActionTypes
    | BuildingActionTypes
    | ClientActionTypes
    | RouterActions;
