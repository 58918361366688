import converter from 'number-to-words';
import pluralize from 'pluralize';

export function ready(fn: () => any): any {
    $(() => fn());
}

export const toggleAttribute = (attribute: string) => (el: Element, force: boolean = null) => {
    const value = force === null
        ? !(el.hasAttribute(attribute) && el.getAttribute(attribute) === 'true')
        : force;

    el.setAttribute(attribute, value.toString());
};

export const toggleClass = (className: string) => (el: Element, force: boolean = null) => {
    // IE11 doesn't support overload
    const hasClass = el.classList.contains(className);
    const shouldHaveClass = force === null
        ? !hasClass
        : force;

    if (shouldHaveClass) {
        el.classList.add(className);
    } else {
        el.classList.remove(className);
    }
};

export const lowerCaseString  = (str: string) => {
    return str.charAt(0).toLowerCase() + str.slice(1);
};

export const ConvertArrayFieldToWords = (propertyNameDisplay: string) => {
    if (propertyNameDisplay.includes('[')) {
      const numbers = (propertyNameDisplay.match(/\d+/g).map(Number));
      const nr = Number(numbers.join('')) + 1;
      const word = propertyNameDisplay.substring(0, propertyNameDisplay.indexOf('['));
      const singularWord = pluralize.singular(word);
      propertyNameDisplay = `${singularWord} ${converter.toWords(nr)} `;
    }
    return propertyNameDisplay;
  };

export const ConvertCamelCaseToWords = (str: string) => {
    return str.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1');
  };
