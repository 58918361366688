import { isEmpty } from 'lodash';
import * as React from 'react';

import { IBuilding } from '../../models/building';
import { IKeyedItem } from '../../models/keyed-item';
import { GridContentExpander } from '../gridContentExpander';

import './linked-buildings.scss';
import useTranslate from 'translations/translation-utils';

export interface ILinkedBuildingsStateProps {
    item: IKeyedItem;
    title: string;
    data: IBuilding[];
    isExpanded: boolean;
    isLoading: boolean;
}

export interface ILinkedBuildingsDispatchProps {
    onOpen: (item: IKeyedItem) => void;
    onClose: (item: IKeyedItem) => void;
}

interface IProps extends ILinkedBuildingsStateProps, ILinkedBuildingsDispatchProps { }

export const LinkedBuildings: React.FC<IProps> = (props) => {
    const onOpen = () => props.onOpen(props.item);
    const onClose = () => props.onClose(props.item);

    const isLoadedAndHasData = !props.isLoading && !isEmpty(props.data);
    const isLoadedAndHasNoData = !props.isLoading && isEmpty(props.data);
    const translate = useTranslate();

    return (
        <GridContentExpander
            buttonText={props.title}
            isExpanded={props.isExpanded}
            isLoading={props.isLoading}
            className="linked-buildings"
            onOpen={onOpen}
            onClose={onClose}
        >
            <>
                {isLoadedAndHasNoData ? (
                    <p>{translate('LinkedBuildings.NoBuildings')}</p>
                ) : null}

                {isLoadedAndHasData ? (
                    <div className="grid-data">
                        <div className="columns horizontal-table grid-data__headers">
                            <div className="column">{translate('LinkedBuildings.Code')}</div>
                            <div className="column">{translate('IncidentApprovals.Label.Name')}</div>
                            <div className="column">{translate('LinkedBuildings.City')}</div>
                            <div className="column">{translate('LinkedBuildings.Country')}</div>
                        </div>
                        {props.data.map((item, i) => (
                            <div className="columns horizontal-table" key={i}>
                                <div className="column">
                                    <h2 className="mobile-header">{translate('Attachments.AttachmentList.Type')}</h2>
                                    <div>{item.buildingCode}</div>
                                </div>
                                <div className="column">
                                    <h2 className="mobile-header">{translate('Attachments.AttachmentList.Type')}</h2>
                                    <div>{item.buildingName}</div>
                                </div>
                                <div className="column">
                                    <h2 className="mobile-header">{translate('Attachments.AttachmentList.Type')}</h2>
                                    <div>{item.city}</div>
                                </div>
                                <div className="column">
                                    <h2 className="mobile-header">{translate('Attachments.AttachmentList.Type')}</h2>
                                    <div>{item.country}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : null}
            </>
        </GridContentExpander>
    );
};
