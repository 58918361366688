import * as React from 'react';
import { SelectField } from 'components/form/fields/select-field';
import { Field, Control } from 'components/form';
import { BulmaSize } from 'enums/BulmaSize';
import { GetScorePercentage } from 'utils/decimal-utils';
import { Spinner } from '../spinner';
import { DatePickerField } from 'components/form/fields/date-picker-field';
import { ICermAssessmentResult, INextExpertReviewStatus } from 'models/cerm/cerm-assessment-result';
import { Formik, FormikProps } from 'formik';
import { Loader } from 'components/loader';
import { IPerson } from 'models/person';
import { Privilege } from 'enums/Privilege';
import { IKeyValue } from 'models';
import { checkPermissions } from 'utils/permission-utils';
import useTranslate from 'translations/translation-utils';

interface IProps {
    siteId: string;
    permissions: Array<IKeyValue<string>>;
    score: number;
    assessmentResult: ICermAssessmentResult;
    isLoading: boolean;
    siteAndPrivilegePersons: IPerson[];
    loadPersonsBySiteAndPrivilege: (siteId: string, privilegeName: string) => void;
    updateNextExpertReviewStatus: (model: Partial<INextExpertReviewStatus>) => void;
}

export const CermSiteScore: React.FC<IProps> = ({
    siteId,
    permissions,
    score,
    assessmentResult,
    isLoading,
    siteAndPrivilegePersons,
    loadPersonsBySiteAndPrivilege,
    updateNextExpertReviewStatus,
}) => {
    let formikBag: FormikProps<INextExpertReviewStatus>;

    React.useEffect(() => {
        if (siteId && (!siteAndPrivilegePersons || siteAndPrivilegePersons.length === 0)) {
            loadPersonsBySiteAndPrivilege(siteId, Privilege.CermAssessmentExpertReviewCreate);
        }
    }, [siteId]);

    const translate = useTranslate();

    const getInitialValues = () => {
        return {
            siteId,
            nextDate: assessmentResult?.expertReviewStatus?.nextDate,
            nextById: assessmentResult?.expertReviewStatus?.nextById,
            nextByName: assessmentResult?.expertReviewStatus?.nextByName,
        } as INextExpertReviewStatus;
    };

    const handleReviewerChange = (field: { value: string; label: string }) => {
        formikBag.setFieldValue('nextById', field.value);
        formikBag.setFieldValue('nextByName', field.label);

        onExpertReviewStatusUpdate();
    };

    const handleReviewDateChange = (value: Date) => {
        formikBag.setFieldValue('nextDate', value);

        onExpertReviewStatusUpdate();
    };

    const onExpertReviewStatusUpdate = () => {
        formikBag.submitForm();
    };

    const onSubmit = (value: Partial<INextExpertReviewStatus>) => {
        updateNextExpertReviewStatus(value);
    };

    const renderTab = (formik: FormikProps<INextExpertReviewStatus>) => {
        formikBag = formik;

        return (
            <>
                <div className="columns">
                    <div className="column is-1">
                        <Field
                            isHorizontal={true}
                            htmlFor="currentScore"
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <label className="label">{translate('Cerm.SiteScore.Labels.Score')}</label>
                                    <div className="score-site">
                                        {isLoading ? <Spinner /> : GetScorePercentage(score)}
                                    </div>
                                </Control>
                            </Field>
                        </Field>
                    </div>

                    <div className="column is-2">
                        <DatePickerField
                            id="nextReviewDate"
                            label={translate('Cerm.SiteScore.Labels.NextReviewDate')}
                            value={formikBag.values.nextDate ?? ''}
                            selected={formikBag.values.nextDate ?? ''}
                            handleChange={handleReviewDateChange}
                            showMonthDropdown={true}
                            showYearDropdown={true}
                            minDate={new Date()}
                            isDisabled={
                                !checkPermissions(
                                    [Privilege.CermAssessmentExpertReviewCreate],
                                    permissions
                                )
                            }
                        />
                    </div>

                    <div className="column is-2">
                        <Field
                            isHorizontal={true}
                            htmlFor="nextReviewerSelector"
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <SelectField
                                        label={translate('Cerm.SiteScore.Labels.By')}
                                        value={{
                                            value: formikBag.values.nextById,
                                            label: formikBag.values.nextByName,
                                        }}
                                        id="nextReviewerSelector"
                                        options={
                                            siteAndPrivilegePersons &&
                                            siteAndPrivilegePersons.map((x) => ({
                                                value: x.id,
                                                label: x.fullName,
                                            }))
                                        }
                                        handleChange={handleReviewerChange}
                                        handleBlur={formikBag.handleBlur}
                                        isDisabled={
                                            !checkPermissions(
                                                [Privilege.CermAssessmentExpertReviewCreate],
                                                permissions
                                            )
                                        }
                                    />
                                </Control>
                            </Field>
                        </Field>
                    </div>
                </div>
            </>
        );
    };

    return (
        <Loader loading={isLoading}>
            <Formik<Partial<INextExpertReviewStatus>>
                initialValues={getInitialValues()}
                validationSchema={null}
                onSubmit={onSubmit}
                render={renderTab}
                enableReinitialize={true}
                isInitialValid={true}
            />
        </Loader>
    );
};
