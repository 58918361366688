import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import OpportunityQuestionSets from './opportunity-question-sets';
import {
    loadOpportunityQuestions,
    createOpportunityQuestions,
    updateOpportunityQuestions,
    loadOpportunityQuestionsList,
    cleanUpOpportunity,
} from 'actions/actions-v2/opportunity-action';

const getLoading = (state: IRootState) => state.opportunityState.isLoading;
const getquestionsSet = (state: IRootState) => state.opportunityState.opportunityQuestionsList;
const mapStateToProps = createSelector(
    getLoading,
    getquestionsSet,
    (isLoading, opportunityQuestionsList) => ({
        isLoading,
        opportunityQuestionsList,
    })
);

const mapDispatchToProps = {
    loadOpportunityQuestions,
    createOpportunityQuestions,
    updateOpportunityQuestions,
    loadOpportunityQuestionsList,
    cleanUp: cleanUpOpportunity,
};

export const OpportnityQuestionSetsContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(OpportunityQuestionSets)
);
