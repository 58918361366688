import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { connect } from 'react-redux';
import RomonetIframe from './romonet-iframe';

const getReport = (state: IRootState) => state.romonetState.romonetReport;
const getLoadingStatus = (state: IRootState) => state.romonetState.isLoading;

const mapStateToProps = createSelector(getReport, getLoadingStatus, (romonetReport, isLoading) => ({
    romonetReport,
    isLoading,
}));

export const RomonetIframeContainer = connect(mapStateToProps)(RomonetIframe);
