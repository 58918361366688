import { isEmpty } from 'lodash';
import * as React from 'react';
import { Format } from 'components/dateTime';
import { IAttachmentCollection } from 'models/attachment-collection';
import { IKeyValue } from 'models/key-value';
import { checkPermission } from 'utils/permission-utils';
import { Spinner } from '../spinner';
import './attachment-collection.scss';
import { DateTimeUnix } from '../dateTime/date-time-unix';
import { ButtonWithConfirmDialog } from '../v2/components';
import { Privilege } from 'enums/Privilege';
import { useTranslate } from 'translations/translation-utils';
import Dotdotdot from 'react-dotdotdot';

export interface IAttachmentItemValue<T> {
    label: string;
    value: (input: T) => string;
}

export interface IAttachmentCollectionStateProps<T extends IAttachmentCollection = IAttachmentCollection> {
    id: string;
    childId?: string;
    data: T[];
    disabled?: boolean;
    permissions: Array<IKeyValue<string>>;
    isLoading?: boolean;
    onDelete: (attachmentId: string, entityId: string) => void;
}

export const AttachmentCollectionStatic: React.FC<IAttachmentCollectionStateProps> = (props) => {
    const translate = useTranslate();
    const onDelete: any = (item: string) => {
        props.onDelete(item, props.id);
    };

    return (
        <div className="AttachmentCollectionStatic">
            {!isEmpty(props.data) && (
                <div className="grid-data">
                    <div className="columns horizontal-table grid-data__headers ">
                        <div className="column is-3">{translate('Attachments.AttachmentList.FileName')}</div>
                        <div className="column is-3">{translate('Attachments.AttachmentList.Type')}</div>
                        <div className="column">{translate('Attachments.AttachmentList.Uploaded')}</div>
                        <div className="column" />
                        <div className="column" />
                    </div>

                    {props.data.map((item, i) => (
                        <div className="columns horizontal-table" key={i}>
                            <div className="column is-3">
                                <h2 className="mobile-header">{translate('Attachments.AttachmentList.FileName')}</h2>
                                {item.isClean && (
                                    <div><a
                                        className="is-link"
                                        href={item.isClean ? item.blobStorageUri : ''}
                                        target="_blank"
                                        title={item.fileName}
                                        rel="noopener noreferrer"

                                    >
                                        <Dotdotdot clamp={2}>{item.fileName}</Dotdotdot>
                                    </a></div>
                                )}
                                {!item.isClean && (
                                    <div><span>
                                        <Dotdotdot clamp={2}>{item.fileName}</Dotdotdot>
                                    </span></div>
                                )}
                            </div>
                            <div className="column is-3">
                                <h2 className="mobile-header">{translate('Attachments.AttachmentList.Type')}</h2>
                                <div>
                                    <Dotdotdot clamp={2}>{item.contentType}</Dotdotdot>
                                </div>
                            </div>
                            <div className="column">
                                <h2 className="mobile-header">{translate('Attachments.AttachmentList.Uploaded')}</h2>
                                <div>
                                    <DateTimeUnix value={item._ts} format={Format.DateAndTime} />
                                </div>
                            </div>
                            <div className="column">
                                {checkPermission(Privilege.AllAttachmentsDelete, props.permissions) &&
                                    <ButtonWithConfirmDialog
                                        title={translate('Attachments.AttachmentList.ButtonWithConfirmDialog.Title')}
                                        message={translate('Attachments.AttachmentList.ButtonWithConfirmDialog.Message')}
                                        buttonText={translate('Attachments.AttachmentList.ButtonWithConfirmDialog.ButtonText')}
                                        buttonType="archive"
                                        isButtonDisabled={props.disabled}
                                        onConfirm={onDelete.bind(this, item.id, item.siteId)}
                                    />}
                            </div>
                            <div className="column">
                                {item.isClean === null && (
                                    <div className="tag is-warning is-small">
                                        <span className="icon is-small is-loading">
                                            <Spinner />
                                        </span>
                                        <span>{translate('Attachments.AttachmentList.ScanStatus.Scanning')}</span>
                                    </div>
                                )}
                                {item.isClean === true && (
                                    <span className="tag is-success is-clearfix">{translate('Attachments.AttachmentList.ScanStatus.Scanned&Uploaded')}</span>
                                )}
                                {item.isClean === false && (
                                    <span className="tag is-danger is-clearfix">{translate('Attachments.AttachmentList.ScanStatus.Malwarefound')}</span>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
