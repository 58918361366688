import React from 'react';
import {
    Table,
    TableHeader,
    SortOrder,
    Button,
    Toolbar,
    ToolbarGroupRight,
    Alert,
} from 'components/v2/components';
import { IcheckList, IAuditorComplianceChecklist } from 'models/iauditor';
import { sortArrayBy } from 'utils/sort-utils';
import { usePageUIPreferences, UiPreferences } from 'utils/page-ui-preferences-utils';
import { Loader } from 'components/loader';
import { DateTime, Format } from 'components/dateTime';
import useTranslate from 'translations/translation-utils';
import appConfig from 'helpers/config-helper';
import { IAuditorTemplate } from './iauditor-template'
import { IOperatingPlatform } from 'models/operating-platform-model';
import { getTitlePrefixGeneric } from 'models/cerm/cerm-assessment-result';
import { IKeyValue } from 'models';

const config = appConfig();
interface IProps {
    isLoading: boolean,
    complianceCheckList: IcheckList;
    siteCode: string;
    isInitialising: boolean;
    loadComplianceChecklist: (siteCode: string, cermProcess: number) => void;
    hasIAuditorView: boolean;
    allOperatingPlatforms: IOperatingPlatform[];
    selectedSite: IKeyValue<string>;
}

const getOperatingPlatform = (site: any) => {
    return site ? site.operatingPlatform : '';
}

export const IAuditorInspectionsDocuments: React.FC<IProps> = ({
    complianceCheckList,
    /*   siteCode, */
    isLoading,
    isInitialising,
    /*    loadComplianceChecklist, */
    hasIAuditorView,
    allOperatingPlatforms,
    selectedSite
}) => {
    const translate = useTranslate();
    const titlePrefix = getTitlePrefixGeneric(getOperatingPlatform(selectedSite), allOperatingPlatforms);
    const [sortField, setSortField] = usePageUIPreferences(
        UiPreferences.RisksPageSortField,
        'riskLevelIdSortable'
    );
    const [sortOrder, setSortOrder] = usePageUIPreferences(
        UiPreferences.RisksPageSortOrder,
        SortOrder.desc
    );

    React.useEffect(() => {
        setSortOrder(SortOrder.off);
    }, []);

    const onToggleHandler = (field: string) => {
        if (sortField !== field) {
            setSortField(field);
            setSortOrder(SortOrder.asc);
            return;
        }

        const sort = sortOrder === SortOrder.asc ? SortOrder.desc : SortOrder.asc;
        setSortOrder(sort);
    };

    const getSortState = (field: string): SortOrder => {
        if (field === sortField) {
            return sortOrder;
        }

        return SortOrder.off;
    };

    const getSortedList = (): IAuditorComplianceChecklist[] => {
        if (sortOrder === SortOrder.off) {
            return sortArrayBy('riskLevelIdSortable', complianceCheckList.inspections, false);
        }

        return sortOrder === SortOrder.asc
            ? sortArrayBy(sortField, complianceCheckList.inspections, true)
            : sortArrayBy(sortField, complianceCheckList.inspections, false);
    };

    const getSortableTableHeader = (
        key: Extract<keyof IAuditorComplianceChecklist, string>,
        displayName: string,
        className?: string
    ) => {
        return (
            <TableHeader
                sortState={getSortState(key)}
                onToggle={() => onToggleHandler(key)}
                className={className}
            >
                {displayName}
            </TableHeader>
        );
    };

    const viewChecklistItem = (url: string) => {
        window.open(url, '_blank')
    };

    const buildTable = () => (
        <Table>
            <thead>
                <tr>
                    {getSortableTableHeader(
                        'inspectionName',
                        translate('IAuditorComplianceChecklistPage.table.DocumentName')
                    )}
                    {getSortableTableHeader(
                        'lastUpdatedDate',
                        translate('IAuditorComplianceChecklistPage.table.LastUpdatedDate')
                    )}
                    {getSortableTableHeader(
                        'createdDate',
                        translate('IAuditorComplianceChecklistPage.table.CreatedDate')
                    )}
                    {getSortableTableHeader(
                        'authorName',
                        translate('IAuditorComplianceChecklistPage.table.UpdatedBy')
                    )}
                    <th>{translate('IAuditorComplianceChecklistPage.table.View')}</th>
                </tr>
            </thead>

            <tbody className="clickable">
                {getSortedList().map((item, index) => (
                    <tr key={index}>
                        <td>
                            <span>{item.inspectionName}</span>
                        </td>
                        <td>
                            <DateTime value={item.lastUpdatedDate} format={Format.DateOnly} />
                        </td>
                        <td>
                            <DateTime value={item.createdDate} format={Format.DateOnly} />
                        </td>
                        <td>{item.authorName}</td>
                        <td>
                            <Button onClick={() => viewChecklistItem(item.webReportLink)}>
                                {translate('IAuditorComplianceChecklistPage.table.View')}
                            </Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );

    const loadMoreComplianceList = () => {
        window.open(`${config.REACT_APP_IAUDITOR_WEB_URL}/inspections`, '_blank');
    }
    return (
        <>
            <Loader loading={isLoading || isInitialising}>
                <div className="tabs-container tab-content compliance-checklist">
                    {!hasIAuditorView ? (
                        <Alert type="warning" title="Permission Denied">
                            <p>
                                {translate('iAuditor.Inspections.Message1')}
                            </p>
                        </Alert>
                    ) : (
                        !complianceCheckList.isTokenExist
                            ? (
                                <>
                                    <IAuditorTemplate message={translate('IAuditorComplianceChecklistPage.Messages.NoAccessToken').replace("CERM", titlePrefix).replace("e-CERM", `${"e-"}${titlePrefix}`)} />
                                    <p>
                                        {translate('IAuditorComplianceChecklistPage.Messages.DataRefreshFreq')} {config.REACT_APP_IAUDITOR_DATA_REFRESH_FREQ} {translate('IAuditorComplianceChecklistPage.Messages.DataRefreshFreqPostfix')}
                                    </p>
                                </>
                            ) : (
                                complianceCheckList && complianceCheckList.inspections.length > 0
                                    ? (
                                        <>
                                            <p className='header-note'>{translate('IAuditorComplianceChecklistPage.Note')}</p>

                                            {buildTable()}
                                            {
                                                config.REACT_APP_IAUDITOR_WEB_URL && <Toolbar type="save">
                                                    <ToolbarGroupRight>
                                                        <Button
                                                            onClick={loadMoreComplianceList}
                                                        >
                                                            {translate('IAuditorComplianceChecklistPage.button.LoadMore')}
                                                        </Button>
                                                    </ToolbarGroupRight>
                                                </Toolbar>}
                                            {<p>{translate('IAuditorComplianceChecklistPage.Messages.DataLastRefreshed')} <DateTime value={complianceCheckList.refreshedAt} format={Format.DateAndTime}></DateTime>
                                            </p>}
                                            {<p>{translate('IAuditorComplianceChecklistPage.Messages.DataRefreshFreq')} {config.REACT_APP_IAUDITOR_DATA_REFRESH_FREQ} {translate('IAuditorComplianceChecklistPage.Messages.DataRefreshFreqPostfix')}</p>}
                                            {complianceCheckList && complianceCheckList?.isRefreshing === true &&
                                                <p className="is-danger">{translate('IAuditorComplianceChecklistPage.Messages.IsRefreshInprogress')}</p>}
                                        </>
                                    ) : (
                                        !isLoading && (
                                            <>
                                                <p className='header-note'>{translate('IAuditorComplianceChecklistPage.Note')}</p>
                                                <IAuditorTemplate message={translate('IAuditorComplianceChecklistPage.Messages.NoInspection').replace("e-CERM", `${"e-"}${titlePrefix}`)} />
                                                {<p>{translate('IAuditorComplianceChecklistPage.Messages.DataLastRefreshed')} <DateTime value={complianceCheckList.refreshedAt} format={Format.DateAndTime}></DateTime>
                                                </p>}
                                                {<p>
                                                    {translate('IAuditorComplianceChecklistPage.Messages.DataRefreshFreq')} {config.REACT_APP_IAUDITOR_DATA_REFRESH_FREQ} {translate('IAuditorComplianceChecklistPage.Messages.DataRefreshFreqPostfix')}
                                                </p>}
                                                {complianceCheckList && complianceCheckList?.isRefreshing === true &&
                                                    <p className="is-danger">{translate('IAuditorComplianceChecklistPage.Messages.IsRefreshInprogress')}</p>}
                                            </>
                                        )
                                    )
                            )
                    )}
                </div>
            </Loader>
        </>
    );
};