import * as React from 'react';
import { IncidentApprovalStatus } from 'models';
import { Button } from '../v2/components';
import useTranslate from 'translations/translation-utils';

export interface IProps {
    approvalStatus: IncidentApprovalStatus;
    reapprove(): void;
}


export const IncidentReapprovalBar: React.FC<IProps> = (props) => {
    const translate = useTranslate();
    return (
        <div
            className='incident-reproval-tab'
        >
            <label
                className="incident-reproval-tab__label is-primary is-medium is-pulled-left"
            >
                {props.approvalStatus ===
                    IncidentApprovalStatus.Approved
                    ? translate('IncidentDetails.Label.ApproveStatus') :
                    translate('IncidentDetails.Label.RejectedStatus')
                }
            </label>
            <div className="is-pulled-right incident-reproval-tab--margin-right">
                <Button
                    className="incident-reproval-tab--margin-right"
                    id="incidentRejectButton"
                    onClick={props.reapprove}
                >
                    {translate('IncidentDetails.Label.ChangeResponse')}
                </Button>
            </div>
        </div>
    );
}