import * as React from 'react';

import './timeline-item-group.scss';

export const TimelineItemGroup: React.FC<React.PropsWithChildren<{}>> = ({ children }) => (
    <div className="TimelineItemGroup">
        <div className="TimelineItemGroup__items">
            {React.Children.map(children, (child, i) => (
                <div key={i} className="TimelineItemGroup__item">{child}</div>
            ))}
        </div>
    </div>
);
