import { connect } from 'react-redux';

import actions from './actions';
import selector from './selector';
import { RoleUpsertModal as View } from './view/index';

export const AddEditRoleModal = connect(
    selector,
    actions
)(View);
