import { IRootState } from "../store";
import {
    toggleEngineerSkillCategory,
    addEngineerSkillCategory,
    resetExpandedCategories,
} from "actions/actions-v2/engineer-action-v2";
import { connect } from "react-redux";
import { Dispatch } from "react";
import { IAppAction } from "actions/app-action";
import { CompetencyDNAProcessPage } from "./competency-dna-process-page";
import { IAppState } from "reducers/app";
import { createSelector } from "reselect";
import {
    loadSkills,
    loadSiteSkills,
    updateSiteSkills,
    createSiteSkills,
    ISiteSkillsFilters,
    loadObsoleteSkillCategories,
    resetSavedSiteSkillCardState,
} from 'actions/actions-v2';
import { ISiteSkills } from 'models';
import { getPermissions } from 'sharedSelectors';
import { checkPermissions } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import { loadSiteSkillCards } from 'actions/skill-card-actions';

export const getCurrentSiteName = createSelector(
    (state: IRootState) => state.app,
    (state: IAppState) => {
        const currentSiteId = state.siteId;
        const currentSite = currentSiteId
            ? state.lookupSites.find((m) => m.key === currentSiteId)
            : null;
        const currentSiteName: string = currentSite ? currentSite.value : "";
        return currentSiteName ? currentSiteName : "";
    }
);

const getSiteId = (state: IRootState) => state.app.siteId;
const getSkillState = (state: IRootState) => state.skillsState;
const getSkillCategories = (state: IRootState) =>
    state.engineersState.skillCategories;
const getLookupSites = (state: IRootState) => state.app.lookupSites;
const getLookupSitesClient = (state: IRootState) => state.app.lookupSitesClient;
const getSkillCards = (state: IRootState) =>
    state.skillCardState.siteSkillCards;

const mapStateToProps = createSelector(
    getSkillState,
    getSkillCategories,
    getSkillCards,
    getCurrentSiteName,
    getSiteId,
    getLookupSites,
    getLookupSitesClient,
    getPermissions,
    (
        skillsState,
        skillCategories,
        siteSkillCards,
        siteName,
        siteId,
        lookupSites,
        lookupSitesClient,
        permissions
    ) => ({
        isExportingEngineerSkills: skillsState.isExportingSkills,
        globalSkills: skillsState.skills,
        isLoading: skillsState.isLoading,
        isSaving: skillsState.isSaving,
        expandedCategories: skillsState.expandedCategories,
        skillCategories,
        siteName,
        siteId,
        lookupSites,
        lookupSitesClient,
        siteSkills: skillsState.siteSkills,
        hasHideShowPermission: checkPermissions(
            [Privilege.CompetencyDNACategorySkillHideShow],
            permissions
        ),
        hasYesNoPermission: checkPermissions(
            [Privilege.CompetencyDNASkillYesNo],
            permissions
        ),
        hasAccountSkillPermission: checkPermissions(
            [Privilege.CompetencyDNAAccountSkill],
            permissions
        ),
        siteSkillCards,
        siteSkillsSaved: skillsState.siteSkillsSaved
    })
);

const mapDispatchToProps = (dispatch: Dispatch<IAppAction>) => {
    return {
        loadEngineerSkills(siteId: string, qualificationCardType: string) {
            dispatch(loadSkills(qualificationCardType));
            dispatch(loadObsoleteSkillCategories(siteId, qualificationCardType));
        },
        loadSiteSkills(siteId: string) {
            const filters: ISiteSkillsFilters = { id: siteId };
            dispatch(loadSiteSkills(filters));
        },
        onToggleCategory(id: string) {
            dispatch(toggleEngineerSkillCategory(id));
        },
        onSaveSiteSkills(siteSkills: ISiteSkills, hasNoSiteSkillsRecord: boolean) {
            if (hasNoSiteSkillsRecord) {
                dispatch(createSiteSkills(siteSkills));
            } else {
                dispatch(updateSiteSkills(siteSkills));
            }
        },
        addEngineerSkillCategory(id: string) {
            dispatch(addEngineerSkillCategory(id));
        },
        resetExpandedCategories() {
            dispatch(resetExpandedCategories());
        },
        loadSiteSkillCards(siteId: string) {
            dispatch(loadSiteSkillCards(siteId));
        },
        resetSavedSiteSkillCardState() {
            dispatch(resetSavedSiteSkillCardState());
        }
    };
};

export const CompetencyDNAProcessContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CompetencyDNAProcessPage);
