import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { MockDrillReportActions } from 'actions/actions-v2/mock-drill-report-actions';
import { IMockDrillReport, IMockDrillReportsSummary } from 'models/mock-drills';
import { IAction } from 'models/action';

const controllerName = 'MockDrillReport';
const apiPath = `/api/${controllerName}`;

const getListActions = {
    action: MockDrillReportActions.LOAD_REPORTS_SUMMARY,
    actionFulfilled: MockDrillReportActions.LOAD_REPORTS_SUMMARY_FULFILLED,
    actionRejected: MockDrillReportActions.LOAD_REPORTS_SUMMARY_REJECTED
} as IActionType;

const getActions = {
    action: MockDrillReportActions.LOAD,
    actionFulfilled: MockDrillReportActions.LOAD_FULFILLED,
    actionRejected: MockDrillReportActions.LOAD_REJECTED
} as IActionType;

const createActions = {
    action: MockDrillReportActions.CREATE,
    actionFulfilled: MockDrillReportActions.CREATE_FULFILLED,
    actionRejected: MockDrillReportActions.CREATE_REJECTED
} as IActionType;

const updateActions = {
    action: MockDrillReportActions.UPDATE,
    actionFulfilled: MockDrillReportActions.UPDATE_FULFILLED,
    actionRejected: MockDrillReportActions.UPDATE_REJECTED
} as IActionType;

const deleteActions = {
    action: MockDrillReportActions.DELETE,
    actionFulfilled: MockDrillReportActions.DELETE_FULFILLED,
    actionRejected: MockDrillReportActions.DELETE_REJECTED,
} as IActionType;

const exportActions = {
    action: MockDrillReportActions.EXPORT,
    actionFulfilled: MockDrillReportActions.EXPORT_FULFILLED,
    actionRejected: MockDrillReportActions.EXPORT_REJECTED
} as IActionType;

const setParticipation = {
    action: MockDrillReportActions.SET_PARTICIPATION,
    actionFulfilled: MockDrillReportActions.SET_PARTICIPATION_FULFILLED,
    actionRejected: MockDrillReportActions.SET_PARTICIPATION_REJECTED
} as IActionType;

const loadHistory = {
    action: MockDrillReportActions.LOAD_HISTORY,
    actionFulfilled: MockDrillReportActions.LOAD_HISTORY_FULFILLED,
    actionRejected: MockDrillReportActions.LOAD_HISTORY_REJECTED
} as IActionType;

const loadActions = {
    action: MockDrillReportActions.LOAD_ACTIONS,
    actionFulfilled: MockDrillReportActions.LOAD_ACTIONS_FULFILLED,
    actionRejected: MockDrillReportActions.LOAD_ACTIONS_REJECTED
} as IActionType;

const loadMockDrillReportsSummaryEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getListActions).get<IMockDrillReportsSummary>(`${apiPath}/summary`);

const loadMockDrillReportEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getActions).getByIdAndShard<IMockDrillReport>(apiPath);

const createMockDrillReportEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createActions).post<IMockDrillReport>(apiPath);

const updateMockDrillReportEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateActions).put<IMockDrillReport>(apiPath);

const deleteMockDrillReportEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, deleteActions).deleteByIdAndSiteId<IMockDrillReport>(apiPath);

const exportMockDrillReportsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, exportActions).export(`${apiPath}/export`);

const setMockDrillReportParticipationEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, setParticipation).patch<IMockDrillReport>(`${apiPath}/participation`);

const loadHistoryEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, loadHistory).getById<IMockDrillReport>(`/api/${controllerName}/history`);

const loadRiskActionsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, loadActions).get<IAction>(`/api/action`);

export const mockDrillReportEpicsV2 = combineEpics(
    loadMockDrillReportsSummaryEpic,
    loadMockDrillReportEpic,
    createMockDrillReportEpic,
    updateMockDrillReportEpic,
    deleteMockDrillReportEpic,
    exportMockDrillReportsEpic,
    setMockDrillReportParticipationEpic,
    loadHistoryEpic,
    loadRiskActionsEpic
);
