import * as React from 'react';
import { Help, Control, Field } from '..';
import { BulmaColor } from 'enums/BulmaColor';
import Select from 'react-select';

interface IProps {
    id: string;
    label: string;
    value: IOption | IOption[];
    options: IOption[];
    className?: string;
    error?: string | string[];
    isMulti?: boolean;
    isDisabled?: boolean;
    isHorizontal?: boolean;
    handleChange: any;
    placeholder?: string;
    handleBlur?: (e: any) => void;
}

export interface IOption {
    value: string;
    label: string;
}

export const SelectField: React.FC<IProps> = (props) => {
    return (
        <Field isHorizontal={props.isHorizontal} className={props.className}>
            <label className="label">{props.label}</label>
            <Control>
                <Select
                    id={props.id}
                    isMulti={props.isMulti}
                    classNamePrefix="multi-select"
                    className="is-fullwidth is-medium"
                    value={props.value}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    options={props.options}
                    isDisabled={props.isDisabled}
                    placeholder={props.placeholder}
                />
            </Control>
            <Help bulmaColor={BulmaColor.Danger} isShown={true}>
                {props.error}
            </Help>
        </Field>
    );
};
