import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { MockDrillReportDetailsTab } from './mock-drill-report-details-tab';
import { getFilteredLookupSitePeopleWithAllowedRoles } from 'sharedSelectors';
import { IRootState } from 'routes/store';
import { loadPersonsLookup } from 'actions/actions-v2/person-action-v2';

const getMockDrillReportsSummary = (state: IRootState) =>
    state.mockDrillReportState.mockDrillReportsSummary;

const getSiteId = (state: IRootState) => state.app.siteId;

const mapStateToProps = createSelector(
    getFilteredLookupSitePeopleWithAllowedRoles,
    getMockDrillReportsSummary,
    getSiteId,
    (lookupSitePeopleRoles, mockDrillReportsSummary, siteId) => ({
        lookupSitePeopleRoles,
        mockDrillReportsSummary,
        siteId,
    })
);

const mapDispatchToProps = {
    loadPersonsLookup,
};

export const MockDrillReportDetailsTabContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MockDrillReportDetailsTab);
