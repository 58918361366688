import {
  EmeraldProgressIndicatorCoreInputs,
  IEmeraldProgressIndicatorCoreInputs,
} from './progress-indicator.inputs';
export abstract class EmeraldProgressSpinnerCoreInputs
  extends EmeraldProgressIndicatorCoreInputs
  implements IEmeraldProgressSpinnerCoreInputs
{
  sizeInRem?: number = 1;
  isWhite?: boolean;
}

export interface IEmeraldProgressSpinnerCoreInputs
  extends IEmeraldProgressIndicatorCoreInputs {
  sizeInRem?: number;
  isWhite?: boolean;
}
