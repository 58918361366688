import { IAppAction } from './app-action';
import { IGlobalSkill } from 'models/admin-cdna/global-skill';

export enum GlobalSkillActions {
    LOAD_GLOBAL_SKILL = '@@globalSkill/LOAD_GLOBAL_SKILL',
    LOAD_GLOBAL_SKILL_FULFILLED = '@@globalSkill/LOAD_GLOBAL_SKILL_FULFILLED',
    LOAD_GLOBAL_SKILL_REJECTED = '@@globalSkill/LOAD_GLOBAL_SKILL_REJECTED',

    LOAD_GLOBAL_SKILLS_BY_CATEGORY = '@@globalSkill/LOAD_GLOBAL_SKILLS_BY_CATEGORY',
    LOAD_GLOBAL_SKILLS_BY_CATEGORY_FULFILLED = '@@globalSkill/LOAD_GLOBAL_SKILLS_BY_CATEGORY_FULFILLED',
    LOAD_GLOBAL_SKILLS_BY_CATEGORY_REJECTED = '@@globalSkill/LOAD_GLOBAL_SKILLS_BY_CATEGORY_REJECTED',

    LOAD_GLOBAL_SKILLS_BY_CARD = '@@globalSkill/LOAD_GLOBAL_SKILLS_BY_CARD',
    LOAD_GLOBAL_SKILLS_BY_CARD_FULFILLED = '@@globalSkill/LOAD_GLOBAL_SKILLS_BY_CARD_FULFILLED',
    LOAD_GLOBAL_SKILLS_BY_CARD_REJECTED = '@@globalSkill/LOAD_GLOBAL_SKILLS_BY_CARD_REJECTED',

    CREATE_GLOBAL_SKILL = '@@globalSkill/CREATE_GLOBAL_SKILL',
    CREATE_GLOBAL_SKILL_FULFILLED = '@@globalSkill/CREATE_GLOBAL_SKILL_FULFILLED',
    CREATE_GLOBAL_SKILL_REJECTED = '@@globalSkill/CREATE_GLOBAL_SKILL_REJECTED',

    UPDATE_GLOBAL_SKILL = '@@globalSkill/UPDATE_GLOBAL_SKILL',
    UPDATE_GLOBAL_SKILL_FULFILLED = '@@globalSkill/UPDATE_GLOBAL_SKILL_FULFILLED',
    UPDATE_GLOBAL_SKILL_REJECTED = '@@globalSkill/UPDATE_GLOBAL_SKILL_REJECTED',

    DELETE_GLOBAL_SKILL = '@@globalSkill/DELETE_GLOBAL_SKILL',
    DELETE_GLOBAL_SKILL_FULFILLED = '@@globalSkill/DELETE_GLOBAL_SKILL_FULFILLED',
    DELETE_GLOBAL_SKILL_REJECTED = '@@globalSkill/DELETE_GLOBAL_SKILL_REJECTED',
}

export const loadGlobalSkill = (id: string): IAppAction => ({
    type: GlobalSkillActions.LOAD_GLOBAL_SKILL,
    payload: id,
});

export const loadGlobalSkillsByCategory = (categoryId: string): IAppAction => ({
    type: GlobalSkillActions.LOAD_GLOBAL_SKILLS_BY_CATEGORY,
    payload: categoryId,
});

export const loadGlobalSkillsByCard = (cardId: string): IAppAction => ({
    type: GlobalSkillActions.LOAD_GLOBAL_SKILLS_BY_CARD,
    payload: cardId,
});

export const createGlobalSkill = (skill: IGlobalSkill): IAppAction => ({
    type: GlobalSkillActions.CREATE_GLOBAL_SKILL,
    payload: skill,
});

export const updateGlobalSkill = (skill: IGlobalSkill): IAppAction => ({
    type: GlobalSkillActions.UPDATE_GLOBAL_SKILL,
    payload: skill,
});

export const deleteGlobalSkill = (categoryId: string, skillId: string): IAppAction => ({
    type: GlobalSkillActions.DELETE_GLOBAL_SKILL,
    payload: {
        shard: skillId,
        id: categoryId
    },
});