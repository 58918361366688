import * as React from 'react';
import Page from 'components/v2/page/Page';
import { RouteComponentProps } from 'react-router';
import { Formik, FormikProps } from 'formik';
import { history } from 'routes/App';
import {
    TabGroup,
    TabItem,
    ConfirmDialog,
    Button,
    Toolbar,
    ToolbarGroup,
    ToolbarGroupRight,
    ButtonWithConfirmDialog,
} from 'components/v2/components';
import { isEmpty } from 'lodash';
import {
    IMockDrillReport,
    MockDrillReportStatus,
    IMockDrillParticipant,
    MockDrillType,
    getScenarioTypeStringFromParam,
} from 'models/mock-drills';
import { IKeyValue } from 'models';
import { MockDrillReportDetailsTabContainer } from './tabs/details/mock-drill-report-details-tab-container';
import { MockDrillReportDetailsReadOnlyTabContainer } from './tabs/details/mock-drill-report-details-readonly-tab-container';
import { MockDrillReportParticipantsTabTabContainer } from './tabs/participants/mock-drill-report-participants-tab-container';
import { MockDrillReportActionsTabContainer } from './tabs/actions/mock-drill-report-actions-tab-container';
import { MockDrillReportDocumentsTabContainer } from './tabs/documents/mock-drill-report-documents-tab-container';
import { MockDrillReportHistoryTabContainer } from './tabs/history/mock-drill-report-history-tab-container';
import { mockDrillReportDetailsFormValidationSchema } from './tabs/details/mock-drill-report-details-tab';
import { MockDrillReportScenarioAttachmentsTabContainer } from './tabs/scenario-attachments/mock-drill-report-scenario-attachments-tab-container';
import { scrollToFirstValidationError } from 'utils/form-utils';
import { onRouteChange } from 'actions/app-actions';
import { buildReportPageUrl } from '../mock-drill-reports-page';
import { decodeUri, encodeUri } from 'utils/string-utils';
import { hasDifferences } from 'utils/array-utils';
import { MockDrillReportParticipantAlertContainer } from '../shared/mock-drill-report-participant-alert-container';
import { TextField } from 'components/form/fields/text-field';
import useTranslate from 'translations/translation-utils';

interface IParams {
    mockDrillId: string;
    mockDrillType: string;
    scenarioType: string;
    title: string;
    id?: string;
    tab: string;
    siteId?: string;
}

interface IProps extends RouteComponentProps<IParams> {
    siteId: string;
    isLoading: boolean;
    isMockDrillReportedCreated: boolean;
    mockDrillReport: IMockDrillReport;
    hasReadWritePermission: boolean;
    canEditTitle: boolean;
    userCanDeleteLocalMockDrillsReport: boolean;
    loadMockDrillReport: (id: string, siteId: string) => void;
    createMockDrillReport: (model: Partial<IMockDrillReport>) => void;
    updateMockDrillReport: (model: Partial<IMockDrillReport>) => void;
    deleteMockDrillReport: (id: string, siteId: string, redirectUrl: string) => void;
    clearForm: () => void;
}

enum Tab {
    details = 'details',
    participants = 'participants',
    actions = 'actions',
    documents = 'documents',
    history = 'history',
    scenarioAttachments = 'scenario-attachments',
}

export const MockDrillReportUpsertPage: React.FC<IProps> = ({
    match,
    siteId,
    isLoading,
    isMockDrillReportedCreated,
    mockDrillReport,
    hasReadWritePermission,
    canEditTitle,
    userCanDeleteLocalMockDrillsReport,
    ...props
}) => {
    const translate = useTranslate();
    const currentTab = match.params.tab.toLowerCase();
    const [isValid, setIsValid] = React.useState(false);
    const [isFormDirty, setIsFormDirty] = React.useState(false);
    const [showTabDisabledDialog, setShowTabDisabledDialog] = React.useState(false);
    const [participants, setParticipants] = React.useState<IMockDrillParticipant[]>([]);
    const [shouldBlockNavigation, setShouldBlockNavigation] = React.useState(false);
    const [selectedTab, setSelectedTab] = React.useState(currentTab);
    const mockDrillId = match.params.mockDrillId;
    const mockDrillType = match.params.mockDrillType;
    const scenarioType = match.params.scenarioType;
    const scenarioTypeString = getScenarioTypeStringFromParam(scenarioType);
    const mockDrillReportId = match.params.id;
    const mockDrillReportTitleFromUri = decodeUri(match.params.title);
    const mockDrillsPageUrl = `/MockDrills/${scenarioType}`;
    const mockDrillReportsPageUrl = `/MockDrill/${scenarioType}/Reports/${mockDrillType}/${mockDrillId}`;
    const customLinks: Array<IKeyValue<string>> = [
        { key: mockDrillsPageUrl, value: scenarioTypeString },
        { key: mockDrillReportsPageUrl, value: 'Reports' },
    ];
    const isReportCompleted =
        mockDrillReport && mockDrillReport.status === MockDrillReportStatus.Completed;

    const title =
        mockDrillReportId && mockDrillReport && mockDrillReport.title
            ? mockDrillReport.title
            : mockDrillReportTitleFromUri;
    const [mockDrillReportTitle, setMockDrillReportTitle] = React.useState(title);
    const [mockDrillReportTitleError, setMockDrillReportTitleError] = React.useState('');

    let formikBag: FormikProps<IMockDrillReport>;

    React.useEffect(() => {
        if (!mockDrillReportId && !match.params.title) {
            onRouteChange(mockDrillReportsPageUrl);
        }

        return () => {
            props.clearForm();
        };
    }, []);

    React.useEffect(() => {
        if (siteId && mockDrillReportId) {
            props.loadMockDrillReport(mockDrillReportId, siteId);
        }
    }, [siteId, mockDrillReportId]);

    React.useEffect(() => {
        if (mockDrillReport) {
            setParticipants(mockDrillReport.participants);
        }
    }, [mockDrillReport]);

    React.useEffect(() => {
        if (isMockDrillReportedCreated && mockDrillReport.id) {
            const url = buildReportPageUrl(
                mockDrillId,
                mockDrillType,
                mockDrillReport.title,
                scenarioType,
                mockDrillReport.id
            );
            props.clearForm();
            onRouteChange(url);
        }
    }, [isMockDrillReportedCreated, mockDrillReport]);

    React.useEffect(() => {
        if (hasDifferences(mockDrillReport.participants, participants, 'id')) {
            setShouldBlockNavigation(true);
            return;
        }

        setShouldBlockNavigation(false);
    }, [formikBag, participants]);

    const setNavLink = (tab: Tab) => {
        if (mockDrillReportId) {
            return match.path
                .replace(':mockDrillId', mockDrillId)
                .replace(':mockDrillType', mockDrillType)
                .replace(':title', encodeUri(mockDrillReportTitleFromUri))
                .replace(':id?', mockDrillReportId)
                .replace(':tab', tab)
                .replace(':siteId?', '')
                .replace(':scenarioType', scenarioType);
        }

        return match.path
            .replace(':mockDrillId', mockDrillId)
            .replace(':mockDrillType', mockDrillType)
            .replace(':title', encodeUri(mockDrillReportTitleFromUri))
            .replace(':id?/', '')
            .replace(':tab', tab)
            .replace(':siteId?', '')
            .replace(':scenarioType', scenarioType);
    };

    const onSubmit = (value: Partial<IMockDrillReport>) => {
        setShouldBlockNavigation(false);
        setIsFormDirty(false);
        if (mockDrillReportId) {
            const dataToUpdate = {
                ...value,
                participants,
                title: mockDrillReportTitle,
                reloadUrl:
                    value.status === MockDrillReportStatus.Completed
                        ? mockDrillReportsPageUrl
                        : null,
            };

            props.updateMockDrillReport(dataToUpdate);
        } else {
            props.createMockDrillReport({
                ...value,
                siteId,
                title: mockDrillReportTitle,
                mockDrillId,
                mockDrillType,
            });
        }
    };

    const handleClick = (event: any, tab: Tab) => {
        event.preventDefault();

        if (!isMockDrillReportIdAvailable()) {
            if (tab === Tab.details || tab === Tab.scenarioAttachments) {
                checkFormValidation(tab);
                return;
            }

            setShowTabDisabledDialog(true);
            return;
        }

        checkFormValidation(tab);
    };

    const checkFormValidation = (tab: Tab) => {
        formikBag.validateForm().then((errors) => {
            if (!Object.keys(errors).some((field) => field.length > 0)) {
                setShouldBlockNavigation(false);
                setSelectedTab(tab);
                history.push(setNavLink(tab));
            }
        });
    }

    const onSaveAsDraftClick = () => {
        onSave();
    };

    const onCompleteButtonClick = () => {
        formikBag.setFieldValue('status', MockDrillReportStatus.Completed);
        onSave();
    };

    const onSave = () => {
        formikBag.validateForm().then(() => {
            if (isValid && mockDrillReportTitle != "") {
                formikBag.submitForm();
            } else {
                scrollToFirstValidationError();
            }
        });
    };

    const renderTab = (formik: FormikProps<IMockDrillReport>) => {
        formikBag = formik;
        setIsValid(formik.isValid);
        setIsFormDirty(formik.dirty);

        switch (currentTab) {
            case Tab.details:
                return hasReadWritePermission ? (
                    <MockDrillReportDetailsTabContainer
                        formikBag={formikBag}
                        isLoading={isLoading}
                        scenarioType={scenarioType}
                    />
                ) : (
                    <MockDrillReportDetailsReadOnlyTabContainer
                        formikBag={formikBag}
                        isLoading={isLoading}
                        scenarioType={scenarioType}
                    />
                );

            case Tab.scenarioAttachments:
                return (
                    mockDrillType === MockDrillType.Global && (
                        <MockDrillReportScenarioAttachmentsTabContainer id={mockDrillId} />
                    )
                );

            case Tab.participants:
                return (
                    <MockDrillReportParticipantsTabTabContainer
                        isLoading={isLoading}
                        formikBag={formikBag}
                        participants={participants}
                        setParticipants={setParticipants}
                        hasReadWritePermission={hasReadWritePermission}
                    />
                );

            case Tab.actions:
                return (
                    <MockDrillReportActionsTabContainer
                        id={mockDrillReportId}
                        isLoading={isLoading}
                        hasReadWritePermission={hasReadWritePermission}
                    />
                );

            case Tab.documents:
                return (
                    <MockDrillReportDocumentsTabContainer
                        id={mockDrillReportId}
                        isReadOnly={isReportCompleted || !hasReadWritePermission}
                    />
                );

            case Tab.history:
                return (
                    <MockDrillReportHistoryTabContainer id={mockDrillReportId} siteId={siteId} />
                );
        }
    };

    const isMockDrillReportIdAvailable = () => {
        return isEmpty(mockDrillReportId) ? false : true;
    };

    const isTabChanging = selectedTab !== currentTab;
    const isTabDisabled = !mockDrillReportId;

    const showSaveAsDraftButton =
        !mockDrillReportId ||
        (mockDrillReport && mockDrillReport.status === MockDrillReportStatus.Draft);

    const shouldShowShowConfirmNavigateAwayDialog =
        !isMockDrillReportedCreated && !isTabChanging && (shouldBlockNavigation || isFormDirty);

    const handleTitleChange = (e) => {
        setMockDrillReportTitle(e.target.value);
    }

    const handleTitleBlur = () => {
        setMockDrillReportTitleError(mockDrillReportTitle.trim() == "" ? translate('MockDrill.Repost.Lable.3') : "")
    }

    const isTitleEditable =
        hasReadWritePermission &&
        canEditTitle &&
        mockDrillReport.mockDrillType === MockDrillType.Local;

    const onArchive = () => {
        props.deleteMockDrillReport(
            formikBag.values.id,
            formikBag.values.siteId,
            mockDrillReportsPageUrl
        );
    };

    const archiveButton = () => {
        return mockDrillReportId && (
            <ButtonWithConfirmDialog
                onConfirm={onArchive}
                buttonType={'archive'}
                buttonText={translate('MockDrills.Report.UpsertPage.Button.Archive')}
                message={translate('MockDrills.Report.UpsertPage.Archive.Message')}
                title={translate('MockDrills.Report.UpsertPage.Archive.Title')}
                isButtonDisabled={!userCanDeleteLocalMockDrillsReport}
                isLoading={isLoading}
            />
        );
    };

    return (
        <Page
            title={mockDrillReportTitle}
            showHeader={!isTitleEditable}
            breadcrumbCustomLinks={customLinks}
            className="mock-drill-report-upsert-page"
            redirectOnSiteChange={true}
            redirectOnSiteChangeUrl={mockDrillsPageUrl}
            showConfirmNavigateAwayDialog={shouldShowShowConfirmNavigateAwayDialog}
        >
            {isTitleEditable && (
                <div className="column mock-drill-reports-page-title">
                    <TextField
                        id="title"
                        label={translate('MockDrill.Repost.Lable.4') + '*'}
                        placeholder={translate('MockDrill.Repost.Lable.5')}
                        value={mockDrillReportTitle}
                        handleChange={handleTitleChange}
                        handleBlur={handleTitleBlur}
                        error={mockDrillReportTitleError}
                    />
                </div>
            )}

            <MockDrillReportParticipantAlertContainer mockDrillReport={mockDrillReport} />

            <TabGroup isLoading={isLoading}>
                <TabItem
                    title={translate('RiskRegisterUpsertPage.Tabs.Details')}
                    tab={Tab.details}
                    currentTab={currentTab}
                    onClick={handleClick}
                />
                {mockDrillType === MockDrillType.Global && (
                    <TabItem
                        title={translate('MockDrill.Repost.Lable.6')}
                        tab={Tab.scenarioAttachments}
                        currentTab={currentTab}
                        onClick={handleClick}
                    />
                )}
                <TabItem
                    title={translate('MockDrill.Repost.Lable.7')}
                    tab={Tab.participants}
                    currentTab={currentTab}
                    isDisabled={isTabDisabled}
                    onClick={handleClick}
                />
                <TabItem
                    title={translate('SideNavBar.Labels.Actions')}
                    tab={Tab.actions}
                    currentTab={currentTab}
                    isDisabled={isTabDisabled}
                    onClick={handleClick}
                />
                <TabItem
                    title={translate('RiskRegisterUpsertPage.Tabs.Documents')}
                    tab={Tab.documents}
                    currentTab={currentTab}
                    isDisabled={isTabDisabled}
                    onClick={handleClick}
                />
                <TabItem
                    title={translate('RiskRegisterUpsertPage.Tabs.History')}
                    tab={Tab.history}
                    currentTab={currentTab}
                    isDisabled={isTabDisabled}
                    onClick={handleClick}
                />
            </TabGroup>
            <Formik<Partial<IMockDrillReport>>
                initialValues={mockDrillReport}
                onSubmit={onSubmit}
                validationSchema={mockDrillReportDetailsFormValidationSchema}
                render={renderTab}
                enableReinitialize={true}
                isInitialValid={mockDrillReportId ? true : false}
                validateOnBlur={true}
                validateOnChange={false}
            />
            <ConfirmDialog
                title={translate('RiskRegisterUpsertPage.ConfirmDialog.Title')}
                message={translate('MockDrill.Repost.Lable.8')}
                buttonCancelHide={true}
                buttonConfirmText={translate('RiskRegisterUpsertPage.ConfirmDialog.ButtonConfirmText')}
                isVisible={showTabDisabledDialog}
                onConfirm={() => setShowTabDisabledDialog(false)}
                onOutsideDialogClick={() => setShowTabDisabledDialog(false)}
            />
            <Toolbar type="save">
                <ToolbarGroup>
                    <Button
                        id="cancel"
                        buttonType="cancel"
                        isLoading={isLoading}
                        onClick={() => history.push(mockDrillReportsPageUrl)}
                    >
                        {translate('Globals.Label.Cancel')}
                    </Button>
                    {archiveButton()}
                </ToolbarGroup>
                {hasReadWritePermission && (
                    <ToolbarGroupRight>
                        {showSaveAsDraftButton && (
                            <Button
                                id="btnSaveDraft"
                                type="submit"
                                buttonType="save"
                                onClick={onSaveAsDraftClick}
                                isLoading={isLoading}
                            >
                                {translate('QuestionForm.Lable.8')}
                            </Button>
                        )}
                        {mockDrillReportId && (
                            <Button
                                id="btnComplete"
                                type="submit"
                                buttonType="save"
                                onClick={onCompleteButtonClick}
                                isLoading={isLoading}
                                disabled={isEmpty(participants)}
                            >
                                {
                                    showSaveAsDraftButton ?
                                        translate('Globals.Label.Complete') :
                                        translate('Globals.Label.Save')
                                }
                            </Button>
                        )}
                    </ToolbarGroupRight>
                )}
            </Toolbar>
        </Page>
    );
};
