import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { AttachmentActions } from 'actions/actions-v2/attachment-action-v2';
import { IAttachmentModel } from 'models/attachment/attachment-model';

const controllerName = 'attachment';
const apiPath = `/api/${controllerName}`;

const getEngineerActions = {
    action: AttachmentActions.LOAD_ATTACHMENTS_ENGINEER,
    actionFulfilled: AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_ENGINEER,
    actionRejected: AttachmentActions.LOAD_ATTACHMENTS_REJECTED_ENGINEER,
} as IActionType;

const getProcessStepActions = {
    action: AttachmentActions.LOAD_ATTACHMENTS_PROCESS_STEP,
    actionFulfilled: AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_PROCESS_STEP,
    actionRejected: AttachmentActions.LOAD_ATTACHMENTS_REJECTED_PROCESS_STEP,
} as IActionType;

const getIncidentActions = {
    action: AttachmentActions.LOAD_ATTACHMENTS_INCIDENT,
    actionFulfilled: AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_INCIDENT,
    actionRejected: AttachmentActions.LOAD_ATTACHMENTS_REJECTED_INCIDENT,
} as IActionType;

const getSpareActions = {
    action: AttachmentActions.LOAD_ATTACHMENTS_SPARE,
    actionFulfilled: AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_SPARE,
    actionRejected: AttachmentActions.LOAD_ATTACHMENTS_REJECTED_SPARE,
} as IActionType;

const getRiskActions = {
    action: AttachmentActions.LOAD_ATTACHMENTS_RISK,
    actionFulfilled: AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_RISK,
    actionRejected: AttachmentActions.LOAD_ATTACHMENTS_REJECTED_RISK,
} as IActionType;

const getActionAttachments = {
    action: AttachmentActions.LOAD_ATTACHMENTS_ACTION,
    actionFulfilled: AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_ACTION,
    actionRejected: AttachmentActions.LOAD_ATTACHMENTS_REJECTED_ACTION,
} as IActionType;

const uploadActionAttachment = {
    action: AttachmentActions.UPLOAD_ATTACHMENT_ACTION,
    actionFulfilled: AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_ACTION,
    actionRejected: AttachmentActions.UPLOAD_ATTACHMENT_REJECTED_ACTION,
} as IActionType;

const getOutcomeAttachments = {
    action: AttachmentActions.LOAD_ATTACHMENTS_OUTCOME,
    actionFulfilled: AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_OUTCOME,
    actionRejected: AttachmentActions.LOAD_ATTACHMENTS_REJECTED_OUTCOME,
} as IActionType;

const uploadOutcomeAttachment = {
    action: AttachmentActions.UPLOAD_ATTACHMENT_OUTCOME,
    actionFulfilled: AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_OUTCOME,
    actionRejected: AttachmentActions.UPLOAD_ATTACHMENT_REJECTED_OUTCOME,
    actionCancelled: AttachmentActions.UPLOAD_ATTACHMENT_CANCELLED,
} as IActionType;

const getMockDrillReportAttachments = {
    action: AttachmentActions.LOAD_ATTACHMENTS_MOCK_DRILL_REPORT,
    actionFulfilled: AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_MOCK_DRILL_REPORT,
    actionRejected: AttachmentActions.LOAD_ATTACHMENTS_REJECTED_MOCK_DRILL_REPORT,
} as IActionType;

const uploadMockDrillReportAttachment = {
    action: AttachmentActions.UPLOAD_ATTACHMENT_MOCK_DRILL_REPORT,
    actionFulfilled: AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_MOCK_DRILL_REPORT,
    actionRejected: AttachmentActions.UPLOAD_ATTACHMENT_REJECTED_MOCK_DRILL_REPORT,
    actionCancelled: AttachmentActions.UPLOAD_ATTACHMENT_CANCELLED,
} as IActionType;

const getShiftHandoverAttachments = {
    action: AttachmentActions.LOAD_ATTACHMENTS_SHIFT_HANDOVER,
    actionFulfilled: AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_SHIFT_HANDOVER,
    actionRejected: AttachmentActions.LOAD_ATTACHMENTS_REJECTED_SHIFT_HANDOVER,
} as IActionType;

const uploadShiftHandoverAttachment = {
    action: AttachmentActions.UPLOAD_ATTACHMENT_SHIFT_HANDOVER,
    actionFulfilled: AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_SHIFT_HANDOVER,
    actionRejected: AttachmentActions.UPLOAD_ATTACHMENT_REJECTED_SHIFT_HANDOVER,
    actionCancelled: AttachmentActions.UPLOAD_ATTACHMENT_CANCELLED,
} as IActionType;

const getSiteAttachments = {
    action: AttachmentActions.LOAD_ATTACHMENTS_SITE,
    actionFulfilled: AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_SITE,
    actionRejected: AttachmentActions.LOAD_ATTACHMENTS_REJECTED_SITE,
} as IActionType;

const getEngineerReportAttachments = {
    action: AttachmentActions.LOAD_ATTACHMENTS_ENGINEER_REPORT,
    actionFulfilled: AttachmentActions.LOAD_ATTACHMENTS_ENGINEER_REPORT_FULFILLED,
    actionRejected: AttachmentActions.LOAD_ATTACHMENTS_ENGINEER_REPORT_REJECTED,
} as IActionType;

const uploadSiteAttachment = {
    action: AttachmentActions.UPLOAD_ATTACHMENT_SITE,
    actionFulfilled: AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_SITE,
    actionRejected: AttachmentActions.UPLOAD_ATTACHMENT_REJECTED_SITE,
    actionCancelled: AttachmentActions.UPLOAD_ATTACHMENT_CANCELLED,
} as IActionType;

const getGlobalMockDrillAttachments = {
    action: AttachmentActions.LOAD_ATTACHMENTS_GLOBAL_MOCK_DRILL,
    actionFulfilled: AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_GLOBAL_MOCK_DRILL,
    actionRejected: AttachmentActions.LOAD_ATTACHMENTS_REJECTED_GLOBAL_MOCK_DRILL,
} as IActionType;

const getCermAssesmentAttachments = {
    action: AttachmentActions.LOAD_ATTACHMENTS_CERM_ASSESSMENT,
    actionFulfilled: AttachmentActions.LOAD_ATTACHMENTS_CERM_ASSESSMENT_FULFILLED,
    actionRejected: AttachmentActions.LOAD_ATTACHMENTS_CERM_ASSESSMENT_REJECTED,
} as IActionType;

const uploadGlobalMockDrillAttachment = {
    action: AttachmentActions.UPLOAD_ATTACHMENT_GLOBAL_MOCK_DRILL,
    actionFulfilled: AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_GLOBAL_MOCK_DRILL,
    actionRejected: AttachmentActions.UPLOAD_ATTACHMENT_REJECTED_GLOBAL_MOCK_DRILL,
    actionCancelled: AttachmentActions.UPLOAD_ATTACHMENT_CANCELLED,
} as IActionType;

const postActions = {
    action: AttachmentActions.UPLOAD_ATTACHMENT,
    actionFulfilled: AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED,
    actionRejected: AttachmentActions.UPLOAD_ATTACHMENT_REJECTED,
    actionCancelled: AttachmentActions.UPLOAD_ATTACHMENT_CANCELLED,
} as IActionType;

const attchSpareActions = {
    action: AttachmentActions.UPLOAD_ATTACHMENT_SPARE,
    actionFulfilled: AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_SPARE,
    actionRejected: AttachmentActions.UPLOAD_ATTACHMENT_REJECTED_SPARE,
    actionCancelled: AttachmentActions.UPLOAD_ATTACHMENT_CANCELLED,
} as IActionType;

const attchRiskActions = {
    action: AttachmentActions.UPLOAD_ATTACHMENT_RISK,
    actionFulfilled: AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_RISK,
    actionRejected: AttachmentActions.UPLOAD_ATTACHMENT_REJECTED_RISK,
    actionCancelled: AttachmentActions.UPLOAD_ATTACHMENT_CANCELLED,
} as IActionType;

const attchIncidentActions = {
    action: AttachmentActions.UPLOAD_ATTACHMENT_INCIDENT,
    actionFulfilled: AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_INCIDENT,
    actionRejected: AttachmentActions.UPLOAD_ATTACHMENT_REJECTED_INCIDENT,
    actionCancelled: AttachmentActions.UPLOAD_ATTACHMENT_CANCELLED,
} as IActionType;

const attchProcessStepActions = {
    action: AttachmentActions.UPLOAD_ATTACHMENT_PROCESS_STEP,
    actionFulfilled: AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_PROCESS_STEP,
    actionRejected: AttachmentActions.UPLOAD_ATTACHMENT_REJECTED_PROCESS_STEP,
    actionCancelled: AttachmentActions.UPLOAD_ATTACHMENT_CANCELLED,
} as IActionType;

const attchEngineerReportActions = {
    action: AttachmentActions.UPLOAD_ATTACHMENT_ENGINEER_REPORT,
    actionFulfilled: AttachmentActions.UPLOAD_ATTACHMENT_ENGINEER_REPORT_FULFILLED,
    actionRejected: AttachmentActions.UPLOAD_ATTACHMENT_ENGINEER_REPORT_REJECTED,
    actionCancelled: AttachmentActions.UPLOAD_ATTACHMENT_CANCELLED,
} as IActionType;

const deleteActions = {
    action: AttachmentActions.DELETE_ATTACHMENT,
    actionFulfilled: AttachmentActions.DELETE_ATTACHMENT_FULFILLED,
    actionRejected: AttachmentActions.DELETE_ATTACHMENT_REJECTED,
} as IActionType;

const loadAttachmentsSpareEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getSpareActions).getBySiteIdAndQuery<IAttachmentModel[]>(apiPath);
const loadAttachmentsEngineerEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getEngineerActions).getBySiteIdAndQuery<IAttachmentModel[]>(apiPath);
const loadAttachmentsRiskEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getRiskActions).getBySiteIdAndQuery<IAttachmentModel[]>(apiPath);
const addAttachmentEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, postActions).attachment<IAttachmentModel>(apiPath);
const addAttachmentRiskEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, attchRiskActions).attachment<IAttachmentModel>(apiPath);
const addAttachmentSpareEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, attchSpareActions).attachment<IAttachmentModel>(apiPath);
const deleteAttachmentEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, deleteActions).delete<IAttachmentModel>(apiPath);

const loadAttachmentsActionEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getActionAttachments).getBySiteIdAndQuery<IAttachmentModel[]>(apiPath);
const addAttachmentActionEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, uploadActionAttachment).attachment<IAttachmentModel>(apiPath);

const loadAttachmentsIncidentEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getIncidentActions).getBySiteIdAndQuery<IAttachmentModel[]>(apiPath);
const addAttachmentIncidentEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, attchIncidentActions).attachment<IAttachmentModel>(apiPath);

const loadAttachmentsProcessStepEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getProcessStepActions).getBySiteIdAndQuery<IAttachmentModel[]>(apiPath);
const addAttachmentProcessStepEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, attchProcessStepActions).attachment<IAttachmentModel>(apiPath);

const loadAttachmentsOutcomeEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getOutcomeAttachments).getBySiteIdAndQuery<IAttachmentModel[]>(apiPath);

const addAttachmentOutcomeEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, uploadOutcomeAttachment).attachment<IAttachmentModel>(apiPath);

const loadAttachmentsMockDrillReportEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getMockDrillReportAttachments).getBySiteIdAndQuery<IAttachmentModel[]>(apiPath);

const addAttachmentMockDrillReportEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, uploadMockDrillReportAttachment).attachment<IAttachmentModel>(apiPath);

const loadAttachmentsShiftHandoverEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getShiftHandoverAttachments).getBySiteIdAndQuery<IAttachmentModel[]>(apiPath);

const addAttachmentShiftHandoverEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, uploadShiftHandoverAttachment).attachment<IAttachmentModel>(apiPath);

const loadAttachmentsSiteEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getSiteAttachments).getBySiteIdAndQuery<IAttachmentModel[]>(apiPath);

const addAttachmentSiteEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, uploadSiteAttachment).attachment<IAttachmentModel>(apiPath);

const loadAttachmentsEngineeringReportEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getEngineerReportAttachments).getBySiteIdAndQuery<IAttachmentModel[]>(apiPath);

const addAttachmentEngineeringReportEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, attchEngineerReportActions).attachment<IAttachmentModel>(apiPath);

const loadAttachmentsGlobalMockDrillEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getGlobalMockDrillAttachments).getBySiteIdAndQuery<IAttachmentModel[]>(apiPath);

const addAttachmentGlobalMockDrillEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, uploadGlobalMockDrillAttachment).attachment<IAttachmentModel>(apiPath);

const loadAttachmentsCermAssessmentEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getCermAssesmentAttachments).getBySiteIdAndQuery<IAttachmentModel>(apiPath);

export const attachmentEpicsV2 = combineEpics(
    loadAttachmentsEngineerEpic,
    loadAttachmentsRiskEpic,
    addAttachmentEpic,
    deleteAttachmentEpic,
    loadAttachmentsSpareEpic,
    addAttachmentSpareEpic,
    addAttachmentRiskEpic,
    loadAttachmentsActionEpic,
    addAttachmentActionEpic,
    loadAttachmentsIncidentEpic,
    addAttachmentIncidentEpic,
    loadAttachmentsProcessStepEpic,
    addAttachmentProcessStepEpic,
    loadAttachmentsOutcomeEpic,
    addAttachmentOutcomeEpic,
    loadAttachmentsMockDrillReportEpic,
    addAttachmentMockDrillReportEpic,
    loadAttachmentsShiftHandoverEpic,
    addAttachmentShiftHandoverEpic,
    loadAttachmentsSiteEpic,
    addAttachmentSiteEpic,
    loadAttachmentsEngineeringReportEpic,
    addAttachmentEngineeringReportEpic,
    loadAttachmentsGlobalMockDrillEpic,
    addAttachmentGlobalMockDrillEpic,
    loadAttachmentsCermAssessmentEpic
);
