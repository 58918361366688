import React from 'react';
import { Spinner } from 'components/spinner';
import classNames from 'classnames';

export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    buttonType?: 'save' | 'action' | 'cancel' | 'filter' | 'archive' | 'clear' | 'upload';
    className?: string;
    isLoading?: boolean;
}

export const Button: React.FC<IButtonProps> = ({
    type,
    buttonType = 'action',
    className,
    isLoading,
    children,
    ...props
}) => {
    const shouldShowSpinner = () => {
        if (!isLoading) {
            return false;
        }

        return (
            type === 'submit' ||
            buttonType === 'save' ||
            buttonType === 'action' ||
            buttonType === 'archive' ||
            buttonType === 'upload'
        );
    };

    const disabled = isLoading || props.disabled;

    const theType = type || 'button';

    return (
        <button
            type={theType}
            className={classNames(`button button-${buttonType}`, className)}
            disabled={disabled}
            {...props}
        >
            {children}
            {shouldShowSpinner() && <Spinner />}
        </button>
    );
};
