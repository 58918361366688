import React from 'react';
import { IProgressBarModel } from 'components/step-progress-bar/StepProgressBar';
import { Answer, Tier } from '../Context';
import { IStateResult, State } from '../State';
import { ChillerType } from './question-cooled-chillers';
import { DxType } from './question-dx-type';
import { QuestionHeatExchangeA } from './question-heat-exchange-a';
import { Result } from './question-result';

export class QuestionAirflow extends State {

    public result: IStateResult;
    private title: string = 'Air Flow Management';
    private type: Answer;
    constructor() {
        super();
        this.handleClick = this.handleClick.bind(this);
    }
    public getResult(): IStateResult {
        return this.result;
    }
    public display(): JSX.Element {
        return (
            <div >
                <div className="is-size-3  has-text-centered">
                    {this.title}
                </div>
                <p className="has-text-centered">
                    Is the air supply in your data hall 'contained' (e.g. cold or hot aisle containment)?
                    i.e. in your data halls does supply cool air have to pass through IT equipment in order
                    to return to the CRAC / CRAH / AHU? Typically in an air flow contained data hall there will
                    be blanking panels in the racks to prevent bypass air and either cold aisle containment with
                    doors at the end of aisles and a ceiling above or hot aisle containment with doors, ceiling or
                    rack chimneys which return the hot side air to a return plenum such as the ceiling.
                    <span>Note: this does not include an underfloor plenum</span>
                </p>
                <hr />
                <div className="formBody">
                    <div className="field">
                        <div className="control">
                            <input
                                className="radioButton"
                                type="radio"
                                name="answer"
                                value={Answer.Yes}
                                id={Answer.Yes}
                                onClick={this.handleClick}
                                checked={this.type === Answer.Yes}
                            />

                            <label htmlFor={Answer.Yes} className="radio is-size-5">
                                {Answer.Yes.toString()}
                            </label>
                            <p>
                                Cold supply air has to pass through IT equipment to return to the cooling plant,
                                CRAC, CRAH, AHU fan speeds are variable and respond to IT air flow demand
                            </p>
                        </div>
                    </div>
                    <div className="field">
                        <div className="control">
                            <input
                                className="radioButton"
                                type="radio"
                                name="answer"
                                id={Answer.No}
                                value={Answer.No}
                                onClick={this.handleClick}
                                checked={this.type === Answer.No}
                            />
                            <label htmlFor={Answer.No} className="radio is-size-5">

                                {Answer.No.toString()}
                            </label>
                            <p>
                                Significant amounts of cold supply air can bypass the IT
                                equipment and return directly to the cooling plant
                            </p>
                        </div>
                    </div>
                </div>
                <hr />
                {this.context.footer(this.handle.bind(this))}
            </div>);
    }
    public handle() {
        this.result = {
            title: this.title,
            value: this.type.toString()
        };
        const lastAnswer = this.context.answers[this.context.answers.length - 1];

        switch (lastAnswer.value) {
            case DxType.GlycolDx:
                this.type === Answer.No ?
                    this.context.tier === Tier.HighSpec ?
                        this.context.transitionTo(new Result(21)) :
                        this.context.transitionTo(new Result(22)) :
                    this.context.tier === Tier.HighSpec ?
                        this.context.transitionTo(new Result(23)) :
                        this.context.transitionTo(new Result(24));
                break;
            case DxType.SplitDx:
                this.type === Answer.No ?
                    this.context.tier === Tier.HighSpec ?
                        this.context.transitionTo(new Result(17)) :
                        this.context.transitionTo(new Result(18)) :
                    this.context.tier === Tier.HighSpec ?
                        this.context.transitionTo(new Result(19)) :
                        this.context.transitionTo(new Result(20));
                break;
            case ChillerType.AirChillers:
                this.type === Answer.No ?
                    this.context.tier === Tier.HighSpec ?
                        this.context.transitionTo(new Result(13)) :
                        this.context.transitionTo(new Result(14)) :
                    this.context.tier === Tier.HighSpec ?
                        this.context.transitionTo(new Result(15)) :
                        this.context.transitionTo(new Result(16));
                break;
            case ChillerType.WaterChillers:
                this.context.transitionTo(new QuestionHeatExchangeA());
                break;
        }
    }
    public getProgressBarModel(): IProgressBarModel[] {
        return [
            { isActive: true, name: 'Type of site' },
            { isActive: true, name: 'Question 1' },
            { isActive: true, name: 'Question 2' },
            { isActive: false, name: 'Question 3' },
            { isActive: false, name: 'Question 4' },
            { isActive: false, name: 'Summary' },
        ] as IProgressBarModel[];
    }
    private handleClick(event: any) {
        this.context.isDisable = false;
        this.type = event.currentTarget.value as Answer;
        this.context.handleRender();
    }
}
