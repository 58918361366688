import { IKeyedItem } from './keyed-item';
import { IOption } from 'components/form/fields/select-field';
import { IAuditableItem } from './auditable-item';

export interface IOpportunity extends IKeyedItem {
    title: string;
    category: string;
    questions: Array<Partial<IOpportunityQuestion>>;
    advices: Array<IOpportunityAdvice>;
    isDraft: boolean;
    path: string;
}

export interface IOpportunityQuestion {
    id: string;
    title: string;
    questions: Array<Partial<IOpportunityQuestion>>;
    responseAnswers: Array<OpportunityResponse>;
    advices: Array<IOpportunityAdvice>;
    path: string;
}

export interface IOpportunityAdvice {
    id: string;
    path: string;
    title: string;
    responseAnswers: Array<OpportunityResponse>;
}

export interface IOpportunityGroup {
    category: string;
    opportunities: IOpportunity[];
}

export interface IOpportunityQuestionnaire extends IKeyedItem, IAuditableItem {
    customerName: string;
    customerRole: string;
    customerType: string;
    region: string;
    contractManager: string;
    contractMonths: string;
    opportunityAnswers: IOpportunityAnswer[];
    isCompleted: boolean;
}

export interface IOpportunityAnswer {
    opportunityId: string;
    questionAnswers: IQuestionAnswer[];
}

export interface IQuestionAnswer {
    id: string;
    responseAnswer: string;
    path: string;
}

export enum OpportunityResponse {
    Yes = 'Yes',
    No = 'No',
    Unsure = 'Unsure',
    Discussing = 'Discussing',
    Less3Years = '< 3 Years',
    Years3To10 = '3-10 Years',
    GreaterThen10 = '> 10 Years',
    CBRE = 'CBRE',
    CustomerSelfPerforms = 'Customer Self Performs',
    Single3Party = 'Single Third Party',
    Multiple3Parties = 'Multiple Third Parties',
    NoOne = 'No one',
    CustomerConcerns = 'Customer Concerns',
    InProgress = 'In Progress',
    NoTime = 'No Time',
    OtherSolution = 'Other solution in place',
    CoLoOperator = 'CoLo Operator',
    Client = 'Client',
    OpportunitySold = 'Opportunity Sold',
    OpportunityDeclined = 'Opportunity Declined',
    Nothing = 'Nothing',
}

export enum OpportunityCategory {
    BasicHeatmapQuestions = 'Basic Heatmap Questions - Services Delivered Today',
    SitePhysicalQuestions = 'Site Physical Questions',
    CustomerQuestions = 'Customer Questions',
}

export const getCategories: IOption[] = [
    {
        label: OpportunityCategory.BasicHeatmapQuestions,
        value: OpportunityCategory.BasicHeatmapQuestions,
    },
    {
        label: OpportunityCategory.SitePhysicalQuestions,
        value: OpportunityCategory.SitePhysicalQuestions,
    },
    {
        label: OpportunityCategory.CustomerQuestions,
        value: OpportunityCategory.CustomerQuestions,
    },
];

export enum OpportunityNav {
    Master,
    Question,
    Advice,
}

export const getAllOpportunityResponse = (): string[] =>
    Object.keys(OpportunityResponse).map((key) => OpportunityResponse[key]);
