import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { onSiteChange } from 'actions/app-actions';
import RoleSelectorDialog from './role-selector-dialog';
import {
    showRoleSelectorDialog,
    updatePreferredRole,
} from 'actions/actions-v2/user-profile-action-v2';

const getPersonProfile = (state: IRootState) => state.person.personProfile;
const getShowRoleSelectorDialog = (state: IRootState) => state.person.showRoleSelectorDialog;
const getSignOffAfterRoleUpdate = (state: IRootState) => state.person.signOffAfterRoleUpdate;
const getIsLoading = (state: IRootState) => state.person.isLoading;

const mapStateToProps = createSelector(
    getPersonProfile,
    getShowRoleSelectorDialog,
    getSignOffAfterRoleUpdate,
    getIsLoading,
    (personProfile, showRoleSelectorDialog, signOffAfterRoleUpdate, isLoading) => ({
        personProfile,
        isShowRoleSelectorDialog: showRoleSelectorDialog,
        signOffAfterRoleUpdate,
        isLoading,
    })
);

const mapDispatchToProps = {
    onSiteChange,
    showRoleSelectorDialog,
    updatePreferredRole,
};

export const RoleSelectorDialogContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(RoleSelectorDialog)
);
