import {
    onToggleAllClientRelationshipCheckedFilter,
    onToggleSelectAll,
    toggleLobCheckedFilter,
    toggleRegionsCheckedFilter,
    toggleRelationshipCheckedFilter,
} from '../../../../actions/manage-role-actions';
import { onRouteChange } from 'actions/app-actions';
import {
    loadPerson,
    upsertPerson,
    resetPerson,
    loadRelationships,
    deleteUser,
    activateUser,
    loadPersonAssignedSites,
} from 'actions/actions-v2/person-action-v2';
import { loadPrivileges } from 'actions/actions-v2/role-actions-v2';
import { onLoadSitesFeatures } from 'actions/site-feature';

export default {
    onLoadSitesFeatures,
    onLoadPerson: loadPerson,
    resetPerson,
    onRouteChange,
    onSubmit: upsertPerson,
    onToggleRegionsCheckedFilter: toggleRegionsCheckedFilter,
    onToggleLobCheckedFilter: toggleLobCheckedFilter,
    toggleRelationshipCheckedFilter,
    toggleAllClientRelationshipCheckedFilter: onToggleAllClientRelationshipCheckedFilter,
    toggleAllRelationshipCheckedFilter: onToggleSelectAll,
    loadPrivileges,
    loadRelationships,
    deleteUser,
    activateUser,
    loadPersonAssignedSites,
};
