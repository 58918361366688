import { createSelector } from 'reselect';
import { IAppState } from '../../reducers/app';
import { IBaseState } from '../../reducers/base-state';
import { INavState } from '../../reducers/nav';
import { IRootControlCentreState } from 'reducers/control-centre';
import { IRootState } from 'routes/store';
import { getIsClient } from 'sharedSelectors';

const getFullName = createSelector(
    (state: IBaseState) => state.app,
    (state: IAppState) => state.fullName
);

const getIsUserMenuExpanded = createSelector(
    (state: IBaseState) => state.nav,
    (state: INavState) => state.isUserMenuExpanded
);

const getUserMenuItems = createSelector(
    (state: IBaseState) => state.nav,
    (state: INavState) => state.userMenuItems
);

const getNotifications = createSelector(
    (state: IBaseState) => state.app,
    (state: IAppState) => state.notifications
);

const getIsInitialising = createSelector(
    (state: IRootControlCentreState) => state.controlCentre,
    state => state.isInitialising
);

const getPersonLanguage = createSelector(
    (state: any) => state.nav.languageItem,
    state => state.person.personProfile && state.person.personProfile.language,
    (languageItem, language) => {
        const personLanguage = languageItem.filter(item => item.key === language);
        return personLanguage.length !== 0 ? personLanguage[0].display : '';
    }
);

const getperson = createSelector(
    (state: any) => state.person,
    state => state.personProfile
);

const getSelectedSite = (state: IRootState) => state.app.siteId;
const getLoadingSite = (state: IRootState) => state.person.isLoadingSite;
const getAppState = (state: IRootState) => state.app;
const getOperatingPlatforms = (state: IRootState) =>
    state.operatingPlatformState.operatingPlatforms;

export default createSelector(
    getFullName,
    getIsUserMenuExpanded,
    getUserMenuItems,
    getNotifications,
    getIsInitialising,
    getPersonLanguage,
    getperson,
    getSelectedSite,
    getLoadingSite,
    getIsClient,
    getAppState,
    getOperatingPlatforms,
    (
        fullName,
        isUserMenuExpanded,
        userMenuItems,
        notifications,
        isInitialising,
        language,
        person,
        siteId,
        isLoadingSite,
        isClient,
        app,
        operatingPlatforms
    ) => ({
        fullName,
        isUserMenuExpanded,
        userMenuItems,
        notifications,
        isInitialising,
        language,
        person,
        siteId,
        isLoadingSite,
        isClient,
        moduleNotificationCount: app.notificationCount,
        lookupSites: app.lookupSites,
        operatingPlatforms,
    })
);
