import { uniqueId } from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { INotification } from 'models/notification';
import actions from './actions';
import selectors from './selectors';
import Page from 'components/v2/page/Page';
import { Button } from 'components/v2/components';
import { getPathName, isInternalRoute } from 'utils/string-utils';
import { onRouteChange } from 'actions/app-actions';
import appConfig from 'helpers/config-helper';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconColor, MaterialIconSize, MaterialIconType } from 'routes/material-icon/material-icon-type';
import useTranslate from 'translations/translation-utils';

interface IProps {
    notifications: INotification[];
    ecermToken: string;
    onNotificationRead: (id: string, notificationLink: string) => void;
    onMarkAllRead: () => void;
    getECermToken: () => void;
}
const Notifications: React.FC<IProps> = (props) => {
    const translate = useTranslate();

    React.useEffect(() => {
        if (props.ecermToken == null || props.ecermToken == '')
            props.getECermToken();
    }, [props.ecermToken]);

    const onClickLink = (notification: INotification) => {
        if (isInternalRoute(notification.link)) {
            const pathname = getPathName(notification.link);
            onRouteChange(pathname);
        } else {
            const ecermUrl = appConfig().REACT_APP_ECERM_URL.toLowerCase();
            if (notification.link.toLocaleLowerCase().startsWith(ecermUrl)) {
                let separator = "?";
                if (notification.link.indexOf("?") != -1) {
                    separator = "&"
                }
                window.open(`${notification.link}${separator}auth-token=${props.ecermToken}`, '_self');
            }
            else {
                window.location.href = notification.link;
            }
        }

        props.onNotificationRead(notification.id, notification.link);
    };

    const renderTable = (notifications: INotification[]): React.ReactNode => {
        return notifications.map((notification) => {
            return (
                <div
                    key={uniqueId(notification.id)}
                    className={notification.read ? '' : 'notification-unread'}
                >
                    <a
                        className="navbar-item is-hoverable"
                        onClick={() => onClickLink(notification)}
                    >
                        <div className={'columns notification'}>
                            <div className={'column is-1 message-icon'}>
                                {
                                    // tslint:disable-next-line:max-line-length
                                    < MaterialIcons type={MaterialIconType.Warning} color={MaterialIconColor.grey} size={MaterialIconSize.medium} />
                                }
                            </div>
                            <div className={'column is-11 columns'}>
                                <div className={'column is-11'}>
                                    <div className="notification-title">{notification.title}</div>
                                    <div className="notification-message">
                                        <div className="message">{notification.messageBody}</div>
                                    </div>
                                </div>
                                <div className={'column is-1'}>
                                    <div className="notification-time">
                                        {moment(notification.created).utc().fromNow()}
                                    </div>
                                </div>
                            </div>
                            <div className="unread-icon column is-1" />
                        </div>
                    </a>
                    <hr className="navbar-divider" />
                </div>
            );
        });
    };

    return (
        <Page title="Notifications" className="notifications-page" isAdminPage={true}>
            <div className="has-text-right">
                <Button onClick={props.onMarkAllRead}>{translate('NavDropDown.MarkAsRead')}</Button>
            </div>
            <div className="columns is-multiline is-mobile">
                <div className="column is-12">{renderTable(props.notifications)}</div>
            </div>
        </Page>
    );
};

export default connect(selectors, actions)(Notifications);
