import * as React from 'react';
import { Loader } from '../../../components/loader';
import { SearchFilter } from '../../../components/searchFilter';
import { ClientList, IClientListDispatchProps, IClientListStateProps } from './clientList';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { IKeyValue } from '../../../models/key-value';
import { RouteUrl } from '../routes';
import { ClientGridToolbar } from './clientGridToolbar/ClientGridToolbar';
import { ClientUpsertPage } from './upsert-client-page';
import { OverlayPanel } from 'components/v2/components';
import Page from 'components/v2/page/Page';
import { IClientFilters } from 'models/client-filters';
import { checkFilterExists } from 'utils/array-utils';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';

export interface IProps extends IClientListStateProps, RouteComponentProps {
    filterKeywords: string[];
    permissions: Array<IKeyValue<string>>;
    isInitialising: boolean;
    intl: any;
}

export interface IDispatchProps extends IClientListDispatchProps {
    onChangeRoute: (url: string) => void;
    loadClients: (filters: IClientFilters) => void;
    loadMyDataClients: () => void;
}

interface IState {
    showFilters: boolean;
    filters: IClientFilters;
    sortColumn: string;
    sortAscending: boolean;
}

class ClientPageClass extends React.PureComponent<IProps & IDispatchProps, IState> {
    constructor(props: IProps & IDispatchProps) {
        super(props);
        this.state = {
            showFilters: false,
            filters: {
                filterKeywords: [],
            },
            sortColumn: 'Name',
            sortAscending: false,
        };
    }

    public componentWillMount() {
        this.props.loadClients(this.state.filters);
    }

    public render() {
        const props = this.props;
        const isFilterExists = checkFilterExists(this.state.filters);
        const intl = props.intl;

        return (
            <>
                <Switch>
                    <Route exact={false} path={`${RouteUrl.ClientUpsert}/:clientId?`}>
                        <ClientUpsertPage />
                    </Route>
                    <Route exact={false} path={RouteUrl.Clients}>
                        <Page title={translate(intl, 'Globals.Category.dropdown.Clients')} isAdminPage={true}>
                            <ClientGridToolbar
                                onShowFilters={() => this.setState({ showFilters: true })}
                                onClearFilters={this.onClearFilters}
                                isFilterExists={isFilterExists}
                            />
                            <OverlayPanel
                                title={translate(intl, 'RiskRegisterPage.Title.Filters')}
                                isVisible={this.state.showFilters}
                                onClose={() => this.setState({ showFilters: false })}
                                onOutsideDialogClick={() => this.setState({ showFilters: false })}
                                onClearFilters={this.onClearFilters}
                                isFilterExists={isFilterExists}
                            >
                                <SearchFilter
                                    title={translate(intl, 'RiskRegisterPage.Title.Filters')}
                                    name="filterKeywords"
                                    values={this.state.filters.filterKeywords}
                                    onAddFilterKeyword={this.onAddFilterKeyword}
                                    onRemoveFilterKeyword={this.onRemoveFilterKeyword}
                                />
                            </OverlayPanel>
                            {props.isInitialising ? (
                                <Loader loading={true} />
                            ) : Boolean(props.items && props.items.length) ? (
                                <ClientList {...props} />
                            ) : (
                                <p>{translate(intl, 'QuestionnSetList.Timeline.NoResultFound')}</p>
                            )}
                        </Page>
                    </Route>
                </Switch>
            </>
        );
    }

    private onAddFilterKeyword = (keyword: string) => {
        const filters = { ...this.state.filters };
        const keywords = [
            keyword,
            ...filters.filterKeywords.slice(0, filters.filterKeywords.length),
        ];
        filters.filterKeywords = keywords;
        this.setState({ filters });
        this.props.loadClients(filters);
    };

    private onClearFilters = () => {
        const filters = {
            filterKeywords: [],
        } as IClientFilters;
        this.setState({ filters });
        this.props.loadClients(filters);
    };

    private onRemoveFilterKeyword = (keyword: string) => {
        const filters = { ...this.state.filters };
        const index = filters.filterKeywords.indexOf(keyword);
        const keywords = [
            ...filters.filterKeywords.slice(0, index),
            ...filters.filterKeywords.slice(index + 1),
        ];
        filters.filterKeywords = keywords;
        this.setState({ filters });
        this.props.loadClients(filters);
    };
}

export const ClientPage = injectIntl(ClientPageClass);
