import { RiskActions, RiskActionTypes } from '../../../../actions/risk-actions';

export default ({
    hideModal: (id: string, siteId: string): RiskActionTypes => ({
        type: RiskActions.HIDE_ADD_ATTACHMENT_MODAL,
        payload: {
            id,
            siteId
        }
    }),

    onConfirm: (id: string, siteId: string, files: File[]): RiskActionTypes => ({
        type: RiskActions.ADD_ATTACHMENT,
        payload: {
            id,
            siteId,
            files
        }
    })
});
