import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { IRisk } from 'models/risk';
import { MacroRiskActions } from 'actions/actions-v2/macro-risk-action-v2';

const controllerName = 'macrorisk';
const apiPath = `/api/${controllerName}`;
const riskRegisterPageUrl = '/Risks';

const createRisks = {
    action: MacroRiskActions.CREATE_MACRO_RISK,
    actionFulfilled: MacroRiskActions.CREATE_MACRO_RISK_FULFILLED,
    actionRejected: MacroRiskActions.CREATE_MACRO_RISK_REJECTED,
} as IActionType;

const updateRisks = {
    action: MacroRiskActions.UPDATE_MACRO_RISK,
    actionFulfilled: MacroRiskActions.UPDATE_MACRO_RISK_FULFILLED,
    actionRejected: MacroRiskActions.UPDATE_MACRO_RISK_REJECTED,
} as IActionType;

const getRisk = {
    action: MacroRiskActions.LOAD_MACRO_RISK,
    actionFulfilled: MacroRiskActions.LOAD_MACRO_RISK_FULFILLED,
    actionRejected: MacroRiskActions.LOAD_MACRO_RISK_REJECTED,
} as IActionType;

const getAllRisks = {
    action: MacroRiskActions.LOAD_MACRO_RISKS,
    actionFulfilled: MacroRiskActions.LOAD_MACRO_RISKS_FULFILLED,
    actionRejected: MacroRiskActions.LOAD_MACRO_RISKS_REJECTED,
    actionCancelled: MacroRiskActions.LOAD_MACRO_RISKS_CANCELLED,
} as IActionType;

const deleteRisk = {
    action: MacroRiskActions.DELETE_MACRO_RISK,
    actionFulfilled: MacroRiskActions.DELETE_MACRO_RISK_FULFILLED,
    actionRejected: MacroRiskActions.DELETE_MACRO_RISK_REJECTED,
} as IActionType;

const createMacroRiskEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createRisks, riskRegisterPageUrl).post<IRisk>(apiPath);

const updateMacroRiskEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateRisks, riskRegisterPageUrl).put<IRisk>(apiPath);

const deleteMacroRiskEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, deleteRisk, riskRegisterPageUrl).deleteById<string>(apiPath);

const loadMacroRiskEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getRisk).getById<IRisk>(apiPath);

const loadMacroRisksEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getAllRisks).get<IRisk[]>(apiPath);

export const macroRiskEpicsV2 = combineEpics(
    createMacroRiskEpic,
    updateMacroRiskEpic,
    deleteMacroRiskEpic,
    loadMacroRisksEpic,
    loadMacroRiskEpic
);
