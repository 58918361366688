import queryString from 'query-string';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { ajax, AjaxError } from 'rxjs/ajax';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { history } from 'routes/App';

import {
    CermSiteOverviewActions,
    CermSiteOverviewActionTypes,
    ILoadDataFulfilledAction,
    loadData,
    loadDataFulfilled,
} from 'actions/cerm-site-overview';
import { getErrorActions$ } from 'epics/epic-helpers';
import { IRootCermSiteOverviewState } from 'reducers/cerm-site-overview';
import { getApiHeaders } from 'epics/shared-header';

import appConfig from 'helpers/config-helper';

const config = appConfig();

const apiUrl = config.REACT_APP_BASE_API;

export const changeQueryEpic: Epic<CermSiteOverviewActionTypes> = (action$) =>
    action$.pipe(
        ofType<CermSiteOverviewActionTypes>(
            CermSiteOverviewActions.CHANGE_FILTER_VALUE,
            CermSiteOverviewActions.CHANGE_SORT_COLUMN,
            CermSiteOverviewActions.CLEAR_FILTERS
        ),
        map(loadData)
    );

const getUrlFromPath = (path: string) => {
    switch (path) {
        case 'agm':
            return path;
        case 'country':
            return path;
        default:
            return 'client';
    }
};

const getPathName = () => history.location.pathname.match(/([^\/]*)\/*$/)[1];

const loadDataEpic: Epic<CermSiteOverviewActionTypes, any, IRootCermSiteOverviewState> = (
    action$,
    state$
) =>
    action$.pipe(
        ofType(CermSiteOverviewActions.LOAD_DATA, CermSiteOverviewActions.LOAD_PAGE_FULFILLED),
        map(() => ({
            query: {
                sortColumn: state$.value.cermSiteOverview.sortColumn,
                sortAscending: state$.value.cermSiteOverview.sortAscending,
                regionFilters: state$.value.cermSiteOverview.regionFilters,
                otherFilters: state$.value.cermSiteOverview.otherFilters,
                lineOfBusinessFilters: state$.value.cermSiteOverview.lineOfBusinessFilters,
            },
            path: getPathName(),
        })),
        map(
            ({ query, path }) =>
                `${apiUrl}/api/CermSiteOverviewApi/${getUrlFromPath(path)}?${queryString.stringify(query)}`
        ),
        switchMap((url) =>
            ajax.getJSON(url, getApiHeaders()).pipe(
                map<any, ILoadDataFulfilledAction>((response) => loadDataFulfilled(response)),
                takeUntil(
                    action$.pipe(
                        ofType<CermSiteOverviewActionTypes>(
                            CermSiteOverviewActions.LOAD_DATA,
                            CermSiteOverviewActions.LOAD_DATA_CANCELLED,
                            CermSiteOverviewActions.CHANGE_FILTER_VALUE,
                            CermSiteOverviewActions.CHANGE_SORT_COLUMN,
                            CermSiteOverviewActions.CLEAR_FILTERS
                        )
                    )
                ),
                catchError<any, any>((error: AjaxError) =>
                    getErrorActions$('CERM Site Overview')(
                        CermSiteOverviewActions.LOAD_DATA_REJECTED,
                        error,
                        {
                            errorMessage: 'Unable to load data',
                        }
                    )
                )
            )
        )
    );

const loadPageEpic: Epic<any> = (action$) =>
    action$.pipe(
        ofType(CermSiteOverviewActions.LOAD_PAGE),
        switchMap(() =>
            ajax.getJSON(`${apiUrl}/api/CermSiteOverviewApi/loadPage`, getApiHeaders()).pipe(
                map<IRootCermSiteOverviewState, CermSiteOverviewActionTypes>((state) => ({
                    type: CermSiteOverviewActions.LOAD_PAGE_FULFILLED,
                    payload: state,
                })),
                takeUntil(action$.pipe(ofType(CermSiteOverviewActions.LOAD_PAGE_CANCELLED))),
                catchError<any, any>((error: AjaxError) =>
                    getErrorActions$('CermSiteOverview')(
                        CermSiteOverviewActions.LOAD_PAGE_REJECTED,
                        error,
                        {
                            errorMessage: 'Unable to load page',
                        }
                    )
                )
            )
        )
    );

export const epics = combineEpics(loadDataEpic, loadPageEpic, changeQueryEpic);
