import * as React from 'react';

import classNames from 'classnames';
import './timeline-header-grid-value-collection.scss';

interface IProps extends React.FC<React.AllHTMLAttributes<HTMLDivElement>> { }

export const TimelineHeaderGridValueCollection: IProps = (props) => {
    const css = classNames([
        'TimelineHeaderGridValueCollection',
        props.className
    ]);

    return (
        <div className={css}>
            {props.children}
        </div>
    );
};
