import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import actions from './actions';
import { ExistingRiskForm as View } from './existing-risk-form';
import selector from './selector';

export const ExistingRiskForm = withRouter(connect(
    selector,
    actions,
)(View));
