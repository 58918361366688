import React from 'react';
import {
    IToolbarIconItem,
    ToolbarIconType,
    IconToolbar,
} from '../../../components/v2/toolbar/IconToolbar';
import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { IKeyValue } from 'models/key-value';
import { history } from 'routes/App';
import useTranslate from 'translations/translation-utils';

interface IProps {
    permissions?: Array<IKeyValue<string>>;
    isExporting?: boolean;
    cleanUp: () => void;
}

const opportunityGridToolbarView: React.FC<IProps> = ({ cleanUp }) => {
    const translate = useTranslate();
    const onAddNew = async () => {
        await cleanUp();
        history.push('/Opportunity');
    };

    const getToolbarItems = (): IToolbarIconItem[] => {
        return [
            {
                id: 'add-question-set-button',
                title: translate('OpportunityQ.QuestionSet.ToolbarCreate'),
                type: ToolbarIconType.add,
                onClick: onAddNew,
            },
        ];
    };

    return <IconToolbar items={getToolbarItems()} />;
};

const mapStateToProps = (state: IRootState) => {
    return {
        siteId: state.app.siteId,
        permissions: state.app.permissions,
        isExporting: state.romonetState.isExporting,
    };
};

export const OpportunityGridToolbar = connect(mapStateToProps)(opportunityGridToolbarView);
