import * as React from 'react';
import { onRouteChange } from 'actions/app-actions';
import { Loader } from 'components/loader';
import { IClientSiteSummary } from 'models/risk';
import { ClientSiteSummaryTable } from 'components/client-dashboard/client-site-summary-table';
import { IKeyValue } from 'models';
import Page from 'components/v2/page/Page';
import useTranslate from 'translations/translation-utils';

interface IClientSitesOverviewProps {
    siteId: string;
    riskSummaries: IClientSiteSummary[];
    isLoading: boolean;
    showClientRisks: boolean;
    showCriticalSpares: boolean;
    // tslint:disable-next-line: array-type
    siteFeatures: IKeyValue<string[]>[];
    onSiteChange: (siteId: string) => void;
    loadRiskSummaries: () => void;
}

export const ClientSitesOverviewPage: React.FC<IClientSitesOverviewProps> = ({
    siteId,
    riskSummaries,
    showClientRisks,
    showCriticalSpares,
    loadRiskSummaries,
    isLoading,
    onSiteChange,
    siteFeatures
}) => {
    const translate = useTranslate();
    const [isFirstSiteLoad, setIsFirstSiteLoad] = React.useState(true);

    React.useEffect(() => {
        loadRiskSummaries();
    }, []);

    React.useEffect(() => {
        if (siteId) {
            if (isFirstSiteLoad) {
                setIsFirstSiteLoad(false);
            } else {
                onRouteChange('/ClientDashboard');
            }
        }
    }, [siteId]);

    const siteChange = (theSiteId: string) => {
        onSiteChange(theSiteId);
    };

    const openSite = (theSiteId: string) => {
        onSiteChange(theSiteId);
        onRouteChange('/ClientDashboard');
    };

    return (
        <Page title={translate('SideNavBar.Labels.AllSitesOverview')} isAdminPage={true}>
            <Loader loading={isLoading}>
                <ClientSiteSummaryTable
                    summary={riskSummaries}
                    isLoading={false}
                    onSiteChange={siteChange}
                    showClientRisks={showClientRisks}
                    showCriticalSpares={showCriticalSpares}
                    siteFeatures={siteFeatures}
                    openSite={openSite}
                />
            </Loader>
        </Page>
    );
};
