import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { loadCermProgressChartData } from 'actions/actions-v2/cerm-assessment-action-v3';
import { CermProgressChart } from './cerm-progress-chart';

const getChartData = (state: IRootState) => state.cermAssessmentResultState.cermProgressChartData;
const getOperatingPlatforms = (state: IRootState) => state.operatingPlatformState.operatingPlatforms;

const mapStateToProps = createSelector(getChartData, getOperatingPlatforms, (chartData, allOperatingPlatforms) => ({
    chartData,
    allOperatingPlatforms
}));

const mapDispatchToProps = {
    getChartData: loadCermProgressChartData
};

export const CermProgressChartContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CermProgressChart);
