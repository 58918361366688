import * as React from 'react';
import { IPerson } from 'models/person';
import { Card, CardContent, CardHeader } from 'components/card';
import { UsersList } from '../users-list';
import Select from 'react-select';
import { IPersonAdvanceFilters } from 'models/person-filters';
import { IOption } from 'components/form/fields/select-field';
import { Checkbox } from 'components/form/Checkbox';
import { Loader } from 'components/loader';
import { ICommonLookupModel } from 'models/common-lookup-model';
import { getUniqueItems } from 'components/common/common';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';
import { IOperatingPlatform } from 'models/operating-platform-model';

interface IProps {
    users: IPerson[];
    clients: IOption[];
    lineOfBusinesses: string[];
    regions: string[];
    roles: IOption[];
    sites: IOption[];
    siteRoles: IOption[];
    isLoading: boolean;
    loadAllPersons(filters?: IPersonAdvanceFilters): void;
    selectUsers(userIds: string[]): void;
    onLoadSitesFeatures(): void;
    loadSiteRoles(): void;
    allLineOfBusinesses: ICommonLookupModel[];
    resetNotificationPerson(): void;
    intl: any;
    operatingPlatforms: IOperatingPlatform[];
}

export interface IFilters {
    roles: string[];
    lineOfBusiness: string[];
    regions: string[];
    sites: string[];
    clients: string[];
    siteRoles: string[];
    operatingPlatform: string[];
}

export interface IState {
    userIds: string[];
    filters: IFilters;
    sendAll: boolean;
}

export class UsersSelectorClass extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            userIds: [],
            sendAll: false,
            filters: {
                clients: [],
                lineOfBusiness: [],
                regions: [],
                roles: [],
                siteRoles: [],
                sites: [],
                operatingPlatform: []
            },
        };
    }

    resetState() {
        return {
            userIds: [],
            sendAll: false,
            filters: {
                clients: [],
                lineOfBusiness: [],
                regions: [],
                roles: [],
                siteRoles: [],
                sites: [],
                operatingPlatform: []
            },
        };
    }

    componentDidMount() {
        this.props.onLoadSitesFeatures();
        this.props.loadSiteRoles();
        this.props.resetNotificationPerson()
        this.setState(this.resetState());
    }

    numberOfUsers: number = (this.props.users && this.props.users.length) || 0;

    onToggleUsers = (userIds: string[]) => {
        this.setState({ userIds });
        this.props.selectUsers(userIds);
    };

    onToggleAllUsers = (allchecked: boolean) => {
        if (!allchecked) {
            const ids = this.props.users.map((m) => m.id);
            this.setState({ userIds: ids });
            this.props.selectUsers(ids);
        } else {
            this.setState({ userIds: [] });
            this.props.selectUsers([]);
        }
    };

    onSendToAll = () => {
        this.setState({ sendAll: !this.state.sendAll });
        const selectedUsers = this.state.sendAll ? [] : ['all'];
        this.props.selectUsers(selectedUsers);
    };

    getLineOfBusiness = () => {
        let lineOfBusiness = (this.state.filters.operatingPlatform && this.state.filters.operatingPlatform.length > 0) ?
            this.props.allLineOfBusinesses
                .filter(x => this.state.filters.operatingPlatform.includes(x.operatingPlatform))
                .map((x) => ({ value: x.value, label: x.value })) :
            this.props.allLineOfBusinesses;

        return getUniqueItems(lineOfBusiness);
    }

    render() {
        const numberSelectedUsers: number = this.state.userIds.length;
        const numberOfUsers: number = this.props.users.length || 0;

        return (
            <Card key={1}>
                <CardHeader
                    title={translate(this.props.intl, 'UserSelector.Labels.Recipients')}
                    subtitle={`${translate(this.props.intl, 'UserSelector.Labels.NoOfUsersSelected')}:  ${this.state.sendAll
                        ? translate(this.props.intl, 'UserSelector.Labels.All')
                        : numberSelectedUsers}`}
                />
                <CardContent>
                    <Checkbox
                        checked={this.state.sendAll}
                        label={translate(this.props.intl, 'UserSelector.Labels.SendToAllRecipients')}
                        id="send-to-all-checkbox"
                        onChange={this.onSendToAll}
                    />
                    {!this.state.sendAll && (
                        <>
                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">
                                        {translate(this.props.intl, 'UserSelector.Labels.OperatingPlatform')}
                                    </label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control">
                                            <Select
                                                id="operatingPlatform"
                                                options={this.props.operatingPlatforms && this.props.operatingPlatforms.map((x) => ({
                                                    value: x.value,
                                                    label: x.value,
                                                }))}
                                                isMulti={true}
                                                onChange={this.filterOperatingPlatform}
                                                placeholder={translate(this.props.intl, 'Globals.Label.Select')}
                                            />
                                        </div>
                                        <div>
                                            <span>({translate(this.props.intl, 'UserSelector.Labels.OperatingPlatformNote')})</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">
                                        {translate(this.props.intl, 'UserSelector.Labels.LineOfBusiness')}
                                    </label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control">
                                            <Select
                                                id="line-of-business-multiselect"
                                                options={this.getLineOfBusiness()}
                                                isMulti={true}
                                                onChange={this.filterLineOfBusiness}
                                                placeholder={translate(this.props.intl, 'Globals.Label.Select')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">
                                        {translate(this.props.intl, 'UserSelector.Labels.Roles')}
                                    </label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control">
                                            <Select
                                                id="roles-multiselect"
                                                options={this.props.roles}
                                                isMulti={true}
                                                onChange={this.filterRoles}
                                                placeholder={translate(this.props.intl, 'Globals.Label.Select')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">
                                        {translate(this.props.intl, 'UserSelector.Labels.Regions')}
                                    </label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control">
                                            <Select
                                                id="regions-multiselect"
                                                options={this.props.regions.map((x) => ({
                                                    value: x,
                                                    label: x,
                                                }))}
                                                isMulti={true}
                                                onChange={this.filterRegions}
                                                placeholder={translate(this.props.intl, 'Globals.Label.Select')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">
                                        {translate(this.props.intl, 'UserSelector.Labels.Sites')}
                                    </label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control">
                                            <Select
                                                id="clients-multiselect"
                                                options={this.props.sites}
                                                isMulti={true}
                                                onChange={this.filterSites}
                                                placeholder={translate(this.props.intl, 'Globals.Label.Select')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">
                                        {translate(this.props.intl, 'UserSelector.Labels.Clients')}
                                    </label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control">
                                            <Select
                                                id="clients-multiselect"
                                                options={this.props.clients}
                                                isMulti={true}
                                                onChange={this.filterClientNames}
                                                placeholder={translate(this.props.intl, 'Globals.Label.Select')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">
                                        {translate(this.props.intl, 'UserSelector.Labels.SiteRole')}
                                    </label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control">
                                            <Select
                                                id="clients-multiselect"
                                                options={this.props.siteRoles}
                                                isMulti={true}
                                                onChange={this.filterSiteRoles}
                                                placeholder={translate(this.props.intl, 'Globals.Label.Select')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <span>({translate(this.props.intl, 'UserSelector.Labels.SiteRoleNote')})</span>
                            </div>
                            <Loader loading={this.props.isLoading}>
                                {numberOfUsers > 0 ? (
                                    <UsersList
                                        users={this.props.users}
                                        onToggleUsers={this.onToggleUsers}
                                        onToggleAllUsers={this.onToggleAllUsers}
                                        selectedUserIds={this.state.userIds}
                                    />
                                ) : (
                                    <p>{translate(this.props.intl, 'UserSelector.Labels.UserListNote')}</p>
                                )}
                            </Loader>
                        </>
                    )}
                </CardContent>
            </Card>
        );
    }

    private filterLineOfBusiness = (lineOfBusinesses: IOption[]) => {
        const filters = {
            ...this.state.filters,
            lineOfBusiness: lineOfBusinesses ? lineOfBusinesses.map((x) => x.value) : [],
        };
        this.setState({ filters });
        this.props.loadAllPersons(filters);
    };

    private filterRegions = (regions: IOption[]) => {
        const filters = {
            ...this.state.filters,
            regions: regions ? regions.map((x) => x.value) : [],
        };
        this.setState({ filters });
        this.props.loadAllPersons(filters);
    };

    private filterClientNames = (clients: IOption[]) => {
        const filters = {
            ...this.state.filters,
            clients: clients ? clients.map((x) => x.value) : [],
        };
        this.setState({ filters });
        this.props.loadAllPersons(filters);
    };

    private filterRoles = (roles: IOption[]) => {
        const filters = {
            ...this.state.filters,
            roles: roles ? roles.map((x) => x.value) : [],
        };
        this.setState({ filters });
        this.props.loadAllPersons(filters);
    };

    private filterSites = (sites: IOption[]) => {
        const filters = {
            ...this.state.filters,
            sites: sites ? sites.map((m) => m.value) : [],
        };
        this.setState({ filters });
        this.props.loadAllPersons(filters);
    };

    private filterSiteRoles = (siteRoles: IOption[]) => {
        const filters = {
            ...this.state.filters,
            siteRoles: siteRoles ? siteRoles.map((m) => m.value) : [],
        };
        this.setState({ filters });
        this.props.loadAllPersons(filters);
    };

    private filterOperatingPlatform = (operatingPlatform: IOption[]) => {
        const filters = {
            ...this.state.filters,
            operatingPlatform: operatingPlatform ? operatingPlatform.map((x) => x.value) : [],
        };
        this.setState({ filters });
        this.props.loadAllPersons(filters);
    };
}

export const UsersSelector = injectIntl(UsersSelectorClass);