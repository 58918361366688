import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';

import { Epic, combineEpics } from 'redux-observable';
import { ShiftActions } from 'actions/actions-v2/shift-actions';
import { IShift, ICreateShift } from 'models/shift';

const controllerName = 'shift';

const getAllActions = {
    action: ShiftActions.LOAD_SHIFTS,
    actionFulfilled: ShiftActions.LOAD_SHIFTS_FULFILLED,
    actionRejected: ShiftActions.LOAD_SHIFTS_REJECTED
} as IActionType;

const getActions = {
    action: ShiftActions.LOAD_SHIFT,
    actionFulfilled: ShiftActions.LOAD_SHIFT_FULFILLED,
    actionRejected: ShiftActions.LOAD_SHIFT_REJECTED
} as IActionType;

const getEngineersActions = {
    action: ShiftActions.LOAD_ENGINEERS,
    actionFulfilled: ShiftActions.LOAD_ENGINEERS_FULFILLED,
    actionRejected: ShiftActions.LOAD_ENGINEERS_REJECTED
} as IActionType;

const saveActions = {
    action: ShiftActions.CREATE_SHIFT,
    actionFulfilled: ShiftActions.CREATE_SHIFT_FULFILLED,
    actionRejected: ShiftActions.CREATE_SHIFT_REJECTED
} as IActionType;

const updateActions = {
    action: ShiftActions.UPDATE_SHIFT,
    actionFulfilled: ShiftActions.UPDATE_SHIFT_FULFILLED,
    actionRejected: ShiftActions.UPDATE_SHIFT_REJECTED
} as IActionType;

const deleteActions = {
    action: ShiftActions.DELETE_SHIFT,
    actionFulfilled: ShiftActions.DELETE_SHIFT_FULFILLED,
    actionRejected: ShiftActions.DELETE_SHIFT_REJECTED
} as IActionType;

const loadShiftsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getAllActions).get<IShift[]>(`/api/${controllerName}`);

const loadShiftEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getActions).getById<string>(`/api/${controllerName}`);

const loadEngineersEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getEngineersActions).getById<string>(`/api/${controllerName}/engineers`);

const createShiftEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, saveActions).post<ICreateShift>(`/api/${controllerName}`);

const updateShiftEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateActions).put<IShift>(`/api/${controllerName}`);

const deleteShiftEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, deleteActions).deleteById<string>(`/api/${controllerName}`);

export const shiftEpicsV2 = combineEpics(
    loadShiftsEpic,
    loadShiftEpic,
    loadEngineersEpic,
    createShiftEpic,
    updateShiftEpic,
    deleteShiftEpic
);
