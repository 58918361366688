export enum NavLocation {
    Home,
    Action,
    Risk,
    CermAssessment,
    CermOverview,
    CermProcess,
    RiskAssessment,
    RiskQuestions,
    Calendar,
    Person,
    Site,
    Client,
    ManageRoles,
    ManageRelationships
}
