import { IAppAction } from '../app-action';
import {
    IGlobalMockDrillsFilters,
    ICreateGlobalMockDrill,
    IUpdateGlobalMockDrill
} from 'models/mock-drills';

export enum GlobalMockDrillsActions {
    LOAD_LIST = '@@globalMockDrills/LOAD_LIST',
    LOAD_LIST_FULFILLED = '@@globalMockDrills/LOAD_LIST_FULFILLED',
    LOAD_LIST_REJECTED = '@@globalMockDrills/LOAD_LIST_REJECTED',

    LOAD = '@@globalMockDrills/LOAD',
    LOAD_FULFILLED = '@@globalMockDrills/LOAD_FULFILLED',
    LOAD_REJECTED = '@@globalMockDrills/LOAD_REJECTED',

    CREATE = '@@globalMockDrills/CREATE',
    CREATE_FULFILLED = '@@globalMockDrills/CREATE_FULFILLED',
    CREATE_REJECTED = '@@globalMockDrills/CREATE_REJECTED',

    UPDATE = '@@globalMockDrills/UPDATE',
    UPDATE_FULFILLED = '@@globalMockDrills/UPDATE_FULFILLED',
    UPDATE_REJECTED = '@@globalMockDrills/UPDATE_REJECTED',

    DELETE = '@@globalMockDrills/DELETE',
    DELETE_FULFILLED = '@@globalMockDrills/DELETE_FULFILLED',
    DELETE_REJECTED = '@@globalMockDrills/DELETE_REJECTED',

    EXPORT = '@@globalMockDrills/EXPORT',
    EXPORT_FULFILLED = '@@globalMockDrills/EXPORT_FULFILLED',
    EXPORT_REJECTED = '@@globalMockDrills/EXPORT_REJECTED',

    CLEANUP = '@@globalMockDrills/CLEANUP'
}

export const loadGlobalMockDrills = (filters: IGlobalMockDrillsFilters): IAppAction => ({
    type: GlobalMockDrillsActions.LOAD_LIST,
    payload: filters
});

export const loadGlobalMockDrill = (id: string): IAppAction => ({
    type: GlobalMockDrillsActions.LOAD,
    payload: id
});

export const createGlobalMockDrill = (model: ICreateGlobalMockDrill): IAppAction => ({
    type: GlobalMockDrillsActions.CREATE,
    payload: model
});

export const updateGlobalMockDrill = (model: IUpdateGlobalMockDrill): IAppAction => ({
    type: GlobalMockDrillsActions.UPDATE,
    payload: model
});


export const deleteGlobalMockDrill = (id: string, reloadUrl: string): IAppAction => ({
    type: GlobalMockDrillsActions.DELETE,
    payload: { id, reloadUrl },
});

export const exportGlobalMockDrills = (): IAppAction => ({
    type: GlobalMockDrillsActions.EXPORT,
});

export const cleanupGlobalMockDrill = (): IAppAction => ({
    type: GlobalMockDrillsActions.CLEANUP
});
