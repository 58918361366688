import * as React from 'react';
import './ModalCard.scss';

interface IProps {
    title: string;
    modalId: string;
    subTitle?: string;
    isInterstatial?: boolean;
    onClose?: () => void;
}

export const ModalCardHeader: React.FC<IProps> = (props) => {
    return (
        <>
            <header
                className={
                    'modal-card-head ' +
                    (props.isInterstatial ? 'modal-card-head__singleSiteSelector' : '')
                }
            >
                <p id={props.modalId + '-modal-dialog-title'} className="modal-card-title">
                    {props.title}
                </p>
                {props.onClose && (
                    <button className="delete" aria-label="close" onClick={props.onClose} />
                )}
            </header>
            {props.subTitle && (
                <header className="modal-card-sub-head">
                    <p className="modal-card-sub-title">{props.subTitle}</p>
                </header>
            )}
        </>
    );
};
