import * as React from 'react';
export interface IProps {
    features: string[];
    feature: string;
}

export const FeatureToggle: React.FC<React.PropsWithChildren<IProps>> = (props) => (
    <>
        {props.features.indexOf(props.feature) !== -1 && props.children}
    </>
);
