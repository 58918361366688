import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { IAuditorInspectionsDocuments } from './iauditor-inspections-documents';
import { loadComplianceChecklist } from 'actions/actions-v2/iauditor-action';
import { checkPermission } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';

const getIsLoading = (state: IRootState) => state.iauditorState.isLoading;
const getComplianceCheckList = (state: IRootState) => state.iauditorState.complianceCheckList;
const getSiteCode = (state: IRootState) => state.siteState.site.siteCode;
const getIsInitialising = (state: IRootState) => state.siteState.isInitialising;
const getPermissions = (state: IRootState) => state.app.permissions;
const getOperatingPlatforms = (state: IRootState) => state.operatingPlatformState.operatingPlatforms;

export const getSelectedSite = createSelector(
    (state: IRootState) => state.app,
    (appState) =>
        appState.lookupSites.length > 0 && appState.siteId && appState.siteId.length > 0
            ? appState.lookupSites.find((site) => site.key === appState.siteId)
            : null
);
const mapStateToProps = createSelector(
    getIsLoading,
    getComplianceCheckList,
    getSiteCode,
    getIsInitialising,
    getPermissions,
    getOperatingPlatforms,
    getSelectedSite,
    (isLoading, complianceCheckList, siteCode, isInitialising, permissions, allOperatingPlatforms, selectedSite) => ({
        isLoading,
        complianceCheckList,
        siteCode,
        isInitialising,
        hasIAuditorView: checkPermission(Privilege.IAuditorView, permissions),
        allOperatingPlatforms,
        selectedSite
    })
);

const mapDispatchToProps = {
    loadComplianceChecklist,
};

export const IAuditorInspectionsDocumentsContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(IAuditorInspectionsDocuments)
);