import { IAppAction } from '../app-action';
import { IRisk } from 'models/risk';
import { IRiskApproval } from 'models/risk-approval';
import { IUpsertRiskRequest } from 'models/upsert-risk-request';
import { IRiskFilters } from 'models/IRiskFilters';
import { IActionFilters } from 'models/action-filters';
import { IEntitySite } from 'models/entity-site';
import { IRiskClientRequestAmendment } from 'models/risk-client-request-amendment';

export enum RiskActions {
    LOAD_RISKS = '@@risk/LOAD_RISKS',
    LOAD_RISKS_FULFILLED = '@@risk/LOAD_RISKS_FULFILLED',
    LOAD_RISKS_REJECTED = '@@risk/LOAD_RISKS_REJECTED',

    LOAD_RISK = '@@risk/LOAD_RISK',
    LOAD_RISK_FULFILLED = '@@risk/LOAD_RISK_FULFILLED',
    LOAD_RISK_REJECTED = '@@risk/LOAD_RISK_REJECTED',

    CREATE_RISK = '@@risk/CREATE_RISK',
    CREATE_RISK_FULFILLED = '@@risk/CREATE_RISK_FULFILLED',
    CREATE_RISK_REJECTED = '@@risk/CREATE_RISK_REJECTED',

    CREATE_RISK_MITIGATION_PLAN = '@@risk/CREATE_RISK_MITIGATION_PLAN',
    CREATE_RISK_MITIGATION_PLAN_FULFILLED = '@@risk/CREATE_RISK_MITIGATION_PLAN_FULFILLED',
    CREATE_RISK_MITIGATION_PLAN_REJECTED = '@@risk/CREATE_RISK_MITIGATION_PLAN_REJECTED',
    ACCEPT_RISK = '@@risk/ACCEPT_RISK',
    ACCEPT_RISK_FULFILLED = '@@risk/ACCEPT_RISK_FULFILLED',
    ACCEPT_RISK_REJECTED = '@@risk/ACCEPT_RISK_REJECTED',

    UPDATE_RISK = '@@risk/UPDATE_RISK',
    UPDATE_RISK_FULFILLED = '@@risk/UPDATE_RISK_FULFILLED',
    UPDATE_RISK_REJECTED = '@@risk/UPDATE_RISK_REJECTED',

    DELETE_RISK = '@@risk/DELETE_RISK',
    DELETE_RISK_FULFILLED = '@@risk/DELETE_RISK_FULFILLED',
    DELETE_RISK_REJECTED = '@@risk/DELETE_RISK_REJECTED',

    CLOSE_RISK = '@@risk/CLOSE_RISK',
    CLOSE_RISK_FULFILLED = '@@risk/CLOSE_RISK_FULFILLED',
    CLOSE_RISK_REJECTED = '@@risk/CLOSE_RISK_REJECTED',

    OPEN_CLOSED_RISK = '@@risk/OPEN_CLOSED_RISK',
    OPEN_CLOSED_RISK_FULFILLED = '@@risk/OPEN_CLOSED_RISK_FULFILLED',
    OPEN_CLOSED_RISK_REJECTED = '@@risk/OPEN_CLOSED_RISK_REJECTED',

    CREATE_RISK_FOR_INCIDENT = '@@risk/CREATE_RISK_FOR_INCIDENT',
    CREATE_RISK_FOR_INCIDENT_FULFILLED = '@@risk/CREATE_RISK_FOR_INCIDENT_FULFILLED',
    CREATE_RISK_FOR_INCIDENT_REJECTED = '@@risk/CREATE_RISK_FOR_INCIDENT_REJECTED',

    UPDATE_RISK_FOR_INCIDENT = '@@risk/UPDATE_RISK_FOR_INCIDENT',
    UPDATE_RISK_FOR_INCIDENT_FULFILLED = '@@risk/UPDATE_RISK_FOR_INCIDENT_FULFILLED',
    UPDATE_RISK_FOR_INCIDENT_REJECTED = '@@risk/UPDATE_RISK_FOR_INCIDENT_REJECTED',

    SORT_RISKS = '@@risk/SORT_RISKS',

    LOAD_HISTORY = '@@risk/LOAD_HISTORY',
    LOAD_HISTORY_FULFILLED = '@@risk/LOAD_HISTORY_FULFILLED',
    LOAD_HISTORY_REJECTED = '@@risk/LOAD_HISTORY_REJECTED',

    CLEAR_FORM = '@@risk/CLEAR_FORM',

    CBRE_APPROVAL_RISK = '@@risk/CBRE_APPROVAL_RISK',
    CBRE_APPROVAL_RISK_FULFILLED = '@@risk/CBRE_APPROVAL_RISK_FULFILLED',
    CBRE_APPROVAL_RISK_REJECTED = '@@risk/CBRE_APPROVAL_RISK_REJECTED',

    CLIENT_APPROVAL_RISK = '@@risk/CLIENT_APPROVAL_RISK',
    CLIENT_APPROVAL_RISK_FULFILLED = '@@risk/CLIENT_APPROVAL_RISK_FULFILLED',
    CLIENT_APPROVAL_RISK_REJECTED = '@@risk/CLIENT_APPROVAL_RISK_REJECTED',

    CLIENT_REQUEST_AMENDMENT = '@@risk/CLIENT_REQUEST_AMENDMENT',
    CLIENT_REQUEST_AMENDMENT_FULFILLED = '@@risk/CLIENT_REQUEST_AMENDMENT_FULFILLED',
    CLIENT_REQUEST_AMENDMENT_REJECTED = '@@risk/CLIENT_REQUEST_AMENDMENT_REJECTED',

    LOAD_RISK_ACTIONS = '@@risk/LOAD_RISK_ACTIONS',
    LOAD_RISK_ACTIONS_FULFILLED = '@@risk/LOAD_RISK_ACTIONS_FULFILLED',
    LOAD_RISK_ACTIONS_REJECTED = '@@risk/LOAD_RISK_ACTIONS_REJECTED',

    EXPORT_RISKS = '@@risk/EXPORT_RISKS',
    EXPORT_RISKS_FULFILLED = '@@risk/EXPORT_RISKS_FULFILLED',
    EXPORT_RISKS_REJECTED = '@@risk/EXPORT_RISKS_REJECTED',

    EXPORT_ALL_RISKS = '@@risk/EXPORT_ALL_RISKS',
    EXPORT_ALL_RISKS_FULFILLED = '@@risk/EXPORT_ALL_RISKS_FULFILLED',
    EXPORT_ALL_RISKS_REJECTED = '@@risk/EXPORT_ALL_RISKS_REJECTED',

    LOAD_RISKS_SUMMARY = '@@risk/LOAD_RISKS_SUMMARY',
    LOAD_RISKS_SUMMARY_FULFILLED = '@@risk/LOAD_RISKS_SUMMARY_FULFILLED',
    LOAD_RISKS_SUMMARY_REJECTED = '@@risk/LOAD_RISKS_SUMMARY_REJECTED',

    LOAD_RISKS_SUMMARIES = '@@risk/LOAD_RISKS_SUMMARIES',
    LOAD_RISKS_SUMMARIES_FULFILLED = '@@risk/LOAD_RISKS_SUMMARIES_FULFILLED',
    LOAD_RISKS_SUMMARIES_REJECTED = '@@risk/LOAD_RISKS_SUMMARIES_REJECTED',

    RESET_RISK = '@@risk/RESET_RISK',
    RESET_RISK_FULFILLED = '@@risk/RESET_RISK_FULFILLED',
    RESET_RISK_REJECTED = '@@risk/RESET_RISK_REJECTED',
}

export const loadRisks = (filters: IRiskFilters): IAppAction => ({
    type: RiskActions.LOAD_RISKS,
    payload: filters,
});

export const loadRisk = (id: string, siteId: string): IAppAction => ({
    type: RiskActions.LOAD_RISK,
    payload: { id, shard: siteId },
});

export const createRisk = (risk: Partial<IRisk>): IAppAction => ({
    type: RiskActions.CREATE_RISK,
    payload: risk,
});

export const updateRisk = (risk: Partial<IRisk>): IAppAction => ({
    type: RiskActions.UPDATE_RISK,
    payload: risk,
});

export const createRiskMitigationPlan = (risk: Partial<IRisk>): IAppAction => ({
    type: RiskActions.CREATE_RISK_MITIGATION_PLAN,
    payload: risk,
});

export const acceptRisk = (risk: Partial<IRisk>): IAppAction => ({
    type: RiskActions.ACCEPT_RISK,
    payload: risk,
});

export const removeRisk = (id: string, siteId: string, archiveActions: boolean): IAppAction => ({
    type: RiskActions.DELETE_RISK,
    payload: { id, shard: siteId, param: archiveActions, siteId },
});

export const closeRisk = (risk: IRisk): IAppAction => ({
    type: RiskActions.CLOSE_RISK,
    payload: risk,
});

export const openClosedRisk = (risk: IRisk): IAppAction => ({
    type: RiskActions.OPEN_CLOSED_RISK,
    payload: risk,
});

export const createRiskForIncident = (risk: Partial<IRisk>): IAppAction => ({
    type: RiskActions.CREATE_RISK_FOR_INCIDENT,
    payload: risk,
});

export const updateRiskForIncident = (risk: Partial<IRisk>): IAppAction => ({
    type: RiskActions.UPDATE_RISK_FOR_INCIDENT,
    payload: risk,
});

export const upsertPerson = (risk: IRisk | IUpsertRiskRequest): IAppAction => {
    if (risk.id) {
        return updateRisk(risk);
    } else {
        return createRisk(risk);
    }
};

export const onSort = (sortColumn: Extract<keyof IRisk, string>, sortAscending: boolean) => ({
    type: RiskActions.SORT_RISKS,
    payload: { sortColumn, sortAscending },
});

export const loadHistory = (model: IEntitySite): IAppAction => ({
    type: RiskActions.LOAD_HISTORY,
    payload: {
        id: model.id,
        shard: model.siteId,
        siteId: model.siteId,
    },
});

export const clearForm = (): IAppAction => ({
    type: RiskActions.CLEAR_FORM,
});

export const cbreApprovalRisk = (riskApproval: IRiskApproval): IAppAction => ({
    type: RiskActions.CBRE_APPROVAL_RISK,
    payload: riskApproval,
});

export const clientApprovalRisk = (riskApproval: IRiskApproval): IAppAction => ({
    type: RiskActions.CLIENT_APPROVAL_RISK,
    payload: riskApproval,
});

export const clientRequestAmendment = (clientRequestAmendment: IRiskClientRequestAmendment): IAppAction => ({
    type: RiskActions.CLIENT_REQUEST_AMENDMENT,
    payload: clientRequestAmendment,
});

export const loadRiskActions = (filters: IActionFilters): IAppAction => ({
    type: RiskActions.LOAD_RISK_ACTIONS,
    payload: filters,
});

export const exportData = (siteId: string, clientExport: boolean = false): IAppAction => ({
    type: RiskActions.EXPORT_RISKS,
    payload: { siteId, clientExport },
});

export const exportAllData = (clientExport: boolean = false, operatingPlatform: string = ''): IAppAction => ({
    type: RiskActions.EXPORT_ALL_RISKS,
    payload: { clientExport, operatingPlatform },
});

export const loadRisksSummary = (siteId: string): IAppAction => ({
    type: RiskActions.LOAD_RISKS_SUMMARY,
    payload: siteId,
});

export const loadRiskSummaries = (): IAppAction => ({
    type: RiskActions.LOAD_RISKS_SUMMARIES,
});

export const resetRisk = (risk: IRisk): IAppAction => ({
    type: RiskActions.RESET_RISK,
    payload: {
        id: risk.id,
        shard: risk.siteId
    }
});