import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { load } from 'actions/actions-v2/critical-system-spares-actions';
import { ClientCriticalSparePage } from './client-critical-spares-page';

const getIsLoading = (state: IRootState) => state.criticalSystemSparesState.isLoading;
const getSiteId = (state: IRootState) => state.app.siteId;
const getCriticalSpare = (state: IRootState) => state.criticalSystemSparesState.criticalSystemSpare;

const mapStateToProps = createSelector(
    getIsLoading,
    getSiteId,
    getCriticalSpare,
    (isLoading, siteId, criticalSpare) => ({
        isLoading,
        siteId,
        criticalSpare: { ...criticalSpare, siteId }
    })
);

const mapDispatchToProps = {
    load
};

export const ClientCriticalSparesContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientCriticalSparePage));
