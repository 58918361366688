import * as React from 'react';
import { IEngineer } from 'models';
import { IconToolbar, IToolbarIconItem, ToolbarIconType } from '../v2/components';
import useTranslate from 'translations/translation-utils';

export interface IEngineerGridToolbarProps {
    items: IEngineer;
    isExportingEngineer: boolean;
    displayExportButton: boolean;
    isFilterExists?: boolean;
    onShowFilters: () => void;
    onClearFilters: () => void;
    onDownloadEngineer: (id: string) => void;
}

export const EngineerGridToolbar: React.FC<IEngineerGridToolbarProps> = ({
    items,
    isExportingEngineer,
    displayExportButton,
    isFilterExists,
    onShowFilters,
    onClearFilters,
    ...props
}) => {
    const translate = useTranslate();

    const onDownloadEngineer = () => {
        props.onDownloadEngineer(items.id);
    };

    const getToolbarItems = (): IToolbarIconItem[] => {
        return [
            {
                id: 'export-engineers-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.Export'),
                type: ToolbarIconType.download,
                isDisabled: isExportingEngineer,
                onClick: onDownloadEngineer
            },
            {
                id: 'clear-all-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.ClearFilters'),
                type: isFilterExists ? ToolbarIconType.filterApplied : ToolbarIconType.clearFilter,
                onClick: onClearFilters
            },
            {
                id: 'show-filters-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.ShowFilters'),
                type: ToolbarIconType.filter,
                onClick: onShowFilters
            }
        ];
    };

    return <IconToolbar items={getToolbarItems()} />;
};
