import * as React from 'react';

import { ActionUpsertModal } from '../../../../components/actionUpsertModal';
import { IKeyValue } from '../../../../models/key-value';
import { IPersonLookup } from '../../../../models/person-lookup';
import { IAction } from 'models/action';
// import { IUpsertActionRequest } from '../../../../models/upsert-action-request';

export interface IProps {
    isUpsertModalShown: boolean;
    lookups: {
        lookupActionCategories: Array<IKeyValue<string>>;
        lookupActionPriorities: Array<IKeyValue<string>>;
        lookupActionStatuses: Array<IKeyValue<string>>;
        lookupSites: Array<IKeyValue<string>>;
        lookupSitePeopleRoles: IPersonLookup[];
    };

    upsertRequest: Partial<IAction>;
    disableClosedAndCompleted?: boolean;
    isSaving?: boolean;
}

export interface IActionProps {
    onCreate?: (item: IAction) => void;
    onUpdate?: (item: IAction) => void;
    hideModal: () => void;
}

export class ActionUpsert extends React.Component<IProps & IActionProps> {
    private hideModal = () => this.props.hideModal();
    private onSubmit = (item: IAction) => {
        if (item.id) {
            this.props.onUpdate(item);
        } else {
            this.props.onCreate(item);
        }
    };
    public render() {
        return (
            <ActionUpsertModal
                disableClosedAndCompleted={this.props.disableClosedAndCompleted}
                onClose={this.hideModal}
                isShown={this.props.isUpsertModalShown}
                isLoading={this.props.isSaving}
                onSubmit={this.onSubmit}
                upsertActionRequest={this.props.upsertRequest}
                lookupActionCategories={this.props.lookups.lookupActionCategories}
                lookupActionPriorities={this.props.lookups.lookupActionPriorities}
                lookupActionStatuses={this.props.lookups.lookupActionStatuses}
                lookupSitePeopleRoles={this.props.lookups.lookupSitePeopleRoles}
                lookupSites={this.props.lookups.lookupSites}
            />
        );
    }
}
