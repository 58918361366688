import React from 'react';
import { history } from 'routes/App';
import { IconToolbar, IToolbarIconItem, ToolbarIconType } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';
import { scenarioTypeTitle } from '../common';

interface IProps {
    hasReadWritePermission: boolean;
    disableExport: boolean;
    isFilterExists?: boolean;
    isShowHelp: boolean;
    scenarioType: string;
    onShowFilters: () => void;
    onClearFilters: () => void;
    onExport: () => void;
    onClickHelp: () => void;
}

export const MockDrillsPageToolbar: React.FC<IProps> = ({
    hasReadWritePermission,
    disableExport,
    isFilterExists,
    isShowHelp,
    scenarioType,
    onShowFilters,
    onClearFilters,
    onExport,
    onClickHelp,
}) => {
    const translate = useTranslate();

    const getToolbarItems = (): IToolbarIconItem[] => {
        return [
            hasReadWritePermission && {
                id: 'add-local-mock-drill-button',
                title: `${translate('FacilityPopup.Button.Add')} ${scenarioTypeTitle(scenarioType)}`,
                type: ToolbarIconType.add,
                onClick: () => {
                    history.push(`/MockDrill/${scenarioType}/Details`);
                },
            },
            hasReadWritePermission && {
                id: 'export-button',
                title: `${translate('RiskRegisterPage.RiskRegisterToolbar.Title.Export')} ${scenarioTypeTitle(scenarioType)}`,
                type: ToolbarIconType.download,
                isDisabled: disableExport,
                onClick: onExport,
            },
            {
                id: 'clear-all-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.ClearFilters'),
                type: isFilterExists ? ToolbarIconType.filterApplied : ToolbarIconType.clearFilter,
                onClick: onClearFilters,
            },
            {
                id: 'show-filters-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.ShowFilters'),
                type: ToolbarIconType.filter,
                onClick: onShowFilters,
            },
            isShowHelp && {
                id: 'show-help',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.Help'),
                type: ToolbarIconType.help,
                onClick: onClickHelp,
            },
        ];
    };

    return <IconToolbar items={getToolbarItems()} />;
};
