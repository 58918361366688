/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { IEmeraldRequiredInput } from '../shared/required.input';
export class EmeraldTabBarInputs<T> implements IEmeraldTabBarInputs<T> {
  required: boolean = true;
  data?: Array<T> = [];
  vertical? = false;
  placeIconOnTop: boolean = false;
  labelAccessor?: string;
  iconAccessor?: string;
  allowDeselect: boolean = false;
  resizeAuditTime?: number = 200;
}

export interface IEmeraldTabBarInputs<T> extends IEmeraldRequiredInput {
  data?: Array<T>;
  vertical?: boolean;
  placeIconOnTop: boolean;
  labelAccessor?: string;
  iconAccessor?: string;
  allowDeselect?: boolean;
  resizeAuditTime?: number;
}
