import { Action } from 'redux';
import { ISiteData } from 'models/control-centre';
import { IControlCentreState, IFilterWidget, IWidgetState, WidgetType } from '../reducers/control-centre';
import { NavActionTypes } from './nav-actions';

export enum ControlCentreActions {
    INITIALISE_CONTROL_CENTRE_STATE = '@@control_centre/INITIALISE_CONTROL_CENTRE_STATE',
    INITIALISE_CONTROL_CENTRE_STATE_FULFILLED = 'INITIALISE_CONTROL_CENTRE_STATE_FULFILLED',
    INITIALISE_CONTROL_CENTRE_STATE_CANCELLED = 'INITIALISE_CONTROL_CENTRE_STATE_CANCELLED',
    INITIALISE_CONTROL_CENTRE_STATE_REJECTED = 'INITIALISE_CONTROL_CENTRE_STATE_REJECTED',
    INITIALISE_WIDGET_STATE = '@@control_centre/INITIALISE_WIDGET_STATE',
    INITIALISE_WIDGET_STATE_FULFILLED = 'INITIALISE_WIDGET_STATE_FULFILLED',
    INITIALISE_WIDGET_STATE_CANCELLED = 'INITIALISE_WIDGET_STATE_CANCELLED',
    INITIALISE_WIDGET_STATE_REJECTED = 'INITIALISE_WIDGET_STATE_REJECTED',
    TOGGLE_SHOW_SITES = '@@control_centre/TOGGLE_SHOW_SITES',
    TOGGLE_SHOW_SETTINGS = '@@control_centre/TOGGLE_SHOW_SETTINGS',
    TOGGLE_COLLAPSE_EXPAND = '@@control_centre/TOGGLE_COLLAPSE_EXPAND',
    TOGGLE_SHOW_WIDGET = '@@control_centre/TOGGLE_SHOW_WIDGET',
    FILTER_WIDGET = '@@control_centre/FILTER_WIDGET',
    FILTER_AND_UPDATE_WIDGET = '@@control_centre/FILTER_AND_UPDATE_WIDGET',
    CHANGE_SORT_ORDER_VALUE = '@@control_centre/CHANGE_SORT_ORDER_VALUE',
    RESET_WIDGET = '@@control_centre/RESET_WIDGET',
    TOGGLE_FILTER_CHECKED = '@@control_centre/TOGGLE_FILTER_CHECKED',
    UPDATE_WIDGET_STATE = '@@control_centre/UPDATE_WIDGET_STATE',
    UPDATE_WIDGET_STATE_FULFILLED = '@@risks/UPDATE_WIDGET_STATE_FULFILLED',
    UPDATE_WIDGET_STATE_REJECTED = '@@risks/UPDATE_WIDGET_STATE_REJECTED',
    UPDATE_WIDGET_STATE_CANCELLED = '@@risks/UPDATE_WIDGET_STATE_CANCELLED',
}

export interface IInitialiseWidget extends Action {
    type: ControlCentreActions.INITIALISE_WIDGET_STATE;
    payload: {
        widgetType: string
    };
}

export const onInitialiseWidget = (widgetType: string): IInitialiseWidget => ({
    type: ControlCentreActions.INITIALISE_WIDGET_STATE,
    payload: {
        widgetType
    }
});

export interface IInitialiseWidgetFulfilled extends Action {
    type: ControlCentreActions.INITIALISE_WIDGET_STATE_FULFILLED;
    payload: {
        widgetType: string;
        sites: ISiteData[];
    };
}

export interface IInitialiseControlCentre extends Action {
    type: ControlCentreActions.INITIALISE_CONTROL_CENTRE_STATE;
}

export const onInitialiseControlCentre = (): IInitialiseControlCentre => ({
    type: ControlCentreActions.INITIALISE_CONTROL_CENTRE_STATE,
});

export interface IInitialiseWidgetsFulfilled extends Action {
    type: ControlCentreActions.INITIALISE_CONTROL_CENTRE_STATE_FULFILLED;
    payload: {
        controlCentre: IControlCentreState;
    };
}

export const InitialiseWidgetsFulfilled = (controlCentre: IControlCentreState): IInitialiseWidgetsFulfilled => ({
    type: ControlCentreActions.INITIALISE_CONTROL_CENTRE_STATE_FULFILLED,
    payload: {
        controlCentre
    }
});

export interface IToggleShowSitesAction extends Action {
    type: ControlCentreActions.TOGGLE_SHOW_SITES;
    payload: {
        widgetId: string;
        siteIds: string[];
    };
}

export interface IChangeSortOrderValue extends Action {
    type: ControlCentreActions.CHANGE_SORT_ORDER_VALUE;
    payload: {
        sortColumn?: string;
        sortAscending?: boolean;
    };
}

export interface IToggleShowSettingsAction extends Action {
    type: ControlCentreActions.TOGGLE_SHOW_SETTINGS;
    payload: {
        widgetId: string;
    };
}

export interface IToggleCollapseExpandAction extends Action {
    type: ControlCentreActions.TOGGLE_COLLAPSE_EXPAND;
    payload: {
        widgetId: string;
    };
}

export interface IToggleShowWidgetAction extends Action {
    type: ControlCentreActions.TOGGLE_SHOW_WIDGET;
    payload: {
        widgetId: string;
    };
}

export interface IFilterWidgetAction extends Action {
    type: ControlCentreActions.FILTER_WIDGET;
    payload: {
        widgetId: string;
        filterName: Extract<keyof WidgetType, string>;
        value: string | string[] | number;
    };
}

export interface IFilterAndUpdateWidgetAction extends Action {
    type: ControlCentreActions.FILTER_AND_UPDATE_WIDGET;
    payload: {
        widgetId: string;
        filterName: Extract<keyof WidgetType, string>;
        value: string | string[] | boolean | number;
    };
}

export interface IResetWidgetAction extends Action {
    type: ControlCentreActions.RESET_WIDGET;
    payload: {
        widgetId: string;
        filters: IFilterWidget[]
    };
}

export interface IToggleFilterCheckedAction extends Action {
    type: ControlCentreActions.TOGGLE_FILTER_CHECKED;
    payload: {
        widgetId: string;
        filterName: Extract<keyof WidgetType, string>;
        value: string;
    };
}

export interface IUpdateWidgetStateAction extends Action {
    type: ControlCentreActions.UPDATE_WIDGET_STATE;
    payload: {
        widget: IWidgetState;
    };
}

export interface IUpdateWidgetStateFulfilledAction extends Action {
    type: ControlCentreActions.UPDATE_WIDGET_STATE_FULFILLED;
}

export interface IUpdateWidgetStateRejectedAction extends Action {
    type: ControlCentreActions.UPDATE_WIDGET_STATE_REJECTED;
}

export interface IUpdateWidgetStateCancelledAction extends Action {
    type: ControlCentreActions.UPDATE_WIDGET_STATE_CANCELLED;
}

export type ControlCentreActionTypes =
| IInitialiseWidgetFulfilled
| IInitialiseWidget
| IInitialiseWidgetsFulfilled
| IInitialiseControlCentre
| NavActionTypes
| IToggleShowSitesAction
| IToggleShowSettingsAction
| IToggleCollapseExpandAction
| IChangeSortOrderValue
| IToggleShowWidgetAction
| IFilterWidgetAction
| IFilterAndUpdateWidgetAction
| IResetWidgetAction
| IToggleFilterCheckedAction
| IUpdateWidgetStateAction
| IUpdateWidgetStateFulfilledAction
| IUpdateWidgetStateRejectedAction
| IUpdateWidgetStateCancelledAction;
