import { IProcessStepOutcome, IProcessStepAnswer } from 'models/cerm/process-step-answer';
import { isEmpty } from 'lodash';
import { ICermAssessmentResult, IProcessStepResult } from 'models/cerm/cerm-assessment-result';
import { IMasterProcessStep } from 'models/master-process-step/master-process-step';

export const isRequiredToLoadProcessStepAnswer = (
    siteId: string,
    masterProcessStepId: string,
    processStepAnswer: IProcessStepAnswer
): boolean =>
    siteId &&
    masterProcessStepId &&
    (!processStepAnswer ||
        siteId !== processStepAnswer.shard ||
        processStepAnswer.masterProcessStepId !== masterProcessStepId);

export const isValidProcessStepAnswer = (processStepAnswer: IProcessStepAnswer) =>
    processStepAnswer && !isEmpty(processStepAnswer.processStepOutcomes);

export const hasAnyNonComplyOutcomeAnswer = (
    answer: IProcessStepAnswer,
    masterProcessStep: IMasterProcessStep
): boolean => {
    return (
        answer &&
        answer.processStepOutcomes &&
        answer.processStepOutcomes.some(
            (o) => isNonComplyOutcomeAnswer(o) && isOutcomeExistInMaster(masterProcessStep, o)
        )
    );
};

export const isNonComplyOutcomeAnswer = (outcome: IProcessStepOutcome): boolean => {
    if (outcome.compliance === null || outcome.compliance === undefined) {
        return false;
    }

    return outcome.compliance !== outcome.compliantResponse;
};

export const getNonComplyOutcomes = (
    answer: IProcessStepAnswer,
    masterProcessStep: IMasterProcessStep
): IProcessStepOutcome[] => {
    if (!answer || !answer.processStepOutcomes) {
        return [];
    }

    return answer.processStepOutcomes.filter(
        (outcome) =>
            isNonComplyOutcomeAnswer(outcome) &&
            masterProcessStep.expectedOutcomes.some((o) => o.id === outcome.id)
    );
};

export const getOutcomeById = (
    processStepAnswer: IProcessStepAnswer,
    outcomeId: string
): IProcessStepOutcome => {
    return (
        processStepAnswer &&
        processStepAnswer.processStepOutcomes.find((outcome) => outcome.id === outcomeId)
    );
};

export const getOutcomeIdByActionId = (
    processStepAnswer: IProcessStepAnswer,
    actionId: string
): string => {
    const found = processStepAnswer.processStepOutcomes.find(
        (outcome) => outcome.actionId === actionId
    );

    return found ? found.id : null;
};

export const mapOutcomeCompliance = (
    processStepAnswer: IProcessStepAnswer,
    outcomeId: string,
    compliance: boolean
): IProcessStepAnswer => ({
    ...processStepAnswer,
    processStepOutcomes: processStepAnswer.processStepOutcomes.map((outcome: IProcessStepOutcome) =>
        outcome.id === outcomeId ? { ...outcome, compliance, lastUpdated: new Date() } : outcome
    ),
});

export const isProcessStepNotApplicable = (
    cermAssessmentResult: ICermAssessmentResult,
    masterProcessStepId: string
): boolean =>
    cermAssessmentResult &&
    cermAssessmentResult.processStepResults.some(
        (s) => s.applicable !== true && s.masterProcessStepId === masterProcessStepId
    );

export const isProcessStepExistInMaster = (
    masterProcessSteps: IMasterProcessStep[],
    processStepResult: IProcessStepResult
): boolean =>
    masterProcessSteps.some((master) => master.id === processStepResult.masterProcessStepId);

export const isOutcomeExistInMaster = (
    masterProcessStep: IMasterProcessStep,
    o: IProcessStepOutcome
): boolean => masterProcessStep.expectedOutcomes.some((master) => master.id === o.id);
