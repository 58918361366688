import * as React from 'react';
import { Loader } from 'components/loader';

interface IProps {
    type?: 'single-line' | 'multi-line';
    isLoading?: boolean;
}

export const TabGroup: React.FC<React.PropsWithChildren<IProps>> = ({
    type = 'single-line',
    isLoading = false,
    ...props
}) => {
    return (
        <Loader loading={isLoading} showSpinner={false}>
            <div className={`tabs tabs-quantum tabs-${type}`}>
                <ul>{props.children}</ul>
            </div>
        </Loader>
    );
};
