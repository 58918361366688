import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { DateTime, Format } from '../../../../components/dateTime';
import { GridSortLink } from '../../../../components/gridSortLink';
import { Loader } from '../../../../components/loader';
import {
    Timeline,
    TimelineHeader,
    TimelineHeaderGridValue,
    TimelineHeaderGridValueCollection,
    TimelineItem,
} from '../../../../components/timeline';
import { SortDirection } from '../../../../enums/SortDirection';
import { IAction } from '../../../../models/action';
import { ActionAttachmentCollection } from '../actionAttachments';
import { ActionAuditHistory } from '../actionAuditHistory';
import { ActionDeleteConfirmation } from '../actionDeleteConfirmation';
import { IEmbeddedLink } from 'models/embeddedLink';
import './action-list.scss';
import { Button } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconType } from 'routes/material-icon/material-icon-type';

export interface IActionListStateProps {
    items: IAction[];
    isLoading: boolean;
    canEditAction: boolean;
    sortColumn: string;
    sortAscending: boolean;
    expandedItems: string[];
    deleteConfirmationItem: IAction;
}

export interface IActionListDispatchProps {
    onSort: (sortColumn: string, sortAscending: boolean) => void;
    onToggleExpanded: (id: IAction) => void;
    onOpenEditActionModal: (action: IAction) => void;
    remove: (id: string) => void;
}

interface IActionListProps extends IActionListStateProps, IActionListDispatchProps { }

const getFullName = (item: IEmbeddedLink) => {
    if (item === null || item === undefined) {
        return '';
    }
    if (item.items === null || item.items === undefined) {
        return '';
    }
    if (item.items.fullName === null || item.items.fullName === undefined) {
        return '';
    }
    return item.items.fullName;
};

const getActionItem = (
    action: IAction,
    canEditAction: boolean,
    isToggleExpanded: boolean,
    removeEnabled: boolean,
    onToggleExpanded: () => void,
    onOpenEditActionModal: () => void,
    remove: () => void,
    translate: (key: string, concatKey?: string) => any
) => {
    return (
        <Timeline expanded={isToggleExpanded} key={`${action.siteId}__${action.id}`}>
            <TimelineHeader
                centered={true}
                expanded={isToggleExpanded}
                onToggleExpanded={onToggleExpanded}
            >
                <TimelineHeaderGridValueCollection>
                    <TimelineHeaderGridValue className="timeline-title column is-4">
                        {action.title}
                    </TimelineHeaderGridValue>

                    <TimelineHeaderGridValue
                        className="column is-2"
                        label={translate('IncidentRiskActionsModel.Label.Status')}
                    >
                        {translate('RiskActionTab.ActionStatus.dropdown.', action.status)}
                    </TimelineHeaderGridValue>

                    <TimelineHeaderGridValue
                        label={translate('IncidentRiskActionsModel.Label.Category')}
                    >
                        {translate('RiskActionTab.Category.dropdown.', action.category)}
                    </TimelineHeaderGridValue>

                    <TimelineHeaderGridValue
                        className="column is-2"
                        label={translate('IncidentRiskActionsModel.Label.DueDate')}
                    >
                        <DateTime value={action.dueDate} format={Format.DateOnly} />
                    </TimelineHeaderGridValue>

                    {removeEnabled && (
                        <TimelineHeaderGridValue
                            className="column is-1"
                            label={translate('IncidentRiskActionsModel.Label.Action')}
                        >
                            <label onClick={remove}>
                                < MaterialIcons type={MaterialIconType.Delete} alt={translate('IncidentRiskActions.Trash.Remove')} />
                            </label>
                        </TimelineHeaderGridValue>
                    )}
                </TimelineHeaderGridValueCollection>
            </TimelineHeader>

            <TimelineItem>
                <div className="columns">
                    <div className="column is-2 grid__label">
                        {translate('IncidentRiskActions.Label.Description')}
                    </div>
                    <div className="column">{action.description}</div>
                    <div className="column is-narrow">
                        {canEditAction && (
                            <Button onClick={onOpenEditActionModal}>
                                {translate('IncidentRiskActions.Button.Edit')}
                            </Button>
                        )}
                    </div>
                </div>
            </TimelineItem>

            <TimelineItem>
                <div className="columns">
                    <div className="column is-2 grid__label">
                        {translate('IncidentRiskActions.Label.Assignee')}
                    </div>
                    <div className="column">{getFullName(action.assignee)}</div>
                </div>
            </TimelineItem>

            <TimelineItem>
                <div className="columns">
                    <div className="column is-2 grid__label">
                        {translate('IncidentRiskActions.Label.ReminderDate')}
                    </div>
                    <div className="column is-2">
                        <DateTime value={action.reminderDate} format={Format.DateOnly} />
                    </div>
                    <div className="column">
                        <DateTime value={action.reminderDate} format={Format.Relative} />
                    </div>
                </div>
            </TimelineItem>

            <TimelineItem>
                <div className="columns">
                    <div className="column is-2 grid__label">
                        {translate('IncidentRiskActions.Label.DueDate')}
                    </div>
                    <div className="column is-2">
                        <DateTime value={action.dueDate} format={Format.DateOnly} />
                    </div>
                    <div className="column">
                        <DateTime value={action.dueDate} format={Format.Relative} />
                    </div>
                </div>
            </TimelineItem>

            <TimelineItem>
                <ActionAttachmentCollection item={action} title="Attachments" />
            </TimelineItem>

            <TimelineItem>
                <ActionAuditHistory item={action} title="History" continuationToken="" />
            </TimelineItem>
            <TimelineItem>
                <div className="columns">
                    <div className="column is-2 grid__label">
                        {translate('IncidentRiskActions.Label.Parent')}
                    </div>
                    <div className="column is-2">
                        {action.parent && action.parent.title && (
                            <NavLink to={`/RiskRegister/${action.parent.id}/details`}>
                                {action.parent.title}
                            </NavLink>
                        )}
                        {!action.parent ||
                            (!action.parent.title && (
                                <span> {translate('IncidentRiskActions.Label.Unparented')} </span>
                            ))}
                    </div>
                </div>
            </TimelineItem>
        </Timeline>
    );
};

const getGridHeader = (label: string, fieldName: string, props: IActionListProps) => {
    if (props.onSort) {
        const onSort = (sortField: string, sortDirection: SortDirection) =>
            props.onSort(sortField, sortDirection === SortDirection.Ascending);
        return (
            <GridSortLink
                currentSortFieldName={props.sortColumn}
                currentSortDirection={
                    props.sortAscending ? SortDirection.Ascending : SortDirection.Descending
                }
                sortFieldName={fieldName}
                onSort={onSort}
            >
                {label}
            </GridSortLink>
        );
    } else {
        return <label> {label} </label>;
    }
};

export const ActionList: React.FC<IActionListProps> = (props) => {
    const translate = useTranslate();
    return (
        <Loader className="ActionList" loading={props.isLoading}>
            {props.items && props.items.length > 0 ? (
                <React.Fragment>
                    <div
                        className={`Timeline-offset grid-headers columns ${props.isLoading ? 'is-loading' : ''
                            }`}
                    >
                        <div className="column is-4">{getGridHeader(translate('IncidentList.Header.Title'), 'title', props)}</div>
                        <div className="column is-2">
                            {getGridHeader(translate('IncidentRiskActionsModel.Label.Status'), 'status', props)}
                        </div>
                        {/* <div className="column">{getGridHeader('Priority', 'priorityId', props)}</div> */}
                        <div className="column">{getGridHeader(translate('IncidentRiskActionsModel.Label.Category'), 'category', props)}</div>
                        <div className="column is-2">
                            {getGridHeader(translate('RiskActionTab.TableHeader.Duedate'), 'dueDate', props)}
                        </div>
                        {props.remove && (
                            <div className="column is-1">
                                {getGridHeader(translate('IncidentRiskActions.Label.Action'), 'action', props)}
                            </div>
                        )}
                    </div>

                    {/* Not redrawing on change */}
                    {props.items &&
                        props.items.map((action) => {
                            const removeEnabled = props.remove ? true : false;
                            return getActionItem(
                                action,
                                props.canEditAction,
                                props.expandedItems.includes(action.id),
                                removeEnabled,
                                () => props.onToggleExpanded(action),
                                () => props.onOpenEditActionModal(action),
                                () => props.remove(action.id),
                                translate
                            );
                        })}
                </React.Fragment>
            ) : (
                <p> {translate('IncidentRiskActions.Label.NoItemsFound')} </p>
            )}

            <ActionDeleteConfirmation
                confirmMessage={translate('IncidentRiskActions.confirmMessage')}
                itemForConfirmation={props.deleteConfirmationItem}
            />
        </Loader>
    );
};
