import * as React from 'react';
import { uniqueId } from 'lodash';
import { FileInput } from '../fileInput';
import { Button } from '../v2/components';
import messages from 'translations/messages';
import { injectIntl } from 'react-intl';

export interface IProps {
    aloudFiles?: string;
    allowMultiple?: boolean;
    visible?: boolean;
    uploading: boolean;
    onConfirm: (files: File[]) => void;
    onClose: () => void;
    intl: any;
    customMessage?: string;
}

export interface IState {
    files: File[];
}

export class FileUploadModalContainer extends React.Component<IProps, IState> {
    private readonly modalId: string;

    constructor(props: IProps) {
        super(props);
        this.onClose = this.onClose.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
        this.onFileChange = this.onFileChange.bind(this);

        this.modalId = uniqueId('FileUploadModal');
    }

    public render() {
        const intl = this.props.intl;
        return this.props.visible ? (
            <div id={this.modalId} className={`modal modal-quantum is-clipped is-active`}>
                <div className="modal-background" onClick={this.onClose} />
                <div className="modal-content">
                    <h2>{intl.formatMessage(messages['Attachments.UploadFile.Label.UploadFile'])}</h2>
                    <FileInput name={this.modalId} allowMultiple={this.props.allowMultiple} onChange={this.onFileChange} label={intl.formatMessage(messages['Attachments.UploadFile.Label.ChooseFile'])} />
                    <div className="document-info">
                        {!this.props.aloudFiles ? intl.formatMessage(messages['Attachments.UploadFile.Label.RecommendedFile']) : this.props.aloudFiles}
                    </div>

                    {this.props.customMessage && (
                        <div className="document-info">{this.props.customMessage}</div>
                    )}

                    <div className="modal-buttons">
                        <Button buttonType="cancel" onClick={this.onClose}>
                            {intl.formatMessage(messages['Attachments.UploadFile.Label.Cancel'])}
                        </Button>
                        <Button onClick={this.onConfirm} isLoading={this.props.uploading}>
                            {intl.formatMessage(messages['Attachments.UploadFile.Label.Upload'])}
                        </Button>
                    </div>
                </div>
            </div>
        ) : null;
    }

    private onClose() {
        this.props.onClose();
    }

    private onConfirm() {
        this.props.onConfirm(this.state.files);
    }

    private onFileChange(files: File[]) {
        this.setState(() => ({ files }));
    }
}

export const FileUploadModal = injectIntl(FileUploadModalContainer);
