import { EmeraldDragHandlerPositionType } from './drag-handler.position';
import { IEmeraldListItemCoreInputs } from './list-item.inputs';

export class DragHandleCoreOptions implements IDragHandleCoreOptions {
  icon?: string = 'drag_indicator';
  position?: string = 'left';
  color?: string = '#0000004d';
}

export interface IDragHandleCoreOptions {
  icon?: string;
  position?: string;
  color?: string;
}

export class EmeraldListCoreInputs implements IEmeraldListCoreInputs {
  items?: IEmeraldListItemCoreInputs[];
  isDragable?: boolean = false;
  dragHandleOptions?: IDragHandleCoreOptions = new DragHandleCoreOptions();
  // below inputs to be discarded soon and be deleted
  listItems?: Array<any>;
  showDragHandler?: boolean = false;
  dragHandlerIcon?: string;
  dragHandlerPosition?: EmeraldDragHandlerPositionType;
}

export interface IEmeraldListCoreInputs {
  items?: IEmeraldListItemCoreInputs[];
  isDragable?: boolean;
  dragHandleOptions?: IDragHandleCoreOptions;
  //below inouts are duplicate and to be removed
  listItems?: Array<any>; //duplicate and to be removed
  showDragHandler?: boolean; //duplicate and to be removed
  dragHandlerIcon?: string; //duplicate and to be removed
  dragHandlerPosition?: EmeraldDragHandlerPositionType; //duplicate and to be removed
}
