import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import {
    loadAttachmentsMockDrillReport,
    uploadAttachmentMockDrillReport
} from 'actions/actions-v2/attachment-action-v2';
import { MockDrillReportDocumentsTab } from './mock-drill-report-documents-tab';

const getIsUploading = (state: IRootState) => state.mockDrillReportState.isLoading;
const getAttachments = (state: IRootState) => state.mockDrillReportState.attachments;

const mapStateToProps = createSelector(
    getIsUploading,
    getAttachments,
    (isUploading, attachments) => ({
        isUploading,
        attachments
    })
);

const mapDispatchToProps = {
    loadAttachments: loadAttachmentsMockDrillReport,
    uploadAttachment: uploadAttachmentMockDrillReport
};

export const MockDrillReportDocumentsTabContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MockDrillReportDocumentsTab);
