import { combineReducers } from 'redux';

import { ClientActions, ClientActionTypes } from '../../actions/client-actions';
import { IClient } from '../../models/client';
import { genericSort } from 'utils/sort-utils';
import { ClientActions as ClientActionsV2 } from 'actions/actions-v2/client-action-v2';
import { IAppAction } from 'actions/app-action';

export interface IClientsGridState {
    items: IClient[];
    isLoading: boolean;
    sortColumn: string;
    sortAscending: boolean;
    isInitialising: boolean;
}

export const INITIAL_STATE: IClientsGridState = {
    isLoading: false,
    items: [],
    sortAscending: false,
    sortColumn: null,
    isInitialising: true
};

const items = (state: IClientsGridState['items'] = INITIAL_STATE.items, action: IAppAction) => {
    switch (action.type) {
        case ClientActionsV2.LOAD_CLIENTS_FULFILLED:
            return action.payload ? action.payload : state;
        case ClientActionsV2.CREATE_CLIENT_FULFILLED:
            if (action.payload !== null) {
                return [action.payload, ...state];
            }
            return state;
        case ClientActionsV2.UPDATE_CLIENT_FULFILLED:
            if (action.payload !== null) {
                const actionItem = state.find((n) => n.id === action.payload.id);
                if (actionItem) {
                    return state.map((item: IClient) => {
                        if (item.id === action.payload.id) {
                            return action.payload;
                        }
                        return item;
                    });
                }
                return [action.payload, ...state];
            }
            return state;
        case ClientActions.SORT_CLIENT:
            return genericSort(action.payload.key, [...state], action.payload.sortAscending);
        case ClientActionsV2.DELETE_CLIENT_FULFILLED:
            return state.filter((n) => n.id !== action.payload.id);
        default:
            return state;
    }
};

const isLoading = (state: boolean = INITIAL_STATE.isLoading, action: IAppAction) => {
    switch (action.type) {
        case ClientActions.LOAD_CLIENTS:
            return true;
        case ClientActions.LOAD_PAGE_FULFILLED:
        case ClientActions.LOAD_CLIENTS_CANCELLED:
        case ClientActions.LOAD_CLIENTS_FULFILLED:
        case ClientActions.LOAD_CLIENTS_REJECTED:
        case ClientActionsV2.LOAD_CLIENTS_FULFILLED:
            return false;

        default:
            return state;
    }
};

const sortColumn = (
    state: IClientsGridState['sortColumn'] = INITIAL_STATE.sortColumn,
    action: ClientActionTypes
) => {
    switch (action.type) {
        case ClientActions.SORT_CLIENT:
            return action.payload.key;

        default:
            return state;
    }
};

const sortAscending = (
    state: IClientsGridState['sortAscending'] = INITIAL_STATE.sortAscending,
    action: ClientActionTypes
) => {
    switch (action.type) {
        case ClientActions.SORT_CLIENT:
            return action.payload.sortAscending;

        default:
            return state;
    }
};

const isInitialising = (
    state: IClientsGridState['isInitialising'] = INITIAL_STATE.isInitialising,
    action: IAppAction
) => {
    switch (action.type) {
        case ClientActions.LOAD_PAGE_FULFILLED:
        case ClientActionsV2.LOAD_CLIENTS_FULFILLED:
            return false;
        default:
            return state;
    }
};

export const reducer = combineReducers<IClientsGridState>({
    isInitialising,
    isLoading,
    items,
    sortAscending,
    sortColumn,
});
