/* eslint-disable @typescript-eslint/no-inferrable-types */
export interface pageData {
  from: number;
  to: number;
  currentPage: number;
  pageSize: number;
}

export class EmeraldPaginationCoreInputs
  implements IEmeraldPaginationCoreInputs
{
  total: number = 0;
  pageSizeOptions?: Array<number> = [10, 25, 100];
  pageSize? = 10;
  currentPage? = 1;
  pageRangeDisplayed? = 5;
  pageSizeLabel? = 'Rows per page:';
  showNumberedList? = false;
  showRecords? = true;
  //onChange?: any;
}

export interface IEmeraldPaginationCoreInputs {
  total: number;
  pageSizeOptions?: Array<number> | Array<any>;
  pageSize?: number;
  currentPage?: number;
  pageRangeDisplayed?: number;
  pageSizeLabel?: string;
  showNumberedList?: boolean;
  showRecords?: boolean;
  onChange?: any;
}
