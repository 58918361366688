import { AssetActions } from 'actions/asset-action';
import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from 'epics/base-epic';
import { IAssetManufacturer, IModelDetails } from 'models/asset/asset-model';
import { Epic, combineEpics } from 'redux-observable';

const controllerName = 'asset';
const apiPath = `/api/${controllerName}`;

const loadAssetManufacturerActions = {
    action: AssetActions.LOAD_ASSET_MANUFACTURER,
    actionFulfilled: AssetActions.LOAD_ASSET_MANUFACTURER_FULFILLED,
    actionRejected: AssetActions.LOAD_ASSET_MANUFACTURER_REJECTED,
} as IActionType;

const loadAssetModelActions = {
    action: AssetActions.LOAD_ASSET_MODEL,
    actionFulfilled: AssetActions.LOAD_ASSET_MODEL_FULFILLED,
    actionRejected: AssetActions.LOAD_ASSET_MODEL_REJECTED,
} as IActionType;

const loadAssetManufacturerEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, loadAssetManufacturerActions).get<IAssetManufacturer[]>(apiPath + '/search/manufacturer');

const loadAssetmodelEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, loadAssetModelActions).get<IModelDetails[]>(apiPath + '/search/model');

export const assetEpics = combineEpics(
    loadAssetManufacturerEpic,
    loadAssetmodelEpic
);    