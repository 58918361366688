/* eslint-disable @typescript-eslint/no-inferrable-types */
import { EmeraldBadgeSizeType, EmeraldBadgeVariant } from '../badge/badge.size';
import { IEmeraldActiveInput } from '../shared/active.input';
import { IEmeraldDisabledInput } from '../shared/disabled.input';
import { IEmeraldLabelInput } from '../shared/label.input';

export class EmeraldTabData implements IEmeraldTabData {
  isActive?: boolean;
  hasBadge?: boolean = false;
  badgeSize?: EmeraldBadgeSizeType;
  badgeContent?: string;
  badgeVariant?: EmeraldBadgeVariant;
  isTabInstance: boolean = true;
  isTabActive: boolean = false;
  constructor(
    public label: string,
    public icon: string,
    public disabled: boolean = false
  ) {}
}

export interface IEmeraldTabData
  extends IEmeraldActiveInput,
    IEmeraldDisabledInput,
    IEmeraldLabelInput {
  icon?: string;
  hasBadge?: boolean;
  badgeSize?: EmeraldBadgeSizeType;
  badgeContent?: string;
  badgeVariant?: EmeraldBadgeVariant;
  isTabInstance?: boolean;
  isTabActive?: boolean;
  directive?: string;
  routerLink?: string;
  value?: string;
  navigationExtras?: any;
}

export enum TabPaginationDirection {
  PREVIOUS = 'previous',
  NEXT = 'next',
}
