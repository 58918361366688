//No More used
import * as React from 'react';
import { isEmpty } from 'lodash';
import { BulmaSize } from '../../enums/BulmaSize';
import { IAttachmentCollection } from '../../models/attachment-collection';
import { IKeyedItem } from '../../models/keyed-item';
import { DateTime, Format } from '../dateTime';
import { GridContentExpander } from '../gridContentExpander';
import { Icon, IconType } from '../icon';
import { Spinner } from '../spinner';

import './attachment-collection.scss';
import { Button } from '../v2/components';
import { useTranslate } from 'translations/translation-utils';

export interface IAttachmentItemValue<T> {
    label: string;
    value: (input: T) => string;
}

export interface IAttachmentCollectionStateProps<T extends IAttachmentCollection = IAttachmentCollection> {
    item: IKeyedItem;
    title: string;
    data: T[];
    isExpanded: boolean;
    isLoading: boolean;
    isDisabled: boolean;
}

export interface IAttachmentCollectionActionProps {
    onOpen: (item: IKeyedItem) => void;
    onClose: (item: IKeyedItem) => void;
    onDelete?: (item: IKeyedItem) => void;
    onShowAddAttachmentModal: (riskId: string, siteId: string) => void;
}

interface IProps extends IAttachmentCollectionStateProps, IAttachmentCollectionActionProps { }

export const AttachmentCollection: React.FC<IProps> = (props) => {
    const onOpen = () => props.onOpen(props.item);
    const onClose = () => props.onClose(props.item);
    const translate = useTranslate();

    const onDelete: React.MouseEventHandler<HTMLButtonElement> = () => {
        props.onDelete(props.item);
    };

    const onShowAddAttachmentModal = () => props.onShowAddAttachmentModal(props.item.id, props.item.siteId);

    return (
        <GridContentExpander
            buttonText={props.title}
            isExpanded={props.isExpanded}
            isLoading={props.isLoading}
            className="attachment-collection"
            onOpen={onOpen}
            onClose={onClose}
        >
            {isEmpty(props.data) && (
                <p>{props.isDisabled ? '(Coming soon)' : 'No attachments.'}  </p>
            )}

            {!isEmpty(props.data) && (
                <div className="grid-data">
                    <div className="columns horizontal-table grid-data__headers ">
                        <div className="column is-5">{translate('Attachments.AttachmentList.FileName')}</div>
                        <div className="column">{translate('Attachments.AttachmentList.Type')}</div>
                        <div className="column">{translate('Attachments.AttachmentList.Uploaded')}</div>
                        <div className="column" />
                        {props.onDelete && <div className="column is-1" />}
                    </div>

                    {props.data.map((item, i) => (
                        <div className="columns horizontal-table" key={i}>
                            <div className="column is-5">
                                <h2 className="mobile-header">{translate('Attachments.AttachmentList.FileName')}</h2>
                                {item.isClean && (
                                    <div><a
                                        className="is-link"
                                        href={item.isClean ? item.blobStorageUri : ''}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title={item.fileName}
                                    >
                                        {item.fileName}
                                    </a></div>
                                )}
                                {!item.isClean && (
                                    <div><span>{item.fileName}</span></div>
                                )}
                            </div>
                            <div className="column">
                                <h2 className="mobile-header">{translate('Attachments.AttachmentList.Uploaded')}</h2>
                                <div><DateTime value={item.createdDateTime} format={Format.DateAndTime} /></div>
                            </div>
                            <div className="column">
                                {item.isClean === null && (
                                    <div className="button is-warning is-small">
                                        <span className="icon is-small is-loading">
                                            <Spinner />
                                        </span>
                                        <span>{translate('Attachments.AttachmentList.ScanStatus.Scanning')}</span>
                                    </div>
                                )}
                                {item.isClean === true && (
                                    <span className="tag is-success is-clearfix">{translate('Attachments.AttachmentList.ScanStatus.Scanned&Uploaded')}</span>
                                )}
                                {item.isClean === false && (
                                    <span className="tag is-danger is-clearfix">{translate('Attachments.AttachmentList.ScanStatus.Malwarefound')}</span>
                                )}
                            </div>
                            {props.onDelete && (<div className="column is-1">
                                <button
                                    className="button grid-view__option-action"
                                    onClick={onDelete}
                                >
                                    <Icon type={IconType.Trash} size={BulmaSize.Medium} />
                                    <span>{translate('Attachments.AttachmentList.ButtonWithConfirmDialog.ButtonText')}</span>
                                </button>
                            </div>)}
                        </div>
                    ))}
                </div>
            )}
            <div className="columns GridContentExpander__action-buttons">
                <div className="column">
                    <Button
                        disabled={props.isDisabled}
                        onClick={onShowAddAttachmentModal}
                    >
                        {translate('Attachments.AttachmentList.AddAttachement')}
                    </Button>
                </div>
            </div>
        </GridContentExpander>
    );
};
