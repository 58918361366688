import { IIncident } from 'models';
import { IIncidentsFiltersState, getDefaultIncidentsFiltersState } from './incidents-filters-state';
import { IKeyValue } from 'models/key-value';
import { getDefaultIncidentsGridState, IIncidentsGridState } from './incidents-grid-state';

export interface IIncidentsState {
    incidents: IIncident[];
    incidentSite: string[];
    incidentSeverity: string[];
    incidentStatus: string[];
    incidentType: string[];
    incidentTimePeriod: string[];
    lookupSites: Array<IKeyValue<string>>;
    lookupIncidentSeverity: Array<IKeyValue<string>>;
    lookupIncidentStatus: Array<IKeyValue<string>>;
    lookupIncidentType: Array<IKeyValue<string>>;
    lookupIncidentTimePeriod: Array<IKeyValue<string>>;
    incidentsFilters: IIncidentsFiltersState;
    expandedFilters: string[];
    incidentsGrid: IIncidentsGridState;
    isLoading: boolean;
}

const defaultState: IIncidentsState = {
    incidents: [],
    incidentSite: [],
    incidentSeverity: [],
    incidentStatus: [],
    incidentType: [],
    incidentTimePeriod: [],
    lookupSites: [],
    lookupIncidentSeverity: [],
    lookupIncidentStatus: [],
    lookupIncidentType: [],
    lookupIncidentTimePeriod: [],
    incidentsFilters: getDefaultIncidentsFiltersState(),
    expandedFilters: [],
    incidentsGrid: getDefaultIncidentsGridState(),
    isLoading: false
};

// tslint:disable-next-line:arrow-return-shorthand
export const getDefaultIncidentsState = (options?: any) => { return {...defaultState, ...options }; };
