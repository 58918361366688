import * as React from 'react';
import { FileUploadModal } from 'components/fileUploadModal';

export interface IStateProps {
    isVisible: boolean;
    isModalVisible: boolean;
    isUploading: boolean;
}

export interface IDispatchProps {
    onConfirm: (id: string, childId: string, files: File[]) => void;
    hideModal: () => void;
}

export interface IOwnProps {
    id: string;
    siteId: string;
    childId: string;
}

interface IProps extends IStateProps, IDispatchProps, IOwnProps { }

export const EvidenceModal: React.FC<IProps> = (props) => {
    if (!props.isVisible) {
        return null;
    }

    const onConfirm = (files: File[]) => props.onConfirm(
        props.id,
        props.childId,
        files);

    return (
        <FileUploadModal
            visible={props.isModalVisible}
            uploading={props.isUploading}
            onConfirm={onConfirm}
            onClose={props.hideModal}
        />
    );
};

EvidenceModal.defaultProps = {
    isModalVisible: false
};
