import React from 'react';
import { IconToolbar, IToolbarIconItem, ToolbarIconType } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';

interface IProps {
    isExporting: boolean;
    onExportAllSites: () => void;
    onExportThisSite: () => void;
}

export const RiskProfileQuestionnaireToolbar: React.FC<IProps> = ({
    isExporting,
    onExportAllSites,
    onExportThisSite
}) => {
    const translate = useTranslate();
    const getToolbarItems = (): IToolbarIconItem[] => {
        return [
            {
                id: 'export-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.Export'),
                type: ToolbarIconType.download,
                isDisabled: isExporting,
                children: [
                    {
                        id: 'export-this-site-button',
                        title: translate('RiskProfileMasterQuestionnaireContainer.Toolbar.Title.ForAllSites'),
                        type: ToolbarIconType.excel,
                        isDisabled: isExporting,
                        onClick: onExportAllSites
                    },
                    {
                        id: 'export-all-site-button',
                        title: translate('RiskProfileMasterQuestionnaireContainer.Toolbar.Title.OnlyThisSite'),
                        type: ToolbarIconType.excel,
                        isDisabled: isExporting,
                        onClick: onExportThisSite
                    }
                ]
            }
        ];
    };

    return <IconToolbar items={getToolbarItems()} />;
};
