import { Epic, combineEpics } from 'redux-observable';
import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { RiskAssessmentActions } from 'actions/actions-v2/risk-assessment-action-v2';
import { IAction } from 'models/action';

const controllerName = 'riskAssessment';

const getAllActions = {
    action: RiskAssessmentActions.LOAD_RISK_ASSESSMENTS,
    actionFulfilled: RiskAssessmentActions.LOAD_RISK_ASSESSMENTS_FULFILLED,
    actionRejected: RiskAssessmentActions.LOAD_RISK_ASSESSMENTS_REJECTED,
} as IActionType;

const setRiskAssessmentAnswersActions = {
    action: RiskAssessmentActions.SET_RISK_ASSESSMENT_ANSWERS,
    actionFulfilled: RiskAssessmentActions.SET_RISK_ASSESSMENT_ANSWERS_FULFILLED,
    actionRejected: RiskAssessmentActions.SET_RISK_ASSESSMENT_ANSWERS_REJECTED,
} as IActionType;

const loadRiskAssessmentsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getAllActions).get<IAction[]>(`/api/${controllerName}`);

const setRiskAssessmentAnswersEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, setRiskAssessmentAnswersActions).post<IAction>(
        `/api/${controllerName}/SetRiskAssessmentAnswers`
    );

export const riskAssessmentEpicsV2 = combineEpics(
    loadRiskAssessmentsEpic,
    setRiskAssessmentAnswersEpic
);
