import React from 'react';
import { Panel } from '../v2/components';
import { IClientRisksSummary } from 'models/risk';
import { CircleDataDisplayer } from '../circle-data-displayer/circle-data-displayer';
import useTranslate from 'translations/translation-utils';

interface IProps {
    risks: IClientRisksSummary;
    isLoading: boolean;
}

export const RiskRegisterSummary: React.FC<IProps> = ({ risks, isLoading }) => {
    const translate = useTranslate();
    return (
        <Panel title={translate('RiskRegisterUpsertPage.CustomLinks.Title')} intro={translate('RiskRegisterSummary.Intro')}>
            <br />
            <div className="horizontal-layout">
                <CircleDataDisplayer
                    numberRisks={risks ? risks.numberOpenPendingReview : 0}
                    titleLineOne={translate('RiskActionTab.ActionStatus.dropdown.PendingApproval')}
                    titleLineTwo=""
                    link="/RiskRegisterClient/OpenPending"
                    colour="red"
                    isLoading={isLoading}
                />
                <CircleDataDisplayer
                    numberRisks={risks ? risks.numberOpenRiskAccepted : 0}
                    titleLineOne={translate('Globals.Status.Open&RiskAccepted')}
                    titleLineTwo=""
                    link="/RiskRegisterClient/OpenAccepted"
                    colour="orange"
                    isLoading={isLoading}
                />
                <CircleDataDisplayer
                    numberRisks={risks ? risks.numberOpenRemedyAuthorised : 0}
                    titleLineOne={translate('Globals.Status.MitigationLevel.PendingMitigation')}
                    titleLineTwo=""
                    link="/RiskRegisterClient/OpenApproved"
                    colour="green"
                    isLoading={isLoading}
                />
                <CircleDataDisplayer
                    numberRisks={risks ? risks.numberOpen : 0}
                    titleLineOne={translate('Globals.Status.OpenRisks')}
                    titleLineTwo=""
                    link="/RiskRegisterClient/ClientRisks"
                    colour="dark-green"
                    isLoading={isLoading}
                />
                <CircleDataDisplayer
                    numberRisks={risks ? risks.numberClosed : 0}
                    titleLineOne={translate('Globals.Status.ClosedRisks')}
                    titleLineTwo=""
                    link="/RiskRegisterClient/ClosedRisks"
                    colour="black"
                    isLoading={isLoading}
                />
            </div>
            <br />
        </Panel>
    );
};
