import { AppActions, AppActionTypes } from '../../actions/app-actions';
import { loadSite } from 'actions/actions-v2/site-actions-v2';
import { loadMenu, selectedMenu } from 'actions/actions-v2/menu-actions';

export default {
    onSiteChange: (siteId: string): AppActionTypes => ({
        type: AppActions.CHANGE_SITE,
        payload: {
            siteId,
        },
    }),
    onLoadSite: loadSite,
    onLoadMenu: loadMenu,
    onSelectedMenuItem: selectedMenu,
};
