import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { CermAssessmentTab } from './cerm-assessment-tab';
import { setProcessStepResult } from 'actions/actions-v2/cerm-assessment-action-v3';
import { getLookupSites, getLookupSitesClient } from 'sharedSelectors';

const getIsLoading = (state: IRootState) =>
    state.masterProcessStepState.isLoading || state.cermAssessmentResultState.isLoading;
const getSiteId = (state: IRootState) => state.app.siteId;
const getPillars = (state: IRootState) => state.app.lookupPillars;
const getCermAssessmentResult = (state: IRootState) =>
    state.cermAssessmentResultState.cermAssessmentResult;
const getPermissions = (state: IRootState) => state.app.permissions;    

const mapStateToProps = createSelector(
    getIsLoading,
    getSiteId,
    getPillars,
    getCermAssessmentResult,
    getLookupSites,
    getLookupSitesClient,
    getPermissions,
    (isLoading, siteId, pillars, cermAssessmentResult,lookupSites, lookupSitesClient, permissions) => ({
        isLoading,
        siteId,
        pillars,
        cermAssessmentResult,
        lookupSites,
        lookupSitesClient,
        permissions
    })
);

const mapDispatchToProps = {
    setProcessStepResult
};

export const CermAssessmentTabContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CermAssessmentTab);
