import * as React from 'react';
import { Panel } from 'components/v2/components';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import moment from 'moment';
import 'chartjs-plugin-annotation';
import 'chartjs-adapter-moment';
import { FormikProps } from 'formik';
import { ICriticalSystemSpare } from 'models/critical-system-spares/critical-system-spare';
import useTranslate from 'translations/translation-utils';

Chart.register(...registerables);

interface IProps {
    formikBag: FormikProps<ICriticalSystemSpare>;
}

export const ManageStockTab: React.FC<IProps> = (props) => {
    const translate = useTranslate();

    const { quantityCurrent, quantityMin, quantityMax, updateRecords } = {
        ...props.formikBag.values
    };
    const onStockLevelButtonClick = (count: number) => {
        const stockValue = quantityCurrent + count;
        props.formikBag.setFieldValue('quantityCurrent', stockValue);
    };

    const shouldDisableDecreaseStockLevelButton = (count: number) => {
        return quantityCurrent + count < 0;
    };

    const exceededMaximumQuantity = () => {
        return quantityCurrent > quantityMax;
    };

    const getClassNameIfQuantityIsBelowMinimum = () => {
        return quantityCurrent < quantityMin || quantityCurrent > quantityMax
            ? ' stock-level-low'
            : '';
    };

    const getStockHistoryData = (): any => {
        const parsedHistoryData = [];
        if (updateRecords && updateRecords.length > 0) {
            for (const item in updateRecords) {
                if (item) {
                    const date = moment(updateRecords[item].updatedDateTime);
                    parsedHistoryData.push({
                        x: date.valueOf(),
                        y: updateRecords[item].quantity
                    });
                }
            }
        }

        const data = {
            datasets: [
                {
                    data: parsedHistoryData
                }
            ]
        };

        return data;
    };

    const chartSetting: any = {
        responsive: true,
        plugins: {
            annotation: {
                annotations: {
                    line1: {
                        type: 'line',
                        mode: 'horizontal',
                        scaleID: 'y-axis-0',
                        value: quantityMin,
                        borderColor: 'red',
                        borderWidth: 2,
                        label: {
                            enabled: true,
                            content: quantityMin,
                            backgroundColor: 'rgba(255,0,0,1)',
                            position: 'right',
                            xPadding: 20,
                            yPadding: 10,
                            fontSize: 14
                        }
                    }
                }
            },
            legend: {
                display: false,
            }
        },
        elements: {
            point: {
                radius: 5,
                borderColor: 'rgba(0,104,77,1)',
                backgroundColor: 'rgba(0,104,77,1)'
            },
            line: {
                tension: 0,
                borderColor: 'rgba(0,104,77,1)',
                backgroundColor: 'rgba(255,255,255,0)'
            }
        },
        scales: {
            y: {
                suggestedMin: 0,
                //suggestedMax: 100,
                ticks: {
                    beginAtZero: true,
                    stepSize: Math.round(Math.max(quantityMax, quantityCurrent) * 0.1),
                },
                title: {
                    display: true,
                    text: translate('ManageStock.Lable.1')
                    // padding: 50 // test
                }
            },
            x: {
                title: {
                    text: translate('ManageStock.Lable.2'),
                    display: true
                },
                type: 'time',
                time: {
                    unit: 'month'
                },

            }
        },

    };

    return (
        <>
            <div className="tabs-container tab-content tab-manage-stock">
                <h3>{translate('ManageStock.Lable.3')}</h3>
                <div className="form">
                    <button
                        className="button button-action"
                        onClick={() => onStockLevelButtonClick(-5)}
                        disabled={shouldDisableDecreaseStockLevelButton(-5)}
                    >
                        -5
                    </button>
                    <button
                        className="button button-action"
                        onClick={() => onStockLevelButtonClick(-1)}
                        disabled={shouldDisableDecreaseStockLevelButton(-1)}
                    >
                        -1
                    </button>
                    <input
                        className={`input stock-level${getClassNameIfQuantityIsBelowMinimum()}`}
                        value={quantityCurrent}
                        type="text"
                        readOnly={true}
                    />
                    <button
                        className="button button-action"
                        onClick={() => onStockLevelButtonClick(1)}
                    >
                        +1
                    </button>
                    <button
                        className="button button-action"
                        onClick={() => onStockLevelButtonClick(5)}
                    >
                        +5
                    </button>
                    {exceededMaximumQuantity() ? (
                        <p className="has-text-warning">
                            {translate('ManageStock.Lable.4') + ' '}
                            <strong className="has-text-danger">({quantityMax})</strong>
                            {' ' + translate('ManageStock.Lable.5')}
                        </p>
                    ) : null}
                </div>
                <p>{translate('ManageStock.Lable.6')}</p>
            </div>
            <Panel title={translate('ManageStock.Lable.7')}>
                <div className="chart">
                    <Line
                        data={getStockHistoryData()}
                        options={chartSetting}
                        height={100} />
                </div>
            </Panel>
        </>
    );
};
