import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { MockDrillsActions } from 'actions/actions-v2/mock-drills-actions';
import { IMockDrill } from 'models/mock-drills';

const controllerName = 'MockDrills';
const apiPath = `/api/${controllerName}`;
const redirectUrl = '/MockDrills';

const getListActions = {
    action: MockDrillsActions.LOAD_LIST,
    actionFulfilled: MockDrillsActions.LOAD_LIST_FULFILLED,
    actionRejected: MockDrillsActions.LOAD_LIST_REJECTED
} as IActionType;

const getActions = {
    action: MockDrillsActions.LOAD,
    actionFulfilled: MockDrillsActions.LOAD_FULFILLED,
    actionRejected: MockDrillsActions.LOAD_REJECTED
} as IActionType;

const createActions = {
    action: MockDrillsActions.CREATE,
    actionFulfilled: MockDrillsActions.CREATE_FULFILLED,
    actionRejected: MockDrillsActions.CREATE_REJECTED
} as IActionType;

const updateActions = {
    action: MockDrillsActions.UPDATE,
    actionFulfilled: MockDrillsActions.UPDATE_FULFILLED,
    actionRejected: MockDrillsActions.UPDATE_REJECTED
} as IActionType;

const deleteActions = {
    action: MockDrillsActions.DELETE,
    actionFulfilled: MockDrillsActions.DELETE_FULFILLED,
    actionRejected: MockDrillsActions.DELETE_REJECTED,
} as IActionType;

const exportActions = {
    action: MockDrillsActions.EXPORT,
    actionFulfilled: MockDrillsActions.EXPORT_FULFILLED,
    actionRejected: MockDrillsActions.EXPORT_REJECTED
} as IActionType;

const loadMockDrillsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getListActions).get<IMockDrill[]>(apiPath);

const loadMockDrillEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getActions).getByIdAndShard<IMockDrill>(apiPath);

const createMockDrillsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createActions, redirectUrl).post<IMockDrill[]>(apiPath);

const updateMockDrillsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateActions, redirectUrl).put<IMockDrill[]>(apiPath);

const deleteMockDrillsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, deleteActions, redirectUrl).deleteByIdAndSiteId<IMockDrill[]>(apiPath);

const exportMockDrillsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, exportActions).export('/api/MockDrillReport/export');

export const mockDrillsEpicsV2 = combineEpics(
    loadMockDrillsEpic,
    loadMockDrillEpic,
    createMockDrillsEpic,
    updateMockDrillsEpic,
    deleteMockDrillsEpic,
    exportMockDrillsEpic
);
