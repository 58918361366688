import * as React from 'react';
import { FormikProps } from 'formik';
import { TriStateToggle, Button } from 'components/v2/components';
import { ActionOrEvent } from 'models/cerm-process-step';
import { convertToBoolean } from 'routes/upsert-cerm-process-step/views/common';
import { Control, Field, Help } from '../../../../../components/form';
import { BulmaColor } from '../../../../../enums/BulmaColor';
import { BulmaSize } from '../../../../../enums/BulmaSize';
import { IOutcome, IProcessStep, IPillar } from '../../../../../reducers/cermAssessments';
import { formikFieldCssByState, getKeyed } from '../../../../../utils/form-utils';
import { EvidenceModal } from '../../evidenceUpload';
import { ActionForm } from './action-form';
import { EventForm } from './event-form';
import { ReviewOutcomeAttachmentCollection } from './reviewOutcomeAttachments';
import { ISetOutcomeAction } from 'models/set-outcome-action';
import _ from 'lodash';
import useTranslate from 'translations/translation-utils';

export interface IProps {
    formikBag: FormikProps<Partial<IProcessStep>>;
    outcomeIndex: number;
    pillars: IPillar[];
    siteId: string;
    onSaveAndNext: (outcome: Partial<IOutcome>) => void;
    onSaveAndEnd: (outcome: Partial<IOutcome>) => void;
    setOutcome: (model: ISetOutcomeAction) => void;
    onUploadClick: () => void;
    onChangeRoot: (urlLocation: string) => void;
    onSetOutcome: (outcome: Partial<IOutcome>) => void;
}

export const OutComeReviewForm: React.FC<IProps> = ({
    formikBag,
    pillars,
    siteId,
    outcomeIndex,
    onUploadClick,
    onChangeRoot,
    setOutcome,
    onSetOutcome
}) => {
    const translate = useTranslate();
    const handelOnSaveAndEnd = () => {
        if (formikBag.isValid || (!formikBag.dirty && value('masterExpectedOutcomeId'))) {
            const outcome = formikBag.values.expectedOutcomeAnswers[outcomeIndex];
            const model = getModel(outcome);
            onSetOutcome(outcome);
            if (
                formikBag.values.expectedOutcomeAnswers.every(
                    (c) => c.answer === c.compliantResponse
                )
            ) {
                model.saveAndEnd = true;
                setOutcome(model);
                onChangeRoot('/CermAssessment');
            } else {
                // Not all compliant
                setOutcome(model);
                onChangeRoot('aditionalRisk');
            }
        } else {
            formikBag.submitForm();
        }
    };
    const getModel = (outcome: Partial<IOutcome>): ISetOutcomeAction => {
        let outcomes = [] as Array<Partial<IOutcome>>;
        if (outcome.masterExpectedOutcomeId) {
            _.forEach(pillars, (pillar) => {
                _.forEach(pillar.processSteps, (step) => {
                    _.forEach(step.expectedOutcomeAnswers, (o) => {
                        if (
                            o.masterExpectedOutcomeId === outcome.masterExpectedOutcomeId &&
                            o.masterExpectedOutcomeId !== ''
                        ) {
                            outcomes = step.expectedOutcomeAnswers.map((c) =>
                                c.masterExpectedOutcomeId === outcome.masterExpectedOutcomeId
                                    ? outcome
                                    : c
                            );
                        }
                    });
                });
            });
        }
        return { outcomes, siteId };
    };

    const handleOnBack = () => {
        if (formikBag.isValid || (!formikBag.dirty && value('masterExpectedOutcomeId'))) {
            onChangeRoot(`${outcomeIndex - 1}`);
        } else {
            formikBag.submitForm();
        }
    };

    const handleOnCancel = () => {
        if (formikBag.isValid || (!formikBag.dirty && value('masterExpectedOutcomeId'))) {
            onChangeRoot(`/CermAssessment`);
        } else {
            formikBag.submitForm();
        }
    };

    const handleOnSaveAndNext = () => {
        if (formikBag.isValid || (!formikBag.dirty && value('masterExpectedOutcomeId'))) {
            const outcome = formikBag.values.expectedOutcomeAnswers[outcomeIndex];
            const model = getModel(outcome);
            onSetOutcome(outcome);
            setOutcome(model);
            onChangeRoot(`${outcomeIndex + 1}`);
        } else {
            formikBag.submitForm();
        }
    };

    const prefix = `expectedOutcomeAnswers[${outcomeIndex}]`;

    const field = (name: Extract<keyof IOutcome, string>) => `${prefix}.${name}`;

    function value<T extends Extract<keyof IOutcome, string>>(name: T) {
        const val = getKeyed(formikBag, 'values', field(name));
        return !_.isNil(val) ? val : '';
    }

    const getErrors = (name: Extract<keyof IOutcome, string>) =>
        getKeyed(formikBag, 'errors', field(name));

    const isTouched = (name: Extract<keyof IOutcome, string>) =>
        getKeyed(formikBag, 'touched', field(name));

    const getFieldCss = (name: Extract<keyof IOutcome, string>, otherCss?: string) =>
        formikFieldCssByState(Boolean(getErrors(name)), isTouched(name), otherCss);

    const getHelpByKey = (name: Extract<keyof IOutcome, string>) => (
        <Help isShown={formikBag.submitCount > 0 || isTouched(name)} bulmaColor={BulmaColor.Danger}>
            {getErrors(name)}
        </Help>
    );

    const handleTriStateChange = (answer?: boolean) => {
        formikBag.setFieldValue(field('answer'), answer);
    };

    return (
        <>
            <Field
                isHorizontal={true}
                htmlFor={field('answer')}
                label={translate('ReviewOutcomeForm.Labels.Answer')}
                labelSize={BulmaSize.Medium}
                className="is-uppercase"
            >
                <Field>
                    <Control>
                        <TriStateToggle
                            selectedValue={convertToBoolean(value('answer'))}
                            onChange={handleTriStateChange}
                            toggleFor={prefix}
                        />
                    </Control>
                    {getHelpByKey('answer')}
                </Field>
            </Field>

            <Field
                isHorizontal={true}
                htmlFor={field('clarification')}
                label={translate('ReviewOutcomeForm.Labels.Clarification')}
                labelSize={BulmaSize.Medium}
                className="is-uppercase"
            >
                <Field>
                    <Control>
                        <textarea
                            id={field('clarification')}
                            name={field('clarification')}
                            placeholder={translate('ReviewOutcomeForm.Labels.ClarificationPlaceholder')}
                            className={getFieldCss('clarification', 'textarea')}
                            value={value('clarification')}
                            onChange={formikBag.handleChange}
                            onBlur={formikBag.handleBlur}
                        />
                    </Control>
                    {getHelpByKey('clarification')}
                </Field>
            </Field>
            <Field
                isHorizontal={true}
                htmlFor="uploadButton"
                label={translate('ReviewOutcomeForm.Labels.Evidence')}
                labelSize={BulmaSize.Medium}
                className="is-centered is-uppercase"
            >
                <Field>
                    <Control>
                        <Button id="uploadButton" onClick={onUploadClick}>
                            {translate('Globals.Label.Upload')}
                        </Button>

                        <EvidenceModal
                            id={formikBag.values.id}
                            siteId={formikBag.values.shard}
                            childId={
                                formikBag.values.expectedOutcomeAnswers[outcomeIndex]
                                    .masterExpectedOutcomeId
                            }
                        />

                        <ReviewOutcomeAttachmentCollection
                            id={formikBag.values.id}
                            childId={
                                formikBag.values.expectedOutcomeAnswers[outcomeIndex]
                                    .masterExpectedOutcomeId
                            }
                        />
                    </Control>
                </Field>
            </Field>

            {!formikBag.values.expectedOutcomeAnswers[outcomeIndex].compliantResponse ===
                formikBag.values.expectedOutcomeAnswers[outcomeIndex].answer &&
                formikBag.values.expectedOutcomeAnswers[outcomeIndex].actionOrEvent ===
                ActionOrEvent.Action && <ActionForm formikBag={formikBag} />}

            {!formikBag.values.expectedOutcomeAnswers[outcomeIndex].compliantResponse ===
                formikBag.values.expectedOutcomeAnswers[outcomeIndex].answer &&
                formikBag.values.expectedOutcomeAnswers[outcomeIndex].actionOrEvent ===
                ActionOrEvent.Event && <EventForm formikBag={formikBag} />}

            <div className="action-groups">
                <div className="action-group">
                    {outcomeIndex === 0 && (
                        <Button onClick={handleOnCancel} buttonType="cancel">
                            {translate('Globals.Label.Cancel')}
                        </Button>
                    )}
                    {outcomeIndex > 0 && (
                        <Button onClick={handleOnBack} buttonType="cancel">
                            {translate('Globals.Label.Back')}
                        </Button>
                    )}
                </div>
                <div className="action-group">
                    {outcomeIndex < formikBag.values.expectedOutcomeAnswers.length - 1 && (
                        <Button onClick={handleOnSaveAndNext}>
                            {translate('Globals.Label.SaveNext')}
                        </Button>
                    )}
                    {outcomeIndex === formikBag.values.expectedOutcomeAnswers.length - 1 && (
                        <Button onClick={handelOnSaveAndEnd}>
                            {formikBag.values.expectedOutcomeAnswers.every(
                                (c) => c.answer === c.compliantResponse
                            )
                                ? translate('Globals.Label.SaveAndEnd')
                                : translate('Globals.Label.SaveNext')}
                        </Button>
                    )}
                </div>
            </div>
        </>
    );
};
