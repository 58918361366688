import { onShowUpsertRiskModal, onToggleExpanded } from 'actions/risk-actions';
import { onShowUpsertActionModal, onToggleExpanded as onToggleActions } from 'actions/action-actions';
import { loadActions, createActionForIncident } from 'actions/actions-v2/action-action-v2';
import { loadRisks, createRiskForIncident } from 'actions/actions-v2/risk-action-v2';

export default {
    loadRisks,
    loadActions,
    onShowUpsertRiskModal,
    onShowUpsertActionModal,
    onToggleRisk: onToggleExpanded,
    onToggleActions,
    createActionForIncident,
    createRiskForIncident
};
