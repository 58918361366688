import { IEmeraldTitleInput } from '../shared/title.input';

export interface IMenuListItem {
  label: string;
  icon?: string;
  iconPosition?: string;
}

export type MenuPositionType =
  | 'fixed'
  | 'absolute'
  | 'top_start'
  | 'top_end'
  | 'bottom_start'
  | 'bottom_end'
  | 'left_start'
  | 'left_end'
  | 'right_start'
  | 'right_end'
  | 'auto';

export enum EmeraldMenuPosition {
  fixed = 'fixed',
  absolute = 'absolute',
  top_start = 'top_start',
  top_end = 'top_end',
  bottom_start = 'bottom_start',
  bottom_end = 'bottom_end',
  left_start = 'left_start',
  left_end = 'left_end',
  right_start = 'right_start',
  right_end = 'right_end',
  auto = 'auto',
}

export class EmeraldMenuCoreInputs implements IEmeraldMenuCoreInputs {
  opened?: boolean = false;
  position?: MenuPositionType;
  positionX?: number | string;
  positionY?: number | string;
  title?: string;
  items?: IMenuListItem[];
  closeOnOutsideClick?: boolean = true;
}

export interface IEmeraldMenuCoreInputs extends IEmeraldTitleInput {
  opened?: boolean;
  position?: MenuPositionType;
  positionX?: number | string;
  positionY?: number | string;
  items?: IMenuListItem[];
  closeOnOutsideClick?: boolean;
}
