import React from 'react';
import { Table } from '../v2/components';
import { ICriticalSystemSparesSummary} from 'models/critical-system-spares/critical-system-spare';
import { Link } from 'react-router-dom';
import useTranslate from 'translations/translation-utils';

interface IProps {
    criticalSpares: ICriticalSystemSparesSummary[];
}

export const CriticalSystemSparesSummaryTable: React.FC<IProps> = ({ criticalSpares }) => {

    const translate = useTranslate();

    const getNumberWithFormat = (categoryName: string, fieldName: string, amount: number) => {
        if (fieldName === 'belowMin' && amount > 0) {
            return <Link to={{pathname: `/ClientCriticalSystemSpares/${categoryName}/BelowMin`}}><mark className="high">{amount}</mark></Link>;
        }
        if (fieldName === 'closeToMin' && amount > 0) {
            return <Link to={{pathname: `/ClientCriticalSystemSpares/${categoryName}/CloseToMin`}}><mark className="medium">{amount}</mark></Link>;
        }
        return <div>{amount}</div>;
    };

    return (
        <div>
                <Table>
                    <thead>
                        <tr>
                            <td><div className="unsorted-table-header">Category</div></td>
                            <td><div className="unsorted-table-header grid-column-width">{translate('CriticalSystemSparesSummaryTable.BelowMin')}</div></td> 
                            <td><div className="unsorted-table-header grid-column-width">{translate('CriticalSystemSparesSummaryTable.CloseToMin')}</div></td>
                            <td><div className="unsorted-table-header grid-column-width">{translate('CriticalSystemSparesSummaryTable.ActionReq')}</div></td>
                        </tr>
                    </thead>
                    <tbody>
                        {criticalSpares.map((item) => (
                            <tr key={item.categoryName}>
                                <td>{item.categoryName}</td>
                                <td className="data-entry">{getNumberWithFormat(item.categoryName, 'belowMin', item.numberPartsBelowMinimum)}</td>
                                <td className="data-entry">{getNumberWithFormat(item.categoryName, 'closeToMin', item.numberPartsCloseToMinimum)}</td>
                                <td className="data-entry">{getNumberWithFormat(item.categoryName, 'ok', item.numberPartsOk)}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
        </div>
    );
};
