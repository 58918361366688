import * as React from 'react';
import classNames from 'classnames';

interface IProps {
    className?: string;
}

export const Table: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    return (
        <div className="table-container">
            <table className={classNames('table', props.className)}>{props.children}</table>
        </div>
    );
};
