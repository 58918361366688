import {
  EmeraldInputCoreInputs,
  IEmeraldInputCoreInputs,
} from '../input/input.inputs';
import { IEmeraldTrailingIconInput } from '../shared/trailing-icon.input';

export interface AutoCompleteListItemObj {
  label: string;
  [key: string]: any;
}

export type AutoCompleteListItem = string | AutoCompleteListItemObj;

export abstract class EmeraldAutoCompleteCoreInputs
  extends EmeraldInputCoreInputs
  implements IEmeraldAutoCompleteCoreInputs
{
  override value?: string;
  focusChangeTimeoutMs?: number = 50;
  closeOnFocusOut?: boolean = true;
  minChar?: number = 0;
  items: AutoCompleteListItem[] = [];
  itemTemplate?: any;
  noItemsFoundText?: string = 'No items found';
}

export interface IEmeraldAutoCompleteCoreInputs
  extends IEmeraldInputCoreInputs,
    IEmeraldTrailingIconInput {
  value?: string;
  focusChangeTimeoutMs?: number;
  closeOnFocusOut?: boolean;
  minChar?: number;
  items: AutoCompleteListItem[];
  itemTemplate?: any;
  noItemsFoundText?: string;
}
