//import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createReduxHistoryContext } from "redux-first-history";
import { createMemoryHistory as createHistory, createBrowserHistory } from 'history';
import {
    Action,
    applyMiddleware,
    combineReducers,
    compose as composeProd,
    createStore as reduxCreateStore,
    DeepPartial,
    Middleware,
    ReducersMapObject
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createEpicMiddleware, Epic } from 'redux-observable';

import { appNotificationEpics } from './epics/app-notification-epics';



export function createStore<TState extends object>(
    reducers: ReducersMapObject<Partial<TState>, any>,
    epic: Epic<Action, Action, any, any>,
    initialState?: DeepPartial<TState>) {
    const { routerMiddleware, routerReducer } = createReduxHistoryContext({
        history: createBrowserHistory(),
        //other options if needed 
    });


    const epicMiddleware = createEpicMiddleware();
    const history = createHistory();

    const middlewares: Middleware[] = [
        routerMiddleware,
        epicMiddleware
    ];

    // deep freeze the state if in dev. This is an expensive operation
    // so should never be enabled for prod.
    if (process.env.NODE_ENV === 'development') {
        // tslint:disable-next-line:no-var-requires
        const freeze: Middleware = require('redux-freeze');
        middlewares.push(freeze);
    }

    const compose: (...args: any[]) => any = process.env.NODE_ENV === 'production'
        ? composeProd
        : composeWithDevTools;

    const reducer = combineReducers({
        router: routerReducer,
        // Typescript being awkward here
        ...reducers as any
    });

    const store = reduxCreateStore<TState & any, Action, {}, {}>(
        reducer,
        initialState,
        compose(applyMiddleware(...middlewares)));

    if (epic) {
        epicMiddleware.run(epic);
    }

    //// App notifications should be available on every page in the app
    epicMiddleware.run(appNotificationEpics);

    return { store, history };
}
