import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { ActionActions } from 'actions/actions-v2/action-action-v2';
import { IAction } from 'models/action';

const controllerName = 'action';
const apiPath = `/api/${controllerName}`;
const actionPageUrl = '/Action';

const createActionsForIncident = {
    action: ActionActions.CREATE_ACTION_FOR_INCIDENT,
    actionFulfilled: ActionActions.CREATE_ACTION_FULFILLED_FOR_INCIDENT,
    actionRejected: ActionActions.CREATE_ACTION_REJECTED_FOR_INCIDENT,
} as IActionType;

const createActionsForRisk = {
    action: ActionActions.CREATE_ACTION_FOR_RISK,
    actionFulfilled: ActionActions.CREATE_ACTION_FOR_RISK_FULFILLED,
    actionRejected: ActionActions.CREATE_ACTION_FOR_RISK_REJECTED,
} as IActionType;

const updateActionsForRisk = {
    action: ActionActions.UPDATE_ACTION_FOR_RISK,
    actionFulfilled: ActionActions.UPDATE_ACTION_FOR_RISK_FULFILLED,
    actionRejected: ActionActions.UPDATE_ACTION_FOR_RISK_REJECTED,
} as IActionType;

const archiveActionsForRisk = {
    action: ActionActions.ARCHIVE_ACTION_FOR_RISK,
    actionFulfilled: ActionActions.ARCHIVE_ACTION_FOR_RISK_FULFILLED,
    actionRejected: ActionActions.ARCHIVE_ACTION_FOR_RISK_REJECTED,
} as IActionType;

const createActionsForMockDrillReport = {
    action: ActionActions.CREATE_ACTION_FOR_MOCK_DRILL_REPORT,
    actionFulfilled: ActionActions.CREATE_ACTION_FOR_MOCK_DRILL_REPORT_FULFILLED,
    actionRejected: ActionActions.CREATE_ACTION_FOR_MOCK_DRILL_REPORT_REJECTED,
} as IActionType;

const updateActionsForMockDrillReport = {
    action: ActionActions.UPDATE_ACTION_FOR_MOCK_DRILL_REPORT,
    actionFulfilled: ActionActions.UPDATE_ACTION_FOR_MOCK_DRILL_REPORT_FULFILLED,
    actionRejected: ActionActions.UPDATE_ACTION_FOR_MOCK_DRILL_REPORT_REJECTED,
} as IActionType;

const archiveActionsForMockDrillReport = {
    action: ActionActions.ARCHIVE_ACTION_FOR_MOCK_DRILL_REPORT,
    actionFulfilled: ActionActions.ARCHIVE_ACTION_FOR_MOCK_DRILL_REPORT_FULFILLED,
    actionRejected: ActionActions.ARCHIVE_ACTION_FOR_MOCK_DRILL_REPORT_REJECTED,
} as IActionType;

const createActions = {
    action: ActionActions.CREATE_ACTION,
    actionFulfilled: ActionActions.CREATE_ACTION_FULFILLED,
    actionRejected: ActionActions.CREATE_ACTION_REJECTED,
} as IActionType;

const updateActions = {
    action: ActionActions.UPDATE_ACTION,
    actionFulfilled: ActionActions.UPDATE_ACTION_FULFILLED,
    actionRejected: ActionActions.UPDATE_ACTION_REJECTED,
} as IActionType;

const getAllActions = {
    action: ActionActions.LOAD_ACTIONS,
    actionFulfilled: ActionActions.LOAD_ACTIONS_FULFILLED,
    actionRejected: ActionActions.LOAD_ACTIONS_REJECTED,
} as IActionType;

const getRiskActions = {
    action: ActionActions.LOAD_RISK_ACTIONS,
    actionFulfilled: ActionActions.LOAD_RISK_ACTIONS_FULFILLED,
    actionRejected: ActionActions.LOAD_RISK_ACTIONS_REJECTED,
} as IActionType;

const getAction = {
    action: ActionActions.LOAD_ACTION,
    actionFulfilled: ActionActions.LOAD_ACTION_FULFILLED,
    actionRejected: ActionActions.LOAD_ACTION_REJECTED,
} as IActionType;

const deleteAction = {
    action: ActionActions.DELETE_ACTION,
    actionFulfilled: ActionActions.DELETE_ACTION_FULFILLED,
    actionRejected: ActionActions.DELETE_ACTION_REJECTED,
} as IActionType;

const closeAction = {
    action: ActionActions.CLOSE_ACTION,
    actionFulfilled: ActionActions.CLOSE_ACTION_FULFILLED,
    actionRejected: ActionActions.CLOSE_ACTION_REJECTED,
} as IActionType;

const loadHistory = {
    action: ActionActions.LOAD_HISTORY,
    actionFulfilled: ActionActions.LOAD_HISTORY_FULFILLED,
    actionRejected: ActionActions.LOAD_HISTORY_REJECTED,
} as IActionType;

const exportActions = {
    action: ActionActions.EXPORT_ACTIONS,
    actionFulfilled: ActionActions.EXPORT_ACTIONS_FULFILLED,
    actionRejected: ActionActions.ARCHIVE_ACTION_FOR_RISK_REJECTED,
} as IActionType;

const getAllMacroActions = {
    action: ActionActions.LOAD_MACRO_ACTIONS,
    actionFulfilled: ActionActions.LOAD_MACRO_ACTIONS_FULFILLED,
    actionRejected: ActionActions.LOAD_MACRO_ACTIONS_REJECTED,
} as IActionType;

const getMacroAction = {
    action: ActionActions.LOAD_MACRO_ACTION,
    actionFulfilled: ActionActions.LOAD_MACRO_ACTION_FULFILLED,
    actionRejected: ActionActions.LOAD_MACRO_ACTION_REJECTED,
} as IActionType;

const createMacroAction = {
    action: ActionActions.CREATE_MACRO_ACTION,
    actionFulfilled: ActionActions.CREATE_MACRO_ACTION_FULFILLED,
    actionRejected: ActionActions.CREATE_MACRO_ACTION_REJECTED,
} as IActionType;

const updateMacroAction = {
    action: ActionActions.UPDATE_MACRO_ACTION,
    actionFulfilled: ActionActions.UPDATE_MACRO_ACTION_FULFILLED,
    actionRejected: ActionActions.UPDATE_MACRO_ACTION_REJECTED,
} as IActionType;

const updateMacroActionForRisk = {
    action: ActionActions.UPDATE_MACRO_ACTION_FOR_RISK,
    actionFulfilled: ActionActions.UPDATE_MACRO_ACTION_FOR_RISK_FULFILLED,
    actionRejected: ActionActions.UPDATE_MACRO_ACTION_FOR_RISK_REJECTED,
} as IActionType;

const deleteMacroAction = {
    action: ActionActions.DELETE_MACRO_ACTION,
    actionFulfilled: ActionActions.DELETE_MACRO_ACTION_FULFILLED,
    actionRejected: ActionActions.DELETE_MACRO_ACTION_REJECTED,
} as IActionType;

const closeMacroAction = {
    action: ActionActions.CLOSE_MACRO_ACTION,
    actionFulfilled: ActionActions.CLOSE_MACRO_ACTION_FULFILLED,
    actionRejected: ActionActions.CLOSE_MACRO_ACTION_REJECTED,
} as IActionType;

const exportActionsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, exportActions).post<IAction[]>(`${apiPath}/export`);

const loadHistoryEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, loadHistory).getById<IAction>(`/api/${controllerName}/history`);

const createActionForIncidentEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createActionsForIncident).post<IAction>(`/api/${controllerName}`);

const createActionForRiskEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createActionsForRisk).post<IAction>(`/api/${controllerName}`);

const updateActionForRiskEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateActionsForRisk).put<IAction>(`/api/${controllerName}`);

const archiveActionForRiskEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, archiveActionsForRisk).deleteByIdAndShardAndParam<IAction>(
        `/api/${controllerName}`
    );

const createActionForMockDrillReportEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createActionsForMockDrillReport).post<IAction>(`/api/${controllerName}`);

const updateActionForMockDrillReportEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateActionsForMockDrillReport).put<IAction>(`/api/${controllerName}`);

const archiveActionForMockDrillReportEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, archiveActionsForMockDrillReport).deleteByIdAndShardAndParam<IAction>(
        `/api/${controllerName}`
    );

const createActionEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createActions, actionPageUrl).post<IAction>(`/api/${controllerName}`);

const updateActionEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateActions, actionPageUrl).put<IAction>(`/api/${controllerName}`);

const loadActionsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getAllActions).get<IAction[]>(`/api/${controllerName}`);

const loadRiskActionsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getRiskActions).get<IAction[]>(`/api/${controllerName}`);

const loadActionEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getAction).get<IAction>(`/api/${controllerName}/category`);

const deleteActionEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, deleteAction, actionPageUrl).deleteByIdAndShard<IAction>(
        `/api/${controllerName}`
    );

const closeActionEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, closeAction, actionPageUrl).put<IAction>(`/api/${controllerName}/close`);

const loadMacroActionsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getAllMacroActions).get<IAction[]>(`/api/${controllerName}/macro`);
const loadMacroActionEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getMacroAction).getByIdAndShard<IAction>(`/api/${controllerName}/macro`);
const createMacroActionEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createMacroAction).post<IAction>(
        `/api/${controllerName}/macro`
    );
const updateMacroActionEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateMacroAction, actionPageUrl).put<IAction>(
        `/api/${controllerName}/macro`
    );
const updateMacroActionForRiskEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateMacroActionForRisk).put<IAction>(
        `/api/${controllerName}/macro`
    );
const deleteMacroActionEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, deleteMacroAction).deleteByIdAndShard<IAction>(
        `/api/${controllerName}/macro`
    );
const closeMacroActionEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, closeMacroAction, actionPageUrl).put<IAction>(
        `/api/${controllerName}/macro/close`
    );

export const actionEpicsV2 = combineEpics(
    exportActionsEpic,
    loadHistoryEpic,
    createActionForIncidentEpic,
    createActionForRiskEpic,
    updateActionForRiskEpic,
    archiveActionForRiskEpic,
    createActionForMockDrillReportEpic,
    updateActionForMockDrillReportEpic,
    archiveActionForMockDrillReportEpic,
    createActionEpic,
    updateActionEpic,
    loadActionsEpic,
    loadActionEpic,
    deleteActionEpic,
    loadRiskActionsEpic,
    closeActionEpic,
    loadMacroActionEpic,
    loadMacroActionsEpic,
    createMacroActionEpic,
    updateMacroActionEpic,
    updateMacroActionForRiskEpic,
    deleteMacroActionEpic,
    closeMacroActionEpic,
);
