import { IRootState } from './../../../../routes/store';
import { createSelector } from 'reselect';
import { getPermissions } from 'sharedSelectors';

const getEngineer = (state: IRootState) => state.engineerState.engineer;
const getIsLoading = (state: IRootState) => state.engineerState.isLoading;
const getSkillCategories = (state: IRootState) =>
    state.engineersState.skillCategories.filter((c) => c.isActive);
const getExpandedCategories = (state: IRootState) => state.engineerState.expandedCategories;

export default createSelector(
    getEngineer,
    getExpandedCategories,
    getIsLoading,
    getSkillCategories,
    getPermissions,
    (engineer, expandedCategories, isLoading, skillCategories, permissions) => ({
        engineer,
        expandedCategories,
        isLoading,
        skillCategories,
        permissions,
    })
);
