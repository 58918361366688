import { combineReducers } from 'redux';
import { RiskActions, RiskActionTypes } from '../../actions/risk-actions';
import { IUpsertRiskRequest } from '../../models/upsert-risk-request';
import { RiskActions as RiskActionsV2 } from '../../actions/actions-v2/risk-action-v2';
import { IAppAction } from 'actions/app-action';

export interface IRiskFormState {
    showUpsertRiskModal: boolean;
    upsertItem: IUpsertRiskRequest;
}

export const INITIAL_STATE: IRiskFormState = {
    showUpsertRiskModal: false,
    upsertItem: null,
};

const showUpsertRiskModal = (
    state: IRiskFormState['showUpsertRiskModal'] = INITIAL_STATE.showUpsertRiskModal,
    action: IAppAction
) => {
    switch (action.type) {
        case RiskActions.SHOW_UPSERT_RISK_MODAL:
            return true;
        case RiskActionsV2.CREATE_RISK_FULFILLED:
        case RiskActionsV2.CREATE_RISK_REJECTED:
        case RiskActionsV2.UPDATE_RISK_FULFILLED:
        case RiskActionsV2.UPDATE_RISK_REJECTED:
        case RiskActionsV2.CREATE_RISK_FOR_INCIDENT_FULFILLED:
        case RiskActionsV2.CREATE_RISK_FOR_INCIDENT_REJECTED:
        case RiskActions.HIDE_UPSERT_RISK_MODAL:
            return false;

        default:
            return state;
    }
};

const upsertItem = (
    state: IUpsertRiskRequest = INITIAL_STATE.upsertItem,
    action: RiskActionTypes) => {
    switch (action.type) {
        case RiskActions.HIDE_UPSERT_RISK_MODAL:
            return null;
        case RiskActions.SHOW_UPSERT_RISK_MODAL:
            return action.payload.item;
        default:
            return state;
    }
};

export const reducer = combineReducers<IRiskFormState>({
    showUpsertRiskModal,
    upsertItem,
});
