import { createSelector } from 'reselect';
import { IRootManageRoleState } from 'reducers/manageRoles';
import { getFilterKeywords, getManageRolesFilterRoles } from '../selectors';

const getRoleState = createSelector(
    (state: IRootManageRoleState) => state.manageRoles.grid.rolesState.items,
    (state) => state
);

const getSortColumn = createSelector(
    (state: IRootManageRoleState) => state.manageRoles.grid.rolesState.sortColumn,
    (state) => state
);

const getSortAscending = createSelector(
    (state: IRootManageRoleState) => state.manageRoles.grid.rolesState.sortAscending,
    (state) => state
);

const getIsLoading = createSelector(
    (state: IRootManageRoleState) => state.manageRoles.grid.rolesState.isLoading,
    (state) => state
);

export default createSelector(
    getRoleState,
    getSortColumn,
    getSortAscending,
    getIsLoading,
    getManageRolesFilterRoles,
    getFilterKeywords,
    (rolesState, sortColumn, sortAscending, isLoading, manageRolesFilterRoles, filterKeywords) => ({
        rolesState,
        sortColumn,
        sortAscending,
        isLoading,
        manageRolesFilterRoles,
        filterKeywords,
    })
);
