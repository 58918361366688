import { IKeyValue } from './key-value';

export enum RiskMacroLevel {
    Division = 'Division',
    BusinessUnit = 'Business Unit',
    Region = 'Region',
    Country = 'Country',
    Account = 'Account',
    Site = 'Site',
}

export const RiskMacroLevelKvp: Array<IKeyValue<string>> = [
    { key: RiskMacroLevel.Division, value: RiskMacroLevel.Division, label: 'Division/Sector' },
    { key: RiskMacroLevel.BusinessUnit, value: RiskMacroLevel.BusinessUnit, label: RiskMacroLevel.BusinessUnit },
    { key: RiskMacroLevel.Region, value: RiskMacroLevel.Region, label: RiskMacroLevel.Region },
    { key: RiskMacroLevel.Country, value: RiskMacroLevel.Country, label: RiskMacroLevel.Country },
    { key: RiskMacroLevel.Account, value: RiskMacroLevel.Account, label: RiskMacroLevel.Account },
    { key: RiskMacroLevel.Site, value: RiskMacroLevel.Site, label: RiskMacroLevel.Site },
];