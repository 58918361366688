import React from 'react';
import { SearchFilter } from '../searchFilter';
import { IKeyValue } from 'models';
import Select from 'react-select';
import { CheckboxFilter } from '../checkboxFilter';
import { FilterSet } from '../filterSet';
import { ICermGlobalOverviewFilters } from 'models/cerm-global-overview';
import useTranslate from 'translations/translation-utils';
import { IOperatingPlatform } from 'models/operating-platform-model';
import { ICommonLookupModel } from 'models/common-lookup-model';

interface IProps {
    filters: ICermGlobalOverviewFilters;
    onAddFilterKeyword: (keyword: string) => void;
    onRemoveFilterKeyword: (keyword: string) => void;

    // checkboxes
    onCheckBoxFilter: (key: string, element: string) => void;
    selectedLinesOfBusiness: string[];
    selectedRegions: string[];

    // dropdown
    clients: Array<IKeyValue<string>>;
    onSelectClient: (value: any) => void;
    linesOfBusiness: ICommonLookupModel[];
    regions: Array<IKeyValue<string>>;
    operatingPlatforms: IOperatingPlatform[];
    selectedOperatingPlatforms: string[];
}

export const CermGlobalOverviewFilters: React.FC<IProps> = props => {
    const commonProps = {
        expandedFilters: ['operatingPlatform', 'lineOfBusiness', 'region'],
        onFilter: props.onCheckBoxFilter,
        onToggleFilterExpanded: null,
    };

    const linesOfBusiness =
        props.linesOfBusiness &&
        props.linesOfBusiness.map(x => {
            return {
                key: `${x.operatingPlatform}:${x.key}`,
                value: `${x.operatingPlatform}: ${x.value}`,
            };
        });
    const translate = useTranslate();
    return (
        <>
            <SearchFilter
                title={translate('RiskRegisterPage.Title.Filters')}
                name="filterKeywords"
                values={props.filters.filterKeywords}
                onAddFilterKeyword={props.onAddFilterKeyword}
                onRemoveFilterKeyword={props.onRemoveFilterKeyword}
            />           
            <CheckboxFilter
                {...commonProps}
                title={translate('Cerm.GlobalOverviewFilters.Labels.LineOfBusiness')}
                name="lineOfBusiness"
                selectedFilters={props.selectedLinesOfBusiness}
                unreservedFilters={linesOfBusiness}
            />           
            <FilterSet title={translate('Globals.Category.dropdown.Clients')} fieldName="client">
                <div className="filter-fields">
                    <div className="field">
                        <Select
                            isMulti={false}
                            classNamePrefix="dropdown-multi"
                            placeholder={translate('CermGlobalOverview.Filter.Message')}
                            value={props.filters.selectedClient}
                            onChange={e => props.onSelectClient(e)}
                            options={props.clients.map(x => ({
                                value: x.key,
                                label: x.value,
                            }))}
                        />
                    </div>
                </div>
            </FilterSet>
        </>
    );
};
