import * as React from 'react';
import useTranslate from 'translations/translation-utils';


interface IProps {
    colSpan?: number;
    noItemLabel?: string;
}

export const TableNoItem: React.FC<IProps> = ({ colSpan, noItemLabel }) => {
    const translate = useTranslate();
    return (
        <tr>
            <td className="no-record" {...(colSpan && { colSpan })}>
                {noItemLabel ? noItemLabel : translate('RiskRegisterPage.RiskRegisterTable.Title.NoItem')}
            </td>
        </tr>
    );
};
