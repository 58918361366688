import { Formik, FormikProps } from 'formik';
import * as React from 'react';
import { object } from 'yup';

import { IKeyValue } from '../../../models/key-value';
import { Modal } from '../../modal';
import { Button } from '../../v2/components';
import { Loader } from '../../loader';
import useTranslate from 'translations/translation-utils';
import { ISetProcessStepResult } from 'models/cerm/cerm-assessment-result';
import { Checkbox } from 'components/form/Checkbox';

export interface IProps {
    isShown: boolean;
    isLoading?: boolean;
    siteId: string;
    lookupSites: Array<IKeyValue<string>>;
    setProcessStepResult?: Partial<ISetProcessStepResult>;
    disableClosedAndCompleted?: boolean;
    canEditInExpertReview?: () => boolean;
}

export interface ISiteSelectProps {
    onClose: () => void;
    onSubmit: (sites: string[]) => void;
}

export const SiteSelectModal: React.FC<React.PropsWithChildren<IProps & ISiteSelectProps>> = ({ isLoading, ...props }) => {
    const [selectedSites, setSelectedSites] = React.useState<string[]>([])
    const translate = useTranslate();
    const onClose: React.MouseEventHandler<HTMLButtonElement> = () => {
        setSelectedSites([props.siteId])
        props.onClose();
    };

    const onSubmit = () => {
        props.onSubmit(selectedSites);
    };

    const SetProcessStepResultSchema = object().shape({
    });


    const isSiteSelected = (siteId) => {
        let ndx = selectedSites?.findIndex(x => x == siteId)
        if (siteId == props.siteId && ndx == -1) {
            let sites = [...selectedSites]
            sites.push(siteId)
            setSelectedSites(sites)
            return true
        }
        return ndx > -1
    }

    const addRemoveSiteSelected = (siteId) => {
        if (siteId == props.siteId) {
            return
        }
        let sites = selectedSites?.filter(x => x != siteId)
        if (sites.length == selectedSites.length) {
            sites.push(siteId)
        }
        setSelectedSites(sites)
    }

    const addRemoveAllSiteSelected = () => {
        if (props.lookupSites.length == selectedSites.length) {
            setSelectedSites([props.siteId])
        }
        else {
            let sites = props.lookupSites.map(x => {
                return x.key;
            })
            setSelectedSites(sites)
        }
    }

    const renderForm = (formikBag: FormikProps<ISetProcessStepResult>) => {
        if (!formikBag.values && formikBag.initialValues.siteIds?.length > 0) {
            const s = props.lookupSites.find((x) => x.key === formikBag.initialValues.siteId[0]);
            if (s) {
                formikBag.setFieldValue('siteIds', [s.value]);
            }
        }

        return (
            <Loader loading={isLoading}>
                <form onSubmit={formikBag.handleSubmit}>
                    <div className="content">
                        <div className="columns">
                            <div className="column is-11 label">
                                {translate('Cerm.SiteSelect.Cloumns.SiteName')}
                            </div>
                            <div className="column is-1 label">
                                {translate('Cerm.SiteSelect.Cloumns.Include')}
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-11">
                            </div>
                            <div className="column is-1">
                                <Checkbox
                                    id={"includeAll"}
                                    checked={props.lookupSites.length == selectedSites.length}
                                    onChange={() => { addRemoveAllSiteSelected() }}
                                />
                            </div>
                        </div>
                        <div className="sites-container-rows">
                            {props.lookupSites && props.lookupSites.map((site, key) => {
                                return (
                                    <div className="columns" key={"columns_" + site.key}>
                                        <div
                                            key={"siteId_value_" + site.key}
                                            className="column is-11"
                                        >
                                            {site.value}
                                        </div>
                                        <div className="column is-1" key={`individual_checkbox_${key}`}>
                                            <Checkbox
                                                id={`${site.key}_${key.toString()}`}
                                                key={key}
                                                checked={isSiteSelected(site.key)}
                                                onChange={() => { addRemoveSiteSelected(site.key) }}
                                            />
                                        </div>
                                    </div>

                                );
                            })}
                        </div>
                    </div>
                    <br />
                    <div className="columns">
                        <div className="column is-1">
                            <p className="label">NOTE -</p>
                        </div>
                        <div className="column">
                            <p>{translate('Cerm.SiteSelect.Messages.Message1')}</p>
                            <p>{translate('Cerm.SiteSelect.Messages.Message2')}</p>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-6">
                            <Button
                                id="btnCancelAction"
                                buttonType="cancel"
                                onClick={onClose}
                                isLoading={isLoading}
                            >
                                {translate('RiskActionTab.Button.Cancel')}
                            </Button>
                        </div>

                        <div className="column is-6">
                            {(props.canEditInExpertReview == null ||
                                (props.canEditInExpertReview && props.canEditInExpertReview())) && (
                                    <>
                                        <Button
                                            id="btnSaveAction"
                                            type="submit"
                                            isLoading={isLoading}
                                            disabled={
                                                !(selectedSites?.length > 0)
                                            }
                                        >
                                            {translate('RiskActionTab.Button.Save')}
                                        </Button>
                                        <br />
                                        <br />
                                    </>
                                )}
                        </div>
                    </div>
                </form>
            </Loader>
        );
    };

    return (
        <Modal modalId="siteSelectModal" isShown={props.isShown}>
            <Formik<Partial<ISetProcessStepResult>>
                initialValues={props.setProcessStepResult}
                validationSchema={SetProcessStepResultSchema}
                onSubmit={onSubmit}
                render={renderForm}
            />
        </Modal>
    )
};

SiteSelectModal.defaultProps = {
    setProcessStepResult: {
        siteId: '',
        siteIds: [],
        applicableToAccount: true
    }
};
