/* eslint-disable @typescript-eslint/no-inferrable-types */
import { IEmeraldTitleInput } from '../shared/title.input';
import {
  EmeraldSidesheetType,
  EmeraldSidesheetTypeType,
  EmeraldSidesheetWidth,
  EmeraldSidesheetWidthType,
} from './sidesheet.type';

export class EmeraldSidesheetCoreInputs implements IEmeraldSidesheetCoreInputs {
  type: EmeraldSidesheetTypeType = EmeraldSidesheetType.Modal;
  width: EmeraldSidesheetWidthType = EmeraldSidesheetWidth.Small;
  fixed: boolean = false;
  short?: boolean;
  dense?: boolean;
  prominent?: boolean;
  hasToolbarAttached?: boolean = false;
  title?: string;
  subTitle?: string;
}

export interface IEmeraldSidesheetCoreInputs extends IEmeraldTitleInput {
  type: EmeraldSidesheetTypeType;
  width: EmeraldSidesheetWidthType;
  fixed: boolean;
  short?: boolean;
  dense?: boolean;
  prominent?: boolean;
  hasToolbarAttached?: boolean;
  subTitle?: string;
}
