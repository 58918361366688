import { IKeyValue } from 'models';

export interface ILogUploadModel {
    siteId: string;
    logType: LogType;
    templateType: TemplateType;
    collection: File[];
}

export enum LogType {
    PleaseSelect = '',
    Risk = 'Risk',
    User = 'User',
    CriticalSpares = 'Critical Spares',
    Site = 'Site',
    Facility = 'Facility',
    ExpertReview = 'Expert Review',
    Incident = "Incident",
}

export enum TemplateType {
    PleaseSelect = '',
    CermView = 'CermView',
    Quantum = 'Quantum',
}

export const getLogTypes: Array<IKeyValue<string>> = [
    {
        key: LogType.PleaseSelect,
        label: 'LogsUpload.LogType.PleaseSelect',
        value: LogType.PleaseSelect,
    },
    {
        key: LogType.Site,
        label: 'LogsUpload.LogType.Site',
        value: LogType.Site,
    },
    {
        key: LogType.Facility,
        label: 'LogsUpload.LogType.Facility',
        value: LogType.Facility,
    },
    {
        key: LogType.Risk,
        label: 'LogsUpload.LogType.Risk',
        value: LogType.Risk,
    },
    {
        key: LogType.CriticalSpares,
        label: 'LogsUpload.LogType.CriticalSpares',
        value: LogType.CriticalSpares,
    },
    {
        key: LogType.User,
        label: 'LogsUpload.LogType.User',
        value: LogType.User,
    },
    {
        key: LogType.Incident,
        label: 'LogsUpload.LogType.Incident',
        value: LogType.Incident,
    },
    // TODO: Performance issue when uploading with more rows, so hiding for the current 6.11.0 release

    // {
    //     key: LogType.ExpertReview,
    //     label: 'LogsUpload.LogType.ExpertReview',
    //     value: LogType.ExpertReview,
    // },
];

export const getTemplateTypes: Array<IKeyValue<string>> = [
    {
        key: TemplateType.PleaseSelect,
        label: 'LogsUpload.TemplateType.PleaseSelect',
        value: TemplateType.PleaseSelect,
    },
    {
        key: TemplateType.Quantum,
        label: 'LogsUpload.TemplateType.Quantum',
        value: TemplateType.Quantum,
    },
];

export interface ITemplateValidationSummary {
    errorCount: number;
    invalidRowCount: number;
    totalRowCount: number;
    validationResults: string[];
}
