import { IEmeraldIdInput } from '../../shared/id.input';
import { IEmeraldSelectedInput } from '../../shared/selected.input';
import { IEmeraldTitleInput } from '../../shared/title.input';
import { EmeraldChipVariantType } from '../chip.variant';
import { EmeraldChipSizeType } from '../chip.size';
import { IEmeraldLabelInput } from '../../shared/label.input';
import { IEmeraldDisabledInput } from '../../shared/disabled.input';
import { IEmeraldValueInput } from '../../shared/value.input';

export class EmeraldChipCoreInputs<T> implements IEmeraldChipCoreInputs<T> {
  id?: string = `Chip${Math.random().toString()}`;
  label?: string;
  leadingIcon?: string;
  avatar?: any;
  // onClick?: (e: any) => void;
  // onDelete?: (e: any) => void;
  disabled?: boolean;
  size?: EmeraldChipSizeType;
  selected?: boolean;
  selectable?: boolean;
  value?: T;
  variant?: EmeraldChipVariantType;
  title?: string;
  trailingIcon?: string;
  selectedIcon?: string;
  cssClasses?: string;
}

export interface IEmeraldChipCoreInputs<T>
  extends IEmeraldIdInput,
    IEmeraldLabelInput,
    IEmeraldDisabledInput,
    IEmeraldValueInput<T>,
    IEmeraldSelectedInput,
    IEmeraldTitleInput {
  leadingIcon?: string;
  avatar?: any;
  trailingIcon?: string;
  // onClick?: (e: any) => void;
  // onDelete?: (e: any) => void;
  size?: EmeraldChipSizeType;
  selectable?: boolean;
  variant?: EmeraldChipVariantType;
  selectedIcon?: string;
}
