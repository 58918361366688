import { uniqueId } from 'lodash';
import { IPerson } from 'models/person';
import * as React from 'react';
import './Nav.scss';
import { NavItem } from './NavItem';
import { NavLink as QuantumNavLink } from './NavLink';
import _ from 'lodash';
import { Loader } from 'components/loader';
import useTranslate from 'translations/translation-utils';
import { SideNavDropdown } from '../../models/site-profile';

export interface IDropdownItem {
    text: string;
    link: string;
    isVisible?: boolean;
    isHref?: boolean;
    isCentre?: boolean;
    id?: string;
}

export interface IStateProps extends React.AllHTMLAttributes<HTMLDivElement> {
    linkElement: any;
    isSelected?: boolean;
    isExpanded: boolean;
    dropdownItems: IDropdownItem[];
    person?: IPerson;
    siteId?: string;
    isLoadingSite?: boolean;
    loadPersonProfile?: (id: string) => void;
    showRoleSelectorDialog?: (show: boolean) => void;
    showOperatingPlatformSelectorDialog?: (show: boolean) => void;
}

export const NavDropdown: React.FC<IStateProps> = (props) => {
    const translate = useTranslate();
    const [dropdownItem, setDropdownItem] = React.useState([]);
    const onChangeRole = () => {
        props.showRoleSelectorDialog(true);
    };

    const onChangeOperatingPlatform = () => {
        props.showOperatingPlatformSelectorDialog(true);
    };


    const cdnaItem = {
        id: '2',
        text: 'NavDropDown.ViewMyCDNA',
        link: `/Engineer/profile/${props.person.selectedSiteEngineerId}/details/`
    };
    const roleItem = {
        id: '3',
        text: 'NavDropDown.ChangeRole',
        subtext: props.person.preferredRole ?? translate('NavDropDown.NotSelected'),
        link: ``,
        onLinkClick: onChangeRole,
    };

    const operatingPlatformItem = {
        id: SideNavDropdown.SelectPreferredOperatingPlatform,
        text: 'NavDropDown.SelectOperatingPlatform',
        link: ``,
        onLinkClick: onChangeOperatingPlatform,
    };

    React.useEffect(() => {
        let dropdown = _.cloneDeep(dropdownItem);

        if (!props.person.selectedSiteEngineerId) {
            dropdown = dropdown.filter((dropdown) => dropdown.id !== '2');
        } else {
            if (!dropdown.some((item) => item.id === '2')) {
                dropdown.splice(1, 0, cdnaItem);
            }
        }

        if (props.person?.roles.length > 1 && !dropdown.some((item) => item.id === '3')) {
            dropdown.splice(2, 0, roleItem);
        }

        if (props.person?.accessRights.operatingPlatforms.length > 1 && !dropdown.some((item) => item.id === SideNavDropdown.SelectPreferredOperatingPlatform)) {
            if (!dropdown.some((item) => item.id === '3'))
                dropdown.splice(2, 0, operatingPlatformItem);
            else
                dropdown.splice(3, 0, operatingPlatformItem);
        }

        setDropdownItem(dropdown);
    }, [props.person]);

    React.useEffect(() => {
        setDropdownItem(props.dropdownItems);
    }, [props.dropdownItems]);

    return (
        <NavItem
            hasContainer={true}
            isSelected={props.isSelected}
            hasDropdown={true}
            className={props.className}
            link={'/'}
        >
            <QuantumNavLink linkElement={props.linkElement} />
            <Loader loading={props.isLoadingSite}>
                <div className="navbar-dropdown">
                    {dropdownItem && dropdownItem.filter((dd) => dd && dd.isVisible !== false).map((dropdownItem) => {
                        return (
                            <div key={uniqueId(dropdownItem.text)}>
                                <NavItem
                                    text={translate(dropdownItem.text)}
                                    subtext={dropdownItem?.subtext}
                                    link={dropdownItem.link}
                                    isHref={dropdownItem.isHref}
                                    isCentre={dropdownItem.isCentre}
                                    navState={{ fromProfile: true }}
                                    onLinkClick={dropdownItem.onLinkClick}
                                />
                                <hr className="navbar-divider" />
                            </div>
                        );
                    })}
                </div>
            </Loader>
        </NavItem>
    );
};
