import * as React from 'react';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconSize, MaterialIconType } from 'routes/material-icon/material-icon-type';

interface IProps {
    className?: string
    tooltipTitle?: string;
    onClick?: () => void;
}

export const HrTag: React.FC<IProps> = (props) => (
    <div>
        <span data-tip={props.tooltipTitle} className="hr-icon" onClick={props.onClick}>  < MaterialIcons type={MaterialIconType.Add} size={MaterialIconSize.medium} /> </span>
        <hr />
    </div>
);
