import * as React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { Tab, Tabs } from 'components/tabs';
import { BulmaSize } from 'enums/BulmaSize';
import { IRole } from 'reducers/manageRoles/manageRoles-grid';
import { checkPermissions } from 'utils/permission-utils';
import { CheckboxFilter } from '../../../components/checkboxFilter';
import { SearchFilter } from '../../../components/searchFilter';
import { IKeyValue } from '../../../models/key-value';
import { RouteUrl } from '../routes';
import { PersonUpsertPage } from './add-edit-person-page';
import { AddEditRoleModal } from './add-edit-role-modal';
import { ManagePrivilegeTab } from './manage-privileges-tab';
import { ManageRolesTab } from './manage-roles-tab';
import { ManageRoleGridToolbar } from './manageRoleGridToolbar/ManageRoleGridToolbar';
import { IManageRoleListDispatchProps, ManageRoleList } from './manageRoleList';
import { IPerson } from 'models/person';
import { OverlayPanel } from 'components/v2/components';
import Page from 'components/v2/page/Page';
import { isEmpty } from 'lodash';
import { checkFilterExistsWithExceptions } from 'utils/array-utils';
import { Privilege } from 'enums/Privilege';
import useTranslate from 'translations/translation-utils';
import { IOperatingPlatform } from 'models/operating-platform-model';

export interface IRouterPersonId {
    personId?: string;
}

export interface IManageRoleListStateProps extends RouteComponentProps {
    items: IPerson[];
    isLoading: boolean;
    expandedItems: string[];
    lookupRoles: Array<IKeyValue<string>>;
    expandedFilters: string[];
    manageRolesFilterRoles: string[];
    permissions: Array<IKeyValue<string>>;
    isInitialising: boolean;
    isExporting: boolean;
    responseContinuation: string;
    operatingPlatforms: IOperatingPlatform[];
}

export interface IActionProps {
    onNavigateRolesTab: () => void;
    onNavigatePeopleTab: (filters?: IManageRolesFilters) => void;
    onNavigatePrivilegesTab: () => void;
}

interface IProps extends IManageRoleListStateProps, IActionProps {}

export interface IDispatchProps extends IManageRoleListDispatchProps {
    onRouteChange: (url: string) => void;
    onListSort: (sortColumn: string, sortAscending: boolean) => void;
    onShowRoleModal: (role?: IRole) => void;
    onDeleteRole: (roleId: string) => void;
    onEditRolePrivileges: (roleId: string) => void;
    onToggleExpanded: (id: string) => void;
    onRoleChange: (value: any, action: any) => void;
    onToggleFilterExpanded: (id: string) => void;
    onClearFilters: () => void;
    loadPersons: (filters?: IManageRolesFilters) => void;
    onExport: () => void;
}

export interface IManageRolesFilters {
    loadInActive: string | string[];
    roles: string[];
    filterKeywords: string[];
    responseContinuation: string | string[];
    operatingPlatform: string[];
}

export const ManageRolesPage: React.FC<IProps & IDispatchProps> = props => {
    const translate = useTranslate();
    const [sortColumn, setSortColumn] = React.useState<string>('');
    const [sortAscending, setSortAscending] = React.useState<boolean>(false);
    const [showFilters, setShowFilters] = React.useState<boolean>(false);
    const [selectedStatus, setSelectedStatus] = React.useState<string[]>([]);
    const [filters, setFilters] = React.useState<IManageRolesFilters>({
        loadInActive: [],
        roles: [],
        filterKeywords: [],
        responseContinuation: null,
        operatingPlatform: [],
    });

    React.useEffect(() => {
        switch (props.location.pathname) {
            case RouteUrl.Roles:
                props.onNavigateRolesTab();
                break;

            case RouteUrl.Privileges:
                props.onNavigatePrivilegesTab();
                break;

            default:
                navigateToPeopleTabHandler();
        }
    }, []);

    const loadMorePersons = () => {
        const filter = {
            ...filters,
            responseContinuation: props.responseContinuation,
        };
        props.loadPersons(filter);
    };

    const onClearFilter = () => {
        setFilters({
            loadInActive: [],
            roles: [],
            filterKeywords: [],
            responseContinuation: null,
            operatingPlatform: [],
        });
        setSelectedStatus([]);
        props.loadPersons();
    };

    const onSort = (key: string, _: boolean) => {
        const sortDirection = sortAscending ? false : true;
        props.onListSort(key, sortDirection);
        setSortAscending(sortDirection);
        setSortColumn(key);
    };

    const onAddFilterKeyword = (keyword: string) => {
        const updatedFilters = { ...filters } as IManageRolesFilters;
        const keywords = [
            keyword,
            ...updatedFilters.filterKeywords.slice(0, updatedFilters.filterKeywords.length),
        ];
        updatedFilters.filterKeywords = keywords;
        setFilters(updatedFilters);
        props.loadPersons(updatedFilters);
    };

    const onRemoveFilterKeyword = (keyword: string) => {
        const updatedFilters = { ...filters };
        const index = updatedFilters.filterKeywords.indexOf(keyword);
        const keywords = [
            ...updatedFilters.filterKeywords.slice(0, index),
            ...updatedFilters.filterKeywords.slice(index + 1),
        ];
        updatedFilters.filterKeywords = keywords;
        setFilters(updatedFilters);
        props.loadPersons(updatedFilters);
    };

    const onFilter = (key: Extract<keyof IManageRolesFilters, string>, element: string) => {
        const updatedFilters = { ...filters };

        if (element === 'loadInActive'.toString()) {
            const loadInActiveSelected = selectedStatus.length > 0;
            updatedFilters.loadInActive = loadInActiveSelected ? null : 'true';
            setSelectedStatus(loadInActiveSelected ? [] : [element]);
        } else {
            const index = updatedFilters[key].indexOf(element);
            if (index !== -1) {
                const items = [
                    ...updatedFilters[key].slice(0, index),
                    ...updatedFilters[key].slice(index + 1),
                ];
                updatedFilters[key] = items;
            } else {
                const items = [
                    element,
                    ...updatedFilters[key].slice(0, updatedFilters[key].length),
                ];
                updatedFilters[key] = items;
            }
        }

        setFilters(updatedFilters);
        props.loadPersons(updatedFilters);
    };

    const navigateToPeopleTabHandler = () => {
        props.loadPersons(filters);
        props.onNavigatePeopleTab();
    };

    const isFilterExists = checkFilterExistsWithExceptions(filters, ['responseContinuation']);
    const commonProps = {
        expandedFilters: props.expandedFilters,
        onFilter: onFilter,
        onToggleFilterExpanded: props.onToggleFilterExpanded,
    };

    const statusCheckbox: Array<IKeyValue<string>> = [
        { key: 'loadInActive', value: translate('UserPage.Filter.Label.IncludeInactiveUsers') },
    ];

    return (
        <>
            <Switch>
                <Route exact={false} path={`${RouteUrl.PersonUpsert}/:personId?`}>
                    <PersonUpsertPage />
                </Route>
                <Route exact={false} path={RouteUrl.People}>
                    <Page title={translate('Roles.Title.1')} isAdminPage={true}>
                        <ManageRoleGridToolbar
                            isExporting={props.isExporting}
                            onExport={props.onExport}
                            onShowFilters={() => setShowFilters(true)}
                            onClearFilters={onClearFilter}
                            path={props.location.pathname}
                            onShowRoleModal={props.onShowRoleModal}
                            isFilterExists={isFilterExists}
                        />
                        <OverlayPanel
                            title={translate('RiskRegisterPage.Title.Filters')}
                            isVisible={showFilters}
                            onClose={() => setShowFilters(false)}
                            onOutsideDialogClick={() => setShowFilters(false)}
                            onClearFilters={onClearFilter}
                            isFilterExists={isFilterExists}
                        >
                            <SearchFilter
                                title={translate('RiskRegisterPage.Title.Filters')}
                                name="filterKeywords"
                                values={filters.filterKeywords}
                                onAddFilterKeyword={onAddFilterKeyword}
                                onRemoveFilterKeyword={onRemoveFilterKeyword}
                            />
                            <CheckboxFilter
                                {...commonProps}
                                onFilter={onFilter}
                                title={translate('Globals.Category.dropdown.OP')}
                                name="operatingPlatform"
                                selectedFilters={filters.operatingPlatform}
                                unreservedFilters={props.operatingPlatforms}
                            />
                            <CheckboxFilter
                                {...commonProps}
                                onFilter={onFilter}
                                title={translate('Roles.Role')}
                                name="roles"
                                selectedFilters={filters.roles}
                                unreservedFilters={props.lookupRoles}
                            />
                            <CheckboxFilter
                                {...commonProps}
                                onFilter={onFilter}
                                title={translate('UserPage.Filter.Label.Status')}
                                name="loadInActive"
                                selectedFilters={selectedStatus}
                                unreservedFilters={statusCheckbox}
                            />
                        </OverlayPanel>
                        <Tabs isToggle={true} bulmaSize={BulmaSize.Medium} isFullwidth={true}>
                            <Tab
                                text={translate('Globals.Category.dropdown.People')}
                                active={props.location.pathname === RouteUrl.People}
                                onClick={navigateToPeopleTabHandler}
                            />
                            {checkPermissions(
                                [Privilege.ManageRoles, Privilege.UserManagementRoleView],
                                props.permissions
                            ) && (
                                <Tab
                                    text={translate('GainAccess.Labels.Roles')}
                                    active={props.location.pathname === RouteUrl.Roles}
                                    onClick={() => props.history.push(RouteUrl.Roles)}
                                />
                            )}
                            {checkPermissions(
                                [Privilege.ManageRoles, Privilege.UserManagementPrivilegeView],
                                props.permissions
                            ) && (
                                <Tab
                                    text={translate('Roles.Privileges')}
                                    active={props.location.pathname === RouteUrl.Privileges}
                                    onClick={() => props.history.push(RouteUrl.Privileges)}
                                />
                            )}
                        </Tabs>
                        <div className="tabs-container tab-content">
                            <Switch>
                                <Route exact={true} path={RouteUrl.People}>
                                    <ManageRoleList
                                        {...props}
                                        onSort={onSort}
                                        sortColumn={sortColumn}
                                        sortAscending={sortAscending}
                                        hasMoreItems={!isEmpty(props.responseContinuation)}
                                        loadMorePersons={loadMorePersons}
                                        permissions={props.permissions}
                                        isLoading={props.isLoading}
                                    />
                                </Route>
                                <Route exact={true} path={RouteUrl.Roles}>
                                    <ManageRolesTab permissions={props.permissions} />
                                </Route>
                                <Route exact={true} path={RouteUrl.Privileges}>
                                    <ManagePrivilegeTab permissions={props.permissions} />
                                </Route>
                            </Switch>
                        </div>
                    </Page>
                </Route>
            </Switch>
            <AddEditRoleModal />
        </>
    );
};
