import * as React from 'react';
import { Panel, Table, ToggleButton } from '../v2/components';
import { RiskDetailsFormContainer } from 'routes/risk-register/upsert/tabs/details/risk-details-form-container';
import { Formik, FormikProps } from 'formik';
import { IRisk } from 'models/risk';
import { riskDetailsFormValidationSchema } from 'routes/risk-register/upsert/tabs/details/risk-details-form';
import {
    IMasterProcessStep,
    ActionOrEvent,
    IEventTemplate,
} from 'models/master-process-step/master-process-step';
import { IProcessStepAnswer, IProcessStepOutcome } from 'models/cerm/process-step-answer';
import { getRiskLevel } from '../risk-register/shared/risk-register-utils';
import {
    isNonComplyOutcomeAnswer,
    hasAnyNonComplyOutcomeAnswer,
    getOutcomeIdByActionId,
    getOutcomeById,
    getNonComplyOutcomes,
    isValidProcessStepAnswer,
} from './shared/cerm-utils';
import { IActionFilters } from 'models/action-filters';
import { IAction } from 'models/action';
import { actionDetailsValidationSchema } from 'routes/actions/upsert/tabs/details/actions-details-form';
import { hasErrorInFormik, scrollToFirstValidationError } from 'utils/form-utils';
import { CermProcessStepOutcomeActionTable } from './CermProcessStepOutcomeActionTable';
import {
    CermProcessStepOutcomeEventTable,
    eventTemplateFormValidationSchema,
} from './CermProcessStepOutcomeEventTable';
import {
    buildRiskWithTemplate,
    buildActionWithTemplate,
    buildEventWithTemplate,
} from './shared/cerm-template-builder';
import { IAppState } from 'reducers/app';
import { isEmpty } from 'lodash';
import * as RiskStatus from 'models/risk-statuses';
import useTranslate from 'translations/translation-utils';
import { IOperatingPlatform } from 'models/operating-platform-model';

interface IProps {
    siteId: string;
    createdRisk: IRisk;
    createdActions: IAction[];
    lookups: IAppState;
    masterProcessStep: IMasterProcessStep;
    processStepAnswer: IProcessStepAnswer;
    outcomeId?: string;
    submitUrl?: string;
    canEdit: boolean;
    loadRisk: (id: string, siteId: string) => void;
    loadActions: (filters: IActionFilters) => void;
    saveProcessStepAnswer: (processStepAnswer: IProcessStepAnswer) => void;
    setShouldBlockNavigation: (value: boolean) => void;
    PersonsLookup: (siteId: string) => void;
    canEditInExpertReview: () => boolean;
    allOperatingPlatforms: IOperatingPlatform[];
}

export const CermProcessStepRiskPanel: React.FC<IProps> = ({
    siteId,
    createdRisk,
    createdActions,
    lookups,
    masterProcessStep,
    processStepAnswer,
    outcomeId,
    submitUrl,
    canEdit,
    loadRisk,
    loadActions,
    saveProcessStepAnswer,
    setShouldBlockNavigation,
    PersonsLookup,
    canEditInExpertReview,
    allOperatingPlatforms,
}) => {
    const translate = useTranslate();
    const [showRiskDetails, setShowRiskDetails] = React.useState(true);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [risk, setRisk] = React.useState<IRisk>(null);
    const [actions, setActions] = React.useState<IAction[]>(null);
    const [events, setEvents] = React.useState<IEventTemplate[]>(null);
    const cermPageUrl = '/CermAssessment/';
    const reloadUrl = outcomeId
        ? submitUrl
            ? submitUrl
            : `${cermPageUrl}ProcessStep/${masterProcessStep.id}`
        : `${cermPageUrl}assessment`;

    React.useEffect(() => {
        PersonsLookup(siteId);
    }, []);

    React.useEffect(() => {
        setRiskOrRiskTemplateToState();
        if (createdRisk.id) {
            setShowRiskDetails(false);
        }
    }, [createdRisk]);

    React.useEffect(() => {
        setActionsOrActionsTemplateToState();
    }, [createdActions]);

    React.useEffect(() => {
        if (isValidProcessStepAnswer(processStepAnswer)) {
            loadExistingRiskActionsEvents();
            setActionsOrActionsTemplateToState();
            setEventsOrEventTemplatesToState();
        }
    }, [processStepAnswer]);

    const loadExistingRiskActionsEvents = () => {
        if (!hasAnyNonComplyOutcomeAnswer(processStepAnswer, masterProcessStep)) {
            return;
        }

        if (processStepAnswer.riskId) {
            loadRisk(processStepAnswer.riskId, siteId);
        } else {
            setRiskOrRiskTemplateToState();
        }

        const found = processStepAnswer.processStepOutcomes.filter(o => o.actionId !== null);
        if (!isEmpty(found)) {
            loadActions({
                siteId,
                filterSpecial: ['IncludeClosed'],
                Ids: found.map(o => o.actionId),
            } as IActionFilters);
        }
    };

    const setRiskOrRiskTemplateToState = () => {
        const theRisk = isValidActiveRisk
            ? createdRisk
            : buildRiskWithTemplate(
                masterProcessStep.riskTemplate,
                siteId,
                lookups,
                allOperatingPlatforms
            );
        setRisk(theRisk);
    };

    const isValidActiveRisk =
        createdRisk &&
        createdRisk.id &&
        createdRisk.id === processStepAnswer.riskId &&
        createdRisk.isActive &&
        createdRisk.riskStatusId !== RiskStatus.CLOSED;

    const setActionsOrActionsTemplateToState = () => {
        const nonComplyOutcomes = getNonComplyOutcomes(processStepAnswer, masterProcessStep);
        const currentActions: IAction[] = createdActions
            ? createdActions
                .filter(
                    action =>
                        isValidActiveAction(action) &&
                        nonComplyOutcomes.some(outcome => outcome.actionId === action.id)
                )
                .map(action => ({
                    ...action,
                    outcomeId: getOutcomeIdByActionId(processStepAnswer, action.id),
                }))
            : [];
        const allActions = [...currentActions, ...buildActionsWithTemplate(currentActions)];
        setActions(allActions);
    };

    const isValidActiveAction = (action: IAction) => action && action.id && action.isActive;

    const buildActionsWithTemplate = (existingActions: IAction[]): IAction[] => {
        // Get the not comply outcomes answers
        const nonComplyOutcomes = getNonComplyOutcomes(processStepAnswer, masterProcessStep);

        // Filter out the outcomes that already have the existing actions
        const nonComplyOutcomesThatExcludesExistingActions = nonComplyOutcomes.filter(
            outcome => !existingActions.some(action => action.id === outcome.actionId)
        );
        // Build list of actions based on Master outcome templates
        return masterProcessStep.expectedOutcomes
            .filter(
                masterOutcome =>
                    masterOutcome.actionOrEvent === ActionOrEvent.Action &&
                    nonComplyOutcomesThatExcludesExistingActions.some(
                        outcome => masterOutcome.id === outcome.id
                    )
            )
            .map(masterOutcome =>
                buildActionWithTemplate(
                    masterOutcome.id,
                    masterOutcome.action,
                    siteId,
                    lookups,
                    allOperatingPlatforms
                )
            );
    };

    const setEventsOrEventTemplatesToState = () => {
        const templates = buildEventTemplates();
        setEvents(templates);
    };

    const buildEventTemplates = (): IEventTemplate[] => {
        // Get the not comply outcomes answers
        const nonComplyOutcomes = getNonComplyOutcomes(processStepAnswer, masterProcessStep);

        // Build list of actions based on Master outcome templates
        return masterProcessStep.expectedOutcomes
            .filter(
                masterOutcome =>
                    masterOutcome.actionOrEvent === ActionOrEvent.Event &&
                    nonComplyOutcomes.some(outcome => masterOutcome.id === outcome.id)
            )
            .map(masterOutcome =>
                buildEventWithTemplate(
                    masterOutcome.id,
                    masterOutcome.event,
                    masterProcessStep,
                    nonComplyOutcomes
                )
            );
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let riskToBeUpdated: IRisk = processStepAnswer.risk;
        let outcomesToBeUpdated: IProcessStepOutcome[] = processStepAnswer.processStepOutcomes;
        if (riskFormik) {
            if (await hasErrorInFormik(riskFormik)) {
                setShowRiskDetails(true);
                scrollToFirstValidationError();
                return;
            }

            for (const actionFormik of actionFormiks) {
                if (await hasErrorInFormik(actionFormik)) {
                    scrollToFirstValidationError();
                    return;
                }
            }

            for (const eventFormik of eventFormiks) {
                if (await hasErrorInFormik(eventFormik)) {
                    scrollToFirstValidationError();
                    return;
                }
            }

            if (hasAnyNonComplyOutcomeAnswer(processStepAnswer, masterProcessStep)) {
                riskToBeUpdated = riskFormik.values;
                outcomesToBeUpdated = processStepAnswer.processStepOutcomes.map(outcome =>
                    getUpdatedOutcomeAnswer(outcome)
                );
            }
        }

        const answerToBeUpdated = {
            ...processStepAnswer,
            risk: riskToBeUpdated,
            processStepOutcomes: outcomesToBeUpdated,
            reloadUrl,
            siteId,
        } as IProcessStepAnswer;

        setIsSubmitting(true);
        setShouldBlockNavigation(false);
        saveProcessStepAnswer(answerToBeUpdated);
    };

    const getUpdatedOutcomeAnswer = (outcome: IProcessStepOutcome) => {
        if (!isNonComplyOutcomeAnswer(outcome)) {
            return outcome;
        }

        const masterOutcome = masterProcessStep.expectedOutcomes.find(o => o.id === outcome.id);
        if (masterOutcome.actionOrEvent === ActionOrEvent.Action) {
            const found = actionFormiks.find(
                actionFormik => actionFormik.values.outcomeId === outcome.id
            );
            if (found) {
                return { ...outcome, action: found.values, event: null };
            }
        } else {
            const found = eventFormiks.find(
                eventFormik =>
                    eventFormik.values.outcomeId === outcome.id &&
                    (!eventFormik.values.hasExistingEvents ||
                        eventFormik.values.overwriteExistingEvents)
            );
            if (found) {
                return { ...outcome, action: null, event: found.values };
            }
        }

        return outcome;
    };

    let riskFormik: FormikProps<IRisk> = null;
    let actionFormiks: Array<FormikProps<IAction>> = [];
    let eventFormiks: Array<FormikProps<IEventTemplate>> = [];

    const renderActionsForms = () => {
        if (isEmpty(actions)) {
            return null;
        }

        const getInitialAction = action => {
            if (!action.assigneeId) {
                action.assigneeId = null;
            }
            return action;
        };

        return actions.map((action, key) => (
            <Formik<IAction>
                key={key}
                initialValues={getInitialAction(action)}
                onSubmit={() => void 0}
                validationSchema={actionDetailsValidationSchema}
                enableReinitialize={true}
                isInitialValid={true}
                validateOnBlur={true}
                validateOnChange={true}
                render={formikBag => {
                    setShouldBlockNavigationOnFormikBagIsDirty(formikBag);
                    const found = actionFormiks.find(
                        f => f.values.outcomeId === formikBag.values.outcomeId
                    );
                    if (found) {
                        actionFormiks = actionFormiks.map(existingFormikBag => {
                            if (existingFormikBag.values.outcomeId === formikBag.values.outcomeId) {
                                return formikBag;
                            }

                            return existingFormikBag;
                        });
                    } else {
                        actionFormiks = [...actionFormiks, formikBag];
                    }

                    return (
                        <CermProcessStepOutcomeActionTable
                            actionFormik={formikBag}
                            canEdit={canEdit}
                        />
                    );
                }}
            />
        ));
    };

    const renderEventsForms = () => {
        return (
            events &&
            events.map((event, key) => (
                <Formik<IEventTemplate>
                    key={key}
                    initialValues={event}
                    onSubmit={() => void 0}
                    validationSchema={eventTemplateFormValidationSchema}
                    enableReinitialize={true}
                    isInitialValid={true}
                    validateOnBlur={true}
                    validateOnChange={true}
                    render={formikBag => {
                        setShouldBlockNavigationOnFormikBagIsDirty(formikBag);
                        const found = eventFormiks.find(
                            f => f.values.outcomeId === formikBag.values.outcomeId
                        );
                        if (found) {
                            eventFormiks = eventFormiks.map(existingFormikBag => {
                                if (
                                    existingFormikBag.values.outcomeId ===
                                    formikBag.values.outcomeId
                                ) {
                                    return formikBag;
                                }

                                return existingFormikBag;
                            });
                        } else {
                            eventFormiks = [...eventFormiks, formikBag];
                        }

                        return (
                            <CermProcessStepOutcomeEventTable
                                eventFormik={formikBag}
                                canEdit={canEdit}
                            />
                        );
                    }}
                />
            ))
        );
    };

    const setShouldBlockNavigationOnFormikBagIsDirty = <T extends unknown>(
        formikBag: FormikProps<T>
    ) => {
        if (!isSubmitting && formikBag.dirty) {
            setShouldBlockNavigation(true);
        }
    };

    return (
        <form id="riskPanelForms" onSubmit={handleSubmit} className="form-risk-actions-events">
            {(outcomeId &&
                isNonComplyOutcomeAnswer(getOutcomeById(processStepAnswer, outcomeId))) ||
                (!outcomeId && hasAnyNonComplyOutcomeAnswer(processStepAnswer, masterProcessStep)) ? (
                <Panel
                    title={translate('Cerm.ProcessStepRiskPanel.Labels.Title')}
                    intro={translate('Cerm.ProcessStepRiskPanel.Labels.Intro')}
                    type="warning"
                    className="panel-cerm-risk"
                    collapsed={false}
                >
                    {risk && (
                        <Table>
                            <tbody className="clickable">
                                <tr onClick={() => setShowRiskDetails(!showRiskDetails)}>
                                    <td className="narrow">
                                        <strong>
                                            {translate('Cerm.ProcessStepRiskPanel.Labels.Risk')}
                                        </strong>
                                    </td>
                                    <td className="narrow is-hidden-touch">{getRiskLevel(risk)}</td>
                                    <td>{risk.title}</td>
                                    <td className="is-hidden-touch">{risk.riskCategory}</td>
                                    <td className="is-hidden-touch">{risk.riskSubCategory}</td>
                                    <td className="is-hidden-touch">{risk.riskStatus}</td>
                                    <td className="narrow no-wrap is-hidden-touch">
                                        {risk.reviewDate.toDateString()}
                                    </td>
                                    <td className="narrow">
                                        <ToggleButton
                                            isEnabled={showRiskDetails}
                                            onClick={() => setShowRiskDetails(!showRiskDetails)}
                                        />
                                    </td>
                                </tr>
                                <Formik<IRisk>
                                    initialValues={risk}
                                    onSubmit={() => void 0}
                                    validationSchema={riskDetailsFormValidationSchema}
                                    enableReinitialize={true}
                                    isInitialValid={true}
                                    validateOnBlur={true}
                                    validateOnChange={false}
                                    render={formikBag => {
                                        riskFormik = formikBag;
                                        setShouldBlockNavigationOnFormikBagIsDirty(formikBag);

                                        return (
                                            // It is mandatory to use css to hide the form instead of make it not render in DOM
                                            // in order for the Formik validation to work properly
                                            <tr className={showRiskDetails ? '' : 'is-hidden'}>
                                                <td colSpan={7} className="form">
                                                    <RiskDetailsFormContainer
                                                        formikBag={formikBag}
                                                        isCermAssesment={true}
                                                        isReadOnly={!canEdit}
                                                        isRiskActiveAndNotClose={() => {
                                                            const _risk = formikBag.values;
                                                            return (
                                                                _risk &&
                                                                _risk.isActive &&
                                                                _risk.riskStatusId !==
                                                                RiskStatus.CLOSED
                                                            );
                                                        }}
                                                        commentSave={true}
                                                        canEditInExpertReview={
                                                            canEditInExpertReview
                                                        }
                                                        isMacro={false}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    }}
                                />
                                {renderActionsForms()}
                                {renderEventsForms()}
                            </tbody>
                        </Table>
                    )}
                </Panel>
            ) : null}
        </form>
    );
};
