import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { NavLink } from 'react-router-dom';
import { onRouteChange } from 'actions/app-actions';
import { IKeyValue } from 'models/key-value';
import { checkPermission } from 'utils/permission-utils';
import { GridSortLink } from '../../../../components/gridSortLink';
import { Loader } from '../../../../components/loader';
import { ButtonWithConfirmDialog, Button } from 'components/v2/components';
import {
    Timeline,
    TimelineHeader,
    TimelineHeaderGridValue,
    TimelineHeaderGridValueCollection,
    TimelineItem,
} from '../../../../components/timeline';
import { SortDirection } from '../../../../enums/SortDirection';
import { IQuestion, IQuestionSet } from '../../../../models/question-set';
import './question-set-list.scss';
import { isEmpty } from 'lodash';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconType } from 'routes/material-icon/material-icon-type';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';

export interface IQuestionSetListDispatchProps {
    onSort: (sortColumn: string, sortAscending: boolean) => void;
    onToggleExpanded: (id: string, siteId: string) => void;
    onCollapseAll: () => void;
    onExpandAll: (values: string[]) => void;
    deleteQuestionSet: (id: string, reloadUrl: string) => void;
}

interface IRouteParams {
    operatingPlatform?: string;
}

interface IProps extends RouteComponentProps<IRouteParams>, IQuestionSetListDispatchProps {
    items: IQuestionSet[];
    expandedItems: string[];
    isLoading: boolean;
    sortColumn: string;
    sortAscending: boolean;
    permissions: Array<IKeyValue<string>>;
    intl: any;
}

class QuestionSetListClass extends React.Component<IProps> {
    public state = {
        isExpanded: false,
    };
    constructor(props: IProps) {
        super(props);
        this.onClickHandle = this.onClickHandle.bind(this);
    }
    public onClickHandle() {
        const expanded = this.state.isExpanded;
        if (expanded) {
            this.props.onCollapseAll();
        } else {
            this.props.onExpandAll(this.props.items.map(({ id }) => id));
        }
        this.setState({ isExpanded: !expanded });
    }

    public render() {
        const { intl } = this.props;

        const getQuestionTimelineItem = (question: Partial<IQuestion>, key: number) => (
            <TimelineItem key={key}>
                <div className="columns">
                    <div className="column is-2 grid__label">{translate(intl, 'QuestionnSetList.Timeline.Item')} {key + 1}</div>
                    <div className="column">
                        {question.title}
                        <div className="columns">
                            <div className="column is-one-fifth">
                                <div>{translate(intl, 'QuestionnSetList.Timeline.Item2')} {question.actionsGenerated}</div>
                                <div>{translate(intl, 'QuestionnSetList.Timeline.Item3')} {question.risksGenerated}</div>
                            </div>
                            <div className="column is-1">
                                <div className="tag is-medium">{translate(intl, 'QuestionnSetList.Timeline.Yes')}{question.answeredYes}</div>
                            </div>
                            <div className="column is-1">
                                <div className="tag is-medium">{translate(intl, 'QuestionnSetList.Timeline.No')} {question.answeredNo}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </TimelineItem>
        );

        const getQuestionSetItem = (
            questionSet: IQuestionSet,
            isToggleExpanded: boolean,
            onToggleExpanded: () => void,
            permissions: Array<IKeyValue<string>>,
            deleteQuestionSet: (id: string, reloadUrl: string) => void,
            operatingPlatform: string
        ) => {
            const deleteAndReloadList = (id: string) => {
                onRouteChange('/QuestionSets/' + operatingPlatform);
                deleteQuestionSet(id, '/QuestionSets/' + operatingPlatform);
            };

            const editQuestionSet = (id: string) => {
                onRouteChange(`/QuestionSets/ManageQuestionSets/${id}/Questions/` + operatingPlatform);
            };

            return (
                <Timeline
                    highlighted={questionSet.status === 'Draft'}
                    expanded={isToggleExpanded}
                    key={`timeline_${questionSet.id}`}
                >
                    <TimelineHeader
                        centered={true}
                        expanded={isToggleExpanded}
                        onToggleExpanded={onToggleExpanded}
                    >
                        <TimelineHeaderGridValueCollection>
                            <TimelineHeaderGridValue className="timeline-title">
                                {questionSet.title}
                            </TimelineHeaderGridValue>

                            <TimelineHeaderGridValue label={translate(intl, 'QuestionnSetList.Columns.Status')}>
                                {questionSet.status}
                            </TimelineHeaderGridValue>

                            <TimelineHeaderGridValue label={translate(intl, 'QuestionnSetList.Columns.Asked')}>
                                {questionSet.asked}
                            </TimelineHeaderGridValue>

                            <TimelineHeaderGridValue label={translate(intl, 'QuestionnSetList.Columns.Completed')}>
                                {questionSet.completed}
                            </TimelineHeaderGridValue>

                            <TimelineHeaderGridValue label={translate(intl, 'QuestionnSetList.Columns.Relevant')}>
                                {questionSet.relevant}
                            </TimelineHeaderGridValue>

                            <TimelineHeaderGridValue label={translate(intl, 'QuestionnSetList.Columns.Relevant')}>
                                {questionSet.started}
                            </TimelineHeaderGridValue>
                        </TimelineHeaderGridValueCollection>
                    </TimelineHeader>

                    <TimelineItem>
                        <div className="columns is-mobile">
                            <div className="column is-2-tablet grid__label">{translate(intl, 'QuestionnSetList.Timeline.Item4')}</div>
                            <div className="column">
                                {questionSet.relevancyQuestion}
                                <div className="columns">
                                    <div className="column grid__label">{translate(intl, 'RiskRegisterPage.RiskFilter.Title.Category')}</div>
                                    <div className="column">{questionSet.category}</div>
                                    <div className="column grid__label">{translate(intl, 'QuestionnSetList.Timeline.Item5')}</div>
                                    <div className="column">{questionSet.urgency}</div>
                                </div>
                                <div className="columns">
                                    <div className="column is-3 grid__label">{translate(intl, 'RiskActionTab.Label.Priority')}</div>
                                    <div className="column">{questionSet.priority} </div>
                                </div>
                            </div>
                            {(checkPermission('RiskQuestionsOverviewUpdate', permissions)
                            ) && (
                                    <div className="column is-2-tablet">
                                        <div className="column is-12-tablet">
                                            {questionSet.status === 'Draft' && (
                                                <Button onClick={editQuestionSet.bind(this, questionSet.id)}>
                                                    {translate(intl, 'QuestionnSetList.NavLink.Edit')}
                                                </Button>
                                            )}
                                            {questionSet.status === 'Published' && (
                                                <>
                                                    <NavLink
                                                        to={`/QuestionSets/ManageQuestionSets/${questionSet.id}/Questions/${operatingPlatform}`}
                                                        className="button button-action"
                                                    >
                                                        {translate(intl, 'QuestionnSetList.NavLink.Amend')}
                                                    </NavLink>
                                                    <ButtonWithConfirmDialog
                                                        buttonId="archive"
                                                        buttonText={translate(intl, 'RiskActionTab.Button.Archive')}
                                                        buttonType="archive"
                                                        title={translate(intl, 'QuestionnSetList.Dialog.ButtonText')}
                                                        message={translate(intl, 'QuestionnSetList.Dialog.Message')}
                                                        onConfirm={deleteAndReloadList.bind(
                                                            this,
                                                            questionSet.id
                                                        )}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )}
                        </div>
                    </TimelineItem>

                    {questionSet.questions &&
                        questionSet.questions.map((question, key) =>
                            getQuestionTimelineItem(question, key)
                        )}
                </Timeline>
            );
        };

        const getGridHeader = (label: string, fieldName: string, props: IProps) => {
            const onSort = (sortField: string, sortDirection: SortDirection) =>
                props.onSort(sortField, sortDirection === SortDirection.Ascending);

            return (
                <GridSortLink
                    currentSortFieldName={props.sortColumn}
                    currentSortDirection={
                        props.sortAscending ? SortDirection.Ascending : SortDirection.Descending
                    }
                    sortFieldName={fieldName}
                    onSort={onSort}
                >
                    {label}
                </GridSortLink>
            );
        };

        return (
            <Loader className="QuestionSetList" loading={this.props.isLoading}>
                {isEmpty(this.props.items) ? (
                    <p>{translate(intl, 'QuestionnSetList.Timeline.NoResultFound')}</p>
                ) : (
                    <>
                        <div
                            className={`Timeline-offset grid-headers columns ${this.props.isLoading ? 'is-loading' : ''
                                }`}
                        >
                            <button
                                className="TimelineHeader__toggle TimelineColumnHeader"
                                onClick={this.onClickHandle}
                            >
                                < MaterialIcons type={this.state.isExpanded ? MaterialIconType.Minus : MaterialIconType.Plus} />
                            </button>
                            <div className="column">
                                {getGridHeader(translate(intl, 'QuestionnSetList.Columns.QuestionSet'), 'title', this.props)}
                            </div>
                            <div className="column">
                                {getGridHeader(translate(intl, 'QuestionnSetList.Columns.Status'), 'status', this.props)}
                            </div>
                            <div className="column">
                                {getGridHeader(translate(intl, 'QuestionnSetList.Columns.Asked'), 'asked', this.props)}
                            </div>
                            <div className="column">
                                {getGridHeader(translate(intl, 'QuestionnSetList.Columns.Completed'), 'completed', this.props)}
                            </div>
                            <div className="column">
                                {getGridHeader(translate(intl, 'QuestionnSetList.Columns.Relevant'), 'relevant', this.props)}
                            </div>
                            <div className="column">
                                {getGridHeader(translate(intl, 'QuestionnSetList.Columns.Started'), 'started', this.props)}
                            </div>
                        </div>
                        {this.props.items &&
                            this.props.items.map((action) =>
                                getQuestionSetItem(
                                    action,
                                    this.props.expandedItems.includes(action.id),
                                    () => this.props.onToggleExpanded(action.id, action.siteId),
                                    this.props.permissions,
                                    this.props.deleteQuestionSet,
                                    this.props.match.params.operatingPlatform
                                )
                            )}
                    </>
                )}
            </Loader>
        );
    }
}

export const QuestionSetList = injectIntl(QuestionSetListClass);
