import { isNil } from 'lodash';
import { createSelector } from 'reselect';

import { IRootRisksState } from '../../../../reducers/risks';
import { getKey } from '../../../../utils/id-utils';
import { IOwnProps } from './risk-add-attachment-modal';

const getModalData = (state: IRootRisksState) =>
    state.risks.attachments.openModal;

const getIsModalVisible = (state: IRootRisksState, props: IOwnProps) => {
    const data = getModalData(state);
    return  !isNil(data) &&
        data.id === props.riskId &&
        data.siteId === props.siteId;
};

const getIsUploading = (state: IRootRisksState) => {
    const data = getModalData(state);
    return !isNil(data) && data.isUploading;
};

const getIsVisible = (state: IRootRisksState, props: IOwnProps) =>
    state.risks.attachments.byRisk.hasOwnProperty(getKey({ id: props.riskId, siteId: props.siteId }));

export default createSelector(
    [getIsVisible, getIsUploading, getIsModalVisible],
    (isVisible, isUploading, isModalVisible) => ({
        isVisible,
        isUploading,
        isModalVisible
    })
);
