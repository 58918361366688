import { connect } from 'react-redux';

import {
    IDispatchProps,
    IOwnProps,
    IStateProps,
    RiskAddAttachmentModal as View
} from './risk-add-attachment-modal';

import actions from './actions';
import selectors from './selector';

export const RiskAddAttachmentModal = connect<IStateProps, IDispatchProps, IOwnProps, {}>(
    selectors,
    actions
)(View);
