import { reducer as toastrReducer } from 'react-redux-toastr';
import { combineEpics } from 'redux-observable';
import { calendarEpics } from 'epics/calendar-epics';
import { siteFeaturesEpics } from 'epics/site-feature-epics';
import { cermSiteOverviewEpics } from 'epics/cerm-site-overview-epics';
import { manageSiteRelationships } from 'epics/manage-site-relationships-epics';
import { manageRoleEpics } from 'epics/manage-roles-epic';
import { notificationEpics } from 'epics/notification-epics';
import { personEpics } from 'epics/person-epics';
import { questionnaireEpics } from 'epics/questionnaire-epics';
import { siteEpics } from 'epics/manage-site-relationships-epics/site-epics';
import { questionSetEpicsV2 } from 'epics/epics-v2/question-set-epic';
import { riskAssessmentEpics } from 'epics/risk-assessment-epics';
import { actionsReducer, IActionsState } from 'reducers/actions';
import { reducer as calendar } from 'reducers/calendar';
import { reducer as cermSiteOverview } from 'reducers/cerm-site-overview';
import { cermAssessmentsReducer } from 'reducers/cermAssessments';
import { clientsReducer } from 'reducers/clients';
import { clientPage } from 'reducers/clients/upsert-client-page';
import { manageRolesReducer } from 'reducers/manageRoles';
import { IPersonProfileState, reducer as personProfileReducer } from 'reducers/person-profile';
import { personsReducer, IPersonState } from 'reducers/persons';
import { questionSetsReducer } from 'reducers/questionSet';
import { riskAssessmentReducer } from 'reducers/risk-assessment';
import {
    dashboardComponentsReducer,
    IDashboardComponentsState,
} from 'reducers/dashboard/dashboard-components-reducer';
import { risksReducer, IRisksState } from 'reducers/risks';
import { siteFeaturesReducer, ISiteFeatureState } from 'reducers/site-features';
import { siteProfilesReducer } from 'reducers/site-profiles';
import { siteRelationshipsReducer as siteRelationships } from 'reducers/siteRelationships';
import {
    cermProcessStepStateReducer as cermProcessStepState,
    outcomeItemToDelete,
    stepItemToDelete,
} from 'reducers/upsert-cerm-process-step';
import { siteUpsertReducer } from 'reducers/upsert-site';
import { controlCentreEpics } from '../epics/control-centre-epics';
import { appReducer } from '../reducers/app';
import { reducer as cermOverviewReducer } from '../reducers/cerm-overview';
import { reducer as editQuestionSet, IQuestionSetsState } from '../reducers/create-question-set';
import { controlCentreReducer } from '../reducers/control-centre';
import { navReducer } from '../reducers/nav';
import { createStore as createStoreFactory } from '../store-factory';
import { appNotificationEpics } from 'epics/app-notification-epics';
import { lookupsReducer } from 'reducers/lookups/lookups-reducer';
import { incidentReducer, IIncidentState } from 'reducers/incidents/incidents-reducer';
import { IBaseState } from 'reducers/base-state';
import { ISiteState } from 'state/upsert-site-state';
import {
    INotificationState,
    IEngineersState,
    IEngineerState,
    ISkillsState,
    IShiftsState,
    IRiskProfileQuestionnaireState,
    ICermProcessOwnersState,
    ICriticalSystemSparesState,
    IMasterProcessStepState,
} from 'state';
import { notificationsReducer, riskProfileQuestionsReducer } from 'reducers';
import { engineersReducer } from 'reducers/engineers/engineers-reducer';
import { engineerReducer } from 'reducers/engineers/engineer-reducer';
import { skillsReducer } from 'reducers/engineers/skills-reducer';
import { cermProcessOwnerReducer } from 'reducers/cerm/cerm-process-owner-reducer';
import { siteEpicsV2 } from 'epics/epics-v2/site-epics';
import { clientEpicsV2 } from 'epics/epics-v2/client-epic';
import { actionEpicsV2 } from 'epics/epics-v2/action-epic';
import { personEpicsV2 } from 'epics/epics-v2/person-epics';
import { riskEpicsV2 } from 'epics/epics-v2/risk-epic';
import { macroRiskEpicsV2 } from 'epics/epics-v2/macro-risk-epic';
import { cermAssessmentEpicsV2 } from 'epics/epics-v2/cerm-assessment-epic';
import { cermProcessStepEpicsV2 } from 'epics/epics-v2/cerm-process-step-epic';
import { navEpicsV2 } from 'epics/epics-v2/nav-epics';
import {
    skillCategoryEpicsV2,
    skillEpicsV2,
    engineerEpicsV2,
    siteSkillsEpicsV2,
    riskProfileMasterQuestionnaireEpicsV2,
    riskProfileQuestionnaireEpicsV2,
    incidentEpicsV2,
} from 'epics/epics-v2';
import { riskAssessmentEpicsV2 } from 'epics/epics-v2/risk-assessment-epic';
import { shiftsReducer } from 'reducers/shifts/shifts-reducer';
import { shiftEpicsV2 } from 'epics/epics-v2/shift-epics';
import { dashboardEpics } from 'epics/epics-v2/dashboard-epic';
import { attachmentEpicsV2 } from 'epics/epics-v2/attachment-epic';
import { cermProcessOwnerEpicsV2 } from 'epics/epics-v2/cerm-process-owner-epic';
import { criticalSystemSpareEpicsV2 } from 'epics/epics-v2/critical-system-spares-epic';
import { criticalSystemSparesReducer } from 'reducers/critical-system-spares/critical-system-spares-reducer';
import { IRiskRegisterState } from 'state/risk-register-state';
import { riskRegisterReducer } from 'reducers/risk-register/risk-register-reducer';
import { IAttachmentState, attachmentReducer } from 'reducers/attachments/attachment-reducer';
import { ICommentState } from 'state/comment-state';
import { commentReducer } from 'reducers/comments/comments-reducer';
import { commentEpicsV2 } from 'epics/epics-v2/comment-epic';
import { userProfileEpicsV2 } from 'epics/epics-v2/user-profile-epic';
import { criticalSystemSpareSummaryEpicsV2 } from 'epics/epics-v2/critical-system-spares-summary-epic';
import { clientRisksSummaryEpicsV2 } from 'epics/epics-v2/client-risks-summary-epic';
import { criticalSystemSpareSiteSummariesEpicsV2 } from 'epics/epics-v2/critical-system-spares-site-summaries-epic';
import { IActionRegisterState } from 'state/action-state';
import { actionRegisterReducer } from 'reducers/actions/action-reducer';
import { masterProcessStepReducer } from 'reducers/master-process-step/master-process-step-reducer';
import { masterProcessStepEpicsV2 } from 'epics/epics-v2/master-process-step-epic';
import { ICermAssessmentResultState } from 'state/cerm/cerm-assessment-result-state';
import { cermAssessmentResultReducer } from 'reducers/cerm/cerm-assessment-result-reducer';
import { cermAssessmentEpicsV3 } from 'epics/epics-v2/cerm-assessment-epic-v3';
import { ICermGlobalOverviewState } from 'state/cerm-global-overview-state';
import { cermGlobalOverviewEpicsV2 } from 'epics/epics-v2/cerm-global-overview-epic';
import { cermGlobalOverviewReducer } from 'reducers/cerm-global-overview/cerm-global-overview-reducer';
import { cermCermSnapshotsEpicsV2 } from 'epics/epics-v2/cerm-assessment-snapshots-epic';
import { globalMockDrillsEpicsV2 } from 'epics/epics-v2/global-mock-drills-epic';
import { romonetEpics } from 'epics/epics-v2/romonet-epic';
import { opportunityQuestionsEpic } from 'epics/epics-v2/opportunity-epic';
import { opportunityQuestionnaireEpics } from 'epics/epics-v2/opportunity-questionnnaire-epic';
import { calendarEpicv2 } from 'epics/epics-v2/calendar-epics-v2';
import { externalBuildingsEpics } from 'epics/epics-v2/external-buildings-epic-v2';
import {
    IGlobalMockDrillsState,
    IMockDrillsState,
    IMockDrillReportState,
} from 'state/mock-drills-state';
import { clientVerificationEpicsV2 } from 'epics/epics-v2/client-verification-epic';
import { globalMockDrillsReducer } from 'reducers/mock-drills/global-mock-drills-reducer';
import { mockDrillsReducer } from 'reducers/mock-drills/mock-drills-reducer';
import { mockDrillsEpicsV2 } from 'epics/epics-v2/mock-drills-epic';
import { mockDrillReportReducer } from 'reducers/mock-drills/mock-drill-report-reducer';
import { mockDrillReportEpicsV2 } from 'epics/epics-v2/mock-drill-report-epic';
import { roleEpicsV2 } from 'epics/epics-v2/role-epic';
import { IShiftHandoverState } from 'state/shift-handover-state';
import { shiftHandoverReducer } from 'reducers/shifts/shift-handover-reducer';
import { shiftHandoverEpicsV2 } from 'epics/epics-v2/shift-handover-epic';
import { ILogsUploadState } from 'state/logs-upload-state';
import { logsUploadReducer } from 'reducers/logs-upload/logs-upload-reducer';
import { uploadLogEpics } from 'epics/epics-v2/logs-upload-epic.';
import { romonetReducer } from 'reducers/romonet/romonet-reducer';
import { upsertSiteEpics } from 'epics/upsert-site-buildings-epics';
import { siteRolesEpicsV2 } from 'epics/epics-v2/site-roles-epics';
import { siteRoleReducer } from 'reducers/siteRoles/site-roles-reducer';
import { ISiteRolesState } from 'state/site-roles-state';
import { supportTeamEpicsV2 } from 'epics/epics-v2/opportunity-support-team-epics';
import { supportTeamReducer } from 'reducers/support-team/opportunity-support-team-reducer';
import { ISupportTeamState } from 'state/opportunity-support-team-state';
import { IRomonetState } from 'state/romonet-state';
import { opportunityReducer } from 'reducers/opportunity-questions/opportunity-reducer';
import { IOpportunityState } from 'state/opportunity-state';
import { opportunityQuestionnaireReducer } from 'reducers/opportunity-questionnaire/opportunity-questionnaire-reducer';
import { IOpportunityQuestionnaireState } from 'state/opportunity-questionnaire-state';
import { IRootCalendarState } from 'state/calendar-state';
import { clientVerificationReducer } from 'reducers/client-verification/client-verification-reducer';
import { IClientVerificationState } from 'state/client-verification-state';
import { IauditorEpicsV2 } from 'epics/epics-v2/iauditor-epics';
import { IauditorReducer } from 'reducers/iauditor/iauditor-reducer';
import { IAuditorState } from 'state/iauditor-state';
import { IFaqsEpicsV2 } from 'epics/epics-v2/faqs-epic';
import { IFaqsReducer } from 'reducers/faqs/faqs-reducer';
import { IFaqsState } from 'state/faqs-state';
import { IFacilityEpicsV2 } from 'epics/epics-v2/facility-epic';
import { IFacilityReducer } from 'reducers/facility/facility-reducer';
import { IFacilityState } from 'state/facility-state';
import { notificationEpicV2 } from 'epics/epics-v2/notification-epics';
import { IMacroRisksState, macroRisksReducer } from 'reducers/macro-risks';
import { IMenuEpics } from 'epics/epics-v2/menu-epics';
import { IMenuReducer } from 'reducers/menu/menu-reducer';
import { IMenuState } from 'state/menu-state';
import { IOperatingPlatformEpics } from 'epics/epics-v2/operating-platform-epic';
import { IOperatingPlatformReducer } from 'reducers/operatingPlatform/operating-platform-reducer';
import { IOperatingPlatformState } from 'state/operating-platform-state';
import { ISiteMetaDataEpics } from 'epics/epics-v2/site-metadata-epic';
import { ISiteMetaDataReducer } from 'reducers/site-metadata/site-metadata-reducer';
import { ISiteMetaDataState } from 'state/site-metadata-state';
import { assetReducer, IAssetState } from 'reducers/assets/asset-reducers';
import { assetEpics } from 'epics/asset-epics';
import { globalSkillCardEpics } from 'epics/global-skill-card-epics';
import { globalSkillCategoryEpics } from 'epics/global-skill-category-epic';
import { adminCdnaGlobalReducer, IAdminCdnaGlobalState } from 'reducers/admin-cdna/admin-cdna-reducers';
import { globalSkillEpics } from 'epics/global-skill-epics';
import { skillCardEpics } from 'epics/skill-card-epics';
import { ISkillCardState, skillCardReducer } from 'reducers/cdna/skill-card-reducers';

export interface IRootState extends IBaseState {
    controlCentre: any;
    risks: IRisksState;
    macroRisks: IMacroRisksState;
    cermAssessmentState: any;
    actions: IActionsState;
    calendar: any;
    manageRoles: any;
    cermOverview: any;
    cermSiteOverview: any;
    clients: any;
    clientPage: any;
    siteRelationships: any;
    siteProfiles: any;
    riskAssessmentState: any;
    questionSets: any;
    editQuestionSet: IQuestionSetsState;
    outcomeItemToDelete: any;
    stepItemToDelete: any;
    cermProcessStepState: any;
    siteState: ISiteState;
    siteFeatures: ISiteFeatureState;
    person: IPersonProfileState;
    persons: IPersonState;
    notificationContainer: INotificationState;
    incidentsState: IIncidentState;
    engineersState: IEngineersState;
    engineerState: IEngineerState;
    skillsState: ISkillsState;
    engineerSkillsState: ISkillsState;
    shiftsState: IShiftsState;
    dashboardComponentsState: IDashboardComponentsState;
    riskProfileQuestionnaireState: IRiskProfileQuestionnaireState;
    cermProcessOwnerState: ICermProcessOwnersState;
    criticalSystemSparesState: ICriticalSystemSparesState;
    riskRegisterState: IRiskRegisterState;
    attachmentState: IAttachmentState;
    commentState: ICommentState;
    actionRegisterState: IActionRegisterState;
    cermGlobalOverviewState: ICermGlobalOverviewState;
    masterProcessStepState: IMasterProcessStepState;
    cermAssessmentResultState: ICermAssessmentResultState;
    globalMockDrillsState: IGlobalMockDrillsState;
    mockDrillsState: IMockDrillsState;
    mockDrillReportState: IMockDrillReportState;
    shiftHandoverState: IShiftHandoverState;
    logsUploadState: ILogsUploadState;
    siteRolesState: ISiteRolesState;
    opportunityState: IOpportunityState;
    opportunityQuestionsState: IOpportunityQuestionnaireState;
    supportTeamState: ISupportTeamState;
    romonetState: IRomonetState;
    calendarState: IRootCalendarState;
    clientVerificationState: IClientVerificationState;
    iauditorState: IAuditorState;
    faqsState: IFaqsState;
    facilityState: IFacilityState;
    operatingPlatformState: IOperatingPlatformState;
    siteMetaDataState: ISiteMetaDataState;
    menuState: IMenuState;
    assetState: IAssetState;
    adminCdnaGlobalState: IAdminCdnaGlobalState;
    skillCardState: ISkillCardState
}

const reducers = {
    lookups: lookupsReducer,
    controlCentre: controlCentreReducer,
    risks: risksReducer,
    macroRisks: macroRisksReducer,
    cermAssessmentState: cermAssessmentsReducer,
    actions: actionsReducer,
    calendar,
    manageRoles: manageRolesReducer,
    cermOverview: cermOverviewReducer,
    cermSiteOverview,
    clients: clientsReducer,
    toastr: toastrReducer,
    clientPage,
    siteRelationships,
    siteProfiles: siteProfilesReducer,
    persons: personsReducer,
    riskAssessmentState: riskAssessmentReducer,
    questionSets: questionSetsReducer,
    editQuestionSet,
    outcomeItemToDelete,
    stepItemToDelete,
    cermProcessStepState,
    app: appReducer,
    nav: navReducer,
    siteState: siteUpsertReducer,
    siteFeatures: siteFeaturesReducer,
    person: personProfileReducer,
    notificationContainer: notificationsReducer,
    incidentsState: incidentReducer,
    engineersState: engineersReducer,
    engineerState: engineerReducer,
    dashboardComponentsState: dashboardComponentsReducer,
    skillsState: skillsReducer,
    shiftsState: shiftsReducer,
    riskProfileQuestionnaireState: riskProfileQuestionsReducer,
    cermProcessOwnerState: cermProcessOwnerReducer,
    criticalSystemSparesState: criticalSystemSparesReducer,
    riskRegisterState: riskRegisterReducer,
    attachmentState: attachmentReducer,
    commentState: commentReducer,
    actionRegisterState: actionRegisterReducer,
    cermGlobalOverviewState: cermGlobalOverviewReducer,
    masterProcessStepState: masterProcessStepReducer,
    cermAssessmentResultState: cermAssessmentResultReducer,
    globalMockDrillsState: globalMockDrillsReducer,
    mockDrillsState: mockDrillsReducer,
    mockDrillReportState: mockDrillReportReducer,
    shiftHandoverState: shiftHandoverReducer,
    logsUploadState: logsUploadReducer,
    siteRolesState: siteRoleReducer,
    romonetState: romonetReducer,
    opportunityState: opportunityReducer,
    opportunityQuestionsState: opportunityQuestionnaireReducer,
    supportTeamState: supportTeamReducer,
    clientVerificationState: clientVerificationReducer,
    iauditorState: IauditorReducer,
    faqsState: IFaqsReducer,
    facilityState: IFacilityReducer,
    operatingPlatformState: IOperatingPlatformReducer,
    siteMetaDataState: ISiteMetaDataReducer,
    menuState: IMenuReducer,
    assetState: assetReducer,
    adminCdnaGlobalState: adminCdnaGlobalReducer,
    skillCardState: skillCardReducer
};
export const createStore = (state?: IRootState) =>
    createStoreFactory(
        reducers,
        combineEpics(
            appNotificationEpics,
            controlCentreEpics,
            riskEpicsV2,
            macroRiskEpicsV2,
            cermSiteOverviewEpics,
            navEpicsV2,
            manageSiteRelationships,
            clientEpicsV2,
            siteEpicsV2,
            siteFeaturesEpics,
            manageRoleEpics,
            notificationEpics,
            personEpics,
            questionnaireEpics,
            calendarEpics,
            siteEpics,
            personEpicsV2,
            riskAssessmentEpics,
            questionSetEpicsV2,
            incidentEpicsV2,
            actionEpicsV2,
            cermAssessmentEpicsV2,
            cermProcessStepEpicsV2,
            skillCategoryEpicsV2,
            skillEpicsV2,
            engineerEpicsV2,
            riskAssessmentEpicsV2,
            dashboardEpics,
            shiftEpicsV2,
            siteSkillsEpicsV2,
            riskProfileMasterQuestionnaireEpicsV2,
            riskProfileQuestionnaireEpicsV2,
            attachmentEpicsV2,
            cermProcessOwnerEpicsV2,
            criticalSystemSpareEpicsV2,
            commentEpicsV2,
            userProfileEpicsV2,
            clientRisksSummaryEpicsV2,
            criticalSystemSpareSummaryEpicsV2,
            criticalSystemSpareSiteSummariesEpicsV2,
            cermGlobalOverviewEpicsV2,
            masterProcessStepEpicsV2,
            cermAssessmentEpicsV3,
            cermCermSnapshotsEpicsV2,
            globalMockDrillsEpicsV2,
            mockDrillsEpicsV2,
            mockDrillReportEpicsV2,
            roleEpicsV2,
            shiftHandoverEpicsV2,
            uploadLogEpics,
            upsertSiteEpics,
            siteRolesEpicsV2,
            supportTeamEpicsV2,
            romonetEpics,
            opportunityQuestionsEpic,
            opportunityQuestionnaireEpics,
            calendarEpicv2,
            externalBuildingsEpics,
            clientVerificationEpicsV2,
            IauditorEpicsV2,
            notificationEpicV2,
            IFaqsEpicsV2,
            IFacilityEpicsV2,
            IOperatingPlatformEpics,
            ISiteMetaDataEpics,
            IMenuEpics,
            assetEpics,
            globalSkillCardEpics,
            globalSkillCategoryEpics,
            globalSkillEpics,
            skillCardEpics
        ),
        state
    );
