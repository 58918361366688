import * as React from 'react';
import { Button } from '../components';
import { Checkbox } from 'components/form/Checkbox';
import useTranslate from 'translations/translation-utils';

interface IProps {
    title: string;
    message: string | JSX.Element;
    isVisible: boolean;
    isLoading?: boolean;
    id?: string;
    checkboxLabel?: string;
    buttonCancelText?: string;
    buttonCancelHide?: boolean;
    buttonConfirmText?: string;
    onConfirm: (checked: boolean) => void;
    onClose?: () => void;
    onOutsideDialogClick?: () => void;
}

export const ConfirmDialogWithCheckbox: React.FC<IProps> = ({
    title,
    message: confirmMessage,
    isVisible,
    isLoading,
    id = 'confirmation',
    checkboxLabel = 'Please confirm',
    buttonCancelText,
    buttonCancelHide = false,
    buttonConfirmText,
    onClose,
    onConfirm,
    onOutsideDialogClick
}) => {
    const [checked, setChecked] = React.useState(false);
    const translate = useTranslate();
    const onChangeHandler = () => {
        setChecked(!checked);
    };
    buttonCancelText = buttonCancelText
        ? buttonCancelText
        : translate('ConfirmNavigation.Label.Cancel');
    buttonConfirmText = buttonConfirmText
        ? buttonConfirmText
        : translate('ConfirmNavigation.Label.Confirm');
    const onConfirmHandler = () => {
        onConfirm(checked);
    };

    const getConfirmMessage = () => {
        if (typeof confirmMessage === 'string') {
            return (
                <p>
                    <strong>{confirmMessage}</strong>
                </p>
            );
        }

        return confirmMessage;
    };

    return isVisible ? (
        <div id={id} className={`modal modal-quantum is-clipped is-active`}>
            <div className="modal-background" onClick={onOutsideDialogClick} />
            <div className="modal-content">
                <h2>{title}</h2>
                {getConfirmMessage()}
                <Checkbox
                    id="checkbox"
                    label={checkboxLabel}
                    checked={checked}
                    onChange={onChangeHandler}
                />
                <div className="modal-buttons">
                    {!buttonCancelHide && (
                        <Button buttonType="cancel" onClick={onClose}>
                            {buttonCancelText}
                        </Button>
                    )}
                    <Button onClick={onConfirmHandler} isLoading={isLoading} disabled={!checked}>
                        {buttonConfirmText}
                    </Button>
                </div>
            </div>
        </div>
    ) : null;
};
