import React from 'react';
import { IconToolbar, IToolbarIconItem, ToolbarIconType } from 'components/v2/components';
import { onRouteChange } from 'actions/app-actions';

interface IProps {
    mockDrillId: string;
    allowEdit: boolean;
    scenarioType?: string;
}

export const MockDrillReportsPageToolbar: React.FC<IProps> = ({
    mockDrillId,
    allowEdit,
    scenarioType,
}) => {
    const scenarioTypeParam = scenarioType ? `${scenarioType}/` : '';
    const getToolbarItems = (): IToolbarIconItem[] => {
        return [
            allowEdit && {
                id: 'edit-mock-drill-button',
                title: 'EditScenario',
                type: ToolbarIconType.edit,
                onClick: () => onRouteChange(`/MockDrill/${scenarioTypeParam}Details/${mockDrillId}`),
            },
        ];
    };

    return <IconToolbar items={getToolbarItems()} />;
};
