import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { MasterProcessStepActions } from 'actions/actions-v2/master-process-step-action-v2';
import { IMasterProcessStep } from 'models/master-process-step/master-process-step';

const controllerName = 'masterProcessStep';

const getMasterProcessSteps = {
    action: MasterProcessStepActions.LOAD_MASTER_PROCESS_STEPS,
    actionFulfilled: MasterProcessStepActions.LOAD_MASTER_PROCESS_STEPS_FULFILLED,
    actionRejected: MasterProcessStepActions.LOAD_MASTER_PROCESS_STEPS_REJECTED
} as IActionType;

const loadMasterProcessStepsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getMasterProcessSteps).get<IMasterProcessStep[]>(
        `/api/${controllerName}`
    );

export const masterProcessStepEpicsV2 = combineEpics(loadMasterProcessStepsEpic);
