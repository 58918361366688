import { constant } from 'lodash';
import { IBaseState } from 'reducers/base-state';
import { INavState } from 'reducers/nav';
import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { checkRole } from 'utils/permission-utils';
import { getClients } from 'routes/site-features/selectors';
import { OperatingPlatform } from 'models/site-profile';
import { IPersonToSite } from 'models/person';

const getPageTitle = constant('User Profile');

const getPerson = createSelector(
    (state: any) => state.person,
    (state) => state.personProfile
);

const getMyAssignedSites = (state: IRootState) =>
    state.person.myAssignedSites;

const getRelationships = createSelector(
    (state: IRootState) => state,
    state => state.person.relationships
);

const getIsClient = createSelector(
    (state: IRootState) => state.app.permissions,
    (permissions) => checkRole('Client', permissions)
);
const getlanguageItems = createSelector(
    (state: IBaseState) => state.nav,
    (state: INavState) => state.languageItem
);
const getPersonLanguage = createSelector(
    (state: any) => state.nav.languageItem,
    (state) => state.person.personProfile && state.person.personProfile.language,
    (languageItem, language) => {
        const personLanguage = languageItem.filter((item) => item.key === language);
        return personLanguage.length !== 0 ? personLanguage[0].display : '';
    }
);

const getLoading = createSelector(
    (state: IRootState) => state.person.isLoading,
    (appState) => appState
);

const getLinesOfBusiness = (state: IRootState) =>
    state.app.lookupLineOfBusiness.map(x => {
        return {
            key: x.key,
            value: x.value,
            label: `${x.operatingPlatform}: ${x.value}`,
        };
    });

const getDivisions = (state: IRootState) => [
    ...state.app.lookupEnterpriseDivisions.map(x => {
        return {
            key: x.key,
            value: x.value,
            label: `${x.operatingPlatform} - Enterprise: ${x.value}`,
            operatingPlatform: x.operatingPlatform,
        };
    }),
    ...state.app.lookupLocalDivisions.map(x => {
        return {
            key: x.key,
            value: x.value,
            label: `${x.operatingPlatform} - Local: ${x.value}`,
            operatingPlatform: x.operatingPlatform,
        };
    }),
    ...state.app.lookupDcsDivisions.map(x => {
        return {
            key: x.key,
            value: x.value,
            label: `${OperatingPlatform.DataCenter} - DCS: ${x.value}`,
            operatingPlatform: OperatingPlatform.DataCenter,
        };
    }),
];
const getBusinessUnits = (state: IRootState) =>
    state.app.lookupBusinessUnit.map(x => {
        return {
            key: x.key,
            value: x.value,
            label: `${x.division ? x.division : x.operatingPlatform}: ${x.label}`,
        };
    });

export default createSelector(
    getPageTitle,
    getPerson,
    getMyAssignedSites,
    getIsClient,
    getlanguageItems,
    getPersonLanguage,
    getLoading,
    getLinesOfBusiness,
    getDivisions,
    getBusinessUnits,
    getClients,
    getRelationships,
    (
        pageTitle,
        person,
        myAssignedSites,
        isClient,
        languageItem,
        language,
        isLoading,
        linesOfBusiness,
        divisions,
        businessUnits,
        clients,
        relationships: IPersonToSite[],
    ) => ({
        pageTitle,
        person,
        myAssignedSites,
        isClient,
        languageItem,
        language,
        isLoading,
        linesOfBusiness,
        divisions,
        businessUnits,
        clients,
        relationships,
    })
);
