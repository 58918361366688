import React from 'react';
import {
    Timeline,
    TimelineHeader,
    TimelineHeaderGridValue,
    TimelineHeaderGridValueCollection,
    TimelineItem,
} from 'components/timeline';
import { IDifference } from 'models/difference';
import DifferenceItem from 'components/history/difference-item';
import { formatDateTime } from 'utils/date-utils';
import { ConvertArrayFieldToWords, ConvertCamelCaseToWords } from 'utils/utils';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';
import Dotdotdot from 'react-dotdotdot';

interface IEntityHistoryProps {
    differences: IDifference[];
    intl: any;
}

class EntitytHistoryItemClass extends React.PureComponent<IEntityHistoryProps> {
    public state = {
        isToggled: false,
    };

    constructor(props: IEntityHistoryProps) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    public onClick() {
        const toggle = !this.state.isToggled;
        this.setState({ isToggled: toggle });
    }

    private getFieldsModified() {
        const fieldsModified = this.props.differences.map((difference) => {
            const field = ConvertArrayFieldToWords(difference.propertyName);
            if (field) {
                return ConvertCamelCaseToWords(field);
            }
            return ConvertCamelCaseToWords(difference.propertyName);
        });
        return fieldsModified.join(', ');
    }

    public render(): JSX.Element {
        const { differences } = this.props;
        if (differences && differences.length > 0) {
            return (
                <Timeline
                    expanded={this.state.isToggled}
                    key={`${differences[0].parentObject2.id}`}
                >
                    <TimelineHeader
                        expanded={this.state.isToggled}
                        onClick={this.onClick}
                        centered={true}
                    >
                        <TimelineHeaderGridValueCollection>
                            <TimelineHeaderGridValue
                                className="timeline-title column is-9"
                                label={translate(this.props.intl, 'RiskRegisterUpsertPage.Tabs.Details')}
                            >
                                <Dotdotdot clamp={1} size={50}>
                                    {differences[0].parentObject2.lastUpdatedByPersonName} {translate(this.props.intl, 'History.HistoryItem.Updated')}{' '}
                                    {this.getFieldsModified()}
                                </Dotdotdot>
                            </TimelineHeaderGridValue>
                            <TimelineHeaderGridValue
                                className="has-text-centered column i-5"
                                label={translate(this.props.intl, 'History.HistoryItem.DateTime')}
                            >
                                {formatDateTime(differences[0].parentObject2.lastUpdatedDateTime)}
                            </TimelineHeaderGridValue>
                        </TimelineHeaderGridValueCollection>
                    </TimelineHeader>
                    {differences.map((diff, key) => {
                        return (
                            <TimelineItem key={`timeline-item-${key}`}>
                                <DifferenceItem difference={diff} />
                            </TimelineItem>
                        );
                    })}
                </Timeline>
            );
        }
    }
}

export const EntitytHistoryItem = injectIntl(EntitytHistoryItemClass);