import { history } from 'routes/App';
import {
    UpsertCermProcessStepActions,
    UpsertCermProcessStepActionTypes } from '../../../actions/upsert-cerm-process-step-actions';
import { loadCermProcess, upsertCermProcess } from 'actions/actions-v2/cerm-process-step-action-v2';

export default {
    onPageLoad: loadCermProcess,
    onChangeRoot: (urlLocation: string) => history.push(urlLocation),
    onSubmit: upsertCermProcess,
    goToOutcome: (outcomeIndex: number): UpsertCermProcessStepActionTypes => ({
        type: UpsertCermProcessStepActions.SET_SELECTED_OUTCOME,
        payload: {
            selectedOutcomeIndex: outcomeIndex
        }
    })
};
