import { connect } from 'react-redux';
import { IRootState } from "routes/store";
import { IauditorGridToolbarView } from './iauditor-toolbar';
import { loadTemplate } from 'actions/actions-v2/iauditor-action';

const mapStateToProps = (state: IRootState) => {
    return {
        multipleSites: state.app.lookupSites.length > 1,
        siteId: state.app.siteId,
        templates: state.iauditorState.templates,
        siteCode: state.siteState.site.siteCode,
            };
};

const mapDispatchToProps = {
    loadTemplate,
};

export const IauditorGridToolbar = connect(mapStateToProps, mapDispatchToProps)(IauditorGridToolbarView);


