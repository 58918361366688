import * as React from 'react';
import { RouteUrlProcessStep } from 'routes/upsert-cerm-process-step/routes';
import { checkPermissions } from 'utils/permission-utils';
import { IKeyValue } from '../../../../models/key-value';
import { IToolbarIconItem, ToolbarIconType, IconToolbar } from 'components/v2/components';
import { history } from 'routes/App';
import { useTranslate } from 'translations/translation-utils';
import { Privilege } from 'enums/Privilege';

export interface IProps {
    permissions: Array<IKeyValue<string>>;
    isShowHelp: boolean;
    isFilterExists?: boolean;
    onShowFilters: () => void;
    onClearFilters: () => void;
    onClickHelp: () => void;
    operatingPlatform: string;
    isOpExist: boolean;
}

export const CermOverviewToolbar: React.FC<IProps> = ({
    permissions,
    isFilterExists,
    isShowHelp,
    onShowFilters,
    onClearFilters,
    onClickHelp,
    operatingPlatform,
    isOpExist,
}) => {
    const translate = useTranslate();
    const getToolbarItems = (): IToolbarIconItem[] => {
        return [
            checkPermissions([Privilege.ProcessUpdate], permissions) &&
                isOpExist && {
                    id: 'add-action-button',
                    title: translate('RiskActionTab.Button.Addaction'),
                    type: ToolbarIconType.add,
                    isActive: false,
                    onClick: () => {
                        history.push(`${RouteUrlProcessStep(operatingPlatform)}`);
                    },
                },
            {
                id: 'clear-all-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.ClearFilters'),
                type: isFilterExists ? ToolbarIconType.filterApplied : ToolbarIconType.clearFilter,
                isActive: false,
                onClick: onClearFilters,
            },
            {
                id: 'show-filters-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.ShowFilters'),
                type: ToolbarIconType.filter,
                isActive: false,
                onClick: onShowFilters,
            },
            isShowHelp && {
                id: 'show-help',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.Help'),
                type: ToolbarIconType.help,
                onClick: onClickHelp,
            },
        ];
    };

    return <IconToolbar items={getToolbarItems()} />;
};
