import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { getSitesWithFeatures, getPermissions } from 'sharedSelectors';

const getSiteId = (state: IRootState) => state.app.siteId;
const getIsLoading = (state: IRootState) =>
    state.actionRegisterState.isLoading || state.lookups.isLoading;
const getAction = (state: IRootState) => state.actionRegisterState.action;
const getPersonLookups = (state: IRootState) => state.lookups.personLookups;
const getExpertReviewStatus = (state: IRootState) =>
    state.cermAssessmentResultState.expertReviewStatus;
const getMockDrillReport = (state: IRootState) => state.mockDrillReportState.mockDrillReport;

export default createSelector(
    getSiteId,
    getIsLoading,
    getAction,
    getPersonLookups,
    getSitesWithFeatures,
    getExpertReviewStatus,
    getPermissions,
    getMockDrillReport,
    (
        siteId,
        isLoading,
        action,
        users,
        lookupSiteFeatures,
        expertReviewStatus,
        permissions,
        mockDrillReport
    ) => ({
        siteId,
        isLoading,
        action: { ...action, siteId },
        users,
        lookupSiteFeatures,
        expertReviewStatus,
        permissions,
        mockDrillReport,
    })
);
