import { IEmeraldTitleInput } from '../shared/title.input';
import { IEmeraldLabelInput } from '../shared/label.input';

export abstract class EmeraldProgressIndicatorCoreInputs
  implements IEmeraldProgressIndicatorCoreInputs
{
  determinate?: boolean = true;
  progress?: number = 0;
  label?: string;
  title?: string;
}

export interface IEmeraldProgressIndicatorCoreInputs
  extends IEmeraldTitleInput,
    IEmeraldLabelInput {
  determinate?: boolean;
  progress?: number;
}
