import React from 'react';
import Page from 'components/v2/page/Page';
import Attachments from 'components/attachemnts/attachments';
import { Entity } from 'models/entity';
import { IAttachmentQuery } from 'models/attachment/attachment-query';
import { IAttachmentUpload } from 'models/attachment/attachment-upload';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import { Loader } from 'components/loader';

interface IProps {
    siteId: string;
    attachments: IAttachmentModel[];
    isReadOnly: boolean;
    loadAttachments: (query: IAttachmentQuery) => void;
    uploadAttachment: (upload: IAttachmentUpload) => void;
}

export const SiteDocumentsPage: React.FC<IProps> = (props) => {
    return (
        <Page title="Documents" className="site-documents-page">
            {props.siteId ? (
                <div className="documents">
                    <Attachments
                        id={props.siteId}
                        entity={Entity.Site}
                        attachments={props.attachments}
                        loadAttachments={props.loadAttachments}
                        uploadAttachment={props.uploadAttachment}
                        isReadOnly={props.isReadOnly}
                    />
                </div>
            ) : (
                <Loader loading={true} />
            )}
        </Page>
    );
};
