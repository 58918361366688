import { createSelector } from 'reselect';

import { IKeyedItem } from '../../../../models/keyed-item';
import { IRootRisksState } from '../../../../reducers/risks';
import { getKey } from '../../../../utils/id-utils';

const getIsExpanded = (state: IRootRisksState, props: { item: IKeyedItem }) =>
    state.risks.grid.expandedLinkedBuildings.hasOwnProperty(props.item.id);

const getIsLoading = (state: IRootRisksState, props: { item: IKeyedItem }) => {
    const match = state.risks.linkedBuildings[getKey(props.item)];
    return Boolean(match && match.isLoading);
};

const getLinkedBuildings = (state: IRootRisksState, props: { item: IKeyedItem }) => {
    const match = state.risks.linkedBuildings[getKey(props.item)];
    return (match && match.data) || [];
};

export default () => createSelector(
    getIsExpanded,
    getIsLoading,
    getLinkedBuildings,
    (isExpanded, isLoading, data) => ({
        isExpanded,
        isLoading,
        data
    })
);
