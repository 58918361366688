import { IEmeraldTitleInput } from '../shared/title.input';
import { EmeraldDrawerType, EmeraldDrawerTypeType } from './drawer.type';

export class EmeraldDrawerCoreInputs implements IEmeraldDrawerCoreInputs {
  open = false;
  type?: EmeraldDrawerTypeType = EmeraldDrawerType.Dismissible;
  title?: string;
  subTitle?: string;
  classes?: string;
}

export interface IEmeraldDrawerCoreInputs extends IEmeraldTitleInput {
  open: boolean;
  type?: EmeraldDrawerTypeType;
  subTitle?: string;
  classes?: string;
}
