export const riskEnGB = `
<div>
<p>Click the link below to view:</p>
<p><a href="https://confluence.cbre.com/x/u7zSC" target= "_blank">Risk Flow Process</a></p>
</div>
<br />
<div>
<p><a href="https://quantum.cbre.com/issues" target= "_blank">Report an Issue</a></p>
<p><a href="https://quantum.cbre.com/requests" target= "_blank">Raise a Request</a></p>
</div>`;
