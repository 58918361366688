import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { IDashboardComponentProps } from 'models/dashboard/IDashboardComponentProps';
import { IDashboardCalenderEventItem } from 'models/dashboard/IDashboardCalenderEventItem';
import { IDashboardCalenderEventComponent } from 'models/dashboard/IDashboardCalenderEventComponent';
import { Table, TableNoItem } from 'components/v2/components';
import { Loader } from 'components/loader';
import DashboardComponent, { loadComponentEffect } from '../DashboardComponent';
import selectors from './selectors';
import actions from './actions';
import moment from 'moment';
import useTranslate from 'translations/translation-utils';

const DashboardCalendarEventComponent: React.FC<
    IDashboardComponentProps<IDashboardCalenderEventComponent>
> = ({ siteId, isLoading, component, hasPermission, loadComponent, className }) => {

    const translate = useTranslate();

    const currentSiteId = React.useRef<string>(null);

    loadComponentEffect(hasPermission, currentSiteId, siteId, loadComponent);

    const getEventRow = (item: IDashboardCalenderEventItem) => {
        const today = moment();
        const start = moment(item.startDate);
        const end = moment(item.endDate);
        const isToday = today.isBetween(start, end);
        let date = '';
        if (isToday) {
            date = 'Today';
        } else if (start.isSame(end)) {
            date = start.format('DD MMM');
        } else {
            date = `${start.format('DD MMM')}-${end.format('DD MMM')}`;
        }

        return (
            <tr key={item.id} className={isToday ? 'today' : null}>
                <td className="is-narrow">{date}</td>
                <td>{item.title}</td>
            </tr>
        );
    };

    return (
        <DashboardComponent
            title={translate('Dashboard.CalandarEvents.Title')}
            intro={translate('Dashboard.CalandarEvents.Intro')}
            className={`column-calendar-event ${className}`}
            headerButton={
                <div className="has-text-centered">
                    <NavLink to="/Calendar" className="button is-primary is-fullwidth is-medium">
                        {translate('Dashboard.CalandarEvents.ViewCalendar')}
                    </NavLink>
                </div>
            }
            hasPermission={hasPermission}
        >
            <Loader loading={isLoading}>
                <Table>
                    <tbody>
                        {!component || !component.items || component.items.length < 1 ? (
                            <TableNoItem colSpan={2} />
                        ) : (
                            component.items.map((item) => getEventRow(item))
                        )}
                    </tbody>
                </Table>
            </Loader>
        </DashboardComponent>
    );
};

export default connect(
    selectors,
    actions
)(DashboardCalendarEventComponent);
