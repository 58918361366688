import { IActionsState } from 'reducers/actions';
import { ActionActionTypes, ActionActions } from 'actions/action-actions';
export default {
    onToggleFilterExpanded: (id: Extract<keyof IActionsState, string>): ActionActionTypes => ({
        type: ActionActions.TOGGLE_FILTER_EXPANDED,
        payload: {
            id
        }
    })
};
