// tslint:disable-next-line:max-line-length
import { deleteProcessStep, UpsertCermProcessStepActions, UpsertCermProcessStepActionTypes } from 'actions/upsert-cerm-process-step-actions';
import {
    CermOverviewActions,
    CermOverviewActionTypes,
    // changeSortColumn,
    collapseAll,
    togglePillar,
    toggleProcessStepExpanded
} from '../../../../actions/cerm-overview-actions';

export default {
    onToggleProcessStep: toggleProcessStepExpanded,
    onTogglePillar: togglePillar,
    // onSort: changeSortColumn,
    onDelete: deleteProcessStep,
    onCollapseAll: collapseAll,
    onExpandAll:  (pillars: string[], steps: string[]): CermOverviewActionTypes => ({
        type: CermOverviewActions.EXPAND_ALL,
        payload: {
            pillars,
            steps
        }
    }),
    onLoadEditPage: (id: string): UpsertCermProcessStepActionTypes => ({
        type: UpsertCermProcessStepActions.LOAD_PAGE,
        payload: {
            id
        }
    })
};
