import { DefaultContainerID } from '../toast/toast.inputs';
import {
  EmeraldSnackbarPositionType,
  EmeraldSnakbarPosition,
} from './snackbar.position';
import {
  EmeraldSnackbarVariance,
  EmeraldSnackbarVarianceType,
} from './snackbar.variance';
import { IEmeraldOpenedInput } from '../shared/opened.input';
import { IEmeraldIdInput } from '../shared/id.input';

export declare class EmeraldSnackbarCoreInputs
  implements IEmeraldSnackbarCoreInputs
{
  id?: string;
  opened?: boolean;
  containerId: string;
  hasDismissIcon?: boolean;
  hasActionButton?: boolean;
  actionButtonText?: string;
  message?: string;
  timeoutMs?: number;
  isStacked?: boolean;
  position?: EmeraldSnackbarPositionType;
  closeOnEscape?: boolean;
  showSecondaryButton?: boolean;
  secondaryButtonText?: string;
  variance?: EmeraldSnackbarVarianceType;
  leftIcon?: string;
}

export interface IEmeraldSnackbarCoreInputs
  extends IEmeraldIdInput,
    IEmeraldOpenedInput {
  containerId: string;
  snackbarId?: string;
  hasDismissIcon?: boolean;
  hasActionButton?: boolean;
  actionButtonText?: string;
  message?: string;
  timeoutMs?: number;
  isStacked?: boolean;
  position?: EmeraldSnackbarPositionType;
  closeOnEscape?: boolean;
  showSecondaryButton?: boolean;
  secondaryButtonText?: string;
  variance?: EmeraldSnackbarVarianceType;
  leftIcon?: string;
}

export class EmeraldSnackbarData implements IEmeraldSnackbarData {
  id?: string;
  variance?: EmeraldSnackbarVariance = EmeraldSnackbarVariance.NONE;
  position?: EmeraldSnackbarPositionType = EmeraldSnakbarPosition.NONE;
  hasDismissIcon?: boolean = false;
  hasActionButton?: boolean = false;
  actionButtonText?: string = 'Ok';
  showSecondaryButton?: boolean = false;
  secondaryButtonText?: string = '';
  timeoutMs?: number = 5000;
  isStacked?: boolean = false;
  closeOnEscape?: boolean = false;
  leftIcon?: string;
  constructor(
    public message: string,
    public containerId: string = DefaultContainerID
  ) {}
}

export interface IEmeraldSnackbarData extends IEmeraldIdInput {
  message: string;
  containerId: string;
  variance?: EmeraldSnackbarVarianceType;
  position?: EmeraldSnackbarPositionType;
  hasDismissIcon?: boolean;
  hasActionButton?: boolean;
  actionButtonText?: string;
  showSecondaryButton?: boolean;
  secondaryButtonText?: string;
  timeoutMs?: number;
  isStacked?: boolean;
  closeOnEscape?: boolean;
  leftIcon?: string;
  snackbarId?: string;
}
