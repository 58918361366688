import * as React from 'react';
import { Button } from '../components';
import { ConfirmDialog } from './ConfirmDialog';
import classNames from 'classnames';

interface IProps {
    title: string;
    message: string | JSX.Element;
    buttonText: string | JSX.Element;
    buttonId?: string;
    buttonType?: 'save' | 'action' | 'cancel' | 'filter' | 'archive' | 'clear' | 'upload';
    buttonConfirmText?: string;
    buttonClassName?: string;
    isButtonDisabled?: boolean;
    isButtonVisible?: boolean;
    confirmButtonDisabled?: boolean;
    isLoading?: boolean;
    onConfirm: () => void;
}

export const ButtonWithConfirmDialog: React.FC<IProps> = ({
    buttonText,
    buttonId = 'confirmButton',
    buttonType,
    buttonClassName,
    isButtonDisabled = false,
    isButtonVisible = true,
    confirmButtonDisabled,
    onConfirm,
    ...props
}) => {
    const [isDialogVisible, setIsDialogVisible] = React.useState(false);

    const onConfirmButtonClick = () => {
        onConfirm();
        setIsDialogVisible(false);
    };

    const onClose = () => {
        setIsDialogVisible(false);
    };

    return (
        <>
            {isButtonVisible && (
                <Button
                    id={buttonId}
                    isLoading={props.isLoading}
                    buttonType={buttonType}
                    onClick={() => setIsDialogVisible(true)}
                    disabled={isButtonDisabled}
                    className={classNames(buttonClassName)}
                >
                    {buttonText}
                </Button>
            )}

            <ConfirmDialog
                isVisible={isDialogVisible}
                onConfirm={onConfirmButtonClick}
                onClose={onClose}
                onOutsideDialogClick={onClose}
                confirmButtonDisabled={confirmButtonDisabled}
                {...props}
            />
        </>
    );
};
