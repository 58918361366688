import * as React from 'react';
import { Icon, IconType } from '../icon';

import './file-input.scss';

export interface IState {
    files: File[];
}

export interface IProps {
    name: string;
    allowMultiple?: boolean;
    label?: string;
    onChange: (files?: File[]) => void;
}

export class FileInput extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.handleFileChange = this.handleFileChange.bind(this);

        this.state = {
            files: []
        };
    }

    static get defaultProps() {
        return {
            label: 'Choose a file…'
        };
    }

    public render() {
        return (
            <div className="file has-name FileInput">
                <label className="file-label">
                    <input
                        className="file-input"
                        type="file"
                        multiple={this.props.allowMultiple}
                        name={this.props.name}
                        onChange={this.handleFileChange}
                    />
                    <span className="file-cta">
                        <span className="file-icon">
                            <Icon type={IconType.Upload} />
                        </span>
                        <span className="file-label">{this.props.label}</span>
                    </span>
                    {this.state.files.map((file, i) => (
                        <span key={i} className="file-name">
                            {file.name}
                        </span>
                    ))}
                </label>
            </div>
        );
    }

    private handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        const files: File[] = [];
        for (const file of e.target.files) {
            files.push(file);
        }

        this.setState(() => ({
            files
        }));

        this.props.onChange(files);
    }
}
