import { deleteSite } from 'actions/actions-v2/site-actions-v2';
import {
    loadClient,

} from 'actions/actions-v2/client-action-v2';
import {
    loadAttachmentsSite,
    uploadAttachmentSite,
} from 'actions/actions-v2/attachment-action-v2';



export default {
    loadClient: loadClient,
    onDeleteSite: deleteSite,
    loadAttachments: loadAttachmentsSite,
    uploadAttachment: uploadAttachmentSite 
};
