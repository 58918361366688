import { createSelector } from 'reselect';
import { IRootControlCentreState } from '../../reducers/control-centre';

export const getSites = createSelector(
    (state: IRootControlCentreState) => state.app,
    (appState) => (appState.lookupSites.length > 0 ? appState.lookupSites : [])
);

export const getRegions = (state: IRootControlCentreState) => state.controlCentre.regions;

const getIsInitialising = (state: IRootControlCentreState) => state.controlCentre.isInitialising;

const getSelectedSiteId = createSelector(
    (state: IRootControlCentreState) => state.app.siteId,
    (appState) => appState
);

export default createSelector(
    getIsInitialising,
    getSites,
    getSelectedSiteId,
    (isInitialising, lookupSites, siteId) => ({
        isInitialising,
        lookupSites,
        siteId
    })
);
