import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { CermLinks } from './cerm-links-form';
import { ICermOverviewRootState } from 'reducers/cerm-overview';
import { withRouter } from 'react-router';
/* import { orderBy, property } from 'lodash'; */

const getCermItem = (state: ICermOverviewRootState) => state.cermOverview.items;

const getIsLoading = (state: IRootState) => state.cermOverview.isLoading;
const mapStateToProps = createSelector(getIsLoading, getCermItem, (isLoading, cermItems) => ({
    isLoading,
    cermItems,
}));

export const CermLinksFormContainer = withRouter(connect(mapStateToProps)(CermLinks));
