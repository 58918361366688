import { createSelector } from 'reselect';
import { IBaseState } from 'reducers/base-state';
import { IRootRiskAssessmentState } from 'reducers/risk-assessment';
import { getFilteredLookupSitePeopleWithAllowedRoles, getFilteredLookupSiteRolesRiskQuestionsPeopleWithAllowed } from 'sharedSelectors';

const getRiskAssessmentState = createSelector(
    (state: IRootRiskAssessmentState) => state.riskAssessmentState,
    (state) => state
);

const getSiteId = (state: IBaseState) => state.app.siteId;

const getIsInitialising = createSelector(
    (state: IRootRiskAssessmentState) => state.riskAssessmentState,
    (state) => state.isInitialising
);

const getLookupSite = (state: IBaseState) => state.app.lookupSites;

export default createSelector(
    getRiskAssessmentState,
    getSiteId,
    getIsInitialising,
    getFilteredLookupSitePeopleWithAllowedRoles,
    getLookupSite,
    getFilteredLookupSiteRolesRiskQuestionsPeopleWithAllowed,
    (state, siteId, isInitialising, lookupSitePeopleRoles, lookupSite, lookupSiteRiskQuestionUpdatePeopleRoles) => ({
        ...state,
        siteId,
        isInitialising,
        lookupSitePeopleRoles,
        lookupSite,
        lookupSiteRiskQuestionUpdatePeopleRoles,
    })
);
