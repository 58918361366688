import { connect } from 'react-redux';
import { setProcessStepResult } from 'actions/actions-v2/cerm-assessment-action-v3';
import { CermProcessStepsTable } from './CermProcessStepsTable';
import { onRouteChange } from 'actions/app-actions';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { refreshECermToken } from 'actions/actions-v2/cerm-assessment-action-v3';

const mapDispatchToProps = {
    setProcessStepResult,
    onRouteChange,
    getECermToken: refreshECermToken,
};
const getPermissions = (state: IRootState) => state.app.permissions;
const getAuthToken = (state: IRootState) => state.cermAssessmentResultState.eCermToken;
const getOperatingPlatforms = (state: IRootState) => state.operatingPlatformState.operatingPlatforms;

const mapStateToProps = createSelector(
    getPermissions,
    getAuthToken,
    getOperatingPlatforms,
    (permissions, authToken,allOperatingPlatforms) => ({
        permissions, authToken, allOperatingPlatforms
    })
);

export const CermProcessStepsTableContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CermProcessStepsTable);
