import { combineReducers } from 'redux';
import { SupportTeamActions as Actions } from 'actions/actions-v2/opportunity-support-team-actions-v2';
import { IAppAction } from 'actions/app-action';
import { ISupportTeamState } from 'state/opportunity-support-team-state';

export const INITIAL_STATE: ISupportTeamState = {
    supportTeam: null,
};

const supportTeam = (
    state: ISupportTeamState['supportTeam'] = INITIAL_STATE.supportTeam,
    action: IAppAction
) => {
    switch (action.type) {
        case Actions.LOAD_TEAM:
            return INITIAL_STATE.supportTeam;

        case Actions.LOAD_TEAM_FULFILLED:
            return action.payload;
        case Actions.RESET_SUPPORT_TEAM:
            return null;
        default:
            return state;
    }
};

export const supportTeamReducer = combineReducers<ISupportTeamState>({
    supportTeam,
});
