import { IPerson } from 'models/person';
import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import appConfig from 'helpers/config-helper';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { connect } from 'react-redux';
import selectors from './selectors';
import { v5 } from 'uuid';
import { IKeyValue } from 'models';
import { findValueByKey } from 'helpers/helpers';
import actions from './actions';

export interface IProps extends RouteComponentProps<{}> {
    person: IPerson;
    appSettings?: Array<IKeyValue<string>>;
    loadAppSettings: () => void;
}

const config = appConfig();

const SendSegmentData: React.FC<IProps> = props => {
    const analytics = AnalyticsBrowser.load({
        writeKey: findValueByKey('REACT_APP_UEM_ANALYTICS_KEY', props.appSettings),
    });

    const { history } = props;

    useEffect(() => {
        if (history.location && config.REACT_APP_FEATURE_FLAG_UEM_ANALYTICS === 'true') {
            if (props.person.emailAddress1 && config.REACT_APP_UUID_NAMESPACE) {
                const userId = v5(props.person.emailAddress1, config.REACT_APP_UUID_NAMESPACE);
                analytics.page({ userId: userId });
            }
        }
    }, [history.location]);

    useEffect(() => {
        props.loadAppSettings();
    }, []);

    return null;
};

export default withRouter(connect(selectors, actions)(SendSegmentData));
