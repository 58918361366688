import { combineReducers } from 'redux';
import {
    UpsertCermProcessStepActions,
    UpsertCermProcessStepActionTypes,
} from '../../actions/upsert-cerm-process-step-actions';
import { ICermProcessStep } from '../../models/cerm-process-step';
import { IKeyValue } from '../../models/key-value';
import { IKeyedItem } from '../../models/keyed-item';
import { IBaseState } from '../base-state';
import { IConfirmDeleteProcessStep } from '../cerm-overview';
import { CermProcessStepActions } from 'actions/actions-v2/cerm-process-step-action-v2';
import { IAppAction } from 'actions/app-action';

export interface IConfirmDeleteOutcome {
    outcomeItemToDelete?: IKeyedItem;
}

export interface IUpsertCermProcessStepRootState
    extends IBaseState,
    IConfirmDeleteProcessStep,
    IConfirmDeleteOutcome {
    cermProcessStepState: ICermProcessStepState;
}

export interface ICermProcessStepState {
    isInitialising: boolean;
    cermProcessStep: ICermProcessStep;
    actionPriorities: Array<IKeyValue<string>>;
    processStepPriorities: Array<IKeyValue<string>>;
    pillars: Array<IKeyValue<string>>;
    phases: Array<IKeyValue<string>>;
    services: Array<IKeyValue<string>>;
    isSubmitting: boolean;
}

export const CERM_PROCESS_STEP_INITIAL_STATE: ICermProcessStep = {
    id: '',
    siteId: null,
    bucket: '',
    shard: '',
    eTag: null,
    status: '',
    title: '',
    service: '',
    phase: '',
    pillar: '',
    processNumber: 0,
    priority: '1',
    reviewPeriodMonths: 1,
    isPublished: false,
    riskTemplate: {},
    expectedOutcomes: [],
    createdDateTime: undefined,
    lastUpdatedDateTime: undefined,
    createdByPersonId: undefined,
    lastUpdatedByPersonId: undefined,
    operatingPlatform: '',
};

export const INITIAL_STATE: ICermProcessStepState = {
    isInitialising: true,
    cermProcessStep: CERM_PROCESS_STEP_INITIAL_STATE,
    actionPriorities: [],
    processStepPriorities: [],
    pillars: [],
    phases: [],
    services: [],
    isSubmitting: false,
};

const services = (
    state: ICermProcessStepState['services'] = INITIAL_STATE.services,
    action: UpsertCermProcessStepActionTypes
) => {
    switch (action.type) {
        case UpsertCermProcessStepActions.LOAD_PAGE_FULFILLED:
            return action.payload.cermProcessStepState.services;
        default:
            return state;
    }
};

const processStepPriorities = (
    state: ICermProcessStepState['processStepPriorities'] = INITIAL_STATE.processStepPriorities,
    action: UpsertCermProcessStepActionTypes
) => {
    switch (action.type) {
        case UpsertCermProcessStepActions.LOAD_PAGE_FULFILLED:
            return action.payload.cermProcessStepState.processStepPriorities;
        default:
            return state;
    }
};

const actionPriorities = (
    state: ICermProcessStepState['actionPriorities'] = INITIAL_STATE.actionPriorities,
    action: UpsertCermProcessStepActionTypes
) => {
    switch (action.type) {
        case UpsertCermProcessStepActions.LOAD_PAGE_FULFILLED:
            return action.payload.cermProcessStepState.actionPriorities;
        default:
            return state;
    }
};

const pillars = (
    state: ICermProcessStepState['pillars'] = INITIAL_STATE.pillars,
    action: UpsertCermProcessStepActionTypes
) => {
    switch (action.type) {
        case UpsertCermProcessStepActions.LOAD_PAGE_FULFILLED:
            return action.payload.cermProcessStepState.pillars;
        default:
            return state;
    }
};

const phases = (
    state: ICermProcessStepState['phases'] = INITIAL_STATE.phases,
    action: UpsertCermProcessStepActionTypes
) => {
    switch (action.type) {
        case UpsertCermProcessStepActions.LOAD_PAGE_FULFILLED:
            return action.payload.cermProcessStepState.phases;
        default:
            return state;
    }
};

const isSubmitting = (state = INITIAL_STATE.isSubmitting, action: IAppAction) => {
    switch (action.type) {
        case UpsertCermProcessStepActions.UPDATE_CERM_PROCESS_STEP:
        case CermProcessStepActions.UPDATE_CERM_PROCESS:
        case CermProcessStepActions.CREATE_CERM_PROCESS:
        case UpsertCermProcessStepActions.DELETE_PROCESS_STEP_CONFIRMED:
            return true;

        case UpsertCermProcessStepActions.UPDATE_CERM_PROCESS_STEP_FULFILLED:
        case CermProcessStepActions.UPDATE_CERM_PROCESS_FULFILLED:
        case CermProcessStepActions.UPDATE_CERM_PROCESS_REJECTED:
        case CermProcessStepActions.CREATE_CERM_PROCESS_FULFILLED:
        case CermProcessStepActions.CREATE_CERM_PROCESS_REJECTED:
        case UpsertCermProcessStepActions.DELETE_PROCESS_STEP_CONFIRMED_FULFILLED:
        case UpsertCermProcessStepActions.DELETE_PROCESS_STEP_CONFIRMED_REJECTED:
            return false;

        default:
            return state;
    }
};

const cermProcessStep = (state = INITIAL_STATE.cermProcessStep, action: IAppAction) => {
    switch (action.type) {
        case CermProcessStepActions.LOAD_CERM_PROCESS_FULFILLED:
            if (action.payload) {
                return action.payload;
            }
            return state;
        case CermProcessStepActions.LOAD_CERM_PROCESS_INITIAL:
            return INITIAL_STATE.cermProcessStep;
        case UpsertCermProcessStepActions.LOAD_PAGE_FULFILLED:
            return action.payload.cermProcessStepState.cermProcessStep;
        case UpsertCermProcessStepActions.DELETE_OUTCOME_CONFIRMED_FULFILLED:
            return {
                ...state,
                expectedOutcomes: state.expectedOutcomes.filter(
                    (expectedOutcome) => expectedOutcome.id !== action.payload.item.id
                ),
            };
        case UpsertCermProcessStepActions.UPDATE_CERM_PROCESS_STEP_FULFILLED:
            return action.payload.processStep;

        default:
            return state;
    }
};

export const outcomeItemToDelete = (
    state: IConfirmDeleteOutcome['outcomeItemToDelete'] = null,
    action: UpsertCermProcessStepActionTypes
) => {
    switch (action.type) {
        case UpsertCermProcessStepActions.DELETE_OUTCOME_CONFIRMED_FULFILLED:
        case UpsertCermProcessStepActions.CANCEL_DELETE_OUTCOME:
            return null;
        case UpsertCermProcessStepActions.DELETE_OUTCOME:
            return action.payload.item;
        default:
            return state;
    }
};

export const stepItemToDelete = (
    state: IConfirmDeleteProcessStep['stepItemToDelete'] = null,
    action: UpsertCermProcessStepActionTypes
) => {
    switch (action.type) {
        case UpsertCermProcessStepActions.DELETE_PROCESS_STEP_CONFIRMED_FULFILLED:
        case UpsertCermProcessStepActions.CANCEL_DELETE_PROCESS_STEP:
            return null;
        case UpsertCermProcessStepActions.DELETE_PROCESS_STEP:
            return action.payload;
        default:
            return state;
    }
};

export const isInitialising = (
    state: ICermProcessStepState['isInitialising'] = INITIAL_STATE.isInitialising,
    action: UpsertCermProcessStepActionTypes
) => {
    switch (action.type) {
        case UpsertCermProcessStepActions.LOAD_PAGE_FULFILLED:
            return false;
        default:
            return state;
    }
};

export const cermProcessStepStateReducer = combineReducers<ICermProcessStepState>({
    isInitialising,
    cermProcessStep,
    actionPriorities,
    phases,
    pillars,
    services,
    processStepPriorities,
    isSubmitting,
});
