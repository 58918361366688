import React from 'react';
import { IRisk, RiskLevelIdSortable, RiskMitigationPlanStatus } from 'models/risk';
import useTranslate from 'translations/translation-utils';
import moment from 'moment';

export const getRiskLevel = (risk: IRisk) => {
    const translate = useTranslate();
    switch (risk.riskLevelIdSortable) {
        case RiskLevelIdSortable.High:
            return (
                <mark className="high">
                    {translate('RiskRegisterPage.RiskRegisterTable.RiskRegisterLevel.High')}
                </mark>
            );
        case RiskLevelIdSortable.Medium:
            return (
                <mark className="medium">
                    {translate('RiskRegisterPage.RiskRegisterTable.RiskRegisterLevel.Med')}
                </mark>
            );
        case RiskLevelIdSortable.Low:
            return (
                <mark className="low">
                    {translate('RiskRegisterPage.RiskRegisterTable.RiskRegisterLevel.Low')}
                </mark>
            );
        default:
            return (
                <mark className="low">
                    {translate('RiskRegisterPage.RiskRegisterTable.RiskRegisterLevel.Low')}
                </mark>
            );
    }
};

export const getRiskActionCountClass = (risk: IRisk, riskActionCount: number) => {
    if (riskActionCount > 0 && risk.reviewDate) {
        const days = moment.duration(moment(risk.reviewDate).diff(moment())).asDays();
        let colorClass = 'review-date-primary';

        if (days < 0) {
            colorClass = 'review-date-error'
        } else if (days < 7) {
            colorClass = 'review-date-warning';
        }

        return 'badge review-date-counter ' + colorClass;
    }

    return '';
};

export const getRiskActionCount = (risk: IRisk) => {
    if (risk.mitigationPlans === null || risk.mitigationPlans.length === 0) {
        return 0;
    }

    const openMitigationPlans = risk.mitigationPlans.filter(
        x => x.status !== RiskMitigationPlanStatus.Completed
    );
    if (openMitigationPlans === null || openMitigationPlans.length === 0) {
        return 0;
    }

    const defaultPlan = openMitigationPlans[0];
    if (defaultPlan.actions === null || defaultPlan.actions.length === 0) {
        return 0;
    }

    return defaultPlan.actions.filter(x => x.isActive && x.status !== 'Closed').length;
};

export const getRiskReviewDays = (risk: IRisk) => {
    if (risk.reviewDate) {
        return moment.duration(moment(risk.reviewDate).diff(moment())).asDays();
    }

    return null;
};
