import { connect } from 'react-redux';

import { ChangeLinkConfirmation as View } from '../../common/change-link-confirmation';
import actions from './actions';
import selector from './selector';

export const ClientLinkChangeConfirmation = connect(
    selector,
    actions
)(View);
