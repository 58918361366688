import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { ClientMitigationPlan } from './client-mitigation-plan';

const getImpacts = (state: IRootState) => state.app.lookupRiskImpacts;
const getLikelihoods = (state: IRootState) => state.app.lookupRiskLikelihoods;

const mapStateToProps = createSelector(getImpacts, getLikelihoods, (impacts, likelihoods) => ({
    impacts,
    likelihoods,
}));

export const ClientMitigationPlanContainer = connect(mapStateToProps, null)(ClientMitigationPlan);
