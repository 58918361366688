export const easeInScrollToTop = () => {
    easeInScrollTo(0);
};

export const easeInScrollTo = (y: number) => {
    if (
        navigator.userAgent.indexOf('MSIE') !== -1 ||
        navigator.appVersion.indexOf('Trident/') > -1
    ) {
        window.scrollTo(0, y);
        return;
    }

    window.scroll({
        top: y,
        left: 0,
        behavior: 'smooth'
    });
};
