import * as React from 'react';
import { OpportunityQuestionSetList } from './opportunity-questions/opportunity-questionnaire-list';
import { Loader } from 'components/loader';
import { IOpportunity, IOpportunityAnswer } from 'models/opportunity-question-set';
import useTranslate from 'translations/translation-utils';

interface IProps {
    siteId: string;
    isLoading: boolean;
    opportunityQuestions: IOpportunity[];
    opportunityAnswers: IOpportunityAnswer[];
    loadOpportunityQuestions: (query?: any) => void;
    setOpportunityQuestionnaire: (model) => void;
    setIsCompleted: React.Dispatch<React.SetStateAction<boolean>>;
}

export const OpportunityQuestionnaire: React.FC<IProps> = ({ siteId, ...props }) => {
    const translate = useTranslate();
    const [curQuestionSets, setCurQuestionSets] = React.useState<IOpportunity[]>([]);
    const [opportunityquestionAnswers, setQestionAnswers] = React.useState([]);

    React.useEffect(() => {
        if (!siteId) {
            return;
        }
        setCurQuestionSets([]);
        setQestionAnswers([]);
        props.loadOpportunityQuestions({ draft: false });
    }, [siteId]);

    React.useEffect(() => {
        setCurQuestionSets(props.opportunityQuestions);
    }, [props.opportunityQuestions]);

    React.useEffect(() => {
        if (props.opportunityAnswers) {
            setQestionAnswers(props.opportunityAnswers);
        }
    }, [props.opportunityAnswers]);

    React.useEffect(() => {
        const elements = document.getElementsByClassName('multi-select__single-value');

        const completed = Array.from(elements).some(
            (el: any) => el.innerHTML === translate('OpportunityQ.Dropdown.DefaultLabel')
        );
        props.setIsCompleted(!completed);
    });

    const searchTree = (element, guid) => {
        if (element.id == guid) {
            return element;
        } else if (element.questions != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.questions.length; i++) {
                result = searchTree(element.questions[i], guid);
            }
            return result;
        }
        return null;
    };

    const onQuestionAnswerChanged = (
        opportunityId: string,
        opportunitySetId: string,
        responseAnswer: string
    ) => {
        let opp = {} as any;
        for (let i = 0; i < props.opportunityQuestions.length; i++) {
            opp = searchTree(props.opportunityQuestions[i], opportunityId);
            if (opp) {
                break;
            }
        }

        //   const opp = searchTree(props.opportunityQuestions, opportunityId);

        const opportunityquestion = JSON.parse(JSON.stringify(opportunityquestionAnswers));

        if (
            opportunityquestion &&
            opportunityquestion.some((el) => el.opportunityId === opportunitySetId)
        ) {
            opportunityquestion.map((opportunityquestions) => {
                if (opportunityquestions.opportunityId === opportunitySetId) {
                    if (
                        opportunityquestions.questionAnswers.some((el) => el.id === opportunityId)
                    ) {
                        opportunityquestions.questionAnswers.map((que) => {
                            if (que.id === opportunityId) {
                                que.responseAnswer = responseAnswer;
                            }
                        });
                    } else {
                        opportunityquestions.questionAnswers.push({
                            id: opportunityId,
                            responseAnswer,
                            path: opp?.path,
                        });
                    }
                }
            });
        } else {
            if (opportunityquestion) {
                opportunityquestion.push({
                    opportunityId: opportunitySetId,
                    questionAnswers: [
                        {
                            id: opportunityId,
                            responseAnswer,
                            path: opp?.path,
                        },
                    ],
                });
            }
        }

        setQestionAnswers(opportunityquestion);
        props.setOpportunityQuestionnaire(opportunityquestion);
    };

    return (
        <Loader loading={props.isLoading}>
            <div className="tabs-container tab-content opportunity-question">
                <OpportunityQuestionSetList
                    {...props}
                    siteId={siteId}
                    opportunityQuestions={curQuestionSets}
                    onQuestionAnswerChanged={onQuestionAnswerChanged}
                    opportunityAnswers={props.opportunityAnswers}
                />
            </div>
        </Loader>
    );
};
