import { IProgressBarModel } from '../step-progress-bar/StepProgressBar';
import { Context } from './Context';
export abstract class State {
    public abstract result: IStateResult;
    public id: string;
    protected context: Context;
    public setContext(context: Context) {
        this.context = context;
    }
    public abstract handle(response: boolean): void;
    public abstract display(): JSX.Element;
    public abstract getProgressBarModel(): IProgressBarModel[];
}

export interface IStateResult {
    title: string;
    value: string;
}
