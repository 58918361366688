
import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';

const getPermissions = (state: IRootState) => state.app.permissions;

export default createSelector(
    getPermissions,
    (permissions) => ({
        permissions
    })
);
