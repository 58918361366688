
export interface IGridViewState<TItemType> {
    items: TItemType[];
    isLoading: boolean;
    sortColumn: string;
    sortAscending: boolean;
    secondarySortColumn: string;
    secondarySortAscending: boolean;
    expandedItems: string[];
    responseContinuation: string;
}

export const INITIAL_STATE: IGridViewState<any> = {
    items: [],
    isLoading: false,
    sortColumn: null,
    sortAscending: true,
    secondarySortColumn: null,
    secondarySortAscending: true,
    expandedItems: [],
    responseContinuation: null,
};
