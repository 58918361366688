/* eslint-disable @typescript-eslint/no-inferrable-types */
import { AlignmentOpts } from './constants';
import { ISortParams, IFilterParams } from './common.params';

/**
 * Represents the default inputs for a data table header.
 */
export class ColumnDefParams<T> implements IColumnDefParams<T> {
  /**
   * The unique identifier for the header.
   */
  idx?: string = `${Math.floor(Math.random() * 10000)}`;

  /**
   * The display name of the header.
   */
  headerName?: string;

  /**
   * The field name of the header from row data object.
   */
  field: keyof T & string = '' as keyof T & string;

  /**
   * The data type of the header.
   * @default 'text'
   */
  dataType?: string = 'text';

  /**
   * Indicates whether the header is hidden.
   * @default false
   */
  isHidden?: boolean = false;

  /**
   * The text alignment of the header.
   * @default AlignmentOpts.LEFT
   */
  textAlignment?: AlignmentOpts = AlignmentOpts.LEFT;

  /**
   * Additional CSS classes for the header.
   */
  cssClasses?: string;

  /**
   * The sort parameters for the header.
   */
  sortParams?: ISortParams;

  /**
   * The filter parameters for the header.
   */
  filterParams?: IFilterParams;

  /**
   * Indicates whether the header is resizable.
   */
  resizable?: boolean = true;

  /**
   * The cell template for the header.
   */
  headerTemplate?: any;

  /**
   * The cell template for the header.
   */
  cellTemplate?: any;

  /**
   * The children of the header.
   */
  children?: ColumnDefParams<T>[];

  /*
   * The field name to be filtered by
   */
  filteredBy?: string;

  /*
   * Width of the column
   */
  width?: string;

  /*
   * Min width of the column
   */
  minWidth?: string;

  /**
   * Indicates whether the header is editable.
   * @default false
   */
  editable?: boolean;
}

export interface IColumnDefParams<T> {
  /**
   * The unique identifier for the header.
   */
  idx?: string;

  /**
   * The display name of the header.
   */
  headerName?: string;

  /**
   * The field name of the header from row data object.
   */
  field: keyof T & string;

  /**
   * The data type of the header.
   */
  dataType?: string;

  /**
   * Indicates whether the header is hidden.
   */
  isHidden?: boolean;

  /**
   * The text alignment of the header.
   */
  textAlignment?: AlignmentOpts;

  /**
   * Additional CSS classes for the header.
   */
  cssClasses?: string;

  /**
   * The sort parameters for the header.
   */
  sortParams?: ISortParams;

  /**
   * The filter parameters for the header.
   */
  filterParams?: IFilterParams;

  /**
   * Indicates whether the header is resizable.
   */
  resizable?: boolean;

  /**
   * The cell template for the header.
   */
  headerTemplate?: any;

  /**
   * The cell template for the header.
   */
  cellTemplate?: any;

  /**
   * The children of the header.
   * @default []
   */
  children?: ColumnDefParams<T>[];

  /*
   * Width of the column
   */
  width?: string;

  /*
   * Min width of the column
   */
  minWidth?: string;

  /**
   * Indicates whether the header is editable.
   * @default false
   */
  editable?: boolean;
}
