import { compact, isNil } from 'lodash';
import { createSelector } from 'reselect';

import { IPerson } from 'models/person';
import { ISiteRelationshipsRootState } from 'reducers/siteRelationships';

const getPersonText = (person: IPerson) =>
    isNil(person)
        ? null
        : compact([
            person.firstName,
            person.lastName
        ]).join(' ');

export default createSelector(
    (state: ISiteRelationshipsRootState) => state.siteRelationships.deletePersonConfirmation,
    (state) => ({
        item: state.person,
        itemText: getPersonText(state.person),
        site: state.site,
        visible: Boolean(state.person && state.site)
    })
);
