import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router';
import { SideNavMenu } from '../sideNavMenu/SideNavMenu';
import { IKeyValue } from '../../models/key-value';
import { useWindowSize } from 'utils/window-size-utils';
import { SideNavSiteSelector } from '../sideNavSiteSelector/';
import INavItem from 'models/nav-item';
import { IMenu } from 'models/menu-model';
import appConfig from 'helpers/config-helper';
import { checkRole } from 'utils/permission-utils';

interface IStateProps extends RouteComponentProps<{}> {
    navItems: INavItem[];
    lookupSites?: Array<IKeyValue<string>>;
    siteId?: string;
    onLoadSite: (id: string) => void;
    onLoadMenu: (operatingPlatform: string) => void;
    menus: IMenu[];
    onSelectedMenuItem: (selectedMenuItem: string) => void;
    permissions: Array<IKeyValue<string>>;
}

export const SideNav: React.FC<IStateProps> = props => {
    const [sideNavStatus, setSideNavStatus] = useState('show');
    const [isMobileView, setIsMobileView] = useState(false);
    const { width } = useWindowSize();
    const isMobileViewRef = useRef(isMobileView);
    isMobileViewRef.current = isMobileView;
    const config = appConfig();

    useEffect(() => {
        setSideNavView();
    }, [props.siteId, props.match, width, isMobileView]);

    const setSideNavView = () => {
        if (width < 770) {
            setIsMobileView(true);
            setSideNavStatus('');
        } else {
            setIsMobileView(false);
        }
    };

    const onSideNavClick = () => {
        setSideNavStatus(sideNavStatus === '' ? 'show' : '');
    };

    const onMenuItemClick = () => {
        if (isMobileViewRef.current) {
            setSideNavStatus('');
        }
    };

    const getSiteName = () => {
        const siteResult = props.lookupSites.find(site => site.key === props.siteId);
        const siteName = siteResult ? siteResult.value : '';
        const path = getRelativePath();
        const navItemResult = findNavItem(props.navItems, path);
        const navItemName = navItemResult ? navItemResult.text : '';

        return `${siteName} ${navItemName}`;
    };

    const getRelativePath = () => {
        const paths = window.location.pathname.split('/');
        return paths && paths.length > 1 ? `/${paths[1]}` : '/';
    };

    const findNavItem = (navItems: INavItem[], link: string): INavItem => {
        for (const navItem of navItems) {
            if (navItem.link === link) {
                return navItem;
            } else if (navItem.children) {
                const findResult = findNavItem(navItem.children, link);
                if (findResult) {
                    return findResult.isNotSingleSiteRelated ? null : findResult;
                }
            }
        }
        return null;
    };

    const isClientRole = checkRole('Client', props.permissions);

    return (
        <div className={'sidenav ' + sideNavStatus}>
            <div id="sidenav-button" className="sidenav-toggle" onClick={onSideNavClick}>
                <button>
                    <span className="bar" />
                    <span className="bar" />
                    <span className="bar" />
                </button>
            </div>
            <div className="sidenav-content">
                <div className="site-select">
                    <SideNavSiteSelector />
                </div>
                {config.REACT_APP_FEATURE_FLAG_DYNAMIC_MENU === 'true' && !isClientRole ? (
                    <SideNavMenu
                        adminNavMenuItems={props.navItems}
                        navMenuItems={props.menus}
                        onMenuItemClick={onMenuItemClick}
                        siteId={props.siteId}
                        onSelectedMenuItem={props.onSelectedMenuItem}
                    />
                ) : (

                    <SideNavMenu navItems={props.navItems} onMenuItemClick={onMenuItemClick} onSelectedMenuItem={props.onSelectedMenuItem} isClientRole={isClientRole} />
                )}
            </div>
            <div className="sidenav-vertical">{getSiteName()}</div>
        </div>
    );
};
