import { Action } from 'redux';
import { ICalendarItem } from 'models/calendar';

export enum CalendarActions {
    LOAD_CALENDAR_EVENTS = '@@calendar/LOAD_CALENDAR_EVENTS',
    LOAD_CALENDAR_EVENTS_FULFILLED = '@@calendar/LOAD_CALENDAR_EVENTS_FULFILLED',
    LOAD_CALENDAR_EVENTS_REJECTED = '@@calendar/LOAD_CALENDAR_EVENTS_REJECTED',
    LOAD_CALENDAR_EVENTS_CANCELLED = '@@calendar/LOAD_CALENDAR_EVENTS_CANCELLED',
    FILTER_CALENDAR_ITEMS = '@@calendar/FILTER_CALENDAR_ITEMS',
    LOAD_PAGE = '@@calendar/LOAD_PAGE',
    LOAD_PAGE_FULFILLED = '@@calendar/LOAD_PAGE_FULFILLED',
    LOAD_PAGE_REJECTED = '@@calendar/LOAD_PAGE_REJECTED',
    LOAD_PAGE_CANCELLED = '@@calendar/LOAD_PAGE_CANCELLED',
}

export interface ILoadPageAction extends Action {
    type: CalendarActions.LOAD_PAGE;
}

export interface ILoadPageRejectedAction extends Action {
    type: CalendarActions.LOAD_PAGE_REJECTED;
    error: true;
    payload: any;
}

export interface ILoadPageCancelledAction extends Action {
    type: CalendarActions.LOAD_PAGE_CANCELLED;
}

export interface ILoadPageFulfilledAction extends Action {
    type: CalendarActions.LOAD_PAGE_FULFILLED;
    payload: any;
}

export interface ILoadCalendarEventsAction extends Action {
    type: CalendarActions.LOAD_CALENDAR_EVENTS;
}

export interface IFilterCalendarEventsAction extends Action {
    type: CalendarActions.FILTER_CALENDAR_ITEMS;
    payload: {
        type: string;
    };
}

export interface ILoadCalendarEventsRejectedAction extends Action {
    type: CalendarActions.LOAD_CALENDAR_EVENTS_REJECTED;
    error: true;
    payload: any;
}

export interface ILoadCalendarEventsCancelledAction extends Action {
    type: CalendarActions.LOAD_CALENDAR_EVENTS_CANCELLED;
}

export interface ILoadCalendarEventsFulfilledAction extends Action {
    type: CalendarActions.LOAD_CALENDAR_EVENTS_FULFILLED;
    payload: {
        calendarItems: ICalendarItem[];
    };
}

export type CalendarActionTypes =
    | ILoadCalendarEventsAction
    | IFilterCalendarEventsAction
    | ILoadCalendarEventsRejectedAction
    | ILoadCalendarEventsCancelledAction
    | ILoadCalendarEventsFulfilledAction
    | ILoadPageAction
    | ILoadPageCancelledAction
    | ILoadPageRejectedAction
    | ILoadPageFulfilledAction;
