import classNames from 'classnames';
import * as React from 'react';
import { Control, Field } from '../form';
import { Checkbox } from 'components/form/Checkbox';
import useTranslate from 'translations/translation-utils';

export interface ICheckbox {
    fieldId: string;
    label: string;
    checked: boolean;
}

export enum CheckboxFilterBackgroundColor {
    reserved = 'reserved',
    highlighted = 'highlighted',
}

export interface IStateProps extends React.AllHTMLAttributes<HTMLDivElement> {
    fieldName: string;
    filters: ICheckbox[];
    background?: CheckboxFilterBackgroundColor;
}

export interface IActionProps {
    onFilter: (field: string, value: string) => any;
}

interface IProps extends IStateProps, IActionProps { }

export const CheckboxFilterSet: React.FC<IProps> = (props) => {
    const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        props.onFilter(props.fieldName, e.target.value);
    };

    let containerClass = null;
    if (props.background) {
        containerClass = `filter-fields--${props.background}`;
    }

    const css = classNames(['filter-fields', containerClass, props.className]);
    const translate = useTranslate();
    return (
        <div className={css}>
            {props.filters.map((filter) => {
                const id = `filters__${props.fieldName}__${filter.fieldId}`;
                return (
                    <Field key={id}>
                        <Control>
                            <Checkbox
                                id={id}
                                filterValue={filter.fieldId}
                                value={filter.fieldId}
                                checked={filter.checked}
                                onChange={onChange}
                            />
                            <label htmlFor={id}>
                                {translate('CheckboxFilter.Labels.', filter.label)}
                            </label>
                        </Control>
                    </Field>
                );
            })}
        </div>
    );
};
