import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import {
    getLookupActionCategories,
    getLookupActionPriorities,
    getLookupActionStatuses
} from 'sharedSelectors';
import { checkPermission } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';

const getSiteId = (state: IRootState) => state.app.siteId;
const getShowHelpModule = (state: IRootState) => state.app.showHelpModule;
const getIsExporting = (state: IRootState) => state.actionRegisterState.isExporting;
const getCanViewMacroActions = (state: IRootState) =>
    checkPermission(Privilege.RiskMacroLevelUpdate, state.app.permissions);
const getMacroActions = (state: IRootState) => state.actionRegisterState.macroActions;
export const getActionsState = createSelector(
    (state: IRootState) => state.actions,
    (state) => state
);

export default createSelector(
    getSiteId,
    getActionsState,
    getLookupActionCategories,
    getLookupActionPriorities,
    getLookupActionStatuses,
    getIsExporting,
    getShowHelpModule,
    getCanViewMacroActions,
    getMacroActions,
    (
        siteId,
        actionState,
        actionCategories,
        actionPriorities,
        actionStatuses,
        isExporting,
        showHelpModule,
        canViewMacroActions,
        macroActions
    ) => ({
        siteId,
        isLoading: actionState.grid.isLoading,
        actions: actionState.grid.items,
        actionCategory: actionCategories,
        actionStatus: actionStatuses,
        actionPriority: actionPriorities,
        isExporting,
        showHelpModule,
        canViewMacroActions,
        macroActions,
    })
);
