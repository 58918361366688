import { IKeyValue } from 'models/key-value';

export interface IMasterProcessStep {
    id: string;
    title: string;
    service: string;
    phase: string;
    pillar: string;
    priority: string;
    processNumber: number;
    reviewPeriodMonths: number;
    expectedOutcomes: IMasterOutcome[];
    status: string;
    riskTemplate: IRiskTemplate;
    cermProcessStepPlatform?: ICermProcessStepPlatform;
    cermTechnologyPlatform: string;
    applicable: boolean;
    operatingPlatform: string;
}

export interface ICermProcessStepPlatform {
    technologyPlatform: string;
    moreInfoLink: string;
    moreInfoDisplayLabel: string;
}
export interface IMasterOutcome {
    id: string;
    title: string;
    compliantResponse: boolean;
    actionOrEvent: ActionOrEvent;
    action: IActionTemplate;
    event: IEventTemplate;
}

export interface IActionTemplate {
    title: string;
    description: string;
    priority: string;
    categoryId: string;
    dueDateUnit: string;
    dueDateValue: string;
}

export interface IEventTemplate {
    title: string;
    recurrenceUnit: string;
    recurrenceValue: number | null;
    startDate: Date | string | null;
    numberOfOccurrences: number;
    outcomeId?: string;
    hasExistingEvents?: boolean;
    overwriteExistingEvents?: boolean;
}

export interface IRiskTemplate {
    title: string;
    description: string;
    siteId: string;
    riskLikelihoodId: string;
    riskImpactId: string;
    riskCategoryId: string;
    riskSubCategoryId: string;
    riskStatusId: string;
    reviewDays: number;
}

export enum ActionOrEvent {
    'Action',
    'Event',
}

export enum CermTechnologyPlatform {
    CustomersPlatforms = 'Customer\'s platforms',
    CustomersMSOfficeorEquivalent = 'Customer\'s MS Office or equivalent',
    OfflineSolutionPenandPaperWhiteboardBinders = 'Offline solution - pen and paper, whiteboard, binders',
    CBREsMSOfficeorEquivalent = ' CBRE\'s MS Office or equivalent',
    CBREsPlatforms = 'CBRE\'s platforms',
}
export const cermTechnologyPlatform: Array<IKeyValue<string>> = [
    {
        key: CermTechnologyPlatform.CustomersPlatforms,
        value: CermTechnologyPlatform.CustomersPlatforms,
        label: CermTechnologyPlatform.CustomersPlatforms,
    },
    {
        key: CermTechnologyPlatform.CustomersMSOfficeorEquivalent,
        value: CermTechnologyPlatform.CustomersMSOfficeorEquivalent,
        label: CermTechnologyPlatform.CustomersMSOfficeorEquivalent,
    },
    {
        key: CermTechnologyPlatform.OfflineSolutionPenandPaperWhiteboardBinders,
        value: CermTechnologyPlatform.OfflineSolutionPenandPaperWhiteboardBinders,
        label: CermTechnologyPlatform.OfflineSolutionPenandPaperWhiteboardBinders,
    },
    {
        key: CermTechnologyPlatform.CBREsMSOfficeorEquivalent,
        value: CermTechnologyPlatform.CBREsMSOfficeorEquivalent,
        label: CermTechnologyPlatform.CBREsMSOfficeorEquivalent,
    },
    {
        key: CermTechnologyPlatform.CBREsPlatforms,
        value: CermTechnologyPlatform.CBREsPlatforms,
        label: CermTechnologyPlatform.CBREsPlatforms,
    },
];
