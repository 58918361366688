import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from './../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { RiskProfileQuestionnaireActions } from 'actions/actions-v2';
import { IRiskProfileMasterQuestionnaire } from 'models';

const controllerName = 'riskProfileMasterQuestionnaire';

const updateActions = {
    action: RiskProfileQuestionnaireActions.UPDATE_RISK_PROFILE_MASTER_QUESTIONNAIRE,
    actionFulfilled: RiskProfileQuestionnaireActions.UPDATE_RISK_PROFILE_MASTER_QUESTIONNAIRE_FULFILLED,
    actionRejected: RiskProfileQuestionnaireActions.UPDATE_RISK_PROFILE_MASTER_QUESTIONNAIRE_REJECTED
} as IActionType;

const createActions = {
    action: RiskProfileQuestionnaireActions.CREATE_RISK_PROFILE_MASTER_QUESTIONNAIRE,
    actionFulfilled: RiskProfileQuestionnaireActions.CREATE_RISK_PROFILE_MASTER_QUESTIONNAIRE_FULFILLED,
    actionRejected: RiskProfileQuestionnaireActions.CREATE_RISK_PROFILE_MASTER_QUESTIONNAIRE_REJECTED
} as IActionType;

const getActions = {
    action: RiskProfileQuestionnaireActions.LOAD_RISK_PROFILE_MASTER_QUESTIONNAIRE,
    actionFulfilled: RiskProfileQuestionnaireActions.LOAD_RISK_PROFILE_MASTER_QUESTIONNAIRE_FULFILLED,
    actionRejected: RiskProfileQuestionnaireActions.LOAD_RISK_PROFILE_MASTER_QUESTIONNAIRE_REJECTED
} as IActionType;

const getOverviewActions = {
    action: RiskProfileQuestionnaireActions.LOAD_RISK_PROFILE_MASTER_OVERVIEW,
    actionFulfilled: RiskProfileQuestionnaireActions.LOAD_RISK_PROFILE_MASTER_QUESTIONNAIRE_FULFILLED,
    actionRejected: RiskProfileQuestionnaireActions.LOAD_RISK_PROFILE_MASTER_QUESTIONNAIRE_REJECTED
} as IActionType;

const updateRiskProfileMasterQuestionnaireEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateActions).put<IRiskProfileMasterQuestionnaire>(`/api/${controllerName}`);
const loadRiskProfileMasterQuestionnaireEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getActions).getById<IRiskProfileMasterQuestionnaire[]>(`/api/${controllerName}`);
const loadRiskProfileMasterOverviewEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getOverviewActions).get<IRiskProfileMasterQuestionnaire[]>(`/api/${controllerName}`);
const createRiskProfileMasterQuestionnaireEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createActions).post<IRiskProfileMasterQuestionnaire>(`/api/${controllerName}`);

export const riskProfileMasterQuestionnaireEpicsV2 = combineEpics(
    updateRiskProfileMasterQuestionnaireEpic,
    loadRiskProfileMasterQuestionnaireEpic,
    loadRiskProfileMasterOverviewEpic,
    createRiskProfileMasterQuestionnaireEpic
);
