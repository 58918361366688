import * as React from 'react';
import { onRouteChange } from 'actions/app-actions';

interface IClientBackToAllSitesProps {
    siteId: string;
    onSiteChange: (siteId: string) => void;
}

export const ClientBackToAllSitesPage: React.FC<IClientBackToAllSitesProps> = ({ siteId }) => {
    React.useEffect(() => {
        if (!siteId) {
            onRouteChange('/ClientSitesOverview');
        }
    }, [siteId]);

    const getRender = () => <div/>;

    return getRender();
};
