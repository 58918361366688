import {
  EmeraldTooltipCoreInputs,
  IEmeraldTooltipCoreInputs,
} from '../tooltip/tooltip.inputs';
export class EmeraldAccordionCoreInputs implements IEmeraldAccordionCoreInputs {
  id?: string = Math.random().toString(36).substring(7);
  title?: string;
  tabIndex?: number = 0;
  open?: boolean = false;
  openIcon?: string = 'expand_less';
  closeIcon?: string = 'expand_more';
  iconPosition?: 'left' | 'right' = 'right';
  disabled?: boolean = false;
  tooltip?: string;
  tooltipOptions?: EmeraldTooltipCoreInputs;
  titleTemplate?: any;
  tooltipTemplate?: any;
  buttonTemplate?: any;
  onInfoIconClick?: any;
}
export interface IEmeraldAccordionCoreInputs {
  id?: string;
  title?: string;
  tabIndex?: number;
  open?: boolean;
  openIcon?: string;
  closeIcon?: string;
  iconPosition?: 'left' | 'right';
  disabled?: boolean;
  tooltip?: string;
  tooltipOptions?: IEmeraldTooltipCoreInputs;
  titleTemplate?: any;
  tooltipTemplate?: any;
  buttonTemplate?: any;
  onInfoIconClick?: any;
}
