import { IAppAction } from '../app-action';
import { IQuestionSet, IQuestionSetFilters } from 'models/question-set';


export enum QuestionSetActions {
    LOAD_LIST = '@@QuestionSet/LOAD_LIST',
    LOAD_LIST_FULFILLED = '@@QuestionSet/LOAD_LIST_FULFILLED',
    LOAD_LIST_REJECTED = '@@QuestionSet/LOAD_LIST_REJECTED',

    LOAD = '@@QuestionSet/LOAD',
    LOAD_FULFILLED = '@@QuestionSet/LOAD_FULFILLED',
    LOAD_REJECTED = '@@QuestionSet/LOAD_REJECTED',

    CREATE = '@@QuestionSet/CREATE',
    CREATE_FULFILLED = '@@QuestionSet/CREATE_FULFILLED',
    CREATE_REJECTED = '@@QuestionSet/CREATE_REJECTED',

    UPDATE = '@@QuestionSet/UPDATE',
    UPDATE_FULFILLED = '@@QuestionSet/UPDATE_FULFILLED',
    UPDATE_REJECTED = '@@QuestionSet/UPDATE_REJECTED',

    DELETE = '@@QuestionSet/DELETE',
    DELETE_FULFILLED = '@@QuestionSet/DELETE_FULFILLED',
    DELETE_REJECTED = '@@QuestionSet/DELETE_REJECTED',

    CLEANUP = '@@QuestionSet/CLEANUP',

    DOWNLOAD = '@@QuestionSet/DOWNLOAD',
    DOWNLOAD_FULFILLED = '@@QuestionSet/DOWNLOAD_FULFILLED',
    DOWNLOAD_REJECTED = '@@QuestionSet/DOWNLOAD_REJECTED',
}

export const loadQuestionSets = (filters: IQuestionSetFilters): IAppAction => ({
    type: QuestionSetActions.LOAD_LIST,
    payload: filters
});

export const loadQuestionSet = (id: string): IAppAction => ({
    type: QuestionSetActions.LOAD,
    payload: id
});

export const createQuestionSet = (model: IQuestionSet): IAppAction => ({
    type: QuestionSetActions.CREATE,
    payload: model
});

export const updateQuestionSet = (model: IQuestionSet): IAppAction => ({
    type: QuestionSetActions.UPDATE,
    payload: model
});

export const deleteQuestionSet = (id: string, reloadUrl: string): IAppAction => ({
    type: QuestionSetActions.DELETE,
    payload: { id, reloadUrl }
});

export const cleanUpQuestionSet = (): IAppAction => ({
    type: QuestionSetActions.CLEANUP
});

export const downloadQuestionSet = (operatingPlatform: string): IAppAction => ({
    type: QuestionSetActions.DOWNLOAD,
    payload: { operatingPlatform }
});