import React, { useEffect, useState } from 'react';
import { AsidePanel } from '../../../components/panel';
import {
    IOpportunity,
    IOpportunityQuestion,
    IOpportunityAdvice,
} from 'models/opportunity-question-set';
import TreeMenu, { TreeNodeInArray } from 'react-simple-tree-menu';
import { isEmpty } from 'lodash';
import 'react-simple-tree-menu/dist/main.css';
import useTranslate from 'translations/translation-utils';

export interface IProps {
    title?: string;
    subtitle?: string;
    opportunity: Partial<IOpportunity>;
    isDirty: boolean;
    activePath: string;
    onNavigate: (pathValue: string) => void;
    setShouldBlockNavigation: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IActions {
    onChangeRoot: (urlLocation: string) => void;
}

export const OpportunityQuestionHierarchy: React.FC<IProps> = ({
    title,
    opportunity,
    onNavigate,
    activePath,
    isDirty,
    setShouldBlockNavigation,
}) => {
    const translate = useTranslate();
    const [nodes, setNodes] = useState([] as TreeNodeInArray[]);
    const [openNodes, setOpenNodes] = useState(['master']);
    const [activeKey, setActiveKey] = useState('master');
    useEffect(() => {
        if (!isEmpty(opportunity)) {
            const tree = [
                {
                    key: 'master',
                    label: opportunity.title,
                    path: 'master',
                    nodes: generateNodes(opportunity.questions, opportunity.advices),
                },
            ];

            setNodes(tree);
        }
    }, [opportunity]);

    useEffect(() => {
        if (activePath && activePath.slice(-1) !== '-') {
            setActiveKey(activePath);
        }
    }, [activePath]);

    useEffect(() => {
        setShouldBlockNavigation(isDirty);
    }, [isDirty]);

    const getKey = (path: string) => {
        const keys = path.split('/');
        const last = keys[keys.length - 1];
        return last;
    };

    const generateNodes = (
        questions: Partial<IOpportunityQuestion>[],
        advices: Array<IOpportunityAdvice> = null
    ): TreeNodeInArray[] => {
        const result =
            questions &&
            questions.map((value) => {
                if (value) {
                    const node = {
                        key: getKey(value.path),
                        label: `${translate('OpportunityQ.QuestionSet.QuestionLabel')} - ${value.title ? value.title : ''}`,
                        path: value.path,
                        nodes: generateNodes(value.questions, value.advices),
                    };

                    if (value.path.slice(-1) !== '-' && openNodes.indexOf(value.path) === -1) {
                        setOpenNodes([...openNodes, value.path]);
                    }

                    return node;
                }
            });

        if (advices && advices.length > 0) {
            const adv = advices.map((value) => {
                if (value) {
                    return {
                        key: getKey(value.path),
                        label: `${translate('OpportunityQ.Title.Advice')} - ${value.title ? value.title : ''}`,
                        path: value.path,
                        nodes: [],
                    };
                }
            });
            return result ? [...result, ...adv] : adv;
        }
        return result ? result : [];
    };

    return (
        <AsidePanel className="column is-3">
            <header>
                <h2 className="title is-4">{title}</h2>
            </header>
            <div className="opportunity-hierarchy">
                <TreeMenu
                    data={nodes}
                    onClickItem={({ path }) => onNavigate(path)}
                    hasSearch={false}
                    resetOpenNodesOnDataUpdate={true}
                    openNodes={openNodes}
                    activeKey={activeKey}
                />
            </div>
        </AsidePanel>
    );
};
