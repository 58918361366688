import { IAppAction } from './../app-action';
import { IRole } from 'reducers/manageRoles/manageRoles-grid';
import { IRoleFilters } from 'models/role-filters';

export enum RoleActions {
    LOAD_ROLES = '@@role/LOAD_ROLES',
    LOAD_ROLES_FULFILLED = '@@role/LOAD_ROLES_FULFILLED',
    LOAD_ROLES_REJECTED = '@@role/LOAD_ROLES_REJECTED',

    LOAD_PRIVILEGE_ROLES = '@@role/LOAD_PRIVILEGE_ROLES',
    LOAD_PRIVILEGE_ROLES_FULFILLED = '@@role/LOAD_PRIVILEGE_ROLES_FULFILLED',
    LOAD_PRIVILEGE_ROLES_REJECTED = '@@role/LOAD_PRIVILEGE_ROLES_REJECTED',

    LOAD_PRIVILEGES = '@@role/LOAD_PRIVILEGES',
    LOAD_PRIVILEGES_FULFILLED = '@@role/LOAD_PRIVILEGES_FULFILLED',
    LOAD_PRIVILEGES_REJECTED = '@@role/LOAD_PRIVILEGES_REJECTED',

    LOAD_ROLE = '@@role/LOAD_ROLE',
    LOAD_ROLE_FULFILLED = '@@role/LOAD_ROLE_FULFILLED',
    LOAD_ROLE_REJECTED = '@@role/LOAD_ROLE_REJECTED',

    CREATE_ROLE = '@@role/CREATE_ROLE',
    CREATE_ROLE_FULFILLED = '@@role/CREATE_ROLE_FULFILLED',
    CREATE_ROLE_REJECTED = '@@role/CREATE_ROLE_REJECTED',

    UPDATE_ROLE =  '@@role/UPDATE_ROLE',
    UPDATE_ROLE_FULFILLED = '@@role/UPDATE_ROLE_FULFILLED',
    UPDATE_ROLE_REJECTED = '@@role/UPDATE_ROLE_REJECTED',

    SORT_ROLES = '@@role/SORT_ROLES',
}

export const loadRoles = (filters: IRoleFilters): IAppAction => ({
    type: RoleActions.LOAD_ROLES,
    payload: filters
});

export const loadPrivileges = (privilegeNames: string[]): IAppAction => ({
    type: RoleActions.LOAD_PRIVILEGES,
    payload: privilegeNames
});

export const loadPrivilegeRoles = (privilegeId: string): IAppAction => ({
    type: RoleActions.LOAD_PRIVILEGE_ROLES,
    payload: privilegeId
});

export const loadRole = (id: string): IAppAction => ({
    type: RoleActions.LOAD_ROLE,
    payload: id
});

export const createRole = (role: IRole): IAppAction => ({
    type: RoleActions.CREATE_ROLE,
    payload: role
});

export const updateRole = (role: IRole): IAppAction => ({
    type: RoleActions.UPDATE_ROLE,
    payload: role
});

export const onSort = (key: Extract<keyof IRole, string>, sortAscending: boolean) => ({
    type: RoleActions.SORT_ROLES,
    payload: { key, sortAscending }
});
