import * as React from 'react';
import { onRouteChange } from 'actions/app-actions';
import { GridSortLink } from 'components/gridSortLink';
import { PermissionWrapper } from 'components/permission-wrapper';
import {
    Timeline,
    TimelineHeader,
    TimelineHeaderGridValue,
    TimelineHeaderGridValueCollection,
    TimelineItem
} from 'components/timeline';
import { SortDirection } from 'enums/SortDirection';
import { IBuilding } from 'models/building';
import { RouteUrlSite } from 'routes/upsert-site/routes';
import { ISiteProfile } from '../../../../models/site-profile';
import './site-profile-list.scss';
import InfiniteScroll from 'react-infinite-scroller';
import { Loader } from 'components/loader';
import useTranslate from 'translations/translation-utils';

export interface ISiteProfileListStateProps {
    items: ISiteProfile[];
    isLoading: boolean;
    sortColumn: string;
    sortAscending: boolean;
    expandedItems: string[];
    hasMoreItems: boolean;
}

export interface ISiteProfileListDispatchProps {
    onSort: (sortColumn: string, sortAscending: boolean) => void;
    onToggleExpanded: (id: string) => void;
    loadMoreSites: () => void;
}

interface IActionListProps extends ISiteProfileListStateProps, ISiteProfileListDispatchProps { }

export const SiteProfileList: React.FC<IActionListProps> = (props) => {
    const translate = useTranslate();

    const getSumValue = (name: Extract<keyof IBuilding, string>, currentSite: ISiteProfile) => {
        const hasFacilities = currentSite && currentSite.buildings && currentSite.buildings.length > 0;
        return hasFacilities
            ? currentSite.buildings
                .filter((f) => f[name])
                .map((c) => c[name])
                .reduce((sum: number, r: number) => sum + r, 0)
            : 0;
    };

    const getBuildingAddressString = (building: Partial<IBuilding>) => {
        const addressStrings = [];
        if (building.addressLine1) {
            addressStrings.push(building.addressLine1);
        }
        if (building.city) {
            addressStrings.push(building.city);
        }
        if (building.postCode) {
            addressStrings.push(building.postCode);
        }
        if (building.country) {
            addressStrings.push(building.country);
        }
        return addressStrings.join(', ');
    };

    const getSiteProfileItem = (
        site: ISiteProfile,
        isToggleExpanded: boolean,
        onToggleExpanded: () => void,
        loadAndNavigate: (id: string) => void
    ) => (
        <Timeline expanded={isToggleExpanded} key={`${site.siteId}__${site.id}`}>
            <TimelineHeader
                centered={true}
                expanded={isToggleExpanded}
                onToggleExpanded={onToggleExpanded}
            >
                <TimelineHeaderGridValueCollection>
                    <TimelineHeaderGridValue className="timeline-title column is-5" label={translate('SiteProfile.Grid.Columns.SiteName')}>
                        {site.clientAndSiteName}
                    </TimelineHeaderGridValue>

                    <TimelineHeaderGridValue className="column is-2" label={translate('SiteProfile.Grid.Columns.Country')}>
                        {site.country}
                    </TimelineHeaderGridValue>

                    <TimelineHeaderGridValue className="column is-2" label={translate('SiteProfile.Grid.Columns.NumberOfFacilities')}>
                        {site.numOfBuildings}
                    </TimelineHeaderGridValue>

                    <TimelineHeaderGridValue className="column is-2" label={translate('SiteProfile.Grid.Columns.UpsPower')}>
                        <>{getSumValue('upsPowerCapacity_MW', site)}</>
                    </TimelineHeaderGridValue>
                    <PermissionWrapper permissionToCheck="SiteUpdate">
                        <TimelineHeaderGridValue className="column is-1">
                            <button
                                onClick={loadAndNavigate.bind(this, site.id)}
                                className="button button is-medium is-primary is-pulled-left"
                            >
                                {translate('Globals.Label.Edit')}
                            </button>
                        </TimelineHeaderGridValue>
                    </PermissionWrapper>
                </TimelineHeaderGridValueCollection>
            </TimelineHeader>
            {isToggleExpanded &&
                site.buildings &&
                site.buildings.map((build) => {
                    return (
                        <>
                            <TimelineItem>
                                <div className="columns">
                                    <div className="column is-4 is-gapless grid__label">
                                        {translate('SiteProfile.Grid.Item.FacilityName')}: {build.buildingName}
                                    </div>
                                    <div className="column is-3 grid__label">
                                        {translate('SiteProfile.Grid.Item.Type')}: {build.facility}
                                    </div>
                                    <div className="column is-5 grid__label">
                                        {translate('SiteProfile.Grid.Item.Address')}: {getBuildingAddressString(build)}
                                    </div>
                                </div>
                            </TimelineItem>
                        </>
                    );
                })}
        </Timeline>
    );

    const getGridHeader = (label: string, fieldName: string, props: IActionListProps) => {
        const onSort = (sortField: string, sortDirection: SortDirection) =>
            props.onSort(sortField, sortDirection === SortDirection.Ascending);

        return (
            <GridSortLink
                currentSortFieldName={props.sortColumn}
                currentSortDirection={
                    props.sortAscending ? SortDirection.Ascending : SortDirection.Descending
                }
                sortFieldName={fieldName}
                onSort={onSort}
            >
                {label}
            </GridSortLink>
        );
    };

    const loadAndNavigate = (id: string) => {
        onRouteChange(`${RouteUrlSite(id)}`);
    };

    const loadMore = (): any => {
        if (!props.isLoading) {
            props.loadMoreSites();
        }
    };

    return (
        <div className={`grid-view ${props.isLoading ? 'grid-view--is-loading' : ''}`}>
            <div className="Timeline-offset grid-headers columns is-hidden-mobile">
                <div className="column is-5">
                    {getGridHeader(translate('SiteProfile.Grid.Columns.SiteName'), 'clientAndSiteName', props)}
                </div>
                <div className="column is-2">{getGridHeader(translate('SiteProfile.Grid.Columns.Country'), 'country', props)}</div>
                <div className="column is-2">
                    {getGridHeader(translate('SiteProfile.Grid.Columns.NumberOfFacilities'), 'numOfBuildings', props)}
                </div>
                <div className="column is-2">
                    {getGridHeader(translate('SiteProfile.Grid.Columns.UpsPower'), 'totalMainsPowerCapacity', props)}
                </div>
                <PermissionWrapper permissionToCheck="SiteUpdate">
                    <div className="column is-1">
                        {translate('SiteProfile.Grid.Columns.Action')}
                    </div>
                </PermissionWrapper>
            </div>
            <InfiniteScroll
                loadMore={loadMore}
                hasMore={props.hasMoreItems}
                loader={<Loader loading={true} />}
            >
                {props.items.map((site) =>
                    getSiteProfileItem(
                        site,
                        props.expandedItems.includes(site.id),
                        () => props.onToggleExpanded(site.id),
                        loadAndNavigate
                    )
                )}
            </InfiniteScroll>
        </div>
    );
};
