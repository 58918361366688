import * as React from 'react';
import { Link } from 'react-router-dom';
import { Loader } from '../loader';

interface ICircleProps {
    numberRisks: number;
    titleLineOne: string;
    titleLineTwo: string;
    link: string;
    colour: string;
    isLoading: boolean;
}

export const CircleDataDisplayer: React.FC<ICircleProps> = ({numberRisks, titleLineOne, titleLineTwo, link, colour, isLoading}) => {
    return (
        <Link to={link} className="circle-data-displayer">
        <div className={`circle colour-${colour}`}>
        <Loader loading={isLoading}>
            <div className="circle-container">
                <div className="inner-number">
                    {isLoading ? '' : (numberRisks < 1000 ? numberRisks : '999+')}
                </div>
                {titleLineTwo && <div className="inner-description">
                    <div className="inner-description-two">{isLoading ? '' : titleLineOne}</div>
                    <div className="inner-description-two">{isLoading ? '' : titleLineTwo}</div>
                </div>}
                {!titleLineTwo && <div className="inner-description">{isLoading ? '' : titleLineOne}</div>}
            </div>
        </Loader>
        </div>
    </Link>
    );
};
