import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { getUserId, getCurrentSiteId, getCurrentLanguage, getCurrentSiteName, getUserEmailAddress } from 'helpers/helpers';
import appConfig from 'helpers/config-helper';
const config = appConfig();

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
    config: {
        instrumentationKey: config.REACT_APP_INSIGHT_INSTRUMENTAION_KEY,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
        },
        enableAutoRouteTracking:true,
        autoTrackPageVisitTime: true,
        disableFetchTracking: false,
    },
});
ai.loadAppInsights();
ai.trackPageView();

ai.addTelemetryInitializer((envelope) => {
        var telemetryItem = envelope.baseData;
        telemetryItem.properties = telemetryItem.properties || {}
        telemetryItem.properties["userId"] = getUserId()??''
        telemetryItem.properties["siteId"] = getCurrentSiteId()??''
        telemetryItem.properties["language"] = getCurrentLanguage()??''
        telemetryItem.properties["siteName"] = getCurrentSiteName()??''
        telemetryItem.properties["userEmail"] = getUserEmailAddress()??''
    });


export default (Component) => withAITracking(reactPlugin, Component);
export const appInsights = ai.appInsights;
