import {
    faAngleDown,
    faAngleUp,
    faArrowDown,
    faArrowUp,
    faCheck,
    faEdit,
    faEllipsisH,
    faMinus,
    faPlus,
    faPlusCircle,
    faSearch,
    faTimes,
    faTimesCircle,
    faTrash,
    faUpload,
    faWrench,
    faLanguage,
    IconDefinition,
    faGlobe,
    faExpandArrowsAlt,
    faCalendarAlt,
    faEyeSlash,
    faEye,
    faQuestionCircle,
    faHome,
    faQuestion,
    faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';

export enum IconType {
    AngleDown = 'fa-angle-down',
    AngleUp = 'fa-angle-up',
    ArrowDown = 'fa-arrow-down',
    ArrowUp = 'fa-arrow-up',
    Check = 'fa-check',
    Edit = 'fa-edit',
    Ellipsis = 'fa-ellipsis-h',
    Minus = 'fa-minus',
    Plus = 'fa-plus',
    PlusCircle = 'fa-plus-circle',
    Search = 'fa-search',
    TimesCircle = 'fa-times-circle',
    Times = 'fa-times',
    Trash = 'trash-alt',
    Upload = 'fa-upload',
    Wrench = 'fa-wrench',
    Language = 'fa-language',
    Globe = 'fa-globe',
    FullScreen = 'fa-expand-arrows-alt',
    Calender = 'fa-calendar-alt',
    Hide = 'faEyeSlash',
    Show = 'faEye',
    questionCircle = 'faQuestionCircle',
    Home = 'fa-home',
    Question = 'fa-question',
    InfoCircle = 'fa-info-circle',
}

const typeMap: { [key: string]: IconDefinition } = {
    [IconType.AngleDown]: faAngleDown,
    [IconType.AngleUp]: faAngleUp,
    [IconType.ArrowDown]: faArrowDown,
    [IconType.ArrowUp]: faArrowUp,
    [IconType.Check]: faCheck,
    [IconType.Edit]: faEdit,
    [IconType.Ellipsis]: faEllipsisH,
    [IconType.Minus]: faMinus,
    [IconType.Plus]: faPlus,
    [IconType.PlusCircle]: faPlusCircle,
    [IconType.Search]: faSearch,
    [IconType.TimesCircle]: faTimesCircle,
    [IconType.Times]: faTimes,
    [IconType.Trash]: faTrash,
    [IconType.Upload]: faUpload,
    [IconType.Wrench]: faWrench,
    [IconType.Language]: faLanguage,
    [IconType.Globe]: faGlobe,
    [IconType.FullScreen]: faExpandArrowsAlt,
    [IconType.Calender]: faCalendarAlt,
    [IconType.Hide]: faEyeSlash,
    [IconType.Show]: faEye,
    [IconType.questionCircle]: faQuestionCircle,
    [IconType.Home]: faHome,
    [IconType.Question]: faQuestion,
    [IconType.InfoCircle]: faInfoCircle,
};

export const getIcon = (type: IconType): IconDefinition => typeMap[type];
