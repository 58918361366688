import { IAuditableItem } from './auditable-item';
import { IEmbeddedLink } from './embeddedLink';
import { IMoney } from './money';
import { ICBREApproval } from './cbre-approval';
import { IClientApproval } from './client-approval';
import { IKeyedItem } from './keyed-item';
import { IClientViewRecord } from './client-risk-view-record';
import { IUserEmbedded } from './user-embedded';
import { IRelatedEntity } from './related-entity';
import { IAction } from './action';
import { IKeyValue } from './key-value';
import { IAssetModel } from './asset/asset-model';

export interface IRisk extends IKeyedItem, IAuditableItem {
    changeRequestNo: string;
    refNo: string;
    seq: number;
    siteId: string;
    site: string;
    title: string;
    description: string;
    riskCategoryId: string;
    riskCategory: string;
    riskSubCategoryId: string;
    riskSubCategory: string;
    likelihoodId: string;
    likelihood: string;
    impactId: string;
    impact: string;
    riskLevelId: string;
    riskLevel: string;
    riskLevelScore: number;
    riskStatusId: string;
    riskStatus: string;
    origin: string;
    reviewDate: string;
    isOwnedByCustomer: boolean;
    isActive: boolean;
    closedByPerson: string;
    owner?: IEmbeddedLink;
    assignee?: IEmbeddedLink;
    assigneeId: string;
    linkedAttachmentsCount: number;
    linkedActionsCount: number;
    linkedBuildingsCount: number;
    linkedActionsCountDueIn7Days: number;
    parents: IEmbeddedLink[];
    cost: Partial<IMoney>;
    costAmount: number;
    cbreApproversDetails: IUserEmbedded[];
    cbreOptionalApprovers: string[];
    cbreMandatoryApprovers: string[];
    cbreApprovalToShare: ICBREApproval;
    sharedWithClient: boolean;
    clientApproversDetails: IUserEmbedded[];
    clientOptionalApprovers: string[];
    clientMandatoryApprovers: string[];
    clientApproval: IClientApproval;
    readByClient: boolean;
    clientSharedStatus: ClientSharedStatus;
    riskLevelIdSortable: RiskLevelIdSortable;
    clientViewRecords: IClientViewRecord[];
    masterProcessStepId: string;
    relatedEntity: IRelatedEntity;
    customerId?: string;
    contractId?: string;
    buildingId?: string;
    riskProfileQuestionId?: string;
    uid: string;
    mitigationLevel: string;
    mitigationAction: string;
    actions?: IAction[];
    mitigationPlans?: IRiskMitigationPlan[];
    macroLevel: string;
    macroLevelValues: Array<IKeyValue<string>>;
    isMacro: boolean;
    asset: IAssetModel;
    status?: string;
}

export enum RiskLevelIdSortable {
    Low = 0,
    Medium = 1,
    High = 2,
}

export enum RiskLikelihoodId {
    HighlyLikely = 5,
    Likely = 4,
    Possible = 3,
    Unlikely = 2,
    Rare = 1,
}

export enum RiskImpactId {
    Negligible = 1,
    Low = 2,
    Moderate = 3,
    Serious = 4,
    Critical = 5,
}

export enum ClientSharedStatus {
    InternalOnly = 0,
    ExternalRejected = 1,
    ExternalAwaitingConfirmation = 2,
    ExternalConfirmed = 3,
}

export enum ApprovalStatus {
    None = 0,
    Approved = 1,
    Accepted = 2,
}

export interface IClientRisksSummary {
    siteId: string;
    numberOpenPendingReview: number;
    numberOpenRiskAccepted: number;
    numberOpenRemedyAuthorised: number;
    numberOpen: number;
    numberClosed: number;
}

export interface IClientSiteSummary {
    siteId: string;
    siteName: string;
    numberOpenPendingReview: number;
    numberOpenRiskAccepted: number;
    numberOpenRemedyAuthorised: number;
    numberPartsBelowMinimum: number;
    numberPartsCloseToMinimum: number;
}

export interface IRiskMitigationPlan extends IKeyedItem, IAuditableItem {
    id: string;
    createdDate: string;
    dueDate?: string;
    estimatedCost?: number;
    riskLikelihoodId: string;
    riskImpactId: string;
    visibleToClient?: boolean;
    status: string;
    actions?: IAction[];
}

export enum RiskMitigationPlanStatus {
    Draft = 'Draft',
    Approved = 'Approved',
    Completed = 'Completed',
}
