import classnames from 'classnames';
import * as React from 'react';

export interface IProps {
    text: string;
    selected: boolean;
    onClick: () => void;
}

const css = (props: IProps) => classnames(
    'Typeahead__result',
    {
        ['Typeahead__result--selected']: props.selected
    }
);

export const TypeaheadResult: React.FC<IProps> = (props) => (
    <li className={css(props)}>
        <button className="Typeahead__result-action" onClick={props.onClick}>
            {props.text}
        </button>
    </li>
);
