import { IAppAction } from '../app-action';

export enum ExternalBuildingsActions {
    LOAD_ELOG_BOOKS_BUILDINGS = '@@ExternalBuildings/LOAD_ELOG_BOOKS_BUILDINGS',
    LOAD_ELOG_BOOKS_BUILDINGS_FULFILLED = '@@ExternalBuildings/LOAD_ELOG_BOOKS_BUILDINGS_FULFILLED',
    LOAD_ELOG_BOOKS_BUILDINGS_REJECTED = '@@ExternalBuildings/LOAD_ELOG_BOOKS_BUILDINGS_REJECTED',

    CLEAN_UP_BUILDINGS = '@@ExternalBuildings/CLEAN_UP_BUILDINGS',
}

export const loadExternalBuildings = (keyword: string): IAppAction => ({
    type: ExternalBuildingsActions.LOAD_ELOG_BOOKS_BUILDINGS,
    payload: { keyword: keyword }
});
