import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { IauditorActions } from 'actions/actions-v2/iauditor-action';
import { IAuditorTemplate } from 'models/iauditor';

const controllerName = 'Inspection';
const apiPath = `/api/${controllerName}`;

const getListActions = {
    action: IauditorActions.LOAD_IAUDITOR_COMPLIANCE_CHECKLIST,
    actionFulfilled: IauditorActions.LOAD_IAUDITOR_COMPLIANCE_CHECKLIST_FULFILLED,
    actionRejected: IauditorActions.LOAD_IAUDITOR_COMPLIANCE_CHECKLIST_REJECTED,
} as IActionType;

const getTemplateActions = {
    action: IauditorActions.LOAD_IAUDITOR_TEMPLATE,
    actionFulfilled: IauditorActions.LOAD_IAUDITOR_TEMPLATE_FULFILLED,
    actionRejected: IauditorActions.LOAD_IAUDITOR_TEMPLATE_REJECTED,
} as IActionType;

const loadComplianceCheckListEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getListActions).getByIdAndShard(`${apiPath}/siteCode`);

const loadTemplateEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getTemplateActions).getByIdAndShard<IAuditorTemplate[]>(`${apiPath}/template`);
export const IauditorEpicsV2 = combineEpics(
    loadComplianceCheckListEpic,
    loadTemplateEpic
);
