import { Dictionary } from 'lodash';
import { combineReducers } from 'redux';

import { RiskActions, RiskActionTypes } from '../../actions/risk-actions';
import { IRisk } from '../../models/risk';
import { IGridViewState, INITIAL_STATE as INITIAL_GRID_STATE } from '../grid';
import { RiskActions as RiskActionsV2} from 'actions/actions-v2/risk-action-v2';
import { IAppAction } from 'actions/app-action';
import { genericSort } from 'utils/sort-utils';

export interface IRisksGridState extends IGridViewState<IRisk> {
    expandedFilters: string[];

    expandedHistoryItems: Dictionary<boolean>;
    expandedAttachments: Dictionary<boolean>;
    expandedLinkedActions: Dictionary<boolean>;
    expandedLinkedBuildings: Dictionary<boolean>;
    isDownloadingRisks: boolean;
}

export const INITIAL_STATE: IRisksGridState = {
    ...INITIAL_GRID_STATE,
    expandedFilters: [
        'riskStatus'
    ],
    expandedHistoryItems: {},
    expandedAttachments: {},
    expandedLinkedActions: {},
    expandedLinkedBuildings: {},
    isDownloadingRisks: false
};

const items = (state: IRisksGridState['items'] = INITIAL_STATE.items, action: IAppAction) => {
    switch (action.type) {
        case RiskActions.LOAD_RISKS_CANCELLED:
        case RiskActions.LOAD_RISKS_REJECTED:
            return INITIAL_STATE.items;

        case RiskActions.LOAD_RISKS_FULFILLED:
            return action.payload.risks;

        case RiskActionsV2.LOAD_RISKS_FULFILLED:
            if (action.payload) {
                return action.payload;
            }
            return state;
        case RiskActionsV2.CREATE_RISK_FULFILLED:
        case RiskActionsV2.CREATE_RISK_FOR_INCIDENT_FULFILLED:
            if (action.payload) {
                return [action.payload, ...state];
            }
            return state;
        case RiskActionsV2.UPDATE_RISK_FULFILLED:
            if (action.payload) {
                const actionItem = state.find((n) => n.id === action.payload.id);
                if (actionItem) {
                    return state.map((item: IRisk) => {
                        if (item.id === action.payload.id) {
                            return  action.payload;
                        }
                        return item;
                    });
                }
                return [action.payload, ...state];
            }
            return state;
        case RiskActionsV2.SORT_RISKS:
            return  genericSort(action.payload.sortColumn, [...state], action.payload.sortAscending);

        default:
            return state;
    }
};

const isDownloadingRisks = (state: IRisksGridState['isDownloadingRisks'] = INITIAL_STATE.isDownloadingRisks, action: RiskActionTypes) => {
    switch (action.type) {
        case RiskActions.DOWNLOAD_RISKS:
            return true;
        case RiskActions.DOWNLOAD_RISKS_FULFILLED:
        case RiskActions.DOWNLOAD_RISKS_CANCELED:
        case RiskActions.DOWNLOAD_RISKS_REJECTED:
            return false;
        default:
            return state;
    }
};

const expandedItems = (
    state: IRisksGridState['expandedItems'] = INITIAL_STATE.expandedItems,
    action: RiskActionTypes
) => {
    switch (action.type) {
        case RiskActions.TOGGLE_ITEM_EXPANDED:
            const id = action.payload.id;
            if (state.includes(id)) {
                return state.filter((c) => c !== id);
            }
            return [...state, id];
        default:
            return state;
    }
};

const expandedHistoryItems  = (
    state: IRisksGridState['expandedHistoryItems'] = INITIAL_STATE.expandedHistoryItems,
    action: RiskActionTypes
) => {
    switch (action.type) {
        case RiskActions.SHOW_HISTORY:
            return {
                ...state,
                [action.payload.id]: true
            };

        case RiskActions.HIDE_HISTORY:
            const id = action.payload.id;
            const { [id]: _, ...newState } = state;
            return newState;

        default:
            return state;
    }
};

const expandedAttachments  = (
    state: IRisksGridState['expandedAttachments'] = INITIAL_STATE.expandedAttachments,
    action: RiskActionTypes
) => {
    switch (action.type) {
        case RiskActions.SHOW_ATTACHMENTS:
            return {
                ...state,
                [action.payload.id]: true
            };

        case RiskActions.HIDE_ATTACHMENTS:
            const id = action.payload.id;
            const { [id]: _, ...newState } = state;
            return newState;

        default:
            return state;
    }
};

const expandedLinkedActions  = (
    state: IRisksGridState['expandedLinkedActions'] = INITIAL_STATE.expandedLinkedActions,
    action: RiskActionTypes
) => {
    switch (action.type) {
        case RiskActions.SHOW_LINKED_ACTIONS:
            return {
                ...state,
                [action.payload.id]: true
            };

        case RiskActions.HIDE_LINKED_ACTIONS:
            const id = action.payload.id;
            const { [id]: _, ...newState } = state;
            return newState;

        default:
            return state;
    }
};

const expandedLinkedBuildings  = (
    state: IRisksGridState['expandedLinkedBuildings'] = INITIAL_STATE.expandedLinkedBuildings,
    action: RiskActionTypes
) => {
    switch (action.type) {
        case RiskActions.SHOW_LINKED_BUILDINGS:
            return {
                ...state,
                [action.payload.id]: true
            };

        case RiskActions.HIDE_LINKED_BUILDINGS:
            const id = action.payload.id;
            const { [id]: _, ...newState } = state;
            return newState;

        default:
            return state;
    }
};

const isLoading = (state: boolean = INITIAL_STATE.isLoading, action: IAppAction) => {
    switch (action.type) {
        case RiskActionsV2.LOAD_RISKS:
            return true;

        case RiskActionsV2.LOAD_RISKS_FULFILLED:
        case RiskActionsV2.LOAD_RISKS_REJECTED:
            return false;

        default:
            return state;
    }
};

const sortColumn = (state: IRisksGridState['sortColumn'] = INITIAL_STATE.sortColumn, action: IAppAction) => {
    switch (action.type) {
        case RiskActionsV2.SORT_RISKS:
            return action.payload.sortColumn;

        default:
            return state;
    }
};

const sortAscending = (
    state: IRisksGridState['sortAscending'] = INITIAL_STATE.sortAscending,
    action: IAppAction
) => {
    switch (action.type) {
        case RiskActionsV2.SORT_RISKS:
            return action.payload.sortAscending;

        default:
            return state;
    }
};

const secondarySortColumn = (
    state: IRisksGridState['secondarySortColumn'] = INITIAL_STATE.secondarySortColumn,
    action: RiskActionTypes
) => {
    switch (action.type) {
        default:
            return state;
    }
};

const secondarySortAscending = (
    state: IRisksGridState['secondarySortAscending'] = INITIAL_STATE.secondarySortAscending,
    action: RiskActionTypes
) => {
    switch (action.type) {
        default:
            return state;
    }
};

const expandedFilters = (
    state: IRisksGridState['expandedFilters'] = INITIAL_STATE.expandedFilters,
    action: RiskActionTypes
) => {
    switch (action.type) {
        case RiskActions.TOGGLE_FILTER_EXPANDED:
            const id = action.payload.id;

            if (state.includes(id)) {
                const newState = state.filter((item) => item !== id);
                return newState;
            } else {
                if (id) {
                    const newState = state.concat(id);
                    return newState;
                }
            }

            return state;

        default:
            return state;
    }
};

const responseContinuation = (state: string = INITIAL_STATE.responseContinuation) => state;

export const reducer = combineReducers<IRisksGridState>({
    items,
    isLoading,
    sortColumn,
    sortAscending,
    secondarySortColumn,
    secondarySortAscending,
    expandedItems,
    expandedFilters,
    expandedHistoryItems,
    expandedAttachments,
    expandedLinkedActions,
    expandedLinkedBuildings,
    isDownloadingRisks,
    responseContinuation
});
