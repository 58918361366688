import * as React from 'react';
import { FormikProps } from 'formik';
import { object, string } from 'yup';
import useTranslate from 'translations/translation-utils';
import { TextField } from 'components/form/fields/text-field';
import { TextAreaField } from 'components/form/fields/textarea-field';
import { DatePickerField } from 'components/form/fields/date-picker-field';
import { ICalendarItem } from 'models/calendar';
import { IPersonLookup } from 'models/person-lookup';
import { ISelectOption } from 'models/select-option';
import { PopoutSelectField } from 'components/form/fields/popout-select-field';
import { isTodayOrFutureDate, getTimezoneDiffrence } from 'utils/date-utils';
interface IProps {
    formikBag: FormikProps<Partial<ICalendarItem>>;
    lookupSitePeopleRoles: IPersonLookup[];
    isCalendarDetail?: boolean;
}

export const calendarFormValidationSchema = object<Partial<ICalendarItem>>().shape({
    title: string().trim().required('Title is required'),
    description: string().trim().required('Description is required'),
});

export const CalendarEventForm: React.FC<IProps> = ({
    formikBag,
    lookupSitePeopleRoles,
    isCalendarDetail,
}) => {
    const translate = useTranslate();
    const [isValid, setIsValid] = React.useState(true);
    const [isValidStart, setIsValidStart] = React.useState(true);

    const handleStartDateChange = (newDate: Date) => {
        if (!isTodayOrFutureDate(newDate)) {
            setIsValidStart(false);
            setIsValid(true);
        } else if (newDate.getTime() > new Date(formikBag.values.end).getTime()) {
            formikBag.setFieldValue('end', getTimezoneDiffrence(newDate));
            formikBag.setFieldValue('start', newDate);
            setIsValidStart(true);
            setIsValid(false);
        } else {
            formikBag.setFieldValue('start', newDate);
            setIsValidStart(true);
        }
    };
    const handleEndDateChange = (newDate: Date) => {
        if (newDate.getTime() < new Date(formikBag.values.start).getTime()) {
            setIsValid(false);
        } else {
            formikBag.setFieldValue('end', getTimezoneDiffrence(newDate));
            setIsValid(true);
        }
    };
    const getLookupSitePeople = (lookups: IPersonLookup[]) => {
        const typeaheadOptions: ISelectOption[] = lookups.map((lookup) => {
            const roles = lookup.roleNames.join(', ');
            if (roles) {
                const label = `${lookup.label} (${roles})`;
                return { label, value: lookup.id };
            } else {
                return { label: lookup.label, value: lookup.id };
            }
        });

        return typeaheadOptions;
    };

    const handleAssigneeChange = (value: string) => {
        formikBag.setFieldValue('assignedId', value);
    };

    return (
        <>
            <div className="tabs-container tab-content">
                <div className="columns is-mobile">
                    <div className="column is-full">
                        <TextField
                            id="title"
                            label={translate('RiskRegisterPage.RiskRegisterTable.Title.Title')+'*'}
                            error={formikBag.errors.title}
                            value={formikBag.values.title}
                            handleChange={formikBag.handleChange}
                            setVal={formikBag.setFieldValue}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-full">
                        <TextAreaField
                            id="description"
                            label={translate('RiskDetailsPage.label.Description')}
                            placeholder={translate('RiskDetailsPage.placeholder.Description')}
                            error={formikBag.errors.description}
                            value={formikBag.values.description}
                            handleChange={formikBag.handleChange}
                            setVal={formikBag.setFieldValue}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-6">
                        <DatePickerField
                            id="start"
                            label={translate('Cerm.ProcessStepOutcomes.Labels.StartDate')+'*'}
                            value={formikBag.values.start}
                            handleChange={handleStartDateChange}
                            handleBlur={formikBag.handleBlur}
                            error={!isValidStart && translate('CalenderDetails.Message.2')}
                        />
                    </div>
                    <div className="column is-6">
                        <DatePickerField
                            id="endDate"
                            label={translate('RiskActionTab.TableHeader.Duedate')}
                            value={formikBag.values.end}
                            handleChange={handleEndDateChange}
                            handleBlur={formikBag.handleBlur}
                            error={!isValid && translate('CalenderDetails.Message.3')}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-6">
                        <PopoutSelectField
                            id="assinedId"
                            label={translate('CalendarEventPage.label.Assignedto')}
                            value={formikBag.values.assignedId}
                            options={getLookupSitePeople(lookupSitePeopleRoles)}
                            handleChange={handleAssigneeChange}
                            handleBlur={formikBag.handleBlur}
                        />
                    </div>
                    {isCalendarDetail && (
                        <div className="column is-6">
                            <TextField
                                id="lastUpdatedByPersonName"
                                label={translate('CalenderDetails.Message.4')}
                                value={
                                    formikBag.values.lastUpdatedByPersonName
                                        ? formikBag.values.lastUpdatedByPersonName
                                        : ''
                                }
                                handleChange={formikBag.handleChange}
                                handleBlur={formikBag.handleBlur}
                                isDisabled={true}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
