import { IAppAction } from 'actions/app-action';
import { combineReducers } from 'redux';
import { ISiteMetaDataState } from 'state/site-metadata-state';
import { ISiteMetaDataActions } from 'actions/actions-v2/site-metadata-actions-v2';

const INITIAL_STATE: ISiteMetaDataState = {
    isLoading: false,
    allLineOfBusinesses: [],
    divisions: [],
    businessUnit: [],
    facilityType: [],
    hierarchy: {
        linesOfBusiness: [],
        divisions: [],
        businessUnits: [],
        selectedLineOfBusiness: { id: '', label: '', count: 0, isActive: true },
        selectedDivision: { id: '', label: '', count: 0, isActive: true },
        divisionsForLineOfBusiness: [],
    }
};

const isLoading = (
    state: ISiteMetaDataState['isLoading'] = INITIAL_STATE.isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case ISiteMetaDataActions.LOAD_SITE_META_DATA:
        case ISiteMetaDataActions.CREATE_SITE_META_DATA:
        case ISiteMetaDataActions.SAVE_LINE_OF_BUSINESS_SITE_META_DATA:
        case ISiteMetaDataActions.SELECT_LINE_OF_BUSINESS:
        case ISiteMetaDataActions.GET_DIVISIONS_FOR_LINE_OF_BUSINESS:
            return true;
        case ISiteMetaDataActions.LOAD_SITE_META_DATA_FULFILLED:
        case ISiteMetaDataActions.LOAD_SITE_META_DATA_REJECTED:
        case ISiteMetaDataActions.CREATE_SITE_META_DATA_REJECTED:
        case ISiteMetaDataActions.SAVE_LINE_OF_BUSINESS_SITE_META_DATA_FULFILLED:
        case ISiteMetaDataActions.SAVE_LINE_OF_BUSINESS_SITE_META_DATA_REJECTED:
        case ISiteMetaDataActions.SELECT_LINE_OF_BUSINESS_FULFILLED:
        case ISiteMetaDataActions.SELECT_LINE_OF_BUSINESS_REJECTED:
        case ISiteMetaDataActions.GET_DIVISIONS_FOR_LINE_OF_BUSINESS_FULFILLED:
        case ISiteMetaDataActions.GET_DIVISIONS_FOR_LINE_OF_BUSINESS_REJECTED:
            return false;
        default:
            return state;
    }
};

const allLineOfBusinesses = (
    state: ISiteMetaDataState['allLineOfBusinesses'] = INITIAL_STATE.allLineOfBusinesses,
    action: IAppAction
) => {
    switch (action.type) {
        case ISiteMetaDataActions.LOAD_SITE_META_DATA_FULFILLED:
            if (action.payload) {
                return action.payload.lineOfBusiness;
            }
            break;
        default:
            return state;
    }
};

const divisions = (
    state: ISiteMetaDataState['divisions'] = INITIAL_STATE.divisions,
    action: IAppAction
) => {
    switch (action.type) {
        case ISiteMetaDataActions.LOAD_SITE_META_DATA_FULFILLED:
            if (action.payload) {
                return action.payload.divisions;
            }
            break;
        default:
            return state;
    }
};

const facilityType = (
    state: ISiteMetaDataState['facilityType'] = INITIAL_STATE.facilityType,
    action: IAppAction
) => {
    switch (action.type) {
        case ISiteMetaDataActions.LOAD_SITE_META_DATA_FULFILLED:
            if (action.payload) {
                return action.payload.facilityType;
            }
            break;
        default:
            return state;
    }
};

const businessUnit = (
    state: ISiteMetaDataState['businessUnit'] = INITIAL_STATE.businessUnit,
    action: IAppAction
) => {
    switch (action.type) {
        case ISiteMetaDataActions.LOAD_SITE_META_DATA_FULFILLED:
            if (action.payload) {
                return action.payload.businessUnit;
            }
            break;
        default:
            return state;
    }
};

const hierarchy = (
    state: ISiteMetaDataState['hierarchy'] = INITIAL_STATE.hierarchy,
    action: IAppAction
) => {
    switch (action.type) {
        case ISiteMetaDataActions.SAVE_LINE_OF_BUSINESS_SITE_META_DATA:
            return {
                ...state,
                linesOfBusiness: action.payload.lineofBusiness,
            };

        case ISiteMetaDataActions.SAVE_DIVISIONS_SITE_META_DATA:
            return {
                ...state,
                divisions: action.payload.divisions,
            };

        case ISiteMetaDataActions.SAVE_BUSINESS_UNIT_SITE_META_DATA:
            if (action.payload) {
                return {
                    ...state,
                    businessUnits: action.payload.businessUnits,
                };
            }
            break;

        case ISiteMetaDataActions.SELECT_LINE_OF_BUSINESS:
            if (action.payload) {
                return {
                    ...state,
                    selectedLineOfBusiness: action.payload,
                };
            }
            break;

        case ISiteMetaDataActions.SELECT_DIVISION:
            if (action.payload) {
                return {
                    ...state,
                    selectedDivision: action.payload,
                };
            }
            break;

        case ISiteMetaDataActions.GET_DIVISIONS_FOR_LINE_OF_BUSINESS:
            if (action.payload) {
                return {
                    ...state,
                    divisionsForLineOfBusiness: action.payload,
                };
            }
            break;

        case ISiteMetaDataActions.CREATE_SITE_META_DATA_FULFILLED:
            return action.payload === false ? INITIAL_STATE.hierarchy : action.payload;

        case ISiteMetaDataActions.RESET_SITE_META_DATA:
            return INITIAL_STATE.hierarchy;

        default:
            return state;
    }
};

export const ISiteMetaDataReducer = combineReducers<ISiteMetaDataState>({
    isLoading,
    allLineOfBusinesses,
    divisions,
    businessUnit,
    facilityType,
    hierarchy
});