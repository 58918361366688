import { createSelector } from 'reselect';

import { ICermProcessStep } from '../../../models/cerm-process-step';
import { IUpsertCermProcessStepRootState } from '../../../reducers/upsert-cerm-process-step';
import { isCreating } from '../../../utils/id-utils';
import { getUpsertCermProcessStepState } from './common';
import { IRootState } from '../../store';

const getPermissions = (state: IUpsertCermProcessStepRootState) =>
    state.app.permissions;

const getIsInitialising = (state: IUpsertCermProcessStepRootState) =>
    state.cermProcessStepState.isInitialising;

const isCreatingQuestionSet = createSelector(
    getUpsertCermProcessStepState,
    (state) => isCreating(state.cermProcessStep)
);


const getPageTitle = (state: IUpsertCermProcessStepRootState) =>
        state.cermProcessStepState.cermProcessStep.id ? 'Edit CERM Process Step' : 'Create CERM Process Step';

const getInitialValues = createSelector(
    getUpsertCermProcessStepState,
    (state) => ({
        ...state.cermProcessStep,
        expectedOutcomes: state.cermProcessStep.expectedOutcomes ?
            state.cermProcessStep.expectedOutcomes.length > 0
                ? state.cermProcessStep.expectedOutcomes.map((q) => ({
                    ...q
                }))
                : [] : []
    })
);

const getProgressSubtitle = createSelector(
    isCreatingQuestionSet,
    (creating: boolean) =>
        creating ? 'Question Set Creation Progress' : 'Question Set Edit Progress'
);


const getIsSubmitting = (state: IUpsertCermProcessStepRootState) =>
    state.cermProcessStepState.isSubmitting;

const getOperatingPlatforms = (state: IRootState) => state.operatingPlatformState.operatingPlatforms;

export default createSelector(
    getPermissions,
    getPageTitle,
    getInitialValues,
    getProgressSubtitle,
    getIsInitialising,
    getIsSubmitting,
    getOperatingPlatforms,
    (permissions, pageTitle, initialValues: Partial<ICermProcessStep>, progressSubtitle, isInitialising,isSubmitting,operatingPlatforms) => ({
        isInitialising,
        permissions,
        pageTitle,
        initialValues,
        progressSubtitle,
        isSubmitting,
        operatingPlatforms
    })
);
