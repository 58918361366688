import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { CermGlobalOverviewActions } from 'actions/actions-v2/cerm-global-overview-actions-v2';
import { ICermGlobalOverview } from 'models/cerm-global-overview';

const controllerName = 'CermGlobalOverview';
const apiPath = `/api/${controllerName}`;
const downloadControllerName = 'Download';
const apiPathDownload = `/api/${downloadControllerName}`;

const getActions = {
    action: CermGlobalOverviewActions.LOAD_CERM_GLOBAL_OVERVIEW_LIST,
    actionFulfilled: CermGlobalOverviewActions.LOAD_CERM_GLOBAL_OVERVIEW_LIST_FULFILLED,
    actionRejected: CermGlobalOverviewActions.LOAD_CERM_GLOBAL_OVERVIEW_LIST_REJECTED
} as IActionType;

const exportActions = {
    action: CermGlobalOverviewActions.EXPORT_GLOBAL_CERM_OVERVIEW,
    actionFulfilled: CermGlobalOverviewActions.EXPORT_GLOBAL_CERM_OVERVIEW_FULFILLED,
    actionRejected: CermGlobalOverviewActions.EXPORT_GLOBAL_CERM_OVERVIEW_REJECTED
} as IActionType;

const exportSitesActions = {
    action: CermGlobalOverviewActions.EXPORT_GLOBAL_CERM_SITES_OVERVIEW,
    actionFulfilled: CermGlobalOverviewActions.EXPORT_GLOBAL_CERM_SITES_OVERVIEW_FULFILLED,
    actionRejected: CermGlobalOverviewActions.EXPORT_GLOBAL_CERM_SITES_OVERVIEW_REJECTED
} as IActionType;

const loadCermListEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getActions).get<ICermGlobalOverview[]>(apiPath);

const exportCermGlobalOverviewEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, exportActions).export(`${apiPath}/export`);

const exportCermSitesOverviewEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, exportSitesActions).exportById(`${apiPathDownload}/CermAssessment`);

export const cermGlobalOverviewEpicsV2 = combineEpics(
    loadCermListEpic,
    exportCermGlobalOverviewEpic,
    exportCermSitesOverviewEpic
);
