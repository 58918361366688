import { combineReducers } from 'redux';
import { ManageRoleActions, ManageRoleActionTypes } from 'actions/manage-role-actions';
import { IKeyValue } from 'models/key-value';
import { IPerson, IPersonToSite } from 'models/person';
import { PersonActions } from 'actions/actions-v2/person-action-v2';
import { RelationshipType } from './manageRoles-grid';
import { IAppAction } from 'actions/app-action';

export interface IPersonFormState {
    isLoading: boolean;
    upsertItem: Partial<IPerson>;
    relationships: IPersonToSite[];
}

export const INITIAL_PERSON_STATE: IPerson = {
    id: '',
    siteId: '',
    bucket: 'Person',
    shard: 'Person',
    eTag: '',
    personReference: '',
    firstName: '',
    lastName: '',
    fullName: '',
    preferredName: '',
    businessTitle: '',
    emailAddress1: '',
    departmentId: 0,
    departmentDescription: '',
    supervisorId: 0,
    supervisorName: '',
    telephone1: '',
    telephone2: '',
    isActive: true,
    isExternal: false,
    importedFromMyHr: false,
    roles: [],
    createdDateTime: null,
    lastUpdatedDateTime: null,
    createdByPersonId: null,
    lastUpdatedByPersonId: null,
    notify: true,
    notifyEmail: '',
    selectedSite: null,
    snowTicket: '',
    siteCount: 0,
    accessRights: {
        operatingPlatforms: [],
        linesOfBusiness: [],
        divisions: [],
        businessUnits: [],
        clients: [],
        sites: [],
    },
};

export const INITIAL_STATE: IPersonFormState = {
    isLoading: true,
    upsertItem: INITIAL_PERSON_STATE,
    relationships: [],
};

const upsertItem = (state: Partial<IPerson> = INITIAL_STATE.upsertItem, action: IAppAction) => {
    let isInclude = null;
    switch (action.type) {
        case ManageRoleActions.TOGGLE_ALL_RELATIONSHIPS:
            const relationshipType = action.payload.relationshipType;
            const removing = action.payload.allChecked;
            isInclude = relationshipType === ('Include' as RelationshipType);

            let itemsToRemoveOrAdd: Array<IKeyValue<string>> = action.payload.siteIds.map((c) => ({
                key: c,
                value: relationshipType as RelationshipType,
            }));

            let currentRelationships = [];

            if (!isInclude) {
                itemsToRemoveOrAdd = itemsToRemoveOrAdd.filter((f) =>
                    currentRelationships.some(
                        (c) => c.key === f.key && c.value === ('Include' as RelationshipType)
                    )
                );
            }

            if (removing && isInclude) {
                currentRelationships = [];
            } else if (removing && !isInclude) {
                currentRelationships = [];
            } else if (!removing && isInclude) {
                currentRelationships = currentRelationships
                    .concat(itemsToRemoveOrAdd)
                    .filter((relationship, index, self) => index === self.indexOf(relationship));
            } else if (!removing && relationshipType !== ('Include' as RelationshipType)) {
                currentRelationships = currentRelationships
                    .concat(itemsToRemoveOrAdd)
                    .filter((relationship, index, self) => index === self.indexOf(relationship));
            }

            return {
                ...state,
                sitesResponsibilities: currentRelationships,
            };

        case ManageRoleActions.TOGGLE_RELATIONSHIP:
            // 1 get the current state or get empty state
            currentRelationships = [];
            if (!currentRelationships) {
                currentRelationships = [];
            }

            // get relationship if any
            const hasRelationship = currentRelationships.find(
                (relationshipItem) =>
                    relationshipItem.key === action.payload.siteId &&
                    relationshipItem.value === (action.payload.relationshipType as RelationshipType)
            );

            isInclude = action.payload.relationshipType === ('Include' as RelationshipType);

            let includeIsChecked = false;
            if (!isInclude) {
                includeIsChecked =
                    currentRelationships.find(
                        (relationshipItem) =>
                            relationshipItem.key === action.payload.siteId &&
                            relationshipItem.value === ('Include' as RelationshipType)
                    ) != null;
            }

            if (hasRelationship) {
                // Already checked then remove it
                if (isInclude) {
                    return {
                        ...state,
                        sitesResponsibilities: currentRelationships.filter((relationshipItem) => {
                            return !(relationshipItem.key === action.payload.siteId);
                        }),
                    };
                } else {
                    return {
                        ...state,
                        sitesResponsibilities: currentRelationships.filter((relationshipItem) => {
                            return !(
                                relationshipItem.key === action.payload.siteId &&
                                relationshipItem.value === action.payload.relationshipType
                            );
                        }),
                    };
                }
            } else {
                // not checked, check it the add it
                if (isInclude || includeIsChecked) {
                    return {
                        ...state,
                        sitesResponsibilities: [
                            ...[],
                            { key: action.payload.siteId, value: action.payload.relationshipType },
                        ],
                    };
                }
            }
            return state;
        case ManageRoleActions.LOAD_PERSON:
            return INITIAL_STATE.upsertItem;
        case PersonActions.LOAD_PERSON_FULFILLED:
            return action.payload;
        case PersonActions.RESET_PERSON:
            return INITIAL_STATE.upsertItem;
        case ManageRoleActions.ROUTE_CHANGE:
            return INITIAL_STATE.upsertItem;

        case PersonActions.UPDATE_PERSON_FULFILLED:
            if (action.payload) {
                return action.payload;
            }

            return state;

        default:
            return state;
    }
};

const isLoading = (
    state: boolean = INITIAL_STATE.isLoading,
    action: ManageRoleActionTypes | IAppAction
) => {
    switch (action.type) {
        case PersonActions.UPDATE_PERSON:
        case PersonActions.CREATE_PERSON:
        case PersonActions.LOAD_PERSON:
        case PersonActions.LOAD_PERSONS:
        case PersonActions.LOAD_PERSON_RELATIONSHIPS:
        case PersonActions.DELETE_PERSON:
        case PersonActions.ACTIVATE_PERSON:
        case ManageRoleActions.LOAD_PAGE:
        case ManageRoleActions.LOAD_PERSON:
            return true;
        case ManageRoleActions.LOAD_PAGE_FULFILLED:
        case ManageRoleActions.LOAD_PAGE_CANCELLED:
        case ManageRoleActions.LOAD_PAGE_REJECTED:
        case PersonActions.UPDATE_PERSON_FULFILLED:
        case PersonActions.CREATE_PERSON_FULFILLED:
        case PersonActions.LOAD_PERSON_FULFILLED:
        case PersonActions.LOAD_PERSONS_FULFILLED:
        case PersonActions.LOAD_PERSON_RELATIONSHIPS_REJECTED:
        case PersonActions.LOAD_PERSON_RELATIONSHIPS_FULFILLED:
        case PersonActions.DELETE_PERSON_FULFILLED:
        case PersonActions.DELETE_PERSON_REJECTED:
        case PersonActions.ACTIVATE_PERSON_FULFILLED:
        case PersonActions.ACTIVATE_PERSON_REJECTED:
        case ManageRoleActions.LOAD_PERSON_FULFILLED:
            return false;
        default:
            return state;
    }
};

const relationships = (
    state: IPersonFormState['relationships'] = INITIAL_STATE.relationships,
    action: IAppAction
) => {
    switch (action.type) {
        case PersonActions.LOAD_PERSON_RELATIONSHIPS_FULFILLED:
            return action.payload;
        case PersonActions.LOAD_PERSON_RELATIONSHIPS:
        case PersonActions.LOAD_PERSON_RELATIONSHIPS_REJECTED:
            return [];
        default:
            return state;
    }
};

export const reducer = combineReducers<IPersonFormState>({
    isLoading,
    upsertItem,
    relationships,
});
