import * as React from 'react';
import { SortDirection } from '../../enums/SortDirection';
import './grid-sort-link.scss';
import { MaterialIconType } from 'routes/material-icon/material-icon-type';
import { MaterialIcons } from 'routes/material-icon/material-icon';

export interface IProps {
    sortFieldName: string;
    currentSortFieldName: string;
    currentSortDirection: SortDirection;

    onSort: (sortField: string, sortDirection: SortDirection) => void;
}

export const GridSortLink: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const isCurrent = props.sortFieldName === props.currentSortFieldName;

    const onSort = () =>
        props.onSort(
            props.sortFieldName,
            isCurrent
                ? props.currentSortDirection === SortDirection.Ascending
                    ? SortDirection.Descending
                    : SortDirection.Ascending
                : SortDirection.Ascending
        );

    if (isCurrent) {
        const icon =
            props.currentSortDirection === SortDirection.Ascending
                ? MaterialIconType.ArrowUp
                : MaterialIconType.ArrowDown;

        return (
            <a className="GridSortLink" onClick={onSort}>
                {props.children}
                < MaterialIcons type={icon} />
            </a>
        );
    }

    return (
        <a className="GridSortLink" onClick={onSort}>
            {props.children}
        </a>
    );
};
