export type EmeraldIconButtonColorType =
  | 'primary'
  | 'accent'
  | 'warn'
  | 'error'
  | 'none';

export enum EmeraldIconButtonColor {
  Primary = 'primary',
  Accent = 'accent',
  Warn = 'warn',
  Error = 'error',
  None = 'none',
}
