import { Action } from 'redux';
import { ICreateQuestionSetRootState } from 'reducers/create-question-set';
import { IQuestionSet } from '../models/question-set';

export enum CreateQuestionSetActions {
    UPDATE_QUESTION_SET = '@@createQuestionSet/UPDATE_QUESTION_SET',
    UPDATE_QUESTION_SET_FULFILLED = '@@createQuestionSet/UPDATE_QUESTION_SET_FULFILLED',
    UPDATE_QUESTION_SET_REJECTED = '@@createQuestionSet/UPDATE_QUESTION_SET_REJECTED',
    UPDATE_QUESTION_SET_CANCELLED = '@@createQuestionSet/UPDATE_QUESTION_SET_CANCELLED',

    SET_SELECTED_QUESTION = '@@createQuestionSet/SET_SELECTED_QUESTION',
    LOAD_PAGE = '@@createQuestionSet/LOAD_PAGE',
    LOAD_PAGE_FULFILLED = '@@createQuestionSet/LOAD_PAGE_FULFILLED',
    LOAD_PAGE_REJECTED = '@@createQuestionSet/LOAD_PAGE_REJECTED',
    LOAD_PAGE_CANCELLED = '@@createQuestionSet/LOAD_PAGE_CANCELLED'
}

export interface ILoadPageAction extends Action {
    type: CreateQuestionSetActions.LOAD_PAGE;
    payload: {
        id?: string;
    };
}

export interface ILoadPageRejectedAction extends Action {
    type: CreateQuestionSetActions.LOAD_PAGE_REJECTED;
    error: true;
    payload: ICreateQuestionSetRootState;
}

export interface ILoadPageCancelledAction extends Action {
    type: CreateQuestionSetActions.LOAD_PAGE_CANCELLED;
}

export interface ILoadPageFulfilledAction extends Action {
    type: CreateQuestionSetActions.LOAD_PAGE_FULFILLED;
    payload: any;
}

export enum AddQuestionNextAction {
    ADD_ANOTHER_QUESTION,
    SAVE_DRAFT
}

export interface IUpdateQuestionSetAction extends Action {
    type: CreateQuestionSetActions.UPDATE_QUESTION_SET;
    payload: {
        questionSet: Partial<IQuestionSet>
    };
}

export interface IUpdateQuestionSetFulfilledAction extends Action {
    type: CreateQuestionSetActions.UPDATE_QUESTION_SET_FULFILLED;
    payload: {
        questionSet: IQuestionSet
    };
}

export interface IUpdateQuestionSetRejectedAction extends Action {
    type: CreateQuestionSetActions.UPDATE_QUESTION_SET_REJECTED;
    error: true;
    payload: {
        questionSet: Partial<IQuestionSet>
    } & any;
}

export interface IUpdateQuestionSetCancelledAction extends Action {
    type: CreateQuestionSetActions.UPDATE_QUESTION_SET_CANCELLED;
    payload: {
        questionSet: Partial<IQuestionSet>
    };
}

export interface ISetSelectedQuestionAction extends Action {
    type: CreateQuestionSetActions.SET_SELECTED_QUESTION;
    payload: {
        selectedQuestionIndex: number | null;
    };
}

export type CreateQuestionSetActionTypes =
| IUpdateQuestionSetAction
| IUpdateQuestionSetFulfilledAction
| IUpdateQuestionSetRejectedAction
| IUpdateQuestionSetCancelledAction

| ISetSelectedQuestionAction
| ILoadPageAction
| ILoadPageCancelledAction
| ILoadPageRejectedAction
| ILoadPageFulfilledAction;
