import { Action } from 'redux';

import { IChangeSearchValueAction, ILoadSearchResultsAction } from '.';
import { IClient } from '../../models/client';
import { ISiteProfile } from '../../models/site-profile';

export enum ClientActions {
    SELECT_CLIENT = '@@siteRelationships/SELECT_CLIENT',
    CHANGE_CLIENT_SEARCH_VALUE = '@@siteRelationships/CHANGE_CLIENT_SEARCH_VALUE',
    LOAD_CLIENT_SEARCH_RESULTS = '@@siteRelationships/LOAD_CLIENT_SEARCH_RESULTS',
    LOAD_CLIENT_SEARCH_RESULTS_FULFILLED = '@@siteRelationships/LOAD_CLIENT_SEARCH_RESULTS_FULFILLED',
    LOAD_CLIENT_SEARCH_RESULTS_REJECTED = '@@siteRelationships/LOAD_CLIENT_SEARCH_RESULTS_REJECTED',
    LOAD_CLIENT_SEARCH_RESULTS_CANCELLED = '@@siteRelationships/LOAD_CLIENT_SEARCH_RESULTS_CANCELLED',

    CHANGE_CLIENT = '@@siteRelationships/CHANGE_CLIENT',
    CHANGE_CLIENT_CONFIRMED = '@@siteRelationships/CHANGE_CLIENT_CONFIRMED',
    CHANGE_CLIENT_FULFILLED = '@@siteRelationships/CHANGE_CLIENT_FULFILLED',
    CHANGE_CLIENT_REJECTED = '@@siteRelationships/CHANGE_CLIENT_REJECTED',
    CHANGE_CLIENT_CANCELLED = '@@siteRelationships/CHANGE_CLIENT_CANCELLED',
    LOAD_PAGE = '@@siteRelationships/LOAD_PAGE',
    LOAD_PAGE_FULFILLED = '@@siteRelationships/LOAD_PAGE_FULFILLED',
    LOAD_PAGE_REJECTED = '@@siteRelationships/LOAD_PAGE_REJECTED',
    LOAD_PAGE_CANCELLED = '@@siteRelationships/LOAD_PAGE_CANCELLED'
}

export interface ILoadPageAction extends Action {
    type: ClientActions.LOAD_PAGE;
}

export interface ILoadPageRejectedAction extends Action {
    type: ClientActions.LOAD_PAGE_REJECTED;
    error: true;
    payload: any;
}

export interface ILoadPageCancelledAction extends Action {
    type: ClientActions.LOAD_PAGE_CANCELLED;
}

export interface ILoadPageFulfilledAction extends Action {
    type: ClientActions.LOAD_PAGE_FULFILLED;
    payload: any;
}

export interface ISelectClient extends Action {
    type: ClientActions.SELECT_CLIENT;
    payload: {
        client: IClient;
    };
}

export const selectClient = (client: IClient): ISelectClient => ({
    type: ClientActions.SELECT_CLIENT,
    payload: {
        client
    }
});

export interface IChangeClientSearchValueAction extends IChangeSearchValueAction {
    type: ClientActions.CHANGE_CLIENT_SEARCH_VALUE;
}

export const changeClientSearchValue = (value: string): IChangeClientSearchValueAction => ({
    type: ClientActions.CHANGE_CLIENT_SEARCH_VALUE,
    payload: {
        value
    }
});

export interface ILoadClientSearchResults extends ILoadSearchResultsAction {
    type: ClientActions.LOAD_CLIENT_SEARCH_RESULTS;
}

export const loadClientSearchResults = (
    searchString: string
): ILoadSearchResultsAction => ({
    type: ClientActions.LOAD_CLIENT_SEARCH_RESULTS,
    payload: {
        searchString
    }
});

export interface ILoadClientSearchResultsFulfilled extends Action {
    type: ClientActions.LOAD_CLIENT_SEARCH_RESULTS_FULFILLED;
    payload: {
        searchString: string;
        clients: IClient[];
    };
}

export const loadClientSearchResultsFulfilled = (
    searchString: string,
    clients: IClient[]
): ILoadClientSearchResultsFulfilled => ({
    type: ClientActions.LOAD_CLIENT_SEARCH_RESULTS_FULFILLED,
    payload: {
        searchString,
        clients
    }
});

export interface ILoadClientSearchResultsCancelled extends Action {
    type: ClientActions.LOAD_CLIENT_SEARCH_RESULTS_CANCELLED;
    payload: {
        searchString: string;
    };
}

export const loadClientSearchResultsCancelled = (
    searchString: string
): ILoadClientSearchResultsCancelled => ({
    type: ClientActions.LOAD_CLIENT_SEARCH_RESULTS_CANCELLED,
    payload: {
        searchString
    }
});

export interface ILoadClientSearchResultsRejected extends Action {
    type: ClientActions.LOAD_CLIENT_SEARCH_RESULTS_REJECTED;
    error: true;
    payload: {
        searchString: string;
        error?: any;
    };
}

export const loadClientSearchResultsRejected = (
    searchString: string,
    error?: any
): ILoadClientSearchResultsRejected => ({
    type: ClientActions.LOAD_CLIENT_SEARCH_RESULTS_REJECTED,
    error: true,
    payload: {
        searchString,
        error
    }
});

export interface IChangeClientAction extends Action {
    type: ClientActions.CHANGE_CLIENT;
    payload: {
        site: ISiteProfile;
        client: IClient;
    };
}

export const changeClient = (site: ISiteProfile, client: IClient): IChangeClientAction => ({
    type: ClientActions.CHANGE_CLIENT,
    payload: {
        site,
        client
    }
});

export interface IChangeClientConfirmedAction extends Action {
    type: ClientActions.CHANGE_CLIENT_CONFIRMED;
    payload: {
        site: ISiteProfile;
        client: IClient;
    };
}

export const changeClientConfirmed = (site: ISiteProfile, client: IClient): IChangeClientConfirmedAction => ({
    type: ClientActions.CHANGE_CLIENT_CONFIRMED,
    payload: {
        site,
        client
    }
});

export interface IChangeClientActionFulfilled extends Action {
    type: ClientActions.CHANGE_CLIENT_FULFILLED;
    payload: {
        site: ISiteProfile;
        client: IClient;
    };
}

export const changeClientFulfilled = (site: ISiteProfile, client: IClient): IChangeClientActionFulfilled => ({
    type: ClientActions.CHANGE_CLIENT_FULFILLED,
    payload: {
        site,
        client
    }
});

export interface IChangeClientActionCancelled extends Action {
    type: ClientActions.CHANGE_CLIENT_CANCELLED;
    payload: {
        site: ISiteProfile;
        client: IClient;
    };
}

export const changeClientCancelled = (site: ISiteProfile, client: IClient): IChangeClientActionCancelled => ({
    type: ClientActions.CHANGE_CLIENT_CANCELLED,
    payload: {
        site,
        client
    }
});

export interface IChangeClientActionRejected extends Action {
    type: ClientActions.CHANGE_CLIENT_REJECTED;
    error: true;
    payload: {
        site: ISiteProfile;
        client: IClient;
        error?: any;
    };
}

export const changeClientRejected = (
    site: ISiteProfile,
    client: IClient,
    error?: any
): IChangeClientActionRejected => ({
    type: ClientActions.CHANGE_CLIENT_REJECTED,
    payload: {
        site,
        client,
        error
    },
    error: true
});

export type ClientActionTypes =
    | ISelectClient
    | IChangeClientSearchValueAction
    | ILoadClientSearchResults
    | ILoadClientSearchResultsFulfilled
    | ILoadClientSearchResultsCancelled
    | ILoadClientSearchResultsRejected

    | IChangeClientAction
    | IChangeClientConfirmedAction
    | IChangeClientActionCancelled
    | IChangeClientActionFulfilled
    | IChangeClientActionRejected
    | ILoadPageAction
    | ILoadPageCancelledAction
    | ILoadPageRejectedAction
    | ILoadPageFulfilledAction;
