import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { connect } from 'react-redux';
import ShiftHandoverAdminPage from './shift-handover-admin-page';
import {
    loadSpecificSiteShiftChecklist,
    createSpecificSiteShiftChecklist,
    updateSpecificSiteShiftChecklist,
    deleteSpecificSiteShiftChecklist,
} from 'actions/actions-v2/shift-handover-actions';

const getSiteId = (state: IRootState) => state.app.siteId;
const getAdminShiftCheckList = (state: IRootState) => state.shiftHandoverState.adminShiftChecklist;
const getAdminShiftCheckListLoading = (state: IRootState) => state.shiftHandoverState.isLoading;

const mapStateToProps = createSelector(
    getSiteId,
    getAdminShiftCheckList,
    getAdminShiftCheckListLoading,
    (siteId, shiftCheckList, isLoading) => ({
        siteId,
        shiftCheckList,
        isLoading,
    })
);

const mapDispatchToProps = {
    loadSpecificSiteShiftChecklist,
    createSpecificSiteShiftChecklist,
    updateSpecificSiteShiftChecklist,
    deleteSpecificSiteShiftChecklist,
};

export const ShiftHandoverAdminPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ShiftHandoverAdminPage);
