import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { CriticalSystemSpareActions } from 'actions/actions-v2/critical-system-spares-actions';

const controllerName = 'CriticalSystemSpareSitesSummary';
const apiPath = `/api/${controllerName}`;

const getActions = {
    action: CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARES_SITE_SUMMARIES,
    actionFulfilled: CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARES_SITE_SUMMARIES_FULFILLED,
    actionRejected: CriticalSystemSpareActions.LOAD_CRITICAL_SYSTEM_SPARES_SITE_SUMMARIES_REJECTED
} as IActionType;

const loadEpic: Epic<IAppAction, IAppAction> = (action$, state$) => new BaseEpic(action$, state$, getActions).get(apiPath);

export const criticalSystemSpareSiteSummariesEpicsV2 = combineEpics(
    loadEpic
);
