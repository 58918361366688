import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { setIncidentApproval } from 'actions/actions-v2/incident-action-v2';
import { IRootState } from 'routes/store';
import { IncidentApprovalBar } from './incident-approval-bar';

const getCurrentUserId = (state: IRootState) => state.app.userId;

const mapStateToProps = createSelector(
    getCurrentUserId,
    (userId) => ({
        userId,
    })
);

const mapDispatchToProps =  {
    setIncidentApproval
};

export const IncidentApprovalBarContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(IncidentApprovalBar);
