import { createSelector } from 'reselect';
import { ICermOverviewRootState, INITIAL_STATE } from '../../../../reducers/cerm-overview';

const getLookupServiceFilters = (state: ICermOverviewRootState) =>
state.app.lookupServiceFilters;

const getLookupPriorityFilters = (state: ICermOverviewRootState) =>
state.app.lookupPriorities;

const getLookupPhaseFilters = (state: ICermOverviewRootState) =>
state.app.lookupPhases;

const getLookupPillars = (state: ICermOverviewRootState) =>
state.app.lookupPillars;

export default createSelector(
    getLookupServiceFilters,
    getLookupPriorityFilters,
    getLookupPhaseFilters,
    getLookupPillars,
    (state: ICermOverviewRootState) => state.cermOverview,
    (lookupServiceFilters, lookupPriorityFilters, lookupPhaseFilters, lookupPillarFilters, state) => ({
        lookupServiceFilters,
        lookupPriorityFilters,
        lookupPhaseFilters,
        lookupPillarFilters,
        ...state !== undefined ? state : INITIAL_STATE,
    })
);
