import React from 'react';

import appConfig from 'helpers/config-helper';
const config = appConfig();

const SnowRequestRedirectPage: React.FC = () => {
    React.useEffect(() => {
        window.location.href = config.REACT_APP_SERVICE_NOW_REQUEST;
    }, []);

    return <></>;
};

export default SnowRequestRedirectPage;
