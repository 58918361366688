import * as React from 'react';
import { Control, Field } from '../../../../components/form';
import { Button } from 'components/v2/components';
// import { ActionSortSelector } from '../actionSortSelector';
import useTranslate from 'translations/translation-utils';

export interface IStateProps {
    userId: string;
    siteId: string;
    sortColumn: string;
    canCreateAction: boolean;
}

export interface IActionProps {
    onClearFilters: () => void;
    onShowUpsertActionModal: (siteIdContext: string, userId: string) => void;
}

export const ActionGridToolbar: React.FC<IStateProps & IActionProps> = (props) => {
    const translate = useTranslate();

    const onClearFilters: React.MouseEventHandler<HTMLButtonElement> = () => {
        props.onClearFilters();
    };

    const onShowUpsertActionModal: React.MouseEventHandler<HTMLButtonElement> = () => {
        props.onShowUpsertActionModal(props.siteId, props.userId);
    };

    return (
        <div className="grid-toolbar">
            <div className="columns is-multiline is-mobile">
                <div className="column is-12-mobile is-7-tablet">
                    <Field className="is-grouped is-grouped-left" >
                        <Control className="is-expanded-mobile">
                            <Button className="is-medium is-fullwidth-mobile" onClick={onClearFilters}>
                                {translate('IncidentPage.Title.ClearFilters')}
                            </Button>
                        </Control>
                    </Field>
                </div>
                <div className="column is-12-mobile is-5-tablet">
                    <Field className="is-grouped is-grouped-right" >
                        <Control className="is-expanded">
                            {/* <ActionSortSelector
                                className="is-medium is-fullwidth"
                            /> */}
                        </Control>
                        {(props.canCreateAction && (
                            <Control className="is-expanded">
                                <Button
                                    onClick={onShowUpsertActionModal}
                                >
                                    {translate('RiskActionTab.Button.Addaction')}
                                </Button>
                            </Control>
                        ))}
                    </Field>
                </div>
            </div>
        </div>
    );
};
