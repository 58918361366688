import * as React from 'react';
import { Field, Control } from 'components/form';
import { ICermProcessOwnerQuestion } from './CermProcessOwnerQuestionsBuilder';
import Select from 'react-select';
import { IKeyValue } from 'models';
import { ICermProcessOwner } from 'models/cerm-process-owners';
import useTranslate from 'translations/translation-utils';

interface IProps {
    question: ICermProcessOwnerQuestion;
    cermProcessOwner: ICermProcessOwner;
    personsList: any;
    className?: string;
    onChange: (question: ICermProcessOwnerQuestion, persons: Array<IKeyValue<string>>) => void;
}

export const CermProcessOwnerQuestionField: React.FC<IProps> = ({
    question,
    cermProcessOwner,
    personsList,
    className,
    onChange
}) => {
    const translate = useTranslate();
    const getSelectedOwners = (questionFieldName: string) => {
        if (!personsList || !cermProcessOwner || !cermProcessOwner[questionFieldName]) {
            return [];
        }

        return personsList.filter((person) =>
            cermProcessOwner[questionFieldName].find((owner) => owner === person.value)
        );
    };

    const onChangeHandler = (owners: Array<IKeyValue<string>>) => {
        onChange(question, owners);
    };

    return !question.subQuestions || question.subQuestions.length < 1 ? (
        <div className="column is-12">
            <Field
                isHorizontal={true}
                htmlFor="owners"
                label={question.title}
                infoText={question.intro}
                className={className}
            >
                <Field>
                    <Control>
                        <Select
                            id={`question${question.title}`}
                            name="owners"
                            options={personsList}
                            value={getSelectedOwners(question.fieldName)}
                            isMulti={true}
                            onChange={onChangeHandler}
                            classNamePrefix="dropdown-multi"
                            placeholder={translate('Globals.Label.Select')}
                        />
                    </Control>
                </Field>
            </Field>
        </div>
    ) : (
        <>
            <div className="column is-12">
                <Field isHorizontal={true} htmlFor="owners" label={question.title} />
            </div>
            {question.subQuestions.map((subQuestion, key) => (
                <CermProcessOwnerQuestionField
                    key={key}
                    question={subQuestion}
                    cermProcessOwner={cermProcessOwner}
                    personsList={personsList}
                    className="question-sub"
                    onChange={onChange}
                />
            ))}
        </>
    );
};
