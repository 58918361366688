import { createSelector } from 'reselect';
import { IRootActionsState } from '../../../reducers/actions';
import { IRootState } from 'routes/store';

const getPermissions = createSelector(
    (state: IRootActionsState) => state.app,
    (appState) => appState.permissions
);

const getActionsState = createSelector(
    (state: IRootActionsState) => state.actions,
    (state) => state
);

const getExpandedItemIdArray = createSelector(
    (state: IRootActionsState) => state.actions.grid.expandedItems,
    (expandedItems) => expandedItems
);

const getCanEditAction = createSelector(
    (state: IRootActionsState) => state.app,
    (appState) => appState.canEditAction
);

const getSiteId = createSelector(
    (state: IRootActionsState) => state.app,
    (appState) => appState.siteId
);

const getIsInitialising = createSelector(
    (state: IRootActionsState) => state.actions.isInitialising,
    (appState) => appState
);

const getLookupActionCategories = (state: IRootState) => state.app.lookupActionCategories;
const getLookupActionPriorities = (state: IRootState) => state.app.lookupActionPriorities;
const getLookupActionStatuses = (state: IRootState) => state.app.lookupActionStatus;
const getLookupSites = (state: IRootState) => state.app.lookupSites;

export default createSelector(
    getActionsState,
    getIsInitialising,
    getExpandedItemIdArray,
    getCanEditAction,
    getSiteId,
    getPermissions,
    getLookupActionCategories,
    getLookupActionPriorities,
    getLookupActionStatuses,
    getLookupSites,
    (state, isInitialising, expandedItems, canEditAction, siteId, permissions, lookupActionCategory, lookupActionPriority, lookupActionStatus, lookupSites) => ({
        ...state,
        ...state.grid,
        isInitialising,
        lookupSites,
        expandedItems,
        canEditAction,
        siteId,
        permissions,
        lookupActionCategory,
        lookupActionPriority,
        lookupActionStatus
    })
);
