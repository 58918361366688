import * as React from 'react';
import { TimelineItem } from 'components/timeline';
import { TriStateToggle } from 'components/v2/components';
import { IAssessmentQuestion } from '../../../../../reducers/risk-assessment';
import './question.scss';

interface IProps {
    siteId: string;
    questionSetId: string;
    question: IAssessmentQuestion;
    questionIndex: number;
    onQuestionAnswerChanged: (
        questionId: string,
        questionSetId: string,
        answer: boolean | null
    ) => void;
}

export const Question: React.FC<IProps> = ({ question, ...props }) => {
    const onChange = (answer?: boolean) => {
        props.onQuestionAnswerChanged(question.id, props.questionSetId, answer);
    };

    return (
        <TimelineItem>
            <div className="columns">
                <div className="column is-2 grid__label is-uppercase">
                    Question {props.questionIndex}
                </div>
                <div className="column is-8">{question.title}</div>
                <div className="TimelineHeaderGridValue">
                    <TriStateToggle
                        selectedValue={question.answer}
                        onChange={onChange}
                        toggleFor={question.id}
                    />
                </div>
            </div>
        </TimelineItem>
    );
};
