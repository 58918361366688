import React from 'react';
import {
    Timeline,
    TimelineHeader,
    TimelineHeaderGridValue,
    TimelineHeaderGridValueCollection,
    TimelineItem
} from 'components/timeline';
import { IDifference } from 'models/difference';
import EngineerDifferenceItem from 'components/engineer-history/engineer-difference-item';
import { formatDateTime } from 'utils/date-utils';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';
import Dotdotdot from 'react-dotdotdot';

interface IEntityHistoryProps {
    differences: IDifference[];
    intl: any;
}

class EngineerEntitytHistoryItemClass extends React.PureComponent<IEntityHistoryProps> {

    public state = {
        isToggled: false
    };

    constructor(props: IEntityHistoryProps) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    public onClick() {
        const toggle = !this.state.isToggled;
        this.setState({ isToggled: toggle });
    }

    private getFieldsModified() {
        return [...new Set(this.props.differences.map((x) => x.propertyName.replace(/ *\[[^\]]*]/g, '')))].join(',');
    }

    public render(): JSX.Element {
        const { differences } = this.props;
        if (differences && differences.length > 0) {
            return (
                <Timeline expanded={this.state.isToggled} key={`${differences[0].parentObject2.id}`}>
                    <TimelineHeader
                        expanded={this.state.isToggled}
                        onClick={this.onClick}
                        centered={true}
                    >
                        <TimelineHeaderGridValueCollection>
                            <TimelineHeaderGridValue className="timeline-title column is-9" label="Details">
                                <Dotdotdot clamp={1} size={50}>{differences[0].parentObject2.lastUpdatedByPersonName}
                                    {' '} {translate(this.props.intl, 'History.HistoryItem.Updated')} {this.getFieldsModified()}
                                </Dotdotdot>
                            </TimelineHeaderGridValue>
                            <TimelineHeaderGridValue className="has-text-centered column i-5" label="Date/Time">
                                {formatDateTime(differences[0].parentObject2.lastUpdatedDateTime)}
                            </TimelineHeaderGridValue>
                        </TimelineHeaderGridValueCollection>
                    </TimelineHeader>
                    {differences.map((diff) => {
                        // tslint:disable-next-line: jsx-key
                        return <TimelineItem>
                            <EngineerDifferenceItem difference={diff} />
                        </TimelineItem>;
                    })}
                </Timeline>
            );
        }
    }
}

export const EngineerEntitytHistoryItem = injectIntl(EngineerEntitytHistoryItemClass);