import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { onSiteChange } from 'actions/app-actions';
import { SiteSelectorDialog } from './SiteSelectorDialog';

const getSites = createSelector(
    (state: IRootState) => state.app,
    (appState) => (appState.lookupSites.length > 0 ? appState.lookupSites : [])
);

const getSelectedSiteId = createSelector(
    (state: IRootState) => state.person.personProfile.selectedSite,
    (appState) => appState
);
const getLoading = createSelector(
    (state: IRootState) => state.person.isLoading,
    (appState) => appState
);

const mapStateToProps = createSelector(getSites, getSelectedSiteId, getLoading, (lookupSites, siteId, isLoading) => ({
    lookupSites,
    siteId,
    isLoading,
}));

const mapDispatchToProps = {
    onSiteChange,
};

export const SiteSelectorDialogContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SiteSelectorDialog)
);
