import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from './../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { SkillActions } from 'actions/actions-v2';
import { ISkill } from 'models';

const controllerName = 'skill';

const createActions = {
    action: SkillActions.CREATE_SKILL,
    actionFulfilled: SkillActions.CREATE_SKILL_FULFILLED,
    actionRejected: SkillActions.CREATE_SKILL_REJECTED,
} as IActionType;

const updateActions = {
    action: SkillActions.UPDATE_SKILL,
    actionFulfilled: SkillActions.UPDATE_SKILL_FULFILLED,
    actionRejected: SkillActions.UPDATE_SKILL_REJECTED,
} as IActionType;

const getAllActions = {
    action: SkillActions.LOAD_SKILLS,
    actionFulfilled: SkillActions.LOAD_SKILLS_FULFILLED,
    actionRejected: SkillActions.LOAD_SKILLS_REJECTED,
} as IActionType;

const getActions = {
    action: SkillActions.LOAD_SKILL,
    actionFulfilled: SkillActions.LOAD_SKILL_FULFILLED,
    actionRejected: SkillActions.LOAD_SKILL_REJECTED,
} as IActionType;

const deleteActions = {} as IActionType;

const createSkillEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createActions, '/Skill').post<ISkill>(`/api/${controllerName}`);
const updateSkillEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateActions, '/Skill').put<ISkill>(`/api/${controllerName}`);
const loadSkillsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getAllActions).get<ISkill[]>(`/api/${controllerName}`);
const loadSkillEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getActions).getById<ISkill>(`/api/${controllerName}`);
const deleteSkillEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, deleteActions).deleteById<string>(`/api/${controllerName}`);

export const skillEpicsV2 = combineEpics(
    createSkillEpic,
    updateSkillEpic,
    loadSkillsEpic,
    loadSkillEpic,
    deleteSkillEpic
);
