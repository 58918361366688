import { ErrorMessage, Field } from 'formik';
import React from 'react';

interface IProps {
    lable: string;
    value: string;
    type: InputType;
}

export default class FormikInput extends React.PureComponent<IProps> {

    public render() {
        return (
        <div className="field">
            <label className="label">{this.props.lable}</label>
            <div className="control">
                <Field  className={this.props.type} type="text" name={this.props.value} component={this.props.type} />
                <ErrorMessage
                    name={this.props.value}
                    render={this.ErrorMessage}
                />
            </div>
        </div>);
    }
    private ErrorMessage(error: string): React.ReactNode {
        return <div className="help is-danger">{error}</div>;
    }
}

export enum InputType {
    input = 'input',
    textarea = 'textarea'
}
