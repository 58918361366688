import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { IOperatingPlatformActions } from 'actions/actions-v2/operating-platform-action';

const controllerName = 'OperatingPlatform';
const menuControllerName = 'Menu';
const apiPath = `/api/${controllerName}`;
const apiMenuPath = `/api/${menuControllerName}`;
//const operatingPlatformListUrl = '/operating-platform/list';

const getOperatingPlatformActions = {
    action: IOperatingPlatformActions.LOAD_OPERATING_PLATFORM,
    actionFulfilled: IOperatingPlatformActions.LOAD_OPERATING_PLATFORM_FULFILLED,
    actionRejected: IOperatingPlatformActions.LOAD_OPERATING_PLATFORM_REJECTED,
} as IActionType;

const createOperatingPlatformActions = {
    action: IOperatingPlatformActions.CREATE_OPERATING_PLATFORM,
    actionFulfilled: IOperatingPlatformActions.CREATE_OPERATING_PLATFORM_FULFILLED,
    actionRejected: IOperatingPlatformActions.CREATE_OPERATING_PLATFORM_REJECTED,
} as IActionType;

const updateOperatingPlatformActions = {
    action: IOperatingPlatformActions.UPDATE_OPERATING_PLATFORM,
    actionFulfilled: IOperatingPlatformActions.UPDATE_OPERATING_PLATFORM_FULFILLED,
    actionRejected: IOperatingPlatformActions.UPDATE_OPERATING_PLATFORM_REJECTED,
} as IActionType;

const updateOperatingPlatformMenusActions = {
    action: IOperatingPlatformActions.UPDATE_OPERATING_PLATFORM_MENUS,
    actionFulfilled: IOperatingPlatformActions.UPDATE_OPERATING_PLATFORM_MENUS_FULFILLED,
    actionRejected: IOperatingPlatformActions.UPDATE_OPERATING_PLATFORM_MENUS_REJECTED,
} as IActionType;

const deleteOperatingPlatformActions = {
    action: IOperatingPlatformActions.DELETE_OPERATING_PLATFORM,
    actionFulfilled: IOperatingPlatformActions.DELETE_OPERATING_PLATFORM_FULFILLED,
    actionRejected: IOperatingPlatformActions.DELETE_OPERATING_PLATFORM_REJECTED,
} as IActionType;

const loadMenusForOperatingPlatformActions = {
    action: IOperatingPlatformActions.LOAD_MENUS_FOR_OPERATING_PLATFORM,
    actionFulfilled: IOperatingPlatformActions.LOAD_MENUS_FOR_OPERATING_PLATFORM_FULFILLED,
    actionRejected: IOperatingPlatformActions.LOAD_MENUS_FOR_OPERATING_PLATFORM_REJECTED,
} as IActionType;

const loadOperatingPlatformEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getOperatingPlatformActions).get(`${apiPath}`);

const loadMenusForOperatingPlatformEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, loadMenusForOperatingPlatformActions).get(`${apiMenuPath}/operating-platform-menus`);

const createOperatingPlatformEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createOperatingPlatformActions).post(`${apiPath}`);

const updateOperatingPlatformEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateOperatingPlatformActions).put(`${apiPath}`);

const updateOperatingPlatformMenusEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateOperatingPlatformMenusActions).post(`${apiMenuPath}`);

const deleteOperatingPlatformEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, deleteOperatingPlatformActions).deleteById(`${apiPath}`);

export const IOperatingPlatformEpics = combineEpics(
    loadOperatingPlatformEpic,
    createOperatingPlatformEpic,
    updateOperatingPlatformEpic,
    deleteOperatingPlatformEpic,
    updateOperatingPlatformMenusEpic,
    loadMenusForOperatingPlatformEpic);
