import _ from 'lodash';
import * as React from 'react';
import './assignedItem-dialog.scss';
import { IPersonAssignedItem } from 'models/person';
import parse from 'html-react-parser';
import { Button } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';
import { replaceModuleOrRoleNameWithTranslationKey } from 'routes/bulk-reassign/bul-reassign-helper';

interface IProps {
    personAssignedItem: IPersonAssignedItem;
    moduleName: string;
    roleName: string;
    setIsShown: (shown: boolean) => void;
}

export const AssignedItemDialog: React.FC<IProps> = ({ personAssignedItem, moduleName, roleName, setIsShown }) => {
    const [items, setItems] = React.useState<string[]>([]);

    React.useEffect(() => {
        let result = []
        if (personAssignedItem) {
            const assignItems = personAssignedItem.assignItems.filter(x => x.moduleName == moduleName && x.roleName == roleName)
            if (assignItems && assignItems.length > 0) {
                result = assignItems[0].items
            }
        }
        setItems(result)
    }, [personAssignedItem, moduleName, roleName])

    const translate = useTranslate();

    return (
        <>
            {personAssignedItem && personAssignedItem.assignItems?.length > 0 &&
                <div className="columns">
                    <div className="column is-12">
                        <h4 className="title is-4 is-uppercase">
                            {translate(
                                replaceModuleOrRoleNameWithTranslationKey(moduleName))}
                        </h4>
                        {moduleName != roleName &&
                            <div className="columns">
                                <div className="column is-4">
                                    <h2>
                                        {translate(
                                            replaceModuleOrRoleNameWithTranslationKey(roleName))}
                                    </h2>
                                </div>
                            </div>
                        }
                        {items && items.map((item, key) => {
                            return (
                                <div className="columns">
                                    <div
                                        key={key}
                                        className="column is-12"
                                    >
                                        {parse(item)}
                                    </div>
                                </div>
                            )
                        })}
                        <div className="columns">
                            <div className="column is-12 assignedItem-aligh-left">
                                <Button onClick={() => setIsShown(false)} >
                                    {translate('BulkReassign.Button.Close')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
