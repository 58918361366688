import * as React from 'react';
import { IIncident, IIncidentsFilters, IKeyValue } from 'models';
import { IncidentsList, IncidentStatusCounts } from 'components/incidents';
import { IncidentsGridToolbarContainer } from 'components/incidents-grid-toolbar/incidents-grid-toolbar-container';
import { connect } from 'react-redux';
import actions from './actions';
import selectors from './selectors';
import { IncidentsFilters } from 'components/incidentsFilters/incidents-filters';
import { OverlayPanel } from 'components/v2/components';
import Page from 'components/v2/page/Page';
import { isEmpty } from 'lodash';
import { useTranslate } from 'translations/translation-utils';
import { HelpModuleContainer } from 'routes/help-module/help-module-container';
import { ModuleType } from 'models/help-module';
import { checkFilterExists } from 'utils/array-utils';
import { RouteComponentProps, useParams } from 'react-router';
import { useLocation } from 'react-router-dom'

export interface IIncidentsProps extends RouteComponentProps<IRouteParams> {
    siteId: string;
    intl: any;
    combinedSiteFeatures: string[];
    incidents: IIncident[];
    isLoading: boolean;
    lookupSites: Array<IKeyValue<string>>;
    secondarySortColumn: string;
    secondarySortAscending: boolean;
    isExportingIncidents: boolean;
    loadIncidents(filters: IIncidentsFilters): void;
    onSort: (key: string, sortAscending: boolean) => void;
    showHelpModule: boolean;
}

export interface IIncidentsState {
    showFilters: boolean;
    filters: IIncidentsFilters;
    sortColumn: string;
    sortAscending: boolean;
    showHelp: boolean;
}

interface IRouteParams {
    specialFilter?: string;
}
export const Incidents: React.FC<IIncidentsProps> = (props) => {
    const translate = useTranslate();
    const params: any = useParams();
    const location = useLocation();
    const [showFilters, setShowFilters] = React.useState(false);
    const [sortColumn, setSortColumn] = React.useState('');
    const [sortAscending, setSortAscending] = React.useState(false);
    const [showHelp, setShowsHelp] = React.useState(false);
    const [filters, setFilters] = React.useState<IIncidentsFilters>({
        periods: [],
        severities: [],
        siteId: props.siteId,
        statuses: [],
        types: [],
        filterKeywords: [],
        specialFilters: []
    });
    const buildRequestFiltersObject = (keepState: boolean) => {
        return {
            periods: filters && keepState ? filters.periods : [],
            severities: filters && keepState ? filters.severities : [],
            statuses: filters && keepState ? filters.statuses : [],
            types: filters && keepState ? filters.types : [],
            filterKeywords: filters && keepState ? filters.filterKeywords : [],
            specialFilters: filters && keepState ? filters.specialFilters : [],
            siteId: props.siteId,
        } as IIncidentsFilters;
    };


    React.useEffect(() => {
        onClearFilters();
    }, [])

    React.useEffect(() => {

        if (!props.siteId) {
            return;
        }
        props.loadIncidents(buildRequestFiltersObject(true));

    }, [props.siteId, filters])

    React.useEffect(() => {

        let buildRequestFilters = buildRequestFiltersObject(false)
        if (params.specialFilter) {

            buildRequestFilters = {
                ...buildRequestFilters,
                specialFilters: [params.specialFilter],
                statuses: ["Open", "Pending"]


            }
            setFilters(buildRequestFilters)
        }
        props.loadIncidents(buildRequestFilters);
    }, [params.specialFilter, location.key]);



    const isFilterExists = checkFilterExists(filters);

    const onClickHelp = () => {
        setShowsHelp(!showHelp)
    };

    const onSort = (key: string, sortAscending: boolean) => {
        props.onSort(key, sortAscending);
        setSortAscending(sortAscending);
        setSortColumn(key)
    };

    const onAddFilterKeyword = (keyword: string) => {
        const filter = { ...filters };
        const keywords = [
            keyword,
            ...filter.filterKeywords.slice(0, filters.filterKeywords.length),
        ];
        filter.filterKeywords = keywords;
        // setState({ filters });
        setFilters(filter)
        props.loadIncidents(filter);
    };

    const onClearFilters = () => {

        setFilters(buildRequestFiltersObject(false));
    };

    const onRemoveFilterKeyword = (keyword: string) => {
        const newfilter = { ...filters } as IIncidentsFilters;;
        const index = newfilter.filterKeywords.indexOf(keyword);
        const keywords = [
            ...newfilter.filterKeywords.slice(0, index),
            ...newfilter.filterKeywords.slice(index + 1),
        ];
        newfilter.filterKeywords = keywords;
        setFilters(newfilter);
        props.loadIncidents(newfilter);
    };
    const getUpdatedFilters = (
        updatedActionFilters: IIncidentsFilters,
        key: Extract<keyof IIncidentsFilters, string>,
        element: string
    ) => {
        const specialFilters = ['Closed'];
        if (specialFilters.some((c) => c === element)) {
            if (updatedActionFilters.statuses.find((c) => c === 'Closed')) {
                return {
                    ...updatedActionFilters,
                    statuses: [],
                };
            }
            return {
                ...updatedActionFilters,
                statuses: [element],
            };
        }

        const newFilters = { ...updatedActionFilters };
        const index = newFilters[key].indexOf(element);
        if (index !== -1) {
            const items = [...newFilters[key].slice(0, index), ...newFilters[key].slice(index + 1)];
            newFilters[key] = items;
        } else {
            const items = [element, ...newFilters[key].slice(0, newFilters[key].length)];
            newFilters[key] = items;
        }
        const filtersWithOutSpecials = {
            ...newFilters,
            statuses: newFilters.statuses.filter(
                (f) => !specialFilters.some((c) => c === f)
            ),
        } as IIncidentsFilters;

        return filtersWithOutSpecials;
    };

    const onFilter = (key: Extract<keyof IIncidentsFilters, string>, element: string) => {
        const updatedFilters = getUpdatedFilters(buildRequestFiltersObject(true), key, element);
        setFilters(updatedFilters);
    };

    return (
        <Page
            title={translate('IncidentPage.Title.Incidents&NearMisses')}
            scrollTopOnPageLoad={true}
        >
            <IncidentsGridToolbarContainer
                onShowFilters={() => setShowFilters(true)}
                onClearFilters={onClearFilters}
                isExportingIncidents={
                    props.isExportingIncidents || isEmpty(props.incidents)
                }
                isShowHelp={props.showHelpModule || true}
                onClickHelp={onClickHelp}
                isFilterExists={isFilterExists}
            />
            <HelpModuleContainer
                isShown={showHelp}
                onClose={onClickHelp}
                moduleType={ModuleType.Incident}
            />
            <OverlayPanel
                title={translate('ActionRegisterPage.Title.Filters')}
                isVisible={showFilters}
                onClose={() => setShowFilters(false)}
                onOutsideDialogClick={() => setShowFilters(false)}
                onClearFilters={onClearFilters}
                isFilterExists={isFilterExists}
            >
                <IncidentsFilters
                    onFilter={onFilter}
                    filters={filters}
                    onAddFilterKeyword={onAddFilterKeyword}
                    onRemoveFilterKeyword={onRemoveFilterKeyword}
                />
            </OverlayPanel>
            <div className="columns">
                <div className="column is-offset-8 is-4">
                    <IncidentStatusCounts
                        items={props.incidents}
                        isLoading={props.isLoading}
                    />
                </div>
            </div>
            <IncidentsList
                {...props}
                onSort={onSort}
                sortColumn={sortColumn}
                sortAscending={sortAscending}
            />
        </Page>
    );

}
export default connect(selectors, actions)(Incidents);
