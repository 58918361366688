import { Formik, FormikProps } from 'formik';
import * as React from 'react';
import { object, string } from 'yup';
import { PopoutSelect } from 'components/popoutSelect';
import { IPersonLookup } from 'models/person-lookup';
import { ISelectOption } from 'models/select-option';
import { DatePicker } from '../../../../components/datepicker/DatePicker';
import { Control, Field, Help, Input } from '../../../../components/form';
import { Modal } from '../../../../components/modal';
import { Select } from '../../../../components/select';
import { BulmaColor } from '../../../../enums/BulmaColor';
import { BulmaSize } from '../../../../enums/BulmaSize';
import { IKeyValue } from '../../../../models/key-value';
import { IUpsertRiskRequest } from '../../../../models/upsert-risk-request';
import { formatDate } from '../../../../utils/date-utils';
import { formikFieldCss, onPopoutSelectChange } from '../../../../utils/form-utils';
import './riskUpsertModal.scss';
import { Button } from 'components/v2/components';
import { Loader } from 'components/loader';
import { useTranslate } from 'translations/translation-utils';
import { ISiteProfile } from 'models/site-profile';
import { ICommonLookupModel } from 'models/common-lookup-model';
import { RiskCategory } from 'models/risk-category';
import * as RiskStatus from 'models/risk-statuses';
import moment from 'moment';

export interface IStateProps {
    lookupRiskCategories: RiskCategory[];
    lookupRiskStatus: Array<IKeyValue<string>>;
    lookupRiskLikelihoods: Array<IKeyValue<string>>;
    lookupRiskImpacts: Array<IKeyValue<string>>;
    lookupSites: Array<IKeyValue<string>>;
    upsertRiskRequest?: IUpsertRiskRequest;
    lookupSitePeopleRoles: IPersonLookup[];
    isShown: boolean;
    isSaving?: boolean;
}

export interface IActionProps {
    onClose: () => void;
    onSubmit: (request: IUpsertRiskRequest) => void;
}

export interface IProps extends IStateProps, IActionProps {
    site: ISiteProfile;
}

const publishRiskSchema = object<Partial<IUpsertRiskRequest>>().shape({
    siteId: string().required('Please select a site'),
    title: string().trim().required('Please enter a title'),
    description: string().trim().required('Please enter a description'),
    likelihoodId: string().required('Please select a likelihood'),
    impactId: string().required('Please select a impact'),
    riskCategoryId: string().required('Please select a category'),
    riskSubCategoryId: string().required('Please select a sub category'),
    riskStatusId: string().required('Please select a risk status'),
    reviewDate: string().required('Please enter a review date'),
    assigneeId: string().required('Please select a assignee'),
});


export const RiskUpsertModalView: React.FC<IProps> = (props) => {
    const translate = useTranslate();
    const renderForm = (formikBag: FormikProps<IUpsertRiskRequest>) => {
        // Default site if you're in a site context, otherwise allow you to select one
        const withinSiteContext = Boolean(formikBag.initialValues.siteId);
        const handleDateChange = (date: Date) => {
            formikBag.setFieldValue('reviewDate', date);
        };
        const getLookupSitePeople = (lookups: IPersonLookup[]) => {
            const typeaheadOptions: ISelectOption[] = lookups.map((lookup) => {
                const roles = lookup.roleNames.join(', ');
                if (roles) {
                    const label = `${lookup.label} (${roles})`;
                    return { label, value: lookup.id };
                } else {
                    return { label: lookup.label, value: lookup.id };
                }
            });

            return typeaheadOptions;
        };

        const riskCategories = props.lookupRiskCategories.filter(
            (x) =>
                !x.operatingPlatform ||
                x.operatingPlatform === props.site.operatingPlatform
        );


        const subCategories: ICommonLookupModel[] = formikBag.values.riskCategoryId
            ? riskCategories
                .find((x) => x.key === formikBag.values.riskCategoryId)
                ?.subCategory?.filter(
                    (x) => !x.operatingPlatform || x.operatingPlatform === props.site.operatingPlatform
                )
            ?? []
            : [];

        return (
            <form id="riskUpsertModal" onSubmit={formikBag.handleSubmit}>
                <div className="columns">
                    <div className="column is-9">
                        <Field
                            isHorizontal={true}
                            htmlFor="siteId"
                            label={translate('RiskActionTab.Label.Site')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Select
                                        id="siteId"
                                        name="siteId"
                                        aria-required="true"
                                        options={props.lookupSites}
                                        className={formikFieldCss(formikBag, 'siteId')}
                                        value={formikBag.values.siteId}
                                        disabled={withinSiteContext}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                        emptyOptionsValue={translate('Globals.Label.Select')}
                                    />
                                </Control>
                                <Help
                                    isShown={formikBag.touched.siteId}
                                    bulmaColor={BulmaColor.Danger}
                                >
                                    {formikBag.errors.siteId}
                                </Help>
                            </Field>
                        </Field>
                        <Field
                            isHorizontal={true}
                            htmlFor="title"
                            label={`${translate('IncidentRiskActionsModel.Label.FullTitle')}*`}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Input
                                        id="title"
                                        name="title"
                                        aria-required="true"
                                        placeholder={translate('IncidentRiskActionsModel.placeholder.FullTitle')}
                                        type="text"
                                        className={formikFieldCss(formikBag, 'title')}
                                        value={formikBag.values.title}
                                        onChange={formikBag.handleChange}
                                        onBlur={(event) => formikBag.setFieldValue(event.target.name, event.target.value.trim())}
                                    />
                                </Control>
                                <Help
                                    isShown={formikBag.touched.title}
                                    bulmaColor={BulmaColor.Danger}
                                >
                                    {formikBag.errors.title}
                                </Help>
                            </Field>
                        </Field>
                        <Field
                            isHorizontal={true}
                            htmlFor="description"
                            label={translate('RiskDetailsPage.label.Description')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <textarea
                                        id="description"
                                        name="description"
                                        aria-required="true"
                                        className={formikFieldCss(
                                            formikBag,
                                            'description',
                                            'textarea'
                                        )}
                                        placeholder={translate('IncidentRiskActionsModel.placeholder.Description')}
                                        value={formikBag.values.description}
                                        onBlur={formikBag.handleBlur}
                                        onChange={formikBag.handleChange}
                                    />
                                </Control>
                                <Help
                                    isShown={formikBag.touched.description}
                                    bulmaColor={BulmaColor.Danger}
                                >
                                    {formikBag.errors.description}
                                </Help>
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor="likelihoodId"
                            label={`${translate('IncidentRiskActionsModel.Label.Likelihood')}*`}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Select
                                        id="likelihoodId"
                                        name="likelihoodId"
                                        aria-required="true"
                                        options={props.lookupRiskLikelihoods.map((c) => ({
                                            key: c.key,
                                            value: translate(
                                                'RiskDetailsPage.Likelihood.dropdown.'.concat(
                                                    c.value.replace(/\s/g, '')
                                                )
                                            ),
                                        }))}
                                        className={formikFieldCss(formikBag, 'likelihoodId')}
                                        value={formikBag.values.likelihoodId}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                        emptyOptionsValue={translate(
                                            'IncidentRiskActionsModel.EmptyOptionsValue'
                                        )}
                                    />
                                </Control>
                                <Help
                                    isShown={formikBag.touched.likelihoodId}
                                    bulmaColor={BulmaColor.Danger}
                                >
                                    {formikBag.errors.likelihoodId}
                                </Help>
                            </Field>
                            <Field>
                                <Control>
                                    <Select
                                        id="impactId"
                                        name="impactId"
                                        aria-required="true"
                                        options={props.lookupRiskImpacts.map((c) => ({
                                            key: c.key,
                                            value: translate(
                                                'RiskDetailsPage.Impact.dropdown.'.concat(
                                                    c.value.replace(/\s/g, '')
                                                )
                                            ),
                                        }))}
                                        className={formikFieldCss(formikBag, 'impactId')}
                                        value={formikBag.values.impactId}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                        emptyOptionsValue={translate(
                                            'IncidentRiskActionsModel.EmptyOptionsValue'
                                        )}
                                    />
                                </Control>
                                <Help
                                    isShown={formikBag.touched.impactId}
                                    bulmaColor={BulmaColor.Danger}
                                >
                                    {formikBag.errors.impactId}
                                </Help>
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor="riskCategoryId"
                            label={`${translate('IncidentRiskActionsModel.Label.Category')}*`}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Select
                                        id="riskCategoryId"
                                        name="riskCategoryId"
                                        aria-required="true"
                                        options={riskCategories.map((c) => ({
                                            key: c.key,
                                            value: translate(
                                                'RiskDetailsPage.Category.dropdown.'.concat(
                                                    c.value.replace(/\s/g, '')
                                                )
                                            ),
                                        }))}
                                        className={formikFieldCss(formikBag, 'riskCategoryId')}
                                        value={formikBag.values.riskCategoryId}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                        emptyOptionsValue={translate(
                                            'IncidentRiskActionsModel.EmptyOptionsValue'
                                        )}
                                    />
                                </Control>
                                <Help
                                    isShown={formikBag.touched.riskCategoryId}
                                    bulmaColor={BulmaColor.Danger}
                                >
                                    {formikBag.errors.riskCategoryId}
                                </Help>
                            </Field>
                        </Field>
                        <Field
                            isHorizontal={true}
                            htmlFor="riskSubCategoryId"
                            label={`${translate('IncidentRiskActionsModel.Label.SubCategory')}*`}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Select
                                        id="riskSubCategoryId"
                                        name="riskSubCategoryId"
                                        aria-required="true"
                                        options={subCategories.map((c) => ({
                                            key: c.key,
                                            value: c.value,
                                        }))}
                                        className={formikFieldCss(formikBag, 'riskSubCategoryId')}
                                        value={formikBag.values.riskSubCategoryId}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                        emptyOptionsValue={translate(
                                            'IncidentRiskActionsModel.EmptyOptionsValue'
                                        )}
                                        disabled={!formikBag.values.riskCategoryId}
                                    />
                                </Control>
                                <Help
                                    isShown={formikBag.touched.riskSubCategoryId}
                                    bulmaColor={BulmaColor.Danger}
                                >
                                    {formikBag.errors.riskSubCategoryId}
                                </Help>
                            </Field>
                        </Field>
                        <Field
                            isHorizontal={true}
                            htmlFor="assigneeId"
                            label={translate('RiskActionTab.Label.Assignee')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <PopoutSelect
                                        id="assigneeId"
                                        name="assigneeId"
                                        className={formikFieldCss(formikBag, 'riskAssigneeId')}
                                        value={formikBag.values.assigneeId}
                                        onBlur={formikBag.handleBlur}
                                        onChange={formikBag.handleChange}
                                        onSelectChange={onPopoutSelectChange.bind(
                                            this,
                                            formikBag,
                                            'assigneeId'
                                        )}
                                        options={getLookupSitePeople(
                                            props.lookupSitePeopleRoles
                                        )}
                                    />
                                </Control>
                                <Help
                                    isShown={formikBag.touched.assigneeId}
                                    bulmaColor={BulmaColor.Danger}
                                >
                                    {formikBag.errors.assigneeId}
                                </Help>
                            </Field>
                        </Field>
                        <Field
                            isHorizontal={true}
                            htmlFor="riskStatusId"
                            label={translate('RiskActionTab.Label.Status')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Input
                                        id="riskStatusId"
                                        name="riskStatusId"
                                        aria-required="true"
                                        type="text"
                                        className={formikFieldCss(formikBag, 'riskStatusId')}
                                        value={RiskStatus.OPEN_AND_PENDING_REVIEW}
                                        readOnly={true}
                                    />
                                </Control>
                                <Help
                                    isShown={formikBag.touched.riskStatusId}
                                    bulmaColor={BulmaColor.Danger}
                                >
                                    {formikBag.errors.riskStatusId}
                                </Help>
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor="reviewDate"
                            label={translate('RiskDetailsPage.label.Reviewdate')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <DatePicker
                                        id="reviewDate"
                                        name="reviewDate"
                                        value={formatDate(formikBag.values.reviewDate)}
                                        minDate={moment(new Date()).add(1, 'day').toDate()}
                                        onChange={handleDateChange}
                                    />
                                </Control>
                                <Help
                                    isShown={formikBag.touched.reviewDate}
                                    bulmaColor={BulmaColor.Danger}
                                >
                                    {formikBag.errors.reviewDate}
                                </Help>
                            </Field>
                        </Field>
                    </div>
                    <div className="column is-3">
                        <Button
                            id="riskUpsertModal__save-published"
                            onClick={onSaveAsPublished.bind(this, formikBag)}
                            disabled={!formikBag.dirty || formikBag.isSubmitting}
                            isLoading={props.isSaving}
                        >
                            {translate('RiskActionTab.Button.Save')}
                        </Button>
                        <br />
                        <br />
                        <Button
                            id="riskUpsertModal__cancel"
                            buttonType="cancel"
                            onClick={onClose}
                            isLoading={props.isSaving}
                        >
                            {translate('RiskActionTab.Button.Cancel')}
                        </Button>
                    </div>
                </div>
            </form>
        );

    };
    const onSaveAsPublished = (formikBag: FormikProps<IUpsertRiskRequest>) => {
        formikBag.setFieldValue('isPublished', true);
        formikBag.setFieldValue('origin', 'Incidents');
        formikBag.submitForm()

    };

    const onClose: React.MouseEventHandler<HTMLButtonElement> = () => {
        props.onClose();
    };
    return (
        <Modal modalId="upsertRiskModal" isShown={props.isShown}>
            <Loader loading={props.isSaving}>
                <Formik
                    initialValues={props.upsertRiskRequest}
                    validationSchema={publishRiskSchema}
                    onSubmit={props.onSubmit}
                    render={renderForm}
                />
            </Loader>
        </Modal>
    );
}

export const RiskUpsertModal = RiskUpsertModalView;
