import { createSelector } from 'reselect';

import { getLookupActionCategories, getLookupActionPriorities, getLookupActionStatuses, getLookupActionSpecial } from 'sharedSelectors';
import { IRootActionsState } from 'reducers/actions';

const getActionState = createSelector(
    (state: IRootActionsState) => state.actions,
    (state) => state
);

export default createSelector(
    getActionState,
    getLookupActionCategories,
    getLookupActionPriorities,
    getLookupActionStatuses,
    getLookupActionSpecial,
    (state, lookupActionCategory, lookupActionPriority, lookupActionStatus, lookupActionSpecial) => ({
        ...state,
        ...state.grid,
        lookupActionCategory,
        lookupActionPriority,
        lookupActionStatus,
        lookupActionSpecial
    })
);
