import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';

const getIsExportingSites = createSelector(
    (state: IRootState) => state.siteProfiles.grid.isExportingSites,
    (state) => {
        return state;
    }
);

export default createSelector(
    getIsExportingSites,
    (isExportingSites) => ({
        isExportingSites
    })
);
