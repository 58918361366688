export const riskEs = `
<div>
<p>Haga clic en el enlace de abajo para ver:</p>
<p><a href="https://confluence.cbre.com/x/u7zSC" target= "_blank">Proceso de Flujo de Riesgo</a></p>
</div>
<br />
<div>
<p><a href="https://quantum.cbre.com/issues" target= "_blank">Reportar un problema</a></p>
<p><a href="https://quantum.cbre.com/requests" target= "_blank">Levantar una solicitud</a></p>
</div>`;
