import * as React from 'react';

interface IProps {
    type?: 'normal' | 'save';
    className?: string;
}

export const Toolbar: React.FC<React.PropsWithChildren<IProps>> = ({ type = '', className = '', ...props }) => {
    return (
        <div className={`toolbar toolbar-${type} is-mobile columns ${className}`}>
            {props.children}
        </div>
    );
};
