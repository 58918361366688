import * as React from 'react';
import { GetPercentage } from 'utils/decimal-utils';
import { Spinner } from '../spinner';
import useTranslate from 'translations/translation-utils';

interface IProps {
    score: number;
    isLoading?: boolean;
}

export const CermComplianceScore: React.FC<IProps> = ({ score, isLoading = false }) => {
    const translate = useTranslate();
    return (
        <div className="score-compliance">
            <label>{translate('Cerm.ComplianceScore.Labels.Compliance')}:</label> {isLoading ? <Spinner /> : GetPercentage(score)}
        </div>
    );
};
