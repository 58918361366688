import { IRootState } from './../../../../routes/store';
import { createSelector } from 'reselect';

const getEngineer = (state: IRootState) => state.engineerState.engineer;
const getIsLoading = (state: IRootState) => state.engineerState.isLoading;
const getSkillCategories = (state: IRootState) => state.engineersState.skillCategories;
const getExpandedCategories = (state: IRootState) => state.engineerState.expandedCategories;
const getHistory = (state: IRootState) => state.engineerState.history;

export default createSelector(
    getEngineer,
    getExpandedCategories,
    getIsLoading,
    getSkillCategories,
    getHistory,
    (engineer, expandedCategories, isLoading, skillCategories, engineerHistory) => ({
        engineer,
        expandedCategories,
        isLoading,
        skillCategories,
        engineerHistory
    })
);
