import * as React from 'react';
import { AsidePanel } from '../panel';

export interface IProgressSection {
    title?: string;
    text?: string;
    urlLocation?: string;
}

export interface IProps {
    title: string;
    subtitle: string;
    sections: IProgressSection[];
    currentPath: string;
}

export interface IActions {
    onChangeRoot: (urlLocation: string) => void;
}

export const ProgressBar: React.FC<IProps & IActions> = (props) => {
    return (
        <AsidePanel className="column is-3">
            <header>
                <h2 className="title is-4">{props.title}</h2>
                <small>{props.subtitle}</small>
            </header>
            <div className="Panel__content">
                {props.sections.map((section, index) => {
                    const additionalCss =
                        section.urlLocation === props.currentPath ? 'selected' : '';
                    return (
                        <section key={index} className={`Panel__content__section ${additionalCss}`}>
                            {section.title && (
                                <header className="Panel__content__section__header level">
                                    <a
                                        className={`level-right ${additionalCss}`}
                                        onClick={props.onChangeRoot.bind(this, section.urlLocation)}
                                    >
                                        <h3 className="Panel__content__section__header__title level-left">
                                            {section.title}
                                        </h3>
                                    </a>
                                </header>
                            )}
                            {section.text && (
                                <p className="Panel__content__section__content">{section.text}</p>
                            )}
                        </section>
                    );
                })}
            </div>
        </AsidePanel>
    );
};
