import * as React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { INotification, INotificationCount } from 'models/notification';
import logo from 'assets/CBREQuantum-NewLogo_White.svg';
import { EnvironmentSign, NavBar } from '../nav/NavBar';
import { IDropdownItem, NavDropdown } from '../nav/NavDropdown';
import NotificationNavDropdown from '../nav/NotificationNavDropDown';
import { IPerson } from 'models/person';
import { createSignalRConfig } from 'routes/signalR';
import { NavHelpModule } from '../nav/NavHelpModule';
import { NavModuleNotification } from '../nav/NavModuleNotification';
import { getUserId } from 'helpers/helpers';
import { IKeyValue } from 'models';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import {
    MaterialIconType,
    MaterialIconColor,
    MaterialIconSize,
} from 'routes/material-icon/material-icon-type';
import { IOperatingPlatform } from 'models/operating-platform-model';

export interface IStateProps {
    fullName: string;
    isUserMenuExpanded: boolean;
    userMenuItems: IDropdownItem[];
    notifications: INotification[];
    language: string;
    isInitialising: boolean;
    person: IPerson;
    siteId: string;
    isLoadingSite: boolean;
    isClient: boolean;
    moduleNotificationCount: INotificationCount[];
    loadPersonProfile: (id: string) => void;
    updateLanguage: (person: IPerson) => void;
    updateIsShowFaq: (person: IPerson) => void;
    loadNotificationCount: (siteId: string) => void;
    showRoleSelectorDialog: (show: boolean) => void;
    lookupSites: IKeyValue<string>[];
    operatingPlatforms: IOperatingPlatform[];
    showOperatingPlatformSelectorDialog: (show: boolean) => void;
}

export interface IDispatchProps {
    onToggleExpandUserMenu: (expanded: boolean) => void;
    onUpdateNotification: (notification: INotification) => void;
    onNotificationRead: (id: string, notificationLink: string) => void;
    onMarkAllRead: () => void;
}

export const Masthead: React.FC<IStateProps & IDispatchProps> = props => {
    const notificationCount: number = 5;

    React.useEffect(() => {
        createSignalRConfig(props.onUpdateNotification);
    }, []);

    React.useEffect(() => {
        if (!getUserId()) {
            return;
        }
        props.loadPersonProfile(getUserId());
    }, [getUserId()]);

    React.useEffect(() => {
        if (props.siteId) {
            props.loadNotificationCount(props.siteId);
        }
    }, [props.siteId]);

    const getNotificationElement = () => {
        return (
            <>
                <span className="icon icon-notification">
                    <MaterialIcons
                        type={MaterialIconType.Notifications}
                        color={MaterialIconColor.white}
                        size={MaterialIconSize.medium}
                    />
                    <span className="badge badge-pill badge-warning">
                        {props.notifications.filter(n => !n.read).length}
                    </span>
                </span>
            </>
        );
    };

    const getProfileLinkElement = () => {
        return (
            <>
                <MaterialIcons
                    type={MaterialIconType.Avatar}
                    color={MaterialIconColor.white}
                    size={MaterialIconSize.medium}
                />
            </>
        );
    };

    const getOperatingPlatform = (SelectedSiteId: string, lookupSites: any) => {
        const site = lookupSites.find(x => x.key === SelectedSiteId);
        return site ? site.operatingPlatform : '';
    };

    const selectedOperatingPlatform = props?.operatingPlatforms.find(
        x => x.value === getOperatingPlatform(props.siteId, props.lookupSites)
    );

    return (
        <>
            <div className="section masthead">
                <NavBar className="masthead__container">
                    <div className="navbar-brand">
                        <NavLink className="navbar-item masthead__link--home" to="/Dashboard">
                            <img src={logo} alt="CBRE Quantum" className="is-hidden-mobile" />
                        </NavLink>
                        <Link to="/Dashboard" className="navbar-home-icon is-hidden-tablet">
                            <span className="icon">
                                <MaterialIcons
                                    type={MaterialIconType.Home}
                                    color={MaterialIconColor.white}
                                />
                            </span>
                        </Link>
                    </div>

                    {!props.isInitialising && (
                        <div className="navbar-menu is-active">
                            <div className="navbar-end">
                                {<EnvironmentSign />}
                                <NotificationNavDropdown
                                    linkElement={getNotificationElement()}
                                    notifications={props.notifications.slice(0, notificationCount)}
                                    className="masthead__notifications"
                                    onMarkAllRead={props.onMarkAllRead}
                                    onNotificationRead={props.onNotificationRead}
                                />
                                {!props.isClient && (
                                    <NavModuleNotification
                                        className="is-mobile-hidden"
                                        moduleNotificationCount={props.moduleNotificationCount}
                                        operatingPlatform={selectedOperatingPlatform}
                                    />
                                )}
                                <NavHelpModule
                                    person={props.person}
                                    updateIsShowFaq={props.updateIsShowFaq}
                                />
                                <NavDropdown
                                    linkElement={getProfileLinkElement()}
                                    isExpanded={props.isUserMenuExpanded}
                                    className="masthead__usermenu"
                                    dropdownItems={props.userMenuItems}
                                    person={props.person}
                                    loadPersonProfile={props.loadPersonProfile}
                                    siteId={props.siteId}
                                    isLoadingSite={props.isLoadingSite}
                                    showRoleSelectorDialog={props.showRoleSelectorDialog}
                                    showOperatingPlatformSelectorDialog={props.showOperatingPlatformSelectorDialog}
                                />
                            </div>
                        </div>
                    )}
                </NavBar>
            </div>

            {!props.isClient && (
                <NavBar className="navbar-secondary is-hidden-desktop  is-tablet-hidden">
                    <NavModuleNotification
                        moduleNotificationCount={props.moduleNotificationCount}
                        operatingPlatform={selectedOperatingPlatform}
                    />
                </NavBar>
            )}
        </>
    );
};
