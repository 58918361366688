import React from 'react';
import { Panel, ConfirmDialog, Button, Table } from '../v2/components';
import { isEmpty, groupBy } from 'lodash';
import { FormikProps } from 'formik';
import {
    IShiftHandover,
    IShiftHandoverItem,
    HandoverItemCategory,
    HandoverItemStatus,
    IShiftHandoverCategories,
} from 'models/shifts/shift-handover';
import { TextAreaField } from '../form/fields/textarea-field';
import { ISelectOption } from 'models/select-option';
import { newGuid } from 'utils/id-utils';
import { Checkbox } from '../form/Checkbox';
import { SelectField } from '../form/fields/select-field';
import { ReadOnlyField } from '../form/fields/read-only-field';
import useTranslate from 'translations/translation-utils';
import moment from 'moment';
import { IKeyValue } from 'models';

interface IProps {
    formikBag: FormikProps<IShiftHandover>;
    isReadOnly?: boolean;
    shiftHandoverItemCategories?: IShiftHandoverCategories;
    priorities: Array<IKeyValue<string>>;

}

export const ShiftHandoverItemsTable: React.FC<IProps> = ({ formikBag, isReadOnly, shiftHandoverItemCategories, priorities }) => {
    const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
    const [showClosedItems, setShowClosedItems] = React.useState(false);
    const [errorCategory, setErrorCategory] = React.useState('');
    const [errorDescription, setErrorDescription] = React.useState('');
    const [shiftHandoverItem, setShiftHandoverItem] = React.useState({} as IShiftHandoverItem);
    const [selectedCategory, setSelectedCategory] = React.useState('');
    const translate = useTranslate();

    const showDialog = (item?: IShiftHandoverItem) => {
        if (item && item.category) {
            setShiftHandoverItem(item);
        } else {
            setShiftHandoverItem({
                dateOfItemRaised: new Date(),
            } as IShiftHandoverItem);
        }
        setErrorCategory('');
        setErrorDescription('');
        setShowConfirmDialog(true);
    };
    const handleCategoryChange = (field: { value: string }) => {
        if (field.value) {
            setErrorCategory('');
        }
        const item = { ...shiftHandoverItem, category: field.value as HandoverItemCategory };
        setShiftHandoverItem(item);
    };
    const handlePriorityChange = (field: { value: string }) => {
        if (field.value) {
            setErrorCategory('');
        }
        const item = { ...shiftHandoverItem, priority: field.value };
        setShiftHandoverItem(item);
    };
    const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (event.target.value) {
            setErrorDescription('');
        }
        const item = { ...shiftHandoverItem, description: event.target.value };
        setShiftHandoverItem(item);
    };
    const handleCloseNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const item = { ...shiftHandoverItem, closeNotes: event.target.value };
        setShiftHandoverItem(item);
    };
    const handleCloseChange = () => {
        const status =
            shiftHandoverItem.status === HandoverItemStatus.Ongoing
                ? HandoverItemStatus.Closed
                : HandoverItemStatus.Ongoing;
        const dateOfItemClosed =
            status === HandoverItemStatus.Closed ? new Date().toISOString() : null;

        const item = { ...shiftHandoverItem, status, dateOfItemClosed };
        setShiftHandoverItem(item);
    };
    const getLookupCategory = () => {
        const options: ISelectOption[] = shiftHandoverItemCategories.categories && shiftHandoverItemCategories.categories.filter((item) => item.isEnabled).map((lookup) => {

            return {
                label: HandoverItemCategory[lookup.name] ? HandoverItemCategory[lookup.name] : lookup.name,
                value: lookup.name,
            };


        });
        return options;
    };
    const saveHandoverItem = async () => {
        if (!shiftHandoverItem.category) {
            setErrorCategory(translate('ShiftHandoverItemsTable.Errors.Category.required'));
        }
        if (!shiftHandoverItem.description || (shiftHandoverItem.description && shiftHandoverItem.description.trim() === '')) {
            setErrorDescription(translate('ShiftHandoverItemsTable.Errors.Description.required'));
        }

        if (shiftHandoverItem.category && shiftHandoverItem.description && shiftHandoverItem.description.trim() !== '') {
            let handoverItems = formikBag.values.handoverItems;

            if (shiftHandoverItem.id) {
                const handoverItem = handoverItems.find((n) => n.id === shiftHandoverItem.id);
                if (handoverItem) {
                    handoverItems = handoverItems.map((item) => {
                        if (item.id === shiftHandoverItem.id) {
                            return shiftHandoverItem;
                        }
                        return item;
                    });
                }
            } else {
                const handoverItem = {
                    ...shiftHandoverItem,
                    id: newGuid(),
                    status: HandoverItemStatus.Ongoing,
                };

                handoverItems = [handoverItem, ...handoverItems];
            }

            formikBag.setFieldValue('handoverItems', handoverItems);
            setShowConfirmDialog(false);
            setShiftHandoverItem({} as IShiftHandoverItem);
        }
    };

    const displayDateTimeAndText = (date: Date | string, text: string): string =>
        date ? `${moment(date).format('LT YYYY-MM-DD')} - ${text}` : text;

    const displayDateTime = (date: Date | string): string =>
        date ? `${moment(date).format('LT YYYY-MM-DD')}` : '';

    const getBody = () => {
        const shift = formikBag.values.handoverItems.find((c) => c.id === shiftHandoverItem.id);
        return (
            <>
                <div className="columns">
                    <div className="column is-4">
                        {isReadOnly ? (
                            <ReadOnlyField
                                id="category"
                                label={translate(
                                    'ShiftHandoverItemsTable.ReadonlyFields.Category.label'
                                )}
                                value={shiftHandoverItem.category}
                            />
                        ) : (

                            <SelectField
                                id="category"
                                label={translate('ShiftHandoverItemsTable.Fields.Category.label')}
                                value={{
                                    value: shiftHandoverItem.category,
                                    label: shiftHandoverItem.category
                                        ? HandoverItemCategory[shiftHandoverItem.category] ? translate(
                                            'ShiftHandoverItemsTable.HandoverItemCategory.',
                                            HandoverItemCategory[shiftHandoverItem.category]) : shiftHandoverItem.category
                                        : '',
                                }}
                                error={errorCategory}
                                handleChange={handleCategoryChange}
                                options={getLookupCategory()}
                            />
                        )}
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-4">
                        {isReadOnly ? (
                            <ReadOnlyField
                                id="priority"
                                label={translate(
                                    'ShiftHandoverItemsTable.Fields.Priority.label'
                                )}
                                value={shiftHandoverItem.priority}
                            />
                        ) : (

                            <SelectField
                                id="priority"
                                label={translate('ShiftHandoverItemsTable.Fields.Priority.label')}
                                value={{
                                    value: shiftHandoverItem.priority,
                                    label: shiftHandoverItem.priority
                                }}
                                error={errorCategory}
                                handleChange={handlePriorityChange}
                                options={priorities.map((c) => ({
                                    key: c.key,
                                    label: c.label,
                                    value: c.value,
                                }))}
                            />
                        )}
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        {isReadOnly ? (
                            <ReadOnlyField
                                id="notes"
                                label={translate(
                                    'ShiftHandoverItemsTable.ReadonlyFields.Items.label'
                                )}
                                value={displayDateTimeAndText(
                                    shiftHandoverItem.dateOfItemRaised,
                                    shiftHandoverItem.description
                                )}
                            />
                        ) : (
                            <TextAreaField
                                id="notes"
                                label={translate('ShiftHandoverItemsTable.Fields.Items.label')}
                                value={shiftHandoverItem.description}
                                error={errorDescription}
                                handleChange={handleDescriptionChange}
                            />
                        )}
                    </div>
                </div>
                {shiftHandoverItem.id && (
                    <>
                        <div className="columns">
                            <div className="column">
                                {!isReadOnly && (
                                    <Checkbox
                                        id="isClosed"
                                        label={
                                            shift.status === HandoverItemStatus.Closed
                                                ? translate(
                                                    'ShiftHandoverItemsTable.CheckboxToReOpen.label'
                                                )
                                                : translate(
                                                    'ShiftHandoverItemsTable.CheckboxToClose.label'
                                                )
                                        }
                                        checked={
                                            shiftHandoverItem.status === HandoverItemStatus.Closed
                                        }
                                        onChange={handleCloseChange}
                                    />
                                )}
                            </div>
                        </div>
                        {shiftHandoverItem.status === HandoverItemStatus.Closed && (
                            <div className="columns">
                                <div className="column">
                                    {isReadOnly ? (
                                        <>
                                            <p>
                                                {translate(
                                                    'ShiftHandoverItemsTable.ClosedItem.description'
                                                )}
                                            </p>
                                            <ReadOnlyField
                                                id="closeNotes"
                                                label={translate(
                                                    'ShiftHandoverItemsTable.ReadonlyFields.ClosedNotes.label'
                                                )}
                                                value={displayDateTimeAndText(
                                                    shiftHandoverItem.dateOfItemClosed,
                                                    shiftHandoverItem.closeNotes
                                                )}
                                            />
                                        </>
                                    ) : (
                                        <TextAreaField
                                            id="closeNotes"
                                            label={translate(
                                                'ShiftHandoverItemsTable.Fields.CloseNotes.label'
                                            )}
                                            value={shiftHandoverItem.closeNotes}
                                            handleChange={handleCloseNotesChange}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </>
                )}
            </>
        );
    };

    const getAddHandOverItemButton = isReadOnly ? (
        <></>
    ) : (
        <Button id="btnAddHandoverItem" onClick={() => showDialog()}>
            {translate('ShiftHandoverItemsTable.Button.AddNewHandoverItem.label')}
        </Button>
    );
    const toggleClosedItem = (category: string) => {
        setShowClosedItems(!showClosedItems);
        setSelectedCategory(category);
    }
    const grouped = !isEmpty(formikBag.values.handoverItems) && groupBy(formikBag.values.handoverItems, item => item.category);
    let groupedCategory = {}
    shiftHandoverItemCategories.categories && shiftHandoverItemCategories.categories.forEach((item) => {
        groupedCategory[item.name] = grouped[item.name] ? grouped[item.name] : []
    })

    const allCategory = { ...groupedCategory, ...grouped };
    return (
        <Panel
            className="handover-items-panel"
            title={translate('ShiftHandoverItemsTable.Panel.Title')}
            headerElements={getAddHandOverItemButton}
        >
            <Table className="handover-items">
                <thead>
                    <tr>
                        <th>
                            {translate('ShiftHandoverItemsTable.TableHeaders.HandoverItemDateTime')}
                        </th>
                        <th colSpan={2}>
                            {translate(
                                'ShiftHandoverItemsTable.TableHeaders.HandoverItemDescription'
                            )}
                        </th>
                        <th>{translate('ShiftHandoverItemsTable.TableHeaders.Priority')}</th>
                        <th>{translate('ShiftHandoverItemsTable.TableHeaders.Status')}</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(allCategory).map((category) => {
                        return (
                            <>
                                {!isEmpty(allCategory[category]) &&
                                    <tr className="header-category"><td colSpan={4}>{HandoverItemCategory[category] ? HandoverItemCategory[category] : category}</td>
                                    </tr>}
                                {!isEmpty(allCategory[category]) && allCategory[category].filter((x) => x.status === HandoverItemStatus.Ongoing)
                                    .map((item) => (
                                        <tr
                                            onClick={() => showDialog(item)}
                                            className="clickable"
                                            key={item.id}
                                        >
                                            <td className="datetime-narrow">{
                                                displayDateTime(
                                                    item.dateOfItemRaised
                                                )}
                                            </td>
                                            <td colSpan={2}>
                                                {item.description}
                                            </td>
                                            <td>
                                                {item.priority}
                                            </td>
                                            <td className="narrow">
                                                {translate(
                                                    'ShiftHandoverItemsTable.HandoverItemStatus.',
                                                    HandoverItemStatus[item.status]
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                {showClosedItems && selectedCategory === category && (
                                    <tr className="header-category">
                                        <td colSpan={4}>
                                            {translate('ShiftHandoverItemsTable.AllClosedItemsHeader.Text')}
                                        </td>
                                    </tr>
                                )}
                                {!isEmpty(allCategory[category]) &&
                                    showClosedItems &&
                                    selectedCategory === category &&
                                    allCategory[category]
                                        .filter((x) => x.status === HandoverItemStatus.Closed)
                                        .map((item) => (
                                            <tr
                                                key={item.id}
                                                onClick={() => showDialog(item)}
                                                className="item-closed clickable"
                                            >
                                                <td colSpan={3}>{
                                                    displayDateTimeAndText(
                                                        item.dateOfItemClosed,
                                                        item.description
                                                    )}
                                                </td>
                                                <td>
                                                    {translate(
                                                        'ShiftHandoverItemsTable.HandoverItemStatus.',
                                                        HandoverItemStatus[item.status]
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                {!isEmpty(allCategory[category]) &&
                                    allCategory[category].some(
                                        (x) => x.status === HandoverItemStatus.Closed
                                    ) && (
                                        <tr>
                                            <td
                                                colSpan={4}
                                                className="collapse-toggle"
                                                onClick={() => toggleClosedItem(category)}
                                            >
                                                {showClosedItems && selectedCategory === category
                                                    ? translate(
                                                        'ShiftHandoverItemsTable.HideClosedItemsToggle.Text'
                                                    )
                                                    : translate(
                                                        'ShiftHandoverItemsTable.ShowClosedItemsToggle.Text'
                                                    )}
                                            </td>
                                        </tr>
                                    )}
                            </>
                        )
                    })}
                </tbody>
            </Table>
            <ConfirmDialog
                title={translate('ShiftHandoverItemsTable.ConfirmDialog.Title')}
                message={getBody()}
                isVisible={showConfirmDialog}
                showConfirmButton={!isReadOnly}
                onConfirm={saveHandoverItem}
                onClose={() => setShowConfirmDialog(false)}
                onOutsideDialogClick={() => setShowConfirmDialog(false)}
                buttonConfirmText={translate('ShiftHandoverItemsTable.ConfirmDialog.Button.Text.Confirm')}
                buttonCancelText={translate('ShiftHandoverItemsTable.ConfirmDialog.Button.Text.Cancel')}
            />
        </Panel>
    );
};
