import { connect } from 'react-redux';

import { AttachmentCollection } from '../../../../components/attachmentCollection';
import actions from './actions';
import selector from './selector';

export const RiskAttachmentCollection = connect(
    selector,
    actions
)(AttachmentCollection);
