import queryString from 'query-string';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';

import {
    changeClientFulfilled,
    IChangeClientAction,
    IChangeClientSearchValueAction,
    ILoadClientSearchResults,
    ILoadSiteSearchResultsAction,
    loadClientSearchResultsFulfilled,
    SiteRelationshipsActions,
    SiteRelationshipsActionTypes,
} from '../../actions/site-relationships-actions';
import { IClient } from '../../models/client';
import { ISiteRelationshipsRootState } from '../../reducers/siteRelationships';
import { getErrorActions$ } from '../epic-helpers';
import { getChangeSearchValueEpic } from './factories';
import { getApiHeaders } from 'epics/shared-header';

import appConfig from 'helpers/config-helper';

const config = appConfig();

const apiUrl = config.REACT_APP_BASE_API;

const changeClientSearchValueEpic = getChangeSearchValueEpic<
    IChangeClientSearchValueAction,
    ILoadClientSearchResults
>(
    SiteRelationshipsActions.CHANGE_CLIENT_SEARCH_VALUE,
    SiteRelationshipsActions.LOAD_CLIENT_SEARCH_RESULTS
);

const loadClientSearchResultsEpic: Epic<
    SiteRelationshipsActionTypes,
    any,
    ISiteRelationshipsRootState
> = (action$, state$) =>
    action$.pipe(
        ofType(SiteRelationshipsActions.LOAD_CLIENT_SEARCH_RESULTS),
        filter(() => Boolean(state$.value.siteRelationships.siteSelector.selectedSite)),
        map<
            ILoadSiteSearchResultsAction,
            {
                siteId: string;
                searchString: string;
                filterKeywords: string[];
            }
        >((action) => ({
            siteId: state$.value.siteRelationships.siteSelector.selectedSite.id,
            searchString: action.payload.searchString,
            filterKeywords: action.payload.searchString.split(' '),
        })),
        map((values) => ({
            url: `${apiUrl}/api/siteapi/${values.siteId}/linkedclients?${queryString.stringify({
                isAttached: false,
                filterKeywords: values.filterKeywords,
            })}`,
            searchString: values.searchString,
        })),
        switchMap(({ searchString, url }) =>
            ajax.getJSON(url, getApiHeaders()).pipe(
                map<IClient[], SiteRelationshipsActionTypes>((response) =>
                    loadClientSearchResultsFulfilled(searchString, response)
                ),
                takeUntil(
                    action$.pipe(
                        ofType<SiteRelationshipsActionTypes>(
                            SiteRelationshipsActions.CHANGE_SITE_SEARCH_VALUE,
                            SiteRelationshipsActions.CHANGE_CLIENT_SEARCH_VALUE,
                            SiteRelationshipsActions.LOAD_CLIENT_SEARCH_RESULTS,
                            SiteRelationshipsActions.LOAD_CLIENT_SEARCH_RESULTS_CANCELLED
                        )
                    )
                ),
                catchError<any, any>((error: AjaxError) =>
                    getErrorActions$('Clients')(
                        SiteRelationshipsActions.LOAD_CLIENT_SEARCH_RESULTS_REJECTED,
                        error,
                        {
                            errorMessage: 'Unable to load clients',
                        }
                    )
                )
            )
        )
    );

const changeClientEpic: Epic<any> = (action$) =>
    action$.pipe(
        ofType<SiteRelationshipsActionTypes>(SiteRelationshipsActions.CHANGE_CLIENT_CONFIRMED),
        switchMap<IChangeClientAction, any>((action) =>
            ajax
                .post(
                    `${apiUrl}/api/siteapi/${action.payload.site.id}/client`,
                    action.payload.client,
                    getApiHeaders()
                )
                .pipe(
                    map<AjaxResponse, SiteRelationshipsActionTypes>(() =>
                        changeClientFulfilled(action.payload.site, action.payload.client)
                    ),
                    catchError<any, any>((error: AjaxError) =>
                        getErrorActions$('Change Client')(
                            SiteRelationshipsActions.CHANGE_CLIENT_REJECTED,
                            error,
                            {
                                errorMessage: 'Unable to link client.',
                            }
                        )
                    )
                )
        )
    );

export const clientEpics = combineEpics(
    changeClientSearchValueEpic,
    loadClientSearchResultsEpic,
    changeClientEpic
);
