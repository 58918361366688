import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { IPrivilege, IRole } from 'reducers/manageRoles/manageRoles-grid';
import { Epic, combineEpics } from 'redux-observable';
import { RoleActions } from 'actions/actions-v2/role-actions-v2';

const controllerName = 'role';

const createActions = {
    action: RoleActions.CREATE_ROLE,
    actionFulfilled: RoleActions.CREATE_ROLE_FULFILLED,
    actionRejected: RoleActions.CREATE_ROLE_REJECTED
} as IActionType;

const updateActions = {
    action: RoleActions.UPDATE_ROLE,
    actionFulfilled: RoleActions.UPDATE_ROLE_FULFILLED,
    actionRejected: RoleActions.UPDATE_ROLE_REJECTED
} as IActionType;

const getAllActions = {
    action: RoleActions.LOAD_ROLES,
    actionFulfilled: RoleActions.LOAD_ROLES_FULFILLED,
    actionRejected: RoleActions.LOAD_ROLES_REJECTED
} as IActionType;

const getPrivilegeActions = {
    action: RoleActions.LOAD_PRIVILEGE_ROLES,
    actionFulfilled: RoleActions.LOAD_PRIVILEGE_ROLES_FULFILLED,
    actionRejected: RoleActions.LOAD_PRIVILEGE_ROLES_REJECTED
} as IActionType;

const getPrivilegesActions = {
    action: RoleActions.LOAD_PRIVILEGES,
    actionFulfilled: RoleActions.LOAD_PRIVILEGES_FULFILLED,
    actionRejected: RoleActions.LOAD_PRIVILEGES_REJECTED
} as IActionType;

const getActions = {
    action: RoleActions.LOAD_ROLE,
    actionFulfilled: RoleActions.LOAD_ROLE_FULFILLED,
    actionRejected: RoleActions.LOAD_ROLE_REJECTED
} as IActionType;

// const deleteActions = {
//     action: RoleActions.DELETE_ROLE,
//     actionFulfilled: RoleActions.DELETE_ROLE_FULFILLED,
//     actionRejected: RoleActions.DELETE_ROLE_REJECTED
// } as IActionType;

const createRoleEpic: Epic<IAppAction, IAppAction> = (action$, state$) => new BaseEpic(action$, state$, createActions).post<IRole>(`/api/${controllerName}`);
const updateRoleEpic: Epic<IAppAction, IAppAction> = (action$, state$) => new BaseEpic(action$, state$, updateActions).put<IRole>(`/api/${controllerName}`);
const loadRolesEpic: Epic<IAppAction, IAppAction> = (action$, state$) => new BaseEpic(action$, state$, getAllActions).get<IRole[]>(`/api/${controllerName}`);
const loadPrivilegesEpic: Epic<IAppAction, IAppAction> = (action$, state$) => new BaseEpic(action$, state$, getPrivilegesActions).post<string[]>(`/api/privilege/names`);
const loadPrivilegeRolesEpic: Epic<IAppAction, IAppAction> = (action$, state$) => new BaseEpic(action$, state$, getPrivilegeActions).getById<IPrivilege>(`/api/privilege/name`);
const loadRoleEpic: Epic<IAppAction, IAppAction> = (action$, state$) => new BaseEpic(action$, state$, getActions).getById<IRole>(`/api/${controllerName}`);
// const deleteRoleEpic: Epic<IAppAction, IAppAction> = (action$, state$) => new BaseEpic(action$, state$, deleteActions, '/Role').deleteById<string>(`/api/${controllerName}`);

export const roleEpicsV2 = combineEpics(
    createRoleEpic,
    updateRoleEpic,
    loadRolesEpic,
    loadPrivilegesEpic,
    loadPrivilegeRolesEpic,
    loadRoleEpic,
    // deleteRoleEpic
);
