import {
    changeClientSearchValue,
    selectClient
} from 'actions/site-relationships-actions';
import { ITypeaheadItem } from 'components/typeahead';
import { IClient } from 'models/client';

export default {
    onValueChanged: changeClientSearchValue,
    onSelectionChanged: (selection: ITypeaheadItem<IClient>) => selectClient(selection.value)
};
