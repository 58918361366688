import React, { useMemo } from 'react';
import SideNavMenuItem from './SideNavMenuItem';
import SideDynamicNavMenu from './SideDynamicNavMenu';
import INavItem from 'models/nav-item';
import { IMenu } from 'models/menu-model';
import { uniqueId } from 'lodash';
import { Loader } from '../loader';
import appConfig from 'helpers/config-helper';

interface IStateProps {
    navItems?: INavItem[];
    adminNavMenuItems?: INavItem[];
    navMenuItems?: IMenu[];
    siteId?: string;
    isClientRole?: boolean;
}

interface IDispatchProps {
    onMenuItemClick: () => void;
    onSelectedMenuItem: (selectedMenu: string) => void;
}

export const SideNavMenu: React.FC<IStateProps & IDispatchProps> = ({
    navItems,
    adminNavMenuItems,
    navMenuItems,
    onMenuItemClick,
    siteId,
    onSelectedMenuItem,
    isClientRole,
}) => {
    const config = appConfig();

    const renderDynamicMenuItems = useMemo(() => {
        return navMenuItems && navMenuItems.length < 1 ? (
            <Loader loading={true} isWhiteSpinner={true} />
        ) : (
            <div className="sidenav-menu">
                <ul>
                    {navMenuItems &&
                        navMenuItems.map(navItem => {
                            return (
                                <SideDynamicNavMenu
                                    key={uniqueId(navItem.menuName)}
                                    navItem={navItem}
                                    onMenuItemClick={onMenuItemClick}
                                    siteId={siteId}
                                    onSelectedMenuItem={onSelectedMenuItem}
                                />
                            );
                        })}
                    {adminNavMenuItems &&
                        adminNavMenuItems.length > 0 &&
                        adminNavMenuItems.map(navItem => {
                            return (
                                <SideNavMenuItem
                                    key={uniqueId(navItem.text)}
                                    navItem={navItem}
                                    onMenuItemClick={onMenuItemClick}
                                />
                            );
                        })}
                </ul>
            </div>
        );
    }, [adminNavMenuItems, navMenuItems]);

    const renderStaticMenuItems = useMemo(() => {
        return navItems && navItems.length < 1 ? (
            <Loader loading={true} isWhiteSpinner={true} />
        ) : (
            <div className="sidenav-menu">
                <ul>
                    {navItems &&
                        navItems.map(navItem => {
                            return (
                                <SideNavMenuItem
                                    key={uniqueId(navItem.text)}
                                    navItem={navItem}
                                    onMenuItemClick={onMenuItemClick}
                                />
                            );
                        })}
                </ul>
            </div>
        );
    }, [navItems]);

    return config.REACT_APP_FEATURE_FLAG_DYNAMIC_MENU === 'true' && !isClientRole
        ? renderDynamicMenuItems
        : renderStaticMenuItems;
};
