import { connect } from 'react-redux';

import { withRouter } from 'react-router';
import actions from './actions';
import { CermProcessStepOutcomeForm as View } from './cerm-process-step-outcome-form';
import selector from './selector';

export const CermProcessStepOutcomeForm = withRouter(connect(
    selector,
    actions
)(View));
