import * as React from 'react';
import { IRisk, RiskMitigationPlanStatus } from 'models/risk';
import { Entity } from 'models/entity';
import Comments from 'components/comments/comments';
import { ReadOnlyField } from 'components/form/fields/read-only-field';
import { formatDate } from 'utils/date-utils';
import { TextAreaField } from 'components/form/fields/textarea-field';
import { ClientMitigationPlanContainer } from './client-mitigation-plan-container';
import useTranslate from 'translations/translation-utils';
import Asset from 'components/asset/asset';
import appConfig from 'helpers/config-helper';

interface IProps {
    risk: IRisk;
}
export const ClientRiskDetailsTab: React.FC<IProps> = ({ risk }) => {
    const translate = useTranslate();
    const config = appConfig();
    const getRiskLevelMarkup = (riskLevel: string) => {
        return riskLevel ? (
            <mark id="riskLevel" className={`${riskLevel.toLowerCase()}`}>
                {riskLevel}
            </mark>
        ) : null;
    };

    const updateAssetField = (value: string) => {
        return value ? value : "N/A";
    }

    return (
        <>
            <div className="tabs-container tab-content">
                <div className="columns">
                    <div className="column is-8">
                        <ReadOnlyField label={translate('RiskDetailsPage.label.title')} value={risk.title} />
                    </div>
                    <div className="column is-4">
                        <ReadOnlyField label={translate('RiskDetailsPage.label.Reviewdate')} value={formatDate(risk.reviewDate)} />
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-8">
                        <TextAreaField
                            label={translate('RiskDetailsPage.label.Description')}
                            value={risk.description}
                            id={''}
                            handleChange={_ => {
                                return;
                            }}
                            isReadOnly={true}
                        />
                    </div>
                    <div className="column is-4">
                        <ReadOnlyField label="Status" value={risk.riskStatus} />
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-4">
                        <ReadOnlyField label={translate('RiskRegisterPage.RiskFilter.Title.Category')} value={translate('RiskDetailsPage.Category.dropdown.', risk.riskCategory)} />
                    </div>
                    <div className="column is-4">
                        <ReadOnlyField label={translate('RiskRegisterPage.RiskRegisterTable.Title.SubCategory')} value={translate('RiskDetailsPage.SubCategory.dropdown.', risk.riskSubCategory)} />
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-2">
                        <ReadOnlyField label={translate('RiskRegisterPage.RiskRegisterTable.Title.Likelihood')} value={translate('RiskDetailsPage.Likelihood.dropdown.', risk.likelihood)} />
                    </div>
                    <div className="column is-2">
                        <ReadOnlyField label={translate('RiskRegisterPage.RiskRegisterTable.Title.Impact')} value={translate('RiskDetailsPage.Impact.dropdown.', risk.impact)} />
                    </div>
                    <div className="column is-2">
                        <div className="field field-risk-level">
                            <div className="label">{translate('RiskDetailsPage.label.Risklevel')}</div>
                            {getRiskLevelMarkup(risk.riskLevel)}
                        </div>
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-4">
                        <ReadOnlyField
                            label={translate('RiskDetailsPage.label.Currencytype')}
                            value={risk.cost ? risk.cost.currencyIsoCode : ''}
                        />
                    </div>
                    <div className="column is-4">
                        <ReadOnlyField
                            label={translate('RiskDetailsPage.label.Estimatedcosttoremedy')}
                            value={risk.cost ? risk.cost.amount : ''}
                        />
                    </div>
                </div>
            </div>

            {risk.id && (
                <Comments
                    id={risk.id}
                    entity={Entity.Risk}
                    allowNewComment={true}
                    forceSave={false}
                    overrideSiteId={risk.shard}
                />
            )}

            {risk?.mitigationPlans
                ?.filter(x => x.status !== RiskMitigationPlanStatus.Draft && !!x.visibleToClient)
                .map(mitigationPlan => (
                    <ClientMitigationPlanContainer
                        key={'mp_' + mitigationPlan.id}
                        mitigationPlan={mitigationPlan}
                        riskId={risk.id}
                    />
                ))}

            {config.REACT_APP_FEATURE_FLAG_ASSET_INTEGRATION === 'true' && (
                <div className="columns">
                    <div className="column column is-12">
                        <Asset
                            showLabel={true}
                            isManadatory={false}
                            setValue={() => { }}
                            isValid={() => { }}
                            selectedAsset={{
                                siteId: updateAssetField(risk?.asset?.siteId),
                                manufacturer: updateAssetField(risk?.asset?.manufacturer),
                                model: updateAssetField(risk?.asset?.model),
                                serial: updateAssetField(risk?.asset?.serial),
                                cmmsReference: updateAssetField(risk?.asset?.cmmsReference),
                                name: updateAssetField(risk?.asset?.name),
                                age: updateAssetField(risk?.asset?.age),
                            }}
                            isDisabled={true}
                        />
                    </div >
                </div>
            )}
        </>
    );
};
