import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { ShiftHandoverUpsertPage } from './shift-handover-upsert-page';
import { getPermissions, getShiftHandoverSettings, getLookupActionPriorities } from 'sharedSelectors';
import {
    loadShiftChecklistVersion,
    cleanupShiftHandover,
    createShiftHandover,
    updateShiftHandover,
    loadShiftHandover,
    loadSettings,
    loadLastSubmittedShiftHandover,
    acknowledgeShiftHandover,
    exportShiftHandovers,
    loadShiftHandoverCategory,
    deleteShiftHandover,
} from 'actions/actions-v2/shift-handover-actions';
import { loadEngineers } from 'actions/actions-v2/shift-actions';

const getSiteId = (state: IRootState) => state.app.siteId;
const getIsLoading = (state: IRootState) => state.shiftHandoverState.isLoading;
const getIsShiftHandoverCreated = (state: IRootState) => state.shiftHandoverState.isCreated;
const getShiftHandover = (state: IRootState) => state.shiftHandoverState.shiftHandover;
const getShiftChecklist = (state: IRootState) => state.shiftHandoverState.shiftChecklist;
const getIsExporting = (state: IRootState) => state.shiftHandoverState.isExporting;
const getShifthandoverItemCategory = (state: IRootState) =>
    state.shiftHandoverState.shiftHandoverCategories;
const getShiftEngineers = (state: IRootState) =>
    state.shiftsState.engineers;

const mapStateToProps = createSelector(
    getSiteId,
    getIsLoading,
    getIsShiftHandoverCreated,
    getShiftHandover,
    getShiftChecklist,
    getPermissions,
    getShiftHandoverSettings,
    getIsExporting,
    getShifthandoverItemCategory,
    getShiftEngineers,
    getLookupActionPriorities,
    (
        siteId,
        isLoading,
        isShiftHandoverCreated,
        shiftHandover,
        shiftChecklist,
        permissions,
        shiftHandoverSettings,
        isExporting,
        shiftHandoverItemCategories,
        shiftEngineers,
        priorities
    ) => ({
        siteId,
        isLoading,
        isShiftHandoverCreated,
        shiftHandover,
        shiftChecklist,
        permissions,
        // hasReadWritePermission: checkPermission(Privilege.ShiftHandover, permissions),
        shiftHandoverSettings,
        isExporting,
        shiftHandoverItemCategories,
        shiftEngineers,
        priorities
    })
);

const mapDispatchToProps = {
    loadShiftHandover,
    createShiftHandover,
    updateShiftHandover,
    deleteShiftHandover,
    clearForm: cleanupShiftHandover,
    loadShiftChecklistVersion,
    loadSettings,
    acknowledgeShiftHandover,
    loadLastSubmittedShiftHandover,
    exportShiftHandovers,
    loadShiftHandoverCategory,
    loadEngineers
};

export const ShiftHandoverUpsertPageContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ShiftHandoverUpsertPage)
);
