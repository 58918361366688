import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { IShiftEngineer } from 'models/shift';

const getIsLoading = (state: IRootState) => state.shiftsState.isLoading;
const getIsSaving = (state: IRootState) => state.shiftsState.isSaving;
const getSiteId = (state: IRootState) => state.app.siteId;
const getEngineers = (state: IRootState) => state.shiftsState.engineers && state.shiftsState.engineers.length > 0 &&
    state.shiftsState.engineers.slice().sort((a: IShiftEngineer, b: IShiftEngineer) =>
    a.firstName === b.firstName ? (a.lastName > b.lastName ? 1 : -1) : (a.firstName > b.firstName ? 1 : -1));
const getShift = (state: IRootState) => state.shiftsState.shift;

export default createSelector(
    getIsLoading,
    getIsSaving,
    getSiteId,
    getEngineers,
    getShift,
    (isLoading, isSaving, siteId, engineers, shift) => ({
        isLoading,
        isSaving,
        siteId,
        engineers,
        shift
    })
);
