import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import selectors from './selectors';
import actions from './actions';
import { Loader } from 'components/loader';
import { RiskRegisterTable } from 'components/risk-register/risk-register-table';
import { RiskRegisterGridToolbar } from 'components/risk-register/risk-register-toolbar';
import { IRisk } from 'models/risk';
import { IRiskFilters } from 'models/IRiskFilters';
import { RiskFilters } from '../risks/views/riskFilters';
import { getUserId, isSiteApprover, isRiskApprover } from 'helpers/helpers';
import { OverlayPanel } from 'components/v2/components';
import Page from 'components/v2/page/Page';
import { usePageUIPreferences, UiPreferences } from 'utils/page-ui-preferences-utils';
import { HelpModuleContainer } from '../help-module/help-module-container';
import useTranslate from 'translations/translation-utils';
import { ModuleType } from 'models/help-module';
import { checkFilterExists } from 'utils/array-utils';
import { IKeyValue } from 'models';
import { getSiteOperatingPlatform } from 'models/site-profile';
import * as RiskStatus from 'models/risk-statuses';
import './../../components/risk-register/risk.scss';
import { RiskRegisterOverview } from 'components/risk-register/risk-register-overview';
import { MacroLevelRiskRegisterTable } from 'components/risk-register/macro-level-risk-register-table';
import { Privilege } from 'enums/Privilege';
import { checkPermission } from 'utils/permission-utils';

interface IProps extends RouteComponentProps<IRouteParams> {
    siteId: string;
    isLoading: boolean;
    isLoadingMacroRisk: boolean;
    risks: IRisk[];
    macroRisks: IRisk[];
    loadAll: (filters: IRiskFilters) => void;
    loadMacroRisks: (filters: IRiskFilters) => void;
    exportData: (siteId: string) => void;
    exportAllData: () => void;
    clearForm: () => void;
    isExporting: boolean;
    showHelpModule: boolean;
    lookupSites: IKeyValue<string>[];
    permissions: Array<IKeyValue<string>>;
    loadPersonsLookup: (siteId: string) => void;
}

interface IRouteParams {
    specialFilter?: string;
}

export const RiskRegisterPage: React.FC<IProps> = ({
    location,
    siteId,
    isLoading,
    isLoadingMacroRisk,
    risks,
    macroRisks,
    loadAll,
    loadMacroRisks,
    exportData,
    exportAllData,
    isExporting,
    clearForm,
    showHelpModule,
    match,
    lookupSites,
    permissions,
    loadPersonsLookup,
}) => {

    const isMacroRiskVisible = checkPermission(Privilege.RiskMacroLevelUpdate, permissions);
    const allRisks = isMacroRiskVisible ? [...risks, ...macroRisks] : risks;

    const getUpdatedFilters = (
        updatedRiskFilters: IRiskFilters,
        key: Extract<keyof IRiskFilters, string>,
        element: string
    ) => {
        const specialFilters = [RiskStatus.CLOSED];

        if (specialFilters.some((c) => c === element)) {
            if (updatedRiskFilters.riskStatus.find((c) => c === RiskStatus.CLOSED)) {
                return {
                    ...updatedRiskFilters,
                    riskStatus: [],
                };
            }
            return {
                ...updatedRiskFilters,
                riskStatus: [element],
            };
        }

        const newFilters = { ...updatedRiskFilters };
        const index = newFilters[key].indexOf(element);
        if (index !== -1) {
            const items = [...newFilters[key].slice(0, index), ...newFilters[key].slice(index + 1)];
            newFilters[key] = items;
        } else {
            const items = [element, ...newFilters[key].slice(0, newFilters[key].length)];
            newFilters[key] = items;
        }
        const filtersWithOutSpecials = {
            ...newFilters,
            riskStatus: newFilters.riskStatus.filter((f) => !specialFilters.some((c) => c === f)),
        } as IRiskFilters;

        return filtersWithOutSpecials;
    };

    const initializeFilters = () => {
        let initialFilters = {
            filterKeywords: [],
            riskCategory: [],
            riskLevel: [],
            riskSpecial: [],
            riskStatus: [],
            siteId,
        } as IRiskFilters;
        const urlParams = new URLSearchParams(location.search);
        urlParams.forEach((element, key) => {
            initialFilters = getUpdatedFilters(initialFilters, key as any, element);
        });
        return initialFilters;
    };

    const [showFilters, setShowFilters] = React.useState(false);
    const [showHelp, setShowHelp] = React.useState(false);
    const [filters, setFilters] = usePageUIPreferences<IRiskFilters>(
        UiPreferences.RisksPageFilters,
        initializeFilters()
    );
    const operatingPlatform = getSiteOperatingPlatform(siteId, lookupSites);
    React.useEffect(() => {
        if (!siteId) {
            return;
        }
        if (siteId != filters.siteId) {
            setFilters(buildRequestFiltersObject(true))
        }
        else {
            loadPersonsLookup(siteId);
            loadAll(filters)
            if (isMacroRiskVisible) {
                loadMacroRisks(filters)
            }
        }
    }, [siteId, filters]);

    React.useEffect(() => {
        let buildRequestFilters = buildRequestFiltersObject(false)
        if (match.params.specialFilter) {
            buildRequestFilters = {
                ...buildRequestFilters,
                riskSpecial: [match.params.specialFilter],
                riskStatus: [RiskStatus.OPEN_AND_PENDING_REVIEW, RiskStatus.MITIGATION_PENDING_INTERNAL_APPROVAL,
                RiskStatus.MITIGATION_PENDING_INTERNAL_APPROVAL, RiskStatus.MITIGATION_PENDING_MITIGATION, "IN_PROGRESS", "TODO"]
            }

            loadAll(buildRequestFilters);
            if (isMacroRiskVisible)
                loadMacroRisks(buildRequestFilters);
            setFilters(buildRequestFilters);
        }
    }, [match.params.specialFilter, location.key]);

    React.useEffect(() => {
        const risksHasData = !risks || risks.length === 0;

        if (risksHasData) {
            return;
        }
    }, [risks]);

    const buildRequestFiltersObject = (keepState: boolean) => {
        return {
            riskStatus: filters && keepState ? filters.riskStatus : [],
            riskCategory: filters && keepState ? filters.riskCategory : [],
            riskLevel: filters && keepState ? filters.riskLevel : [],
            riskSpecial: filters && keepState ? filters.riskSpecial : [],
            filterKeywords: filters && keepState ? filters.filterKeywords : [],
            siteId,
        } as IRiskFilters;
    };

    const toggleFilters = () => {
        setShowFilters(showFilters ? false : true);
    };

    const resetFilters = () => {
        setFilters(buildRequestFiltersObject(false));
    };

    const onAddFilterKeyword = (keyword) => {
        if (!keyword) {
            return;
        }
        const newFilterKeywords = [...filters.filterKeywords, keyword];
        setFilters({ ...filters, filterKeywords: newFilterKeywords });
    };

    const onRemoveFilterKeyword = (keyword) => {
        const newFilterKeywords = filters.filterKeywords.filter((x) => x !== keyword);
        setFilters({ ...filters, filterKeywords: newFilterKeywords });
    };

    const onFilter = (key: Extract<keyof IRiskFilters, string>, element: string) => {
        const updatedFilters = getUpdatedFilters(filters, key, element);
        setFilters(updatedFilters);
        loadAll(updatedFilters);
    };

    const siteApprover = isSiteApprover(siteId);

    const userId = getUserId();

    const hasRiskApprovalPermissions = (risk: IRisk) => {
        if (siteApprover) {
            return true;
        }
        return isRiskApprover(risk, userId);
    };

    const riskPendingApproval = risks
        ? risks.filter(
            (f) =>
                f.sharedWithClient &&
                !f.cbreApprovalToShare &&
                f.isActive &&
                hasRiskApprovalPermissions(f)
        )
        : [];

    const translate = useTranslate();

    const onClickHelp = () => {
        setShowHelp(!showHelp);
    };

    const isFilterExists = checkFilterExists(filters);
    return (
        <Page
            title={translate('RiskRegisterPage.Title.RiskRegister')}
            className="risk-register-page"
            scrollTopOnPageLoad={true}
        >
            <RiskRegisterGridToolbar
                isClient={false}
                isShowHelp={showHelpModule || true}
                onShowFilters={toggleFilters}
                onClearFilters={resetFilters}
                onExport={exportData}
                onExportAll={exportAllData}
                clearForm={clearForm}
                disableExport={isExporting || !allRisks || allRisks.length === 0}
                onClickHelp={onClickHelp}
                isFilterExists={isFilterExists}
                operatingPlatform={operatingPlatform}
                permissions={permissions}

            />
            <HelpModuleContainer
                isShown={showHelp}
                onClose={onClickHelp}
                moduleType={ModuleType.Risk}
            />
            <OverlayPanel
                title={translate('RiskRegisterPage.Title.Filters')}
                isVisible={showFilters}
                onClose={() => setShowFilters(false)}
                onOutsideDialogClick={() => setShowFilters(false)}
                onClearFilters={resetFilters}
                isFilterExists={isFilterExists}
            >
                <RiskFilters
                    onRemoveFilterKeyword={onRemoveFilterKeyword}
                    onFilter={onFilter}
                    onAddFilterKeyword={onAddFilterKeyword}
                    filters={buildRequestFiltersObject(true)}
                />
            </OverlayPanel>
            <Loader loading={isLoading}>
                <RiskRegisterOverview isClientView={false} risks={allRisks} />
                {riskPendingApproval && riskPendingApproval.length > 0 && (
                    <RiskRegisterTable
                        componentId={'client-risk-list'}
                        title={translate('RiskRegisterPage.Title.RiskPendingApproval')}
                        risks={riskPendingApproval}
                        type={'warning'}
                        collapsed={false}
                    />
                )}
                {isMacroRiskVisible && macroRisks?.length > 0 ? (
                    <MacroLevelRiskRegisterTable
                        componentId={'risk-list'}
                        risks={macroRisks}
                        collapsed={false}
                    />
                ) : (
                    isMacroRiskVisible && !isLoadingMacroRisk && (
                        <div className="column is-12 has-text-centered">
                            {translate('RiskRegisterPage.Message.NoMacroRiskDataAvailable')}
                        </div>
                    )
                )}
                {risks?.length > 0 ? (
                    <RiskRegisterTable componentId={'risk-list'} risks={risks} />
                ) : (
                    !isLoading && (
                        <div className="column is-12 has-text-centered">
                            {translate('RiskRegisterPage.Message.NoDataAvailable')}
                        </div>
                    )
                )}
            </Loader>
        </Page>
    );
};

export default withRouter(connect(selectors, actions)(RiskRegisterPage));
