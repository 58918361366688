import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Tab } from 'models/critical-system-spares/critical-system-spare-enums';
import { ICriticalSystemSpare } from 'models/critical-system-spares/critical-system-spare';
import { TabGroup, TabItem } from 'components/v2/components';
import { Loader } from 'components/loader';
import SpareDetailsTab from './tabs/details/spare-details-tab';
import SpareDocumentsTab from './tabs/documents/spare-documents-tab';
import Page from 'components/v2/page/Page';
import { IKeyValue } from 'models';
import { IEntitySite } from 'models/entity-site';
import useTranslate from 'translations/translation-utils';

interface IProps extends RouteComponentProps<IParams> {
    siteId: string;
    isLoading: boolean;
    criticalSpare: ICriticalSystemSpare;
    load: (model: IEntitySite) => void;
}

interface IParams {
    id?: string;
    tab: string;
}

export const ClientCriticalSparePage: React.FC<IProps> = ({ criticalSpare, match, ...props }) => {
    const translate = useTranslate();
    const id = match.params.id;
    const currentTab = match.params.tab ? match.params.tab : Tab.details;
    const clientSystemSparesPageUrl = '/ClientCriticalSystemSpares';
    const customLinks: Array<IKeyValue<string>> = [{ key: clientSystemSparesPageUrl, value: 'Critical System Spares' }];

    React.useEffect(() => {
        if (!id) {
            return;
        }

        props.load({
            id,
            siteId: props.siteId,
        });
    }, [id]);

    const renderTab = () => {
        switch (match.params.tab.toLowerCase()) {
            case Tab.details:
                return <SpareDetailsTab criticalSpare={criticalSpare} />;
            case Tab.documents:
                return <SpareDocumentsTab id={id} />;
        }
    };

    const setNavLink = (tab: Tab) => {
        if (id) {
            return match.path.replace(':id', match.params.id).replace(':tab', tab);
        }
        return match.path.replace(':id/', '').replace(':tab', tab);
    };

    return (
        <Page
            title={criticalSpare.name}
            breadcrumbCustomLinks={customLinks}
            redirectOnSiteChange={true}
            redirectOnSiteChangeUrl={clientSystemSparesPageUrl}
            className="critical-spares-page"
        >
            <Loader loading={props.isLoading}>
                <TabGroup>
                    <TabItem
                        title={translate('RiskRegisterUpsertPage.Tabs.Details')}
                        tab={Tab.details}
                        currentTab={currentTab}
                        to={setNavLink(Tab.details)}
                    />
                    <TabItem
                        title={translate('RiskRegisterUpsertPage.Tabs.Documents')}
                        tab={Tab.documents}
                        currentTab={currentTab}
                        to={setNavLink(Tab.documents)}
                    />
                </TabGroup>
                {renderTab()}
            </Loader>
        </Page>
    );
};
