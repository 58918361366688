import React from 'react';
import { history } from 'routes/App';
import { IconToolbar, IToolbarIconItem, ToolbarIconType } from 'components/v2/components';
import { useTranslate } from 'translations/translation-utils';

interface IProps {
    disableExport: boolean;
    isFilterExists?: boolean;
    isShowHelp: boolean;
    onShowFilters: () => void;
    onClearFilters: () => void;
    onExport: () => void;
    onClickHelp: () => void;
    disableCreate: boolean;
}

export const GlobalMockDrillsPageToolbar: React.FC<IProps> = ({
    disableExport,
    isFilterExists,
    isShowHelp,
    onShowFilters,
    onClearFilters,
    onExport,
    onClickHelp,
    disableCreate,
}) => {
    const translate = useTranslate();
    const onHandleExport = () => {
        onExport();
    };

    const getToolbarItems = (): IToolbarIconItem[] => {
        return [
            {
                id: 'add-global-mock-drill-button',
                title: translate('GlobalMockDrills.Add'),
                isDisabled: disableCreate,
                type: ToolbarIconType.add,
                onClick: () => {
                    history.push(`/GlobalMockDrill/Details`);
                },
            },
            {
                id: 'export-button',
                title: translate('GlobalMockDrills.Export'),
                type: ToolbarIconType.download,
                isDisabled: disableExport,
                onClick: onHandleExport,
            },
            {
                id: 'clear-all-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.ClearFilters'),
                type: isFilterExists ? ToolbarIconType.filterApplied : ToolbarIconType.clearFilter,
                onClick: onClearFilters,
            },
            {
                id: 'show-filters-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.ShowFilters'),
                type: ToolbarIconType.filter,
                onClick: onShowFilters,
            },
            isShowHelp && {
                id: 'show-help',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.Help'),
                type: ToolbarIconType.help,
                onClick: onClickHelp,
            },
        ];
    };

    return <IconToolbar items={getToolbarItems()} />;
};
