import { IAppAction } from '../app-action';

export enum RomonetActions {
    DOWNLOAD_REPORTS = '@@Romonet/DOWNLOAD_REPORTS',
    DOWNLOAD_REPORTS_FULFILLED = '@@Romonet/DOWNLOAD_REPORTS_FULFILLED',
    DOWNLOAD_REPORTS_REJECTED = '@@Romonet/DOWNLOAD_REPORTS_REJECTED',

    LOAD_REPORT = '@@Romonet/LOAD_REPORT',
    LOAD_REPORT_FULFILLED = '@@Romonet/LOAD_REPORT_FULFILLED',
    LOAD_REPORT_REJECTED = '@@Romonet/LOAD_REPORT_REJECTED',

    LOAD_DATE_FILTER = '@@Romonet/LOAD_DATE_FILTER',
    LOAD_DATE_FILTER_FULFILLED = '@@Romonet/LOAD_DATE_FILTER_FULFILLED',
    LOAD_DATE_FILTER_REJECTED = '@@Romonet/LOAD_DATE_FILTER_REJECTED',

    DOWNLOAD_PPT = '@@Romonet/DOWNLOAD_PPT',
    DOWNLOAD_PPT_FULFILLED = '@@Romonet/DOWNLOAD_PPT_FULFILLED',
    DOWNLOAD_PPT_REJECTED = '@@Romonet/DOWNLOAD_PPT_REJECTED',

    LOAD_DETAILS_HALL_NAME = '@@Romonet/LOAD_DETAILS_HALL_NAME',
    LOAD_DETAILS_HALL_NAME_FULFILLED = '@@Romonet/LOAD_DETAILS_HALL_NAME_FULFILLED',
    LOAD_DETAILS_HALL_NAME_REJECTED = '@@Romonet/LOAD_DETAILS_HALL_NAME_REJECTED',

    RESET_REPORT = '@@Romonet/RESET_REPORT',
}

export const loadHallNames = (ReportType: string, FacilityId: string): IAppAction => ({
    type: RomonetActions.LOAD_DETAILS_HALL_NAME,
    payload: { FacilityId, ReportType },
});

export const downloadReports = (
    ReportType: string,
    FacilityId: string,
    FileName: string,
    HallName?: string
): IAppAction => ({
    type: RomonetActions.DOWNLOAD_REPORTS,
    payload: { FacilityId, ReportType, FileName, HallName },
});

export const downloadPPT = (): IAppAction => ({
    type: RomonetActions.DOWNLOAD_PPT,
});

export const loadReport = (
    ReportType: string,
    FacilityId: string,
    FileName: string,
    HallName?: string
): IAppAction => ({
    type: RomonetActions.LOAD_REPORT,
    payload: { FacilityId, ReportType, FileName, HallName },
});

export const loadDateFilters = (
    reportType: string,
    facilityId: string,
    hallName?: string
): IAppAction => ({
    type: RomonetActions.LOAD_DATE_FILTER,
    payload: { FacilityId: facilityId, ReportType: reportType, HallName: hallName },
});

export const resetReport = (): IAppAction => ({
    type: RomonetActions.RESET_REPORT,
});
