import classnames from 'classnames';
import * as React from 'react';

import { ICustomCheckboxFilters } from '.';
import { IKeyValue } from '../../models/key-value';
import { FilterSet } from '../filterSet';
import { CheckboxFilterSet, ICheckbox } from '../filterSet/CheckboxFilterSet';

export interface IProps<T = any> {
    title: string;
    name: Extract<keyof T, string>;
    selectedFilters: string[];
    expandedFilters: string[];
    unreservedFilters: Array<IKeyValue<string>>;

    onFilter: (field: string, value: string) => void;
    onToggleFilterExpanded: (id: string) => void;
    customFilters?: ICustomCheckboxFilters[];
    secondaryTitle?: string;

    className?: string;
}

export const CheckboxFilter: React.FC<IProps> = (props) => {
    const getIsExpanded = (identifier: string) =>
        props.expandedFilters &&
        props.expandedFilters.length > 0 &&
        props.expandedFilters.includes(identifier);
    return (
        <div className={classnames(props.className)}>
            <FilterSet
                title={props.title}
                fieldName={props.name}
                isExpanded={getIsExpanded(props.name)}
                onCollapseExpand={props.onToggleFilterExpanded}
                secondaryTitle={props.secondaryTitle}
            >
                <CheckboxFilterSet
                    fieldName={props.name}
                    filters={getCheckboxes(props.selectedFilters, props.unreservedFilters)}
                    onFilter={props.onFilter}
                />
                {props.customFilters &&
                    props.customFilters.map((customFilter) => {
                        return (
                            <CheckboxFilterSet
                                key={customFilter.id}
                                fieldName={props.name}
                                filters={getCheckboxes(props.selectedFilters, customFilter.filters)}
                                background={customFilter.color}
                                onFilter={customFilter.onFilter}
                            />
                        );
                    })}
            </FilterSet>
        </div>
    );
};

function getCheckboxes(selectedValues: string[], filters: Array<IKeyValue<string>>): ICheckbox[] {
    const checkboxes: ICheckbox[] = filters.map((x: IKeyValue<string>) => {
        if (x) {
            return {
                fieldId: x.key,
                label: x.value,
                checked:
                    selectedValues && selectedValues.length > 0 && selectedValues.includes(x.key),
            };
        }
    });

    return checkboxes;
}
