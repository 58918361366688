import { ICermProcessOwnersState } from 'state';
import { IAppAction } from 'actions/app-action';
import { CermProcessOwnerActions } from 'actions/actions-v2/cerm-process-owner-action-v2';
import { combineReducers } from 'redux';
import { s2ab } from 'utils/export-utils';
import saveAs from 'file-saver';

const INITIAL_STATE: ICermProcessOwnersState = {
    cermProcessOwner: null,
    isLoading: false,
    isSaving: false,
    isExporting: false,
};

const cermProcessOwner = (
    state: ICermProcessOwnersState['cermProcessOwner'] = INITIAL_STATE.cermProcessOwner,
    action: IAppAction
) => {
    switch (action.type) {
        case CermProcessOwnerActions.LOAD_CERM_PROCESS:
            return INITIAL_STATE.cermProcessOwner;

        case CermProcessOwnerActions.LOAD_CERM_PROCESS_FULFILLED:
        case CermProcessOwnerActions.CREATE_CERM_PROCESS_FULFILLED:
        case CermProcessOwnerActions.UPDATE_CERM_PROCESS_FULFILLED:
            return action.payload;

        default:
            return state;
    }
};

const isLoading = (
    state: ICermProcessOwnersState['isLoading'] = INITIAL_STATE.isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case CermProcessOwnerActions.LOAD_CERM_PROCESS:
            return true;

        case CermProcessOwnerActions.LOAD_CERM_PROCESS_FULFILLED:
        case CermProcessOwnerActions.LOAD_CERM_PROCESS_REJECTED:
            return false;

        default:
            return state;
    }
};

const isSaving = (
    state: ICermProcessOwnersState['isSaving'] = INITIAL_STATE.isSaving,
    action: IAppAction
) => {
    switch (action.type) {
        case CermProcessOwnerActions.CREATE_CERM_PROCESS:
        case CermProcessOwnerActions.UPDATE_CERM_PROCESS:
            return true;

        case CermProcessOwnerActions.CREATE_CERM_PROCESS_FULFILLED:
        case CermProcessOwnerActions.CREATE_CERM_PROCESS_REJECTED:
        case CermProcessOwnerActions.UPDATE_CERM_PROCESS_FULFILLED:
        case CermProcessOwnerActions.UPDATE_CERM_PROCESS_REJECTED:
            return false;

        default:
            return state;
    }
};

const isExporting = (
    state: ICermProcessOwnersState['isExporting'] = INITIAL_STATE.isExporting,
    action: IAppAction
) => {
    switch (action.type) {
        case CermProcessOwnerActions.EXPORT_SITE_USERS:
            return true;

        case CermProcessOwnerActions.EXPORT_SITE_USERS_FULFILLED:
            const blob = new Blob([s2ab(atob(action.payload.fileContents))], {
                type: 'application/excel',
            });
            saveAs(blob, action.payload.fileDownloadName);
            return false;

        case CermProcessOwnerActions.EXPORT_SITE_USERS_REJECTED:
            return false;

        default:
            return state;
    }
};

export const cermProcessOwnerReducer = combineReducers<ICermProcessOwnersState>({
    cermProcessOwner,
    isLoading,
    isSaving,
    isExporting,
});
