import React from 'react';
import { IconToolbar, IToolbarIconItem, ToolbarIconType } from '../v2/components';
import useTranslate from '../../translations/translation-utils';

interface IProps {
    isShowHelp: boolean;
    onClickHelp: () => void;
}

export const SiteFacilitiesToolbar: React.FC<IProps> = ({ isShowHelp, onClickHelp }) => {
    const translate = useTranslate();

    const getToolbarItems = (): IToolbarIconItem[] => {
        return [
            isShowHelp && {
                id: 'show-help',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.Help'),
                type: ToolbarIconType.help,
                onClick: onClickHelp,
            },
        ];
    };

    return <IconToolbar items={getToolbarItems()} />;
};
