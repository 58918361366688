import * as React from 'react';
import { connect } from 'react-redux';
import { IKeyValue } from 'models';
import { ICermProcessOwnerQuestion } from './CermProcessOwnerQuestionsBuilder';
import { ICermProcessOwner } from 'models/cerm-process-owners';
import { CermProcessOwnerQuestionField } from './CermProcessOwnerQuestionField';
import { Loader } from 'components/loader';
import { Panel, Button } from 'components/v2/components';
import actions from './actions';
import selectors from './selectors';
import Page from 'components/v2/page/Page';
import { IPersonLookup } from 'models/person-lookup';
import { CermProcessOwnerToolbar } from './cerm-process-owner-toolbar';
import useTranslate from 'translations/translation-utils';

interface IProps {
    isLoading: boolean;
    isSaving: boolean;
    isExporting: boolean;
    siteId: string;
    personsList: IPersonLookup[];
    cermProcessOwner: ICermProcessOwner;
    loadSitePersons: (siteId: string) => void;
    loadCermProcessOwner: (siteId: string) => void;
    createCermProcessOwner: (request: ICermProcessOwner) => void;
    updateCermProcessOwner: (request: ICermProcessOwner) => void;
    exportSiteUsers: () => void;
    onLoadPeopleLinkedToSite: (siteId: string) => void
}
export const CermProcessOwnerPage: React.FC<IProps> = (props) => {
    const translate = useTranslate();
    const [processOwner, setProcessOwner] = React.useState<ICermProcessOwner>(null);

    React.useEffect(() => {

        if (!props.siteId) {
            return;
        }
        props.onLoadPeopleLinkedToSite(props.siteId);
        props.loadSitePersons(props.siteId);
        props.loadCermProcessOwner(props.siteId);
        setProcessOwner({ siteId: props.siteId });
    }, [props.siteId]);

    React.useEffect(() => {
        if (!props.cermProcessOwner) {
            return;
        }

        setProcessOwner(props.cermProcessOwner);
    }, [props.cermProcessOwner]);

    const questions: ICermProcessOwnerQuestion[] = [
        {
            fieldName: 'process100OwnerIds',
            title: translate('CermProcessOwner.Question.1'),
        },
        {
            fieldName: 'process200OwnerIds',
            title: translate('CermProcessOwner.Question.2'),
            subQuestions: [
                {
                    fieldName: 'process2001OwnerIds',
                    title: translate('CermProcessOwner.Question.2.SubQuestion.1.Title'),
                    intro: translate('CermProcessOwner.Question.2.SubQuestion.1.Intro'),
                },
                {
                    fieldName: 'process2022OwnerIds',
                    title: translate('CermProcessOwner.Question.2.SubQuestion.2.Title'),
                    intro: translate('CermProcessOwner.Question.2.SubQuestion.2.Intro'),
                },
                {
                    fieldName: 'process200OtherOwnerIds',
                    title: translate('CermProcessOwner.Question.2.SubQuestion.3.Title'),
                    intro: translate('CermProcessOwner.Question.2.SubQuestion.3.Intro'),
                }
            ]
        },
        {
            fieldName: 'process300OwnerIds',
            title: translate('CermProcessOwner.Question.3'),
        },
        {
            fieldName: 'process400OwnerIds',
            title: translate('CermProcessOwner.Question.4'),
        },
        {
            fieldName: 'process500OwnerIds',
            title: translate('CermProcessOwner.Question.5'),
        }
    ];

    const isFormReady = () => {
        return questions && questions.length > 0 && props.personsList;
    };

    const getPersonsList = () => {
        return props.personsList.map((x) => ({
            key: x.id,
            value: x.id,
            label: `${x.firstName} ${x.lastName}`,
        }));
    };

    const onChange = (question: ICermProcessOwnerQuestion, persons: Array<IKeyValue<string>>) => {
        const results = { ...processOwner };
        results[question.fieldName] = persons && persons.map((p) => p.value);
        setProcessOwner(results);
    };

    const onSave = () => {
        if (processOwner.id) {
            props.updateCermProcessOwner(processOwner);
        } else {
            props.createCermProcessOwner(processOwner);
        }
    };

    return (
        <Page title={translate('CermProcessOwner.Lable.1')} className="cerm-process-owner-page">
            <CermProcessOwnerToolbar
                onExport={() => props.exportSiteUsers()}
                isExporting={props.isExporting}
            />
            <Loader loading={props.isLoading || props.isSaving || !isFormReady()}>
                <Panel
                    title={translate('CermProcessOwner.Lable.2')}
                    intro={translate('CermProcessOwner.Lable.3')}
                    className="column is-12"
                    bodyPadding={true}
                >
                    {isFormReady() &&
                        questions.map((question, key) => (
                            <CermProcessOwnerQuestionField
                                key={key}
                                question={question}
                                cermProcessOwner={processOwner}
                                personsList={getPersonsList()}
                                onChange={onChange}
                            />
                        ))}
                </Panel>
                {isFormReady() ? (
                    <div className="columns columns-buttons">
                        <div className="column column-save">
                            <Button id="buttonSave" isLoading={props.isLoading} onClick={onSave}>
                                {translate('Globals.Label.Save')}
                            </Button>
                        </div>
                    </div>
                ) : null}
            </Loader>
        </Page>
    );
};

export default connect(selectors, actions)(CermProcessOwnerPage);
