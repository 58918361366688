export const riskQuestionsOverviewEnGB = `
<div>
<p>Click the link below to view:</p>
<p><a href="https://confluence.cbre.com/display/ED/Risk+Questions+Process" target= "_blank">Risk Questions Process</a></p>
</div>
<br />
<div>
<p><a href="https://quantum.cbre.com/issues" target= "_blank">Report an Issue</a></p>
<p><a href="https://quantum.cbre.com/requests" target= "_blank">Raise a Request</a></p>
</div>`;
