import React from 'react';
import { Loader } from 'components/loader';
import useTranslate from 'translations/translation-utils';

interface Iprops {
    romonetReport?: string;
    isLoading: boolean;
}
const RomonetIframe: React.FC<Iprops> = ({ romonetReport, isLoading }) => {
    const translate = useTranslate();
    return (
        <Loader className="romonet-reports" loading={isLoading}>
            {romonetReport ? (
                <div className="romonet-reports-container">
                    <iframe
                        width="100%"
                        height="100%"
                        src={romonetReport}
                        frameBorder="0"
                        allowFullScreen={true}
                    />
                </div>
            ) : (
                <div className="column is-12 has-text-centered">
                    {translate('Romonet.Label.NoData')}
                </div>
            )}
        </Loader>
    );
};

export default RomonetIframe;
