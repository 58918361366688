import { IKeyValue } from 'models';

export enum Regions {
    Americas = 'Americas',
    APAC = 'APAC',
    EMEA = 'EMEA'
}

export const getRegions: Array<IKeyValue<string>> = Object.keys(Regions).map((key) => ({
    key,
    value: key
}));
