import * as React from 'react';
import { FormikProps } from 'formik';
import { IIncident, IKeyValue, IPersonSummary, IApproval, IncidentStatus } from 'models';
import { Field, Control } from 'components/form';
import Select from 'react-select';
import { BulmaSize } from 'enums/BulmaSize';
import { connect } from 'react-redux';
import selectors from './selectors';
import actions from './actions';
import { IPersonLookup } from 'models/person-lookup';
import {
    resetFormikApproversOnSiteChange,
    getMandatoryPeopleInfo,
    getPersonInfo,
    getSiteUserIds,
} from 'business-area-functions/incidents';
import { IMandatoryClaimCollections } from 'models/incidents/incident-settings';
import { DateTime, Format } from 'components/dateTime';
import { genericSort } from 'utils/sort-utils';
import { Loader } from 'components/loader';
import { Button } from 'components/v2/components';
import { useTranslate } from 'translations/translation-utils';

interface IProps {
    formikBag: FormikProps<Partial<IIncident>>;
    lookupSites?: Array<IKeyValue<string>>;
    users: IPersonLookup[];
    mandatorySettingApprovals: IMandatoryClaimCollections;
    isLoading: boolean;
    loadPersonsLookup: (siteId: string) => void;
    passLiveValuesOfIncidentToParent: (incident: Partial<IIncident>) => void;
}

const IncidentApprovals: React.FC<IProps> = props => {
    const translate = useTranslate();
    const [siteUsers, setSiteUsers] = React.useState<IPersonSummary[]>([]);
    const [addedPeople, setAddedPeople] = React.useState<IPersonSummary[]>([]);
    const [addPeople, setAddPeople] = React.useState<IPersonSummary[]>([]);
    const [approvalHistory, setApprovalHistory] = React.useState<IApproval[]>([]);

    React.useEffect(() => {
        props.loadPersonsLookup(props.formikBag.values.siteId);
        resetFormikApproversOnSiteChange(props.formikBag, props.users);
    }, []);

    React.useEffect(() => {
        if (props.formikBag) {
            const siteId = props.formikBag.values.siteId;
            const currentApprovers = props.formikBag.values.approvers;
            const siteUsersIds = getSiteUserIds(siteId, props.users);
            const siteUsersInfo = getPersonInfo(siteId, siteUsersIds, props.users, false, false);
            const mandatoryUsersInfo = getMandatoryPeopleInfo(
                props.formikBag.values,
                props.users,
                props.mandatorySettingApprovals
            );
            const usedIds = mandatoryUsersInfo.map(m => m.id).concat(currentApprovers);
            const availableUsers = siteUsersInfo.filter(f => usedIds.indexOf(f.id) === -1);
            const latestApprovals =
                props.formikBag.values.approvalResponses &&
                props.formikBag.values.approvalResponses
                    .slice()
                    .sort((a, b) => (a.statusDate > b.statusDate ? -1 : 1));
            const _addedPeople = getPersonInfo(siteId, currentApprovers, props.users, false, false);
            setSiteUsers(availableUsers);
            setAddedPeople(_addedPeople);
            setAddPeople([]);
            setApprovalHistory(latestApprovals);
        }
    }, [props.users, props.formikBag]);

    const updateCurrentApprovers = (value: IPersonSummary[]) => {
        const people = [] as IPersonSummary[];
        if (value) {
            for (const v of value) {
                people.push(v);
            }
        }
        setAddPeople(people);
    };

    const updateFormikBagApprovers = () => {
        const people = [] as string[];
        addPeople.map(v => people.push(v.id));
        const currentApprovers = props.formikBag.values.approvers.concat(people);
        props.formikBag.setFieldValue('approvers', currentApprovers);
        const siteId = props.formikBag.values.siteId;
        const siteUsersIds = getSiteUserIds(siteId, props.users);
        const mandatoryUsersInfo = getMandatoryPeopleInfo(
            props.formikBag.values,
            props.users,
            props.mandatorySettingApprovals
        );
        const siteUsersInfo = getPersonInfo(siteId, siteUsersIds, props.users, false, false);
        const usedIds = mandatoryUsersInfo.map(m => m.id).concat(currentApprovers);
        const availableUsers = siteUsersInfo.filter(f => usedIds.indexOf(f.id) === -1);
        const incident: Partial<IIncident> = {
            ...props.formikBag.values,
            approvers: currentApprovers,
        };
        props.passLiveValuesOfIncidentToParent(incident);
        setSiteUsers(availableUsers);
        setAddedPeople(getPersonInfo(siteId, currentApprovers, props.users, false, false));
        setAddPeople([]);
    };

    const removeUserFormikBagApprovers = (id: string) => {
        const currentApprovers = props.formikBag.values.approvers.filter(f => f !== id);
        props.formikBag.setFieldValue('approvers', currentApprovers);
        const siteId = props.formikBag.values.siteId;
        const siteUsersIds = getSiteUserIds(siteId, props.users);
        const siteUsersInfo = getPersonInfo(siteId, siteUsersIds, props.users, false, false);
        const mandatoryUsersInfo = getMandatoryPeopleInfo(
            props.formikBag.values,
            props.users,
            props.mandatorySettingApprovals
        );
        const usedIds = mandatoryUsersInfo.map(m => m.id).concat(currentApprovers);
        const availableUsers = siteUsersInfo.filter(f => usedIds.indexOf(f.id) === -1);
        const incident: Partial<IIncident> = {
            ...props.formikBag.values,
            approvers: currentApprovers,
        };
        props.passLiveValuesOfIncidentToParent(incident);

        setSiteUsers(availableUsers);
        setAddedPeople(getPersonInfo(siteId, currentApprovers, props.users, false, false));
        setAddPeople([]);
    };

    const renderHistoryTable = (): React.ReactNode => {
        return (
            <table className="table is-fullwidth is-hoverable">
                <thead>
                    <tr>
                        <th>{translate('IncidentApprovals.Label.Name')}</th>
                        <th>{translate('IncidentApprovals.Label.Comment')}</th>
                        <th>{translate('IncidentApprovals.Label.DateTime')}</th>
                        <th>{translate('IncidentApprovals.Label.ApprovalStatus')}</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {approvalHistory &&
                        approvalHistory.map(approval => {
                            const user =
                                props.users.length > 0 &&
                                props.users.find(x => x.id === approval.approver);
                            return (
                                <tr key={approval.approver}>
                                    <td>{user ? user.firstName + ' ' + user.lastName : ''}</td>
                                    <td>{approval.comment}</td>
                                    <td>
                                        <DateTime
                                            value={(new Date(approval.statusDate)).toString()}
                                            format={Format.DateAndTime}
                                        />
                                    </td>
                                    <td>{translate('Globals.Status.', approval.status)}</td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        );
    };

    const renderPersonTable = (people: IPersonSummary[]): React.ReactNode => {
        const updatedPeople = people.filter(
            elem =>
                !getMandatoryPeopleInfo(
                    props.formikBag.values,
                    props.users,
                    props.mandatorySettingApprovals
                ).find(({ id }) => elem.id === id)
        );
        return (
            <table className="table is-fullwidth is-hoverable">
                <thead>
                    <tr>
                        <th>{translate('IncidentApprovals.Label.FirstName')}</th>
                        <th>{translate('IncidentApprovals.Label.LastName')}</th>
                        <th>{translate('IncidentApprovals.Label.Email')}</th>
                        <th>{translate('IncidentApprovals.Label.SiteRoles')}</th>
                        <th>{translate('IncidentApprovals.Label.ApprovalStatus')}</th>
                        <th>&nbsp;</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {getMandatoryPeopleInfo(
                        props.formikBag.values,
                        props.users,
                        props.mandatorySettingApprovals
                    ).map(person => {
                        const approve = approvalHistory
                            ? approvalHistory.find(x => x.approver === person.id)
                            : undefined;
                        return (
                            <tr key={person.id}>
                                <td>{person.firstName}</td>
                                <td>{person.lastName}</td>
                                <td>{person.email}</td>
                                <td>
                                    {person.siteRoles
                                        .map(item => {
                                            return translate('Globals.SiteRoles.', item);
                                        })
                                        .join(', ')}
                                </td>
                                <td>
                                    {approve !== undefined
                                        ? translate('Globals.Status.', approve.status)
                                        : translate('Globals.Status.Unanswered')}
                                </td>
                                <td>{translate('IncidentApprovals.Label.Mandatory')}</td>
                            </tr>
                        );
                    })}
                    {updatedPeople.map(person => {
                        const approv = approvalHistory
                            ? approvalHistory.find(x => x.approver === person.id)
                            : undefined;
                        return (
                            <tr key={person.id}>
                                <td>{person.firstName}</td>
                                <td>{person.lastName}</td>
                                <td>{person.email}</td>
                                <td>
                                    {person.siteRoles
                                        .map(item => {
                                            return translate('Globals.SiteRoles.', item);
                                        })
                                        .join(', ')}
                                </td>
                                <td>
                                    {approv !== undefined
                                        ? translate('Globals.Status.', approv.status)
                                        : translate('Globals.Status.Unanswered')}
                                </td>
                                <td>
                                    <Button
                                        className="remove"
                                        onClick={removeUserFormikBagApprovers.bind(this, person.id)}
                                        disabled={
                                            props.formikBag.values.status === IncidentStatus.Closed
                                        }
                                    >
                                        {translate('IncidentApprovals.Button.Remove')}
                                    </Button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    return (
        <Loader loading={props.isLoading}>
            <div className="section">
                <div className="column is-12">
                    <div className="title is-4 section-title">
                        {translate('IncidentApprovals.SectionTitle')}
                    </div>
                    <Field
                        isHorizontal={true}
                        htmlFor="responsible"
                        label={translate('IncidentApprovals.Label.AddPeople')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control className="columns">
                                <div className="column is-10">
                                    <Select
                                        id="responsible"
                                        isDisabled={
                                            props.formikBag.values.status === IncidentStatus.Closed
                                        }
                                        options={genericSort('fullName', siteUsers, true).map(
                                            x => ({
                                                key: x.id,
                                                label: x.fullName,
                                                value: x.id,
                                                ...x,
                                            })
                                        )}
                                        isMulti={true}
                                        onChange={updateCurrentApprovers}
                                        value={addPeople.map(x => ({
                                            key: x.id,
                                            label: x.fullName,
                                            value: x.id,
                                            ...x,
                                        }))}
                                    />
                                </div>
                                <div className="column is-2">
                                    <Button
                                        id="add-responsible-person-button"
                                        onClick={updateFormikBagApprovers}
                                        disabled={addPeople.length === 0}
                                    >
                                        {translate('IncidentApprovals.Button.Add')}
                                    </Button>
                                </div>
                            </Control>
                        </Field>
                    </Field>
                    <Field
                        isHorizontal={true}
                        htmlFor="current"
                        label={translate('IncidentApprovals.Label.Current')}
                        labelSize={BulmaSize.Medium}
                    >
                        {renderPersonTable(addedPeople)}
                    </Field>
                    <Field
                        isHorizontal={true}
                        htmlFor="ApprovalHistory"
                        label={translate('IncidentApprovals.Label.ApprovalHistory')}
                        labelSize={BulmaSize.Medium}
                    >
                        {renderHistoryTable()}
                    </Field>
                </div>
            </div>
        </Loader>
    );
};

export default connect(selectors, actions)(IncidentApprovals);
