/* eslint-disable @typescript-eslint/no-inferrable-types */
import { IEmeraldIdInput } from '../shared/id.input';
import { IEmeraldOpenedInput } from '../shared/opened.input';

const id = `${Math.floor(Math.random() * 10000)}`;

export class EmeraldModalCoreInputs implements IEmeraldModalCoreInputs {
  id?: string = id;
  opened?: boolean = false;
  headerText?: string;
  areButtonsStacked?: boolean;
  closeOnEscape?: boolean = true;
  closeOnOutsideClick?: boolean = true;
  enableFullscreen?: boolean;
  widthInRem?: number;
  heightInRem?: number;
  hasCloseIcon?: boolean = false;
  contentId?: string = `content-${id}`;
  titleId?: string = `title-${id}`;
  areaLabelledBy?: string;
  areaDescribedBy?: string;
}

export interface IEmeraldModalCoreInputs
  extends IEmeraldIdInput,
    IEmeraldOpenedInput {
  headerText?: string;
  areButtonsStacked?: boolean;
  closeOnEscape?: boolean;
  closeOnOutsideClick?: boolean;
  enableFullscreen?: boolean;
  widthInRem?: number;
  heightInRem?: number;
  hasCloseIcon?: boolean;
  contentId?: string;
  titleId?: string;
  areaLabelledBy?: string;
  areaDescribedBy?: string;
}
