import React from 'react';
import { SearchFilter } from 'components/searchFilter';
import { IMockDrillsFilters, mockDrillScenarioType } from 'models/mock-drills';
import { CheckboxFilter } from 'components/checkboxFilter';
import useTranslate from 'translations/translation-utils';

interface IProps {
    filters: IMockDrillsFilters;
    onAddFilterKeyword: (keyword: string) => void;
    onRemoveFilterKeyword: (keyword: string) => void;
    onFilter: (field: string, value: string) => void;
    onToggleFilterExpanded?: (id: string) => void;
}

export const MockDrillsPageFilters: React.FC<IProps> = (props) => {
    const translate = useTranslate();
    const commonProps = {
        expandedFilters: ['filterScenarioTypes'],
        onFilter: props.onFilter,
        onToggleFilterExpanded: props.onToggleFilterExpanded,
    };

    return (
        <>
            <SearchFilter
                title={translate('RiskRegisterPage.Title.Filters')}
                name="filterKeywords"
                values={props.filters.filterKeywords}
                onAddFilterKeyword={props.onAddFilterKeyword}
                onRemoveFilterKeyword={props.onRemoveFilterKeyword}
            />
            <CheckboxFilter
                {...commonProps}
                title={translate('GlobalMockDrills.Scenarios')}
                name="filterScenarioTypes"
                selectedFilters={props.filters.filterScenarioTypes}
                unreservedFilters={mockDrillScenarioType}
            />
        </>
    );
};
