import React from 'react';
import { IconToolbar, IToolbarIconItem, ToolbarIconType } from '../v2/components';
import { history } from 'routes/App';
import { useTranslate } from 'translations/translation-utils';

interface IProps {
    isShowHelp: boolean;
    isReadOnly: boolean;
    onClickHelp: () => void;
}

export const ShiftsGridToolbar: React.FC<IProps> = ({
    isShowHelp,
    onClickHelp,
    isReadOnly
}) => {
    const translate = useTranslate();
    const getToolbarItems = (): IToolbarIconItem[] => {
        return [
            !isReadOnly && {
                id: 'create-new-shift-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.New'),
                type: ToolbarIconType.add,
                onClick: () => {
                    history.push('/Shifts/Details', { some: null });
                }
            },
            isShowHelp && {
                id: 'show-help',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.Help'),
                type: ToolbarIconType.help,
                onClick: onClickHelp,
            },
        ];
    };

    return <IconToolbar items={getToolbarItems()} />;
};
