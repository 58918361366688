import { IClient } from 'models/client';
import { IAppAction } from '../app-action';
import { ISetOutcomeAction } from 'models/set-outcome-action';
import { ISetProcessStepAction } from 'models/set-process-step-action';
import { IProcessStep } from 'reducers/cermAssessments';

export enum CermActions {

    SET_OUTCOME = '@@cerm/SET_OUTCOME',
    SET_OUTCOME_FULFILLED = '@@cerm/SET_OUTCOME_FULFILLED',
    SET_OUTCOME_REJECTED = '@@cerm/SET_OUTCOME_REJECTED',

    SET_PROCESS_STEP = '@@cerm/SET_PROCESS_STEP',
    SET_PROCESS_STEP_FULFILLED = '@@cerm/SET_PROCESS_STEP_FULFILLED',
    SET_PROCESS_STEP_REJECTED = '@@cerm/SET_PROCESS_STEP_REJECTED',

    SET_RISK_DETAILS = '@@cerm/SET_ADDITIONAL_RISK',
    SET_RISK_DETAILS_FULFILLED = '@@cerm/SET_RISK_DETAILS_FULFILLED',
    SET_RISK_DETAILS_REJECTED = '@@cerm/SET_RISK_DETAILS_REJECTED',

    LOAD_CERM_ASSESSMENT = '@@cerm/LOAD_CERM_ASSESSMENT',
    LOAD_CERM_ASSESSMENT_FULFILLED = '@@cerm/LOAD_CLIENT_FULFILLED',
    LOAD_CERM_ASSESSMENT_REJECTED = '@@cerm/LOAD_CLIENT_REJECTED',

    LOAD_CERM_ASSESSMENT_RISK = '@@cerm/LOAD_CERM_ASSESSMENT',
    LOAD_CERM_ASSESSMENT_RISK_FULFILLED = '@@cerm/LOAD_CLIENT_FULFILLED',
    LOAD_CERM_ASSESSMENT_RISK_REJECTED = '@@cerm/LOAD_CLIENT_REJECTED',

    SORT_CERM = '@@cerm/SORT_CERM_ASSESSMENT',
}
export const loadCermAssessment = (siteId: string): IAppAction => ({
    type: CermActions.LOAD_CERM_ASSESSMENT,
    payload: siteId
});

export const loadCermAssessmentRisk = (siteId: string): IAppAction => ({
    type: CermActions.LOAD_CERM_ASSESSMENT_RISK,
    payload: siteId
});

export const setOutcome = (model: ISetOutcomeAction): IAppAction => ({
    type: CermActions.SET_OUTCOME,
    payload: model
});

export const setAssessmentRisk = (model: IProcessStep): IAppAction => ({
    type: CermActions.SET_RISK_DETAILS,
    payload: model
});

export const setProcessStep = (model: ISetProcessStepAction): IAppAction => ({
    type: CermActions.SET_PROCESS_STEP,
    payload: model
});

export const onSort = (key: Extract<keyof IClient, string>, sortAscending: boolean) => ({
    type: CermActions.SORT_CERM,
    payload: { key, sortAscending }
});
