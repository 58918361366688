import React from 'react';
import {
    Timeline,
    TimelineHeader,
    TimelineHeaderGridValue,
    TimelineHeaderGridValueCollection
} from 'components/timeline';
import { formatDateTime } from 'utils/date-utils';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';
import Dotdotdot from 'react-dotdotdot';

interface IEntityHistoryProps {
    entity: any;
    intl: any;
}

class CreatedEntitytHistoryItemClass extends React.PureComponent<IEntityHistoryProps> {

    public state = {
        isToggled: false
    };

    constructor(props: IEntityHistoryProps) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    public onClick() {
        const toggle = !this.state.isToggled;
        this.setState({ isToggled: toggle });
    }

    public render(): JSX.Element {
        return (
            <Timeline expanded={this.state.isToggled}>
                <TimelineHeader
                    expanded={this.state.isToggled}
                    centered={true}
                >
                    <TimelineHeaderGridValueCollection>
                        <TimelineHeaderGridValue className="timeline-title column is-9" label="Details">
                            <Dotdotdot clamp={1} size={50}> {translate(this.props.intl, 'CalenderDetails.Message.4')} {this.props.entity && this.props.entity.createdByPersonName}
                            </Dotdotdot>
                        </TimelineHeaderGridValue>
                        <TimelineHeaderGridValue className="has-text-centered column i-5" label="Date/Time">
                            {formatDateTime(this.props.entity && this.props.entity.createdDateTime && this.props.entity.createdDateTime.toString())}
                        </TimelineHeaderGridValue>
                    </TimelineHeaderGridValueCollection>
                </TimelineHeader>
            </Timeline>
        );
    }
}

export const CreatedEntitytHistoryItem = injectIntl(CreatedEntitytHistoryItemClass);