import { IEmeraldSwitchCoreInputs } from '../switch/switch.inputs';
import { IEmeraldAvatarCoreInputs } from '../avatar/avatar.inputs';
import { IEmeraldRadioButtonCoreInputs } from '../radio-button/radio-button.inputs';
import { IEmeraldCheckboxCoreInputs } from '../checkbox/checkbox.inputs';
import { EmeraldLabelPosition } from '../../utils/common/common';
import {
  EmeraldListItemVariant,
  EmeraldListItemVariantType,
} from './list-item.variant';

type avatarOptions = Omit<IEmeraldAvatarCoreInputs, 'label'>;
type checkboxOptions = Omit<IEmeraldCheckboxCoreInputs, 'label'>;
type radioOptions = Omit<IEmeraldRadioButtonCoreInputs, 'label'>;
type switchOptions = Omit<IEmeraldSwitchCoreInputs, 'label'>;

export interface IEmeraldListItemCoreInputs {
  type?: EmeraldListItemVariant | EmeraldListItemVariantType;
  value?: any;
  label?: string;
  labelPosition?: EmeraldLabelPosition;
  secondaryText?: string;
  hasAvatar?: boolean;
  avatarOptions?: avatarOptions;
  checkboxOptions?: checkboxOptions;
  radioOptions?: Exclude<radioOptions, 'label'>;
  switchOptions?: switchOptions;
  radioGroup?: string;
  enableRipple?: boolean;
  classes?: string;
  itemTemplate?: any;
  trailingTemplate?: any;
  tabIndex?: number;
  title?: string;
}

export class EmeraldLisItemCoreInputs implements IEmeraldListItemCoreInputs {
  type?: EmeraldListItemVariant | EmeraldListItemVariantType =
    EmeraldListItemVariant.DEFAULT;
  value?: any;
  label?: string;
  labelPosition?: EmeraldLabelPosition = EmeraldLabelPosition.RIGHT;
  secondaryText?: string;
  hasAvatar?: boolean = false;
  avatarOptions?: avatarOptions;
  checkboxOptions?: checkboxOptions;
  radioOptions?: Exclude<radioOptions, 'label'>;
  switchOptions?: switchOptions;
  radioGroup?: string = `Radio${Math.random().toString()}`;
  enableRipple?: boolean = true;
  classes?: string;
  itemTemplate?: any;
  trailingTemplate?: any;
  tabIndex?: number;
  title?: string = '';
}
