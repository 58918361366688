import { Action } from 'redux';
import { IBuilding } from 'models/building';
import { IBenchmarkData } from 'models/pue-benchmarks';

export enum QuestionnaireActions {
    SUBMIT_MODEL = '@@questionnaire/SUBMIT_MODEL',
    SUBMIT_MODEL_FULFILLED = '@@questionnaire/SUBMIT_MODEL_FULFILLED',
    SUBMIT_MODEL_REJECTED = '@@questionnaire/SUBMIT_MODEL_REJECTED',
}

export interface ISubmitModelAction extends Action {
    type: QuestionnaireActions.SUBMIT_MODEL;
    payload: {
        siteId: string,
        building: Partial<IBuilding>
    };
}
export interface ISubmitModelFulfilledAction extends Action {
    type: QuestionnaireActions.SUBMIT_MODEL_FULFILLED;
    payload: IBenchmarkData;
}

export interface ISubmitModelRejectedAction extends Action {
    type: QuestionnaireActions.SUBMIT_MODEL_REJECTED;
    error: true;
    payload: any;
}

export type QuestionnaireActionTypes =
| ISubmitModelAction
| ISubmitModelRejectedAction
| ISubmitModelFulfilledAction;
