import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { loadAttachmentsGlobalMockDrill } from 'actions/actions-v2/attachment-action-v2';
import { MockDrillReportScenarioAttachmentsTab } from './mock-drill-report-scenario-attachments-tab';

const getAttachments = (state: IRootState) => state.globalMockDrillsState.attachments;

const mapStateToProps = createSelector(getAttachments, (attachments) => ({
    attachments,
}));

const mapDispatchToProps = {
    loadAttachments: loadAttachmentsGlobalMockDrill,
};

export const MockDrillReportScenarioAttachmentsTabContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MockDrillReportScenarioAttachmentsTab);
