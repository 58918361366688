import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import selectors from './selectors';
import actions from './actions';
import { history } from 'routes/App';
import { NavLink } from 'react-router-dom';
import { Tab } from 'models/critical-system-spares/critical-system-spare-enums';
import SpareDetailsTab from './tabs/details/spare-details-tab';
import { ManageStockTab } from './tabs/manage-stock/manage-stock-tab';
import SpareDocumentsTab from './tabs/documents/spare-documents-tab';
import { ICriticalSystemSpare } from 'models/critical-system-spares/critical-system-spare';
import { Formik, FormikProps } from 'formik';
import { object, string, number, ref } from 'yup';
import { Button, ButtonWithConfirmDialog } from 'components/v2/components';
import { Loader } from 'components/loader';
import Page from 'components/v2/page/Page';
import { IKeyValue } from 'models';
import { scrollToFirstValidationError } from 'utils/form-utils';
import { IEntitySite } from 'models/entity-site';
import useTranslate from 'translations/translation-utils';

interface IProps extends RouteComponentProps<IParams> {
    isLoading: boolean;
    criticalSpare: ICriticalSystemSpare;
    siteId: string;
    load: (model: IEntitySite) => void;
    create: (model: Partial<ICriticalSystemSpare>) => void;
    update: (model: Partial<ICriticalSystemSpare>) => void;
    remove: (id: string) => void;
}

interface IParams {
    id?: string;
    tab: string;
}

export const CriticalSystemSpareUpsertPage: React.FC<IProps> = (props) => {
    const translate = useTranslate();
    const [isValid, setIsValid] = React.useState(false);
    const id = props.match.params.id;
    const systemSparesPageUrl = '/CriticalSystemSpares';
    const customLinks: Array<IKeyValue<string>> = [
        { key: systemSparesPageUrl, value: translate('CriticalSystemSpares.Lable.1') },
    ];
    let formikBag: FormikProps<ICriticalSystemSpare>;

    React.useEffect(() => {
        if (!id) {
            return;
        }

        props.load({
            id,
            siteId: props.siteId,
        });
    }, [id]);

    const validationSchema = object<Partial<ICriticalSystemSpare>>().shape({
        subCategoryName: string().trim().required(translate('CriticalSystemSpares.Validation.1')),
        categoryName: string().trim().required(translate('CriticalSystemSpares.Validation.2')),
        name: string().trim().required(translate('CriticalSystemSpares.Validation.3')),
        quantityMin: number().required(translate('CriticalSystemSpares.Validation.4')),
        quantityMax: number().required(translate('CriticalSystemSpares.Validation.5')).min(
            ref('quantityMin'),
            translate('CriticalSystemSpares.Validation.6')
        ),
    });

    const onSubmit = (value: Partial<ICriticalSystemSpare>) => {
        if (value.id) {
            props.update(value);
        } else {
            props.create(value);
        }
    };

    const renderTab = (formik: FormikProps<ICriticalSystemSpare>) => {
        formikBag = formik;
        setIsValid(formik.isValid);
        switch (props.match.params.tab.toLowerCase()) {
            case Tab.details:
                return <SpareDetailsTab formikBag={formik} />;
            case Tab.stock:
                return <ManageStockTab formikBag={formik} />;
            case Tab.documents:
                return <SpareDocumentsTab id={props.match.params.id} />;
        }
    };

    const setNavLink = (tab: Tab) => {
        const match = props.match;
        if (id) {
            return match.path.replace(':id?', match.params.id).replace(':tab', tab);
        }
        return match.path.replace(':id?/', '').replace(':tab', tab);
    };

    const shouldShowDocumentTab = () => {
        return id && id.length > 1;
    };

    const isActive = (value: string) => {
        const tab = props.match.params.tab.toLowerCase();
        return tab === value ? 'is-active' : '#';
    };

    const onArchive = () => {
        props.remove(id);
    };

    const handleClick = (e: any) => {
        formikBag.validateForm();
        if (!isValid) {
            e.preventDefault();
        }
    };

    const onSave = async () => {
        formikBag.validateForm().then(() => {
            if (isValid) {
                if (!!formikBag.values.estimateValue) {
                    formikBag.values.estimateValue = Number(formikBag.values.estimateValue.toString().replace(/,/g, ''));
                }
                else {
                    formikBag.values.estimateValue = 0;
                }
                
                formikBag.submitForm();
            } 
            else {
                scrollToFirstValidationError();
            }
        });
    };

    const title =
        props.criticalSpare && props.criticalSpare.name
            ? props.criticalSpare.name
            : translate('CriticalSystemSpares.Lable.2');

    return (
        <Page
            title={title}
            breadcrumbCustomLinks={customLinks}
            className="critical-spares-page"
            redirectOnSiteChange={true}
            redirectOnSiteChangeUrl={systemSparesPageUrl}
        >
            <Loader loading={props.isLoading}>
                <div className="tabs is-boxed is-large">
                    <ul>
                        <li className={isActive(Tab.details)}>
                            <NavLink id="details-tab-link" to={setNavLink(Tab.details)}>
                                <span>{translate('RiskRegisterUpsertPage.Tabs.Details')}</span>
                            </NavLink>
                        </li>
                        <li className={isActive(Tab.stock)}>
                            <NavLink
                                id="manage-stocks-tab-link"
                                to={setNavLink(Tab.stock)}
                                onClick={handleClick}
                            >
                                <span>{translate('CriticalSystemSpares.Lable.3')}</span>
                            </NavLink>
                        </li>
                        {shouldShowDocumentTab() && (
                            <li className={isActive(Tab.documents)}>
                                <NavLink
                                    id="documents-tab-link"
                                    to={setNavLink(Tab.documents)}
                                    onClick={handleClick}
                                >
                                    <span>{translate('RiskRegisterUpsertPage.Tabs.Documents')}</span>
                                </NavLink>
                            </li>
                        )}
                    </ul>
                </div>
                <Formik<Partial<ICriticalSystemSpare>>
                    initialValues={props.criticalSpare}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    render={renderTab}
                    enableReinitialize={true}
                    isInitialValid={props.match.params.id ? true : false}
                />
                <div className="columns section">
                    <div className="column">
                        <Button
                            buttonType="cancel"
                            isLoading={props.isLoading}
                            onClick={() => history.push('/CriticalSystemSpares')}
                        >
                            {translate('Globals.Label.Cancel')}
                        </Button>
                        {id && props.criticalSpare.isActive && (
                            <ButtonWithConfirmDialog
                                buttonType="archive"
                                buttonText={translate('CriticalSystemSpares.Lable.4')}
                                title={translate('CriticalSystemSpares.Lable.5')}
                                message={translate('CriticalSystemSpares.Lable.6')}
                                onConfirm={onArchive}
                                isLoading={props.isLoading}
                            />
                        )}
                    </div>
                    <div className="column has-text-right">
                        <Button
                            type="submit"
                            buttonType="save"
                            onClick={onSave}
                            isLoading={props.isLoading}
                        >
                            {translate('Globals.Label.Save')}
                        </Button>
                    </div>
                </div>
            </Loader>
        </Page>
    );
};

export default withRouter(connect(selectors, actions)(CriticalSystemSpareUpsertPage));
