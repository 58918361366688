import * as React from 'react';
import { IncidentApprovalStatus, IApproval } from 'models';
import { ConfirmWithCommentDialog } from '../confirmationDialog/ConfirmWithCommentDialog';
import { getUserId } from 'helpers/helpers';
import { Button } from '../v2/components';
import { useTranslate } from 'translations/translation-utils'

export interface IProps {
    changeApprovalStatus(approval: IApproval): void;
}

export interface IState {
    approval: boolean;
    showPopup: boolean;
    approvalType: IncidentApprovalStatus | null;
}
export const IncidentApprovalBarNormal: React.FC<IProps> = (props) => {
    const translation = useTranslate();
    const [showPopup, setShowPopup] = React.useState(false);
    const [approvalType, setApprovalType] = React.useState(null);
    const showCommentPopup = (approvalType: IncidentApprovalStatus): React.MouseEventHandler<HTMLButtonElement> => (e) => {
        e.preventDefault();
        setApprovalType(approvalType);
        setShowPopup(true)
        // this.setState({ approvalType, showPopup: true });
    }

    const cancelCommentPopup = () => {
        setApprovalType(null);
        setShowPopup(false)
        //  this.setState({ approvalType: null, showPopup: false });
    }

    const confirmCommentPopup = (comment: string) => {
        setShowPopup(false)
        //  this.setState({ showPopup: false });
        props.changeApprovalStatus(createApproval(comment));
    }

    const createApproval = (comment: string): IApproval => {
        const approval: IApproval = { approver: getUserId(), comment, status: approvalType, statusDate: new Date() };
        return approval;
    }

    return (
        <div style={{ width: '100%', height: 50, backgroundColor: 'wheat', padding: 5, marginBottom: 10 }}>
            <label
                className="is-primary is-medium is-pulled-left"
                style={{ fontStyle: 'bold', paddingTop: 8, color: '#003F2D', fontSize: 18, display: 'block' }}
            >
                {translation('IncidentApprovals.Messages.ApproveIncident')}
            </label>
            <div className="is-pulled-right" style={{ marginRight: 5 }}>
                <Button
                    style={{ marginRight: 5 }}
                    id="incidentRejectButton"
                    buttonType="cancel"
                    onClick={showCommentPopup(IncidentApprovalStatus.Rejected)}
                >
                    {translation('IncidentApprovals.Messages.Reject')}
                </Button>
                <Button
                    id="incidentApproveButton"
                    onClick={showCommentPopup(IncidentApprovalStatus.Approved)}
                >  {translation('IncidentApprovals.Messages.Approve')}
                </Button>
                {showPopup &&
                    <ConfirmWithCommentDialog
                        confirmMessage={`${approvalType === IncidentApprovalStatus.Approved ?
                            translation('IncidentApprovals.Messages.Approve') : translation('IncidentApprovals.Messages.Reject')}  ${translation('IncidentApprovals.Messages.ThisIncident')}`}
                        onConfirm={confirmCommentPopup}
                        onClose={cancelCommentPopup}
                        requireComment={approvalType === IncidentApprovalStatus.Rejected ? true : false}
                    />}
            </div>
        </div>
    );
}
