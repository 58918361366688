import { createSelector } from 'reselect';
import { IRootState } from '../../store';
import { constant } from 'lodash';
import { ICermOverviewRootState } from '../../../reducers/cerm-overview';

const getIsInitialising = (state: ICermOverviewRootState) => {
    return state.cermOverview.isLoading;
};
const getShowHelpModule = (state: IRootState) => state.app.showHelpModule;

const getPermissions = (state: ICermOverviewRootState) => state.app.permissions;
const getUserId = (state: ICermOverviewRootState) => state.app.userId;

const getPageTitle = constant('Cerm Process Step Overview');

const getPerson = createSelector(
    (state: any) => state.person,
    (state) => state.personProfile
);

const getOperatingPlatforms = (state: IRootState) => state.operatingPlatformState.operatingPlatforms;

export default createSelector(
    getPageTitle,
    getPermissions,
    getIsInitialising,
    getShowHelpModule,
    getPerson,
    getOperatingPlatforms,
    getUserId,
    (
        pageTitle,
        permissions,
        isInitialising,
        showHelpModule,
        person,
        operatingPlatformValues,
        userId,
    ) => ({
        pageTitle,
        permissions,
        isInitialising,
        showHelpModule,
        person,
        operatingPlatformValues,
        userId,
    })
);
