import { INotification } from 'models/notification';
import { NavActions, NavActionTypes } from '../../actions/nav-actions';
import {
    updateLanguage,
    loadPerson,
    updateIsShowFaq,
    showRoleSelectorDialog,
    showOperatingPlatformSelectorDialog
} from 'actions/actions-v2/user-profile-action-v2';
import { loadNotificationCount } from 'actions/actions-v2/notification-action-v2';

export default {
    onUpdateNotification: (notification: INotification): NavActionTypes => ({
        type: NavActions.NOTIFICATION_RECEIVED,
        payload: {
            notification,
        },
    }),
    onNotificationRead: (id: string, notificationLink: string): NavActionTypes => ({
        type: NavActions.NOTIFICATION_READ,
        payload: {
            id,
            notificationLink,
        },
    }),
    onToggleExpandUserMenu: (expanded: boolean): NavActionTypes => ({
        type: NavActions.TOGGLE_EXPAND_USER_MENU,
        payload: {
            isExpanded: expanded,
        },
    }),
    onMarkAllRead: (): NavActionTypes => ({
        type: NavActions.MARK_ALL_READ,
    }),
    updateLanguage,
    updateIsShowFaq,
    loadPersonProfile: loadPerson,
    loadNotificationCount,
    showRoleSelectorDialog,
    showOperatingPlatformSelectorDialog
};
