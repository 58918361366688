import { IAppAction } from '../app-action';


export enum IFacilityActions {
    LOAD_FACILITY = '@@Facility/LOAD_FACILITY',
    LOAD_FACILITY_FULFILLED = '@@Facility/LOAD_FACILITY_FULFILLED',
    LOAD_FACILITY_REJECTED = '@@Facility/LOAD_FACILITY_REJECTED',
}

export const loadFacility = (): IAppAction => ({
    type: IFacilityActions.LOAD_FACILITY,
});
