import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import 'url-search-params-polyfill';
import { ScrollUpButton } from 'components/scrollUpButton';
import { Toastr } from '../toastr';
import { NavBar } from 'components/nav/NavBar';
/* import logo from 'assets/logo-cbre-quantum.svg'; */
import logo from 'assets/CBREQuantum-NewLogo_White.svg'
/* import logoMobile from 'assets/logo-cbre-quantum-mobile.svg'; */
import { NavLink } from 'react-router-dom';
import actions from './actions';

interface IProps extends RouteComponentProps {
    children: any;
}

const LayoutWithoutSideNav: React.FC<IProps> = ({ children }) => {
    return (
        <>
            <div className="layout-side-nav section masthead">
                <NavBar className="masthead__container">
                    <div className="navbar-brand">
                        <NavLink className="navbar-item masthead__link--home" to="/">
                            <img src={logo} alt="CBRE Quantum" className="is-hidden-mobile" />
                            <img src={logo} alt="CBRE Quantum" className="is-hidden-tablet" />
                        </NavLink>
                    </div>
                </NavBar>
            </div>
            <div className="main quantum">
                <div className="container">{children}</div>
            </div>
            <ScrollUpButton />
            <Toastr />
        </>
    );
};

export default withRouter(connect(null, actions)(LayoutWithoutSideNav));
