import { CreateQuestionSetActions, CreateQuestionSetActionTypes } from '../../../actions/create-question-set-actions';
import { loadQuestionSet, createQuestionSet, updateQuestionSet, cleanUpQuestionSet } from 'actions/actions-v2/question-set-actions';

export default {
    loadQuestionSet,
    createQuestionSet,
    updateQuestionSet,
    cleanUpQuestionSet,
    goToQuestion: (questionIndex: number): CreateQuestionSetActionTypes => ({
        type: CreateQuestionSetActions.SET_SELECTED_QUESTION,
        payload: {
            selectedQuestionIndex: questionIndex
        }
    })
};
