import { connect } from 'react-redux';
import actions from './actions';
import selector from './selector';

import { ActionFilters as View } from './views/action-filters';

export const ActionFilters = connect(
    selector,
    actions
)(View);
