import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { RiskActions } from 'actions/actions-v2/risk-action-v2';
import { IClientSiteSummary } from 'models/risk';

const controllerName = 'ClientRisksSummary';
const apiPath = `/api/${controllerName}`;

const getActions = {
    action: RiskActions.LOAD_RISKS_SUMMARY,
    actionFulfilled: RiskActions.LOAD_RISKS_SUMMARY_FULFILLED,
    actionRejected: RiskActions.LOAD_RISKS_SUMMARY_REJECTED
} as IActionType;

const getAllActions = {
    action: RiskActions.LOAD_RISKS_SUMMARIES,
    actionFulfilled: RiskActions.LOAD_RISKS_SUMMARIES_FULFILLED,
    actionRejected: RiskActions.LOAD_RISKS_SUMMARIES_REJECTED
} as IActionType;

const loadClientRiskSummaryEpic: Epic<IAppAction, IAppAction> = (action$, state$) => new BaseEpic(action$, state$, getActions).getById<string>(apiPath);

const loadClientRiskSummariesEpic: Epic<IAppAction, IAppAction> = (action$, state$) => new BaseEpic(action$, state$, getAllActions).get<IClientSiteSummary[]>(apiPath);

export const clientRisksSummaryEpicsV2 = combineEpics(
    loadClientRiskSummaryEpic,
    loadClientRiskSummariesEpic
);
