import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { onRouteChange } from 'actions/app-actions';
import { Tab, Tabs } from 'components/tabs';
import { BulmaSize } from 'enums/BulmaSize';
import { CermSiteOverviewFilters } from 'routes/cerm-site-overview/views/cerm-site-overview-filters';
import { CermSiteOverviewList } from 'routes/cerm-site-overview/views/cerm-site-overview-list';
import { checkPermissions } from 'utils/permission-utils';
import { IKeyValue } from '../../../models/key-value';
import { RouteUrl } from '../routes';
import { easeInScrollToTop } from 'utils/animation-utils';
import { OverlayPanel } from 'components/v2/components';
import { CermSiteOverviewToolbar } from '../cerm-site-overview-toolbar';
import Page from 'components/v2/page/Page';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';

export interface IProps extends RouteComponentProps<{}> {
    itemIds: string[];
    pageTitle: string;
    permissions: Array<IKeyValue<string>>;
    averageScore: number;
    selectTab: (tab: RouteUrl) => void;
    expandItems: (ids: string[]) => void;
    collapseAllItems: () => void;
    isInitialising: boolean;
    onPageLoad: () => void;
    onTabChange: () => void;
    onClearFilters: () => void;
    intl: any;
}

interface IState {
    showFilters: boolean;
}

class CermSiteOverviewPageClass extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            showFilters: false
        };
    }

    public componentDidMount() {
        this.props.onPageLoad();
        easeInScrollToTop();
    }

    public onDownloadClick() {
        window.open('/Downloads/CermAssessments');
    }

    public render() {
        const props = this.props;
        const intl = props.intl;

        return (
            <Page title={translate(intl, 'CermSiteOverview.Page.Title')} className="CermSiteOverview">
                <CermSiteOverviewToolbar
                    hasPermissionToDownload={checkPermissions(
                        ['CermAssessmentExport'],
                        this.props.permissions
                    )}
                    disableDownload={this.props.isInitialising}
                    onDownload={this.onDownloadClick}
                    onClearFilters={this.props.onClearFilters}
                    onShowFilters={() => this.setState({ showFilters: true })}
                />
                <OverlayPanel
                    title={translate(intl, 'RiskRegisterPage.Title.Filters')}
                    isVisible={this.state.showFilters}
                    onClose={() => this.setState({ showFilters: false })}
                    onOutsideDialogClick={() => this.setState({ showFilters: false })}
                    onClearFilters={this.props.onClearFilters}
                >
                    <CermSiteOverviewFilters />
                </OverlayPanel>
                <Tabs isToggle={true} bulmaSize={BulmaSize.Default} isFullwidth={true}>
                    {[RouteUrl.ViewByClient, RouteUrl.ViewByCountry].map((route) =>
                        getRouteTab(route, props)
                    )}
                </Tabs>
                <div className="tabs-container tab-content">
                    <CermSiteOverviewList
                        onCollapseItems={this.onCollapseItems}
                        onExpandItems={this.onExpandItems}
                        averageScore={props.averageScore}
                    />
                </div>
            </Page>
        );
    }

    private onExpandItems = () => this.props.expandItems(this.props.itemIds);
    private onCollapseItems = () => this.props.collapseAllItems();
}

const getRouteLabel = (route: RouteUrl) => {
    // TODO: Localise
    const prefix = 'View by';

    switch (route) {
        case RouteUrl.ViewByClient:
            return `${prefix} Client`;

        case RouteUrl.ViewByAreaManager:
            return `${prefix} Area Manager`;

        case RouteUrl.ViewByCountry:
            return `${prefix} Country`;

        default:
            return null;
    }
};

const getRouteTab = (route: RouteUrl, props: IProps) => (
    <Tab
        key={route}
        text={getRouteLabel(route)}
        active={props.location.pathname === route}
        // tslint:disable-next-line:jsx-no-lambda
        onClick={() => changeTab(route, props)}
    />
);

const changeTab = (route: string, props: IProps) => {
    onRouteChange(route);
    props.onTabChange();
};

export const CermSiteOverviewPage = injectIntl(CermSiteOverviewPageClass);
