import { AppActions, AppActionTypes } from 'actions/app-actions';
import { ActionActions, ActionActionTypes, onToggleExpanded } from '../../../actions/action-actions';
import { IActionsState } from '../../../reducers/actions';
import { IAction } from 'models/action';
import { loadActions, onSort } from 'actions/actions-v2/action-action-v2';

export default {
    onSort,
    onToggleExpanded,
    onPageLoad: loadActions,
    onSiteChange: (siteId: string): AppActionTypes => ({
        type: AppActions.CHANGE_SITE,
        payload: {
            siteId
        }
    }),
    onToggleFilterExpanded: (id: Extract<keyof IActionsState, string>): ActionActionTypes => ({
        type: ActionActions.TOGGLE_FILTER_EXPANDED,
        payload: {
            id
        }
    }),

    onOpenEditActionModal: (upsertRequest: IAction): ActionActionTypes => ({
        type: ActionActions.SHOW_UPSERT_ACTION_MODAL,
        payload: {
            item: upsertRequest
        }
    }),
};
