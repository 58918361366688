import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import {
    loadAttachmentsShiftHandover,
    uploadAttachmentShiftHandover
} from 'actions/actions-v2/attachment-action-v2';
import { ShiftHandoverDocumentsTab } from '././shift-handover-documents-tab';

const getIsUploading = (state: IRootState) => state.shiftHandoverState.isLoading;
const getAttachments = (state: IRootState) => state.shiftHandoverState.attachments;

const mapStateToProps = createSelector(
    getIsUploading,
    getAttachments,
    (isUploading, attachments) => ({
        isUploading,
        attachments
    })
);

const mapDispatchToProps = {
    loadAttachments: loadAttachmentsShiftHandover,
    uploadAttachment: uploadAttachmentShiftHandover
};

export const ShiftHandoverDocumentsTabContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ShiftHandoverDocumentsTab);
