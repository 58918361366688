import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { CermProcessStepRiskPanel } from './CermProcessStepRiskPanel';
import { loadRisk } from 'actions/actions-v2/risk-action-v2';
import { loadActions } from 'actions/actions-v2/action-action-v2';
import { saveProcessStepAnswer } from 'actions/actions-v2/cerm-assessment-action-v3';
import { loadPersonsLookup } from 'actions/actions-v2/person-action-v2';

const getSiteId = (state: IRootState) => state.app.siteId;
const getRisk = (state: IRootState) => state.riskRegisterState.risk;
const getActions = (state: IRootState) => state.actionRegisterState.actions;
const getLookups = (state: IRootState) => state.app;
const getOperatingPlatforms = (state: IRootState) =>
    state.operatingPlatformState.operatingPlatforms;

const mapStateToProps = createSelector(
    getSiteId,
    getRisk,
    getActions,
    getLookups,
    getOperatingPlatforms,
    (siteId, createdRisk, createdActions, lookups, allOperatingPlatforms) => ({
        siteId,
        createdRisk,
        createdActions,
        lookups,
        allOperatingPlatforms,
    })
);

const mapDispatchToProps = {
    loadRisk,
    loadActions,
    saveProcessStepAnswer,
    PersonsLookup: loadPersonsLookup,
};

export const CermProcessStepRiskPanelContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CermProcessStepRiskPanel);
