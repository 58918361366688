import * as React from 'react';
import { ModalCard, ModalCardBody, ModalCardFooter, ModalCardHeader } from '../modalCard';
import { Control, Field } from '../form';
import { IRiskProfileQuestion } from 'models';
import { BulmaSize } from 'enums/BulmaSize';
import { Button } from '../v2/components';
import { injectIntl } from 'react-intl';
import { translate } from 'translations/translation-utils';

export interface IProps {
    question: IRiskProfileQuestion;
    questionSetId: string;
    intl: any;
}

export interface IActions {
    onSaveQuestion: (question: IRiskProfileQuestion, questionSetId: string) => void;
    onCancel: () => void;
}

export interface IState {
    question: IRiskProfileQuestion;
}

interface IMergeProps extends IProps, IActions { }

export class EditRiskProfileQuestionClass extends React.Component<IMergeProps, IState>  {
    constructor(props: IMergeProps) {
        super(props);
        this.state = { question: this.props.question };
    }

    public onClose = () => {
        this.props.onCancel();
    }

    public onSaveQuestion = () => {
        this.props.onSaveQuestion(this.state.question, this.props.questionSetId);
    }

    public onChangeText = (text: React.ChangeEvent<HTMLTextAreaElement>) => {
        const questionUpdated: IRiskProfileQuestion = { ...this.state.question, questionText: text.currentTarget.value };
        this.setState({ question: questionUpdated });
    }

    public render() {
        return (
            <ModalCard modalId="textUpdate" isShown={true}>
                <ModalCardHeader modalId="confirmation" title="Edit Question" />
                <ModalCardBody modalId="confirmation">
                    {/* Text */}
                    <Field
                        isHorizontal={true}
                        htmlFor="Text"
                        label="Question"
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <textarea
                                    id="Text"
                                    className="textarea"
                                    rows={4}
                                    value={this.state.question.questionText}
                                    onChange={this.onChangeText}
                                />
                            </Control>
                        </Field>
                    </Field>
                </ModalCardBody>
                <ModalCardFooter>
                    <Button className={'is-pulled-right'} onClick={this.onSaveQuestion}>
                        {translate(this.props.intl, 'Globals.Label.Save')}
                    </Button>
                    <Button onClick={this.onClose} buttonType="cancel" type="button">
                        {translate(this.props.intl, 'Globals.Label.Cancel')}
                    </Button>
                </ModalCardFooter>
            </ModalCard>
        );
    }
}

export const EditRiskProfileQuestion = injectIntl(EditRiskProfileQuestionClass);
