import { compact } from 'lodash';
import * as React from 'react';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconType } from 'routes/material-icon/material-icon-type';
import './Tabs.scss';

export interface IProps extends React.AllHTMLAttributes<HTMLLIElement> {
    active?: boolean;
    text: string;
    icon?: MaterialIconType;
}

export const Tab: React.FC<IProps> = (props) => {
    const { active, icon, ...otherProps } = props;
    const css = compact([
        props.active ? 'is-active' : null
    ]).join(' ');

    return (
        <li className={css} {...otherProps}>
            <a>
                {icon && (
                    <span className="icon">

                        < MaterialIcons type={icon} />
                    </span>
                )}
                <span>{props.text}</span>
            </a>
        </li>
    );
};
