import * as React from 'react';
import { Icon, IconType } from 'components/icon';
import { BulmaSize } from 'enums/BulmaSize';
import { Button, ConfirmDialog } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';
import { Help, Input } from 'components/form';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconColor, MaterialIconType } from 'routes/material-icon/material-icon-type';
import { newGuid } from 'utils/id-utils';
import { IOperatingPlatform } from 'models/operating-platform-model';
import { ILineOfBusinessCreate, ISelectedSiteMetaData } from 'models/site-metadata-model';
import iconHierachyWhite from 'assets/icon-hierarchy-lob-white.svg';
import iconHierachyBlack from 'assets/icon-hierachy-lob-black.svg';
import { BulmaColor } from 'enums/BulmaColor';
interface IProps {
    lineOfBusiness: ILineOfBusinessCreate[];
    selectOperatingPlatform: IOperatingPlatform;
    newOperatingPlatform: { id: string, label: string };
    saveLineOfBusinessSiteMetaData: (lineOfBusiness: ILineOfBusinessCreate[]) => void;
    selectLineOfBusiness: (lineOfbusinessId: string, lineOfBusinessLabel: string, count: number, isActive: boolean) => void;
    selectDivision: (divisionId: string, divisionLabel: string, count: number, isActive: boolean) => void;
    selectLineOfBusinessValue: ISelectedSiteMetaData;
}
export const HierarchyLineofBusiness: React.FC<IProps> = ({
    lineOfBusiness,
    selectOperatingPlatform,
    newOperatingPlatform,
    saveLineOfBusinessSiteMetaData,
    selectLineOfBusiness,
    selectLineOfBusinessValue,
    selectDivision
}) => {
    const translate = useTranslate();
    const operatingPlatform = selectOperatingPlatform ? selectOperatingPlatform : newOperatingPlatform
    const filteredLineOfBusinesses = operatingPlatform?.id && lineOfBusiness && lineOfBusiness.filter(
        x => x.operatingPlatformId === operatingPlatform.id
    );
    const [lobList, setLobList] = React.useState<ILineOfBusinessCreate[]>(filteredLineOfBusinesses)
    const [isShown, setIsShown] = React.useState<boolean>(false);
    const [isEditHierarchySetOpen, setisEditHierarchySetOpen] = React.useState<boolean>(false);
    const [isEditHierarchySet, setisEditHierarchySet] = React.useState<ILineOfBusinessCreate>(null);
    const [newLobText, setNewLobText] = React.useState<string>('');
    const [updatedLobText, setUpdatedLobText] = React.useState<string>('');
    const [updatedLineOfBusinesses, setUpdatedLineOfBusinesses] = React.useState<ILineOfBusinessCreate[]>([])
    const [showDialogue, setShowDialogue] = React.useState(false);
    const [selecteLOB, setLOB] = React.useState();
    const [siteCount, setSiteCount] = React.useState(false);
    const [isDuplicate, setIsDuplicate] = React.useState<boolean>(false);

    const handleOnClick = () => {
        if (isEditHierarchySetOpen) {
            setisEditHierarchySetOpen(false)
        }
        setIsShown(true);
    };

    React.useEffect(() => {
        let allLineOfBusinesses = [...lobList];
        const lineOfBusinessIndex = selectLineOfBusinessValue?.id && allLineOfBusinesses && allLineOfBusinesses.findIndex(x => x.key === selectLineOfBusinessValue?.id);
        if (lineOfBusinessIndex > -1) {
            const obj2 = Object.assign({}, allLineOfBusinesses[lineOfBusinessIndex]);
            obj2.count = selectLineOfBusinessValue.count;
            allLineOfBusinesses[lineOfBusinessIndex] = obj2;
            setLobList(allLineOfBusinesses);
        }
    }, [selectLineOfBusinessValue?.count]);

    const onClickLineOfBusiness = (x) => {
        if (x?.key) {
            selectLineOfBusiness(x.key, x.label, x.count, true);
            selectDivision('', '', 0, true);
        }
        setisEditHierarchySet(x)
    }

    const setLineOfBusiness = () => {
        if (isShown) {
            setIsShown(false)
        }
        setisEditHierarchySetOpen(true)
    }

    const onConfirmDelete = () => {
        selecteLOB && deleteLineOfBusiness(selecteLOB);
        setShowDialogue(false);
    }

    const onChangeEditText = (obj, updatedText) => {
        setUpdatedLobText(updatedText);
        const obj2 = Object.assign({}, obj);
        if (updatedText) {
            obj2.label = updatedText;
            obj2.value = updatedText;
        }
        setisEditHierarchySet(obj2)
    }

    const deleteItem = (deletedItem) => {
        setShowDialogue(true);
        if (deletedItem.siteCount > 0) {
            setSiteCount(true)
        }
        if (deletedItem.siteCount === 0) {
            setLOB(deletedItem);
            setSiteCount(false);
        }
    }

    const deleteLineOfBusiness = (deletedItem) => {
        let oldList = [];
        if (lobList?.length > 0) {
            oldList = [...lobList];
            oldList = oldList.filter(obj => obj.id !== deletedItem.id);
            setLobList(oldList);
        }
        const payloadDeletedItem = {
            operatingPlatformId: deletedItem.operatingPlatformId,
            value: deletedItem.value,
            id: deletedItem.id,
            key: deletedItem.id,
            label: deletedItem.value,
            operatingPlatform: deletedItem.operatingPlatform,
            isActive: false
        };
        if (deletedItem.id === selectLineOfBusinessValue?.id) {
            selectLineOfBusiness(selectLineOfBusinessValue?.id, selectLineOfBusinessValue?.label, selectLineOfBusinessValue?.count, false)
        }
        let deletedList = [];
        if (updatedLineOfBusinesses?.length > 0) {
            deletedList = [...updatedLineOfBusinesses];
            const obj = deletedList.findIndex(x => x.id === deletedItem.id);
            if (obj > -1) {
                deletedList[obj] = payloadDeletedItem;
            }
            else {
                deletedList.push(payloadDeletedItem);
            }
        }
        else {
            deletedList.push(payloadDeletedItem);
        }
        setUpdatedLineOfBusinesses(deletedList);
        saveLineOfBusinessSiteMetaData(deletedList);
    }

    const onChangeText = (title) => {
        setNewLobText(title)
    }

    const addLineOfBusiness = () => {
        let oldList = [];
        if (lobList?.length > 0) {
            oldList = [...lobList];
        }
        const operatingPlatformId = selectOperatingPlatform ? selectOperatingPlatform.id : newOperatingPlatform.id;
        const operatingPlatformValue = selectOperatingPlatform ? selectOperatingPlatform.value : newOperatingPlatform.label;
        const newLobId = newGuid();
        const payloadLobs = { operatingPlatform: operatingPlatformValue, operatingPlatformId: operatingPlatformId, key: newLobId, label: newLobText, value: newLobText, id: newLobId, count: 0, siteCount: 0 };
        const payloadLobs1 = { operatingPlatform: operatingPlatformValue, operatingPlatformId: operatingPlatformId, key: newLobId, label: newLobText, value: newLobText, id: newLobId, isActive: true };
        const found = oldList && oldList.length > 0 && oldList.some(el => el.label === newLobText);
        if (!found) {
            if (isDuplicate) {
                setIsDuplicate(false);
            }
            oldList.push(payloadLobs);
            const updatedlist1 = [...updatedLineOfBusinesses];
            updatedlist1.push(payloadLobs1)
            selectLineOfBusiness(newLobId, newLobText, 0, true);
            setUpdatedLineOfBusinesses(updatedlist1);
            saveLineOfBusinessSiteMetaData(updatedlist1);
            selectDivision('', '', 0, true);
            setisEditHierarchySet(payloadLobs);
            setLobList(oldList);
            setIsShown(false);
            setNewLobText('');
        }
        else {
            setIsDuplicate(true);
        }
    }
    const updateLineOfBusiness = (id) => {
        let oldList = [];
        const found = updatedLobText && lobList.length > 0 && lobList.some(el => el.label === updatedLobText);
        if (!found) {
            if (isDuplicate) {
                setIsDuplicate(false);
            }
            if (lobList?.length > 0) {
                oldList = [...lobList];
                const obj = oldList.findIndex(x => x.id === id);
                if (obj > -1) {
                    oldList[obj] = isEditHierarchySet;
                    setLobList(oldList);
                }
            }
            else {
                oldList.push(isEditHierarchySet);
                setLobList(oldList);
            }

            const payloadLobs = {
                operatingPlatformId: isEditHierarchySet.operatingPlatformId,
                operatingPlatform: isEditHierarchySet.operatingPlatform,
                value: isEditHierarchySet.value,
                label: isEditHierarchySet.label,
                id: isEditHierarchySet.id,
                key: isEditHierarchySet.key,
                count: isEditHierarchySet.count,
                isActive: true
            };

            const payloadLobs1 = {
                operatingPlatformId: isEditHierarchySet.operatingPlatformId,
                operatingPlatform: isEditHierarchySet.operatingPlatform,
                value: isEditHierarchySet.value,
                label: isEditHierarchySet.label,
                id: isEditHierarchySet.id,
                key: isEditHierarchySet.key,
                isActive: true
            };
            const updatedList2 = [...updatedLineOfBusinesses];
            const obj1 = updatedList2.findIndex(x => x.id === id);
            if (obj1 > -1) {
                updatedList2[obj1] = payloadLobs1;
                setUpdatedLineOfBusinesses(updatedList2);
                saveLineOfBusinessSiteMetaData(updatedList2);
            }
            else {
                updatedList2.push(payloadLobs)
                setUpdatedLineOfBusinesses(updatedList2);
                saveLineOfBusinessSiteMetaData(updatedList2);
            }
            selectLineOfBusiness(isEditHierarchySet.key, isEditHierarchySet.label, isEditHierarchySet.count, isEditHierarchySet.isActive);
            setUpdatedLobText('');
            setisEditHierarchySetOpen(false)
        } else {
            setIsDuplicate(true);
        }
    }
    const OnCancelIsEditHierarhySet = () => {
        setisEditHierarchySetOpen(false);
        if (isDuplicate) {
            setIsDuplicate(false);
        }
    }

    const getBody = () => {
        return (
            <>
                <br />
                <p>{(siteCount) ? translate('OperatingPlatform.Modal.LineOfbusiness.DeleteMessageWithSite') : translate('OperatingPlatform.Model.DeleteMessageWithoutSite')}</p>
                {(!siteCount) ? <p><strong>{translate('OperatingPlatform.Hierarchy.Modal.DeleteFooterMessage')}</strong></p> : <p><strong>{translate('OperatingPlatform.Modal.withSitesDeleteFooterMessage')}</strong></p>}
            </>
        )
    };

    const OnCancelHierarhySet = () => {
        setIsShown(false);
        if (isDuplicate) {
            setIsDuplicate(false);
        }
    }
    return (
        <>
            <div className='columns'>
                <div className='column is-8'>
                    <span className='hierachy-title'>{translate('Globals.Label.LineOfBusiness')} {`(${lobList ? lobList.length : 0})`}</span>
                </div>
                <div className='column is-2'>
                    <Button
                        id="btnLobSaveAction"
                        type="submit"
                        className="add-hierachy-item"
                        onClick={handleOnClick}
                    >
                        <Icon type={IconType.PlusCircle} size={BulmaSize.Normal} />
                        <span className='hierarchy-add-button'>{translate('OperatingPlatform.ButtonLabel.Add')}</span>
                    </Button>
                </div>
            </div>
            <ConfirmDialog
                title={translate('OperatingPlatform.Hierarchy.Modal.DeleteTitle')}
                message={getBody()}
                isVisible={showDialogue}
                isLoading={false}
                showConfirmButton={!siteCount}
                onConfirm={() => onConfirmDelete()}
                onClose={() => setShowDialogue(false)}
                buttonConfirmText={translate('OperatingPlatform.Model.DeleteButton')}
                buttonCancelText={translate('Globals.Label.Cancel')}

            />
            {lobList?.length > 0 &&
                lobList.map((x) => (
                    <div className={x.id === selectLineOfBusinessValue?.id ? "columns selectedHierarchy active-hierachy-item" : "columns selectedHierarchy"}>
                        <div
                            id={
                                'editLineOfBusiness' + x.key
                            }
                            className="column is-9 is-clickable"
                            onClick={() =>
                                onClickLineOfBusiness(
                                    x
                                )
                            }
                        >
                            <span className='icon'>
                                <img src={x.id === selectLineOfBusinessValue?.id ? iconHierachyWhite : iconHierachyBlack} alt={"hierachy-item"} />
                            </span>
                            <span className='icon-text'>{x.value} {x?.count > 0 ? `(${x.count})` : " "}</span>
                        </div>
                        <div className="column is-3">
                            <a
                                id={
                                    'editLineOfBusiness' + x.key
                                }

                                onClick={() =>
                                    setLineOfBusiness(

                                    )
                                }
                            >

                                < MaterialIcons type={MaterialIconType.Edit} color={x.id === selectLineOfBusinessValue?.id ? MaterialIconColor.white : MaterialIconColor.green} />
                            </a>
                            <a
                                id={
                                    'deleteLineOfBusiness' + x.key
                                }
                                onClick={() =>
                                    deleteItem(
                                        x
                                    )
                                }
                            >
                                < MaterialIcons type={MaterialIconType.Delete} color={x.id === selectLineOfBusinessValue?.id ? MaterialIconColor.white : MaterialIconColor.green} />
                            </a>
                        </div>
                    </div>
                ))}
            {
                isShown &&
                <div className='edit-hirerachy-section'>
                    <div className="columns">
                        <div className="column is-1">
                        </div>
                        <div className="column is-10">
                            <Help
                                bulmaColor={BulmaColor.Danger}
                                isShown={isDuplicate}
                            >
                                {translate('OperatingPlatform.Hierarchy.Duplicate.LineOfBusiness')}
                            </Help>
                            <Input
                                id={
                                    'newLineOfBusiness'
                                }
                                placeholder={translate('OperatingPlatform.Hierarchy.LineOfBusiness')}
                                onChange={(e: any) =>
                                    onChangeText(
                                        e.target.value
                                    )
                                }
                                value={newLobText}
                                className='hierarchy-new-item-input'
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className='column is-2'></div>
                        <div className="column is-10 is-pull-right hierarchy-buttons">
                            <Button
                                id="btnCancelAction"
                                onClick={() =>
                                    OnCancelHierarhySet(
                                    )
                                }
                                className='cancel-hirerachy-button'
                            >
                                {translate('OperatingPlatform.Button.Cancel')}
                            </Button>
                            <Button
                                id={
                                    'newLineOfBusinessAdd'
                                }
                                onClick={() =>
                                    addLineOfBusiness(

                                    )
                                }
                                className='save-hirerachy-button'
                            >
                                {translate('OperatingPlatform.Button.Save')}
                            </Button>
                        </div>
                    </div>
                </div>
            }
            {
                isEditHierarchySetOpen && isEditHierarchySet &&
                <div className='edit-hirerachy-section'>
                    <div className="columns">
                        <div className="column is-1">
                        </div>
                        <div className="column is-10">
                            <Help
                                bulmaColor={BulmaColor.Danger}
                                isShown={isDuplicate}
                            >
                                {translate('OperatingPlatform.Hierarchy.Duplicate.LineOfBusiness')}
                            </Help>
                            <Input
                                id={
                                    'editLineOfBusiness' +
                                    isEditHierarchySet.key
                                }
                                placeholder={isEditHierarchySet.value}
                                onChange={(e: any) =>
                                    onChangeEditText(
                                        isEditHierarchySet,
                                        e.target.value
                                    )
                                }
                                value={
                                    updatedLobText ? updatedLobText : isEditHierarchySet.value
                                }
                                className='hierarchy-new-item-input'
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className='column is-2'></div>
                        <div className="column is-10 is-pull-right hierarchy-buttons">
                            <Button
                                id={
                                    'cancelHierarhySet' +
                                    isEditHierarchySet.key
                                }
                                onClick={() =>
                                    OnCancelIsEditHierarhySet(
                                    )
                                }
                                className='cancel-hirerachy-button'
                            >
                                {translate('OperatingPlatform.Button.Cancel')}
                            </Button>
                            <Button
                                id={
                                    'newLineOfBusinessAdd' +
                                    isEditHierarchySet.key
                                }
                                onClick={() =>
                                    updateLineOfBusiness(
                                        isEditHierarchySet.id
                                    )
                                }
                                className='save-hirerachy-button'
                            >
                                {translate('OperatingPlatform.Button.Save')}
                            </Button>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};
