import { Dictionary, map } from 'lodash';
import * as React from 'react';
import { Percentage } from 'components/percentage';
import { Timeline, TimelineHeader, TimelineItem } from 'components/timeline';
import { ICermSiteOverviewGroup, ICermSiteOverviewSite } from 'models/cerm-site-overview';
import './cerm-site-overview-list.scss';
// tslint:disable-next-line: no-duplicate-imports
import _ from 'lodash';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';

export interface IProps {
    group: ICermSiteOverviewGroup;
    expandedItems: string[];
    onToggleExpanded: (id: string) => void;
    getServiceScoreLabel: (key: string) => string;
    getColumnCss: (name: string, ...others: string[]) => string;
    optionValue: string;
    intl: any;
}

class CermSiteOverviewListGroupClass extends React.PureComponent<IProps> {
    constructor(props: IProps) {
        super(props);
    }

    public render() {
        const { group, expandedItems } = this.props;
        const isExpanded = expandedItems.includes(group.id);
        const intl = this.props.intl;

        return (
            <div className="CermSiteOverviewList__group">
                <Timeline expanded={isExpanded} key={group.title}>
                    <TimelineHeader
                        expanded={isExpanded}
                        onToggleExpanded={this.onToggleExpanded}
                    >
                        <div className="columns">
                            <div className={this.getCss('title')}>
                                <span className="grid__label">{translate(intl, 'RiskRegisterPage.RiskRegisterTable.Title.Title')}</span>
                                {group.title}
                            </div>
                            <div className={this.getCss('completion', 'column--numeric')}>
                                <span className="grid__label">{translate(intl, 'CermSiteOverview.List.Completion')}</span>
                                <Percentage value={group.completion} maxDecimals={0} />
                            </div>
                            {this.getTotalScores(group.serviceScores, group.pillarScores)}
                            <div className={this.getCss('averageScore', 'column--numeric')}>
                                <span className="grid__label">{translate(intl, 'CermSiteOverview.List.Group.Overall')}</span>
                                <Percentage value={this.groupCompleteScore(group)} maxDecimals={0} />
                            </div>
                        </div>
                    </TimelineHeader>

                    {group.sites.map((site, index) => (
                        <TimelineItem key={index}>
                            <div className="site">
                                <h3 className="site__value site__value--title">
                                    <span className="grid__label">{translate(intl, 'RiskRegisterPage.RiskRegisterTable.Title.Title')}</span>
                                    {site.name}
                                </h3>
                                <div className="site__value site__value--numeric site__value--completion">
                                    <span className="grid__label">{translate(intl, 'CermSiteOverview.List.Group.FullyComplete')}</span>
                                    <Percentage value={site.completion} maxDecimals={0} />
                                </div>

                                {this.getScores(site.serviceScores, site.pillarScores)}

                                <div className="site__value site__value--numeric site__value--averageScore">
                                    <span className="grid__label">{translate(intl, 'CermSiteOverview.List.Group.Overall')}</span>
                                    <Percentage value={this.siteCompleteScore(site)} maxDecimals={0} />
                                </div>
                            </div>
                        </TimelineItem>
                    ))}
                </Timeline>
            </div>
        );
    }
    private groupCompleteScore = (group: ICermSiteOverviewGroup) => {
        const results: number[] = [];
        _.forEach(group.pillarScores, (pillar) => {
            if (pillar) {
                results.push(pillar);
            }
        });
        if (results && results.length > 0) {
            return _.sum(results);
        }
        return 0;
    }
    private siteCompleteScore = (site: ICermSiteOverviewSite) => {
        const results: number[] = [];
        _.forEach(site.pillarScores, (pillar) => {
            if (pillar) {
                results.push(pillar);
            }
        });
        if (results && results.length > 0) {
            return _.sum(results);
        }
        return 0;
    }
    private getTotalScores(serviceScores: Dictionary<number>, pillarScores: Dictionary<number>) {
        if (this.props.optionValue === 'services') {
            return map(serviceScores, (value, key) => (
                <div className={this.getCss(key, 'column--numeric')}>
                    <span className="grid__label">{this.props.getServiceScoreLabel(key)}</span>
                    <Percentage value={value} maxDecimals={0} />
                </div>
            ));
        } else {
            return map(pillarScores, (value, key) => (
                <div className={this.getCss(key, 'column--numeric')}>
                    <span className="grid__label">{this.props.getServiceScoreLabel(key)}</span>
                    <Percentage value={value} maxDecimals={0} />
                </div>
            ));
        }
    }
    private getScores(serviceScores: Dictionary<number>, pillarScores: Dictionary<number>) {
        if (this.props.optionValue === 'services') {
            return map(serviceScores, (value, key) => (
                <div className={`site__value site__value--numeric site__value--${key}`}>
                    <span className="grid__label">
                        {this.props.getServiceScoreLabel(key)}
                    </span>
                    <Percentage value={value} maxDecimals={0} />
                </div>
            ));
        } else {
            return map(pillarScores, (value, key) => (
                <div className={`site__value site__value--numeric site__value--${key}`}>
                    <span className="grid__label">
                        {this.props.getServiceScoreLabel(key)}
                    </span>
                    <Percentage value={value} maxDecimals={0} />
                </div>
            ));
        }
    }
    private getCss = (name: string, ...others: string[]) =>
        this.props.getColumnCss(name, 'grid__header', ...others)
    private onToggleExpanded = () => this.props.onToggleExpanded(this.props.group.id);
}

export const CermSiteOverviewListGroup = injectIntl(CermSiteOverviewListGroupClass);
