import { Dispatch, useEffect, useState } from 'react';

export default function useLocalStorage(
    key: string,
    initialValue: string = ''
): [string, Dispatch<string>] {
    const getItemFromLocalStorage = () => {
        return window.localStorage ? window.localStorage.getItem(key) : null;
    };

    const setItemInLocalStorage = (newValue: string) => {
        if (window.localStorage) {
            window.localStorage.setItem(key, newValue);
        }
    };

    const [value, setValue] = useState(() => getItemFromLocalStorage() || initialValue);

    const setItem = (newValue: string) => {
        setValue(newValue);
        setItemInLocalStorage(newValue);
    };

    useEffect(() => {
        const newValue = getItemFromLocalStorage();
        if (value !== newValue) {
            setValue(newValue || initialValue);
        }
    });

    /* const handleStorage = useCallback(
        (event: StorageEvent) => {
            if (event.key === key && event.newValue !== value) {
                setValue(event.newValue || initialValue);
            }
        },
        [value]
    ); */
    const handleStorage = (event: StorageEvent) => {
        if (event.key === key && event.newValue !== value) {
            setValue(event.newValue || initialValue);
        }
    };

    useEffect(() => {
        window.addEventListener('storage', handleStorage);
        return () => window.removeEventListener('storage', handleStorage);
    }, [handleStorage]);

    return [value, setItem];
}
