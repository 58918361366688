import { AnyAction } from 'redux';

import { SiteRelationshipsActions } from 'actions/site-relationships-actions';
import { IBuilding } from 'models/building';
import { ISiteProfile } from 'models/site-profile';

export interface IDeleteBuildingConfirmationState {
    building: IBuilding;
    site: ISiteProfile;
}

export const INITIAL_STATE: IDeleteBuildingConfirmationState = {
    building: null,
    site: null
};

export const reducer = (
    state: IDeleteBuildingConfirmationState = INITIAL_STATE,
    action: AnyAction
) => {
    switch (action.type) {
        case SiteRelationshipsActions.UNLINK_BUILDING:
            return {
                ...action.payload
            };

        case SiteRelationshipsActions.UNLINK_BUILDING_CONFIRMED:
        case SiteRelationshipsActions.UNLINK_BUILDING_CANCELLED:
            return INITIAL_STATE;

        default:
            return state;
    }
};
