import * as React from 'react';
import { Redirect } from 'react-router';
/* import { checkPermission } from 'utils/permission-utils'; */
import { IKeyValue } from 'models';
import { Loader } from 'components/loader';

interface IStateProps {
    permissions: Array<IKeyValue<string>>;
    sites: Array<IKeyValue<string>>;
    isClient: boolean;
}

export const HomePage: React.FC<IStateProps> = ({ permissions, sites, isClient }) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    React.useEffect(() => {
        setIsLoading(!permissions || permissions.length < 1);
    }, [permissions, sites, isLoading]);

    const getAppropriateHomepage = () => {
        if (isLoading) {
            return <Loader loading={true} />;
        }

        if (isClient) {
            const hasMultipleSites = sites && sites.length > 1;
            return hasMultipleSites ? <Redirect to="/ClientSitesOverview" /> : <Redirect to="/ClientDashboard" />;
        }

        /*  if (checkPermission('ControlCentre', permissions)) {
             return <Redirect to="/ControlCentre" />;
         } */

        return <Redirect to="/Dashboard" />;
    };

    return getAppropriateHomepage();
};
