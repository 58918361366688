import { createSelector } from 'reselect';
import { ICreateQuestionSetRootState } from '../../../reducers/create-question-set';
import { isCreatingQuestionSet } from './common-question-set-selectors';
import { IRootState } from 'routes/store';

export const getInitialValues = (state: IRootState) => state.editQuestionSet.questionSet;

const getIsInitialising = (state: ICreateQuestionSetRootState) => state.editQuestionSet.isInitialising;
const getIsLoading = (state: ICreateQuestionSetRootState) => state.editQuestionSet.isSubmitting;

const getPageTitle = createSelector(
    isCreatingQuestionSet,
    (isCreating: boolean) =>
        isCreating ? 'Create Question Set' : 'Edit Question Set'
);
const getProgressSubtitle = createSelector(
    isCreatingQuestionSet,
    (isCreating: boolean) =>
        isCreating ? 'Question Set Creation Progress' : 'Question Set Edit Progress'
);

export default createSelector(
    getInitialValues,
    getIsInitialising,
    getPageTitle,
    getProgressSubtitle,
    getIsLoading,
    (initialValues, isInitialising, pageTitle, progressSubtitle, isLoading) => ({
        initialValues,
        isInitialising,
        isLoading,
        pageTitle,
        progressSubtitle
    })
);
