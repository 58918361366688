import { Action } from 'redux';
import { IBenchmarkData } from 'models/pue-benchmarks';
import { IBuilding } from '../models/building';

export enum BuildingActions {
    LOAD_BUILDINGS = '@@buildings/LOAD_BUILDINGS',
    LOAD_BUILDINGS_FULFILLED = '@@buildings/LOAD_BUILDINGS_FULFILLED',
    LOAD_BUILDINGS_REJECTED = '@@buildings/LOAD_BUILDINGS_REJECTED',
    LOAD_BUILDINGS_CANCELLED = '@@buildings/LOAD_BUILDINGS_CANCELLED',

    TOGGLE_ITEM_EXPANDED = '@@buildings/TOGGLE_ITEM_EXPANDED',
    CHANGE_SORT_ORDER_VALUE = '@@buildings/CHANGE_SORT_ORDER_VALUE',
    CHANGE_FILTER_VALUE = '@@buildings/CHANGE_FILTER_VALUE',
    SHOW_ACTIONS = '@@buildings/SHOW_ACTIONS',
    HIDE_ACTIONS = '@@buildings/HIDE_ACTIONS',
    LOAD_PUE_BENCHMARKS = '@@buildings/LOAD_PUE_BENCHMARKS',
    LOAD_PUE_BENCHMARKS_FULFILLED = '@@buildings/LOAD_PUE_BENCHMARKS_FULFILLED',
    LOAD_PUE_BENCHMARKS_REJECTED = '@@buildings/LOAD_PUE_BENCHMARKS_REJECTED',
    LOAD_PUE_BENCHMARKS_CANCELLED = '@@buildings/LOAD_PUE_BENCHMARKS_CANCELLED',

    CHANGE_PUE_CHART_MONTH = '@@buildings/CHANGE_PUE_CHART_MONTH',
}

export interface ILoadBuildingsAction extends Action {
    type: BuildingActions.LOAD_BUILDINGS;
}

export interface ILoadBuildingsRejectedAction extends Action {
    type: BuildingActions.LOAD_BUILDINGS_REJECTED;
    error: true;
    payload: any;
}

export interface ILoadBuildingsCancelledAction extends Action {
    type: BuildingActions.LOAD_BUILDINGS_CANCELLED;
}

export interface ILoadBuildingsFulfilledAction extends Action {
    type: BuildingActions.LOAD_BUILDINGS_FULFILLED;
    payload: {
        buildings: IBuilding[];
    };
}

export interface ISetSortOrderAction extends Action {
    type: BuildingActions.CHANGE_SORT_ORDER_VALUE;
    payload: {
        sortColumn?: string;
        sortAscending?: boolean;
    };
}

export interface ISetFilterValueAction extends Action {
    type: BuildingActions.CHANGE_FILTER_VALUE;
    payload: {
        field: string;
        value: string;
    };
}

export interface IToggleItemExpanded extends Action {
    type: BuildingActions.TOGGLE_ITEM_EXPANDED;
    payload: {
        id: string;
    };
}

export interface IHideActions extends Action {
    type: BuildingActions.HIDE_ACTIONS;
    payload: {
        id: string;
    };
}

export interface IShowActions extends Action {
    type: BuildingActions.SHOW_ACTIONS;
    payload: {
        id: string;
    };
}

export interface ILoadPueBenchmarksActions extends Action {
    type: BuildingActions.LOAD_PUE_BENCHMARKS;
    payload: {
        siteId: string;
        buildingId: string;
    };
}

export interface ILoadPueBenchmarksFulfilledActions extends Action {
    type: BuildingActions.LOAD_PUE_BENCHMARKS_FULFILLED;
    payload: {
        model: IBenchmarkData;
    };
}

export interface ILoadPueBenchmarksCancelledActions extends Action {
    type: BuildingActions.LOAD_PUE_BENCHMARKS_CANCELLED;
}

export interface ILoadPueBenchmarksRejectedActions extends Action {
    type: BuildingActions.LOAD_PUE_BENCHMARKS_REJECTED;
}

export interface IChangePueChartMonthActions extends Action {
    type: BuildingActions.CHANGE_PUE_CHART_MONTH;
    payload: {
        month: Date;
    };
}

export type BuildingActionTypes =
| ILoadBuildingsAction
| ILoadBuildingsCancelledAction
| ILoadBuildingsFulfilledAction
| ILoadBuildingsRejectedAction

| ISetFilterValueAction
| ISetSortOrderAction
| IToggleItemExpanded
| IHideActions
| IShowActions
| ILoadPueBenchmarksActions
| ILoadPueBenchmarksFulfilledActions
| ILoadPueBenchmarksRejectedActions
| ILoadPueBenchmarksCancelledActions
| IChangePueChartMonthActions;
