import { Action } from 'redux';

export enum notificationStateActions {
    SEND_NOTIFICATION = '@@notifications/SEND_NOTIFICATION',
    SEND_NOTIFICATION_COMPLETE = '@@notifications/SEND_NOTIFICATION_COMPLETE',
    SEND_NOTIFICATION_REJECTED = '@@notifications/SEND_NOTIFICATION_REJECTED',
}

export interface ISendNotification extends Action {
    type: notificationStateActions.SEND_NOTIFICATION;
}

export const onSendNotification = (): ISendNotification => ({
    type: notificationStateActions.SEND_NOTIFICATION,
});

export interface ISendNotificationComplete extends Action {
    type: notificationStateActions.SEND_NOTIFICATION_COMPLETE;
}

export const onSenNotificationComplete = (): ISendNotificationComplete => ({
    type: notificationStateActions.SEND_NOTIFICATION_COMPLETE,
});

export interface ISendNotificationRejected extends Action {
    type: notificationStateActions.SEND_NOTIFICATION_REJECTED;
}

export const onSendNotificationRejected = (): ISendNotificationRejected => ({
    type: notificationStateActions.SEND_NOTIFICATION_REJECTED,
});

export type NotificationActionTypes =
    | ISendNotificationRejected
    | ISendNotification
    | ISendNotificationComplete;
