import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { GlobalMockDrillsPage } from './global-mock-drills-page';
import {
    loadGlobalMockDrills,
    exportGlobalMockDrills,
} from 'actions/actions-v2/global-mock-drills-actions';
import { withRouter } from 'react-router';
import { checkPermissions } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';

const getGlobalMockDrills = (state: IRootState) => state.globalMockDrillsState.globalMockDrills;
const getIsLoading = (state: IRootState) => state.globalMockDrillsState.isLoading;
const getIsExporting = (state: IRootState) => state.globalMockDrillsState.isExporting;
const getShowHelpModule = (state: IRootState) => state.app.showHelpModule;
const getGlobalMockDrillsPermission = (state: IRootState) =>
    checkPermissions(
        [Privilege.GlobalMockDrills],
        state.app.permissions
    );

const mapStateToProps = createSelector(
    getGlobalMockDrills,
    getIsLoading,
    getIsExporting,
    getShowHelpModule,
    getGlobalMockDrillsPermission,
    getGlobalMockDrillsPermission,
    (
        globalMockDrills,
        isLoading,
        isExporting,
        showHelpModule,
        canCreateGlobalMockDrill,
        canExportGlobalMockDrill
    ) => ({
        globalMockDrills,
        isLoading,
        isExporting,
        showHelpModule,
        canCreateGlobalMockDrill,
        canExportGlobalMockDrill,
    })
);

const mapDispatchToProps = {
    loadGlobalMockDrills,
    exportGlobalMockDrills,
};

export const GlobalMockDrillsContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(GlobalMockDrillsPage)
);
