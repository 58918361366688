import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { QuestionSetActions } from 'actions/actions-v2/question-set-actions';
import { IQuestionSet } from 'models/question-set';

const controllerName = 'questionSet';
const apiPath = `/api/${controllerName}`;
const redirectUrl = '/QuestionSets';
const downloadControllerName = 'Download';
const apiPathDownload = `/api/${downloadControllerName}`;

const getListActions = {
    action: QuestionSetActions.LOAD_LIST,
    actionFulfilled: QuestionSetActions.LOAD_LIST_FULFILLED,
    actionRejected: QuestionSetActions.LOAD_LIST_REJECTED
} as IActionType;

const getActions = {
    action: QuestionSetActions.LOAD,
    actionFulfilled: QuestionSetActions.LOAD_FULFILLED,
    actionRejected: QuestionSetActions.LOAD_REJECTED
} as IActionType;

const createActions = {
    action: QuestionSetActions.CREATE,
    actionFulfilled: QuestionSetActions.CREATE_FULFILLED,
    actionRejected: QuestionSetActions.CREATE_REJECTED
} as IActionType;

const updateActions = {
    action: QuestionSetActions.UPDATE,
    actionFulfilled: QuestionSetActions.UPDATE_FULFILLED,
    actionRejected: QuestionSetActions.UPDATE_REJECTED
} as IActionType;

const deleteActions = {
    action: QuestionSetActions.DELETE,
    actionFulfilled: QuestionSetActions.DELETE_FULFILLED,
    actionRejected: QuestionSetActions.DELETE_REJECTED
} as IActionType;

const downloadActions = {
    action: QuestionSetActions.DOWNLOAD,
    actionFulfilled: QuestionSetActions.DOWNLOAD_FULFILLED,
    actionRejected: QuestionSetActions.DOWNLOAD_REJECTED
} as IActionType;

const loadQuestionSetsListEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getListActions).get<IQuestionSet[]>(`${apiPath}`);

const loadQuestionSetEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getActions).getById<IQuestionSet>(apiPath);

const createQuestionSetEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createActions, redirectUrl).post<IQuestionSet>(apiPath);

const updateQuestionSetEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateActions, redirectUrl).put<IQuestionSet>(apiPath);

const deleteQuestionSetEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, deleteActions, redirectUrl).delete<string>(apiPath);

const downloadQuestionSetEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, downloadActions).exportByQueryModel(
        `${apiPathDownload}/RiskQuestionSets`
    );

export const questionSetEpicsV2 = combineEpics(
    loadQuestionSetsListEpic,
    loadQuestionSetEpic,
    createQuestionSetEpic,
    updateQuestionSetEpic,
    deleteQuestionSetEpic,
    downloadQuestionSetEpic
);
