import { combineEpics, Epic, ofType } from 'redux-observable';
import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax';
import { catchError, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import {
    ISubmitModelAction,
    QuestionnaireActions,
    QuestionnaireActionTypes,
} from './../actions/questionnaire-actions';
import { getErrorActions$ } from './epic-helpers';
import { getApiHeaders } from './shared-header';

import appConfig from 'helpers/config-helper';
import {
    BuildingActions,
    BuildingActionTypes,
    ILoadPueBenchmarksActions,
} from 'actions/building-actions';

const config = appConfig();

const apiUrl = config.REACT_APP_BASE_API;

const submitModel: Epic<QuestionnaireActionTypes, QuestionnaireActionTypes> = (action$) =>
    action$.pipe(
        ofType(QuestionnaireActions.SUBMIT_MODEL),
        switchMap((action: ISubmitModelAction) =>
            ajax
                .post(
                    `${apiUrl}/api/pueProjection/${action.payload.siteId}`,
                    action.payload.building,
                    getApiHeaders()
                )
                .pipe(
                    map<AjaxResponse, QuestionnaireActionTypes>((response) => ({
                        type: QuestionnaireActions.SUBMIT_MODEL_FULFILLED,
                        payload: response.response,
                    })),
                    catchError<any, any>((error: AjaxError) =>
                        getErrorActions$('Questionnaire')(
                            QuestionnaireActions.SUBMIT_MODEL_REJECTED,
                            error,
                            {
                                errorMessage: 'Unable to submit model',
                            }
                        )
                    )
                )
        )
    );

export const loadPueBenchmarksEpic: Epic<BuildingActionTypes, BuildingActionTypes> = (action$) =>
    action$.pipe(
        ofType<ILoadPueBenchmarksActions>(BuildingActions.LOAD_PUE_BENCHMARKS),
        map(
            (action) =>
                `${apiUrl}/api/pueProjection/${action.payload.siteId}/${action.payload.buildingId}`
        ),
        mergeMap(
            // TODO: Cancel previous load first
            (url) =>
                ajax.getJSON(url, getApiHeaders()).pipe(
                    map<any, BuildingActionTypes>((response) => ({
                        type: BuildingActions.LOAD_PUE_BENCHMARKS_FULFILLED,
                        payload: {
                            model: response,
                        },
                    })),
                    takeUntil(action$.pipe(ofType(BuildingActions.LOAD_BUILDINGS_CANCELLED))),
                    catchError<any, BuildingActionTypes>((error: AjaxError) =>
                        getErrorActions$('Buildings')(
                            BuildingActions.LOAD_PUE_BENCHMARKS_REJECTED,
                            error,
                            {
                                errorMessage: 'Unable to load Buildings',
                            }
                        )
                    )
                )
        )
    );

export const questionnaireEpics = combineEpics(submitModel, loadPueBenchmarksEpic);
