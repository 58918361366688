import { IAppAction } from '../app-action';
import { IOpportunity } from 'models/opportunity-question-set';

export enum OpportunityQuestionsActions {
    LOAD_OPPORTUNITY_QUESTIONS_LIST = '@@OpportunityQuestions/LOAD_OPPORTUNITY_QUESTIONS_LIST',
    LOAD_OPPORTUNITY_QUESTIONS_LIST_FULFILLED = '@@OpportunityQuestions/LOAD_OPPORTUNITY_QUESTIONS_LIST_FULFILLED',
    LOAD_OPPORTUNITY_QUESTIONS_LIST_REJECTED = '@@OpportunityQuestions/LOAD_OPPORTUNITY_QUESTIONS_LIST_REJECTED',

    LOAD_OPPORTUNITY_QUESTIONS = '@@OpportunityQuestions/LOAD_OPPORTUNITY_QUESTIONS',
    LOAD_OPPORTUNITY_QUESTIONS_FULFILLED = '@@OpportunityQuestions/LOAD_OPPORTUNITY_QUESTIONS_FULFILLED',
    LOAD_OPPORTUNITY_QUESTIONS_REJECTED = '@@OpportunityQuestions/LOAD_OPPORTUNITY_QUESTIONS_REJECTED',

    CREATE_OPPORTUNITY_QUESTIONS = '@@OpportunityQuestions/CREATE_OPPORTUNITY_QUESTIONS',
    CREATE_OPPORTUNITY_QUESTIONS_FULFILLED = '@@OpportunityQuestions/CREATE_OPPORTUNITY_QUESTIONS_FULFILLED',
    CREATE_OPPORTUNITY_QUESTIONS_REJECTED = '@@OpportunityQuestions/CREATE_OPPORTUNITY_QUESTIONS_REJECTED',

    UPDATE_OPPORTUNITY_QUESTIONS = '@@OpportunityQuestions/UPDATE_OPPORTUNITY_QUESTIONS',
    UPDATE_OPPORTUNITY_QUESTIONS_FULFILLED = '@@OpportunityQuestions/UPDATE_OPPORTUNITY_QUESTIONS_FULFILLED',
    UPDATE_OPPORTUNITY_QUESTIONS_REJECTED = '@@OpportunityQuestions/UPDATE_OPPORTUNITY_QUESTIONS_REJECTED',

    DELETE_OPPORTUNITY_QUESTIONS = '@@OpportunityQuestions/DELETE_OPPORTUNITY_QUESTIONS',
    DELETE_OPPORTUNITY_QUESTIONS_FULFILLED = '@@OpportunityQuestions/DELETE_OPPORTUNITY_QUESTIONS_FULFILLED',
    DELETE_OPPORTUNITY_QUESTIONS_REJECTED = '@@OpportunityQuestions/DELETE_OPPORTUNITY_QUESTIONS_REJECTED',

    CLEAN_UP_OPPORTUNITY = '@@OpportunityQuestions/CLEAN_UP_OPPORTUNITY',
}

export const loadOpportunityQuestionsList = (query?: any): IAppAction => ({
    type: OpportunityQuestionsActions.LOAD_OPPORTUNITY_QUESTIONS_LIST,
    payload: query,
});

export const loadOpportunityQuestions = (id: string): IAppAction => ({
    type: OpportunityQuestionsActions.LOAD_OPPORTUNITY_QUESTIONS,
    payload: id,
});

export const createOpportunityQuestions = (model: IOpportunity): IAppAction => ({
    type: OpportunityQuestionsActions.CREATE_OPPORTUNITY_QUESTIONS,
    payload: model,
});

export const updateOpportunityQuestions = (model: IOpportunity): IAppAction => ({
    type: OpportunityQuestionsActions.UPDATE_OPPORTUNITY_QUESTIONS,
    payload: model,
});

export const deleteOpportunity = (id: string): IAppAction => ({
    type: OpportunityQuestionsActions.DELETE_OPPORTUNITY_QUESTIONS,
    payload: id,
});

export const cleanUpOpportunity = (): IAppAction => ({
    type: OpportunityQuestionsActions.CLEAN_UP_OPPORTUNITY,
});
