
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { getCurrentSelectedSiteId, getCombinedSiteFeatures } from 'sharedSelectors';
import { ClientSitesOverviewPage } from './client-sites-overview-page';
import { onSiteChange } from 'actions/app-actions';
import { loadRiskSummaries } from 'actions/actions-v2/risk-action-v2';
import { IRootState } from 'routes/store';

const getIsLoading = (state: IRootState) => state.riskRegisterState.isLoading;
const getRiskSummaries = (state: IRootState) => state.riskRegisterState.clientRisksSummaries;
const getSiteFeatures = (state: IRootState) => state.app.lookupSitesFeatures;

const mapStateToProps = createSelector(
    getCurrentSelectedSiteId,
    getRiskSummaries,
    getIsLoading,
    getCombinedSiteFeatures,
    getSiteFeatures,
    (siteId, riskSummaries, isLoading, features, siteFeatures) => ({
        siteId, riskSummaries, isLoading, features, siteFeatures,
        showClientRisks: features.includes('Client Risks'),
        showCriticalSpares: features.includes('Client facing critical spares')
    })
);

const mapDispatchToProps = {
    onSiteChange,
    loadRiskSummaries
};

export const ClientSitesOverviewContainer = connect(mapStateToProps, mapDispatchToProps)(ClientSitesOverviewPage);
