import React from 'react';
import useTranslate from 'translations/translation-utils';
import { Button } from 'components/v2/button/Button';
/* import logo from 'assets/Quantum-cbre.png'; */
import logo from 'assets/CBREQuantum-NewLogo_Green.svg'
import { history } from 'routes/App';
import { isCbreUser } from '../../helpers/user-helper';
import appConfig from 'helpers/config-helper';
const config = appConfig();
const AccessDeniedpage: React.FC = () => {
    const translate = useTranslate();
    const onSaveButtonClick = () => {
        // history.push('/Account/GainAccess');
        window.open(config.REACT_APP_SERVICE_NOW_ACCESS, "_blank")
    };
    const onEcermClick = () => {
        history.push('/Account/Ecerm');
    };
    return (
        <div className="access-denied">
            <div className="columns">
                <div className="column is-full">
                    <header>
                        <div className="logo">

                            <img src={logo} alt="CBRE Quantum" className="is-hidden-mobile" />
                            <img src={logo} alt="CBRE Quantum" className="is-hidden-tablet" />
                        </div>
                    </header>
                </div>
            </div>
            <div className="columns is-multiline is-centered">
                {!isCbreUser() && (
                    <div className="column is-full">
                        <ul className="details">
                            <div id="main_block">
                                <div id="msg_long_reason" className="non-cbre">
                                    <div className="title" id="msg_title">
                                        <b>{translate('AccessDenied.Label.NonCbre')}</b>{' '}
                                    </div>
                                    {/*  <li>
                                        <b>{translate('AccessDenied.Label.GainAccessTitle')}</b>
                                    </li> */}
                                </div>
                            </div>
                        </ul>
                        {/*   <div className="column column-save">
                            <Button
                                id="save"
                                buttonType="save"
                                onClick={onSaveButtonClick}
                                type="submit"
                            >
                                {translate('GainAccess.Button.RequestAccess')}
                            </Button>
                        </div> */}
                    </div>
                )}
                {isCbreUser() && (
                    <React.Fragment>
                        <div className="column is-half">
                            <ul className="details">
                                <div id="main_block">
                                    <div id="msg_long_reason">
                                        <div className="title" id="msg_title">
                                            <b>
                                                {translate('AccessDenied.Label.Cbre.AccessDenied')}
                                            </b>{' '}
                                        </div>
                                        <li>
                                            <b>
                                                {translate(
                                                    'AccessDenied.Label.Cbre.GainAccessTitle'
                                                )}
                                            </b>
                                        </li>
                                    </div>
                                </div>
                            </ul>
                            <div className="column column-save">
                                <Button
                                    id="save"
                                    buttonType="save"
                                    onClick={onSaveButtonClick}
                                    type="submit"
                                >
                                    {translate('GainAccess.Button.RequestAccess')}
                                </Button>
                            </div>
                        </div>
                        <div className="column is-half">
                            <ul className="details">
                                <div id="main_block">
                                    <div id="msg_long_reason">
                                        <div className="title" id="msg_title">
                                            <b>
                                                {translate('AccessDenied.Label.ECERM.AccessDenied')}
                                            </b>{' '}
                                        </div>
                                        <li>
                                            <b>
                                                {translate(
                                                    'AccessDenied.Label.ECERM.GainAccessTitle'
                                                )}
                                            </b>
                                        </li>
                                    </div>
                                </div>
                            </ul>
                            <div className="column column-save">
                                <Button
                                    id="ecerm-btn"
                                    buttonType="save"
                                    onClick={onEcermClick}
                                    type="button"
                                >
                                    ECERM
                                </Button>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};

export default AccessDeniedpage;
