import * as React from 'react';
import _, { orderBy, isEmpty } from 'lodash';
import {
    Table,
    TableHeader,
    SortOrder,
    Button,
    TableNoItem,
    ConfirmDialog,
} from 'components/v2/components';
import { IAction } from 'models/action';
import selectors from './selectors';
import actions from './actions';
import { connect } from 'react-redux';
import { ActionUpsertModal } from 'components/actionUpsertModal';
import { IKeyValue, IDeleteModel } from 'models';
import { IPersonLookup } from 'models/person-lookup';
import useTranslate from 'translations/translation-utils';
import './../risk-register.scss';

interface IProps {
    siteId: string;
    riskId: string;
    riskMitigationPlanId: string;
    riskActions: IAction[];
    macroActions: IAction[];
    lookups: {
        lookupActionCategories: Array<IKeyValue<string>>;
        lookupActionPriorities: Array<IKeyValue<string>>;
        lookupActionStatuses: Array<IKeyValue<string>>;
        lookupSites: Array<IKeyValue<string>>;
        lookupSitePeopleRoles: IPersonLookup[];
    };
    disableClosedAndCompleted?: boolean;
    isUpsertModalShown: boolean;
    upsertRequest: Partial<IAction>;
    actionAutoFieldUpdatePermission?: boolean;
    isReadOnly: boolean;
    isActionEditable: boolean;
    isRiskEdited: boolean;
    isMacro: boolean;
    canEditInExpertReview: () => boolean;
    setIsActionEditable: (isEditable: boolean) => void;
}

export interface IActionProps {
    onShowUpsertActionModal: (siteId: string, userId: string) => void;
    onShowEditActionModal: (upsertItem: IAction) => void;
    createActionForRisk: (action: IAction) => void;
    updateActionForRisk: (action: IAction) => void;
    createMacroAction: (action: IAction) => void;
    updateMacroActionForRisk: (action: IAction) => void;
    archiveActionForRisk: (model: IDeleteModel) => void;
    hideModal: () => void;
}

const RiskActions: React.FC<IProps & IActionProps> = ({
    siteId,
    riskId,
    riskMitigationPlanId,
    riskActions,
    macroActions,
    lookups,
    isUpsertModalShown,
    upsertRequest,
    actionAutoFieldUpdatePermission,
    isReadOnly,
    isActionEditable,
    isRiskEdited,
    isMacro,
    onShowUpsertActionModal,
    onShowEditActionModal,
    createActionForRisk,
    updateActionForRisk,
    createMacroAction,
    updateMacroActionForRisk,
    archiveActionForRisk,
    hideModal,
    canEditInExpertReview,
    setIsActionEditable,
}) => {
    const translate = useTranslate();
    const [sortField, setSortField] = React.useState('');
    const [sortOrder, setSortOrder] = React.useState(SortOrder.off);
    const [showRiskEditedDialog, setShowRiskEditedDialog] = React.useState(false);
    const _actions = isMacro ? macroActions : riskActions;
    const onToggleHandler = (field: string) => {
        if (sortField !== field) {
            setSortField(field);
            setSortOrder(SortOrder.asc);
            return;
        }
        const sort = sortOrder === SortOrder.asc ? SortOrder.desc : SortOrder.asc;
        setSortOrder(sort);
    };

    const getSortState = (field: string): SortOrder => {
        if (field === sortField) {
            return sortOrder;
        }
        return SortOrder.off;
    };

    const onAddAction = () => {
        if (isRiskEdited) {
            setShowRiskEditedDialog(true);
        } else {
            setIsActionEditable(true);
            onShowUpsertActionModal(siteId, null);
        }
    };

    const onHideModal = () => hideModal();

    const onSubmit = (item: IAction) => {
        item.riskItemId = riskId;
        item.riskMitigationPlanId = riskMitigationPlanId;

        if (item.id) {
            if (isMacro) {
                updateMacroActionForRisk(item);
            } else {
                updateActionForRisk(item);
            }
        } else {
            if (isMacro) {
                createMacroAction(item);
            } else {
                createActionForRisk(item);
            }
        }
    };

    const openActionModal = (item: IAction) => {
        setIsActionEditable(!isReadOnly);
        onShowEditActionModal(item);
    };

    const onArchive = (action: Partial<IAction>) => {
        archiveActionForRisk({ id: riskId, shard: siteId, param: action.id } as IDeleteModel);
    };

    const getSortedList = (): IAction[] => {
        if (sortOrder === SortOrder.off) {
            return _actions;
        }
        return sortOrder === SortOrder.asc
            ? orderBy(_actions, [sortField], ['asc'])
            : orderBy(_actions, [sortField], ['desc']);
    };

    const table = (riskActionList: IAction[]) => {
        return (
            <Table>
                <thead>
                    <tr>
                        <th>{translate('RiskActionTab.TableHeader.Title')}</th>
                        <th>{translate('RiskActionTab.TableHeader.Category')}</th>
                        <th>{translate('RiskActionTab.TableHeader.Status')}</th>
                        <th>{translate('ActionDetailsPage.label.Assignedto')}</th>
                        <TableHeader
                            sortState={getSortState('dueDate')}
                            onToggle={() => onToggleHandler('dueDate')}
                        >
                            {translate('RiskActionTab.TableHeader.Duedate')}
                        </TableHeader>
                    </tr>
                </thead>
                <tbody className="clickable">
                    {!isEmpty(riskActionList) ? (
                        riskActionList
                            .filter(x => x.riskMitigationPlanId === riskMitigationPlanId)
                            .map((item, index) => (
                                <tr key={index} onClick={() => openActionModal(item)}>
                                    <td>{item.title}</td>
                                    <td>
                                        {translate('RiskActionTab.Category.dropdown.', item.category)}
                                    </td>
                                    <td>
                                        {translate('RiskActionTab.ActionStatus.dropdown.', item.status)}
                                    </td>
                                    <td>{item.assignee && item.assignee.displayLabel}</td>
                                    <td className="narrow no-wrap">{item.dueDate.split('T')[0]}</td>
                                </tr>
                            ))
                    ) : (
                        <TableNoItem colSpan={5} />
                    )}
                </tbody>
            </Table>
        );
    };

    const disableAutoGeneratedActionFields = upsertRequest &&
        !actionAutoFieldUpdatePermission &&
        (!!upsertRequest.masterProcessStepId ||
            !!upsertRequest.outcomeId ||
            !!upsertRequest.riskProfileQuestionId);
    return (
        <div className="tabs-container tabs-container-table-only tab-risk-register-action">
            {table(getSortedList())}

            {!isReadOnly ? (
                <div className="buttons">
                    {canEditInExpertReview() && (
                        <Button onClick={onAddAction}>
                            {translate('RiskActionTab.Button.Addaction')}
                        </Button>
                    )}

                    <ActionUpsertModal
                        onClose={onHideModal}
                        isShown={isUpsertModalShown}
                        onSubmit={onSubmit}
                        upsertActionRequest={{ ...upsertRequest, siteId }}
                        lookupActionCategories={lookups.lookupActionCategories}
                        lookupActionPriorities={lookups.lookupActionPriorities}
                        lookupActionStatuses={lookups.lookupActionStatuses}
                        lookupSitePeopleRoles={lookups.lookupSitePeopleRoles}
                        lookupSites={lookups.lookupSites}
                        archiveButtonEnabled={false} // archive functionality is disabled in MVP
                        onArchive={onArchive}
                        canEditInExpertReview={canEditInExpertReview}
                        disableClosedAndCompleted={true}
                        disablePendingApproval={true}
                        disableAutoGeneratedActionFields={disableAutoGeneratedActionFields}
                        isActionEditable={isActionEditable}
                    />

                    <ConfirmDialog
                        title={translate('RiskActionTab.ConfirmDialogRiskEdited.Title')}
                        message={translate('RiskActionTab.ConfirmDialogRiskEdited.Message')}
                        onClose={() => setShowRiskEditedDialog(false)}
                        onOutsideDialogClick={() => setShowRiskEditedDialog(false)}
                        isVisible={showRiskEditedDialog}
                        showConfirmButton={false}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default connect(selectors, actions)(RiskActions);
