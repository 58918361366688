import * as React from 'react';
import { Checkbox } from 'components/form/Checkbox';
import { IPersonSelection, IPerson } from 'models/person';
import useTranslate from 'translations/translation-utils';

interface IUsersListProps {
    users: IPerson[];
    selectedUserIds: string[];
    onToggleUsers: (userIds: string[]) => void;
    onToggleAllUsers: (checked: boolean) => void;
}

export const UsersList: React.FC<IUsersListProps> = (props) => {
    const hasUsers = props.users && props.users.length > 0;

    const onToggleUser = (user: IPersonSelection) => {
        const exists = props.selectedUserIds.includes(user.id);

        if (exists) {
            props.onToggleUsers(
                props.selectedUserIds.filter((c) => {
                    return c !== user.id;
                })
            );
        } else {
            props.onToggleUsers([...props.selectedUserIds, user.id]);
        }
    };

    const allChecked = () => {
        return props.users.length === props.selectedUserIds.length;
    };
    const translate = useTranslate();

    return (
        <>
            <Checkbox
                checked={allChecked()}
                label={translate('UserSelector.Labels.Select')}
                id="select-all-for-notification-checkbox"
                onChange={props.onToggleAllUsers.bind(this, allChecked())}
            />
            {hasUsers && (
                <table className="table table-responsive">
                    <thead>
                        <tr>
                            <td>{translate('UserList.UserName')}</td>
                        </tr>
                    </thead>
                    <tbody>
                        {props.users.map((user: IPerson) => (
                            <tr key={user.id}>
                                <td>
                                    <Checkbox
                                        checked={props.selectedUserIds.some((s) => s === user.id)}
                                        label={user.firstName + ' ' + user.lastName}
                                        id={`user-select-chgeckbox-${user.id}`}
                                        onChange={onToggleUser.bind(this, user)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </>
    );
};
