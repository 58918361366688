import { createSelector } from 'reselect';

import { ISiteRelationshipsRootState } from 'reducers/siteRelationships';

const getSiteState = (state: ISiteRelationshipsRootState) =>
    state.siteRelationships.siteSelector.selectedSite;

const getManagePeopleState = (state: ISiteRelationshipsRootState) =>
    state.siteRelationships.managePeople;

export default createSelector(
    getSiteState,
    getManagePeopleState,
    (site, managePeople) => ({
        site,
        people: managePeople.people,
        loading: managePeople.isLoading,
        personIdsBeingRemoved: managePeople.idsBeingRemoved
    })
);
