import Page from 'components/v2/page/Page';
import { IOperatingPlatform } from 'models/operating-platform-model';
import React, { useState } from 'react';
import { Loader } from 'components/loader';
import useTranslate from 'translations/translation-utils';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconColor, MaterialIconType } from 'routes/material-icon/material-icon-type';
import { IMenu } from 'models/menu-model';
import { onRouteChange } from 'actions/app-actions';
import { Icon, IconType } from 'components/icon';
import { checkPermissions } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import { BulmaSize } from 'enums/BulmaSize';
import { Button, ConfirmDialog } from 'components/v2/components';
import { IKeyValue } from 'models/key-value';
import './operating-platform.scss';

export interface IOperatingPlatformListStateProps {
    isLoading: boolean;
    sortColumn: string;
    sortAscending: boolean;
    menus: IMenu[];
    siteOperatingPlatform: string;
}

export interface IOperatingPlatformListDispatchProps {
    onSort?: (sortColumn: string, sortAscending: boolean) => void;
    deleteOperatingPlatform: (OperatingPlatformId: string) => void;
    loadOperatingPlatform: () => void;
    operatingPlatforms: IOperatingPlatform[];
    permissions?: Array<IKeyValue<string>>;
    loadSiteMetaData: () => void;
    resetOperatingPlatformMenus: () => void;
    resetMenusForOperatingPlatform: () => void;
    isDeletedOperatingPlatform: boolean;
    resetIsDeletedOperatingPlatformFlag: () => void;
}

export interface IActionListProps extends IOperatingPlatformListStateProps, IOperatingPlatformListDispatchProps { }
export const OperatingPlatformList: React.FC<IActionListProps> = (props) => {
    const [showDialogue, setShowDialogue] = useState(false);
    const [selectedOperatingPlatform, setSelectedOperatingPlatform] = useState('');
    const [hasAnySites, setHasAnySites] = React.useState(false);

    React.useEffect(() => {
        props.resetOperatingPlatformMenus();
        props.resetMenusForOperatingPlatform();
        props.loadOperatingPlatform();
        props.loadSiteMetaData();
    }, []);

    React.useEffect(() => {
        if (props.isDeletedOperatingPlatform) {
            props.loadOperatingPlatform();
            props.resetIsDeletedOperatingPlatformFlag();
        }
    }, [props.isDeletedOperatingPlatform]);

    const translate = useTranslate();

    const onAddPlatformClick = () => {
        onRouteChange(`/operating-platform/Create/menu-configuration`);
    }

    const onClick = (id: string) => {
        setSelectedOperatingPlatform(id);
        onRouteChange(`/operating-platform/details/${id}/menu-configuration`)
    }

    const onDelete = (id: string, siteCount: number) => {
        if (siteCount > 0) {
            setHasAnySites(true)
        }
        if (siteCount === 0) {
            setHasAnySites(false);
        }
        setShowDialogue(true);
        setSelectedOperatingPlatform(id);
    }

    const onConfirmDelete = () => {
        selectedOperatingPlatform && props.deleteOperatingPlatform(selectedOperatingPlatform);
        setShowDialogue(false);
    }


    const getOperatingPlatformItem = (
        operatingPlatform: IOperatingPlatform
    ) => (
        <div key={operatingPlatform.id} className="operating-platform-list">
            <div className="columns">
                <div className="column is-3">
                    {operatingPlatform.value}
                </div>
                <div className="column is-5">
                    <a href={operatingPlatform.processLink} className="active" target="_blank" rel="noreferrer">{operatingPlatform.processLink}</a>
                </div>
                <div className="column is-2">
                    {operatingPlatform.siteCount}
                </div>
                <div className=" column is-2">
                    <div className="is-pulled-right">
                        <a
                            onClick={() =>
                                onDelete(operatingPlatform.id, operatingPlatform.siteCount)
                            }
                        >
                            {checkPermissions([Privilege.OperatingPlatformDelete], props.permissions) &&
                                < MaterialIcons type={MaterialIconType.Delete} color={MaterialIconColor.green} />
                            }
                        </a>
                    </div>

                    <div className="is-pulled-right" >
                        <a onClick={() => onClick(operatingPlatform.id)}>
                            < MaterialIcons type={MaterialIconType.Edit} color={MaterialIconColor.green} />
                        </a>

                    </div>

                </div>
            </div>
        </div>
    );

    const getGridHeader = (label: string, fieldName: string, props: IActionListProps) => {
        if (props.onSort) {
            const onSort = () => props.onSort(
                fieldName,
                fieldName === props.sortColumn
                    ? !props.sortAscending
                    : true
            );

            if (props.sortColumn === fieldName) {
                const icon = props.sortAscending
                    ? MaterialIconType.AngleUp
                    : MaterialIconType.AngleDown;

                return (
                    <a onClick={onSort}>
                        {label}
                        < MaterialIcons type={icon} className="sort-indicator" />
                    </a>
                );
            }
        }
        else {
            return <label> {label} </label>;
        }
    };

    const getBody = () => {
        return (
            <>
                <br />
                <p>{(hasAnySites) ? translate('OperatingPlatform.Modal.withSitesDeleteMessage') : translate('OperatingPlatform.Model.DeleteMessage')}</p>
                <p><strong>{(hasAnySites) ? translate('OperatingPlatform.Modal.withSitesDeleteFooterMessage') : translate('OperatingPlatform.Model.DeleteFooterMessage')}</strong></p>
            </>
        )
    };

    return (
        <>
            <Page title={translate('SideNavBar.Labels.OperatingPlatform')} isAdminPage={true} className="">
                <Button
                    id="btnSaveAction"
                    type="submit"
                    className="add-operating-platform"
                    onClick={onAddPlatformClick}
                >
                    <Icon type={IconType.Plus} size={BulmaSize.Small} />
                    <span>Add Platform</span>
                </Button>
                <br /> <br />
                <div>
                    <Loader loading={false}>
                        <div className={'grid-headers columns is-loading'}>
                            <div className="column is-3 grid__label">
                                {getGridHeader(translate('OperatingPlatformPage.Table.Column.OperatingPlatformName'), 'OperatingPlatformName', props)}
                            </div>
                            <div className="column is-5 grid__label">
                                {getGridHeader(translate('OperatingPlatformPage.Table.Column.ProcessLink'), 'ProcessLink', props)}
                            </div>
                            <div className="column is-2 grid__label ">
                                {getGridHeader(translate('OperatingPlatformPage.Table.Column.NumberOfSites'), 'NumberOfSites', props)}
                            </div>

                        </div>
                        {props.operatingPlatforms && props.operatingPlatforms.map(
                            (action) => getOperatingPlatformItem(
                                action,
                            )
                        )}
                    </Loader >
                    <ConfirmDialog
                        title={translate('OperatingPlatform.Model.DeleteTitle')}
                        message={getBody()}
                        isVisible={showDialogue}
                        isLoading={false}
                        showConfirmButton={!hasAnySites}
                        onConfirm={() => onConfirmDelete()}
                        onClose={() => setShowDialogue(false)}
                        buttonConfirmText={translate('OperatingPlatform.Model.DeleteButton')}
                        buttonCancelText={translate('Globals.Label.Cancel')}

                    />
                </div>
            </Page >
        </>
    );
};
