import { combineReducers } from 'redux';
import { DashboardActions } from 'actions/actions-v2/dashboard-action-v2';
import { IAppAction } from 'actions/app-action';
import { IDashboardActionComponent } from 'models/dashboard/IDashboardActionComponent';
import { IDashboardRiskRegisterComponent } from 'models/dashboard/IDashboardRiskRegisterComponent';
import { IDashboardCalenderEventComponent } from 'models/dashboard/IDashboardCalenderEventComponent';
import { IDashboardIncidentComponent } from 'models/dashboard/IDashboardIncidentComponent';
import { IDashboardCermOverviewComponent } from 'models/dashboard/IDashboardCermOverviewComponent';

export interface IDashboardComponentsState {
    isActionComponentLoading: boolean;
    actionComponent: IDashboardActionComponent;
    isRiskRegisterComponentLoading: boolean;
    riskRegisterComponent: IDashboardRiskRegisterComponent;
    isCalendarEventComponentLoading: boolean;
    calendarEventComponent: IDashboardCalenderEventComponent;
    isIncidentComponentLoading: boolean;
    incidentComponent: IDashboardIncidentComponent;
    isCermOverviewComponentLoading: boolean;
    cermOverviewComponent: IDashboardCermOverviewComponent;
}

const INITIAL_STATE: IDashboardComponentsState = {
    isIncidentComponentLoading: true,
    isActionComponentLoading: true,
    actionComponent: null,
    isRiskRegisterComponentLoading: true,
    riskRegisterComponent: null,
    isCalendarEventComponentLoading: true,
    calendarEventComponent: null,
    incidentComponent: null,
    isCermOverviewComponentLoading: null,
    cermOverviewComponent: null
};

const isActionComponentLoading = (
    state: IDashboardComponentsState['isActionComponentLoading'] = true,
    action: IAppAction
) => {
    switch (action.type) {
        case DashboardActions.LOAD_DASHBOARD_ACTION:
            return true;
        case DashboardActions.LOAD_DASHBOARD_ACTION_FULFILLED:
        case DashboardActions.LOAD_DASHBOARD_ACTION_REJECTED:
            return false;
        default:
            return state;
    }
};

const actionComponent = (
    state: IDashboardComponentsState['actionComponent'] = INITIAL_STATE.actionComponent,
    action: IAppAction
) => {
    switch (action.type) {
        case DashboardActions.LOAD_DASHBOARD_ACTION_FULFILLED:
            if (action.payload) {
                return action.payload;
            }
            return state;
        default:
            return state;
    }
};

const isRiskRegisterComponentLoading = (
    state: IDashboardComponentsState['isRiskRegisterComponentLoading'] = true,
    action: IAppAction
) => {
    switch (action.type) {
        case DashboardActions.LOAD_DASHBOARD_RISK_REGISTER:
            return true;
        case DashboardActions.LOAD_DASHBOARD_RISK_REGISTER_FULFILLED:
        case DashboardActions.LOAD_DASHBOARD_RISK_REGISTER_REJECTED:
            return false;
        default:
            return state;
    }
};

const riskRegisterComponent = (
    state: IDashboardComponentsState['riskRegisterComponent'] = INITIAL_STATE.riskRegisterComponent,
    action: IAppAction
) => {
    switch (action.type) {
        case DashboardActions.LOAD_DASHBOARD_RISK_REGISTER_FULFILLED:
            if (action.payload) {
                return action.payload;
            }
            return state;
        default:
            return state;
    }
};

const isCalendarEventComponentLoading = (
    state: IDashboardComponentsState['isCalendarEventComponentLoading'] = true,
    action: IAppAction
) => {
    switch (action.type) {
        case DashboardActions.LOAD_DASHBOARD_CALENDAR_EVENT:
            return true;
        case DashboardActions.LOAD_DASHBOARD_CALENDAR_EVENT_FULFILLED:
        case DashboardActions.LOAD_DASHBOARD_CALENDAR_EVENT_REJECTED:
            return false;
        default:
            return state;
    }
};

const calendarEventComponent = (
    state: IDashboardComponentsState['calendarEventComponent'] = INITIAL_STATE.calendarEventComponent,
    action: IAppAction
) => {
    switch (action.type) {
        case DashboardActions.LOAD_DASHBOARD_CALENDAR_EVENT_FULFILLED:
            if (action.payload) {
                return action.payload;
            }
            return state;
        default:
            return state;
    }
};

const isIncidentComponentLoading = (
    state: IDashboardComponentsState['isIncidentComponentLoading'] = true,
    action: IAppAction
) => {
    switch (action.type) {
        case DashboardActions.LOAD_DASHBOARD_INCIDENT:
            return true;
        case DashboardActions.LOAD_DASHBOARD_INCIDENT_FULFILLED:
        case DashboardActions.LOAD_DASHBOARD_INCIDENT_REJECTED:
            return false;
        default:
            return state;
    }
};

const incidentComponent = (
    state: IDashboardComponentsState['incidentComponent'] = INITIAL_STATE.incidentComponent,
    action: IAppAction
) => {
    switch (action.type) {
        case DashboardActions.LOAD_DASHBOARD_INCIDENT_FULFILLED:
            if (action.payload) {
                return action.payload;
            }
            return state;
        default:
            return state;
    }
};

const isCermOverviewComponentLoading = (
    state: IDashboardComponentsState['isCermOverviewComponentLoading'] = true,
    action: IAppAction
) => {
    switch (action.type) {
        case DashboardActions.LOAD_DASHBOARD_CERM_OVERVIEW:
            return true;
        case DashboardActions.LOAD_DASHBOARD_CERM_OVERVIEW_FULFILLED:
        case DashboardActions.LOAD_DASHBOARD_CERM_OVERVIEW_REJECTED:
            return false;
        default:
            return state;
    }
};

const cermOverviewComponent = (
    state: IDashboardComponentsState['cermOverviewComponent'] = INITIAL_STATE.cermOverviewComponent,
    action: IAppAction
) => {
    switch (action.type) {
        case DashboardActions.LOAD_DASHBOARD_CERM_OVERVIEW_FULFILLED:
            if (action.payload) {
                return action.payload;
            }

            return INITIAL_STATE.cermOverviewComponent;

        default:
            return state;
    }
};

export const dashboardComponentsReducer = combineReducers<IDashboardComponentsState>({
    isActionComponentLoading,
    actionComponent,
    isRiskRegisterComponentLoading,
    riskRegisterComponent,
    isCalendarEventComponentLoading,
    calendarEventComponent,
    isIncidentComponentLoading,
    incidentComponent,
    isCermOverviewComponentLoading,
    cermOverviewComponent
});
