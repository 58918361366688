import React from 'react';
import { IProgressBarModel } from 'components/step-progress-bar/StepProgressBar';
import { history } from 'routes/App';
import { RouteUrlBuilding } from 'routes/upsert-site/routes';
import { ModelTypeDescription, ModelTypeName } from '../Context';
import { IStateResult, State } from '../State';
import { QuestionSiteType } from './question-site-type';

export class Result extends State {
    public result: IStateResult;
    private title: string = 'Your Benchmarking Type is';
    private value: number;

    constructor(value: number) {
        super();
        this.value = value;
    }
    public getResult(): IStateResult {
        return this.result;
    }
    public display(): JSX.Element {
        return (
        <div>
            <div className="is-size-3  has-text-centered">
                {this.title} : {ModelTypeName[this.value].toString()}
            </div>
            <hr/>
            <div className="is-size-4">
                Your answers:
                <div className="buttons is-right">
                    <button
                        onClick={this.handleChange}
                        className="button button is-medium"
                        type="button"
                    >Change Answers
                    </button>
                </div>
            </div>
           <table className="table is-fullwidth">
            <tbody>
                {this.context.answers.map((c) => {
                    return(
                    <tr key={c.title}>
                        <td>{c.title}</td>
                        <td><b>{c.value}</b></td>
                    </tr>
                    );
                })}
            </tbody>
           </table>
           <div className="is-size-4">
            Model Type {this.value}:
            </div>
            <p>{ModelTypeDescription[this.value].toString()}</p>
           <hr/>
         <div className="columns is-mobile is-centered">
            <div className="is-size-5 has-text-centered column is-three-fifths is-offset-one-quarte">
                It can take 1-2 hours for your benchmark and graph to be displayed on the facilities page.
            </div>
        </div>
        <div className="columns is-mobile is-centered">
            <div className="is-size-5 has-text-centered column is-three-fifths is-offset-one-quarte">
                    <button
                        // tslint:disable-next-line: jsx-no-bind
                        onClick={this.handle.bind(this)}
                        className="button button is-primary is-medium"
                        type="button"
                    >Submit
                    </button>
            </div>
        </div>
        <div className="columns is-mobile is-centered">
        <div className="has-text-centered column is-three-fifths is-offset-one-quarter is-hidden">
            {
                // this should be re-enabled when we have the details
            }
            <div className="box">
                <p className="is-5"><b>Doesn't seem quite right?</b></p>
                <p>
                    Raise a ticket and we'll be able to help, it takes up to 5 business days to respond
                </p>
                <button
                        // tslint:disable-next-line: jsx-no-bind
                        onClick={this.handleContactForm}
                        className="button button is-medium"
                        type="button"
                >Raise a ticket
                </button>
            </div>
        </div>
        </div>
    </div>);
    }
    public handle() {
        this.context.handleSubmit(this.value, this.context.id);
    }
    public getProgressBarModel(): IProgressBarModel[] {
        return [
            {isActive: true, name: 'Type of site'},
            {isActive: true, name: 'Question 1'},
            {isActive: true, name: 'Question 2'},
            {isActive: true, name: 'Question 3'},
            {isActive: true, name: 'Question 4'},
            {isActive: true, name: 'Summary'},
        ] as IProgressBarModel [];
    }
    private handleContactForm = () => {
        history.push({
                pathname: '/ContactForm',
                search: `returnUrl=${RouteUrlBuilding(this.context.siteId)}/${this.context.id}`
            }
        );
    }
    private handleChange = () => {
        this.context.transitionTo(new QuestionSiteType());
        this.context.clear();
    }
}
