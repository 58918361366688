import { createSelector } from 'reselect';
import { IAppState } from 'reducers/app';
import { IBaseState } from 'reducers/base-state';
import { IRootState } from 'routes/store';

const getNotifications = createSelector(
    (state: IBaseState) => state.app,
    (state: IAppState) => state.notifications,
);

const getECermToken = createSelector((state: IRootState) => state,
    (state) =>  {
        return state.cermAssessmentResultState.eCermToken;
    }
);

export default createSelector(
    getNotifications,
    getECermToken,
    (notifications, ecermToken) => ({
        notifications,
        ecermToken
    })
);
