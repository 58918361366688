import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { CheckboxFilter, ICustomCheckboxFilters } from '../../../components/checkboxFilter';
import { CheckboxFilterBackgroundColor } from '../../../components/filterSet/CheckboxFilterSet';
import { Loader } from '../../../components/loader';
import { SearchFilter } from '../../../components/searchFilter';
import { IKeyValue } from '../../../models/key-value';
import { ActionGridToolbar } from './actionGridToolbar';
import { ActionList, IActionListDispatchProps, IActionListStateProps } from './actionList';
import { ActionUpsert } from './actionUpsert';
import { IActionFilters } from 'models/action-filters';
import { Helmet } from 'react-helmet';
import { injectIntl, IntlShape } from 'react-intl';
import { translate } from 'translations/translation-utils';

interface IRouteParams {
    id: string;
}

export interface IStateProps extends IActionListStateProps {
    isInitialising: boolean;
    siteId: string;
    expandedFilters: string[];
    intl: IntlShape;
    lookupSites: Array<IKeyValue<string>>;
    lookupActionPriority: Array<IKeyValue<string>>;
    lookupActionCategory: Array<IKeyValue<string>>;
    lookupActionStatus: Array<IKeyValue<string>>;
}

export interface IDispatchProps extends IActionListDispatchProps {
    onSiteChange: (siteId: string) => void;
    onPageLoad: (filters: IActionFilters) => void;
}

type IProps = RouteComponentProps<IRouteParams> & IStateProps & IDispatchProps;

interface IState {
    filters: IActionFilters;
    expandedFilters: string[];
    sortColumn: string;
    sortAscending: boolean;
    hasOpenedRequestedId: boolean;
}

class ActionPageContainer extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const filters = this.getInitialFilters();
        this.state = {
            sortColumn: 'createdDateTime',
            sortAscending: false,
            filters,
            expandedFilters: [],
            hasOpenedRequestedId: null,
        };
    }

    private reservedStatuses = ['Closed', 'Cancelled'];

    public componentWillReceiveProps(props: IStateProps & IDispatchProps) {
        if (props.siteId !== this.props.siteId) {
            const filters = { ...this.state.filters, siteId: [props.siteId] };
            this.setState({ filters });
            this.props.onPageLoad(filters);
        }
    }
    public componentDidMount() {
        if (this.props.siteId) {
            this.props.onPageLoad(this.state.filters);
        }
    }

    public componentDidUpdate() {
        const id = this.props.match.params.id;
        const items = this.props.items;
        if (id && items && items.length > 0 && this.state.hasOpenedRequestedId !== true) {
            const found = items.find((item) => item.id === id);
            if (found) {
                this.props.onOpenEditActionModal(found);
                this.setState({ hasOpenedRequestedId: true });
            }
        }
    }

    public render() {
        const props = this.props;
        const intl = props.intl;
        const commonProps = {
            expandedFilters: this.state.expandedFilters,
            onFilter: this.onFilter,
            onToggleFilterExpanded: this.onToggleFilterExpanded,
            className: 'column is-6-mobile is-12-tablet',
        };
        return (
            <>
                <div className="section">
                    <Helmet>
                        <title>Quantum - Action</title>
                    </Helmet>
                    <div className="container">
                        <div className="columns">
                            <div className="column is-3 filters-container">
                                <div className="columns is-mobile is-multiline">
                                    <SearchFilter
                                        title={translate(intl, 'ActionRegisterPage.Title.Filters')}
                                        name="filterKeywords"
                                        className="column is-12"
                                        values={this.state.filters.filterKeywords}
                                        onAddFilterKeyword={this.onAddFilterKeyword}
                                        onRemoveFilterKeyword={this.onRemoveFilterKeyword}
                                    />

                                    <CheckboxFilter
                                        {...commonProps}
                                        title={translate(
                                            intl,
                                            'ActionRegisterPage.ActionRegisterTable.Title.Status'
                                        )}
                                        name="filterStatus"
                                        selectedFilters={this.state.filters.filterStatus}
                                        unreservedFilters={this.getUnreservedRiskStatuses(
                                            props.lookupActionStatus
                                        )}
                                        customFilters={this.getCustomActionStatuses()}
                                    />

                                    <CheckboxFilter
                                        {...commonProps}
                                        title={translate(
                                            intl,
                                            'ActionRegisterPage.ActionRegisterTable.Title.Category'
                                        )}
                                        name="filterCategory"
                                        selectedFilters={this.state.filters.filterCategory}
                                        unreservedFilters={props.lookupActionCategory}
                                    />

                                    <CheckboxFilter
                                        {...commonProps}
                                        title={translate(
                                            intl,
                                            'ActionRegisterPage.ActionRegisterTable.Title.Priority'
                                        )}
                                        name="filterPriority"
                                        selectedFilters={this.state.filters.filterPriority}
                                        unreservedFilters={props.lookupActionPriority}
                                    />
                                </div>
                            </div>
                            <div className="column is-9">
                                <h4 className="title is-4 is-uppercase">Actions</h4>
                                <div className="columns is-multiline is-mobile">
                                    <div className="column is-12">
                                        <ActionGridToolbar
                                            onClearFilters={this.onClearFilters}
                                            sortColumn={this.state.sortColumn}
                                        />
                                    </div>
                                    <div className="column is-12">
                                        {props.isInitialising ? (
                                            <Loader loading={true} />
                                        ) : (
                                            <ActionList
                                                onSort={this.onSort}
                                                sortColumn={this.state.sortColumn}
                                                sortAscending={this.state.sortAscending}
                                                canEditAction={this.props.canEditAction}
                                                items={this.props.items}
                                                expandedItems={this.props.expandedItems}
                                                isLoading={this.props.isLoading}
                                                onOpenEditActionModal={
                                                    this.props.onOpenEditActionModal
                                                }
                                                deleteConfirmationItem={
                                                    this.props.deleteConfirmationItem
                                                }
                                                onToggleExpanded={this.props.onToggleExpanded}
                                                remove={this.props.remove}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ActionUpsert />
            </>
        );
    }

    private getInitialFilters = () => {
        return {
            filterStatus: [],
            filterCategory: [],
            filterPriority: [],
            filterKeywords: [],
            siteId: [this.props.siteId],
        } as IActionFilters;
    };

    private onClearFilters = () => {
        const filters = this.getInitialFilters();
        this.setState({ filters });
        this.props.onPageLoad(filters);
    };

    private onSort = (key: string, _: boolean) => {
        const sortDirection = this.state.sortAscending ? false : true;
        this.props.onSort(key, sortDirection);
        this.setState({
            sortAscending: sortDirection,
            sortColumn: key,
        });
    };

    private onAddFilterKeyword = (keyword: string) => {
        const filters = { ...this.state.filters };
        const keywords = [
            keyword,
            ...filters.filterKeywords.slice(0, filters.filterKeywords.length),
        ];
        filters.filterKeywords = keywords;
        this.setState({ filters });
        this.props.onPageLoad(filters);
    };

    private onRemoveFilterKeyword = (keyword: string) => {
        const filters = { ...this.state.filters };
        const index = filters.filterKeywords.indexOf(keyword);
        const keywords = [
            ...filters.filterKeywords.slice(0, index),
            ...filters.filterKeywords.slice(index + 1),
        ];
        filters.filterKeywords = keywords;
        this.setState({ filters });
        this.props.onPageLoad(filters);
    };

    private onFilter = (key: Extract<keyof IActionFilters, string>, element: string) => {
        const specialFilters = ['Closed'];
        if (specialFilters.some((c) => c === element)) {
            const filters = {
                ...this.state.filters,
                filterStatus: [element],
            };
            this.setState({ filters });
            this.props.onPageLoad(filters);
        } else {
            const filters = { ...this.state.filters };
            const index = filters[key].indexOf(element);
            if (index !== -1) {
                const items = [...filters[key].slice(0, index), ...filters[key].slice(index + 1)];
                filters[key] = items;
            } else {
                const items = [element, ...filters[key].slice(0, filters[key].length)];
                filters[key] = items;
            }
            const filtersWithOutSpecials = {
                ...filters,
                filterStatus: filters.filterStatus.filter(
                    (f) => !specialFilters.some((c) => c === f)
                ),
            } as IActionFilters;
            this.setState({ filters: filtersWithOutSpecials });
            this.props.onPageLoad(filtersWithOutSpecials);
        }
    };

    private onToggleFilterExpanded = (id: string) => {
        const expandedFilters = this.state.expandedFilters;
        if (expandedFilters.some((c) => c === id)) {
            this.setState({ expandedFilters: expandedFilters.filter((c) => c !== id) });
        } else {
            this.setState({ expandedFilters: [id, ...this.state.expandedFilters] });
        }
    };

    private getCustomActionStatuses = () => {
        const customFilters: ICustomCheckboxFilters[] = [
            {
                id: 'Closed',
                onFilter: this.onFilter,
                color: CheckboxFilterBackgroundColor.reserved,
                filters: [{ key: 'Closed', value: 'Closed (Only)' }],
            },
        ];

        return customFilters;
    };

    private getUnreservedRiskStatuses = (statuses: Array<IKeyValue<string>>) =>
        statuses.filter((status) => !this.reservedStatuses.includes(status.key));
}

export const ActionPage = injectIntl(ActionPageContainer);
