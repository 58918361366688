import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import {
    getLookupRiskCategories,
    getLookupRiskLevels,
    getLookupRiskStatus,
    getLookupRiskSpecial,
} from 'sharedSelectors';
import { ICermOverviewRootState } from 'reducers/cerm-overview';

const getSiteId = (state: IRootState) => state.app.siteId;
const getShowHelpModule = (state: IRootState) => state.app.showHelpModule;
const getIsExporting = (state: IRootState) => state.riskRegisterState.isExporting;
const getLookupSites = (state: IRootState) => state.app.lookupSites;
const getPermissions = (state: ICermOverviewRootState) => state.app.permissions;
export const getRisksState = createSelector(
    (state: IRootState) => state.risks,
    (state) => state
);

export const getMacroRisksState = createSelector(
    (state: IRootState) => state.macroRisks,
    (state) => state
);

export default createSelector(
    getSiteId,
    getRisksState,
    getMacroRisksState,
    getLookupRiskCategories,
    getLookupRiskLevels,
    getLookupRiskStatus,
    getLookupRiskSpecial,
    getIsExporting,
    getShowHelpModule,
    getLookupSites,
    getPermissions,
    (
        siteId,
        riskState,
        macroRiskState,
        riskCategories,
        riskLevels,
        riskStatus,
        riskSpecial,
        isExporting,
        showHelpModule,
        lookupSites,
        permissions
    ) => ({
        siteId,
        isLoading: riskState.grid.isLoading,
        risks: riskState.grid.items,
        isLoadingMacroRisks: macroRiskState.isLoading,
        macroRisks: macroRiskState.items,
        isExporting,
        riskCategory: riskCategories,
        riskLevel: riskLevels,
        riskSpecial,
        riskStatus,
        showHelpModule,
        lookupSites,
        permissions
    })
);
