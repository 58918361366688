import { Select } from 'components/select';
import { FormikProps } from 'formik';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Control, Field, Help, Input, Radio } from '../../../../components/form';
import { ValueSlider } from '../../../../components/value-slider';
import { BulmaColor } from '../../../../enums/BulmaColor';
import { BulmaSize } from '../../../../enums/BulmaSize';
import { IKeyValue } from '../../../../models/key-value';
import { IActionTemplate, IQuestion } from '../../../../models/question-set';
import { formikFieldCssByState } from '../../../../utils/form-utils';
import { dueDateUnitOptions } from '../common';
import { ICreateQuestionSetPageRouteParams } from '../create-question-set-page';
import useTranslate from 'translations/translation-utils';

export interface IProps extends RouteComponentProps<ICreateQuestionSetPageRouteParams> {
    lookupActionPriorities: Array<IKeyValue<string>>;
    actionCategories: Array<IKeyValue<string>>;
    formikBag: FormikProps<Partial<any>>;
}

export const ActionForm: React.FC<IProps> = ({ lookupActionPriorities, actionCategories, formikBag, match }) => {
    const translate = useTranslate();

    const questionIndex = Number(match.params.questionIndex) || 0;

    const entityName: Extract<keyof IQuestion, string> = 'action';
    const field = (name: Extract<keyof IActionTemplate, string>) => `questions[${questionIndex}].${entityName}.${name}`;

    function getKeyed<T extends Extract<keyof IActionTemplate, string>>(key: 'values' | 'errors', name: T): string;
    function getKeyed<T extends Extract<keyof IActionTemplate, string>>(key: 'touched', name: T): boolean;
    function getKeyed<T extends Extract<keyof any, string>>(key: 'values' | 'errors' | 'touched', name: T) {
        return formikBag[key] &&
            formikBag[key].questions &&
            formikBag[key].questions[questionIndex] &&
            formikBag[key].questions[questionIndex].action &&
            formikBag[key].questions[questionIndex].action[name];
    }

    function value<T extends Extract<keyof IActionTemplate, string>>(name: T) {
        return getKeyed<T>('values', name) || '';
    }

    const getErrors = (name: Extract<keyof IActionTemplate, string>) => getKeyed('errors', name);
    const isTouched = (name: Extract<keyof IActionTemplate, string>) => getKeyed('touched', name);

    const getFieldCss = (name: Extract<keyof IActionTemplate, string>, otherCss?: string) =>
        formikFieldCssByState(Boolean(getErrors(name)), isTouched(name), otherCss);

    const getHelpByKey = (name: Extract<keyof IActionTemplate, string>) => (
        <Help
            isShown={formikBag.submitCount > 0 || isTouched(name)}
            bulmaColor={BulmaColor.Danger}
        >
            {getErrors(name)}
        </Help>
    );

    const onPriorityChanged = (newValue: IKeyValue<string>) => {
        if (newValue !== undefined) {
            formikBag.setFieldValue(field('priority'), newValue.key);
        }
    };

    const getPriorityValue = () => {
        const priorityValue = value('priority');
        return priorityValue !== undefined || priorityValue !== '' ? priorityValue : '1';
    };

    return (
        <>
            <header>
                <h2 className="title is-4">{translate('ActionDetails.Lable.1')}</h2>
            </header>

            <Field
                isHorizontal={true}
                htmlFor={field('description')}
                label={translate('ActionDetails.Lable.2') + '*'}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <textarea
                            id={field('description')}
                            name={field('description')}
                            className={getFieldCss('description', 'textarea')}
                            placeholder={translate('ActionDetails.Lable.3')}
                            value={value('description')?.trimStart()}
                            onBlur={formikBag.handleBlur}
                            onChange={formikBag.handleChange}
                        />
                    </Control>
                    {getHelpByKey('description')}
                </Field>
            </Field>
            <Field
                isHorizontal={true}
                htmlFor={field('categoryId')}
                label={translate('RiskRegisterPage.RiskFilter.Title.Category') + '*'}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <Select
                            id={field('categoryId')}
                            name={field('categoryId')}
                            aria-required="true"
                            options={actionCategories}
                            className={getFieldCss('categoryId')}
                            value={value('categoryId')}
                            onBlur={formikBag.handleBlur}
                            onChange={formikBag.handleChange}
                            emptyOptionsValue={translate('ActionDetails.Lable.4') + '...'}
                        />
                    </Control>
                    {getHelpByKey('categoryId')}
                </Field>
            </Field>

            <Field
                isHorizontal={true}
                htmlFor={field('priority')}
                label={translate('RiskActionTab.Label.Priority')}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        {lookupActionPriorities.length > 0 && (
                            <ValueSlider
                                name={field('priority')}
                                values={lookupActionPriorities}
                                selectedValue={getPriorityValue()}
                                onChange={onPriorityChanged}
                            />
                        )}
                    </Control>
                    {getHelpByKey('priority')}
                </Field>
            </Field>
            <Field
                isHorizontal={true}
                htmlFor={field('dueDateValue')}
                label={translate('ActionDetails.Lable.5')}
                labelSize={BulmaSize.Medium}
                className="is-vcentered actionDueDate"
            >
                <p>{translate('ActionDetails.Lable.6')}</p>
                <Field>
                    <Control>
                        <Input
                            id={field('dueDateValue')}
                            name={field('dueDateValue')}
                            placeholder="0"
                            type="number"
                            min={0}
                            className={getFieldCss('dueDateValue')}
                            value={value('dueDateValue')}
                            onChange={formikBag.handleChange}
                            onBlur={(event) => formikBag.setFieldValue(event.target.name, event.target.value.trim())}
                        />
                    </Control>
                </Field>
                <Field>
                    <Control>
                        <Radio
                            id={field('dueDateUnit')}
                            name={field('dueDateUnit')}
                            options={dueDateUnitOptions}
                            className={getFieldCss('dueDateUnit')}
                            value={value('dueDateUnit')}
                            onBlur={formikBag.handleBlur}
                            onChange={formikBag.handleChange}
                        />
                    </Control>
                </Field>
            </Field>
        </>
    );
};
