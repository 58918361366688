import React from 'react';
/* import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
    faChevronDown,
    faChevronUp,
    faPlusCircle,
    faMinusCircle,
    faCircle
} from '@fortawesome/free-solid-svg-icons'; */
import classNames from 'classnames';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconSize, MaterialIconType } from 'routes/material-icon/material-icon-type';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    isEnabled?: boolean;
    buttonType?: 'arrow' | 'circle' | 'plus-circle';
    className?: string;
}

export const ToggleButton: React.FC<IProps> = ({
    isEnabled = true,
    buttonType = 'arrow',
    className,
    ...props
}) => {
    const getIconName = (): MaterialIconType => {
        switch (buttonType) {
            case 'plus-circle':
                return isEnabled ? MaterialIconType.Add : MaterialIconType.Remove;

            case 'circle':
                return isEnabled ? MaterialIconType.Circle : MaterialIconType.Circle;

            case 'arrow':
            default:
                return isEnabled ? MaterialIconType.AngleDown : MaterialIconType.AngleUp;
        }
    };

    return (
        <button
            className={classNames(`button-icon button-${buttonType}`, className)}
            type="button"
            {...props}
        >
            {/*   <FontAwesomeIcon icon={getIconName()} /> */}
            < MaterialIcons type={getIconName()} size={buttonType === 'circle' && MaterialIconSize.xs} />
        </button>
    );
};
