import React from 'react';
import {
    Timeline,
    TimelineHeader,
    TimelineHeaderGridValue,
    TimelineHeaderGridValueCollection,
} from 'components/timeline';
import { TriStateToggle } from 'components/v2/components';
import { IAssessmentQuestionSet } from 'reducers/risk-assessment';
import { Question } from 'routes/risk-assessment/view/question/views/question';

interface IProps {
    questionSet: IAssessmentQuestionSet;
    expandAll: boolean;
    onQuestionSetAnswerChanged: (questionSetId: string, answer: boolean | null) => void;
    onQuestionAnswerChanged: (
        questionId: string,
        questionSetId: string,
        answer: boolean | null
    ) => void;
}

const RiskQuestionStepItem: React.FC<IProps> = ({ questionSet, expandAll, ...props }) => {
    const [isToggled, setIsToggled] = React.useState(false);

    React.useEffect(() => {
        if (questionSet.answer === true) {
            setIsToggled(expandAll);
        }
    }, [expandAll]);

    const onChange = (value?: boolean) => {
        setIsToggled(value === true);
        props.onQuestionSetAnswerChanged(questionSet.masterQuestionSetId, value);
    };

    const onToggleButtonClick = () => {
        if (questionSet.answer === false || questionSet.answer == null) {
            setIsToggled(false);
            return;
        }

        setIsToggled(!isToggled);
    };

    return (
        <Timeline expanded={isToggled} key={`${questionSet.masterQuestionSetId}`}>
            <TimelineHeader
                expanded={isToggled}
                onToggleButtonClick={onToggleButtonClick}
                centered={true}
                disabled={!questionSet.answer}
            >
                <TimelineHeaderGridValueCollection>
                    <TimelineHeaderGridValue className="timeline-title column is-5">
                        {questionSet.title}
                    </TimelineHeaderGridValue>
                    <TimelineHeaderGridValue className="has-text-centered" label="Category">
                        {questionSet.category}
                    </TimelineHeaderGridValue>
                    <TimelineHeaderGridValue className="has-text-centered" label="Urgency">
                        {questionSet.urgency}
                    </TimelineHeaderGridValue>
                    <TimelineHeaderGridValue label="Answer">
                        <TriStateToggle
                            toggleFor={questionSet.masterQuestionSetId}
                            selectedValue={questionSet.answer}
                            onChange={onChange}
                        />
                    </TimelineHeaderGridValue>
                </TimelineHeaderGridValueCollection>
                <TimelineHeaderGridValueCollection>
                    <TimelineHeaderGridValue className="timeline-title column is-12">
                        {questionSet.relevancyQuestion}
                    </TimelineHeaderGridValue>
                </TimelineHeaderGridValueCollection>
            </TimelineHeader>
            {questionSet.questions &&
                questionSet.questions.map((question, key) => {
                    return (
                        <Question
                            key={key}
                            questionIndex={key + 1}
                            question={question}
                            questionSetId={questionSet.masterQuestionSetId}
                            siteId={questionSet.siteId}
                            onQuestionAnswerChanged={props.onQuestionAnswerChanged}
                        />
                    );
                })}
        </Timeline>
    );
};

export default RiskQuestionStepItem;
