import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from './../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { SkillCategoryActions } from 'actions/actions-v2';
import { IObsoleteSkillCategory, ISkillCategory } from 'models';

const controllerName = 'skillCategory';

const createActions = {
    action: SkillCategoryActions.CREATE_SKILL_CATEGORY,
    actionFulfilled: SkillCategoryActions.CREATE_SKILL_CATEGORY_FULFILLED,
    actionRejected: SkillCategoryActions.CREATE_SKILL_CATEGORY_REJECTED,
} as IActionType;

const updateActions = {
    action: SkillCategoryActions.UPDATE_SKILL_CATEGORY,
    actionFulfilled: SkillCategoryActions.UPDATE_SKILL_CATEGORY_FULFILLED,
    actionRejected: SkillCategoryActions.UPDATE_SKILL_CATEGORY_REJECTED,
} as IActionType;

const getActions = {
    action: SkillCategoryActions.LOAD_SKILL_CATEGORIES,
    actionFulfilled: SkillCategoryActions.LOAD_SKILL_CATEGORIES_FULFILLED,
    actionRejected: SkillCategoryActions.LOAD_SKILL_CATEGORIES_REJECTED,
} as IActionType;

const getObsoleteActions = {
    action: SkillCategoryActions.LOAD_OBSOLETE_SKILL_CATEGORIES,
    actionFulfilled: SkillCategoryActions.LOAD_OBSOLETE_SKILL_CATEGORY_FULFILLED,
    actionRejected: SkillCategoryActions.LOAD_OBSOLETE_SKILL_CATEGORY_REJECTED,
} as IActionType;

const deleteActions = {} as IActionType;

const createSkillCategoryEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createActions, '/SkillCategory').post<IObsoleteSkillCategory>(
        `/api/${controllerName}`
    );
const updateSkillCategoryEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateActions, '/SkillCategory').put<IObsoleteSkillCategory>(
        `/api/${controllerName}`
    );
const loadSkillCategoriesEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getActions).getByIdAndShard<ISkillCategory>(`/api/${controllerName}`);
const loadObsoleteSkillCategoriesEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getObsoleteActions).get<IObsoleteSkillCategory>(`/api/${controllerName}`);
const deleteSkillCategoryEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, deleteActions).deleteById<string>(`/api/${controllerName}`);

export const skillCategoryEpicsV2 = combineEpics(
    createSkillCategoryEpic,
    updateSkillCategoryEpic,
    loadSkillCategoriesEpic,
    loadObsoleteSkillCategoriesEpic,
    deleteSkillCategoryEpic
);
