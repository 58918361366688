import React from 'react';
import { IconToolbar, IToolbarIconItem, ToolbarIconType } from '../v2/components';
import { history } from 'routes/App';
import useTranslate from 'translations/translation-utils';

interface IProps {
    disableExport: boolean;
    isClient: boolean;
    isFilterExists?: boolean;
    onShowFilters: () => void;
    onClearFilters: () => void;
    onExport: () => void;
    clearForm: () => void;
    isShowHelp: boolean;
    onClickHelp: () => void;
}

export const CriticalSystemSparesGridToolbar: React.FC<IProps> = ({
    disableExport,
    isClient,
    isFilterExists,
    onShowFilters,
    onClearFilters,
    onExport,
    clearForm,
    isShowHelp,
    onClickHelp,
}) => {
    const translate = useTranslate();

    const getToolbarItems = (): IToolbarIconItem[] => {
        return [
            !isClient && {
                id: 'create-new-spare-part-button',
                title: translate('CriticalSystemSparesTable.Toolbar.NewSparePart'),
                type: ToolbarIconType.add,
                onClick: () => {
                    if (clearForm) {
                        clearForm();
                    }
                    history.push('/CriticalSystemSpare/Details');
                },
            },
            !isClient && {
                id: 'export-button',
                title: translate('CriticalSystemSparesTable.Toolbar.ExportActions'),
                type: ToolbarIconType.download,
                isDisabled: disableExport,
                onClick: onExport,
            },
            {
                id: 'clear-all-button',
                title: translate('CriticalSystemSparesTable.Toolbar.ClearFilters'),
                type: isFilterExists ? ToolbarIconType.filterApplied : ToolbarIconType.clearFilter,
                onClick: onClearFilters,
            },
            {
                id: 'show-filters-button',
                title: translate('CriticalSystemSparesTable.Toolbar.ShowFilters'),
                type: ToolbarIconType.filter,
                onClick: onShowFilters,
            },
            isShowHelp && {
                id: 'show-help',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.Help'),
                type: ToolbarIconType.help,
                onClick: onClickHelp,
            },
        ];
    };

    return <IconToolbar items={getToolbarItems()} />;
};