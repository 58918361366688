import * as React from 'react';
import { Entity } from 'models/entity';
import selectors from './selectors';
import actions from './actions';
import { IComment } from 'models/comment';
import { connect } from 'react-redux';
import { ICommentQuery } from 'models/comment-query';
import { Button, Panel, ConfirmDialog, ConfirmNavigateAwayDialog } from '../v2/components';
import { formatDateToUniversalDateTime } from 'utils/date-utils';
import { Loader } from '../loader';
import { checkPermissions } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import { IKeyValue } from 'models';
import { getUserId } from 'helpers/helpers';
import useTranslate from 'translations/translation-utils';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconColor, MaterialIconType } from 'routes/material-icon/material-icon-type';

interface IProps {
    id: string;
    entity: Entity;
    siteId: string;
    comments: IComment[];
    riskComments: IComment[];
    actionComments: IComment[];
    isSaving: boolean;
    isLoading: boolean;
    permissions: Array<IKeyValue<string>>;
    message: string;
    messageEditId: string;
    editMessage: string;
    allowNewComment?: boolean;
    forceSave: boolean;
    canEdit?: boolean;
    loadComments: (query: ICommentQuery) => void;
    deleteComment: (id: string, shard: string) => void;
    createComment: (upload: IComment) => void;
    updateComment: (upload: IComment) => void;
    setMessage: (msg: string) => void;
    setEditId: (id: string) => void;
    setEditMessage: (msg: string) => void;
    overrideSiteId?: string;
}
interface ICommentToDelete {
    id: string;
    shard: string;
}

const Comments: React.FC<IProps> = ({ allowNewComment, canEdit, ...props }) => {
    const [message, setMessage] = React.useState<string>(props.message);
    const [editMessage, setEditMessage] = React.useState<string>('');
    const [editId, setEditId] = React.useState<string>('');
    const [showDialog, setShowDialog] = React.useState(false);
    const [commentToDelete, setCommentToDelete] = React.useState({} as ICommentToDelete);
    const [enableSaveButton, setEnableSaveButton] = React.useState(false);
    const translate = useTranslate();

    const comments = props.entity === Entity.Risk ? props.riskComments : 
        props.entity === Entity.Action ? props.actionComments : 
        props.comments;


    React.useEffect(() => {
        if (!props.id || !props.entity) {
            return;
        }

        setMessage('');
        props.loadComments({
            entity: props.entity,
            id: props.id,
            siteId: props.overrideSiteId ?? props.siteId,
        });
    }, [props.id]);

    React.useEffect(() => {
        if (props.forceSave && message) {
            props.createComment({
                message,
                shard: props.id,
                bucket: props.entity,
                siteId: props.overrideSiteId ?? props.siteId,
            } as IComment);
        }

        if (props.forceSave && editMessage) {
            const comment = { ...comments.find((c) => c.id === editId) };
            comment.message = editMessage;
            comment.siteId = props.siteId;
            props.updateComment(comment);
        }
    }, [props.forceSave]);

    React.useEffect(() => {
        if (props.message !== message) {
            setMessage(props.message);
        }
    }, [props.message]);

    React.useEffect(() => {
        if (props.editMessage !== editMessage) {
            setEditMessage(props.editMessage);
        }
    }, [props.editMessage]);

    React.useEffect(() => {
        if (props.messageEditId !== editId) {
            setEditId(props.messageEditId);
        }
    }, [props.messageEditId]);

    const onHandleChange = (event: any) => {
        const msg = event.target.value;
        setMessage(msg);
    };

    const onHandleEditChange = (event: any) => {
        const msg = event.target.value;
        const comment = comments.find((c) => c.id === editId);
        const containsMessage = msg && msg.length > 0 && msg !== comment.message;

        setEnableSaveButton(containsMessage);
        setEditMessage(msg);
    };

    const onCreate = () => {
        props.createComment({
            message,
            shard: props.id,
            bucket: props.entity,
            siteId: props.siteId,
        } as IComment);
        setMessage('');
    };

    const onUpdate = () => {
        const comment = { ...comments.find((c) => c.id === editId) };
        comment.message = editMessage;
        comment.siteId = props.siteId;
        props.updateComment(comment);
        props.setEditMessage(editMessage);
        props.setEditId(editId);

        setEnableSaveButton(false);
    };

    const handleEditComment = (id: string, msg: string) => {
        setEditId(id);
        setEditMessage(msg);
    };

    const handleDeleteComment = (id: string, shard: string) => {
        setShowDialog(true);
        setCommentToDelete({ id, shard });
    };

    const closeDialog = () => {
        setShowDialog(false);
    };

    const onConfirmDialog = () => {
        props.deleteComment(commentToDelete.id, commentToDelete.shard);
        setShowDialog(false);
    };

    const allowToEdit = (userId: string): boolean => {
        return (
            allowNewComment &&
            (checkPermissions([Privilege.AllCommentUpdate], props.permissions) ||
                userId === getUserId())
        );
    };

    const renderEditComment = (id: string, createdByPersonId: string) => {
        if (allowToEdit(createdByPersonId)) {
            return (
                <>
                    <div className="field">
                        <div className="control">
                            <textarea
                                id={id}
                                className="textarea is-primary"
                                value={editMessage}
                                onChange={onHandleEditChange}
                                disabled={canEdit != null && canEdit === false}
                            />
                        </div>
                    </div>
                    <Button
                        isLoading={props.isSaving}
                        type="button"
                        className="button is-primary is-medium"
                        onClick={onUpdate}
                        disabled={!enableSaveButton}
                    >
                        {translate('Comments.Button.Save')}
                    </Button>
                </>
            );
        }
    };

    return (
        <Panel title={translate('Comments.Title')} intro={translate('Comments.Intro')}>
            <Loader loading={props.isLoading || props.isSaving}>
                <div className="section">
                    <div className="comments">
                        {comments.length > 0 &&
                            comments.map(value => {
                                return (
                                    <div key={value.id} className="comment">
                                        <div className="comment-title">
                                            {value.createdByPersonName}
                                            <div className="metadata">
                                                <div>
                                                    {formatDateToUniversalDateTime(
                                                        value.lastUpdatedDateTime
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {editId === value.id ? (
                                            renderEditComment(value.id, value.createdByPersonId)
                                        ) : (
                                            <pre>{value.message}</pre>
                                        )}
                                        <div className="comment-buttons">
                                            {allowToEdit(value.createdByPersonId) && (
                                                <>
                                                    <a
                                                        className="icon-button"
                                                        onClick={() =>
                                                            handleEditComment(
                                                                value.id,
                                                                value.message
                                                            )
                                                        }
                                                    >
                                                        <MaterialIcons
                                                            type={MaterialIconType.Edit}
                                                            color={MaterialIconColor.green}
                                                        />
                                                    </a>
                                                    <a
                                                        className="icon-button"
                                                        onClick={() =>
                                                            handleDeleteComment(
                                                                value.id,
                                                                value.shard
                                                            )
                                                        }
                                                    >
                                                        <MaterialIcons
                                                            type={MaterialIconType.Delete}
                                                            color={MaterialIconColor.green}
                                                        />
                                                    </a>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}

                        {!allowNewComment &&
                            comments.length === 0 &&
                            translate('Comments.None.Message')}
                    </div>

                    {allowNewComment ? (
                        <>
                            <div className="field">
                                <div className="control">
                                    <textarea
                                        id="commentDescription"
                                        className="textarea is-primary"
                                        placeholder={translate('Comments.placeholder')}
                                        value={message}
                                        onChange={onHandleChange}
                                        disabled={canEdit != null && canEdit === false}
                                    />
                                </div>
                            </div>

                            <Button
                                isLoading={props.isSaving}
                                type="submit"
                                onClick={onCreate}
                                disabled={!(message && message.length > 0)}
                            >
                                {translate('Comments.Button.AddAComment')}
                            </Button>
                            {props.entity == Entity.Engineer &&
                                <ConfirmNavigateAwayDialog when={message && message.length > 0} />
                            }
                        </>
                    ) : null}
                </div>

                <ConfirmDialog
                    title={translate('Comments.ConfirmDialog.Title')}
                    message={translate('Comments.ConfirmDialog.Message')}
                    onClose={closeDialog}
                    onConfirm={onConfirmDialog}
                    isVisible={showDialog}
                />
            </Loader>
        </Panel>
    );
};

export default connect(selectors, actions)(Comments);
