import {
  EmeraldIconButtonVariant,
  EmeraldIconButtonVariantType,
  EmeraldIconButtonColor,
  EmeraldIconButtonColorType,
  EmeraldIconButtonShape,
  EmeraldIconButtonShapeType,
  EmeraldIconButtonSize,
  EmeraldIconButtonSizeType,
  EmeraldIconType,
  EmeraldIconTypeType,
} from '../../index';

export class EmeraldIconButtonCoreInputs
  implements IEmeraldIconButtonCoreInputs
{
  icon?: string;
  iconFillType?: EmeraldIconType | EmeraldIconTypeType = EmeraldIconType.Filled;
  type?: EmeraldIconButtonVariant | EmeraldIconButtonVariantType =
    EmeraldIconButtonVariant.Icon;
  color?: EmeraldIconButtonColor | EmeraldIconButtonColorType =
    EmeraldIconButtonColor.Primary;
  shape?: EmeraldIconButtonShape | EmeraldIconButtonShapeType =
    EmeraldIconButtonShape.Rectangular;
  size?: EmeraldIconButtonSize | EmeraldIconButtonSizeType =
    EmeraldIconButtonSize.Large;
  enableRipple?: boolean = true;
  disabled?: boolean = false;
  title?: string;
  classes?: string;
  onClick?: () => any;
}

export interface IEmeraldIconButtonCoreInputs {
  icon?: string;
  iconFillType?: EmeraldIconType | EmeraldIconTypeType;
  type?: EmeraldIconButtonVariant | EmeraldIconButtonVariantType;
  color?: EmeraldIconButtonColor | EmeraldIconButtonColorType;
  shape?: EmeraldIconButtonShape | EmeraldIconButtonShapeType;
  size?: EmeraldIconButtonSize | EmeraldIconButtonSizeType;
  enableRipple?: boolean;
  disabled?: boolean;
  title?: string;
  classes?: string;
  onClick?: () => any;
}
