import { Formik } from 'formik';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { array, bool, number, object, string } from 'yup';
import { ProgressBar } from 'components/progress-bar/progress-bar';
import { IActionTemplate, IEventTemplate, IRiskTemplate } from 'models/question-set';
import { IOutcome, IProcessStep } from '../../../../../reducers/cermAssessments';
import { getProgressSections } from '../../common';
import { ReviewAdditionalRisk } from '../../reviewAdditionalRisk';
import { ReviewOutcome } from '../../reviewOutcome';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';

export interface IRouteProps {
    id?: string;
}

export interface IProps extends RouteComponentProps<IRouteProps> {
    initialValues?: Partial<IProcessStep>;
    intl: any;
}

export interface ICermAssessmentPageDispatchProps {
    onSubmit?: () => void;
    onChangeRoot: (url: string) => void;
}

export interface IMergedProps extends IProps, ICermAssessmentPageDispatchProps { }

class CermAssessmentsReviewClass extends React.PureComponent<IMergedProps> {
    public componentDidMount = () => {
        // TODO: load cerm assessment when refresh is empty page.
    }

    public render() {
        const props = this.props;
        const intl = props.intl;
        const assessmentProcessStepSchema = object<Partial<IProcessStep>>().shape({
            title: string()
                .trim()
                .required(translate(intl, 'ValidationMessages.Action.SelectTitle'))
                .nullable(true),
            service: string()
                .required(translate(intl, 'CermAssessment.Views.Review.Message'))
                .nullable(true),
            phase: string()
                .required(translate(intl, 'CermAssessment.Views.Review.Message2'))
                .nullable(true),
            processNumber: number()
                .required(translate(intl, 'CermAssessment.Views.Review.Message3'))
                .nullable(true),

            priority: string()
                .required(translate(intl, 'ValidationMessages.Action.SelectPriority'))
                .nullable(true),
            riskTemplate: object<Partial<IRiskTemplate>>().notRequired().nullable(true).shape({
                title: string().nullable(false).required(translate(intl, 'ValidationMessages.Action.SelectTitle')),
                reviewDays: number().notRequired().nullable(true).max(1000, 'Maximum 1000'),
            }),
            expectedOutcomeAnswers: array().of(object<Partial<IOutcome>>().shape({
                title: string().required(translate(intl, 'ValidationMessages.Action.SelectTitle')),
                compliantResponse: bool().required(translate(intl, 'CermAssessment.Views.Review.Message4')),
                action: object<Partial<IActionTemplate>>().notRequired().shape({
                    description: string().required(translate(intl, 'CermAssessment.Views.Review.Message5')).nullable(false),
                    priority: string().notRequired(),
                }),
                event: object<Partial<IEventTemplate>>().notRequired().nullable(true).shape({
                    title: string().required(translate(intl, 'ValidationMessages.Action.SelectTitle')).nullable(true)
                })
            }))
        });

        return (
            <div className="section">
                <div className="container">
                    <div className="columns">
                        <div className="column">
                            <h1 className="title is-uppercase">{translate(intl, 'CermAssessment.Views.Review.Title')}</h1>
                        </div>
                    </div>

                    {props.initialValues &&
                        <Formik<Partial<IProcessStep>>
                            initialValues={props.initialValues}
                            onSubmit={props.onSubmit}
                            enableReinitialize={true}
                            validationSchema={assessmentProcessStepSchema}
                            render={(formikBag) => (
                                <div className="columns">
                                    <ProgressBar
                                        title={translate(intl, 'QSet.Lable.3')}
                                        subtitle={translate(intl, 'CermAssessment.Views.Review.SubTitle')}
                                        onChangeRoot={props.onChangeRoot}
                                        sections={getProgressSections(formikBag)}
                                        currentPath={props.location.pathname}
                                    />
                                    <div className="column is-9">
                                        <div className="columns is-multiline is-mobile">
                                            {this.props.location.pathname.includes('aditionalRisk') ?
                                                <ReviewAdditionalRisk formikBag={formikBag} /> :
                                                <ReviewOutcome formikBag={formikBag} />
                                            }
                                        </div>
                                    </div>
                                </div>)}
                        />}
                </div>
            </div>
        );
    }
}

export const CermAssessmentsReview = injectIntl(CermAssessmentsReviewClass);
