import { createSelector } from 'reselect';
import { IRootManageRoleState } from 'reducers/manageRoles';
import { getFilterKeywords, getManageRolesFilterRoles } from '../selectors';

const getPrivilegesState = createSelector(
    (state: IRootManageRoleState) => state.manageRoles.grid.privilegesState.items,
    (state) => state
);

const getSortColumn = createSelector(
    (state: IRootManageRoleState) => state.manageRoles.grid.privilegesState.sortColumn,
    (state) => state
);

const getSortAscending = createSelector(
    (state: IRootManageRoleState) => state.manageRoles.grid.privilegesState.sortAscending,
    (state) => state
);

const getIsLoading = createSelector(
    (state: IRootManageRoleState) => state.manageRoles.grid.privilegesState.isLoading,
    (state) => state
);

const getLookupRoles = createSelector(
    (state: IRootManageRoleState) => state.app.lookupRoles,
    (state) => state
);

const getPermissions = createSelector(
    (state: IRootManageRoleState) => state.app.permissions,
    (state) => state
);

export default createSelector(
    getPrivilegesState,
    getSortColumn,
    getSortAscending,
    getIsLoading,
    getLookupRoles,
    getManageRolesFilterRoles,
    getFilterKeywords,
    getPermissions,
    (privilegesState, sortColumn, sortAscending, isLoading, lookupRoles, manageRolesFilterRoles, filterKeywords,Permissions) => ({
        privilegesState,
        sortColumn,
        sortAscending,
        isLoading,
        lookupRoles,
        manageRolesFilterRoles,
        filterKeywords,
        Permissions,
    })
);
