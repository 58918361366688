export enum enumBenchmarks {
    target = 'target',
    best = 'best',
    average = 'average',
    worst = 'worst'
}

export enum enumMonths {
    jan = 'jan',
    feb = 'feb',
    mar = 'mar',
    apr = 'apr',
    may = 'may',
    jun = 'jun',
    jul = 'jul',
    aug = 'aug',
    sep = 'sep',
    oct = 'oct',
    nov = 'nov',
    dec = 'dec',
}

export enum enumQuarters {
    q1 = 'q1',
    q2 = 'q2',
    q3 = 'q3',
    q4 = 'q4',
}
