import * as signalR from '@microsoft/signalr';
import { getAuthenticationPlatform } from 'helpers/authentication';
import appConfig from 'helpers/config-helper';


const config = appConfig();
const url = config.REACT_APP_BASE_API;

let signalRConnection: signalR.HubConnection;

export async function createSignalRConfig(message: any) {
    const token = await getAuthenticationPlatform().getAccessToken();

    const options: signalR.IHttpConnectionOptions = {
        accessTokenFactory: () => {
            return `${token}`;
        },
    };

    signalRConnection = new signalR.HubConnectionBuilder()
        .withUrl(`${url}/notification`, options)
        .configureLogging(signalR.LogLevel.Information)
        .build();

    signalRConnection.on('notificationUpsert', message);
    // tslint:disable-next-line: no-console
    //signalRConnection.onclose(() => console.log('disconnected'));
    signalRConnection.onclose(() => { });

    signalRConnection
        .start()
        // tslint:disable-next-line: no-console
        .then(() => { })
        // tslint:disable-next-line: no-console
        .catch(() => { });

    return signalRConnection;
}
