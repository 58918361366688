import React from 'react';
import { IToolbarIconItem, ToolbarIconType, IconToolbar } from 'components/v2/toolbar/IconToolbar';
import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
interface IProps {
    clearForm?: () => void;
    addCalendarEvent: () => void;
}

const CalendarToolbarView: React.FC<IProps> = ({ addCalendarEvent }) => {
    const getToolbarItems = (): IToolbarIconItem[] => {
        return [
            {
                id: 'add-Calendar-event',
                title: 'Add Calendar Event',
                type: ToolbarIconType.add,
                onClick: () => {
                    addCalendarEvent();
                },
            },
        ];
    };

    return <IconToolbar items={getToolbarItems()} />;
};

const mapStateToProps = (state: IRootState) => {
    return {
        multipleSites: state.app.lookupSites.length > 1,
        siteId: state.app.siteId,
    };
};

export const CalendarToolbar = connect(mapStateToProps)(CalendarToolbarView);
