import { combineReducers } from 'redux';
import { OpportunityQuestionnaireActions } from 'actions/actions-v2/opportunity-questionnaire-actions';
import { IAppAction } from 'actions/app-action';
import { IOpportunityQuestionnaireState } from 'state/opportunity-questionnaire-state';
import { IOpportunityQuestionnaire } from 'models/opportunity-question-set';

const INITIAL_STATE: IOpportunityQuestionnaireState = {
    isLoading: false,
    isSaved: false,
    opportunityQuestionnaire: {} as IOpportunityQuestionnaire,
};

const isLoading = (
    state: IOpportunityQuestionnaireState['isLoading'] = INITIAL_STATE.isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case OpportunityQuestionnaireActions.LOAD_OPPORTUNITY_QUESTIONNAIRE:
        case OpportunityQuestionnaireActions.CREATE_OPPORTUNITY_ANSWERS:
        case OpportunityQuestionnaireActions.UPDATE_OPPORTUNITY_ANSWERS:
            return true;
        case OpportunityQuestionnaireActions.LOAD_OPPORTUNITY_QUESTIONNAIRE_FULFILLED:
        case OpportunityQuestionnaireActions.CREATE_OPPORTUNITY_ANSWERS_FULFILLED:
        case OpportunityQuestionnaireActions.CREATE_OPPORTUNITY_ANSWERS_REJECTED:
        case OpportunityQuestionnaireActions.LOAD_OPPORTUNITY_QUESTIONNAIRE_REJECTED:
        case OpportunityQuestionnaireActions.UPDATE_OPPORTUNITY_ANSWERS_FULFILLED:
        case OpportunityQuestionnaireActions.UPDATE_OPPORTUNITY_ANSWERS_REJECTED:
            return false;

        default:
            return state;
    }
};

const isSaved = (
    state: IOpportunityQuestionnaireState['isSaved'] = INITIAL_STATE.isSaved,
    action: IAppAction
) => {
    switch (action.type) {
        case OpportunityQuestionnaireActions.CREATE_OPPORTUNITY_ANSWERS:
            return false;

        case OpportunityQuestionnaireActions.CREATE_OPPORTUNITY_ANSWERS_FULFILLED:
            return true;

        default:
            return state;
    }
};

const opportunityQuestionnaire = (
    state: IOpportunityQuestionnaireState['opportunityQuestionnaire'] = INITIAL_STATE.opportunityQuestionnaire,
    action: IAppAction
) => {
    switch (action.type) {
        case OpportunityQuestionnaireActions.LOAD_OPPORTUNITY_QUESTIONNAIRE_FULFILLED:
        case OpportunityQuestionnaireActions.UPDATE_OPPORTUNITY_ANSWERS_FULFILLED:
            if (action.payload) {
                return action.payload;
            }

            return state;
        case OpportunityQuestionnaireActions.RESET_OPPORTUNITY_QUESTIONNAIRE:
            return INITIAL_STATE.opportunityQuestionnaire;
        default:
            return state;
    }
};

export const opportunityQuestionnaireReducer = combineReducers<IOpportunityQuestionnaireState>({
    isLoading,
    isSaved,
    opportunityQuestionnaire,
});
