export interface INotificationDetails {
    notificationTitle: string;
    notificationMessage: string;
    notificationLink: string;
    notificationIcon: string;
    sendEmailNotification: boolean;
    emailSubject: string;
    emailMessage: string;
    emailLinkText: string;
}

export const newNotificationDetails: INotificationDetails = {
    notificationTitle: '',
    notificationMessage: '',
    notificationLink: '',
    notificationIcon: '',
    sendEmailNotification: false,
    emailSubject: '',
    emailMessage: '',
    emailLinkText: 'Go to Quantum',
};
