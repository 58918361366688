import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { createSiteMeta, saveLineOfBusinessSiteMetaData, selectLineOfBusiness, saveDivisionsSiteMetaData, saveBusinessUnitsSiteMetaData, resetSiteMetaData } from 'actions/actions-v2/site-metadata-actions-v2'
import { createOperatingPlatform, updateOperatingPlatform, updateOperatingPlatformMenus, deleteOperatingPlatform, getOperatingPlatformMenus, loadMenusForOperatingPlatform, resetUpdatedOperatingPlatformMenusFlag, resetUpdatedOperatingPlatformMenus } from 'actions/actions-v2/operating-platform-action';
import { OperatingPlatformPageEdit } from './operating-platform-page-edit';

const getOperatingPlatforms = (state: IRootState) => state.operatingPlatformState.operatingPlatforms;
const getLineofbusinesses = (state: IRootState) => state.siteMetaDataState.hierarchy.linesOfBusiness;
const getDivisions = (state: IRootState) => state.siteMetaDataState.hierarchy.divisions;
const getBusinessUnits = (state: IRootState) => state.siteMetaDataState.hierarchy.businessUnits;
const getOperatingPlatformMenusDetail = (state: IRootState) => state.operatingPlatformState.operatingPlatformMenus;
const getDefaultMenus = (state: IRootState) => state.operatingPlatformState.menusForOperatingPlatform;
const getNewOperatingPlatformCreatedMenus = (state: IRootState) => state.operatingPlatformState.newOperatingPlatformGeneratedMenus;
const getIsUpdatedOperatingPlatformMenus = (state: IRootState) => state.operatingPlatformState.isUpdatedOperatingPlatformMenus;

const mapStateToProps = createSelector(
    getOperatingPlatforms,
    getLineofbusinesses,
    getDivisions,
    getBusinessUnits,
    getOperatingPlatformMenusDetail,
    getDefaultMenus,
    getNewOperatingPlatformCreatedMenus,
    getIsUpdatedOperatingPlatformMenus,
    (operatingPlatforms, lineofBusinessList, divisionsList, businessUnitsList, operatingPlatformMenusDetail, defaultMenus, newOperatingPlatformGeneratedMenus, isUpdatedOperatingPlatformMenus) => ({
        operatingPlatforms,
        lineofBusinessList,
        divisionsList,
        businessUnitsList,
        operatingPlatformMenusDetail,
        defaultMenus,
        newOperatingPlatformGeneratedMenus,
        isUpdatedOperatingPlatformMenus
    })
);

const mapDispatchToProps = {
    loadMenusForOperatingPlatform,
    createOperatingPlatform,
    updateOperatingPlatform,
    deleteOperatingPlatform,
    createSiteMeta,
    saveLineOfBusinessSiteMetaData,
    saveDivisionsSiteMetaData,
    saveBusinessUnitsSiteMetaData,
    selectLineOfBusiness,
    getOperatingPlatformMenus,
    updateOperatingPlatformMenus,
    resetSiteMetaData,
    resetUpdatedOperatingPlatformMenusFlag,
    resetUpdatedOperatingPlatformMenus
};

export const operatingPlatformEditPage = connect(mapStateToProps, mapDispatchToProps)(OperatingPlatformPageEdit);
