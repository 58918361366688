import { ManageRoleActions, ManageRoleActionTypes, onAddRemovePrivilege, loadPrivileges } from 'actions/manage-role-actions';

export default {
    onSort: (sortColumn: string, sortAscending: boolean): ManageRoleActionTypes => ({
        type: ManageRoleActions.CHANGE_SORT_ORDER_VALUE_PRIVILEGES_TAB,
        payload: {
            sortColumn,
            sortAscending
        }
    }),
    load: loadPrivileges,
    onAddRemovePrivilege
};
