import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';

const getIsLoading = (state: IRootState) => state.criticalSystemSparesState.isLoading;
const getSiteId = (state: IRootState) => state.app.siteId;
const getCriticalSpare = (state: IRootState) => state.criticalSystemSparesState.criticalSystemSpare;

export default createSelector(
    getIsLoading,
    getSiteId,
    getCriticalSpare,
    (isLoading, siteId, criticalSpare) => ({
        isLoading,
        siteId,
        criticalSpare: { ...criticalSpare, siteId }
    })
);
