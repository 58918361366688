import * as React from 'react';
import { Icon, IconType } from 'components/icon';
import { BulmaSize } from 'enums/BulmaSize';
import { Button, ConfirmDialog } from 'components/v2/components';
import { Help, Input } from 'components/form';
import useTranslate from 'translations/translation-utils';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconColor, MaterialIconType } from 'routes/material-icon/material-icon-type';
import { newGuid } from 'utils/id-utils';
import { IOperatingPlatform } from 'models/operating-platform-model';
import { IDivisionsCreate, ISelectedSiteMetaData } from 'models/site-metadata-model';
import { IDivisions } from 'models/divisions-model';
import iconHierachyWhite from 'assets/icon-hierarchy-division-white.svg';
import iconHierachyBlack from 'assets/icon-hierarchy-division-black.svg';
import { BulmaColor } from 'enums/BulmaColor';
interface IProps {
    divisions: IDivisions[];
    selectOperatingPlatform: IOperatingPlatform;
    newOperatingPlatform: { id: string, label: string };
    saveDivisionsSiteMetaData: (divisions: IDivisionsCreate[]) => void;
    selectDivision: (divisionId: string, divisionLabel: string, count: number, isActive: boolean) => void;
    selectLineOfBusinessValue: ISelectedSiteMetaData;
    selectLineOfBusiness: (lineOfbusinessId: string, lineOfBusinessLabel: string, count: number, isActive: boolean) => void;
    selectDivisionValue: ISelectedSiteMetaData;
    getDivisionsForLineOfBusiness: (model: IDivisions[]) => void;
}
export const HierarchyDivisions: React.FC<IProps> = ({
    divisions,
    selectOperatingPlatform,
    newOperatingPlatform,
    saveDivisionsSiteMetaData,
    selectDivision,
    selectLineOfBusinessValue,
    selectLineOfBusiness,
    selectDivisionValue,
    getDivisionsForLineOfBusiness
}) => {
    const translate = useTranslate();
    const [allDivisions, setAllDivisions] = React.useState<IDivisions[]>(divisions)
    const [divisionList, setDivisionList] = React.useState<IDivisions[]>([])
    const [isShown, setIsShown] = React.useState<boolean>(false);
    const [isEditHierarchySetOpen, setisEditHierarchySetOpen] = React.useState<boolean>(false);
    const [isEditHierarchySet, setisEditHierarchySet] = React.useState<IDivisions>(null);
    const [newLobText, setNewLobText] = React.useState<string>('');
    const [updatedLobText, setUpdatedLobText] = React.useState<string>('');
    const [updatedDivisions, setUpdatedDivisions] = React.useState<IDivisions[]>([])
    const [showDialogue, setShowDialogue] = React.useState(false);
    const [selecteDivision, setDivision] = React.useState('');
    const [siteCount, setSiteCount] = React.useState(false);
    const [isDuplicate, setIsDuplicate] = React.useState<boolean>(false);

    React.useEffect(() => {
        let allDivisionsList = [...allDivisions];
        const divisionsIndex = selectDivisionValue?.id && allDivisionsList && allDivisionsList.findIndex(x => x.id === selectDivisionValue?.id);
        if (divisionsIndex > -1) {
            const obj2 = Object.assign({}, allDivisionsList[divisionsIndex]);
            obj2.count = selectDivisionValue.count;
            allDivisionsList[divisionsIndex] = obj2;
            setAllDivisions(allDivisionsList);
        }
    }, [selectDivisionValue?.count]);

    React.useEffect(() => {
        let allDivisionsList = [...allDivisions];
        if (selectLineOfBusinessValue?.isActive === false) {
            const deletedDivisionItemsForLOB = allDivisionsList.filter(obj => obj.lineOfBusinessId === selectLineOfBusinessValue?.id);
            getDivisionsForLineOfBusiness(deletedDivisionItemsForLOB);
            allDivisionsList = allDivisionsList.filter(obj => obj.lineOfBusinessId !== selectLineOfBusinessValue?.id);
            setAllDivisions(allDivisionsList);
        }
    }, [selectLineOfBusinessValue?.isActive]);

    React.useEffect(() => {
        filterDivisions(allDivisions);
    }, [selectLineOfBusinessValue?.id, allDivisions]);

    const filterDivisions = (allDivisions) => {
        const operatingPlatform = selectOperatingPlatform ? selectOperatingPlatform : newOperatingPlatform
        const divisions1 = operatingPlatform?.id && selectLineOfBusinessValue?.id && allDivisions.filter(
            x =>
                x.operatingPlatformId === operatingPlatform.id &&
                x.lineOfBusinessId === selectLineOfBusinessValue.id
        );
        setDivisionList(divisions1)
    }

    const handleOnClick = () => {
        if (isEditHierarchySetOpen) {
            setisEditHierarchySetOpen(false)
        }
        setIsShown(true);
    };

    const onConfirmDelete = () => {
        selecteDivision && deleteDivision(selecteDivision);
        setShowDialogue(false);
    }

    const onClickDivision = (x) => {
        if (x?.key) {
            selectDivision(x.key, x.label, x.count, true)
        }
        setisEditHierarchySet(x)
    }

    const setSelectedDivision = () => {
        if (isShown) {
            setIsShown(false)
        }
        setisEditHierarchySetOpen(true)
    }

    const onChangeEditText = (obj, updatedText) => {
        setUpdatedLobText(updatedText);
        const obj2 = Object.assign({}, obj);
        if (updatedText) {
            obj2.label = updatedText;
            obj2.value = updatedText;
        }
        setisEditHierarchySet(obj2)
    }

    const deleteDivision = (deletedItem) => {
        let oldList = [];
        if (allDivisions?.length > 0) {
            oldList = [...allDivisions];
            oldList = oldList.filter(obj => obj.id !== deletedItem.id);
            setAllDivisions(oldList);
            selectLineOfBusiness(selectLineOfBusinessValue?.id, selectLineOfBusinessValue?.label, selectLineOfBusinessValue?.count - 1, selectLineOfBusinessValue.isActive)
        }
        const payloadDeletedItem = {
            id: deletedItem.id,
            key: deletedItem.key,
            label: deletedItem.value,
            lineOfBusiness: deletedItem.lineOfBusiness,
            lineOfBusinessId: deletedItem.lineOfBusinessId,
            operatingPlatform: deletedItem.operatingPlatform,
            operatingPlatformId: deletedItem.operatingPlatformId,
            value: deletedItem.value,
            isActive: false,
            count: deletedItem.count
        };
        if (deletedItem.id === selectDivisionValue?.id) {
            selectDivision(selectDivisionValue?.id, selectDivisionValue?.label, selectDivisionValue?.count, false)
        }
        let deletedList = [];
        if (updatedDivisions?.length > 0) {
            deletedList = [...updatedDivisions];
            const obj = deletedList.findIndex(x => x.id === deletedItem.id);
            if (obj > -1) {
                deletedList[obj] = payloadDeletedItem;
            }
            else {
                deletedList.push(payloadDeletedItem);
            }
        }
        else {
            deletedList.push(payloadDeletedItem);
        }
        setUpdatedDivisions(deletedList);
        saveDivisionsSiteMetaData(deletedList);

    }

    const deleteItem = (deletedItem) => {
        setShowDialogue(true);
        if (deletedItem.siteCount > 0) {
            setSiteCount(true)
        }
        if (deletedItem.siteCount === 0) {
            setDivision(deletedItem);
            setSiteCount(false);
        }
    }

    const onChangeText = (title) => {
        setNewLobText(title)
    }

    const addDivisions = () => {
        let oldList = [];
        if (allDivisions?.length > 0) {
            oldList = [...allDivisions];
        }
        const operatingPlatformId = selectOperatingPlatform ? selectOperatingPlatform.id : newOperatingPlatform.id;
        const operatingPlatformValue = selectOperatingPlatform ? selectOperatingPlatform.value : newOperatingPlatform.label;
        const newLobId = newGuid();
        const payloadLobs = {
            id: newLobId,
            key: newLobId,
            label: newLobText,
            lineOfBusiness: selectLineOfBusinessValue?.label,
            lineOfBusinessId: selectLineOfBusinessValue?.id,
            operatingPlatform: operatingPlatformValue,
            operatingPlatformId: operatingPlatformId,
            value: newLobText,
            isActive: true,
            count: 0,
            siteCount: 0
        };
        const payloadUpdatedLobs = {
            id: newLobId,
            key: newLobId,
            label: newLobText,
            lineOfBusiness: selectLineOfBusinessValue?.label,
            lineOfBusinessId: selectLineOfBusinessValue?.id,
            operatingPlatform: operatingPlatformValue,
            operatingPlatformId: operatingPlatformId,
            value: newLobText,
            isActive: true,
        };
        const found = divisionList && divisionList.length > 0 && divisionList.some(el => el.label === newLobText);
        if (!found) {
            if (isDuplicate) {
                setIsDuplicate(false);
            }
            oldList.push(payloadLobs);
            const updatedlist1 = [...updatedDivisions];
            updatedlist1.push(payloadUpdatedLobs)
            setUpdatedDivisions(updatedlist1);
            saveDivisionsSiteMetaData(updatedlist1);
            setAllDivisions(oldList);
            setisEditHierarchySet(payloadLobs);
            selectDivision(newLobId, newLobText, 0, true)
            selectLineOfBusiness(selectLineOfBusinessValue?.id, selectLineOfBusinessValue?.label, selectLineOfBusinessValue?.count + 1, selectLineOfBusinessValue.isActive)
            setIsShown(false);
            setNewLobText('');
        }
        else {
            setIsDuplicate(true);
        }
    }
    const updateDivisions = (id) => {
        let oldList = [];
        const found = updatedLobText && divisionList && divisionList.length > 0 && divisionList.some(el => el.label === updatedLobText);
        if (!found) {
            if (isDuplicate) {
                setIsDuplicate(false);
            }
            if (allDivisions?.length > 0) {
                oldList = [...allDivisions];
                const obj = oldList.findIndex(x => x.id === id);
                if (obj > -1) {
                    oldList[obj] = isEditHierarchySet;
                    setAllDivisions(oldList);
                }
            }
            else {
                oldList.push(isEditHierarchySet);
                setAllDivisions(oldList);
            }

            const payloadLobs = {
                id: isEditHierarchySet?.id,
                key: isEditHierarchySet?.key,
                label: isEditHierarchySet?.value,
                lineOfBusiness: isEditHierarchySet?.lineOfBusiness,
                lineOfBusinessId: isEditHierarchySet?.lineOfBusinessId,
                operatingPlatform: isEditHierarchySet?.operatingPlatform,
                operatingPlatformId: isEditHierarchySet?.operatingPlatformId,
                value: isEditHierarchySet?.value,
                isActive: true
            };

            const updatedList2 = [...updatedDivisions];
            const obj1 = updatedList2.findIndex(x => x.id === id);
            if (obj1 > -1) {
                updatedList2[obj1] = isEditHierarchySet;
                setUpdatedDivisions(updatedList2);
                saveDivisionsSiteMetaData(updatedList2);
            }
            else {
                updatedList2.push(payloadLobs)
                setUpdatedDivisions(updatedList2);
                saveDivisionsSiteMetaData(updatedList2);
            }
            selectDivision(isEditHierarchySet.key, isEditHierarchySet.label, isEditHierarchySet.count, isEditHierarchySet.isActive);
            setUpdatedLobText('');
            setisEditHierarchySetOpen(false)
        }
        else {
            setIsDuplicate(true);
        }
    }
    const OnCancelIsEditHierarhySet = () => {
        setisEditHierarchySetOpen(false);
        if (isDuplicate) {
            setIsDuplicate(false);
        }
    }

    const OnCancelHierarhySet = () => {
        setIsShown(false);
        if (isDuplicate) {
            setIsDuplicate(false);
        }
    }
    const getBody = () => {
        return (
            <>
                <br />
                <p>{(siteCount) ? translate('OperatingPlatform.Modal.Division.DeleteMessageWithSite') : translate('OperatingPlatform.Model.DeleteMessageWithoutSite')}</p>
                {(!siteCount) ? <p><strong>{translate('OperatingPlatform.Hierarchy.Modal.DeleteFooterMessage')}</strong></p> : <p><strong>{translate('OperatingPlatform.Modal.withSitesDeleteFooterMessage')}</strong></p>}
            </>
        )
    };
    return (
        <>
            <div className='columns'>
                <div className='column is-8'>
                    <span className='hierachy-title'>{translate('Globals.Label.DivisionSector')} {`(${divisionList ? divisionList.length : 0})`}</span>
                </div>
                <div className='column is-2'>
                    <Button
                        id="btnDivisionSaveAction"
                        type="submit"
                        className="add-hierachy-item"
                        onClick={handleOnClick}
                        disabled={
                            !(selectLineOfBusinessValue?.id)
                        }
                    >
                        <Icon type={IconType.PlusCircle} size={BulmaSize.Small} />
                        <span className='hierarchy-add-button'>{translate('OperatingPlatform.ButtonLabel.Add')}</span>
                    </Button>
                </div>
            </div>
            <ConfirmDialog
                title={translate('OperatingPlatform.Hierarchy.Modal.DeleteTitle')}
                message={getBody()}
                isVisible={showDialogue}
                isLoading={false}
                showConfirmButton={!siteCount}
                onConfirm={() => onConfirmDelete()}
                onClose={() => setShowDialogue(false)}
                buttonConfirmText={translate('OperatingPlatform.Model.DeleteButton')}
                buttonCancelText={translate('Globals.Label.Cancel')}

            />
            {divisionList?.length > 0 &&
                divisionList.map((x) =>
                (

                    < div key={x.key} className={x.id === selectDivisionValue?.id ? "columns selectedHierarchy active-hierachy-item" : "columns selectedHierarchy"} >
                        <div
                            id={
                                'editDivision' + x.key
                            }
                            className="column is-9 is-clickable"
                            onClick={() =>
                                onClickDivision(
                                    x
                                )}
                        >  <span className='icon'>
                                <img src={x.id === selectDivisionValue?.id ? iconHierachyWhite : iconHierachyBlack} alt={"hierachy-item"} />
                            </span>
                            <span className='icon-text'>{x.value} {x?.count > 0 ? `(${x.count})` : " "}</span>
                        </div>
                        <div className="column is-3">
                            <a
                                id={
                                    'editDivision' + x.key
                                }

                                onClick={() =>
                                    setSelectedDivision(

                                    )
                                }
                            >

                                < MaterialIcons type={MaterialIconType.Edit} color={x.id === selectDivisionValue?.id ? MaterialIconColor.white : MaterialIconColor.green} />
                            </a>
                            <a
                                id={
                                    'deleteDivision' + x.key
                                }
                                onClick={() =>
                                    deleteItem(
                                        x
                                    )
                                }
                            >
                                < MaterialIcons type={MaterialIconType.Delete} color={x.id === selectDivisionValue?.id ? MaterialIconColor.white : MaterialIconColor.green} />
                            </a>
                        </div>
                    </div >
                ))}
            {
                isShown &&
                <div className='edit-hirerachy-section'>
                    <div className="columns">
                        <div className="column is-1">
                        </div>
                        <div className="column is-10">
                            <Help
                                bulmaColor={BulmaColor.Danger}
                                isShown={isDuplicate}
                            >
                                {translate('OperatingPlatform.Hierarchy.Duplicate.Divisions')}
                            </Help>
                            <Input
                                id={
                                    'newDivision'
                                }
                                placeholder={translate('OperatingPlatform.Hierarchy.Divisions')}
                                onChange={(e: any) =>
                                    onChangeText(
                                        e.target.value
                                    )
                                }
                                className='hierarchy-new-item-input'
                                value={newLobText}
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className='column is-2'></div>
                        <div className="column is-10 is-pull-right hierarchy-buttons">
                            <Button
                                id={
                                    'newDivisionAdd'
                                }
                                className='cancel-hirerachy-button'
                                onClick={() =>
                                    OnCancelHierarhySet(
                                    )
                                }
                            >
                                {translate('OperatingPlatform.Button.Cancel')}
                            </Button>
                            <Button
                                id={
                                    'newDivisionAdd'
                                }
                                onClick={() =>
                                    addDivisions(

                                    )
                                }
                                className='save-hirerachy-button'
                            >
                                {translate('OperatingPlatform.Button.Save')}
                            </Button>
                        </div>
                    </div>
                </div>
            }
            {
                isEditHierarchySetOpen && isEditHierarchySet &&
                <div className='edit-hirerachy-section'>
                    <div className="columns">
                        <div className="column is-1">
                        </div>
                        <div className="column is-10">
                            <Help
                                bulmaColor={BulmaColor.Danger}
                                isShown={isDuplicate}
                            >
                                {translate('OperatingPlatform.Hierarchy.Duplicate.Divisions')}
                            </Help>
                            <Input
                                id={
                                    'editDivision' +
                                    isEditHierarchySet.key
                                }
                                placeholder={isEditHierarchySet.value}
                                onChange={(e: any) =>
                                    onChangeEditText(
                                        isEditHierarchySet,
                                        e.target.value
                                    )
                                }
                                value={
                                    updatedLobText ? updatedLobText : isEditHierarchySet.value
                                }
                                className='hierarchy-new-item-input'
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className='column is-2'></div>
                        <div className="column is-10 is-pull-right hierarchy-buttons">
                            <Button
                                id={
                                    'cancelHierarhySet' +
                                    isEditHierarchySet.key
                                }
                                className='cancel-hirerachy-button'
                                onClick={() =>
                                    OnCancelIsEditHierarhySet(
                                    )
                                }
                            >
                                {translate('OperatingPlatform.Button.Cancel')}
                            </Button>
                            <Button
                                id={
                                    'newDivisionAdd' +
                                    isEditHierarchySet.key
                                }
                                onClick={() =>
                                    updateDivisions(
                                        isEditHierarchySet.id
                                    )
                                }
                                className='save-hirerachy-button'
                            >
                                {translate('OperatingPlatform.Button.Save')}
                            </Button>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};