import classnames from 'classnames';
import * as React from 'react';

import { SliderLabel, SliderLabels } from './sliderLabels';
import { getOffsetLeftPosition } from './utils';

import { IKeyValue } from '../../models/key-value';
import './value-slider.scss';

export interface IValueSliderProps {
    selectedValue: string;
    values: Array<IKeyValue<string>>;
    name: string;
    labelStart?: SliderLabel;
    labelEnd?: SliderLabel;

    onChange?: (value: IKeyValue<string>) => void;

    isDanger?: boolean;
    isWarning?: boolean;
}

export interface IState {
    value?: number;
}

export class ValueSlider extends React.Component<IValueSliderProps, IState> {
    constructor(props: IValueSliderProps) {
        super(props);
        this.state = {
            value: this.returnIndex(props.selectedValue)
        };

        this.onChange = this.onChange.bind(this);
    }
    public returnIndex(selectedValue: string) {
        let selectedIndex = this.props.values.findIndex((x) => x.key === selectedValue);
        if (selectedIndex == null || selectedIndex < 0) {
            selectedIndex = 0;
            this.props.onChange(this.props.values[0]);
        }
        return selectedIndex;
    }

    public componentWillReceiveProps(newProps: IValueSliderProps) {
        this.setState({
            value: this.returnIndex(newProps.selectedValue)
        });
    }

    public onChange(e: React.ChangeEvent<HTMLInputElement>) {
        const newValue = Number(e.target.value);
        this.setState({
            ...this.state,
            value: newValue
        });

        this.emitChange(newValue);
    }

    public render() {
        const props = this.props;

        const valueStyle = {
            transform: `translateX(${getOffsetLeftPosition(this.state.value, 0, props.values.length - 1)}%)`
        };

        const addColorClass = () => {
            if ((this.state.value) < Math.ceil(this.props.values.length / 4)) {
                return 'low';
            }
            if ((this.state.value) >= Math.ceil(this.props.values.length / 4) &&
            (this.state.value) < this.props.values.length / 1.5) {
                return 'medium';
            }
            return 'high';
        };

        const baseStyle = 'ValueSlider';
        const css = classnames(
            baseStyle,
            `${baseStyle}--${addColorClass()}`,
            {
                [`${baseStyle}--is-danger`]: props.isDanger,
                [`${baseStyle}--is-warning`]: props.isWarning
            }
        );

        return (
            <div className={css}>
                <SliderLabels
                    currentValue={this.state.value}
                    labelStart={props.values[0].key}
                    labelEnd={props.values[props.values.length - 1].key}
                />

                <input
                    className="ValueSlider__range"
                    step={1}
                    min={0}
                    max={props.values.length - 1}
                    type="range"
                    value={this.state.value}
                    name={props.name}
                    onChange={this.onChange}
                />

                <div className="ValueSlider__value-track">
                    <div className="ValueSlider__value-content" style={valueStyle}>
                        <div className="ValueSlider__value">
                            {props.values[this.state.value].value}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private emitChange = (newValue: number) => {
        if (this.props.onChange) {
            requestAnimationFrame(() =>
                this.props.onChange(this.props.values[newValue])
            );
        }
    }
}
