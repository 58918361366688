import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Loader } from 'components/loader';
import { IRisk, RiskMitigationPlanStatus } from 'models/risk';
import { Formik, FormikProps, FormikState } from 'formik';
import { history } from 'routes/App';
import { Button } from 'components/v2/button/Button';
import RiskDetailsTab from './tabs/details/risk-details-tab';
import RiskHistoryTab from './tabs/history/risk-history-tab';
import {
    TabGroup,
    TabItem,
    ConfirmDialog,
    ButtonWithConfirmDialog,
    ButtonWithCheckboxConfirmDialog,
    Alert,
} from 'components/v2/components';
import { IPersonLookup } from 'models/person-lookup';
import CbreApproval from './cbre-approval/approval';
import selectors from './selectors';
import actions from './actions';
import { checkPermission } from 'utils/permission-utils';
import { IKeyValue } from 'models';
import { getUserId, isSiteApprover } from 'helpers/helpers';
import { ClientRiskDetailsTab } from 'routes/clientView/client-risk-register/tabs/client-risk-details-tab';
import { riskDetailsFormValidationSchema } from './tabs/details/risk-details-form';
import { isEmpty } from 'lodash';
import Page from 'components/v2/page/Page';
import { getPersonInfo } from 'business-area-functions/incidents';
import useTranslate from 'translations/translation-utils';
import { IExpertReviewStatus } from 'models/cerm/cerm-assessment-result';
import { Privilege } from 'enums/Privilege';
import { Link } from 'react-router-dom';
import { RiskCircleStatus } from 'components/circle-data-displayer/risk-circle-status';
import { RiskArrow } from 'components/circle-data-displayer/risk-arrow';
import * as RiskStatus from 'models/risk-statuses';
import { IRiskApproval } from 'models/risk-approval';
import { getDatePartOfDateTime } from 'utils/date-utils';
import './risk-register-upsert-page.scss';
import moment from 'moment';
import RiskDocumentsTab from './tabs/documents/risk-documents-tab';
import { IAttachmentQuery } from 'models/attachment/attachment-query';
import { Entity } from 'models/entity';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import { DatePickerField } from 'components/form/fields/date-picker-field';
import { ShardValue } from 'enums/ShardValue';

interface IProps extends RouteComponentProps<IParams> {
    siteId: string;
    isLoading?: boolean;
    risk: IRisk;
    users: IPersonLookup[];
    lookupSiteFeatures: Array<IKeyValue<string[]>>;
    expertReviewStatus: IExpertReviewStatus;
    permissions: Array<IKeyValue<string>>;
    attachments: IAttachmentModel[];
    submitActionToRisk: boolean;
    loadRisk: (id: string, siteId: string) => void;
    createRisk: (model: Partial<IRisk>) => void;
    createRiskMitigationPlan: (model: Partial<IRisk>) => void;
    acceptRisk: (model: Partial<IRisk>) => void;
    updateRisk: (model: Partial<IRisk>) => void;
    removeRisk: (id: string, siteId: string, archiveActions: boolean) => void;
    closeRisk: (risk: IRisk) => void;
    openClosedRisk: (risk: IRisk) => void;
    clearForm: () => void;
    loadPersonsLookup: (siteId: string) => void;
    loadPersonProfile: (id: string) => void;
    loadPersonsBySiteAndPrivilege: (siteId: string, privilegeName: string) => void;
    loadCermAssessmentExpertReview(siteId: string);
    cbreApprovalRisk: (riskApproval: IRiskApproval) => void;
    loadAttachments: (query: IAttachmentQuery) => void;
    loadMacroRisk: (id: string) => void;
    createMacroRisk: (model: Partial<IRisk>) => void;
    updateMacroRisk: (model: Partial<IRisk>) => void;
    removeMacroRisk: (id: string) => void;
    resetRisk: (risk: IRisk) => void;
}

interface IParams {
    id?: string;
    tab: string;
    siteId?: string;
    macro?: string;
}

enum Tab {
    details = 'details',
    documents = 'documents',
    history = 'history',
}

const RiskRegisterUpsertPage: React.FC<IProps> = ({
    siteId,
    match,
    location,
    isLoading,
    risk,
    users,
    lookupSiteFeatures,
    expertReviewStatus,
    permissions,
    loadPersonsLookup,
    loadPersonProfile,
    loadPersonsBySiteAndPrivilege,
    loadCermAssessmentExpertReview,
    ...props
}) => {
    const translate = useTranslate();
    const [isValid, setIsValid] = React.useState(false);
    const [isMitigationValid, setIsMitigationValid] = React.useState(true);
    const [mandatoryApproverIds, setMandatoryApproverIds] = React.useState([]);
    const [showTabDisabledDialog, setShowTabDisabledDialog] = React.useState(false);
    const [currentSharedToClient, setCurrentSharedToClient] = React.useState<boolean>(null);
    const [showClientFacingDialog, setShowClientFacingDialog] = React.useState(false);
    const [commentSave, setCommentSave] = React.useState(false);
    const [messageText, setMessageText] = React.useState<JSX.Element>();
    const [linkedIncidentsListDialogVisible, setLinkedIncidentsListDialogVisible] = React.useState(false);
    const [showAcceptButton, setShowAcceptButton] = React.useState(false);
    const [acceptDialogReviewDate, setAcceptDialogReviewDate] = React.useState(null);
    const [acceptDialogReviewDateError, setAcceptDialogReviewDateError] = React.useState(null);
    const [isMacroValid, setIsMacroValid] = React.useState<boolean>(true);

    const i = new URLSearchParams(location.search);
    const [isMacro, setIsMacro] = React.useState<boolean>(
        i.get('macro') === 'true' || (risk && risk.isMacro)
    );
    const riskId = match.params.id;
    const currentSiteId = match.params.siteId ? match.params.siteId : siteId;
    const currentTab = match.params.tab.toLowerCase();
    const risksPageUrl = '/Risks';
    const customLinks: Array<IKeyValue<string>> = [
        { key: risksPageUrl, value: translate('RiskRegisterPage.Title.RiskRegister') },
    ];
    const isReadOnly = risk && risk.riskCategory === 'ElogBooks';
    const isSameUserAsAssigned = risk && risk.assigneeId === getUserId();
    const allowMacroConfig = !riskId || (risk && !isMacro) || (isMacro && isSameUserAsAssigned);

    let formikBag: FormikProps<IRisk>;

    const canEditInExpertReview = (): boolean => {
        if (
            risk.masterProcessStepId &&
            expertReviewStatus &&
            expertReviewStatus.isInReview === true
        ) {
            return (
                expertReviewStatus &&
                (expertReviewStatus.startedById === undefined ||
                    expertReviewStatus.startedById === null ||
                    (expertReviewStatus.startedById &&
                        (expertReviewStatus.startedById == getUserId() ||
                            checkPermission(
                                Privilege.CermAssessmentExpertReviewFinish,
                                permissions
                            ))))
            );
        }

        return true;
    };

    React.useEffect(() => {
        return () => {
            props.clearForm();
        };
    }, []);

    React.useEffect(() => {
        if (!isEmpty(users)) {
            setMandatoryApproverIds(getMandatoryApproverIds());
        } else {
            loadPersonsLookup(siteId);
        }
    }, [users]);

    React.useEffect(() => {
        loadPersonsBySiteAndPrivilege(siteId, Privilege.RiskMacroLevelUpdate);
    }, [siteId]);

    React.useEffect(() => {
        if (!isEmpty(lookupSiteFeatures) && currentSiteId && riskId) {
            if (isMacro) {
                props.loadMacroRisk(riskId);
            } else {
                props.loadRisk(riskId, currentSiteId);
            }
        }
    }, [currentSiteId, riskId, props.submitActionToRisk]);

    React.useEffect(() => {
        if (!isEmpty(lookupSiteFeatures) && currentSiteId && riskId) {
            const shard = isMacro ? ShardValue.MacroRisk : currentSiteId;
            props.loadAttachments({ entity: Entity.Risk, id: riskId, siteId: shard });
        }
    }, [currentSiteId, riskId]);

    React.useEffect(() => {
        if (risk && risk.id) {
            setCurrentSharedToClient(risk.sharedWithClient);
            setShowAcceptButton(getAcceptButtonStatus());
        }
    }, [risk]);

    React.useEffect(() => {
        if (!getUserId()) {
            return;
        }
        if (match.params.siteId) {
            loadPersonProfile(getUserId());
        }
        if (siteId) {
            loadCermAssessmentExpertReview(siteId);
        }
    }, [siteId]);

    const riskTitle = isReadOnly
        ? `${translate(
            'RiskDetailsTab.Label.Elogbooks.',
            risk.title.split('-')[0]
        )} - ${risk.title.split('-').slice(1).join('-')}`
        : risk.title;
    const riskUid = !!risk.uid ? `(${risk.uid}) - ` : ``;
    const title =
        riskId && riskTitle ? `${riskUid}${riskTitle}` : translate('RiskRegisterUpsertPage.Title');

    const getMandatoryApproverIds = () => {
        const approvers = users.filter(c => c.approvers.some(s => s === risk.siteId)) || [];
        return approvers.map(m => m.id);
    };

    const setNavLink = (selectedTab: Tab) => {
        if (riskId) {
            return match.path
                .replace(':id?', riskId)
                .replace(':tab', selectedTab)
                .replace(':siteId?', '');
        }

        return match.path.replace(':id?/', '').replace(':tab', selectedTab).replace(':siteId?', '');
    };

    const onSubmit = (value: Partial<IRisk>) => {
        if (value.id) {
            if (isMacro) {
                props.updateMacroRisk(value);
            } else {
                props.updateRisk(value);
            }
        } else {
            if (isMacro) {
                props.createMacroRisk(value);
            } else {
                props.createRisk(value);
            }
        }
    };

    const handleTabClick = (event: any, tab: Tab) => {
        event.preventDefault();
        if (!risk) {
            setShowTabDisabledDialog(true);
            return;
        }

        if (!isMacroValid) {
            return;
        }

        formikBag.validateForm().then(errors => {
            if (
                !Object.keys(errors).some(field => field.length > 0) ||
                formikBag.initialValues.riskStatus == RiskStatus.CLOSED
            ) {
                history.push(setNavLink(tab));
            }
        });
    };

    const onSave = () => {
        setShowClientFacingDialog(false);
        formikBag.validateForm().then(() => {
            if (isValid && isMitigationValid && isMacroValid) {
                formikBag.submitForm();
                setCommentSave(true);
            }
        });
    };

    const onResetRisk = () => {
        props.resetRisk(risk);
    };

    const onSaveButtonClick = () => {
        if (!currentSharedToClient && formikBag.values.sharedWithClient) {
            setMessageText(getClientFacingConfirmMessage(formikBag));
            setShowClientFacingDialog(true);
        } else {
            onSave();
        }
    };

    const getAcceptButtonStatus = () => {
        return (
            risk.riskStatus === RiskStatus.OPEN_AND_PENDING_REVIEW &&
            !risk.sharedWithClient &&
            (!risk.mitigationPlans ||
                risk.mitigationPlans.length === 0 ||
                (risk.mitigationPlans.length > 0 &&
                    !risk.mitigationPlans.some(
                        x => x.status !== RiskMitigationPlanStatus.Completed
                    )))
        );
    };

    const onAcceptRisk = () => {
        if (!IsAcceptValid) {
            return;
        }

        formikBag.validateForm().then(() => {
            if (isValid) {
                props.acceptRisk({
                    ...formikBag.values,
                    reviewDate: acceptDialogReviewDate,
                });

                setAcceptDialogReviewDate(null);
            }
        });
    };

    const onReviewDateChange = () => {
        setShowAcceptButton(getAcceptButtonStatus());
    };

    const onCreateMitigationPlan = () => {
        formikBag.validateForm().then(() => {
            if (isValid) {
                props.createRiskMitigationPlan(formikBag.values);
            }
        });
    };

    const getClientFacingConfirmMessage = (formik: FormikState<IRisk>) => {
        const optionalUserIds = formik.values.cbreOptionalApprovers || [];
        const cbreApproversDetails = getPersonInfo(
            formik.values.siteId,
            mandatoryApproverIds.concat(optionalUserIds),
            users,
            false
        );

        return (
            <>
                <p>{translate('RiskRegisterUpsertPage.GetClientFacingConfirmMessage.Message')}</p>
                {cbreApproversDetails && (
                    <ul>
                        {cbreApproversDetails.map((approver, index) => (
                            <li key={index}>
                                {approver.fullName} ({approver.email})
                            </li>
                        ))}
                    </ul>
                )}
            </>
        );
    };

    const getCreateMitigationButton = () => {
        return (
            <>
                {risk.riskStatusId === RiskStatus.OPEN_AND_PENDING_REVIEW &&
                    (!risk.mitigationPlans ||
                        risk.mitigationPlans.length === 0 ||
                        risk.mitigationPlans.every(
                            x => x.status === RiskMitigationPlanStatus.Completed
                        )) && (
                        <Button
                            id="CreateMitigationPlanButton"
                            buttonType="save"
                            onClick={onCreateMitigationPlan}
                            isLoading={isLoading}
                            type="submit"
                            className="standout-workflow-button"
                        >
                            {translate('RiskRegisterUpsertPage.Button.CreateMitigationPlan')}
                        </Button>
                    )}
            </>
        );
    };

    const renderTab = (formik: FormikProps<IRisk>) => {
        formikBag = formik;
        setIsValid(formik.isValid);

        switch (currentTab) {
            case Tab.details:
                return isRiskActiveAndNotClose() ? (
                    <RiskDetailsTab
                        riskId={riskId}
                        formikBag={formikBag}
                        commentSave={commentSave}
                        isReadOnly={isReadOnly}
                        users={users}
                        canEditInExpertReview={canEditInExpertReview}
                        onReviewDateChange={onReviewDateChange}
                        isMitigationValid={isMitigationValid}
                        setIsMitigationValid={setIsMitigationValid}
                        isMacro={isMacro}
                        setIsMacro={setIsMacro}
                        setIsMacroValid={setIsMacroValid}
                    />
                ) : (
                    <ClientRiskDetailsTab risk={risk} />
                );
            case Tab.documents:
                return <RiskDocumentsTab id={riskId} />;
            case Tab.history:
                return <RiskHistoryTab id={riskId} siteId={siteId} />;
        }
    };

    const isRiskActiveAndNotClose = () => {
        return risk.isActive && risk.riskStatusId !== RiskStatus.CLOSED;
    };

    const hasParentIncident = () => {
        return risk.parents && risk.parents.some(x => x.bucket === 'Incident');
    };

    const onParentIncidentButtonClick = () => {
        if (!hasParentIncident()) {
            return;
        }

        const incidents = risk.parents.filter(x => x.bucket === 'Incident');
        if (incidents.length === 1) {
            history.push(`/Incident/${incidents[0].id}/details`);
        } else {
            setLinkedIncidentsListDialogVisible(true);
        }
    };

    const getLinkedIncidentsList = () => {
        if (!hasParentIncident()) {
            return;
        }

        const incidents = risk.parents.filter(x => x.bucket === 'Incident');

        return (
            <>
                {incidents.map(incident => (
                    <Link to={`/Incident/${incident.id}/details`}>
                        <p>{incident.displayLabel}</p>
                    </Link>
                ))}
            </>
        );
    };

    const onApproval = (result: boolean) => {
        const riskApproval = {
            siteId: risk.siteId,
            riskId: risk.id,
            status: result,
            isClient: false,
        } as IRiskApproval;

        props.cbreApprovalRisk(riskApproval);
    };

    const getApprovalButtons = () => {
        if (
            risk.sharedWithClient &&
            (isSiteApprover(siteId) ||
                risk.cbreOptionalApprovers.some(userId => userId === getUserId())) &&
            !risk.cbreApprovalToShare
        ) {
            return (
                <>
                    <ButtonWithConfirmDialog
                        buttonClassName="standout-workflow-button"
                        buttonText={
                            <div>
                                <p className="standout-workflow-button-main-text">
                                    {translate('RiskRegisterUpsertPage.Approval.Label.ApproveRisk')}
                                </p>
                                {translate('RiskRegisterUpsertPage.Approval.Label.NotifyClient')}
                            </div>
                        }
                        buttonConfirmText={translate(
                            'RiskRegisterUpsertPage.Approval.Label.ShareClient'
                        )}
                        title={translate('RiskRegisterUpsertPage.Approval.Title.RiskApproved')}
                        message={
                            <>
                                <p>
                                    <strong>
                                        {translate(
                                            'RiskRegisterUpsertPage.Approval.Title.ShareWithClient'
                                        )}
                                    </strong>
                                </p>
                                {risk && risk.clientApproversDetails && (
                                    <ul>
                                        {risk.clientApproversDetails.map((client, index) => (
                                            <li key={index}>
                                                {client.fullName} ({client.email})
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </>
                        }
                        onConfirm={() => onApproval(true)}
                        isLoading={isLoading}
                    />
                    <Button
                        buttonType="action"
                        onClick={() => onApproval(false)}
                        isLoading={isLoading}
                        className="standout-workflow-button"
                    >
                        <div>
                            <p className="standout-workflow-button-main-text">
                                {translate('RiskRegisterUpsertPage.Approval.Title.RejectRisk')}
                            </p>
                            {translate('RiskRegisterUpsertPage.Approval.Title.RejectDetails')}
                        </div>
                    </Button>
                </>
            );
        }
    };

    const getCloseRiskButton = () => {
        if (
            riskId &&
            risk &&
            risk.riskStatusId &&
            (risk.riskStatusId === RiskStatus.OPEN_AND_PENDING_REVIEW ||
                risk.riskStatusId === RiskStatus.MITIGATION_PENDING_MITIGATION ||
                risk.riskStatusId === RiskStatus.ACCEPTED)
        ) {
            const commonProps = {
                buttonId: 'closeRisk',
                buttonText: translate('RiskRegisterUpsertPage.GetCloseRiskButton.ButtonText'),
                title: translate('RiskRegisterUpsertPage.GetCloseRiskButton.Title'),
                onConfirm: () => props.closeRisk(risk),
                isLoading,
            };

            if (risk.masterProcessStepId) {
                return (
                    <ButtonWithCheckboxConfirmDialog
                        message={
                            <p>
                                <strong>
                                    {translate(
                                        `${risk.origin && risk.origin.includes('HERM')
                                            ? 'RiskRegisterUpsertPage.GetCloseRiskButton.ButtonWithCheckboxConfirmDialog.MessageHERM'
                                            : 'RiskRegisterUpsertPage.GetCloseRiskButton.ButtonWithCheckboxConfirmDialog.MessageCERM'
                                        }`
                                    )}
                                </strong>
                            </p>
                        }
                        checkboxLabel={translate(
                            `${risk.origin && risk.origin.includes('HERM')
                                ? 'RiskRegisterUpsertPage.GetCloseRiskButton.ButtonWithCheckboxConfirmDialog.CheckboxLabelHERM'
                                : 'RiskRegisterUpsertPage.GetCloseRiskButton.ButtonWithCheckboxConfirmDialog.CheckboxLabelCERM'
                            }`
                        )}
                        {...commonProps}
                    />
                );
            }

            return (
                <ButtonWithConfirmDialog
                    message={translate(
                        'RiskRegisterUpsertPage.GetCloseRiskButton.ButtonWithConfirmDialog.Message'
                    )}
                    {...commonProps}
                />
            );
        }

        return null;
    };

    const getArchiveButton = () => {
        if (!canEditInExpertReview() || !riskId || !risk || !risk.isActive || !allowMacroConfig) {
            return null;
        }

        let message = <>{translate('RiskRegisterUpsertPage.GetArchiveButton.Message')}</>;
        let checkboxLabel = translate('RiskRegisterUpsertPage.GetArchiveButton.CheckboxLabel');
        if (risk.masterProcessStepId) {
            message = (
                <p>
                    <strong>
                        {translate(
                            `${risk.origin && risk.origin.includes('HERM')
                                ? 'RiskRegisterUpsertPage.GetArchiveButton.MasterProcessStepIdMessageHERM'
                                : 'RiskRegisterUpsertPage.GetArchiveButton.MasterProcessStepIdMessageCERM'
                            }`
                        )}
                    </strong>
                </p>
            );
            checkboxLabel = translate(
                `${risk.origin && risk.origin.includes('HERM')
                    ? 'RiskRegisterUpsertPage.GetArchiveButton.MasterProcessStepIdCheckboxLabelHERM'
                    : 'RiskRegisterUpsertPage.GetArchiveButton.MasterProcessStepIdCheckboxLabelCERM'
                }`
            );
        }

        return (
            <ButtonWithCheckboxConfirmDialog
                buttonId="archive"
                buttonText={translate(
                    'RiskRegisterUpsertPage.ButtonWithCheckboxConfirmDialog.buttonText'
                )}
                buttonType="archive"
                title={translate('RiskRegisterUpsertPage.ButtonWithCheckboxConfirmDialog.Title')}
                message={message}
                checkboxLabel={checkboxLabel}
                onConfirm={archiveActions => isMacro
                    ? props.removeMacroRisk(riskId)
                    : props.removeRisk(riskId, siteId, archiveActions)
                }
                isLoading={isLoading}
            />
        );
    };

    const getResetButton = () => {
        if (riskId &&
            risk &&
            risk.riskStatusId !== RiskStatus.CLOSED &&
            checkPermission(Privilege.RiskReset, permissions)) {
            return (
                <ButtonWithCheckboxConfirmDialog
                    message={
                        <p>
                            <strong>
                                {translate('RiskRegisterUpsertPage.ResetRisk.Message')}
                            </strong>
                        </p>
                    }
                    checkboxLabel={translate('RiskRegisterUpsertPage.ResetRisk.ClickToConfirm')}
                    buttonId='resetRisk'
                    buttonText={translate('RiskRegisterUpsertPage.ResetRisk.ButtonText')}
                    title={translate('RiskRegisterUpsertPage.ResetRisk.Title')}
                    onConfirm={onResetRisk}
                    isLoading={isLoading}
                    buttonDisabled={
                        !(!risk.mitigationPlans ||
                            risk.mitigationPlans.length === 0 ||
                            (risk.mitigationPlans.length > 0 &&
                                risk.mitigationPlans.every(
                                    x => x.status === RiskMitigationPlanStatus.Completed
                                )))
                    }
                />
            );
        }
    };

    const handleAcceptDialogReviewDateChange = (newDate: Date) => {
        validateAcceptDialogReviewDate(newDate);
        setAcceptDialogReviewDate(newDate);
    };

    const validateAcceptDialogReviewDate = (reviewDate: Date) => {
        if (getDatePartOfDateTime(reviewDate) <= getDatePartOfDateTime(new Date())) {
            setAcceptDialogReviewDateError('Review date must be in the future');
        } else {
            setAcceptDialogReviewDateError(null);
        }
    };

    const IsAcceptValid = acceptDialogReviewDate && !acceptDialogReviewDateError;

    const getAcceptDialogBody = () => {
        return (
            <>
                <p>{translate('RiskRegisterUpsertPage.AcceptWithConfirmDialog.Message')}</p>
                <br />
                <DatePickerField
                    id="updatedReviewDate"
                    label={translate('RiskDetailsPage.label.Reviewdate')}
                    value={acceptDialogReviewDate}
                    error={acceptDialogReviewDateError}
                    handleChange={handleAcceptDialogReviewDateChange}
                    handleBlur={formikBag?.handleBlur}
                    minDate={moment(new Date()).add(1, 'day').toDate()}
                />
            </>
        );
    };

    const getAcceptButton = () => {
        return (
            <>
                {showAcceptButton && (
                    <>
                        <ButtonWithConfirmDialog
                            buttonClassName="standout-workflow-button"
                            buttonId="AcceptRiskButton"
                            buttonText={translate(
                                'RiskRegisterUpsertPage.AcceptWithConfirmDialog.ButtonText'
                            )}
                            buttonType="save"
                            title={translate(
                                'RiskRegisterUpsertPage.AcceptWithConfirmDialog.Title'
                            )}
                            message={getAcceptDialogBody()}
                            onConfirm={onAcceptRisk}
                            confirmButtonDisabled={!IsAcceptValid}
                            isLoading={isLoading}
                        />
                    </>
                )}
            </>
        );
    };

    const getGoToIncidentButton = () => {
        return (
            <>
                {hasParentIncident() && (
                    <>
                        <Button
                            id="incidentLink"
                            buttonType="action"
                            isLoading={isLoading}
                            onClick={onParentIncidentButtonClick}
                        >
                            {translate('RiskRegisterUpsertPage.Button.IncidentLink')}
                        </Button>

                        <ConfirmDialog
                            title={translate(
                                'RiskRegisterUpsertPage.Button.IncidentLinkConfirmTitle'
                            )}
                            message={getLinkedIncidentsList()}
                            isVisible={linkedIncidentsListDialogVisible}
                            showConfirmButton={false}
                            onClose={() => setLinkedIncidentsListDialogVisible(false)}
                            onOutsideDialogClick={() => setLinkedIncidentsListDialogVisible(false)}
                            buttonCancelText="Cancel"
                        />
                    </>
                )}
            </>
        );
    };

    const getCircleOneText = (risk: IRisk): string => {
        switch (risk.riskStatus) {
            case RiskStatus.OPEN_AND_PENDING_REVIEW:
                return translate('Globals.Status.Open&PendingReview');
            case RiskStatus.ACCEPTED:
                return translate('Globals.Status.Open&RiskAccepted');
            default:
                return translate('Globals.Status.Open&PendingReview');
        }
    };

    const getCircleTwoText = (risk: IRisk): string => {
        switch (risk.riskStatus) {
            case RiskStatus.MITIGATION_PENDING_INTERNAL_APPROVAL:
                return translate('Globals.Status.MitigationLevel.PendingInternalApproval');
            case RiskStatus.MITIGATION_PENDING_CLIENT_APPROVAL:
                return translate('Globals.Status.MitigationLevel.PendingClientApproval');
            case RiskStatus.MITIGATION_PENDING_MITIGATION:
                return translate('Globals.Status.MitigationLevel.PendingMitigation');
            default:
                return translate('Globals.Status.MitigationLevel.PendingMitigation');
        }
    };

    const isCircleTwoActive = (risk: IRisk): boolean => {
        switch (risk.riskStatus) {
            case RiskStatus.OPEN_AND_PENDING_REVIEW:
            case RiskStatus.ACCEPTED:
                return false;
            default:
                return true;
        }
    };

    const getReopenClosedRiskButton = () => {
        return (
            <>
                {risk &&
                    !isReadOnly &&
                    risk.isActive &&
                    risk.riskStatus === RiskStatus.CLOSED &&
                    allowMacroConfig &&
                    canEditInExpertReview() && (
                        <ButtonWithConfirmDialog
                            buttonId="OpenClosedRiskButton"
                            buttonText={translate(
                                'RiskRegisterUpsertPage.ButtonWithConfirmDialog.ButtonText'
                            )}
                            buttonType="archive"
                            title={translate(
                                'RiskRegisterUpsertPage.ButtonWithConfirmDialog.Title'
                            )}
                            message={translate(
                                'RiskRegisterUpsertPage.ButtonWithConfirmDialog.Message'
                            )}
                            onConfirm={() => props.openClosedRisk(risk)}
                            isLoading={isLoading}
                        />
                    )}
            </>
        );
    };

    return (
        <Page
            title={title}
            breadcrumbCustomLinks={customLinks}
            className="risk-register-page"
            redirectOnSiteChange={match.params.siteId ? false : true}
            redirectOnSiteChangeUrl={risksPageUrl}
        >
            {!canEditInExpertReview() && risk.masterProcessStepId && (
                <Alert type="warning">
                    <p>
                        {expertReviewStatus &&
                            `${translate('Alert.Message.Youreinreviewmode')}
                        ${expertReviewStatus.startDate.toDateString()}
                        ${expertReviewStatus.startedByName &&
                            ' by ' + expertReviewStatus.startedByName + '.'
                            }
                        ${translate('Alert.Message.Youarenotallowedtoeditit')}`}
                    </p>
                </Alert>
            )}
            <Loader loading={isLoading}>
                <CbreApproval isLoading={isLoading} siteId={siteId} risk={risk} />
                {risk && risk.id && (
                    <div className="horizontal-layout width-80">
                        <RiskCircleStatus text={getCircleOneText(risk)} active={true} />
                        <RiskArrow text={''} active={isCircleTwoActive(risk)} />
                        <RiskCircleStatus
                            text={getCircleTwoText(risk)}
                            active={isCircleTwoActive(risk)}
                        />
                        <RiskArrow text={''} active={risk.riskStatus == RiskStatus.CLOSED} />
                        <RiskCircleStatus
                            text={translate('Globals.Status.Closed')}
                            active={risk.riskStatus == RiskStatus.CLOSED}
                        />
                    </div>
                )}
                <br />

                <TabGroup>
                    <TabItem
                        title={translate('RiskRegisterUpsertPage.Tabs.Details')}
                        tab={Tab.details}
                        currentTab={currentTab}
                        onClick={handleTabClick}
                    />
                    {!isReadOnly ? (
                        <>
                            {props.attachments &&
                                props.attachments.filter(x => x.id.includes(riskId)).length > 0 && (
                                    <TabItem
                                        title={translate('RiskRegisterUpsertPage.Tabs.Documents')}
                                        tab={Tab.documents}
                                        currentTab={currentTab}
                                        onClick={handleTabClick}
                                    />
                                )}

                            {riskId && risk && (
                                <TabItem
                                    title={translate('RiskRegisterUpsertPage.Tabs.History')}
                                    tab={Tab.history}
                                    currentTab={currentTab}
                                    onClick={handleTabClick}
                                />
                            )}
                        </>
                    ) : null}
                </TabGroup>

                <Formik<Partial<IRisk>>
                    initialValues={risk}
                    onSubmit={onSubmit}
                    validationSchema={riskDetailsFormValidationSchema}
                    render={renderTab}
                    enableReinitialize={true}
                    isInitialValid={riskId ? true : false}
                    validateOnBlur={true}
                    validateOnChange={true}
                />
                <ConfirmDialog
                    title={translate('RiskRegisterUpsertPage.ConfirmDialog.Title')}
                    message={translate('RiskRegisterUpsertPage.ConfirmDialog.Message')}
                    buttonCancelHide={true}
                    buttonConfirmText={translate(
                        'RiskRegisterUpsertPage.ConfirmDialog.ButtonConfirmText'
                    )}
                    isVisible={showTabDisabledDialog}
                    onConfirm={() => setShowTabDisabledDialog(false)}
                    onOutsideDialogClick={() => setShowTabDisabledDialog(false)}
                />
                {riskId &&
                    risk &&
                    currentTab === Tab.details &&
                    (risk.riskStatusId === RiskStatus.OPEN_AND_PENDING_REVIEW ||
                        risk.riskStatusId === RiskStatus.ACCEPTED ||
                        risk.riskStatusId === RiskStatus.MITIGATION_PENDING_INTERNAL_APPROVAL ||
                        risk.riskStatusId === RiskStatus.MITIGATION_PENDING_MITIGATION) &&
                    allowMacroConfig &&
                    risk.isActive && (
                        <>
                            <div className="standout-workflow">
                                {getAcceptButton()}
                                {getCreateMitigationButton()}
                                {getApprovalButtons()}
                            </div>
                        </>
                    )}
                <div className="columns columns-buttons">
                    <div className="column">
                        <Button
                            id="cancel"
                            buttonType="cancel"
                            isLoading={isLoading}
                            onClick={() => history.push(risksPageUrl)}
                        >
                            {translate('RiskRegisterUpsertPage.Button.Cancel')}
                        </Button>
                        {getArchiveButton()}
                        {getReopenClosedRiskButton()}
                        {getGoToIncidentButton()}
                    </div>
                    {!isReadOnly &&
                        risk.isActive &&
                        canEditInExpertReview() &&
                        isRiskActiveAndNotClose() &&
                        allowMacroConfig && (
                            <>
                                <div className="column column-save">
                                    {getResetButton()}
                                    {getCloseRiskButton()}
                                    <Button
                                        id="save"
                                        buttonType="save"
                                        onClick={onSaveButtonClick}
                                        isLoading={isLoading}
                                        type="submit"
                                    >
                                        {translate('RiskRegisterUpsertPage.Button.Save')}
                                    </Button>
                                </div>

                                <ConfirmDialog
                                    title={translate(
                                        'RiskRegisterUpsertPage.ConfirmDialog.ConfirmTitle'
                                    )}
                                    message={messageText}
                                    onConfirm={onSave}
                                    onClose={() => setShowClientFacingDialog(false)}
                                    onOutsideDialogClick={() => setShowClientFacingDialog(false)}
                                    buttonCancelText={translate(
                                        'RiskRegisterUpsertPage.ConfirmDialog.ButtonCancelText'
                                    )}
                                    isVisible={showClientFacingDialog}
                                />
                            </>
                        )}
                </div>
            </Loader>
        </Page>
    );
};

export default withRouter(connect(selectors, actions)(RiskRegisterUpsertPage));
