//import { LocationChangeAction } from 'connected-react-router';
import { RouterActions } from "redux-first-history";

import { CermSiteOverviewFilterActions, CermSiteOverviewFilterActionTypes } from './filters';
import { CermSiteOverviewGridActions, CermSiteOverviewGridActionTypes } from './grid';

export * from './filters';
export * from './grid';

export const CermSiteOverviewActions = {
    ...CermSiteOverviewFilterActions,
    ...CermSiteOverviewGridActions
};

export type CermSiteOverviewActionTypes =
    //| LocationChangeAction
    | RouterActions
    | CermSiteOverviewFilterActionTypes
    | CermSiteOverviewGridActionTypes;
