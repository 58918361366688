import React from 'react';
import { IProgressBarModel } from 'components/step-progress-bar/StepProgressBar';
import { IStateResult, State } from '../State';
import { QuestionAdiabaticCoolingDae } from './question-adiabatic-cooling-dae';
import { QuestionAdiabaticCoolingIae } from './question-adiabatic-cooling-iae';
import { QuestionCooledChillers } from './question-cooled-chillers';
import { QuestionDxType } from './question-dx-type';

export class QuestionSiteType extends State {
    public result: IStateResult;
    private title: string = 'What type of cooling plant does your site have?';

    private systemType: SystemType;
    constructor() {
        super();
        this.handleClick = this.handleClick.bind(this);
    }
    public path(): string {
        return 'QuestionSiteType';
    }

    public display(): JSX.Element {
        return (
            <div >
                <div className="is-size-3  has-text-centered">
                    {this.title}
                </div>
                <p className="has-text-centered">
                    Please choose the cooling system type which most closely matches how your
                    site rejects heat from the IT equipment and support spaces such as UPS rooms
                </p>
                <hr />
                <div className="formBody">
                    <div className="field">
                        <div className="control">
                            <input
                                className="radioButton"
                                type="radio"
                                name="answer"
                                id={SystemType.ColdWaterLoop}
                                value={SystemType.ColdWaterLoop}
                                onClick={this.handleClick}
                            />
                            <label htmlFor={SystemType.ColdWaterLoop} className="radio is-size-5">
                                Chilled water loop
                            </label>
                            <p>
                                Primarily through a chilled water loop feeding CRAC / CRAH
                                or AHUs which supply air to the data halls and support spaces
                            </p>
                        </div>
                    </div>
                    <div className="field">
                        <div className="control">
                            <input
                                className="radioButton"
                                type="radio"
                                name="answer"
                                id={SystemType.DxSystem}
                                value={SystemType.DxSystem}
                                onClick={this.handleClick}
                            />
                            <label htmlFor={SystemType.DxSystem} className="radio is-size-5">
                                DX cooling
                            </label>
                            <p>
                                Primarily through local DX (Direct eXpansion) compressors in CRAC / CRAH units
                                in the cooled spaces with either external gas condensers or a common pumped
                                glycol loop to external dry coolers or cooling towers
                            </p>
                        </div>
                    </div>
                    <div className="field">
                        <div className="control">
                            <input
                                className="radioButton"
                                type="radio"
                                name="answer"
                                id={SystemType.DirectAir}
                                value={SystemType.DirectAir}
                                onClick={this.handleClick}
                            />
                            <label htmlFor={SystemType.DirectAir} className="radio is-size-5">

                                Direct outside air economised
                            </label>
                            <p>
                                Primarily through direct supply of outside air to the cooled spaces,
                                with or without additional adiabatic or mechanical cooling and humidity contro
                            </p>
                        </div>
                    </div>
                    <div className="field">
                        <div className="control">
                            <input
                                className="radioButton"
                                type="radio"
                                name="answer"
                                id={SystemType.IndirectAir}
                                value={SystemType.IndirectAir}
                                onClick={this.handleClick}
                            />
                            <label htmlFor={SystemType.IndirectAir} className="radio is-size-5">

                                Indirect outside air economised
                            </label>
                            <p>
                                Primarily through passing air from the cooled spaces through a heat exchanger
                                unit with outside air to provide 'free' cooling whilst recirculating internal air,
                                with or without additional adiabatic or mechanical cooling
                            </p>
                        </div>
                    </div>
                </div>
                <hr />
                {this.context.footer(this.handle.bind(this), true)}
            </div>);
    }

    public handle() {

        if (this.systemType) {
            this.result = {
                title: this.title,
                value: this.systemType.toString()
            };
            switch (this.systemType) {
                case SystemType.ColdWaterLoop:
                    this.context.transitionTo(new QuestionCooledChillers());
                    break;
                case SystemType.DxSystem:
                    this.context.transitionTo(new QuestionDxType());
                    break;
                case SystemType.DirectAir:
                    this.context.transitionTo(new QuestionAdiabaticCoolingDae());
                    break;
                case SystemType.IndirectAir:
                    this.context.transitionTo(new QuestionAdiabaticCoolingIae());
                    break;
            }
        }

    }
    public getProgressBarModel(): IProgressBarModel[] {
        return [
            { isActive: true, name: 'Type of site' },
            { isActive: false, name: 'Question 1' },
            { isActive: false, name: 'Question 2' },
            { isActive: false, name: 'Question 3' },
            { isActive: false, name: 'Question 4' },
            { isActive: false, name: 'Summary' },
        ] as IProgressBarModel[];
    }
    private handleClick(event: any) {
        this.context.isDisable = false;
        this.systemType = event.currentTarget.value as SystemType;
        this.context.handleRender();
    }
}

export enum SystemType {
    ColdWaterLoop = 'Cold Water Loop',
    DxSystem = 'DX System',
    DirectAir = 'Direct Air Economised System',
    IndirectAir = 'Indirect Air Economised System'
}
