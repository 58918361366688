import * as React from 'react';
import { CheckboxFilter } from 'components/checkboxFilter';
import { IKeyValue } from 'models/key-value';
import { IRiskAssessmentFilters } from 'models/risk-assessment-filters';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';

interface IStateProps {
    filters: IRiskAssessmentFilters;
    expandedFilters: string[];

    lookupCategoryFilters: Array<IKeyValue<string>>;
    lookupUrgencyFilters: Array<IKeyValue<string>>;
    lookupOtherFilters: Array<IKeyValue<string>>;
}

interface IDispatchProps {
    onFilter: (field: string, value: string) => void;
    onToggleFilterExpanded: (id: string) => void;
}

interface IProps extends IStateProps, IDispatchProps {
    intl: any;
}

class RiskAssessmentFiltersClass extends React.PureComponent<IProps> {
    public componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);

        urlParams.forEach((element, key) => {
            this.props.onFilter(key, element);
        });
    }

    public render() {
        const intl = this.props.intl;
        const commonProps = {
            expandedFilters: this.props.expandedFilters,
            onFilter: this.props.onFilter,
            onToggleFilterExpanded: this.props.onToggleFilterExpanded,
        };

        return (
            <>
                <CheckboxFilter
                    {...commonProps}
                    title={translate(intl, 'RiskRegisterPage.RiskFilter.Title.Category')}
                    name="filterCategory"
                    selectedFilters={this.props.filters.filterCategory}
                    unreservedFilters={this.props.lookupCategoryFilters}
                />

                <CheckboxFilter
                    {...commonProps}
                    title={translate(intl, 'RiskAssessmentFilters.CheckBoxFilters.Urgency')}
                    name="filterUrgency"
                    selectedFilters={this.props.filters.filterUrgency}
                    unreservedFilters={this.props.lookupUrgencyFilters}
                />
            </>
        );
    }
}

export const RiskAssessmentFilters = injectIntl(RiskAssessmentFiltersClass);