import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { getSitesWithFeatures, getFilteredLookupSitePeopleWithAllowedRoles } from 'sharedSelectors';
import { ActionDetailsForm } from './actions-details-form';
import { checkPermission } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';

const getCategories = (state: IRootState) => state.app.lookupActionCategories;
const getPriorities = (state: IRootState) => state.app.lookupActionPriorities;
const getActionStatuses = (state: IRootState) => state.app.lookupActionStatus;
const getActionAutoFieldUpdatePermission = (state: IRootState) => checkPermission(
    Privilege.ActionAutoFieldUpdate,
    state.app.permissions);

const mapStateToProps = createSelector(
    getCategories,
    getPriorities,
    getActionStatuses,
    getFilteredLookupSitePeopleWithAllowedRoles,
    getSitesWithFeatures,
    getActionAutoFieldUpdatePermission,
    (categories, priorities, actionStatuses, lookupSitePeopleRoles, lookupSiteFeatures, actionAutoFieldUpdatePermission) => ({
        categories,
        priorities,
        actionStatuses,
        lookupSitePeopleRoles,
        lookupSiteFeatures,
        actionAutoFieldUpdatePermission,
    })
);

export const ActionDetailsFormContainer = connect(mapStateToProps, null)(ActionDetailsForm);
