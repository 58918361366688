import { connect } from 'react-redux';

import actions from './actions';
import { AttachPersonForm as View } from './attach-person-form';
import selector from './selector';

export const AttachPersonForm = connect(
    selector,
    actions
)(View);
