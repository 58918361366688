import { SiteRelationshipsActions, SiteRelationshipsActionTypes } from 'actions/site-relationships-actions';
import { ITypeaheadItem } from 'components/typeahead';
import { IPerson } from 'models/person';

export default {
    onValueChanged: (value: string): SiteRelationshipsActionTypes => ({
        type: SiteRelationshipsActions.CHANGE_PERSON_SEARCH_VALUE,
        payload: {
            value
        }
    }),

    onSelectionChanged: (selection: ITypeaheadItem<IPerson>): SiteRelationshipsActionTypes => ({
        type: SiteRelationshipsActions.SELECT_PERSON,
        payload: {
            person: selection.value
        }
    })
};
