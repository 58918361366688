import {
    ManageRoleActions,
    ManageRoleActionTypes,
    onDeleteRole,
    onEditRolePrivileges,
    onShowRoleModal
} from 'actions/manage-role-actions';
import { loadRoles } from 'actions/actions-v2/role-actions-v2';

export default {
    onSort: (sortColumn: string, sortAscending: boolean): ManageRoleActionTypes => ({
        type: ManageRoleActions.CHANGE_SORT_ORDER_VALUE_ROLES_TAB,
        payload: {
            sortColumn,
            sortAscending
        }
    }),
    onShowRoleModal,
    onDeleteRole,
    onEditRolePrivileges,
    loadRoles
};
