import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import { map, mergeMap, catchError, takeUntil, debounceTime } from 'rxjs/operators';
import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax';
import { IAppAction } from 'actions/app-action';
import queryString from 'querystring';
import { toastr } from 'react-redux-toastr';
import { IResponseMessage } from 'models/reponse/response-message';
import { ResponseMessageType } from 'models/reponse/response-message-type';
import { IValidationFailure } from 'models/reponse/response-validation-failure';
import { onRouteChange } from 'actions/app-actions';
import { IResponse } from 'models/reponse/response';
import { ILogUploadModel } from 'models/logs-upload';
import { getApiHeaders, getHeadersWithoutContentType } from './shared-header';
import { of } from 'rxjs';
import appConfig from 'helpers/config-helper';
import { getIntl, translate, translationApiRegex } from 'translations/translation-utils';
import { IntlShape } from 'react-intl';

const config = appConfig();
const url = config.REACT_APP_BASE_API;

export interface IActionType {
    action: string;
    optionalAction?: string;
    optionalAction2?: string;

    actionFulfilled: string;
    actionCancelled: string;
    actionRejected: string;
}

export interface INotificationMessage {
    title: string;
    message: string;
}

export class BaseEpic {
    protected action$: ActionsObservable<any>;
    protected state$: StateObservable<any>;
    private actionTypes: IActionType;
    private reloadUrl: string;
    private blobContentType = 'blob';
    private intl: IntlShape;

    constructor(action: ActionsObservable<any>, state: StateObservable<any>, actionTypes: IActionType, reloadUrl = null) {
        this.action$ = action;
        this.state$ = state;
        this.actionTypes = actionTypes;
        this.reloadUrl = reloadUrl;

        if (this.state$) {
            this.state$.subscribe(state => {
                this.intl = getIntl(state.person?.personProfile?.language ?? 'en-GB');
            });
        }
        else {
            this.intl = getIntl('en-GB');
        }
    }

    public getById<T>(path: string) {
        return this.action$.pipe(
            ofType(this.actionTypes.action),
            mergeMap((action: IAppAction) =>
                ajax.getJSON(`${url}${path}/${this.getId(action)}`, this.getHeaders(action)).pipe(
                    map((response: IResponse<T>) =>
                        this.handleResponse<T>(response, this.actionTypes.actionFulfilled)
                    ),
                    takeUntil(this.action$.pipe(ofType(this.actionTypes.actionCancelled))),
                    catchError(error => this.handleError(error, this.actionTypes.actionRejected))
                )
            )
        );
    }

    public getByOperatingPlatform<T>(path: string) {
        return this.action$.pipe(
            ofType(this.actionTypes.action),
            mergeMap((action: IAppAction) =>
                ajax
                    .getJSON(
                        `${url}${path}/${this.getOperatingPlatform(action)}`,
                        this.getHeaders(action)
                    )
                    .pipe(
                        map((response: IResponse<T>) =>
                            this.handleResponse<T>(response, this.actionTypes.actionFulfilled)
                        ),
                        takeUntil(this.action$.pipe(ofType(this.actionTypes.actionCancelled))),
                        catchError(error =>
                            this.handleError(error, this.actionTypes.actionRejected)
                        )
                    )
            )
        );
    }

    public getByIdAndOperatngPlatform<T>(path: string) {
        return this.action$.pipe(
            ofType(this.actionTypes.action),
            mergeMap((action: IAppAction) =>
                ajax
                    .getJSON(
                        `${url}${path}/${this.getId(action)}/${this.getOperatingPlatform(action)}`,
                        this.getHeaders(action)
                    )
                    .pipe(
                        map((response: IResponse<T>) =>
                            this.handleResponse<T>(response, this.actionTypes.actionFulfilled)
                        ),
                        takeUntil(this.action$.pipe(ofType(this.actionTypes.actionCancelled))),
                        catchError(error =>
                            this.handleError(error, this.actionTypes.actionRejected)
                        )
                    )
            )
        );
    }

    public getByIdAndShard<T>(path: string) {
        return this.action$.pipe(
            ofType(this.actionTypes.action),
            mergeMap((action: IAppAction) =>
                ajax
                    .getJSON(
                        `${url}${path}/${action.payload.id}/${action.payload.shard}`,
                        this.getHeaders(action)
                    )
                    .pipe(
                        map((response: IResponse<T>) =>
                            this.handleResponse<T>(response, this.actionTypes.actionFulfilled)
                        ),
                        takeUntil(this.action$.pipe(ofType(this.actionTypes.actionCancelled))),
                        catchError(error =>
                            this.handleError(error, this.actionTypes.actionRejected)
                        )
                    )
            )
        );
    }

    public getBySiteIdAndQuery<T>(path: string) {
        return this.action$.pipe(
            ofType(this.actionTypes.action, this.actionTypes.optionalAction),
            mergeMap((action: IAppAction) =>
                ajax
                    .getJSON(
                        `${url}${path}/${action.payload.siteId}?${queryString.stringify(
                            action.payload
                        )}`,
                        this.getHeaders(action)
                    )
                    .pipe(
                        map((response: IResponse<T>) =>
                            this.handleResponse<T>(response, this.actionTypes.actionFulfilled)
                        ),
                        takeUntil(this.action$.pipe(ofType(this.actionTypes.actionCancelled))),
                        catchError(error =>
                            this.handleError(error, this.actionTypes.actionRejected)
                        )
                    )
            )
        );
    }

    public exportById<T>(path: string) {
        return this.action$.pipe(
            ofType(this.actionTypes.action),
            debounceTime(1500),
            mergeMap((action: IAppAction) =>
                ajax
                    .get(
                        `${url}${path}/${action.payload}`,
                        this.getHeaders(action, this.blobContentType)
                    )
                    .pipe(
                        map<AjaxResponse, any>((response: IResponse<T>) =>
                            this.handleResponse<T>(
                                response.response,
                                this.actionTypes.actionFulfilled
                            )
                        ),
                        takeUntil(this.action$.pipe(ofType(this.actionTypes.actionCancelled))),
                        catchError(error =>
                            this.handleError(error, this.actionTypes.actionRejected)
                        )
                    )
            )
        );
    }

    public exportByIdAndOperatingPlatform<T>(path: string) {
        return this.action$.pipe(
            ofType(this.actionTypes.action),
            debounceTime(1500),
            mergeMap((action: IAppAction) =>
                ajax
                    .get(
                        `${url}${path}/${this.getId(action)}/${this.getOperatingPlatform(action)}`,
                        this.getHeaders(action, this.blobContentType)
                    )
                    .pipe(
                        map<AjaxResponse, any>((response: IResponse<T>) =>
                            this.handleResponse<T>(
                                response.response,
                                this.actionTypes.actionFulfilled
                            )
                        ),
                        takeUntil(this.action$.pipe(ofType(this.actionTypes.actionCancelled))),
                        catchError(error =>
                            this.handleError(error, this.actionTypes.actionRejected)
                        )
                    )
            )
        );
    }

    public exportBySiteId<T>(path: string) {
        return this.action$.pipe(
            ofType(this.actionTypes.action),
            debounceTime(1500),
            mergeMap((action: IAppAction) =>
                ajax
                    .get(
                        `${url}${path}/${action.payload.siteId}`,
                        this.getHeaders(action, this.blobContentType)
                    )
                    .pipe(
                        map<AjaxResponse, any>((response: IResponse<T>) =>
                            this.handleResponse<T>(
                                response.response,
                                this.actionTypes.actionFulfilled
                            )
                        ),
                        takeUntil(this.action$.pipe(ofType(this.actionTypes.actionCancelled))),
                        catchError(error =>
                            this.handleError(error, this.actionTypes.actionRejected)
                        )
                    )
            )
        );
    }

    public exportByQueryModel<T>(path: string) {
        return this.action$.pipe(
            ofType(this.actionTypes.action),
            debounceTime(1500),
            mergeMap((action: IAppAction) =>
                ajax
                    .get(
                        `${url}${path}/?${queryString.stringify(action.payload)}`,
                        this.getHeaders(action, this.blobContentType)
                    )
                    .pipe(
                        map<AjaxResponse, any>((response: IResponse<T>) =>
                            this.handleResponse<T>(
                                response.response,
                                this.actionTypes.actionFulfilled
                            )
                        ),
                        takeUntil(this.action$.pipe(ofType(this.actionTypes.actionCancelled))),
                        catchError(error =>
                            this.handleError(error, this.actionTypes.actionRejected)
                        )
                    )
            )
        );
    }

    public export<T>(path: string) {
        return this.action$.pipe(
            ofType(this.actionTypes.action),
            debounceTime(1500),
            mergeMap((action: IAppAction) =>
                ajax.get(`${url}${path}`, this.getHeaders(action, this.blobContentType)).pipe(
                    map<AjaxResponse, any>((response: IResponse<T>) =>
                        this.handleResponse<T>(response.response, this.actionTypes.actionFulfilled)
                    ),
                    takeUntil(this.action$.pipe(ofType(this.actionTypes.actionCancelled))),
                    catchError(error => this.handleError(error, this.actionTypes.actionRejected))
                )
            )
        );
    }
    public get<T>(path: string) {
        return this.action$.pipe(
            ofType(this.actionTypes.action, this.actionTypes.optionalAction),
            mergeMap((action: IAppAction) =>
                ajax
                    .getJSON(
                        `${url}${path}?${queryString.stringify(action.payload)}`,
                        this.getHeaders(action)
                    )
                    .pipe(
                        map((response: IResponse<T>) =>
                            this.handleResponse<T>(response, this.actionTypes.actionFulfilled)
                        ),
                        takeUntil(this.action$.pipe(ofType(this.actionTypes.actionCancelled))),
                        catchError(error =>
                            this.handleError(error, this.actionTypes.actionRejected)
                        )
                    )
            )
        );
    }

    public post<T>(path: string, debounce: boolean = false, message?: INotificationMessage) {
        return this.action$.pipe(
            ofType(this.actionTypes.action, this.actionTypes.optionalAction),
            debounce ? debounceTime(2000) : debounceTime(0),
            mergeMap((action: IAppAction) =>
                ajax.post(`${url}${path}`, action.payload, this.getHeaders(action)).pipe(
                    map<AjaxResponse, any>((response: IResponse<T>) =>
                        this.handleResponse<T>(
                            response.response,
                            this.actionTypes.actionFulfilled,
                            message,
                            action.payload.reloadUrl
                        )
                    ),
                    takeUntil(this.action$.pipe(ofType(this.actionTypes.actionCancelled))),
                    catchError(error => this.handleError(error, this.actionTypes.actionRejected))
                )
            )
        );
    }

    public logUpload<T>(path: string, message?: INotificationMessage) {
        return this.action$.pipe(
            ofType(this.actionTypes.action, this.actionTypes.optionalAction),
            map(action => action.payload),
            map((data: ILogUploadModel) => {
                const formData = new FormData();
                formData.append('siteId', data.siteId);
                formData.append('logType', data.logType);
                formData.append('templateType', data.templateType);
                data.collection.forEach((file: any) => formData.append('collection', file));
                return formData;
            }),
            mergeMap(formData =>
                ajax.post(`${url}${path}`, formData, getHeadersWithoutContentType()).pipe(
                    map<AjaxResponse, any>((response: IResponse<T>) =>
                        this.handleResponse<T>(
                            response.response,
                            this.actionTypes.actionFulfilled,
                            message
                        )
                    ),
                    takeUntil(this.action$.pipe(ofType(this.actionTypes.actionCancelled))),
                    catchError(error => this.handleError(error, this.actionTypes.actionRejected))
                )
            )
        );
    }

    public attachment<T>(path: string, message?: INotificationMessage) {
        return this.action$.pipe(
            ofType(this.actionTypes.action, this.actionTypes.optionalAction),
            map(action => action.payload),
            map(data => {
                const formData = new FormData();
                formData.append('id', data.id);
                formData.append('entity', data.entity);
                formData.append('siteId', data.siteId);
                data.files.forEach((file: any) => formData.append(file.name, file));
                return formData;
            }),
            mergeMap(formData =>
                ajax.post(`${url}${path}`, formData, getHeadersWithoutContentType()).pipe(
                    map<AjaxResponse, any>((response: IResponse<T>) =>
                        this.handleResponse<T>(
                            response.response,
                            response.response.isSuccess
                                ? this.actionTypes.actionFulfilled
                                : this.actionTypes.actionRejected,
                            message
                        )
                    ),
                    takeUntil(this.action$.pipe(ofType(this.actionTypes.actionCancelled))),
                    catchError(error => this.handleError(error, this.actionTypes.actionRejected))
                )
            )
        );
    }

    public put<T>(path: string, debounce: boolean = false, message?: INotificationMessage) {
        return this.action$.pipe(
            ofType(this.actionTypes.action, this.actionTypes.optionalAction),
            debounce ? debounceTime(2000) : debounceTime(0),
            mergeMap((action: IAppAction) =>
                ajax.put(`${url}${path}`, action.payload, this.getHeaders(action)).pipe(
                    map<AjaxResponse, any>((response: IResponse<T>) =>
                        this.handleResponse<T>(
                            response.response,
                            this.actionTypes.actionFulfilled,
                            message,
                            action.payload.reloadUrl
                        )
                    ),
                    takeUntil(this.action$.pipe(ofType(this.actionTypes.actionCancelled))),
                    catchError(error => this.handleError(error, this.actionTypes.actionRejected))
                )
            )
        );
    }

    public patch<T>(path: string, debounce: boolean = false, message?: INotificationMessage) {
        return this.action$.pipe(
            ofType(this.actionTypes.action, this.actionTypes.optionalAction),
            debounce ? debounceTime(2000) : debounceTime(0),
            mergeMap((action: IAppAction) =>
                ajax.patch(`${url}${path}`, action.payload, this.getHeaders(action)).pipe(
                    map<AjaxResponse, any>((response: IResponse<T>) =>
                        this.handleResponse<T>(
                            response.response,
                            this.actionTypes.actionFulfilled,
                            message,
                            action.payload.reloadUrl
                        )
                    ),
                    takeUntil(this.action$.pipe(ofType(this.actionTypes.actionCancelled))),
                    catchError(error => this.handleError(error, this.actionTypes.actionRejected))
                )
            )
        );
    }

    public deleteById<T>(path: string, message?: INotificationMessage) {
        return this.action$.pipe(
            ofType(this.actionTypes.action),
            mergeMap((action: IAppAction) =>
                ajax.delete(`${url}${path}/${action.payload}`, this.getHeaders(action)).pipe(
                    map<AjaxResponse, any>((response: IResponse<T>) =>
                        this.handleResponse<T>(
                            response.response,
                            this.actionTypes.actionFulfilled,
                            message,
                            action.payload.reloadUrl
                        )
                    ),
                    takeUntil(this.action$.pipe(ofType(this.actionTypes.actionCancelled))),
                    catchError(error => this.handleError(error, this.actionTypes.actionRejected))
                )
            )
        );
    }

    public deleteByIdAndOperatingPlatform<T>(path: string, message?: INotificationMessage) {
        return this.action$.pipe(
            ofType(this.actionTypes.action),
            mergeMap((action: IAppAction) =>
                ajax
                    .delete(
                        `${url}${path}/${this.getId(action)}/${this.getOperatingPlatform(action)}`,
                        this.getHeaders(action)
                    )
                    .pipe(
                        map<AjaxResponse, any>((response: IResponse<T>) =>
                            this.handleResponse<T>(
                                response.response,
                                this.actionTypes.actionFulfilled,
                                message,
                                action.payload.reloadUrl
                            )
                        ),
                        takeUntil(this.action$.pipe(ofType(this.actionTypes.actionCancelled))),
                        catchError(error =>
                            this.handleError(error, this.actionTypes.actionRejected)
                        )
                    )
            )
        );
    }

    public deleteByIdAndShard<T>(path: string, message?: INotificationMessage) {
        return this.action$.pipe(
            ofType(this.actionTypes.action),
            mergeMap((action: IAppAction) =>
                ajax
                    .delete(
                        `${url}${path}/${action.payload.id}/${action.payload.shard}`,
                        this.getHeaders(action)
                    )
                    .pipe(
                        map<AjaxResponse, any>((response: IResponse<T>) =>
                            this.handleResponse<T>(
                                response.response,
                                this.actionTypes.actionFulfilled,
                                message,
                                action.payload.reloadUrl
                            )
                        ),
                        takeUntil(this.action$.pipe(ofType(this.actionTypes.actionCancelled))),
                        catchError(error =>
                            this.handleError(error, this.actionTypes.actionRejected)
                        )
                    )
            )
        );
    }

    public deleteByIdAndSiteId<T>(path: string, message?: INotificationMessage) {
        return this.action$.pipe(
            ofType(this.actionTypes.action),
            mergeMap((action: IAppAction) =>
                ajax
                    .delete(
                        `${url}${path}/${action.payload.id}/${action.payload.siteId}`,
                        this.getHeaders(action)
                    )
                    .pipe(
                        map<AjaxResponse, any>((response: IResponse<T>) =>
                            this.handleResponse<T>(
                                response.response,
                                this.actionTypes.actionFulfilled,
                                message,
                                action.payload.reloadUrl
                            )
                        ),
                        takeUntil(this.action$.pipe(ofType(this.actionTypes.actionCancelled))),
                        catchError(error =>
                            this.handleError(error, this.actionTypes.actionRejected)
                        )
                    )
            )
        );
    }

    public deleteByIdAndShardAndParam<T>(path: string, message?: INotificationMessage) {
        return this.action$.pipe(
            ofType(this.actionTypes.action),
            mergeMap((action: IAppAction) =>
                ajax
                    .delete(
                        `${url}${path}/${action.payload.id}/${action.payload.shard}/${action.payload.param}`,
                        this.getHeaders(action)
                    )
                    .pipe(
                        map<AjaxResponse, any>((response: IResponse<T>) =>
                            this.handleResponse<T>(
                                response.response,
                                this.actionTypes.actionFulfilled,
                                message,
                                action.payload.reloadUrl
                            )
                        ),
                        takeUntil(this.action$.pipe(ofType(this.actionTypes.actionCancelled))),
                        catchError(error =>
                            this.handleError(error, this.actionTypes.actionRejected)
                        )
                    )
            )
        );
    }

    public delete<T>(path: string, message?: INotificationMessage) {
        return this.action$.pipe(
            ofType(this.actionTypes.action),
            mergeMap((action: IAppAction) =>
                ajax
                    .delete(
                        `${url}${path}?${queryString.stringify(action.payload)}`,
                        this.getHeaders(action)
                    )
                    .pipe(
                        map<AjaxResponse, any>((response: IResponse<T>) =>
                            this.handleResponse<T>(
                                response.response,
                                this.actionTypes.actionFulfilled,
                                message,
                                action.payload.reloadUrl
                            )
                        ),
                        takeUntil(this.action$.pipe(ofType(this.actionTypes.actionCancelled))),
                        catchError(error =>
                            this.handleError(error, this.actionTypes.actionRejected)
                        )
                    )
            )
        );
    }
    private getId(action: IAppAction) {
        if (action.payload && action.payload.id) {
            return action.payload.id;
        }

        return action.payload;
    }

    private getOperatingPlatform(action: IAppAction) {
        if (action.payload && action.payload.operatingPlatform) {
            return action.payload.operatingPlatform;
        }

        return action.payload;
    }

    private getHeaders(action: IAppAction, defaultHeaders: string = 'application/json') {
        if (action.payload && action.payload.siteId) {
            return { ...getApiHeaders(defaultHeaders), SiteId: action.payload.siteId };
        }
        return getApiHeaders(defaultHeaders);
    }

    private handleResponse<T>(
        response: IResponse<T>,
        actionFulfilled: string,
        message: INotificationMessage = null,
        reloadUrlInPayload: string = null
    ): any {
        if (response.messages && response.messages.length > 0) {
            this.handleToastrMessages(response.messages);
        }
        if (response.validationErrors && response.validationErrors.length > 0) {
            this.handleValidationErrors(response.validationErrors);
        }
        if (response.isSuccess && message) {
            toastr.success(message.title, this.translateResponse(message.message));
        }
        if (response.isSuccess && response.validationErrors.length === 0) {
            if (reloadUrlInPayload) {
                this.redirectTo(reloadUrlInPayload);
            } else if (this.reloadUrl) {
                this.redirectTo(this.reloadUrl);
            }
        }
        return {
            type: actionFulfilled,
            payload: response.value,
            responseContinuation: response.responseContinuation,
            isFromContinuationRequest: response.isFromContinuationRequest,
            isSuccess: response.isSuccess
        };
    }

    private handleValidationErrors = (errors: IValidationFailure[]) => {
        errors.map(error => {
            toastr.error('Error', this.translateResponse(error.errorMessage));
        });
    };

    private handleToastrMessages = (messages: IResponseMessage[]) => {
        messages.map(message => {
            const m = this.translateResponse(message.message);
            if (message.type === ResponseMessageType.Info) {
                toastr.info(translate(this.intl, 'BaseEpic.Information'), m);
            } else if (message.type === ResponseMessageType.Error) {
                toastr.error(translate(this.intl, 'BaseEpic.Error'), m);
            } else if (message.type === ResponseMessageType.Success) {
                toastr.success(translate(this.intl, 'BaseEpic.Success'), m);
            } else if (message.type === ResponseMessageType.Warning) {
                toastr.warning(translate(this.intl, 'BaseEpic.Warning'), m);
            }
        });
    };

    private handleError(error: AjaxError, actionRejected: string): any {
        switch (error.status) {
            case 403:
                onRouteChange('/Account/AccessDenied');
                break;
            case 401:
                onRouteChange('/AccessDenied');
                break;
            case 406:
                onRouteChange('/Account/ClientEmailVerificationRequired');
                break;
            case 409:
                onRouteChange('/Account/ClientDomainRequired');
                break;
            default:
                toastr.error(`Error`, JSON.stringify(error.response));
        }

        return of({
            type: actionRejected,
        });
    }

    // tslint:disable-next-line: no-shadowed-variable
    private redirectTo(url: string): void {
        setTimeout(() => onRouteChange(url), 10); // delay slightly to make sure the payload is being handled in reducer before redirect
    }

    private translateResponse = (message: string): string =>
        message.replace(translationApiRegex,
            apiStr => translate(this.intl, apiStr));
}
