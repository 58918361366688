import { IRiskRegisterState } from 'state/risk-register-state';
import { IRisk } from 'models/risk';
import { combineReducers } from 'redux';
import { IAppAction } from 'actions/app-action';
import { AttachmentActions } from 'actions/actions-v2/attachment-action-v2';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import { RiskActions } from 'actions/actions-v2/risk-action-v2';
import { ActionActions as ActionActionsV2 } from 'actions/actions-v2/action-action-v2';
import { addMonthsToNow } from 'utils/date-utils';
import { IAction } from 'models/action';
import * as RiskStatus from 'models/risk-statuses';
import { MacroRiskActions } from 'actions/actions-v2/macro-risk-action-v2';

const INITIAL_STATE: IRiskRegisterState = {
    risks: [],
    risk: {
        isActive: true,
        riskStatusId: RiskStatus.OPEN_AND_PENDING_REVIEW,
        riskStatus: RiskStatus.OPEN_AND_PENDING_REVIEW,
        reviewDate: addMonthsToNow(1).toISOString(),
        origin: 'Risk log'
    } as IRisk,
    clientRisksSummary: null,
    clientRisksSummaries: [],
    isLoading: false,
    isExporting: false,
    attachments: [],
    history: [],
    actions: [],
    submitActionToRisk: false,
};

const risks = (state: IRiskRegisterState['risks'] = INITIAL_STATE.risks, action: IAppAction) => {
    switch (action.type) {
        case RiskActions.LOAD_RISKS_FULFILLED:
            if (action.payload) {
                return action.payload;
            }
        default:
            return state;
    }
};

const attachments = (
    state: IRiskRegisterState['attachments'] = INITIAL_STATE.attachments,
    action: IAppAction
) => {
    switch (action.type) {
        case AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_RISK:
            if (action.payload) {
                return action.payload;
            }
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_RISK:
            if (action.payload) {
                return [...state, ...action.payload];
            }
            return state;
        case AttachmentActions.DELETE_ATTACHMENT_FULFILLED:
            if (action.payload) {
                return state.filter((item: IAttachmentModel) => item.fileName !== action.payload);
            }
            return state;
        default:
            return state;
    }
};

const risk = (state: IRiskRegisterState['risk'] = INITIAL_STATE.risk, action: IAppAction) => {
    switch (action.type) {
        case RiskActions.LOAD_RISK_FULFILLED:
        case MacroRiskActions.LOAD_MACRO_RISK_FULFILLED:
        case RiskActions.CREATE_RISK_MITIGATION_PLAN_FULFILLED:
        case RiskActions.CLIENT_REQUEST_AMENDMENT_FULFILLED:
            if (action.payload) {
                return action.payload;
            }

        case RiskActions.CREATE_RISK_FULFILLED:
        case RiskActions.ACCEPT_RISK_FULFILLED:
        case RiskActions.DELETE_RISK_FULFILLED:
        case RiskActions.CLOSE_RISK_FULFILLED:
        case RiskActions.CLEAR_FORM:
        case RiskActions.CBRE_APPROVAL_RISK_FULFILLED:
        case RiskActions.CLIENT_APPROVAL_RISK_FULFILLED:
            return INITIAL_STATE.risk;

        case RiskActions.UPDATE_RISK_FULFILLED:
        default:
            return state;
    }
};

const clientRisksSummary = (
    state: IRiskRegisterState['clientRisksSummary'] = INITIAL_STATE.clientRisksSummary,
    action: IAppAction
) => {
    switch (action.type) {
        case RiskActions.LOAD_RISKS_SUMMARY_FULFILLED:
            return action.payload;
        default:
            return state;
    }
};

const clientRisksSummaries = (
    state: IRiskRegisterState['clientRisksSummaries'] = INITIAL_STATE.clientRisksSummaries,
    action: IAppAction
) => {
    switch (action.type) {
        case RiskActions.LOAD_RISKS_SUMMARIES_FULFILLED:
            return action.payload;
        default:
            return state;
    }
};

const isLoading = (
    state: IRiskRegisterState['isLoading'] = INITIAL_STATE.isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case RiskActions.LOAD_RISKS:
        case RiskActions.LOAD_RISK:
        case MacroRiskActions.LOAD_MACRO_RISK:
        case RiskActions.LOAD_HISTORY:
        case RiskActions.CREATE_RISK:
        case MacroRiskActions.CREATE_MACRO_RISK:
        case RiskActions.CREATE_RISK_MITIGATION_PLAN:
        case RiskActions.ACCEPT_RISK:
        case RiskActions.UPDATE_RISK:
        case MacroRiskActions.UPDATE_MACRO_RISK:
        case RiskActions.CLOSE_RISK:
        case RiskActions.DELETE_RISK:
        case MacroRiskActions.DELETE_MACRO_RISK:
        case RiskActions.CBRE_APPROVAL_RISK:
        case RiskActions.CLIENT_APPROVAL_RISK:
        case RiskActions.CLIENT_REQUEST_AMENDMENT:
        case RiskActions.LOAD_RISK_ACTIONS:
        case RiskActions.LOAD_RISKS_SUMMARY:
        case RiskActions.LOAD_RISKS_SUMMARIES:
        case RiskActions.RESET_RISK:
            return true;

        case RiskActions.LOAD_RISKS_FULFILLED:
        case RiskActions.LOAD_RISKS_REJECTED:
        case MacroRiskActions.LOAD_MACRO_RISKS_FULFILLED:
        case MacroRiskActions.LOAD_MACRO_RISKS_REJECTED:
        case RiskActions.LOAD_RISK_FULFILLED:
        case RiskActions.LOAD_RISK_REJECTED:
        case MacroRiskActions.LOAD_MACRO_RISK_FULFILLED:
        case MacroRiskActions.LOAD_MACRO_RISK_REJECTED:
        case RiskActions.LOAD_HISTORY_FULFILLED:
        case RiskActions.LOAD_HISTORY_REJECTED:
        case RiskActions.CREATE_RISK_FULFILLED:
        case RiskActions.CREATE_RISK_REJECTED:
        case MacroRiskActions.CREATE_MACRO_RISK_FULFILLED:
        case MacroRiskActions.CREATE_MACRO_RISK_REJECTED:
        case RiskActions.CREATE_RISK_MITIGATION_PLAN_FULFILLED:
        case RiskActions.CREATE_RISK_MITIGATION_PLAN_REJECTED:
        case RiskActions.ACCEPT_RISK_FULFILLED:
        case RiskActions.ACCEPT_RISK_REJECTED:
        case RiskActions.UPDATE_RISK_FULFILLED:
        case RiskActions.UPDATE_RISK_REJECTED:
        case MacroRiskActions.UPDATE_MACRO_RISK_FULFILLED:
        case MacroRiskActions.UPDATE_MACRO_RISK_REJECTED:
        case RiskActions.CLOSE_RISK_FULFILLED:
        case RiskActions.CLOSE_RISK_REJECTED:
        case RiskActions.DELETE_RISK_FULFILLED:
        case RiskActions.DELETE_RISK_REJECTED:
        case MacroRiskActions.DELETE_MACRO_RISK_FULFILLED:
        case MacroRiskActions.DELETE_MACRO_RISK_REJECTED:
        case RiskActions.CBRE_APPROVAL_RISK_FULFILLED:
        case RiskActions.CBRE_APPROVAL_RISK_REJECTED:
        case RiskActions.CLIENT_APPROVAL_RISK_FULFILLED:
        case RiskActions.CLIENT_APPROVAL_RISK_REJECTED:
        case RiskActions.CLIENT_REQUEST_AMENDMENT_FULFILLED:
        case RiskActions.CLIENT_REQUEST_AMENDMENT_REJECTED:
        case RiskActions.LOAD_RISK_ACTIONS_FULFILLED:
        case RiskActions.LOAD_RISK_ACTIONS_REJECTED:
        case RiskActions.LOAD_RISKS_SUMMARY_FULFILLED:
        case RiskActions.LOAD_RISKS_SUMMARY_REJECTED:
        case RiskActions.LOAD_RISKS_SUMMARIES_FULFILLED:
        case RiskActions.LOAD_RISKS_SUMMARIES_REJECTED:
        case RiskActions.RESET_RISK_FULFILLED:
        case RiskActions.RESET_RISK_REJECTED:
            return false;

        default:
            return state;
    }
};

const isExporting = (
    state: IRiskRegisterState['isExporting'] = INITIAL_STATE.isExporting,
    action: IAppAction
) => {
    switch (action.type) {
        case RiskActions.EXPORT_RISKS:
        case RiskActions.EXPORT_ALL_RISKS:
            return true;
        case RiskActions.EXPORT_RISKS_FULFILLED:
        case RiskActions.EXPORT_ALL_RISKS_FULFILLED:
            return false;
        case RiskActions.EXPORT_RISKS_REJECTED:
        case RiskActions.EXPORT_ALL_RISKS_REJECTED:
            return false;
        default:
            return state;
    }
};

const history = (
    state: IRiskRegisterState['history'] = INITIAL_STATE.history,
    action: IAppAction
) => {
    switch (action.type) {
        case RiskActions.LOAD_HISTORY_FULFILLED:
            return action.payload ? action.payload : state;

        case RiskActions.LOAD_HISTORY_REJECTED:
            return state;
        default:
            return state;
    }
};

const actions = (
    state: IRiskRegisterState['actions'] = INITIAL_STATE.actions,
    action: IAppAction
) => {
    switch (action.type) {
        case RiskActions.LOAD_RISK_ACTIONS_FULFILLED:
            return action.payload ? action.payload : state;

        case ActionActionsV2.CREATE_ACTION_FOR_RISK_FULFILLED:
            const newActions = [...state];
            newActions.push(action.payload);
            return newActions;

        case ActionActionsV2.UPDATE_ACTION_FOR_RISK_FULFILLED:
            const updatedItemIndex = state.findIndex((x) => x.id === action.payload.id);
            const updatedActions = [...state];
            updatedActions.splice(updatedItemIndex, 1, action.payload);
            return updatedActions;

        case ActionActionsV2.ARCHIVE_ACTION_FOR_RISK_FULFILLED:
            return state.filter((item: IAction) => item.id !== action.payload);

        default:
            return state;
    }
};

const submitActionToRisk = (
    state: IRiskRegisterState['submitActionToRisk'] = INITIAL_STATE.submitActionToRisk,
    action: IAppAction
) => {
    switch (action.type) {
        case ActionActionsV2.CREATE_ACTION_FOR_RISK:
        case ActionActionsV2.CREATE_ACTION_FOR_RISK_REJECTED:
        case ActionActionsV2.UPDATE_ACTION_FOR_RISK:
        case ActionActionsV2.UPDATE_ACTION_FOR_RISK_REJECTED:
        case ActionActionsV2.ARCHIVE_ACTION_FOR_RISK:
        case ActionActionsV2.ARCHIVE_ACTION_FOR_RISK_REJECTED:
        case ActionActionsV2.CREATE_MACRO_ACTION:
        case ActionActionsV2.CREATE_MACRO_ACTION_REJECTED:
        case ActionActionsV2.UPDATE_MACRO_ACTION_FOR_RISK:
        case ActionActionsV2.UPDATE_MACRO_ACTION_FOR_RISK_REJECTED:
            return false;
        case ActionActionsV2.CREATE_ACTION_FOR_RISK_FULFILLED:
        case ActionActionsV2.UPDATE_ACTION_FOR_RISK_FULFILLED:
        case ActionActionsV2.ARCHIVE_ACTION_FOR_RISK_FULFILLED:
        case ActionActionsV2.CREATE_MACRO_ACTION_FULFILLED:
        case ActionActionsV2.UPDATE_MACRO_ACTION_FOR_RISK_FULFILLED:
            return true;
        default:
            return state;
    }
};

export const riskRegisterReducer = combineReducers<IRiskRegisterState>({
    isLoading,
    isExporting,
    risks,
    risk,
    clientRisksSummary,
    clientRisksSummaries,
    attachments,
    history,
    actions,
    submitActionToRisk,
});
