import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { SystemIntegration } from './system-integration';
import { loadExternalBuildings } from 'actions/actions-v2/external-buildings-action-v2';

const getExternalBuildingsBuildings = (state: IRootState) =>
    state.siteState.externalBuildings.map((obj) => ({
        label: obj.name,
        value: obj.id.toString(),
    }));
const getIsSubmitting = (state: IRootState) => state.siteState.isSubmitting;
const mapStateToProps = createSelector(
    getIsSubmitting,
    getExternalBuildingsBuildings,
    (isSubmitting, externalBuildings) => ({
        isSubmitting,
        externalBuildings,
    })
);

const mapDispatchToProps = {
    load: loadExternalBuildings,
};

export const SystemIntegrationContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SystemIntegration)
);
