import * as React from 'react';
import { easeInScrollToTop } from '../../utils/animation-utils';
import './scroll-up-button.scss';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconColor, MaterialIconType } from 'routes/material-icon/material-icon-type';

export interface IProps {
    showAtPixels?: number;
}

export const ScrollUpButton: React.FC<IProps> = (props) => {
    const [showButton, setShowButton] = React.useState(false);
    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        handleScroll();
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);
    const handleScroll = () => {
        // PureComponent will do a shallow check to decide whether it needs to re-render - using setState here is fine
        setShowButton((window.scrollY > props.showAtPixels))
    }

    const scrollToTop = () => easeInScrollToTop();

    return (
        <>
            {(showButton && (
                <button
                    onClick={scrollToTop}
                    aria-label="Scroll to top"
                    className="scroll-to-top"
                >
                    < MaterialIcons type={MaterialIconType.ArrowUp} color={MaterialIconColor.white} />
                </button>
            ))}
        </>
    );
}

ScrollUpButton.defaultProps = {
    showAtPixels: 100
}
