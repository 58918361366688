import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { DashboardActions } from 'actions/actions-v2/dashboard-action-v2';
import { IAction } from 'models/action';

const controllerName = 'dashboard';

const getDashboardIncident = {
    action: DashboardActions.LOAD_DASHBOARD_INCIDENT,
    actionFulfilled: DashboardActions.LOAD_DASHBOARD_INCIDENT_FULFILLED,
    actionRejected: DashboardActions.LOAD_DASHBOARD_INCIDENT_REJECTED
} as IActionType;

const getDashboardAction = {
    action: DashboardActions.LOAD_DASHBOARD_ACTION,
    actionFulfilled: DashboardActions.LOAD_DASHBOARD_ACTION_FULFILLED,
    actionRejected: DashboardActions.LOAD_DASHBOARD_ACTION_REJECTED
} as IActionType;

const getDashboardRiskRegister = {
    action: DashboardActions.LOAD_DASHBOARD_RISK_REGISTER,
    actionFulfilled: DashboardActions.LOAD_DASHBOARD_RISK_REGISTER_FULFILLED,
    actionRejected: DashboardActions.LOAD_DASHBOARD_RISK_REGISTER_REJECTED
} as IActionType;

const getDashboardCalendarEvent = {
    action: DashboardActions.LOAD_DASHBOARD_CALENDAR_EVENT,
    actionFulfilled: DashboardActions.LOAD_DASHBOARD_CALENDAR_EVENT_FULFILLED,
    actionRejected: DashboardActions.LOAD_DASHBOARD_CALENDAR_EVENT_REJECTED
} as IActionType;

const getDashboardCermOverview = {
    action: DashboardActions.LOAD_DASHBOARD_CERM_OVERVIEW,
    actionFulfilled: DashboardActions.LOAD_DASHBOARD_CERM_OVERVIEW_FULFILLED,
    actionRejected: DashboardActions.LOAD_DASHBOARD_CERM_OVERVIEW_REJECTED
} as IActionType;

const loadDashboardIncidentEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getDashboardIncident).getById<IAction[]>(
        `/api/${controllerName}/incidents`
    );

const loadDashboardActionEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getDashboardAction).getById<IAction[]>(`/api/${controllerName}/actions`);

const loadDashboardRiskRegisterEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getDashboardRiskRegister).getById<IAction[]>(
        `/api/${controllerName}/riskRegisters`
    );

const loadDashboardCalendarEventEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getDashboardCalendarEvent).getById<IAction[]>(
        `/api/${controllerName}/calenderEvents`
    );

const loadDashboardCermOverviewEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getDashboardCermOverview).getById<IAction[]>(
        `/api/${controllerName}/cermOverviews`
    );

export const dashboardEpics = combineEpics(
    loadDashboardIncidentEpic,
    loadDashboardActionEpic,
    loadDashboardRiskRegisterEpic,
    loadDashboardCalendarEventEpic,
    loadDashboardCermOverviewEpic
);
