import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { CermAssessmentActions } from 'actions/actions-v2/cerm-assessment-action-v3';
import { ICermAssessmentResult, ISetProcessStepResult } from 'models/cerm/cerm-assessment-result';
import { IProcessStepAnswer, IProcessStepOutcome } from 'models/cerm/process-step-answer';
import { ICermProgressChartData } from 'models/cerm/cerm-progress-chart';

const cermApiPath = '/api/cerm';
const processStepAnswerApiPath = '/api/processStepAnswer';

const recordECermClick = {
    action: CermAssessmentActions.RECORD_ECERM_CLICK,
    actionFulfilled: CermAssessmentActions.RECORD_ECERM_CLICK_FULFILLED,
    actionRejected: CermAssessmentActions.RECORD_ECERM_CLICK_REJECTED,
} as IActionType;

const refreshECermToken = {
    action: CermAssessmentActions.REFRESH_ECERM_TOKEN,
    actionFulfilled: CermAssessmentActions.REFRESH_ECERM_TOKEN_FULFILLED,
    actionRejected: CermAssessmentActions.REFRESH_ECERM_TOKEN__REJECTED,
} as IActionType;

const finishCermExpertReview = {
    action: CermAssessmentActions.FINISH_CERM_EXPERT_ASSESSMENT,
    actionFulfilled: CermAssessmentActions.FINISH_CERM_EXPERT_ASSESSMENT_FULFILLED,
    actionRejected: CermAssessmentActions.FINISH_CERM_EXPERT_ASSESSMENT__REJECTED,
} as IActionType;

const startCermExpertReview = {
    action: CermAssessmentActions.START_CERM_EXPERT_ASSESSMENT,
    actionFulfilled: CermAssessmentActions.START_CERM_EXPERT_ASSESSMENT_FULFILLED,
    actionRejected: CermAssessmentActions.START_CERM_EXPERT_ASSESSMENT__REJECTED,
} as IActionType;

const getCermAssessment = {
    action: CermAssessmentActions.LOAD_CERM_ASSESSMENT_RESULT,
    actionFulfilled: CermAssessmentActions.LOAD_CERM_ASSESSMENT_RESULT_FULFILLED,
    actionRejected: CermAssessmentActions.LOAD_CERM_ASSESSMENT_RESULT_REJECTED,
} as IActionType;

const setProcessStepResult = {
    action: CermAssessmentActions.SET_PROCESS_STEP_RESULT,
    actionFulfilled: CermAssessmentActions.SET_PROCESS_STEP_RESULT_FULFILLED,
    actionRejected: CermAssessmentActions.SET_PROCESS_STEP_RESULT_REJECTED,
} as IActionType;

const getProcessStepAnswer = {
    action: CermAssessmentActions.LOAD_PROCESS_STEP_ANSWER,
    actionFulfilled: CermAssessmentActions.LOAD_PROCESS_STEP_ANSWER_FULFILLED,
    actionRejected: CermAssessmentActions.LOAD_PROCESS_STEP_ANSWER_REJECTED,
} as IActionType;

const getProcessStepOutcome = {
    action: CermAssessmentActions.LOAD_PROCESS_STEP_OUTCOME,
    actionFulfilled: CermAssessmentActions.LOAD_PROCESS_STEP_OUTCOME_FULFILLED,
    actionRejected: CermAssessmentActions.LOAD_PROCESS_STEP_OUTCOME_REJECTED,
} as IActionType;

const createProcessStepAnswer = {
    action: CermAssessmentActions.CREATE_PROCESS_STEP_ANSWER,
    actionFulfilled: CermAssessmentActions.CREATE_PROCESS_STEP_ANSWER_FULFILLED,
    actionRejected: CermAssessmentActions.CREATE_PROCESS_STEP_ANSWER_REJECTED,
} as IActionType;

const saveProcessStepAnswer = {
    action: CermAssessmentActions.SAVE_PROCESS_STEP_ANSWER,
    actionFulfilled: CermAssessmentActions.SAVE_PROCESS_STEP_ANSWER_FULFILLED,
    actionRejected: CermAssessmentActions.SAVE_PROCESS_STEP_ANSWER_REJECTED,
} as IActionType;

const getCermProgressChartDataActions = {
    action: CermAssessmentActions.LOAD_CERM_PROGRESS_CHART_DATA,
    actionFulfilled: CermAssessmentActions.LOAD_CERM_PROGRESS_CHART_DATA_FULFILLED,
    actionRejected: CermAssessmentActions.LOAD_CERM_PROGRESS_CHART_DATA_REJECTED,
} as IActionType;

const loadHistory = {
    action: CermAssessmentActions.LOAD_HISTORY,
    actionFulfilled: CermAssessmentActions.LOAD_HISTORY_FULFILLED,
    actionRejected: CermAssessmentActions.LOAD_HISTORY_REJECTED,
} as IActionType;

const getCermAssessmentExpertReview = {
    action: CermAssessmentActions.LOAD_CERM_ASSESSMENT_EXPERT_REVIEW,
    actionFulfilled: CermAssessmentActions.LOAD_CERM_ASSESSMENT_EXPERT_REVIEW_FULFILLED,
    actionRejected: CermAssessmentActions.LOAD_CERM_ASSESSMENT_EXPERT_REVIEW_REJECTED,
} as IActionType;

const updateNextExpertReviewStatus = {
    action: CermAssessmentActions.UPDATE_CERM_ASSESSMENT_EXPERT_REVIEW_STATUS,
    actionFulfilled: CermAssessmentActions.UPDATE_CERM_ASSESSMENT_EXPERT_REVIEW_STATUS_FULFILLED,
    actionRejected: CermAssessmentActions.UPDATE_CERM_ASSESSMENT_EXPERT_REVIEW_STATUS_REJECTED,
} as IActionType;

const loadCermAssessmentEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getCermAssessment).getById<ICermAssessmentResult>(`${cermApiPath}`);

const setProcessStepResultEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, setProcessStepResult).put<ISetProcessStepResult>(`${cermApiPath}`);

const startCermExpertReviewEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, startCermExpertReview).post(`${cermApiPath}/StartExpertReview`);

const refreshECermTokenEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, refreshECermToken).get(`${cermApiPath}/GetECermToken`);

const recordECermClickEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, recordECermClick).get(`${cermApiPath}/RecordECermClick`);

const finishCermExpertReviewEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, finishCermExpertReview).post(`${cermApiPath}/FinishExpertReview`);

const loadProcessStepAnswerEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getProcessStepAnswer).getByIdAndShard<IProcessStepAnswer>(
        `${processStepAnswerApiPath}`
    );

const loadProcessStepOutcomeEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getProcessStepOutcome).getByIdAndShard<IProcessStepOutcome>(
        `${processStepAnswerApiPath}`
    );

const saveProcessStepAnswerEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(
        action$,
        state$,
        saveProcessStepAnswer,
        '/CermAssessment/assessment'
    ).put<IProcessStepAnswer>(`${processStepAnswerApiPath}`);

const createProcessStepAnswerEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(
        action$,
        state$,
        createProcessStepAnswer,
        '/CermAssessment/assessment'
    ).post<IProcessStepAnswer>(`${processStepAnswerApiPath}`);

const loadCermProgressChartDataEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getCermProgressChartDataActions).get<ICermProgressChartData>(
        `${cermApiPath}`
    );

const loadHistoryEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, loadHistory).getBySiteIdAndQuery<string>(`${cermApiPath}/history`);

const loadCermAssessmentExpertReviewEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getCermAssessmentExpertReview).getById<string>(
        `${cermApiPath}/expertReviewStatus`
    );

const updateNextExpertReviewStatusEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateNextExpertReviewStatus).put(
        `${cermApiPath}/UpdateExpertReviewStatus`
    );

export const cermAssessmentEpicsV3 = combineEpics(
    recordECermClickEpic,
    refreshECermTokenEpic,
    finishCermExpertReviewEpic,
    startCermExpertReviewEpic,
    loadCermAssessmentEpic,
    setProcessStepResultEpic,
    loadProcessStepAnswerEpic,
    loadProcessStepOutcomeEpic,
    saveProcessStepAnswerEpic,
    createProcessStepAnswerEpic,
    loadCermProgressChartDataEpic,
    loadHistoryEpic,
    loadCermAssessmentExpertReviewEpic,
    updateNextExpertReviewStatusEpic
);
