import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import {
    getLookupRiskCategories,
    getLookupRiskLevels,
    getLookupRiskStatus,
    getLookupRiskSpecial,
    getCurrentSiteFeatures,
    getIsClient
} from 'sharedSelectors';
import { ICermOverviewRootState } from 'reducers/cerm-overview';

const getSiteId = (state: IRootState) => state.app.siteId;
const getShowHelpModule = (state: IRootState) => state.app.showHelpModule;
const getIsExporting = (state: IRootState) => state.riskRegisterState.isExporting;
const getLookupSites = (state: IRootState) => state.app.lookupSites;
const getPermissions = (state: ICermOverviewRootState) => state.app.permissions;
const getRisksState = createSelector(
    (state: IRootState) => state.risks,
    (state) => state
);

export default createSelector(
    getSiteId,
    getRisksState,
    getLookupRiskCategories,
    getLookupRiskLevels,
    getLookupRiskStatus,
    getLookupRiskSpecial,
    getIsExporting,
    getCurrentSiteFeatures,
    getIsClient,
    getShowHelpModule,
    getLookupSites,
    getPermissions,
    (siteId, riskState, riskCategories, riskLevels, riskStatus,
        riskSpecial, isExporting, siteFeatures, isClient, showHelpModule, lookupSites, permissions) => ({
            siteId,
            isLoading: riskState.grid.isLoading,
            risks: riskState.grid.items,
            isExporting,
            riskCategory: riskCategories,
            riskLevel: riskLevels,
            riskSpecial,
            riskStatus,
            showClientRisks: siteFeatures.includes('Client Risks'),
            isClient,
            showHelpModule,
            lookupSites,
            permissions
        })
);
