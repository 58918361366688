import { IIncident, IIncidentsFilters, IApproval } from 'models';
import { IIncidentsState } from 'state';
import { IAppAction, IGenericAction } from './app-action';
import { IKeyedItem } from 'models/keyed-item';

export const IncidentActions = {
    UPDATE_INCIDENT_RISKS: '@@incidents/UPDATE_INCIDENT_RISKS',

    LOAD_INCIDENT_SETTINGS: '@@incidents/LOAD_INCIDENT_SETTINGS',
    LOAD_INCIDENT_SETTINGS_FULFILLED: '@@incidents/LOAD_INCIDENT_SETTINGS_FULFILLED',
    LOAD_INCIDENT_SETTINGS_REJECTED: '@@incidents/LOAD_INCIDENT_SETTINGS_REJECTED',
    LOAD_INCIDENT_SETTINGS_CANCELLED: '@@incidents/LOAD_INCIDENT_SETTINGS_CANCELLED',

    LOAD_INCIDENTS: '@@incidents/LOAD_INCIDENTS',
    LOAD_INCIDENTS_FULFILLED: '@@incidents/LOAD_INCIDENTS_FULFILLED',
    LOAD_INCIDENTS_REJECTED: '@@incidents/LOAD_INCIDENTS_REJECTED',
    LOAD_INCIDENTS_CANCELLED: '@@incidents/LOAD_INCIDENTS_CANCELLED',
    LOAD_INCIDENT: '@@incidents/LOAD_INCIDENT',
    LOAD_INCIDENT_FULFILLED: '@@incidents/LOAD_INCIDENT_FULFILLED',
    LOAD_INCIDENT_REJECTED: '@@incidents/LOAD_INCIDENT_REJECTED',
    LOAD_INCIDENT_CANCELLED: '@@incidents/LOAD_INCIDENT_CANCELLED',
    SEND_EMAILS: '@@incidents/SEND_EMAILS',
    SEND_EMAILS_FULFILLED: '@@incidents/SEND_EMAILS_FULFILLED',
    SEND_EMAILS_REJECTED: '@@incidents/SEND_EMAILS_REJECTED',
    SEND_EMAILS_CANCELLED: '@@incidents/SEND_EMAILS_CANCELLED',
    UPSERT_INCIDENT: '@@incidents/UPSERT_INCIDENT',
    UPSERT_INCIDENT_FULFILLED: '@@incidents/UPSERT_INCIDENT_FULFILLED',
    UPSERT_INCIDENT_REJECTED: '@@incidents/UPSERT_INCIDENT_REJECTED',
    UPSERT_INCIDENT_CANCELLED: '@@incidents/UPSERT_INCIDENT_CANCELLED',
    ADD_FILTER_KEYWORD: '@@incidents/ADD_FILTER_KEYWORD',
    REMOVE_FILTER_KEYWORD: '@@incidents/REMOVE_FILTER_KEYWORD',
    CLEAR_FILTERS: '@@incidents/CLEAR_FILTERS',
    TOGGLE_FILTER_EXPANDED: '@@incidents/TOGGLE_FILTER_EXPANDED',
    TOGGLE_ITEM_EXPANDED: '@@incidents/TOGGLE_ITEM_EXPANDED',
    CHANGE_SORT_ORDER_VALUE: '@@incidents/CHANGE_SORT_ORDER_VALUE',
    SORT_INCIDENTS: '@@incidents/SORT_INCIDENTS',
    CHANGE_IS_PUBLISHED_VALUE: '@@incidents/CHANGE_IS_PUBLISHED_VALUE',
    CHANGE_FILTER_VALUE: '@@incidents/CHANGE_FILTER_VALUE',
    SHOW_FILTER_MODAL: '@@incidents/SHOW_FILTER_MODAL',
    HIDE_FILTER_MODAL: '@@incidents/HIDE_FILTER_MODAL',
    LOAD_PAGE: '@@incidents/LOAD_PAGE',
    LOAD_PAGE_FULFILLED: '@@incidents/LOAD_PAGE_FULFILLED',
    LOAD_PAGE_REJECTED: '@@incidents/LOAD_PAGE_REJECTED',
    LOAD_PAGE_CANCELLED: '@@incidents/LOAD_PAGE_CANCELLED',
    LOAD_LOOKUPS: '@@incidents/LOAD_LOOKUPS',
    LOAD_LOOKUPS_FULFILLED: '@@incidents/LOAD_LOOKUPS_FULFILLED',
    LOAD_LOOKUPS_REJECTED: '@@incidents/LOAD_LOOKUPS_REJECTED',
    DOWNLOAD_INCIDENTS: '@@incidents/DOWNLOAD_INCIDENTS',
    DOWNLOAD_INCIDENTS_FULFILLED: '@@incidents/DOWNLOAD_INCIDENTS_FULFILLED',
    DOWNLOAD_INCIDENTS_CANCELLED: '@@incidents/DOW/NLOAD_INCIDENTS_CANCELLED',
    DOWNLOAD_INCIDENTS_REJECTED: '@@incidents/DOWNLOAD_INCIDENTS_REJECTED',

    SHOW_ADD_ATTACHMENT_MODAL: '@@incidents/SHOW_ADD_ATTACHMENT_MODAL',
    HIDE_ADD_ATTACHMENT_MODAL: '@@incidents/HIDE_ADD_ATTACHMENT_MODAL',
    ADD_ATTACHMENT: '@@incidents/ADD_ATTACHMENT',
    ADD_ATTACHMENT_FULFILLED: '@@incidents/ADD_ATTACHMENT_FULFILLED',
    ADD_ATTACHMENT_REJECTED: '@@incidents/ADD_ATTACHMENT_REJECTED',
    ADD_ATTACHMENT_CANCELLED: '@@incidents/ADD_ATTACHMENT_CANCELLED',

    LOAD_ATTACHMENTS: '@@incidents/LOAD_ATTACHMENTS',
    LOAD_ATTACHMENTS_FULFILLED: '@@incidents/LOAD_ATTACHMENTS_FULFILLED',
    LOAD_ATTACHMENTS_CANCELED: '@@incidents/LOAD_ATTACHMENTS_CANCELED',
    LOAD_ATTACHMENTS_REJECTED: '@@incidents/LOAD_ATTACHMENTS_REJECTED',
    HIDE_ATTACHMENTS: '@@incidents/HIDE_ATTACHMENTS',
    DELETE_ATTACHMENT: '@@incidents/DELETE_ATTACHMENT',
    DELETE_ATTACHMENT_COMPLETE: '@@incidents/DELETE_ATTACHMENT_COMPLETE',
    DELETE_ATTACHMENT_REJECTED: '@@incidents/DELETE_ATTACHMENT_REJECTED',
    RELOAD_ATTACHMENTS: '@@incidents/RELOAD_ATTACHMENTS',

    CHANGE_APPROVAL_STATUS: '@@incidents/CHANGE_APPROVAL_STATUS',
    CHANGE_APPROVAL_STATUS_FULFILLED: '@@incidents/CHANGE_APPROVAL_STATUS_FULFILLED',
    CHANGE_APPROVAL_STATUS_CANCELLED: '@@incidents/CHANGE_APPROVAL_STATUS_CANCELLED',
    CHANGE_APPROVAL_STATUS_REJECTED: '@@incidents/CHANGE_APPROVAL_STATUS_REJECTED',

    LOAD_HISTORY: '@@incidents/LOAD_HISTORY',
    LOAD_HISTORY_FULFILLED: '@@incidents/LOAD_HISTORY_FULFILLED',
    LOAD_HISTORY_CANCELED: '@@incidents/LOAD_HISTORY_CANCELED',
    LOAD_HISTORY_REJECTED: '@@incidents/LOAD_HISTORY_REJECTED',
    PASS_LIVE_VALUES_OF_INCIDENT_TO_PARENT: '@@incidents/PASS_LIVE_VALUES_OF_INCIDENT_TO_PARENT'
};

export const loadHistory = (id: string): IAppAction => ({
    type: IncidentActions.LOAD_HISTORY,
    payload: {
        id
    }
});

export const loadHistoryFulfilled = (incidents: IIncident[]): IAppAction => ({
    type: IncidentActions.LOAD_HISTORY_FULFILLED,
    payload: incidents
});

export const loadHistoryRejected = (error: any): IAppAction => ({
    type: IncidentActions.LOAD_HISTORY_REJECTED,
    payload: error
});

export const loadPage = (): IAppAction => ({
    type: IncidentActions.LOAD_PAGE
});

export const loadPageFulfilled = (incidents: IIncident[]): IAppAction => ({
    type: IncidentActions.LOAD_PAGE_FULFILLED,
    payload: incidents
});

export const loadPageRejected = (error: any): IAppAction => ({
    type: IncidentActions.LOAD_PAGE_REJECTED,
    payload: error
});

export const loadAttachments = (id: string): IAppAction => ({
    type: IncidentActions.LOAD_ATTACHMENTS,
    payload: {
        id
    }
});

export const onAddAttachment = (incident: IIncident, files: File[]): IAppAction => ({
    type: IncidentActions.ADD_ATTACHMENT,
    payload: {
        incident,
        files
    }
});

export const onHideAttachmentModal = (): IAppAction => ({
    type: IncidentActions.HIDE_ADD_ATTACHMENT_MODAL
});

export const loadPageCancelled = (): IAppAction => ({
    type: IncidentActions.LOAD_PAGE_CANCELLED,

});

export const loadIncidents = (filters: IIncidentsFilters): IAppAction => ({
    type: IncidentActions.LOAD_INCIDENTS,
    payload: filters
});

export const loadIncidentsRejected = (error: any): IAppAction => ({
    type: IncidentActions.LOAD_INCIDENTS_REJECTED,
    payload: error
});

export const loadIncidentsCancelled = (): IAppAction => ({
    type: IncidentActions.LOAD_INCIDENTS_CANCELLED
});

export const loadIncidentsFulfilled = (incidents: IIncident[]): IAppAction => ({
    type: IncidentActions.LOAD_INCIDENTS_FULFILLED,
    payload: incidents
});

export const loadIncident = (id: string): IAppAction => ({
    type: IncidentActions.LOAD_INCIDENT,
    payload: id
});

export const onSendEmails = (id: string, appUsers: string[], emails: string[]): IAppAction => ({
    type: IncidentActions.SEND_EMAILS,
    payload: {
        incidentId: id,
        appUsers,
        emails
    }
});

export const loadIncidentRejected = (error: any): IAppAction => ({
    type: IncidentActions.LOAD_INCIDENT_REJECTED,
    payload: error
});

export const loadIncidentCancelled = (): IAppAction => ({
    type: IncidentActions.LOAD_INCIDENT_CANCELLED
});

export const loadIncidentSettings = (): IAppAction => ({
    type: IncidentActions.LOAD_INCIDENT_SETTINGS
});

export const loadIncidentSettingsFulfilled = (): IAppAction => ({
    type: IncidentActions.LOAD_INCIDENT_SETTINGS_FULFILLED
});

export const loadIncidentSettingsRejected = (error: any): IAppAction => ({
    type: IncidentActions.LOAD_INCIDENT_SETTINGS_REJECTED,
    payload: error
});

export const loadIncidentSettingsCancelled = (): IAppAction => ({
    type: IncidentActions.LOAD_INCIDENT_SETTINGS_CANCELLED
});

export const loadIncidentFulfilled = (incident: IIncident): IAppAction => ({
    type: IncidentActions.LOAD_INCIDENT_FULFILLED,
    payload: incident
});

export const upsertIncident = (incident: IIncident, shouldRedirect: boolean): IAppAction => {

    const reloadUrl = shouldRedirect ? '/Incidents' : '';
    return {
        type: IncidentActions.UPSERT_INCIDENT,
        payload: {...incident, reloadUrl},
    };
};

export const upsertIncidentRejected = (error: any): IAppAction => ({
    type: IncidentActions.UPSERT_INCIDENT_REJECTED,
    payload: error
});

export const upsertIncidentCancelled = (): IAppAction => ({
    type: IncidentActions.UPSERT_INCIDENT_CANCELLED
});

export const upsertIncidentFulfilled = (incident: IIncident): IGenericAction<IIncident> => ({
    type: IncidentActions.UPSERT_INCIDENT_FULFILLED,
    payload: incident
});

export const onSort = (key: Extract<keyof IIncident, string>, sortAscending: boolean) => ({
    type: IncidentActions.SORT_INCIDENTS,
    payload: { key, sortAscending }
});

export const setSortOrder = (sortColumn?: string, sortAscending?: boolean): IAppAction => ({
    type: IncidentActions.CHANGE_SORT_ORDER_VALUE,
    payload: { sortColumn, sortAscending }
});

export const setFilterValue = (field: string, value: string): IAppAction => ({
    type: IncidentActions.CHANGE_FILTER_VALUE,
    payload: { field, value }
});

export const clearFilters = (): IAppAction => ({
    type: IncidentActions.CLEAR_FILTERS
});

export const addFilterKeyword = (keyword: string): IAppAction => ({
    type: IncidentActions.ADD_FILTER_KEYWORD,
    payload: { keyword }
});

export const removeFilterKeyword = (keyword: string): IAppAction => ({
    type: IncidentActions.REMOVE_FILTER_KEYWORD,
    payload: { keyword }
});

export const toggleFilterExpanded = (id: Extract<keyof IIncidentsState, string>): IAppAction => ({
    type: IncidentActions.TOGGLE_FILTER_EXPANDED,
    payload: { id }
});

export const toggleItemExpanded = (id: string, siteId: string): IAppAction => ({
    type: IncidentActions.TOGGLE_ITEM_EXPANDED,
    payload: { id, siteId}
});

export const showFilterModal = (): IAppAction => ({
    type: IncidentActions.SHOW_FILTER_MODAL
});

export const hideFilterModal = (): IAppAction => ({
    type: IncidentActions.HIDE_FILTER_MODAL
});

export const loadLookups = (): IAppAction => ({
    type: IncidentActions.LOAD_LOOKUPS
});

export const loadLookupsRejected = (error: any): IAppAction => ({
    type: IncidentActions.LOAD_LOOKUPS_REJECTED,
    payload: error
});

export const downloadIncidents = (siteId: string): IAppAction => ({
    type: IncidentActions.DOWNLOAD_INCIDENTS,
    payload: siteId
});

export const downloadIncidentsFulfilled = (payload: any): IAppAction => ({
    type: IncidentActions.DOWNLOAD_INCIDENTS_FULFILLED,
    payload
});

export const downloadIncidentsCancelled = (): IAppAction => ({
    type: IncidentActions.DOWNLOAD_INCIDENTS_CANCELLED
});

export const downloadIncidentsRejected = (): IAppAction => ({
    type: IncidentActions.DOWNLOAD_INCIDENTS_REJECTED
});

export const reloadAttachments = (id: string): IAppAction => ({
    type: IncidentActions.RELOAD_ATTACHMENTS,
    payload: {
        id
    }
});

export const onClose = (item: IKeyedItem): IAppAction => ({
    type: IncidentActions.HIDE_ATTACHMENTS,
    payload: {
        outcome: item
    }
});

export const onDelete = (attachmentId: string, incidentId: string): IAppAction => ({
    type: IncidentActions.DELETE_ATTACHMENT,
    payload: {
        attachmentId,
        incidentId
    }
});

export const onShowAddAttachmentModal = (): IAppAction => ({
    type: IncidentActions.SHOW_ADD_ATTACHMENT_MODAL,
});

export const changeApprovalStatus = (id: string, approval: IApproval): IAppAction => ({
    type: IncidentActions.CHANGE_APPROVAL_STATUS,
    payload: {id, approval}
});

export const changeApprovalStatusRejected = (error: any): IAppAction => ({
    type: IncidentActions.CHANGE_APPROVAL_STATUS_REJECTED,
    payload: error
});

export const changeApprovalStatusCancelled = (): IAppAction => ({
    type: IncidentActions.CHANGE_APPROVAL_STATUS_CANCELLED
});

export const changeApprovalStatusFulfilled = (incident: IIncident): IAppAction => ({
    type: IncidentActions.CHANGE_APPROVAL_STATUS_FULFILLED,
    payload: incident
});

export const passLiveValuesOfIncidentToParent = (incident: Partial<IIncident>): IAppAction => ({
    type: IncidentActions.PASS_LIVE_VALUES_OF_INCIDENT_TO_PARENT,
    payload: incident
});
