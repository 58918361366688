import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';

const getCommentState = (state: IRootState) => state.commentState;
const getPermissions = (state: IRootState) => state.app.permissions;
const getSiteId = (state: IRootState) => state.app.siteId;
export default createSelector(
    getCommentState,
    getPermissions,
    getSiteId,
    (commentState, permissions, siteId) => ({
        comments: commentState.comments,
        riskComments: commentState.riskComments,
        actionComments: commentState.actionComments,
        isLoading: commentState.isLoading,
        isSaving: commentState.isSaving,
        message: commentState.message,
        editMessage: commentState.editMessage,
        messageEditId: commentState.messageEditId,
        permissions,
        siteId
    })
);
