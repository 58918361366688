import * as React from 'react';
import { CheckboxFilter } from 'components/checkboxFilter';
import { SearchFilter } from 'components/searchFilter';
// import Select from 'react-select';
import { engineerSkillLevels, engineerSkillStatuses } from 'models/engineer-enums';
import { IEngineerFilters } from 'models/engineer-filters';
import { IObsoleteSkillCategory } from 'models';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';

interface IProps {
    filters: IEngineerFilters;
    skillCategories: IObsoleteSkillCategory[];
    onFilter: (key: string, element: string) => void;
    onAddFilterKeyword: (keyword: string) => void;
    onRemoveFilterKeyword: (keyword: string) => void;
    showStatusFilter?: boolean;
    intl: any;
}

export class EngineerFiltersClass extends React.PureComponent<IProps> {
    onToggleFilterExpanded: any;

    constructor(props: IProps) {
        super(props);
    }

    public render() {
        const commonProps = {
            expandedFilters: ['levels', 'categories', 'statuses'],
            onFilter: this.props.onFilter,
            onToggleFilterExpanded: this.onToggleFilterExpanded,
        };
        return (
            <>
                <SearchFilter
                    title={translate(this.props.intl, 'RiskRegisterPage.Title.Filters')}
                    name="filterKeywords"
                    values={this.props.filters.filterKeywords}
                    onAddFilterKeyword={this.props.onAddFilterKeyword}
                    onRemoveFilterKeyword={this.props.onRemoveFilterKeyword}
                />
                <CheckboxFilter
                    {...commonProps}
                    title={translate(this.props.intl, 'EngineersFilter.SkillLevel')} // Skill Level
                    name="levels"
                    selectedFilters={this.props.filters.levels}
                    unreservedFilters={engineerSkillLevels}
                />
                <CheckboxFilter
                    {...commonProps}
                    title={translate(this.props.intl, 'RiskRegisterPage.RiskFilter.Title.Category')} // Category
                    name="categories"
                    selectedFilters={this.props.filters.categories}
                    unreservedFilters={
                        this.props.skillCategories &&
                        this.props.skillCategories.filter((item) => item.isActive === true).map((x) => ({
                            key: x.id.toString(),
                            value: x.code.toString() + ': ' + x.name,
                        }))
                    }
                />
                {this.props.showStatusFilter && (
                    <CheckboxFilter
                        {...commonProps}
                        title={translate(this.props.intl, 'RiskRegisterPage.RiskFilter.Title.Status')} // Status
                        name="statuses"
                        selectedFilters={this.props.filters.statuses}
                        unreservedFilters={engineerSkillStatuses}
                    />
                )}
            </>
        );
    }
}

export const EngineerFilters = injectIntl(EngineerFiltersClass);