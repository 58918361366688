import { IMenu } from 'models/menu-model';
import { IAppAction } from '../app-action';

export enum IOperatingPlatformActions {

    LOAD_DEFAULT_MENUS = '@@operatingPlatform/LOAD_DEFAULT_MENUS',
    LOAD_DEFAULT_MENUS_FULFILLED = '@@operatingPlatform/LOAD_DEFAULT_MENUS_FULFILLED',
    LOAD_DEFAULT_MENUS_REJECTED = '@@operatingPlatform/LOAD_DEFAULT_MENUS_REJECTED',

    LOAD_OPERATING_PLATFORM = '@@operatingPlatform/LOAD_OPERATING_PLATFORM',
    LOAD_OPERATING_PLATFORM_FULFILLED = '@@operatingPlatform/LOAD_OPERATING_PLATFORM_FULFILLED',
    LOAD_OPERATING_PLATFORM_REJECTED = '@@operatingPlatform/LOAD_OPERATING_PLATFORM_REJECTED',

    CREATE_OPERATING_PLATFORM = '@@operatingPlatform/CREATE_OPERATING_PLATFORM',
    CREATE_OPERATING_PLATFORM_FULFILLED = '@@operatingPlatform/CREATE_OPERATING_PLATFORM_FULFILLED',
    CREATE_OPERATING_PLATFORM_REJECTED = '@@operatingPlatform/CREATE_OPERATING_PLATFORM_REJECTED',

    UPDATE_OPERATING_PLATFORM = '@@operatingPlatform/UPDATE_OPERATING_PLATFORM',
    UPDATE_OPERATING_PLATFORM_FULFILLED = '@@operatingPlatform/UPDATE_OPERATING_PLATFORM_FULFILLED',
    UPDATE_OPERATING_PLATFORM_REJECTED = '@@Op/UPDATE_OPERATING_PLATFORM_REJECTED',

    DELETE_OPERATING_PLATFORM = '@@operatingPlatform/DELETE_OPERATING_PLATFORM',
    DELETE_OPERATING_PLATFORM_FULFILLED = '@@operatingPlatform/DELETE_OPERATING_PLATFORM_FULFILLED',
    DELETE_OPERATING_PLATFORM_REJECTED = '@@operatingPlatform/DELETE_OPERATING_PLATFORM_REJECTED',

    GET_OPERATING_PLATFORM_MENUS = '@@operatingPlatform/GET_OPERATING_PLATFORM_MENUS',
    GET_OPERATING_PLATFORM_MENUS_FULFILLED = '@@operatingPlatform/GET_OPERATING_PLATFORM_MENUS_FULFILLED',
    GET_OPERATING_PLATFORM_MENUS_REJECTED = '@@operatingPlatform/GET_OPERATING_PLATFORM_MENUS_REJECTED',

    UPDATE_OPERATING_PLATFORM_MENUS = '@@operatingPlatform/UPDATE_OPERATING_PLATFORM_MENUS',
    UPDATE_OPERATING_PLATFORM_MENUS_FULFILLED = '@@operatingPlatform/UPDATE_OPERATING_PLATFORM_MENUS_FULFILLED',
    UPDATE_OPERATING_PLATFORM_MENUS_REJECTED = '@@operatingPlatform/UPDATE_OPERATING_PLATFORM_MENUS_REJECTED',

    LOAD_MENUS_FOR_OPERATING_PLATFORM = '@@operatingPlatform/LOAD_MENUS_FOR_OPERATING_PLATFORM',
    LOAD_MENUS_FOR_OPERATING_PLATFORM_FULFILLED = '@@operatingPlatform/LOAD_MENUS_FOR_OPERATING_PLATFORM_FULFILLED',
    LOAD_MENUS_FOR_OPERATING_PLATFORM_REJECTED = '@@operatingPlatform/LOAD_MENUS_FOR_OPERATING_PLATFORM_REJECTED',

    RESET_OPERATING_PLATFORM_MENUS = '@@operatingPlatform/RESET_OPERATING_PLATFORM_MENUS',
    RESET_UPDATED_OPERATING_PLATFORM_MENUS_FLAG = '@@operatingPlatform/RESET_UPDATED_OPERATING_PLATFORM_MENUS_FLAG',
    RESET_UPDATED_OPERATING_PLATFORM_MENUS = '@@operatingPlatform/RESET_UPDATED_OPERATING_PLATFORM_MENUS',
    RESET_MENUS_FOR_OPERATING_PLATFORM = '@@operatingPlatform/RESET_MENUS_FOR_OPERATING_PLATFORM',
    RESET_IS_DELETED_OPERATING_PLATFORM_FLAG = '@@operatingPlatform/RESET_IS_DELETED_OPERATING_PLATFORM_FLAG',
}

export const loadOperatingPlatform = (): IAppAction => ({
    type: IOperatingPlatformActions.LOAD_OPERATING_PLATFORM,
});

export const getOperatingPlatformMenus = (operatingPlatformMenus: []): IAppAction => ({
    type: IOperatingPlatformActions.GET_OPERATING_PLATFORM_MENUS,
    payload: { operatingPlatformMenus }
});

export const createOperatingPlatform = (
    id: string,
    operatingPlatform: string,
    processlink: string,
    shortForm: string
): IAppAction => ({
    type: IOperatingPlatformActions.CREATE_OPERATING_PLATFORM,
    payload: {
        id: id,
        label: operatingPlatform,
        processlink: processlink,
        shortForm: shortForm
    },
});

export const updateOperatingPlatform = (
    operatingPlatformId: string,
    operatingPlatform: string,
    processlink: string,
    shortForm: string
): IAppAction => ({
    type: IOperatingPlatformActions.UPDATE_OPERATING_PLATFORM,
    payload: {
        id: operatingPlatformId,
        label: operatingPlatform,
        processlink: processlink,
        shortForm: shortForm
    },
});

export const updateOperatingPlatformMenus = (
    operatingPlatformMenus: IMenu[],
): IAppAction => ({
    type: IOperatingPlatformActions.UPDATE_OPERATING_PLATFORM_MENUS,
    payload: { operatingPlatformMenus: operatingPlatformMenus }
});

export const deleteOperatingPlatform = (operatingPlatformId: string): IAppAction => ({
    type: IOperatingPlatformActions.DELETE_OPERATING_PLATFORM,
    payload: operatingPlatformId,
});

export const loadMenusForOperatingPlatform = (operatingPlatform: string): IAppAction => ({
    type: IOperatingPlatformActions.LOAD_MENUS_FOR_OPERATING_PLATFORM,
    payload: { operatingPlatform },
});

export const resetOperatingPlatformMenus = (): IAppAction => ({
    type: IOperatingPlatformActions.RESET_OPERATING_PLATFORM_MENUS
});

export const resetUpdatedOperatingPlatformMenusFlag = (): IAppAction => ({
    type: IOperatingPlatformActions.RESET_UPDATED_OPERATING_PLATFORM_MENUS_FLAG
});

export const resetUpdatedOperatingPlatformMenus = (): IAppAction => ({
    type: IOperatingPlatformActions.RESET_UPDATED_OPERATING_PLATFORM_MENUS
});

export const resetMenusForOperatingPlatform = (): IAppAction => ({
    type: IOperatingPlatformActions.RESET_MENUS_FOR_OPERATING_PLATFORM
});

export const resetIsDeletedOperatingPlatformFlag = (): IAppAction => ({
    type: IOperatingPlatformActions.RESET_IS_DELETED_OPERATING_PLATFORM_FLAG
});
