import { differenceBy } from 'lodash';

export const getDifferences = (left: any[], right: any[], field: string) => {
    const leftToRight = differenceBy(left, right, field);
    const rightToLeft = differenceBy(right, left, field);
    return [...leftToRight, ...rightToLeft];
};

export const hasDifferences = (left: any[], right: any[], field: string) => {
    return getDifferences(left, right, field).length > 0;
};

export const checkFilterExists = (filter) => {
    return Object.keys(filter).some((item) => {
        if (Array.isArray(filter[item]) && item !== 'siteId') {
            return filter[item].length > 0;
        } else if (typeof filter[item] === 'string' && item !== 'siteId') {
            return filter[item] !== '';
        } else if (typeof filter[item] === 'boolean') {
            return filter[item];
        }
    });
};

export const checkFilterExistsWithIgnoreKey = (filter,ignoreKey) => {
    return Object.keys(filter).some((item) => {
        if (Array.isArray(filter[item]) && item !== 'siteId' && item !== ignoreKey) {
            return filter[item].length > 0;
        } else if (typeof filter[item] === 'string' && item !== 'siteId') {
            return filter[item] !== '';
        } else if (typeof filter[item] === 'boolean') {
            return filter[item];
        }
    });
};

export const checkFilterExistsWithExceptions = (filter, exception?: string[]) => {
    return Object.keys(filter).some((item) => {
        if (exception && !exception.includes(item)) {
            if (Array.isArray(filter[item])) {
                return filter[item].length > 0;
            } else if (typeof filter[item] === 'string' && item !== 'siteId') {
                return filter[item] !== '';
            } else if (typeof filter[item] === 'boolean') {
                return filter[item];
            }
        }
    });
};
