import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { MockDrillReportParticipantsTab } from './mock-drill-report-participants-tab';
import { getFilteredLookupSitePeopleForMockdrillParticipants, getFilteredLookupSitePeopleWithCRORolesExclusively } from 'sharedSelectors';
import { loadPrivilegeRoles } from 'actions/actions-v2/role-actions-v2';
import { IRootState } from 'routes/store';


const getMockdrillParticipantsPrivilege = (state: IRootState) => {
    return state.manageRoles.grid.privilegeRoles?.privilegeRoles;
}

const mapStateToProps = createSelector(
    getFilteredLookupSitePeopleForMockdrillParticipants,
    getFilteredLookupSitePeopleWithCRORolesExclusively,
    getMockdrillParticipantsPrivilege,
    (sitePersons, croPersons, mockdrillParticipantsPrivilege) => ({
    sitePersons,
    croPersons,
    mockdrillParticipantsPrivilege
}));

const actions={loadPrivilegeRoles}

export const MockDrillReportParticipantsTabTabContainer = connect(
    mapStateToProps,
    actions
)(MockDrillReportParticipantsTab);
