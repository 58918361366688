import * as React from 'react';
import { Button } from '../components';
import useTranslate from 'translations/translation-utils';

interface IProps {
    title: string;
    message?: string | JSX.Element;
    getMessage?: () => string | JSX.Element;
    isVisible: boolean;
    isLoading?: boolean;
    id?: string;
    buttonCancelText?: string;
    buttonCancelHide?: boolean;
    buttonConfirmText?: string;
    showConfirmButton?: boolean;
    confirmButtonDisabled?: boolean;
    onConfirm?: () => void;
    onClose?: () => void;
    onOutsideDialogClick?: () => void;
}

export const ConfirmDialog: React.FC<IProps> = ({
    title,
    message: confirmMessage,
    getMessage,
    isVisible,
    isLoading,
    id = 'confirmation',
    buttonCancelText,
    buttonCancelHide = false,
    buttonConfirmText,
    showConfirmButton = true,
    confirmButtonDisabled = false,
    onClose,
    onConfirm,
    onOutsideDialogClick,
}) => {
    const translate = useTranslate();
    buttonCancelText = buttonCancelText
        ? buttonCancelText
        : translate('ConfirmNavigation.Label.Cancel');
    buttonConfirmText = buttonConfirmText
        ? buttonConfirmText
        : translate('ConfirmNavigation.Label.Confirm');
    const getConfirmMessage = () => {
        if (getMessage) {
            return getMessage();
        }
        if (typeof confirmMessage === 'string') {
            return (
                <div>
                    <strong>{confirmMessage}</strong>
                </div>
            );
        }

        return confirmMessage;
    };

    return isVisible ? (
        <div id={id} className={`modal modal-quantum is-clipped is-active`}>
            <div className="modal-background" onClick={onOutsideDialogClick} />
            <div className="modal-content">
                <h2>{title}</h2>
                {getConfirmMessage()}
                <div className="modal-buttons">
                    {!buttonCancelHide && (
                        <Button
                            buttonType="cancel"
                            onClick={onClose}
                            id="cancel-button"
                            disabled={isLoading}
                        >
                            {buttonCancelText}
                        </Button>
                    )}
                    {showConfirmButton && (
                        <Button
                            onClick={onConfirm}
                            isLoading={isLoading}
                            disabled={confirmButtonDisabled}
                            id="confirm-button"
                        >
                            {buttonConfirmText}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    ) : null;
};
