import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import actions from './actions';
import { CreateQuestionSetPage as View } from './create-question-set-page';
import selector from './selector';

export const CreateQuestionSetPage = withRouter(connect(
    selector,
    actions
)(View));
