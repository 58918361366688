import { IAppAction } from '../app-action';

export enum SiteRolesActions {
    LOAD_SITEROLES = '@@person/LOAD_SITEROLES',
    LOAD_SITEROLES_FULFILLED = '@@person/LOAD_SITEROLES_FULFILLED',
    LOAD_SITEROLES_REJECTED = '@@person/LOAD_SITEROLES_REJECTED',
}

export const loadSiteRoles = (): IAppAction => ({
    type: SiteRolesActions.LOAD_SITEROLES,
});
