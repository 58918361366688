import * as React from 'react';
import Page from 'components/v2/page/Page';
import { RouteComponentProps } from 'react-router';
import { IKeyValue } from 'models/key-value';
import { Button } from 'components/v2/button/Button';
import { ICalendarItem } from 'models/calendar';
import { calendarFormValidationSchema, CalendarEventForm } from '../calendar-event-form';
import { history } from 'routes/App';
import { Formik, FormikProps } from 'formik';
import { Loader } from 'components/loader';
import { IPersonLookup } from 'models/person-lookup';
import { ButtonWithConfirmDialog } from 'components/v2/components';
import { isEmpty } from 'lodash';
import useTranslate from 'translations/translation-utils';

let formikBag: FormikProps<Partial<ICalendarItem>>;
const CalendarUrl = '/Calendar';

interface IParams {
    id?: string;
    siteId?: string;
}

export interface IProps extends RouteComponentProps<IParams> {
    permissions: Array<IKeyValue<string>>;
    siteId: string;
    lookupSites: Array<IKeyValue<string>>;
    events?: ICalendarItem;
    lookupSitePeopleRoles: IPersonLookup[];
    isLoading: boolean;
    calenderEvent: ICalendarItem;
    onSiteChange: (siteId: string) => void;
    onLoadCalendar: () => void;
    onCreateCalendarEvent: (model: ICalendarItem) => void;
    onUpdateCalendarEvent: (model: Partial<ICalendarItem>) => void;
    onDeleteCalendarEvent: (id: string, siteId: string) => void;
    onLoadCalendarEvent: (id: string, siteId: string) => void;
    loadPersonsLookup: (siteId: string) => void;
}

export const CalendarDetailsPage: React.FC<IProps> = ({ match, ...props }) => {
    const translate = useTranslate();
    const [isValid, setIsValid] = React.useState(false);
    const calendarId = match.params.id;
    React.useEffect(() => {
        if (calendarId && props.siteId) {
            props.onLoadCalendarEvent(calendarId, props.siteId);
        }
    }, [calendarId, props.siteId]);

    React.useEffect(() => {
        if (isEmpty(props.lookupSitePeopleRoles)) {
            props.loadPersonsLookup(props.siteId);
        }
    }, [props.lookupSitePeopleRoles]);

    const onSubmit = (value: Partial<ICalendarItem>) => {
        props.onUpdateCalendarEvent(value);
    };

    const onSave = () => {
        formikBag.validateForm().then(() => {
            if (isValid) {
                formikBag.submitForm();
            }
        });
    };
    const getCloseModalBody = () => {
        return <p>{translate('CalenderDetails.Message.1')}</p>;
    };
    const onCloseCalenderEvent = () => {
        props.onDeleteCalendarEvent(calendarId, props.siteId);
    };

    return (
        <Page title="Calendar" className="CalendarDetailsPage">
            <Loader loading={props.isLoading}>
                <div>
                    <Formik<Partial<ICalendarItem>>
                        initialValues={props.calenderEvent}
                        onSubmit={onSubmit}
                        validationSchema={calendarFormValidationSchema}
                        isInitialValid={true}
                        render={(formik) => {
                            formikBag = formik;
                            setIsValid(formikBag.isValid);
                            return (
                                <CalendarEventForm
                                    formikBag={formikBag}
                                    lookupSitePeopleRoles={props.lookupSitePeopleRoles}
                                    isCalendarDetail={true}
                                />
                            );
                        }}
                        enableReinitialize={true}
                        validateOnBlur={true}
                        validateOnChange={false}
                    />
                    <div className="QuestionSetForm__actions">
                        <Button
                            className="button button-cancel"
                            id="cancel"
                            buttonType="cancel"
                            isLoading={props.isLoading}
                            onClick={() => history.push(CalendarUrl)}
                        >
                            {translate('Globals.Label.Cancel')}
                        </Button>
                        <ButtonWithConfirmDialog
                            message={getCloseModalBody()}
                            isButtonDisabled={props.isLoading || !isValid}
                            onConfirm={onCloseCalenderEvent}
                            buttonType={'archive'}
                            buttonText={translate('RiskActionTab.Button.Archive')}
                            title={translate('RiskRegisterUpsertPage.ConfirmDialog.ConfirmTitle')}
                        />
                        <Button
                            id="save"
                            buttonType="save"
                            className="is-pulled-right"
                            onClick={onSave}
                            isLoading={props.isLoading}
                            type="submit"
                        >
                            {translate('Globals.Label.Save')}
                        </Button>
                    </div>
                </div>
            </Loader>
        </Page>
    );
};
