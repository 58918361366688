import { IAppAction } from '../app-action';

export enum NotificationActions {
    MARK_ALL_READ = '@@nav/MARK_ALL_READ',
    MARK_ALL_READ_FULFILLED = '@@nav/MARK_ALL_READ_FULFILLED',
    MARK_ALL_READ_CANCELED = '@@nav/MARK_ALL_READ_CANCELED',
    MARK_ALL_READ_REJECTED = '@@nav/MARK_ALL_READ_REJECTED',
    LOAD_NOTIFICATION_COUNT = '@@nav/LOAD_NOTIFICATION_COUNT',
    LOAD_NOTIFICATION_COUNT_FULFILLED = '@@nav/LOAD_NOTIFICATION_COUNT_FULFILLED',
    LOAD_NOTIFICATION_COUNT_REJECTED = '@@nav/LOAD_NOTIFICATION_COUNT_FULFILLED',
}

export const onMarkAllRead = (): IAppAction => ({
    type: NotificationActions.MARK_ALL_READ,
});

export const loadNotificationCount = (siteId: string): IAppAction => ({
    type: NotificationActions.LOAD_NOTIFICATION_COUNT,
    payload: { siteId },
});
