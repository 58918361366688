import { IActionRegisterState } from 'state/action-state';
import { combineReducers } from 'redux';
import { IAppAction } from 'actions/app-action';
import { ActionActions } from 'actions/actions-v2/action-action-v2';
import { addMonthsToNow } from 'utils/date-utils';
import { IAction } from 'models/action';
import { AttachmentActions } from 'actions/actions-v2/attachment-action-v2';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import { s2ab } from 'utils/export-utils';
import saveAs from 'file-saver';

const INITIAL_STATE: IActionRegisterState = {
    actions: [],
    macroActions: [],
    action: {
        isActive: true,
        status: 'Open',
        statusId: 'Open',
        dueDate: addMonthsToNow(1).toISOString(),
        reminderDate: addMonthsToNow(1).toISOString(),
        categoryId: '',
        priorityId: '',
        description: '',
    } as IAction,
    isLoading: false,
    isExporting: false,
    attachments: [],
    history: [],
};

const history = (
    state: IActionRegisterState['history'] = INITIAL_STATE.history,
    action$: IAppAction
) => {
    switch (action$.type) {
        case ActionActions.LOAD_HISTORY_FULFILLED:
            return action$.payload ? action$.payload : state;
        case ActionActions.LOAD_HISTORY_REJECTED:
            return state;
        default:
            return state;
    }
};

const actions = (
    state: IActionRegisterState['actions'] = INITIAL_STATE.actions,
    appAction: IAppAction
) => {
    switch (appAction.type) {
        case ActionActions.LOAD_ACTIONS_FULFILLED:
            if (appAction.payload) {
                return appAction.payload;
            }

        default:
            return state;
    }
};

const attachments = (
    state: IActionRegisterState['attachments'] = INITIAL_STATE.attachments,
    appAction: IAppAction
) => {
    switch (appAction.type) {
        case AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_ACTION:
            if (appAction.payload) {
                return appAction.payload;
            }
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_ACTION:
            if (appAction.payload) {
                return [...state, ...appAction.payload];
            }
            return state;
        case AttachmentActions.DELETE_ATTACHMENT_FULFILLED:
            if (appAction.payload) {
                return state.filter(
                    (item: IAttachmentModel) => item.fileName !== appAction.payload
                );
            }
            return state;
        default:
            return state;
    }
};

const action = (
    state: IActionRegisterState['action'] = INITIAL_STATE.action,
    appAction: IAppAction
) => {
    switch (appAction.type) {
        case ActionActions.LOAD_ACTION_FULFILLED:
        case ActionActions.LOAD_MACRO_ACTION_FULFILLED:
            if (appAction.payload) {
                return appAction.payload;
            }

        case ActionActions.CREATE_ACTION_FULFILLED:
        case ActionActions.UPDATE_ACTION_FULFILLED:
        case ActionActions.CLOSE_ACTION_FULFILLED:
        case ActionActions.DELETE_ACTION_FULFILLED:
        case ActionActions.CLEAR_FORM:
            return INITIAL_STATE.action;

        default:
            return state;
    }
};

const isLoading = (
    state: IActionRegisterState['isLoading'] = INITIAL_STATE.isLoading,
    appAction: IAppAction
) => {
    switch (appAction.type) {
        case ActionActions.LOAD_ACTIONS:
        case ActionActions.LOAD_ACTION:
        case ActionActions.CREATE_ACTION:
        case ActionActions.UPDATE_ACTION:
        case ActionActions.CLOSE_ACTION:
        case ActionActions.DELETE_ACTION:
        case ActionActions.LOAD_HISTORY:
        case ActionActions.LOAD_MACRO_ACTIONS:
        case ActionActions.LOAD_MACRO_ACTION:
        case ActionActions.CREATE_MACRO_ACTION:
        case ActionActions.UPDATE_MACRO_ACTION:
        case ActionActions.CLOSE_MACRO_ACTION:
        case ActionActions.DELETE_MACRO_ACTION:
            return true;

        case ActionActions.LOAD_ACTIONS_FULFILLED:
        case ActionActions.LOAD_ACTIONS_REJECTED:
        case ActionActions.LOAD_ACTION_FULFILLED:
        case ActionActions.LOAD_ACTION_REJECTED:
        case ActionActions.CREATE_ACTION_FULFILLED:
        case ActionActions.CREATE_ACTION_REJECTED:
        case ActionActions.UPDATE_ACTION_FULFILLED:
        case ActionActions.UPDATE_ACTION_REJECTED:
        case ActionActions.CLOSE_ACTION_FULFILLED:
        case ActionActions.CLOSE_ACTION_REJECTED:
        case ActionActions.DELETE_ACTION_FULFILLED:
        case ActionActions.DELETE_ACTION_REJECTED:
        case ActionActions.LOAD_HISTORY_FULFILLED:
        case ActionActions.LOAD_MACRO_ACTION_FULFILLED:
        case ActionActions.LOAD_MACRO_ACTION_REJECTED:
        case ActionActions.LOAD_MACRO_ACTIONS_FULFILLED:
        case ActionActions.LOAD_MACRO_ACTIONS_REJECTED:
        case ActionActions.CREATE_MACRO_ACTION_FULFILLED:
        case ActionActions.CREATE_MACRO_ACTION_REJECTED:
        case ActionActions.UPDATE_MACRO_ACTION_FULFILLED:
        case ActionActions.UPDATE_MACRO_ACTION_REJECTED:
        case ActionActions.CLOSE_MACRO_ACTION_FULFILLED:
        case ActionActions.CLOSE_MACRO_ACTION_REJECTED:
        case ActionActions.DELETE_MACRO_ACTION_FULFILLED:
        case ActionActions.DELETE_MACRO_ACTION_REJECTED:
            return false;

        default:
            return state;
    }
};

const isExporting = (
    state: IActionRegisterState['isExporting'] = INITIAL_STATE.isExporting,
    appAction: IAppAction
) => {
    switch (appAction.type) {
        case ActionActions.EXPORT_ACTIONS:
            return true;
        case ActionActions.EXPORT_ACTIONS_FULFILLED:
            if (appAction.payload) {
                saveAs(
                    new Blob([s2ab(atob(appAction.payload.fileContents))], {
                        type: 'application/excel',
                    }),
                    appAction.payload.fileDownloadName
                );
            }

            return false;
        case ActionActions.EXPORT_ACTIONS_REJECTED:
            return false;
        default:
            return state;
    }
};

const macroActions = (
    state: IActionRegisterState['macroActions'] = INITIAL_STATE.macroActions,
    appAction: IAppAction
) => {
    switch (appAction.type) {
        case ActionActions.LOAD_MACRO_ACTIONS_FULFILLED:
            if (appAction.payload) {
                return appAction.payload;
            }

        case ActionActions.CREATE_MACRO_ACTION_FULFILLED:
            const newActions = [...state];
            newActions.push(appAction.payload);
            return newActions;

        case ActionActions.UPDATE_MACRO_ACTION_FOR_RISK_FULFILLED:
            const updatedItemIndex = state.findIndex(x => x.id === appAction.payload.id);
            const updatedActions = [...state];
            updatedActions.splice(updatedItemIndex, 1, appAction.payload);
            return updatedActions;

        default:
            return state;
    }
};

export const actionRegisterReducer = combineReducers<IActionRegisterState>({
    history,
    isLoading,
    actions,
    action,
    isExporting,
    attachments,
    macroActions,
});
