import { combineReducers } from 'redux';
import { IBaseState } from '../base-state';
import { IAppAction } from 'actions/app-action';
import { MacroRiskActions as MacroRiskActionsV2 } from 'actions/actions-v2/macro-risk-action-v2';
import { IRisk } from 'models/risk';
import { genericSort } from 'utils/sort-utils';

export interface IRootMacroRisksState extends IBaseState {
    risks: IMacroRisksState;
}

export interface IMacroRisksState {
    isLoading: boolean;
    items: IRisk[]
}

const INITIAL_STATE: IMacroRisksState = {
    isLoading: true,
    items: []
};



const isLoading = (
    state: IMacroRisksState['isLoading'] = INITIAL_STATE.isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case MacroRiskActionsV2.LOAD_MACRO_RISKS:
            return true;
        case MacroRiskActionsV2.LOAD_MACRO_RISKS_CANCELLED:
        case MacroRiskActionsV2.LOAD_MACRO_RISKS_REJECTED:
        case MacroRiskActionsV2.LOAD_MACRO_RISKS_FULFILLED:
            return false;
        default:
            return state;
    }
};

const items = (
    state: IMacroRisksState['items'] = INITIAL_STATE.items,
    action: IAppAction
) => {
    switch (action.type) {
        case MacroRiskActionsV2.LOAD_MACRO_RISKS_CANCELLED:
        case MacroRiskActionsV2.LOAD_MACRO_RISKS_REJECTED:
            return INITIAL_STATE.items;
        case MacroRiskActionsV2.LOAD_MACRO_RISKS_FULFILLED:
            if (action.payload) {
                return action.payload;
            }
            return state;
        case MacroRiskActionsV2.SORT_MACRO_RISKS:
            return genericSort(action.payload.sortColumn, [...state], action.payload.sortAscending);
        default:
            return state;
    }
};


export const macroRisksReducer = combineReducers<IMacroRisksState>({
    isLoading,
    items,
});
