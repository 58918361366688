import React from 'react';
import { connect } from 'react-redux';
import { Loader } from 'components/loader';
import actions from './actions';
import selectors from './selectors';
import {
    ICermListFilters,
    ICermGlobalOverview,
    ICermGlobalOverviewFilters,
} from 'models/cerm-global-overview';
import { Table, SortOrder, TableHeader, OverlayPanel } from 'components/v2/components';
import { orderBy } from 'lodash';
import { CermGlobalOverviewPageToolbar } from 'components/cerm-global-overview/cerm-global-overview-toolbar';
import { CermGlobalOverviewFilters } from 'components/cerm-global-overview/cerm-global-overview-filters';
import { IKeyValue } from 'models';
import { GetPercentage } from 'utils/decimal-utils';
import { checkPermission } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import Page from 'components/v2/page/Page';
import { checkFilterExists } from 'utils/array-utils';
import useTranslate from 'translations/translation-utils';
import { IOperatingPlatform } from 'models/operating-platform-model';
import { ICommonLookupModel } from 'models/common-lookup-model';

interface IProps {
    isLoading: boolean;
    isExporting: boolean;
    cermList: ICermGlobalOverview[];
    lookupClients: Array<IKeyValue<string>>;
    permissions: Array<IKeyValue<string>>;
    loadCermList: (filters: ICermListFilters) => void;
    exportData: () => void;
    exportCermSitesData: (operatingPlatform: string) => void;
    linesOfBusiness: ICommonLookupModel[];
    regions: Array<IKeyValue<string>>;
    operatingPlatforms: IOperatingPlatform[];
    OpsList: IOperatingPlatform[];
}

const CermGlobalOverviewPage: React.FC<IProps> = ({
    isLoading,
    isExporting,
    cermList,
    permissions,
    lookupClients,
    loadCermList,
    exportData,
    exportCermSitesData,
    linesOfBusiness,
    regions,
    operatingPlatforms,
    OpsList,
}) => {
    const translate = useTranslate();
    const [sortField, setSortField] = React.useState('siteName');
    const [sortOrder, setSortOrder] = React.useState(SortOrder.off);

    // filters
    const [showFilters, setShowFilters] = React.useState(false);
    const [filterKeywords, setFilterKeywords] = React.useState([]);
    const [selectedLineOfBusiness, setSelectedLineOfBusiness] = React.useState([]);
    const [selectedRegions, setSelectedRegions] = React.useState([]);
    const [selectedClient, setSelectedClient] = React.useState({ value: '', label: '' });
    const [selectedOperatingPlatforms, setSelectedOperatingPlatforms] = React.useState([]);

    React.useEffect(() => {
        loadCermList(buildRequestFiltersObject());
    }, [
        filterKeywords,
        selectedOperatingPlatforms,
        selectedLineOfBusiness,
        selectedRegions,
        selectedClient,
    ]);

    const onToggleHandler = (field: string) => {
        if (sortField !== field) {
            setSortField(field);
            setSortOrder(SortOrder.asc);
            return;
        }
        const sort = sortOrder === SortOrder.asc ? SortOrder.desc : SortOrder.asc;
        setSortOrder(sort);
    };

    const getSortState = (field: string): SortOrder => {
        if (field === sortField) {
            return sortOrder;
        }
        return SortOrder.off;
    };

    const getSortedList = (cermListArray): ICermGlobalOverview[] => {
        if (sortOrder === SortOrder.off) {
            return cermListArray;
        }
        return sortOrder === SortOrder.asc
            ? orderBy(cermListArray, [sortField], ['asc'])
            : orderBy(cermListArray, [sortField], ['desc']);
    };

    // const openDetails = (id: string) => {
    //     const pageUrl = '/xxxxxxxxx';

    //     onRouteChange(`${pageUrl}/${id}/details`);
    // };

    const onToggleFiltersHandler = () => {
        setShowFilters(showFilters ? false : true);
    };

    const onResetFiltersHandler = () => {
        setFilterKeywords([]);
        setSelectedOperatingPlatforms([]);
        setSelectedLineOfBusiness([]);
        setSelectedRegions([]);
        setSelectedClient({ value: '', label: '' });
    };

    const onAddFilterKeywordHandler = (keyword: string) => {
        if (!keyword) {
            return;
        }
        const newFilterKeywords = [...filterKeywords, keyword];
        setFilterKeywords(newFilterKeywords);
    };

    const onRemoveFilterKeywordHandler = keyword => {
        const newFilterKeywords = filterKeywords.filter(x => x !== keyword);
        setFilterKeywords(newFilterKeywords);
    };

    const onSelectClientHandler = (value: { value: string; label: string }) => {
        setSelectedClient(value);
    };

    const onCheckBoxFilterHandler = (key: string, element: string) => {
        if (key === 'operatingPlatform') {
            updateState(selectedOperatingPlatforms, setSelectedOperatingPlatforms, element);
            return;
        }

        if (key === 'lineOfBusiness') {
            updateState(selectedLineOfBusiness, setSelectedLineOfBusiness, element);
            return;
        }
        if (key === 'region') {
            updateState(selectedRegions, setSelectedRegions, element);
            return;
        }
    };

    const updateState = (collection: string[], setCollection: (value) => void, value: string) => {
        if (collection.indexOf(value) === -1) {
            const items = [...collection, value];
            setCollection(items);
        } else {
            const items = collection.filter(x => x !== value);
            setCollection(items);
        }
    };

    const onExportHandler = () => {
        exportData();
    };

    const getScoreMarker = (score: number) => {
        switch (true) {
            case score >= 70:
                return <mark className="completed">{GetPercentage(score)}</mark>;
            case score < 70 && score >= 50:
                return <mark className="compliant">{GetPercentage(score)}</mark>;
            case score < 50 && score > 0:
                return <mark className="score">{GetPercentage(score)}</mark>;
            default:
                return <mark className="grey">-</mark>;
        }
    };

    const buildMainTable = (cermListArray: ICermGlobalOverview[]) => (
        <Table>
            <thead>
                <tr>
                    <TableHeader
                        sortState={getSortState('siteName')}
                        onToggle={() => onToggleHandler('siteName')}
                        rowSpan={2}
                        className="border-right"
                    >
                        {translate('CermGlobalOverview.Lable.SiteName')}
                    </TableHeader>
                    <th colSpan={2} className="border-none border-right">
                        {translate('Cerm.ComparisonPanel.Labels.ExpertReview')}
                    </th>
                    <th colSpan={2} className="border-none border-right">
                        {translate('CermGlobalOverview.Lable.1')}
                    </th>
                    <th colSpan={4} className="border-none border-right">
                        {translate('CermGlobalOverview.Lable.2')}
                    </th>
                    <th colSpan={5} className="border-none border-right">
                        {translate('CermGlobalOverview.Lable.3')}
                    </th>
                    <th rowSpan={2}>{translate('CermGlobalOverview.Lable.4')}</th>
                </tr>
                <tr className="header-secondary">
                    <th>{translate('SchedulerWeek.Button.Calendar.buttonText')}</th>
                    <th className="border-right">
                        {translate('Dashboard.CermOverview.ScoreSmall')}
                    </th>
                    <th>{translate('SchedulerWeek.Button.Calendar.buttonText')}</th>
                    <th className="border-right">
                        {translate('Dashboard.CermOverview.ScoreSmall')}
                    </th>
                    <th>Core</th>
                    <th>M&amp;E</th>
                    <th>IT</th>
                    <th className="border-right">{translate('CermGlobalOverview.Lable.5')}</th>
                    <th>100</th>
                    <th>200</th>
                    <th>300</th>
                    <th>400</th>
                    <th className="border-right">500</th>
                </tr>
            </thead>
            <tbody>
                {getSortedList(cermListArray).map(item => (
                    <tr key={item.siteId}>
                        <td className="border-right">{item.siteName}</td>
                        <td className="no-wrap"><>{item.expertReview.date}</></td>
                        <td className="border-right"><>{getScoreMarker(item.expertReview.score)}</></td>
                        <td className="no-wrap"><>{item.currentCerm.date}</></td>
                        <td className="border-right">{getScoreMarker(item.currentCerm.score)}</td>
                        <td>{GetPercentage(item.complianceCore)}</td>
                        <td>{GetPercentage(item.complianceME)}</td>
                        <td>{GetPercentage(item.complianceIT)}</td>
                        <td className="border-right">{GetPercentage(item.complianceEnergy)}</td>
                        <td>{GetPercentage(item.compliance100)}</td>
                        <td>{GetPercentage(item.compliance200)}</td>
                        <td>{GetPercentage(item.compliance300)}</td>
                        <td>{GetPercentage(item.compliance400)}</td>
                        <td className="border-right">{GetPercentage(item.compliance500)}</td>
                        <td>{GetPercentage(item.selfAssessmentStatus)}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );

    const buildFiltersObject = (): ICermGlobalOverviewFilters => {
        return {
            filterKeywords,
            selectedOperatingPlatforms,
            selectedLineOfBusiness,
            selectedRegions,
            selectedClient,
        };
    };

    const buildRequestFiltersObject = () => {
        return {
            filterKeywords,
            selectedOperatingPlatforms,
            selectedLineOfBusiness,
            selectedRegions,
            selectedClient: selectedClient.value,
        };
    };
    const isFilterExists = checkFilterExists(buildRequestFiltersObject());

    return (
        <Page
            title={translate('SideNavBar.Labels.CERMGlobalOverview')}
            className="cerm-global-overview-page"
            isAdminPage={true}
        >
            <CermGlobalOverviewPageToolbar
                onShowFilters={onToggleFiltersHandler}
                onClearFilters={onResetFiltersHandler}
                onExport={onExportHandler}
                onCermDownload={exportCermSitesData}
                isLoading={isLoading}
                isExporting={isExporting}
                hasPermissionToExportSitesOverview={checkPermission(
                    Privilege.CermAssessmentExport,
                    permissions
                )}
                isFilterExists={isFilterExists}
                OpsList={OpsList}
            />
            <OverlayPanel
                title={translate('RiskRegisterPage.Title.Filters')}
                isVisible={showFilters}
                onClose={() => setShowFilters(false)}
                onOutsideDialogClick={() => setShowFilters(false)}
                onClearFilters={onResetFiltersHandler}
                isFilterExists={isFilterExists}
            >
                <CermGlobalOverviewFilters
                    filters={buildFiltersObject()}
                    onAddFilterKeyword={onAddFilterKeywordHandler}
                    onRemoveFilterKeyword={onRemoveFilterKeywordHandler}
                    // checkboxes
                    onCheckBoxFilter={onCheckBoxFilterHandler}
                    selectedOperatingPlatforms={selectedOperatingPlatforms}
                    selectedLinesOfBusiness={selectedLineOfBusiness}
                    selectedRegions={selectedRegions}
                    // dropdown
                    clients={lookupClients}
                    onSelectClient={onSelectClientHandler}
                    linesOfBusiness={linesOfBusiness}
                    regions={regions}
                    operatingPlatforms={operatingPlatforms}
                />
            </OverlayPanel>
            <Loader loading={isLoading}>
                {cermList && cermList.length > 0
                    ? buildMainTable(cermList)
                    : !isLoading && (
                        <div className="has-text-centered">
                            {translate('RiskRegisterPage.Message.NoDataAvailable')}
                        </div>
                    )}
            </Loader>
        </Page>
    );
};

export default connect(selectors, actions)(CermGlobalOverviewPage);
