import * as React from 'react';
import { CheckboxFilter, ICustomCheckboxFilters } from '../../../../../components/checkboxFilter';
import { CheckboxFilterBackgroundColor } from '../../../../../components/filterSet/CheckboxFilterSet';
import { SearchFilter } from '../../../../../components/searchFilter';
import { IKeyValue } from '../../../../../models/key-value';
import { IRiskFilters } from 'models/IRiskFilters';
import useTranslate from 'translations/translation-utils';
import { ISiteProfile } from 'models/site-profile';
import { ICommonLookupModel } from 'models/common-lookup-model';
import * as RiskStatus from 'models/risk-statuses';

export interface IStateProps {
    filters: IRiskFilters;
    expandedFilters: string[];
    lookupRiskCategories: ICommonLookupModel[];
    lookupRiskLevels: Array<IKeyValue<string>>;
    lookupRiskStatus: Array<IKeyValue<string>>;
    lookupRiskSpecial: Array<IKeyValue<string>>;
    hideFilters?: string[];
    intl: any;
    site: ISiteProfile;
}

export interface IDispatchProps {
    onFilter: (field: string, value: string) => void;
    onToggleFilterExpanded: (id: string) => void;
    onAddFilterKeyword: (keyword: string) => void;
    onRemoveFilterKeyword: (keyword: string) => void;
}

interface IProps extends IStateProps, IDispatchProps { }

export const RiskFilters: React.FC<IProps> = ({
    filters,
    expandedFilters,
    lookupRiskCategories,
    lookupRiskLevels,
    lookupRiskStatus,
    lookupRiskSpecial,
    site,
    ...props
}) => {
    const commonProps = {
        expandedFilters,
        onFilter: props.onFilter,
        onToggleFilterExpanded: props.onToggleFilterExpanded,
    };

    const showRiskStatus = !props.hideFilters || props.hideFilters.length === 0 || !props.hideFilters.some((s) => s === 'riskStatus');
    const showRiskCategory = !props.hideFilters || props.hideFilters.length === 0 || !props.hideFilters.some((s) => s === 'riskCategory');
    const showRiskSpecial = !props.hideFilters || props.hideFilters.length === 0 || !props.hideFilters.some((s) => s === 'riskSpecial');
    const riskCategories = lookupRiskCategories.filter(
        (x) => !x.operatingPlatform || x.operatingPlatform === site.operatingPlatform
    );

    const getUnreservedRiskStatuses = (riskStatuses: Array<IKeyValue<string>>) => {
        return riskStatuses.filter((status) => status.key !== RiskStatus.CLOSED);
    };

    const getCustomRiskStatuses = () => {
        const customFilters: ICustomCheckboxFilters[] = [
            {
                id: RiskStatus.CLOSED,
                onFilter: props.onFilter,
                color: CheckboxFilterBackgroundColor.reserved,
                filters: [{ key: RiskStatus.CLOSED, value: 'Closed (Only)' }]
            }
        ];
        return customFilters;
    };

    const translate = useTranslate();

    return (
        <>
            <SearchFilter
                title={translate('RiskRegisterPage.RiskFilter.Title.SearchByKeywords')}
                name="filterKeywords"
                values={filters.filterKeywords}
                onAddFilterKeyword={props.onAddFilterKeyword}
                onRemoveFilterKeyword={props.onRemoveFilterKeyword}
            />
            {showRiskSpecial &&
                <CheckboxFilter
                    {...commonProps}
                    title={translate('RiskRegisterPage.RiskFilter.Title.SpecialFilters')}
                    name="riskSpecial"
                    selectedFilters={filters.riskSpecial}
                    unreservedFilters={lookupRiskSpecial}
                />}

            {showRiskStatus &&
                <CheckboxFilter
                    {...commonProps}
                    title={translate('RiskRegisterPage.RiskFilter.Title.Status')}
                    name="riskStatus"
                    selectedFilters={filters.riskStatus}
                    unreservedFilters={getUnreservedRiskStatuses(lookupRiskStatus)}
                    customFilters={getCustomRiskStatuses()}
                />}

            {showRiskCategory &&
                <CheckboxFilter
                    {...commonProps}
                    title={translate('RiskRegisterPage.RiskFilter.Title.Category')}
                    name="riskCategory"
                    selectedFilters={filters.riskCategory}
                    unreservedFilters={riskCategories}
                />}
            <CheckboxFilter
                {...commonProps}
                title={translate('RiskRegisterPage.RiskFilter.Title.Level')}
                name="riskLevel"
                selectedFilters={filters.riskLevel}
                unreservedFilters={lookupRiskLevels}
            />
        </>
    );
};
