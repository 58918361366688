import * as React from 'react';
import { Button, ConfirmDialog, Table, TriStateToggle } from '../v2/components';
import { ICermProcessStepPlatform, IMasterProcessStep, cermTechnologyPlatform } from 'models/master-process-step/master-process-step';
import { IProcessStepResult } from 'models/cerm/cerm-assessment-result';
import { GetPercentage } from 'utils/decimal-utils';
import { CermComplianceScore } from './CermComplianceScore';
import { sumBy, isEmpty, cloneDeep } from 'lodash';
import { isProcessStepExistInMaster } from './shared/cerm-utils';
import { checkPermission } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import { IKeyValue } from 'models/key-value';
import { IExpertReviewStatus } from 'models/cerm/cerm-assessment-result';
import { getUserId } from '../../helpers/helpers';
import { SelectField } from 'components/form/fields/select-field';
import ReactTooltip from 'react-tooltip';
import appConfig from 'helpers/config-helper';
import { MaterialIconColor, MaterialIconType } from 'routes/material-icon/material-icon-type';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import useTranslate from 'translations/translation-utils';
import { IOperatingPlatform } from 'models/operating-platform-model';
import { getTitlePrefixGeneric } from 'models/cerm/cerm-assessment-result';

interface IProps {
    pillar: string;
    pillarMasterProcessSteps: IMasterProcessStep[];
    pillarProcessStepResults: IProcessStepResult[];
    updateProcessStepResult: (model: IProcessStepResult) => void;
    onRouteChange: (url: string) => void;
    ShowTabDisabledDialog: (show: boolean) => void;
    permissions: Array<IKeyValue<string>>;
    expertReviewStatus: IExpertReviewStatus
    authToken: string;
    getECermToken: () => void;
    allOperatingPlatforms: IOperatingPlatform[];
}

export const CermProcessStepsTable: React.FC<IProps> = ({
    pillar,
    pillarMasterProcessSteps,
    pillarProcessStepResults,
    permissions,
    expertReviewStatus,
    authToken,
    updateProcessStepResult,
    onRouteChange,
    ShowTabDisabledDialog,
    getECermToken,
    allOperatingPlatforms,
}) => {
    const translate = useTranslate();
    const [cermProcessStepPlatform, setCermProcessStepPlatform] = React.useState<ICermProcessStepPlatform>(null);
    const [isDialogVisible, setIsDialogVisible] = React.useState(false);
    const [isTechnologyDialogVisible, setIsTechnologyDialogVisible] = React.useState(false);
    const [newPillarProcessStepResults, setNewPillarProcessStepResults] = React.useState<IProcessStepResult[]>([])
    const ecermUrl = appConfig().REACT_APP_ECERM_URL.toLowerCase();
    const titlePrefix = pillarMasterProcessSteps.length > 0 ?
        getTitlePrefixGeneric(pillarMasterProcessSteps[0].operatingPlatform, allOperatingPlatforms) : "";

    React.useEffect(() => {
        setNewPillarProcessStepResults(cloneDeep(pillarProcessStepResults))
    }, pillarProcessStepResults)

    React.useEffect(() => {
        ReactTooltip.rebuild();
    });
    const hasProcessStepResults = () => {
        return !isEmpty(newPillarProcessStepResults);
    };

    React.useEffect(() => {
        if (authToken == null || authToken === '') getECermToken();
    }, [authToken]);

    const canEdit = () => {
        return (expertReviewStatus === null
            || expertReviewStatus.startedById === undefined
            || (expertReviewStatus.startedById === null)
            || expertReviewStatus.isInReview === false
            || expertReviewStatus.startedById === getUserId()
        )
    }

    const getApplicable = (masterProcessStep: IMasterProcessStep): boolean => {
        if (hasProcessStepResults()) {
            const found = newPillarProcessStepResults?.find(
                (r) => r.masterProcessStepId === masterProcessStep.id
            );
            if (found) {
                return found.applicable;
            }
        }
        return null;
    }

    const getResults = (masterProcessStep: IMasterProcessStep) => {
        let completedOutcomes = 0;
        let compliance = '-';
        let applicable = null;

        if (hasProcessStepResults()) {
            const found = newPillarProcessStepResults.find(
                (r) => r.masterProcessStepId === masterProcessStep.id
            );
            if (found) {
                completedOutcomes = found.completedOutcomes;
                compliance =
                    found.compliance || found.compliance === 0
                        ? GetPercentage(found.compliance)
                        : '-';
                applicable = found.applicable;
            }
        }

        let tdProps = null;
        if (applicable === true && canEdit()) {
            tdProps = {
                onClick: () => onRouteChange(`/CermAssessment/ProcessStep/${masterProcessStep.id}`),
                className: 'clickable'
            };
        }

        const setCermProcessStepPlatformUrl = (technologyPlatform) => {
            setCermProcessStepPlatform(technologyPlatform);
            setIsDialogVisible(true)
        }
        const getCermTechnologyPlatform = (masterProcessStep: IMasterProcessStep) => {
            if (hasProcessStepResults()) {
                const found = newPillarProcessStepResults?.find(
                    (r) => r.masterProcessStepId === masterProcessStep.id
                );
                if (found) {
                    return found.cermTechnologyPlatform ? { label: found.cermTechnologyPlatform, value: found.cermTechnologyPlatform } : { label: translate('LogsUpload.LogType.PleaseSelect'), value: '' }
                        ;
                }
            }
            return null;
        }

        const setCermTechnologyPlatform = (masterProcessStep: IMasterProcessStep,
            technologyPlatform?: string) => {

            let processStepResults: IProcessStepResult[] = cloneDeep(newPillarProcessStepResults)
            let processStepResult = processStepResults?.find(
                (r) => r.masterProcessStepId === masterProcessStep.id
            );

            if (processStepResult) {
                processStepResult.cermTechnologyPlatform = technologyPlatform;
            }
            else {
                processStepResult = {
                    completedOutcomes: 0,
                    compliance: 0,
                    pillar: masterProcessStep.pillar,
                    priority: masterProcessStep.priority,
                    service: masterProcessStep.service,
                    masterProcessStepId: masterProcessStep.id,
                    applicable: null,
                    cermTechnologyPlatform: technologyPlatform,
                }
                processStepResults.push(processStepResult)
            }

            setNewPillarProcessStepResults(processStepResults)
            updateProcessStepResult(processStepResult)
        }

        return (
            <tr key={masterProcessStep.id}>
                <td {...tdProps}>{masterProcessStep.processNumber}</td>
                <td {...tdProps}>{masterProcessStep.title}</td>
                <td {...tdProps}>{masterProcessStep.service}</td>
                <td {...tdProps}>{masterProcessStep.phase}</td>
                <td {...tdProps}>{masterProcessStep.priority}</td>
                <td {...tdProps}>
                    {getCompletion(
                        applicable,
                        completedOutcomes,
                        masterProcessStep.expectedOutcomes.length
                    )}
                </td>
                <td {...tdProps}>{compliance}</td>
                <td>
                    <TriStateToggle
                        toggleFor={masterProcessStep.id}
                        selectedValue={getApplicable(masterProcessStep)}
                        onChange={(value) => {
                            checkPermission(
                                Privilege.CermAssessmentApplicabilityUpdate,
                                permissions
                            ) && canEdit()
                                ? setProcessStepApplicable(masterProcessStep, value)
                                : ShowTabDisabledDialog(true);
                        }}
                    />
                </td>

                <td className="cerm-technology-platform">
                    {masterProcessStep.cermProcessStepPlatform && getApplicable(masterProcessStep) &&
                        <div className="cerm-technology-platform__dropdown" data-tip={getCermTechnologyPlatform(masterProcessStep) && getCermTechnologyPlatform(masterProcessStep).label} >
                            <SelectField
                                id="cermTechnologyPlatform"
                                label={""}
                                value={getCermTechnologyPlatform(masterProcessStep)}
                                options={cermTechnologyPlatform.map((c) => ({
                                    label: c.label,
                                    value: c.value,
                                }))}
                                handleChange={(value) => setCermTechnologyPlatform(masterProcessStep, value.value)}
                                isDisabled={!canEdit}
                            />
                        </div>
                    }



                </td>
                <td>
                    {masterProcessStep.cermProcessStepPlatform &&
                        <a
                            onClick={() => setCermProcessStepPlatformUrl(masterProcessStep.cermProcessStepPlatform)}
                        >
                            < MaterialIcons type={MaterialIconType.Help} color={MaterialIconColor.green} />
                        </a>}

                </td>

            </tr>
        );
    };

    const getCompletion = (applicable: boolean, completed: number, total: number) => {
        if (!applicable) {
            return '-';
        }

        let className = '';
        if (completed < 1) {
            className = 'is-danger';
        } else if (completed < total) {
            className = 'is-warning';
        }

        return (
            <span className={className}>
                {completed}/{total}
            </span>
        );
    };

    const getComplianceScore = () => {
        if (!hasProcessStepResults()) {
            return null;
        }

        const results = newPillarProcessStepResults.filter(
            (r) =>
                r.pillar === pillar &&
                r.applicable !== false &&
                isProcessStepExistInMaster(pillarMasterProcessSteps, r)
        );
        if (isEmpty(results)) {
            return 0;
        }

        const totalMasterProcessSteps = pillarMasterProcessSteps.length;
        const totalNotApplicableResults = newPillarProcessStepResults.countMatches(
            (r) =>
                r.pillar === pillar &&
                r.applicable === false &&
                isProcessStepExistInMaster(pillarMasterProcessSteps, r)
        );
        const totalApplicableProcessSteps = totalMasterProcessSteps - totalNotApplicableResults;

        return (
            sumBy(results, (r) => (r.compliance ? r.compliance : 0)) / totalApplicableProcessSteps
        );
    };

    const setProcessStepApplicable = (
        masterProcessStep: IMasterProcessStep,
        applicable?: boolean
    ) => {
        let processStepResults: IProcessStepResult[] = cloneDeep(newPillarProcessStepResults)
        let processStepResult = processStepResults?.find(
            (r) => r.masterProcessStepId === masterProcessStep.id
        );

        if (processStepResult) {
            processStepResult.applicable = applicable;
        }
        else {
            processStepResult = {
                completedOutcomes: 0,
                compliance: 0,
                pillar: masterProcessStep.pillar,
                priority: masterProcessStep.priority,
                service: masterProcessStep.service,
                masterProcessStepId: masterProcessStep.id,
                applicable: applicable,
                cermTechnologyPlatform: null,
            }
            processStepResults.push(processStepResult)
        }

        setNewPillarProcessStepResults(processStepResults)
        updateProcessStepResult(processStepResult)
    }

    const openUrl = (url: string) => {
        if (url.toLocaleLowerCase().startsWith(ecermUrl)) {
            let separator = '?';
            if (url.indexOf('?') !== -1) {
                separator = '&';
            }
            window.open(`${url}${separator}auth-token=${authToken}`, '_blank');
        } else {
            window.open(url, '_blank');
        }
    }
    const getBody = () => {

        return (
            <>
                <div className="cerm-process-step-platform-dialog">
                    <p>{cermProcessStepPlatform && cermProcessStepPlatform.technologyPlatform}</p>
                    {cermProcessStepPlatform && cermProcessStepPlatform.moreInfoLink && cermProcessStepPlatform.moreInfoDisplayLabel &&

                        <Button
                            id="btnSaveAction"
                            onClick={() => openUrl(cermProcessStepPlatform.moreInfoLink)}
                        >
                            {cermProcessStepPlatform.moreInfoDisplayLabel}

                        </Button>}

                </div>
            </>
        )

    }
    const getTechnologyPlatformBody = () => {
        return (
            <>
                <div className="cerm-process-dialog">
                    <p><strong>Quantum Technology utilization review</strong></p>

                    <p> The technology platform information is now part of the {titlePrefix} assessment. The data is part of an accounts assessment of its technology maturity, and support the prioritisation of resources to help digitise processes, minimise administration, create insight.</p>

                    <p> For each applicable {titlePrefix} process, please select which of the following technologies is in usage:</p>
                    <ul>
                        <li><strong>Using customer's platforms</strong>
                            <p> A system owned by the customer dedicated to the process - CMMS, BMS, ticketing system.</p></li>
                        <li><strong>Using customer's MS Office or equivalent</strong>
                            <p> Excel, Word, or PowerPoint where the files are stored in a customer owned storage (SharePoint or shared drives)</p></li>
                        <li><strong>Using offline solution</strong>
                            <p>A mixed use of paper, whiteboards, and binders</p></li>
                        <li><strong>Using CBRE's MS Office or equivalent</strong>
                            <p>Excel, Word, or PowerPoint where the files are stored in a customer owned storage (SharePoint or shared drives)</p></li>
                        <li><strong>Using CBRE's platforms</strong>
                            <p>A system owned by CBRE dedicated to the process - Quantum, SI, eLogbooks, WebQuote...
                                If you have any queries please contact your site Technology Champion.</p></li>
                    </ul>
                </div>
            </>
        )
    }


    return (
        <>
            <CermComplianceScore score={getComplianceScore()} />
            <Table>
                <thead>
                    <tr>
                        <th className="narrow">#</th>
                        <th>{titlePrefix} {translate('CermAssessment.Lable.2')}</th>
                        <th className="narrow">{translate('Cerm.AssessmentFilters.Labels.Service')}</th>
                        <th className="narrow">{translate('Cerm.AssessmentFilters.Labels.Phase')}</th>
                        <th className="narrow">{translate('Cerm.AssessmentFilters.Labels.Priority')}</th>
                        <th className="narrow">{translate('Cerm.AssessmentFilters.Labels.Completion')}</th>
                        <th className="narrow">{translate('Cerm.ComplianceScore.Labels.Compliance')}</th>
                        <th className="narrow">{translate('Cerm.AssessmentFilters.Labels.Applicable')}</th>
                        <th className="narrow cerm-technology-platform">{translate('Cerm.AssessmentFilters.Labels.Technology')}</th>
                        <th>
                            <a onClick={() => setIsTechnologyDialogVisible(true)}>
                                <MaterialIcons type={MaterialIconType.Help} color={MaterialIconColor.green} />
                            </a>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {pillarMasterProcessSteps &&
                        pillarMasterProcessSteps.map((step) => getResults(step))}
                </tbody>
            </Table>
            <ConfirmDialog
                title="Information"
                message={getBody()}
                isVisible={isDialogVisible}
                showConfirmButton={false}
                onClose={() => setIsDialogVisible(false)}
                onOutsideDialogClick={() => setIsDialogVisible(false)}
                buttonCancelText={translate('Globals.Label.Cancel')}
            />
            <ConfirmDialog
                title="Information"
                message={getTechnologyPlatformBody()}
                isVisible={isTechnologyDialogVisible}
                showConfirmButton={false}
                onClose={() => setIsTechnologyDialogVisible(false)}
                onOutsideDialogClick={() => setIsTechnologyDialogVisible(false)}
                buttonCancelText={translate('Globals.Label.Cancel')}
            />

        </>
    );
};
