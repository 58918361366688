import { createSelector } from 'reselect';
import { IRootState } from './routes/store';
import { checkRole } from './utils/permission-utils';
import { IKeyValue } from './models';
import _ from 'lodash';

export const getCombinedSiteFeatures = createSelector(
    (state: IRootState) => state.app.lookupSitesFeatures,
    (siteFeatures) => {
        const uniqueFeaturesList: string[] = [];
        _.forEach(siteFeatures, (site) => {
            _.forEach(site.value, (featureString) => {
                if (uniqueFeaturesList && uniqueFeaturesList.indexOf(featureString) === -1) {
                    uniqueFeaturesList.push(featureString);
                }
            });
        });
        return uniqueFeaturesList;
    }
);

export const getCurrentSiteFeatures = createSelector(
    (state: IRootState) => state.app.lookupSitesFeatures,
    (state: IRootState) => state.app.siteId,
    (siteFeatures, siteId) => {
        const uniqueFeaturesList: string[] = [];
        const site: IKeyValue<string[]> = siteFeatures.find((x) => x.key === siteId);
        if (site) {
            _.forEach(site.value, (featureString) => {
                if (uniqueFeaturesList && uniqueFeaturesList.indexOf(featureString) === -1) {
                    uniqueFeaturesList.push(featureString);
                }
            });
        }
        return uniqueFeaturesList;
    }
);

export const getIsClient = createSelector(
    (state: IRootState) => state.app.permissions,
    (permissions) => checkRole('Client', permissions)
);

export const getPermissions = createSelector(
    (state: IRootState) => state.app,
    (appState) => appState.permissions
);

export const getCurrentSelectedSiteId = createSelector(
    (state: IRootState) => state.app,
    (appState) => appState.siteId
);

export const getSitesWithFeatures = createSelector(
    (state: IRootState) => state.app.lookupSitesFeatures,
    (siteFeatures) => siteFeatures
);
export const getSelectedSite = createSelector(
    (state: IRootState) => state.app,
    (appState) =>
        appState.lookupSites.length > 0 && appState.siteId && appState.siteId.length > 0
            ? appState.lookupSites.find((site) => site.key === appState.siteId)
            : null
);

export const getLineOfBusinessLookUps = createSelector(
    (state: IRootState) => state.app,
    (appState) => appState.lookupLineOfBusiness
);

export const getRegionsLookUps = createSelector(
    (state: IRootState) => state.app,
    (appState) => appState.lookupRegion
);

export const getPossibleEngineersForSite = (state: IRootState) => {
    const siteWideAloudUserRoleList = ['Site Manager', 'Technician'];
    return state.app.lookupSitePeopleRoles.filter((c) =>
        c.roleNames.some((s) => siteWideAloudUserRoleList.indexOf(s) >= 0)
    );
};

export const getFilteredLookupSitePeopleWithAllowedRoles = (state: IRootState) => {
    if (state.app.siteId && state.app.allowedDropDownRoles) {
        return state.lookups.personLookups.filter(
            (x) =>
                (x.siteIds.includes(state.app.siteId) &&
                    x.roleNames.some((s) => state.app.allowedDropDownRoles.indexOf(s) >= 0)) ||
                x.roleNames.includes('CRO')
        );
    }
    return state.lookups.personLookups;
};

export const getFilteredLookupSiteRolesRiskQuestionsPeopleWithAllowed = (state: IRootState) => {
    const siteWideRiskQuestionsUpdateRoleList = [
        'Area Manager',
        'Contract Support',
        'CRO',
        'Executive Leader',
        'Leader',
        'Regional Leader',
        'Site Manager',
        'Global Super User - DC',
        'Global Super User - HC',
        'Product Administrator',
    ];

    if (state.app.siteId) {
        return state.lookups.personLookups.filter(
            (x) =>
            (x.siteIds.includes(state.app.siteId) &&
                x.roleNames.some((s) => siteWideRiskQuestionsUpdateRoleList.indexOf(s) >= 0))
        );
    }
    return state.lookups.personLookups;
};

export const getFilteredLookupSitePeopleForMockdrillParticipants = (state: IRootState) => {
    if (state.app.siteId && state.app.allowedDropDownRoles) {
        return state.lookups.personLookups.filter(
            (x) =>
                (x.siteIds.includes(state.app.siteId) &&
                    x.roleNames.some((s) => state.app.allowedDropDownRoles.indexOf(s) >= 0)) ||
                x.roleNames.includes('CRO')
        );
    }
    return state.lookups.personLookups;
};

export const getFilteredLookupSitePeopleWithCRORolesExclusively = (state: IRootState) => {
    if (state.app.siteId && state.app.allowedDropDownRoles) {
        return state.lookups.personLookups.filter(
            (x) =>
                !(x.siteIds.includes(state.app.siteId) &&
                    x.roleNames.some((s) => state.app.allowedDropDownRoles.indexOf(s) >= 0)) &&
                x.roleNames.includes('CRO')
        );
    }
    return state.lookups.personLookups;
};
export const getFilteredLookupSitePeopleWithRoles = (state: IRootState) => {
    const siteWideAloudUserRoleList = [
        'Area Manager',
        'Contract Support',
        'Executive Leader',
        'Leader',
        'Regional Leader',
        'Site Manager',
        'Technician',
    ];
    return state.app.lookupSitePeopleRoles.filter((c) =>
        c.roleNames.some((s) => siteWideAloudUserRoleList.indexOf(s) >= 0)
    );
};

export const getSitePeopleWithSpecificRoles = (state: IRootState) => {
    const siteWideAloudUserRoleList = [
        'Area Manager',
        'Contract Support',
        'Executive Leader',
        'Product Administrator',
        'Leader',
        'Regional Leader',
        'Site Manager',
        'Technician',
    ];
    return state.app.lookupSitePeopleRoles.filter((c) =>
        c.roleNames.some((s) => siteWideAloudUserRoleList.indexOf(s) >= 0)
    );
};

export const getLookupRiskCategories = (state: IRootState) => state.app.lookupRiskCategories;
export const getLookupRiskLevels = (state: IRootState) => state.app.lookupRiskLevel;
export const getLookupRiskStatus = (state: IRootState) => state.app.lookupRiskStatus;
export const getLookupRiskSpecial = (state: IRootState) => state.app.lookupRiskSpecial;
export const getLookupActionPriorities = (state: IRootState) => state.app.lookupActionPriorities;
export const getLookupActionStatuses = (state: IRootState) => state.app.lookupActionStatus;
export const getLookupActionCategories = (state: IRootState) => state.app.lookupActionCategories;
export const getLookupSites = (state: IRootState) => state.app.lookupSites;
export const getLookupSitesClient = (state: IRootState) => state.app.lookupSitesClient;
export const getShiftHandoverSettings = (state: IRootState) => state.shiftHandoverState.settings;
export const getLookupActionSpecial = (state: IRootState) => state.app.lookupActionSpecial;
