import { createSelector } from 'reselect';
import { IRootRisksState } from '../../../../reducers/risks';
import {
    getLookupRiskCategories,
    getLookupRiskLevels,
    getLookupRiskStatus,
    getLookupRiskSpecial,
} from 'sharedSelectors';
import { IRootState } from 'routes/store';

const getSite = (state: IRootState) => state.siteState.site;

const getRisksState = createSelector(
    (state: IRootRisksState) => state.risks,
    (state) => state
);

export default createSelector(
    getRisksState,
    getLookupRiskCategories,
    getLookupRiskLevels,
    getLookupRiskStatus,
    getLookupRiskSpecial,
    getSite,
    (state, lookupRiskCategories, lookupRiskLevels, lookupRiskStatus, lookupRiskSpecial, site) => ({
        ...state,
        ...state.grid,
        lookupRiskCategories,
        lookupRiskLevels,
        lookupRiskStatus,
        lookupRiskSpecial,
        site,
    })
);
