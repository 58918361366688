/* eslint-disable @typescript-eslint/no-inferrable-types */
import { IEmeraldTitleInput } from '../../shared/title.input';
import { IEmeraldTabData } from '../tab.data';
export class EmeraldTabInputs implements IEmeraldTabInputs {
  title?: string;
  data?: IEmeraldTabData;
  isStackable: boolean = false;
}

export interface IEmeraldTabInputs extends IEmeraldTitleInput {
  data?: IEmeraldTabData;
  isStackable: boolean;
}
