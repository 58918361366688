import React, { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { IMenu } from 'models/menu-model';
import { uniqueId } from 'lodash';
import { easeInScrollToTop } from 'utils/animation-utils';
import { findReplaceString } from 'helpers/helpers';

interface IStateProps extends RouteComponentProps {
    navItem?: IMenu;
    onMenuItemClick: () => void;
    siteId?: string;
    onSelectedMenuItem: (selectedMenu: string) => void;
}

const SideDynamicNavMenu: React.FC<IStateProps> = ({
    navItem,
    location,
    history,
    match,
    onMenuItemClick,
    siteId,
    onSelectedMenuItem,
}) => {
    const [itemStatus, setItemStatus] = useState<string>('');
    const hasChild = navItem.children && navItem.children.length > 0;

    useEffect(() => {
        if (
            hasChild &&
            navItem.children.some(item => !item.isPreview && isLocationMatch(item.module.link))
        ) {
            setItemStatus('show');
        }
    }, [location]);

    const isMenuItemVisible = () => {
        if (!navItem.isVisible) {
            return false;
        }
        if (hasChild && navItem.children.every(c => !c.isVisible)) {
            return false;
        }
        return true;
    };

    const getListItemClassName = () => {
        let className = `${itemStatus}`;
        return className;
    };

    const getLinkItemClassName = () => {
        let className = '';
        if (hasChild) {
            className = 'sidenav-menu-toggle';
        } else if (!navItem.isPreview && isLocationMatch(navItem.module.link)) {
            className = 'active';
        }

        // To be reverted in User Story 2635263: [Quantum UX] Navigation
        // if (navItem.iconName) {
        //     className += ` menuicon menuicon-${navItem.iconName}`;
        // }

        return className;
    };

    const isLocationMatch = (link: string): boolean => {
        if (link === location.pathname) {
            return true;
        }
        const linkPathToCompare = getCompareString(link);
        const locationPathToCompare = location.pathname && getCompareString(removeUrlParams(location.pathname));
        return `${locationPathToCompare}/`.search(new RegExp(`${linkPathToCompare}/`, 'i')) > -1;
    };

    const getCompareString = (value: string) => {
        return value.slice(-1) === 's' ? value.replace(/.$/s, '') : value;
    };

    const navItemText =
        navItem.module && navItem.module.link.includes('${')
            ? findReplaceString(navItem.module.link, siteId)
            : navItem.module.link;

    const removeUrlParams = (value: string) => {
        const x = value.split('/').slice(1);
        const y = '/' + x[0];
        return y;
    };

    const onItemClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        onSelectedMenuItem(event.currentTarget.innerHTML)
        if (!hasChild) {
            easeInScrollToTop();
            onMenuItemClick();
            return;
        }
        setItemStatus(itemStatus === '' ? 'show' : '');
        event.preventDefault();
    };
    return isMenuItemVisible() ? (
        <li className={getListItemClassName()}>
            <Link
                to={navItem.module ? navItemText : '#'}
                className={getLinkItemClassName()}
                onClick={onItemClick}
            >
                {navItem.menuName}
            </Link>
            {navItem && navItem.children && (
                <ul>
                    {navItem.children.map(childNode => (
                        <SideDynamicNavMenu
                            history={history}
                            location={location}
                            match={match}
                            key={uniqueId(navItem.menuName)}
                            navItem={childNode}
                            onMenuItemClick={onMenuItemClick}
                            siteId={siteId}
                            onSelectedMenuItem={onSelectedMenuItem}
                        />
                    ))}
                </ul>
            )}
        </li>
    ) : null;
};

export default withRouter(SideDynamicNavMenu);
