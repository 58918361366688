import * as React from 'react';
import { ISiteAssessmentSummary, ISiteProfile } from 'models/site-profile';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import { ICermAssessmentSnapshot } from 'models/cerm/cerm-assessment-snapshot';
import useTranslate from 'translations/translation-utils';
import { EmeraldTextAreaField } from 'components/form/fields/emerald-textarea-field';
import { EmeraldTextField } from 'components/form/fields/emerald-text-field';
import { GetPercentage } from 'utils/decimal-utils';
import { FormikProps } from 'formik';
import { IAttachmentUpload } from 'models/attachment/attachment-upload';
import CermFileUpload from 'routes/cerm/shared/cerm-file-upload';
import { Entity } from 'models/entity';
import { MaterialIconColor, MaterialIconType } from 'routes/material-icon/material-icon-type';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { IAttachmentQuery } from 'models/attachment/attachment-query';
import { Loader } from 'components/loader/Loader';
import { Spinner } from 'components/spinner';
import { checkPermission } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import { IKeyValue } from 'models';
import './cerm-assessment-summary-tab-readOnly.scss';

interface IProps {
    isLoading: boolean;
    site: ISiteProfile;
    snapshots: ICermAssessmentSnapshot[];
    formikBag: FormikProps<ISiteAssessmentSummary>;
    siteImage: IAttachmentModel[];
    isEdit: boolean;
    uploadAttachmentSite: (attachment: IAttachmentUpload) => void;
    deleteAttachment: (attachment: IAttachmentQuery) => void;
    isSaving: boolean;
    permissions: Array<IKeyValue<string>>;
}

export const CermAssessmentSummaryTabForm: React.FC<IProps> = ({
    site,
    snapshots,
    formikBag,
    uploadAttachmentSite,
    deleteAttachment,
    permissions,
    ...props
}) => {

    const translate = useTranslate();
    const [attachment, setAttachmentDetails] = React.useState<IAttachmentUpload>(null);
    const [siteImageUrl, setSiteImageUrl] = React.useState<string | null>(null);
    const [siteImageData, setSiteImageData] = React.useState<IAttachmentModel>(null);
    const [isDeleteBtnDisabled, setIsDeleteBtnDisabled] = React.useState<boolean>(false);

    const handleFileUpload = (newFile: File): void => {
        setIsDeleteBtnDisabled(false);
        const attachmentDetails: IAttachmentUpload = {
            id: site.id,
            entity: Entity.SiteImage,
            siteId: site.id,
            files: [newFile]
        };
        setAttachmentDetails(attachmentDetails);
        uploadAttachmentSite(attachmentDetails);
    };

    const handleFileRemove = (): void => {
        setAttachmentDetails(null);
    };

    const handleFileDelete = (): void => {
        const fileName = props.siteImage.length > 0 ? props.siteImage[0].fileName : '';
        setAttachmentDetails(null);
        setIsDeleteBtnDisabled(true);
        const attachmentDetails: IAttachmentQuery = {
            id: site.id,
            entity: Entity.SiteImage,
            siteId: site.id,
            fileName: fileName
        };
        deleteAttachment(attachmentDetails);
    }

    const getMainAddress = () => {
        if (!site.buildings || site.buildings.length === 0) {
            return null;
        }

        const masterAddressBuilding = site.buildings.filter((b) => b.isMasterAddress);
        return masterAddressBuilding && masterAddressBuilding[0];
    };

    const getTierLevels = () => {
        const tierLevels: string[] = [];

        if (site.buildings && site.buildings.length > 0) {
            site.buildings.forEach((building) => {
                if (tierLevels.indexOf(building.tier) === -1) {
                    if (building.tier !== '') {
                        tierLevels.push(building.tier);
                    }
                }
            });
        }

        return tierLevels.join(',');
    };

    const mainAddress = getMainAddress();
    const expertReviews = snapshots.filter((snapshot) => snapshot.isExpertReview)
    const snapshot = expertReviews[expertReviews.length - 1];
    const canUploadSiteImage = checkPermission(Privilege.AllAttachmentsUpload, permissions);
    const canDeleteSiteImage = checkPermission(Privilege.AllAttachmentsDelete, permissions);

    React.useEffect(() => {
        if (props.siteImage &&
            props.siteImage.length > 0) {
            const sortedSiteImages = [...props.siteImage].sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
            const latestSiteImage = sortedSiteImages[0];
            const newSiteImageUrl = latestSiteImage.blobStorageUri;
            if (newSiteImageUrl !== siteImageUrl) {
                setSiteImageUrl(newSiteImageUrl);
            }
            setSiteImageData(latestSiteImage);
        } else {
            setSiteImageUrl(null);
            setSiteImageData(null);
        }
    }, [props.siteImage, siteImageUrl]);

    return (
        <div className="columns">
            <div className="column is-6">
                <div className="summary-top-section">
                    <div className="site-address readonly-field">
                        <div className="site-address-tag-box">
                            <div className="site-address-tag-box-header">
                                {translate('CermAssessmentSummary.Label.SiteNameandAddress')}
                            </div>
                            <p>{site.name}</p>
                            <p>{mainAddress && mainAddress.addressLine1}</p>
                            <p>{mainAddress && mainAddress.city}</p>
                            <p>
                                <span>{mainAddress && mainAddress.postCode}</span>
                                <span> {mainAddress && mainAddress.country}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="summary-section">
                    <EmeraldTextAreaField
                        id="facilityDescription"
                        label={translate('CermAssessmentSummary.Lable.1')}
                        placeholder={translate('CermAssessmentSummary.Lable.2')}
                        rows={4}
                        value={formikBag.values.facilityDescription}
                        error={formikBag.errors.facilityDescription}
                        handleChange={formikBag.handleChange}
                        setVal={formikBag.setFieldValue}
                        isReadOnly={props.isEdit}
                    />
                </div>

                <div className="summary-section">
                    <EmeraldTextAreaField
                        id="assessmentSummary"
                        label={translate('CermAssessmentSummary.Lable.3')}
                        placeholder={translate('CermAssessmentSummary.Lable.4')}
                        rows={4}
                        value={formikBag.values.assessmentSummary}
                        error={formikBag.errors.assessmentSummary}
                        handleChange={formikBag.handleChange}
                        setVal={formikBag.setFieldValue}
                        isReadOnly={props.isEdit}
                    />
                </div>
                <div className="cerm-assessment-summary__site-details">
                    <div className="summary-section">
                        <EmeraldTextAreaField
                            id="yearConstructed"
                            label={translate('CermAssessmentSummary.Lable.6')}
                            placeholder={translate('CermAssessmentSummary.Lable.5')}
                            rows={3}
                            value={formikBag.values.yearConstructed}
                            error={formikBag.errors.yearConstructed}
                            handleChange={formikBag.handleChange}
                            setVal={formikBag.setFieldValue}
                            isReadOnly={props.isEdit}
                        />
                    </div>
                    <div className="summary-section readonly-field">
                        <EmeraldTextField
                            id="tierLevel"
                            label={translate('CermAssessmentSummary.Label.TierLevel')}
                            value={`Currently-Tier ${getTierLevels()}`}
                            readOnly={true}
                            disabled={true}
                        />
                    </div>

                    <div className="summary-section">
                        <EmeraldTextAreaField
                            id="criticalLinesOfBusiness"
                            label={translate('CermAssessmentSummary.Lable.7')}
                            placeholder={translate('CermAssessmentSummary.Lable.8')}
                            rows={3}
                            value={formikBag.values.criticalLinesOfBusiness}
                            error={formikBag.errors.criticalLinesOfBusiness}
                            handleChange={formikBag.handleChange}
                            setVal={formikBag.setFieldValue}
                            isReadOnly={props.isEdit}
                        />
                    </div>

                    <div className="summary-section">
                        <EmeraldTextAreaField
                            id="space"
                            label={translate('CermAssessmentSummary.Lable.10')}
                            placeholder={translate('CermAssessmentSummary.Lable.9')}
                            rows={3}
                            value={formikBag.values.space}
                            error={formikBag.errors.space}
                            handleChange={formikBag.handleChange}
                            setVal={formikBag.setFieldValue}
                            isReadOnly={props.isEdit}
                        />
                    </div>

                    <div className="summary-section">
                        <EmeraldTextAreaField
                            id="staff"
                            label={translate('CermAssessmentSummary.Lable.11')}
                            placeholder={translate('CermAssessmentSummary.Lable.12')}
                            rows={4}
                            value={formikBag.values.staff}
                            error={formikBag.errors.staff}
                            handleChange={formikBag.handleChange}
                            setVal={formikBag.setFieldValue}
                            isReadOnly={props.isEdit}
                        />
                    </div>
                    <div className="summary-section">
                        <EmeraldTextAreaField
                            id="contactInfo"
                            label={translate('CermAssessmentSummary.Lable.13')}
                            placeholder={translate('CermAssessmentSummary.Lable.14')}
                            rows={3}
                            value={formikBag.values.contactInfo}
                            error={formikBag.errors.contactInfo}
                            handleChange={formikBag.handleChange}
                            setVal={formikBag.setFieldValue}
                            isReadOnly={props.isEdit}
                        />
                    </div>
                    <div className="summary-section readonly-field">
                        <EmeraldTextField
                            id="score"
                            label={translate('CermAssessmentSummary.Label.Score')}
                            value={snapshot && snapshot.isExpertReview ? GetPercentage(snapshot.score) : ""}
                            readOnly={true}
                            disabled={true}
                        />
                    </div>
                    <div className="summary-section readonly-field">
                        <EmeraldTextField
                            id="assessmentPerformedBy"
                            label={translate(
                                'CermAssessmentSummary.Label.AssessmentPerformedBy'
                            )}
                            value={snapshot && snapshot.isExpertReview ? snapshot.expertReviewStatus?.startedByName ?? snapshot.reviewBy : ""}
                            readOnly={true}
                            disabled={true}
                        />
                    </div>

                    <div className="summary-section readonly-field">
                        <EmeraldTextField
                            id='date'
                            label={translate('CermAssessmentSummary.Label.Date')}
                            value={snapshot && snapshot.isExpertReview ? snapshot.date.toDateString() : ""}
                            readOnly={true}
                            disabled={true}
                        />
                    </div>

                    <div className="summary-section readonly-field">
                        <EmeraldTextField
                            id='reviewType'
                            label={translate('CermAssessmentSummary.Label.ReviewType')}
                            value={snapshot && snapshot.isExpertReview ? snapshot.reviewType : ""}
                            readOnly={true}
                            disabled={true}
                        />
                    </div>
                </div>
            </div>

            <div className="column is-6">
                <Loader loading={props.isSaving}>
                    {canUploadSiteImage &&
                        <div className="column">
                            <div className="cerm-assessment-summary__site-image-upload">
                                <CermFileUpload
                                    onFileUpload={handleFileUpload}
                                    onFileRemove={handleFileRemove}
                                    uploadFileDetails={attachment}
                                />
                            </div>
                        </div>
                    }
                    <div className='column'>
                        <div className={siteImageUrl ? "cerm-assessment-summary edit-site-image" : " no-site-image"}>
                            {siteImageUrl &&
                                siteImageData ?
                                <div>
                                    <img src={siteImageUrl} alt={translate('CermAssessmentSummary.Label.NoImageavailable')} />
                                    <div>
                                        <strong>{siteImageData.fileName}</strong>
                                        <br />
                                        <span className='is-small'>{siteImageData.createdDate?.toDateString()}</span>
                                        <span> | </span>
                                        <span style={{ display: 'inline-block' }}>
                                            {siteImageData.isClean === null && (
                                                <div className="is-warning is-small">
                                                    <span className="icon is-small is-loading">
                                                        <Spinner />
                                                    </span>
                                                    <span>{translate('Attachments.AttachmentList.ScanStatus.Scanning')}</span>
                                                </div>
                                            )}
                                            {siteImageData.isClean === true && (
                                                <span className="is-success is-clearfix">{translate('Attachments.AttachmentList.ScanStatus.Scanned&Uploaded')}</span>
                                            )}
                                            {siteImageData.isClean === false && (
                                                <span className="is-danger is-clearfix">{translate('Attachments.AttachmentList.ScanStatus.Malwarefound')}</span>
                                            )}
                                        </span>
                                        {canDeleteSiteImage && (
                                            <button
                                                onClick={() => handleFileDelete()}
                                                className="cerm-site-image-delete-btn"
                                                disabled={isDeleteBtnDisabled}
                                            >
                                                <MaterialIcons type={MaterialIconType.Delete} color={MaterialIconColor.grey} />
                                            </button>
                                        )}
                                    </div>
                                </div> :
                                <p className='no-image-available'>
                                    <strong>{translate('CermAssessmentSummary.Label.NoImageavailable')} </strong>
                                </p>
                            }
                        </div>
                    </div>
                </Loader>
            </div>
        </div>
    );
};
