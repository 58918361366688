import classnames from 'classnames';
import * as React from 'react';

import { TimelineHeader } from './views';

import './timeline.scss';

export interface IProps {
    expanded?: boolean;
    highlighted?: boolean;
}

const baseClassName = 'Timeline';

const getClassNames = (props: IProps) => classnames(
    baseClassName,
    {
        [`${baseClassName}--expanded`]: props.expanded,
        [`${baseClassName}--highlighted`]: props.highlighted
    });

export const Timeline: React.FC<React.PropsWithChildren<IProps>> = (props) => (
    <div className={getClassNames(props)}>
        {
            React.Children
                .toArray(props.children)
                .filter((x: React.ReactElement<any>) => props.expanded || x.type === TimelineHeader)
        }
    </div >
);

Timeline.defaultProps = {
    expanded: false
};
