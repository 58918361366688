import * as React from 'react';
import { connect } from 'react-redux';
import selectors from './selectors';
import actions from './actions';
import { Alert } from 'components/v2/alert/Alert';
import { IRisk, ApprovalStatus } from 'models/risk';
import { formatDateTime } from '../../../../utils/date-utils';
import { IKeyValue } from 'models';
import useTranslate from 'translations/translation-utils';

export interface IOwnProps {
    siteId: string;
    riskId: string;
}

interface IProps extends IOwnProps {
    siteId: string;
    risk: IRisk;
    permissions: IKeyValue<string>[];
    isClient: boolean;
}

export const ClientApproval: React.FC<IProps> = props => {
    const translate = useTranslate();
    return props.risk.clientApproval ? (
        props.risk.clientApproval.approvalStatus === ApprovalStatus.Approved ? (
            <>
                <Alert
                    type="warning"
                    title={
                        translate('Approval.Lable.1') +  ' '  +
                        props.risk.clientApproval.fullName +
                        ' on ' +
                        formatDateTime(props.risk.clientApproval.approvalTime)
                    }
                />
            </>
        ) : (
            <>
                <Alert type="warning" title={translate('Approval.Lable.2')}>
                    <div className="columns">
                        <div className="column">
                            <p>
                                {translate('Approval.Lable.3')} {props.risk.clientApproval.fullName}{' on '}
                                {formatDateTime(props.risk.clientApproval.approvalTime)}
                            </p>

                            <p>
                                {translate('Approval.Lable.5')}
                            </p>
                        </div>
                    </div>
                </Alert>
            </>
        )
    ) : null;
};

export default connect(selectors, actions)(ClientApproval);
