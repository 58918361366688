import * as React from 'react';
import { Help, Control, Field } from '..';
import { BulmaColor } from 'enums/BulmaColor';

interface IProps {
    id: string;
    label: string;
    value: string;
    placeholder?: string;
    error?: string;
    isDisabled?: boolean;
    isReadOnly?: boolean;
    setVal?: any;
    rows?: number;
    handleChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    handleBlur?: (e: any) => void;
}

export const TextAreaField: React.FC<IProps> = props => {
    const trim = evt => {
        if (!props.isReadOnly && props.setVal) {
            props.setVal(evt.target.name, evt.target.value.trim());
        }
    };

    return (
        <Field>
            <label className="label">{props.label}</label>
            <Control>
                <textarea
                    id={props.id}
                    name={props.id}
                    className="textarea is-primary"
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur ?? trim}
                    disabled={props.isDisabled}
                    readOnly={props.isReadOnly}
                    rows={props.rows}
                />
            </Control>
            <Help bulmaColor={BulmaColor.Danger} isShown={true}>
                {props.error}
            </Help>
        </Field>
    );
};
