import { connect } from 'react-redux';

import { ActionGridToolbar as View } from './ActionGridToolbar';
import actions from './actions';
import selectors from './selectors';

export const ActionGridToolbar = connect(
    selectors,
    actions
)(View);
