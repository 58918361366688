import * as React from 'react';
import { Card, CardHeader, CardContent } from 'components/card';
import { Control, Field, Help, Input } from 'components/form';
import { BulmaSize } from 'enums/BulmaSize';
import { Checkbox } from 'components/form/Checkbox';
import { INotificationDetails, newNotificationDetails } from 'models/notification-details';
import { UserSelectorContainer } from 'components/user/user-selector/container';
import { connect } from 'react-redux';
import actions from './actions';
import selectors from './selectors';
import Page from 'components/v2/page/Page';
import { Toolbar, ToolbarGroupRight } from 'components/v2/components';
import { FloatingButtonWithConfirmDialog } from 'components/v2/button/FloatingButtonWithConfirmDialog';
import { BulmaColor } from 'enums/BulmaColor';
import appConfig from 'helpers/config-helper';
import useTranslate from 'translations/translation-utils';

export interface ISendNotificationsProps {
    isSaving: boolean;
    onSend(notification: INotificationDetails, userIds: string[]): void;
}

export interface ISendNotificationsState {
    notificationSpec: INotificationDetails;
    selectedUsers: string[];
}

export const SendNotifications: React.FC<ISendNotificationsProps> = ({ isSaving, onSend }) => {
    const [selectedUsers, setSelectedUsers] = React.useState([]);
    const [showError, setShowError] = React.useState(false);
    const [notificationSpec, setNotificationSpec] = React.useState(newNotificationDetails);
    const reg = /https:\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?/
    const config = appConfig();

    React.useEffect(() => {
        const url = notificationSpec.notificationLink.toLocaleLowerCase();
        if (url == "" || url.match(reg)) {
            setShowError(false);
        } else {
            setShowError(true);
        }
    }, [notificationSpec.notificationLink]);

    const onChangeText = (key: string, value: string) => {
        const changedState = { [key]: value };
        setNotificationSpec({ ...notificationSpec, ...changedState });
    };

    const onChangeCheckbox = (key: string, value: boolean) => {
        const changedState = { [key]: value };
        setNotificationSpec({ ...notificationSpec, ...changedState });
    };

    const selectUsers = (userIds: string[]) => {
        setSelectedUsers(userIds);
    };

    const send = () => {
        onSend(notificationSpec, selectedUsers);
    };

    const disableSendButton = () => {
        const url = notificationSpec.notificationLink.toLocaleLowerCase();
        if (url != "" && !url.match(reg)) {
            return true;
        }

        if (selectedUsers && selectedUsers.length < 1) {
            return true;
        }
        if (!notificationSpec.notificationMessage || !notificationSpec.notificationTitle) {
            return true;
        }
        if (
            notificationSpec.sendEmailNotification &&
            (!notificationSpec.emailMessage ||
                !notificationSpec.emailSubject ||
                !notificationSpec.emailLinkText)
        ) {
            return true;
        }
        return false;
    };

    const disabled = disableSendButton();
    const translate = useTranslate();
    return (
        <Page title={translate('SideNavBar.Labels.SendNotifications')} isAdminPage={true}>
            <Card key={2} className="SitesOverview">
                <CardHeader title={translate('Risk.SendNotification.Title.Notification Content')} subtitle={``} />
                <CardContent>
                    <Field
                        isHorizontal={true}
                        htmlFor="notificationTitle"
                        label={translate('RiskRegisterPage.RiskRegisterTable.Title.Title')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <Input
                                    id="notificationTitle"
                                    name="notificationTitle"
                                    aria-required="true"
                                    className={'input'}
                                    placeholder={translate('RiskRegisterPage.RiskRegisterTable.Title.Title')}
                                    value={notificationSpec && notificationSpec.notificationTitle}
                                    // tslint:disable-next-line:jsx-no-lambda
                                    onChange={(e: any) =>
                                        onChangeText('notificationTitle', e.target.value)
                                    }
                                />
                            </Control>
                        </Field>
                    </Field>
                    <Field
                        isHorizontal={true}
                        htmlFor="notificationMessage"
                        label={translate('SendNotifications.Label.Message')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <textarea
                                    id="notificationMessage"
                                    name="notificationMessage"
                                    aria-required="true"
                                    className={'textarea'}
                                    placeholder={translate('SendNotifications.Label.Message')}
                                    value={notificationSpec && notificationSpec.notificationMessage}
                                    // tslint:disable-next-line:jsx-no-lambda
                                    onChange={(e) =>
                                        onChangeText('notificationMessage', e.target.value)
                                    }
                                />
                            </Control>
                        </Field>
                    </Field>
                    <Field
                        isHorizontal={true}
                        htmlFor="notificationLink"
                        label={`Url : `}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <div className="columns is-vcentered">
                                    <div className="column">
                                        <Input
                                            id="notificationLink"
                                            name="notificationLink"
                                            aria-required="true"
                                            className={'input'}
                                            placeholder={translate('SendNotifications.Label.UrlToLinkTo')}
                                            value={
                                                notificationSpec &&
                                                notificationSpec.notificationLink
                                            }
                                            // tslint:disable-next-line:jsx-no-lambda
                                            onChange={(e: any) =>
                                                onChangeText('notificationLink', e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </Control>
                            <Help bulmaColor={BulmaColor.Danger} isShown={showError}>
                                {translate('SendNotifications.Help.Message')}
                            </Help>
                            <p>
                                {translate('SendNotifications.Help.Message2')} : {config.REACT_APP_DOMAIN_WHITELIST}.
                            </p>
                        </Field>
                    </Field>

                    <br />
                    <Checkbox
                        checked={notificationSpec.sendEmailNotification}
                        label={translate('Risk.SendNotification.Label.SendEmailNotification')}
                        id="send-email-notification-checkbox"
                        // tslint:disable-next-line:jsx-no-lambda
                        onChange={(e: any) =>
                            onChangeCheckbox('sendEmailNotification', e.currentTarget.checked)
                        }
                    />
                    <br />
                    <div hidden={!notificationSpec.sendEmailNotification}>
                        <Field
                            isHorizontal={true}
                            htmlFor="emailSubject"
                            label={translate('SendNotifications.Label.Subject')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Input
                                        id="emailSubject"
                                        name="emailSubject"
                                        aria-required="true"
                                        className={'input'}
                                        placeholder={translate('SendNotifications.Label.Subject')}
                                        value={notificationSpec && notificationSpec.emailSubject}
                                        // tslint:disable-next-line:jsx-no-lambda
                                        onChange={(e: any) =>
                                            onChangeText('emailSubject', e.target.value)
                                        }
                                    />
                                </Control>
                            </Field>
                        </Field>
                        <Field
                            isHorizontal={true}
                            htmlFor="emailMessage"
                            label={translate('SendNotifications.Label.Message')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <textarea
                                        id="emailMessage"
                                        name="emailMessage"
                                        aria-required="true"
                                        className={'textarea'}
                                        placeholder={translate('SendNotifications.Label.Message')}
                                        value={notificationSpec && notificationSpec.emailMessage}
                                        onChange={(e) =>
                                            onChangeText('emailMessage', e.target.value)
                                        }
                                    />
                                </Control>
                            </Field>
                        </Field>
                        <Field
                            isHorizontal={true}
                            htmlFor="emailLinkText"
                            label={translate('SendNotifications.Label.LinkText')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Input
                                        id="emailLinkText"
                                        name="emailLinkText"
                                        aria-required="true"
                                        className={'input'}
                                        placeholder={translate('SendNotifications.Label.GoToQuantum')}
                                        value={notificationSpec && notificationSpec.emailLinkText}
                                        // tslint:disable-next-line:jsx-no-lambda
                                        onChange={(e: any) =>
                                            onChangeText('emailLinkText', e.target.value)
                                        }
                                    />
                                </Control>
                            </Field>
                        </Field>
                    </div>
                    <div className="has-text-right">
                        <Toolbar type="save">
                            <ToolbarGroupRight>
                                <FloatingButtonWithConfirmDialog
                                    disabled={disabled || isSaving}
                                    className="button is-medium is-primary is-pulled-right"
                                    title={translate('SideNavBar.Labels.SendNotifications')}
                                    float={!disabled}
                                    message={
                                        selectedUsers.length === 1 && selectedUsers[0] === 'all'
                                            ? translate('SendNotifications.Dialog.AllPeopleMessage')
                                            : selectedUsers.length === 1
                                                ? translate('SendNotifications.Dialog.OnePersonMessage')
                                                : `${selectedUsers.length.toString()} ${translate('SendNotifications.Dialog.MoreNotAllPeopleMessage')}`
                                    }
                                    onConfirm={send}
                                    isLoading={isSaving}
                                >
                                    {translate('SideNavBar.Labels.SendNotifications')}
                                </FloatingButtonWithConfirmDialog>
                            </ToolbarGroupRight>
                        </Toolbar>
                    </div>
                </CardContent>
            </Card>
            <UserSelectorContainer selectUsers={selectUsers} />
        </Page>
    );
};
export default connect(selectors, actions)(SendNotifications);
