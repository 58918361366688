import { store } from 'routes/App';
import { IRootState } from 'routes/store';
import { IRisk } from 'models/risk';
import { IPersonLookup } from 'models/person-lookup';
import { IPerson } from 'models/person';
import { IKeyValue } from 'models';

export const getUserId = (): string => {
    const state: IRootState = store.getState() as IRootState;
    return state.app.userId;
};

export const getAppSettings = (): Array<IKeyValue<string>> => {
    const state: IRootState = store.getState() as IRootState;
    return state.app.appSettings;
};

export const isSiteApprover = (siteId: string): boolean => {
    const state: IRootState = store.getState() as IRootState;
    return state.app.permissions.some(s => {
        if (s.key === siteId && s.value === 'Approver') {
            return true;
        }
    });
};

export const isClientProxy = (siteId: string): boolean => {
    const state: IRootState = store.getState() as IRootState;
    return state.app.permissions.some(s => {
        if (s.key === siteId && s.value === 'ClientProxy') {
            return true;
        }
    });
};

export const isRiskApprover = (risk: IRisk, userId: string): boolean => {
    return risk.cbreOptionalApprovers.some(s => s === userId);
};

export const getCurrentSiteId = (): string | null => {
    const state: IRootState = store.getState() as IRootState;
    return state.app.siteId;
};

export const getCurrentSiteName = (): string | null => {
    const state: IRootState = store.getState() as IRootState;
    return state.app.lookupSites.length > 0 && state.app.siteId && state.app.siteId.length > 0
        ? state.app.lookupSites.find(site => site.key === state.app.siteId).value
        : null;
};

export const getCurrentLanguage = (): string | null => {
    const state: IRootState = store.getState() as IRootState;
    return state.person.personProfile.language;
};

export const getUserEmailAddress = (): string => {
    const state: IRootState = store.getState() as IRootState;
    return state.person.personProfile.emailAddress1;
};

export const checkIfUserAssignedToSite = (lookupSitePeopleRoles: IPersonLookup[]) => {
    if (lookupSitePeopleRoles.some(lookups => lookups.id === getUserId())) {
        return true;
    }
    return false;
};

/**
 * Format double braced template string
 * @param {string} sourceText
 * @param {string} replacementText
 * @returns {string}
 */
export const findReplaceString = (sourceText: string, replacementText: string) => {
    if (/[a-zA-Z\_]+/g.test(sourceText)) {
        return sourceText.replace(/ *\${[^)]*\} */g, replacementText);
    }
};

export const getUserAccessOperatingPlatform = (personProfile: IPerson) => {
    if (personProfile.accessRights && personProfile.accessRights.operatingPlatforms.length > 1) {
        return personProfile.accessRights.operatingPlatforms.slice().sort((a, b) => a.localeCompare(b))
    }
    if (personProfile.accessRights && personProfile.accessRights.operatingPlatforms.length <= 1) {
        return personProfile.accessRights.operatingPlatforms;
    }
}

export const findValueByKey = (key: string, appSettings: Array<IKeyValue<string>>): string | undefined => {
    const item = appSettings.find(setting => setting.key === key);
    return item ? item.value : undefined;
};
