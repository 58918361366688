import { IEmeraldDisabledInput } from '../shared/disabled.input';
import { IEmeraldLabelInput } from '../shared/label.input';
import { IEmeraldTitleInput } from '../shared/title.input';
import { IEmeraldClassesInput } from '../shared/classes.input';

import {
  EmeraldButtonAlignment,
  EmeraldButtonAlignmentType,
} from './button.alignment';
import { EmeraldButtonColor, EmeraldButtonColorType } from './button.color';
import {
  EmeraldButtonElevation,
  EmeraldButtonElevationType,
} from './button.elevation';
import { EmeraldButtonShape, EmeraldButtonShapeType } from './button.shape';
import { EmeraldButtonSize, EmeraldButtonSizeType } from './button.size';
import {
  EmeraldButtonVariant,
  EmeraldButtonVariantType,
} from './button.variant';
import { EmeraldIconTypeType, EmeraldIconType } from '../icon/icon.inputs';

export class EmeraldButtonCoreInputs implements IEmeraldButtonCoreInputs {
  disabled?: boolean = false;
  label?: string;
  title?: string;
  color? = EmeraldButtonColor.Primary;
  shape? = EmeraldButtonShape.Rectangular;
  mini?: boolean = false;
  light?: boolean = false;
  stroked?: boolean = false;
  elevation? = EmeraldButtonElevation.None;
  ripple?: boolean = true;
  size? = EmeraldButtonSize.Medium;
  icon?: string;
  iconPosition? = EmeraldButtonAlignment.Left;
  iconFillType? = EmeraldIconType.Filled;
  type? = EmeraldButtonVariant.Primary;
  classes?: string;
  block?: boolean = false;
  onClick?: any;
}

export interface IEmeraldButtonCoreInputs
  extends IEmeraldDisabledInput,
    IEmeraldLabelInput,
    IEmeraldTitleInput,
    IEmeraldClassesInput {
  color?: EmeraldButtonColorType | EmeraldButtonColor | string;
  shape?: EmeraldButtonShapeType;
  mini?: boolean;
  light?: boolean;
  stroked?: boolean;
  elevation?: EmeraldButtonElevationType;
  ripple?: boolean;
  size?: EmeraldButtonSizeType;
  icon?: string;
  iconPosition?: EmeraldButtonAlignmentType;
  iconFillType?: EmeraldIconTypeType;
  type?: EmeraldButtonVariantType | EmeraldButtonVariant;
  block?: boolean;
  onClick?: any;
}
