import React from 'react';
import parse from 'html-react-parser';
interface IProps {
    message?: string;
}

export const IAuditorTemplate: React.FC<IProps> = (props) => {
    return (<>
        <div className="compliance-template">{parse(props.message)}</div>
    </>);
};
