import { IAppAction } from '../app-action';
import { ICermFilters } from 'models/filters/cerm-assessment-filters';
import { ICermProcessStep } from 'models/cerm-process-step';

export enum CermProcessStepActions {
    LOAD_CERM_PROCESS = '@@cerm-process-step/LOAD_CERM_PROCESS',
    LOAD_CERM_PROCESS_INITIAL = '@@cerm-process-step/LOAD_CERM_PROCESS_INITIAL',
    LOAD_CERM_PROCESS_FULFILLED = '@@cerm-process-step/LOAD_CERM_PROCESS_FULFILLED',
    LOAD_CERM_PROCESS_REJECTED = '@@cerm-process-step/LOAD_CERM_PROCESS_REJECTED',

    LOAD_CERM_PROCESS_PAGE = '@@cerm-process-step/LOAD_CERM_PROCESS_PAGE',
    LOAD_CERM_PROCESS_PAGE_FULFILLED = '@@cerm-process-step/LOAD_CERM_PROCESS_PAGE_FULFILLED',
    LOAD_CERM_PROCESS_PAGE_REJECTED = '@@cerm-process-step/LOAD_CERM_PROCESS_PAGE_REJECTED',

    CREATE_CERM_PROCESS = '@@cerm-process-step/CREATE_CERM_PROCESS',
    CREATE_CERM_PROCESS_FULFILLED = '@@cerm-process-step/CREATE_CERM_PROCESS_FULFILLED',
    CREATE_CERM_PROCESS_REJECTED = '@@cerm-process-step/CREATE_CERM_PROCESS_REJECTED',

    UPDATE_CERM_PROCESS = '@@cerm-process-step/UPDATE_CERM_PROCESS',
    UPDATE_CERM_PROCESS_FULFILLED = '@@cerm-process-step/UPDATE_CERM_PROCESS_FULFILLED',
    UPDATE_CERM_PROCESS_REJECTED = '@@cerm-process-step/UPDATE_CERM_PROCESS_REJECTED',

    GET_CERM_PROCESS_LINKS = '@@cerm-process-step/GET_CERM_PROCESS_LINKS',
    GET_CERM_PROCESS_LINKS_FULFILLED = '@@cerm-process-step/GET_CERM_PROCESS_LINKS_FULFILLED',
    GET_CERM_PROCESS_LINKS_REJECTED = '@@cerm-process-step/GET_CERM_PROCESS_LINKS_REJECTED',

    SORT_CERM_PROCESS = '@@CERM_PROCESS/SORT_CERM_PROCESS',
}

export const getCermProcessLinks = (cermProcessId: string): IAppAction => ({
    type: CermProcessStepActions.GET_CERM_PROCESS_LINKS,
    payload: { id: cermProcessId },
});

export const loadCermProcessPage = (filters: ICermFilters): IAppAction => ({
    type: CermProcessStepActions.LOAD_CERM_PROCESS_PAGE,
    payload: filters,
});

export const loadCermProcess = (id: string): IAppAction => {
    if (id) {
        return {
            type: CermProcessStepActions.LOAD_CERM_PROCESS,
            payload: id,
        };
    } else {
        return {
            type: CermProcessStepActions.LOAD_CERM_PROCESS_INITIAL,
        };
    }
};

export const upsertCermProcess = (item: ICermProcessStep): IAppAction => {
    if (item.id) {
        return {
            type: CermProcessStepActions.UPDATE_CERM_PROCESS,
            payload: item,
        };
    } else {
        return {
            type: CermProcessStepActions.CREATE_CERM_PROCESS,
            payload: item,
        };
    }
};

export const onSort = (key: Extract<keyof ICermProcessStep, string>, sortAscending: boolean) => ({
    type: CermProcessStepActions.SORT_CERM_PROCESS,
    payload: { key, sortAscending },
});
