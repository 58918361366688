import classNames from 'classnames';
import { FormikProps } from 'formik';
import { get, isEmpty, orderBy, PropertyPath } from 'lodash';
import { IKeyValue } from '../models/key-value';
import { easeInScrollTo } from './animation-utils';
import { ISelectOption } from 'models/select-option';
import { IPersonLookup } from 'models/person-lookup';
import { sortArrayBy } from './sort-utils';
import { IPrivilege } from '../reducers/manageRoles/manageRoles-grid';

export function formikFieldCss<T>(
    formikBag: FormikProps<T>,
    field: Extract<keyof T, string>,
    otherCss?: string
) {
    // Only show the error if the form field has been touched, otherwise they may just not have filled it in yet
    return classNames(
        'is-medium',
        'is-fullwidth',
        {
            'is-danger': formikBag.errors[field] && formikBag.touched[field],
        },
        otherCss
    );
}

export function formikFieldCssByState(hasError: boolean, isTouched: boolean, otherCss?: string) {
    // Only show the error if the form field has been touched, otherwise they may just not have filled it in yet
    return classNames(
        'is-medium',
        'is-fullwidth',
        {
            'is-danger': hasError && isTouched,
        },
        otherCss
    );
}

export async function hasErrorInFormik(formikProps: FormikProps<any>) {
    const errors = await formikProps.validateForm();
    return Object.keys(errors).some((field) => field.length > 0);
}

export function orderDropdownByKey(items: Array<IKeyValue<string>>) {
    return orderBy(items, (item) => item.key);
}

export function onPopoutSelectChange<T>(
    formikBag: FormikProps<T>,
    field: Extract<keyof T, string>,
    changeToId: string
) {
    formikBag.setFieldValue(field, changeToId);
    formikBag.setFieldTouched(field);
}

export const getField = <TType>(name: Extract<keyof TType, string>, prefix: string) =>
    `${prefix}.${name}`;

export function getKeyed<TType>(
    formikBag: FormikProps<Partial<TType>>,
    key: 'values' | 'errors',
    pathToProp: PropertyPath
): any;
export function getKeyed<TType>(
    formikBag: FormikProps<Partial<TType>>,
    key: 'values' | 'errors',
    pathToProp: PropertyPath
): string;
export function getKeyed<TType>(
    formikBag: FormikProps<Partial<TType>>,
    key: 'touched',
    pathToProp: PropertyPath
): boolean;
export function getKeyed<TType>(
    formikBag: FormikProps<Partial<TType>>,
    key: 'values' | 'errors' | 'touched',
    pathToProp: PropertyPath
) {
    return get(formikBag[key], pathToProp);
}

export const canSubmit = (formikBag: FormikProps<any>, isSubmitting: boolean) => {
    // Can't submit an untouched form
    if (!formikBag.dirty) {
        return false;
    }

    // Allow user to try and submit a form once
    if (formikBag.submitCount === 0) {
        return true;
    }

    // Can't submit while submitting
    if (isSubmitting) {
        return false;
    }

    return isEmpty(formikBag.errors);
};

export const scrollToFirstValidationError = () => {
    const errorLabel = document.querySelectorAll('.field .is-danger')[0];
    const rect =
        errorLabel && errorLabel.parentElement && errorLabel.parentElement.getBoundingClientRect();
    easeInScrollTo(rect && rect.top + window.scrollY);
};

export const buildSitePeopleSelectOptions = (sitePeople: IPersonLookup[]): ISelectOption[] => {
    if (isEmpty(sitePeople)) {
        return [];
    }

    const options: ISelectOption[] = sitePeople.map((lookup) => {
        const roles = lookup.roleNames ? lookup.roleNames.join(', ') : 'N/A';
        if (roles) {
            const label = `${lookup.label} (${roles})`;
            return { label, value: lookup.id };
        } else {
            return { label: lookup.label, value: lookup.id };
        }
    });

    return sortArrayBy('label', options, true);
};

export const buildSitePeopleTechAndSiteManagerSelectOptions = (
    sitePeople: IPersonLookup[]
): ISelectOption[] => {
    if (isEmpty(sitePeople)) {
        return [];
    }

    const techManagers = sitePeople.filter(
        (x) => x.roleNames.includes('Technician') || x.roleNames.includes('Site Manager')
    );

    const options: ISelectOption[] = techManagers.map((lookup) => {
        const roles = lookup.roleNames ? lookup.roleNames.join(', ') : 'N/A';

        if (roles) {
            const label = `${lookup.fullName ?? lookup.label} (${roles})`;
            return { label, value: lookup.id };
        } else {
            return { label: lookup.fullName ?? lookup.label, value: lookup.id };
        }
    });

    return sortArrayBy('label', options, true);
};

export const buildSitePeoplePrivilegeSelectOptions = (
    sitePeople: IPersonLookup[],
    privilege: IPrivilege
): ISelectOption[] => {
    if (isEmpty(sitePeople)) {
        return [];
    }

   const privilegeUsers = sitePeople.filter(
    (x) => privilege?.rolesInPrivilege?.findIndex(r => x.roleNames.includes(r.value))>-1
   )

    const options: ISelectOption[] = privilegeUsers.map((lookup) => {
        const roles = lookup.roleNames ? lookup.roleNames.join(', ') : 'N/A';

        if (roles) {
            const label = `${lookup.fullName ?? lookup.label} (${roles})`;
            return { label, value: lookup.id };
        } else {
            return { label: lookup.fullName ?? lookup.label, value: lookup.id };
        }
    });

    return sortArrayBy('label', options, true);
};
