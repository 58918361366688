import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { CermAssessmentActions } from 'actions/actions-v2/cerm-assessment-action-v3';
import { ICermAssessmentSnapshot } from 'models/cerm/cerm-assessment-snapshot';

const controllerName = 'CermAssessmentSnapshot';
const apiPath = `/api/${controllerName}`;

const getActions = {
    action: CermAssessmentActions.LOAD_CERM_SNAPSHOTS_OVERVIEW_ONLY,
    actionFulfilled: CermAssessmentActions.LOAD_CERM_SNAPSHOTS_OVERVIEW_ONLY_FULFILLED,
    actionRejected: CermAssessmentActions.LOAD_CERM_SNAPSHOTS_OVERVIEW_ONLY_REJECTED
} as IActionType;

const getSnapshotComparisonActions = {
    action: CermAssessmentActions.LOAD_CERM_SNAPSHOTS_COMPARISON,
    actionFulfilled: CermAssessmentActions.LOAD_CERM_SNAPSHOTS_COMPARISON_FULFILLED,
    actionRejected: CermAssessmentActions.LOAD_CERM_SNAPSHOTS_COMPARISON_REJECTED
} as IActionType;

const exportActions = {
    action: CermAssessmentActions.EXPORT_CERM_SNAPSHOTS_COMPARISON,
    actionFulfilled: CermAssessmentActions.EXPORT_CERM_SNAPSHOTS_COMPARISON_FULFILLED,
    actionRejected: CermAssessmentActions.EXPORT_CERM_SNAPSHOTS_COMPARISON_REJECTED
} as IActionType;

const loadCermSnapshotsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getActions).getById<ICermAssessmentSnapshot[]>(apiPath);

const loadCermSnapshotComparisonEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getSnapshotComparisonActions).get<ICermAssessmentSnapshot[]>(apiPath);

const exportCermSnapshotsComparisonEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, exportActions).exportByQueryModel(`${apiPath}/ExportSnapshotsComparison`);

export const cermCermSnapshotsEpicsV2 = combineEpics(
    loadCermSnapshotsEpic,
    loadCermSnapshotComparisonEpic,
    exportCermSnapshotsComparisonEpic
);
