import { createSelector } from 'reselect';
import { IBaseState } from 'reducers/base-state';
import { IRootState } from 'routes/store';
import { SiteFeatures } from 'actions/site-feature';
import { checkSiteHasFeature } from 'utils/permission-utils';

const getLoadingStatus = createSelector(
    (state: IRootState) => state.dashboardComponentsState.isIncidentComponentLoading,
    (state) => state
);

const getSiteId = (state: IBaseState) => state.app.siteId;

const getComponent = createSelector(
    (state: IRootState) => state.dashboardComponentsState.incidentComponent,
    (state) => state
);

const getPermission = createSelector(
    (state: IRootState) => state.app.siteId,
    (state: IRootState) => state.app.lookupSitesFeatures,
    (siteId, lookupSitesFeatures) => {
        return checkSiteHasFeature(siteId, SiteFeatures.Incidents, lookupSitesFeatures);
    }
);

export default createSelector(
    getLoadingStatus,
    getSiteId,
    getComponent,
    getPermission,
    (isLoading, siteId, component, hasPermission) => ({
        isLoading,
        siteId,
        component,
        hasPermission
    })
);
