import * as React from 'react';
import { IconToolbar, IToolbarIconItem, ToolbarIconType } from '../v2/components';
import { history } from 'routes/App';
import { checkPermission } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import { IKeyValue } from 'models/key-value';
import useTranslate from 'translations/translation-utils';

export interface IEngineersGridToolbarProps {
    isFilterExists?: boolean;
    isExportingAllEngineers?: boolean | true;
    permissions: Array<IKeyValue<string>>;
    onClearFilters: () => void;
    onShowFilters: () => void;
    onExport?: () => void;
}

export const EngineersGridToolbar: React.FC<IEngineersGridToolbarProps> = ({
    isFilterExists,
    isExportingAllEngineers,
    permissions,
    onExport,
    onClearFilters,
    onShowFilters,
}) => {
    const translate = useTranslate();
    
    const getToolbarItems = (): IToolbarIconItem[] => {
        return [
            checkPermission(Privilege.CDNAUpdate, permissions) && {
                id: 'create-new-engineer-button',
                title: translate('Engineer.GridToolbar.Title.1'),
                type: ToolbarIconType.add,
                onClick: () => {
                    history.push('/Engineers/Engineer/');
                }
            },
            checkPermission(Privilege.CDNAReportAllProfessionalsExport, permissions) && {
                id: 'export-button',
                title: translate('Engineer.GridToolbar.Title.2'),
                type: ToolbarIconType.download,
                isDisabled: isExportingAllEngineers,
                onClick: onExport
            },
            {
                id: 'clear-all-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.ClearFilters'),
                type: isFilterExists ? ToolbarIconType.filterApplied : ToolbarIconType.clearFilter,
                onClick: onClearFilters
            },
            {
                id: 'show-filters-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.ShowFilters'),
                type: ToolbarIconType.filter,
                onClick: onShowFilters
            }
        ];
    };

    return <IconToolbar items={getToolbarItems()} />;
};
