import * as React from 'react';
import { Bar } from 'react-chartjs-2';

export interface IPueBenchmarks {
  target: number;
  // best: number;
  // average: number;
  // worst: number;
}

interface IProps extends IPueBenchmarks {
  actual: number;
}

export const BarChart: React.FC<IProps> = (props) => {

  const points = [props.actual, props.target];

  const data = {
    labels: ['Actual', 'Target'],
    datasets: [
      {
        backgroundColor: ['#E7E7F9', '#17E88F'],
        data: points
      }
    ]
  };

  const chartOptions: any = {
    legend: {
      display: false
    },
    title: {
      text: 'Benchmarks',
      display: false
    },
    maintainAspectRatio: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        },
        scaleLabel: {
          labelString: 'PUE',
          display: true
        }
      }],
      xAxes: [{
        scaleLabel: {
          labelString: 'Quarter',
          display: false
        }
      }]
    }
  };

  return (
    <Bar
      data={data}
      width={600}
      height={300}
      options={chartOptions}
    />
  );
};
