import * as React from 'react';
import { Loader } from 'components/loader';
import { ISkill, SkillLevel, IObsoleteSkillCategory, ISiteSkills, IKeyValue } from 'models';
import '../engineers/engineer.scss';
import './engineer.scss';
import { Card, CardHeader, CardContent } from '../card';
import { IModalCardHeaderToolbarItem } from '../card/CardHeader';
import { getSkillLevelText, SkillLevelTextType } from 'business-area-functions/cdna';
import { EditLocalSkill } from './edit-local-skill';
import { CardSubheader } from '../card/CardSubheader';
import {
    TriStateToggle,
    Toolbar,
    ToolbarGroupRight,
    Button,
    FloatingButton,
    ConfirmDialog,
    Alert,
} from 'components/v2/components';
import { ConfirmNavigateAwayDialog } from 'components/v2/dialog/ConfirmNavigateAwayDialog';
import { AddLocalSkillCategory } from './add-local-skill-category-form';
import _ from 'lodash';
import { Help } from 'components/form';
import { BulmaColor } from 'enums/BulmaColor';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconColor, MaterialIconType } from 'routes/material-icon/material-icon-type';
import { EditLocalSkillCategory } from './edit-local-skill-category-form';
import useTranslate from 'translations/translation-utils';
import { ISiteSkillCard } from 'models/competency-dna/site-skill-card';

export interface ICompetencyDNAProcessesProps {
    globalSkills: ISkill[];
    siteSkills: ISiteSkills | null;
    skillCategories: IObsoleteSkillCategory[];
    siteId: string;
    sameClientSites: Array<IKeyValue<string>>;
    isLoading: boolean;
    expandedCategories: string[];
    showDialog: boolean;
    qualificationCardType?: string;
    onToggleCategory: (id: string) => void;
    save?(): void;
    onSaveSiteSkills: (siteSkills: ISiteSkills, hasNoSiteSkillsRecord: boolean) => void;
    addLocalSkillCategory: (show?: boolean) => void;
    addEngineerSkillCategory: (id: string) => void;
    skillCategeryHideshowPermission: boolean;
    skillCategeryYesNoPermission: boolean;
    hasAccountSkillPermission: boolean;
    showTabDisabledDialog: (show: boolean) => void;
    siteSkillCards: ISiteSkillCard[];
}

export const CompetencyDNAProcesses: React.FC<ICompetencyDNAProcessesProps> = (props) => {
    const translate = useTranslate();
    const [isEditDialogOpen, setIsEditDialogOpen] = React.useState<boolean>(false);
    const [addToSkillCategoryId, setAddToSkillCategoryId] = React.useState<string>('');
    const [siteSkillsExcludedBeforeEditing, setSiteSkillsExcludedBeforeEditing] = React.useState<string[]>([]);
    const [siteSkillsExcluded, setSiteSkillsExcluded] = React.useState<string[]>([]);
    const [localSkillsBeforeEditing, setLocalSkillsBeforeEditing] = React.useState<ISkill[]>([]);
    const [localSkills, setLocalSkills] = React.useState<ISkill[]>([]);
    const [hasNoSiteSkillsRecord, setHasNoSiteSkillsRecord] = React.useState<boolean>(true);
    const [skillToEdit, setSkillToEdit] = React.useState<ISkill>(null);
    const [globalSkillCategoriesExcluded, setGlobalSkillCategoriesExcluded] = React.useState<string[]>([]);
    const [showConfirmDialog, setShowConfirmDialog] = React.useState<boolean>(false);
    const [showEditSkillCategoryDialog, setShowEditSkillCategoryDialog] = React.useState<boolean>(false);
    const [localSkillCategory, setLocalSkillCategory] = React.useState<IObsoleteSkillCategory[]>([]);
    const [allSkillCategories, setAllSkillCategories] = React.useState<IObsoleteSkillCategory[]>([]);
    const [currentCategory, setCurrentCategory] = React.useState<string>('');
    const [showCategoryError, setShowCategoryError] = React.useState<boolean>(false);
    const [globalSkillCategoriesExcludeBeforeEditing, setGlobalSkillCategoriesExcludeBeforeEditing] = React.useState<string[]>([]);
    const [accountSkills, setAccountSkills] = React.useState<ISkill[]>([]);
    const [isAccountSkill, setIsAccountSkill] = React.useState<boolean>(false);
    const [currentSkill, setCurrentSkill] = React.useState<ISkill>(null);
    const [showTabDisabledDialog, setShowTabDisabledDialog] = React.useState<boolean>(false);
    const [skillsToApplyToAccount, setSkillsToApplyToAccount] = React.useState<string[]>([]);

    React.useEffect(() => {
        if (props.siteSkills) {
            const turnedOffGlobalSkills = [...props.siteSkills.turnedOffGlobalSkills]
            setSiteSkillsExcludedBeforeEditing(props.siteSkills.turnedOffGlobalSkills.length > 0
                ? turnedOffGlobalSkills.sort()
                : [])
            setSiteSkillsExcluded(props.siteSkills.turnedOffGlobalSkills.length > 0
                ? turnedOffGlobalSkills.sort()
                : []);
            setLocalSkillsBeforeEditing(props.siteSkills.localSkills);
            setLocalSkills(props.siteSkills.localSkills);
            setGlobalSkillCategoriesExcluded(props.siteSkills.turnedOffGlobalSkillCategories);
            setHasNoSiteSkillsRecord(false);
            setLocalSkillCategory(props.siteSkills.localSkillCategories)
            setGlobalSkillCategoriesExcludeBeforeEditing(props.siteSkills.turnedOffGlobalSkillCategories)
            setAccountSkills(props.siteSkills.localSkills.filter(
                (skill) => skill.isAccount === true
            ));
            setSkillsToApplyToAccount([]);
        }
    }, [props.siteSkills]);

    React.useEffect(() => {
        setShowConfirmDialog(props.showDialog);
    }, [props.showDialog]);

    React.useEffect(() => {
        setAllSkillCategories(props.skillCategories);
    }, [props.skillCategories]);

    const getIsCategoryExpanded = (identifier: string) => {
        return props.expandedCategories && props.expandedCategories.includes(identifier);
    };

    const toolbarItems = (id: string): IModalCardHeaderToolbarItem[] => [
        {
            type: 'icon',
            action: props.onToggleCategory,
            icon: getIsCategoryExpanded(id) ? MaterialIconType.AngleUp : MaterialIconType.AngleDown,
            label: getIsCategoryExpanded(id) ? 'Expand' : 'Collapse',
        },
    ];

    const onHideCategory = (skillId: string) => {
        const skills = [...globalSkillCategoriesExcluded, skillId];
        if (
            allSkillCategories.length - 1 !==
            globalSkillCategoriesExcluded.length
        ) {
            props.onToggleCategory(skillId);
            const globalSkillsInCategory = props.globalSkills
                .filter((item) => item.skillCategoryId === skillId)
                .map((skill) => skill.id);
            setSiteSkillsExcluded([...siteSkillsExcluded, ...globalSkillsInCategory])
            setGlobalSkillCategoriesExcluded(skills)
            setGlobalSkillCategoriesExcludeBeforeEditing(_.uniq([
                ...globalSkillCategoriesExcludeBeforeEditing,
                ...skills,
            ]))
            setShowCategoryError(false);
            setCurrentCategory(skillId);
        } else {
            setShowCategoryError(true);
            setCurrentCategory(skillId);
        }
    };

    const onShowCategory = (skillId: string) => {
        const skills = globalSkillCategoriesExcluded.filter((item) => item !== skillId);
        const allskills = globalSkillCategoriesExcludeBeforeEditing.filter(
            (item) => item !== skillId
        );
        const globalSkillsInCategory = props.globalSkills
            .filter((item) => item.skillCategoryId === skillId)
            .map((skill) => skill.id);
        const arr = siteSkillsExcluded.filter(
            (item) => !globalSkillsInCategory.includes(item)
        );

        setSiteSkillsExcluded(arr);
        props.onToggleCategory(skillId);
        setGlobalSkillCategoriesExcluded(skills);
        setGlobalSkillCategoriesExcludeBeforeEditing(allskills);
    };

    const checkCategoryExcluded = (skillId: string) => {
        return globalSkillCategoriesExcluded.includes(skillId);
    };

    // apply skill category side filter in case some skill categories shouldn't be displayed at all
    const checkGlobalSkillExist = categoryId =>
        props.globalSkills?.filter(a => a.skillCategoryId === categoryId).length > 0;

    const isLocalSkillCategory = (skillCategoryId) => {
        const category = props.skillCategories.find(x => x.id === skillCategoryId);
        const categoryIsAccountOrLocal = (category?.selectedSites?.length > 0 || category?.allSitesSelected) ?? false;
        return (
            localSkillCategory &&
            localSkillCategory.some((item) => item.id === skillCategoryId)
        ) || categoryIsAccountOrLocal;
    };

    const onEditSkillCategory = (skillCategoryId) => {
        setCurrentCategory(skillCategoryId);
        setShowEditSkillCategoryDialog(true);
    };

    const onSave = () => {
        const siteSkills: ISiteSkills = {
            id: props.siteSkills ? props.siteSkills.id : props.siteId,
            turnedOffGlobalSkills: siteSkillsExcluded,
            localSkills,
            turnedOffGlobalSkillCategories: globalSkillCategoriesExcludeBeforeEditing,
            localSkillCategories: localSkillCategory,
            applyToAccountLevelGlobalSkills: skillsToApplyToAccount,
        };
        props.onSaveSiteSkills(siteSkills, hasNoSiteSkillsRecord);

    };

    const onAddLocalSkill = (id: string, isAccountSkill: boolean) => {
        setAddToSkillCategoryId(id);
        setIsEditDialogOpen(true);
        setSkillToEdit(null);
        setIsAccountSkill(isAccountSkill);
    };

    const onAddSkillToApplyToAccount = (id: string) => {
        skillsToApplyToAccount.push(id);
        setSkillsToApplyToAccount([].concat(skillsToApplyToAccount));
    };

    const onSaveLocalSkill = (skill: ISkill) => {
        let updatedLocalSkills = localSkills;
        // add if new, or replace if existing
        if (skillToEdit === null) {
            updatedLocalSkills = updatedLocalSkills.concat(skill);
        } else {
            const indexToReplace = localSkills.findIndex((x) => x.id === skill.id);
            updatedLocalSkills = updatedLocalSkills.map((obj, index) => {
                return index === indexToReplace ? skill : obj;
            });
        }
        const local = updatedLocalSkills
            .filter((item) => item.isAccount !== true)
            .sort((a, b) => a.skillNumber - b.skillNumber)
            .slice();
        const accountSkills = updatedLocalSkills
            .filter((skill) => skill.isAccount === true)
            .sort((a, b) => a.skillNumber - b.skillNumber)
            .slice();
        updatedLocalSkills = accountSkills.concat(local);
        setLocalSkills(updatedLocalSkills);
        setIsEditDialogOpen(false);
        setSkillToEdit(null);
        setAccountSkills(accountSkills)
    };

    const onSaveLocalSkillCategory = (skill, skillCategory) => {
        props.addEngineerSkillCategory(skillCategory.id);
        setLocalSkills(localSkills.concat(skill));
        setLocalSkillCategory(localSkillCategory.concat(skillCategory));
        setShowConfirmDialog(false);
        setAllSkillCategories(allSkillCategories.concat(skillCategory));
    };

    const onSaveUpdateSkillCategory = (skillCategory: IObsoleteSkillCategory) => {
        setAllSkillCategories(
            allSkillCategories
                .filter(x => x.id !== skillCategory.id)
                .concat(skillCategory));

        setLocalSkillCategory(
            localSkillCategory
                .filter(x => x.id !== skillCategory.id)
                .concat(skillCategory));

        setShowEditSkillCategoryDialog(false);
    };

    const onEditLocalSkill = (skill: ISkill, isAccountSkill: boolean) => {
        setAddToSkillCategoryId(skill.skillCategoryId);
        setIsEditDialogOpen(true);
        setSkillToEdit(skill);
        setIsAccountSkill(isAccountSkill);
    };

    const onDeleteLocalSkill = (skill: ISkill) => {
        const UpdatedLocalSkills = localSkills.filter((x) => x.id !== skill.id);
        setLocalSkills(UpdatedLocalSkills);
        setIsEditDialogOpen(false);
        setSkillToEdit(null);
        setAccountSkills(localSkills.filter(s => s.isAccount === true));
        setShowTabDisabledDialog(false);
    };

    const enterTextDialogClose = () => {
        setIsEditDialogOpen(false);
        setSkillToEdit(null);
    };

    const onChangeTriStateToggle = (skill) => {
        const localSkill = _.cloneDeep(localSkills);
        localSkill.map((item) => {
            if (item.id === skill.id) {
                return (item.isDisabled = !item.isDisabled);
            }
            return item;
        });
        setLocalSkills(localSkill)
    };

    const getGlobalSkillItem = (skill: ISkill, excluded: string[]) => (
        <div
            className={`grid-view__item is-hoverable`}
            id={'global-skill-' + skill.id.toString()}
            key={skill.id}
        >
            <div className="columns engineer-columns" style={{ cursor: 'pointer' }}>
                <div className="column is-1">
                    <span
                        className={
                            skill.skillLevel === SkillLevel.C
                                ? 'engineer-c'
                                : skill.skillLevel === SkillLevel.B
                                    ? 'engineer-b'
                                    : skill.skillLevel === SkillLevel.A
                                        ? 'engineer-a'
                                        : ''
                        }
                    >
                        {getSkillLevelText(skill.skillLevel, SkillLevelTextType.Letter)}
                    </span>
                </div>
                <div className="column is-1">
                    {allSkillCategories
                        .find(x => x.id === skill.skillCategoryId)
                        .categoryNumber?.toString() +
                        '.'.toString() +
                        skill.skillNumber.toString()}
                </div>
                <div className="column is-8">
                    <span className="word-break whitespace-wrap">
                        {skill.description}
                    </span>
                </div>
                <div className="column is-1">
                    <TriStateToggle
                        toggleFor={skill.id}
                        selectedValue={!excluded.includes(skill.id)}
                        onChange={(e) => {
                            props.skillCategeryYesNoPermission
                                ? onChangeToggle(skill.id, e)
                                : props.showTabDisabledDialog(true);
                        }}
                    />
                </div>
                <div className="column is-1">
                    <Button
                        id={'apply-to-account-' + skill.skillCategoryId + skill.id}
                        disabled={skillsToApplyToAccount.includes(skill.id)}
                        className="button is-medium is-primary"
                        onClick={() => onAddSkillToApplyToAccount(skill.id)}
                    >
                        {translate('CompetencyDnaProcess.Label.1')}
                    </Button>
                </div>
            </div>
        </div>
    );

    const onChangeToggle = (skillId: string, e: any) => {
        const yes = e;
        let skillsExcluded = siteSkillsExcluded;
        if (yes && skillsExcluded.includes(skillId)) {
            skillsExcluded = skillsExcluded.filter((x) => x !== skillId);
        }
        if (!yes && !skillsExcluded.includes(skillId)) {
            skillsExcluded = skillsExcluded.concat(skillId).sort();
        }
        setSiteSkillsExcluded(skillsExcluded);
    };

    const ondeleteAccountCiterian = (skill) => {
        setCurrentSkill(skill);
        setShowTabDisabledDialog(true);
    };

    const checkShowbutton = (accountSkill) => {
        if (accountSkill) {
            if (props.hasAccountSkillPermission) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    };

    const getLocalSkillItem = (skill: ISkill) => (
        <div
            className={`grid-view__item is-hoverable`}
            id={'site-skill-' + skill.id.toString()}
            key={skill.id}
        >
            <div className="columns engineer-columns" style={{ cursor: 'pointer' }}>
                <div className="column is-1">
                    <span
                        className={
                            skill.skillLevel === SkillLevel.C
                                ? 'engineer-c'
                                : skill.skillLevel === SkillLevel.B
                                    ? 'engineer-b'
                                    : skill.skillLevel === SkillLevel.A
                                        ? 'engineer-a'
                                        : ''
                        }
                    >
                        {getSkillLevelText(skill.skillLevel, SkillLevelTextType.Letter)}
                    </span>
                </div>
                <div className="column is-1">
                    {(skill.isAccount ? 'A' : '') +
                        allSkillCategories
                            .find(x => x.id === skill.skillCategoryId)
                            .categoryNumber?.toString() +
                        '.'.toString() +
                        skill.skillNumber.toString()}
                </div>
                <div className="column is-5">
                    <span className="word-break whitespace-wrap">
                        {skill.description}
                    </span>
                </div>
                <div className="column is-1">{skill.isAccount ? translate('CompetencyDnaProcess.Label.2') : translate('SideNavBar.Labels.Site')}</div>
                <div className="column is-1 local-skill-edit">
                    {checkShowbutton(skill.isAccount) && (
                        <a onClick={() => onEditLocalSkill(skill, skill.isAccount)}>
                            < MaterialIcons type={MaterialIconType.Edit} color={MaterialIconColor.green} />
                        </a>
                    )}
                </div>
                <div className="column is-1">
                    {checkShowbutton(skill.isAccount) && (
                        <a
                            onClick={() =>
                                skill.isAccount
                                    ? ondeleteAccountCiterian(skill)
                                    : onDeleteLocalSkill(skill)
                            }
                        >
                            < MaterialIcons type={MaterialIconType.Delete} color={MaterialIconColor.green} />
                        </a>
                    )}
                </div>

                <div className="column is-1 local-skills-tristate">
                    {skill.isAccount && (
                        <TriStateToggle
                            toggleFor={skill.id}
                            selectedValue={!skill.isDisabled}
                            onChange={() => {
                                props.skillCategeryYesNoPermission
                                    ? onChangeTriStateToggle(skill)
                                    : props.showTabDisabledDialog(true);
                            }}
                        />
                    )}
                </div>
                <div className="column is-1">
                    {skill.isAccount && (
                        <Button
                            id={'apply-to-account-' + skill.skillCategoryId + skill.id}
                            disabled={skillsToApplyToAccount.includes(skill.id)}
                            className="button is-medium is-primary"
                            onClick={() => onAddSkillToApplyToAccount(skill.id)}
                        >
                            {translate('CompetencyDnaProcess.Label.1')}
                        </Button>)}
                </div>
            </div>
        </div>
    );

    const isExcludedSkillsUnchanged = _.isEqual(
        siteSkillsExcluded,
        siteSkillsExcludedBeforeEditing
    );
    const isLocalSkillsUnchanged = _.isEqual(
        localSkills,
        localSkillsBeforeEditing
    );
    const isGlobalCategoryUnchanged = props.siteSkills ? _.isEqual(
        globalSkillCategoriesExcludeBeforeEditing,
        props.siteSkills && props.siteSkills.turnedOffGlobalSkillCategories
    ) : true;
    const isLocalSkillCategoryUnchanged = (props.siteSkills && localSkillCategory?.length > 0) ? _.isEqual(
        props.siteSkills && props.siteSkills.localSkillCategories,
        localSkillCategory
    ) : true;
    const isDirty = !(
        isExcludedSkillsUnchanged &&
        isLocalSkillsUnchanged &&
        isGlobalCategoryUnchanged &&
        isLocalSkillCategoryUnchanged &&
        skillsToApplyToAccount.length === 0
    );
    return (
        <>
            <Loader className="Skills site-level-card" loading={props.isLoading}>
                {props.globalSkills && props.skillCategories ? (
                    <>
                        {props.siteSkills === null && (
                            <Alert type="warning" title="Warning">
                                <p>
                                    {translate('CompetencyDnaProcess.Label.3')}
                                </p>
                            </Alert>
                        )}

                        <ConfirmNavigateAwayDialog when={isDirty} />
                        {isEditDialogOpen && (
                            <EditLocalSkill
                                globalSkills={props.globalSkills}
                                localSkills={localSkills}
                                skillCategoryId={addToSkillCategoryId}
                                onSaveLocalSkill={onSaveLocalSkill}
                                onCancel={enterTextDialogClose}
                                skill={skillToEdit}
                                isNew={skillToEdit ? false : true}
                                isAccountSkill={isAccountSkill}
                                accountSkills={accountSkills}
                            />
                        )}
                        {allSkillCategories &&
                            allSkillCategories.map((skillCategory) => (
                                <div id={skillCategory.id} key={skillCategory.id}>
                                    <Card>
                                        {(
                                            <CardHeader
                                                widgetId={skillCategory.id}
                                                title={
                                                    skillCategory.code + ': ' + skillCategory.name
                                                }
                                                subtitle={skillCategory.description}
                                                toolbarItems={toolbarItems(
                                                    skillCategory.id
                                                )}
                                            >
                                                <>
                                                    {isLocalSkillCategory(
                                                        skillCategory.id
                                                    ) && (
                                                            <>
                                                                <a onClick={() => onEditSkillCategory(skillCategory.id)}
                                                                    className="skills-category-edit"
                                                                >
                                                                    < MaterialIcons type={MaterialIconType.Edit} color={MaterialIconColor.green} />
                                                                </a>
                                                            </>
                                                        )}

                                                    {props.skillCategeryHideshowPermission &&
                                                        (!checkCategoryExcluded(
                                                            skillCategory.id
                                                        ) ? (
                                                            <a
                                                                className="skills-category-enable"
                                                                onClick={() =>
                                                                    onHideCategory(
                                                                        skillCategory.id
                                                                    )
                                                                }
                                                            >
                                                                < MaterialIcons type={MaterialIconType.Visibility} color={MaterialIconColor.green} />
                                                            </a>
                                                        ) : (
                                                            <a
                                                                className="skills-category-enable"
                                                                onClick={() =>
                                                                    onShowCategory(
                                                                        skillCategory.id
                                                                    )
                                                                }
                                                            >
                                                                < MaterialIcons type={MaterialIconType.VisibilityOff} color={MaterialIconColor.green} />
                                                            </a>
                                                        ))}
                                                </>
                                            </CardHeader>
                                        )}
                                        {currentCategory === skillCategory.id && (
                                            <Help
                                                isShown={showCategoryError}
                                                bulmaColor={BulmaColor.Danger}
                                            >
                                                {translate('CompetencyDnaProcess.Label.4')}
                                            </Help>
                                        )}
                                        {props.expandedCategories &&
                                            props.expandedCategories.includes(
                                                skillCategory.id
                                            ) && (
                                                <>
                                                    <div className={`grid-view`}>
                                                        {checkGlobalSkillExist(
                                                            skillCategory.id
                                                        ) && (
                                                                <>
                                                                    <CardSubheader title={translate('CompetencyDnaProcess.Label.5')} />
                                                                    <div
                                                                        className={`centered-grid-headers grid-headers columns ${props.isLoading
                                                                            ? 'is-loading'
                                                                            : ''
                                                                            }`}
                                                                    >
                                                                        <div className="column is-1">
                                                                            {translate('RiskRegisterPage.RiskFilter.Title.Level')}
                                                                        </div>
                                                                        <div className="column is-1">
                                                                            Ref
                                                                        </div>
                                                                        <div className="column is-8">
                                                                            {translate('CompetencyDnaProcess.Label.6')}
                                                                            {translate('CompetencyDnaProcess.Label.7')}
                                                                        </div>
                                                                        <div className="column is-1">
                                                                            {translate('CompetencyDnaProcess.Label.8')}
                                                                        </div>
                                                                        <div className="column is-1">
                                                                            {translate('CompetencyDnaProcess.Label.9')}
                                                                        </div>
                                                                    </div>
                                                                    <CardContent className="GlobalSkills">
                                                                        {props.globalSkills &&
                                                                            props.globalSkills
                                                                                .length > 0 &&
                                                                            props.globalSkills
                                                                                // apply skill category side filter in case some skill categories shouldn't be displayed at all
                                                                                .filter(
                                                                                    (a) =>
                                                                                        a.skillCategoryId ===
                                                                                        skillCategory.id
                                                                                )
                                                                                // render the individual skill
                                                                                .map((skill) =>
                                                                                    getGlobalSkillItem(
                                                                                        skill,
                                                                                        siteSkillsExcluded
                                                                                    )
                                                                                )}
                                                                    </CardContent>
                                                                </>
                                                            )}
                                                        <CardSubheader title={translate('CompetencyDnaProcess.Label.10')} />
                                                        <div className={`grid-view`}>
                                                            {localSkills &&
                                                                localSkills.filter(
                                                                    (x) =>
                                                                        x.skillCategoryId ===
                                                                        skillCategory.id
                                                                ).length > 0 && (
                                                                    <div
                                                                        className={`centered-grid-headers grid-headers columns ${props.isLoading
                                                                            ? 'is-loading'
                                                                            : ''
                                                                            }`}
                                                                    >
                                                                        <div className="column is-1">
                                                                            {translate('RiskRegisterPage.RiskFilter.Title.Level')}
                                                                        </div>
                                                                        <div className="column is-1">
                                                                            Ref
                                                                        </div>
                                                                        <div className="column is-5">
                                                                            {translate('CompetencyDnaProcess.Label.6')}
                                                                            {translate('CompetencyDnaProcess.Label.7')}
                                                                        </div>
                                                                        <div className="column is-1">
                                                                            {translate('Attachments.AttachmentList.Type')}
                                                                        </div>
                                                                        <div className="column is-2" />
                                                                        <div className="column is-1">
                                                                            {translate('CompetencyDnaProcess.Label.8')}
                                                                        </div>
                                                                        <div className="column is-1">
                                                                            {translate('CompetencyDnaProcess.Label.9')}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            <CardContent className="LocalSkills">
                                                                {localSkills &&
                                                                    localSkills.length > 0 &&
                                                                    localSkills
                                                                        // apply skill category side filter in case some skill categories shouldn't be displayed at all
                                                                        .filter(
                                                                            (a) =>
                                                                                a.skillCategoryId ===
                                                                                skillCategory.id
                                                                        )
                                                                        // render the individual skill
                                                                        .map((skill) =>
                                                                            getLocalSkillItem(skill)
                                                                        )}
                                                                <Button
                                                                    id="add-site-skill-button"
                                                                    className="button is-medium is-primary"
                                                                    onClick={() =>
                                                                        onAddLocalSkill(
                                                                            skillCategory.id,
                                                                            false
                                                                        )
                                                                    }
                                                                >
                                                                    {translate('CompetencyDnaProcess.Label.11')}
                                                                </Button>
                                                                {
                                                                    // Display button only for the global category
                                                                    //only local category have L in there skill LQS and LITQS
                                                                    props
                                                                        .hasAccountSkillPermission &&
                                                                    !skillCategory.id.includes(
                                                                        'L'
                                                                    ) && (
                                                                        <Button
                                                                            id="add-site-skill-button"
                                                                            className="button is-medium is-primary display-none"
                                                                            onClick={() =>
                                                                                onAddLocalSkill(
                                                                                    skillCategory.id,
                                                                                    true
                                                                                )
                                                                            }
                                                                        >
                                                                            {translate('CompetencyDnaProcess.Label.12')}
                                                                            {translate('CompetencyDnaProcess.Label.13')}
                                                                        </Button>
                                                                    )
                                                                }
                                                            </CardContent>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                    </Card>
                                </div>
                            ))}

                        <ConfirmDialog
                            title={translate('CermAssesmentTab.Title.Information')}
                            message={
                                translate('CompetencyDnaProcess.Label.14')
                            }
                            buttonCancelHide={false}
                            buttonConfirmText={translate('Globals.Label.Confirm')}
                            isVisible={showTabDisabledDialog}
                            onConfirm={() => onDeleteLocalSkill(currentSkill)}
                            onClose={() => setShowTabDisabledDialog(false)}
                            onOutsideDialogClick={() =>
                                () => setShowTabDisabledDialog(false)}
                        />
                        {showConfirmDialog && (
                            <AddLocalSkillCategory
                                globalSkills={props.globalSkills}
                                localSkills={localSkills}
                                onSaveLocalSkillCategory={onSaveLocalSkillCategory}
                                onCancel={() => props.addLocalSkillCategory(false)}
                                allSkillCategories={allSkillCategories}
                                qualificationCardType={props.qualificationCardType}
                                siteId={props.siteId}
                                lookupSites={props.sameClientSites}
                            />
                        )}
                        {showEditSkillCategoryDialog && (
                            <EditLocalSkillCategory
                                onSaveUpdateSkillCategory={onSaveUpdateSkillCategory}
                                onCancel={() => setShowEditSkillCategoryDialog(false)}
                                siteId={props.siteId}
                                lookupSites={props.sameClientSites}
                                skillCategoryToEdit={allSkillCategories.find(x => x.id === currentCategory)}
                            />
                        )}
                        {
                            <Toolbar type="save">
                                <ToolbarGroupRight>
                                    <FloatingButton
                                        id="save-site-skills-button"
                                        disabled={!isDirty}
                                        onClick={onSave}
                                        float={isDirty}
                                        tooltip={translate('CompetencyDnaProcess.Label.15')}
                                    >
                                        Save
                                    </FloatingButton>
                                </ToolbarGroupRight>
                            </Toolbar>
                        }
                    </>
                ) : (
                    <p> {translate('CompetencyDnaProcess.Label.16')}</p>
                )}
            </Loader>
        </>
    );
}