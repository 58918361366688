import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { ISiteMetaDataActions } from 'actions/actions-v2/site-metadata-actions-v2';
import { ISiteMetaData, ISiteMetaDataCreate } from 'models/site-metadata-model';
import { IAppAction } from 'actions/app-action';

const controllerName = 'SiteMetadata';
const apiPath = `/api/${controllerName}`;

const getSiteMetaDataActions = {
    action: ISiteMetaDataActions.LOAD_SITE_META_DATA,
    actionFulfilled: ISiteMetaDataActions.LOAD_SITE_META_DATA_FULFILLED,
    actionRejected: ISiteMetaDataActions.LOAD_SITE_META_DATA_REJECTED,
} as IActionType;

const createSiteMetaDataActions = {
    action: ISiteMetaDataActions.CREATE_SITE_META_DATA,
    actionFulfilled: ISiteMetaDataActions.CREATE_SITE_META_DATA_FULFILLED,
    actionRejected: ISiteMetaDataActions.CREATE_SITE_META_DATA_REJECTED,
} as IActionType;

const loadSiteMetaDataEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getSiteMetaDataActions).get<ISiteMetaData[]>(`${apiPath}`);

const createSiteMetaDataEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createSiteMetaDataActions).post<ISiteMetaDataCreate>(`${apiPath}`);

export const ISiteMetaDataEpics = combineEpics(loadSiteMetaDataEpic, createSiteMetaDataEpic);