import * as React from 'react';
import { connect } from 'react-redux';
import { IRisk } from 'models/risk';
import { FormikProps } from 'formik';
import { IKeyValue } from 'models';
import selectors from './selectors';
import { ConfirmDialog } from 'components/v2/components';
import { RiskDetailsFormContainer } from './risk-details-form-container';
import useTranslate from 'translations/translation-utils';
import * as RiskStatus from 'models/risk-statuses';
import { IPersonLookup } from 'models/person-lookup';
import { MacroRiskFormContainer } from '../../macro/macro-risk-form-container';
import { getUserId } from 'helpers/helpers';

interface IProps {
    formikBag: FormikProps<IRisk>;
    lookupSiteFeatures: Array<IKeyValue<string[]>>;
    commentSave: boolean;
    riskId: string;
    isReadOnly: boolean;
    users: IPersonLookup[];
    isMitigationValid?: boolean;
    isMacro: boolean;
    canEditInExpertReview: () => boolean;
    onReviewDateChange: (date: Date) => void;
    setIsMitigationValid?: (value: boolean) => void;
    onSubmitActionToRisk?: () => void;
    setIsMacro?: (value: boolean) => void;
    setIsMacroValid?: (value: boolean) => void;
}

export const RiskDetailsTab: React.FC<IProps> = ({
    formikBag,
    riskId,
    commentSave,
    isReadOnly,
    users,
    isMitigationValid,
    isMacro,
    canEditInExpertReview,
    onReviewDateChange,
    setIsMitigationValid,
    setIsMacro,
    setIsMacroValid
}) => {
    const translate = useTranslate();
    const [showWarningPopup, setShowWarningPopup] = React.useState<boolean>(false);
    const [isReadOnlyMacroRisk, setIsReadOnlyMacroRisk] = React.useState<boolean>(false);
    const isSameUser =
        riskId && formikBag.values.assigneeId && formikBag.values.assigneeId !== getUserId();

    React.useEffect(() => {
        setIsReadOnlyMacroRisk(
            isMacro &&
            (!formikBag.values.macroLevelValues ||
                formikBag.values.macroLevelValues.length === 0 ||
                isSameUser)
        );
    }, [isMacro, formikBag.values.macroLevelValues]);

    const isRiskActiveAndNotClose = () => {
        const risk = formikBag.values;
        return risk && risk.isActive && risk.riskStatusId !== RiskStatus.CLOSED;
    };

    const onCloseWarning = () => {
        formikBag.setFieldValue('sharedWithClient', true);
        setShowWarningPopup(false);
    };

    const onConfirmWarning = () => {
        formikBag.setFieldValue('sharedWithClient', false);
        setShowWarningPopup(false);
    };

    const isMacroLevelAndSelected = (isMacroLevel: boolean, isSelected: boolean) => {
        setIsReadOnlyMacroRisk(isMacroLevel && !isSelected);
        setIsMacro(isMacroLevel);

        if (isMacroLevel) {
            formikBag.setFieldValue('sharedWithClient', false);
        }

        // For new risks
        if (!riskId) {
            if (isMacroLevel) {
                formikBag.setFieldValue('assigneeId', getUserId());
            } else {
                formikBag.setFieldValue('assigneeId', null);
            }
        }
    };

    return (
        <>
            <ConfirmDialog
                title={translate('RiskDetailsTab.ConfirmDialog.title')}
                message={translate('RiskDetailsTab.ConfirmDialog.message')}
                onClose={onCloseWarning}
                onConfirm={onConfirmWarning}
                isVisible={showWarningPopup}
            />
            <div className="tabs-container tab-content">
                <MacroRiskFormContainer
                    riskId={riskId}
                    formikBag={formikBag}
                    isMacro={isMacro}
                    isMacroLevelAndSelected={isMacroLevelAndSelected}
                    setIsMacroValid={setIsMacroValid}
                />
                <RiskDetailsFormContainer
                    riskId={riskId}
                    formikBag={formikBag}
                    isReadOnly={isReadOnlyMacroRisk || isReadOnly}
                    isReadOnlyMacroRisk={isReadOnlyMacroRisk}
                    setShowWarningPopup={setShowWarningPopup}
                    isRiskActiveAndNotClose={isRiskActiveAndNotClose}
                    users={users}
                    canEditInExpertReview={canEditInExpertReview}
                    commentSave={commentSave}
                    onReviewDateChange={onReviewDateChange}
                    isMitigationValid={isMitigationValid}
                    setIsMitigationValid={setIsMitigationValid}
                    isMacro={isMacro}
                />
            </div>
        </>
    );
};

export default connect(selectors, null)(RiskDetailsTab);
