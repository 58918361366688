import { IAssetModel } from 'models/asset/asset-model';

export interface ICriticalSystemSpare {
    siteId: string;
    id: string;
    name: string;
    categoryId: string;
    categoryName: string;
    subCategoryId: string;
    subCategoryName: string;
    quantityMin: number;
    quantityMax: number;
    quantityCurrent: number;
    ownerId: string;
    ownerName: string;
    modelNumber: string;
    partNumber: string;
    serialNumber: string;
    estimateValue: number;
    manufacturer: string;
    testFrequencyId: string;
    testFrequency: string;
    location: string;
    isActive: boolean;
    amp: number;
    volts: number;
    lastTestedDate: Date | string;
    notes: string;
    updateRecords: ICriticalSystemSpareQuantityUpdateRecord[];
    status: Status;
    isCritical: boolean;
    asset: IAssetModel;
}

export interface ICriticalSystemSparesSummary {
    categoryName: string;
    numberPartsBelowMinimum: number;
    numberPartsCloseToMinimum: number;
    numberPartsOk: number;
}

export interface ICriticalSystemSparesSiteSummary {
    siteId: string;
    numberPartsBelowMinimum: number;
    numberPartsCloseToMinimum: number;
}

export enum Status {
    BelowMin = 1,
    CloseToMin = 2,
    Ok = 3,
}

export interface ICriticalSystemSpareQuantityUpdateRecord {
    quantity: number;
    updatedDateTime: Date | string;
}

export interface ICriticalSystemSpareFilters {
    siteId: string;
    filterKeywords?: string[];
    selectedCategory?: { value: string; label: string };
    selectedSubCategory?: { value: string; label: string };
    selectedCriticality?: string[];
    selectedStatuses?: string[];
    showArchivedParts?: boolean;
}

export interface ICriticalSystemSpareRequestFilters {
    siteId: string;
    filterKeywords?: string[];
    selectedCategory?: string;
    selectedSubCategory?: string;
    selectedCriticality?: string[];
    selectedStatuses?: string[];
    showArchivedParts?: boolean;
}

export interface ICriticalSystemSpareSubCategory {
    category: string;
    key: string;
    value: string;
}

export enum Criticality {
    Critical = 'Critical',
    NonCritical = 'Non-Critical',
}
