import * as React from 'react';
import Page from 'components/v2/page/Page';
import useTranslate from 'translations/translation-utils';
import { TabGroup, TabItem, Button, ConfirmDialog } from 'components/v2/components';
import { history } from 'routes/App';
import { Form, FormikProps, Formik } from 'formik';
import { Control, Field, Input } from 'components/form';
import { BulmaSize } from 'enums/BulmaSize';
import { IOperatingPlatformCreate } from 'models/site-profile';
import { Tab } from '../constants';
import { MenuConfigurationTab } from '../tabs/menu-configuration/menu-configuration';
import { IMenu } from 'models/menu-model';
import { RouteComponentProps } from 'react-router';
import { IOperatingPlatform } from 'models/operating-platform-model';
import { onRouteChange } from 'actions/app-actions';
import { object, string } from 'yup';
import { formikFieldCss } from 'utils/form-utils';
import HierarchyTab from '../tabs/hierarchy/hierarchy-tab';
import { ILineOfBusinessCreate, IDivisionsCreate, IBusinessUnitCreate } from 'models/site-metadata-model';
import { newGuid } from 'utils/id-utils';

interface IParams {
    id?: string;
    tab: string;
}
interface MyFormValues {
    OperatingPlatform: string;
    processLink: string;
    shortForm: string;
}

interface IProps extends RouteComponentProps<IParams> {
    isLoading: boolean;
    operatingPlatforms: IOperatingPlatform[];
    createOperatingPlatform: (id: string, operatingPlatform: string, processLink: string, shortForm: string) => void;
    updateOperatingPlatform: (operatingPlatformId: string, operatingPlatform: string, processLink: string, shortForm: string) => void;
    operatingPlatformMenus: IMenu[];
    updateOperatingPlatformMenus: (OperatingPlatformMenus: IMenu[]) => void;
    createSiteMeta: (lineOfBusinesses: ILineOfBusinessCreate[], divisions: IDivisionsCreate[], businessUnits: IBusinessUnitCreate[]) => void;
    saveLineOfBusinessSiteMetaData: (lineOfBusiness: ILineOfBusinessCreate[]) => void;
    saveDivisionsSiteMetaData: (divisions: IDivisionsCreate[]) => void;
    saveBusinessUnitsSiteMetaData: (businessUnits: IBusinessUnitCreate[]) => void;
    lineofBusinessList: [];
    selectLineOfBusiness: (lineOfbusinessId: string, lineOfBusinessLabel: string, count: number, isActive: boolean) => void;
    businessUnitsList: IBusinessUnitCreate[];
    divisionsList: IDivisionsCreate[];
    defaultMenus: IMenu[];
    operatingPlatformMenusDetail: IMenu[];
    getOperatingPlatformMenus: (OperatingPlatformMenus: IMenu[]) => void;
    loadMenusForOperatingPlatform: (operatingPlatform: string) => void;
    resetSiteMetaData: () => void;
    newOperatingPlatformGeneratedMenus: IMenu[];
    isUpdatedOperatingPlatformMenus: false;
    resetUpdatedOperatingPlatformMenusFlag: () => void;
    resetUpdatedOperatingPlatformMenus: () => void;
}

export const OperatingPlatformPageEdit: React.FC<IProps> = ({
    match,
    isLoading,
    createSiteMeta,
    saveLineOfBusinessSiteMetaData,
    lineofBusinessList,
    selectLineOfBusiness,
    saveDivisionsSiteMetaData,
    saveBusinessUnitsSiteMetaData,
    businessUnitsList,
    divisionsList,
    operatingPlatforms,
    createOperatingPlatform,
    updateOperatingPlatform,
    operatingPlatformMenusDetail,
    updateOperatingPlatformMenus,
    getOperatingPlatformMenus,
    defaultMenus,
    loadMenusForOperatingPlatform,
    resetSiteMetaData,
    newOperatingPlatformGeneratedMenus,
    isUpdatedOperatingPlatformMenus,
    resetUpdatedOperatingPlatformMenusFlag,
    resetUpdatedOperatingPlatformMenus
}) => {
    const translate = useTranslate();
    const [userSubmenu, setUserSubmenu] = React.useState([]);
    const [showDialogue, setShowDialogue] = React.useState(false);
    const [isOperatingPlatformUpdated, setIsOperatingPlatformUpdated] = React.useState<boolean>(false);
    const [newOperatingPlatform, setNewOperatingPlatform] = React.useState({ id: '', label: '' })
    React.useEffect(() => {
        if (newOperatingPlatformGeneratedMenus && newOperatingPlatformGeneratedMenus.length > 0 && newOperatingPlatform?.id) {
            const menuUrl = `/operating-platform/details/${newOperatingPlatform?.id}/menu-configuration`;
            setTimeout(() => onRouteChange(menuUrl), 1000);
            setTimeout(() => loadMenusForOperatingPlatform(newOperatingPlatform?.label), 1000);
        }
    }, [newOperatingPlatformGeneratedMenus, newOperatingPlatform?.id]);


    let formikBag: FormikProps<IOperatingPlatformCreate>;
    const OperatingPlatformId = match.params.id;

    const selectedOperatingPlatform = OperatingPlatformId && operatingPlatforms.find(
        x => x.id === OperatingPlatformId
    );

    React.useEffect(() => {
        if (isUpdatedOperatingPlatformMenus) {
            const operatingPlatformValue = selectedOperatingPlatform ? selectedOperatingPlatform.value : newOperatingPlatform ? newOperatingPlatform.label : " ";
            operatingPlatformValue && setTimeout(() => loadMenusForOperatingPlatform(operatingPlatformValue), 100);
            setTimeout(() => resetUpdatedOperatingPlatformMenusFlag(), 100);
            resetUpdatedOperatingPlatformMenus();
        }
    }, [isUpdatedOperatingPlatformMenus]);

    const setNavLink = (selectedTab: Tab) => {
        return match.path.replace(':tab', selectedTab).replace(':id', OperatingPlatformId)
    };
    const currentTab = (match.params) && match.params.tab?.toLowerCase();
    const handleClick = (event: any, tab: Tab) => {
        event.preventDefault();
        history.push(setNavLink(tab));
    };

    React.useEffect(() => {
        if (selectedOperatingPlatform?.value) {
            loadMenusForOperatingPlatform(selectedOperatingPlatform?.value);
        }
        loadMenu();
    }, []);

    const initialValues: MyFormValues = {
        OperatingPlatform: selectedOperatingPlatform ? selectedOperatingPlatform.value : '',
        processLink: selectedOperatingPlatform ? selectedOperatingPlatform.processLink : '',
        shortForm: selectedOperatingPlatform ? selectedOperatingPlatform.shortForm : '',
    };


    const onChangeOperatingPlatformValue = (operatingPlatform: string) => {
        formikBag.setFieldValue('OperatingPlatform', operatingPlatform);
        if (OperatingPlatformId === undefined) {
            setNewOperatingPlatform({ id: newGuid(), label: operatingPlatform });
        }
    }

    const onChangeProcessLink = (processLink: string) => {
        formikBag.setFieldValue('processLink', processLink);
        checkUpdatedOperatingPlatform();
    }

    const onChangePrefix = (shortForm: string) => {
        formikBag.setFieldValue('shortForm', shortForm);
        checkUpdatedOperatingPlatform();
    }

    const checkUpdatedOperatingPlatform = () => {
        if (OperatingPlatformId !== undefined) {
            setIsOperatingPlatformUpdated(true);
        }
        else {
            setIsOperatingPlatformUpdated(false);
        }
    }
    const Submit = (operatingPlatformRequest: IOperatingPlatformCreate) => {
        if (OperatingPlatformId && isOperatingPlatformUpdated) {
            updateOperatingPlatform(OperatingPlatformId, operatingPlatformRequest.OperatingPlatform, operatingPlatformRequest.processLink, operatingPlatformRequest.shortForm);
            setIsOperatingPlatformUpdated(false);
        }
        if (newOperatingPlatform?.id && newOperatingPlatformGeneratedMenus.length <= 0) {
            newOperatingPlatform?.id && createOperatingPlatform(newOperatingPlatform?.id, operatingPlatformRequest.OperatingPlatform, operatingPlatformRequest.processLink, operatingPlatformRequest.shortForm);
        }

        if (((lineofBusinessList && lineofBusinessList.length > 0) || (divisionsList && divisionsList.length > 0) || (businessUnitsList && businessUnitsList.length > 0))) {
            (currentTab === Tab.hierarchy) && createSiteMeta(lineofBusinessList, divisionsList, businessUnitsList);
            resetSiteMetaData();
        }
        if (!isUpdatedOperatingPlatformMenus) {
            (currentTab === Tab.menuconfiguration && operatingPlatformMenusDetail && operatingPlatformMenusDetail.length > 0) && updateOperatingPlatformMenus(operatingPlatformMenusDetail);
        }
    };

    const onClick = () => {
        setShowDialogue(true);
    };

    const operatingPlatformValidation = object<Partial<IOperatingPlatformCreate>>().shape({
        OperatingPlatform: string().trim().required('Please enter an operating platform name ').matches(/^[ A-Za-z0-9_@./#&+-]*$/),
        shortForm: string().trim().required('Please enter a short form').matches(/^[ A-Za-z0-9_@./#&+-]*$/),
    });

    const renderForm = (formik: FormikProps<IOperatingPlatformCreate>) => {
        formikBag = formik;
        const isOperatingPlatformFieldsInValid = (!formikBag.errors.OperatingPlatform && !formikBag.errors.shortForm) ? true : false
        const isFormValid = ((match.path.includes('Create')) ? (formikBag.isValid) : isOperatingPlatformFieldsInValid)
        return (
            <Form id="operatingPlatformPage">
                <div className="columns">
                    <br />
                    <br />
                    <div className='column is 8 bolded'>{translate('OperatingPlatform.Page.Title')}</div>
                    <div className='is-pulled-right'>
                        <Button
                            id="btnCancelAction"
                            buttonType="cancel"
                            onClick={onClick}
                            isLoading={isLoading}
                        >
                            {translate('OperatingPlatform.Button.Cancel')}
                        </Button>
                        <Button
                            id="btnSaveAction"
                            type="submit"
                            isLoading={isLoading}
                            disabled={
                                !(isFormValid)
                            }
                        >
                            {OperatingPlatformId ? translate('OperatingPlatform.Button.Save') : translate('OperatingPlatform.Button.Create')}
                        </Button>
                    </div>
                </div >
                <div className="columns">
                    <div className='column is-4'>
                        <Field
                            isHorizontal={true}
                            htmlFor="OperatingPlatform"
                            label={''}
                            labelSize={BulmaSize.Large}
                        >
                            <Field>
                                <Control>
                                    <Input
                                        id="OperatingPlatform"
                                        name="OperatingPlatform"
                                        aria-required="true"
                                        placeholder={translate('OperatingPlatformPage.Table.Column.OperatingPlatformName')}
                                        type="text"
                                        className={formikFieldCss(formikBag, 'OperatingPlatform')}
                                        value={formikBag.values.OperatingPlatform}
                                        onChange={(e: any) =>
                                            onChangeOperatingPlatformValue(
                                                e.target.value
                                            )
                                        }
                                        onBlur={formikBag.handleBlur}
                                        isDisabled={OperatingPlatformId ? true : false}
                                    />
                                </Control>
                            </Field>
                        </Field>
                    </div>
                    <div className='column is-3'>
                        <Field
                            isHorizontal={true}
                            htmlFor="processLink"
                            label={''}
                            labelSize={BulmaSize.Large}
                        >
                            <Field>
                                <Control>
                                    <Input
                                        id="processLink"
                                        name="processLink"
                                        aria-required="true"
                                        placeholder={translate('OperatingPlatformPage.Table.Column.ProcessLink')}
                                        type="text"
                                        value={formikBag.values.processLink}
                                        onChange={(e: any) =>
                                            onChangeProcessLink(
                                                e.target.value
                                            )
                                        }
                                        onBlur={formikBag.handleBlur}
                                    />
                                </Control>
                            </Field>
                        </Field>
                    </div>
                    <div className='column is-2'>
                        <Field
                            isHorizontal={true}
                            htmlFor="shortForm"
                            label={''}
                            labelSize={BulmaSize.Large}
                        >
                            <Field>
                                <Control>
                                    <Input
                                        id="shortForm"
                                        name="shortForm"
                                        aria-required="true"
                                        placeholder={translate('OperatingPlatformPage.Table.Column.shortForm')}
                                        type="text"
                                        className={formikFieldCss(formikBag, 'shortForm')}
                                        value={formikBag.values.shortForm}
                                        onChange={(e: any) =>
                                            onChangePrefix(
                                                e.target.value
                                            )
                                        }
                                        onBlur={formikBag.handleBlur}
                                    />
                                </Control>
                            </Field>
                        </Field>
                    </div>
                </div>
            </Form >
        );
    };
    const loadMenu = () => {
        return defaultMenus &&
            defaultMenus.length > 0 &&
            defaultMenus.map(navItem => {
                addAllMenuItems(navItem)
            }),
            setUserSubmenu(userSubmenu)
    }

    const addAllMenuItems = (navItem: IMenu) => {
        userSubmenu.push(navItem.id)
        navItem.children &&
            navItem.children.map(navItem => {
                addAllMenuItems(navItem)

            })

    }
    const onConfirm = () => {
        setShowDialogue(false);
        onRouteChange(`/operating-platform/list`);
    }

    const getBody = () => {
        return (
            <>
                <br />
                <p>{translate('OperatingPlatform.Model.CancelMessage')}</p>
                {<p><strong>{translate('OperatingPlatform.Model.CancelFooterMessage')}</strong></p>}
            </>
        )
    };

    const renderTab = () => {
        switch (currentTab) {
            case Tab.menuconfiguration:
                return (OperatingPlatformId && defaultMenus && defaultMenus.length > 0) ? <MenuConfigurationTab isReadOnly={false} menus={defaultMenus} getOperatingPlatformMenus={getOperatingPlatformMenus} selectedOperatingPlatform={selectedOperatingPlatform && selectedOperatingPlatform.value} newOperatingPlatform={newOperatingPlatform?.label} /> : '';
            case Tab.hierarchy:
                return <HierarchyTab isReadOnly={false} selectOperatingPlatform={selectedOperatingPlatform} newOperatingPlatform={newOperatingPlatform} saveLineOfBusinessSiteMetaData={saveLineOfBusinessSiteMetaData} selectLineOfBusiness={selectLineOfBusiness} saveDivisionsSiteMetaData={saveDivisionsSiteMetaData} saveBusinessUnitsSiteMetaData={saveBusinessUnitsSiteMetaData} />;
        }
    };
    return (
        <>
            <Page title={translate('SideNavBar.Labels.OperatingPlatform')} isAdminPage={true} className="operating-platform-page">
                <Formik<Partial<IOperatingPlatformCreate>>
                    initialValues={initialValues}
                    onSubmit={Submit}
                    render={renderForm}
                    validationSchema={operatingPlatformValidation}
                    validateOnBlur={true}
                    validateOnChange={true}
                    enableReinitialize={true}
                />
                <br />
                {
                    OperatingPlatformId &&
                    <TabGroup>
                        <TabItem
                            title={translate('OperatingPlatform.Tab.MenuConfiguration')}
                            tab={Tab.menuconfiguration}
                            currentTab={currentTab}
                            onClick={handleClick}
                        />
                        <TabItem
                            title={translate('OperatingPlatform.Tab.Hierarchy')}
                            tab={Tab.hierarchy}
                            currentTab={currentTab}
                            onClick={handleClick}
                        />
                    </TabGroup>
                }
                <ConfirmDialog
                    title={translate('OperatingPlatform.Model.CancelTitle')}
                    message={getBody()}
                    isVisible={showDialogue}
                    isLoading={false}
                    showConfirmButton={true}
                    onConfirm={() => onConfirm()}
                    onClose={() => setShowDialogue(false)}
                    buttonConfirmText={translate('Globals.Label.Cancel')}
                    buttonCancelText={translate('OperatingPlatform.Model.Continue')}
                />
                {renderTab()}
            </Page >
        </>
    );
};
export default OperatingPlatformPageEdit;