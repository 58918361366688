import * as React from 'react';
import { connect } from 'react-redux';
import { EngineerOverview } from 'components/engineer/engineer-dna-overview';
import { EngineerSkills } from 'components/engineer/engineer-skills';
import { IEngineer, IObsoleteSkillCategory, IEngineerSkill, IKeyValue, IEngineerFilters } from 'models';
import selectors from './selectors';
import actions from './actions';
import Comments from 'components/comments/comments';
import { Entity } from 'models/entity';
import { ISiteSkillCard } from 'models/competency-dna/site-skill-card';

interface IProps {
    engineer: IEngineer;
    isLoading: boolean;
    skillCategories: IObsoleteSkillCategory[];
    expandedCategories: string[];
    card: ISiteSkillCard;
    filters: IEngineerFilters;
    isReadOnly: boolean;
    permissions: Array<IKeyValue<string>>;
    onToggleCategory: (id: string) => void;
    onChangeStatus: (skill: IEngineerSkill, engineerId: string) => void;
    onUpdateEngineer: (engineer: IEngineer) => void;
}

export const EngineerDetails: React.FC<IProps> = props => {
    return (
        <>
            {props.skillCategories && props.skillCategories.length > 0 && (
                <EngineerOverview
                    engineer={props.engineer}
                    isLoading={props.isLoading}
                    isOpen={props.expandedCategories.includes('Overview')}
                    onToggleCategory={props.onToggleCategory}
                    skillCategories={props.skillCategories}
                    card={props.card}
                />
            )}
            <EngineerSkills
                engineer={props.engineer}
                isLoading={props.isLoading}
                expandedCategories={props.expandedCategories}
                onToggleCategory={props.onToggleCategory}
                filters={props.filters}
                onChangeStatus={props.onChangeStatus}
                skillCategories={props.skillCategories}
                onSaveEngineer={props.onUpdateEngineer}
                isReadOnly={props.isReadOnly}
                permissions={props.permissions}
            />
            <Comments
                key={props.card?.name + '__' + props.engineer.id}
                id={props.card?.name + '__' + props.engineer.id}
                entity={Entity.Engineer}
                allowNewComment={!props.isReadOnly}
                forceSave={true}
                canEdit={!props.isReadOnly}
            />
        </>
    );
};

export default connect(selectors, actions)(EngineerDetails);