import React from 'react';
import Page from '../../../components/v2/page/Page';
import useTranslate from 'translations/translation-utils';
import Attachments from 'components/attachemnts/attachments';
import { Entity } from 'models/entity';
import { IAttachmentQuery } from 'models/attachment/attachment-query';
import { IAttachmentUpload } from 'models/attachment/attachment-upload';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import { Privilege } from 'enums/Privilege';
import { checkPermission } from 'utils/permission-utils';
import { IKeyValue } from 'models';
interface IProps {
    id: string;
    attachments: IAttachmentModel[];
    siteId: string;
    permissions: Array<IKeyValue<string>>;
    loadAttachmentsEngineerReport: (query: IAttachmentQuery) => void;
    uploadAttachmentEngineerReport: (upload: IAttachmentUpload) => void;
}

const EngineerReport: React.FC<IProps> = (props) => {
    const translate = useTranslate();
    return (
        <Page title={translate('Romonet.EngineerReport.Title')} className="engineer-report">
            <div className="tabs-container tabs-container-table-only">
                {props.siteId && (
                    <Attachments
                        entity={Entity.EngineerReport}
                        attachments={props.attachments}
                        loadAttachments={props.loadAttachmentsEngineerReport}
                        uploadAttachment={props.uploadAttachmentEngineerReport}
                        id={props.siteId}
                        isReadOnly={
                            checkPermission(
                                Privilege.RomonetEngineeringReportAccess,
                                props.permissions
                            )
                                ? false
                                : true
                        }
                    />
                )}
            </div>
        </Page>
    );
};

export default EngineerReport;
