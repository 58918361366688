import { IAppAction } from '../app-action';
import { AppActions } from '../app-actions';
import { ICalendarItem } from 'models/calendar';
export enum CalendarActionsV2 {
    LOAD_CALENDAR_EVENTS = '@@calendar/LOAD_CALENDAR_EVENTS',
    LOAD_CALENDAR_EVENTS_FULFILLED = '@@calendar/LOAD_CALENDAR_EVENTS_FULFILLED',
    LOAD_CALENDAR_EVENTS_REJECTED = '@@calendar/LOAD_CALENDAR_EVENTS_REJECTED',
    LOAD_CALENDAR_EVENTS_CANCELLED = '@@calendar/LOAD_CALENDAR_EVENTS_CANCELLED',

    LOAD_CALENDAR_EVENT = '@@calendar/LOAD_CALENDAR_EVENT',
    LOAD_CALENDAR_EVENT_FULFILLED = '@@calendar/LOAD_CALENDAR_EVENT_FULFILLED',
    LOAD_CALENDAR_EVENT_REJECTED = '@@calendar/LOAD_CALENDAR_EVENT_REJECTED',
    LOAD_CALENDAR_EVENT_CANCELLED = '@@calendar/LOAD_CALENDAR_EVENT_CANCELLED',

    FILTER_CALENDAR_ITEMS = '@@calendar/FILTER_CALENDAR_ITEMS',
    LOAD_PAGE = '@@calendar/LOAD_PAGE',
    LOAD_PAGE_FULFILLED = '@@calendar/LOAD_PAGE_FULFILLED',
    LOAD_PAGE_REJECTED = '@@calendar/LOAD_PAGE_REJECTED',
    LOAD_PAGE_CANCELLED = '@@calendar/LOAD_PAGE_CANCELLED',

    CREATE_CALENDAR_EVENTS = '@@calendar/CREATE_CALENDAR_EVENTS',
    CREATE_CALENDAR_EVENTS_FULFILLED = '@@calendar/CREATE_CALENDAR_EVENTS_FULFILLED',
    CREATE_CALENDAR_EVENTS_REJECTED = '@@calendar/CREATE_CALENDAR_EVENTS_REJECTED',
    CREATE_CALENDAR_EVENTS_CANCELLED = '@@calendar/CREATE_CALENDAR_EVENTS_CANCELLED',

    UPDATE_CALENDAR_EVENTS = '@@calendar/UPDATE_CALENDAR_EVENTS',
    UPDATE_CALENDAR_EVENTS_FULFILLED = '@@calendar/UPDATE_CALENDAR_EVENTS_FULFILLED',
    UPDATE_CALENDAR_EVENTS_REJECTED = '@@calendar/UPDATE_CALENDAR_EVENTS_REJECTED',
    UPDATE_CALENDAR_EVENTS_CANCELLED = '@@calendar/UPDATE_CALENDAR_EVENTS_CANCELLED',

    DELETE_CALENDAR_EVENTS = '@@calendar/DELETE_CALENDAR_EVENTS',
    DELETE_CALENDAR_EVENTS_FULFILLED = '@@calendar/DELETE_CALENDAR_EVENTS_FULFILLED',
    DELETE_CALENDAR_EVENTS_REJECTED = '@@calendar/DELETE_CALENDAR_EVENTS_REJECTED',
    DELETE_CALENDAR_EVENTS_CANCELLED = '@@calendar/DELETE_CALENDAR_EVENTS_CANCELLED',

    SUBSCRIBE_CALENDAR_EVENT = '@@calendar/SUBSCRIBE_CALENDAR_EVENT',
    SUBSCRIBE_CALENDAR_EVENT_FULFILLED = '@@calendar/SUBSCRIBE_CALENDAR_EVENT_FULFILLED',
    SUBSCRIBE_CALENDAR_EVENT_REJECTED = '@@calendar/SUBSCRIBE_CALENDAR_EVENT_REJECTED',

    RESET_CALENDAR_EVENT = '@@calendar/RESET_CALENDAR_EVENT',
}

export const onSubscribeEvent = (siteId): IAppAction => ({
    type: CalendarActionsV2.SUBSCRIBE_CALENDAR_EVENT,
    payload: {
        siteId,
    },
});

export const resetCalendarEvent = (): IAppAction => ({
    type: CalendarActionsV2.RESET_CALENDAR_EVENT,
});
export const onLoadCalendar = (): IAppAction => ({
    type: CalendarActionsV2.LOAD_CALENDAR_EVENTS,
});

export const onLoadCalendarEvent = (id: string, siteId: string): IAppAction => ({
    type: CalendarActionsV2.LOAD_CALENDAR_EVENT,
    payload: {
        id,
        shard: siteId,
    },
});

export const onSiteChange = (siteId: string): IAppAction => ({
    type: AppActions.CHANGE_SITE,
    payload: {
        siteId,
    },
});
export const onFilterCalendar = (type: string): IAppAction => ({
    type: CalendarActionsV2.FILTER_CALENDAR_ITEMS,
    payload: {
        type,
    },
});
export const onCreateCalendarEvent = (model: ICalendarItem): IAppAction => ({
    type: CalendarActionsV2.CREATE_CALENDAR_EVENTS,
    payload: model,
});
export const onUpdateCalendarEvent = (model: Partial<ICalendarItem>): IAppAction => ({
    type: CalendarActionsV2.UPDATE_CALENDAR_EVENTS,
    payload: model,
});

export const onDeleteCalendarEvent = (id: string, siteId: string): IAppAction => ({
    type: CalendarActionsV2.DELETE_CALENDAR_EVENTS,
    payload: {
        id,
        shard: siteId,
    },
});
