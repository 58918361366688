import * as React from 'react';
import { FormikProps, FormikErrors } from 'formik';
import { IIncident, IKeyValue, IPersonSummary } from 'models';
import { Field, Control, Help, Input, Radio } from 'components/form';
import { BulmaColor } from 'enums/BulmaColor';
import { BulmaSize } from 'enums/BulmaSize';
import Select from 'react-select';
import { CustomDateTime } from 'components/datepicker/custom-date-picker/custom-date-time';
import { Checkbox } from 'components/form/Checkbox';
import selectors from './selectors';
import actions from './actions';
import { connect } from 'react-redux';
import {
    IncidentType,
    IncidentSeverity,
    ContributingFactors,
    IncidentStatus,
    incidentRootCause,
    IncidentImpact,
    ImpactedSystem,
    ScaleOfImpact,
    IncidentStakeholder
} from 'models/incident-enums';
import { IPersonLookup } from 'models/person-lookup';
import { getPersonInfo, getSiteUserIds } from 'business-area-functions/incidents';
import { Loader } from 'components/loader';
import { genericSort } from 'utils/sort-utils';
import { IncidentImpactedCheckBoxes } from './incident-impacted-check-boxes';
import { ImpactedSystemsCheckBoxes } from './impacted-systems-check-boxes';
import { Button, ConfirmDialog } from 'components/v2/components';
import { useTranslate } from 'translations/translation-utils';
import moment from 'moment';
import {
    IMultiCellSelect,
    IMultiSelectHeaderCell,
    MultiCellSelect,
} from 'components/multiCellSelect/MultiCellSelect';
import './incident-details.scss';
import { yesNoOptions } from 'routes/create-question-set/views/common';
import { NavLink } from 'react-router-dom';
import appConfig from 'helpers/config-helper';
import Asset from 'components/asset/asset';
import { TextField } from 'components/form/fields/text-field';

interface IProps {
    formikBag: FormikProps<Partial<IIncident>>;
    incidentId?: string;
    users: IPersonLookup[];
    isLoading: boolean;
    language: string;
    majorIncidentUpdated: boolean;
    setMajorIncidentUpdated: (isValid: boolean) => void;
    setBusinessImpactUpdated: (isValid: boolean) => void;
    onLessonsLearnedChange: (value: boolean) => void;
    setIsValidDetails: (isValid: boolean) => void;
    onSiteChange: (site: string) => void;
    passLiveValuesOfIncidentToParent: (incident: Partial<IIncident>) => void;
    setTimeValid: (isValid: boolean) => void;
}

const IncidentDetails: React.FC<IProps> = (props) => {
    const translate = useTranslate();
    const config = appConfig();

    const typeOptions: Array<IKeyValue<string>> = [
        { key: 'Incident', value: IncidentType.FullIncident },
        { key: 'Near Miss', value: IncidentType.NearMiss },
    ];

    const scaleOfImpactOptions: Array<IKeyValue<string>> = [
        { key: 'Server', value: ScaleOfImpact.Server },
        { key: 'Rack', value: ScaleOfImpact.Rack },
        { key: 'Hall', value: ScaleOfImpact.Hall },
        { key: 'Whole Data Centre', value: ScaleOfImpact.WholeDataCentre },
        { key: 'Other, please specify', value: ScaleOfImpact.ScaleOfImpactOther },
    ];

    const [availablePeopleInfo, setAvailablePeopleInfo] = React.useState<IPersonSummary[]>([]);
    const [showDowntimeConfirmation, setShowDowntimeConfirmation] = React.useState<boolean>(false);

    React.useEffect(() => {
        const allowedUsers = getSiteUserIds(props.formikBag.values.siteId, props.users);
        setAvailablePeopleInfo(getPersonInfo(
            props.formikBag.values.siteId,
            allowedUsers,
            props.users
        ))
    }, [props.users, props.formikBag])

    React.useEffect(() => {
        setHasLessonsLearned(props.formikBag.values.hasLessonsLearned);
    }, [props.formikBag.values.hasLessonsLearned]);

    React.useEffect(() => {
        setIsBusinessImpacted(props.formikBag.values.isBusinessImpacted);
    }, [props.formikBag.values.isBusinessImpacted]);

    React.useEffect(() => {
        setIsBusinessImpacted(props.formikBag.values.isBusinessImpacted);
    }, [props.formikBag.values.isBusinessImpacted]);

    React.useEffect(() => {
        setIsInformational(props.formikBag.values.informationalIncident);
    }, [props.formikBag.values.informationalIncident]);

    const handleFactorsChange = (item: ContributingFactors): void => {
        let factors = [...props.formikBag.values.contributingFactors];
        const factor = factors.find((n) => n === item);
        if (factor) {
            const index = factors.indexOf(factor);
            factors = [...factors.slice(0, index), ...factors.slice(index + 1)];
        } else {
            factors = [item as ContributingFactors, ...factors.slice(0, factors.length)];
        }
        props.formikBag.setFieldValue('contributingFactors', factors);
    };

    const handleIncidentImpactChange = (item: IncidentImpact): void => {
        let factors = [...props.formikBag.values.incidentImpact];
        const factor = factors.find((n) => n === item);
        if (factor) {
            const index = factors.indexOf(factor);
            factors = [...factors.slice(0, index), ...factors.slice(index + 1)];
        } else {
            factors = [item as IncidentImpact, ...factors.slice(0, factors.length)];
        }
        props.formikBag.setFieldValue('incidentImpact', factors);
    };

    const handleImpactedSystemsChange = (item: ImpactedSystem): void => {
        let factors = [...props.formikBag.values.impactedSystems];
        const factor = factors.find((n) => n === item);
        if (factor) {
            const index = factors.indexOf(factor);
            factors = [...factors.slice(0, index), ...factors.slice(index + 1)];
        } else {
            factors = [item as ImpactedSystem, ...factors.slice(0, factors.length)];
        }
        props.formikBag.setFieldValue('impactedSystems', factors);
    };

    const handleScaleOfImpactChange = (item): void => {
        const selected = item.target.value;
        let factors = [...props.formikBag.values.scaleOfImpact];
        factors = [selected as ScaleOfImpact];
        props.formikBag.setFieldValue('scaleOfImpact', factors);
    };

    const updateResponsibleUsers = (value: [{ key: string; value: string; label: string }]): void => {
        props.formikBag.setFieldValue(
            'responsiblePeople',
            value?.map((m) => m.key) ?? []
        );
    };

    const handleRootChange = (value: IKeyValue<string>): void => {
        props.formikBag.setFieldValue('rootCause', value?.value);
    };

    const closeDialogBox = (): void => {
        setShowDowntimeConfirmation(false)
    };

    const getSeverityName = (rowIndex: number): IncidentSeverity => {
        let severityName: IncidentSeverity = null
        switch (rowIndex) {
            case 0:
                severityName = IncidentSeverity.S1
                break
            case 1:
                severityName = IncidentSeverity.S2
                break
            case 2:
                severityName = IncidentSeverity.S3
                break
            case 3:
                severityName = IncidentSeverity.S4
                break
        }
        return severityName
    }

    const getCategoryName = (index: number): string => {
        let text: string = ''
        switch (index) {
            case 2: text = translate('IncidentDetails.Table.Header.HSE')
                break;
            case 3: text = translate('IncidentDetails.Table.Header.Technical')
                break;
            case 4: text = translate('IncidentDetails.Table.Header.Commercial')
                break;
            case 5: text = translate('IncidentDetails.Table.Label.MajorIncidentNo')
                break;
        }
        return text
    }

    const getCategoriesText = (row: Array<boolean>, prefix: string = ''): string => {
        let text = '';
        if (row) {
            for (let index = 0; index < row.length; index++) {
                const cell = row[index];
                if (index !== 0 && cell === true) {
                    if (text === '') {
                        text += prefix + getCategoryName(index);
                    } else {
                        text += ', ' + getCategoryName(index);
                    }
                }
            }
        }

        return text;
    };

    const updateSeverityText = (cellSelected: boolean[][]): void => {
        let text = ''
        const rowIndex = cellSelected.findIndex(x => x[0] === true)
        if (rowIndex != -1) {
            text = translate('IncidentDetails.Label.SeverityPrefix') + ' ' + (rowIndex + 1) + getCategoriesText(cellSelected[rowIndex], ' - ')
        }
        if (severityText != text) {
            setSeverityText(text)
        }
    }

    const updateMajorIncidentText = (cellSelected: boolean[][]): void => {
        let rowIndex = -1
        if (cellSelected.findIndex(x => x[0] === true) == 0 || cellSelected.findIndex(x => x[5] === true) == 0) {
            rowIndex = 0
        }
        const text = getCategoriesText(cellSelected[rowIndex])
        if (majorIncidentText != text) {
            setMajorIncidentText(text)
        }
    }

    const initCellSelected = (formData: boolean[][], rowCount: number, colCount: number): boolean[][] => {
        let data = new Array(rowCount).fill(null).map(() => new Array(colCount).fill(false));
        if (formData) {
            for (let rowIndex = 0; rowIndex < formData.length; rowIndex++) {
                const row = formData[rowIndex];
                if (row) {
                    for (let colIndex = 0; colIndex < row.length; colIndex++) {
                        const col = row[colIndex];
                        if (col === true) {
                            data[rowIndex][colIndex + 2] = col;
                        }
                    }
                }
            }
        }

        if (isMajorPopup === true && formikBag.values.majorIncident == false) {
            data[0][5] = true;
        }
        return data;
    };

    const initIncidentState = (formData: boolean[][], rowCount: number, colCount: number): boolean[][] => {
        let data: boolean[][]
        if (formData) {
            data = formData
        } else {
            data = new Array(rowCount).fill(new Array(colCount).fill(false))
        }
        return data
    }

    const getCellSelectedSeverity = (selectedSeverity: boolean[][]): boolean[][] => {
        if (selectedSeverity) {
            switch (props.formikBag.values.severity) {
                case IncidentSeverity.S1:
                    selectedSeverity[0][0] = true
                    break
                case IncidentSeverity.S2:
                    selectedSeverity[1][0] = true
                    break
                case IncidentSeverity.S3:
                    selectedSeverity[2][0] = true
                    break
                case IncidentSeverity.S4:
                    selectedSeverity[3][0] = true
                    break
            }
            updateSeverityText(selectedSeverity)
        }
        return selectedSeverity
    }

    const getCellSelectedMajorIncident = (selectedMajorIncident: boolean[][]): boolean[][] => {
        if (selectedMajorIncident) {
            selectedMajorIncident[0][0] = props.formikBag.values.majorIncident
        }
        updateMajorIncidentText(selectedMajorIncident)
        return selectedMajorIncident
    }

    const [showSeverityPopup, setShowSeverityPopup] = React.useState(false);
    const [showMajorPopup, setShowMajorPopup] = React.useState(false);
    const [isMajorPopup, setIsMajorPopup] = React.useState(false);
    const [severityText, setSeverityText] = React.useState('')
    const [majorIncidentText, setMajorIncidentText] = React.useState('')

    const { formikBag } = props;

    const [cellSelectedSeverity, setCellSelectedSeverity] = React.useState<boolean[][]>(getCellSelectedSeverity(initCellSelected(formikBag?.values?.severityData, 4, 5)))
    const [cellSelectedMajorIncident, setCellSelectedMajorIncident] = React.useState<boolean[][]>(getCellSelectedMajorIncident(initCellSelected(formikBag?.values?.majorIncidentData, 1, 6)))
    const [severityState, setSeverityState] = React.useState(initIncidentState(formikBag?.values?.severityData, 4, 3))
    const [majorState, setMajorState] = React.useState(initIncidentState(formikBag?.values?.majorIncidentData, 1, 3))

    const initMajorIncident: any = () => {
        let val = null;
        if (props.incidentId) {
            val = JSON.stringify(formikBag.values.majorIncident);
        } else if (formikBag.values.majorIncident) {
            val = JSON.stringify(formikBag.values.majorIncident);
        }
        return val;
    };

    const initBusinessImpacted = (): boolean => {
        return props.incidentId && formikBag.values.isBusinessImpacted;
    };

    const initLessonsLearned = (): boolean => {
        return props.incidentId && formikBag.values.hasLessonsLearned;
    };

    const initInformational = (): boolean => {
        return props.incidentId &&
            formikBag.values.informationalIncident &&
            formikBag.values.informationalIncident === true;
    };

    const [majorIncident, setMajorIncident] = React.useState(initMajorIncident());
    const [isBusinessImpacted, setIsBusinessImpacted] = React.useState(initBusinessImpacted());
    const [hasLessonsLearned, setHasLessonsLearned] = React.useState(initLessonsLearned());
    const [isInformational, setIsInformational] = React.useState(initInformational());

    const updateCellSelectedSeverity = (data: boolean[][]): void => {
        setCellSelectedSeverity(data)
    }

    const updateCellSelectedMajorIncident = (data: boolean[][]): void => {
        setCellSelectedMajorIncident(data)
    }

    const convertToIncidentState = (data: boolean[][]): boolean[][] => {
        const dataState: boolean[][] = new Array<Array<boolean>>();

        if (data) {
            for (const row of data) {
                const stateRow = new Array<boolean>();

                row.forEach((cell, columnIndex) => {
                    if (columnIndex >= 2) {
                        stateRow.push(cell);
                    }
                });

                dataState.push(stateRow);
            }
        }

        return dataState;
    };

    const getSelectedRow = (data: boolean[][]): number => {
        return data?.findIndex(x => x[0] === true)
    }

    const IsMajorIncidentSelected = (data: boolean[][]): boolean => {

        if (data?.findIndex(x => x[5] === true) != -1)
            return false;
        else
            return data?.findIndex(x => x[0] === true) != -1
    }
    const onConfirmSeverity = () => {
        const newState = convertToIncidentState(cellSelectedSeverity)
        setSeverityState(newState)
        const incident: Partial<IIncident> = {
            ...props.formikBag.values,
            severity: getSeverityName(getSelectedRow(cellSelectedSeverity)),
            severityData: newState
        };
        props.passLiveValuesOfIncidentToParent(incident);

        setShowSeverityPopup(false)
    }

    const onCancelSeverity = () => {
        setCellSelectedSeverity(getCellSelectedSeverity(initCellSelected(formikBag?.values?.severityData, 4, 5)))
        setShowSeverityPopup(false)
    }

    const onConfirmMajorIncident = () => {
        const newState = convertToIncidentState(cellSelectedMajorIncident)
        setMajorState(newState)

        let val = IsMajorIncidentSelected(cellSelectedMajorIncident)

        props.formikBag.setFieldValue('majorIncident', val)
        props.formikBag.setFieldValue('majorIncidentData', newState)
        setMajorIncident(val)
        setShowMajorPopup(false)
        setIsMajorPopup(false)
    }

    const onCancelMajorIncident = (): void => {
        setCellSelectedMajorIncident(getCellSelectedMajorIncident(initCellSelected(formikBag?.values?.majorIncidentData, 1, 6)))
        setShowMajorPopup(false)
        setIsMajorPopup(false)
    }

    const [openedSeverityPopup, setOpenedSeverityPopup] = React.useState(false)
    const [openedMajorIncidentPopup, setOpenedMajorIncidentPopup] = React.useState(false)

    const onSelectSeverityPopup = (): void => {
        if (openedSeverityPopup === false) {
            setCellSelectedSeverity(getCellSelectedSeverity(initCellSelected(formikBag?.values?.severityData, 4, 5)))
            setOpenedSeverityPopup(true)
        }
        setShowSeverityPopup(true)
    }

    const onSelectMajorIncidentPopup = (): void => {
        setIsMajorPopup(true)
        if (openedMajorIncidentPopup === false) {
            setCellSelectedMajorIncident(getCellSelectedMajorIncident(initCellSelected(formikBag?.values?.majorIncidentData, 1, 6)))
            setOpenedMajorIncidentPopup(true)
        }
        setShowMajorPopup(true)
    }

    React.useEffect(() => {
        if (severityState && cellSelectedSeverity) {
            updateSeverityText(cellSelectedSeverity)
        }
    }, [severityState])

    React.useEffect(() => {
        updateMajorIncidentText(cellSelectedMajorIncident)
    }, [majorState])

    const isColumnClickableSeverity: boolean[] = [false, false, true, true, true];
    const header: IMultiSelectHeaderCell[] = [
        { colSpan: 1, text: '' },
        { colSpan: 1, text: 'INCIDENT CATEGORISATION' },
        { colSpan: 3, text: 'INCIDENT CATEGORISATION EXAMPLE' },
    ]
    const subheaderser: IMultiSelectHeaderCell[] = [
        { colSpan: 1, text: '' },
        { colSpan: 1, text: translate('IncidentDetails.Table.Header.Definition') },
        { colSpan: 1, text: translate('IncidentDetails.Table.Header.HSE') },
        { colSpan: 1, text: translate('IncidentDetails.Table.Header.Technical') },
        { colSpan: 1, text: translate('IncidentDetails.Table.Header.Commercial') }
    ]
    const subheader: IMultiSelectHeaderCell[] = [
        { colSpan: 1, text: '' },
        { colSpan: 1, text: translate('IncidentDetails.Table.Header.Definition') },
        { colSpan: 1, text: translate('IncidentDetails.Table.Header.HSE') },
        { colSpan: 1, text: translate('IncidentDetails.Table.Header.Technical') },
        { colSpan: 1, text: translate('IncidentDetails.Table.Header.Commercial') },
        { colSpan: 1, text: translate('IncidentDetails.Table.Header.MajorIncident') }
    ]
    const cellTextSeverity: Array<Array<string>> = [
        [
            `${translate('IncidentDetails.Table.Label.SEV')} 1`,
            translate('IncidentDetails.Table.Label.Incidentswithsignificant'),
            translate('IncidentDetails.Table.Label.Seriousinjuryrequiring'),
            translate('IncidentDetails.Table.Label.Lossofservicewithsignificant'),
            translate('IncidentDetails.Table.Label.Financialandorcommercialpenalties')
        ],
        [
            `${translate('IncidentDetails.Table.Label.SEV')} 2`,
            translate('IncidentDetails.Table.Label.Clientdataoperationscouldbeaffected'),
            translate('IncidentDetails.Table.Label.Significantinjuryrequiringmedicalassistance'),
            translate('IncidentDetails.Table.Label.Limitedscopecustomerimpact'),
            translate('IncidentDetails.Table.Label.ContractualFinancialpenalties')
        ],
        [
            `${translate('IncidentDetails.Table.Label.SEV')} 3`,
            translate('IncidentDetails.Table.Label.Incidentswhereclientdatacenter'),
            translate('IncidentDetails.Table.Label.Minorinjurytreatableonsite'),
            translate('IncidentDetails.Table.Label.Servicesimpactedbutminimalimpactoncustomers'),
            translate('IncidentDetails.Table.Label.PossibleSLAbreach')
        ],
        [
            `${translate('IncidentDetails.Table.Label.SEV')} 4`,
            translate('IncidentDetails.Table.Label.Allotherminorincidents'),
            translate('IncidentDetails.Table.Label.Injuryenvironmentalnearmiss'),
            translate('IncidentDetails.Table.Label.Recordableoutagebutlimited'),
            translate('IncidentDetails.Table.Label.Nocustomerimpact')
        ]
    ]

    const multiCellSelectDataSeverity: IMultiCellSelect = {
        isColumnClickable: isColumnClickableSeverity,
        header: header,
        subheader: subheaderser,
        cellText: cellTextSeverity,
    }

    const severityPopup = (): JSX.Element => {
        return (
            <div>
                <h3>{translate('IncidentDetails.Label.SeverityNote')}</h3>
                <MultiCellSelect
                    multiCellSelectData={multiCellSelectDataSeverity}
                    isEditable={
                        formikBag.values.status !== IncidentStatus.Closed
                    }
                    isMultiSelect={true}
                    cellSelected={cellSelectedSeverity}
                    updateCellSelected={updateCellSelectedSeverity}
                />
            </div>
        )
    }

    const isColumnClickableMajor: boolean[] = [false, false, true, true, true, true];
    const cellTextMajor: Array<Array<string>> = [
        [
            translate('IncidentDetails.Label.MajorIncident'),
            translate('IncidentDetails.Table.Label.MajorIncidentDefinition'),
            translate('IncidentDetails.Table.Label.MajorIncidentHSE'),
            translate('IncidentDetails.Table.Label.MajorIncidentTechnical'),
            translate('IncidentDetails.Table.Label.MajorIncidentCommercial'),
            translate('IncidentDetails.Table.Label.MajorIncidentNo'),
        ],
    ]

    const multiCellSelectDataMajor: IMultiCellSelect = {
        isColumnClickable: isColumnClickableMajor,
        header: header,
        subheader: subheader,
        cellText: cellTextMajor,
    }

    const majorPopup = (): JSX.Element => {
        return (
            <div>
                <h3>{translate('IncidentDetails.Label.MajorIncidentNote')}</h3>
                <MultiCellSelect
                    multiCellSelectData={multiCellSelectDataMajor}
                    isEditable={
                        formikBag.values.status !== IncidentStatus.Closed
                    }
                    isMultiSelect={false}
                    cellSelected={cellSelectedMajorIncident}
                    updateCellSelected={updateCellSelectedMajorIncident}
                />
            </div>
        )
    }

    const getHelper = (formikErrors: FormikErrors<any> | any, isShown: boolean = true) => {
        if (!isShown) {
            isShown = false;
        }
        return (
            <Help bulmaColor={BulmaColor.Danger} isShown={isShown}>
                {formikErrors}
            </Help>
        );
    };
    const setResolvedTimeValue = (newDate: Date) => {
        const i = moment(newDate).format('DD-MM-YYYY HH:mm:ss');

        props.formikBag.setFieldValue('resolvedDateTime', newDate);
        props.formikBag.setFieldValue('resolvedLocalDateTime', i);

        const incident: Partial<IIncident> = {
            ...props.formikBag.values,
            resolvedDateTime: newDate,
            resolvedLocalDateTime: i,
        };

        props.passLiveValuesOfIncidentToParent(incident);
    };
    const setFinishTimeValue = (newDate: Date) => {
        const i = moment(newDate).format('DD-MM-YYYY HH:mm:ss');

        props.formikBag.setFieldValue('finishDateTime', newDate);
        props.formikBag.setFieldValue('finishLocalDateTime', i);

        const incident: Partial<IIncident> = {
            ...props.formikBag.values,
            finishDateTime: newDate,
            finishLocalDateTime: i,
        };

        props.passLiveValuesOfIncidentToParent(incident);
    };

    const setValue = (newDate: Date) => {
        props.formikBag.setFieldValue('startDateTime', newDate);
        props.formikBag.setFieldValue('localDateTime', moment(newDate).format('DD-MM-YYYY HH:mm:ss'));
    };

    const getUsersValue = (responsiblePeople: string[]) => {
        return responsiblePeople.map((x) => {
            const user = props.users.find((u) => u.id === x);
            if (user) {
                return { key: user.id, value: user.email, label: user.label };
            }
        });
    };

    const status = formikBag.values.status;

    const isValidMajorIncident = (): boolean => {
        let isValid = true;

        if (majorIncident == null) {
            isValid = false;
        } else if (majorIncident === true && majorIncidentText.length === 0) {
            isValid = false;
        }

        return isValid;
    };

    React.useEffect(() => {
        if (isValidMajorIncident() === false || severityText === '') {
            props.setIsValidDetails(false);
        } else {
            props.setIsValidDetails(true);
        }
    }, [severityText, majorIncident, majorIncidentText]);

    const handleIsBsuinessImpacted = (event: any) => {
        const result = event.currentTarget.value === 'true';

        setIsBusinessImpacted(result);
        formikBag.setFieldValue('isBusinessImpacted', result);

        if (!result) {
            formikBag.setFieldValue('businessImpactDescription', '');
        }
    };

    const handleBusinessImpactDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        formikBag.setFieldValue('businessImpactDescription', event.target.value);
    };

    const handleHasLessonsLearned = (event: any): void => {
        const result = event.currentTarget.value === 'true';

        formikBag.setFieldValue('hasLessonsLearned', result);
        setHasLessonsLearned(result);
        props.onLessonsLearnedChange(result);

        if (!result) {
            formikBag.setFieldValue('lessonLearnDescription', '');
        }
    };

    const handleLessonsLearnedDescription = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        formikBag.setFieldValue('lessonLearnDescription', event.target.value);
    };

    const operationalImpactDescriptionChange = (value: any): void => {
        formikBag.setFieldValue('operationalImpactDescription', value.currentTarget.value);

        if (isValidMajorIncident() === false) {
            props.setIsValidDetails(false);
        } else {
            props.setIsValidDetails(true);
        }

        props.setBusinessImpactUpdated(true);
    };

    const handleInformationalIncidentChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const result = event.currentTarget.value === 'true';

        setIsInformational(result);
        formikBag.setFieldValue('informationalIncident', result);
    };

    const displayDownloadHSE =
        severityText.includes(translate('IncidentDetails.Table.Header.HSE')) ||
        majorIncidentText.includes(translate('IncidentDetails.Table.Header.HSE'));

    const setAssetFieldValues = (values: any) => {
        formikBag.setFieldValue('equipmentManufacturer', values.manufacturer);
        formikBag.setFieldValue('equipmentModel', values.model);
        formikBag.setFieldValue('equipmentSerial', values.serial);
        formikBag.setFieldValue('equipmentCmmsAsset', values.cmmsReference);
        formikBag.setFieldValue('equipmentName', values.name);
        formikBag.setFieldValue('equipmentAge', values.age);
    }

    const isEquipmentFailure = (value) => {
        const translatedValue = translate('IncidentDetails.incidentRootCause.EquipmentFailure');
        if (value === null || translatedValue === null) {
            return false;
        }
        const processedValue = translatedValue.replace(/[^\w]/g, '');
        return processedValue === value.replace(/[^\w]/g, '');
    }

    return (
        <Loader loading={props.isLoading}>
            <div className="section incident-details">
                <div className="column is-12">
                    <div className="column is-pull-right status-title">
                        <p>{translate('IncidentRiskActions.Label.Status')}: </p>
                        <div
                            className={
                                status === IncidentStatus.Open
                                    ? 'open'
                                    : status === IncidentStatus.Pending
                                        ? 'pending'
                                        : status === IncidentStatus.Closed
                                            ? 'closed'
                                            : 'draft'
                            }
                        >
                            {translate(
                                'IncidentList.IncidentStatus.',
                                IncidentStatus[formikBag.values.status]
                            )}
                        </div>
                    </div>
                    <div className="title is-4 section-title">
                        {translate('IncidentDetails.Label.Details')}
                    </div>
                    <ConfirmDialog
                        title={translate('IncidentDetails.ConfirmDialog.title')}
                        message={translate('IncidentDetails.ConfirmDialog.message')}
                        buttonConfirmText={translate(
                            'IncidentDetails.ConfirmDialog.ButtonConfirmText'
                        )}
                        buttonCancelHide={true}
                        isVisible={showDowntimeConfirmation}
                        onConfirm={closeDialogBox}
                    />
                    <Field
                        isHorizontal={true}
                        htmlFor="title"
                        label={translate('IncidentDetails.Label.Title')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <Input
                                    id="title"
                                    disabled={formikBag.values.status === IncidentStatus.Closed}
                                    placeholder={translate(
                                        'IncidentDetails.placeholder.Title'
                                    )}
                                    value={formikBag.values.title}
                                    onChange={formikBag.handleChange}
                                />
                            </Control>
                            {getHelper(formikBag.errors.title, formikBag.touched.title)}
                        </Field>
                    </Field>
                    <Field
                        isHorizontal={true}
                        htmlFor="type"
                        label={translate('IncidentDetails.Label.Type')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <Radio
                                    id="type"
                                    name="type"
                                    options={typeOptions.map((c) => ({
                                        value: c.value,
                                        key: translate(
                                            'IncidentDetails.typeOptions.'.concat(
                                                c.key.replace(/\s/g, '')
                                            )
                                        ),
                                    }))}
                                    value={formikBag.values.type}
                                    onBlur={formikBag.handleBlur}
                                    onChange={formikBag.handleChange}
                                    disabled={formikBag.values.status === IncidentStatus.Closed}
                                />
                            </Control>
                            {getHelper(formikBag.errors.type, formikBag.touched.type)}
                        </Field>
                    </Field>
                    <Field
                        isHorizontal={true}
                        htmlFor="severity"
                        label={translate('IncidentDetails.Label.Severity')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control className="columns">
                                <div className="column is-8">
                                    <Button
                                        className="is-Medium selectButton"
                                        onClick={onSelectSeverityPopup}
                                    >
                                        {translate('IncidentDetails.Label.SelectSeverityType')}
                                    </Button>
                                    <label className='selectText'>{severityText == '' ? 'Not selected' : severityText}</label>
                                    <div className='modal-fullwidth'>
                                        <ConfirmDialog
                                            title={translate('IncidentDetails.Label.Severity')}
                                            message={severityPopup()}
                                            buttonCancelHide={false}
                                            isVisible={showSeverityPopup}
                                            showConfirmButton={true}
                                            onConfirm={onConfirmSeverity}
                                            onClose={onCancelSeverity}
                                            onOutsideDialogClick={onCancelSeverity}
                                        />
                                    </div>
                                </div>
                            </Control>
                            {getHelper(
                                formikBag.errors.severity,
                                formikBag.touched.severity
                            )}
                        </Field>
                    </Field>

                    <Field
                        isHorizontal={true}
                        htmlFor="majorIncident"
                        label={translate('IncidentDetails.Label.MajorIncident')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control className="columns">
                                <div className="column is-12">
                                    <Button
                                        className="is-medium selectButton"
                                        onClick={onSelectMajorIncidentPopup}
                                    >
                                        {translate('IncidentDetails.Label.SelectMajorIncident')}
                                    </Button>
                                    <label className="selectText">{majorIncidentText === '' ? 'Not selected' : majorIncidentText}</label>
                                    {displayDownloadHSE && (
                                        <>
                                            <label className="selectText">
                                                {translate('IncidentDetails.Label.HSEDownload')}
                                            </label>
                                            <Button
                                                className="is-medium"
                                                onClick={() => {
                                                    window.open(
                                                        config.REACT_APP_INCIDENT_HSE_DOWNLOAD_DOCUMENT_WEB_URL,
                                                        '_blank'
                                                    );
                                                }}
                                            >
                                                {translate('IncidentDetails.Label.ViewDocument')}
                                            </Button>
                                        </>
                                    )}
                                    <Help bulmaColor={BulmaColor.Danger} isShown={true}>
                                        {formikBag.dirty && (majorIncidentText.length == 0 || majorIncidentText == 'Not selected') ? translate('IncidentDetails.Label.SelectMajorIncident') + ' is required' : ''}
                                    </Help>
                                    <div className='modal-fullwidth'>
                                        <ConfirmDialog
                                            title={translate('IncidentDetails.Label.MajorIncident')}
                                            message={majorPopup()}
                                            buttonCancelHide={false}
                                            isVisible={showMajorPopup}
                                            showConfirmButton={true}
                                            onConfirm={onConfirmMajorIncident}
                                            onClose={onCancelMajorIncident}
                                            onOutsideDialogClick={onCancelMajorIncident}
                                        />
                                    </div>
                                </div>
                            </Control>
                        </Field>
                    </Field>
                    <Field
                        isHorizontal={true}
                        htmlFor="informationalIncident"
                        label={translate('IncidentDetails.Label.InformationalIncident')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <Radio
                                    id="informationalIncident"
                                    name="informationalIncident"
                                    options={yesNoOptions.map((c) => ({
                                        key: c.key,
                                        label: translate('Globals.Label.'
                                            .concat(c.label.replace(/\s/g, ''))),
                                        value: c.value,
                                    }))}
                                    value={formikBag.values.informationalIncident &&
                                        formikBag.values.informationalIncident === true
                                        ? 'true'
                                        : 'false'
                                    }
                                    onBlur={formikBag.handleBlur}
                                    onChange={handleInformationalIncidentChange}
                                    disabled={formikBag.values.status === IncidentStatus.Closed}
                                />
                            </Control>
                            {getHelper(formikBag.errors.informationalIncident)}
                        </Field>
                    </Field>
                    {isInformational &&
                        <>
                            <Field
                                isHorizontal={true}
                                htmlFor="incidentStakeholder"
                                label={translate('IncidentDetails.Label.IncidentStakeholder')}
                                labelSize={BulmaSize.Medium}
                            >
                                <Field>
                                    <Control>
                                        <Select
                                            id="incidentStakeholder"
                                            options={IncidentStakeholder && IncidentStakeholder.map((c) => ({
                                                key: c.key,
                                                label: translate('IncidentDetails.incidentStakeholder.'.concat(c.label.replace(/\s/g, ''))),
                                                value: c.value,
                                            }))}
                                            isDisabled={
                                                formikBag.values.status === IncidentStatus.Closed
                                            }
                                            onChange={(value: IKeyValue<string>) => { props.formikBag.setFieldValue('incidentStakeholder', value?.value) }}
                                            className="form-select-box"
                                            value={{
                                                key: formikBag?.values?.incidentStakeholder as string,
                                                label: translate(
                                                    'IncidentDetails.incidentStakeholder.'.concat(
                                                        formikBag?.values?.incidentStakeholder?.replace(/[^\w]/g, '')
                                                    )
                                                ),
                                                value: formikBag?.values?.incidentStakeholder as string
                                            }}
                                        />
                                    </Control>
                                    {getHelper(formikBag.errors.incidentStakeholder)}
                                </Field>
                            </Field>
                            <Field
                                isHorizontal={true}
                                htmlFor="incidentBasis"
                                label={translate('IncidentDetails.Label.IncidentBasis')}
                                labelSize={BulmaSize.Medium}
                            >
                                <Field>
                                    <Control>
                                        <Input
                                            id="incidentBasis"
                                            disabled={formikBag.values.status === IncidentStatus.Closed}
                                            placeholder={translate(
                                                'IncidentDetails.placeholder.IncidentBasis'
                                            )}
                                            value={formikBag.values.incidentBasis}
                                            onChange={formikBag.handleChange}
                                        />
                                    </Control>
                                    {getHelper(formikBag.errors.incidentBasis)}
                                </Field>
                            </Field>
                        </>
                    }
                    <Field
                        isHorizontal={true}
                        htmlFor="description"
                        label={translate('IncidentDetails.Label.Description')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <textarea
                                    id="description"
                                    className="textarea is-primary"
                                    placeholder={translate(
                                        'IncidentDetails.placeholder.Description'
                                    )}
                                    value={formikBag.values.description}
                                    onChange={formikBag.handleChange}
                                    onBlur={formikBag.handleBlur}
                                    disabled={formikBag.values.status === IncidentStatus.Closed}
                                />
                            </Control>
                            {getHelper(
                                formikBag.errors.description,
                                formikBag.touched.description
                            )}
                        </Field>
                    </Field>
                    <Field
                        isHorizontal={true}
                        htmlFor="responsiblePeople"
                        label={translate('IncidentDetails.Label.IncidentOwner')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field disabled={formikBag.values.status === IncidentStatus.Closed}>
                            <Control>
                                <Select
                                    id="incidentOwner"
                                    options={genericSort(
                                        'fullName',
                                        availablePeopleInfo,
                                        true
                                    ).map((x) => ({
                                        key: x.id,
                                        value: x.email,
                                        label: `${x.fullName} (${x.roles ? x.roles.join(', ') : 'N/A'})`
                                    }))}
                                    isMulti={true}
                                    onChange={updateResponsibleUsers}
                                    value={getUsersValue(
                                        formikBag.values.responsiblePeople
                                    )}
                                    isDisabled={
                                        formikBag.values.status === IncidentStatus.Closed
                                    }
                                />
                            </Control>
                            {getHelper(formikBag.errors.responsiblePeople)}
                        </Field>
                    </Field>
                    <Field
                        isHorizontal={true}
                        htmlFor="started"
                        label={translate('IncidentDetails.Label.startedAt')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field disabled={formikBag.values.status === IncidentStatus.Closed}>
                            <Control
                                disabled={formikBag.values.status === IncidentStatus.Closed}
                                className="buttons custom-date-picker columns"
                            >
                                <div className="column is-6-desktop is-12-mobile is-12-tablet">
                                    <CustomDateTime
                                        disabled={
                                            formikBag.values.status === IncidentStatus.Closed
                                        }
                                        locale={props.language}
                                        setValue={setValue}
                                        value={
                                            formikBag.values.localDateTime != null
                                                ? moment(
                                                    formikBag.values.localDateTime,
                                                    'DD-MM-YYYY HH:mm:ss'
                                                ).toDate()
                                                : formikBag.values.startDateTime
                                        }
                                        setTime={formikBag.values.id ? true : false}
                                        setTimeValid={props.setTimeValid}
                                        showError={formikBag.dirty}
                                    />
                                </div>
                            </Control>
                            {getHelper(formikBag.errors.startDateTime)}
                        </Field>
                    </Field>
                    <Field
                        isHorizontal={true}
                        htmlFor="started"
                        label={translate('IncidentDetails.Label.ResolvedAt')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field disabled={formikBag.values.status === IncidentStatus.Closed}>
                            <Control className="buttons custom-date-picker columns">
                                <div className="column is-6-desktop is-12-mobile is-12-tablet">
                                    <CustomDateTime
                                        disabled={
                                            formikBag.values.status === IncidentStatus.Draft ||
                                            formikBag.values.localDateTime === null ||
                                            formikBag.values.status === IncidentStatus.Closed
                                        }
                                        locale={props.language}
                                        setValue={setResolvedTimeValue}
                                        value={
                                            formikBag.values.resolvedLocalDateTime != null
                                                ? moment(
                                                    formikBag.values.resolvedLocalDateTime,
                                                    'DD-MM-YYYY HH:mm:ss'
                                                ).toDate()
                                                : formikBag.values.resolvedDateTime
                                        }
                                        setTime={formikBag.values.id ? true : false}
                                        setTimeValid={props.setTimeValid}
                                        showError={
                                            formikBag.values.resolvedLocalDateTime !== null &&
                                            formikBag.dirty
                                        }
                                        minDate={formikBag.values.startDateTime}
                                    />
                                </div>
                                <div className="column is-6-desktop is-12-mobile is-12-tablet columns" />
                            </Control>
                            {getHelper(formikBag.errors.resolvedLocalDateTime)}
                        </Field>
                    </Field>
                    <Field
                        isHorizontal={true}
                        htmlFor="started"
                        label={translate('IncidentDetails.Label.Finishedat')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field disabled={formikBag.values.status === IncidentStatus.Closed}>
                            <Control className="buttons custom-date-picker columns">
                                <div className="column is-6-desktop is-12-mobile is-12-tablet">
                                    <CustomDateTime
                                        disabled={
                                            formikBag.values.status === IncidentStatus.Draft ||
                                            formikBag.values.resolvedLocalDateTime === null ||
                                            formikBag.values.status === IncidentStatus.Closed
                                        }
                                        locale={props.language}
                                        setValue={setFinishTimeValue}
                                        value={
                                            formikBag.values.finishLocalDateTime != null
                                                ? moment(
                                                    formikBag.values.finishLocalDateTime,
                                                    'DD-MM-YYYY HH:mm:ss'
                                                ).toDate()
                                                : formikBag.values.finishDateTime
                                        }
                                        setTime={formikBag.values.id ? true : false}
                                        setTimeValid={props.setTimeValid}
                                        showError={
                                            formikBag.values.finishLocalDateTime !== null &&
                                            formikBag.dirty
                                        }
                                        minDate={formikBag.values.resolvedDateTime}
                                    />
                                </div>
                                <div className="column is-6-desktop is-12-mobile is-12-tablet columns" />
                            </Control>
                            {getHelper(formikBag.errors.finishLocalDateTime)}
                        </Field>
                    </Field>
                    <Field
                        isHorizontal={true}
                        htmlFor="businessImpactDescription"
                        label={translate('IncidentDetails.Label.CustomerBusinessImpact')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control className="columns">
                                <div style={{ padding: '12.125px' }}>
                                    <Radio
                                        id="businessImpactRadio"
                                        name="businessImpactRadio"
                                        options={yesNoOptions}
                                        value={
                                            formikBag.values.isBusinessImpacted ? 'true' : 'false'
                                        }
                                        onBlur={formikBag.handleBlur}
                                        onChange={handleIsBsuinessImpacted}
                                    />
                                </div>
                            </Control>
                            {getHelper(formikBag.errors.isBusinessImpacted)}
                            <Control className="columns">
                                <div className="column">
                                    <textarea
                                        id="businessImpactDescription"
                                        className="textarea is-primary"
                                        placeholder={translate(
                                            'IncidentDetails.placeholder.CustomerBusinessImpact'
                                        )}
                                        value={formikBag.values.businessImpactDescription ?? ''}
                                        onChange={handleBusinessImpactDescription}
                                        onBlur={formikBag.handleBlur}
                                        disabled={
                                            !isBusinessImpacted ||
                                            !formikBag.values.isBusinessImpacted ||
                                            formikBag.values.status === IncidentStatus.Closed
                                        }
                                    />
                                </div>
                            </Control>
                            <Help bulmaColor={BulmaColor.Danger} isShown={true}>
                                {formikBag.dirty &&
                                    isBusinessImpacted &&
                                    !(formikBag.values.businessImpactDescription?.length > 0)
                                    ? translate('IncidentDetails.Label.CustomerBusinessImpact') +
                                    ' description is required'
                                    : ''}
                            </Help>
                        </Field>
                    </Field>
                    <Field
                        isHorizontal={true}
                        htmlFor="operationalImpactDescription"
                        label={translate('IncidentDetails.Label.OperationalImpact')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <textarea
                                    id="operationalImpactDescription"
                                    className="textarea is-primary"
                                    placeholder={translate(
                                        'IncidentDetails.placeholder.IncidentOperationalImpact'
                                    )}
                                    value={formikBag.values.operationalImpactDescription}
                                    onChange={operationalImpactDescriptionChange}
                                    onBlur={formikBag.handleBlur}
                                    disabled={formikBag.values.status === IncidentStatus.Closed}
                                />
                            </Control>
                        </Field>
                    </Field>
                    <Field
                        isHorizontal={true}
                        htmlFor="lessonLearnDescription"
                        label={translate('IncidentDetails.Label.LessonLearn')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control className="columns">
                                <div style={{ padding: '12.125px' }}>
                                    <Radio
                                        id="lessonLearnRadio"
                                        name="lessonLearnRadio"
                                        options={yesNoOptions}
                                        value={
                                            formikBag.values.hasLessonsLearned ? 'true' : 'false'
                                        }
                                        onBlur={formikBag.handleBlur}
                                        onChange={handleHasLessonsLearned}
                                    />
                                </div>
                            </Control>
                            {getHelper(formikBag.errors.hasLessonsLearned)}
                            <Control className="columns">
                                <div className="column">
                                    <textarea
                                        id="lessonLearnDescription"
                                        className="textarea is-primary"
                                        placeholder={translate(
                                            'IncidentDetails.placeholder.IncidentLessonLearnt'
                                        )}
                                        value={formikBag.values.lessonLearnDescription}
                                        onChange={handleLessonsLearnedDescription}
                                        onBlur={formikBag.handleBlur}
                                        disabled={
                                            !hasLessonsLearned ||
                                            !formikBag.values.hasLessonsLearned ||
                                            formikBag.values.status === IncidentStatus.Closed
                                        }
                                    />
                                </div>
                            </Control>
                            <Help bulmaColor={BulmaColor.Danger} isShown={true}>
                                {formikBag.dirty &&
                                    hasLessonsLearned &&
                                    !(formikBag.values.lessonLearnDescription?.length > 0)
                                    ? translate('IncidentDetails.Label.LessonLearn') +
                                    ' description is required'
                                    : ''}
                            </Help>
                            {formikBag.values.lessonsLearned?.id && (
                                <NavLink
                                    to={`/MockDrill/LessonsLearned/Reports/Global/${formikBag.values.lessonsLearned?.id}`}
                                    className="button is-primary"
                                >
                                    View Created Lessons Learned
                                </NavLink>
                            )}
                        </Field>
                    </Field>
                    <div className="title is-4 section-title">
                        {translate('IncidentDetails.SectionTitle.Cause')}
                    </div>
                    <Field
                        isHorizontal={true}
                        htmlFor="rootCause"
                        label={translate('IncidentDetails.Label.PrimaryRootCause')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control>
                                <Select
                                    id="rootCause"
                                    options={incidentRootCause && incidentRootCause.map((c) => ({
                                        key: c.key,
                                        label: translate('IncidentDetails.incidentRootCause.'.concat(c.label.replace(/\s/g, ''))),
                                        value: c.value,
                                    }))}
                                    isDisabled={
                                        formikBag.values.status === IncidentStatus.Closed
                                    }
                                    onChange={handleRootChange}
                                    className="form-select-box"
                                    value={{
                                        key: formikBag?.values?.rootCause as string,
                                        label: translate(
                                            'IncidentDetails.incidentRootCause.'.concat(
                                                formikBag?.values?.rootCause?.replace(/[^\w]/g, '')
                                            )
                                        ),
                                        value: formikBag?.values?.rootCause as string
                                    }}
                                />
                            </Control>
                            {getHelper(formikBag.errors.rootCause)}
                        </Field>
                    </Field>
                    {
                        config.REACT_APP_FEATURE_FLAG_ASSET_INTEGRATION === 'true' ?
                            (
                                <>
                                    <Field
                                        isHorizontal={true}
                                        label={translate('Asset.Label.Title')}
                                        labelSize={BulmaSize.Medium}>
                                        <Asset
                                            showLabel={false}
                                            isManadatory={isEquipmentFailure(formikBag.values.rootCause)}
                                            setValue={setAssetFieldValues}
                                            isValid={(v) => {
                                                isEquipmentFailure(formikBag.values.rootCause) ? props.setIsValidDetails(v) : props.setIsValidDetails(true)
                                            }}
                                            selectedAsset={{
                                                siteId: formikBag.values.siteId,
                                                manufacturer: formikBag.values.equipmentManufacturer,
                                                model: formikBag.values.equipmentModel,
                                                serial: formikBag.values.equipmentSerial,
                                                cmmsReference: formikBag.values.equipmentCmmsAsset,
                                                name: formikBag.values.equipmentName,
                                                age: formikBag.values.equipmentAge,
                                            }}
                                            isDisabled={false}
                                        />
                                    </Field>
                                    <Field isHorizontal={true} label=" ">
                                        {formikBag.errors &&
                                            Object.keys(formikBag.errors).find(f => f === "equipmentManufacturer" || f === "equipmentModel" || f === "equipmentName") &&
                                            getHelper(translate('Validations.Incident.Equipment'))}
                                    </Field>
                                </>
                            ) :
                            (
                                formikBag && formikBag.values && isEquipmentFailure(formikBag.values.rootCause) &&
                                <>
                                    <Field isHorizontal={true} label=" ">
                                        <Field>
                                            <Control className="columns is-desktop">
                                                <div className="column is-4">
                                                    <TextField
                                                        id="equipmentCmmsAsset"
                                                        label={`${translate(
                                                            'IncidentDetails.Label.CMMSAsset'
                                                        )} #`}
                                                        value={formikBag.values.equipmentCmmsAsset}
                                                        handleChange={formikBag.handleChange}
                                                        error={formikBag.errors.equipmentCmmsAsset}
                                                        setVal={formikBag.setFieldValue}
                                                    />
                                                </div>
                                                <div className="column is-4">
                                                    <TextField
                                                        id="equipmentManufacturer"
                                                        label={`${translate('IncidentDetails.Label.EquipmentManufacturer')}*`}
                                                        value={formikBag.values.equipmentManufacturer}
                                                        handleChange={formikBag.handleChange}
                                                        error={formikBag.errors.equipmentManufacturer}
                                                        setVal={formikBag.setFieldValue}
                                                    />
                                                </div>
                                                <div className="column is-4">
                                                    <TextField
                                                        id="equipmentModel"
                                                        label={`${translate('IncidentDetails.Label.EquipmentModel')}#*`}
                                                        value={formikBag.values.equipmentModel}
                                                        handleChange={formikBag.handleChange}
                                                        error={formikBag.errors.equipmentModel}
                                                        setVal={formikBag.setFieldValue}
                                                    />
                                                </div>
                                            </Control>
                                        </Field>
                                    </Field>
                                    <Field isHorizontal={true} label=" ">
                                        <Field>
                                            <Control className="columns is-desktop">
                                                <div className="column is-4">
                                                    <TextField
                                                        id="equipmentSerial"
                                                        label={`${translate(
                                                            'IncidentDetails.Label.EquipmentSerial'
                                                        )}#`}
                                                        value={formikBag.values.equipmentSerial}
                                                        handleChange={formikBag.handleChange}
                                                        error={formikBag.errors.equipmentSerial}
                                                        setVal={formikBag.setFieldValue}
                                                    />
                                                </div>
                                                <div className="column is-4">
                                                    <TextField
                                                        id="equipmentName"
                                                        label={`${translate(
                                                            'IncidentDetails.Label.EquipmentName'
                                                        )}*`}
                                                        value={formikBag.values.equipmentName}
                                                        handleChange={formikBag.handleChange}
                                                        error={formikBag.errors.equipmentName}
                                                        setVal={formikBag.setFieldValue}
                                                    />
                                                </div>
                                                <div className="column is-4">
                                                    <TextField
                                                        id="equipmentAge"
                                                        label={translate(
                                                            'IncidentDetails.Label.EquipmentAge'
                                                        )}
                                                        value={formikBag.values.equipmentAge}
                                                        handleChange={formikBag.handleChange}
                                                        error={formikBag.errors.equipmentAge}
                                                        setVal={formikBag.setFieldValue}
                                                    />
                                                </div>
                                            </Control>
                                        </Field>
                                    </Field>
                                </>
                            )
                    }

                    <Field
                        isHorizontal={true}
                        htmlFor="factors"
                        label={translate('IncidentDetails.Label.ContributingFactors')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field>
                            <Control className="columns is-desktop">
                                <div className="column is-one-quarter">
                                    <Checkbox
                                        checked={formikBag.values.contributingFactors.includes(
                                            ContributingFactors.BMSAutomation
                                        )}
                                        id={ContributingFactors.BMSAutomation}
                                        label={translate(
                                            'IncidentDetails.ContributingFactors.'.concat(
                                                ContributingFactors.BMSAutomation.toString().replace(
                                                    /\s/g,
                                                    ''
                                                )
                                            )
                                        )}
                                        onChange={handleFactorsChange.bind(
                                            this,
                                            ContributingFactors.BMSAutomation
                                        )}
                                        disabled={
                                            formikBag.values.status === IncidentStatus.Closed
                                        }
                                    />
                                </div>
                                <div className="column is-one-quarter">
                                    <Checkbox
                                        checked={formikBag.values.contributingFactors.includes(
                                            ContributingFactors.Training
                                        )}
                                        id={ContributingFactors.Training}
                                        label={translate(
                                            'IncidentDetails.ContributingFactors.'.concat(
                                                ContributingFactors.Training.toString().replace(
                                                    /\s/g,
                                                    ''
                                                )
                                            )
                                        )}
                                        onChange={handleFactorsChange.bind(
                                            this,
                                            ContributingFactors.Training
                                        )}
                                        disabled={
                                            formikBag.values.status === IncidentStatus.Closed
                                        }
                                    />
                                </div>
                                <div className="column is-one-quarter">
                                    <Checkbox
                                        checked={formikBag.values.contributingFactors.includes(
                                            ContributingFactors.ChangeControl
                                        )}
                                        id={ContributingFactors.ChangeControl}
                                        label={translate(
                                            'IncidentDetails.ContributingFactors.'.concat(
                                                ContributingFactors.ChangeControl.toString().replace(
                                                    /\s/g,
                                                    ''
                                                )
                                            )
                                        )}
                                        onChange={handleFactorsChange.bind(
                                            this,
                                            ContributingFactors.ChangeControl
                                        )}
                                        disabled={
                                            formikBag.values.status === IncidentStatus.Closed
                                        }
                                    />
                                </div>
                                <div className="column is-one-quarter">
                                    <Checkbox
                                        checked={formikBag.values.contributingFactors.includes(
                                            ContributingFactors.Unclear
                                        )}
                                        id={ContributingFactors.Unclear}
                                        label={translate(
                                            'IncidentDetails.ContributingFactors.'.concat(
                                                ContributingFactors.Unclear.toString().replace(
                                                    /\s/g,
                                                    ''
                                                )
                                            )
                                        )}
                                        onChange={handleFactorsChange.bind(
                                            this,
                                            ContributingFactors.Unclear
                                        )}
                                        disabled={
                                            formikBag.values.status === IncidentStatus.Closed
                                        }
                                    />
                                </div>
                            </Control>
                        </Field>
                    </Field>
                    <Field isHorizontal={true} label=" ">
                        <Field disabled={formikBag.values.status === IncidentStatus.Closed}>
                            <Control className="columns is-desktop">
                                <div className="column is-3">
                                    <Checkbox
                                        checked={formikBag.values.contributingFactors.includes(
                                            ContributingFactors.ContractorRelated
                                        )}
                                        id={ContributingFactors.ContractorRelated}
                                        label={translate(
                                            'IncidentDetails.ContributingFactors.'.concat(
                                                ContributingFactors.ContractorRelated.toString().replace(
                                                    /\s/g,
                                                    ''
                                                )
                                            )
                                        )}
                                        onChange={handleFactorsChange.bind(
                                            this,
                                            ContributingFactors.ContractorRelated
                                        )}
                                        disabled={
                                            formikBag.values.status === IncidentStatus.Closed
                                        }
                                    />
                                </div>
                                <div className="column is-3">
                                    <Checkbox
                                        checked={formikBag.values.contributingFactors.includes(
                                            ContributingFactors.WorkPractice
                                        )}
                                        id={ContributingFactors.WorkPractice}
                                        label={translate(
                                            'IncidentDetails.ContributingFactors.'.concat(
                                                ContributingFactors.WorkPractice.toString().replace(
                                                    /\s/g,
                                                    ''
                                                )
                                            )
                                        )}
                                        onChange={handleFactorsChange.bind(
                                            this,
                                            ContributingFactors.WorkPractice
                                        )}
                                        disabled={
                                            formikBag.values.status === IncidentStatus.Closed
                                        }
                                    />
                                </div>
                                <div className="column is-3">
                                    <Checkbox
                                        checked={formikBag.values.contributingFactors.includes(
                                            ContributingFactors.Equipment
                                        )}
                                        id={ContributingFactors.Equipment}
                                        label={translate(
                                            'IncidentDetails.ContributingFactors.'.concat(
                                                ContributingFactors.Equipment.toString().replace(
                                                    /\s/g,
                                                    ''
                                                )
                                            )
                                        )}
                                        onChange={handleFactorsChange.bind(
                                            this,
                                            ContributingFactors.Equipment
                                        )}
                                        disabled={
                                            formikBag.values.status === IncidentStatus.Closed
                                        }
                                    />
                                </div>
                                <div className="column is-3">
                                    <Checkbox
                                        checked={formikBag.values.contributingFactors.includes(
                                            ContributingFactors.Investigation
                                        )}
                                        id={ContributingFactors.Investigation}
                                        label={translate(
                                            'IncidentDetails.ContributingFactors.'.concat(
                                                ContributingFactors.Investigation.toString().replace(
                                                    /\s/g,
                                                    ''
                                                )
                                            )
                                        )}
                                        onChange={handleFactorsChange.bind(
                                            this,
                                            ContributingFactors.Investigation
                                        )}
                                        disabled={
                                            formikBag.values.status === IncidentStatus.Closed
                                        }
                                    />
                                </div>
                            </Control>
                        </Field>
                    </Field>
                    <Field isHorizontal={true} label=" ">
                        <Field>
                            <Control className="columns is-gapless">
                                <div className="column is-4">
                                    <Checkbox
                                        checked={formikBag.values.contributingFactors.includes(
                                            ContributingFactors.Other
                                        )}
                                        id={ContributingFactors.Other}
                                        label={translate(
                                            'IncidentDetails.ContributingFactors.'.concat(
                                                ContributingFactors.Other.toString()
                                                    .replace(/\s/g, '')
                                                    .replace(/[,]/g, '')
                                            )
                                        )}
                                        onChange={handleFactorsChange.bind(
                                            this,
                                            ContributingFactors.Other
                                        )}
                                        disabled={
                                            formikBag.values.status === IncidentStatus.Closed
                                        }
                                    />
                                </div>
                                <div className="column is-8">
                                    <Input
                                        id="other"
                                        value={formikBag.values.other}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                        disabled={
                                            !formikBag.values.contributingFactors.includes(
                                                ContributingFactors.Other
                                            ) ||
                                            formikBag.values.status === IncidentStatus.Closed
                                        }
                                    />
                                </div>
                            </Control>
                            {
                                ((formikBag.values.contributingFactors.includes(ContributingFactors.Other))
                                    || formikBag.values.contributingFactors.length === 0
                                )
                                &&
                                getHelper(formikBag.errors.other, formikBag.touched.other)}
                        </Field>
                    </Field>

                    <div className="title is-4 section-title">
                        {translate('IncidentRiskActions.Label.Impact')}
                    </div>

                    <IncidentImpactedCheckBoxes
                        formikBag={formikBag}
                        getHelper={getHelper}
                        handleFactorsChange={handleIncidentImpactChange}
                    />
                    <hr />
                    <ImpactedSystemsCheckBoxes
                        formikBag={formikBag}
                        getHelper={getHelper}
                        handleFactorsChange={handleImpactedSystemsChange}
                    />
                    <hr />

                    <Field
                        isHorizontal={true}
                        htmlFor="factors"
                        label={translate('IncidentDetails.Label.Scaleofimpact')}
                        labelSize={BulmaSize.Medium}
                    >
                        <div style={{ paddingRight: '12.125px', width: '100%' }}>
                            <Radio
                                id="ScaleOfImpactRadio"
                                name="ScaleOfImpactRadio"
                                options={scaleOfImpactOptions.map((c) => ({
                                    value: c.value,
                                    key: translate(
                                        'IncidentDetails.ScaleOfImpact.'.concat(
                                            c.key.replace(/\s/g, '').replace(/[,]/g, '')
                                        )
                                    ),
                                }))}
                                value={formikBag.values.scaleOfImpact[0]}
                                onChange={handleScaleOfImpactChange}
                                onBlur={formikBag.handleBlur}
                            />
                        </div>
                    </Field>
                    <Field isHorizontal={true} label=" ">
                        <div style={{ paddingRight: '12.125px', width: '50%' }}>
                            <Input
                                id="scaleOfImpactOther"
                                value={formikBag.values.scaleOfImpactOther}
                                onChange={formikBag.handleChange}
                                onBlur={formikBag.handleBlur}
                                disabled={
                                    !formikBag.values.scaleOfImpact.includes(
                                        ScaleOfImpact.ScaleOfImpactOther
                                    ) || formikBag.values.status === IncidentStatus.Closed
                                }
                            />
                        </div>
                    </Field>
                    <Field isHorizontal={true} label=" ">
                        {getHelper(formikBag.errors.scaleOfImpactOther)}
                    </Field>
                </div>
            </div>
        </Loader>
    );
};

export default connect(selectors, actions)(IncidentDetails);