import { SiteProfileActions, SiteProfileActionTypes } from '../../../actions/site-profile-actions';
import { onSort, loadSites } from '../../../actions/actions-v2/site-actions-v2';
import { ISiteFilters } from 'models/site-filters';
export default {
    onToggleExpanded: (id: string): SiteProfileActionTypes => ({
        type: SiteProfileActions.TOGGLE_ITEM_EXPANDED,
        payload: {
            id
        }
    }),
    onToggleFilterExpanded: (id: Extract<keyof ISiteFilters, string>): SiteProfileActionTypes => ({
        type: SiteProfileActions.TOGGLE_FILTER_EXPANDED,
        payload: {
             id
        }
    }),

    onPageLoad: (): SiteProfileActionTypes => ({
        type: SiteProfileActions.LOAD_SITE_PROFILES
    }),
    loadSites,
    onSort
};
