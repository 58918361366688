import { FormikProps } from 'formik';
import _ from 'lodash';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Checkbox } from 'components/form/Checkbox';
import { PopoutSelect } from 'components/popoutSelect';
import { IRisk } from 'models/risk';
import { ISelectOption } from 'models/select-option';
import { Control, Field } from '../../../../../../components/form';
import { IProcessStep } from '../../../../../../reducers/cermAssessments';
import { getKeyed, onPopoutSelectChange } from '../../../../../../utils/form-utils';
import { ICermAssessmentRouteProps } from '../../../cerm-assessments-page';
import { IRiskFilters } from 'models/IRiskFilters';
import { Button } from 'components/v2/components';

export interface IProps extends RouteComponentProps<ICermAssessmentRouteProps> {
    onChangeRoot: (urlLocation: string) => void;
    loadRisks: (filters: IRiskFilters) => void;
    setAssessmentRisk: (processStep: Partial<IProcessStep>) => void;
    formikBag: FormikProps<Partial<IProcessStep>>;
    riskList: IRisk[];
    siteId: string;
}

export const ExistingRiskForm: React.FC<IProps> = (props) => {
    React.useEffect(() => {
        if (!props.siteId) {
            return;
        }
        props.loadRisks({ siteId: props.siteId } as IRiskFilters);
    }, [props.siteId]);

    const onHandelSaveRiskDetails = () => {
        if (props.formikBag.isValid || (!props.formikBag.dirty && value('title'))) {
            props.setAssessmentRisk(props.formikBag.values);
            props.onChangeRoot(`/CermAssessment`);
        } else {
            props.formikBag.submitForm();
        }
    };

    const onHandleBack = () => {
        if (props.formikBag.isValid || (!props.formikBag.dirty && value('title'))) {
            props.onChangeRoot(`${props.formikBag.values.expectedOutcomeAnswers.length - 1}`);
        } else {
            props.formikBag.submitForm();
        }
    };

    function value<T extends Extract<keyof IProcessStep, string>>(name: T) {
        const val = getKeyed(props.formikBag, 'values', field(name));
        return !_.isNil(val) ? val : '';
    }

    const field = (name: Extract<keyof IProcessStep, string>) => `${name}`;
    const getRiskList = () => {
        let risk = props.riskList;

        if (props.formikBag.values.isProcessIssueSelected) {
            risk = props.riskList.filter((c) => c.riskCategory === 'Process Issue');
        }

        const result: ISelectOption[] = risk.map((option) => {
            return { label: option.title, value: option.id };
        });

        return result;
    };

    return (
        <>
            <h2 className="title is-4">Select Risk</h2>

            <Field>
                <Checkbox
                    label="Search only 'Process Issues'"
                    checked={props.formikBag.values.isProcessIssueSelected}
                    onChange={props.formikBag.handleChange}
                    id={field('isProcessIssueSelected')}
                    name={field('isProcessIssueSelected')}
                />
            </Field>
            <Field className="">
                <Control>
                    <PopoutSelect
                        className="is-fullwidth"
                        id={field('relatedRiskId')}
                        name={field('relatedRiskId')}
                        value={value('relatedRiskId')}
                        onBlur={props.formikBag.handleBlur}
                        onChange={props.formikBag.handleChange}
                        onSelectChange={onPopoutSelectChange.bind(
                            this,
                            props.formikBag,
                            'relatedRiskId'
                        )}
                        options={getRiskList()}
                    />
                </Control>
            </Field>
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="action-groups">
                <div className="action-group">
                    <Button onClick={onHandleBack} buttonType="cancel">
                        Back
                    </Button>
                </div>
                <div className="action-group">
                    <Button onClick={onHandelSaveRiskDetails}>Save & Complete</Button>
                </div>
            </div>
        </>
    );
};
