import { IAppAction } from 'actions/app-action';
import { combineReducers } from 'redux';
import { ILogsUploadState } from 'state/logs-upload-state';
import { LogsUploadActions } from 'actions/actions-v2/logs-upload-actions';
import { ITemplateValidationSummary } from 'models/logs-upload';
import { s2ab } from 'utils/export-utils';
import saveAs from 'file-saver';

const INITIAL_STATE: ILogsUploadState = {
    isLoading: false,
    isExporting: false,
    results: {} as ITemplateValidationSummary,
};

const isExporting = (
    state: ILogsUploadState['isExporting'] = INITIAL_STATE.isExporting,
    action: IAppAction
) => {
    switch (action.type) {
        case LogsUploadActions.DOWNLOAD_TEMPLATE:
            return true;

        case LogsUploadActions.DOWNLOAD_TEMPLATE_FULFILLED:
            const blob = new Blob([s2ab(atob(action.payload.fileContents))], {
                type: 'application/excel',
            });
            saveAs(blob, action.payload.fileDownloadName);
            return false;

        case LogsUploadActions.DOWNLOAD_TEMPLATE_REJECTED:
            return false;

        default:
            return state;
    }
};

const isLoading = (
    state: ILogsUploadState['isLoading'] = INITIAL_STATE.isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case LogsUploadActions.UPLOAD:
        case LogsUploadActions.DOWNLOAD_TEMPLATE:
            return true;

        case LogsUploadActions.UPLOAD_FULFILLED:
        case LogsUploadActions.UPLOAD_REJECTED:
        case LogsUploadActions.DOWNLOAD_TEMPLATE_FULFILLED:
        case LogsUploadActions.DOWNLOAD_TEMPLATE_REJECTED:
            return false;

        default:
            return state;
    }
};

const results = (
    state: ILogsUploadState['results'] = INITIAL_STATE.results,
    action: IAppAction
) => {
    switch (action.type) {
        case LogsUploadActions.UPLOAD_FULFILLED:
            if (action.payload) {
                return action.payload;
            }
        case LogsUploadActions.RESET_LOGS:
            return {};
        default:
            return state;
    }
};

export const logsUploadReducer = combineReducers<ILogsUploadState>({
    isLoading,
    isExporting,
    results,
});
