import { Action } from 'redux';
import { ICermSiteOverviewGroup } from 'models/cerm-site-overview';

export enum CermSiteOverviewGridActions {
    LOAD_DATA = '@@cermSiteOverview/LOAD_DATA',
    LOAD_DATA_FULFILLED = '@@cermSiteOverview/LOAD_DATA_FULFILLED',
    LOAD_DATA_CANCELLED = '@@cermSiteOverview/LOAD_DATA_CANCELLED',
    LOAD_DATA_REJECTED = '@@cermSiteOverview/LOAD_DATA_REJECTED',

    TOGGLE_ITEM_EXPANDED = '@@cermSiteOverview/TOGGLE_ITEM_EXPANDED',
    COLLAPSE_ALL_ITEMS = '@@cermSiteOverview/COLLAPSE_ALL_ITEMS',
    EXPAND_ITEMS = '@@cermSiteOverview/EXPAND_ITEMS',
    LOAD_PAGE = '@@cermSiteOverview/LOAD_PAGE',
    LOAD_PAGE_FULFILLED = '@@cermSiteOverview/LOAD_PAGE_FULFILLED',
    LOAD_PAGE_REJECTED = '@@cermSiteOverview/LOAD_PAGE_REJECTED',
    LOAD_PAGE_CANCELLED = '@@cermSiteOverview/LOAD_PAGE_CANCELLED'
}

export interface ILoadPageAction extends Action {
    type: CermSiteOverviewGridActions.LOAD_PAGE;
}

export interface ILoadPageRejectedAction extends Action {
    type: CermSiteOverviewGridActions.LOAD_PAGE_REJECTED;
    error: true;
    payload: any;
}

export interface ILoadPageCancelledAction extends Action {
    type: CermSiteOverviewGridActions.LOAD_PAGE_CANCELLED;
}

export interface ILoadPageFulfilledAction extends Action {
    type: CermSiteOverviewGridActions.LOAD_PAGE_FULFILLED;
    payload: any;
}

export interface IToggleItemExpandedAction extends Action {
    type: CermSiteOverviewGridActions.TOGGLE_ITEM_EXPANDED;
    payload: {
        id: string;
    };
}

export const toggleItemExpanded = (id: string): IToggleItemExpandedAction => ({
    type: CermSiteOverviewGridActions.TOGGLE_ITEM_EXPANDED,
    payload: {
        id
    }
});

export interface ILoadDataAction extends Action {
    type: CermSiteOverviewGridActions.LOAD_DATA;
}

export const loadData = (): ILoadDataAction => ({
    type: CermSiteOverviewGridActions.LOAD_DATA
});

export interface ILoadDataFulfilledAction extends Action {
    type: CermSiteOverviewGridActions.LOAD_DATA_FULFILLED;
    payload: {
        data: ICermSiteOverviewGroup[];
    };
}

export const loadDataFulfilled = (data: ICermSiteOverviewGroup[]): ILoadDataFulfilledAction => ({
    type: CermSiteOverviewGridActions.LOAD_DATA_FULFILLED,
    payload: {
        data
    }
});

export interface ILoadDataRejectedAction extends Action {
    type: CermSiteOverviewGridActions.LOAD_DATA_REJECTED;
    payload: {
        error: any;
    };
    error: true;
}

export const loadDataRejected = (error: any): ILoadDataRejectedAction => ({
    type: CermSiteOverviewGridActions.LOAD_DATA_REJECTED,
    payload: {
        error
    },
    error: true
});

export interface ILoadDataCancelledAction extends Action {
    type: CermSiteOverviewGridActions.LOAD_DATA_CANCELLED;
}

export const loadDataCancelled = (): ILoadDataCancelledAction => ({
    type: CermSiteOverviewGridActions.LOAD_DATA_CANCELLED
});

export interface ICollapseAllItemsAction extends Action {
    type: CermSiteOverviewGridActions.COLLAPSE_ALL_ITEMS;
}

export const collapseAllItems = (): ICollapseAllItemsAction => ({
    type: CermSiteOverviewGridActions.COLLAPSE_ALL_ITEMS
});

export interface IExpandItemsAction extends Action {
    type: CermSiteOverviewGridActions.EXPAND_ITEMS;
    payload: {
        ids: string[];
    };
}

export const expandItems = (...ids: string[]): IExpandItemsAction => ({
    type: CermSiteOverviewGridActions.EXPAND_ITEMS,
    payload: {
        ids
    }
});

export type CermSiteOverviewGridActionTypes =
    | ILoadDataAction
    | ILoadDataCancelledAction
    | ILoadDataFulfilledAction
    | ILoadDataRejectedAction

    | IToggleItemExpandedAction
    | ICollapseAllItemsAction
    | IExpandItemsAction
    | ILoadPageAction
    | ILoadPageCancelledAction
    | ILoadPageRejectedAction
    | ILoadPageFulfilledAction;
