import { createSelector } from 'reselect';

import { IKeyedItem } from '../../../../models/keyed-item';
import { IRootRisksState } from '../../../../reducers/risks';
import { getKey } from '../../../../utils/id-utils';

const getIsExpanded = (state: IRootRisksState, props: { item: IKeyedItem }) =>
    state.risks.grid.expandedAttachments.hasOwnProperty(props.item.id);

const getIsLoading = (state: IRootRisksState, props: { item: IKeyedItem }) => {
    const match = state.risks.attachments.byRisk[getKey(props.item)];
    return Boolean(match && match.isLoading);
};

const getAttachmentCollection = (state: IRootRisksState, props: { item: IKeyedItem }) => {
    const match = state.risks.attachments.byRisk[getKey(props.item)];
    return (match && match.data) || [];
};

export default () => createSelector(
    getIsExpanded,
    getIsLoading,
    getAttachmentCollection,
    (isExpanded, isLoading, data) => ({
        isExpanded,
        isLoading,
        data,
        isDisabled: false,
    })
);
