import { connect } from 'react-redux';
import { ClientPage as View } from './client-page';

import { withRouter } from 'react-router';
import actions from './actions';
import selectors from './selectors';

export const ClientsPage = withRouter(connect(
    selectors,
    actions
)(View));
