function setupGetInstanceId() {
  let instanceId = 0;
  return function getInstanceId() {
    return ++instanceId;
  };
}

const getId = setupGetInstanceId();

export function getUid(prefix = 'id') {
  return `${prefix}-${getId()}`;
}
