import _ from 'lodash';
import { combineReducers } from 'redux';
import { IAttachmentCollection } from 'models/attachment-collection';
import { ActionOrEvent } from 'models/cerm-process-step';
import { IKeyedItem } from 'models/keyed-item';
import { IActionTemplate, IEventTemplate, IRiskTemplate } from 'models/question-set';
import {
    CermAssessmentActions,
    CermAssessmentActionTypes
} from '../../actions/cerm-assessment-actions';
import { IBaseState } from '../base-state';
import { IAppAction } from 'actions/app-action';
import { CermActions } from 'actions/actions-v2/cerm-assessment-action-v2';
import { IRisk } from 'models/risk';
import { genericSort } from 'utils/sort-utils';

export interface IRootCermAssessmentState extends IBaseState {
    cermAssessmentState: ICermAssessmentsState;
}

export interface IOutcome {
    compliantResponse: boolean;
    clarification: string;
    masterExpectedOutcomeId: string;
    title: string;
    answer?: boolean;
    actionOrEvent: ActionOrEvent;
    action: Partial<IActionTemplate>;
    event: Partial<IEventTemplate>;
    relatedEventIds: string[];
    createEvents: boolean;
}

export interface IProcessStep extends IKeyedItem {
    timePeriod: string;
    completed: boolean;
    priority: string;
    processStepScore?: number;
    processStepCompliance?: number;
    phase: string;
    processNumber: number;
    service: string;
    title: string;
    expectedOutcomeAnswers: Array<Partial<IOutcome>>;
    applicable?: boolean;
    allAnswered: boolean;
    createNewRisk: string;
    riskTemplate: Partial<IRiskTemplate>;
    relatedRiskId: string;
    isProcessIssueSelected: boolean;
    attachments: IAttachment[];
}

export interface IAttachment {
    childId: string;
    fileName: string;
}

export interface IPillar {
    id: string;
    title: string;
    pillarScore?: number;
    pillarCompliance?: number;
    processSteps: IProcessStep[];
}
export interface ISortProps {
    sortColumn: string;
    sortAsending: boolean;
}

export interface ICermAssessmentsState {
    riskList: IRisk[];
    attachments: IAttachmentCollection[];
    isLoading: boolean;
    reviewProcessStep: Partial<IProcessStep>;
    siteScore: number;
    expandedPillars: string[];
    expandedSteps: string[];
    expandedFilters: string[];
    pillars: IPillar[];
    isVisible: boolean;
    outcomeSavedAndCompleted: boolean;
}

const INITIAL_STATE: ICermAssessmentsState = {
    attachments: [],
    isLoading: false,
    reviewProcessStep: null,
    expandedPillars: [
        '100: Administration',
        '200: Training & Qualifications',
        '300: Maintenance & Operations',
        '400: Planning, Analysis & Engineering',
        '500: Continuous Improvement'
    ],
    expandedSteps: [],
    expandedFilters: ['serviceFilters', 'priorityFilters', 'pillarFilters', 'phaseFilters'],
    pillars: [],
    riskList: [],
    siteScore: 0,
    isVisible: false,
    outcomeSavedAndCompleted: false
};

const attachments = (
    state: ICermAssessmentsState['attachments'] = INITIAL_STATE.attachments,
    action: CermAssessmentActionTypes
) => {
    switch (action.type) {
        case CermAssessmentActions.LOAD_ATTACHMENTS_FULFILLED:
            return action.payload.data;
        default:
            return state;
    }
};

const reviewProcessStep = (
    state: ICermAssessmentsState['reviewProcessStep'] = INITIAL_STATE.reviewProcessStep,
    action: CermAssessmentActionTypes
) => {
    switch (action.type) {
        case CermAssessmentActions.CONFIRM_CLOSE:
            return action.payload.processStep;
        default:
            return state;
    }
};

const isVisible = (
    state: ICermAssessmentsState['isVisible'] = INITIAL_STATE.isVisible,
    action: CermAssessmentActionTypes
) => {
    switch (action.type) {
        case CermAssessmentActions.SHOW_ADD_ATTACHMENT_MODAL:
            return true;
        case CermAssessmentActions.HIDE_ADD_ATTACHMENT_MODAL:
        case CermAssessmentActions.ADD_ATTACHMENT_FULFILLED:
            return false;
        default:
            return state;
    }
};

const pillars = (
    state: ICermAssessmentsState['pillars'] = INITIAL_STATE.pillars,
    action: IAppAction
) => {
    switch (action.type) {
        case CermActions.LOAD_CERM_ASSESSMENT_FULFILLED:
            return action.payload.pillars;
        case CermActions.SET_PROCESS_STEP_FULFILLED:
        case CermActions.SET_OUTCOME_FULFILLED:
            if (action.payload && action.payload.pillars) {
                const pillarsModel = [...action.payload.pillars];
                const newPillars = pillarsModel.map((process: IPillar) => {
                    const pillar = state.find((x) => x.id === process.id);
                    if (pillar) {
                        return {
                            id: process.id,
                            pillarScore: process.pillarScore,
                            pillarCompliance: process.pillarCompliance,
                            title: process.title,
                            processSteps: pillar.processSteps.map((p: IProcessStep) => {
                                const processStep = process.processSteps.find((x) => x.id === p.id);
                                return {
                                    ...p,
                                    processStepScore: processStep.processStepScore,
                                    processStepCompliance: processStep.processStepCompliance
                                };
                            })
                        };
                    }
                });
                return newPillars;
            }
            return state;
        case CermActions.SORT_CERM:
            const processSteps = [...state];
            const newState = processSteps.map((process) => {
                return {
                    id: process.id,
                    pillarScore: process.pillarScore,
                    pillarCompliance: process.pillarCompliance,
                    title: process.title,
                    processSteps: genericSort(
                        action.payload.key,
                        [...process.processSteps],
                        action.payload.sortAscending
                    )
                };
            });
            return newState;
        case CermAssessmentActions.SET_OUTCOME:
            return state.map((item) => {
                return {
                    ...item,
                    processSteps: item.processSteps.map((innerItem) => {
                        return {
                            ...innerItem,
                            expectedOutcomeAnswers: innerItem.expectedOutcomeAnswers.map(
                                (outcome) => {
                                    if (
                                        outcome.masterExpectedOutcomeId ===
                                        action.payload.outcome.masterExpectedOutcomeId
                                    ) {
                                        return {
                                            ...outcome,
                                            answer: action.payload.outcome.answer
                                        };
                                    }
                                    return outcome;
                                }
                            )
                        };
                    })
                };
            });

        case CermActions.SET_PROCESS_STEP:
            return state.map((item) => {
                return {
                    ...item,
                    processSteps: item.processSteps.map((innerItem) => {
                        if (innerItem.id === action.payload.processStepId) {
                            return {
                                ...innerItem,
                                applicable: action.payload.answer
                            };
                        }
                        return innerItem;
                    })
                };
            });
        default:
            return state;
    }
};

const expandedFilters = (
    state: ICermAssessmentsState['expandedFilters'] = INITIAL_STATE.expandedFilters,
    action: CermAssessmentActionTypes
) => {
    switch (action.type) {
        case CermAssessmentActions.TOGGLE_FILTER_EXPANDED:
            const id = action.payload.id;

            if (state.includes(id)) {
                const newState = state.filter((item) => item !== id);
                return newState;
            } else {
                if (id) {
                    const newState = state.concat(id);
                    return newState;
                }
            }
            return state;
        case CermAssessmentActions.COLLAPSE_ALL:
            return [];
        default:
            return state;
    }
};

const expandedPillars = (
    state: ICermAssessmentsState['expandedPillars'] = INITIAL_STATE.expandedPillars,
    action: IAppAction
) => {
    switch (action.type) {
        case CermAssessmentActions.TOGGLE_PILLAR:
            const id = action.payload.id;

            if (state.includes(id)) {
                const newState = state.filter((item) => item !== id);
                return newState;
            } else {
                if (id) {
                    const newState = state.concat(id);
                    return newState;
                }
            }
            return state;
        case CermAssessmentActions.EXPAND_ALL:
            return action.payload.pillars;
        case CermAssessmentActions.COLLAPSE_ALL:
            return [];
        default:
            return state;
    }
};

const expandedSteps = (
    state: ICermAssessmentsState['expandedSteps'] = INITIAL_STATE.expandedSteps,
    action: IAppAction
) => {
    switch (action.type) {
        case CermAssessmentActions.TOGGLE_STEP:
            const id = action.payload.id;

            if (state.includes(id)) {
                const newState = state.filter((item) => item !== id);
                return newState;
            } else {
                if (id) {
                    const newState = state.concat(id);
                    return newState;
                }
            }

            return state;
        case CermAssessmentActions.COLLAPSE_ALL:
            return [];
        case CermAssessmentActions.EXPAND_ALL:
            return action.payload.steps;
        default:
            return state;
    }
};

const riskList = (
    state: ICermAssessmentsState['riskList'] = INITIAL_STATE.riskList,
    action: IAppAction
) => {
    switch (action.type) {
        case CermActions.LOAD_CERM_ASSESSMENT_FULFILLED:
            return action.payload.riskList !== undefined ? action.payload.riskList : state;
        default:
            return state;
    }
};

const siteScore = (
    state: ICermAssessmentsState['siteScore'] = INITIAL_STATE.siteScore,
    action: IAppAction
) => {
    switch (action.type) {
        case CermActions.LOAD_CERM_ASSESSMENT_FULFILLED:
            return action.payload.siteScore !== undefined ? action.payload.siteScore : state;
        case CermActions.SET_OUTCOME_FULFILLED:
        case CermActions.SET_PROCESS_STEP_FULFILLED:
            return action.payload.siteScore;
        default:
            return state;
    }
};

const isLoading = (
    state: ICermAssessmentsState['isLoading'] = INITIAL_STATE.isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case CermAssessmentActions.ADD_ATTACHMENT:
        case CermAssessmentActions.DELETE_ATTACHMENT:
        case CermActions.LOAD_CERM_ASSESSMENT:
            return true;
        case CermActions.LOAD_CERM_ASSESSMENT_FULFILLED:
        case CermActions.LOAD_CERM_ASSESSMENT_REJECTED:
        case CermAssessmentActions.DELETE_ATTACHMENT_COMPLETE:
        case CermAssessmentActions.ADD_ATTACHMENT_FULFILLED:
        case CermAssessmentActions.ADD_ATTACHMENT_REJECTED:
        case CermAssessmentActions.ADD_ATTACHMENT_CANCELLED:
            return false;
        default:
            return state;
    }
};

const outcomeSavedAndCompleted = (
    state: ICermAssessmentsState['outcomeSavedAndCompleted'] = INITIAL_STATE.outcomeSavedAndCompleted,
    action: IAppAction
) => {
    switch (action.type) {
        case CermActions.SET_OUTCOME:
            return action.payload.saveAndEnd === undefined ? false : action.payload.saveAndEnd;
        default:
            return state;
    }
};

export const cermAssessmentsReducer = combineReducers<ICermAssessmentsState>({
    attachments,
    isLoading,
    reviewProcessStep,
    expandedFilters,
    expandedPillars,
    expandedSteps,
    pillars,
    siteScore,
    isVisible,
    riskList,
    outcomeSavedAndCompleted
});
