export enum ModuleType {
    Risk = 'risks',
    ClientRisk = 'clientRisk',
    Incident = 'incident',
    Action = 'action',
    ShiftHandover = 'shiftHandover',
    MockDrill = 'mockDrill',
    CriticalSpare = 'criticalSpare',
    Assesment = 'assesment',
    siteFacilities = 'siteFacilities',
    Faqs = 'faqs',
    Facility = 'facility',
    RiskQuestionsOverview = 'riskQuestionsOverview',
}
