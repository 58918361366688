import { Action } from 'redux';
import { IKeyedItem } from 'models/keyed-item';
import { ISiteProfile } from 'models/site-profile';

export enum UpsertSiteActions {
    CANCEL_DELETE_SITE = '@@upsertSite/CANCEL_DELETE_SITE',
    DELETE_SITE = '@@upsertSite/DELETE_SITE',
    DELETE_SITE_FULFILLED = '@@upsertSite/DELETE_SITE_FULFILLED',
    DELETE_SITE_REJECTED = '@@upsertSite/DELETE_SITE_REJECTED',
    DELETE_SITE_CANCELLED = '@@upsertSite/DELETE_SITE_CANCELLED',

    DELETE_BUILDING = '@@upsertSite/DELETE_BUILDING',
    CANCEL_DELETE_BUILDING = '@@upsertSite/CANCEL_DELETE_BUILDING',
    DELETE_BUILDING_CONFIRMED = '@@upsertSite/DELETE_BUILDING_CONFIRMED',
    DELETE_BUILDING_CONFIRMED_FULFILLED = '@@upsertSite/DELETE_BUILDING_CONFIRMED_FULFILLED',
    DELETE_BUILDING_CONFIRMED_REJECTED = '@@upsertSite/DELETE_BUILDING_CONFIRMED_REJECTED',
    DELETE_BUILDING_CONFIRMED_CANCELLED = '@@upsertSite/DELETE_BUILDING_CONFIRMED_CANCELLED',

    SET_SELECTED_BUILDING = '@@upsertSite/SET_SELECTED_QUESTION',
}

export interface IDeleteSiteAction extends Action {
    type: UpsertSiteActions.DELETE_SITE;
    payload: {
        item: IKeyedItem;
    };
}

export const deleteSite = (item: IKeyedItem): IDeleteSiteAction => ({
    type: UpsertSiteActions.DELETE_SITE,
    payload: {
        item
    }
});

export interface ICancelDeleteSiteAction extends Action {
    type: UpsertSiteActions.CANCEL_DELETE_SITE;
}

export const cancelDeleteSiteAction = (): ICancelDeleteSiteAction => ({
    type: UpsertSiteActions.CANCEL_DELETE_SITE
});

export interface IDeleteSiteConfirmedAction extends Action {
    type: UpsertSiteActions.DELETE_SITE;
    payload: {
        item: ISiteProfile;
    };
}

export const deleteSiteConfirmed = (item: ISiteProfile): IDeleteSiteConfirmedAction => ({
    type: UpsertSiteActions.DELETE_SITE,
    payload: {
        item
    }
});

export interface IDeleteSiteConfirmedFulfilledAction extends Action {
    type: UpsertSiteActions.DELETE_SITE_FULFILLED;
    payload: {
        item: IKeyedItem;
    };
}

export const deleteSiteConfirmedFulfilled =
(item: IKeyedItem): IDeleteSiteConfirmedFulfilledAction => ({
    type: UpsertSiteActions.DELETE_SITE_FULFILLED,
    payload: {
        item
    }
});

export interface IDeleteSiteConfirmedCancelledAction extends Action {
    type: UpsertSiteActions.DELETE_SITE_CANCELLED;
    payload: {
        item: IKeyedItem;
    };
}

export const deleteSiteConfirmedCancelledStep =
(item: IKeyedItem): IDeleteSiteConfirmedCancelledAction => ({
    type: UpsertSiteActions.DELETE_SITE_CANCELLED,
    payload: {
        item
    }
});

export interface IDeleteSiteConfirmedRejectedAction extends Action {
    type: UpsertSiteActions.DELETE_SITE_REJECTED;
    payload: {
        item: IKeyedItem;
        errorMessage: string;
    };
    error: true;
}

export const deleteSiteConfirmedRejectedStep = (
    item: IKeyedItem,
    errorMessage: string
): IDeleteSiteConfirmedRejectedAction => ({
    type: UpsertSiteActions.DELETE_SITE_REJECTED,
    payload: {
        item,
        errorMessage
    },
    error: true
});

export interface ISetSelectedBuildingAction extends Action {
    type: UpsertSiteActions.SET_SELECTED_BUILDING;
    payload: {
        selectedBuildingIndex: number | null;
    };
}

export interface IDeleteBuildingAction extends Action {
    type: UpsertSiteActions.DELETE_BUILDING;
    payload: {
        item: IKeyedItem
        backLinkUrl: string;
    };
}

export const deleteBuilding = (item: IKeyedItem, backLinkUrl: string): IDeleteBuildingAction => ({
    type: UpsertSiteActions.DELETE_BUILDING,
    payload: {
        item,
        backLinkUrl
    }
});

export interface ICancelDeleteBuildingAction extends Action {
    type: UpsertSiteActions.CANCEL_DELETE_BUILDING;
}

export const cancelDeleteBuildingAction = (): ICancelDeleteBuildingAction => ({
    type: UpsertSiteActions.CANCEL_DELETE_BUILDING
});

export interface IDeleteBuildingConfirmedAction extends Action {
    type: UpsertSiteActions.DELETE_BUILDING_CONFIRMED;
    payload: {
        item: IKeyedItem;
        backLinkUrl: string;
    };
}

export const deleteBuildingConfirmed = (item: IKeyedItem, backLinkUrl: string): IDeleteBuildingConfirmedAction => ({
    type: UpsertSiteActions.DELETE_BUILDING_CONFIRMED,
    payload: {
        item,
        backLinkUrl
    }
});

export interface IDeleteBuildingConfirmedFulfilledAction extends Action {
    type: UpsertSiteActions.DELETE_BUILDING_CONFIRMED_FULFILLED;
    payload: {
        item: IKeyedItem;
    };
}

export const deleteBuildingConfirmedFulfilled = (item: IKeyedItem): IDeleteBuildingConfirmedFulfilledAction => ({
    type: UpsertSiteActions.DELETE_BUILDING_CONFIRMED_FULFILLED,
    payload: {
        item
    }
});

export interface IDeleteBuildingConfirmedCancelledAction extends Action {
    type: UpsertSiteActions.DELETE_BUILDING_CONFIRMED_CANCELLED;
    payload: {
        item: IKeyedItem;
    };
}

export const deleteProcessConfirmedCancelled = (item: IKeyedItem): IDeleteBuildingConfirmedCancelledAction => ({
    type: UpsertSiteActions.DELETE_BUILDING_CONFIRMED_CANCELLED,
    payload: {
        item
    }
});

export interface IDeleteBuildingConfirmedRejectedAction extends Action {
    type: UpsertSiteActions.DELETE_BUILDING_CONFIRMED_REJECTED;
    payload: {
        item: IKeyedItem;
        error: any;
    };
    error: true;
}

export const deleteBuildingConfirmedRejected = (
    item: IKeyedItem,
    error: any
): IDeleteBuildingConfirmedRejectedAction => ({
    type: UpsertSiteActions.DELETE_BUILDING_CONFIRMED_REJECTED,
    payload: {
        item,
        error
    },
    error: true
});

export type UpsertSiteActionTypes =
ISetSelectedBuildingAction

| IDeleteBuildingAction
| ICancelDeleteBuildingAction
| IDeleteBuildingConfirmedAction
| IDeleteBuildingConfirmedCancelledAction
| IDeleteBuildingConfirmedFulfilledAction
| IDeleteBuildingConfirmedRejectedAction

| IDeleteSiteAction
| ICancelDeleteSiteAction
| IDeleteSiteConfirmedAction
| IDeleteSiteConfirmedCancelledAction
| IDeleteSiteConfirmedFulfilledAction
| IDeleteSiteConfirmedRejectedAction;
