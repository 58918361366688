import * as React from 'react';
import { FieldArrayRenderProps, FormikProps } from 'formik';
import { RouteComponentProps } from 'react-router';
import { Control, Field, Help, Radio } from '../../../../components/form';
import { BulmaColor } from '../../../../enums/BulmaColor';
import { BulmaSize } from '../../../../enums/BulmaSize';
import { IQuestion, IQuestionSet } from '../../../../models/question-set';
import { canSubmit, formikFieldCssByState, getField, getKeyed } from '../../../../utils/form-utils';
import { RouteUrlManageQuestionSets } from '../../routes';
import { ActionForm } from '../action-form';
import { formikBagValidation, yesNoOptions } from '../common';
import { ICreateQuestionSetPageRouteParams } from '../create-question-set-page';
import { RiskForm } from '../risk-form';
import { ToggleRiskForm } from '../toggle-risk-form';
import { Button, ButtonWithConfirmDialog } from 'components/v2/components';
import { onRouteChange } from 'actions/app-actions';
import { easeInScrollToTop } from 'utils/animation-utils';
import useTranslate from 'translations/translation-utils';

export interface IProps extends RouteComponentProps<ICreateQuestionSetPageRouteParams> {
    formikBag: FormikProps<Partial<IQuestionSet>>;
    arrayHelpers: FieldArrayRenderProps;
    isSubmitting: boolean;
    operatingPlatform: string;
    showErrorDialogue(): void;
}

export const QuestionForm: React.FC<IProps> = ({
    match,
    formikBag,
    arrayHelpers,
    isSubmitting,
    operatingPlatform,
    showErrorDialogue,
}) => {
    const translate = useTranslate();
    const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(null);

    React.useEffect(() => {
        easeInScrollToTop();
    }, []);

    React.useEffect(() => {
        if (currentQuestionIndex !== match.params.questionIndex) {
            easeInScrollToTop();
            setCurrentQuestionIndex(match.params.questionIndex);
        }

        if (formikBag.values.questions.length > questionIndex &&
            Object.keys(formikBag.values.questions[questionIndex]).length === 0) {
            formikBag.setFieldValue(`${prefix}.title`, '');
            formikBag.setFieldValue(`${prefix}.instructions`, '');
            formikBag.setFieldValue(`${prefix}.hasRisk`, 'false');
        }
    }, [match]);

    const questionIndex = Number(match.params.questionIndex) || 0;

    const backLinkUrl =
        questionIndex === 0
            ? RouteUrlManageQuestionSets(formikBag.values.id)
            : `${RouteUrlManageQuestionSets(formikBag.values.id)}${questionIndex - 1}`;
    const prefix = `questions[${questionIndex}]`;
    const value = <T extends Extract<keyof IQuestion, string>>(name: T) => {
        return getKeyed(formikBag, 'values', `${prefix}.${name.trim()}`);
    };

    const getErrors = (name: Extract<keyof IQuestion, string>) =>
        getKeyed(formikBag, 'errors', `${prefix}.${name}`);
    const isTouched = (name: Extract<keyof IQuestion, string>) =>
        getKeyed(formikBag, 'touched', `${prefix}.${name}`);
    const getFieldCss = (name: Extract<keyof IQuestion, string>, otherCss?: string) =>
        formikFieldCssByState(Boolean(getErrors(name)), isTouched(name), otherCss);

    const getHelpByKey = (name: Extract<keyof IQuestion, string>) => (
        <Help isShown={formikBag.submitCount > 0 || isTouched(name)} bulmaColor={BulmaColor.Danger}>
            {getErrors(name)}
        </Help>
    );

    const formHasErrors = !formikBagValidation(formikBag.errors);

    const currentQuestionIsValid = () =>
        formikBag.errors.questions?.length >= questionIndex
            ? !formikBag.errors.questions[questionIndex]
            : true;

    const getUrlWithOperatingPlatform = (url: string) => {
        if (url.lastIndexOf('/') === url.length - 1) {
            return url + match.params.operatingPlatform;
        }
        return url + '/' + match.params.operatingPlatform;
    };

    const onGoBack = (backUrl: string) => {
        if (formHasErrors) {
            showErrorDialogue();
        }
        if (currentQuestionIsValid()) {
            onRouteChange(getUrlWithOperatingPlatform(backUrl));
        }
    };

    const onGoToNext = (e) => {
        e.preventDefault();

        if (formHasErrors) {
            showErrorDialogue();
        }

        if (currentQuestionIsValid()) {
            const indexNumber = (Number(match.params.questionIndex) || 0) + 1;
            onRouteChange(
                getUrlWithOperatingPlatform(`${RouteUrlManageQuestionSets(formikBag.values.id)}${indexNumber}`)
            );
        }
    };

    const onAddQuestionHandler = (e) => {
        e.preventDefault();

        if (!formHasErrors) {
            const currentLength = formikBag.values.questions.length;
            arrayHelpers.push({});

            const url = `${RouteUrlManageQuestionSets(formikBag.values.id)}${currentLength}`;
            onRouteChange(getUrlWithOperatingPlatform(url));
        } else {
            showErrorDialogue();
            formikBag.submitForm();
        }
    };

    const onDeleteQuestionHandler = (index: number) => {
        arrayHelpers.remove(index);
        const id = formikBag.values.id;
        const url =
            index > 0
                ? `${RouteUrlManageQuestionSets(id)}${index - 1}`
                : `${RouteUrlManageQuestionSets(id)}`;
        onRouteChange(getUrlWithOperatingPlatform(url));
    };

    const onSaveAsDraft = () => {
        if (!formHasErrors) {
            formikBag.setFieldValue('status', 'Draft');
        } else {
            showErrorDialogue();
        }
        formikBag.submitForm();
    };

    const onPublish = () => {
        if (!formHasErrors) {
            formikBag.setFieldValue('status', 'Published');
        } else {
            showErrorDialogue();
        }
        formikBag.submitForm();
    };

    return (
        <>
            <Field
                isHorizontal={true}
                htmlFor={getField<IQuestion>('title', prefix)}
                label={`Question #${questionIndex + 1}*`}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <textarea
                            id={getField<IQuestion>('title', prefix)}
                            name={getField<IQuestion>('title', prefix)}
                            aria-required="true"
                            className={getFieldCss('title', 'textarea')}
                            placeholder={translate('QuestionForm.Lable.2')}
                            value={value('title')}
                            onBlur={formikBag.handleBlur}
                            onChange={formikBag.handleChange}
                        />
                    </Control>
                    {getHelpByKey('title')}
                </Field>
            </Field>

            <Field
                isHorizontal={true}
                htmlFor={getField<IQuestion>('compliantResponse', prefix)}
                label={translate('QuestionForm.Lable.3')}
                labelSize={BulmaSize.Medium}
                className="is-vcentered"
            >
                <Field>
                    <Control>
                        {translate('QuestionForm.Lable.9')}
                        <Radio
                            id={getField<IQuestion>('compliantResponse', prefix)}
                            name={getField<IQuestion>('compliantResponse', prefix)}
                            options={yesNoOptions}
                            className={getFieldCss('compliantResponse')}
                            value={`${value('compliantResponse')}`}
                            onBlur={formikBag.handleBlur}
                            onChange={formikBag.handleChange}
                        />
                    </Control>
                    {getHelpByKey('compliantResponse')}
                </Field>
            </Field>

            <Field
                isHorizontal={true}
                htmlFor={getField<IQuestion>('instructions', prefix)}
                label={translate('QuestionForm.Lable.4') + '*'}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <textarea
                            id={getField<IQuestion>('instructions', prefix)}
                            name={getField<IQuestion>('instructions', prefix)}
                            aria-required="true"
                            className={getFieldCss('instructions', 'textarea')}
                            placeholder={translate('QuestionForm.Lable.5')}
                            value={value('instructions')}
                            onBlur={formikBag.handleBlur}
                            onChange={formikBag.handleChange}
                        />
                    </Control>
                    {getHelpByKey('instructions')}
                </Field>
            </Field>

            <ActionForm formikBag={formikBag} />
            <ToggleRiskForm formikBag={formikBag} />
            {value('hasRisk') && value('hasRisk').toString() === 'true' && (
                <RiskForm formikBag={formikBag} operatingPlatform={operatingPlatform} />
            )}

            <div className="QuestionForm__action-groups">
                <div className="QuestionForm__action-group">
                    <Button onClick={() => onGoBack(backLinkUrl)}>{translate('Globals.Label.Back')}</Button>
                    <ButtonWithConfirmDialog
                        buttonId="btnDeleteQuestion"
                        buttonType="archive"
                        buttonText={translate('Attachments.AttachmentList.ButtonWithConfirmDialog.ButtonText')}
                        title={translate('QuestionForm.Lable.6')}
                        message={translate('QuestionForm.Lable.7')}
                        onConfirm={() => onDeleteQuestionHandler(questionIndex)}
                    />
                </div>

                <div className="QuestionForm__action-group">
                    <Button
                        disabled={!canSubmit}
                        onClick={onSaveAsDraft}
                        isLoading={isSubmitting}
                    >
                        {translate('QuestionForm.Lable.8')}
                    </Button>

                    <Button
                        disabled={!canSubmit}
                        onClick={onPublish}
                        isLoading={isSubmitting}
                    >
                        {translate('Button.Captions.Publish')}
                    </Button>

                    {questionIndex < formikBag.values.questions.length - 1 && (
                        <Button onClick={onGoToNext}>{translate('SchedulerWeek.Button.NextDay.buttonText')}</Button>
                    )}

                    {questionIndex === formikBag.values.questions.length - 1 && (
                        <Button onClick={onAddQuestionHandler}>{translate('QuestionForm.Lable.10')}</Button>
                    )}
                </div>
            </div>
        </>
    );
};