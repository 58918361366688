import { loadPersonsLookup } from 'actions/actions-v2/person-action-v2';
import { IncidentActions } from 'actions/incident-actions';

export default {
    loadPersonsLookup,
    passLiveValuesOfIncidentToParent: (incident) => ({
        type: IncidentActions.PASS_LIVE_VALUES_OF_INCIDENT_TO_PARENT,
        payload: incident
    })
};
