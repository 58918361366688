import {
    loadRisk,
    clearForm,
    clientApprovalRisk,
    clientRequestAmendment,
} from 'actions/actions-v2/risk-action-v2';

import { onSiteChange } from 'actions/app-actions';
import { cleanupAttachment, uploadAttachmentRisk } from 'actions/actions-v2/attachment-action-v2';
import { loadAttachmentsRisks } from 'actions/actions-v2/attachment-action-v2';

export default {
    loadRisk,
    onSiteChange,
    clearForm,
    clientApprovalRisk,
    cleanupAttachment,
    uploadAttachmentRisk,
    clientRequestAmendment,
    loadAttachments: loadAttachmentsRisks,
};
