import React from 'react';
import { IToolbarIconItem, ToolbarIconType, IconToolbar } from 'components/v2/toolbar/IconToolbar';
import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { IKeyValue } from 'models/key-value';
import { checkPermissions } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import useTranslate from 'translations/translation-utils';

interface IProps {
    clearForm?: () => void;
    addLocalSkillCategory?: () => void;
    permissions?: Array<IKeyValue<string>>;
}

const CompetencyDnaView: React.FC<IProps> = ({ permissions, addLocalSkillCategory }) => {
    const translate = useTranslate();
    const getToolbarItems = (): IToolbarIconItem[] => {        
        return [
            checkPermissions([Privilege.CompetencyDNAProcessCreate], permissions) && {
                id: 'add-skill-category',
                title: translate('AddLocalSkillCat.Title'),
                type: ToolbarIconType.add,
                onClick: () => {
                    addLocalSkillCategory();
                },
            },
        ];
    };

    return <IconToolbar items={getToolbarItems()} />;
};

const mapStateToProps = (state: IRootState) => {
    return {
        multipleSites: state.app.lookupSites.length > 1,
        permissions: state.app.permissions,
        siteId: state.app.siteId,
    };
};

export const CompetencyDnaToolbar = connect(mapStateToProps)(CompetencyDnaView);
