import { connect } from 'react-redux';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { loadHistory } from 'actions/actions-v2/shift-handover-actions';
import { ShiftHandoverHistoryTab } from '././shift-handover-history-tab';

const getIsLoading = (state: IRootState) => state.shiftHandoverState.isLoading;
const getHistory = (state: IRootState) => state.shiftHandoverState.history;
const getShiftHandover = (state: IRootState) => state.shiftHandoverState.shiftHandover;

const mapStateToProps = createSelector(
    getIsLoading,
    getShiftHandover,
    getHistory,
    (isLoading, shiftHandover, shiftHandoverHistory) => ({
        isLoading,
        shiftHandover,
        shiftHandoverHistory
    })
);

const mapDispatchToProps = {
    loadHistory
};

export const ShiftHandoverHistoryTabContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ShiftHandoverHistoryTab);
