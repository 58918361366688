import { createSelector } from 'reselect';
import { ICermOverviewRootState } from 'reducers/cerm-overview';
import { getUpsertCermProcessStepState } from '../common';
import { IRootState } from 'routes/store';

const getCermOverviewRootState = (state: ICermOverviewRootState) => {
    return state;
};
const getPermissions = (state: IRootState) => state.app.permissions;

export default createSelector(
    getCermOverviewRootState,
    getUpsertCermProcessStepState,
    getPermissions,
    (state: ICermOverviewRootState, CermProcessStepState,permissions) => {
        return ({
            lookupServices: state.app.lookupServiceFilters,
            lookupPhases: state.app.lookupPhases,
            lookupPillars: state.app.lookupPillars,
            lookupPriorities: state.app.lookupPriorities,
            isSubmitting: CermProcessStepState.isSubmitting,
            permissions:permissions
        });
    }
);
