import { combineReducers } from 'redux';
import { IManageRolesGridState, reducer as grid } from './manageRoles-grid';

import { IBaseState } from '../base-state';
import { reducer as manageRoleFiltersReducer } from './manage-roles-filters';
import { IRoleFormState, reducer as roleForm } from './manageRoles-form';
import { IPersonFormState, reducer as personForm } from './person-upsert-reducer';

export interface IRootManageRoleState extends IBaseState {
    manageRoles: IManageRoleState;
}

export interface IManageRoleFiltersState {
    filterKeywords: string[];
}

export interface IManageRoleState {
    filters: IManageRoleFiltersState;
    grid: IManageRolesGridState;
    roleForm: IRoleFormState;
    personForm: IPersonFormState;
}

export const manageRolesReducer = combineReducers<IManageRoleState>({
    grid,
    filters: manageRoleFiltersReducer,
    roleForm,
    personForm,
});
