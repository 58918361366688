//Icons Library for Outline Icons
//https://fonts.google.com/icons
export enum MaterialIconType {
    Visibility = 'visibility',
    VisibilityOff = 'visibility_off',
    Add = 'add_circle_outline',
    Remove = 'remove_circle_outline',
    ArrowUp = 'arrow_upward',
    ArrowRight = 'arrow_forward',
    ArrowDown = 'arrow_downward',
    Edit = 'edit',
    Delete = 'delete',
    Avatar = 'account_circle',
    Home = 'home',
    Notifications = 'notifications',
    Help = 'help_outline',
    Warning = 'warning_amber',
    Person = 'person',
    Info = 'info',
    CheckCircle = 'check_circle',
    AngleDown = 'expand_more',
    AngleUp = 'expand_less',
    Circle = 'circle',
    Minus = 'remove',
    Plus = 'add',
    Check = 'check',
    Language = 'language',
    Calendar = 'calendar_month',
    Close = 'close',
    OpenInNew = 'open_in_new',
    ToggleOn = 'toggle_on',
    ToggleOff = 'toggle_off',
    ChevronRight = "chevron_right",
    ChevronLeft = "chevron_left",
    ContentCopy = "content_copy",
    CloudUpload = "cloud_upload"
}

export enum MaterialIconColor {
    green = 'green',
    grey = 'grey',
    red = 'red',
    white = 'white',
    lightGreen = 'light-green',
    orange = 'orange',
    black = 'black'
}

export enum MaterialIconSize {
    xs = '18',
    default = '24',
    medium = '36',
    large = '48',
}
