import * as React from 'react';
import Page from 'components/v2/page/Page';
import { RouteComponentProps } from 'react-router';
import { CheckboxFilterSet, ICheckbox } from 'components/filterSet/CheckboxFilterSet';
import { Calendar } from 'components/fullCalendar';
import { IKeyValue } from 'models/key-value';
import { onRouteChange } from 'actions/app-actions';
import { CalendarToolbar } from './calendar-toobar';
import { ICalendarItem } from 'models/calendar';
import { ConfirmDialog, Button } from 'components/v2/components';
import { calendarFormValidationSchema, CalendarEventForm } from './calendar-event-form';
import { Formik, FormikProps } from 'formik';
import { IPersonLookup } from 'models/person-lookup';
import { newGuid } from 'utils/id-utils';
import { Loader } from 'components/loader';
import { TextField } from 'components/form/fields/text-field';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { isEmpty } from 'lodash';
import { getTitlePrefix } from 'models/cerm/cerm-assessment-result';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconType } from 'routes/material-icon/material-icon-type';
import useTranslate from 'translations/translation-utils';

let formikBag: FormikProps<Partial<ICalendarItem>>;

export interface IProps extends RouteComponentProps<{}> {
    permissions: Array<IKeyValue<string>>;
    siteId: string;
    lookupSites: Array<IKeyValue<string>>;
    events: Partial<ICalendarItem[]>;
    lookupSitePeopleRoles: IPersonLookup[];
    calendarEvent: Partial<ICalendarItem>;
    isLoading: boolean;
    isSaving: boolean;
    siteLinkUrl: string;
    onSiteChange: (siteId: string) => void;
    onLoadCalendar: () => void;
    onCreateCalendarEvent: (model: ICalendarItem) => void;
    resetCalendarEvent: () => void;
    onSubscribeEvent: (siteId) => void;
    loadPersonsLookup: (siteId: string) => void;
    siteOperatinPlatform: string;
}
export const CalendarPage: React.FC<IProps> = (props) => {
    const translate = useTranslate();
    const getAllEventType = (operatingPlatform: string) => {
        return [
            'Risks',
            `${getTitlePrefix(operatingPlatform)} Events`,
            'Expert Reviews',
            'Incidents',
            'System Spares',
            'Mock Drills',
            'Site Events',
        ];
    }
    const [selectedValues, setSelectedValues] = React.useState(getAllEventType(props.siteOperatinPlatform));
    const [showAddEvent, setShowAddEvent] = React.useState(false);
    const [showSubscribePopup, setshowSubscribePopup] = React.useState(false);
    const [copied, setCopied] = React.useState(false);
    const [isValid, setIsValid] = React.useState(false);

    React.useEffect(() => {
        if (props.siteId) {
            props.onLoadCalendar();
        }
    }, [props.siteId]);

    React.useEffect(() => {
        if (isEmpty(props.lookupSitePeopleRoles)) {
            props.loadPersonsLookup(props.siteId);
        }
    }, [props.lookupSitePeopleRoles]);

    React.useEffect(() => {
        if (!props.isSaving) {
            setShowAddEvent(false);
        }
    }, [props.isSaving]);

    React.useEffect(() => {
        if (props.siteOperatinPlatform) {
            setSelectedValues(getAllEventType(props.siteOperatinPlatform));
        }
    }, [props.siteOperatinPlatform]);

    const propsFilteredEvents = () => {
        return props.events.filter((f) => selectedValues.indexOf(f.calendarItemType) > -1);
    };

    const distinctCalendarItemTypes = () => {
        return props.events
            .filter((event, i, arr) => {
                return (
                    arr.indexOf(arr.find((t) => t.calendarItemType === event.calendarItemType)) ===
                    i
                );
            })
            .map((calendarItem) => ({
                key: calendarItem.calendarItemType,
                value: calendarItem.calendarItemType,
            }));
    };

    const onFilterCalendarHandler = (_name: string, type: string) => {
        const values = selectedValues;
        if (values.includes(type)) {
            const index = values.indexOf(type);
            const newArray = values.slice(0, index).concat(values.slice(index + 1));
            setSelectedValues(newArray);
        } else {
            const newArray = [...values, type];
            setSelectedValues(newArray);
        }
    };

    const getCheckboxes = (values: string[], filters: Array<IKeyValue<string>>): ICheckbox[] => {
        const checkboxes: ICheckbox[] = filters.map((x: IKeyValue<string>) => {
            if (x) {
                return {
                    fieldId: x.key,
                    label: x.value,
                    checked: values.includes(x.key),
                };
            }
        });
        return checkboxes;
    };

    const onClickEvent = (item: ICalendarItem) => {
        switch (item.calendarItemType.toLocaleLowerCase()) {
            case 'risks':
                onRouteChange('/RiskRegister/' + item.uid + '/details');
                break;
            case 'incidents':
                onRouteChange('/incident/' + item.uid + '/details');
                break;
            case 'system spares':
                onRouteChange('/CriticalSystemSpare/' + item.uid + '/details');
                break;
            case 'mock drills':
                onRouteChange(item.uid);
                break;
            case 'site events':
                onRouteChange(`/calendar/${item.uid}/details`);
                break;
            case 'expert reviews':
                onRouteChange(`/${getTitlePrefix(props.siteOperatinPlatform)}Assessment`);
                break;
        }
    };
    const addCalendarEvent = () => {
        props.resetCalendarEvent();
        setShowAddEvent(true);
    };
    const onAddEvent = () => {
        formikBag.validateForm().then(() => {
            if (isValid) {
                formikBag.submitForm();
            }
        });
    };
    const onSubmit = (value: ICalendarItem) => {
        value.uid = newGuid();
        value.shard = props.siteId;
        props.onCreateCalendarEvent(value);
    };
    const getBody = () => {
        return (
            <div>
                <Loader loading={props.isLoading}>
                    <Formik<Partial<ICalendarItem>>
                        initialValues={props.calendarEvent}
                        onSubmit={onSubmit}
                        validationSchema={calendarFormValidationSchema}
                        isInitialValid={true}
                        render={(formik) => {
                            formikBag = formik;
                            setIsValid(formikBag.isValid);
                            return (
                                <CalendarEventForm
                                    formikBag={formikBag}
                                    lookupSitePeopleRoles={props.lookupSitePeopleRoles}
                                />
                            );
                        }}
                        enableReinitialize={true}
                        validateOnBlur={true}
                        validateOnChange={false}
                    />
                </Loader>
            </div>
        );
    };
    const getSubscribeBody = () => {
        return (
            <Loader loading={props.isLoading}>
                <div className="tabs-container tab-content">
                    <div className="columns">
                        <div className="column is-10">
                            <TextField
                                id="title"
                                label={translate('CalenderDetails.Message.5')}
                                value={props.siteLinkUrl}
                                readOnly={true}
                                error={copied ? translate('CalenderDetails.Message.6') : ''}
                            />
                        </div>
                        <div className="column is-2">
                            <CopyToClipboard
                                text={props.siteLinkUrl}
                                onCopy={() => setCopied(true)}
                            >
                                <Button className="button-copy">{translate('CalenderDetails.Message.7')}</Button>
                            </CopyToClipboard>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-full">
                            <h3>
                                <strong>Outlook on web</strong>
                            </h3>
                            <ol>
                                <li>Sign in to Outlook on the web</li>
                                <li>
                                    At the bottom of the page, select{' '}
                                    < MaterialIcons type={MaterialIconType.Calendar} />
                                    to go to Calendar.
                                </li>
                                <li>
                                    In the navigation pane, select <strong> Import calendar</strong>
                                    .
                                </li>
                                <li>
                                    Under <strong>Import calendar</strong>, select{' '}
                                    <strong>From web</strong>.
                                </li>
                                <li>
                                    Under <strong>Link to the calendar</strong>, type the URL for
                                    the linked calendar.
                                </li>
                                <li>
                                    Under <strong>Calendar name</strong>, type the name you want to
                                    use for the calendar.
                                </li>
                                <li>
                                    Select <strong>Import</strong>.
                                </li>
                            </ol>
                            <h3>
                                <strong>Classic Outlook :</strong>
                            </h3>
                            <ol>
                                <li>Sign in to Outlook on the web.</li>
                                <li>
                                    At the bottom of the navigation pane, click the Calendar icon.
                                </li>
                                <li>
                                    On the toolbar, click{' '}
                                    <strong>Add Calendar/Open Calendar</strong>.
                                </li>
                                <li>
                                    Click <strong> From Internet</strong>, and in the{' '}
                                    <strong>Link to the calendar box</strong>, type or copy and
                                    paste the web address of the calendar.
                                </li>
                                <li>
                                    In the <strong> Calendar name </strong> box, enter a name for
                                    the linked calendar.
                                </li>
                                <li>
                                    Click<strong>Save</strong>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </Loader>
        );
    };
    const onSubscribe = () => {
        props.onSubscribeEvent(props.siteId);
        setshowSubscribePopup(true);
        setCopied(false);
    };
    return (
        <Page title="Calendar" className="CalendarPage">
            <Loader loading={props.isLoading}>
                <Button className="button-subscribe" disabled={false} onClick={onSubscribe}>
                    {translate('CalenderDetails.Message.8')}
                </Button>
                <CalendarToolbar addCalendarEvent={addCalendarEvent} />
                <ConfirmDialog
                    title={translate('CalenderDetails.Message.9')}
                    message={getBody()}
                    isVisible={showAddEvent}
                    showConfirmButton={true}
                    onConfirm={onAddEvent}
                    onClose={() => setShowAddEvent(false)}
                    onOutsideDialogClick={() => setShowAddEvent(false)}
                    buttonConfirmText={translate('CalenderDetails.Message.10')}
                    buttonCancelText={translate('Globals.Label.Cancel')}
                />
                <ConfirmDialog
                    title={translate('CalenderDetails.Message.11')}
                    message={getSubscribeBody()}
                    isVisible={showSubscribePopup}
                    showConfirmButton={false}
                    onClose={() => setshowSubscribePopup(false)}
                    onOutsideDialogClick={() => setshowSubscribePopup(false)}
                    buttonCancelText={translate('Globals.Label.Cancel')}
                />
                <div className="columns is-desktop">
                    <div className="column is-narrow filters-container">
                        <fieldset className="filters is-expanded">
                            <div className="filter-container">
                                <legend>{translate('CalenderDetails.Message.12')}</legend>
                                <CheckboxFilterSet
                                    fieldName="Filter items"
                                    selected={true}
                                    onFilter={onFilterCalendarHandler}
                                    filters={getCheckboxes(
                                        selectedValues,
                                        distinctCalendarItemTypes()
                                    )}
                                >
                                    {translate('CalenderDetails.Message.13')}
                                </CheckboxFilterSet>
                            </div>
                        </fieldset>
                    </div>
                    <div className="column">
                        <Calendar onclick={onClickEvent} events={propsFilteredEvents()} />
                    </div>
                </div>
            </Loader>
        </Page>
    );
};
