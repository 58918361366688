import { IAuditableItem } from './auditable-item';
import { IEmbeddedLink } from './embeddedLink';
import { IKeyedItem } from './keyed-item';
import { IRisk } from './risk';
import { IRelatedEntity } from './related-entity';
import { IKeyValue } from './key-value';

export interface IAction extends IKeyedItem, IAuditableItem {
    seq: number;
    site: string;
    title: string;
    riskItemId: string;
    riskMitigationPlanId: string;
    description: string;
    dueDate: string;
    reminderDate: string;
    priorityId: string;
    priority: string;
    isActive: boolean;
    categoryId: string;
    category: string;
    statusId: string;
    status: string;
    owner?: IEmbeddedLink;
    assignee?: IEmbeddedLink;
    assigneeId: string;
    outcomeId?: string;
    parent: IRisk; // TODO: This wont always be a risk - refactor...
    relatedEntity: IRelatedEntity;
    origin: string;
    buildingId: string;
    customerId?: string;
    contractId?: string;
    sectorId?: string;
    assetId?: string;
    documentGroupID?: string;
    actionID?: string;
    actionLink?: string;
    masterProcessStepId?: string;
    riskProfileQuestionId?: string;
    parentRisk: IRisk;
    parents: IEmbeddedLink[];
    macroLevel?: string;
    macroLevelValues?: Array<IKeyValue<string>>;
}

export enum ActionPriority {
    Low = '1',
    Medium = '2',
    High = '3',
}
