import React from 'react';
import Page from 'components/v2/page/Page';
import * as Yup from 'yup';
import {
    IMockDrillReport,
    IMockDrillReportsSummary,
    IMockDrillReportInfo,
    MockDrillType,
    getScenarioTypeStringFromParam,
} from 'models/mock-drills';
import {
    Table,
    SortOrder,
    TableHeaders,
    Panel,
    Toolbar,
    ToolbarGroup,
    Button,
    Badge,
    ButtonWithConfirmDialog,
} from 'components/v2/components';
import { Loader } from 'components/loader';
import { onRouteChange } from 'actions/app-actions';
import { sortArrayBy } from 'utils/sort-utils';
import { UiPreferences } from 'utils/page-ui-preferences-utils';
import { isEmpty } from 'lodash';
import { IKeyValue } from 'models';
import { RouteComponentProps } from 'react-router-dom';
import { FormikProps, Formik, FormikActions } from 'formik';
import { TextField } from 'components/form/fields/text-field';
import { MockDrillReportsPageToolbar } from './shared/mock-drill-reports-page-toolbar';
import { formatDateString } from 'utils/date-utils';
import { GetDisplayString } from 'utils/decimal-utils';
import { encodeUri } from 'utils/string-utils';
import useTranslate from 'translations/translation-utils';

interface IRouteParams {
    mockDrillId: string;
    mockDrillType: MockDrillType;
    scenarioType: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
    siteId: string;
    isLoading: boolean;
    mockDrillReportsSummary: IMockDrillReportsSummary;
    hasReadWritePermission: boolean;
    userCanDeleteLocalMockDrillsScenario: boolean;
    userCanDeleteGlobalMockDrills: boolean;
    loadMockDrillReportsSummary: (
        mockDrillId: string,
        mockDrillType: string,
        siteId: string
    ) => void;
    cleanupMockDrillReportList: () => void;
    deleteMockDrill: (id: string, siteId: string, redirectUrl: string) => void;
    deleteGlobalMockDrill(id: string, reloadUrl: string);
}

export const buildReportPageUrl = (
    mockDrillId: string,
    mockDrillType: string,
    reportTitle: string,
    scenarioType: string,
    reportId: string = null
) => {
    const reportIdPath = reportId ? `/${reportId}` : '';
    return `/MockDrill/${scenarioType}/Reports/${mockDrillType}/${mockDrillId}/${encodeUri(
        reportTitle
    )}${reportIdPath}/Details`;
};

export const MockDrillReportsPage: React.FC<IProps> = ({
    match,
    siteId,
    isLoading,
    mockDrillReportsSummary,
    hasReadWritePermission,
    userCanDeleteLocalMockDrillsScenario,
    userCanDeleteGlobalMockDrills,
    loadMockDrillReportsSummary,
    cleanupMockDrillReportList,
    deleteMockDrill,
    deleteGlobalMockDrill,
}) => {
    const mockDrillId = match.params.mockDrillId;
    const mockDrillType = match.params.mockDrillType;
    const scenarioType = match.params.scenarioType;
    const scenarioTypeString = getScenarioTypeStringFromParam(scenarioType);
    const mockDrillsPageUrl = `/MockDrills/${scenarioType}`;
    const customLinks: Array<IKeyValue<string>> = [
        { key: mockDrillsPageUrl, value: scenarioTypeString },
    ];
    const [reports, setReports] = React.useState<IMockDrillReportInfo[]>([]);
    const [disabledStartButton, setDisabledStartButton] = React.useState(true);
    let formikBag: FormikProps<Partial<IMockDrillReport>>;
    const translate = useTranslate();

    React.useEffect(() => {
        cleanupMockDrillReportList();
    }, []);

    React.useEffect(() => {
        if (mockDrillId && siteId) {
            cleanupMockDrillReportList();
            loadMockDrillReportsSummary(mockDrillId, mockDrillType, siteId);
        }
    }, [siteId]);

    React.useEffect(() => {
        if (!mockDrillReportsSummary || isEmpty(mockDrillReportsSummary.reports)) {
            setReports([]);
            return;
        }

        setReports(mockDrillReportsSummary.reports);
    }, [mockDrillReportsSummary]);

    const validationSchema = Yup.object<Partial<IMockDrillReport>>().shape({
        title: Yup.string().trim().required(translate('Validations.Title')),
    });

    const renderForm = (formikProps: FormikProps<Partial<IMockDrillReport>>) => {
        formikBag = formikProps;
        setDisabledStartButton(isEmpty(formikBag.values.title));
        return formikProps.values ? (
            <form id="mockDrillReportForm" onSubmit={formikProps.handleSubmit}>
                <TextField
                    id="title"
                    label={translate('RiskRegisterPage.RiskRegisterTable.Title.Title') + '*'}
                    value={formikProps.values.title}
                    maxLength={180}
                    error={formikProps.errors.title}
                    handleChange={formikProps.handleChange}
                    setVal={formikBag.setFieldValue}
                />
            </form>
        ) : null;
    };

    const onSubmit = (data: IMockDrillReport) => {
        redirectToReportPage(data.title);
    };

    const redirectToReportPage = (reportTitle: string, reportId: string = null) => {
        const reportPageUrl = buildReportPageUrl(
            mockDrillId,
            mockDrillType,
            reportTitle,
            scenarioType,
            reportId
        );
        onRouteChange(reportPageUrl);
    };

    const onToggleHandler = (field: string, sortOrder: SortOrder) => {
        if (sortOrder === SortOrder.off) {
            setReports(mockDrillReportsSummary.reports);
        }

        setReports(
            sortArrayBy(field, mockDrillReportsSummary.reports, sortOrder === SortOrder.asc)
        );
    };

    const buildReportsTable = () => {
        if (isEmpty(reports)) {
            return (
                <div className="no-data">
                    <p>
                        <strong>
                            {translate('MockDrill.Repost.Lable.12')}
                        </strong>
                    </p>
                    <ul className="ul-default">
                        <li>{translate('MockDrill.Repost.Lable.13')}</li>
                        {scenarioType === 'MockDrills' && (
                            <li>
                                {translate('MockDrill.Repost.Lable.14')}
                            </li>
                        )}
                        <li>{translate('MockDrill.Repost.Lable.15')}</li>
                        <li>
                            {translate('MockDrill.Repost.Lable.16')}
                        </li>
                    </ul>
                </div>
            );
        }

        return (
            <Table>
                <TableHeaders
                    headers={[
                        {
                            title: translate('RiskRegisterPage.RiskFilter.Title.Status'),
                            sortField: 'status',
                            className: 'narrow',
                        },
                        {
                            title: translate('RiskRegisterPage.RiskRegisterTable.Title.Title'),
                            sortField: 'title',
                        },
                        {
                            title: translate('SchedulerWeek.Button.Calendar.buttonText'),
                            className: 'narrow no-wrap',
                        },
                        {
                            title: translate('MockDrill.Repost.Lable.17'),
                            className: 'narrow',
                        },
                        {
                            title: translate('MockDrill.Repost.Lable.18'),
                            className: 'narrow',
                        },
                    ]}
                    sortFieldUiPreference={UiPreferences.MockDrillsReportPageSortField}
                    sortOrderUiPreference={UiPreferences.MockDrillsReportPageSortOrder}
                    onToggle={onToggleHandler}
                />
                <tbody className="clickable">
                    {reports.map((report) => (
                        <tr
                            key={report.id}
                            onClick={() => redirectToReportPage(report.title, report.id)}
                        >
                            <td>
                                <Badge type={report.status} />
                            </td>
                            <td>{report.title}</td>
                            <td>{formatDateString(report.dateOfDrill, 'DD MMM YYYY')}</td>
                            <td>{GetDisplayString(report.totalActions)}</td>
                            <td>{GetDisplayString(report.totalParticipants)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    };

    const title =
        mockDrillReportsSummary && mockDrillReportsSummary.name
            ? mockDrillReportsSummary && mockDrillReportsSummary.name
            : scenarioTypeString;

    const drillDescription = mockDrillReportsSummary && (
        <p className="page-description">{mockDrillReportsSummary.description}</p>
    );

    const isLocalMockDrill = mockDrillType === MockDrillType.Local;
    const onArchive = () => {
        isLocalMockDrill
            ? deleteMockDrill(mockDrillId, siteId, mockDrillsPageUrl)
            : deleteGlobalMockDrill(mockDrillId, mockDrillsPageUrl);
    };

    const archiveButton = () => {
        const enable = isLocalMockDrill
            ? userCanDeleteLocalMockDrillsScenario
            : userCanDeleteGlobalMockDrills;

        return (
            <ButtonWithConfirmDialog
                onConfirm={onArchive}
                buttonType={'archive'}
                buttonText={translate('MockDrills.Scenario.Button.Archive')}
                message={translate('MockDrills.Scenario.Archive.Message')}
                title={translate('MockDrills.Scenario.Archive.Title')}
                isButtonDisabled={!enable}
                isLoading={isLoading}
            />
        );
    };

    return (
        <Page
            title={title}
            breadcrumbCustomLinks={customLinks}
            redirectOnSiteChange={true}
            redirectOnSiteChangeUrl={mockDrillsPageUrl}
            className="mock-drill-reports-page"
        >
            {drillDescription}
            <MockDrillReportsPageToolbar
                mockDrillId={mockDrillId}
                allowEdit={hasReadWritePermission && isLocalMockDrill}
                scenarioType={scenarioType}
            />
            {hasReadWritePermission && (
                <Panel title={translate('MockDrill.Repost.Lable.19')} bodyPadding={true}>
                    <Formik
                        initialValues={{}}
                        validationSchema={validationSchema}
                        onSubmit={(
                            data: IMockDrillReport,
                            formikActions: FormikActions<IMockDrillReport>
                        ) => {
                            formikActions.setSubmitting(true);
                            onSubmit(data);
                            formikActions.setSubmitting(false);
                        }}
                        render={renderForm}
                        enableReinitialize={true}
                        validateOnBlur={true}
                    />
                    <br />
                    <Button
                        id="startMockDrill"
                        type="submit"
                        form="mockDrillReportForm"
                        disabled={disabledStartButton}
                    >
                        {translate('MockDrill.Repost.Lable.20')}
                    </Button>
                </Panel>
            )}
            <Panel title={translate('MockDrill.Repost.Lable.21')} collapsed={false}>
                <Loader loading={isLoading}>{buildReportsTable()}</Loader>
            </Panel>
            <Toolbar type="save">
                <ToolbarGroup>
                    <Button buttonType="cancel" onClick={() => onRouteChange(mockDrillsPageUrl)}>
                        {translate('Globals.Label.Back')}
                    </Button>
                    {archiveButton()}
                </ToolbarGroup>
            </Toolbar>
        </Page>
    );
};
