import {
    loadComments,
    deleteComment,
    createComment,
    updateComment,
    setMessage,
    setEditMessage,
    setEditId
} from 'actions/actions-v2/comment-action-v2';

export default {
    loadComments,
    deleteComment,
    createComment,
    updateComment,
    setMessage,
    setEditMessage,
    setEditId
};
