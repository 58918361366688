import moment from 'moment';
import * as React from 'react';

interface IIncidentArrowProps {
    startDate?: string;
    endDate?: string;
    text: string;
    active: boolean;
}

export const IncidentArrow: React.FC<IIncidentArrowProps> = ({
    endDate,
    startDate,
    text,
    active,
}) => {
    const start = moment(startDate, 'DD-MM-YYYY HH:mm:ss');
    const end = moment(endDate, 'DD-MM-YYYY HH:mm:ss');

    const weeks = end.diff(start, 'weeks');
    const days = end.diff(start, 'days');
    const hours = end.diff(start, 'hours');
    const minutes = end.diff(start, 'minutes');

    const duration = (): string => {
        const day = days - (weeks * 7);
        const hour = hours - (days * 24);
        const minute = minutes - (hours * 60);

        return `${weeks > 0 ? weeks + 'w' : ''}
                ${day > 0 ? day + 'd' : ''}
                ${hour > 0 ? hour + 'h' : ''}
                ${minute > 0 ? minute + 'm' : ''}`;
    };

    return (
        <div className={`arrow-pointer ${active ? `arrow-pointer-green` : `arrow-pointer-grey`}`}>
            <div>
                {start.isValid() && end.isValid() && (
                    <div className="label">
                        {text} {duration()}
                    </div>
                )}
            </div>
        </div>
    );
};
