import React, { PureComponent } from 'react';
import IdleTimer from 'react-idle-timer';
import Cookies from 'universal-cookie';
import { ModalCard, ModalCardBody, ModalCardFooter, ModalCardHeader } from '../modalCard';
import { Button } from '../v2/components';
import { getTokenExpiry, logOut } from '../../utils/auth-helper';
import { getAuthenticationPlatform } from 'helpers/authentication';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';

interface IProps {
    sendRequestSessionAlive: () => void;
    intl: any;
}

class SessionTimerClass extends PureComponent<IProps> {
    public state = {
        showDialog: false,
        countdown: 60,
        isSessionAlive: true,
    };

    private timeout: number = 1000 * 60 * 18; // 18 minutes
    private tokenRenewalTime = 5 * 60;
    private idleTimer: IdleTimer;
    private cookies: Cookies;
    private countDownInterval: NodeJS.Timeout;

    public componentDidMount() {
        this.cookies = new Cookies();
    }

    public render() {
        const intl = this.props.intl;

        return (
            <>
                <IdleTimer
                    ref={(ref) => {
                        this.idleTimer = ref;
                    }}
                    onIdle={this.onIdle}
                    onAction={this.onAction}
                    debounce={250}
                    timeout={this.timeout}
                />
                <ModalCard modalId="confirmation" isShown={this.state.showDialog}>
                    <ModalCardHeader
                        modalId="confirmation"
                        title={translate(intl, 'SessionTimer.Message')}
                    />
                    <ModalCardBody modalId="confirmation">
                        <p>
                            <b>
                                {translate(intl, 'SessionTimer.Message3')} {this.state.countdown} {translate(intl, 'SessionTimer.Message4')} {translate(intl, 'SessionTimer.Message2')}{' '}
                            </b>
                        </p>
                        <p>
                            <progress className="progress" value={this.state.countdown} max="60" />
                        </p>
                    </ModalCardBody>
                    <ModalCardFooter>
                        <Button onClick={this.onClick}>{translate(intl, 'IncidentDetails.Ongoing.Yes')}</Button>
                    </ModalCardFooter>
                </ModalCard>
            </>
        );
    }

    private keepSessionAlive = () => {
        if (this.state.isSessionAlive) {
            this.setState({ isSessionAlive: false });
            setTimeout(() => {
                this.props.sendRequestSessionAlive();
                this.setState({ isSessionAlive: true });
            }, 30000);
        }
    };

    private startCountDown = () => {
        let timeLeft = 60;
        this.countDownInterval = setInterval(() => {
            this.setState({ countdown: timeLeft });
            timeLeft -= 1;
            if (timeLeft <= 0) {
                logOut();
            }
        }, 1000);
    };
    private onAction = () => {
        const timeRemaining = this.idleTimer ? this.idleTimer.getRemainingTime() : 0;
        if (timeRemaining !== 0 && !this.state.showDialog) {
            this.idleTimer.reset();
            this.keepSessionAlive();
            this.cookies.set(`QuantumLastActiveTime`, new Date().getTime());
        }
    };
    private updateRefreshTime = () => {
        const expiryTime = getTokenExpiry();
        const refreshTime = expiryTime - (new Date().getTime() / 1000);
        if (refreshTime <= this.tokenRenewalTime) {
            getAuthenticationPlatform().refreshToken(this.updateRefreshTime);
        }
    }
    private onClick = () => {
        this.updateRefreshTime();
        clearInterval(this.countDownInterval);
        this.idleTimer.reset();
        this.setState({ showDialog: false });
        this.cookies.set(`QuantumLastActiveTime`, new Date().getTime());
    };
    private onIdle = () => {
        const timeRemaining = this.idleTimer.getRemainingTime();
        const cookieLastActive = Number(this.cookies.get(`QuantumLastActiveTime`));
        const lastActive = new Date().getTime() - cookieLastActive;

        if (timeRemaining === 0 && lastActive > this.timeout - 5000) {
            this.setState({ showDialog: true });
            this.startCountDown();
        } else {
            this.idleTimer.reset();
        }
    };
}

export const SessionTimer = injectIntl(SessionTimerClass);