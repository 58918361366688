import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { getFilteredLookupSitePeopleWithAllowedRoles } from 'sharedSelectors';
import { getIsLoading } from '../../selectors';

const getLookupSites = createSelector(
    (state: IRootState) => state.app,
    (state: any) => state.lookupSites
);

const getLanguage = (state: IRootState) => state.person.personProfile.language;
export default createSelector(
    getLookupSites,
    getFilteredLookupSitePeopleWithAllowedRoles,
    getIsLoading,
    getLanguage,
    (lookupSites, personLookups, isLoading, language) => ({
        lookupSites,
        users: personLookups,
        isLoading,
        language,
    })
);
