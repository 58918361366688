import { INotificationState } from 'state';
import { NotificationActionTypes, notificationStateActions } from 'actions';
import { combineReducers } from 'redux';

const INITIAL_STATE: INotificationState = {
    sendNotification: null,
    isSaving: false,
};

const isSaving = (state: INotificationState['isSaving'] = INITIAL_STATE.isSaving, action: NotificationActionTypes) => {
    switch (action.type) {
        case notificationStateActions.SEND_NOTIFICATION:
            return true;
        case notificationStateActions.SEND_NOTIFICATION_COMPLETE:
        case notificationStateActions.SEND_NOTIFICATION_REJECTED:
            return false;
        default:
            return state;
    }
};

export const notificationsReducer  = combineReducers({
    isSaving
});
