import { Action } from 'redux';

export enum AppNotificationActions {
    SEND_SUCCESS_NOTIFICATION = '@@appNotifications/SEND_SUCCESS_NOTIFICATION',
    SEND_SUCCESS_NOTIFICATION_COMPLETE = '@@appNotifications/SEND_SUCCESS_NOTIFICATION_COMPLETE',

    SEND_ERROR_NOTIFICATION = '@@appNotifications/SEND_ERROR_NOTIFICATION',
    SEND_ERROR_NOTIFICATION_COMPLETE = '@@appNotifications/SEND_ERROR_NOTIFICATION_COMPLETE',
}

export interface ISendSuccessNotification extends Action {
    type: AppNotificationActions.SEND_SUCCESS_NOTIFICATION;
    payload: {
        title: string;
        message: string;
    };
}

export interface ISendSuccessNotificationComplete extends Action {
    type: AppNotificationActions.SEND_SUCCESS_NOTIFICATION_COMPLETE;
}

export interface ISendErrorNotification extends Action {
    type: AppNotificationActions.SEND_ERROR_NOTIFICATION;
    payload: {
        errorTitle: string;
        errorMessage: string;
    };
}

export interface ISendErrorNotificationComplete extends Action {
    type: AppNotificationActions.SEND_ERROR_NOTIFICATION_COMPLETE;
}

export type AppNotificationActionTypes =
| ISendSuccessNotification
| ISendSuccessNotificationComplete
| ISendErrorNotification
| ISendErrorNotificationComplete;
