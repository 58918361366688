import * as React from 'react';
import { IAttachmentQuery } from 'models/attachment/attachment-query';
import { IAttachmentUpload } from 'models/attachment/attachment-upload';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import Attachments from 'components/attachemnts/attachments';
import { Entity } from 'models/entity';
import { connect } from 'react-redux';
import selectors from './selector';
import actions from './actions';
interface IProps {
    id: string;
    loadAttachments: (query: IAttachmentQuery) => void;
    uploadAttachment: (upload: IAttachmentUpload) => void;
    attachments: IAttachmentModel[];
}

const IncidentAttachmentsTab: React.FC<IProps> = (props) => {
    return (
            <Attachments
                entity={Entity.Incident}
                attachments={props.attachments}
                loadAttachments={props.loadAttachments}
                uploadAttachment={props.uploadAttachment}
                id={props.id}
            />
    );
};

export default connect(selectors, actions)(IncidentAttachmentsTab);
