import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';

const getAttachments = (state: IRootState) => state.incidentsState.attachments;

export default createSelector(
    [getAttachments],
    (attachments) => ({
        attachments
    })
);
