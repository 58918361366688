import moment from 'moment';
import React from 'react';
import Select from 'react-select';
import {
    getActualsUtilisationValue, getActualsValueForQuarter,
    getBenchmarkValueForQuarter, getNumberMonthsOfDataInQuarter,
    getQuartersListFromActuals
} from 'business-area-functions/pue';
import { enumEnergyBillPeriods } from 'models/building';
import { IEnergyBill } from 'models/energy-bill';
import { IKeyValue } from 'models/key-value';
import { IDataLoadProjection } from 'models/pue-benchmarks';
import { enumBenchmarks, enumQuarters } from 'models/pue-benchmarks-enums';
import { BarChart } from './barchart';
import useTranslate from 'translations/translation-utils';

export interface IProps {
    pueProjections: IDataLoadProjection;
    points: Array<IKeyValue<number>>;
    energyBills: IEnergyBill[];
    capacity: number;
    initialQuarter: Date;
    energyBillPeriods: enumEnergyBillPeriods;
}

export const BarChartWithSelect: React.FC<IProps> = (props) => {
    const { pueProjections, energyBills, capacity, energyBillPeriods } = props;
    const [selectedQuarter, setSelectedQuarter] = React.useState<Date>(props.initialQuarter);
    const [quarters, setQuarters] = React.useState<Date[]>(energyBills.map(x => x.date));

    React.useEffect(() => {
        const newQuarters = getQuarters(energyBills);
        setQuarters(newQuarters);

        // switch selected quarter to the latest one if currently selected one 
        // is no longer existing (on different facility)
        if (newQuarters.filter((x) => moment(x).isSame(moment(selectedQuarter))).length === 0) {
            const sortedQuarters = newQuarters.sort((a, b) =>
                parseInt(moment(a).format('YYYYMMDD'), 10) -
                parseInt(moment(b).format('YYYYMMDD'), 10)
            );
            setSelectedQuarter(sortedQuarters[sortedQuarters.length - 1]);
        }
    }, [energyBills]);

    const onChangeSelectedQuarter = (event: any) => {
        setSelectedQuarter(event.value);
    }

    const getQuarters = (bills: IEnergyBill[]) => getQuartersListFromActuals(bills);


    const q = ('q' + moment(selectedQuarter).format('Q')) as keyof typeof enumQuarters;
    const quarter: enumQuarters = enumQuarters[q];
    const options = quarters.map((x) => ({
        value: x,
        label: 'Q' + moment(x).format('Q YYYY').toString(),
    }));
    const value = {
        value: selectedQuarter,
        label: 'Q' + moment(selectedQuarter).format('Q YYYY').toString(),
    };
    const numberMonths = getNumberMonthsOfDataInQuarter(
        energyBills,
        energyBillPeriods,
        selectedQuarter
    );
    const translate = useTranslate();
    return (
        pueProjections &&
        <React.Fragment>
            <div style={{ margin: '0 auto', textAlign: 'center' }}>
                {translate('UpsertSite.BuildingForm.BarChartWithSelect.BenchmarkFor')} {value.label}
            </div>
            <BarChart
                actual={getActualsValueForQuarter(energyBills, selectedQuarter)}
                target={getBenchmarkValueForQuarter(
                    getActualsUtilisationValue(energyBills, selectedQuarter, capacity),
                    pueProjections, enumBenchmarks.target, quarter)}
            />
            <div style={{ margin: '0 auto', textAlign: 'center' }}>
                {numberMonths <= 1 ? translate('UpsertSite.BuildingForm.BarChartWithSelect.Message1') :
                    numberMonths < 2 ? '' : translate('UpsertSite.BuildingForm.BarChartWithSelect.Message2')}</div>
            <span>{translate('UpsertSite.BuildingForm.BarChartWithSelect.SelectAQuarter')} </span><div style={{ width: '100px' }}>
                <Select
                    id="quarterId"
                    name="quarter"
                    aria-required="true"
                    options={options}
                    value={value}
                    onChange={onChangeSelectedQuarter}
                />
            </div>
            <br />
            <br />
        </React.Fragment>
    );
};
