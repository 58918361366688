import { Dispatch, useEffect, useState } from 'react';
import useLocalStorage from './local-storage-utils';

export const usePageUIPreferences = <T>(key: UiPreferences, defaultValue: T): [T, Dispatch<T>] => {
    const [value, setValue] = useLocalStorage(key, JSON.stringify(defaultValue));

    const getItem = () => {
        if (value && value !== 'undefined') {
            return JSON.parse(value);
        }

        return defaultValue;
    };

    const [state, setState] = useState(getItem());

    useEffect(() => {
        setValue(JSON.stringify(state));
    }, [key, state]);

    return [state, setState];
};

export enum UiPreferences {
    ActionsPageFilters = 'ActionsPageFilters',
    ActionsPageSortField = 'ActionsPageSortField',
    ActionsPageSortOrder = 'ActionsPageSortOrder',
    CermAssessmentPageCollapsedPillars = 'CermAssessmentPageCollapsedPillars',
    GlobalMockDrillPageSiteListSortField = 'GlobalMockDrillPageSiteListSortField',
    GlobalMockDrillPageSiteListSortOrder = 'GlobalMockDrillPageSiteListSortOrder',
    GlobalMockDrillsPageSortField = 'GlobalMockDrillsPageSortField',
    GlobalMockDrillsPageSortOrder = 'GlobalMockDrillsPageSortOrder',
    GlobalMockDrillsPageFilters = 'GlobalMockDrillsPageFilters',
    GlobalMockDrillsPageFiltersDC = 'GlobalMockDrillsPageFiltersDC',
    GlobalMockDrillsPageFiltersHC = 'GlobalMockDrillsPageFiltersHC',
    MockDrillsPageSortField = 'MockDrillsPageSortField',
    MockDrillsPageSortOrder = 'MockDrillsPageSortOrder',
    MockDrillsPageFilters = 'MockDrillsPageFilters',
    MockDrillsReportPageSortField = 'MockDrillsReportPageSortField',
    MockDrillsReportPageSortOrder = 'MockDrillsReportPageSortOrder',
    MockDrillReportPageActionListSortField = 'MockDrillReportPageActionListSortField',
    MockDrillReportPageActionListSortOrder = 'MockDrillReportPageActionListSortOrder',
    RiskQuestionSetsPageFiltersDC = 'RiskQuestionSetsPageFiltersDC',
    RiskQuestionSetsPageFiltersHC = 'RiskQuestionSetsPageFiltersHC',
    RisksClientPageFilters = 'RisksClientPageFilters',
    RisksClientPageSortField = 'RisksClientPageSortField',
    RisksClientPageSortOrder = 'RisksClientPageSortOrder',
    RisksPageFilters = 'RisksPageFilters',
    RisksPageSortField = 'RisksPageSortField',
    RisksPageSortOrder = 'RisksPageSortOrder',
    ShiftHandoversPageFacilityId = 'ShiftHandoversPageFacilityId',
    SiteFilters = 'SiteFilters'
}
