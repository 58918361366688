import * as React from 'react';
import useTranslate from 'translations/translation-utils';
interface IProps {
    componentId?: string;
    title: string;
    isVisible: boolean;
    position?: 'left' | 'right';
    className?: string;
    isFilterExists?: boolean;
    onClose?: () => void;
    onOutsideDialogClick?: () => void;
    onClearFilters: () => void;
}

export const OverlayPanel: React.FC<React.PropsWithChildren<IProps>> = ({
    title,
    isVisible,
    position = 'left',
    className,
    componentId,
    isFilterExists,
    onClose,
    onOutsideDialogClick,
    onClearFilters,
    ...props
}) => {
    const translate = useTranslate();
    const css = `overlay-panel overlay-panel-${position}${isVisible ? ` hide` : ''}${className ? ` ${className}` : ''
        }`;

    const headerId = componentId ? { id: `overlay-panel-title-${componentId}` } : null;

    return isVisible ? (
        <div>
            <div className={`modal modal-quantum is-clipped is-active`}>
                <div className="modal-background" onClick={onOutsideDialogClick} />
            </div>
            <div className={css}>
                <div className="header">
                    <h2 {...headerId}>{title}</h2>
                    <ul className="icon-toolbar icon-toolbar-filter">
                        <li className={`icon-toolbar-item-${isFilterExists ? 'filter-applied' : 'clear-filter'}`} onClick={onClearFilters}>
                            <span>
                                {translate(
                                    'RiskRegisterPage.RiskRegisterToolbar.Title.ClearFilters'
                                )}
                            </span>
                        </li>
                    </ul>
                    <ul className="icon-toolbar icon-toolbar-close">
                        <li className="icon-toolbar-item-close" onClick={onClose}>
                            <span>
                                {translate('RiskRegisterPage.RiskRegisterToolbar.Title.Close')}
                            </span>
                        </li>
                    </ul>
                </div>
                {props.children}
            </div>
        </div>
    ) : null;
};
