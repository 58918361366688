import { onRouteChange } from 'actions/app-actions';
import { IOutcome } from 'reducers/cermAssessments';
import {
    CermAssessmentActions,
    onSaveAndNext,
    onSetOutcomeEndReview as onSaveAndEnd,
    onShowAddAttachmentModal as onUploadClick
} from '../../../../actions/cerm-assessment-actions';
import {
    setProcessStep,
    setOutcome
} from 'actions/actions-v2/cerm-assessment-action-v2';
import { IAppAction } from 'actions/app-action';

export default {
    onChangeRoot: (urlLocation: string) => onRouteChange(urlLocation),
    onSaveAndNext,
    onSaveAndEnd,
    onUploadClick,
    setProcessStep,
    setOutcome,
    onSetOutcome: (outcome: Partial<IOutcome>): IAppAction => ({
        type: CermAssessmentActions.SET_OUTCOME,
        payload: {
            outcome
        }
    })
};
