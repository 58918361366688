import { Action } from 'redux';

import { IChangeSearchValueAction, ILoadSearchResultsAction } from '.';
import { ISiteProfile } from '../../models/site-profile';

export enum SiteActions {
    SELECT_SITE = '@@siteRelationships/SELECT_SITE',
    CHANGE_SITE_SEARCH_VALUE = '@@siteRelationships/CHANGE_SITE_SEARCH_VALUE',
    LOAD_SITE_SEARCH_RESULTS = '@@siteRelationships/LOAD_SITE_SEARCH_RESULTS',
    LOAD_SITE_SEARCH_RESULTS_FULFILLED = '@@siteRelationships/LOAD_SITE_SEARCH_RESULTS_FULFILLED',
    LOAD_SITE_SEARCH_RESULTS_REJECTED = '@@siteRelationships/LOAD_SITE_SEARCH_RESULTS_REJECTED',
    LOAD_SITE_SEARCH_RESULTS_CANCELLED = '@@siteRelationships/LOAD_SITE_SEARCH_RESULTS_CANCELLED'
}

export interface ISelectSiteAction extends Action {
    type: SiteActions.SELECT_SITE;
    payload: {
        site: ISiteProfile;
    };
}

export const selectSite = (site: ISiteProfile): ISelectSiteAction => ({
    type: SiteActions.SELECT_SITE,
    payload: {
        site
    }
});

export interface IChangeSiteSearchValueAction extends IChangeSearchValueAction {
    type: SiteActions.CHANGE_SITE_SEARCH_VALUE;
}

export const changeSiteSearchValue = (value: string): IChangeSiteSearchValueAction => ({
    type: SiteActions.CHANGE_SITE_SEARCH_VALUE,
    payload: { value }
});

export interface ILoadSiteSearchResultsAction extends ILoadSearchResultsAction {
    type: SiteActions.LOAD_SITE_SEARCH_RESULTS;
}

export const loadSiteSearchResults = (searchString: string): ILoadSearchResultsAction => ({
    type: SiteActions.LOAD_SITE_SEARCH_RESULTS,
    payload: {
        searchString
    }
});

export interface ILoadSiteSearchResultsFulfilled extends Action {
    type: SiteActions.LOAD_SITE_SEARCH_RESULTS_FULFILLED;
    payload: {
        searchString: string;
        sites: ISiteProfile[];
    };
}

export const loadSiteSearchResultsFulfilled = (
    searchString: string,
    sites: ISiteProfile[]
): ILoadSiteSearchResultsFulfilled => ({
    type: SiteActions.LOAD_SITE_SEARCH_RESULTS_FULFILLED,
    payload: {
        searchString,
        sites
    }
});

export interface ILoadSiteSearchResultsCancelled extends Action {
    type: SiteActions.LOAD_SITE_SEARCH_RESULTS_CANCELLED;
    payload: {
        searchString: string;
    };
}

export const loadSiteSearchResultsCancelled = (searchString: string): ILoadSiteSearchResultsCancelled => ({
    type: SiteActions.LOAD_SITE_SEARCH_RESULTS_CANCELLED,
    payload: {
        searchString
    }
});

export interface ILoadSiteSearchResultsRejected extends Action {
    type: SiteActions.LOAD_SITE_SEARCH_RESULTS_REJECTED;
    error: true;
    payload: any;
}

export const loadSiteSearchResultsRejected = (
    searchString: string,
    error: any
): ILoadSiteSearchResultsRejected => ({
    type: SiteActions.LOAD_SITE_SEARCH_RESULTS_REJECTED,
    error: true,
    payload: {
        searchString,
        error
    }
});

export type SiteActionTypes =
| ISelectSiteAction
| IChangeSiteSearchValueAction
| ILoadSiteSearchResultsAction
| ILoadSiteSearchResultsFulfilled
| ILoadSiteSearchResultsCancelled
| ILoadSiteSearchResultsRejected;
