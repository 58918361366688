import { compact } from 'lodash';
import * as React from 'react';

import { IKeyValue } from '../../models/key-value';
import '../select/Select.scss';

export interface IProps extends React.AllHTMLAttributes<HTMLSelectElement> {
    options: Array<IKeyValue<string>>;
    emptyOptionsValue?: string;
}

export const CustomSelect: React.FC<IProps> = (props) => {
    const { options, emptyOptionsValue, ...otherProps } = props;
    const css = compact([
        'select',
        props.className,
    ]).join(' ');

    return (
        <div className={css}>
            <select
                {...otherProps}
            >
                {emptyOptionsValue && (
                    <option value="">{emptyOptionsValue}</option>
                )}
                {options && options.map((x) => (
                    <option key={x.value} value={x.value}>
                        {x.value}
                    </option>
                ))}
            </select>
        </div>
    );
};
