import * as React from 'react';
import { ConfirmDialog } from 'components/v2/components';
import { IIncident } from 'models';
import useTranslate from 'translations/translation-utils';
import { FormikProps } from 'formik';
import { TextField } from 'components/form/fields/text-field';
import { TextAreaField } from 'components/form/fields/textarea-field';
import { RadioField } from 'components/form/fields/radio-field';
import { yesNoOptions } from 'routes/create-question-set/views/common';

interface IProps {
    formikBag: FormikProps<Partial<IIncident>>;
    isVisible: boolean;
    onConfirm: () => void;
    onClose: () => void;
}

export const LessonsLearnedDialog: React.FC<IProps> = ({
    formikBag,
    isVisible,
    onConfirm,
    onClose,
}) => {
    const translate = useTranslate();
    const [createLessonsLearnedFromIncident, setCreateLessonsLearnedFromIncident] = React.useState(null);

    const selectedCreateLessonsLearned =
        createLessonsLearnedFromIncident !== null
            ? createLessonsLearnedFromIncident
                ? 'true'
                : 'false'
            : null;

    const handleCreateChange = (event: any) => {
        const result = event.currentTarget.value === 'true';

        setCreateLessonsLearnedFromIncident(result);
        formikBag.setFieldValue('lessonsLearned.isCreated', result);
    };

    const handleTitleChange = (event: any) => {
        formikBag.setFieldValue('lessonsLearned.title', event.target.value);
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        formikBag.setFieldValue('lessonLearnDescription', event.target.value);
    };

    const onCloseButtonClick = () => {
        setCreateLessonsLearnedFromIncident(null);
        formikBag.setFieldValue('lessonsLearned.isCreated', null);

        onClose();
    };

    const getBody = () => {
        return (
            <>
                <RadioField
                    id="createLessonsLearned"
                    label={translate('IncidentPage.LessonsLearnedConfirmDialog.CreateYesNo')}
                    options={yesNoOptions}
                    name={'createLessonsLearned'}
                    value={selectedCreateLessonsLearned}
                    handleChange={handleCreateChange}
                />
                <br />
                <TextField
                    id="lessonsLearnedTitle"
                    label={translate('IncidentPage.LessonsLearnedConfirmDialog.Title')}
                    value={formikBag.values.lessonsLearned?.title}
                    error={formikBag.errors.lessonsLearned?.title}
                    isDisabled={!createLessonsLearnedFromIncident}
                    handleChange={handleTitleChange}
                    setVal={formikBag.setFieldValue}
                />
                <TextAreaField
                    id="lessonsLearnedDescription"
                    label={translate('IncidentPage.LessonsLearnedConfirmDialog.Description')}
                    value={formikBag.values.lessonLearnDescription}
                    error={formikBag.errors.lessonLearnDescription}
                    isDisabled={!createLessonsLearnedFromIncident}
                    handleChange={handleDescriptionChange}
                    setVal={formikBag.setFieldValue}
                />
            </>
        );
    };

    return (
        <ConfirmDialog
            title={translate('IncidentPage.LessonsLearnedConfirmDialog.Header')}
            message={getBody()}
            buttonCancelHide={false}
            buttonConfirmText={translate(
                'IncidentPage.LessonsLearnedConfirmDialog.ConfirmButtonText'
            )}
            isVisible={isVisible}
            onConfirm={onConfirm}
            onClose={onCloseButtonClick}
            onOutsideDialogClick={onCloseButtonClick}
            confirmButtonDisabled={!formikBag.isValid || createLessonsLearnedFromIncident === null}
        />
    );
};
