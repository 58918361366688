import * as React from 'react';
import '../../views/operating-platform.scss';
import useTranslate from 'translations/translation-utils';
import iconHierachyArrow from 'assets/icon-hierarchy-tracking.svg';
interface IProps {
    isReadOnly?: boolean;
    lineOfBusiness?: string;
    divisionSector?: string;
    selectOperatingPlatform?: string;
}

export const HierarchyTrackerTab: React.FC<IProps> = (props) => {

    const lineOfBusiness = (
        (!props.lineOfBusiness) ? 'Line Of Business' : props.lineOfBusiness
    );

    const divisionSector = (
        (!props.divisionSector) ? 'Division / Sector' : props.divisionSector
    );

    const translate = useTranslate();

    return (
        <div className="tracking-box">
            <br />
            <div className="text-alignment-center bolded">
                <span>{translate('OperatingPlatform.Tracker.Title')}</span>
            </div>
            <br />
            <div className="text-alignment-center bolded">
                <span className='operating-platform-selected'>{props.selectOperatingPlatform}</span>
            </div>
            <br />
            <div className="tracking-arrow">
                <span className="icon">
                    <img src={iconHierachyArrow} alt={"hierachy-Arrow"} />
                </span>
            </div>
            <br />
            <div className='tracking-input-box text-alignment-center bolded'>
                <span>{lineOfBusiness}</span>
            </div>
            <br />

            <div className="tracking-arrow ">
                <span className="icon">
                    <img src={iconHierachyArrow} alt={"hierachy-Arrow"} />
                </span>
            </div>

            <br />
            <div className='tracking-input-box text-alignment-center bolded'>
                <span>{divisionSector}</span>
            </div>

            <br />
            <div className="tracking-arrow ">
                <span className="icon">
                    <img src={iconHierachyArrow} alt={"hierachy-Arrow"} />
                </span>
            </div>
            <br />
            <div className="text-alignment-center">
                <span>{translate('OperatingPlatform.Tracker.BusinessUnit')}</span>
            </div>
            <br />

        </div >
    )
};

export default HierarchyTrackerTab;
