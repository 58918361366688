import * as React from 'react';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconColor, MaterialIconType } from 'routes/material-icon/material-icon-type';
import { IKeyValue } from '../../models/key-value';
import './Radio.scss';

export interface IProps extends React.AllHTMLAttributes<HTMLInputElement> {
    id: string;
    name: string;
    options: Array<IKeyValue<string | number | string[]>>;
    isShowWarning?: boolean;
    warningLabel?: string;
}

export const Radio: React.FC<IProps> = (props) => {
    const { id, options, value, ...otherProps } = props;

    return (
        <div id={id}>
            {options.map((option) => (
                <React.Fragment key={option.key}>
                    <input
                        type="radio"
                        value={option.value}
                        id={id + option.key}
                        checked={option.value === value}
                        {...otherProps}
                    />
                    <label htmlFor={id + option.key} className="radio" key={option.key}>
                        {option?.label ?? option.key}
                    </label>
                    {props.isShowWarning && option.value === value && <p className="has-text-warning">
                        <span className='warning-icon'>< MaterialIcons type={MaterialIconType.Warning} color={MaterialIconColor.orange} /> </span><span className="warning-label">{props.warningLabel}</span>
                    </p>}
                </React.Fragment>
            ))}
        </div>
    );
};
