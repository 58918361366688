import { IAppAction } from '../app-action';

export enum NavActions {
    LOAD_APP_DATA = '@@appdata/LOAD_APP_DATA',
    LOAD_APP_DATA_FULFILLED = '@@appdata/LOAD_APP_DATA_FULFILLED',
    LOAD_APP_DATA_REJECTED = '@@appdata/LOAD_APP_DATA_REJECTED',
    LOAD_APP_SETTINGS = '@@appdata/LOAD_APP_SETTINGS',
    LOAD_APP_SETTINGS_FULFILLED = '@@appdata/LOAD_APP_SETTINGS_FULFILLED',
    LOAD_APP_SETTINGS_REJECTED = '@@appdata/LOAD_APP_SETTINGS_REJECTED',
}

export const loadAppData = (): IAppAction => ({
    type: NavActions.LOAD_APP_DATA,
});

export const loadAppSettings = (): IAppAction => ({
    type: NavActions.LOAD_APP_SETTINGS,
});