import { createSelector } from 'reselect';

export default createSelector(
    (state: any) => state,
    (state) => ({
        pageTitle: 'CERM Site Overview',
        permissions: state.app.permissions,
        itemIds: state.cermSiteOverview.items.map((x: { id: any; }) => x.id),
        averageScore: state.cermSiteOverview.averageScore,
        isInitialising: state.cermSiteOverview.isInitialising
    })
);
