import { IAppAction } from '../app-action';
import { IActionFilters } from 'models/action-filters';
import { IAction } from 'models/action';
import { IDeleteModel } from 'models';

export enum ActionActions {
    CREATE_ACTION_FOR_INCIDENT = '@@action/CREATE_ACTION_FOR_INCIDENT',
    CREATE_ACTION_FULFILLED_FOR_INCIDENT = '@@action/CREATE_ACTION_FULFILLED_FOR_INCIDENT',
    CREATE_ACTION_REJECTED_FOR_INCIDENT = '@@action/CREATE_ACTION_REJECTED_FOR_INCIDENT',

    CREATE_ACTION_FOR_RISK = '@@action/CREATE_ACTION_FOR_RISK',
    CREATE_ACTION_FOR_RISK_FULFILLED = '@@action/CREATE_ACTION_FOR_RISK_FULFILLED',
    CREATE_ACTION_FOR_RISK_REJECTED = '@@action/CREATE_ACTION_FOR_RISK_REJECTED',

    CREATE_ACTION_FOR_MOCK_DRILL_REPORT = '@@action/CREATE_ACTION_FOR_MOCK_DRILL_REPORT',
    CREATE_ACTION_FOR_MOCK_DRILL_REPORT_FULFILLED = '@@action/CREATE_ACTION_FOR_MOCK_DRILL_REPORT_FULFILLED',
    CREATE_ACTION_FOR_MOCK_DRILL_REPORT_REJECTED = '@@action/CREATE_ACTION_FOR_MOCK_DRILL_REPORT_REJECTED',

    UPDATE_ACTION_FOR_RISK = '@@action/UPDATE_ACTION_FOR_RISK',
    UPDATE_ACTION_FOR_RISK_FULFILLED = '@@action/UPDATE_ACTION_FOR_RISK_FULFILLED',
    UPDATE_ACTION_FOR_RISK_REJECTED = '@@action/UPDATE_ACTION_FOR_RISK_REJECTED',

    UPDATE_ACTION_FOR_MOCK_DRILL_REPORT = '@@action/UPDATE_ACTION_FOR_MOCK_DRILL_REPORT',
    UPDATE_ACTION_FOR_MOCK_DRILL_REPORT_FULFILLED = '@@action/UPDATE_ACTION_FOR_MOCK_DRILL_REPORT_FULFILLED',
    UPDATE_ACTION_FOR_MOCK_DRILL_REPORT_REJECTED = '@@action/UPDATE_ACTION_FOR_MOCK_DRILL_REPORT_REJECTED',

    ARCHIVE_ACTION_FOR_RISK = '@@action/ARCHIVE_ACTION_FOR_RISK',
    ARCHIVE_ACTION_FOR_RISK_FULFILLED = '@@action/ARCHIVE_ACTION_FOR_RISK_FULFILLED',
    ARCHIVE_ACTION_FOR_RISK_REJECTED = '@@action/ARCHIVE_ACTION_FOR_RISK_REJECTED',

    ARCHIVE_ACTION_FOR_MOCK_DRILL_REPORT = '@@action/ARCHIVE_ACTION_FOR_MOCK_DRILL_REPORT',
    ARCHIVE_ACTION_FOR_MOCK_DRILL_REPORT_FULFILLED = '@@action/ARCHIVE_ACTION_FOR_MOCK_DRILL_REPORT_FULFILLED',
    ARCHIVE_ACTION_FOR_MOCK_DRILL_REPORT_REJECTED = '@@action/ARCHIVE_ACTION_FOR_MOCK_DRILL_REPORT_REJECTED',

    LOAD_ACTIONS = '@@action/LOAD_ACTIONS',
    LOAD_ACTIONS_FULFILLED = '@@action/LOAD_ACTIONS_FULFILLED',
    LOAD_ACTIONS_REJECTED = '@@action/LOAD_ACTIONS_REJECTED',

    LOAD_RISK_ACTIONS = '@@action/LOAD_RISK_ACTIONS',
    LOAD_RISK_ACTIONS_FULFILLED = '@@action/LOAD_RISK_ACTIONS_FULFILLED',
    LOAD_RISK_ACTIONS_REJECTED = '@@action/LOAD_RISK_ACTIONS_REJECTED',

    LOAD_ACTION = '@@action/LOAD_ACTION',
    LOAD_ACTION_FULFILLED = '@@action/LOAD_ACTION_FULFILLED',
    LOAD_ACTION_REJECTED = '@@action/LOAD_ACTION_REJECTED',

    CREATE_ACTION = '@@action/CREATE_ACTION',
    CREATE_ACTION_FULFILLED = '@@action/CREATE_ACTION_FULFILLED',
    CREATE_ACTION_REJECTED = '@@action/CREATE_ACTION_REJECTED',

    UPDATE_ACTION = '@@action/UPDATE_ACTION',
    UPDATE_ACTION_FULFILLED = '@@action/UPDATE_ACTION_FULFILLED',
    UPDATE_ACTION_REJECTED = '@@action/UPDATE_ACTION_REJECTED',

    DELETE_ACTION = '@@action/DELETE_ACTION',
    DELETE_ACTION_FULFILLED = '@@action/DELETE_ACTION_FULFILLED',
    DELETE_ACTION_REJECTED = '@@action/DELETE_ACTION_REJECTED',

    CLOSE_ACTION = '@@action/CLOSE_ACTION',
    CLOSE_ACTION_FULFILLED = '@@action/CLOSE_ACTION_FULFILLED',
    CLOSE_ACTION_REJECTED = '@@action/CLOSE_ACTION_REJECTED',

    SORT_ACTIONS = '@@action/SORT_ACTIONS',
    CLEAR_FORM = '@@action/CLEAR_FORM',

    EXPORT_ACTIONS = '@@action/EXPORT_ACTIONS',
    EXPORT_ACTIONS_FULFILLED = '@@action/EXPORT_ACTIONS_FULFILLED',
    EXPORT_ACTIONS_REJECTED = '@@action/EXPORT_ACTIONS_REJECTED',

    LOAD_HISTORY = '@@action/LOAD_HISTORY',
    LOAD_HISTORY_FULFILLED = '@@action/LOAD_HISTORY_FULFILLED',
    LOAD_HISTORY_REJECTED = '@@action/LOAD_HISTORY_REJECTED',

    LOAD_MACRO_ACTIONS = '@@action/LOAD_MARCO_ACTIONS',
    LOAD_MACRO_ACTIONS_FULFILLED = '@@action/LOAD_MARCO_ACTIONS_FULFILLED',
    LOAD_MACRO_ACTIONS_REJECTED = '@@action/LOAD_MARCO_ACTIONS_REJECTED',

    LOAD_MACRO_ACTION = '@@action/LOAD_MARCO_ACTION',
    LOAD_MACRO_ACTION_FULFILLED = '@@action/LOAD_MARCO_ACTION_FULFILLED',
    LOAD_MACRO_ACTION_REJECTED = '@@action/LOAD_MARCO_ACTION_REJECTED',

    CREATE_MACRO_ACTION = '@@action/CREATE_MARCO_ACTION',
    CREATE_MACRO_ACTION_FULFILLED = '@@action/CREATE_MARCO_ACTION_FULFILLED',
    CREATE_MACRO_ACTION_REJECTED = '@@action/CREATE_MARCO_ACTION_REJECTED',

    UPDATE_MACRO_ACTION = '@@action/UPDATE_MARCO_ACTION',
    UPDATE_MACRO_ACTION_FULFILLED = '@@action/UPDATE_MARCO_ACTION_FULFILLED',
    UPDATE_MACRO_ACTION_REJECTED = '@@action/UPDATE_MARCO_ACTION_REJECTED',

    UPDATE_MACRO_ACTION_FOR_RISK = '@@action/UPDATE_MARCO_ACTION_FOR_RISK',
    UPDATE_MACRO_ACTION_FOR_RISK_FULFILLED = '@@action/UPDATE_MARCO_ACTION_FOR_RISK_FULFILLED',
    UPDATE_MACRO_ACTION_FOR_RISK_REJECTED = '@@action/UPDATE_MARCO_ACTION_FOR_RISK_REJECTED',

    DELETE_MACRO_ACTION = '@@action/DELETE_MARCO_ACTION',
    DELETE_MACRO_ACTION_FULFILLED = '@@action/DELETE_MARCO_ACTION_FULFILLED',
    DELETE_MACRO_ACTION_REJECTED = '@@action/DELETE_MARCO_ACTION_REJECTED',

    CLOSE_MACRO_ACTION = '@@action/CLOSE_MARCO_ACTION',
    CLOSE_MACRO_ACTION_FULFILLED = '@@action/CLOSE_MARCO_ACTION_FULFILLED',
    CLOSE_MACRO_ACTION_REJECTED = '@@action/CLOSE_MARCO_ACTION_REJECTED',
}

export const loadActionHistory = (id: string): IAppAction => ({
    type: ActionActions.LOAD_HISTORY,
    payload: id,
});

export const loadRiskActions = (filters: IActionFilters): IAppAction => ({
    type: ActionActions.LOAD_RISK_ACTIONS,
    payload: filters,
});

export const loadActions = (filters: IActionFilters): IAppAction => ({
    type: ActionActions.LOAD_ACTIONS,
    payload: filters,
});

export const loadAction = (id: string, siteId: string, category: string): IAppAction => ({
    type: ActionActions.LOAD_ACTION,
    payload: { id, shard: siteId, category },
});

export const createAction = (action: IAction): IAppAction => ({
    type: ActionActions.CREATE_ACTION,
    payload: action,
});

export const createActionForRisk = (action: IAction): IAppAction => ({
    type: ActionActions.CREATE_ACTION_FOR_RISK,
    payload: action,
});

export const updateActionForRisk = (action: IAction): IAppAction => ({
    type: ActionActions.UPDATE_ACTION_FOR_RISK,
    payload: action,
});

export const archiveActionForRisk = (model: IDeleteModel): IAppAction => ({
    type: ActionActions.ARCHIVE_ACTION_FOR_RISK,
    payload: model,
});

export const createActionForMockDrillReport = (action: IAction): IAppAction => ({
    type: ActionActions.CREATE_ACTION_FOR_MOCK_DRILL_REPORT,
    payload: action,
});

export const updateActionForMockDrillReport = (action: IAction): IAppAction => ({
    type: ActionActions.UPDATE_ACTION_FOR_MOCK_DRILL_REPORT,
    payload: action,
});

export const archiveActionForMockDrillReport = (model: IDeleteModel): IAppAction => ({
    type: ActionActions.ARCHIVE_ACTION_FOR_MOCK_DRILL_REPORT,
    payload: model,
});

export const createActionForIncident = (action: IAction): IAppAction => ({
    type: ActionActions.CREATE_ACTION_FOR_INCIDENT,
    payload: action,
});

export const updateAction = (action: IAction): IAppAction => ({
    type: ActionActions.UPDATE_ACTION,
    payload: action,
});

export const onSort = (sortColumn: Extract<keyof IAction, string>, sortAscending: boolean) => ({
    type: ActionActions.SORT_ACTIONS,
    payload: { sortColumn, sortAscending },
});

export const removeAction = (id: string, siteId: string): IAppAction => ({
    type: ActionActions.DELETE_ACTION,
    payload: { id, shard: siteId, siteId },
});

export const closeAction = (action: IAction): IAppAction => ({
    type: ActionActions.CLOSE_ACTION,
    payload: action,
});

export const clearForm = (): IAppAction => ({
    type: ActionActions.CLEAR_FORM,
});

export const exportData = (siteId: string): IAppAction => ({
    type: ActionActions.EXPORT_ACTIONS,
    payload: { siteId },
});

export const loadMacroActions = (filters: IActionFilters): IAppAction => ({
    type: ActionActions.LOAD_MACRO_ACTIONS,
    payload: filters,
});

export const loadMacroAction = (id: string, siteId: string): IAppAction => ({
    type: ActionActions.LOAD_MACRO_ACTION,
    payload: { id, shard: siteId },
});

export const createMacroAction = (action: IAction): IAppAction => ({
    type: ActionActions.CREATE_MACRO_ACTION,
    payload: action,
});

export const updateMacroAction = (action: IAction): IAppAction => ({
    type: ActionActions.UPDATE_MACRO_ACTION,
    payload: action,
});

export const updateMacroActionForRisk = (action: IAction): IAppAction => ({
    type: ActionActions.UPDATE_MACRO_ACTION_FOR_RISK,
    payload: action,
});

export const archiveMacroAction = (id: string, siteId: string): IAppAction => ({
    type: ActionActions.DELETE_MACRO_ACTION,
    payload: { id, shard: siteId, siteId },
});

export const closeMacroAction = (action: IAction): IAppAction => ({
    type: ActionActions.CLOSE_MACRO_ACTION,
    payload: action,
});
