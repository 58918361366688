import { IRootState } from '../store';
import { connect } from 'react-redux';
import { Dispatch } from 'react';
import { IAppAction } from 'actions/app-action';
import { createSelector } from 'reselect';
import { RiskProfileMasterQuestionnairePage } from './risk-profile-master-questionnaire-page';
import {
    loadRiskProfileMasterQuestionnaire,
    updateRiskProfileMasterQuestionnaire,
    loadRiskProfileMasterOverview,
    createRiskProfileMasterQuestionnaire
} from 'actions/actions-v2';
import { IRiskProfileMasterQuestionnaire } from 'models';
import { withRouter } from 'react-router';
import { loadPerson } from 'actions/actions-v2/user-profile-action-v2';

const getRiskProfileMasterQuestionnaire = (state: IRootState) =>
    state.riskProfileQuestionnaireState.riskProfileMasterQuestionnaire;
const getIsLoading = (state: IRootState) => state.riskProfileQuestionnaireState.isLoading;
const getRiskProfileCategories = (state: IRootState) => state.app.riskProfileCategories;

const getPerson = createSelector(
    (state: any) => state.person,
    (state) => state.personProfile
);

const getUserId = (state: IRootState) => state.app.userId;

const mapStateToProps = createSelector(
    getRiskProfileMasterQuestionnaire,
    getRiskProfileCategories,
    getIsLoading,
    getPerson,
    getUserId,
    (riskProfileMasterQuestionnaire, riskProfileCategories, isLoading, person, userId) => ({
        riskProfileMasterQuestionnaire,
        riskProfileCategories,
        isLoading,
        person,
        userId
    })
);

const mapDispatchToProps = (dispatch: Dispatch<IAppAction>) => {
    return {
        onLoadPerson(personId: string)
        {
            dispatch(loadPerson(personId)); 
        },
        loadMasterQuestionnaire() {
            dispatch(loadRiskProfileMasterQuestionnaire());
        },
        loadRiskProfileOverview(operatingPlatform: string) {
            dispatch(loadRiskProfileMasterOverview(operatingPlatform));
        },
        saveMasterQuestionnaire(questionnaire: IRiskProfileMasterQuestionnaire) {
            dispatch(updateRiskProfileMasterQuestionnaire(questionnaire));
        },
        createMasterQuestionnaire(questionnaire: IRiskProfileMasterQuestionnaire) {
            dispatch(createRiskProfileMasterQuestionnaire(questionnaire));
        },
    };
};

export const RiskProfileMasterQuestionnaireContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(RiskProfileMasterQuestionnairePage));
