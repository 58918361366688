import { IAppAction } from 'actions/app-action';
import { Epic, combineEpics } from 'redux-observable';
import { BaseEpic, IActionType } from './base-epic';
import { GlobalSkillCardActions } from 'actions/global-skill-card-actions';
import { IGlobalSkillCard, IHierarchicalPathsModel } from 'models/admin-cdna/global-skill-card';

const controllerName = 'GlobalSkillCard';

const loadGlobalSkillCards = {
    action: GlobalSkillCardActions.LOAD_GLOBAL_SKILL_CARD,
    actionFulfilled: GlobalSkillCardActions.LOAD_GLOBAL_SKILL_CARD_FULFILLED,
    actionRejected: GlobalSkillCardActions.LOAD_GLOBAL_SKILL_CARD_REJECTED,
} as IActionType;

const createSkillCardActions = {
    action: GlobalSkillCardActions.CREATE_GLOBAL_SKILL_CARD,
    actionFulfilled: GlobalSkillCardActions.CREATE_GLOBAL_SKILL_CARD_FULFILLED,
    actionRejected: GlobalSkillCardActions.CREATE_GLOBAL_SKILL_CARD_REJECTED,
} as IActionType;

const updateSkillCardActions = {
    action: GlobalSkillCardActions.UPDATE_GLOBAL_SKILL_CARD,
    actionFulfilled: GlobalSkillCardActions.UPDATE_GLOBAL_SKILL_CARD_FULFILLED,
    actionRejected: GlobalSkillCardActions.UPDATE_GLOBAL_SKILL_CARD_REJECTED,
} as IActionType;

const deleteSkillCardActions = {
    action: GlobalSkillCardActions.DELETE_GLOBAL_SKILL_CARD,
    actionFulfilled: GlobalSkillCardActions.DELETE_GLOBAL_SKILL_CARD_FULFILLED,
    actionRejected: GlobalSkillCardActions.DELETE_GLOBAL_SKILL_CARD_REJECTED,
} as IActionType;

const publishSkillCardActions = {
    action: GlobalSkillCardActions.PUBLISH_SKILLCARD,
    actionFulfilled: GlobalSkillCardActions.PUBLISH_SKILLCARD_FULFILLED,
    actionRejected: GlobalSkillCardActions.PUBLISH_SKILLCARD_REJECTED,
} as IActionType;

const loadGlobalSkillCardsHierarchyPathsByVisiblityActions = {
    action: GlobalSkillCardActions.LOAD_GLOBAL_SKILL_CARD_HIERARCHY_PATHS_BY_VISIBILITY,
    actionFulfilled: GlobalSkillCardActions.LOAD_GLOBAL_SKILL_CARD_HIERARCHY_PATHS_BY_VISIBILITY_FULFILLED,
    actionRejected: GlobalSkillCardActions.LOAD_GLOBAL_SKILL_CARD_HIERARCHY_PATHS_BY_VISIBILITY_REJECTED,
} as IActionType;

const loadGlobalSkillCardsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, loadGlobalSkillCards).get<IGlobalSkillCard[]>(`/api/${controllerName}`);

const createSkillCardEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createSkillCardActions).post<IGlobalSkillCard>(`/api/${controllerName}`);

const updateSkillCardEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateSkillCardActions).put<IGlobalSkillCard>(`/api/${controllerName}`);

const deleteSkillCardEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, deleteSkillCardActions).deleteById<IGlobalSkillCard>(`/api/${controllerName}`);

const publishSkillCardEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, publishSkillCardActions).put<string>(`/api/${controllerName}/publish`);

const loadGlobalSkillCardsHierarchyPathsByVisiblityEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, loadGlobalSkillCardsHierarchyPathsByVisiblityActions).get<IHierarchicalPathsModel[]>(`/api/${controllerName}/hierarchical-paths`);

export const globalSkillCardEpics = combineEpics(
    loadGlobalSkillCardsEpic,
    createSkillCardEpic,
    updateSkillCardEpic,
    deleteSkillCardEpic,
    publishSkillCardEpic,
    loadGlobalSkillCardsHierarchyPathsByVisiblityEpic
);