export type EmeraldStepperVariantType = 'horizontal' | 'vertical';

export enum EmeraldStepperVariant {
  horizontal = 'horizontal',
  vertical = 'vertical',
}

export type StepVariantType = 'default' | 'success' | 'priority';

export enum StepVariant {
  default = 'default',
  success = 'success',
  priority = 'priority',
}
