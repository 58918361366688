import React from 'react';
import {
    ConfirmDialog,
    ConfirmNavigateAwayDialog,
    FloatingButton,
    Panel,
    Table,
    Toolbar,
    ToolbarGroupRight,
} from 'components/v2/components';
import { Loader } from 'components/loader';
import _ from 'lodash';
import { IShiftHandoverItemCategory, IShiftHandoverCategories, HandoverItemCategory } from 'models/shifts/shift-handover';
import useTranslate from 'translations/translation-utils';
import { TextAreaField } from 'components/form/fields/textarea-field';
import { newGuid } from 'utils/id-utils';
import { Input } from 'components/form';
import ReactTooltip from 'react-tooltip';
import { MaterialIcons } from '../../material-icon/material-icon';
import { MaterialIconType, MaterialIconColor } from '../../material-icon/material-icon-type';

interface IProps {
    isLoading?: boolean;
    siteId: string;
    shiftHandoverItemCategories: IShiftHandoverCategories;
    load: (siteId: string) => void;
    create?: (model: IShiftHandoverItemCategory[], siteId: string) => void;
    update?: (model: IShiftHandoverItemCategory[], siteId: string) => void;
    delete?: (categoryId: string) => void;
}

const ShifthandoverCategoryTable: React.FC<IProps> = ({
    isLoading,
    siteId,
    shiftHandoverItemCategories,
    load,
    create,
}) => {
    const translate = useTranslate();
    const getNewShiftHandoverCategory = () => {
        return {
            id: newGuid(),
            isEnabled: true,
            isDefault: false,
            name: ''
        } as IShiftHandoverItemCategory;
    };
    const [newShiftHandoverItemCategories, setNewShiftHandoverItemCategories] = React.useState<IShiftHandoverItemCategory[]>([]);
    const [shiftHandoverCategory, setShiftHandoverCategory] = React.useState(getNewShiftHandoverCategory());
    const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
    const [showCategoryEditDialog, setShowCategoryEditDialog] = React.useState(false);
    const [currentShiftHandoverCategory, setCurrentShiftHandoverCategory] = React.useState('');
    const [localShiftHandoverCategory, setLocalShiftHandoverCategory] = React.useState('');
    const [categoryError, setCategoryError] = React.useState(false);

    React.useEffect(() => {
        if (siteId) {
            load(siteId);
        }
    }, [siteId]);

    React.useEffect(() => {
        ReactTooltip.rebuild();
    });

    React.useEffect(() => {
        if (shiftHandoverItemCategories) {
            setNewShiftHandoverItemCategories(shiftHandoverItemCategories.categories);
        }
    }, [shiftHandoverItemCategories])

    const handleShiftHandoverCategoryChange = (event) => {
        if (event.target.value === '') {
            setCategoryError(true)
        } else {
            setCategoryError(false);
        }
        setShiftHandoverCategory({ ...shiftHandoverCategory, name: event.target.value });

    }
    const handleShiftHandoverCategoryBlur = (event) => {
        if (event.target.value === '') {
            setCategoryError(true)
        } else {
            setCategoryError(false);
        }
    }
    const getBody = () => {
        return (
            <>
                <div className="columns">
                    <div className="column is-4" />
                </div>

                <div className="columns">
                    <div className="column">
                        <TextAreaField
                            id="shiftHandoverCategory"
                            label={translate('ShiftHandoversAdminPage.label.ShiftHandoverCategory')}
                            value={shiftHandoverCategory.name}
                            handleChange={handleShiftHandoverCategoryChange}
                            error={categoryError ? translate('ShiftHandoversAdminPage.Error.CategoryName') : ""}
                            handleBlur={handleShiftHandoverCategoryBlur}
                        />
                    </div>
                </div>
            </>
        );
    };

    const onConfirmAddSiftHandoverItemCategory = () => {
        const updatedShiftHandoverItemCategories = [...newShiftHandoverItemCategories];
        if (shiftHandoverCategory.name.trim() !== '') {
            setNewShiftHandoverItemCategories([
                ...updatedShiftHandoverItemCategories,
                shiftHandoverCategory,
            ]);
            resetAll();
        } else {
            setCategoryError(true)
        }


    };

    const onShowHideCategory = (categoryId: string) => {
        let updatedShiftHandoverItemCategories = [...newShiftHandoverItemCategories];
        updatedShiftHandoverItemCategories =
            updatedShiftHandoverItemCategories &&
            updatedShiftHandoverItemCategories.map((category) => {
                if (category.id === categoryId) {
                    return ({
                        ...category,
                        isEnabled: !category.isEnabled
                    });
                }

                return category;
            });

        setNewShiftHandoverItemCategories(updatedShiftHandoverItemCategories);
    };

    const handleCategoryChange = (event) => {
        setLocalShiftHandoverCategory(event.target.value)
    };

    const onHandleEditCategoryBlur = (categoryId) => {
        let updatedShiftHandoverItemCategories = [...newShiftHandoverItemCategories];
        updatedShiftHandoverItemCategories =
            updatedShiftHandoverItemCategories &&
            updatedShiftHandoverItemCategories.map((item) => {
                if (item.id === categoryId) {
                    return ({
                        ...item,
                        name: localShiftHandoverCategory.trim()
                    })
                }

                return item;
            });

        setNewShiftHandoverItemCategories(updatedShiftHandoverItemCategories);
        setShowCategoryEditDialog(false);
        setLocalShiftHandoverCategory('');
    };

    const resetAll = () => {
        setShiftHandoverCategory(getNewShiftHandoverCategory());
        setShowConfirmDialog(false);
        setLocalShiftHandoverCategory('');
        setCategoryError(false);
    };

    const renderEditTextBox = (shiftHandoverCategory: IShiftHandoverItemCategory) => {
        return (
            <>
                <div className="field">
                    <div className="control">
                        <Input
                            id={shiftHandoverCategory.id}
                            value={
                                localShiftHandoverCategory !== ''
                                    ? localShiftHandoverCategory
                                    : shiftHandoverCategory.name
                            }
                            onChange={handleCategoryChange}
                            onBlur={() => onHandleEditCategoryBlur(shiftHandoverCategory.id)}
                        />
                    </div>
                </div>
            </>
        );
    };

    const onEditHandoverCategory = (categoryId: string) => {
        setCurrentShiftHandoverCategory(categoryId);
        setShowCategoryEditDialog(true);
    };

    const onTrashShiftHandoverCategory = (categoryId: string) => {
        let updatedShiftHandoverItemCategories = [...newShiftHandoverItemCategories];
        updatedShiftHandoverItemCategories =
            updatedShiftHandoverItemCategories &&
            updatedShiftHandoverItemCategories.filter((item) => item.id !== categoryId);
        setNewShiftHandoverItemCategories(updatedShiftHandoverItemCategories);
    };

    const onMoveShifthandoverCategoryUp = (index) => {
        const updatedShiftHandoverItemCategories = [...newShiftHandoverItemCategories];

        if (index > 0) {
            [updatedShiftHandoverItemCategories[index], updatedShiftHandoverItemCategories[index - 1]] = [
                updatedShiftHandoverItemCategories[index - 1],
                updatedShiftHandoverItemCategories[index],
            ];
        }

        setNewShiftHandoverItemCategories(updatedShiftHandoverItemCategories);
    };

    const onMoveShifthandoverCategoryDown = (index) => {
        const updatedShiftHandoverItemCategories = [...newShiftHandoverItemCategories];

        if (index < updatedShiftHandoverItemCategories.length - 1) {
            [updatedShiftHandoverItemCategories[index], updatedShiftHandoverItemCategories[index + 1]] = [
                updatedShiftHandoverItemCategories[index + 1],
                updatedShiftHandoverItemCategories[index],
            ];
        }

        setNewShiftHandoverItemCategories(updatedShiftHandoverItemCategories);
    };

    const onSave = () => {
        create(newShiftHandoverItemCategories, siteId);
    };

    const onAddShiftHandoverCategory = () => {
        resetAll();
        setShowConfirmDialog(true);
    };

    const isHandoverCategoryUnchanged = () => {
        return _.isEqual(
            shiftHandoverItemCategories && shiftHandoverItemCategories.categories && shiftHandoverItemCategories.categories,
            newShiftHandoverItemCategories
        );
    }
    return (
        <Loader loading={isLoading}>
            <div className="tabs-container tab-content">
                <Panel title={translate('ShiftHandoversAdminPage.label.HandoverCategories')} collapsed={null}>
                    <div
                        data-tip={translate('ShiftHandoversAdminPage.label.AddnewhandoverCategory')}
                        className="handover-plus-icon"
                        onClick={onAddShiftHandoverCategory}
                    >
                        < MaterialIcons type={MaterialIconType.Add} color={MaterialIconColor.green} />
                    </div>
                    <Table className="shift-handover-categories">
                        <tbody>
                            {newShiftHandoverItemCategories && newShiftHandoverItemCategories.map((shiftHandoverCategory, index) => {
                                return (
                                    <tr className="header-category" key={shiftHandoverCategory.id}>
                                        <td>
                                            {
                                                (showCategoryEditDialog && shiftHandoverCategory.id === currentShiftHandoverCategory)
                                                    ? renderEditTextBox(shiftHandoverCategory)
                                                    : HandoverItemCategory[shiftHandoverCategory.name] ? HandoverItemCategory[shiftHandoverCategory.name] : shiftHandoverCategory.name
                                            }
                                        </td>
                                        <td>
                                            <ul className="shift-checklist-icons">
                                                {shiftHandoverCategory.isDefault &&
                                                    (
                                                        <li
                                                            className="is-pulled-right"
                                                            onClick={() =>
                                                                onShowHideCategory(shiftHandoverCategory.id)
                                                            }
                                                            data-tip={`${shiftHandoverCategory.isEnabled ? translate('ShiftHandoversAdminPage.label.Show') :
                                                                translate('ShiftHandoversAdminPage.label.Hide')
                                                                }`}
                                                        >
                                                            < MaterialIcons type={shiftHandoverCategory && shiftHandoverCategory.isEnabled ? MaterialIconType.Visibility : MaterialIconType.VisibilityOff} color={MaterialIconColor.green} />

                                                        </li>
                                                    )}

                                                {((shiftHandoverCategory.operatingPlatform && shiftHandoverCategory.operatingPlatform.trim() !== "") ? false : !shiftHandoverCategory.isDefault) && (
                                                    <>
                                                        <li
                                                            className="is-pulled-right"
                                                            onClick={() =>
                                                                onEditHandoverCategory(shiftHandoverCategory.id)
                                                            }
                                                            data-tip={translate('ShiftHandoversAdminPage.label.Edit')}
                                                        >

                                                            < MaterialIcons type={MaterialIconType.Edit} color={MaterialIconColor.green} />
                                                        </li>

                                                        <li
                                                            className="is-pulled-right"
                                                            onClick={() =>
                                                                onTrashShiftHandoverCategory(shiftHandoverCategory.id)
                                                            }
                                                            data-tip={translate('ShiftHandoversAdminPage.label.Delete')}
                                                        >

                                                            < MaterialIcons type={MaterialIconType.Delete} color={MaterialIconColor.green} />
                                                        </li>
                                                    </>
                                                )}
                                                <li className="is-pulled-right" data-tip={translate('ShiftHandoversAdminPage.label.MoveUp')}>

                                                    <a
                                                        id={'moveUpQuestionSet'}
                                                        onClick={() =>
                                                            onMoveShifthandoverCategoryUp(index)
                                                        }
                                                        className={`${index === 0 ? 'hidden-link' : ''
                                                            }`}
                                                    >

                                                        < MaterialIcons type={MaterialIconType.ArrowUp} color={MaterialIconColor.green} />
                                                    </a>
                                                </li>
                                                <li className="is-pulled-right" data-tip={translate('ShiftHandoversAdminPage.label.MoveDown')}>
                                                    <a
                                                        id={'moveDownQuestionSet'}
                                                        onClick={() =>
                                                            onMoveShifthandoverCategoryDown(index)
                                                        }
                                                        className={`${shiftHandoverItemCategories && (index ===
                                                            shiftHandoverItemCategories.categories.length - 1)
                                                            ? 'hidden-link'
                                                            : ''
                                                            }`}
                                                    >
                                                        < MaterialIcons type={MaterialIconType.ArrowDown} color={MaterialIconColor.green} />
                                                    </a>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>

                    <ConfirmDialog
                        title={translate('ShiftHandoverItemsTable.ConfirmDialog.AddShiftHandoverCategory.Title')}
                        message={getBody()}
                        isVisible={showConfirmDialog}
                        showConfirmButton={true}
                        onConfirm={onConfirmAddSiftHandoverItemCategory}
                        onClose={() => setShowConfirmDialog(false)}
                        onOutsideDialogClick={() => setShowConfirmDialog(false)}
                        buttonConfirmText={translate(
                            'ShiftHandoverItemsTable.ConfirmDialog.Button.Text.Confirm'
                        )}
                        buttonCancelText={translate(
                            'ShiftHandoverItemsTable.ConfirmDialog.Button.Text.Cancel'
                        )}
                    />

                    <Toolbar type="save">
                        <ToolbarGroupRight>
                            <FloatingButton
                                id="save-risk-profile-questionnaire-button"
                                className="button is-medium is-primary is-pulled-right"
                                disabled={isHandoverCategoryUnchanged()}
                                onClick={onSave}
                                float={false}
                                tooltip={translate('ShiftHandoverItemsTable.ConfirmDialog.Button.SaveTooltip')}
                            >
                                {translate('Globals.Label.Save')}
                            </FloatingButton>
                            <ConfirmNavigateAwayDialog when={false} />
                        </ToolbarGroupRight>
                    </Toolbar>
                </Panel>
            </div>
        </Loader>
    );
};

export default ShifthandoverCategoryTable;
