import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { CermAssessmentFilters as View } from '../../../../components/cerm-assessment-filters';
import actions from './actions';
import selector from './selector';

export const CermOverviewFilters = withRouter(connect(
    selector,
    actions
)(View));
