import { combineReducers } from 'redux';
import { LOCATION_CHANGE } from "redux-first-history";
import { IAppAction } from 'actions/app-action';
import { ICommentState } from 'state/comment-state';
import { CommentActions } from 'actions/actions-v2/comment-action-v2';
import { IComment } from 'models/comment';

const INITIAL_STATE: ICommentState = {
    isLoading: false,
    isSaving: false,
    comments: [],
    riskComments: [],
    actionComments: [],
    message: '',
    editMessage: '',
    messageEditId: ''
};

const isLoading = (
    state: ICommentState['isLoading'] = INITIAL_STATE.isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case CommentActions.LOAD_COMMENTS:
        case CommentActions.LOAD_RISK_COMMENTS:
        case CommentActions.LOAD_ACTION_COMMENTS:
            return true;
        case CommentActions.LOAD_COMMENTS_FULFILLED:
        case CommentActions.LOAD_RISK_COMMENTS_FULFILLED:
        case CommentActions.LOAD_ACTION_COMMENTS_FULFILLED:
        case CommentActions.LOAD_COMMENTS_REJECTED:
        case CommentActions.LOAD_RISK_COMMENTS_REJECTED:
        case CommentActions.LOAD_ACTION_COMMENTS_REJECTED:
            return false;
        default:
            return state;
    }
};

const isSaving = (
    state: ICommentState['isSaving'] = INITIAL_STATE.isSaving,
    action: IAppAction
) => {
    switch (action.type) {
        case CommentActions.UPDATE_COMMENT:
        case CommentActions.CREATE_COMMENT:
        case CommentActions.CREATE_RISK_COMMENT:
        case CommentActions.CREATE_ACTION_COMMENT:
        case CommentActions.DELETE_COMMENT:
            return true;
        case CommentActions.UPDATE_COMMENT_REJECTED:
        case CommentActions.CREATE_COMMENT_REJECTED:
        case CommentActions.CREATE_RISK_COMMENT_REJECTED:
        case CommentActions.CREATE_ACTION_COMMENT_REJECTED:
        case CommentActions.UPDATE_COMMENT_FULFILLED:
        case CommentActions.CREATE_COMMENT_FULFILLED:
        case CommentActions.CREATE_RISK_COMMENT_FULFILLED:
        case CommentActions.CREATE_ACTION_COMMENT_FULFILLED:
        case CommentActions.DELETE_COMMENT_FULFILLED:
        case CommentActions.DELETE_COMMENT_REJECTED:
            return false;
        default:
            return state;
    }
};

const comments = (
    state: ICommentState['comments'] = INITIAL_STATE.comments,
    action: IAppAction
) => {
    switch (action.type) {
        case CommentActions.LOAD_COMMENTS_FULFILLED:
            return action.payload ? action.payload : state;
        case CommentActions.CREATE_COMMENT_FULFILLED:
            if (action.payload !== null) {
                return [...state, action.payload];
            }
            return state;
        case CommentActions.UPDATE_COMMENT_FULFILLED:
            if (action.payload !== null) {
                const element = state.find((n) => n.id === action.payload.id);
                if (element) {
                    return state.map((item: IComment) => {
                        if (item.id === action.payload.id) {
                            return action.payload;
                        }
                        return item;
                    });
                }
            }
            return state;
        case CommentActions.DELETE_COMMENT_FULFILLED:
            if (action.payload !== null) {
                return state.filter((n) => n.id !== action.payload);
            }
            return state;
        default:
            return state;
    }
};

const riskComments = (
    state: ICommentState['riskComments'] = INITIAL_STATE.riskComments,
    action: IAppAction
) => {
    switch (action.type) {
        case CommentActions.LOAD_RISK_COMMENTS_FULFILLED:
            return action.payload ? action.payload : state;
        case CommentActions.CREATE_RISK_COMMENT_FULFILLED:
            if (action.payload !== null) {
                return [...state, action.payload];
            }
            return state;
        case CommentActions.UPDATE_COMMENT_FULFILLED:
            if (action.payload !== null) {
                const element = state.find((n) => n.id === action.payload.id);
                if (element) {
                    return state.map((item: IComment) => {
                        if (item.id === action.payload.id) {
                            return action.payload;
                        }
                        return item;
                    });
                }
            }
            return state;
        case CommentActions.DELETE_COMMENT_FULFILLED:
            if (action.payload !== null) {
                return state.filter((n) => n.id !== action.payload);
            }
            return state;
        default:
            return state;
    }
};

const actionComments = (
    state: ICommentState['actionComments'] = INITIAL_STATE.actionComments,
    action: IAppAction
) => {
    switch (action.type) {
        case CommentActions.LOAD_ACTION_COMMENTS_FULFILLED:
            return action.payload ? action.payload : state;
        case CommentActions.CREATE_ACTION_COMMENT_FULFILLED:
            if (action.payload !== null) {
                return [...state, action.payload];
            }
            return state;
        case CommentActions.UPDATE_COMMENT_FULFILLED:
            if (action.payload !== null) {
                const element = state.find((n) => n.id === action.payload.id);
                if (element) {
                    return state.map((item: IComment) => {
                        if (item.id === action.payload.id) {
                            return action.payload;
                        }
                        return item;
                    });
                }
            }
            return state;
        case CommentActions.DELETE_COMMENT_FULFILLED:
            if (action.payload !== null) {
                return state.filter((n) => n.id !== action.payload);
            }
            return state;
        default:
            return state;
    }
};

const message = (state: ICommentState['message'] = INITIAL_STATE.message, action: IAppAction) => {
    switch (action.type) {
        case CommentActions.SET_MESSAGE:
            return action.payload;
        case CommentActions.CREATE_COMMENT_FULFILLED:
            return action.payload ? '' : state;
        case LOCATION_CHANGE:
            return '';
        default:
            return state;
    }
};
const editMessage = (
    state: ICommentState['editMessage'] = INITIAL_STATE.message,
    action: IAppAction
) => {
    switch (action.type) {
        case CommentActions.SET_EDIT_MESSAGE:
            return action.payload;
        case CommentActions.UPDATE_COMMENT_FULFILLED:
            return action.payload ? '' : state;
        case LOCATION_CHANGE:
            return '';
        default:
            return state;
    }
};

const messageEditId = (
    state: ICommentState['messageEditId'] = INITIAL_STATE.message,
    action: IAppAction
) => {
    switch (action.type) {
        case CommentActions.SET_EDIT_ID:
            return action.payload;
        case CommentActions.UPDATE_COMMENT_FULFILLED:
            return action.payload ? '' : state;
        case LOCATION_CHANGE:
            return '';
        default:
            return state;
    }
};

export const commentReducer = combineReducers<ICommentState>({
    isLoading,
    isSaving,
    comments,
    riskComments,
    actionComments,
    message,
    editMessage,
    messageEditId,
});
