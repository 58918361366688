import { IAppAction } from './../app-action';
import { ISkill } from 'models';
// import { ISkillFilters } from 'models/skill-filters';

export enum SkillActions {
    LOAD_SKILLS = '@@skill/LOAD_SKILLS',
    LOAD_SKILLS_FULFILLED = '@@skill/LOAD_SKILLS_FULFILLED',
    LOAD_SKILLS_REJECTED = '@@skill/LOAD_SKILLS_REJECTED',

    LOAD_SKILL = '@@skill/LOAD_SKILL',
    LOAD_SKILL_FULFILLED = '@@skill/LOAD_SKILL_FULFILLED',
    LOAD_SKILL_REJECTED = '@@skill/LOAD_SKILL_REJECTED',

    CREATE_SKILL = '@@skill/CREATE_SKILL',
    CREATE_SKILL_FULFILLED = '@@skill/CREATE_SKILL_FULFILLED',
    CREATE_SKILL_REJECTED = '@@skill/CREATE_SKILL_REJECTED',

    UPDATE_SKILL = '@@skill/UPDATE_SKILL',
    UPDATE_SKILL_FULFILLED = '@@skill/UPDATE_SKILL_FULFILLED',
    UPDATE_SKILL_REJECTED = '@@skill/UPDATE_SKILL_REJECTED',

    SORT_SKILLS = '@@skill/SORT_SKILLS',
}

export const loadSkills = (qualificationCardType: string): IAppAction => ({
    type: SkillActions.LOAD_SKILLS,
    payload: { qualificationCardType },
});

export const createSkill = (skill: ISkill): IAppAction => ({
    type: SkillActions.CREATE_SKILL,
    payload: skill,
});

export const updateSkill = (skill: ISkill): IAppAction => ({
    type: SkillActions.UPDATE_SKILL,
    payload: skill,
});

export const onSortSkills = (key: Extract<keyof ISkill, string>, sortAscending: boolean) => ({
    type: SkillActions.SORT_SKILLS,
    payload: { key, sortAscending },
});
