import { SiteRelationshipsActions, SiteRelationshipsActionTypes } from 'actions/site-relationships-actions';
import { IPerson } from 'models/person';
import { ISiteProfile } from 'models/site-profile';

export default {
    onUnlink: (site: ISiteProfile, person: IPerson): SiteRelationshipsActionTypes => ({
        type: SiteRelationshipsActions.UNLINK_PERSON,
        payload: {
            site,
            person
        }
    })
};
