import * as React from 'react';
import './Card.scss';

export interface IProps {
    title: string;
}

export const CardSubheader: React.FC<IProps> = (props) => {
    return (
        <header className="card-subheader">
            <div className="card-subheader-title">
                <span className="title-area">
                    <div className="media-content">
                        <p className="title is-fullwidth">
                            <span>{props.title}</span>
                        </p>
                    </div>
                </span>
            </div>
        </header>
    );
};
