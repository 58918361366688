import { connect } from 'react-redux';

import actions from './actions';
import { RiskUpsertModal as view } from 'routes/risks/views/riskUpsertModal/RiskUpsertModal';
import selector from 'routes/risks/views/riskUpsertModal//selector';

export const RiskUpsertModal = connect(
    selector,
    actions
)(view);
