import classnames from 'classnames';
import * as React from 'react';

import './ModalCard.scss';

export interface IProps {
    modalId: string;
    isShown: boolean;
    className?: string;
}

export const ModalCard: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const css = classnames(
        'modal',
        {
            ['is-active']: props.isShown
        },
        props.className
    );

    if (!props.isShown) {
        return null;
    }

    return (
        <div
            className={css}
            role="dialog"
            aria-labelledby={props.modalId + '-modal-dialog-title'}
            aria-describedby={props.modalId + '-modal-dialog-desc'}
        >
            <div className="modal-background" />
            <div className="modal-card">
                {props.children}
            </div>
        </div>
    );
};
