import { compact } from 'lodash';
import { createSelector } from 'reselect';

import { ITypeaheadItem } from 'components/typeahead';
import { IPerson } from 'models/person';
import { ISiteRelationshipsRootState } from 'reducers/siteRelationships';
//import { IPersonSelectorState } from 'reducers/siteRelationships/person-selector';

const getPersonSelectorState = (state: ISiteRelationshipsRootState) => state.siteRelationships.personSelector;

const getIsLoading = createSelector(
    getPersonSelectorState,
    (state) => state.isLoading
);

const getInputValue = createSelector(
    getPersonSelectorState,
    (state) => state.searchValue
);

const getSiteDisplayText = (person: IPerson) => compact([
    person.firstName,
    person.lastName
]).join(' ');

const getValues = createSelector(
    [getPersonSelectorState],
    (state) => state.people.map<ITypeaheadItem<IPerson>>((person) => ({
        text: getSiteDisplayText(person),
        value: person
    }))
);

const getPlaceholder = () => 'Search';

export default createSelector(
    [getInputValue, getIsLoading, getValues, getPlaceholder],
    (inputValue, loading, values, placeholder) => ({
        inputValue,
        loading,
        values,
        placeholder
    })
);
