import { loadSitePersons } from 'actions/actions-v2/person-action-v2';
import { onLoadPeopleLinkedToSite } from 'actions/app-actions';
import {
    loadCermProcessOwner,
    createCermProcessOwner,
    updateCermProcessOwner,
    exportSiteUsers,
} from 'actions/actions-v2/cerm-process-owner-action-v2';

export default {
    loadSitePersons,
    loadCermProcessOwner,
    createCermProcessOwner,
    updateCermProcessOwner,
    exportSiteUsers,
    onLoadPeopleLinkedToSite,
};
