import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { NavActions } from 'actions/actions-v2/nav-actions-v2';
import { IAppState } from 'reducers/app';

const controllerName = 'nav';

const getAppData = {
    action: NavActions.LOAD_APP_DATA,
    actionFulfilled: NavActions.LOAD_APP_DATA_FULFILLED,
    actionRejected: NavActions.LOAD_APP_DATA_REJECTED,
} as IActionType;

const getAppSettings = {
    action: NavActions.LOAD_APP_SETTINGS,
    actionFulfilled: NavActions.LOAD_APP_SETTINGS_FULFILLED,
    actionRejected: NavActions.LOAD_APP_SETTINGS_REJECTED,
} as IActionType;

const loadAppDataEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getAppData).get<IAppState>(`/api/${controllerName}`);

const loadAppSettingsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getAppSettings).get<IAppState>(`/api/${controllerName}/AppSettings`);

export const navEpicsV2 = combineEpics(loadAppDataEpic, loadAppSettingsEpic);
