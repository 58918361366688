import * as React from 'react';
import { Loader } from 'components/loader';
import { IClient } from '../../../../models/client';
import { RouteUrl } from '../../routes';
import './client-list.scss';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconType } from 'routes/material-icon/material-icon-type';
import useTranslate from 'translations/translation-utils';

export interface IClientListStateProps {
    items: IClient[];
    isLoading: boolean;
    sortColumn: string;
    sortAscending: boolean;
}

export interface IClientListDispatchProps {
    onChangeRoute: (url: string) => void;
    onSort: (sortColumn: string, sortAscending: boolean) => void;
    onToggleExpanded: (id: string) => void;
}

interface IActionListProps extends IClientListStateProps, IClientListDispatchProps { }

export const ClientList: React.FC<IActionListProps> = (props) => {
    const translate = useTranslate();

    const getClientItem = (
        onChangeRoute: (url: string) => void,
        client: IClient
    ) => (
        <div key={client.id} className="QuantumTable">
            <div className="columns">
                <div className="column is-3">
                    {client.name}
                </div>
                <div className="column is-2">
                    {client.industry}
                </div>
                <div className="column is-2">
                    {client.clientCode}
                </div>
                <div className="column is-2">
                    {client.iAuditorAccountName}
                </div>
                <div className="column is-2">
                    {client.sitesCount == 0 ? '' : client.sitesCount}
                </div>
                <div className="column is-1">
                    <button
                        onClick={onChangeRoute.bind(this, `${RouteUrl.ClientUpsert}/${client.id}`)}
                        className="button button is-medium is-primary is-pulled-left"
                    >
                        {translate('ClientListPage.Button.Label.Edit')}
                    </button>
                </div>
            </div>
        </div>
    );

    const getGridHeader = (label: string, fieldName: string, props: IActionListProps) => {
        const onSort = () => props.onSort(
            fieldName,
            fieldName === props.sortColumn
                ? !props.sortAscending
                : true
        );

        if (props.sortColumn === fieldName) {
            const icon = props.sortAscending
                ? MaterialIconType.AngleUp
                : MaterialIconType.AngleDown;

            return (
                <a onClick={onSort}>
                    {label}
                    < MaterialIcons type={icon} className="sort-indicator" />
                </a>
            );
        }

        return (
            <a onClick={onSort}>{label}</a>
        );
    };

    return (
        <Loader className="ClientList" loading={props.isLoading}>
            <div className={`grid-headers columns ${props.isLoading ? 'is-loading' : ''}`}>
                <div className="column is-3">
                    {getGridHeader(translate('ClientListPage.Table.Column.ClientName'), 'name', props)}
                </div>
                <div className="column is-2">
                    {getGridHeader(translate('ClientListPage.Table.Column.ClientIndustry'), 'industry', props)}
                </div>
                <div className="column is-2">
                    {getGridHeader(translate('ClientListPage.Table.Column.MyDataClientCode'), 'clientCode', props)}
                </div>
                <div className="column is-2">
                    {getGridHeader(translate('ClientListPage.Table.Column.iAuditor'), 'iAuditorAccountName', props)}
                </div>
                <div className="column is-2">
                    {getGridHeader(translate('ClientListPage.Table.Column.NumberOfSites'), 'sitesCount', props)}
                </div>
                <div className="column is-1">
                    <div className="GridSortLink">
                        {translate('ClientListPage.Table.Column.Action')}
                    </div>
                </div>
            </div>
            {props.items && props.items.map(
                (action) => getClientItem(
                    props.onChangeRoute,
                    action,
                )
            )}
        </Loader>
    )
};
