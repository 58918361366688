import * as React from 'react';
import { FormikProps } from 'formik';
import { RouteComponentProps } from 'react-router';
import { onRouteChange } from 'actions/app-actions';
import { Select } from 'components/select';
import { BulmaColor } from 'enums/BulmaColor';
import { IRiskTemplate } from 'models/question-set';
import {
    canSubmit,
    formikFieldCssByState,
    getKeyed,
    orderDropdownByKey,
    scrollToFirstValidationError,
} from 'utils/form-utils';
import { Control, Field, Help, Input } from '../../../../components/form';
import { BulmaSize } from '../../../../enums/BulmaSize';
import { ICermProcessStep } from '../../../../models/cerm-process-step';
import { IKeyValue } from '../../../../models/key-value';
import { RouteUrlProcessStep, RouteUrlCermLinks } from '../../routes';
import { IUpsertCermProcessStepPageRouteParams } from '../upsert-cerm-process-step-page';
import { Button } from 'components/v2/components';
import { isNil, isEmpty } from 'lodash';
import { ISiteProfile } from 'models/site-profile';
import { ICommonLookupModel } from 'models/common-lookup-model';
import { RiskCategory } from 'models/risk-category';
import * as RiskStatus from 'models/risk-statuses';
import useTranslate from 'translations/translation-utils';
import { IOperatingPlatform } from 'models/operating-platform-model';
import { getTitlePrefixGeneric } from 'models/cerm/cerm-assessment-result';

export interface IProps extends RouteComponentProps<IUpsertCermProcessStepPageRouteParams> {
    lookupRiskCategories: RiskCategory[];
    lookupRiskStatus: Array<IKeyValue<string>>;
    lookupRiskLikelihoods: Array<IKeyValue<string>>;
    lookupRiskImpacts: Array<IKeyValue<string>>;
    formikBag: FormikProps<Partial<ICermProcessStep>>;
    site: ISiteProfile;
    isSubmitting: boolean;
    setErrorDialogVisible: (isDialogVisible: boolean) => void;
    operatingPlatforms: IOperatingPlatform[];
}

export const RiskTemplateForm: React.FC<IProps> = ({
    lookupRiskCategories,
    lookupRiskStatus,
    lookupRiskLikelihoods,
    lookupRiskImpacts,
    formikBag,
    site,
    match,
    isSubmitting,
    setErrorDialogVisible,
    operatingPlatforms,
}) => {
    let titlePrefix;
    if (Array.isArray(operatingPlatforms) && operatingPlatforms.length > 0)
        titlePrefix = getTitlePrefixGeneric(match.params.operatingPlatform, operatingPlatforms);
    const translate = useTranslate();
    function value<T extends Extract<keyof IRiskTemplate, string>>(name: T) {
        const val = getKeyed(formikBag, 'values', field(name));
        return !isNil(val) ? val : '';
    }

    if (isEmpty(formikBag.values.riskTemplate)) {
        const defaultValues: IRiskTemplate = {
            title: `${titlePrefix} ${formikBag.values.processNumber} ${formikBag.values.title}`,
            riskLikelihoodId: null,
            description: `${formikBag.values.title} does not meet expected outcomes.`,
            riskImpactId: '1',
            riskCategoryId: 'Operational',
            riskSubCategoryId: 'PI',
            riskStatusId: RiskStatus.OPEN_AND_PENDING_REVIEW,
            reviewDays: 120,
        };

        switch (formikBag.values.priority) {
            case '1':
                defaultValues.riskImpactId = '1';
                defaultValues.reviewDays = 120;
                break;
            case '3':
                defaultValues.riskImpactId = '2';
                defaultValues.reviewDays = 90;
                break;
            case '9':
                defaultValues.riskImpactId = '3';
                defaultValues.reviewDays = 60;
                break;
            case '27':
                defaultValues.riskImpactId = '4';
                defaultValues.reviewDays = 30;
                break;
        }
        formikBag.setFieldValue('riskTemplate', defaultValues);
        return <></>;
    }

    const field = (name: Extract<keyof IRiskTemplate, string>) => `riskTemplate.${name}`;
    const getErrors = (name: Extract<keyof IRiskTemplate, string>) =>
        getKeyed(formikBag, 'errors', field(name));
    const isTouched = (name: Extract<keyof IRiskTemplate, string>) =>
        getKeyed(formikBag, 'touched', field(name));
    const getFieldCss = (name: Extract<keyof IRiskTemplate, string>, otherCss?: string) =>
        formikFieldCssByState(Boolean(getErrors(name)), isTouched(name), otherCss);

    const getHelpByKey = (name: Extract<keyof IRiskTemplate, string>) => (
        <Help isShown={formikBag.submitCount > 0 || isTouched(name)} bulmaColor={BulmaColor.Danger}>
            {getErrors(name)}
        </Help>
    );

    const onSaveAsDraft: React.MouseEventHandler<HTMLButtonElement> = () => {
        validateForm(async () => {
            await formikBag.setFieldValue('status', 'Draft');
            formikBag.submitForm();
        });
    };

    const onPublish: React.MouseEventHandler<HTMLButtonElement> = () => {
        validateForm(async () => {
            await formikBag.setFieldValue('status', 'Published');
            formikBag.submitForm();
        });
    };

    const validateForm = callback => {
        formikBag.validateForm().then(errors => {
            if (!Object.keys(errors).some(e => e.length > 0)) {
                callback();
            } else {
                formikBag.submitForm();
                scrollToFirstValidationError();
                setErrorDialogVisible(true);
            }
        });
    };

    const validateWithoutField = callback => {
        formikBag.validateForm().then(errors => {
            if (!Object.keys(errors).includes('riskTemplate')) {
                callback();
            } else {
                formikBag.submitForm();
                scrollToFirstValidationError();
            }
        });
    };

    const onNaviagetToNext: React.MouseEventHandler<HTMLButtonElement> = () => {
        validateWithoutField(() => {
            onRouteChange(RouteUrlCermLinks(match.params.operatingPlatform, formikBag.values.id));
        });
    };

    const riskCategories = lookupRiskCategories.filter(
        x => !x.operatingPlatform || x.operatingPlatform === site.operatingPlatform
    );

    const subCategories: ICommonLookupModel[] = !!value('riskCategoryId')
        ? riskCategories
            .find(x => x.key === value('riskCategoryId'))
            ?.subCategory?.filter(
                x => !x.operatingPlatform || x.operatingPlatform === site.operatingPlatform
            ) ?? []
        : [];

    const onNavigateBack: React.MouseEventHandler<HTMLButtonElement> = () => {
        validateWithoutField(() => {
            onRouteChange(RouteUrlProcessStep(match.params.operatingPlatform, formikBag.values.id));
        });
    };

    const onHandleChange: React.FormEventHandler<HTMLButtonElement> = (e: any) => {
        const val = Object.assign({}, e);
        if (val.currentTarget.value.length === 0) {
            formikBag.setFieldValue(field('reviewDays'), '0');
            val.currentTarget.value = '0';
        }
        formikBag.handleChange(val);
    };

    const handleCategoryChange = (e: any) => {
        formikBag.setFieldValue(field('riskSubCategoryId'), '');
        formikBag.handleChange(e);
    };

    return (
        <>
            <Field
                isHorizontal={true}
                htmlFor={field('title')}
                label={translate('RiskDetailsPage.label.title')}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <Input
                            htmlFor={field('title')}
                            name={field('title')}
                            aria-required="true"
                            placeholder={translate('RiskForm.Lable.2')}
                            type="text"
                            className={getFieldCss('title')}
                            value={value('title')}
                            onChange={formikBag.handleChange}
                            onBlur={event =>
                                formikBag.setFieldValue(
                                    event.target.name,
                                    event.target.value.trim()
                                )
                            }
                        />
                    </Control>
                    {getHelpByKey('title')}
                </Field>
            </Field>

            <Field
                isHorizontal={true}
                htmlFor={field('description')}
                label={translate('RiskDetailsPage.label.Description')}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <textarea
                            id={field('description')}
                            name={field('description')}
                            className={getFieldCss('description', 'textarea')}
                            placeholder={translate('ActionDetails.Lable.3')}
                            value={formikBag.values.riskTemplate.description}
                            onBlur={formikBag.handleBlur}
                            onChange={formikBag.handleChange}
                        />
                    </Control>
                    {getHelpByKey('description')}
                </Field>
            </Field>
            <Field
                isHorizontal={true}
                htmlFor={field('riskLikelihoodId')}
                label={translate('RiskDetailsPage.label.Likelihood')}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <Select
                            id={field('riskLikelihoodId')}
                            name={field('riskLikelihoodId')}
                            aria-required="true"
                            options={orderDropdownByKey(lookupRiskLikelihoods)}
                            className={getFieldCss('riskLikelihoodId')}
                            value={formikBag.values.riskTemplate.riskLikelihoodId}
                            onChange={formikBag.handleChange}
                            onBlur={formikBag.handleBlur}
                            emptyOptionsValue={translate(
                                'RiskActionTab.Placeholder.emptyOptionsValue'
                            )}
                        />
                    </Control>
                    {getHelpByKey('riskLikelihoodId')}
                </Field>
            </Field>

            <Field
                isHorizontal={true}
                htmlFor={field('riskImpactId')}
                label={translate('RiskDetailsPage.label.impact')}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <Select
                            id={field('riskImpactId')}
                            name={field('riskImpactId')}
                            aria-required="true"
                            options={orderDropdownByKey(lookupRiskImpacts)}
                            className={getFieldCss('riskImpactId')}
                            value={formikBag.values.riskTemplate.riskImpactId}
                            onChange={formikBag.handleChange}
                            onBlur={formikBag.handleBlur}
                            emptyOptionsValue={translate(
                                'RiskActionTab.Placeholder.emptyOptionsValue'
                            )}
                        />
                    </Control>
                    {getHelpByKey('riskImpactId')}
                </Field>
            </Field>

            <Field
                isHorizontal={true}
                htmlFor={field('riskCategoryId')}
                label={translate('RiskDetailsPage.label.Category')}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <Select
                            id={field('riskCategoryId')}
                            name={field('riskCategoryId')}
                            aria-required="true"
                            options={orderDropdownByKey(riskCategories)}
                            className={getFieldCss('riskCategoryId')}
                            value={formikBag.values.riskTemplate.riskCategoryId}
                            onChange={handleCategoryChange}
                            onBlur={formikBag.handleBlur}
                            emptyOptionsValue={translate(
                                'RiskActionTab.Placeholder.emptyOptionsValue'
                            )}
                        />
                    </Control>
                    {getHelpByKey('riskCategoryId')}
                </Field>
            </Field>
            <Field
                isHorizontal={true}
                htmlFor={field('riskSubCategoryId')}
                label={translate('UpsertCermProcessStep.Views.RiskTemplateForm.SubCategory')}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <Select
                            id={field('riskSubCategoryId')}
                            name={field('riskSubCategoryId')}
                            aria-required="true"
                            options={orderDropdownByKey(subCategories)}
                            className={getFieldCss('riskSubCategoryId')}
                            value={formikBag.values.riskTemplate.riskSubCategoryId}
                            onChange={formikBag.handleChange}
                            onBlur={formikBag.handleBlur}
                            emptyOptionsValue={translate(
                                'RiskActionTab.Placeholder.emptyOptionsValue'
                            )}
                        />
                    </Control>
                    {getHelpByKey('riskSubCategoryId')}
                </Field>
            </Field>

            <Field
                isHorizontal={true}
                htmlFor={field('riskStatusId')}
                label={translate('RiskRegisterPage.RiskFilter.Title.Status')}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <Select
                            id={field('riskStatusId')}
                            name={field('riskStatusId')}
                            aria-required="true"
                            options={orderDropdownByKey(lookupRiskStatus)}
                            className={getFieldCss('riskStatusId')}
                            value={formikBag.values.riskTemplate.riskStatusId}
                            onChange={formikBag.handleChange}
                            onBlur={formikBag.handleBlur}
                            emptyOptionsValue={translate(
                                'RiskActionTab.Placeholder.emptyOptionsValue'
                            )}
                        />
                    </Control>
                </Field>
            </Field>

            <Field
                isHorizontal={true}
                htmlFor={field('reviewDays')}
                label={translate('UpsertCermProcessStep.Views.RiskTemplateForm.ReviewDays')}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <Input
                            htmlFor={field('reviewDays')}
                            name={field('reviewDays')}
                            aria-required="true"
                            placeholder={translate('IncidentRiskActions.Label.Review')}
                            type="number"
                            className={getFieldCss('reviewDays')}
                            value={value('reviewDays')}
                            onChange={onHandleChange}
                            onBlur={event =>
                                formikBag.setFieldValue(
                                    event.target.name,
                                    event.target.value.trim()
                                )
                            }
                        />
                    </Control>
                </Field>
            </Field>
            <div className="action-groups">
                <div className="action-group">
                    <Button buttonType="cancel" onClick={onNavigateBack}>
                        {translate('Globals.Label.Back')}
                    </Button>
                </div>
                <div className="action-group">
                    <Button disabled={!canSubmit} onClick={onSaveAsDraft} isLoading={isSubmitting}>
                        {translate('QuestionForm.Lable.8')}
                    </Button>
                    {formikBag.values.expectedOutcomes &&
                        formikBag.values.expectedOutcomes.length > 0 && (
                            <Button onClick={onPublish} isLoading={isSubmitting}>
                                {translate('Button.Captions.Publish')}
                            </Button>
                        )}
                    <Button onClick={onNaviagetToNext}>
                        {translate('SchedulerWeek.Button.NextDay.buttonText')}
                    </Button>
                </div>
            </div>
        </>
    );
};
