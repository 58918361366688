import { combineReducers } from 'redux';
import { IAppAction } from 'actions/app-action';
import { s2ab } from 'utils/export-utils';
import { EngineerActions } from 'actions/actions-v2/engineer-action-v2';
import { getDefaultSkillsState } from 'state';
import { ISkillsState } from 'state/skills-state';
import { SkillActions, SiteSkillsActions, SkillCategoryActions } from 'actions/actions-v2';
import { IAppState, APP_USER_INITIAL_STATE } from '../app';
import { AppActions } from 'actions/app-actions';
import { ISiteSkills } from 'models';
import saveAs from 'file-saver';

const skills = (
    state: ISkillsState['skills'] = getDefaultSkillsState().skills,
    action: IAppAction
) => {
    switch (action.type) {
        case SkillActions.LOAD_SKILLS_FULFILLED:
            return action.payload;
        case SkillActions.LOAD_SKILLS_REJECTED:
        case SkillActions.LOAD_SKILLS:
            return [];
        default:
            return state;
    }
};

const isLoading = (
    state: ISkillsState['isLoading'] = getDefaultSkillsState().isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case SkillActions.LOAD_SKILLS:
        case SiteSkillsActions.UPDATE_SITE_SKILLS:
        case SiteSkillsActions.LOAD_SITE_SKILLS:
            return true;
        case SkillActions.LOAD_SKILLS_FULFILLED:
        case SkillActions.LOAD_SKILLS_REJECTED:
        case SiteSkillsActions.UPDATE_SITE_SKILLS_FULFILLED:
        case SiteSkillsActions.UPDATE_SITE_SKILLS_REJECTED:
        case SiteSkillsActions.LOAD_SITE_SKILLS_FULFILLED:
        case SiteSkillsActions.LOAD_SITE_SKILLS_REJECTED:
            return false;
        default:
            return state;
    }
};

const siteSkills = (
    state: ISkillsState['siteSkills'] = getDefaultSkillsState().siteSkills,
    action: IAppAction
) => {
    switch (action.type) {
        case SiteSkillsActions.LOAD_SITE_SKILLS_FULFILLED:
            return action.payload.length > 0 ? Object.values(action.payload)[0] : null;
        case SiteSkillsActions.CREATE_SITE_SKILLS_FULFILLED:
        case SiteSkillsActions.UPDATE_SITE_SKILLS_FULFILLED:
            return action.payload ? action.payload : state;
        case SiteSkillsActions.LOAD_SITE_SKILLS:
        case SiteSkillsActions.LOAD_SITE_SKILLS_REJECTED:
            return null;
        default:
            return state;
    }
};

const siteSkillsSaved = (
    state: ISkillsState['siteSkillsSaved'] = getDefaultSkillsState().siteSkillsSaved,
    action: IAppAction
) => {
    switch (action.type) {
        case SiteSkillsActions.CREATE_SITE_SKILLS:
        case SiteSkillsActions.CREATE_SITE_SKILLS_REJECTED:
        case SiteSkillsActions.UPDATE_SITE_SKILLS:
        case SiteSkillsActions.UPDATE_SITE_SKILLS_REJECTED:
        case SiteSkillsActions.RESET_SAVED_SITE_SKILL_CARD_STATE:
            return false;
        case SiteSkillsActions.CREATE_SITE_SKILLS_FULFILLED:
        case SiteSkillsActions.UPDATE_SITE_SKILLS_FULFILLED:
            return true;
        default:
            return state;
    }
};

const isExportingSkills = (
    state: ISkillsState['isExportingSkills'] = getDefaultSkillsState().isExportingSkills,
    action: IAppAction
) => {
    switch (action.type) {
        case EngineerActions.DOWNLOAD_ENGINEER_SKILLS:
            return true;
        case EngineerActions.DOWNLOAD_ENGINEER_SKILLS_FULFILLED:
            const blob = new Blob([s2ab(atob(action.payload.response.fileContents))], {
                type: 'application/excel',
            });
            saveAs(blob, 'EngineerSkills.xlsx');
            return false;
        case EngineerActions.DOWNLOAD_ENGINEER_SKILLS_REJECTED:
        case EngineerActions.DOWNLOAD_ENGINEER_SKILLS_CANCELLED:
            return false;
        default:
            return state;
    }
};

const isSaving = (
    state: ISkillsState['isSaving'] = getDefaultSkillsState().isSaving,
    action: IAppAction
) => {
    switch (action.type) {
        case SkillActions.UPDATE_SKILL_FULFILLED:
        case SkillActions.UPDATE_SKILL_REJECTED:
            return false;
        case SkillActions.UPDATE_SKILL:
            return true;
        default:
            return state;
    }
};

const expandedCategories = (
    state: ISkillsState['expandedCategories'] = getDefaultSkillsState().expandedCategories,
    action: IAppAction
) => {
    switch (action.type) {
        case EngineerActions.TOGGLE_ENGINEER_SKILLS_CATEGORY:
            const id = action.payload.id;
            if (state.includes(id)) {
                return state.filter((x) => x !== id);
            }
            return [...state, id];
        //case SkillCategoryActions.LOAD_SKILL_CATEGORIES_FULFILLED:
        case SkillCategoryActions.LOAD_OBSOLETE_SKILL_CATEGORY_FULFILLED:
            if (action.payload) {
                return [].concat(action.payload.map((x) => x.id));
            }
            return state;

        case SiteSkillsActions.LOAD_SITE_SKILLS_FULFILLED:
            if (action.payload.length > 0) {
                const siteSkills = Object.values(action.payload)[0] as ISiteSkills;
                const localCategories = siteSkills.localSkillCategories.map((item) => item.id);
                return state
                    .concat(localCategories)
                    .filter((item) => !siteSkills.turnedOffGlobalSkillCategories.includes(item));
            }
            return state;
        case EngineerActions.ADD_ENGINEER_SKILLS_CATEGORY:
            return state.concat(action.payload.id);
        case EngineerActions.RESET_ENGINEER_EXPANDED_CATEGORIES:
            return getDefaultSkillsState().expandedCategories;
        default:
            return state;
    }
};

const siteId = (state: IAppState['siteId'] = APP_USER_INITIAL_STATE.siteId, action: any) => {
    switch (action.type) {
        case AppActions.CHANGE_SITE:
            return action.payload.siteId;
        default:
            return state;
    }
};

export const skillsReducer = combineReducers<ISkillsState>({
    isLoading,
    skills,
    isExportingSkills,
    isSaving,
    expandedCategories,
    siteId,
    siteSkills,
    siteSkillsSaved
});
