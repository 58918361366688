import * as React from 'react';
import { IMasterProcessStep } from 'models/master-process-step/master-process-step';
import { isEmpty } from 'lodash';

export const useMasterProcessSteps = (
    masterProcessSteps: IMasterProcessStep[],
    loadMasterProcessSteps: (operatingPlatform: string) => void,
    operatingPlatform: string
) => {
    React.useEffect(() => {
        if (isEmpty(masterProcessSteps)) {
            loadMasterProcessSteps(operatingPlatform);
        }
    }, [loadMasterProcessSteps,operatingPlatform]);
};
