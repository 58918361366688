export const RouteUrlSite = (siteId?: string) => {
    return siteId && siteId !== 'undefined'
        ? `/Site/Upsert/${siteId}/Form/SiteDetails`
        : `/Site/Upsert/Form/SiteDetails`;
};

export const RouteUrlSiteSummary = (siteId?: string) => {
    return siteId && siteId !== 'undefined'
        ? `/Site/Upsert/${siteId}/Form/SiteSummary`
        : `/Site/Upsert/Form/SiteSummary`;
};

export const RouteUrlUsers = (siteId?: string) => {
    return siteId && siteId !== 'undefined'
        ? `/Site/Upsert/${siteId}/Form/Users`
        : `/Site/Upsert/Form/Users`;
};

export const RouteUrlBuilding = (siteId?: string) => {
    return siteId && siteId !== 'undefined'
        ? `/Site/Upsert/${siteId}/Form/Buildings`
        : `/Site/Upsert/Form/Buildings`;
};

export const RouteUrlQuestionnaire = (buildingId: string, siteId?: string) => {
    return siteId && siteId !== 'undefined'
        ? `/Site/Upsert/${siteId}/Form/Questionnaire?buildingIndex=${buildingId}`
        : `/Site/Upsert/Form/Questionnaire?buildingIndex=${buildingId}`;
};

export const RouteUrlEnergyBill = (buildingId: string, energyBillId: number, siteId?: string) => {
    return siteId && siteId !== 'undefined'
        ? `/Site/Upsert/${siteId}/Form/Building/${buildingId}/${energyBillId.toString()}`
        : `/Site/Upsert/Form/Building/${buildingId}/${energyBillId.toString()}`;
};
export const RouteUrlSystemIntegration = (siteId?: string) => {
    return `/Site/Upsert/${siteId}/Form/SystemIntegration`;
};

export const RouteUrlOperatingPlatform = (oP: string) => {
    return `/CermProcessSteps/${oP}`;
};

export const RouteQuestionSetsUrlOperatingPlatform = (OperatingPlatform: string) => {
    return `/QuestionSets/${OperatingPlatform}`;
}
export const RouteUrlRiskProfileOperatingPlatform = (operatingPlatform: string) => {
    return `/RiskProfileMasterQuestionnaire/${operatingPlatform}`;
};

export const RouteUrlShiftHandoverOperatingPlatform = (operatingPlatform: string) => {
    return `/shift-handover-new-admin/shift-handover-checklist/${operatingPlatform}`;
};