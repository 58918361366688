import { compact } from 'lodash';
import { createSelector } from 'reselect';

import { ITypeaheadItem } from 'components/typeahead';
import { ISiteProfile } from 'models/site-profile';
import { ISiteRelationshipsRootState } from 'reducers/siteRelationships';
//import { ISiteSelectorState } from 'reducers/siteRelationships/site-selector';

const getSiteSelectorState = (state: ISiteRelationshipsRootState) => state.siteRelationships.siteSelector;

const getIsLoading = createSelector(
    getSiteSelectorState,
    (state) => state.isLoading
);

const getInputValue = createSelector(
    getSiteSelectorState,
    (state) => state.searchValue
);

const getSiteDisplayText = (site: ISiteProfile) => compact([
    site.clientName && `(${site.clientName})`,
    site.name
]).join(' ');

const getValues = createSelector(
    [getSiteSelectorState],
    (state) => state.sites.map<ITypeaheadItem<ISiteProfile>>((site) => ({
        text: getSiteDisplayText(site),
        value: site
    }))
);

const getPlaceholder = () => 'Search for a site…';

export default createSelector(
    [getInputValue, getIsLoading, getValues, getPlaceholder],
    (inputValue, loading, values, placeholder) => ({
        inputValue,
        loading,
        values,
        placeholder
    })
);
