export const actionEnGB = `
<h1>What is Lorem Ipsum Action</h1>
<div>
Lorem Ipsum is simply dummy text of the printing and typesetting industry.
Lorem Ipsum has been the industry's standard dummy text ever since the
1500s, when an unknown printer took a galley of type and scrambled it to
make a type specimen book. with desktop publishing software like Aldus
PageMaker including versions of Lorem Ipsum.
<p> It has survived not only five centuries,</p>
<p>
    but also the leap into electronic typesetting, remaining essentially
    unchanged.
</p>
<p>
    It was popularised in the 1960s with the release of Letraset sheets
    containing Lorem Ipsum passages, and more recently
</p>
<a>Contrary to popular belief</a>
</div> `;
