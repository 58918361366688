import React from 'react';
import './progress-bar.scss';

interface IProps {
    model: IProgressBarModel[];
}
export interface IProgressBarModel {
    name: string;
    isActive: boolean;
}
export default class StepProgressBar extends React.PureComponent<IProps> {

    public render() {
        const widthPercent = this.calculateWidth(this.props.model.length);
        return (
            <ul className="progressbar">
                {this.props.model.map((m) => {
                    // tslint:disable-next-line: jsx-key
                   return <li style={{width: widthPercent}} className={m.isActive && 'active'}>{m.name}</li>;
                })}
            </ul>
        );
    }
    private calculateWidth(numb: number): string {
        if (numb > 0) {
            const width = 100 / numb;
            return width + '%';
        }
    }
}
