import * as React from 'react';

interface IIncidentCircleStatusProps {
    text: string;
    active: boolean;
}

export const IncidentCircleStatus: React.FC<IIncidentCircleStatusProps> = ({ text, active }) => {
    return (
        <div>
            <div className="circle-status-displayer">
                <div className={active ? `circle colour-green` : `circle colour-grey`}>
                    <div className="circle-container">
                        <div className="inner-description-two">{text}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
