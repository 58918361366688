import { combineReducers } from 'redux';
import { NavActions, NavActionTypes } from '../actions/nav-actions';
import { Menu } from '../components/nav/Nav';
import { IDropdownItem } from '../components/nav/NavDropdown';
import { ILanguageDropdownItem } from 'models/nav-lanuage';

export interface INavState {
    isHamburgerExpanded: boolean;
    expandedDropdownMenu?: Menu;
    isUserMenuExpanded: boolean;
    userMenuItems: IDropdownItem[];
    languageItem: ILanguageDropdownItem[];
}

const isHamburgerExpanded = (
    state: boolean = NAV_INITIAL_STATE.isHamburgerExpanded,
    action: NavActionTypes
) => {
    switch (action.type) {
        case NavActions.TOGGLE_NAV_EXPANDED:
            return !state;

        default:
            return state;
    }
};

const expandedDropdownMenu = (
    state: Menu = NAV_INITIAL_STATE.expandedDropdownMenu,
    action: NavActionTypes
) => {
    switch (action.type) {
        case NavActions.TOGGLE_EXPAND_DROPDOWN_MENU:
            if (action.payload.menu === state) {
                return null;
            }

            return action.payload.menu;

        case NavActions.TOGGLE_EXPAND_USER_MENU:
            // Close nav dropdown when opening masthead user menu
            return null;

        default:
            return state;
    }
};

const isUserMenuExpanded = (
    state: boolean = NAV_INITIAL_STATE.isUserMenuExpanded,
    action: NavActionTypes
) => {
    switch (action.type) {
        case NavActions.TOGGLE_EXPAND_USER_MENU:
            return action.payload.isExpanded;

        case NavActions.TOGGLE_EXPAND_DROPDOWN_MENU:
            // Always close user menu when toggling another nav menu
            return false;

        default:
            return state;
    }
};

const userMenuItems = (state = NAV_INITIAL_STATE.userMenuItems) => state;
const languageItem = (state = NAV_INITIAL_STATE.languageItem) => state;

export const NAV_INITIAL_STATE: INavState = {
    isHamburgerExpanded: false,
    expandedDropdownMenu: null,
    isUserMenuExpanded: false,

    userMenuItems: [
        { id: '1', text: 'ProfileMenu.Label.Profile', link: '/PersonProfile' },
        { id: '4', text: 'ProfileMenu.Label.ChangeLanguage', link: '/PersonProfile/ChangeLanguage' },
        { id: '6', text: 'ProfileMenu.Label.SignOut', link: '/AzureADB2C/Account/SignOut', isHref: true },
    ],
    languageItem: [
        { display: 'English', key: 'en-GB' },
        { display: '日本語', key: 'ja' },
        { display: 'Español', key: 'es' },
        // { display: '中文（简体)', key: 'zh-CN' }, // Chineese Simplified
        // { display: '中文 (傳統的)', key: 'zh-TW' }, // Chineese Traditional
        { display: 'Français', key: 'fr' },
        { display: 'Deutsch', key: 'de' },
        { display: 'Português', key: 'pt' },
        { display: 'Dutch', key: 'nl' },
    ],
};

export const navReducer = combineReducers<INavState>({
    isHamburgerExpanded,
    expandedDropdownMenu,
    isUserMenuExpanded,
    userMenuItems,
    languageItem,
});
