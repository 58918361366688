import { Dictionary } from 'lodash';
import { combineReducers } from 'redux';

import { RiskActions, RiskActionTypes } from '../../actions/risk-actions';
import { IRiskAttachmentCollection } from '../../models/risk-attachments';
import { getKey } from '../../utils/id-utils';

export interface IRisksAttachmentsState {
    byRisk: Dictionary<{
        isLoading: boolean;
        data?: IRiskAttachmentCollection[];
    }>;

    openModal: null | {
        id: string;
        siteId: string;
        isUploading: boolean;
    };
}

export const INITIAL_STATE: IRisksAttachmentsState = {
    byRisk: {},
    openModal: null
};

const byRisk = (
    state: IRisksAttachmentsState['byRisk'] = INITIAL_STATE.byRisk,
    action: RiskActionTypes
) => {
    switch (action.type) {
        case RiskActions.LOAD_ATTACHMENTS:
            return {
                ...state,
                [getKey(action.payload)]: {
                    isLoading: true
                }
            };

        case RiskActions.LOAD_ATTACHMENTS_CANCELLED:
            return {
                ...state,
                [getKey(action.payload)]: {
                    ...state[getKey(action.payload)],
                    isLoading: false
                }
            };

        case RiskActions.LOAD_ATTACHMENTS_FULFILLED:
            return {
                ...state,
                [getKey(action.payload)]: {
                    data: action.payload.data,
                    isLoading: false
                }
            };

        default:
            return state;
    }
};

const openModal = (
    state: IRisksAttachmentsState['openModal'] = INITIAL_STATE.openModal,
    action: RiskActionTypes
) => {
    switch (action.type) {
        case RiskActions.SHOW_ADD_ATTACHMENT_MODAL:
            return {
                ...action.payload,
                isUploading: false
            };

        case RiskActions.ADD_ATTACHMENT:
            return {
                ...state,
                isUploading: true
            };

        case RiskActions.ADD_ATTACHMENT_REJECTED:
            return {
                ...state,
                isUploading: false
            };

        case RiskActions.HIDE_ADD_ATTACHMENT_MODAL:
        case RiskActions.ADD_ATTACHMENT_CANCELLED:
        case RiskActions.ADD_ATTACHMENT_FULFILLED:
            return null;

        default:
            return state;
    }
};

export const reducer = combineReducers<IRisksAttachmentsState>({
    byRisk,
    openModal
});
