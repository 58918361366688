import { createSelector } from 'reselect';
import { getPermissions, getIsClient } from 'sharedSelectors';
import { getSites } from 'components/sideNavSiteSelector/selectors';

export default createSelector(
    getPermissions,
    getSites,
    getIsClient,
    (permissions, sites, isClient) => ({
        permissions,
        sites,
        isClient
    })
);
