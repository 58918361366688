import * as React from 'react';
import { Table, SortOrder, Button, TableHeaders } from 'components/v2/components';
import { IAction } from 'models/action';
import { IActionFilters } from 'models/action-filters';
import { ActionUpsertModal } from 'components/actionUpsertModal';
import { IKeyValue, IDeleteModel } from 'models';
import { IPersonLookup } from 'models/person-lookup';
import { UiPreferences } from 'utils/page-ui-preferences-utils';
import { sortArrayBy } from 'utils/sort-utils';
import { isEmpty } from 'lodash';
import { Loader } from 'components/loader';
import { IRelatedEntity, RelatedType } from 'models/related-entity';
import useTranslate from 'translations/translation-utils';

interface IProps {
    id: string;
    siteId: string;
    isLoading: boolean;
    actions: IAction[];
    lookups: {
        lookupActionCategories: Array<IKeyValue<string>>;
        lookupActionPriorities: Array<IKeyValue<string>>;
        lookupActionStatuses: Array<IKeyValue<string>>;
        lookupSites: Array<IKeyValue<string>>;
        lookupSitePeopleRoles: IPersonLookup[];
    };
    disableClosedAndCompleted?: boolean;
    isUpsertModalShown: boolean;
    upsertRequest: Partial<IAction>;
    hasReadWritePermission: boolean;
    loadActions: (filters: IActionFilters) => void;
    onShowUpsertActionModal: (siteId: string, userId: string) => void;
    onShowEditActionModal: (upsertItem: IAction) => void;
    createAction: (action: IAction) => void;
    updateAction: (action: IAction) => void;
    archiveAction: (model: IDeleteModel) => void;
    hideModal: () => void;
    loadPersonsLookup: (siteId: string) => void;
}

export const MockDrillReportActionsTab: React.FC<IProps> = ({
    id,
    siteId,
    isLoading,
    actions,
    loadActions,
    onShowUpsertActionModal,
    onShowEditActionModal,
    lookups,
    disableClosedAndCompleted,
    isUpsertModalShown,
    upsertRequest,
    hasReadWritePermission,
    createAction,
    updateAction,
    archiveAction,
    hideModal,
    loadPersonsLookup,
}) => {
    const translate = useTranslate();
    const [sortedActions, setSortActions] = React.useState<IAction[]>([]);
    const relatedEntity = {
        id,
        type: RelatedType.MockDrillReport,
    } as IRelatedEntity;

    React.useEffect(() => {
        if (isEmpty(lookups.lookupSitePeopleRoles)) {
            loadPersonsLookup(siteId);
        }
    }, [lookups.lookupSitePeopleRoles]);

    React.useEffect(() => {
        if (!siteId || !id) {
            return;
        }
        loadActions({
            siteId: [siteId],
            relatedEntityId: id,
            relatedEntityType: RelatedType.MockDrillReport,
        });
    }, [siteId, id]);

    React.useEffect(() => {
        if (!siteId || !actions) {
            return;
        }

        setSortActions(actions);
    }, [actions]);

    const onToggleHandler = (field: string, sortOrder: SortOrder) => {
        if (sortOrder === SortOrder.off) {
            setSortActions(sortedActions);
        }

        setSortActions(sortArrayBy(field, sortedActions, sortOrder === SortOrder.asc));
    };

    const onAddAction = () => {
        onShowUpsertActionModal(siteId, null);
    };

    const onHideModal = () => hideModal();

    const onSubmit = (item: IAction) => {
        if (item.id) {
            updateAction(item);
        } else {
            createAction({
                ...item,
                relatedEntity,
            });
        }
    };

    const openActionModal = (item: IAction) => {
        onShowEditActionModal(item);
    };

    const onArchive = (action: Partial<IAction>) => {
        archiveAction({
            id,
            shard: siteId,
            param: action.id,
        } as IDeleteModel);
    };

    const buildTable = () => {
        return isEmpty(sortedActions) ? (
            <p className="no-data">
                {translate('MockDrills.Message.4')}
            </p>
        ) : (
            <Table>
                <TableHeaders
                    headers={[
                        {
                            title: translate('RiskRegisterPage.RiskRegisterTable.Title.Title'),
                            sortField: 'title',
                        },
                        {
                            title: translate('RiskRegisterPage.RiskFilter.Title.Category'),
                            sortField: 'category',
                        },
                        {
                            title: translate('RiskRegisterPage.RiskFilter.Title.Status'),
                            sortField: 'status',
                        },
                        hasReadWritePermission && {
                            title: translate('CalendarEventPage.label.Assignedto'),
                            sortField: 'assignee',
                        },
                        {
                            title: translate('RiskActionTab.Label.DueDate'),
                            sortField: 'dueDate',
                        },
                    ]}
                    sortFieldUiPreference={UiPreferences.MockDrillReportPageActionListSortField}
                    sortOrderUiPreference={UiPreferences.MockDrillReportPageActionListSortOrder}
                    onToggle={onToggleHandler}
                />
                <tbody>
                    {sortedActions.map((item) => {
                        const trProps = hasReadWritePermission
                            ? { onClick: () => openActionModal(item), className: 'clickable' }
                            : null;
                        return (
                            <tr key={item.id} {...trProps}>
                                <td>{item.title}</td>
                                <td>{item.category}</td>
                                <td>{item.status}</td>
                                {hasReadWritePermission && <td>{item && item.assignee && item.assignee.displayLabel}</td>}

                                <td className="narrow no-wrap">{item.dueDate.split('T')[0]}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        );
    };

    return (
        <div className="tabs-container tabs-container-table-only tab-mock-drill-report-action">
            <Loader loading={isLoading}>
                {buildTable()}
                {hasReadWritePermission && (
                    <div className="buttons">
                        <Button onClick={onAddAction}>{translate('RiskActionTab.Button.Addaction')}</Button>
                    </div>
                )}
            </Loader>
            <ActionUpsertModal
                disableClosedAndCompleted={disableClosedAndCompleted}
                onClose={onHideModal}
                isShown={isUpsertModalShown}
                isLoading={isLoading}
                onSubmit={onSubmit}
                upsertActionRequest={upsertRequest}
                lookupActionCategories={lookups.lookupActionCategories}
                lookupActionPriorities={lookups.lookupActionPriorities}
                lookupActionStatuses={lookups.lookupActionStatuses}
                lookupSitePeopleRoles={lookups.lookupSitePeopleRoles}
                lookupSites={lookups.lookupSites}
                archiveButtonEnabled={false} // archive functionality is disabled in MVP
                onArchive={onArchive}
            />
        </div>
    );
};
