import { IAppAction } from '../app-action';
import {
    IMockDrillReport,
    ICreateMockDrillReport,
    ISetMockDrillReportParticipation
} from 'models/mock-drills';
import { IActionFilters } from 'models/action-filters';

export enum MockDrillReportActions {
    LOAD_REPORTS_SUMMARY = '@@MockDrillReport/LOAD_REPORTS_SUMMARY',
    LOAD_REPORTS_SUMMARY_FULFILLED = '@@MockDrillReport/LOAD_REPORTS_SUMMARY_FULFILLED',
    LOAD_REPORTS_SUMMARY_REJECTED = '@@MockDrillReport/LOAD_REPORTS_SUMMARY_REJECTED',

    LOAD = '@@MockDrillReport/LOAD',
    LOAD_FULFILLED = '@@MockDrillReport/LOAD_FULFILLED',
    LOAD_REJECTED = '@@MockDrillReport/LOAD_REJECTED',

    CREATE = '@@MockDrillReport/CREATE',
    CREATE_FULFILLED = '@@MockDrillReport/CREATE_FULFILLED',
    CREATE_REJECTED = '@@MockDrillReport/CREATE_REJECTED',

    UPDATE = '@@MockDrillReport/UPDATE',
    UPDATE_FULFILLED = '@@MockDrillReport/UPDATE_FULFILLED',
    UPDATE_REJECTED = '@@MockDrillReport/UPDATE_REJECTED',

    DELETE = '@@MockDrillReport/DELETE',
    DELETE_FULFILLED = '@@MockDrillReport/DELETE_FULFILLED',
    DELETE_REJECTED = '@@MockDrillReport/DELETE_REJECTED',

    EXPORT = '@@MockDrillReport/EXPORT',
    EXPORT_FULFILLED = '@@MockDrillReport/EXPORT_FULFILLED',
    EXPORT_REJECTED = '@@MockDrillReport/EXPORT_REJECTED',

    SET_PARTICIPATION = '@@MockDrillReport/SET_PARTICIPATION',
    SET_PARTICIPATION_FULFILLED = '@@MockDrillReport/SET_PARTICIPATION_FULFILLED',
    SET_PARTICIPATION_REJECTED = '@@MockDrillReport/SET_PARTICIPATION_REJECTED',

    LOAD_ACTIONS = '@@MockDrillReport/LOAD_ACTIONS',
    LOAD_ACTIONS_FULFILLED = '@@MockDrillReport/LOAD_ACTIONS_FULFILLED',
    LOAD_ACTIONS_REJECTED = '@@MockDrillReport/LOAD_ACTIONS_REJECTED',

    LOAD_HISTORY = '@@MockDrillReport/LOAD_HISTORY',
    LOAD_HISTORY_FULFILLED = '@@MockDrillReport/LOAD_HISTORY_FULFILLED',
    LOAD_HISTORY_REJECTED = '@@MockDrillReport/LOAD_HISTORY_REJECTED',

    CLEANUP = '@@MockDrillReport/CLEANUP',
    CLEANUP_LIST = '@@MockDrillReport/CLEANUP_LIST'
}

export const loadMockDrillReportsSummary = (
    mockDrillId: string,
    mockDrillType: string,
    siteId: string
): IAppAction => ({
    type: MockDrillReportActions.LOAD_REPORTS_SUMMARY,
    payload: {
        siteId,
        mockDrillId,
        mockDrillType
    }
});

export const loadMockDrillReport = (mockDrillId: string, siteId: string): IAppAction => ({
    type: MockDrillReportActions.LOAD,
    payload: {
        id: mockDrillId,
        shard: siteId,
        siteId
    }
});

export const createMockDrillReport = (model: ICreateMockDrillReport): IAppAction => ({
    type: MockDrillReportActions.CREATE,
    payload: model
});

export const updateMockDrillReport = (model: IMockDrillReport): IAppAction => ({
    type: MockDrillReportActions.UPDATE,
    payload: model
});

export const deleteMockDrillReport = (
    id: string,
    siteId: string,
    redirectUrl: string
): IAppAction => ({
    type: MockDrillReportActions.DELETE,
    payload: {
        id,
        siteId,
        reloadUrl: redirectUrl,
    },
});

export const exportMockDrillReports = (): IAppAction => ({
    type: MockDrillReportActions.EXPORT
});

export const cleanupMockDrillReport = (): IAppAction => ({
    type: MockDrillReportActions.CLEANUP
});

export const cleanupMockDrillReportList = (): IAppAction => ({
    type: MockDrillReportActions.CLEANUP_LIST
});

export const setMockDrillReportParticipation = (
    model: ISetMockDrillReportParticipation
): IAppAction => ({
    type: MockDrillReportActions.SET_PARTICIPATION,
    payload: model
});

export const loadHistory = (id: string, siteId: string): IAppAction => ({
    type: MockDrillReportActions.LOAD_HISTORY,
    payload: {
        id,
        siteId
    }
});

export const loadActions = (filters: IActionFilters): IAppAction => ({
    type: MockDrillReportActions.LOAD_ACTIONS,
    payload: filters
});
