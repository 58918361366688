import { combineReducers } from 'redux';

import { IBaseState } from '../base-state';
import {
    ISiteProfilesGridState,
    reducer as grid
} from './site-profiles-grid';

export interface IRootSiteProfileState extends IBaseState {
    siteProfiles: ISiteProfileState;
}

export interface ISiteProfileState {
    grid: ISiteProfilesGridState;
}

export const siteProfilesReducer = combineReducers({
    grid,
});
