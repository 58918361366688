import classnames from 'classnames';
import * as React from 'react';

import './timeline-item.scss';

export interface IProps {
    centered?: boolean;
}

const getClassName = (props: IProps) => classnames(
    'TimelineItem',
    {
        'TimelineItem--centered': props.centered
    }
);

export const TimelineItem: React.FC<React.PropsWithChildren<IProps>> = (props) => (
    <div className={getClassName(props)}>
        {props.children}
    </div>
);
