import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';

const getLookupQuestionCategories = (state: IRootState) => state.app.lookupQuestionCategories;
const getLookupQuestionUrgencies = (state: IRootState) => state.app.lookupQuestionUrgencies;
const getLookupPriorities = (state: IRootState) => state.app.lookupPriorities;

export default createSelector(
    getLookupQuestionCategories,
    getLookupQuestionUrgencies,
    getLookupPriorities,
    (lookupCategories, lookupUrgencies, lookupPriorities) => ({
        lookupCategories,
        lookupUrgencies,
        lookupPriorities
    })
);
