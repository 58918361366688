import queryString from 'query-string';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';

import {
    IChangeBuildingSearchValueAction,
    ILinkBuildingAction,
    ILoadBuildingSearchResults,
    ILoadLinkedBuildingsAction,
    ILoadSiteSearchResultsAction,
    ISelectSiteAction,
    IUnlinkBuildingConfirmedAction,
    SiteRelationshipsActions,
    SiteRelationshipsActionTypes,
    unlinkBuildingFulfilled,
} from '../../actions/site-relationships-actions';
import { IBuilding } from '../../models/building';
import { ISiteRelationshipsRootState } from '../../reducers/siteRelationships';
import { getErrorActions$ } from '../epic-helpers';
import { getChangeSearchValueEpic } from './factories';
import { getApiHeaders } from 'epics/shared-header';

import appConfig from 'helpers/config-helper';

const config = appConfig();

const apiUrl = config.REACT_APP_BASE_API;

const changeBuildingSearchValueEpic = getChangeSearchValueEpic<
    IChangeBuildingSearchValueAction,
    ILoadBuildingSearchResults
>(
    SiteRelationshipsActions.CHANGE_BUILDING_SEARCH_VALUE,
    SiteRelationshipsActions.LOAD_BUILDING_SEARCH_RESULTS
);

const loadBuildingSearchResultsEpic: Epic<
    SiteRelationshipsActionTypes,
    any,
    ISiteRelationshipsRootState
> = (action$, state$) =>
    action$.pipe(
        ofType(SiteRelationshipsActions.LOAD_BUILDING_SEARCH_RESULTS),
        filter(() => Boolean(state$.value.siteRelationships.siteSelector.selectedSite)),
        map<
            ILoadSiteSearchResultsAction,
            {
                siteId: string;
                searchString: string;
                filterKeywords: string[];
            }
        >((action) => ({
            siteId: state$.value.siteRelationships.siteSelector.selectedSite.id,
            searchString: action.payload.searchString,
            filterKeywords: action.payload.searchString.split(' '),
        })),
        map((values) => ({
            // tslint:disable-next-line:max-line-length
            url: `${apiUrl}/api/siteapi/${values.siteId}/buildings?${queryString.stringify({
                isAttached: false,
                filterKeywords: values.filterKeywords,
            })}`,
            searchString: values.searchString,
        })),
        switchMap(({ searchString, url }) =>
            ajax.getJSON(url, getApiHeaders()).pipe(
                map<IBuilding[], SiteRelationshipsActionTypes>((response) => ({
                    type: SiteRelationshipsActions.LOAD_BUILDING_SEARCH_RESULTS_FULFILLED,
                    payload: {
                        buildings: response,
                        searchString,
                    },
                })),
                takeUntil(
                    action$.pipe(
                        ofType<SiteRelationshipsActionTypes>(
                            SiteRelationshipsActions.CHANGE_SITE_SEARCH_VALUE,
                            SiteRelationshipsActions.CHANGE_BUILDING_SEARCH_VALUE,
                            SiteRelationshipsActions.LOAD_BUILDING_SEARCH_RESULTS,
                            SiteRelationshipsActions.LOAD_BUILDING_SEARCH_RESULTS_CANCELLED
                        )
                    )
                ),
                catchError<any, any>((error: AjaxError) =>
                    getErrorActions$('Buildings')(
                        SiteRelationshipsActions.LOAD_BUILDING_SEARCH_RESULTS_REJECTED,
                        error,
                        {
                            errorMessage: 'Unable to load buildings',
                        }
                    )
                )
            )
        )
    );

const triggerLinkedBuildingsRefreshEpic: Epic<SiteRelationshipsActionTypes> = (action$) =>
    action$.pipe(
        ofType<SiteRelationshipsActionTypes>(
            SiteRelationshipsActions.SELECT_SITE,
            SiteRelationshipsActions.LINK_BUILDING_FULFILLED,
            SiteRelationshipsActions.UNLINK_BUILDING_FULFILLED
        ),
        map<ISelectSiteAction, SiteRelationshipsActionTypes>((action) => ({
            type: SiteRelationshipsActions.LOAD_LINKED_BUILDINGS,
            payload: {
                siteId: action.payload.site.id,
            },
        }))
    );

const loadLinkedBuildingsEpic: Epic<any> = (action$) =>
    action$.pipe(
        ofType(SiteRelationshipsActions.LOAD_LINKED_BUILDINGS),
        map<
            ILoadLinkedBuildingsAction,
            {
                siteId: string;
            }
        >((action) => ({
            siteId: action.payload.siteId,
        })),
        map((values) => ({
            // tslint:disable-next-line:max-line-length
            url: `${apiUrl}/api/siteapi/${values.siteId}/buildings?${queryString.stringify({
                isAttached: true,
            })}`,
            siteId: values.siteId,
        })),
        switchMap(({ siteId, url }) =>
            ajax.getJSON(url).pipe(
                map<IBuilding[], SiteRelationshipsActionTypes>((response) => ({
                    type: SiteRelationshipsActions.LOAD_LINKED_BUILDINGS_FULFILLED,
                    payload: {
                        buildings: response,
                        siteId,
                    },
                })),
                takeUntil(
                    action$.pipe(
                        ofType<SiteRelationshipsActionTypes>(
                            SiteRelationshipsActions.CHANGE_SITE_SEARCH_VALUE,
                            SiteRelationshipsActions.LOAD_LINKED_BUILDINGS,
                            SiteRelationshipsActions.LOAD_LINKED_BUILDINGS_CANCELLED
                        )
                    )
                ),
                catchError<any, any>((error: AjaxError) =>
                    getErrorActions$('Linked Buildings')(
                        SiteRelationshipsActions.LOAD_LINKED_BUILDINGS_REJECTED,
                        error,
                        {
                            errorMessage: 'Unable to load linked buildings.',
                        }
                    )
                )
            )
        )
    );

const linkBuildingEpic: Epic<any> = (action$) =>
    action$.pipe(
        ofType(SiteRelationshipsActions.LINK_BUILDING),
        switchMap<ILinkBuildingAction, any>((action) =>
            ajax
                .post(
                    `${apiUrl}/api/siteapi/${action.payload.site.id}/buildings`,
                    action.payload.building,
                    {
                        'Content-Type': 'application/json',
                    }
                )
                .pipe(
                    map<AjaxResponse, SiteRelationshipsActionTypes>(() => ({
                        type: SiteRelationshipsActions.LINK_BUILDING_FULFILLED,
                        payload: {
                            ...action.payload,
                        },
                    })),
                    catchError<any, any>((error: AjaxError) =>
                        getErrorActions$('Link Building')(
                            SiteRelationshipsActions.LINK_BUILDING_REJECTED,
                            error,
                            {
                                errorMessage: 'Unable to link building.',
                            }
                        )
                    )
                )
        )
    );

const unlinkBuildingConfirmedEpic: Epic<any> = (action$) =>
    action$.pipe(
        ofType(SiteRelationshipsActions.UNLINK_BUILDING_CONFIRMED),
        switchMap<IUnlinkBuildingConfirmedAction, any>((action) =>
            ajax
                .delete(
                    `${apiUrl}/api/siteapi/${action.payload.site.id}/buildings/${action.payload.building.id}`,
                    getApiHeaders()
                )
                .pipe(
                    map<AjaxResponse, SiteRelationshipsActionTypes>(() =>
                        unlinkBuildingFulfilled(action.payload.site, action.payload.building)
                    ),
                    catchError<any, any>((error: AjaxError) =>
                        getErrorActions$('Un-link Building')(
                            SiteRelationshipsActions.UNLINK_BUILDING_REJECTED,
                            error,
                            {
                                ...action.payload,
                                errorMessage: 'Unable to un-link building.',
                            }
                        )
                    )
                )
        )
    );

export const buildingEpics = combineEpics(
    changeBuildingSearchValueEpic,
    loadBuildingSearchResultsEpic,
    triggerLinkedBuildingsRefreshEpic,
    loadLinkedBuildingsEpic,
    linkBuildingEpic,
    unlinkBuildingConfirmedEpic
);
