import { PersonActions } from '../../actions/actions-v2/person-action-v2';
import { combineReducers } from 'redux';
import { IQuantumLookups } from '../base-state';
import { IAppAction } from 'actions/app-action';

const personLookups = (
    state: IQuantumLookups['personLookups'] = [],
    action: IAppAction
) => {
    switch (action.type) {
        case PersonActions.LOAD_PERSONS_LOOKUP_FULFILLED:
            return action.payload ? action.payload : state;

        default:
            return state;
    }
};

const isLoading = (
    state: IQuantumLookups['isLoading'] = false,
    action: IAppAction
) => {
    switch (action.type) {
        case PersonActions.LOAD_PERSONS_LOOKUP:
            return true;

        case PersonActions.LOAD_PERSONS_LOOKUP_FULFILLED:
        case PersonActions.LOAD_PERSONS_LOOKUP_REJECTED:
            return false;

        default:
            return state;
    }
};

export const lookupsReducer  = combineReducers<IQuantumLookups>({
   personLookups,
   isLoading
});
