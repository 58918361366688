import * as React from 'react';
import { ArticlePanel } from 'components/panel';
import { FormikProps } from 'formik';
import { ISiteProfile } from 'models/site-profile';
import { RouteComponentProps } from 'react-router';
import { IUpsertSitePageRouteParams } from '../upsert-site-page';
import { NavLink } from 'react-router-dom';
import { FloatingButton } from 'components/v2/button/FloatingButton';
import { SelectField, IOption } from 'components/form/fields/select-field';
import { Button } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';

interface IProps extends RouteComponentProps<IUpsertSitePageRouteParams> {
    externalBuildings: Array<IOption>;
    formikBag?: FormikProps<Partial<ISiteProfile>>;
    isSubmitting: boolean;
    load: (keyword: string) => void;
}
export const SystemIntegration: React.FC<IProps> = ({
    formikBag,
    externalBuildings,
    isSubmitting,
    load,
}) => {
    const [selectedItems, setSelectedItems] = React.useState([] as IOption[]);
    const [searchKeyword, setSearchKeyword] = React.useState('');
    const [selectedBuildings, setSelectedBuildings] = React.useState([] as IOption[]);
    const [buildings, setBuildings] = React.useState(externalBuildings);

    React.useEffect(() => {
        // if (externalBuildings && formikBag.values.elogbooksBuildingIds) {
        //     const items = externalBuildings.filter((b) =>
        //         formikBag.values.elogbooksBuildingIds.some((id) => id === b.value)
        //     );
        //     setSelectedItems(items);
        // }
        setBuildings(externalBuildings);
    }, [externalBuildings]);

    React.useEffect(() => {
        if (
            formikBag.values.elogbooksBuildingIds &&
            formikBag.values.elogbooksBuildingIds.length > 0
        ) {
            let array = [] as IOption[];
            formikBag.values.elogbooksBuildingIds.forEach((element) => {
                const item = element.split('::');
                array = [...array, { value: item[0], label: item[1] } as IOption];
            });

            const ids = selectedBuildings.map((b) => b.value);
            const options = array.filter((o) => !ids.includes(o.value));
            if (options && options.length > 0) {
                setSelectedBuildings(options);
            }
        }
    }, [formikBag.values.elogbooksBuildingIds]);

    React.useEffect(() => {
        const ids = selectedBuildings.map((b) => b.value);
        const options = buildings.filter((o) => !ids.includes(o.value));
        setBuildings(options);
    }, [selectedBuildings]);

    const searchChange = (field: any) => {
        setSearchKeyword(field.target.value);
    };
    const updateBuilding = (fields: IOption[]) => {
        setSelectedItems(fields);
    };
    const onPublish = () => {
        formikBag.submitForm();
    };
    const onSearchButtonClick = () => {
        load(searchKeyword);
    };

    const updateSelectedBuildings = () => {
        const ids = selectedBuildings.map((b) => b.value);
        const elemets = selectedItems.filter((b) => !ids.includes(b.value));
        const items = [...selectedBuildings, ...elemets];
        setSelectedBuildings(items);
        setSelectedItems([]);

        const bs = items && items.map((b) => `${b.value}::${b.label}`);
        formikBag.setFieldValue('elogbooksBuildingIds', bs);
    };
    const removeBuilding = (id: string) => {
        const buildings = selectedBuildings.filter((b) => b.value !== id);
        setSelectedBuildings(buildings);

        const bs = buildings && buildings.map((b) => `${b.value}::${b.label}`);
        formikBag.setFieldValue('elogbooksBuildingIds', bs);
    };
    const translate = useTranslate();

    const hasError = () => {
        return Object.keys(formikBag.errors).length > 0;
    }

    return (
        <>
            <ArticlePanel className="column">
                <header>
                    <h1 className="title is-4">{translate('UpsertSite.SystemIntegration.Panel.Header')}</h1>
                    <small>{translate('UpsertSite.SystemIntegration.Panel.Header2')}</small>
                </header>
                <div className="field has-addons">
                    <div className="control is-expanded">
                        <input
                            className="input"
                            type="text"
                            onChange={searchChange}
                            value={searchKeyword}
                            placeholder={translate('UpsertSite.SystemIntegration.Div.Text')}
                        />
                    </div>
                    <div className="control">
                        <a className="button button-action" onClick={onSearchButtonClick}>
                            {translate('SelectorDialog.Label.2')}
                        </a>
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-10">
                        <SelectField
                            id="elogbooksBuildingIds"
                            label=""
                            value={selectedItems}
                            isMulti={true}
                            handleChange={updateBuilding}
                            options={buildings}
                        />
                    </div>
                    <div className="column is-2">
                        <Button
                            id="add-approval-person-button"
                            onClick={updateSelectedBuildings}
                            disabled={selectedItems.length === 0}
                        >
                            {translate('FacilityPopup.Button.Add')}
                        </Button>
                    </div>
                </div>

                <table className="table is-fullwidth is-hoverable">
                    <thead>
                        <tr>
                            <th>{translate('UpsertSite.SystemIntegration.Id')}</th>
                            <th>{translate('IncidentApprovals.Label.Name')}</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {selectedBuildings.map((item) => {
                            return (
                                <tr key={item.value}>
                                    <td>{item.value}</td>
                                    <td>{item.label}</td>
                                    <td>
                                        {' '}
                                        <Button
                                            className="remove"
                                            onClick={() => removeBuilding(item.value)}
                                        >
                                            {translate('IncidentRiskActions.Trash.Remove')}
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                <div className="SiteForm__actions">
                    <div className="action-group">
                        <NavLink className="button button-cancel" to={`/Site`}>
                            {translate('Globals.Label.Cancel')}
                        </NavLink>
                    </div>
                    <div className="action-group">
                        <FloatingButton
                            onClick={onPublish}
                            isLoading={isSubmitting}
                            disabled={hasError() || !formikBag.dirty}
                            float={formikBag.dirty}
                            tooltip={translate('Globals.Label.PleaseClick')}
                        >
                            {translate('Globals.Label.Save')}
                        </FloatingButton>
                    </div>
                </div>
            </ArticlePanel>
        </>
    );
};
