import React, { useState, useEffect } from 'react';
import Page from 'components/v2/page/Page';
import { Loader } from 'components/loader';
import { Button } from 'components/v2/components';
import { FileUploadModal } from 'components/fileUploadModal';
import useTranslate from 'translations/translation-utils';
import { SelectField } from 'components/form/fields/select-field';
import { IKeyValue } from 'models';
import {
    ILogUploadModel,
    getLogTypes,
    LogType,
    TemplateType,
    ITemplateValidationSummary,
} from 'models/logs-upload';
import { TemplateValidationSummary } from './template-validation-summary';
import { checkPermission } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import { ISiteProfile } from 'models/site-profile';

interface IProps {
    isUploading: boolean;
    templateValidationSummary: ITemplateValidationSummary;
    lookupSites: Array<IKeyValue<string>>;
    isLoading: boolean;
    permissions: Array<IKeyValue<string>>;
    site: ISiteProfile;
    logsUpload: (model: ILogUploadModel) => void;
    resetLogs: () => void;
    downloadTemplate: (logType: string, templateType: string) => void;
    loadSite: (siteId: string) => void;
}

export const LogsUploadPage: React.FC<IProps> = ({
    templateValidationSummary,
    lookupSites,
    isUploading,
    permissions,
    site,
    downloadTemplate,
    logsUpload,
    resetLogs,
    isLoading,
    loadSite
}) => {
    const translate = useTranslate();

    const [fileUploadModalVisible, setFileUploadModalVisible] = useState(false);
    const [logTypesFiltered, setLogTypesFiltered] = useState(getLogTypes);
    const [uploadModel, setUploadModel] = useState({
        siteId: '',
        logType: LogType.PleaseSelect,
        templateType: TemplateType.Quantum,
    } as ILogUploadModel);

    const [showMyDataError, setshowMyDataError] = useState(false);

    useEffect(() => {
        if (!lookupSites) {
            setshowMyDataError(false);
        } else {
            if (site.clientCode != null && uploadModel.logType == "Facility") {
                setshowMyDataError(true);
            } else {
                setshowMyDataError(false);
            }
        }
    }, [site, lookupSites]);

    useEffect(() => {
        if (!lookupSites) {
            return;
        }
    }, [lookupSites]);

    useEffect(() => {
        resetLogs();
        if (uploadModel.siteId) {
            loadSite(uploadModel.siteId);
        }
    }, [uploadModel]);

    useEffect(() => {
        if (!checkPermission(Privilege.CermAssessmentExpertReviewCreate, permissions)) {
            setLogTypesFiltered(getLogTypes.filter((x) => x.key !== LogType.ExpertReview));
        }
    }, []);

    const showModal = () => {
        setFileUploadModalVisible(true);
    };

    const onConfirm = (collection: File[]) => {
        logsUpload({ ...uploadModel, collection });
        setFileUploadModalVisible(false);
    };

    const showHide = () => {
        setFileUploadModalVisible(false);
    };

    const selectedSiteValue = () => {
        const currentValue = lookupSites.find((f) => f.key === uploadModel.siteId);
        if (!currentValue) {
            return { value: '', label: translate('LogsUpload.LogType.PleaseSelect'), key: '' };
        }
        return { value: currentValue.key, label: currentValue.value, key: currentValue.key };
    };

    const selectedLogTypeValue = () => {
        const currentValue = logTypesFiltered.find((f) => f.key === uploadModel.logType);
        if (!currentValue) {
            return { value: '', label: translate('LogsUpload.LogType.PleaseSelect'), key: '' };
        }
        return {
            value: currentValue.key,
            label: translate(currentValue.label),
            key: currentValue.key,
        };
    };

    const getTemplateType = (templateType: string, lookupSites: any) => {
        const modelSite = lookupSites.find(x => x.key === uploadModel.siteId);

        return modelSite && templateType && uploadModel.logType === LogType.Risk
            ? `${templateType}-${modelSite.operatingPlatform.replace(/ /g, "")}`
            : templateType;
    };

    const canUploadUser = checkPermission(Privilege.UploadUser, permissions);

    const uploadButtonIsDisabled =
        uploadModel.logType === LogType.PleaseSelect ||
        (uploadModel.logType === LogType.Site &&
            (uploadModel.siteId !== '' ||
                uploadModel.templateType === TemplateType.PleaseSelect)) ||
        ((uploadModel.logType === LogType.Risk || uploadModel.logType === LogType.Facility || uploadModel.logType === LogType.Incident) &&
            uploadModel.siteId === '' ||
            uploadModel.templateType === TemplateType.PleaseSelect
        ) || (showMyDataError === true) ||
        (uploadModel.logType === LogType.User &&
            canUploadUser === false);

    const isSiteUpload = () => {
        return (
            uploadModel.logType !== LogType.PleaseSelect &&
            uploadModel.logType !== LogType.Site &&
            uploadModel.logType !== LogType.ExpertReview
        );
    };

    const downloadButtonIsDisabled =
        uploadModel.logType === LogType.PleaseSelect ||
        ((uploadModel.logType === LogType.Site || uploadModel.logType === LogType.ExpertReview) &&
            (uploadModel.siteId !== '' ||
                uploadModel.templateType === TemplateType.PleaseSelect)) ||
        ((uploadModel.logType === LogType.Risk || uploadModel.logType === LogType.Facility || uploadModel.logType === LogType.Incident) &&
            uploadModel.siteId === '' ||
            uploadModel.templateType === TemplateType.PleaseSelect
        );

    const handleLogTypeChange = (field: any) => {
        const newUploadModel = { ...uploadModel };
        newUploadModel.templateType = TemplateType.Quantum;
        newUploadModel.logType = field.value as LogType;

        if (
            (field.value as LogType) === LogType.Site ||
            (field.value as LogType) === LogType.ExpertReview
        ) {
            newUploadModel.siteId = '';
        }

        setUploadModel({ ...newUploadModel });
    };

    return (
        <Loader loading={isLoading}>
            <Page title={translate('LogsUpload.PageTitle')}>
                <div className="tabs-container tab-content">
                    <div className="columns download-template">
                        <div className="column is-full">
                            <Button
                                id="downloadTemplateButton"
                                buttonType="action"
                                onClick={() =>
                                    downloadTemplate(
                                        uploadModel.logType,
                                        getTemplateType(uploadModel.templateType, lookupSites)
                                    )
                                }
                                disabled={downloadButtonIsDisabled}
                            >
                                {translate('LogsUpload.Button.DownloadTemplate')}
                            </Button>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-full">
                            <SelectField
                                id="logs-upload-type-selector"
                                label={translate('LogsUpload.Label.UploadType')}
                                value={selectedLogTypeValue()}
                                options={logTypesFiltered.map((m) => ({
                                    ...m,
                                    label: translate(m.label),
                                }))}
                                handleChange={handleLogTypeChange}
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-full">
                            {isSiteUpload() && (
                                <SelectField
                                    id="logs-upload-site-selector"
                                    label={translate('LogsUpload.Label.Site')}
                                    value={selectedSiteValue()}
                                    options={lookupSites.map((x) => ({
                                        value: x.key,
                                        label: x.value,
                                        key: x.key,
                                    }))}
                                    handleChange={(field: any) =>
                                        setUploadModel({ ...uploadModel, siteId: field.value })
                                    }
                                    error={
                                        showMyDataError === true
                                            ? translate('LogsUpload.Label.MyDataErrorMessage')
                                            : ''
                                    }
                                />
                            )}
                        </div>
                    </div>
                    <FileUploadModal
                        aloudFiles={translate('LogsUpload.Label.Recommendedfile')}
                        visible={fileUploadModalVisible}
                        uploading={isUploading}
                        onConfirm={onConfirm}
                        onClose={showHide}
                    />
                    <div className="columns">
                        <div className="column is-6">
                            <Button
                                id="uploadButton"
                                buttonType="upload"
                                onClick={showModal}
                                disabled={uploadButtonIsDisabled}
                            >
                                {translate('LogsUpload.Button.Upload')}
                            </Button>
                            {uploadModel.logType === LogType.User && canUploadUser === false &&
                                <div className="help is-danger">
                                    <br />
                                    {translate('LogsUpload.Label.NoUploadUserPrivilege')}
                                </div>
                            }
                        </div>
                    </div>
                    {Object.keys(templateValidationSummary).length ? (
                        <TemplateValidationSummary results={templateValidationSummary} />
                    ) : (
                        ''
                    )}
                </div>
            </Page>
        </Loader>
    );
};
