import * as React from 'react';

interface IRiskArrowProps {
    text: string;
    active: boolean;
}

export const RiskArrow: React.FC<IRiskArrowProps> = ({
    text,
    active,
}) => {

    return (
        <div className={`arrow-pointer ${active ? `arrow-pointer-green` : `arrow-pointer-grey`}`}>
            <div>
                <div className="label">
                    {text}
                </div>
            </div>
        </div>
    );
};
