import moment from 'moment';
import * as React from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
/* import { IKeyedItem } from 'models/keyed-item';
import { IAuditableItem } from 'models/auditable-item'; */
import { ICalendarItem } from 'models/calendar';
import './Calendar.scss';

export interface IProps {
    id?: string;
    events: Partial<ICalendarItem>[];
    onclick: any;
}
const eventStyleGetter = (event: Partial<ICalendarItem>) => {
    const color = event.className;
    return {
        className: color
    };
};

export const Calendar: React.FC<IProps> = (props) => {
    const localizer = momentLocalizer(moment);
    return (
        <div className="Calendar" id={props.id}>
            <BigCalendar
                onSelectEvent={props.onclick}
                localizer={localizer}
                events={props.events}
                startAccessor="start"
                endAccessor="end"
                eventPropGetter={eventStyleGetter}
            />
        </div>
    );
};
