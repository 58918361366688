import { IAppAction } from '../app-action';
import { IRiskAssessmentFilters } from 'models/risk-assessment-filters';
import { ISetRiskAssessmentAnswers } from 'models/risk-assessments/set-risk-assessment-answers';

export enum RiskAssessmentActions {
    LOAD_RISK_ASSESSMENTS = '@@riskAssessment/LOAD_RISK_ASSESSMENTS',
    LOAD_RISK_ASSESSMENTS_FULFILLED = '@@riskAssessment/LOAD_RISK_ASSESSMENTS_FULFILLED',
    LOAD_RISK_ASSESSMENTS_REJECTED = '@@riskAssessment/LOAD_RISK_ASSESSMENTS_REJECTED',

    SET_RISK_ASSESSMENT_ANSWERS = '@@riskAssessment/SET_RISK_ASSESSMENT_ANSWERS',
    SET_RISK_ASSESSMENT_ANSWERS_FULFILLED = '@@riskAssessment/SET_RISK_ASSESSMENT_ANSWERS_FULFILLED',
    SET_RISK_ASSESSMENT_ANSWERS_REJECTED = '@@riskAssessment/SET_RISK_ASSESSMENT_ANSWERS_REJECTED',
}

export const loadRiskAssessment = (filters: IRiskAssessmentFilters): IAppAction => ({
    type: RiskAssessmentActions.LOAD_RISK_ASSESSMENTS,
    payload: filters,
});

export const setRiskAssessmentAnswers = (model: ISetRiskAssessmentAnswers): IAppAction => ({
    type: RiskAssessmentActions.SET_RISK_ASSESSMENT_ANSWERS,
    payload: model,
});
