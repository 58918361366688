import { combineReducers } from 'redux';
import { IAppAction } from 'actions/app-action';
import { AttachmentActions } from 'actions/actions-v2/attachment-action-v2';

export interface IAttachmentState {
    isSaving: boolean;
    isLoading: boolean;
    showModal: boolean;
    saved: boolean;
}

const INITIAL_STATE: IAttachmentState = {
    isLoading: false,
    isSaving: false,
    showModal: false,
    saved: false,
};

const isLoading = (
    state: IAttachmentState['isLoading'] = INITIAL_STATE.isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case AttachmentActions.LOAD_ATTACHMENTS_ENGINEER:
        case AttachmentActions.LOAD_ATTACHMENTS_RISK:
        case AttachmentActions.LOAD_ATTACHMENTS_SPARE:
        case AttachmentActions.LOAD_ATTACHMENTS_ACTION:
        case AttachmentActions.LOAD_ATTACHMENTS_INCIDENT:
        case AttachmentActions.LOAD_ATTACHMENTS_PROCESS_STEP:
        case AttachmentActions.LOAD_ATTACHMENTS_OUTCOME:
        case AttachmentActions.LOAD_ATTACHMENTS_MOCK_DRILL_REPORT:
        case AttachmentActions.LOAD_ATTACHMENTS_SHIFT_HANDOVER:
        case AttachmentActions.LOAD_ATTACHMENTS_SITE:
        case AttachmentActions.LOAD_ATTACHMENTS_ENGINEER_REPORT:
        case AttachmentActions.LOAD_ATTACHMENTS_GLOBAL_MOCK_DRILL:
            return true;
        case AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_ENGINEER:
        case AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_RISK:
        case AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_OUTCOME:
        case AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_SPARE:
        case AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_ACTION:
        case AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_INCIDENT:
        case AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_PROCESS_STEP:
        case AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_MOCK_DRILL_REPORT:
        case AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_SHIFT_HANDOVER:
        case AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_SITE:
        case AttachmentActions.LOAD_ATTACHMENTS_ENGINEER_REPORT_FULFILLED:
        case AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_GLOBAL_MOCK_DRILL:
        case AttachmentActions.LOAD_ATTACHMENTS_REJECTED_ENGINEER:
        case AttachmentActions.LOAD_ATTACHMENTS_REJECTED_RISK:
        case AttachmentActions.LOAD_ATTACHMENTS_REJECTED_SPARE:
        case AttachmentActions.LOAD_ATTACHMENTS_REJECTED_ACTION:
        case AttachmentActions.LOAD_ATTACHMENTS_REJECTED_INCIDENT:
        case AttachmentActions.LOAD_ATTACHMENTS_REJECTED_PROCESS_STEP:
        case AttachmentActions.LOAD_ATTACHMENTS_REJECTED_OUTCOME:
        case AttachmentActions.LOAD_ATTACHMENTS_REJECTED_MOCK_DRILL_REPORT:
        case AttachmentActions.LOAD_ATTACHMENTS_REJECTED_SHIFT_HANDOVER:
        case AttachmentActions.LOAD_ATTACHMENTS_REJECTED_SITE:
        case AttachmentActions.LOAD_ATTACHMENTS_ENGINEER_REPORT_REJECTED:
        case AttachmentActions.LOAD_ATTACHMENTS_REJECTED_GLOBAL_MOCK_DRILL:
            return false;
        default:
            return state;
    }
};

const isSaving = (
    state: IAttachmentState['isSaving'] = INITIAL_STATE.isSaving,
    action: IAppAction
) => {
    switch (action.type) {
        case AttachmentActions.UPLOAD_ATTACHMENT_SPARE:
        case AttachmentActions.UPLOAD_ATTACHMENT_RISK:
        case AttachmentActions.UPLOAD_ATTACHMENT_ACTION:
        case AttachmentActions.UPLOAD_ATTACHMENT_INCIDENT:
        case AttachmentActions.UPLOAD_ATTACHMENT_PROCESS_STEP:
        case AttachmentActions.UPLOAD_ATTACHMENT_MOCK_DRILL_REPORT:
        case AttachmentActions.UPLOAD_ATTACHMENT_SHIFT_HANDOVER:
        case AttachmentActions.UPLOAD_ATTACHMENT_SITE:
        case AttachmentActions.UPLOAD_ATTACHMENT_OUTCOME:
        case AttachmentActions.UPLOAD_ATTACHMENT:
        case AttachmentActions.UPLOAD_ATTACHMENT_ENGINEER_REPORT:
        case AttachmentActions.UPLOAD_ATTACHMENT_GLOBAL_MOCK_DRILL:
            return true;
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_SPARE:
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_RISK:
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_ACTION:
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_INCIDENT:
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_PROCESS_STEP:
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED:
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_OUTCOME:
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_MOCK_DRILL_REPORT:
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_SHIFT_HANDOVER:
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_SITE:
        case AttachmentActions.UPLOAD_ATTACHMENT_REJECTED_OUTCOME:
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_GLOBAL_MOCK_DRILL:
        case AttachmentActions.UPLOAD_ATTACHMENT_REJECTED:
        case AttachmentActions.UPLOAD_ATTACHMENT_REJECTED_RISK:
        case AttachmentActions.UPLOAD_ATTACHMENT_REJECTED_SPARE:
        case AttachmentActions.UPLOAD_ATTACHMENT_REJECTED_ACTION:
        case AttachmentActions.UPLOAD_ATTACHMENT_REJECTED_INCIDENT:
        case AttachmentActions.UPLOAD_ATTACHMENT_REJECTED_PROCESS_STEP:
        case AttachmentActions.UPLOAD_ATTACHMENT_REJECTED_MOCK_DRILL_REPORT:
        case AttachmentActions.UPLOAD_ATTACHMENT_REJECTED_SHIFT_HANDOVER:
        case AttachmentActions.UPLOAD_ATTACHMENT_REJECTED_SITE:
        case AttachmentActions.UPLOAD_ATTACHMENT_CANCELLED:
        case AttachmentActions.UPLOAD_ATTACHMENT_ENGINEER_REPORT_FULFILLED:
        case AttachmentActions.UPLOAD_ATTACHMENT_ENGINEER_REPORT_REJECTED:
        case AttachmentActions.UPLOAD_ATTACHMENT_REJECTED_GLOBAL_MOCK_DRILL:
            return false;
        default:
            return state;
    }
};

const saved = (
    state: IAttachmentState['saved'] = INITIAL_STATE.saved,
    action: IAppAction
) => {
    switch (action.type) {
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_SPARE:
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_RISK:
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_ACTION:
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_INCIDENT:
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_PROCESS_STEP:
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED:
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_OUTCOME:
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_MOCK_DRILL_REPORT:
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_SHIFT_HANDOVER:
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_SITE:
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_GLOBAL_MOCK_DRILL:
            return true;
        case AttachmentActions.UPLOAD_ATTACHMENT_SPARE:
        case AttachmentActions.UPLOAD_ATTACHMENT_RISK:
        case AttachmentActions.UPLOAD_ATTACHMENT_ACTION:
        case AttachmentActions.UPLOAD_ATTACHMENT_INCIDENT:
        case AttachmentActions.UPLOAD_ATTACHMENT_PROCESS_STEP:
        case AttachmentActions.UPLOAD_ATTACHMENT_MOCK_DRILL_REPORT:
        case AttachmentActions.UPLOAD_ATTACHMENT_SHIFT_HANDOVER:
        case AttachmentActions.UPLOAD_ATTACHMENT_SITE:
        case AttachmentActions.UPLOAD_ATTACHMENT_OUTCOME:
        case AttachmentActions.UPLOAD_ATTACHMENT:
        case AttachmentActions.UPLOAD_ATTACHMENT_ENGINEER_REPORT:
        case AttachmentActions.UPLOAD_ATTACHMENT_GLOBAL_MOCK_DRILL:
        case AttachmentActions.UPLOAD_ATTACHMENT_REJECTED_OUTCOME:
        case AttachmentActions.UPLOAD_ATTACHMENT_REJECTED:
        case AttachmentActions.UPLOAD_ATTACHMENT_REJECTED_RISK:
        case AttachmentActions.UPLOAD_ATTACHMENT_REJECTED_SPARE:
        case AttachmentActions.UPLOAD_ATTACHMENT_REJECTED_ACTION:
        case AttachmentActions.UPLOAD_ATTACHMENT_REJECTED_INCIDENT:
        case AttachmentActions.UPLOAD_ATTACHMENT_REJECTED_PROCESS_STEP:
        case AttachmentActions.UPLOAD_ATTACHMENT_REJECTED_MOCK_DRILL_REPORT:
        case AttachmentActions.UPLOAD_ATTACHMENT_REJECTED_SHIFT_HANDOVER:
        case AttachmentActions.UPLOAD_ATTACHMENT_REJECTED_SITE:
        case AttachmentActions.UPLOAD_ATTACHMENT_CANCELLED:
        case AttachmentActions.UPLOAD_ATTACHMENT_ENGINEER_REPORT_FULFILLED:
        case AttachmentActions.UPLOAD_ATTACHMENT_ENGINEER_REPORT_REJECTED:
        case AttachmentActions.UPLOAD_ATTACHMENT_REJECTED_GLOBAL_MOCK_DRILL:
            return false;
        case AttachmentActions.CLEANUP:
            return INITIAL_STATE.saved;
        default:
            return state;
    }
};

const showModal = (
    state: IAttachmentState['showModal'] = INITIAL_STATE.showModal,
    action: IAppAction
) => {
    switch (action.type) {
        case AttachmentActions.SHOW_ATTACHMENT_MODAL:
            return true;
        case AttachmentActions.HIDE_ATTACHMENT_MODAL:
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_SPARE:
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_RISK:
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_ACTION:
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_OUTCOME:
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_INCIDENT:
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_MOCK_DRILL_REPORT:
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_SHIFT_HANDOVER:
        case AttachmentActions.UPLOAD_ATTACHMENT_ENGINEER_REPORT_FULFILLED:
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_SITE:
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED:
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_GLOBAL_MOCK_DRILL:
        case AttachmentActions.UPLOAD_ATTACHMENT_CANCELLED:
            return false;
        default:
            return state;
    }
};

export const attachmentReducer = combineReducers<IAttachmentState>({
    isLoading,
    isSaving,
    showModal,
    saved,
});
