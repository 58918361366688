import * as React from 'react';
import { TextField } from 'components/form/fields/text-field';
import { Checkbox } from 'components/form/Checkbox';
import { IMenu } from 'models/menu-model';
import { Loader } from 'components/loader';
import '../../views/operating-platform.scss';

interface IProps {
    menus?: IMenu[];
    isReadOnly: boolean;
    selectedTab?: string;
    getOperatingPlatformMenus: (OperatingPlatformMenus: IMenu[]) => void;
    selectedOperatingPlatform: string;
    newOperatingPlatform: string;
}

export const MenuConfigurationTab: React.FC<IProps> = (props) => {
    const [userSelectedMenu, setUserSelectedMenu] = React.useState<IMenu[]>([]);
    const [allMenuItems, setAllMenuItems] = React.useState([]);
    const [toggleSelectAll, setToggleSelectAll] = React.useState(false);
    const [myDataIsLoading, setLoading] = React.useState(true);
    const [updatedMenuItems, setUpdatedMenuItems] = React.useState([]);

    const loadMainMenu = () => {
        props.menus &&
            props.menus.length > 0 &&
            props.menus.map(navItem => {
                addAllMenuItems(navItem)

            });
        setAllMenuItems(allMenuItems)
    }

    const loadMenuItemsDetails = () => {
        props.menus &&
            props.menus.length > 0 &&
            props.menus.map(navItem => {

                loadMenuItemsName(navItem)

            });
        setUserSelectedMenu(userSelectedMenu)
    }

    React.useEffect(() => {
        (userSelectedMenu && userSelectedMenu.length === 0) && loadMenuItemsDetails();
        setLoading(false);
        const operatingPlatformValue = props.selectedOperatingPlatform ? props.selectedOperatingPlatform : props.newOperatingPlatform;
        setOperatingPlatform(operatingPlatformValue);
        (userSelectedMenu && userSelectedMenu.length !== 0) && setToggleCheckState();

    }, []);


    React.useEffect(() => {
        (allMenuItems && allMenuItems.length === 0) && loadMainMenu();
    }, [toggleSelectAll]);

    const SetOperatingPlatform = (menuId: string, operatingPlatform?: string) => {
        const menuItem = (userSelectedMenu && userSelectedMenu?.find((x) => x.id === userSelectedMenu[menuId].id))

        // Make a shallow copy of the current `data`.
        const newArray = [...userSelectedMenu];

        // set the isActive value of menu.
        newArray[menuId] = {
            ...newArray[menuId],
            operatingPlatform: operatingPlatform,
        }


        if (userSelectedMenu.find((item) => item.id === newArray[menuId].id)) {
            userSelectedMenu.splice(userSelectedMenu.findIndex((item) => item.id === newArray[menuId].id), 1, newArray[menuId])
        }

        menuItem && menuItem.children &&
            menuItem.children.map(navItem => {
                SetOperatingPlatform(userSelectedMenu.findIndex((item) => item.id === navItem.id).toString(), operatingPlatform)
            })


        setUserSelectedMenu([...userSelectedMenu]);
    }


    const setMenuVisibility = (menuId: string, isChecked?: boolean) => {
        const menuItem = (userSelectedMenu && userSelectedMenu?.find((x) => x.id === userSelectedMenu[menuId]?.id))
        // Make a shallow copy of the current `data`.
        const newArray = [...userSelectedMenu];
        // set the isActive value of menu.
        newArray[menuId] = {
            ...newArray[menuId],
            isActive: isChecked,
        }
        //updating new operating platform
        if (!props.selectedOperatingPlatform) {
            newArray[menuId] = {
                ...newArray[menuId],
                operatingPlatform: props.newOperatingPlatform,
            }
        }

        if (userSelectedMenu && userSelectedMenu.find((item) => item.id === newArray[menuId].id)) {
            userSelectedMenu.splice(userSelectedMenu.findIndex((item) => item.id === newArray[menuId].id), 1, newArray[menuId])
        }
        // creating update menu list
        if (updatedMenuItems && updatedMenuItems.find((item) => item.id === newArray[menuId].id)) {
            updatedMenuItems.splice(updatedMenuItems.findIndex((item) => item.id === newArray[menuId].id), 1, newArray[menuId])
        }
        else {
            if (updatedMenuItems && updatedMenuItems.length === 0) { updatedMenuItems.push(newArray[menuId]) }
            if (updatedMenuItems && updatedMenuItems.length > 0 && updatedMenuItems.some(
                (s) => s.id !== newArray[menuId].id)) { updatedMenuItems.push(newArray[menuId]) }
        }
        setUpdatedMenuItems([...updatedMenuItems]);
        setUserSelectedMenu([...userSelectedMenu]);

        menuItem && menuItem.children &&
            menuItem.children.map(navItem => {
                setMenuVisibility(userSelectedMenu.findIndex((item) => item.id === navItem.id).toString(), isChecked)
            })
    }

    const setAllMenuUnSelected = (isChecked: boolean) => {
        userSelectedMenu && userSelectedMenu.length > 0 && userSelectedMenu.map(navItem => {
            setMenuVisibility(userSelectedMenu.findIndex((item) => item.id === navItem.id).toString(), isChecked)
        });
    };

    const setOperatingPlatform = (operatingPlatform: string) => {
        userSelectedMenu && userSelectedMenu.length > 0 && userSelectedMenu.map(navItem => {
            SetOperatingPlatform(userSelectedMenu.findIndex((item) => item.id === navItem.id).toString(), operatingPlatform)
        });
    }

    const onSelectAll = () => {
        setToggleSelectAll(!toggleSelectAll);
        if (!toggleSelectAll) {
            setAllMenuUnSelected(true);
        } else {
            setAllMenuUnSelected(false);
        }
    };

    const onChecked = (e: { target: any; }) => {
        const menuId = e.target.id;
        const isChecked = e.target.checked;
        // Set the menu visibility.
        setMenuVisibility(menuId, isChecked);
        setToggleCheckState();
        // Call function to update menu list.
        props.getOperatingPlatformMenus([...updatedMenuItems]);

    }

    const setToggleCheckState = () => {
        (userSelectedMenu.some((item) => item.isActive !== true)) ? setToggleSelectAll(false) : setToggleSelectAll(true)
    }


    const addAllMenuItems = (navItem: IMenu) => {
        if (allMenuItems.length === 0) { allMenuItems.push(navItem.id) }
        if (allMenuItems.length > 0 && allMenuItems.some(
            (s) => s !== navItem.id)) { allMenuItems.push(navItem.id) }

        navItem.children &&
            navItem.children.map(navItem => {
                addAllMenuItems(navItem)
            })
    }

    const loadMenuItemsName = (navItem: IMenu) => {
        if (userSelectedMenu.length === 0) { userSelectedMenu.push(navItem) }
        if (userSelectedMenu.length > 0 && userSelectedMenu.some(
            (s) => s.id !== navItem.id)) { userSelectedMenu.push(navItem) }

        navItem.children &&
            navItem.children.map(navItem => {
                loadMenuItemsName(navItem)
            })
    }

    const addSubMenuItems = (navItem: IMenu) => {
        if (userSelectedMenu.length === 0) { userSelectedMenu.push(navItem) }
        if (userSelectedMenu.length > 0 && userSelectedMenu.some(
            (s) => s.id !== navItem.id)) { userSelectedMenu.push(navItem) }
        navItem.children &&
            navItem.children.map(navItem => {
                addSubMenuItems(navItem)
            })
    }

    const handleChange = e => {
        const { value, id } = e.target;
        // Make a shallow copy of the current `data`.
        const newArray = [...userSelectedMenu];
        // Update the changed item.
        newArray[id] = {
            ...newArray[id],
            menuName: value,
        }
        // creating update menu list
        if (updatedMenuItems && updatedMenuItems.find((item) => item.id === newArray[id].id)) {
            updatedMenuItems.splice(updatedMenuItems.findIndex((item) => item.id === newArray[id].id), 1, newArray[id])
        }
        else {
            if (updatedMenuItems && updatedMenuItems.length === 0) { updatedMenuItems.push(newArray[id]) }
            if (updatedMenuItems && updatedMenuItems.length > 0 && updatedMenuItems.some(
                (s) => s.id !== newArray[id].id)) { updatedMenuItems.push(newArray[id]) }
        }
        // Call setData to update.
        setUserSelectedMenu([...newArray]);
        setUpdatedMenuItems([...updatedMenuItems]);
        props.getOperatingPlatformMenus([...updatedMenuItems]);
    }

    const renderMenuItems = (navItem: IMenu, isChildren: boolean, index: number) => {
        return (
            <>
                <ul className="stats" >
                    <li>
                        <td className={'operating-platform-menus-checkbox '}>
                            <Checkbox
                                key={index.toString()}
                                checked={userSelectedMenu.some((x) => x.isActive === true && x.id === navItem.id)}
                                label={''}
                                id={index.toString()}
                                onChange={onChecked}
                            />
                        </td>
                        <td className={isChildren ? 'operating-platform-submenus' : 'operating-platform-menus'}>
                            <TextField
                                id={index.toString()}
                                key={index.toString()}
                                label={''}
                                value={userSelectedMenu.length > 0 && userSelectedMenu.find(x => x.id === navItem.id)?.menuName}
                                isDisabled={false}
                                readOnly={false}
                                handleChange={handleChange}
                            />
                        </td>
                    </li>
                </ul>

                {navItem.children &&
                    navItem.children.map((navItem) => {
                        return (
                            renderMenuItems(navItem, true, (userSelectedMenu.length > 0 && userSelectedMenu.findIndex(x => x.id === navItem.id)))
                        )

                    })}
            </>
        )
    }
    return (
        <>
            <Loader loading={myDataIsLoading}>
                <div className="tabs-container-table-only is-pulled-center">
                    <br />
                    <table className="grid-view__extended-properties" >

                        <tr>
                            <td colSpan={1} className='table-header'>
                                Visibility
                            </td>
                            <td colSpan={2} className='table-header'>
                                Menu Items
                            </td>
                        </tr>
                    </table>
                    <br />
                    <table>
                        <tr>
                            <td colSpan={1} className='table-header'>
                                <div >
                                    <Checkbox
                                        checked={toggleSelectAll}
                                        label=""
                                        id="user-request"
                                        onChange={onSelectAll}
                                    />
                                </div>
                            </td>
                            <td colSpan={2} className='table-header' >
                                All Items
                            </td>
                        </tr>
                    </table>
                    <br />
                    <table>
                        <tr >
                            {props.menus &&
                                props.menus.map((navItem) => {
                                    return (
                                        renderMenuItems(navItem, false, (userSelectedMenu.length > 0 && userSelectedMenu.findIndex(x => x.id === navItem.id)))

                                    );
                                })}
                        </tr>
                    </table>
                </div >
            </Loader>
        </>
    );
};


