import { createSelector } from 'reselect';
import { IRootManageRoleState } from '../../../reducers/manageRoles';
import { IRootPersonState } from 'reducers/persons';
import { IRootState } from 'routes/store';

const getPermissions = createSelector(
    (state: IRootManageRoleState) => state.app,
    state => state.permissions
);

const getPersonsState = createSelector(
    (state: IRootManageRoleState) => state.manageRoles,
    state => state
);

const getRolesState = createSelector(
    (state: IRootManageRoleState) => state.app.lookupRoles,
    state => state
);

const getExpandedItemIdArray = createSelector(
    (state: IRootManageRoleState) => state.manageRoles.grid.expandedItems,
    expandedItems => expandedItems
);

export const getManageRolesFilterRoles = createSelector(
    (state: IRootManageRoleState) => state.manageRoles.grid.manageRolesFilterRoles,
    state => state
);

export const getFilterKeywords = createSelector(
    (state: IRootManageRoleState) => state.manageRoles.filters.filterKeywords,
    state => state
);

export const getExpandedFilters = createSelector(
    (state: IRootManageRoleState) => state,
    state => state.manageRoles.grid.expandedFilters
);

const getIsInitialising = createSelector(
    (state: IRootManageRoleState) => state.manageRoles,
    state => state.grid.isInitialising
);

const getIsPersonsGridLoading = createSelector(
    (state: IRootPersonState) => state.persons,
    state => state.grid.isLoading
);

export const getIsExporting = createSelector(
    (state: IRootManageRoleState) => state,
    state => state.manageRoles.grid.isExporting
);

export const getOperatingPlatforms = (state: IRootState) =>
    state.operatingPlatformState.operatingPlatforms;

export default createSelector(
    getPersonsState,
    getIsInitialising,
    getExpandedItemIdArray,
    getRolesState,
    getPermissions,
    getExpandedFilters,
    getIsPersonsGridLoading,
    getIsExporting,
    getOperatingPlatforms,
    (
        state,
        isInitialising,
        expandedItems,
        lookupRoles,
        permissions,
        expandedFilters,
        isLoading,
        isExporting,
        operatingPlatforms
    ) => ({
        ...state,
        ...state.grid,
        ...state.filters,
        isInitialising,
        expandedItems,
        lookupRoles,
        permissions,
        expandedFilters,
        isLoading,
        isExporting,
        operatingPlatforms,
    })
);
