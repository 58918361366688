import * as React from 'react';
import { Help, Control, Field } from '..';
import { BulmaColor } from 'enums/BulmaColor';
import { PopoutSelect } from 'components/popoutSelect';
import { ISelectOption } from 'models/select-option';

interface IProps {
    id: string;
    label: string;
    value: string;
    options: IOption[];
    secondaryValue?: ISelectOption;
    error?: string;
    isMulti?: boolean;
    handleChange: any;
    isDisabled?: boolean;
    handleBlur?: (e: any) => void;
}

interface IOption {
    value: string;
    label: string;
}

export const PopoutSelectField: React.FC<IProps> = (props) => {
    return (
        <Field>
            <label className="label">{props.label}</label>
            <Control>
                <PopoutSelect
                    id={props.id}
                    value={props.value}
                    onSelectChange={props.handleChange}
                    options={props.options}
                    className="is-fullwidth is-medium"
                    onBlur={props.handleBlur}
                    isDisabled={props.isDisabled}
                    secondaryValue={props.secondaryValue}
                />
            </Control>
            <Help bulmaColor={BulmaColor.Danger} isShown={true}>
                {props.error}
            </Help>
        </Field>
    );
};
