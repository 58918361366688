import * as React from 'react';
import { IEngineer, IEngineersFilters, IKeyValue } from 'models';
import { Loader } from 'components/loader';
import { EngineersList } from 'components/engineers/engineers-list';
import { EngineersGridToolbarContainer } from 'components/engineers-grid-toolbar/engineers-grid-toolbar-container';
import { EngineersFilters } from 'components/engineers-filters/engineers-filters';
import { OverlayPanel } from 'components/v2/components';
import Page from 'components/v2/page/Page';
import { checkFilterExistsWithExceptions } from 'utils/array-utils';
import { IPerson } from 'models/person';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';
import { ISiteSkillCard } from 'models/competency-dna/site-skill-card';

export interface IEngineersProps {
    siteId: string;
    engineers: IEngineer[];
    isLoading: boolean;
    lookupSites: Array<IKeyValue<string>>;
    secondarySortColumn: string;
    secondarySortAscending: boolean;
    isExportingEngineers: boolean;
    filters: IEngineersFilters;
    currentPerson: IPerson;
    permissions: Array<IKeyValue<string>>;
    loadEngineers(filters: IEngineersFilters): void;
    onSort: (key: string, sortAscending: boolean) => void;
    intl: any;
    siteSkillCards: ISiteSkillCard[];
}

export interface IEngineersState {
    showFilters: boolean;
    filters: IEngineersFilters;
    sortColumn: string;
    sortAscending: boolean;
}

class EngineersClass extends React.Component<IEngineersProps, IEngineersState> {
    constructor(props: IEngineersProps) {
        super(props);
        this.state = {
            showFilters: false,
            sortColumn: 'LastName',
            sortAscending: false,
            filters: {
                sites: [this.props.siteId],
                levels: [],
                filterKeywords: [],
            },
        };
    }

    public componentWillReceiveProps(nextProps: IEngineersProps) {
        if (nextProps.siteId !== this.props.siteId) {
            const filters = { ...this.state.filters, sites: [nextProps.siteId] };
            this.setState({ filters });
            this.props.loadEngineers(filters);
        }
    }
    public componentDidMount() {
        if (this.props.siteId) {
            this.props.loadEngineers(this.state.filters);
        }
    }

    public render() {
        const props = this.props;
        const isFilterExists = checkFilterExistsWithExceptions(this.state.filters, ['sites']);
        const intl = props.intl;

        return (
            <Page title={translate(intl, 'SideNavBar.Labels.DCProfessionals')} className="page">
                <OverlayPanel
                    title={translate(intl, 'RiskRegisterPage.Title.Filters')}
                    isVisible={this.state.showFilters}
                    onClose={() => this.setState({ showFilters: false })}
                    onOutsideDialogClick={() => this.setState({ showFilters: false })}
                    onClearFilters={this.onClearFilters}
                    isFilterExists={isFilterExists}
                >
                    <EngineersFilters
                        onFilter={this.onFilter}
                        filters={this.state.filters}
                        onAddFilterKeyword={this.onAddFilterKeyword}
                        onRemoveFilterKeyword={this.onRemoveFilterKeyword}
                    />
                </OverlayPanel>
                <EngineersGridToolbarContainer
                    onShowFilters={() => this.setState({ showFilters: true })}
                    onClearFilters={this.onClearFilters}
                    isFilterExists={isFilterExists}
                />
                <Loader loading={props.isLoading}>
                    <EngineersList
                        filters={this.state.filters}
                        {...props}
                        onSort={this.onSort}
                        sortColumn={this.state.sortColumn}
                        sortAscending={this.state.sortAscending}
                        siteSkillCards={this.props.siteSkillCards}
                    />
                </Loader>
            </Page>
        );
    }

    private onSort = (key: string, sortAscending: boolean) => {
        this.props.onSort(key, sortAscending);
        this.setState({
            sortAscending,
            sortColumn: key,
        });
    };

    private onAddFilterKeyword = (keyword: string) => {
        const filters = { ...this.state.filters };
        const keywords = [
            keyword,
            ...filters.filterKeywords.slice(0, filters.filterKeywords.length),
        ];
        filters.filterKeywords = keywords;
        this.setState({ filters });
        this.props.loadEngineers(filters);
    };

    private onClearFilters = () => {
        const filters = {
            sites: [this.props.siteId],
            levels: [],
            filterKeywords: [],
        } as IEngineersFilters;
        this.setState({ filters });
        this.props.loadEngineers(filters);
    };

    private onRemoveFilterKeyword = (keyword: string) => {
        const filters = { ...this.state.filters };
        const index = filters.filterKeywords.indexOf(keyword);
        const keywords = [
            ...filters.filterKeywords.slice(0, index),
            ...filters.filterKeywords.slice(index + 1),
        ];
        filters.filterKeywords = keywords;
        this.setState({ filters });
        this.props.loadEngineers(filters);
    };

    private onFilter = (key: Extract<keyof IEngineersFilters, string>, element: string) => {
        const filters = { ...this.state.filters };
        const index = filters[key].indexOf(element);
        if (index !== -1) {
            const items = [...filters[key].slice(0, index), ...filters[key].slice(index + 1)];
            filters[key] = items;
        } else {
            const items = [element, ...filters[key].slice(0, filters[key].length)];
            filters[key] = items;
        }
        this.setState({ filters });
        this.props.loadEngineers(filters);
    };
}

export const Engineers = injectIntl(EngineersClass);
