import * as React from 'react';
import classNames from 'classnames';
import { ISelectOption } from '../../models/select-option';
import { Button } from '../v2/components';
import './PopoutSelect.scss';
import useTranslate from 'translations/translation-utils';

export interface IProps extends React.AllHTMLAttributes<HTMLSelectElement> {
    isOpen: boolean;
    isDisabled?: boolean;
    selectedValue: ISelectOption;
}

export interface IDispatchProps {
    onClose: () => void;
    onOpen: () => void;
}

export const PopupSelectDropdown: React.FC<IProps & IDispatchProps> = (props) => {
    const translate = useTranslate();
    const css = classNames(['popout-select__button', props.className]);
    const dropdownTarget = (
        <Button className={css} onClick={props.onOpen} disabled={props.isDisabled}>
            <span className="popout-select__button__text">
                {props.selectedValue ? props.selectedValue.label : translate('LogsUpload.LogType.PleaseSelect')}
            </span>
            <span className="popout-select__button__icon" />
        </Button>
    );

    return (
        <div className="popout-select__dropdown">
            {dropdownTarget}

            {(props.isOpen && (
                <>
                    <div className="popout-select__dropdown__menu" children={props.children} />
                    <div className="popout-select__dropdown__blanket" onClick={props.onClose} />
                </>
            ))}
        </div>
    );
};
