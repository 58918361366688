import { IKeyedItem } from './keyed-item';
import { IEventTemplate, IRiskTemplate } from './question-set';
import { IAuditableItem } from './auditable-item';
import { IActionTemplate } from './master-process-step/master-process-step';

export interface ICermProcessStep extends IKeyedItem, IAuditableItem {
    status: string;
    title: string;
    service: string;
    phase: string;
    pillar: string;
    processNumber: number;
    priority: string;
    reviewPeriodMonths: number;
    isPublished: boolean;
    riskTemplate: Partial<IRiskTemplate>;
    expectedOutcomes: Array<Partial<ICermOutcome>>;
    cermProcessStepsLinks?: Partial<ICermProcessStepLinks>;
    cermProcessStepPlatform?: Partial<ICermProcessStepPlatform>;
    operatingPlatform: string;
    reloadUrl?: string;
}

export interface ICermProcessStepLinks {
    trainingLink: string;
    documentLink: string;
}

export interface ICermProcessStepPlatform {
    technologyPlatform: string;
    moreInfoLink: string;
    moreInfoDisplayLabel: string;
}

export enum ActionOrEvent {
    Action = 0,
    Event = 1,
}

export interface ICermOutcome {
    id: string;
    title: string;
    compliantResponse: boolean;
    actionOrEvent: ActionOrEvent;
    action: Partial<IActionTemplate>;
    event: Partial<IEventTemplate>;
}

export const cermValidationGroup = [
    'title',
    'service',
    'phase',
    'pillar',
    'processNumber',
    'priority',
    'reviewPeriodMonths',
    'reviewPeriodMonths',
];
export enum cermProcessStepFieldTitle {
    expectedOutcomes = 'Outcome',
    riskTemplate = 'Risk Template',
    cermProcessStepsLinks = 'Links',
    cermProcessStepPlatform = 'Technology Platform',
}
