import { combineReducers } from 'redux';

import { CermOverviewActions, CermOverviewActionTypes } from '../../actions/cerm-overview-actions';
import { ICermOverviewPillar, ICermLinks } from '../../models/cerm-overview';
import { IKeyedItem } from '../../models/keyed-item';
import { IBaseState } from '../base-state';
import { CermProcessStepActions } from '../../actions/actions-v2/cerm-process-step-action-v2';
import { IAppAction } from '../../actions/app-action';
import { genericSort } from '../../utils/sort-utils';
import { UpsertCermProcessStepActions } from 'actions/upsert-cerm-process-step-actions';

export interface IConfirmDeleteProcessStep {
    stepItemToDelete?: IKeyedItem;
}

export interface ICermOverviewRootState extends IBaseState, IConfirmDeleteProcessStep {
    cermOverview: ICermOverviewGridState;
}

export interface ICermOverviewGridState {
    expandedFilters: string[];
    items: ICermOverviewPillar[];
    expandedItems: string[];
    isLoading: boolean;
    expandedPillars: string[];
    cermLinks: ICermLinks;
    isDeleting:boolean;
}

export const INITIAL_STATE: ICermOverviewGridState = {
    isLoading: false,
    expandedFilters: ['serviceFilters', 'priorityFilters', 'pillarFilters', 'phaseFilters'],
    expandedItems: [],
    items: [],
    expandedPillars: [
        '100: Administration',
        '200: Training & Qualifications',
        '300: Maintenance & Operations',
        '400: Planning, Analysis & Engineering',
        '500: Continuous Improvement',
    ],
    cermLinks: {},
    isDeleting:false
};

const cermLinks = (state = INITIAL_STATE.cermLinks, action: IAppAction) => {
    switch (action.type) {
        case CermProcessStepActions.GET_CERM_PROCESS_LINKS_FULFILLED:
            return action.payload;
        default:
            return state;
    }
};

const expandedFilters = (
    state = INITIAL_STATE.expandedFilters,
    action: CermOverviewActionTypes
) => {
    switch (action.type) {
        case CermOverviewActions.TOGGLE_FILTER_EXPANDED:
            const id = action.payload.id;

            if (state.includes(id)) {
                return state.filter((item) => item !== id);
            } else {
                if (id) {
                    return state.concat(id);
                }
            }

            return state;

        default:
            return state;
    }
};

const expandedPillars = (
    state = INITIAL_STATE.expandedPillars,
    action: CermOverviewActionTypes
) => {
    switch (action.type) {
        case CermOverviewActions.TOGGLE_PILLAR:
            const id = action.payload.id;
            if (state.includes(id)) {
                return state.filter((x) => x !== id);
            }
            return [...state, id];
        case CermOverviewActions.COLLAPSE_ALL:
            return [];
        case CermOverviewActions.EXPAND_ALL:
            return action.payload.pillars;
        default:
            return state;
    }
};

const expandedItems = (state = INITIAL_STATE.expandedItems, action: CermOverviewActionTypes) => {
    switch (action.type) {
        case CermOverviewActions.TOGGLE_PROCESS_STEP_EXPANDED:
            const id = action.payload.id;
            if (state.includes(id)) {
                return state.filter((c) => c !== id);
            }
            return [...state, id];
        case CermOverviewActions.EXPAND_ALL:
            return action.payload.steps;
        case CermOverviewActions.COLLAPSE_ALL:
            return [];
        default:
            return state;
    }
};

const items = (state = INITIAL_STATE.items, action: IAppAction) => {
    switch (action.type) {
        case CermProcessStepActions.LOAD_CERM_PROCESS_PAGE_FULFILLED:
            if (action.payload) {
                return action.payload;
            }
            return state;
        case CermProcessStepActions.SORT_CERM_PROCESS:
            const processSteps = [...state];
            const newState = processSteps.map((process) => {
                return {
                    title: process.title,
                    processSteps: genericSort(
                        action.payload.key,
                        [...process.processSteps],
                        action.payload.sortAscending
                    ),
                };
            });
            return newState;
        case UpsertCermProcessStepActions.DELETE_PROCESS_STEP_CONFIRMED_FULFILLED:
            if (action.payload) {
                const allProcessSteps = [...state];
                const newStateVariable = allProcessSteps.map((process) => {
                    return {
                        ...process,
                        processSteps: process.processSteps.filter(f => f.id !== action.payload)
                    };
                });

                return newStateVariable;
            }
            return state;
        default:
            return state;
    }
};

const isLoading = (state = INITIAL_STATE.isLoading, action: IAppAction) => {
    switch (action.type) {
        case CermProcessStepActions.LOAD_CERM_PROCESS_PAGE:
            return true;

        case CermProcessStepActions.LOAD_CERM_PROCESS_PAGE_FULFILLED:
        case CermProcessStepActions.LOAD_CERM_PROCESS_PAGE_REJECTED:
            return false;

        default:
            return state;
    }
};

const isDeleting = (state = INITIAL_STATE.isLoading, action: IAppAction) => {
    switch (action.type) {
        case UpsertCermProcessStepActions.DELETE_PROCESS_STEP_CONFIRMED:
            return true;

        case UpsertCermProcessStepActions.DELETE_PROCESS_STEP_CONFIRMED_FULFILLED:
        case UpsertCermProcessStepActions.DELETE_PROCESS_STEP_CONFIRMED_REJECTED:
            return false;

        default:
            return state;
    }
};

export const reducer = combineReducers<ICermOverviewGridState>({
    items,
    isLoading,
    expandedFilters,
    expandedPillars,
    expandedItems,
    cermLinks,
    isDeleting
});
