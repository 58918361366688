/**
 * Sort options for data table.
 */
export enum SortDirectionOpts {
  'ASC' = 'asc', // Sort in ascending order
  'DESC' = 'desc', // Sort in descending order
  'DEFAULT' = 'default', // Default sort option
}

/**
 * Alignment options for data table columns.
 */
export enum AlignmentOpts {
  'LEFT' = 'left', // Align content to the left
  'RIGHT' = 'right', // Align content to the right
  'CENTER' = 'center', // Align content to the center
}

/**
 * Input types for filter options in data table.
 */
export enum filterInputTypeOpts {
  'TEXT' = 'text', // Text input
  'NUMBER' = 'number', // Number input
  'DATE' = 'date', // Date input
  'DROPDOWN' = 'dropdown', // Dropdown input
}

/**
 * Input types for filter options
 */
export type filterInputTypeOptsType = 'text' | 'number' | 'date' | 'dropdown';

/**
 * Common filter options for text, number, and dropdown filters.
 */
export enum commonFilterOpts {
  'EQUALS' = 'equals', // Equals filter
  'NOT_EQUALS' = 'notEquals', // Not equals filter
}

/**
 * Common filter options for text, number, and dropdown filters.
 */
export type commonFilterOptsType = 'equals' | 'notEquals';

/**
 * Filter options for text filters.
 */
export enum textFilterOpts {
  'STARTS_WITH' = 'startsWith', // Starts with filter
  'CONTAINS' = 'contains', // Contains filter
  'ENDS_WITH' = 'endsWith', // Ends with filter
}

export type textFilterOptsType = 'startsWith' | 'contains' | 'endsWith';

/**
 * Filter options for number filters.
 */
export enum numberFilterOpts {
  'GREATER_THAN' = 'greterThan', // Greater than filter
  'LESS_THAN' = 'lessThan', // Less than filter
  'GREATER_THAN_OR_EQUAL' = 'greaterThanOrEqual', // Greater than or equal filter
  'LESS_THAN_OR_EQUAL' = 'lessThanOrEqual', // Less than or equal filter
}

/**
 * Filter options for number filters.
 */
export type numberFilterOptsType =
  | 'greterThan'
  | 'lessThan'
  | 'greaterThanOrEqual'
  | 'lessThanOrEqual';

/**
 * Filter options for dropdown filters.
 */
export enum dropdownFilterOpts {
  'IN' = 'in', // In filter
  'NOT_IN' = 'notIn', // Not in filter
}

/**
 * Filter options for dropdown filters.
 */
export type dropdownFilterOptsType = 'in' | 'notIn';

/**
 * Filter options for date filters.
 */
export enum dateFilterOpts {
  'AFTER' = 'after', // After filter
  'BEFORE' = 'before', // Before filter
  'BETWEEN' = 'between', // Between filter
  'NOT_BETWEEN' = 'notBetween', // Not between filter
}

/**
 * Filter options for date filters.
 */
export type dateFilterOptsType = 'after' | 'before' | 'between' | 'notBetween';

export const FilterMethodLabel = {
  contains: 'Contains',
  doesNotContain: 'Does Not Contain',
  equals: 'Equals',
  notEquals: 'Does Not Equal',
  startsWith: 'Starts With',
  endsWith: 'Ends With',
  greterThan: 'Greater Than',
  greaterThanOrEqual: 'Greater Than Or Equal',
  lessThan: 'Less Than',
  lessThanOrEqual: 'Less Than Or Equal',
  empty: 'Empty',
  notEmpty: 'Not Empty',
  after: 'After',
  before: 'Before',
  between: 'Between',
  notBetween: 'Not Between',
};
