import { IAppAction } from 'actions/app-action';
import { combineReducers } from 'redux';
import { IFacilityState } from 'state/facility-state';
import { IFacilityActions } from 'actions/actions-v2/facility-action-v2';


const INITIAL_STATE: IFacilityState = {
    isLoading: false,
    facilities: [],
};

const isLoading = (
    state: IFacilityState['isLoading'] = INITIAL_STATE.isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case IFacilityActions.LOAD_FACILITY:
            return true;
        case IFacilityActions.LOAD_FACILITY_FULFILLED:
        case IFacilityActions.LOAD_FACILITY_REJECTED:
            return false;
        default:
            return state;
    }
};

const facilities = (
    state: IFacilityState['facilities'] = INITIAL_STATE.facilities,
    action: IAppAction
) => {
    switch (action.type) {
        case IFacilityActions.LOAD_FACILITY_FULFILLED:
            if (action.payload) {
                return action.payload;
            }

        default:
            return state;
    }
};

export const IFacilityReducer = combineReducers<IFacilityState>({
    isLoading,
    facilities,
});
