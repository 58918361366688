import React from 'react';
import { Prompt } from 'react-router';
import { ConfirmDialog } from '../components';
import { history } from 'routes/App';
import useTranslate from 'translations/translation-utils';

interface IProps {
    when: boolean;
}

export const ConfirmNavigateAwayDialog: React.FC<IProps> = ({ when }) => {
    const [confirmedNavigation, setConfirmedNavigation] = React.useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
    const [lastLocation, setLastLocation] = React.useState(null);
    const translate = useTranslate();

    React.useEffect(() => {
        if (when) {
            window.addEventListener('beforeunload', handleBeforeUnload);

            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            };
        }
    }, [when]);

    React.useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            history.push(lastLocation.pathname);
        }
    }, [confirmedNavigation]);

    const handleBeforeUnload = (e) => {
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = '';
    };

    const handleBlockedNavigation = (nextLocation: any) => {
        if (!confirmedNavigation && when) {
            setLastLocation(nextLocation);
            setShowConfirmDialog(true);
            return false;
        }

        return true;
    };

    const handleConfirmNavigationClick = () => {
        setConfirmedNavigation(true);
        setShowConfirmDialog(false);
    };

    return (
        <>
            <Prompt when={when} message={handleBlockedNavigation} />
            <ConfirmDialog
                title={translate('ConfirmNavigation.Label.Confirm')}
                message={`${translate('ConfirmNavigation.Label.Info')}?`}
                isVisible={showConfirmDialog}
                onConfirm={handleConfirmNavigationClick}
                onClose={() => setShowConfirmDialog(false)}
                onOutsideDialogClick={() => setShowConfirmDialog(false)}
            />
        </>
    );
};
