import { JwtUser } from 'models/jwt-user';
import { getAuthenticationPlatform } from './authentication';

export const getUser = () => {
    return user();
};

export const isCbreUser = () => {
    const jwtUser = user();
    const email = jwtUser.unique_name;
    const domain = email.substring(email.lastIndexOf('@') + 1).toLowerCase();
    return domain.includes('cbre.');
};

const user = () => {
    const token = getAuthenticationPlatform().getAccessToken();
    const base64Url = token.split('.')[1];
    const base64 = base64Url && base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map((c) => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );
    return JSON.parse(jsonPayload) as JwtUser;
};
