import React, { useState } from 'react';
import { SelectField } from 'components/form/fields/select-field';
import { RomonetToolbar } from '../romonet-header/romonet-toolbar';
import { IKeyValue } from 'models/key-value';
import useTranslate from 'translations/translation-utils';
import { getDateRange } from 'models/romonet/romonet-page';
import { isEmpty } from 'lodash';
import { replaceCharacter } from 'utils/string-utils';
import { ISiteProfile } from 'models/site-profile';
import { Helmet } from 'react-helmet';

interface IProps {
    title: string;
    siteId?: string;
    facilities?: Array<IKeyValue<string>>;
    romonetReportType: string;
    romonetDateFilter: Array<IKeyValue<string>>;
    romonetReport: string;
    site: ISiteProfile;
    detailedHallNames?: string[];
    downloadReports?: (reportType: string, facility: string, date: string, hallName?: string) => void;
    loadReport?: (reportType: string, facility: string, date: string, hallName?: string) => void;
    loadDateFilters: (reportType: string, facility: string, hallName?: string) => void;
    loadSite?: (id: string) => void;
    resetReport?: () => void;
    loadHallNames: (reportType: string, facility: string) => void;
}

const DetailedHallReportHeader: React.FC<IProps> = ({
    title,
    siteId,
    facilities,
    romonetReportType,
    romonetReport,
    romonetDateFilter,
    site,
    detailedHallNames,
    downloadReports,
    loadReport,
    loadDateFilters,
    loadSite,
    resetReport,
    loadHallNames,
}) => {
    const [selectedFacilityId, setSelectedFacilityId] = useState(null);
    const [selectedDateId, setSelectedDateId] = useState(null);
    const [selectedHallReport, setSelectedHallReport] = useState(null);
    const translate = useTranslate();

    React.useEffect(() => {
        resetReport();
        setSelectedHallReport(null);
        setSelectedDateId(null);
    }, []);

    React.useEffect(() => {
        resetReport();
        setSelectedHallReport(null);
        setSelectedDateId(null);
        siteId && loadSite(siteId);
    }, [siteId]);

    React.useEffect(() => {
        if (!isEmpty(facilities)) {
            setSelectedFacilityId(facilities[0].value);
        }
    }, [site]);

    React.useEffect(() => {
        setSelectedHallReport(null);
        if (!isEmpty(facilities) && selectedFacilityId) {
            setSelectedDateId(null);
            loadHallNames(romonetReportType, selectedFacilityId);
        }
    }, [selectedFacilityId]);

    React.useEffect(() => {
        if (!isEmpty(detailedHallNames)) {
            setSelectedHallReport(detailedHallNames[0]);
        }
    }, [detailedHallNames]);

    React.useEffect(() => {
        if (!isEmpty(romonetDateFilter)) {
            setSelectedDateId(romonetDateFilter[0]);
        }
    }, [romonetDateFilter]);

    React.useEffect(() => {
        if (
            selectedDateId &&
            romonetDateFilter &&
            romonetDateFilter.length !== 0 &&
            selectedFacilityId &&
            selectedHallReport
        ) {
            loadReport(romonetReportType, selectedFacilityId, selectedDateId, selectedHallReport);
        }
    }, [selectedDateId]);

    React.useEffect(() => {
        if (selectedHallReport && selectedFacilityId) {
            setSelectedDateId(null);
            loadDateFilters(romonetReportType, selectedFacilityId, selectedHallReport);
        }
    }, [selectedHallReport]);

    const selectedFacilityValue = (
        facilityIds: Array<IKeyValue<string>>,
        facilityId: string
    ) => {
        if (!facilityId || isEmpty(facilityIds)) {
            return null;
        }
        const facility = facilities.find((f) => f.value === facilityId);
        if (!facility) {
            return null;
        }
        return { value: facility.value, label: facility.label, key: facility.key };
    };

    const selectedDateValue = (
        dates: Array<IKeyValue<string>>,
        selectedRomonetDateId: string
    ) => {
        if (!selectedDateId || isEmpty(facilities)) {
            return null;
        }
        const romonetDate = dates && dates.find((f) => f.key === selectedRomonetDateId);
        if (!romonetDate) {
            return null;
        }
        return {
            value: romonetDate.value,
            label: replaceCharacter(romonetDate.label, '.html'),
            key: romonetDate.key,
        };
    };

    const selectedHallNameValue = (
        hallNames: string[],
        selectedHallname: string
    ) => {
        if (!selectedHallname || isEmpty(hallNames)) {
            return null;
        }

        const report = hallNames.find((f) => f === selectedHallname);
        if (!report) {
            return null;
        }

        return { value: report, label: report, key: report };
    };
    const onFacilityChange = (field: { value: string; label: string }) => {
        if (selectedFacilityId !== field.value) {
            resetReport();
            setSelectedHallReport(null);
            setSelectedDateId(null);
        }

        setSelectedFacilityId(field.value);
    };

    const onDateChange = (field: { value: string; label: string }) => {
        setSelectedDateId(field.value);
    };

    const onHallNameChange = (field: { value: string; label: string }) => {
        if (selectedHallReport !== field.value) {
            setSelectedDateId(null);
            resetReport();
        }
        setSelectedHallReport(field.value);
    };

    const onExport = () => {
        downloadReports(romonetReportType, selectedFacilityId, selectedDateId, selectedHallReport);
    };
    return (
        <div className="page romonet-layout">
            <div className="columns">
                <div className="column is-3">
                    {title && <h1>{title}</h1>}
                    <Helmet>
                        <title>Quantum - {title}</title>
                    </Helmet>
                </div>
                <div className="column is-3">
                    <SelectField
                        id="facilityId"
                        label={translate('Romonet.Label.Faility')}
                        className="select-facility"
                        value={selectedFacilityValue(facilities, selectedFacilityId)}
                        options={facilities && facilities.map((m) => ({
                            value: m.value,
                            label: m.label,
                            key: m.key,
                        }))}
                        handleChange={onFacilityChange}
                    />
                </div>

                <div className="column is-2">
                    <SelectField
                        id="reportId"
                        label={translate('Romonet.Label.HallName')}
                        className="select-Hall-name"
                        value={selectedHallNameValue(detailedHallNames, selectedHallReport)}
                        options={
                            selectedFacilityId
                                ? detailedHallNames && detailedHallNames.map((m) => ({
                                    label: m,
                                    value: m
                                }))
                                : []
                        }
                        handleChange={onHallNameChange}
                    />
                </div>

                <div className="column is-2">
                    <div className="columns is-mobile romonet-date">
                        <SelectField
                            id="dateId"
                            label={translate('Romonet.Label.DateRange')}
                            className="select-date"
                            value={selectedDateValue(
                                getDateRange(romonetDateFilter),
                                selectedDateId
                            )}
                            options={
                                getDateRange(romonetDateFilter) &&
                                getDateRange(romonetDateFilter).map((m) => ({
                                    value: m.value,
                                    label: replaceCharacter(m.label, '.html'),
                                    key: m.key,
                                }))
                            }
                            handleChange={onDateChange}
                        />
                    </div>
                </div>
                <div className="column is-2">
                    {romonetReport && <RomonetToolbar onExport={onExport} />}
                </div>
            </div>
        </div>
    );
};

export default DetailedHallReportHeader;
