import { connect } from 'react-redux';

import actions from './actions';
import { CermSiteOverviewFilters as View } from './cerm-site-overview-filters';
import selector from './selector';

export const CermSiteOverviewFilters = connect(
    selector,
    actions
)(View);
