import * as React from 'react';
import Page from 'components/v2/page/Page';
import { RouteComponentProps } from 'react-router';
import { history } from 'routes/App';
import { TabGroup, TabItem } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';
import { Form, FormikProps, Formik } from 'formik';
import { Control, Field } from 'components/form';
import { BulmaSize } from 'enums/BulmaSize';
import { Icon, IconType } from 'components/icon';
import { IOperatingPlatformProcess } from 'models/site-profile';
import { onRouteChange } from 'actions/app-actions';
import { RouteUrlShiftHandoverOperatingPlatform } from 'routes/upsert-site/routes';
import { Select as QuantumSelect } from 'components/select';
import { ShiftsHandoverAdminToolbar } from './shift-handover-operating-platform-admin-toolbar';
import { HelpModuleContainer } from 'routes/help-module/help-module-container';
import { ModuleType } from 'models/help-module';
import { ShiftHandoverAdminPageContainer } from './shift-handover-operating-platform-admin-container';
import { ShiftHandoverCategoryTableContainer } from './shift-handover-operating-platform-category/shift-handover-operating-platform-category-table-container';
import { IPerson } from 'models/person';


interface IParams {
    id?: string;
    tab: string;
    siteId?: string;
    date?: string;
    facilityId?: string;
    operatingPlatform?: string;

}

interface IProps extends RouteComponentProps<IParams> {
    isLoading: boolean;
    person: IPerson;
    onSubmit: (request: IOperatingPlatformProcess) => void;
    onLoadPerson?: (personId: string) => void;
    loadSelectedOperatingPlatform: (operatingPlatform: string) => void;
}

enum Tab {
    shifthandoverchecklist = 'shift-handover-checklist',
    shifthandoveritemcategory = 'shift-handover-item-category',
}

interface OperatingPlatformFormValues {
    operatingPlatformDropdown: string;
}


export const ShiftHandoverAdminUpsertPage: React.FC<IProps> = ({
    match,
    isLoading,
    onSubmit,
    person,
    loadSelectedOperatingPlatform,
}) => {

    React.useEffect(() => {
        if (match.params.operatingPlatform) {
            loadSelectedOperatingPlatform(match.params.operatingPlatform);
        }
    }, [match.params.operatingPlatform]);

    const currentTab = match.params.tab.toLowerCase();
    const [showHelp, setShowHelp] = React.useState(false);

    const setNavLink = (tab: Tab) => {
        return match.path
            .replace(':tab', tab)
            .replace(':operatingPlatform', match.params.operatingPlatform)
    };

    const handleClick = (event: any, tab: Tab) => {
        event.preventDefault();
        history.push(setNavLink(tab));
    };

    const renderTab = () => {
        switch (currentTab) {
            case Tab.shifthandoverchecklist:
                return (
                    <ShiftHandoverAdminPageContainer />
                );
            case Tab.shifthandoveritemcategory:
                return (
                    <ShiftHandoverCategoryTableContainer />
                );
        }
    };

    const onClickHelp = () => {
        setShowHelp(!showHelp);
    };

    const translate = useTranslate();

    const initialValues: OperatingPlatformFormValues = {
        operatingPlatformDropdown: match.params.operatingPlatform,
    };

    const onFormSubmit = (upsertRoleRequest: IOperatingPlatformProcess) => {
        onSubmit(upsertRoleRequest);
    };

    let formikBag: FormikProps<IOperatingPlatformProcess>;

    const selectectedOperatingPlatform = (e: any) => {
        const value = e.target.value;
        formikBag.setFieldValue('operatingPlatformDropdown', value);
        loadSelectedOperatingPlatform(value);
        onRouteChange(`${RouteUrlShiftHandoverOperatingPlatform(value)}`);

    };

    const renderForm = (formik: FormikProps<IOperatingPlatformProcess>) => {
        formikBag = formik;
        if (formikBag.values.operatingPlatformDropdown !== match.params.operatingPlatform) {
            const operatingPlatform = match.params.operatingPlatform;
            if (operatingPlatform) {
                formikBag.setFieldValue('operatingPlatformDropdown', operatingPlatform);
            }
        }
        return (
            <Form id="riskProfileQuestionnaireDropdown">
                <Field
                    isHorizontal={true}
                    htmlFor={translate(
                        'RiskProfileQuestionnaire.Views.Dropdown.Title'
                    )}
                    label={translate(
                        'RiskProfileQuestionnaire.Views.Dropdown.Title'
                    )}
                    labelSize={BulmaSize.Medium}
                >
                    <Field>
                        <Control>
                            <QuantumSelect
                                label={translate(
                                    'RiskProfileQuestionnaire.Views.Dropdown.Title'
                                )}
                                id="operatingPlatformdropdown"
                                name="operatingPlatformDropdown"
                                aria-required="true"
                                options={person.accessRights.operatingPlatforms
                                    .map(x => ({
                                        key: x,
                                        value: x,
                                        label: x,
                                    }))
                                    .sort((a, b) => a.label.localeCompare(b.label))}
                                className="operatingPlatforms-dropdown"
                                value={formikBag.values.operatingPlatformDropdown}
                                onBlur={formikBag.handleBlur}
                                onChange={selectectedOperatingPlatform}
                            />
                        </Control>
                    </Field>
                </Field>
            </Form>
        );
    };

    return (

        <Page
            title={translate('ShiftHandoversAdminPage.Title')}
            className="shift-handover-admin-page"
        >
            <ShiftsHandoverAdminToolbar onClickHelp={onClickHelp} isShowHelp={true} />
            <HelpModuleContainer
                isShown={showHelp}
                onClose={onClickHelp}
                moduleType={ModuleType.ShiftHandover}
            />
            {person.accessRights.operatingPlatforms.length > 1 ? (
                <>
                    <div className="risk-profile-questionaire-dropdown">
                        <Formik<Partial<IOperatingPlatformProcess>>
                            initialValues={initialValues}
                            onSubmit={onFormSubmit}
                            render={renderForm}
                        />
                        <div className="risk-profile-questionaire-dropdown-info-box">
                            <div className="risk-profile-questionaire-dropdown-info">
                                <Icon type={IconType.InfoCircle} />
                            </div>
                            <div className="risk-profile-questionaire-dropdown-info-text">
                                {translate(
                                    'RiskProfileQuestionnaire.Views.Dropdown.Info'
                                )}
                            </div>
                        </div>
                    </div>
                    <hr />
                </>
            ) : null}

            <TabGroup isLoading={isLoading}>
                <TabItem
                    title={translate('ShiftHandoversAdminPage.Tab.ShiftHandoverChecklist')}
                    tab={Tab.shifthandoverchecklist}
                    currentTab={currentTab}
                    onClick={handleClick}
                />
                <TabItem
                    title={translate('ShiftHandoversAdminPage.Tab.HandoverCategory')}
                    tab={Tab.shifthandoveritemcategory}
                    currentTab={currentTab}
                    onClick={handleClick}
                />
            </TabGroup>
            {renderTab()}
        </Page>
    );
};
