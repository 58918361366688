export enum EmeraldToastXPosition {
  LEFT = 'LEFT',
  CENTER = 'CENTER',
  RIGHT = 'RIGHT',
}
export type EmeraldToastXPositionType = 'LEFT' | 'CENTER' | 'RIGHT';

export enum EmeraldToastYPosition {
  TOP = 'TOP',
  MIDDLE = 'MIDDLE',
  BOTTOM = 'BOTTOM',
}
export type EmeraldToastYPositionType = 'TOP' | 'MIDDLE' | 'BOTTOM';
