import React, { useEffect, useState } from 'react';
import './Nav.scss';
import { ILanguageDropdownItem } from 'models/nav-lanuage';
import { IPerson } from 'models/person';
import { getUserId } from 'helpers/helpers';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconType } from 'routes/material-icon/material-icon-type';
export interface IStateProps extends React.AllHTMLAttributes<HTMLDivElement> {
    isSelected?: boolean;
    dropdownItems: ILanguageDropdownItem[];
    personlanguage: string;
    person: IPerson;
    siteId?: string;
    isSiteLoading?: boolean
    loadPersonProfile: (id: string) => void;
    updateLanguage: (person: IPerson) => void;
    className: string;
}

export const NavLanguageDropdown: React.FC<IStateProps> = (props) => {
    const [isActive, setisActive] = useState(true);
    useEffect(() => {
        if (!getUserId()) {
            return;
        }
        props.loadPersonProfile(getUserId());
    }, [getUserId()]);

    const onChangeLanguage = (language) => {
        setisActive(!isActive);
        const personProfile = { ...props.person };
        personProfile.language = language;
        personProfile.isLanguageChange = true;
        props.updateLanguage(personProfile);
    };
    return (
        <div
            className={`language-dropdown dropdown ${props.className} ${isActive ? 'is-hoverable' : ''}`}
            onMouseEnter={() => {
                setisActive(true);
            }}
        >
            <div className="dropdown-trigger">
                <button className="button" aria-haspopup="true" aria-controls="dropdown-menu3">
                    <span className="icon is-small">
                        < MaterialIcons type={MaterialIconType.Language} />
                    </span>
                    <span className="dropdown-text">
                        {props.personlanguage ? props.personlanguage : 'English'}
                    </span>
                    <span className="icon is-small icon-arrow">
                        < MaterialIcons type={MaterialIconType.AngleDown} />
                    </span>
                </button>
            </div>
            <div className="dropdown-menu" id="dropdown-menu3" role="menu">
                <div className="dropdown-content">
                    {props.dropdownItems.map((dropDownItem) => {
                        return (
                            <a
                                key={dropDownItem.key}
                                href="#"
                                className="dropdown-item"
                                onClick={() => onChangeLanguage(dropDownItem.key)}
                            >
                                {dropDownItem.display}
                            </a>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
