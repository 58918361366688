import * as React from 'react';
import {
    IToolbarIconItem,
    ToolbarIconType,
    IconToolbar
} from 'components/v2/toolbar/IconToolbar';
import useTranslate from 'translations/translation-utils';

export interface IProps {
    isFilterExists?: boolean;
    isShowHelp: boolean;
    onShowFilters: () => void;
    onClearFilters: () => void;
    onClickHelp: () => void;

}

export const RiskAssessmentToolbar: React.FC<IProps> = ({ onClickHelp, onShowFilters, onClearFilters, isFilterExists, isShowHelp }) => {
    const translate = useTranslate();
    const getToolbarItems = (): IToolbarIconItem[] => {
        return [
            {
                id: 'clear-all-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.ClearFilters'),
                type: isFilterExists ? ToolbarIconType.filterApplied : ToolbarIconType.clearFilter,
                onClick: onClearFilters
            },
            {
                id: 'show-filters-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.ShowFilters'),
                type: ToolbarIconType.filter,
                onClick: onShowFilters
            },
            isShowHelp && {
                id: 'show-help',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.Help'),
                type: ToolbarIconType.help,
                onClick: onClickHelp,
            },
        ];
    };

    return <IconToolbar items={getToolbarItems()} />;
};
