import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from './../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { EngineerActions } from 'actions/actions-v2';
import { IEngineer } from 'models';

const controllerName = 'engineer';

const createActions = {
    action: EngineerActions.CREATE_ENGINEER,
    actionFulfilled: EngineerActions.CREATE_ENGINEER_FULFILLED,
    actionRejected: EngineerActions.CREATE_ENGINEER_REJECTED,
} as IActionType;

const updateActions = {
    action: EngineerActions.UPDATE_ENGINEER,
    actionFulfilled: EngineerActions.UPDATE_ENGINEER_FULFILLED,
    actionRejected: EngineerActions.UPDATE_ENGINEER_REJECTED,
} as IActionType;

const getAllActions = {
    action: EngineerActions.LOAD_ENGINEERS,
    actionFulfilled: EngineerActions.LOAD_ENGINEERS_FULFILLED,
    actionRejected: EngineerActions.LOAD_ENGINEERS_REJECTED,
} as IActionType;

const getActions = {
    action: EngineerActions.LOAD_ENGINEER,
    actionFulfilled: EngineerActions.LOAD_ENGINEER_FULFILLED,
    actionRejected: EngineerActions.LOAD_ENGINEER_REJECTED,
} as IActionType;

const deleteActions = {
    action: EngineerActions.DELETE_ENGINEER,
    actionFulfilled: EngineerActions.DELETE_ENGINEER_FULFILLED,
    actionRejected: EngineerActions.DELETE_ENGINEER_REJECTED,
} as IActionType;

const loadHistory = {
    action: EngineerActions.LOAD_HISTORY,
    actionFulfilled: EngineerActions.LOAD_HISTORY_FULFILLED,
    actionRejected: EngineerActions.LOAD_HISTORY_REJECTED,
} as IActionType;

const exportActions = {
    action: EngineerActions.EXPORT_ENGINEER,
    actionFulfilled: EngineerActions.EXPORT_ENGINEER_FULFILLED,
    actionRejected: EngineerActions.EXPORT_ENGINEER_REJECTED,
} as IActionType;

const exportAllEngineerActions = {
    action: EngineerActions.EXPORT_ALL_ENGINEERS,
    actionFulfilled: EngineerActions.EXPORT_ALL_ENGINEERS_FULFILLED,
    actionRejected: EngineerActions.EXPORT_ALL_ENGINEERS_REJECTED,
} as IActionType;

const createEngineerEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createActions, '/Engineers').post<IEngineer>(`/api/${controllerName}`);
const updateEngineerEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateActions).put<IEngineer>(`/api/${controllerName}`);
const loadEngineersEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getAllActions).get<IEngineer[]>(`/api/${controllerName}`);
const loadEngineerEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getActions).getById<IEngineer>(`/api/${controllerName}`);
const deleteEngineerEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, deleteActions, '/Engineers').deleteById<string>(`/api/${controllerName}`);
const loadHistoryEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, loadHistory).getById<IEngineer>(`/api/${controllerName}/history`);
const exportEngineersEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, exportActions).exportById<IEngineer[]>(`/api/${controllerName}/export`);
const exportAllEngineersEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, exportAllEngineerActions).get<IEngineer[]>(
        `/api/${controllerName}/exportAll`
    );
export const engineerEpicsV2 = combineEpics(
    createEngineerEpic,
    updateEngineerEpic,
    loadEngineersEpic,
    loadEngineerEpic,
    deleteEngineerEpic,
    exportEngineersEpic,
    loadHistoryEpic,
    exportAllEngineersEpic
);
