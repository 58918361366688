import { ActionActions, ActionActionTypes } from '../../../../actions/action-actions';
import { IKeyedItem } from '../../../../models/keyed-item';

export default {
    onOpen: (item: IKeyedItem): ActionActionTypes => ({
        type: ActionActions.SHOW_HISTORY,
        payload: {
            id: item.id,
            siteId: item.siteId
        }
    }),

    onClose: (item: IKeyedItem): ActionActionTypes => ({
        type: ActionActions.HIDE_HISTORY,
        payload: {
            id: item.id,
            siteId: item.siteId
        }
    }),

    onRetry: (item: IKeyedItem): ActionActionTypes => ({
        type: ActionActions.RELOAD_HISTORY,
        payload: {
            id: item.id,
            siteId: item.siteId
        }
    })
};
