import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { ExternalBuildingsActions } from 'actions/actions-v2/external-buildings-action-v2';
import { IExternalBuilding } from 'models/building';

const controllerName = 'building';
const apiPath = `/api/${controllerName}`;

const getActions = {
    action: ExternalBuildingsActions.LOAD_ELOG_BOOKS_BUILDINGS,
    actionFulfilled: ExternalBuildingsActions.LOAD_ELOG_BOOKS_BUILDINGS_FULFILLED,
    actionRejected: ExternalBuildingsActions.LOAD_ELOG_BOOKS_BUILDINGS_REJECTED,
} as IActionType;

const loadExternalBuildingsBuildingsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getActions).get<IExternalBuilding>(apiPath);

export const externalBuildingsEpics = combineEpics(loadExternalBuildingsBuildingsEpic);
