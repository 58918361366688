import { Form, FormikProps } from 'formik';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ConfirmNavigateAwayDialog } from 'components/v2/dialog/ConfirmNavigateAwayDialog';
import { ArticlePanel } from 'components/panel';
import { Context, Tier } from 'components/questionnaire/Context';
import { QuestionSiteType } from 'components/questionnaire/questions/question-site-type';
import StepProgressBar from 'components/step-progress-bar/StepProgressBar';
import { IBuilding } from 'models/building';
import { ISiteProfile } from 'models/site-profile';
import { history } from 'routes/App';
import { RouteUrlBuilding } from '../upsert-site/routes';
import actions from './actions';
import './questionnaire.scss';
import { translate } from 'translations/translation-utils';
import { injectIntl } from 'react-intl';

interface IProps extends RouteComponentProps {
    id: string;
    formikBag: FormikProps<Partial<ISiteProfile>>;
    onChangeRoot: (urlRoute: string) => void;
    onSubmit: (siteId: string, building: Partial<IBuilding>) => void;
    intl: any;
}

interface IQuestionnaireState {
    shouldNavigateAway: boolean;
    questionnaireContext: Context;
    displayValue: JSX.Element;
}

class QuestionnaireClass extends React.PureComponent<IProps, IQuestionnaireState> {
    public state = {
        questionnaireContext: new Context(new QuestionSiteType()),
        displayValue: <div>Loading ... </div>,
        shouldNavigateAway: true,
    };

    public componentDidMount() {
        const id = this.props.location.search.slice(this.props.location.search.length - 1);
        this.state.questionnaireContext.siteId = this.props.id;
        this.state.questionnaireContext.id = id;
        if (this.props.formikBag.values.buildings[Number(id)]) {
            const tierType =
                this.props.formikBag.values.buildings[Number(id)].tier === 'I' ||
                    this.props.formikBag.values.buildings[Number(id)].tier === 'II'
                    ? Tier.HighSpec
                    : Tier.LowSpec;
            this.state.questionnaireContext.tier = tierType;
        }

        this.state.questionnaireContext.handleRender = this.handleRender.bind(this);
        this.state.questionnaireContext.handleSubmit = this.handleSubmit.bind(this);
        this.state.questionnaireContext.handleNavigate = this.handleNavigate.bind(this);
        const display = this.state.questionnaireContext.display();
        this.setState({ displayValue: display });
    }

    public componentWillReceiveProps(props: any) {
        const questionnaireContext = this.state.questionnaireContext;
        if (props.history.action === 'POP') {
            questionnaireContext.back();
        }
    }

    public render() {
        const intl = this.props;

        return (
            <ArticlePanel className="column">
                <ConfirmNavigateAwayDialog when={this.state.shouldNavigateAway} />
                <header>
                    <h1 className="title is-4">{translate(intl, 'Questionnaire.Page.Header')}</h1>
                    <small>{translate(intl, 'Questionnaire.Page.Header2')}</small>
                </header>
                <Form className="questionnaire">
                    <StepProgressBar
                        model={this.state.questionnaireContext.getProgressBarModel()}
                    />
                    {this.state.displayValue}
                </Form>
            </ArticlePanel>
        );
    }

    private handleRender() {
        const questionnaireContext = this.state.questionnaireContext;
        this.setState({
            displayValue: questionnaireContext.display(),
        });
    }
    private async handleNavigate(shouldNavigateAway: boolean = false) {
        await this.setState({ shouldNavigateAway });
    }
    private async handleSubmit(value: string, buildindId: string) {
        await this.setState({ shouldNavigateAway: false });
        const prefix = `buildings[${buildindId}]`;
        const field = (name: Extract<keyof IBuilding, string>) => `${prefix}.${name}`;
        await this.props.formikBag.setFieldValue(field('questionnaireResult'), Number(value));
        this.props.onSubmit(
            this.props.formikBag.values.id,
            this.props.formikBag.values.buildings[Number(buildindId)]
        );
        history.push({
            pathname: `${RouteUrlBuilding(this.props.id)}/${buildindId}`,
            hash: 'chart',
        });
    }
}

export default withRouter(connect(null, actions)(injectIntl(QuestionnaireClass)));
