import React from 'react';
import { IconToolbar, IToolbarIconItem, ToolbarIconType } from 'components/v2/components';

interface IProps {
    isExporting: boolean;
    onExport: () => void;
}

export const ShiftHandlerPageToolbar: React.FC<IProps> = ({ isExporting, onExport }) => {
    const getToolbarItems = (): IToolbarIconItem[] => {
        return [
            {
                id: 'export-button',
                title: 'Export Shift Handler',
                type: ToolbarIconType.download,
                isDisabled: isExporting,
                onClick: onExport,
            },
        ];
    };

    return <IconToolbar items={getToolbarItems()} />;
};
