import { ActionActions, ActionActionTypes } from '../../../../actions/action-actions';
import { RiskActions, RiskActionTypes } from '../../../../actions/risk-actions';
import { IKeyedItem } from '../../../../models/keyed-item';
import { loadRiskActions } from 'actions/actions-v2/action-action-v2';

export default {
    onOpen: (item: IKeyedItem): RiskActionTypes => ({
        type: RiskActions.SHOW_LINKED_ACTIONS,
        payload: {
            id: item.id,
            siteId: item.siteId
        }
    }),
    loadRiskActions,
    onClose: (item: IKeyedItem): RiskActionTypes => ({
        type: RiskActions.HIDE_LINKED_ACTIONS,
        payload: {
            id: item.id,
            siteId: item.siteId
        }
    }),

    onShowUpsertActionModal: (riskId: string, siteId: string): ActionActionTypes => ({
        type: ActionActions.SHOW_UPSERT_ACTION_MODAL,
        payload: {
            item: {
                riskItemId: riskId,
                siteId,
                dueDate: new Date().toISOString(),
                reminderDate: new Date().toISOString(),
            }
        }
    }),
};
