import { IAppAction } from 'actions/app-action';
import { combineReducers } from 'redux';
import { IAuditorState } from 'state/iauditor-state';
import { IauditorActions } from 'actions/actions-v2/iauditor-action';

const INITIAL_STATE: IAuditorState = {
    isLoading: false,
    complianceCheckList: {
        inspections: [],
        isTokenExist: false,
        refreshedAt: "",
        isRefreshing: false,
    },
    templates: null,
    isLoadingTemplate: false,
};

const isLoading = (
    state: IAuditorState['isLoading'] = INITIAL_STATE.isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case IauditorActions.LOAD_IAUDITOR_COMPLIANCE_CHECKLIST:
            return true;
        case IauditorActions.LOAD_IAUDITOR_COMPLIANCE_CHECKLIST_FULFILLED:
        case IauditorActions.LOAD_IAUDITOR_COMPLIANCE_CHECKLIST_REJECTED:
            return false;

        default:
            return state;
    }
};

const isLoadingTemplate = (
    state: IAuditorState['isLoadingTemplate'] = INITIAL_STATE.isLoadingTemplate,
    action: IAppAction
) => {
    switch (action.type) {
        case IauditorActions.LOAD_IAUDITOR_TEMPLATE:
            return true;
        case IauditorActions.LOAD_IAUDITOR_TEMPLATE_FULFILLED:
        case IauditorActions.LOAD_IAUDITOR_TEMPLATE_REJECTED:
            return false;

        default:
            return state;
    }
};
const complianceCheckList = (
    state: IAuditorState['complianceCheckList'] = INITIAL_STATE.complianceCheckList,
    action: IAppAction
) => {
    switch (action.type) {
        case IauditorActions.LOAD_IAUDITOR_COMPLIANCE_CHECKLIST_FULFILLED:
            if (action.payload) {
                return action.payload;
            }

        default:
            return state;
    }
};

const templates = (
    state: IAuditorState['templates'] = INITIAL_STATE.templates,
    action: IAppAction
) => {
    switch (action.type) {
        case IauditorActions.LOAD_IAUDITOR_TEMPLATE_FULFILLED:
            if (action.payload) {
                return action.payload;
            }

        default:
            return state;
    }
};

export const IauditorReducer = combineReducers<IAuditorState>({
    isLoading,
    complianceCheckList,
    templates,
    isLoadingTemplate,
});
