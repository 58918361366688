import * as React from 'react';
import { IOpportunity } from 'models/opportunity-question-set';
import { Table, Panel, TableHeader, SortOrder } from 'components/v2/components';
import { sortArrayBy } from 'utils/sort-utils';
import { onRouteChange } from 'actions/app-actions';
import { usePageUIPreferences, UiPreferences } from 'utils/page-ui-preferences-utils';
import useTranslate from 'translations/translation-utils';
interface IProps {
    opportunities: IOpportunity[];
}

const OpportunityQuestionSets: React.FC<IProps> = ({ opportunities }) => {
    const translate = useTranslate();
    const [sortField, setSortField] = usePageUIPreferences(
        UiPreferences.ActionsPageSortField,
        'actionLevel'
    );
    const [sortOrder, setSortOrder] = usePageUIPreferences(
        UiPreferences.ActionsPageSortOrder,
        SortOrder.off
    );
    const openDetails = (id: string) => {
        onRouteChange(`Opportunity/${id}`);
    };
    const getSortState = (field: string): SortOrder => {
        if (field === sortField) {
            return sortOrder;
        }
        return SortOrder.off;
    };
    const onToggleHandler = (field: string) => {
        if (sortField !== field) {
            setSortField(field);
            setSortOrder(SortOrder.asc);
            return;
        }
        const sort = sortOrder === SortOrder.asc ? SortOrder.desc : SortOrder.asc;
        setSortOrder(sort);
    };
    const getSortedList = (): IOpportunity[] => {
        if (sortOrder === SortOrder.off) {
            return opportunities;
        }
        return sortOrder === SortOrder.asc
            ? sortArrayBy(sortField, opportunities, true)
            : sortArrayBy(sortField, opportunities, false);
    };
    const getSortableTableHeader = (
        key: Extract<keyof IOpportunity, string>,
        displayName: string,
        className?: string
    ) => {
        return (
            <TableHeader
                sortState={getSortState(key)}
                onToggle={() => onToggleHandler(key)}
                className={className}
            >
                {displayName}
            </TableHeader>
        );
    };

    return (
        <Panel title={translate('OpportunityQ.QuestionSet.PageListTitle')} type="info">
            <Table>
                <thead>
                    <tr>
                        {getSortableTableHeader('title', translate('OpportunityQ.QuestionSet.HeaderTitle'))}
                        {getSortableTableHeader('category', translate('OpportunityQ.QuestionSet.HeaderCategory'))}
                        {getSortableTableHeader('isDraft', translate('OpportunityQ.QuestionSet.HeaderStatus'))}
                    </tr>
                </thead>
                <tbody className="clickable">
                    {getSortedList().map((item, index) => (
                        <tr key={`action-row-${index}`} onClick={() => openDetails(item.id)}>
                            <td>{item.title}</td>
                            <td>{item.category}</td>
                            <td>{item.isDraft ? translate('OpportunityQ.QuestionSet.StatusDraft') : translate('OpportunityQ.QuestionSet.StatusPublished')}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Panel>
    );
};

export default OpportunityQuestionSets;
