
import { connect } from 'react-redux';
import { ClientBackToAllSitesPage } from './client-back-to-all-sites-page';
import { createSelector } from 'reselect';
import { getCurrentSelectedSiteId } from 'sharedSelectors';
import { onSiteChange } from 'actions/app-actions';

const mapStateToProps = createSelector(
    getCurrentSelectedSiteId,
    (siteId) => ({
        siteId
    })
);

const mapDispatchToProps = {
    onSiteChange
};

export const ClientBackToAllSitesContainer = connect(mapStateToProps, mapDispatchToProps)(ClientBackToAllSitesPage);
