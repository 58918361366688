import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { MockDrillReportDetailsReadOnlyTab } from './mock-drill-report-details-readonly-tab';
import { IRootState } from 'routes/store';

const getMockDrillReportsSummary = (state: IRootState) =>
    state.mockDrillReportState.mockDrillReportsSummary;

const mapStateToProps = createSelector(getMockDrillReportsSummary, (mockDrillReportsSummary) => ({
    mockDrillReportsSummary,
}));

export const MockDrillReportDetailsReadOnlyTabContainer = connect(mapStateToProps)(
    MockDrillReportDetailsReadOnlyTab
);
