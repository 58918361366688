import { connect } from 'react-redux';

import actions from './actions';
import { LinkedPeople as View } from './linked-people';
import selector from './selector';

export const LinkedPeople = connect(
    selector,
    actions
)(View);
