import { TimeUnit } from 'chart.js';
import * as React from 'react';
import { Line } from 'react-chartjs-2';
import { enumEnergyBillPeriods } from 'models/building';
import { IKeyValue } from 'models/key-value';

interface IProps {
  points?: Array<IKeyValue<number>>;
  energyBillPeriod: enumEnergyBillPeriods;
}

export const LineChart: React.FC<IProps> = (props) => {
  const data = {
    labels: props.points.map((r) => r.key),
    datasets: [
      {
        label: '',
        backgroundColor: '#3e7ca6',
        fill: true,
        showLine: true,
        spanGaps: false,
        pointRadius: 0,
        pointHoverRadius: 0,
        data: props.points.map(() => 1)
      },
      {
        label: 'Actual PUE',
        backgroundColor: '#3e7ca6',
        fill: true,
        spanGaps: false,
        showLine: props ? true : false,
        data: props.points.map((m) => m.value)
      },
    ]
  };

  const options: any = {
    legend: {
      display: false
    },
    title: {
      text: 'Actual PUE',
      display: false
    },
    maintainAspectRatio: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        },
        scaleLabel: {
          labelString: 'PUE',
          display: true
        }
      }],
      xAxes:
        [{
          type: 'time',
          time: {
            unit: props.energyBillPeriod === enumEnergyBillPeriods.month ? 'month' as TimeUnit : 'week' as TimeUnit,
            displayFormats: {
              week: 'DD MMM',
              month: 'MMM YY'
            }
          },
          scaleLabel: {
            labelString: props.energyBillPeriod === enumEnergyBillPeriods.month ? 'Month' : 'Week',
            display: true
          }
        }]
    }
  };

  return (
    <React.Fragment>
      <div style={{ margin: '0 auto', textAlign: 'center' }}>PUE Actuals Over Time</div>
      <Line
        data={data}
        width={600}
        height={300}
        options={options}
      />
    </React.Fragment>
  );
};
