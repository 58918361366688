import INavItem from 'models/nav-item';
import { IKeyValue } from 'models';
import { checkPermissions, checkSiteFeature } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import { SiteFeatures } from 'actions/site-feature';

export const getHealthcareNavItems = (
    siteId: string,
    permissions: IKeyValue<string>[],
    currentSiteFeatures: string[]
) => {
    const navItems: INavItem[] = [
        {
            text: 'Dashboard',
            link: '/Dashboard',
            isVisible: true,
        },
        {
            text: 'HERM Assessment',
            link: '/CermAssessment',
            isVisible: true,
        },
        {
            text: 'HERM 100 Administration',
            link: '/',
            isVisible: true,
            children: [
                {
                    text: 'HERM 100 - Healthcare Client Contract Review',
                    link: checkPermissions([Privilege.IAuditorView], permissions)
                        ? '/ComplianceCheckList/100/documents'
                        : '/ComplianceCheckList/100/opportunityQuestionnaire',
                    isVisible: true,
                },
                {
                    text: 'HERM 101 - Healthcare Critical Environment Risk Communication',
                    link: '/ComplianceCheckList/101/documents',
                    isVisible: false,
                },

                {
                    text: 'HERM 101.1 - Lesson\'s learned',
                    link: '/MockDrills/LessonsLearned',
                    isVisible: true,
                },
                {
                    text: 'HERM 101.1 - Toolbox talks',
                    link: '/MockDrills/ToolboxTalks',
                    isVisible: true,
                },
            ],
        },
        {
            text: 'HERM 200 Training & Qualifications',
            link: '/',
            isVisible: true,
            children: [
                {
                    text: 'HERM 200.1 DNA Engineer Assessment',
                    link: '/Engineers',
                    isVisible: false
                },
                {
                    text: 'HERM 200.1 Competency DNA Processes',
                    link: '/CompetencyDNAProcess/M&E',
                    isVisible:
                        checkPermissions([Privilege.CompetencyDNAProcess], permissions) &&
                        checkSiteFeature(SiteFeatures.CDNA, currentSiteFeatures),
                },
                {
                    text: 'HERM 200.2 Shifts and Gaps',
                    link: '/Shifts',
                    isVisible: false
                },
                {
                    text: 'HERM 204 Mock Drills',
                    link: '/MockDrills/MockDrills',
                    isVisible:
                        checkPermissions(
                            [Privilege.MockDrillsRead, Privilege.MockDrillsReadWrite],
                            permissions
                        ) && checkSiteFeature(SiteFeatures.MockDrills, currentSiteFeatures),
                },
            ],
        },
        {
            text: 'HERM 300 Maintenance & Operations (M&E)',
            link: '/',
            isVisible: false,
            children: [
                {
                    text: 'HERM 301 - Healthcare Critical Plant - Area Labelling',
                    link: '/ComplianceCheckList/301/documents',
                    isVisible: true,
                },
                {
                    text: 'HERM 304 Critical Spares',
                    link: '/CriticalSystemSpares',
                    isVisible:
                        checkPermissions([Privilege.CriticalSystemsView], permissions) &&
                        checkSiteFeature(SiteFeatures.CriticalSpares, currentSiteFeatures),
                },
                {
                    text: 'HERM 310 - Healthcare Pre-Impairment Checklist',
                    link: '/ComplianceCheckList/310/documents',
                    isVisible: true,
                },
                {
                    text: 'HERM 311 Incidents and Near Misses',
                    link: '/Incidents',
                    isVisible: checkSiteFeature(SiteFeatures.Incidents, currentSiteFeatures),
                },
                {
                    text: 'HERM 312 Rounds and Readings',
                    link: '/ComplianceCheckList/312/documents',
                    isVisible: true,
                },
                {
                    text: 'HERM 313 Shift Handover',
                    link: '/ShiftHandovers',
                    isVisible:
                        checkPermissions([Privilege.ShiftHandover], permissions) &&
                        checkSiteFeature(SiteFeatures.CDNA, currentSiteFeatures),
                },
            ],
        },
        {
            text: 'HERM 350 Maintenance & Operations (IT)',
            link: '/',
            isVisible: false,
            children: [
                {
                    text: 'HERM 350 - Healthcare IT Energy Efficiency Management',
                    link: '/ComplianceCheckList/350/documents',
                    isVisible: true,
                },
                {
                    text: 'HERM 351 - Healthcare Energy Consumption Recording Process',
                    link: '/ComplianceCheckList/351/documents',
                    isVisible: true,
                },
                {
                    text: 'HERM 354 - Healthcare IT Equipment Installation Check List',
                    link: '/ComplianceCheckList/354/documents',
                    isVisible: true,
                },
                {
                    text: 'HERM 358 - Healthcare IT Equipment Receipt Form',
                    link: '/ComplianceCheckList/358/documents',
                    isVisible: true,
                },
                {
                    text: 'HERM 359 - Healthcare IT Equipment Release Form',
                    link: '/ComplianceCheckList/359/documents',
                    isVisible: true,
                },
                {
                    text: 'HERM 369 - Healthcare IT Project Acceptance Form',
                    link: '/ComplianceCheckList/369/documents',
                    isVisible: true,
                },
                {
                    text: 'HERM 372 - Healthcare IT Service Compliance Check List',
                    link: '/ComplianceCheckList/372/documents',
                    isVisible: true,
                },
            ],
        },
        {
            text: 'HERM 400 Planning, Analysis & Engineering',
            link: '/',
            isVisible: false,
            children: [
                {
                    text:
                        'HERM 400 - Healthcare Critical Project Handover - Project Close Out Form',
                    link: '/ComplianceCheckList/400/documents',
                    isVisible: true,
                },
            ],
        },
        {
            text: 'HERM 500 Continuous Improvement',
            link: '/',
            isVisible: true,
            children: [
                {
                    text: 'Actions',
                    link: '/Actions',
                    isVisible: checkPermissions([Privilege.ActionView], permissions),
                },
                {
                    text: 'HERM 502 Risk Register',
                    link: '/Risks', //--404
                    isVisible: checkPermissions([Privilege.RiskView], permissions),
                },
                {
                    text: 'HERM 502 Client Risk Register',
                    link: '/RiskRegisterClient', //--404
                    isVisible: checkPermissions([Privilege.ClientRiskRegister], permissions),
                },
                {
                    text: 'HERM 502 Risk Profile Questionnaire',
                    link: '/RiskProfileQuestionnaire',
                    isNotSingleSiteRelated: false,
                    isVisible:
                        checkPermissions([Privilege.RiskProfileQuestionnaire], permissions) &&
                        checkSiteFeature(SiteFeatures.RiskProfiles, currentSiteFeatures),
                },
                {
                    text: 'HERM 502 Risk Questions',
                    link: '/RiskAssessment',
                    isVisible: true,
                },
            ],
        },
        {
            text: 'HERM 600 Regulatory Compliance',
            link: '/',
            isVisible: false,
            children: [
                {
                    text: 'HERM 603 Reporting Fire Safety Incidents',
                    link: '/HERM603RegulatoryCompliance', //--404
                    isVisible: true,
                },
                {
                    text: 'HERM 604 Report Utility System Incidents',
                    link: '/HERM604RegulatoryCompliance', //--404
                    isVisible: true,
                },
                {
                    text: 'HERM 605.1 Fire Safety Annual Evaluations',
                    link: '/FireSafetyEvaluations', //--404
                    isVisible: true,
                },
                {
                    text: 'HERM 605.2 Utility Systems Annual Evaluations',
                    link: '/UtilitySystemsEvaluations', //--404
                    isVisible: true,
                },
                {
                    text: 'HERM 622.1 Equipment Inventory-Category-Risk Report',
                    link: '/EquipmentInventoryCategoryRiskReport', //--404
                    isVisible: true,
                },
            ],
        },
        {
            text: 'Quantum Analytics',
            link: '/QuantumAnalytics',
            isNotSingleSiteRelated: true,
            isVisible: checkPermissions([Privilege.ReportTableau], permissions),
        },
        {
            text: 'Site',
            link: '/',
            isVisible: true,
            children: [
                {
                    text: 'Site Documents',
                    link: '/SiteDocuments',
                    isVisible: checkSiteFeature(SiteFeatures.SiteDocuments, currentSiteFeatures),
                },
                {
                    text: 'Site HERM Process Owners',
                    link: '/HERMProcessOwners',
                    isVisible: false,
                },
                {
                    text: 'Site & Facilities',
                    link: `/Site/Upsert/${siteId}/Form/SiteDetails`,
                    isVisible:
                        siteId !== null && checkPermissions([Privilege.SiteView], permissions),
                },
            ],
        },
    ];

    return navItems;
};
