import { LOCATION_CHANGE } from "redux-first-history";
import { combineReducers } from 'redux';

import { SiteRelationshipsActions, SiteRelationshipsActionTypes } from '../../actions/site-relationships-actions';
import { IClient } from '../../models/client';

export interface IClientSelectorState {
    selectedClient: IClient;
    searchValue: string;
    clients: IClient[];

    isLoading: boolean;
    isSubmitting: boolean;
}

export const INITIAL_STATE: IClientSelectorState = {
    selectedClient: null,
    searchValue: '',
    clients: [],
    isLoading: false,
    isSubmitting: false
};

const selectedClient = (
    state: IClientSelectorState['selectedClient'] = INITIAL_STATE.selectedClient,
    action: SiteRelationshipsActionTypes
) => {
    switch (action.type) {
        case SiteRelationshipsActions.SELECT_CLIENT:
            return action.payload.client;

        case LOCATION_CHANGE:
        case SiteRelationshipsActions.CHANGE_SITE_SEARCH_VALUE:
        case SiteRelationshipsActions.CHANGE_CLIENT_SEARCH_VALUE:
            return null;

        default:
            return state;
    }
};

const searchValue = (
    state: IClientSelectorState['searchValue'] = INITIAL_STATE.searchValue,
    action: SiteRelationshipsActionTypes
) => {
    switch (action.type) {
        case SiteRelationshipsActions.CHANGE_CLIENT_SEARCH_VALUE:
            return action.payload.value;

        case SiteRelationshipsActions.SELECT_CLIENT:
            return action.payload.client.name;

        case LOCATION_CHANGE:
        case SiteRelationshipsActions.CHANGE_CLIENT_FULFILLED:
            return '';

        default:
            return state;
    }
};

const clients = (
    state: IClientSelectorState['clients'] = INITIAL_STATE.clients,
    action: SiteRelationshipsActionTypes
) => {
    switch (action.type) {
        case LOCATION_CHANGE:
        case SiteRelationshipsActions.CHANGE_SITE_SEARCH_VALUE:
        case SiteRelationshipsActions.CHANGE_CLIENT_SEARCH_VALUE:
        case SiteRelationshipsActions.LOAD_CLIENT_SEARCH_RESULTS:
            return [];

        case SiteRelationshipsActions.LOAD_CLIENT_SEARCH_RESULTS_FULFILLED:
            return action.payload.clients;

        default:
            return state;
    }
};

const isLoading = (
    state: IClientSelectorState['isLoading'] = INITIAL_STATE.isLoading,
    action: SiteRelationshipsActionTypes
) => {
    switch (action.type) {
        case SiteRelationshipsActions.LOAD_CLIENT_SEARCH_RESULTS:
            return true;

        case LOCATION_CHANGE:
        case SiteRelationshipsActions.CHANGE_SITE_SEARCH_VALUE:
        case SiteRelationshipsActions.CHANGE_CLIENT_SEARCH_VALUE:
        case SiteRelationshipsActions.LOAD_CLIENT_SEARCH_RESULTS_FULFILLED:
        case SiteRelationshipsActions.LOAD_CLIENT_SEARCH_RESULTS_CANCELLED:
        case SiteRelationshipsActions.LOAD_CLIENT_SEARCH_RESULTS_REJECTED:
            return false;

        default:
            return state;
    }
};

const isSubmitting = (
    state: IClientSelectorState['isSubmitting'] = INITIAL_STATE.isSubmitting,
    action: SiteRelationshipsActionTypes
) => {
    switch (action.type) {
        case SiteRelationshipsActions.CHANGE_CLIENT_CONFIRMED:
            return true;

        case SiteRelationshipsActions.CHANGE_CLIENT:
        case SiteRelationshipsActions.CHANGE_CLIENT_CANCELLED:
        case SiteRelationshipsActions.CHANGE_CLIENT_FULFILLED:
        case SiteRelationshipsActions.CHANGE_CLIENT_REJECTED:
            return false;

        default:
            return state;
    }
};

export const reducer = combineReducers<IClientSelectorState>({
    selectedClient,
    searchValue,
    clients,
    isLoading,
    isSubmitting
});
