import {
    updatePerson,
    loadPerson,
    updateLanguage,
    loadMyAssignedSites,
    loadRelationships,
} from 'actions/actions-v2/user-profile-action-v2';

export default {
    updatePerson,
    loadPersonProfile: loadPerson,
    updateLanguage,
    loadRelationships,
    loadMyAssignedSites,
};
