import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { connect } from 'react-redux';
import ShiftHandoverCategoryTable from './shift-handover-category-table';
import {
    loadShiftHandoverCategory,
    createShiftHandoverCategory,
    updateShiftHandoverCategory,
    deleteShiftHandoverCategory,
} from 'actions/actions-v2/shift-handover-actions';

const getSiteId = (state: IRootState) => state.app.siteId;
const getShifthandoverItemCategory = (state: IRootState) =>
    state.shiftHandoverState.shiftHandoverCategories;
const getAdminShiftCheckListLoading = (state: IRootState) => state.shiftHandoverState.isLoading;

const mapStateToProps = createSelector(
    getSiteId,
    getShifthandoverItemCategory,
    getAdminShiftCheckListLoading,
    (siteId, shiftHandoverItemCategories, isLoading) => ({
        siteId,
        shiftHandoverItemCategories,
        isLoading,
    })
);

const mapDispatchToProps = {
    load: loadShiftHandoverCategory,
    create: createShiftHandoverCategory,
    update: updateShiftHandoverCategory,
    delete: deleteShiftHandoverCategory,
};

export const ShiftHandoverCategoryTableContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ShiftHandoverCategoryTable);
