import { Form, FormikProps } from 'formik';
import _ from 'lodash';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Field } from 'components/form';
import { ArticlePanel } from 'components/panel';
import { BulmaSize } from 'enums/BulmaSize';
import { IBuilding } from 'models/building';
import { ISiteProfile } from 'models/site-profile';
import { RouteUrlBuilding, RouteUrlSite } from '../../routes';
import { IUpsertSitePageRouteParams } from '../upsert-site-page';
import './site-summary.scss';
import { Button } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';

export interface IProps extends RouteComponentProps<IUpsertSitePageRouteParams> {
    formikBag: FormikProps<Partial<ISiteProfile>>;
    setErrorDialogVisible: (isDialogVisible: boolean) => void;
    setIsFacilityDialogVisible: (isDialogVisible: boolean) => void;
}

export interface IDispatchProps {
    onChangeRoot: (routeUrl: string) => void;
}

const SiteSummary: React.FC<IProps & IDispatchProps> = ({
    match,
    formikBag,
    onChangeRoot: onNavigate,
    setErrorDialogVisible,
    setIsFacilityDialogVisible
}) => {

    const onNavigateToBuilding = () => {
        // tslint:disable-next-line:max-line-length

        formikBag.validateForm().then((errors) => {
            if (!Object.keys(errors).some((field) => field.length > 0)) {
                onNavigate(`${RouteUrlBuilding(match.params.id)}/${match.params.buildingIndex ? match.params.buildingIndex : '0'}`);
            } else {
                setErrorDialogVisible(true)

                //   scrollToFirstValidationError();
            }
        })

    };

    const onNavigateToSite = () => {
        onNavigate(RouteUrlSite(match.params.id));
    };

    const onAddBuilding = () => {

        formikBag.validateForm().then((errors) => {
            if (!Object.keys(errors).some((field) => field.length > 0)) {
                if (formikBag.values.clientCode && (formikBag.values.clientCode != null && formikBag.values.clientCode != "")) {
                    setIsFacilityDialogVisible(true)
                    onNavigateToSite()
                }
                else {
                    onNavigate(`${RouteUrlBuilding(match.params.id)}/${formikBag.values.buildings.length}`);
                }
            } else {
                setErrorDialogVisible(true)
                //   scrollToFirstValidationError();
            }
        });


    };

    const hasFacilities = formikBag.values.buildings && formikBag.values.buildings.length > 0;

    const getSumValue = (name: Extract<keyof IBuilding, string>) => {
        return hasFacilities ?
            formikBag.values.buildings.filter((f) => f[name])
                .map((c) => c[name]).reduce((sum: number, r: number) => sum + r, 0) : 0;
    };

    const getSumValueCalculatedInFt2 = (name: Extract<keyof IBuilding, string>) => {
        let result = 0;
        if (hasFacilities) {
            _.forEach(formikBag.values.buildings, (building) => {
                if (building.displayUnit === 'ft2') {
                    result += Number(building[name]);
                } else {
                    result += (Number(building[name]) * 10.76391042);
                }
            });
        }
        return Math.floor(result * 100) / 100;
    };

    const getTierLevels = () => {
        const tierLevels: string[] = [];
        if (formikBag.values.buildings && formikBag.values.buildings.length > 0) {
            formikBag.values.buildings.forEach((building) => {
                if (tierLevels.indexOf(building.tier) === -1) {
                    if (building.tier !== '') {
                        tierLevels.push(building.tier);
                    }
                }
            });
        }
        return tierLevels.join(',');
    };

    const getFacilityTypes = () => {
        const facilityTypes: string[] = [];
        if (formikBag.values.buildings && formikBag.values.buildings.length > 0) {
            formikBag.values.buildings.forEach((building) => {
                if (facilityTypes.indexOf(building.facility) === -1) {
                    if (building.facility !== '') {
                        facilityTypes.push(building.facility);
                    }
                }
            });
        }
        return facilityTypes.join(',');
    };
    const translate = useTranslate();
    return (
        <ArticlePanel className="column">
            <header>
                <h1 className="title is-4">{translate('UpsertSite.SiteSummary.Panel.Text')}</h1>
                <small>{translate('UpsertSite.SiteSummary.Panel.Text2')}</small>
            </header>
            <Form>
                <div className="SiteSummary">
                    <Field
                        isHorizontal={true}
                        htmlFor="numberOfFacilites"
                        label={translate('UpsertSite.SiteSummary.Field.Label')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field
                            name="mainAddress"
                            labelSize={BulmaSize.Medium}
                        >
                            {(formikBag.values.buildings ? formikBag.values.buildings.length : '0')}
                        </Field>
                    </Field>
                    <Field
                        isHorizontal={true}
                        htmlFor="raisedFloorSpace_SqFt"
                        label={translate('UpsertSite.SiteSummary.Field.Labe2')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field
                            labelSize={BulmaSize.Medium}
                        >
                            {getSumValueCalculatedInFt2('raisedFloorSpace_SqFt')}
                        </Field>
                    </Field>
                    <Field
                        isHorizontal={true}
                        htmlFor="totalSpace_SqFt"
                        label={translate('UpsertSite.SiteSummary.Field.Labe2')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field
                            labelSize={BulmaSize.Medium}
                        >
                            {getSumValueCalculatedInFt2('totalSpace_SqFt')}
                        </Field>
                    </Field>
                    <Field
                        isHorizontal={true}
                        label={translate('UpsertSite.SiteSummary.Field.Labe3')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field
                            labelSize={BulmaSize.Medium}
                        >
                            {getTierLevels()}
                        </Field>
                    </Field>
                    <Field
                        isHorizontal={true}
                        label={translate('UpsertSite.SiteSummary.Field.Labe4')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field
                            labelSize={BulmaSize.Medium}
                        >
                            {getFacilityTypes()}
                        </Field>
                    </Field>
                    <Field
                        isHorizontal={true}
                        htmlFor="totalGeneratorPowerCapacity_MW"
                        label={translate('UpsertSite.SiteSummary.Field.Label5')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field
                            labelSize={BulmaSize.Medium}
                        >
                            <>{getSumValue('totalGeneratorPowerCapacity_MW')}</>
                        </Field>
                    </Field>

                    <Field
                        isHorizontal={true}
                        htmlFor="upsPowerCapacity_MW"
                        label={translate('UpsertSite.SiteSummary.Field.Label6')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field
                            labelSize={BulmaSize.Medium}
                        >
                            <>{getSumValue('upsPowerCapacity_MW')}</>
                        </Field>
                    </Field>

                    <Field
                        isHorizontal={true}
                        htmlFor="itPowerCapacity_MW"
                        label={translate('UpsertSite.SiteSummary.Field.Label7')}
                        labelSize={BulmaSize.Medium}
                    >
                        <Field
                            labelSize={BulmaSize.Medium}
                        >
                            <>{getSumValue('itPowerCapacity_MW')}</>
                        </Field>
                    </Field>

                    <div className="action-groups">
                        <div className="action-group">
                            <Button
                                buttonType="cancel"
                                onClick={onNavigateToSite}
                            >
                                {translate('Globals.Label.Back')}
                            </Button>
                        </div>
                        <div className="action-group">
                            {formikBag.values.buildings?.length > 0 && (
                                <Button
                                    type="submit"
                                    onClick={onNavigateToBuilding}
                                >
                                    {translate('UpsertSite.SiteForm.Field.FirstFacility')}
                                </Button>
                            )}
                            <Button
                                type="submit"
                                onClick={onAddBuilding}
                            >
                                {translate('UpsertSite.BuildingForm.Field.AddFacility')}
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
        </ArticlePanel>
    );
};

export default withRouter(SiteSummary);
