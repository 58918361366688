const appConfig = () => {
    const config: any = (window as any).Config;
    if (config && config.REACT_APP_BASE_API === '#{base-api}#') {
        return {
            ...process.env,
        };
    }
    return {
        ...config,
    };
};

export default appConfig;
