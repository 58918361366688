import { IAppAction } from '../app-action';

export enum IauditorActions {
    LOAD_IAUDITOR_COMPLIANCE_CHECKLIST = '@@Iauditor/LOAD_IAUDITOR_COMPLIANCE_CHECKLIST',
    LOAD_IAUDITOR_COMPLIANCE_CHECKLIST_FULFILLED = '@@Iauditor/LOAD_IAUDITOR_COMPLIANCE_CHECKLIST_FULFILLED',
    LOAD_IAUDITOR_COMPLIANCE_CHECKLIST_REJECTED = '@@Iauditor/LOAD_IAUDITOR_COMPLIANCE_CHECKLIST_REJECTED',

    LOAD_IAUDITOR_TEMPLATE = '@@Iauditor/LOAD_IAUDITOR_TEMPLATE',
    LOAD_IAUDITOR_TEMPLATE_FULFILLED = '@@Iauditor/LOAD_IAUDITOR_TEMPLATE_FULFILLED',
    LOAD_IAUDITOR_TEMPLATE_REJECTED = '@@Iauditor/LOAD_IAUDITOR_TEMPLATE_REJECTED',
}

export const loadComplianceChecklist = (siteCode: string, cermProcess: number): IAppAction => ({
    type: IauditorActions.LOAD_IAUDITOR_COMPLIANCE_CHECKLIST,
    payload: {id: siteCode, shard: cermProcess},
});

export const loadTemplate = (siteCode: string, cermProcess: number): IAppAction => ({
    type: IauditorActions.LOAD_IAUDITOR_TEMPLATE,
    payload: {id: siteCode , shard: cermProcess},
});
