import React from 'react';
import {
    Timeline,
    TimelineHeader,
    TimelineHeaderGridValue,
    TimelineHeaderGridValueCollection,
} from 'components/timeline';
import OpportunitySubQuestionList from './opportunity-questionnaire-subquestions-list';
import OpportunityQuestionnaireResponse from './opportunity-questionnaire-response';
import { IOpportunity, IOpportunityAnswer } from 'models/opportunity-question-set';
import OpportunityQuestionnaireAdvice from './opportunity-questionnaire-advice';
import useTranslate from 'translations/translation-utils';
interface IProps {
    opportunity: IOpportunity;
    expandAll: boolean;
    opportunityAnswers: IOpportunityAnswer[];
    onQuestionAnswerChanged: (questionId: string, questionSetId: string, answer: string) => void;
    allResponseAnswers: string[];
}

const OpportunityQuestionnaireItem: React.FC<IProps> = ({
    opportunity,
    expandAll,
    opportunityAnswers,
    onQuestionAnswerChanged,
}) => {
    const translate = useTranslate();
    const [isToggled, setIsToggled] = React.useState(false);
    const [responseAnswer, setResponseAnswer] = React.useState('');
    const [allResponseAnswers, setAllResponseAnswers] = React.useState([]);

    React.useEffect(() => {
        setIsToggled(expandAll);
    }, [expandAll]);

    React.useEffect(() => {
        getAllResponseAnswers();
    }, [opportunity]);

    const getAllResponseAnswers = () => {
        let responseAnswer = [];
        if (opportunity.questions) {
            opportunity.questions.forEach((question) => {
                responseAnswer = [...new Set([...responseAnswer, ...question.responseAnswers])];
            });
        }
        if (opportunity.advices) {
            opportunity.advices.forEach((advice) => {
                responseAnswer = [...new Set([...responseAnswer, ...advice.responseAnswers])];
            });
        }

        setAllResponseAnswers(responseAnswer);
    };
    React.useEffect(() => {
        if (allResponseAnswers.includes(responseAnswer)) {
            setIsToggled(true);
        } else {
            setIsToggled(false);
        }
    }, [responseAnswer]);

    const onToggleButtonClick = () => {
        if (responseAnswer === translate('OpportunityQ.Dropdown.DefaultLabel')) {
            setIsToggled(false);
        } else {
            setIsToggled(!isToggled);
        }
    };
    const setResponse = (questionId: string, response: string) => {
        if (response !== translate('OpportunityQ.Dropdown.DefaultLabel')) {
            onQuestionAnswerChanged(questionId, opportunity.id, response);
        }
        setResponseAnswer(response);
    };
    const selectedResponseAnwer = (questionId: string, questionSetId: string) => {
        let questions = [];
        if (opportunityAnswers) {
            opportunityAnswers.forEach((questioinSet) => {
                if (questioinSet.opportunityId === questionSetId) {
                    questions = questioinSet.questionAnswers.filter(
                        (quesAns) => quesAns.id === questionId
                    );
                }
            });
        }
        return questions.length !== 0 ? questions[0].responseAnswer : 'Select Response';
    };
    const checkResponse = (responses: string[], reponseAnswer: string) => {
        // tslint:disable-next-line: radix
        if (responses && responses.includes(reponseAnswer)) {
            return true;
        } else {
            return false;
        }
    };
    return (
        <Timeline expanded={isToggled} key={`${opportunity.title}`}>
            <TimelineHeader
                expanded={isToggled}
                onToggleButtonClick={onToggleButtonClick}
                centered={true}
                disabled={!opportunity.title}
            >
                <TimelineHeaderGridValueCollection>
                    <TimelineHeaderGridValue className="timeline-title column is-6">
                        {translate('RiskProfileQuestion.Set.Question')} - {opportunity.title}
                    </TimelineHeaderGridValue>
                    <TimelineHeaderGridValue
                        label={translate('OpportunityQ.TimelineHeader.Label')}
                        className="column is-6 timeline-response  timeline-outer-response"
                    >
                        <OpportunityQuestionnaireResponse
                            onChange={setResponse}
                            answers={allResponseAnswers}
                            questionId={opportunity.id}
                            selectedResponseAns={selectedResponseAnwer(
                                opportunity.id,
                                opportunity.id
                            )}
                        />
                    </TimelineHeaderGridValue>
                </TimelineHeaderGridValueCollection>
            </TimelineHeader>
            {
                <OpportunitySubQuestionList
                    questionSet={opportunity.questions}
                    responseAnswer={responseAnswer}
                    questionSetId={opportunity.id}
                    onQuestionAnswerChanged={onQuestionAnswerChanged}
                    selectedResponseAnwer={selectedResponseAnwer}
                />
            }
            {opportunity.advices &&
                opportunity.advices.map((advice) => {
                    return checkResponse(advice.responseAnswers, responseAnswer) ? (
                        <OpportunityQuestionnaireAdvice advice={advice} />
                    ) : null;
                })}
        </Timeline>
    );
};

export default OpportunityQuestionnaireItem;
