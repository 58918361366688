import React from 'react';
import { orderBy } from 'lodash';
import { Table, TableHeader, SortOrder } from '../v2/components';
import { onRouteChange } from 'actions/app-actions';
import { IRisk } from 'models/risk';
import NumberFormat from 'react-number-format';
import { getRiskActionCount, getRiskActionCountClass, getRiskLevel } from './shared/risk-register-utils';
import { TableTd } from '../v2/table/TableTd';
import { usePageUIPreferences, UiPreferences } from 'utils/page-ui-preferences-utils';
import useTranslate from 'translations/translation-utils';
import { DateTime, Format } from 'components/dateTime';

interface IProps {
    risks: IRisk[];
    removeColumnHeaders?: boolean;
}

export const ClientRiskRegisterTable: React.FC<IProps> = ({
    risks,
    removeColumnHeaders: removeColumnHeaders,
}) => {
    const translate = useTranslate();
    const [sortField, setSortField] = usePageUIPreferences(
        UiPreferences.RisksClientPageSortField,
        'riskLevel'
    );
    const [sortOrder, setSortOrder] = usePageUIPreferences(
        UiPreferences.RisksClientPageSortOrder,
        SortOrder.off
    );

    const onToggleHandler = (field: string) => {
        if (sortField !== field) {
            setSortField(field);
            setSortOrder(SortOrder.asc);
            return;
        }
        const sort = sortOrder === SortOrder.asc ? SortOrder.desc : SortOrder.asc;
        setSortOrder(sort);
    };

    const getSortState = (field: string): SortOrder => {
        if (field === sortField) {
            return sortOrder;
        }
        return SortOrder.off;
    };

    const getSortedList = (): IRisk[] => {
        if (sortOrder === SortOrder.off) {
            return risks;
        }
        return sortOrder === SortOrder.asc
            ? orderBy(risks, [sortField], ['asc'])
            : orderBy(risks, [sortField], ['desc']);
    };

    const openDetails = (id: string) => {
        onRouteChange(`/RiskRegisterClient/${id}/details`);
    };

    const getSortableTableHeader = (
        key: Extract<keyof IRisk, string>,
        displayName: string,
        className?: string
    ) => {
        return (
            <TableHeader
                sortState={getSortState(key)}
                onToggle={() => onToggleHandler(key)}
                className={className}
            >
                {displayName}
            </TableHeader>
        );
    };

    return (
        <Table>
            {!removeColumnHeaders && (
                <thead>
                    <tr>
                        {getSortableTableHeader(
                            'uid',
                            translate('RiskRegisterPage.RiskRegisterTable.Title.Uid')
                        )}
                        {getSortableTableHeader(
                            'createdDateTime',
                            translate('RiskRegisterPage.RiskRegisterTable.Title.CreatedDateTime')
                        )}
                        {getSortableTableHeader(
                            'riskLevelIdSortable',
                            translate('RiskRegisterPage.RiskRegisterTable.Title.Level')
                        )}
                        {getSortableTableHeader(
                            'title',
                            translate('RiskRegisterPage.RiskRegisterTable.Title.Title')
                        )}
                        {getSortableTableHeader(
                            'riskCategory',
                            translate('RiskRegisterPage.RiskRegisterTable.Title.Category')
                        )}
                        {getSortableTableHeader(
                            'riskSubCategory',
                            translate('RiskRegisterPage.RiskRegisterTable.Title.SubCategory')
                        )}
                        {getSortableTableHeader(
                            'reviewDate',
                            translate('RiskRegisterPage.RiskRegisterTable.Title.Review')
                        )}
                        {getSortableTableHeader(
                            'costAmount',
                            translate('RiskDetailsPage.MitigationPlan.Label.EstimatedTotalCostToRemedy'),
                            'narrow'
                        )}
                    </tr>
                </thead>
            )}
            <tbody className="clickable">
                {getSortedList().map((item, index) => {
                    const actionCount = getRiskActionCount(item);

                    return (
                        <tr key={index} onClick={() => openDetails(item.id)}>
                            <td>{item.uid}</td>
                            <td className="no-wrap">
                                <DateTime value={item.createdDateTime} format={Format.DateOnly} />
                            </td>
                            <td>
                                <span>{getRiskLevel(item)}</span>
                            </td>
                            <TableTd type="ellipsis">{item.title}</TableTd>
                            <td>{translate('Globals.Category.', item.riskCategory)}</td>
                            <td>{item.riskSubCategory}</td>
                            <td className="no-wrap">
                                <DateTime value={item.reviewDate} format={Format.DateOnly} />
                                <span className={getRiskActionCountClass(item, actionCount)}>
                                    {actionCount > 0 ? actionCount : ''}
                                </span>
                            </td>
                            <td className="no-wrap">
                                {item.cost && item.cost.amount > 0 && item.cost.currencyIsoCode && (
                                    <NumberFormat
                                        decimalScale={2}
                                        displayType="text"
                                        value={item.cost.amount}
                                        thousandSeparator=","
                                        prefix={`${item.cost.currencySymbol} `}
                                    />
                                )}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
};
