import { Action } from 'redux';
import { IKeyedItem, IKeyedItemExtended } from 'models/keyed-item';
import { IUpsertCermProcessStepRootState } from 'reducers/upsert-cerm-process-step';
import { ICermProcessStep } from '../models/cerm-process-step';

export enum UpsertCermProcessStepActions {
    UPDATE_CERM_PROCESS_STEP = '@@upsertCermProcessStep/UPDATE_CERM_PROCESS_STEP',
    UPDATE_CERM_PROCESS_STEP_FULFILLED = '@@upsertCermProcessStep/UPDATE_CERM_PROCESS_STEP_FULFILLED',
    UPDATE_CERM_PROCESS_STEP_REJECTED = '@@upsertCermProcessStep/UPDATE_CERM_PROCESS_STEP_REJECTED',
    UPDATE_CERM_PROCESS_STEP_CANCELLED = '@@upsertCermProcessStep/UPDATE_CERM_PROCESS_STEP_CANCELLED',

    DELETE_PROCESS_STEP = '@@upsertCermProcessStep/DELETE_PROCESS_STEP',
    DELETE_PROCESS_STEP_FULFILLED = '@@upsertCermProcessStep/DELETE_PROCESS_STEP_FULFILLED',
    DELETE_PROCESS_STEP_REJECTED = '@@upsertCermProcessStep/DELETE_PROCESS_STEP_REJECTED',
    CANCEL_DELETE_PROCESS_STEP = '@@upsertCermProcessStep/CANCEL_DELETE_PROCESS_STEP',
    DELETE_PROCESS_STEP_CONFIRMED = '@@upsertCermProcessStep/DELETE_PROCESS_STEP_CONFIRMED',
    DELETE_PROCESS_STEP_CONFIRMED_FULFILLED = '@@upsertCermProcessStep/DELETE_PROCESS_STEP_CONFIRMED_FULFILLED',
    DELETE_PROCESS_STEP_CONFIRMED_REJECTED = '@@upsertCermProcessStep/DELETE_PROCESS_STEP_CONFIRMED_REJECTED',
    DELETE_PROCESS_STEP_CONFIRMED_CANCELLED = '@@upsertCermProcessStep/DELETE_PROCESS_STEP_CONFIRMED_CANCELLED',

    DELETE_OUTCOME = '@@upsertCermProcessStep/DELETE_OUTCOME',
    CANCEL_DELETE_OUTCOME = '@@upsertCermProcessStep/CANCEL_DELETE_OUTCOME',
    DELETE_OUTCOME_CONFIRMED = '@@upsertCermProcessStep/DELETE_OUTCOME_CONFIRMED',
    DELETE_OUTCOME_CONFIRMED_FULFILLED = '@@upsertCermProcessStep/DELETE_OUTCOME_CONFIRMED_FULFILLED',
    DELETE_OUTCOME_CONFIRMED_REJECTED = '@@upsertCermProcessStep/DELETE_OUTCOME_CONFIRMED_REJECTED',
    DELETE_OUTCOME_CONFIRMED_CANCELLED = '@@upsertCermProcessStep/DELETE_OUTCOME_CONFIRMED_CANCELLED',

    SET_SELECTED_OUTCOME = '@@upsertCermProcessStep/SET_SELECTED_QUESTION',
    LOAD_PAGE = '@@upsertCermProcessStep/LOAD_PAGE',
    LOAD_PAGE_FULFILLED = '@@upsertCermProcessStep/LOAD_PAGE_FULFILLED',
    LOAD_PAGE_REJECTED = '@@upsertCermProcessStep/LOAD_PAGE_REJECTED',
    LOAD_PAGE_CANCELLED = '@@upsertCermProcessStep/LOAD_PAGE_CANCELLED'
}
export interface ILoadPageAction extends Action {
    type: UpsertCermProcessStepActions.LOAD_PAGE;
    payload: {
        id?: string;
    };
}

export interface ILoadPageRejectedAction extends Action {
    type: UpsertCermProcessStepActions.LOAD_PAGE_REJECTED;
    error: true;
    payload: any;
}

export interface ILoadPageCancelledAction extends Action {
    type: UpsertCermProcessStepActions.LOAD_PAGE_CANCELLED;
}

export interface ILoadPageFulfilledAction extends Action {
    type: UpsertCermProcessStepActions.LOAD_PAGE_FULFILLED;
    payload: IUpsertCermProcessStepRootState;
}

export interface IDeleteProcessStepAction extends Action {
    type: UpsertCermProcessStepActions.DELETE_PROCESS_STEP;
    payload: { id: string, reloadUrl: string };
}

export const deleteProcessStep = (item: IKeyedItemExtended): IDeleteProcessStepAction => ({
    type: UpsertCermProcessStepActions.DELETE_PROCESS_STEP,
    payload: { id: item.id, reloadUrl: item.reloadUrl }
});

export interface ICancelDeleteProcessStepAction extends Action {
    type: UpsertCermProcessStepActions.CANCEL_DELETE_PROCESS_STEP;
}

export const cancelDeleteProcessStepAction = (): ICancelDeleteProcessStepAction => ({
    type: UpsertCermProcessStepActions.CANCEL_DELETE_PROCESS_STEP
});

export interface IDeleteProcessStepConfirmedAction extends Action {
    type: UpsertCermProcessStepActions.DELETE_PROCESS_STEP_CONFIRMED;
    payload: { id: string, reloadUrl: string };
}

export const deleteProcessStepConfirmed = (item: IKeyedItemExtended): IDeleteProcessStepConfirmedAction => ({
    type: UpsertCermProcessStepActions.DELETE_PROCESS_STEP_CONFIRMED,
    payload: { id: item.id, reloadUrl: item.reloadUrl }
});

export interface IDeleteProcessStepConfirmedFulfilledAction extends Action {
    type: UpsertCermProcessStepActions.DELETE_PROCESS_STEP_CONFIRMED_FULFILLED;
    payload: {
        item: IKeyedItem;
    };
}

export const deleteProcessStepConfirmedFulfilled =
    (item: IKeyedItem): IDeleteProcessStepConfirmedFulfilledAction => ({
        type: UpsertCermProcessStepActions.DELETE_PROCESS_STEP_CONFIRMED_FULFILLED,
        payload: {
            item
        }
    });

export interface IDeleteProcessStepConfirmedCancelledAction extends Action {
    type: UpsertCermProcessStepActions.DELETE_PROCESS_STEP_CONFIRMED_CANCELLED;
    payload: {
        item: IKeyedItem;
    };
}

export const deleteProcessStepConfirmedCancelledStep =
    (item: IKeyedItem): IDeleteProcessStepConfirmedCancelledAction => ({
        type: UpsertCermProcessStepActions.DELETE_PROCESS_STEP_CONFIRMED_CANCELLED,
        payload: {
            item
        }
    });

export interface IDeleteProcessStepConfirmedRejectedAction extends Action {
    type: UpsertCermProcessStepActions.DELETE_PROCESS_STEP_CONFIRMED_REJECTED;
    payload: {
        item: IKeyedItem;
        errorMessage: string;
    };
    error: true;
}

export const deleteProcessStepConfirmedRejectedStep = (
    item: IKeyedItem,
    errorMessage: string
): IDeleteProcessStepConfirmedRejectedAction => ({
    type: UpsertCermProcessStepActions.DELETE_PROCESS_STEP_CONFIRMED_REJECTED,
    payload: {
        item,
        errorMessage
    },
    error: true
});

export interface IUpdateCermProcessStepAction extends Action {
    type: UpsertCermProcessStepActions.UPDATE_CERM_PROCESS_STEP;
    payload: {
        processStep: Partial<ICermProcessStep>
    };
}

export interface IUpdateCermProcessStepFulfilledAction extends Action {
    type: UpsertCermProcessStepActions.UPDATE_CERM_PROCESS_STEP_FULFILLED;
    payload: {
        processStep: ICermProcessStep
    };
}

export interface IUpdateCermProcessStepRejectedAction extends Action {
    type: UpsertCermProcessStepActions.UPDATE_CERM_PROCESS_STEP_REJECTED;
    error: true;
    payload: {
        processStep: Partial<ICermProcessStep>
    } & any;
}

export interface IUpdateCermProcessStepCancelledAction extends Action {
    type: UpsertCermProcessStepActions.UPDATE_CERM_PROCESS_STEP_CANCELLED;
    payload: {
        processStep: Partial<ICermProcessStep>
    };
}

export interface ISetSelectedOutcomeAction extends Action {
    type: UpsertCermProcessStepActions.SET_SELECTED_OUTCOME;
    payload: {
        selectedOutcomeIndex: number | null;
    };
}

export interface IDeleteOutcomeAction extends Action {
    type: UpsertCermProcessStepActions.DELETE_OUTCOME;
    payload: {
        item: IKeyedItem
    };
}

export const deleteOutcome = (item: IKeyedItem): IDeleteOutcomeAction => ({
    type: UpsertCermProcessStepActions.DELETE_OUTCOME,
    payload: {
        item
    }
});

export interface ICancelDeleteOutcomeAction extends Action {
    type: UpsertCermProcessStepActions.CANCEL_DELETE_OUTCOME;
}

export const cancelDeleteOutcomeAction = (): ICancelDeleteOutcomeAction => ({
    type: UpsertCermProcessStepActions.CANCEL_DELETE_OUTCOME
});

export interface IDeleteOutcomeConfirmedAction extends Action {
    type: UpsertCermProcessStepActions.DELETE_OUTCOME_CONFIRMED;
    payload: {
        item: IKeyedItem;
    };
}

export const deleteOutcomeConfirmed = (item: IKeyedItem): IDeleteOutcomeConfirmedAction => ({
    type: UpsertCermProcessStepActions.DELETE_OUTCOME_CONFIRMED,
    payload: {
        item
    }
});

export interface IDeleteOutcomeConfirmedFulfilledAction extends Action {
    type: UpsertCermProcessStepActions.DELETE_OUTCOME_CONFIRMED_FULFILLED;
    payload: {
        item: IKeyedItem;
    };
}

export const deleteOutcomeConfirmedFulfilled = (item: IKeyedItem): IDeleteOutcomeConfirmedFulfilledAction => ({
    type: UpsertCermProcessStepActions.DELETE_OUTCOME_CONFIRMED_FULFILLED,
    payload: {
        item
    }
});

export interface IDeleteOutcomeConfirmedCancelledAction extends Action {
    type: UpsertCermProcessStepActions.DELETE_OUTCOME_CONFIRMED_CANCELLED;
    payload: {
        item: IKeyedItem;
    };
}

export const deleteProcessConfirmedCancelled = (item: IKeyedItem): IDeleteOutcomeConfirmedCancelledAction => ({
    type: UpsertCermProcessStepActions.DELETE_OUTCOME_CONFIRMED_CANCELLED,
    payload: {
        item
    }
});

export interface IDeleteOutcomeConfirmedRejectedAction extends Action {
    type: UpsertCermProcessStepActions.DELETE_OUTCOME_CONFIRMED_REJECTED;
    payload: {
        item: IKeyedItem;
        error: any;
    };
    error: true;
}

export const deleteOutcomeConfirmedRejected = (
    item: IKeyedItem,
    error: any
): IDeleteOutcomeConfirmedRejectedAction => ({
    type: UpsertCermProcessStepActions.DELETE_OUTCOME_CONFIRMED_REJECTED,
    payload: {
        item,
        error
    },
    error: true
});

export interface IDeleteProcessStepByIdConfirmedAction extends Action {
    type: UpsertCermProcessStepActions.DELETE_PROCESS_STEP_CONFIRMED;
    payload: {
        id: string;
        reloadUrl: string;
    };
}

export const deleteProcessStepByIdConfirmed = (id: string, reloadUrl: string): IDeleteProcessStepByIdConfirmedAction => ({
    type: UpsertCermProcessStepActions.DELETE_PROCESS_STEP_CONFIRMED,
    payload: {
        id, reloadUrl
    }
});

export type UpsertCermProcessStepActionTypes =
    IUpdateCermProcessStepAction |
    IUpdateCermProcessStepFulfilledAction |
    IUpdateCermProcessStepRejectedAction |
    IUpdateCermProcessStepCancelledAction |
    ISetSelectedOutcomeAction

    | IDeleteOutcomeAction
    | ICancelDeleteOutcomeAction
    | IDeleteOutcomeConfirmedAction
    | IDeleteOutcomeConfirmedCancelledAction
    | IDeleteOutcomeConfirmedFulfilledAction
    | IDeleteOutcomeConfirmedRejectedAction

    | IDeleteProcessStepAction
    | ICancelDeleteProcessStepAction
    | IDeleteProcessStepConfirmedAction
    | IDeleteProcessStepConfirmedCancelledAction
    | IDeleteProcessStepConfirmedFulfilledAction
    | IDeleteProcessStepConfirmedRejectedAction
    | ILoadPageAction
    | ILoadPageCancelledAction
    | ILoadPageRejectedAction
    | ILoadPageFulfilledAction
    | IDeleteProcessStepByIdConfirmedAction;
