import { combineReducers } from 'redux';

import { Dictionary } from 'lodash';
import { IKeyedItem } from 'models/keyed-item';
import { ManageRoleActions, ManageRoleActionTypes } from '../../actions/manage-role-actions';
import { IKeyValue } from '../../models/key-value';
import { IGridViewState, INITIAL_STATE as INITIAL_GRID_STATE } from '../grid';
import { IAppAction } from 'actions/app-action';

import { PersonActions } from '../../actions/actions-v2/person-action-v2';
import { IPerson } from 'models/person';
import { sortArrayBy } from 'utils/sort-utils';
import { s2ab } from 'utils/export-utils';
import { RoleActions } from 'actions/actions-v2/role-actions-v2';
import { IUserEmbedded } from 'models/user-embedded';
import { SiteActions } from 'actions/actions-v2/site-actions-v2';
import saveAs from 'file-saver';

export interface ISite {
    id: string;
    name: string;
    regions: string[];
    lineOfBusiness: string;
    clientName: string;
    siteFeatures: string[];
}

// Keep in sync with CermView.WebAppCore.ViewModels.ManageRoles.Role
export interface IRole extends IKeyedItem {
    value: string;
    description: string;
    peopleInRole: number;
    roleLevel: number;
    privilegesInRole: Array<IKeyValue<string>>;
    people: IUserEmbedded[];
    privileges: IPrivilege[];
}

export interface IPrivilege extends IKeyedItem {
    displayName: string;
    privilegeName: string;
    module: string;
    description: string;
    rolesInPrivilege: Array<IKeyValue<string>>;
}

export type RelationshipType = 'Include' | 'AreaManager' | 'SiteManager';

export interface ISiteRelationship {
    siteId: string;
    relationshipType: RelationshipType;
}

export interface ISiteValidateMyDataFacility {
    siteId: string;
    propertyIds: string[];
}

export interface ISiteMyDataFacilityAssignment {
    siteId: string;
    siteName: string;
    propertyId: string;
}
export interface IManageRolesGridState extends IGridViewState<IPerson> {
    lookupRoles: Array<IKeyValue<string>>;
    manageRolesFilterRoles: string[];
    expandedFilters: string[];
    sites: ISite[];
    isInitialising: boolean;
    personSiteRelationships: Dictionary<ISiteRelationship[]>;
    rolesState: IRolesTabGridState;
    privilegesState: IPrivilegesTabGridState;
    regionsChecked: Dictionary<string[]>;
    lobChecked: Dictionary<string[]>;
    clientExpanded: Dictionary<string[]>;
    isLoading: boolean;
    isExporting: boolean;
    privilegeRoles: IPrivilege;
    privileges: IPrivilege[];
    isValidatingMyDataFacility: boolean;
    siteMyDataFacilityAssignments: ISiteMyDataFacilityAssignment[];
}

export interface IRolesTabGridState extends IGridViewState<IRole> { }

export interface IPrivilegesTabGridState extends IGridViewState<IPrivilege> { }

export const INITIAL_STATE: IManageRolesGridState = {
    ...INITIAL_GRID_STATE,
    lookupRoles: [],
    manageRolesFilterRoles: [],
    expandedFilters: ['operatingPlatform', 'roles'],
    sites: [],
    isInitialising: true,
    rolesState: INITIAL_GRID_STATE,
    privilegesState: INITIAL_GRID_STATE,
    personSiteRelationships: {},
    regionsChecked: {},
    lobChecked: {},
    clientExpanded: {},
    isLoading: false,
    isExporting: false,
    privilegeRoles: null,
    privileges: [],
    isValidatingMyDataFacility: false,
    siteMyDataFacilityAssignments: [],
};

const sites = (
    state: IManageRolesGridState['sites'] = INITIAL_STATE.sites,
    action: ManageRoleActionTypes
): ISite[] => {
    switch (action.type) {
        case ManageRoleActions.LOAD_PAGE_FULFILLED:
            return action.payload.sites;
        default:
            return state;
    }
};

const personSiteRelationships = (
    state: IManageRolesGridState['personSiteRelationships'] = INITIAL_STATE.personSiteRelationships,
    action: ManageRoleActionTypes
): Dictionary<ISiteRelationship[]> => {
    switch (action.type) {
        case ManageRoleActions.LOAD_MANAGE_ROLES_PERSON_FULFILLED:
            return {
                ...state,
                [action.payload.personId]: action.payload.relationships,
            };
        case ManageRoleActions.LOAD_MANAGE_ROLES_PERSON_CANCELLED:
        case ManageRoleActions.LOAD_MANAGE_ROLES_PERSON_REJECTED:
            return state;
        default:
            return state;
    }
};

const items = (state: IManageRolesGridState['items'] = INITIAL_STATE.items, action: IAppAction) => {
    switch (action.type) {
        case PersonActions.SORT_PERSONS:
            return sortArrayBy(action.payload.key, [...state], action.payload.sortAscending);
        case ManageRoleActions.LOAD_PAGE_FULFILLED:
            return action.payload.items;
        case ManageRoleActions.LOAD_MANAGE_ROLES_CANCELLED:
        case ManageRoleActions.LOAD_MANAGE_ROLES_REJECTED:
            return INITIAL_STATE.items;

        case ManageRoleActions.LOAD_MANAGE_ROLES_FULFILLED:
            return action.payload.manageRoles;

        case PersonActions.LOAD_PERSONS_FULFILLED:
            if (action.payload) {
                if (action.isFromContinuationRequest) {
                    return [...state, ...action.payload];
                }

                return action.payload;
            }

            return state;

        case PersonActions.CREATE_PERSON_FULFILLED:
            if (action.payload) {
                return [action.payload, ...state];
            }

            return state;

        case PersonActions.UPDATE_PERSON_FULFILLED:
            if (action.payload) {
                const updatedPerson = action.payload;
                if (!updatedPerson.isActive) {
                    return state.filter((person) => person.id !== updatedPerson.id);
                }

                return state.map((person) =>
                    person.id === updatedPerson.id ? action.payload : person
                );
            }

            return state;

        case ManageRoleActions.CHANGE_ROLES_FULFILLED:
            return state.map((manageRole) => {
                if (manageRole.id === action.payload.value.parentId) {
                    return { ...manageRole, roles: action.payload.value.value };
                }
                return manageRole;
            });

        case ManageRoleActions.TOGGLE_SITES_FIELD_VALUE:
            return state.map((row) => {
                if (row.id === action.payload.personId) {
                    return {
                        ...row,
                        [action.payload.field]: action.payload.value,
                    };
                }

                return row;
            });
        default:
            return state;
    }
};

const regionsChecked = (
    state: Dictionary<string[]> = INITIAL_STATE.regionsChecked,
    action: ManageRoleActionTypes
): Dictionary<string[]> => {
    switch (action.type) {
        case ManageRoleActions.TOGGLE_REGION_SELECTED:
            const id = action.payload.personId;
            if (state.hasOwnProperty(id)) {
                if (state[id].includes(action.payload.value)) {
                    // Already checked, uncheck it
                    const newState = state[id].filter((site) => site !== action.payload.value);
                    return {
                        ...state,
                        [id]: [...newState],
                    };
                }

                // not checked, check it
                return {
                    ...state,
                    [id]: [...state[id], action.payload.value],
                };
            }

            // If no arr value, create first item
            return {
                ...state,
                [id]: [action.payload.value],
            };

        default:
            return state;
    }
};

const lobChecked = (
    state: Dictionary<string[]> = INITIAL_STATE.regionsChecked,
    action: ManageRoleActionTypes
): Dictionary<string[]> => {
    switch (action.type) {
        case ManageRoleActions.TOGGLE_LOB_SELECTED:
            const id = action.payload.personId;
            if (state.hasOwnProperty(id)) {
                if (state[id].includes(action.payload.value)) {
                    // Already checked, uncheck it
                    const newState = state[id].filter((site) => site !== action.payload.value);
                    return {
                        ...state,
                        [id]: [...newState],
                    };
                }

                // not checked, check it
                return {
                    ...state,
                    [id]: [...state[id], action.payload.value],
                };
            }

            // If no arr value, create first item
            return {
                ...state,
                [id]: [action.payload.value],
            };

        default:
            return state;
    }
};

const clientExpanded = (
    state: Dictionary<string[]> = INITIAL_STATE.regionsChecked,
    action: ManageRoleActionTypes
): Dictionary<string[]> => {
    // TODO
    switch (action.type) {
        case ManageRoleActions.TOGGLE_EXPAND_CLIENT:
            const id = action.payload.personId;
            if (state.hasOwnProperty(id)) {
                if (state[id].includes(action.payload.clientName)) {
                    // Already checked, uncheck it
                    const newState = state[id].filter((site) => site !== action.payload.clientName);
                    return {
                        ...state,
                        [id]: [...newState],
                    };
                }

                // not checked, check it
                return {
                    ...state,
                    [id]: [...state[id], action.payload.clientName],
                };
            }

            // If no arr value, create first item
            return {
                ...state,
                [id]: [action.payload.clientName],
            };

        default:
            return state;
    }
};

const isLoading = (
    state: IManageRolesGridState['isLoading'] = INITIAL_STATE.isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case ManageRoleActions.LOAD_MANAGE_ROLES:
        case ManageRoleActions.LOAD_PRIVILEGES_TAB_FULFILLED:
        case ManageRoleActions.SAVE_RELATIONSHIPS:
        case ManageRoleActions.LOAD_PAGE:
        case PersonActions.LOAD_PERSON:
            return true;

        case ManageRoleActions.LOAD_MANAGE_ROLES_CANCELLED:
        case ManageRoleActions.LOAD_MANAGE_ROLES_FULFILLED:
        case ManageRoleActions.LOAD_MANAGE_ROLES_REJECTED:
        case ManageRoleActions.LOAD_PAGE_FULFILLED:
        case ManageRoleActions.SAVE_RELATIONSHIPS_CANCELLED:
        case ManageRoleActions.SAVE_RELATIONSHIPS_FULFILLED:
        case ManageRoleActions.SAVE_RELATIONSHIPS_REJECTED:
        case PersonActions.LOAD_PERSON_FULFILLED:
            return false;
        default:
            return state;
    }
};

const sortColumn = (
    state: IManageRolesGridState['sortColumn'] = INITIAL_STATE.sortColumn,
    action: ManageRoleActionTypes
) => {
    switch (action.type) {
        case ManageRoleActions.CHANGE_SORT_ORDER_VALUE:
            return action.payload.sortColumn;

        default:
            return state;
    }
};

const sortAscending = (
    state: IManageRolesGridState['sortAscending'] = INITIAL_STATE.sortAscending,
    action: ManageRoleActionTypes
) => {
    switch (action.type) {
        case ManageRoleActions.CHANGE_SORT_ORDER_VALUE:
            return action.payload.sortAscending;

        default:
            return state;
    }
};

const secondarySortColumn = (
    state: IManageRolesGridState['secondarySortColumn'] = INITIAL_STATE.secondarySortColumn,
    action: ManageRoleActionTypes
) => {
    switch (action.type) {
        default:
            return state;
    }
};

const secondarySortAscending = (
    state: IManageRolesGridState['secondarySortAscending'] = INITIAL_STATE.secondarySortAscending,
    action: ManageRoleActionTypes
) => {
    switch (action.type) {
        default:
            return state;
    }
};

const expandedItems = (
    state: IManageRolesGridState['expandedItems'] = INITIAL_STATE.expandedItems,
    action: ManageRoleActionTypes
) => {
    switch (action.type) {
        case ManageRoleActions.TOGGLE_ITEM_EXPANDED:
            const id = action.payload.personId;
            if (state.includes(id)) {
                return state.filter((c) => c !== id);
            }
            return [...state, id];

        default:
            return state;
    }
};

const lookupRoles = (
    state: IManageRolesGridState['lookupRoles'] = INITIAL_STATE.lookupRoles,
    action: ManageRoleActionTypes
) => {
    switch (action.type) {
        case ManageRoleActions.DELETE_ROLE_FULFILLED:
            return state.filter((role) => {
                if (role.key !== action.payload.roleId) {
                    return role;
                }
            });
        case ManageRoleActions.LOAD_PAGE_FULFILLED:
            return action.payload.lookupRoles;
        default:
            return state;
    }
};

const manageRolesFilterRoles = (
    state: IManageRolesGridState['manageRolesFilterRoles'] = INITIAL_STATE.manageRolesFilterRoles,
    action: ManageRoleActionTypes
) => {
    switch (action.type) {
        case ManageRoleActions.CHANGE_FILTER_VALUE:
            if (action.payload.field !== 'manageRolesFilterRoles') {
                return state;
            }

            const filterValue = action.payload.value;
            if (state.includes(filterValue)) {
                const newState = state.filter((item) => item !== filterValue);
                return newState;
            } else {
                if (filterValue) {
                    const newState = state.concat(filterValue);
                    return newState;
                }
            }

        case ManageRoleActions.CLEAR_FILTERS:
            return [];

        default:
            return state;
    }
};

const expandedFilters = (
    state: IManageRolesGridState['expandedFilters'] = INITIAL_STATE.expandedFilters,
    action: ManageRoleActionTypes
) => {
    switch (action.type) {
        case ManageRoleActions.LOAD_PAGE_FULFILLED:
            return action.payload.expandedFilters ? action.payload.expandedFilters : [];
        case ManageRoleActions.TOGGLE_FILTER_EXPANDED:
            const id = action.payload.personId;

            if (state.includes(id)) {
                const newState = state.filter((item) => item !== id);
                return newState;
            } else {
                if (id) {
                    const newState = state.concat(id);
                    return newState;
                }
            }
            return state;

        default:
            return state;
    }
};

const rolesState = (
    state: IManageRolesGridState['rolesState'] = INITIAL_STATE.rolesState,
    action: IAppAction
) => {
    switch (action.type) {
        case ManageRoleActions.DELETE_ROLE_FULFILLED:
            return {
                ...state,
                items: state.items.filter((role) => {
                    if (role.id !== action.payload.roleId) {
                        return role;
                    }
                }),
            };

        case RoleActions.CREATE_ROLE_FULFILLED:
        case RoleActions.UPDATE_ROLE_FULFILLED:
            if (action.payload && state.items.some((s) => s.id === action.payload.id)) {
                return {
                    ...state,
                    items: state.items.map((roleState) => {
                        if (roleState.id === action.payload.id) {
                            return action.payload;
                        }
                        return roleState;
                    }),
                };
            }
            return {
                ...state,
                items: state.items.concat(action.payload),
            };
        case ManageRoleActions.LOAD_PAGE:
            return {
                ...state,
                isLoading: true,
            };
        case RoleActions.LOAD_ROLES:
            return {
                ...state,
                isLoading: true,
            };
        case ManageRoleActions.LOAD_PAGE_FULFILLED:
            return {
                ...state,
                isLoading: false,
            };

        case RoleActions.LOAD_ROLES_FULFILLED:
            return {
                ...state,
                items: action.payload ? action.payload : state,
                isLoading: false,
            };
        case ManageRoleActions.CHANGE_SORT_ORDER_VALUE_ROLES_TAB:
            return {
                ...state,
                sortColumn: action.payload.sortColumn,
                sortAscending: action.payload.sortAscending,
            };

        default:
            return state;
    }
};

const privilegeRoles = (
    state: IManageRolesGridState['privilegeRoles'] = INITIAL_STATE.privilegeRoles,
    action: IAppAction
) => {
    switch (action.type) {
        case RoleActions.LOAD_PRIVILEGE_ROLES:
            return {
                ...state,
            };
        case RoleActions.LOAD_PRIVILEGE_ROLES_FULFILLED:
            return {
                ...state,
                privilegeRoles: action.payload
            };
        default:
            return state;
    }
};

const privileges = (
    state: IManageRolesGridState['privileges'] = INITIAL_STATE.privileges,
    action: IAppAction
) => {
    switch (action.type) {
        case RoleActions.LOAD_PRIVILEGES:
            return {
                ...state,
            };
        case RoleActions.LOAD_PRIVILEGES_FULFILLED:
            return {
                ...state,
                privileges: action.payload
            };
        default:
            return state;
    }
};

const privilegesState = (
    state: IManageRolesGridState['privilegesState'] = INITIAL_STATE.privilegesState,
    action: ManageRoleActionTypes
) => {
    switch (action.type) {
        case ManageRoleActions.ADD_REMOVE_PRIVILEGE:
            return {
                ...state,
                isLoading: true,
            };

        case ManageRoleActions.ADD_REMOVE_PRIVILEGE_FULFILLED:
            return {
                ...state,
                isLoading: false,
            };
        case ManageRoleActions.LOAD_PRIVILEGES_TAB:
            return {
                ...state,
                isLoading: true,
            };

        case ManageRoleActions.LOAD_PRIVILEGES_TAB_FULFILLED:
            return {
                ...state,
                items: action.payload.privilegesState,
                isLoading: false,
            };

        case ManageRoleActions.CHANGE_SORT_ORDER_VALUE_PRIVILEGES_TAB:
            return {
                ...state,
                sortColumn: action.payload.sortColumn,
                sortAscending: action.payload.sortAscending,
            };
        default:
            return state;
    }
};

const isInitialising = (
    state: IManageRolesGridState['isInitialising'] = INITIAL_STATE.isInitialising,
    action: ManageRoleActionTypes
) => {
    switch (action.type) {
        case ManageRoleActions.LOAD_PAGE_FULFILLED:
            return false;
        default:
            return state;
    }
};

const isExporting = (
    state: IManageRolesGridState['isExporting'] = INITIAL_STATE.isExporting,
    action: IAppAction
) => {
    switch (action.type) {
        case PersonActions.EXPORT_PEOPLE:
            return true;
        case PersonActions.EXPORT_PEOPLE_FULFILLED:
            if (action.payload && action.payload.fileContents) {
                const blob = new Blob([s2ab(atob(action.payload.fileContents))], {
                    type: 'application/excel',
                });
                saveAs(blob, action.payload.fileDownloadName);
            }
            return false;

        case PersonActions.EXPORT_PEOPLE_REJECTED:
            return false;
        default:
            return state;
    }
};

const isValidatingMyDataFacility = (
    state: IManageRolesGridState['isValidatingMyDataFacility'] = INITIAL_STATE.isValidatingMyDataFacility,
    action: IAppAction
) => {
    switch (action.type) {
        case SiteActions.VALIDATE_MYDATA_FACILITY_SITES:
            return true;
        case SiteActions.VALIDATE_MYDATA_FACILITY_SITES_FULFILLED:
        case SiteActions.VALIDATE_MYDATA_FACILITY_SITES_REJECTED:
            return false;
        default:
            return state;
    }
};

const siteMyDataFacilityAssignments = (
    state: IManageRolesGridState['siteMyDataFacilityAssignments'] = INITIAL_STATE.siteMyDataFacilityAssignments,
    action: IAppAction
) => {
    switch (action.type) {
        case SiteActions.VALIDATE_MYDATA_FACILITY_SITES:
            return {
                ...state,
                siteMyDataFacilityAssignments: []
            }
        case SiteActions.VALIDATE_MYDATA_FACILITY_SITES_FULFILLED:
            return {
                ...state,
                siteMyDataFacilityAssignments: action.payload
            }
        case SiteActions.VALIDATE_MYDATA_FACILITY_SITES_REJECTED:
            return {
                ...state,
                siteMyDataFacilityAssignments: []
            }
        default:
            return state;
    }
};

const responseContinuation = (
    state: string = INITIAL_STATE.responseContinuation,
    action: IAppAction
) => {
    switch (action.type) {
        case PersonActions.LOAD_PERSONS_FULFILLED:
            return action.responseContinuation;

        default:
            return state;
    }
};

export const reducer = combineReducers<IManageRolesGridState>({
    rolesState,
    privilegesState,
    expandedFilters,
    manageRolesFilterRoles,
    lookupRoles,
    items,
    isInitialising,
    personSiteRelationships,
    isLoading,
    sortColumn,
    sortAscending,
    secondarySortColumn,
    secondarySortAscending,
    expandedItems,
    sites,
    regionsChecked,
    lobChecked,
    clientExpanded,
    isExporting,
    privilegeRoles,
    privileges,
    responseContinuation,
    isValidatingMyDataFacility,
    siteMyDataFacilityAssignments,
});
