import * as React from 'react';
import { ISiteAssessmentSummary, ISiteProfile } from 'models/site-profile';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import { ICermAssessmentSnapshot } from 'models/cerm/cerm-assessment-snapshot';
import useTranslate from 'translations/translation-utils';
import { EmeraldReadOnlyField } from 'components/form/fields/emerald-read-only-field';
import { GetPercentage } from 'utils/decimal-utils';
import { FormikProps } from 'formik';
import { Spinner } from 'components/spinner';
import { IBuilding } from 'models/building';

interface IProps {
    site: ISiteProfile;
    snapshots: ICermAssessmentSnapshot[];
    formikBag: FormikProps<ISiteAssessmentSummary>;
    siteImage: IAttachmentModel[];
    isEdit: boolean;
}

export const CermAssessmentSummaryTabReadOnlyForm: React.FC<IProps> = ({
    site,
    snapshots,
    formikBag,
    ...props
}) => {

    const translate = useTranslate();

    const getMainAddress = (): Partial<IBuilding> => {
        if (!site.buildings || site.buildings.length === 0) {
            return null;
        }

        const masterAddressBuilding = site.buildings.filter((b) => b.isMasterAddress);
        return masterAddressBuilding && masterAddressBuilding[0];
    };

    const getTierLevels = (): string => {
        const tierLevels: string[] = [];

        if (site.buildings && site.buildings.length > 0) {
            site.buildings.forEach((building) => {
                if (tierLevels.indexOf(building.tier) === -1) {
                    if (building.tier !== '') {
                        tierLevels.push(building.tier);
                    }
                }
            });
        }

        return tierLevels.join(',');
    };
    const mainAddress = getMainAddress();
    const expertReviews = snapshots.filter((snapshot) => snapshot.isExpertReview)
    const snapshot = expertReviews[expertReviews.length - 1];
    const [siteImageUrl, setSiteImageUrl] = React.useState<string | null>(null);
    const [siteImageData, setSiteImageData] = React.useState<IAttachmentModel>(null);

    React.useEffect(() => {
        if (props.siteImage &&
            props.siteImage.length > 0) {
            const sortedSiteImages = [...props.siteImage].sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
            const latestSiteImage = sortedSiteImages[0];
            const newSiteImageUrl = latestSiteImage.blobStorageUri;
            if (newSiteImageUrl !== siteImageUrl) {
                setSiteImageUrl(newSiteImageUrl);
            }
            setSiteImageData(latestSiteImage);
        }
    }, [props.siteImage, siteImageUrl]);

    return (
        <div className="columns">
            <div className="column is-6">
                <div className="summary-readonly-section">
                    <div className="site-address">
                        <EmeraldReadOnlyField
                            label={translate('CermAssessmentSummary.Label.SiteNameandAddress')}
                        >
                            <p>{site.name}</p>
                            <p>{mainAddress && mainAddress.addressLine1}</p>
                            <p>{mainAddress && mainAddress.city}</p>
                            <p>
                                <span>{mainAddress && mainAddress.postCode}</span>
                                <span> {mainAddress && mainAddress.country}</span>
                            </p>
                        </EmeraldReadOnlyField>
                    </div>
                </div>
                <div className="summary-section">
                    <EmeraldReadOnlyField
                        id="facilityDescription"
                        label={translate('CermAssessmentSummary.Lable.1')}
                        value={formikBag.values.facilityDescription}
                    />
                </div>

                <div className="summary-section">
                    <EmeraldReadOnlyField
                        id="assessmentSummary"
                        label={translate('CermAssessmentSummary.Lable.3')}
                        value={formikBag.values.assessmentSummary}
                    />
                </div>
                <div className="cerm-assessment-summary__site-details">
                    <div className="summary-section">
                        <EmeraldReadOnlyField
                            id="yearConstructed"
                            label={translate('CermAssessmentSummary.Lable.6')}
                            value={formikBag.values.yearConstructed} />
                    </div>
                    <div className="summary-section">
                        <EmeraldReadOnlyField
                            id="tierLevel"
                            label={translate('CermAssessmentSummary.Label.TierLevel')}
                            value={`Currently-Tier ${getTierLevels()}`}
                        />
                    </div>

                    <div className="summary-section">
                        <EmeraldReadOnlyField
                            id="criticalLinesOfBusiness"
                            label={translate('CermAssessmentSummary.Lable.7')}
                            value={formikBag.values.criticalLinesOfBusiness}
                        />
                    </div>

                    <div className="summary-section">
                        <EmeraldReadOnlyField
                            id="space"
                            label={translate('CermAssessmentSummary.Lable.10')}
                            value={formikBag.values.space}
                        />
                    </div>

                    <div className="summary-section">
                        <EmeraldReadOnlyField
                            id="staff"
                            label={translate('CermAssessmentSummary.Lable.11')}
                            value={formikBag.values.staff}
                        />
                    </div>
                    <div className="summary-section">
                        <EmeraldReadOnlyField
                            id="contactInfo"
                            label={translate('CermAssessmentSummary.Lable.13')}
                            value={formikBag.values.contactInfo}
                        />
                    </div>
                    <div className="summary-section ">
                        <EmeraldReadOnlyField
                            label={translate('CermAssessmentSummary.Label.Score')}
                            value={snapshot && snapshot.isExpertReview ? GetPercentage(snapshot.score) : ""}
                        />
                    </div>
                    <div className="summary-section">
                        <EmeraldReadOnlyField
                            label={translate(
                                'CermAssessmentSummary.Label.AssessmentPerformedBy'
                            )}
                            value={snapshot && snapshot.isExpertReview ? snapshot.expertReviewStatus?.startedByName ?? snapshot.reviewBy : ""}
                        />
                    </div>

                    <div className="summary-section">
                        <EmeraldReadOnlyField
                            label={translate('CermAssessmentSummary.Label.Date')}
                            value={snapshot && snapshot.isExpertReview ? snapshot.date.toDateString() : ""}
                        />
                    </div>

                    <div className="summary-section">
                        <EmeraldReadOnlyField
                            label={translate('CermAssessmentSummary.Label.ReviewType')}
                            value={snapshot && snapshot.isExpertReview ? snapshot.reviewType : ""}
                        />
                    </div>
                </div>
            </div>

            <div className="column is-6">
                <div className="cerm-assessment-summary__details">
                    <div className={siteImageUrl ? "cerm-assessment-summary site-image" : "no-site-image"}>
                        {siteImageUrl &&
                            siteImageData ?
                            <div>
                                <img src={siteImageUrl} alt={translate('CermAssessmentSummary.Label.NoImageavailable')} />
                                <div>
                                    <strong>{siteImageData.fileName}</strong>
                                    <br />
                                    <span className='is-small'>{siteImageData.createdDate?.toDateString()}</span>
                                    <span> | </span>
                                    <span style={{ display: 'inline-block' }}>
                                        {siteImageData.isClean === null && (
                                            <div className="is-warning is-small">
                                                <span className="icon is-small is-loading">
                                                    <Spinner />
                                                </span>
                                                <span>{translate('Attachments.AttachmentList.ScanStatus.Scanning')}</span>
                                            </div>
                                        )}
                                        {siteImageData.isClean === true && (
                                            <span className="is-success is-clearfix">{translate('Attachments.AttachmentList.ScanStatus.Scanned&Uploaded')}</span>
                                        )}
                                        {siteImageData.isClean === false && (
                                            <span className="is-danger is-clearfix">{translate('Attachments.AttachmentList.ScanStatus.Malwarefound')}</span>
                                        )}
                                    </span>
                                </div>
                            </div> :
                            <p className='no-image-available'>
                                <strong>{translate('CermAssessmentSummary.Label.NoImageavailable')} </strong>
                            </p>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};
