import { IKeyValue } from 'models';

export interface ILogURomonetModel {
    siteId: string;
}

export enum RomonetReportType {
    RomonetExecutiveDashboard = 'ExecSummary',
    FacilityOperationsSnapshot = 'SiteDashboard',
    ProjectTrackingDashboard = 'ProjectTrackingReport',
    EngineerReport = 'EngineerReport',
    DataReports = 'DataReports',
    DetailReport = 'DetailReport',
}

export enum dataReportType {
    LateData = 'LateDataReport',
    Variance = 'VarianceReport',
    Completeness = 'CompletenessReport',
}
export const getReportTypes: Array<IKeyValue<string>> = [
    {
        key: dataReportType.LateData,
        label: 'Romonet.EngineerReport.ReportType.LateData',
        value: dataReportType.LateData,
    },
    {
        key: dataReportType.Variance,
        label: 'Romonet.EngineerReport.ReportType.Variance',
        value: dataReportType.Variance,
    },
    {
        key: dataReportType.Completeness,
        label: 'Romonet.EngineerReport.ReportType.Completeness',
        value: dataReportType.Completeness,
    },
];

export const getDateRange = (dateRange) =>
    dateRange
        ? dateRange.map((reports) => ({
              key: reports,
              label: reports,
              value: reports,
          }))
        : [];
