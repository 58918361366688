import { IEngineerSkill, SkillLevel } from 'models';

export const getSkillLevel = (skills: IEngineerSkill[]): SkillLevel | null => {
    const c =
        skills.some((z) => z.skillLevel === SkillLevel.C) &&
        skills.filter((y) => y.skillLevel === SkillLevel.C && y.isIncluded !== false).every((x) => x.status === true);
    const b =
        skills.some((z) => z.skillLevel === SkillLevel.B) &&
        skills.filter((y) => y.skillLevel === SkillLevel.B && y.isIncluded !== false).every((x) => x.status === true);
    const a =
        skills.some((z) => z.skillLevel === SkillLevel.A) &&
        skills.filter((y) => y.skillLevel === SkillLevel.A && y.isIncluded !== false).every((x) => x.status === true);
    const skillLevel = c ? (b ? (a ? SkillLevel.A : SkillLevel.B) : SkillLevel.C) : null;
    return skillLevel;
};

export const getLocalSkillLevel = (skills: IEngineerSkill[]): SkillLevel | null => {
    const c =
        skills.some((z) => z.skillLevel === SkillLevel.C) &&
        skills.filter((y) => y.skillLevel === SkillLevel.C).every((x) => x.status === true);
    const b =
        skills.some((z) => z.skillLevel === SkillLevel.B) &&
        skills.filter((y) => y.skillLevel === SkillLevel.B).every((x) => x.status === true);
    const a =
        skills.some((z) => z.skillLevel === SkillLevel.A) &&
        skills.filter((y) => y.skillLevel === SkillLevel.A).every((x) => x.status === true);
    let skillLevel;
    if (!c) {
        if (!b) {
            skillLevel = a ? SkillLevel.A : null;
        } else {
            skillLevel = a ? SkillLevel.A : SkillLevel.B;
        }
    } else {
        skillLevel = b ? (a ? SkillLevel.A : SkillLevel.B) : SkillLevel.C;
    }
    return skillLevel;
};

export enum SkillLevelTextType {
    LevelAndCategory = 'LevelAndCategory',
    CatCertified = 'CatCertified',
    Letter = 'Letter',
    Number = 'Number',
}

export const getSkillLevelText = (skillLevel: SkillLevel | null, textType: SkillLevelTextType) => {
    switch (textType) {
        case SkillLevelTextType.LevelAndCategory:
            switch (skillLevel) {
                case SkillLevel.C:
                    return 'LEVEL 1 (CAT-C)';
                case SkillLevel.B:
                    return 'LEVEL 2 (CAT-B)';
                case SkillLevel.A:
                    return 'LEVEL 3 (CAT-A)';
                case null:
                    return '-';
            }
        case SkillLevelTextType.CatCertified:
            switch (skillLevel) {
                case SkillLevel.C:
                    return '(CAT-C Certified)';
                case SkillLevel.B:
                    return '(CAT-B Certified)';
                case SkillLevel.A:
                    return '(CAT-A Certified)';
                case null:
                    return '';
            }
        case SkillLevelTextType.Letter:
            switch (skillLevel) {
                case SkillLevel.C:
                    return 'C';
                case SkillLevel.B:
                    return 'B';
                case SkillLevel.A:
                    return 'A';
                case null:
                    return '';
            }
        case SkillLevelTextType.Number:
            switch (skillLevel) {
                case SkillLevel.C:
                    return '1';
                case SkillLevel.B:
                    return '2';
                case SkillLevel.A:
                    return '3';
                case null:
                    return '-';
            }
    }
};

export const getItemPercentageSummary = (skills: IEngineerSkill[]) => {
    var pc = (100 * skills.filter((skill) => skill.status === true ).length) / skills.length
    return isNaN(pc) ? '' : ' ' + Math.round(pc) + '% Completed';
}
