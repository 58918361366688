import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import OpportunityQuestionPage from './opportunity-questions-page';
import {
    loadOpportunityQuestions,
    createOpportunityQuestions,
    updateOpportunityQuestions,
    deleteOpportunity,
    cleanUpOpportunity,
} from 'actions/actions-v2/opportunity-action';

const getLoading = (state: IRootState) => state.opportunityState.isLoading;
const getOpportunity = (state: IRootState) => state.opportunityState.opportunity;
const mapStateToProps = createSelector(getLoading, getOpportunity, (isLoading, opportunity) => ({
    isLoading,
    opportunity,
}));

const mapDispatchToProps = {
    load: loadOpportunityQuestions,
    create: createOpportunityQuestions,
    update: updateOpportunityQuestions,
    delete: deleteOpportunity,
    cleanUp: cleanUpOpportunity,
};

export const OpportunityContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(OpportunityQuestionPage)
);
