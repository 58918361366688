import { IAppAction } from 'actions/app-action';
import { combineReducers } from 'redux';
import { s2ab } from 'utils/export-utils';
import { IMockDrillsState } from 'state/mock-drills-state';
import { MockDrillsActions } from 'actions/actions-v2/mock-drills-actions';
import saveAs from 'file-saver';

const INITIAL_STATE: IMockDrillsState = {
    isLoading: false,
    mockDrills: null,
    mockDrill: null,
    isExporting: false,
    isSaving: false
};

const isLoading = (
    state: IMockDrillsState['isLoading'] = INITIAL_STATE.isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case MockDrillsActions.LOAD_LIST:
        case MockDrillsActions.LOAD:
        case MockDrillsActions.DELETE:
            return true;

        case MockDrillsActions.LOAD_LIST_FULFILLED:
        case MockDrillsActions.LOAD_LIST_REJECTED:
        case MockDrillsActions.LOAD_FULFILLED:
        case MockDrillsActions.LOAD_REJECTED:
        case MockDrillsActions.DELETE_FULFILLED:
        case MockDrillsActions.DELETE_REJECTED:
            return false;

        default:
            return state;
    }
};

const mockDrills = (
    state: IMockDrillsState['mockDrills'] = INITIAL_STATE.mockDrills,
    action: IAppAction
) => {
    switch (action.type) {
        case MockDrillsActions.LOAD_LIST_FULFILLED:
            if (action.payload) {
                return action.payload;
            }

        default:
            return state;
    }
};

const mockDrill = (
    state: IMockDrillsState['mockDrill'] = INITIAL_STATE.mockDrill,
    action: IAppAction
) => {
    switch (action.type) {
        case MockDrillsActions.LOAD_FULFILLED:
            if (action.payload) {
                return action.payload;
            }

        case MockDrillsActions.CLEANUP:
            return INITIAL_STATE.mockDrill;

        default:
            return state;
    }
};

const isExporting = (
    state: IMockDrillsState['isExporting'] = INITIAL_STATE.isExporting,
    action: IAppAction
) => {
    switch (action.type) {
        case MockDrillsActions.EXPORT:
            return true;

        case MockDrillsActions.EXPORT_FULFILLED:
            const blob = new Blob([s2ab(atob(action.payload.fileContents))], {
                type: 'application/excel'
            });
            saveAs(blob, action.payload.fileDownloadName);
            return false;

        case MockDrillsActions.EXPORT_REJECTED:
            return false;

        default:
            return state;
    }
};

const isSaving = (
    state: IMockDrillsState['isSaving'] = INITIAL_STATE.isSaving,
    action: IAppAction
) => {
    switch (action.type) {
        case MockDrillsActions.CREATE:
        case MockDrillsActions.UPDATE:
            return true;

        case MockDrillsActions.CREATE_FULFILLED:
        case MockDrillsActions.CREATE_REJECTED:
        case MockDrillsActions.UPDATE_FULFILLED:
        case MockDrillsActions.UPDATE_REJECTED:
            return false;

        default:
            return state;
    }
};

export const mockDrillsReducer = combineReducers<IMockDrillsState>({
    isLoading,
    mockDrills,
    mockDrill,
    isExporting,
    isSaving
});
