import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { CermProcessOwnerActions } from 'actions/actions-v2/cerm-process-owner-action-v2';
import { ICermProcessOwner } from 'models/cerm-process-owners';

const controllerName = 'cermProcessOwner';

const createActions = {
    action: CermProcessOwnerActions.CREATE_CERM_PROCESS,
    actionFulfilled: CermProcessOwnerActions.CREATE_CERM_PROCESS_FULFILLED,
    actionRejected: CermProcessOwnerActions.CREATE_CERM_PROCESS_REJECTED,
} as IActionType;

const updateActions = {
    action: CermProcessOwnerActions.UPDATE_CERM_PROCESS,
    actionFulfilled: CermProcessOwnerActions.UPDATE_CERM_PROCESS_FULFILLED,
    actionRejected: CermProcessOwnerActions.UPDATE_CERM_PROCESS_REJECTED,
} as IActionType;

const getActions = {
    action: CermProcessOwnerActions.LOAD_CERM_PROCESS,
    actionFulfilled: CermProcessOwnerActions.LOAD_CERM_PROCESS_FULFILLED,
    actionRejected: CermProcessOwnerActions.LOAD_CERM_PROCESS_REJECTED,
} as IActionType;

const exportActions = {
    action: CermProcessOwnerActions.EXPORT_SITE_USERS,
    actionFulfilled: CermProcessOwnerActions.EXPORT_SITE_USERS_FULFILLED,
    actionRejected: CermProcessOwnerActions.EXPORT_SITE_USERS_REJECTED,
} as IActionType;

const createCermProcessOwnerEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createActions).post<ICermProcessOwner>(`/api/${controllerName}`);

const updateCermProcessOwnerEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateActions).put<ICermProcessOwner>(`/api/${controllerName}`);

const loadCermProcessOwnerEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getActions).getById<ICermProcessOwner>(`/api/${controllerName}/site`);
const exportSiteUsersEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, exportActions).export<ICermProcessOwner>(
        `/api/${controllerName}/exportAll`
    );

export const cermProcessOwnerEpicsV2 = combineEpics(
    createCermProcessOwnerEpic,
    updateCermProcessOwnerEpic,
    loadCermProcessOwnerEpic,
    exportSiteUsersEpic
);
