import React from 'react';
import { SchedulerItem, ISchedulerItemProps } from 'components/v2/scheduler/SchedulerItem';
import { ShiftHandoverStatus } from 'models/shifts/shift-handover';
import useTranslate from 'translations/translation-utils';

interface IProps extends ISchedulerItemProps {
    status: ShiftHandoverStatus;
    itemsNew: number;
    itemsOngoing: number;
    itemsClosed: number;
    checklistsYes: number;
    checklistsNo: number;
}

export const ShiftHandoverSchedulerItem: React.FC<IProps> = ({
    status,
    itemsNew,
    itemsOngoing,
    itemsClosed,
    checklistsYes,
    checklistsNo,
    ...props
}) => {
const translate = useTranslate();
    const titleClassName = status === null ? 'is-draft' : `is-${status.toLowerCase()}`;

    return (
        <SchedulerItem {...props} titleClassName={titleClassName}>
            <ul className="stats">
                <li>
                    <strong>{itemsNew}</strong>
                    {translate('ShiftHandoverSchedulerItem.stat.New')}
                </li>
                <li>
                    <strong>{itemsOngoing}</strong>
                    {translate('ShiftHandoverSchedulerItem.stat.Ongoing')}
                </li>
                <li>
                    <strong>{itemsClosed}</strong>
                    {translate('ShiftHandoverSchedulerItem.stat.Closed')}
                </li>
            </ul>
            <ul className="votes">
                <li className="votes-up">{checklistsYes}</li>
                <li className="votes-down">{checklistsNo}</li>
            </ul>
        </SchedulerItem>
    );
};
