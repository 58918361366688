import { IRootState } from '../store';
import { loadEngineerSkills, toggleEngineerSkillCategory} from 'actions/actions-v2/engineer-action-v2';
import { connect } from 'react-redux';
import { Dispatch } from 'react';
import { IAppAction } from 'actions/app-action';
import { ManageEngineerSkills } from './manage-engineer-skills-page';

const mapStateToProps = (state: IRootState) => {
return {
    isExportingEngineerSkills: state.skillsState.isExportingSkills,
    engineerSkills: state.skillsState.skills,
    isLoading: state.skillsState.isLoading,
    isSaving: state.skillsState.isSaving,
    expandedCategories: state.skillsState.expandedCategories,
    skillCategories: state.engineersState.skillCategories
    };
};

const mapDispatchToProps = (dispatch: Dispatch<IAppAction>) => {
return {
    loadEngineerSkills() {
        dispatch(loadEngineerSkills());
    },
    onToggleCategory(id: string) {
        dispatch(toggleEngineerSkillCategory(id));
    },
};
};

export const ManageEngineerSkillsContainer = connect(mapStateToProps, mapDispatchToProps)(ManageEngineerSkills);
