import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';

const getIsUploading = (state: IRootState) => state.actionRegisterState.isLoading;
const getAttachments = (state: IRootState) => state.actionRegisterState.attachments;

export default createSelector(getIsUploading, getAttachments, (isUploading, attachments) => ({
    isUploading,
    attachments
}));
