import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import actions from '../actions';
import { QuestionSetForm as View } from './question-set-form';
import selector from './selector';

export const QuestionSetForm = withRouter(connect(
    selector,
    actions
)(View));
