import { createSelector } from 'reselect';
import { IRootQuestionSetState } from '../../../reducers/questionSet';
import { IRootState } from 'routes/store';

const getPermissions = (state: IRootQuestionSetState) => state.app.permissions;
const getQuestionSetState = (state: IRootQuestionSetState) => state.questionSets;
const getAccessOperatingPlatformList = (state: IRootState) => {
    return state.operatingPlatformState.operatingPlatforms && state.operatingPlatformState.operatingPlatforms.filter(item => state.person.personProfile.accessRights.operatingPlatforms.includes(item.key));
};

const getLookupStatuses = createSelector(
    (state: IRootQuestionSetState) => state.app,
    (app) => app.lookupQuestionStatuses
);

const getLookupCompletion = createSelector(
    (state: IRootQuestionSetState) => state.app,
    (app) => app.lookupQuestionCompletion
);

const getExpandedItemIdArray = createSelector(
    getQuestionSetState,
    (expandedItems) => expandedItems.expandedItems
);

const getIsInitialising = createSelector(
    (state: IRootQuestionSetState) => state.questionSets,
    (state) => state.isInitialising
);

const getPerson = createSelector(
    (state: any) => state.person,
    (state) => state.personProfile
);

export default createSelector(
    getQuestionSetState,
    getIsInitialising,
    getExpandedItemIdArray,
    getLookupStatuses,
    getLookupCompletion,
    getPermissions,
    getPerson,
    getAccessOperatingPlatformList,
    (state, isInitialising, expandedItems, lookupStatuses, lookupCompletion, permissions, person, operatingPlatformList) => ({
        ...state,
        isInitialising,
        expandedItems,
        lookupStatuses,
        lookupCompletion,
        permissions,
        person,
        operatingPlatformList,
    })
);
