import React from 'react';
import { TimelineItem } from 'components/timeline';
import { IOpportunityAdvice } from 'models/opportunity-question-set';
import useTranslate from 'translations/translation-utils';
interface IProps {
    expandAll?: boolean;
    advice: IOpportunityAdvice;
}

const OpportunityQuestionnaireAdvice: React.FC<IProps> = ({ advice }) => {
    const translate = useTranslate();

    return (
        <TimelineItem>
            <div className="columns">
                <div className="column is-12">
                    <span className="grid__label is-uppercase"> {translate('OpportunityQ.Title.Advice')} -</span> {advice.title}
                </div>
            </div>
        </TimelineItem>
    );
};

export default OpportunityQuestionnaireAdvice;
