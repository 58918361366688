import React from 'react';
import { SelectField } from 'components/form/fields/select-field';
import ReactTooltip from 'react-tooltip';

interface IProps {
    expandAll?: boolean;
    answers: string[];
    questionId: string;
    selectedResponseAns?: string;
    onChange?: (questionId: string, response: string) => void;
}

const OpportunityQuestionnaireResponse: React.FC<IProps> = ({
    answers,
    questionId,
    selectedResponseAns,
    onChange,
}) => {
    const [selectedResponse, setResponse] = React.useState(selectedResponseAns);

    React.useEffect(() => {
        onChange(questionId, selectedResponseAns);
        setResponse(selectedResponseAns);
    }, [selectedResponseAns]);

    const onResponseChange = (field: { value: string; label: string }) => {
        setResponse(field.value);
        onChange(questionId, field.value);
    };

    return (
        <>
            <div data-tip={selectedResponse}>
                <SelectField
                    id="responseAnswer"
                    label=""
                    className="select-response"
                    value={{ value: selectedResponse, label: selectedResponse }}
                    options={
                        answers
                            ? answers.map((m) => ({
                                value: m,
                                label: m,
                            }))
                            : []
                    }
                    handleChange={onResponseChange}
                />
            </div>
            <ReactTooltip />
        </>
    );
};

export default OpportunityQuestionnaireResponse;
