import { ActionActions, ActionActionTypes } from '../../../../actions/action-actions';
import { RiskActions, RiskActionTypes } from '../../../../actions/risk-actions';
import { IKeyedItem } from '../../../../models/keyed-item';

export default {
    onOpen: (item: IKeyedItem): ActionActionTypes => ({
        type: ActionActions.SHOW_ATTACHMENTS,
        payload: {
            id: item.id,
            siteId: item.siteId
        }
    }),

    onClose: (item: IKeyedItem): ActionActionTypes => ({
        type: ActionActions.HIDE_ATTACHMENTS,
        payload: {
            id: item.id,
            siteId: item.siteId
        }
    }),

    // TODO: Generic action - Currently the base Attachments stuff uses the Risk Action types so stuck with this for now
    onShowAddAttachmentModal: (id: string, siteId: string): RiskActionTypes => ({
        type: RiskActions.SHOW_ADD_ATTACHMENT_MODAL,
        payload: {
            id,
            siteId
        }
    })
};
