import * as React from 'react';
import { FieldArray, Form, Formik, FormikErrors, FormikProps } from 'formik';
import { RouteComponentProps } from 'react-router';
import { array, object, string } from 'yup';
import { ArticlePanel } from '../../../components/panel';
import { IProgressSection, ProgressBar } from '../../../components/progress-bar/progress-bar';
import {
    IActionTemplate,
    IQuestion,
    IQuestionSet,
    IRiskTemplate,
} from '../../../models/question-set';
import { formikBagValidation } from './common';
import { QuestionForm } from './question-form';
import { QuestionSetForm } from './question-set-form';
import Page from 'components/v2/page/Page';
import { IKeyValue } from 'models';
import { Loader } from 'components/loader';
import { onRouteChange } from 'actions/app-actions';
import './create-question-set-page.scss';
import { ConfirmDialog } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';
import { RouteUrlManageQuestionSets } from '../routes';
import { isCreating } from 'utils/id-utils';

export interface ICreateQuestionSetPageRouteParams {
    id: string;
    questionIndex: string;
    operatingPlatform: string;
}

export interface IProps extends RouteComponentProps<ICreateQuestionSetPageRouteParams> {
    pageTitle: string;
    progressSubtitle: string;
    initialValues?: Partial<IQuestionSet>;
    isInitialising: boolean;
    isLoading: boolean;
    isCreatingQuestionSet: boolean;
    loadQuestionSet: (id: string) => void;
    createQuestionSet: (model: IQuestionSet) => void;
    updateQuestionSet: (model: IQuestionSet) => void;
    cleanUpQuestionSet: () => void;
}

export const CreateQuestionSetPage: React.FC<IProps> = ({
    match,
    location,
    pageTitle,
    progressSubtitle,
    initialValues,
    isInitialising,
    isLoading,
    loadQuestionSet,
    createQuestionSet,
    updateQuestionSet,
    cleanUpQuestionSet,
}) => {
    const translate = useTranslate();
    const riskQuestionsOverviewPageUrl = '/QuestionSets/' + match.params.operatingPlatform;
    const customLinks: Array<IKeyValue<string>> = [
        { key: riskQuestionsOverviewPageUrl, value: translate('QSet.Lable.1') },
    ];
    const questionSetId = match.params.id;

    const [displayErrorDialogue, setDisplayErrorDialogue] = React.useState(false);
    const showErrorDialogue = () => {
        setDisplayErrorDialogue(true);
    };

    const hideErrorDialogue = () => {
        setDisplayErrorDialogue(false);
    };

    React.useEffect(() => {
        if (questionSetId) {
            loadQuestionSet(questionSetId);
        }
    }, [questionSetId]);

    React.useEffect(() => {
        return () => cleanUpQuestionSet();
    }, []);

    const submit = (questionSet: Partial<IQuestionSet>) => {
        const payload = {
            ...questionSet,
            questions: questionSet.questions.map((q) => ({
                ...q,
                // Drop risk object if disabled by Kevin Wilson
                risk: q.hasRisk ? q.risk : null,
            })),
            OperatingPlatform: match.params.operatingPlatform,
        } as IQuestionSet;
        payload.reloadUrl = riskQuestionsOverviewPageUrl;
        if (questionSetId) {
            updateQuestionSet(payload);
        } else {
            createQuestionSet(payload);
        }
    };

    const questionSetSchema = object<Partial<IQuestionSet>>().shape({
        title: string().trim().required(translate('ValidationMessages.Action.SelectTitle')).nullable(true),
        category: string().required(translate('ValidationMessages.Category')).nullable(true),
        urgency: string().required(translate('ValidationMessages.UrgencyNo')).nullable(true),
        relevancyQuestion: string()
            .trim()
            .required(translate('ValidationMessages.RelevancyQ'))
            .nullable(true),
        priority: string().required(translate('ValidationMessages.Priority')).nullable(true),
        questions: array().of(
            object<Partial<IQuestion>>().shape({
                title: string().trim().required(translate('ValidationMessages.Action.SelectTitle')),
                instructions: string().trim().required(translate('ValidationMessages.Instructions')),
                compliantResponse: string().required(translate('ValidationMessages.Response')),
                hasRisk: string().required(translate('ValidationMessages.Response')).nullable(true),
                action: object<Partial<IActionTemplate>>().shape({
                    description: string()
                        .max(10000, 'Description can have maximum 10000 characters.')
                        .required(translate('ValidationMessages.Action.SelectDescription'))
                        .nullable(true),
                    categoryId: string().required(translate('ValidationMessages.Category')).nullable(true),
                    priority: string().required(translate('ValidationMessages.Priority')).nullable(true),
                }),
                risk: object().when('hasRisk', (hasRiskStr: string) => {
                    if (hasRiskStr === 'true') {
                        return object<Partial<IRiskTemplate>>().shape({
                            title: string().trim().required(translate('ValidationMessages.Action.SelectTitle')).nullable(true),
                            riskCategoryId: string()
                                .required(translate('ValidationMessages.Category'))
                                .nullable(true),
                            riskSubCategoryId: string()
                                .required(translate('ValidationMessages.SubCat'))
                                .nullable(true),
                            description: string()
                                .trim()
                                .required(translate('ValidationMessages.Action.SelectDescription'))
                                .nullable(true),
                            riskLikelihoodId: string()
                                .required(translate('ValidationMessages.likelihood'))
                                .nullable(true),
                            riskImpactId: string()
                                .required(translate('ValidationMessages.Impact'))
                                .nullable(true),
                        });
                    }
                }),
            })
        ),
    });

    const title = initialValues && initialValues.title ? initialValues.title : pageTitle;

    const getErrorsForQuestionSet = (questionSet: FormikErrors<Partial<IQuestionSet>>) =>
        Object.keys(questionSet)
            .filter((x) => x !== 'questions')
            .map((property) => {
                return (
                    <div>
                        <span>{questionSet[property]}</span>
                    </div>
                );
            });

    const getErrorsForQuestion = (question: FormikErrors<Partial<IQuestion>>) => {
        return (
            question &&
            Object.keys(question).map((property) => {
                return (
                    <>
                        {typeof question[property] === 'object' ? (
                            <>
                                <div>
                                    <strong>
                                        {property.replace(
                                            /\w\S*/g,
                                            (txt) =>
                                                txt.charAt(0).toUpperCase() +
                                                txt.substring(1).toLowerCase()
                                        )}
                                    </strong>
                                </div>
                                {getErrorsForQuestion(question[property])}
                            </>
                        ) : (
                            <div>
                                <span>{question[property]}</span>
                            </div>
                        )}
                    </>
                );
            })
        );
    };

    const questionsErrorMessages = (formErrors: FormikErrors<Partial<IQuestionSet>>) => {
        return (
            <>
                {getErrorsForQuestionSet(formErrors)}
                {formErrors?.questions?.map((question, index) => {
                    return (
                        <>
                            {question && (
                                <div className="validation-header">
                                    <strong>Question #{index + 1}</strong>
                                </div>
                            )}
                            {getErrorsForQuestion(question)}
                        </>
                    );
                })}
            </>
        );
    };

    const onChangeRoot = (formErrors, url: string) => {
        if (!formikBagValidation(formErrors)) {
            showErrorDialogue();
        } else {
            return onRouteChange(url);
        }
    };

    const getProgressSections = (
        formikBag: FormikProps<Partial<IQuestionSet>>,
        operatingPlatform: string
    ): IProgressSection[] => {
        const showProgress =
            !isCreating(formikBag.values) ||
            (formikBag.values.title &&
                formikBag.values.relevancyQuestion &&
                formikBag.touched.title &&
                formikBag.touched.relevancyQuestion);

        if (!showProgress) {
            return [
                { text: translate('OpportunityQ.QuestionSet.ProgressText') },
            ];
        }

        // Edit Record Progress
        const sections: IProgressSection[] = [
            {
                title: translate('OpportunityQ.MasterForm.TitlePlaceholder'),
                text: formikBag.values.title,
                urlLocation: `${RouteUrlManageQuestionSets(formikBag.values.id)}${operatingPlatform}`,
            },
        ];

        if (formikBag.values) {
            const questionSections = formikBag.values.questions.map<IProgressSection>(
                (question, questionIndex) => {
                    return {
                        title: `${translate('RiskProfileQuestion.Set.Question')} #${questionIndex + 1} `,
                        text: question.title,
                        urlLocation: `${RouteUrlManageQuestionSets(
                            formikBag.values.id
                        )}${questionIndex}/${operatingPlatform}`,
                    };
                }
            );

            sections.push(...questionSections);
        }

        return sections;
    };

    return (
        <Page title={title} breadcrumbCustomLinks={customLinks} isAdminPage={true}>
            {!isInitialising && (
                <div className="CreateQuestionSetPage">
                    <div className="CreateQuestionSetForm">
                        <Loader loading={isLoading}>
                            <Formik<Partial<IQuestionSet>>
                                initialValues={initialValues}
                                validationSchema={questionSetSchema}
                                onSubmit={submit}
                                enableReinitialize={true}
                                render={(formikBag) => (
                                    <div className="columns">
                                        <ConfirmDialog
                                            title={translate('QSet.Lable.2')}
                                            message={questionsErrorMessages(formikBag.errors)}
                                            isVisible={displayErrorDialogue}
                                            showConfirmButton={false}
                                            onClose={hideErrorDialogue}
                                            onOutsideDialogClick={hideErrorDialogue}
                                            buttonCancelText={translate('RiskRegisterPage.RiskRegisterToolbar.Title.Close')}
                                        />
                                        <ProgressBar
                                            title={translate('QSet.Lable.3')}
                                            subtitle={progressSubtitle}
                                            onChangeRoot={(url) =>
                                                onChangeRoot(formikBag.errors, url)
                                            }
                                            sections={getProgressSections(
                                                formikBag,
                                                match.params.operatingPlatform
                                            )}
                                            currentPath={location.pathname}
                                        />
                                        <ArticlePanel className="column">
                                            <header>
                                                <h2 className="title is-4">{pageTitle}</h2>
                                                <small>{translate('QSet.Lable.4')}</small>
                                            </header>
                                            <Form>
                                                {match.params.questionIndex ? (
                                                    <FieldArray
                                                        name="questions"
                                                        render={(arrayHelpers) => (
                                                            <QuestionForm
                                                                formikBag={formikBag}
                                                                arrayHelpers={arrayHelpers}
                                                                operatingPlatform={
                                                                    match.params.operatingPlatform
                                                                }
                                                                showErrorDialogue={showErrorDialogue}
                                                            />
                                                        )}
                                                    />
                                                ) : (
                                                    <QuestionSetForm formikBag={formikBag} />
                                                )}
                                            </Form>
                                        </ArticlePanel>
                                    </div>
                                )}
                            />
                        </Loader>
                    </div>
                </div>
            )}
        </Page>
    );
};
