import { combineReducers } from 'redux';

import { IBaseState } from '../base-state';
import { IPersonsGridState, reducer as grid } from './persons-grid';

export interface IRootPersonState extends IBaseState {
    persons: IPersonState;
}

export interface IPersonState {
    grid: IPersonsGridState;
}

export const personsReducer = combineReducers({
    grid,
});
