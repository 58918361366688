import { FormikProps } from 'formik';
import { IKeyValue } from 'models/key-value';
import { IProcessStep } from 'reducers/cermAssessments';
import { IProgressSection } from '../../../components/progress-bar/progress-bar';

export const yesNoOptions: Array<IKeyValue<string>> = [
    {
        key: 'Create new risk',
        value: JSON.stringify(true)
    },
    {
        key: 'Select existing risk',
        value: JSON.stringify(false)
    }
];
export const getProgressSections = (formikBag: FormikProps<Partial<IProcessStep>>): IProgressSection[] => {

    // Edit Record Progress
    const sections: IProgressSection[] = [];

    if (formikBag.values) {
        const questionSections =
        formikBag.values.expectedOutcomeAnswers.map<IProgressSection>((outcome, questionIndex) => {
                return {
                    title: `Outcome #${questionIndex + 1} `,
                    text: outcome.title,
                    urlLocation: `${questionIndex}`,
                };
            });

        sections.push(...questionSections);

        if (!formikBag.values.expectedOutcomeAnswers.every((c) => c.answer === c.compliantResponse)) {
            // Not all compliant
            sections.push({
                title: 'Additional Risk',
                text: formikBag.values.title,
                urlLocation: 'aditionalRisk'
            });
        }
    }

    return sections;
};
