import * as React from 'react';
import classnames from 'classnames';
import { Spinner } from '../spinner';
import './Loader.scss';

export interface IProps {
    loading: boolean;
    className?: string;
    showSpinner?: boolean;
    isWhiteSpinner?: boolean;
}

const baseClassName = 'Loader';

const css = (props: IProps) =>
    classnames(
        baseClassName,
        {
            [`${baseClassName}--is-loading`]: props.loading
        },
        props.className
    );

export const Loader: React.FC<React.PropsWithChildren<IProps>> = ({ showSpinner = true, ...props }) => (
    <div className={css(props)}>
        <div className={`${baseClassName}__content`}>{props.children}</div>
        {props.loading && (
            <div className={`${baseClassName}__spinner`}>{showSpinner && <Spinner showWhiteSpinner={props.isWhiteSpinner} />}</div>
        )}
    </div>
);
