import { connect } from 'react-redux';

import { LinkedActions } from '../../../../components/linkedActions';
import actions from './actions';
import selector from './selector';

export const RiskLinkedActions = connect(
    selector,
    actions
)(LinkedActions);
