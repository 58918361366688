import * as React from 'react';
import moment from 'moment';
import { ICriticalSystemSpare, Criticality } from 'models/critical-system-spares/critical-system-spare';
import { ReadOnlyField } from 'components/form/fields/read-only-field';
import { Panel } from 'components/v2/components';
import { formatDateString } from 'utils/date-utils';
import useTranslate from 'translations/translation-utils';

interface IProps {
    criticalSpare: ICriticalSystemSpare;
}

export const SpareDetailsTab: React.FC<IProps> = ({ criticalSpare }) => {
    const translate = useTranslate();
    const getFormattedStockQuantity = () => {
        if (criticalSpare.quantityCurrent < criticalSpare.quantityMin) {
            return <span className="stock-level-low">{criticalSpare.quantityCurrent}</span>;
        }

        return <>{criticalSpare.quantityCurrent}</>;
    };

    const getFormattedDate = (date: string | Date) => {
        if (!date || moment(date).year() < 2) {
            return null;
        }

        return formatDateString(date);
    };

    return (
        <>
            <div className="tabs-container tab-content">
                <div className="section">
                    <div className="columns">
                        <div className="column">
                            <ReadOnlyField
                                id="name"
                                label={translate('SpareDetails.Lable.1')}
                                value={criticalSpare.name}
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column">
                            <ReadOnlyField
                                id="categoryId"
                                label={translate('RiskRegisterPage.RiskFilter.Title.Category')}
                                value={criticalSpare.categoryName}
                            />
                        </div>
                        <div className="column">
                            <ReadOnlyField
                                id="subCategoryId"
                                label={translate('SpareDetails.Lable.2')}
                                value={criticalSpare.subCategoryName}
                            />
                        </div>
                        <div className="column">
                            <ReadOnlyField
                                id="isCritical"
                                label={translate('SpareDetails.Lable.3')}
                                value={
                                    criticalSpare.isCritical
                                        ? Criticality.Critical
                                        : Criticality.NonCritical
                                }
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column">
                            <ReadOnlyField
                                id="quantityMin"
                                label={translate('SpareDetails.Lable.4')}
                                value={criticalSpare.quantityMin}
                            />
                        </div>
                        <div className="column">
                            <ReadOnlyField
                                id="quantityMax"
                                label={translate('SpareDetails.Lable.5')}
                                value={criticalSpare.quantityMax}
                            />
                        </div>
                        <div className="column">
                            <ReadOnlyField
                                id="quantityCurrent"
                                label={translate('SpareDetails.Lable.7')}
                                value={getFormattedStockQuantity()}
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column">
                            <ReadOnlyField
                                id="ownerId"
                                label={translate('SpareDetails.Lable.6')}
                                value={criticalSpare.ownerName}
                            />
                        </div>
                    </div>
                    <div className="subtitle">{translate('SpareDetails.Lable.8')}</div>
                    <div className="columns">
                        <div className="column">
                            <ReadOnlyField
                                id="modelNumber"
                                label={translate('SpareDetails.Lable.9')}
                                value={criticalSpare.modelNumber}
                            />
                        </div>
                        <div className="column">
                            <ReadOnlyField
                                id="partNumber"
                                label={translate('SpareDetails.Lable.10')}
                                value={criticalSpare.partNumber}
                            />
                        </div>
                        <div className="column">
                            <ReadOnlyField
                                id="serialNumber"
                                label={translate('SpareDetails.Lable.11')}
                                value={criticalSpare.serialNumber}
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column">
                            <ReadOnlyField
                                id="estimateValue"
                                label={translate('SpareDetails.Lable.12')}
                                value={criticalSpare.estimateValue}
                            />
                        </div>
                        <div className="column">
                            <ReadOnlyField
                                id="manufacturer"
                                label={translate('CriticalSystemSparesTable.Columns.Manufacturer')}
                                value={criticalSpare.manufacturer}
                            />
                        </div>
                        <div className="column">
                            <ReadOnlyField
                                id="location"
                                label={translate('SpareDetails.Lable.13')}
                                value={criticalSpare.location}
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column">
                            <ReadOnlyField id="AMP" label="AMPs" value={criticalSpare.amp} />
                        </div>
                        <div className="column">
                            <ReadOnlyField id="volts" label="Volts" value={criticalSpare.volts} />
                        </div>
                        <div className="column">
                            <ReadOnlyField
                                id="lastTestedDate"
                                label={translate('SpareDetails.Lable.14')}
                                value={getFormattedDate(criticalSpare.lastTestedDate)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Panel title={translate('SpareDetails.Lable.15')}>
                <div className="section">
                    <ReadOnlyField
                        id="notes"
                        label=""
                        value={<div className="notes">{criticalSpare.notes}</div>}
                    />
                </div>
            </Panel>
        </>
    );
};

export default SpareDetailsTab;
