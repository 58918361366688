import { connect } from 'react-redux';

import { withRouter } from 'react-router';
import { SiteUsers as View } from './site-users';
import actions from './actions';
import selector from './selector';

export const SiteUsers = withRouter(connect(
    selector,
    actions
)(View));
