import { IAppAction } from '../app-action';
import {
    ILineOfBusinessCreate,
    IDivisionsCreate,
    IBusinessUnitCreate,
} from 'models/site-metadata-model';
import { IDivisions } from 'models/divisions-model';

export enum ISiteMetaDataActions {
    LOAD_SITE_META_DATA = '@@sitemetadata/LOAD_SITE_META_DATA',
    LOAD_SITE_META_DATA_FULFILLED = '@@sitemetadata/LOAD_SITE_META_DATA_FULFILLED',
    LOAD_SITE_META_DATA_REJECTED = '@@sitemetadata/LOAD_SITE_META_DATA_REJECTED',

    CREATE_SITE_META_DATA = '@@sitemetadata/CREATE_SITE_META_DATA',
    CREATE_SITE_META_DATA_FULFILLED = '@@sitemetadata/CREATE_SITE_META_DATA_FULFILLED',
    CREATE_SITE_META_DATA_REJECTED = '@@sitemetadata/CREATE_SITE_META_DATA_REJECTED',

    SAVE_LINE_OF_BUSINESS_SITE_META_DATA = '@@sitemetadata/SAVE_LINE_OF_BUSINESS_SITE_META_DATA',
    SAVE_LINE_OF_BUSINESS_SITE_META_DATA_FULFILLED = '@@sitemetadata/SAVE_LINE_OF_BUSINESS_SITE_META_DATA_FULFILLED',
    SAVE_LINE_OF_BUSINESS_SITE_META_DATA_REJECTED = '@@sitemetadata/SAVE_LINE_OF_BUSINESS_SITE_META_DATA_REJECTED',

    SAVE_DIVISIONS_SITE_META_DATA = '@@sitemetadata/SAVE_DIVISIONS_SITE_META_DATA',
    SAVE_DIVISIONS_SITE_META_DATA_FULFILLED = '@@sitemetadata/SAVE_DIVISIONS_SITE_META_DATA_FULFILLED',
    SAVE_DIVISIONS_SITE_META_DATA_REJECTED = '@@sitemetadata/SAVE_DIVISIONS_SITE_META_DATA_REJECTED',

    SAVE_BUSINESS_UNIT_SITE_META_DATA = '@@sitemetadata/SAVE_BUSINESS_UNIT_SITE_META_DATA',
    SAVE_BUSINESS_UNIT_SITE_META_DATA_FULFILLED = '@@sitemetadata/SAVE_BUSINESS_UNIT_SITE_META_DATA_FULFILLED',
    SAVE_BUSINESS_UNIT_SITE_META_DATA_REJECTED = '@@sitemetadata/SAVE_BUSINESS_UNIT_SITE_META_DATA_REJECTED',

    SELECT_LINE_OF_BUSINESS = '@@sitemetadata/SELECT_LINE_OF_BUSINESS',
    SELECT_LINE_OF_BUSINESS_FULFILLED = '@@sitemetadata/SELECT_LINE_OF_BUSINESS_FULFILLED',
    SELECT_LINE_OF_BUSINESS_REJECTED = '@@sitemetadata/SELECT_LINE_OF_BUSINESS_REJECTED',

    SELECT_DIVISION = '@@sitemetadata/SELECT_DIVISION',
    SELECT_DIVISION_FULFILLED = '@@sitemetadata/SELECT_DIVISION_FULFILLED',
    SELECT_DIVISION_REJECTED = '@@sitemetadata/SELECT_DIVISION_REJECTED',

    GET_DIVISIONS_FOR_LINE_OF_BUSINESS = '@@sitemetadata/GET_DIVISIONS_FOR_LINE_OF_BUSINESS',
    GET_DIVISIONS_FOR_LINE_OF_BUSINESS_FULFILLED = '@@sitemetadata/GET_DIVISIONS_FOR_LINE_OF_BUSINESS_FULFILLED',
    GET_DIVISIONS_FOR_LINE_OF_BUSINESS_REJECTED = '@@sitemetadata/GET_DIVISIONS_FOR_LINE_OF_BUSINESS_REJECTED',

    RESET_SITE_META_DATA = '@@ShiftHandover/RESET_SITE_META_DATA',
}

export const loadSiteMetaData = (): IAppAction => ({
    type: ISiteMetaDataActions.LOAD_SITE_META_DATA,
});

export const createSiteMeta = (
    lineOfBusinesses: ILineOfBusinessCreate[],
    divisions: IDivisionsCreate[],
    businessUnits: IBusinessUnitCreate[]
): IAppAction => ({
    type: ISiteMetaDataActions.CREATE_SITE_META_DATA,
    payload: {
        LineOfBusiness: lineOfBusinesses,
        Divisions: divisions,
        BusinessUnit: businessUnits,
    },
});

export const saveLineOfBusinessSiteMetaData = (
    lineofBusiness: ILineOfBusinessCreate[]
): IAppAction => ({
    type: ISiteMetaDataActions.SAVE_LINE_OF_BUSINESS_SITE_META_DATA,
    payload: { lineofBusiness },
});

export const saveDivisionsSiteMetaData = (divisions: IDivisionsCreate[]): IAppAction => ({
    type: ISiteMetaDataActions.SAVE_DIVISIONS_SITE_META_DATA,
    payload: { divisions },
});

export const saveBusinessUnitsSiteMetaData = (
    businessUnits: IBusinessUnitCreate[]
): IAppAction => ({
    type: ISiteMetaDataActions.SAVE_BUSINESS_UNIT_SITE_META_DATA,
    payload: { businessUnits },
});

export const selectLineOfBusiness = (
    lineOfBusinessId: string,
    lineOfBusinessLabel: string,
    count: number,
    isActive: boolean
): IAppAction => ({
    type: ISiteMetaDataActions.SELECT_LINE_OF_BUSINESS,
    payload: {
        id: lineOfBusinessId,
        label: lineOfBusinessLabel,
        count: count,
        isActive: isActive
    },
});

export const selectDivision = (
    divisionId: string,
    divisionLabel: string,
    count: number,
    isActive: boolean
): IAppAction => ({
    type: ISiteMetaDataActions.SELECT_DIVISION,
    payload: {
        id: divisionId,
        label: divisionLabel,
        count: count,
        isActive: isActive
    },
});

export const getDivisionsForLineOfBusiness = (
    divisionsForLineOfBusiness: IDivisions[]
): IAppAction => ({
    type: ISiteMetaDataActions.GET_DIVISIONS_FOR_LINE_OF_BUSINESS,
    payload: {
        model: divisionsForLineOfBusiness
    },
});

export const resetSiteMetaData = (): IAppAction => ({
    type: ISiteMetaDataActions.RESET_SITE_META_DATA
});