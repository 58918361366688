import React from 'react';
import classNames from 'classnames';
import { IButtonProps, Button } from './Button';
import { throttle } from 'lodash';

interface IProps extends IButtonProps {
    float?: boolean;
    tooltip?: string;
}

export const FloatingButton: React.FC<IProps> = ({ float = true, tooltip, children, ...props }) => {
    const [disabledFloat, setDisabledFloat] = React.useState(false);
    const wrapper = React.useRef(null);

    React.useEffect(() => {
        if ('IntersectionObserver' in window) {
            const watchMainContainer = new IntersectionObserver(trackElementInViewport, {
                threshold: [0.5]
            });
            watchMainContainer.observe(document.getElementsByClassName('main')[0]);

            return () => {
                watchMainContainer.disconnect();
            };
        }
    }, []);

    React.useEffect(() => {
        const onScroll = throttle(trackElementInViewport, 500);
        document.addEventListener('scroll', onScroll);

        return () => {
            document.removeEventListener('scroll', onScroll);
        };
    }, []);

    const trackElementInViewport = () => {
        setDisabledFloat(isElementInViewport());
    };

    const isElementInViewport = () => {
        const el = document.getElementsByClassName('button-float-default-location')[0];
        const rect = el.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <=
                (window.innerHeight ||
                    document.documentElement.clientHeight) /* or $(window).height() */ &&
            rect.right <=
                (window.innerWidth ||
                    document.documentElement.clientWidth) /* or $(window).width() */
        );
    };

    return (
        <span className="button-float-default-location">
            <span
                className={classNames('button-float-wrapper', float && !disabledFloat && 'active')}
                ref={wrapper}
            >
                <p>{tooltip}</p>
                <Button {...props}>{children}</Button>
            </span>
        </span>
    );
};
