import * as React from 'react';
import Page from 'components/v2/page/Page';
import { RouteComponentProps } from 'react-router';
import { IMasterProcessStep, IMasterOutcome } from 'models/master-process-step/master-process-step';
import {
    Table,
    Toolbar,
    ToolbarGroup,
    Button,
    ToolbarGroupRight,
    FloatingButton,
} from 'components/v2/components';
import { CermComplianceScore } from 'components/cerm/CermComplianceScore';
import { history } from 'routes/App';
import { IProcessStepAnswer, IProcessStepOutcome } from 'models/cerm/process-step-answer';
import { CermProcessStepRiskPanelContainer } from 'components/cerm/CermProcessStepRiskPanelContainer';
import { Loader } from 'components/loader';
import {
    mapOutcomeCompliance,
    isValidProcessStepAnswer,
    isProcessStepNotApplicable,
    isOutcomeExistInMaster,
} from 'components/cerm/shared/cerm-utils';
import { IKeyValue } from 'models';
import { CermOutcomeTriStateToggle } from './shared/CermOutcomeTriStateToggle';
import { ICermAssessmentResult, IExpertReviewStatus } from 'models/cerm/cerm-assessment-result';
import { useCermAssessmentResult } from 'components/cerm/shared/useCermAssessmentResult';
import { CermProcessStepNotApplicableAlert } from './shared/CermProcessStepNotApplicableAlert';
import { useMasterProcessSteps } from 'components/cerm/shared/useMasterProcessSteps';
import { useProcessStepAnswer } from 'components/cerm/shared/useProcessStepAnswer';
import { ISiteProfile } from 'models/site-profile';
import { checkPermission } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import { getUserId } from 'helpers/helpers';
import useTranslate from 'translations/translation-utils';
import { IOperatingPlatform } from 'models/operating-platform-model';
import { getTitlePrefixGeneric } from 'models/cerm/cerm-assessment-result';

interface IParams {
    id: string;
}

interface IProps extends RouteComponentProps<IParams> {
    isLoading: boolean;
    siteId: string;
    site: ISiteProfile;
    masterProcessSteps: IMasterProcessStep[];
    processStepAnswer: IProcessStepAnswer;
    cermAssessmentResult: ICermAssessmentResult;
    permissions: Array<IKeyValue<string>>;
    expertReviewStatus: IExpertReviewStatus;
    loadMasterProcessSteps: (operatingPlatform: string) => void;
    loadProcessStepAnswer: (masterProcessStepId: string, siteId: string) => void;
    loadCermAssessmentResult: (siteId: string) => void;
    setProcessStepAnswer: (processStepAnswer: IProcessStepAnswer) => void;
    loadCermAssessmentExpertReview(siteId: string);
    allOperatingPlatforms: IOperatingPlatform[];
}

export const CermAssessmentProcessStepPage: React.FC<IProps> = ({
    match,
    isLoading,
    siteId,
    site,
    masterProcessSteps,
    processStepAnswer,
    cermAssessmentResult,
    permissions,
    expertReviewStatus,
    loadMasterProcessSteps,
    loadProcessStepAnswer,
    loadCermAssessmentResult,
    setProcessStepAnswer,
    loadCermAssessmentExpertReview,
    allOperatingPlatforms,
}) => {
    const translate = useTranslate();
    const [shouldBlockNavigation, setShouldBlockNavigation] = React.useState(false);
    const masterProcessStepId = match.params.id;
    const cermAssessmentPageUrl = '/CermAssessment/assessment';

    const titlePrefix = getTitlePrefixGeneric(site.operatingPlatform, allOperatingPlatforms);
    const customLinks: Array<IKeyValue<string>> = [
        { key: cermAssessmentPageUrl, value: `${titlePrefix} Assessment` }
    ];

    useMasterProcessSteps(masterProcessSteps, loadMasterProcessSteps, site.operatingPlatform);
    useCermAssessmentResult(siteId, cermAssessmentResult, loadCermAssessmentResult);
    useProcessStepAnswer(siteId, masterProcessStepId, loadProcessStepAnswer);

    React.useEffect(() => {
        if (siteId && !expertReviewStatus) {
            loadCermAssessmentExpertReview(siteId);
        }
    }, [siteId]);

    const masterProcessStep = masterProcessSteps
        ? masterProcessSteps.find((s) => s.id === masterProcessStepId)
        : null;

    const getComplianceScore = () => {
        if (!masterProcessStep || !isValidProcessStepAnswer(processStepAnswer)) {
            return null;
        }

        const totalCompliances = processStepAnswer.processStepOutcomes.countMatches(
            (outcome) =>
                outcome.compliance === outcome.compliantResponse &&
                isOutcomeExistInMaster(masterProcessStep, outcome)
        );
        const totalOutcomes = masterProcessStep.expectedOutcomes.length;

        return (totalCompliances / totalOutcomes) * 100;
    };

    const setProcessStepOutcome = (outcomeId: string, compliance?: boolean) => {
        if (isValidProcessStepAnswer(processStepAnswer)) {
            const processStepAnswerToBeUpdated = mapOutcomeCompliance(
                { ...processStepAnswer, siteId },
                outcomeId,
                compliance
            );
            setProcessStepAnswer(processStepAnswerToBeUpdated);
        } else {
            const master = masterProcessSteps.find((p) => p.id === masterProcessStepId);
            let process = null;

            if (!processStepAnswer) {
                process = {
                    masterProcessStepId,
                    pillar: master.pillar,
                    processNumber: master.processNumber,
                    service: master.service,
                    shard: siteId,
                    siteId,
                } as IProcessStepAnswer;
            } else {
                process = { ...processStepAnswer, siteId };
            }

            const outcomes = master.expectedOutcomes.map((m) => {
                const outcome = {
                    id: m.id,
                    actionOrEvent: m.actionOrEvent,
                    compliantResponse: m.compliantResponse,
                } as IProcessStepOutcome;
                if (m.id === outcomeId) {
                    outcome.lastUpdated = new Date();
                    outcome.compliance = compliance;
                }
                return outcome;
            });

            const result = { ...process, processStepOutcomes: outcomes } as IProcessStepAnswer;

            setProcessStepAnswer(result);
        }

        setShouldBlockNavigation(true);
    };

    const getOutcomeResult = (masterOutcome: IMasterOutcome, index: number) => {
        let outcomeResult = {
            attachmentNumber: 0,
            uploadsUpdated: '-',
            commentsUpdated: '-',
            lastUpdated: '-',
            compliance: null,
        };

        if (isValidProcessStepAnswer(processStepAnswer)) {
            const foundOutcome = processStepAnswer.processStepOutcomes.find(
                (p) => p.id === masterOutcome.id
            );

            if (foundOutcome) {
                outcomeResult = {
                    ...outcomeResult,
                    lastUpdated: foundOutcome.lastUpdated ? foundOutcome.lastUpdated.toDateString() : '-',
                    compliance: foundOutcome.compliance,
                };

                if (processStepAnswer.outcomeExtras) {
                    const foundExtras = processStepAnswer.outcomeExtras.find(
                        (p) => p.id === masterOutcome.id
                    );
                    outcomeResult = {
                        ...outcomeResult,
                        attachmentNumber: foundExtras.attachmentNumber,
                        uploadsUpdated: foundExtras.uploadsUpdated ? foundExtras.uploadsUpdated.toDateString() : '-',
                        commentsUpdated: foundExtras.commentsUpdated ? foundExtras.commentsUpdated.toDateString() : '-',
                    };
                }
            }
        }

        const tdProps = {
            onClick: () =>
                history.push(
                    `/CermAssessment/ProcessStep/${masterProcessStep.id}/${masterOutcome.id}`
                ),
        };

        const canEdit = checkPermission(Privilege.CermAssessmentOutcomeUpdate, permissions)

        return (
            <tr key={masterOutcome.id}>
                <td className="clickable" {...tdProps}>
                    #{index + 1}
                </td>
                <td className="clickable" {...tdProps}>
                    {masterOutcome.title}
                </td>
                <td className="clickable" {...tdProps}>
                    {outcomeResult.attachmentNumber}
                </td>
                <td className="clickable no-wrap" {...tdProps}>
                    {outcomeResult.uploadsUpdated}
                </td>
                <td className="clickable no-wrap" {...tdProps}>
                    {outcomeResult.commentsUpdated}
                </td>
                <td className="clickable no-wrap" {...tdProps}>
                    {outcomeResult.lastUpdated}
                </td>
                <td>
                    <CermOutcomeTriStateToggle
                        masterOutcome={masterOutcome}
                        processStepAnswer={processStepAnswer}
                        compliance={outcomeResult.compliance}
                        canEdit={canEdit}
                        onChange={(value) =>
                            setProcessStepOutcome(masterOutcome.id, value)
                        }
                    />
                </td>
            </tr>
        );
    };

    const backToPage = () => history.push(cermAssessmentPageUrl);
    const canEdit = checkPermission(Privilege.CermAssessmentOutcomeUpdate, permissions);
    const canEditInExpertReview = (): boolean => {
        if (
            masterProcessStepId &&
            expertReviewStatus &&
            expertReviewStatus.isInReview === true
        ) {
            return (
                expertReviewStatus &&
                (expertReviewStatus.startedById === undefined ||
                    expertReviewStatus.startedById === null ||
                    (expertReviewStatus.startedById &&
                        (expertReviewStatus.startedById === getUserId() ||
                            checkPermission(
                                Privilege.CermAssessmentExpertReviewFinish,
                                permissions
                            ))))
            );
        }

        return true;
    };
    const pageTitle = masterProcessStep
        ? `${titlePrefix} ${masterProcessStep.processNumber}: ${masterProcessStep.title}`
        : `${titlePrefix} Assessment`;

    return (
        <Page
            title={pageTitle}
            className="cerm-assessment-process-step-page"
            breadcrumbCustomLinks={customLinks}
            showConfirmNavigateAwayDialog={shouldBlockNavigation}
            redirectOnSiteChange={true}
            redirectOnSiteChangeUrl={cermAssessmentPageUrl}
        >
            {isProcessStepNotApplicable(cermAssessmentResult, masterProcessStepId) ? (
                <CermProcessStepNotApplicableAlert />
            ) : (
                <>
                    <CermComplianceScore score={getComplianceScore()} />
                    <Loader loading={isLoading}>
                        {masterProcessStep ? (
                            <>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th className="narrow">#</th>
                                            <th>{translate('CermProcessStep.Lable.3')}</th>
                                            <th className="narrow">{translate('CermProcessStep.Lable.4')}</th>
                                            <th className="narrow">{translate('CermProcessStep.Lable.5')}</th>
                                            <th className="narrow">{translate('CermProcessStep.Lable.6')}</th>
                                            <th className="narrow">{translate('CermProcessStep.Lable.7')}</th>
                                            <th className="narrow">{translate('SideNavBar.Labels.Compliance')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {masterProcessStep.expectedOutcomes &&
                                            masterProcessStep.expectedOutcomes.map(
                                                (outcome, index) => getOutcomeResult(outcome, index)
                                            )}
                                    </tbody>
                                </Table>
                                {processStepAnswer && (
                                    <CermProcessStepRiskPanelContainer
                                        masterProcessStep={masterProcessStep}
                                        processStepAnswer={processStepAnswer}
                                        setShouldBlockNavigation={setShouldBlockNavigation}
                                        canEdit={canEdit}
                                        canEditInExpertReview={canEditInExpertReview}
                                    />
                                )}
                                <Toolbar type="save">
                                    <ToolbarGroup>
                                        <Button buttonType="cancel" onClick={backToPage}>
                                            {translate('Globals.Label.Cancel')}
                                        </Button>
                                    </ToolbarGroup>
                                    <ToolbarGroupRight>
                                        <FloatingButton
                                            type="submit"
                                            form="riskPanelForms"
                                            float={shouldBlockNavigation}
                                            tooltip={translate('Globals.Label.PleaseClick')}
                                            disabled={!canEdit}
                                        >
                                            {translate('Globals.Label.Save')}
                                        </FloatingButton>
                                    </ToolbarGroupRight>
                                </Toolbar>
                            </>
                        ) : null}
                    </Loader>
                </>
            )}
        </Page>
    );
};
