import { RiskActions, RiskActionTypes } from '../../../../actions/risk-actions';
import { IKeyedItem } from '../../../../models/keyed-item';

export default {
    onOpen: (item: IKeyedItem): RiskActionTypes => ({
        type: RiskActions.SHOW_ATTACHMENTS,
        payload: {
            id: item.id,
            siteId: item.siteId
        }
    }),

    onClose: (item: IKeyedItem): RiskActionTypes => ({
        type: RiskActions.HIDE_ATTACHMENTS,
        payload: {
            id: item.id,
            siteId: item.siteId
        }
    }),

    onShowAddAttachmentModal: (id: string, siteId: string): RiskActionTypes => ({
        type: RiskActions.SHOW_ADD_ATTACHMENT_MODAL,
        payload: {
            id,
            siteId
        }
    })
};
