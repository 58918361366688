import { IEmeraldLabelInput } from '../shared/label.input';

export class EmeraldSliderCoreInputs implements IEmeraldSliderCoreInputs {
  value = 0;
  min = 0;
  max = 100;
  step?: number;
  name?: string;
  label?: string;
  isRange?: boolean = false;
  disabled?: boolean = false;
  isDiscrete?: boolean = false;
  valueStart?: number = 0;
  minRange?: number;
  hasTickMarks?: boolean = false;
  valuePrefix?: string;
  valueSuffix?: string;
  showInput?: boolean = false;
}
export interface IEmeraldSliderCoreInputs extends IEmeraldLabelInput {
  value: number;
  min: number;
  max: number;
  step?: number;
  name?: string;
  label?: string;
  isRange?: boolean;
  disabled?: boolean;
  isDiscrete?: boolean;
  valueStart?: number;
  minRange?: number;
  hasTickMarks?: boolean;
  valuePrefix?: string;
  valueSuffix?: string;
  showInput?: boolean;
}
