import * as React from 'react';
import Select from 'react-select';
import { FormikProps } from 'formik';
import {
    IMockDrillReport,
    IMockDrillParticipant,
    MockDrillParticipation,
    MockDrillReportStatus,
} from 'models/mock-drills';
import { Loader } from 'components/loader';
import { isEmpty } from 'lodash';
import { ISelectOption } from 'models/select-option';
import { Button, Table, ButtonWithConfirmDialog } from 'components/v2/components';
import { IPersonLookup } from 'models/person-lookup';
import { buildSitePeoplePrivilegeSelectOptions } from 'utils/form-utils';
import { getDisplayString } from 'utils/string-utils';
import { IPrivilege } from 'reducers/manageRoles/manageRoles-grid';
import useTranslate from 'translations/translation-utils';

interface IProps {
    isLoading: boolean;
    formikBag: FormikProps<IMockDrillReport>;
    sitePersons: IPersonLookup[];
    croPersons: IPersonLookup[];
    participants: IMockDrillParticipant[];
    hasReadWritePermission: boolean;
    mockdrillParticipantsPrivilege: IPrivilege;
    setParticipants: (participants: IMockDrillParticipant[]) => void;
    loadPrivilegeRoles: (id: string) => void;
}

export const MockDrillReportParticipantsTab: React.FC<IProps> = ({
    isLoading,
    formikBag,
    sitePersons,
    croPersons,
    participants,
    hasReadWritePermission,
    mockdrillParticipantsPrivilege,
    setParticipants,
    loadPrivilegeRoles
}) => {
    const translate = useTranslate();
    const reportStatus = formikBag.values.status;
    const drillLeaderPersonId = formikBag.values.drillLeaderPersonId;
    const [availablePeople, setAvailablePeople] = React.useState<ISelectOption[]>([]);
    const [participantDropDownValues, setParticipantDropDownValues] = React.useState<
        ISelectOption[]
    >([]);
    const [sitePeople, setSitePeople] = React.useState<IPersonLookup[]>([]);

    
    const getCurrentAvailableparticipants = () => {
        return sitePeople.filter(
            (people) =>
                (isEmpty(participants) ||
                    !participants.some((participant) => participant.id === people.id)) &&
                (sitePeople.length === 1 || people.id !== drillLeaderPersonId)
        );
    };

    React.useEffect(()=>{
        loadPrivilegeRoles('MockDrillsParticipants')
    },[])

    React.useEffect(() => {
        let persons = [...sitePersons]
        if (!isEmpty(sitePeople) && mockdrillParticipantsPrivilege) {
            if(mockdrillParticipantsPrivilege?.rolesInPrivilege.findIndex(x=> x.value=='CRO')>-1)
            persons = persons.concat(croPersons)
        }
        setSitePeople(persons)
    }, [sitePersons, mockdrillParticipantsPrivilege]); 

    React.useEffect(() => {
        if (!isEmpty(sitePeople)) {
            setAvailablePeople(buildSitePeoplePrivilegeSelectOptions(getCurrentAvailableparticipants(), mockdrillParticipantsPrivilege));
        }
    }, [sitePeople, participants, mockdrillParticipantsPrivilege]); 

    React.useEffect(() => {
        if (!isEmpty(sitePeople)) {
            setAvailablePeople(buildSitePeoplePrivilegeSelectOptions(getCurrentAvailableparticipants(), mockdrillParticipantsPrivilege));
        }
    }, [sitePeople, participants, mockdrillParticipantsPrivilege]);

    const onSelectParticipantChange = (options: ISelectOption[]) => {
        setParticipantDropDownValues(options);
    };

    const onAddParticipantClick = () => {
        const newSelected = participantDropDownValues.map((p) => getParticipant(p.value));
        setParticipants([...participants, ...newSelected]);
        setParticipantDropDownValues([]);
    };

    const getParticipant = (id: string): IMockDrillParticipant => {
        const found = sitePeople.find((people) => people.id === id);
        if (!found) {
            return null;
        }

        return {
            id,
            fullName: found.fullName,
            firstName: found.firstName,
            lastName: found.lastName,
            email: found.email,
            roleNames: found.roleNames,
            isActive: true,
            participation: MockDrillParticipation.Unanswered,
        } as IMockDrillParticipant;
    };

    const onRemoveParticipantClick = (id: string) => {
        setParticipants(participants.filter((p) => p.id !== id));
    };

    return (
        <div className="tabs-container tabs-container-table-only tab-participants">
            <Loader loading={isLoading}>
                {isEmpty(participants) ? (
                    <p className="no-data">
                        {translate('MockDrills.Lable.5')}
                    </p>
                ) : (
                    <Table>
                        <thead>
                            <tr>
                                <th>{translate('GainAccess.Labels.FirstName')}</th>
                                <th>{translate('GainAccess.Labels.LastName')}</th>
                                <th>{translate('RiskApproversTab.Title.Email')}</th>
                                <th>{translate('RiskApproversTab.Title.SiteRoles')}</th>
                                <th>{translate('RiskApproversTab.Title.Participation')}</th>
                                {hasReadWritePermission && <th />}
                            </tr>
                        </thead>
                        <tbody>
                            {participants.map((people, index) => (
                                <tr key={index}>
                                    <td>{people.firstName}</td>
                                    <td>{people.lastName}</td>
                                    <td>{people.email}</td>
                                    <td>{getDisplayString(people.roleNames)}</td>
                                    <td>{people.participation}</td>
                                    {hasReadWritePermission &&
                                        reportStatus !== MockDrillReportStatus.Completed && (
                                            <td>
                                                <ButtonWithConfirmDialog
                                                    title={translate('MockDrill.Repost.Lable.1')}
                                                    message={translate('MockDrill.Repost.Lable.2')}
                                                    buttonId={`remove-people-${people.id}`}
                                                    buttonClassName="remove"
                                                    buttonText={translate('IncidentRiskActions.Trash.Remove')}
                                                    onConfirm={() =>
                                                        onRemoveParticipantClick(people.id)
                                                    }
                                                />
                                            </td>
                                        )}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
                {hasReadWritePermission && (
                    <div className="columns dropdown-add-people">
                        <div className="column is-2 has-text-right">
                            <label htmlFor="selectParticipant">{translate('FacilityPopup.Button.Add')}</label>
                        </div>
                        <div className="column is-8">
                            <Select
                                id="selectParticipant"
                                className="dropdown-add-people-select"
                                classNamePrefix="dropdown-multi"
                                value={participantDropDownValues}
                                options={availablePeople}
                                onChange={onSelectParticipantChange}
                                isMulti={true}
                            />
                        </div>
                        <div className="column is-2">
                            <Button id="addParticipants" onClick={onAddParticipantClick}>
                                {translate('FacilityPopup.Button.Add')}
                            </Button>
                        </div>
                    </div>
                )}
            </Loader>
        </div>
    );
};
