import { combineReducers } from 'redux';
import { ActionActions, ActionActionTypes } from '../../actions/action-actions';
import { IAction } from 'models/action';
import { ActionActions as ActionActionsV2 } from 'actions/actions-v2/action-action-v2';
import { IAppAction } from 'actions/app-action';

export interface IActionFormState {
    showUpsertActionModal: boolean;
    upsertItem: Partial<IAction>;
    linkedToId?: string;
}

export const INITIAL_STATE: IActionFormState = {
    showUpsertActionModal: false,
    upsertItem: null,
    linkedToId: null
};

const linkedToId = (
    state: IActionFormState['linkedToId'] = INITIAL_STATE.linkedToId,
    action: ActionActionTypes
) => {
    switch (action.type) {
        case ActionActions.SHOW_UPSERT_ACTION_MODAL:
            return action.payload.item.riskItemId;

        case ActionActions.HIDE_UPSERT_ACTION_MODAL:
            return null;

        default:
            return state;
    }
};

const showUpsertActionModal = (
    state: IActionFormState['showUpsertActionModal'] = INITIAL_STATE.showUpsertActionModal,
    action: IAppAction
) => {
    switch (action.type) {
        case ActionActions.SHOW_UPSERT_ACTION_MODAL:
            return true;

        case ActionActions.HIDE_UPSERT_ACTION_MODAL:
        case ActionActionsV2.CREATE_ACTION_FULFILLED_FOR_INCIDENT:
        case ActionActionsV2.CREATE_ACTION_FOR_RISK_FULFILLED:
        case ActionActionsV2.UPDATE_ACTION_FOR_RISK_FULFILLED:
        case ActionActionsV2.ARCHIVE_ACTION_FOR_RISK_FULFILLED:
        case ActionActionsV2.CREATE_ACTION_FOR_MOCK_DRILL_REPORT_FULFILLED:
        case ActionActionsV2.UPDATE_ACTION_FOR_MOCK_DRILL_REPORT_FULFILLED:
        case ActionActionsV2.ARCHIVE_ACTION_FOR_MOCK_DRILL_REPORT_FULFILLED:
        case ActionActionsV2.UPDATE_ACTION_FULFILLED:
        case ActionActionsV2.CREATE_ACTION_FULFILLED:
        case ActionActionsV2.UPDATE_MACRO_ACTION_FOR_RISK_FULFILLED:
        case ActionActionsV2.CREATE_MACRO_ACTION_FULFILLED:
            return false;

        default:
            return state;
    }
};

const upsertItem = (
    state: Partial<IAction> = INITIAL_STATE.upsertItem,
    action: ActionActionTypes
) => {
    switch (action.type) {
        case ActionActions.HIDE_UPSERT_ACTION_MODAL:
            return null;
        case ActionActions.SHOW_UPSERT_ACTION_MODAL:
            return action.payload.item;
        default:
            return state;
    }
};

export const reducer = combineReducers<IActionFormState>({
    showUpsertActionModal,
    linkedToId,
    upsertItem
});
