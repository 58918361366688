import * as React from 'react';
import { ToggleButton, Button } from '../v2/components';
import { FormikProps } from 'formik';
import { IEventTemplate } from 'models/master-process-step/master-process-step';
import { TextField } from '../form/fields/text-field';
import { DatePickerField } from '../form/fields/date-picker-field';
import { NumberField } from '../form/fields/number-field';
import { RadioField } from '../form/fields/radio-field';
import { IKeyValue } from 'models';
import { object, string, number } from 'yup';
import { isEmpty } from 'lodash';
import useTranslate from 'translations/translation-utils';

interface IProps {
    eventFormik: FormikProps<IEventTemplate>;
    canEdit: boolean;
}

export const eventTemplateFormValidationSchema = object<Partial<IEventTemplate>>().shape({
    title: string().required('Please enter title'),
    startDate: string().required('Please enter start date'),
    numberOfOccurrences: number()
        .required('Please enter number of occurrences')
        .min(1, 'Number of occurrences must be at least 1')
        .max(30, 'Number of occurrences must not exceed 30'),
    recurrenceValue: number()
        .required('Please enter number of recurrence')
        .min(1, 'Number of recurrence must be at least 1')
        .max(180, 'Number of recurrence must not exceed 180'),
    recurrenceUnit: string().required('Please select recurrence days or months')
});

export const CermProcessStepOutcomeEventTable: React.FC<IProps> = ({ eventFormik, canEdit }) => {
    const [showDetails, setShowDetails] = React.useState(true);

    const translate = useTranslate();

    React.useEffect(() => {
        if (!isEmpty(eventFormik.errors)) {
            setShowDetails(true);
        }
    }, [eventFormik.isValidating]);

    const recurrenceOptions: Array<IKeyValue<string>> = [
        {
            key: 'Days',
            value: 'day'
        },
        {
            key: 'Months',
            value: 'month'
        }
    ];

    const handleDateChange = (newDate: Date) => {
        eventFormik.setFieldValue('startDate', newDate);
    };

    const handleRecurrenceUnitChange = (event: any) => {
        eventFormik.setFieldValue('recurrenceUnit', event.currentTarget.value);
    };

    const overwriteExistingEvents = () => {
        eventFormik.setFieldValue('overwriteExistingEvents', true);
        setShowDetails(true);
    };

    return !eventFormik.values.hasExistingEvents || eventFormik.values.overwriteExistingEvents ? (
        <>
            <tr onClick={() => setShowDetails(!showDetails)}>
                <td className="no-wrap">
                    <strong>{translate('Cerm.ProcessStepOutcomes.Labels.Event')}</strong>
                </td>
                <td className="is-hidden-touch" />
                <td>{eventFormik.values.title}</td>
                <td className="is-hidden-touch" />
                <td className="is-hidden-touch" />
                <td className="is-hidden-touch" />
                <td className="narrow">
                    <ToggleButton
                        isEnabled={showDetails && canEdit}
                        onClick={() => setShowDetails(!showDetails)}
                    />
                </td>
            </tr>
            {/* It is mandatory to use css to hide the form instead of make it not render in DOM in order for the Formik validation to work properly */}
            <tr className={showDetails ? '' : 'is-hidden'}>
                <td colSpan={7} className="form">
                    <div className="columns">
                        <div className="column">
                            <TextField
                                id="title"
                                label={translate('Cerm.ProcessStepOutcomes.Labels.EventTitle') + '*'}
                                value={eventFormik.values.title}
                                handleChange={eventFormik.handleChange}
                                error={eventFormik.errors.title}
                                setVal={eventFormik.setFieldValue}
                                isDisabled={!canEdit}
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-3 is-12-mobile">
                            <DatePickerField
                                id="startDate"
                                label={translate('Cerm.ProcessStepOutcomes.Labels.StartDate')}
                                value={eventFormik.values.startDate}
                                handleChange={handleDateChange}
                                handleBlur={eventFormik.handleBlur}
                                isDisabled={!canEdit}
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-3 is-12-mobile">
                            <NumberField
                                id="numberOfOccurrences"
                                label={translate('Cerm.ProcessStepOutcomes.Labels.NumberOfOccurrences')}
                                error={eventFormik.errors.numberOfOccurrences}
                                value={eventFormik.values.numberOfOccurrences}
                                handleChange={eventFormik.handleChange}
                                handleBlur={eventFormik.handleBlur}
                                allowNegative={false}
                                thousandSeparator={false}
                                fixedDecimalScale={true}
                                decimalScale={0}
                                min={1}
                                minLength={1}
                                max={30}
                                maxLength={2}
                                isDisabled={!canEdit}
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-3 is-12-mobile">
                            <NumberField
                                id="recurrenceValue"
                                label={translate('Cerm.ProcessStepOutcomes.Labels.NumberOfRecurrence')}
                                error={eventFormik.errors.recurrenceValue}
                                value={eventFormik.values.recurrenceValue}
                                handleChange={eventFormik.handleChange}
                                handleBlur={eventFormik.handleBlur}
                                allowNegative={false}
                                thousandSeparator={false}
                                fixedDecimalScale={true}
                                decimalScale={0}
                                min={1}
                                minLength={1}
                                max={180}
                                maxLength={3}
                                isDisabled={!canEdit}
                            />
                        </div>
                        <div className="column is-3 is-12-mobile">
                            <RadioField
                                id="recurrenceUnit"
                                label="&nbsp;"
                                options={recurrenceOptions}
                                name={`recurrenceUnit-${eventFormik.values.outcomeId}`}
                                error={eventFormik.errors.recurrenceUnit}
                                value={eventFormik.values.recurrenceUnit}
                                handleChange={handleRecurrenceUnitChange}
                                handleBlur={eventFormik.handleBlur}
                                canEdit={!canEdit}
                            />
                        </div>
                    </div>
                </td>
            </tr>
        </>
    ) : (
        <tr>
            <td className="reset-cursor">
                <strong>Event</strong>
            </td>
            <td colSpan={6} className="reset-cursor">
                <Button
                    onClick={overwriteExistingEvents}
                    type="button"
                    className="button-overwrite-event"
                    disabled={!canEdit}
                >
                    {translate('Cerm.ProcessStepOutcomes.Labels.Overwrite')}
                </Button>
            </td>
        </tr>
    );
};
