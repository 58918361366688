import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from '../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { ShiftHandoverActions } from 'actions/actions-v2/shift-handover-actions';
import { IShiftHandover, IShiftHandoverSettings } from 'models/shifts/shift-handover';
import { IShiftChecklist } from 'models/shifts/master-shift-checklist';

const controllerName = 'ShiftHandover';
const apiPath = `/api/${controllerName}`;
const apiPathAdmin = `/api/ShiftCheckListVersion`;
const apiPathAdminCategory = `/api/ShiftHandoverCategory`;
const apiPathAdminOperatingPlatform = `/api/ShiftChecklistVersionAdmin`;


const getListActions = {
    action: ShiftHandoverActions.LOAD_LIST,
    actionFulfilled: ShiftHandoverActions.LOAD_LIST_FULFILLED,
    actionRejected: ShiftHandoverActions.LOAD_LIST_REJECTED,
} as IActionType;

const getCheckListVersionActions = {
    action: ShiftHandoverActions.LOAD_CHECKLIST_VERSION,
    actionFulfilled: ShiftHandoverActions.LOAD_CHECKLIST_VERSION_FULFILLED,
    actionRejected: ShiftHandoverActions.LOAD_CHECKLIST_VERSION_REJECTED,
} as IActionType;

const getCheckListAdminActions = {
    action: ShiftHandoverActions.LOAD_CHECKLIST_ADMIN,
    actionFulfilled: ShiftHandoverActions.LOAD_CHECKLIST_ADMIN_FULFILLED,
    actionRejected: ShiftHandoverActions.LOAD_CHECKLIST_ADMIN_REJECTED,
} as IActionType;

const getCheckListOperatingPlatformAdminActions = {
    action: ShiftHandoverActions.LOAD_OPERATING_PLATFORM_CHECKLIST_ADMIN,
    actionFulfilled: ShiftHandoverActions.LOAD_OPERATING_PLATFORM_CHECKLIST_ADMIN_FULFILLED,
    actionRejected: ShiftHandoverActions.LOAD_OPERATING_PLATFORM_CHECKLIST_ADMIN_REJECTED,
} as IActionType;

const createCheckListAdminActions = {
    action: ShiftHandoverActions.CREATE_CHECKLIST_ADMIN,
    actionFulfilled: ShiftHandoverActions.CREATE_CHECKLIST_ADMIN_FULFILLED,
    actionRejected: ShiftHandoverActions.CREATE_CHECKLIST_ADMIN_REJECTED,
} as IActionType;

const updateCheckListAdminActions = {
    action: ShiftHandoverActions.UPDATE_CHECKLIST_ADMIN,
    actionFulfilled: ShiftHandoverActions.UPDATE_CHECKLIST_ADMIN_FULFILLED,
    actionRejected: ShiftHandoverActions.UPDATE_CHECKLIST_ADMIN_REJECTED,
} as IActionType;

const deleteCheckListAdminActions = {
    action: ShiftHandoverActions.DELETE_CHECKLIST_ADMIN,
    actionFulfilled: ShiftHandoverActions.DELETE_CHECKLIST_ADMIN_FULFILLED,
    actionRejected: ShiftHandoverActions.DELETE_CHECKLIST_ADMIN_REJECTED,
} as IActionType;

const createCheckListOperatingPlatformAdminActions = {
    action: ShiftHandoverActions.CREATE_OPERATING_PLATFORM_CHECKLIST_ADMIN,
    actionFulfilled: ShiftHandoverActions.CREATE_OPERATING_PLATFORM_CHECKLIST_ADMIN_FULFILLED,
    actionRejected: ShiftHandoverActions.CREATE_OPERATING_PLATFORM_CHECKLIST_ADMIN_REJECTED,
} as IActionType;

const updateCheckListOperatingPlatformAdminActions = {
    action: ShiftHandoverActions.UPDATE_OPERATING_PLATFORM_CHECKLIST_ADMIN,
    actionFulfilled: ShiftHandoverActions.UPDATE_OPERATING_PLATFORM_CHECKLIST_ADMIN_FULFILLED,
    actionRejected: ShiftHandoverActions.UPDATE_OPERATING_PLATFORM_CHECKLIST_ADMIN_REJECTED,
} as IActionType;

const deleteCheckListOperatingPlatformAdminActions = {
    action: ShiftHandoverActions.DELETE_OPERATING_PLATFORM_CHECKLIST_ADMIN,
    actionFulfilled: ShiftHandoverActions.DELETE_OPERATING_PLATFORM_CHECKLIST_ADMIN_FULFILLED,
    actionRejected: ShiftHandoverActions.DELETE_OPERATING_PLATFORM_CHECKLIST_ADMIN_REJECTED,
} as IActionType;
const getActions = {
    action: ShiftHandoverActions.LOAD,
    actionFulfilled: ShiftHandoverActions.LOAD_FULFILLED,
    actionRejected: ShiftHandoverActions.LOAD_REJECTED,
} as IActionType;

const getLastSubmittedActions = {
    action: ShiftHandoverActions.LOAD_LAST_SUBMITTED,
    actionFulfilled: ShiftHandoverActions.LOAD_LAST_SUBMITTED_FULFILLED,
    actionRejected: ShiftHandoverActions.LOAD_LAST_SUBMITTED_REJECTED,
} as IActionType;

const createActions = {
    action: ShiftHandoverActions.CREATE,
    actionFulfilled: ShiftHandoverActions.CREATE_FULFILLED,
    actionRejected: ShiftHandoverActions.CREATE_REJECTED,
} as IActionType;

const updateActions = {
    action: ShiftHandoverActions.UPDATE,
    actionFulfilled: ShiftHandoverActions.UPDATE_FULFILLED,
    actionRejected: ShiftHandoverActions.UPDATE_REJECTED,
} as IActionType;

const deleteShiftHandover = {
    action: ShiftHandoverActions.DELETE_SHIFT_HANDOVER,
    actionFulfilled: ShiftHandoverActions.DELETE_SHIFT_HANDOVER_FULFILLED,
    actionRejected: ShiftHandoverActions.DELETE_SHIFT_HANDOVER_REJECTED,
} as IActionType;

const exportActions = {
    action: ShiftHandoverActions.EXPORT,
    actionFulfilled: ShiftHandoverActions.EXPORT_FULFILLED,
    actionRejected: ShiftHandoverActions.EXPORT_REJECTED,
} as IActionType;

const loadHistory = {
    action: ShiftHandoverActions.LOAD_HISTORY,
    actionFulfilled: ShiftHandoverActions.LOAD_HISTORY_FULFILLED,
    actionRejected: ShiftHandoverActions.LOAD_HISTORY_REJECTED,
} as IActionType;

const loadSettings = {
    action: ShiftHandoverActions.LOAD_SETTINGS,
    actionFulfilled: ShiftHandoverActions.LOAD_SETTINGS_FULFILLED,
    actionRejected: ShiftHandoverActions.LOAD_SETTINGS_REJECTED,
} as IActionType;

const acknowledgeShifthandover = {
    action: ShiftHandoverActions.ACKNOWLEDGE_SHIFT_HANDOVER,
    actionFulfilled: ShiftHandoverActions.ACKNOWLEDGE_FULFILLED,
    actionRejected: ShiftHandoverActions.ACKNOWLEDGE_REJECTED,
} as IActionType;

const loadShiftHandovercategory = {
    action: ShiftHandoverActions.LOAD_SHIFT_HANDOVER_CATEGORY,
    actionFulfilled: ShiftHandoverActions.LOAD_SHIFT_HANDOVER_CATEGORY_FULFILLED,
    actionRejected: ShiftHandoverActions.LOAD_SHIFT_HANDOVER_CATEGORY_REJECTED,
} as IActionType;

const loadShiftHandoverOperatingPlatformCategory = {
    action: ShiftHandoverActions.LOAD_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY,
    actionFulfilled: ShiftHandoverActions.LOAD_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY_FULFILLED,
    actionRejected: ShiftHandoverActions.LOAD_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY_REJECTED,
} as IActionType;

const createShiftHandovercategory = {
    action: ShiftHandoverActions.CREATE_SHIFT_HANDOVER_CATEGORY,
    actionFulfilled: ShiftHandoverActions.CREATE_SHIFT_HANDOVER_CATEGORY_FULFILLED,
    actionRejected: ShiftHandoverActions.CREATE_SHIFT_HANDOVER_CATEGORY_REJECTED,
} as IActionType;

const updateShiftHandovercategory = {
    action: ShiftHandoverActions.UPDATE_SHIFT_HANDOVER_CATEGORY,
    actionFulfilled: ShiftHandoverActions.UPDATE_SHIFT_HANDOVER_CATEGORY_FULFILLED,
    actionRejected: ShiftHandoverActions.UPDATE_SHIFT_HANDOVER_CATEGORY_REJECTED,
} as IActionType;

const createShiftHandoverOperatingPlatformcategory = {
    action: ShiftHandoverActions.CREATE_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY,
    actionFulfilled: ShiftHandoverActions.CREATE_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY_FULFILLED,
    actionRejected: ShiftHandoverActions.CREATE_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY_REJECTED,
} as IActionType;

const updateShiftHandoverOperatingPlatformcategory = {
    action: ShiftHandoverActions.UPDATE_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY,
    actionFulfilled: ShiftHandoverActions.UPDATE_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY_FULFILLED,
    actionRejected: ShiftHandoverActions.UPDATE_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY_REJECTED,
} as IActionType;

const deleteShiftHandovercategory = {
    action: ShiftHandoverActions.DELETE_SHIFT_HANDOVER_CATEGORY,
    actionFulfilled: ShiftHandoverActions.DELETE_SHIFT_HANDOVER_CATEGORY_FULFILLED,
    actionRejected: ShiftHandoverActions.DELETE_SHIFT_HANDOVER_CATEGORY_REJECTED,
} as IActionType;

const loadShiftHandoversEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getListActions).get<IShiftHandover[]>(`${apiPath}`);

const loadShiftChecklistVersionEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getCheckListVersionActions).getByIdAndShard<IShiftChecklist>(
        apiPathAdmin
    );

const loadShiftChecklistAdminEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getCheckListAdminActions).getById<IShiftChecklist>(apiPathAdmin);

const loadShiftHandOverOperatingPlatformChecklistAdminEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getCheckListOperatingPlatformAdminActions).getById<IShiftChecklist>(apiPathAdminOperatingPlatform);

const createShiftChecklistAdminEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createCheckListAdminActions).post<IShiftChecklist>(apiPathAdmin);

const updateShiftChecklistAdminEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateCheckListAdminActions).put<IShiftChecklist>(apiPathAdmin);

const deleteShiftChecklistAdminEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, deleteCheckListAdminActions).delete(apiPathAdmin);

const createShiftChecklistOperatingPlatformAdminEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createCheckListOperatingPlatformAdminActions).post<IShiftChecklist>(apiPathAdminOperatingPlatform);

const updateShiftChecklistOperatingPlatformAdminEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateCheckListOperatingPlatformAdminActions).put<IShiftChecklist>(apiPathAdminOperatingPlatform);

const deleteShiftChecklistOperatingPlatformAdminEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, deleteCheckListOperatingPlatformAdminActions).delete(apiPathAdminOperatingPlatform);

const loadShiftHandoverEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getActions).getByIdAndShard<IShiftHandover>(apiPath);

const loadLastSubmittedShiftHandoverEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getLastSubmittedActions).getByIdAndShard<IShiftHandover>(
        `${apiPath}/LastSubmitted`
    );

const createShiftHandoverEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createActions, '/ShiftHandovers').post<IShiftHandover>(apiPath);

const updateShiftHandoverEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateActions, '/ShiftHandovers').put<IShiftHandover>(apiPath);

const deleteShiftHandoverEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(
        action$,
        state$,
        deleteShiftHandover,
        '/ShiftHandovers'
    ).deleteByIdAndShard<IShiftHandover>(`${apiPath}`);

const exportShiftHandoversEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, exportActions).exportByQueryModel(`${apiPath}/export`);

const loadHistoryEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, loadHistory).getById<IShiftHandover>(`${apiPath}/history`);

const loadSettingsEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, loadSettings).get<IShiftHandoverSettings>(`${apiPath}/settings`);
const acknowledgeShifthandoverEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, acknowledgeShifthandover).post<IShiftHandoverSettings>(
        `${apiPath}/Acknowledge`
    );

const loadShiftHandoverCategoryEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, loadShiftHandovercategory).getById<IShiftHandoverSettings>(
        `${apiPathAdminCategory}`
    );

const loadShiftHandoverOperatingPlatformCategoryEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, loadShiftHandoverOperatingPlatformCategory).getByOperatingPlatform<IShiftHandoverSettings>(
        `${apiPathAdminCategory}/operating-platform`
    );

const createShiftHandoverCategoryEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createShiftHandovercategory).post<IShiftHandoverSettings>(
        `${apiPathAdminCategory}`
    );

const updateShiftHandoverCategoryEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateShiftHandovercategory).put<IShiftHandoverSettings>(
        `${apiPathAdminCategory}`
    );

const createShiftHandoverOperatingPlatformCategoryEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createShiftHandoverOperatingPlatformcategory).post<IShiftHandoverSettings>(
        `${apiPathAdminCategory}/operating-platform`
    );

const updateShiftHandoverOperatingPlatformCategoryEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateShiftHandoverOperatingPlatformcategory).put<IShiftHandoverSettings>(
        `${apiPathAdminCategory}/operating-platform`
    );

const deleteShiftHandoverCategoryEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, deleteShiftHandovercategory).delete<IShiftHandoverSettings>(
        `${apiPathAdminCategory}`
    );

export const shiftHandoverEpicsV2 = combineEpics(
    loadShiftHandoversEpic,
    loadShiftHandoverEpic,
    loadLastSubmittedShiftHandoverEpic,
    createShiftHandoverEpic,
    updateShiftHandoverEpic,
    deleteShiftHandoverEpic,
    exportShiftHandoversEpic,
    loadSettingsEpic,
    loadHistoryEpic,
    loadShiftChecklistVersionEpic,
    loadShiftChecklistAdminEpic,
    loadShiftHandOverOperatingPlatformChecklistAdminEpic,
    createShiftChecklistAdminEpic,
    updateShiftChecklistAdminEpic,
    deleteShiftChecklistAdminEpic,
    acknowledgeShifthandoverEpic,
    loadShiftHandoverCategoryEpic,
    createShiftHandoverCategoryEpic,
    updateShiftHandoverCategoryEpic,
    deleteShiftHandoverCategoryEpic,
    loadShiftHandoverOperatingPlatformCategoryEpic,
    createShiftHandoverOperatingPlatformCategoryEpic,
    updateShiftHandoverOperatingPlatformCategoryEpic,
    createShiftChecklistOperatingPlatformAdminEpic,
    updateShiftChecklistOperatingPlatformAdminEpic,
    deleteShiftChecklistOperatingPlatformAdminEpic,
);
