
import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';

const getIsUploading = (state: IRootState) => state.riskRegisterState.isLoading;
const getHistory = (state: IRootState) => state.riskRegisterState.history;
const getRisk = (state: IRootState) => state.riskRegisterState.risk;

export default createSelector(
    getIsUploading,
    getHistory,
    getRisk,
    (isLoading, riskHistory, risk) => ({
        isLoading,
        riskHistory,
        risk
    })
);
