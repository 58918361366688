import React from 'react';

import { FormikProps } from 'formik';
import { isNil } from 'lodash';
import { RouteComponentProps } from 'react-router';
import { Control, Field, Help, Radio } from 'components/form';
import { BulmaColor } from 'enums/BulmaColor';
import { BulmaSize } from 'enums/BulmaSize';
import { getKeyed } from 'utils/form-utils';
import { ArticlePanel } from '../../../../../components/panel';
import { IProcessStep } from '../../../../../reducers/cermAssessments';
import { ICermAssessmentRouteProps } from '../../cerm-assessments-page';
import { yesNoOptions } from '../../common';
import { ExistingRiskForm } from './existing-risk-form';
import { RiskTemplateForm } from './risk-template-form';

export interface IProps extends RouteComponentProps<ICermAssessmentRouteProps> {
    formikBag: FormikProps<Partial<IProcessStep>>;
}

export interface IDispatchProps {
    onChangeRoot: (urlLocation: string) => void;
    onUploadClick: () => void;
}

export const ReviewAdditionalRisk: React.FC<IProps & IDispatchProps> = (props) => {

    const field = (name: Extract<keyof IProcessStep, string>) => `${name}`;

    function value<T extends Extract<keyof IProcessStep, string>>(name: T) {
        const val = getKeyed(props.formikBag, 'values', field(name));
        return !isNil(val) ? val : '';
    }

    const isTouched = (name: Extract<keyof IProcessStep, string>) =>
        getKeyed(props.formikBag, 'touched', field(name));

    const getErrors = (name: Extract<keyof IProcessStep, string>) =>
        getKeyed(props.formikBag, 'errors', field(name));

    const getHelpByKey = (name: Extract<keyof IProcessStep, string>) => (
        <Help
            isShown={props.formikBag.submitCount > 0 || isTouched(name)}
            bulmaColor={BulmaColor.Danger}
        >
            {getErrors(name)}
        </Help>
    );
    return (
        <ArticlePanel className="column">
            <header>
                <h1 className="title is-4  is-uppercase">Risk Details</h1>
                <small className="subtitle">
                    The risk of non-compliance with this process is described below.
                </small>
            </header>

            <Field
                isHorizontal={true}
                htmlFor={field('createNewRisk')}
                label="New or existing risk"
                labelSize={BulmaSize.Medium}
                className="is-vcentered"
            >
                <Field>
                    <Control>
                        One or more expected outcomes are not met. Actions will be attached to a risk. Choose:
                        <Radio
                            id={field('createNewRisk')}
                            name={field('createNewRisk')}
                            options={yesNoOptions}
                            value={value('createNewRisk')}
                            onBlur={props.formikBag.handleBlur}
                            onChange={props.formikBag.handleChange}
                        />
                    </Control>
                    {getHelpByKey('createNewRisk')}
                </Field>
            </Field>

            {value('createNewRisk') === 'true' &&
                <RiskTemplateForm
                    formikBag={props.formikBag}
                    onChangeRoot={props.onChangeRoot}
                />}

            {value('createNewRisk') === 'false' &&
                <ExistingRiskForm
                    formikBag={props.formikBag}
                    onChangeRoot={props.onChangeRoot}
                />}

        </ArticlePanel>
    );
};
