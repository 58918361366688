export type EmeraldListItemVariantType =
  | 'default'
  | 'checkbox'
  | 'radio'
  | 'switch'
  | 'avatar'
  | 'link';

export enum EmeraldListItemVariant {
  DEFAULT = 'default',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  SWITCH = 'switch',
  AVATAR = 'avatar',
  LINK = 'link',
}
