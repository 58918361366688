import { isEmpty } from 'lodash';
import parse from 'url-parse';

export const getDisplayString = (values: string[]): string =>
    isEmpty(values) ? '-' : values.join(', ');

export const getDisplayName = (value: string): string => (isEmpty(value) ? '-' : value);

export const encodeUri = (value: string): string => encodeURIComponent(value.replace(/%/gi, ''));

export const decodeUri = (value: string): string => decodeURIComponent(value);

export const getInternalRoute = (value: string): any => {
    const url = parse(value);
    if (url.origin === window.location.origin) {
        return url.pathname;
    }
};

export const isInternalRoute = (value: string): boolean => {
    const url = parse(value);
    return url.origin === window.location.origin;
};

export const getPathName = (value: string): string => {
    const url = parse(value);
    return url.pathname;
};

export const isSafe = (value: string): boolean => {
    const url = parse(value);
    if (url.protocol === 'http:' || url.protocol === 'https:') {
        return true;
    } else {
        return false;
    }
};
export const replaceCharacter = (str: string, replaceStr: string): string => {
    return str.replace(replaceStr, ' ');
};
