import React from 'react';
import Page from 'components/v2/page/Page';
import { Button } from 'components/v2/button/Button';
import { FormikProps, Formik } from 'formik';
import OpportunitySiteInfoFrom from './opportunity-site-info-form';
import { IKeyValue } from 'models/key-value';
import { OpportunityQuestionnaireContainer } from '../opportunity-questionnaire/opportunity-questionnaire-container';
import { SupportTeamContainer } from '../opportunity-support-Team/support-Team-container';
import { Loader } from 'components/loader';
import { IOpportunityQuestionnaire } from 'models/opportunity-question-set';
import useTranslate from 'translations/translation-utils';

let formikBag: FormikProps<Partial<IOpportunityQuestionnaire>>;
interface IProps {
    siteId: string;
    customerName?: string;
    siteName?: string;
    regions: Array<IKeyValue<string>>;
    division: string;
    businessUnit: string;
    customerTypes: Array<IKeyValue<string>>;
    isLoading: boolean;
    opportunityQuestionnaire: IOpportunityQuestionnaire;
    isSaved: boolean;
    isHideTitle?: boolean;
    loadSite: (id: string) => void;
    loadTeam: (region: string) => void;
    loadOpportunityQuestions: (siteId: string) => void;
    resetTeam: () => void;
    reset: () => void;
    create: (model: Partial<IOpportunityQuestionnaire>) => void;
    update: (model: Partial<IOpportunityQuestionnaire>) => void;
}
// tslint:disable-next-line: ban-comma-operator
const OpportunitySiteInfoPage: React.FC<IProps> = ({
    siteId,
    customerName,
    siteName,
    regions,
    customerTypes,
    isLoading,
    opportunityQuestionnaire,
    isSaved,
    division,
    businessUnit,
    isHideTitle,
    loadTeam,
    resetTeam,
    loadSite,
    create,
    update,
    loadOpportunityQuestions,
    reset,
}) => {
    const translate = useTranslate();
    const [opportunityQuestionnaireAns, setOpportunityQuestionnaireAns] = React.useState([]);
    const [isCompleted, setIsCompleted] = React.useState(false);
    const onSubmit = (value: Partial<IOpportunityQuestionnaire>) => {
        const model = {
            value,
            opportunityAnswers: opportunityQuestionnaireAns,
        };
        create(model);
    };

    React.useEffect(() => {
        resetTeam();
    }, []);

    React.useEffect(() => {
        if (isSaved) {
            loadOpportunityQuestions(siteId);
        }
    }, [isSaved]);

    React.useEffect(() => {
        if (!siteId) {
            return;
        }
        resetTeam();
        reset();
        loadSite(siteId);
        loadOpportunityQuestions(siteId);
    }, [siteId]);

    const setOpportunityQuestionnaire = (opportunityQuestionnaire) => {
        setOpportunityQuestionnaireAns(opportunityQuestionnaire);
        formikBag.setFieldValue('opportunityAnswers', opportunityQuestionnaire);
    };
    const onSave = async () => {
        formikBag.setFieldValue('siteId', siteId);
        await formikBag.setFieldValue('isCompleted', isCompleted);
        if (opportunityQuestionnaire.id) {
            update(formikBag.values);
        } else {
            create(formikBag.values);
        }
    };
    return (
        <Page title={isHideTitle ? '' : translate('SideNavBar.Labels.OpportunityQuestionnaire')} className="opportunity-site-info--upsert-page">
            <Loader loading={isLoading}>
                <Formik<Partial<IOpportunityQuestionnaire>>
                    initialValues={opportunityQuestionnaire}
                    onSubmit={onSubmit}
                    render={(formik) => {
                        formikBag = formik;
                        return (
                            <OpportunitySiteInfoFrom
                                formikBag={formikBag}
                                customerName={customerName}
                                siteName={siteName}
                                division={division}
                                businessUnit={businessUnit}
                                regions={regions}
                                loadTeam={loadTeam}
                                customerTypes={customerTypes}
                            />
                        );
                    }}
                    enableReinitialize={true}
                    isInitialValid={true}
                    validateOnBlur={true}
                    validateOnChange={false}
                />
                <SupportTeamContainer />
                <OpportunityQuestionnaireContainer
                    setOpportunityQuestionnaire={setOpportunityQuestionnaire}
                    opportunityAnswers={
                        opportunityQuestionnaire && opportunityQuestionnaire.opportunityAnswers
                    }
                    setIsCompleted={setIsCompleted}
                />
                <div className="column column-save">
                    <Button
                        id="save"
                        buttonType="save"
                        onClick={onSave}
                        isLoading={isLoading}
                        type="submit"
                    >{translate('Globals.Label.Save')}</Button>
                </div>
            </Loader>
        </Page>
    );
};
export default OpportunitySiteInfoPage;
