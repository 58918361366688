import { Action } from 'redux';

//import { RouterAction } from 'connected-react-router';
import { RouterActions } from "redux-first-history";


import { IManageRole } from '../models/manage-role';
import { IManageRoleState } from '../reducers/manageRoles';

import { AppNotificationActionTypes } from './app-notification-actions';

import { ICommunication } from 'models/communcation';
//import { IKeyValue } from 'models/key-value';
import { IPerson } from 'models/person';
import { IRolePriviledge } from 'models/role-priviledge-list'
// tslint:disable-next-line:max-line-length
import { IManageRolesGridState, IPrivilege, IRole, ISiteRelationship, RelationshipType } from 'reducers/manageRoles/manageRoles-grid';
import { ISiteProfile } from 'models/site-profile';

export enum ManageRoleActions {
    ROUTE_CHANGE = '@@router/LOCATION_CHANGE',

    SHOW_ROLE_MODAL = '@@manageRoles/SHOW_UPSERT_ROLE',
    // UPSERT_ROLE = '@@manageRoles/UPSERT_ROLE',
    // UPSERT_ROLE_REJECTED = '@@manageRoles/UPSERT_ROLE_REJECTED',
    // UPSERT_ROLE_CANCELLED = '@@manageRoles/UPSERT_ROLE_CANCELLED',
    // UPSERT_ROLE_FULFILLED = '@@manageRoles/UPSERT_ROLE_FULFILLED',

    ADD_REMOVE_PRIVILEGE = '@@manageRoles/ADD_REMOVE_PRIVILEGE',
    ADD_REMOVE_PRIVILEGE_REJECTED = '@@manageRoles/ADD_REMOVE_PRIVILEGE_REJECTED',
    ADD_REMOVE_PRIVILEGE_CANCELLED = '@@manageRoles/ADD_REMOVE_PRIVILEGE_CANCELLED',
    ADD_REMOVE_PRIVILEGE_FULFILLED = '@@manageRoles/ADD_REMOVE_PRIVILEGE_FULFILLED',

    DELETE_ROLE = '@@manageRoles/DELETE_ROLE',
    DELETE_ROLE_FULFILLED = '@@manageRoles/DELETE_ROLE_FULFILLED',
    DELETE_ROLE_REJECTED = '@@manageRoles/DELETE_ROLE_REJECTED',
    DELETE_ROLE_CANCELLED = '@@manageRoles/DELETE_ROLE_CANCELLED',

    EDIT_ROLE_PRIVILEGES = '@@manageRoles/EDIT_ROLE_PRIVILEGES',
    NAVIGATE_PEOPLE_TAB = '@@manageRoles/NAVIGATE_PEOPLE_TAB',
    NAVIGATE_ROLES_TAB = '@@manageRoles/NAVIGATE_ROLES_TAB',
    NAVIGATE_PRIVILEGES_TAB = '@@manageRoles/NAVIGATE_PRIVILEGES_TAB',
    // LOAD_ROLES_TAB_FULFILLED = '@@manageRoles/LOAD_ROLES_TAB_FULFILLED',
    // LOAD_ROLES_TAB = '@@manageRoles/LOAD_ROLES_TAB',
    // LOAD_ROLES_TAB_REJECTED = '@@manageRoles/LOAD_ROLES_TAB_REJECTED',
    // LOAD_ROLES_TAB_CANCELLED = '@@manageRoles/LOAD_ROLES_TAB_CANCELLED',
    LOAD_PRIVILEGES_TAB = '@@manageRoles/LOAD_PRIVILEGES_TAB',
    LOAD_PRIVILEGES_TAB_FULFILLED = '@@manageRoles/LOAD_PRIVILEGES_TAB_FULFILLED',
    LOAD_PRIVILEGES_TAB_REJECTED = '@@manageRoles/LOAD_PRIVILEGES_TAB_REJECTED',
    LOAD_PRIVILEGES_TAB_CANCELLED = '@@manageRoles/LOAD_PRIVILEGES_TAB_CANCELLED',

    LOAD_PERSON = '@@manageRoles/LOAD_PERSON',
    LOAD_PERSON_FULFILLED = '@@manageRoles/LOAD_PERSON_FULFILLED',
    LOAD_PERSON_REJECTED = '@@manageRoles/LOAD_PERSON_REJECTED',

    LOAD_MANAGE_ROLES = '@@manageRoles/LOAD_MANAGE_ROLES',
    LOAD_MANAGE_ROLES_FULFILLED = '@@manageRoles/LOAD_MANAGE_ROLES_FULFILLED',
    LOAD_MANAGE_ROLES_REJECTED = '@@manageRoles/LOAD_MANAGE_ROLES_REJECTED',
    LOAD_MANAGE_ROLES_CANCELLED = '@@manageRoles/LOAD_MANAGE_ROLES_CANCELLED',
    LOAD_MANAGE_ROLES_PERSON = '@@manageRoles/LOAD_MANAGE_ROLES_PERSON',
    LOAD_MANAGE_ROLES_PERSON_FULFILLED = '@@manageRoles/LOAD_MANAGE_ROLES_PERSON_FULFILLED',
    LOAD_MANAGE_ROLES_PERSON_REJECTED = '@@manageRoles/LOAD_MANAGE_ROLES_PERSON_REJECTED',
    LOAD_MANAGE_ROLES_PERSON_CANCELLED = '@@manageRoles/LOAD_MANAGE_ROLES_PERSON_CANCELLED',
    CHANGE_ROLES = '@@manageRoles/CHANGE_ROLES',
    CHANGE_ROLES_FULFILLED = '@@manageRoles/CHANGE_ROLES_FULFILLED',
    CHANGE_ROLES_REJECTED = '@@manageRoles/CHANGE_ROLES_REJECTED',
    TOGGLE_ITEM_EXPANDED = '@@manageRoles/TOGGLE_ITEM_EXPANDED',
    TOGGLE_FILTER_EXPANDED = '@@manageRoles/TOGGLE_FILTER_EXPANDED',
    ADD_FILTER_KEYWORD = '@@manageRoles/ADD_FILTER_KEYWORD',
    REMOVE_FILTER_KEYWORD = '@@manageRoles/REMOVE_FILTER_KEYWORD',
    CLEAR_FILTERS = '@@manageRoles/CLEAR_FILTERS',
    CHANGE_SORT_ORDER_VALUE = '@@manageRoles/CHANGE_SORT_ORDER_VALUE',
    CHANGE_SORT_ORDER_VALUE_PRIVILEGES_TAB = '@@manageRoles/CHANGE_SORT_ORDER_VALUE_PRIVILEGES_TAB',
    CHANGE_SORT_ORDER_VALUE_ROLES_TAB = '@@manageRoles/CHANGE_SORT_ORDER_VALUE_ROLES_TAB',
    CHANGE_FILTER_VALUE = '@@manageRoles/CHANGE_FILTER_VALUE',
    SHOW_ACTIONS = '@@manageRoles/SHOW_ACTIONS',
    HIDE_ACTIONS = '@@manageRoles/HIDE_ACTIONS',
    TOGGLE_SITES_FIELD_VALUE = '@@manageRoles/TOGGLE_SITES_FIELD_VALUE',
    TOGGLE_REGION_SELECTED = '@@manageRoles/TOGGLE_REGION_SELECTED',
    TOGGLE_LOB_SELECTED = '@@manageRoles/TOGGLE_LOB_SELECTED',
    TOGGLE_RELATIONSHIP = '@@manageRoles/TOGGLE_RELATIONSHIP',
    TOGGLE_ALL_CLIENT_RELATIONSHIP = '@@manageRoles/TOGGLE_ALL_CLIENT_RELATIONSHIP',
    TOGGLE_ALL_RELATIONSHIPS = '@@manageRoles/TOGGLE_ALL_RELATIONSHIPS',
    TOGGLE_EXPAND_CLIENT = '@@manageRoles/TOGGLE_EXPAND_CLIENT',
    SAVE_RELATIONSHIPS = '@@manageRoles/SAVE_RELATIONSHIPS',
    SAVE_RELATIONSHIPS_FULFILLED = '@@manageRoles/SAVE_RELATIONSHIPS_FULFILLED',
    SAVE_RELATIONSHIPS_REJECTED = '@@manageRoles/SAVE_RELATIONSHIPS_REJECTED',
    SAVE_RELATIONSHIPS_CANCELLED = '@@manageRoles/SAVE_RELATIONSHIPS_CANCELLED',

    LOAD_PAGE = '@@manageRoles/LOAD_PAGE',
    LOAD_PAGE_FULFILLED = '@@manageRoles/LOAD_PAGE_FULFILLED',
    LOAD_PAGE_REJECTED = '@@manageRoles/LOAD_PAGE_REJECTED',
    LOAD_PAGE_CANCELLED = '@@manageRoles/LOAD_PAGE_CANCELLED',
    SEND_EMAIL = '@@manageRoles/SEND_EMAIL',
    SEND_EMAIL_REJECTED = '@@manageRoles/SEND_EMAIL_REJECTED',
    SEND_EMAIL_FULLFILED = '@@manageRoles/SEND_EMAIL_FULLFILED',
    LOAD_SITES = '@@manageRoles/LOAD_SITES',
    LOAD_SITES_REJECTED = '@@manageRoles/LOAD_SITES_REJECTED',
    LOAD_SITES_FULFILLED = '@@manageRoles/LOAD_SITES_FULFILLED',
}

export interface ILoadPageAction extends Action {
    type: ManageRoleActions.LOAD_PAGE;
}

export interface ILoadPageRejectedAction extends Action {
    type: ManageRoleActions.LOAD_PAGE_REJECTED;
    error: true;
    payload: any;
}

export const onLoadSitesFeatures = (): ILoadSitesFeatures => ({
    type: ManageRoleActions.LOAD_SITES,
});

export interface ILoadSitesFeatures extends Action {
    type: ManageRoleActions.LOAD_SITES;
}

export interface ILoadSitesFulfilled extends Action {
    type: ManageRoleActions.LOAD_SITES_FULFILLED;
    payload: {
        sites: ISiteProfile[]
    };
}

export const onLoadSitesFeaturesFulfilled = (sites: ISiteProfile[]): ILoadSitesFulfilled => ({
    type: ManageRoleActions.LOAD_SITES_FULFILLED,
    payload: {
        sites
    }
});

export interface ILoadPageCancelledAction extends Action {
    type: ManageRoleActions.LOAD_PAGE_CANCELLED;
}

export interface ILoadPageFulfilledAction extends Action {
    type: ManageRoleActions.LOAD_PAGE_FULFILLED;
    payload: IManageRolesGridState;
}

export interface IShowRoleModal extends Action {
    type: ManageRoleActions.SHOW_ROLE_MODAL;
    payload: {
        role?: IRole;
    };
}

export const onShowRoleModal = (role?: IRole): IShowRoleModal => ({
    type: ManageRoleActions.SHOW_ROLE_MODAL,
    payload: {
        role
    }
});

export interface ISendEmail extends Action {
    type: ManageRoleActions.SEND_EMAIL;
    payload: {
        communication?: ICommunication;
    };
}

// export interface IUpsertRoleFulfilled extends Action {
//     type: ManageRoleActions.UPSERT_ROLE_FULFILLED;
//     payload: {
//         role: IRole;
//     };
// }

// export const onShowUpsertRoleFulfilled = (role?: IRole): IUpsertRoleFulfilled => ({
//     type: ManageRoleActions.UPSERT_ROLE_FULFILLED,
//     payload: {
//         role
//     }
// });

// export interface IUpsertRole extends Action {
//     type: ManageRoleActions.UPSERT_ROLE;
//     payload: {
//         role: IRole;
//     };
// }

// export const onUpsertRole = (role: IRole): IUpsertRole => ({
//     type: ManageRoleActions.UPSERT_ROLE,
//     payload: {
//         role
//     }
// });

export interface ILoadPerson extends Action {
    type: ManageRoleActions.LOAD_PERSON;
    payload: {
        personId: string;
    };
}

export interface ILoadPersonFulfilled extends Action {
    type: ManageRoleActions.LOAD_PERSON_FULFILLED;
    payload: {
        person: IPerson;
    };
}

export const onLoadPersonFulfilled = (person?: IPerson): ILoadPersonFulfilled => ({
    type: ManageRoleActions.LOAD_PERSON_FULFILLED,
    payload: {
        person
    }
});

export interface IAddRemovePrivilege extends Action {
    type: ManageRoleActions.ADD_REMOVE_PRIVILEGE;
    payload: {
        selectedValues: IRolePriviledge[]
    };
}

export const onAddRemovePrivilege = (selectedValues: IRolePriviledge[]): IAddRemovePrivilege => ({
    type: ManageRoleActions.ADD_REMOVE_PRIVILEGE,
    payload: {
        selectedValues
    }
});

export interface IAddRemovePrivilegeFulfilled extends Action {
    type: ManageRoleActions.ADD_REMOVE_PRIVILEGE_FULFILLED;
    payload: {
        selectedValues: IRolePriviledge[]
    };
}

export const onAddRemovePrivilegeFulfilled = (selectedValues: IRolePriviledge[]): IAddRemovePrivilegeFulfilled => ({
    type: ManageRoleActions.ADD_REMOVE_PRIVILEGE_FULFILLED,
    payload: {
        selectedValues
    }
});

export interface IDeleteRole extends Action {
    type: ManageRoleActions.DELETE_ROLE;
    payload: {
        roleId: string;
    };
}

export const onDeleteRole = (roleId: string): IDeleteRole => ({
    type: ManageRoleActions.DELETE_ROLE,
    payload: {
        roleId,
    }
});

export interface IDeleteRoleFulfilled extends Action {
    type: ManageRoleActions.DELETE_ROLE_FULFILLED;
    payload: {
        roleId: string;
    };
}

export interface IEditRolePrivileges extends Action {
    type: ManageRoleActions.EDIT_ROLE_PRIVILEGES;
    roleId: string;
}

export const onEditRolePrivileges = (roleId: string): IEditRolePrivileges => ({
    type: ManageRoleActions.EDIT_ROLE_PRIVILEGES,
    roleId
});

export interface INavigatePeopleTab extends Action {
    type: ManageRoleActions.NAVIGATE_PEOPLE_TAB;
}

export const onNavigatePeopleTab = (): INavigatePeopleTab => ({
    type: ManageRoleActions.NAVIGATE_PEOPLE_TAB,
});

export interface INavigateRolesTab extends Action {
    type: ManageRoleActions.NAVIGATE_ROLES_TAB;
}

export const onNavigateRolesTab = (): INavigateRolesTab => ({
    type: ManageRoleActions.NAVIGATE_ROLES_TAB,
});

// export interface ILoadRolesTab extends Action {
//     type: ManageRoleActions.LOAD_ROLES_TAB;
// }

// export interface ILoadManageRolesTabFulfilledAction extends Action {
//     type: ManageRoleActions.LOAD_ROLES_TAB_FULFILLED;
//     payload: {
//         rolesState: IRole[];
//     };
// }

export interface INavigatePrivilegesTab extends Action {
    type: ManageRoleActions.NAVIGATE_PRIVILEGES_TAB;
}

export const onNavigatePrivilegesTab = (): INavigatePrivilegesTab => ({
    type: ManageRoleActions.NAVIGATE_PRIVILEGES_TAB,
});

export const loadPrivileges = () => ({
    type: ManageRoleActions.LOAD_PRIVILEGES_TAB
});

export interface ILoadPrivilegesTab extends Action {
    type: ManageRoleActions.LOAD_PRIVILEGES_TAB;
}

export interface ILoadManagePrivilegesTabFulfilledAction extends Action {
    type: ManageRoleActions.LOAD_PRIVILEGES_TAB_FULFILLED;
    payload: {
        privilegesState: IPrivilege[];
    };
}

export interface IAddFilterKeyword extends Action {
    type: ManageRoleActions.ADD_FILTER_KEYWORD;
    payload: {
        keyword: string;
    };
}

export interface IRemoveFilterKeyword extends Action {
    type: ManageRoleActions.REMOVE_FILTER_KEYWORD;
    payload: {
        keyword: string;
    };
}

export interface IClearFilters extends Action {
    type: ManageRoleActions.CLEAR_FILTERS;
}

export interface ILoadManageRolesAction extends Action {
    type: ManageRoleActions.LOAD_MANAGE_ROLES;
}

export interface IToggleFilterExpanded extends Action {
    type: ManageRoleActions.TOGGLE_FILTER_EXPANDED;
}

export interface ILoadManageRolesRejectedAction extends Action {
    type: ManageRoleActions.LOAD_MANAGE_ROLES_REJECTED;
    error: true;
    payload: any;
}

export interface ILoadManageRolesCancelledAction extends Action {
    type: ManageRoleActions.LOAD_MANAGE_ROLES_CANCELLED;
}

export interface ILoadManageRolesFulfilledAction extends Action {
    type: ManageRoleActions.LOAD_MANAGE_ROLES_FULFILLED;
    payload: {
        manageRoles: IManageRole[];
    };
}

export interface ILoadManageRolesPersonAction extends Action {
    type: ManageRoleActions.LOAD_MANAGE_ROLES_PERSON;
    payload: {
        personId: string;
    };
}

export interface ILoadManageRolesPersonRejectedAction extends Action {
    type: ManageRoleActions.LOAD_MANAGE_ROLES_PERSON_REJECTED;
    error: true;
    payload: any;
}

export interface ILoadManageRolesPersonCancelledAction extends Action {
    type: ManageRoleActions.LOAD_MANAGE_ROLES_PERSON_CANCELLED;
}

export interface ILoadManageRolesPersonFulfilledAction extends Action {
    type: ManageRoleActions.LOAD_MANAGE_ROLES_PERSON_FULFILLED;
    payload: {
        personId: string;
        relationships: ISiteRelationship[];
    };
}

export interface ISetSortOrderAction extends Action {
    type: ManageRoleActions.CHANGE_SORT_ORDER_VALUE;
    payload: {
        sortColumn?: string;
        sortAscending?: boolean;
    };
}

export interface ISetPrivilegesTabSortOrderAction extends Action {
    type: ManageRoleActions.CHANGE_SORT_ORDER_VALUE_PRIVILEGES_TAB;
    payload: {
        sortColumn?: string;
        sortAscending?: boolean;
    };
}

export interface ISetRolesTabSortOrderAction extends Action {
    type: ManageRoleActions.CHANGE_SORT_ORDER_VALUE_ROLES_TAB;
    payload: {
        sortColumn?: string;
        sortAscending?: boolean;
    };
}

export interface ISetFilterValueAction extends Action {
    type: ManageRoleActions.CHANGE_FILTER_VALUE;
    payload: {
        field: string;
        value: string;
    };
}

export interface IToggleItemExpanded extends Action {
    type: ManageRoleActions.TOGGLE_ITEM_EXPANDED;
    payload: {
        personId: string;
    };
}

export interface IHideActions extends Action {
    type: ManageRoleActions.HIDE_ACTIONS;
    payload: {
        personId: string;
    };
}

export interface IShowActions extends Action {
    type: ManageRoleActions.SHOW_ACTIONS;
    payload: {
        personId: string;
    };
}

export interface IChangeRoleAction extends Action {
    type: ManageRoleActions.CHANGE_ROLES;
    payload: {
        value: any,
        action: any;
    };
}

export interface IChangeRoleFulfilledAction extends Action {
    type: ManageRoleActions.CHANGE_ROLES_FULFILLED;
    payload: {
        value: IMultiSelectManageRoleActionModel
    };
}

export interface IToggleFilterExpanded extends Action {
    type: ManageRoleActions.TOGGLE_FILTER_EXPANDED;
    payload: {
        personId: Extract<keyof IManageRoleState, string>;
    };
}

export interface IToggleSitesFieldValue extends Action {
    type: ManageRoleActions.TOGGLE_SITES_FIELD_VALUE;
    payload: {
        personId: string;
        field: Extract<keyof IManageRole, string>;
        value: string;
    };
}

export interface IToggleRegionSelectedAction extends Action {
    type: ManageRoleActions.TOGGLE_REGION_SELECTED;
    payload: {
        personId: string;
        value: string;
    };
}

export interface IToggleLobSelectedAction extends Action {
    type: ManageRoleActions.TOGGLE_LOB_SELECTED;
    payload: {
        personId: string;
        value: string;
    };
}

export interface IToggleAllClientRelationshipSelectedAction extends Action {
    type: ManageRoleActions.TOGGLE_ALL_CLIENT_RELATIONSHIP;
    payload: {
        personId: string;
        relationshipType: RelationshipType;
        siteIds: string[];
        allChecked: boolean;
    };
}

export interface IToggleRelationshipSelectedAction extends Action {
    type: ManageRoleActions.TOGGLE_RELATIONSHIP;
    payload: {
        personId: string;
        relationshipType: RelationshipType;
        siteId: string;
    };
}

export interface ISaveRelationshipsActionRejectedAction extends Action {
    type: ManageRoleActions.SAVE_RELATIONSHIPS_REJECTED;
    error: true;
    payload: any;
}

export interface ISaveRelationshipsActionCancelledAction extends Action {
    type: ManageRoleActions.SAVE_RELATIONSHIPS_CANCELLED;
}

export interface ISaveRelationshipsActionFulfilledAction extends Action {
    type: ManageRoleActions.SAVE_RELATIONSHIPS_FULFILLED;
    payload: {
        personId: string;
        relationships: ISiteRelationship[];
    };
}

export const toggleRegionsCheckedFilter = (personId: string, value: string): IToggleRegionSelectedAction => ({
    type: ManageRoleActions.TOGGLE_REGION_SELECTED,
    payload: {
        personId,
        value
    }
});

export const toggleLobCheckedFilter = (personId: string, value: string): IToggleLobSelectedAction => ({
    type: ManageRoleActions.TOGGLE_LOB_SELECTED,
    payload: {
        personId,
        value
    }
});

export const onToggleAllClientRelationshipCheckedFilter =
    (personId: string, relationshipType: RelationshipType, siteIds: string[], allChecked: boolean):
        IToggleAllClientRelationshipSelectedAction => ({
            type: ManageRoleActions.TOGGLE_ALL_CLIENT_RELATIONSHIP,
            payload: {
                personId,
                relationshipType,
                siteIds,
                allChecked
            }
        });

export const toggleRelationshipCheckedFilter =
    (personId: string, relationshipType: RelationshipType, siteId: string): IToggleRelationshipSelectedAction => ({
        type: ManageRoleActions.TOGGLE_RELATIONSHIP,
        payload: {
            personId,
            relationshipType,
            siteId
        }
    });

export const onToggleExpandClient =
    (personId: string, clientName: string): IToggleExpandClientAction => ({
        type: ManageRoleActions.TOGGLE_EXPAND_CLIENT,
        payload: {
            personId,
            clientName
        }
    });

export interface IToggleExpandClientAction extends Action {
    type: ManageRoleActions.TOGGLE_EXPAND_CLIENT;
    payload: {
        personId: string;
        clientName: string;
    };
}

export interface IToggleAllRelationshipsAction extends Action {
    type: ManageRoleActions.TOGGLE_ALL_RELATIONSHIPS;
    payload: {
        personId: string;
        relationshipType: string;
        siteIds: string[];
        allChecked: boolean;
    };
}

export const onToggleSelectAll =
    (personId: string, relationshipType: string, siteIds: string[], allChecked: boolean):
        IToggleAllRelationshipsAction => ({
            type: ManageRoleActions.TOGGLE_ALL_RELATIONSHIPS,
            payload: {
                personId,
                relationshipType,
                allChecked,
                siteIds,
            }
        });

export interface ISaveRelationshipsAction extends Action {
    type: ManageRoleActions.SAVE_RELATIONSHIPS;
    payload: {
        personId: string;
    };
}

export const saveRelationships = (personId: string): ISaveRelationshipsAction => ({
    type: ManageRoleActions.SAVE_RELATIONSHIPS,
    payload: {
        personId
    }
});

export interface ISelectValue {
    value: string;
    label: string;
    parentId: string;
}

export interface ISelectOption {
    value: string;
    label: string;
    parentId: string;
}

export interface ISelectAction {
    action: string;
    removedValue?: ISelectOption;
    option?: ISelectOption;
}

export interface IMultiSelectManageRoleActionModel {
    value: ISelectValue[];
    action: ISelectAction;
    parentId: string;
}

export type ManageRoleActionTypes =
    IToggleFilterExpanded
    | ILoadPersonFulfilled
    | ILoadPerson
    | ISetPrivilegesTabSortOrderAction
    | ISetRolesTabSortOrderAction
    | AppNotificationActionTypes
    | IDeleteRole
    | IDeleteRoleFulfilled
    // | IUpsertRoleFulfilled
    | IShowRoleModal
    // | IUpsertRole
    | IAddRemovePrivilegeFulfilled
    | IEditRolePrivileges
    | IAddRemovePrivilege
    | INavigatePeopleTab
    | INavigateRolesTab
    // | ILoadRolesTab
    // | ILoadManageRolesTabFulfilledAction
    | INavigatePrivilegesTab
    | ILoadPrivilegesTab
    | ILoadManagePrivilegesTabFulfilledAction
    | IRemoveFilterKeyword
    | RouterActions
    | IAddFilterKeyword
    | IClearFilters
    | IToggleFilterExpanded
    | IChangeRoleFulfilledAction
    | IChangeRoleAction
    | ILoadManageRolesAction
    | ILoadManageRolesCancelledAction
    | ILoadManageRolesFulfilledAction
    | ILoadManageRolesRejectedAction
    | ILoadManageRolesPersonAction
    | ILoadManageRolesPersonCancelledAction
    | ILoadManageRolesPersonFulfilledAction
    | ILoadManageRolesPersonRejectedAction
    | ISetFilterValueAction
    | ISetSortOrderAction
    | IToggleItemExpanded
    | IHideActions
    | IShowActions
    | IToggleSitesFieldValue
    | IToggleRegionSelectedAction
    | IToggleLobSelectedAction
    | IToggleRelationshipSelectedAction
    | IToggleAllClientRelationshipSelectedAction
    | IToggleExpandClientAction
    | ISaveRelationshipsAction
    | ISaveRelationshipsActionCancelledAction
    | ISaveRelationshipsActionFulfilledAction
    | IToggleAllRelationshipsAction
    | ILoadPageAction
    | ILoadPageCancelledAction
    | ILoadPageRejectedAction
    | ILoadPageFulfilledAction
    | ISendEmail
    | ISaveRelationshipsActionRejectedAction
    | ILoadSitesFeatures
    | ILoadSitesFulfilled;
