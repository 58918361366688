import { onRouteChange } from 'actions/app-actions';
import { UpsertSiteActions, UpsertSiteActionTypes } from '../../../actions/upsert-site-actions';
import { createSite, updateSite, updateSiteNoNav, loadSite } from 'actions/actions-v2/site-actions-v2';
import { loadClients } from 'actions/actions-v2/client-action-v2';
import { loadFacility } from 'actions/actions-v2/facility-action-v2';

export default {
    onChangeRoot: onRouteChange,
    goToBuilding: (buildingIndex: number): UpsertSiteActionTypes => ({
        type: UpsertSiteActions.SET_SELECTED_BUILDING,
        payload: {
            selectedBuildingIndex: buildingIndex
        }
    }),
    onCreate: createSite,
    onUpdate: updateSite,
    onSubmitEnergyBill: updateSiteNoNav,
    onLoadSite: loadSite,
    loadClients,
    loadFacility,
};
