import React from 'react';
import { IToolbarIconItem, IconToolbar, ToolbarIconType } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';

interface IProps {
    isExporting;
    onExport: (includeMeta: boolean) => void;
    className?: string;
}

export const CermAssessmentComparisonToolbar: React.FC<IProps> = ({ isExporting, onExport, className }) => {
    const translate = useTranslate();
    const onExportClick = () => {
        onExport(false);
    };

    const onExportIncMetaClick = () => {
        onExport(true);
    };

    const getToolbarItems = (): IToolbarIconItem[] => {
        return [
            {
                id: 'export-button',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.Export'),
                type: ToolbarIconType.download,
                isDisabled: isExporting,
                children: [
                    {
                        id: 'export-comparisons-button',
                        title: translate('CermComparision.Title.1'),
                        type: ToolbarIconType.download,
                        isDisabled: isExporting,
                        onClick: onExportClick,
                    },
                    {
                        id: 'export-comparisons-inc-meta-button',
                        title: translate('CermComparision.Title.2'),
                        type: ToolbarIconType.download,
                        isDisabled: isExporting,
                        onClick: onExportIncMetaClick,
                    }
                ]
            }
        ];
    };

    return <IconToolbar items={getToolbarItems()} className={`${className}`} />;
};
