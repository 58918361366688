import * as React from 'react';
import { IToolbarIconItem, ToolbarIconType, IconToolbar } from '../v2/toolbar/IconToolbar';
import { history } from 'routes/App';
import { useTranslate } from 'translations/translation-utils';
import { checkPermission } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';
import { IKeyValue } from 'models';

export interface IIncidentsGridToolbarProps {
    siteId: string;
    permissions: IKeyValue<string>[];
    onClearFilters: () => void;
    onShowFilters: () => void;
    exportIncidents: (siteId?: string) => void;
    isExportingIncidents: boolean;
    isShowHelp: boolean;
    isFilterExists: boolean;
    onClickHelp: () => void;
}

export const IncidentsGridToolbar: React.FC<IIncidentsGridToolbarProps> = ({
    siteId,
    permissions,
    onClearFilters,
    onShowFilters,
    exportIncidents,
    isExportingIncidents,
    isShowHelp,
    isFilterExists,
    onClickHelp,
}) => {
    const translate = useTranslate();
    const onDownloadSiteIncidents = () => {
        exportIncidents(siteId);
    };

    const getToolbarItems = (): IToolbarIconItem[] => {
        return [
            checkPermission(Privilege.IncidentCreate,permissions) &&
            {
                id: 'create-new-incident-button',
                title: translate('IncidentPage.Title.NewIncident'),
                type: ToolbarIconType.add,
                onClick: () => {
                    history.push('/Incident/Details');
                },
            },
            {
                id: 'export-button',
                title: translate('IncidentPage.Title.Export'),
                type: ToolbarIconType.download,
                isDisabled: isExportingIncidents,
                children: [
                    {
                        id: 'export-incidents-button',
                        title: translate('IncidentPage.Title.ExportSiteIncidents'),
                        type: ToolbarIconType.excel,
                        isDisabled: isExportingIncidents,
                        onClick: onDownloadSiteIncidents,
                    },
                    {
                        id: 'export-this-site-button',
                        title: translate('IncidentPage.Title.ExportAllIncidents'),
                        type: ToolbarIconType.excel,
                        isDisabled: isExportingIncidents,
                        onClick: exportIncidents,
                    },
                ],
            },
            {
                id: 'clear-all-button',
                title: translate('IncidentPage.Title.ClearFilters'),
                type: isFilterExists ? ToolbarIconType.filterApplied : ToolbarIconType.clearFilter,
                onClick: onClearFilters,
            },
            {
                id: 'show-filters-button',
                title: translate('IncidentPage.Title.ShowFilters'),
                type: ToolbarIconType.filter,
                onClick: onShowFilters,
            },
            isShowHelp && {
                id: 'show-help',
                title: translate('RiskRegisterPage.RiskRegisterToolbar.Title.Help'),
                type: ToolbarIconType.help,
                onClick: onClickHelp,
            },
        ];
    };

    return <IconToolbar items={getToolbarItems()} />;
};
