import * as React from 'react';
import { IDifference } from 'models/difference';
import { formatDateTime } from 'utils/date-utils';
import useTranslate from 'translations/translation-utils';
import './difference-item.scss';
import { DateTime, Format } from 'components/dateTime';

interface IProps {
    entityHistory: IDifference[][];
    entity?: any;
}

const EntityHistory: React.FC<IProps> = (props) => {
    const translate = useTranslate();

    const getChangedBy = (entityHistory: IDifference[]): string => {
        const isSystem = entityHistory?.findIndex(x=> x.propertyName=='IsSystemGenerated' && x.object2Value=="True")>-1
        const isClient = entityHistory?.findIndex(x=> x.propertyName=='IsClient' && x.object2Value=="True")>-1
        const isClientProxy = entityHistory?.findIndex(x=> x.propertyName=='IsClientProxy' && x.object2Value=="True")>-1

        let result = ""
        if (isSystem === true) {
            result = translate('RiskPage.History.Data.System')
        }
        else if (isClient === true) {
            result = translate('RiskPage.History.Data.Client')
        }
        else if (isClientProxy === true) {
            result = entityHistory[0].parentObject2.lastUpdatedByPersonName + ' ' +  translate('RiskPage.History.Data.Proxy')
        }
        else if (entityHistory[0]?.parentObject2?.lastUpdatedByPersonName) {
            result = entityHistory[0].parentObject2.lastUpdatedByPersonName
        }
        return result
    }

    const getChangedByPerson = (entity: any): string => {
        let result = ""
        if (entity?.isSystemGenerated === true) {
            result = translate('RiskPage.History.Data.System')
        }
        else if (entity?.isClient === true) {
            result = translate('RiskPage.History.Data.Client')
        }
        else if (entity?.isClientProxy === true) {
            result = entity.createdByPersonName + ' as '+  translate('RiskPage.History.Data.Proxy')
        }
        else if (entity?.createdByPersonName) {
            result = entity.createdByPersonName
        }
        return result
    }

    return (
        <div className="History">
            <div className="columns grid-headers underline">
                <div className="label column is-1">{translate('RiskPage.History.Header.DateTime')}</div>
                <div className="label column is-3">{translate('RiskPage.History.Header.PerformedBy')}</div>
                <div className="label column is-2">{translate('RiskPage.History.Header.WhatWasChanged')}</div>
                <div className="label column is-3">{translate('RiskPage.History.Header.OldValue')}</div>
                <div className="label column is-3">{translate('RiskPage.History.Header.NewValue')}</div>
            </div>
            {props.entityHistory &&
                props.entityHistory.map((entityHistory) => {
                    return entityHistory.map((diff, key) => {
                        return (
                            <div className={"columns" + (key == entityHistory.length - 1 ? " underline" : "")}>
                                <div className="column is-1">{key == 0 && formatDateTime(entityHistory[0].parentObject2.lastUpdatedDateTime)}</div>
                                <div className="column is-3">{key == 0 && getChangedBy(entityHistory)}</div>
                                <div className="column is-2" key={`field-changed-${key}`}>
                                    {diff.propertyName?.replace(/([A-Z])/g, ' $1').trim()}
                                </div>
                                <div className="column is-3" key={`new-field-${key}`}>
                                    {diff.object1Value}
                                </div>
                                <div className="column is-3" key={`old-field-${key}`}>
                                    {diff.object2Value}
                                </div>
                            </div>
                        )
                    })
                }
                )
            }
            {props.entityHistory?.length > 0 && props.entityHistory[0]?.length > 0 &&
                <div className="columns">
                    <div className="column is-1">{formatDateTime(props.entityHistory[props.entityHistory.length-1][0].parentObject2.createdDateTime)}</div>
                    <div className="column is-3">{getChangedByPerson(props.entityHistory[props.entityHistory.length-1][0].parentObject2)}</div>
                    <div className="column is-2">
                        Created
                    </div>
                    <div className="column is-3">N/A
                    </div>
                    <div className="column is-3">N/A
                    </div>
                </div>
            }
            {props.entityHistory?.length == 0 && props.entity.id &&
                <div className="columns">
                    <div className="column is-1">
                        <DateTime value={props.entity.createdDateTime} format={Format.DateAndTime} />
                    </div>
                    <div className="column is-3">{getChangedByPerson(props.entity)}</div>
                    <div className="column is-2">
                        Created
                    </div>
                    <div className="column is-3">N/A
                    </div>
                    <div className="column is-3">N/A
                    </div>
                </div>
            }            
        </div>
    )
}

export default EntityHistory
