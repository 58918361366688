import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';

const getPerson = createSelector(
    (state: any) => state.person,
    state => state.personProfile
);

const getAppSettings = (state: IRootState) => state.app.appSettings;

export default createSelector(
    getPerson,
    getAppSettings,
    (person, appSettings) => ({
        person,
        appSettings,
    })
);
