import React from 'react';

import { FormikProps } from 'formik';
import { RouteComponentProps } from 'react-router';
import { ArticlePanel } from '../../../../../components/panel';
import { IOutcome, IProcessStep, IPillar } from '../../../../../reducers/cermAssessments';
import { ICermAssessmentRouteProps } from '../../cerm-assessments-page';
import { OutComeReviewForm } from './review-outcome-form';
import { ISetOutcomeAction } from 'models/set-outcome-action';

export interface IProps extends RouteComponentProps<ICermAssessmentRouteProps> {
    formikBag: FormikProps<Partial<IProcessStep>>;
    pillars: IPillar[];
    siteId: string;
}

export interface IDispatchProps {
    onChangeRoot: (urlLocation: string) => void;
    onSaveAndNext: (outcome: IOutcome) => void;
    onSaveAndEnd: (outcome: IOutcome) => void;
    onUploadClick: () => void;
    onSetOutcome: (outcome: Partial<IOutcome>) => void;
    setOutcome: (model: ISetOutcomeAction) => void;
}

export class ReviewOutcome extends React.PureComponent<IProps & IDispatchProps> {
    public render() {
        const index = Number(this.props.match.params.outcomeId)
            ? Number(this.props.match.params.outcomeId)
            : 0;
        return (
            <ArticlePanel className="column">
                <header>
                    <h2 className="title is-4  is-uppercase">Outcome #{index + 1}</h2>
                    <small className="subtitle">
                        {this.props.formikBag.values.expectedOutcomeAnswers[index].title}
                    </small>
                </header>

                <OutComeReviewForm
                    onChangeRoot={this.props.onChangeRoot}
                    outcomeIndex={index}
                    formikBag={this.props.formikBag}
                    onSaveAndEnd={this.props.onSaveAndEnd}
                    onSaveAndNext={this.props.onSaveAndNext}
                    onUploadClick={this.props.onUploadClick}
                    pillars={this.props.pillars}
                    setOutcome={this.props.setOutcome}
                    onSetOutcome={this.props.onSetOutcome}
                    siteId={this.props.siteId}
                />
            </ArticlePanel>
        );
    }
}
