import { ICermGlobalOverviewState } from 'state/cerm-global-overview-state';
import { CermGlobalOverviewActions } from 'actions/actions-v2/cerm-global-overview-actions-v2';
import { IAppAction } from 'actions/app-action';
import { combineReducers } from 'redux';
import { s2ab } from 'utils/export-utils';
import saveAs from 'file-saver';

const INITIAL_STATE: ICermGlobalOverviewState = {
    isLoading: false,
    cermGlobalOverviewList: [],
    isExporting: false
};

const isLoading = (
    state: ICermGlobalOverviewState['isLoading'] = INITIAL_STATE.isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case CermGlobalOverviewActions.LOAD_CERM_GLOBAL_OVERVIEW_LIST:
            return true;

        case CermGlobalOverviewActions.LOAD_CERM_GLOBAL_OVERVIEW_LIST_FULFILLED:
        case CermGlobalOverviewActions.LOAD_CERM_GLOBAL_OVERVIEW_LIST_REJECTED:
            return false;

        default:
            return state;
    }
};

const cermGlobalOverviewList = (
    state: ICermGlobalOverviewState['cermGlobalOverviewList'] = INITIAL_STATE.cermGlobalOverviewList,
    action: IAppAction
) => {
    switch (action.type) {
        case CermGlobalOverviewActions.LOAD_CERM_GLOBAL_OVERVIEW_LIST_FULFILLED:
            if (action.payload) {
                return action.payload;
            }

        default:
            return state;
    }
};

const isExporting = (
    state: ICermGlobalOverviewState['isExporting'] = INITIAL_STATE.isExporting,
    action: IAppAction
) => {
    switch (action.type) {
        case CermGlobalOverviewActions.EXPORT_GLOBAL_CERM_OVERVIEW:
            return true;

        case CermGlobalOverviewActions.EXPORT_GLOBAL_CERM_OVERVIEW_FULFILLED:
            const blob = new Blob([s2ab(atob(action.payload.fileContents))], {
                type: 'application/excel'
            });
            saveAs(blob, action.payload.fileDownloadName);
            return false;

        case CermGlobalOverviewActions.EXPORT_GLOBAL_CERM_OVERVIEW_REJECTED:
            return false;

        default:
            return state;
    }
};

export const cermGlobalOverviewReducer = combineReducers<ICermGlobalOverviewState>({
    isLoading,
    cermGlobalOverviewList,
    isExporting
});
