import { RiskActions, RiskActionTypes } from '../../../../actions/risk-actions';
import { IRisksState } from '../../../../reducers/risks';

export default {
    onToggleFilterExpanded: (id: Extract<keyof IRisksState, string>): RiskActionTypes => ({
        type: RiskActions.TOGGLE_FILTER_EXPANDED,
        payload: {
            id,
        },
    }),
};
