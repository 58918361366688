import * as React from 'react';
import Select from 'react-select';
import { ISelectOption } from '../../models/select-option';
import './PopoutSelect.scss';
import { PopupSelectDropdown } from './PopoutSelectDropdown';
import { PopupSelectDropdownIndicator } from './PopoutSelectDropdownIndicator';

export interface IState {
    isOpen: boolean;
    value: ISelectOption;
}

export interface IStateProps extends React.AllHTMLAttributes<HTMLSelectElement> {
    options: ISelectOption[];
}

export interface IDispatchProps {
    onSelectChange: (id: string) => void;
}

export interface IProps extends IStateProps, IDispatchProps { isDisabled?: boolean; secondaryValue?: ISelectOption }

const selectStyles = {
    control: (provided: any) => ({ ...provided, margin: 8 }),
    menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' })
};

const dropdownComponents: any = {
    dropdownIndicator: PopupSelectDropdownIndicator,
    IndicatorSeparator: null
};

export class PopoutSelect extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = { isOpen: false, value: null };
        this.toggleOpen = this.toggleOpen.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
    }

    public render() {
        const theValue = this.props.secondaryValue ? this.props.secondaryValue : this.props.options?.find((option) => option.value === this.props.value);
        const { isOpen } = this.state;
        const { options, ...otherProps } = this.props;

        return (
            <div className="popout-select">
                <PopupSelectDropdown
                    isOpen={isOpen}
                    onClose={this.toggleOpen}
                    onOpen={this.toggleOpen}
                    selectedValue={theValue}
                    {...otherProps}
                    isDisabled={this.props.isDisabled}
                >
                    <Select
                        id={this.props.id}
                        inputId={this.props.id}
                        name={this.props.name}
                        maxMenuHeight={120}
                        autoFocus={true}
                        backspaceRemovesValue={false}
                        blurInputOnSelect={true}
                        components={dropdownComponents}
                        controlShouldRenderValue={true}
                        hideSelectedOptions={false}
                        isClearable={true}
                        menuIsOpen={isOpen}
                        onChange={this.onSelectChange}
                        onBlur={() => this.props.onBlur}
                        options={options}
                        placeholder="Search…"
                        styles={selectStyles}
                        tabSelectsValue={true}
                        value={theValue}
                        isDisabled={this.props.isDisabled}
                    />
                </PopupSelectDropdown>
            </div>
        );
    }

    private toggleOpen() {
        this.setState((state: IState) => ({ isOpen: !state.isOpen }));
    }

    private onSelectChange(value: ISelectOption) {
        this.toggleOpen();
        this.setState({ value });
        // this lib doesn't bring back the original event so have to manually set formik
        this.props.onSelectChange(value.value);
    }
}
