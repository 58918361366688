import { FormikProps } from 'formik';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Control, Field, Input, Help } from '../../../../components/form';
import { BulmaColor } from '../../../../enums/BulmaColor';
import { BulmaSize } from '../../../../enums/BulmaSize';
import { ICermProcessStep, ICermProcessStepPlatform } from '../../../../models/cerm-process-step';
import _ from 'lodash';
import { Button } from 'components/v2/components';
import { ICermOverviewPillar } from 'models/cerm-overview';
import { formikFieldCss, getKeyed, scrollToFirstValidationError } from 'utils/form-utils';
import appConfig from 'helpers/config-helper';
import { onRouteChange } from 'actions/app-actions';
import {
    RouteUrlOutcome,
    RouteUrlCermLinks,
} from 'routes/upsert-cerm-process-step/routes';
import useTranslate from 'translations/translation-utils';

interface IRouteParams {
    operatingPlatform?: string;
}

export interface IProps extends RouteComponentProps<IRouteParams> {
    cermItems?: ICermOverviewPillar[];
    processSteps?: Partial<ICermProcessStep>;
    formikBag?: FormikProps<Partial<ICermProcessStep>>;
    isLoading?: boolean;
    setErrorDialogVisible?: (isDialogVisible: boolean) => void;
}

export const CermTechnologyPlatform: React.FC<IProps> = ({ formikBag, match, setErrorDialogVisible }) => {
    const config = appConfig();
    const translate = useTranslate();

    const field = (name: Extract<keyof ICermProcessStepPlatform, string>) =>
        `cermProcessStepPlatform.${name}`;
    const getErrors = (name: Extract<keyof ICermProcessStepPlatform, string>) =>
        getKeyed(formikBag, 'errors', field(name));
    const isTouched = (name: Extract<keyof ICermProcessStepPlatform, string>) =>
        getKeyed(formikBag, 'touched', field(name));
    const getHelpByKey = (name: Extract<keyof ICermProcessStepPlatform, string>) => (
        <Help isShown={isTouched(name)} bulmaColor={BulmaColor.Danger}>
            {getErrors(name)}
        </Help>
    );
    const validateForm = (callback) => {
        formikBag.validateForm().then((errors) => {
            if (!Object.keys(errors).some((field) => field.length > 0)) {
                callback()
            } else {
                formikBag.submitForm();
                scrollToFirstValidationError();
                setErrorDialogVisible(true)
            }
        })
    }
    const validateWithoutField = (callback) => {
        formikBag.validateForm().then((errors) => {
            if (!Object.keys(errors).includes('cermProcessStepPlatform')) {
                callback();
            } else {
                formikBag.submitForm();
                scrollToFirstValidationError();
            }
        })
    }


    const onSaveAsDraft: React.MouseEventHandler<HTMLButtonElement> = async () => {
        validateForm(async () => {
            await formikBag.setFieldValue('status', 'Draft');
            formikBag.submitForm();
        })
    };

    const onPublish: React.MouseEventHandler<HTMLButtonElement> = async () => {
        validateForm(async () => {
            await formikBag.setFieldValue('status', 'Published');
            formikBag.submitForm();
        })
    };

    const onNaviagetToNext: React.MouseEventHandler<HTMLButtonElement> = () => {

        validateWithoutField(async () => {
            onRouteChange(RouteUrlOutcome(match.params.operatingPlatform, formikBag.values.id) + '0');
        })
    };

    const onNavigateBack: React.MouseEventHandler<HTMLButtonElement> = () => {
        validateWithoutField(async () => {
            onRouteChange(RouteUrlCermLinks(match.params.operatingPlatform, formikBag.values.id));
        })
    };

    return (
        <>
            <Field
                isHorizontal={true}
                htmlFor={field('technologyPlatform')}
                label={translate('UpsertCermProcessStep.Views.CenmTechPlatform.Label1')}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <textarea
                            id={field('technologyPlatform')}
                            name={field('technologyPlatform')}
                            aria-required="true"
                            placeholder={translate('UpsertCermProcessStep.Views.CenmTechPlatform.Label1')}
                            className="textarea"
                            value={
                                formikBag.values.cermProcessStepPlatform &&
                                formikBag.values.cermProcessStepPlatform.technologyPlatform
                            }
                            onChange={formikBag.handleChange}
                            onBlur={formikBag.handleBlur}
                        />
                    </Control>
                </Field>
            </Field>

            <Field
                isHorizontal={true}
                htmlFor={field('moreInfoDisplayLabel')}
                label={translate('UpsertCermProcessStep.Views.CenmTechPlatform.Label2')}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <Input
                            id={field('moreInfoDisplayLabel')}
                            name={field('moreInfoDisplayLabel')}
                            aria-required="true"
                            placeholder={translate('UpsertCermProcessStep.Views.CenmTechPlatform.Label3')}
                            type="text"
                            className={formikFieldCss(formikBag, 'title')}
                            value={
                                formikBag.values.cermProcessStepPlatform &&
                                formikBag.values.cermProcessStepPlatform.moreInfoDisplayLabel
                            }
                            onChange={formikBag.handleChange}
                            onBlur={(event) => formikBag.setFieldValue(event.target.name, event.target.value.trim())}
                        />
                    </Control>
                </Field>
            </Field>

            <Field
                isHorizontal={true}
                htmlFor={field('moreInfoLink')}
                label="&nbsp;" // space added to indent the field
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control>
                        <Input
                            id={field('moreInfoLink')}
                            name={field('moreInfoLink')}
                            aria-required="true"
                            placeholder="URL"
                            type="text"
                            className={formikFieldCss(formikBag, 'title')}
                            value={
                                formikBag.values.cermProcessStepPlatform &&
                                formikBag.values.cermProcessStepPlatform.moreInfoLink
                            }
                            onChange={formikBag.handleChange}
                            onBlur={(event) => formikBag.setFieldValue(event.target.name, event.target.value.trim())}
                        />
                    </Control>
                    {getHelpByKey('moreInfoLink')}
                    <p>
                        <br />
                        {translate('SendNotifications.Help.Message2')} : {config.REACT_APP_DOMAIN_WHITELIST}.
                    </p>
                </Field>
            </Field>


            <div className="action-groups">
                <div className="action-group">
                    <Button buttonType="cancel" onClick={onNavigateBack}>
                        {translate('Globals.Label.Back')}
                    </Button>
                </div>

                <div className="action-group">
                    <Button onClick={onSaveAsDraft}>
                        {translate('Globals.Label.SaveAsDraft')}
                    </Button>

                    {formikBag.values.expectedOutcomes.length > 0 && (
                        <Button onClick={onPublish}>
                            {translate('Globals.Label.Publish')}
                        </Button>
                    )}

                    <Button onClick={onNaviagetToNext}>
                        {translate('Globals.Label.Next')}
                    </Button>
                </div>
            </div>
        </>
    );
};
