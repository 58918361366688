import classnames from 'classnames';
import * as React from 'react';

import { ITypeaheadItem } from '.';
import './typeahead.scss';
import { TypeaheadResult } from './views/typeahead-result';

export interface IStateProps<T = any> {
    inputValue: string;
    values: Array<ITypeaheadItem<T>>;
    loading?: boolean;
    placeholder?: string;
}

export interface IDispatchProps<T = any> {
    onValueChanged: (value: string) => void;
    onSelectionChanged: (selection: ITypeaheadItem<T>) => void;
}

interface IState {
    isExpanded: boolean;
    selectedIndex: number | null;
}

interface IProps extends IStateProps, IDispatchProps { }

const CARRIAGE_RETURN = 13;
const ESCAPE = 27;
const UP_ARROW = 38;
const DOWN_ARROW = 40;

export class Typeahead extends React.Component<IProps, IState> {
    public static defaultProps: Partial<IProps> = {
        inputValue: ''
    };

    private resultsRef: React.RefObject<HTMLUListElement>;

    constructor(props: IProps) {
        super(props);

        this.resultsRef = React.createRef<HTMLUListElement>();

        this.onInputChange = this.onInputChange.bind(this);
        this.onInputFocus = this.onInputFocus.bind(this);
        this.onInputKeyPress = this.onInputKeyPress.bind(this);
        this.onBlur = this.onBlur.bind(this);

        this.state = {
            isExpanded: false,
            selectedIndex: null
        };
    }

    public componentWillReceiveProps(props: IProps) {
        if (this.props.values !== props.values) {
            this.setState((state) => ({
                ...state,
                selectedIndex: null
            }));
        }
    }

    public render() {
        const showAsExpanded = this.state.isExpanded && this.props.values.length > 0;

        const containerCss = classnames(
            'Typeahead',
            {
                ['Typeahead--expanded']: showAsExpanded
            }
        );

        const controlCss = classnames(
            'Typeahead__control',
            {
                ['is-loading']: this.props.loading
            }
        );

        return (
            <div className={containerCss} onBlur={this.onBlur}>
                <div className={controlCss}>
                    <input
                        className="Typeahead__input"
                        type="text"
                        placeholder={this.props.placeholder}
                        value={this.props.inputValue}

                        onChange={this.onInputChange}
                        onKeyDown={this.onInputKeyPress}

                        onFocus={this.onInputFocus}
                        onClick={this.onInputFocus}
                    />
                </div>
                {showAsExpanded && (
                    <div className="Typeahead__results-container">
                        <ul className="Typeahead__results" ref={this.resultsRef}>
                            {this.props.values.map((x, index) => (
                                <TypeaheadResult
                                    key={index}
                                    text={x.text}
                                    selected={index === this.state.selectedIndex}
                                    onClick={this.onConfirmSelection.bind(this, index)}
                                />
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        );
    }

    private onInputFocus() {
        this.setState((state) => ({
            ...state,
            isExpanded: true
        }));
    }

    private onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        const newValue = e.target.value;

        this.setState((state) => ({
            ...state,
            isExpanded: true
        }));

        this.props.onValueChanged(newValue);
    }

    private onInputKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
        switch (e.keyCode) {
            case UP_ARROW:
            case DOWN_ARROW:
                if (this.state.isExpanded) {
                    this.onSelectionChange(
                        e.keyCode === UP_ARROW
                            ? -1
                            : 1
                    );
                } else {
                    // If not expanded, just show results
                    this.setState((state) => ({
                        ...state,
                        isExpanded: true
                    }));
                }

                e.preventDefault();
                break;

            case ESCAPE:
                this.setState((state) => ({
                    ...state,
                    selectedIndex: null,
                    isExpanded: false
                }));
                break;

            case CARRIAGE_RETURN:
                this.onConfirmSelection(this.state.selectedIndex);
                break;

            default:
                return;
        }
    }

    private onSelectionChange(increment: number) {
        this.setState((state) => {
            let newSelectedIndex: number;

            if (state.selectedIndex === null) {
                newSelectedIndex = increment > 0
                    ? 0
                    : this.props.values.length - 1;
            } else {
                newSelectedIndex = (
                    this.props.values.length +
                    state.selectedIndex +
                    increment
                ) % this.props.values.length;
            }

            this.onSelectedIndexChanged(newSelectedIndex);

            return {
                ...state,
                selectedIndex: newSelectedIndex,
                isExpanded: true
            };
        });
    }

    private onConfirmSelection(selectedIndex: number) {
        const selectedItem = this.props.values[selectedIndex];

        this.setState((state) => ({
            ...state,
            isExpanded: false
        }));

        if (selectedItem) {
            this.props.onSelectionChanged(selectedItem);
        }
    }

    private onSelectedIndexChanged(newIndex: number) {
        if (this.resultsRef.current) {
            const highlightedItem = this.resultsRef.current.children.item(newIndex);

            if (highlightedItem) {
                highlightedItem.scrollIntoView({
                    block: 'center',
                    behavior: 'smooth'
                });
            }
        }
    }

    private onBlur() {
        // Timeout to force selection event before state change
        setTimeout(() => {
            this.setState((state) => ({
                ...state,
                isExpanded: false
            }));
        }, 150);
    }
}
