import { IAppAction } from '../app-action';
import {
    IShiftHandover,
    IShiftHandoverFilters,
    IShiftHandoverItemCategory,
} from 'models/shifts/shift-handover';
import { IShiftChecklist, IShiftOperatingPlatformChecklist } from 'models/shifts/master-shift-checklist';

export enum ShiftHandoverActions {
    LOAD_LIST = '@@ShiftHandover/LOAD_LIST',
    LOAD_LIST_FULFILLED = '@@ShiftHandover/LOAD_LIST_FULFILLED',
    LOAD_LIST_REJECTED = '@@ShiftHandover/LOAD_LIST_REJECTED',

    LOAD_CHECKLIST_VERSION = '@@ShiftHandover/LOAD_CHECKLIST_VERSION',
    LOAD_CHECKLIST_VERSION_FULFILLED = '@@ShiftHandover/LOAD_CHECKLIST_VERSION_FULFILLED',
    LOAD_CHECKLIST_VERSION_REJECTED = '@@ShiftHandover/LOAD_CHECKLIST_VERSION_REJECTED',

    LOAD_CHECKLIST_ADMIN = '@@ShiftHandover/LOAD_CHECKLIST_ADMIN',
    LOAD_CHECKLIST_ADMIN_FULFILLED = '@@ShiftHandover/LOAD_CHECKLIST_ADMIN_FULFILLED',
    LOAD_CHECKLIST_ADMIN_REJECTED = '@@ShiftHandover/LOAD_CHECKLIST_ADMIN_REJECTED',

    LOAD_OPERATING_PLATFORM_CHECKLIST_ADMIN = '@@ShiftHandover/LOAD_OPERATING_PLATFORM_CHECKLIST_ADMIN',
    LOAD_OPERATING_PLATFORM_CHECKLIST_ADMIN_FULFILLED = '@@ShiftHandover/LOAD_OPERATING_PLATFORM_CHECKLIST_ADMIN_FULFILLED',
    LOAD_OPERATING_PLATFORM_CHECKLIST_ADMIN_REJECTED = '@@ShiftHandover/LOAD_OPERATING_PLATFORM_CHECKLIST_ADMIN_REJECTED',

    CREATE_CHECKLIST_ADMIN = '@@ShiftHandover/CREATE_CHECKLIST_ADMIN',
    CREATE_CHECKLIST_ADMIN_FULFILLED = '@@ShiftHandover/CREATE_CHECKLIST_ADMIN_FULFILLED',
    CREATE_CHECKLIST_ADMIN_REJECTED = '@@ShiftHandover/CREATE_CHECKLIST_ADMIN_REJECTED',

    CREATE_OPERATING_PLATFORM_CHECKLIST_ADMIN = '@@ShiftHandover/CREATE_OPERATING_PLATFORM_CHECKLIST_ADMIN',
    CREATE_OPERATING_PLATFORM_CHECKLIST_ADMIN_FULFILLED = '@@ShiftHandover/CREATE_OPERATING_PLATFORM_CHECKLIST_ADMIN_FULFILLED',
    CREATE_OPERATING_PLATFORM_CHECKLIST_ADMIN_REJECTED = '@@ShiftHandover/CREATE_OPERATING_PLATFORM_CHECKLIST_ADMIN_REJECTED',

    UPDATE_OPERATING_PLATFORM_CHECKLIST_ADMIN = '@@ShiftHandover/UPDATE_OPERATING_PLATFORM_CHECKLIST_ADMIN',
    UPDATE_OPERATING_PLATFORM_CHECKLIST_ADMIN_FULFILLED = '@@ShiftHandover/UPDATE_OPERATING_PLATFORM_CHECKLIST_ADMIN_FULFILLED',
    UPDATE_OPERATING_PLATFORM_CHECKLIST_ADMIN_REJECTED = '@@ShiftHandover/UPDATE_OPERATING_PLATFORM_CHECKLIST_ADMIN_REJECTED',

    DELETE_OPERATING_PLATFORM_CHECKLIST_ADMIN = '@@ShiftHandover/DELETE_OPERATING_PLATFORM_CHECKLIST_ADMIN',
    DELETE_OPERATING_PLATFORM_CHECKLIST_ADMIN_FULFILLED = '@@ShiftHandover/DELETE_OPERATING_PLATFORM_CHECKLIST_ADMIN_FULFILLED',
    DELETE_OPERATING_PLATFORM_CHECKLIST_ADMIN_REJECTED = '@@ShiftHandover/DELETE_OPERATING_PLATFORM_CHECKLIST_ADMIN_REJECTED',

    UPDATE_CHECKLIST_ADMIN = '@@ShiftHandover/UPDATE_CHECKLIST_ADMIN',
    UPDATE_CHECKLIST_ADMIN_FULFILLED = '@@ShiftHandover/UPDATE_CHECKLIST_ADMIN_FULFILLED',
    UPDATE_CHECKLIST_ADMIN_REJECTED = '@@ShiftHandover/UPDATE_CHECKLIST_ADMIN_REJECTED',

    DELETE_CHECKLIST_ADMIN = '@@ShiftHandover/DELETE_CHECKLIST_ADMIN',
    DELETE_CHECKLIST_ADMIN_FULFILLED = '@@ShiftHandover/DELETE_CHECKLIST_ADMIN_FULFILLED',
    DELETE_CHECKLIST_ADMIN_REJECTED = '@@ShiftHandover/DELETE_CHECKLIST_ADMIN_REJECTED',

    LOAD = '@@ShiftHandover/LOAD',
    LOAD_FULFILLED = '@@ShiftHandover/LOAD_FULFILLED',
    LOAD_REJECTED = '@@ShiftHandover/LOAD_REJECTED',

    LOAD_LAST_SUBMITTED = '@@ShiftHandover/LOAD_LAST_SUBMITTED',
    LOAD_LAST_SUBMITTED_FULFILLED = '@@ShiftHandover/LOAD_LAST_SUBMITTED_FULFILLED',
    LOAD_LAST_SUBMITTED_REJECTED = '@@ShiftHandover/LOAD_LAST_SUBMITTED_REJECTED',

    CREATE = '@@ShiftHandover/CREATE',
    CREATE_FULFILLED = '@@ShiftHandover/CREATE_FULFILLED',
    CREATE_REJECTED = '@@ShiftHandover/CREATE_REJECTED',

    UPDATE = '@@ShiftHandover/UPDATE',
    UPDATE_FULFILLED = '@@ShiftHandover/UPDATE_FULFILLED',
    UPDATE_REJECTED = '@@ShiftHandover/UPDATE_REJECTED',

    EXPORT = '@@ShiftHandover/EXPORT',
    EXPORT_FULFILLED = '@@ShiftHandover/EXPORT_FULFILLED',
    EXPORT_REJECTED = '@@ShiftHandover/EXPORT_REJECTED',

    LOAD_HISTORY = '@@ShiftHandover/LOAD_HISTORY',
    LOAD_HISTORY_FULFILLED = '@@ShiftHandover/LOAD_HISTORY_FULFILLED',
    LOAD_HISTORY_REJECTED = '@@ShiftHandover/LOAD_HISTORY_REJECTED',

    LOAD_SETTINGS = '@@ShiftHandover/LOAD_SETTINGS',
    LOAD_SETTINGS_FULFILLED = '@@ShiftHandover/LOAD_SETTINGS_FULFILLED',
    LOAD_SETTINGS_REJECTED = '@@ShiftHandover/LOAD_SETTINGS_REJECTED',

    ACKNOWLEDGE_SHIFT_HANDOVER = '@@ShiftHandover/ACKNOWLEDGE_SHIFT_HANDOVER',
    ACKNOWLEDGE_FULFILLED = '@@ShiftHandover/ACKNOWLEDGE_FULFILLED',
    ACKNOWLEDGE_REJECTED = '@@ShiftHandover/ACKNOWLEDGE_REJECTED',

    LOAD_SHIFT_HANDOVER_CATEGORY = '@@ShiftHandover/LOAD_SHIFT_HANDOVER_CATEGORY',
    LOAD_SHIFT_HANDOVER_CATEGORY_FULFILLED = '@@ShiftHandover/LOAD_SHIFT_HANDOVER_CATEGORY_FULFILLED',
    LOAD_SHIFT_HANDOVER_CATEGORY_REJECTED = '@@ShiftHandover/LOAD_SHIFT_HANDOVER_CATEGORY_REJECTED',

    LOAD_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY = '@@ShiftHandover/ LOAD_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY',
    LOAD_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY_FULFILLED = '@@ShiftHandover/ LOAD_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY_FULFILLED',
    LOAD_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY_REJECTED = '@@ShiftHandover/ LOAD_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY_REJECTED',

    CREATE_SHIFT_HANDOVER_CATEGORY = '@@ShiftHandover/CREATE_SHIFT_HANDOVER_CATEGORY',
    CREATE_SHIFT_HANDOVER_CATEGORY_FULFILLED = '@@ShiftHandover/CREATE_SHIFT_HANDOVER_CATEGORY_FULFILLED',
    CREATE_SHIFT_HANDOVER_CATEGORY_REJECTED = '@@ShiftHandover/CREATE_SHIFT_HANDOVER_CATEGORY_REJECTED',

    CREATE_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY = '@@ShiftHandover/CREATE_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY',
    CREATE_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY_FULFILLED = '@@ShiftHandover/CREATE_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY_FULFILLED',
    CREATE_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY_REJECTED = '@@ShiftHandover/CREATE_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY_REJECTED',

    UPDATE_SHIFT_HANDOVER_CATEGORY = '@@ShiftHandover/UPDATE_SHIFT_HANDOVER_CATEGORY',
    UPDATE_SHIFT_HANDOVER_CATEGORY_FULFILLED = '@@ShiftHandover/ UPDATE_SHIFT_HANDOVER_CATEGORY_FULFILLED',
    UPDATE_SHIFT_HANDOVER_CATEGORY_REJECTED = '@@ShiftHandover/ UPDATE_SHIFT_HANDOVER_CATEGORY_REJECTED',

    UPDATE_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY = '@@ShiftHandover/UPDATE_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY',
    UPDATE_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY_FULFILLED = '@@ShiftHandover/ UPDATE_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY_FULFILLED',
    UPDATE_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY_REJECTED = '@@ShiftHandover/ UPDATE_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY_REJECTED',

    DELETE_SHIFT_HANDOVER = '@@ShiftHandover/DELETE_SHIFT_HANDOVER',
    DELETE_SHIFT_HANDOVER_FULFILLED = '@@ShiftHandover/ DELETE_SHIFT_HANDOVER_FULFILLED',
    DELETE_SHIFT_HANDOVER_REJECTED = '@@ShiftHandover/ DELETE_SHIFT_HANDOVER_REJECTED',

    DELETE_SHIFT_HANDOVER_CATEGORY = '@@ShiftHandover/DELETE_SHIFT_HANDOVER_CATEGORY',
    DELETE_SHIFT_HANDOVER_CATEGORY_FULFILLED = '@@ShiftHandover/ DELETE_SHIFT_HANDOVER_CATEGORY_FULFILLED',
    DELETE_SHIFT_HANDOVER_CATEGORY_REJECTED = '@@ShiftHandover/ DELETE_SHIFT_HANDOVER_CATEGORY_REJECTED',

    CLEANUP = '@@ShiftHandover/CLEANUP',
    CLEANUP_LIST = '@@ShiftHandover/CLEANUP_LIST',

    LOAD_SHIFT_HANDOVER_OPERATING_PLATFORM = '@@ShiftHandover/LOAD_SHIFT_HANDOVER_OPERATING_PLATFORM',
    LOAD_SHIFT_HANDOVER_OPERATING_PLATFORM_FULFILLED = '@@ShiftHandover/LOAD_SHIFT_HANDOVER_OPERATING_PLATFORM_FULFILLED',
    LOAD_SHIFT_HANDOVER_OPERATING_PLATFORM_REJECTED = '@@ShiftHandover/LOAD_SHIFT_HANDOVER_OPERATING_PLATFORM_REJECTED',
}

export const loadShiftHandovers = (filters: IShiftHandoverFilters): IAppAction => ({
    type: ShiftHandoverActions.LOAD_LIST,
    payload: filters,
});

export const loadSpecificSiteShiftChecklist = (siteId: string): IAppAction => ({
    type: ShiftHandoverActions.LOAD_CHECKLIST_ADMIN,
    payload: {
        id: siteId,
    },
});

export const createSpecificSiteShiftChecklist = (model: IShiftChecklist): IAppAction => ({
    type: ShiftHandoverActions.CREATE_CHECKLIST_ADMIN,
    payload: model,
});

export const updateSpecificSiteShiftChecklist = (model: IShiftChecklist): IAppAction => ({
    type: ShiftHandoverActions.UPDATE_CHECKLIST_ADMIN,
    payload: model,
});

export const deleteSpecificSiteShiftChecklist = (id: string, siteId: string): IAppAction => ({
    type: ShiftHandoverActions.DELETE_CHECKLIST_ADMIN,
    payload: { id, siteId },
});

export const loadSpecificOperatingPlatformShiftChecklist = (operatingPlatform: string): IAppAction => ({
    type: ShiftHandoverActions.LOAD_OPERATING_PLATFORM_CHECKLIST_ADMIN,
    payload: {
        id: operatingPlatform,
    },
});

export const createSpecificSiteShiftOperatingPlatformChecklist = (model: IShiftOperatingPlatformChecklist): IAppAction => ({
    type: ShiftHandoverActions.CREATE_OPERATING_PLATFORM_CHECKLIST_ADMIN,
    payload: model,
});

export const updateSpecificSiteShiftOperatingPlatformChecklist = (model: IShiftOperatingPlatformChecklist): IAppAction => ({
    type: ShiftHandoverActions.UPDATE_OPERATING_PLATFORM_CHECKLIST_ADMIN,
    payload: model,
});

export const deleteSpecificSiteShiftOperatingPlatformChecklist = (id: string, operatingPlatform: string): IAppAction => ({
    type: ShiftHandoverActions.DELETE_OPERATING_PLATFORM_CHECKLIST_ADMIN,
    payload: { id, operatingPlatform },
});

export const loadShiftChecklistVersion = (siteId: string, shiftHandoverId: string): IAppAction => ({
    type: ShiftHandoverActions.LOAD_CHECKLIST_VERSION,
    payload: {
        id: siteId,
        shard: shiftHandoverId,
    },
});

export const loadShiftHandover = (id: string, siteId: string): IAppAction => ({
    type: ShiftHandoverActions.LOAD,
    payload: {
        id,
        shard: siteId,
        siteId,
    },
});

export const loadLastSubmittedShiftHandover = (facilityId: string, siteId: string): IAppAction => ({
    type: ShiftHandoverActions.LOAD_LAST_SUBMITTED,
    payload: {
        id: facilityId,
        shard: siteId,
        siteId,
    },
});

export const createShiftHandover = (model: IShiftHandover): IAppAction => ({
    type: ShiftHandoverActions.CREATE,
    payload: model,
});

export const updateShiftHandover = (model: IShiftHandover): IAppAction => ({
    type: ShiftHandoverActions.UPDATE,
    payload: model,
});

export const deleteShiftHandover = (id: string, siteId: string): IAppAction => ({
    type: ShiftHandoverActions.DELETE_SHIFT_HANDOVER,
    payload: {
        id,
        shard: siteId,
        siteId,
    },
});

export const exportShiftHandovers = (Id: string, siteId: string): IAppAction => ({
    type: ShiftHandoverActions.EXPORT,
    payload: {
        Id,
        shard: siteId,
        siteId,
    },
});

export const cleanupShiftHandover = (): IAppAction => ({
    type: ShiftHandoverActions.CLEANUP,
});

export const cleanupShiftHandoverList = (): IAppAction => ({
    type: ShiftHandoverActions.CLEANUP_LIST,
});

export const loadHistory = (id: string, siteId: string): IAppAction => ({
    type: ShiftHandoverActions.LOAD_HISTORY,
    payload: {
        id,
        siteId,
    },
});

export const loadSettings = (siteId: string): IAppAction => ({
    type: ShiftHandoverActions.LOAD_SETTINGS,
    payload: { siteId },
});

export const acknowledgeShiftHandover = (id: string, siteId: string): IAppAction => ({
    type: ShiftHandoverActions.ACKNOWLEDGE_SHIFT_HANDOVER,
    payload: {
        id,
        siteId,
    },
});

export const loadShiftHandoverCategory = (siteId: string): IAppAction => ({
    type: ShiftHandoverActions.LOAD_SHIFT_HANDOVER_CATEGORY,
    payload: siteId,
});

export const createShiftHandoverCategory = (
    model: IShiftHandoverItemCategory[],
    shard: string
): IAppAction => ({
    type: ShiftHandoverActions.CREATE_SHIFT_HANDOVER_CATEGORY,
    payload: { shard, categories: model },
});

export const updateShiftHandoverCategory = (
    model: IShiftHandoverItemCategory[],
    shard: string
): IAppAction => ({
    type: ShiftHandoverActions.UPDATE_SHIFT_HANDOVER_CATEGORY,
    payload: { shard, categories: model },
});
export const deleteShiftHandoverCategory = (categoryId: string): IAppAction => ({
    type: ShiftHandoverActions.DELETE_SHIFT_HANDOVER_CATEGORY,
    payload: categoryId,
});

export const loadShiftHandoverOperatingPlatformCategory = (operatingPlatform: string): IAppAction => ({
    type: ShiftHandoverActions.LOAD_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY,
    payload: {operatingPlatform},
});

export const createShiftHandoverOperatingPlatformCategory = (
    model: IShiftHandoverItemCategory[],
    shard: string
): IAppAction => ({
    type: ShiftHandoverActions.CREATE_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY,
    payload: { shard, categories: model },
});

export const updateShiftHandoverOperatingPlatformCategory = (
    model: IShiftHandoverItemCategory[],
    shard: string
): IAppAction => ({
    type: ShiftHandoverActions.UPDATE_SHIFT_HANDOVER_OPERATING_PLATFORM_CATEGORY,
    payload: { shard, categories: model },
});

export const loadSelectedOperatingPlatform = (selectedOperatingPlatform: string): IAppAction => ({
    type: ShiftHandoverActions.LOAD_SHIFT_HANDOVER_OPERATING_PLATFORM,
    payload: { selectedOperatingPlatform },
});
