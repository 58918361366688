import React from 'react';
import ReactDatePicker, { DatePickerProps } from 'react-datepicker';
import { Button } from '../button/Button';

type IProps = DatePickerProps & {
    buttonText?: string;
}

const CustomInput: React.FC<any> = (props) => (
    <Button className="button-date-picker" onClick={props.onClick}>
        {props.buttonText}
    </Button>
);

export const ButtonWithDatePicker: React.FC<IProps> = ({ buttonText = 'Date', ...props }) => {
    return <ReactDatePicker {...props} customInput={<CustomInput buttonText={buttonText} />} />;
};
