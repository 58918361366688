import React, { useEffect } from 'react';
import { TimelineItem } from 'components/timeline';
import OpportunityQuestionnaireAdvice from './opportunity-questionnaire-advice';
import OpportunitySubQuestionnaire from './opportunity-questionnaire-sub-questions';
import { IOpportunityQuestion } from 'models/opportunity-question-set';
import OpportunityQuestionnaireResponse from './opportunity-questionnaire-response';
import useTranslate from 'translations/translation-utils';

interface IProps {
    questionSet: Partial<IOpportunityQuestion>[];
    expandAll?: boolean;
    onQuestionAnswerChanged: (questionId: string, questionSetId: string, answer: string) => void;
    selectedResponseAnwer: (questionId, questionSetId) => string;
    responseAnswer: string;
    subResponseAnswer?: string;
    questionSetId: string;
}

const OpportunitySubQuestionnaireList: React.FC<IProps> = ({
    questionSet,
    expandAll,
    responseAnswer,
    questionSetId,
    onQuestionAnswerChanged,
    selectedResponseAnwer,
}) => {
    const [allResponseAnswers, setAllResponseAnswers] = React.useState([]);
    const translate = useTranslate();
    const setResponse = (questionId, response) => {
        if (response !== translate('OpportunityQ.Dropdown.DefaultLabel')) {
            onQuestionAnswerChanged(questionId, questionSetId, response);
        }
    };
    useEffect(() => {
        if (questionSet && questionSet.length > 0) {
            let answers = [];
            // tslint:disable-next-line: no-unused-expression
            questionSet &&
                questionSet.forEach((question) => {
                    answers = [...new Set([...answers, ...question.responseAnswers])];
                });

            setAllResponseAnswers(answers);
        }
    }, [questionSet]);

    const renderitem = (question) => {
        return (
            <>
                <TimelineItem>
                    <div className="columns timeline-inner-question">
                        <div className="column is-6">
                            <span className="grid__label is-uppercase">Question -</span>{' '}
                            {question.title}
                        </div>
                        <div className="TimpelineHeaderGridValue column is-6 timeline-response">
                            <OpportunityQuestionnaireResponse
                                onChange={setResponse}
                                answers={allResponseAnswers}
                                questionId={question.id}
                                selectedResponseAns={selectedResponseAnwer(
                                    question.id,
                                    questionSetId
                                )}
                            />
                        </div>
                    </div>
                </TimelineItem>
                {question.advices &&
                    question.advices.map((advice) => {
                        return checkResponse(advice.responseAnswers, responseAnswer) ? (
                            <OpportunityQuestionnaireAdvice advice={advice} />
                        ) : null;
                    })}
            </>
        );
    };

    const checkResponse = (responses, reponseAnswer) => {
        // tslint:disable-next-line: radix
        if (responses && responses.includes(reponseAnswer)) {
            return true;
        } else {
            return false;
        }
    };
    const renderTimeLineQustion = (question) => {
        return (
            question &&
            question.map((q) => {
                return q.questions || q.advices ? (
                    checkResponse(q.responseAnswers, responseAnswer) ? (
                        <OpportunitySubQuestionnaire
                            questionSet={q}
                            expandAll={expandAll}
                            responseAnswer={responseAnswer}
                            questionSetId={questionSetId}
                            onQuestionAnswerChanged={onQuestionAnswerChanged}
                            selectedResponseAnwer={selectedResponseAnwer}
                        />
                    ) : null
                ) : checkResponse(q.responseAnswers, responseAnswer) ? (
                    renderitem(q)
                ) : null;
            })
        );
    };
    return <>{renderTimeLineQustion(questionSet)}</>;
};

export default OpportunitySubQuestionnaireList;
