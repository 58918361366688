import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { IRootState } from 'routes/store';
import { createSelector } from 'reselect';
import { MockDrillReportUpsertPage } from './mock-drill-report-upsert-page';
import {
    loadMockDrillReport,
    createMockDrillReport,
    updateMockDrillReport,
    cleanupMockDrillReport,
    deleteMockDrillReport,
} from 'actions/actions-v2/mock-drill-report-actions';
import { getPermissions } from 'sharedSelectors';
import { checkPermission } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';

const getSiteId = (state: IRootState) => state.app.siteId;
const getIsLoading = (state: IRootState) => state.mockDrillReportState.isLoading;
const getIsMockDrillReportCreated = (state: IRootState) => state.mockDrillReportState.isCreated;
const getMockDrillReport = (state: IRootState) => state.mockDrillReportState.mockDrillReport;

const mapStateToProps = createSelector(
    getSiteId,
    getIsLoading,
    getIsMockDrillReportCreated,
    getMockDrillReport,
    getPermissions,
    (siteId, isLoading, isMockDrillReportedCreated, mockDrillReport, permissions) => ({
        siteId,
        isLoading,
        isMockDrillReportedCreated,
        mockDrillReport,
        hasReadWritePermission: checkPermission(Privilege.MockDrillsReadWrite, permissions),
        canEditTitle: checkPermission(Privilege.EditLocalMockDrillReportTitle, permissions),
        userCanDeleteLocalMockDrillsReport: checkPermission(
            Privilege.MockDrillsLocalReportDelete,
            permissions
        ),
        canAddParticipants: checkPermission(Privilege.MockDrillsParticipants, permissions),
    })
);

const mapDispatchToProps = {
    loadMockDrillReport,
    createMockDrillReport,
    updateMockDrillReport,
    deleteMockDrillReport,
    clearForm: cleanupMockDrillReport,
};

export const MockDrillReportUpsertPageContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MockDrillReportUpsertPage)
);
