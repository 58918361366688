import * as React from 'react';
import { compact } from 'lodash';
import { ModalCard, ModalCardBody, ModalCardFooter, ModalCardHeader } from 'components/modalCard';
import { ISiteProfile } from 'models/site-profile';
import useTranslate from 'translations/translation-utils';
import './delete-link-confirmation.scss';
import { Button } from 'components/v2/components';

export interface IDeleteLinkStateProps<T> {
    visible: boolean;
    site: ISiteProfile;
    item: T;
    itemText: string;
}

export interface IDeleteLinkActionProps<T> {
    onConfirm: (site: ISiteProfile, item: T) => void;
    onClose: (site: ISiteProfile, item: T) => void;
}

interface IProps<T> extends IDeleteLinkStateProps<T>, IDeleteLinkActionProps<T> { }

const getSiteDisplayText = (site: ISiteProfile) => compact([
    site.clientName && `(${site.clientName})`,
    site.name
]).join(' ');

export const DeleteLinkConfirmation: React.FC<IProps<any>> = (props) => {
    const translate = useTranslate();

    if (!props.visible) {
        return null;
    }

    const onClose = () => props.onClose(props.site, props.item);
    const onConfirm = () => props.onConfirm(props.site, props.item);

    return (
        <ModalCard className="DeleteLinkConfirmation" modalId="confirmation" isShown={props.visible}>
            <ModalCardHeader modalId="confirmation" title={'Confirmation'} />
            <ModalCardBody modalId="confirmation">
                <p><b>{translate('IncidentRiskActions.Trash.Remove')} {props.itemText} {translate('SiteRelationships.Common.DeleteLinkConfirmation')} {getSiteDisplayText(props.site)}</b></p>
            </ModalCardBody>
            <ModalCardFooter>
                {props.onConfirm && (
                    <Button onClick={onConfirm}>{translate('RiskRegisterUpsertPage.ConfirmDialog.ConfirmTitle')}</Button>
                )}
                <Button onClick={onClose}>{translate('RiskRegisterPage.RiskRegisterToolbar.Title.Close')}</Button>
            </ModalCardFooter>
        </ModalCard>
    );
};
