import * as React from 'react';
import selectors from './selectors';
import actions from './actions';
import { connect } from 'react-redux';
import { IAttachmentQuery } from 'models/attachment/attachment-query';
import { IAttachmentUpload } from 'models/attachment/attachment-upload';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import { IKeyValue } from 'models';
import { AttachmentModal } from 'components/attachment-modal/attachment-modal';
import { AttachmentList } from 'components/attachmentCollection/attachment-list';
import { Entity } from 'models/entity';
import { Loader } from '../loader';
import { Alert } from '../v2/alert/Alert';
import { Button } from '../v2/components';
import useTranslate from 'translations/translation-utils';
import { checkPermission } from 'utils/permission-utils';
import { Privilege } from 'enums/Privilege';

interface IProps {
    siteId?: string;
    id: string;
    entity: Entity;
    attachments: IAttachmentModel[];
    permissions: Array<IKeyValue<string>>;
    isSaving: boolean;
    isLoading: boolean;
    isModalVisible: boolean;
    isReadOnly?: boolean;
    isSingleFile?: boolean;
    isImage?: boolean;
    bottomUploadButton?: true;
    loadAttachments: (query: IAttachmentQuery) => void;
    deleteAttachment: (query: IAttachmentQuery) => void;
    uploadAttachment?: (upload: IAttachmentUpload) => void;
    showModal: () => void;
    hideModal: () => void;
    dontLoadAutomatically?: boolean;
}

const Documents: React.FC<IProps> = ({ id, entity, siteId, dontLoadAutomatically, ...props }) => {
    const translate = useTranslate();
    React.useEffect(() => {
        if (!id || !entity || !siteId || !!dontLoadAutomatically) {
            return;
        } else {
            props.loadAttachments({ entity, id, siteId });
        }
    }, [id, entity, siteId, dontLoadAutomatically]);

    const onDelete = (name: string) => {
        props.deleteAttachment({
            entity,
            fileName: name,
            id,
            siteId,
        });
    };

    const renderAlert = () => {
        return (
            <Alert type="warning" title={translate('Attachments.RenderAlert.Title')}>
                <p>{translate('Attachments.RenderAlert.Message')}</p>
            </Alert>
        );
    };

    const renderAttachments = () => {
        return (
            <>
                <Loader loading={props.isLoading}>
                    <AttachmentList
                        data={props.attachments}
                        permissions={props.permissions}
                        onDelete={onDelete}
                        disabled={props.isReadOnly}
                        isImage={props.isImage}
                    />
                </Loader>
                {!props.isReadOnly && (
                    <div className="upload">
                        {!checkPermission(Privilege.AllAttachmentsUpload, props.permissions) ?
                            <p className="has-text-warning">
                                {translate('Attachments.Warning.CorrectPermission')}
                            </p> : !(props.isSingleFile && props.isSingleFile == true) &&
                            <label className="label">
                                {translate('Attachments.RenderAttachments.label')}
                            </label>
                        }
                        <Button
                            id="uploadButton"
                            buttonType="upload"
                            onClick={props.showModal}
                            disabled={props.isReadOnly || (props.isSingleFile == true && props.attachments?.length == 1) || !checkPermission(Privilege.AllAttachmentsUpload, props.permissions)}
                        >
                            {translate('Attachments.Button.Upload')}
                        </Button>

                        <AttachmentModal
                            id={id}
                            entity={entity}
                            siteId={siteId}
                            isVisible={props.isModalVisible}
                            isUploading={props.isSaving}
                            onConfirm={props.uploadAttachment}
                            hideModal={props.hideModal}
                            isImage={props.isImage}
                        />
                    </div>
                )}
            </>
        );
    };

    return id ? renderAttachments() : renderAlert();
};

export default connect(selectors, actions)(Documents);
