import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Form, FormikProps, Formik, FormikActions } from 'formik';
import { Control, Field, Input, Help } from 'components/form';
import { BulmaSize } from 'enums/BulmaSize';
import { IKeyValue } from 'models';
import { IShift, ICreateShift, IShiftEngineer, getOption } from 'models/shift';
import { formikFieldCss } from 'utils/form-utils';
import { BulmaColor } from 'enums/BulmaColor';
import { Loader } from 'components/loader';
import { onRouteBack, onRouteChange } from 'actions/app-actions';
import { Panel, Button, ButtonWithConfirmDialog } from 'components/v2/components';
import * as Yup from 'yup';
import actions from './actions';
import selectors from './selectors';
import Page from 'components/v2/page/Page';
import { SelectField, IOption } from 'components/form/fields/select-field';
import useTranslate from 'translations/translation-utils';

interface IRouteParams {
    id: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
    isLoading: boolean;
    isSaving: boolean;
    siteId: string;
    engineers: IShiftEngineer[];
    shift: IShift;
    loadEngineers: (siteId: string) => void;
    loadShift: (id: string) => void;
    createShift: (shift: ICreateShift) => void;
    updateShift: (shift: IShift) => void;
    deleteShift: (id: string) => void;
    cancelUpsertShift: (shift: IShift) => void;
}

const UpsertShiftPage: React.FC<IProps> = (props) => {
    const translate = useTranslate();
    const shiftId = props.match.params.id;
    const isSubmitted = React.useRef(false);
    const shiftLoaded = React.useRef(false);
    const shiftsPageUrl = '/Shifts';
    const customLinks: Array<IKeyValue<string>> = [{ key: shiftsPageUrl, value: translate('Shifts.ShiftPage.Title') }];
    const [submitButtonText, setSubmitButtonText] = React.useState(' ');
    const [availableEngineers, setAvailableEngineers] = React.useState<IShiftEngineer[]>([]);
    const [selectedEngineers, setSelectedEngineers] = React.useState<IShiftEngineer[]>([]);
    const [selectedEngineer, setSelectedEngineer] = React.useState<IShiftEngineer>(null);

    React.useEffect(() => {
        // cleanup
        return () => {
            props.cancelUpsertShift(null);
        };
    }, []);

    React.useEffect(() => {
        if (props.siteId && !shiftLoaded.current) {
            if (shiftId && shiftId.length > 0) {
                setSubmitButtonText(translate('Globals.Label.Save'));
                props.loadShift(shiftId);
                shiftLoaded.current = true;
            } else {
                setSubmitButtonText(translate('Globals.Label.Create'));
            }

            // load engineers on every page load
            props.loadEngineers(props.siteId);
        }

        if (props.shift && props.shift.engineers) {
            setSelectedEngineers(props.shift.engineers);
        }
    }, [props.shift, props.siteId]);

    React.useEffect(() => {
        if (isSubmitted.current && !props.isSaving) {
            onRouteChange('/Shifts');
        }
    }, [props.isSaving]);

    React.useEffect(() => {
        if (props.engineers) {
            setAvailableEngineers(props.engineers);
        }
    }, [props.engineers]);

    const onSelectEngineer = (selectedOption: IOption) => {
        const id = selectedOption.value;
        const engineer = availableEngineers.find((e) => e.id === id);
        setSelectedEngineer(engineer);
    };

    const onRemoveEngineer = (id: string) => {
        const engineerToMove = selectedEngineers.find((e) => e.id === id);
        // add engineer to "available engineers" collection
        if (!availableEngineers.some((engineer) => engineer.id === id)) {
            setAvailableEngineers([...availableEngineers, engineerToMove]);
        }
        // remove engineer from "selected engineers" collection
        setSelectedEngineers(selectedEngineers.filter((engineer) => engineer.id !== id));
    };

    const onAddToShift = (e) => {
        e.preventDefault();

        // remove engineer from "available engineers" collection
        setAvailableEngineers(
            availableEngineers.filter((engineer) => engineer.id !== selectedEngineer.id)
        );

        // add engineer to "selected engineers" collection
        setSelectedEngineers([...selectedEngineers, selectedEngineer]);

        setSelectedEngineer(null);
    };

    const onSubmit = (formValues: IShift) => {
        if (shiftId) {
            props.updateShift({
                id: shiftId,
                siteId: props.siteId,
                name: formValues.name,
                engineers: selectedEngineers
            });
        } else {
            props.createShift({
                siteId: props.siteId,
                name: formValues.name,
                engineers: selectedEngineers
            });
        }

        isSubmitted.current = true;
    };

    const onRemoveShift = () => {
        if (props.shift) {
            props.deleteShift(props.shift.id);
        }

        isSubmitted.current = true;
    };

    const onCancel = () => {
        props.cancelUpsertShift(null);
        onRouteBack();
    };
    const getAvailableEngineers = (): IShiftEngineer[] => {
        if (!availableEngineers || availableEngineers.length < 1) {
            return [];
        }
        return availableEngineers
            .filter(
                (engineer) =>
                    selectedEngineers &&
                    !selectedEngineers.some((selected) => selected.id === engineer.id)
            );
    };

    const getSelectedEngineersList = (engineers: IShiftEngineer[]) => {
        if (!engineers || engineers.length < 1) {
            return <p>{translate('Shifts.ShiftPage.Engineers.Empty')}</p>;
        }

        return (
            <div className="table-container">
                <table className="table table-quantum">
                    <thead>
                        <tr>
                            <th>{translate('Shifts.ShiftPage.Engineers.FirstName')}</th>
                            <th>{translate('Shifts.ShiftPage.Engineers.LastName')}</th>
                            <th>{translate('Shifts.ShiftPage.Engineers.Email')}</th>
                            <th>{''}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {engineers.map((engineer, index) => (
                            <tr key={index}>
                                <td>{engineer.firstName}</td>
                                <td>{engineer.lastName}</td>
                                <td>{engineer.email}</td>
                                <td className="is-narrow">
                                    <button
                                        className="remove"
                                        onClick={() => onRemoveEngineer(engineer.id)}
                                    >{translate('Globals.Label.Remove')}</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const renderForm = (formikBag: FormikProps<Partial<IShift>>) => {
        return formikBag.values ? (
            <form id="shiftNameForm" onSubmit={formikBag.handleSubmit}>
                <Field htmlFor="name" label={translate('Shifts.ShiftPage.NameOfShift')} labelSize={BulmaSize.Medium}>
                    <Control>
                        <Input
                            id="name"
                            name="name"
                            aria-required="true"
                            placeholder={translate('Shifts.ShiftPage.ShiftName')}
                            type="text"
                            className={formikFieldCss(formikBag, 'name', 'shift-name')}
                            value={formikBag.values.name}
                            onChange={formikBag.handleChange}
                            onBlur={(event) => formikBag.setFieldValue(event.target.name, event.target.value.trim())}
                        />
                        <Help isShown={formikBag.touched.name} bulmaColor={BulmaColor.Danger}>
                            {formikBag.errors.name}
                        </Help>
                    </Control>
                </Field>
            </form>
        ) : null;
    };

    const title = props.shift && props.shift.name ? props.shift.name : translate('Shifts.ShiftPage.NewShift');

    return (
        <Page
            title={title}
            breadcrumbCustomLinks={customLinks}
            redirectOnSiteChange={true}
            redirectOnSiteChangeUrl={shiftsPageUrl}
            className="shift-page"
        >
            {props.isLoading ? (
                <Loader loading={true} />
            ) : (
                <Loader loading={props.isSaving}>
                    <Panel title={translate('Shifts.ShiftPage.Details')} bodyPadding={true}>
                        <Formik
                            initialValues={props.shift}
                            validationSchema={Yup.object<Partial<IShift>>().shape({
                                name: Yup.string().trim().required('Name is required'),
                            })}
                            onSubmit={(values: IShift, formikActions: FormikActions<IShift>) => {
                                onSubmit(values);
                                formikActions.setSubmitting(false);
                            }}
                            render={renderForm}
                            enableReinitialize={true}
                        />
                    </Panel>
                    <Panel
                        title={translate('Shifts.ShiftPage.Engineers.Title')}
                        intro={translate('Shifts.ShiftPage.Engineers.Subtitle')}
                        bodyPadding={true}
                    >
                        <Form>
                            <div className="EngineersForm">
                                <Field className="columns">
                                    <Control className="column is-8">
                                        <SelectField
                                            id="engineer_selector"
                                            label={translate('Shifts.ShiftPage.Engineers.Search')}
                                            className="select-facility"
                                            value={getOption(selectedEngineer)}
                                            options={getAvailableEngineers().map((f) => getOption(f))}
                                            handleChange={onSelectEngineer}
                                        />
                                    </Control>
                                    <Control className="btn-add-shift column is-4 is-narrow">
                                        <Button
                                            className="button is-medium is-primary"
                                            disabled={
                                                getAvailableEngineers().length < 1 ||
                                                !selectedEngineer
                                            }
                                            onClick={onAddToShift}
                                        >{translate('Shifts.ShiftPage.Engineers.Add')}</Button>
                                    </Control>
                                </Field>
                            </div>
                        </Form>
                        <div className="selected-engineers">
                            <label className="label is-medium">{translate('Shifts.ShiftPage.Engineers.Existing')} </label>
                            {getSelectedEngineersList(selectedEngineers)}
                        </div>
                    </Panel>
                    <div className="columns columns-buttons">
                        <div className="column">
                            <Button buttonType="cancel" onClick={onCancel}>{translate('Globals.Label.Cancel')} </Button>
                            {props.shift ? (
                                <ButtonWithConfirmDialog
                                    title={translate('Shifts.ShiftPage.Remove.Title')}
                                    message={translate('Shifts.ShiftPage.Remove.Message')}
                                    onConfirm={onRemoveShift}
                                    buttonType="archive"
                                    buttonText={translate('Shifts.ShiftPage.Remove.ButtonText')}
                                    buttonId="remove-button"
                                    isButtonDisabled={props.isSaving}
                                />
                            ) : null}
                        </div>
                        <div className="column column-save">
                            <Button
                                type="submit"
                                buttonType="save"
                                form="shiftNameForm"
                                disabled={!selectedEngineers || selectedEngineers.length < 1}
                            >
                                {submitButtonText}
                            </Button>
                        </div>
                    </div>
                </Loader>
            )}
        </Page>
    );
};

export default withRouter(connect(selectors, actions)(UpsertShiftPage));
