import { IAppAction } from 'actions/app-action';
import { BaseEpic, IActionType } from './../base-epic';
import { Epic, combineEpics } from 'redux-observable';
import { RiskProfileQuestionnaireActions } from 'actions/actions-v2';
import { IRiskProfileQuestionnaire } from 'models';

const controllerName = 'riskProfileQuestionnaire';

const createActions = {
    action: RiskProfileQuestionnaireActions.CREATE_RISK_PROFILE_QUESTIONNAIRE,
    actionFulfilled: RiskProfileQuestionnaireActions.CREATE_RISK_PROFILE_QUESTIONNAIRE_FULFILLED,
    actionRejected: RiskProfileQuestionnaireActions.CREATE_RISK_PROFILE_QUESTIONNAIRE_REJECTED
} as IActionType;

const updateActions = {
    action: RiskProfileQuestionnaireActions.UPDATE_RISK_PROFILE_QUESTIONNAIRE,
    actionFulfilled: RiskProfileQuestionnaireActions.UPDATE_RISK_PROFILE_QUESTIONNAIRE_FULFILLED,
    actionRejected: RiskProfileQuestionnaireActions.UPDATE_RISK_PROFILE_QUESTIONNAIRE_REJECTED
} as IActionType;

const getActions = {
    action: RiskProfileQuestionnaireActions.LOAD_RISK_PROFILE_QUESTIONNAIRE,
    actionFulfilled: RiskProfileQuestionnaireActions.LOAD_RISK_PROFILE_QUESTIONNAIRE_FULFILLED,
    actionRejected: RiskProfileQuestionnaireActions.LOAD_RISK_PROFILE_QUESTIONNAIRE_REJECTED
} as IActionType;

const exportActions = {
    action: RiskProfileQuestionnaireActions.EXPORT_RISK_PROFILE_QUESTIONNAIRE,
    actionFulfilled: RiskProfileQuestionnaireActions.EXPORT_RISK_PROFILE_QUESTIONNAIRE_FULFILLED,
    actionRejected: RiskProfileQuestionnaireActions.EXPORT_RISK_PROFILE_QUESTIONNAIRE_REJECTED
} as IActionType;

const exportAllActions = {
    action: RiskProfileQuestionnaireActions.EXPORT_ALL_RISK_PROFILE_QUESTIONNAIRE,
    actionFulfilled: RiskProfileQuestionnaireActions.EXPORT_ALL_RISK_PROFILE_QUESTIONNAIRE_FULFILLED,
    actionRejected: RiskProfileQuestionnaireActions.EXPORT_ALL_RISK_PROFILE_QUESTIONNAIRE_REJECTED
} as IActionType;

const createRiskProfileQuestionnaireEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, createActions).post<IRiskProfileQuestionnaire>(`/api/${controllerName}`);
const updateRiskProfileQuestionnaireEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, updateActions).put<IRiskProfileQuestionnaire>(`/api/${controllerName}`);
const loadRiskProfileQuestionnaireEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, getActions).get<IRiskProfileQuestionnaire[]>(`/api/${controllerName}`);
const exportRiskProfileQuestionnaireEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, exportActions).exportByQueryModel<IRiskProfileQuestionnaire[]>(`/api/${controllerName}/export`);
const exportAllRiskProfileQuestionnaireEpic: Epic<IAppAction, IAppAction> = (action$, state$) =>
    new BaseEpic(action$, state$, exportAllActions).exportByQueryModel<IRiskProfileQuestionnaire[]>(`/api/${controllerName}/exportAll`);

export const riskProfileQuestionnaireEpicsV2 = combineEpics(
    createRiskProfileQuestionnaireEpic,
    updateRiskProfileQuestionnaireEpic,
    loadRiskProfileQuestionnaireEpic,
    exportRiskProfileQuestionnaireEpic,
    exportAllRiskProfileQuestionnaireEpic
);
