import { createSelector } from 'reselect';
import { IUpsertCermProcessStepRootState } from 'reducers/upsert-cerm-process-step';
import { IRootState } from 'routes/store';
import { getUpsertCermProcessStepState } from '../common';

const getLookupRiskCategories = (state: IUpsertCermProcessStepRootState) =>
    state.app.lookupRiskCategories;

const getLookupRiskStatus = (state: IUpsertCermProcessStepRootState) => state.app.lookupRiskStatus;

const getLookupRiskLikelihoods = (state: IUpsertCermProcessStepRootState) =>
    state.app.lookupRiskLikelihoods;

const getLookupRiskImpacts = (state: IUpsertCermProcessStepRootState) =>
    state.app.lookupRiskImpacts;

const getSite = (state: IRootState) => state.siteState.site;

const getOperatingPlatforms = (state: IRootState) =>
    state.operatingPlatformState.operatingPlatforms;

export default createSelector(
    getLookupRiskCategories,
    getLookupRiskStatus,
    getLookupRiskLikelihoods,
    getLookupRiskImpacts,
    getSite,
    getUpsertCermProcessStepState,
    getOperatingPlatforms,
    (lookupRiskCategories, lookupRiskStatus, lookupRiskLikelihoods, lookupRiskImpacts, site,state,operatingPlatforms) => ({
        lookupRiskCategories,
        lookupRiskStatus,
        lookupRiskLikelihoods,
        lookupRiskImpacts,
        site,
        isSubmitting: state.isSubmitting,
        operatingPlatforms
    })
);
