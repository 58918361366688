import { connect } from 'react-redux';

import actions from './actions';
import { ActionUpsert as View } from './ActionUpsert';
import selectors from './selectors';

export const ActionUpsert = connect(
    selectors,
    actions
)(View);
