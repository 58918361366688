import { IAppAction } from '../app-action';
import { IUserAssignModule, IPerson, IPersonActivate } from 'models/person';
import { IPersonFilters, IPersonAdvanceFilters } from 'models/person-filters';
import { IManageRolesFilters } from 'routes/manageRoles/views/manage-roles-page';

export enum PersonActions {
    LOAD_PERSONS = '@@person/LOAD_PERSONS',
    LOAD_PERSONS_FULFILLED = '@@person/LOAD_PERSONS_FULFILLED',
    LOAD_PERSONS_REJECTED = '@@person/LOAD_PERSONS_REJECTED',
    RESET_PERSON = '@@person/RESET_PERSON',

    LOAD_ALL_PERSONS = '@@person/LOAD_ALL_PERSONS',
    LOAD_ALL_PERSONS_FULFILLED = '@@person/LOAD_ALL_PERSONS_FULFILLED',
    LOAD_ALL_PERSONS_REJECTED = '@@person/LOAD_ALL_PERSONS_REJECTED',

    LOAD_SITE_PERSONS = '@@person/LOAD_SITE_PERSONS',
    LOAD_SITE_PERSONS_FULFILLED = '@@person/LOAD_SITE_PERSONS_FULFILLED',
    LOAD_SITE_PERSONS_REJECTED = '@@person/LOAD_SITE_PERSONS_REJECTED',

    LOAD_SITE_ALL_PERSONS = '@@person/LOAD_SITE_ALL_PERSONS',
    LOAD_SITE_ALL_PERSONS_FULFILLED = '@@person/LOAD_SITE_ALL_PERSONS_FULFILLED',
    LOAD_SITE_ALL_PERSONS_REJECTED = '@@person/LOAD_SITE_ALL_PERSONS_REJECTED',

    LOAD_PERSON = '@@person/LOAD_PERSON',
    LOAD_PERSON_FULFILLED = '@@person/LOAD_PERSON_FULFILLED',
    LOAD_PERSON_REJECTED = '@@person/LOAD_PERSON_REJECTED',

    CREATE_PERSON = '@@person/CREATE_PERSON',
    CREATE_PERSON_FULFILLED = '@@person/CREATE_PERSON_FULFILLED',
    CREATE_PERSON_REJECTED = '@@person/CREATE_PERSON_REJECTED',

    UPDATE_PERSON = '@@person/UPDATE_PERSON',
    UPDATE_PERSON_FULFILLED = '@@person/UPDATE_PERSON_FULFILLED',
    UPDATE_PERSON_REJECTED = '@@person/UPDATE_PERSON_REJECTED',

    SORT_PERSONS = '@@person/SORT_PERSONS',

    EXPORT_PEOPLE = '@@person/EXPORT_PEOPLE',
    EXPORT_PEOPLE_FULFILLED = '@@person/EXPORT_PEOPLE_FULFILLED',
    EXPORT_PEOPLE_REJECTED = '@@person/EXPORT_PEOPLE_REJECTED',

    LOAD_PERSONS_LOOKUP = '@@person/LOAD_PERSONS_LOOKUP',
    LOAD_PERSONS_LOOKUP_FULFILLED = '@@person/LOAD_PERSONS_LOOKUP_FULFILLED',
    LOAD_PERSONS_LOOKUP_REJECTED = '@@person/LOAD_PERSONS_LOOKUP_REJECTED',

    LOAD_PERSONS_BY_SITE_AND_PRIVILEGE = '@@nav/LOAD_PERSONS_BY_SITE_AND_PRIVILEGE',
    LOAD_PERSONS_BY_SITE_AND_PRIVILEGE_FULFILLED = '@@nav/LOAD_PERSONS_BY_SITE_AND_PRIVILEGE_FULFILLED',
    LOAD_PERSONS_BY_SITE_AND_PRIVILEGE_REJECTED = '@@nav/LOAD_PERSONS_BY_SITE_AND_PRIVILEGE_REJECTED',

    RESET_NOTIFICATION_PERSONS = '@@person/RESET_NOTIFICATION_PERSONS',

    LOAD_PERSON_ASSIGNED_ITEM = '@@person/LOAD_PERSON_ASSIGNED_ITEM',
    LOAD_PERSON_ASSIGNED_ITEM_FULFILLED = '@@person/LOAD_PERSON_ASSIGNED_ITEM_FULFILLED',
    LOAD_PERSON_ASSIGNED_ITEM_REJECTED = '@@person/LOAD_PERSON_ASSIGNED_ITEM_REJECTED',

    LOAD_PERSON_ASSIGN_MODULES = '@@person/LOAD_PERSON_ASSIGN_MODULES',
    LOAD_PERSON_ASSIGN_MODULES_FULFILLED = '@@person/LOAD_PERSON_ASSIGN_MODULES_FULFILLED',
    LOAD_PERSON_ASSIGN_MODULES_REJECTED = '@@person/LOAD_PERSON_ASSIGN_MODULES_REJECTED',

    UPDATE_PERSON_ASSIGN_MODULES = '@@person/UPDATE_PERSON_ASSIGN_MODULES',
    UPDATE_PERSON_ASSIGN_MODULES_FULFILLED = '@@person/UPDATE_PERSON_ASSIGN_MODULES_FULFILLED',
    UPDATE_PERSON_ASSIGN_MODULES_REJECTED = '@@person/UPDATE_PERSON_ASSIGN_MODULES_REJECTED',

    LOAD_PERSON_RELATIONSHIPS = '@@person/PERSON_RELATIONSHIPS',
    LOAD_PERSON_RELATIONSHIPS_FULFILLED = '@@person/PERSON_RELATIONSHIPS_FULFILLED',
    LOAD_PERSON_RELATIONSHIPS_REJECTED = '@@person/PERSON_RELATIONSHIPS_REJECTED',

    DELETE_PERSON = '@@person/DELETE_PERSON',
    DELETE_PERSON_FULFILLED = '@@person/DELETE_PERSON_FULFILLED',
    DELETE_PERSON_REJECTED = '@@person/DELETE_PERSON_REJECTED',

    ACTIVATE_PERSON = '@@person/ACTIVATE_PERSON',
    ACTIVATE_PERSON_FULFILLED = '@@person/ACTIVATE_PERSON_FULFILLED',
    ACTIVATE_PERSON_REJECTED = '@@person/ACTIVATE_PERSON_REJECTED',

    LOAD_PERSON_ASSIGNED_SITES = '@@person/LOAD_PERSON_ASSIGNED_SITES',
    LOAD_PERSON_ASSIGNED_SITES_FULFILLED = '@@person/LOAD_PERSON_ASSIGNED_SITES_FULFILLED',
    LOAD_PERSON_ASSIGNED_SITES_REJECTED = '@@person/LOAD_PERSON_ASSIGNED_SITES_REJECTED',
}

export const resetPerson = (): IAppAction => ({
    type: PersonActions.RESET_PERSON,
});

export const exportPeople = (): IAppAction => ({
    type: PersonActions.EXPORT_PEOPLE,
});

export const loadPersons = (filters?: IPersonFilters | IManageRolesFilters): IAppAction => ({
    type: PersonActions.LOAD_PERSONS,
    payload: filters,
});

export const loadAllPersons = (filters?: IPersonAdvanceFilters): IAppAction => ({
    type: PersonActions.LOAD_ALL_PERSONS,
    payload: filters,
});

export const loadSitePersons = (siteId: string): IAppAction => ({
    type: PersonActions.LOAD_SITE_PERSONS,
    payload: siteId,
});

export const loadSiteAllPersons = (siteId: string): IAppAction => ({
    type: PersonActions.LOAD_SITE_ALL_PERSONS,
    payload: siteId,
});

export const loadPerson = (id?: string): IAppAction => ({
    type: PersonActions.LOAD_PERSON,
    payload: id,
});

export const createPerson = (person: IPerson): IAppAction => ({
    type: PersonActions.CREATE_PERSON,
    payload: person,
});

export const updatePerson = (person: IPerson): IAppAction => ({
    type: PersonActions.UPDATE_PERSON,
    payload: person,
});

export const onSort = (
    key: Extract<keyof IPerson, string>,
    sortAscending: boolean
): IAppAction => ({
    type: PersonActions.SORT_PERSONS,
    payload: { key, sortAscending },
});

export const upsertPerson = (person: IPerson): IAppAction => {
    if (person.id) {
        return {
            type: PersonActions.UPDATE_PERSON,
            payload: person,
        };
    } else {
        return {
            type: PersonActions.CREATE_PERSON,
            payload: person,
        };
    }
};

export const loadPersonsLookup = (siteId: string): IAppAction => ({
    type: PersonActions.LOAD_PERSONS_LOOKUP,
    payload: siteId,
});

export const loadPersonsBySiteAndPrivilege = (
    siteId: string,
    privilegeName: string
): IAppAction => ({
    type: PersonActions.LOAD_PERSONS_BY_SITE_AND_PRIVILEGE,
    payload: {
        id: siteId,
        shard: privilegeName,
    },
});

export const resetNotificationPerson = (): IAppAction => ({
    type: PersonActions.RESET_NOTIFICATION_PERSONS,
});

export const loadPersonAssignedItem = (userAssignModules: IUserAssignModule): IAppAction => ({
    type: PersonActions.LOAD_PERSON_ASSIGNED_ITEM,
    payload: userAssignModules
});

export const loadPersonAssignModules = (id: string, siteId: string): IAppAction => ({
    type: PersonActions.LOAD_PERSON_ASSIGN_MODULES,
    payload: {
        id,
        siteId
    }
});

export const updatePersonAssignModules = (userAssignModules: IUserAssignModule): IAppAction => ({
    type: PersonActions.UPDATE_PERSON_ASSIGN_MODULES,
    payload: userAssignModules
});

export const loadRelationships = (personId: string): IAppAction => ({
    type: PersonActions.LOAD_PERSON_RELATIONSHIPS,
    payload: personId,
});

export const deleteUser = (personId: string): IAppAction => ({
    type: PersonActions.DELETE_PERSON,
    payload: personId,
});

export const activateUser = (activateModel: IPersonActivate): IAppAction => ({
    type: PersonActions.ACTIVATE_PERSON,
    payload: {
        id: activateModel.id,
    },
});

export const loadPersonAssignedSites = (personId: string): IAppAction => ({
    type: PersonActions.LOAD_PERSON_ASSIGNED_SITES,
    payload: personId
});