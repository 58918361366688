import * as React from 'react';
import classnames from 'classnames';

export enum SortOrder {
    off = 'off',
    asc = 'asc',
    desc = 'desc',
}

interface IProps
    extends React.DetailedHTMLProps<
        React.ThHTMLAttributes<HTMLTableHeaderCellElement>,
        HTMLTableHeaderCellElement
    > {
    sortState: SortOrder;
    className?: string;
    onToggle: () => void;
}

export const TableHeader: React.FC<IProps> = ({
    sortState = 'off',
    className,
    children,
    onToggle,
    ...props
}) => {
    return (
        <th
            className={classnames(`sort sort-${sortState}`, className)}
            onClick={onToggle}
            {...props}
        >
            {children}
        </th>
    );
};
