import * as React from 'react';
import { Loader } from 'components/loader';
import {
    IRiskProfileQuestionnaire,
    IRiskProfileQuestionSetQuestionAnswer,
    IRiskProfileAnswer
} from 'models';
import '../engineers/engineer.scss';
import {
    Timeline,
    TimelineHeader,
    TimelineHeaderGridValueCollection,
    TimelineHeaderGridValue,
    TimelineItem
} from '../timeline';
import { IRiskProfileCategory } from 'models/risk-profile-questions/risk-profile-lookups';
import { Panel, TriStateToggle, Toolbar, ToolbarGroupRight, FloatingButton } from '../v2/components';
import { ConfirmNavigateAwayDialog } from '../v2/dialog/ConfirmNavigateAwayDialog';
import useTranslate from 'translations/translation-utils';

export interface IRiskProfileQuestionsProps {
    riskProfileQuestionnaire: IRiskProfileQuestionnaire;
    categories: IRiskProfileCategory[];
    siteId: string;
    isLoading: boolean;
    onSaveQuestionnaire: (questionnaire: IRiskProfileQuestionnaire) => void;
    operatingPlatform: string;
}

export interface IRiskProfileQuestionsState {
    questionnaire: IRiskProfileQuestionnaire;
    isDirty: boolean;
}
export const RiskProfileQuestions: React.FC<IRiskProfileQuestionsProps> = (props) => {
    const [questionnaire, setQuestionnaire] = React.useState(null);
    const [isDirty, setIsDirty] = React.useState(false);
    const translate = useTranslate();

    React.useEffect(() => {
        if (props.riskProfileQuestionnaire) {
            setQuestionnaire(props.riskProfileQuestionnaire);
        }
    }, [props.riskProfileQuestionnaire])

    const onToggleQuestionSet = (questionSet: IRiskProfileQuestionSetQuestionAnswer, e: any) => {
        const yes = e;
        let updatedQuestionnaire: IRiskProfileQuestionnaire = questionnaire;
        questionSet = { ...questionSet, questionSetAnswer: yes };
        const indexToReplace = updatedQuestionnaire.questionSets.findIndex(
            (x) => x.questionSetId === questionSet.questionSetId
        );
        updatedQuestionnaire = {
            id: updatedQuestionnaire.id,
            questionSets: updatedQuestionnaire.questionSets.map((obj, index) => {
                return index === indexToReplace ? questionSet : obj;
            }),
            operatingPlatform: props.operatingPlatform
        };
        setQuestionnaire(updatedQuestionnaire);
        setIsDirty(true);
    }

    const onToggleQuestion = (
        answer: IRiskProfileAnswer,
        questionSet: IRiskProfileQuestionSetQuestionAnswer,
        e: any
    ) => {
        const yes = e;
        let updatedQuestionnaire: IRiskProfileQuestionnaire = questionnaire;
        answer = { ...answer, answer: yes };
        // replace this question within the questionSet
        const indexToReplaceQuestion = questionSet.answers.findIndex(
            (x) => x.questionId === answer.questionId
        );
        questionSet = {
            ...questionSet,
            answers: questionSet.answers.map((obj, index) => {
                return index === indexToReplaceQuestion ? answer : obj;
            })
        };
        // replace the questionSet within the questionnaire
        const indexToReplace = updatedQuestionnaire.questionSets.findIndex(
            (x) => x.questionSetId === questionSet.questionSetId
        );
        updatedQuestionnaire = {
            id: updatedQuestionnaire.id,
            questionSets: updatedQuestionnaire.questionSets.map((obj, index) => {
                return index === indexToReplace ? questionSet : obj;
            }),
            operatingPlatform: props.operatingPlatform
        };
        setQuestionnaire(updatedQuestionnaire);
        setIsDirty(true);
    }

    const onSave = () => {
        const updatedQuestionnaire: IRiskProfileQuestionnaire = questionnaire;
        setQuestionnaire(updatedQuestionnaire);
        setIsDirty(false);
        props.onSaveQuestionnaire(updatedQuestionnaire);
    }
    return (
        <Loader className="Questions" loading={props.isLoading}>
            {props.riskProfileQuestionnaire && props.categories ? (
                <>
                    <ConfirmNavigateAwayDialog when={isDirty} />
                    <Panel title={translate('RiskProfile.RiskQuestions.Title')}>
                        {questionnaire &&
                            questionnaire.questionSets.map((questionSet) => (
                                <div
                                    id={questionSet.questionSetId}
                                    key={questionSet.questionSetId}
                                    className="risk-profile-questions"
                                >
                                    <Timeline
                                        expanded={questionSet.questionSetAnswer}
                                        key={`${questionSet.questionSetId}__${questionSet.questionSetId}`}
                                    >
                                        <TimelineHeader
                                            centered={true}
                                            expanded={questionSet.questionSetAnswer}
                                        >
                                            <TimelineHeaderGridValueCollection>
                                                <TimelineHeaderGridValue className="timeline-title column is-2 has-text-weight-bold">
                                                    {questionSet.category}
                                                </TimelineHeaderGridValue>
                                                <TimelineHeaderGridValue className="column is-8 has-text-weight-bold">
                                                    {questionSet.questionSetText}
                                                </TimelineHeaderGridValue>
                                                <TimelineHeaderGridValue className="column is-2">
                                                    <TriStateToggle
                                                        toggleFor={questionSet.questionSetId}
                                                        onChange={(e: any) =>
                                                            onToggleQuestionSet(
                                                                questionSet,
                                                                e
                                                            )
                                                        }
                                                        selectedValue={
                                                            questionSet.questionSetAnswer
                                                        }
                                                    />
                                                </TimelineHeaderGridValue>
                                            </TimelineHeaderGridValueCollection>
                                        </TimelineHeader>
                                        {questionSet.questionSetAnswer &&
                                            questionSet.answers.map((answer) => (
                                                <TimelineItem key={answer.questionId}>
                                                    <div className="columns">
                                                        <div className="column is-2">
                                                            {translate('RiskProfile.RiskQuestions.Question')}{' '}
                                                            {answer.questionOrder.toString()}
                                                        </div>
                                                        <div className="column is-7">
                                                            {answer.questionText}
                                                        </div>
                                                        <div className="column is-2 tristate-toggle">
                                                            <TriStateToggle
                                                                toggleFor={answer.questionId}
                                                                onChange={(e: any) =>
                                                                    onToggleQuestion(
                                                                        answer,
                                                                        questionSet,
                                                                        e
                                                                    )
                                                                }
                                                                selectedValue={answer.answer}
                                                            />
                                                        </div>
                                                    </div>
                                                </TimelineItem>
                                            ))}
                                    </Timeline>
                                </div>
                            ))}
                    </Panel>
                    <Toolbar type="save">
                        <ToolbarGroupRight>
                            <FloatingButton
                                id="save-risk-profile-questionnaire-button"
                                className="button is-medium is-primary is-pulled-right"
                                disabled={!isDirty}
                                onClick={onSave}
                                float={isDirty}
                                tooltip={translate('RiskProfile.RiskQuestions.SaveTooltip')}
                            >
                                {translate('Globals.Label.Save')}
                            </FloatingButton>
                        </ToolbarGroupRight>
                    </Toolbar>
                </>
            ) : (
                <p> {translate('CompetencyDnaProcess.Label.16')}</p>
            )}
        </Loader>
    );
}
