import { createSelector } from 'reselect';
import { getIsClient } from 'sharedSelectors';
import { isNil } from 'lodash';
import { IRootRisksState } from '../../../../reducers/risks';
import { getKey } from '../../../../utils/id-utils';
import { IOwnProps } from './approval';
import { IRootState } from 'routes/store';

const getModalData = (state: IRootRisksState) => state.risks.attachments.openModal;
const getIsModalVisible = (state: IRootRisksState, props: IOwnProps) => {
    const data = getModalData(state);
    return !isNil(data) && data.id === props.riskId && data.siteId === props.siteId;
};

const getIsVisible = (state: IRootRisksState, props: IOwnProps) =>
    state.risks.attachments.byRisk.hasOwnProperty(getKey({ id: props.riskId, siteId: props.siteId }));

const getPermissions = createSelector(
    (state: IRootRisksState) => state.app,
    (state) => state.permissions
);

const getUploaded = (state: IRootState) => state.attachmentState.saved;
const getIsUploading = (state: IRootState) => state.attachmentState.isSaving;

export default createSelector(
    getPermissions,
    getIsClient,
    getIsVisible,
    getIsUploading,
    getIsModalVisible,
    getUploaded,
    (permissions, isClient, isVisible, isUploading, isModalVisible, uploaded) => ({
        permissions,
        isClient,
        isVisible,
        isUploading,
        isModalVisible,
        uploaded,
    })
);
