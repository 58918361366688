import { combineReducers } from 'redux';
import { IAppAction } from 'actions/app-action';
import { ICermAssessmentResultState } from 'state/cerm/cerm-assessment-result-state';
import { CermAssessmentActions } from 'actions/actions-v2/cerm-assessment-action-v3';
import { AttachmentActions } from 'actions/actions-v2/attachment-action-v2';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import { s2ab } from 'utils/export-utils';
import { ActionActions } from 'actions/actions-v2';
import { RiskActions } from 'actions/actions-v2/risk-action-v2';
import saveAs from 'file-saver';
import { ICermAssessmentSnapshot } from 'models/cerm/cerm-assessment-snapshot';
import { IDifference } from 'models/difference';

const INITIAL_STATE: ICermAssessmentResultState = {
    cermAssessmentResult: null,
    processStepAnswer: null,
    isLoading: false,
    attachments: [],
    cermProgressChartData: null,
    snapshots: [],
    isExporting: false,
    history: [],
    historyResponseContinuation: null,
    eCermToken: '',
    isLoadingHistory: false,
    expertReviewStatus: null,
    attachmentsSite: [],
    snapshotsFullResult: [],
};

const expertReviewStatus = (
    state: ICermAssessmentResultState['expertReviewStatus'] = INITIAL_STATE.expertReviewStatus,
    action: IAppAction
) => {
    switch (action.type) {
        case CermAssessmentActions.LOAD_CERM_ASSESSMENT_EXPERT_REVIEW_FULFILLED:
            if (action.payload) {
                return action.payload;
            }
        default:
            return state;
    }
};

const attachments = (
    state: ICermAssessmentResultState['attachments'] = INITIAL_STATE.attachments,
    action: IAppAction
) => {
    switch (action.type) {
        case AttachmentActions.LOAD_ATTACHMENTS_FULFILLED_OUTCOME:
            if (action.payload) {
                return action.payload;
            }
        case AttachmentActions.UPLOAD_ATTACHMENT_FULFILLED_OUTCOME:
            if (action.payload) {
                return [...state, ...action.payload];
            }
            return state;
        case AttachmentActions.DELETE_ATTACHMENT_FULFILLED:
            if (action.payload) {
                return state.filter((item: IAttachmentModel) => item.fileName !== action.payload);
            }
            return state;
        default:
            return state;
    }
};

const attachmentsSite = (
    state: ICermAssessmentResultState['attachmentsSite'] = INITIAL_STATE.attachments,
    action: IAppAction
) => {
    switch (action.type) {
        case AttachmentActions.LOAD_ATTACHMENTS_CERM_ASSESSMENT_FULFILLED:
            if (action.payload) {
                return action.payload;
            }
        default:
            return state;
    }
};
const eCermToken = (
    state: ICermAssessmentResultState['eCermToken'] = INITIAL_STATE.eCermToken,
    action: IAppAction
) => {
    switch (action.type) {
        case CermAssessmentActions.REFRESH_ECERM_TOKEN_FULFILLED:
            return action.payload;

        default:
            return state;
    }
};

const isLoading = (
    state: ICermAssessmentResultState['isLoading'] = INITIAL_STATE.isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case CermAssessmentActions.FINISH_CERM_EXPERT_ASSESSMENT:
        case CermAssessmentActions.START_CERM_EXPERT_ASSESSMENT:
        case CermAssessmentActions.LOAD_CERM_ASSESSMENT_RESULT:
        case CermAssessmentActions.LOAD_PROCESS_STEP_ANSWER:
        case CermAssessmentActions.LOAD_PROCESS_STEP_OUTCOME:
        case CermAssessmentActions.CREATE_PROCESS_STEP_ANSWER:
        case CermAssessmentActions.SAVE_PROCESS_STEP_ANSWER:
        case CermAssessmentActions.LOAD_HISTORY:
        case CermAssessmentActions.SET_PROCESS_STEP_RESULT:
            return true;
        case CermAssessmentActions.LOAD_HISTORY_FULFILLED:
        case CermAssessmentActions.LOAD_HISTORY_REJECTED:
        case CermAssessmentActions.START_CERM_EXPERT_ASSESSMENT_FULFILLED:
        case CermAssessmentActions.START_CERM_EXPERT_ASSESSMENT__REJECTED:
        case CermAssessmentActions.FINISH_CERM_EXPERT_ASSESSMENT_FULFILLED:
        case CermAssessmentActions.FINISH_CERM_EXPERT_ASSESSMENT__REJECTED:
        case CermAssessmentActions.LOAD_CERM_ASSESSMENT_RESULT_FULFILLED:
        case CermAssessmentActions.LOAD_CERM_ASSESSMENT_RESULT_REJECTED:
        case CermAssessmentActions.LOAD_PROCESS_STEP_ANSWER_FULFILLED:
        case CermAssessmentActions.LOAD_PROCESS_STEP_ANSWER_REJECTED:
        case CermAssessmentActions.LOAD_PROCESS_STEP_OUTCOME_FULFILLED:
        case CermAssessmentActions.LOAD_PROCESS_STEP_OUTCOME_REJECTED:
        case CermAssessmentActions.CREATE_PROCESS_STEP_ANSWER_FULFILLED:
        case CermAssessmentActions.CREATE_PROCESS_STEP_ANSWER_REJECTED:
        case CermAssessmentActions.SAVE_PROCESS_STEP_ANSWER_FULFILLED:
        case CermAssessmentActions.SAVE_PROCESS_STEP_ANSWER_REJECTED:
        case CermAssessmentActions.SET_PROCESS_STEP_RESULT_FULFILLED:
        case CermAssessmentActions.SET_PROCESS_STEP_RESULT_REJECTED:
            return false;

        default:
            return state;
    }
};

const isLoadingHistory = (
    state: ICermAssessmentResultState['isLoading'] = INITIAL_STATE.isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case CermAssessmentActions.LOAD_HISTORY:
            return true;
        case CermAssessmentActions.LOAD_HISTORY_FULFILLED:
        case CermAssessmentActions.LOAD_HISTORY_REJECTED:
            return false;

        default:
            return state;
    }
};

const cermAssessmentResult = (
    state: ICermAssessmentResultState['cermAssessmentResult'] = INITIAL_STATE.cermAssessmentResult,
    action: IAppAction
) => {
    switch (action.type) {
        case CermAssessmentActions.START_CERM_EXPERT_ASSESSMENT_FULFILLED:
        case CermAssessmentActions.FINISH_CERM_EXPERT_ASSESSMENT_FULFILLED:
            if (action.payload) {
                return {
                    ...state,
                    expertReviewStatus: action.payload.expertReviewStatus,
                };
            }

            return state;
        case CermAssessmentActions.LOAD_CERM_ASSESSMENT_RESULT_FULFILLED:
            if (action.payload) {
                return action.payload;
            }

            return state;

        case CermAssessmentActions.SET_PROCESS_STEP_RESULT:
            if (action.payload) {
                const newResult = action.payload.processStepResult;
                const oldResult = state.processStepResults.find(
                    (r) => r.masterProcessStepId === newResult.masterProcessStepId
                );
                let processStepResults = null;
                if (oldResult) {
                    processStepResults = state.processStepResults.map((r) =>
                        r.masterProcessStepId ===
                            action.payload.processStepResult.masterProcessStepId
                            ? newResult
                            : r
                    );
                } else {
                    processStepResults = [newResult, ...state.processStepResults];
                }
                return {
                    score: state.score,
                    processStepResults,
                    expertReviewStatus: state.expertReviewStatus,
                };
            }

        case CermAssessmentActions.SET_PROCESS_STEP_RESULT_FULFILLED:
            if (action.payload) {
                const oldResult = state.processStepResults?.filter(
                    (o) =>
                        action.payload.processStepResult?.findIndex(
                            (n) => n.masterProcessStepId === o.masterProcessStepId
                        ) < 0
                );
                const newResult = action.payload.processStepResult?.filter(
                    (n) =>
                        oldResult?.findIndex(
                            (o) => n.masterProcessStepId === o.masterProcessStepId
                        ) < 0
                );
                let processStepResults = [];
                if (oldResult) {
                    processStepResults.push(...oldResult);
                }
                if (newResult) {
                    processStepResults.push(...newResult);
                }
                return {
                    score: action.payload.score,
                    processStepResults,
                    expertReviewStatus: state.expertReviewStatus,
                };
            }

            return state;

        case ActionActions.CLOSE_ACTION_FULFILLED:
        case ActionActions.DELETE_ACTION_FULFILLED:
        case RiskActions.CLOSE_RISK_FULFILLED:
        case RiskActions.DELETE_RISK_FULFILLED:
            return INITIAL_STATE.cermAssessmentResult;

        default:
            return state;
    }
};

const processStepAnswer = (
    state: ICermAssessmentResultState['processStepAnswer'] = INITIAL_STATE.processStepAnswer,
    action: IAppAction
) => {
    switch (action.type) {
        case CermAssessmentActions.LOAD_PROCESS_STEP_ANSWER_FULFILLED:
        case CermAssessmentActions.CREATE_PROCESS_STEP_ANSWER_FULFILLED:
        case CermAssessmentActions.SAVE_PROCESS_STEP_ANSWER_FULFILLED:
            return action.payload ? action.payload : state;

        case CermAssessmentActions.LOAD_PROCESS_STEP_OUTCOME_FULFILLED:
            if (!state?.processStepOutcomes) {
                return state;
            }
            const index = state.processStepOutcomes.findIndex((item) => item.id === action.payload?.id);
            if (index === -1) {
                return state;
            }
            const updatedProcessStepOutcomes = [
                ...state.processStepOutcomes.slice(0, index),
                action.payload,
                ...state.processStepOutcomes.slice(index + 1),
            ];

            return {
                ...state,
                processStepOutcomes: updatedProcessStepOutcomes,
            };

        case CermAssessmentActions.SET_PROCESS_STEP_ANSWER:
            return action.payload;

        case ActionActions.CLOSE_ACTION_FULFILLED:
        case ActionActions.DELETE_ACTION_FULFILLED:
        case RiskActions.CLOSE_RISK_FULFILLED:
        case RiskActions.DELETE_RISK_FULFILLED:
            return INITIAL_STATE.processStepAnswer;

        default:
            return state;
    }
};

const cermProgressChartData = (
    state: ICermAssessmentResultState['cermProgressChartData'] = INITIAL_STATE.cermProgressChartData,
    action: IAppAction
) => {
    switch (action.type) {
        case CermAssessmentActions.LOAD_CERM_PROGRESS_CHART_DATA_FULFILLED:
            return action.payload ? action.payload : state;
        default:
            return state;
    }
};

const snapshots = (
    state: ICermAssessmentResultState['snapshots'] = INITIAL_STATE.snapshots,
    action: IAppAction
) => {
    switch (action.type) {
        case CermAssessmentActions.LOAD_CERM_SNAPSHOTS_OVERVIEW_ONLY_FULFILLED:
            return action.payload ? action.payload : state;
        default:
            return state;
    }
};

const snapshotsFullResult = (
    state: ICermAssessmentResultState['snapshotsFullResult'] = INITIAL_STATE.snapshotsFullResult,
    action: IAppAction
): ICermAssessmentSnapshot[] => {
    switch (action.type) {
        case CermAssessmentActions.LOAD_CERM_SNAPSHOTS_COMPARISON_FULFILLED:
            return action.payload ? action.payload : state;
        default:
            return state;
    }
};

const isExporting = (
    state: ICermAssessmentResultState['isExporting'] = INITIAL_STATE.isExporting,
    action: IAppAction
) => {
    switch (action.type) {
        case CermAssessmentActions.EXPORT_CERM_SNAPSHOTS_COMPARISON:
            return true;

        case CermAssessmentActions.EXPORT_CERM_SNAPSHOTS_COMPARISON_FULFILLED:
            if (action.payload && action.payload.fileContents) {
                const blob = new Blob([s2ab(atob(action.payload.fileContents))], {
                    type: 'application/excel',
                });
                saveAs(blob, action.payload.fileDownloadName);
            }

            return false;

        case CermAssessmentActions.EXPORT_CERM_SNAPSHOTS_COMPARISON_REJECTED:
            return false;

        default:
            return state;
    }
};

const history = (
    state: ICermAssessmentResultState['history'] = INITIAL_STATE.history,
    action: IAppAction
): IDifference[][] => {
    switch (action.type) {
        case CermAssessmentActions.LOAD_HISTORY_FULFILLED:
            if (action.payload) {
                if (action.isFromContinuationRequest) {
                    return [...state, ...action.payload as IDifference[][]];
                }
                return action.payload;
            }
            return state;

        case CermAssessmentActions.LOAD_HISTORY:
        case CermAssessmentActions.LOAD_HISTORY_REJECTED:
        default:
            return state;
    }
};

const historyResponseContinuation = (
    state: ICermAssessmentResultState['historyResponseContinuation'] = null,
    action: IAppAction
): string => {
    switch (action.type) {
        case CermAssessmentActions.LOAD_HISTORY:
            return null;
        case CermAssessmentActions.LOAD_HISTORY_FULFILLED:
            return action.responseContinuation;
        case CermAssessmentActions.LOAD_HISTORY_REJECTED:
            return state;
        default:
            return state;
    }
};

export const cermAssessmentResultReducer = combineReducers<ICermAssessmentResultState>({
    eCermToken,
    cermAssessmentResult,
    processStepAnswer,
    isLoading,
    attachments,
    cermProgressChartData,
    snapshots,
    isExporting,
    history,
    historyResponseContinuation,
    isLoadingHistory,
    expertReviewStatus,
    attachmentsSite,
    snapshotsFullResult,
});
