import { compact } from 'lodash';
import * as React from 'react';
import { MaterialIcons } from 'routes/material-icon/material-icon';
import { MaterialIconType } from 'routes/material-icon/material-icon-type';
export interface IStateProps {
    title?: string;
    fieldName: string;
    isExpanded?: boolean;
    secondaryTitle?: string;
}

export interface IActionProps {
    onCollapseExpand?: (id: string) => any;
}

interface IProps extends IStateProps, IActionProps { }

export const FilterSet: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const onCollapseExpand: React.MouseEventHandler<HTMLAnchorElement> = () => {
        props.onCollapseExpand(props.fieldName);
    };

    const filterFieldsCss = compact([
        'filters',
        props.isExpanded ? 'is-expanded' : 'is-collapsed'
    ]).join(' ');

    const getTitle = () => {
        if (!props.title) {
            return null;
        }

        if (!props.onCollapseExpand) {
            return props.title;
        }

        if (props.isExpanded) {
            return (
                <a aria-label="Collapse" onClick={onCollapseExpand}>
                    {props.title}
                    < MaterialIcons className="icon" type={MaterialIconType.Minus} />
                </a>
            );
        }

        return (
            <a aria-label="Expand" onClick={onCollapseExpand}>
                {props.title}
                < MaterialIcons className="icon" type={MaterialIconType.Plus} />
            </a>
        );
    };

    return (
        <fieldset className={filterFieldsCss}>
            <div className="filter-container">
                <legend>{getTitle()}</legend>
                <div className="secondary-title">{props.secondaryTitle}</div>
                {props.children}
            </div>
        </fieldset>
    );
};

FilterSet.defaultProps = {
    isExpanded: true
};
