import { IAppAction } from 'actions/app-action';
import { combineReducers } from 'redux';
import { IMenuState } from 'state/menu-state';
import { IMenuActions } from 'actions/actions-v2/menu-actions';

const INITIAL_STATE: IMenuState = {
    isLoading: false,
    menus: [],
    selectedMenu: '',
};

const isLoading = (
    state: IMenuState['isLoading'] = INITIAL_STATE.isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case IMenuActions.LOAD_MENU:
            return true;
        case IMenuActions.LOAD_MENU_FULFILLED:
        case IMenuActions.LOAD_MENU_REJECTED:
            return false;
        default:
            return state;
    }
};

const menus = (state: IMenuState['menus'] = INITIAL_STATE.menus, action: IAppAction) => {
    switch (action.type) {
        case IMenuActions.LOAD_MENU_FULFILLED:
            if (action.payload) {
                return action.payload;
            }
            break;
        default:
            return state;
    }
};

const selectedMenu = (state: IMenuState['selectedMenu'] = INITIAL_STATE.selectedMenu, action: IAppAction) => {
    switch (action.type) {
        case IMenuActions.SELECTED_MENU:
            if (action.payload) {
                return action.payload.selectedMenuName;
            }
            break;
        default:
            return state;
    }
};

export const IMenuReducer = combineReducers<IMenuState>({
    isLoading,
    menus,
    selectedMenu,
});
