import {
  SortDirectionOpts,
  commonFilterOpts,
  dateFilterOpts,
  dropdownFilterOpts,
  numberFilterOpts,
  textFilterOpts,
  commonFilterOptsType,
  dateFilterOptsType,
  dropdownFilterOptsType,
  numberFilterOptsType,
  textFilterOptsType,
  filterInputTypeOpts,
  filterInputTypeOptsType,
} from './constants';
import { IEmeraldPaginationCoreInputs } from '../pagination/pagination.inputs';
import { IEmeraldButtonCoreInputs } from '../button/button.inputs';
import { IEmeraldDropdownCoreInputs } from '../dropdown/dropdown.inputs';
import { IEmeraldDatepickerCoreInputs } from '../datepicker/datepicker.inputs';
import { IEmeraldAutoCompleteCoreInputs } from '../autocomplete/autocomplete.inputs';

export type FilterPatternOpts =
  | commonFilterOpts
  | textFilterOpts
  | numberFilterOpts
  | dropdownFilterOpts
  | dateFilterOpts;

export type FilterPatternType =
  | commonFilterOptsType
  | textFilterOptsType
  | numberFilterOptsType
  | dropdownFilterOptsType
  | dateFilterOptsType;

/**
 * Represents the sort parameters for a header.
 */
export interface ISortParams {
  enabled?: boolean; // Whether sorting is enabled
  direction?: SortDirectionOpts; // Sorting direction
  order?: number; // Sorting order
}

/**
 * Represents the filter parameters for a header.
 */
export interface IFilterParams {
  enabled?: boolean; // Whether filtering is enabled
  inputValue?: string; // Input value for filtering
  inputType?: filterInputTypeOpts | filterInputTypeOptsType; // Type of filter input
  pattern?: FilterPatternOpts | FilterPatternType; // Filter pattern
  autocomplete?: boolean; // Whether autocomplete is enabled for filtering
  dropdownInputs?: IEmeraldDropdownCoreInputs<string>; // All inputs for the dropdown and applied if inputType is dropdown
  datePickerInputs?: IEmeraldDatepickerCoreInputs; // All inputs for the date picker and applied if inputType is date
  autoCompleteInputs?: IEmeraldAutoCompleteCoreInputs; // All inputs for the autocomplete and applied if inputType is autocomplete
  hideMenu?: boolean; // Whether the sort menu is hidden
}

export type filterCellInputType = Exclude<filterInputTypeOptsType, 'dropdown'>;

/**
 * Represents the inputs for a field in a header.
 */
export interface IFieldParams<T> {
  field: keyof T & string; // Field name
  dataType: string; // Data type of the field
  isHidden: boolean; // Whether the field is hidden
}

export interface IPaginationParams extends IEmeraldPaginationCoreInputs {
  enabled?: boolean;
  serverSide?: boolean;
  onChange?: any;
}

export interface IActionButtonParams extends IEmeraldButtonCoreInputs {
  enabled?: boolean;
  actions?: {
    renderButton?: any;
  }[];
}

/**
 * Represents the parameters for row selection.
 */
export interface IRowSelectionParams {
  enabled?: boolean; // Whether row selection is enabled
  selectedRows?: string[]; // Indexes of selected rows
  onChange?: any; // Callback function for row selection change
}

/**
 * Represents the parameters for a data cell.
 */
export interface IDataCellParams<T> extends IFieldParams<T> {
  idx: number; // Index of the cell
  value: T[keyof T]; // Value of the cell
  cellTemplate?: any; // Cell template
  rowObj?: T; // Row data object
  active?: boolean; // Whether the cell is active
  editable?: boolean; // Whether the cell is editable
}

/**
 * Represents the parameters for a data row.
 */
export interface IDataRowParams<T> {
  idx: number; // Index of the row
  cells: IDataCellParams<T>[]; // Cells of the row
  rowObj: T;
  rowIdentifier: string;
}

export enum TableViewMode {
  HorizontalLines = 'horizontal_lines',
  ZebraStripes = 'zebra_stripes',
  Grid = 'grid',
}
