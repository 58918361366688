import { FormikProps, FormikErrors } from 'formik';
import { IIncident, ImpactedSystem, IncidentStatus } from 'models';
import { Field } from 'components/form/Field';
import { BulmaSize } from 'enums/BulmaSize';
import React from 'react';
import { Checkbox } from 'components/form/Checkbox';
import { Control } from 'components/form/Control';
import { Input } from 'components/form/Input';
import useTranslate from 'translations/translation-utils';

interface IProps {
    formikBag: FormikProps<Partial<IIncident>>;
    getHelper: (formikErrors: FormikErrors<any> | any, isShown?: boolean) => void;
    handleFactorsChange: (item: ImpactedSystem) => void;
}

export const ImpactedSystemsCheckBoxes: React.FC<IProps> = ({
    formikBag,
    getHelper,
    handleFactorsChange,
}) => {
    const translate = useTranslate();
    return (
        <div>
            <Field
                isHorizontal={true}
                htmlFor="factors"
                label={translate('IncidentDetails.Label.ImpactedSystems')}
                labelSize={BulmaSize.Medium}
            >
                <Field>
                    <Control className="columns is-desktop">
                        <div className="column is-one-quarter">
                            <Checkbox
                                checked={formikBag.values.impactedSystems.includes(
                                    ImpactedSystem.ElectricalDistribution
                                )}
                                id={ImpactedSystem.ElectricalDistribution}
                                label={translate(
                                    'IncidentDetails.ImpactedSystem.'.concat(
                                        ImpactedSystem.ElectricalDistribution.toString().replace(
                                            /\s/g,
                                            ''
                                        )
                                    )
                                )}
                                onChange={handleFactorsChange.bind(
                                    this,
                                    ImpactedSystem.ElectricalDistribution
                                )}
                                disabled={formikBag.values.status === IncidentStatus.Closed}
                            />
                        </div>
                        <div className="column is-one-quarter">
                            <Checkbox
                                checked={formikBag.values.impactedSystems.includes(
                                    ImpactedSystem.UninterruptiblePower
                                )}
                                id={ImpactedSystem.UninterruptiblePower}
                                label={translate(
                                    'IncidentDetails.ImpactedSystem.'.concat(
                                        ImpactedSystem.UninterruptiblePower.toString().replace(
                                            /\s/g,
                                            ''
                                        )
                                    )
                                )}
                                onChange={handleFactorsChange.bind(
                                    this,
                                    ImpactedSystem.UninterruptiblePower
                                )}
                                disabled={formikBag.values.status === IncidentStatus.Closed}
                            />
                        </div>
                        <div className="column is-one-quarter">
                            <Checkbox
                                checked={formikBag.values.impactedSystems.includes(
                                    ImpactedSystem.DieselGenerator
                                )}
                                id={ImpactedSystem.DieselGenerator}
                                label={translate(
                                    'IncidentDetails.ImpactedSystem.'.concat(
                                        ImpactedSystem.DieselGenerator.toString().replace(/\s/g, '')
                                    )
                                )}
                                onChange={handleFactorsChange.bind(
                                    this,
                                    ImpactedSystem.DieselGenerator
                                )}
                                disabled={formikBag.values.status === IncidentStatus.Closed}
                            />
                        </div>
                        <div className="column is-one-quarter">
                            <Checkbox
                                checked={formikBag.values.impactedSystems.includes(
                                    ImpactedSystem.MechanicalCooling
                                )}
                                id={ImpactedSystem.MechanicalCooling}
                                label={translate(
                                    'IncidentDetails.ImpactedSystem.'.concat(
                                        ImpactedSystem.MechanicalCooling.toString()
                                            .replace(/\s/g, '')
                                            .replace(/[,/]/g, '')
                                    )
                                )}
                                onChange={handleFactorsChange.bind(
                                    this,
                                    ImpactedSystem.MechanicalCooling
                                )}
                                disabled={formikBag.values.status === IncidentStatus.Closed}
                            />
                        </div>
                    </Control>
                </Field>
            </Field>
            <Field isHorizontal={true} label=" ">
                <Field disabled={formikBag.values.status === IncidentStatus.Closed}>
                    <>
                        <Control className="columns is-desktop">
                            <div className="column is-3">
                                <Checkbox
                                    checked={formikBag.values.impactedSystems.includes(
                                        ImpactedSystem.FireProtection
                                    )}
                                    id={ImpactedSystem.FireProtection}
                                    label={translate(
                                        'IncidentDetails.ImpactedSystem.'.concat(
                                            ImpactedSystem.FireProtection.toString().replace(/\s/g, '')
                                        )
                                    )}
                                    onChange={handleFactorsChange.bind(
                                        this,
                                        ImpactedSystem.FireProtection
                                    )}
                                    disabled={formikBag.values.status === IncidentStatus.Closed}
                                />
                            </div>
                            <div className="column is-4">
                                <Checkbox
                                    checked={formikBag.values.impactedSystems.includes(
                                        ImpactedSystem.ImpactedSystemOther
                                    )}
                                    id="ImpactedSystemImpactedSystemOther"
                                    label={translate(
                                        'IncidentDetails.ImpactedSystem.'.concat(
                                            ImpactedSystem.ImpactedSystemOther.toString()
                                                .replace(/\s/g, '')
                                                .replace(/[,]/g, '')
                                        )
                                    )}
                                    onChange={handleFactorsChange.bind(
                                        this,
                                        ImpactedSystem.ImpactedSystemOther
                                    )}
                                    disabled={formikBag.values.status === IncidentStatus.Closed}
                                />
                            </div>
                            <div className="column is-5">
                                <Input
                                    id="impactedSystemOther"
                                    value={formikBag.values.impactedSystemOther}
                                    onChange={formikBag.handleChange}
                                    disabled={
                                        !formikBag.values.impactedSystems.includes(
                                            ImpactedSystem.ImpactedSystemOther
                                        ) || formikBag.values.status === IncidentStatus.Closed
                                    }
                                />
                            </div>
                        </Control>
                        {
                            ((formikBag.values.impactedSystems.includes(ImpactedSystem.ImpactedSystemOther))
                                || (formikBag.values.impactedSystems.length === 0))
                            &&
                            getHelper(
                                formikBag.errors.impactedSystemOther,
                                formikBag.touched.impactedSystemOther
                            )}
                    </>
                </Field>
            </Field>
        </div>
    );
};
