import { createSelector } from 'reselect';

import { SortDirection } from 'enums/SortDirection';
import { IRootCermSiteOverviewState } from 'reducers/cerm-site-overview';

export default createSelector(
    (state: IRootCermSiteOverviewState) => state.cermSiteOverview,
    (state) => ({
        groups: state.items,
        isLoading: state.isLoading,
        sortColumn: state.sortColumn,
        sortDirection: state.sortAscending
            ? SortDirection.Ascending
            : SortDirection.Descending,
        expandedItems: state.expandedItems,
    })
);
