import _ from 'lodash';
import { createSelector } from 'reselect';
import { IRootState } from '../store';

const getIsLoading = createSelector(
    (state: IRootState) => state.siteFeatures.isLoading,
    (state) => state
);

export const getSites = createSelector(
    (state: IRootState) => state.siteFeatures.sites,
    (state) => state
);

export const getClients = createSelector(
    (state: IRootState) => state.siteFeatures.sites,
    (state) => {
        if (state) {
            return _.uniq(state.map((site) => site.clientName));
        }
        return [];
    }
);

export const getClientOptions = createSelector(
    (state: IRootState) => state.siteFeatures.sites,
    (state) => {
        if (state) {
            return _.uniq(
                state.map((site) => ({
                    label: site.clientName,
                    value: site.clientId,
                }))
            );
        }
        return [];
    }
);

export const getLineOfBusinesses = createSelector(
    (state: IRootState) => state.siteFeatures.sites,
    (state) => {
        if (state) {
            return _.uniq(state.map((site) => site.lineOfBusiness));
        }
        return [];
    }
);

export const getLineOfBusinessOptions = createSelector(
    (state: IRootState) => state.siteFeatures.sites,
    (state) => {
        if (state) {
            return _.uniq(
                state.map((site) => ({
                    label: site.lineOfBusiness,
                    value: site.lineOfBusiness,
                }))
            );
        }
        return [];
    }
);

export const getRegions = createSelector(
    (state: IRootState) => state.siteFeatures.sites,
    (state) => {
        const regions: string[] = [];
        _.forEach(state, (site) => {
            _.forEach(site.buildings, (building) => {
                if (building.region && regions.indexOf(building.region) === -1) {
                    regions.push(building.region);
                }
            });
        });
        return regions;
    }
);


export const getOperatingPlatforms = (state: IRootState) => state.operatingPlatformState.operatingPlatforms;

export const getLineOfBusiness = (state: IRootState) =>
    state.siteMetaDataState.allLineOfBusinesses;

export default createSelector(
    getIsLoading,
    getSites,
    getLineOfBusiness,
    getRegions,
    getClients,
    getOperatingPlatforms,
    (isLoading, sites, lineOfBusinesses, regions, clients, operatingPlatforms) => ({
        isLoading,
        sites,
        lineOfBusinesses,
        regions,
        clients,
        operatingPlatforms,
    })
);
