import { IAppAction } from 'actions/app-action';
import { combineReducers } from 'redux';
import { IRomonetState } from 'state/romonet-state';
import { RomonetActions } from 'actions/actions-v2/romonet-actions';
import { IAttachmentModel } from 'models/attachment/attachment-model';
import { AttachmentActions } from 'actions/actions-v2/attachment-action-v2';
import { SiteActions } from 'actions/actions-v2/site-actions-v2';
import { s2ab } from 'utils/export-utils';
import saveAs from 'file-saver';

const INITIAL_STATE: IRomonetState = {
    isLoading: false,
    isExporting: false,
    romonetReport: null,
    romonetDateFilter: null,
    isdownloadPPT: null,
    attachments: [],
    detailedHallNames: null,
};

const isdownloadPPT = (
    state: IRomonetState['isdownloadPPT'] = INITIAL_STATE.isdownloadPPT,
    action: IAppAction
) => {
    switch (action.type) {
        case RomonetActions.DOWNLOAD_PPT:
            return true;

        case RomonetActions.DOWNLOAD_PPT_FULFILLED:
            const blob = new Blob([s2ab(atob(action.payload.fileContents))], {
                type: 'pdf',
            });
            saveAs(blob, action.payload.fileDownloadName);
            return false;

        case RomonetActions.DOWNLOAD_PPT_REJECTED:
            return false;

        default:
            return state;
    }
};

const isExporting = (
    state: IRomonetState['isExporting'] = INITIAL_STATE.isExporting,
    action: IAppAction
) => {
    switch (action.type) {
        case RomonetActions.DOWNLOAD_REPORTS:
            return true;

        case RomonetActions.DOWNLOAD_REPORTS_FULFILLED:
            const blob = new Blob([s2ab(atob(action.payload.fileContents))], {
                type: 'text/html',
            });
            saveAs(blob, action.payload.fileDownloadName);
            return false;

        case RomonetActions.DOWNLOAD_REPORTS_REJECTED:
            return false;

        default:
            return state;
    }
};

const isLoading = (
    state: IRomonetState['isLoading'] = INITIAL_STATE.isLoading,
    action: IAppAction
) => {
    switch (action.type) {
        case RomonetActions.LOAD_REPORT:
        case RomonetActions.LOAD_DATE_FILTER:
        case SiteActions.LOAD_SITE:
        case RomonetActions.LOAD_DETAILS_HALL_NAME:
            return true;
        case RomonetActions.LOAD_REPORT_FULFILLED:
        case RomonetActions.LOAD_REPORT_REJECTED:
        case RomonetActions.LOAD_DATE_FILTER_FULFILLED:
        case RomonetActions.LOAD_DATE_FILTER_REJECTED:
        case SiteActions.LOAD_SITE_FULFILLED:
        case SiteActions.LOAD_SITE_REJECTED:
        case RomonetActions.LOAD_DETAILS_HALL_NAME_FULFILLED:
        case RomonetActions.LOAD_DETAILS_HALL_NAME_REJECTED:
            return false;

        default:
            return state;
    }
};

const romonetReport = (
    state: IRomonetState['romonetReport'] = INITIAL_STATE.romonetReport,
    action: IAppAction
) => {
    switch (action.type) {
        case RomonetActions.LOAD_REPORT:
            return state;

        case RomonetActions.LOAD_REPORT_FULFILLED:
            return action.payload;

        case RomonetActions.LOAD_REPORT_REJECTED:
            return state;
        case RomonetActions.RESET_REPORT:
            return null;
        default:
            return state;
    }
};

const romonetDateFilter = (
    state: IRomonetState['romonetDateFilter'] = INITIAL_STATE.romonetDateFilter,
    action: IAppAction
) => {
    switch (action.type) {
        case RomonetActions.LOAD_DATE_FILTER:
            return state;

        case RomonetActions.LOAD_DATE_FILTER_FULFILLED:
            return action.payload;

        case RomonetActions.LOAD_DATE_FILTER_REJECTED:
            return state;
        case RomonetActions.RESET_REPORT:
            return null;

        default:
            return state;
    }
};

const detailedHallNames = (
    state: IRomonetState['detailedHallNames'] = INITIAL_STATE.detailedHallNames,
    action: IAppAction
) => {
    switch (action.type) {
        case RomonetActions.LOAD_DETAILS_HALL_NAME:
            return state;

        case RomonetActions.LOAD_DETAILS_HALL_NAME_FULFILLED:
            return action.payload;

        case RomonetActions.LOAD_DETAILS_HALL_NAME_REJECTED:
            return state;

        default:
            return state;
    }
};

const attachments = (
    state: IRomonetState['attachments'] = INITIAL_STATE.attachments,
    action: IAppAction
) => {
    switch (action.type) {
        case AttachmentActions.LOAD_ATTACHMENTS_ENGINEER_REPORT_FULFILLED:
            if (action.payload) {
                return action.payload;
            }
        case AttachmentActions.UPLOAD_ATTACHMENT_ENGINEER_REPORT_FULFILLED:
            if (action.payload) {
                return [...state, ...action.payload];
            }
            return state;
        case AttachmentActions.DELETE_ATTACHMENT_FULFILLED:
            if (action.payload) {
                return state.filter((item: IAttachmentModel) => item.fileName !== action.payload);
            }
            return state;
        default:
            return state;
    }
};
export const romonetReducer = combineReducers<IRomonetState>({
    isLoading,
    isExporting,
    romonetReport,
    romonetDateFilter,
    attachments,
    isdownloadPPT,
    detailedHallNames,
});
