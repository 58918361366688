import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import actions from './actions';
import { CermOverviewList as View } from './cerm-overview-list';
import selector from './selector';

export const CermOverviewList = withRouter(connect(
    selector,
    actions
)(View));
