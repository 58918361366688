import { Action } from 'redux';
import { history } from 'routes/App';
import { IAppAction, IGenericAction } from './app-action';

export enum AppActions {
    CHANGE_SITE = '@@app/CHANGE_SITE',
    CHANGE_SITE_FULFILLED = '@@app/CHANGE_SITE_FULFILLED',
    CHANGE_SITE_REJECTED = '@@app/CHANGE_SITE_REJECTED ',
    LOAD_PEOPLE_LINKED_TO_SITE = '@@nav/LOAD_PEOPLE_LINKED_TO_SITE',
    LOAD_PEOPLE_LINKED_TO_SITE_FULFILLED = '@@nav/LOAD_PEOPLE_LINKED_TO_SITE_FULFILLED',
    LOAD_PEOPLE_LINKED_TO_SITE_REJECTED = '@@nav/LOAD_PEOPLE_LINKED_TO_SITE_REJECTED',
    LOAD_ALL_PEOPLE_LINKED_TO_SITE = '@@nav/LOAD_ALL_PEOPLE_LINKED_TO_SITE',
    LOAD_ALL_PEOPLE_LINKED_TO_SITE_FULFILLED = '@@nav/LOAD_ALL_PEOPLE_LINKED_TO_SITE_FULFILLED',
    LOAD_ALL_PEOPLE_LINKED_TO_SITE_REJECTED = '@@nav/LOAD_ALL_PEOPLE_LINKED_TO_SITE_REJECTED',
    CHANGE_ROUTE = '@@app/CHANGE_ROUTE',
    IS_SESSION_ALIVE = '@@nav/IS_SESSION_ALIVE',
    IS_SESSION_ALIVE_FULFILLED = '@@nav/IS_SESSION_ALIVE_FULFILLED',
    IS_SESSION_ALIVE_REJECTED = '@@nav/IS_SESSION_ALIVE_REJECTED',
}

export interface IChangeSiteAction extends Action {
    type: AppActions.CHANGE_SITE;
    payload: {
        siteId: string;
    };
}

export const sendRequestSessionAlive = (): AppActionTypes => ({
    type: AppActions.IS_SESSION_ALIVE,
});

export interface ISessionAliveFulfilled extends IGenericAction<boolean> {
    type: AppActions.IS_SESSION_ALIVE_FULFILLED;
    payload: boolean;
}
export interface ISessionAliveRejected extends IGenericAction<boolean> {
    type: AppActions.IS_SESSION_ALIVE_REJECTED;
    payload: boolean;
}

export interface ISessionAlive extends Action {
    type: AppActions.IS_SESSION_ALIVE;
}

export const onLoadPeopleLinkedToSite = (siteId: string): IAppAction => ({
    type: AppActions.LOAD_PEOPLE_LINKED_TO_SITE,
    payload: {
        siteId,
    },
});

export const onLoadAllPeopleLinkedToSite = (siteId: string): IAppAction => ({
    type: AppActions.LOAD_ALL_PEOPLE_LINKED_TO_SITE,
    payload: {
        siteId,
    },
});

export const onSiteChange = (siteId: string): AppActionTypes => ({
    type: AppActions.CHANGE_SITE,
    payload: {
        siteId,
    },
});

export const onRouteChange = (url: string) => {
    history.push(url);
    return {
        type: `${AppActions.CHANGE_ROUTE}_${url}`,
        payload: {
            url: `${url}`,
        },
    };
};

export const onRouteBack = () => history.goBack();

export type AppActionTypes =
    | IChangeSiteAction
    | ISessionAlive
    | ISessionAliveFulfilled
    | ISessionAliveRejected;
