import { combineEpics, Epic, ofType } from 'redux-observable';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { flatMap, switchMap, catchError } from 'rxjs/operators';
import { notificationStateActions, onSenNotificationComplete } from './../actions';
import { INotificationState } from './../state';
import { Action } from 'redux';
import { Observable } from 'rxjs';
import { AppNotificationActions } from './../actions/app-notification-actions';
import { IAppAction } from './../actions/app-action';
import { getErrorActions$, IResponse as IErrorResult } from './epic-helpers';
import { NavActionTypes, NavActions, INotificationRead } from './../actions/nav-actions';
import { getApiHeaders } from './shared-header';
// import { onRouteChange } from 'actions/app-actions';
// import { getInternalRoute } from 'utils/string-utils';

import appConfig from 'helpers/config-helper';

const config = appConfig();

const apiUrl = config.REACT_APP_BASE_API;

const sendNotificationEpic: Epic<IAppAction, IAppAction, INotificationState> = (
    action$
): Observable<Action> =>
    action$.pipe(
        ofType(notificationStateActions.SEND_NOTIFICATION),
        switchMap((action: IAppAction) =>
            ajax
                .post(
                    `${apiUrl}/api/SendNotification/ManualNotification`,
                    action.payload,
                    getApiHeaders()
                )
                .pipe(
                    flatMap<AjaxResponse, IAppAction | any>(() => [
                        {
                            type: AppNotificationActions.SEND_SUCCESS_NOTIFICATION,
                            payload: {
                                title: 'Send notification',
                                message:
                                    'The notification has been submitted for sending to recipients',
                            },
                        },
                        onSenNotificationComplete(),
                    ]),
                    catchError<any, any>((error: IErrorResult) =>
                        getErrorActions$('Send notifications error')(
                            notificationStateActions.SEND_NOTIFICATION_REJECTED,
                            error,
                            {
                                errorMessage: JSON.stringify(error.response),
                            }
                        )
                    )
                )
        )
    );

const readNotificationEpic: Epic<NavActionTypes, NavActionTypes> = (action$) =>
    action$.pipe(
        ofType(NavActions.NOTIFICATION_READ),
        switchMap((action: INotificationRead) =>
            ajax
                .post(
                    `${apiUrl}/api/SendNotification/UpdateNotification/${action.payload.id}`,
                    '',
                    getApiHeaders()
                )
                .pipe(
                    flatMap<AjaxResponse, any>((response) => {
                        return [
                            {
                                type: NavActions.NOTIFICATION_RECEIVED,
                                payload: {
                                    notification: response.response,
                                },
                            },
                            // download files was crashing, had to comment out
                            /// getInternalRoute(action.payload.notificationLink) &&
                            // onRouteChange(getInternalRoute(action.payload.notificationLink)),
                        ];
                    }),
                    catchError<any, any>((error: IErrorResult) =>
                        getErrorActions$('Notification errors')(
                            NavActions.NOTIFICATION_REJECTED,
                            error,
                            {
                                errorMessage: JSON.stringify(error.response),
                            }
                        )
                    )
                )
        )
    );

export const notificationEpics = combineEpics(sendNotificationEpic, readNotificationEpic);
