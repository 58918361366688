import React from 'react';
import Page from '../../components/v2/page/Page';
import useTranslate from 'translations/translation-utils';
import { RomonetHeaderContainer } from './romonet-header/romonet-header-container';
import { RomonetIframeContainer } from './romonet-iframe/romonet-iframe-container';
import { RomonetReportType } from 'models/romonet/romonet-page';

const FacilityOperationsSnapshot: React.FC = () => {
    const translate = useTranslate();
    return (
        <Page title="" className="facility-operations-snapshot">
            <RomonetHeaderContainer
                title={translate('Romonet.FacilityOperationsSnapshot.Title')}
                romonetReportType={RomonetReportType.FacilityOperationsSnapshot}
            />
            <div className="container">
                <RomonetIframeContainer />
            </div>
        </Page>
    );
};

export default FacilityOperationsSnapshot;
