import React from 'react';
import { Alert } from 'components/v2/components';
import useTranslate from 'translations/translation-utils';

export const PageNotFound = () => {
    const translate = useTranslate();
    return (
        <Alert type="warning" title={translate('PageNotFound.Alert.Title')}>
            <p>{translate('PageNotFound.Alert.Message')}</p>
        </Alert>
    );
};
