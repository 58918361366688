import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IRootState } from 'routes/store';
import supportTeamForm from './support-Team-form';

const getSupportTeam = (state: IRootState) => state.supportTeamState.supportTeam;

export const userSelectorSelector = createSelector(getSupportTeam, (supportTeam) => ({
    supportTeam,
}));

export const SupportTeamContainer = connect(userSelectorSelector)(supportTeamForm);
